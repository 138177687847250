import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useHistory } from 'react-router';
import { makeStyles } from '@material-ui/core/styles';
import { DatabaseConnector, RESTConnection } from '@phinxlab/libby-rest-web';
import {
  LibbyObject,
  loadingModal,
  OptionsModalOption,
  MainInput,
  useDebounce,
  Loading,
  useRouteScreenTitle,
  ROL,
} from 'src/commons';
import AddIcon from '@material-ui/icons/Add';
import { grey, primary } from 'src/theme/colors';
import { IconButton, Grid } from '@material-ui/core';
import { useProfileContext } from 'src/lib/profiles/ProfileContext';
import { useFillRoleUser } from 'src/commons/hooks/useFillRoleUser';
import {
  Localizacion,
  UsuarioRolEstablecimientoArea,
  AutoRegistracion,
  UsuarioRolEstablecimiento,
} from 'src/app/models';
import {
  useAutoRegistracionLibbyFetch,
  useAutoRegistracionDAO,
  useUsuarioRolEstablecimientoLibbyCall,
  useReloginDAO,
  useRemoveCardCustomPostRequest,
} from 'src/app/business';
import { useOrganizacionesLibbyFetch } from 'src/app/business/acap/Organizaciones';
import { useOfertasGruposLibbyFetch } from 'src/app/business/acap/OfertasGrupos';
import { CardItemPending } from 'src/commons/components/Card/components';
import { CardComponent } from 'src/commons/components/Card';
import { CardItem } from 'src/commons/components/Card/components';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useSnackbar } from 'notistack';
import { ROL_STATUS } from 'src/commons/const/rolStatus';
import customFormDialog from 'src/commons/services/customFormDialog';
import { AnyObject } from 'src/commons/types';
import { CambioPasswordModal } from './CambioPasswordModal';
import { useCreateCuentaAndPersonaPostRequest } from 'src/app/business/businessCustomEndpoints/CreateCuentaAndPersona';

const homeStyles = makeStyles(() => ({
  headerTitle: {
    fontFamily: 'Open Sans',
    fontSize: 24,
    color: grey.medium,
  },
  subHeaderText: {
    fontFamily: 'Open Sans',
    fontSize: 14,
    color: grey.medium,
  },
  roleHeader: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingTop: 34,
    paddingBottom: 15,
  },
  roleSubHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  addRole: {
    position: 'fixed',
    right: 50,
    bottom: 50,
    zIndex: 1000,
    backgroundColor: primary.lightBlue,
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
}));

const searchFilters = [
  'usuario.apellidoUsuario',
  'usuario.nombreUsuario',
  'usuario.username',
  'rolUsuario.descripcion',
  'localizacion.descripcion',
  'localizacion.cueAnexo',
  'nivel.descripcionNivel',
];

export interface RolesLocations {
  localizacion: Localizacion;
  roles: UsuarioRolEstablecimientoArea[];
  pendientes: AutoRegistracion[];
}

const HomeRaw = ({ libby }: LibbyObject) => {
  useRouteScreenTitle('Roles');
  const classes = homeStyles();
  const { setProfile } = useProfileContext();
  const autoRegistracionDAO = useAutoRegistracionDAO();
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState('');
  const [hasDefaultPassword, setHasDefaultPassword] = useState<
    boolean | undefined
  >(undefined);
  const reloginDAO = useReloginDAO();
  const history = useHistory();

  const searchDebounced = useDebounce(search, 1500);

  const onSearchChange = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setSearch(target.value);
  };

  const {
    request,
    data = [],
    working: workingCreateCuenta,
  } = useCreateCuentaAndPersonaPostRequest();

  const { data: roleUserStudent, working: workingStudent } =
    useUsuarioRolEstablecimientoLibbyCall({
      methodName: 'getUsuarioRolStudent',
      params: [libby.session.user.id],
      aspect: 'home',
    });

  useEffect(() => {
    if (roleUserStudent?.length && workingStudent) {
      (async () => {
        const id = roleUserStudent[0].idRolEstablecimiento;
        const res = await reloginDAO.selectRole(id);
        const user = { ...res.user, isGuest: res.isGuest };
        // FIXME: esto es por algo que le falta a libby, quitarlo cuando libby logre reaccioner automaticamente a los cambios de tokens
        RESTConnection.defineHeader('x-chino-token', res.token);
        libby.session.notify('New session', user, res.token);
      })();
    }
  }, [roleUserStudent, reloginDAO, libby.session, workingStudent]);

  const {
    data: rolesUser = [],
    working,
    reFetch,
    fetchMore,
  } = useFillRoleUser(libby, searchDebounced);

  const openModalChangePassword = async () => {
    const dataModal: any = await customFormDialog.show({
      title: 'Cambio de contraseña',
      renderComponent: (
        <Grid>
          <CambioPasswordModal />
        </Grid>
      ),
      sizeWidth: 'md',
      onCancel: undefined,
    });
    if (Boolean(dataModal)) {
      const result: any = await request({
        updatePassword: true,
        password: dataModal?.password,
        email: libby?.session?.user?.username,
      });
      if (result?.showSnackbar) {
        enqueueSnackbar(result?.text, {
          variant: 'success',
        });
        setHasDefaultPassword(false);
      }
    }
  };

  const doRequest = async () => {
    const result: any = await request({
      hasDefaultPassword: true,
      email: libby?.session?.user?.username,
    });

    setHasDefaultPassword(result?.hasDefaultPassword);
  };

  useEffect(() => {
    doRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libby?.session?.user?.username, request]);

  useEffect(() => {
    if (hasDefaultPassword) {
      openModalChangePassword();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasDefaultPassword]);

  const filterRol = useMemo(
    () => ({
      usuario: [{ path: 'usuario.idUsuario', value: libby.session.user.id }],
      rol: [{ path: 'rolUsuario.idRolUsuario', value: 3 }],
      rolStatus: [
        { path: 'rolStatus.idRolStatus', value: ROL_STATUS.APROBADO },
      ],
    }),
    [libby],
  );

  const filterReferente = useMemo(
    () => ({
      0: Boolean(libby.session.user.username)
        ? [{ path: 'referente.email', value: libby.session.user.username }]
        : [],
    }),
    [libby.session.user.username],
  );

  const { data: Organizaciones = [], working: workingOrganizaciones } =
    useOrganizacionesLibbyFetch({
      filter: filterReferente,
      aspect: 'default',
    });
  const { data: OfertasGrupos = [], working: workingOfertasGrupos } =
    useOfertasGruposLibbyFetch({
      filter: filterReferente,
      aspect: 'check-referentes',
    });
  const referenteAcapCheck = Organizaciones.length + OfertasGrupos.length > 0;
  const loadingReferente = workingOrganizaciones || workingOfertasGrupos;

  const rolesByReferente = useMemo(() => {
    let roles: AnyObject[] = [];
    if (Organizaciones.length > 0)
      roles = [
        ...roles,
        {
          rolUsuario: {
            idRolUsuario: 'referente-organizacion',
            descripcion: 'Referente de organización',
          },
        },
      ];
    if (OfertasGrupos.length > 0)
      roles = [
        ...roles,
        {
          rolUsuario: {
            idRolUsuario: 'referente-accion',
            descripcion: 'Referente de acción',
          },
        },
      ];
    return roles;
  }, [Organizaciones, OfertasGrupos]);

  const {
    data: autoregister = [],
    working: autoregisterWorking,
    fetchMore: autoFetchMore,
  } = useAutoRegistracionLibbyFetch({
    filter: filterRol,
    aspect: 'limit_columns',
    orderBy: 'localizacion.idLocalizacion',
    limit: 100,
  });

  const filterRoles = useMemo(
    () => ({
      usuario: [{ path: 'usuario.idUsuario', value: libby.session.user.id }],
      rolStatus: [
        { path: 'rolStatus.idRolStatus', value: ROL_STATUS.PENDIENTE },
      ],
      search: !searchDebounced
        ? []
        : searchFilters.map((path) => ({
            path,
            value: searchDebounced,
            method: 'includes',
          })),
    }),
    [libby, searchDebounced],
  );

  const {
    data: solicitudesPendientes = [],
    working: solicitudesPendientesWorking,
    reFetch: reFetchSolicitudes,
    fetchMore: registerFetchMore,
  } = useAutoRegistracionLibbyFetch({
    filter: filterRoles,
    aspect: 'limit_columns',
    orderBy: 'localizacion.idLocalizacion',
    limit: 100,
  });

  const depuredSolicitudes = useMemo<AutoRegistracion[]>(
    () =>
      solicitudesPendientes.reduce<AutoRegistracion[]>((acum, solicitud) => {
        if (
          !acum.find(
            (item) =>
              item?.localizacion?.idLocalizacion ===
                solicitud?.localizacion?.idLocalizacion &&
              item?.nivel?.idNivel === solicitud?.nivel?.idNivel &&
              item?.rolUsuario?.idRolUsuario ===
                solicitud?.rolUsuario?.idRolUsuario,
          )
        ) {
          // Eliminamos las solicitudes del rol, que ya tiene aprovado
          if (
            !rolesUser.find(
              (rol) =>
                rol.localizacion.idLocalizacion ===
                  solicitud.localizacion.idLocalizacion &&
                rol.rolUsuario.idRolUsuario ===
                  solicitud.rolUsuario.idRolUsuario &&
                rol.nivel.idNivel === solicitud.nivel.idNivel,
            )
          ) {
            acum.push(solicitud);
          }
        }
        return acum;
      }, []),
    [solicitudesPendientes, rolesUser],
  );

  const { request: removeCard } = useRemoveCardCustomPostRequest();

  const actionButtonsPendingRol = useMemo<
    OptionsModalOption<AutoRegistracion>[]
  >(
    () => [
      {
        label: 'Eliminar',
        onClick: async (pendingRol: AutoRegistracion) => {
          const confirm = await confirmDialog.show({
            title: 'Eliminar solicitud',
            content: `¿Desea eliminar la solicitud del rol '${pendingRol.rolUsuario.descripcion}'?`,
            confirmText: 'Eliminar',
            cancelText: 'Volver',
          });
          if (confirm) {
            try {
              loadingModal.open();
              await autoRegistracionDAO.aspect('limit_localization').save({
                ...pendingRol,
                rolStatus: {
                  idRolStatus: ROL_STATUS.ELIMINADO,
                },
              });
              enqueueSnackbar('Se ha eliminado la solicitud con exito', {
                variant: 'success',
              });
              reFetch();
              reFetchSolicitudes();
            } catch (e) {
              console.log(e);
              enqueueSnackbar(
                'Ha ocurrido un error al intentar eliminar la solicitud',
                {
                  variant: 'error',
                },
              );
            } finally {
              loadingModal.close();
            }
          }
        },
      },
    ],
    [enqueueSnackbar, reFetch, reFetchSolicitudes, autoRegistracionDAO],
  );

  const actionButtons = useMemo<
    OptionsModalOption<UsuarioRolEstablecimiento>[]
  >(
    () => [
      {
        label: 'Eliminar',
        onClick: async (row: UsuarioRolEstablecimiento) => {
          const confirm = await confirmDialog.show({
            title: 'Confirmación de Eliminación',
            content: `¿Desea eliminar su rol ${row.rolUsuario.descripcion}?`,
            confirmText: 'Eliminar',
            cancelText: 'Cancelar',
          });
          if (confirm) {
            try {
              loadingModal.open();
              await removeCard({
                idRolEstablecimiento: row.idRolEstablecimiento,
              });
              enqueueSnackbar('Se ha rechazado con exito', {
                variant: 'success',
              });
              reFetch();
              reFetchSolicitudes();
            } catch (e) {
              console.log(e);
              enqueueSnackbar('Ha ocurrido un error al intentar eliminar', {
                variant: 'error',
              });
            } finally {
              loadingModal.close();
            }
          }
        },
      },
    ],
    [enqueueSnackbar, reFetch, reFetchSolicitudes, removeCard],
  );

  // TODO: esto es dificil de entender, refactorizar
  const rolesBySchool: RolesLocations[] = useMemo(() => {
    const data = rolesUser.reduce<RolesLocations[]>((acumn, rol) => {
      const id = rol.localizacion.idLocalizacion;
      const areas = autoregister
        .filter(
          (_rol) =>
            _rol.localizacion.idLocalizacion === id &&
            _rol.rolUsuario.idRolUsuario === rol.rolUsuario.idRolUsuario,
        )
        .map((_item) => _item.area);
      if (acumn.find((item) => id === item.localizacion.idLocalizacion)) {
        acumn
          .find((item) => id === item.localizacion.idLocalizacion)
          ?.roles.push({ ...rol, areas });
      } else {
        const pendientes = depuredSolicitudes.filter(
          (item) => item.localizacion.idLocalizacion === id,
        );
        acumn.push({
          localizacion: { ...rol.localizacion },
          roles: [{ ...rol, areas }],
          pendientes,
        });
      }
      return acumn;
    }, []);
    depuredSolicitudes.forEach((solicitud) => {
      // agregamos las solicitudes pendientes de localizacion no existentes en sus roles
      if (
        !data.find(
          (item) =>
            item.localizacion.idLocalizacion ===
            solicitud.localizacion.idLocalizacion,
        )
      ) {
        const pendientes = depuredSolicitudes.filter(
          (_item) =>
            _item.localizacion.idLocalizacion ===
            solicitud.localizacion.idLocalizacion,
        );
        data.push({
          localizacion: { ...solicitud.localizacion },
          roles: [],
          pendientes,
        });
      }
    });
    return data.sort((a, b) => {
      const idA = a.localizacion.idLocalizacion;
      const idB = b.localizacion.idLocalizacion;
      if (idA > idB) {
        return 1;
      }
      if (idA < idB) {
        return -1;
      }
      return 0;
    });
  }, [rolesUser, autoregister, depuredSolicitudes]);

  const loading =
    autoregisterWorking || working || solicitudesPendientesWorking;
  const [lastDogHeith, setLastLastDogHeith] = useState(0);

  const isBottom = useCallback(
    (threehold = 0) => {
      const windowHeight =
        'innerHeight' in window
          ? window.innerHeight
          : document.documentElement.offsetHeight;
      const { body } = document;
      const html = document.documentElement;
      const docHeight = Math.max(
        body.scrollHeight,
        body.offsetHeight,
        html.clientHeight,
        html.scrollHeight,
        html.offsetHeight,
      );
      const windowBottom = windowHeight + window.pageYOffset + threehold;
      if (windowBottom >= docHeight) {
        if (lastDogHeith <= docHeight) {
          setLastLastDogHeith(docHeight);
        }
      }
      return windowBottom >= docHeight;
    },
    [lastDogHeith],
  );

  const handleScroll = useCallback(() => {
    const bottom = isBottom(100);
    if (bottom && fetchMore) {
      fetchMore();
      autoFetchMore();
      registerFetchMore();
    }
  }, [fetchMore, autoFetchMore, registerFetchMore, isBottom]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  // Those roles shouldn't be shown as cards. (Access via side menu or similar)
  const NonCardRoles = [ROL.EQUIPO_ACAP];

  return loadingReferente || loading ? (
    <Grid container justifyContent="center">
      <Loading width={70} />
    </Grid>
  ) : referenteAcapCheck ? (
    <div className={classes.cardContainer}>
      {rolesByReferente.map((rol) => {
        return (
          <CardItem
            item={rol}
            handleClick={() =>
              history.push(`/establecimientos/${rol.rolUsuario.idRolUsuario}`)
            }
          />
        );
      })}
    </div>
  ) : (
    <div>
      <MainInput
        customStyle={{ width: 300, marginRight: 25 }}
        type="search"
        handleChange={onSearchChange}
        placeholder="Buscar"
        name="search"
        value={search}
        fullWidth
      />
      <Grid>
        {rolesBySchool.map((roleSchool: RolesLocations, index: number) => (
          <div key={index}>
            {roleSchool.localizacion.idLocalizacion !== '-1' && (
              <>
                <div className={classes.roleHeader}>
                  <span className={classes.headerTitle}>
                    {roleSchool.localizacion.descripcion}
                  </span>
                </div>
                <div className={classes.roleSubHeader}>
                  <span className={classes.subHeaderText}>
                    {` Cue: ${roleSchool.localizacion?.cueAnexo}`}
                  </span>
                  <span className={classes.subHeaderText}>
                    {` Anexo: ${roleSchool.localizacion?.anexo}`}
                  </span>
                </div>
              </>
            )}
            <CardComponent
              content={roleSchool.roles.filter(
                (rol) => !NonCardRoles.includes(rol.rolUsuario.idRolUsuario),
              )}
              handleMenuIcon={actionButtons}
              appendRender={roleSchool.pendientes.map((item) => (
                <CardItemPending
                  key={item.idAutoregistracion}
                  item={item}
                  handleMenuIcon={actionButtonsPendingRol}
                />
              ))}
            />
          </div>
        ))}
      </Grid>
      {loading && (
        <Grid container justifyContent="center">
          <Loading width={70} />
        </Grid>
      )}
      <IconButton
        onClick={() => setProfile('autoregistracion')}
        className={classes.addRole}
      >
        <AddIcon style={{ color: 'white' }} />
      </IconButton>
    </div>
  );
};

// TODO: remove database connector
export const Home = DatabaseConnector(HomeRaw)('usuario_rol_establecimiento');
