import React, { useMemo, useState } from 'react';
import {
  ButtonGroup,
  Grid,
  makeStyles,
  Typography,
  withStyles,
} from '@material-ui/core';
import { DatePicker } from '@material-ui/pickers';
import { Footer, InfoTable, SimpleSelect, typeOfPass } from 'src/commons';
import { accionesColumns } from '../utils';
import moment from 'moment';
import { useRolesContext } from 'src/context/RolesContext';
import { useAnioLibbyFetch, useSeccionLibbyFetch } from 'src/app/business';
import { useContraturnosRow } from '../hooks';
import { useSnackbar } from 'notistack';
import { ContraturnosRow, Filter } from '../Types';
import '../styles/fonts.css';
import { Anio } from 'src/app/models/Anio';
import { EspacioCurricular } from 'src/app/models';

export const AccionContraturnos = ({
  actionContracturno,
  setAccionContraturno,
  filterContraturno,
  setFilterContraturno,
  contraturnosRow,
  espacioCurriculares,
  contraturnoDAO,
  contraturnoDiaDAO,
  reFetchContraturno,
  reFetchContraturnoDia,
  userIdActual,
  seccionesECS,
  fechInit: dateMinDesde,
}: any) => {
  const [disabledButton, setDisabledButton] = useState(false);

  const fontClases = {
    fontNunito: {
      fontFamily: 'Nunito',
    },
    formNunitoSemiBold: {
      fontFamily: 'Nunito-SemiBold',
    },
  };

  const useStyles = makeStyles({
    paper: { boxShadow: 'none !important' },
    fieldset: {
      '& .MuiInputBase-root > fieldset': {
        border: 0,
      },
    },
    selectInput: {
      backgroundColor: 'rgb(237 244 252)',
    },

    datePicker: {
      '& .MuiInputBase-root': {
        border: 'none',
        backgroundColor: 'rgb(237 244 252)',
        margin: '0px',
        height: '20px',
      },
      margin: '0px',
    },
    divDatePicker: {
      backgroundColor: 'rgb(237 244 252)',
    },

    table: {
      '& tr:nth-child(odd)': {
        backgroundColor: '#F9F9F9',
      },
      maxHeight: '500px',
    },
    fontNunito: {
      fontFamily: 'Nunito',
    },
    fontNunitoBold: {
      fontFamily: 'Nunito-Bold',
    },
  });

  const classes = useStyles();
  const roleContext = useRolesContext();
  const { enqueueSnackbar } = useSnackbar();
  const { rows } = useContraturnosRow(
    filterContraturno,
    setFilterContraturno,
    espacioCurriculares,
    actionContracturno,
  );
  const { localizacionId, nivel } = roleContext.selectedRole;

  const paramsFetchSeccion = useMemo(
    () => ({
      daoName: 'seccion',
      filter: {
        0: [{ path: 'localizacion', value: localizacionId }],
      },
      orderBy: 'nombreSeccion',
    }),
    [localizacionId],
  );
  const { data: secciones = [], working: seccionWorking } =
    useSeccionLibbyFetch(paramsFetchSeccion);
  const filterAnioByNivel = useMemo(
    () => ({
      nivel: [{ path: 'nivel.idNivel', value: nivel || -1 }],
    }),
    [nivel],
  );
  const { data: anio, working: anioWorking } = useAnioLibbyFetch({
    filter: filterAnioByNivel,
    limit: 200,
    orderBy: 'descripcionAnio',
  });

  function extraerAnios(espaciosCurriculares: EspacioCurricular[]) {
    const anios: any = [];
    const aniosUnicos: any = [];

    // Extraer todos los objetos anio
    espaciosCurriculares.forEach((espacioCurricular) => {
      const anio = espacioCurricular.materia.anio;
      anios.push(anio);
    });

    // Quitar duplicados por idAnio
    anios.forEach((anio: Anio) => {
      if (
        !aniosUnicos.some((anioUnico: Anio) => anioUnico.idAnio === anio.idAnio)
      ) {
        aniosUnicos.push(anio);
      }
    });

    //Ordena
    aniosUnicos.sort((a: Anio, b: Anio) => a.numeroAnio - b.numeroAnio);

    return aniosUnicos;
  }

  const anios = extraerAnios(espacioCurriculares);

  const SeccionesSort = seccionesECS.sort((a: any, b: any) =>
    a.nombreSeccion.localeCompare(b.nombreSeccion),
  );

  const seccionesPorAnio = seccionesECS.filter(
    (item: any) => item.anio.idAnio === filterContraturno.anio,
  );

  const handleChangeSeccion = (e: any) => {
    //quitar doble validacion
    const value = e?.target?.value;
    const result = SeccionesSort.find((seccion: any) => {
      if (seccion.idSeccion === value) {
        return seccion;
      }
    });
    if (result)
      setFilterContraturno({
        ...filterContraturno,
        idSeccion: result,
        seccion: result.idSeccion,
      });
  };

  const yearFromFechaFin = filterContraturno.fechaFin
    ? moment(filterContraturno.fechaFin).format('YYYY')
    : moment().format('YYYY');

  const dateMax = new Date(`12/01/${yearFromFechaFin}`);
  const dateMaxDesde = filterContraturno.fechaFin
    ? filterContraturno.fechaFin
    : new Date(`12/01/${yearFromFechaFin}`);
  const dateMin = filterContraturno.fechaInicio;

  const validateFecha = (
    contraturnosRow: ContraturnosRow[],
    filterContraturno: Filter,
  ): boolean => {
    const seccionId = filterContraturno?.idSeccion?.idSeccion;
    const fechaInicioFilter = moment(filterContraturno.fechaInicio).format(
      'YYYY-MM-DD',
    );
    const fechaFinFilter = moment(filterContraturno.fechaFin).format(
      'YYYY-MM-DD',
    );

    let contraturnosSeccion = contraturnosRow.filter(
      (c) => c.idSeccion.idSeccion === seccionId,
    );

    if (!actionContracturno.nuevoContraturno) {
      contraturnosSeccion = contraturnosSeccion.filter(
        (e) => e.idcontraturno !== filterContraturno.idcontraturno,
      );
    }

    let encontrado = false;
    for (const contraturno of contraturnosSeccion) {
      const fechaInicioContraturno = moment(contraturno.fechaInicio).format(
        'YYYY-MM-DD',
      );
      const fechaFinContraturno = moment(contraturno.fechaFin).format(
        'YYYY-MM-DD',
      );

      if (
        fechaInicioContraturno <= fechaFinFilter &&
        fechaFinContraturno >= fechaInicioFilter
      ) {
        encontrado = true;
        break;
      }
    }

    return encontrado;
  };

  const existeEdit = Object.values(filterContraturno.objContraturnoDia).some(
    (dia: any) => dia.edit === true,
  );

  const validate = validateFecha(contraturnosRow, filterContraturno);

  const isEdit = Object.values(filterContraturno.objContraturnoDia).some(
    (dia: any) => dia.edit,
  );

  const valueObj = Object.values(filterContraturno.objContraturnoDia);

  const validateCheked = valueObj.some((dia: any) => dia.checked);

  const validarCantidad = (data: any) => {
    let valid = true;

    for (let i = 0; i < data.length; i++) {
      const obj = data[i];
      if (
        (obj.checked && obj.cantidad === 0) ||
        (obj.checked && !obj.cantidad)
      ) {
        valid = false;
        break;
      } else if (obj.cantidad === 1 && !obj.esc1) {
        valid = false;
        break;
      } else if (obj.cantidad === 2 && (!obj.esc1 || !obj.esc2)) {
        valid = false;
        break;
      }
    }

    return valid;
  };

  const dayMap: any = {
    Lunes: 1,
    Martes: 2,
    Miercoles: 3,
    Jueves: 4,
    Viernes: 5,
  };

  const buttonConfig: any = [
    {
      title: 'Cancelar',
      handleOnClick: () => {
        setAccionContraturno({ openModalAction: false, presentismo: false });
        setFilterContraturno({});
      },
      size: 'medium',
    },
    {
      title: 'Guardar',
      handleOnClick: async () => {
        setDisabledButton(true);
        try {
          const contraturnoidDAO = await contraturnoDAO.save({
            idContraturno: filterContraturno.idcontraturno || null,
            seccionCustom: filterContraturno.idSeccion.idSeccion,
            fechaInicio: filterContraturno.fechaInicio,
            fechaFin: filterContraturno.fechaFin,
            cicloLectivo:
              filterContraturno.idSeccion.cicloLectivo.idCicloLectivo,
            active: true,
            createdAt: new Date(),
            updatedBy: filterContraturno.idcontraturno ? userIdActual : null,
          });

          if (existeEdit) {
            const respuestaContraturnoFiltrada =
              await contraturnoDiaDAO.getContraturnoDiaByIdContraturno(
                contraturnoidDAO.idContraturno,
              );

            const mapSaveContraturnoDia = respuestaContraturnoFiltrada?.map(
              (item: any) => ({
                idContraturnoDia: item.idContraturnoDia,
                active: false,
              }),
            );

            if (mapSaveContraturnoDia.length > 0) {
              await contraturnoDiaDAO
                .aspect('ContraturnoDiaAsp')
                .save(mapSaveContraturnoDia);
            }

            const results: Array<any> = [];
            for (const dia in filterContraturno.objContraturnoDia) {
              const obj = filterContraturno.objContraturnoDia[dia];
              if (obj.esc1 || obj.esc2) {
                if (obj.esc1) {
                  results.push({
                    contraturno: contraturnoidDAO.idContraturno,
                    dia: dayMap[dia],
                    EspacioCurricular: {
                      idEspacioCurricular: obj.esc1.idEspacioCurricular,
                    },
                    active: obj.esc1.activo,
                  });
                }
                if (obj.esc2) {
                  results.push({
                    contraturno: contraturnoidDAO.idContraturno,
                    dia: dayMap[dia],
                    EspacioCurricular: {
                      idEspacioCurricular: obj.esc2.idEspacioCurricular,
                    },
                    active: obj.esc2.activo,
                  });
                }
              }
            }

            const a = await contraturnoDiaDAO
              .aspect('ContraturnoDiaAsp')
              .save(results);
          }
          reFetchContraturnoDia();
          reFetchContraturno();
          setAccionContraturno({ openModalAction: false });
          setFilterContraturno({});
          enqueueSnackbar('Se ha guardado con exito', {
            variant: 'success',
          });
        } catch (e) {
          console.log(e);
          enqueueSnackbar(
            'Error al guardar, favor de ingresar la seccion correspondiente',
            {
              variant: 'error',
            },
          );
        }
      },

      size: 'medium',
      disabled:
        validate ||
        disabledButton ||
        !(filterContraturno.editContraurno || isEdit) ||
        !Boolean(filterContraturno.idSeccion) ||
        !validarCantidad(valueObj) ||
        (actionContracturno.nuevoContraturno && !validateCheked),
    },
  ];

  return (
    <>
      <Typography
        variant="h2"
        style={{
          marginTop: 15,
          marginBottom: 15,
          color: 'rgb(0,0,0)',
          fontSize: '30px',
        }}
        className={classes.fontNunito}
      >
        {actionContracturno.title}
      </Typography>

      <Grid container xs={12}>
        <Grid item xs={11} container spacing={4}>
          <Grid item xs={2}>
            <SimpleSelect
              value={filterContraturno.anio}
              title={'Año'}
              content={anios}
              labelKey="descripcionAnio"
              valueKey="idAnio"
              placeholder="Año"
              handleChange={(e: any) => {
                const value = e?.target?.value;
                setFilterContraturno({
                  ...filterContraturno,
                  anio: value,
                });
              }}
              disabled={anioWorking || actionContracturno.disableSelectAction}
              customClassNameFormControl={classes.fieldset}
              selectClassName={classes.selectInput}
              customStyleTitle={fontClases.formNunitoSemiBold}
              placeholderStyle={fontClases.fontNunito}
            />
          </Grid>
          <Grid item xs={3}>
            <SimpleSelect
              name={'Seccion'}
              title={'sección'}
              content={
                filterContraturno.anio ? seccionesPorAnio : SeccionesSort
              }
              value={filterContraturno?.idSeccion?.idSeccion}
              valueKey="idSeccion"
              labelKey="nombreSeccion"
              placeholder="Todos"
              handleChange={handleChangeSeccion}
              disabled={
                seccionWorking || actionContracturno.disableSelectAction
              }
              customClassNameFormControl={classes.fieldset}
              selectClassName={classes.selectInput}
              customStyleTitle={fontClases.formNunitoSemiBold}
              placeholderStyle={fontClases.fontNunito}
            />
          </Grid>

          <Grid
            item
            container
            style={{
              paddingLeft: 20,
              width: '25%',
            }}
            spacing={1}
          >
            <Grid item xs={12}>
              <span
                style={{
                  fontFamily: 'Nunito-SemiBold',
                  // color: '#38485C',
                  color: '#737373',
                  fontSize: 14,
                }}
              >
                Fecha de contraturno
              </span>
            </Grid>
            <Grid
              container
              style={{
                height: '38px',
              }}
              spacing={0}
              direction="row"
            >
              <Grid
                item
                xs={5}
                style={{
                  backgroundColor: 'rgb(237 244 252)',
                  width: '50%',
                }}
              >
                <p
                  style={{
                    backgroundColor: 'rgb(237 244 252)',
                    fontFamily: 'Nunito-medium',
                    fontSize: '12px',
                    marginTop: 0,
                    marginLeft: '5px',
                    marginBottom: 0,
                    color: '#9FA1A4',
                  }}
                >
                  Desde
                </p>
                <DatePicker
                  value={filterContraturno.fechaInicio}
                  onChange={(_date) => {
                    setFilterContraturno({
                      ...filterContraturno,
                      fechaInicio: _date ? _date.toDate() : null,
                      editContraurno: true,
                    });
                  }}
                  minDate={dateMinDesde}
                  maxDate={dateMaxDesde}
                  placeholder="Inicio"
                  format={'DD/MM/YY'}
                  clearable={false}
                  error={validate}
                  className={`${classes.datePicker} ${classes.divDatePicker}`}
                  disabled={actionContracturno.presentismo}
                  minDateMessage={''}
                  maxDateMessage={''}
                />
              </Grid>
              <Grid
                xs={1}
                style={{
                  backgroundColor: 'rgb(237 244 252)',
                  paddingTop: '10px',
                  fontSize: '18px',
                }}
              >
                <span>|</span>
              </Grid>
              <Grid
                item
                xs={5}
                style={{
                  marginLeft: '-15px',
                  // paddingTop: '10px',
                  // paddingBottom: '-10px',
                  backgroundColor: 'rgb(237 244 252)',
                }}
              >
                <p
                  style={{
                    backgroundColor: 'rgb(237 244 252)',
                    fontFamily: 'Nunito-medium',
                    fontSize: 12,
                    marginTop: 0,
                    marginLeft: '5px',
                    marginBottom: 0,
                    color: '#9FA1A4',
                  }}
                >
                  Hasta
                </p>
                <DatePicker
                  value={filterContraturno.fechaFin}
                  onChange={(_date) => {
                    setFilterContraturno({
                      ...filterContraturno,
                      fechaFin: _date ? _date.toDate() : null,
                      editContraurno: true,
                    });
                  }}
                  minDate={dateMin}
                  maxDate={dateMax}
                  placeholder="Inicio"
                  format={'DD/MM/YY'}
                  clearable={false}
                  error={validate}
                  minDateMessage={''}
                  maxDateMessage={''}
                  // container={CustomContainer}
                  className={`${classes.datePicker} ${classes.divDatePicker}`}
                />
              </Grid>
            </Grid>
          </Grid>

          {validate ? (
            <p
              style={{
                fontFamily: 'Nunito-SemiBold',
                marginTop: 60,
                marginLeft: 10,
                color: 'red',
                fontSize: 13,
              }}
            >
              La fecha seleccionada ya contiene un contraturno
            </p>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Grid container>
        <InfoTable
          rows={rows}
          columns={accionesColumns}
          rowIdKey="diasContraturno"
          customStyle={classes.table}
        />
      </Grid>
      <Footer buttonConfig={buttonConfig} />
    </>
  );
};
