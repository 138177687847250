import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { PlanSocial } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'plan_social';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPlanSocial',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPlanSocial',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.PlanSocial
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.PlanSocial,
      hoc: { ...defaultOptions.hoc, ...businessOptions.PlanSocial.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<PlanSocial>(DAO_NAME, optionsToUse);

// hooks
export const usePlanSocialFetchById = fetchById;
export const usePlanSocialLibbyCall = libbyCall;
export const usePlanSocialDAO = libbyDAO;
export const usePlanSocialLibbyFetch = libbyFetch;

// components/hoc
export const PlanSocialAutocomplete = entityAutocomplete;
export const PlanSocialCall = renderPropCall;
export const PlanSocialById = renderPropFetchById;
export const PlanSocialSelect = simpleSelect;
export const PlanSocialAccordionSelector = accordionSelector;
export const PlanSocialSelectedChips = selectedChips;

// context
// ByIdContext
export const usePlanSocialByIdContext = useByIdContext;
export const PlanSocialByIdProvider = ByIdProvider;
export const PlanSocialByIdContext = ByIdContext;
// GeneralContext
export const usePlanSocialContext = useGeneralContext;
export const PlanSocialProvider = GeneralProvider;
export const PlanSocialContext = GeneralContext;
