import bibliotecaDigital from './Biblioteca_digital.png';
import apoyoEscolar from './Apoyo_escolar.png';
import escuelaDeMaestros from './Escuela_de_maestros.png';
import recursos from './Recursos.png';
import mesLectura from './Mes_lectura.png';
import ciudadaniaGlobal from './Ciudadania_global.png';

const loginCards = {
  bibliotecaDigital,
  apoyoEscolar,
  escuelaDeMaestros,
  recursos,
  mesLectura,
  ciudadaniaGlobal,
};

export { loginCards };
