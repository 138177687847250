import React, {
  createContext,
  useReducer,
  useContext,
  useMemo,
  useEffect,
} from 'react';
import { useFetch } from 'src/lib/libby/hooks/useSimpleFetchRequest';
import { generalReducer, initialState } from 'src/reducers';
import {
  changeNewUser,
  selectPlanification,
  selectPlanificationAnio,
  selectCicloLectivo,
  selectConstantes,
  valuesChanged,
  deudasAcademicasChanged,
  date,
  printPlanificacion,
  setLegajoCicloLectivo,
} from './actions';

interface GeneralContextProps {
  children: JSX.Element;
}

// Simple utility function to return a callable function of two functions chained, keeping types.
// So, d: (a) => b(c(a)) becomes d: Chainer(b,c)
// Arguments names are due to call order, not argument order.
function Chainer<T, B>(second: (a: T) => void, first: (a: B) => T) {
  return function (param: B) {
    return second(first(param));
  };
}

// Changes function return type to void. To use below.
type VoidReturn<T extends (...args: any) => any> = (
  ...p: Parameters<T>
) => void;

type ContextType = {
  generalState: typeof initialState;
  dispatch: {
    selectPlanification: VoidReturn<typeof selectPlanification>;
    selectPlanificationAnio: VoidReturn<typeof selectPlanificationAnio>;
    changeNewUser: VoidReturn<typeof changeNewUser>;
    selectCicloLectivo: VoidReturn<typeof selectCicloLectivo>;
    selectConstantes: VoidReturn<typeof selectConstantes>;
    valuesChanged: VoidReturn<typeof valuesChanged>;
    deudasAcademicasChanged: VoidReturn<typeof deudasAcademicasChanged>;
    date: VoidReturn<typeof date>;
    setLegajoCicloLectivo: VoidReturn<typeof setLegajoCicloLectivo>;
    printPlanificacion: VoidReturn<typeof printPlanificacion>;
  };
};

export const GeneralContextProvider = ({ children }: GeneralContextProps) => {
  const [generalState, dispatcher] = useReducer(generalReducer, initialState);

  const dispatch = useMemo<ContextType['dispatch']>(
    () => ({
      selectPlanification: Chainer(dispatcher, selectPlanification),
      selectPlanificationAnio: Chainer(dispatcher, selectPlanificationAnio),
      changeNewUser: Chainer(dispatcher, changeNewUser),
      selectCicloLectivo: Chainer(dispatcher, selectCicloLectivo),
      selectConstantes: Chainer(dispatcher, selectConstantes),
      valuesChanged: Chainer(dispatcher, valuesChanged),
      deudasAcademicasChanged: Chainer(dispatcher, deudasAcademicasChanged),
      date: Chainer(dispatcher, date),
      setLegajoCicloLectivo: Chainer(dispatcher, setLegajoCicloLectivo),
      printPlanificacion: Chainer(dispatcher, printPlanificacion),
    }),
    [dispatcher],
  );

  const contextValue = useMemo<ContextType>(
    () => ({ generalState, dispatch }),
    [generalState, dispatch],
  );

  const { data: constantes = {}, responseHeaders } = useFetch<
    Parameters<typeof selectConstantes>[0]
  >({
    url: `/api/public/constantes`,
    autoCall: true,
  });

  const dateFromServer = responseHeaders?.get('Date');

  useEffect(() => {
    if (Object.keys(constantes).length) {
      dispatch.selectConstantes(constantes);
      dispatch.date(dateFromServer);
    }
  }, [constantes, dateFromServer, dispatch, responseHeaders]);

  return (
    <GeneralContext.Provider value={contextValue}>
      {children}
    </GeneralContext.Provider>
  );
};

// @ts-ignore
const GeneralContext = createContext<ContextType>({});

export const useGeneralContext = () => useContext<ContextType>(GeneralContext);
