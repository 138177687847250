import React, { useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router';
import { MainButton } from '../../../../../../../commons/components/MainButton';
import { MainInput } from '../../../../../../../commons/components/MainInput';
import { FiltersLogicReturn } from '../hooks';

export type ToolbarProps = FiltersLogicReturn;

export const Toolbar = ({ search, onSearchChange }: ToolbarProps) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const onNewClick = useCallback(() => {
    history.push(`${url}/form/new`);
  }, [history, url]);
  return (
    <Grid container spacing={1} justify="flex-end">
      <Grid item>
        <MainButton title="Agregar recurso" handleOnClick={onNewClick} />
      </Grid>
      <Grid item>
        <MainInput
          type="search"
          handleChange={onSearchChange}
          placeholder="Buscar"
          name="search"
          value={search}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
