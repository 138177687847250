import React, { FunctionComponent, useState, useEffect } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Footer } from 'src/commons/components/Footer';
import customFormDialog from 'src/commons/services/customFormDialog';
import { SimpleSelect } from 'src/commons';

interface FormGestionRolesDialogProps {
  userRoles: any;
  selectedUserRoles: any;
}

enum ACTION_TYPE {
  SAVE = 1,
  REMOVE = 2,
}

const arrSelect = [
  {
    name: 'APROBAR',
    value: ACTION_TYPE.SAVE,
  },
  {
    name: 'RECHAZAR',
    value: ACTION_TYPE.REMOVE,
  },
];

const useStyles = makeStyles(() => ({
  container: {
    padding: '0 20px',
  },
  typography: {
    paddingBottom: '10px',
  },
}));

export const FormGestionRolesDialog = ({
  userRoles,
  selectedUserRoles,
}: FormGestionRolesDialogProps) => {
  const classes = useStyles();
  const [value, setValue] = useState('');
  const [users, setUsers] = useState<string[]>([]);
  const dialogButtonConfig = [
    {
      title: 'Cancelar',
      handleOnClick: () => customFormDialog.handleCancel(),
      type: 'secondary',
      size: 'small',
    },
    {
      title: 'Aplicar',
      handleOnClick: () => customFormDialog.handleConfirm(value),
      size: 'small',
      disabled: !value,
    },
  ];

  useEffect(() => {
    if (users.length === 0) {
      const usersArray: string[] = [];
      userRoles.forEach((user: any) => {
        const response = selectedUserRoles.find(
          (element: any) => element === user.idAutoregistracion,
        );
        if (response === undefined) {
          return;
        } else {
          const nameAndSurname =
            user.usuario.apellidoUsuario + ' ' + user.usuario.nombreUsuario;
          usersArray.push(nameAndSurname);
        }
      });
      setUsers(usersArray);
    }
  }, [selectedUserRoles, userRoles, users]);

  return (
    <Grid
      container
      direction="column"
      justify="center"
      className={classes.container}
    >
      <Grid item xs>
        <Typography className={classes.typography}>
          Estás gestionando los roles para los siguientes usuarios:{' '}
          {users.join(', ')}
        </Typography>
        <SimpleSelect
          value={value}
          handleChange={(e: any) => setValue(e.target.value)}
          placeholder="Seleccioná la operación"
          content={arrSelect}
          labelKey="name"
          valueKey="value"
        />
      </Grid>
      <Footer buttonConfig={dialogButtonConfig} spacing={1} />
    </Grid>
  );
};
