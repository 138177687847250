import {
  useDevolucionContenidosDAO,
  useDevolucionHabilidadesDAO,
  useDevolucionPerfilesDAO,
  useDevolucionesEvaluacionesDAO,
} from 'src/app/business/acap';
import { InitialValue } from '../types';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { useEscuelaContext } from '../context/EscuelaContext';

/***
 * Hook encargado de cuardar la evalucacion del docente
 * Cuando idAutoevaluacionPerfil, idAutoevaluacionHabilidad y idAutoevaluacionContenido  es igual a 0
 * es un recurso nuevo, ya que para ese punto no tiene una devolucion
 */
export const useEvaluacionSubmit = () => {
  const devPerfilDAO = useDevolucionPerfilesDAO();
  const devHabilidadesDAO = useDevolucionHabilidadesDAO();
  const devContenidosDAO = useDevolucionContenidosDAO();
  const evaluacionesDevDAO = useDevolucionesEvaluacionesDAO();
  const { enqueueSnackbar } = useSnackbar();
  const { nextStudent, reFecthEvaluaciones } = useEscuelaContext();
  const [loading, setLoading] = useState(false);

  const handleSubmit = useCallback(
    async (values: InitialValue) => {
      try {
        setLoading(true);
        const devPerfilesSaved = await devPerfilDAO.aspect('basic').save({
          ...(values.perfiles.idAutoevaluacionPerfil.toString() !== '0'
            ? { idAutoevaluacionPerfil: values.perfiles.idAutoevaluacionPerfil }
            : {}),
          observaciones: values.perfiles.observaciones ?? '',
          observacionesReferente: values.perfiles.observacionesReferente ?? '',
          grupoInscripcion: values.grupoInscripcion,
        });
        await evaluacionesDevDAO.save(
          values.perfiles.valores.map((evaluacionDev) => ({
            ...evaluacionDev,
            idDevolucion: devPerfilesSaved.idAutoevaluacionPerfil,
          })),
        );

        const devHabSaved = await devHabilidadesDAO.aspect('basic').save({
          ...(values.habilidades.idAutoevaluacionHabilidad.toString() !== '0'
            ? {
                idAutoevaluacionHabilidad:
                  values.habilidades.idAutoevaluacionHabilidad,
              }
            : {}),
          observaciones: values.habilidades.observaciones ?? '',
          observacionesReferente:
            values.habilidades.observacionesReferente ?? '',
          grupoInscripcion: values.grupoInscripcion!,
        });
        await evaluacionesDevDAO.save(
          values.habilidades.valores.map((evaluacion) => ({
            ...evaluacion,
            idDevolucion: devHabSaved.idAutoevaluacionHabilidad,
          })),
        );
        const devContenidoSaved = await devContenidosDAO.aspect('basic').save({
          ...(values.contenidos.idAutoevaluacionContenido.toString() !== '0'
            ? {
                idAutoevaluacionContenido:
                  values.contenidos.idAutoevaluacionContenido,
              }
            : {}),
          observaciones: values.contenidos.observaciones ?? '',
          observacionesReferente:
            values.contenidos.observacionesReferente ?? '',
          grupoInscripcion: values.grupoInscripcion!,
        });
        await evaluacionesDevDAO.save(
          values.contenidos.valores.map((evaluacion) => ({
            ...evaluacion,
            idDevolucion: devContenidoSaved.idAutoevaluacionContenido,
          })),
        );
        enqueueSnackbar('Se guardó la evaluación', { variant: 'success' });
        if (values.tab) {
          nextStudent();
        } else {
          reFecthEvaluaciones();
        }
      } catch (error) {
        enqueueSnackbar('No se pudo guardar la evaluación', {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [
      enqueueSnackbar,
      nextStudent,
      reFecthEvaluaciones,
      devPerfilDAO,
      devContenidosDAO,
      devHabilidadesDAO,
      evaluacionesDevDAO,
    ],
  );

  return { handleSubmit, loading };
};
