import React, { useMemo } from 'react';
import { useSeccionContext } from 'src/app/business';
import { Grid } from '@material-ui/core';
import { EspaciosSimpDiscipPlan } from 'src/app/models';
import { HeaderFeedback } from '../../../feedbacks/commons/components/FeedbackHeader';

export type DisciplinarComunFlowHeaderProps = {
  data: EspaciosSimpDiscipPlan;
  toPrint?: boolean;
};

export const DisciplinarComunFlowHeader = ({
  data,
  toPrint,
}: DisciplinarComunFlowHeaderProps) => {
  const { data: secciones, working } = useSeccionContext();

  const formateDataHeader = useMemo(
    () => ({
      disciplina: data.espacioCurricular.materia.descripcion,
      area: data.espacioCurricular.materia.area.descripcion,
      orientacion:
        data?.espacioCurricular?.planEstudio?.orientacion
          ?.descripcionOrientacion,
      seccion: '',
      fecha: data.simpDiscipPlan.ultimaActualizacion,
      autor: `${data?.simpDiscipPlan?.cuentas?.nombreUsuario} ${data?.simpDiscipPlan?.cuentas?.apellidoUsuario}`,
      nombre: data.espacioCurricular.descripcion,
      descripcion: '',
    }),
    [
      data.espacioCurricular.materia.area.descripcion,
      data.espacioCurricular.materia.descripcion,
      data.espacioCurricular.descripcion,
      data.espacioCurricular?.planEstudio?.orientacion?.descripcionOrientacion,
      data.simpDiscipPlan?.cuentas?.apellidoUsuario,
      data.simpDiscipPlan?.cuentas?.nombreUsuario,
      data.simpDiscipPlan.ultimaActualizacion,
    ],
  );
  return (
    <Grid container direction="column">
      {!working && (
        <HeaderFeedback data={formateDataHeader} toPrint={toPrint} />
      )}
    </Grid>
  );
};
