import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { AccionOrientacionCustom } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'acciones_orientaciones_custom';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idAccionOrientacion',
      labelKey: 'nombre',
    },
    withSelectedChips: {
      idKey: 'idAccionOrientacion',
      labelKey: 'nombre',
    },
  },
};

const optionsToUse = !businessOptions.AccionesOrientacionesCustom
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.AccionesOrientacionesCustom,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.AccionesOrientacionesCustom.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
    selectInput,
  },
} = makeBusinessEntity<AccionOrientacionCustom>(DAO_NAME, optionsToUse);

// hooks
export const useAccionesOrientacionesCustomFetchById = fetchById;
export const useAccionesOrientacionesCustomLibbyCall = libbyCall;
export const useAccionesOrientacionesCustomDAO = libbyDAO;
export const useAccionesOrientacionesCustomLibbyFetch = libbyFetch;

// components/hoc
export const AccionesOrientacionesCustomAutocomplete = entityAutocomplete;
export const AccionesOrientacionesCustomCall = renderPropCall;
export const AccionesOrientacionesCustomById = renderPropFetchById;
export const AccionesOrientacionesCustomSelect = simpleSelect;
export const AccionesOrientacionesCustomSelectInput = selectInput;
export const AccionesOrientacionesCustomAccordionSelector = accordionSelector;
export const AccionesOrientacionesCustomSelectedChips = selectedChips;
