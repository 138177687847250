import { Template } from '../../../../../lib/templates';

export const rolTemplate: Template = {
  name: 'Rol Template',
  template: [
    {
      name: 'idLocalizacion',
      type: { name: 'number' },
    },
    {
      name: 'idNivel',
      type: { name: 'number' },
    },
    {
      name: 'idRolUsuario',
      type: { name: 'number' },
    },
    {
      name: 'isFuture',
      type: { name: 'boolean' },
    },
  ],
};
