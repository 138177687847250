import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Materia } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'materia';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idMateria',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idMateria',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Materia
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Materia,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Materia.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Materia>(DAO_NAME, optionsToUse);

// hooks
export const useMateriaFetchById = fetchById;
export const useMateriaLibbyCall = libbyCall;
export const useMateriaDAO = libbyDAO;
export const useMateriaLibbyFetch = libbyFetch;

// components/hoc
export const MateriaAutocomplete = entityAutocomplete;
export const MateriaCall = renderPropCall;
export const MateriaById = renderPropFetchById;
export const MateriaSelect = simpleSelect;
export const MateriaAccordionSelector = accordionSelector;
export const MateriaSelectedChips = selectedChips;

// context
// ByIdContext
export const useMateriaByIdContext = useByIdContext;
export const MateriaByIdProvider = ByIdProvider;
export const MateriaByIdContext = ByIdContext;
// GeneralContext
export const useMateriaContext = useGeneralContext;
export const MateriaProvider = GeneralProvider;
export const MateriaContext = GeneralContext;
