import React, { useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AnyObject, Loading } from 'src/commons';
import {
  LocalizacionById,
  EspaciosAreasAutocomplete,
  usePlanEstudioMateriaLibbyFetch,
} from '../../../../../../../../app/business';
import { Establishment } from '../../commons/components/Establishment';
import { Area, EspacioCurricular } from '../../../../../../../../app/models';
import { grey } from '../../../../../../../../theme/colors';

const useStyles = makeStyles({
  tittle: {
    fontSize: 13,
    fontWeight: 700,
    color: grey.medium,
  },
  subtitle: {
    fontSize: 14,
    fontWeight: 400,
    color: grey.medium,
  },
  areasTextField: {
    minWidth: 383,
    height: 40,
  },
});

export type ArealInterarealHeaderProps = {
  localizacionId: string;
  idEspacioCurricular: string;
  areas: Area[];
  onChangeAreas: (newAreas: Area[]) => void;
  espacioCurricular: AnyObject | undefined;
};

export const ArealInterarealHeader = ({
  localizacionId,
  areas,
  espacioCurricular,
  onChangeAreas,
}: ArealInterarealHeaderProps) => {
  const classes = useStyles();

  const filterPlanEstudio = useMemo(
    () => ({
      filter: {
        planEstudio: [
          {
            path: 'planEstudio',
            value: espacioCurricular?.planEstudio.idPlanEstudio || 0,
          },
        ],
        anio: [
          {
            path: 'materia.anio',
            value: espacioCurricular?.materia.anio.idAnio || 0,
          },
        ],
      },
      autoCall: espacioCurricular?.materia.anio.idAnio || 0,
    }),
    [espacioCurricular],
  );

  const { data: planEstudioMateria, working } =
    usePlanEstudioMateriaLibbyFetch(filterPlanEstudio);

  const filter = useMemo(
    () => ({
      espacios: planEstudioMateria?.map((item) => ({
        path: 'idMateria',
        value: item.materia.idMateria,
      })),
    }),
    [planEstudioMateria],
  );

  return (
    <Grid container style={{ paddingBottom: 10 }}>
      <LocalizacionById id={localizacionId}>
        {({ data }) => (
          <Establishment establishmentData={data?.establecimiento} />
        )}
      </LocalizacionById>
      <Grid container spacing={1} alignItems="center" style={{ paddingTop: 5 }}>
        <Grid item>
          <Typography className={classes.tittle}>Área:</Typography>
        </Grid>
        <Grid item>
          {working ? (
            <Loading />
          ) : (
            <EspaciosAreasAutocomplete
              value={areas}
              onChange={onChangeAreas}
              multiple
              textFieldProps={{ className: classes.areasTextField }}
              filter={filter}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
