import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { SimpDiscipPlanFeedback } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'simp_discip_plan_feedback';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idSimpDiscipPlanFeedback',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idSimpDiscipPlanFeedback',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.SimpDiscipPlanFeedback
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.SimpDiscipPlanFeedback,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.SimpDiscipPlanFeedback.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<SimpDiscipPlanFeedback>(DAO_NAME, optionsToUse);

// hooks
export const useSimpDiscipPlanFeedbackFetchById = fetchById;
export const useSimpDiscipPlanFeedbackLibbyCall = libbyCall;
export const useSimpDiscipPlanFeedbackDAO = libbyDAO;
export const useSimpDiscipPlanFeedbackLibbyFetch = libbyFetch;

// components/hoc
export const SimpDiscipPlanFeedbackAutocomplete = entityAutocomplete;
export const SimpDiscipPlanFeedbackCall = renderPropCall;
export const SimpDiscipPlanFeedbackById = renderPropFetchById;
export const SimpDiscipPlanFeedbackSelect = simpleSelect;
export const SimpDiscipPlanFeedbackAccordionSelector = accordionSelector;
export const SimpDiscipPlanFeedbackSelectedChips = selectedChips;

// context
// ByIdContext
export const useSimpDiscipPlanFeedbackByIdContext = useByIdContext;
export const SimpDiscipPlanFeedbackByIdProvider = ByIdProvider;
export const SimpDiscipPlanFeedbackByIdContext = ByIdContext;
// GeneralContext
export const useSimpDiscipPlanFeedbackContext = useGeneralContext;
export const SimpDiscipPlanFeedbackProvider = GeneralProvider;
export const SimpDiscipPlanFeedbackContext = GeneralContext;
