import React, {
  createContext,
  useReducer,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import {
  openTableModal,
  selectWeek,
  makeDaySelection,
  setRows,
  setIsModalOpen,
  setRowIndex,
  setIdxDaySelected,
  setCheckedRows,
  setMetaData,
  setExceptuados,
  setEstadoEmergencia,
  setSuspensionClases,
  setTipoJornada,
  setSaveDisable,
  setEnableAutoSave,
  setExceptuadosList,
  setGridPosition,
  setIsJornadaExtendidaTab,
  setAlumnoToSearch,
  setOpenJustificacion,
} from './actions';
import { ROLES_JERARQUICOS } from '../TomaPresentismo/utils/rolesPresentismo';
import _ from 'lodash';
import {
  AlumnoPresentismo,
  ExceptuadosListPayload,
  FilterPresentismo,
  GeneralContextProps,
  PresentismoContextInterface,
} from '../types';
import { initialContextValue, initialState, reducer } from './reducer';
import { useRolesContext } from 'src/context/RolesContext';
import { Point } from '../TomaPresentismo/utils/Grid';
import useWorldTime from './useWorldTime';
import { ROL } from 'src/commons';
import {
  useFechaBloqueadasLibbyFetch,
  usePropuestaJELibbyFetch,
} from 'src/app/business/presentismo_v2';
import moment from 'moment';

const PresentismoContext =
  createContext<PresentismoContextInterface>(initialContextValue);

export const usePresentismoContext = () => useContext(PresentismoContext);

export const PresentismoContextProvider = ({
  children,
}: GeneralContextProps) => {
  const [state, dispatcher] = useReducer(reducer, initialState);
  const roleContext = useRolesContext();
  const { rol, localizacion } = roleContext.selectedRole;

  const {
    data: propuestasJE = [],
    working: propuestasWorking,
    reFetch,
  } = usePropuestaJELibbyFetch({ limit: 30, aspect: 'default' });

  const [filter, setFilter] = useState<FilterPresentismo>(
    initialContextValue.filter,
  );
  const enabledByPropuesta = propuestasJE
    .find((propuesta) => propuesta.seccion.idSeccion === filter.seccion)
    ?.dias.some(
      (propuestaDia) => propuestaDia.dia.idDia === state.idxDaySelected + 1,
    );

  const { data: feriados = [] } = { data: [] } as {
    data: any[];
  }; /* useFechaBloqueadasLibbyFetch({
    limit: 100,
  }); */

  const rolCanUpdateGrid = useMemo(() => {
    const isNotFeriado = !feriados.some((feriado) =>
      moment(feriado.date).isSame(
        moment(state.currentWeek).add(state.idxDaySelected, 'd'),
      ),
    );

    const isCurrentDateSameOrAfterLowerDeadlineForTomaPresentismo = moment(
      state.currentWeek,
    )
      .add(state.idxDaySelected, 'd')
      .isSameOrAfter(state.lowerDeadLineForTomaPresentismo);

    const validations = state.isJornadaExtendidaTab
      ? [ROL.DOCENTE, ROL.COORDINADOR_JE].includes(Number(rol)) &&
        enabledByPropuesta &&
        propuestasJE.length > 0
      : ROL.DOCENTE === Number(rol) ||
        ROLES_JERARQUICOS.filter((rol) => rol !== ROL.COORDINADOR_JE).includes(
          Number(rol),
        );
    return (
      isCurrentDateSameOrAfterLowerDeadlineForTomaPresentismo &&
      isNotFeriado &&
      validations
    );
  }, [
    feriados,
    state.currentWeek,
    state.idxDaySelected,
    state.lowerDeadLineForTomaPresentismo,
    state.isJornadaExtendidaTab,
    rol,
    enabledByPropuesta,
    propuestasJE.length,
  ]);

  const espacioValue = useMemo(() => {
    const propuesta = propuestasJE.find(
      (propuesta) => propuesta.seccion.idSeccion === filter.seccion,
    );
    const dia = propuesta?.dias.find(
      (propuestaDia) => propuestaDia.dia.idDia === state.idxDaySelected + 1,
    );
    let espacio = '';

    if (dia?.sede === null) {
      espacio = localizacion;
    } else if (Boolean(dia?.sede)) {
      espacio = dia!.sede!.nombre;
    }

    return espacio;
  }, [propuestasJE, filter.seccion, state.idxDaySelected, localizacion]);

  const dispatch = useMemo(
    () => ({
      openTableModal: (payload: any) => dispatcher(openTableModal(payload)),
      setOpenJustificacion: (payload: any) =>
        dispatcher(setOpenJustificacion(payload)),
      selectWeek: (payload: any) => dispatcher(selectWeek(payload)),
      setRows: (payload: AlumnoPresentismo[]) => dispatcher(setRows(payload)),
      makeDaySelection: (payload: any) => dispatcher(makeDaySelection(payload)),
      setIsModalOpen: (payload: boolean) => {
        if (rolCanUpdateGrid) dispatcher(setIsModalOpen(payload));
      },
      setAlumnoToSearch: (payload: string) =>
        dispatcher(setAlumnoToSearch(payload)),
      setRowIndex: (payload: number) => dispatcher(setRowIndex(payload)),
      setGridPosition: (point: Point) => {
        if (rolCanUpdateGrid) dispatcher(setGridPosition(point));
      },
      setIdxDaySelected: (payload: number) =>
        dispatcher(setIdxDaySelected(payload)),
      setCheckedRows: (payload: string[]) =>
        dispatcher(setCheckedRows(payload)),
      setMetaData: (payload: any) => {
        if (rolCanUpdateGrid) dispatcher(setMetaData(payload));
      },
      setEstadoEmergencia: (payload: any) => {
        if (rolCanUpdateGrid) dispatcher(setEstadoEmergencia(payload));
      },
      setSuspensionClases: (payload: any) => {
        if (rolCanUpdateGrid) dispatcher(setSuspensionClases(payload));
      },
      setExceptuados: (payload: any) => dispatcher(setExceptuados(payload)),
      setExceptuadosList: (payload: ExceptuadosListPayload) =>
        dispatcher(
          setExceptuadosList(
            payload.idMovimiento,
            payload.list,
            payload.toDelete,
          ),
        ),
      setTipoJornada: (payload: any) => dispatcher(setTipoJornada(payload)),
      setSaveDisable: (payload: boolean) => dispatcher(setSaveDisable(payload)),
      setEnableAutoSave: (payload: boolean) =>
        dispatcher(setEnableAutoSave(payload)),
      setIsJornadaExtendidaTab: (payload: boolean) =>
        dispatcher(setIsJornadaExtendidaTab(payload)),
    }),
    [dispatcher, rolCanUpdateGrid],
  );

  const serverDate = ''; //  useWorldTime();

  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
      filter,
      setFilter,
      serverDate,
      reFetch,
      propuestasJE,
      propuestasWorking,
      rolCanUpdateGrid,
      feriados,
      espacioValue,
    }),
    [
      espacioValue,
      feriados,
      state,
      dispatch,
      filter,
      serverDate,
      reFetch,
      propuestasJE,
      propuestasWorking,
      rolCanUpdateGrid,
    ],
  );

  return (
    <PresentismoContext.Provider value={contextValue}>
      {children}
    </PresentismoContext.Provider>
  );
};
