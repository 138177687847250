import React, { useMemo, useEffect, useState } from 'react';
import {
  InscripcionAlumno,
  ModalidadType,
  SolicitudPases,
} from 'src/app/models';
import { level, TabBar } from 'src/commons';
import { useRolesContext } from 'src/context/RolesContext';
import { BLOCKER_ID, TABBAR_BLOCKER_ID } from 'src/platform/permission/const';
import { Enrolled } from './Enrolled';
import { External } from './External';
import { PassManagement } from './passManagement';
import { Promotion } from './Promotion';
import { Registered } from './Registered';
import { Section } from './Section';
import { useRouteScreenTitle } from '../../../commons/hooks';
import _ from 'lodash';
import { MatriculationContextProvider } from './context/Context';
import { useRouterContext } from 'src/lib/router';
import { PromocionAcompaniada } from './PromocionAcompaniada/Initial';
import { useLocalizacionLibbyFetch } from 'src/app/business';
import { PermissionBlocker } from 'src/lib/permission';
import { useGetMatriculaConfig } from './Registered/Initial/hooks/useGetMatriculaConfig';
import { useColegioEfectivizadoContext } from '../Calificaciones/context/EfectivizacionProvider';

const ID_DEPENDENCIAS_FUNCIONALES = {
  ID_DEPENDENCIA_ESPECIAL: 50,
  ID_DEPENDENCIA_ADULTOS: 60,
};

export const Matriculation = () => {
  const [inscriptos, setInscriptos] = useState<InscripcionAlumno[]>([]);
  const [gestionPases, setGestionPases] = useState<SolicitudPases[]>([]);

  useRouteScreenTitle('Matriculación');
  const { selectedRole } = useRolesContext();
  const { setMetadata } = useRouterContext();
  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();
  const {
    data: { isEnable },
  } = useGetMatriculaConfig() || {};

  const { data: dFuncional = [] } = useLocalizacionLibbyFetch({
    limit: 1,
    aspect: 'dependencia_funcional',
  });

  const esEscuelaAdultos = useMemo(
    () =>
      dFuncional?.[0]?.establecimiento?.dependenciaFuncional
        ?.idDependenciaFuncional ===
      ID_DEPENDENCIAS_FUNCIONALES.ID_DEPENDENCIA_ADULTOS,
    [dFuncional],
  );

  const esEscuelaEspecialUHospitalaria = useMemo(
    () =>
      dFuncional?.[0]?.establecimiento?.dependenciaFuncional
        ?.idDependenciaFuncional ===
      ID_DEPENDENCIAS_FUNCIONALES.ID_DEPENDENCIA_ESPECIAL,
    [dFuncional],
  );

  const esEscuelaDemo = useMemo(
    () =>
      dFuncional?.[0]?.establecimiento?.nombre.toLowerCase().includes(' demo '),

    [dFuncional],
  );

  useEffect(() => {
    return () => {
      setMetadata((prev) => {
        delete prev.tab;
        return { ...prev };
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sectionTabTitle: Record<string, string> = useMemo(
    () => ({
      '1': 'Secciones',
      '2': 'Grupo/Grado',
      '3': 'Ciclo/OPEL',
    }),
    [],
  );
  let exceptDomiciliary = useMemo(
    () => [
      {
        id: TABBAR_BLOCKER_ID.MATRICULACION_SECCION,
        title: sectionTabTitle[selectedRole.modalidad.toString()],
        component: <Section />,
      },
      {
        id: TABBAR_BLOCKER_ID.MATRICULACION_PROMOTION,
        title: 'Promoción',
        component: <Promotion />,
      },
      {
        id: TABBAR_BLOCKER_ID.PROMOCION_ACOMPANIADA,
        title: 'Promoción Acompañada',
        component: <PromocionAcompaniada />,
      },
      {
        id: TABBAR_BLOCKER_ID.MATRICULACION_PASS_MANAGEMENT,
        title: 'Gestión de pases',
        component: <PassManagement />,
        badgeContent:
          gestionPases && gestionPases?.length > 0
            ? gestionPases?.length
            : undefined,
      },
      {
        id: TABBAR_BLOCKER_ID.MATRICULACION_INSCRIPTOS,
        title: 'Inscriptos',
        component: <Enrolled />,
        badgeContent:
          inscriptos && inscriptos.length > 0 ? inscriptos.length : undefined,
      },
      {
        id: TABBAR_BLOCKER_ID.MATRICULACION_MATRICULADOS,
        title: 'Matriculados',
        component: <Registered />,
        badgeContent: isEnable && establecimientoEfectivizado ? '!' : undefined,
        tooltipTitle:
          isEnable && establecimientoEfectivizado
            ? 'Falta confirmar la matrícula'
            : undefined,
      },
    ],
    [
      sectionTabTitle,
      selectedRole.modalidad,
      gestionPases,
      inscriptos,
      isEnable,
      establecimientoEfectivizado,
    ],
  );

  if (Number(selectedRole.nivel) !== level.PRIMARIO) {
    exceptDomiciliary = exceptDomiciliary.filter(
      (item) => item.title !== 'Promoción Acompañada',
    );
  }

  // 22/10/2021 LAS TABAS DE EXTERNOS Y GRUPOS QUEDAN DESHABILITADAS HASTA NUEVO AVISO

  const allArrTabBar = useMemo(
    () => [
      ...(esEscuelaDemo && (esEscuelaAdultos || esEscuelaEspecialUHospitalaria)
        ? [
            {
              id: TABBAR_BLOCKER_ID.MATRICULACION_EXTERNOS,
              title: 'Externos',
              path: 'external',
              component: <External />,
            },
          ]
        : []),
      // {
      //   id: TABBAR_BLOCKER_ID.MATRICULACION_GRUPOS,
      //   title: 'Grupos',
      //   path: 'grupos',
      //   component: <Grupos />,
      // },
    ],
    [esEscuelaAdultos, esEscuelaDemo, esEscuelaEspecialUHospitalaria],
  );

  return (
    <PermissionBlocker id={BLOCKER_ID.VIEW_MODULE_MATRICULACIONES}>
      <MatriculationContextProvider
        selectedRole={selectedRole}
        setInscriptos={setInscriptos}
        setGestionPases={setGestionPases}
      >
        <TabBar
          content={[
            ...(selectedRole.modalidad === ModalidadType.EspecialAyDomiciliariaC
              ? []
              : [...exceptDomiciliary]),
            ...allArrTabBar,
          ]}
        />
      </MatriculationContextProvider>
    </PermissionBlocker>
  );
};
