import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Disciplina } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'disciplina';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idDisciplina',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idDisciplina',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Disciplina
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Disciplina,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Disciplina.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Disciplina>(DAO_NAME, optionsToUse);

// hooks
export const useDisciplinaFetchById = fetchById;
export const useDisciplinaLibbyCall = libbyCall;
export const useDisciplinaDAO = libbyDAO;
export const useDisciplinaLibbyFetch = libbyFetch;

// components/hoc
export const DisciplinaAutocomplete = entityAutocomplete;
export const DisciplinaCall = renderPropCall;
export const DisciplinaById = renderPropFetchById;
export const DisciplinaSelect = simpleSelect;
export const DisciplinaAccordionSelector = accordionSelector;
export const DisciplinaSelectedChips = selectedChips;

// context
// ByIdContext
export const useDisciplinaByIdContext = useByIdContext;
export const DisciplinaByIdProvider = ByIdProvider;
export const DisciplinaByIdContext = ByIdContext;
// GeneralContext
export const useDisciplinaContext = useGeneralContext;
export const DisciplinaProvider = GeneralProvider;
export const DisciplinaContext = GeneralContext;
