import React, {
  createContext,
  useContext,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { AnyObject } from 'src/commons';
import {
  useEspacioCurricularByIdContext,
  SeccionProvider,
  useIndicadoresDeEvaluacionLibbyCall,
  useHabilidadesLibbyCall,
  useRecursosLibbyCall,
  useInstrumentosDeEvaluacionLibbyCall,
  useBloquesLibbyCall,
  useObjetivosBloquesLibbyCall,
  usePropositosBloquesLibbyCall,
} from 'src/app/business';
import { useContenidoBloqueLibbyCall } from 'src/app/business/ContenidoBloque';
import {
  EspacioCurricular,
  IndicadoresDeEvaluacion,
  Recursos,
  Habilidades,
  InstrumentosDeEvaluacion,
  Bloques,
  ObjetivosBloques,
  PropositosBloques,
  ContenidoBloque,
  Seccion,
} from 'src/app/models';
import { useRolesContext } from 'src/context/RolesContext';
import { useGeneralContext } from 'src/context';
import { makeEntityByEspacio, makeEntityByBloques } from '../../commons/hooks';

export type SetActiveFilter = (filter: AnyObject) => void;

type Normalize<T> = {
  [k: string]: {
    bloque: { idBloque: string; descripcion: string };
    values: T[];
  };
};
export interface Result {
  objetivos: Normalize<Omit<ObjetivosBloques, 'bloque'>>;
  propositos: Normalize<Omit<PropositosBloques, 'bloque'>>;
  indicadores: IndicadoresDeEvaluacion[];
  recursos: Recursos[];
  habilidades: Habilidades[];
  instrumentos: InstrumentosDeEvaluacion[];
  bloques: Bloques[];
  contenidos: ContenidoBloque[];
}

export interface DisciplinarOrientadaContextValue {
  data: Result;
  working: boolean;
}

const filterMateria = (espacio?: EspacioCurricular) => ({
  filter: {
    materia: [{ path: 'id_materia', value: espacio?.materia.idMateria || '' }],
  },
  limit: 20000,
});

const filterBloque = (bloques: Bloques[] = []) => ({
  filter: {
    bloques: bloques?.map((bloque) => ({
      path: 'bloque',
      value: bloque.idBloque,
    })),
  },
  limit: 20000,
});

const filterVoid = () => ({
  filter: {},
  limit: 20000,
});

const useObjetivosDAO = makeEntityByBloques<ObjetivosBloques[]>(
  useObjetivosBloquesLibbyCall,
  filterBloque,
);

const usePropositosDAO = makeEntityByBloques<PropositosBloques[]>(
  usePropositosBloquesLibbyCall,
  filterBloque,
);

const useIndicadoresDAO = makeEntityByEspacio<IndicadoresDeEvaluacion[]>(
  useIndicadoresDeEvaluacionLibbyCall,
  filterVoid,
  true,
);

const useHabilidadesDAO = makeEntityByEspacio<Habilidades[]>(
  useHabilidadesLibbyCall,
  filterVoid,
  true,
);

const useRecursosDAO = makeEntityByEspacio<Recursos[]>(
  useRecursosLibbyCall,
  filterVoid,
  true,
);

const useInstrumentosDAO = makeEntityByEspacio<InstrumentosDeEvaluacion[]>(
  useInstrumentosDeEvaluacionLibbyCall,
  filterVoid,
  true,
);

const useBloquesDAO = makeEntityByEspacio<Bloques[]>(
  useBloquesLibbyCall,
  filterMateria,
);

const useContenidosDAO = makeEntityByBloques<ContenidoBloque[]>(
  useContenidoBloqueLibbyCall,
  filterBloque,
);

export const DisciplinarOrientadaContext =
  createContext<DisciplinarOrientadaContextValue>({
    // @ts-ignore
    data: {},
    working: false,
  });

export const useDisciplinarOrientadaContext = () =>
  useContext<DisciplinarOrientadaContextValue>(DisciplinarOrientadaContext);

export interface DisciplinarOrientadaProviderProps {
  children: JSX.Element;
}

// TODO: abstraer esto
export const DisciplinarOrientadaProvider = ({
  children,
}: DisciplinarOrientadaProviderProps) => {
  const { selectedRole } = useRolesContext();
  const { generalState } = useGeneralContext();

  let filterAnio: any = [];
  if (generalState?.espacioCurricular.planificacion.selected.anio) {
    filterAnio = [
      {
        path: 'anio.idAnio',
        value: generalState?.espacioCurricular.planificacion.selected.anio,
      },
    ];
  }

  const seccionFilter = useRef({
    filterAnio,
    1: [{ path: 'localizacion', value: selectedRole?.localizacionId }],
    seccion: generalState
      ? generalState.espacioCurricular.planificacion.selected.seccion
          ?.filter((item: Seccion) => typeof item.idSeccion === 'number')
          .map((item2: Seccion) => ({
            path: 'seccion.idSeccion',
            value: item2.idSeccion || 0,
          }))
      : [],
  });

  const { data: espacioCurricular } = useEspacioCurricularByIdContext();
  const { data: bloques, working } = useBloquesDAO(espacioCurricular);
  const { data: objetivos, working: oWorking } = useObjetivosDAO(bloques);
  const { data: propositos, working: pWorking } = usePropositosDAO(bloques);
  const { data: indicadores, working: iWorking } = useIndicadoresDAO();
  const { data: recursos, working: rWorking } = useRecursosDAO();
  const { data: habilidades, working: hWorking } = useHabilidadesDAO();
  const { data: instrumentos, working: instWorking } = useInstrumentosDAO();

  const {
    data: contenidos,
    working: ceWorking,
    recall: recallContenidoBloque,
  } = useContenidoBloqueLibbyCall<ContenidoBloque[]>({
    methodName: 'filtroContenidoBloqueDisciplinar',
    params: bloques,
  });

  useEffect(() => {
    if (bloques) recallContenidoBloque(bloques);
  }, [bloques, recallContenidoBloque]);

  const normalizeObj = objetivos?.reduce<
    Normalize<Omit<ObjetivosBloques, 'bloque'>>
  >((accoum, objetivo) => {
    const id = objetivo.bloque.idBloque;
    const {
      bloque: { idBloque, descripcion },
      ...rest
    } = objetivo;
    if (!accoum[id]) {
      accoum[id] = { bloque: { idBloque, descripcion }, values: [] };
    }
    accoum[id].values.push({ ...rest });
    return accoum;
  }, {});

  const normalizeProp = propositos?.reduce<
    Normalize<Omit<PropositosBloques, 'bloque'>>
  >((accoum, proposito) => {
    const id = proposito.bloque.idBloque;
    const {
      bloque: { idBloque, descripcion },
      ...rest
    } = proposito;
    if (!accoum[id]) {
      accoum[id] = { bloque: { idBloque, descripcion }, values: [] };
    }
    accoum[id].values.push({ ...rest });
    return accoum;
  }, {});

  const workingAll =
    oWorking ||
    pWorking ||
    iWorking ||
    rWorking ||
    hWorking ||
    instWorking ||
    working ||
    ceWorking;

  const value = useMemo<DisciplinarOrientadaContextValue>(
    () => ({
      data: {
        objetivos: normalizeObj || {},
        propositos: normalizeProp || {},
        indicadores: indicadores || [],
        recursos: recursos || [],
        habilidades: habilidades || [],
        instrumentos: instrumentos || [],
        bloques: bloques || [],
        contenidos: contenidos || [],
      },
      working: workingAll,
    }),
    [
      indicadores,
      recursos,
      habilidades,
      instrumentos,
      workingAll,
      normalizeObj,
      normalizeProp,
      contenidos,
      bloques,
    ],
  );
  return (
    <SeccionProvider filter={seccionFilter.current}>
      <DisciplinarOrientadaContext.Provider value={value}>
        {children}
      </DisciplinarOrientadaContext.Provider>
    </SeccionProvider>
  );
};
