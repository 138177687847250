import { CierreDAO } from '../../../platform/libby/dao/Presentismov3';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { businessOptions } from '../businessOptions';
import { Cierre } from '../../models/presentismo_v3';

const DAO_NAME = 'cierre';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEstadoPresente',
      labelKey: 'descripcion',
    },
    withSimpleSelect: {
      labelKey: 'descripcion',
      valueKey: 'idEstadoPresente',
      title: 'Estado',
      placeholder: 'Seleccioná Estado',
      name: 'idEstadoPresente',
    },
  },
};

const optionsToUse = !businessOptions.EstadoPresente_v3
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EstadoPresenteV3,
      hoc: { ...defaultOptions.hoc, ...businessOptions.EstadoPresenteV3.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Cierre, CierreDAO>(DAO_NAME, optionsToUse);

// hooks
export const useCierrePresenteV3FetchById = fetchById;
export const useCierrePresenteV3LibbyCall = libbyCall;
export const useCierrePresenteV3DAO = libbyDAO;
export const useCierrePresenteV3LibbyFetch = libbyFetch;

// components/hoc
export const CierrePresenteV3Autocomplete = entityAutocomplete;
export const CierrePresenteV3Call = renderPropCall;
export const CierrePresenteV3ById = renderPropFetchById;
export const CierrePresenteV3Select = simpleSelect;
export const CierreresenteV3AccordionSelector = accordionSelector;
export const CierrePresenteV3SelectedChips = selectedChips;

// context
// ByIdContext
export const useCierrePresenteV3ByIdContext = useByIdContext;
export const CierrePresenteV3ByIdProvider = ByIdProvider;
export const CierrePresenteV3ByIdContext = ByIdContext;
// GeneralContext
export const useCierrePresenteV3Context = useGeneralContext;
export const CierrePresenteV3Provider = GeneralProvider;
export const CierrePresenteV3Context = GeneralContext;
