import React from 'react';
import { Grid } from '@material-ui/core';
import { Filters, Toolbar } from './components';
import { useFiltersLogic, FilterLogicOptions } from './hooks';

export type RepositorioABMTableHeaderProps = Pick<
  FilterLogicOptions,
  'filter' | 'onChangeFilter'
>;

export const RepositorioABMTableHeader = (
  props: RepositorioABMTableHeaderProps,
) => {
  const filterLogic = useFiltersLogic(props);
  return (
    <Grid container justify="space-between" alignItems="flex-end">
      <Grid item sm={6}>
        <Filters {...filterLogic} />
      </Grid>
      <Grid item sm={6}>
        <Toolbar {...filterLogic} />
      </Grid>
    </Grid>
  );
};
