import { makeBusinessEntity } from '../../lib/libby/generator';
import { TipoCalificacion } from '../models';

const DAO_NAME = 'tipo_calificacion';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<TipoCalificacion>(DAO_NAME);

// hooks
export const useTipoCalificacionFetchById = fetchById;
export const useTipoCalificacionLibbyCall = libbyCall;
export const useTipoCalificacionDAO = libbyDAO;
export const useTipoCalificacionLibbyFetch = libbyFetch;

// components/hoc
export const TipoCalificacionAutocomplete = entityAutocomplete;
export const TipoCalificacionCall = renderPropCall;
export const TipoCalificacionById = renderPropFetchById;
export const TipoCalificacionSelect = simpleSelect;
export const TipoCalificacionAccordionSelector = accordionSelector;
export const TipoCalificacionSelectedChips = selectedChips;

// context
// ByIdContext
export const useTipoCalificacionByIdContext = useByIdContext;
export const TipoCalificacionByIdProvider = ByIdProvider;
export const TipoCalificacionByIdContext = ByIdContext;
// GeneralContext
export const useTipoCalificacionContext = useGeneralContext;
export const TipoCalificacionProvider = GeneralProvider;
export const TipoCalificacionContext = GeneralContext;
