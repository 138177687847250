import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { PlanEstudio } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'plan_estudio';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPlanEstudio',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPlanEstudio',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.PlanEstudio
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.PlanEstudio,
      hoc: { ...defaultOptions.hoc, ...businessOptions.PlanEstudio.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<PlanEstudio>(DAO_NAME, optionsToUse);

// hooks
export const usePlanEstudioFetchById = fetchById;
export const usePlanEstudioLibbyCall = libbyCall;
export const usePlanEstudioDAO = libbyDAO;
export const usePlanEstudioLibbyFetch = libbyFetch;

// components/hoc
export const PlanEstudioAutocomplete = entityAutocomplete;
export const PlanEstudioCall = renderPropCall;
export const PlanEstudioById = renderPropFetchById;
export const PlanEstudioSelect = simpleSelect;
export const PlanEstudioAccordionSelector = accordionSelector;
export const PlanEstudioSelectedChips = selectedChips;

// context
// ByIdContext
export const usePlanEstudioByIdContext = useByIdContext;
export const PlanEstudioByIdProvider = ByIdProvider;
export const PlanEstudioByIdContext = ByIdContext;
// GeneralContext
export const usePlanEstudioContext = useGeneralContext;
export const PlanEstudioProvider = GeneralProvider;
export const PlanEstudioContext = GeneralContext;
