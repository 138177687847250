import React, { useMemo } from 'react';
import moment from 'moment';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import { makeStyles } from '@material-ui/styles';
import { IconButton, TableRow, Tooltip } from '@material-ui/core';
import { ROL } from 'src/commons/const';
import { Dias } from '../../Acap/utils';
import { InfoTableColumn, useTabBarValue } from '../../../../commons';
import { GruposInscripcionAcumulate, InscripcionesColumnProps } from '../types';
import { MUIEditorMIESC } from '../components/MUIEditorMIESC';
import { useColegioEfectivizadoContext } from '../../Calificaciones/context/EfectivizacionProvider';

const useStyles = makeStyles({
  arrow: {
    color: '#00b3e3',
  },
  tooltip: {
    backgroundColor: '#00b3e3',
    fontSize: '13px',
  },
});

const useStylesDisabled = makeStyles({
  tooltip: {
    fontSize: '13px',
  },
});

export const useInscripcionesColumns = ({
  detalleStep,
  setDetalleStep,
  editGroup,
  handleDelete,
  gruposInscripcionesTotal,
  rol,
}: InscripcionesColumnProps) => {
  const classes = useStyles();
  const classesDisabled = useStylesDisabled();

  const { proyeccionEstado } = useColegioEfectivizadoContext();

  const columns = useMemo<InfoTableColumn<GruposInscripcionAcumulate>[]>(() => {
    return [
      {
        id: 'cicloLectivo',
        width: '2%',
        label: 'Ciclo Lectivo',
        render: (_: unknown, row) =>
          row.ofertaGrupo.ofertaTurno.oferta.accion.cicloLectivo.anio,
        hideSortIcon: true,
      },
      {
        id: 'organizacion',
        label: 'Organización',
        hideSortIcon: detalleStep.enabled,
        render: (value: any, row) =>
          row?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.organizacion?.nombre,
        orderById: 'ofertaGrupo.ofertaTurno.oferta.accion.organizacion.nombre',
      },
      {
        id: 'sector',
        label: 'Sector',
        hideSortIcon: detalleStep.enabled,
        render: (value: any, row) =>
          row?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.organizacion?.sector
            ?.nombre,

        orderById:
          'ofertaGrupo.ofertaTurno.oferta.accion.organizacion.sector.nombre',
      },
      {
        id: 'tipoAccion',
        label: 'Tipo de acción',
        hideSortIcon: detalleStep.enabled,
        render: (value: any, row) =>
          row?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.tipoAccion?.nombre,
        orderById: 'ofertaGrupo.ofertaTurno.oferta.accion.tipoAccion.nombre',
      },
      {
        id: 'accion',
        label: 'Acción',
        hideSortIcon: detalleStep.enabled,
        orderById: 'ofertaGrupo.ofertaTurno.oferta.accion.nombre',
        render: (value: any, row) => {
          let editorText;
          try {
            editorText =
              JSON.parse(
                row?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.descripcion,
              )?.text ||
              JSON.parse(
                row?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.descripcion,
              );
          } catch (e) {
            editorText =
              row?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.descripcion;
          }
          let onlyDescription = '';
          if (!!editorText?.blocks) {
            editorText?.blocks.forEach((elem: any, i: number) => {
              if (elem.text !== '')
                onlyDescription = onlyDescription.concat(
                  i === 0 ? '' : '\n',
                  elem.text,
                );
            });
          }
          const editorTextUpdated =
            typeof editorText === 'string' ? editorText : onlyDescription;
          return (
            <Tooltip
              classes={classes}
              arrow
              title={<MUIEditorMIESC text={editorTextUpdated} />}
            >
              <span>
                {row?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.nombre}
              </span>
            </Tooltip>
          );
        },
      },
      {
        id: 'grupoId',
        label: 'Grupo',
        hideSortIcon: true,
        render: (_: unknown, row) =>
          `${row?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.nombre}, ${row?.ofertaGrupo?.ofertaTurno?.turno?.descripcionTurno}, id: ${row.ofertaGrupo.idOfertaGrupo}`,
      },
      {
        id: 'fechaInicio',
        label: 'Fecha de inicio',
        hideSortIcon: detalleStep.enabled,
        render: (value: any, row) => {
          return (
            moment(row?.ofertaGrupo?.fechaInicio).format('DD-MM-YYYY') || ''
          );
        },
        orderById: 'ofertaGrupo.ofertaTurno.oferta.fechaInicio',
      },
      {
        id: 'turno',
        label: detalleStep.enabled ? 'Turno oferta' : 'Turno',
        hideSortIcon: detalleStep.enabled,
        render: (value: any, row) =>
          row?.ofertaGrupo?.ofertaTurno?.turno?.descripcionTurno,
        orderById: 'ofertaGrupo.ofertaTurno.turno.descripcionTurno',
      },
      {
        id: 'cupo',
        label: 'Cupo',
        hideSortIcon: true,
        render: (value: any, row) =>
          row?.ofertaGrupo?.ofertaTurno?.cupo /
          row?.ofertaGrupo?.ofertaTurno?.cantGrupos,
      },
      {
        id: 'dia',
        label: 'Día',
        hideSortIcon: true,
        customStyles: { padding: '0' },
        render: (value: any, row) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {row?.ofertaGrupo?.jornadas?.map((jornada, index) => {
                return (
                  <TableRow
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      ...(row?.ofertaGrupo?.jornadas?.length > 1 &&
                      row?.ofertaGrupo?.jornadas?.length - 1 !== index
                        ? { borderBottom: '1px solid #DFE3E8' }
                        : {}),
                      padding: '0 16px',
                    }}
                  >
                    {Dias[jornada.cupo][0] +
                      Dias[jornada.cupo].slice(1).toLowerCase()}
                  </TableRow>
                );
              })}
            </div>
          );
        },
      },
      {
        id: 'horaInicio',
        label: 'Hora inicio',
        hideSortIcon: true,
        customStyles: { padding: '0' },
        render: (value: any, row) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {row?.ofertaGrupo?.jornadas?.map((jornada, index) => {
                return (
                  <TableRow
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      ...(row?.ofertaGrupo?.jornadas?.length > 1 &&
                      row?.ofertaGrupo?.jornadas?.length - 1 !== index
                        ? { borderBottom: '1px solid #DFE3E8' }
                        : {}),
                      padding: '0 16px',
                    }}
                  >
                    {moment(jornada.horaInicio, 'HH:mm:ss').format('HH:mm')}
                  </TableRow>
                );
              })}
            </div>
          );
        },
      },
      {
        id: 'horaFin',
        label: 'Hora fin',
        hideSortIcon: true,
        customStyles: { padding: '0' },
        render: (value: any, row) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {row?.ofertaGrupo?.jornadas?.map((jornada, index) => {
                return (
                  <TableRow
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      ...(row?.ofertaGrupo?.jornadas?.length > 1 &&
                      row?.ofertaGrupo?.jornadas?.length - 1 !== index
                        ? { borderBottom: '1px solid #DFE3E8' }
                        : {}),
                      padding: '0 16px',
                    }}
                  >
                    {moment(jornada.horaFin, 'HH:mm:ss').format('HH:mm')}
                  </TableRow>
                );
              })}
            </div>
          );
        },
      },
      {
        id: 'referente',
        label: 'Referente',
        hideSortIcon: true,
        render: (value: any, row) =>
          Boolean(row?.ofertaGrupo?.referente?.apellido)
            ? `${row?.ofertaGrupo?.referente?.apellido}, ${row?.ofertaGrupo?.referente?.nombre}, ${row?.ofertaGrupo?.referente?.email}, ${row?.ofertaGrupo?.referente?.telefono}`
            : `${row?.ofertaGrupo?.referente?.nombre} , ${row?.ofertaGrupo?.referente?.email}, ${row?.ofertaGrupo?.referente?.telefono}`,
      },
      {
        id: 'inscriptos',
        label: 'Inscriptos',
        hideSortIcon: true,
        hideColumn: detalleStep.enabled,
        render: (value: any, row) => {
          return row?.alumnoMovimientos.length;
        },
      },
      {
        id: 'libre',
        label: 'Libre',
        hideSortIcon: true,
        hideColumn: detalleStep.enabled,
        render: (value: any, row) =>
          row?.ofertaGrupo?.ofertaTurno?.cupo /
            row?.ofertaGrupo?.ofertaTurno?.cantGrupos -
          row?.alumnoMovimientos.length,
      },
      {
        id: 'icon',
        label: '',
        width: '1%',
        hideSortIcon: true,
        noSort: true,
        hideColumn: detalleStep.enabled,
        render: (value: any, row) => {
          const validacionFechaInicio =
            moment(new Date()).isAfter(
              row?.ofertaGrupo?.ofertaTurno?.oferta?.fechaInicio,
            ) &&
            !moment(new Date()).isAfter(
              row?.ofertaGrupo?.ofertaTurno?.oferta?.fechaFin,
            );

          const fechaActual = new Date();

          const fechaFinOferta = new Date(row?.ofertaGrupo?.fechaFin);

          const validacionFechaFin = fechaActual > fechaFinOferta;

          return (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <IconButton
                disabled={
                  !(
                    ROL.REFERENTE_ACAP === Number(rol) &&
                    row.ofertaGrupo.ofertaTurno.oferta.accion.cicloLectivo
                      .idCicloLectivo ===
                      proyeccionEstado?.cicloLectivo.idCicloLectivo!
                  ) || validacionFechaFin
                }
                onClick={() => editGroup(row)}
              >
                <EditIcon
                  color={
                    !(
                      ROL.REFERENTE_ACAP === Number(rol) &&
                      row.ofertaGrupo.ofertaTurno.oferta.accion.cicloLectivo
                        .idCicloLectivo ===
                        proyeccionEstado?.cicloLectivo.idCicloLectivo!
                    ) || validacionFechaFin
                      ? 'disabled'
                      : 'primary'
                  }
                />
              </IconButton>
              <IconButton
                onClick={() =>
                  setDetalleStep({
                    enabled: true,
                    row: [
                      {
                        ...row,
                        totalAlumnosInscriptosEscuelas:
                          gruposInscripcionesTotal?.filter(
                            (grupo) =>
                              grupo?.ofertaGrupo?.idOfertaGrupo ===
                              row?.ofertaGrupo?.idOfertaGrupo,
                          ).length,
                      },
                    ],
                  })
                }
              >
                <VisibilityIcon color="primary" />
              </IconButton>
              {validacionFechaInicio ? (
                <Tooltip
                  classes={classesDisabled}
                  arrow
                  title="La actividad ha iniciado, no borrar"
                >
                  <DeleteIcon
                    style={{
                      padding: '10px',
                      color: 'rgba(0, 0, 0, 0.26)',
                    }}
                  />
                </Tooltip>
              ) : (
                <IconButton
                  disabled={
                    !(
                      ROL.REFERENTE_ACAP === Number(rol) &&
                      row.ofertaGrupo.ofertaTurno.oferta.accion.cicloLectivo
                        .idCicloLectivo ===
                        proyeccionEstado?.cicloLectivo.idCicloLectivo!
                    )
                  }
                  onClick={() => handleDelete(row)}
                >
                  <DeleteIcon
                    color={
                      !(
                        ROL.REFERENTE_ACAP === Number(rol) &&
                        row.ofertaGrupo.ofertaTurno.oferta.accion.cicloLectivo
                          .idCicloLectivo ===
                          proyeccionEstado?.cicloLectivo.idCicloLectivo!
                      )
                        ? 'disabled'
                        : 'primary'
                    }
                  />
                </IconButton>
              )}
            </div>
          );
        },
      },
    ];
  }, [
    detalleStep.enabled,
    classes,
    rol,
    proyeccionEstado?.cicloLectivo.idCicloLectivo,
    classesDisabled,
    editGroup,
    setDetalleStep,
    gruposInscripcionesTotal,
    handleDelete,
  ]);

  return columns;
};
