import { DAO_NAME } from '../../../../app/business/DiasHabilitados';
import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class DiasHabilitadosDAO extends LibbyFetchDAO {
  constructor() {
    super(DAO_NAME, 'idDiasHabilitados');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
