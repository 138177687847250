import { LibbyFetchDAO } from './LibbyFetchDAO';

export class InterdiscipPlanDAO extends LibbyFetchDAO {
  constructor() {
    super('interdiscip_plan', 'idInterdiscipPlan');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
