import { useGenerarExcelDAO } from '../../app/business';

const useGenerarExcel = () => {
  const generarExcelDAO = useGenerarExcelDAO();
  const generateExcel = async (data: any, titleExcel: any) => {
    const response = await generarExcelDAO.generate(data);

    const linkSource = `data:application/vnd.ms-excel;base64,${response.data}`;
    const downloadLink = document.createElement('a');
    const fileName = `${titleExcel}.xlsx`;
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  return generateExcel;
};

export default useGenerarExcel;
