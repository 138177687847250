import React from 'react';
import { useField } from 'react-final-form-hooks';
import { getErrorAndHelperTextNoTranslation } from '../../../utils/materialHelpers';

type FunctionComponent<T> = (props: T) => JSX.Element;

export interface FormGenericControlProps<T = any> {
  name: string;
  form: any;
  componentProps?: T;
  disableErrorMessage?: boolean;
  component: React.ComponentType<T> | FunctionComponent<T>;
}

export const FormGenericControl = ({
  component: Comp,
  componentProps = {},
  form,
  name,
  disableErrorMessage,
}: FormGenericControlProps) => {
  const field = useField(name, form);
  const errorMessage =
    !disableErrorMessage && getErrorAndHelperTextNoTranslation(field);
  return <Comp {...componentProps} {...field.input} {...errorMessage} />;
};
