import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  selectInput: {
    backgroundColor: '#EEF5FC',
    border: 'none',
    height: 38,
  },
  selectLabel: {
    fontSize: 16,
    letterSpacing: 0,
    color: '#38485C',
    fontWeight: 'bold',
    fontFamily: 'Open Sans',
  },
  altaButton: {
    marginRight: 10,
    backgroundColor: '#3786EE',
    textTransform: 'none',
  },
  container: {
    paddingTop: 10,
    paddingRight: 14,
  },
  selectBg: {
    backgroundColor: '#EEF5FC',
  },
  daterange: {
    display: 'flex',
    '& > .rdrDefinedRangesWrapper': {
      display: 'none',
    },
    '& .rdrMonth': {
      padding: 0,
    },
    '& .rdrWeekDay': {
      fontFamily: 'Open Sans',
      fontSize: 12,
      color: '#9FA1A4',
      textTransform: 'uppercase',
    },
    '& .rdrDay': {
      fontFamily: 'Open Sans',
      fontSize: 12,
      textTransform: 'uppercase',
    },
  },
  printer: {
    display: 'none',
    /*  visibility: 'hidden', */
    '@media print': {
      /* visibility: "visible", */
      display: 'flex',
    },
  },
});
