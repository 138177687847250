import { useEffect } from 'react';
import { useRouterContext } from '../../lib/router';

export const useBackButton = (
  toPath?: string,
  tab = 0,
  callback?: () => void,
): void => {
  const { setMetadata } = useRouterContext();
  useEffect(() => {
    setMetadata((prev) => ({
      ...prev,
      showBackButton: true,
      toPath,
      callback,
    }));

    return () => {
      setMetadata((prev) => ({
        ...prev,
        showBackButton: false,
        toPath: null,
        ...(prev.fromTab && { tab: prev.fromTab }),
      }));
    };
  }, [setMetadata, toPath, tab, callback]);
};
