import { useEffect, useState } from 'react';
import { Nodo } from 'src/app/models/orientaciones';
import { NodosDAO } from 'src/platform/libby/dao';
import { makeBusinessEntity } from '../../../lib/libby/generator';

const DAO_NAME = 'nodos';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
} = makeBusinessEntity<Nodo>(DAO_NAME, {});

// hooks
export const useNodosFetchById = fetchById;
export const useNodosLibbyCall = libbyCall;
export const useNodosDAO = libbyDAO;
export const useNodosLibbyFetch = libbyFetch;

export const useNodosData = () => {
  const dao = useNodosDAO();
  const [nodosMetadata, setNodosMetadata] = useState<{
    error: string | boolean;
    meta: {
      data: Nodo[];
      headers?: any;
      status?: number;
    };
  }>({
    error: false,
    meta: {
      data: [],
    },
  });

  useEffect(() => {
    const fetch = async () => {
      const data = await (dao as NodosDAO).getNodosContenidos();
      setNodosMetadata(data);
    };
    fetch();
  }, [dao]);

  return nodosMetadata;
};
