import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSnackbar } from 'notistack';
import { Grid, makeStyles, TablePagination } from '@material-ui/core';
import { AnyObject, LibbyObject } from 'src/commons';
import {
  useInscripcionAlumnoDAO,
  useInscripcionAlumnoCustomDAO,
  useSeccionLibbyFetch,
  useProyeccionLibbyFetch,
  useProyeccionDAO,
  useCicloLectivoLibbyFetch,
  useProyeccionEstadoLibbyFetch,
  useIgualarCalificacionesPostRequest,
  useLocalizacionLibbyFetch,
  useProyeccionEstadoDAO,
} from 'src/app/business';
import { Footer, InfoTable, Loading } from 'src/commons/components';
import {
  alumnEnrolledStatus,
  typeEstablishment,
  level,
} from 'src/commons/const';
import { useLibbyFetch } from 'src/commons/hooks';
import confirmDialog from 'src/commons/services/confirmDialog';
import customFormDialog from 'src/commons/services/customFormDialog';
import { useRolesContext } from 'src/context/RolesContext';
import { useDebounce } from 'src/commons/hooks/useDebounce';
import { useActivePermissionBlocker } from 'src/lib/permission';
import { PermissionBlocker } from 'src/lib/permission/components/PermissionBlocker';
import { BLOCKER_ID } from 'src/platform/permission/const/BlockerId';
import { useLegajoLogic } from 'src/screens/Private/LegajoAlumno';
import { primary } from 'src/theme/colors';
import { ROL } from 'src/commons/const/rol';
import { NIVEL } from 'src/commons/const/nivel';
import { useDateValidator } from 'src/commons/hooks/useDateValidator';
import { setSearchParamsMultipleWords } from 'src/utils/setSearchParamsMultipleWords';
import { EnrolledAlumnModal, EnrolledDatePassModal } from '../components';
import { FilterTablesEnrolled } from './components';
import { EnrolledAddStudentModal } from '../components/EnrolledAddStudentModal';
import { useInscripcionColumns } from './hooks/useInscripcionColumns';
import _ from 'lodash';
import { useStyles } from './styles';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
import { useMatriculationContext } from '../../context/Context';
import { AddStudentModal } from '../components/AddStudentModal/AddStudentModal';
import { MovimientoRazon } from 'src/app/models/MovimientoRazon';
import { InscripcionAlumno } from 'src/app/models';

const { SCHOOLD_TECHNIQUE } = typeEstablishment;

export interface Alumno {
  idInscripcionAlumno: string;
  idAlumno: string;
  idAnio: number;
  descripcionAnio: string;
  nombre: string;
  documento: string;
  idCondicion: string;
  idTurno: string;
  idNivel: number;
  idSeccion: string | null;
  selectedSeccion: string | null;
  cicloLectivo: any;
  idSeccionDestino?: string | null;
  idSeccionOrigen?: string | null;
  matriculacionDate?: string;
  razon: MovimientoRazon;
}
const InitialEnrolledRaw = ({ libby }: LibbyObject) => {
  const roleContext = useRolesContext();
  const { cicloLectivo: cicloLectivoContext } = useColegioEfectivizadoContext();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const inscripcionAlumnoCustomDAO = useInscripcionAlumnoCustomDAO();
  const { localizacionId, nivel, tipoEstablecimiento, rol } =
    roleContext.selectedRole;
  const proyeccionEstadoDAO = useProyeccionEstadoDAO();
  const nivelId = roleContext.selectedRole.nivel;
  const filterLocalization = useMemo(
    () => [{ path: 'localizacion', value: localizacionId }],
    [localizacionId],
  );
  const { data: dFuncional = [] } = useLocalizacionLibbyFetch({
    limit: 1,
    aspect: 'dependencia_funcional',
  });
  const filterLevel = useMemo(
    () => [{ path: 'nivel', value: nivelId }],
    [nivelId],
  );
  const { current: filterEstadoMatricula } = useRef([
    {
      path: 'alumno.estadoMatricula.idEstadoMatricula',
      value: alumnEnrolledStatus.INSCRIPTO,
    },
  ]);
  const { current: filterIsDeleted } = useRef([
    { path: 'isDeleted', value: false },
  ]);
  const editStudentBlocked = useActivePermissionBlocker(
    BLOCKER_ID.EDIT_STUDENT,
  );

  const filterLocalizacion2 = useMemo(
    () => ({
      0: [{ path: 'localizacion', value: localizacionId }],
    }),
    [localizacionId],
  );

  const { data: proyeccionEstado }: any = useProyeccionEstadoLibbyFetch({
    filter: filterLocalizacion2,
    aspect: 'default',
    orderBy: 'cicloLectivo',
    direction: 'desc',
  });

  const CURRENT_YEAR = cicloLectivoContext?.anio;

  const cicloLectivoActual = cicloLectivoContext;

  const [filterCicloLectivo, setFilterCicloLectivo] = useState([
    {
      path: 'cicloLectivo.anio',
      value: null,
    },
    {
      path: 'cicloLectivo.anio',
      value: null,
    },
  ]);

  const { request: requestIgualarCalificaciones } =
    useIgualarCalificacionesPostRequest();

  useEffect(() => {
    if (cicloLectivoActual) {
      const isDEI =
        dFuncional?.[0]?.establecimiento?.dependenciaFuncional
          ?.idDependenciaFuncional === 10;
      const filterCicloLectivo = [
        {
          path: 'cicloLectivo.anio',
          value: cicloLectivoActual.anio,
        },
      ];
      setFilterCicloLectivo(filterCicloLectivo);
    }
  }, [cicloLectivoActual, dFuncional, rol]);

  const [direction, setDirection] = React.useState<'asc' | 'desc'>('asc');
  const [alumnRows, setAlumnRows] = useState<Alumno[]>([]);
  const [orderBy, setOrderBy] = useState('alumno.persona.apellido');
  const [alumnsWithSections, setAlumnsWithSections] = useState<Alumno[]>([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const [filterLibby, setFilterLibby] = useState<AnyObject>({
    0: filterLocalization,
    1: filterLevel,
    2: filterIsDeleted,
    3: filterEstadoMatricula,
    4: filterCicloLectivo,
  });
  const [formValues, setFormValues] = useState({
    id_anio: '',
    id_turno: '',
  });
  const [registersCount, setRegistersCount] = useState(0);
  const [registersWorking, setRegistersWorking] = useState<boolean>(false);
  const [inscripcionAlumno, setInscripcionAlumno] = useState<
    InscripcionAlumno[]
  >([]);
  const [inscripcionAlumnoWorking, setInscripcionAlumnoWorking] =
    useState<boolean>(false);
  const [count, setCount] = useState<number>(0);

  const inscripcionAlumnoDAO = useInscripcionAlumnoDAO();
  const ProyeccionDAO = useProyeccionDAO();
  const [proyeccionWorking, setProyeccionWorking] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const reFetch = useCallback(() => {
    setTimeout(() => setCount((prevState: any) => prevState + 1), 0);
  }, []);

  const { badgeInscriptosReFetch, inscriptos, workingIscriptos } =
    useMatriculationContext();

  useEffect(() => {
    if (inscriptos.length > 0) {
      setRegistersCount(inscriptos.length);
      setRegistersWorking(workingIscriptos);
    }
  }, [inscriptos.length, workingIscriptos]);

  const enabled = useDateValidator('Alumnos');

  const [search, setSearch] = useState('');
  const searchDebounced = useDebounce(search, 1000);

  const searchFilterParams = useMemo(
    () => ({
      ...setSearchParamsMultipleWords(
        [
          'alumno.persona.nombre',
          'alumno.persona.apellido',
          'alumno.persona.documento',
          'anio.descripcionAnio',
          'condicion.descripcionCondicion',
          'turno.descripcionTurno',
        ],
        searchDebounced,
      ),
    }),
    [searchDebounced],
  );

  const filterCicloLectivoNivelInicial = useMemo(
    () => ({
      0: [
        { path: 'anio', value: CURRENT_YEAR },
        {
          path: 'anio',
          value: Number(CURRENT_YEAR) + 1,
        },
      ],
    }),
    [CURRENT_YEAR],
  );

  const filterCicloLectivoOtrosNiveles = useMemo(
    () => ({
      0: [{ path: 'anio', value: CURRENT_YEAR }],
    }),
    [CURRENT_YEAR],
  );

  const [currentCicloLectivoFilter, setCurrentCicloLectivoFilter] =
    useState<any>(filterCicloLectivoNivelInicial);

  const { data: ciclosLectivos = [] } = useCicloLectivoLibbyFetch({
    filter: currentCicloLectivoFilter,
    limit: 100,
    enabled: CURRENT_YEAR !== undefined,
  });

  useEffect(() => {
    const existCiclo = Object.keys(filterLibby)
      .map((idx) => filterLibby[idx])
      .flatMap((filtros) => filtros)
      .find((filtro) => filtro.path === 'cicloLectivo.anio').value;

    if (existCiclo) {
      setInscripcionAlumnoWorking(true);
      inscripcionAlumnoDAO
        .getInscriptosFilter(
          filterLibby,
          'inscripcion_alumno',
          orderBy,
          direction,
          5000,
          rowsPerPage,
          page,
        )
        .then((response: any) => {
          setInscripcionAlumno(response);
          setInscripcionAlumnoWorking(false);
        });
    }
  }, [
    direction,
    filterLibby,
    inscripcionAlumnoDAO,
    orderBy,
    page,
    rowsPerPage,
    count,
  ]);

  const filterSecciones = useMemo(
    () => ({
      0: [
        { path: 'cicloLectivo.anio', value: CURRENT_YEAR },
        {
          path: 'cicloLectivo.anio',
          value: Number(CURRENT_YEAR) + 1,
        },
      ],
      1: [{ path: 'localizacion', value: localizacionId }],
    }),
    [CURRENT_YEAR, localizacionId],
  );

  const { data: seccion = [] } = useSeccionLibbyFetch({
    limit: 100,
    filter: filterSecciones,
    aspect: 'calificaciones_inscriptos',
    enabled: CURRENT_YEAR !== undefined,
  });

  const filterProyeccion = useMemo(
    () => ({
      0: [{ path: 'seccionDestino.localizacion', value: localizacionId }],
      1: [{ path: 'cicloLectivo.anio', value: CURRENT_YEAR }],
      2: [
        {
          path: 'alumno.estadoMatricula.idEstadoMatricula',
          value: alumnEnrolledStatus.INSCRIPTO,
        },
      ],
    }),
    [CURRENT_YEAR, localizacionId],
  );

  const {
    data: proyecciones = [],
    reFetch: reFetchProyeccion,
    working: workingP,
  } = useProyeccionLibbyFetch({
    limit: 10000,
    filter: filterProyeccion,
    aspect: 'matriculacion_inscriptosProyeccion',
    enabled: CURRENT_YEAR !== undefined,
  });

  const paramsFetchAnio = useMemo(
    () => ({
      daoName: 'anio',
      orderBy: 'numeroAnio',
      direction: 'asc',
      filter: {
        0: [{ path: 'nivel', value: nivel }],
        1:
          tipoEstablecimiento === SCHOOLD_TECHNIQUE ||
          Number(nivel) === level.PRIMARIO ||
          Number(nivel) === level.INICIAL
            ? {}
            : [{ path: 'numeroAnio', value: 6, method: 'notEquals' }],
      },
    }),
    [nivel, tipoEstablecimiento],
  );

  useEffect(() => {
    if (nivel !== String(NIVEL.INICIAL)) {
      setCurrentCicloLectivoFilter(filterCicloLectivoOtrosNiveles);
    }
  }, [filterCicloLectivoOtrosNiveles, nivel]);

  const { data: anio } = useLibbyFetch(libby, paramsFetchAnio);
  const { data: turno } = useLibbyFetch(libby, { daoName: 'turno' });
  // CAMBIO DESECCION
  const handleChangeSection = useCallback(
    (e: React.FormEvent<EventTarget>, alumno: Alumno) => {
      const target = e.target as HTMLInputElement;

      const rows = alumnRows.map((item: Alumno) =>
        item.idAlumno === alumno.idAlumno
          ? { ...item, idSeccion: target.value }
          : item,
      );
      setAlumnRows(rows);

      const seccionEncontrada = seccion?.find(
        (id) => id.idSeccion === target.value,
      );
      const nombreSeccionSeleccionada = seccionEncontrada
        ? seccionEncontrada.nombreSeccion
        : '-';

      const updatedAlumn = {
        ...alumno,
        idSeccion: target.value,
        selectedSeccion: nombreSeccionSeleccionada,
      };
      setAlumnsWithSections((previous) => {
        const index = previous.findIndex(
          (item) => item.idAlumno === alumno.idAlumno,
        );
        if (index !== -1) {
          const updated = [...previous];
          updated[index] = updatedAlumn;
          return updated;
        } else {
          return [...previous, updatedAlumn];
        }
      });
    },
    [alumnRows, seccion],
  );

  const removeAlumn = useCallback(
    async (nombre: string, idInscripcionAlumno: number) => {
      try {
        const confirm = await confirmDialog.show({
          title: 'Dar de baja',
          content: `¿Estás seguro que desea dar de baja a ${nombre}?`,
          confirmText: 'Confirmar',
          cancelText: 'Cancelar',
        });
        if (confirm) {
          customFormDialog.show({
            title: 'Motivo de baja',
            renderComponent: (
              <EnrolledAlumnModal
                alumn={idInscripcionAlumno}
                reFetch={reFetch}
                badgeInscriptosReFetch={badgeInscriptosReFetch}
              />
            ),
            sizeWidth: 'md',
          });
        }
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Ha ocurrido un error al intentar guardar', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, reFetch, badgeInscriptosReFetch],
  );

  useEffect(() => {
    const alumns: Array<any> = inscripcionAlumno.map((item) => {
      const proyeccion = proyecciones.find(
        (element: any) => element.alumno.idAlumno === item.alumno.idAlumno,
      );

      const {
        idInscripcionAlumno,
        alumno: {
          idAlumno,
          persona: { nombre, apellido, documento },
          condicion: { descripcionCondicion },
        },
        turno: { descripcionTurno },
        nivel: { idNivel },
      } = item;

      const hasProyeccion = !!proyeccion ? 'Proyectado' : 'Sin proyección';

      const alumn = {
        idInscripcionAlumno,
        idAlumno,
        idAnio: item?.anio?.idAnio,
        descripcionAnio: item?.anio?.descripcionAnio,
        nombre: `${apellido}, ${nombre}`,
        documento,
        idCondicion: descripcionCondicion,
        idTurno: descripcionTurno,
        idNivel,
        idSeccion: !!proyeccion ? proyeccion.seccionDestino.idSeccion : null,
        selectedSeccion: null,
        proyeccion: hasProyeccion,
        cicloLectivo: !!proyeccion
          ? proyeccion.seccionDestino.cicloLectivo.anio
          : item?.cicloLectivo?.anio,
        razon: item.razon,
        createdAt: item.createdAt,
      };
      return alumn;
    });

    setAlumnRows(alumns);
  }, [alumnRows.length, inscripcionAlumno, proyecciones, seccion]);

  const { toLegajoAlumno } = useLegajoLogic();

  const filterTables = useCallback(
    (filterSearch: any) => {
      if (formValues.id_turno || formValues.id_anio) {
        const toReformFormValues = Object.entries(formValues).map(
          (filter: AnyObject) =>
            filter[1] && [{ path: filter[0], value: filter[1] }],
        );
        const toObjectLibby = {
          filterLocalization,
          filterLevel,
          ...filterSearch,
          filterEstadoMatricula,
          filterIsDeleted,
          filterCicloLectivo,
          ...toReformFormValues,
        };
        setFilterLibby(toObjectLibby);
      } else {
        setFilterLibby({
          0: filterLocalization,
          1: filterLevel,
          3: filterEstadoMatricula,
          4: filterIsDeleted,
          5: filterCicloLectivo,
          ...filterSearch,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formValues, filterCicloLectivo],
  );

  useEffect(() => {
    if (filterCicloLectivo[0].value) {
      filterTables(searchFilterParams);
    }
  }, [formValues, filterTables, searchFilterParams, filterCicloLectivo]);

  const handleChange = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setDirection(newDirection);
    setOrderBy(newOrderBy);
  };

  const handleSave = useCallback(async () => {
    // AKI EL MODAL
    const { matriculacionDate } = await customFormDialog.show<{
      matriculacionDate: string;
    }>({
      title: 'Matriculación',
      renderComponent: (
        <EnrolledDatePassModal
          alumnos={alumnsWithSections}
          cicloLectivo={cicloLectivoActual}
        />
      ),
      sizeWidth: 'md',
      customModalServices: {
        '& .MuiPaper-root': { overflowY: 'auto', overflowX: 'hidden' },
      },
    });

    if (!matriculacionDate) return;
    const confirm = await confirmDialog.show({
      title: 'Matricular',
      content:
        '¿Está seguro que desea matricular al/los alumno(s) seleccionado(s)?',
      confirmText: 'Confirmar',
      cancelText: 'Cancelar',
    });
    if (confirm) {
      setIsSaving(true);
      setProyeccionWorking(true);
      let alumnosToProyectar = [];
      let alumnosToMatricular: Alumno[] = [];
      for (let element of alumnsWithSections) {
        const proyeccion = proyecciones.find(
          (proyeccionElement: any) =>
            proyeccionElement.alumno.idAlumno === element.idAlumno,
        );

        if (element?.cicloLectivo === Number(CURRENT_YEAR)) {
          if (
            proyeccionEstado[0]?.proyeccionEstadoTipo
              ?.idProyeccionEstadoTipo === 2
          ) {
            await proyeccionEstadoDAO.save({
              idProyeccionEstado: proyeccionEstado[0]?.idProyeccionEstado,
              localizacion: {
                idLocalizacion: localizacionId,
              },
              proyeccionEstadoTipo: {
                idProyeccionEstadoTipo: 1,
              },
              cicloLectivo: proyeccionEstado[0]?.cicloLectivo?.idCicloLectivo,
              idNivel: nivel,
            });
          }

          if (!!proyeccion) {
            enqueueSnackbar(
              'El alumno se encuentra proyectado, no es posible realizar la matriculación.',
              {
                variant: 'error',
              },
            );
          } else {
            alumnosToMatricular.push({
              ...element,
              idAlumno: element.idAlumno,
              idSeccionOrigen: null,
              idSeccionDestino: element.idSeccion,
              idInscripcionAlumno: element.idInscripcionAlumno,
              matriculacionDate,
            });
          }
        } else if (
          proyeccionEstado[0]?.proyeccionEstadoTipo?.idProyeccionEstadoTipo ===
            1 &&
          rol === ROL.DIRECCION_AREA.toString() &&
          element?.idSeccion !== ''
        ) {
          if (!!proyeccion) {
            await ProyeccionDAO.save({
              idProyeccion: proyeccion.idProyeccion,
              alumno: element.idAlumno,
              articula: false,
              cicloLectivo: proyeccionEstado[0]?.cicloLectivo?.idCicloLectivo,
              estadoPaseAnio: 1, // promociona
              seccionDestino: element.idSeccion,
            });
          } else {
            await ProyeccionDAO.save({
              alumno: element.idAlumno,
              articula: false,
              cicloLectivo: proyeccionEstado[0]?.cicloLectivo?.idCicloLectivo,
              estadoPaseAnio: 1, // promociona
              seccionDestino: element.idSeccion,
            });
          }
        } else if (rol !== ROL.DIRECCION_AREA.toString()) {
          enqueueSnackbar(
            'Únicamente la dirección de área puede realizar proyecciones en inscriptos.',
            {
              variant: 'error',
            },
          );
        } else if (
          proyeccionEstado[0]?.proyeccionEstadoTipo?.idProyeccionEstadoTipo ===
          2
        ) {
          enqueueSnackbar(
            'No es posible proyectar al alumno, la proyección ya fue finalizada.',
            {
              variant: 'error',
            },
          );
        }
      }
      if (alumnosToMatricular.length > 0) {
        try {
          const response = await inscripcionAlumnoCustomDAO.matricular(
            alumnosToMatricular.map((am) => ({
              ...am,
              idSeccion: am.idSeccionDestino || '',
            })),
          );

          if (
            alumnosToProyectar.length > 0 &&
            Number(nivel) === level.SECUNDARIO
          ) {
            const response: any = await requestIgualarCalificaciones({
              data: alumnosToMatricular,
            });
            if (response?.shouldSeeSnackBar) {
              enqueueSnackbar(response?.text, {
                variant: 'success',
                autoHideDuration: 2000,
              });
            }
          }
          if (Array.isArray(response?.failed) && response?.failed.length > 0) {
            enqueueSnackbar(
              'Algunos alumnos no se pudieron matricular debido a que ya están matriculados en otro ciclo lectivo',
              {
                variant: 'warning',
              },
            );
          }
        } catch (error) {
          enqueueSnackbar(
            'Algunos alumnos no se pudieron matricular debido a que ya están matriculados en otro ciclo lectivo',
            {
              variant: 'error',
            },
          );
        } finally {
          setProyeccionWorking(false);
          setAlumnsWithSections([]);
          reFetch();
          reFetchProyeccion();
          setIsSaving(false);
          badgeInscriptosReFetch();
        }
      }
    }
  }, [
    alumnsWithSections,
    cicloLectivoActual,
    proyecciones,
    CURRENT_YEAR,
    proyeccionEstado,
    rol,
    proyeccionEstadoDAO,
    localizacionId,
    nivel,
    enqueueSnackbar,
    ProyeccionDAO,
    inscripcionAlumnoCustomDAO,
    requestIgualarCalificaciones,
    reFetch,
    reFetchProyeccion,
    badgeInscriptosReFetch,
  ]);

  const matricularValidation = useMemo(() => {
    let shouldBeDisabled = true;
    alumnsWithSections.forEach((element: any) => {
      if (element?.idSeccion === '') {
        shouldBeDisabled = true;
      } else {
        shouldBeDisabled = false;
      }
    });
    return shouldBeDisabled;
  }, [alumnsWithSections]);

  const handleChangeSearch = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setSearch(target.value);
  };

  const buttonConfig: any = [
    {
      title: 'Matricular',
      handleOnClick: () => handleSave(),
      size: 'large',
      disabled: !enabled || matricularValidation,
    },
  ];

  const handleAddStudent = () => {
    if (enabled) {
      customFormDialog.show({
        title: 'Agregar Estudiante',
        renderComponent: (
          <AddStudentModal
            isCommonSchool
            toLegajoAlumno={toLegajoAlumno}
            nivelId={nivel}
            cicloLectivo={cicloLectivoActual!}
            localizacionId={localizacionId}
          />
        ),
        sizeWidth: 'md',
        customModalServices: {
          '& .MuiPaper-root': { overflowY: 'auto', overflowX: 'hidden' },
        },
      });
    } else {
      enqueueSnackbar(
        'No es posible agregar un estudiante luego de el 1/10/2021 con su rol actual.',
        {
          variant: 'error',
        },
      );
    }
  };

  const handleChangeCicloLectivo = (e: any, row: any) => {
    const updatedAlumnRow = alumnRows.map((item: Alumno) =>
      item.idAlumno === row.idAlumno
        ? { ...item, cicloLectivo: e.target.value }
        : item,
    );
    setAlumnRows(updatedAlumnRow);
  };

  const columns = useInscripcionColumns(
    seccion,
    handleChangeSection,
    toLegajoAlumno,
    removeAlumn,
    editStudentBlocked,
    handleChangeCicloLectivo,
    ciclosLectivos,
    enabled,
  );

  const rowsToShow = () => {
    return inscripcionAlumnoWorking ? [] : alumnRows;
  };

  const allWorking = useMemo(() => {
    return workingP || proyeccionWorking || isSaving;
  }, [isSaving, proyeccionWorking, workingP]);

  return (
    <>
      <FilterTablesEnrolled
        year={anio}
        turn={turno}
        registersCount={registersCount}
        handleChange={handleChange}
        handleChangeSearch={handleChangeSearch}
        search={search}
        formValues={formValues}
        titleMainButton={
          rol === ROL.DIRECCION_AREA.toString()
            ? 'Agregar Estudiante'
            : undefined
        }
        handleOnClick={handleAddStudent}
      />
      <>
        {!allWorking && (
          <>
            <InfoTable
              rows={rowsToShow()}
              columns={columns}
              working={inscripcionAlumnoWorking}
              rowIdKey="id_seccion"
              direction={direction}
              onSortChange={handleRequestSort}
              customStyle={classes.infoTableCustom}
            />
            <TablePagination
              className={classes.paginationContainer}
              rowsPerPageOptions={[5, 10, 25, 100]}
              component="div"
              count={registersCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={(e) =>
                handleChangeRowsPerPage(parseInt(e.target.value))
              }
              labelRowsPerPage="Filas por página"
              labelDisplayedRows={({ from, to, count }) => {
                return registersWorking
                  ? `Calculando...`
                  : `${from} - ${to} de ${count}`;
              }}
            />
          </>
        )}
        {allWorking && <Loading />}

        <PermissionBlocker id={BLOCKER_ID.ADD_STUDENT}>
          <Grid className={classes.footer}>
            <Footer buttonConfig={buttonConfig} />
          </Grid>
        </PermissionBlocker>
      </>
    </>
  );
};

export const InitialEnrolled = DatabaseConnector(InitialEnrolledRaw)(
  'anio',
  'turno',
  'alumno',
  'inscripcion_alumno',
  'alumno_movimiento',
  'seccion',
);
