import { Container } from '@material-ui/core';
import React, { useCallback, useMemo, useEffect } from 'react';
import { ObservacionesBoletin } from '../../../../../../app/models/presentismo_v3/ObservacionesBoletin';
import { FormContextProvider } from '../../../../../../lib/templates';
import { ObservacionesForm } from './components';
import { ObservacionesBoletinTemplate } from './template';
import {
  IObservacionesBoletinInitialValue,
  ObservacionesModalProps,
} from './types';
import { Alumno, PeriodoNuevo } from 'src/app/models';
import moment from 'moment';
import { Loading, TIPO_PERIODO } from '../../../../../../commons';
import { useObservacionnesBoletinLibbyCall } from '../../../../../../app/business/presentismo_v3';

const initialValues: IObservacionesBoletinInitialValue = {
  observaciones: '',
  idPeriodoNuevo: 0,
  alumnoId: '',
};

export const ObservacionesBoletinModal = ({
  onSubmit,
  hasJornadaExtendidaDisabled,
  nivel,
  periodos,
  alumno,
  isJE,
}: ObservacionesModalProps) => {
  const currentPeriodo = useMemo(() => {
    const _currentPeriodo = periodos.filter(
      (periodo) =>
        moment(periodo.fechaFin, 'YYYY-MM-DD').isSameOrAfter(
          moment().format('YYYY-MM-DD'),
        ) &&
        moment(periodo.fechaInicio, 'YYYY-MM-DD').isSameOrBefore(
          moment().format('YYYY-MM-DD'),
        ),
    );

    const index = periodos.findIndex(
      (periodo) =>
        periodo.idPeriodoNuevo === _currentPeriodo[0]?.idPeriodoNuevo,
    );
    let afterPeriodo: PeriodoNuevo | undefined = undefined;
    let beforePeriodo: PeriodoNuevo | undefined = undefined;
    if (index !== -1) {
      afterPeriodo = periodos[index + 1];
      beforePeriodo = periodos[index - 1];
    }

    let periodo =
      _currentPeriodo[0]?.tipoPeriodo.idTipoPeriodo ===
      TIPO_PERIODO.RECESO_INVERNAL
        ? beforePeriodo
        : _currentPeriodo[0];

    if (!beforePeriodo) {
      const periodosOld = periodos
        .filter((periodo) =>
          moment(periodo.fechaFin, 'YYYY-MM-DD').isSameOrBefore(
            moment().format('YYYY-MM-DD'),
          ),
        )
        .reverse();
      periodo = periodosOld[0];
    }

    return periodo;
  }, [periodos]);

  const {
    data: [observacion] = [],
    working,
    recall,
  } = useObservacionnesBoletinLibbyCall({
    methodName: 'getByAlumno',
    params: [],
    noAutoCall: true,
  });

  useEffect(() => {
    if (
      !currentPeriodo ||
      currentPeriodo.tipoPeriodo.idTipoPeriodo === TIPO_PERIODO.RECESO_INVERNAL
    )
      return;
    const params = [
      alumno.idAlumno,
      nivel,
      isJE,
      currentPeriodo.idPeriodoNuevo,
    ];
    recall(...params);
  }, [currentPeriodo, recall, isJE, nivel, alumno]);

  const _initialValues = useMemo(() => {
    const _initials: IObservacionesBoletinInitialValue = initialValues;
    _initials.idPeriodoNuevo =
      observacion?.periodoNuevo?.idPeriodoNuevo ||
      currentPeriodo?.idPeriodoNuevo ||
      0;
    _initials.observaciones = observacion?.observacion || '';
    _initials.alumnoId = alumno.idAlumno;
    return _initials;
  }, [observacion, currentPeriodo, alumno]);

  const handleSubmit = useCallback(
    (values: IObservacionesBoletinInitialValue) => {
      onSubmit({ ...(observacion ? observacion : {}), ...values });
    },
    [onSubmit, observacion],
  );

  return (
    <Container maxWidth="lg">
      {working ? (
        <Loading />
      ) : (
        <FormContextProvider
          initialValues={_initialValues}
          onSubmit={handleSubmit}
          template={ObservacionesBoletinTemplate}
        >
          <ObservacionesForm
            hasJornadaExtendidaDisabled={hasJornadaExtendidaDisabled}
            nivel={nivel}
            isJE={isJE}
          />
        </FormContextProvider>
      )}
    </Container>
  );
};
