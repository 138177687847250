import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { PromocionPaseAnio } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'promocion_pase_anio';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPromocionPaseAnio',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPromocionPaseAnio',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.PromocionPaseAnio
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.PromocionPaseAnio,
      hoc: { ...defaultOptions.hoc, ...businessOptions.PromocionPaseAnio.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<PromocionPaseAnio>(DAO_NAME, optionsToUse);

// hooks
export const usePromocionPaseAnioFetchById = fetchById;
export const usePromocionPaseAnioLibbyCall = libbyCall;
export const usePromocionPaseAnioDAO = libbyDAO;
export const usePromocionPaseAnioLibbyFetch = libbyFetch;

// components/hoc
export const PromocionPaseAnioAutocomplete = entityAutocomplete;
export const PromocionPaseAnioCall = renderPropCall;
export const PromocionPaseAnioById = renderPropFetchById;
export const PromocionPaseAnioSelect = simpleSelect;
export const PromocionPaseAnioAccordionSelector = accordionSelector;
export const PromocionPaseAnioSelectedChips = selectedChips;

// context
// ByIdContext
export const usePromocionPaseAnioByIdContext = useByIdContext;
export const PromocionPaseAnioByIdProvider = ByIdProvider;
export const PromocionPaseAnioByIdContext = ByIdContext;
// GeneralContext
export const usePromocionPaseAnioContext = useGeneralContext;
export const PromocionPaseAnioProvider = GeneralProvider;
export const PromocionPaseAnioContext = GeneralContext;
