import { InformesHistory } from '../../../../app/models';
import { drawEscudo } from '../../../../commons';

const replacer = (template: string) =>
  template
    ?.replace(
      '<img src="https://www.buenosaires.gob.ar/sites/gcaba/files/escudo.jpg" width="100%">',
      drawEscudo({ isWatermark: true }),
    )
    .replace(
      '<img src="https://www.buenosaires.gob.ar/sites/gcaba/files/escudo.jpg" width="60px" height="70px" style="filter: brightness(1.1); mix-blend-mode: multiply;">',
      `<div style="justify-content: center; display: flex; padding-top: 10px; height: 45px; width: 39px; margin: 0 auto;">${drawEscudo(
        { isWatermark: false },
      )}</div>`,
    )
    .replace(
      '<img src="https://www.buenosaires.gob.ar/sites/gcaba/files/escudo.jpg" width="50px" height="55px" style="filter: brightness(1.1); mix-blend-mode: multiply;">',
      `<div style="justify-content: center; display: flex; padding-top: 10px; height: 45px; width: 39px; margin: 0 auto;">${drawEscudo(
        { isWatermark: false },
      )}</div>`,
    )
    .replace(
      ' <img src="https://www.buenosaires.gob.ar/sites/gcaba/files/escudo.jpg" width="50px" height="55px">',
      `<div style="justify-content: center; display: flex; padding-top: 10px; height: 45px; width: 39px; margin: 0 auto;">${drawEscudo(
        { isWatermark: false },
      )}</div>`,
    )
    .replace(
      `.marca-agua{
              position: fixed;
              top: 0%;
              left: 0%;
              margin: -1.5%;
              width: 100vw;
              height: 100vh;
              filter: opacity(0.0);
              pointer-events: none;
            }`,
      `.marca-agua {
          position: fixed;
          top: 0%;
          left: 2%;
          margin: 12%;
          width: 80vw;
          filter: opacity(0.1);
          pointer-events: none;
        }`,
    );
/**
 * Remplaza las imagen del escudo y style de los documentos antiguos
 *  */
export const fixTemplate = (data: InformesHistory[]): InformesHistory[] =>
  data.map((informe) => {
    return {
      ...informe,
      preview: {
        ...informe.preview,
        template: replacer(informe?.preview?.template || ''),
      },
    };
  });
