import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { EspacioCurricularSeccion } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'espacio_curricular_seccion';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEspacioCurricularSeccion',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEspacioCurricularSeccion',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EspacioCurricularSeccion
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EspacioCurricularSeccion,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.EspacioCurricularSeccion.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EspacioCurricularSeccion>(DAO_NAME, optionsToUse);

// hooks
export const useEspacioCurricularSeccionFetchById = fetchById;
export const useEspacioCurricularSeccionLibbyCall = libbyCall;
export const useEspacioCurricularSeccionDAO = libbyDAO;
export const useEspacioCurricularSeccionLibbyFetch = libbyFetch;

// components/hoc
export const EspacioCurricularSeccionAutocomplete = entityAutocomplete;
export const EspacioCurricularSeccionCall = renderPropCall;
export const EspacioCurricularSeccionById = renderPropFetchById;
export const EspacioCurricularSeccionSelect = simpleSelect;
export const EspacioCurricularSeccionAccordionSelector = accordionSelector;
export const EspacioCurricularSeccionSelectedChips = selectedChips;

// context
// ByIdContext
export const useEspacioCurricularSeccionByIdContext = useByIdContext;
export const EspacioCurricularSeccionByIdProvider = ByIdProvider;
export const EspacioCurricularSeccionByIdContext = ByIdContext;
// GeneralContext
export const useEspacioCurricularSeccionContext = useGeneralContext;
export const EspacioCurricularSeccionProvider = GeneralProvider;
export const EspacioCurricularSeccionContext = GeneralContext;
