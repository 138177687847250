import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Content, TabBar, useRouteScreenTitle } from 'src/commons';
import { useAcapEstablecimientoContext } from '../context/AcapEstablecimientoContext';
import { ACAP_SCREEN, ACCIONES_SCREEN } from '../context/type';
import { convertFromRaw } from 'draft-js';
import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
// @ts-ignore
import { MUIEditorState } from 'react-mui-draft-wysiwyg';
import { useAccionesDAO, useTiposAccionesLibbyCall } from 'src/app/business';
import { useAccionesMinimizadasLibbyCall } from 'src/app/business/acap/AccionesMinimizadas';
import { useHabilidadesLibbyCall } from 'src/app/business/orientaciones/Habilidades';
import { useNodosLibbyCall } from 'src/app/business/orientaciones/Nodos';
import { useOrientacionesLibbyCall } from 'src/app/business/orientaciones/Orientaciones';
import { usePerfilOrientadoLibbyCall } from 'src/app/business/orientaciones/PerfilOrientado';
import {
  Accion,
  AccionOrientacion,
  AccionOrientacionMinimizada,
  AccionesMinimizadas,
} from 'src/app/models';
import { DEFAULT_HIDE_TIME } from 'src/commons/const/ui';
import { DeepPartial } from 'src/commons/types/DeepPartial';
import { useLibbyCall } from 'src/lib/libby';
import { DetalleAccionForm } from './components/DetalleAccionForm';
import { TableContent } from './components/TableContent';
import { accionesInitialValue } from './initialValues';
import { AccionNames, AccionesFormProps, AccionesFormValues } from './types';
import confirmDialog from 'src/commons/services/confirmDialog';

const DUPLICATE_ERRORS = {
  EXISTS_ERROR:
    'Ya existe una acción con los mismos parámetros en el ciclo lectivo elegido',
  NAME_ERROR: 'La acción duplicada no puede tener el mismo nombre',
};

export const AccionesForm = ({
  initialValue,
  editorConfig,
  loading = false,
  handleSubmit,
}: AccionesFormProps) => {
  const { screen, accionesScreen, ciclosLectivos, setScreen } =
    useAcapEstablecimientoContext();
  const [formValues, setFormValues] = useState<AccionesFormValues>(() => ({
    ...initialValue,
  }));

  useEffect(() => {
    setFormValues(initialValue);
  }, [initialValue]);

  const { data: orientaciones = [], working: workingOrientaciones } =
    useOrientacionesLibbyCall({ methodName: 'getAll' });
  const { data: tiposAcciones = [], working: workingTiposAcciones } =
    useTiposAccionesLibbyCall({ methodName: 'getAll' });
  const { data: accionesNombres = [], working: workingAccionesNombres } =
    useLibbyCall<AccionNames>({
      daoName: 'acciones',
      methodName: 'getAllNames',
    });

  const {
    data: nodos = [],
    // recall: nodosRefetch,
    working: workingNodos,
  } = useNodosLibbyCall({
    methodName: 'fetch',
    params: [
      {
        limit: 5000,
        orderBy:
          'bloque.idBloque,eje.idEje,unidad.idUnidad,contenido.idContenido',
      },
    ],
  });
  const {
    data: habilidades = [],
    // recall: habilidadesReFetch,
    working: workingHabilidades,
  } = useHabilidadesLibbyCall({
    methodName: 'fetch',
    params: [
      {
        limit: 5000,
      },
    ],
  });
  const {
    data: perfilOrientado = [],
    // recall: perfilOrientadoReFetch,
    working: workingPerfiles,
  } = usePerfilOrientadoLibbyCall({
    methodName: 'fetch',
    params: [
      {
        limit: 5000,
      },
    ],
  });

  const loadingGeneral =
    workingOrientaciones ||
    workingTiposAcciones ||
    workingAccionesNombres ||
    workingNodos ||
    workingHabilidades ||
    workingPerfiles;

  const isDisabledToSave = useMemo(() => {
    const { accionOrientaciones, ...rest } = formValues;
    const { accionOrientaciones: aoInitiales, ...iniValues } = initialValue;

    const isEqualValues = isEqual(rest, iniValues);

    const isEquaslAO = isEqual(
      JSON.stringify(accionOrientaciones),
      JSON.stringify(aoInitiales),
    );

    const isEmpty =
      formValues?.tipoAccion === 0 ||
      formValues?.nombre === '' ||
      formValues?.accionOrientaciones?.length === 0 ||
      formValues?.descripcion === '' ||
      formValues?.horas === 0 ||
      !Boolean(formValues?.cicloLectivo);

    return (isEqualValues && isEquaslAO) || isEmpty;
  }, [formValues, initialValue]);

  const handleCancel = useCallback(async () => {
    // [MIESC-3233] el pr hace que se vuelva atras sin preguntar si qiuere salir
    if (initialValue.cicloLectivo === 23) {
      setScreen(ACAP_SCREEN.ORGANIZACIONES);
      return;
    }
    if (isDisabledToSave) {
      setScreen(ACAP_SCREEN.ORGANIZACIONES);
    } else if (initialValue.cicloLectivo !== 23) {
      const confirm = await confirmDialog.show({
        title: '¿Desea continuar?',
        content:
          'La información precargada se perderá en caso que no guarde, por favor seleccione una opción.',
        confirmText: 'Salir sin guardar',
        cancelText: 'Seguir en esta pantalla',
      });
      if (confirm) {
        setScreen(ACAP_SCREEN.ORGANIZACIONES);
      }
    }
  }, [initialValue.cicloLectivo, isDisabledToSave, setScreen]);

  const tabs = useMemo<Content[]>(
    () => [
      {
        title: 'Detalle de la Acción',
        component: (
          <DetalleAccionForm
            formValues={formValues}
            ciclosLectivos={ciclosLectivos}
            tipoAcciones={tiposAcciones}
            orientaciones={orientaciones}
            accionesNombre={accionesNombres}
            editorConfig={editorConfig}
            setFormValues={setFormValues}
            loading={loadingGeneral || loading}
            onlyView={accionesScreen.screen === ACCIONES_SCREEN.VIEW}
            isDublicating={accionesScreen.screen === ACCIONES_SCREEN.DUPLICATE}
            handleCancel={handleCancel}
            handleSubmit={() => handleSubmit(formValues)}
            disabled={isDisabledToSave}
          />
        ),
        hidden: screen === ACAP_SCREEN.ORGANIZACIONES,
      },
      {
        title: 'Perfil del Egresado',
        component: (
          <TableContent
            titleModal="PERFIL DEL EGRESADO"
            type="perfiles"
            nombreColumna="Perfil del Egresado"
            formValues={formValues}
            initialValues={initialValue}
            setFormValues={setFormValues}
            contenidos={perfilOrientado}
            handleSubmit={handleSubmit}
            loading={loading}
            disabled={isDisabledToSave}
            onlyView={accionesScreen.screen === ACCIONES_SCREEN.VIEW}
          />
        ),
        disabled:
          !accionesScreen.data ||
          accionesScreen.screen === ACCIONES_SCREEN.DUPLICATE,
        hidden: screen === ACAP_SCREEN.ORGANIZACIONES,
      },
      {
        title: 'Habilidades',
        component: (
          <TableContent
            titleModal="HABILIDADES, CAPACIDADES, COMPETENCIAS"
            type="habilidades"
            nombreColumna="Competencias"
            formValues={formValues}
            initialValues={initialValue}
            setFormValues={setFormValues}
            contenidos={habilidades}
            handleSubmit={handleSubmit}
            loading={loading}
            disabled={isDisabledToSave}
            onlyView={accionesScreen.screen === ACCIONES_SCREEN.VIEW}
          />
        ),
        disabled:
          !accionesScreen.data ||
          accionesScreen.screen === ACCIONES_SCREEN.DUPLICATE,
        hidden: screen === ACAP_SCREEN.ORGANIZACIONES,
      },
      {
        title: 'Contenidos',
        component: (
          <TableContent
            titleModal="CONTENIDOS"
            type="nodos"
            nombreColumna="Contenido"
            formValues={formValues}
            initialValues={initialValue}
            setFormValues={setFormValues}
            contenidos={nodos.filter(
              (nodo) =>
                Boolean(nodo.orientacion) &&
                Boolean(nodo.bloque) &&
                Boolean(nodo.eje) &&
                Boolean(nodo.unidad) &&
                Boolean(nodo.contenido),
            )}
            loading={loading}
            handleSubmit={handleSubmit}
            disabled={isDisabledToSave}
            onlyView={accionesScreen.screen === ACCIONES_SCREEN.VIEW}
          />
        ),
        disabled:
          !accionesScreen.data ||
          accionesScreen.screen === ACCIONES_SCREEN.DUPLICATE,
        hidden: screen === ACAP_SCREEN.ORGANIZACIONES,
      },
    ],
    [
      accionesNombres,
      accionesScreen.data,
      accionesScreen.screen,
      ciclosLectivos,
      editorConfig,
      formValues,
      habilidades,
      handleCancel,
      handleSubmit,
      initialValue,
      isDisabledToSave,
      loading,
      loadingGeneral,
      nodos,
      orientaciones,
      perfilOrientado,
      screen,
      tiposAcciones,
    ],
  );

  return <TabBar content={tabs} />;
};

const editorConfig = {};
let rawContent = {
  blocks: [
    {
      data: {},
      depth: 0,
      entityRanges: [],
      inlineStyleRanges: [],
      key: '2vm6d',
      text: '',
      type: '',
    },
  ],
  entityMap: {},
};

export const Acciones = () => {
  const { accionesScreen, organizacionesScreen, setAccionesScreen } =
    useAcapEstablecimientoContext();
  const accionesDAO = useAccionesDAO();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  let titleScreen = '';

  switch (accionesScreen.screen) {
    case ACCIONES_SCREEN.VIEW:
      titleScreen = 'Ver Acción';
      break;
    case ACCIONES_SCREEN.NEW:
      titleScreen = 'Crear Acción';
      break;
    case ACCIONES_SCREEN.EDIT:
      titleScreen = 'Editar Acción';
      break;
    case ACCIONES_SCREEN.DUPLICATE:
      titleScreen = 'Duplicar Acción';
      break;
    default:
      titleScreen = 'Actividades de aproximación';
      break;
  }
  useRouteScreenTitle(titleScreen);

  const initialValues = useMemo(() => {
    const orientacionesMap = accionesScreen?.data?.accionOrientaciones.map(
      (orientacion) => {
        const nodos = orientacion?.nodos;
        const habilidades = orientacion?.habilidades;
        const perfiles = orientacion?.perfiles;
        return {
          idOrientacion: orientacion?.orientacion?.idOrientacion,
          nombreOrientacion: orientacion?.orientacion?.nombre,
          status: true,
          idAccionOrientacion: orientacion?.idAccionOrientacion ?? undefined,
          nodos,
          habilidades,
          perfiles,
        };
      },
    );

    let editorInitialValue: any;

    if (accionesScreen?.data?.descripcion) {
      try {
        rawContent = JSON.parse(accionesScreen?.data?.descripcion);
      } catch (e) {
        rawContent.blocks[0].text = accionesScreen?.data?.descripcion;
      }
      editorInitialValue = MUIEditorState.createWithContent(
        editorConfig,
        convertFromRaw(rawContent),
      );
    } else {
      editorInitialValue = MUIEditorState.createEmpty();
    }

    const _initialValues: AccionesFormValues = {
      ...accionesInitialValue,
      idAccion: accionesScreen?.data?.idAccion,
      tipoAccion: accionesScreen?.data?.tipoAccion?.idTipoAccion || 0,
      nombre: accionesScreen?.data?.nombre || '',
      accionOrientaciones: orientacionesMap || [],
      descripcion: accionesScreen?.data?.descripcion || '',
      horas: accionesScreen?.data?.horas || 0,
      cicloLectivo: accionesScreen?.data?.cicloLectivo.idCicloLectivo || 0,
      idOrganizacion: organizacionesScreen.data?.idOrganizacion || 0,
      organizacionName: organizacionesScreen.data?.nombre || '',
      sectorName: organizacionesScreen.data?.sector.nombre || '',
      autoCompleteValue: { entity_nombre: '' },
      autoCompleteInputValue: accionesScreen?.data?.nombre || '',
      editorTextValue: editorInitialValue,
    };
    return _initialValues;
  }, [
    accionesScreen?.data?.accionOrientaciones,
    accionesScreen?.data?.cicloLectivo.idCicloLectivo,
    accionesScreen?.data?.descripcion,
    accionesScreen?.data?.horas,
    accionesScreen?.data?.idAccion,
    accionesScreen?.data?.nombre,
    accionesScreen?.data?.tipoAccion?.idTipoAccion,
    organizacionesScreen.data?.idOrganizacion,
    organizacionesScreen.data?.nombre,
    organizacionesScreen.data?.sector.nombre,
  ]);

  const { recall: reFetchAcciones } = useAccionesMinimizadasLibbyCall({
    methodName: 'fetch',
    noAutoCall: true,
  });

  const checkDuplicates = useCallback(
    async (values: AccionesFormValues): Promise<string> => {
      const filter = {
        filter: {
          0: [
            {
              path: 'organizacion',
              value: organizacionesScreen.data?.idOrganizacion,
            },
          ],
          1: [
            {
              path: 'tipoAccion',
              value: values?.tipoAccion,
            },
          ],
          2: [
            {
              path: 'cicloLectivo',
              value: values?.cicloLectivo,
            },
          ],
          3: [
            {
              path: 'horas',
              value: values?.horas,
            },
          ],
        },
      };

      if (
        values.nombre === initialValues.nombre &&
        values.cicloLectivo === initialValues.cicloLectivo
      ) {
        return DUPLICATE_ERRORS.NAME_ERROR;
      }
      const results = await reFetchAcciones(filter);

      if (results.length > 0) {
        const hasSameDescription = !!results.find(
          (res) => res.descripcion === values.descripcion,
        );

        const sameCicloLectivo = !!results.find(
          (res) => res.cicloLectivo.idCicloLectivo === values.cicloLectivo,
        );
        const hasSameName = !!results.find(
          (res) => res.nombre === values.nombre,
        );

        if (hasSameName && sameCicloLectivo) {
          return DUPLICATE_ERRORS.NAME_ERROR;
        }
        const idsOrientaciones = values.accionOrientaciones.map(
          (or) => or.idOrientacion,
        );
        const hasSameOrientations = !!results.find((res) => {
          const resultIdsOrientaciones = res.accionOrientaciones.map(
            (or) => or.orientacion.idOrientacion,
          );
          return isEqual(idsOrientaciones, resultIdsOrientaciones);
        });

        if (hasSameDescription && hasSameOrientations && sameCicloLectivo) {
          return DUPLICATE_ERRORS.EXISTS_ERROR;
        }
      }

      return '';
    },
    [
      initialValues.cicloLectivo,
      initialValues.nombre,
      organizacionesScreen.data?.idOrganizacion,
      reFetchAcciones,
    ],
  );

  const handleSubmit = useCallback<(values: AccionesFormValues) => void>(
    async (formValues) => {
      try {
        const isDuplicating =
          accionesScreen.screen === ACCIONES_SCREEN.DUPLICATE;
        const hasSameAccion = await checkDuplicates(formValues);

        if (isDuplicating && !!hasSameAccion) {
          throw new Error(hasSameAccion);
        }
        setLoading(true);
        const orientaciones: DeepPartial<AccionOrientacion>[] = [];
        formValues.accionOrientaciones.forEach((accionOrientacion) => {
          const oldValue = initialValues.accionOrientaciones.find(
            (ao) =>
              ao.idAccionOrientacion === accionOrientacion.idAccionOrientacion,
          );

          const isEditedNodes = !isEqual(
            JSON.stringify(accionOrientacion.nodos),
            JSON.stringify(oldValue?.nodos),
          );
          const isEditedHabilidad = !isEqual(
            JSON.stringify(accionOrientacion.habilidades),
            JSON.stringify(oldValue?.habilidades),
          );
          const isEditedPerfil = !isEqual(
            JSON.stringify(accionOrientacion.perfiles),
            JSON.stringify(oldValue?.perfiles),
          );

          orientaciones.push({
            idAccionOrientacion: accionOrientacion?.idAccionOrientacion,
            orientacion: { idOrientacion: accionOrientacion.idOrientacion },
            ...(isEditedPerfil || isDuplicating
              ? {
                  perfiles: accionOrientacion.perfiles,
                }
              : {}),
            ...(isEditedNodes || isDuplicating
              ? {
                  nodos: accionOrientacion.nodos,
                }
              : {}),
            ...(isEditedHabilidad || isDuplicating
              ? {
                  habilidades: accionOrientacion.habilidades,
                }
              : {}),
          });
        });

        const toSaveAccion: DeepPartial<Accion> = {
          ...(Boolean(formValues?.idAccion)
            ? { idAccion: formValues?.idAccion }
            : {}),
          organizacion: { idOrganizacion: formValues.idOrganizacion },
          tipoAccion: { idTipoAccion: formValues?.tipoAccion },
          nombre: formValues?.nombre || formValues.autoCompleteInputValue,
          descripcion: formValues?.descripcion,
          horas: formValues?.horas,
          accionOrientaciones: orientaciones,
          cicloLectivo: { idCicloLectivo: formValues.cicloLectivo },
        };

        const accionSaved: Accion = await accionesDAO
          .aspect('save-accion')
          .save(toSaveAccion);

        const { accionOrientaciones, ...rest } = accionSaved;
        const mapped =
          formValues.accionOrientaciones.map<AccionOrientacionMinimizada>(
            (ao) => {
              const newValor = accionOrientaciones.find(
                (newAo) => newAo.orientacion.idOrientacion === ao.idOrientacion,
              );

              const newValue: AccionOrientacionMinimizada = {
                ...ao,
                idAccion: rest.idAccion,
                orientacion: {
                  idOrientacion: ao.idOrientacion,
                  nombre: ao.nombreOrientacion,
                },
                idAccionOrientacion: newValor?.idAccionOrientacion || 0,
                ...(newValor ? newValor : {}),
              };
              return newValue;
            },
          );

        setAccionesScreen((prev) => {
          const newData: AccionesMinimizadas = {
            ...(prev.data ? prev.data : {}),
            ...rest,
            organizacion: organizacionesScreen.data!,
            accionOrientaciones: mapped,
          };

          return {
            ...prev,
            data: newData,
          };
        });
        const message = isDuplicating
          ? '¡La acción se duplicó con éxito!'
          : 'Se ha guardado con éxito';
        enqueueSnackbar(message, {
          variant: 'success',
          autoHideDuration: DEFAULT_HIDE_TIME,
        });
      } catch (error) {
        const err = error as any;
        let messagge =
          'Ha ocurrido un error al guardar. Por favor inténtelo nuevamente.';
        if (
          err.message === DUPLICATE_ERRORS.NAME_ERROR ||
          err.message === DUPLICATE_ERRORS.EXISTS_ERROR
        ) {
          messagge = err.message;
        }
        enqueueSnackbar(messagge, {
          variant: 'error',
          autoHideDuration: DEFAULT_HIDE_TIME,
        });
      } finally {
        setLoading(false);
      }
    },
    [
      accionesDAO,
      accionesScreen.screen,
      checkDuplicates,
      enqueueSnackbar,
      initialValues.accionOrientaciones,
      organizacionesScreen.data,
      setAccionesScreen,
    ],
  );

  return (
    <AccionesForm
      initialValue={initialValues}
      editorConfig={editorConfig}
      handleSubmit={handleSubmit}
      loading={loading}
    />
  );
};
