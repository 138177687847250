import { useEffect, useState } from 'react';
import moment from 'moment/moment';
import { obtenerContraturno, obtenerTurno } from '../utils';
import { razonJustificacionEstadoPresente } from '../components';
import { JustificacionInitialValues, TurnoOptions } from '../types';
import { Presente_v3 } from '../../../../../app/models';
import { FormApi } from 'final-form';

interface UsePreSelectTurnoProps {
  values: JustificacionInitialValues;
  presentismosAll: Presente_v3[];
  contraturnosOptions: TurnoOptions[];
  form: FormApi<
    JustificacionInitialValues,
    Partial<JustificacionInitialValues>
  >;
}

export const usePreSelectTurno = ({
  values,
  presentismosAll,
  contraturnosOptions,
  form,
}: UsePreSelectTurnoProps) => {
  const [firstLoad, setFirstLoad] = useState(true); // primera carga

  useEffect(() => {
    if (!firstLoad) return;
    const idEstadoPresente =
      razonJustificacionEstadoPresente[values.razonJustificacion]?.toString();

    if (idEstadoPresente && values.fechaDesde && values.fechaHasta) {
      const presentismosBetweenRange = presentismosAll.filter((a) =>
        moment(a.fecha, 'YYYY-MM-DD').isBetween(
          moment(values.fechaDesde, 'YYYY-MM-DD'),
          moment(values.fechaHasta, 'YYYY-MM-DD'),
          'days',
          '[]',
        ),
      );
      const idTurno = obtenerTurno(+idEstadoPresente, presentismosBetweenRange);

      const idsContraturnosJustificacion = obtenerContraturno(
        +idEstadoPresente,
        presentismosBetweenRange,
      );

      if (
        idsContraturnosJustificacion.length > 0 &&
        !values.contraturno.length
      ) {
        form.change(
          'contraturno',
          idsContraturnosJustificacion.map((ct) => ct.value?.toString()),
        );
        setFirstLoad(false);
      }

      if (idTurno.length > 0 && !values.turno.length) {
        form.change('turno', idTurno);
        setFirstLoad(false);
      }
    }
  }, [
    values.razonJustificacion,
    values.fechaDesde,
    values.fechaHasta,
    values.turno,
    values.contraturno,
    presentismosAll,
    form,
    contraturnosOptions,
    firstLoad,
  ]);
};
