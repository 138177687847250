import React, { useCallback, useMemo, useEffect } from 'react';
import {
  useAlumnoMovimientoLibbyCall,
  useEspacioCurricularSeccionLibbyCall,
} from 'src/app/business';
import { useRolesContext } from 'src/context/RolesContext';
import { Loading } from 'src/commons/components/Loading';
import { Grid } from '@material-ui/core';
import { useRouteScreenTitle } from 'src/commons';
import { SeccionAulaVirtual } from 'src/app/models';
import { EspacioCurricularCard } from '../EspaciosCurriculares/components';

interface OnClickClassroom {
  seccionAulaVirtuals: SeccionAulaVirtual[];
  idEspacioCurricularSeccion: string;
}

export const AulasVirtuales = () => {
  useRouteScreenTitle('Aulas Virtuales');
  const { userInfo } = useRolesContext();

  const {
    data: alumnoMovimiento = [],
    working: workingAlumnoMovimiento,
    recall: recallAlumno,
  } = useAlumnoMovimientoLibbyCall({
    methodName: 'getByIdCuenta',
    params: [userInfo.id],
    aspect: 'limit_plan',
    noAutoCall: true,
  });

  useEffect(() => {
    if (userInfo.id) recallAlumno(userInfo.id);
  }, [userInfo.id, recallAlumno]);

  const filterMagnament = useMemo(
    () => ({
      seccion: alumnoMovimiento.map((item) => ({
        path: 'seccion',
        value: item.seccion.idSeccion,
      })),
    }),
    [alumnoMovimiento],
  );

  const {
    data: aulasVirtuales = [],
    working,
    recall,
  } = useEspacioCurricularSeccionLibbyCall({
    methodName: 'getBySecciones',
    params: [],
    noAutoCall: true,
  });

  useEffect(() => {
    if (alumnoMovimiento.length) {
      recall(filterMagnament);
    }
  }, [alumnoMovimiento, recall, filterMagnament]);

  const onCardClick = useCallback(
    (value: OnClickClassroom) =>
      window.open(
        value.seccionAulaVirtuals?.[0]?.classroomMetadata?.alternateLink || '',
        '_blank',
      ),
    [],
  );

  const filterAulas = useMemo(
    () => aulasVirtuales.filter((aula) => aula.seccionAulaVirtuals.length),
    [aulasVirtuales],
  );

  return (
    <>
      {workingAlumnoMovimiento || working ? (
        <Loading />
      ) : (
        <Grid container spacing={2}>
          {filterAulas.map(({ espacioCurricular, seccion, ...rest }) => (
            <Grid item xs={3} key={rest.idEspacioCurricularSeccion}>
              <EspacioCurricularCard
                secciones={[seccion]}
                espacioCurricular={espacioCurricular}
                onClick={() => onCardClick(rest)}
              />
            </Grid>
          ))}
        </Grid>
      )}
    </>
  );
};
