/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormInput } from '../../../../../../../../../lib/templates/components';
import { useArealInterArealForm } from '../../../ArealInterareal/useArealInterArealForm';

const useStyles = makeStyles({
  buttonRoot: {
    fontSize: 16,
    padding: '9px 12px',
  },
  container: {
    padding: '15px',
  },
});

export const Proyecto = (): JSX.Element => {
  const classes = useStyles();
  const { errors, values, form } = useArealInterArealForm();
  return (
    <Grid
      container
      direction="column"
      spacing={4}
      className={classes.container}
    >
      <Grid item>
        <FormInput
          label="Nombre"
          name="proyecto.nombre"
          form={form}
          placeholder="Escribí el nombre del proyecto"
        />
      </Grid>
      <Grid item>
        <FormInput
          label="Descripción"
          name="proyecto.descripcion"
          form={form}
          placeholder="Describí de que trata el proyecto"
        />
      </Grid>
    </Grid>
  );
};
