import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import {
  Footer,
  InfoTable,
  MainButton,
  Tooltips,
} from 'src/commons/components';
import customFormDialog from 'src/commons/services/customFormDialog';
import _ from 'lodash';
import { level } from 'src/commons/const';
import { Proyeccion, Seccion } from 'src/app/models';

const useStyles = makeStyles(() => ({
  borderGap: {
    padding: '1em 1em 0em 1em',
  },
  modalButton: {
    marginTop: '1em',
  },
}));

const findBusyProyectedColumn = (columns: any[]) => {
  const index = columns.findIndex(
    (column: any) => column.id === 'busy_projected',
  );
  const busyColumn = columns.find(
    (column: any) => column.id === 'busy_projected',
  );
  return { index, busyColumn };
};

const findVacanciesProyectedColumn = (columns: any[]) => {
  const index = columns.findIndex(
    (column: any) => column.id === 'vacancy_projected',
  );
  const vacanciesColumn = columns.find(
    (column: any) => column.id === 'vacancy_projected',
  );
  return { index, vacanciesColumn };
};

const modifyBusyProyectedColumn = (
  vacanciesProyected: any,
  promocion_pase_anio: any[],
  nivel: any = 0,
) => {
  let busyProyectedModified = _.cloneDeep(vacanciesProyected);
  const render = (value: any, row: any) => {
    let promotionPassAnio = promocion_pase_anio.filter(
      (element: any) =>
        element?.seccionDestino?.division === row?.division &&
        element?.seccionDestino?.cicloLectivo?.anio ===
          row?.cicloLectivo?.anio &&
        element?.seccionDestino?.anio?.idAnio === row?.anio?.idAnio &&
        element?.seccionDestino?.jornada === row?.jornada &&
        element?.seccionDestino?.turno?.descripcionTurno ===
          row?.turno?.descripcionTurno,
    );

    if (promotionPassAnio.length === 0) {
      promotionPassAnio = promocion_pase_anio.filter(
        (element: any) => element?.seccionDestino?.idSeccion === row?.idSeccion,
      );
    }

    const permanecen = promotionPassAnio.filter(
      (item: any) =>
        item.estadoPaseAnio.descripcionEstadoPaseAnio === 'Permanece',
    );
    const promocionan = promotionPassAnio.filter(
      (item: any) =>
        item.estadoPaseAnio.descripcionEstadoPaseAnio === 'Promoción directa' ||
        item.estadoPaseAnio.descripcionEstadoPaseAnio === 'Promociona',
    );
    const promocionAcompaniada = promotionPassAnio.filter(
      (item: any) =>
        item.estadoPaseAnio.descripcionEstadoPaseAnio ===
        'Promoción acompañada',
    );

    const promocionanConApoyo = promotionPassAnio.filter(
      (item: any) =>
        item.estadoPaseAnio.descripcionEstadoPaseAnio ===
        'Con promoción acompañada',
    );

    return (
      <Tooltips
        title={
          <Grid>
            <p>
              <span style={{ fontWeight: 'bold', marginRight: '1em' }}>
                Permanecen:
              </span>
              {`${permanecen.length}`}
            </p>
            <p>
              <span style={{ fontWeight: 'bold', marginRight: '1em' }}>
                Promocionan:
              </span>
              {`${promocionan.length}`}
            </p>
            {Number(nivel) === level.PRIMARIO && (
              <p>
                <span style={{ fontWeight: 'bold', marginRight: '1em' }}>
                  Promociona con promocion acompañada:
                </span>
                {`${promocionAcompaniada.length}`}
              </p>
            )}
            {Number(nivel) === level.SECUNDARIO && (
              <p>
                <span style={{ fontWeight: 'bold', marginRight: '1em' }}>
                  Promocionan con Apoyo:
                </span>
                {`${promocionanConApoyo.length}`}
              </p>
            )}
          </Grid>
        }
      >
        <p>{promotionPassAnio.length}</p>
      </Tooltips>
    );
  };

  busyProyectedModified.render = render;
  busyProyectedModified.customStyles = null;

  return busyProyectedModified;
};

const modifyVacanciesProyectedColumn = (
  vacanciesProyected: any,
  promocion_pase_anio: any[],
) => {
  let vacanciesProyectedModified = _.cloneDeep(vacanciesProyected);
  const render = (value: any, row: any) => {
    let promotionPassAnio = promocion_pase_anio.filter(
      (element: any) =>
        element?.seccionDestino?.division === row?.division &&
        element?.seccionDestino?.cicloLectivo?.anio ===
          row?.cicloLectivo?.anio &&
        element?.seccionDestino?.anio?.idAnio === row?.anio?.idAnio &&
        element?.seccionDestino?.jornada === row?.jornada &&
        element?.seccionDestino?.turno?.descripcionTurno ===
          row?.turno?.descripcionTurno,
    );

    if (promotionPassAnio.length === 0) {
      promotionPassAnio = promocion_pase_anio.filter(
        (element: any) => element?.seccionDestino?.idSeccion === row?.idSeccion,
      );
    }

    const vacantyProject = row.capacidadMaxima - promotionPassAnio.length;

    return <p>{`${vacantyProject}`}</p>;
  };

  vacanciesProyectedModified.render = render;
  vacanciesProyectedModified.customStyles = null;

  return vacanciesProyectedModified;
};

type Props = {
  columns: any;
  rows: Seccion[];
  proyecciones: Proyeccion[];
  working: boolean;
  nivel: string;
};

export const ModalProyeccion = ({
  columns = [],
  rows = [],
  proyecciones = [],
  working = false,
  nivel = '',
}: Props) => {
  const classes = useStyles();
  const modalColumns = _.cloneDeep(columns);
  const columnsWithoutEditCell: any[] = modalColumns.filter(
    (column: any) => column.id !== 'icon',
  );
  const { index: vacanciesColumnIndex, vacanciesColumn } =
    findVacanciesProyectedColumn(columnsWithoutEditCell);
  const { index: busyColumnIndex, busyColumn } = findBusyProyectedColumn(
    columnsWithoutEditCell,
  );
  columnsWithoutEditCell[vacanciesColumnIndex] = modifyVacanciesProyectedColumn(
    vacanciesColumn,
    proyecciones,
  );
  columnsWithoutEditCell[busyColumnIndex] = modifyBusyProyectedColumn(
    busyColumn,
    proyecciones,
    nivel,
  );

  const buttonConfig: any = [
    {
      title: 'Cerrar',
      handleOnClick: () => customFormDialog.handleCancel(),
      type: 'secondary',
      size: 'small',
    },
  ];
  return (
    <Grid
      className={classes.borderGap}
      container
      justify="center"
      alignItems="center"
    >
      <InfoTable
        working={working}
        rows={rows}
        columns={columnsWithoutEditCell}
      />
      <Footer buttonConfig={buttonConfig} spacing={1} />
    </Grid>
  );
};
