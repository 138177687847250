import {
  Grid,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import _ from 'lodash';
import React, { useMemo, useState } from 'react';
import { MainInput, Loading } from 'src/commons';
import { useInputValidator } from '../hooks/useInputValidator';
import { grey, primary } from 'src/theme/colors';
import { Sede } from 'src/app/models/presentismo_v2';
import { useOfertasGruposLibbyFetch } from 'src/app/business';
import { OfertaGrupo } from 'src/app/models';

const useStyles = makeStyles({
  labelAsterisk: {
    color: 'red',
  },
  boxHeight: {
    minHeight: '3.5em',
    maxHeight: '6.5em',
    width: '24.5rem',
  },
  iconColor: { color: '#207dd9' },
  iconDisabled: { color: primary.disabled },
});

export const SedeComponent = ({
  index,
  sedesValues,
  turnos,
  setSedesValues,
  borrarSede,
}: any) => {
  const [inputValidator, error] = useInputValidator();
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const filterSede = useMemo(
    () => ({
      sede: [
        {
          path: 'jornadas.sede.idSede',
          value: sedesValues.map((sede: Sede) => sede.idSede),
          method: 'in',
        },
      ],
    }),

    [sedesValues],
  );

  const { data: ofertasGrupos = [], working: workingOfertasGrupos } =
    useOfertasGruposLibbyFetch({
      limit: 100,
      filter: filterSede,
      aspect: 'sedeDefault',
    });

  const validateSedeinOferta = (): boolean => {
    let validate = false;

    ofertasGrupos.forEach((ofertaGrupo: OfertaGrupo) => {
      ofertaGrupo.jornadas.forEach((jornada: any) => {
        if (jornada.sede.idSede === sedesValues[index].idSede) {
          validate = true;
        }
      });
    });
    return validate;
  };

  const handleChangeSede = (name: any, value: any) => {
    const clonnedSedesValues = _.cloneDeep(sedesValues);

    if (clonnedSedesValues.length - 1 >= index) {
      clonnedSedesValues[index][name] = value;
    } else {
      clonnedSedesValues.push({ [name]: value });
    }

    setSedesValues(clonnedSedesValues);
  };

  return (
    <>
      <Grid style={{ border: '1px solid black', marginTop: '15px' }}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Grid container alignItems="center" spacing={1}>
              <Grid item>
                <Typography
                  style={{
                    color: grey.heading,
                    fontSize: 22,
                    fontFamily: 'Open Sans',
                    marginLeft: '10px',
                  }}
                >
                  Sede
                </Typography>
              </Grid>
              <Grid item>
                <Tooltip
                  title="La sede tiene un grupo asignado."
                  arrow
                  disableHoverListener={!validateSedeinOferta()}
                  placement="right"
                >
                  <div>
                    <IconButton
                      onClick={async () => {
                        setLoading(true);
                        await borrarSede(index, sedesValues[index]);
                        setLoading(false);
                      }}
                      disabled={workingOfertasGrupos || validateSedeinOferta()}
                      className={
                        workingOfertasGrupos || validateSedeinOferta()
                          ? classes.iconDisabled
                          : classes.iconColor
                      }
                    >
                      <DeleteIcon />
                    </IconButton>
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid container style={{ marginBottom: '20px' }}>
              <Grid
                container
                item
                md={6}
                justifyContent="center"
                alignItems="center"
                style={{
                  display: 'inline-grid',
                }}
              >
                <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                  Nombre:
                </p>
                <MainInput
                  name="nombre"
                  value={
                    Boolean(sedesValues)
                      ? sedesValues[index]?.nombre || ''
                      : null
                  }
                  handleChange={(e) => {
                    if (e.target.value.charAt(0) === ' ') {
                      return '';
                    }

                    handleChangeSede('nombre', e.target.value);
                  }}
                  // error={error.direccion}
                  // helperText="No ingrese caracteres especiales"
                  placeholder=""
                  fullWidth
                  customStyle={{
                    width: '30em',
                  }}
                />
              </Grid>
              <Grid
                container
                item
                md={6}
                justifyContent="center"
                alignItems="center"
                style={{
                  display: 'inline-grid',
                }}
              >
                <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                  Dirección:
                  <span className={classes.labelAsterisk}>
                    {sedesValues[index]?.direccion ? '' : '*'}
                  </span>
                </p>
                <MainInput
                  name="direccion"
                  value={
                    Boolean(sedesValues)
                      ? sedesValues[index]?.direccion || ''
                      : null
                  }
                  handleChange={(e) => {
                    if (e.target.value.charAt(0) === ' ') {
                      return '';
                    }
                    if (inputValidator(e.target.value, 'direccion')) {
                      handleChangeSede('direccion', e.target.value);
                    }
                  }}
                  error={error.direccion}
                  helperText="No ingrese caracteres especiales"
                  placeholder=""
                  fullWidth
                  customStyle={{
                    width: '30em',
                  }}
                />
                {/* <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                  Turnos:
                  <span className={classes.labelAsterisk}>
                    {sedesValues[index]?.turnos?.length ? '' : '*'}
                  </span>
                </p>
                <Autocomplete
                  multiple
                  id="idTurno"
                  options={turnos?.length > 0 ? turnos : []}
                  disableCloseOnSelect
                  value={
                    Boolean(sedesValues) ? sedesValues[index]?.turnos : null
                  }
                  placeholder="Seleccioná turnos"
                  onChange={(e: any, newValue) => {
                    handleChangeSede('turnos', newValue);
                  }}
                  classes={{ inputRoot: classes.boxHeight }}
                  getOptionLabel={(option: any) => option.descripcionTurno}
                  renderOption={(props: any) => (
                    <li {...props}>{props.descripcionTurno}</li>
                  )}
                  renderInput={(params: any) => (
                    <TextField {...params} placeholder="" />
                  )}
                /> */}
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
};
