import React, { FC, useCallback, useMemo, useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import {
  LockCloseSolidV2,
  LockOpenSolidV2,
} from '../../../../../../../assets/images/svgs';
import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import cx from 'classnames';
import { useSelectedDayStyles } from '../hooks';
import { LockCellProps, PresenteGeneral } from '../../../types';
import { useLockCellStyles } from '../css';
import { useSnackbar } from 'notistack';
import confirmDialog from 'src/commons/services/confirmDialog';
import { ESTADO_PRESENTE, ROL, TURNOS } from 'src/commons';
import { useRolesContext } from 'src/context/RolesContext';
import { useCierrePresenteV3DAO } from 'src/app/business/presentismo_v3';
import moment from 'moment';
import { DiasCierre, Presente_v3 } from 'src/app/models';
import {
  useMultiPresentismoChecks,
  usePresentismoContext,
} from '../../../context';
import { DeepPartial } from '../../../../../../../commons/types/DeepPartial';
import { usePresentismosValidosContext } from '../../../context/PresentismosValidosContext';
import { ESPACIOS_CURRICULARES_ESTADOS } from '../../../utils/EstadoPresente';
import { compareDates } from '../../../../../../../utils';

function formatearFecha(fecha: string | number | Date) {
  const fechaObj = new Date(fecha);
  const year = fechaObj.getUTCFullYear();
  const month = String(fechaObj.getUTCMonth() + 1).padStart(2, '0');
  const day = String(fechaObj.getUTCDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export const LockCell: FC<LockCellProps> = ({
  align = 'center',
  colSpan = 2,
  first,
  last,
  isSelected,
  isDayFuture = false,
  day,
  dataCierrePresentismo,
  seccion,
  presentComplete = [],
  reFetchCierre,
  alumnos,
  presentes,
  selectedEspacioCurricular,
  isDisabled,
  isLockBlock,
  tieneDiasHabilitados,
  isTabJornadaExtendida,
  setWaitingCandados,
}) => {
  const { data } = usePresentismosValidosContext();
  const classes = useLockCellStyles();
  const selectedClasses = useSelectedDayStyles();
  const classNames = useMemo(() => {
    if (isSelected) return;
    return first ? classes.first : last ? classes.last : classes.middle;
  }, [first, last, classes, isSelected]);

  const {
    selectedRole: { rol, nivel },
    userInfo: { id },
  } = useRolesContext();
  const cierrePresentismoDAO = useCierrePresenteV3DAO();
  const { setNewPresentismos, feriados } = usePresentismoContext();
  const { turno } = useMultiPresentismoChecks();

  const { enqueueSnackbar } = useSnackbar();

  const isFeriado = useMemo(
    () =>
      !!feriados.find((feriado) => {
        const hasExceptions = Object.keys(feriado.exceptions || {}).length > 0;
        if (hasExceptions) {
          return (
            moment(feriado.date, 'YYYY-MM-DD').isSame(
              moment(day, 'YYYY-MM-DD'),
            ) && !feriado.exceptions.nivels.includes(Number(nivel))
          );
        }
        return moment(feriado.date, 'YYYY-MM-DD').isSame(
          moment(day, 'YYYY-MM-DD'),
        );
      }),
    [day, feriados, nivel],
  );

  const isAllowedToLock = [
    ROL.DIRECTIVO,
    ROL.EQUIPO_CONDUCCION,
    ROL.COORDINADOR_JE,
    ROL.PRECEPTOR,
    ROL.DOCENTE,
    ROL.DOCENTE_JE,
  ].includes(Number(rol));

  const isNoOpenLock = [ROL.DOCENTE, ROL.DOCENTE_JE, ROL.PRECEPTOR].includes(
    Number(rol),
  );

  const isOpenLock = [
    ROL.DIRECTIVO,
    ROL.EQUIPO_CONDUCCION,
    ROL.COORDINADOR_JE,
  ].includes(Number(rol));

  const validateFullPresent = !!data.days.find((_day) =>
    Boolean(_day[moment(day).format('YYYY-MM-DD')]),
  )?.[moment(day).format('YYYY-MM-DD')];

  const handleClose = async () => {
    const confirm = await confirmDialog.show({
      title: 'Cerrar asistencia del día',
      content: `¿Estás seguro que deseas cerrar la asistencia del día?`,
      secondLine: `Recuerde que solo puede volver abrir un rol jerárquico.`,
      confirmText: 'Confirmar',
      cancelText: 'Cancelar',
    });

    if (confirm) {
      try {
        setWaitingCandados(true);
        if (isTabJornadaExtendida) {
          dataCierrePresentismo[0]?.dias?.je?.push(new Date(day).toISOString());
        } else if (selectedEspacioCurricular) {
          dataCierrePresentismo[0]?.dias?.ec?.push(new Date(day).toISOString());
        } else {
          dataCierrePresentismo[0]?.dias?.jc?.push(new Date(day).toISOString());
        }

        if (dataCierrePresentismo.length > 0) {
          await cierrePresentismoDAO.save({
            idCierre: dataCierrePresentismo[0]?.idCierre,
            dias: dataCierrePresentismo[0]?.dias,
            seccion,
            closed_at: new Date(),
            closed_by: id,
          });
        } else {
          const dias: DiasCierre = { je: [], jc: [], ec: [] };
          if (isTabJornadaExtendida) {
            dias.je.push(new Date(day).toISOString());
          } else if (selectedEspacioCurricular) {
            dias.ec.push(new Date(day).toISOString());
          } else {
            dias.jc.push(new Date(day).toISOString());
          }
          await cierrePresentismoDAO.save({
            dias,
            seccion,
            closed_at: new Date(),
            closed_by: id,
            open_at: new Date(),
          });
        }
        enqueueSnackbar('Se ha cerrado con éxito', {
          key: day,
          variant: 'success',
        });
        reFetchCierre();
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Error al cerrar', {
          key: day,
          variant: 'error',
        });
      } finally {
        setWaitingCandados(false);
      }
    }
  };

  const handleOpen = async () => {
    try {
      setWaitingCandados(true);
      const diasCierre: DiasCierre = {
        je: dataCierrePresentismo[0]?.dias.je,
        jc: dataCierrePresentismo[0]?.dias.jc,
        ec: dataCierrePresentismo[0]?.dias.ec,
      };

      if (isTabJornadaExtendida) {
        diasCierre.je = diasCierre.je.filter(
          (e) =>
            !moment.utc(e, 'YYYY-MM-DD').isSame(moment.utc(day, 'YYYY-MM-DD')),
        );
      } else if (selectedEspacioCurricular) {
        diasCierre.ec = diasCierre.ec.filter(
          (e) =>
            !moment.utc(e, 'YYYY-MM-DD').isSame(moment.utc(day, 'YYYY-MM-DD')),
        );
      } else {
        diasCierre.jc = diasCierre.jc.filter(
          (e) =>
            !moment.utc(e, 'YYYY-MM-DD').isSame(moment.utc(day, 'YYYY-MM-DD')),
        );
      }

      await cierrePresentismoDAO.save({
        idCierre: dataCierrePresentismo[0]?.idCierre,
        dias: diasCierre,
        seccion: seccion,
        open_at: new Date(),
        open_by: id,
      });
      enqueueSnackbar('Se ha abierto con éxito', {
        key: day,
        variant: 'success',
      });
      reFetchCierre();
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Error al abrir', {
        key: day,
        variant: 'error',
      });
    } finally {
      setWaitingCandados(false);
    }
  };

  const validateFechaCierre = useMemo(() => {
    if (isTabJornadaExtendida) {
      return dataCierrePresentismo[0]?.dias?.je?.some(
        (fecha) => moment.utc(fecha).format('YYYY-MM-DD') === day,
      );
    } else if (selectedEspacioCurricular) {
      return dataCierrePresentismo[0]?.dias?.ec?.some(
        (fecha) => moment.utc(fecha).format('YYYY-MM-DD') === day,
      );
    } else {
      return dataCierrePresentismo[0]?.dias?.jc?.some(
        (fecha) => moment.utc(fecha).format('YYYY-MM-DD') === day,
      );
    }
  }, [
    dataCierrePresentismo,
    day,
    isTabJornadaExtendida,
    selectedEspacioCurricular,
  ]);

  const presentesDelDia: Presente_v3[] = useMemo(
    () =>
      presentes.filter((presente) =>
        moment(presente.fecha, 'YYYY-MM-DD').isSame(moment(day, 'YYYY-MM-DD')),
      ),
    [day, presentes],
  );

  const handleAsistenciaDiaria = useCallback(async () => {
    const confirm = await confirmDialog.show({
      title: 'Sobreescribir presentismo',
      content: `Se cargarán los mismos presentismos de asistencia general, pisando los existentes`,
      confirmText: 'Aceptar',
      cancelText: 'Cancelar',
    });
    if (confirm) {
      const isCompleta = turno === TURNOS.DOBLE;
      // TENER EN CONSIDERACION EL TURNO QUE EL DOCENTE HABILITO EN HABILITAR DIA
      if (presentesDelDia.length === 0) return;
      const newPresentes: DeepPartial<PresenteGeneral>[] = [];
      alumnos.forEach((alumno) => {
        const _presentes = presentesDelDia.filter(
          (presente) =>
            presente.alumno.idAlumno === alumno.alumno.idAlumno &&
            compareDates(presente.fecha, day),
        );
        const byTurno = _presentes.filter((pre) => Boolean(pre.turno?.idTurno)); // <- turno del docente
        const selectedTurno = isCompleta
          ? byTurno.find((_t) => _t.turno?.idTurno === TURNOS.MANIANA)
          : byTurno[0];

        if (!selectedTurno) {
          return;
        }

        let estado = [
          ESTADO_PRESENTE.INGRESO_TARDIO,
          ESTADO_PRESENTE.RETIRO_ANTICIPADO,
        ].includes(selectedTurno.estadoPresente.idEstadoPresente)
          ? ESTADO_PRESENTE.PRESENTE
          : selectedTurno.estadoPresente.idEstadoPresente;

        const actualPresente = alumno.presentismo.find((presente) =>
          moment(presente.fecha, 'YYYY-MM-DD').isSame(
            moment(day, 'YYYY-MM-DD'),
          ),
        );

        if (actualPresente) {
          newPresentes.push({
            ...actualPresente,
            estadoPresente: {
              ...actualPresente.estadoPresente,
              idEstadoPresente: estado,
            },
            turno: {
              ...selectedTurno.turno,
              idTurno: 1,
            },
          });
        } else {
          const { idPresente, ...rest } = selectedTurno;
          newPresentes.push({
            ...rest,
            espacioCurricular: {
              idEspacioCurricular: selectedEspacioCurricular || '',
            },
            turno: {
              idTurno: TURNOS.MANIANA,
            },
            estadoPresente: {
              idEstadoPresente: estado,
            },
          });
        }
      });
      if (newPresentes.length > 0) {
        setNewPresentismos((presente) => {
          let toSave = presente.filter(
            (pre) =>
              !newPresentes.find(
                (_pre) =>
                  _pre.alumno?.idAlumno === pre.alumno.idAlumno &&
                  _pre.fecha === pre.fecha &&
                  (_pre.turno && pre.turno
                    ? _pre.turno.idTurno === pre.turno.idTurno
                    : pre.turno === _pre.turno),
              ),
          );
          for (const newPresente of newPresentes) {
            const exist = presente.find((_pre) => {
              return (
                _pre.alumno.idAlumno === newPresente.alumno?.idAlumno &&
                _pre.fecha === newPresente.fecha &&
                (_pre.turno && newPresente.turno
                  ? _pre.turno.idTurno === newPresente.turno.idTurno
                  : newPresente.turno === _pre.turno)
              );
            });
            if (exist) {
              toSave.push({ ...exist, ...newPresente } as PresenteGeneral);
            } else {
              toSave.push(newPresente as PresenteGeneral);
            }
          }

          return toSave;
        });
        enqueueSnackbar('Se copio la asistencia', {
          key: day,
          variant: 'success',
        });
      }
    }
  }, [
    day,
    alumnos,
    turno,
    selectedEspacioCurricular,
    presentesDelDia,
    setNewPresentismos,
    enqueueSnackbar,
  ]);
  const disableAdButton = useMemo(() => {
    if (tieneDiasHabilitados) {
      return isDisabled || presentesDelDia.length === 0;
    } else {
      return true;
    }
  }, [isDisabled, presentesDelDia, tieneDiasHabilitados]);

  return (
    <TableCell
      align={align}
      colSpan={colSpan}
      className={cx(
        classes.box,
        isSelected ? selectedClasses.selected : '',
        isSelected ? 'selected' : '',
      )}
    >
      <div className={cx(classes.content, classNames)}>
        {Boolean(selectedEspacioCurricular) ? (
          <Box
            width={33}
            height={33}
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="#F5F9FC"
            borderRadius="50%"
            marginRight={0.6}
          >
            <Tooltip title="Asistencia diaria">
              <span>
                <IconButton
                  size="small"
                  disabled={disableAdButton}
                  onClick={handleAsistenciaDiaria}
                >
                  <Typography color={disableAdButton ? 'inherit' : 'primary'}>
                    AD
                  </Typography>
                </IconButton>
              </span>
            </Tooltip>
          </Box>
        ) : (
          <></>
        )}
        {Boolean(selectedEspacioCurricular) ? (
          <Box
            width={33}
            height={33}
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="#F5F9FC"
            borderRadius="50%"
          >
            <LockCloseSolidV2 handleOpenDay={() => null} isDisabled={true} />
          </Box>
        ) : (
          <Box
            width={33}
            height={33}
            display="flex"
            justifyContent="center"
            alignItems="center"
            bgcolor="#F5F9FC"
            borderRadius="50%"
          >
            {isFeriado || isLockBlock || validateFechaCierre ? (
              <LockCloseSolidV2
                handleOpenDay={handleOpen}
                isDisabled={
                  isDayFuture ||
                  isNoOpenLock ||
                  !isOpenLock ||
                  isLockBlock ||
                  isFeriado
                }
              />
            ) : (
              <Tooltip
                title="Debe completar todas las asistencias para cerrar el día."
                disableHoverListener={validateFullPresent}
                disableTouchListener={validateFullPresent}
              >
                <span>
                  <LockOpenSolidV2
                    handleCloseDay={handleClose}
                    isDisabled={
                      isDayFuture || !isAllowedToLock || !validateFullPresent
                    }
                  />
                </span>
              </Tooltip>
            )}
          </Box>
        )}
      </div>
    </TableCell>
  );
};
