import React, { useMemo, useCallback } from 'react';
import { InfoTableColumn } from 'src/commons';
import { EjeWithDocente } from '../../types';
import { SelectInput } from '../../../../../commons/components/SelectInput';
import { Checkbox, Grid, Typography } from '@material-ui/core';
import { Cuentas } from '../../../../../app/models';

interface ConfiguracionColumnsProps {
  docentes: { [k: number]: string[] };
  setDocentes: React.Dispatch<
    React.SetStateAction<{
      [k: number]: string[];
    }>
  >;
}

export const useConfiguracionColumns = ({
  docentes,
  setDocentes,
}: ConfiguracionColumnsProps) => {
  const handleChange = useCallback(
    (eje: EjeWithDocente) => (event: any) => {
      const value = event.target.value as string[];
      setDocentes((prev) => {
        if (value.length === 0) {
          delete prev[eje.idEje];
          return { ...prev };
        }
        return { ...prev, [eje.idEje]: value };
      });
    },
    [setDocentes],
  );

  const columns = useMemo<InfoTableColumn<EjeWithDocente>[]>(() => {
    return [
      {
        id: 'nombre',
        width: '50%',
        label: 'Eje',
        orderById: 'nombre',
        hideSortIcon: true,
      },
      {
        id: 'docenteEvaluador',
        width: '50%',
        label: 'Docente Evaluador',
        hideSortIcon: true,
        customStyles: { display: 'flex', justifyContent: 'center' },
        render: (_, row) => (
          <Grid style={{ maxWidth: 250, width: 250 }}>
            <SelectInput
              fullWidth
              name="docenteEvaluador"
              value={docentes[row.idEje] || []}
              multiple
              placeholder="Seleccione Docente"
              options={row.docenteEvaluador}
              labelKey="name"
              valueKey="idUsuario"
              handleChange={handleChange(row)}
              render={(option: Cuentas & { name: string }) => (
                <>
                  <Checkbox
                    checked={
                      !!(docentes[row.idEje] || []).find(
                        (i) => i === option.idUsuario,
                      )
                    }
                  />
                  <Typography>{option.name}</Typography>
                </>
              )}
            />
          </Grid>
        ),
      },
    ];
  }, [docentes, handleChange]);

  return columns;
};
