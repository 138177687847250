import React, { useState } from 'react';

export const useInputValidator = () => {
  /*eslint-disable*/
  const [error, setError] = useState<any>({
    nombreOrg: false,
    nombreRef: false,
    apellidoRef: false,
    direccion: false,
    domicilio: false,
    emailMain: false,
    emailRef: false,
    cuit: false,
    telefonoMain: false,
    telefonoReferente: false,
  });

  const inputValidator = (input: any, type: any) => {
    const emailDomain = input?.split('@')[1]?.split('.') || [];

    if (type === 'telefonoMain') {
      setError({
        ...error,
        telefonoMain: input !== '' ? !/^[0-9-]+$/.test(input) : false,
      });
      return input !== '' ? /^[0-9-]+$/.test(input) : true;
    }
    if (type === 'direccion') {
      setError({
        ...error,
        direccion:
          input !== ''
            ? !/^[a-zA-Z\'\-\u00C0-\u017F\s\d]+$/.test(input)
            : false,
      });
      return input !== ''
        ? /^[a-zA-Z\'\-\u00C0-\u017F\s\d]+$/.test(input)
        : true;
    }
    if (type === 'domicilio') {
      setError({
        ...error,
        domicilio:
          input !== ''
            ? !/^[a-zA-Z\'\-\.\u00C0-\u017F\s\d]+$/.test(input)
            : false,
      });
      return input !== ''
        ? /^[a-zA-Z\'\-\.\u00C0-\u017F\s\d]+$/.test(input)
        : true;
    }
    // if (type === 'nombre organizacion') {
    //   setError({
    //     ...error,
    //     nombreOrg:
    //       input !== '' ? !/^[a-zA-Z\u00C0-\u017F\s]+$/.test(input) : false,
    //   });
    //   return input !== '' ? /^[a-zA-Z\u00C0-\u017F\s]+$/.test(input) : true;
    // }
    if (type === 'nombre referente') {
      setError({
        ...error,
        nombreRef:
          input !== '' ? !/^[a-zA-Z\u00C0-\u017F\s]+$/.test(input) : false,
      });
      return input !== '' ? /^[a-zA-Z\u00C0-\u017F\s]+$/.test(input) : true;
    }
    if (type === 'apellido referente') {
      setError({
        ...error,
        apellidoRef:
          input !== '' ? !/^[a-zA-Z\u00C0-\u017F\s]+$/.test(input) : false,
      });
      return input !== '' ? /^[a-zA-Z\u00C0-\u017F\s]+$/.test(input) : true;
    }
    if (type === 'telefonoReferente') {
      setError({
        ...error,
        telefonoReferente: input !== '' ? !/^[0-9-]+$/.test(input) : false,
      });
      return input !== '' ? /^[\d]+$/.test(input) : true;
    }
    if (type === 'cuit') {
      setError({
        ...error,
        cuit:
          input !== '' ? !/^[\d-]+$/.test(input) || input.length < 13 : false,
      });
      return input !== '' ? /^[\d-]+$/.test(input) : true;
    }
    if (type === 'emailRef') {
      setError({
        ...error,
        emailRef:
          input !== ''
            ? !/^[a-zA-Z\'\-_.\u00C0-\u017F\d-]+@(?:[a-zA-Z0-9\'\-\u00C0-\u017F]+\.)+[A-Za-z]+$/.test(
                input,
              ) ||
              input[0] === '-' ||
              emailDomain.some(
                (domain: string) =>
                  domain[0] === '-' || domain[domain?.length - 1] === '-',
              )
            : false,
      });
      return input !== ''
        ? /^[a-zA-Z\'\-_.\u00C0-\u017F\d-]+@(?:[a-zA-Z0-9\'\-\u00C0-\u017F]+\.)+[A-Za-z]+$/.test(
            input,
          ) ||
            !(input[0] === '-') ||
            !emailDomain.some(
              (domain: string) =>
                domain[0] === '-' || domain[domain?.length - 1] === '-',
            )
        : true;
    }
    if (type === 'emailMain') {
      setError({
        ...error,
        emailMain:
          input !== ''
            ? !/^[a-zA-Z\'\-_.\u00C0-\u017F\d-]+@(?:[a-zA-Z0-9\'\-\u00C0-\u017F]+\.)+[A-Za-z]+$/.test(
                input,
              ) ||
              input[0] === '-' ||
              emailDomain.some(
                (domain: string) =>
                  domain[0] === '-' || domain[domain?.length - 1] === '-',
              )
            : false,
      });
      return input !== ''
        ? /^[a-zA-Z\'\-_.\u00C0-\u017F\d-]+@(?:[a-zA-Z0-9\'\-\u00C0-\u017F]+\.)+[A-Za-z]+$/.test(
            input,
          ) ||
            !(input[0] === '-') ||
            !emailDomain.some(
              (domain: string) =>
                domain[0] === '-' || domain[domain?.length - 1] === '-',
            )
        : true;
    }
  };

  return [inputValidator, error];
};
