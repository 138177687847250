import React, { createContext, useContext } from 'react';
import { presentismo } from '../../types';
import { configurationInitialReducer } from './configurationReducer';

export const ConfigurationContext =
  createContext<presentismo.ConfigurationContextType>({
    // estado global mod configuracion
    state: configurationInitialReducer,
    dispatch: {},
  });

export const useConfigurationContext = () => useContext(ConfigurationContext);
