import { Observacionesc } from 'src/app/models';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from 'src/lib/libby';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'observacionesc';

const defaultOptions: PartialBusinessEntityOptions<any> = {};

const optionsToUse = !businessOptions.Observacionesc
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Observacionesc,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Observacionesc.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Observacionesc>(DAO_NAME, optionsToUse);

// hooks
export const useObservacionescFetchById = fetchById;
export const useObservacionescLibbyCall = libbyCall;
export const useObservacionescDAO = libbyDAO;
export const useObservacionescLibbyFetch = libbyFetch;

// components/hoc
export const ObservacionescAutocomplete = entityAutocomplete;
export const ObservacionescCall = renderPropCall;
export const ObservacionescById = renderPropFetchById;
export const ObservacionescSelect = simpleSelect;
export const ObservacionescAccordionSelector = accordionSelector;
export const ObservacionescSelectedChips = selectedChips;

// context
// ByIdContext
export const useObservacionescByIdContext = useByIdContext;
export const ObservacionescByIdProvider = ByIdProvider;
export const ObservacionescByIdContext = ByIdContext;
// GeneralContext
export const useObservacionescContext = useGeneralContext;
export const ObservacionescProvider = GeneralProvider;
export const ObservacionescContext = GeneralContext;
