import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import {
  FormGenericControl,
  FormAutocomplete,
} from 'src/lib/templates/components';
import { Loading } from 'src/commons/components/Loading';
import { useDistritoEscolarLibbyCall } from 'src/app/business';
import {
  depenFuncFiltersMap,
  filterByDepenFunc,
  filtersToCleanByDependencia,
} from './filtersByDepFuncConsts';

interface FormFiltersProps {
  dependenciaFuncional: 10 | 31 | 20 | 50 | 32 | 41 | 70;
  form: any;
}

export const RolFormFilters = ({
  dependenciaFuncional,
  form,
}: FormFiltersProps) => {
  const { data: distritos, working } = useDistritoEscolarLibbyCall({
    methodName: 'getAll',
  });
  const dependenciaFuncionalMapped = useMemo(
    () => depenFuncFiltersMap[dependenciaFuncional],
    [dependenciaFuncional],
  );
  const filter = useMemo(
    () =>
      dependenciaFuncionalMapped &&
      filterByDepenFunc[dependenciaFuncionalMapped],
    [dependenciaFuncionalMapped],
  );

  useEffect(() => {
    if (dependenciaFuncionalMapped) {
      filtersToCleanByDependencia[dependenciaFuncionalMapped].forEach((f) => {
        form.change(f, undefined);
      });
    }
  }, [dependenciaFuncionalMapped, form]);

  return (
    <>
      {filter && (
        <Grid item sm={4}>
          {filter.name === 'distrito' ? (
            !working && !!distritos ? (
              <FormAutocomplete
                label="Distrito"
                name={filter.name}
                form={form}
                options={distritos}
                optionLabelKey="nombre"
                placeholder="Seleccioná el/los distrito/s"
                multiple
              />
            ) : (
              <Loading />
            )
          ) : (
            <FormGenericControl
              name={filter.name}
              form={form}
              component={filter.component}
              componentProps={filter.componentProps}
            />
          )}
        </Grid>
      )}
    </>
  );
};
