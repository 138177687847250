import moment from 'moment';
import { isWeekClosed } from '../../../utils/DateRangeList';
export const weekDays = ['Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes'];

/**
 * Verifica si el boton
 * debe estar deshabilitado
 * para cerrar semana
 * @param rows Los alumnos que aparecen en la grilla de presentismo
 * @param daysOfWeek Los dias de la semana activa en la UI
 * @param closedDays Los dias que han sido cerrados
 * @returns boolean
 */
export const buttonHasToBeDisabled = (rows: { isValid: boolean }[]) => {
  const allHasPresentismos = rows.every((row) => row.isValid);

  return rows.length === 0 || !allHasPresentismos;
};
