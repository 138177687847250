import { LibbyFetchDAO } from './LibbyFetchDAO';

export class SecundariaFuturoDAO extends LibbyFetchDAO {
  constructor() {
    super('secundaria_futuro', 'idSecundariaFuturo');
  }

  getByEstablecimiento(id: any) {
    return this.query().equals('establecimiento', id).run();
  }
}
