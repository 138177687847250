import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { DatePicker } from 'src/app/components/inputs/Input/DatePicker';
import {
  ButtonTypesProps,
  Footer,
  InfoTable,
  InfoTableColumn,
  statusPassesRequest,
} from 'src/commons';
import { Alumno } from '../Initial';
import customFormDialog from 'src/commons/services/customFormDialog';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import moment from 'moment';
import { CicloLectivo } from 'src/app/models';
import { useSolicitudPasesLibbyFetch } from 'src/app/business';
import { Info } from '@material-ui/icons';
import { MOVIMIENTO_RAZON } from 'src/commons/const/movimientoRazon';

const columns: InfoTableColumn<Alumno>[] = [
  { id: 'nombre', label: 'Nombre y Apellido', width: '30%' },
  { id: 'documento', label: 'Documento', width: '30%' },
  {
    id: 'nombre',
    label: 'Sección a Matricular',
    width: '40%',
    render: (_, row) => row.selectedSeccion,
  },
];

interface EnrolledDatePassModalProps {
  alumnos: Alumno[];
  cicloLectivo: CicloLectivo;
}

export const EnrolledDatePassModal = ({
  alumnos,
  cicloLectivo,
}: EnrolledDatePassModalProps) => {
  const [passDate, setPassDate] = useState<MaterialUiPickersDate>(moment());

  const alumnsWithPass = useMemo(() => {
    return alumnos.filter(
      (a) =>
        a.razon?.idMovimientoRazon === MOVIMIENTO_RAZON.CAMBIO_ESTABLECIMIENTO,
    );
  }, [alumnos]);
  const filterPases = useMemo(() => {
    return {
      alumnos: [
        {
          path: 'alumno',
          value: alumnsWithPass.map((a) => a.idAlumno),
          method: 'in',
        },
      ],
      estado: [
        {
          path: 'estadoSolicitudPases',
          value: statusPassesRequest.APPROVED,
        },
      ],
      fecha: [
        {
          path: 'fecha_pase',
          value: moment().startOf('year').format('YYYY-MM-DD'),
          method: 'higherOrEquals',
        },
      ],
    };
  }, [alumnsWithPass]);
  const { data: solicitudes = [], working: soliWorking } =
    useSolicitudPasesLibbyFetch({
      filter: filterPases,
      enabled: alumnsWithPass.length > 0,
      aspect: 'matriculacion_gestionDePases_solicitudDePasesRequest',
    });
  const isOneAlum = alumnos.length === 1;
  const isPass = solicitudes.length > 0;
  const disableChandePassDate = isOneAlum && isPass;
  useEffect(() => {
    if (!disableChandePassDate) return;
    setPassDate(moment(solicitudes[0].fechaPase, 'YYYY-MM-DD'));
  }, [disableChandePassDate, solicitudes]);

  const buttons: ButtonTypesProps[] = [
    {
      title: 'Cancelar',
      type: 'secondary',
      handleOnClick: () => customFormDialog.handleCancel(),
    },
    {
      title: 'Aceptar',
      handleOnClick: () => {
        customFormDialog.handleConfirm({
          matriculacionDate: passDate?.format('YYYY-MM-DD'),
        });
      },
      disabled: !passDate || soliWorking,
    },
  ];

  const fechaInicioCiclo = moment(
    cicloLectivo?.fechaInicio,
    'YYYY-MM-DD',
  ).format('YYYY-MM-DD');
  const minDate = fechaInicioCiclo || moment(new Date()).startOf('year');
  const today = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD');
  const fechaFinCiclo = moment(cicloLectivo?.fechaFin, 'YYYY-MM-DD').format(
    'YYYY-MM-DD',
  );
  const isBeforeInit = moment(today, 'YYYY-MM-DD').isBefore(fechaInicioCiclo);
  let maxDate = moment(today, 'YYYY-MM-DD').isBefore(fechaFinCiclo)
    ? today
    : fechaFinCiclo;
  if (isBeforeInit) {
    maxDate = fechaInicioCiclo;
  }

  const columns = useMemo<Array<InfoTableColumn<Alumno>>>(
    () => [
      { id: 'nombre', label: 'Nombre y Apellido', width: '30%' },
      { id: 'documento', label: 'Documento', width: '10%' },
      {
        id: 'enrrolleAt',
        label: 'Fecha efectiva de Matriculación',
        hideSortIcon: true,
        width: '30%',
        render: (_, row) => {
          const isPass =
            row.razon.idMovimientoRazon ===
            MOVIMIENTO_RAZON.CAMBIO_ESTABLECIMIENTO;
          const datePass = solicitudes.find(
            (s) => s.alumno.idAlumno === row.idAlumno,
          );
          return (
            <Tooltip
              disableFocusListener={!isPass}
              disableHoverListener={!isPass}
              disableTouchListener={!isPass}
              title="Viene de otro establecimiento"
            >
              <Typography
                component={Box}
                variant="body2"
                style={{ display: 'flex' }}
                justifyContent="center"
                alignItems="center"
              >
                {moment(
                  isPass ? datePass?.fechaPase : passDate,
                  'YYYY-MM-DD',
                ).format('DD/MM/YYYY')}
                {isPass ? (
                  <Info color="primary" style={{ paddingLeft: 1 }} />
                ) : (
                  <></>
                )}
              </Typography>
            </Tooltip>
          );
        },
      },
      {
        id: 'seccionMatricular',
        label: 'Sección a Matricular',
        width: '40%',
        render: (_, row) => row.selectedSeccion,
      },
    ],
    [passDate, solicitudes],
  );

  return (
    <Grid container>
      <Grid component={Box} pl={3} container spacing={3}>
        <Grid item container>
          <InfoTable columns={columns} rows={alumnos} />
        </Grid>
        <Grid item container>
          <Grid item xs={12}>
            <Typography>Fecha efectiva de Matriculación:</Typography>
          </Grid>
          <DatePicker
            value={passDate}
            onChange={(date) => {
              if (date) setPassDate(date);
            }}
            shouldDisableDate={(day: MaterialUiPickersDate) =>
              [0, 6].includes(Number(day?.format('d')))
            }
            maxDate={maxDate}
            minDate={minDate}
            disabled={disableChandePassDate}
          />
        </Grid>
      </Grid>
      <Footer buttonConfig={buttons} />
    </Grid>
  );
};
