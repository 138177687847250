import React from 'react';
import { Button, Card, CardContent, Grid, Typography } from '@material-ui/core';

interface SorterButton {
  label: string;
  sort: string;
  direction: 'asc' | 'desc';
}

const sorterButtons: SorterButton[] = [
  {
    label: 'Alfabéticamente',
    sort: 'nombre',
    direction: 'asc',
  },
  {
    label: 'Por espacio curricular',
    sort: 'espacioCurricular.descripcion',
    direction: 'asc',
  },
  {
    label: 'Más nuevos',
    sort: 'fechaCreacion',
    direction: 'desc',
  },
];

export interface SorterProps {
  value: string;
  onChange: (sort: string, direction: 'asc' | 'desc') => void;
}

export const Sorter = ({ value, onChange }: SorterProps) => (
  <Card>
    <CardContent>
      <Grid container spacing={2}>
        <Grid item>
          <Typography variant="h4">Ordenar recursos</Typography>
        </Grid>
        {sorterButtons.map((item) => (
          <Grid item key={item.label}>
            <Button
              variant="contained"
              color={value === item.sort ? 'primary' : 'default'}
              onClick={() => onChange(item.sort, item.direction)}
            >
              {item.label}
            </Button>
          </Grid>
        ))}
      </Grid>
    </CardContent>
  </Card>
);
