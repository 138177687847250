import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { InformescCustom } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'informesc_custom';

const defaultOptions: PartialBusinessEntityOptions<any> = {};

const optionsToUse = !businessOptions.InformescCustom
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.InformescCustom,
      hoc: { ...defaultOptions.hoc, ...businessOptions.InformescCustom.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<InformescCustom>(DAO_NAME, optionsToUse);

// hooks
export const useInformescCustomFetchById = fetchById;
export const useInformescCustomLibbyCall = libbyCall;
export const useInformescCustomDAO = libbyDAO;
export const useInformescCustomLibbyFetch = libbyFetch;

// components/hoc
export const InformescCustomAutocomplete = entityAutocomplete;
export const InformescCustomCall = renderPropCall;
export const InformescCustomById = renderPropFetchById;
export const InformescCustomSelect = simpleSelect;
export const InformescCustomAccordionSelector = accordionSelector;
export const InformescCustomSelectedChips = selectedChips;

// context
// ByIdContext
export const useInformescCustomByIdContext = useByIdContext;
export const InformescCustomByIdProvider = ByIdProvider;
export const InformescCustomByIdContext = ByIdContext;
// GeneralContext
export const useInformescCustomContext = useGeneralContext;
export const InformescCustomProvider = GeneralProvider;
export const InformescCustomContext = GeneralContext;
