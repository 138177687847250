import React from 'react';
import { useSeccionContext } from 'src/app/business';
import { Grid } from '@material-ui/core';
import { EspaciosDiscipOrientPlan } from 'src/app/models';
import { HeaderFeedback } from '../../commons/components/FeedbackHeader';

export type DisciplinarOrientadaFlowHeaderProps = {
  data: EspaciosDiscipOrientPlan;
};

export const DisciplinarOrientadaFlowHeader = ({
  data,
}: DisciplinarOrientadaFlowHeaderProps) => {
  const { data: secciones, working } = useSeccionContext();
  /*  const seccionesPlan = data.simpDiscipPlan;
  const filterSecciones = seccionesPlan?.map((_item: any) => {
    const filter = secciones?.find((_seccion: any) => _seccion.idSeccion === _item) || [];
    return filter;
  }); */

  const formateDataHeader = {
    disciplina: data.espacioCurricular.materia.descripcion,
    area: data.espacioCurricular.materia.area.descripcion,
    orientacion:
      data?.espacioCurricular?.planEstudio?.orientacion?.descripcionOrientacion,
    seccion: '',
    fecha: data.discipOrientPlan.ultimaActualizacion,
    autor: `${data?.discipOrientPlan?.cuentas?.nombreUsuario} ${data?.discipOrientPlan?.cuentas?.apellidoUsuario}`,
    nombre: data.espacioCurricular.descripcion,
    descripcion: '',
  };
  return (
    <Grid container direction="column">
      {!working && <HeaderFeedback data={formateDataHeader} />}
    </Grid>
  );
};
