import React, { FC } from 'react';
import {
  Checkbox,
  createStyles,
  FormControlLabel,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { TextField } from 'src/app/components/inputs/Input';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from 'src/theme/colors';

const useStyles = makeStyles((theme) =>
  createStyles({
    listItem: {
      padding: 0,
    },
    listItemIcon: {
      marginRight: '-20px',
    },
    text: {
      color: grey.medium,
    },
  }),
);

interface Props {
  value: {
    checked: boolean | undefined;
    id: number;
    descripcion: React.ReactNode;
  };
  input: {
    onChange: (arg0: any) => void;
    onRemove:
      | ((event: React.MouseEvent<SVGSVGElement, MouseEvent>) => void)
      | undefined;
  };
  meta?: any;
  index: number;
  inputPlaceholder?: string;
}

export const CheckboxListItem: FC<Props> = ({
  value,
  input,
  meta,
  index,
  inputPlaceholder,
}) => {
  const classes = useStyles();
  const onChangeMaker =
    (name: string) =>
    ({ target: { value: _value, checked: _checked } }: any) => {
      const valueOrChecked = name === 'checked' ? _checked : _value;
      input.onChange({ ...value, [name]: valueOrChecked });
    };
  return (
    <ListItem key={index} role="listitem" className={classes.listItem}>
      <ListItemIcon className={classes.listItemIcon}>
        <FormControlLabel
          control={
            <Checkbox
              checked={value.checked}
              onChange={onChangeMaker('checked')}
              color="primary"
            />
          }
          label=""
        />
      </ListItemIcon>
      <ListItemText id={`list-all-item-${index}-label`} disableTypography>
        {value.id > 0 ? (
          <Typography variant="body1" className={classes.text}>
            {value.descripcion}
          </Typography>
        ) : (
          <TextField
            value={value.descripcion}
            onChange={onChangeMaker('descripcion')}
            placeholder={inputPlaceholder}
          />
        )}
      </ListItemText>
      <ListItemSecondaryAction>
        {value.id < 0 && (
          <IconButton edge="end" aria-label="delete">
            <DeleteIcon onClick={input.onRemove} />
          </IconButton>
        )}
      </ListItemSecondaryAction>
    </ListItem>
  );
};
