import { Grid, TextField, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/styles';
import { FormApi } from 'final-form';
import { orderBy } from 'lodash';
import { Footer, SimpleSelect } from 'src/commons/components';
import { DatePicker, useFormInputStyles } from 'src/app/components';
import customFormDialog from 'src/commons/services/customFormDialog';
import { usePlanEstudioMateriaLibbyFetch } from 'src/app/business';
import useMotivoSelect from '../../hooks/useMotivoSelect';
import {
  SeccionSelect,
  AnioSelect,
  CicloLectivoSelect,
  TipoDeudaAcademicaSelect,
} from 'src/app/business';
import { AnyObject } from 'src/commons/types/types';
interface Modal {
  form: FormApi<any>;
  values: any;
  onSubmit: (data: any) => void;
  selectPlanDeEstudio: (data: any) => void;
  establecimientoEfectivizado: boolean;
}

const useStyles = makeStyles({
  gridPlanEstudio: {
    padding: '0 15px 15px',
  },
  gridContainer: {
    padding: '16px 24px',
  },
  gridSubSelect: {
    padding: '0 15px 10px',
  },
  datePicker: {
    paddingLeft: 0,
  },
});

const participoSelectsOptions = [
  {
    name: 'Sí',
    value: 'true',
  },
  {
    name: 'No',
    value: 'false',
  },
];

const calificacionesNumericas = [
  {
    name: '1',
    value: '1',
  },
  {
    name: '2',
    value: '2',
  },
  {
    name: '3',
    value: '3',
  },
  {
    name: '4',
    value: '4',
  },
  {
    name: '5',
    value: '5',
  },
  {
    name: '6',
    value: '6',
  },
  {
    name: '7',
    value: '7',
  },
  {
    name: '8',
    value: '8',
  },
  {
    name: '9',
    value: '9',
  },
  {
    name: '10',
    value: '10',
  },
  {
    name: 'Ausente',
    value: 'Ausente',
  },
  {
    name: 'Pendiente',
    value: 'Pendiente',
  },
];

const calificacionesPalabras = [
  {
    name: 'Avanzado',
    value: 'Avanzado',
  },
  {
    name: 'Suficiente',
    value: 'Suficiente',
  },
  {
    name: 'En Proceso',
    value: 'En Proceso',
  },
  {
    name: 'Pendiente',
    value: 'Pendiente',
  },
];

const tablaPeriodo = [
  {
    name: 'Primer Bimestre',
    value: 1,
  },
  {
    name: 'Segundo Bimestre',
    value: 3,
  },
  {
    name: 'Tercer Bimestre',
    value: 7,
  },
  {
    name: 'Cuarto Bimestre',
    value: 8,
  },
  {
    name: 'Período recuperación - Diciembre',
    value: 14,
  },
  {
    name: 'Período recuperación - Febrero - Marzo',
    value: 15,
  },
];

export const ModalHistorialAcademico = ({
  form,
  values,
  onSubmit,
  selectPlanDeEstudio,
  establecimientoEfectivizado,
}: Modal) => {
  const classes = useStyles();
  const { selectStatus: selectDisabled, setMotivo } = useMotivoSelect();
  const [disciplinas, setDisciplinas] = useState<any>({
    anio: '',
    periodo: '',
    cicloLectivo: '',
    apoyo: '',
    espacioCurricular: '',
    valoracion: '',
    calificacion: '',
    motivo: { name: '', idTipoDeudaAcademica: '' },
    planEstudio: '',
    fecha: new Date(),
  });

  const [calificacionesActual, setCalificacionesActual] = useState(
    calificacionesPalabras,
  );

  const [anios, setAnios] = useState<any>();
  const [ciclosLectivos, setCiclosLectivos] = useState<any>();
  const classesLabel = useFormInputStyles();
  const [motivos, setMotivos] = useState<any>([]);
  const [espaciosCurriculares, setEspaciosCurriculares] = useState<any>([]);
  const [planesDeEstudio, setPlanesDeEstudio] = useState<any>();

  const filterPlanStudioMateria = useMemo(
    () => ({
      plan: [
        {
          path: 'planEstudio',
          value:
            disciplinas.planEstudio?.planEstudioNivel?.planEstudio
              ?.idPlanEstudio,
        },
      ],
    }),
    [disciplinas.planEstudio],
  );

  const { data: planEstudioMateria = [] } = usePlanEstudioMateriaLibbyFetch({
    filter: filterPlanStudioMateria,
    limit: 250,
    enabled:
      disciplinas.planEstudio?.planEstudioNivel?.planEstudio?.idPlanEstudio !==
      undefined,
  });

  const handleSubmit = useCallback(() => {
    onSubmit(disciplinas);

    customFormDialog.handleCancel();
  }, [disciplinas, onSubmit]);

  const buttonConfig: any = [
    {
      title: 'Cancelar',
      handleOnClick: () => customFormDialog.handleCancel(),
      type: 'secondary',
      size: 'small',
    },
    {
      title: 'Agregar',
      handleOnClick: () => handleSubmit(),
      size: 'small',
      disabled:
        !disciplinas?.motivo?.idTipoDeudaAcademica ||
        !disciplinas?.anio?.idAnio ||
        !disciplinas?.planEstudio?.idSeccion ||
        !disciplinas?.calificacion?.value ||
        !disciplinas?.espacioCurricular?.id ||
        !disciplinas?.cicloLectivo?.idCicloLectivo ||
        (!disciplinas?.apoyo &&
          disciplinas?.motivo?.descripcion ===
            'Plan de Fortalecimiento de los Aprendizajes') ||
        (!disciplinas?.periodo &&
          disciplinas?.motivo?.descripcion ===
            'Plan de Fortalecimiento de los Aprendizajes'),
    },
  ];

  // LOGICA MOTIVO
  useEffect(() => {
    if (typeof disciplinas?.motivo?.descripcion === 'string') {
      setMotivo(disciplinas.motivo.descripcion);

      if (selectDisabled?.calificacionContent === 'calificacionesNumericas') {
        setCalificacionesActual(calificacionesNumericas);
      } else if (
        selectDisabled?.calificacionContent === 'calificacionesPalabras'
      ) {
        setCalificacionesActual(calificacionesPalabras);
      }
    }
  }, [
    disciplinas,
    disciplinas.motivo,
    selectDisabled.calificacion,
    selectDisabled?.calificacionContent,
    selectDisabled.selectsDisabled.calificacion,
    setMotivo,
  ]);

  // ON CHANGE
  const onEspacioCurricularChange = useCallback(
    ({ target }) => {
      const esp = espaciosCurriculares.find((i: any) => i.id === target.value);
      if (esp) {
        setDisciplinas({ ...disciplinas, [target.name]: esp });
      } else {
        setDisciplinas({ ...disciplinas, [target.name]: undefined });
      }
    },
    [disciplinas, espaciosCurriculares],
  );

  const onAnioChange = useCallback(
    ({ target }) => {
      const anio = anios.find((i: any) => i.idAnio === target.value);
      const espaciosCurricularesFiltrados = planEstudioMateria.filter(
        (item: any) => {
          if (typeof anio?.idAnio === 'number') {
            return item.materia.anio.idAnio === anio.idAnio;
          }
        },
      );

      const formatEspaciosCurricularesOptions =
        espaciosCurricularesFiltrados.map(
          (item: any): { name: string; id: number } => ({
            id: item?.idPlanEstudioMateria,
            name: item?.materia?.descripcion,
          }),
        );

      setEspaciosCurriculares(
        orderBy(formatEspaciosCurricularesOptions, ['name'], ['asc']),
      );

      if (anio) {
        setDisciplinas({ ...disciplinas, [target.name]: anio });
      } else {
        setDisciplinas({ ...disciplinas, [target.name]: undefined });
      }
    },
    [anios, disciplinas, planEstudioMateria],
  );

  const onOptionsAniosChange = useCallback(
    (options) => {
      if (JSON.stringify(options) !== JSON.stringify(anios)) {
        setAnios(options);
      }
    },
    [anios],
  );

  const onCicloLectivoChange = useCallback(
    ({ target }) => {
      const cicloLectivo = ciclosLectivos.find(
        (i: any) => i.idCicloLectivo === target.value,
      );
      if (cicloLectivo) {
        setDisciplinas({ ...disciplinas, [target.name]: cicloLectivo });
      } else {
        setDisciplinas({ ...disciplinas, [target.name]: undefined });
      }
    },
    [ciclosLectivos, disciplinas],
  );

  const onOptionsCicloLectivoChange = useCallback(
    (options) => {
      if (JSON.stringify(options) !== JSON.stringify(ciclosLectivos)) {
        setCiclosLectivos(options);
      }
    },
    [ciclosLectivos],
  );

  const onMotivoChange = useCallback(
    ({ target }) => {
      const motivo = motivos.find(
        (i: any) => i.idTipoDeudaAcademica === target.value,
      );
      if (motivo) {
        if (motivo?.idTipoDeudaAcademica === 1) {
          setDisciplinas({
            cicloLectivo: {
              anio: 2020,
              estadoCicloLectivo: {
                idEstadoCicloLectivo: 2,
                descripcionEstadoCicloLectivo: 'Inactivo',
              },
              fechaFin: '2020-12-31T03:00:00.000Z',
              fechaInicio: '2020-01-01T03:00:00.000Z',
              idCicloLectivo: 22,
            },
            [target.name]: motivo,
            valoracion: '',
          });
        } else {
          setDisciplinas({
            ...disciplinas,
            [target.name]: motivo,
            valoracion: '',
          });
        }
      } else {
        setDisciplinas({ [target.name]: undefined, valoracion: '' });
      }
    },
    [disciplinas, motivos],
  );

  const onOptionsMotivoChange = useCallback(
    (options) => {
      if (JSON.stringify(options) !== JSON.stringify(motivos)) {
        setMotivos(options);
      }
    },
    [motivos],
  );

  const onPlanEstudioChange = useCallback(
    ({ target }) => {
      const planEstudio = planesDeEstudio.find(
        (i: any) =>
          i.planEstudioNivel.planEstudio.idPlanEstudio === target.value,
      );

      selectPlanDeEstudio(target.value);
      if (planEstudio) {
        setDisciplinas({ ...disciplinas, [target.name]: planEstudio });
      } else {
        setDisciplinas({ ...disciplinas, [target.name]: undefined });
      }
    },
    [disciplinas, planesDeEstudio, selectPlanDeEstudio],
  );

  const onPlanEstudioOptionsChange = useCallback(
    (options) => {
      if (JSON.stringify(options) !== JSON.stringify(planesDeEstudio)) {
        setPlanesDeEstudio(options);
      }
    },
    [planesDeEstudio],
  );

  const onPeriodoChange = useCallback(
    ({ target }) => {
      const periodo = tablaPeriodo.find((i: any) => i.value === target.value);
      if (periodo) {
        setDisciplinas({ ...disciplinas, [target.name]: periodo });
      } else {
        setDisciplinas({ ...disciplinas, [target.name]: undefined });
      }
    },
    [disciplinas],
  );

  const onApoyoChange = useCallback(
    ({ target }) => {
      const apoyo = participoSelectsOptions.find(
        (i: any) => i.value === target.value,
      );
      if (apoyo) {
        setDisciplinas({ ...disciplinas, [target.name]: apoyo });
      } else {
        setDisciplinas({ ...disciplinas, [target.name]: undefined });
      }
    },
    [disciplinas],
  );

  const onCalificacionChange = useCallback(
    ({ target }) => {
      const calificacion = calificacionesActual.find(
        (i: any) => i.value === target.value,
      );
      if (calificacion) {
        setDisciplinas({ ...disciplinas, [target.name]: calificacion });
      } else {
        setDisciplinas({ ...disciplinas, [target.name]: undefined });
      }
    },
    [calificacionesActual, disciplinas],
  );

  const filtroCicloLectivo = useMemo(() => {
    const previaFilter = {
      cicloLectivo: [
        { path: 'idCicloLectivo', value: 22, method: 'notEquals' },
      ],
    };
    return disciplinas?.motivo?.idTipoDeudaAcademica === 2
      ? previaFilter
      : undefined;
  }, [disciplinas?.motivo?.idTipoDeudaAcademica]);

  return (
    <>
      <Grid spacing={3} xs={12} direction="column">
        <Grid xs={12} className={classes.gridContainer}>
          <Grid spacing={3}>
            <Grid container xs={12} className={classes.gridSubSelect}>
              <Grid xs={12}>
                <Grid item xs={12}>
                  <TipoDeudaAcademicaSelect
                    title="Motivo"
                    placeholder="Seleccioná el motivo"
                    name="motivo"
                    value={disciplinas.motivo?.idTipoDeudaAcademica || ''}
                    handleChange={onMotivoChange}
                    onOptionsChange={onOptionsMotivoChange}
                    labelKey="descripcion"
                    valueKey="idTipoDeudaAcademica"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12} className={classes.gridSubSelect}>
              <Grid xs={12}>
                <Grid item xs={12}>
                  {disciplinas.motivo?.idTipoDeudaAcademica !== '' && (
                    <SeccionSelect
                      transformOptions={(data): any => {
                        if (data.length !== 0) {
                          const planesDeEstudioUnicos: any[] = [];

                          data.forEach((seccion) => {
                            if (
                              planesDeEstudioUnicos.find(
                                (element) =>
                                  element.planEstudioNivel.planEstudio
                                    .idPlanEstudio ===
                                  seccion.planEstudioNivel.planEstudio
                                    .idPlanEstudio,
                              ) === undefined
                            ) {
                              planesDeEstudioUnicos.push(seccion);
                            }
                          });
                          return planesDeEstudioUnicos;
                        }
                      }}
                      title="Plan de estudio"
                      placeholder="Seleccioná el plan de estudio"
                      name="planEstudio"
                      value={
                        disciplinas?.planEstudio?.planEstudioNivel?.planEstudio
                          ?.idPlanEstudio || ''
                      }
                      handleChange={onPlanEstudioChange}
                      onOptionsChange={onPlanEstudioOptionsChange}
                      labelKey="planEstudioNivel.planEstudio.descripcionPlanEstudio"
                      valueKey="planEstudioNivel.planEstudio.idPlanEstudio"
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12} className={classes.gridSubSelect}>
              <Grid xs={6}>
                <Grid item xs={11}>
                  {disciplinas.motivo?.idTipoDeudaAcademica !== '' && (
                    <AnioSelect
                      disabled={selectDisabled.selectsDisabled.año}
                      title="Año"
                      placeholder="Seleccioná el año"
                      name="anio"
                      value={disciplinas.anio?.idAnio || ''}
                      handleChange={onAnioChange}
                      onOptionsChange={onOptionsAniosChange}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid xs={6}>
                <Grid item xs={12}>
                  {disciplinas.motivo?.idTipoDeudaAcademica !== '' && (
                    <SimpleSelect
                      title="Espacio Curricular"
                      value={disciplinas.espacioCurricular?.id || ''}
                      onChange={onEspacioCurricularChange}
                      placeholder="Seleccioná el Espacio Curricular"
                      content={espaciosCurriculares}
                      name="espacioCurricular"
                      labelKey="name"
                      valueKey="id"
                      disabled={
                        selectDisabled.selectsDisabled.espacioCurricular
                      }
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12} className={classes.gridSubSelect}>
              <Grid xs={6}>
                <Grid item xs={11}>
                  {disciplinas.motivo?.idTipoDeudaAcademica !== '' && (
                    <CicloLectivoSelect
                      orderBy="anio"
                      direction="desc"
                      disabled={
                        disciplinas.motivo?.idTipoDeudaAcademica === 1 ||
                        selectDisabled.selectsDisabled.cicloLectivo
                      }
                      title="Ciclo Lectivo"
                      placeholder="Seleccioná el ciclo lectivo"
                      name="cicloLectivo"
                      value={
                        disciplinas.motivo?.idTipoDeudaAcademica === 1
                          ? 22
                          : disciplinas.cicloLectivo?.idCicloLectivo || ''
                      }
                      handleChange={onCicloLectivoChange}
                      onOptionsChange={onOptionsCicloLectivoChange}
                      filter={filtroCicloLectivo}
                    />
                  )}
                </Grid>
              </Grid>
              <Grid xs={6}>
                <Grid item xs={12}>
                  {!selectDisabled.selectsDisabled.fecha && (
                    <>
                      <Typography className={classesLabel.label}>
                        Seleccioná la fecha
                      </Typography>
                      <DatePicker
                        value={disciplinas.fecha}
                        onChange={(_date) =>
                          setDisciplinas({
                            ...disciplinas,
                            fecha: _date?.toDate(),
                          })
                        }
                      />
                    </>
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12} className={classes.gridSubSelect}>
              <Grid xs={6}>
                <Grid item xs={11}>
                  {!selectDisabled.selectsDisabled.participoInstanciasApoyo && (
                    <SimpleSelect
                      title="¿Participó en las instancias de apoyo?"
                      value={disciplinas.apoyo?.value || ''}
                      onChange={onApoyoChange}
                      placeholder="Seleccioná"
                      content={participoSelectsOptions || []}
                      name="apoyo"
                      labelKey="name"
                      valueKey="value"
                    />
                  )}
                </Grid>
              </Grid>
              <Grid xs={6}>
                <Grid item xs={12}>
                  {!selectDisabled.selectsDisabled.periodo && (
                    <SimpleSelect
                      title="Periodo"
                      value={disciplinas.periodo?.value || ''}
                      onChange={onPeriodoChange}
                      placeholder="Seleccioná el periodo"
                      content={tablaPeriodo || []}
                      name="periodo"
                      labelKey="name"
                      valueKey="value"
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
            <Grid container xs={12} className={classes.gridSubSelect}>
              <Grid xs={6}>
                <Grid item xs={11}>
                  {!selectDisabled.selectsDisabled.valoracion && (
                    <>
                      <Typography className={classesLabel.label}>
                        Valoración
                      </Typography>
                      <TextField
                        value={disciplinas.valoracion}
                        fullWidth
                        name="valoracion"
                        onChange={(event: any) =>
                          setDisciplinas({
                            ...disciplinas,
                            valoracion: event?.target?.value,
                          })
                        }
                      />
                    </>
                  )}
                </Grid>
              </Grid>
              <Grid xs={6}>
                <Grid item xs={12}>
                  {disciplinas.motivo?.idTipoDeudaAcademica !== '' && (
                    <SimpleSelect
                      disabled={selectDisabled.selectsDisabled.calificacion}
                      title="Calificación"
                      value={disciplinas.calificacion?.value || ''}
                      onChange={onCalificacionChange}
                      placeholder="Seleccioná la calificación"
                      content={calificacionesActual || []}
                      name="calificacion"
                      labelKey="name"
                      valueKey="value"
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Footer buttonConfig={buttonConfig} spacing={1} />
    </>
  );
};
