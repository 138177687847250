import { useState } from 'react';

interface IUseTableSortConfig {
  direction: DirectionType;
  onSortChange: IHandleRequestSort;
  orderBy: any;
}

interface IHandleRequestSort {
  (newOrderBy: string, newDirection: DirectionType): void;
}

export type DirectionType = 'asc' | 'desc';

export const useTableSortConfig = (
  initialOrderBy: string | string[],
  initialDirection: DirectionType = 'asc',
): IUseTableSortConfig => {
  const [direction, setDirection] = useState<DirectionType>(initialDirection);
  const [orderBy, setOrderBy] = useState(initialOrderBy);

  const handleRequestSort: IHandleRequestSort = (newOrderBy, newDirection) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  return {
    direction,
    onSortChange: handleRequestSort,
    orderBy,
  };
};
