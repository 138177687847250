import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ActivityExtraCurricularDAO extends LibbyFetchDAO {
  constructor() {
    super('actividad_extra_curricular', 'idActividadExtracurricular');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByIdLocalizacion(id: number | string) {
    return this.query().equals('localizacion', id).run();
  }
}
