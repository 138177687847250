import React, { useCallback } from 'react';
import { ROL } from 'src/commons/const';
import { Area, ModalidadType, UsuarioRolEstablecimiento } from 'src/app/models';
import { CardActionArea, makeStyles, Grid } from '@material-ui/core';
import { roles } from '../../../../assets/images';
import { primary } from '../../../../theme/colors';
import { AnyObject } from '../../../types';
import { OptionsModal, OptionsModalOption } from '../../OptionsModal';

const useStyles = makeStyles({
  cardActionArea: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: -5,
    marginBottom: -5,
  },
  titleText: {
    alignSelf: 'flex-start',
    fontSize: 24,
    color: primary.white,
    fontFamily: 'Open Sans',
    textShadow: '1px 1px 2px black',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingTop: 45,
    paddingLeft: 12,
  },
  descriptionText: {
    alignSelf: 'flex-start',
    fontSize: 14,
    color: primary.white,
    fontFamily: 'Open Sans',
    textShadow: '1px 1px 2px black',
  },
});

export interface CardItemContentProps {
  item: AnyObject;
  handleClick?: (item: AnyObject) => void;
  handleMenuIcon?: OptionsModalOption<UsuarioRolEstablecimiento>[];
}

export const CardItemContent = ({
  handleClick,
  handleMenuIcon,
  item,
}: CardItemContentProps) => {
  const classes = useStyles();
  const isDirectorArea = (id: number) => id === ROL.DIRECCION_AREA;

  const onClick = useCallback(
    (e) => {
      if (item.onClick) {
        item.onClick();
      } else if (handleClick) {
        handleClick(item);
      }
    },
    [handleClick, item],
  );
  return (
    <CardActionArea className={classes.cardActionArea} id="card">
      <div
        style={{
          backgroundImage: `url(${
            roles[item?.rolUsuario?.idRolUsuario?.toString()]
          })`,
          backgroundPosition: '50% 10%',
          height: 150,
        }}
      >
        {handleMenuIcon && (
          <Grid className={classes.iconContainer} id="modal">
            <OptionsModal
              options={handleMenuIcon}
              customStyleIcon={{ fill: 'white' }}
              color="inherit"
              data={item}
            />
          </Grid>
        )}
        <Grid
          className={classes.textContainer}
          style={{ paddingTop: handleMenuIcon ? 45 : 100 }}
          onClick={(e) => onClick(e)}
          id={`card-ure-${item.idRolEstablecimiento}`}
        >
          <span className={classes.titleText}>
            {item?.rolUsuario?.descripcion}
          </span>
          {isDirectorArea(item?.rolUsuario?.idRolUsuario) ? (
            <span className={classes.descriptionText}>
              {item?.dependenciaFuncional?.descripcion}
            </span>
          ) : (
            (item?.localizacion?.establecimiento?.modalidad?.idModalidad ===
              ModalidadType.General ||
              item?.localizacion?.establecimiento?.modalidad?.idModalidad ===
                ModalidadType.Adultos) && (
              <span className={classes.descriptionText}>
                {item?.areas.map((a: Area) => a.descripcion).join(' ,') ||
                  item?.nivel?.descripcionNivel}
              </span>
            )
          )}
        </Grid>
      </div>
    </CardActionArea>
  );
};
