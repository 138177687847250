import {
  JORNADA,
  Seccion,
  SituacionSeccion,
  SituacionV3,
  Turno,
} from '../../../../../../../app/models';
import { FormState, TurnoAfectado } from '../../../types';
import moment from 'moment';
import { TURNOS, TURNOS_MAP } from '../../../../../../../commons';

export type AcctionMasiveCheckCallback = (
  checked: boolean,
  secciones: Seccion[],
  state: FormState,
) => SituacionSeccion[];

export const addSeccion = (
  secciones: SituacionSeccion[],
  seccion: Seccion,
  checked: boolean,
  jornada: JORNADA,
): SituacionSeccion[] => {
  const copy = [...secciones];
  let existeIndex = copy.findIndex((sa) => sa.idSeccion === seccion.idSeccion);
  const index = jornada === JORNADA.EXTENDIDA ? 'afectaJE' : 'afectaJS';
  if (checked) {
    if (existeIndex !== -1) {
      copy[existeIndex][index] = checked;
    } else {
      let newSeccion = {
        idSeccion: seccion.idSeccion,
        afectaJS: false,
        afectaJE: false,
        idTurno: seccion.turno.idTurno.toString(),
      };
      newSeccion[index] = checked;
      copy.push(newSeccion);
    }
  } else {
    if (
      (jornada === JORNADA.SIMPLE && !copy[existeIndex].afectaJE) ||
      (jornada === JORNADA.EXTENDIDA && !copy[existeIndex].afectaJS)
    ) {
      copy.splice(existeIndex, 1);
    } else {
      copy[existeIndex][index] = checked;
    }
  }
  return [...copy];
};

export const JornadaCompletaCheckCallback: AcctionMasiveCheckCallback = (
  checked,
  secciones,
  state,
) => {
  const newSecciones: SituacionSeccion[] = [];

  if (checked) {
    secciones.forEach((seccion) => {
      const existe = !!state.seccionesAfectadas.find((sa) => {
        return sa.idSeccion === seccion.idSeccion;
      });
      if (!existe) {
        // AGREGO NO MASS
        newSecciones.push({
          idSeccion: seccion.idSeccion,
          idTurno: seccion.turno.idTurno.toString(),
          afectaJE: false,
          afectaJS: false,
        });
      }
    });
  }
  return newSecciones;
};

export const verifyDateInterception = (
  fechaInicio: Date,
  fechaFin: Date,
  dateRanges: {
    startDate: string;
    endDate: string;
  }[],
) => {
  const _fechaInicio = moment(fechaInicio, 'YYYY-MM-DD');
  const _fechaFin = moment(fechaFin, 'YYYY-MM-DD');

  _fechaInicio.startOf('day');
  _fechaFin.startOf('day');

  for (const range of dateRanges) {
    const startDate = moment(range.startDate, 'YYYY-MM-DD');
    const endDate = moment(range.endDate, 'YYYY-MM-DD');

    startDate.startOf('day');
    endDate.startOf('day');

    if (
      _fechaInicio.isSameOrBefore(endDate) &&
      _fechaFin.isSameOrAfter(startDate)
    ) {
      return true; // Hay intersección con al menos un rango
    }
  }

  return false; // No hay intersección con ningún rango
};

export const checkeSameSituacion = (
  type: 'afectaJE' | 'afectaJS',
  seccion: Seccion,
  formValues: FormState,
  situaciones: SituacionV3[],
) => {
  let sameSituacion = false;
  let sameMotivo = false;
  let hasInterception = false;
  let sameSeccion = false;

  situaciones.forEach((situacion) => {
    const { startDate, endDate } = formValues.range[0];
    const ranges = [
      {
        startDate: moment(situacion.fechaDesde, 'YYYY-MM-DD').format(
          'YYYY-MM-DD',
        ),
        endDate: moment(situacion.fechaHasta, 'YYYY-MM-DD').format(
          'YYYY-MM-DD',
        ),
      },
    ];

    if (!verifyDateInterception(startDate, endDate, ranges)) return;
    hasInterception = true;
    if (
      Number(formValues.tipoSituacion) ===
      situacion.tipoSituacion.idTipoSituacion
    ) {
      sameSituacion = true;
    }
    if (
      Number(formValues.motivoTipoSituacion) ===
      situacion.motivoSituacion.idMotivoSituacion
    ) {
      sameMotivo = true;
    }

    const exist = situacion.secciones.find(
      (s) => s.idSeccion === seccion.idSeccion,
    );

    if (exist) {
      if (Number(exist.idTurno) === seccion.turno.idTurno && exist[type]) {
        sameSeccion = true;
      }
    }
  });

  if (sameSituacion && sameMotivo && hasInterception && sameSeccion)
    return true;

  return false;
};

export const checkTurnSameSituation = (
  turnos: Turno[],
  seccion: Seccion,
  formValues: FormState,
  situaciones: SituacionV3[],
) => {
  let sameSituacion = false;
  let sameMotivo = false;
  let hasInterception = false;
  let sameSeccion = false;
  let turnosAfectados = turnos.map<TurnoAfectado>((t) => ({
    ...t,
    idTurno: t.idTurno.toString(),
    disabled: false,
  }));

  situaciones.forEach((situacion) => {
    const { startDate, endDate } = formValues.range[0];
    const ranges = [
      {
        startDate: moment(situacion.fechaDesde, 'YYYY-MM-DD').format(
          'YYYY-MM-DD',
        ),
        endDate: moment(situacion.fechaHasta, 'YYYY-MM-DD').format(
          'YYYY-MM-DD',
        ),
      },
    ];

    const isInterceted = verifyDateInterception(startDate, endDate, ranges);

    if (!isInterceted) return;
    hasInterception = true;

    if (
      Number(formValues.tipoSituacion) ===
      situacion.tipoSituacion.idTipoSituacion
    ) {
      sameSituacion = true;
    }
    if (
      Number(formValues.motivoTipoSituacion) ===
      situacion.motivoSituacion.idMotivoSituacion
    ) {
      sameMotivo = true;
    }

    const exit = situacion.secciones.find(
      (s) => s.idSeccion === seccion.idSeccion,
    );

    if (exit) {
      sameSeccion = true;

      turnosAfectados.forEach((turno) => {
        const isAfectado = exit.idTurno === turno.idTurno.toString();

        const index = turnosAfectados.findIndex(
          (_turno) => _turno.idTurno === turno.idTurno.toString(),
        );

        const turnoDoble = turnosAfectados.findIndex(
          (_turno) => _turno.idTurno === TURNOS.DOBLE.toString(),
        );

        if (index !== -1) {
          turnosAfectados[index].disabled = isAfectado;

          if (
            turnosAfectados[index].idTurno === TURNOS.DOBLE.toString() &&
            turnosAfectados[index].disabled
          ) {
            turnosAfectados.forEach((t) => {
              t.disabled = isAfectado;
            });
          }

          if (turnoDoble) {
            turnosAfectados[turnoDoble].disabled = true;
          }
        }
      });
    }
  });

  const result = turnosAfectados.map((turno) => ({
    ...turno,
    disabled:
      sameSituacion &&
      sameMotivo &&
      hasInterception &&
      sameSeccion &&
      turno.disabled,
  }));

  return result;
};
