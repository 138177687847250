import React, { useEffect, useMemo } from 'react';
import { useJustificacionMotivoLibbyFetch } from '../../../../../app/business/presentismo_v3';
import { useJustificacionFormContext } from './useJustificacionFormContext';

export const useGetJustificationRange = () => {
  const { values } = useJustificacionFormContext();
  const { data: motivos = [], working } = useJustificacionMotivoLibbyFetch();

  const justificationRange = useMemo(() => {
    const motivo = motivos.find(
      (motivo) =>
        motivo.idMotivoJustificacion.toString() === values.motivoJustificacion,
    );
    return motivo?.days_group || 0;
  }, [values.motivoJustificacion, motivos]);

  return { justificationRange, working };
};
