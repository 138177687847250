import styled, { keyframes } from 'styled-components';
import { primary } from 'src/theme/colors';

export const ContenedorInicioDeSesion = styled.div`
  transition: height 0.3s;
  height: ${(props) => (props.hidden ? '300px' : '165px')};
  background-color: #fff;
  width: 80%;
  border-radius: 15px;
  display: block;
  padding: 35px;
  justify-content: center;
  max-width: 250px;
  @media (max-width: 960px) {
    width: 100%;
  }
`;

export const GoogleButtonStyle = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const DescripcionText = styled.span`
  margin-top: 15px;
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: 600px;
`;

export const SpanText = styled.span`
  cursor: pointer;
  margin-left: 4px;
  color: ${primary.lightBlue};
  text-decoration: underline;
`;

export const TitleStyleCustom = styled.span`
  margin-top: 30%;
  font-size: 24px;
  font-weight: 600;
  font-family: 'Open Sans';
`;

export const FormStyle = styled.div``;

export const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
