import { Box, Grid, TextField, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo } from 'react';
import { useEscuelaContext } from '../../../context/EscuelaContext';
import {
  CriterioEvaluacion,
  DevolucionOrganizacion as IDevolucionOrganizacion,
} from '../../../../../../app/models';
import { Loading, useTabBarValue } from '../../../../../../commons';
import {
  EVALUACIONES,
  useRadarStyles,
} from 'src/screens/Private/AcapEstudiante/components';
import DianaChart from 'src/commons/components/Diana/DianaChart';

interface DevolucionOrganizacionProps {
  devolucion: IDevolucionOrganizacion;
  loading: boolean;
  lisenerTab: (value: boolean) => void;
}

export const DevolucionOrganizacion = ({
  devolucion,
  loading = false,
  lisenerTab,
}: DevolucionOrganizacionProps) => {
  const { criterios } = useEscuelaContext();
  const { tabIndex } = useTabBarValue();
  const classes = useRadarStyles();

  useEffect(() => {
    if (tabIndex === 2) {
      lisenerTab(true);
    }
    return () => lisenerTab(false);
  }, [tabIndex, lisenerTab]);

  const getValue = useCallback<(criterio: CriterioEvaluacion) => string>(
    (criterio) => {
      const _valor = devolucion?.evaluaciones?.find(
        (d) =>
          d.criterio.idCriterioEvaluacion === criterio.idCriterioEvaluacion,
      );
      const valor = _valor?.valor || 0;
      return EVALUACIONES[valor];
    },
    [devolucion],
  );

  const dianaValues = useMemo(() => {
    return criterios
      .filter((c) => c.forOrganizacion)
      .map((criterio) => ({
        label: criterio.nombre,
        evaluacion:
          devolucion?.evaluaciones?.find(
            (d) =>
              d.criterio.idCriterioEvaluacion === criterio.idCriterioEvaluacion,
          )?.valor || 0,
      }));
  }, [criterios, devolucion]);

  return loading ? (
    <Loading />
  ) : (
    <Grid container component={Box} paddingY={2} spacing={2}>
      <Grid
        item
        xs={12}
        lg={6}
        alignItems="center"
        justifyContent="center"
        style={{ marginTop: '6px', marginBottom: '6px', display: 'flex' }}
      >
        <div className={classes.radar}>
          <DianaChart
            title="Devolución de la organización"
            evaluaciones={dianaValues.map((dv) => dv.evaluacion)}
            labels={dianaValues.map((dv) => dv.label)}
          />
        </div>
      </Grid>
      <Grid item xs={12} lg={6} container justifyContent="center">
        <Grid xs={12} container spacing={4} component={Box} padding={3}>
          {criterios
            .filter((c) => c.forOrganizacion)
            .map((criterio) => (
              <Grid
                item
                container
                alignItems="center"
                spacing={3}
                key={criterio.idCriterioEvaluacion}
              >
                <Grid item container xs={5}>
                  <Typography variant="body2" style={{ fontWeight: 'bold' }}>
                    {criterio.nombre}
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    label=""
                    fullWidth
                    value={getValue(criterio)}
                    InputProps={{
                      disableUnderline: true,
                      readOnly: true,
                    }}
                  />
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Grid>
      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} sm={2} md={2} lg={1}>
          <Typography
            style={{
              fontWeight: 600,
            }}
          >
            Observaciones:
          </Typography>
        </Grid>

        <Grid item container xs={12} sm={10} md={10} lg={11}>
          <Grid item xs={12} style={{ marginBottom: '10px' }}>
            <TextField
              label=""
              fullWidth
              value={devolucion?.observaciones}
              multiline
              minRows={3}
              InputProps={{
                disableUnderline: true,
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
