import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { EspecialesByc } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'especiales_byc';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEspecialesByc',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEspecialesByc',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EspecialesByc
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EspecialesByc,
      hoc: { ...defaultOptions.hoc, ...businessOptions.EspecialesByc.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EspecialesByc>(DAO_NAME, optionsToUse);

// hooks
export const useEspecialesBycFetchById = fetchById;
export const useEspecialesBycLibbyCall = libbyCall;
export const useEspecialesBycDAO = libbyDAO;
export const useEspecialesBycLibbyFetch = libbyFetch;

// components/hoc
export const EspecialesBycAutocomplete = entityAutocomplete;
export const EspecialesBycCall = renderPropCall;
export const EspecialesBycById = renderPropFetchById;
export const EspecialesBycSelect = simpleSelect;
export const EspecialesBycAccordionSelector = accordionSelector;
export const EspecialesBycSelectedChips = selectedChips;

// context
// ByIdContext
export const useEspecialesBycByIdContext = useByIdContext;
export const EspecialesBycByIdProvider = ByIdProvider;
export const EspecialesBycByIdContext = ByIdContext;
// GeneralContext
export const useEspecialesBycContext = useGeneralContext;
export const EspecialesBycProvider = GeneralProvider;
export const EspecialesBycContext = GeneralContext;
