import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { TipoEstablecimiento } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'tipo_establecimiento';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idTipoEstablecimiento',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idTipoEstablecimiento',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.TipoEstablecimiento
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.TipoEstablecimiento,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.TipoEstablecimiento.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<TipoEstablecimiento>(DAO_NAME, optionsToUse);

// hooks
export const useTipoEstablecimientoFetchById = fetchById;
export const useTipoEstablecimientoLibbyCall = libbyCall;
export const useTipoEstablecimientoDAO = libbyDAO;
export const useTipoEstablecimientoLibbyFetch = libbyFetch;

// components/hoc
export const TipoEstablecimientoAutocomplete = entityAutocomplete;
export const TipoEstablecimientoCall = renderPropCall;
export const TipoEstablecimientoById = renderPropFetchById;
export const TipoEstablecimientoSelect = simpleSelect;
export const TipoEstablecimientoAccordionSelector = accordionSelector;
export const TipoEstablecimientoSelectedChips = selectedChips;

// context
// ByIdContext
export const useTipoEstablecimientoByIdContext = useByIdContext;
export const TipoEstablecimientoByIdProvider = ByIdProvider;
export const TipoEstablecimientoByIdContext = ByIdContext;
// GeneralContext
export const useTipoEstablecimientoContext = useGeneralContext;
export const TipoEstablecimientoProvider = GeneralProvider;
export const TipoEstablecimientoContext = GeneralContext;
