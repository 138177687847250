import React from 'react';
import { Box, SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

export const COLORS = {
  GREEN: {
    typography: '#619E8C',
    bgcolor: '#F1FFFC',
    borderColor: '#C2C2C2',
  },
  YELLOW: {
    typography: '#FFB862',
    bgcolor: '#FFFCEB',
    borderColor: undefined,
  },
  RED: {
    typography: '#C93B3B',
    bgcolor: '#FFF4F1',
    borderColor: '#C93B3B',
  },
  GREY: {
    typography: '#38485C',
    bgcolor: '#DDE1E5',
    borderColor: '#C2C2C2',
  },
  ORANGE: {
    typography: '#FF8059',
    bgcolor: '#FFF4F1',
    borderColor: undefined,
  },
  WHITE: {
    typography: '#00000',
    bgcolor: 'transparent',
    borderColor: undefined,
  },
  BLUE: {
    typography: '#3786EE',
    bgcolor: '#EFF8FF',
    borderColor: '#3786EE',
  },
};

export type IndicatorColor =
  | 'GREEN'
  | 'YELLOW'
  | 'RED'
  | 'GREY'
  | 'ORANGE'
  | 'WHITE'
  | 'BLUE';
export type IndicatorVariant = 'contained' | 'outline' | 'text';
interface IndicatorProps {
  label?: string;
  color?: IndicatorColor;
  variant?: IndicatorVariant;
  fontSize?: number;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
}

export const Indicator = ({
  color = 'WHITE',
  label = '',
  variant = 'contained',
  fontSize = 16,
  icon,
}: IndicatorProps) => {
  const Icon = icon ? icon : () => <></>;
  const isContained = variant === 'contained';
  const isOutline = variant === 'outline';

  return (
    <Box
      style={{
        backgroundColor: isContained ? COLORS[color].bgcolor : '',
        pointerEvents: 'none',
      }}
      border={isOutline ? 1 : 0}
      borderColor={isOutline ? COLORS[color].borderColor : ''}
      bgcolor={isContained ? COLORS[color].bgcolor : ''}
      borderRadius={5}
      px={0.2}
      maxWidth={80}
      minWidth={40}
      height={isContained ? 24 : 22}
      display="flex"
      justifyContent="center"
    >
      <Box
        display="flex"
        fontFamily="Nunito"
        bgcolor={undefined}
        p={0}
        m={0}
        alignItems="center"
        fontWeight="600"
        fontSize={fontSize}
        color={COLORS[color].typography}
        component="span"
      >
        {label}
        <Icon fontSize="small" style={{ width: 17, height: 17 }} />
      </Box>
    </Box>
  );
};
