import { DevolucionContenidosDAO } from 'src/platform/libby/dao';
import { makeBusinessEntity } from '../../../lib/libby';
import { DevolucionContenido } from '../../models/acap';

const DAO_NAME = 'devoluciones_contenidos';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
} = makeBusinessEntity<DevolucionContenido, DevolucionContenidosDAO>(
  DAO_NAME,
  {},
);

// hooks
export const useDevolucionContenidosFetchById = fetchById;
export const useDevolucionContenidosLibbyCall = libbyCall;
export const useDevolucionContenidosDAO = libbyDAO;
export const useDevolucionContenidosLibbyFetch = libbyFetch;
