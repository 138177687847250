import React, { useMemo } from 'react';
import { usePermissionContext } from '../permissionContext';

export const PermissionBlocker = ({
  children,
  id,
}: {
  children: JSX.Element;
  id: number | string;
}) => {
  const { activeRules } = usePermissionContext();

  const isBlock = useMemo(
    () => activeRules.blockers.some((blocker) => blocker.id === id),
    [activeRules, id],
  );

  return <>{isBlock ? null : children}</>;
};
