import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React from 'react';
import customFormDialog from 'src/commons/services/customFormDialog';

interface CondicionesModalTypes {
  reason: string | undefined;
  detail: string | undefined;
}

const useStyles = makeStyles({
  button: {
    textDecoration: 'none',
  },
  textMultiline: {
    '& > .MuiInputBase-root > .MuiInput-inputMultiline': {
      padding: '4px 7px !important',
    },
  },
});

export const CondicionesModal = ({ reason, detail }: CondicionesModalTypes) => {
  const classes = useStyles();

  return (
    <Container maxWidth="lg">
      <Grid item xs={12}>
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingBottom: '20px',
            }}
          >
            <Typography style={{ paddingRight: '10px' }}>Motivo:</Typography>
            <TextField value={reason} disabled fullWidth />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingBottom: '20px',
            }}
          >
            <Typography style={{ paddingRight: '10px' }}>Detalle:</Typography>
            <TextField
              value={detail}
              disabled
              multiline
              fullWidth
              minRows={4}
              className={classes.textMultiline}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingBottom: '15px',
        }}
      >
        <Button
          className={classes.button}
          variant="text"
          color="primary"
          onClick={() => customFormDialog.handleCancel()}
        >
          Cerrar
        </Button>
      </Grid>
    </Container>
  );
};
