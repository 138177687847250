import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Publicacion } from '../../../../../../app/models';
import { Publicacion as Publicaciones } from './components/Publicacion/Publicacion';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
}));
export interface PublicacionTypesProps {
  items: Publicacion[];
  reFetch?: () => void;
}

export const PublicacionesList = ({
  items,
  reFetch,
}: PublicacionTypesProps) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      spacing={4}
      className={classes.container}
    >
      {items.map((item) => (
        <Publicaciones
          id={item.idPublicacion}
          idCuenta={item.cuentas.idUsuario}
          userName={item.cuentas.nombreUsuario}
          date={item.fechaCreacion}
          message={item.descripcion}
          url={item.url}
          comentable={item.comentable}
          publicacion={item}
          reFetchPublicaciones={reFetch}
          key={item.idPublicacion}
        />
      ))}
    </Grid>
  );
};
