import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ExternalPersonEnrolledDAO extends LibbyFetchDAO {
  constructor() {
    super('matricula_persona_externa', 'idMatriculaPersonaExterna');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByIdLocalizacion(id: string | number) {
    return this.query().equals('localizacion', id).limit(0, 10000).run();
  }

  getByIdMatriculadoEn(id: string | number) {
    return this.query()
      .equals('localizacionMatriculadoEn', id)
      .limit(0, 10000)
      .run();
  }
}
