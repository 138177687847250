import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { InstrumentosDeEvaluacion } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'instrumentos_de_evaluacion';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idInstrumentosDeEvaluacion',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idInstrumentosDeEvaluacion',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.InstrumentosDeEvaluacion
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.InstrumentosDeEvaluacion,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.InstrumentosDeEvaluacion.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<InstrumentosDeEvaluacion>(DAO_NAME, optionsToUse);

// hooks
export const useInstrumentosDeEvaluacionFetchById = fetchById;
export const useInstrumentosDeEvaluacionLibbyCall = libbyCall;
export const useInstrumentosDeEvaluacionDAO = libbyDAO;
export const useInstrumentosDeEvaluacionLibbyFetch = libbyFetch;

// components/hoc
export const InstrumentosDeEvaluacionAutocomplete = entityAutocomplete;
export const InstrumentosDeEvaluacionCall = renderPropCall;
export const InstrumentosDeEvaluacionById = renderPropFetchById;
export const InstrumentosDeEvaluacionSelect = simpleSelect;
export const InstrumentosDeEvaluacionAccordionSelector = accordionSelector;
export const InstrumentosDeEvaluacionSelectedChips = selectedChips;

// context
// ByIdContext
export const useInstrumentosDeEvaluacionByIdContext = useByIdContext;
export const InstrumentosDeEvaluacionByIdProvider = ByIdProvider;
export const InstrumentosDeEvaluacionByIdContext = ByIdContext;
// GeneralContext
export const useInstrumentosDeEvaluacionContext = useGeneralContext;
export const InstrumentosDeEvaluacionProvider = GeneralProvider;
export const InstrumentosDeEvaluacionContext = GeneralContext;
