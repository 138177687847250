import { Grid } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import React from 'react';
import { SimpleSelect, ROL } from 'src/commons';
import FiltersSelect from '../FiltersSelect';
import reportsList from '../reportsConfig';
import { useRolesContext } from 'src/context/RolesContext';
import { useReportsContext } from '../contexts/ReportContext';

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
};

export const SelectReportList = ({
  onChange,
  reportCategory,
}: {
  reportCategory: string;
  onChange: (e: React.ChangeEvent<{ value: any }>) => void;
}) => {
  const { selectedRole } = useRolesContext();
  const { currentReport } = useReportsContext();
  const reports = Object.keys(reportsList[reportCategory]).map((report) => ({
    id: report,
    label: reportsList[reportCategory][report].label,
  }));

  return (
    <Grid container spacing={2}>
      <Grid item xs={2}>
        <SimpleSelect
          title="Lista de Reportes"
          content={reports.filter(
            (report) =>
              !reportsList[reportCategory][report.id].cannotSee.includes(
                Number(selectedRole.rol),
              ),
          )}
          valueKey="id"
          labelKey="label"
          name="currentReport"
          placeholder="Seleccioná un reporte"
          value={currentReport}
          handleChange={onChange}
          customStyleTitle={customStyles.customStyleTitleSelect}
        />
      </Grid>
      {!!currentReport &&
        (Number(selectedRole.rol) === ROL.NO_ROL ||
          selectedRole.rol === '') && <FiltersSelect />}
    </Grid>
  );
};
