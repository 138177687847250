import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from 'react';
import {
  useEspacioCurricularSeccionLibbyFetch,
  useGruposInscripcionesLibbyCall,
  useUsuarioRolTraverseActionLibbyFetch,
} from '../../../../app/business';
import {
  EscuelaContextValue,
  EscuelaProviderProps,
  FilterState,
  GruposInscripcionesAlumns,
} from '../types';
import { OfertaGrupo } from '../../../../app/models';
import { DEVOLUCIONES_SCREEN } from '../enums/DevolucionesScreen';
import { useCriteriosEvaluacionLibbyCall } from '../../../../app/business/acap/CriterioEvaluacion';
import { useGrupoInscripcionAutoevaluadosLibbyFetch } from '../../../../app/business/acap/GrupoInscripcionAutoevaluados';
import { CURRENT_CICLO_LECTIVO } from '../../Acap/tabs';
import { useRolesContext } from '../../../../context/RolesContext';
import { TRAVERSE_ACTIONS, ROL } from '../../../../commons';
import { useLinkedList } from '../../../../utils';

const initialState: FilterState = {
  seccion: '',
  accion: '',
  organizacion: '',
  sector: '',
  orientacion: '',
};

export const EscuelaContext = createContext<EscuelaContextValue>({
  filter: initialState,
  ofertasGrupos: [],
  loading: false,
  ofertaSelected: undefined,
  currentTap: DEVOLUCIONES_SCREEN.DEFAULT,
  alumnoSelected: undefined,
  criterios: [],
  inscriptos: [],
  disabledNext: false,
  espacioCurricularSeccion: [],
  docentesNodos: [],
  tab: 0,
  setCurrentTap: () => {},
  setFilter: () => {},
  setOfertaSelected: () => {},
  reFetchOfertasGrupos: () => {},
  handleFilterChange: () => {},
  setAlumnoSelected: () => {},
  nextStudent: () => {},
  reFecthEvaluaciones: () => {},
  setTab: () => {},
});

export const useEscuelaContext = () => useContext(EscuelaContext);

export const EscuelaProvider = ({ children }: EscuelaProviderProps) => {
  const [filter, setFilter] = useState<FilterState>(initialState);
  const [tab, setTab] = useState(0);
  const [currentTap, setCurrentTap] = useState<DEVOLUCIONES_SCREEN>(
    DEVOLUCIONES_SCREEN.DEFAULT,
  );
  const [ofertaSelected, setOfertaSelected] = useState<OfertaGrupo>();
  const { userInfo, selectedRole } = useRolesContext();

  const handleFilterChange = useCallback(
    (event) => {
      const target = event.target;
      setFilter((prev) => ({ ...prev, [target.name]: `${target.value}` }));
    },
    [setFilter],
  );

  const urtaFilter = useMemo(
    () => ({
      0: [{ path: 'traverseAction', value: TRAVERSE_ACTIONS.NODO }],
      1: [
        {
          path: 'usuarioRolEstablecimiento.usuario.idUsuario',
          value: userInfo.id,
        },
      ],
      2: [
        {
          path: 'usuarioRolEstablecimiento.rolUsuario.idRolUsuario',
          value: selectedRole.rol,
        },
      ],
      3: [
        {
          path: 'usuarioRolEstablecimiento.localizacion.idLocalizacion',
          value: selectedRole.localizacionId,
        },
      ],
    }),
    [userInfo.id, selectedRole.rol, selectedRole.localizacionId],
  );

  const { data: [urta] = [], working: workinngEjes } =
    useUsuarioRolTraverseActionLibbyFetch({
      filter: urtaFilter,
      enabled:
        Boolean(userInfo.id) &&
        Boolean(selectedRole.rol) &&
        Boolean(selectedRole.localizacionId),
    });

  const filterGrupoInscripciones = useMemo(() => {
    const _filter = {
      ...(filter.accion
        ? {
            accion: [
              {
                path: 'ofertaGrupo.ofertaTurno.oferta.accion',
                value: filter.accion,
              },
            ],
          }
        : {}),
      ...(filter.sector
        ? {
            sector: [
              {
                path: 'ofertaGrupo.ofertaTurno.oferta.accion.organizacion.sector',
                value: filter.sector,
              },
            ],
          }
        : {}),
      ...(filter.orientacion
        ? {
            orientacion: [
              {
                path: 'ofertaGrupo.ofertaTurno.oferta.accion.accionOrientaciones.orientacion.idOrientacion',
                value: filter.orientacion,
              },
            ],
          }
        : {}),
      fechaInicio: [
        {
          path: 'ofertaGrupo.ofertaTurno.oferta.fechaInicio',
          value: false,
          method: 'isnull',
        },
      ],
    };
    return {
      filter: _filter,
      limit: 20000,
      orderBy: 'ofertaGrupo.ofertaTurno.oferta.accion.nombre',
    };
  }, [filter]);

  const {
    data: grupoInscripciones = [],
    working: workingGrupoInscripciones,
    recall: recallGrupoInscripciones,
  } = useGruposInscripcionesLibbyCall({
    methodName: 'fetch',
    params: [filterGrupoInscripciones],
  });

  const ofertasFiltered = useMemo<OfertaGrupo[]>(() => {
    const ofertasGrupo: OfertaGrupo[] = [];
    grupoInscripciones
      .filter(
        (gi) =>
          !(
            // esta filtrando las ofertas cerradas con cupos y distintas a la localizacion
            (
              gi?.ofertaGrupo.tipoCupo === 'Cerrado' &&
              gi?.ofertaGrupo.cupoEstatal > 0 &&
              gi?.ofertaGrupo.localizacion?.idLocalizacion !==
                selectedRole.localizacionId
            )
            // y filtra aqueellas que sean cerradas y con 0 cupo
          ) &&
          !(
            gi?.ofertaGrupo.tipoCupo === 'Cerrado' &&
            gi?.ofertaGrupo.cupoEstatal === 0
          ),
      )

      .forEach((og) => {
        if (
          !ofertasGrupo.find(
            (_og) => _og.idOfertaGrupo === og.ofertaGrupo.idOfertaGrupo,
          )
        ) {
          og.ofertaGrupo.idGrupoInscripcion = og.idGrupoInscripcion;
          ofertasGrupo.push(og.ofertaGrupo);
        }
      });
    return ofertasGrupo;
  }, [grupoInscripciones, selectedRole]);

  const { data: criterios = [], working: criteriosWorking } =
    useCriteriosEvaluacionLibbyCall({
      methodName: 'getAllActivos',
    });

  const filterIncriptos = useMemo(() => {
    return {
      ...(filter.seccion
        ? {
            seccion: [
              {
                path: 'alumnoMovimiento.seccion.idSeccion',
                value: filter.seccion,
              },
            ],
          }
        : {}),
      oferta: [
        {
          path: 'ofertaGrupo.idOfertaGrupo',
          value: ofertaSelected?.idOfertaGrupo || 0,
        },
      ],
      actividad: [
        {
          path: 'ofertaGrupo.ofertaTurno.oferta.accion.idAccion',
          value: ofertaSelected?.ofertaTurno?.oferta?.accion?.idAccion || 0,
        },
      ],
    };
  }, [ofertaSelected, filter.seccion]);

  const {
    data: inscriptos = [],
    working: loading,
    reFetch,
  } = useGrupoInscripcionAutoevaluadosLibbyFetch({
    filter: filterIncriptos,
    aspect: 'estudiante-list',
    orderBy:
      'alumnoMovimiento.alumno.persona.apellido,alumnoMovimiento.alumno.persona.nombre',
    enabled: Boolean(ofertaSelected),
  });

  const {
    current: alumnoSelected,
    disabledNext,
    nextStudent,
    setCurrent: setAlumnoSelected,
  } = useLinkedList<GruposInscripcionesAlumns>({
    items: inscriptos,
    id: 'idGrupoInscripcion',
    loading,
  });

  const _nextStudent = useCallback(() => {
    reFetch();
    nextStudent();
  }, [nextStudent, reFetch]);

  const reFecthEvaluaciones = useCallback(() => {
    reFetch();
  }, [reFetch]);

  const reFetchOfertasGrupos = useCallback(() => {
    recallGrupoInscripciones(filterGrupoInscripciones);
  }, [recallGrupoInscripciones, filterGrupoInscripciones]);

  const filterEC = useMemo(
    () => ({
      anioSeccion: [
        {
          path: 'seccion.anio',
          value: [25, 26],
          method: 'in',
        },
      ],
      ...(alumnoSelected
        ? {
            seccion: [
              {
                path: 'seccion',
                value: [alumnoSelected.alumnoMovimiento.seccion.idSeccion],
              },
            ],
          }
        : {}),
    }),
    [alumnoSelected],
  );

  const { data: espacioCurricularSeccion = [], working } =
    useEspacioCurricularSeccionLibbyFetch({
      filter: filterEC,
      enabled: Boolean(alumnoSelected),
      ...(ROL.COORDINADOR_ORIENTACION === Number(selectedRole.rol) && {
        aspect: 'acap_devoluciones',
      }),
    });

  const values = useMemo<EscuelaContextValue>(() => {
    const _values: EscuelaContextValue = {
      filter,
      ofertasGrupos: ofertasFiltered,
      loading:
        criteriosWorking ||
        workinngEjes ||
        loading ||
        working ||
        workingGrupoInscripciones,
      ofertaSelected,
      currentTap,
      alumnoSelected,
      criterios,
      inscriptos,
      disabledNext,
      espacioCurricularSeccion,
      docentesNodos: urta?.value || [],
      tab,
      setTab,
      setAlumnoSelected,
      setCurrentTap,
      setFilter,
      setOfertaSelected,
      reFetchOfertasGrupos,
      handleFilterChange,
      nextStudent: _nextStudent,
      reFecthEvaluaciones,
    };
    return _values;
  }, [
    tab,
    filter,
    ofertaSelected,
    ofertasFiltered,
    workingGrupoInscripciones,
    currentTap,
    alumnoSelected,
    criterios,
    criteriosWorking,
    inscriptos,
    loading,
    disabledNext,
    espacioCurricularSeccion,
    working,
    workinngEjes,
    urta,
    setFilter,
    reFetchOfertasGrupos,
    handleFilterChange,
    setCurrentTap,
    setAlumnoSelected,
    _nextStudent,
    reFecthEvaluaciones,
    setTab,
  ]);

  return (
    <EscuelaContext.Provider value={values}>{children}</EscuelaContext.Provider>
  );
};
