import { primary } from 'src/theme/colors';

export const makeIconColor: Record<string, string> = {
  1: primary.success,
  2: primary.error,
  3: primary.paleBlueStrong,
  4: primary.violet,
  5: primary.error,
  6: primary.successMedium,
  7: primary.violet,
  8: primary.successMedium,
  9: primary.successMedium,
  10: primary.success,
};
