import { RecuperacionDAO } from 'src/platform/libby/dao';
import { RowsSaver } from './RowsSaver';

export function RowsModified<T>(rows: T[]) {
  this.rows = rows;
}

RowsModified.prototype = {
  async save(dao: RecuperacionDAO, ecs: string) {
    for (const row of this.rows) {
      const saver = new RowsSaver<Recuperacion>(row);
      try {
        await saver.save(dao, ecs);
      } catch (err) {
        console.error(err);
      }
    }
  },
};
