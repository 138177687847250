import * as React from 'react';

export const BuenosAiresCiudadLogin = () => (
  <svg
    width="266"
    height="32"
    viewBox="0 0 266 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M122.01 15.3929C122.01 14.2766 121.501 13.4853 120.667 13.0473C121.275 12.6093 121.628 11.9452 121.628 
      11.1256C121.628 9.48653 120.639 8.46917 118.675 8.46917H114.153V18.2754H118.929C120.823 18.2754 122.01 
      17.145 122.01 15.3929ZM118.675 10.1648C119.382 10.1648 119.82 10.5887 119.82 11.2387C119.82 11.8886 119.311 
      12.3267 118.675 12.3267H116.019V10.1648H118.675ZM120.102 15.3222C120.102 16.2831 119.396 16.5657 118.675 
      16.5657H116.019V14.0364H118.633C119.579 14.0364 120.102 14.5027 120.102 15.3222ZM129.606 
      10.9984H127.741V15.2516C127.741 16.057 127.162 16.6787 126.328 16.6787C125.113 16.6787 
      124.971 15.6613 124.971 15.2516V10.9984H123.106V15.2516C123.106 17.1591 124.251 18.445 125.975 
      18.445C126.738 18.445 127.374 18.1341 127.826 17.6113V18.2754H129.606V10.9984ZM138.27 15.3646C138.539 
      12.4821 136.758 10.8148 134.611 10.8148C132.562 10.8148 130.88 12.5386 130.88 14.644C130.88 16.7494 
      132.562 18.445 134.611 18.445C136.419 18.445 137.705 17.4417 138.129 15.9581H136.137C135.826 16.5374 
      135.218 16.8059 134.596 16.8059C133.72 16.8059 133.014 16.2548 132.774 15.3646H138.27ZM132.802 
      13.8386C133.07 12.9908 133.749 12.4538 134.596 12.4538C135.487 12.4538 136.193 12.9908 136.419 
      13.8386H132.802ZM146.069 14.0223C146.069 12.1006 144.939 10.8148 143.229 10.8148C142.452 10.8148 
      141.816 11.1539 141.35 11.7049V10.9984H139.57V18.2754H141.435V14.0223C141.435 13.2027 142.014 
      12.581 142.848 12.581C144.077 12.581 144.204 13.5984 144.204 14.0223V18.2754H146.069V14.0223ZM150.96 
      10.8148C148.94 10.8148 147.301 12.468 147.301 14.644C147.301 16.82 148.94 18.445 150.96 18.445C152.981 
      18.445 154.634 16.82 154.634 14.644C154.634 12.468 152.981 10.8148 150.96 10.8148ZM150.96 16.7352C149.971
       16.7352 149.166 15.8874 149.166 14.6581C149.166 13.4005 149.971 12.5245 150.96 12.5245C151.95 12.5245 
       152.755 13.4005 152.755 14.6581C152.755 15.8874 151.95 16.7352 150.96 16.7352ZM162.054 15.9863C162.054 
       14.4038 160.754 14.0081 158.945 13.7114C158.041 13.5701 157.504 13.4429 157.504 13.0614C157.504 12.793 
       157.857 12.4821 158.719 12.4821C159.412 12.4821 159.821 12.7506 159.934 13.0897H161.87C161.63 11.7191
        160.387 10.8148 158.719 10.8148C156.614 10.8148 155.611 12.0017 155.611 13.1462C155.611 14.7712 157.349
         15.1244 158.649 15.3222C159.92 15.52 160.175 15.6896 160.175 16.0994C160.175 16.5233 159.609 16.7494 
         158.861 16.7494C158.309 16.7494 157.561 16.5233 157.306 15.9016H155.413C155.639 17.47 157.01 18.445 
         158.861 18.445C160.726 18.445 162.054 17.4276 162.054 15.9863ZM173.485 16.3113L174.149 
         18.2754H176.127L172.708 8.46917H170.348L166.929 18.2754H168.907L169.571 16.3113H173.485ZM172.891 
         14.5733H170.15L171.521 10.5463L172.891 14.5733ZM179.024 8.46917H177.159V10.2072H179.024V8.46917ZM179.024 
         10.9984H177.159V18.2754H179.024V10.9984ZM187.273 14.0223C187.273 12.1006 186.142 10.8148 184.433 
         10.8148C183.655 10.8148 183.02 11.1539 182.553 11.7049V10.9984H180.773V18.2754H182.638V14.0223C182.638 
         13.2027 183.217 12.581 184.051 12.581C185.28 12.581 185.408 13.5984 185.408 14.0223V14.3896H187.273V14.0223ZM195.494 
         15.3646C195.762 12.4821 193.982 10.8148 191.834 10.8148C189.785 10.8148 188.104 12.5386 188.104 
         14.644C188.104 16.7494 189.785 18.445 191.834 18.445C193.643 18.445 194.929 17.4417 195.353 
         15.9581H193.36C193.049 16.5374 192.442 16.8059 191.82 16.8059C190.944 16.8059 190.238 16.2548 189.997 
         15.3646H195.494ZM190.026 13.8386C190.294 12.9908 190.972 12.4538 191.82 12.4538C192.71 12.4538 193.417 
         12.9908 193.643 13.8386H190.026ZM202.94 15.9863C202.94 14.4038 201.64 14.0081 199.831 13.7114C198.927 
         13.5701 198.39 13.4429 198.39 13.0614C198.39 12.793 198.743 12.4821 199.605 12.4821C200.298 12.4821 
         200.707 12.7506 200.82 13.0897H202.756C202.516 11.7191 201.273 10.8148 199.605 10.8148C197.5 10.8148 
         196.497 12.0017 196.497 13.1462C196.497 14.7712 198.235 15.1244 199.535 15.3222C200.806 15.52 201.061 
         15.6896 201.061 16.0994C201.061 16.5233 200.495 16.7494 199.746 16.7494C199.195 16.7494 198.447 16.5233 
         198.192 15.9016H196.299C196.525 17.47 197.895 18.445 199.746 18.445C201.612 18.445 202.94 17.4276 202.94 
         15.9863ZM217.395 15.1668H215.388C214.95 16.057 214.06 16.7494 212.944 16.7494C211.333 16.7494 210.061 
         15.1951 210.061 13.3299C210.061 11.4789 211.333 9.92456 212.944 9.92456C214.074 9.92456 214.95 10.5887 
         215.388 11.4789H217.409C216.801 9.4724 215.162 8.21483 212.944 8.21483C210.273 8.21483 208.196 10.4615 
         208.196 13.3299C208.196 16.1983 210.273 18.445 212.944 18.445C215.12 18.445 216.773 17.1591 217.395 
         15.1668ZM220.489 8.46917H218.624V10.2072H220.489V8.46917ZM220.489 10.9984H218.624V18.2754H220.489V10.9984ZM228.613 
         10.9984H226.747V15.2516C226.747 16.057 226.168 16.6787 225.334 16.6787C224.119 16.6787 223.978 15.6613 
         223.978 15.2516V10.9984H222.113V15.2516C222.113 17.1591 223.257 18.445 224.981 18.445C225.744 18.445 
         226.38 18.1341 226.832 17.6113V18.2754H228.613V10.9984ZM237.362 7.76267H235.496V11.6908C234.931 11.1539 
         234.196 10.843 233.349 10.843C231.455 10.843 229.887 12.4821 229.887 14.644C229.887 16.7917 231.455 18.4308 
         233.349 18.4308C234.239 18.4308 235.002 18.0917 235.581 17.4982V18.2754H237.362V7.76267ZM235.496 
         14.6581C235.496 15.7885 234.663 16.707 233.631 16.707C232.6 16.707 231.766 15.8168 231.766 14.6581C231.766 
         13.4712 232.6 12.5669 233.631 12.5669C234.663 12.5669 235.496 13.4995 235.496 14.6581ZM246.151 
         10.9984H244.371V11.7756C243.806 11.1821 243.029 10.843 242.138 10.843C240.245 10.843 238.677 12.4821 
         238.677 14.644C238.677 16.7917 240.245 18.4308 242.138 18.4308C243.029 18.4308 243.806 18.0917 244.371 
         17.4982V18.2754H246.151V10.9984ZM244.286 14.6581C244.286 15.7885 243.453 16.707 242.421 16.707C241.39 
         16.707 240.556 15.8168 240.556 14.6581C240.556 13.4712 241.39 12.5669 242.421 12.5669C243.453 12.5669 
         244.286 13.4995 244.286 14.6581ZM254.927 7.76267H253.062V11.6908C252.497 11.1539 251.762 10.843 250.915 
         10.843C249.021 10.843 247.453 12.4821 247.453 14.644C247.453 16.7917 249.021 18.4308 250.915 
         18.4308C251.805 18.4308 252.568 18.0917 253.147 17.4982V18.2754H254.927V7.76267ZM253.062 14.6581C253.062 
         15.7885 252.229 16.707 251.197 16.707C250.166 16.707 249.332 15.8168 249.332 14.6581C249.332 13.4712 
         250.166 12.5669 251.197 12.5669C252.229 12.5669 253.062 13.4995 253.062 14.6581Z"
      fill="white"
    />
    <path
      d="M102.309 7.32881H102.288C101.517 7.30024 100.782 7.01452 100.068 6.74308C99.7753 6.63594 99.4827 6.52165 
      99.1829 6.42879C98.5976 6.24307 98.0765 6.13593 97.5911 6.1145C97.4626 6.10735 97.3484 6.10735 97.2199 
      6.10735C96.8916 6.10735 96.4276 6.13593 96.1421 6.42165C96.0636 6.05735 95.9637 5.6502 95.8138 
      5.26448C95.671 4.90018 95.4498 4.46446 95.0643 4.34303C95.0001 4.3216 94.9358 4.31445 94.8716 
      4.31445C94.4861 4.31445 94.1935 4.66446 94.0436 4.87161L93.9793 4.96447C93.808 5.20733 93.7295 
      5.32876 93.7509 5.44305C93.7795 5.55734 93.9151 5.64306 94.2292 5.82878C94.3648 5.90735 94.3862 
      6.10735 94.4076 6.25021V6.2645C94.4362 6.48593 94.4504 6.70023 94.4504 6.90023C94.0008 6.44308 93.3227 
      6.25736 92.7017 6.14307C92.5375 6.1145 92.3662 6.09307 92.1877 6.09307C91.4811 6.09307 90.7816 6.35736 
      90.3176 6.55736C90.0535 6.67165 89.7965 6.79308 89.5396 6.92166C89.0471 7.15024 88.5474 7.3931 88.0263 
      7.55025C87.8622 7.60025 87.698 7.63596 87.491 7.67168H87.4767C87.4767 7.67882 87.4696 7.67168 87.4625 
      7.67168H87.4482C87.3697 7.68596 87.234 7.72882 87.2055 7.85025C87.1841 7.97169 87.1984 8.05026 87.3625 
      8.18598C87.7408 8.40027 88.2119 8.50741 88.783 8.50741C88.8972 8.50741 89.0185 8.50741 89.1541 
      8.49313C89.3254 8.48598 89.4968 8.46455 89.6823 8.43598C89.9464 8.40027 90.2177 8.3717 90.4746 
      8.3717C90.6531 8.3717 90.8101 8.39312 90.9529 8.4217C91.374 8.5217 91.631 8.94314 91.8451 9.31458C91.9022 
      9.40743 91.9522 9.49315 92.0093 9.57172L92.0164 9.57887C92.1164 9.72887 92.2877 9.99316 92.2805 
      10.186C92.2734 10.3717 92.0093 10.5289 91.8237 10.6432C91.7737 10.6789 91.7238 10.7075 91.6881 
      10.7289C91.631 10.7718 91.5525 10.8146 91.4811 10.8646C91.0528 11.136 90.4675 11.5003 90.5389 
      12.0004C90.5674 12.2432 90.7744 12.4432 91.0243 12.4718C91.0457 12.4789 91.0671 12.4789 91.0956 
      12.4789C91.2669 12.4789 91.4311 12.3932 91.581 12.3218C91.6453 12.2861 91.7166 12.2504 91.7737 
      12.2289C91.9736 12.1504 92.1806 12.1075 92.3805 12.1075C92.7231 12.1075 93.0586 12.2218 93.3583 
      12.4504C93.4583 12.5289 93.5511 12.6147 93.6367 12.7004C93.7295 12.8004 93.8295 12.9004 93.9437 
      12.9718C94.0293 13.0361 94.1935 13.1075 94.3362 13.1075C94.479 13.1075 94.5647 13.0432 94.5932 
      12.9147C94.6432 12.6647 94.6432 12.4075 94.6432 12.1575C94.6432 11.9575 94.6432 11.7503 94.6646 
      11.5503C94.6789 11.4289 94.7145 11.186 94.8716 11.1075C94.9215 11.0789 94.9858 11.0718 95.0572 
      11.0718C95.1357 11.0718 95.2213 11.086 95.2999 11.1003C95.4141 11.1218 95.5283 11.136 95.6282 
      11.1218C96.0779 11.0646 96.4562 10.6932 96.6275 10.136C96.7132 9.9503 96.8131 9.87173 97.0058 
      9.82173C97.2271 9.77173 97.3698 9.58601 97.5126 9.40743C97.5483 9.36458 97.584 9.32172 97.6125 
      9.27886C97.898 8.94314 98.2407 8.79313 98.726 8.79313C98.8117 8.79313 98.9116 8.80028 99.0187 
      8.80742C99.1115 8.81456 99.2186 8.82885 99.3113 8.84314C99.5683 8.87171 99.8181 8.90028 100.068 
      8.90028C100.339 8.90028 100.582 8.86456 100.81 8.79313C101.396 8.60027 101.91 8.22169 102.381 
      7.84311L102.452 7.79311C102.588 7.67882 102.616 7.60739 102.581 7.48596C102.545 7.36453 102.374 
      7.33596 102.309 7.32881ZM92.252 9.05028C92.1378 8.85742 92.0164 8.65742 91.8737 8.48598C91.5311 
      8.07169 91.0885 7.88597 90.4104 7.88597C90.132 7.88597 89.8322 7.92168 89.5467 7.95026C89.2683 
      7.97883 88.9757 8.0074 88.6973 8.0074C88.5545 8.0074 88.4189 8.00026 88.2976 7.98597C88.783 7.82168 
      89.2541 7.60025 89.7038 7.38596C90.1891 7.15024 90.6888 6.91452 91.217 6.75023C91.5382 6.65022 
      91.8808 6.60022 92.2234 6.60022C92.8516 6.60022 93.4297 6.76451 93.9008 7.07881C94.2934 7.33596 
      94.4076 7.61453 94.272 8.00026C94.1292 8.40741 93.8866 8.81456 93.5439 9.20029C93.5225 9.22172 
      93.494 9.25743 93.4654 9.286C93.3155 9.46458 93.0728 9.75744 92.8587 9.75744C92.7945 9.75744 
      92.7374 9.72887 92.6874 9.67173C92.5089 9.48601 92.3805 9.26457 92.252 9.05028ZM96.1992 
      9.63601C96.1493 9.97888 96.0136 10.2717 95.8066 10.4575C95.6211 10.6218 95.4212 10.6575 
      95.15 10.6575H95.0143C94.9644 10.6575 94.9144 10.6575 94.8644 10.6575C94.6931 10.6575 
      94.5575 10.7146 94.4433 10.8218C94.1221 11.1503 94.1221 11.8718 94.1292 12.3432V12.5004L94.1007 
      12.4718C93.6938 12.0932 93.3084 11.7289 92.7302 11.6432C92.6374 11.6289 92.5446 11.6218 92.4518 
      11.6218C92.2805 11.6218 92.0878 11.6432 91.9022 11.6861C91.738 11.7218 91.5953 11.7861 91.4525 
      11.8575C91.3954 11.8789 91.3383 11.9075 91.2812 11.9361C91.2098 11.9646 91.1171 11.9932 91.06 
      11.9932C91.0457 11.9932 91.0314 11.9932 91.0243 11.9861C91.0243 11.9789 91.0243 11.9646 91.0243 
      11.9504C91.0742 11.7575 91.2884 11.6146 91.4597 11.5075L91.5025 11.4789C91.9308 11.1861 92.3376 
      10.9003 92.7302 10.5932C93.4583 10.0289 94.2577 9.32172 94.6574 8.36455C94.8859 7.82882 94.9787 
      7.20738 94.9358 6.57165V6.53594C94.9215 6.27879 94.9001 6.00735 94.8002 5.77163C94.7145 5.57163 
      94.5575 5.47877 94.4005 5.38591C94.3648 5.36448 94.3362 5.35019 94.3077 5.32876C94.3862 5.1859 
      94.6003 4.80732 94.843 4.80732C94.9358 4.80732 95.0286 4.86447 95.1143 4.97876C95.3998 5.37877 
      95.5354 5.8502 95.6496 6.27164C95.671 6.34307 95.6924 6.42165 95.7139 6.50736C95.9423 7.31453 
      96.3206 8.6717 96.1992 9.63601ZM101.167 8.10026C100.767 8.32884 100.418 8.43598 100.082 8.42884H100.054C99.8324 
      8.4217 99.6111 8.39312 99.397 8.36455C99.2114 8.34312 99.0258 8.31455 98.8403 8.30741C98.3763 
      8.28598 98.0123 8.36455 97.7267 8.53598C97.5269 8.65742 97.3841 8.82171 97.2485 8.99314C97.1557 
      9.11457 97.0486 9.236 96.9273 9.33601C96.8916 9.36458 96.8488 9.40029 96.7988 9.40029H96.7917C96.7417 
      9.40029 96.7346 9.36458 96.7417 9.286V9.236C96.7346 9.05743 96.706 8.88599 96.6846 8.71456L96.6703 
      8.6217C96.6275 8.32884 96.5847 8.01454 96.5133 7.71454C96.5062 7.66454 96.4847 7.60025 96.4633 
      7.52882C96.3777 7.26453 96.2777 6.9288 96.4847 6.75737C96.6132 6.65022 96.8274 6.63594 96.9701 
      6.62165C96.9701 6.62165 97.1272 6.60737 97.3056 6.60737H97.3698C97.5626 6.60737 97.7624 6.62165 
      97.9623 6.65022C98.3335 6.70023 98.7189 6.79308 99.19 6.95023C99.4398 7.03595 99.6897 7.12881 99.9395
       7.22167C100.503 7.43596 101.096 7.65025 101.703 7.75739C101.503 7.90025 101.331 8.01454 101.167 8.10026Z"
      fill="white"
    />
    <path
      d="M107.463 12.6359C107.299 11.5573 107.034 10.493 106.67 9.46442C106.321 8.47868 105.885 7.52152 105.357 
      6.61435C104.95 5.91434 104.493 5.24289 103.979 4.61431C103.323 3.80714 102.58 3.07855 101.76 2.44282C100.96 
      1.82138 100.089 1.29994 99.1614 0.892786C98.2406 0.499919 97.2556 0.221341 96.2634 0.0927662C95.2498 
      -0.0429514 94.2148 -0.0286652 93.2012 0.128482C92.2091 0.278485 91.2383 0.578493 90.3247 
      0.992788C89.4039 1.41423 88.5402 1.9571 87.7479 2.59283C86.9413 3.24284 86.2132 3.98572 85.5708 
      4.80002C84.9356 5.60004 84.3931 6.45721 83.9291 7.36437C83.4437 8.30725 83.0512 9.29299 82.7585 
      10.3073C82.4444 11.3645 82.2303 12.4574 82.109 13.5502C81.9876 14.6931 81.9662 15.8503 82.0519 
      16.9932C82.1375 18.1075 82.316 19.2075 82.5872 20.2861C82.8584 21.3147 83.2153 22.329 83.6722 
      23.2933C84.1076 24.2219 84.6286 25.1077 85.2354 25.9363C85.9135 26.8648 87.6408 28.5077 87.648 
      28.5077C89.6894 30.1792 92.1591 31.1721 94.8215 31.1721H94.8287C95.8423 31.1721 96.8558 31.0292 
      97.8337 30.7507C98.8045 30.4721 99.7253 30.0578 100.582 29.5363C101.46 29.0006 102.266 28.3649 
      102.994 27.6434C103.715 26.922 104.358 26.122 104.922 25.2577C105.486 24.3934 105.971 23.4719 
      106.364 22.5147C106.77 21.5147 107.084 20.479 107.306 19.4218C107.534 18.3146 107.663 17.1932 
      107.691 16.0646C107.72 14.9003 107.648 13.7645 107.463 12.6359ZM84.7143 20.0504H84.3859L84.6786 
      18.9361C84.7072 18.8504 84.7214 18.8575 84.7286 18.8932C84.7286 19.2932 84.7214 19.679 84.7143 
      20.0504ZM84.8785 17.0146L84.2218 16.7075C84.1861 16.6932 84.1861 16.6789 84.2289 16.6789H85.6422C85.685 
      16.6789 85.685 16.6932 85.6494 16.7075L85.0141 17.0075C84.9784 17.0289 84.9142 17.0289 84.8785 
      17.0146ZM84.0576 16.3932L84.7928 15.8717C84.7857 16.0432 84.7857 16.2146 84.7785 16.3932H84.0576ZM84.3003 
      14.6288H85.5566C85.5923 14.6288 85.5923 14.6431 85.5637 14.6574L84.9998 14.9217C84.9641 14.936 84.907 
      14.936 84.8785 14.9288L84.2932 14.6574C84.2646 14.636 84.2717 14.6288 84.3003 14.6288ZM84.1433 
      14.3645L84.8214 13.9217C84.8142 14.0574 84.8142 14.2145 84.8071 14.3645H84.1433ZM85.064 13.9288L85.7279 
      14.3717H85.064C85.0712 14.2217 85.064 14.0717 85.064 13.9288ZM85.0926 15.8717L85.8349 16.3932H85.0997C85.0926 
      16.2217 85.0926 16.0432 85.0926 15.8717ZM85.1497 20.0504C85.1426 19.6861 85.1354 19.2932 85.1283 
      18.8932C85.1354 18.8647 85.1497 18.8432 85.1783 18.9218L85.478 20.0504H85.1497ZM94.8287 30.0292C92.8729 
      30.0292 91.0242 29.4363 89.3967 28.3792C90.6602 28.372 91.9164 28.5435 93.1584 28.7292C94.4075 28.9149 
      95.6567 29.1292 96.9129 29.2578C97.3983 29.3078 97.8837 29.3435 98.3691 29.3649C97.2484 29.8006 96.0635 
      30.0364 94.8287 30.0292ZM100.575 28.1863C99.3327 28.3149 98.0836 28.2506 96.8416 28.1149C95.6067 27.9792 
      94.3719 27.7649 93.1441 27.5863C91.8736 27.4006 90.5959 27.2434 89.3111 27.2506C88.8757 27.2577 88.4403 
      27.272 88.0049 27.3149C87.598 26.9506 87.2126 26.5577 86.8414 26.1363C88.1262 25.9505 89.4396 25.9577 
      90.7387 26.0505C92.0378 26.1434 93.3297 26.3434 94.6217 26.5291C95.2712 26.6291 95.9136 26.722 96.5632 
      26.8148C96.8915 26.8577 98.1692 26.9577 98.4833 26.9577C99.7752 26.9577 101.081 26.8648 102.359 
      26.6577C101.81 27.2363 101.21 27.7434 100.575 28.1863ZM103.53 25.2862C102.288 25.5862 101.01 25.7434 
      99.7395 25.8077C99.0971 25.8434 98.4619 25.8577 97.8195 25.822C97.177 25.7934 96.5346 25.6862 95.8994 
      25.5934C94.6145 25.4077 93.3297 25.2005 92.0378 25.0505C90.7458 24.9077 89.4539 24.8219 88.1619 
      24.8862C87.4552 24.9219 86.7486 25.0005 86.0562 25.1362C85.8207 24.8077 85.5923 24.4576 85.3853 
      24.1005C86.5273 23.8648 87.6908 23.7148 88.8543 23.6791C90.032 23.6433 91.2098 23.7219 92.3804 23.8719C93.5153 
      24.0219 94.636 24.2362 95.7637 24.3862C96.7987 24.5291 97.8409 24.6219 98.883 24.6219H98.8901C99.9751 
      24.6219 101.06 24.5291 102.124 24.3576C102.909 24.2291 103.68 24.0719 104.443 23.8719C104.165 24.3648 
      103.858 24.8362 103.53 25.2862ZM86.2846 21.2861C85.7421 20.929 85.6708 20.3433 85.7065 20.229L87.3839 
      16.286C87.4196 16.2146 87.4338 16.236 87.4267 16.286L86.2846 21.2861ZM87.4053 17.5575C87.3767 19.3289
      87.3482 20.9718 87.341 21.7647C87.0341 21.679 86.7343 21.5861 86.4774 21.4004L87.3696 17.486C87.3839 
      17.4432 87.3981 17.5003 87.4053 17.5575ZM86.9627 14.0931H88.219C88.2547 14.0931 88.2547 14.1074 88.2261 
      14.1217L87.6622 14.386C87.6337 14.4003 87.5766 14.4003 87.548 14.386L86.9627 14.1145C86.927 14.1074 
      86.927 14.0931 86.9627 14.0931ZM86.8057 13.8431L87.4695 13.4074C87.4624 13.5502 87.4624 13.6931 87.4624 
      13.8431H86.8057ZM87.7336 13.4074L88.3903 13.8431H87.7408C87.7408 13.6931 87.7336 13.5502 87.7336 
      13.4074ZM89.0755 19.2075C89.047 20.0575 89.0256 20.9004 89.0113 21.5576C89.0042 21.5647 88.997 21.5647 
      88.9899 21.5647L88.4331 19.1218L88.7329 18.4075L89.0755 19.2075ZM88.8471 18.1432L89.0684 17.6146C89.0898 
      17.5789 89.1041 17.5932 89.1112 17.6218C89.1041 17.9646 89.0898 18.3289 89.0827 18.7004L88.8471 
      18.1432ZM88.2904 19.4647L88.8257 21.6861C88.6401 21.7647 88.3974 21.8076 88.1904 21.8362C88.1191 
      21.8433 88.0477 21.8504 87.9692 21.8576C87.9263 21.8647 87.8764 21.8647 87.8335 21.8647C87.8335 
      21.5647 87.8264 21.1147 87.8193 20.5576L88.2904 19.4647ZM87.8193 20.029C87.8121 19.2932 87.805 
      18.4361 87.7907 17.5432C87.7979 17.4718 87.8121 17.4218 87.8478 17.5289L88.2119 19.1075L87.8193 
      20.029ZM88.3546 18.7789L87.7836 16.286C87.7764 16.236 87.7907 16.2146 87.8264 16.286L88.6187 
      18.1503L88.3546 18.7789ZM89.4467 19.5575V19.1932C89.4324 18.5432 89.4182 18.2432 89.4039 17.6432C89.411 
      17.6003 89.4182 17.5718 89.4539 17.6218L90.446 20.1218C90.2818 20.4504 90.2533 20.5647 89.9963 
      20.829L89.4467 19.5575ZM89.8393 20.9861C89.7394 21.1004 89.6037 21.2076 89.4895 21.3004C89.4824 
      20.9576 89.4681 20.5218 89.461 20.1218L89.8393 20.9861ZM90.86 20.029L92.0092 17.5718C91.9878 18.2789 
      91.9592 19.2861 91.945 20.029H91.4739C91.3026 20.029 91.0242 20.0433 90.86 20.029ZM99.04 
      16.9003H100.689C100.732 16.9003 100.739 16.9146 100.71 16.9289C100.703 16.936 99.9537 17.286 99.9537 
      17.286C99.9109 17.3075 99.8466 17.3075 99.8109 17.286L99.04 16.9289C98.9901 16.9075 98.9972 16.9003 
      99.04 16.9003ZM98.8545 16.5646L99.7181 15.9503C99.7181 16.136 99.711 16.5432 99.711 16.5717L98.8545 
      16.5646ZM99.1685 14.1288C99.1685 14.1288 99.1899 14.1217 99.1971 14.1217H100.575C100.589 14.1217 
      100.596 14.1217 100.596 14.1288H100.603H100.596C100.603 14.136 100.603 14.1431 100.575 14.1503L99.9537 
      14.4431C99.918 14.4574 99.8609 14.4574 99.8252 14.4431L99.1828 14.1503C99.1685 14.1431 99.1685 
      14.136 99.1685 14.1288ZM99.0258 13.836L99.761 13.3074C99.7538 13.4788 99.7538 13.6574 99.7538 
      13.836H99.0258ZM100.018 13.3145L100.76 13.836H100.032C100.025 13.6574 100.025 13.486 100.018 
      13.3145ZM100.054 15.9503L100.91 16.5717H100.061C100.061 16.3646 100.054 16.1503 100.054 15.9503ZM102.552 
      15.2931H103.965C104.008 15.2931 104.008 15.3003 103.972 15.3217L103.337 15.6289C103.301 15.6431 
      103.244 15.6431 103.209 15.6289L102.552 15.3217C102.509 15.3003 102.509 15.2931 102.552 15.2931ZM102.373 
      15.0003L103.116 14.4788C103.109 14.6574 103.109 14.8288 103.109 15.0074L102.373 15.0003ZM103.408 
      14.4788L104.158 15.0074H103.416C103.416 14.8288 103.408 14.6503 103.408 14.4788ZM103.08 17.5146C103.052 
      19.2289 103.023 21.0647 103.002 22.3576C102.588 22.5005 102.224 22.5362 101.909 22.5147L102.616 
      19.379L103.03 17.5289C103.052 17.486 103.073 17.4575 103.08 17.5146ZM102.98 16.786L101.745 22.2219L101.21 
      20.7576L101.217 20.7504L102.902 16.7289C102.952 16.6432 103.002 16.5932 102.98 16.786ZM101.617 
      22.4647C101.231 22.379 100.932 22.1933 100.696 21.9862L101.096 21.029L101.617 22.4647ZM100.546 
      21.8362C100.311 21.5861 100.175 21.3361 100.118 21.229C100.111 20.4075 100.089 19.1718 100.075 
      17.8575L101.003 20.729L100.546 21.8362ZM98.5261 21.1719H96.4989L99.5183 17.7718L98.5261 21.1719ZM99.6896 
      17.8575C99.6753 18.6218 99.6468 21.079 99.6468 21.1719H98.7474L99.6896 17.8575ZM103.444 17.5146C103.437 
      17.4575 103.473 17.4789 103.487 17.5289L104.322 21.179C104.136 21.6076 103.765 21.9219 103.494 
      22.1076C103.494 21.4433 103.444 17.5289 103.444 17.5146ZM104.543 21.1576L103.515 16.8217C103.487 
      16.5575 103.551 16.6503 103.601 16.7503L105.486 21.1576H104.543ZM106.235 19.0504C106.092 19.7432 
      105.914 20.4218 105.693 21.0861L103.437 15.7146L104.429 15.2717C104.536 15.2217 104.536 15.0717 
      104.429 15.0217C104.429 15.0217 103.458 14.3288 103.401 14.286L103.38 12.6359C103.501 12.6216 103.615 
      12.5645 103.73 12.5431C103.865 12.5288 103.987 12.5502 104.115 12.5716C104.229 12.5931 104.351 12.6002 
      104.465 12.5716C104.572 12.5431 104.672 12.4788 104.757 12.4074C104.501 12.4002 104.294 12.2359 104.065 
      12.1359C103.951 12.0788 103.83 12.0502 103.701 12.0359C103.58 12.0216 103.43 12.0145 103.316 
      12.0502C103.301 11.9573 103.287 11.8573 103.273 11.7502H103.223C103.144 12.2002 103.144 12.6931 
      103.144 13.1502C103.144 13.4717 103.137 13.7931 103.137 14.1145C103.137 14.1717 103.137 14.2288 
      103.13 14.286L102.109 15.0146C102.009 15.0646 102.009 15.2146 102.109 15.2646V15.2717L103.073 
      15.7146L101.124 20.4504L100.125 17.4003L101.239 16.8789C101.353 16.8217 101.36 16.6503 101.246 
      16.586C101.246 16.586 100.196 15.8503 100.061 15.7503C100.054 15.3646 100.046 14.9503 100.046 14.586L101.06 
      14.1074C101.16 14.0574 101.167 13.9074 101.067 13.8574C101.067 13.8574 100.161 13.2074 100.025 
      13.1145C100.018 12.3645 100.018 11.6216 100.004 10.8787C100.061 10.8787 100.118 10.8573 100.175 
      10.843C100.239 10.8216 100.303 10.8002 100.368 10.793C100.496 10.7716 100.625 10.793 100.753 
      10.8145C100.874 10.8359 101.003 10.8502 101.124 10.8145C101.239 10.7859 101.331 10.7216 101.417 
      10.6502C101.16 10.643 100.946 10.4787 100.725 10.3787C100.61 10.3216 100.482 10.293 100.361 
      10.2787C100.239 10.2644 100.089 10.2573 99.9751 10.293C99.968 10.2002 99.9537 10.1002 99.9323 
      9.98587H99.8823C99.8109 10.4002 99.8038 10.8145 99.8038 11.2359C99.7967 11.6145 99.7967 11.9931 
      99.7895 12.3716C99.7824 12.6216 99.7824 12.8645 99.7752 13.1145L98.7402 13.8502C98.6403 13.9002 
      98.6403 14.0502 98.7402 14.1074L99.7538 14.586C99.7538 14.9503 99.7467 15.3717 99.7395 15.7503L98.5547 
      16.5789C98.4333 16.636 98.4333 16.8146 98.5547 16.8717L99.6396 17.3932L96.2348 21.1647C96.1706 21.1647 
      95.7495 21.1719 95.6852 21.2504C95.5924 21.3219 95.5924 21.429 95.6281 21.5219C95.6781 21.6433 95.778 
      21.729 95.8922 21.7933C95.9493 21.829 96.0136 21.8576 96.0778 21.879C96.0849 21.8862 96.0921 21.8862 
      96.0992 21.8933C96.0992 21.8933 96.4062 22.9076 97.7124 23.3862H97.7052C97.7052 23.3862 97.7409 23.4005 
      97.7338 23.4219C97.7267 23.4291 97.7267 23.4362 97.7267 23.4362C97.7195 23.4433 97.6981 23.4433 97.691 
      23.4433C96.4347 23.3648 95.207 23.1576 93.9721 22.9648C93.501 22.8862 93.0299 22.8148 92.5588 
      22.7505C92.2376 22.7076 91.9164 22.6719 91.6024 22.6147C91.5738 22.6005 91.5595 22.5933 91.5524 
      22.579V22.5719V22.5647C91.5524 22.5576 91.5595 22.5505 91.5667 22.5433C91.5738 22.5362 91.5809 
      22.5362 91.5809 22.5362C93.3226 22.0219 93.6866 20.7504 93.6866 20.7504C93.708 20.7433 93.7294 
      20.729 93.7509 20.7147C93.8008 20.6933 93.8508 20.6718 93.8865 20.6504C94.0007 20.5861 94.1078 
      20.5004 94.1506 20.379C94.1863 20.2861 94.1863 20.179 94.1006 20.1075C94.0364 20.0218 93.5439 
      20.0218 93.5439 20.0218H93.5367H93.4511C93.4439 19.7504 93.4439 19.0504 93.4297 18.5932C93.4796 
      18.6146 93.5439 18.6004 93.5938 18.5861C93.6581 18.5646 93.7223 18.5432 93.7937 18.5289C93.9293
       18.4932 94.0578 18.5075 94.1934 18.5289C94.3219 18.5504 94.4504 18.5718 94.586 18.5361C94.7002 
       18.5075 94.8073 18.4432 94.9001 18.3718C94.636 18.3646 94.4218 18.1932 94.1934 18.0932C94.0721 
       18.0432 93.9436 18.0075 93.8151 18.0003C93.6866 17.9932 93.5296 17.9789 93.4225 18.0361C93.4154 
       17.9218 93.4011 17.8361 93.394 17.7646H93.344C93.2798 18.1575 93.2726 19.5075 93.2726 20.029H92.4018C92.3804 
       19.0789 92.3376 17.8003 92.3161 16.986L93.0728 15.3646C93.1798 15.4074 93.294 15.4217 93.4011 
       15.4574C93.5224 15.5003 93.6152 15.5717 93.7152 15.6503C93.808 15.7289 93.9008 15.8003 94.0078 
       15.836C94.1292 15.8717 94.2648 15.8717 94.3861 15.8503C94.172 15.7217 94.0721 15.486 93.9364 
       15.286C93.8651 15.186 93.7723 15.0931 93.6723 15.0217C93.5653 14.9431 93.4439 14.8646 93.3154 
       14.836C93.344 14.7503 93.3868 14.6288 93.3868 14.5717C93.3868 14.5645 93.3868 14.5503 93.3654 
       14.536C93.3583 14.536 93.3511 14.5288 93.3511 14.5288C93.3154 14.536 93.3083 14.5503 93.2869 
       14.5788C93.2155 14.6645 93.1227 14.8217 93.1084 14.8503L92.3019 16.586C92.2947 16.3146 92.2876 
       16.036 92.2805 15.7646C92.2805 15.4217 92.2662 15.0788 92.2091 14.736H92.1591C92.0877 15.1503 
       92.0877 15.5646 92.0806 15.9789C92.0735 16.3503 92.0663 16.7289 92.052 17.1075L90.6602 19.9861L89.4182 
       16.9289C89.411 16.5503 89.3967 16.1717 89.3967 15.7931C89.3896 15.3646 89.3896 14.936 89.3182 
       14.5074H89.2683C89.1969 14.9288 89.1969 15.3503 89.1826 15.7717C89.1826 16.1574 89.1683 16.5432 
       89.1612 16.9217L88.7615 17.8646L87.7907 15.5931C87.7836 15.2146 87.7836 14.8431 87.7764 14.486L88.6615 
       14.0717C88.7472 14.0288 88.7543 13.8931 88.6615 13.8502C88.6615 13.8502 87.9121 13.3074 87.755 
       13.1931C87.7479 12.4145 87.7408 11.6288 87.7265 10.8502C87.7836 10.8502 87.8407 10.8287 87.8907 
       10.8145C87.9549 10.793 88.0191 10.7716 88.0834 10.7645C88.219 10.743 88.3403 10.7645 88.4688 
       10.7859C88.5902 10.8073 88.7186 10.8216 88.84 10.7859C88.9471 10.7573 89.047 10.693 89.1326 
       10.6216C88.8828 10.6145 88.6758 10.4573 88.4545 10.3502C88.3403 10.293 88.219 10.2644 88.0905 
       10.2502C87.9692 10.2359 87.8121 10.2216 87.6837 10.2644C87.6765 10.1644 87.6622 10.0573 87.648 
       9.95729H87.598C87.5123 10.4573 87.5195 10.9573 87.5123 11.4645C87.5052 11.9502 87.4981 12.4359 
       87.4909 12.9288C87.4909 13.0217 87.4909 13.1074 87.4838 13.2002L86.5773 13.8502C86.4845 13.8931 
       86.4845 14.0288 86.5773 14.0788L87.4624 14.5003C87.4552 14.9074 87.4481 15.5646 87.4481 15.6074L85.6351 
       19.8504L85.371 18.8789H85.5994H85.7207C85.7707 18.8789 85.8278 18.8575 85.8706 18.8432C85.9063 18.8361 
       85.9848 18.8075 85.9848 18.7575C85.9848 18.7146 85.9135 18.6861 85.8849 18.6718C85.8349 18.6575 85.7778 
       18.6361 85.7207 18.6361H85.3067L85.1283 17.9646C85.1212 17.6932 85.1212 17.4075 85.114 17.136L86.1133 
       16.6717C86.2132 16.6217 86.2204 16.4717 86.1205 16.4217C86.1205 16.4217 85.164 15.7431 85.0926 
       15.7003C85.0926 15.5003 85.0926 15.2288 85.0855 15.0431L85.9848 14.6217C86.0776 14.5788 86.0776 
       14.4431 85.992 14.4003C85.992 14.4003 85.1925 13.8288 85.0712 13.736C85.064 13.0788 85.0569 
       12.6431 85.0569 12.6002C85.1568 12.6074 85.2782 12.5502 85.371 12.5288C85.4852 12.5074 85.5923 
       12.5216 85.7065 12.5359C85.8135 12.5574 85.9277 12.5716 86.0348 12.5502C86.1347 12.5216 86.2275 
       12.4716 86.3132 12.4002C86.0848 12.3931 85.8992 12.2502 85.6993 12.1573C85.5994 12.1145 85.4852 
       12.0788 85.371 12.0716C85.2568 12.0573 85.1283 12.0502 85.0212 12.0931C85.0069 12.0073 84.9998 
       11.9145 84.9784 11.8216H84.9284C84.8642 12.1788 84.857 12.5359 84.8499 12.9002C84.8428 13.2145 
       84.8428 13.4288 84.8285 13.736L83.9077 14.3931C83.8149 14.4431 83.8149 14.5717 83.9077 14.6217L84.8071 
       15.0431C84.8071 15.2288 84.7999 15.5003 84.7999 15.7003L83.7864 16.4146C83.6864 16.4646 83.6864 16.6146 
       83.7864 16.6646L84.7714 17.136C84.7714 17.4075 84.7571 17.6861 84.7571 17.9503L84.5787 
       18.6289H84.1647C84.1076 18.6289 84.0576 18.6432 84.0076 18.6575C83.972 18.6718 83.8934 18.7004 
       83.8934 18.7432C83.8934 18.7932 83.9648 18.8147 84.0005 18.8289C84.0505 18.8504 84.1076 18.8718 
       84.1647 18.8718H84.2717H84.2789H84.5073L84.2004 20.0504H83.7007C83.3938 18.9075 83.2153 17.7432 
       83.1582 16.5717C83.094 15.3931 83.144 14.2145 83.3081 13.0502C83.4652 11.9716 83.7221 10.9002 
       84.0862 9.87158C84.4288 8.89298 84.8642 7.94296 85.3995 7.05008C85.6779 6.57864 85.9848 6.12148 
       86.3132 5.68576C87.6622 6.14291 89.411 5.72862 89.411 5.72862C90.9814 5.45004 91.9664 5.4929 
       92.5374 5.57861L92.8443 5.63576C92.88 5.4429 92.9015 5.30004 92.9586 5.12146C91.3168 4.60002 
       89.461 4.84288 89.461 4.84288C88.3332 4.95003 87.6551 4.71431 87.2982 4.52145C87.2982 4.52145 
       88.0049 3.77143 88.5902 3.35713C89.097 3.72857 89.7322 3.90715 90.3389 4.01429C90.7387 4.08572 
       91.1384 4.11429 91.531 4.20715C91.9307 4.30001 92.3304 4.4143 92.7159 4.55002C92.8515 4.60002 
       93.0299 4.68574 93.1156 4.72859C93.187 4.5643 93.2869 4.42144 93.394 4.27858L93.137 4.11429C92.6302 
       3.82143 92.152 3.67143 91.6095 3.50714C91.2526 3.39999 90.91 3.27142 90.5745 3.09998C90.1177 2.86426 
       89.8322 2.55711 89.8107 2.53568C89.8107 2.53568 90.5959 2.0571 91.3311 1.78566C91.6737 2.27139 92.3019 
       2.54283 92.7373 2.91426C93.344 3.4357 93.6652 3.92143 93.7009 3.97858C93.8437 3.87143 93.9793 3.79286 
       94.1649 3.72143C93.9222 3.1857 93.5795 2.5714 93.1584 2.17853C92.9657 1.99996 92.6802 1.69281 92.6017 
       1.38565C92.6017 1.38565 93.5796 1.14279 94.3362 1.12851L94.6145 3.61428C94.7216 3.59285 94.8786 3.59999
        94.8786 3.59999C94.8786 3.59999 94.9786 3.59999 95.1427 3.61428L95.4283 1.13565C95.4283 1.13565 96.0921 
    1.16422 96.6346 1.27137C96.9344 1.33565 97.1628 1.3928 97.1628 1.3928C97.0771 1.69281 96.7987 2.0071 
    96.6132 2.18567C96.1849 2.57854 95.8565 3.1857 95.6067 3.72857C95.7495 3.79286 95.9136 3.85714 96.0635 
    3.97858C96.3491 3.55714 96.6988 3.19284 97.0272 2.91426C97.4483 2.54283 98.0836 2.26425 98.4262 
    1.79281C98.5118 1.82852 98.8973 1.97138 99.504 2.28568L99.9608 2.54997C99.9465 2.56425 99.4398 2.97141 
    99.1899 3.11427C98.8616 3.29999 98.5118 3.40713 98.1478 3.52142C97.6125 3.68571 97.1271 3.82857 96.6203 
    4.12144L96.3633 4.27858C96.4633 4.40716 96.5632 4.55716 96.6417 4.73574C96.7345 4.69288 96.9129 4.60716 
    97.0414 4.5643C97.4269 4.42859 97.8266 4.3143 98.2263 4.22144C98.6189 4.12858 99.0186 4.10001 99.4183 
    4.02858C100.011 3.92857 100.66 3.73571 101.16 3.37856C101.802 3.87857 102.445 4.55002 102.445 
    4.55002C102.088 4.74288 101.403 4.97146 100.289 4.86431C100.289 4.86431 98.4333 4.60716 96.7916 
    5.12146C96.8416 5.27861 96.863 5.38575 96.8987 5.62147L97.227 5.57147C97.8123 5.4929 98.7688 5.45718 
    100.339 5.7429C100.339 5.7429 102.081 6.1572 103.43 5.71433C103.565 5.89291 103.694 6.06434 103.815
    6.25006C104.393 7.09294 104.879 8.0001 105.271 8.93584C105.693 9.92872 106.007 10.9573 106.221 12.0145C106.449 
    13.1217 106.563 14.2503 106.578 15.386C106.585 16.6146 106.471 17.8432 106.235 19.0504Z"
      fill="white"
    />
    <path d="M70 1V31" stroke="white" strokeLinejoin="round" />
    <g clipPath="url(#clip0)">
      <path
        d="M57.4759 24.5562L46.2952 3.13483C45.4217 1.2191 43.3253 0 41.0542 0C38.7831 0 36.8614 1.2191 35.8133 
        3.13483L27.9518 18.2865C27.0783 16.7191 25.6807 15.5 23.9337 14.8034C25.8554 13.4101 27.0783 11.3202 
        27.0783 8.70787C27.0783 5.74719 25.506 3.30899 23.0602 2.08989C20.9639 1.04494 18.5181 0.348315 15.8976 
        0.348315H4.36747C1.92169 0.522472 0 2.4382 0 4.70225V26.4719C0 28.9101 1.92169 30.8258 4.36747 
        30.8258H8.56024H16.9458C19.3916 30.8258 21.6626 30.3034 23.759 29.4326L24.6325 29.0843H24.8072C25.506 
        30.3034 26.9036 31.1742 28.4759 31.1742C30.2229 31.1742 31.7952 30.1292 32.494 28.5618C32.8434 27.691 
        33.5422 27.1685 34.5904 27.1685H47.6928C48.5663 27.1685 49.4398 27.691 49.7892 28.5618C50.4879 30.1292 
        52.0602 31.1742 53.8072 31.1742C56.253 31.1742 58.1747 29.2584 58.1747 26.8202C58 25.9494 57.8253 25.2528 
        57.4759 24.5562Z"
        fill="url(#paint0_linear)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear"
        x1="28.9851"
        y1="28.9592"
        x2="28.9851"
        y2="9.27875"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00B8F1" />
        <stop offset="0.0317256" stopColor="#10BBEB" />
        <stop offset="0.0940926" stopColor="#3BC3DD" />
        <stop offset="0.1803" stopColor="#80CFC5" />
        <stop offset="0.2124" stopColor="#9BD4BC" />
        <stop offset="0.3042" stopColor="#BADEB1" />
        <stop offset="0.4164" stopColor="#E5EBA2" />
        <stop offset="0.5077" stopColor="#E8E88F" />
        <stop offset="0.6929" stopColor="#F0E05E" />
        <stop offset="0.9534" stopColor="#FDD40F" />
        <stop offset="1" stopColor="#FFD200" />
      </linearGradient>
      <clipPath id="clip0">
        <rect width="58" height="31" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
