export const regionMap = {
  I: [20058300, 20145600, 20050900, 20123200, 20046900],
  II: [20083800, 20084300, 20169400, 20049900],
  III: [20019200, 20050600, 20084000, 20129800, 20144800],
  IV: [20080800, 20087600, 20152700, 20062200, 20047800],
  V: [20061200, 20091100, 20307500, 20117000, 20086500],
  VI: [20093900, 20090300, 20123000, 20074600, 20125300],
  VII: [20121600, 20108500, 20116900, 20107900, 20147500],
  VIII: [20105100, 20103800, 20072400, 20131800, 20124700],
};
