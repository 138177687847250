import React, { createContext, Dispatch, useContext, useState } from 'react';

export interface TabBarValue {
  tabIndex: number;
  params?: any;
}
export const defaultStateValue: TabBarValue = {
  tabIndex: 0,
  params: {},
};

export const TabBarValueContext = createContext(defaultStateValue);
export const TabBarSetValueContext = createContext<
  Dispatch<React.SetStateAction<TabBarValue>> | undefined
>(undefined);

export const useTabBarSetValue = () => {
  const setTabValue = useContext(TabBarSetValueContext);
  if (!setTabValue) {
    throw new Error(
      'useTabBarSetValue was called outside of the TabBarSetValueContext provider',
    );
  }
  return (tabIndex: number, params?: any): void =>
    setTabValue({ tabIndex, params: params || {} });
};

export const useTabBarValue = () => useContext(TabBarValueContext);

export const TabBarContext: React.FC<{ defaultTab: number }> = ({
  children,
  defaultTab = 0,
}) => {
  const [tabBarValue, setTabBarValue] = useState(() => ({
    ...defaultStateValue,
    tabIndex: defaultTab,
  }));
  return (
    <TabBarValueContext.Provider value={tabBarValue}>
      <TabBarSetValueContext.Provider value={setTabBarValue}>
        {children}
      </TabBarSetValueContext.Provider>
    </TabBarValueContext.Provider>
  );
};
