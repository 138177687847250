import { MenuItem } from '../../../../commons/const/ui/menu';
import { routes } from './routes';

export const leerParaCrecer: MenuItem = {
  key: 'leerparacrecer',
  basePath: '/leerparacrecer',
  enabled: true,
  icon: 'List',
  text: 'Leer para crecer',
  children: routes,
};
