import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { EstadoCivil } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'estado_civil';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEstadoCivil',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEstadoCivil',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EstadoCivil
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EstadoCivil,
      hoc: { ...defaultOptions.hoc, ...businessOptions.EstadoCivil.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EstadoCivil>(DAO_NAME, optionsToUse);

// hooks
export const useEstadoCivilFetchById = fetchById;
export const useEstadoCivilLibbyCall = libbyCall;
export const useEstadoCivilDAO = libbyDAO;
export const useEstadoCivilLibbyFetch = libbyFetch;

// components/hoc
export const EstadoCivilAutocomplete = entityAutocomplete;
export const EstadoCivilCall = renderPropCall;
export const EstadoCivilById = renderPropFetchById;
export const EstadoCivilSelect = simpleSelect;
export const EstadoCivilAccordionSelector = accordionSelector;
export const EstadoCivilSelectedChips = selectedChips;

// context
// ByIdContext
export const useEstadoCivilByIdContext = useByIdContext;
export const EstadoCivilByIdProvider = ByIdProvider;
export const EstadoCivilByIdContext = ByIdContext;
// GeneralContext
export const useEstadoCivilContext = useGeneralContext;
export const EstadoCivilProvider = GeneralProvider;
export const EstadoCivilContext = GeneralContext;
