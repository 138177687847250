import { Box } from '@material-ui/core';
import React, { useMemo, useCallback } from 'react';
import { useRouteMatch } from 'react-router';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import confirmDialog from 'src/commons/services/confirmDialog';
import {
  useEspaciosSimpDiscipPlanDAO,
  useSimpDiscipPlanDAO,
} from 'src/app/business';
import { EspaciosSimpDiscipPlan } from '../../../../../../../app/models';
import { OptionsModal } from '../../../../../../../commons/components';
import { makeActionButtons } from './makeActionButtons';

const useActionButtons = makeActionButtons(
  'idEspaciosSimpDiscipPlan',
  (row: EspaciosSimpDiscipPlan) => row.simpDiscipPlan.planificacionEstados,
  'simpDiscipPlan',
);

export const useDisciplinarTableColumns = (reFetch: () => void) => {
  const { url } = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();

  const espaciosSimpDiscipPlan = useEspaciosSimpDiscipPlanDAO();
  const simpDiscipPlanDAO = useSimpDiscipPlanDAO();

  const handleDeletePlanificacionSimp = useCallback(
    async (planificacion) => {
      try {
        const confirm = await confirmDialog.show({
          title: 'Confirmación',
          content: `¿Esta seguro que desea eliminar la planificacion?`,
          confirmText: 'Eliminar',
          cancelText: 'No',
        });
        if (confirm) {
          await espaciosSimpDiscipPlan.remove({
            idEspaciosSimpDiscipPlan: planificacion.idEspaciosSimpDiscipPlan,
          });

          await simpDiscipPlanDAO.remove({
            idSimpDiscipPlan: planificacion.simpDiscipPlan.idSimpDiscipPlan,
          });
          enqueueSnackbar('Se ha eliminado con éxito', { variant: 'success' });

          reFetch();
        }
      } catch (e) {
        enqueueSnackbar('Ha ocurrido un error al intentar eliminar', {
          variant: 'error',
        });
      }
    },
    [espaciosSimpDiscipPlan, simpDiscipPlanDAO, reFetch, enqueueSnackbar],
  );

  const actionButtons = useActionButtons({
    urls: {
      edit: `${url}/planificacion/disciplinar`,
      feedback: `${url}/planificacionfeedback/disciplinar`,
      preview: `${url}/planificacionpreview/disciplinar`,
    },
    helper: {
      delete: handleDeletePlanificacionSimp,
    },
  });
  return useMemo(
    () => [
      {
        id: 'espacioCurricular.Area',
        label: 'Áreas/orientación',
        width: '15%',
        orderById: 'espacioCurricular.materia.area.descripcion',
        render: (value: any, row: EspaciosSimpDiscipPlan) =>
          row.espacioCurricular.materia.area.descripcion,
      },
      {
        id: 'espacioCurricular.PlanEstudio',
        label: 'Proyecto',
        width: '15%',
        orderById: 'espacioCurricular.materia.descripcion',
        render: (value: any, row: EspaciosSimpDiscipPlan) => (
          <Box whiteSpace="nowrap">
            {row.espacioCurricular.materia.descripcion}
          </Box>
        ),
      },
      {
        id: 'tipo',
        label: 'Tipo',
        width: '13%',
        style: {
          whiteSpace: 'nowrap',
        },
        hideSortIcon: true,
        render: () => 'Disciplinar',
      },
      {
        id: 'simpDiscipPlan.ultimaActualizacion',
        label: 'Ultima modificación',
        width: '10%',
        render: (value: any, row: EspaciosSimpDiscipPlan) => (
          <Box whiteSpace="nowrap">
            {row.simpDiscipPlan.ultimaActualizacion
              ? moment(row.simpDiscipPlan.ultimaActualizacion).format(
                  'DD/MM/YYYY - HH:mm',
                )
              : '-'}
          </Box>
        ),
      },
      {
        id: 'estado',
        label: 'Estado',
        width: '10%',
        style: { textAlign: 'center' },
        orderById: 'simpDiscipPlan.planificacionEstados.nombre',
        render: (value: any, row: EspaciosSimpDiscipPlan) => {
          return (
            <Box whiteSpace="nowrap" textAlign="center">
              {row.simpDiscipPlan.planificacionEstados.nombre ?? '-'}
            </Box>
          );
        },
      },
      {
        id: 'nombre',
        label: 'Creado Por',
        width: '10%',
        style: { textAlign: 'center' },
        orderById: 'simpDiscipPlan.cuentas.nombreUsuario',
        render: (value: any, row: EspaciosSimpDiscipPlan) => {
          return (
            <Box whiteSpace="nowrap" textAlign="center">
              {`${row.simpDiscipPlan.cuentas.nombreUsuario} ${row.simpDiscipPlan.cuentas.apellidoUsuario}` ??
                '-'}
            </Box>
          );
        },
      },
      {
        id: 'icono',
        label: '',
        width: '5%',
        style: { textAlign: 'right' },
        hideSortIcon: true,
        render: (value: any, row: EspaciosSimpDiscipPlan) => (
          <Box whiteSpace="nowrap" textAlign="right">
            <OptionsModal options={actionButtons} data={row} />
          </Box>
        ),
      },
    ],
    [actionButtons],
  );
};
