import React, { useMemo, useState } from 'react';
import { HeaderTable } from 'src/commons';
import { useRolesContext } from 'src/context/RolesContext';
import { useGestionRolesColumns } from 'src/screens/Private/GestionRoles/hooks';
import { JoinedFilters } from './components/GestionRolesHeader/types';
import { useAutoRegistracionLibbyFetch } from '../../../../../../app/business';
import { GestionRolesHeader } from './components/GestionRolesHeader';
import { InfoTable } from '../../../../../../commons/components/InfoTable';
import { useTableSortConfig } from '../../../../../../commons/hooks';

const searchFilters = [
  'usuario.apellidoUsuario',
  'usuario.nombreUsuario',
  'usuario.username',
  'rolUsuario.descripcion',
  'seccion.division',
  'espacioCurricular.descripcion',
  'rolStatus.descripcion',
];

export interface GestionRolesProps {
  idLocalizacion: string;
}

export const GestionRoles = ({ idLocalizacion }: GestionRolesProps) => {
  const { selectedRole } = useRolesContext();
  const [filter, setFilter] = useState<JoinedFilters>({
    idRolUsuario: '',
    idRolStatus: '',
    search: '',
  });

  const processedFilter = useMemo(
    () => ({
      rol: !filter.idRolUsuario
        ? []
        : [{ path: 'rolUsuario.idRolUsuario', value: filter.idRolUsuario }],
      estado: !filter.idRolStatus
        ? []
        : [{ path: 'rolStatus.idRolStatus', value: filter.idRolStatus }],
      search: !filter.search
        ? []
        : searchFilters.map((path) => ({
            path,
            value: filter.search,
            method: 'includes',
          })),
      localizacion: [{ path: 'localizacion', value: idLocalizacion }],
    }),
    [filter.idRolStatus, filter.idRolUsuario, filter.search, idLocalizacion],
  );

  const { orderBy, onSortChange, direction } = useTableSortConfig([
    'rolStatus.idRolStatus',
    'usuario.apellidoUsuario',
  ]);
  const {
    data = [],
    working,
    fetchMore,
    reFetch,
  } = useAutoRegistracionLibbyFetch({
    direction,
    filter: processedFilter,
    aspect: 'limit_localization',
    orderBy,
  });
  const { column, rows, checkedAll, checkUserRoles, handleModalGestionRoles } =
    useGestionRolesColumns(reFetch, data);

  const hidddenValidation = useMemo(() => {
    const checkUserRolesValidation = Array.isArray(checkUserRoles)
      ? checkUserRoles.length
      : false;

    return (checkedAll || checkUserRolesValidation) && rows.length;
  }, [checkUserRoles, checkedAll, rows.length]);

  return (
    <>
      <HeaderTable title={selectedRole.localizacion}>
        <GestionRolesHeader
          filter={filter}
          onChangeFilter={setFilter}
          handleModalGestionRoles={handleModalGestionRoles}
          hidden={hidddenValidation}
        />
      </HeaderTable>
      <InfoTable
        working={working}
        rows={rows}
        columns={column}
        rowIdKey="idGrupo"
        orderBy={orderBy}
        direction={direction}
        onSortChange={onSortChange}
        onBottomScroll={fetchMore}
      />
    </>
  );
};
