import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { AspectosGenerales } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'aspectos_generales';

const defaultOptions: PartialBusinessEntityOptions<any> = {};

const optionsToUse = !businessOptions.AspectosGenerales
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.AspectosGenerales,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.AspectosGenerales.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<AspectosGenerales>(DAO_NAME, optionsToUse);

// hooks
export const useAspectosGeneralesFetchById = fetchById;
export const useAspectosGeneralesLibbyCall = libbyCall;
export const useAspectosGeneralesDAO = libbyDAO;
export const useAspectosGeneralesLibbyFetch = libbyFetch;

// components/hoc
export const AspectosGeneralesAutocomplete = entityAutocomplete;
export const AspectosGeneralesCall = renderPropCall;
export const AspectosGeneralesById = renderPropFetchById;
export const AspectosGeneralesSelect = simpleSelect;
export const AspectosGeneralesAccordionSelector = accordionSelector;
export const AspectosGeneralesSelectedChips = selectedChips;

// context
// ByIdContext
export const useAspectosGeneralesByIdContext = useByIdContext;
export const AspectosGeneralesByIdProvider = ByIdProvider;
export const AspectosGeneralesByIdContext = ByIdContext;
// GeneralContext
export const useAspectosGeneralesContext = useGeneralContext;
export const AspectosGeneralesProvider = GeneralProvider;
export const AspectosGeneralesContext = GeneralContext;
