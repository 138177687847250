import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Exceptuado } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'exceptuados';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idExceptuados',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idExceptuados',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Exceptuados
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Exceptuados,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Exceptuados.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Exceptuado>(DAO_NAME, optionsToUse);

// hooks
export const useExceptuadosFetchById = fetchById;
export const useExceptuadosLibbyCall = libbyCall;
export const useExceptuadosDAO = libbyDAO;
export const useExceptuadosLibbyFetch = libbyFetch;

// components/hoc
export const ExceptuadosAutocomplete = entityAutocomplete;
export const ExceptuadosCall = renderPropCall;
export const ExceptuadosById = renderPropFetchById;
export const ExceptuadosSelect = simpleSelect;
export const ExceptuadosAccordionSelector = accordionSelector;
export const ExceptuadosSelectedChips = selectedChips;

// context
// ByIdContext
export const useExceptuadosByIdContext = useByIdContext;
export const ExceptuadosByIdProvider = ByIdProvider;
export const ExceptuadosByIdContext = ByIdContext;
// GeneralContext
export const useExceptuadosContext = useGeneralContext;
export const ExceptuadosProvider = GeneralProvider;
export const ExceptuadosContext = GeneralContext;
