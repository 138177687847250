import React from 'react';
import { useStepOneAlumnosColumn } from '../hooks/useStepOneAlumnosColumn';
import {
  Footer,
  HeaderTable,
  InfoTable,
  Loading,
} from 'src/commons/components';
import { grey } from '@material-ui/core/colors';
import { Grid, makeStyles } from '@material-ui/core';

const customStyles = {
  infoTable: {
    marginRight: 15,
    marginLeft: 15,
  },
};

const useStyles = makeStyles(customStyles);

export const StepOne = ({ alumnsWithSections }: any) => {
  const classes = useStyles();
  const columns = useStepOneAlumnosColumn();

  return (
    <>
      <Grid className={classes.infoTable}>
        <InfoTable rows={alumnsWithSections} columns={columns} />
      </Grid>
    </>
  );
};
