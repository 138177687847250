import { Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import {
  FormCheckbox,
  FormInput,
  FormMultiCheckbox,
  useFormContext,
} from 'src/lib/templates';
import { useStyles } from '../styles';
import { CustomFormAutocomplete as FormAutocomplete } from '../../../components';

interface EnfermedadCronica {
  id: number;
  descripcion: string;
}

const enfermedadesCronicas: EnfermedadCronica[] = [
  {
    id: 1,
    descripcion: 'Asma',
  },
  {
    id: 2,
    descripcion: 'Diabetes',
  },
  {
    id: 3,
    descripcion: 'Epilepsia',
  },
  {
    id: 4,
    descripcion: 'Celiaquía',
  },
  {
    id: 5,
    descripcion: 'Hernias',
  },
  {
    id: 6,
    descripcion: 'Cardiopatías',
  },
  {
    id: 7,
    descripcion: 'Otra',
  },
];

const gruposSanguineos = [
  {
    label: 'A+',
    value: 'A+',
  },
  {
    label: 'A-',
    value: 'A-',
  },
  {
    label: 'B+',
    value: 'B+',
  },
  {
    label: 'B-',
    value: 'B-',
  },
  {
    label: 'AB+',
    value: 'AB+',
  },
  {
    label: '0+',
    value: '0+',
  },
  {
    label: '0-',
    value: '0-',
  },
];

// TODO: Crear datos para esto, u obtenerlos de algun model
const tieneCalendarioVacuna = [
  { label: 'Completo', value: 'completo' },
  { label: 'Incompleto', value: 'incompleto' },
];

export const DatosPrincipales: FunctionComponent<{ isHistoric: boolean }> = ({
  isHistoric,
}) => {
  const classes = useStyles();

  const { form } = useFormContext();

  return (
    <Grid container spacing={6} direction="column">
      <Grid item className={classes.titleContainer}>
        <Typography className={classes.title} variant="h4">
          Salud
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={2}>
          <Grid container spacing={4}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormAutocomplete
                disabled={isHistoric}
                placeholder="Seleccioná grupo y factor sanguíneo"
                label="Grupo y factor sanguíneo"
                name="salud.datosPrincipales.grupoSanguineo"
                form={form}
                options={gruposSanguineos}
                optionLabelKey="value"
                optionToValue={(option) => option?.value}
                valueToOption={(value, options) =>
                  options.find((option: any) => option?.value === value)
                }
                getOptionSelected={(
                  option: { value: any },
                  value: { value: any },
                ) => option?.value === value?.value}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormAutocomplete
                disabled={isHistoric}
                placeholder="Seleccioná estado"
                label="Calendario de vacunación"
                name="salud.datosPrincipales.calendarioVacunacion"
                form={form}
                options={tieneCalendarioVacuna}
                optionLabelKey="label"
                optionToValue={(option) => option?.value}
                valueToOption={(value, options) =>
                  options.find((option: any) => option?.value === value)
                }
                getOptionSelected={(
                  option: { value: any },
                  value: { value: any },
                ) => option?.value === value?.value}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormInput
                disabled={isHistoric}
                placeholder="Describí las alergias"
                label="Alergias (opcional)"
                name="salud.datosPrincipales.alergias"
                form={form}
              />
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs={12}>
              <FormMultiCheckbox
                disabled={isHistoric}
                options={enfermedadesCronicas}
                name="salud.datosPrincipales.enfermedadesCronicas"
                form={form}
              />
            </Grid>
          </Grid>
          <Grid container spacing={4}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormInput
                disabled={isHistoric}
                placeholder="Escribí las observaciones necesarias respecto a enfermedades crónicas"
                label="Observaciones (opcional)"
                name="salud.datosPrincipales.observaciones"
                form={form}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormInput
                disabled={isHistoric}
                placeholder="Describí seguimiento"
                label="Seguimiento docente (opcional)"
                name="salud.datosPrincipales.seguimientoDocente"
                form={form}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormInput
                disabled={isHistoric}
                placeholder="Describí el tratamiento"
                label="Tratamiento médico (opcional)"
                name="salud.datosPrincipales.tratamientoMedico"
                form={form}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormInput
                disabled={isHistoric}
                placeholder="Describí los medicamentos necesarios"
                label="Medicamentos (opcional)"
                name="salud.datosPrincipales.medicamentos"
                form={form}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormInput
                disabled={isHistoric}
                placeholder="Describí necesidades"
                label="Necesidades educativas especiales (opcional)"
                name="salud.datosPrincipales.necesidades"
                form={form}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormInput
                disabled={isHistoric}
                placeholder="Describí las actividades que el alumno no puede realizar"
                label="Actividades restringidas por oden médica (opcional)"
                name="salud.datosPrincipales.actividadesRestringidas"
                form={form}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormInput
                disabled={isHistoric}
                placeholder="Describí qué hacer en caso de emergencia"
                label="Acciones en caso de emergencia (opcional)"
                name="salud.datosPrincipales.accionesEmergencia"
                form={form}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormInput
                disabled={isHistoric}
                placeholder="Escribí las observaciones necesarias"
                label="Observaciones (opcional)"
                name="salud.datosPrincipales.observaciones2"
                form={form}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormCheckbox
              disabled={isHistoric}
              name="salud.datosPrincipales.sabeNadar"
              form={form}
              label="Sabe nadar"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
