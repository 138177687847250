import React from 'react';
import '../styles/fonts.css';

const headerStyle = {
  fontFamily: 'Nunito-bold',
  color: '#38485C',
  align: 'left',
  fontSize: '14px',
  paddingTop: '10px',
  paddingBottom: '0px',
  margin: 0,
  backgroundColor: 'white',
};

const headerStyle2 = {
  fontFamily: 'Nunito-mediumItalic',
  fontStyle: 'italic',
  fontSize: '14px',
  margin: 0,
  fontWeight: 'normal',
};

export const accionesColumns = [
  {
    id: 'diasContraturno',
    label: 'Días',
    width: '10%',
    noSort: true,
    hideSortIcon: true,
    headCellsStyles: headerStyle,
  },
  {
    id: 'Seleccion',
    label: 'Selección',
    width: '10%',
    noSort: true,
    hideSortIcon: true,
    headCellsStyles: headerStyle,
  },
  {
    id: 'Cantidad',
    label: 'Cantidad',
    width: '10%',
    noSort: true,
    hideSortIcon: true,
    headCellsStyles: headerStyle,
  },
  {
    id: 'EspacioCurricular1',
    label: (
      <div>
        <p style={headerStyle}>Espacio Curricular</p>
        <p style={headerStyle2}>Contraturno 1</p>
      </div>
    ),
    width: '15%',
    noSort: true,
    hideSortIcon: true,
    headCellsStyles: headerStyle,
  },
  {
    id: 'EspacioCurricular2',
    label: (
      <div>
        <p style={headerStyle}>Espacio Curricular</p>
        <p style={headerStyle2}>Contraturno 2</p>
      </div>
    ),
    width: '15%',
    noSort: true,
    hideSortIcon: true,
    headCellsStyles: headerStyle,
  },
];
