import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { ROL, SimpleSelect } from 'src/commons';

import { useGetRows } from '../../../../hooks';
import { useQuehaceresCustomLibbyFetch } from 'src/app/business/Calificaciones/QueHaceresCustom';
import { isEqual } from 'lodash';
import { setSearchParamsMultipleWords } from 'src/utils/setSearchParamsMultipleWords';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
import { useRolesContext } from 'src/context/RolesContext';

export type objTable = {
  id: string;
  nombre: string;
  tipo: string;
  seccion: string;
  bimestre: string;
};

const opcionesMock = [
  { descripcion: '-' },
  { descripcion: 'Insuficiente (I)' },
  { descripcion: 'Regular (R)' },
  { descripcion: 'Bueno (B)' },
  { descripcion: 'Muy Bueno (MB)' },
  { descripcion: 'Sobresaliente (S)' },
];

export const useQueHaceresGeneralRows = (filter: any) => {
  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();
  const [readyToClose, setReadyToClose] = useState<boolean>(false);
  const filterParams = useMemo(
    () => ({
      0: [{ path: 'seccion', value: filter.seccion }],
      1: [{ path: 'calificacion.periodo', value: filter.periodo }],
    }),
    [filter.seccion, filter.periodo],
  );

  const {
    selectedRole: { rol },
  } = useRolesContext();

  const fetchResponse = useQuehaceresCustomLibbyFetch({
    aspect: 'default',
    filter: filterParams,
    enabled: !!filter.seccion,
    limit: 100,
  });

  const { data } = fetchResponse;

  const {
    rows: _rows,
    changeRow,
    working,
    reFetch,
    hasCalifications,
  } = useGetRows(fetchResponse, filter.search);

  const isClosed = useMemo(
    () => _rows.some((row: any) => row.nota.abierto === false),
    [_rows],
  );

  useEffect(() => {
    const submitRows = _rows.filter((row: any) => {
      let isUpdated = false;
      const currentCalification = data?.find(
        (d: any) => d.idCalificacion === row.idCalificacion,
      );
      if (currentCalification?.nota) {
        isUpdated = !isEqual(currentCalification?.nota?.data, row.nota.data);
      } else if (Object.keys(row.nota.data).length) {
        isUpdated = true;
      }
      return isUpdated;
    });
    setReadyToClose(!submitRows.length);
  }, [_rows, data]);

  const rows = useMemo(() => {
    const isDisabled =
      _rows.some(
        (c) => c.nota?.abierto !== undefined && c.nota?.abierto === false,
      ) || Number(rol) === ROL.COORDINADOR_JE;

    return _rows.map((row, index: number) => {
      return {
        nombre: `${row?.apellido}, ${row?.nombre}`,
        organizaTareas: (
          <Grid style={{ minWidth: 150 }}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={opcionesMock}
              value={row.nota?.data?.organizaTareas}
              name="organizaTareas"
              key={`organizaTareas-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          </Grid>
        ),
        expresaIdeas: (
          <Grid style={{ minWidth: 150 }}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={opcionesMock}
              value={row.nota?.data?.expresaIdeas}
              name="expresaIdeas"
              key={`expresaIdeas-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          </Grid>
        ),
        asumeResponsabilidad: (
          <Grid style={{ minWidth: 150 }}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={opcionesMock}
              value={row.nota?.data?.asumeResponsabilidad}
              name="asumeResponsabilidad"
              key={`asumeResponsabilidad-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          </Grid>
        ),
        realizaTareas: (
          <Grid style={{ minWidth: 150 }}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={opcionesMock}
              value={row.nota?.data?.realizaTareas}
              name="realizaTareas"
              key={`realizaTareas-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          </Grid>
        ),
        manifiestaInteres: (
          <Grid style={{ minWidth: 150 }}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={opcionesMock}
              value={row.nota?.data?.manifiestaInteres}
              name="manifiestaInteres"
              key={`manifiestaInteres-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          </Grid>
        ),
        logrosDificultades: (
          <Grid style={{ minWidth: 150 }}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={opcionesMock}
              value={row.nota?.data?.logrosDificultades}
              name="logrosDificultades"
              key={`logrosDificultades-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          </Grid>
        ),
      };
    });
  }, [rol, changeRow, _rows]);
  return {
    rows,
    working,
    _rows,
    data,
    reFetch,
    isClosed,
    readyToClose,
    hasCalifications,
  };
};
