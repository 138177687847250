import { Cuentas, Seccion } from 'src/app/models';
import { ProcesoSeccion } from 'src/app/models/ProcesoSeccion';
import { REQUEST_TYPES } from 'src/lib/libby/hooks/useLibbyRequest';
import { makeEntityLibbyRequest } from '../../../lib/libby/hooks/makeEntityLibbyRequest';

const DAO_NAME = 'generarinforme';

// hooks
export const useGenerarinformeGetRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.GET,
);
export const useGenerarinformePostRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.POST,
);
export const useGenerarinformePutRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.PUT,
);
export const useGenerarinformeDeleteRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.DELETE,
);
