import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { EstadoPropuesta } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'estado_propuesta';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEstadoPropuesta',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEstadoPropuesta',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EstadoPropuesta
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EstadoPropuesta,
      hoc: { ...defaultOptions.hoc, ...businessOptions.EstadoPropuesta.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EstadoPropuesta>(DAO_NAME, optionsToUse);

// hooks
export const useEstadoPropuestaFetchById = fetchById;
export const useEstadoPropuestaLibbyCall = libbyCall;
export const useEstadoPropuestaDAO = libbyDAO;
export const useEstadoPropuestaLibbyFetch = libbyFetch;

// components/hoc
export const EstadoPropuestaAutocomplete = entityAutocomplete;
export const EstadoPropuestaCall = renderPropCall;
export const EstadoPropuestaById = renderPropFetchById;
export const EstadoPropuestaSelect = simpleSelect;
export const EstadoPropuestaAccordionSelector = accordionSelector;
export const EstadoPropuestaSelectedChips = selectedChips;

// context
// ByIdContext
export const useEstadoPropuestaByIdContext = useByIdContext;
export const EstadoPropuestaByIdProvider = ByIdProvider;
export const EstadoPropuestaByIdContext = ByIdContext;
// GeneralContext
export const useEstadoPropuestaContext = useGeneralContext;
export const EstadoPropuestaProvider = GeneralProvider;
export const EstadoPropuestaContext = GeneralContext;
