import { useState, useCallback, useEffect, useMemo } from 'react';

interface LinkedListProps<T = any> {
  items: T[];
  id: keyof T;
  loading?: boolean;
}

export const useLinkedList = <T>({
  items,
  id,
  loading = false,
}: LinkedListProps<T>) => {
  const [visited, setVisited] = useState<number[]>([]);
  const [loadNext, setLoadNext] = useState(false);
  const [current, setCurrent] = useState<T>();

  useEffect(() => {
    if (!loadNext) return;
    if (loading && items.length === 0) return;
    const lastVisited = visited[visited.length - 1];
    const index = items.findIndex((i) => Number(i[id]) === lastVisited);
    if (index !== -1) {
      const nextAlumno = items[index + 1];
      if (nextAlumno) {
        setCurrent(nextAlumno);
      }

      if (index === items.length - 1) {
        const noVisitados = items.filter(
          (i) => !visited.includes(Number(i[id])),
        );
        if (noVisitados[0]) {
          setCurrent(noVisitados[0]);
        }
      }
    }
    setLoadNext(false);
  }, [items, id, loadNext, visited, loading]);

  const nextStudent = useCallback(async () => {
    if (!current) return;
    setVisited((prev) => {
      prev.push(Number(current[id]));
      return [...new Set(prev)];
    });
    setLoadNext(true);
  }, [current, id]);

  const disabledNext = useMemo(() => {
    return items.length === 0
      ? false
      : items.every((i) => visited.includes(Number(i[id])));
  }, [items, id, visited]);

  return { current, disabledNext, nextStudent, setCurrent };
};
