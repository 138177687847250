import React, { useState, useMemo } from 'react';
import { AnyObject } from 'src/commons';
import {
  RolesContext,
  SelectedRole,
  RolesContextValue,
  UserRoles,
  UserInfo,
} from './RolesContext';

const emptyRole = {
  idUsuarioRol: '',
  localizacion: '',
  localizacionId: '',
  modalidad: 0,
  nivel: '',
  rol: '',
};

const emptyUserRoles = [
  {
    schoolName: '',
    rolDescription: '',
    img: '',
  },
];

const emptyUserInfo = {
  id: '',
  name: '',
  email: '',
  apellido: '',
};

export const RolesContextProvider = ({ children }: AnyObject) => {
  const [selectedRole, setSelectedRole] = useState<SelectedRole>(emptyRole);
  const [userRoles, setUserRoles] = useState<UserRoles[]>(emptyUserRoles);
  const [userInfo, setUserInfo] = useState<UserInfo>(emptyUserInfo);

  const value = useMemo<RolesContextValue>(
    () => ({
      selectedRole,
      setSelectedRole,
      deselectRole: () => setSelectedRole(emptyRole),
      userRoles,
      setUserRoles,
      userInfo,
      setUserInfo,
    }),
    [selectedRole, userRoles, userInfo],
  );
  return (
    <RolesContext.Provider value={value}>{children}</RolesContext.Provider>
  );
};
