import { LibbyFetchDAO } from './LibbyFetchDAO';

export class SolicitudPaseDAO extends LibbyFetchDAO {
  constructor() {
    super('solicitud_pases', 'idSolicitudPases');
  }

  filterByEstadoSolicitud(value: number) {
    return this.query()
      .equals('estadoSolicitudPases.idEstadoSolicitudPases', value)
      .limit(0, 10000)
      .run();
  }

  getByFilter(filter: any) {
    return this.fetch({ filter });
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
