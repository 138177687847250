import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo } from 'react';
import { useOrganizacionesDAO } from 'src/app/business/acap/Organizaciones';
import { Organizacion } from 'src/app/models';
import { InfoTableColumn, OptionsModal, OptionsModalOption } from 'src/commons';
import confirmDialog from 'src/commons/services/confirmDialog';
import { OrganizacionColumnProps } from '../../types';

export const useOrganizacionesColumns = ({
  cantidadAccionesPorOrganizacion,
  organizacionesReFetch,
  handleViewAcciones,
  handleEdit,
  handleViewReferente,
}: OrganizacionColumnProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const organizacionesDAO = useOrganizacionesDAO();

  const handleDelete = useCallback(
    (row) => async () => {
      const confirm = await confirmDialog.show({
        title: 'Eliminación de organización',
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        content: '¿Esta seguro que desea eliminar la organización?',
      });
      if (confirm) {
        try {
          await organizacionesDAO.remove(row);
          enqueueSnackbar('Se ha eliminado con exito', {
            variant: 'success',
          });
          organizacionesReFetch();
        } catch (e) {
          console.log(e);
          enqueueSnackbar('Error al eliminar', { variant: 'error' });
        }
      }
    },
    [enqueueSnackbar, organizacionesReFetch, organizacionesDAO],
  );

  const columns = useMemo<InfoTableColumn<Organizacion>[]>(
    () => [
      {
        id: 'nombre',
        label: 'Organización',
        render: (value) => value,
        orderById: 'nombre',
      },
      {
        id: 'cuit',
        label: 'CUIT',
        render: (value) => value,
        orderById: 'cuit',
      },
      {
        id: 'sector.nombre',
        label: 'Sector',
        render: (value) => value,
        orderById: 'sector.nombre',
      },
      {
        id: 'domicilio',
        label: 'Domicilio',
        render: (_, row) => row.sedes[0]?.direccion,
        hideSortIcon: true,
      },
      {
        id: 'referente',
        label: 'Referente',
        render: (_, row) =>
          Boolean(row?.referente?.apellido)
            ? `${row?.referente?.apellido}, ${row?.referente?.nombre}`
            : row?.referente?.nombre,
        orderById: 'referente.nombre',
      },
      {
        id: 'referente.telefono',
        label: 'Teléfono',
        render: (value) => value,
        orderById: 'referente.telefono',
      },
      {
        id: 'referente.email',
        label: 'Mail',
        render: (value) => value,
        orderById: 'referente.email',
      },
      {
        id: 'acciones',
        label: 'Acciones',
        hideSortIcon: true,
        render: (_, row) => {
          const accionFiltered = cantidadAccionesPorOrganizacion?.find(
            (accion) => accion?.entity_id_organizacion === row?.idOrganizacion,
          );
          return accionFiltered?.countofidaccion ?? 0;
        },
      },
      {
        id: 'icon',
        label: '',
        width: '1%',
        hideSortIcon: true,
        noSort: true,
        render: (_, row) => {
          const options: OptionsModalOption<any>[] = [
            {
              label: 'Editar organización',
              onClick: handleEdit(row),
              hide: false,
            },
            {
              label: 'Acciones asociadas',
              onClick: handleViewAcciones(row),
            },
            {
              label: 'Eliminar organización',
              onClick: handleDelete(row),
            },
            {
              label: 'Ver como referente',
              onClick: handleViewReferente(row),
            },
          ];
          return <OptionsModal options={options} />;
        },
      },
    ],
    [
      cantidadAccionesPorOrganizacion,
      handleDelete,
      handleEdit,
      handleViewAcciones,
      handleViewReferente,
    ],
  );

  return columns;
};
