import React from 'react';
import { Grid } from '@material-ui/core';
import { FiltersLogicReturn } from '../hooks';
import {
  RolSelect,
  RolStatusSelect,
} from '../../../../../../../../../app/business';

export type FilterProps = FiltersLogicReturn;

export const Filters = ({
  idRolStatus,
  idRolUsuario,
  onRolChange,
  onEstadoChange,
}: FilterProps) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <RolSelect
          title="Rol"
          placeholder="Seleccioná El Rol"
          value={idRolUsuario}
          handleChange={onRolChange}
          direction="asc"
        />
      </Grid>
      <Grid item xs={4}>
        <RolStatusSelect
          title="Estado"
          placeholder="Seleccioná el estado"
          value={idRolStatus}
          handleChange={onEstadoChange}
          direction="asc"
        />
      </Grid>
    </Grid>
  );
};
