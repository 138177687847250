import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { RepositorioTransversales } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'repositorio_transversales';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idTransversal',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idTransversal',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.RepositorioTransversales
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.RepositorioTransversales,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.RepositorioTransversales.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<RepositorioTransversales>(DAO_NAME, optionsToUse);

// hooks
export const useRepositorioTransversalesFetchById = fetchById;
export const useRepositorioTransversalesLibbyCall = libbyCall;
export const useRepositorioTransversalesDAO = libbyDAO;
export const useRepositorioTransversalesLibbyFetch = libbyFetch;

// components/hoc
export const RepositorioTransversalesAutocomplete = entityAutocomplete;
export const RepositorioTransversalesCall = renderPropCall;
export const RepositorioTransversalesById = renderPropFetchById;
export const RepositorioTransversalesSelect = simpleSelect;
export const RepositorioTransversalesAccordionSelector = accordionSelector;
export const RepositorioTransversalesSelectedChips = selectedChips;

// context
// ByIdContext
export const useRepositorioTransversalesByIdContext = useByIdContext;
export const RepositorioTransversalesByIdProvider = ByIdProvider;
export const RepositorioTransversalesByIdContext = ByIdContext;
// GeneralContext
export const useRepositorioTransversalesContext = useGeneralContext;
export const RepositorioTransversalesProvider = GeneralProvider;
export const RepositorioTransversalesContext = GeneralContext;
