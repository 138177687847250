import { Box, Button, Grid } from '@material-ui/core';
import React, { useMemo, useRef, useState } from 'react';
import { InfoTable } from '../../../../../commons';
import { useConfiguracionColumns } from '../hooks/useConfiguracionColumns';
import { EjeWithDocente } from '../../types';

interface TableFormProps {
  initialValue: { [k: number]: string[] };
  ejes: EjeWithDocente[];
  loading: boolean;
  loadingSave: boolean;
  handleSave: (docente: { [k: number]: string[] }) => () => Promise<void>;
}

export const TableForm = ({
  initialValue,
  ejes,
  loading,
  loadingSave,
  handleSave,
}: TableFormProps) => {
  const [docentes, setDocentes] = useState<{ [k: number]: string[] }>({
    ...initialValue,
  });

  const columns = useConfiguracionColumns({ docentes, setDocentes });

  const disable = useMemo(() => {
    const compare_a = JSON.stringify({ ...initialValue });
    const compare_b = JSON.stringify({ ...docentes });
    return compare_a === compare_b;
  }, [initialValue, docentes]);

  return (
    <>
      <Grid container id="table" justifyContent="center">
        <Grid item xs={8}>
          <InfoTable
            headerAlign="center"
            columns={columns}
            rows={ejes}
            working={loading}
          />
        </Grid>
      </Grid>

      <Grid container id="buttons" justifyContent="center">
        <Grid item component={Box} padding={4}>
          <Button
            disabled={loadingSave || disable}
            variant="contained"
            color="primary"
            style={{ textTransform: 'none' }}
            onClick={handleSave(docentes)}
          >
            Confirmar
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
