import React, { useMemo, useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import customFormDialog from 'src/commons/services/customFormDialog';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useUsuariosRolesDAO } from 'src/app/business/businessCustomEndpoints';
import { Grid } from '@material-ui/core';
import { Loading } from 'src/commons/components/Loading';
import { FormContextProvider } from 'src/lib/templates';
import { makeUser, Values } from './consts';
import { AddRolFormItem } from './AddRolFormItem';
import { template } from './template';
import {
  Cuentas,
  DistritoEscolar,
  UsuarioRolEstablecimiento,
} from 'src/app/models';

interface AddRolFormProps {
  usuario: Cuentas;
  roles: UsuarioRolEstablecimiento[];
  reFetch: () => void;
}

export interface InitialValuesDefinition {
  rol?: number;
  dependenciaFuncional?: 10 | 31 | 20 | 50 | 32 | 41 | 70;
  distrito: DistritoEscolar[];
  escalafon?: number;
  region?: number;
  nivel?: number;
  oldDependencias: number[];
}

export const AddRolForm = ({ usuario, roles, reFetch }: AddRolFormProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const usuariosRolesDAO = useUsuariosRolesDAO();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues: InitialValuesDefinition = useMemo(() => {
    const idDependencias: number[] = [];
    roles.forEach((r) => {
      if (r.dependenciaFuncional) {
        idDependencias.push(r.dependenciaFuncional.idDependenciaFuncional);
      }
    });
    return {
      distrito: [],
      oldDependencias: idDependencias,
    };
  }, [roles]);

  const onSubmit = useCallback(
    async (values: Values) => {
      const userParams = makeUser(values);
      const { idUsuario } = usuario;
      const confirm = await confirmDialog.show({
        title: 'Confirmacion de asignación de rol',
        confirmText: 'Confirmar',
        cancelText: 'Cancelar',
        content: `¿Desea asignar el rol al usuario ${usuario.username}?`,
      });

      if (userParams && confirm) {
        try {
          const { user, insertType } = userParams;
          setSubmitting(true);
          const usuariosRolesResponse = await usuariosRolesDAO.addRol(
            user,
            insertType,
            idUsuario,
          );
          if (usuariosRolesResponse.status === 500) {
            throw new Error(`${usuariosRolesResponse.message}`);
          }
          await customFormDialog.handleCancel();
          enqueueSnackbar('Se ha guardado con éxito', { variant: 'success' });
          reFetch();
        } catch (e) {
          console.log(e);
          enqueueSnackbar('Error al guardar', { variant: 'error' });
          await customFormDialog.handleCancel();
        }
      }
    },
    [usuario, reFetch, enqueueSnackbar, usuariosRolesDAO],
  );

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      style={{ marginTop: 20, paddingBottom: 20 }}
    >
      <FormContextProvider
        template={template}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {submitting ? <Loading /> : <AddRolFormItem />}
      </FormContextProvider>
    </Grid>
  );
};
