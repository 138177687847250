import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { FormApi } from 'final-form';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-final-form-hooks';
import { usePpsDAO, usePpsLibbyFetch } from 'src/app/business';
import { Pps } from 'src/app/models';
import { InformePpsType, PPSFormValues } from './types';
import { TABS_NAMES, submit } from './helpers';
import { Loading } from 'src/commons';
import { validateForm } from './validators';

const initialValues = {
  trayectoria: {
    requirio: '',
    cuales: '',
    ajustesRazonables: '',
    cualesAjustes: '',
    ajustesAreas: '',
    requirioacompañada: '',
    contenidos: '',
    requiriopedagogico: '',
    interrumpida: '',
    destaca: '',
    interes: '',
    observaciones: '',
  },
  vinculo: {
    acompaña: '',
    participa: '',
    adulto: '',
    observaciones: '',
  },
  jornada: {
    participa: '',
    cual: '',
    observaciones: '',
  },
  intervenciones: {
    derivacion: '',
    informe: {},
  },
  antecedentes: {
    antecedentes: '',
    informe: {},
  },
  convivencia: {
    mantiene: '',
    respeta: '',
    vincula: '',
    acude: '',
    observaciones: '',
  },
  actitud: {
    puedeOrganizarse: '',
    cumple: '',
    consulta: '',
    demuestra: '',
    logra: '',
    manifiesta: '',
    observaciones: '',
  },
  antecedentesAdjunto: { files: '', name: '' },
  intervencionesAdjunto: { files: '', name: '' },
};

export const PPSFormContext = createContext({
  currentTab: TABS_NAMES.EMPTY,
  form: {},
  pps: [],
  isSaving: false,
  data: [],
  informePps: {},
  reFetch: () => {},
  ppsWorking: false,
  setCurrentTab: (tab: TABS_NAMES) => {},
});

export const usePPSFormContext = () => useContext(PPSFormContext);

export const PPSFormProvider = ({
  children,
  informePps,
  data = [],
}: {
  children: React.ReactNode;
  informePps: InformePpsType;
  data: Pps[];
}) => {
  const [isSaving, setIsSaving] = useState(false);
  const [currentTab, setCurrentTab] = useState<TABS_NAMES>(TABS_NAMES.EMPTY);

  const ppsFilter = useMemo(
    () => ({
      alumno: [
        { path: 'alumno.idAlumno', value: informePps?.row?.alumno?.idAlumno },
      ],
    }),
    [informePps?.row?.alumno?.idAlumno],
  );

  const {
    data: pps = [],
    working,
    reFetch,
  } = usePpsLibbyFetch({
    filter: ppsFilter,
    limit: 1,
    aspect: 'basic',
  });

  const ppsDao = usePpsDAO();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = async (values: PPSFormValues) => {
    setIsSaving(true);
    try {
      const response = await submit(values, ppsDao, informePps.row.alumno);
      if (response.existError) {
        enqueueSnackbar('Ha ocurrido un error', { variant: 'error' });
      } else {
        enqueueSnackbar('Se ha guardado con éxito', { variant: 'success' });
      }
      reFetch();
    } catch (error) {
      console.error('Error guardando el PPS', error);
      enqueueSnackbar('Ocurrió un error al guardar el PPS', {
        variant: 'error',
      });
    } finally {
      setIsSaving(false);
    }
  };

  const preValues = useMemo(() => {
    return pps.length > 0
      ? {
          ...pps[0],
          antecedentesAdjunto: {
            files: '',
            name: pps[0]?.antecedentes?.informe?.filename ?? '',
          },
          intervencionesAdjunto: {
            files: '',
            name: pps[0]?.intervenciones?.informe?.filename ?? '',
          },
        }
      : initialValues;
  }, [pps]);

  const { handleSubmit, form } = useForm({
    onSubmit,
    initialValues: preValues,
    validate: validateForm(currentTab),
  });

  useEffect(() => {
    const unsubscribe = form.subscribe(
      ({ modified }) => {
        const values = form.getState().values;

        if (
          modified['intervenciones.derivacion'] &&
          values.intervenciones?.derivacion === 'No'
        ) {
          (form as FormApi).change('intervenciones.informe', {});
          (form as FormApi).change('intervencionesAdjunto', {
            files: '',
            name: '',
          });
        }
      },
      {
        modified: true,
      },
    );

    return () => unsubscribe();
  }, [form]);
  return (
    <PPSFormContext.Provider
      value={{
        form,
        pps,
        isSaving,
        informePps,
        data,
        reFetch,
        ppsWorking: working,
        setCurrentTab,
        currentTab,
      }}
    >
      {working ? <Loading /> : <form onSubmit={handleSubmit}>{children}</form>}
    </PPSFormContext.Provider>
  );
};
