import React from 'react';
import { useParams } from 'react-router';
import { EspaciosInterdiscipPlanById } from 'src/app/business';
import {
  useBackButton,
  useRouteScreenTitle,
} from '../../../../../../../commons/hooks';
import { InterdisciplinarFlow } from './InterdisciplinarFlow';
import { Loading } from '../../../../../../../commons/components/Loading';

export const InterdisciplinarFeedback = () => {
  // Title
  useRouteScreenTitle('Asesoramiento para la planificación Interdisciplinar');
  useBackButton();
  // @ts-ignore
  const { idPlanificacion } = useParams();

  return (
    <EspaciosInterdiscipPlanById id={idPlanificacion} aspect="limit_province">
      {({ data, working }) =>
        working || !data ? <Loading /> : <InterdisciplinarFlow data={data} />
      }
    </EspaciosInterdiscipPlanById>
  );
};
