import React, { useState, FunctionComponent, useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  customDialog: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '500px',
  },

  customDialogTitle: {
    fontSize: '25px',
    color: 'black',
    marginRight: '70px',
    marginBottom: '-30px',
  },

  hrLine: {
    border: 'none',
    height: '1px',
    backgroundColor: '#ccc',
    margin: '12px 0',
  },
});

type CustomDialogProps = {
  open: boolean;
  onClose: () => void;
  title: string;
  content: string;
  confirmText: string;
  cancelText: string;
  onConfirm: (selectedOptions: SelectedOptions) => void;
};

type SelectedOptions = {
  weeklyChecked: boolean;
  dailyChecked: boolean;
};

export const CustomDialog: FunctionComponent<CustomDialogProps> = ({
  open,
  onClose,
  title,
  content,
  confirmText,
  cancelText,
  onConfirm,
}) => {
  const classes = useStyles();

  const [weeklyChecked, setWeeklyChecked] = useState(false);
  const [selectedOption, setSelectedOption] = useState<string>('');

  useEffect(() => {
    if (open) {
      setSelectedOption('');
    }
  }, [open]);

  const handleOptionChange = (option: string) => {
    setSelectedOption(option);
  };

  const handleConfirm = () => {
    const weeklyChecked = selectedOption === 'weekly';
    const dailyChecked = selectedOption === 'daily';
    onConfirm({ weeklyChecked, dailyChecked });
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={classes.customDialogTitle}>{title}</DialogTitle>
      <DialogContent>
        <hr className={classes.hrLine} />
        <DialogContentText>{content}</DialogContentText>
        <FormControlLabel
          control={
            <input
              type="radio"
              style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
              }}
              checked={selectedOption === 'daily'}
              onChange={() => handleOptionChange('daily')}
            />
          }
          label="Diario"
          style={{ marginLeft: '20px' }}
        />
        <FormControlLabel
          control={
            <input
              type="radio"
              style={{
                width: '20px',
                height: '20px',
                borderRadius: '50%',
              }}
              checked={selectedOption === 'weekly'}
              onChange={() => handleOptionChange('weekly')}
            />
          }
          label="Semanal"
        />
      </DialogContent>
      <DialogActions style={{ marginTop: '10px' }}>
        <Button onClick={onClose} color="secondary">
          {cancelText}
        </Button>
        <Button onClick={handleConfirm} color="primary" variant="contained">
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
