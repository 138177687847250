import { LibbyFetchDAO } from './LibbyFetchDAO';

export class LegajoAlumnoDAO extends LibbyFetchDAO {
  constructor() {
    super('legajo_alumno', 'idLegajoAlumno');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByAlumnoId(idAlumno: number) {
    return this.query().equals('alumno', idAlumno).run();
  }
}
