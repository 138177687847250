import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import moment from 'moment';
import { OptionsModal } from 'src/commons/components';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { useSnackbar } from 'notistack';
import { UserAvatar } from 'src/commons';
import {
  useComentarioLibbyFetch,
  usePublicacionDAO,
  useComentarioDAO,
} from 'src/app/business';
import { useRolesContext } from 'src/context/RolesContext';
import customFormDialog from 'src/commons/services/customFormDialog';
import confirmDialog from 'src/commons/services/confirmDialog';
import {
  Publicacion as PlubicacionModel,
  Comentario as ComentarioModel,
} from 'src/app/models';
import { PublicacionFormModal } from './components/PublicacionFormModal';
import { ComentarioForm } from './components/ComentarioForm';
import { ComentariosList } from './components/ComentariosList/ComentariosList';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(4),
    border: '1px solid #ccc',
    borderRadius: '5px',
    maxWidth: '100%',
    wordBreak: 'break-word',
  },
  comentarios: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(1),
    borderTop: '1px solid #ccc',
  },
  archive: {
    marginTop: 10,
    padding: 25,
    background: '#f5f5f5',
    cursor: 'pointer',
  },
  header: {
    padding: '0px 5px 15px 0',
  },
  name: {
    fontWeight: 'bold',
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
  },
  textarea: {
    padding: 0,
  },
  selectsContainer: {
    marginTop: theme.spacing(2),
  },
  container_comentarios: {
    marginBottom: 10,
  },
}));

interface PublicacionPropsTypes {
  userName: string;
  date: string;
  message: string;
  url?: string;
  comentable?: boolean;
  id: number;
  idCuenta: string | number;
  publicacion: PlubicacionModel;
  reFetchPublicaciones?: () => void;
}

export const Publicacion = ({
  userName,
  date,
  message,
  url,
  comentable,
  id,
  idCuenta,
  publicacion,
  reFetchPublicaciones,
}: PublicacionPropsTypes) => {
  const { userInfo } = useRolesContext();
  const publicacionDAO = usePublicacionDAO();
  const comentarioDAO = useComentarioDAO();
  const { enqueueSnackbar } = useSnackbar();

  const filter = useMemo(
    () => ({
      filter: [{ path: 'publicacion', value: id }],
    }),
    [id],
  );

  const { data: comentarios, reFetch } = useComentarioLibbyFetch({
    filter,
  });

  const handleEditModal = () => {
    customFormDialog.show({
      title: '',
      renderComponent: (
        <PublicacionFormModal
          idPublicacion={id}
          reFetch={reFetchPublicaciones}
        />
      ),
      sizeWidth: 'md',
    });
  };

  const handleDeletePublicacion = async () => {
    const confirm = await confirmDialog.show({
      title: 'Confirmación de Eliminación',
      confirmText: 'Eliminar',
      cancelText: 'Cancelar',
      content: '¿Desea eliminar la publicación?',
    });
    if (confirm) {
      try {
        if (comentarios) {
          comentarios.forEach(async (comentario) => {
            await comentarioDAO.remove({
              idComentario: comentario.idComentario,
            });
          });
        }
        await publicacionDAO.remove({ idPublicacion: id });
        enqueueSnackbar('Se ha eliminado con exito', { variant: 'success' });
        reFetchPublicaciones && reFetchPublicaciones();
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Error al eliminar', { variant: 'error' });
      }
    }
  };

  const classes = useStyles();
  return (
    <Grid item container direction="column" className={classes.container}>
      <Grid
        container
        item
        direction="row"
        spacing={2}
        className={classes.header}
      >
        <Grid item className={classes.avatar}>
          <UserAvatar
            name={publicacion.cuentas.nombreUsuario}
            avatarWidth={28}
            avatarHeight={28}
          />
        </Grid>
        <Grid item xs>
          <Typography
            className={classes.name}
          >{`${publicacion.cuentas.nombreUsuario} ${publicacion.cuentas.apellidoUsuario}`}</Typography>
          <Typography>
            {moment(date).format('DD / MM / YYYY - HH:mm')}
          </Typography>
        </Grid>
        {idCuenta === userInfo.id && (
          <Grid container item spacing={0} xs={6} justify="flex-end">
            <OptionsModal
              options={[
                {
                  label: 'Editar',
                  onClick: handleEditModal,
                },
                {
                  label: 'Borrar',
                  onClick: handleDeletePublicacion,
                },
              ]}
            />
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Typography>{message}</Typography>
      </Grid>
      {url && (
        <Grid
          item
          className={classes.archive}
          onClick={() => {
            window.open(url, '_blank');
          }}
        >
          <Grid container item direction="row" spacing={2}>
            <Grid item>
              <AttachFileIcon />
            </Grid>
            <Grid item xs>
              <Typography>Adjunto</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {comentable && (
        <Grid item className={classes.comentarios}>
          {comentarios && (
            <ComentariosList
              comentarios={comentarios}
              userName={userName}
              reFetchPublicacion={reFetch}
              idPublicacion={id}
            />
          )}
          <ComentarioForm reFetch={reFetch} idPublicacion={id} />
        </Grid>
      )}
    </Grid>
  );
};
