import React from 'react';
import { Grid } from '@material-ui/core';
import { nivelDefaultFilter } from 'src/commons/const';
import {
  NivelSelect,
  RepositorioTipoItemSelect,
} from '../../../../../../../app/business';
import { FiltersLogicReturn } from '../hooks';

export type FilterProps = FiltersLogicReturn;

export const Filters = ({
  idNivel,
  idTipo,
  onNivelChange,
  onTipoChange,
}: FilterProps) => (
  <Grid container spacing={1}>
    <Grid item xs={4}>
      <NivelSelect
        value={idNivel}
        handleChange={onNivelChange}
        filter={nivelDefaultFilter}
      />
    </Grid>
    <Grid item xs={4}>
      <RepositorioTipoItemSelect
        title="Tipo"
        value={idTipo}
        handleChange={onTipoChange}
      />
    </Grid>
  </Grid>
);
