import React, { FC } from 'react';
import { Grid, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormMultiCheckboxInput,
  FormMultiCheckbox,
} from 'src/lib/templates/components';
import { usePlanificationFormDisciplinarComun } from '../../hooks';
import { CheckboxListItem } from '../../../commons/components/CheckboxListItem';
import { useDisciplinarComunContext } from '../../components/context';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: '32px 10px',
      marginBottom: 60,
    },
  }),
);

export const IndicadoresEvaluacion: FC = () => {
  const classes = useStyles();
  const { form, values, errors } = usePlanificationFormDisciplinarComun();
  const { data, working } = useDisciplinarComunContext();
  const buttonCustomStyle = { margin: '10px 0px 25px 0' };
  return (
    <Grid container direction="column" className={classes.container}>
      <FormMultiCheckbox
        name="indicadoresDeEvaluacion.indicadoresDeEvaluacion"
        // @ts-ignore
        options={data?.indicadores}
        form={form}
        idKey="idIndicadoresDeEvaluacion"
      />
      <FormMultiCheckboxInput
        label=""
        name="indicadoresDeEvaluacion.adicionales"
        form={form}
        addButtonText="Agregar Indicador"
        buttonCustomStyle={buttonCustomStyle}
        getNewElement={(value) => ({
          checked: false,
          descripcion: '',
          id: -1 - 1 * value.filter((item: any) => item.id < 0).length, // use negative id to validate new items
        })}
      >
        {(value, input, meta, index) => (
          <CheckboxListItem
            inputPlaceholder="Nuevo indicador"
            value={value}
            input={input}
            meta={meta}
            index={index}
          />
        )}
      </FormMultiCheckboxInput>
    </Grid>
  );
};
