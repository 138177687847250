import React from 'react';
import { ModalidadType } from 'src/app/models';
import { useRolesContext } from 'src/context/RolesContext';
import { InitialRegistered } from './Initial';
import { ScaleBCRegistered } from './ScaleBC';
import { RegisteredAdults } from './Adults';
import { useSaveTabIndex } from '../hooks/useSaveTabIndex';

const screenModalidad: Record<string, JSX.Element> = {
  1: <InitialRegistered />,
  2: <ScaleBCRegistered />,
  3: <RegisteredAdults />,
};

export const Registered = () => {
  useSaveTabIndex();
  const {
    selectedRole: { modalidad },
  } = useRolesContext();
  return screenModalidad[modalidad || ModalidadType.General];
};
