import React from 'react';
import { useParams } from 'react-router';
import {
  useBackButton,
  useRouteScreenTitle,
} from '../../../../../commons/hooks';
import {
  InitialValueDefinition,
  MainForm,
  TransformFunction,
  UsuarioRolEstablecimientoEditValue,
} from './components';
import { Loading } from '../../../../../commons/components/Loading';

const initialValue: InitialValueDefinition = {
  idLocalizacion: '',
  idNivel: '',
  idRolUsuario: '',
};

const transformer: TransformFunction<InitialValueDefinition> = (value) => ({
  idLocalizacion: value.localizacion.idLocalizacion,
  idNivel: value.nivel.idNivel,
  idRolUsuario: value.rolUsuario.idRolUsuario,
});

export const UsuariosRolForm = () => {
  useBackButton();
  const { idRolEstablecimiento } = useParams();
  useRouteScreenTitle(
    idRolEstablecimiento === 'new' ? 'Agregar Rol' : 'Editar Rol',
  );

  return idRolEstablecimiento === 'new' ? (
    <MainForm initialValue={initialValue} />
  ) : (
    <UsuarioRolEstablecimientoEditValue<InitialValueDefinition>
      idRolEstablecimiento={idRolEstablecimiento}
      transformer={transformer}
    >
      {({ working, data, raw }) =>
        working || !data ? (
          <Loading />
        ) : (
          <MainForm initialValue={data} raw={raw} />
        )
      }
    </UsuarioRolEstablecimientoEditValue>
  );
};
