import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { Footer, MainInput } from 'src/commons';
import customFormDialog from 'src/commons/services/customFormDialog';

export const CambioPasswordModal = () => {
  const [formValues, setFormValues] = useState({
    password: '',
    confirmPassword: '',
  });

  const handleConfirm = () => {
    customFormDialog.handleConfirm(formValues);
  };

  const defaultButtons: any[] = [
    {
      title: 'Guardar',
      size: 'medium',
      handleOnClick: handleConfirm,
      disabled:
        formValues?.password === '' ||
        formValues?.confirmPassword === '' ||
        formValues?.password !== formValues?.confirmPassword,
    },
  ];

  return (
    <>
      <Grid style={{ height: '15em' }}>
        <p style={{ fontFamily: 'Open Sans', marginLeft: '8px' }}>
          Cambiar la contraseña por defecto es obligatorio para poder acceder a
          MiEscuela.
        </p>
        <Grid container direction="row">
          <p style={{ fontFamily: 'Open Sans', marginLeft: '8px' }}>
            Nueva contraseña:
            <span style={{ color: 'red' }}>
              {formValues?.password ? '' : '*'}
            </span>
          </p>
          <MainInput
            value={formValues?.password || ''}
            type="password"
            handleChange={(e) => {
              const value = e?.target?.value;
              setFormValues({ ...formValues, password: value });
            }}
            placeholder=""
            fullWidth
            autocomplete="new-password"
            customStyle={{
              width: '20em',
              marginLeft: '8px',
            }}
          />
          <p
            style={{
              fontFamily: 'Open Sans',
              marginLeft: '8px',
            }}
          >
            Repetir contraseña:
            <span style={{ color: 'red' }}>
              {formValues?.confirmPassword &&
              formValues?.password === formValues?.confirmPassword
                ? ''
                : '*'}
            </span>
          </p>
          <MainInput
            value={formValues?.confirmPassword || ''}
            type="password"
            handleChange={(e) => {
              const value = e?.target?.value;
              setFormValues({ ...formValues, confirmPassword: value });
            }}
            placeholder=""
            autocomplete="new-password"
            error={formValues?.password !== formValues?.confirmPassword}
            helperText="Las contraseñas deben ser iguales"
            fullWidth
            customStyle={{
              width: '20em',
              marginLeft: '8px',
            }}
          />
        </Grid>
        <Footer
          customStyle={{ marginTop: '70px' }}
          buttonConfig={defaultButtons}
        />
      </Grid>
    </>
  );
};
