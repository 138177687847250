import { ConfirmacionMatriculaDAO } from 'src/platform/libby/dao/confirmacionMatricula';
import { makeBusinessEntity } from '../../../lib/libby/generator';
import { ConfirmacionMatricula } from '../../models';

const DAO_NAME = 'confirmacion_matricula';

const {
  hook: { libbyDAO, libbyFetch, libbyCall },
} = makeBusinessEntity<ConfirmacionMatricula, ConfirmacionMatriculaDAO>(
  DAO_NAME,
  {},
);

// hooks
export const useConfirmacionMatriculaLibbyCall = libbyCall;
export const useConfirmacionMatriculaDAO = libbyDAO;
export const useConfirmacionMatriculaLibbyFetch = libbyFetch;
