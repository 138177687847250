import { useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useEfectivizarProyeccionPostRequest } from 'src/app/business';
import { useRolesContext } from 'src/context/RolesContext';
import { DEFAULT_HIDE_TIME } from 'src/commons/const/ui/snackbar';
import { useSnackbar } from 'notistack';
import confirmDialog from 'src/commons/services/confirmDialog';
import {
  Proyeccion,
  ProyeccionEstado,
  SeccionWithProyeccion,
} from 'src/app/models';
import EfectivizadorInicial from 'src/app/business/Proyeccion/EfectivizadorInicial';
import EfectivizadorPrimaria from 'src/app/business/Proyeccion/EfectivizadorPrimaria';
import EfectivizadorSecundaria from '../../../../../../app/business/Proyeccion/EfectivizadorSecundaria';
import { useColegioEfectivizadoContext } from '../../../../Calificaciones/context/EfectivizacionProvider';

export const useEfectivizar = (
  proyecciones: Proyeccion[],
  proyeccionesIEL: { idProyeccionIEL: string }[],
  secciones: SeccionWithProyeccion[],
  resultado: boolean,
  estadoProyeccion?: ProyeccionEstado,
) => {
  const { enqueueSnackbar } = useSnackbar();
  const { request: efectivizarRequest } = useEfectivizarProyeccionPostRequest();
  const [inProgress, setInProgress] = useState(false);
  const history = useHistory();
  const {
    deselectRole,
    selectedRole: { nivel, localizacionId },
  } = useRolesContext();

  const { cicloLectivo } = useColegioEfectivizadoContext();

  const enabled = useMemo(() => {
    const efectivizadorInicial = new EfectivizadorInicial(
      proyecciones.map((p) => ({
        ...p,
        status: p.estadoPaseAnio.idEstadoPaseAnio,
      })),
      proyeccionesIEL,
      localizacionId,
      estadoProyeccion?.proyeccionEstadoTipo?.idProyeccionEstadoTipo,
    );
    const efectivizadorPrimaria = new EfectivizadorPrimaria(
      proyecciones.map((p) => ({
        ...p,
        status: p.estadoPaseAnio.idEstadoPaseAnio,
      })),
      estadoProyeccion?.proyeccionEstadoTipo?.idProyeccionEstadoTipo,
    );
    const efectivizacionSecundaria = new EfectivizadorSecundaria(
      proyecciones.map((p) => ({
        ...p,
        status: p.estadoPaseAnio.idEstadoPaseAnio,
      })),
      secciones.filter(
        (seccion) => seccion.cicloLectivo.anio === cicloLectivo?.anio,
      ),
      estadoProyeccion?.proyeccionEstadoTipo?.idProyeccionEstadoTipo,
    );
    const efectivizadores = [
      efectivizadorInicial,
      efectivizadorPrimaria,
      efectivizacionSecundaria,
    ];
    const canEfectivizar = efectivizadores[Number(nivel) - 1].canEfectivizar();
    return resultado && canEfectivizar;
  }, [
    localizacionId,
    proyecciones,
    proyeccionesIEL,
    estadoProyeccion?.proyeccionEstadoTipo?.idProyeccionEstadoTipo,
    resultado,
    nivel,
    secciones,
    cicloLectivo,
  ]);

  return {
    inProgress,
    enabled,
    efectivizar: async () => {
      const confirm = await confirmDialog.show({
        title: 'Efectivizar',
        content:
          '¿Está seguro que desea efectivizar el colegio? Una vez comenzado el proceso no podra revertirse',
        confirmText: 'Aceptar',
        cancelText: 'Volver',
      });
      if (confirm) {
        setInProgress(true);
        try {
          const efectivizarResponse: any = await efectivizarRequest();
          setInProgress(false);
          if (!efectivizarResponse?.existError) {
            enqueueSnackbar('La efectivización se realizó con éxito.', {
              variant: 'success',
              autoHideDuration: DEFAULT_HIDE_TIME,
            });
            history.push('/establecimientos/home');
            deselectRole();
          } else {
            throw new Error(efectivizarResponse?.error?.message);
          }
        } catch (error) {
          setInProgress(false);
          enqueueSnackbar(
            'La efectivización no pudo realizarse. Por favor inténtelo nuevamente.',
            {
              variant: 'error',
              autoHideDuration: DEFAULT_HIDE_TIME,
            },
          );
        }
      }
    },
  };
};
