import React, { useContext, useEffect } from 'react';
import { createContext } from 'react';
import { useIsLocalizacionEfectivizada } from 'src/commons/hooks/useIsLocalizacionEfectivizada';
import { useRolesContext } from 'src/context/RolesContext';
import { CicloLectivo, ProyeccionEstado } from 'src/app/models';

type EfectivizacionContextType = {
  establecimientoEfectivizado: string | boolean;
  waiting: boolean;
  cicloLectivo: CicloLectivo | null;
  proyeccionEstado: ProyeccionEstado | null;
};

export const EfectivizacionContext = createContext<EfectivizacionContextType>({
  establecimientoEfectivizado: false,
  waiting: false,
  cicloLectivo: null,
  proyeccionEstado: null,
});

export const useColegioEfectivizadoContext = () =>
  useContext(EfectivizacionContext);

export const EfectivizacionProvider = ({
  children,
}: {
  children: JSX.Element;
}) => {
  const {
    selectedRole: { nivel, localizacionId },
  } = useRolesContext();

  const efectivizacion = useIsLocalizacionEfectivizada(localizacionId, nivel);

  return (
    <EfectivizacionContext.Provider value={efectivizacion}>
      {children}
    </EfectivizacionContext.Provider>
  );
};
