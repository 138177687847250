import { makeStyles } from '@material-ui/core';
import { primary } from 'src/theme/colors';

export const primarioTableStyles = makeStyles({
  table: {
    maxHeight: '600px',
    marginBottom: '80px',
  },
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
  containerFooter: {
    background: primary.white,
    padding: '10px 20px',
    boxShadow: '0px -7px 3px rgba(0, 0, 0, 0.1);',
  },
});
