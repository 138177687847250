import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useEspacioCurricularSeccionLibbyCall } from 'src/app/business';
import { Footer, InfoTable, useBackButton } from 'src/commons';
import { PermissionBlocker } from 'src/lib/permission';
import { BLOCKER_ID } from 'src/platform/permission/const';
import confirmDialog from 'src/commons/services/confirmDialog';
import { CalificacionesFiltersHeader } from '../../components/CalificacionesFiltersHeader/CalificacionesFiltersHeader';
import { useCalificacionesSecundarioContext } from '../context/CalificacionesSecundarioContext';
import { RecuperacionFactory } from '../../CalificacionesSecundario/factories/RecuperacionFactory';
import { useCalificacionesRecuperacionExcelData } from '../hooks/useCalificacionesRecuperacionExcelData';
import { ModifyArrayPrototype } from '../../CalificacionesSecundario/factories/ArrayModifier';
import { useRecuperacionesRows } from '../hooks/useRecuperacionesRows';
import { useRecuperacionesSubmit } from '../hooks/useRecuperacionesSubmit';
import { useRecuperacionSwitch } from '../hooks/useRecuperacionSwitch';
import { useInitSecundario } from '../hooks/useInitSecundario';
import { useValidator } from '../hooks/useValidator';
import { AlertMessage } from '../../components';

ModifyArrayPrototype();

export const CalificacionesRecuperacion = () => {
  const { periodo, factory } =
    useCalificacionesSecundarioContext<RecuperacionFactory>();
  const columns = factory.getColumns();

  const {
    idEspacioCurricular,
    idAnio,
    classes,
    history,
    filter,
    setFilter,
    roleInfo,
    seccionSelect,
    setSeccionSelect,
    espacioSeccionSelect,
    setEspacioSeccionSelect,
  } = useInitSecundario(Number(14)); // 14 = Recuperacion

  factory.setConfig({ filter });

  const {
    data: espacioCurricularSeccion = [],
    recall,
    working: ecsWorking,
  } = useEspacioCurricularSeccionLibbyCall({
    methodName: 'getByEspacioCurricularAndSeccion',
    params: [],
    noAutoCall: true,
    aspect: 'shorted',
  });

  useEffect(() => {
    if (idEspacioCurricular && filter.seccion) {
      recall(idEspacioCurricular, filter.seccion);
    }
  }, [idEspacioCurricular, filter.seccion, recall]);

  const ecs = useMemo(() => {
    return espacioCurricularSeccion[0]?.idEspacioCurricularSeccion;
  }, [espacioCurricularSeccion]);

  const {
    rows,
    working,
    _rows,
    reFetch,
    isClosed,
    hasCalifications,
    readyToClose,
  } = useRecuperacionesRows(filter, ecs, ecsWorking);

  const { incompletedRows, isDisabled, hasPermissionsToOpen, submitRows } =
    useValidator(_rows, filter);

  const espacioCurricularName = useMemo(() => {
    const espacioCurricularDescripcion =
      espacioCurricularSeccion[0]?.espacioCurricular?.descripcion;

    return espacioSeccionSelect !== ''
      ? espacioSeccionSelect
      : espacioCurricularDescripcion;
  }, [espacioCurricularSeccion, espacioSeccionSelect]);

  const [anioSelect, setAnioSelect] = useState('');

  const { excelData } = useCalificacionesRecuperacionExcelData(
    _rows,
    `Calificaciones - ${espacioCurricularName} - Recuperaciones - ${seccionSelect} - ${roleInfo?.selectedRole?.localizacionId}`,
    'Período de recuperación',
    anioSelect,
    seccionSelect,
    espacioCurricularName,
  );

  const showTable = useMemo(() => {
    const filters = filter.anio && filter.seccion;
    return idEspacioCurricular
      ? Boolean(filters)
      : Boolean(filters && filter.espacioCurricularSeccion);
  }, [idEspacioCurricular, filter]);

  const { onSubmit, submitting } = useRecuperacionesSubmit(
    _rows,
    reFetch,
    ecs || filter.espacioCurricularSeccion,
  );

  const { onSwitch } = useRecuperacionSwitch({
    filter,
    reFetch,
    ecs,
  });

  const handleLeave = useCallback(
    async (route?: string) => {
      if (incompletedRows.length || (!readyToClose && showTable)) {
        const confirm = await confirmDialog.show({
          title: '¿Desea continuar?',
          content:
            'La información precargada se perderá en caso que no guarde, por favor seleccione una opción.',
          confirmText: 'Salir sin guardar',
          cancelText: 'Seguir en esta pantalla',
        });
        if (confirm) history.push(route ?? '/');
      } else {
        history.push(route ?? '/');
      }
    },
    [history, incompletedRows.length, readyToClose, showTable],
  );

  const confirmarSalirConBackButton = useCallback(() => {
    if (!(isDisabled || isClosed || submitRows)) {
      handleLeave('/private/calificaciones');
    } else {
      history.push('/private/calificaciones');
    }
  }, [handleLeave, history, isClosed, isDisabled, submitRows]);

  useBackButton('/calificaciones', 0, confirmarSalirConBackButton);

  const buttonConfig: any = useMemo(() => {
    const defaultButtons: any[] = [
      {
        title: 'Salir',
        size: 'medium',
        type: 'secondary',
        handleOnClick: () => handleLeave(),
      },
      {
        title: 'Guardar',
        size: 'medium',
        handleOnClick: onSubmit,
        disabled: isDisabled || isClosed || submitRows,
      },
    ];
    if (!isClosed) {
      defaultButtons.push({
        title: 'Cerrar Calificaciones',
        size: 'medium',
        handleOnClick: () => onSwitch('close'),
        disabled:
          isDisabled ||
          !readyToClose ||
          isClosed ||
          !hasCalifications ||
          !submitRows,
      });
    } else if (hasPermissionsToOpen) {
      defaultButtons.push({
        title: 'Abrir Calificaciones',
        size: 'medium',
        handleOnClick: () => onSwitch('open'),
      });
    }
    return defaultButtons;
  }, [
    handleLeave,
    onSubmit,
    isDisabled,
    isClosed,
    submitRows,
    hasPermissionsToOpen,
    readyToClose,
    onSwitch,
    hasCalifications,
  ]);

  const handleSeccionesChange = (options: any) => {
    const espacioCurricularCustomFound = options?.find(
      (s: any) =>
        s.seccion?.idSeccion === filter.seccion ||
        s.idSeccion === filter.seccion,
    );
    if (espacioCurricularCustomFound) {
      const sectionName = espacioCurricularCustomFound.nombreSeccion
        ? espacioCurricularCustomFound.nombreSeccion
        : espacioCurricularCustomFound.seccion?.nombreSeccion;
      setSeccionSelect(sectionName);
    }
  };

  const handleEspaciosSeccionChange = (options: any) => {
    const espacioSeccion = options?.find(
      (p: any) =>
        p.idEspacioCurricularSeccion === filter.espacioCurricularSeccion,
    );
    setEspacioSeccionSelect(espacioSeccion?.espacioCurricular.descripcion);
  };

  const handleAnioChange = (options: any) => {
    const anio = options?.find((s: any) => s.idAnio === filter.anio);
    setAnioSelect(anio?.descripcionAnio);
  };

  return (
    <>
      <AlertMessage
        incompletedRows={incompletedRows}
        isDisabled={isDisabled}
        isClosed={isClosed}
        working={working}
        showView={showTable}
      />
      <CalificacionesFiltersHeader
        rows={rows}
        filter={filter}
        setFilter={setFilter}
        showEspacioCurricular={!idEspacioCurricular}
        idAnio={idAnio}
        excelData={excelData}
        showView={showTable}
        handleSeccionesChange={handleSeccionesChange}
        handleAnioChange={handleAnioChange}
        incompletedRows={incompletedRows}
        readyToClose={readyToClose}
        handleEspaciosSeccionChange={handleEspaciosSeccionChange}
        setEspacioSeccionSelect={setEspacioSeccionSelect}
        hideEDIandTutoria={true}
        recuperacion={true}
      />
      <p>
        <b style={{ fontFamily: 'Nunito' }}>
          “Período de Apoyo y Acompañamiento para la Evaluación y Promoción Res.
          11684/11”
        </b>
      </p>
      {showTable && !ecsWorking ? (
        <>
          <InfoTable
            rows={rows}
            columns={columns}
            customStyle={classes.table}
            working={working}
          />
          <PermissionBlocker id={BLOCKER_ID.QUALIFY_ESTUDENS}>
            <Grid className={classes.footer}>
              <Footer buttonConfig={buttonConfig} loading={submitting} />
            </Grid>
          </PermissionBlocker>
        </>
      ) : (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: '400px' }}
        >
          <Typography
            style={{
              fontSize: '40px',
              marginTop: '50px',
              color: 'gray',
              fontWeight: 'bold',
              lineHeight: '45px',
            }}
          >
            {!idEspacioCurricular
              ? 'Seleccione año, sección, período y espacio curricular.'
              : 'Seleccione año, sección y período.'}
          </Typography>
        </Grid>
      )}
    </>
  );
};
