import { Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { FormDatePicker, FormInput, useFormContext } from 'src/lib/templates';
import { useStyles } from '../styles';

export const LOSE: FunctionComponent<{ isHistoric: boolean }> = ({
  isHistoric,
}) => {
  const classes = useStyles();
  const { form } = useFormContext();

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      className={classes.secondaryContainer}
    >
      <Grid item>
        <Typography className={classes.title} variant="h5">
          LOSE (opcional)
        </Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <FormDatePicker
              disabled={isHistoric}
              placeholder="Seleccioná fecha"
              label="Desde"
              name="salud.datosLose.loseDesde"
              form={form}
              className={classes.datePicker}
            />
          </Grid>
          <Grid item xs={6}>
            <FormDatePicker
              disabled={isHistoric}
              placeholder="Seleccioná fecha"
              label="Hasta"
              name="salud.datosLose.loseHasta"
              form={form}
              className={classes.datePicker}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
