export const drawEscudo = (options: { isWatermark: boolean }) => `
<svg
  version="1.1"
  id="Capa_1"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  x="0px"
  y="0px"
  viewBox="0 0 877 967"
  style="enable-background:new 0 0 877 967;"
  xml:space="preserve"
>
  <style type="text/css">
    .st0{fill:${options.isWatermark ? '#707070' : '#000000'}
  </style>
  <g>
  <path class="st0" d="M778.52,402.87c-4.73-30.25-12.2-60-22.33-88.89c-9.8-27.72-22.14-54.48-36.87-79.93
  c-11.45-19.68-24.43-38.44-38.83-56.08c-18.39-22.63-39.32-43.07-62.38-60.91c-22.41-17.47-46.95-32.03-73.02-43.33
  c-26.02-11.1-53.43-18.62-81.47-22.36c-28.54-3.75-57.48-3.37-85.91,1.12c-27.94,4.36-55.14,12.56-80.83,24.36
  c-25.8,11.98-50.04,27.08-72.17,44.95c-22.56,18.32-43,39.13-60.91,62.02c-17.63,22.46-33.05,46.57-46.06,71.99
  c-13.5,26.46-24.5,54.13-32.85,82.64c-8.73,29.75-14.75,60.22-18,91.05c-3.34,32.06-3.81,64.36-1.38,96.5
  c2.33,31.15,7.43,62.03,15.23,92.28c7.61,28.93,17.8,57.12,30.46,84.22c12.24,26.01,26.95,50.79,43.92,73.99
  c20.99,25.47,43.65,49.52,67.83,71.98c56.44,47.53,127.7,73.89,201.49,74.52h0.11c28.55,0.02,56.97-4.03,84.37-12.03
  c27.1-7.96,53.04-19.43,77.15-34.14c24.55-15.02,47.23-32.9,67.57-53.26c20.26-20.4,38.35-42.85,53.99-66.97
  c15.88-24.36,29.35-50.2,40.24-77.16c11.4-28.06,20.2-57.1,26.3-86.77c6.44-30.99,10.06-62.49,10.8-94.14
  C785.73,466.52,783.57,434.49,778.52,402.87z M140.17,611.82h-9.22l8.31-31.24v-0.01c0.47-1.38,0.79-1.9,0.98-1.9
  c0.19,0,0.32,0.26,0.43,0.66C140.53,590.57,140.34,601.49,140.17,611.82z M144.74,526.64l-18.51-8.54
  c-1.05-0.39-0.93-0.76,0.21-0.8h39.61c1.16,0,1.22,0.37,0.17,0.8l-17.72,8.54C147.29,527.14,145.94,527.14,144.74,526.64z
    M121.58,509.25l20.67-14.7c-0.1,4.79-0.22,9.7-0.32,14.7H121.58z M128.43,459.68h35.34c0.94,0,1.04,0.37,0.19,0.76l-15.81,7.42
  c-1.1,0.44-2.32,0.48-3.45,0.11l-16.39-7.56C127.39,459.95,127.47,459.68,128.43,459.68z M124.03,452.35l19.12-12.46
  c-0.11,3.79-0.15,8.11-0.31,12.46H124.03z M149.94,452.44l-0.01-0.08h0.01c0-4.16-0.18-8.19-0.18-12.21l18.76,12.21h-18.58
  C149.94,452.38,149.94,452.41,149.94,452.44z M150.61,494.56l20.79,14.69h-20.56C150.72,504.25,150.61,499.34,150.61,494.56z
    M152.49,611.82c-0.13-10.33-0.4-21.32-0.57-32.39l0.01-0.01c0.07-0.4,0.26-0.72,0.51-0.72c0.25,0,0.57,0.4,0.93,1.55l8.31,31.58
  H152.49z M424.46,891.37v-0.01c-54.25-0.2-107.25-16.25-152.5-46.18h2.17c34.66,0.7,69.21,3.94,103.4,9.69
  c35.07,5.25,70.21,11.1,105.51,14.66c13.57,1.37,27.29,2.38,40.86,2.96C492.23,884.97,458.5,891.37,424.46,891.37z M585.65,839.46
  c-34.88,3.14-69.99,2.53-104.74-1.84c-34.73-3.67-69.22-9.65-103.82-14.63l0.01-0.01c-34.64-5.6-69.63-8.72-104.71-9.33h-2.89
  c-12.22,0.17-24.46,0.71-36.57,1.95c-11.58-10.31-22.52-21.33-32.75-32.98c36.25-4.84,72.92-5.71,109.36-2.57
  c36.53,2.66,72.77,8,108.99,13.29c18.23,2.75,36.35,5.45,54.5,7.85c9.19,1.18,45.17,3.95,53.99,3.95
  c36.45,0.04,72.85-2.83,108.85-8.58C620.54,812.43,603.73,826.8,585.65,839.46z M668.47,757.86
  c-34.96,8.18-70.59,13.12-106.46,14.76c-17.91,1.12-35.87,1.29-53.8,0.5c-18.09-1.21-36.11-3.34-53.99-6.38
  c-36.12-5.18-72.18-10.85-108.38-15.01v-0.01c-27.38-3.18-54.92-4.84-82.49-5c-8.79,0-17.61,0.21-26.41,0.65
  c-19.81,0.93-39.53,3.27-59,7c-6.68-9.3-13-18.99-18.95-29.07c32.06-6.74,64.63-10.76,97.36-12.02c33.1-0.9,66.22,0.89,99.03,5.33
  c31.77,4.2,63.24,10.09,94.98,14.38c29.01,4.05,58.25,6.19,87.53,6.42h0.21c30.43-0.04,60.81-2.56,90.84-7.53
  c22-3.56,43.74-8.11,65.2-13.65C686.33,731.92,677.76,745.14,668.47,757.86z M184.37,646.34c-10.23-6.33-16.42-17.54-16.32-29.57
  l46.97-110.75c0.47-0.9,0.78-1.27,0.98-1.27c0.21,0,0.33,0.53,0.21,1.27L184.37,646.34z M215.57,541.78
  c-0.71,49.7-1.38,95.85-1.66,118c-8.63-2.04-16.86-5.52-24.32-10.31l24.92-109.83c0.13-0.25,0.32-0.36,0.37-0.36
  C215.27,539.28,215.48,540.52,215.57,541.78z M203.16,444.6h35.31c0.89,0,1.02,0.42,0.08,0.83l-15.85,7.53
  c-1.03,0.47-2.21,0.49-3.27,0.07l-16.51-7.6v0.04C201.99,445.07,201.99,444.64,203.16,444.6z M198.73,437.45l18.63-12.28
  c-0.19,3.97-0.19,8.13-0.19,12.28H198.73z M224.74,425.36l18.4,12.09h-18.25v0.08C224.89,433.36,224.72,429.29,224.74,425.36z
    M262.52,588.03c-0.66,23.81-1.25,47.5-1.65,65.92c-0.15,0.17-0.37,0.28-0.59,0.3l-15.67-68.47l8.32-19.91L262.52,588.03z
    M256.18,558.24l6.23-14.85l0.01-0.01c0.29-0.36,0.44-0.55,0.69-0.55c0.25,0,0.4,0.28,0.47,0.68c-0.17,9.58-0.44,19.87-0.72,30.34
  L256.18,558.24z M240.52,595.12l15.23,62.29c-5.72,2.14-11.69,3.56-17.76,4.22c-2.01,0.23-4.04,0.41-6.16,0.57
  c-1.26,0.16-2.54,0.19-3.81,0.11c0-8.33-0.28-20.99-0.39-36.56L240.52,595.12z M227.38,611.14c-0.22-20.61-0.54-44.73-1.01-69.65
  c0.14-1.25,0.48-2.2,0.72-2.2c0.24,0,0.55,0.47,0.93,1.67l10.27,44.3L227.38,611.14z M242.26,576l-16.17-69.89
  c-0.1-0.77-0.03-1.33,0.18-1.33c0.21,0,0.54,0.36,1.01,1.25l22.45,52.22L242.26,576z M273.12,597.78v-10.22
  c-0.53-18.15-0.96-26.58-1.27-43.44v-0.01c0.08-0.73,0.32-1.31,0.51-1.31c0.19,0,0.46,0.19,0.8,0.71l27.99,70.01
  c-2.83,7.35-7.07,14.07-12.46,19.81L273.12,597.78z M284.11,637.88c-2.97,3.21-6.22,6.16-9.69,8.82l-0.07-0.04
  c-0.23-9.51-0.62-21.84-0.82-33.04L284.11,637.88z M312.66,611.03l32.07-68.9c-0.51,19.8-1.23,48.13-1.81,68.9h-13.14
  C325.03,611.03,317.38,611.35,312.66,611.03z M542.3,522.82h46.32c1.2,0,1.4,0.35,0.61,0.83c-0.17,0.11-21.17,10.01-21.17,10.01
  c-1.37,0.63-2.93,0.67-4.33,0.12l-21.69-10.02l0.03-0.01C540.81,523.19,540.93,522.8,542.3,522.82z M537,513.53l24.2-17.36
  c0,5.14-0.19,16.74-0.19,17.36H537z M546.48,445.07h38.76c0.44,0,0.41,0.06,0.69,0.17c0.28,0.11,0.15,0.39-0.53,0.66l-17.35,8.18
  c-1.18,0.37-2.44,0.37-3.61,0l-18.07-8.24c-0.59-0.26-0.73-0.51-0.59-0.63c-0.01,0-0.02,0-0.02,0.01l-0.01-0.04
  c-0.01,0.01,0.04,0.03,0.04,0.04C546.02,445.15,546.25,445.1,546.48,445.07z M541.6,436.84l20.7-14.83
  c-0.19,4.79-0.19,9.69-0.19,14.83H541.6z M569.84,436.92l-0.01-0.08h0.01c-0.14-4.91-0.23-9.77-0.32-14.6l20.76,14.6h-20.45
  C569.84,436.86,569.84,436.89,569.84,436.92z M570.54,496.17l24.2,17.36h-23.8l-0.01,0.03
  C570.75,507.85,570.6,501.87,570.54,496.17z M652.45,515.81c0.43,0,0.69,0.9,0.43,3.67l-34.41,152.47l-14.95-41.02l0.11-0.25
  l47.07-112.91v0.06C651.33,516.71,652.02,515.81,652.45,515.81z M640.72,477.58h39.61c1.11,0,1.26,0.28,0.17,0.82l-17.77,8.55
  c-1.18,0.43-2.48,0.43-3.67,0l-18.48-8.55h0.01C639.52,477.85,639.58,477.59,640.72,477.58z M635.73,469.51l20.81-14.77
  c-0.11,4.94-0.14,9.75-0.24,14.77H635.73z M664.59,454.78l21.06,14.73H664.9C664.83,464.58,664.73,459.68,664.59,454.78z
    M654.18,540.25c0.36-0.66,0.73-1.19,0.93-1.19s0.36,0.28,0.47,0.96c-0.66,48.01-1.54,99.59-2.05,135.84
  c-9.8,3.57-20.23,5.06-30.64,4.36l19.69-88.06L654.18,540.25z M614.84,678.82c-9.6-2.18-18.49-6.74-25.86-13.28l11.23-26.79
  L614.84,678.82z M584.66,661.28c-4.77-5.06-8.8-10.78-11.97-16.97v0.03c-0.24-23.1-0.82-57.6-1.36-94.56l26.16,80.57L584.66,661.28
  z M527.89,642.69h-56.76l84.54-95.45L527.89,642.69z M560.4,549.7c-0.37,21.48-1.12,90.38-1.12,92.99h-25.32L560.4,549.7z
    M665.8,539.97v-0.04C665.8,539.93,665.8,539.97,665.8,539.97c-0.04-0.68,0.02-1.02,0.29-1.02s0.68,0.51,0.98,1.3l23.69,102.44
  c-5.15,10.68-13.2,19.68-23.24,25.98C667.28,650.17,665.87,540.78,665.8,539.97C665.8,539.97,665.8,539.97,665.8,539.97z
    M696.79,642.05l-28.93-121.46l0.01,0.04c-0.37-3.6-0.24-4.83,0.29-4.83s1.4,1.38,2.19,2.77l53.01,123.48H696.79z M744.18,582.87
  c-3.91,19.36-9,38.47-15.23,57.21l-63.44-150.6l27.91-12.46c0.72-0.38,1.31-0.96,1.69-1.69c1.01-1.95,0.26-4.34-1.69-5.36
  c0,0-27.34-19.45-28.89-20.53l-0.68-46.2c3.41-0.48,6.67-1.97,9.94-2.52c3.62-0.34,7.26-0.09,10.8,0.75
  c6.42,1.64,13.23-0.1,18.07-4.64c-6.92-1.17-13.52-3.75-19.38-7.6c-3.23-1.5-6.69-2.44-10.23-2.77c-3.65-0.57-7.38-0.41-10.98,0.47
  c-0.36-2.7-0.71-5.47-1.16-8.5h-1.48c-1.74,13.02-2.48,26.14-2.23,39.27c-0.04,8.93-0.13,17.98-0.24,26.99c0,1.56,0,3.16-0.15,4.8
  l-28.68,20.49c-0.7,0.37-1.27,0.95-1.65,1.65c-1.03,1.94-0.29,4.34,1.65,5.37v0.07l27.05,12.46l-54.57,132.9l-28.19-85.54
  l31.15-14.62c0.79-0.41,1.45-1.04,1.9-1.82c1.27-2.23,0.5-5.07-1.73-6.35c0,0-29.42-20.58-33.22-23.35
  c-0.14-10.8-0.44-22.48-0.44-32.67l28.46-13.41c0.71-0.36,1.3-0.93,1.7-1.62c1.08-1.93,0.39-4.38-1.54-5.46l-29.32-20.76
  c-0.22-21.03-0.33-42.03-0.68-62.79c1.64-0.19,3.26-0.54,4.83-1.04c1.77-0.59,3.56-1.09,5.39-1.49c3.6-0.43,7.25-0.24,10.78,0.57
  c6.58,1.77,13.61,0.06,18.63-4.56c-6.92-1.18-13.51-3.78-19.38-7.63c-3.24-1.46-6.7-2.39-10.23-2.77
  c-3.62-0.55-7.32-0.42-10.89,0.39c-0.28-2.64-0.68-5.44-1.23-8.54h-1.38v0.08c-1.72,11.58-2.46,23.29-2.23,35
  c-0.1,10.69-0.1,21.28-0.33,31.96c-0.11,6.92-0.23,13.84-0.33,20.76l-28.95,20.7c-0.63,0.36-1.14,0.88-1.51,1.51
  c-1.15,1.98-0.48,4.53,1.51,5.68l28.38,13.37c0,10.13-0.21,22.08-0.37,32.74l-33.11,23.27c-0.88,0.44-1.59,1.15-2.03,2.03
  c-1.13,2.25-0.22,4.99,2.03,6.11l30.45,14.63L463.3,642.77c-1.83,0-13.69,0.1-15.38,2.31c-2.23,1.84-2.85,5-1.48,7.55
  c1.52,3.35,4.16,6.06,7.46,7.67c1.61,1.01,3.32,1.84,5.11,2.49c0.23,0.09,0.42,0.27,0.53,0.5c8.11,20.05,24.77,35.41,45.42,41.85
  h-0.14c0,0,1,0.35,0.77,1.05l-0.14,0.33c-0.33,0.13-0.67,0.23-1.02,0.3c-35.22-2.15-69.83-7.9-104.43-13.34
  c-13.32-2.15-26.54-4.14-39.8-5.88c-9.03-1.14-17.94-2.24-26.9-3.81c-0.83-0.37-1.27-0.59-1.48-0.95v-0.08v-0.12v-0.19
  c0-0.1,0-0.1,0-0.1c0.1-0.26,0.27-0.5,0.48-0.68l0.32-0.21c26.71-6.01,48.78-24.74,59.04-50.13c0.62-0.33,1.23-0.69,1.8-1
  c1.32-0.57,2.61-1.21,3.86-1.92c3.25-1.69,5.88-4.36,7.5-7.64c1.3-2.58,0.68-5.72-1.51-7.6c-1.63-2.23-13.39-2.31-15.35-2.31
  l-0.39,0.08l-0.11,0.08h-2.34c-0.1-7.6-0.22-27.38-0.73-40.15c1.51,0.39,3.1,0.3,4.55-0.25c1.92-0.57,3.68-1.15,5.54-1.51
  c3.69-0.84,7.51-0.84,11.2,0c3.6,0.82,7.33,0.9,10.96,0.26c3.22-0.86,6.2-2.45,8.71-4.65c-7.07-1.34-13.81-4.02-19.87-7.9
  c-3.4-1.47-7.02-2.37-10.71-2.66c-3.72-0.61-7.52-0.27-11.08,0.98c-0.26-3.13-0.51-5.69-0.82-7.68h-1.38
  c-1.88,10.92-1.88,48.85-2.01,63.53h-24.52c-0.75-26.72-1.95-62.38-2.55-85.25l21.29-45.52c2.92,1.22,6.23,1.48,9.19,2.6
  c3.24,1.21,6.21,3.01,8.78,5.33c2.41,2.27,5.23,4.06,8.31,5.27c3.45,0.9,7.06,1.01,10.56,0.35c-5.33-4.29-9.67-9.69-12.72-15.82
  c-2.07-2.84-4.56-5.36-7.39-7.45c-2.97-2.39-6.36-4.18-10.01-5.29c0.89-2.43,1.57-4.94,2.02-7.49c-0.11-0.25,0-0.69-0.62-1.05
  c-0.14-0.02-0.29-0.04-0.43-0.07c-0.97,0.17-1.19,0.66-1.79,1.47c-1.8,2.43-3.44,4.96-4.91,7.6l-22.56,48.63
  c-0.29-7.66-0.44-15.31-0.57-22.97c0.13-9.62-0.55-19.23-2.03-28.74h-1.38c-1.64,11.58-2.36,23.27-2.16,34.97
  c-0.14,10.45-0.51,21.08-0.82,31.6l-38.98,80.79l-34.97-85.7c-0.3-10.6-0.54-21.12-0.68-31.84c0.18-12.04-0.58-24.07-2.27-35.99
  h-1.38c-1.64,11.74-2.4,23.58-2.3,35.43c0,10.8-0.33,21.62-0.57,32.31l-11.23,26.47l-27.31-63.68c-0.18-10.6-0.32-21.03-0.46-31.11
  l24.92-11.73c0.65-0.33,1.18-0.86,1.51-1.51c0.87-1.71,0.2-3.8-1.51-4.68c0,0-20.97-15.17-25.42-18.33
  c-0.26-21.86-0.46-43.8-0.8-65.69c1.59-0.17,3.15-0.52,4.66-1.04c1.82-0.6,3.67-1.1,5.54-1.5c3.6-0.43,7.24-0.24,10.77,0.57
  c3.42,0.78,6.97,0.78,10.4,0c3.01-0.97,5.79-2.52,8.21-4.55c-6.81-1.11-13.3-3.68-19.03-7.52c-3.21-1.48-6.64-2.42-10.16-2.77
  c-3.77-0.65-7.63-0.54-11.35,0.33c-0.25-2.85-0.62-5.77-1.02-8.62h-1.38c-1.95,14-2.73,28.14-2.34,42.26
  c-0.12,13.68-0.33,27.31-0.5,40.98c-0.07,2.55-0.07,5.08-0.17,7.59l-25.42,18.23c-0.67,0.34-1.21,0.88-1.55,1.55
  c-0.88,1.74-0.19,3.86,1.55,4.74l24.92,11.73c-0.22,11.35-0.46,29.85-0.46,31.11l-50.64,119.05l-7.43-27.35h9.82
  c1.46-0.09,2.89-0.45,4.22-1.05c0.97-0.25,3.25-1.26,3.25-2.44c0-1.18-2.03-1.94-2.89-2.3c-1.46-0.58-3.01-0.91-4.58-0.98h-11.56
  l-5.04-18.91c-0.1-7.64-0.22-15.57-0.39-23.15l27.96-13.12c0.7-0.36,1.29-0.92,1.67-1.61c1.07-1.92,0.39-4.35-1.53-5.43
  c0,0-26.86-19.01-28.79-20.27c0-5.58-0.11-13.23-0.3-18.43l25.15-11.89c0.63-0.32,1.15-0.82,1.49-1.44
  c0.96-1.72,0.35-3.9-1.37-4.86c0,0-22.4-16.03-25.91-18.51c-0.22-18.33-0.36-30.54-0.46-31.92c3.02-0.25,6-0.91,8.85-1.95
  c3.14-0.52,6.35-0.44,9.47,0.23c2.99,0.74,6.1,0.85,9.14,0.32c2.85-0.83,5.5-2.25,7.78-4.15c-6.14-1.06-12-3.39-17.19-6.84
  c-2.92-1.27-6.02-2.05-9.19-2.33c-3.23-0.56-6.55-0.37-9.69,0.55c-0.28-2.48-0.65-4.96-1.22-7.64h-1.4
  c-1.59,10.02-2.28,20.17-2.08,30.32c-0.14,8.75-0.26,14.74-0.46,23.46l-25.78,18.43c-0.66,0.34-1.19,0.88-1.54,1.54
  c-0.91,1.76-0.22,3.92,1.54,4.83l25.21,11.88c-0.08,5.22-0.13,12.89-0.26,18.43l-28.35,20.17c-0.75,0.38-1.37,0.99-1.75,1.75
  c-0.98,1.95-0.2,4.32,1.75,5.3l27.69,13.18c-0.07,7.57-0.31,15.39-0.43,22.95l-4.94,19.12h-11.53c-1.48,0.05-2.94,0.33-4.33,0.83
  c-0.91,0.37-3.23,1.09-3.23,2.44c0,1.34,2.05,1.99,2.92,2.34c1.46,0.67,3.03,1.06,4.64,1.15h9.69l-8.68,33.1h-13.94
  c-8.53-31.91-13.7-64.62-15.45-97.61c-1.91-33.01-0.52-66.12,4.15-98.85c4.29-30.37,11.54-60.25,21.64-89.21
  c9.49-27.55,21.77-54.05,36.63-79.11c7.8-13.24,16.37-26.01,25.67-38.25c28.5,7.24,58.31,7.62,86.99,1.14
  c28.8-6.21,58.43-7.64,87.7-4.24l8.58,1.52c0.68-4.88,1.76-9.69,3.24-14.38c-31.97-8.83-65.34-11.42-98.29-7.61
  c-20.66,3.15-41.79,0-60.63-9.03c11.18-11.89,23.28-22.88,36.19-32.88c14.79,9.67,31.53,15.94,49.03,18.37
  c11.14,1.92,22.47,2.78,33.47,5.32c11.24,2.62,22.33,5.86,33.22,9.69c3.81,1.36,8.9,3.81,11.24,4.91
  c2.12-4.49,4.71-8.74,7.71-12.69l-7.14-4.53c-13.56-7.37-27.97-13.07-42.91-16.96c-10.01-2.91-19.74-6.72-29.07-11.38
  c-7.93-4.14-15.19-9.45-21.54-15.75c13.61-8.24,27.89-15.32,42.68-21.18c9.61,13.59,27.4,21.18,39.49,31.59
  c10.39,8.59,19.51,18.6,27.09,29.75c3.95-3.01,8.33-5.42,12.98-7.16c-6.71-16.01-16.32-30.65-28.34-43.18
  c-7-5.93-12.36-13.56-15.57-22.15c15.96-3.95,32.27-6.39,48.69-7.3l7.93,69.59c2.43-0.36,4.89-0.52,7.35-0.48
  c2.49,0,4.97,0.16,7.43,0.48l7.93-69.59c11.38,0.63,22.71,1.96,33.93,3.97c8.47,1.76,14.85,3.36,14.85,3.36
  c-3.29,8.51-8.58,16.11-15.42,22.15c-12.02,12.49-21.57,27.14-28.14,43.18c4.62,1.71,8.96,4.11,12.87,7.12
  c7.61-11.12,16.68-21.16,26.97-29.86c11.85-10.34,29.61-18.37,39.19-31.59c10.34,4.04,20.44,8.66,30.26,13.84l12.74,7.35
  c-6.88,5.67-14.07,10.96-21.53,15.85c-9.23,4.91-18.99,8.75-29.1,11.43c-14.93,3.91-29.34,9.6-42.91,16.96l-7.2,4.46
  c3.12,3.9,5.76,8.17,7.88,12.69c2.6-1.25,7.49-3.56,11.17-4.86c10.88-3.85,21.98-7.09,33.22-9.69c10.95-2.55,22.29-3.39,33.44-5.32
  c17.44-2.52,34.12-8.79,48.91-18.37c12.73,10.17,24.81,21.13,36.15,32.84c-18.79,8.9-39.79,12.03-60.36,9
  c-32.94-3.84-66.3-1.33-98.29,7.42c1.5,4.57,2.51,9.29,3.03,14.08l9.18-1.45c29.13-3.11,58.58-1.53,87.21,4.66
  c28.59,6.51,58.31,6.16,86.74-1c3.89,4.9,7.48,9.88,10.95,15.05c16.25,23.61,30,48.84,41.05,75.29
  c11.75,27.81,20.74,56.71,26.83,86.28c6.43,31.12,9.82,62.79,10.11,94.56C753.98,514.57,750.83,549.02,744.18,582.87z"/>
  <path class="st0" d="M633.6,254.38h-0.65c-21.5-2.04-42.54-7.53-62.29-16.27c-8.15-3.02-16.46-6.19-24.77-8.83
  c-14.47-4.84-29.5-7.77-44.73-8.74c-3.5-0.11-6.89-0.3-10.42-0.3c-10.82-0.97-21.62,2.16-30.23,8.79
  c-2.3-11.06-5.41-21.94-9.32-32.55c-4.04-10.24-10.2-22.48-21.11-25.8c-1.77-0.55-3.61-0.82-5.45-0.8
  c-9.7,1.34-18.23,7.09-23.12,15.57l-1.87,2.66c-4.76,6.84-7.27,10.22-6.44,13.47c0.83,3.25,4.71,5.69,13.54,10.77
  c3.82,2.2,4.5,7.74,5.05,11.89v0.47c0.81,5.93,1.2,11.91,1.19,17.9c-13.88-11.86-31.01-19.28-49.16-21.28
  c-4.79-0.9-9.66-1.36-14.54-1.38c-18.18,0.87-36,5.32-52.45,13.1c-7.32,3.24-14.7,6.67-21.73,10.16
  c-13.71,6.94-27.9,12.88-42.47,17.76c-4.93,1.49-9.95,2.67-15.03,3.53h-0.44c-0.09,0.06-0.2,0.09-0.3,0.08l-0.43,0.1
  c-2.27,0.39-6.09,1.59-6.8,5.01c-0.71,3.42-0.24,5.7,4.39,9.32c12.26,6.44,25.99,9.55,39.83,9.03c3.31,0,6.67-0.08,10.35-0.36
  c4.8-0.21,9.66-0.87,14.81-1.59c7.34-1.09,14.73-1.72,22.15-1.88c4.52-0.02,9.02,0.45,13.44,1.38
  c11.89,2.91,18.97,14.55,25.13,25.06c1.62,2.62,3.03,5.08,4.56,7.31l0.15,0.22c3.96,4.91,6.52,10.81,7.39,17.05
  c-0.26,5.33-7.61,9.65-12.89,12.92c-1.36,0.89-2.8,1.72-3.84,2.37c-1.65,1.2-3.67,2.46-5.7,3.85
  c-11.95,7.59-28.34,17.95-26.41,31.98c1.01,7.02,6.64,12.48,13.69,13.28c0.63,0.11,1.26,0.15,1.9,0.13
  c4.86-0.4,9.56-1.92,13.73-4.44c1.79-1.04,3.63-1.96,5.54-2.77c14.88-6.32,32.03-3.93,44.62,6.22c2.75,2.18,5.34,4.55,7.75,7.1
  c2.62,2.81,5.48,5.37,8.57,7.66c3.19,2.27,6.95,3.59,10.85,3.81c3.45,0.37,6.61-1.96,7.27-5.37c1.13-7.03,1.56-14.15,1.3-21.26
  c-0.19-5.66,0.01-11.34,0.61-16.97c0.44-3.42,1.4-10.34,5.79-12.46c1.61-0.75,3.38-1.1,5.15-1.02c2.32,0.08,4.63,0.32,6.92,0.72
  c3,0.7,6.1,0.9,9.16,0.61c13.97-2.94,24.93-13.76,28.06-27.69c1.72-4.59,5.7-7.95,10.51-8.89c5.95-2.13,10.96-6.29,14.15-11.74
  c0.98-1.26,1.99-2.49,2.92-3.67c7.63-9.28,19.26-14.33,31.26-13.57c2.49,0,5.26,0.11,8.31,0.37c2.77,0.29,5.54,0.61,8.31,0.97
  c7.08,0.94,14.21,1.48,21.35,1.62c7.04,0.11,14.06-0.92,20.76-3.07c16.15-6.22,31.04-15.28,43.98-26.77l1.92-1.52
  c3.75-3.09,4.53-5.05,3.6-8.62C640.02,255.39,635.39,254.58,633.6,254.38z M351.28,303.05c-3.11-5.56-6.68-10.85-10.69-15.81v-0.01
  c-10.16-11.93-25.48-18.18-41.09-16.75c-7.84,0-16.2,0.94-24.31,1.76c-7.94,0.99-15.92,1.56-23.92,1.7
  c-3.75,0.02-7.5-0.21-11.21-0.71c13.48-4.79,26.64-10.42,39.41-16.87c13.66-7.03,27.84-12.99,42.42-17.84
  c9.12-2.87,18.63-4.32,28.2-4.29c16.67-0.25,33.05,4.43,47.07,13.46c9.89,4.35,14.54,15.75,10.52,25.78
  c-4.56,12.41-11.48,23.83-20.38,33.61c-0.71,0.66-1.37,1.54-2.17,2.42c-4.14,4.96-11.1,13.26-17,13.26
  c-1.86-0.01-3.61-0.86-4.76-2.33C358.77,315.05,354.72,309.23,351.28,303.05z M462.1,319.13c-0.9,8.74-4.79,16.9-11.01,23.1
  c-5.11,4.29-11.73,6.34-18.37,5.68h-3.82c-1.36,0-2.74,0-4.29,0c-4.43-0.13-8.72,1.56-11.88,4.68c-9.11,9.25-8.92,29.43-8.8,42.8
  v4.29l-0.91-0.87c-10.05-11.6-23.52-19.71-38.47-23.17c-2.54-0.46-5.12-0.68-7.7-0.68c-5.18,0.01-10.35,0.64-15.38,1.9
  c-4.38,1.12-8.62,2.73-12.64,4.79c-1.69,0.68-3.11,1.44-4.8,2.16c-1.96,0.85-4.04,1.39-6.16,1.62c-0.36,0.03-0.72-0.07-1.01-0.28
  c-0.09-0.3-0.09-0.62,0-0.93c1.48-5.41,7.5-9.34,12.2-12.46l1.22-0.83c12.02-8.1,23.34-16.27,34.5-24.92
  c23.05-15.87,41.64-37.38,53.99-62.49c6.47-15.92,9.16-33.12,7.84-50.25v-1.01c-0.03-7.36-1.34-14.65-3.89-21.55
  c-2.43-4.79-6.42-8.59-11.32-10.78c-0.91-0.55-1.72-1.05-2.55-1.52c2.19-4.06,8.11-14.63,15.03-14.63
  c3.15,0.33,5.95,2.14,7.55,4.87c7.17,11.07,12.28,23.35,15.09,36.24c0.58,1.95,1.19,4.18,1.76,6.53
  C454.8,254.17,465.54,292.16,462.1,319.13z M601.55,275.99c-9.19,5.75-19.72,8.98-30.55,9.37h-0.86
  c-6.17-0.3-12.32-0.91-18.43-1.83c-5.18-0.68-10.48-1.34-15.71-1.62c-10.85-1.05-21.77,1.18-31.34,6.41
  c-5.19,3.44-9.71,7.79-13.35,12.85c-2.7,3.51-5.74,6.76-9.07,9.69c-0.97,0.97-2.23,1.6-3.59,1.81h-0.1
  c-1.54-0.07-1.68-1.02-1.54-3.18v-1.38c-0.22-4.93-0.93-9.79-1.61-14.51l-0.4-2.64c-1.14-8.22-2.35-16.97-4.42-25.53
  c-0.34-1.76-0.85-3.48-1.51-5.15c-2.31-7.53-5.29-16.9,0.55-21.68c4.11-2.57,8.88-3.89,13.73-3.81c0,0,4.44-0.48,9.47-0.61h1.86
  c5.58,0.01,11.15,0.38,16.68,1.11c11.7,1.73,23.21,4.54,34.39,8.42c7.06,2.34,14.12,5.02,21.04,7.57
  c16,6.5,32.56,11.52,49.48,15.02C611.57,269.8,606.64,273.04,601.55,275.99z"/>
  </g>
</svg>
`;
