import React, { FunctionComponent, MouseEventHandler } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';
import { grey, primary } from 'src/theme/colors';
import { Typography } from '@material-ui/core';

type ConfirmDialogProps = {
  title: React.ReactNode;
  content: React.ReactNode;
  secondLine?: React.ReactNode;
  cancelText: React.ReactNode;
  confirmText: React.ReactNode;
  open: boolean;
  onCancel: MouseEventHandler;
  onConfirm: MouseEventHandler;
  customTheme: { [k: string]: any } | null;
  backText?: string;
  onBack?: () => void;
};

const styles = {
  title: {
    color: grey.medium,
    fontFamily: 'Open Sans',
    fontSize: 16,
    fontStyle: 'normal',
    fontWeight: 600,
    letterSpacing: 0,
  },
};

const dummyFunc = () => {};

const ConfirmDialog: FunctionComponent<ConfirmDialogProps> = ({
  title = 'Confirmation Dialog',
  content = 'Are you sure of perform this action?',
  secondLine = '',
  cancelText,
  confirmText = 'Confirm',
  open = false,
  onCancel = dummyFunc,
  onConfirm = dummyFunc,
  customTheme = null,
  backText = null,
  onBack = dummyFunc,
}) => {
  const dialog = (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Typography style={{ ...styles.title }}>{title}</Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
        {content ? (
          <DialogContentText id="alert-dialog-description">
            {secondLine}
          </DialogContentText>
        ) : null}
      </DialogContent>
      <DialogActions>
        {backText && (
          <Button
            onClick={onBack}
            variant="text"
            style={{
              textTransform: 'none',
              color: primary.paleBlueStrong,
            }}
          >
            {backText}
          </Button>
        )}
        {cancelText && (
          <Button
            onClick={onCancel}
            variant="text"
            style={{
              textTransform: 'none',
              color: primary.paleBlueStrong,
            }}
          >
            {cancelText}
          </Button>
        )}
        {confirmText && (
          <Button
            onClick={onConfirm}
            color="primary"
            variant="contained"
            autoFocus
            style={{ textTransform: 'none' }}
          >
            {confirmText}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
  return !customTheme ? (
    dialog
  ) : (
    <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>
  );
};

export default ConfirmDialog;
