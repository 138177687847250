import React from 'react';
import { FormRadio, useFormContext } from 'src/lib/templates';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { ButtonTypesProps, MainButton } from 'src/commons';
import { Loading } from '../..';

const useStyles = makeStyles((theme) => ({
  centered: {
    textAlign: 'center',
  },
  spaced: {
    margin: '0 10px',
  },
}));

export const NivelesForm = () => {
  const classes = useStyles();
  const { form, handleSubmit, submitting } = useFormContext();
  const submitProps: ButtonTypesProps = {
    title: 'Ingresar',
    handleOnClick: handleSubmit,
    disabled: false,
  };

  return submitting ? (
    <Loading />
  ) : (
    <Grid container>
      <Grid container alignItems="center" justify="center">
        <Grid
          item
          classes={{
            item: `${classes.centered} ${classes.spaced}`,
          }}
        >
          <FormRadio value="1" form={form} name="nivel" />
          <Typography
            classes={{
              h4: classes.centered,
            }}
            variant="h4"
          >
            Inicial
          </Typography>
        </Grid>
        <Grid
          item
          classes={{
            item: `${classes.centered} ${classes.spaced}`,
          }}
          justify="center"
        >
          <FormRadio value="2" form={form} name="nivel" />
          <Typography
            classes={{
              h4: classes.centered,
            }}
            variant="h4"
          >
            Primario
          </Typography>
        </Grid>
        <Grid
          item
          classes={{
            item: `${classes.centered} ${classes.spaced}`,
          }}
        >
          <FormRadio value="3" form={form} name="nivel" />
          <Typography
            classes={{
              h4: classes.centered,
            }}
            variant="h4"
          >
            Secundario
          </Typography>
        </Grid>
      </Grid>
      <Grid container xs justify="flex-end" style={{ paddingTop: 20 }}>
        <Grid item>
          <MainButton {...submitProps} />
        </Grid>
      </Grid>
    </Grid>
  );
};
