import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { ArealTempPlan } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'areal_temp_plan';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idArealTempPlan',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idArealTempPlan',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.ArealTempPlan
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.ArealTempPlan,
      hoc: { ...defaultOptions.hoc, ...businessOptions.ArealTempPlan.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<ArealTempPlan>(DAO_NAME, optionsToUse);

// hooks
export const useArealTempPlanFetchById = fetchById;
export const useArealTempPlanLibbyCall = libbyCall;
export const useArealTempPlanDAO = libbyDAO;
export const useArealTempPlanLibbyFetch = libbyFetch;

// components/hoc
export const ArealTempPlanAutocomplete = entityAutocomplete;
export const ArealTempPlanCall = renderPropCall;
export const ArealTempPlanById = renderPropFetchById;
export const ArealTempPlanSelect = simpleSelect;
export const ArealTempPlanAccordionSelector = accordionSelector;
export const ArealTempPlanSelectedChips = selectedChips;

// context
// ByIdContext
export const useArealTempPlanByIdContext = useByIdContext;
export const ArealTempPlanByIdProvider = ByIdProvider;
export const ArealTempPlanByIdContext = ByIdContext;
// GeneralContext
export const useArealTempPlanContext = useGeneralContext;
export const ArealTempPlanProvider = GeneralProvider;
export const ArealTempPlanContext = GeneralContext;
