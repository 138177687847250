import React, { MouseEventHandler } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { ThemeProvider } from '@material-ui/styles';
import Button from '@material-ui/core/Button';

type ConfirmDialogProps = {
  title: React.ReactNode;
  content: React.ReactNode;
  cancelText: React.ReactNode;
  confirmText: React.ReactNode;
  open: boolean;
  onCancel: MouseEventHandler;
  onConfirm: MouseEventHandler;
  customTheme: { [k: string]: any } | null;
};

const dummyFunc = () => {};

export const ConfirmDialog = ({
  title = 'Confirmation Dialog',
  content = 'Are you sure of perform this action?',
  cancelText = 'Cancel',
  confirmText = 'Confirm',
  open = false,
  onCancel = dummyFunc,
  onConfirm = dummyFunc,
  customTheme = null,
}: ConfirmDialogProps) => {
  const dialog = (
    <Dialog
      open={open}
      onClose={onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} variant="contained">
          {cancelText}
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          variant="contained"
          autoFocus
        >
          {confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  );
  return !customTheme ? (
    dialog
  ) : (
    <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>
  );
};
