import { DAOCommand } from '@phinxlab/libby-rest-web';
import { LibbyFetchDAO } from '../LibbyFetchDAO';
import { ApreciacionesActividades } from 'src/app/models/acap/ApreciacionesActividades';

export class ApreciacionActividadDAO extends LibbyFetchDAO {
  constructor() {
    super('acap.apreciaciones_actividades', 'idApreciacionActividad');
  }

  async save(form: Acap.Estudiante.ApreciacionForm): Promise<void> {
    const { evaluaciones, ...apreciacion } = form;

    return await super.save(apreciacion);
  }

  done(command: typeof DAOCommand, data: ApreciacionesActividades) {
    this.workers--
    command.done(data)
  }
}
