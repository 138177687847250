import styled from 'styled-components';
import { primary } from 'src/theme/colors';
import { nubesBackground } from 'src/assets/images';

export const ParentContainer = styled.div`
  background: linear-gradient(180deg, #417ab6 0%, #6aa7d0 77.08%, #86c6e3 100%);
`;

export const Container = styled.div`
  @media (max-width: 800px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  display: flex;
  justify-content: space-between;
`;

export const LogoBienvenida = styled.div`
  @media (max-width: 960px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  @media (max-width: 1280px) {
    width: 80%;
  }
  width: 65%;
  background-image: url(${nubesBackground.nubes});
  background-position: 0% 70%;
  background-size: cover;
  padding: 30px;
`;

export const BuenosAiresCiudadContainer = styled.div`
  padding-left: 30px;
  @media (max-width: 800px) {
    padding: 0;
  }
`;

export const IconContainer = styled.div`
  padding-left: 40px;
  margin-top: 10px;
  @media (max-width: 800px) {
    padding: 0;
  }
`;

export const DescriptionTextTitle = styled.h2`
  font-size: 30px;
  font-family: 'Open Sans';
  font-weight: 600;
  padding-left: 30px;
  color: ${primary.white};
  margin-bottom: 10px;
  @media (max-width: 800px) {
    padding: 0;
  }
`;

export const DescriptionText = styled.p`
  font-size: 15px;
  font-family: 'Open Sans';
  font-weight: 600;
  padding-left: 30px;
  color: ${primary.white};
  @media (max-width: 800px) {
    padding: 0;
  }
`;

export const FormContainer = styled.div`
  @media (max-width: 960px) {
    display: flex;
    justify-content: center;
    margin: 0;
    width: 100%;
  }
  @media (max-width: 960px) {
    width: 30%;
  }
  @media (max-width: 1280px) {
    margin-top: 15%;
    width: 40%;
  }
  @media (min-width: 1280px) {
    width: 40%;
  }
  @media (min-width: 1600px) {
    width: 21%;
  }
  margin-top: 7%;
`;
