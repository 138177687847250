import { LibbyFetchDAO } from './LibbyFetchDAO';

export class MedioContactoDAO extends LibbyFetchDAO {
  constructor() {
    super('medio_contacto', 'idMedioContacto');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByPersonaId(idsPersona: any) {
    // eslint-disable-next-line
    const query = this.query();
    idsPersona.forEach((idPersona: any, index: number) => {
      query.equals('persona', idPersona);
      idsPersona.length - 1 !== index && query.or();
    });
    return query.limit(0, 10000).run();
  }
}
