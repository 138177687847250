import { CommonEndpointDAO } from '../CommonEndpointDAO';

type PostDataObject = {
  seccion: string;
  alumno: string;
  turno?: string;
  bimestre: number;
};

export class ReportePresentismoDAO extends CommonEndpointDAO {
  constructor() {
    super('presentismo.reporte');
  }

  download(data: PostDataObject) {
    return super.post(data);
  }
}
