import React, { useCallback, useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Grid } from '@material-ui/core';
import {
  AnyObject,
  ExternalSelectTypesProps,
  SelectStudentTypes,
  useDebounce,
} from 'src/commons';
import { MainInput, SimpleSelect } from 'src/commons/components';
import { selectColumns } from 'src/screens/Private/Matriculation/passManagement/utils/const';
import { StudentSelectTable } from './StudentSelectTable';

const customStyles = {
  container: { padding: '24px', paddingTop: '0px' },
};

const ExternalSelectStudentRaw = ({
  setSelectStudent,
  selectStudent,
  localizacionId,
  handleAddExternalAlumn,
}: ExternalSelectTypesProps) => {
  const [formValues, setFormValues] = useState({
    select: '1',
    params: '',
    path: 'alumno.persona.apellido',
  });
  const [search, setSearch] = useState('');

  const searchDevounced = useDebounce(search, 500);

  useEffect(() => {
    setFormValues({
      ...formValues,
      params: searchDevounced,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDevounced]);

  const handleSelectStudent = useCallback(
    ({
      alumno: {
        idAlumno,
        persona: { idPersona, documento, nombre, apellido },
      },
      localizacion: { idLocalizacion, descripcion },
      nivel: { idNivel, descripcionNivel },
    }: AnyObject) => {
      const selectStudentCheckbox: SelectStudentTypes = {
        alumno: idAlumno,
        persona: idPersona,
        localizacion: localizacionId,
        localizacionMatriculadoEn: idLocalizacion,
        nivel: idNivel,
        createdAt: moment().toISOString(),
        preview: {
          id: idAlumno,
          name: `${nombre} ${apellido}`,
          document: documento,
          establishment: descripcion,
          level: descripcionNivel,
        },
      };
      setSelectStudent(selectStudentCheckbox);
    },
    [setSelectStudent, localizacionId],
  );

  const handleChangeSelect = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    const pathValue: any = selectColumns.filter(
      ({ value }: any) => value === target.value,
    );

    setFormValues({
      ...formValues,
      select: target.value,
      path: pathValue.length && pathValue[0].path,
    });
  };

  const handleChangeParams = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setSearch(target.value);
  };

  return (
    <Grid container style={customStyles.container} spacing={2}>
      <Grid item xs={4}>
        <SimpleSelect
          placeholder=""
          labelKey="label"
          valueKey="value"
          handleChange={handleChangeSelect}
          value={formValues.select}
          name="column"
          content={selectColumns}
        />
      </Grid>
      <Grid item xs={8}>
        <MainInput
          type="search"
          placeholder=""
          name="search"
          handleChange={handleChangeParams}
          value={search}
          fullWidth
          customStyle={{ padding: 9.5 }}
        />
      </Grid>
      <Grid item xs={12}>
        {formValues.params && (
          <StudentSelectTable
            localizacionId={localizacionId}
            formValues={formValues}
            handleAddExternalAlumn={handleAddExternalAlumn}
            handleSelectStudent={handleSelectStudent}
            selectStudent={selectStudent}
          />
        )}
      </Grid>
    </Grid>
  );
};

export const ExternalSelectStudent = DatabaseConnector(
  ExternalSelectStudentRaw,
)('inscripcion_alumno');
