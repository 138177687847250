import { CommonEndpointDAO } from '../CommonEndpointDAO';

export class PresentesStudentCustomDAO extends CommonEndpointDAO {
  constructor() {
    super('presentes_student');
  }

  get(params: any = {}): any {
    return super.get(params);
  }
}
