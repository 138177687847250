import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { Comentario } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'comentario';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idComentario',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idComentario',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Comentario
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Comentario,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Comentario.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Comentario>(DAO_NAME, optionsToUse);

// hooks
export const useComentarioFetchById = fetchById;
export const useComentarioLibbyCall = libbyCall;
export const useComentarioDAO = libbyDAO;
export const useComentarioLibbyFetch = libbyFetch;

// components/hoc
export const ComentarioAutocomplete = entityAutocomplete;
export const ComentarioCall = renderPropCall;
export const ComentarioById = renderPropFetchById;
export const ComentarioSelect = simpleSelect;
export const ComentarioAccordionSelector = accordionSelector;
export const ComentarioSelectedChips = selectedChips;

// context
// ByIdContext
export const useComentarioByIdContext = useByIdContext;
export const ComentarioByIdProvider = ByIdProvider;
export const ComentarioByIdContext = ByIdContext;
// GeneralContext
export const useComentarioContext = useGeneralContext;
export const ComentarioProvider = GeneralProvider;
export const ComentarioContext = GeneralContext;
