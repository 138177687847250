import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { ROL, SimpleSelect } from 'src/commons';

import { useGetRows } from '../../../../hooks';
import { useJornadaExtendidaLibbyFetch } from 'src/app/business/Calificaciones/JornadaExtendida';
import { isEqual } from 'lodash';
import { setSearchParamsMultipleWords } from 'src/utils/setSearchParamsMultipleWords';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
import { JornadaExtendida } from 'src/app/models';
import { useRolesContext } from 'src/context/RolesContext';
import { Docente } from '../../../../../../../assets/images/svgs/Docente';

export type objTable = {
  id: string;
  nombre: string;
  tipo: string;
  seccion: string;
  bimestre: string;
};

const booleanMock = [
  { descripcion: 'No', id: 0 },
  { descripcion: 'Sí', id: 1 },
];

const opcionesMock = [
  { descripcion: '-' },
  { descripcion: 'Insuficiente (I)' },
  { descripcion: 'Regular (R)' },
  { descripcion: 'Bueno (B)' },
  { descripcion: 'Muy Bueno (MB)' },
  { descripcion: 'Sobresaliente (S)' },
];

let calificacionGeneralStringMock = [
  { descripcion: '-' },
  { descripcion: 'Insuficiente (I)' },
  { descripcion: 'Regular (R)' },
  { descripcion: 'Bueno (B)' },
  { descripcion: 'Muy Bueno (MB)' },
  { descripcion: 'Sobresaliente (S)' },
];

const areaDeConocimientoStyle = makeStyles(() => ({
  selectSize: {
    minWidth: 150,
  },
}));

export const useJornadaExtendidaRows = (
  filter: any,
  columns: any,
  docenteJE: boolean,
) => {
  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();

  const {
    selectedRole: { rol },
  } = useRolesContext();

  const directivo = Number(rol) === ROL.DIRECTIVO;
  const equipoConduccion = Number(rol) === ROL.EQUIPO_CONDUCCION;
  const supervisor = Number(rol) === ROL.SUPERVISOR;
  const direccionArea = Number(rol) === ROL.DIRECCION_AREA;
  const equipoAcap = Number(rol) === ROL.EQUIPO_ACAP;
  const preceptor = Number(rol) === ROL.PRECEPTOR;
  const docenteComun = Number(rol) === ROL.DOCENTE && !docenteJE;

  const rolJerarquico =
    directivo ||
    equipoConduccion ||
    supervisor ||
    direccionArea ||
    equipoAcap ||
    preceptor ||
    docenteComun;

  useEffect(() => {
    if (!establecimientoEfectivizado)
      calificacionGeneralStringMock = calificacionGeneralStringMock.filter(
        (item) => item.descripcion !== '-',
      );
  }, [establecimientoEfectivizado]);

  const [readyToClose, setReadyToClose] = useState(false);
  const classes = areaDeConocimientoStyle();
  const filterParams = useMemo(() => {
    let filtersWithSearch;
    const filters = {
      0: [{ path: 'seccion', value: filter.seccion }],
      1: [{ path: 'calificacion.periodo', value: filter.periodo }],
      2: [{ path: 'jemateria', value: filter.jemateria }],
    };

    return filters;
  }, [filter.jemateria, filter.periodo, filter.seccion]);

  const fetchResponse = useJornadaExtendidaLibbyFetch({
    aspect: 'default',
    filter: filterParams,
    enabled:
      Boolean(filter.seccion) &&
      Boolean(filter.jemateria) &&
      Boolean(filter.periodo),
    limit: 100,
    checkDuplicationDeep: true,
  });

  const { data = [] } = fetchResponse;

  const {
    rows: _rows,
    changeRow,
    working,
    reFetch,
    hasCalifications,
  } = useGetRows<JornadaExtendida>(fetchResponse, filter.search);

  const isClosed = useMemo(
    () => _rows.some((row) => row.nota?.abierto === false),
    [_rows],
  );

  useEffect(() => {
    const submitRows = _rows.filter((row) => {
      let isUpdated = false;
      const currentCalification = data?.find(
        (d: any) => d.idCalificacion === row.idCalificacion,
      );
      if (currentCalification?.nota) {
        isUpdated = !isEqual(currentCalification?.nota?.data, row.nota.data);
      } else if (Object.keys(row.nota.data).length) {
        isUpdated = true;
      }
      return isUpdated;
    });
    setReadyToClose(!submitRows.length);
  }, [_rows, data]);

  const makeRows = useCallback(
    (row: any, index: number, isDisabled: boolean) => {
      let rows: any = {};
      columns.forEach(({ id, label }) => {
        if (id === 'alumno' || id === 'ppi' || id === 'calificacionGeneral')
          return;
        rows[id] = (
          <Grid className={classes.selectSize}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={opcionesMock}
              value={row.nota?.data[id]}
              name={id}
              key={`${id}-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled || rolJerarquico}
            />
          </Grid>
        );
      });

      return rows;
    },
    [changeRow, classes.selectSize, columns, rolJerarquico],
  );

  const rows = useMemo(() => {
    const isDisabled = _rows.some(
      (c) => c.nota?.abierto !== undefined && c.nota.abierto === false,
    );

    return _rows.map((row, index: number) => {
      return {
        alumno: `${row?.apellido}, ${row?.nombre}`,
        ppi: (
          <Grid className={classes.selectSize}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={booleanMock}
              value={row.nota?.data?.ppi}
              name="ppi"
              key={`ppi-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled || rolJerarquico}
            />
          </Grid>
        ),
        ...makeRows(row, index, isDisabled),
        calificacionGeneral: (
          <Grid className={classes.selectSize}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={calificacionGeneralStringMock}
              value={row.nota?.data?.calificacionGeneral}
              name="calificacionGeneral"
              key={`calificacionGeneral-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled || rolJerarquico}
            />
          </Grid>
        ),
      };
    });
  }, [_rows, classes.selectSize, rolJerarquico, makeRows, changeRow]);

  return {
    rows,
    working,
    _rows,
    data,
    reFetch,
    isClosed,
    readyToClose,
    hasCalifications,
  };
};
