import React, { useCallback, useMemo, useState } from 'react';
import {
  useGruposInscripcionesDAO,
  useGruposInscripcionesLibbyCall,
} from 'src/app/business/acap/GruposInscripciones';
import { InfoTable, useRouteScreenTitle } from 'src/commons';
import { useRolesContext } from 'src/context/RolesContext';
import { useInscripcionesColumns } from '../hooks/useInscripcionesColumns';
import { FiltersHeaderInscripciones } from '../components/FiltersHeaderInscripciones';
import { InscripcionDetalle } from '../components/InscripcionDetalle';
import { Typography } from '@material-ui/core';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useSnackbar } from 'notistack';
import { cloneDeep, get } from 'lodash';
import { TablePagination } from '@material-ui/core';
import { DetallesInscripto, GruposInscripcionAcumulate } from '../types';
import { useColegioEfectivizadoContext } from '../../Calificaciones/context/EfectivizacionProvider';

const initialFilter = {
  orientacion: '',
  tipoAccion: '',
  turnoOferta: '',
  turnoSeccion: '',
  sector: '',
  organizacion: '',
  accion: '',
  seccion: '',
  cicloLectivo: 0,
};

export type InscripcionesFilter = typeof initialFilter;

export const Inscripciones = ({
  editGroup,
}: {
  editGroup: (row: GruposInscripcionAcumulate) => void;
}) => {
  useRouteScreenTitle('Actividades de aproximación');

  const { proyeccionEstado } = useColegioEfectivizadoContext();

  const [filters, setFilters] = useState({
    ...initialFilter,
    cicloLectivo: proyeccionEstado?.cicloLectivo.idCicloLectivo!,
  });
  const [detalleStep, setDetalleStep] = useState<DetallesInscripto>({
    enabled: false,
    row: [],
  });

  const [deleting, setDeleting] = useState(false);
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const { enqueueSnackbar } = useSnackbar();
  const GruposInscripcionesDAO = useGruposInscripcionesDAO();

  const {
    selectedRole: { rol },
  } = useRolesContext();
  const [direction, setDirection] = useState<'asc' | 'desc'>('desc');
  const [orderBy, seOrderBy] = useState<string>(
    'ofertaGrupo.ofertaTurno.oferta.accion.organizacion.nombre',
  );

  const filterGruposInscripciones = useMemo(() => {
    const _filter = {
      0: Boolean(filters.orientacion)
        ? [
            {
              path: 'ofertaGrupo.ofertaTurno.oferta.accion.accionOrientaciones.orientacion.idOrientacion',
              value: filters.orientacion,
            },
          ]
        : [],
      1: Boolean(filters.tipoAccion)
        ? [
            {
              path: 'ofertaGrupo.ofertaTurno.oferta.accion.tipoAccion',
              value: filters.tipoAccion,
            },
          ]
        : [],
      2: Boolean(filters.turnoOferta)
        ? [
            {
              path: 'ofertaGrupo.ofertaTurno.turno',
              value: filters.turnoOferta,
            },
          ]
        : [],
      3: Boolean(filters.sector)
        ? [
            {
              path: 'ofertaGrupo.ofertaTurno.oferta.accion.organizacion.sector',
              value: filters.sector,
            },
          ]
        : [],
      4: Boolean(filters.organizacion)
        ? [
            {
              path: 'ofertaGrupo.ofertaTurno.oferta.accion.organizacion',
              value: filters.organizacion,
            },
          ]
        : [],
      5: Boolean(filters.seccion)
        ? [{ path: 'alumnoMovimiento.seccion', value: filters.seccion }]
        : [],
      6: Boolean(filters.turnoSeccion)
        ? [
            {
              path: 'alumnoMovimiento.seccion.turno',
              value: filters.turnoSeccion,
            },
          ]
        : [],
      7: Boolean(filters.accion)
        ? [
            {
              path: 'ofertaGrupo.ofertaTurno.oferta.accion',
              value: filters.accion,
            },
          ]
        : [],
      8: Boolean(filters.cicloLectivo)
        ? [
            {
              path: 'ofertaGrupo.ofertaTurno.oferta.accion.cicloLectivo',
              value: filters.cicloLectivo,
            },
          ]
        : [],
    };

    return {
      filter: _filter,
      limit: 1000,
      orderBy: 'ofertaGrupo.ofertaTurno.oferta.accion.nombre',
      aspect: 'tab-inscripciones',
    };
  }, [filters]);

  const {
    data: gruposInscripciones = [],
    working: workingGruposInscripciones,
    recall: reCallGruposInscripciones,
  } = useGruposInscripcionesLibbyCall({
    methodName: 'fetch',
    aspect: 'tab-inscripciones',
    params: [filterGruposInscripciones],
  });

  const recallInscriptos = useCallback(() => {
    reCallGruposInscripciones(filterGruposInscripciones);
  }, [reCallGruposInscripciones, filterGruposInscripciones]);

  const handleDelete = useCallback(
    async (row: GruposInscripcionAcumulate) => {
      const confirm = await confirmDialog.show({
        title: 'Eliminación de inscripción a la oferta',
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        content:
          '¿Esta seguro que desea eliminar esta inscripción a la oferta?',
      });
      if (confirm) {
        setDeleting(true);
        try {
          for (const id of row.idsGrupoInscripcion) {
            await GruposInscripcionesDAO.remove({
              idGrupoInscripcion: id,
            });
          }
          enqueueSnackbar('Se ha eliminado con exito', {
            variant: 'success',
          });
          recallInscriptos();
        } catch (e) {
          console.log(e);
          enqueueSnackbar('Error al eliminar', {
            variant: 'error',
          });
        }
      }
      setDeleting(false);
    },
    [setDeleting, enqueueSnackbar, recallInscriptos, GruposInscripcionesDAO],
  );

  const columns = useInscripcionesColumns({
    detalleStep,
    setDetalleStep,
    editGroup,
    handleDelete,
    gruposInscripcionesTotal: gruposInscripciones,
    rol,
  });

  const gruposInscripcionesFormatted = useMemo(
    () =>
      gruposInscripciones.reduce<GruposInscripcionAcumulate[]>(
        (acc, grupoInscripcion) => {
          const index = acc.findIndex(
            (grupo) =>
              grupoInscripcion?.ofertaGrupo?.idOfertaGrupo ===
              grupo?.ofertaGrupo?.idOfertaGrupo,
          );
          if (index === -1)
            acc.push({
              ...grupoInscripcion,
              alumnoMovimientos: [grupoInscripcion?.alumnoMovimiento],
              idsGrupoInscripcion: [grupoInscripcion?.idGrupoInscripcion],
            });
          else
            acc[index] = {
              ...acc[index],
              alumnoMovimientos: [
                ...acc[index]?.alumnoMovimientos,
                grupoInscripcion?.alumnoMovimiento,
              ],
              idsGrupoInscripcion: [
                ...acc[index]?.idsGrupoInscripcion,
                grupoInscripcion?.idGrupoInscripcion,
              ],
            };
          return acc;
        },
        [],
      ),
    [gruposInscripciones],
  );

  const totalHorasACAP = useMemo(() => {
    let totalHoras = 0;
    gruposInscripcionesFormatted.forEach((gi) => {
      totalHoras += gi.ofertaGrupo?.ofertaTurno?.oferta?.accion?.horas || 0;
    });
    return totalHoras;
  }, [gruposInscripcionesFormatted]);

  const gruposSeleccionesPaginadas = useMemo(
    () => gruposInscripcionesFormatted.slice(offset, limit + offset),
    [gruposInscripcionesFormatted, offset, limit],
  );

  const gruposInscripcionesFormattedOrderer = useMemo(
    () =>
      cloneDeep(gruposSeleccionesPaginadas).sort(
        (ofertaSeleccionA, ofertaSeleccionB) => {
          const nombreOrganizacionA = get(
            ofertaSeleccionA,
            orderBy,
          ).toLowerCase();
          const nombreOrganizacionB = get(
            ofertaSeleccionB,
            orderBy,
          ).toLowerCase();
          if (nombreOrganizacionA < nombreOrganizacionB) {
            return direction === 'desc' ? -1 : 1;
          }
          if (nombreOrganizacionA > nombreOrganizacionB) {
            return direction === 'desc' ? 1 : -1;
          }
          return 0;
        },
      ),
    [direction, orderBy, gruposSeleccionesPaginadas],
  );

  const handleRequestSort = useCallback(
    (orderBy: string, _direction: 'asc' | 'desc') => {
      setDirection(_direction);
      seOrderBy(orderBy);
    },
    [setDirection, seOrderBy],
  );

  return detalleStep.enabled ? (
    <InscripcionDetalle
      detalleStep={detalleStep}
      setDetalleStep={setDetalleStep}
      columns={columns}
    />
  ) : (
    <>
      <FiltersHeaderInscripciones
        setFilters={setFilters}
        filters={filters}
        working={workingGruposInscripciones || deleting}
        searchInscripcion={() => recallInscriptos()}
        gruposInscripcionesOrgAcc={gruposInscripciones}
      />
      <Typography style={{ margin: '30px 0' }}>
        <b>Total horas ACAP:</b> {totalHorasACAP}
      </Typography>
      <InfoTable
        rows={
          deleting || workingGruposInscripciones
            ? []
            : gruposInscripcionesFormattedOrderer
        }
        columns={columns}
        working={workingGruposInscripciones || deleting}
        direction={direction}
        onSortChange={handleRequestSort}
      />
      {gruposInscripcionesFormatted.length > 0 &&
        !(deleting || workingGruposInscripciones) && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={gruposInscripcionesFormatted.length}
            rowsPerPage={limit}
            page={offset / limit}
            onPageChange={(e, page) => {
              let offset = 0;
              if (page < 0) offset = 0 * limit;
              else offset = page * limit;
              setOffset(offset);
            }}
            onRowsPerPageChange={(e) => {
              setLimit(parseInt(e.target.value));
            }}
            labelRowsPerPage="Filas por página"
            labelDisplayedRows={({ from, to, count }) => {
              return from + '-' + to + ' de ' + count;
            }}
          />
        )}
    </>
  );
};
