import { useHabilidadesLibbyCall } from 'src/app/business';
import { Habilidades } from 'src/app/models';
import { makeEntityByEspacio } from '../../../commons/hooks';
import { useArealInterarealContext } from '../../context';

export const useHabilidadesFetch = () => {
  const { espacioCurricular } = useArealInterarealContext();

  const filterVoid = () => ({
    filter: {},
    limit: 20000,
  });

  const useHabilidadesDAO = makeEntityByEspacio<Habilidades[]>(
    useHabilidadesLibbyCall,
    filterVoid,
    true,
  );

  const { data: habilidades = [], working } =
    useHabilidadesDAO(espacioCurricular);

  return { habilidades, working };
};
