import React, { FC } from 'react';
import { useEspaciocurricularseccioncustomGetRequest } from 'src/app/business/businessCustomEndpoints/espaciocurricularseccioncustom';
import { useRolesContext } from 'src/context/RolesContext';
import { EspacioCurricularSeccion } from 'src/app/models';
import { Loading } from 'src/commons';
import { TomaPresentismo } from '../../../Presentismo/TomaPresentismo';
import { useECContext } from '../components/ECProvider';

export const TomaAsistenciaTabWrapper = () => {
  const { ec: espacioCurricular, ecsWorking } = useECContext();

  return (
    <>
      {ecsWorking ? (
        <Loading />
      ) : (
        <TomaPresentismo
          isTabJornadaExtendida={false}
          espacioCurricularSelected={espacioCurricular.idEspacioCurricular}
        />
      )}
    </>
  );
};
