import { ApreciacionActividadDAO } from 'src/platform/libby/dao';
import { makeBusinessEntity } from '../../../lib/libby/generator';
import { Apreciacion } from '../../models';

const DAO_NAME = 'acap.apreciaciones_actividades';

const {
  hook: { libbyDAO, libbyFetch },
} = makeBusinessEntity<Apreciacion, ApreciacionActividadDAO>(DAO_NAME, {});

// hooks;
export const useApreciacionActividadDAO = libbyDAO;
export const useApreciacionActividadLibbyFetch = libbyFetch;
