import React, {
  FunctionComponent,
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ConfirmDialog from 'src/commons/components/ConfirmDialog/ConfirmDialog';
import {
  useVinculoLibbyCall,
  useVinculoDAO,
  useMedioContactoLibbyFetch,
} from 'src/app/business';
import { Vinculo, MedioContacto } from 'src/app/models';
import { Loading } from 'src/commons/components/Loading';
import {
  ResponsablesCard,
  ResponsablesForm,
  AddResponsableModal,
} from './components';
import { useLegajoData, LegajoLayoutContext } from '../../index';

const useStyles = makeStyles({
  responsablesGrid: {
    marginTop: 10,
  },
  sinResponsables: {
    fontSize: 24,
    color: '#8c8c8c',
    margin: '20px 10px',
  },
});

export const Responsables: FunctionComponent = () => {
  const classes = useStyles();

  const vinculoDAO = useVinculoDAO();

  const {
    changeResponsableIds,
    setBeingSaved,
    saving,
    setIsNewResponsable,
    isNewResponsable,
    setIsEditResponsable,
    saveDone,
    setResponsableDatosSecundarios,
  } = useContext(LegajoLayoutContext);

  const [showForm, setShowForm] = useState<boolean>(false);
  const [editResponsableId, setEditResponsableId] = useState<
    string | undefined
  >(undefined);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
  const [addModalOpen, setAddModalOpen] = useState<boolean>(false);
  const [fetchedMedios, setFetchedMedios] = useState<boolean>(false);
  const [fetchedVinculos, setFetchedVinculos] = useState<boolean>(false);

  const { alumno, isHistoric } = useLegajoData();

  const {
    data: vinculos = [],
    working,
    recall: recallVinculos,
  } = useVinculoLibbyCall({
    methodName: 'getByAlumnoId',
    params: [Number(alumno?.idAlumno)],
    noAutoCall: !Boolean(alumno),
  });

  const filterContacto = useMemo(() => {
    return {
      personas: [
        {
          path: 'persona',
          value: vinculos.map((v) => v.responsable.persona.idPersona),
          method: 'in',
        },
      ],
    };
  }, [vinculos]);

  // ESTO NO ESTA LLENO NO TRAE NADA
  const { data: contactosResponsables = [], working: workingContacto } =
    useMedioContactoLibbyFetch({
      filter: filterContacto,
      enabled: vinculos.length > 0 || !working,
    });

  const onCancelEdit = useCallback(() => {
    setIsNewResponsable?.(false);
    setIsEditResponsable?.(false);
    changeResponsableIds?.(null);
    setResponsableDatosSecundarios?.(null);
    setShowForm(false);
    setEditResponsableId(undefined);
    // setFetchedMedios(false);
  }, [
    setIsNewResponsable,
    setIsEditResponsable,
    changeResponsableIds,
    setShowForm,
    setEditResponsableId,
    setResponsableDatosSecundarios,
  ]);

  useEffect(() => {
    if (saving && saveDone && !isNewResponsable) {
      setBeingSaved?.(false);
      onCancelEdit();
    }
  }, [
    saveDone,
    saving,
    setBeingSaved,
    setFetchedMedios,
    onCancelEdit,
    isNewResponsable,
  ]);

  const onEditResponsable = (id: string) => {
    const vinculoResult = vinculos.find(
      (vinculo: Vinculo) => vinculo.responsable.idResponsable === id,
    );

    const contactoEmail = contactosResponsables?.filter(
      (contacto) =>
        contacto.persona.idPersona ===
          vinculoResult?.responsable?.persona?.idPersona &&
        contacto.tipoMedioContacto.idTipoMedioContacto === 2,
    )?.[0];

    const email = contactoEmail?.descripcionMedioContacto;
    const idMedioContactoEmail = contactoEmail?.idMedioContacto;

    const contactoCelular = contactosResponsables?.filter(
      (contacto) =>
        contacto.persona.idPersona ===
          vinculoResult?.responsable?.persona?.idPersona &&
        contacto.tipoMedioContacto.idTipoMedioContacto === 1,
    )?.[0];

    const celular = contactoCelular?.descripcionMedioContacto;
    const idMedioContactoCelular = contactoCelular?.idMedioContacto;

    const contactoLinea = contactosResponsables?.filter(
      (contacto) =>
        contacto.persona.idPersona ===
          vinculoResult?.responsable?.persona?.idPersona &&
        contacto.tipoMedioContacto.idTipoMedioContacto === 3,
    )?.[0];

    const linea = contactoLinea?.descripcionMedioContacto;
    const idMedioContactoLinea = contactoLinea?.idMedioContacto;

    const idPersona = vinculoResult?.responsable?.persona?.idPersona;
    const idResponsable = vinculoResult?.responsable?.idResponsable;
    const nivelEducativo = vinculoResult?.responsable?.nEducativoAlcanzado;
    const tituloEducativoAlcanzado =
      vinculoResult?.responsable?.tituloEducativoAlcanzado;

    if (vinculoResult?.responsable?.persona?.idPersona)
      changeResponsableIds?.({
        responsableCustomData: {
          ...(email && { email }),
          ...(celular && { celular }),
          ...(linea && { linea }),
          ...(nivelEducativo && { nivelEducativo }),
          ...(tituloEducativoAlcanzado && { tituloEducativoAlcanzado }),
          ...(idPersona && { idPersona }),
          ...(idResponsable && { idResponsable }),
          ...(idMedioContactoEmail && { idMedioContactoEmail }),
          ...(idMedioContactoCelular && { idMedioContactoCelular }),
          ...(idMedioContactoLinea && { idMedioContactoLinea }),
        },
        responsablePersona: vinculoResult?.responsable?.persona,
        responsableVinculo: vinculoResult,
      });
    setIsEditResponsable?.(true);
    setEditResponsableId(id);
    setShowForm(true);
  };

  const onDeleteResponsable = () => {
    setConfirmDeleteOpen(true);
  };

  const deleteResponsable = async () => {
    const vinculoToDelete = vinculos.find(
      (vinculo: Vinculo) =>
        vinculo.responsable.idResponsable === editResponsableId,
    );
    await vinculoDAO.remove({
      idVinculo: vinculoToDelete?.idVinculo,
    });
    setFetchedVinculos(false);
    onCancelEdit();
    setConfirmDeleteOpen(false);
  };

  const onAddResponsable = () => {
    setAddModalOpen(false);
    setShowForm(true);
    setIsNewResponsable?.(true);
  };

  const onAddSelectedResponsable = () => setFetchedVinculos(false);

  return showForm ? (
    <>
      <ResponsablesForm
        editResponsableId={editResponsableId}
        onDelete={onDeleteResponsable}
        onCancel={() => onCancelEdit()}
      />
      <ConfirmDialog
        open={confirmDeleteOpen}
        title="Eliminar Responsable"
        content="Al hacer esto, se borrará también su relación con el alumno"
        cancelText="Cancelar"
        confirmText="Eliminar"
        onCancel={() => setConfirmDeleteOpen(false)}
        onConfirm={deleteResponsable}
        customTheme={null}
      />
    </>
  ) : (
    <>
      <Grid spacing={3} container direction="column">
        <Grid
          className={classes.responsablesGrid}
          item
          container
          direction="row"
          spacing={3}
        >
          {working || workingContacto ? (
            <Loading />
          ) : vinculos.length > 0 ? (
            vinculos.map((vinculo) => {
              const {
                tipoVinculo: { descripcionTipoVinculo },
                responsable: {
                  idResponsable,
                  persona: { nombre, apellido, idPersona },
                },
              } = vinculo;
              const telefono1 =
                contactosResponsables.find(
                  (contacto) =>
                    contacto.persona.idPersona === idPersona &&
                    contacto.tipoMedioContacto.idTipoMedioContacto === 1,
                )?.descripcionMedioContacto || '';
              return (
                <ResponsablesCard
                  contactoEmergencia={vinculo.contactoEmergencia === 1}
                  lazo={descripcionTipoVinculo}
                  nombre={`${nombre} ${apellido}`}
                  key={`responsable-card-${idResponsable}`}
                  onEdit={() => onEditResponsable(idResponsable)}
                  telefono1={telefono1}
                  isHistoric={isHistoric}
                />
              );
            })
          ) : (
            <Typography className={classes.sinResponsables}>
              {isHistoric
                ? 'No hay responsables vinculados con el alumno'
                : 'Por ahora no hay responsables vinculados con el alumno'}
            </Typography>
          )}
        </Grid>

        <Grid item>
          {!isHistoric && (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setAddModalOpen(true)}
            >
              Agregar Responsable
            </Button>
          )}
        </Grid>
      </Grid>

      <AddResponsableModal
        open={addModalOpen}
        onClose={() => setAddModalOpen(false)}
        onAddResponsable={onAddResponsable}
        onAddSelectedResponsable={onAddSelectedResponsable}
        vinculos={vinculos}
      />
    </>
  );
};
