import { LibbyFetchDAO } from './LibbyFetchDAO';

export class RolDAO extends LibbyFetchDAO {
  constructor() {
    super('rol', 'idRolUsuario');
  }

  getAll() {
    return this.query().limit(0, 20).run();
  }

  getValidRoles() {
    return this.query()
      .notEquals('descripcion', 'Admin')
      .and()
      .notEquals('descripcion', 'no_rol')
      .and()
      .notEquals('descripcion', '--')
      .and()
      .notEquals('descripcion', 'Tutor')
      .and()
      .notEquals('descripcion', 'Preceptor')
      .and()
      .notEquals('descripcion', 'Estudiante')
      .orderBy('idRolUsuario')
      .run();
  }

  getRolesByIds(...ids: number[]) {
    let query = this.query();
    ids.forEach((id, index) => {
      if (index > 0) {
        query = query.or();
      }
      query = query.equals('idRolUsuario', id);
    });
    return query.run();
  }

  getById(id: number) {
    return this.query().equals('idRolUsuario', id).limit(0, 100).run();
  }
}
