import React, { useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InfoTableColumn, Loading, MainButton } from 'src/commons';
import { formatDate } from 'src/utils';
import { Typography } from '@material-ui/core';
import {
  Pps,
  EspacioCurricularState,
  AlumnoMovimiento,
  Pip,
  Seccion,
} from '../../../../app/models';
import { ResponseGenerarInforme } from '../types';

const useBoletinesColumnsStyles = makeStyles(() => ({
  loading: {
    justifyContent: 'flex-start',
  },
  verStyle: {
    '&:hover': {
      color: '#1caada',
      cursor: 'pointer',
    },
  },
}));

export interface BoletinesColumns {
  loading: {
    loading: boolean;
    idSeccion: string;
    idTipoInforme: string;
    periodo: string;
  };
  handleGenerate: (
    seccion: Seccion,
    idTipoPeriodo: number,
    periodo: string,
  ) => void;
  espaciosCurriculares: EspacioCurricularState[];
  levelId: any;
  filter: any;
  openModal: (
    data: ResponseGenerarInforme & {
      idPeriodo: number;
      alumnos: AlumnoMovimiento[];
      idNivel: number;
      isPip?: boolean;
      isPps?: boolean;
    },
  ) => void;
  alumnosMovimiento: AlumnoMovimiento[];
  pps: Pps[];
  pipData: Pip[];
}

export const useBoletinesColumns = ({
  handleGenerate,
  loading,
  espaciosCurriculares,
  levelId,
  filter,
  openModal,
  alumnosMovimiento,
  pps,
  pipData,
}: BoletinesColumns) => {
  const classes = useBoletinesColumnsStyles();
  const disabledRow = useCallback(
    (id: string) => loading.idSeccion === id && Boolean(loading.loading),
    [loading],
  );

  const getEspaciosCurricularesConCalifacion = useCallback(
    (row: ResponseGenerarInforme) => {
      const espaciosCurricularesCalificados: EspacioCurricularState[] = [];
      if (espaciosCurriculares.length > 0) {
        espaciosCurriculares.forEach((espacioCurricular) => {
          if (
            espacioCurricular.idSeccion === `${row.seccion.idSeccion}` &&
            espacioCurricular.abierto === 'false'
          ) {
            espaciosCurricularesCalificados.push(espacioCurricular);
          }
        });
      }
      let cantidadSinCalificar = '';
      if (espaciosCurricularesCalificados.length !== 0) {
        cantidadSinCalificar = `(${espaciosCurricularesCalificados.length})`;
      }
      return cantidadSinCalificar;
    },
    [espaciosCurriculares],
  );

  const getEspacioCurricularesRestantes = useCallback(
    (row: ResponseGenerarInforme) => {
      const espaciosCurricularesAbiertos: EspacioCurricularState[] = [];
      if (espaciosCurriculares.length > 0) {
        espaciosCurriculares.forEach((espacioCurricular) => {
          if (
            espacioCurricular.idSeccion === `${row.seccion.idSeccion}` &&
            espacioCurricular.abierto === 'true'
          ) {
            espaciosCurricularesAbiertos.push(espacioCurricular);
          }
        });
      }
      let cantidadEspacios = '';
      if (espaciosCurricularesAbiertos.length !== 0) {
        cantidadEspacios = `(${espaciosCurricularesAbiertos.length})`;
      }
      return cantidadEspacios;
    },
    [espaciosCurriculares],
  );

  const disableGenerateBoletin = useCallback(
    (row: ResponseGenerarInforme) => {
      const seccionEC = espaciosCurriculares.filter(
        (ec) => ec.idSeccion === row.seccion?.idSeccion,
      );
      const enable = seccionEC.every((ec) => ec.abierto === 'false');
      return !enable;
    },
    [espaciosCurriculares],
  );

  const getPpsRestantes = useCallback(
    (row: ResponseGenerarInforme) => {
      let cantidadPps = 0;
      const cerrados: { hasPPS: boolean; isOpen: boolean }[] = [];
      alumnosMovimiento.forEach((alumno) => {
        const hasPps = pps.find(
          (element) =>
            element.alumno.idAlumno === alumno.alumno.idAlumno &&
            alumno.seccion.idSeccion === row.seccion.idSeccion,
        );
        if (hasPps !== undefined && hasPps.abierto) {
          cantidadPps += 1;
        }
        cerrados.push({
          hasPPS: Boolean(hasPps),
          isOpen: Boolean(hasPps?.abierto),
        });
      });

      const tienePPS = cerrados.some((c) => c.hasPPS);
      const tieneAbiertos = cerrados.some((c) => c.isOpen);

      return {
        message: cantidadPps !== 0 ? `(${cantidadPps})` : '',
        disabled: tieneAbiertos || !tienePPS,
      };
    },
    [alumnosMovimiento, pps],
  );

  const getPipRestantes = useCallback(
    (row: ResponseGenerarInforme) => {
      const cerrados: { hasPIP: boolean; isOpen: boolean }[] = [];
      const alumnosPipData = alumnosMovimiento.filter((am) => {
        const pipInfo = pipData.find(
          (pipInfo) => pipInfo.alumno.idAlumno === am.alumno.idAlumno,
        );

        const sameSeccion = Boolean(
          am.seccion.idSeccion === row?.seccion?.idSeccion,
        );
        const hasPIP = Boolean(pipInfo);
        const isOpen = Boolean(pipInfo?.abierto);

        const count = sameSeccion && hasPIP && isOpen;

        if (sameSeccion) {
          cerrados.push({ hasPIP, isOpen });
        }

        return count;
      });
      const tienePIP = cerrados.some((c) => c.hasPIP);
      const tieneAbiertos = cerrados.some((c) => c.isOpen);
      return {
        count: alumnosPipData.length,
        disabled: tieneAbiertos || !tienePIP,
      };
    },
    [alumnosMovimiento, pipData],
  );

  const espacioCurricularLabel = useMemo(
    () =>
      levelId === '2'
        ? 'Area de Conocimiento (restantes)'
        : 'Espacios Curriculares (restantes)',
    [levelId],
  );
  const columns = useMemo<InfoTableColumn[]>(() => {
    let columnas =
      levelId === '2'
        ? [
            {
              id: 'seccion',
              label: 'Sección',
              orderById: 'seccion',
              render: (seccion: Seccion, row: ResponseGenerarInforme) =>
                seccion?.nombreSeccion,
            },
            {
              id: 'espaciosCurriculares',
              label: espacioCurricularLabel,
              hideSortIcon: true,
              render: (value: unknown, row: ResponseGenerarInforme) => {
                return (
                  <Typography
                    className={classes.verStyle}
                    onClick={() =>
                      openModal({
                        ...row,
                        idPeriodo: filter.periodo,
                        idNivel: levelId,
                        isPps: false,
                        alumnos: alumnosMovimiento,
                        pps,
                      })
                    }
                  >
                    Ver{getEspacioCurricularesRestantes(row)}
                  </Typography>
                );
              },
            },
            {
              id: 'boletin',
              label: 'Boletín',
              hideSortIcon: true,
              render: (_: unknown, row: ResponseGenerarInforme) => {
                return loading.idSeccion === row?.seccion?.idSeccion &&
                  loading.loading === true &&
                  loading.idTipoInforme === 1 &&
                  loading.periodo !== null ? (
                  <Loading className={classes.loading} />
                ) : (
                  <MainButton
                    size="small"
                    title="Generar"
                    handleOnClick={() => handleGenerate(row.seccion, 1)}
                    disabled={
                      disabledRow(loading.idSeccion) ||
                      getEspaciosCurricularesConCalifacion(row) === '' ||
                      getEspacioCurricularesRestantes(row) !== ''
                    }
                  />
                );
              },
            },
            {
              id: 'estado',
              label: 'Estado',
              orderById: 'boletin',
              width: '20%',
              render: (value: string, row: ResponseGenerarInforme) => {
                let estado;
                if (value === 'done') {
                  estado = `Ùltima vez generado el dia ${formatDate(
                    row.fecha,
                  )} por ${row.generadoPor?.nombreUsuario} ${
                    row.generadoPor?.apellidoUsuario
                  }`;
                }
                return <span>{estado ?? 'Nunca Generado'}</span>;
              },
            },
            {
              id: 'ppsRestantes',
              label: 'PPS',
              width: '10%',
              hideSortIcon: true,
              render: (_: unknown, row: ResponseGenerarInforme) => {
                return Number(row.seccion.anio.idAnio) === 7 ||
                  Number(row.seccion.anio.idAnio) === 9 ? (
                  <Typography
                    className={classes.verStyle}
                    onClick={() =>
                      openModal({
                        ...row,
                        idPeriodo: filter.periodo,
                        idNivel: levelId,
                        isPps: true,
                        alumnos: alumnosMovimiento,
                        pps,
                      })
                    }
                  >
                    Ver{getPpsRestantes(row).message}
                  </Typography>
                ) : null;
              },
            },
            {
              id: 'pps',
              label: 'PPS',
              hideSortIcon: true,
              render: (_: Pps, row: ResponseGenerarInforme) => {
                return loading.idSeccion === row.seccion.idSeccion &&
                  loading.loading === true &&
                  loading.idTipoInforme === 5 ? (
                  <Loading className={classes.loading} />
                ) : Number(row.seccion.anio.idAnio) === 7 ||
                  Number(row.seccion.anio.idAnio) === 9 ? (
                  <MainButton
                    size="small"
                    title="Generar"
                    handleOnClick={() => handleGenerate(row.seccion, 5)}
                    disabled={
                      (filter.periodo === 2 || filter.periodo === 1
                        ? false
                        : disabledRow(loading.idSeccion)) ||
                      getPpsRestantes(row).disabled
                    }
                  />
                ) : null;
              },
            },
            {
              id: 'estadopps',
              label: 'Estado PPS',
              orderById: 'boletin',
              render: (_: unknown, row: ResponseGenerarInforme) => {
                let estado;
                if (row.PPS?.estado === 'done') {
                  estado = `Ùltima vez generado el dia ${formatDate(
                    row.PPS?.fecha,
                  )} por ${row.PPS?.generadoPor?.nombreUsuario} ${
                    row.PPS?.generadoPor?.apellidoUsuario
                  }`;
                }

                return Number(row.seccion.anio.idAnio) === 7 ||
                  Number(row.seccion.anio.idAnio) === 9 ? (
                  <span>
                    {row.PPS?.estado === 'done' ? estado : 'Nunca Generado'}
                  </span>
                ) : null;
              },
            },
          ].filter(
            (c) =>
              [13].includes(filter.periodo) ||
              !['estadopps', 'pps', 'ppsRestantes'].includes(c.id),
          )
        : levelId === '3'
        ? [
            {
              id: 'seccion',
              label: 'Sección',
              orderById: 'seccion',
              render: (seccion: Seccion) => seccion.nombreSeccion,
            },
            {
              id: 'espaciosCurriculares',
              label: espacioCurricularLabel,
              hideSortIcon: true,
              render: (_: unknown, row: ResponseGenerarInforme) => {
                return (
                  <Typography
                    className={classes.verStyle}
                    onClick={() =>
                      openModal({
                        ...row,
                        idPeriodo: filter.periodo,
                        idNivel: levelId,
                        isPps: false,
                        alumnos: alumnosMovimiento,
                        pps: pps,
                      })
                    }
                  >
                    Ver{getEspacioCurricularesRestantes(row)}
                  </Typography>
                );
              },
            },
            {
              id: 'boletin',
              label: filter.periodo === 8 ? '4to Bimestre' : 'Boletín',
              hideSortIcon: true,
              render: (_: unknown, row: ResponseGenerarInforme) => {
                return loading.idSeccion === row.seccion.idSeccion &&
                  loading.loading === true &&
                  loading?.idTipoInforme === 1 &&
                  loading.periodo !== null ? (
                  <Loading className={classes.loading} />
                ) : (
                  <MainButton
                    size="small"
                    title="Generar"
                    handleOnClick={() => handleGenerate(row.seccion, 1)}
                    disabled={
                      disabledRow(loading.idSeccion) ||
                      getEspaciosCurricularesConCalifacion(row) === '' ||
                      getEspacioCurricularesRestantes(row) !== ''
                    }
                  />
                );
              },
            },
            {
              id: 'estado',
              label: 'Estado',
              orderById: 'boletin',
              width: '25%',
              render: (value: string, row: ResponseGenerarInforme) => {
                let estado;
                if (value === 'done') {
                  estado = `Ùltima vez generado el dia ${formatDate(
                    row.fecha,
                  )} por ${row.generadoPor?.nombreUsuario} ${
                    row.generadoPor?.apellidoUsuario
                  }`;
                }

                return (
                  <span>
                    {Boolean(value) && value === 'done'
                      ? estado
                      : 'Nunca Generado'}
                  </span>
                );
              },
            },
          ]
        : [
            {
              id: 'seccion',
              label: 'Sección',
              orderById: 'seccion',
              render: (seccion: Seccion) => seccion?.nombreSeccion,
            },
            {
              id: 'boletin',
              label: 'Boletín',
              hideSortIcon: true,
              render: (_: unknown, row: ResponseGenerarInforme) => {
                return loading.idSeccion === row?.seccion?.idSeccion &&
                  loading.loading === true &&
                  loading.idTipoInforme === 1 &&
                  loading.periodo !== null ? (
                  <Loading className={classes.loading} />
                ) : (
                  <MainButton
                    size="small"
                    title="Generar"
                    handleOnClick={() => handleGenerate(row.seccion, 1)}
                    disabled={
                      disabledRow(loading.idSeccion) ||
                      disableGenerateBoletin(row)
                    }
                  />
                );
              },
            },
            {
              id: 'estado',
              label: 'Estado',
              orderById: 'boletin',
              render: (value: string, row: ResponseGenerarInforme) => {
                let estado;
                if (value === 'done') {
                  estado = `Ùltima vez generado el dia ${formatDate(
                    row.fecha,
                  )} por ${row.generadoPor?.nombreUsuario} ${
                    row.generadoPor?.apellidoUsuario
                  }`;
                }

                return (
                  <span>
                    {Boolean(value) && value === 'done'
                      ? estado
                      : 'Nunca Generado'}
                  </span>
                );
              },
            },
          ];

    // TODO: cuando esté el backend chequear row.anual
    const columnsCuartoBimestre = [
      {
        id: 'boletinAnual',
        label: 'Anual',
        hideSortIcon: true,
        render: (_: unknown, row: ResponseGenerarInforme) => {
          return loading.idSeccion === row.seccion.idSeccion &&
            loading.loading === true &&
            loading.idTipoInforme === 1 &&
            loading.periodo === null ? (
            <Loading className={classes.loading} />
          ) : (
            <MainButton
              size="small"
              title="Generar"
              handleOnClick={() => handleGenerate(row.seccion, 1, null)}
              disabled={
                disabledRow(loading.idSeccion) ||
                getEspacioCurricularesRestantes(row) !== '' ||
                !row.estado ||
                !(row.estado && row.estado === 'done')
              }
            />
          );
        },
      },
      {
        id: 'anual',
        label: 'Estado',
        orderById: 'boletin',
        render: (anual: ResponseGenerarInforme['anual']) => {
          let estado;
          if (anual?.estado === 'done') {
            estado = `Ùltima vez generado el dia ${formatDate(
              anual?.fecha,
            )} por ${anual?.generadoPor?.nombreUsuario} ${
              anual?.generadoPor?.apellidoUsuario
            }`;
          }

          return (
            <span>
              {Boolean(anual) && anual?.estado === 'done'
                ? estado
                : 'Nunca Generado'}
            </span>
          );
        },
      },
    ];

    const columnasPip = [
      {
        id: 'pendientesPip',
        label: 'Pendientes PIP',
        width: '15%',
        hideSortIcon: true,
        render: (_: unknown, row: ResponseGenerarInforme) => {
          const idAnio = row.seccion.anio?.idAnio;
          return (
            [15, 19].includes(idAnio) && (
              <Typography
                className={classes.verStyle}
                onClick={() =>
                  openModal({
                    ...row,
                    idPeriodo: filter.periodo,
                    alumnos: alumnosMovimiento,
                    idNivel: levelId,
                    isPip: true,
                  })
                }
              >
                {`Ver ( ${getPipRestantes(row).count} )`}
              </Typography>
            )
          );
        },
      },
      {
        id: 'pip',
        label: 'PIP',
        width: '15%',
        hideSortIcon: true,
        render: (
          _: ResponseGenerarInforme['PIP'],
          row: ResponseGenerarInforme,
        ) => {
          const idAnio = row.seccion.anio?.idAnio;
          return loading.idSeccion === row.seccion.idSeccion &&
            loading.loading === true &&
            loading.idTipoInforme === 4 ? (
            <Loading className={classes.loading} />
          ) : (
            [15, 19].includes(idAnio) && (
              <MainButton
                size="small"
                title="Generar"
                handleOnClick={() => handleGenerate(row.seccion, 4)}
                disabled={
                  loading.loading ||
                  (filter.periodo !== 4
                    ? false
                    : disabledRow(loading.idSeccion)) ||
                  getPipRestantes(row).disabled
                }
              />
            )
          );
        },
      },
      {
        id: 'estadopip',
        label: 'Estado PIP',
        width: '20%',
        orderById: 'estadopip',
        render: (_: unknown, row: ResponseGenerarInforme) => {
          const idAnio = row.seccion.anio?.idAnio;
          let estado;
          if (row.PIP?.estado === 'done') {
            estado = `Ùltima vez generado el dia ${formatDate(
              row.PIP?.fecha,
            )} por ${row.PIP?.generadoPor?.nombreUsuario} ${
              row.PIP?.generadoPor?.apellidoUsuario
            }`;
          }

          return (
            [15, 19].includes(Number(idAnio)) && (
              <span>
                {row && row.PIP?.estado === 'done' ? estado : 'Nunca Generado'}
              </span>
            )
          );
        },
      },
    ];

    if (filter.periodo === 8) {
      columnas.push(...columnsCuartoBimestre);
    }
    if (filter.periodo === 11) {
      columnas.push(...columnasPip);
    }
    return columnas;
  }, [
    levelId,
    filter.periodo,
    espacioCurricularLabel,
    classes.verStyle,
    classes.loading,
    getEspacioCurricularesRestantes,
    getEspaciosCurricularesConCalifacion,
    openModal,
    alumnosMovimiento,
    pps,
    loading.idSeccion,
    loading.loading,
    loading.idTipoInforme,
    loading.periodo,
    disabledRow,
    handleGenerate,
    getPpsRestantes,
    disableGenerateBoletin,
    getPipRestantes,
  ]);

  return columns;
};
