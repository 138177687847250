import { useVerificacionConfMatriculaGetRequest } from 'src/app/business/ConfiguracionMatricula';
import { useConfirmacionMatriculaGenerateLibbyFetch } from 'src/app/business/matriculacion';

export const useGetMatriculaConfig = () => {
  const { data = { isValid: false, isEnable: false } } =
    useVerificacionConfMatriculaGetRequest<{
      isValid: boolean;
      isEnable: boolean;
    }>({
      url: '/api/public/matriculacion/verificacion_conf_matricula',
      autoCall: true,
    });

  const { data: generados = [] } = useConfirmacionMatriculaGenerateLibbyFetch({
    limit: 1,
    orderBy: 'createdAt',
    direction: 'desc',
  });

  return {
    data,
    generados,
  };
};
