import React, { ReactElement } from 'react';
import { ModalidadType } from 'src/app/models';
import { useRolesContext } from 'src/context/RolesContext';
import { AdultPromotion } from './AdultPromotion';
import { BCPromotion } from './BCPromotion';
import { InitialPromotion } from './Initial';
import { ConfiguracionProvider } from 'src/app/business';

const makeModalityScreen: Record<string, ReactElement> = {
  1: <InitialPromotion />,
  2: <BCPromotion />,
  3: <AdultPromotion />,
};

export const Promotion = () => {
  const {
    selectedRole: { modalidad },
  } = useRolesContext();

  return (
    <ConfiguracionProvider>
      {makeModalityScreen[modalidad || ModalidadType.General]}
    </ConfiguracionProvider>
  );
};
