import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { API_URL } from 'src/config';

// Todo: Other types in the future?
export enum REQUEST_TYPES {
  GET = 'get',
}

export interface LibbyRequestOptions {
  type: string;
  daoName: string;
  autoCall?: boolean;
  aspect?: string;
  params?: any[];
  url?: string;
}

export interface LibbyRequestReturn<T> {
  data?: T;
  working: boolean;
  request: (...args: any[]) => Promise<T>;
  setResponse: Dispatch<SetStateAction<any[]>>;
}

type fetchParams = {
  url: string;
  autoCall: boolean;
  aspect?: string;
  headers?: HeadersInit;
};

export const useFetch = <T>({
  url,
  autoCall = false,
  aspect,
  headers = { 'Content-Type': 'application/json' },
}: fetchParams) => {
  const [data, setResponse] = useState<T | undefined>(undefined);
  const [responseHeaders, setHeaders] = useState<Headers | undefined>(
    undefined,
  );
  const [error, setError] = useState<Error | undefined>(undefined);
  const [working, setWorking] = useState(false);

  const makeRequest = useCallback(async () => {
    setWorking(true);
    await fetch(`${API_URL}${url}`, {
      headers: {
        ...headers,
        ...(aspect ? { 'x-chino-aspect': aspect } : {}),
      },
    })
      .then(async (res) => {
        setHeaders(res.headers);
        if (res.status >= 400) {
          throw await res.json();
        }
        return res.json();
      })
      .then((response) => setResponse(response))
      .catch((error) =>
        setError(error instanceof Error ? error : new Error(error)),
      );
    setWorking(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [aspect, url]);

  const response = useMemo(
    () => ({
      data,
      error,
      working,
      recall: makeRequest,
      setResponse,
      responseHeaders,
    }),
    [makeRequest, data, error, working, setResponse, responseHeaders],
  );

  useEffect(() => {
    if (autoCall) {
      makeRequest();
    }
  }, [makeRequest, autoCall]);

  return response;
};
