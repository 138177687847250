import { useMemo } from 'react';

export const useFilters = (filter: GestionProyeccion.Filters) => {
  const filterCycle = useMemo(
    () => [{ path: 'cicloLectivo.idCicloLectivo', value: filter.cycle }],
    [filter.cycle],
  );

  const filterLocalizacion = useMemo(
    () => [
      {
        path: 'seccionOrigen.localizacion',
        value: filter.localizacion,
      },
    ],
    [filter.localizacion],
  );

  const filterJornada = useMemo(
    () => [
      {
        path: 'seccionOrigen.jornada',
        value: filter.jornada,
      },
    ],
    [filter.jornada],
  );

  const filterTurno = useMemo(
    () => [
      {
        path: 'seccionOrigen.turno',
        value: filter.turno,
      },
    ],
    [filter.turno],
  );

  const filterSeccionOrigen = useMemo(
    () => [
      {
        path: 'seccionOrigen.idSeccion',
        value: filter.seccionOrigen,
      },
    ],
    [filter.seccionOrigen],
  );

  const filterDistrito = useMemo(
    () => [
      {
        path: 'seccionOrigen.localizacion.establecimiento.distritoEscolar.idDistritoEscolar',
        value: filter.distrito,
      },
    ],
    [filter.distrito],
  );

  const filterAnio = useMemo(
    () => [{ path: 'seccionOrigen.anio.idAnio', value: filter.anio }],
    [filter.anio],
  );

  const filterEstado = useMemo(
    () => [{ path: 'estadoPaseAnio', value: filter.estado }],
    [filter.estado],
  );

  const filterArticula = useMemo(() => [{ path: 'articula', value: true }], []);

  const filterSearch = useMemo(
    () => [
      {
        path: 'alumno.persona.nombre',
        value: filter.searchDebounced,
        method: 'includes',
      },
      {
        path: 'alumno.persona.apellido',
        value: filter.searchDebounced,
        method: 'includes',
      },
      {
        path: 'alumno.persona.documento',
        value: filter.searchDebounced,
        method: 'includes',
      },
    ],
    [filter.searchDebounced],
  );

  return {
    filterCycle,
    filterLocalizacion,
    filterJornada,
    filterTurno,
    filterSeccionOrigen,
    filterDistrito,
    filterAnio,
    filterEstado,
    filterArticula,
    filterSearch,
  };
};
