import React, { useState } from 'react';
// @ts-ignore
import MUIEditor, { MUIEditorState } from 'react-mui-draft-wysiwyg';
import { RawDraftContentState, convertFromRaw } from 'draft-js';

const editorConfig = {
  toolbar: { visible: false },
  draftEditor: { readOnly: true },
  editor: {
    wrapperElement: 'div',
    style: {
      padding: 0,
      //borderTop: '1px solid gray',
    },
  },
};

export const MUIEditorMIESC = ({ text = '' }: { text: string | object }) => {
  let rawContent: RawDraftContentState =
    typeof text === 'object'
      ? text
      : {
          blocks: [
            {
              data: {},
              depth: 0,
              entityRanges: [],
              inlineStyleRanges: [],
              key: '2vm6d',
              text,
              type: '',
            },
          ],
          entityMap: {},
        };

  return (
    <MUIEditor
      editorState={MUIEditorState.createWithContent(
        editorConfig,
        convertFromRaw(rawContent),
      )}
      config={editorConfig}
    />
  );
};
