import React from 'react';
import { useBackButton, useRouteScreenTitle } from 'src/commons/hooks';
import { Grid, Button } from '@material-ui/core';

export const Createc = () => {
  useBackButton('/');
  useRouteScreenTitle('Createc');

  const handleClick = () => {
    window.open(
      'https://createc.bue.edu.ar/app/login?googleOneTap=true&oneTapAutoSelect=true',
      '_blank',
    );
  };

  return (
    <Grid container style={{ padding: 30 }}>
      <Grid item xs>
        <Button color="primary" variant="contained" onClick={handleClick}>
          Ingresar
        </Button>
      </Grid>
    </Grid>
  );
};
