import React, { createContext, useContext, useMemo, useEffect } from 'react';
import { AnyObject } from 'src/commons';
import {
  useObjetivosDeAprendizajeLibbyCall,
  useContenidoEspecificoLibbyCall,
  usePropositosDeEnsenanzaLibbyCall,
  useIndicadoresDeEvaluacionLibbyCall,
  useHabilidadesLibbyCall,
  useRecursosLibbyCall,
  useInstrumentosDeEvaluacionLibbyCall,
  useContenidoBloqueLibbyCall,
  useEspacioCurricularCustomLibbyFetch,
} from 'src/app/business';
import {
  EspacioCurricular,
  ObjetivosDeAprendizaje,
  PropositosDeEnsenanza,
  IndicadoresDeEvaluacion,
  Recursos,
  Habilidades,
  InstrumentosDeEvaluacion,
  Orientacion,
  ContenidoEspecifico,
  ContenidoBloque,
} from 'src/app/models';
import { useRolesContext } from 'src/context/RolesContext';
import { makeEntityByEspacios, makeEntityByEspacio } from '../../../hooks';

export type SetActiveFilter = (filter: AnyObject) => void;

export interface Result {
  objetivos: ObjetivosDeAprendizaje[];
  propositos: PropositosDeEnsenanza[];
  indicadores: IndicadoresDeEvaluacion[];
  recursos: Recursos[];
  habilidades: Habilidades[];
  instrumentos: InstrumentosDeEvaluacion[];
  espaciosCurriculares: EspacioCurricular[];
  contenidosEspecificos: ContenidoEspecifico[];
  contenidosBloques: ContenidoBloque[];
}

export interface InterdisciplinarContextValue {
  data: Result;
  working: boolean;
}

const filterMateria = (espacio?: EspacioCurricular) => ({
  filter: {
    materia: [{ path: 'id_materia', value: espacio?.materia.idMateria || '' }],
  },
  limit: 20000,
});

const filterVoid = () => ({
  filter: {},
  limit: 20000,
});

const useObjetivosDAO = makeEntityByEspacio<ObjetivosDeAprendizaje[]>(
  useObjetivosDeAprendizajeLibbyCall,
  filterMateria,
);

const usePropositosDAO = makeEntityByEspacio<PropositosDeEnsenanza[]>(
  usePropositosDeEnsenanzaLibbyCall,
  filterMateria,
);

const useIndicadoresDAO = makeEntityByEspacio<IndicadoresDeEvaluacion[]>(
  useIndicadoresDeEvaluacionLibbyCall,
  filterVoid,
  true,
);

const useHabilidadesDAO = makeEntityByEspacio<Habilidades[]>(
  useHabilidadesLibbyCall,
  filterVoid,
  true,
);

const useRecursosDAO = makeEntityByEspacio<Recursos[]>(
  useRecursosLibbyCall,
  filterVoid,
  true,
);

const useInstrumentosDAO = makeEntityByEspacio<InstrumentosDeEvaluacion[]>(
  useInstrumentosDeEvaluacionLibbyCall,
  filterVoid,
  true,
);

const useContenidoEspecificoDAO = makeEntityByEspacios<ContenidoEspecifico[]>(
  useContenidoEspecificoLibbyCall,
  (espacios) => ({
    filter: {
      materia: !espacios
        ? []
        : espacios.map((espacio) => ({
            path: 'id_materia',
            value: espacio?.materia.idMateria || '',
          })),
    },
    limit: 20000,
  }),
);

const filterBloque = (espaciosCurriculares: EspacioCurricular[] = []) => {
  const idMaterias = espaciosCurriculares?.reduce<number[]>((acc, item) => {
    if (!acc.includes(item.materia.idMateria)) {
      return [...acc, item.materia.idMateria];
    }
    return acc;
  }, []);
  return {
    filter: {
      bloques: !idMaterias.length
        ? [{ path: 'bloque.idMateria', value: -1 }]
        : idMaterias.map((idMateria) => ({
            path: 'bloque.idMateria',
            value: idMateria,
          })),
    },
    limit: 20000,
  };
};

const useContenidosDAO = makeEntityByEspacios<ContenidoBloque[]>(
  useContenidoBloqueLibbyCall,
  filterBloque,
);
export const InterdisciplinarContext =
  createContext<InterdisciplinarContextValue>({
    // @ts-ignore
    data: {},
    working: false,
  });

export const useInterdisciplinarContext = () =>
  useContext<InterdisciplinarContextValue>(InterdisciplinarContext);

export interface InterdisciplinarProviderProps {
  children: JSX.Element;
  orientaciones: Orientacion[];
  idAnio?: number;
  idPlanEstudio?: number;
}
// TODO: abstraer esto
export const InterdisciplinarProvider = ({
  children,
  orientaciones,
  idAnio = 0,
  idPlanEstudio = 0,
}: InterdisciplinarProviderProps) => {
  const { selectedRole } = useRolesContext();

  const paramsFetchEspaciosCurriculares = useMemo(
    () => ({
      filter: {
        orientaciones: orientaciones.map((orientacion) => ({
          path: 'materia.orientacion',
          value: orientacion.idOrientacion,
        })),
        localizacion: [
          {
            path: 'localizacion',
            value: selectedRole.localizacionId,
          },
        ],
        anio: [
          {
            path: 'materia.anio',
            value: idAnio,
          },
        ],
        planEstudio: [
          {
            path: 'planEstudio',
            value: idPlanEstudio,
          },
        ],
      },
      limit: 20000,
    }),
    [orientaciones, selectedRole, idAnio, idPlanEstudio],
  );

  const { data: espaciosCurriculares = [], working: espaciosWorking } =
    useEspacioCurricularCustomLibbyFetch(paramsFetchEspaciosCurriculares);

  const { data: objetivos, working: oWorking } = useObjetivosDAO();
  const { data: propositos, working: pWorking } = usePropositosDAO();
  const { data: indicadores, working: iWorking } = useIndicadoresDAO();
  const { data: recursos, working: rWorking } = useRecursosDAO();
  const { data: habilidades, working: hWorking } = useHabilidadesDAO();
  const { data: instrumentos, working: instWorking } = useInstrumentosDAO();

  const { data: contenidosEspecificos = [], working: ceWorking } =
    useContenidoEspecificoLibbyCall<ContenidoEspecifico[]>({
      methodName: 'filtroEspacioCurricular',
      params: espaciosCurriculares,
    });

  const { data: contenidosBloques = [], working: ceBlWorking } =
    useContenidoBloqueLibbyCall<ContenidoBloque[]>({
      methodName: 'filtroContenidoBloque',
      params: espaciosCurriculares,
    });

  const workingAll =
    oWorking ||
    pWorking ||
    iWorking ||
    rWorking ||
    hWorking ||
    instWorking ||
    espaciosWorking ||
    ceBlWorking ||
    ceWorking;

  const value = useMemo<InterdisciplinarContextValue>(
    () => ({
      data: {
        objetivos: objetivos || [],
        propositos: propositos || [],
        indicadores: indicadores || [],
        recursos: recursos || [],
        habilidades: habilidades || [],
        instrumentos: instrumentos || [],
        espaciosCurriculares: espaciosCurriculares,
        contenidosEspecificos: contenidosEspecificos,
        contenidosBloques: contenidosBloques,
      },
      working: workingAll,
    }),
    [
      objetivos,
      propositos,
      indicadores,
      recursos,
      habilidades,
      instrumentos,
      espaciosCurriculares,
      contenidosEspecificos,
      contenidosBloques,
      workingAll,
    ],
  );

  return (
    <InterdisciplinarContext.Provider value={value}>
      {children}
    </InterdisciplinarContext.Provider>
  );
};
