import { Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { FormInput, useFormContext } from 'src/lib/templates';
import { useStyles } from '../styles';

export const Cobertura: FunctionComponent<{ isHistoric: boolean }> = ({
  isHistoric,
}) => {
  const { form } = useFormContext();
  const classes = useStyles();

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      className={classes.secondaryContainer}
    >
      <Grid item>
        <Typography className={classes.title} variant="h5">
          Cobertura (opcional)
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormInput
              disabled={isHistoric}
              placeholder="Ingresá tipo de cobertura"
              label="Tipo de cobertura"
              name="salud.datosCobertura.tipoDeCobertura"
              form={form}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormInput
              disabled={isHistoric}
              placeholder="Ingresá número de socio"
              label="Número de socio"
              name="salud.datosCobertura.numeroSocio"
              form={form}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormInput
              disabled={isHistoric}
              placeholder="Ingresá plan"
              label="Plan"
              name="salud.datosCobertura.planCobertura"
              form={form}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormInput
              disabled={isHistoric}
              placeholder="Ingresá un teléfono"
              label="Teléfono"
              name="salud.datosCobertura.telefonoCobertura"
              form={form}
              type="number"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
