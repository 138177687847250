import { Container } from '@material-ui/core';
import { cloneDeep, isEqual } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { Habilidad, Nodo, PerfilOrientado } from 'src/app/models/orientaciones';
import { ButtonTypesProps, Footer } from 'src/commons';
import customFormDialog from 'src/commons/services/customFormDialog';
import { makeArbolNodes } from '../../utils';
import {
  AccionesOrientacionesFormValues,
  ArbolCheckModalProps,
  ArbolContent,
  HandleChexboxChange,
} from '../types';
import { ArbolCheckbox } from './ArbolCheckbox';

export const ArbolCheckModal = ({
  type,
  contenidos,
  data,
  onlyView = false,
  handleSubmit,
}: ArbolCheckModalProps) => {
  const [state, setState] = useState<AccionesOrientacionesFormValues>(data);

  const handleCheckboxChange = useCallback<
    (item: PerfilOrientado | Nodo | Habilidad) => HandleChexboxChange
  >(
    (item) => (_, checked) => {
      switch (type) {
        case 'perfiles':
          const newPerfil = item as PerfilOrientado;
          if (checked) {
            setState((prev) => {
              return {
                ...prev,
                perfiles: [...prev.perfiles, newPerfil],
              };
            });
          } else {
            setState((prev) => {
              const olds = cloneDeep(prev.perfiles).filter(
                (p) => p.idPerfilOrientado !== newPerfil.idPerfilOrientado,
              );
              return {
                ...prev,
                perfiles: [...olds],
              };
            });
          }
          break;
        case 'habilidades':
          const newHabilidad = item as Habilidad;
          if (checked) {
            setState((prev) => {
              return {
                ...prev,
                habilidades: [...prev.habilidades, newHabilidad],
              };
            });
          } else {
            setState((prev) => {
              const olds = cloneDeep(prev.habilidades).filter(
                (h) => h.idHabilidad !== newHabilidad.idHabilidad,
              );
              return {
                ...prev,
                habilidades: [...olds],
              };
            });
          }

          break;

        default:
          break;
      }
    },
    [type],
  );

  const handleCheckboxChangeNode = useCallback<HandleChexboxChange>(
    (event, checked) => {
      const [_nodo, _bloque, _eje, _unidad, _contenido] =
        event.target.name.split('-');

      if (checked) {
        // NO existe, agregar
        const filtered = (contenidos as Nodo[]).filter((nodo) => {
          if (nodo.orientacion.idOrientacion !== state.idOrientacion)
            return false;

          let isBloque = true;
          let isEje = true;
          let isUnidad = true;
          let isContenido = true;
          if (_bloque) {
            isBloque = nodo.bloque.idBloque.toString() === _bloque.toString();
          }
          if (_eje) {
            isEje = nodo.eje.idEje.toString() === _eje.toString();
          }
          if (_unidad) {
            isUnidad = nodo.unidad.idUnidad.toString() === _unidad.toString();
          }
          if (_contenido) {
            isContenido =
              nodo.contenido.idContenido.toString() === _contenido.toString();
          }
          return isBloque && isEje && isUnidad && isContenido;
        });
        setState((prev) => {
          const olds: Nodo[] = cloneDeep(prev.nodos);
          olds.push(...filtered);
          return {
            ...prev,
            nodos: [...olds],
          };
        });
      } else {
        // Existe se saca
        const filtered = state.nodos.filter((nodo) => {
          let isBloque = true;
          let isEje = true;
          let isUnidad = true;
          let isContenido = true;
          if (_bloque) {
            isBloque = nodo.bloque.idBloque.toString() === _bloque.toString();
          }
          if (_eje) {
            isEje = nodo.eje.idEje.toString() === _eje.toString();
          }
          if (_unidad) {
            isUnidad = nodo.unidad.idUnidad.toString() === _unidad.toString();
          }
          if (_contenido) {
            isContenido =
              nodo.contenido.idContenido.toString() === _contenido.toString();
          }
          return isBloque && isEje && isUnidad && isContenido;
        });
        setState((prev) => {
          const ids = filtered.map((fn) => fn.idNodo);
          const newNodos: Nodo[] = cloneDeep(prev.nodos).filter(
            (n) => !ids.includes(n.idNodo),
          );
          return {
            ...prev,
            nodos: [...newNodos],
          };
        });
      }
    },
    [contenidos, state.idOrientacion, state.nodos],
  );

  const _contenidos = useMemo(() => {
    const idOrientacion = state.idOrientacion;
    const mapped: ArbolContent[] = [];
    switch (type) {
      case 'perfiles':
        (contenidos as PerfilOrientado[])
          .filter((p) => p.orientacion.idOrientacion === idOrientacion)
          .forEach((contenido) => {
            const isChecked = !!state.perfiles.find(
              (p) => p.idPerfilOrientado === contenido.idPerfilOrientado,
            );
            mapped.push({
              id: contenido.idPerfilOrientado,
              label: contenido.descripcion,
              checked: isChecked,
              handleChange: handleCheckboxChange(contenido),
              contenidos: [],
            });
          });
        break;
      case 'habilidades':
        (contenidos as Habilidad[])
          .filter(
            (p) =>
              p.habilidadCategoria.orientacion.idOrientacion === idOrientacion,
          )
          .forEach((contenido) => {
            const isCheked = !!state.habilidades.find(
              (p) => p.idHabilidad === contenido.idHabilidad,
            );
            mapped.push({
              id: contenido.idHabilidad,
              label: contenido.descripcion,
              checked: isCheked,
              handleChange: handleCheckboxChange(contenido),
              contenidos: [],
            });
          });
        break;
      case 'nodos':
        makeArbolNodes(
          (contenidos as Nodo[]).filter(
            (n) => n.orientacion.idOrientacion === idOrientacion,
          ),
        ).forEach((bloque) => {
          const name = `${bloque.idNodo}-${bloque.idBloque}`;
          const ejesContent = bloque.ejes.map((eje) => {
            const ejeName = name + `-${eje.idEje}`;
            const unidadesContent = eje.unidades.map((unidad) => {
              const unidadName = ejeName + `-${unidad.idUnidad}`;
              const contenidosContent = unidad.contenidos.map((content) => {
                const contenidoName = unidadName + `-${content.idContenido}`;
                const isChekedContenido = !!state.nodos.find(
                  (p) =>
                    p.bloque.idBloque === bloque.idBloque &&
                    p.eje.idEje === eje.idEje &&
                    p.unidad.idUnidad === unidad.idUnidad &&
                    p.contenido.idContenido === content.idContenido,
                );
                return {
                  id: content.idContenido,
                  label: content.descripcion,
                  name: contenidoName,
                  checked: isChekedContenido,
                  handleChange: handleCheckboxChangeNode,
                  contenidos: [],
                };
              });
              const isChekedUnidad = contenidosContent.every((c) => c.checked);
              const isIndeterminateUnidad =
                contenidosContent.some((c) => c.checked) && !isChekedUnidad;
              return {
                id: unidad.idUnidad,
                label: 'Unidad. ' + unidad.nombre,
                name: unidadName,
                indeterminate: isIndeterminateUnidad,
                checked: isChekedUnidad,
                handleChange: handleCheckboxChangeNode,
                contenidos: contenidosContent,
              };
            });
            const isChekedEje = unidadesContent.every((uc) => uc.checked);
            const isIndeterminateEje =
              unidadesContent.some((uc) => uc.checked || uc.indeterminate) &&
              !isChekedEje;
            return {
              id: Number(eje.idEje),
              label: 'Eje. ' + eje.nombre,
              checked: isChekedEje,
              indeterminate: isIndeterminateEje,
              name: ejeName,
              handleChange: handleCheckboxChangeNode,
              contenidos: unidadesContent,
            };
          });
          const isChekedBloque = ejesContent.every((ec) => ec.checked);
          const indeterminateBloque =
            ejesContent.some((ec) => ec.checked || ec.indeterminate) &&
            !isChekedBloque;
          mapped.push({
            id: Number(bloque.idBloque),
            label: 'Bloque. ' + bloque.nombre,
            checked: isChekedBloque,
            indeterminate: indeterminateBloque,
            name,
            handleChange: handleCheckboxChangeNode,
            contenidos: ejesContent,
          });
        });
        break;
      default:
        // noting
        break;
    }

    return mapped;
  }, [
    contenidos,
    handleCheckboxChange,
    handleCheckboxChangeNode,
    state.habilidades,
    state.idOrientacion,
    state.nodos,
    state.perfiles,
    type,
  ]);

  const buttonConfig: ButtonTypesProps[] = useMemo(() => {
    const isDisabledToSave = isEqual(
      JSON.stringify(data),
      JSON.stringify(state),
    );
    return [
      {
        title: onlyView ? 'Cerrar' : 'Cancelar',
        handleOnClick: () => {
          customFormDialog.handleCancel();
        },
        size: 'medium',
      },
      {
        title: 'Guardar',
        handleOnClick: () => {
          handleSubmit(state);
        },
        size: 'medium',
        disabled: isDisabledToSave,
        hidden: onlyView,
      },
    ];
  }, [data, handleSubmit, onlyView, state]);

  return (
    <Container>
      <ArbolCheckbox contenido={_contenidos} />
      <Footer buttonConfig={buttonConfig} />
    </Container>
  );
};
