import { makeStyles } from '@material-ui/core';
import { primary } from 'src/theme/colors';

export const useStyles = makeStyles({
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
  infoTableCustom: {
    marginBottom: '20px',
    height: '485px',
  },
  alumnoButton: {
    color: primary.lightBlue,
    margin: '0',
    background: 'none',
    textAlign: 'left',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  paginationContainer: {
    marginBottom: '5rem',
  },
});
