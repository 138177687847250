import React, { useRef } from 'react';
import { Grid } from '@material-ui/core';
import { useRolesContext } from 'src/context/RolesContext';
import {
  SeccionProvider,
  EspacioCurricularByIdProvider,
  DiscipOrientPlanFeedbackCall,
  useDiscipOrientPlanFeedbackDAO,
  useDiscipOrientPlanDAO,
} from 'src/app/business';
import {
  EspaciosDiscipOrientPlan,
  DiscipOrientPlanFeedback,
} from 'src/app/models';
import { FeedbackTemplate, PLANIFICACION_ESTADOS, Loading } from 'src/commons';
import { useInfoCards } from './hooks';
import {
  useFeedbackOnSubmit,
  InfoCard,
  PlanificacionFeedbackForm,
  PlanificacionFeedbackView,
} from '../commons';
import { DisciplinarOrientadaProvider } from '../../forms/PlanificacionDisciplinarOrientada/Context';
import { DisciplinarOrientadaFlowHeader } from './components';

export interface DisciplinarOrientadaFlowProps {
  data: EspaciosDiscipOrientPlan;
}

export type FeedbackTemplateCompleteValue = {
  feedback: FeedbackTemplate[];
};

export const DisciplinarOrientadaFlow = ({
  data,
}: DisciplinarOrientadaFlowProps) => {
  const cards = useInfoCards(data);

  const { selectedRole } = useRolesContext();

  const seccionFilter = useRef({
    filter: [{ path: 'localizacion', value: selectedRole.localizacionId }],
  });

  const discipOrientPlanFeedbackDAO = useDiscipOrientPlanFeedbackDAO();
  const discipOrientPlanDAO = useDiscipOrientPlanDAO();
  const onSubmit = useFeedbackOnSubmit({
    planDAO: discipOrientPlanDAO,
    feedbackDAO: discipOrientPlanFeedbackDAO,
    plan: data.discipOrientPlan,
    aspectFeedback: 'limit_province',
  });

  const isSend =
    data.discipOrientPlan.planificacionEstados.idPlanificacionEstados ===
    PLANIFICACION_ESTADOS.ENVIADO;

  // this is temporal
  // @ts-ignore
  const header = <DisciplinarOrientadaFlowHeader data={data} />;
  return (
    <SeccionProvider filter={seccionFilter.current}>
      <>
        {header}
        <Grid container>
          <Grid item sm={6}>
            <EspacioCurricularByIdProvider
              id={data.espacioCurricular.idEspacioCurricular}
            >
              <DisciplinarOrientadaProvider>
                <InfoCard title="Planificación" cards={cards} />
              </DisciplinarOrientadaProvider>
            </EspacioCurricularByIdProvider>
          </Grid>
          <Grid item sm={6}>
            <DiscipOrientPlanFeedbackCall<DiscipOrientPlanFeedback>
              methodName="getLastFeedback"
              params={[data.discipOrientPlan.idDiscipOrientPlan]}
              aspect="limit_province"
            >
              {({ data: planFeedback, working }) =>
                working || planFeedback === undefined ? (
                  <Loading />
                ) : isSend ? (
                  <PlanificacionFeedbackForm
                    onSubmit={onSubmit}
                    data={planFeedback}
                  />
                ) : (
                  <PlanificacionFeedbackView data={planFeedback.feedback} />
                )
              }
            </DiscipOrientPlanFeedbackCall>
          </Grid>
        </Grid>
      </>
    </SeccionProvider>
  );
};
