import { RecuperacionDAO } from 'src/platform/libby/dao';

export function RowsSaver<T>(row: T[]) {
  this.row = row;
}

RowsSaver.prototype = {
  async save(dao: RecuperacionDAO, ecs: string) {
    for (const field of this.row.fields) {
      const nota =
        'idRecuperacionDiciembre' === field
          ? this.row.diciembre
          : this.row.febreroMarzo;
      await dao.aspect('default').save({
        nota,
        alumno: this.row.idAlumno,
        periodo: field === 'idRecuperacionDiciembre' ? 14 : 15,
        espacioCurricularSeccion: ecs,
        aprobado: Number(nota) >= 6,
        ...(this.row[field] &&
          Boolean(this.row[field]) && { idRecuperacion: this.row[field] }),
      });
    }
  },
};
