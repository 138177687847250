import React, { useContext, createContext, FC, useState, useMemo } from 'react';
import { useGetGruposEstudiante } from '../hooks/useGetGruposEstudiante';
import { useCriteriosEvaluacionLibbyFetch } from 'src/app/business/acap/CriterioEvaluacion';
import { useAcapListNodosGetRequest } from 'src/app/business/acap';
import { AccionOrientacion } from 'src/app/models';
import { Typography } from '@material-ui/core';

export const EstudianteContext = createContext<Acap.Estudiante.Context>({
  loadingGrupos: false,
  grupos: [],
  criterios: [],
  data: [],
  loadingListNodos: false,
});

export const useEstudianteContext = () => useContext(EstudianteContext);

type Props = {
  children: React.ReactNode;
};

export const EstudianteProvider: FC<Props> = ({ children }) => {
  const { data: grupos, working } = useGetGruposEstudiante();
  const {
    data = [],
    working: loadingListNodos,
    error,
  } = useAcapListNodosGetRequest<AccionOrientacion[]>({
    url: '/api/acap/inscripcion_nodos',
    autoCall: true,
  });

  const criteriosFilter = useMemo(
    () => ({
      activos: [
        {
          path: 'activo',
          value: true,
        },
      ],
      forEstudiante: [
        {
          path: 'forOrganizacion',
          value: false,
        },
      ],
    }),
    [],
  );
  const { data: criterios = [], working: criteriosLoading } =
    useCriteriosEvaluacionLibbyFetch({
      limit: 4,
      filter: criteriosFilter,
    });

  return (
    <EstudianteContext.Provider
      value={{
        loadingGrupos: working,
        grupos,
        criterios,
        data,
        loadingListNodos,
      }}
    >
      {error?.existError ? (
        <Typography>Ocurrió un error, vuelva a intentarlo</Typography>
      ) : (
        children
      )}
    </EstudianteContext.Provider>
  );
};
