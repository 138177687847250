import { Template } from 'src/lib/templates';

export const devOrganizacionTemplate: Template = {
  name: 'Devolucion Organizacion Template',
  template: [
    {
      name: 'idDevolucionOrganizacion',
      type: {
        name: 'number',
      },
      optional: true,
    },
    {
      name: 'createdAt',
      type: {
        name: 'string',
      },
      optional: true,
    },
    {
      name: 'fechaAcap',
      type: {
        name: 'string',
      },
    },
    {
      name: 'createdBy',
      type: {
        name: 'any',
      },
      optional: true,
    },
    {
      name: 'grupoInscripcion',
      type: {
        name: 'any',
      },
    },
    {
      name: 'evaluaciones',
      type: {
        name: 'array',
        kind: [
          {
            name: 'idDevolucionEvaluacion',
            type: {
              name: 'number',
            },
            optional: true,
          },
          {
            name: 'devolucion',
            type: {
              name: 'any',
            },
            optional: true,
          },
          {
            name: 'valor',
            type: {
              name: 'number',
            },
          },
          {
            name: 'criterio',
            type: {
              name: 'any',
            },
          },
        ],
      },
    },
    {
      name: 'citarCv',
      type: {
        name: 'number',
      },
      validators: [
        {
          name: 'max',
          signature: [1],
        },
        {
          name: 'min',
          signature: [0],
        },
      ],
    },
    {
      name: 'observaciones',
      type: {
        name: 'string',
      },
      optional: true,
    },
  ],
};
