import { Rules } from 'src/lib/permission/types';
import { BLOCKER_ID, SIDEMENU_ID, TABBAR_BLOCKER_ID } from '../../const';

// route docente
export const coordinadorOrientacionRulesSet: Rules = {
  idRule: 'rol-13',
  routes: [
    { key: 'matriculation' },
    { key: 'legajoAlumno' },
    { key: 'repositorioABM' },
    { key: 'planificacionesFeedback' },
    { key: 'configuration' },
    { key: 'configurationAdmin' },
    { key: 'gestionRoles' },
    { key: 'espacioscurriculares' },
    { key: 'presentismo' },
    { key: 'tablero' },
  ],
  blockers: [
    { id: BLOCKER_ID.BOLETINES },
    { id: BLOCKER_ID.COMUNICATIONS },
    { id: BLOCKER_ID.GESTION_PROYECCION },
    { id: BLOCKER_ID.LEGAJO },
    { id: BLOCKER_ID.PRESENTISMO },
    { id: BLOCKER_ID.REPORTS },
    { id: BLOCKER_ID.CONFIGURATIONS },
    { id: BLOCKER_ID.ROLES_MANAGEMENT },
    { id: BLOCKER_ID.REPORTS },
    { id: BLOCKER_ID.ESPACIOS_CURRICULARES },
    { id: BLOCKER_ID.REQUEST_ROL_ESTUDIANTE },
    { id: BLOCKER_ID.REQUEST_ROL_RESPONSABLE },
    { id: BLOCKER_ID.VALIDATE_SUPERVISOR_USER },
    { id: BLOCKER_ID.VIEW_USER_MANAGEMENT },
    { id: BLOCKER_ID.VALIDATE_DIRECTIVE_USER },
    { id: BLOCKER_ID.VALIDATE_USER_BY_SCHOOL },
    { id: BLOCKER_ID.VALIDATE_USER_INTEC },
    { id: BLOCKER_ID.ASSIGN_ROLES },
    { id: BLOCKER_ID.APPROVE_SUPERVISOR_USER },
    { id: BLOCKER_ID.APPROVE_DIRECTIVE_USER },
    { id: BLOCKER_ID.APPROVE_USERS },
    { id: BLOCKER_ID.REMOVE_SUPERVISOR_ESTABLISHMENT },
    { id: BLOCKER_ID.REMOVE_DIRECTIVE_ESTABLISHMENT },
    { id: BLOCKER_ID.REMOVE_USERS_ESTABLISHMENT },
    { id: BLOCKER_ID.VIEW_USERS },
    { id: BLOCKER_ID.EDIT_PROFILE_USERS },
    { id: BLOCKER_ID.EDIT_PROFILE_ESTUDENDS },
    { id: BLOCKER_ID.VIEW_MODULE_MATRICULACIONES },
    { id: BLOCKER_ID.ADD_SECCION },
    { id: BLOCKER_ID.EDIT_SECCION },
    { id: BLOCKER_ID.DELETE_SECCIONS },
    { id: BLOCKER_ID.ESTABLISHMENT_PASS_REQUEST },
    { id: BLOCKER_ID.ESTABLISHMENT_PASS_MANAGEMENT },
    { id: BLOCKER_ID.VIEW_PASS_REQUEST },
    { id: BLOCKER_ID.ADD_STUDENT },
    { id: BLOCKER_ID.EDIT_STUDENT },
    { id: BLOCKER_ID.DELETE_STUDENT },
    { id: BLOCKER_ID.VIEW_AREAL_PLANIFICATIONS },
    { id: BLOCKER_ID.COPY_SECCION },
    { id: BLOCKER_ID.CALIFICATIONS },
  ],
  sideMenu: [
    { id: SIDEMENU_ID.LEGAJO },
    { id: SIDEMENU_ID.CALENDAR },
    { id: SIDEMENU_ID.DOCUMENTS },
    { id: SIDEMENU_ID.REPOSITORY_ABM },
    { id: SIDEMENU_ID.MATRICULACION },
    { id: SIDEMENU_ID.GESTIONROLES },
    { id: SIDEMENU_ID.COMUNICATIONS },
    { id: SIDEMENU_ID.NOTIFICATIONS },
    { id: SIDEMENU_ID.CONFIGURACION },
    { id: SIDEMENU_ID.GESTIONROLES },
    { id: SIDEMENU_ID.ALUMNOS_AULAS_VRITUALES },
    { id: SIDEMENU_ID.CONFIGURACION_ADMIN },
    { id: SIDEMENU_ID.BOLETINES },
    { id: SIDEMENU_ID.APRENDIZAJE_BASADO_EN_PROYECTOS },
    { id: SIDEMENU_ID.CALIFICACIONES },
    { id: SIDEMENU_ID.CURRICULAR_SPACES },
    { id: SIDEMENU_ID.COMUNICACIONES },
    { id: SIDEMENU_ID.DASHBOARD },
  ],
  tabBars: [
    { id: TABBAR_BLOCKER_ID.MATRICULACION_SECCION },
    { id: TABBAR_BLOCKER_ID.MATRICULACION_MATRICULADOS },
    { id: TABBAR_BLOCKER_ID.MATRICULACION_INSCRIPTOS },
    { id: TABBAR_BLOCKER_ID.MATRICULACION_EXTERNOS },
    { id: TABBAR_BLOCKER_ID.MATRICULACION_PROMOTION },
    { id: TABBAR_BLOCKER_ID.MATRICULACION_GRUPOS },
    { id: TABBAR_BLOCKER_ID.PRESENTISMO_CONFIGURACION },
  ],
};
