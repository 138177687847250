import React, { useMemo } from 'react';
import {
  useArealTempPlanFetchById,
  useEspacioCurricularFetchById,
} from 'src/app/business';
import { ArealTempPlan, NewArealPlan } from 'src/app/models';

const initialValues = {
  arealPlanAreas: [],
  informacionGeneral: {},
  proyecto: {},
  objetivosDeAprendizaje: {
    objetivosDeAprendizaje: [],
  },
  espacioCurricular: {
    espaciosCurriculares: [
      {
        habilidades: [],
        contenidosEspecificos: [],
      },
    ],
  },
  actividad: {
    actividades: [
      {
        nombre: '',
        descripcion: '',
        horasCatedra: '',
        recursos: [],
        periodo: [],
      },
    ],
    periodoDeCalificacionFinalDelProyecto: [],
  },
  indicadoresDeEvaluacion: {
    indicadores: [],
    adicionales: [],
  },
  bibliografia: {},
};

export type childrenFunc = ({
  template,
  working,
  initialValues,
}: {
  template?: ArealTempPlan;
  working: boolean;
  initialValues: NewArealPlan;
}) => JSX.Element;

export interface ArealInterarealNewProps {
  children: childrenFunc;
  templateId: string | number;
  idEspacioCurricular: string | number;
}

export const ArealInterarealNew = ({
  children,
  templateId,
  idEspacioCurricular,
}: ArealInterarealNewProps) => {
  const { data: template, working: workingTemplate } =
    useArealTempPlanFetchById(templateId);
  const { data: espacioCurricular, working: workingEspCurricular } =
    useEspacioCurricularFetchById(idEspacioCurricular);

  const initialValuesMod = useMemo<NewArealPlan>(() => {
    return JSON.parse(
      JSON.stringify(
        espacioCurricular
          ? {
              ...initialValues,
              arealPlanAreas: [{ area: espacioCurricular.materia?.area }],
              espacioCurricular: {
                espaciosCurriculares: [
                  {
                    espacioCurricular: {
                      id: espacioCurricular.idEspacioCurricular,
                      tieneBloque: espacioCurricular.materia.tieneBloque,
                    },
                    habilidades: [],
                    contenidosEspecificos: [],
                  },
                ],
              },
            }
          : initialValues,
      ),
    );
  }, [espacioCurricular]);

  return (
    <>
      {children({
        template,
        initialValues: initialValuesMod,
        working: workingEspCurricular || workingTemplate,
      })}
    </>
  );
};
