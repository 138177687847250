import React from 'react';
import { Organizacion } from 'src/app/models';
import { SedeFormInitialValue } from '../../types';

export const makeInitialValue = (values: Organizacion) => {
  const fechaInicio = values?.vigenciaStarts;
  const fechaFin = values?.vigenciaEnds;
  const mainSede = values?.sedes.find((s) => s.isMain);
  return {
    idSede: mainSede?.idSede || 0,
    idOrganizacion: values?.idOrganizacion || 0,
    nombre: values?.nombre || '',
    sector: values?.sector?.idSector || 0,
    cuit: values?.cuit || '',
    domicilio: mainSede?.direccion || '',
    telefono: values?.telefono || '',
    email: values?.email || '',
    turnos: mainSede?.turnos || [],
    fechaInicio: fechaInicio ? new Date(fechaInicio) : null,
    fechaFin: fechaFin ? new Date(fechaFin) : null,
    referente: {
      idReferente: values?.referente?.idReferente || null,
      nombre: values?.referente?.nombre || '',
      telefono: values?.referente?.telefono || '',
      email: values?.referente?.email || '',
      codigo: values?.referente?.codigo ?? 'Miescuela123',
      apellido: values?.referente?.apellido || '',
      isResetPassword: false,
    },
    sedes:
      values?.sedes
        .filter((s) => !s.isMain)
        .map<SedeFormInitialValue>((s) => ({
          idSede: s.idSede,
          nombre: s.nombre,
          direccion: s.direccion,
          turnos: s.turnos || [],
          isMain: s.isMain,
        })) || [],
  };
};
