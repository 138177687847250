import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Responsable } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'responsable';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idResponsable',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idResponsable',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Responsable
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Responsable,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Responsable.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Responsable>(DAO_NAME, optionsToUse);

// hooks
export const useResponsableFetchById = fetchById;
export const useResponsableLibbyCall = libbyCall;
export const useResponsableDAO = libbyDAO;
export const useResponsableLibbyFetch = libbyFetch;

// components/hoc
export const ResponsableAutocomplete = entityAutocomplete;
export const ResponsableCall = renderPropCall;
export const ResponsableById = renderPropFetchById;
export const ResponsableSelect = simpleSelect;
export const ResponsableAccordionSelector = accordionSelector;
export const ResponsableSelectedChips = selectedChips;

// context
// ByIdContext
export const useResponsableByIdContext = useByIdContext;
export const ResponsableByIdProvider = ByIdProvider;
export const ResponsableByIdContext = ByIdContext;
// GeneralContext
export const useResponsableContext = useGeneralContext;
export const ResponsableProvider = GeneralProvider;
export const ResponsableContext = GeneralContext;
