import React from 'react';
import { Publicacion } from 'src/app/models';
import { Loading } from 'src/commons/components/Loading';
import { NuevaPublicacionForm } from './components';
import { InitialValueDefinition } from './components/NuevaPublicacionForm';
import { PublicacionEditValue } from './components/PublicacionEditValue';

export const initialValue: InitialValueDefinition = {
  descripcion: '',
  idRol: undefined,
  comentable: false,
  idEspacioCurricular: undefined,
};

const fromPublicacionToInitialValue = (
  publicacion: Publicacion,
): InitialValueDefinition => ({
  descripcion: publicacion.descripcion,
  idRol: publicacion.rol?.idRolUsuario,
  comentable: publicacion.comentable,
  idEspacioCurricular: publicacion.idEspacioCurricular,
});

export interface PublicacionFormProps {
  raw: Publicacion;
  idPublicacion?: number;
  reFetch?: () => void;
}

export const PublicacionForm = ({
  idPublicacion,
  reFetch,
}: PublicacionFormProps) => {
  return !idPublicacion ? (
    <NuevaPublicacionForm initialValues={initialValue} reFetch={reFetch} />
  ) : (
    <PublicacionEditValue<InitialValueDefinition>
      idPublicacion={idPublicacion}
      transformer={fromPublicacionToInitialValue}
    >
      {({ working, data, raw }) =>
        working || !data ? (
          <Loading />
        ) : (
          <NuevaPublicacionForm
            initialValues={data}
            raw={raw}
            reFetch={reFetch}
          />
        )
      }
    </PublicacionEditValue>
  );
};
