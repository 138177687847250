import React, { useState, useMemo, useEffect, useCallback } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { Box, IconButton } from '@material-ui/core';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useRolesContext } from 'src/context/RolesContext';
import { ModalidadType } from 'src/app/models';
import { AnyObject, InfoTable, LibbyObject, useLibbyCall } from 'src/commons';
import customFormDialog from 'src/commons/services/customFormDialog';
import { grey } from 'src/theme/colors';
import { generatedArrSearch } from 'src/utils';
import { useDebounce } from 'src/commons/hooks/useDebounce';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useLegajoLogic } from 'src/screens/Private/LegajoAlumno';
import { useMatriculaPersonaExternaLibbyFetch } from 'src/app/business';
import { ROL } from 'src/commons/const/rol';
import { FilterTablesExternal, ExternalModal } from './components';

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  alumnoButton: {
    color: '#00A0D61',
    margin: '0',
    background: 'none',
    textAlign: 'left',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
};

const InitialExternalRaw = ({ libby, paramsNivel }: LibbyObject) => {
  const roleInfo = useRolesContext();
  const {
    localizacionId,
    nivel: levelId,
    modalidad,
    rol,
  } = roleInfo?.selectedRole;

  const filterDefault = [{ path: 'localizacion', value: localizacionId }];
  const filterLevel =
    modalidad === ModalidadType.General
      ? [{ path: 'nivel.idNivel', value: levelId }]
      : null;

  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('');
  const [filterLibby, setFilterLibby] = useState<AnyObject>({
    0: filterDefault,
    1: filterLevel,
  });
  const [formValues, setFormValues] = useState({
    nivel: '',
    actividadExtracurricular: '',
  });

  const [search, setSearch] = useState('');
  const searchDebounced = useDebounce(search, 1000);

  const pathSearch = useMemo(
    () => [
      'persona.nombre',
      'persona.apellido',
      'persona.documento',
      'actividadExtracurricular.descripcionActividad',
      'nivel.descripcionNivel',
      'localizacionMatriculadoEn.descripcion',
    ],
    [],
  );

  const paramsFetch = useMemo(
    () => ({
      orderBy,
      direction,
      filter: filterLibby,
      aspect: 'externos',
    }),
    [filterLibby, direction, orderBy],
  );

  const {
    data: matricula_persona_externa = [],
    working,
    fetchMore,
    reFetch,
  } = useMatriculaPersonaExternaLibbyFetch(paramsFetch);

  const { data: actividad_extra_curricular = [] } = useLibbyCall(libby, {
    daoName: 'externos_actividad_extracurricular',
    methodName: 'getByIdLocalizacion',
    params: [localizacionId],
  });
  const { data: nivel } = useLibbyCall(libby, {
    daoName: 'nivel',
    methodName: 'getLevelsBySchool',
    params: paramsNivel || [],
  });

  const { data: matricula_select = [], reFetch: reFetchSelect } =
    useMatriculaPersonaExternaLibbyFetch({
      filter: filterLibby,
      aspect: 'externos',
    });

  const { toLegajoAlumno } = useLegajoLogic();

  const externalPersonLogicTable = (dataExternPerson: AnyObject) => {
    const rows = dataExternPerson.map(
      ({
        idMatriculaPersonaExterna,
        alumno,
        persona: { nombre, apellido, documento, idPersona },
        localizacionMatriculadoEn: { descripcion: localizacionMatriculado },
        actividadExtracurricular: { descripcionActividad },
        nivel: { descripcionNivel },
      }: AnyObject) => {
        return {
          id: idMatriculaPersonaExterna,
          idAlumno: alumno?.idAlumno,
          surname_name: (
            <button
              type="button"
              style={{
                color: '#00A0D61',
                margin: '0',
                background: 'none',
                border: 'none',
              }}
              onClick={() => toLegajoAlumno(alumno.idAlumno)}
            >
              {`${nombre} ${apellido}`}
            </button>
          ),
          document: documento,
          level: descripcionNivel,
          registered: localizacionMatriculado,
          enrolled: descripcionActividad,
          actions: (
            <Box display="flex" width="92%">
              <Box p={1}>
                <IconButton
                  onClick={() =>
                    removeIncription(
                      idMatriculaPersonaExterna,
                      ` ${apellido}, ${nombre}`,
                    )
                  }
                >
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Box>
          ),
        };
      },
    );

    return {
      rows,
    };
  };

  const externalPersonRow = externalPersonLogicTable(matricula_persona_externa);

  const filterTables = useCallback(
    (filterSearch: any) => {
      if (formValues.nivel || formValues.actividadExtracurricular) {
        const toReformFormValues = Object.entries(formValues)
          .filter((filter) => !!filter[1])
          .map(
            (filter: AnyObject) =>
              filter[1] && [{ path: filter[0], value: filter[1] }],
          );
        const toObjectLibby = {
          filterDefault,
          filterSearch,
          filterLevel,
          ...toReformFormValues,
        };
        setFilterLibby(toObjectLibby);
      } else {
        setFilterLibby({
          0: filterDefault,
          1: filterLevel,
          2: filterSearch,
        });
      }
    },
    // eslint-disable-next-line
    [formValues],
  );

  useEffect(() => {
    filterTables(generatedArrSearch(pathSearch, searchDebounced));
  }, [filterTables, pathSearch, searchDebounced]);

  const removeIncription = useCallback(
    async (id: string, dataPersona: string) => {
      const confirm = await confirmDialog.show({
        title: 'Confirmación',
        content: `¿Desea eliminar al alumno: "${dataPersona}" ?`,
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
      });
      if (confirm) {
        await libby.matricula_persona_externa
          .aspect('externos')
          .remove({ idMatriculaPersonaExterna: id });
        reFetch();
      }
    },
    [libby, reFetch],
  );

  const handleAddStudent = () => {
    customFormDialog.show({
      title: 'Agregar Estudiante',
      renderComponent: (
        <ExternalModal
          reFetch={reFetch}
          reFetchSelect={reFetchSelect}
          toLegajoAlumno={toLegajoAlumno}
          localizacionId={localizacionId}
        />
      ),
      sizeWidth: 'md',
      customModalServices: {
        '& .MuiPaper-root': { overflowY: 'auto', overflowX: 'hidden' },
      },
    });
  };

  const column = [
    {
      id: 'surname_name',
      label: 'Apellido y nombre',
      width: '10%',
      orderById: 'persona.apellido',
    },
    {
      id: 'document',
      label: 'Documento',
      width: '5%',
      orderById: 'persona.documento',
    },
    {
      id: 'level',
      label: 'Nivel',
      width: '5%',
      orderById: 'nivel.descripcionNivel',
    },
    {
      id: 'registered',
      label: 'Matriculado en',
      width: '15%',
      orderById: 'localizacionMatriculadoEn.descripcion',
    },
    {
      id: 'enrolled',
      label: 'Inscripto en',
      width: '15%',
      orderById: 'actividadExtracurricular.descripcionActividad',
    },
    {
      id: 'actions',
      label: '',
      width: '5%',
      hideSortIcon: true,
      noSort: true,
    },
  ];

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  const handleChange = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleChangeSearch = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setSearch(target.value);
  };

  const filterNivelQuantity = nivel.map((t: any) => {
    const quantity = matricula_select.filter(
      (e) => t.idNivel === e.nivel.idNivel,
    );
    return {
      idNivel: t.idNivel,
      descripcionNivel: `${t.descripcionNivel} (${quantity?.length} alumnos)`,
    };
  });

  const arrFilterTables = [
    [
      {
        title: 'Nivel',
        placeholder: 'Seleccioná Nivel',
        labelKey: 'descripcionNivel',
        valueKey: 'idNivel',
        content: filterNivelQuantity,
        handleChange,
        value: formValues.nivel,
        name: 'nivel',
      },
      {
        title: 'Inscripto en',
        placeholder: 'Seleccioná Inscripto',
        labelKey: 'descripcionActividad',
        valueKey: 'idActividadExtracurricular',
        content: actividad_extra_curricular,
        handleChange,
        value: formValues.actividadExtracurricular,
        customStyleTitle: customStyles.customStyleTitleSelect,
        name: 'actividadExtracurricular',
      },
    ],
    [
      {
        input: true,
        handleChange: handleChangeSearch,
        placeholder: 'Buscar',
        value: search,
      },
    ],
  ];

  return (
    <div>
      <FilterTablesExternal
        content={arrFilterTables}
        titleMainButton={
          rol === ROL.DIRECCION_AREA.toString()
            ? 'Agregar estudiante'
            : undefined
        }
        handleOnClick={handleAddStudent}
      />
      <InfoTable
        rows={externalPersonRow.rows}
        columns={column}
        working={working}
        onBottomScroll={fetchMore}
        rowIdKey="id"
        orderBy={orderBy}
        direction={direction}
        onSortChange={handleRequestSort}
      />
    </div>
  );
};

export const InitialExternal = DatabaseConnector(InitialExternalRaw)(
  'matricula_persona_externa',
  'nivel',
  'actividad_extra_curricular',
);
