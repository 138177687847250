import React, { useMemo } from 'react';
import { Card, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { grey } from 'src/theme/colors';
import {
  FeedbackPlanItem,
  FeedbackPlanOpcion,
  FeedbackPlanSeccion,
} from '../../../../../../../../app/models';
import {
  useFeedbackPlanSeccionLibbyCall,
  useFeedbackPlanOpcionLibbyCall,
} from '../../../../../../../../app/business';
import { Loading } from '../../../../../../../../commons/components/Loading';
import { useNormalizeData } from '../../../../../../../../commons/hooks';
import { FeedbackSeccionCard } from './FeedbackSeccionCard';
import { FeedbackItemCard } from './FeedbackItemCard';
import { FeedbackTemplate } from '../../../../../../../../commons/types';

const useStyles = makeStyles(() => ({
  headerTitle: {
    '&& .MuiCardHeader-title': {
      color: grey.textPrimary,
      fontSize: 24,
      paddingTop: 15,
    },
  },
  cardContent: {
    paddingTop: 0,
  },
}));

export interface PlanificacionFeedbackViewProps {
  data?: {
    feedback: FeedbackTemplate[];
  };
}

interface MappedItem extends FeedbackPlanItem {
  comentario?: string;
  opcion: FeedbackPlanOpcion | null;
}

interface MappedSeccion extends FeedbackPlanSeccion {
  items: MappedItem[];
}

export const PlanificacionFeedbackView = ({
  data,
}: PlanificacionFeedbackViewProps) => {
  const classes = useStyles();
  const { data: secciones, working: workingSecciones } =
    useFeedbackPlanSeccionLibbyCall<FeedbackPlanSeccion[]>({
      methodName: 'findAll',
    });
  const { data: opciones, working: workingOpciones } =
    useFeedbackPlanOpcionLibbyCall<FeedbackPlanOpcion[]>({
      methodName: 'findAll',
    });

  const normalizedData = useNormalizeData<FeedbackTemplate>(
    data?.feedback,
    'item',
  );
  const normalizedOpciones = useNormalizeData<FeedbackPlanOpcion>(
    opciones,
    'idFeedbackPlanOpcion',
  );

  const mappedSecciones = useMemo<MappedSeccion[]>(() => {
    if (!secciones || !opciones) {
      return [];
    }
    return (
      secciones
        // le agregamos el valor de los feedback
        .map<MappedSeccion>((seccion) => ({
          ...seccion,
          items: seccion.items
            .filter((item) => !!normalizedData[item.idFeedbackPlanItem])
            .map((item) => {
              const value = normalizedData[item.idFeedbackPlanItem];
              return {
                ...item,
                comentario: value.comentario,
                opcion: !value.opcion ? null : normalizedOpciones[value.opcion],
              };
            }),
        }))
        // filtramos las secciones vacias
        .filter((seccion) => !!seccion.items.length)
    );
  }, [normalizedData, normalizedOpciones, opciones, secciones]);
  let itemCounter = 0;
  return workingSecciones || workingOpciones ? (
    <Loading />
  ) : !data ? (
    <h1>No existe feedback disponible</h1>
  ) : (
    <Card>
      <CardHeader title="Asesoramiento" className={classes.headerTitle} />
      <CardContent className={classes.cardContent}>
        {mappedSecciones.map((seccion, index: number) => (
          <FeedbackSeccionCard
            key={seccion.idFeedbackPlanSeccion}
            descripcion={seccion.descripcion}
            posicion={`${index + 1}/${mappedSecciones.length}`}
          >
            {seccion.items.map((item) => {
              itemCounter += 1;
              return (
                <FeedbackItemCard
                  key={item.idFeedbackPlanItem}
                  descripcion={`${itemCounter}. ${item.descripcion}`}
                  opcion={item.opcion?.descripcion}
                  comentario={item.comentario}
                />
              );
            })}
          </FeedbackSeccionCard>
        ))}
      </CardContent>
    </Card>
  );
};
