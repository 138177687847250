import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import { Box } from '@material-ui/core';
import moment from 'moment';
import { EspaciosInterdiscipPlan } from '../../../../../../../app/models';
import { OptionsModal } from '../../../../../../../commons/components/OptionsModal';
import { makeActionButtons } from './makeActionButtons';

const useActionButtons = makeActionButtons(
  'idEspaciosInterdiscipPlan',
  (row: EspaciosInterdiscipPlan) => row.interdiscipPlan.planificacionEstados,
  'interdiscipPlan',
);

export const useInterDisciplinarTableColumns = () => {
  const { url } = useRouteMatch();
  const actionButtons = useActionButtons({
    urls: {
      edit: `${url}/planificacion/interDisciplinar`,
      feedback: `${url}/planificacionfeedback/interDisciplinar`,
      preview: `${url}/planificacionpreview/interDisciplinar`,
    },
    helper: {
      delete: () => console.log('TODO: eliminar'),
    },
  });
  return useMemo(
    () => [
      {
        id: 'espacioCurricular.Area',
        label: 'Orientaciones',
        width: '15%',
        orderById:
          'interdiscipPlan.interdiscipOrientaPlans.orientacion.descripcionOrientacion',
        render: (value: any, row: EspaciosInterdiscipPlan) =>
          row?.interdiscipPlan?.interdiscipOrientaPlans
            .map((item) => item.orientacion.descripcionOrientacion)
            .join(', '),
      },
      {
        id: 'espacioCurricular.PlanEstudio',
        label: 'Proyecto',
        width: '15%',
        orderById: 'espacioCurricular.planEstudio.descripcionPlanEstudio',
        render: (value: any, row: EspaciosInterdiscipPlan) => (
          <Box whiteSpace="nowrap">{row.interdiscipPlan.proyecto.nombre}</Box>
        ),
      },
      {
        id: 'tipo',
        label: 'Tipo',
        width: '13%',
        style: {
          whiteSpace: 'nowrap',
        },
        hideSortIcon: true,
        render: () => 'Interdisciplinar',
      },
      {
        id: 'interdiscipPlan.ultimaActualizacion',
        label: 'Ultima modificación',
        width: '10%',
        render: (value: any, row: EspaciosInterdiscipPlan) => (
          <Box whiteSpace="nowrap">
            {row.interdiscipPlan.ultimaActualizacion
              ? moment(row.interdiscipPlan.ultimaActualizacion).format(
                  'DD/MM/YYYY - HH:mm',
                )
              : '-'}
          </Box>
        ),
      },
      {
        id: 'estado',
        label: 'Estado',
        width: '10%',
        orderById: 'interdiscipPlan.planificacionEstados.nombre',
        render: (value: any, row: EspaciosInterdiscipPlan) => {
          return (
            <Box whiteSpace="nowrap">
              {row.interdiscipPlan.planificacionEstados.nombre ?? '-'}
            </Box>
          );
        },
      },
      {
        id: 'nombre',
        label: 'Creado Por',
        width: '10%',
        style: { textAlign: 'center' },
        orderById: 'interdiscipPlan.cuentas.nombreUsuario',
        render: (value: any, row: EspaciosInterdiscipPlan) => {
          return (
            <Box whiteSpace="nowrap" textAlign="center">
              {`${row.interdiscipPlan.cuentas.nombreUsuario} ${row.interdiscipPlan.cuentas.apellidoUsuario}` ??
                '-'}
            </Box>
          );
        },
      },
      {
        id: 'icono',
        label: '',
        width: '5%',
        style: { textAlign: 'right' },
        hideSortIcon: true,
        render: (value: any, row: EspaciosInterdiscipPlan) => (
          <Box whiteSpace="nowrap" textAlign="right">
            <OptionsModal options={actionButtons} data={row} />
          </Box>
        ),
      },
    ],
    [actionButtons],
  );
};
