import React from 'react';
import { Grid, Button, makeStyles } from '@material-ui/core';
import customFormDialog from 'src/commons/services/customFormDialog';
import { PublicacionForm } from '../../../../../PublicacionForm';

export interface PublicacionFormModalProps {
  idPublicacion: number;
  reFetch?: () => void;
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    marginTop: theme.spacing(-6),
    position: 'relative',
  },
  modalContainer: {
    padding: theme.spacing(2),
  },
}));

const handleCloseModal = async () => {
  await customFormDialog.handleCancel();
};

export const PublicacionFormModal = ({
  idPublicacion,
  reFetch,
}: PublicacionFormModalProps) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.modalContainer}>
      <Grid container justify="flex-end">
        <Button className={classes.closeButton} onClick={handleCloseModal}>
          X
        </Button>
      </Grid>
      <PublicacionForm idPublicacion={idPublicacion} reFetch={reFetch} />
    </Grid>
  );
};
