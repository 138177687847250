import { Rules } from 'src/lib/permission/types';
import { coordinadorDeAreaRulesSets } from './coordinadorDeArea';
import { directorRulesSets } from './director';
import { docenteRuleSet } from './docente';
import { direccionAreaRuleSet, direccionAreaNoInicial } from './direccionArea';
import { asesorPedagogicoRulesSets } from './asesorPedagogico';
import { equipoConduccionRulesSets } from './equipoConduccion';
import { alumnoRuleSet } from './alumno';
import { adminRuleSet } from './admin';
import { supervisorRulesSets, supervisorInicialRulesSets } from './supervisor';
import { preceptorRulesSets } from './preceptor';
import { coordinadorOrientacionRulesSet } from './coordinadorOrientacion';
import { referenteACAPRulesSet } from './referenteAcap';
import { docenteJeRuleSet } from './docenteje';
import { coordinadorJERulesSets } from './coordinadorJE';

export const rolRules: Rules[] = [
  coordinadorJERulesSets,
  docenteRuleSet,
  docenteJeRuleSet,
  directorRulesSets,
  coordinadorDeAreaRulesSets,
  asesorPedagogicoRulesSets,
  equipoConduccionRulesSets,
  supervisorRulesSets,
  supervisorInicialRulesSets,
  alumnoRuleSet,
  adminRuleSet,
  direccionAreaRuleSet,
  direccionAreaNoInicial,
  preceptorRulesSets,
  coordinadorOrientacionRulesSet,
  referenteACAPRulesSet,
];
