import { Button, Grid, Radio, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { columns } from 'src/screens/Private/Matriculation/Enrolled/utils/const';
import { InfoTable, Loading } from 'src/commons/components';
import { ResultAlumnosSanatized } from 'src/platform/libby/dao/AlumnoExisteDAO';
import { grey, primary } from 'src/theme/colors';
import { level, levelType } from 'src/commons';
import { AddStudentForm, AlumnAdd } from './AddStudentForm';
import { useAlumnoExisteGetRequest } from 'src/app/business/businessCustomEndpoints';
import { CicloLectivo } from 'src/app/models';

const headerStyles = makeStyles(() => ({
  checkBox: {
    color: primary.lightBlue,
    paddingRight: '20px',
    width: '0px',
    height: '0px',
  },
  messageText: {
    fontSize: 14,
    color: grey.heading,
    marginBottom: 10,
  },
  reinscribirButton: {
    marginTop: 10,
    color: primary.lightBlue,
  },
  tooltip: {
    padding: '10px 10px',
    letterSpacing: '1.2px',
    fontSize: '12px',
  },
}));
interface EnrolledStudentSelectTableProps {
  formValues: any;
  commonSchool?: boolean;
  localizacionId: string | number;
  filterById?: any;
  handleAddExternalAlumn: (alumn: AlumnAdd) => Promise<void>;
  handleSelectStudent?: any;
  selectStudent?: any;
  cicloLectivoActual?: CicloLectivo;
}
type searchKeyValueType = {
  [key: string]: string;
};
const searchKeyValue: searchKeyValueType = {
  '1': 'apellido',
  '2': 'documento',
  '3': 'localizacion',
};
export const EnrolledStudentSelectTable = ({
  formValues,
  commonSchool,
  localizacionId,
  handleAddExternalAlumn,
  cicloLectivoActual,
}: EnrolledStudentSelectTableProps) => {
  const classes = headerStyles();
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('alumno.idAlumno');
  const [checkedReinscripto, setCheckedReinscripto] = useState(false);
  const [deletedStudent, setDeletedStudent] = useState<
    ResultAlumnosSanatized | undefined
  >();
  const [reinscribiendo, setReinscribiendo] = useState(false);
  const [value, setValue] = useState(-1);

  const { data = {}, working } = useAlumnoExisteGetRequest<any>({
    autoCall: true,
    url: `/api/public/alumnoexiste?${searchKeyValue[formValues.select]}=${
      formValues.params
    }`,
  });

  const studentRegistration = useMemo(() => {
    if (data?.alumnos) {
      return data.alumnos;
    }
    return [];
  }, [data]);

  const handleChangeCheckBox = (event: any, sr: ResultAlumnosSanatized) => {
    setCheckedReinscripto(event.target.value);
    setDeletedStudent(sr);
  };

  useEffect(() => {
    if (formValues) {
      setDeletedStudent(undefined);
    }
  }, [formValues]);

  const hasSameLocation = (
    localizacionId: string | number,
    studentLocalizacionId: string | number,
  ) => {
    return Number(localizacionId) === Number(studentLocalizacionId);
  };

  const usePassRequestTableLogic = useMemo(() => {
    const rows = studentRegistration?.map(
      (
        {
          idInscripcionAlumno,
          numDocument: documento,
          name: nombre,
          lastName: apellido,
          descripcion,
          localizacionId: studentLocalizacionId,
          descripcionNivel,
          isDeleted,
          hasMatriculacion,
        }: ResultAlumnosSanatized,
        index: number,
      ) => ({
        id: idInscripcionAlumno,
        name: (
          <Grid container alignItems="center">
            <Grid
              item
              style={{ maxWidth: '200px', display: 'flex', flexFlow: 'row' }}
            >
              <Grid
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {hasMatriculacion ||
                (hasSameLocation(localizacionId, studentLocalizacionId) &&
                  !isDeleted) ? (
                  <Tooltip
                    title={`${
                      hasMatriculacion
                        ? 'El estudiante se encuentra matriculado. Para reinscribirlo se debe iniciar el proceso de gestión de pase.'
                        : 'El estudiante ya se encuentra inscripto en este establecimiento.'
                    }`}
                    placement="right"
                    classes={{ tooltip: classes.tooltip }}
                  >
                    <span>
                      <Radio className={classes.checkBox} disabled />
                    </span>
                  </Tooltip>
                ) : (
                  <Radio
                    onChange={(e) => {
                      handleChangeCheckBox(e, studentRegistration[index]);
                      setValue(index);
                    }}
                    className={classes.checkBox}
                    color="default"
                    name="radio-button-demo[]"
                    checked={value === index}
                    value={index}
                  />
                )}
              </Grid>
              <Grid>{`${nombre} ${apellido}`}</Grid>
            </Grid>
          </Grid>
        ),
        document: documento,
        establishment: descripcion,
        level: descripcionNivel,
        registered: isDeleted ? 'Baja' : 'Regular',
      }),
    );
    return {
      rows,
    };
  }, [
    studentRegistration,
    localizacionId,
    classes.tooltip,
    classes.checkBox,
    value,
  ]);

  const handleRequestSort = useCallback(
    (newOrderBy: string, newDirection: 'asc' | 'desc') => {
      setOrderBy(newOrderBy);
      setDirection(newDirection);
    },
    [setOrderBy, setDirection],
  );

  const handleReinscribir = useCallback(() => {
    setReinscribiendo(true);
  }, [setReinscribiendo]);

  const sortRows = useCallback((row, direction, orderBy) => {
    let sortedRows = [];
    if (orderBy === 'idAlumno.idPersona.nombre') {
      sortedRows = row.sort((a: any, b: any) => {
        const valueA = a.name?.props?.children?.props?.children?.props
          ?.children[1]?.props?.children
          ? a.name?.props?.children?.props?.children?.props?.children[1]?.props?.children.toLowerCase()
          : a.name?.props?.children?.props?.children[1]?.props?.children.toLowerCase();
        const valueB = b.name?.props?.children?.props?.children?.props
          ?.children[1]?.props?.children
          ? b.name?.props?.children?.props?.children?.props?.children[1]?.props?.children.toLowerCase()
          : b.name?.props?.children?.props?.children[1]?.props?.children.toLowerCase();
        if (direction === 'asc') {
          return valueA.localeCompare(valueB);
        } else {
          return valueB.localeCompare(valueA);
        }
      });
    } else {
      sortedRows = row.sort((a: any, b: any) => {
        if (
          orderBy === 'idLocalizacion.idEstablecimineto.nombre' ||
          orderBy === 'idAlumno.idPersona.documento'
        ) {
          const typeOrderBy =
            orderBy === 'idLocalizacion.idEstablecimineto.nombre'
              ? 'establishment'
              : 'document';
          const valueA = a[typeOrderBy].toLowerCase();
          const valueB = b[typeOrderBy].toLowerCase();

          if (direction === 'asc') {
            return valueA.localeCompare(valueB);
          } else {
            return valueB.localeCompare(valueA);
          }
        }

        if (orderBy === 'level') {
          const levelA =
            level[a?.level?.toUpperCase() as keyof levelType] === undefined
              ? 5
              : level[a?.level?.toUpperCase() as keyof levelType];
          const levelB =
            level[b?.level?.toUpperCase() as keyof levelType] === undefined
              ? 5
              : level[b?.level?.toUpperCase() as keyof levelType];

          if (direction === 'asc') {
            if (levelA === levelB) {
              return 0;
            }
            if (levelA < levelB) {
              return -1;
            }
            return 1;
          } else {
            if (levelA === levelB) {
              return 0;
            }
            if (levelA > levelB) {
              return -1;
            }
            return 1;
          }
        }
      });
    }

    return sortedRows;
  }, []);

  const sortedRows = sortRows(
    usePassRequestTableLogic?.rows,
    direction,
    orderBy,
  );

  return working ? (
    <Loading />
  ) : (
    <>
      {usePassRequestTableLogic.rows.length && !reinscribiendo ? (
        <Grid>
          <Typography className={classes.messageText}>
            Seleccione estudiante a reinscribir.
          </Typography>
          <InfoTable
            working={working}
            rows={sortedRows}
            columns={columns}
            orderBy={orderBy}
            direction={direction}
            onSortChange={handleRequestSort}
            rowIdKey="id"
          />
          <Button
            disabled={!checkedReinscripto}
            onClick={handleReinscribir}
            className={classes.reinscribirButton}
          >
            Reinscribir
          </Button>
        </Grid>
      ) : (
        <Grid>
          <Typography className={classes.messageText}>
            El alumno ingresado no se encuentra en la base de datos. Por favor,
            completar los siguientes campos para darlo de alta:
          </Typography>
          <AddStudentForm
            localizacionId={localizacionId}
            commonSchool={commonSchool}
            handleAddExternalAlumn={handleAddExternalAlumn}
            cicloLectivoActual={cicloLectivoActual}
            deletedStudent={deletedStudent}
          />
        </Grid>
      )}
    </>
  );
};
