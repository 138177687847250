import { Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { FunctionComponent } from 'react';
import {
  FormAutocomplete,
  FormCheckbox,
  FormInput,
  useFormContext,
} from 'src/lib/templates';
import { useStyles } from '../styles';

interface AcompananteCardProps {
  index: number;
  onRemoveItem: (index: number) => any;
  isHistoric?: boolean;
}

const tiposAcompanante = [
  {
    label: 'Apoyo Personal No Docente',
    value: 'APND',
  },
  {
    label: 'Maestro/a de Apoyo Integradora',
    value: 'MAI',
  },
  {
    label: 'Maestro/a de Apoyo Psicológico',
    value: 'MAP',
  },
  {
    label: 'Maestro/a de Orientación Psicológica',
    value: 'MPO',
  },
  {
    label: 'Maestro/a de Apoyo Pedagógico de recuperación',
    value: 'MAPe',
  },
  {
    label: 'Asistente Celador/a',
    value: 'AC',
  },
];

export const AcompananteCard: FunctionComponent<AcompananteCardProps> = ({
  index,
  onRemoveItem,
  isHistoric,
}) => {
  const classes = useStyles();
  const { form } = useFormContext();

  return (
    <>
      <Grid item>
        <Grid container justify="flex-end" alignContent="flex-end">
          <Grid item className={classes.headerGrayContainer}>
            {!!index && !isHistoric && (
              <DeleteIcon
                className={classes.deleteIcon}
                onClick={() => onRemoveItem(index)}
              />
            )}
          </Grid>
        </Grid>
        <Grid item>
          <Grid container spacing={4}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormInput
                disabled={isHistoric}
                placeholder="Ingrese nombres"
                label="Nombres"
                name={`salud.datosAcompanante[${index}].nombres`}
                form={form}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormInput
                disabled={isHistoric}
                placeholder="Ingrese apellido"
                label="Apellido"
                name={`salud.datosAcompanante[${index}].apellido`}
                form={form}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormInput
                disabled={isHistoric}
                placeholder="Ingresá teléfono"
                label="Teléfono"
                name={`salud.datosAcompanante[${index}].telefonoAcompanante`}
                form={form}
                type="number"
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormAutocomplete
                disabled={isHistoric}
                placeholder="Selecciona tipo de acompañante"
                label="Tipo de acompañante"
                name={`salud.datosAcompanante[${index}].tipoAcompanante`}
                form={form}
                options={tiposAcompanante}
                optionLabelKey="label"
                optionToValue={(option: any) => option?.value}
                valueToOption={(value, options) =>
                  options.find((option: any) => option?.value === value)
                }
                getOptionSelected={(option: any, value: any) =>
                  option?.value === value?.value
                }
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormCheckbox
                disabled={isHistoric}
                label="Ocupa vacante"
                name={`salud.datosAcompanante[${index}].ocupaVacante`}
                form={form}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
