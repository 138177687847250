import React, { ChangeEvent } from 'react';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Box,
  FormHelperText,
  FormLabel,
  FormControl,
} from '@material-ui/core';
import { useField } from 'react-final-form-hooks';
import { getHelperTextNoTranslation } from '../hooks/makeGetHelperText';

export interface FormRadioGroupProps {
  name: string;
  form: any;
  label?: React.ReactNode;
  row?: boolean;
  options: Array<any>;
  valueKey?: string;
  labelKey?: string;
  loading?: boolean;
  customHelperText?: string;
  disabled?: boolean;
  optionToValue?: (value: any, options: any) => any;
  valueToOption?: (value: any, options: any) => any;
}

export const FormRadioGroup = ({
  name,
  form,
  row,
  options,
  label,
  valueKey = 'id',
  labelKey = 'description',
  disabled = false,
  optionToValue,
  valueToOption,
}: FormRadioGroupProps): JSX.Element => {
  const field = useField(name, form);

  const { error, helperText } = getHelperTextNoTranslation(field.meta);

  return (
    <FormControl error={error} disabled={disabled}>
      <FormLabel error={error}>{label}</FormLabel>
      <RadioGroup
        row={row}
        onFocus={field.input.onFocus}
        onBlur={field.input.onBlur}
        value={
          field.input.value
            ? valueToOption
              ? valueToOption(field.input.value, options)
              : field.input.value
            : ''
        }
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          const newValue = event.target.value;
          field.input.onChange(
            optionToValue ? optionToValue(newValue, options) : newValue,
          );
        }}
      >
        {options?.map((item) => (
          <FormControlLabel
            key={item[valueKey].toString()}
            value={item[valueKey].toString()}
            control={item?.control || <Radio id={`type-${item[valueKey]}`} />}
            label={item[labelKey]}
            disabled={item.disabled}
          />
        ))}
      </RadioGroup>
      <FormHelperText error={error}>{helperText}</FormHelperText>
    </FormControl>
  );
};
