import React, { useCallback, useMemo } from 'react';
import { InfoTableColumn } from '../../../../commons';
import { OfertaGrupo } from '../../../../app/models';
import { Grid, IconButton } from '@material-ui/core';
import { Tune } from '@material-ui/icons';
import { useEscuelaContext } from '../context/EscuelaContext';
import { DEVOLUCIONES_SCREEN } from '../enums/DevolucionesScreen';

export const columns: InfoTableColumn<OfertaGrupo>[] = [
  {
    id: 'ofertaTurno.oferta.accion.nombre',
    label: 'Acción',
    width: '20%',
    hideSortIcon: true,
  },
  {
    id: 'ofertaTurno.oferta.accion.tipoAccion.nombre',
    label: 'Tipo de acción',
    width: '10%',
    hideSortIcon: true,
  },
  {
    id: 'ofertaTurno.oferta.accion.organizacion.sector.nombre',
    label: 'Sector',
    width: '5%',
    hideSortIcon: true,
  },
  {
    id: 'ofertaTurno.oferta.accion.organizacion.nombre',
    label: 'Organización',
    width: '20%',
    hideSortIcon: true,
  },
  {
    id: 'equipo',
    label: 'Equipo',
    width: '10%',
    hideSortIcon: true,
    render: (value: string, row: OfertaGrupo) => {
      return (
        <>
          <p>{row.idOfertaGrupo}</p>
        </>
      );
    },
  },
];

export const useDevolucionesColumn = () => {
  const { setOfertaSelected, setCurrentTap } = useEscuelaContext();

  const handleClick = useCallback(
    (item: OfertaGrupo) => () => {
      setOfertaSelected(item);
      setCurrentTap(DEVOLUCIONES_SCREEN.ENROLES);
    },
    [setOfertaSelected, setCurrentTap],
  );

  const _columns = useMemo<InfoTableColumn<OfertaGrupo>[]>(
    () => [
      ...columns,
      {
        id: 'buttons',
        label: '',
        width: '5%',
        hideSortIcon: true,
        render: (_, row) => (
          <Grid>
            <IconButton onClick={handleClick(row)}>
              <Tune color="primary" />
            </IconButton>
          </Grid>
        ),
      },
    ],
    [handleClick],
  );

  return { columns: _columns };
};
