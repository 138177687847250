import { ROL } from '../../../../../commons';
import { ROLES_JERARQUICOS } from '../utils/rolesPresentismo';

export const useEnableToSaveByRol = (isJE: boolean, rol: number) => {
  return !(isJE
    ? [ROL.DOCENTE, ROL.COORDINADOR_JE].includes(rol)
    : ROL.DOCENTE === rol ||
      ROLES_JERARQUICOS.filter((rol) => rol !== ROL.COORDINADOR_JE).includes(
        rol,
      ));
};
