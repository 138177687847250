import React, { useEffect, useMemo } from 'react';
import { usePersonaLibbyCall } from 'src/app/business';
import { useGeneralContext } from 'src/context';
import { useRolesContext } from 'src/context/RolesContext';

export const usePersona = () => {
  const { userInfo } = useRolesContext();
  const {
    dispatch: { changeNewUser },
  } = useGeneralContext();

  const { data: persona, working } = usePersonaLibbyCall({
    methodName: 'getByCuentaId',
    params: [userInfo?.id],
  });

  useEffect(() => {
    if (persona) {
      changeNewUser(persona);
    } else {
      changeNewUser(null);
    }
  }, [persona, changeNewUser]);

  return { persona, working };
};
