import React, { ReactNode, useMemo, useCallback } from 'react';
import { MenuItem, Grid, TextField, TextFieldProps } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import { primary } from '../../../theme/colors';
import { Loading } from '../Loading';

// TODO: sacar el title --> label debe ser la prop adecuada
export type SelectInputProps = {
  title?: ReactNode;
  value: any;
  disabled?: boolean;
  handleChange: (value: any) => void;
  fullWidth?: boolean;
  options: any[]; // Se cambio por que chocaba con el content del TextFieldProps
  labelKey?: any;
  valueKey?: any;
  loading?: boolean;
  multiple?: boolean;
  render?: (option: any) => ReactNode;
} & Omit<TextFieldProps, 'content' | 'onChange'>;

export const SelectInput = ({
  disabled,
  fullWidth,
  title,
  variant,
  value,
  handleChange,
  options = [],
  labelKey,
  valueKey,
  loading = false,
  multiple,
  placeholder,
  SelectProps,
  label,
  render,
  ...rest
}: SelectInputProps) => {
  const renderValue = useCallback(
    (values: unknown) => {
      let toRender: any[] = [];
      if (values && Array.isArray(values)) {
        const _values = values as any[];
        if (_values.length === 0) {
          return placeholder;
        }
        toRender = _values.map((item) => {
          const option = options.find(
            (con: any) => `${_.get(con, valueKey)}` === `${item}`,
          );
          return !option ? '-' : _.get(option, labelKey);
        });
      }
      return toRender.join(', ') as ReactNode;
    },
    [options, labelKey, placeholder, valueKey],
  );

  return (
    <Grid>
      {loading ? (
        <Loading />
      ) : (
        <TextField
          disabled={disabled}
          fullWidth={fullWidth}
          select
          label={label || title}
          variant={variant}
          SelectProps={{
            IconComponent: ExpandMoreIcon,
            multiple,
            displayEmpty: true,
            ...(multiple ? { renderValue } : {}),
            ...SelectProps,
          }}
          onChange={handleChange}
          value={value || ''}
          {...rest}
        >
          {!!placeholder && (
            <MenuItem
              disabled={multiple}
              style={{ backgroundColor: primary.white, opacity: 1 }}
              value=""
            >
              {placeholder?.charAt(0).toUpperCase() +
                placeholder?.toLowerCase().slice(1)}
            </MenuItem>
          )}
          {options.map((option: any) => {
            const theValue = _.get(option, valueKey);
            return (
              <MenuItem
                disabled={option.disabled}
                key={theValue}
                id={theValue}
                value={theValue}
              >
                {render ? render(option) : _.get(option, labelKey)}
              </MenuItem>
            );
          })}
        </TextField>
      )}
    </Grid>
  );
};
