import moment from 'moment';

export const getDaysByRange = (
  startDate: string,
  endDate: string,
  onlyBussinessDays: boolean,
) => {
  const enabledDays = [];

  let start = moment(startDate, 'YYYY-MM-DD');
  const end = moment(endDate, 'YYYY-MM-DD');

  // Ajustamos las fechas al comienzo del día para evitar problemas con la hora
  start.startOf('day');
  end.startOf('day');

  const diferenciaEnDias = Math.abs(end.diff(start, 'days'));

  for (let day = 0; day <= diferenciaEnDias; day++) {
    const date = moment(start).add(day, 'days').format('YYYY-MM-DD');
    const weekDay = moment(date).day();
    if (onlyBussinessDays) {
      if (weekDay >= 1 && weekDay <= 5) {
        // Si el día es de lunes a viernes, lo agregamos a la lista de días hábiles
        enabledDays.push(date);
      }
    } else {
      // Si el día es de lunes a viernes, lo agregamos a la lista de días hábiles
      enabledDays.push(date);
    }
  }

  return enabledDays;
};
