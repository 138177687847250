import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSnackbar } from 'notistack';
import {
  SeccionProvider,
  useEspacioCurricularFetchById,
  useInterdiscipOrientaPlanDAO,
  useInterdiscipPlanDAO,
} from 'src/app/business';
import { useParams, useHistory } from 'react-router';
import { Loading } from 'src/commons';
import { useRolesContext } from 'src/context/RolesContext';
import { useGeneralContext } from 'src/context';
import { useEspaciosInterdiscipPlanDAO } from 'src/app/business/EspaciosInterdiscipPlan';
import {
  EspaciosInterdiscipPlan,
  InterdiscipOrientaPlan,
  InterdiscipPlan,
  InterdiscipTempPlan,
  NewInterdiscipPlan,
  Orientacion,
  Seccion,
} from 'src/app/models';
import { TabBar } from 'src/commons/components/TabBar';
import { FormContextProvider } from 'src/lib/templates';
import {
  PlanificationFooter,
  InterdisciplinarProvider,
} from '../commons/components';
import { FormProps } from '../commons/types';
import { useTemplateJoiner } from '../commons/hooks';
import { tabs } from './tabs';
import { InterdisciplinarHeader } from './components';
import { PLANIFICACION_ESTADOS } from '../../../../../../../commons/const';
import { DEFAULT_HIDE_TIME } from 'src/commons/const/ui/snackbar';

const templateMap = {
  informacionGeneral: 'informacionGeneralTemp',
  actividad: 'actividadTemp',
  bibliografia: 'bibliografiaTemp',
  espacioCurricular: 'espacioCurricularTemp',
  indicadoresDeEvaluacion: 'indicadoresDeEvaluacionTemp',
  objetivosDeAprendizaje: 'objetivosDeAprendizajeTemp',
  proyecto: 'proyectoTemp',
};

const isInterdiscipPlan = (
  item: InterdiscipPlan | NewInterdiscipPlan,
): item is InterdiscipPlan =>
  (item as InterdiscipPlan).idInterdiscipPlan !== undefined;

export const InterdisciplinarForm = ({
  initialValues,
  template,
}:
  | FormProps<InterdiscipPlan, NewInterdiscipPlan, InterdiscipTempPlan>
  | any) => {
  const [isDisabled, setIsDisabled] = useState<Boolean>(
    !isInterdiscipPlan(initialValues),
  );
  const [valuesUpdated, setValuesUpdated] = useState(initialValues);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedRole, userInfo } = useRolesContext();
  const { generalState } = useGeneralContext();
  // @ts-ignore
  const { idEspacioCurricular } = useParams();

  const { data: espacioCurricular } =
    useEspacioCurricularFetchById(idEspacioCurricular);

  // TODO: al autocomplete le falta el filtro de establecimiento
  const [orientaciones, setOrientaciones] = useState<Orientacion[]>(
    initialValues.interdiscipOrientaPlans
      ? initialValues.interdiscipOrientaPlans.map((value) => value.orientacion)
      : [],
  );

  // Unimos varios templates en uno solo
  const joinedTemplates = useTemplateJoiner(template, templateMap);

  const espaciosInterdiscipPlan = useEspaciosInterdiscipPlanDAO();
  const interdiscipPlanDAO = useInterdiscipPlanDAO();
  const interdiscipOrientaPlanDAO = useInterdiscipOrientaPlanDAO();
  const enviarCallBack = async () => {
    const newValue = {
      ...valuesUpdated,
      planificacionEstados: {
        idPlanificacionEstados: PLANIFICACION_ESTADOS.ENVIADO,
      },
    };
    await interdiscipPlanDAO.save(newValue);
    history.goBack();
    enqueueSnackbar('La planificación ha sido enviada con éxito', {
      variant: 'success',
      autoHideDuration: DEFAULT_HIDE_TIME,
    });
  };
  const onSubmit = useCallback(
    async (values) => {
      try {
        if (!isInterdiscipPlan(valuesUpdated)) {
          const planificacionData = {
            ...values,
            localizacion: { idLocalizacion: selectedRole.localizacionId },
            cuentas: { idUsuario: userInfo.id },
            interdiscipTempPlan: {
              idInterdiscipTempPlan: template.idInterdiscipTempPlan,
            },
            interdiscipOrientaPlans: orientaciones.map((orientacion) => ({
              orientacion: orientacion.idOrientacion,
            })),
          };
          const espacioData = {
            espacioCurricular: { idEspacioCurricular },
          };
          const inserted: EspaciosInterdiscipPlan =
            await espaciosInterdiscipPlan.aspect('limit_province').save({
              planificacionData,
              espacioData,
            });
          // Adding orientaciones
          const interdiscipOrientaP = await Promise.all(
            orientaciones.map((orientacion) => {
              const data: Omit<
                InterdiscipOrientaPlan,
                'idInterdiscipOrientaPlan' | 'planificacionEstados'
              > = {
                orientacion,
                interdiscipPlan: {
                  idInterdiscipPlan: inserted.interdiscipPlan.idInterdiscipPlan,
                },
              };
              return interdiscipOrientaPlanDAO
                .aspect('limit_province')
                .save(data);
            }),
          );
          const interDisciplinar = {
            interdiscipOrientaPlans: interdiscipOrientaP.map(
              ({ idInterdiscipOrientaPlan, orientacion }) => ({
                idInterdiscipOrientaPlan,
                orientacion,
              }),
            ),
            ...interdiscipOrientaP[0].interdiscipPlan,
          };
          enqueueSnackbar('Se ha guardado con éxito', {
            variant: 'success',
            autoHideDuration: DEFAULT_HIDE_TIME,
          });
          setValuesUpdated(interDisciplinar);
          setIsDisabled(false);
        } else {
          const updatedInterDiscip = {
            ...valuesUpdated,
            ...values,
            planificacionEstados: {
              idPlanificacionEstados: PLANIFICACION_ESTADOS.EN_PROCESO,
            },
          };
          updatedInterDiscip.ultimaActualizacion = new Date();

          const updated: InterdiscipPlan = await interdiscipPlanDAO.save(
            updatedInterDiscip,
          );
          // Adding news
          const newOrientaciones = orientaciones.filter(
            (orientacion) =>
              !valuesUpdated.interdiscipOrientaPlans.find(
                (item) =>
                  item.orientacion.idOrientacion === orientacion.idOrientacion,
              ),
          );
          if (newOrientaciones.length) {
            await Promise.all(
              newOrientaciones.map((orientacion) => {
                const data: Omit<
                  InterdiscipOrientaPlan,
                  'idInterdiscipOrientaPlan' | 'planificacionEstados'
                > = {
                  orientacion,
                  interdiscipPlan: {
                    idInterdiscipPlan: updated.idInterdiscipPlan,
                  },
                };

                return interdiscipOrientaPlanDAO
                  .aspect('limit_province')
                  .save(data);
              }),
            );
          }
          // Removing
          const toRemove = valuesUpdated.interdiscipOrientaPlans.filter(
            (item) =>
              !orientaciones.find(
                (orientacion) =>
                  orientacion.idOrientacion === item.orientacion.idOrientacion,
              ),
          );
          if (toRemove.length) {
            await Promise.all(
              toRemove.map((item) =>
                interdiscipOrientaPlanDAO.aspect('limit_province').remove(item),
              ),
            );
          }
          enqueueSnackbar('Se ha guardado con éxito', {
            variant: 'success',
            autoHideDuration: DEFAULT_HIDE_TIME,
          });
          setValuesUpdated(updated);
          setIsDisabled(false);
        }
      } catch (e) {
        // TODO: fix error de libby
        console.log(e);
      }
    },
    [
      valuesUpdated,
      selectedRole.localizacionId,
      userInfo.id,
      template.idInterdiscipTempPlan,
      orientaciones,
      idEspacioCurricular,
      espaciosInterdiscipPlan,
      enqueueSnackbar,
      interdiscipOrientaPlanDAO,
      interdiscipPlanDAO,
    ],
  );

  const orientacionesOptions = useMemo(() => {
    if (initialValues.interdiscipOrientaPlans) {
      return initialValues.interdiscipOrientaPlans.map(
        (item: any) => item.orientacion,
      );
    }
    return [];
  }, [initialValues.interdiscipOrientaPlans]);

  const onChangeAreas = useCallback((value) => setOrientaciones(value), []);

  let filterAnio: any = [];
  if (generalState?.espacioCurricular.planificacion.selected.anio) {
    filterAnio = [
      {
        path: 'anio.idAnio',
        value: generalState?.espacioCurricular.planificacion.selected.anio,
      },
    ];
  }

  const seccionFilter = useRef({
    filterAnio,
    1: [{ path: 'localizacion', value: selectedRole?.localizacionId }],
    seccion: generalState
      ? generalState.espacioCurricular.planificacion.selected.seccion
          ?.filter((item: Seccion) => typeof item.idSeccion === 'number')
          .map((item2: Seccion) => ({
            path: 'seccion.idSeccion',
            value: item2.idSeccion || 0,
          }))
      : [],
  });

  const depuredInitialValue = useMemo<NewInterdiscipPlan>(() => {
    if (!isInterdiscipPlan(initialValues)) {
      return initialValues;
    }
    const {
      idInterdiscipPlan,
      interdiscipOrientaPlans,
      interdiscipTempPlan,
      cuentas,
      ultimaActualizacion,
      localizacion,
      planificacionEstados: pe,
      ...rest
    } = initialValues;
    return rest;
  }, [initialValues]);

  useEffect(() => {
    if (
      espacioCurricular?.materia.orientacion !== undefined &&
      orientacionesOptions.length === 0
    ) {
      setOrientaciones([espacioCurricular.materia.orientacion]);
    } else {
      setOrientaciones(orientacionesOptions);
    }
  }, [espacioCurricular, orientacionesOptions]);

  useEffect(() => {
    if (isInterdiscipPlan(initialValues)) {
      setOrientaciones(
        initialValues.interdiscipOrientaPlans.map((value) => value.orientacion),
      );
    }
  }, [initialValues]);

  return (
    <>
      <InterdisciplinarHeader
        espacioCurricular={espacioCurricular}
        orientaciones={orientaciones}
        onChangeOrientaciones={onChangeAreas}
        localizacionId={selectedRole.localizacionId}
      />
      {!joinedTemplates ? (
        <Loading />
      ) : (
        <SeccionProvider filter={seccionFilter.current}>
          <InterdisciplinarProvider
            orientaciones={orientaciones}
            idAnio={espacioCurricular?.materia.anio.idAnio}
            idPlanEstudio={espacioCurricular?.planEstudio.idPlanEstudio}
          >
            <FormContextProvider
              template={joinedTemplates}
              initialValues={depuredInitialValue}
              onSubmit={onSubmit}
            >
              <>
                <TabBar content={tabs} />
                <PlanificationFooter
                  enviarCallBack={enviarCallBack}
                  isDisabled={isDisabled}
                  handleValuesChange={({ isDisabled }) =>
                    setIsDisabled(isDisabled)
                  }
                  orientaciones={orientaciones}
                />
              </>
            </FormContextProvider>
          </InterdisciplinarProvider>
        </SeccionProvider>
      )}
    </>
  );
};
