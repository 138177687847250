import React from 'react';
import { Divider, Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { grey } from 'src/theme/colors';

const useStyles = makeStyles((theme: Theme) => ({
  descipcionText: {
    color: grey.textPrimary,
    fontWeight: 400,
    margin: theme.spacing(2, 0, 2),
  },
  opcionText: {
    color: grey.textPrimary,
  },
  container: {
    marginBottom: theme.spacing(2),
  },
}));

export interface FeedbackItemCardProps {
  descripcion: string;
  opcion?: string | JSX.Element;
  comentario?: string | JSX.Element;
}

export const FeedbackItemCard = ({
  comentario,
  descripcion,
  opcion,
}: FeedbackItemCardProps) => {
  const classes = useStyles();
  return (
    <>
      <Typography className={classes.descipcionText}>{descripcion}</Typography>
      <Grid container spacing={2} className={classes.container}>
        <Grid item sm={4}>
          {typeof opcion === 'string' ? (
            <Typography className={classes.opcionText}>{opcion}</Typography>
          ) : (
            opcion
          )}
        </Grid>
        <Grid item sm={8}>
          {typeof comentario === 'string' ? (
            <Typography>{comentario}</Typography>
          ) : (
            comentario
          )}
        </Grid>
      </Grid>
      <Divider />
    </>
  );
};
