/* eslint-disable react/no-array-index-key */
import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { FormMulti } from 'src/lib/templates/components';
import { TextField } from 'src/app/components/inputs';
import { usePlanificationFormDisciplinarOrientada } from '../../hooks';
import { EspaciosCurricularesDialog } from '../../../../../dialogs';

const useStyles = makeStyles({
  formContainer: {
    padding: 16,
    margin: '5px 0',
    marginBottom: '60px',
  },
  textField: {
    paddingBottom: '17px',
  },
});

export const Bibliografia: FC = () => {
  const classes = useStyles();
  const { errors, values, form } = usePlanificationFormDisciplinarOrientada();

  return (
    <>
      <Grid container spacing={2} className={classes.formContainer}>
        <Grid item xs={12}>
          <FormMulti
            label="Bibliografía"
            name="bibliografia.bibliografia"
            form={form}
            addButtonText="Agregar Bibliografía"
            getNewElement={() => ''}
          >
            {(value, input, meta) => {
              const removeButton = <DeleteIcon onClick={input.onRemove} />;
              return (
                <TextField
                  value={value}
                  {...input}
                  InputProps={{ endAdornment: removeButton }}
                  error={meta.touched && !!meta.error}
                  helperText="El campo Bibliografia no puede estar vacío"
                  className={classes.textField}
                />
              );
            }}
          </FormMulti>
        </Grid>
      </Grid>
    </>
  );
};
