import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { primary, grey } from 'src/theme/colors';
import { Chip, Grid, TextField, Typography } from '@material-ui/core';
import {
  FormAutocomplete,
  FormFileInput,
  FormInput,
  useFormContext,
} from 'src/lib/templates';
import { useEpaLabelsContext } from './context/LabelsProvider';
import { useLegajoData } from 'src/screens/Private/LegajoAlumno';
import { toBase64 } from 'src/utils';
import confirmDialog from 'src/commons/services/confirmDialog';

const useStyles = makeStyles({
  grayContainer: {
    background: primary.whiteLight,
    margin: '0% 15px 85px 15px',
    borderRadius: '5px',
    padding: '20px',
  },
  headerGrayContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },
  text: {
    color: grey.textPrimary,
    fontSize: '20px',
  },
  selectContainer: {
    padding: '0 10em',
  },
  formContainer: {
    marginTop: '1.5em',
  },
  informeContainer: {
    marginTop: '3em',
  },
  textArea: {
    padding: '5px',
  },
  file: {
    width: '80%',
    border: '1px solid #cfcfcf',
    padding: '0em 2em',
    borderRadius: '5px',
    height: '3.5em',
    backgroundColor: 'white',
  },
  fileLabel: {
    paddingRight: '2em',
    fontSize: '15px',
    width: '100%',
    textAlign: 'left',
  },
  fileDeleteIcon: {
    '&:hover': {
      color: '#e29191',
    },
  },
  label: {
    marginBottom: 5,
    fontSize: 13,
    fontWeight: 600,
    color: '#7b7b7b',
  },
});

export type ParticipacionOption = { label: string; id: number };

const options: ParticipacionOption[] = [
  {
    id: 1,
    label: 'Si',
  },
  {
    id: 2,
    label: 'No',
  },
];

export const Epa = () => {
  const classes = useStyles();
  const { form, values, errors } = useFormContext();
  form.subscribe(
    (state) => {
      if (state.values.historialAcademico?.informeEpa?.participo === 2) {
        form.change('historialAcademico.informeEpa.url', undefined);
        form.change('historialAcademico.informeEpa.adjunto', undefined);
        form.change('historialAcademico.informeEpa.adjuntoName', undefined);
        form.change('historialAcademico.informeEpa.informe', '');
      }
    },
    { values: true },
  );
  const {
    moduleName,
    participatedLabel,
    informeTxtLabel,
    placeholderFileInput,
  } = useEpaLabelsContext();

  const { isHistoric } = useLegajoData();
  const informeEpa = values.historialAcademico?.informeEpa;

  const now = new Date();
  const isNotMarch = now.getMonth() !== 2;

  return (
    <Grid className={classes.grayContainer} item xs={12}>
      <Grid container>
        <Grid item xs={12} className={classes.headerGrayContainer}>
          <Typography className={classes.text}>{moduleName}</Typography>
        </Grid>
        <Grid item xs={12} classes={{ root: classes.formContainer }}>
          <Grid container spacing={4}>
            <Grid item xs={12} md={5}>
              <Typography variant="h4" className={classes.text}>
                {participatedLabel}
              </Typography>
            </Grid>

            <Grid item xs={12} md={5}>
              <FormAutocomplete
                placeholder="Seleccioná participación"
                label=""
                name="historialAcademico.informeEpa.participo"
                form={form}
                options={options || []}
                optionLabelKey="label"
                optionToValue={(option: ParticipacionOption) => option?.id}
                valueToOption={(value, options: ParticipacionOption[]) =>
                  options.find(
                    (option: ParticipacionOption) => option.id === value,
                  )
                }
                getOptionSelected={(
                  option: ParticipacionOption,
                  value: ParticipacionOption,
                ) => option.id === value.id}
                disabled={isHistoric || isNotMarch}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          classes={{
            root: classes.informeContainer,
          }}
        >
          <Grid container spacing={3} direction="column">
            <Grid item xs={12}>
              <FormInput
                disabled={
                  isHistoric || informeEpa?.participo === 2 || isNotMarch
                }
                placeholder="¿Cuál es el informe?"
                label={informeTxtLabel}
                name="historialAcademico.informeEpa.informe"
                form={form}
                type="textarea"
                validation={(informe, allValues) => {
                  if (
                    allValues.historialAcademico?.informeEpa?.participo === 1 &&
                    (informe === undefined || informe === '')
                  ) {
                    return 'Debe llenar el informe';
                  }
                }}
                inputProps={{
                  multiline: true,
                  rows: 4,
                  maxRows: 8,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item lg={4} md={4} sm={6} xs={6}>
                {informeEpa?.url ? (
                  <div>
                    <Typography className={classes.label}>
                      Comprobante
                    </Typography>
                    <Chip
                      classes={{
                        root: classes.files,
                        label: classes.fileLabel,
                        deleteIcon: classes.fileDeleteIcon,
                      }}
                      label={informeEpa?.adjuntoName}
                      onClick={() => {
                        window.open(informeEpa?.url, '_blank');
                      }}
                      onDelete={async () => {
                        const confirm = await confirmDialog.show({
                          title: 'Confirme la operación',
                          content: '¿Está seguro de querer cambiar el informe?',
                          confirmText: 'Cambiar',
                          cancelText: 'Cancelar',
                        });

                        if (confirm) {
                          form.change(
                            'historialAcademico.informeEpa.url',
                            undefined,
                          );
                          form.change(
                            'historialAcademico.informeEpa.adjunto',
                            undefined,
                          );
                          form.change(
                            'historialAcademico.informeEpa.adjuntoName',
                            undefined,
                          );
                        }
                      }}
                    />
                  </div>
                ) : (
                  <FormFileInput
                    disabled={
                      isHistoric ||
                      values.historialAcademico?.informeEpa?.participo === 2 ||
                      isNotMarch
                    }
                    label="Adjuntar Informe"
                    name="historialAcademico.informeEpa.adjunto"
                    form={form}
                    placeholder="Subir archivo"
                    validFileTypes={[
                      'application/pdf',
                      '.pdf',
                      'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                      'application/msword',
                      'image/jpeg',
                      '.jpg',
                      '.jpeg',
                      '.jpe',
                      'image/png',
                      'image/webp',
                      '.webp',
                    ]}
                    maxFileSize={'2MB'}
                    getFile={async (field: any, file: File) => {
                      const data = {
                        mime: file.type,
                        name: file.name,
                        base64: await toBase64(file),
                      };
                      field.input.onChange(data);
                    }}
                    validation={(adjunto, allValues) => {
                      if (
                        allValues.historialAcademico?.informeEpa?.participo ===
                          1 &&
                        (adjunto === undefined ||
                          ('value' in adjunto && adjunto.value === ''))
                      ) {
                        return 'Debe colocar el adjunto';
                      }
                    }}
                    custom
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
