export enum ROL {
  NO_ROL = -1,
  DIRECTIVO = 1,
  DOCENTE = 2,
  COORDINADOR_DE_AREA = 3,
  ASESOR_PEDAGOGICO = 4,
  EQUIPO_CONDUCCION = 5,
  ADMIN = 6,
  PRECEPTOR = 7,
  TUTOR = 8,
  ESTUDIANTE = 9,
  DIRECCION_AREA = 10,
  SUPERVISOR = 11,
  EQUIPO_ACAP = 12,
  COORDINADOR_ORIENTACION = 13,
  REFERENTE_ACAP = 14,
  COORDINADOR_JE = 16,
  DOCENTE_JE = 17,
}
