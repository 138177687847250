import { LibbyFetchDAO } from './LibbyFetchDAO';

export class CuentasDAO extends LibbyFetchDAO {
  constructor() {
    super('cuentas', 'idUsuario');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
