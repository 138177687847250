import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Button, Grid, makeStyles, TextField } from '@material-ui/core';
import {
  AnioSelectInput,
  SeccionSelectInput,
} from '../../../../../../app/business';
import {
  ContraturnoDia,
  EspacioCurricularSeccion,
  Seccion,
} from '../../../../../../app/models';
import { useRolesContext } from '../../../../../../context/RolesContext';
import { useAnioFilter, useSeccionFilter } from './hooks';
import moment from 'moment';
import { SelectorSemana } from '../SelectorSemana';
import SearchIcon from '@material-ui/icons/Search';
import { JustificacionesEspeciales } from '../JustificacionesEspeciales';
import { Jornada, usePresentismoContext } from '../../context';
import { ResumenDiario } from './components';
import confirmDialog from '../../../../../../commons/services/confirmDialog';
import { ROL } from '../../../../../../commons';
import { AlumnoMap } from '../../types';
import { SelectInput } from '../../../../../../commons/components/SelectInput';
import { useHistory } from 'react-router';
import { useRouterContext } from 'src/lib/router';
import { useGeneralContext } from 'src/context';
import { filter } from 'lodash';

const useStyles = makeStyles((theme) => ({
  mainHeaderContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gridTemplateRows: '1fr',
    marginBottom: '10px',
  },
  searchGrid: { marginTop: 10 },
  seccionEnabled: {
    width: '10em',
    height: 44,
    backgroundColor: '#00a0d6 !important',
    color: 'white',
    textTransform: 'none',
  },
  seccionDisabled: {
    width: '10em',
    height: 44,
    backgroundColor: '#8c8c8c',
    color: 'white !important',
    textTransform: 'none',
  },
  seccionSelect: {
    width: '133px',
    paddingRight: '100px',
    marginLeft: '1rem',
  },
  buttonEnabled: {
    width: '100%',
    height: 44,
    backgroundColor: '#00a0d6 !important',
    color: 'white',
    textTransform: 'none',
  },
  buttonDisabled: {
    width: '100%',
    height: 44,
    backgroundColor: '#8c8c8c',
    color: 'white !important',
    textTransform: 'none',
  },
  seccionValueSelect: {
    width: 'auto',
    marginLeft: '1rem',
  },
  buttonGroup: {
    width: '100%',
    justifyContent: 'center',
    border: 'solid 1px #00b3e3',
  },
  adaptacionButton: {
    color: 'orange',
  },
  asistenciaGeneralButton: {
    marginTop: '0.5rem',
  },
  descargarExcelButton: {
    marginTop: '0.5rem',
  },
  disabled: {
    color: '#8C8C8C',
  },
  suspensionClases: {
    color: 'orange',
  },
  rightContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
  },
  required: {
    position: 'relative',
    border: '1px solid red',
    borderRadius: '5px',
    '&:after': {
      content: '"Este campo es requerido para guardar un presentismo."',
      position: 'absolute',
      bottom: '-1.3rem',
      width: '100%',
      color: '#E36060',
      fontSize: '0.9rem',
    },
  },
  fieldset: {
    '& .MuiInputBase-root > fieldset': {
      border: 0,
    },
  },
  container: {
    minHeight: 150,
  },
  selectors: {
    minWidth: 200,
  },
  contentActions: {
    paddingTop: 20,
  },
  selectLabel: {
    fontSize: 16,
    letterSpacing: 0,
    color: '#38485C',
    fontWeight: 'bold',
    fontFamily: 'Nunito',
  },
  selectInput: {
    backgroundColor: '#EEF5FC',
    border: 'none',
    height: 38,
  },
  selectBg: {
    backgroundColor: '#EEF5FC',
  },
  button: {
    backgroundColor: '#3786EE',
    height: 35,
    width: 88,
    marginTop: 17,
    paddingRight: 4,
    paddingLeft: 4,
    padding: 3,
    marginLeft: 8,
    marginRight: 12,
    textTransform: 'none',
    borderRadius: 3,
    fontSize: 15,
    fontFamily: 'Nunito',
  },
}));

// TODO: terminar de tipar
export interface PresentismoHeaderProps {
  currentWeek: moment.Moment;
  pastDaysOfWeek: number;
  serverDate: string;
  handleChangeWeek: (value: 'next' | 'back' | 'today') => void;
  setPastDaysOfWeek: React.Dispatch<React.SetStateAction<number>>;
  selectedAnio: string | null;
  onAnioSelect: React.Dispatch<React.SetStateAction<string | null>>;
  anioDisabled: boolean;
  selectedSeccion: string | null;
  onSeccionSelect: React.Dispatch<React.SetStateAction<string | null>>;
  seccionDisabled: boolean;
  handleSeccionesChange: (secciones: any) => void;
  isTabJornadaExtendida?: boolean;
  search: string;
  handleSearchChange: React.Dispatch<React.SetStateAction<string>>;
  alumnoPresentismo: AlumnoMap[];
  tipoJornada: Jornada | null;
  reFetchAll: () => void;
  setJornadaExtendidaValidationDone: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  contraturnosDia: ContraturnoDia[];
  loadingGeneral: boolean;
  espaciosCurriculares: EspacioCurricularSeccion[];
  selectedEspacioCurricular?: string;
  setSelectedEspacioCurricular: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
}

export const PresentismoHeader: FC<PresentismoHeaderProps> = ({
  currentWeek,
  pastDaysOfWeek,
  serverDate,
  handleChangeWeek,
  setPastDaysOfWeek,
  selectedAnio,
  onAnioSelect,
  anioDisabled,
  selectedSeccion,
  onSeccionSelect,
  seccionDisabled,
  handleSeccionesChange,
  isTabJornadaExtendida,
  search,
  handleSearchChange,
  alumnoPresentismo,
  tipoJornada,
  reFetchAll,
  setJornadaExtendidaValidationDone,
  contraturnosDia,
  loadingGeneral,
  espaciosCurriculares,
  selectedEspacioCurricular,
  setSelectedEspacioCurricular,
}) => {
  const classes = useStyles();
  const history = useHistory();
  const {
    selectedRole: { nivel, localizacionId, rol },
  } = useRolesContext();
  const { propuestasJE, currentWeekState, periodoNuevo } =
    usePresentismoContext();
  const [anioFilter] = useAnioFilter(nivel, localizacionId);
  const { setMetadata, metadata } = useRouterContext();

  const onAnioChange = useCallback(
    (event: any) => {
      onSeccionSelect(null);
      onAnioSelect(event.target.value);
    },
    [onAnioSelect, onSeccionSelect],
  );

  const onSeccionChange = useCallback(
    (event: any) => {
      onSeccionSelect(event.target.value);
    },
    [onSeccionSelect],
  );

  const shootModalAlertAbsentOfPropuestaJE = useCallback(async () => {
    await confirmDialog.show({
      title: 'No hay propuestas cargadas',
      content:
        Number(rol) === ROL.COORDINADOR_JE
          ? 'No hay propuestas cargadas, por favor crearla para poder tomar presentismo'
          : 'No hay propuestas cargadas, Comuniquese con el coordinador de jornada extendida.',
      confirmText: 'Continuar',
      cancelText: 'Salir',
    });
  }, [rol]);

  useEffect(() => {
    if (
      Number(rol) === ROL.COORDINADOR_JE ||
      Number(rol) === ROL.DIRECTIVO ||
      Number(rol) === ROL.DOCENTE ||
      Number(rol) === ROL.EQUIPO_CONDUCCION
    ) {
      const propuestaParaLaSeccion = propuestasJE.find(
        (propuestaJe) => propuestaJe.seccion.idSeccion === selectedSeccion,
      );

      if (!propuestaParaLaSeccion && selectedSeccion && isTabJornadaExtendida) {
        shootModalAlertAbsentOfPropuestaJE();
        setJornadaExtendidaValidationDone(false);
      } else if (propuestaParaLaSeccion) {
        setJornadaExtendidaValidationDone(true);
      }
    }
  }, [
    selectedSeccion,
    propuestasJE,
    shootModalAlertAbsentOfPropuestaJE,
    isTabJornadaExtendida,
    rol,
    setJornadaExtendidaValidationDone,
  ]);

  useEffect(() => {
    setMetadata((prev) => {
      const { newPestana, ...newMetadata } = prev;
      return newMetadata;
    });
  }, [setMetadata]);

  const handleChangeEC = useCallback(
    (event: any) => {
      const ecSelected = espaciosCurriculares.find(
        (ec) => ec.espacioCurricular.idEspacioCurricular === event.target.value,
      );
      setSelectedEspacioCurricular(
        ecSelected?.espacioCurricular.idEspacioCurricular,
      );

      setMetadata((prev) => ({
        ...prev,
        newPestaña: 0,
        idSeccion: selectedSeccion,
      }));

      history.push(
        `/private/espacioscurriculares/${ecSelected?.espacioCurricular.idEspacioCurricular}/${ecSelected?.espacioCurricular?.materia.anio.idAnio}`,
      );
    },
    [
      espaciosCurriculares,
      history,
      setSelectedEspacioCurricular,
      setMetadata,
      selectedSeccion,
    ],
  );
  const filteredespaciosCurriculares = useMemo(() => {
    const result = espaciosCurriculares.filter(
      (ec) => ec.seccion.idSeccion === selectedSeccion,
    );
    return result;
  }, [espaciosCurriculares, selectedSeccion]);

  const fSeccion = useMemo(
    () => ({
      anio: [{ path: 'anio.idAnio', value: selectedAnio }],
      localizacion: [{ path: 'localizacion', value: localizacionId }],
    }),
    [selectedAnio, localizacionId],
  );

  return (
    <Grid container className={classes.container}>
      <Grid
        item
        xs={12}
        sm={8}
        md={8}
        container
        justifyContent="flex-start"
        spacing={1}
        className={classes.contentActions}
      >
        <Grid item className={classes.selectors}>
          <AnioSelectInput
            title="Año"
            placeholder="Año"
            name="anio"
            fullWidth
            labelKey="descripcionAnio"
            valueKey="idAnio"
            value={selectedAnio || metadata.anio || ''}
            orderBy="numeroAnio,descripcionAnio"
            handleChange={onAnioChange}
            filter={anioFilter}
            disabled={anioDisabled}
            InputLabelProps={{
              shrink: true,
              className: classes.selectLabel,
            }}
            InputProps={{
              className: classes.selectInput,
              disableUnderline: true,
            }}
            SelectProps={{ classes: { root: classes.selectBg } }}
          />
        </Grid>
        <Grid item className={classes.selectors}>
          <SeccionSelectInput
            aspect="presentismo"
            title="Sección"
            fullWidth
            placeholder="Sección"
            name="seccion"
            handleChange={onSeccionChange}
            value={selectedSeccion || metadata.idSeccion || ''}
            labelKey="nombreSeccion"
            valueKey="idSeccion"
            filter={fSeccion}
            disabled={seccionDisabled || !selectedAnio}
            orderBy="nombreSeccion"
            enableFetch={Boolean(selectedAnio)}
            onOptionsChange={handleSeccionesChange}
            transformOptions={(data) => {
              // TODO: no se para que es esto de matCount, voy a ignorar esto cuando se JE y nivel sea secundaria porque no nos anda el select
              // Determiina si tiene matriculados
              if (isTabJornadaExtendida && nivel.toString() === '3')
                return data;
              return (data as Seccion[]).filter(
                (seccion) => seccion.matCount! > 0,
              );
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.selectLabel,
            }}
            InputProps={{
              className: classes.selectInput,
              disableUnderline: true,
            }}
            SelectProps={{ classes: { root: classes.selectBg } }}
          />
        </Grid>
        <Grid item className={classes.selectors}>
          <SelectorSemana
            currentWeek={currentWeekState}
            pastDaysOfWeek={pastDaysOfWeek}
            serverDate={serverDate}
            handleChangeWeek={handleChangeWeek}
            periodoNuevos={periodoNuevo}
            disableSelectors={
              !Boolean(selectedAnio) ||
              !Boolean(selectedSeccion) ||
              anioDisabled ||
              loadingGeneral
            }
          />
          <Button
            className={classes.button}
            variant="contained"
            color="primary"
            onClick={() => {
              setPastDaysOfWeek(moment().day() - 1);
              handleChangeWeek('today');
            }}
            disabled={anioDisabled}
          >
            {`Hoy ${moment().format('DD/MM')}`}
          </Button>
        </Grid>
        <Grid item className={classes.selectors}>
          <TextField
            name="Search"
            type="search"
            id="outlined-controlled"
            label=" "
            placeholder="Buscar alumno"
            value={search}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              handleSearchChange(event.target.value);
            }}
            InputLabelProps={{
              shrink: true,
              className: classes.selectLabel,
            }}
            InputProps={{
              className: classes.selectInput,
              disableUnderline: true,
              startAdornment: (
                <SearchIcon fontSize="small" htmlColor="#38485C" />
              ),
              style: { width: 250 },
            }}
            SelectProps={{ classes: { root: classes.selectBg } }}
            disabled={anioDisabled}
          />
        </Grid>
        {filteredespaciosCurriculares.length > 0 &&
        selectedSeccion !== null &&
        Boolean(selectedEspacioCurricular) ? (
          <Grid item className={classes.selectors}>
            <SelectInput
              fullWidth
              label="Espacio Curricular"
              name="espacioCurricular"
              value={
                filteredespaciosCurriculares.filter(
                  (ec) =>
                    ec.espacioCurricular.idEspacioCurricular ===
                    selectedEspacioCurricular,
                ).length > 0
                  ? selectedEspacioCurricular
                  : ''
              }
              placeholder="Seleccioná espacio curricular"
              options={filteredespaciosCurriculares}
              labelKey="espacioCurricular.descripcion"
              valueKey="espacioCurricular.idEspacioCurricular"
              handleChange={handleChangeEC}
              InputLabelProps={{
                shrink: true,
                className: classes.selectLabel,
              }}
              InputProps={{
                className: classes.selectInput,
                disableUnderline: true,
              }}
              SelectProps={{ classes: { root: classes.selectBg } }}
            />
          </Grid>
        ) : (
          <></>
        )}
        <JustificacionesEspeciales
          selectedSeccion={selectedSeccion}
          nivel={Number(nivel)}
          currentWeek={currentWeek}
          serverDate={serverDate}
          pastDaysOfWeek={pastDaysOfWeek}
          rows={alumnoPresentismo}
          reFetchAll={reFetchAll}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sm={4}
        md={4}
        container
        justifyContent="center"
        alignItems="center"
      >
        <ResumenDiario
          currentWeek={currentWeek}
          pastDaysOfWeek={pastDaysOfWeek}
          alumnos={alumnoPresentismo}
          contraturnosDia={contraturnosDia}
          isEspacioCurricular={Boolean(selectedEspacioCurricular)}
        />
      </Grid>
    </Grid>
  );
};
