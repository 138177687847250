import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { IndicadoresEvaluacion } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'indicadores_evaluacion';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idIndicadoresEvaluacion',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idIndicadoresEvaluacion',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.IndicadoresEvaluacion
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.IndicadoresEvaluacion,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.IndicadoresEvaluacion.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<IndicadoresEvaluacion>(DAO_NAME, optionsToUse);

// hooks
export const useIndicadoresEvaluacionFetchById = fetchById;
export const useIndicadoresEvaluacionLibbyCall = libbyCall;
export const useIndicadoresEvaluacionDAO = libbyDAO;
export const useIndicadoresEvaluacionLibbyFetch = libbyFetch;

// components/hoc
export const IndicadoresEvaluacionAutocomplete = entityAutocomplete;
export const IndicadoresEvaluacionCall = renderPropCall;
export const IndicadoresEvaluacionById = renderPropFetchById;
export const IndicadoresEvaluacionSelect = simpleSelect;
export const IndicadoresEvaluacionAccordionSelector = accordionSelector;
export const IndicadoresEvaluacionSelectedChips = selectedChips;

// context
// ByIdContext
export const useIndicadoresEvaluacionByIdContext = useByIdContext;
export const IndicadoresEvaluacionByIdProvider = ByIdProvider;
export const IndicadoresEvaluacionByIdContext = ByIdContext;
// GeneralContext
export const useIndicadoresEvaluacionContext = useGeneralContext;
export const IndicadoresEvaluacionProvider = GeneralProvider;
export const IndicadoresEvaluacionContext = GeneralContext;
