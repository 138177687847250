import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, IconButton, makeStyles, TextField } from '@material-ui/core';
import { useGetRows } from '../../../../hooks';
import { useObservacionescCustomLibbyFetch } from 'src/app/business/Calificaciones/ObservacionescCustom';
import { isEqual } from 'lodash';
import customFormDialog from 'src/commons/services/customFormDialog';
import { ObservacionesModal } from 'src/commons/components/ObservacionesModal';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { primary } from 'src/theme/colors';
import EditIcon from '@material-ui/icons/Edit';
import { setSearchParamsMultipleWords } from 'src/utils/setSearchParamsMultipleWords';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
import { useRolesContext } from 'src/context/RolesContext';
import { ROL } from 'src/commons';

const useStyles = makeStyles(() => ({
  iconObservacion: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'end',
    color: primary.lightBlue,
    marginLeft: '20px',
  },
  observaciones: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '400px',
    marginTop: '10px',
    textAlign: 'right',
    color: 'black',
  },
}));

export const useObservacionesRows = (filter: any) => {
  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();
  const classes = useStyles();
  const [readyToClose, setReadyToClose] = useState<boolean>(false);

  const filterParams = useMemo(
    () => ({
      0: [{ path: 'seccion', value: filter.seccion }],
      1: [{ path: 'calificacion.periodo', value: filter.periodo }],
    }),
    [filter.seccion, filter.periodo],
  );

  const {
    selectedRole: { rol },
  } = useRolesContext();

  const response = useObservacionescCustomLibbyFetch({
    aspect: 'default',
    filter: filterParams,
    enabled: !!filter.periodo && !!filter.anio && !!filter.seccion,
    limit: 100,
  });
  const {
    rows: _rows,
    changeRow,
    working,
    fetchMore,
    data,
    reFetch,
    hasCalifications,
  } = useGetRows(response, filter.search);

  const isClosed = useMemo(
    () => _rows.some((row) => row.nota?.abierto === false),
    [_rows],
  );

  useEffect(() => {
    const submitRows = _rows.filter((row: any) => {
      let isUpdated = false;
      const currentCalification = data?.find(
        (d: any) => d.idCalificacion === row.idCalificacion,
      );
      if (currentCalification?.nota) {
        isUpdated = !isEqual(currentCalification?.nota?.data, row.nota.data);
      } else if (Object.keys(row.nota.data).length) {
        isUpdated = true;
      }
      return isUpdated;
    });
    setReadyToClose(!submitRows.length);
  }, [_rows, data]);

  const handleObservaciones: any = useCallback(
    (index: any, name: any, value: any) => {
      customFormDialog.show({
        title: 'Observaciones',
        renderComponent: (
          <ObservacionesModal
            content={_rows.observaciones}
            onAddContent={(content: any) =>
              changeRow(
                {
                  target: {
                    name,
                    value: content,
                  },
                },
                index,
              )
            }
            index={index}
            value={value}
          />
        ),
        sizeWidth: 'sm',
      });
    },
    [changeRow, _rows.observaciones],
  );

  const rows = useMemo(() => {
    const isDisabled =
      _rows.some(
        (c) => c.nota?.abierto !== undefined && c.nota?.abierto === false,
      ) || Number(rol) === ROL.COORDINADOR_JE;

    return _rows.map((row, index: number) => {
      return {
        nombre: `${row?.apellido}, ${row?.nombre}`,
        observaciones: (
          <Grid>
            {row.nota?.data?.observaciones ? (
              <>
                <Grid className={classes.iconObservacion}>
                  <div className={classes.observaciones}>
                    {row.nota?.data?.observaciones}
                  </div>
                  <IconButton
                    className={classes.iconObservacion}
                    disabled={isDisabled}
                    onClick={() =>
                      handleObservaciones(
                        index,
                        'observaciones',
                        row.nota?.data?.observaciones,
                      )
                    }
                  >
                    {<LibraryBooksIcon />}
                  </IconButton>
                </Grid>
              </>
            ) : (
              <Grid className={classes.iconObservacion}>
                <IconButton
                  disabled={isDisabled}
                  onClick={() =>
                    handleObservaciones(
                      index,
                      'observaciones',
                      row.nota?.data?.observaciones,
                    )
                  }
                >
                  {
                    <EditIcon
                      style={{
                        color:
                          row.abierto !== undefined && row.abierto === false
                            ? primary.disabled
                            : primary.lightBlue,
                      }}
                    />
                  }
                </IconButton>
              </Grid>
            )}
          </Grid>
        ),
      };
    });
  }, [rol, _rows, classes, handleObservaciones]);

  return {
    rows,
    _rows,
    working,
    fetchMore,
    data,
    reFetch,
    isClosed,
    readyToClose,
    hasCalifications,
  };
};
