import { publicProfile } from './Public/publicProfile';
import { privateProfile } from './Private/privateProfile';
import { sinEstablecimientoProfile } from './SinEstablecimiento';
import { autoregistracionProfile } from './Autoregistracion';

export const profiles = [
  publicProfile,
  privateProfile,
  sinEstablecimientoProfile,
  autoregistracionProfile,
];
