import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { ReportProblemRounded } from '@material-ui/icons';
import React, { useMemo } from 'react';
import { COLORS } from '../../TomaPresentismo/components/Resume';
import { AlertasType } from '../../TomaPresentismo/types';
import { TooltipAlertMessage } from '../../components/ModalAlertMgs';
import { useAvisosCellStyles } from '../../TomaPresentismo/components/PresentismoTable/css';
import { AvisosStudentReportProps } from '../types';

export const getIconColor = (avisosCount: AlertasType) => {
  const [alertaUno, alertaDos, alertaTres, alertaCuatro, alertaCinco] =
    avisosCount;
  if (alertaCinco.active) return COLORS.RED;
  if (alertaCuatro.active) return COLORS.RED;
  if (alertaTres.active) return COLORS.RED;
  if (alertaDos.active) return COLORS.ORANGE;
  if (alertaUno.active) return COLORS.YELLOW;
  return null;
};

export const AvisosStudentReport = ({
  avisosCount,
  defaultIcon = '-',
}: AvisosStudentReportProps) => {
  const classes = useAvisosCellStyles();
  const color = useMemo(() => {
    if (!avisosCount) return;
    return getIconColor(avisosCount);
  }, [avisosCount]);

  return (
    <Box>
      {color ? (
        <Tooltip
          placement="right"
          classes={{ tooltip: classes.tooltip }}
          title={<TooltipAlertMessage alerts={avisosCount} />}
        >
          <Grid
            item
            style={{
              display: 'flex',
              border: '1px solid',
              borderColor: color.typography,
              borderRadius: 6,
              maxWidth: '90px',
              padding: '2px',
            }}
            spacing={2}
            alignItems={'center'}
            justifyContent="center"
          >
            <Typography
              style={{
                color: color.typography,
              }}
              className={classes.text}
            >
              Alerta
            </Typography>
            <ReportProblemRounded
              className={classes.icon}
              htmlColor={color.typography}
            />
          </Grid>
        </Tooltip>
      ) : (
        defaultIcon
      )}
    </Box>
  );
};
