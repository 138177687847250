import { CommonEndpointDAO } from '../CommonEndpointDAO';

export class ConstanciaCondicionDAO extends CommonEndpointDAO {
  constructor() {
    super('constancia_condicion');
  }

  post(body: any = {}): any {
    return super.post(body);
  }
}
