import React, {
  SetStateAction,
  Dispatch,
  createContext,
  useContext,
} from 'react';
import { AnyObject } from 'src/commons';
import { FILTERS } from '../table_filters';

export type InternalFilters = {
  [category: string]: AnyObject;
};

export type GlobalFilters = {
  [FILTERS.nivel]: string;
  [FILTERS.localizacion]: string;
  [FILTERS.ciclo]: string;
  [FILTERS.distrito_escolar]: string;
  [FILTERS.dependencia_funcional]: string;
};

export type ReportContextType = {
  currentReport: string;
  internalFilters: InternalFilters;
  globalFilters: GlobalFilters;
  setCurrentReport: Dispatch<SetStateAction<string>>;
  setInternalFilters: Dispatch<SetStateAction<InternalFilters>>;
  setGlobalFilters: Dispatch<SetStateAction<GlobalFilters>>;
  reportCategory: string;
  setOrderDirection: Dispatch<SetStateAction<'asc' | 'desc'>>;
  setOrderBy: Dispatch<SetStateAction<string>>;
  orderDirection: string;
  setLimit: Dispatch<SetStateAction<number>>;
  limit: number;
  setOffset: Dispatch<SetStateAction<number>>;
  offset: number;
  totalRows: number;
  request: () => void;
  queryParams: any;
  search: string;
  setSearch: Dispatch<SetStateAction<string>>;
  setQueryParams: Dispatch<SetStateAction<string>>;
  setFetchInmediatly: Dispatch<SetStateAction<boolean>>;
  setFetchCount: Dispatch<SetStateAction<boolean>>;
};

export const ReportsContext = createContext<ReportContextType>({
  currentReport: '',
  setCurrentReport: () => {},
  setInternalFilters: () => {},
  setGlobalFilters: () => {},
  setFetchInmediatly: () => {},
  setFetchCount: () => {},
  internalFilters: {
    matriculacion: {},
    calificaciones: {},
    boletines: {},
    presentismo: {},
    proyeccion: {},
  },
  globalFilters: {
    [FILTERS.nivel]: '',
    [FILTERS.localizacion]: '',
    [FILTERS.ciclo]: '',
    [FILTERS.distrito_escolar]: '',
    [FILTERS.dependencia_funcional]: '',
  },
  reportCategory: '',
  setOrderBy: () => {},
  setOrderDirection: () => {},
  orderDirection: 'asc',
  setLimit: () => {},
  limit: 10,
  setOffset: () => {},
  offset: 0,
  totalRows: 0,
  request: () => {},
  queryParams: {},
  setQueryParams: () => {},
  search: '',
  setSearch: () => {},
});

export const useReportsContext = () => useContext(ReportsContext);
