import { FormAutocomplete, FormAutocompleteProps } from 'src/lib/templates';
import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core';
import { grey } from 'src/theme/colors';

const useStyles = makeStyles({
  label: {
    marginBottom: 5,
    fontSize: 13,
    fontWeight: 600,
    color: grey.medium,
  },
  root: {
    '& .MuiOutlinedInput-root': {
      fontSize: 16,
      padding: 4,
    },
  },
});

export const CustomFormAutocomplete: FunctionComponent<
  FormAutocompleteProps
> = (props) => {
  const classes = useStyles();
  return <FormAutocomplete {...props} customClasses={classes} />;
};
