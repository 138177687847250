import { useEffect, useState } from 'react';
import { useLocalizacionDAO } from 'src/app/business';
import { ESCUELAS_INICIALES_DEI, ESCUELAS_INICIALES_DEN } from 'src/app/models';
import { ROL } from 'src/commons';
import { useRolesContext } from 'src/context/RolesContext';

export const useColegioMeta = () => {
  const [colegioType, setColegioType] = useState({
    isDEN: false,
    isDEI: false,
  });
  const localizacionDAO = useLocalizacionDAO();
  const {
    selectedRole: { nivel, rol, localizacionId },
  } = useRolesContext();

  useEffect(() => {
    const execute = async () => {
      if (Number(nivel) !== 1 || rol === '-1') {
        setColegioType({
          isDEN: false,
          isDEI: false,
        });
      } else {
        const rows = await localizacionDAO.getDetails(
          Number(rol) === Number(ROL.SUPERVISOR)
            ? Number(localizacionId)
            : undefined,
        );

        if (rows !== undefined) {
          setColegioType({
            isDEN:
              rows.length > 0
                ? rows[0].establecimiento.dependenciaFuncional
                    .idDependenciaFuncional === ESCUELAS_INICIALES_DEN
                : false,
            isDEI:
              rows.length > 0
                ? rows[0].establecimiento.dependenciaFuncional
                    .idDependenciaFuncional === ESCUELAS_INICIALES_DEI
                : false,
          });
        } else {
          console.error("La variable 'rows' es undefined.");
        }
      }
    };

    execute();
  }, [localizacionDAO, nivel, rol, localizacionId]);

  return colegioType;
};
