import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Indicadores, IndicadoresOrientaPlan } from 'src/app/models';
import { ListItemProperties } from '../../commons';
import { useDisciplinarOrientadaContext } from '../../../forms/PlanificacionDisciplinarOrientada/Context';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));
export interface IndicadoresDeEvaluacionProps {
  data: IndicadoresOrientaPlan;
}

export const IndicadoresDeEvaluacion = ({
  data,
}: IndicadoresDeEvaluacionProps) => {
  // const classes = useStyles();
  const {
    data: { indicadores, bloques },
    working,
  } = useDisciplinarOrientadaContext();

  const filterIndicadores = data?.indicadoresEvaluacion?.map(
    (indicador: Indicadores) => {
      const filterBloques = bloques.find(
        (bloq: any) => bloq.idBloque === indicador.bloque,
      );
      const _filterIndicadores = indicadores
        .filter((_indicador: any) =>
          indicador?.indicadores?.find(
            (ind: any) => _indicador?.idIndicadoresDeEvaluacion === ind,
          ),
        )
        .map(({ idIndicadoresDeEvaluacion, descripcion }: any) => ({
          id: idIndicadoresDeEvaluacion,
          descripcion,
        }));
      if (indicador?.adicionales?.length)
        _filterIndicadores.push(...indicador?.adicionales);
      return {
        indicadores: _filterIndicadores,
        bloque: filterBloques,
      };
    },
  );

  return (
    <Grid container direction="column">
      {!working &&
        filterIndicadores.map((indicador: any) => (
          <ListItemProperties
            title={indicador?.bloque?.descripcion}
            values={indicador?.indicadores || []}
          />
        ))}
    </Grid>
  );
};
