import React from 'react';
import { Grid } from '@material-ui/core';
import { MainButton } from 'src/commons/components/MainButton';
import customFormDialog from 'src/commons/services/customFormDialog';
import { TableToolbar } from '../../../../../../commons/components/TableToolbar';
import { useInputState } from '../../../../../../commons/hooks/useInputState';
import { useDebounceCall } from '../../../../../../commons/hooks';
import { AddUserModal } from './AddUserModal/AddUserModal';

export type Filter = {
  search: string;
};

export interface UsuarioListTableHeaderProps {
  filter: Filter;
  onChangeFilter: (value: Filter) => void;
  reFetch: () => void;
}

export const UsuarioListTableHeader = ({
  filter,
  onChangeFilter,
  reFetch,
}: UsuarioListTableHeaderProps) => {
  const [search, onSearchChange] = useInputState<string>(filter.search);

  useDebounceCall(
    () => {
      if (filter.search !== search) {
        onChangeFilter({ search });
      }
    },
    300,
    [filter, onChangeFilter, search],
  );

  const openUserModal = () => {
    customFormDialog.show({
      title: '',
      renderComponent: <AddUserModal reFetch={reFetch} />,
      sizeWidth: 'md',
    });
  };

  return (
    <Grid container spacing={2} alignItems="center">
      <Grid item sm={3}>
        <TableToolbar
          search={search}
          onSearchChange={onSearchChange}
          searchContainerProps={{ sm: 12 }}
        />
      </Grid>
      <Grid item>
        <MainButton title="Agregar Usuario" handleOnClick={openUserModal} />
      </Grid>
    </Grid>
  );
};
