import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { ProyeccionEstadoTipo } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'proyeccion_estado_tipo';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idProyeccionEstadoTipo',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idProyeccionEstadoTipo',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.ProyeccionEstadoTipo
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.ProyeccionEstadoTipo,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.ProyeccionEstadoTipo.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<ProyeccionEstadoTipo>(DAO_NAME, optionsToUse);

// hooks
export const useProyeccionEstadoTipoFetchById = fetchById;
export const useProyeccionEstadoTipoLibbyCall = libbyCall;
export const useProyeccionEstadoTipoDAO = libbyDAO;
export const useProyeccionEstadoTipoLibbyFetch = libbyFetch;

// components/hoc
export const ProyeccionEstadoTipoAutocomplete = entityAutocomplete;
export const ProyeccionEstadoTipoCall = renderPropCall;
export const ProyeccionEstadoTipoById = renderPropFetchById;
export const ProyeccionEstadoTipoSelect = simpleSelect;
export const ProyeccionEstadoTipoAccordionSelector = accordionSelector;
export const ProyeccionEstadoTipoSelectedChips = selectedChips;

// context
// ByIdContext
export const useProyeccionEstadoTipoByIdContext = useByIdContext;
export const ProyeccionEstadoTipoByIdProvider = ByIdProvider;
export const ProyeccionEstadoTipoByIdContext = ByIdContext;
// GeneralContext
export const useProyeccionEstadoTipoContext = useGeneralContext;
export const ProyeccionEstadoTipoProvider = GeneralProvider;
export const ProyeccionEstadoTipoContext = GeneralContext;
