import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { TipoDocumento } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'tipo_documento';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idTipoDocumento',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idTipoDocumento',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.TipoDocumento
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.TipoDocumento,
      hoc: { ...defaultOptions.hoc, ...businessOptions.TipoDocumento.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<TipoDocumento>(DAO_NAME, optionsToUse);

// hooks
export const useTipoDocumentoFetchById = fetchById;
export const useTipoDocumentoLibbyCall = libbyCall;
export const useTipoDocumentoDAO = libbyDAO;
export const useTipoDocumentoLibbyFetch = libbyFetch;

// components/hoc
export const TipoDocumentoAutocomplete = entityAutocomplete;
export const TipoDocumentoCall = renderPropCall;
export const TipoDocumentoById = renderPropFetchById;
export const TipoDocumentoSelect = simpleSelect;
export const TipoDocumentoAccordionSelector = accordionSelector;
export const TipoDocumentoSelectedChips = selectedChips;

// context
// ByIdContext
export const useTipoDocumentoByIdContext = useByIdContext;
export const TipoDocumentoByIdProvider = ByIdProvider;
export const TipoDocumentoByIdContext = ByIdContext;
// GeneralContext
export const useTipoDocumentoContext = useGeneralContext;
export const TipoDocumentoProvider = GeneralProvider;
export const TipoDocumentoContext = GeneralContext;
