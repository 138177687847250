import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Grid } from '@material-ui/core';
import { SimpleSelect } from 'src/commons/components/Select';
import { grey } from 'src/theme/colors';
import { useLibbyCall, useLibbyFetch, useDebounce } from 'src/commons/hooks';
import { AnyObject } from 'src/commons/types';
import { MainInput } from 'src/commons/components/MainInput';
import { workingDays } from 'src/commons/const';
import { makeFilter } from '../../utils/MakeFilters';

const customStyles = {
  containerStyles: { marginTop: 23 },
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  inputStyles: { display: 'flex', alignItems: 'center' },
};

interface OwnProps {
  libby?: any;
  setFilters(item: []): void;
  locationId: string;
  isAdultPromotion?: boolean;
  level?: string;
}
interface HandleFilter {
  (event: React.ChangeEvent<{ value: any }>): void;
}

type Props = OwnProps;

const PromotionFiltersRaw: FunctionComponent<Props> = ({
  libby,
  setFilters,
  locationId,
  isAdultPromotion,
  level: nivel,
}) => {
  const [search, setSearch] = useState<string>('');
  const [level, setLevel] = useState<string>(nivel || '');
  const [cycle, setCycle] = useState<string>('');
  const [turn, setTurn] = useState<string>('');
  const [workingDay, setWorkingDay] = useState<string>('');
  const [studyPlan, setStudyPlan] = useState<string>('');
  const searchDebounced = useDebounce(search, 500);

  useEffect(() => {
    setFilters(
      makeFilter({
        search: searchDebounced,
        cycle,
        level,
        turn,
        workingDay,
        studyPlan,
        locationId,
        isAdultPromotion,
      }),
    );
  }, [
    cycle,
    isAdultPromotion,
    level,
    locationId,
    searchDebounced,
    setFilters,
    studyPlan,
    turn,
    workingDay,
  ]);

  const { data: turnData } = useLibbyCall(libby, {
    daoName: 'turno',
    methodName: 'getAll',
  });
  const { data: cycleData } = useLibbyCall(libby, {
    daoName: 'ciclo',
    methodName: isAdultPromotion ? 'getAll' : 'getBCCycle',
  });
  const { data: levelData } = useLibbyCall(libby, {
    daoName: 'nivel',
    methodName: 'getLevelsBySchool',
    params: isAdultPromotion ? [level] : [1, 2, 3],
  });

  const paramsFetchPlanEstudioNivel = useMemo(
    () => ({
      daoName: 'localizacion_plan_estudio_nivel',
      filter: {
        0: nivel
          ? [{ path: 'planEstudioNivel.modalidadNivel.nivel', value: nivel }]
          : [],
        1: [{ path: 'localizacion', value: locationId }],
      },
    }),
    [locationId, nivel],
  );

  const { data: levelStudyPlanData } = useLibbyFetch(
    libby,
    paramsFetchPlanEstudioNivel,
  );

  const handleChangeLevel: HandleFilter = (event) => {
    setLevel(event.target.value);
  };
  const handleChangeCycle: HandleFilter = (event) => {
    setCycle(event.target.value);
  };
  const handleChangeTurn: HandleFilter = (event) => {
    setTurn(event.target.value);
  };
  const handleChangeWorkingDay: HandleFilter = (event) => {
    setWorkingDay(event.target.value);
  };
  const handleChangeStudyPlan: HandleFilter = (event) => {
    setStudyPlan(event.target.value);
  };

  const levelStudyPlanFlatten = () =>
    levelStudyPlanData.map((data: AnyObject) => ({
      ...data,
      ...data.planEstudioNivel,
      ...data.planEstudioNivel.planEstudio,
    }));

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      spacing={3}
      style={customStyles.containerStyles}
    >
      <Grid
        item
        xs={10}
        container
        wrap="nowrap"
        justify="flex-start"
        alignItems="center"
        spacing={3}
      >
        <Grid item xs={3}>
          <SimpleSelect
            title="Nivel"
            placeholder="Seleccioná Nivel"
            labelKey="descripcionNivel"
            valueKey="idNivel"
            handleChange={handleChangeLevel}
            content={levelData}
            value={level}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
        <Grid item xs={3}>
          <SimpleSelect
            title="Ciclo"
            placeholder="Seleccioná Ciclo"
            labelKey="descripcionCiclo"
            valueKey="idCiclo"
            handleChange={handleChangeCycle}
            content={cycleData}
            value={cycle}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
        <Grid item xs={3}>
          <SimpleSelect
            title="Turno"
            placeholder="Seleccioná Turno"
            labelKey="descripcionTurno"
            valueKey="idTurno"
            handleChange={handleChangeTurn}
            content={turnData}
            value={turn}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
        {!isAdultPromotion && (
          <Grid item xs={3}>
            <SimpleSelect
              title="Jornada"
              placeholder="Seleccioná Jornada"
              labelKey="label"
              valueKey="label"
              handleChange={handleChangeWorkingDay}
              content={workingDays}
              value={workingDay}
              customStyleTitle={customStyles.customStyleTitleSelect}
            />
          </Grid>
        )}
        <Grid item xs={3}>
          <SimpleSelect
            title="Plan de Estudios"
            placeholder="Seleccioná Plan"
            labelKey="descripcionPlanEstudio"
            valueKey="idPlanEstudioNivel"
            handleChange={handleChangeStudyPlan}
            content={levelStudyPlanFlatten()}
            value={studyPlan}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
      </Grid>
      <Grid item xs style={customStyles.containerStyles}>
        <MainInput
          handleChange={(event) => setSearch(event.target.value)}
          value={search}
          placeholder="Buscar"
          type="search"
          fullWidth
        />
      </Grid>
    </Grid>
  );
};

export const PromotionFilters = DatabaseConnector(PromotionFiltersRaw)(
  'nivel',
  'ciclo',
  'turno',
  'plan_estudio_nivel',
  'localizacion_plan_estudio_nivel',
);
