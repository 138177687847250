import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { TiposAcciones } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'tipos_acciones';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idTiposAcciones',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idTiposAcciones',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.TiposAcciones
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.TiposAcciones,
      hoc: { ...defaultOptions.hoc, ...businessOptions.TiposAcciones.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
    selectInput,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<TiposAcciones>(DAO_NAME, optionsToUse);

// hooks
export const useTiposAccionesFetchById = fetchById;
export const useTiposAccionesLibbyCall = libbyCall;
export const useTiposAccionesDAO = libbyDAO;
export const useTiposAccionesLibbyFetch = libbyFetch;

// components/hoc
export const TiposAccionesAutocomplete = entityAutocomplete;
export const TiposAccionesCall = renderPropCall;
export const TiposAccionesById = renderPropFetchById;
export const TiposAccionesSelect = selectInput;
export const TiposAccionesAccordionSelector = accordionSelector;
export const TiposAccionesSelectedChips = selectedChips;

// context
// ByIdContext
export const useTiposAccionesByIdContext = useByIdContext;
export const TiposAccionesByIdProvider = ByIdProvider;
export const TiposAccionesByIdContext = ByIdContext;
// GeneralContext
export const useTiposAccionesContext = useGeneralContext;
export const TiposAccionesProvider = GeneralProvider;
export const TiposAccionesContext = GeneralContext;
