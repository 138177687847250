import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, {
  FunctionComponent,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useField } from 'react-final-form-hooks';
import {
  useGeolocalizacionDAO,
  useProvinciaLibbyFetch,
} from 'src/app/business';
import { Map } from 'src/app/components/map';
import { Provincia } from 'src/app/models';
import { pais, useDebounce } from 'src/commons';
import { FormInput, useFormContext } from 'src/lib/templates';
import { CustomFormAutocomplete as FormAutocomplete } from '../components';
import { useLegajoData } from '../context';

const { ID_PAIS_ARG } = pais;

type TipoDeResidencia = 'datosResidenciaTipoA' | 'datosResidenciaTipoB';
interface TiposDeResidencia {
  name: string;
  value: TipoDeResidencia;
}

const tiposDeResidencia: TiposDeResidencia[] = [
  {
    name: 'Residencia Normal',
    value: 'datosResidenciaTipoA',
  },
  {
    name: 'Residencia NHT',
    value: 'datosResidenciaTipoB',
  },
];

const useStyles = makeStyles({
  titleContainer: {
    marginTop: '24px',
    paddingBottom: '0 !important',
  },
  title: {
    color: '#595959',
    fontSize: '20px',
  },
  whiteSpace: {
    height: '75px',
  },
});

export const Residential: FunctionComponent = () => {
  const { isHistoric } = useLegajoData();

  const filterProvinciasByPais = useMemo(
    () => ({
      pais: [{ path: 'pais.idPais', value: ID_PAIS_ARG }],
    }),
    [],
  );
  const classes = useStyles();
  const { data: provincias } = useProvinciaLibbyFetch({
    filter: filterProvinciasByPais,
    orderBy: 'nombreProvincia',
  });
  const { enqueueSnackbar } = useSnackbar();
  const { form } = useFormContext();

  const geolocalizacion = useGeolocalizacionDAO();

  const [directionMap, setDirectionMap] = useState({
    lat: '',
    lng: '',
  });

  const tipoResidenciaField = useField('residencia.tipoDireccion', form);
  const [tipoResidencia, setTipoResidencia] = useState<TipoDeResidencia>(
    tipoResidenciaField.input.value || 'datosResidenciaTipoA',
  );

  const direccionField = useField(`residencia.${tipoResidencia}.calle`, form);
  const alturaField = useField('residencia.datosResidenciaTipoA.altura', form);
  const localidadField = useField(
    `residencia.${tipoResidencia}.localidadBarrio`,
    form,
  );
  const villaField = useField(`residencia.${tipoResidencia}.villa`, form);
  const tipoVillaField = useField(`residencia.${tipoResidencia}.manzana`, form);

  const direccion = useDebounce(direccionField.input.value, 1000);
  const altura = useDebounce(alturaField.input.value, 1000);
  const localidad = useDebounce(localidadField.input.value, 1000);
  const villa = useDebounce(villaField.input.value, 1000);
  const manzana = useDebounce(tipoVillaField.input.value, 1000);

  const gettingLocation = useCallback(async () => {
    try {
      const res = await geolocalizacion.locationQuery({
        direccion,
        localidad,
        altura,
        villa,
        manzana,
      });
      setDirectionMap({
        lat: res.coordinate.lat,
        lng: res.coordinate.lng,
      });
    } catch (e) {
      enqueueSnackbar('No se ha podido encontrar su localizacion', {
        variant: 'error',
      });
    }
  }, [
    localidad,
    direccion,
    altura,
    villa,
    manzana,
    enqueueSnackbar,
    geolocalizacion,
  ]);

  useEffect(() => {
    if ((altura && direccion && localidad) || (villa && manzana)) {
      gettingLocation();
    }
  }, [altura, direccion, localidad, gettingLocation, villa, manzana]);

  useEffect(() => {
    setTipoResidencia(tipoResidenciaField.input.value as TipoDeResidencia);
  }, [tipoResidenciaField.input.value]);

  return (
    <Grid container spacing={4} direction="column">
      <Grid
        className={classes.titleContainer}
        item
        style={{ marginTop: '24px' }}
      >
        <Typography className={classes.title} variant="h5">
          Residencia
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={6}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormAutocomplete
              disabled={isHistoric}
              placeholder="Seleccioná tipo de dirección"
              label="Tipo de dirección"
              name="residencia.tipoDireccion"
              form={form}
              options={tiposDeResidencia || []}
              optionLabelKey="name"
              optionToValue={(option: TiposDeResidencia) => option?.value}
              valueToOption={(value, options: TiposDeResidencia[]) =>
                options.find((option) => option?.value === value)
              }
              getOptionSelected={(
                option: TiposDeResidencia,
                value: TiposDeResidencia,
              ) => option?.value === value?.value}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <br />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormAutocomplete
              disabled={isHistoric}
              placeholder="Seleccioná provincia"
              label="Provincia"
              name={`residencia.${tipoResidencia}.provincia`}
              form={form}
              options={provincias || []}
              optionLabelKey="nombreProvincia"
              optionToValue={(option: Provincia) => option?.idProvincia}
              valueToOption={(value, options: Provincia[]) =>
                options.find((_item) => _item?.idProvincia === value)
              }
              getOptionSelected={(option: Provincia, value: Provincia) =>
                option?.idProvincia === value?.idProvincia
              }
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormInput
              disabled={isHistoric}
              placeholder="Ingresá localidad/barrio"
              label="Localidad/Barrio"
              name={`residencia.${tipoResidencia}.localidadBarrio`}
              form={form}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <FormInput
                  disabled={isHistoric}
                  placeholder="Ingresá código postal"
                  label="Código postal"
                  name={`residencia.${tipoResidencia}.codigoPostal`}
                  form={form}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  disabled={isHistoric}
                  placeholder="Ingresá calle"
                  label="Calle"
                  name={`residencia.${tipoResidencia}.calle`}
                  form={form}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                {tipoResidencia === 'datosResidenciaTipoA' ? (
                  <FormInput
                    disabled={isHistoric}
                    placeholder="Ingresá altura"
                    label="Altura"
                    name={`residencia.${tipoResidencia}.altura`}
                    form={form}
                    type="number"
                  />
                ) : (
                  <FormInput
                    disabled={isHistoric}
                    label="Villa"
                    placeholder="Ingresá villa"
                    name={`residencia.${tipoResidencia}.villa`}
                    form={form}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {tipoResidencia === 'datosResidenciaTipoA' ? (
                  <FormInput
                    disabled={isHistoric}
                    placeholder="Ingresá piso/departamento"
                    label="Piso/Departamento (opcional)"
                    name={`residencia.${tipoResidencia}.pisoDpto`}
                    form={form}
                  />
                ) : (
                  <FormInput
                    disabled={isHistoric}
                    placeholder="Ingresá la manzana"
                    label="Manzana"
                    name={`residencia.${tipoResidencia}.manzana`}
                    form={form}
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <FormInput
                  disabled={isHistoric}
                  placeholder="Ingresá un teléfono"
                  label="Teléfono 1"
                  name={`residencia.${tipoResidencia}.telefono1`}
                  form={form}
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <FormInput
                  disabled={isHistoric}
                  placeholder="Ingresá un teléfono"
                  label="Teléfono 2 (opcional)"
                  name={`residencia.${tipoResidencia}.telefono2`}
                  form={form}
                  type="number"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormInput
              disabled={isHistoric}
              placeholder="Escribí las observaciones necesarias"
              label="Observaciones (opcional)"
              name={`residencia.${tipoResidencia}.observaciones`}
              form={form}
            />
          </Grid>
        </Grid>
      </Grid>
      {directionMap.lng && directionMap.lat && (
        <Grid item>
          <Map lat={directionMap.lat} lng={directionMap.lng} width="100%" />
        </Grid>
      )}
      <Grid className={classes.whiteSpace} item>
        <br />
      </Grid>
    </Grid>
  );
};
