import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { SintesisConceptual } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'sintesis_conceptual';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idSintesisConceptual',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idSintesisConceptual',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.SintesisConceptual
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.SintesisConceptual,
      hoc: { ...defaultOptions.hoc, ...businessOptions.SintesisConceptual.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<SintesisConceptual>(DAO_NAME, optionsToUse);

// hooks
export const useSintesisConceptualFetchById = fetchById;
export const useSintesisConceptualLibbyCall = libbyCall;
export const useSintesisConceptualDAO = libbyDAO;
export const useSintesisConceptualLibbyFetch = libbyFetch;

// components/hoc
export const SintesisConceptualAutocomplete = entityAutocomplete;
export const SintesisConceptualCall = renderPropCall;
export const SintesisConceptualById = renderPropFetchById;
export const SintesisConceptualSelect = simpleSelect;
export const SintesisConceptualAccordionSelector = accordionSelector;
export const SintesisConceptualSelectedChips = selectedChips;

// context
// ByIdContext
export const useSintesisConceptualByIdContext = useByIdContext;
export const SintesisConceptualByIdProvider = ByIdProvider;
export const SintesisConceptualByIdContext = ByIdContext;
// GeneralContext
export const useSintesisConceptualContext = useGeneralContext;
export const SintesisConceptualProvider = GeneralProvider;
export const SintesisConceptualContext = GeneralContext;
