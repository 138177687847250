import { materias } from '../../../../assets/images';

export const imageCard: { [key: string]: string } = {
  1: materias.biologia,
  3: materias.artesVisuales,
  5: materias.biologia,
  6: materias.artesMusica,
  7: materias.artesTeatro,
  8: materias.biologia,
  9: materias.formacionEticaCiudadana,
  10: materias.educacionFisica,
  11: materias.educacionTecnologica,
  12: materias.geografia,
  13: materias.historia,
  14: materias.lenguaYLiteratura,
  fallback: materias.fallback,
};
