import React from 'react';
import { useMemo } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { Pps } from 'src/app/models';
import { TextFieldFF } from 'src/commons/components/FinalForm/TextField';
import { usePPSFormContext } from '../PPSFormProvider';
import { FormApi } from 'final-form';
import { FileField } from 'src/commons/components/FinalForm/FileField';
import { FieldRenderProps } from 'react-final-form-hooks';

const useStyles = makeStyles({
  adjunto: {
    border: 'none',
    borderRadius: 10,
    padding: 10,
    backgroundColor: 'rgb(240,240,240)',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    width: '100px',
  },
  hide: {
    display: 'none',
  },
  adjuntoClose: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 7px',
    fontWeight: 'bold',
  },
  disabledInput: {
    backgroundColor: '#DADADA',
  },
  adjuntoNombre: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

export const useAntecedentesDeSaludColumns = (
  adjuntoHandleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    field: FieldRenderProps,
  ) => void,
  ppsDataAlumno: Pps,
) => {
  const { form } = usePPSFormContext();
  const values = (form as FormApi).getState().values;
  const classes = useStyles();

  const columns = useMemo(() => {
    const isOpen = ppsDataAlumno !== undefined ? ppsDataAlumno?.abierto : true;
    const isDisabled = !isOpen;
    const handleDeleteFile = () => {
      if (!isDisabled) {
        (form as FormApi).change('antecedentes.informe', {});
        (form as FormApi).change('antecedentesAdjunto', {
          files: '',
          name: '',
        });
      }
    };

    return [
      {
        id: 'antecedentes',
        label: 'Si hubo antecedentes de salud, mencione cuales',
        width: '5%',
        hideSortIcon: true,
        render: () => (
          <TextFieldFF
            disabled={isDisabled}
            form={form}
            name="antecedentes.antecedentes"
          />
        ),
      },
      {
        id: 'emptyspace',
        label: '',
        width: '5%',
        hideSortIcon: true,
        render: () => <></>,
      },
      {
        id: 'informe',
        label: 'Certificado único de discapacidad',
        hideSortIcon: true,
        width: '5%',
        render: () => (
          <>
            {Boolean(values?.antecedentesAdjunto?.name) ? (
              <div
                className={classes.adjunto}
                onClick={() => handleDeleteFile()}
              >
                <div className={classes.adjuntoNombre}>
                  {values?.antecedentesAdjunto?.name}
                </div>
                <div className={classes.adjuntoClose}>x</div>
              </div>
            ) : null}
            <Button
              variant="contained"
              component="label"
              color="primary"
              fullWidth
              disabled={isDisabled}
              classes={{
                root: Boolean(values?.antecedentesAdjunto?.name)
                  ? classes.hide
                  : '',
              }}
            >
              Adjuntar
              <FileField
                form={form}
                type="file"
                accept="application/pdf"
                name="antecedentesAdjunto"
                hidden
                changeHandler={adjuntoHandleChange}
              />
            </Button>
          </>
        ),
      },
    ];
  }, [
    adjuntoHandleChange,
    classes.adjunto,
    classes.adjuntoClose,
    classes.adjuntoNombre,
    classes.hide,
    form,
    ppsDataAlumno,
    values?.antecedentesAdjunto?.name,
  ]);

  return columns;
};
