import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { EstadoPromocionPaseAnio } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'estado_promocion_pase_anio';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEstadoPromocionPaseAnio',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEstadoPromocionPaseAnio',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EstadoPromocionPaseAnio
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EstadoPromocionPaseAnio,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.EstadoPromocionPaseAnio.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EstadoPromocionPaseAnio>(DAO_NAME, optionsToUse);

// hooks
export const useEstadoPromocionPaseAnioFetchById = fetchById;
export const useEstadoPromocionPaseAnioLibbyCall = libbyCall;
export const useEstadoPromocionPaseAnioDAO = libbyDAO;
export const useEstadoPromocionPaseAnioLibbyFetch = libbyFetch;

// components/hoc
export const EstadoPromocionPaseAnioAutocomplete = entityAutocomplete;
export const EstadoPromocionPaseAnioCall = renderPropCall;
export const EstadoPromocionPaseAnioById = renderPropFetchById;
export const EstadoPromocionPaseAnioSelect = simpleSelect;
export const EstadoPromocionPaseAnioAccordionSelector = accordionSelector;
export const EstadoPromocionPaseAnioSelectedChips = selectedChips;

// context
// ByIdContext
export const useEstadoPromocionPaseAnioByIdContext = useByIdContext;
export const EstadoPromocionPaseAnioByIdProvider = ByIdProvider;
export const EstadoPromocionPaseAnioByIdContext = ByIdContext;
// GeneralContext
export const useEstadoPromocionPaseAnioContext = useGeneralContext;
export const EstadoPromocionPaseAnioProvider = GeneralProvider;
export const EstadoPromocionPaseAnioContext = GeneralContext;
