/**
 * TODO
 * Implementar la logica para los demas niveles
 * y periodos
 */

import { useMemo } from 'react';
import { Niveles, PeriodosDescripcion } from 'src/commons';

type ValidatorFunction = () => string[];

const validators: {
  [key in Niveles]?: {
    [key in PeriodosDescripcion]?: ValidatorFunction;
  };
} = {
  PRIMARIA: {
    PRIMER_BIMESTRE() {
      return [
        'apoyoPregunta',
        'comprometeReconoce',
        'organizaParticipa',
        'promocionAcompanada',
      ];
    },
    SEGUNDO_BIMESTRE() {
      return ['apoyoPregunta', 'comprometeReconoce', 'organizaParticipa'];
    },
    CUARTO_BIMESTRE() {
      return [
        'acompaniamientoPregunta',
        'apoyoPregunta',
        'organizaParticipa',
        'comprometeReconoce',
      ];
    },
  },
};

export const useFieldsValidator = (
  periodo: PeriodosDescripcion,
  nivel: Niveles,
  isEfectivizado: boolean | string,
): string[] | null => {
  return useMemo(
    () =>
      isEfectivizado && nivel in validators && periodo in validators[nivel]!
        ? validators[nivel]![periodo]!()
        : null,
    [isEfectivizado, nivel, periodo],
  );
};
