import React, { useMemo, useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { ButtonTypesProps, Footer } from 'src/commons';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
import { usePeriodosJe } from '../hooks/usePeriodosRoles';

const useStyles = makeStyles({
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export const CalificacionesSecundarioSwitch = ({
  setPeriodoSecundario,
}: any) => {
  const { establecimientoEfectivizado, cicloLectivo } =
    useColegioEfectivizadoContext();
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();
  const isEfectivizado = establecimientoEfectivizado as boolean;

  const { setPeriodo, isJe, periodo } = usePeriodosJe();

  const handleChange = (event: any) => {
    setPeriodo(String(event.target.value));
  };

  const handleSubmit = useCallback(() => {
    if (match.url.search('espacioscurriculares') === -1) {
      history.push(`${match.url}/${periodo}`);
    } else {
      setPeriodoSecundario(periodo);
    }
  }, [history, match.url, periodo, setPeriodoSecundario]);

  const handleLeave = useCallback(() => {
    history.push('/');
  }, [history]);

  const buttonConfig = useMemo<ButtonTypesProps[]>(() => {
    return [
      {
        title: 'Salir',
        size: 'medium',
        type: 'secondary',
        handleOnClick: handleLeave,
      },
      {
        title: 'Entrar',
        size: 'medium',
        handleOnClick: handleSubmit,
      },
    ];
  }, [handleLeave, handleSubmit]);

  return (
    <Grid container style={{ marginTop: '50px', marginLeft: '30px' }}>
      <Grid item xs>
        <Typography style={{ fontSize: '20px', marginBottom: '20px' }}>
          Seleccione Período
        </Typography>
      </Grid>
      <Grid item container>
        <FormControl component="fieldset">
          <RadioGroup
            aria-label="periodos"
            name="periodos"
            value={periodo}
            onChange={handleChange}
          >
            <FormControlLabel
              value={'1'}
              control={<Radio />}
              label="Primer Bimestre"
              disabled={isJe}
            />
            <FormControlLabel
              value={'2'}
              control={<Radio />}
              label="Segundo Bimestre"
              // disabled={isJe ? !isJe : isEfectivizado}
            />
            <FormControlLabel
              value={'3'}
              control={<Radio />}
              label="Tercer Bimestre"
              disabled={isEfectivizado || isJe}
              // disabled={isJe || cicloLectivo?.idCicloLectivo === 24}
            />
            <FormControlLabel
              value={'4'}
              control={<Radio />}
              label="Cuarto Bimestre"
              disabled={isEfectivizado}
              // disabled={cicloLectivo?.idCicloLectivo === 24}
            />
            <FormControlLabel
              value={'5'}
              control={<Radio />}
              label="Período de Recuperación"
              disabled={isEfectivizado || isJe}
            />
          </RadioGroup>
        </FormControl>
      </Grid>
      <Grid className={classes.footer}>
        <Footer buttonConfig={buttonConfig} />
      </Grid>
    </Grid>
  );
};
