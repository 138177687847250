import React, { useCallback, useMemo, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import { useGeneralContext } from 'src/context/GeneralContext';
import {
  useEspacioCurricularByIdContext,
  INITIAL_YEAR,
} from 'src/app/business';
import { useDebounce } from 'src/commons/hooks';
import { PLANIFICACION_ESTADOS } from 'src/commons/const';
import confirmDialog from 'src/commons/services/confirmDialog';
import {
  DisciplinarOrientadoTable,
  DisciplinarTable,
  InterArealTable,
  InterDisciplinarTable,
} from '../tables';
import { DisciplinarInterArealTable } from '../tables/DisciplinarInterArealTable';
import { DisciplinarInterDisciplinarTable } from '../tables/DisciplinarInterDisciplinarTable';
import { DisciplinarOrientadoInterDisciplinarTable } from '../tables/DisciplinarOrientadoInterDisciplinarTable';

const { EN_PROCESO, ENVIADO, APROBADO, REQUIERE_CAMBIOS } =
  PLANIFICACION_ESTADOS;

export type TipoPlanificacion = {
  id: string;
  descripcion?: string;
  component: React.ComponentType<any>;
  anio?: string;
};

export const planificationTypesIDs = [
  'disciplinar',
  'disciplinarOrientado',
  'interDisciplinar',
  'interareal',
  'disciplinarInterArealTable',
  'disciplinarInterDisciplinarTable',
];
type PlanificationTypesID = typeof planificationTypesIDs[number];

const pathSelect: Record<PlanificationTypesID, string> | any = {
  disciplinar: 'simpDiscipPlan.planificacionEstados.idPlanificacionEstados',
  disciplinarOrientado:
    'discipOrientPlan.planificacionEstados.idPlanificacionEstados',
  interDisciplinar:
    'interdiscipPlan.planificacionEstados.idPlanificacionEstados',
  interareal: 'arealPlan.planificacionEstados.idPlanificacionEstados',

  // TODO: Cuando haya una api general se puede cambiar por un único path
  disciplinarInterArealTable: {
    disciplinar: 'simpDiscipPlan.planificacionEstados.idPlanificacionEstados',
    interareal: 'arealPlan.planificacionEstados.idPlanificacionEstados',
  },
  disciplinarInterDisciplinarTable: {
    disciplinar: 'simpDiscipPlan.planificacionEstados.idPlanificacionEstados',
    interdisciplinar:
      'interdiscipPlan.planificacionEstados.idPlanificacionEstados',
  },
  disciplinarOrientadoInterDisciplinarTable: {
    disciplinarOrientado:
      'discipOrientPlan.planificacionEstados.idPlanificacionEstados',
    interDisciplinar:
      'interdiscipPlan.planificacionEstados.idPlanificacionEstados',
  },
};

const pathSearch: Record<PlanificationTypesID, string> | any = {
  disciplinar: 'espacioCurricular.descripcion',
  disciplinarOrientado: 'espacioCurricular.descripcion',
  interDisciplinar: 'interDiscipPlan.proyecto',
  interareal: 'arealPlan.proyecto',
  disciplinarInterArealTable: {
    disciplinar: 'espacioCurricular.descripcion',
    interareal: 'arealPlan.proyecto',
  },
  disciplinarInterDisciplinarTable: {
    disciplinar: 'espacioCurricular.descripcion',
    interdisciplinar: 'interDiscipPlan.proyecto',
  },
  disciplinarOrientadoInterDisciplinarTable: {
    disciplinarOrientado: 'espacioCurricular.descripcion',
    interdisciplinar: 'interDiscipPlan.proyecto',
  },
};

const planificacion = [
  { title: 'En proceso', idPlanificacionEstados: EN_PROCESO },
  { title: 'Enviado', idPlanificacionEstados: ENVIADO },
  { title: 'Requiere cambios', idPlanificacionEstados: REQUIERE_CAMBIOS },
  { title: 'Aprobado', idPlanificacionEstados: APROBADO },
];

const addIf = (condition: boolean, obj: any) => (condition ? [obj] : []);

export const usePlanificationTable = ({ ciclo_lectivo }: any) => {
  const { generalState, dispatch } = useGeneralContext();
  const { data: espacioCurricular } = useEspacioCurricularByIdContext();
  const match = useRouteMatch();
  const history = useHistory();

  const hasBlock = espacioCurricular?.materia.tieneBloque;
  const isCommonMatter = espacioCurricular?.materia.anio.numeroAnio < 3;

  const [selectedType, setSelectedType] = useState<TipoPlanificacion>({
    id: hasBlock
      ? 'disciplinarOrientadoInterDisciplinarTable'
      : isCommonMatter
      ? 'disciplinarInterArealTable'
      : 'disciplinarInterDisciplinarTable',
    component: hasBlock
      ? DisciplinarOrientadoInterDisciplinarTable
      : isCommonMatter
      ? DisciplinarInterArealTable
      : DisciplinarInterDisciplinarTable,
  });

  const [selectValues, setSelectValues] = useState({
    estado: '',
  });

  const [planificationAmount, setPlanificationAmount] = useState(0);

  const [search, setSearch] = useState('');
  const [idCicloLectivo, setIdCicloLectivo] = useState('');

  const searchDebounced = useDebounce(search, 1000);

  const tiposPlanificaciones: TipoPlanificacion[] = useMemo(
    () => [
      ...addIf(!hasBlock && isCommonMatter, {
        id: 'disciplinarInterArealTable',
        descripcion: 'Ambos tipos',
        component: DisciplinarInterArealTable,
      }),
      ...addIf(!hasBlock && !isCommonMatter, {
        id: 'disciplinarInterDisciplinarTable',
        descripcion: 'Ambos tipos',
        component: DisciplinarInterDisciplinarTable,
      }),
      ...addIf(hasBlock && !isCommonMatter, {
        id: 'disciplinarOrientadoInterDisciplinarTable',
        descripcion: 'Ambos tipos',
        component: DisciplinarOrientadoInterDisciplinarTable,
      }),

      ...addIf(!hasBlock, {
        id: 'disciplinar',
        descripcion: 'Disciplinar',
        component: DisciplinarTable,
      }),
      ...addIf(!isCommonMatter && hasBlock, {
        id: 'disciplinarOrientado',
        descripcion: 'Disciplinar Orientado',
        component: DisciplinarOrientadoTable,
      }),
      ...addIf(!isCommonMatter, {
        id: 'interDisciplinar',
        descripcion: 'Interdisciplinar',
        component: InterDisciplinarTable,
      }),
      ...addIf(!hasBlock && isCommonMatter, {
        id: 'interareal',
        descripcion: 'Areal/Interareal',
        component: InterArealTable,
      }),
    ],
    [hasBlock, isCommonMatter],
  );

  const objSelectPath = useMemo(
    () =>
      selectValues.estado
        ? [
            {
              path: pathSelect[selectedType.id],
              value: selectValues.estado,
            },
          ]
        : [],
    [selectValues, selectedType],
  );

  const createdPlanificationCheck = (list: number) =>
    setPlanificationAmount(list);

  const objSearchPath = useMemo(
    () =>
      searchDebounced
        ? [
            {
              path: pathSearch[selectedType?.id],
              value: encodeURIComponent(searchDebounced),
              method: 'includes',
            },
          ]
        : [],
    [selectedType, searchDebounced],
  );

  const cicloFilter = useMemo(
    () =>
      idCicloLectivo
        ? {
            disciplinar: 'simpDiscipPlan.cicloLectivo.idCicloLectivo',
            disciplinarOrientado:
              'discipOrientPlan.cicloLectivo.idCicloLectivo',
            interareal: 'arealPlan.cicloLectivo.idCicloLectivo',
            interdisciplinar: 'interdiscipPlan.cicloLectivo.idCicloLectivo',
            value: idCicloLectivo,
          }
        : {},
    [idCicloLectivo],
  );

  const onTypeChange = useCallback(
    ({ target }) => {
      const selected = tiposPlanificaciones.find(
        (item) => item.id === target.value,
      );
      if (selected) {
        setSelectedType(selected);
      }
    },
    [tiposPlanificaciones],
  );

  const handleChangeSelect = useCallback(({ target }) => {
    setSelectValues({
      estado: target.value,
    });
  }, []);

  const handleChangeSearch = useCallback(
    ({ target }) => setSearch(target.value),
    [],
  );

  const handleChangeLectiveCycle = useCallback(
    ({ target }) => setIdCicloLectivo(target.value),
    [],
  );

  const findPlanificationById = useCallback(
    (idTypePlanification: string) =>
      tiposPlanificaciones.find(
        (typePlanification) => typePlanification.id === idTypePlanification,
      ) as TipoPlanificacion,
    [tiposPlanificaciones],
  );

  const crearPlanificaciones = useMemo(
    () => [
      ...addIf(!hasBlock && isCommonMatter, {
        title: 'Areal / Interareal',
        handleOnClick: () => {
          dispatch.selectPlanification({
            planificacion: findPlanificationById('interareal'),
            anio: espacioCurricular.materia.anio.idAnio,
            seccion:
              generalState.espacioCurricular.planificacion.selected.seccion,
          });
          history.push(`${match.url}/planificacion/interArealInterAreal/new`);
        },
      }),
      ...addIf(!isCommonMatter, {
        title: 'Interdisciplinar',
        handleOnClick: () => {
          dispatch.selectPlanification({
            planificacion: findPlanificationById('interDisciplinar'),
            anio: espacioCurricular.materia.anio.idAnio,
            seccion:
              generalState.espacioCurricular.planificacion.selected.seccion,
          });
          history.push(`${match.url}/planificacion/interDisciplinar/new`);
        },
      }),
      ...addIf(!hasBlock, {
        title: 'Disciplinar',
        handleOnClick: async () => {
          dispatch.selectPlanification({
            planificacion: findPlanificationById('disciplinar'),
            anio: espacioCurricular.materia.anio.idAnio,
            seccion:
              generalState.espacioCurricular.planificacion.selected.seccion,
          });
          if (planificationAmount > 0) {
            const confirm = await confirmDialog.show({
              title: 'Planificación',
              confirmText: 'Continuar',
              cancelText: 'Salir',
              content:
                'Ya existe una planificación disciplinar creada, ¿desea continuar?',
            });
            if (confirm) {
              history.push(`${match.url}/planificacion/disciplinar/new`);
            }
            return;
          }
          history.push(`${match.url}/planificacion/disciplinar/new`);
        },
      }),
      ...addIf(!isCommonMatter && hasBlock, {
        title: 'Disciplinar Orientada',
        handleOnClick: async () => {
          dispatch.selectPlanification({
            planificacion: findPlanificationById('disciplinarOrientado'),
            anio: espacioCurricular.materia.anio.idAnio,
            seccion:
              generalState.espacioCurricular.planificacion.selected.seccion,
          });
          if (planificationAmount > 0) {
            const confirm = await confirmDialog.show({
              title: 'Planificación',
              confirmText: 'Continuar',
              cancelText: 'Salir',
              content:
                'Ya existe una planificación disciplinar orientada creada, ¿desea continuar?',
            });
            if (confirm) {
              history.push(
                `${match.url}/planificacion/disciplinarOrientada/new`,
              );
            }
            return;
          }
          history.push(`${match.url}/planificacion/disciplinarOrientada/new`);
        },
      }),
    ],
    [
      dispatch,
      findPlanificationById,
      hasBlock,
      history,
      isCommonMatter,
      match.url,
      espacioCurricular,
      planificationAmount,
      generalState,
    ],
  );

  const validLectiveCycles = ciclo_lectivo
    ? ciclo_lectivo.filter((cycle: any) => cycle.anio >= INITIAL_YEAR)
    : [];

  const arrFilterTables = [
    [
      {
        title: 'Tipo',
        placeholder: 'Seleccione un tipo',
        labelKey: 'descripcion',
        valueKey: 'id',
        content: tiposPlanificaciones,
        handleChange: onTypeChange,
        value: selectedType?.id,
        name: 'planificationType',
      },
      {
        title: 'Estado',
        placeholder: 'Seleccione un estado',
        labelKey: 'title',
        valueKey: 'idPlanificacionEstados',
        content: planificacion,
        handleChange: handleChangeSelect,
        value: selectValues.estado,
        name: 'estado',
      },
      {
        title: 'Ciclo Lectivo',
        placeholder: 'Seleccioná un Ciclo Lectivo',
        labelKey: 'anio',
        valueKey: 'idCicloLectivo',
        content: validLectiveCycles.sort((a: any, b: any) =>
          String(b.anio).localeCompare(String(a.anio)),
        ),
        handleChange: handleChangeLectiveCycle,
        value: idCicloLectivo,
        name: 'cicloLectivo',
      },
    ],
    [
      {
        input: true,
        handleChange: handleChangeSearch,
        name: 'search',
        placeholder: 'Buscar',
        value: search,
      },
    ],
  ];

  return {
    Table: selectedType?.component,
    objSelectPath,
    objSearchPath,
    arrFilterTables,
    crearPlanificaciones,
    espacioCurricular,
    createdPlanificationCheck,
    cicloFilter,
  };
};
