import React from 'react';
import { Dias } from '../utils';
import moment from 'moment';
import { AnyObject } from '../../../../commons';

const columnHeaders = [
  'Sector',
  'Organización',
  'Acción',
  'HS ACAP',
  'Cupo total',
  'Turno',
  'Días',
  'Fecha inicio',
  'Fecha fin',
  'Fecha de publicación',
];

const columnHeadersDetail = [
  'Organización',
  'Acción',
  'Turno',
  'Grupo',
  'Cupo',
  'Referente',
  'Día/Hora inicio/Hora fin',
];

export const generateOfertasExcelData = <T extends AnyObject>(
  rows: T[] = [],
  isDetail: boolean,
  name: string,
) => {
  const rowsForExcel = rows.map((row: any) => {
    let horasTotales = 0;
    let turnosAll = '';
    let diasEnGrupo: any = [];
    let diasAll = '';
    for (let i = 0; i < row?.turnos?.length; i++) {
      horasTotales += Number(row?.turnos[i]?.cupo);
      turnosAll += `${row?.turnos[i]?.turno?.descripcionTurno[0]}${
        row?.turnos?.length > 1 && row?.turnos?.length - 1 !== i ? ',' : ''
      }`;
      row?.turnos[i]?.grupos?.forEach((grupo: any) =>
        grupo?.jornadas?.forEach((jornada: any) => {
          const CheckDiasEnGrupo = diasEnGrupo.filter(
            (dia: any) => dia === jornada?.cupo,
          );
          if (!Boolean(CheckDiasEnGrupo.length)) {
            diasEnGrupo.push(jornada?.cupo);
          }
        }),
      );
    }
    diasEnGrupo
      .map((dia: any) => (Dias[dia] ? Dias[dia].slice(0, 3) : Dias[dia]))
      .forEach((dia: any, index: any) => {
        if (dia !== undefined)
          diasAll += `${dia}${
            diasEnGrupo.length > 1 && diasEnGrupo.length - 1 !== index
              ? ','
              : ''
          }`;
      });

    return {
      Sector: row?.accion?.organizacion?.sector?.nombre,
      Organización: row?.accion?.organizacion?.nombre,
      Acción: row?.accion?.nombre,
      'HS ACAP': row?.accion?.horas?.toString(),
      'Cupo total': horasTotales.toString(),
      Turno: turnosAll,
      Días: diasAll,
      'Fecha inicio': moment(row?.fechaInicio).format('DD/MM/YYYY'),
      'Fecha fin': moment(row?.fechaFin).format('DD/MM/YYYY'),
      'Fecha de publicación': moment(row?.fechaPublicacion).format(
        'DD/MM/YYYY',
      ),
    };
  });

  const rowsForExcelDetail = rows.map((row: any) => {
    let diaHoraFormatted = '';
    row?.jornadas?.forEach(
      (jornada: any) =>
        (diaHoraFormatted += `${
          Dias[jornada.cupo][0] + Dias[jornada.cupo].slice(1).toLowerCase()
        }/${moment(jornada.horaInicio, 'HH:mm:ss').format('HH:mm')}/${moment(
          jornada.horaFin,
          'HH:mm:ss',
        ).format('HH:mm')}\n`),
    );
    return {
      Organización: row?.organizacion,
      Acción: row?.accion,
      Turno: row?.turno,
      Grupo: row?.nombreGrupo,
      Cupo: row?.cupoGrupo?.toString(),
      Referente: Boolean(row?.referente?.apellido)
        ? `${row?.referente?.apellido}, ${row?.referente?.nombre}`
        : row?.referente?.nombre,
      'Día/Hora inicio/Hora fin': diaHoraFormatted,
    };
  });

  return isDetail
    ? {
        body: {
          columns: columnHeadersDetail,
          data: rowsForExcelDetail,
        },
        name,
      }
    : {
        body: {
          columns: columnHeaders,
          data: rowsForExcel,
        },
        name,
      };
};

export const useOfertasExcelData = generateOfertasExcelData;
