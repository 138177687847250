import React, { useMemo, useState, useCallback } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import {
  InfoTable,
  Footer,
  InfoTableColumn,
  ButtonTypesProps,
} from 'src/commons';
import { useAlumnosColumns } from '../hooks/useAlumnosColumns';
import { useInscripcionDetalleExcelData } from '../hooks/useInscripcionDetalleExcelData';
import useGenerarExcel from 'src/commons/hooks/useGenerarExcel';
import moment from 'moment';
import { cloneDeep } from 'lodash';
import { DetallesInscripto, GruposInscripcionAcumulate } from '../types';

const useStyles = makeStyles({
  alumnosTable: {
    width: '80%',
    margin: 'auto',
    marginTop: '15px',
  },
});

interface InscripcionDetalleProps {
  detalleStep: DetallesInscripto;
  columns: InfoTableColumn<GruposInscripcionAcumulate>[];
  setDetalleStep: React.Dispatch<React.SetStateAction<DetallesInscripto>>;
}

export const InscripcionDetalle = ({
  detalleStep,
  setDetalleStep,
  columns,
}: InscripcionDetalleProps) => {
  const alumnosColumns = useAlumnosColumns();
  const classes = useStyles();
  const [direction, setDirection] = useState<'asc' | 'desc'>('desc');
  const excelData = useInscripcionDetalleExcelData(
    detalleStep.row,
    `Detalle de inscripción del día ${moment().format('DD/MM/YYYY')}`,
  );
  const generateExcel = useGenerarExcel();

  const readonlyButtonConfig: ButtonTypesProps[] = [
    {
      title: 'Volver',
      handleOnClick: () => setDetalleStep({ enabled: false, row: [] }),
      type: 'primary',
      size: 'medium',
    },
    {
      title: 'Descargar',
      handleOnClick: () => generateExcel(excelData?.body, excelData?.name),
      type: 'primary',
      size: 'medium',
    },
  ];

  const inscriptosOrderer = useMemo(
    () =>
      cloneDeep(detalleStep.row[0]?.alumnoMovimientos).sort(
        (alumnoMovimientoA, alumnoMovimientoB) => {
          const alumnoNombreA =
            alumnoMovimientoA.alumno.persona.apellido.toLowerCase();
          const alumnoNombreB =
            alumnoMovimientoB.alumno.persona.apellido.toLowerCase();
          if (alumnoNombreA < alumnoNombreB) {
            return direction === 'desc' ? -1 : 1;
          }
          if (alumnoNombreA > alumnoNombreB) {
            return direction === 'desc' ? 1 : -1;
          }
          return 0;
        },
      ),
    [detalleStep.row, direction],
  );

  const handleRequestInscriptosSort = useCallback(
    (orderBy: string, _direction: 'asc' | 'desc') => {
      setDirection(_direction);
    },
    [],
  );

  const totalInscriptosTodasEscuelas =
    (detalleStep.row[0]?.totalAlumnosInscriptosEscuelas || 0) -
    detalleStep.row[0]?.alumnoMovimientos?.length;

  return (
    <>
      <Typography
        style={{ margin: '30px 5px', fontSize: '25px', color: '#737373' }}
      >
        Detalle de inscripción
      </Typography>
      <Grid
        style={{
          border: '1px solid black',
          padding: '10px',
          margin: '5px',
          marginTop: '15px',
        }}
      >
        <Typography style={{ fontWeight: '700' }}>
          Actividad seleccionada
        </Typography>
        <InfoTable rows={detalleStep.row} columns={columns} />
      </Grid>
      <Grid
        style={{
          border: '1px solid black',
          padding: '10px',
          margin: '5px',
          marginTop: '15px',
        }}
      >
        <Typography style={{ fontWeight: '700' }}>
          Listado de inscriptos
        </Typography>
        <InfoTable
          paperCustomStyle={classes.alumnosTable}
          rows={inscriptosOrderer}
          columns={alumnosColumns}
          onSortChange={handleRequestInscriptosSort}
          direction={direction}
        />
        <Grid
          container
          item
          md={12}
          justifyContent="flex-start"
          alignItems="center"
          style={{
            marginTop: '15px',
          }}
        >
          <Typography style={{ marginRight: '30px' }}>
            <b>Total de inscriptos escuela:</b>{' '}
            {detalleStep.row[0]?.alumnoMovimientos?.length}
          </Typography>
          {totalInscriptosTodasEscuelas > 0 && (
            <Typography style={{ marginRight: '30px' }}>
              <b>Total de inscriptos otras escuelas:</b>{' '}
              {totalInscriptosTodasEscuelas}
            </Typography>
          )}
          <Typography>
            <b>Cupo disponible:</b>{' '}
            {detalleStep.row[0]?.ofertaGrupo?.ofertaTurno?.cupo /
              detalleStep.row[0]?.ofertaGrupo?.ofertaTurno?.cantGrupos -
              (detalleStep.row[0]?.totalAlumnosInscriptosEscuelas || 0)}
          </Typography>
          <Typography style={{ marginLeft: '30px' }}>
            <b>Equipo:</b>{' '}
            {detalleStep.row[0]?.ofertaGrupo?.ofertaTurno?.oferta.accion.nombre}{' '}
            -{' '}
            {
              detalleStep.row[0]?.ofertaGrupo?.ofertaTurno?.turno
                .descripcionTurno
            }{' '}
            - id {detalleStep.row[0]?.ofertaGrupo.idOfertaGrupo}
          </Typography>
        </Grid>
      </Grid>
      <Footer position="center" buttonConfig={readonlyButtonConfig} />
    </>
  );
};
