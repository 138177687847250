import { Rules } from 'src/lib/permission/types';
import { BLOCKER_ID, SIDEMENU_ID, TABBAR_BLOCKER_ID } from '../../const';

// route supervisor
export const supervisorInicialRulesSets: Rules = {
  idRule: 'rol-11-nivel-1',
  routes: [],
  blockers: [{ id: BLOCKER_ID.ESTABLISHMENT_PASS_MANAGEMENT }],
  sideMenu: [],
  tabBars: [{ id: TABBAR_BLOCKER_ID.PRESENTISMO_CONFIGURACION }],
};
