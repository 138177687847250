import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Cargo } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'cargo';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idCargo',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idCargo',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Cargo
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Cargo,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Cargo.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Cargo>(DAO_NAME, optionsToUse);

// hooks
export const useCargoFetchById = fetchById;
export const useCargoLibbyCall = libbyCall;
export const useCargoDAO = libbyDAO;
export const useCargoLibbyFetch = libbyFetch;

// components/hoc
export const CargoAutocomplete = entityAutocomplete;
export const CargoCall = renderPropCall;
export const CargoById = renderPropFetchById;
export const CargoSelect = simpleSelect;
export const CargoAccordionSelector = accordionSelector;
export const CargoSelectedChips = selectedChips;

// context
// ByIdContext
export const useCargoByIdContext = useByIdContext;
export const CargoByIdProvider = ByIdProvider;
export const CargoByIdContext = ByIdContext;
// GeneralContext
export const useCargoContext = useGeneralContext;
export const CargoProvider = GeneralProvider;
export const CargoContext = GeneralContext;
