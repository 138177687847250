import { ROL } from './rol';

type rol = {
  path: string;
  value: number;
};

export interface RolFilter {
  roles: rol[];
}

export const publicacionRolFilter: RolFilter = {
  roles: [
    { path: 'idRolUsuario', value: ROL.DOCENTE },
    { path: 'idRolUsuario', value: ROL.ESTUDIANTE },
  ],
};
