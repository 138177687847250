import { Grid } from '@material-ui/core';
import React from 'react';
import { MainInput, SimpleSelect } from 'src/commons';
import { SearchFilter } from './types';
import { customStyle, optionsTypes } from './const';

interface AddStudenFilterProps {
  filter: SearchFilter;
  setFilter: React.Dispatch<React.SetStateAction<SearchFilter>>;
}

export const AddStudenFilter = ({
  filter,
  setFilter,
}: AddStudenFilterProps) => {
  const handleChange = ({
    target: { name, value },
  }: React.ChangeEvent<any>) => {
    let _valor = value;
    if (name === 'selected') {
      _valor = optionsTypes.find((ot) => ot.value === value);
    }

    setFilter((prev) => ({ ...prev, [name]: _valor }));
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={4}>
        <SimpleSelect
          placeholder=""
          labelKey="label"
          valueKey="value"
          handleChange={handleChange}
          value={filter.selected.value}
          name="selected"
          content={optionsTypes}
        />
      </Grid>

      <Grid item xs={8}>
        <MainInput
          type="search"
          placeholder=""
          name="search"
          handleChange={handleChange}
          value={filter.search}
          fullWidth
          customStyle={{ padding: 9.5, width: '95%' }}
        />
      </Grid>
    </Grid>
  );
};
