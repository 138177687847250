import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { SolicitudPaseDAO } from '../../platform/libby/dao';
import { SolicitudPases } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'solicitud_pases';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idSolicitudPases',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idSolicitudPases',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.SolicitudPases
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.SolicitudPases,
      hoc: { ...defaultOptions.hoc, ...businessOptions.SolicitudPases.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<SolicitudPases, SolicitudPaseDAO>(
  DAO_NAME,
  optionsToUse,
);

// hooks
export const useSolicitudPasesFetchById = fetchById;
export const useSolicitudPasesLibbyCall = libbyCall;
export const useSolicitudPasesDAO = libbyDAO;
export const useSolicitudPasesLibbyFetch = libbyFetch;

// components/hoc
export const SolicitudPasesAutocomplete = entityAutocomplete;
export const SolicitudPasesCall = renderPropCall;
export const SolicitudPasesById = renderPropFetchById;
export const SolicitudPasesSelect = simpleSelect;
export const SolicitudPasesAccordionSelector = accordionSelector;
export const SolicitudPasesSelectedChips = selectedChips;

// context
// ByIdContext
export const useSolicitudPasesByIdContext = useByIdContext;
export const SolicitudPasesByIdProvider = ByIdProvider;
export const SolicitudPasesByIdContext = ByIdContext;
// GeneralContext
export const useSolicitudPasesContext = useGeneralContext;
export const SolicitudPasesProvider = GeneralProvider;
export const SolicitudPasesContext = GeneralContext;
