import { Grid, Typography, makeStyles } from '@material-ui/core';
import React, {
  FC,
  useCallback,
  useMemo,
  useState,
  useEffect,
  ChangeEvent,
} from 'react';
import { AnyObject, Loading, MainInput, SimpleSelect } from 'src/commons';
import { useCriteriosEvaluacionLibbyFetch } from 'src/app/business/acap/CriterioEvaluacion';
import { CriterioEvaluacion, EvaluacionDevolucion } from 'src/app/models';
import DianaChart from '../../../../../commons/components/Diana/DianaChart';
import { invertirFormatoFecha } from './utils';

export const EVALUACIONES = ['No evaluado', 'Grado 1', 'Grado 2', 'Grado 3'];

export const useRadarStyles = makeStyles({
  radar: {
    width: 400,
    margin: 'auto',
  },
});

export const DevolucionOrganizacion: FC<
  Acap.Estudiante.DevOrganizacionProps
> = ({
  devolucionesOrganizacion,
  workingDevolucionesOrganizacion,
  workingGrupoInscripcion,
}) => {
  const [filterdevoluciones, setFilterDevoluciones] = useState('');
  const classes = useRadarStyles();

  useEffect(() => {
    if (devolucionesOrganizacion)
      setFilterDevoluciones(
        devolucionesOrganizacion?.[0]?.idDevolucionOrganizacion,
      );
  }, [devolucionesOrganizacion]);

  const criteriosFiter = useMemo(
    () => ({
      forEstudiante: [
        {
          path: 'forOrganizacion',
          value: true,
        },
      ],
      activo: [
        {
          path: 'activo',
          value: true,
        },
      ],
    }),
    [],
  );

  const fechasTransformadas = devolucionesOrganizacion.map((devolucion) => {
    return {
      ...devolucion,
      fechaAcap: invertirFormatoFecha(devolucion.fechaAcap),
    };
  });

  const getEvaluacion = useCallback(
    (criterio: CriterioEvaluacion) => {
      const evaluacion = devolucionesOrganizacion[0]?.evaluaciones.find(
        (eva: EvaluacionDevolucion) =>
          eva.criterio.idCriterioEvaluacion === criterio.idCriterioEvaluacion,
      );

      return Number.isInteger(evaluacion?.valor)
        ? EVALUACIONES[evaluacion!.valor]
        : '';
    },
    [devolucionesOrganizacion],
  );

  const { data: criteriosEvaluacion = [], working: loadingCriterios } =
    useCriteriosEvaluacionLibbyFetch({
      filter: criteriosFiter,
      limit: 4,
    });

  const loading = workingGrupoInscripcion || workingDevolucionesOrganizacion;

  const devolucionesOrganizacionFilter = devolucionesOrganizacion?.find(
    (id) => id.idDevolucionOrganizacion === filterdevoluciones,
  );

  if (loading) return <Loading />;
  return (
    <Grid
      container
      key={devolucionesOrganizacion[0]?.idDevolucionOrganizacion}
      spacing={2}
    >
      <Grid
        item
        xs={6}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'flex-start',
          marginTop: '1em',
        }}
      >
        {workingDevolucionesOrganizacion ? (
          <Loading />
        ) : devolucionesOrganizacion.length > 0 ? (
          <>
            <div>
              <SimpleSelect
                value={filterdevoluciones}
                title="Fecha de devolución"
                name="Fecha de devolución"
                placeholder="Seleccione fecha"
                content={fechasTransformadas}
                handleChange={(
                  e: ChangeEvent<{ value: string | number | AnyObject }>,
                ) => {
                  const value = e.target.value.toString();
                  setFilterDevoluciones(value);
                }}
                labelKey="fechaAcap"
                valueKey="idDevolucionOrganizacion"
              />
            </div>
            <div className={classes.radar}>
              <DianaChart
                labels={criteriosEvaluacion.map((criterio) => criterio.nombre)}
                evaluaciones={devolucionesOrganizacionFilter?.evaluaciones.map(
                  (eva: EvaluacionDevolucion) => eva.valor,
                )}
                title="Devolucion de la Organización"
              />
            </div>
          </>
        ) : (
          <Typography>No tiene devoluciones registradas.</Typography>
        )}
      </Grid>
      <Grid item xs={6}>
        {loadingCriterios ? (
          <Loading />
        ) : (
          <Grid container style={{ height: '100%' }}>
            {criteriosEvaluacion.map((criterio) => {
              return (
                <Grid
                  item
                  xs={12}
                  style={{
                    marginBottom: '6px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                  key={criterio.idCriterioEvaluacion}
                >
                  <Grid container style={{ alignItems: 'center' }}>
                    <Grid item xs={3}>
                      <Typography>{criterio.nombre}:</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <MainInput
                        label=""
                        disabled
                        value={getEvaluacion(criterio)}
                        handleChange={() => null}
                        placeholder={''}
                        fullWidth
                        multiline
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Grid>
      <Grid item xs={12} style={{ padding: '10px 30px 15px 30px' }}>
        <Grid container>
          <Grid item style={{ marginRight: '10px' }}>
            <Typography>Observaciones:</Typography>
          </Grid>
          <Grid item xs={11}>
            <MainInput
              label=""
              disabled
              value={devolucionesOrganizacionFilter?.observaciones}
              handleChange={() => null}
              placeholder={''}
              fullWidth
              multiline
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
