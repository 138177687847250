import {
  Persona,
  Rol,
  Seccion,
  TipoActividad,
  TipoDocumento,
  TipoInformes,
  TipoPeriodo,
  TipoPlanificacion,
  RolStatus,
} from 'src/app/models';

export const SELECT_PLANIFICATION = 'SELECT_PLANIFICATION';
export const SELECT_PLANIFICATION_ANIO = 'SELECT_PLANIFICATION_ANIO';
export const CHANGE_NEW_USER = 'CHANGE_NEW_USER';
export const SELECT_CICLO_LECTIVO = 'SELECT_CICLO_LECTIVO';
export const SELECT_CONSTANTES = 'SELECT_CONSTANTES';
export const VALUES_CHANGED = 'VALUES_CHANGED';
export const DEUDAS_ACADEMICAS_CHANGED = 'DEUDAS_ACADEMICAS_CHANGED';
export const DATE = 'DATE';
export const PRINT_PLANIFICACION = 'PRINT_PLANIFICACION';
export const SET_LEGAJO_CICLO_LECTIVO = 'SET_LEGAJO_CICLO_LECTIVO';

export const selectPlanification = (planification: {
  planificacion: TipoPlanificacion;
  anio: number;
  seccion: Seccion[];
}) => ({
  type: SELECT_PLANIFICATION as typeof SELECT_PLANIFICATION,
  payload: planification,
});

export const selectPlanificationAnio = (anio: {
  anio: number;
  bloque: boolean;
  isCommonMatter: boolean;
  seccion: Seccion[];
}) => ({
  type: SELECT_PLANIFICATION_ANIO as typeof SELECT_PLANIFICATION_ANIO,
  payload: anio,
});

export const changeNewUser = (persona: Persona) => ({
  type: CHANGE_NEW_USER as typeof CHANGE_NEW_USER,
  payload: persona,
});

export const selectCicloLectivo = (cicloLectivo: number) => ({
  type: SELECT_CICLO_LECTIVO as typeof SELECT_CICLO_LECTIVO,
  payload: cicloLectivo,
});

export const selectConstantes = (constantes: {
  Rol?: Rol[];
  TipoPeriodo?: TipoPeriodo[];
  TipoDocumento?: TipoDocumento[];
  TipoActividad?: TipoActividad[];
  TipoInformes?: TipoInformes[];
  RolStatus?: RolStatus[];
}) => ({
  type: SELECT_CONSTANTES as typeof SELECT_CONSTANTES,
  payload: constantes,
});

export const valuesChanged = (isChanged: any) => ({
  type: VALUES_CHANGED as typeof VALUES_CHANGED,
  payload: isChanged,
});

export const deudasAcademicasChanged = (isUpdated: any) => ({
  type: DEUDAS_ACADEMICAS_CHANGED as typeof DEUDAS_ACADEMICAS_CHANGED,
  payload: isUpdated,
});

export const date = (date: any) => ({
  type: DATE as typeof DATE,
  payload: date,
});

export const printPlanificacion = (data: any) => ({
  type: PRINT_PLANIFICACION as typeof PRINT_PLANIFICACION,
  payload: data,
});

export const setLegajoCicloLectivo = (data: any) => ({
  type: SET_LEGAJO_CICLO_LECTIVO as typeof SET_LEGAJO_CICLO_LECTIVO,
  payload: data,
});
