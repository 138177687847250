import React, { useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography } from '@material-ui/core';
import { ExternalSelectWorkShopTypesProps, useLibbyFetch } from 'src/commons';
import { InfoTable, SimpleSelect } from 'src/commons/components';
import { grey } from 'src/theme/colors';

const headerStyles = makeStyles(() => ({
  title: {
    fontWeight: 'normal',
    color: grey.medium,
    fontFamily: '"Open Sans", sans-serif',
    marginTop: '20px',
    paddingBottom: '10px',
    fontSize: 17,
  },
}));

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  container: { padding: '24px', paddingTop: 0 },
  item: { paddingBottom: '20px' },
};

const ExternalSelectWorkShopRaw = ({
  libby,
  rows,
  formValues,
  handleChange,
  localizacionId,
}: ExternalSelectWorkShopTypesProps) => {
  const defaultFilters = useMemo(
    () => ({
      defaultFilters: [{ path: 'localizacion', value: localizacionId }],
    }),
    [localizacionId],
  );
  const { data: actividad_extra_curricular } = useLibbyFetch(libby, {
    daoName: 'actividad_extra_curricular',
    filter: defaultFilters,
  });
  const classes = headerStyles();
  const column = [
    {
      id: 'name',
      label: 'Nombre y Apellido',
      width: '15%',
    },
    {
      id: 'document',
      label: 'Documento',
      width: '10%',
    },
    {
      id: 'establishment',
      label: 'Establecimiento',
      width: '20%',
    },
    {
      id: 'level',
      label: 'Nivel',
      width: '10%',
    },
  ];

  return (
    <>
      <Grid container style={customStyles.container}>
        <InfoTable rows={[rows]} columns={column} />
        <Grid container>
          <Typography variant="h6" className={classes.title}>
            Inscripción
          </Typography>
          <Grid item xs={12} style={customStyles.item}>
            <SimpleSelect
              name="actividadExtracurricular"
              title="Inscripto en"
              placeholder="Seleccioná materia, taller o servicio"
              labelKey="descripcionActividad"
              valueKey="idActividadExtracurricular"
              content={actividad_extra_curricular}
              handleChange={handleChange}
              value={formValues.actividadExtracurricular}
              customStyleTitle={customStyles.customStyleTitleSelect}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const ExternalSelectWorkShop = DatabaseConnector(
  ExternalSelectWorkShopRaw,
)('actividad_extra_curricular');
