import React, { useMemo } from 'react';
import {
  InterdiscipTempPlan,
  NewInterdiscipPlan,
} from '../../../../../../../../app/models';
import {
  useEspacioCurricularFetchById,
  useInterdiscipTempPlanFetchById,
} from '../../../../../../../../app/business';
import { useInterdisciplinarContext } from '../../commons/components';

const initialValues = {
  informacionGeneral: {},
  proyecto: {},
  objetivosDeAprendizaje: {
    objetivosDeAprendizaje: [],
  },
  espacioCurricular: {
    espaciosCurriculares: [
      {
        habilidades: [],
        contenidosEspecificos: [],
      },
    ],
  },
  actividad: {
    actividades: [
      {
        nombre: '',
        descripcion: '',
        horasCatedra: '',
        recursos: [],
        periodo: [],
      },
    ],
    periodoDeCalificacionFinalDelProyecto: [],
  },
  indicadoresDeEvaluacion: {
    indicadores: [],
    adicionales: [],
  },
  bibliografia: {},
};

export type childrenFunc = ({
  template,
  working,
  initialValues,
}: {
  template?: InterdiscipTempPlan;
  working: boolean;
  initialValues: NewInterdiscipPlan;
}) => JSX.Element;

export interface InterdiciplinarNewProps {
  children: childrenFunc;
  templateId: string | number;
  idEspacioCurricular: string | number;
}

export const InterdiciplinarNew = ({
  children,
  templateId,
  idEspacioCurricular,
}: InterdiciplinarNewProps) => {
  const { data: template, working } =
    useInterdiscipTempPlanFetchById(templateId);
  const { data, working: _working } = useInterdisciplinarContext();

  const { data: espacioCurricular } =
    useEspacioCurricularFetchById(idEspacioCurricular);

  const initialValuesMod = useMemo<NewInterdiscipPlan>(() => {
    return JSON.parse(
      JSON.stringify(
        espacioCurricular
          ? {
              ...initialValues,
              espacioCurricular: {
                espaciosCurriculares: [
                  {
                    espacioCurricular: {
                      id: espacioCurricular.idEspacioCurricular,
                      tieneBloque: espacioCurricular.materia.tieneBloque,
                    },
                    habilidades: [],
                    contenidosEspecificos: [],
                  },
                ],
              },
            }
          : initialValues,
      ),
    );
  }, [espacioCurricular]);

  return (
    <>
      {children({
        template,
        initialValues: initialValuesMod,
        working: working || _working,
      })}
    </>
  );
};
