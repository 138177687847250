import React from 'react';
import { Grid } from '@material-ui/core';
import { SimpleSelect, MainInput, MainButton } from 'src/commons/components';
import { FilterTablesEnrolledAdultTypesProps } from 'src/commons';
import { grey } from 'src/theme/colors';

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  containerStyles: { marginTop: 23 },
  searchGrid: { marginTop: 11 },
  inputSearch: { marginTop: 21 },
  counterContainer: {
    paddingBottom: 0,
    paddingTop: 19,
    fontSize: 14,
    color: grey.medium,
    fontFamily: 'Open Sans',
  },
};

export const FilterTablesEnrolled = ({
  studyPlanContent,
  turn,
  formValues,
  search,
  titleMainButton,
  registersCount,
  handleChangeSearch,
  handleChange,
  handleOnClick,
}: FilterTablesEnrolledAdultTypesProps) => {
  const { id_plan_estudio, id_turno } = formValues;
  return (
    <>
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        spacing={3}
        style={customStyles.containerStyles}
      >
        <Grid item xs={2}>
          <SimpleSelect
            title="Plan de estudios"
            placeholder="Seleccioná Plan"
            labelKey="descripcionPlanEstudio"
            valueKey="idPlanEstudioNivel"
            content={studyPlanContent}
            handleChange={handleChange}
            value={id_plan_estudio}
            name="planEstudioNivel"
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
        <Grid item xs={2}>
          <SimpleSelect
            title="Turno"
            placeholder="Seleccioná Turno"
            labelKey="descripcionTurno"
            valueKey="idTurno"
            content={turn}
            handleChange={handleChange}
            value={id_turno}
            name="id_turno"
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
        {/* <Grid */}
        {/*  item */}
        {/*  xs={2} */}
        {/*  justify="flex-start" */}
        {/*  alignItems="flex-end" */}
        {/*  style={customStyles.counterContainer} */}
        {/* > */}
        {/*  <span> */}
        {/*    {`${registersCount} resultados`} */}
        {/*  </span> */}
        {/* </Grid> */}
        <Grid
          container
          xs={6}
          justify="flex-end"
          spacing={3}
          alignItems="flex-end"
        >
          {titleMainButton && (
            <Grid item xs={5}>
              <MainButton
                title={titleMainButton}
                handleOnClick={handleOnClick}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <MainInput
              type="search"
              handleChange={handleChangeSearch}
              placeholder="Buscar"
              name="search"
              value={search}
              fullWidth
              customStyle={customStyles.inputSearch}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
