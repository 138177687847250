import React from 'react';
import { createContext, useContext, useReducer } from 'react';
import {
  calificacionesSecundarioReducer,
  initialState,
  ReducerAction,
  StateReducer,
} from '../reducers/secundario';

export const SecundarioReducerContext = createContext<{
  state: StateReducer;
  dispatch: React.Dispatch<ReducerAction>;
}>({
  state: initialState,
  dispatch: () => {},
});

export const useSecundarioReducerContext = () =>
  useContext(SecundarioReducerContext);

export const SecundarioReducerProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const [state, dispatch] = useReducer(
    calificacionesSecundarioReducer,
    initialState,
  );

  return (
    <SecundarioReducerContext.Provider value={{ state, dispatch }}>
      {children}
    </SecundarioReducerContext.Provider>
  );
};
