import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { nivelDefaultFilter } from 'src/commons/const';
import { PanelWithTitle } from '../../../../../../../commons/components/PanelWithTitle';
import { LetterIcon } from './LetterIcon';
import { useFormContext } from '../../../../../../../lib/templates/context';
import { FiltersByNivel } from './FiltersByNivel/FiltersByNivel';
import { NivelSelect } from '../../../../../../../app/business';
import { FormGenericControl } from '../../../../../../../lib/templates/components';

const SPACING = 4;

export const FiltersForm = () => {
  const { form, values } = useFormContext();
  const { idNivel } = values;

  return (
    <PanelWithTitle
      title="Configuración de filtros"
      icon={<LetterIcon>2</LetterIcon>}
    >
      <Typography variant="body1">
        Configurá los filtros para que los destinatarios de este recurso lo
        puedan encontrar con facilidad
      </Typography>
      <Grid container spacing={SPACING}>
        <Grid item md={6} sm={12}>
          <FormGenericControl
            name="idNivel"
            form={form}
            component={NivelSelect}
            componentProps={{
              title: 'Nivel',
              placeholder: 'Seleccioná nivel',
              filter: nivelDefaultFilter,
            }}
          />
        </Grid>
        {idNivel && <FiltersByNivel nivel={idNivel} form={form} />}
      </Grid>
    </PanelWithTitle>
  );
};
