import React from 'react';
import { Footer } from 'src/commons';
import { LoadingStyled } from './styled/Loading';

// Estos colegios se daran de baja
// y no tiene que pasar por el proceso de IEL
export const initialSchoolsToRemove = [
  20147802, 20097502, 20132503, 20163801, 20034403, 20044402, 20022000,
  20105801,
];

export const FooterButtons = ({
  informarIEL,
  manageProyeccion,
  disabledIEL = false,
  disabledManagement = false,
  titleIEL,
  localizacion,
}: Promocion.FooterButtonsGestionProyeccion) => {
  let buttonConfig: any = [
    {
      title: titleIEL,
      size: 'medium',
      handleOnClick: () => informarIEL(),
      disabled: disabledIEL,
    },
    {
      title: 'Gestionar Proyección',
      size: 'medium',
      handleOnClick: () => manageProyeccion(),
      disabled: disabledManagement,
    },
  ];

  if (initialSchoolsToRemove.includes(localizacion))
    buttonConfig = buttonConfig.slice(1);

  return <Footer buttonConfig={buttonConfig} />;
};
