import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import { TabBar } from 'src/commons';
import {
  useApreciacionActividadLibbyFetch,
  useDevolucionOrganizacionLibbyFetch,
} from '../../../../../app/business/acap';
import {
  AutoevaluacionEstudiante,
  ApreciacionAccion,
  DevolucionOrganizacion,
} from './components';
import { useEscuelaContext } from '../../context/EscuelaContext';
import { DEVOLUCIONES_SCREEN } from '../../enums/DevolucionesScreen';

export const DevolucionesView = () => {
  const { alumnoSelected, setCurrentTap, setAlumnoSelected } =
    useEscuelaContext();
  const [isOrgaTab, setIsOrgaTap] = useState(false);

  const apreciacionFilter = useMemo(
    () => ({
      grupo: [
        {
          path: 'grupoInscripcion',
          value: alumnoSelected?.idGrupoInscripcion,
        },
      ],
      criterio: [
        {
          path: 'evaluaciones.criterioEvaluacion.forOrganizacion',
          value: false,
        },
      ],
    }),
    [alumnoSelected?.idGrupoInscripcion],
  );
  const { data: [apreciaciones] = [], working: apreciacionWorking } =
    useApreciacionActividadLibbyFetch({
      filter: apreciacionFilter,
      limit: 1,
      aspect: 'basic',
      enabled: Boolean(alumnoSelected),
    });

  const apreciacion: Acap.Estudiante.ApreciacionInitialValues = useMemo(
    () => ({
      idApreciacion: apreciaciones?.idApreciacion,
      grupoInscripcion: alumnoSelected ? alumnoSelected.idGrupoInscripcion : 0,
      observaciones: apreciaciones?.observaciones ?? '',
      observacionesReferente: apreciaciones?.observacionesReferente ?? '',
      evaluaciones: apreciaciones?.evaluaciones ?? [],
    }),
    [apreciaciones, alumnoSelected],
  );

  const organizacionFilter = useMemo(
    () => ({
      grupo: [
        {
          path: 'grupoInscripcion.idGrupoInscripcion',
          value: alumnoSelected?.idGrupoInscripcion,
        },
      ],
      criterio: [
        {
          path: 'evaluaciones.criterio.forOrganizacion',
          value: true,
        },
      ],
    }),
    [alumnoSelected?.idGrupoInscripcion],
  );

  const { data: [organizacionEva] = [], working: orgaWorking } =
    useDevolucionOrganizacionLibbyFetch({
      filter: organizacionFilter,
      enabled: Boolean(alumnoSelected),
      aspect: 'basic',
      orderBy: 'fechaAcap',
      direction: 'desc',
    });

  const handleMountOrganizacion = useCallback<(mounted: boolean) => void>(
    (mounted) => setIsOrgaTap(mounted),
    [setIsOrgaTap],
  );

  const allTabs = useMemo(
    () => [
      {
        title: 'Apreciación de la acción',
        component: (
          <ApreciacionAccion
            apreciacion={apreciacion}
            loading={apreciacionWorking}
          />
        ),
      },
      {
        title: 'Autoevaluación Estudiante',
        component: <AutoevaluacionEstudiante />,
      },
      {
        title: 'Devolución Organización',
        component: (
          <DevolucionOrganizacion
            devolucion={organizacionEva}
            loading={orgaWorking}
            lisenerTab={handleMountOrganizacion}
          />
        ),
      },
    ],
    [
      apreciacion,
      apreciacionWorking,
      organizacionEva,
      orgaWorking,
      handleMountOrganizacion,
    ],
  );

  return (
    <Grid container>
      <Grid item>
        <Typography variant="h3">Ver Devoluciones</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        component={Box}
        padding={4}
        height="auto"
        marginY={4}
        border={'1px solid #000000'}
      >
        <Grid item xs={12} sm={12} md={12} lg={8} container spacing={2}>
          <Grid item xs={6} container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h5">Estudiante:</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label=""
                fullWidth
                value={`${
                  alumnoSelected?.alumnoMovimiento?.alumno?.persona?.apellido ||
                  ''
                } ${
                  alumnoSelected?.alumnoMovimiento?.alumno?.persona?.nombre ||
                  '-'
                }`}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h5">Establecimiento:</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label=""
                fullWidth
                value={
                  alumnoSelected?.alumnoMovimiento?.seccion?.localizacion
                    ?.descripcion || ''
                }
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h5">Organización:</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label=""
                fullWidth
                value={
                  alumnoSelected?.ofertaGrupo?.ofertaTurno?.oferta?.accion
                    ?.organizacion.nombre || ''
                }
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h5">Actividad:</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label=""
                fullWidth
                value={
                  alumnoSelected?.ofertaGrupo?.ofertaTurno?.oferta?.accion
                    ?.nombre || ''
                }
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
          {isOrgaTab ? (
            <Grid item xs={6} container alignItems="center">
              <Grid item xs={6}>
                <Typography variant="h5">Referente:</Typography>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label=""
                  fullWidth
                  value={
                    alumnoSelected?.ofertaGrupo?.referente?.apellido +
                    ' ' +
                    alumnoSelected?.ofertaGrupo?.referente?.nombre
                  }
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12} md={4} container></Grid>
      </Grid>
      <Grid item xs={12}>
        <TabBar content={allTabs} />
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '15px',
        }}
      >
        <Button
          color="primary"
          variant="contained"
          component="label"
          style={{ marginTop: '20px' }}
          onClick={() => {
            setCurrentTap(DEVOLUCIONES_SCREEN.ENROLES);
            setAlumnoSelected(undefined);
          }}
        >
          Volver
        </Button>
      </Grid>
    </Grid>
  );
};
