import React, { useMemo } from 'react';
import { usePublicacionFetchById } from 'src/app/business';

type ChildrenValue<T> = {
  data: T | null;
  working: boolean;
  raw: any | undefined;
};

export interface PublicacionEditValueProps<T> {
  idPublicacion: number;
  children: (value: ChildrenValue<T>) => JSX.Element;
  transformer: (value: any) => T;
}

// TODO: convertir en un componente de business makeEntityByIdTransformer
export function PublicacionEditValue<T>({
  idPublicacion,
  children,
  transformer,
}: PublicacionEditValueProps<T>) {
  const { data, working } = usePublicacionFetchById(idPublicacion);

  const transformed = useMemo<T | null>(() => {
    if (!data || working) {
      return null;
    }
    return transformer(data);
  }, [data, transformer, working]);

  return <>{children({ data: transformed, working, raw: data })}</>;
}
