import React, { useState } from 'react';
import { makeStyles, Typography } from '@material-ui/core';
import { grey, primary } from 'src/theme/colors';
import { useColegioEfectivizadoContext } from '../../context/EfectivizacionProvider';

const useStyles = makeStyles({
  isIncomplete: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    paddingTop: '17px',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    lineHeight: '22px',
    color: grey.dark,
  },
  isIncompleteClose: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    paddingTop: '17px',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    lineHeight: '22px',
  },
  incompleteMessage: {
    borderRadius: 4,
    display: 'flex',
    padding: '10px 20px',
    backgroundColor: primary.warningLight,
    flexFlow: 'column',
    width: '30%',
  },
  incompleteMessageClose: {
    borderRadius: 4,
    display: 'flex',
    backgroundColor: primary.errorMedium,
    flexFlow: 'column',
    color: grey.dark,
    padding: '10px 20px',
  },
  uncollapsed: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  verDetalles: {
    color: '#00A0D6',
  },
});
export interface AlertMessageProps {
  incompletedRows: any;
  isDisabled: boolean;
  isClosed?: boolean;
  working?: boolean;
  showView?: boolean;
  showEfectivizacionAlert?: boolean;
}

export const AlertMessage = ({
  incompletedRows,
  isDisabled,
  isClosed,
  working,
  showView,
  showEfectivizacionAlert = true,
}: AlertMessageProps) => {
  const { establecimientoEfectivizado, waiting } =
    useColegioEfectivizadoContext();
  const classes = useStyles();
  const [showIncompletedRows, setShowIncompletedRows] = useState<any>(false);

  const fechaActual = new Date();
  const anioActual = fechaActual.getFullYear();
  const anioAnterior = anioActual - 1;

  return (
    <>
      {!isDisabled && <div style={{ minHeight: '30px' }}></div>}
      {isDisabled && !!incompletedRows.length && !working && showView && (
        <div className={classes.isIncomplete}>
          <div className={classes.incompleteMessage}>
            <div className={classes.uncollapsed}>
              <div>
                Se encontraron campos incompletos para los siguientes alumnos:
              </div>
              <div
                onClick={() => {
                  setShowIncompletedRows(!showIncompletedRows);
                }}
                className={classes.verDetalles}
              >
                Ver detalles
              </div>
            </div>
            {showIncompletedRows && (
              <ul>
                {incompletedRows.map((e: string) => (
                  <li>{e}</li>
                ))}
              </ul>
            )}
          </div>
        </div>
      )}
      {isClosed && !working && showView && (
        <div className={classes.isIncompleteClose}>
          <Typography className={classes.incompleteMessageClose}>
            Las calificaciones están cerradas.
          </Typography>
        </div>
      )}
      {showEfectivizacionAlert &&
        establecimientoEfectivizado !== null &&
        !establecimientoEfectivizado &&
        !working &&
        !waiting && (
          <div className={classes.isIncompleteClose}>
            <Typography className={classes.incompleteMessageClose}>
              {establecimientoEfectivizado
                ? `Este establecimiento está efectivizado ((${anioActual})`
                : `Este establecimiento aún no está efectivizado (${anioAnterior})`}
            </Typography>
          </div>
        )}
    </>
  );
};

/*

Este establecimiento aún no está efectivizado, no está permitido
              modificar las calificaciones, sólo visualizarlas.

              */
