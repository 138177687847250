import React from 'react';
import { Box, Card, CardContent, CardHeader } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { grey } from 'src/theme/colors';
import { AppTheme } from '../../../../../../../../theme';
import { useDisciplinarComunContext } from '../../../forms/PlanificacionDisciplinarComun/components/context';
import { useGeneralContext } from 'src/context/GeneralContext';

const useStyle = makeStyles<AppTheme>((theme) => ({
  outerCard: {
    backgroundColor: theme.palette.common.neutral,
    '@media print': {
      '&& .MuiPaper-elevation1': {
        boxShadow: 'none',
      },
    },
  },
  cardTitle: {
    color: grey.textPrimary,
    marginBottom: 40,
    borderRadius: 8,
    '&& .MuiTypography-h5 ': {
      fontSize: 18,
      marginBottom: 10,
      borderRadius: 0,
    },
  },
  headerTitle: {
    '&& .MuiCardHeader-title': {
      color: grey.textPrimary,
      fontSize: 24,
      paddingTop: 15,
    },
  },
  cardContent: {
    paddingTop: 0,
    '@media print': {
      '&& .MuiPaper-elevation1': {
        boxShadow: 'none',
      },
    },
  },
  bimestre: {
    '@media print': {
      pageBreakBefore: 'always',
    },
  },
  printPlanning: {
    '&& .MuiPaper-elevation1': {
      boxShadow: 'none',
    },
  },
}));

export interface InnerCard {
  title: string;
  render: JSX.Element;
}

export interface InfoCardProps {
  title: string;
  cards: InnerCard[];
}

export const InfoCard = ({ title, cards }: InfoCardProps) => {
  const {
    generalState: {
      espacioCurricular: {
        planificacion: {
          print: { idPlanificacion: printIdPlanning },
        },
      },
    },
  } = useGeneralContext();

  const { working } = useDisciplinarComunContext();

  // html2canvas no captura bien los estilos de Card
  const Container = printIdPlanning ? Box : Card;

  const classes = useStyle();
  return (
    <Container
      className={`${classes.outerCard} ${
        printIdPlanning ? classes.printPlanning : ''
      }`}
    >
      <CardHeader title={title} className={classes.headerTitle} />
      <CardContent
        className={`${classes.cardContent} ${
          printIdPlanning ? classes.printPlanning : ''
        }`}
      >
        {!working &&
          cards.map(({ title, render }) => (
            <Card
              key={title}
              className={`${classes.cardTitle} ${
                title.includes('bimestre') ? classes.bimestre : ''
              }`}
            >
              <CardHeader title={title} />
              <CardContent>{render}</CardContent>
            </Card>
          ))}
      </CardContent>
    </Container>
  );
};
