import { useMemo } from 'react';

export const useMatriculadosReporte = (data: any) => {
  const excelData = useMemo(() => {
    const columnHeaders = [
      'Apellido y Nombre',
      'Documento',
      'Media jornada',
      'Jornada Completa',
    ];

    const rows = data.map((row: any) => ({
      'Apellido y Nombre': `${row.alumno?.persona?.apellido}, ${row.alumno?.persona?.nombre}`,
      Documento: row?.alumno?.persona?.documento,
      'Establecimiento Anterior':
        row?.seccionOrigen?.localizacion?.establecimiento?.nombre,
      'Sección anterior': row?.seccionOrigen?.nombreSeccion,
    }));

    return {
      columns: columnHeaders,
      data: rows,
    };
  }, [data]);

  return { excelData };
};
