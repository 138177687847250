import { CommonEndpointDAO } from '../CommonEndpointDAO';

type ConfirmacionResponse = {
  link: string;
  message: string;
};
export class ConfirmacionMatriculaGeneracionDAO extends CommonEndpointDAO {
  constructor() {
    super('confirmacion_matricular_pdf');
  }

  async confirm(): Promise<ConfirmacionResponse> {
    return await super.post({});
  }
}
