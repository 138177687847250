import React, { useState } from 'react';
import { CalificacionesSecundarioSwitch } from './components/CalificacionesSecundarioSwitch';
import { CalificacionesSecundario } from 'src/screens/Private/Calificaciones/CalificacionesSecundario';
import { CalificacionesRecuperacion } from 'src/screens/Private/Calificaciones/CalificacionesRecuperacion/components/CalificacionesRecuperacion';
import { CalificacionesSecundarioTable } from './CalificacionesSecundario';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
import { Loading } from 'src/commons';

export const Calificaciones = () => {
  const [periodoSecundario, setPeriodoSecundario] = useState<string>();
  const { establecimientoEfectivizado, waiting } =
    useColegioEfectivizadoContext();

  return waiting ? (
    <Loading />
  ) : !periodoSecundario ? (
    <CalificacionesSecundarioSwitch
      setPeriodoSecundario={setPeriodoSecundario}
    />
  ) : periodoSecundario === '5' ? (
    <CalificacionesRecuperacion />
  ) : periodoSecundario === '3' ? (
    <CalificacionesSecundario periodo={periodoSecundario} fromEC />
  ) : (
    <CalificacionesSecundario periodo={periodoSecundario} fromEC />
  );
};
