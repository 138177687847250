import {
  AnioSelect,
  RepositorioEspaciosCurricularesSelect,
  RepositorioAudienciaItemSelect,
  RepositorioCicloSelect,
  RepositorioTransversalesSelect,
  RepositorioTipoRecursoSelect,
  RepositorioAreasSelect,
} from 'src/app/business';

export type filters = {
  name: string;
  component: any;
  componentProps: any;
};

export type tFiltersByNivel = {
  [key: string]: Array<filters>;
};

export const filtersByNivel: tFiltersByNivel = {
  inicial: [
    {
      name: 'idCiclo',
      component: RepositorioCicloSelect,
      componentProps: { title: 'Ciclo', placeholder: 'Seleccioná ciclo' },
    },
    {
      name: 'idAnio',
      component: AnioSelect,
      componentProps: {
        title: 'Sala',
        placeholder: 'Seleccioná sala',
        filter: { anios: [{ path: 'id_nivel', value: 1 }] },
      },
    },
    {
      name: 'idTipoRecurso',
      component: RepositorioTipoRecursoSelect,
      componentProps: {
        title: 'Tipo de Recurso',
        placeholder: 'Seleccioná tipo de recurso',
      },
    },
  ],
  primario: [
    {
      name: 'idCiclo',
      component: RepositorioCicloSelect,
      componentProps: { title: 'Ciclo', placeholder: 'Seleccioná ciclo' },
    },
    {
      name: 'idAnio',
      component: AnioSelect,
      componentProps: {
        title: 'Grado',
        placeholder: 'Seleccioná Grado',
        filter: { anio: [{ path: 'id_nivel', value: 2 }] },
      },
    },
    {
      name: 'idArea',
      component: RepositorioAreasSelect,
      componentProps: {
        title: 'Área',
        placeholder: 'Seleccioná Área',
        filter: [{ path: 'id_nivel', value: 2 }],
      },
    },
    {
      name: 'idTipoRecurso',
      component: RepositorioTipoRecursoSelect,
      componentProps: {
        title: 'Tipo de Recurso',
        placeholder: 'Seleccioná tipo de recurso',
      },
    },
    {
      name: 'idTransversal',
      component: RepositorioTransversalesSelect,
      componentProps: {
        title: 'Transversal',
        placeholder: 'Seleccioná transversales',
      },
    },
  ],
  secundario: [
    {
      name: 'idRepositorioAudienciaItem',
      component: RepositorioAudienciaItemSelect,
      componentProps: {
        title: 'Audiencia',
        placeholder: 'Seleccioná Audiencia',
      },
    },
    {
      name: 'idCiclo',
      component: RepositorioCicloSelect,
      componentProps: { title: 'Ciclo', placeholder: 'Seleccioná ciclo' },
    },
    {
      name: 'idAnio',
      component: AnioSelect,
      componentProps: {
        title: 'Año',
        placeholder: 'Seleccioná Año',
        filter: { anios: [{ path: 'id_nivel', value: 3 }] },
      },
    },
    {
      name: 'idArea',
      component: RepositorioAreasSelect,
      componentProps: {
        title: 'Area',
        placeholder: 'Seleccioná Area',
        filter: { anios: [{ path: 'id_nivel', value: 3 }] },
      },
    },
    {
      name: 'idEspacioCurricular',
      component: RepositorioEspaciosCurricularesSelect,
      componentProps: {
        title: 'Espacio curricular',
        placeholder: 'Seleccioná espacio curricular',
      },
    },
    {
      name: 'idTipoRecurso',
      component: RepositorioTipoRecursoSelect,
      componentProps: {
        title: 'Tipo de Recurso',
        placeholder: 'Seleccioná tipo de recurso',
      },
    },
  ],
};

export interface NivelesMap {
  1: string;
  2: string;
  3: string;
}

export const nivelesMap: NivelesMap = {
  1: 'inicial',
  2: 'primario',
  3: 'secundario',
};
