import React from 'react';
import { Grid, Button, makeStyles } from '@material-ui/core';
import customFormDialog from 'src/commons/services/customFormDialog';
import { AddUserForm } from './AddUserForm';

interface AddUserModalProps {
  reFetch: () => void;
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    marginTop: theme.spacing(-4),
    marginRight: theme.spacing(4),
    position: 'relative',
  },
  modalContainer: {
    padding: theme.spacing(2),
  },
}));

const handleCloseModal = async () => {
  await customFormDialog.handleCancel();
};

export const AddUserModal = ({ reFetch }: AddUserModalProps) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.modalContainer}>
      <Grid container>
        <Grid item container justify="flex-end">
          <Button className={classes.closeButton} onClick={handleCloseModal}>
            X
          </Button>
        </Grid>
      </Grid>
      <AddUserForm reFetch={reFetch} />
    </Grid>
  );
};
