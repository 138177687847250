import { LibbyFetchDAO } from './LibbyFetchDAO';

export class PersonalEducativoCargoDAO extends LibbyFetchDAO {
  constructor() {
    super('personal_educativo_cargo', 'idPersonalEducativoCargo');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
