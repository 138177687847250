import React from 'react';
import { Content } from '../../../../../../../commons/components/TabBar';
import { Bloque } from './tabs/Bloque';
import { PropositosDeEnsenanza } from './tabs/PropositosEnsenanza';
import { ObjetivosAprendizaje } from './tabs/ObjetivosAprendizaje';
import { IndicadoresEvaluacion } from './tabs/IndicadoresEvaluacion';
import { PrimerBimestre } from './tabs/PrimerBimestre';
import { SegundoBimestre } from './tabs/SegundoBimestre';
import { TercerBimestre } from './tabs/TercerBimestre';
import { CuartoBimestre } from './tabs/CuartoBimestre';
import { Bibliografia } from './tabs/Bibliografia';

export const tabs: Content[] = [
  {
    title: 'Bloque',
    component: <Bloque />,
  },
  {
    title: 'Propósitos de enseñanza',
    component: <PropositosDeEnsenanza />,
  },
  {
    title: 'Objetivos de Aprendizaje',
    component: <ObjetivosAprendizaje />,
  },
  {
    title: 'Indicadores de evaluación',
    component: <IndicadoresEvaluacion />,
  },
  {
    title: 'Primer Bimestre',
    component: <PrimerBimestre />,
  },
  {
    title: 'Segundo Bimestre',
    component: <SegundoBimestre />,
  },
  {
    title: 'Tercer Bimestre',
    component: <TercerBimestre />,
  },
  {
    title: 'Cuarto Bimestre',
    component: <CuartoBimestre />,
  },
  {
    title: 'Bibliografía',
    component: <Bibliografia />,
  },
];
