import { Typography, Button } from '@material-ui/core';
import React from 'react';
import { useMemo } from 'react';
import { AlumnoMovimiento, Pip } from 'src/app/models';
import { InfoTableColumn, Loading } from 'src/commons';

export const useModalInformesPipPendientesColumns = (
  loading: any,
  isAuthorizedToOpen: boolean,
  handleActionOverPip: (
    idPIP: string | undefined,
    idAlumno: string,
    accion: 'close' | 'open',
  ) => void,
) => {
  return useMemo<InfoTableColumn<AlumnoMovimiento & { pip?: Pip }>[]>(() => {
    return [
      {
        id: 'alumno',
        label: 'Alumno',
        width: '25%',
        orderById: 'alumno',
        hideSortIcon: true,
        render: (value, row) => {
          const { apellido, nombre } = row?.alumno?.persona;
          return <Typography>{`${apellido}, ${nombre}`}</Typography>;
        },
      },
      {
        id: 'estadoInformePip',
        label: 'Estado Informe PIP',
        width: '15%',
        hideSortIcon: true,
        render: (value, row) => {
          const { pip } = row;
          if (pip?.abierto === true) {
            return <p>En Proceso</p>;
          } else if (pip?.abierto === false) {
            return <p>Cerrado</p>;
          } else if (pip === undefined) {
            return <p>No Iniciado</p>;
          }
        },
      },
      {
        id: 'acciones',
        label: 'Acciones',
        width: '15%',
        hideSortIcon: true,
        render: (value, row) => {
          const { pip } = row;
          const isPosibleToClose = () => {
            let isReadyToClose = false;
            if (pip !== undefined) {
              const preguntasRespondidasPip = Object.keys(pip?.data);
              let emptyValueFound = false;
              let necesitaAdjunto = false;

              if (pip && pip.data) {
                for (const key in pip.data) {
                  if (
                    key !== 'adjuntoInforme' &&
                    key !== 'adjuntoDibujo' &&
                    pip.data[key] === ''
                  ) {
                    emptyValueFound = true;
                    break;
                  }
                }
                if (
                  pip.data['12'] === 'Sí' &&
                  pip.data['adjuntoInforme'] === ''
                ) {
                  necesitaAdjunto = true;
                }
              }
              if (
                preguntasRespondidasPip.length === 15 &&
                !emptyValueFound &&
                !necesitaAdjunto
              ) {
                isReadyToClose = true;
              }
            }
            return isReadyToClose;
          };
          if (pip?.abierto === true) {
            return loading.idAlumno === row?.alumno?.idAlumno &&
              loading.loading === true ? (
              <Loading />
            ) : (
              <Button
                onClick={() =>
                  handleActionOverPip(
                    row?.pip?.idPIP,
                    row?.alumno.idAlumno,
                    'close',
                  )
                }
                variant="outlined"
                style={{
                  textTransform: 'capitalize',
                  width: '100px',
                }}
                disabled={
                  !(pip?.abierto === true && isPosibleToClose()) ||
                  !isAuthorizedToOpen
                }
              >
                Cerrar PIP
              </Button>
            );
          } else if (pip?.abierto === false) {
            return loading.idAlumno === row?.alumno?.idAlumno &&
              loading.loading === true ? (
              <Loading />
            ) : (
              <Button
                onClick={() =>
                  handleActionOverPip(
                    row?.pip?.idPIP,
                    row?.alumno.idAlumno,
                    'open',
                  )
                }
                variant="outlined"
                style={{
                  textTransform: 'capitalize',
                  width: '100px',
                }}
                disabled={!isAuthorizedToOpen}
              >
                Abrir PIP
              </Button>
            );
          } else if (pip === undefined) {
            return (
              <Typography
                style={{
                  textTransform: 'capitalize',
                  width: '100px',
                  color: '#bbb',
                }}
              >
                ----------------------
              </Typography>
            );
          }
        },
      },
    ];
  }, [
    handleActionOverPip,
    isAuthorizedToOpen,
    loading.idAlumno,
    loading.loading,
  ]);
};
