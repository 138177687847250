import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class MotivosExceptuadoDAO extends LibbyFetchDAO {
  constructor() {
    super('motivo_exceptuado', 'idMotivoExceptuado');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
