import React, { useState, useCallback, useMemo } from 'react';
import { useForm, useField } from 'react-final-form-hooks';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Grid } from '@material-ui/core';
import {
  AnyObject,
  SeccionModalCycleOpelTypes,
  SeccionModalCycleOpelFormValuesTypes,
  useLibbyFetch,
  useLibbyCall,
} from 'src/commons';
import { useCicloAdultosLibbyCall } from 'src/app/business';
import confirmDialog from 'src/commons/services/confirmDialog';
import { Footer, MainInput, SimpleSelect } from 'src/commons/components';
import customFormDialog from 'src/commons/services/customFormDialog';
import { grey } from 'src/theme/colors';
import { regexValidator } from 'src/utils/regex';

const customStyles = {
  customStyleTitle: { color: grey.medium, position: 'relative', top: '-12px' },
  containerGrid: { paddingRight: 20, paddingLeft: 20, paddingBottom: 17 },
  inputGrid: { paddingTop: 25 },
  footer: { marginTop: 20 },
};

const ID_ESTABLECIMIENTO = 20136200;
const ID_UNIDAD_EDUCATIVA = 10;
const SeccionModalSchoolAdultsRaw = ({
  libby,
  reFetch,
  level,
  cycleLectiveAdults,
  plan_study,
  turn,
  dividing = '',
  capacity = '',
  idSeccion,
  localizacionId,
  edit,
}: SeccionModalCycleOpelTypes) => {
  const [disabled, setDisabled] = useState(false);
  const formValues = {
    level,
    cycleLectiveAdults,
    plan_study,
    turn,
    dividing,
    capacity,
  };

  const validate = (values: SeccionModalCycleOpelFormValuesTypes) => {
    const errors = {};
    if (
      !values.level ||
      !values.turn ||
      !values.plan_study ||
      !values.cycleLectiveAdults ||
      !values.dividing ||
      !values.capacity
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    return errors;
  };

  const paramsFetchPlanEstudioNivel = useMemo(
    () => ({
      daoName: 'localizacion_plan_estudio_nivel',
      filter: {
        // 0: [{ path: 'planEstudioNivel.modalidadNivel.nivel', value: roleInfo?.selectedRole.nivel }],
        1: [{ path: 'localizacion', value: localizacionId }],
      },
    }),
    [localizacionId],
  );

  const { data: planEstudioNivel } = useLibbyFetch(
    libby,
    paramsFetchPlanEstudioNivel,
  );
  const { data: cicloLectivoAdultos } = useLibbyFetch(libby, {
    daoName: 'ciclo_lectivo_adultos',
  });
  const { data: cicloAdultos } = useCicloAdultosLibbyCall({
    methodName: 'getByLevelId',
    params: [level],
  });

  const { data: nivel } = useLibbyCall(libby, {
    daoName: 'nivel',
    methodName: 'getLevelsBySchool',
    params: [level],
  });
  const { data: turno } = useLibbyFetch(libby, { daoName: 'turno' });

  const descriptionNameSection = (key: number, arrSelect: AnyObject) => {
    const filterObjSelect = arrSelect.find(
      (e: AnyObject) =>
        e.idCicloAdultos === key ||
        e.idTurno === key ||
        e.idCicloLectivoAdultos === key,
    );
    const { descripcion, descripcionTurno, descripcionCicloLectivoAdulto } =
      filterObjSelect || {};
    return (
      descripcion || descripcionTurno || descripcionCicloLectivoAdulto || ''
    );
  };

  const onSubmit = useCallback(
    async (params: SeccionModalCycleOpelFormValuesTypes) => {
      try {
        const dividingNumber = Number(params.dividing);
        const iSeccionObj = {
          nombreSeccion: `${descriptionNameSection(
            params.cycleLectiveAdults,
            cicloLectivoAdultos,
          )}  ${descriptionNameSection(
            params.turn,
            turno,
          )} ${descriptionNameSection(dividingNumber, cicloAdultos)}`,
          capacidadRecomendada: 0,
          capacidadMaxima: Number(params.capacity),
          localizacion: localizacionId,
          nivel: params.level,
          planEstudioNivel: params.plan_study,
          cicloLectivo: null,
          // unidadEducativa: ID_UNIDAD_EDUCATIVA,
          turno: params.turn,
          cicloLectivoAdultos: Number(params.cycleLectiveAdults),
          cicloAdultos: params.dividing, // Ciclo/OPEL
        };
        const libbyObjectSeccion = edit
          ? { ...iSeccionObj, idSeccion }
          : iSeccionObj;
        await libby.seccion.save(libbyObjectSeccion);
        reFetch();
        customFormDialog.handleCancel();
      } catch (e) {
        await confirmDialog.show({
          title: 'Error: Ciclo/Opel repetido',
          content: `Se ha encontrado un Ciclo/Opel similar en nuestra base de datos, por favor intente nuevamente con otros datos`,
          confirmText: 'Entendido',
        });
        customFormDialog.handleCancel();
      }
    },
    [
      libby,
      edit,
      idSeccion,
      cicloAdultos,
      turno,
      cicloLectivoAdultos,
      reFetch,
      localizacionId,
    ],
  );

  const { form, handleSubmit, submitting, pristine } = useForm({
    initialValues: formValues,
    onSubmit,
    validate,
  });

  const levelForm = useField('level', form);
  const planStudyForm = useField('plan_study', form);
  const cycleForm = useField('cycleLectiveAdults', form);
  const turnForm = useField('turn', form);
  const dividingForm = useField('dividing', form);
  const capacityForm = useField('capacity', form);

  const validateCapacity = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    const valueNumber = regexValidator.onlyNumber.test(target.value);
    if (valueNumber) {
      capacityForm.input.onChange(target.value);
    }
  };

  const buttonConfig = [
    {
      title: 'Cancelar',
      handleOnClick: () => customFormDialog.handleCancel(),
      type: 'secondary',
      size: 'small',
    },
    {
      title: edit ? 'Guardar' : 'Crear',
      handleOnClick: () => null,
      size: 'small',
      typeButton: 'submit',
      disabled: disabled || submitting || pristine,
    },
  ];

  const flatten = () =>
    planEstudioNivel.map((data: AnyObject) => ({
      ...data,
      ...data.planEstudioNivel,
      ...data.planEstudioNivel.planEstudio,
    }));

  const cicloAdultosSorted = cicloAdultos?.sort((a: any, b: any) => {
    if (a.descripcion < b.descripcion) {
      return -1;
    }
    if (a.descripcion > b.descripcion) {
      return 1;
    }
    return 0;
  });

  const arrSelect = [
    [
      {
        title: 'Nivel',
        placeholder: 'Seleccioná Nivel',
        labelKey: 'descripcionNivel',
        valueKey: 'idNivel',
        content: nivel,
        name: levelForm.input.name,
        handleChange: levelForm.input.onChange,
        value: levelForm.input.value,
      },
      {
        title: 'Plan de Estudios',
        placeholder: 'Seleccioná Plan',
        labelKey: 'descripcionPlanEstudio',
        valueKey: 'idPlanEstudioNivel',
        content: flatten(),
        handleChange: planStudyForm.input.onChange,
        value: planStudyForm.input.value,
        name: planStudyForm.input.name,
      },
    ],
    [
      {
        title: 'Tipo de Ciclo Lectivo',
        placeholder: 'Seleccioná Tipo de Ciclo Lectivo',
        labelKey: 'descripcionCicloLectivoAdulto',
        valueKey: 'idCicloLectivoAdultos',
        content: cicloLectivoAdultos,
        handleChange: cycleForm.input.onChange,
        value: cycleForm.input.value,
        name: cycleForm.input.name,
      },
      {
        title: 'Turno',
        placeholder: 'Seleccioná Turno',
        labelKey: 'descripcionTurno',
        valueKey: 'idTurno',
        content: turno,
        name: turnForm.input.name,
        handleChange: turnForm.input.onChange,
        value: turnForm.input.value,
      },
    ],
    [
      {
        title: 'Ciclo/OPEL',
        placeholder: 'Ingresá Ciclo/OPEL',
        labelKey: 'descripcion',
        valueKey: 'idCicloAdultos',
        name: dividingForm.input.name,
        content: cicloAdultosSorted,
        handleChange: dividingForm.input.onChange,
        value: dividingForm.input.value,
      },
      {
        input: true,
        label: 'Capacidad',
        placeholder: 'Definír Capacidad',
        name: capacityForm.input.name,
        inputProps: { min: 0 },
        handleChange: validateCapacity,
        value: capacityForm.input.value,
      },
    ],
  ];

  return (
    <>
      <form onSubmit={handleSubmit}>
        {arrSelect.map((arr: AnyObject) => (
          <Grid container spacing={3} style={customStyles.containerGrid}>
            {arr.map((e: any) =>
              e.input ? (
                <Grid item xs={6} style={customStyles.inputGrid}>
                  <MainInput
                    {...e}
                    handleChange={e.handleChange}
                    value={e.value}
                    fullWidth
                    customStyleLabel={customStyles.customStyleTitle}
                    requiredField={!e.value}
                  />
                </Grid>
              ) : (
                <Grid item xs={6}>
                  <SimpleSelect
                    {...e}
                    handleChange={e.handleChange}
                    content={e.content}
                    value={e.value}
                    requiredField={!e.value}
                  />
                </Grid>
              ),
            )}
          </Grid>
        ))}
        <Footer
          buttonConfig={buttonConfig}
          spacing={1}
          customStyle={customStyles.footer}
        />
      </form>
    </>
  );
};

export const SeccionModalSchoolAdults = DatabaseConnector(
  SeccionModalSchoolAdultsRaw,
)(
  'nivel',
  'ciclo_lectivo_adultos',
  'plan_estudio_nivel',
  'turno',
  'seccion',
  'localizacion_plan_estudio_nivel',
);
