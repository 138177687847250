import { LibbyFetchDAO } from './LibbyFetchDAO';

export class DiaDAO extends LibbyFetchDAO {
  constructor() {
    super('dia', 'idDia');
  }

  getAll() {
    return this.query().limit(0, 10).run();
  }
}
