import { useEffect } from 'react';
import { InformesHistory } from '../../../../app/models';

/**
 * Setea al nombre del documento cuando este se va a guardar
 *  */
export const useDocumentTitlePrint = (informeHistory?: InformesHistory) => {
  useEffect(() => {
    // set document title before printing in order to save pdf with name
    window.addEventListener('beforeprint', () => {
      document.title = `${informeHistory?.nombre} - ${informeHistory?.alumno?.persona?.apellido},  ${informeHistory?.alumno?.persona?.nombre} - ${informeHistory?.alumno?.persona?.documento}`;
    });

    // set default title after printing
    window.addEventListener('afterprint', () => {
      document.title = 'BA | miEscuela';
    });
  }, [informeHistory]);
};
