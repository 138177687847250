import React, { useState } from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { MainInput, MainButton } from 'src/commons/components';
import GoogleButton from 'react-google-button';
import {
  ContenedorInicioDeSesion,
  DescripcionText,
  FormContainer,
  FormStyle,
  GoogleButtonStyle,
  SpanText,
  TitleStyleCustom,
} from './LoginForm.styles';

interface LoginFormProps {
  handleLogin: (user: string, password: string) => void;
  onGoogleSignInClick: () => void;
}

export const LoginForm = ({
  handleLogin,
  onGoogleSignInClick,
}: LoginFormProps) => {
  const [user, setUser] = useState('');
  const [password, setPassword] = useState('');
  const [hidden, setHidden] = useState(false);

  const onSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    handleLogin(user, password);
  };

  const handleClick = () => {
    setHidden(!hidden);
  };

  return (
    <ContenedorInicioDeSesion hidden={hidden}>
      <TitleStyleCustom>
        {' '}
        {hidden && <ArrowBackIosIcon cursor="pointer" onClick={handleClick} />}
        Iniciar sesión
      </TitleStyleCustom>
      {!hidden && (
        <>
          <GoogleButtonStyle>
            <GoogleButton
              label=" Iniciá sesión"
              onClick={onGoogleSignInClick}
            />
          </GoogleButtonStyle>
          <DescripcionText id="form-portal" onClick={handleClick}>
            Si sos integrante del equipo de soporte de miEscuela, ingresá
            <SpanText>acá</SpanText>.
          </DescripcionText>
        </>
      )}
      {hidden && (
        <FormStyle>
          <FormContainer hidden={hidden} onSubmit={onSubmit}>
            <MainInput
              fullWidth
              customStyle={{ marginTop: 25 }}
              value={user}
              handleChange={(e) => setUser(e.target.value)}
              label="Usuario"
              placeholder="Ingresá tu usuario"
              name="usuario"
              customStyleLabel={{
                fontWeight: 600,
                marginBottom: '-5%',
                marginTop: '10%',
              }}
            />
            <MainInput
              fullWidth
              customStyle={{ marginTop: 25 }}
              label="Contraseña"
              type="password"
              value={password}
              name="password"
              handleChange={(e) => setPassword(e.target.value)}
              placeholder="Ingresá tu contraseña"
              customStyleLabel={{
                fontWeight: 600,
                marginBottom: '-5%',
                marginTop: '10%',
              }}
            />
            <FormControlLabel
              control={<Checkbox color="primary" aria-label="recuerdame" />}
              label="Recuerdame"
            />
            <MainButton
              customStyle={{ marginTop: 5, width: 170 }}
              title="Ingresar"
              type="primary"
              size="medium"
              typeButton="submit"
            />
          </FormContainer>
        </FormStyle>
      )}
    </ContenedorInicioDeSesion>
  );
};
