import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ProyeccionIELDAO extends LibbyFetchDAO {
  constructor() {
    super('proyeccioniel', 'idProyeccionIEL');
  }

  getProyeccionIEL() {
    return this.query().limit(1).run();
  }
}
