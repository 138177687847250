import React from 'react';
import { Content } from 'src/commons';
import {
  Documentation,
  Health,
  PersonalInformation,
  Residential,
  SituacionLegal,
  HistorialAcademico,
  Responsables,
} from '.';

export const tabs: Content[] = [
  {
    id: 'PersonalInformation',
    title: 'Datos Personales',
    component: <PersonalInformation />,
  },
  {
    id: 'Residential',
    title: 'Residencia',
    component: <Residential />,
  },
  {
    id: 'Health',
    title: 'Salud',
    component: <Health />,
  },
  {
    id: 'SituacionLegal',
    title: 'Situación Legal',
    component: <SituacionLegal />,
  },
  {
    id: 'Documentation',
    title: 'Documentación',
    component: <Documentation />,
  },
  {
    id: 'HistorialAcademico',
    title: 'Historial Académico',
    component: <HistorialAcademico />,
  },
  /* {
    id: 'Responsables',
    title: 'Responsables',
    component: <Responsables />,
  }, */
];
