import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { AspectosGeneralesCustom } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'aspectos_generales_custom';

const defaultOptions: PartialBusinessEntityOptions<any> = {};

const optionsToUse = !businessOptions.AspectosGeneralesCustom
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.AspectosGeneralesCustom,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.AspectosGeneralesCustom.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<AspectosGeneralesCustom>(DAO_NAME, optionsToUse);

// hooks
export const useAspectosGeneralesCustomFetchById = fetchById;
export const useAspectosGeneralesCustomLibbyCall = libbyCall;
export const useAspectosGeneralesCustomDAO = libbyDAO;
export const useAspectosGeneralesCustomLibbyFetch = libbyFetch;

// components/hoc
export const AspectosGeneralesCustomAutocomplete = entityAutocomplete;
export const AspectosGeneralesCustomCall = renderPropCall;
export const AspectosGeneralesCustomById = renderPropFetchById;
export const AspectosGeneralesCustomSelect = simpleSelect;
export const AspectosGeneralesCustomAccordionSelector = accordionSelector;
export const AspectosGeneralesCustomSelectedChips = selectedChips;

// context
// ByIdContext
export const useAspectosGeneralesCustomByIdContext = useByIdContext;
export const AspectosGeneralesCustomByIdProvider = ByIdProvider;
export const AspectosGeneralesCustomByIdContext = ByIdContext;
// GeneralContext
export const useAspectosGeneralesCustomContext = useGeneralContext;
export const AspectosGeneralesCustomProvider = GeneralProvider;
export const AspectosGeneralesCustomContext = GeneralContext;
