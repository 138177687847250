import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { uniqBy } from 'lodash';
import {
  useAccionesLibbyFetch,
  useGruposInscripcionesLibbyFetch,
  useOfertasGruposLibbyFetch,
  useOfertasLibbyFetch,
} from 'src/app/business';
import {
  useDevolucionOrganizacionLibbyFetch,
  usePresentismoAsistenciaDAO,
  usePresentismoAsistenciaLibbyFetch,
} from 'src/app/business/acap';
import { Footer, InfoTable, Loading, ROL } from 'src/commons';
import useGenerarExcel from 'src/commons/hooks/useGenerarExcel';
import customFormDialog from 'src/commons/services/customFormDialog';
import { FiltersHeader } from './components/FiltersHeader';
import { ModalAlumno } from './components/ModalAlumno';
import { ModalConfirmarSalida } from './components/ModalConfirmarSalida';
import { useGetExcelData } from './hooks/useGetExcelData';
import { usePresentismoAcapColumns } from './hooks/usePresentismoAcapColumns';
import { useRolesContext } from 'src/context/RolesContext';
import {
  GrupoJornada,
  GruposInscripciones,
  OfertaGrupo,
  Organizacion,
  Referente,
} from 'src/app/models';
import { getFechasByDays } from '../AcapEstudiante/utils/helperFechas';
import { GruposInscripcionAcumulate } from '../AcapEscuela/types';
import { Grid, TablePagination, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useEstadoPresenteLibbyFetch } from 'src/app/business/presentismo_v3/EstadoPresente';

interface PresentismoProps {
  isInsideSchool: boolean;
  row?: OfertaGrupo | null;
  ofertaReferenteAccion?: OfertaGrupo[];
  goToDevoluciones?: () => void;
  setTomandoPresentismo?: React.Dispatch<any>;
  goToTab?: (row: GruposInscripcionAcumulate) => void;
}

const useStyles = makeStyles({
  textCenter: {
    textAlign: 'center',
  },
});
export const Presentismo = ({
  isInsideSchool,
  row,
  setTomandoPresentismo,
  ofertaReferenteAccion,
  goToDevoluciones = () => {},
}: PresentismoProps) => {
  const [formValues, setFormValues] = useState<any>();
  const [filterAlumnos, setFilterAlumnos] = useState<any>({});
  const [rows, setRows] = useState<Acap.Referente.RowOfertaGrupo[]>([]);
  const [presentismoFilter, setPresentismoFilter] = useState<any>();
  const [presenteFilter, setPresenteFilter] = useState<any>();
  const [initialState, setInitialState] = useState<any>([]);
  const [differentRowsState, setDifferentRows] = useState<any>([]);
  const [isSaving, setIsSaving] = useState(false);
  const [organizacionesEscuela, setOrganizacionesEscuela] = useState<
    Organizacion[]
  >([]);
  const [organizacionSeleccionada, setOrganizacionSeleccioanda] = useState<
    Organizacion | undefined
  >();
  const [setTimeoutGuardado, setSetTimeoutGuardado] = useState<any>();
  const [isReferenteAccion, setIsReferenteAccion] = useState<boolean>(false);
  const [isReferenteOrganizacion, setIsReferenteOrganizacion] =
    useState<boolean>(false);
  const classes = useStyles();
  const [showTable, setShowTable] = useState<boolean>(true);
  const [showNoResults, setShowNoResults] = useState<boolean>(false);

  const { enqueueSnackbar } = useSnackbar();
  const [direction, setDirection] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState('nombre');
  const generateExcel = useGenerarExcel();
  const presentismoAsistenciaDAO = usePresentismoAsistenciaDAO();
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);

  const history = useHistory();

  useEffect(() => {
    if (isInsideSchool) {
      const organizacionSelect = organizacionesEscuela?.find(
        (org) => org?.idOrganizacion === formValues?.organizacion,
      );

      setOrganizacionSeleccioanda(organizacionSelect);
    } else {
      setOrganizacionSeleccioanda(
        row?.ofertaTurno?.oferta?.accion?.organizacion,
      );
    }
  }, [
    formValues?.organizacion,
    isInsideSchool,
    organizacionesEscuela,
    row?.ofertaTurno?.oferta?.accion?.organizacion,
  ]);

  const data = useGetExcelData(
    rows,
    `Presentismos ACAP, ${
      organizacionSeleccionada?.nombre ? organizacionSeleccionada?.nombre : ''
    }, ${moment(formValues?.fecha)?.format('YYYY-MM-DD')}, ${
      rows.length > 0
        ? rows[0]?.ofertaGrupo?.ofertaTurno?.turno?.descripcionTurno
        : ''
    }, ${formValues?.ofertaGrupo}`,
    formValues,
    organizacionSeleccionada,
  );

  const { data: gruposInscripciones = [], working: workingInscriptosTotales } =
    useGruposInscripcionesLibbyFetch({
      limit: 5000,
      enabled: isInsideSchool,
      aspect: 'estudiante-list',
    });

  useEffect(() => {
    const uniqueArray: Organizacion[] = [];

    gruposInscripciones.forEach((grupoInscripcion) => {
      const organizacion =
        grupoInscripcion.ofertaGrupo.ofertaTurno.oferta.accion.organizacion;

      const alreadyInArray = uniqueArray?.some(
        (unique) => unique.idOrganizacion === organizacion.idOrganizacion,
      );

      if (!alreadyInArray) {
        uniqueArray.push(organizacion);
      }
    });

    setOrganizacionesEscuela(uniqueArray);
  }, [gruposInscripciones]);

  const filterAccion = useMemo(
    () => ({
      acciones: [
        {
          path: 'idAccion',
          value: isInsideSchool
            ? gruposInscripciones.map(
                (grupo) =>
                  grupo?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.idAccion,
              )
            : row?.ofertaTurno.oferta.accion.idAccion,
          method: isInsideSchool ? 'in' : 'equals',
        },
      ],
    }),
    [
      gruposInscripciones,
      isInsideSchool,
      row?.ofertaTurno.oferta.accion.idAccion,
    ],
  );

  const { data: acciones = [], working: workingAcciones } =
    useAccionesLibbyFetch({
      limit: 500,
      aspect: 'referenteOrganizacion',
      filter: filterAccion,
      enabled: Boolean(organizacionSeleccionada),
    });

  const arrayFilter = useMemo(() => {
    let array = [];
    array = acciones?.map((accion: any) => {
      return {
        path: 'accion.idAccion',
        value: accion?.idAccion,
      };
    });

    return array;
  }, [acciones]);

  const filterOferta = useMemo(
    () => ({
      ofertas: arrayFilter,
    }),
    [arrayFilter],
  );

  const { data: ofertas = [], working: workingOfertas } = useOfertasLibbyFetch({
    limit: 500,
    filter: filterOferta,
    enabled: arrayFilter?.length > 0,
    aspect: 'ofertas_test',
  });

  const ofertasArrayFilter = useMemo(() => {
    let array = [];

    array = ofertas?.map((oferta: any) => {
      return {
        path: 'ofertaTurno.oferta.idOferta',
        value: oferta?.idOferta,
      };
    });
    return array;
  }, [ofertas]);

  const filterOfertasGruposByProperties = useMemo(
    () => ({
      ...(formValues?.sede !== '' &&
        formValues?.sede !== undefined && {
          sede: [
            {
              path: 'jornadas.sede.idSede',
              value: formValues?.sede,
            },
          ],
        }),
      ...(formValues?.actividad !== '' && formValues?.actividad !== undefined
        ? {
            actividad: [
              {
                path: 'ofertaTurno.oferta.accion.idAccion',
                value: formValues?.actividad,
              },
            ],
          }
        : {
            actividad: ofertasArrayFilter,
          }),
      ...(formValues?.referenteActividad !== '' &&
        formValues?.referenteActividad !== undefined && {
          referente: [
            {
              path: 'referente.idReferente',
              value: formValues?.referenteActividad,
            },
          ],
        }),
      ...(formValues?.turno !== '' &&
        formValues?.turno !== undefined && {
          turno: [
            {
              path: 'ofertaTurno.turno.idTurno',
              value: formValues?.turno,
            },
          ],
        }),
    }),
    [
      formValues?.actividad,
      formValues?.referenteActividad,
      formValues?.sede,
      formValues?.turno,
      ofertasArrayFilter,
    ],
  );

  const { data: ofertasGrupos = [], working: workingOfertasGrupos } =
    useOfertasGruposLibbyFetch({
      filter: filterOfertasGruposByProperties,
      enabled: ofertas.length > 0,
      aspect: 'default',
      limit: 1000,
    });

  const referentes = useMemo(() => {
    let arrayReferentes: Referente[] = [];
    ofertasGrupos?.forEach((ofertaGrupo: OfertaGrupo) => {
      arrayReferentes.push(ofertaGrupo?.referente);
    });
    const filteredByNameArr = uniqBy(arrayReferentes, 'email');
    if (isReferenteAccion) {
      return filteredByNameArr.filter((ref) => {
        return ref?.idReferente === row?.referente?.idReferente;
      });
    }
    return isInsideSchool
      ? filteredByNameArr.filter((referente) =>
          gruposInscripciones.some(
            (inscripto: GruposInscripciones) =>
              inscripto?.ofertaGrupo?.referente?.idReferente ===
              referente?.idReferente,
          ),
        )
      : filteredByNameArr;
  }, [
    ofertasGrupos,
    gruposInscripciones,
    isInsideSchool,
    isReferenteAccion,
    row,
  ]);

  const setFilters = useCallback(() => {
    if (referentes?.length === 0 && !workingOfertasGrupos && !isInsideSchool) {
      setShowNoResults(true);
      return;
    } else {
      setShowNoResults(false);
    }
    setPresenteFilter({
      acciones: [
        {
          path: 'ofertaGrupo',
          value: formValues?.ofertaGrupo,
        },
      ],
      fecha: [
        {
          path: 'fecha',
          value: moment(formValues?.fecha)?.format('YYYY-MM-DD'),
          method: 'includes',
        },
      ],
    });
    setPresentismoFilter(formValues?.ofertaGrupo);
    setFilterAlumnos({
      ofertaGrupo: [
        {
          path: 'ofertaGrupo.idOfertaGrupo',
          value: formValues?.ofertaGrupo,
        },
      ],
    });
  }, [
    formValues?.ofertaGrupo,
    formValues?.fecha,
    referentes,
    workingOfertasGrupos,
    isInsideSchool,
  ]);

  const { data: alumnos = [], working: gruposInscripcionesWorking } =
    useGruposInscripcionesLibbyFetch({
      limit: 1000,
      filter: filterAlumnos,
      enabled:
        Boolean(filterAlumnos.ofertaGrupo) &&
        Boolean(filterAlumnos?.ofertaGrupo[0]?.value),
      aspect: 'estudiante-list',
    });
  const devOrgaFilter = useMemo(
    () => ({
      grupos: [
        {
          path: 'grupoInscripcion',
          value: alumnos.map((alumno) => alumno.idGrupoInscripcion),
          method: 'in',
        },
      ],
    }),
    [alumnos],
  );

  const { data: devoluciones = [], working: devolucionesWorking } =
    useDevolucionOrganizacionLibbyFetch({
      filter: devOrgaFilter,
      aspect: 'basic',
      enabled: alumnos.length > 0,
    });
  useEffect(() => {
    if (
      Object.keys(filterAlumnos)?.length === 0 &&
      alumnos.length === 0 &&
      formValues?.ofertaGrupo &&
      !presentismoFilter &&
      !presenteFilter
    ) {
      setFilters();
    }
  }, [
    formValues?.ofertaGrupo,
    filterAlumnos,
    alumnos,
    setFilters,
    presentismoFilter,
    presenteFilter,
  ]);

  const filterEstadoPresente = useMemo(
    () => ({
      acciones: [
        {
          path: 'idEstadoPresente',
          value: 1,
        },
        {
          path: 'idEstadoPresente',
          value: 2,
        },
        {
          path: 'idEstadoPresente',
          value: 3,
        },
        {
          path: 'idEstadoPresente',
          value: 6,
        },
        {
          path: 'idEstadoPresente',
          value: 7,
        },
      ],
    }),
    [],
  );

  const { data: estadosPresente = [], working: estadoPresenteWorking } =
    useEstadoPresenteLibbyFetch({
      filter: filterEstadoPresente,
    });

  const {
    data: presentismos = [],
    working: presentismoWorking,
    reFetch: reFetchPresentismo,
  } = usePresentismoAsistenciaLibbyFetch({
    limit: 500,
    filter: presenteFilter,
    enabled: presentismoFilter !== undefined && presentismoFilter !== '',
    checkDuplication: false,
    checkDuplicationDeep: false,
    aspect: 'default',
  });

  useEffect(() => {
    const alumnosRows: any = [];

    alumnos.forEach((alumno) => {
      const alumnoHasPresentismo: any = presentismos?.filter(
        (presente: any) =>
          Number(presente?.alumno?.idAlumno) ===
          Number(alumno?.alumnoMovimiento?.alumno?.idAlumno),
      );
      if (alumnoHasPresentismo?.length > 0) {
        alumnosRows.push({
          ...alumno,
          presentismo:
            alumnoHasPresentismo[0]?.estadoPresente?.idEstadoPresente,
          idPresente: alumnoHasPresentismo[0]?.idPresente,
        });
      } else {
        alumnosRows.push(alumno);
      }
    });

    setRows(alumnosRows);
    setInitialState(alumnosRows);
  }, [alumnos, presentismos]);

  const {
    selectedRole: { rol },
  } = useRolesContext();

  useEffect(() => {
    setFormValues((prevState: any) => {
      let encuentros: string[] = [];
      if (isInsideSchool) {
        ofertasGrupos.forEach((grupo: OfertaGrupo) => {
          const tempEnc = getFechasByDays(
            moment(grupo?.ofertaTurno?.oferta?.fechaInicio).format(
              'YYYY-MM-DD',
            ),
            moment(grupo?.ofertaTurno?.oferta?.fechaFin).format('YYYY-MM-DD'),
            grupo?.jornadas?.map((jornada: GrupoJornada) => jornada.cupo),
          );
          encuentros = encuentros.concat(tempEnc);
        });
      } else {
        const enc = getFechasByDays(
          moment(row?.ofertaTurno?.oferta?.fechaInicio).format('YYYY-MM-DD'),
          moment(row?.ofertaTurno?.oferta?.fechaFin).format('YYYY-MM-DD'),
          row?.jornadas?.map((jornada: GrupoJornada) => jornada.cupo),
        );
        encuentros = encuentros.concat(enc);
      }

      let defaultDate = moment().format('YYYY-MM-DD');
      const allEncuentrosDate = [...new Set(encuentros)];
      const exists = allEncuentrosDate.includes(defaultDate);
      allEncuentrosDate.push(defaultDate);
      allEncuentrosDate.sort();

      const todayIndex = allEncuentrosDate.findIndex(
        (encuentro) => encuentro === defaultDate,
      );

      if (!exists) defaultDate = allEncuentrosDate[todayIndex - 1];
      return {
        ...prevState,
        fecha:
          allEncuentrosDate.length > 0
            ? defaultDate
            : moment().format('YYYY-MM-DD'),
      };
    });
  }, [isInsideSchool, ofertasGrupos, row]);

  const handleOpenModal = (row: Acap.Referente.RowOfertaGrupo) => {
    customFormDialog.show({
      title: `${row.alumnoMovimiento.alumno.persona.apellido}, ${row.alumnoMovimiento.alumno.persona.nombre}`,
      renderComponent: <ModalAlumno row={row} />,
      sizeWidth: 'sm',
    });
  };

  const onSave = async () => {
    setIsSaving(true);
    try {
      for (const rowAlumno of differentRowsState) {
        if (
          rowAlumno?.presentismo !== undefined &&
          rowAlumno?.presentismo !== '' &&
          rowAlumno?.idPresente === undefined
        ) {
          await presentismoAsistenciaDAO.aspect('default').save({
            alumno: rowAlumno?.alumnoMovimiento?.alumno?.idAlumno,
            ofertaGrupo: rowAlumno?.ofertaGrupo?.idOfertaGrupo,
            estadoPresente: rowAlumno?.presentismo,
            fecha: `${moment(formValues?.fecha)?.format(
              'YYYY-MM-DD',
            )}T03:00:00.000Z`,
          });
        } else if (
          rowAlumno?.presentismo !== undefined &&
          rowAlumno?.presentismo !== '' &&
          rowAlumno?.idPresente !== undefined
        ) {
          await presentismoAsistenciaDAO.aspect('default').save({
            idPresente: rowAlumno?.idPresente,
            alumno: rowAlumno?.alumnoMovimiento?.alumno?.idAlumno,
            ofertaGrupo: rowAlumno?.ofertaGrupo?.idOfertaGrupo,
            estadoPresente: rowAlumno?.presentismo,
            fecha: `${moment(formValues?.fecha)?.format(
              'YYYY-MM-DD',
            )}T03:00:00.000Z`,
          });
        }
      }
      enqueueSnackbar('Se ha guardado con éxito', {
        variant: 'success',
      });
      reFetchPresentismo();
      setIsSaving(false);
    } catch (e) {
      enqueueSnackbar('Ha ocurrido un problema con el guardado', {
        variant: 'error',
      });
      reFetchPresentismo();
      setIsSaving(false);
    }
  };

  const autoGuardado = async () => {
    await onSave();
    setSetTimeoutGuardado(undefined);
  };

  const handleChangeAlumnos = (row: any, idEstadoPresente: number) => {
    const clonedRows: Array<
      GruposInscripciones & {
        presentismo: number;
      }
    > = _.cloneDeep(rows);
    clonedRows.forEach((alumno) => {
      if (row?.idGrupoInscripcion === alumno?.idGrupoInscripcion) {
        alumno.presentismo = idEstadoPresente;
      }
    });
    setRows(clonedRows);
    if (setTimeoutGuardado !== undefined) {
      clearTimeout(setTimeoutGuardado);
    }
    const timeout = setTimeout(autoGuardado, 3000000);
    setSetTimeoutGuardado(timeout);
  };

  const columns = usePresentismoAcapColumns(
    estadosPresente,
    handleChangeAlumnos,
    handleOpenModal,
    devoluciones,
    isInsideSchool,
    goToDevoluciones,
    row,
    formValues?.fecha,
  );

  const saveValidation = useMemo(() => {
    let saveDisabled = true;

    const differentRows: Acap.Referente.RowOfertaGrupo[] = [];

    rows?.forEach((rowIteration) => {
      const initialStateRow = initialState?.find(
        (initialStateRow: any) =>
          initialStateRow?.idGrupoInscripcion ===
          rowIteration?.idGrupoInscripcion,
      );

      const isTheSame = _.isEqual(initialStateRow, rowIteration);

      if (!isTheSame) {
        differentRows.push(rowIteration);
      }
    });

    setDifferentRows(differentRows);

    differentRows?.forEach((rowIterada) => {
      if (Boolean(rowIterada?.presentismo)) {
        saveDisabled = false;
      }
    });

    return saveDisabled;
  }, [initialState, rows]);

  const workingAll =
    gruposInscripcionesWorking ||
    workingOfertasGrupos ||
    workingOfertas ||
    workingAcciones ||
    estadoPresenteWorking ||
    presentismoWorking ||
    isSaving ||
    workingInscriptosTotales;
  const exit = () => {
    if (setTomandoPresentismo !== undefined) {
      setTomandoPresentismo({ enabled: false, row: null });
    }
  };

  const saveAndExit = async () => {
    await onSave();
    exit();
    customFormDialog.handleCancel();
  };

  const handleSalir = async () => {
    if (saveValidation) {
      exit();
    } else {
      customFormDialog.show({
        title: 'Confirmar',
        renderComponent: (
          <ModalConfirmarSalida exit={exit} saveAndExit={saveAndExit} />
        ),
        sizeWidth: 'sm',
      });
    }
  };

  const rowsPaginadas = rows.slice(offset, limit + offset);

  const rowsSorted = useMemo(() => {
    const rowsCloned = _.cloneDeep(rowsPaginadas);
    rowsCloned.sort((a, b) => {
      const _orderBy = (obj: Acap.Referente.RowOfertaGrupo) => {
        const fullName = `${obj.alumnoMovimiento.alumno.persona.apellido} ${obj.alumnoMovimiento.alumno.persona.nombre}`;
        return orderBy === 'nombre' ? fullName : _.get(obj, orderBy);
      };
      return direction === 'desc'
        ? _orderBy(a)?.localeCompare(_orderBy(b))
        : _orderBy(b)?.localeCompare(_orderBy(a));
    });

    return rowsCloned;
  }, [rowsPaginadas, direction, orderBy]);

  const handleRequestSort = useCallback(
    (newOrderBy: string, newDirection: 'asc' | 'desc') => {
      setDirection(newDirection);
      setOrderBy(newOrderBy);
    },
    [],
  );

  const footerButtons: {
    title: string;
    type: string;
    handleOnClick: () => void;
    disabled?: boolean;
  }[] = [
    {
      title: 'Descargar',
      type: 'primary',
      handleOnClick: () => {
        generateExcel(data?.body, data?.name);
      },
      disabled: showTable || workingAll || rows?.length === 0,
    },
    {
      title: 'Guardar',
      type: 'primary',
      handleOnClick: () => onSave(),
      disabled: workingAll || saveValidation || ROL.PRECEPTOR === Number(rol),
    },
  ];

  if (setTomandoPresentismo)
    footerButtons.unshift({
      title: 'Salir',
      type: 'primary',
      handleOnClick: () => handleSalir(),
    });

  useEffect(() => {
    history?.location?.pathname?.includes('referente-accion') &&
      setIsReferenteAccion(true);
    history?.location?.pathname?.includes('referente-organizacion') &&
      setIsReferenteOrganizacion(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return workingInscriptosTotales ||
    workingAcciones ||
    workingOfertasGrupos ||
    workingOfertas ? (
    <Loading />
  ) : (
    <>
      <FiltersHeader
        inscriptos={gruposInscripciones}
        formValues={formValues}
        setFormValues={setFormValues}
        setFilters={setFilters}
        ofertas={ofertas}
        ofertasGrupos={ofertasGrupos}
        workingAll={workingAll}
        isInsideSchool={isInsideSchool}
        organizacionesEscuela={organizacionesEscuela}
        organizacionSeleccionada={organizacionSeleccionada}
        row={row}
        acciones={acciones}
        isReferenteAccion={isReferenteAccion}
        ofertaReferenteAccion={ofertaReferenteAccion}
        setShowTable={setShowTable}
      />
      {showNoResults ? (
        <Grid item style={{ paddingBottom: '20px', paddingTop: '10px' }}>
          <Typography className={classes.textCenter} variant="h4">
            No hay resultados para esta búsqueda
          </Typography>
        </Grid>
      ) : showTable ? (
        <Grid item style={{ paddingBottom: '20px', paddingTop: '10px' }}>
          <Typography className={classes.textCenter} variant="h4">
            Presione Buscar para visualizar la información
          </Typography>
        </Grid>
      ) : (
        <>
          <InfoTable
            columns={columns}
            rows={workingAll ? [] : rowsSorted}
            working={workingAll}
            direction={direction}
            orderBy={orderBy}
            onSortChange={handleRequestSort}
          />
          {rowsSorted.length > 0 && !workingAll && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={limit}
              page={offset / limit}
              onPageChange={(e, page) => {
                let offset = 0;
                if (page < 0) offset = 0 * limit;
                else offset = page * limit;
                setOffset(offset);
                setPage(page);
              }}
              onRowsPerPageChange={(e) => {
                setLimit(parseInt(e.target.value));
              }}
              labelRowsPerPage="Filas por página"
              labelDisplayedRows={({ from, to, count }) => {
                return from + '-' + to + ' de ' + count;
              }}
            />
          )}
        </>
      )}
      <Footer buttonConfig={footerButtons} spacing={3} position="center" />
    </>
  );
};
