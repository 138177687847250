import { REQUEST_TYPES } from 'src/lib/libby/hooks/useLibbyRequest';
import { makeEntityLibbyRequest } from '../../../lib/libby/hooks/makeEntityLibbyRequest';

const DAO_NAME = 'proyeccionbulkcustom';

// hooks
export const useProyeccionBulkCustomGetRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.GET,
);
export const useProyeccionBulkCustomPostRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.POST,
);
export const useProyeccionBulkCustomPutRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.PUT,
);
export const useProyeccionBulkCustomDeleteRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.DELETE,
);
