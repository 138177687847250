import { useCallback } from 'react';
import { useCalificacionesPrimarioDAO } from 'src/app/business';
import { useDescargardriveDAO } from 'src/app/business/businessCustomEndpoints/descargardrive';
import { URLAttachment } from '../type';
import { AnyObject, NIVEL } from '../../../../commons';
import { CalificacionesPrimario } from '../../../../app/models';

type _Window = Window & typeof globalThis & { pdfjsLib: AnyObject };

const isNivelPrimario = (nivel: string, periodo: number) => {
  return nivel === NIVEL.PRIMARIO.toString() && periodo === 2;
};

const drawDocument = (document: any, element?: Element) => {
  if (element) {
    element.setAttribute('style', 'display:block');
    element.appendChild(document);
  }
};

// Se necesita que se renderize primero el documento html para luego buscar los archivos o documentos e incrustarlos
/**
 * Toma la data de los divs con la clase "drive-attachment" que vienen del template y resuelve los documentos y los inyecta en ese mismo div, con lo cual estos quedan dentro de la tabla de Archivos adjuntos u otros dependiendo del Boletin/Informe/PIP/PPS
 *  */
export const useAttachmentFiles = (nivel: string) => {
  const calificacionesDAO = useCalificacionesPrimarioDAO();
  const documentoDriverDAO = useDescargardriveDAO();

  const getURLAttachments = useCallback(() => {
    const links = document.getElementsByClassName('drive-attachment');
    const urlsAttachments: URLAttachment[] = [];
    for (const link of links) {
      const attribute = link.attributes[3]?.nodeValue?.split('.').pop();
      urlsAttachments.push({
        element: link,
        url: link?.attributes[2]?.nodeValue,
        file_name: link?.attributes[3]?.nodeValue,
        file_type: attribute,
      });
    }
    return urlsAttachments;
  }, []);

  const resources = useCallback(async (buffers: URLAttachment[]) => {
    try {
      for await (const buffer of buffers) {
        if (buffer.url != null) {
          const splitedUrl = buffer.url.split('/');
          const imageUrl = `https://drive.google.com/uc?export=view&id=${splitedUrl[5]}`;
          const img = document.createElement('img');
          img.src = imageUrl;
          img.style.margin = '1rem 0';
          img.style.maxWidth = '100%';
          drawDocument(img, buffer.element);
        } else {
          const loadingTask = (window as _Window).pdfjsLib.getDocument({
            data: buffer.data,
          });
          const pdf = await loadingTask.promise;
          const pageCount = pdf._pdfInfo.numPages;

          for (let i = 1; i <= pageCount; i++) {
            // Load information from the first page.
            const page = await pdf.getPage(i);
            const scale = 1.2;
            const viewport = page.getViewport({ scale });
            // Apply page dimensions to the <canvas> element.
            const canvas = document.createElement('canvas');
            const context = canvas.getContext('2d');
            canvas.height = viewport.height;
            canvas.width = viewport.width;
            canvas.style.margin = '1rem 0';
            // Render the page into the <canvas> element.
            const renderContext = {
              canvasContext: context,
              viewport: viewport,
            };
            await page.render(renderContext);
            drawDocument(canvas, buffer.element);
          }
        }
      }
    } catch (error) {
      const div = document.createElement('h4');
      drawDocument('Error creando adjunto', div);
    }
  }, []);

  const fetchBuffers = useCallback(
    async (links: URLAttachment[]) => {
      const buffersArray: URLAttachment[] = [];

      if (links.length === 0) return;

      for await (const link of links) {
        if (link.file_type === 'pdf') {
          const { data } = (await documentoDriverDAO.post({
            url: link?.url,
          })) as { data: { data: Buffer } };
          buffersArray.push({ ...link, url: null, data: data.data });
        } else {
          buffersArray.push(link);
        }
      }

      resources(buffersArray);
    },
    [documentoDriverDAO, resources],
  );

  const renderDocuments = useCallback(
    async (idAlumno: string, periodo: number) => {
      const links = getURLAttachments();
      if (isNivelPrimario(nivel, periodo)) {
        const calificaciones = (await calificacionesDAO.getById(
          idAlumno,
        )) as CalificacionesPrimario[];
        if (calificaciones && calificaciones.length > 0) {
          const attachmentURL = calificaciones[0]?.adjunto;
          if (attachmentURL) {
            links.push({
              url: attachmentURL,
              file_name: null,
              file_type: 'pdf',
            });
          }
        }
      }

      await fetchBuffers(links);
    },
    [nivel, getURLAttachments, calificacionesDAO, fetchBuffers],
  );

  return { renderDocuments };
};
