import React from 'react';
import { TooltipColumn } from '../../components/TooltipColumn';
import { TableColumn } from '../../TableColumn';
import { FILTERS } from '../../table_filters';
import { MatriculacionSeccionAnioReport } from '../../types';

// Matriculados por seccion y año
export const MATRICULADOS_SECCION_ANIO_COLUMNS: TableColumn<
  MatriculacionSeccionAnioReport,
  string | React.ReactNode
>[] = [
  new TableColumn<MatriculacionSeccionAnioReport>('cueanexo'),
  new TableColumn<MatriculacionSeccionAnioReport, React.ReactNode>(
    'establecimiento',
    undefined,
    (value, row) => <TooltipColumn title={value} />,
  ),
  new TableColumn<MatriculacionSeccionAnioReport>('distritoescolar'),
  new TableColumn<MatriculacionSeccionAnioReport, React.ReactNode>(
    'apellido',
    undefined,
    (value, row) => <TooltipColumn title={value} charLength={5} />,
  ),
  new TableColumn<MatriculacionSeccionAnioReport, React.ReactNode>(
    'nombre',
    undefined,
    (value, row) => <TooltipColumn title={value} charLength={5} />,
  ),
  new TableColumn<MatriculacionSeccionAnioReport>('tipodocumento'),
  new TableColumn<MatriculacionSeccionAnioReport>('documento'),
  new TableColumn<MatriculacionSeccionAnioReport>('genero'),
  new TableColumn<MatriculacionSeccionAnioReport>('username'),
  new TableColumn<MatriculacionSeccionAnioReport>('año'),
  new TableColumn<MatriculacionSeccionAnioReport, React.ReactNode>(
    'seccion',
    undefined,
    (value, row) => <TooltipColumn title={value} charLength={5} />,
  ),
  new TableColumn<MatriculacionSeccionAnioReport>('condicion'),
];

export const MATRICULADOS_SECCION_ANIO_FILTERS = [
  FILTERS.ciclo,
  FILTERS.anio,
  FILTERS.dependencia_funcional,
  FILTERS.localizacion,
  FILTERS.dni,
  FILTERS.seccion,
  FILTERS.turno,
  FILTERS.distrito_escolar,
];

// Listado de estudiantes inscriptos
export const MATRICULADOS_LISTADO_INSCRIPTOS_COLUMNS = [
  new TableColumn('cueanexo'),
  new TableColumn<MatriculacionSeccionAnioReport, React.ReactNode>(
    'establecimiento',
    undefined,
    (value, row) => <TooltipColumn title={value} />,
  ),
  new TableColumn('distritoescolar'),
  new TableColumn('apellido'),
  new TableColumn<MatriculacionSeccionAnioReport, React.ReactNode>(
    'nombre',
    undefined,
    (value, row) => <TooltipColumn title={value} charLength={10} />,
  ),
  new TableColumn('tipodocumento'),
  new TableColumn('documento'),
  new TableColumn('genero'),
  new TableColumn('año'),
  new TableColumn('turno'),
  new TableColumn('responsables'),
];

export const MATRICULADOS_LISTADO_INSCRIPTOS_FILTERS = [
  FILTERS.anio,
  FILTERS.dependencia_funcional,
  FILTERS.localizacion,
  FILTERS.dni,
  FILTERS.turno,
  FILTERS.distrito_escolar,
];

// Historial de alumnos desmatriculados
export const MATRICULADOS_DESMATRICULADOS_COLUMNS = [
  new TableColumn('cueanexo'),
  new TableColumn<MatriculacionSeccionAnioReport, React.ReactNode>(
    'establecimiento',
    undefined,
    (value, row) => <TooltipColumn title={value} />,
  ),
  new TableColumn('distritoescolar'),
  new TableColumn('apellido'),
  new TableColumn<MatriculacionSeccionAnioReport, React.ReactNode>(
    'nombre',
    undefined,
    (value, row) => <TooltipColumn title={value} charLength={5} />,
  ),
  new TableColumn('tipodocumento'),
  new TableColumn('documento'),
  new TableColumn('genero'),
  new TableColumn('fecha_desmatriculado'),
  new TableColumn('motivo_baja'),
  new TableColumn('tiene_calificaciones'),
];

export const MATRICULADOS_DESMATRICULADOS_FILTERS = [
  FILTERS.dependencia_funcional,
  FILTERS.distrito_escolar,
  FILTERS.localizacion,
  FILTERS.seccion,
  FILTERS.anio,
  FILTERS.turno,
  FILTERS.dni,
  FILTERS.motivoJustificacion,
];

// Cantidad de estudiantes en todo el colegio, por año, por sección y detallando plan de estudio
export const MATRICULADOS_ESTUDIANTES_COLEGIO_COLUMNS = [
  new TableColumn('cue_anexo'),
  new TableColumn<MatriculacionSeccionAnioReport, React.ReactNode>(
    'establecimiento',
    undefined,
    (value, row) => <TooltipColumn charLength={35} title={value} />,
  ).setWidth('20%'),
  new TableColumn('distrito_escolar'),
  new TableColumn('año'),
  new TableColumn<MatriculacionSeccionAnioReport, React.ReactNode>(
    'seccion',
    undefined,
    (value, row) => <TooltipColumn title={value} charLength={5} />,
  ),
  new TableColumn('cantidad_alumnos').setWidth('6%'),
  new TableColumn('plan_estudios'),
  new TableColumn('cantidad_espacios').setWidth('6%'),
];

export const MATRICULADOS_ESTUDIANTES_COLEGIO_FILTERS = [
  FILTERS.dependencia_funcional,
  FILTERS.distrito_escolar,
  FILTERS.localizacion,
  FILTERS.seccion,
  FILTERS.anio,
  FILTERS.turno,
];
