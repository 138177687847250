import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { ProyeccionIEL } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'proyeccioniel';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idProyeccionIEL',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idProyeccionIEL',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.ProyeccionIEL
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.ProyeccionIEL,
      hoc: { ...defaultOptions.hoc, ...businessOptions.ProyeccionIEL.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<ProyeccionIEL>(DAO_NAME, optionsToUse);

// hooks
export const useProyeccionIELFetchById = fetchById;
export const useProyeccionIELLibbyCall = libbyCall;
export const useProyeccionIELDAO = libbyDAO;
export const useProyeccionIELLibbyFetch = libbyFetch;

// components/hoc
export const ProyeccionIELAutocomplete = entityAutocomplete;
export const ProyeccionIELCall = renderPropCall;
export const ProyeccionIELById = renderPropFetchById;
export const ProyeccionIELSelect = simpleSelect;
export const ProyeccionIELAccordionSelector = accordionSelector;
export const ProyeccionIELSelectedChips = selectedChips;

// context
// ByIdContext
export const useProyeccionIELByIdContext = useByIdContext;
export const ProyeccionIELByIdProvider = ByIdProvider;
export const ProyeccionIELByIdContext = ByIdContext;
// GeneralContext
export const useProyeccionIELContext = useGeneralContext;
export const ProyeccionIELProvider = GeneralProvider;
export const ProyeccionIELContext = GeneralContext;
