import React, { createContext, useContext } from 'react';
import { useForm } from 'react-final-form-hooks';
import { validate } from '../../functions';
import FormService from '../../functions/submitForms';
import { Grid } from '@material-ui/core';
import { usePropuestaJEDAO } from 'src/app/business/presentismo_v2';
import customFormDialog from 'src/commons/services/customFormDialog';
import { useSnackbar } from 'notistack';
import { usePropuestaJEDiaDAO } from 'src/app/business/presentismo_v2/PropuestaJEDia';
import { presentismo } from '../../types';

export const PropuestaJEFormContext =
  createContext<presentismo.PropuestaJEContext>({
    form: {},
    values: {},
  });

export const usePropuestaJEFormContext = () =>
  useContext(PropuestaJEFormContext);

export const PropuestaJEFormProvider = ({
  children,
  seccion,
  initialValues,
  reFetch = () => {},
}: {
  children: React.ReactElement;
  seccion: string | number;
  initialValues?: presentismo.PropuestaJe;
  reFetch: () => void;
}) => {
  const dao = usePropuestaJEDAO();
  const diaDao = usePropuestaJEDiaDAO();
  const { enqueueSnackbar } = useSnackbar();

  const submitHandler = async (values: presentismo.PropuestaJe) => {
    try {
      const instance = new FormService(Number(seccion), dao, diaDao).setValues(
        values,
      );
      await instance.submit();

      enqueueSnackbar('Propuestas guardadas exitosamente', {
        variant: 'success',
      });
      customFormDialog.handleCancel();
      reFetch();
    } catch (error) {
      console.error('Error guardando las propuestas', error.message);
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    }
  };

  const { form, handleSubmit, values } = useForm({
    onSubmit: submitHandler,
    validate,
    initialValues,
  });

  return (
    <PropuestaJEFormContext.Provider
      value={{
        form,
        values,
      }}
    >
      <Grid item xs={12}>
        <form onSubmit={handleSubmit}>{children}</form>
      </Grid>
    </PropuestaJEFormContext.Provider>
  );
};
