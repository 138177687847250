import {
  ACAP_SCREEN,
  ACCIONES_SCREEN,
  AcapEstablecimientoContextValue,
  AccionesScreen,
  ORGANIZACION_SCREEN,
  OrganizacionesScreen,
} from './type';

export const organizacionesScreenValue: OrganizacionesScreen = {
  screen: ORGANIZACION_SCREEN.MAIN,
};

export const accionesScreenValue: AccionesScreen = {
  screen: ACCIONES_SCREEN.VIEW,
};

export const emptyAcapEstablecimiento: AcapEstablecimientoContextValue = {
  screen: ACAP_SCREEN.ORGANIZACIONES,
  organizacionesScreen: organizacionesScreenValue,
  accionesScreen: accionesScreenValue,
  ciclosLectivos: [],
  loading: false,
  setScreen: () => {},
  setAccionesScreen: () => {},
  setOrganizacionesScreen: () => {},
};
