import { makeBusinessEntity } from '../../lib/libby/generator';
import { Proyeccion } from '../models';

const DAO_NAME = 'proyeccionmatriculacion';

const {
  hook: { libbyCall, libbyFetch },
} = makeBusinessEntity<Proyeccion>(DAO_NAME, {});

// hooks
export const useProyeccionMatriculacionLibbyCall = libbyCall;
export const useProyeccionMatriculacionLibbyFetch = libbyFetch;
