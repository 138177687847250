import { routes } from './routes';
import { MenuItem } from '../../../../commons/const/ui/menu';

export const aulasVirtualesMenu: MenuItem = {
  key: 'aulasvirtuales',
  basePath: '/aulasvirtuales',
  enabled: true,
  icon: 'List',
  text: 'AulasVirtuales',
  children: routes,
};
