import { MenuItem } from '../../../../../commons/const/ui/menu';
import { ConfiguracionMatricula } from 'src/screens/Private/ConfigurationAdmin/ConfiguracionMatricula';

export const configuracionMatriculaMenu: MenuItem = {
  key: 'configurationAdminMatricula',
  basePath: '/configuracion_matricula',
  target: {
    component: ConfiguracionMatricula,
    path: '/',
  },
  enabled: true,
  icon: 'List',
  text: 'Configuration Admin Matricula',
  children: [],
};
