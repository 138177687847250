import { ReferentesDAO } from 'src/platform/libby/dao/ReferentesDAO';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { Referente } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'referentes';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idReferentes',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idReferentes',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Referentes
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Referentes,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Referentes.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Referente, ReferentesDAO>(DAO_NAME, optionsToUse);

// hooks
export const useReferentesFetchById = fetchById;
export const useReferentesLibbyCall = libbyCall;
export const useReferentesDAO = libbyDAO;
export const useReferentesLibbyFetch = libbyFetch;

// components/hoc
export const ReferentesAutocomplete = entityAutocomplete;
export const ReferentesCall = renderPropCall;
export const ReferentesById = renderPropFetchById;
export const ReferentesSelect = simpleSelect;
export const ReferentesAccordionSelector = accordionSelector;
export const ReferentesSelectedChips = selectedChips;

// context
// ByIdContext
export const useReferentesByIdContext = useByIdContext;
export const ReferentesByIdProvider = ByIdProvider;
export const ReferentesByIdContext = ByIdContext;
// GeneralContext
export const useReferentesContext = useGeneralContext;
export const ReferentesProvider = GeneralProvider;
export const ReferentesContext = GeneralContext;
