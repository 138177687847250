import { LibbyFetchDAO } from './LibbyFetchDAO';

export class InformesGeneradosDAO extends LibbyFetchDAO {
  constructor() {
    super('informesgenerados', 'idProceso');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getProcessById(idProcess: number) {
    return this.query().equals('idProceso', idProcess).run();
  }
}
