import React, { useCallback } from 'react';
import { useField } from 'react-final-form-hooks';
import { Input, InputProps } from '../../../app/components/inputs/Input';
import { getErrorAndHelperTextNoTranslation } from '../../../utils/materialHelpers';
import { makeStyles } from '@material-ui/core/styles';
import confirmDialog from 'src/commons/services/confirmDialog';
import { AnyObject } from 'src/commons';

const useStyles = makeStyles({
  fileInputContainer: {
    position: 'relative',
    margin: '5px 0 0 0',
    height: '3em',
    width: '80%',
  },
  fileInput: {
    color: 'green',
    width: '0.1px',
    height: '0',
    opacity: '0',
    overflow: 'hidden',
    zIndex: -1,
    pointerEvents: 'none',
  },
  label: {
    marginBottom: '1em',
    fontSize: 14,
    fontWeight: 600,
    color: '#7b7b7b',
  },
  clickLabel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    zIndex: 10,
    top: '0px',
    boxSizing: 'border-box',
    padding: '0 3em 0 1em',
    width: '100%',
    height: '100%',
    color: '#7b7b7b',
    borderRadius: '5px',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#dddddd',
      color: 'black',
      border: '2px solid black',
    },
  },
  deleteButton: {
    position: 'absolute',
    right: -1,
    color: 'black',
    zIndex: 20,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '3em',
    backgroundColor: '#d8d8d8',
    borderRadius: '5px',
    cursor: 'pointer',
    fontFamily: 'sans-serif',
  },
  fileName: {
    paddingRight: '1em',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});

const FILE_SIZES = {
  '2MB': { size: 2000000, description: '2MB' },
  '4MB': { size: 4000000, description: '4MB' },
  '6MB': { size: 6000000, description: '6MB' },
  '8MB': { size: 8000000, description: '8MB' },
  '10MB': { size: 10000000, description: '10MB' },
};

// FIXME: type form props
export interface FormFileInputProps {
  name: string;
  label: string;
  form: any;
  inputProps?: InputProps;
  placeholder?: string;
  disabled?: boolean;
  className?: any;
  custom?: boolean;
  validFileTypes?: string[];
  maxFileSize?: '2MB' | '4MB' | '6MB' | '8MB' | '10MB';
  getFile?: any;
  validation?: (value: any, allValues: AnyObject) => string | undefined | void;
  disableErrorMessage?: boolean;
}

export const FormFileInput = ({
  name,
  form,
  label,
  placeholder,
  inputProps = {},
  disabled,
  className,
  custom,
  validFileTypes,
  maxFileSize = '10MB',
  getFile = null,
  validation = () => {},
}: FormFileInputProps): JSX.Element => {
  const field = useField(name, form, validation);
  const classes = useStyles();

  const fileSize = FILE_SIZES[maxFileSize];
  const onChange = useCallback(
    async ({ target }) => {
      if (!!target.files) {
        const uploadedFile = target.files[0];
        if (getFile && uploadedFile) getFile(field, uploadedFile);
        if (uploadedFile?.size > fileSize.size) {
          await confirmDialog.show({
            title: `Archivo adjunto`,
            content: `El archivo adjunto no puede tener un tamaño mayor a ${fileSize.description}.`,
            confirmText: 'Entendido',
          });
        }

        if (!!validFileTypes && !validFileTypes?.includes(uploadedFile.type)) {
          await confirmDialog.show({
            title: `Archivo adjunto`,
            content:
              'El archivo adjunto debe ser un documento del tipo PDF, Word o imagen.',
            confirmText: 'Entendido',
          });
        }
        if (!!validFileTypes && !!maxFileSize) {
          if (
            validFileTypes?.includes(uploadedFile.type) &&
            uploadedFile?.size < fileSize.size
          ) {
            field.input.onChange({ files: target.files, value: target.value });
          }
        } else {
          field.input.onChange({ files: target.files, value: target.value });
        }
      }
    },
    [
      getFile,
      field,
      fileSize.size,
      fileSize.description,
      validFileTypes,
      maxFileSize,
    ],
  );

  const handleClick = () => {
    form.change(name, undefined);
  };

  const newInputProps = {
    ...inputProps,
    ...{ accept: !!validFileTypes ? validFileTypes?.join(',') : '' },
  };

  return custom ? (
    <div>
      <span className={classes.label}>{label}</span>
      <div className={classes.fileInputContainer}>
        <label htmlFor="fileInputId" className={`${classes.clickLabel}`}>
          {!field.input.value.value ? (
            `${placeholder}`
          ) : (
            <span className={classes.fileName}>
              {`${field.input.value.value.split('\\')[2]}`}
            </span>
          )}
        </label>
        {!!field.input.value.value && (
          <span className={classes.deleteButton} onClick={handleClick}>
            X
          </span>
        )}
        <Input
          id="fileInputId"
          className={className ?? `${classes.fileInput}`}
          disabled={disabled}
          placeholder={placeholder}
          type="file"
          value={!field.input.value ? '' : field.input.value.value}
          onChange={onChange}
          {...getErrorAndHelperTextNoTranslation(field)}
          inputProps={{ ...newInputProps }}
        />
      </div>
    </div>
  ) : (
    <Input
      disabled={disabled}
      placeholder={placeholder}
      label={label}
      type="file"
      value={!field.input.value ? '' : field.input.value.value}
      onChange={onChange}
      {...getErrorAndHelperTextNoTranslation(field)}
      inputProps={{ ...newInputProps }}
    />
  );
};
