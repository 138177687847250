import { Template } from 'src/lib/templates';

export const template: Template = {
  name: 'AddUser',
  template: [
    {
      name: 'nombre',
      type: {
        name: 'string',
      },
    },
    {
      name: 'apellido',
      type: {
        name: 'string',
      },
    },
    {
      name: 'documento',
      type: {
        name: 'string',
      },
    },
    {
      name: 'cuil',
      type: {
        name: 'string',
      },
      label: 'CUIL',
      validators: [
        {
          name: 'pattern',
          signature: ['(20|23|24|27|30|33|34)(-)?[0-9]{8}(-)?[0-9]$'],
        },
      ],
    },
    {
      name: 'cuentaBue',
      type: {
        name: 'string',
      },
    },
  ],
};
