import { LibbyFetchDAO } from './LibbyFetchDAO';

export class AccionesDAO extends LibbyFetchDAO {
  constructor() {
    super('acciones', 'idAccion');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getAllNames() {
    return this.query().aggregate().groupBy('entity.nombre').run();
  }

  getQuantityByOrganization() {
    return this.query()
      .aggregate()
      .groupBy('entity.id_organizacion')
      .count('idAccion')
      .run();
  }
}
