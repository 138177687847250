import { EspacioCurricular, Seccion } from 'src/app/models';
import { ReducerTypes, SET_ESPACIOS_CURRICULARES_SECCION } from './constants';

export type StateReducer = {
  espaciosCurriculares: EspacioCurricular[];
  jeComun: boolean;
  jeEspecial: boolean;
  seccionesJe: boolean;
};

export type ReducerAction = {
  type: ReducerTypes;
  payload:
    | EspacioCurricular[]
    | {
        secciones: Seccion[];
        espaciosCurriculares: EspacioCurricular[];
        espaciosCurricularesJE: EspacioCurricular[];
      };
};

export const initialState = {
  espaciosCurriculares: [],
  jeComun: false,
  jeEspecial: false,
};

export const calificacionesSecundarioReducer = (
  state: StateReducer,
  action: ReducerAction,
) => {
  switch (action.type) {
    case SET_ESPACIOS_CURRICULARES_SECCION:
      const jeEspecial =
        (
          action.payload as {
            secciones: Seccion[];
            espaciosCurriculares: EspacioCurricular[];
            espaciosCurricularesJE: EspacioCurricular[];
          }
        ).espaciosCurricularesJE.length > 0;
      const jeComun = (
        action.payload as {
          secciones: Seccion[];
          espaciosCurriculares: EspacioCurricular[];
        }
      ).espaciosCurriculares.some(
        (ec) => ec.descripcion.toLowerCase() !== 'jornada extendida',
      );
      const seccionesJe = (
        action.payload as {
          secciones: Seccion[];
          espaciosCurriculares: EspacioCurricular[];
        }
      ).secciones.some((s) => s.jornada === 'Extendida');

      return {
        ...state,
        espaciosCurriculares: (
          action.payload as {
            secciones: Seccion[];
            espaciosCurriculares: EspacioCurricular[];
          }
        ).espaciosCurriculares,
        jeEspecial,
        jeComun,
        seccionesJe,
      };

    default:
      return {
        ...state,
      };
  }
};
