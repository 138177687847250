import { Rules } from 'src/lib/permission/types';
import { BLOCKER_ID, SIDEMENU_ID, TABBAR_BLOCKER_ID } from '../../const';

// route preceptor
export const preceptorRulesSets: Rules = {
  idRule: 'rol-7',
  routes: [
    { key: 'repositorioABM' },
    { key: 'planificacionesFeedback' },
    { key: 'aulasvirtuales' },
    { key: 'repositorio' },
    { key: 'configurationAdmin' },
    { key: 'gestionRoles' },
    // { key: 'espacioscurriculares' },
    { key: 'signUp' },
    { key: 'comunicaciones' },
    { key: 'tablero' },
  ],
  blockers: [
    { id: BLOCKER_ID.REQUEST_ROL_ESTUDIANTE },
    { id: BLOCKER_ID.REQUEST_ROL_RESPONSABLE },
    { id: BLOCKER_ID.VALIDATE_SUPERVISOR_USER },
    { id: BLOCKER_ID.VIEW_USER_MANAGEMENT },
    { id: BLOCKER_ID.VALIDATE_DIRECTIVE_USER },
    { id: BLOCKER_ID.APPROVE_SUPERVISOR_USER },
    { id: BLOCKER_ID.APPROVE_DIRECTIVE_USER },
    { id: BLOCKER_ID.REMOVE_SUPERVISOR_ESTABLISHMENT },
    { id: BLOCKER_ID.REMOVE_DIRECTIVE_ESTABLISHMENT },
    { id: BLOCKER_ID.EDIT_PROFILE_USERS },
    { id: BLOCKER_ID.VIEW_REPOSITORY_ABM },
    { id: BLOCKER_ID.DELETE_PLANIFICATIONS },
    { id: BLOCKER_ID.EDIT_PLANIFICATIONS },
    { id: BLOCKER_ID.ADD_PLANIFICATIONS },
    { id: BLOCKER_ID.VIEW_REPOSITORY_ABM },
    { id: BLOCKER_ID.ASESORE_APPROVE_PLANIFICATIONS },
    { id: BLOCKER_ID.MATRICULACION_SECCION },
    { id: BLOCKER_ID.MATRICULACION_PROMOTION },
    { id: BLOCKER_ID.MATRICULACION_PASS_MANAGEMENT },
    { id: BLOCKER_ID.MATRICULACION_EXTERNOS },
    { id: BLOCKER_ID.MATRICULACION_GRUPOS },
    { id: BLOCKER_ID.MATRICULACION_INSCRIPTOS },
  ],
  sideMenu: [
    { id: SIDEMENU_ID.REPOSITORY_ABM },
    { id: SIDEMENU_ID.COMUNICATIONS },
    { id: SIDEMENU_ID.NOTIFICATIONS },
    { id: SIDEMENU_ID.CALENDAR },
    { id: SIDEMENU_ID.DOCUMENTS },
    { id: SIDEMENU_ID.ALUMNOS_AULAS_VRITUALES },
    { id: SIDEMENU_ID.CONFIGURACION_ADMIN },
    // { id: SIDEMENU_ID.CURRICULAR_SPACES },
    { id: SIDEMENU_ID.CONFIGURACION },
    { id: SIDEMENU_ID.GESTIONROLES },
    { id: SIDEMENU_ID.DASHBOARD },
  ],
  tabBars: [
    { id: TABBAR_BLOCKER_ID.MATRICULACION_SECCION },
    { id: TABBAR_BLOCKER_ID.MATRICULACION_INSCRIPTOS },
    { id: TABBAR_BLOCKER_ID.MATRICULACION_EXTERNOS },
    { id: TABBAR_BLOCKER_ID.MATRICULACION_GRUPOS },
    { id: TABBAR_BLOCKER_ID.MATRICULACION_PASS_MANAGEMENT },
    { id: TABBAR_BLOCKER_ID.PRESENTISMO_CONFIGURACION },
  ],
};
