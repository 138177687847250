import React, { useMemo } from 'react';

export const EnviromentBlocker = ({
  children,
  environments,
  inverse = false,
}: {
  children: JSX.Element;
  environments: string[];
  inverse?: boolean;
}) => {
  const HOST_NAME = window.location.host;

  const isBlocked = useMemo(() => {
    return inverse
      ? !environments.includes(HOST_NAME)
      : environments.includes(HOST_NAME);
  }, [inverse, HOST_NAME, environments]);

  return <>{isBlocked ? null : children}</>;
};
