import React from 'react';
import { Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AppTheme } from '../../../theme';

const useStyle = makeStyles<AppTheme>((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
  },
}));

export interface SelectedChipsProps<T> {
  items: T[];
  labelKey: keyof T;
  idKey: keyof T;
  onChange?: (newItems: T[]) => void;
  onItemDelete?: (item: T) => void;
}

// TODO: add performance if needed
export function SelectedChips<T>({
  items,
  idKey,
  labelKey,
  onChange,
  onItemDelete,
}: SelectedChipsProps<T>) {
  const classes = useStyle();
  return (
    <>
      {items.map((item) => (
        <Chip
          key={`${item[idKey]}`}
          className={classes.chip}
          label={item[labelKey]}
          onDelete={() => {
            if (onChange) {
              const newItems = items.filter(
                (_item) => _item[idKey] !== item[idKey],
              );
              onChange(newItems);
            }
            if (onItemDelete) {
              onItemDelete(item);
            }
          }}
        />
      ))}
    </>
  );
}
