import { REQUEST_TYPES } from 'src/lib/libby/hooks/useLibbyRequest';
import { makeEntityLibbyRequest } from '../../../lib/libby/hooks/makeEntityLibbyRequest';

const DAO_NAME = 'proyeccionielbulkcustom';

// hooks
export const useProyeccionIELBulkCustomGetRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.GET,
);
export const useProyeccionIELBulkCustomPostRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.POST,
);
export const useProyeccionIELBulkCustomPutRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.PUT,
);
export const useProyeccionIELBulkCustomDeleteRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.DELETE,
);
