import { Button, Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { SimpleSelect, Loading } from 'src/commons';
import { useTiposAccionesLibbyFetch } from 'src/app/business/acap/TiposAcciones';
import { useSectoresLibbyFetch } from 'src/app/business/acap/Sectores';
import {
  INITIAL_YEAR,
  useCicloLectivoLibbyFetch,
  useLocalizacionPlanEstudioNivelLibbyFetch,
  useTurnoLibbyFetch,
} from 'src/app/business';
import { makeStyles } from '@material-ui/styles';
import { useCicloLectivoAnioFilter } from '../hooks/useCicloLectivoAnioFilter';
import { InscripcionesFilter } from '../tabs/Inscripciones';
import { GruposInscripciones, Organizacion } from 'src/app/models';
import { getUniqueItems } from '../functions/helpers';

const useStyles = makeStyles({
  buttonLoading: {
    height: '20px',
    padding: '0',
    display: 'flex',
    alignContent: 'center',
  },
});

export const FiltersHeaderInscripciones = ({
  setFilters,
  filters,
  working,
  searchInscripcion,
  gruposInscripcionesOrgAcc,
}: {
  setFilters: React.Dispatch<React.SetStateAction<InscripcionesFilter>>;
  filters: InscripcionesFilter;
  working: boolean;
  searchInscripcion: () => void;
  gruposInscripcionesOrgAcc: GruposInscripciones[];
}) => {
  const classes = useStyles();

  const cicloFilter = useCicloLectivoAnioFilter();

  const { data: ciclos } = useCicloLectivoLibbyFetch({
    filter: cicloFilter,
    limit: cicloFilter.ciclo.length,
  });

  const filterCicloLectivo = ciclos
    ? ciclos.filter((cycle: any) => cycle.anio > INITIAL_YEAR)
    : [];

  const { data: tipoAcciones = [], working: workingTipoAcciones } =
    useTiposAccionesLibbyFetch({
      limit: 500,
    });
  const { data: turnos = [], working: workingTurnos } = useTurnoLibbyFetch();
  const { data: sectores = [], working: workingSectores } =
    useSectoresLibbyFetch();

  const organizacionesInscriptos = getUniqueItems(
    gruposInscripcionesOrgAcc.map(
      (grupo) => grupo.ofertaGrupo.ofertaTurno.oferta.accion.organizacion,
    ),
  );
  const accionesInscriptos = getUniqueItems(
    gruposInscripcionesOrgAcc.map(
      (grupo) => grupo.ofertaGrupo.ofertaTurno.oferta.accion,
    ),
  );

  const organizacionesInscriptosOrdenado = organizacionesInscriptos.sort(
    (a: Organizacion, b: Organizacion) =>
      a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0,
  );

  const eliminarDuplicadosPorPropiedad = (array: any[], propiedad: string) => {
    const setProps = new Set();
    const resultado = [];

    for (const element of array) {
      if (!setProps.has(element[propiedad])) {
        resultado.push(element);
        setProps.add(element[propiedad]);
      }
    }

    return resultado;
  };

  const organizacionesSinDuplicados = eliminarDuplicadosPorPropiedad(
    organizacionesInscriptosOrdenado,
    'idOrganizacion',
  );

  const accionesSinDuplicados = eliminarDuplicadosPorPropiedad(
    accionesInscriptos,
    'idAccion',
  );

  const allSecciones = gruposInscripcionesOrgAcc.map(
    (grupo) => grupo.alumnoMovimiento.seccion,
  );

  const secciones = getUniqueItems(allSecciones);

  const { data: localizacionPlanEstudioNivel = [] } =
    useLocalizacionPlanEstudioNivelLibbyFetch({
      limit: 500,
    });

  const uniquesOrientacionesEscuela = useMemo(() => {
    return getUniqueItems(
      localizacionPlanEstudioNivel
        .map((item) =>
          item.planEstudioNivel.planEstudio.orientaciones.map(
            (peOrientacion) => peOrientacion.orientacion,
          ),
        )
        .flat(),
    );
  }, [localizacionPlanEstudioNivel]);

  return (
    <>
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        style={{ marginTop: '15px', background: '#F5F5F5', padding: '30px 0' }}
      >
        <Grid
          container
          item
          md={4}
          justifyContent="center"
          alignItems="center"
          style={{
            display: 'inline-grid',
            marginTop: '30px',
          }}
        >
          <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
            Ciclo Lectivo:
          </p>
          <SimpleSelect
            name="CicloLectivo"
            handleChange={(e: any) => {
              const value = e.target.value;
              setFilters((prev) => ({ ...prev, cicloLectivo: value }));
            }}
            value={filters.cicloLectivo}
            placeholder="Seleccioná ciclo lectivo"
            content={filterCicloLectivo}
            labelKey="anio"
            valueKey="idCicloLectivo"
            customStyleContainer={{ width: '20rem' }}
          />
        </Grid>

        <Grid
          container
          item
          md={4}
          justifyContent="center"
          alignItems="center"
          style={{
            display: 'inline-grid',
            marginTop: '30px',
          }}
        >
          <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
            Orientación:
          </p>
          <SimpleSelect
            name="orientacion"
            value={filters.orientacion}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFilters((prev: any) => ({ ...prev, orientacion: value }));
            }}
            placeholder="Todos"
            content={uniquesOrientacionesEscuela}
            labelKey="nombre"
            valueKey="idOrientacion"
            customStyleContainer={{ width: '20rem' }}
          />
        </Grid>
        <Grid
          container
          item
          md={4}
          justifyContent="center"
          alignItems="center"
          style={{
            display: 'inline-grid',
            marginTop: '30px',
          }}
        >
          <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>Sector:</p>
          <SimpleSelect
            name="sector"
            value={filters.sector}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFilters((prev: any) => ({ ...prev, sector: value }));
            }}
            placeholder="Todos"
            content={workingSectores ? [] : sectores}
            labelKey="nombre"
            valueKey="idSector"
            customStyleContainer={{ width: '20rem' }}
          />
        </Grid>
        <Grid
          container
          item
          md={4}
          justifyContent="center"
          alignItems="center"
          style={{
            display: 'inline-grid',
            marginTop: '30px',
          }}
        >
          <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
            Organización:
          </p>
          <SimpleSelect
            name="organizacion"
            value={filters.organizacion}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFilters((prev: any) => ({ ...prev, organizacion: value }));
            }}
            placeholder="Todos"
            content={organizacionesSinDuplicados}
            labelKey="nombre"
            valueKey="idOrganizacion"
            customStyleContainer={{ width: '20rem' }}
          />
        </Grid>
        <Grid
          container
          item
          md={4}
          justifyContent="center"
          alignItems="center"
          style={{
            display: 'inline-grid',
          }}
        >
          <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
            Tipo de acción:
          </p>
          <SimpleSelect
            name="tipoAccion"
            value={filters.tipoAccion}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFilters((prev: any) => ({ ...prev, tipoAccion: value }));
            }}
            placeholder="Todos"
            content={workingTipoAcciones ? [] : tipoAcciones}
            labelKey="nombre"
            valueKey="idTipoAccion"
            customStyleContainer={{ width: '20rem' }}
          />
        </Grid>
        <Grid
          container
          item
          md={4}
          justifyContent="center"
          alignItems="center"
          style={{
            display: 'inline-grid',
          }}
        >
          <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>Acción:</p>
          <SimpleSelect
            name="accion"
            value={filters.accion}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFilters((prev: any) => ({ ...prev, accion: value }));
            }}
            placeholder="Todos"
            content={accionesSinDuplicados}
            labelKey="nombre"
            valueKey="idAccion"
            customStyleContainer={{ width: '20rem' }}
          />
        </Grid>
        <Grid
          container
          item
          md={4}
          justifyContent="center"
          alignItems="center"
          style={{
            display: 'inline-grid',
          }}
        >
          <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
            Turno oferta:
          </p>
          <SimpleSelect
            name="turno"
            value={filters.turnoOferta}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFilters((prev: any) => ({ ...prev, turnoOferta: value }));
            }}
            placeholder="Todos"
            content={
              workingTurnos
                ? []
                : turnos.filter(
                    (turno: any) =>
                      turno.idTurno === 1 ||
                      turno.idTurno === 2 ||
                      turno.idTurno === 4,
                  )
            }
            labelKey="descripcionTurno"
            valueKey="idTurno"
            customStyleContainer={{ width: '20rem' }}
          />
        </Grid>
        <Grid
          container
          item
          md={4}
          justifyContent="center"
          alignItems="center"
          style={{
            display: 'inline-grid',
          }}
        >
          <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
            Turno sección:
          </p>
          <SimpleSelect
            name="turno"
            value={filters.turnoSeccion}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFilters((prev: any) => ({ ...prev, turnoSeccion: value }));
            }}
            placeholder="Todos"
            content={
              workingTurnos
                ? []
                : turnos.filter(
                    (turno: any) =>
                      turno.idTurno === 1 ||
                      turno.idTurno === 2 ||
                      turno.idTurno === 4,
                  )
            }
            labelKey="descripcionTurno"
            valueKey="idTurno"
            customStyleContainer={{ width: '20rem' }}
          />
        </Grid>
        <Grid
          container
          item
          md={4}
          justifyContent="center"
          alignItems="center"
          style={{
            display: 'inline-grid',
          }}
        >
          <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
            Sección:
          </p>
          <SimpleSelect
            name="turno"
            value={filters.seccion}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFilters((prev: any) => ({ ...prev, seccion: value }));
            }}
            placeholder="Todos"
            content={secciones}
            labelKey="nombreSeccion"
            valueKey="idSeccion"
            customStyleContainer={{ width: '20rem' }}
          />
        </Grid>
        <Grid
          container
          item
          md={4}
          justifyContent="center"
          alignItems="center"
          style={{
            display: 'inline-grid',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            component="label"
            style={{ width: '200px', marginTop: '30px' }}
            onClick={() => searchInscripcion()}
            disabled={working}
          >
            {working ? (
              <Loading className={classes.buttonLoading} />
            ) : (
              'Ver inscripciones'
            )}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
