import { TimePicker, TimePickerProps } from '@material-ui/pickers';
import React, { FunctionComponent } from 'react';
import { useField } from 'react-final-form-hooks';
import { getHelperTextNoTranslation } from '../hooks/makeGetHelperText';

export type FormTimePickerProps = {
  form: any;
  name: string;
  label: string;
  clearable?: boolean;
  placeholder?: string;
  disabled?: boolean;
} & Omit<TimePickerProps, 'value' | 'onChange'>;

export const FormTimePicker: FunctionComponent<FormTimePickerProps> = ({
  name,
  clearable = true,
  disabled,
  form,
  label,
  format = '--:--',
  placeholder = '--:--',
  fullWidth = false,
  ...rest
}) => {
  const field = useField(name, form);

  const { error, helperText } = getHelperTextNoTranslation(field.meta);

  return (
    <TimePicker
      value={field.input.value || null}
      clearable={clearable}
      placeholder={placeholder}
      disabled={disabled}
      error={error}
      helperText={helperText}
      onChange={(_date) => field.input.onChange(_date?.format())}
      label={label}
      onFocus={field.input.onFocus}
      onBlur={field.input.onBlur}
      /*     format={format} */
      {...rest}
    />
  );
};
