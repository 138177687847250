import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { JoinedFilters } from '../types';
import { useInputOnChangeValue } from '../../../../../../../../../commons/hooks';

export interface FilterLogicOptions {
  filter: JoinedFilters;
  onChangeFilter: Dispatch<SetStateAction<JoinedFilters>>;
}

export interface FilterSearch {
  search: string;
  onSearchChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface FiltersLogicReturn {
  idRolUsuario: string;
  idRolStatus: string;
  onRolChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onEstadoChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

export const useFiltersLogic = ({
  filter,
  onChangeFilter,
}: FilterLogicOptions): FiltersLogicReturn & FilterSearch => {
  const [search, setSearch] = useState(filter.search);
  const [idRolUsuario, setIdRol] = useState(filter.idRolUsuario);
  const [idRolStatus, setIdEstado] = useState(filter.idRolStatus);
  const onSearchChange = useInputOnChangeValue(setSearch);
  const onRolChange = useInputOnChangeValue(setIdRol);
  const onEstadoChange = useInputOnChangeValue(setIdEstado);

  useEffect(() => {
    const debounce = setTimeout(() => {
      onChangeFilter((oldValue) => ({
        ...oldValue,
        idRolUsuario,
        idRolStatus,
        search,
      }));
    }, 600);
    return () => {
      clearTimeout(debounce);
    };
  }, [search, onChangeFilter, idRolUsuario, idRolStatus]);

  return {
    search,
    idRolStatus,
    idRolUsuario,
    onEstadoChange,
    onSearchChange,
    onRolChange,
  };
};
