import { LibbyFetchDAO } from './LibbyFetchDAO';

export class DomicilioPersonaDAO extends LibbyFetchDAO {
  constructor() {
    super('domicilio_persona', 'idDomicilioPersona');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByPersonaId(id: string) {
    return this.query().equals('persona.idPersona', id).run();
  }
}
