import moment from 'moment';
import { Dias } from '../../Acap/utils';
import { GruposInscripcionAcumulate } from '../types';

const columnHeaders = [
  'Organización',
  'Sector',
  'Tipo de acción',
  'Acción',
  'Turno oferta',
  'Fecha de inicio',
  'Cupo',
  'Referente',
  'Día/Hora inicio/Hora fin',
  '',
  'Apellido y nombre',
  'Documento',
  'Condición',
  'Año',
  'Turno',
  'Jornada',
  'División',
];

export const useInscripcionDetalleExcelData = (
  rows: GruposInscripcionAcumulate[],
  name: string,
) => {
  const rowsForExcel = rows[0].alumnoMovimientos
    ?.sort((a, b) =>
      a?.alumno?.persona?.apellido > b?.alumno?.persona?.apellido ? 1 : -1,
    )
    .map((row, index) => {
      let diaHoraFormatted = '';
      rows[0]?.ofertaGrupo?.jornadas?.forEach(
        (jornada) =>
          (diaHoraFormatted += `${
            Dias[jornada.cupo][0] + Dias[jornada.cupo].slice(1).toLowerCase()
          }: ${moment(jornada.horaInicio, 'HH:mm:ss').format(
            'HH:mm',
          )} - ${moment(jornada.horaFin, 'HH:mm:ss').format('HH:mm')}, `),
      );
      return {
        Organización:
          index === 0
            ? rows[0]?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.organizacion
                ?.nombre
            : '',
        Sector:
          index === 0
            ? rows[0]?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.organizacion
                ?.sector?.nombre
            : '',
        'Tipo de acción':
          index === 0
            ? rows[0]?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.tipoAccion
                ?.nombre
            : '',
        Acción:
          index === 0
            ? rows[0]?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.nombre
            : '',
        'Turno oferta':
          index === 0
            ? rows[0]?.ofertaGrupo?.ofertaTurno?.turno?.descripcionTurno
            : '',
        'Fecha de inicio':
          index === 0
            ? moment(
                rows[0]?.ofertaGrupo?.ofertaTurno?.oferta?.fechaInicio,
              ).format('DD-MM-YYYY')
            : '',
        Cupo:
          index === 0
            ? `${
                rows[0]?.ofertaGrupo?.ofertaTurno?.cupo /
                rows[0]?.ofertaGrupo?.ofertaTurno?.cantGrupos
              }`
            : '',
        Referente:
          index === 0
            ? Boolean(rows[0]?.ofertaGrupo?.referente?.apellido)
              ? `${rows[0]?.ofertaGrupo?.referente?.apellido}, ${rows[0]?.ofertaGrupo?.referente?.nombre}`
              : rows[0]?.ofertaGrupo?.referente?.nombre
            : '',
        'Día/Hora inicio/Hora fin': index === 0 ? diaHoraFormatted : '',
        '': '',
        'Apellido y nombre':
          row?.alumno?.persona?.apellido + ', ' + row?.alumno?.persona?.nombre,
        Documento: row?.alumno?.persona?.documento,
        Condición: row?.alumno?.condicion?.descripcionCondicion,
        Año: row?.seccion?.anio?.descripcionAnio,
        Turno: row?.seccion?.turno?.descripcionTurno,
        Jornada: row?.seccion?.jornada,
        División: row?.seccion?.division,
      };
    });

  return {
    body: {
      columns: columnHeaders,
      data: rowsForExcel,
    },
    name,
  };
};
