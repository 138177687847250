import { sort } from 'ramda';
import moment, { unitOfTime } from 'moment';
import { AnyObject } from 'src/commons';
import TranslateService from 'src/commons/services/translation';
import _ from 'lodash';
import { GruposInscripciones } from 'src/app/models';

type FilterByKeyFunction = (item: any) => boolean;

export function camelize(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export const distinctList = (
  pathToDistinct: string,
  list: AnyObject[],
): AnyObject[] => {
  const newList = list.reduce((prev: AnyObject, elem: AnyObject) => {
    const distinctValue = `${_.get(elem, pathToDistinct)}`;
    prev[distinctValue] = elem;
    return prev;
  }, {});

  return Object.values(newList);
};

export const getFileProperties = async (file: File[], name: string) => {
  const base64: string = await toBase64(file[0]);
  const pieces = base64.split('base64,');
  return {
    file_name: name,
    base64: pieces[pieces.length - 1],
    mime_type: file[0].type,
  };
};

export const filterByKey =
  (query = ''): FilterByKeyFunction =>
  (value) => {
    const blackList = ['id'];
    if (typeof value === 'string') {
      return value.toLowerCase().includes(query.toLowerCase());
    }
    if (typeof value === 'number') {
      return value.toString().toLowerCase().includes(query.toLowerCase());
    }
    if (Array.isArray(value)) {
      return value.filter(filterByKey(query)).length > 0;
    }
    if (typeof value === 'object' && value !== null) {
      // eslint-disable-next-line no-restricted-syntax
      for (const [key, val] of Object.entries(value)) {
        if (!blackList.includes(key) && filterByKey(query)(val)) {
          return true;
        }
      }
      return false;
    }
    return false;
  };

export const orderPending = (data: AnyObject) =>
  data.sort(
    (a: any, b: any) =>
      b?.estadoSolicitudPases?.descripcionSolicitudPase.indexOf('P') -
      a?.estadoSolicitudPases?.descripcionSolicitudPase.indexOf('P'),
  );

export const sortByKey = (key: string) =>
  sort((a: any, b: any) => {
    if (typeof a[key] === 'undefined' || typeof b[key] === 'undefined') {
      return 0;
    }

    let varA = a[key];
    let varB = b[key];

    if (Number.isInteger(+varA) && Number.isInteger(+varB)) {
      varA = +varA;
      varB = +varB;
    }

    if (typeof varA === 'string') {
      varA = a[key].toUpperCase();
      varB = b[key].toUpperCase();
    }
    // eslint-disable-next-line no-nested-ternary
    return varA > varB ? 1 : varA < varB ? -1 : 0;
  });

export const isEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const getFromLocalStorage = (key: string) => {
  const items = localStorage.getItem(key);
  return items ? JSON.parse(items) : [];
};
export const setToLocalStorage = (key: string, items: any) => {
  localStorage.setItem(key, JSON.stringify(items));
  return true;
};
export const removeToLocalStorage = (key: string) => {
  localStorage.removeItem(key);
};

export const getQueryVariable = (key: string) => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  for (let i = 0; i < vars.length; i += 1) {
    const pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === key) {
      return decodeURIComponent(pair[1]);
    }
  }
  return undefined;
};

export const convertNullsToString = (data = {}) =>
  Object.entries(data).reduce(
    (accum, [key, val]) => ({
      ...accum,
      [key]: val === null ? '' : val,
    }),
    {},
  );

export const capitalize = (word: string): string =>
  word
    .trim()
    .split(' ')
    .reduce((prev, current) => {
      if (current !== '') {
        return `${prev ? `${prev} ` : ''}${
          current[0].toUpperCase() + current.slice(1).toLowerCase()
        }`;
      } else {
        return '';
      }
    }, '');

export class Capitalize {
  public text: string;

  constructor(text: string) {
    this.text = text;
  }
  first() {
    return this.text.replace(/[a-z][A-Z]/, (a) => a.toUpperCase());
  }
  every() {
    return this.text.toUpperCase();
  }
  afterSpace() {
    return this.text.replace(/^(.)|\s+(.)/g, (c) => c.toUpperCase());
  }
}

export const formatMoney = (numberToFormat: string) =>
  `$${parseFloat(numberToFormat).toFixed(2)}`;

export const formatDate = (dateToFormat?: Date | string, withTime = true) =>
  moment(dateToFormat).format(`DD-MM-YYYY ${withTime ? ' HH:mm' : ''}`);

export const formatDecimal = (number: string) => parseInt(number, 10);

export const getProperty = (path: string, obj: AnyObject) =>
  path.split('.').reduce((prev, curr) => (prev ? prev[curr] : null), obj);

export const translate = (string: string) => TranslateService.t(string);

export const isSearchIncludedInValue = (
  search: string,
  values: AnyObject,
  properties: Array<string>,
) =>
  search
    .trim()
    .split(' ')
    .every((element: string) =>
      properties.some((property: string) =>
        getProperty(property, values)
          ?.toLowerCase()
          .includes(element?.toLowerCase()),
      ),
    );

export const toBase64 = (file: File): Promise<string> => {
  if (file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () =>
        resolve((reader.result as string).split('base64,')[1]);
      reader.onerror = (error) => reject(error);
    });
  } else {
    return Promise.reject('File debe ser un archivo valido.');
  }
};

export const optionsSort = (Array: any[]) =>
  Array.sort((a, b) => {
    if (a.descripcion !== 'Otros') {
      if (a.descripcion > b.descripcion) {
        return 1;
      }
      return -1;
    }
    return 0;
  });

export const removeAccents = (str: string) => {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
};

export function sortGruposAcap(grupos: GruposInscripciones[]) {
  if (!Array.isArray(grupos) || grupos.length === 0) return [];
  return grupos.sort((a: GruposInscripciones, b: GruposInscripciones) =>
    `${a.alumnoMovimiento.alumno.persona.apellido} ${a.alumnoMovimiento.alumno.persona.nombre}` >
    `${b.alumnoMovimiento.alumno.persona.apellido} ${b.alumnoMovimiento.alumno.persona.nombre}`
      ? 1
      : -1,
  );
}

/**
  Retorna un array con los valores encontrados en ambos arrays
*/
export const getCommonValues = (
  array1: string[],
  array2: string[],
): string[] => {
  const commonValues: string[] = [];

  for (const valor of array1) {
    if (array2.includes(valor)) {
      commonValues.push(valor);
    }
  }

  return commonValues;
};

type comparator =
  | 'isBefore'
  | 'isAfter'
  | 'isSame'
  | 'isSameOrBefore'
  | 'isSameOrAfter';
export const compareDates = (
  dateA: string,
  dateB: string,
  comparator: comparator = 'isSame',
  granularity?: unitOfTime.StartOf,
) => {
  return moment(dateA, 'YYYY-MM-DD')[comparator](
    moment(dateB, 'YYYY-MM-DD'),
    granularity,
  );
};
