import React, { useMemo } from 'react';
import { Radio } from '@material-ui/core';
import { DomicilioEstablecimiento, Localizacion } from '../../../../models';
import { InfoTableColumn } from '../../../../../commons/types';

export const useLocalizacionPickerColumns = (
  value: string,
  onChange: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void | Promise<void>,
) => {
  return useMemo<InfoTableColumn<Localizacion>[]>(
    () => [
      {
        id: 'radio',
        label: '',
        width: '5%',
        hideSortIcon: true,
        style: { textAlign: 'right' },
        render: (nothing, row) => (
          <Radio
            checked={row.idLocalizacion === value}
            onChange={onChange}
            value={row.idLocalizacion}
            name="radio-button-demo"
            inputProps={{ 'aria-label': 'A' }}
          />
        ),
      },
      {
        id: 'cueAnexo',
        label: 'CUE',
        width: '5%',
        orderById: 'cueAnexo',
        render: (cueAnexo: string) => cueAnexo.slice(0, -2),
      },
      {
        id: 'anexo',
        label: 'Anexo',
        width: '2%',
        orderById: 'anexo',
        render: (anexo: string) => anexo.padStart(2, '0'),
      },
      {
        id: 'descripcion',
        label: 'Establecimiento',
        width: '20%',
        orderById: 'descripcion',
      },
      {
        id: 'establecimiento.dependenciaFuncional.descripcion',
        label: 'Dependencia Funcional',
        width: '2%',
        orderById: 'establecimiento.dependenciaFuncional.descripcion',
      },
      {
        id: 'establecimiento.distritoEscolar.nombre',
        label: 'Distrito',
        width: '2%',
        orderById: 'establecimiento.distritoEscolar.nombre',
      },
      {
        id: 'domicilioEstablecimiento',
        label: 'Dirección',
        width: '10%',
        orderById: 'domicilioEstablecimiento.descripcion',
        render: (domicilioEstablecimiento: DomicilioEstablecimiento) =>
          `${domicilioEstablecimiento?.calle} ${domicilioEstablecimiento?.altura} (${domicilioEstablecimiento?.codigoPostal})`,
      },
    ],
    [onChange, value],
  );
};
