export enum BLOCKER_ID {
  // AULAS VIRTUALES
  ADD_CLASSROOMS = 1,
  VIEW_CLASSROOMS,
  // ACTIVIDADES
  VIEW_ACTIVITIES,
  DELETE_ACTIVITIES,
  EDIT_ACTIVITIES,
  CALENDIZE_ACTIVITIES,
  // PLANIFICACIONES
  VIEW_MODULE_PLANIFICATIONS,
  DELETE_PLANIFICATIONS,
  EDIT_PLANIFICATIONS,
  ADD_PLANIFICATIONS,
  VIEW_DISCIPLINE_PLANIFICATIONS,
  VIEW_AREAL_PLANIFICATIONS,
  VIEW_ORIENTED_PLANIFICATIONS,
  VIEW_INTERDISCPIPLINE_PLANIFICATIONS,
  // FEEDBACK PLANIFICACIONES
  ASESORE_APPROVE_PLANIFICATIONS,
  VIEW_FEEDBACK_PLANIFICATIONS,
  // CALIFICACIONES
  VIEW_MODULE_CALIFICATIONS,
  QUALIFY_ESTUDENS,
  VIEW_CALIFICATIONS,
  CALIFICATIONS,
  // REPOSITORIOS
  VIEW_REPOSITORY,
  VIEW_REPOSITORY_ABM,
  ADD_RESOURCE,
  DOWNLOAD_RESOURCE,
  // REGISTRACION
  AUTO_REGISTER,
  REQUEST_ROL_ESTUDIANTE,
  REQUEST_ROL_RESPONSABLE,
  REQUEST_ROL_BUE,
  // GESTION DE USUARIOS
  ROLES_MANAGEMENT,
  USER_MANAGEMENT,
  VIEW_USER_MANAGEMENT,
  VALIDATE_SUPERVISOR_USER,
  VALIDATE_DIRECTIVE_USER,
  VALIDATE_USER_BY_SCHOOL,
  VALIDATE_USER_INTEC,
  ASSIGN_ROLES,
  APPROVE_SUPERVISOR_USER,
  APPROVE_DIRECTIVE_USER,
  APPROVE_USERS,
  REMOVE_SUPERVISOR_ESTABLISHMENT,
  REMOVE_DIRECTIVE_ESTABLISHMENT,
  REMOVE_USERS_ESTABLISHMENT,
  VIEW_USERS,
  EDIT_PROFILE_PERSONAL,
  EDIT_PROFILE_USERS,
  EDIT_PROFILE_ESTUDENDS,
  IMPERSONATING_ROLE,
  // MATRICULACION
  VIEW_MODULE_MATRICULACIONES,
  ADD_SECCION,
  EDIT_SECCION,
  DELETE_SECCIONS,
  ESTABLISHMENT_PASS_REQUEST,
  ESTABLISHMENT_PASS_MANAGEMENT,
  VIEW_PASS_REQUEST,
  ADD_STUDENT,
  EDIT_STUDENT,
  DELETE_STUDENT,
  COPY_SECCION,
  // TABS MATRICULACION
  MATRICULACION_SECCION,
  MATRICULACION_PROMOTION,
  MATRICULACION_PASS_MANAGEMENT,
  MATRICULACION_MATRICULADOS,
  MATRICULACION_EXTERNOS,
  MATRICULACION_GRUPOS,
  MATRICULACION_INSCRIPTOS,
  // CONFIGURATIONS
  CONFIGURATIONS,
  CONFIGURATION_ESPACIO_CURRICULAR,
  CONFIGURATION_ESPACIO_CURRICULAR_BOTON_AGREGAR,
  CONFIGURATION_ESPACIO_CURRICULAR_BOTON_EDITAR,
  CONFIGURATION_ESPACIO_CURRICULAR_BOTON_ELIMINAR,
  // CONFIGURATIONS ADMIN
  CONFIGURATION_ADMIN_USUARIOS,
  CONFIGURATION_ADMIN_USUARIOS_BOTON_ROLES,
  CONFIGURATION_ADMIN_USUARIOS_ELIMINAR_USUARIO,
  CONFIGURATION_ADMIN_USUARIOS_BOTON_REMOVE_ROL,
  CONFIGURATION_ADMIN_USUARIOS_BOTON_EDIT_ROL,
  CONFIGURATION_ADMIN_APROBACION_ROLES,
  CONFIGURATION_ADMIN_MATRICULA,
  // BOLETINES
  BOLETINES,
  // ESPACIOS CURRICULARES
  ESPACIOS_CURRICULARES,
  // COMUNICACIONES
  COMUNICATIONS,
  // GESTION DE PROYECCION
  GESTION_PROYECCION,
  // LEGAJO
  LEGAJO,
  // PRESENTISMO
  PRESENTISMO,
  // REPORTES
  REPORTS,
  //ACAP
  CHECKBOX_OFERTA_ACTIVIDADES_APROXIMACION,
}
