import React from 'react';
import { CommonCheckboxTemplate } from '../../../../../app/models';
import {
  FormInputControlled,
  FormInputControlledProps,
} from '../../../../../lib/templates/components';
import {
  FormDialog,
  MultiCheckboxFormMetadata,
} from '../../../../../commons/services/dialogs';
import { AnyObject } from '../../../../../commons/types';

export const isCommonCheckboxTemplate = (
  value: CommonCheckboxTemplate[] | number[],
): value is CommonCheckboxTemplate[] =>
  !value.length
    ? false
    : (value as CommonCheckboxTemplate[])[0].id !== undefined;

export interface CommonCheckboxFormInputProps
  extends Omit<FormInputControlledProps, 'getInputValue' | 'getNewValue'> {
  dialog: FormDialog<Array<CommonCheckboxTemplate>, MultiCheckboxFormMetadata>;
  options?: AnyObject[];
  optionIdKey?: string;
  optionLabelKey?: string;
  icon?: any;
}

export const CommonCheckboxFormInput = ({
  dialog,
  options = [],
  optionIdKey = 'id',
  optionLabelKey = 'descripcion',
  ...rest
}: CommonCheckboxFormInputProps) => (
  <FormInputControlled
    getInputValue={(value) => {
      if (!Array.isArray(value)) return '';
      return value.map((val) => {
        const item = options.find((option) => option[optionIdKey] === val);
        return !item ? '' : item[optionLabelKey];
      });
    }}
    getNewValue={async (oldValue: number[]) => {
      const formatted: CommonCheckboxTemplate[] = options.map((option) => ({
        id: option[optionIdKey],
        descripcion: option[optionLabelKey],
        checked: !!oldValue.find((val) => val === option[optionIdKey]),
      }));
      const newValue: CommonCheckboxTemplate[] | null = await dialog.open(
        formatted || [],
      );

      return !newValue
        ? oldValue
        : newValue.filter((item) => item.checked).map((item) => item.id);
    }}
    {...rest}
  />
);
