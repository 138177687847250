import {
  FormDialog,
  OpenOptions,
} from '../../../commons/services/dialogs/base/formDialog';
import theme from '../../../theme';
import { LocalizacionPicker } from './LocalizacionPicker';
import { AnyObject, IPickerDialog } from '../../../commons/types';

// TODO: abstraer esto
class LocalizacionPickerDialog implements IPickerDialog {
  private service: FormDialog<string | null>;

  constructor() {
    this.service = FormDialog.create<string | null>({
      customTheme: theme,
      component: LocalizacionPicker,
      title: 'Establecimiento',
      metadata: {},
      confirmButtonText: 'Seleccionar',
      maxWidth: 'lg',
    });
  }

  // This is to simplify the call
  open(
    initialValues: string | null,
    metadata?: AnyObject,
    openOptions?: OpenOptions,
  ) {
    return this.service.open(initialValues, metadata, openOptions);
  }
}

const instance = new LocalizacionPickerDialog();

export { instance as LocalizacionPickerDialog };
