import { isEqual } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { RowCalification } from 'src/app/models';
import { DEFAULT_HIDE_TIME } from 'src/commons/const/ui/snackbar';
import { InformescDAO } from 'src/platform/libby/dao';

export const useCalificacionesInicialSubmit = <T extends RowCalification>({
  seccionId,
  editedRows,
  originalRows: data,
  dao,
  reFetch,
}: {
  seccionId: string;
  editedRows: Array<T>;
  originalRows: Array<T>;
  dao: InformescDAO;
  reFetch: () => void;
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = useCallback(async () => {
    setSubmitting(true);

    const updatedRows = editedRows.filter((row) => {
      let isUpdated = false;
      const currentCalification = data?.find(
        (d: any) => d.idCalificacion === row.idCalificacion,
      );
      if (currentCalification?.nota) {
        isUpdated = !isEqual(currentCalification?.nota?.data, row.nota?.data);
      } else if (row.nota) {
        isUpdated = true;
      }
      return isUpdated;
    });

    try {
      const rowsToSubmit = updatedRows.map((row) => {
        return {
          ...row.nota,
          seccion: seccionId,
        };
      });

      const hasCalification = rowsToSubmit.length > 0;

      if (hasCalification) {
        await dao.save(rowsToSubmit);
      }

      setSubmitting(false);
      reFetch && reFetch();
      enqueueSnackbar('¡Las calificaciones fueron guardadas con éxito!', {
        variant: 'success',
        autoHideDuration: DEFAULT_HIDE_TIME,
      });
    } catch (e) {
      console.log(e);
      setSubmitting(false);
    }
  }, [editedRows, data, reFetch, enqueueSnackbar, seccionId, dao]);

  return {
    onSubmit,
    submitting,
  };
};
