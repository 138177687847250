import { LibbyFetchDAO } from './LibbyFetchDAO';

export class DependenciaFuncionalNivelDAO extends LibbyFetchDAO {
  constructor() {
    super('dependencia_funcional_nivel', 'idDependenciaFuncionalNivel');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
