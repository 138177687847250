import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import React from 'react';
import { MainInput, SimpleSelect } from 'src/commons';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { OrganizacionHeaderProps } from '../../types';

const customStyles = {
  searchGrid: { marginTop: 10 },
  descargar: { marginBottom: '4px' },
  containerStyles: { marginTop: 23 },
};

export const OrganizacionHeader = ({
  filters,
  sectores,
  disableGenerateExcel = false,
  handleCreate,
  handleGenerateExcel,
  handleChangeFilter,
}: OrganizacionHeaderProps) => {
  return (
    <>
      <Grid container direction="column">
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={3}
          style={{ padding: '0 15px 15px', paddingRight: 15, marginTop: 23 }}
        >
          <Grid
            container
            xs={8}
            justifyContent="flex-start"
            spacing={3}
            alignItems="flex-end"
          >
            <Grid item xs={3}>
              <SimpleSelect
                title="Sector"
                name="sector"
                value={filters.sector}
                handleChange={(e) => {
                  const value = e.target?.value ? Number(e.target.value) : 0;
                  handleChangeFilter((prev) => ({ ...prev, sector: value }));
                }}
                placeholder="Seleccioná sector"
                content={sectores}
                labelKey="nombre"
                valueKey="idSector"
              />
            </Grid>
          </Grid>

          <Grid
            container
            xs={4}
            justifyContent="flex-end"
            spacing={3}
            alignItems="flex-end"
            style={customStyles.searchGrid}
          >
            <Grid item xs>
              <MainInput
                type="search"
                handleChange={(e) =>
                  handleChangeFilter((prev) => ({
                    ...prev,
                    search: e.target?.value?.toString(),
                  }))
                }
                placeholder="Buscar"
                name="search"
                value={filters.search}
                fullWidth
              />
            </Grid>

            <Grid item xs style={customStyles.descargar}>
              <Button
                color="primary"
                variant="contained"
                component="label"
                fullWidth
                onClick={handleGenerateExcel}
                disabled={disableGenerateExcel}
              >
                Descargar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          spacing={3}
          style={{ padding: '0 15px 15px', paddingRight: 15, marginTop: 23 }}
        >
          <Typography>Agregar organización</Typography>
          <IconButton onClick={handleCreate}>
            <AddCircleOutlineIcon color="primary" />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};
