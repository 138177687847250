interface OpcionesPaseAnio {
  [option: string]: {
    id: number;
    description: string;
  };
}

export const MAPPED_PASE_ANIO_OPTIONS_PRIMARIO: OpcionesPaseAnio = {
  Promociona: { id: 1, description: 'Promociona' },
  'Promoción directa': { id: 10, description: 'Promoción directa' },
  Permanece: { id: 2, description: 'Permanece con acompañamiento' },
  Egresa: { id: 3, description: 'Egresa' },
  'Promoción en proceso': { id: 4, description: 'Promoción en Proceso' },
  'Promoción acompañada': { id: 5, description: 'Promoción acompañada' },
  'Completa estudios': { id: 6, description: 'Completa estudios' },
  'Continúa con apoyo para la promoción': {
    id: 7,
    description: 'Continúa con apoyo para la promoción',
  },
  'Con promoción acompañada': {
    id: 8,
    description: 'Promociona con promoción acompañada',
  },
  'No completó el Plan de Estudio': {
    id: 9,
    description: 'No completó el Plan de Estudios',
  },
};

export const MAPPED_PASE_ANIO_OPTIONS_SECUNDARIO: OpcionesPaseAnio = {
  Promociona: { id: 1, description: 'Promociona' },
  'Promoción directa': { id: 10, description: 'Promoción directa' },
  Permanece: { id: 2, description: 'Permanece' },
  Egresa: { id: 3, description: 'Completó el Plan de Estudios' },
  'Promoción en proceso': { id: 4, description: 'En proceso' },
  'Promoción acompañada': { id: 5, description: 'Promoción acompañada' },
  'Completa estudios': {
    id: 6,
    description: 'Completa estudios',
  },
  'Continúa con apoyo para la promoción': {
    id: 7,
    description: 'Continúa con apoyo para la promoción',
  },
  'Con promoción acompañada': { id: 8, description: 'Promoción con apoyo' },
  'No completó el Plan de Estudio': {
    id: 9,
    description: 'No completó el Plan de Estudios',
  },
};
