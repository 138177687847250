import React from 'react';
import { Box, Grid } from '@material-ui/core';
import { SimpleSelect, MainInput } from 'src/commons/components';
import { FilterTablesRegisteredAdultsTypesProps } from 'src/commons';
import { grey } from 'src/theme/colors';
import { secondary } from '../../../../../../../theme/colors/index';

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  containerStyles: { marginTop: 23 },
  searchGrid: { marginTop: 11 },
  inputSearch: { marginTop: 20 },
  counterContainer: {
    paddingBottom: 0,
    paddingTop: 19,
    fontSize: 14,
    color: grey.medium,
    fontFamily: 'Open Sans',
  },
};

export const FilterTablesRegistered = ({
  section,
  studyPlanContent,
  formValues,
  search,
  registersCount,
  handleChange,
  handleChangeSearch,
}: FilterTablesRegisteredAdultsTypesProps) => {
  return (
    <>
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        spacing={3}
        style={customStyles.containerStyles}
      >
        {/* <Grid item xs={2}> */}
        {/*  <SimpleSelect */}
        {/*    title="Plan de estudios" */}
        {/*    placeholder="Seleccioná Plan" */}
        {/*    labelKey="descripcionPlanEstudio" */}
        {/*    valueKey="idPlanEstudioNivel" */}
        {/*    content={studyPlanContent} */}
        {/*    handleChange={handleChange} */}
        {/*    value={formValues.planEstudioNivel} */}
        {/*    name="planEstudioNivel" */}
        {/*    customStyleTitle={customStyles.customStyleTitleSelect} */}
        {/*  /> */}
        {/* </Grid> */}
        <Grid item xs={2}>
          <SimpleSelect
            title="Ciclo"
            placeholder="Seleccioná Ciclo"
            labelKey="nombreSeccion"
            valueKey="nombreSeccion"
            content={section}
            handleChange={handleChange}
            value={formValues[`seccion.nombreSeccion`]}
            name="seccion.nombreSeccion"
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
        {/* <Grid */}
        {/*  item */}
        {/*  xs={2} */}
        {/*  justify="flex-start" */}
        {/*  alignItems="flex-end" */}
        {/*  style={customStyles.counterContainer} */}
        {/* > */}
        {/*  <span> */}
        {/*    {`${registersCount} resultados`} */}
        {/*  </span> */}
        {/* </Grid> */}
        <Grid
          container
          xs={10}
          justify="flex-end"
          spacing={3}
          alignItems="flex-end"
        >
          <Grid item xs={6}>
            <MainInput
              type="search"
              handleChange={handleChangeSearch}
              placeholder="Buscar"
              name="search"
              value={search}
              fullWidth
              customStyle={customStyles.inputSearch}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
