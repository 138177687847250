import { CommonEndpointDAO } from './CommonEndpointDAO';

export class ReportDAO extends CommonEndpointDAO {
  constructor() {
    super('reportesquery');
  }

  get(params: any = {}): any {
    return super.get(params);
  }
}
