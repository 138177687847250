import { Button, Grid, Typography } from '@material-ui/core';
import { isEmpty } from 'ramda';
import React, { FunctionComponent, useCallback } from 'react';
import { DatosAcompanante } from 'src/app/models';
import { useFormContext } from 'src/lib/templates';
import { useStyles } from '../styles';
import { AcompananteCard } from './AcompananteCard';

export const Acompanante: FunctionComponent<{ isHistoric: boolean }> = ({
  isHistoric,
}) => {
  const classes = useStyles();
  const { form, values } = useFormContext();

  const addNew = useCallback(() => {
    const newValue = values?.salud?.datosAcompanante;
    newValue.push({
      nombres: '',
      apellido: '',
      telefonoAcompanante: '',
      tipoAcompanante: '',
      ocupaVacante: false,
    });
    form.change('salud', {
      ...values.salud,
      datosAcompanante: [...newValue],
    });
  }, [values.salud, form]);

  const onRemoveItem = useCallback(
    (index: any) => {
      const newInput = values?.salud?.datosAcompanante.filter(
        (item: any, ind: number) => index !== ind,
      );
      form.change('salud', {
        ...values.salud,
        datosAcompanante: [...newInput],
      });
    },
    [values.salud, form],
  );

  const disabledButton = (datosAcompanante: DatosAcompanante[]) => {
    const { nombres, apellido, telefonoAcompanante, tipoAcompanante } =
      datosAcompanante[datosAcompanante.length - 1];

    return (
      isEmpty(nombres) ||
      isEmpty(apellido) ||
      isEmpty(telefonoAcompanante) ||
      isEmpty(tipoAcompanante)
    );
  };

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      className={classes.secondaryContainer}
    >
      <Grid item>
        <Grid item>
          <Typography className={classes.title} variant="h5">
            Acompañante (opcional)
          </Typography>
        </Grid>
        {values.salud.datosAcompanante.map((value: any, index: number) => (
          <AcompananteCard
            key={index}
            index={index}
            onRemoveItem={onRemoveItem}
            isHistoric={isHistoric}
          />
        ))}
      </Grid>
      <Grid item className={classes.containerButton}>
        {!isHistoric && (
          <Button
            variant="outlined"
            onClick={addNew}
            disabled={disabledButton(values?.salud?.datosAcompanante)}
          >
            Agregar Acompañante
          </Button>
        )}
      </Grid>
      <Grid item style={{ height: 75 }}>
        <br />
      </Grid>
    </Grid>
  );
};
