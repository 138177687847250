import React, { useCallback } from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Typography,
} from '@material-ui/core';

export interface CardButtonProps<T> {
  title: string | JSX.Element;
  image?: string;
  data: T;
  onClick: (data: T) => any;
}

export function CardButton<T = any>({
  title,
  image,
  onClick = (props) => {},
  data,
}: CardButtonProps<T>) {
  const onCardClick = useCallback(() => onClick(data), [data, onClick]);
  return (
    <Card>
      <CardActionArea onClick={onCardClick}>
        {!!image && (
          <CardMedia
            component="img"
            alt="Conf image"
            height="200"
            image={image}
          />
        )}
        <CardContent style={{ minHeight: 60 }}>
          {typeof title !== 'string' ? (
            title
          ) : (
            <Typography variant="h1">{title}</Typography>
          )}
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
