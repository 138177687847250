import { Alumno } from 'src/app/models';
import { REQUEST_TYPES } from 'src/lib/libby/hooks/useLibbyRequest';
import { makeEntityLibbyRequest } from '../../../../lib/libby/hooks/makeEntityLibbyRequest';

const DAO_NAME = 'constancia_condicion';

// hooks
export const useGenerarConstanciaPostRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.POST,
);
