import { useEffect, useCallback } from 'react';
import { useTabBarValue } from 'src/commons';
import { useRouterContext } from 'src/lib/router';

export const useSaveTabIndex = () => {
  const { tabIndex } = useTabBarValue();
  const { setMetadata } = useRouterContext();
  const saveTabIndex = useCallback(
    () => setMetadata((prev) => ({ ...prev, fromTab: tabIndex })),
    [setMetadata, tabIndex],
  );
  useEffect(() => saveTabIndex(), [saveTabIndex]);
};
