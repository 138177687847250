import { Box, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo } from 'react';
import { FormInputV2 } from '../../../../../../lib/templates';
import { SliderAccordion } from '../../sliderGroup';
import { useEvaluacionFormContext } from '../../../hooks/useEvaluacionFormContext';
import { SliderAccordionValues } from '../../../types';
import { useEscuelaContext } from '../../../context/EscuelaContext';
import { ROL, useTabBarValue } from '../../../../../../commons';
import { rebuildBloques } from '../../../functions/rebuildBloques';
import { useRolesContext } from 'src/context/RolesContext';

export const Contenidos = () => {
  const { alumnoSelected, setTab } = useEscuelaContext();
  const { form, values } = useEvaluacionFormContext();
  const { tabIndex } = useTabBarValue();
  const {
    selectedRole: { rol },
  } = useRolesContext();

  const unauthorizedUser = [
    ROL.REFERENTE_ACAP,
    ROL.DIRECTIVO,
    ROL.EQUIPO_ACAP,
  ].includes(Number(rol));

  useEffect(() => {
    form.change('tab', '2');
    setTab(tabIndex);
    return () => form.change('tab', '');
  }, [tabIndex, form, setTab]);

  const hanleSliderChange = useCallback<
    (item: SliderAccordionValues, value: number) => void
  >(
    (item, value) => {
      const newValors = values.contenidos.valores.map((valor) => {
        let _newValue = valor;
        if (valor.idTipo.toString() === item.id.toString()) {
          _newValue.evaluacion = value;
        }
        return valor;
      });
      // @ts-ignore
      form.change('contenidos.valores', newValors);
    },
    [form, values.contenidos.valores],
  );

  const conenidosMapped = useMemo(() => {
    if (!alumnoSelected) return [];
    const bloques = rebuildBloques(
      alumnoSelected.contenidos.filter(
        (c) =>
          c.createdBy?.idUsuario !==
          alumnoSelected.alumnoMovimiento.alumno.persona.cuentas.idUsuario,
      )[0]?.valores || [],
    );

    return bloques.map<SliderAccordionValues>((b) => ({
      id: Number(b.idBloque),
      descripcion: b.nombre,
      contenidos: b.ejes.map((e) => ({
        id: e.idEje,
        descripcion: e.nombre,
        contenidos: e.unidades.map((u) => ({
          id: u.idUnidad,
          descripcion: u.nombre,
          contenidos: u.contenidos.map((c) => {
            const valor = values.contenidos.valores.find(
              (v) => v.idTipo.toString() === c.devolucion.idTipo.toString(),
            );
            return {
              id: c.idNodo,
              descripcion: c.descripcion,
              valor: valor?.evaluacion || 0,
            };
          }),
        })),
      })),
    }));
  }, [values, alumnoSelected]);

  return (
    <Grid container component={Box} paddingY={2}>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={6} container justifyContent="center">
          <Grid item component={Box} paddingX={3}>
            <Grid xs={12} container component={Box} spacing={2}>
              {conenidosMapped.map((contenido) => (
                <>
                  <Grid item xs={12} key={contenido.id}>
                    <Typography>{contenido.descripcion}</Typography>
                  </Grid>
                  {contenido.contenidos?.map((_content) => (
                    <>
                      <Grid
                        component={Box}
                        item
                        xs={10}
                        key={_content.id}
                        marginLeft={4}
                      >
                        <Typography>{_content.descripcion}</Typography>
                      </Grid>
                      <SliderAccordion
                        sliders={_content.contenidos || []}
                        onChange={hanleSliderChange}
                      />
                    </>
                  ))}
                </>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} container component={Box} marginTop={4}>
        <Grid item xs={12} sm={2} md={2} lg={1}>
          <Typography
            style={{
              fontWeight: 600,
            }}
          >
            Observaciones:
          </Typography>
        </Grid>

        <Grid item container xs={12} sm={10} md={10} lg={11}>
          <Grid item xs={12} style={{ marginBottom: '10px' }}>
            <FormInputV2
              form={form}
              name={'contenidos.observaciones'}
              variant="outlined"
              multiline
              maxRows={4}
              disabled={unauthorizedUser}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
