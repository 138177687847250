import React, { FunctionComponent } from 'react';
import { createStyles, Grid, IconButton, Tooltip } from '@material-ui/core';
import { FormRenderProps } from 'react-final-form-hooks';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { GetRenderInput } from 'src/lib/templates/components/FormMulti/components/FormMultiField';
import { FormInput } from 'src/lib/templates/components';
import { FormCheckboxDialog } from '../FormModalCheckbox';

const useStyles = makeStyles((theme) =>
  createStyles({
    formContainer: {
      width: 'auto',
      boxSizing: 'border-box',
      padding: 16,
      margin: '20px 0',
      backgroundColor: 'rgba(218, 218, 218, 0.17)', // TODO: Add color from palette
      borderRadius: 4,
    },
  }),
);

interface OwnProps {
  name: string;
  form: FormRenderProps<any>['form'];
  value: any;
  input?: GetRenderInput;
  meta?: any;
  index: number;
}

type Props = OwnProps;

// TODO: esto no se usa en ningun lado. BORRAR
export const FormBimestre: FunctionComponent<Props> = ({
  name,
  form,
  value,
  input,
  meta,
  index,
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={2} className={classes.formContainer}>
      {!!index && (
        <Grid container justify="flex-end" alignItems="flex-end">
          <Tooltip title="Eliminar" arrow>
            <IconButton
              edge="end"
              size="small"
              aria-label="delete"
              onClick={input?.onRemove}
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      )}
      <Grid item xs={4}>
        <FormCheckboxDialog
          form={form}
          label="Habilidad"
          name={`${name}[${index}].habilidad`}
          options={value.habilidad}
        />
      </Grid>
      <Grid item xs={4}>
        <FormCheckboxDialog
          form={form}
          label="Contenidos Especificos"
          name={`${name}[${index}].contenidosEspecificos`}
          options={value.contenidosEspecificos}
        />
      </Grid>
      <Grid item xs={4}>
        <FormCheckboxDialog
          form={form}
          label="Recursos"
          name={`${name}[${index}].recursos`}
          options={value.recursos}
        />
      </Grid>
      <Grid item xs={4}>
        <FormCheckboxDialog
          form={form}
          label="Instrumentos de evaluación"
          name={`${name}[${index}].intrumentosDeEvaluacion`}
          options={value.intrumentosDeEvaluacion}
        />
      </Grid>
      <Grid item xs={4}>
        <FormInput
          label="ActividadLegacy"
          name={`${name}[${index}].actividad`}
          form={form}
        />
      </Grid>
      <Grid item xs={4}>
        <FormInput
          label="Descripción"
          name={`${name}[${index}].descripcion`}
          form={form}
        />
      </Grid>
      <Grid item xs={2}>
        <FormInput
          label="Horas cátedra"
          name={`${name}[${index}].horasCatedra`}
          form={form}
        />
      </Grid>
      <Grid item xs={2}>
        <FormCheckboxDialog
          form={form}
          label="Sección"
          name={`${name}[${index}].seccion`}
          options={value.seccion}
        />
      </Grid>
    </Grid>
  );
};
