import { Nodo } from 'src/app/models/orientaciones';
import { LibbyFetchDAO } from '../LibbyFetchDAO';

export type Response<T> = {
  error: boolean | string;
  meta: {
    data: T[];
    headers?: any;
    status?: number;
  };
};

export class NodosDAO extends LibbyFetchDAO {
  constructor() {
    super('nodos', 'idNodo');
  }

  async getNodosContenidos(): Promise<Response<Nodo>> {
    const data = await this.fetch({
      limit: 2000,
    });
    const hasError = 'nodos' in this.errors;
    return {
      error: hasError ? this.errors.nodos : false,
      meta: hasError
        ? { data: [] }
        : {
            data: data.filter(
              (nodo: Nodo) =>
                Boolean(nodo.orientacion) &&
                Boolean(nodo.bloque) &&
                Boolean(nodo.eje) &&
                Boolean(nodo.unidad) &&
                Boolean(nodo.contenido),
            ),
          },
    };
  }

  async getAllNodos() {
    return this.query()
      .orderBy(
        'bloque.idBloque,eje.idEje,unidad.idUnidad,contenido.idContenido',
      )
      .limit(5000)
      .run();
  }
}
