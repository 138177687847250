import React from 'react';
import { Comentario } from 'src/app/models';
import { Loading } from 'src/commons/components/Loading';
import { NuevoComentarioForm } from './components';
import { InitialValueDefinition } from './components/NuevoComentarioForm';
import { ComentarioEditValue } from './components/ComentarioEditValue';

export const initialValue: InitialValueDefinition = {
  descripcion: '',
};

const fromComentarioToInitialValue = (
  comentario: Comentario,
): InitialValueDefinition => ({
  descripcion: comentario.descripcion,
});

export interface ComentarioFormProps {
  idComentario?: number;
  idPublicacion: number;
  reFetch?: () => void;
}

export const ComentarioForm = ({
  idComentario,
  reFetch,
  idPublicacion,
}: ComentarioFormProps) => {
  return !idComentario ? (
    <NuevoComentarioForm
      initialValues={initialValue}
      reFetch={reFetch}
      idPublicacion={idPublicacion}
    />
  ) : (
    <ComentarioEditValue<InitialValueDefinition>
      idComentario={idComentario}
      transformer={fromComentarioToInitialValue}
    >
      {({ working, data, raw }) =>
        working || !data ? (
          <Loading />
        ) : (
          <NuevoComentarioForm
            initialValues={data}
            raw={raw}
            reFetch={reFetch}
            idPublicacion={idPublicacion}
          />
        )
      }
    </ComentarioEditValue>
  );
};
