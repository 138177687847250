import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { Convivencia } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'convivencia';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idConvivencia',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idConvivencia',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Convivencia
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Convivencia,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Convivencia.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Convivencia>(DAO_NAME, optionsToUse);

// hooks
export const useConvivenciaFetchById = fetchById;
export const useConvivenciaLibbyCall = libbyCall;
export const useConvivenciaDAO = libbyDAO;
export const useConvivenciaLibbyFetch = libbyFetch;

// components/hoc
export const ConvivenciaAutocomplete = entityAutocomplete;
export const ConvivenciaCall = renderPropCall;
export const ConvivenciaById = renderPropFetchById;
export const ConvivenciaSelect = simpleSelect;
export const ConvivenciaAccordionSelector = accordionSelector;
export const ConvivenciaSelectedChips = selectedChips;

// context
// ByIdContext
export const useConvivenciaByIdContext = useByIdContext;
export const ConvivenciaByIdProvider = ByIdProvider;
export const ConvivenciaByIdContext = ByIdContext;
// GeneralContext
export const useConvivenciaContext = useGeneralContext;
export const ConvivenciaProvider = GeneralProvider;
export const ConvivenciaContext = GeneralContext;
