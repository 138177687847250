import { PrimarioCustom } from 'src/app/models';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'primario_custom';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPrimarioCustom',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPrimarioCustom',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.PrimarioCustom
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.PrimarioCustom,
      hoc: { ...defaultOptions.hoc, ...businessOptions.PrimarioCustom.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<PrimarioCustom>(DAO_NAME, optionsToUse);

// hooks
export const usePrimarioCustomFetchById = fetchById;
export const usePrimarioCustomLibbyCall = libbyCall;
export const usePrimarioCustomDAO = libbyDAO;
export const usePrimarioCustomLibbyFetch = libbyFetch;

// components/hoc
export const PrimarioCustomAutocomplete = entityAutocomplete;
export const PrimarioCustomCall = renderPropCall;
export const PrimarioCustomById = renderPropFetchById;
export const PrimarioCustomSelect = simpleSelect;
export const PrimarioCustomAccordionSelector = accordionSelector;
export const PrimarioCustomSelectedChips = selectedChips;

// context
// ByIdContext
export const usePrimarioCustomByIdContext = useByIdContext;
export const PrimarioCustomByIdProvider = ByIdProvider;
export const PrimarioCustomByIdContext = ByIdContext;
// GeneralContext
export const usePrimarioCustomContext = useGeneralContext;
export const PrimarioCustomProvider = GeneralProvider;
export const PrimarioCustomContext = GeneralContext;
