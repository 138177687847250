import { LibbyFetchDAO } from './LibbyFetchDAO';

export class PropositosBloquesDAO extends LibbyFetchDAO {
  constructor() {
    super('propositos_bloques', 'idPropositoBloque');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
