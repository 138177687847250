import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Dependencia } from '../interface/SwitchItem';
import { AntSwitch } from './AntSwicth';

export const NestedSwitchList = ({ items = [] }: { items: Dependencia[] }) => {
  return (
    <Grid container>
      {items.map((item) => (
        <Grid
          key={item.configuracion.idConfirmacionMatricula}
          container
          alignItems="center"
        >
          <Grid item xs={8}>
            <Typography variant="body2" color="textPrimary">
              {item.descripcion}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <AntSwitch
              edge="end"
              name={item.configuracion.idConfirmacionMatricula.toString()}
              inputProps={{
                'aria-labelledby': `SwitchItem-${item.descripcion.toLowerCase()}`,
              }}
              color="primary"
              checked={item.checked}
              onChange={item.onSwicth && item.onSwicth(item)}
              /*       disabled={item.disabled} */
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};
