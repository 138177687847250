import React from 'react';

export class ButtonConfig {
  title: React.ReactNode;
  disabled?: boolean;
  typeButton?: string;
  size?: string;
  handleOnClick?: () => void;
  icon?: JSX.Element;
  type?: string;
  customStyle?: any;
  customStyleIcon?: any;
  isLink?: boolean;

  constructor(title: string, type?: string, size = 'medium') {
    this.title = title;
    this.type = type;
    this.size = size;
  }

  setClickHandler(fn: () => void) {
    this.handleOnClick = fn;
  }

  disabledFor(condition: boolean) {
    this.disabled = condition;
  }
}
