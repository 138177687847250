import { makeEntityLibbyCall } from '../../../lib/libby/hooks/makeEntityLibbyCall';
import { makeEntityLibbyDAO } from '../../../lib/libby/hooks/makeEntityLibbyDAO';
import { UsuariosRolesDAO } from 'src/platform/libby/dao';

const DAO_NAME = 'usuariosroles';

// hooks
export const useUsuariosRolesLibbyCall = makeEntityLibbyCall(DAO_NAME);
export const useUsuariosRolesDAO =
  makeEntityLibbyDAO<UsuariosRolesDAO>(DAO_NAME);
