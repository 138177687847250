import { Rules } from 'src/lib/permission/types';
import { TABBAR_BLOCKER_ID, SIDEMENU_ID, BLOCKER_ID } from '../../const';

export const primario: Rules = {
  idRule: 'nivel-2',
  routes: [{ key: 'informesc' }],
  blockers: [],
  sideMenu: [{ id: SIDEMENU_ID.INFORMESC }],
  tabBars: [
    { id: TABBAR_BLOCKER_ID.ESPACIO_CURRICULAR_PLANIFICACION_TAB },
    { id: TABBAR_BLOCKER_ID.ESPACIO_CURRICULAR_ACTIVIDADES_TAB },
    { id: TABBAR_BLOCKER_ID.PROMOCION_ACOMPANIADA },
  ],
};
