import React, { useMemo } from 'react';
import { calculatedIconFilling } from 'src/commons/components';

export const useProyeccionDialogColumns = () => {
  const columns = useMemo(() => {
    return [
      {
        id: 'apellidoNombre',
        label: 'Apellido y Nombre',
        render: (value: any, row: any) =>
          `${row.alumno.persona.apellido} ${row.alumno.persona.nombre}`,
        width: '12%',
        orderById: 'alumno.persona.apellido',
        hideSortIcon: true,
      },
      {
        id: 'doc',
        label: 'Documento',
        render: (value: any, row: any) => row.alumno.persona.documento,
        orderById: 'alumno.persona.documento',
        hideSortIcon: true,
      },
      {
        id: 'anio',
        label: 'Año',
        render: (value: any, row: any) =>
          row.seccionOrigen?.anio?.descripcionAnio,
        orderById: 'seccionOrigen.anio.idAnio',
        hideSortIcon: true,
      },
      {
        id: 'turno',
        label: 'Turno',
        render: (value: any, row: any) =>
          row.seccionOrigen?.turno?.descripcionTurno,
        width: '5%',
        hideSortIcon: true,
      },
      {
        id: 'jornada',
        label: 'Jornada',
        render: (value: any, row: any) => row.seccionOrigen?.jornada,
        width: '5%',
        hideSortIcon: true,
      },
      {
        id: 'seccion',
        label: 'Sección origen',
        render: (value: any, row: any) => row.seccionOrigen?.nombreSeccion,
        hideSortIcon: true,
      },
      {
        id: 'paseDeAnio',
        label: 'Pase de Año',
        width: '10%',
        hideSortIcon: true,
        render: (value: any, row: any) => (
          <>
            {calculatedIconFilling(
              row.estadoPaseAnio?.descripcionEstadoPaseAnio === 'Promociona',
            )}
            {row.estadoPaseAnio?.descripcionEstadoPaseAnio}
          </>
        ),
      },
    ];
  }, []);

  return columns;
};
