import { CommonEndpointDAO } from '../CommonEndpointDAO';

export class ConfirmacionMatriculaCountDAO extends CommonEndpointDAO {
  constructor() {
    super('confirmacion_matricular_count');
  }

  getInfoCount(): Promise<Matriculacion.ConfirmacionMatriculaCountResponse> {
    return super.post({});
  }
}
