import { Grid, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import _ from 'lodash';
import React, { useState } from 'react';
import { MainInput, SimpleSelect, Loading } from 'src/commons';
import { useInputValidator } from '../hooks/useInputValidator';

export const JornadaComponent = ({
  index,
  indexGrupo,
  jornadasValues,
  handleChangeGrupo,
  jornadas,
  organizacion,
  setJornadasValues,
  borrarJornada,
}: any) => {
  const [inputValidator, error] = useInputValidator();
  const [loading, setLoading] = useState(false);

  const handleChangeJornada = (e: any) => {
    const clonnedJornadasValues = _.cloneDeep(jornadasValues);

    if (clonnedJornadasValues.length - 1 >= index) {
      clonnedJornadasValues[index][e.target.name] = e.target.value;
    } else {
      clonnedJornadasValues.push({ [e.target.name]: e.target.value });
    }

    const mimicEvent = {
      target: {
        value: clonnedJornadasValues,
        name: 'jornadas',
      },
    };
    handleChangeGrupo(mimicEvent);
    setJornadasValues(clonnedJornadasValues);
  };

  const allSedes = organizacion?.sedes.map((sede: any) => {
    if (sede?.nombre !== null) {
      return { ...sede, label: `${sede.nombre}, ${sede.direccion}` };
    } else {
      return { ...sede, label: sede.direccion };
    }
  });

  return (
    <>
      <Grid md={12} style={{ paddingLeft: '15px' }}>
        {loading ? (
          <Loading />
        ) : (
          <Grid container>
            <Grid container item md={3} alignItems="center">
              <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                <IconButton
                  onClick={async () => {
                    setLoading(true);
                    await borrarJornada(
                      index,
                      jornadasValues[index],
                      indexGrupo,
                    );
                    setLoading(false);
                  }}
                  style={{
                    visibility:
                      jornadasValues.length > 1 ? 'visible' : 'hidden',
                  }}
                >
                  <DeleteIcon color="primary" />
                </IconButton>{' '}
                Día:{' '}
                <span style={{ color: 'red' }}>
                  {jornadasValues[index]?.cupo ||
                  jornadasValues[index]?.cupo === 0
                    ? ''
                    : '*'}
                </span>
              </p>
              <SimpleSelect
                name="cupo"
                value={jornadasValues[index]?.cupo}
                handleChange={(e: any) => {
                  const jornadasCheck = jornadasValues.filter(
                    (jornada: any) => jornada?.cupo === e.target.value,
                  );
                  if (!jornadasCheck.length) {
                    handleChangeJornada(e);
                  }
                }}
                placeholder="Seleccioná día"
                content={jornadas?.length > 0 ? jornadas : []}
                labelKey="descripcion"
                valueKey="cupo"
                style={{
                  width: '30em',
                }}
              />
            </Grid>
            <Grid container item md={3} alignItems="center">
              <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                Hora inicio:{' '}
                <span style={{ color: 'red' }}>
                  {jornadasValues[index]?.horaInicio ? '' : '*'}
                </span>
              </p>
              <MainInput
                value={jornadasValues[index]?.horaInicio}
                name="horaInicio"
                handleChange={(e) => {
                  if (e.target.value > jornadasValues[index]?.horaFin) {
                    let jornadasValuesCopy = jornadasValues;
                    jornadasValuesCopy[index].horaFin = e.target.value;
                    setJornadasValues(jornadasValuesCopy);
                  }
                  handleChangeJornada(e);
                }}
                placeholder=""
                type="time"
                fullWidth
              />
            </Grid>
            <Grid container item md={3} alignItems="center">
              <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                Hora fin:{' '}
                <span style={{ color: 'red' }}>
                  {jornadasValues[index]?.horaFin ? '' : '*'}
                </span>
              </p>
              <MainInput
                value={jornadasValues[index]?.horaFin}
                name="horaFin"
                disabled={!Boolean(jornadasValues[index]?.horaInicio)}
                handleChange={(e) => {
                  handleChangeJornada(e);
                }}
                error={
                  jornadasValues[index]?.horaFin
                    ? jornadasValues[index]?.horaFin <
                      jornadasValues[index]?.horaInicio
                    : false
                }
                helperText={'La hora de fin debe ser mayor a la de inicio'}
                placeholder=""
                type="time"
                fullWidth
              />
            </Grid>
            <Grid container item md={3} alignItems="center">
              <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                Sede:{' '}
                <span style={{ color: 'red' }}>
                  {jornadasValues[index]?.sede?.idSede ||
                  jornadasValues[index]?.sede
                    ? ''
                    : '*'}
                </span>
              </p>
              <SimpleSelect
                name="sede"
                value={
                  jornadasValues[index]?.sede?.idSede ||
                  jornadasValues[index]?.sede
                }
                handleChange={(e: any) => {
                  handleChangeJornada(e);
                }}
                placeholder="Seleccioná sede"
                content={allSedes?.length > 0 ? allSedes : []}
                labelKey="label"
                valueKey="idSede"
                style={{
                  width: '30em',
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};
