import { useState, useEffect, useMemo } from 'react';
import { useCalificacionesRecuperacionLibbyFetch } from 'src/app/business';
import { useCalificacionesSecundarioContext } from '../context/CalificacionesSecundarioContext';
import { useGetRows } from './useGetRows';
import { CalificacionesFilter } from './useInitSecundario';
import {
  RecuperacionFactory,
  RecuperacionResponse,
} from '../../CalificacionesSecundario/factories/RecuperacionFactory';
import { setSearchParamsMultipleWords } from 'src/utils/setSearchParamsMultipleWords';

export const useRecuperacionesRows = (
  filter: CalificacionesFilter,
  ecs: string,
  ecsWorking: boolean,
) => {
  const [readyToClose, setReadyToClose] = useState<boolean>(false);

  const filterParams = useMemo(
    () => ({
      0: [
        {
          path: 'espacioCurricularSeccion',
          value: ecs ?? filter.espacioCurricularSeccion,
        },
      ],
      1: [
        {
          path: 'periodo.idPeriodo',
          value: [14, 15],
          method: 'in',
        },
      ],
      ...(Boolean(filter.search) &&
        setSearchParamsMultipleWords(
          ['alumno.persona.nombre', 'alumno.persona.apellido'],
          filter.search,
        )),
    }),
    [filter, ecs],
  );

  const fetchResponse = useCalificacionesRecuperacionLibbyFetch({
    aspect: 'recuperacion-response',
    filter: filterParams,
    limit: 1000,
    checkDuplicationDeep: true,
    enabled:
      Boolean(filter.espacioCurricularSeccion) || (Boolean(ecs) && !ecsWorking),
  });

  const {
    rows: _rows = [],
    working,
    reFetch,
    hasCalifications,
  } = useGetRows(fetchResponse);

  const isClosed = useMemo(
    () =>
      _rows.some((row: RecuperacionResponse) =>
        [row.abiertoDiciembre, row.abiertoFebreroMarzo].includes(false),
      ),
    [_rows],
  );

  const { factory } = useCalificacionesSecundarioContext<RecuperacionFactory>();

  useEffect(() => {
    const isInvalidToClose = _rows.some(
      (row) =>
        [undefined, ''].includes(row.febreroMarzo) &&
        [0].includes(Number(row.diciembre)),
    );
    setReadyToClose(!isInvalidToClose);
  }, [_rows, factory]);

  const rows = factory.getRows(_rows);

  return {
    rows,
    working,
    _rows,
    reFetch,
    readyToClose,
    isClosed,
    hasCalifications,
    ecs,
  };
};
