import { Box, Button, Divider, Grid, makeStyles } from '@material-ui/core';
import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
} from 'react';
import { JustificacionMotivoSelectInput } from '../../../../../app/business/presentismo_v3';
import {
  ESTADO_PRESENTE,
  Loading,
  RAZON_JUSTIFICACION,
  TURNOS_JUSTIFICACION,
} from '../../../../../commons';
import {
  FormInputV2,
  FormGenericControlV2,
  FormTimePicker,
  FormMultiCheckbox,
} from '../../../../../lib/templates';
import {
  useJustificacionFormContext,
  useJustificacionLogic,
  usePreSelectTurno,
} from '../hooks';
import { DateRangeComponent, DateRanges } from './DateRangeComponent';
import { FormDatePickerV2 } from '../../../../../lib/templates/components/FormDatePickerV2';
import { JustificationRazonSelectInput } from '../../../../../app/business/presentismo_v3/JustificacionRazon';
import { FormFileInput } from './FileInput';
import { AttachFileView } from './AttachFile';
import { ArrowForward } from '@material-ui/icons';
import { usePresenteV3LibbyFetch } from '../../../../../app/business/presentismo_v3/Presente';
import { filterAusencias } from '../JustificacionFilter';
import { useGetJustificationRange } from '../hooks/useGetJustificationRange';
import moment from 'moment';
import { ContraturnoDia, Presente_v3 } from '../../../../../app/models';
import { validateFiles, validateHasta } from '../validators';
import { ESTADOS_PRESENTISMO } from '../../TomaPresentismo/utils/EstadoPresente';
import ConfirmDialog from '../../../../../commons/components/ConfirmDialog';
import { RangoFecha, TurnoOptions } from '../types';
import { AlumnoMap } from '../../TomaPresentismo/types';
import { useRazonChange } from '../hooks/useRazonChange';
import { filtroPresentismoContraturno } from '../utils/filtroPresentismoContraturno';
import _ from 'lodash';

const FEDERADO_MOTIVO_ID = '8';

interface JustificacionFormProps {
  isJornadaExtendida: boolean;
  loading?: boolean;
  data: AlumnoMap;
  selectedDay: moment.Moment;
  turnosOptions: TurnoOptions[];
  openConfirmDialog: boolean;
  contraturnosOptions: TurnoOptions[];
  setAusencias: React.Dispatch<React.SetStateAction<Presente_v3[]>>;
  handleClose: () => void;
  onNext: () => void;
  onCancel: () => void;
  setOpenConfirmDialog: React.Dispatch<React.SetStateAction<boolean>>;
  contraturnosDia: ContraturnoDia[];
  refreshContraturnos: (range: RangoFecha) => void;
}

const useStyles = makeStyles({
  daterange: {
    '& > .rdrMonth': {
      width: '95%',
    },
    '& > .rdrDefinedRangesWrapper': {
      display: 'none',
      fontFamily: '"Open Sans", sans-serif',
    },
    '& > .rdrCalendarWrapper': {
      fontFamily: 'sans-serif',
      textTransform: 'capitalize',
      height: '270px',
      width: '100%',
    },
    '& .rdrInfiniteMonths': {
      height: '240px !important',
    },
    width: '100%',
  },
  selectLabel: {
    fontSize: 16,
    color: '#38485C',
    fontWeight: 'bold',
    fontFamily: 'Nunito',
  },
  selectInput: {
    backgroundColor: '#F3F6F9',
    border: 'none',
    '& > fieldset': {
      border: 'none',
    },
  },
  selectBg: {
    backgroundColor: '#F3F6F9',
  },
  datePickerLabel: {
    fontSize: 16,
    color: '#8A98A8',
    fontWeight: 'bold',
    fontFamily: 'Nunito',
    top: -13,
  },
  datePickerInput: {
    backgroundColor: '#F3F6F9',
    border: 'none',
    height: 50,
    display: 'flex',
    alignItems: 'flex-end',
    '& > fieldset': {
      border: 'none',
    },
    '& > input': {
      padding: '2px 6px',
    },
  },
  datePickerRoot: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
  datePickerContainer: {
    padding: '0x !important',
    height: 33,
  },
  saveButton: {
    backgroundColor: '#3786EE',
    fontWeight: 'bold',
    textTransform: 'none',
    color: 'white',
    '&:hover': {
      backgroundColor: '#3786EE',
      color: 'white',
      fontWeight: 'bold',
      textTransform: 'none',
    },
    '&:disabled': {
      backgroundColor: '#E6EBF0',
      color: '#38485C99',
    },
  },
  disabledButton: {
    opacity: '0.6',
    textTransform: 'none',
  },
  cancelButton: {
    color: '#3786EE',
    textTransform: 'none',
    backgroundColor: '#F5F9FC',
    fontWeight: 'bold',
  },
  nextStudent: {
    color: '#3786EE',
    textTransform: 'none',
    backgroundColor: '#FFFF',
    borderColor: '#3786EE',
    fontWeight: 'bold',
  },
  fileResumeContainer: {
    width: '96.44%',
    marginTop: 3,
    padding: 8,
    borderRadius: 4,
    border: '1px solid gray',
    backgroundColor: 'rgb(237 244 252)',
  },
});

// La key es el id de la razon y el value es el estadoPresente
export const razonJustificacionEstadoPresente: Record<string, number> = {
  '1': 2,
  '2': 7,
  '3': 6,
  '4': 8,
};

const isValidAusenciaJornada = (
  idEstadoPresente: number,
  isJornadaExtendida: boolean,
) => {
  if (isJornadaExtendida) {
    return idEstadoPresente === ESTADO_PRESENTE.AUSENTE;
  } else {
    return (
      idEstadoPresente === ESTADO_PRESENTE.AUSENTE ||
      idEstadoPresente === ESTADO_PRESENTE.RETIRO_ANTICIPADO ||
      idEstadoPresente === ESTADO_PRESENTE.INGRESO_TARDIO ||
      idEstadoPresente === ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE
    );
  }
};

export const getColor = (ausencia: Presente_v3) => {
  if (ausencia.justificacion) return 'BLUE';
  const presente = ESTADOS_PRESENTISMO.find(
    (ep) => ep.idEstadoPresente === ausencia.estadoPresente.idEstadoPresente,
  );
  if (!presente) return 'WHITE';
  return presente?.color;
};

const JustificacionForm = forwardRef<
  { presentismosAll: Presente_v3[] } | undefined,
  JustificacionFormProps
>(
  (
    {
      data,
      isJornadaExtendida,
      contraturnosOptions,
      turnosOptions,
      loading,
      onNext,
      setOpenConfirmDialog,
      openConfirmDialog,
      handleClose,
      setAusencias,
      refreshContraturnos,
    },
    ref,
  ) => {
    const classes = useStyles();
    const { form, values, errors, valid, handleSubmit } =
      useJustificacionFormContext();

    useJustificacionLogic();
    // useTurnosLogic();
    useRazonChange();

    const filter = useMemo(
      () =>
        filterAusencias({
          idAlumno: data.alumno.idAlumno,
          turnos: values.turno,
          fechaDesde: values.fechaDesde,
          fechaHasta: values.fechaHasta,
          isJornadaExtendida,
          contraturnos: values.contraturno,
        }),
      [data.alumno.idAlumno, values, isJornadaExtendida],
    );

    const { data: presentismosAll = [], working: PresentismoAllWorking } =
      usePresenteV3LibbyFetch({
        filter,
        aspect: 'basic',
        limit: 20000,
      });

    const { justificationRange } = useGetJustificationRange();

    const justificationReasonFilter = useMemo(
      () =>
        !isJornadaExtendida
          ? {}
          : {
              estadoPresente: [
                {
                  path: 'estadoPresente.idEstadoPresente',
                  value: ESTADO_PRESENTE.AUSENTE,
                },
              ],
            },
      [isJornadaExtendida],
    );

    const commomPropsSelect = useMemo(() => {
      return {
        InputLabelProps: {
          shrink: true,
          className: classes.selectLabel,
        },
        InputProps: {
          className: classes.selectInput,
          disableUnderline: true,
        },
        SelectProps: { classes: { root: classes.selectBg } },
      };
    }, [classes]);

    const onRasonChange = useCallback(() => {
      form.restart();
    }, [form]);

    const firstStepIsValid = useMemo(
      () =>
        !(
          !values.razonJustificacion ||
          !values.fechaDesde ||
          !values.fechaHasta ||
          !(values.turno || values.contraturno)
        ),
      [values],
    );

    const ausencias = useMemo(
      () =>
        values.razonJustificacion !== ''
          ? presentismosAll.filter(
              (presente) =>
                presente.estadoPresente.idEstadoPresente.toString() ===
                razonJustificacionEstadoPresente[
                  values.razonJustificacion
                ]?.toString(),
            )
          : presentismosAll,
      [presentismosAll, values.razonJustificacion],
    );

    const justificationDates = useMemo(() => {
      const agrupadoPorFecha = _.groupBy(ausencias, 'fecha');
      return Object.values(agrupadoPorFecha);
    }, [ausencias]);

    const _turnosOptions = useMemo(() => {
      return turnosOptions.map((turno) => {
        if (justificationDates.length > 1) {
          return { ...turno };
        } else if (
          turno.value !== TURNOS_JUSTIFICACION.TURNO_AMBOS.toString()
        ) {
          let uno = ausencias.some(
            (ausente) =>
              Number(ausente.turno?.idTurno) ===
              TURNOS_JUSTIFICACION.TURNO_MANANA,
          );
          let dos = ausencias.some(
            (ausente) =>
              Number(ausente.turno?.idTurno) ===
              TURNOS_JUSTIFICACION.TURNO_TARDE,
          );

          return {
            ...turno,
            disabled:
              uno && dos
                ? true
                : turno.value ===
                    TURNOS_JUSTIFICACION.TURNO_MANANA.toString() && uno
                ? false
                : turno.value === TURNOS_JUSTIFICACION.TURNO_TARDE.toString() &&
                  dos
                ? false
                : true,
          };
        } else {
          let uno = ausencias.some(
            (ausente) =>
              Number(ausente.turno?.idTurno) ===
              TURNOS_JUSTIFICACION.TURNO_MANANA,
          );
          let dos = ausencias.some(
            (ausente) =>
              Number(ausente.turno?.idTurno) ===
              TURNOS_JUSTIFICACION.TURNO_TARDE,
          );
          return { ...turno, disabled: !(uno && dos) };
        }
      });
    }, [turnosOptions, ausencias, justificationDates.length]);

    const _contraturnosOptions: TurnoOptions[] = useMemo(() => {
      return filtroPresentismoContraturno(contraturnosOptions, ausencias);
    }, [contraturnosOptions, ausencias]);

    const isFormInvalid = useMemo(() => {
      return (
        !valid ||
        Object.keys(errors as any).length !== 0 ||
        (values.razonJustificacion !== RAZON_JUSTIFICACION.AUSENTE.toString() &&
          !values.horaSalida) ||
        (values.razonJustificacion === RAZON_JUSTIFICACION.AUSENTE.toString() &&
          !values.files.length) ||
        (ausencias.length === 0 && !PresentismoAllWorking) ||
        (values.contraturno.length === 0 && values.turno.length === 0)
      );
    }, [
      valid,
      errors,
      values.razonJustificacion,
      values.horaSalida,
      values.files.length,
      ausencias.length,
      PresentismoAllWorking,
      values.contraturno,
      values.turno,
    ]);
    const presentismos = useMemo(
      () =>
        presentismosAll.filter(
          (presente) => presente?.justificacion?.idJustificacion,
        ),
      [presentismosAll],
    );

    const ausenciasRange = useMemo<DateRanges[]>(() => {
      return ausencias
        .filter((ausencia) =>
          isValidAusenciaJornada(
            ausencia.estadoPresente.idEstadoPresente,
            isJornadaExtendida,
          ),
        )
        .map((ausencia) => ({
          startDate: moment(ausencia.fecha).toDate(),
          endDate: moment(ausencia.fecha).toDate(),
          key: 'indicator',
          color: getColor(ausencia),
        }));
    }, [ausencias, isJornadaExtendida]);

    const rangesCalendar = useMemo<DateRanges[]>(() => {
      return values.fechaDesde && values.fechaHasta
        ? [
            {
              startDate: moment(values.fechaDesde).toDate(),
              endDate: moment(values.fechaHasta).toDate(),
              key: 'selection',
              color: 'WHITE',
            },
            ...ausenciasRange,
          ]
        : ausenciasRange;
    }, [values.fechaDesde, values.fechaHasta, ausenciasRange]);

    const filterMotivoJustificacion = useMemo(
      () =>
        values.contraturno
          ? {}
          : {
              federado: [
                {
                  path: 'idMotivoJustificacion',
                  value: FEDERADO_MOTIVO_ID,
                  method: 'notEquals',
                },
              ],
            },
      [values.contraturno],
    );

    const onDeleteFile = useCallback<(data: File) => void>(
      (data) => {
        const copy = [...values.files];
        const newFiles = copy.filter((file: File) => file.name !== data.name);
        form.change('files', [...newFiles] as any);
      },
      [values.files, form],
    );

    const onSubmit = useCallback(() => {
      setAusencias(ausencias);
      setTimeout(() => handleSubmit(), 500);
    }, [ausencias, setAusencias, handleSubmit]);

    const onConfirmDialog = useCallback(() => {
      form.restart();
      setOpenConfirmDialog(false);
    }, [setOpenConfirmDialog, form]);

    useEffect(() => {
      if (values.fechaDesde && values.fechaHasta) {
        refreshContraturnos({
          fechaDesde: values.fechaDesde,
          fechaHasta: values.fechaHasta,
        });
      }
    }, [values.fechaDesde, values.fechaHasta, refreshContraturnos]);

    usePreSelectTurno({ values, presentismosAll, contraturnosOptions, form });

    useImperativeHandle(ref, () => ({
      presentismosAll,
    }));
    return (
      <Grid container>
        <Grid item container id="header" xs={12}></Grid>
        <Grid
          item
          container
          direction="row"
          justifyContent="space-between"
          id="body"
        >
          <Grid item xs={3} container spacing={3}>
            <Grid item xs={12}>
              <Grid
                item
                component={Box}
                height={10}
                paddingBottom={3}
                fontFamily="Nunito"
                fontSize={15}
                fontWeight="bold"
                color="#38485C"
              >
                ¿Qué deseas justificar?
              </Grid>
              <FormGenericControlV2
                name="razonJustificacion"
                form={form}
                component={JustificationRazonSelectInput}
                optionToValue={(value) => {
                  onRasonChange();
                  return value.toString();
                }}
                valueToOption={(value) => Number(value)}
                componentProps={{
                  filter: justificationReasonFilter,
                  fullWidth: true,
                  ...commomPropsSelect,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <DateRangeComponent
                ranges={rangesCalendar}
                presentismos={presentismosAll}
              />
            </Grid>
          </Grid>
          <Grid item xs={4} container spacing={3}>
            <Grid item component={Box} minHeight={50}>
              <Grid
                item
                component={Box}
                height={10}
                paddingBottom={3}
                fontFamily="Nunito"
                fontSize={15}
                fontWeight="bold"
                color="#38485C"
              >
                Fecha
                {ausencias.length === 0 && !PresentismoAllWorking ? (
                  <span style={{ color: '#f44336', fontSize: '10px' }}>
                    {' '}
                    * En dicha fecha no hay estado para justificar
                  </span>
                ) : null}
              </Grid>
              <Grid
                item
                container
                component={Box}
                height={53}
                bgcolor="#F3F6F9"
                borderRadius={5}
                /*    border={errors?.fechaDesde || errors?.fechaHasta ? "1px solid red" : undefined} */
              >
                <Grid item xs={5}>
                  <FormDatePickerV2
                    name="fechaDesde"
                    form={form}
                    fullWidth
                    label="Desde"
                    InputLabelProps={{
                      className: classes.datePickerLabel,
                      shrink: false,
                    }}
                    InputProps={{
                      className: classes.datePickerInput,
                      disableUnderline: true,
                    }}
                    SelectProps={{ classes: { root: classes.selectBg } }}
                    disabled={values.razonJustificacion === ''}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  container
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: 50 }}
                >
                  <Divider
                    variant="middle"
                    orientation="vertical"
                    style={{ height: 30 }}
                  />
                </Grid>
                <Grid item xs={5}>
                  <FormDatePickerV2
                    name="fechaHasta"
                    form={form}
                    label="Hasta"
                    disabled={values.razonJustificacion === ''}
                    helperText={
                      presentismos.filter(
                        (p) =>
                          p?.turno?.idTurno.toString() ===
                            values.turno.toString() ||
                          p?.contraturnoDia?.idContraturnoDia.toString() ===
                            values.contraturno.toString(),
                      ).length && firstStepIsValid
                        ? `Ya existe una justificacion para esta fecha en este turno`
                        : undefined
                    }
                    InputLabelProps={{
                      className: classes.datePickerLabel,
                      shrink: false,
                    }}
                    InputProps={{
                      className: classes.datePickerInput,
                      disableUnderline: true,
                    }}
                    SelectProps={{ classes: { root: classes.selectBg } }}
                    validator={validateHasta(justificationRange)}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid
                item
                component={Box}
                height={10}
                paddingBottom={3}
                fontFamily="Nunito"
                fontSize={15}
                fontWeight="bold"
                color="#38485C"
              >
                Motivo *
              </Grid>
              <FormGenericControlV2
                name="motivoJustificacion"
                form={form}
                component={JustificacionMotivoSelectInput}
                optionToValue={(value) => value.toString()}
                valueToOption={(value) => Number(value)}
                componentProps={{
                  fullWidth: true,
                  ...commomPropsSelect,
                  disabled:
                    !(values.turno || values.contraturno) ||
                    values.fechaDesde === '' ||
                    values.fechaHasta === '',
                  filter: filterMotivoJustificacion,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Grid item container justifyContent="space-between">
                <Grid
                  item
                  component={Box}
                  height={10}
                  paddingBottom={3}
                  fontFamily="Nunito"
                  fontSize={15}
                  fontWeight="bold"
                  color="#38485C"
                >
                  Detalle *
                </Grid>
                <Grid
                  item
                  component={Box}
                  height={10}
                  paddingBottom={3}
                  fontFamily="Nunito"
                  fontSize={13}
                >
                  {values.details.length}/100
                </Grid>
              </Grid>
              <FormInputV2
                name="details"
                form={form}
                multiline
                fullWidth
                placeholder="Mensaje"
                disabled={!firstStepIsValid}
                minRows={6}
                InputLabelProps={{
                  className: classes.selectLabel,
                  shrink: false,
                }}
                InputProps={{
                  className: classes.selectInput,
                  disableUnderline: true,
                }}
                SelectProps={{ classes: { root: classes.selectBg } }}
              />
            </Grid>
          </Grid>
          <Grid item xs={5} container spacing={3}>
            <Grid item xs={12}>
              <Grid
                item
                component={Box}
                height={10}
                paddingBottom={3}
                fontFamily="Nunito"
                fontSize={15}
                fontWeight="bold"
                color="#38485C"
              >
                Turno *
              </Grid>
              <Grid item container spacing={1}>
                <Grid item>
                  <FormMultiCheckbox
                    name="turno"
                    options={_turnosOptions}
                    form={form}
                    idKey="value"
                    labelKey="label"
                    disabled={
                      values.fechaDesde === '' || values.fechaHasta === ''
                    }
                    direction="row"
                  />
                </Grid>
                {values.fechaDesde && values.fechaHasta && (
                  <Grid item>
                    <FormMultiCheckbox
                      name="contraturno"
                      form={form}
                      idKey="value"
                      labelKey="label"
                      options={_contraturnosOptions}
                      disabled={
                        values.fechaDesde === '' || values.fechaHasta === ''
                      }
                      direction="row"
                    />
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              hidden={
                values.razonJustificacion ===
                RAZON_JUSTIFICACION.AUSENTE.toString()
              }
            >
              <Grid
                item
                component={Box}
                height={10}
                paddingBottom={3}
                fontFamily="Nunito"
                fontSize={15}
                fontWeight="bold"
                color="#38485C"
              >
                Hora *
              </Grid>
              <Grid item>
                <FormTimePicker
                  name="horaSalida"
                  form={form}
                  label=""
                  disabled={!firstStepIsValid}
                  InputLabelProps={{
                    className: classes.selectLabel,
                    shrink: false,
                  }}
                  InputProps={{
                    className: classes.selectInput,
                    disableUnderline: true,
                  }}
                  SelectProps={{ classes: { root: classes.selectBg } }}
                />
              </Grid>
            </Grid>
            <Grid item xs={8}>
              <Grid
                item
                component={Box}
                height={10}
                paddingBottom={3}
                fontFamily="Nunito"
                fontSize={15}
                fontWeight="bold"
                color="#38485C"
              >
                Archivos{' '}
                {razonJustificacionEstadoPresente[
                  values.razonJustificacion
                ]?.toString() === ESTADO_PRESENTE.AUSENTE.toString()
                  ? '*'
                  : ''}
              </Grid>
              <Grid item>
                <FormFileInput
                  withDrop
                  name="files"
                  form={form}
                  disabled={!firstStepIsValid}
                  validator={validateFiles}
                />
              </Grid>
              <Grid item>
                {values.files.map((file: File, i) => {
                  return (
                    <AttachFileView
                      key={i}
                      file={file}
                      onDelete={onDeleteFile}
                    />
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container id="footer" xs={12} component={Box} mt={4}>
          <Grid container spacing={1} justifyContent="flex-end">
            {!loading ? (
              <>
                <Grid item>
                  <Button
                    className={classes.cancelButton}
                    variant="text"
                    color="primary"
                    onClick={handleClose}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.saveButton}
                    type="submit"
                    disabled={isFormInvalid}
                    classes={{ disabled: classes.disabledButton }}
                    onClick={onSubmit}
                  >
                    Guardar
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.nextStudent}
                    variant="outlined"
                    onClick={onNext}
                    endIcon={<ArrowForward />}
                  >
                    Siguiente Estudiante
                  </Button>
                </Grid>
              </>
            ) : (
              <Loading />
            )}
          </Grid>
        </Grid>
        <ConfirmDialog
          title="La justificación se guardo con éxito"
          content="Desea seguir cargando justificaciones?"
          cancelText="Volver"
          confirmText="Continuar"
          open={openConfirmDialog}
          onCancel={handleClose}
          onConfirm={onConfirmDialog}
          customTheme={null}
        />
      </Grid>
    );
  },
);

JustificacionForm.displayName = 'JustificacionForm';
export { JustificacionForm };
