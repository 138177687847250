import React, { MouseEventHandler, ReactNode } from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core';
import { ThemeProvider, makeStyles } from '@material-ui/styles';
import typography from 'src/theme/typography';
import { grey } from 'src/theme/colors';
import { AnyObject } from '../../../../types/types';

export interface ModalBaseProps {
  open: boolean;
  onClose?: MouseEventHandler;
  title: ReactNode;
  content: ReactNode;
  actions: ReactNode;
  customTheme: AnyObject | null;
  maxWidth?: any;
}

const modalBaseStyles = makeStyles(() => ({
  title: {
    fontSize: 28,
    fontWeight: 'normal',
    color: grey.medium,
    fontFamily: typography.fontFamily,
  },
  dialog: {
    margin: 'auto',
    '& .MuiPaper-rounded': {
      borderRadius: 0,
    },
    '& .MuiDialogActions-root': {
      boxShadow: '0px -3px 3px rgba(0, 0, 0, 0.1);',
      height: 36,
      paddingRight: 30,
    },
    '& .MuiDialogContent-root': {
      paddingTop: '0px',
    },
  },
}));

export const ModalBase = ({
  open,
  onClose,
  title,
  content,
  actions,
  customTheme,
  maxWidth = 'sm',
}: ModalBaseProps) => {
  const classes = modalBaseStyles();
  const dialog = (
    <Dialog
      open={open}
      onClose={onClose}
      className={classes.dialog}
      fullWidth
      maxWidth={maxWidth}
    >
      <DialogTitle className={classes.title} disableTypography>
        {title}
      </DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
  return !customTheme ? (
    dialog
  ) : (
    <ThemeProvider theme={customTheme}>{dialog}</ThemeProvider>
  );
};
