import React, { useCallback, useState, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { useParams, useHistory } from 'react-router';
import {
  useDiscipOrientPlanDAO,
  EspacioCurricularByIdProvider,
} from 'src/app/business';
import { useEspaciosDiscipOrientPlanDAO } from 'src/app/business/EspaciosDiscipOrientPlan';
import { useRolesContext } from 'src/context/RolesContext';
import { TabBar } from 'src/commons/components/TabBar';
import { FormContextProvider } from 'src/lib/templates';
import { tabs } from './tabs';
import { FormFooter } from './components/FormFooter';
import { PlanificacionDisciplinarOrientadaHeader } from './components';
import { FormProps } from '../commons/types';
import {
  DiscipOrientPlan,
  NewDiscipOrientPlan,
  OrientDiscipTempPlan,
} from '../../../../../../../app/models';
import { useTemplateJoiner } from '../commons/hooks';
import { DisciplinarOrientadaProvider } from './Context';
import { PLANIFICACION_ESTADOS } from '../../../../../../../commons/const';
import { DEFAULT_HIDE_TIME } from 'src/commons/const/ui/snackbar';

const templateMap = {
  bloque: 'bloqueTemp',
  ensenanza: 'ensenanzaTemp',
  objetivosDeAprendizaje: 'objetivosDeAprendizajeTemp',
  indicadoresEvaluacion: 'indicadoresDeEvaluacionTemp',
  primerBimestre: 'primerBimestreTemp',
  segundoBimestre: 'segundoBimestreTemp',
  tercerBimestre: 'tercerBimestreTemp',
  cuartoBimestre: 'cuartoBimestreTemp',
  bibliografia: 'bibliografiaTemp',
};

const isDiscipOrientPlan = (
  item: DiscipOrientPlan | NewDiscipOrientPlan,
): item is DiscipOrientPlan =>
  (item as DiscipOrientPlan).idDiscipOrientPlan !== undefined;

export const PlanificacionDisciplinarOrientadoForm = ({
  initialValues,
  template,
}: FormProps<DiscipOrientPlan, NewDiscipOrientPlan, OrientDiscipTempPlan>) => {
  // disabled enviar button for new planning
  const [isDisabled, setIsDisabled] = useState<Boolean>(
    !isDiscipOrientPlan(initialValues),
  );
  const [isNewPlan, setIsNewPlan] = useState(
    !isDiscipOrientPlan(initialValues),
  );

  const [valuesUpdated, setValuesUpdated] = useState(initialValues);

  // route params
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const { selectedRole, userInfo } = useRolesContext();
  // @ts-ignore
  const { idEspacioCurricular } = useParams();

  // Unimos varios templates en uno solo
  const joinedTemplates = useTemplateJoiner(template, templateMap);

  const espaciosDiscipOrientPlanDAO = useEspaciosDiscipOrientPlanDAO();
  const discipOrientPlanDAO = useDiscipOrientPlanDAO();
  const enviarCallBack = async (values: any) => {
    const newValue = {
      ...valuesUpdated,
      planificacionEstados: {
        idPlanificacionEstados: PLANIFICACION_ESTADOS.ENVIADO,
      },
    };
    await discipOrientPlanDAO.save(newValue);
    history.goBack();
    enqueueSnackbar('La planificación ha sido enviada con éxito', {
      variant: 'success',
      autoHideDuration: DEFAULT_HIDE_TIME,
    });
  };
  const onSubmit = useCallback(
    async (values) => {
      try {
        if (!isDiscipOrientPlan(valuesUpdated)) {
          const planificacionData = {
            ...values,
            localizacion: { idLocalizacion: selectedRole.localizacionId },
            orientDiscipTempPlan: {
              idOrientDiscipTempPlan: template.idOrientDiscipTempPlan,
            },
            cuentas: { idUsuario: userInfo.id },
          };
          const espacioData = {
            espacioCurricular: { idEspacioCurricular },
          };
          const { discipOrientPlan } = await espaciosDiscipOrientPlanDAO
            .aspect('limit_province')
            .save({
              planificacionData,
              espacioData,
            });
          enqueueSnackbar('Se ha guardado con éxito', {
            variant: 'success',
            autoHideDuration: DEFAULT_HIDE_TIME,
          });
          setValuesUpdated(discipOrientPlan);
          setIsDisabled(false);
          setIsNewPlan(false);
        } else {
          // update
          const updated = {
            ...valuesUpdated,
            ...values,
            planificacionEstados: {
              idPlanificacionEstados: PLANIFICACION_ESTADOS.EN_PROCESO,
            },
          };
          updated.ultimaActualizacion = new Date();
          await discipOrientPlanDAO.save(updated);
          enqueueSnackbar('Se ha guardado con éxito', {
            variant: 'success',
            autoHideDuration: DEFAULT_HIDE_TIME,
          });
          setValuesUpdated(updated);
          setIsDisabled(false);
        }
      } catch (e) {
        // TODO: fix error de libby
        console.log(e);
      }
    },
    [
      enqueueSnackbar,
      discipOrientPlanDAO,
      espaciosDiscipOrientPlanDAO,
      idEspacioCurricular,
      userInfo.id,
      valuesUpdated,
      selectedRole.localizacionId,
      template.idOrientDiscipTempPlan,
    ],
  );

  const depuredInitialValue = useMemo<NewDiscipOrientPlan>(() => {
    if (!isDiscipOrientPlan(initialValues)) {
      return initialValues;
    }
    const {
      idDiscipOrientPlan,
      ultimaActualizacion,
      orientDiscipTempPlan,
      localizacion,
      cuentas,
      planificacionEstados,
      ...rest
    } = initialValues;
    return rest;
  }, [initialValues]);

  return (
    <EspacioCurricularByIdProvider id={idEspacioCurricular}>
      <PlanificacionDisciplinarOrientadaHeader
        localizacionId={selectedRole.localizacionId}
      />
      {!joinedTemplates ? (
        <h1>Loading...</h1>
      ) : (
        <DisciplinarOrientadaProvider>
          <FormContextProvider
            template={joinedTemplates}
            initialValues={depuredInitialValue}
            onSubmit={onSubmit}
          >
            <>
              <TabBar content={tabs} />
              <FormFooter
                enviarCallBack={enviarCallBack}
                isDisabled={isDisabled}
                handleValuesChange={({ isDisabled }) =>
                  setIsDisabled(isDisabled)
                }
                isNewPlan={isNewPlan}
              />
            </>
          </FormContextProvider>
        </DisciplinarOrientadaProvider>
      )}
    </EspacioCurricularByIdProvider>
  );
};
