import React, { useCallback } from 'react';
import { ObservacionesModal, SelectWithField, SimpleSelect } from 'src/commons';
import { useMemo } from 'react';
import { makeStyles } from '@material-ui/core';
import customFormDialog from 'src/commons/services/customFormDialog';
import { primary } from 'src/theme/colors';
import { SelectAutoComplete } from 'src/lib/templates/components/SelectAutoComplete';
import { Pps } from 'src/app/models';
import { usePPSFormContext } from '../PPSFormProvider';
import { FormApi } from 'final-form';
import { PenTextField } from 'src/commons/components/FinalForm/PenTextField';

const useStyles = makeStyles(() => ({
  iconObservacion: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'end',
    color: primary.lightBlue,
    marginLeft: '20px',
  },
  observaciones: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '200px',
    marginTop: '10px',
    textAlign: 'right',
    color: 'black',
    paddingBottom: 10,
  },
}));

interface LabelType {
  label: string;
}

export const useTrayectoriaEscolarColumns = (ppsDataAlumno: Pps) => {
  const { form } = usePPSFormContext();
  const values = (form as FormApi).getState().values;
  const classes = useStyles();
  const handleObservaciones: () => void = useCallback(() => {
    customFormDialog.show({
      title: 'Observaciones',
      renderComponent: (
        <ObservacionesModal
          content={values?.trayectoria?.observaciones}
          onAddContent={(content: any) =>
            (form as FormApi).change('trayectoria.observaciones', content)
          }
          value={values?.trayectoria?.observaciones}
        />
      ),
      sizeWidth: 'sm',
    });
  }, [form, values?.trayectoria?.observaciones]);

  const columns = useMemo(() => {
    const SiNoOptions = [{ label: 'Sí' }, { label: 'No' }];

    const cualesOptions = [
      { label: 'ACDM' },
      { label: 'ILSA' },
      { label: 'Maestro de apoyo' },
      { label: 'Pedagogico' },
      { label: 'MAP' },
      { label: 'MPO' },
      { label: 'Otros (Aclarar en las observaciones)' },
    ];

    cualesOptions.sort((a, b) => {
      const labelA = a.label.toUpperCase();
      const labelB = b.label.toUpperCase();

      if (labelA < labelB) {
        return -1;
      } else if (labelA > labelB) {
        return 1;
      } else {
        return 0;
      }
    });

    const isOpen = ppsDataAlumno !== undefined ? ppsDataAlumno?.abierto : true;
    const isDisabled = !isOpen;

    const handleChangeMultipleOptions = (
      data: [{ label: string }],
      name: string,
      onInputChange: string,
    ) => {
      if (onInputChange !== 'onInputChange') {
        (form as FormApi).change(name, data);
      }
    };

    return [
      {
        id: 'requirio',
        label: '¿Requirió configuraciones de apoyo?',
        width: '5%',
        hideSortIcon: true,
        render: () => (
          <SelectWithField
            form={form}
            name="trayectoria.requirio"
            placeholder="Seleccioná una opción"
            labelKey="label"
            valueKey="label"
            content={SiNoOptions}
            disabled={isDisabled}
            width={'100px'}
          />
        ),
      },
      {
        id: 'cuales',
        label: '¿Cuáles?',
        hideSortIcon: true,
        width: '15%',
        render: () => (
          <SelectAutoComplete
            title=""
            placeholder="Seleccioná una opción"
            optionLabelKey="label"
            optionValueKey="label"
            valueToOption={values?.trayectoria?.cuales || []}
            handleChange={handleChangeMultipleOptions}
            options={cualesOptions}
            getOptionSelected={(option: any, value: LabelType) =>
              option?.label === value?.label
            }
            name="trayectoria.cuales"
            disabled={isDisabled || values?.trayectoria?.requirio !== 'Sí'}
            multiple
            disableCloseOnSelect
            disableText
            width={'200px'}
          />
        ),
      },
      {
        id: 'ajustesRazonables',
        label: '¿Requirió ajustes razonables?',
        width: '5%',
        hideSortIcon: true,
        render: () => (
          <SelectWithField
            form={form}
            name="trayectoria.ajustesRazonables"
            placeholder="Seleccioná una opción"
            labelKey="label"
            valueKey="label"
            content={SiNoOptions}
            disabled={isDisabled}
            width={'100px'}
          />
        ),
      },
      {
        id: 'cualesAjustes',
        label: '¿Cuáles?',
        width: '5%',
        hideSortIcon: true,
        render: () => (
          <PenTextField
            form={form}
            name="trayectoria.cualesAjustes"
            disabled={
              isDisabled ||
              values.trayectoria?.ajustesRazonables?.toLowerCase() !== 'sí'
            }
          />
        ),
      },
      {
        id: 'ajustesAreas',
        label: '¿En qué áreas?',
        width: '5%',
        hideSortIcon: true,
        render: () => (
          <PenTextField
            form={form}
            name="trayectoria.ajustesAreas"
            disabled={
              isDisabled ||
              values.trayectoria?.ajustesRazonables?.toLowerCase() !== 'sí'
            }
          />
        ),
      },
      {
        id: 'requirioacompañada',
        label: '¿Requirió promoción acompañada?',
        width: '5%',
        hideSortIcon: true,
        render: () => (
          <SelectWithField
            form={form}
            name="trayectoria.requirioacompañada"
            placeholder="Seleccioná una opción"
            labelKey="label"
            valueKey="label"
            content={SiNoOptions}
            disabled={isDisabled}
            width={'100px'}
          />
        ),
      },
      {
        id: 'contenidos',
        label: '¿En qué contenidos nodales?',
        width: '10%',
        hideSortIcon: true,
        render: () => (
          <PenTextField
            form={form}
            name="trayectoria.contenidos"
            disabled={
              isDisabled ||
              values.trayectoria?.requirioacompañada?.toLowerCase() !== 'sí'
            }
          />
        ),
      },
      {
        id: 'requiriopedagogico',
        label:
          '¿Requirió Proyecto Pedagógico individual para la inclusión (PPI)?',
        width: '5%',
        hideSortIcon: true,
        render: () => (
          <SelectWithField
            form={form}
            name="trayectoria.requiriopedagogico"
            placeholder="Seleccioná una opción"
            labelKey="label"
            valueKey="label"
            content={SiNoOptions}
            disabled={isDisabled}
            width={'100px'}
          />
        ),
      },
      {
        id: 'interrumpida',
        label:
          'Si hubo interrupción de la presencialidad ¿se requirió alguna intervención en particular?',
        width: '10%',
        hideSortIcon: true,
        render: () => (
          <PenTextField
            form={form}
            name="trayectoria.interrumpida"
            disabled={isDisabled}
          />
        ),
      },
      {
        id: 'destaca',
        label: 'Se destaca en:',
        width: '10%',
        hideSortIcon: true,
        render: () => (
          <PenTextField
            form={form}
            name="trayectoria.destaca"
            disabled={isDisabled}
          />
        ),
      },
      {
        id: 'interes',
        label: 'Manifiesta Interés en:',
        width: '10%',
        hideSortIcon: true,
        render: () => (
          <PenTextField
            form={form}
            name="trayectoria.interes"
            disabled={isDisabled}
          />
        ),
      },
      {
        id: 'observaciones',
        label: 'Observaciones',
        width: '10%',
        hideSortIcon: true,
        render: () => (
          <PenTextField
            form={form}
            name="trayectoria.observaciones"
            disabled={isDisabled}
          />
        ),
      },
    ];
  }, [ppsDataAlumno, form, values?.trayectoria]);

  return columns;
};
