import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { UsuarioRolEstablecimiento } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'usuario_rol_establecimiento';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idUsuarioRolEstablecimiento',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idUsuarioRolEstablecimiento',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.UsuarioRolEstablecimiento
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.UsuarioRolEstablecimiento,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.UsuarioRolEstablecimiento.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<UsuarioRolEstablecimiento>(DAO_NAME, optionsToUse);

// hooks
export const useUsuarioRolEstablecimientoFetchById = fetchById;
export const useUsuarioRolEstablecimientoLibbyCall = libbyCall;
export const useUsuarioRolEstablecimientoDAO = libbyDAO;
export const useUsuarioRolEstablecimientoLibbyFetch = libbyFetch;

// components/hoc
export const UsuarioRolEstablecimientoAutocomplete = entityAutocomplete;
export const UsuarioRolEstablecimientoCall = renderPropCall;
export const UsuarioRolEstablecimientoById = renderPropFetchById;
export const UsuarioRolEstablecimientoSelect = simpleSelect;
export const UsuarioRolEstablecimientoAccordionSelector = accordionSelector;
export const UsuarioRolEstablecimientoSelectedChips = selectedChips;

// context
// ByIdContext
export const useUsuarioRolEstablecimientoByIdContext = useByIdContext;
export const UsuarioRolEstablecimientoByIdProvider = ByIdProvider;
export const UsuarioRolEstablecimientoByIdContext = ByIdContext;
// GeneralContext
export const useUsuarioRolEstablecimientoContext = useGeneralContext;
export const UsuarioRolEstablecimientoProvider = GeneralProvider;
export const UsuarioRolEstablecimientoContext = GeneralContext;
