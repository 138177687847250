import {
  AccionesMinimizadas,
  CicloLectivo,
  Organizacion,
} from 'src/app/models';

export enum ACAP_SCREEN {
  ORGANIZACIONES = 'ORGANIZACIONES',
  ACCIONES_ASOCIADAS = 'ACCIONES_ASOCIADAS',
}

export enum ORGANIZACION_SCREEN {
  MAIN = 'MAIN',
  ACCIONES = 'ACCIONES',
  REFERENTE = 'REFERENTE',
  ORGANIZACION_FORM = 'ORGANIZACION_FORM',
}

export enum ACCIONES_SCREEN {
  VIEW = 'VIEW',
  NEW = 'NEW',
  EDIT = 'EDIT',
  DUPLICATE = 'DUPLICATE',
}

export interface OrganizacionesScreen {
  screen: ORGANIZACION_SCREEN;
  data?: Organizacion;
}

export interface AccionesScreen {
  screen: ACCIONES_SCREEN;
  data?: AccionesMinimizadas;
}

export interface AcapEstablecimientoContextValue {
  screen: ACAP_SCREEN;
  organizacionesScreen: OrganizacionesScreen;
  accionesScreen: AccionesScreen;
  ciclosLectivos: CicloLectivo[];
  loading: boolean;
  setScreen: React.Dispatch<React.SetStateAction<ACAP_SCREEN>>;
  setOrganizacionesScreen: React.Dispatch<
    React.SetStateAction<OrganizacionesScreen>
  >;
  setAccionesScreen: React.Dispatch<React.SetStateAction<AccionesScreen>>;
}
