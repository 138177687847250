import React, { FC } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Footer, InfoTable } from 'src/commons/components';
import { LibbyObject } from 'src/commons/types';
import { useRolesContext } from 'src/context/RolesContext';
import { usePromotionTableLogic } from '../hooks';
import { PromotionFilters } from '../components';

interface IAdultPromotionProps {
  libby: LibbyObject;
  isAdultPromotion?: boolean;
}

const AdultPromotionRaw: FC<IAdultPromotionProps> = ({
  libby,
  isAdultPromotion = true,
}) => {
  const {
    selectedRole: { localizacionId: locationId, nivel: level },
  } = useRolesContext();

  const {
    columns,
    direction,
    footerButtons,
    onSortChange,
    orderBy,
    rows,
    setFilters,
    working,
  } = usePromotionTableLogic({ libby, locationId, isAdultPromotion, level });

  return (
    <>
      <PromotionFilters
        {...{ setFilters, locationId, isAdultPromotion, level }}
      />
      <InfoTable
        {...{ columns, direction, onSortChange, orderBy, rows, working }}
      />
      <Footer buttonConfig={footerButtons} spacing={3} />
    </>
  );
};

export const AdultPromotion = DatabaseConnector(AdultPromotionRaw)(
  'alumno_movimiento',
  'seccion',
);
