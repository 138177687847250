import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Proyeccion } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'proyeccion';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idProyeccion',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idProyeccion',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Proyeccion
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Proyeccion,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Proyeccion.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Proyeccion>(DAO_NAME, optionsToUse);

// hooks
export const useProyeccionFetchById = fetchById;
export const useProyeccionLibbyCall = libbyCall;
export const useProyeccionDAO = libbyDAO;
export const useProyeccionLibbyFetch = libbyFetch;

// components/hoc
export const ProyeccionAutocomplete = entityAutocomplete;
export const ProyeccionCall = renderPropCall;
export const ProyeccionById = renderPropFetchById;
export const ProyeccionSelect = simpleSelect;
export const ProyeccionAccordionSelector = accordionSelector;
export const ProyeccionSelectedChips = selectedChips;

// context
// ByIdContext
export const useProyeccionByIdContext = useByIdContext;
export const ProyeccionByIdProvider = ByIdProvider;
export const ProyeccionByIdContext = ByIdContext;
// GeneralContext
export const useProyeccionContext = useGeneralContext;
export const ProyeccionProvider = GeneralProvider;
export const ProyeccionContext = GeneralContext;
