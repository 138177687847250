import React, { useMemo } from 'react';
import {
  CardContent,
  makeStyles,
  IconButton,
  Grid,
  Box,
} from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { AutoRegistracion, ModalidadType } from 'src/app/models';
import { roles } from '../../../../assets/images';
import { primary } from '../../../../theme/colors';
import { CardItemContainer } from './CardItemContainer';
import { OptionsModalOption, OptionsModal } from '../../OptionsModal';

const useStyles = makeStyles({
  cardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  iconContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    padding: 5,
  },
  statusRol: {
    padding: 5,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
  titleText: {
    alignSelf: 'flex-start',
    fontSize: 24,
    color: primary.white,
    fontFamily: 'Open Sans',
    textShadow: '1px 1px 2px black',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingTop: 44,
    paddingLeft: 12,
  },
  descriptionText: {
    alignSelf: 'flex-start',
    fontSize: 14,
    color: primary.white,
    fontFamily: 'Open Sans',
    textShadow: '1px 1px 2px black',
  },
});

export interface CardItemContentPresentationalProps {
  item: AutoRegistracion;
  onClick?: () => any;
  handleMenuIcon: OptionsModalOption<AutoRegistracion>[];
}

export const CardItemPending = ({
  onClick,
  handleMenuIcon,
  item,
}: CardItemContentPresentationalProps) => {
  const classes = useStyles();

  return (
    <CardItemContainer>
      <CardContent className={classes.cardContent} onClick={onClick}>
        <div
          style={{
            backgroundImage: `url(${
              roles[item.rolUsuario.idRolUsuario.toString()]
            })`,
            backgroundPosition: '50% 10%',
            height: 150,
          }}
        >
          <div style={{ backgroundColor: 'rgba(0,0,0,0.5)', height: 150 }}>
            <Grid container direction="row" justify="space-between">
              <div className={classes.statusRol}>
                <span className={classes.descriptionText}>
                  Pendiente de aprobación
                </span>
              </div>
              <OptionsModal
                options={handleMenuIcon}
                color="inherit"
                data={item}
                customStyleIcon={{ fill: 'white' }}
                classIcon={classes.iconContainer}
              />
            </Grid>

            <div className={classes.textContainer}>
              <span className={classes.titleText}>
                {item.rolUsuario.descripcion}
              </span>
              {(item?.localizacion?.establecimiento?.modalidad?.idModalidad ===
                ModalidadType.General ||
                item?.localizacion?.establecimiento?.modalidad?.idModalidad ===
                  ModalidadType.Adultos) && (
                <span className={classes.descriptionText}>
                  {item?.nivel?.descripcionNivel}
                </span>
              )}
            </div>
          </div>
        </div>
      </CardContent>
    </CardItemContainer>
  );
};
