import { LibbyFetchDAO } from './LibbyFetchDAO';

export class SeccionesDAO extends LibbyFetchDAO {
  constructor() {
    super('secciones', 'idSecciones');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
