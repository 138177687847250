import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import moment from 'moment';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { AnyObject, LibbyObject, statusPassesRequest } from 'src/commons';
import confirmDialog from 'src/commons/services/confirmDialog';
import { OptionsModal } from 'src/commons/components/OptionsModal';
import { useRolesContext } from 'src/context/RolesContext';
import { HeaderTable, InfoTable, MainButton } from 'src/commons/components';
import { useDebounce, useLibbyCall, useLibbyFetch } from 'src/commons/hooks';
import customFormDialog from 'src/commons/services/customFormDialog';
import { primary } from 'src/theme/colors';
import { typeOfPass } from 'src/commons/const';
import { SolicitudPases } from 'src/app/models';
import { orderPending } from 'src/utils';
import { useLegajoLogic } from 'src/screens/Private/LegajoAlumno';
import { FilterTables } from './components/PassRequestModal/FilterTables';
import { PassRequestModal } from './components/PassRequestModal';
import { IconStatus } from '../components/IconStatus';
import { GestionModalInicial } from '../components/GestionModalInicial';

const { PENDING, CANCELLED } = statusPassesRequest;

const ByCpassManagementRaw = ({ libby }: LibbyObject) => {
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('alumno.idAlumno');
  const [formValues, setFormValues] = useState({
    nivel: '',
    ciclo: '',
    localizacionDestino: '',
  });
  const [search, setSearch] = useState('');
  const searchDebounced = useDebounce(search, 1000);

  const arrSearch = useMemo(
    () => [
      {
        path: 'alumno.persona.nombre',
        value: searchDebounced,
        method: 'includes',
      },
      {
        path: 'alumno.persona.apellido',
        value: searchDebounced,
        method: 'includes',
      },
      {
        path: 'alumno.persona.documento',
        value: searchDebounced,
        method: 'includes',
      },
      {
        path: 'nivel.descripcionNivel',
        value: searchDebounced,
        method: 'includes',
      },
      {
        path: 'ciclo.descripcionCiclo',
        value: searchDebounced,
        method: 'includes',
      },
      {
        path: 'localizacionOrigen.descripcion',
        value: searchDebounced,
        method: 'includes',
      },
      { path: 'localizacionDestino.descripcion', value: searchDebounced },
      {
        path: 'estadoSolicitudPases.descripcionSolicitudPase',
        value: searchDebounced,
        method: 'includes',
      },
    ],
    [searchDebounced],
  );

  const roleInfo = useRolesContext();
  const idLocation = roleInfo?.selectedRole?.localizacionId;
  const idLevel = roleInfo?.selectedRole?.nivel;

  const filterDefault = [
    { path: 'localizacionOrigen', value: idLocation },
    { path: 'localizacionDestino', value: idLocation },
  ];
  const [filterLibby, setFilterLibby] = useState<AnyObject>({
    0: filterDefault,
  });

  const paramsFetchManagement = {
    orderBy,
    direction,
    daoName: 'solicitud_pases',
    filter: filterLibby,
  };

  const {
    data: passRequest,
    working,
    reFetch,
    fetchMore,
  } = useLibbyFetch(libby, paramsFetchManagement);

  const { data: cycle } = useLibbyCall(libby, {
    daoName: 'ciclo',
    methodName: 'getBCCycle',
  });
  const { data: level } = useLibbyCall(libby, {
    daoName: 'nivel',
    methodName: 'getLevelsBySchool',
    params: [1, 2, 3],
  });

  const handleOnClick = () => {
    customFormDialog.show({
      title: 'Solicitar Pase',
      renderComponent: (
        <PassRequestModal
          idLocation={idLocation}
          reFetch={reFetch}
          nivel={idLevel}
        />
      ),
      sizeWidth: 'md',
    });
  };

  const handleGestionPassOnClick = (student: AnyObject) => {
    customFormDialog.show({
      title: 'Gestionar pase',
      renderComponent: (
        <GestionModalInicial selectAlumn={student} reFetch={reFetch} />
      ),
      sizeWidth: 'md',
    });
  };

  const handleChange = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleChangeSearch = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setSearch(target.value);
  };

  const handleCancelRequest = useCallback(
    async (idSolicitudPases: number) => {
      try {
        const confirm = await confirmDialog.show({
          title: '¿Desea cancelar la solicitud?',
          content: `Una vez cancelada la solicitud no podrá cambiar su estado.`,
          confirmText: 'Confirmar',
          cancelText: 'Cancelar',
        });
        if (confirm) {
          const passCanceled = {
            idSolicitudPases,
            estadoSolicitudPases: CANCELLED,
            fechaPase: moment().toISOString(),
          };
          await libby.solicitud_pases.save(passCanceled);
          confirmDialog.handleCancel();
          reFetch();
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
        confirmDialog.handleCancel();
      }
    },
    [libby, reFetch],
  );

  const { toLegajoAlumno } = useLegajoLogic();

  const passManagementLogicTable = (_passRequest: AnyObject) => {
    const rows = _passRequest.map(
      ({
        idSolicitudPases,
        alumno: {
          idAlumno,
          persona: { nombre, apellido, documento },
          estadoMatricula: { idEstadoMatricula },
        },
        localizacionOrigen: { descripcion: establecimientoOrigen },
        localizacionDestino: {
          idLocalizacion: idLocalizacionDestino,
          descripcion: establecimientoDestino,
        },
        estadoSolicitudPases: {
          idEstadoSolicitudPases,
          descripcionSolicitudPase,
        },
        fechaPedida,
        observacion,
        nivel,
        ciclo,
      }: AnyObject) => ({
        id: idSolicitudPases,
        icon:
          idLocalizacionDestino !== idLocation ? (
            <ArrowUpwardIcon style={{ color: primary.warning }} />
          ) : (
            <ArrowDownwardIcon />
          ),
        alumno: (
          <button
            type="button"
            style={{
              color: '#00A0D6',
              margin: '0',
              textAlign: 'left',
              background: 'none',
              border: 'none',
            }}
            onClick={() => toLegajoAlumno(idAlumno)}
          >
            {`${nombre} ${apellido}`}
          </button>
        ),
        document: documento,
        Establacemiento_origen: establecimientoOrigen,
        establecimiento_destino: establecimientoDestino,
        estado:
          idLocalizacionDestino !== idLocation &&
          idEstadoSolicitudPases === PENDING ? (
            <MainButton
              title="Gestionar"
              type="secondary"
              handleOnClick={() =>
                handleGestionPassOnClick({
                  idSolicitudPases,
                  observacion,
                  apellido,
                  nombre,
                  documento,
                  establecimientoDestino,
                  idAlumno,
                  idLocalizacionDestino,
                  idEstadoMatricula,
                })
              }
            />
          ) : (
            <IconStatus description={descripcionSolicitudPase} />
          ),
        fecha: moment(fechaPedida).format('DD/MM/YYYY'),
        cycle: ciclo?.descripcionCiclo || '-',
        level: nivel?.descripcionNivel || '-',
        option:
          idLocalizacionDestino === idLocation &&
          idEstadoSolicitudPases === PENDING ? (
            <OptionsModal
              options={[
                {
                  label: 'Cancelar',
                  onClick: () => handleCancelRequest(idSolicitudPases),
                },
              ]}
            />
          ) : (
            ''
          ),
      }),
    );

    return {
      rows,
    };
  };

  const column = [
    {
      id: 'icon',
      label: '',
      width: '2%',
      hideSortIcon: true,
      orderById: '',
    },
    {
      id: 'alumno',
      label: 'Alumno',
      width: '10%',
      orderById: 'alumno.persona.nombre',
    },
    {
      id: 'document',
      label: 'Documento',
      width: '2%',
      orderById: 'alumno.persona.documento',
    },
    {
      id: 'level',
      label: 'Nivel',
      width: '2%',
      orderById: 'nivel.descripcionNivel',
    },
    {
      id: 'cycle',
      label: 'Ciclo',
      width: '3%',
      orderById: 'ciclo.descripcionCiclo',
    },
    {
      id: 'Establacemiento_origen',
      label: 'Establecimiento de origen',
      width: '15%',
      orderById: 'localizacionOrigen.descripcion',
    },
    {
      id: 'establecimiento_destino',
      label: 'Establecimiento de destino',
      width: '15%',
      orderById: 'localizacionDestino.descripcion',
    },
    {
      id: 'estado',
      label: 'Estado',
      width: '8%',
      orderById: 'estadoSolicitudPases.descripcionSolicitudPase',
    },
    {
      id: 'fecha',
      label: 'Fecha',
      width: '2%',
      orderById: 'fechaPedida',
    },
    {
      id: 'option',
      label: '',
      width: '2%',
      hideSortIcon: true,
      noSort: true,
    },
  ];

  const filterTables = useCallback(
    (filterSearch: any) => {
      const { nivel, ciclo, localizacionDestino } = formValues;
      if (nivel || ciclo || localizacionDestino) {
        const variant =
          localizacionDestino !== 'entrada' ? 'notEquals' : 'equals';

        let toObjectLibby = {
          0: filterDefault,
          search: filterSearch,
        };
        Object.entries(formValues)
          .filter((filter) => !!filter[1])
          // eslint-disable-next-line
          .map((filter, i) => {
            const filterRaw = [
              { path: filter[0], value: filter[1], method: 'equals' },
            ];
            if (filter[0] === 'localizacionDestino') {
              filterRaw[0].value = idLocation;
              filterRaw[0].method = variant;
            }
            toObjectLibby = {
              ...toObjectLibby,
              [i + 1]: filterRaw,
            };
          });

        setFilterLibby(toObjectLibby);
      } else {
        setFilterLibby({
          0: filterDefault,
          search: filterSearch,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formValues],
  );

  useEffect(() => {
    filterTables(arrSearch);
  }, [formValues, arrSearch, filterTables]);

  const orderRow: SolicitudPases[] = useMemo(
    () => orderPending(passRequest),
    [passRequest],
  );
  const passManagementRows = passManagementLogicTable(orderRow);

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  return (
    <>
      <HeaderTable
        title="Gestión de pases"
        titleMainButton="Solicitar Pase"
        handleOnClick={handleOnClick}
      >
        <FilterTables
          search={search}
          handleChangeSearch={handleChangeSearch}
          level={level}
          cycle={cycle}
          typeOfPass={typeOfPass}
          handleChange={handleChange}
          formValues={formValues}
        />
      </HeaderTable>
      <InfoTable
        working={working}
        rows={passManagementRows.rows}
        columns={column}
        rowIdKey="id"
        orderBy={orderBy}
        direction={direction}
        onSortChange={handleRequestSort}
        onBottomScroll={fetchMore}
      />
    </>
  );
};

export const ByCpassManagement = DatabaseConnector(ByCpassManagementRaw)(
  'ciclo',
  'solicitud_pases',
  'nivel',
);
