import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { Pip } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'pip';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPip',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPip',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Pip
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Pip,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Pip.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Pip>(DAO_NAME, optionsToUse);

// hooks
export const usePipFetchById = fetchById;
export const usePipLibbyCall = libbyCall;
export const usePipDAO = libbyDAO;
export const usePipLibbyFetch = libbyFetch;

// components/hoc
export const PipAutocomplete = entityAutocomplete;
export const PipCall = renderPropCall;
export const PipById = renderPropFetchById;
export const PipSelect = simpleSelect;
export const PipAccordionSelector = accordionSelector;
export const PipSelectedChips = selectedChips;

// context
// ByIdContext
export const usePipByIdContext = useByIdContext;
export const PipByIdProvider = ByIdProvider;
export const PipByIdContext = ByIdContext;
// GeneralContext
export const usePipContext = useGeneralContext;
export const PipProvider = GeneralProvider;
export const PipContext = GeneralContext;
