import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { JornadaExtendida } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'jornada_extendida';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idJornadaExtendida',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idJornadaExtendida',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.JornadaExtendida
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.JornadaExtendida,
      hoc: { ...defaultOptions.hoc, ...businessOptions.JornadaExtendida.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<JornadaExtendida>(DAO_NAME, optionsToUse);

// hooks
export const useJornadaExtendidaFetchById = fetchById;
export const useJornadaExtendidaLibbyCall = libbyCall;
export const useJornadaExtendidaDAO = libbyDAO;
export const useJornadaExtendidaLibbyFetch = libbyFetch;

// components/hoc
export const JornadaExtendidaAutocomplete = entityAutocomplete;
export const JornadaExtendidaCall = renderPropCall;
export const JornadaExtendidaById = renderPropFetchById;
export const JornadaExtendidaSelect = simpleSelect;
export const JornadaExtendidaAccordionSelector = accordionSelector;
export const JornadaExtendidaSelectedChips = selectedChips;

// context
// ByIdContext
export const useJornadaExtendidaByIdContext = useByIdContext;
export const JornadaExtendidaByIdProvider = ByIdProvider;
export const JornadaExtendidaByIdContext = ByIdContext;
// GeneralContext
export const useJornadaExtendidaContext = useGeneralContext;
export const JornadaExtendidaProvider = GeneralProvider;
export const JornadaExtendidaContext = GeneralContext;
