import React, { FunctionComponent } from 'react';
import {
  DatePicker as MuiDatePicker,
  DatePickerProps as MuiDatePickerProps,
} from '@material-ui/pickers';

export const DatePickerV2: FunctionComponent<MuiDatePickerProps> = ({
  value,
  error,
  format = 'DD/MM/yyyy',
  onChange,
  clearable,
  helperText,
  placeholder = 'dd/mm/aaaa',
  disabled,
  disableFuture,
  className,
  fullWidth = false,
  maxDate,
  minDate,
  ...rest
}) => {
  return (
    <MuiDatePicker
      fullWidth={fullWidth}
      error={error}
      value={value}
      okLabel="Aceptar"
      clearLabel="Limpiar"
      cancelLabel="Cancelar"
      format={format}
      onChange={onChange}
      clearable={clearable}
      helperText={helperText}
      disabled={disabled}
      disableFuture={disableFuture}
      placeholder={placeholder}
      inputVariant="outlined"
      maxDate={maxDate}
      minDate={minDate}
      {...rest}
    />
  );
};
