import React, { useMemo } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { ROL } from 'src/commons/const';
import {
  Button,
  Grid,
  IconButton,
  TableRow,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import moment from 'moment';
import {
  AlumnoMovimiento,
  GruposInscripciones,
  GruposSelecciones,
} from 'src/app/models';
import { useRolesContext } from 'src/context/RolesContext';
import { InfoTableColumn } from '../../../../commons';
import { AccionSeleccionada } from '../types';

const useStylesDisabled = makeStyles({
  tooltip: {
    fontSize: '13px',
  },
});

export const useAccionesSeleccionadasColumns = (
  removeGrupoSeleccion?: (id: string) => void,
  setAccionSeleccionadaRow?: React.Dispatch<
    React.SetStateAction<AccionSeleccionada | null>
  >,
  canEdit = false,
  alumnosInscriptosCorrectos?: GruposInscripciones[],
  alumnosEscuelaMixtoCheck?: AlumnoMovimiento[],
) => {
  const {
    selectedRole: { localizacionId, rol },
  } = useRolesContext();
  const classesDisabled = useStylesDisabled();

  const columns = useMemo<InfoTableColumn<any>[]>(
    () => [
      {
        id: 'organizacion',
        label: 'Organización',
        render: (value: any, row: any) => {
          return row?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.organizacion
            ?.nombre;
        },
        orderById: 'ofertaGrupo.ofertaTurno.oferta.accion.organizacion.nombre',
      },
      {
        id: 'sector',
        label: 'Sector',
        orderById:
          'ofertaGrupo.ofertaTurno.oferta.accion.organizacion.sector.nombre',
        render: (value: any, row: any) =>
          row?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.organizacion?.sector
            ?.nombre,
      },
      {
        id: 'tipoAccion',
        label: 'Tipo de acción',
        orderById: 'ofertaGrupo.ofertaTurno.oferta.accion.tipoAccion.nombre',
        render: (value: any, row: any) =>
          row?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.tipoAccion?.nombre,
      },
      {
        id: 'accion',
        label: 'Acción',
        orderById: 'ofertaGrupo.ofertaTurno.oferta.accion.nombre',
        render: (value: any, row: any) =>
          row?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.nombre,
      },
      {
        id: 'grupoId',
        label: 'Grupo',
        hideSortIcon: true,
        render: (_: unknown, row) =>
          `${row?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.nombre}, ${row?.ofertaGrupo?.ofertaTurno?.turno?.descripcionTurno}, id: ${row.ofertaGrupo.idOfertaGrupo}`,
      },
      {
        id: 'fechaInicio',
        label: 'Fecha de inicio',
        orderById: 'ofertaGrupo.ofertaTurno.oferta.fechaInicio',
        render: (value: any, row: any) => {
          return (
            moment(row?.ofertaGrupo?.fechaInicio).format('DD-MM-YYYY') || ' '
          );
        },
      },
      {
        id: 'turno',
        label: 'Turno',
        orderById: 'ofertaGrupo.ofertaTurno.turno.descripcionTurno',
        render: (value: any, row: any) =>
          row?.ofertaGrupo?.ofertaTurno?.turno?.descripcionTurno,
      },
      {
        id: 'cupo',
        label: 'Cupo',
        hideSortIcon: true,
        render: (value: any, row: any) => {
          if (row?.ofertaGrupo?.tipoCupo === 'Cerrado')
            return row?.ofertaGrupo?.cupoEstatal;
          else if (row?.ofertaGrupo?.tipoCupo === 'Mixto') {
            if (
              row?.ofertaGrupo?.localizacion?.idLocalizacion ===
                localizacionId &&
              row?.ofertaGrupo?.cupoEstatal > 0
            )
              return row?.ofertaGrupo?.cupoEstatal;
            else
              return (
                row?.ofertaGrupo?.ofertaTurno?.cupo /
                  row?.ofertaGrupo?.ofertaTurno?.cantGrupos -
                (row?.ofertaGrupo?.cupoEstatal + row?.ofertaGrupo?.cupoPrivado)
              );
          } else
            return (
              row?.ofertaGrupo?.ofertaTurno?.cupo /
              row?.ofertaGrupo?.ofertaTurno?.cantGrupos
            );
        },
      },
      {
        id: 'referente',
        label: 'Referente',
        hideSortIcon: true,
        render: (value: any, row: any) => {
          return (
            <>
              <Grid container direction="column">
                {row?.ofertaGrupo?.jornadas?.map((jornada: any) => {
                  return (
                    <Grid item>
                      <p>
                        {Boolean(row?.ofertaGrupo?.referente?.apellido)
                          ? `${row?.ofertaGrupo?.referente?.apellido}, ${row?.ofertaGrupo?.referente?.nombre}`
                          : row?.ofertaGrupo?.referente?.nombre}
                      </p>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          );
        },
      },
      {
        id: 'sedes',
        label: 'Sedes',
        hideSortIcon: true,
        render: (value: any, row) => {
          const sedes = row.ofertaGrupo?.jornadas?.map((jornada: any) => {
            return jornada.sede.direccion;
          });
          return sedes.length > 0 ? (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {sedes?.map((sede: any, index: number) => (
                <TableRow
                  key={index}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '1px',
                    maxHeight: '50px',
                    whiteSpace: 'pre',
                    wordBreak: 'break-all',
                    ...(sede?.length > 1 && sede?.length - 1 !== index
                      ? { borderBottom: '1px solid #DFE3E8' }
                      : {}),
                  }}
                >
                  {sede}
                </TableRow>
              ))}
            </div>
          ) : null;
        },
      },
      {
        id: 'dia',
        label: 'Día',
        hideSortIcon: true,
        render: (value: any, row: any) => {
          return (
            <>
              <Grid container direction="column">
                {row?.ofertaGrupo?.jornadas?.map(
                  (jornada: any, index: number) => {
                    return (
                      <>
                        <Grid item>
                          <p>
                            {
                              [
                                'Lunes',
                                'Martes',
                                'Miércoles',
                                'Jueves',
                                'Viernes',
                                'Sábado',
                                'Domingo',
                              ][jornada?.cupo]
                            }
                          </p>
                        </Grid>
                      </>
                    );
                  },
                )}
              </Grid>
            </>
          );
        },
      },
      {
        id: 'horainicio',
        label: 'Hora inicio',
        hideSortIcon: true,
        render: (value: any, row: any) => {
          return (
            <>
              <Grid container direction="column">
                {row?.ofertaGrupo?.jornadas?.map((jornada: any) => {
                  return (
                    <Grid item>
                      <p>{jornada?.horaInicio}</p>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          );
        },
      },
      {
        id: 'horafin',
        label: 'Hora fin',
        hideSortIcon: true,
        render: (value: any, row: any) => {
          return (
            <>
              <Grid container direction="column">
                {row?.ofertaGrupo?.jornadas?.map((jornada: any) => {
                  return (
                    <Grid item>
                      <p>{jornada?.horaFin}</p>
                    </Grid>
                  );
                })}
              </Grid>
            </>
          );
        },
      },
      ...(canEdit
        ? [
            {
              id: 'botones',
              label: '',
              hideSortIcon: true,
              render: (value: any, row: GruposSelecciones) => {
                let cupo = 0;
                let inscriptosTotales;

                if (row?.ofertaGrupo?.tipoCupo === 'Cerrado') {
                  inscriptosTotales = alumnosInscriptosCorrectos?.filter(
                    (alumno) =>
                      alumno?.ofertaGrupo?.idOfertaGrupo ===
                      row?.ofertaGrupo?.idOfertaGrupo,
                  )?.length;
                  cupo = row?.ofertaGrupo?.cupoEstatal;
                } else if (row?.ofertaGrupo?.tipoCupo === 'Mixto') {
                  if (
                    row?.ofertaGrupo?.localizacion?.idLocalizacion ===
                      localizacionId &&
                    row?.ofertaGrupo?.cupoEstatal > 0
                  ) {
                    inscriptosTotales = alumnosInscriptosCorrectos?.filter(
                      (alumno) =>
                        alumno?.ofertaGrupo?.idOfertaGrupo ===
                          row?.ofertaGrupo?.idOfertaGrupo &&
                        row?.ofertaGrupo?.localizacion?.idLocalizacion ===
                          localizacionId,
                    )?.length;
                    cupo = row?.ofertaGrupo?.cupoEstatal;
                  } else {
                    inscriptosTotales = alumnosInscriptosCorrectos?.filter(
                      (alumno) =>
                        alumno?.ofertaGrupo?.idOfertaGrupo ===
                          row?.ofertaGrupo?.idOfertaGrupo &&
                        alumnosEscuelaMixtoCheck?.find(
                          (alumnoMovimiento: AlumnoMovimiento) => {
                            return (
                              alumnoMovimiento?.idAlumnoMovimiento ===
                              alumno?.alumnoMovimiento?.idAlumnoMovimiento
                            );
                          },
                        )?.seccion?.localizacion?.idLocalizacion !==
                          row?.ofertaGrupo?.localizacion?.idLocalizacion,
                    )?.length;
                    cupo =
                      row?.ofertaGrupo?.ofertaTurno?.cupo /
                        row?.ofertaGrupo?.ofertaTurno?.cantGrupos -
                      (row?.ofertaGrupo?.cupoEstatal +
                        row?.ofertaGrupo?.cupoPrivado);
                  }
                } else if (row?.ofertaGrupo?.tipoCupo === 'Abierto') {
                  inscriptosTotales = alumnosInscriptosCorrectos?.filter(
                    (alumno) =>
                      alumno?.ofertaGrupo?.idOfertaGrupo ===
                      row?.ofertaGrupo?.idOfertaGrupo,
                  )?.length;
                  cupo =
                    row?.ofertaGrupo?.ofertaTurno?.cupo /
                    row?.ofertaGrupo?.ofertaTurno?.cantGrupos;
                }

                const validacionFechaInicio =
                  moment(new Date()).isAfter(
                    row?.ofertaGrupo?.ofertaTurno?.oferta?.fechaInicio,
                  ) &&
                  !moment(new Date()).isAfter(
                    row?.ofertaGrupo?.ofertaTurno?.oferta?.fechaFin,
                  );

                const fechaActual = new Date();

                const fechaFinOferta = new Date(row?.ofertaGrupo?.fechaFin);

                const validacionFechaFin = fechaActual > fechaFinOferta;

                return (
                  <>
                    <Grid
                      container
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <Button
                          color="primary"
                          variant="contained"
                          component="label"
                          style={{
                            width: '120px',
                            marginBottom: '10px',
                            //   marginTop: '15px',
                          }}
                          onClick={() => {
                            if (setAccionSeleccionadaRow)
                              setAccionSeleccionadaRow(row);
                          }}
                          disabled={
                            Number(inscriptosTotales) >= Number(cupo) ||
                            !(ROL.REFERENTE_ACAP === Number(rol)) ||
                            validacionFechaFin
                          }
                        >
                          Inscribir
                        </Button>
                      </Grid>
                      <Grid item>
                        {validacionFechaInicio ? (
                          <Tooltip
                            classes={classesDisabled}
                            arrow
                            title="La actividad ha iniciado, no borrar"
                          >
                            <DeleteIcon
                              style={{
                                padding: '10px',
                              }}
                              color="disabled"
                            />
                          </Tooltip>
                        ) : (
                          <IconButton
                            disabled={!(ROL.REFERENTE_ACAP === Number(rol))}
                            onClick={() => {
                              if (removeGrupoSeleccion) {
                                removeGrupoSeleccion(row?.idGrupoSeleccion);
                              }
                            }}
                          >
                            <DeleteIcon
                              color={
                                !(ROL.REFERENTE_ACAP === Number(rol))
                                  ? 'disabled'
                                  : 'primary'
                              }
                            />
                          </IconButton>
                        )}
                      </Grid>
                    </Grid>
                  </>
                );
              },
            },
          ]
        : []),
    ],
    [
      canEdit,
      localizacionId,
      rol,
      classesDisabled,
      alumnosInscriptosCorrectos,
      alumnosEscuelaMixtoCheck,
      setAccionSeleccionadaRow,
      removeGrupoSeleccion,
    ],
  );

  return columns;
};
