import React, { useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  Grid,
  IconButton,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  InfoTable,
  InfoTableColumn,
  NIVEL,
  TIPO_PERIODO,
  useDebounce,
} from 'src/commons';
import {
  Edit as EditIcon,
  Visibility as VisibilityIcon,
  Delete as RemoveIcon,
  Search as SearchIcon,
} from '@material-ui/icons';
import {
  useSituacionV3DAO,
  useSituacionV3LibbyFetch,
} from 'src/app/business/presentismo_v3/SituacionV3';
import { useStyles } from './styles';
import { SituacionV3 } from '../../../../app/models/presentismo_v3/SituacionV3';
import { SituacionesModal } from './components';
import customFormDialog from '../../../../commons/services/customFormDialog';
import { FormState } from './types';
import { useRolesContext } from '../../../../context/RolesContext';
import { DeepPartial } from '../../../../commons/types/DeepPartial';
import { useSnackbar } from 'notistack';
import { usePeriodoNuevoLibbyCall } from '../../../../app/business';
import { PeriodoNuevo } from 'src/app/models';
import { cloneDeep } from 'lodash';

const situacionesColumns: InfoTableColumn<SituacionV3>[] = [
  {
    id: 'tipoSituacion.descripcion',
    label: 'Situación',
    width: '30%',
    hideSortIcon: true,
  },
  {
    id: 'motivoSituacion.descripcion',
    label: 'Motivo',
    width: '20%',
    hideSortIcon: true,
  },
  {
    id: 'fechaDesde',
    label: 'Fecha desde',
    width: '10%',
    hideSortIcon: true,
    render: (value) => moment(value).format('DD/MM/YYYY'),
  },
  {
    id: 'fechaHasta',
    label: 'Fecha hasta',
    width: '10%',
    hideSortIcon: true,
    render: (value) => moment(value).format('DD/MM/YYYY'),
  },
];

const searchColumns = [
  'tipoSituacion.descripcion',
  'motivoSituacion.descripcion',
];

interface ConfiguracionSituacionesProps {
  materiasJE: boolean;
  isPrimariaCoordinadorJE: boolean;
}

export const ConfiguracionSituaciones = ({
  materiasJE,
  isPrimariaCoordinadorJE,
}: ConfiguracionSituacionesProps) => {
  const classes = useStyles();
  const situacionesDAO = useSituacionV3DAO();
  const [search, setSearch] = useState<string>('');
  const searchDebounced: string = useDebounce(search, 500);
  const { enqueueSnackbar } = useSnackbar();

  const {
    selectedRole: { localizacionId, nivel, rol },
  } = useRolesContext();
  const controlsDisabledCoordinadorJE = !materiasJE && isPrimariaCoordinadorJE;
  const situacionFilter = useMemo(
    () => ({
      search: searchDebounced
        ? searchColumns.map((path) => ({
            path,
            value: searchDebounced,
            method: 'includes',
          }))
        : [],
    }),
    [searchDebounced],
  );

  const {
    data: situaciones = [],
    working: situacionesWorking,
    reFetch,
  } = useSituacionV3LibbyFetch({
    filter: situacionFilter,
    orderBy: 'createdAt',
    direction: 'desc',
  });

  const { data: periodoNuevo = [], working: periodoNuevoWorking } =
    usePeriodoNuevoLibbyCall({
      methodName: 'getPeriodoActual',
      params: [nivel],
    });

  //en caso de que traiga "periodo receso"

  const periodo = useMemo(() => {
    let _periodo: PeriodoNuevo | undefined = undefined;
    const periodoActual = periodoNuevo.find((periodo) =>
      moment(new Date(), 'YYYY-MM-DD').isBetween(
        moment(periodo.fechaInicio, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        moment(periodo.fechaFin, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        undefined,
        '[]',
      ),
    );
    if (
      periodoActual?.tipoPeriodo.idTipoPeriodo === TIPO_PERIODO.RECESO_INVERNAL
    ) {
      const tipoPeriodo =
        nivel === NIVEL.INICIAL.toString()
          ? TIPO_PERIODO.SEGUNDO_CUATRIMESTRE
          : TIPO_PERIODO.TERCER_BIMESTRE;
      _periodo = periodoNuevo.find(
        (periodo) => periodo.tipoPeriodo.idTipoPeriodo === tipoPeriodo,
      ); // TERCER BIMESTRE
    } else {
      _periodo = periodoActual;
    }
    return _periodo;
  }, [periodoNuevo, nivel]);

  const openAltaModal = useCallback(async () => {
    try {
      const { values } = await customFormDialog.show<{ values: FormState }>({
        title: 'Alta de Situación',
        renderComponent: (
          <SituacionesModal
            situaciones={situaciones}
            periodo={periodo}
            rol={Number(rol)}
          />
        ),
        sizeWidth: 'md',
      });
      if (!values) return;

      const toSave: DeepPartial<SituacionV3> = {
        fechaDesde: values.range[0].startDate.toISOString(),
        fechaHasta: values.range[0].endDate.toISOString(),
        motivoSituacion: {
          idMotivoSituacion: Number(values.motivoTipoSituacion),
        },
        tipoSituacion: { idTipoSituacion: Number(values.tipoSituacion) },
        observaciones: values.observaciones.join(', '),
        localizacion: { idLocalizacion: localizacionId },
        secciones: values.seccionesAfectadas,
        periodoNuevo: periodo,
      };
      await situacionesDAO.save(toSave);
      enqueueSnackbar('Situación guardada exitosamente.', {
        variant: 'success',
      });
      reFetch();
    } catch (error) {
      enqueueSnackbar('No se pudo guardar la situación.', { variant: 'error' });
    }
  }, [
    localizacionId,
    situaciones,
    periodo,
    situacionesDAO,
    rol,
    enqueueSnackbar,
    reFetch,
  ]);

  const handleViewEdit = useCallback(
    (onlyView: boolean, situacion: SituacionV3) => async () => {
      try {
        const initialValue: FormState = {
          idSituacion: situacion?.idSituacion,
          tipoSituacion:
            situacion?.tipoSituacion.idTipoSituacion.toString() || '',
          motivoTipoSituacion:
            situacion?.motivoSituacion.idMotivoSituacion.toString() || '',
          observaciones: situacion?.observaciones.split(',') || [],
          range: [
            {
              endDate: moment(situacion?.fechaHasta).toDate() || new Date(),
              startDate: moment(situacion?.fechaDesde).toDate() || new Date(),
              key: 'selection',
            },
          ],
          seccionesAfectadas: situacion?.secciones || [],
        };

        const { values } = await customFormDialog.show<{ values: FormState }>({
          title: onlyView ? 'Situación' : 'Editar Situación',
          renderComponent: (
            <SituacionesModal
              situaciones={situaciones}
              periodo={periodo}
              onlyView={onlyView}
              initialValue={cloneDeep(initialValue)}
              rol={Number(rol)}
              edit={true}
            />
          ),
          sizeWidth: 'md',
        });
        if (!values) return;
        const toSave: DeepPartial<SituacionV3> = {
          ...situacion,
          fechaDesde: values.range[0].startDate.toISOString(),
          fechaHasta: values.range[0].endDate.toISOString(),
          motivoSituacion: {
            idMotivoSituacion: Number(values.motivoTipoSituacion),
          },
          tipoSituacion: { idTipoSituacion: Number(values.tipoSituacion) },
          observaciones: values.observaciones.join(', '),
          localizacion: { idLocalizacion: localizacionId },
          secciones: values.seccionesAfectadas,
          periodoNuevo: periodo,
        };
        await situacionesDAO.save(toSave);
        enqueueSnackbar('Situación guardada exitosamente.', {
          variant: 'success',
        });
        reFetch();
      } catch (error) {
        enqueueSnackbar('No se pudo guardar la situación.', {
          variant: 'error',
        });
      }
    },
    [
      localizacionId,
      situaciones,
      periodo,
      situacionesDAO,
      rol,
      enqueueSnackbar,
      reFetch,
    ],
  );

  const removeSituacion = useCallback(
    (situacion: SituacionV3) => async () => {
      try {
        await situacionesDAO.remove(situacion);
        enqueueSnackbar('Situación eliminada exitosamente.', {
          variant: 'success',
        });
        reFetch();
      } catch (error) {
        enqueueSnackbar('No se pudo eliminar la situación.', {
          variant: 'error',
        });
      }
    },
    [enqueueSnackbar, situacionesDAO, reFetch],
  );

  const disableDelete = useCallback(
    (situacion: SituacionV3) => {
      const fechaDesde = moment(situacion.fechaDesde);
      // Comparación solo por año, mes y día

      return (
        fechaDesde.isBefore(moment()) ||
        (!materiasJE && isPrimariaCoordinadorJE)
      );
    },
    [materiasJE, isPrimariaCoordinadorJE],
  );
  const situacionesColumnsUpdated = useMemo<InfoTableColumn<SituacionV3>[]>(
    () => [
      ...situacionesColumns,
      {
        id: 'buttonBar',
        label: '',
        width: '20%',
        hideSortIcon: true,
        render: (value, row) => {
          return (
            <Grid container direction="row">
              <Grid item>
                <IconButton onClick={handleViewEdit(true, row)}>
                  <VisibilityIcon color="primary" />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  disabled={
                    controlsDisabledCoordinadorJE &&
                    Number(rol) !== row.rol?.idRolUsuario
                  }
                >
                  <EditIcon
                    color="primary"
                    onClick={handleViewEdit(false, row)}
                  />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton
                  disabled={
                    disableDelete(row) || Number(rol) !== row.rol?.idRolUsuario
                  }
                  onClick={removeSituacion(row)}
                >
                  <RemoveIcon
                    color={
                      disableDelete(row) ||
                      Number(rol) !== row.rol?.idRolUsuario
                        ? 'disabled'
                        : 'primary'
                    }
                  />
                </IconButton>
              </Grid>
            </Grid>
          );
        },
      },
    ],
    [
      handleViewEdit,
      controlsDisabledCoordinadorJE,
      rol,
      disableDelete,
      removeSituacion,
    ],
  );

  return (
    <Grid component={Box} paddingTop={3} container direction="column">
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        className={classes.container}
        spacing={2}
      >
        <Grid item>
          <Typography variant="h2" style={{ fontWeight: 500 }}>
            Situaciones
          </Typography>
        </Grid>
        <Grid item direction="row">
          <Button
            variant="contained"
            color="primary"
            onClick={openAltaModal}
            className={classes.altaButton}
            disabled={periodoNuevoWorking || isPrimariaCoordinadorJE === true}
          >
            Alta de situación
          </Button>
          <TextField
            name="Search"
            type="search"
            id="outlined-controlled"
            placeholder="Buscar situación o motivo"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            InputLabelProps={{
              shrink: true,
              className: classes.selectLabel,
            }}
            InputProps={{
              className: classes.selectInput,
              disableUnderline: true,
              startAdornment: (
                <SearchIcon fontSize="small" htmlColor="#38485C" />
              ),
              style: { width: 250 },
            }}
            SelectProps={{ classes: { root: classes.selectBg } }}
          />
        </Grid>
      </Grid>
      <Grid container>
        <InfoTable
          working={situacionesWorking || periodoNuevoWorking}
          columns={situacionesColumnsUpdated}
          rows={situaciones}
        />
      </Grid>
    </Grid>
  );
};
