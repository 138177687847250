import { routes } from './routes';
import { MenuItem } from '../../../../commons/const/ui/menu';

export const signUpMenu: MenuItem = {
  key: 'signUp',
  basePath: '/sign-up',
  enabled: true,
  icon: 'List',
  text: 'SignUp',
  children: routes,
};
