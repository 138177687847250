interface IColumnData {
  id: string;
  label: string;
  width: string;
  orderById?: string;
  hideSortIcon?: boolean;
  noSort?: boolean;
}

export const BCPromotionColumns: IColumnData[] = [
  {
    id: 'surnameName',
    label: 'Apellido y nombre',
    width: '10%',
    orderById: 'alumno.persona.apellido',
  },
  {
    id: 'level',
    label: 'Nivel',
    width: '5%',
    orderById: 'seccion.nivel',
  },
  {
    id: 'cycle',
    label: 'Ciclo',
    width: '5%',
    orderById: 'seccion.ciclo',
  },
  {
    id: 'turn',
    label: 'Turno',
    width: '5%',
    orderById: 'seccion.turno',
  },
  {
    id: 'workingDay',
    label: 'Jornada',
    width: '5%',
    orderById: 'seccion.jornada',
  },
  {
    id: 'groupName',
    label: 'Grupo actual',
    width: '7%',
    orderById: 'seccion.nombreSeccion',
  },
  {
    id: 'groupPass',
    label: 'Grupo al que pasa',
    width: '5%',
    hideSortIcon: true,
    noSort: true,
  },
];

export const AdultPromotionColumns: IColumnData[] = [
  {
    id: 'surnameName',
    label: 'Apellido y nombre',
    width: '10%',
    orderById: 'alumno.persona.apellido',
  },
  {
    id: 'schoolYear',
    label: 'Tipo de ciclo lectivo',
    width: '5%',
    orderById: 'seccion.cicloLectivoAdultos',
  },
  {
    id: 'level',
    label: 'Nivel',
    width: '5%',
    orderById: 'seccion.nivel',
  },
  {
    id: 'turn',
    label: 'Turno',
    width: '5%',
    orderById: 'seccion.turno',
  },
  {
    id: 'cycle',
    label: 'Ciclo actual',
    width: '7%',
    orderById: 'seccion.division',
  },
  {
    id: 'groupPass',
    label: 'Ciclo al que pasa',
    width: '5%',
    hideSortIcon: true,
    noSort: true,
  },
];
