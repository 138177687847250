import { TABLE_COLUMNS } from './table_filters';

export class TableColumn<T, U = string> {
  id: string;
  label: string;
  orderById: string;
  customRender?: undefined | ((value: string, row: T) => U);
  width?: string;
  minWidth?: string;

  constructor(
    id: string,
    label?: string,
    customRender?: (value: string, row: T) => U,
  ) {
    this.id = id;
    this.label = label ?? TABLE_COLUMNS[id];
    this.customRender = customRender;
    this.orderById = id;
  }

  setWidth(width: string) {
    this.width = width;
    return this;
  }

  setMinWidth(minWidth: string) {
    this.minWidth = minWidth;
    return this;
  }

  render(value: string, row: T) {
    return this.customRender ? this.customRender(value, row) : value;
  }
}
