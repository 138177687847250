import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { PlanEstudioMateria } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'plan_estudio_materia';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPlanEstudioMateria',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPlanEstudioMateria',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.PlanEstudioMateria
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.PlanEstudioMateria,
      hoc: { ...defaultOptions.hoc, ...businessOptions.PlanEstudioMateria.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<PlanEstudioMateria>(DAO_NAME, optionsToUse);

// hooks
export const usePlanEstudioMateriaFetchById = fetchById;
export const usePlanEstudioMateriaLibbyCall = libbyCall;
export const usePlanEstudioMateriaDAO = libbyDAO;
export const usePlanEstudioMateriaLibbyFetch = libbyFetch;

// components/hoc
export const PlanEstudioMateriaAutocomplete = entityAutocomplete;
export const PlanEstudioMateriaCall = renderPropCall;
export const PlanEstudioMateriaById = renderPropFetchById;
export const PlanEstudioMateriaSelect = simpleSelect;
export const PlanEstudioMateriaAccordionSelector = accordionSelector;
export const PlanEstudioMateriaSelectedChips = selectedChips;

// context
// ByIdContext
export const usePlanEstudioMateriaByIdContext = useByIdContext;
export const PlanEstudioMateriaByIdProvider = ByIdProvider;
export const PlanEstudioMateriaByIdContext = ByIdContext;
// GeneralContext
export const usePlanEstudioMateriaContext = useGeneralContext;
export const PlanEstudioMateriaProvider = GeneralProvider;
export const PlanEstudioMateriaContext = GeneralContext;
