import { Box, Grid, TextField, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useEscuelaContext } from '../../../context/EscuelaContext';
import { AnyObject } from '../../../../../../commons';
import { ContenidoNodo, Perfil, Valor } from '../../../types';
import DianaChart from 'src/commons/components/Diana/DianaChart';
import { useRadarStyles } from 'src/screens/Private/AcapEstudiante/components';

const getValores = <T extends AnyObject>(
  devoluciones: T[],
  alumnoId: string,
): Valor[] => {
  return (
    devoluciones.filter(
      (d) => d.createdBy?.idUsuario?.toString() === alumnoId.toString(),
    )[0]?.valores || []
  );
};
// AJUSTAR
export const AutoevaluacionEstudiante = () => {
  const { alumnoSelected } = useEscuelaContext();

  const { perfilesValues, contenidoValues, habilidadValues } = useMemo(() => {
    const userAlumnoId =
      alumnoSelected?.alumnoMovimiento.alumno.persona.cuentas.idUsuario;

    const perfilesValues =
      getValores<Perfil>(
        alumnoSelected?.perfiles || [],
        userAlumnoId || '',
      ).map<Acap.Estudiante.EscalarDiana>((v) => ({
        evaluacion: v.evaluacion,
        label: v.descripcion,
      })) || [];
    const habilidadValues =
      (alumnoSelected?.habilidades || [])
        .filter((dev) => dev.createdBy?.idUsuario === userAlumnoId)[0]
        ?.valores.reduce<any[]>((acum, item) => {
          if (
            !acum.find(
              (a) => a.id === item.habilidadCategoria.idHabilidadCategoria,
            )
          ) {
            acum.push({
              id: item.habilidadCategoria.idHabilidadCategoria,
              label: item.habilidadCategoria.nombre,
              evaluacion: item.devolucion.evaluacion || 0,
            });
          }
          return acum;
        }, []) || [];

    const contenidos: ContenidoNodo[] = [];
    alumnoSelected?.contenidos
      ?.filter((c) => c.createdBy?.idUsuario === userAlumnoId)[0]
      ?.valores.forEach((nodo) => {
        if (
          !contenidos.find(
            (e) => e.contenido.idContenido === nodo.contenido.idContenido,
          )
        ) {
          contenidos.push(nodo);
        }
      });

    const mapped =
      contenidos.map<Acap.Estudiante.EscalarDiana>((v) => ({
        evaluacion: v.devolucion.evaluacion,
        label: v.devolucion.descripcion,
      })) || [];

    return { perfilesValues, habilidadValues, contenidoValues: mapped };
  }, [alumnoSelected]);
  const classes = useRadarStyles();

  return (
    <Grid container component={Box} paddingY={4} spacing={2}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={4} container justifyContent="center" alignItems="center">
          <Typography
            component={Box}
            paddingY={2}
            variant="body2"
            align="center"
            fontWeight={'bold'}
          >
            Perfil del Egresado
          </Typography>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            style={{ overflow: 'hidden', height: '100%' }}
          >
            <div className={classes.radar}>
              <DianaChart
                title="Perfil"
                evaluaciones={perfilesValues.map((item) => item.evaluacion)}
                labels={perfilesValues.map((item) => item.label)}
              />
            </div>
          </Grid>
        </Grid>
        <Grid item xs={4} container justifyContent="center" alignItems="center">
          <Typography
            component={Box}
            paddingY={2}
            variant="body2"
            align="center"
            fontWeight={'bold'}
          >
            Habilidades, Capacidades y Competencias
          </Typography>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            style={{ overflow: 'hidden', height: '100%' }}
          >
            <div className={classes.radar}>
              <DianaChart
                title="Habilidades"
                evaluaciones={habilidadValues.map((item) => item.evaluacion)}
                labels={habilidadValues.map((item) => item.label)}
              />
            </div>
          </Grid>
        </Grid>

        <Grid item xs={4} container justifyContent="center" alignItems="center">
          <Typography
            component={Box}
            paddingY={2}
            variant="body2"
            align="center"
            fontWeight={'bold'}
          >
            Contenidos del eje
          </Typography>
          <Grid
            item
            xs={12}
            container
            justifyContent="center"
            alignItems="center"
            style={{ overflow: 'hidden', height: '100%' }}
          >
            <div className={classes.radar}>
              <DianaChart
                title="Contenidos"
                evaluaciones={contenidoValues.map((item) => item.evaluacion)}
                labels={contenidoValues.map((item) => item.label)}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} component={Box} marginTop={3} container spacing={2}>
        <Grid item xs={12} sm={2} md={2} lg={1}>
          <Typography
            style={{
              fontWeight: 600,
            }}
          >
            Observaciones:
          </Typography>
        </Grid>

        <Grid item container xs={12} sm={10} md={10} lg={11}>
          <Grid item xs={12} style={{ marginBottom: '10px' }}>
            <TextField
              label=""
              fullWidth
              value={
                alumnoSelected?.perfiles[0]?.observaciones ||
                alumnoSelected?.habilidades[0]?.observaciones ||
                alumnoSelected?.contenidos[0]?.observaciones ||
                ''
              }
              multiline
              minRows={3}
              InputProps={{
                disableUnderline: true,
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
