import {
  PartialBusinessEntityOptions,
  makeBusinessEntity,
} from 'src/lib/libby';
import { businessOptions } from '../businessOptions';
import { Method } from 'src/app/models/security/Method';

const DAO_NAME = 'endpoint_method';

const defaultOptions: PartialBusinessEntityOptions<any> = {};

const optionsToUse = !businessOptions.EndpointMethod
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EndpointMethod,
      hoc: { ...defaultOptions.hoc, ...businessOptions.EndpointMethod.hoc },
    };

const {
  hoc: { simpleSelect, entityAutocomplete },
} = makeBusinessEntity<Method>(DAO_NAME, optionsToUse);

export const EndpointMethodAutoComplete = entityAutocomplete;
export const EndpointMethodSimpleSelect = simpleSelect;
