import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { TipoDisciplina } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'tipo_disciplina';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idTipoDisciplina',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idTipoDisciplina',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.TipoDisciplina
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.TipoDisciplina,
      hoc: { ...defaultOptions.hoc, ...businessOptions.TipoDisciplina.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<TipoDisciplina>(DAO_NAME, optionsToUse);

// hooks
export const useTipoDisciplinaFetchById = fetchById;
export const useTipoDisciplinaLibbyCall = libbyCall;
export const useTipoDisciplinaDAO = libbyDAO;
export const useTipoDisciplinaLibbyFetch = libbyFetch;

// components/hoc
export const TipoDisciplinaAutocomplete = entityAutocomplete;
export const TipoDisciplinaCall = renderPropCall;
export const TipoDisciplinaById = renderPropFetchById;
export const TipoDisciplinaSelect = simpleSelect;
export const TipoDisciplinaAccordionSelector = accordionSelector;
export const TipoDisciplinaSelectedChips = selectedChips;

// context
// ByIdContext
export const useTipoDisciplinaByIdContext = useByIdContext;
export const TipoDisciplinaByIdProvider = ByIdProvider;
export const TipoDisciplinaByIdContext = ByIdContext;
// GeneralContext
export const useTipoDisciplinaContext = useGeneralContext;
export const TipoDisciplinaProvider = GeneralProvider;
export const TipoDisciplinaContext = GeneralContext;
