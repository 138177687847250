import { Box, Grid, Typography } from '@material-ui/core';
import confirmDialog from 'src/commons/services/confirmDialog';
import React from 'react';

export const confirmacionBtnHandler = async (
  totalEstudiantes: number,
  totalSecciones: Matriculacion.Confirmacion.SeccionesInfo[],
  anio?: number,
) => {
  const emptys: string[] = [];

  totalSecciones.forEach((seccionInfo) => {
    for (const [seccion, total] of Object.entries(seccionInfo)) {
      if (total === 0) emptys.push(seccion);
    }
  });

  let message = `Usted está confirmando una matrícula de ${totalEstudiantes} estudiantes y un total de ${
    totalSecciones.length - emptys.length
  } secciones para el ciclo lectivo ${anio}`;

  if (emptys.length > 0) {
    message = '';
  }

  const confirm = await confirmDialog.show({
    title: 'Confirmación de matrícula',
    content: (
      <Grid container spacing={2}>
        <Grid item>
          <Typography>
            Usted está confirmando una matrícula de {totalEstudiantes}{' '}
            estudiantes y un total de {totalSecciones.length} secciones para el
            ciclo lectivo {anio}.
          </Typography>
        </Grid>
        {emptys.length > 0 ? (
          <Grid item container spacing={2}>
            <Grid item>
              <Typography>
                Usted no confirmó alumnos en las siguientes secciones:
              </Typography>
            </Grid>

            <Grid item container component={Box} marginLeft={2}>
              {emptys.map((seccion) => (
                <Grid item xs={12}>
                  <Typography>{seccion}</Typography>
                </Grid>
              ))}
            </Grid>

            <Grid item>
              <Typography>
                Si esto último es correcto, usted deberia eliminar las secciones
                sin alumnos, si no, deberia completar los alumnos.
              </Typography>
            </Grid>
          </Grid>
        ) : null}
      </Grid>
    ),
    confirmText: 'Aceptar',
    cancelText: 'Cancelar',
  });

  return confirm;
};
