import React, { useMemo } from 'react';
import { TabBar, useRouteScreenTitle } from 'src/commons';
import { InformeCuatrimestral } from './InformeCuatrimestral';
import { PuenteInicialPrimaria } from './PuenteInicialPrimaria';
import { CalificacionesProvider } from '../context';
import { useGetValidYears } from '../hooks/useGetValidYears';
import { useProyeccionEstadoLibbyFetch } from 'src/app/business';
import { useRolesContext } from 'src/context/RolesContext';
import { CURRENT_CICLO_LECTIVO } from '../../Acap';

export const Informesc = () => {
  useRouteScreenTitle('Informes');

  const {
    selectedRole: { localizacionId },
  } = useRolesContext();

  const filterProyeccionEstado = useMemo(
    () => ({
      0: [{ path: 'localizacion', value: localizacionId }],
    }),
    [localizacionId],
  );

  const { data: proyeccionEstado = [] } = useProyeccionEstadoLibbyFetch({
    filter: filterProyeccionEstado,
    orderBy: 'cicloLectivo',
    direction: 'desc',
  });

  const { anio, working } = useGetValidYears();

  const tabsToShow = [
    {
      title: 'Informe cuatrimestral',
      component: (
        <InformeCuatrimestral validYears={{ anio }} working={working} />
      ),
    },
  ];

  if (
    proyeccionEstado[0]?.cicloLectivo?.idCicloLectivo ===
      CURRENT_CICLO_LECTIVO &&
    anio.some((item) => [15, 19].includes(Number(item.value)))
  ) {
    tabsToShow.push({
      title: 'Puente inicial primaria',
      component: <PuenteInicialPrimaria />,
    });
  }

  return (
    <CalificacionesProvider>
      <TabBar content={tabsToShow} />
    </CalificacionesProvider>
  );
};
