import React from 'react';
import { Typography, Grid, Chip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { grey } from 'src/theme/colors';

const useStyles = makeStyles({
  typography: {
    color: grey.medium,
    fontWeight: 'bold',
    verticalAlign: 'center',
    fontSize: 13,
  },
  chip: {
    backgroundColor: '#e8e8e8',
    borderRadius: 4,
    color: '#464646',
  },
});

interface AreaEspacioCurricularChipsProps {
  area: string;
  espacioCurricular: string;
}

// TODO: move the Chip an typography implementations
export const AreaEspacioCurricularChips = ({
  area,
  espacioCurricular,
}: AreaEspacioCurricularChipsProps) => {
  const classes = useStyles();
  return (
    <Grid container spacing={1} alignItems="center">
      <Grid item>
        <Typography className={classes.typography}>Area:</Typography>
      </Grid>
      <Grid item>
        <Chip label={area} className={classes.chip} />
      </Grid>
      <Grid item>
        <Typography className={classes.typography}>
          Espacio curricular:
        </Typography>
      </Grid>
      <Grid item>
        <Chip label={espacioCurricular} className={classes.chip} />
      </Grid>
    </Grid>
  );
};
