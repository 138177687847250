import React from 'react';
import { useMemo } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import { primary } from 'src/theme/colors';
import { OptionsModal } from 'src/commons';
import customFormDialog from 'src/commons/services/customFormDialog';
import ModalDescripcion from './ModalDescripcion';

const useStyles = makeStyles({
  descripcionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const useAccionesColumns = (
  setIsCreatingAnAccion: any,
  accion: any,
  handleRemoveAccion: any,
) => {
  const classes = useStyles();

  const onDescriptionClick = (description: any) => async () => {
    await customFormDialog.show({
      title: 'Descripción de Acción',
      sizeWidth: 'lg',
      renderComponent: <ModalDescripcion description={description} />,
    });
  };

  const columns = useMemo(
    () => [
      {
        id: 'CicloLectivo',
        width: '2%',
        label: 'Ciclo Lectivo',
        render: (value: any, row: any) => row.cicloLectivo.anio,
        orderById: 'nombre',
        hideSortIcon: true,
      },
      {
        id: 'accion',
        width: '15%',
        label: 'Acción',
        render: (value: any, row: any) => row.nombre,
        orderById: 'nombre',
        hideSortIcon: true,
      },
      {
        id: 'tipoAccion',
        width: '5%',
        label: 'Tipo de acción',
        render: (value: any, row: any) => row.tipoAccion?.nombre,
        orderById: 'cuit',
        hideSortIcon: true,
      },
      {
        id: 'orientaciones',
        width: '20%',
        label: 'Orientaciones',
        render: (value: any, row: any) => {
          let orientaciones = '';
          row?.accionOrientaciones.forEach(
            (orientacionIteration: any, index: any) => {
              if (index === row?.accionOrientaciones.length - 1) {
                orientaciones += orientacionIteration?.orientacion.nombre;
              } else {
                orientaciones += `${orientacionIteration?.orientacion.nombre}, `;
              }
            },
          );

          return orientaciones;
        },
        orderById: 'sector.nombre',
        hideSortIcon: true,
      },
      {
        id: 'descripcion',
        width: '15%',
        label: 'Descripción',
        render: (description: any) => {
          return (
            <div className={classes.descripcionContainer}>
              <Typography>Ver descripción</Typography>
              <IconButton onClick={onDescriptionClick(description)}>
                <VisibilityIcon style={{ color: primary.paleBlueStrong }} />
              </IconButton>
            </div>
          );
        },
        hideSortIcon: true,
      },
      {
        id: 'menu',
        width: '10%',
        label: '',
        hideSortIcon: true,
        noSort: true,
        render: (value: any, row: any) => {
          const options = [
            {
              label: 'Ver acción',
              onClick: () =>
                setIsCreatingAnAccion({
                  enabled: true,
                  accion: accion,
                  row: row,
                  isDisabled: true,
                }),
              hide: false,
            },
            {
              label: 'Duplicar acción',
              onClick: () =>
                setIsCreatingAnAccion({
                  enabled: true,
                  accion: {
                    isCreatingAccion: true,
                    organizacion: accion.organizacion,
                  },
                  row: row,
                  isDisabled: false,
                  duplicateAction: true,
                }),
              hide: false,
            },
            {
              label: 'Editar acción',
              onClick: () =>
                setIsCreatingAnAccion({
                  enabled: true,
                  accion: accion,
                  row: row,
                  isDisabled: false,
                }),
              //hide: row?.cicloLectivo?.idCicloLectivo !== 24,
            },
            {
              label: 'Eliminar acción',
              onClick: () => handleRemoveAccion(row),
              //hide: row?.cicloLectivo?.idCicloLectivo !== 24,
            },
          ];
          return <OptionsModal options={options} />;
        },
      },
    ],
    [
      accion,
      classes.descripcionContainer,
      handleRemoveAccion,
      setIsCreatingAnAccion,
    ],
  );

  return columns;
};
