import React from 'react';
import { Grid, IconButton, Tooltip } from '@material-ui/core';
import customFormDialog from 'src/commons/services/customFormDialog';
import EditIcon from '@material-ui/icons/Edit';
import { MultipleOptionsModal } from '../components/MultipleOptionsModal';
import { IndicadorTipoModal } from '../../informesTypes';
import { useInformesStyles } from '../hooks/useInformesStyles';
import { InformescCustom } from 'src/app/models';

export const InputIndicadorTipoModal = ({
  isInformesClosed,
  indicador,
  row,
  handleDataChange,
}: {
  isInformesClosed: boolean;
  indicador: IndicadorTipoModal;
  row: InformescCustom;
  handleDataChange: (
    data: { name: string; value: string },
    row: InformescCustom,
  ) => void;
}) => {
  const classes = useInformesStyles();

  const handleEditModal = (
    options: any[],
    name: string,
    title: string,
    row: any,
  ) => {
    customFormDialog.show({
      title: '',
      renderComponent: (
        <MultipleOptionsModal
          options={options}
          checkedOptions={row?.nota?.data[name]}
          name={name}
          title={title}
          handleSave={(selectedOptions: []) => {
            handleDataChange(selectedOptions, row);
          }}
        />
      ),
      sizeWidth: 'sm',
    });
  };

  return (
    <Grid>
      <div>
        <Grid
          className={classes.rowSelect}
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Tooltip title={indicador.label}>
            <IconButton
              edge="end"
              aria-label={indicador.label}
              disabled={isInformesClosed}
              onClick={() => {
                handleEditModal(
                  indicador.modalOptions,
                  indicador.id,
                  indicador.label,
                  row,
                );
              }}
            >
              <EditIcon />
            </IconButton>
          </Tooltip>
        </Grid>
      </div>
    </Grid>
  );
};
