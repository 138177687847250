import React, { useMemo, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { useRolesContext } from 'src/context/RolesContext';
import {
  SeccionProvider,
  EspacioCurricularByIdProvider,
} from 'src/app/business';
import { useInfoCards } from './hooks';
import { ArealInterarealProvider } from '../../forms/ArealInterareal/context';
import {
  EspaciosArealPlan,
  ArealPlanArea,
} from '../../../../../../../app/models';
import { ArealInterarealFlowHeader } from './components';
import { InfoCard } from '../../feedbacks/commons';

export interface ArealInterarealPreviewFlowProps {
  data: EspaciosArealPlan;
}

export const ArealInterarealPreviewFlow = ({
  data,
}: ArealInterarealPreviewFlowProps) => {
  const cards = useInfoCards(data);

  const arealAreas = data.arealPlan.arealPlanAreas;
  const { selectedRole } = useRolesContext();
  const seccionFilter = useRef({
    filter: [{ path: 'localizacion', value: selectedRole.localizacionId }],
  });

  const areas = useMemo(
    () => arealAreas?.map((area: ArealPlanArea) => area.area) || [],
    [arealAreas],
  );

  return (
    <>
      <SeccionProvider filter={seccionFilter.current}>
        <>
          <ArealInterarealFlowHeader data={data} toPrint />
          <Grid container>
            <Grid item sm={12}>
              <EspacioCurricularByIdProvider
                id={data.espacioCurricular.idEspacioCurricular}
              >
                <ArealInterarealProvider
                  areas={areas}
                  idAnio={data.espacioCurricular.materia.anio.idAnio}
                  idPlanEstudio={
                    data.espacioCurricular.planEstudio.idPlanEstudio
                  }
                >
                  <InfoCard title="Planificación" cards={cards} />
                </ArealInterarealProvider>
              </EspacioCurricularByIdProvider>
            </Grid>
          </Grid>
        </>
      </SeccionProvider>
    </>
  );
};
