import { useApreciacionValorDAO } from 'src/app/business/acap/ApreciacionValor';
import { useApreciacionActividadDAO } from 'src/app/business/acap/ApreciacionesActividades';

export const useApreciacionSubmit = (
  cb: (error?: { message: string }) => void,
) => {
  const apreciacionDAO = useApreciacionActividadDAO();
  const apreciacionValorDAO = useApreciacionValorDAO();

  return async (form: Acap.Estudiante.ApreciacionForm) => {
    const { evaluaciones, ...apreciacionData } = form;
    if (typeof apreciacionData.idApreciacion === 'undefined') {
      delete apreciacionData.idApreciacion;
    }
    try {
      const apreciacion = await apreciacionDAO
        .aspect('basic')
        .save(apreciacionData);

      await apreciacionValorDAO.aspect('basic').save(
        evaluaciones.map((evaluacion) => ({
          ...evaluacion,
          criterioEvaluacion: evaluacion.criterioEvaluacion,
          apreciacionActividad: apreciacion,
        })),
      );
      cb();
    } catch (error) {
      cb({ message: (error as Error).message });
    }
  };
};
