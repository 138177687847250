import React, { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { grey, primary } from 'src/theme/colors';
import { useRouteScreenTitle } from 'src/commons/hooks/useRouteScreenTitle';
import { EspacioCurricularCard } from './components';
import {
  EspacioCurricular,
  Seccion,
  Anio,
  EspacioCurricularSeccion,
  Planta,
} from '../../../app/models';
import { useRolesContext } from '../../../context/RolesContext';
import { Loading } from '../../../commons/components/Loading';
import { useGeneralContext } from '../../../context/GeneralContext';
import { useGruposSeccion } from './EspacioCurricular/Planificacion/hooks';
import { FilterEspacioCurricular } from './FilterEspacioCurricular';
import { JoinedFilters } from './types';
import { useEspaciocurricularseccioncustomGetRequest } from 'src/app/business/businessCustomEndpoints/espaciocurricularseccioncustom';
import { PermissionBlocker } from 'src/lib/permission';
import { BLOCKER_ID } from 'src/platform/permission/const';
import { useColegioEfectivizadoContext } from '../Calificaciones/context/EfectivizacionProvider';

export interface EspaciosSecciones {
  espacio: EspacioCurricular;
  secciones: Seccion[];
}

export interface EspaciosSeccionesByAnio {
  espaciosSecciones: EspaciosSecciones[];
  anio: Anio;
}

const searchFilters = [
  'espacioCurricular.materia.anio.descripcionAnio',
  'espacioCurricular.descripcion',
  'espacioCurricular.materia.descripcion',
  'espacioCurricular.materia.area.descripcion',
  'espacioCurricular.materia.orientacion.descripcionOrientacion',
];

const orderBy = 'espacioCurricular.descripcion';

const espaciosCurricularesStyle = makeStyles(() => ({
  anioHeader: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingTop: 15,
    paddingBottom: 15,
    paddingLeft: 15,
  },
  headerTitle: {
    fontFamily: 'Open Sans',
    fontSize: 24,
    color: grey.medium,
  },
  addRole: {
    position: 'fixed',
    right: 50,
    bottom: 50,
    zIndex: 1000,
    backgroundColor: primary.lightBlue,
  },
}));

const removeDuplicate = (data: Seccion[]) => {
  const result = data.filter(
    (valueActual, actual, array) =>
      array.findIndex(
        (value) => JSON.stringify(value) === JSON.stringify(valueActual),
      ) === actual,
  );
  return result;
};

// TODO: finish loading
export const EspaciosCurriculares = () => {
  useRouteScreenTitle('Espacios Curriculares');
  const classes = espaciosCurricularesStyle();
  const history = useHistory();
  const { cicloLectivo } = useColegioEfectivizadoContext();
  const match = useRouteMatch();
  const { selectedRole } = useRolesContext();
  const { dispatch } = useGeneralContext();
  const [filter, setFilter] = useState<JoinedFilters>({
    idAnio: '',
    idPlanEstudio: '',
    search: '',
  });
  const [espaciosSecciones, setEspaciosSecciones] = useState<
    Array<EspacioCurricularSeccion | Planta>
  >([]);

  const onCardClick = (item: EspaciosSecciones) => {
    dispatch.selectPlanificationAnio({
      anio: Number(item.espacio.materia.anio.idAnio),
      bloque: item.espacio.materia.tieneBloque,
      isCommonMatter: item.espacio.materia.anio.numeroAnio < 3,
      seccion: item.secciones,
    });
    history.push(
      `${match.url}/${item.espacio.idEspacioCurricular}/${item.espacio.materia.anio.idAnio}`,
    );
  };

  const {
    data: ecs = [],
    request,
    working,
  } = useEspaciocurricularseccioncustomGetRequest<any>({
    url: `/api/public/custom/espaciocurricularseccioncustom?localizacion=${
      selectedRole?.localizacionId
    }&anio=${filter.idAnio}&planEstudio=${filter.idPlanEstudio}&search=${
      filter.search ? filter.search : ''
    }&cicloLectivo=${cicloLectivo?.idCicloLectivo}&nivel=${selectedRole.nivel}`,
    aspect: 'limit_by_espacio',
    autoCall: false,
  });

  useEffect(() => {
    if (ecs.existError && espaciosSecciones.length !== 0) {
      setEspaciosSecciones([]);
    } else if (!working) {
      setEspaciosSecciones(ecs || []);
    }
  }, [working, setEspaciosSecciones, ecs, espaciosSecciones]);

  useEffect(() => {
    request();
  }, [request]);

  const { gruposEspacios, working: gruposWorking } = useGruposSeccion(
    espaciosSecciones.map(
      (item) => item?.espacioCurricular?.idEspacioCurricular,
    ),
  );

  // TODO: dada la configuracion de espacios secciones, no podria haber secciones repetidas para mismo espacio curricular
  const espaciosSeccionesReduce = espaciosSecciones
    .reduce<EspaciosSecciones[]>((acumn, espaciossec) => {
      const id = espaciossec.espacioCurricular.idEspacioCurricular;
      const grupoSeccion = gruposEspacios.find(
        (item) => item.idEspacioCurricular === id,
      );
      const data = acumn.find(
        (acum) => acum.espacio.idEspacioCurricular === id,
      );
      if (data) {
        data.secciones.push({ ...espaciossec.seccion });
        !!grupoSeccion &&
          data.secciones.push({ ...(grupoSeccion?.seccion as Seccion) });
      } else {
        acumn.push({
          espacio: { ...espaciossec.espacioCurricular },
          secciones: grupoSeccion
            ? [
                { ...espaciossec.seccion },
                { ...(grupoSeccion?.seccion as Seccion) },
              ]
            : [{ ...espaciossec.seccion }],
        });
      }
      return acumn;
    }, [])
    .map((item) => ({
      ...item,
      secciones: removeDuplicate(item.secciones),
    }))
    .reduce<EspaciosSeccionesByAnio[]>((acumn, { espacio, secciones }) => {
      const anio = { ...espacio.materia.anio };
      const matchByAnio = acumn.find(
        (acum) => acum.anio.idAnio === anio.idAnio,
      );
      if (matchByAnio) {
        matchByAnio.espaciosSecciones.push({ espacio, secciones });
      } else {
        acumn.push({
          anio,
          espaciosSecciones: [{ espacio, secciones }],
        });
      }
      return acumn;
    }, [])
    .sort((a, b) => {
      if (a.anio.numeroAnio > b.anio.numeroAnio) return 1;
      if (a.anio.numeroAnio < b.anio.numeroAnio) return -1;
      return 0;
    });
  const Component = espaciosSeccionesReduce.length ? (
    espaciosSeccionesReduce.map((_item, _index) => {
      return (
        <div key={_index}>
          <h1>
            <Grid className={classes.anioHeader}>
              <span className={classes.headerTitle}>
                {_item.anio.descripcionAnio}
              </span>
            </Grid>
          </h1>
          <Grid container spacing={2}>
            {_item.espaciosSecciones.map((item: EspaciosSecciones) => (
              <Grid item xs={3} key={item.espacio.idEspacioCurricular}>
                <EspacioCurricularCard
                  secciones={item.secciones}
                  espacioCurricular={item.espacio}
                  onClick={() => onCardClick(item)}
                />
              </Grid>
            ))}
          </Grid>
          {/* TODO: MIESC-428 */}
          {/* <IconButton
          onClick={() => setProfile('autoregistracion')}
          className={classes.addRole}
        >
          <AddIcon style={{ color: 'white' }} />
        </IconButton> */}
        </div>
      );
    })
  ) : (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      style={{ height: '400px' }}
    >
      <Typography
        style={{
          fontSize: '40px',
          marginTop: '50px',
          color: 'gray',
          fontWeight: 'bold',
        }}
      >
        No hay espacios curriculares asignados para este usuario
      </Typography>
    </Grid>
  );

  return (
    <PermissionBlocker id={BLOCKER_ID.ESPACIOS_CURRICULARES}>
      <>
        <div style={{ width: 'auto' }}>
          <FilterEspacioCurricular
            filter={filter}
            onChangeFilter={setFilter}
            disabled={gruposWorking || working}
          />
        </div>
        {gruposWorking || working ? <Loading /> : Component}
      </>
    </PermissionBlocker>
  );
};
