import { LibbyFetchDAO } from './LibbyFetchDAO';

export class EstadoDisciplinaDAO extends LibbyFetchDAO {
  constructor() {
    super('estado_disciplina', 'idEstadoDisciplina');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
