import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  currentEspacios: {
    // backgroundColor: 'red',
    marginTop: '5vh',
    marginLeft: '2.5vh',
    marginRight: '2.5vh',
    webkitBoxShadow: '0px 0px 17px 1px rgba(0,0,0,0.3)',
    boxShadow: '0px 0px 17px 1px rgba(0,0,0,0.3)',
  },
  firstContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
  },
  currentEspaciosContainer: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-around',
  },
  espacioCurricularParagraph: {
    width: '40vh',
    paddingLeft: '10px',
    backgroundColor: '#F2F2F2',
  },
  paragraph: {
    fontFamily: 'Open Sans',
  },
  espacioCurricular: {
    fontFamily: 'Open Sans',
    borderBottom: '1px solid #d6d6d6',
    margin: '0',
    paddingBottom: 16,
    paddingTop: 16,
  },
  espaciosCurricularesParagraph: {
    width: '40vh',
    paddingLeft: '10px',
  },
}));

export const StepThree = ({ dataModified }: any) => {
  const classes = useStyles();

  return (
    <>
      <Grid className={classes.firstContainer}>
        {dataModified.map((espacio: any, index: number) => {
          return (
            <Grid item className={classes.currentEspacios}>
              <Grid container className={classes.currentEspaciosContainer}>
                <div className={classes.espacioCurricularParagraph}>
                  <p className={classes.paragraph}>
                    Los espacios curriculares del plan{' '}
                    {
                      espacio.planesOrigen[0]?.planEstudio
                        ?.descripcionPlanEstudio
                    }{' '}
                    equivalen a{' '}
                    {
                      espacio.planesDestino[0]?.planEstudio
                        ?.descripcionPlanEstudio
                    }
                  </p>
                </div>
                <div className={classes.espaciosCurricularesParagraph}>
                  {espacio.planesOrigen.map((espacioCurricular: any) => (
                    <>
                      <p className={classes.espacioCurricular}>
                        {`${espacioCurricular.descripcion} equivale a ${
                          espacioCurricular.matchDescription ?? 'No Trasladar'
                        }`}
                      </p>
                    </>
                  ))}
                </div>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};
