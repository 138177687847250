import React, { FC } from 'react';
import { SimpleSelect } from 'src/commons/components/Select';

interface SeccionSelectProps {
  value: string;
  content: any;
  disableUnderline?: boolean;
  handleChange: (value: React.ChangeEvent<{ value: unknown }>) => void;
}

export const SeccionSelect: FC<SeccionSelectProps> = ({
  value,
  content,
  disableUnderline,
  handleChange,
}) => {
  return (
    <SimpleSelect
      value={value}
      handleChange={handleChange}
      placeholder="Seleccioná Grupo/Grado"
      content={content}
      labelKey="nombreSeccion"
      valueKey="idSeccion"
      disableUnderline={disableUnderline}
    />
  );
};
