import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby';
import { businessOptions } from '../businessOptions';
import { DevolucionOrganizacion } from '../../models/acap';
import { DevolucionOrganizacionDAO } from 'src/platform/libby/dao';

const DAO_NAME = 'devoluciones_organizacion';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idDevolucion',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idDevolucion',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.DevolucionOrganizacion
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.DevolucionOrganizacion,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.DevolucionOrganizacion.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<DevolucionOrganizacion, DevolucionOrganizacionDAO>(
  DAO_NAME,
  optionsToUse,
);

// hooks
export const useDevolucionOrganizacionFetchById = fetchById;
export const useDevolucionOrganizacionLibbyCall = libbyCall;
export const useDevolucionOrganizacionDAO = libbyDAO;
export const useDevolucionOrganizacionLibbyFetch = libbyFetch;

// components/hoc
export const DevolucionOrganizacionAutocomplete = entityAutocomplete;
export const DevolucionOrganizacionCall = renderPropCall;
export const DevolucionOrganizacionById = renderPropFetchById;
export const DevolucionOrganizacionSelect = simpleSelect;
export const DevolucionOrganizacionAccordionSelector = accordionSelector;
export const DevolucionOrganizacionSelectedChips = selectedChips;

// context
// ByIdContext
export const useDevolucionOrganizacionByIdContext = useByIdContext;
export const DevolucionOrganizacionByIdProvider = ByIdProvider;
export const DevolucionOrganizacionByIdContext = ByIdContext;
// GeneralContext
export const useDevolucionOrganizacionContext = useGeneralContext;
export const DevolucionOrganizacionProvider = GeneralProvider;
export const DevolucionOrganizacionContext = GeneralContext;
