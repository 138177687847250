import { LibbyFetchDAO } from './LibbyFetchDAO';

export class EstadoPromocionPaseAnioDAO extends LibbyFetchDAO {
  constructor() {
    super('estado_promocion_pase_anio', 'idEstadoPase');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
