import { Rules } from 'src/lib/permission/types';
import { SIDEMENU_ID } from '../../const';

// route docente
export const docenteSecundarioRuleSet: Rules = {
  idRule: 'custom-1-rol-2-nivel-3',
  routes: [
    /* { key: 'presentismo' } */
  ],
  sideMenu: [
    /* { id: SIDEMENU_ID.PRESENTISMO } */
  ],
  blockers: [],
  tabBars: [],
};
