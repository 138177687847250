import { TemplatePropertyType, TemplateProperty } from './types';

const getPropertyType = (type: TemplatePropertyType) => {
  let property;
  switch (type.name) {
    case 'string':
      property = '';
      break;
    case 'boolean':
      property = false;
      break;
    case 'number':
      property = 0;
      break;
    case 'date':
      property = '';
      break;
    case 'array':
      property = [];
      break;
    case 'object':
      if (typeof type.kind === 'string' || !Array.isArray(type.kind)) {
        throw new Error(
          'an object property type must have a TemplateProperty[] in kind property',
        );
      }
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      property = makeInitialValue(type.kind);
      break;
    default:
      property = '';
      break;
  }
  return property;
};

export const makeInitialValue = <T = any>(template: TemplateProperty[]) => {
  const templateObject: T = {} as T;
  // eslint-disable-next-line no-restricted-syntax
  for (const temp of template) {
    const property = getPropertyType(temp.type);
    templateObject[temp.name as keyof T] = property;
  }
  return templateObject;
};
