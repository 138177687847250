import React, {
  createContext,
  useReducer,
  useContext,
  useMemo,
  useState,
  useEffect,
} from 'react';
import { AnyObject } from 'src/commons';
import { SET_SECTION, setSection, SET_CICLO, setCiclo } from './actions';
import {
  useInscripcionAlumnoDAO,
  useInscripcionAlumnoLibbyFetch,
} from 'src/app/business';
import { AlumnoDAO } from 'src/platform/libby/dao';
import { SelectedRole } from 'src/context/RolesContext';
import { useLibbyFetch as useLibbyFetchWithAspect } from 'src/lib/libby/hooks/useLibbyFetch';
import { InscripcionAlumno, SolicitudPases } from 'src/app/models';
import { useColegioEfectivizadoContext } from '../../Calificaciones/context/EfectivizacionProvider';

interface GeneralContextProps {
  children: JSX.Element;
  selectedRole: SelectedRole;
  setInscriptos: React.Dispatch<React.SetStateAction<InscripcionAlumno[]>>;
  setGestionPases: React.Dispatch<React.SetStateAction<SolicitudPases[]>>;
}

interface GeneralActions {
  type: string;
  payload?: AnyObject | any;
}

const initialState: any = {
  section: null,
  ciclo: null,
};

const reducer = (state = initialState, action: GeneralActions) => {
  switch (action.type) {
    case SET_SECTION:
      const { section } = action.payload;
      return {
        ...state,
        section,
      };
    case SET_CICLO:
      const { ciclo } = action.payload;
      return {
        ...state,
        ciclo,
      };
    default:
      return {
        ...state,
      };
  }
};

const MatriculationContext = createContext(initialState);

export const useMatriculationContext = () => useContext(MatriculationContext);

export const MatriculationContextProvider = ({
  children,
  selectedRole,
  setInscriptos,
  setGestionPases,
}: GeneralContextProps) => {
  const [state, dispatcher] = useReducer(reducer, initialState);

  const { cicloLectivo: cicloLectivoContext } = useColegioEfectivizadoContext();
  const CURRENT_YEAR = Number(cicloLectivoContext?.idCicloLectivo ?? '');

  const idLocation = selectedRole?.localizacionId;

  const filterDefault = useMemo(
    () => ({
      1: [
        { path: 'localizacionDestino.idLocalizacion', value: idLocation },
        { path: 'localizacionOrigen.idLocalizacion', value: idLocation },
      ],
      2: [{ path: 'estadoSolicitudPases.idEstadoSolicitudPases', value: 3 }],
    }),
    [idLocation],
  );

  //useIncriptionALumnoslibbyFetch

  const paramsFetchManagement = {
    daoName: 'solicitud_pases',
    filter: filterDefault,
    aspect: 'matriculacion_gestionDePases_solicitudDePasesRequest',
  };

  const {
    data: passRequest = [],
    working,
    reFetch: badgeGestionDePaseReFetch,
    fetchMore,
  } = useLibbyFetchWithAspect(paramsFetchManagement);

  setGestionPases(passRequest as SolicitudPases[]);

  const inscripcionAlumnoDAO = useInscripcionAlumnoDAO();

  const filterAlumno = useMemo(
    () => ({
      0: [{ path: 'alumno.estadoMatricula', value: 2 }],
      1: [{ path: 'isDeleted', value: false }],
      2: [{ path: 'localizacion', value: selectedRole.localizacionId }],
      3: [{ path: 'cicloLectivo.idCicloLectivo', value: CURRENT_YEAR }],
      4: [{ path: 'nivel.idNivel', value: Number(selectedRole.nivel) }],
    }),
    [CURRENT_YEAR, selectedRole.localizacionId, selectedRole.nivel],
  );

  const {
    data: inscriptos = [],
    reFetch: badgeInscriptosReFetch,
    working: workingIscriptos,
  } = useInscripcionAlumnoLibbyFetch({
    filter: filterAlumno,
    limit: 200,
    aspect: 'inscripcion_alumno',
  });

  setInscriptos(inscriptos);

  const dispatch = useMemo(
    () => ({
      setSection: (payload: any) => dispatcher(setSection(payload)),
      setCiclo: (payload: string) => dispatcher(setCiclo(payload)),
    }),
    [dispatcher],
  );

  const contextValue = useMemo(
    () => ({
      state,
      dispatch,
      badgeGestionDePaseReFetch,
      badgeInscriptosReFetch,
      inscriptos,
      passRequest,
      workingIscriptos,
    }),
    [
      state,
      dispatch,
      badgeGestionDePaseReFetch,
      badgeInscriptosReFetch,
      inscriptos,
      passRequest,
      workingIscriptos,
    ],
  );

  return (
    <MatriculationContext.Provider value={contextValue}>
      {children}
    </MatriculationContext.Provider>
  );
};
