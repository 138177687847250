import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useInputOnChangeValue, useDebounce } from 'src/commons/hooks';
import { MainInput, SimpleSelect } from 'src/commons/components';
import { selectColumns } from 'src/screens/Private/Matriculation/passManagement/utils/const';
import { EnrolledStudentSelectTable } from './EnrolledStudentSelectTable';
import { AlumnAdd } from './AddStudentForm';
import { CicloLectivo } from 'src/app/models';

const customStyles = {
  container: { padding: '24px', paddingTop: '0px' },
};

interface EnrolledSelectTypesProps {
  localizacionId: string | number;
  commonSchool?: boolean;
  cicloLectivoActual?: CicloLectivo;
  handleAddExternalAlumn: (alumn: AlumnAdd) => Promise<void>;
}

export const EnrolledSelectStudent = ({
  localizacionId,
  commonSchool,
  cicloLectivoActual,
  handleAddExternalAlumn,
}: EnrolledSelectTypesProps) => {
  const [formValues, setFormValues] = useState({
    select: '1',
    params: '',
    path: 'alumno.persona.apellido',
  });
  const [search, setSearch] = useState('');
  const searchDevounced = useDebounce(search, 500);
  useEffect(() => {
    setFormValues({
      ...formValues,
      params: searchDevounced,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDevounced]);

  const handleChangeSelect = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    const pathValue: any = selectColumns.filter(
      ({ value }: any) => value === target.value,
    );

    setFormValues({
      ...formValues,
      select: target.value,
      path: pathValue.length && pathValue[0].path,
    });
  };

  const handleChangeParams = useInputOnChangeValue(setSearch);

  return (
    <Grid container style={customStyles.container} spacing={2}>
      <Grid item xs={4}>
        <SimpleSelect
          placeholder=""
          labelKey="label"
          valueKey="value"
          handleChange={handleChangeSelect}
          value={formValues.select}
          name="column"
          content={selectColumns}
        />
      </Grid>
      <Grid item xs={8}>
        <MainInput
          type="search"
          placeholder=""
          name="search"
          handleChange={handleChangeParams}
          value={search}
          fullWidth
          customStyle={{ padding: 9.5, width: '95%' }}
        />
      </Grid>
      <Grid item xs={12}>
        {formValues.params && (
          <EnrolledStudentSelectTable
            handleAddExternalAlumn={handleAddExternalAlumn}
            localizacionId={localizacionId}
            cicloLectivoActual={cicloLectivoActual}
            commonSchool={commonSchool}
            formValues={formValues}
          />
        )}
      </Grid>
    </Grid>
  );
};
