import { useMemo } from 'react';
import { primary } from 'src/theme/colors';

const headerStyle = {
  color: primary.lightBlue,
  textAlign: 'center',
};

export const useConvivenciaTableColumns = () => {
  return useMemo(
    () => [
      {
        id: 'nombre',
        label: 'Alumno',
        width: '10%',
        hideSortIcon: true,
        style: headerStyle,
      },
      {
        id: 'acuerdosGrupales',
        label: 'Acepta acuerdos grupales y normas institucionales.',
        width: '10%',
        hideSortIcon: true,
        style: headerStyle,
      },
      {
        id: 'situacionesDeJuego',
        label: 'Participa en situaciones de juego.',
        width: '10%',
        hideSortIcon: true,
        style: headerStyle,
      },
      {
        id: 'resuelveConflictos',
        label: 'Resuelve conflictos a través del diálogo.',
        width: '10%',
        hideSortIcon: true,
        style: headerStyle,
      },
      {
        id: 'repetuosoConAdultos',
        label: 'Se vincula de manera respetuosa con los adultos.',
        width: '10%',
        hideSortIcon: true,
        style: headerStyle,
      },
      {
        id: 'repetuosoConSusPares',
        label: 'Se vincula de manera respetuosa con sus pares.',
        width: '10%',
        hideSortIcon: true,
        style: headerStyle,
      },
    ],
    [],
  );
};
