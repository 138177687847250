import {
  useCicloAdultosLibbyCall,
  useCicloLectivoAdultosLibbyCall,
  useCicloLectivoLibbyCall,
  useNivelLibbyCall,
  useTurnoLibbyCall,
} from '../../../../../../app/business';
import {
  Anio,
  Ciclo,
  CicloAdultos,
  CicloLectivo,
  CicloLectivoAdultos,
  Nivel,
  Turno,
} from '../../../../../../app/models';

export const useRegisteredEntitiesCall = () => {
  const cicloLectivo = useCicloLectivoLibbyCall<CicloLectivo[]>({
    methodName: 'findAll',
  });
  const cicloAdultos = useCicloAdultosLibbyCall<CicloAdultos[]>({
    methodName: 'findAll',
  });
  const nivel = useNivelLibbyCall<Nivel[]>({ methodName: 'findAll' });
  const turno = useTurnoLibbyCall<Turno[]>({ methodName: 'findAll' });
  const cicloLectivoAdultos = useCicloLectivoAdultosLibbyCall<
    CicloLectivoAdultos[]
  >({ methodName: 'findAll' });
  return {
    cicloLectivo: cicloLectivo.data || [],
    cicloAdultos: cicloAdultos.data || [],
    nivel: nivel.data || [],
    turno: turno.data || [],
    cicloLectivoAdultos: cicloLectivoAdultos.data || [],
    working:
      cicloLectivo.working ||
      cicloAdultos.working ||
      nivel.working ||
      turno.working ||
      cicloLectivoAdultos.working ||
      !cicloLectivo.data ||
      !cicloAdultos.data ||
      !nivel.data ||
      !turno.data ||
      !cicloLectivoAdultos.data,
  };
};
