import React, { useCallback } from 'react';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { RepositorioItem } from '../../../../../../app/models';
import { FormContextProvider } from '../../../../../../lib/templates/context';
import { repositorioItemTemplate } from '../../template';
import { BasicForm, FiltersForm } from './components';
import { FormFooter } from './components/FormFooter';
import { useRepositorioItemDAO } from '../../../../../../app/business';
import { AnyObject } from '../../../../../../commons/types';
import { toBase64 } from '../../../../../../utils';

type InitialValueFile = {
  files: File[];
  value: string;
};

export interface InitialValueDefinition {
  nombre: string;
  descripcion: string;
  fuente: string;
  fuente_anio: string;
  url?: string;
  file?: InitialValueFile;
  idRepositorioTipoItem: string | number;
  idRepositorioAudienciaItem?: string | number;
  idNivel: string | number;
  idCiclo: string | number;
  idAnio: string | number;
  idEspacioCurricular?: string | number;
  idTipoRecurso: string | number;
  idArea?: string | number;
  idTransversal?: string | number;
}

const getFileProperties = async (file: InitialValueFile) => {
  const base64: string = await toBase64(file.files[0]);
  const pieces = base64.split('base64,');
  return {
    file_name: file.value,
    base64: pieces[pieces.length - 1],
    mime_type: file.files[0].type,
  };
};

export interface RepositorioItemFormProps {
  initialValue: InitialValueDefinition;
  raw?: RepositorioItem;
}

export const RepositorioItemForm = ({
  initialValue,
  raw,
}: RepositorioItemFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const repositorioItemDAO = useRepositorioItemDAO();
  const onSubmit = useCallback(
    async (values: InitialValueDefinition) => {
      try {
        if (raw) {
          const updatedItem: AnyObject = {
            ...raw,
            anio: values.idAnio,
            fuente_anio: values.fuente_anio,
            fuente: values.fuente,
            espacioCurricular: values.idEspacioCurricular,
            ciclo: values.idCiclo,
            nivel: values.idNivel,
            repositorioAudienciaItem: values.idRepositorioAudienciaItem,
            repositorioTipoItem: values.idRepositorioTipoItem,
            descripcion: values.descripcion,
            nombre: values.nombre,
            url: values.url,
            tipoRecurso: values.idTipoRecurso,
            area: values.idArea,
            transversal: values.idTransversal,
          };
          if (values.files) {
            const { file_name, base64, mime_type } = await getFileProperties(
              values.files,
            );
            updatedItem.file_name = file_name;
            updatedItem.base64 = base64;
            updatedItem.mime_type = mime_type;
          }
          await repositorioItemDAO.save(updatedItem);
        } else {
          const newItem: AnyObject = {
            anio: values.idAnio,
            fuente_anio: values.fuente_anio,
            fuente: values.fuente,
            espacioCurricular: values.idEspacioCurricular,
            ciclo: values.idCiclo,
            nivel: values.idNivel,
            repositorioAudienciaItem: values.idRepositorioAudienciaItem,
            repositorioTipoItem: values.idRepositorioTipoItem,
            descripcion: values.descripcion,
            nombre: values.nombre,
            fechaCreacion: moment().toISOString(),
            url: values.url,
            tipoRecurso: values.idTipoRecurso,
            area: values.idArea,
            transversal: values.idTransversal,
          };
          if (values.files) {
            const { file_name, base64, mime_type } = await getFileProperties(
              values.files,
            );
            newItem.file_name = file_name;
            newItem.base64 = base64;
            newItem.mime_type = mime_type;
          }
          await repositorioItemDAO.save(newItem);
        }
        enqueueSnackbar('Se ha guardado con éxito', { variant: 'success' });
        history.goBack();
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Error al guardar', { variant: 'error' });
      }
    },
    [enqueueSnackbar, history, raw, repositorioItemDAO],
  );
  return (
    <FormContextProvider
      initialValues={initialValue}
      onSubmit={onSubmit}
      template={repositorioItemTemplate}
    >
      <BasicForm />
      <FiltersForm />
      <FormFooter />
    </FormContextProvider>
  );
};
