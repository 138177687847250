import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { EspaciosArealPlan } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'espacios_areal_plan';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEspaciosArealPlan',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEspaciosArealPlan',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EspaciosArealPlan
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EspaciosArealPlan,
      hoc: { ...defaultOptions.hoc, ...businessOptions.EspaciosArealPlan.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EspaciosArealPlan>(DAO_NAME, optionsToUse);

// hooks
export const useEspaciosArealPlanFetchById = fetchById;
export const useEspaciosArealPlanLibbyCall = libbyCall;
export const useEspaciosArealPlanDAO = libbyDAO;
export const useEspaciosArealPlanLibbyFetch = libbyFetch;

// components/hoc
export const EspaciosArealPlanAutocomplete = entityAutocomplete;
export const EspaciosArealPlanCall = renderPropCall;
export const EspaciosArealPlanById = renderPropFetchById;
export const EspaciosArealPlanSelect = simpleSelect;
export const EspaciosArealPlanAccordionSelector = accordionSelector;
export const EspaciosArealPlanSelectedChips = selectedChips;

// context
// ByIdContext
export const useEspaciosArealPlanByIdContext = useByIdContext;
export const EspaciosArealPlanByIdProvider = ByIdProvider;
export const EspaciosArealPlanByIdContext = ByIdContext;
// GeneralContext
export const useEspaciosArealPlanContext = useGeneralContext;
export const EspaciosArealPlanProvider = GeneralProvider;
export const EspaciosArealPlanContext = GeneralContext;
