import { REQUEST_TYPES } from 'src/lib/libby/hooks/useLibbyRequest';
import { makeEntityLibbyRequest } from '../../../lib/libby/hooks/makeEntityLibbyRequest';

const DAO_NAME = 'distritoslocalizacionescustom';

// hooks
export const useDistritosLocalizacionesCustomGetRequest =
  makeEntityLibbyRequest(DAO_NAME, REQUEST_TYPES.GET);
export const useDistritosLocalizacionesCustomPostRequest =
  makeEntityLibbyRequest(DAO_NAME, REQUEST_TYPES.POST);
export const useDistritosLocalizacionesCustomPutRequest =
  makeEntityLibbyRequest(DAO_NAME, REQUEST_TYPES.PUT);
export const useDistritosLocalizacionesCustomDeleteRequest =
  makeEntityLibbyRequest(DAO_NAME, REQUEST_TYPES.DELETE);
