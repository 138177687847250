import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { GruposPlanEstudioNivel } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'grupos_plan_estudio_nivel';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idGruposPlanEstudioNivel',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idGruposPlanEstudioNivel',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.GruposPlanEstudioNivel
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.GruposPlanEstudioNivel,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.GruposPlanEstudioNivel.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<GruposPlanEstudioNivel>(DAO_NAME, optionsToUse);

// hooks
export const useGruposPlanEstudioNivelFetchById = fetchById;
export const useGruposPlanEstudioNivelLibbyCall = libbyCall;
export const useGruposPlanEstudioNivelDAO = libbyDAO;
export const useGruposPlanEstudioNivelLibbyFetch = libbyFetch;

// components/hoc
export const GruposPlanEstudioNivelAutocomplete = entityAutocomplete;
export const GruposPlanEstudioNivelCall = renderPropCall;
export const GruposPlanEstudioNivelById = renderPropFetchById;
export const GruposPlanEstudioNivelSelect = simpleSelect;
export const GruposPlanEstudioNivelAccordionSelector = accordionSelector;
export const GruposPlanEstudioNivelSelectedChips = selectedChips;

// context
// ByIdContext
export const useGruposPlanEstudioNivelByIdContext = useByIdContext;
export const GruposPlanEstudioNivelByIdProvider = ByIdProvider;
export const GruposPlanEstudioNivelByIdContext = ByIdContext;
// GeneralContext
export const useGruposPlanEstudioNivelContext = useGeneralContext;
export const GruposPlanEstudioNivelProvider = GeneralProvider;
export const GruposPlanEstudioNivelContext = GeneralContext;
