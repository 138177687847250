import { LibbyFetchDAO } from './LibbyFetchDAO';

export class InformesHistoryDAO extends LibbyFetchDAO {
  constructor() {
    super('informeshistory', 'informeHistoryId');
  }

  getByIdAlumno(filter: any) {
    return this.fetch(filter);
  }

  getInformesForGestion(params: any) {
    return this.query()
      .equals('cicloLectivo', params.cicloLectivo)
      .equals('tipoInforme', params.tipoInforme)
      .in('alumno', params.alumnos)
      .run();
  }
}
