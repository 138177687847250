import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { MainInput } from 'src/commons';
import { MainButton, SimpleSelect } from 'src/commons/components';
import { grey } from 'src/theme/colors';
import { CicloLectivoSelect, useAnioLibbyFetch } from 'src/app/business';
import { Loading } from 'src/commons/components/Loading';
import sortAnioSelect from 'src/screens/Private/Matriculation/passManagement/utils/sortAnioSelectContent';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
import { useRolesContext } from 'src/context/RolesContext';
import { typeEstablishment, level } from 'src/commons/const';
import { useGetValidYears } from 'src/screens/Private/Calificaciones/hooks/useGetValidYears';
import { Anio } from 'src/app/models';

const { SCHOOLD_TECHNIQUE } = typeEstablishment;

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  containerStyles: { marginTop: 23 },
  searchGrid: { marginTop: 10 },
};

export const FilterTablesPromotion = ({
  formValues,
  handleChange,
  setSearch,
  search,
  handleShowModalProyeccion,
  isModalDisabled,
  proyeccionEstado,
  jornadas,
  turnos,
}: Promocion.FiltersPromotionProps) => {
  let anioValid: Anio[] = [];
  const { anio: validAnio } = useGetValidYears();
  const {
    selectedRole: { nivel, tipoEstablecimiento },
  } = useRolesContext();
  const anioFilter = useMemo(
    () => ({
      tipoEstablecimiento:
        tipoEstablecimiento === SCHOOLD_TECHNIQUE ||
        Number(nivel) === level.PRIMARIO ||
        Number(nivel) === level.INICIAL
          ? {}
          : [{ path: 'numeroAnio', value: 6, method: 'notEquals' }],
    }),
    [tipoEstablecimiento, nivel],
  );
  const { data: year = [] } = useAnioLibbyFetch({
    orderBy: 'numeroAnio',
    direction: 'asc',
    filter: anioFilter,
  });
  const { anio, turno, jornada } = formValues;
  const { cicloLectivo: cicloLectivoContext } = useColegioEfectivizadoContext();
  const CURRENT_YEAR = cicloLectivoContext?.anio || 0;

  validAnio.forEach((v) => {
    year.filter((a) => {
      if (v.value === a.idAnio) {
        anioValid.push(a);
      }
    });
  });
  return (
    <>
      <Grid
        container
        justifyContent="flex-start"
        alignItems="center"
        spacing={3}
        style={customStyles.containerStyles}
      >
        <Grid item xs={2}>
          <CicloLectivoSelect
            name="cicloLectivo"
            title="Ciclo Lectivo"
            placeholder="Seleccioná Ciclo Lectivo"
            labelKey="anio"
            valueKey="anio"
            handleChange={handleChange}
            value={CURRENT_YEAR}
            customStyleTitle={customStyles.customStyleTitleSelect}
            disabled={true}
          />
        </Grid>
        <Grid item xs={2}>
          <SimpleSelect
            name="anio"
            title="Año"
            placeholder="Seleccioná Año"
            labelKey="descripcionAnio"
            valueKey="idAnio"
            handleChange={handleChange}
            content={sortAnioSelect(anioValid)} // AYUDA!!
            value={anio}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
        <Grid item xs={2}>
          <SimpleSelect
            name="turno"
            title="Turno"
            placeholder="Seleccioná Turno"
            labelKey="descripcionTurno"
            valueKey="idTurno"
            handleChange={handleChange}
            content={turnos}
            value={turno}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
        <Grid item xs={2}>
          <SimpleSelect
            handleChange={handleChange}
            title="Jornada"
            value={jornada}
            name="jornada"
            labelKey="label"
            placeholder="Seleccioná Jornada"
            valueKey="label"
            content={jornadas}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
        <Grid item xs={2}>
          <Grid
            container
            spacing={3}
            alignItems="flex-end"
            style={customStyles.searchGrid}
          >
            {proyeccionEstado ? (
              <MainButton
                title={`Proyecciones ${CURRENT_YEAR + 1}`}
                handleOnClick={handleShowModalProyeccion}
                disabled={isModalDisabled}
              />
            ) : (
              <Loading />
            )}
          </Grid>
        </Grid>
        <Grid item xs={2}>
          <Grid
            container
            justifyContent="flex-end"
            spacing={3}
            alignItems="flex-end"
            style={customStyles.searchGrid}
          >
            <Grid item xs={12}>
              <MainInput
                name="search"
                handleChange={(e) => setSearch(e.target.value)}
                placeholder="Buscar"
                type="search"
                value={search}
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
