import { CommonEndpointDAO } from './CommonEndpointDAO';
interface generateParams {
  idSeccion?: number;
  idAlumno?: number;
  idTipoInforme: number;
  idNivel: number;
  idPeriodo: number;
  idUsuario: number;
}

export class GenerarInformeDAO extends CommonEndpointDAO {
  constructor() {
    super('generarinforme');
  }

  get(params: any = {}): any {
    return super.get(params);
  }

  post(body: generateParams): any {
    return super.post(body);
  }

  put(body: any = {}): any {
    return super.put(body);
  }

  delete(body: any = {}): any {
    return super.delete(body);
  }
}
