import { useCallback, useEffect, useMemo, useState } from 'react';
import { LibbyFetchReturn } from 'src/lib/libby';
import {
  RecuperacionFactory,
  RecuperacionResponse,
} from '../../CalificacionesSecundario/factories/RecuperacionFactory';
import { useCalificacionesSecundarioContext } from '../context/CalificacionesSecundarioContext';

export const useGetRows = (fetch: LibbyFetchReturn<RecuperacionResponse>) => {
  const [rows, setRows] = useState<RecuperacionResponse[]>([]);
  const { data = [], working: fetchWorking, fetchMore, reFetch } = fetch;
  const { factory } = useCalificacionesSecundarioContext<RecuperacionFactory>();
  factory.setRows(data);
  const hasCalifications = factory.hasCalifications();

  const working = useMemo(() => fetchWorking, [fetchWorking]);

  const changeRow = useCallback(
    (event: React.ChangeEvent, index: number) => {
      const newRows = factory.changeRow(event, rows, index);
      setRows(newRows);
    },
    [factory, rows],
  );

  factory.setConfig({ changeHandler: changeRow });

  useEffect(() => {
    if (!fetchWorking) {
      const newRows: Array<RecuperacionResponse> = [];
      const dataCopy = JSON.parse(JSON.stringify(data));
      dataCopy.forEach((calificacion: RecuperacionResponse) =>
        newRows.push(calificacion),
      );
      setRows(newRows);
    }
  }, [fetchWorking, data]);

  return {
    rows,
    working,
    changeRow,
    fetchMore,
    reFetch,
    data,
    hasCalifications,
  };
};
