import React from 'react';
import { Grid } from '@material-ui/core';
import { grey } from 'src/theme/colors';
import { SimpleSelect, MainInput } from 'src/commons';
import { StateActividadesType, StateActividades } from 'src/commons/types';

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  searchGrid: { marginTop: 10 },
  containerStyles: { marginTop: 23 },
  inputSearch: { padding: 10 },
};

export const FilterTableActividades = ({ content }: StateActividadesType) => (
  <>
    <Grid
      container
      justify="flex-start"
      alignItems="center"
      spacing={3}
      style={{ marginTop: 15, marginBottom: 15 }}
    >
      {content.map((e: any) =>
        e.map((t: StateActividades) =>
          !t.input ? (
            <Grid item xs={2}>
              <SimpleSelect
                {...t}
                handleChange={t.handleChange}
                content={t.content}
                value={t.value}
                customStyleTitle={customStyles.customStyleTitleSelect}
              />
            </Grid>
          ) : (
            <Grid
              container
              xs={6}
              justify="flex-end"
              spacing={2}
              alignItems="flex-end"
              style={customStyles.searchGrid}
            >
              <Grid
                container
                xs={12}
                justify="flex-end"
                spacing={3}
                alignItems="flex-end"
              >
                <Grid item xs={6}>
                  <MainInput
                    type="search"
                    handleChange={t.handleChange}
                    placeholder="Buscar"
                    name="search"
                    value={t.value}
                    fullWidth
                    customStyle={customStyles.inputSearch}
                  />
                </Grid>
              </Grid>
            </Grid>
          ),
        ),
      )}
    </Grid>
  </>
);
