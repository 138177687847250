import React from 'react';
import { primary } from 'src/theme/colors';
import { IconCircleTablesTypes } from 'src/commons';

const grandientResult = {
  empty: 'none',
  middle:
    'rgba(0, 0, 0, 0) linear-gradient(0deg, rgba(9, 9, 121, 0) 50%, rgb(250, 173, 20) 50%) repeat scroll 0% 0%',
  full: 'rgba(0, 0, 0, 0) linear-gradient(0deg, rgba(9, 9, 121, 0) 0%, rgb(250, 173, 20) 0%) repeat scroll 0% 0%',
};

export const IconCircleTable = ({
  capacidadRecomendada,
  capacidadMaxima,
}: IconCircleTablesTypes) => {
  const porcentaje = capacidadMaxima / 2;
  const filling = capacidadRecomendada < porcentaje;
  const fillingCircle = filling ? 'empty' : 'middle';
  const fillingFull =
    (capacidadRecomendada >= capacidadMaxima && 'full') || fillingCircle;
  const gradient = grandientResult[fillingFull];
  return (
    <div
      style={{
        borderRadius: 100,
        width: 8,
        height: 8,
        background: `${gradient}`,
        border: `1px solid ${primary.warning}`,
        display: 'inline-block',
        marginRight: 10,
      }}
    />
  );
};
