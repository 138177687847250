import React, { useReducer } from 'react';
import { ConfigurationContext } from '../../context/configuration/configuration';
import {
  configurationInitialReducer,
  configurationReducer,
} from '../../context/configuration/configurationReducer';

export const ConfigurationProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(
    configurationReducer,
    configurationInitialReducer,
  );

  return (
    <ConfigurationContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </ConfigurationContext.Provider>
  );
};
