import React from 'react';
import { useRolesContext } from 'src/context/RolesContext';
import { InitialExternal } from './Initial';

export const External = () => {
  const {
    selectedRole: { nivel },
  } = useRolesContext();
  const paramsNivel = [nivel || 1];

  return <InitialExternal paramsNivel={paramsNivel} />;
};
