import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Municipio } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'municipio';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idMunicipio',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idMunicipio',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Municipio
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Municipio,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Municipio.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Municipio>(DAO_NAME, optionsToUse);

// hooks
export const useMunicipioFetchById = fetchById;
export const useMunicipioLibbyCall = libbyCall;
export const useMunicipioDAO = libbyDAO;
export const useMunicipioLibbyFetch = libbyFetch;

// components/hoc
export const MunicipioAutocomplete = entityAutocomplete;
export const MunicipioCall = renderPropCall;
export const MunicipioById = renderPropFetchById;
export const MunicipioSelect = simpleSelect;
export const MunicipioAccordionSelector = accordionSelector;
export const MunicipioSelectedChips = selectedChips;

// context
// ByIdContext
export const useMunicipioByIdContext = useByIdContext;
export const MunicipioByIdProvider = ByIdProvider;
export const MunicipioByIdContext = ByIdContext;
// GeneralContext
export const useMunicipioContext = useGeneralContext;
export const MunicipioProvider = GeneralProvider;
export const MunicipioContext = GeneralContext;
