import { LibbyFetchDAO } from './LibbyFetchDAO';

export class PeriodoDAO extends LibbyFetchDAO {
  constructor() {
    super('periodo', 'idPeriodo');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
