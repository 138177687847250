import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';
import { SnackbarProvider } from 'notistack';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { BrowserRouter as Router } from 'react-router-dom';
import { App } from './App';
import TranslationService from './commons/services/translation/service';
import { PhinxAppProvider } from './lib/profiles/PhinxApp';
import { profiles } from './platform/profiles';
import { ToastProvider } from './commons/components/ToastMessage';
import { RolesContextProvider } from './context/RolesContextProvider';
import { GeneralContextProvider } from './context/GeneralContext/GeneralContext';
import theme from './theme';
import { allPermission } from './platform/permission/allPermission';

TranslationService.initialize();
ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <ToastProvider>
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <PhinxAppProvider
              profiles={profiles}
              rules={allPermission}
              profile="public"
            >
              <RolesContextProvider>
                <GeneralContextProvider>
                  <App />
                </GeneralContextProvider>
              </RolesContextProvider>
            </PhinxAppProvider>
          </MuiPickersUtilsProvider>
        </ToastProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
);
