import { LibbyFetchDAO } from './LibbyFetchDAO';

export class VinculoDAO extends LibbyFetchDAO {
  constructor() {
    super('vinculo', 'idVinculo');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByAlumnoId(alumnoId: string) {
    return this.query().equals('alumno.idAlumno', alumnoId).run();
  }
}
