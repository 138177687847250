import { makeBusinessEntity } from 'src/lib/libby';
import { Regularidad } from '../models';

const DAO_NAME = 'regularidad';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
} = makeBusinessEntity<Regularidad>(DAO_NAME);

// hooks
export const useRegularidadFetchById = fetchById;
export const useRegularidadLibbyCall = libbyCall;
export const useRegularidadDAO = libbyDAO;
export const useRegularidadLibbyFetch = libbyFetch;
