import confirmDialog from 'src/commons/services/confirmDialog';

export const validateLeave = async (
  incompletedRows: any,
  readyToClose: any,
  showView: any,
) => {
  let leave = true;
  if (!incompletedRows.length && !readyToClose && showView) {
    const confirm = await confirmDialog.show({
      title: '¿Desea continuar?',
      content:
        'La información precargada se perderá en caso que no guarde, por favor seleccione una opción.',
      confirmText: 'Seguir en esta pantalla',
      cancelText: 'Salir sin guardar',
    });
    if (confirm) {
      leave = false;
    }
  }

  return leave;
};
