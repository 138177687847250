import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Footer, InfoTable, ROL, useBackButton } from 'src/commons';
import {
  useJornadaExtendidaRows,
  useJornadaExtendidaTableColumns,
  useJornadaExtendidaValidator,
} from './hooks';
import {
  useCalificacionesSwitch,
  useCalificacionesSubmit,
  useExcelData,
} from '../../../hooks';
import { useCalificacionesContext } from '../../../context';
import { CalificacionesFiltersHeader } from '../../../components/CalificacionesFiltersHeader';
import { AlertMessage } from '../../../components';
import { useJornadaExtendidaDAO } from 'src/app/business/Calificaciones/JornadaExtendida';
import { useHistory } from 'react-router';
import { useRolesContext } from 'src/context/RolesContext';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useJeMateriasDAO } from 'src/app/business';
import { useRoleCanOpenGrade } from '../../../hooks/useRoleCanOpenGrade';
import { useColegioEfectivizadoContext } from '../../../context/EfectivizacionProvider';
import { useSeccionesByLocation } from 'src/app/business/Seccion';
import { PeriodosCalificacionesParam } from '../../../components';
import { useParams } from 'react-router';

const useStyles = makeStyles({
  table: {
    maxHeight: '600px',
    marginBottom: '80px',
  },
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

// TODO: tipificar correctamente
export const JornadaExtendida = ({
  jeAnioFilter,
  isJe,
}: {
  jeAnioFilter: {
    anio: {
      path: string;
      value: unknown;
    }[];
  };
  isJe: boolean;
}) => {
  const { periodo } = useParams<PeriodosCalificacionesParam>();
  const periodoMapping: any = {
    1: 1, // Primer Bimestre
    2: 3, // Segundo Bimestre
    3: 7, // Tercer Bimestre
    4: 8, // Cuarto Bimestre
  };
  const idPeriodo = periodoMapping[periodo];
  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();
  const classes = useStyles();
  const history = useHistory();
  const {
    selectedRole: { localizacionId, rol },
  } = useRolesContext();
  const jornadaExtendidaDAO = useJornadaExtendidaDAO();
  const { filter, setFilter } = useCalificacionesContext();

  const seccionFilter = { seccion: [{ path: 'jornada', value: 'Extendida' }] };
  const jeMateriasDAO = useJeMateriasDAO();

  const [jornadaExtendidaMateriaDescripcion, setJeDescripcion] = useState('');

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      const { descripcion } = await jeMateriasDAO.getMaterias(
        filter?.jemateria,
      );

      if (mounted) setJeDescripcion(descripcion!);
    };

    fetch();

    return () => (mounted = false);
  }, [jeMateriasDAO, filter.jemateria]);

  // Jornada Extendida sólo acepta ciertos años, en caso que el año seleccionado previamente
  // no se corresponda se setea año, sección y materia
  useEffect(() => {
    const validYearsToJe = jeAnioFilter.anio?.map(({ value }) => value);
    if (!validYearsToJe.includes(filter.anio)) {
      setFilter({
        ...filter,
        anio: '',
        seccion: '',
        jemateria: null,
      });
    }
    setFilter({
      ...filter,
      periodo: idPeriodo,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { columns, working: columnsWorking } = useJornadaExtendidaTableColumns(
    filter.jemateria,
  );

  const { data: secciones } = useSeccionesByLocation(localizacionId);

  const {
    rows,
    working: dataWorking,
    _rows,
    data,
    reFetch,
    readyToClose,
    isClosed,
    hasCalifications,
  } = useJornadaExtendidaRows(filter, columns);

  const working = useMemo(
    () => dataWorking || columnsWorking,
    [columnsWorking, dataWorking],
  );

  const showView = useMemo(
    () => filter.anio && filter.seccion && filter.periodo && filter.jemateria,
    [filter],
  );

  const fieldsToValidate = useMemo(
    () =>
      columns
        .map((column) => column.id)
        .filter((column) => column !== 'alumno' && column !== 'observaciones'),
    [columns],
  );

  const { incompletedRows, isDisabled, submitRows } =
    useJornadaExtendidaValidator({
      fieldsToValidate,
      _rows,
      filter,
      working,
      data,
    });

  const [anioSelect, setAnioSelect] = useState('');
  const [seccionSelect, setSeccionSelect] = useState('');
  const [periodoSelect, setPeriodoSelect] = useState('');
  const [jornadaExtendidaSelect, setJornadaExtendidaSelect] = useState('');

  const docenteJe = isJe;
  const coordinadorJe = Number(rol) === ROL.COORDINADOR_JE;

  const seccionNameContext = useMemo(() => {
    const seccionName: any = secciones?.find(
      (seccion: any) => seccion.idSeccion === filter.seccion,
    );
    return seccionName?.nombreSeccion;
  }, [filter.seccion, secciones]);

  const { excelData } = useExcelData(
    columns,
    _rows,
    `Calificaciones - Jornada Extendida - ${periodoSelect} - ${
      seccionSelect ?? seccionNameContext
    } - ${jornadaExtendidaMateriaDescripcion} - ${localizacionId}`,
    periodoSelect,
    anioSelect,
    seccionSelect,
    jornadaExtendidaSelect,
  );

  const { onSubmit, submitting } = useCalificacionesSubmit({
    columns,
    _rows,
    data,
    filter,
    dao: jornadaExtendidaDAO.aspect('default'),
    reFetch,
    isSecundario: true,
  });

  const { onSwitch, switchSubmitting } = useCalificacionesSwitch({
    filter,
    reFetch,
    asunto: 'Jornada Extendida',
    isJE: true,
    jemateria: filter.jemateria,
  });

  const handleLeave = useCallback(
    async (route?: string) => {
      if (isDisabled || isClosed || submitRows) {
        history.push('/');
        return;
      } else {
        const confirm = await confirmDialog.show({
          title: '¿Desea continuar?',
          content:
            'La información precargada se perderá en caso que no guarde, por favor seleccione una opción.',
          confirmText: 'Salir sin guardar',
          cancelText: 'Seguir en esta pantalla',
        });
        if (confirm) {
          try {
            history.push('/');
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    [history, isClosed, isDisabled, submitRows],
  );

  const confirmarSalirConBackButton = useCallback(() => {
    if (!(isDisabled || isClosed || submitRows)) {
      handleLeave('/private/calificaciones');
    } else {
      history.push('/private/calificaciones');
    }
  }, [handleLeave, history, isClosed, isDisabled, submitRows]);

  useBackButton('/calificaciones', 0, confirmarSalirConBackButton);

  const canOpenGrade = useRoleCanOpenGrade(['1', '5', '16']);

  const buttonConfig: any = useMemo(() => {
    const defaultButtons: any[] = [
      {
        title: 'Salir',
        size: 'medium',
        type: 'secondary',
        handleOnClick: handleLeave,
      },
      {
        title: 'Guardar',
        size: 'medium',
        handleOnClick: onSubmit,
        disabled:
          isDisabled ||
          isClosed ||
          submitRows /* || !establecimientoEfectivizado */,
      },
    ];

    if (!isClosed) {
      defaultButtons.push({
        title: 'Cerrar Calificaciones',
        size: 'medium',
        handleOnClick: () => onSwitch('close'),
        disabled:
          isDisabled ||
          !readyToClose ||
          isClosed ||
          !hasCalifications ||
          !(docenteJe || coordinadorJe),
      });
    } else if (canOpenGrade) {
      defaultButtons.push({
        title: 'Abrir Calificaciones',
        size: 'medium',
        handleOnClick: () => onSwitch('open'),
        disabled: !coordinadorJe,
      });
    }
    return defaultButtons;
  }, [
    handleLeave,
    onSubmit,
    isDisabled,
    isClosed,
    submitRows,
    canOpenGrade,
    readyToClose,
    hasCalifications,
    docenteJe,
    coordinadorJe,
    onSwitch,
  ]);

  const handleAnioChange = (options: any) => {
    const anio = options?.find((s: any) => s.idAnio === filter.anio);
    setAnioSelect(anio?.descripcionAnio);
  };

  const handleSeccionesChange = (options: any) => {
    const seccion = options?.find((s: any) => s.idSeccion === filter.seccion);
    setSeccionSelect(seccion?.nombreSeccion);
  };

  const handlePeriodosChange = (options: any) => {
    const periodo = options?.find((p: any) => p.idPeriodo === filter.periodo);
    setPeriodoSelect(periodo?.tipoPeriodo.descripcionTipoPeriodo);
  };

  const handleJornadaExtendidaChange = (options: any) => {
    const materiaJornadaExtendida = options?.find(
      (p: any) => p.idJEMateria === filter.jemateria,
    );
    setJornadaExtendidaSelect(materiaJornadaExtendida?.descripcion);
  };

  return (
    <>
      <AlertMessage
        incompletedRows={incompletedRows}
        isDisabled={isDisabled}
        isClosed={isClosed}
        working={working}
        showView={showView}
        establecimientoEfectivizado={establecimientoEfectivizado}
      />
      <CalificacionesFiltersHeader
        data={data}
        rows={_rows}
        filter={filter}
        setFilter={setFilter}
        showEspacioCurricular={false}
        showJeMaterias
        excelData={excelData}
        handleSeccionesChange={handleSeccionesChange}
        handlePeriodosChange={handlePeriodosChange}
        handleAnioChange={handleAnioChange}
        handleJornadaExtendidaChange={handleJornadaExtendidaChange}
        showView={showView}
        incompletedRows={incompletedRows}
        readyToClose={readyToClose}
        anioCustomFilter={jeAnioFilter}
        seccionCustomFilter={seccionFilter}
      />
      {showView ? (
        <>
          <InfoTable
            rows={rows}
            columns={columns}
            customStyle={classes.table}
            working={working}
          />
          <Grid className={classes.footer}>
            <Footer
              buttonConfig={buttonConfig}
              loading={submitting || switchSubmitting}
            />
          </Grid>
        </>
      ) : (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: '400px' }}
        >
          <Typography
            style={{
              fontSize: '40px',
              marginTop: '50px',
              color: 'gray',
              fontWeight: 'bold',
              lineHeight: '45px',
            }}
          >
            Seleccione año, sección, período y espacio educativo.
          </Typography>
        </Grid>
      )}
    </>
  );
};
