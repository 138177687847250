import React, { useMemo, useState, useCallback } from 'react';
import { Stepper, Step, StepLabel, Grid } from '@material-ui/core';
import { AnyObject } from 'src/commons';
import { useAutoRegistracionDAO } from 'src/app/business/AutoRegistracion';
import { usePersonaDAO, useCuentasDAO } from 'src/app/business';
import confirmDialog from 'src/commons/services/confirmDialog';
import { FormContextProvider } from 'src/lib/templates';
import { useProfileContext } from 'src/lib/profiles/ProfileContext';
import { useRolesContext } from 'src/context/RolesContext';
import {
  PersonalStep,
  EstablecimientoStep,
  RolesStep,
  RolDetailsStep,
  NivelStep,
} from './components/Steps';
import { FormFooter } from './components';
import {
  autoregistracionTemplate,
  autoregistracionTemplateSinPersona,
} from './template';
import { usePersona } from './hooks/usePersona';
import { useSnackbar } from 'notistack';

type StepRecord = {
  label: string;
  render: JSX.Element;
};

const allSteps: { [k: string]: StepRecord } = {
  datosPersonales: {
    label: 'Datos Personales',
    render: <PersonalStep />,
  },
  establecimiento: {
    label: 'Establecimiento',
    render: <EstablecimientoStep />,
  },
  nivel: {
    label: 'Nivel',
    render: <NivelStep />,
  },
  roles: {
    label: 'Roles',
    render: <RolesStep />,
  },
  detallesRol: {
    label: 'Detalles de Rol',
    render: <RolDetailsStep />,
  },
};

export const SignUp = () => {
  const autoregistracionDAO = useAutoRegistracionDAO();
  const personaDAO = usePersonaDAO();
  const cuentasDAO = useCuentasDAO();
  const { deselectRole } = useRolesContext();
  const [activeStep, setActiveStep] = useState(0);
  const { setProfile } = useProfileContext();
  const { userInfo } = useRolesContext();
  const { working, persona } = usePersona();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const initialValues = useMemo(
    () => ({
      idLocalizacion: null,
      nombre: userInfo.name,
      apellido: userInfo.apellido,
      cuentaBue: userInfo.email,
      idRolUsuario: null,
    }),
    [userInfo],
  );

  const steps = useMemo<StepRecord[]>(() => {
    return persona
      ? [
          allSteps.establecimiento,
          allSteps.nivel,
          allSteps.roles,
          allSteps.detallesRol,
        ]
      : [
          allSteps.datosPersonales,
          allSteps.establecimiento,
          allSteps.nivel,
          allSteps.roles,
          allSteps.detallesRol,
        ];
  }, [persona]);

  const onSubmit = useCallback(
    async (values, form) => {
      setLoading(true);
      let rejected = false;

      if (!values?.roles) {
        await autoregistracionDAO.aspect('limit_localization').save({
          nivel: +values.idNivel,
          rolStatus: 1,
          rolUsuario: +values.idRolUsuario,
          usuario: +values.idUsuario,
          localizacion: +values.idLocalizacion,
        });
      } else {
        await Promise.all(
          values?.roles.map(async (rol: AnyObject) => {
            await autoregistracionDAO.aspect('limit_localization').save({
              anio: +rol?.idAnio,
              espacioCurricular: +rol?.idEspacioCurricular,
              nivel: +values.idNivel,
              rolStatus: 1,
              area: +rol?.idArea,
              rolUsuario: +values.idRolUsuario,
              seccion: +rol?.idSeccion,
              usuario: +values.idUsuario,
              localizacion: +values.idLocalizacion,
              orientacion: rol.idOrientacion,
            });
          }),
        ).catch(() => {
          rejected = true;
          enqueueSnackbar(
            'Ha ocurrido un error con alguna de las solicitudes, por favor verificar el estado de las mismas.',
            {
              variant: 'error',
            },
          );
        });
      }
      if (!persona) {
        await personaDAO.save({
          nombre: values.nombre,
          apellido: values.apellido,
          tipoDocumento: values.idTipoDocumento,
          documento: values.documento,
          cuentas: { idUsuario: userInfo.id },
          email: values.cuentaBue,
          cuil: values.cuil,
          genero: { idGenero: -1 },
          paisNacimiento: { idPais: 64 },
          nacionalidad: { idPais: 64 },
        });
      }
      await cuentasDAO.save({
        idUsuario: userInfo.id,
        nombreUsuario: values.nombre,
        apellidoUsuario: values.apellido,
      });
      if (!rejected) {
        const confirm = await confirmDialog.show({
          title: 'Solicitud en proceso',
          content: `Ahora solo falta que la apruebe el ${
            +values.idRolUsuario === 1 ? 'supervisor' : 'directivo'
          } del establecimiento. Mientras tanto, tu solicitud quedará en estado pendiente.`,
          confirmText: 'Finalizar',
          sizeWidth: 'md',
        });
        if (confirm) {
          setProfile('sinEstablecimiento');
          deselectRole();
        } else if (+values.idRolUsuario === 2) {
          form.change('roles', null);
          setActiveStep(2);
        } else {
          setProfile('sinEstablecimiento');
          deselectRole();
        }
      } else {
        setActiveStep(2);
      }

      setLoading(false);
    },
    [
      persona,
      cuentasDAO,
      userInfo.id,
      autoregistracionDAO,
      enqueueSnackbar,
      personaDAO,
      setProfile,
      deselectRole,
    ],
  );

  return (
    <>
      {!working && (
        <Grid
          container
          direction="column"
          justify="space-between"
          alignItems="stretch"
          style={{ position: 'relative', height: '100%', paddingBottom: 100 }}
        >
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(({ label }) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <FormContextProvider
            template={
              persona
                ? autoregistracionTemplateSinPersona
                : autoregistracionTemplate
            }
            initialValues={initialValues}
            onSubmit={onSubmit}
          >
            {steps[activeStep].render}
            {!Boolean(loading) ? (
              <FormFooter
                activeStep={activeStep}
                setActiveStep={(next: number) => setActiveStep(next)}
                persona={!!persona}
                maxSteps={steps.length - 1}
              />
            ) : (
              <></>
            )}
          </FormContextProvider>
        </Grid>
      )}
    </>
  );
};
