import { Box, Button, Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';
import { StudentDetail } from '../types';
import { ArrowForward } from '@material-ui/icons';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import { Loading } from 'src/commons';

interface StudentReportTitleProps {
  studenDetail: StudentDetail[];
  handleBack: () => void;
  onNextAlu: () => void;
  workingPresentismos: boolean;
  onClickExcel: () => void;
}

const useStyles = makeStyles({
  title: {
    color: '#38485C',
    fontFamily: 'Nunito',
    fontSize: 30,
    fontWeight: 'bold',
  },
  description: {
    color: '#b5b5b7',
    fontFamily: 'Nunito',
    fontSize: 14,
    fontWeight: 'bold',
    marginTop: '10px',
  },
  backButton: {
    color: '#3786EE',
    textTransform: 'none',
    backgroundColor: '#FFFF',
    borderColor: '#3786EE',
    fontWeight: 'bold',
  },
  nextStudent: {
    color: '#3786EE',
    textTransform: 'none',
    backgroundColor: '#FFFF',
    borderColor: '#3786EE',
    fontWeight: 'bold',
  },
  downloadButton: {
    backgroundColor: '#3786EE',
    textTransform: 'none',
    color: 'white',
    '&:hover': {
      backgroundColor: '#3786EE',
      color: 'white',
      textTransform: 'none',
    },
  },
});

export const StudentReportTitle = ({
  studenDetail,
  handleBack,
  onNextAlu,
  workingPresentismos,
  onClickExcel,
}: StudentReportTitleProps) => {
  const classes = useStyles();

  return (
    <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
      <Grid item xs={12} md={10} container spacing={2}>
        <Grid item xs={12}>
          <Box component="h2">
            <Typography className={classes.title}>Reporte</Typography>
            <Typography className={classes.description}>
              Visualizá el resumen de asistencia del estudiante
            </Typography>
          </Box>
        </Grid>
        <Grid
          xs={6}
          style={{ display: 'flex', alignItems: 'normal' }}
          justifyContent={'flex-start'}
        >
          {workingPresentismos ? (
            <Loading />
          ) : (
            studenDetail.map((studen, index, all) => (
              <Box
                key={`studentreporttable-${studen.title}-${index}`}
                display="flex"
                flexDirection="column"
                pr={2}
                pl={2}
                minWidth={index === all.length - 1 ? '220px' : undefined}
                borderRight={
                  index < all.length - 1 ? '1px solid #B3C1CF' : undefined
                }
              >
                <Box color="#B3C1CF" fontFamily="Nunito" fontSize={16}>
                  {studen.title}
                </Box>
                <Box
                  color="#3786EE"
                  fontFamily="Nunito"
                  fontSize={16}
                  fontWeight="bold"
                >
                  {studen.description}
                  <br />
                  {studen.descriptionSecondLine
                    ? studen.descriptionSecondLine
                    : ''}
                </Box>
              </Box>
            ))
          )}
        </Grid>

        <Grid
          xs={6}
          style={{ display: 'flex' }}
          alignItems={'flex-end'}
          justifyContent={'flex-end'}
        >
          <Grid item>
            <Button
              className={classes.backButton}
              variant="outlined"
              color="primary"
              onClick={handleBack}
            >
              Salir
            </Button>
          </Grid>
          <Grid item style={{ marginLeft: '15px', marginRight: '15px' }}>
            <Button
              className={classes.downloadButton}
              type="submit"
              endIcon={<SaveAltIcon />}
              onClick={onClickExcel}
            >
              Descargar
            </Button>
          </Grid>
          <Grid item>
            <Button
              className={classes.nextStudent}
              variant="outlined"
              onClick={onNextAlu}
              endIcon={<ArrowForward />}
            >
              Siguiente Estudiante
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
