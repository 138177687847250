import {
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { useCallback, useEffect, useState } from 'react';
import { useOfertasGruposLibbyCall } from 'src/app/business';
import { useSedesDAO } from 'src/app/business/acap';
import { grey, primary } from 'src/theme/colors';
import {
  HandleDeleteSede,
  OrganizacionFormInitialValue,
  SedeFormInitialValue,
  SedeFormProps,
  SedesFormComponentProps,
} from '../../types';
import { InputComponent } from './InputComponent';

const useStyles = makeStyles({
  labelAsterisk: {
    color: 'red',
  },
  boxHeight: {
    minHeight: '3.5em',
    maxHeight: '6.5em',
    width: '24.5rem',
  },
  iconColor: { color: '#207dd9' },
  iconDisabled: { color: primary.disabled },
});

const SedeForm = ({
  index,
  formInfo,
  sede,
  disabled,
  handleDelete,
  handleFormChange,
}: SedeFormProps<SedeFormInitialValue>) => {
  const classes = useStyles();
  return (
    <Grid
      container
      style={{ border: '1px solid black', marginTop: '15px', padding: 20 }}
    >
      <Grid container alignItems="center" spacing={1}>
        <Grid item>
          <Typography
            style={{
              color: grey.heading,
              fontSize: 22,
              fontFamily: 'Open Sans',
              marginLeft: '10px',
            }}
          >
            Sede
          </Typography>
        </Grid>
        <Grid item>
          <Tooltip
            title="La sede tiene un grupo asignado."
            arrow
            disableHoverListener={!disabled}
            placement="right"
          >
            <div>
              <IconButton
                onClick={handleDelete}
                disabled={disabled}
                className={disabled ? classes.iconDisabled : classes.iconColor}
              >
                <DeleteIcon />
              </IconButton>
            </div>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container spacing={2} style={{ padding: 20 }}>
        <InputComponent>
          <Typography variant="h5" style={{ marginBottom: 10 }}>
            Nombre:
          </Typography>
          <TextField
            name={`sedes[${index}].nombre`}
            variant="outlined"
            value={sede.nombre}
            onChange={handleFormChange}
            placeholder=""
            fullWidth
            margin="dense"
            size="small"
            style={{ width: '25em' }}
            onFocus={formInfo.handleTouche}
            onBlur={formInfo.handleTouche}
            error={formInfo.touched?.nombre && formInfo.errors?.nombre}
            helperText={
              formInfo.touched?.nombre && formInfo.errors?.nombre
                ? 'No ingrese caracteres especiales'
                : ' '
            }
          />
        </InputComponent>
        <InputComponent>
          <Typography variant="h5" style={{ marginBottom: 10 }}>
            Dirección:
            <span className={classes.labelAsterisk}>
              {!formInfo.errors?.direccion ? '' : '*'}
            </span>
          </Typography>
          <TextField
            name={`sedes[${index}].direccion`}
            variant="outlined"
            value={sede.direccion}
            onChange={handleFormChange}
            placeholder=""
            margin="dense"
            size="small"
            fullWidth
            style={{ width: '25em' }}
            onFocus={formInfo.handleTouche}
            onBlur={formInfo.handleTouche}
            error={formInfo.touched?.direccion && formInfo.errors?.direccion}
            helperText={
              formInfo.touched?.direccion && formInfo.errors?.direccion
                ? 'No ingrese caracteres especiales'
                : ' '
            }
          />
        </InputComponent>
      </Grid>
    </Grid>
  );
};

export const SedesForm = ({
  formValues,
  formInfo,
  handleFormChange,
  setFormValues,
}: SedesFormComponentProps<OrganizacionFormInitialValue>) => {
  const [loading, setLoading] = useState(false);
  const sedesDAO = useSedesDAO();

  const {
    data: ofertaGrupos = [],
    working,
    recall,
  } = useOfertasGruposLibbyCall({
    methodName: 'fetch',
    noAutoCall: true,
    aspect: 'sedeDefault',
  });

  useEffect(() => {
    const validSedes = formValues.sedes.filter((sede) => sede.idSede);
    if (validSedes.length === 0) return;
    // if (!formValues.sedes.some((sede) => sede.idSede)) return;
    const filterGrupoSedes = {
      filter: {
        sede: [
          {
            path: 'jornadas.sede.idSede',
            value: validSedes.map((sede) => sede.idSede),
            method: 'in',
          },
        ],
      },
      limit: 100,
      aspect: 'sedeDefault',
    };
    try {
      recall(filterGrupoSedes);
    } catch (error) {
      console.log(error);
    }
  }, [formValues.sedes, recall]);

  const handleDelete = useCallback<HandleDeleteSede>(
    (item, index) => async () => {
      if (item.idSede) {
        try {
          setLoading(true);
          await sedesDAO.remove(item);
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      }
      // ELIMINAMOS DEL ARRAY
      setFormValues((prev) => {
        const copySedes = [...prev.sedes];
        copySedes.splice(index, 1);
        return { ...prev, sedes: [...copySedes] };
      });
      formInfo.setTouched((prev) => {
        if (prev.sedes) {
          const copySedes = [...prev.sedes];
          copySedes.splice(index, 1);
          return { ...prev, sedes: [...copySedes] };
        } else {
          return { ...prev };
        }
      });
    },
    [formInfo, sedesDAO, setFormValues],
  );

  return (
    <Grid container>
      {formValues.sedes.map((sede, index) => {
        const isDisabled = !!ofertaGrupos.find(
          (og) => !!og.jornadas.find((j) => j.sede.idSede === sede.idSede),
        );
        const touched = formInfo.touched.sedes?.[index];
        const errors = formInfo.errors.sedes?.[index];
        return (
          <SedeForm
            key={index}
            index={index}
            formInfo={{ touched, errors, handleTouche: formInfo.handleTouche }}
            sede={sede}
            disabled={isDisabled || working || loading}
            handleFormChange={handleFormChange}
            handleDelete={handleDelete(sede, index)}
          />
        );
      })}
    </Grid>
  );
};
