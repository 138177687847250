import { useState, useMemo, useEffect } from 'react';
import { Seccion } from 'src/app/models';
import { useSeccionDAO } from '../Seccion';

type SeccionMeta = {
  loading: boolean;
  data: Seccion[];
};

export const useSeccionesByLocation = (idLocalizacion: string) => {
  const [meta, setMeta] = useState<SeccionMeta>({
    data: [],
    loading: false,
  });
  const dao = useSeccionDAO();

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      setMeta((prev: SeccionMeta) => ({ ...prev, loading: true }));
      const data = await dao.getByLocationId(idLocalizacion);
      if (mounted) setMeta((prev: SeccionMeta) => ({ ...prev, data }));
    };
    fetch();
    return () => (mounted = false);
  }, [dao, idLocalizacion]);

  return {
    data: meta.data,
    loading: meta.loading,
  };
};
