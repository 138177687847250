import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { TipoDireccion } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'tipo_direccion';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idTipoDireccion',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idTipoDireccion',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.TipoDireccion
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.TipoDireccion,
      hoc: { ...defaultOptions.hoc, ...businessOptions.TipoDireccion.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<TipoDireccion>(DAO_NAME, optionsToUse);

// hooks
export const useTipoDireccionFetchById = fetchById;
export const useTipoDireccionLibbyCall = libbyCall;
export const useTipoDireccionDAO = libbyDAO;
export const useTipoDireccionLibbyFetch = libbyFetch;

// components/hoc
export const TipoDireccionAutocomplete = entityAutocomplete;
export const TipoDireccionCall = renderPropCall;
export const TipoDireccionById = renderPropFetchById;
export const TipoDireccionSelect = simpleSelect;
export const TipoDireccionAccordionSelector = accordionSelector;
export const TipoDireccionSelectedChips = selectedChips;

// context
// ByIdContext
export const useTipoDireccionByIdContext = useByIdContext;
export const TipoDireccionByIdProvider = ByIdProvider;
export const TipoDireccionByIdContext = ByIdContext;
// GeneralContext
export const useTipoDireccionContext = useGeneralContext;
export const TipoDireccionProvider = GeneralProvider;
export const TipoDireccionContext = GeneralContext;
