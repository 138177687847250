import { Rules } from 'src/lib/permission/types';
import { TABBAR_BLOCKER_ID } from '../const';

// route docente
export const futureRuleSet: Rules = {
  idRule: 'no-future',
  routes: [],
  blockers: [],
  sideMenu: [],
  tabBars: [
    { id: TABBAR_BLOCKER_ID.ESPACIO_CURRICULAR_PLANIFICACION_TAB },
    { id: TABBAR_BLOCKER_ID.ESPACIO_CURRICULAR_ACTIVIDADES_TAB },
    // { id: TABBAR_BLOCKER_ID.ESPACIO_CURRICULAR_CALIFICACIONES_TAB },
  ],
};
