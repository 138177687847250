import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { JeIndicadores } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'je_indicadores';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idJeIndicadores',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idJeIndicadores',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.JeIndicadores
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.JeIndicadores,
      hoc: { ...defaultOptions.hoc, ...businessOptions.JeIndicadores.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<JeIndicadores>(DAO_NAME, optionsToUse);

// hooks
export const useJeIndicadoresFetchById = fetchById;
export const useJeIndicadoresLibbyCall = libbyCall;
export const useJeIndicadoresDAO = libbyDAO;
export const useJeIndicadoresLibbyFetch = libbyFetch;

// components/hoc
export const JeIndicadoresAutocomplete = entityAutocomplete;
export const JeIndicadoresCall = renderPropCall;
export const JeIndicadoresById = renderPropFetchById;
export const JeIndicadoresSelect = simpleSelect;
export const JeIndicadoresAccordionSelector = accordionSelector;
export const JeIndicadoresSelectedChips = selectedChips;

// context
// ByIdContext
export const useJeIndicadoresByIdContext = useByIdContext;
export const JeIndicadoresByIdProvider = ByIdProvider;
export const JeIndicadoresByIdContext = ByIdContext;
// GeneralContext
export const useJeIndicadoresContext = useGeneralContext;
export const JeIndicadoresProvider = GeneralProvider;
export const JeIndicadoresContext = GeneralContext;
