import { LibbyFetchDAO } from './LibbyFetchDAO';

export class TipoMedioContactoDAO extends LibbyFetchDAO {
  constructor() {
    super('tipo_medio_contacto', 'idTipoMedioContacto');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
