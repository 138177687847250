import { useEffect, useMemo, useState } from 'react';
import { useEspacioCurricularLibbyFetch } from 'src/app/business';
import { ROL } from 'src/commons';
import { useRolesContext } from 'src/context/RolesContext';

export const usePeriodosJe = () => {
  const {
    selectedRole: { rol },
  } = useRolesContext();
  const { data: espaciosCurriculares = [] } = useEspacioCurricularLibbyFetch({
    limit: 200,
    aspect: 'sidemenu',
  });

  const docenteJE =
    espaciosCurriculares.some((ec) =>
      ec.descripcion.toLowerCase().includes('jornada extendida'),
    ) && Number(rol) === ROL.DOCENTE;

  const isJe = useMemo(
    () => Number(rol) === ROL.COORDINADOR_JE || docenteJE,
    [rol, docenteJE],
  );

  const [periodo, setPeriodo] = useState('1');

  useEffect(() => {
    setPeriodo(isJe ? '2' : '1');
  }, [isJe]);

  return {
    setPeriodo,
    periodo,
    isJe,
  };
};
