import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  rootContainer: {
    marginTop: '2em',
  },

  configurationTitle: {
    color: theme.palette.text.disabled,
  },

  headerContainer: {
    margin: '1em 0',
  },

  propuestaHeader: {
    marginTop: '2em',
  },

  situacionHeader: {
    marginTop: '2em',
  },

  tableContainer: {},

  situacionContainer: {},

  propuestaContainer: {
    marginBottom: '4em',
  },
}));
