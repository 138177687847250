import { LibbyFetchDAO } from './LibbyFetchDAO';

export class InterdiscipPlanFeedbackDAO extends LibbyFetchDAO {
  constructor() {
    super('interdiscip_plan_feedback', 'idInterdiscipPlanFeedback');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  async getLastFeedback(id: number | string) {
    // TODO: cambiar el orderBy de la pk al campo de fechaCreacion cuando este disponible
    const [feedback] = await this.query()
      .limit(0, 1)
      .orderBy('idInterdiscipPlanFeedback', 'desc')
      .equals('interdiscipPlan', id)
      .run();
    return feedback || null;
  }
}
