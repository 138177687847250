import { LibbyFetchDAO } from './LibbyFetchDAO';

export class AccionesOrientacionesCustomDAO extends LibbyFetchDAO {
  constructor() {
    super('acciones_orientaciones_custom', 'idAccionorientacion');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
