import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useInputOnChangeValue } from 'src/commons';
import { JoinedFilters } from '../types';

export interface FilterLogicOptions {
  filter: JoinedFilters;
  onChangeFilter: Dispatch<SetStateAction<JoinedFilters>>;
}

export interface FilterSearch {
  search: string;
  onSearchChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

export interface FiltersLogicReturn {
  idAnio: string;
  idPlanEstudio: string;
  onAnioChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onPlanEstudioChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

export const useFiltersLogic = ({
  filter,
  onChangeFilter,
}: FilterLogicOptions): FiltersLogicReturn & FilterSearch => {
  const [search, setSearch] = useState(filter.search);
  const [idAnio, setIdAnio] = useState(filter.idAnio);
  const [idPlanEstudio, setIdPlanEstudio] = useState(filter.idPlanEstudio);
  const onSearchChange = useInputOnChangeValue(setSearch);
  const onAnioChange = useInputOnChangeValue(setIdAnio);
  const onPlanEstudioChange = useInputOnChangeValue(setIdPlanEstudio);

  useEffect(() => {
    const debounce = setTimeout(() => {
      onChangeFilter((oldValue) => ({
        ...oldValue,
        idAnio,
        idPlanEstudio,
        search,
      }));
    }, 600);
    return () => {
      clearTimeout(debounce);
    };
  }, [search, onChangeFilter, idAnio, idPlanEstudio]);

  return {
    search,
    idAnio,
    idPlanEstudio,
    onPlanEstudioChange,
    onSearchChange,
    onAnioChange,
  };
};
