import React, { useState } from 'react';
import { useInputOnChangeValue } from './useInputOnChangeValue';

export const useInputState = <T = any>(
  initialState: T,
): [T, (event: React.ChangeEvent<HTMLInputElement>) => void] => {
  const [value, setValue] = useState<T>(initialState);
  const onValueChange = useInputOnChangeValue(setValue);
  return [value, onValueChange];
};
