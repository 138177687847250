import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Grid, makeStyles, Typography, Tooltip } from '@material-ui/core';
import { OptionsModalPresentismo } from './OptionsModalPresentismo';
import { usePresentismoContext } from '../../context/Context';
import { Metadata } from 'src/app/models/presentismo_v2';
import { AlumnoPresentismo, PresenteModified } from '../../types';
import { getXPosition, Point } from '../utils/Grid';
import { useFocusGridPosition } from '../hooks/useFocusGridPosition';
import { primary } from 'src/theme/colors';
import { TooltipDisabledPresentismo } from './TooltipDisabledPresentismo';
import { ROL } from 'src/commons';
import { ROLES_JERARQUICOS } from '../utils/rolesPresentismo';
import { useRolesContext } from 'src/context/RolesContext';

const useStyles = makeStyles({
  cell: {
    position: 'relative',
    width: '100%',
    height: '100%',
    minWidth: '60px',
    maxWidth: '75px',
    '@media (min-width:1280px)': {
      maxWidth: '100%',
    },
    outline: 'none',
  },

  presente: {
    color: primary.success,
    backgroundColor: primary.succesDark,
  },
  'ingreso-tardío': {
    backgroundColor: 'hsl(54deg 100% 60%)',
  },
  'ausente-con-presencia-en-clase': {
    backgroundColor: 'hsl(40deg 100% 37%)',
  },
  'retiro-anticipado': {
    backgroundColor: 'hsl(54deg 100% 60%)',
  },
  ausente: {
    backgroundColor: primary.error,
  },
  'no-corresponde': {
    backgroundColor: 'hsl(0deg 0% 62%)',
  },
  day: {
    '&:hover': {
      cursor: 'pointer',
      background: '#F5F5F5',
    },
  },
  selectedDayBorderInvalidCell: {
    boxShadow: '0px 0px 0px 5px #be8f91 inset',
  },
  selectedDayBorder: {
    boxShadow: '0px 0px 0px 5px #03a1d6 inset',
  },
  notCurrentDay: {
    position: 'relative',
    filter: 'brightness(1)',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '-2px',
      width: '102%',
      height: '102%',
      backgroundColor: '#3e3e3e2e',
      boxShadow: '0px 0px 0px 1px #3e3e3e26',
    },
    '&:hover': {
      '&::after': {
        content: 'none',
      },
    },
  },
  currentDay: {
    position: 'relative',
    filter: 'brightness(1)',
  },
  enabled: {
    position: 'relative',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  feriado: {
    // backgroundColor: 'red',
  },
  notFeriado: {
    // backgroundColor: 'blue',
  },
  disabled: {
    position: 'relative',
    // backgroundColor: '#FFFFFF',
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '-2px',
      width: '102%',
      height: '102%',
      backgroundColor: '#3e3e3e2e',
      boxShadow: '0px 0px 0px 1px #3e3e3e26',
    },
    '&:hover': {
      '&::after': {
        content: '""',
        cursor: 'not-allowed',
      },
    },
  },
  disabledEmergenciaSuspensionClases: {
    position: 'relative',
    '&:hover': {
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '-2px',
        width: '102%',
        height: '102%',
        cursor: 'not-allowed',
      },
    },
  },
  disabledPresentismo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    height: 'fit-content',
    width: '8rem',
    padding: '1em',
    backgroundColor: '#eee',
    color: 'black',
    border: 'solid 0.5px #333',
    fontSize: '0.8rem',
  },
  tooltipPlacement: {
    position: 'relative',
    top: '5rem',
  },
});

export type SupportOptionsType = {
  label: string;
  id: number;
  docente: boolean;
};

interface DayCellProperty {
  day: any;
  data: AlumnoPresentismo;
  onSelect: any;
  setIdxDaySelected: any;
  turno?: any;
  existTM?: boolean;
  esJornadaCompleta: boolean;
}

export const DayCell = ({
  day,
  data,
  onSelect,
  setIdxDaySelected,
  turno,
  existTM = true,
  esJornadaCompleta,
}: DayCellProperty) => {
  const {
    rolCanUpdateGrid: canEditPresentismo,
    serverDate,
    dispatch: { setGridPosition, setIsModalOpen, setEnableAutoSave },
    state: {
      gridPosition: currentGridPosition,
      currentWeek,
      rows,
      isModalOpen,
      idxDaySelected,
      tipoJornada,
      isJornadaExtendidaTab,
      lowerDeadLineForTomaPresentismo,
    },
    feriados,
    propuestasJE,
    filter,
  } = usePresentismoContext();
  const gridWidth = tipoJornada === 'Completa' ? 11 : 5;

  const {
    selectedRole: { rol },
  } = useRolesContext();

  const feriado = useMemo(
    () =>
      feriados.find((feriado) =>
        moment(feriado.date).isSame(moment(currentWeek).add(day, 'days')),
      ),
    [feriados, currentWeek, day],
  );

  const isCurrentDateSameOrAfterLowerDeadlineForTomaPresentismo = useMemo(
    () =>
      moment(currentWeek)
        .add(day, 'days')
        .isSameOrAfter(lowerDeadLineForTomaPresentismo),
    [currentWeek, day, lowerDeadLineForTomaPresentismo],
  );

  const currentSelectedDateIsSameOrBeforeServerDate = useMemo(
    () =>
      moment(currentWeek).add(day, 'days').isSameOrBefore(moment(serverDate)),
    [currentWeek, day, serverDate],
  );

  const enabledByPropuesta = useMemo(
    () =>
      propuestasJE
        .find((propuesta) => propuesta.seccion.idSeccion === filter.seccion)
        ?.dias.some((propuestaDia) => propuestaDia.dia.idDia === day + 1),
    [propuestasJE, filter.seccion, day],
  );

  const esEstadoEmergencia = useCallback(() => {
    let isEmergencia = false;
    const fecha = moment(currentWeek).add(day, 'days').format('YYYY-MM-DD');
    const metadata = data?.metadatas?.find(
      (metadata: Metadata) =>
        metadata.dates.includes(fecha) &&
        metadata.metadataType.descripcion === 'Emergencia',
    );
    isEmergencia = Boolean(metadata?.value);
    return isEmergencia;
  }, [currentWeek, data?.metadatas, day]);

  const esSuspensionClases = useCallback(() => {
    let isSuspension = false;
    const fecha = moment(currentWeek).add(day, 'days').format('YYYY-MM-DD');
    const metadata = data?.metadatas?.find(
      (metadata: Metadata) =>
        metadata.dates.includes(fecha) &&
        metadata.metadataType.descripcion === 'Suspensión de clases',
    );
    isSuspension = Boolean(metadata?.value);
    return isSuspension;
  }, [currentWeek, data?.metadatas, day]);

  const enabled = useMemo(() => {
    let validation = true;
    const currentSelectedDate = moment(currentWeek).add(day, 'days');

    if (isJornadaExtendidaTab) {
      validation = enabledByPropuesta ?? true;
    }

    return (
      currentSelectedDateIsSameOrBeforeServerDate &&
      isCurrentDateSameOrAfterLowerDeadlineForTomaPresentismo &&
      !feriado &&
      validation
    );
  }, [
    currentWeek,
    day,
    isJornadaExtendidaTab,
    currentSelectedDateIsSameOrBeforeServerDate,
    isCurrentDateSameOrAfterLowerDeadlineForTomaPresentismo,
    feriado,
    enabledByPropuesta,
  ]);

  const validCell =
    enabled && !esEstadoEmergencia() && !esSuspensionClases() && existTM;

  const myGridPosition = useMemo(
    () =>
      new Point(
        getXPosition(day, esJornadaCompleta, esJornadaCompleta ? turno : 1),
        data.positionY,
      ),
    [day, esJornadaCompleta, turno, data],
  );
  const currentDay = moment(currentWeek).add(day, 'days').format('DDMMYY');

  const [actualRow, setActualRow] = useState<AlumnoPresentismo>(data);
  const [modalIsOpenOnDay, setModalIsOpenOnDay] = useState('');

  const classes = useStyles();
  const [positionRef, setPositionRef] = useState<any>(null);
  const [daySelected, setDaySelected] = useState('');

  const handleClose = useCallback(
    (e?) => {
      setPositionRef(null);
      setEnableAutoSave(true);
      setIsModalOpen(false);
      setDaySelected('');
    },
    [setEnableAutoSave, setIsModalOpen],
  );

  useEffect(() => {
    const currentSelectedDay = moment(currentWeek)
      .add(idxDaySelected, 'days')
      .format('DDMMYY');
    if (modalIsOpenOnDay !== currentSelectedDay) {
      handleClose();
    }
  }, [currentWeek, handleClose, idxDaySelected, modalIsOpenOnDay]);

  const selection = useCallback(() => {
    let label = '';
    const date = moment(currentWeek).add(day, 'days').format('YYYY-MM-DD');
    if (data.presentismo !== undefined) {
      label = data?.presentismo?.find(
        (element: PresenteModified) =>
          moment(element.fecha).isSame(moment(date)) && element.turno === turno,
      )?.estadoPresente?.descripcion;
    }
    return label;
  }, [currentWeek, data.presentismo, day, turno]);

  const onSelectModal = useCallback(
    (estadoPresente) => {
      const date = moment(currentWeek).add(day, 'days').format('YYYY-MM-DD');
      onSelect({ day: date, estadoPresente, row: actualRow, turno });
      const newPoint = myGridPosition.clone();
      newPoint.ArrowDown(gridWidth, rows.length - 1);
      setGridPosition(newPoint);
      handleClose();
    },
    [
      actualRow,
      currentWeek,
      day,
      handleClose,
      onSelect,
      turno,
      gridWidth,
      myGridPosition,
      rows,
      setGridPosition,
    ],
  );

  const { ref, openModal } = useFocusGridPosition(
    myGridPosition,
    onSelectModal,
    validCell,
  );

  const isCurrentDay = useMemo(() => {
    return (
      moment(currentWeek).add(day, 'days').format('DDMMYY') ===
      moment(serverDate).format('DDMMYY')
    );
  }, [currentWeek, day, serverDate]);

  const clickCell = useCallback(
    (e: any) => {
      setGridPosition(myGridPosition);
      if (validCell && !isModalOpen) {
        const currentRow = rows.find(
          (element: AlumnoPresentismo) =>
            element.alumno.idAlumno === data.alumno.idAlumno,
        )!;
        setIdxDaySelected(day);
        setActualRow(currentRow);
        setPositionRef(e.currentTarget);
        setIsModalOpen(true);
        setEnableAutoSave(false);
        setModalIsOpenOnDay(currentDay);
        setDaySelected(currentDay);
      }
    },
    [
      currentDay,
      myGridPosition,
      setGridPosition,
      validCell,
      day,
      isModalOpen,
      rows,
      setIdxDaySelected,
      setIsModalOpen,
      setEnableAutoSave,
      data.alumno.idAlumno,
    ],
  );

  const getBorderClass = () => {
    return myGridPosition.isEqual(currentGridPosition)
      ? validCell
        ? classes.selectedDayBorder
        : classes.selectedDayBorderInvalidCell
      : '';
  };

  const isAuthorizedRol = isJornadaExtendidaTab
    ? [ROL.DOCENTE, ROL.COORDINADOR_JE].includes(Number(rol))
    : ROL.DOCENTE === Number(rol) ||
      ROLES_JERARQUICOS.filter((rol) => rol !== ROL.COORDINADOR_JE).includes(
        Number(rol),
      );

  return (
    <>
      <Tooltip
        placement={'top'}
        classes={{
          tooltip: classes.disabledPresentismo,
          tooltipPlacementTop: classes.tooltipPlacement,
        }}
        title={
          !currentSelectedDateIsSameOrBeforeServerDate ||
          Boolean(feriado) ||
          !isCurrentDateSameOrAfterLowerDeadlineForTomaPresentismo ||
          esEstadoEmergencia() ||
          esSuspensionClases() ||
          !isAuthorizedRol ||
          (isJornadaExtendidaTab ? !enabledByPropuesta : false) ? (
            <TooltipDisabledPresentismo
              futureDate={!currentSelectedDateIsSameOrBeforeServerDate}
              feriado={feriado}
              lowerDeadLine={
                !isCurrentDateSameOrAfterLowerDeadlineForTomaPresentismo
                  ? lowerDeadLineForTomaPresentismo
                  : false
              }
              emergencia={esEstadoEmergencia()}
              suspensionClases={esSuspensionClases()}
              noAuthorizedRol={!isAuthorizedRol}
              propuestaJE={isJornadaExtendidaTab ? !enabledByPropuesta : false}
            />
          ) : (
            ''
          )
        }
      >
        <Grid
          id="cell"
          onClick={(e: any) => {
            clickCell(e);
          }}
          onKeyPress={(e) => openModal(e)}
          ref={ref}
          className={`
            ${Boolean(feriado) ? classes.feriado : classes.notFeriado}
            ${classes.cell} ${!selection() && enabled ? classes.day : ''}
            ${enabled && existTM ? classes.enabled : classes.disabled}
            ${isAuthorizedRol ? classes.enabled : classes.disabled}
            ${classes[selection()?.toLocaleLowerCase().replaceAll(' ', '-')]}
            ${isCurrentDay ? classes.currentDay : classes.notCurrentDay} 
            ${
              esEstadoEmergencia() || esSuspensionClases()
                ? classes.disabledEmergenciaSuspensionClases
                : ''
            }
            ${getBorderClass()}
        `}
        >
          <Typography
            style={{
              textAlign: 'center',
              alignItems: 'center',
              height: '100%',
              justifyContent: 'center',
              display: 'flex',
              padding: '0 1rem',
              fontWeight: 'bold',
            }}
          >
            {selection()}
          </Typography>
        </Grid>
      </Tooltip>
      {!esEstadoEmergencia() &&
        !esSuspensionClases() &&
        isModalOpen &&
        Boolean(positionRef) && (
          <OptionsModalPresentismo
            positionRef={positionRef}
            onClose={handleClose}
            onSelect={onSelectModal}
            actualRow={actualRow}
          />
        )}
    </>
  );
};
