import { isEqual } from 'lodash';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { JornadaExtendida } from 'src/app/models';
import { AnyObject } from 'src/commons';

type ValidatorProps = {
  fieldsToValidate: string[];
  _rows: Array<JornadaExtendida>;
  isDynamic: boolean;
  filter: any;
  working: boolean;
  data: JornadaExtendida[];
};

export const useJornadaExtendidaValidator = ({
  fieldsToValidate,
  _rows,
  isDynamic,
  filter,
  working,
  data,
}: ValidatorProps) => {
  const [incompletedRows, setIncompletedRows] = useState<any>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [currentRows, setCurrentRows] = useState<any>();
  const [currentFilter, setCurrentFilter] = useState<any>();
  const [submitRows, setSubmitRows] = useState<any>([]);

  useEffect(() => {
    const submitRow = _rows.filter((row) => {
      let isUpdated = false;
      const currentCalification = data?.find(
        (d: any) => d.idCalificacion === row.idCalificacion,
      );
      if (currentCalification?.nota) {
        isUpdated = !isEqual(currentCalification?.nota?.data, row.nota.data);
      } else if (Object.keys(row.nota.data).length) {
        isUpdated = true;
      }
      return isUpdated;
    });
    if (submitRow.length === 0) {
      setSubmitRows(true);
    } else {
      setSubmitRows(false);
    }
  }, [_rows, data]);

  const validator = useCallback(() => {
    let disabled = false;
    if (filter !== currentFilter) {
      setCurrentFilter(filter);
      setIncompletedRows([]);
    }
    _rows.forEach((r) => {
      const fieldsToValidateByRow = [...fieldsToValidate];
      if (isDynamic) {
        if (r.nota.data.espaciosConsolidandoPregunta === 'Sí') {
          fieldsToValidateByRow.push('espaciosConsolidando');
        }
        if (r.nota.data.apoyoPregunta === 'Sí') {
          fieldsToValidateByRow.push('apoyo');
        }
      }
      r.fieldsToValidateByRow = fieldsToValidateByRow;
      const name = `${r?.apellido}, ${r?.nombre}`;
      const result = fieldsToValidateByRow.reduce(
        (acc: number, val: string) => {
          if (
            r.nota.data[val] === undefined ||
            r.nota.data[val] === null ||
            r.nota.data[val] === ''
          )
            ++acc;
          return acc;
        },
        0,
      );
      if (result !== fieldsToValidateByRow.length && result !== 0) {
        disabled = true;
        if (incompletedRows.indexOf(name) === -1) {
          setIncompletedRows([...incompletedRows, name]);
        }
      } else {
        if (incompletedRows.indexOf(name) !== -1) {
          const incompletedRowsCopy = [...incompletedRows];
          incompletedRowsCopy.splice(incompletedRowsCopy.indexOf(name), 1);
          setIncompletedRows(incompletedRowsCopy);
        }
      }
    });
    setIsDisabled(disabled);
  }, [
    incompletedRows,
    _rows,
    isDynamic,
    filter,
    currentFilter,
    fieldsToValidate,
  ]);

  useEffect(() => {
    if (currentRows !== _rows && !working) {
      setCurrentRows(_rows);
      validator();
    }
  }, [currentRows, _rows, validator, working]);

  return {
    incompletedRows,
    setIncompletedRows,
    isDisabled,
    submitRows,
  };
};
