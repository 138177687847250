import React, { useEffect, useState } from 'react';
import {
  Grid,
  makeStyles,
  TextField,
  FormControl,
  Typography,
} from '@material-ui/core';
import customFormDialog from 'src/commons/services/customFormDialog';
import { grey } from 'src/theme/colors';
import { Footer } from 'src/commons';

export interface ObservacionesModalProps {
  index?: any;
  onAddContent?: any;
  value: any;
  reFetch?: () => void;
  readonly?: boolean;
  maxLength?: number;
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    marginTop: theme.spacing(-10),
    position: 'relative',
  },
  modalContainer: {
    padding: theme.spacing(2),
  },
  titleDate: {
    color: grey.textPrimary,
  },
  readonly: {
    border: `1px solid grey`,
    borderRadius: 5,
    width: '100%',
    padding: 10,
    margin: '10px 0 20px 0',
    height: 300,
    overflowY: 'auto',
  },
}));

export const ObservacionesModal = ({
  onAddContent,
  value,
  readonly,
  maxLength,
}: ObservacionesModalProps) => {
  const classes = useStyles();
  const [content, setContent] = useState(value);
  const handleCloseModal = async () => {
    await customFormDialog.handleCancel();
  };

  useEffect(() => {
    setContent(value);
  }, [value]);

  const agregarObservacion = (event: any) => {
    setContent(event?.target.value);
  };
  const addHandle = async () => {
    onAddContent?.(content);
    await customFormDialog.handleCancel();
  };

  const buttonConfig: any = [
    {
      title: 'Cancelar',
      handleOnClick: () => handleCloseModal(),
      type: 'secondary',
      size: 'medium',
    },
    {
      title: 'Agregar',
      handleOnClick: () => addHandle(),
      size: 'medium',
      disabled: !content && !value,
    },
  ];

  const readonlyButtonConfig: any = [
    {
      title: 'Salir',
      handleOnClick: () => handleCloseModal(),
      type: 'secondary',
      size: 'medium',
    },
  ];

  return readonly ? (
    <Grid container className={classes.modalContainer}>
      <Grid container>
        <Typography classes={{ root: classes.readonly }}>{content}</Typography>
      </Grid>
      <Footer buttonConfig={readonlyButtonConfig} spacing={1} />
    </Grid>
  ) : (
    <Grid container className={classes.modalContainer}>
      <Grid container>
        <FormControl fullWidth variant="filled">
          <TextField
            multiline
            rows={12}
            name="observaciones"
            onChange={agregarObservacion}
            value={content}
            autoFocus
            inputProps={{
              style: { padding: '10px' },
              maxLength: maxLength ?? 1000,
            }}
          />
        </FormControl>
      </Grid>
      <Grid
        style={{ borderTop: '4px', marginTop: '5px' }}
        container
        justify="flex-end"
      ></Grid>
      <Footer buttonConfig={buttonConfig} spacing={1} />
    </Grid>
  );
};
