import React, { useMemo } from 'react';
import { useGruposLibbyCall, useGruposLibbyFetch } from 'src/app/business';
import { Grupos, Seccion } from 'src/app/models';
import { useGeneralContext } from 'src/context';
import { useRolesContext } from 'src/context/RolesContext';

interface GrupoEspacio {
  idEspacioCurricular: string;
  seccion: Seccion;
}
export const useGruposSeccion = (filter: Array<string>) => {
  const { generalState } = useGeneralContext();
  const {
    selectedRole: { localizacionId },
  } = useRolesContext();
  // NOTE: Los grupos deben poderse planificar como secciones solo si tienen el espacioCurricular,
  // TODO: verificar si tambien tienen que tener vinculada la seccion a la que le coresponde al docente

  const filterLocalizacion = useMemo(
    () => ({
      localizacion: [
        {
          path: 'localizacion.idLocalizacion',
          value: localizacionId,
        },
      ],
    }),
    [localizacionId],
  );

  const { data: gruposRequest = [], working }: any = useGruposLibbyFetch({
    limit: 100,
    aspect: 'grupos_limits',
    filter: filterLocalizacion,
  });

  const grupos = useMemo(() => {
    const gruposArray: Grupos[] = [];
    gruposRequest.forEach((element: any) => {
      const existsOnFilter = filter.find(
        (id: string) =>
          id ===
          element?.gruposEspacios?.espacioCurricular?.idEspacioCurricular,
      );
      if (existsOnFilter) {
        gruposArray.push(element);
      }
    });
    return gruposArray;
  }, [filter, gruposRequest]);

  const transformeGrupos = grupos.reduce<Seccion[]>((acumn, grupo) => {
    acumn.push(getTrasformerGrupoSeccion(grupo));
    return acumn;
  }, []);

  const gruposEspacios = grupos.reduce<GrupoEspacio[]>((acumn, grupo: any) => {
    const ids = grupo?.gruposEspacios.forEach((item: any) => {
      if (
        !acumn.find(
          (i) =>
            i.idEspacioCurricular ===
            item.espacioCurricular.idEspacioCurricular,
        )
      ) {
        acumn.push({
          idEspacioCurricular: item.espacioCurricular.idEspacioCurricular,
          seccion: getTrasformerGrupoSeccion(grupo),
        });
      }
    });
    return acumn;
  }, []);

  return { transformeGrupos, gruposEspacios, working };
};

const getTrasformerGrupoSeccion = (grupo: Grupos) =>
  ({
    idSeccion: `${grupo.idGrupo}-grupo`,
    nombreSeccion: grupo.nombre,
    nivel: grupo.nivel,
    localizacion: grupo.localizacion,
    capacidadRecomendada: grupo.capacidad,
    division: grupo.nombre,
  } as Seccion);
