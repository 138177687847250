import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { DatosLaboralesResp } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'datos_laborales_resp';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idDatosLaboralesResp',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idDatosLaboralesResp',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.DatosLaboralesResp
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.DatosLaboralesResp,
      hoc: { ...defaultOptions.hoc, ...businessOptions.DatosLaboralesResp.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<DatosLaboralesResp>(DAO_NAME, optionsToUse);

// hooks
export const useDatosLaboralesRespFetchById = fetchById;
export const useDatosLaboralesRespLibbyCall = libbyCall;
export const useDatosLaboralesRespDAO = libbyDAO;
export const useDatosLaboralesRespLibbyFetch = libbyFetch;

// components/hoc
export const DatosLaboralesRespAutocomplete = entityAutocomplete;
export const DatosLaboralesRespCall = renderPropCall;
export const DatosLaboralesRespById = renderPropFetchById;
export const DatosLaboralesRespSelect = simpleSelect;
export const DatosLaboralesRespAccordionSelector = accordionSelector;
export const DatosLaboralesRespSelectedChips = selectedChips;

// context
// ByIdContext
export const useDatosLaboralesRespByIdContext = useByIdContext;
export const DatosLaboralesRespByIdProvider = ByIdProvider;
export const DatosLaboralesRespByIdContext = ByIdContext;
// GeneralContext
export const useDatosLaboralesRespContext = useGeneralContext;
export const DatosLaboralesRespProvider = GeneralProvider;
export const DatosLaboralesRespContext = GeneralContext;
