import { LibbyFetchDAO } from './LibbyFetchDAO';

type agg_ret = {
  materia_id_anio: number;
}[];

export class PlanEstudioMateriaDAO extends LibbyFetchDAO {
  constructor() {
    super('plan_estudio_materia', 'idPlanEstudioMateria');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  // TIL: When you call functions with useLibbyCall, you get last server's response, not the return value of the function.
  async getIdAniosByPlanEstudio(
    planEstudio: number | number[],
    aspect: string = 'aniosByPlanEstudio',
  ) {
    // console.log(planEstudio);
    // console.log(typeof planEstudio);
    // console.log(!(Array.isArray(planEstudio) && planEstudio.length > 0));
    if (
      typeof planEstudio !== 'number' &&
      !(Array.isArray(planEstudio) && planEstudio.length > 0)
    )
      return [];
    const query = this.aspect(aspect).query();
    query.groupStart();
    [planEstudio].flat().forEach((n, i) => {
      if (i !== 0) query.or();
      query.equals('planEstudio', n);
    });
    const ret = query
      .groupEnd()
      .aggregate()
      .groupBy('materia.id_anio')
      .run()
      .then((res: agg_ret) => res.map((e) => e.materia_id_anio));
    this.removeAspects();
    return ret;
  }
}
