import { DiasHabilitadosDAO } from 'src/platform/libby/dao/Presentismov3';
import { makeBusinessEntity } from '../../lib/libby/generator';
import { DiasHabilitados } from '../models/DiasHabilitados';

export const DAO_NAME = 'dias_habilitados';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    selectInput,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<DiasHabilitados, DiasHabilitadosDAO>(DAO_NAME);

// hooks
export const useDiasHabilitadosFetchById = fetchById;
export const useDiasHabilitadosLibbyCall = libbyCall;
export const useDiasHabilitadosDAO = libbyDAO;
export const useDiasHabilitadosLibbyFetch = libbyFetch;

// components/hoc
export const DiasHabilitadosAutocomplete = entityAutocomplete;
export const DiasHabilitadosCall = renderPropCall;
export const DiasHabilitadosById = renderPropFetchById;
export const DiasHabilitadosSelect = selectInput;
export const DiasHabilitadosAccordionSelector = accordionSelector;
export const DiasHabilitadosSelectedChips = selectedChips;

// context
// ByIdContext
export const useDiasHabilitadosByIdContext = useByIdContext;
export const DiasHabilitadosByIdProvider = ByIdProvider;
export const DiasHabilitadosByIdContext = ByIdContext;
// GeneralContext
export const useDiasHabilitadosContext = useGeneralContext;
export const DiasHabilitadosProvider = GeneralProvider;
export const DiasHabilitadosContext = GeneralContext;
