import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { SeccionAulaVirtual } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'seccion_aula_virtual';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idSeccionAulaVirtual',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idSeccionAulaVirtual',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.SeccionAulaVirtual
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.SeccionAulaVirtual,
      hoc: { ...defaultOptions.hoc, ...businessOptions.SeccionAulaVirtual.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<SeccionAulaVirtual>(DAO_NAME, optionsToUse);

// hooks
export const useSeccionAulaVirtualFetchById = fetchById;
export const useSeccionAulaVirtualLibbyCall = libbyCall;
export const useSeccionAulaVirtualDAO = libbyDAO;
export const useSeccionAulaVirtualLibbyFetch = libbyFetch;

// components/hoc
export const SeccionAulaVirtualAutocomplete = entityAutocomplete;
export const SeccionAulaVirtualCall = renderPropCall;
export const SeccionAulaVirtualById = renderPropFetchById;
export const SeccionAulaVirtualSelect = simpleSelect;
export const SeccionAulaVirtualAccordionSelector = accordionSelector;
export const SeccionAulaVirtualSelectedChips = selectedChips;

// context
// ByIdContext
export const useSeccionAulaVirtualByIdContext = useByIdContext;
export const SeccionAulaVirtualByIdProvider = ByIdProvider;
export const SeccionAulaVirtualByIdContext = ByIdContext;
// GeneralContext
export const useSeccionAulaVirtualContext = useGeneralContext;
export const SeccionAulaVirtualProvider = GeneralProvider;
export const SeccionAulaVirtualContext = GeneralContext;
