import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { AccionOrientacion } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'accionesorientaciones';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idAccionOrientacion',
      labelKey: 'nombre',
    },
    withSelectedChips: {
      idKey: 'idAccionOrientacion',
      labelKey: 'nombre',
    },
  },
};

const optionsToUse = !businessOptions.AccionesOrientaciones
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.AccionesOrientaciones,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.AccionesOrientaciones.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
    selectInput,
  },
} = makeBusinessEntity<AccionOrientacion>(DAO_NAME, optionsToUse);

// hooks
export const useAccionesOrientacionesFetchById = fetchById;
export const useAccionesOrientacionesLibbyCall = libbyCall;
export const useAccionesOrientacionesDAO = libbyDAO;
export const useAccionesOrientacionesLibbyFetch = libbyFetch;

// components/hoc
export const AccionesOrientacionesAutocomplete = entityAutocomplete;
export const AccionesOrientacionesCall = renderPropCall;
export const AccionesOrientacionesById = renderPropFetchById;
export const AccionesOrientacionesSelect = simpleSelect;
export const AccionesOrientacionesSelectInput = selectInput;
export const AccionesOrientacionesAccordionSelector = accordionSelector;
export const AccionesOrientacionesSelectedChips = selectedChips;
