import { Grid, Typography } from '@material-ui/core';
import React from 'react';

export const ErrorInternetConnection = () => {
  return (
    <Grid container direction="column">
      <Typography variant="caption" color="inherit">
        Permanezca en ésta pantalla para no perder los avances de carga.
      </Typography>
      <Typography variant="caption" color="inherit">
        Cuando vuelva la conexión guarde los cambios
      </Typography>
    </Grid>
  );
};
