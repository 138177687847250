import React from 'react';
import { ButtonTypesProps, Footer } from 'src/commons';
import { useFormContext } from '../../../../../../../../lib/templates/context';

export const FormFooter = () => {
  const { handleSubmit, submitting } = useFormContext();

  const buttonConfig: ButtonTypesProps[] = [
    {
      title: 'Asesorar',
      handleOnClick: () => handleSubmit(),
      typeButton: 'submit',
    },
  ];

  return <Footer buttonConfig={buttonConfig} loading={submitting} />;
};
