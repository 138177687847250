import React, { useEffect } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { grey } from 'src/theme/colors';
import {
  FormRadio,
  TemplateProperty,
  useTemplateContext,
} from 'src/lib/templates';
import {
  useRolLibbyCall,
  useAutoRegistracionLibbyCall,
  useUsuarioRolEstablecimientoLibbyCall,
  useSeccionLibbyCall,
} from 'src/app/business';
import { Rol } from 'src/app/models';
import { ROL } from 'src/commons/const/rol';
import { useRolesContext } from 'src/context/RolesContext';
import {
  coordinadorOrientacionRoles,
  coordinadorRoles,
  directivoRoles,
  docenteRoles,
  tutorRoles,
} from '../../template';
import { NIVEL } from '../../../../../commons/const/nivel';
import { ROLES_BY_NIVEL } from '../../../../../commons/const/rolesByNivel';
import { useSignUpForm } from '../../hooks/useSignUpForm';

const SPACING = 4;

const rolTemplate: Record<string, TemplateProperty> = {
  2: docenteRoles,
  3: coordinadorRoles,
  4: coordinadorRoles,
  1: directivoRoles,
  5: directivoRoles,
  13: coordinadorOrientacionRoles,
  14: directivoRoles,
  16: directivoRoles,
  8: tutorRoles,
  7: directivoRoles, // TODO verify if preceptor equals to directivo
};

const useStyles = makeStyles({
  errorNotification: {
    color: 'red',
    fontSize: '0.8em',
  },
  radioContainer: {
    maxWidth: '400px',
  },
});

export const RolesStep = () => {
  const classes = useStyles();
  const { form, values } = useSignUpForm();
  const { setTemplate } = useTemplateContext();
  const { userInfo } = useRolesContext();
  const { data: autoregister = [] } = useAutoRegistracionLibbyCall({
    methodName: 'getByNivelRoles',
    params: [userInfo.id, values.idNivel, values.idLocalizacion],
  });

  const { data: userRoles = [] } = useUsuarioRolEstablecimientoLibbyCall({
    methodName: 'getByNivel',
    params: [userInfo.id, values.idNivel, values.idLocalizacion],
  });

  let params =
    values.isFuture && values.idNivel.toString() === NIVEL.SECUNDARIO.toString()
      ? ROLES_BY_NIVEL[NIVEL.SECUNDARIA_FUTURO]
      : ROLES_BY_NIVEL[values.idNivel as NIVEL];

  if (values?.showCORole && Number(values?.idNivel) === NIVEL.SECUNDARIO) {
    params.push(ROL.COORDINADOR_ORIENTACION);
    params.push(ROL.REFERENTE_ACAP);
  } else if (!values?.showCORole) {
    const newParams = params.filter(
      (param: number) =>
        ![ROL.COORDINADOR_ORIENTACION, ROL.REFERENTE_ACAP].includes(param),
    );
    params = newParams;
  }

  const { data: roles = [], working: rolWorking } = useRolLibbyCall({
    methodName: 'getRolesByIds',
    params,
  });

  const { data: secciones = [], working } = useSeccionLibbyCall({
    methodName: 'getForAutoregistration',
    params: [values.idLocalizacion, values.idNivel, 'Extendida'],
  });

  useEffect(() => {
    values.idRolUsuario &&
      setTemplate((oldTemplate) => {
        const newTemplate = oldTemplate.template.filter(
          (item) => item.name !== 'roles',
        );
        values?.idRolUsuario !== '4' &&
          newTemplate.push(rolTemplate[values.idRolUsuario]);
        return {
          ...oldTemplate,
          template: newTemplate,
        };
      });
  }, [setTemplate, values]);

  return rolWorking && working ? null : (
    <Grid
      container
      spacing={SPACING}
      direction="column"
      style={{ paddingRight: '5%', paddingLeft: '5%', flex: 1 }}
    >
      <Grid item>
        <Typography
          style={{
            fontFamily: 'Open Sans',
            color: grey.medium,
            fontSize: 20,
          }}
        >
          Rol
        </Typography>
        <Grid container direction="row" style={{ marginTop: 20 }}>
          {roles?.map((rol: Rol) => (
            <Grid container alignItems="center" style={{ marginTop: 10 }}>
              <Grid item>
                <FormRadio
                  form={form}
                  name="idRolUsuario"
                  disabled={
                    !!autoregister.find(
                      (item) =>
                        item.rolUsuario.idRolUsuario === rol.idRolUsuario,
                    ) ||
                    !!userRoles.find(
                      (_rol) =>
                        _rol.rolUsuario.idRolUsuario === rol.idRolUsuario,
                    ) ||
                    (secciones.length === 0 && rol.idRolUsuario === 16)
                  }
                  value={rol.idRolUsuario.toString()}
                />
              </Grid>
              <Grid item className={classes.radioContainer}>
                <Typography>
                  {rol.descripcion}{' '}
                  {secciones.length === 0 &&
                  rol.idRolUsuario === 16 &&
                  !working ? (
                    <small className={classes.errorNotification}>
                      (Este colegio no tiene secciones de JE)
                    </small>
                  ) : (
                    ''
                  )}
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
