import { Button, TableCell } from '@material-ui/core';
import React, { useMemo } from 'react';
import cx from 'classnames';
import { useSelectedDayStyles } from '../hooks';
import { useDiasCeelStyles } from '../css';
import { DiaCellState } from '../../../types';

interface DiasCellProps {
  day: string;
  dayOfWeek: string;
  state: DiaCellState;
  isSelected: boolean;
  onSelecte: () => void;
  isDayFuture: boolean;
  isDisabled: boolean;
}
export const DiasCell = ({
  dayOfWeek,
  day,
  state,
  isSelected,
  onSelecte,
  isDisabled,
}: DiasCellProps) => {
  const classes = useDiasCeelStyles();
  const selectedClasses = useSelectedDayStyles();

  const classNames = useMemo(() => {
    if (isSelected) return;
    return dayOfWeek === 'Viernes' ? classes.last : classes.middle;
  }, [dayOfWeek, classes, isSelected]);

  return (
    <TableCell
      className={cx(
        classes.cell,
        classes.noBorderBotton,
        isSelected ? selectedClasses.selected : '',
        isSelected ? 'selected' : '',
      )}
      colSpan={2}
      align="center"
      onClick={onSelecte}
      role="button"
    >
      <div className={cx(classes.content, classNames)}>
        <Button
          className={cx(classes.button, classes[state])}
          size="small"
          fullWidth
          variant="text"
          disabled={isDisabled}
        >
          {dayOfWeek} {day}
        </Button>
      </div>
    </TableCell>
  );
};
