import React, { useMemo, useState, useEffect } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { grey } from 'src/theme/colors';
import { FormRadio } from 'src/lib/templates';
import { ModalidadType } from 'src/app/models';
import {
  useLocalizacionLibbyFetch,
  DistritoEscolarSelect,
  DependenciaFuncionalSelect,
  useSecundariaFuturoLibbyCall,
} from 'src/app/business';
import { InfoTable, MainInput, useDebounce } from 'src/commons';
import { useSignUpForm } from '../../hooks/useSignUpForm';
import { useEstablecimientosAcapLibbyFetch } from 'src/app/business/acap';

const localizacionFixedFilter = {
  fixedLocalizacionSkip: [{ path: 'cueAnexo', value: 0, method: 'higher' }],
};

const SPACING = 4;

const column = [
  {
    id: 'cueAnexo',
    label: 'CUE',
    width: '5%',
    orderById: 'cueAnexo',
  },
  {
    id: 'anexo',
    label: 'Anexo',
    width: '2%',
    orderById: 'anexo',
  },
  {
    id: 'establecimiento',
    label: 'Establecimiento',
    width: '20%',
    orderById: 'descripcion',
  },
  {
    id: 'dependenciaFuncional',
    label: 'Dependencia Funcional',
    width: '2%',
    orderById: 'establecimiento.dependenciaFuncional.idDependenciaFuncional',
  },
  {
    id: 'distrito',
    label: 'Distrito',
    width: '2%',
    orderById: 'establecimiento.distritoEscolar.nombre',
  },
  {
    id: 'direccion',
    label: 'Dirección',
    width: '10%',
    orderById: 'domicilioEstablecimiento.descripcion',
  },
];

export const EstablecimientoStep = () => {
  const { form, values } = useSignUpForm();
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [state, setState] = useState({
    idModalidad: '',
    idDistritoEscolar: '',
    idDependenciaFuncional: '',
  });

  const [search, setSearch] = useState('');
  const searchDebounced = useDebounce(search, 1000);

  const [orderBy, setOrderBy] = useState('cueAnexo');
  const filterLocalizacion = useMemo(
    () => ({
      distrito: state.idDistritoEscolar
        ? [
            {
              path: 'establecimiento.distritoEscolar.idDistritoEscolar',
              value: state.idDistritoEscolar,
            },
          ]
        : null,
      dependenciaFuncional: state.idDependenciaFuncional
        ? [
            {
              path: 'establecimiento.dependenciaFuncional.idDependenciaFuncional',
              value: state.idDependenciaFuncional,
            },
          ]
        : null,
      noDependenciaFuncional: [1].map((item) => {
        return {
          path: 'establecimiento.dependenciaFuncional.idDependenciaFuncional',
          value: item,
          method: 'notEquals',
        };
      }),
      noTwoDependenciaFuncional: [50].map((item) => {
        return {
          path: 'establecimiento.dependenciaFuncional.idDependenciaFuncional',
          value: item,
          method: 'notEquals',
        };
      }),
      noThreeDependenciaFuncional: [60].map((item) => {
        return {
          path: 'establecimiento.dependenciaFuncional.idDependenciaFuncional',
          value: item,
          method: 'notEquals',
        };
      }),
    }),
    [state],
  );

  const filterSelectInput = useMemo(
    () => ({
      noDependenciaFuncional: [1].map((item) => {
        return {
          path: 'idDependenciaFuncional',
          value: item,
          method: 'notEquals',
        };
      }),
      noTwoDependenciaFuncional: [50].map((item) => {
        return {
          path: 'idDependenciaFuncional',
          value: item,
          method: 'notEquals',
        };
      }),
      noThreeDependenciaFuncional: [60].map((item) => {
        return {
          path: 'idDependenciaFuncional',
          value: item,
          method: 'notEquals',
        };
      }),
    }),
    [],
  );

  const filterByText = useMemo(
    () => ({
      search: [
        {
          path: 'establecimiento.dependenciaFuncional.descripcion',
          value: searchDebounced,
          method: 'includes',
        },
        {
          path: 'establecimiento.modalidad.descripcion',
          value: searchDebounced,
          method: 'includes',
        },
        {
          path: 'establecimiento.distritoEscolar.nombre',
          value: searchDebounced,
          method: 'includes',
        },
        { path: 'descripcion', value: searchDebounced, method: 'includes' },
        { path: 'cueAnexo', value: searchDebounced, method: 'includes' },
        {
          path: 'domicilioEstablecimiento.calle',
          value: searchDebounced,
          method: 'includes',
        },
        {
          path: 'domicilioEstablecimiento.altura',
          value: searchDebounced,
          method: 'includes',
        },
      ],
    }),
    [searchDebounced],
  );

  const filter = useMemo(
    () => ({
      ...filterLocalizacion,
      ...(searchDebounced ? filterByText : {}),
    }),
    [filterByText, filterLocalizacion, searchDebounced],
  );

  const { data, fetchMore, working } = useLocalizacionLibbyFetch({
    orderBy,
    direction,
    filter,
    fixedFilter: localizacionFixedFilter,
  });

  const { recall } = useSecundariaFuturoLibbyCall({
    methodName: 'getByEstablecimiento',
    params: [],
    noAutoCall: true,
  });

  const filterEscuelasAcap = useMemo(
    () => ({
      search: [
        {
          path: 'habilitado',
          value: true,
        },
      ],
    }),
    [],
  );

  const { data: establecimientosAcap = [] } = useEstablecimientosAcapLibbyFetch(
    {
      limit: 500,
      filter: filterEscuelasAcap,
      checkDuplication: false,
      checkDuplicationDeep: false,
    },
  );

  useEffect(() => {
    form.change('idNivel', null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (values.idLocalizacion) {
        const localizacion = data?.find(
          (loca) =>
            loca.idLocalizacion.toString() === values.idLocalizacion.toString(),
        );
        if (localizacion) {
          const showCORole = establecimientosAcap.some(
            (item) =>
              Number(item.localizacion.idLocalizacion) ===
              Number(values.idLocalizacion),
          );

          const items = await recall(
            localizacion.establecimiento.idEstablecimiento,
          );

          const isHospitalary =
            localizacion.establecimiento.modalidad.idModalidad ===
            ModalidadType.EspecialAyDomiciliariaC;
          form.change('isFuture', items?.length > 0);
          form.change('isHospitalary', isHospitalary);
          form.change('showCORole', showCORole); // Coordinador de Orientacion
          if (isHospitalary) form.change('idNivel', -1);
        }
      }
    })();
  }, [recall, form, values.idLocalizacion, data, establecimientosAcap]);

  const rows = useMemo(() => {
    return data?.map(
      ({
        idLocalizacion,
        cueAnexo,
        anexo,
        descripcion,
        establecimiento,
        domicilioEstablecimiento,
      }) => ({
        cueAnexo: (
          <Grid container alignItems="center">
            <Grid item>
              <FormRadio
                form={form}
                name="idLocalizacion"
                value={idLocalizacion}
              />
            </Grid>
            <Grid item style={{ maxWidth: '200px' }}>
              {cueAnexo.slice(0, -2)}
            </Grid>
          </Grid>
        ),
        anexo: anexo.padStart(2, '0'),
        establecimiento: descripcion,
        modalidad: establecimiento?.modalidad?.descripcion,
        dependenciaFuncional:
          establecimiento?.dependenciaFuncional?.descripcion,
        distrito: establecimiento?.distritoEscolar?.nombre,
        direccion: `${domicilioEstablecimiento?.calle} ${domicilioEstablecimiento?.altura} (${domicilioEstablecimiento?.codigoPostal})`,
      }),
    );
  }, [data, form]);

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  return (
    <Grid
      container
      spacing={SPACING}
      direction="column"
      style={{ paddingRight: '5%', paddingLeft: '5%', flex: 1 }}
    >
      <Typography
        style={{
          fontFamily: 'Open Sans',
          color: grey.medium,
          fontSize: 20,
          marginBottom: 30,
        }}
      >
        Establecimiento
      </Typography>
      <Grid item direction="row">
        <Grid
          container
          spacing={SPACING}
          justify="flex-start"
          alignItems="center"
        >
          <Grid item xs={2}>
            <DistritoEscolarSelect
              title="Distrito Escolar"
              placeholder="Seleccioná un distrito"
              value={state.idDistritoEscolar}
              handleChange={({ target }) => {
                setState({ ...state, idDistritoEscolar: target.value });
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <DependenciaFuncionalSelect
              title="Dependencia Funcional"
              placeholder="Seleccioná una dependencia funcional"
              value={state.idDependenciaFuncional}
              handleChange={({ target }) => {
                setState({ ...state, idDependenciaFuncional: target.value });
              }}
              filter={filterSelectInput}
            />
          </Grid>
          <Grid item xs={8} justify="flex-end" alignItems="flex-end">
            <Grid xs={6} justify="flex-end" alignItems="flex-end">
              <MainInput
                type="search"
                handleChange={({ target }) => {
                  setSearch(target.value);
                }}
                placeholder="Buscar"
                name="search"
                value={search}
                fullWidth
                customStyle={{ padding: 9.5, marginTop: 30 }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <InfoTable
        working={working}
        rows={rows || []}
        columns={column}
        orderBy={orderBy}
        direction={direction}
        onSortChange={handleRequestSort}
        rowIdKey="id"
        onBottomScroll={fetchMore}
      />
    </Grid>
  );
};
