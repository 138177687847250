import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { Secundario } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'secundario';

const defaultOptions: PartialBusinessEntityOptions<any> = {};

const optionsToUse = !businessOptions.Secundario
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Secundario,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Secundario.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Secundario>(DAO_NAME, optionsToUse);

// hooks
export const useSecundarioFetchById = fetchById;
export const useSecundarioLibbyCall = libbyCall;
export const useSecundarioDAO = libbyDAO;
export const useSecundarioLibbyFetch = libbyFetch;

// components/hoc
export const SecundarioAutocomplete = entityAutocomplete;
export const SecundarioCall = renderPropCall;
export const SecundarioById = renderPropFetchById;
export const SecundarioSelect = simpleSelect;
export const SecundarioAccordionSelector = accordionSelector;
export const SecundarioSelectedChips = selectedChips;

// context
// ByIdContext
export const useSecundarioByIdContext = useByIdContext;
export const SecundarioByIdProvider = ByIdProvider;
export const SecundarioByIdContext = ByIdContext;
// GeneralContext
export const useSecundarioContext = useGeneralContext;
export const SecundarioProvider = GeneralProvider;
export const SecundarioContext = GeneralContext;
