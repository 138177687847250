import { AlumnoMovimiento } from 'src/app/models';
import { makeEntityLibbyDAO } from 'src/lib/libby';
import { REQUEST_TYPES } from 'src/lib/libby/hooks/useLibbyRequest';
import { InformesCalificacionDAO } from 'src/platform/libby/dao';
import { makeEntityLibbyRequest } from '../../../lib/libby/hooks/makeEntityLibbyRequest';

type GetRequestParams = {
  localizacionId: string;
  nivelId: string;
  searchDebounced: string;
  rowsPerPage: number;
  page: number;
  orderBy: string;
  direction: 'asc' | 'desc';
  section: string;
  ciclo: string;
};

const DAO_NAME = 'informesmatriculacion';

// hooks
export const useInformesMatriculacionGetRequest = (
  params: GetRequestParams,
  form: any,
) => {
  const queryParams = new URLSearchParams();
  queryParams.append('seccion.idLocalizacion', `${params.localizacionId}`);
  queryParams.append('seccion.idNivel', `${params.nivelId}`);
  queryParams.append('seccion.idAnio', `${form[`seccion.anio`]}`);
  queryParams.append(
    'seccion.idSeccion',
    `${params.section || form[`seccion.nombreSeccion`]}`,
  );
  queryParams.append('seccion.idTurno', `${form[`seccion.turno`]}`);
  queryParams.append('seccion.jornada', `${form[`seccion.jornada`]}`);
  queryParams.append(
    'cicloLectivo',
    `${params.ciclo || form[`cicloLectivo.idCicloLectivo`]}`,
  );
  queryParams.append(
    `alumno.condicion.idCondicion`,
    `${form[`alumno.condicion`]}`,
  );
  queryParams.append('text', params.searchDebounced);
  queryParams.append('limit', `${params.rowsPerPage}`);
  queryParams.append('offset', `${params.rowsPerPage * params.page}`);
  queryParams.append('sort', `${params.orderBy}`);
  queryParams.append('sortOrder', `${params.direction}`);

  const getRequest = makeEntityLibbyRequest(DAO_NAME, REQUEST_TYPES.GET);

  return getRequest<AlumnoMovimiento[]>({
    url: `/api/documents/custom/informesmatriculacion?${queryParams.toString()}`,
    autoCall: true,
  });
};
export const useInformesMatriculacionPostRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.POST,
);
export const useInformesMatriculacionPutRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.PUT,
);
export const useInformesMatriculacionDeleteRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.DELETE,
);

export const useInformeMatriculacionDAO =
  makeEntityLibbyDAO<InformesCalificacionDAO>(DAO_NAME);
