import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { IndicadoresDeEvaluacion } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'indicadores_de_evaluacion';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idIndicadoresDeEvaluacion',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idIndicadoresDeEvaluacion',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.IndicadoresDeEvaluacion
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.IndicadoresDeEvaluacion,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.IndicadoresDeEvaluacion.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<IndicadoresDeEvaluacion>(DAO_NAME, optionsToUse);

// hooks
export const useIndicadoresDeEvaluacionFetchById = fetchById;
export const useIndicadoresDeEvaluacionLibbyCall = libbyCall;
export const useIndicadoresDeEvaluacionDAO = libbyDAO;
export const useIndicadoresDeEvaluacionLibbyFetch = libbyFetch;

// components/hoc
export const IndicadoresDeEvaluacionAutocomplete = entityAutocomplete;
export const IndicadoresDeEvaluacionCall = renderPropCall;
export const IndicadoresDeEvaluacionById = renderPropFetchById;
export const IndicadoresDeEvaluacionSelect = simpleSelect;
export const IndicadoresDeEvaluacionAccordionSelector = accordionSelector;
export const IndicadoresDeEvaluacionSelectedChips = selectedChips;

// context
// ByIdContext
export const useIndicadoresDeEvaluacionByIdContext = useByIdContext;
export const IndicadoresDeEvaluacionByIdProvider = ByIdProvider;
export const IndicadoresDeEvaluacionByIdContext = ByIdContext;
// GeneralContext
export const useIndicadoresDeEvaluacionContext = useGeneralContext;
export const IndicadoresDeEvaluacionProvider = GeneralProvider;
export const IndicadoresDeEvaluacionContext = GeneralContext;
