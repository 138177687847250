import { Box, Button, Grid, Typography } from '@material-ui/core';
import { CloudUploadOutlined } from '@material-ui/icons';
import { FieldValidator } from 'final-form';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useField } from 'react-final-form-hooks';
import { getHelperTextNoTranslation } from '../../../../../lib/templates/hooks/makeGetHelperText';
import AttachFile from '@material-ui/icons/AttachFile';

interface FileInputProps {
  onChange: (value: any) => void;
  onDrop?: React.DragEventHandler<HTMLElement>;
  className?: string;
  disabled?: boolean;
  error: boolean;
  helperText: string;
  withDrop?: boolean;
  onFocus: () => void;
  onBlur: () => void;
}

export const FileInput = ({
  className,
  disabled,
  error,
  helperText,
  withDrop = false,
  onDrop = () => {},
  onChange,
  ...rest
}: FileInputProps) => {
  const [dropActive, setDropActive] = useState(false);
  const hiddenFileInput = useRef<any>(null);
  const textRef = useRef<HTMLElement>(null);
  const handleRef = useCallback<React.MouseEventHandler<HTMLElement>>(
    (e) => {
      hiddenFileInput.current?.click();
      rest.onFocus();
      rest.onBlur();
    },
    [rest],
  );

  const onDragLeave = useCallback<React.DragEventHandler<HTMLElement>>(
    (e) => {
      if (!withDrop || disabled) return;
      e.preventDefault();
      if (!textRef.current) return;
      setDropActive(false);
      textRef.current.textContent =
        'Arrastrá tus archivos o hace click para adjuntar';
    },
    [withDrop, disabled],
  );

  const onDragOver = useCallback<React.DragEventHandler<HTMLElement>>(
    (e) => {
      if (!withDrop || disabled) return;
      e.preventDefault();
      if (!textRef.current) return;
      if (dropActive) return;
      setDropActive(true);
      textRef.current.textContent = 'Suelta aquí para cargar los archivos';
    },
    [dropActive, disabled, withDrop],
  );

  const onDropped = useCallback<React.DragEventHandler<HTMLElement>>(
    (e) => {
      if (!withDrop || disabled) return;
      e.preventDefault();
      if (!textRef.current) return;
      setDropActive(false);
      textRef.current.textContent =
        'Arrastrá tus archivos o hace click para adjuntar';
      e.persist();
      rest.onFocus();
      rest.onBlur();
      onDrop(e);
    },
    [withDrop, rest, disabled, onDrop],
  );

  const borderBy = useMemo(() => {
    if (disabled) return '2px dashed #00000042';
    if (error) return '2px solid #C93B3B';
    if (dropActive) return '2px dashed #38485C';
    return '2px dashed #8A98A8';
  }, [dropActive, error, disabled]);

  const colorBy = useMemo(() => {
    if (disabled) return '#9EAAB8';
    return '#007BC7';
  }, [disabled]);

  return (
    <>
      {withDrop ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection={'column'}
          style={{ cursor: disabled ? 'auto' : 'pointer' }}
          onClick={handleRef}
          bgcolor="#F3F6F9"
          border={borderBy}
          borderRadius={4}
          height={70}
          color={colorBy}
          fontFamily="Nunito"
          fontSize={13}
          fontWeight="bold"
          padding={4}
          className={className}
          {...rest}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
          onDrop={onDropped}
        >
          <CloudUploadOutlined
            style={{ pointerEvents: 'none', zIndex: 0 }}
            fontSize="large"
          />
          <span
            style={{ pointerEvents: 'none', zIndex: 0, textAlign: 'center' }}
            ref={textRef}
          >
            Arrastrá tus archivos o hace click para adjuntar
          </span>
        </Box>
      ) : (
        <Button
          startIcon={<AttachFile />}
          disabled={disabled}
          onClick={handleRef}
          variant="contained"
          color="primary"
          style={{
            backgroundColor: disabled ? '#9EAAB8' : '#3786EE',
            color: 'white',
            marginTop: '20px',
          }}
          onDragLeave={onDragLeave}
          onDragOver={onDragOver}
          onDrop={onDropped}
        >
          Subir Archivo
        </Button>
      )}

      <input
        ref={hiddenFileInput}
        style={{ display: 'none' }}
        type="file"
        name="justicación filename"
        multiple={true}
        onChange={onChange}
        disabled={disabled}
        {...rest}
      />
      {helperText && (
        <Typography style={{ color: 'red' }}>{helperText}</Typography>
      )}
    </>
  );
};

interface FormFileInputProps {
  form: any;
  name: string;
  className?: string;
  disabled?: boolean;
  withDrop?: boolean;
  validator?: FieldValidator<any>;
}

export const FormFileInput = ({
  name,
  form,
  disabled,
  className,
  withDrop = false,
  validator,
}: FormFileInputProps) => {
  const field = useField(name, form, validator);

  const handleChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const target = e.target;
      const files = [...(target.files as FileList)];
      field.input.onChange(files);
    },
    [field.input],
  );

  const handleDropChange = useCallback<React.DragEventHandler<HTMLElement>>(
    (e) => {
      const dataTransferFiles = e.dataTransfer.files;
      const files = [...dataTransferFiles];
      field.input.onChange(files);
    },
    [field.input],
  );

  return (
    <FileInput
      withDrop={withDrop}
      disabled={disabled}
      className={className}
      onChange={handleChange}
      onBlur={field.input.onBlur}
      onFocus={field.input.onFocus}
      onDrop={handleDropChange}
      {...getHelperTextNoTranslation(field.meta)}
    />
  );
};
