import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import {
  useEspacioCurricularLibbyFetch,
  useSeccionLibbyFetch,
} from 'src/app/business';
import { Loading, Content, ROL } from 'src/commons';
import { TabBar as TabBarComp } from 'src/commons/components/TabBar';
import { TABBAR_BLOCKER_ID } from 'src/platform/permission/const';
import { PeriodosCalificacionesParam } from '../components';
import { CalificacionesProvider } from '../context';
import { TabBarCuartoBimestreProps } from '../types';
import {
  AspectosGenerales,
  QueHaceresEstudiante,
  Convivencia,
  AreaDeConocimiento,
  Observaciones,
} from './tabs';
import { InformeValorativo } from './tabs/InformeValorativo';
import { JornadaExtendida } from './tabs/JornadaExtendida';
import { SintesisConceptual } from './tabs/SintesisConceptual';
import { useRolesContext } from 'src/context/RolesContext';

export const TabBarCuartoBimestre = ({
  working,
  validYears: { anio, anioJe },
}: TabBarCuartoBimestreProps) => {
  const { periodo } = useParams<PeriodosCalificacionesParam>();

  const { data: espaciosCurriculares = [] } = useEspacioCurricularLibbyFetch({
    limit: 200,
  });

  const {
    selectedRole: { rol },
  } = useRolesContext();

  const { data: secciones = [] } = useSeccionLibbyFetch({
    limit: 200,
    aspect: 'front-light',
  });

  const existeJornadaExtendida = secciones.some(
    (seccion) => seccion.jornada === 'Extendida',
  );

  const validateRol = [
    ROL.PRECEPTOR,
    ROL.DIRECTIVO,
    ROL.EQUIPO_CONDUCCION,
    ROL.SUPERVISOR,
    ROL.DIRECCION_AREA,
    ROL.DIRECTIVO,
    ROL.DOCENTE,
  ].includes(Number(rol));

  const coordinadorJE = Number(rol) === ROL.COORDINADOR_JE;
  const docenteJE =
    espaciosCurriculares.some((ec) =>
      ec.descripcion.toLowerCase().includes('jornada extendida'),
    ) && Number(rol) === ROL.DOCENTE;

  const mostrarSoloJornadaExtendida = docenteJE || coordinadorJE;

  const tabs = useMemo(() => {
    const components: Content[] = [];

    if (mostrarSoloJornadaExtendida) {
      if (existeJornadaExtendida) {
        components.push({
          id: TABBAR_BLOCKER_ID.CALIFICACION_PRIMARIO_JE,
          title: 'Jornada Extendida',
          component: (
            <JornadaExtendida
              jeAnioFilter={{ anio: anioJe }}
              docenteJE={docenteJE}
            />
          ),
        });
      }
    } else {
      components.push(
        {
          id: TABBAR_BLOCKER_ID.CALIFICACION_PRIMARIO_ASPECTOS_GENERALES,
          title: 'Aspectos Generales',
          component: <AspectosGenerales anioFilter={{ anio }} />,
        },
        {
          id: TABBAR_BLOCKER_ID.CALIFICACION_PRIMARIO_QUEHACERES,
          title: 'Quehaceres del/la Estudiante',
          component: <QueHaceresEstudiante anioFilter={{ anio }} />,
        },
        {
          id: TABBAR_BLOCKER_ID.CALIFICACION_PRIMARIO_CONVIVENCIA,
          title: 'Convivencia',
          component: <Convivencia anioFilter={{ anio }} />,
        },
        {
          id: TABBAR_BLOCKER_ID.CALIFICACION_PRIMARIO_AREA_CONOCIMIENTO,
          title: 'Calificación Área de Conocimiento',
          component: <AreaDeConocimiento anioFilter={{ anio }} />,
        },
        {
          id: TABBAR_BLOCKER_ID.CALIFICACION_PRIMARIO_OBSERVACIONES,
          title: 'Observaciones',
          component: <Observaciones anioFilter={{ anio }} />,
        },
      );

      if (periodo === '4') {
        components.push({
          id: TABBAR_BLOCKER_ID.CALIFICACION_PRIMARIO_SINTESIS_CONCEPTUAL,
          title: 'Síntesis Conceptual',
          component: <SintesisConceptual anioFilter={{ anio }} />,
        });
      }

      if (periodo === '2' || periodo === '4') {
        components.push({
          id: TABBAR_BLOCKER_ID.INFORME_VALORATIVO,
          title: 'Informe Valorativo',
          component: <InformeValorativo anioFilter={{ anio }} />,
        });
      }
      if (existeJornadaExtendida && validateRol) {
        components.push({
          id: TABBAR_BLOCKER_ID.CALIFICACION_PRIMARIO_JE,
          title: 'Jornada Extendida',
          component: (
            <JornadaExtendida
              jeAnioFilter={{ anio: anioJe }}
              docenteJE={docenteJE}
            />
          ),
        });
      }
    }

    return components;
  }, [
    mostrarSoloJornadaExtendida,
    existeJornadaExtendida,
    anioJe,
    anio,
    periodo,
    validateRol,
    docenteJE,
  ]);

  return (
    <CalificacionesProvider>
      {working ? <Loading /> : <TabBarComp content={tabs} />}
    </CalificacionesProvider>
  );
};
