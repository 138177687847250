import React, { useMemo } from 'react';
import {
  Grid,
  IconButton,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  makeStyles,
} from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TuneIcon from '@material-ui/icons/Tune';
import TimelineIcon from '@material-ui/icons/Timeline';
import moment from 'moment';
import { primary } from 'src/theme/colors';
import { useTabBarSetValue } from 'src/commons';
import { GruposInscripciones } from 'src/app/models';

const getJornadas = (grupo: GruposInscripciones) => {
  const days = ['Lunes', 'Martes', 'Miercoles', 'Jueves', 'Viernes', 'Sabado'];
  return grupo.ofertaGrupo.jornadas.map((jornada) => ({
    day: days[jornada.cupo],
    start_time: moment(jornada.horaInicio, 'HH-mm-ss').format('HH-mm-ss'),
    end_time: moment(jornada.horaFin, 'HH-mm-ss').format('HH-mm-ss'),
    referent: `${grupo.ofertaGrupo.referente.apellido} ${grupo.ofertaGrupo.referente.nombre}`,
  }));
};

const useStyles = makeStyles({
  tableHead: { display: 'flex', flexDirection: 'column' },
});

export const useActividadesColumns = () => {
  const classes = useStyles();

  return useMemo(
    () => [
      {
        id: 'ofertaGrupo.ofertaTurno.oferta.accion.organizacion.nombre',
        label: 'Organización',
      },
      {
        id: 'ofertaGrupo.ofertaTurno.oferta.accion.organizacion.sector.nombre',
        label: 'Sector',
        hideSortIcon: true,
      },
      {
        id: 'ofertaGrupo.ofertaTurno.oferta.accion.tipoAccion.nombre',
        label: 'Tipo de acción',
        hideSortIcon: true,
      },
      {
        id: 'ofertaGrupo.ofertaTurno.oferta.accion.nombre',
        label: 'Acción',
        hideSortIcon: true,
      },
      {
        id: 'ofertaGrupo.ofertaTurno.turno.descripcionTurno',
        label: 'Turno',
        hideSortIcon: true,
      },
      {
        id: 'ofertaGrupo.ofertaTurno.oferta.accion.nombre',
        label: 'Equipo',
        hideSortIcon: true,
      },
      {
        id: 'day',
        label: 'Día',
        hideSortIcon: true,
        customStyles: { padding: '0' },

        render: (_: unknown, row: GruposInscripciones) => {
          return (
            <TableHead classes={{ root: classes.tableHead }}>
              {getJornadas(row)
                .sort((a, b) => {
                  const daysOrder = [
                    'lunes',
                    'martes',
                    'miercoles',
                    'jueves',
                    'viernes',
                  ];
                  const dayA = a.day.toLowerCase();
                  const dayB = b.day.toLowerCase();
                  return daysOrder.indexOf(dayA) - daysOrder.indexOf(dayB);
                })
                .map((jornada, index) => (
                  <TableRow
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      ...(getJornadas(row).length > 1 &&
                      getJornadas(row).length - 1 !== index
                        ? { borderBottom: '1px solid #DFE3E8' }
                        : {}),
                      padding: '0 16px',
                    }}
                  >
                    <TableCell>
                      {jornada.day === 'Miercoles' ? 'Miércoles' : jornada.day}
                    </TableCell>
                  </TableRow>
                ))}
            </TableHead>
          );
        },
      },
      {
        id: 'startTime',
        label: 'Hora inicio',
        hideSortIcon: true,
        customStyles: { padding: '0' },

        render: (_: unknown, row: GruposInscripciones) => {
          return (
            <TableHead
              classes={{
                root: classes.tableHead,
              }}
            >
              {getJornadas(row).map((jornada, index: number) => {
                return (
                  <TableRow
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      ...(getJornadas(row).length > 1 &&
                      getJornadas(row).length - 1 !== index
                        ? { borderBottom: '1px solid #DFE3E8' }
                        : {}),
                      padding: '0 16px',
                    }}
                  >
                    <TableCell>
                      {moment(jornada.start_time, 'HH:mm:ss').format('HH:mm')}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableHead>
          );
        },
      },
      {
        id: 'endTime',
        label: 'Hora fin',
        hideSortIcon: true,
        customStyles: { padding: '0' },

        render: (_: unknown, row: GruposInscripciones) => {
          return (
            <TableHead
              classes={{
                root: classes.tableHead,
              }}
            >
              {getJornadas(row).map((jornada, index: number) => {
                return (
                  <TableRow
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      ...(getJornadas(row).length > 1 &&
                      getJornadas(row).length - 1 !== index
                        ? { borderBottom: '1px solid #DFE3E8' }
                        : {}),
                      padding: '0 16px',
                    }}
                  >
                    <TableCell>
                      {moment(jornada.end_time, 'HH:mm:ss').format('HH:mm')}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableHead>
          );
        },
      },
      {
        id: 'referent',
        label: 'Referente',
        hideSortIcon: true,
        customStyles: { padding: '0' },
        render: (_: unknown, row: GruposInscripciones) => {
          return (
            <TableHead
              classes={{
                root: classes.tableHead,
              }}
            >
              {getJornadas(row).map((jornada, index: number) => {
                return (
                  <TableRow
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      fontSize: '12px',
                      minWidth: '100px',
                      ...(getJornadas(row).length > 1 &&
                      getJornadas(row).length - 1 !== index
                        ? { borderBottom: '1px solid #DFE3E8' }
                        : {}),
                      padding: '0 16px',
                    }}
                  >
                    <TableCell style={{ padding: 0, borderBottom: '0px' }}>
                      {jornada.referent}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableHead>
          );
        },
      },
      { id: 'hoursAcap', label: 'HS ACAP', hideSortIcon: true },
      {
        id: 'icons',
        label: '',
        width: '1%',
        hideSortIcon: true,
        render: (_: unknown, row: GruposInscripciones) => {
          // eslint-disable-next-line react-hooks/rules-of-hooks
          const setTabBarValue = useTabBarSetValue();

          return (
            <Grid container style={{ height: '100%' }}>
              <Tooltip title="Cronograma" placement="top">
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <IconButton
                    onClick={() => {
                      setTabBarValue(1);
                    }}
                  >
                    <VisibilityIcon style={{ color: primary.paleBlueStrong }} />
                  </IconButton>
                </Grid>
              </Tooltip>
              <Tooltip title="Autoevaluación" placement="left">
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <IconButton
                    onClick={() => {
                      setTabBarValue(2, { id: row.idGrupoInscripcion });
                    }}
                  >
                    <TuneIcon style={{ color: primary.paleBlueStrong }} />
                  </IconButton>
                </Grid>
              </Tooltip>
              <Tooltip title="Devoluciones">
                <Grid
                  item
                  xs={12}
                  style={{ display: 'flex', justifyContent: 'center' }}
                >
                  <IconButton
                    onClick={() => {
                      setTabBarValue(3, { id: row.idGrupoInscripcion });
                    }}
                  >
                    <TimelineIcon style={{ color: primary.paleBlueStrong }} />
                  </IconButton>
                </Grid>
              </Tooltip>
            </Grid>
          );
        },
      },
    ],
    [classes.tableHead],
  );
};
