import { useRecursosLibbyCall } from 'src/app/business';
import { Recursos } from 'src/app/models';
import { makeEntityByEspacio } from '../../../commons/hooks';
import { useArealInterarealContext } from '../../context';

export const useRecursosFetch = () => {
  const { espacioCurricular } = useArealInterarealContext();

  const filterVoid = () => ({
    filter: {},
    limit: 20000,
  });

  const useRecursosDAO = makeEntityByEspacio<Recursos[]>(
    useRecursosLibbyCall,
    filterVoid,
    true,
  );

  const { data: recursos = [], working } = useRecursosDAO(espacioCurricular);

  return { recursos, working };
};
