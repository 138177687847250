import { InscripcionAlumnoDAO } from 'src/platform/libby/dao';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { InscripcionAlumno } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'inscripcion_alumno';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idInscripcionAlumno',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idInscripcionAlumno',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.InscripcionAlumno
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.InscripcionAlumno,
      hoc: { ...defaultOptions.hoc, ...businessOptions.InscripcionAlumno.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<InscripcionAlumno, InscripcionAlumnoDAO>(
  DAO_NAME,
  optionsToUse,
);

// hooks
export const useInscripcionAlumnoFetchById = fetchById;
export const useInscripcionAlumnoLibbyCall = libbyCall;
export const useInscripcionAlumnoDAO = libbyDAO;
export const useInscripcionAlumnoLibbyFetch = libbyFetch;

// components/hoc
export const InscripcionAlumnoAutocomplete = entityAutocomplete;
export const InscripcionAlumnoCall = renderPropCall;
export const InscripcionAlumnoById = renderPropFetchById;
export const InscripcionAlumnoSelect = simpleSelect;
export const InscripcionAlumnoAccordionSelector = accordionSelector;
export const InscripcionAlumnoSelectedChips = selectedChips;

// context
// ByIdContext
export const useInscripcionAlumnoByIdContext = useByIdContext;
export const InscripcionAlumnoByIdProvider = ByIdProvider;
export const InscripcionAlumnoByIdContext = ByIdContext;
// GeneralContext
export const useInscripcionAlumnoContext = useGeneralContext;
export const InscripcionAlumnoProvider = GeneralProvider;
export const InscripcionAlumnoContext = GeneralContext;
