import React, { createContext, useContext } from 'react';
import { useColegioMeta } from '../hooks/useColegioMeta';

const ColegioMetaContext = createContext<ColegioMetaNamespace.Meta.Context>({
  isDEI: false,
  isDEN: false,
});

export const useColegioMetaContext = () => useContext(ColegioMetaContext);

const ColegioMetaProvider = ({
  children,
}: ColegioMetaNamespace.Meta.ProviderProps) => {
  const { isDEI, isDEN } = useColegioMeta();
  return (
    <ColegioMetaContext.Provider
      value={{
        isDEI,
        isDEN,
      }}
    >
      {children}
    </ColegioMetaContext.Provider>
  );
};

export default ColegioMetaProvider;
