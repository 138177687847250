import React, {
  FunctionComponent,
  ReactText,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Grid } from '@material-ui/core';
import { Footer } from 'src/commons/components/Footer';
import customFormDialog from 'src/commons/services/customFormDialog';
import { SelectPassStatus } from './SelectPassStatus';
import { SelectPassSection } from './SelectPassSection';
import { SimpleSelect } from 'src/commons';
import { NIVEL } from 'src/commons/const';
import { usePendientesOpciones } from '../../../hooks/PromotionDetailSecundariaColumnHooks/usePendientesOpciones';
import { EstadoPaseAnio, Seccion } from 'src/app/models';
import { getStatusPassFromPendientes } from '../../../utils/getStatusPassFromPendientes';
import { getFilterPassSection } from '../../../utils/getFilterPassSection';
import { MAPPED_PASE_ANIO_OPTIONS_SECUNDARIO } from '../../../utils/mappedPaseAnioOptions';

interface OwnProps {
  yearPassStatus: EstadoPaseAnio[];
  sections: Seccion[];
  currentYear: ReactText;
  currentCicloLectivo: number;
  idCurrentSection: any;
  row: any;
  descripcionAnioSection: any;
  localizacionData: any;
  nivel: any;
  promocionAcompaniada?: boolean;
  proyeccionEstado?: any;
}

type Props = OwnProps;

export const FormPromotionDialog: FunctionComponent<Props> = ({
  yearPassStatus,
  sections,
  currentYear,
  currentCicloLectivo,
  idCurrentSection,
  row = {},
  descripcionAnioSection,
  localizacionData,
  nivel,
  promocionAcompaniada = false,
  proyeccionEstado,
}) => {
  const [formValuesDialog, setFormValuesDialog] = useState<any>({
    passSection: promocionAcompaniada
      ? row?.seccion?.idSeccion
      : getFilterPassSection(
          [...sections],
          Number(nivel) === NIVEL.INICIAL ? 1 : 10,
          currentCicloLectivo,
          row?.currentYear?.numeroAnio,
          nivel,
          descripcionAnioSection,
          row?.currentYear?.idAnio,
        ).filteredOptions[0]?.idSeccion ?? 'No Concurrirá',
    passStatus: promocionAcompaniada
      ? 5
      : Number(nivel) === NIVEL.INICIAL
      ? 1
      : 10,
    pendientesPrevias: 0,
    pendientesActuales: 0,
  });

  const handlePassStatusChange = (e: any) => {
    const newPassStatus = Number(e.target.value);
    const options = [...sections];
    const numeroAnio = row?.currentYear?.numeroAnio;
    const idAnio = row?.currentYear?.idAnio;

    const { filteredOptions }: any = getFilterPassSection(
      options,
      newPassStatus,
      currentCicloLectivo,
      numeroAnio,
      nivel,
      descripcionAnioSection,
      idAnio,
    );

    setFormValuesDialog((prevState: any) =>
      e.target.name === 'passStatus'
        ? {
            ...prevState,
            passStatus: newPassStatus,
            passSection:
              promocionAcompaniada && e.target.value !== 2
                ? row?.seccion?.idSeccion
                : e.target.value === 3
                ? null
                : filteredOptions.length === 0 && nivel === NIVEL.INICIAL
                ? 'Articula'
                : filteredOptions[0]?.idSeccion,
          }
        : {
            ...prevState,
            [e.target.name]: e.target.value,
          },
    );
  };

  const handlePassSectionChange = (e: any) => {
    const selectedSeccion = e.target.value;

    setFormValuesDialog((prevState: any) => ({
      ...prevState,
      passSection: selectedSeccion,
    }));
  };

  const handlePendientesActualesChange = (e: any) => {
    const selectedCurricularesPendientes = Number(e.target.value);

    const statusPass = getStatusPassFromPendientes({
      localizacionData: localizacionData,
      totalPendientes: selectedCurricularesPendientes,
      numeroAnio: row?.currentYear?.numeroAnio,
      descripcionTurno: sections[0]?.turno?.descripcionTurno,
    });

    const options = [...sections];
    const numeroAnio = row?.currentYear?.numeroAnio;
    const idAnio = row?.currentYear?.idAnio;

    const { filteredOptions }: any = getFilterPassSection(
      options,
      statusPass,
      currentCicloLectivo,
      numeroAnio,
      nivel,
      descripcionAnioSection,
      idAnio,
    );

    const { Egresa: EGRESA, 'Promoción en proceso': PROMOCION_EN_PROCESO } =
      MAPPED_PASE_ANIO_OPTIONS_SECUNDARIO;

    setFormValuesDialog((prevState: any) => ({
      ...prevState,
      passStatus: statusPass,
      pendientesActuales: selectedCurricularesPendientes,
      //seccionesFiltradas: filteredOptions,
      passSection: [PROMOCION_EN_PROCESO.id].includes(statusPass)
        ? '-'
        : [EGRESA.id].includes(statusPass)
        ? null
        : filteredOptions[0]?.idSeccion ?? 'No Concurrirá',
    }));
  };

  // Si no hay passStatus, lo setteamos a 1, 1 = promociona
  useEffect(() => {
    if (
      formValuesDialog?.passStatus === '' &&
      Number(currentYear) === 15 &&
      Number(nivel) === NIVEL.INICIAL
    ) {
      setFormValuesDialog((prevState: any) => ({
        passStatus: 3,
        passSection: '',
        ...prevState,
      }));
    } else if (
      formValuesDialog?.passStatus === '' &&
      Number(nivel) === NIVEL.INICIAL
    ) {
      setFormValuesDialog((prevState: any) => ({
        passStatus: 1,
        passSection: '',
        ...prevState,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentYear, formValuesDialog.passSection, formValuesDialog?.passStatus]);

  const dialogButtonConfig = [
    {
      title: 'Cancelar',
      handleOnClick: () => customFormDialog.handleCancel(),
      type: 'secondary',
      size: 'small',
    },
    {
      title: 'Agregar',
      handleOnClick: () => customFormDialog.handleConfirm(formValuesDialog),
      size: 'small',
      disabled: !formValuesDialog.passStatus,
    },
  ];

  const { espaciosCurricularesPendientes } = usePendientesOpciones();

  const disabled = useMemo(() => {
    let isDisabled;
    if (
      (Number(nivel) === NIVEL.SECUNDARIO ||
        Number(nivel) === NIVEL.SECUNDARIA_FUTURO) &&
      !!formValuesDialog.passStatus
    ) {
      isDisabled =
        formValuesDialog.passStatus.toString() === '3' ||
        formValuesDialog.passStatus.toString() === '6';
    } else {
      isDisabled = false;
    }

    return isDisabled;
  }, [formValuesDialog.passStatus, nivel]);

  return (
    <Grid
      container
      direction="column"
      justify="center"
      style={{ padding: '0 20px' }}
    >
      {(Number(nivel) === NIVEL.SECUNDARIO ||
        Number(nivel) === NIVEL.SECUNDARIA_FUTURO) && (
        <>
          <Grid item xs style={{ margin: '10px 0' }}>
            <SimpleSelect
              content={espaciosCurricularesPendientes}
              disabled={false}
              handleChange={handlePendientesActualesChange}
              labelKey="label"
              name="pendientesActuales"
              value={Number(formValuesDialog.pendientesActuales)}
              valueKey="value"
              disableUnderline
              variant="standard"
              title="Cantidad de espacios curriculares pendientes"
            />
          </Grid>
        </>
      )}

      <Grid item xs style={{ margin: '10px 0' }}>
        <SelectPassStatus
          title="Pase de Año"
          value={formValuesDialog.passStatus}
          options={yearPassStatus}
          handleChange={handlePassStatusChange}
          row={row}
          localizacionData={localizacionData}
          nivel={nivel}
          disabled={disabled}
          totalPendientes={formValuesDialog.pendientesActuales}
        />
      </Grid>
      <Grid item xs style={{ margin: '10px 0' }}>
        <SelectPassSection
          title="Sección a la que pasa"
          value={formValuesDialog.passSection}
          statusPass={formValuesDialog.passStatus}
          options={sections}
          numeroAnio={row.currentYear.numeroAnio}
          currentCicloLectivo={currentCicloLectivo}
          handleChange={handlePassSectionChange}
          row={row}
          disabled={
            !formValuesDialog.passStatus ||
            disabled ||
            formValuesDialog.passStatus === 3 ||
            (formValuesDialog.passStatus === 1 && promocionAcompaniada) ||
            (formValuesDialog.passStatus === 5 && promocionAcompaniada)
          }
          descripcionAnioSection={descripcionAnioSection}
          nivel={nivel}
          shoulBeNextYear={!promocionAcompaniada}
          proyeccionEstado={proyeccionEstado}
          idAnio={row?.idAnio}
        />
      </Grid>
      <Footer buttonConfig={dialogButtonConfig} spacing={1} />
    </Grid>
  );
};
