import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import { capitalize } from '@material-ui/core';
import {
  AnyObject,
  IFormValue,
  IPromotionFormValue,
  IHandleSelectGroupPass,
  IStudentsMovement,
  IUsePromotionTableLogic,
} from 'src/commons/types';
import {
  useLibbyCall,
  useLibbyFetch,
  useTableSortConfig,
} from 'src/commons/hooks';
import { useAlumnoMovimientoLibbyFetch } from 'src/app/business';
import { SelectGroupPass } from '../components/SelectGroupPass';
import { AdultPromotionColumns, BCPromotionColumns } from '../utils/const';
import { makeFilter } from '../utils/MakeFilters';

export const usePromotionTableLogic = ({
  libby,
  locationId,
  level,
  isAdultPromotion = false,
}: IUsePromotionTableLogic) => {
  const [studentsData, setStudentsData] = useState<IStudentsMovement[]>([]);
  const [formValues, setFormValues] = useState<IFormValue[]>([]);

  const [filter, setFilter] = useState<IPromotionFormValue>(
    makeFilter({ locationId, level }),
  );
  const { direction, orderBy, onSortChange } = useTableSortConfig(
    'alumno.persona.apellido',
  );

  const paramsFetch = useMemo(
    () => ({
      direction,
      orderBy,
      filter,
      aspect: 'promotion',
      limit: 100,
    }),
    [direction, filter, orderBy],
  );

  const {
    data: studentMovementData = [],
    working,
    reFetch,
  } = useAlumnoMovimientoLibbyFetch(paramsFetch);

  const { data: sectionData, working: sectionWorking } = useLibbyCall(libby, {
    daoName: 'seccion',
    methodName: 'getByLocationId',
    params: [locationId],
  });

  const studentsMapping: IStudentsMovement[] = useMemo(
    () =>
      studentMovementData
        .map((data: AnyObject) => ({
          ...data,
          ...data.alumno,
          ...data.cicloLectivo,
          ...data.estadoAlumno,
          ...data.seccion,
        }))
        .map(
          ({
            idAlumnoMovimiento,
            persona: { nombre, apellido },
            nivel: idNivel,
            ciclo: idCiclo,
            cicloLectivoAdultos: idCicloLectivoAdultos,
            turno: idTurno,
            nombreSeccion,
            division,
            jornada,
          }) => ({
            id: idAlumnoMovimiento,
            idLevel: +idNivel?.idNivel,
            idCycle: +idCiclo?.idCiclo,
            schoolYear: idCicloLectivoAdultos?.descripcionCicloLectivoAdulto,
            surnameName: capitalize(`${apellido}, ${nombre}`.toLowerCase()),
            level: idNivel?.descripcionNivel,
            cycle: isAdultPromotion ? division : idCiclo?.descripcionCiclo,
            turn: idTurno?.descripcionTurno,
            groupName: nombreSeccion,
            group: '',
            workingDay: jornada,
          }),
        ),
    [isAdultPromotion, studentMovementData],
  );

  useEffect(() => {
    setStudentsData(studentsMapping);
  }, [studentsMapping]);

  const handleChangeGroupPass: IHandleSelectGroupPass = (event, studentRow) => {
    const { value: newSection } = event.target as HTMLInputElement;
    setStudentsData((prevStudentsData) =>
      prevStudentsData.map((student) =>
        student.id === studentRow.id
          ? { ...student, group: newSection }
          : student,
      ),
    );
    setFormValues((prevFormValues) => {
      const existsStudent = prevFormValues.findIndex(
        ({ idAlumnoMovimiento }) => idAlumnoMovimiento === studentRow.id,
      );
      return existsStudent === -1
        ? [
            ...prevFormValues,
            { idAlumnoMovimiento: studentRow.id, idSeccion: newSection },
          ]
        : [
            ...prevFormValues.map((student) =>
              student.idAlumnoMovimiento === studentRow.id
                ? { ...student, idSeccion: newSection }
                : student,
            ),
          ];
    });
  };

  const rows: IStudentsMovement[] = studentsData.map((studentData) => {
    return {
      ...studentData,
      groupPass: (
        <SelectGroupPass
          currentIdCycle={studentData.idCycle}
          currentIdLevel={studentData.idLevel}
          disabled={sectionWorking}
          isAdultPromotion={isAdultPromotion}
          handleChange={(e: FormEvent<EventTarget>) =>
            handleChangeGroupPass(e, studentData)
          }
          options={sectionData}
          value={studentData.group}
        />
      ),
    };
  });

  const onSave = async () => {
    try {
      const promises: Promise<IFormValue[]>[] = [];
      formValues.forEach((studentMovement) => {
        if (studentMovement.idSeccion)
          promises.push(
            libby.alumno_movimiento.aspect('limit_plan').save({
              idAlumnoMovimiento: studentMovement.idAlumnoMovimiento,
              seccion: studentMovement.idSeccion,
            }),
          );
      });
      await Promise.all(promises);
      setFormValues([]);
      reFetch();
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
    }
  };

  const footerButtons = [
    {
      title: 'Cancelar',
      type: 'secondary',
      handleOnClick: () => {
        setFormValues([]);
        reFetch();
      },
      disabled: !formValues.length,
    },
    {
      title: 'Guardar',
      type: 'primary',
      handleOnClick: () => onSave(),
      disabled: !formValues.length,
    },
  ];

  return {
    columns: isAdultPromotion ? AdultPromotionColumns : BCPromotionColumns,
    direction,
    footerButtons,
    onSortChange,
    orderBy,
    rows,
    setFilters: setFilter,
    working,
  };
};
