import { LibbyFetchDAO } from './LibbyFetchDAO';

export class OrientacionDAO extends LibbyFetchDAO {
  constructor() {
    super('orientacion', 'idOrientacion');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
