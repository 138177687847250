import { makeStyles } from '@material-ui/styles';

export const permissionsStyles = makeStyles({
  autoCompleteStyle: {
    color: 'gray',
    marginBottom: '0.8em',
  },
  formContainer: {
    width: '30%',
    margin: 'auto',
  },
});
