import React, { FunctionComponent, useMemo } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { GruposInscripciones } from 'src/app/models';
import moment from 'moment';
import { colorSectionInscription } from '../../hooks';

interface InscripcionesProps {
  classImageList?: string;
  grupos: GruposInscripciones[];
  activeStartDate: Date;
}

export const Inscripciones: FunctionComponent<InscripcionesProps> = ({
  classImageList,
  grupos,
  activeStartDate,
}) => {
  const inscripcionesPorMes = useMemo(
    () =>
      grupos.filter((grupo) => {
        const fechaInicioGrupo = moment(
          grupo.ofertaGrupo.ofertaTurno.oferta.fechaInicio,
        );
        const fechaFinGrupo = moment(
          grupo.ofertaGrupo.ofertaTurno.oferta.fechaFin,
        );
        const fechaInicioMes = moment(activeStartDate).startOf('month');
        const fechaFinMes = moment(activeStartDate).endOf('month');

        const isInMonth =
          fechaInicioGrupo.isBetween(fechaInicioMes, fechaFinMes, null, '[]') ||
          fechaFinGrupo.isBetween(fechaInicioMes, fechaFinMes, null, '[]');

        const isOverlappingMonth =
          fechaInicioGrupo.isSameOrBefore(fechaFinMes) &&
          fechaFinGrupo.isSameOrAfter(fechaInicioMes);

        return isInMonth || isOverlappingMonth;
      }),
    [grupos, activeStartDate],
  );

  return (
    <>
      <Grid
        container
        xs={12}
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Grid item xs={6}>
          <Box
            style={{
              height: '15px',
              width: '400px',
              borderRadius: '50px',
              display: 'flex',
              alignItems: 'center',
              padding: '10px',
              boxShadow: '0px 3px 0px 1px rgba(231,231,231,1)',
            }}
          >
            <Typography
              style={{
                fontFamily: 'Nunito',
                fontSize: 16,
                fontWeight: 600,
              }}
            >
              Inscripciones mes{' '}
              {moment(activeStartDate).locale('ar').format('MMMM')}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} style={{ width: 400, marginTop: '10px' }}>
          {inscripcionesPorMes.length > 0 ? (
            inscripcionesPorMes.map((item) => {
              return (
                <Grid
                  container
                  style={{
                    marginTop: '5px',
                    marginBottom: '15px',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Grid item xs={2}>
                    <FiberManualRecordIcon
                      style={{
                        color: colorSectionInscription(
                          item.ofertaGrupo.ofertaTurno.oferta.accion
                            .organizacion.sector.nombre,
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {item.ofertaGrupo.ofertaTurno.oferta.accion.nombre}
                    </Typography>
                    <Typography style={{ color: '#afafaf' }}>
                      {
                        item.ofertaGrupo.ofertaTurno.oferta.accion.organizacion
                          .sector.nombre
                      }
                    </Typography>
                  </Grid>
                  {/* <Grid
                    item
                    xs={5}
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <ImageListItem
                      className={classImageList}
                      style={{
                        width: '120px',
                        height: '120px',
                        listStyle: 'none',
                      }}
                    >
                      <img src={item.img} alt={''} />
                    </ImageListItem>
                  </Grid> */}
                </Grid>
              );
            })
          ) : (
            <Typography>No hay inscripciones ACAP para este mes.</Typography>
          )}
        </Grid>
      </Grid>
    </>
  );
};
