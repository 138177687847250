import { Checkbox, IconButton } from '@material-ui/core';
import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { useMemo } from 'react';
import { useEstadoPaseAnioLibbyFetch } from 'src/app/business';
import { SelectPassSection } from '../../Promotion/Initial/PromotionDetail/components/SelectPassSection';
import { SelectPassStatus } from '../../Promotion/Initial/PromotionDetail/components/SelectPassStatus';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import customFormDialog from 'src/commons/services/customFormDialog';
import { FormPromotionDialog } from '../../Promotion/Initial/PromotionDetail/components/FormPromotionDialog';
import { useSnackbar } from 'notistack';
import { AnyObject } from 'src/commons/types';
import { Seccion, Localizacion } from 'src/app/models';

interface objectRowType {
  idAlumno: string;
  apellido: string;
  nombre: string;
  documento: string;
  condicion: string;
  seccion: {
    nombreSeccion: string;
    anio: {
      descripcionAnio: string;
    };
  };
  statusPass: number;
  idAnio: number;
  sectionPass: string;
  currentYear: {
    numeroAnio: number;
  };
  cicloLectivo: number;
}

export const usePromocionAcompaniadaColumns = (
  sections: Seccion[],
  handleChangePassStatus: (
    value: React.FormEvent<EventTarget>,
    values: objectRowType,
  ) => void,
  localizacionData: Localizacion[],
  nivel: string,
  handleChangePassSection: (
    value: React.FormEvent<EventTarget>,
    values: objectRowType,
  ) => void,
  checkedAll: boolean,
  setCheckedAll: (newValue: boolean) => void,
  setCheckStudent: Dispatch<SetStateAction<string[]>>,
  checkStudent: string[],
  setRestoreFlag: (newValue: boolean) => void,
  restoreFlag: boolean,
  rowsMap: [
    {
      idAnio: string | number;
      idAlumno: string;
      cicloLectivo: number;
      seccion: {
        idSeccion: number;
        anio: {
          descripcionAnio: string;
        };
      };
    },
  ],
  setRowsMap: (filter: AnyObject) => void,
) => {
  const { data: yearPassStatus = [] } = useEstadoPaseAnioLibbyFetch();
  const { enqueueSnackbar } = useSnackbar();
  const handleClickModal = useCallback(async () => {
    if (checkStudent.length !== 0) {
      try {
        const resolve = await customFormDialog.show({
          title: 'Pase de Año',
          sizeWidth: 'xs',
          renderComponent: (
            <FormPromotionDialog
              {...{
                yearPassStatus,
                sections,
                currentYear: rowsMap[0].idAnio,
                currentCicloLectivo: rowsMap[0].cicloLectivo,
                idCurrentSection: rowsMap[0]?.seccion?.idSeccion,
                row: rowsMap[0],
                descripcionAnioSection:
                  rowsMap[0]?.seccion?.anio?.descripcionAnio,
                localizacionData,
                nivel,
                materiasPendientes: [],
                promocionAcompaniada: true,
              }}
            />
          ),
        });
        if (resolve) {
          const { passSection, passStatus } = resolve as any;

          setRowsMap((e: any) =>
            e.map((item: any) =>
              checkStudent.includes(item.idAlumno) &&
              item?.sectionPass !== 'Articulado'
                ? {
                    ...item,
                    statusPass: passStatus,
                    sectionPass: passSection,
                  }
                : item,
            ),
          );
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      enqueueSnackbar('No hay alumnos seleccionados', {
        variant: 'error',
      });
    }
  }, [
    checkStudent,
    enqueueSnackbar,
    localizacionData,
    nivel,
    rowsMap,
    sections,
    setRowsMap,
    yearPassStatus,
  ]);

  const columns = useMemo(
    () => [
      {
        id: 'icon',
        width: '1%',
        label: (
          <Checkbox
            checked={checkedAll}
            onChange={(e) => {
              setCheckedAll(e.target.checked);
              return checkedAll
                ? setCheckStudent([])
                : setCheckStudent(rowsMap.map((student) => student.idAlumno));
            }}
            inputProps={{ 'aria-labelledby': 'transfer-list-item-all-label' }}
            color="primary"
          />
        ),
        hideSortIcon: true,
        noSort: true,
        align: 'left',
        render: (value: any, row: objectRowType) => {
          const isStudentChecked =
            checkedAll || checkStudent.includes(row?.idAlumno);
          return (
            <Checkbox
              name={row?.idAlumno}
              checked={checkedAll || isStudentChecked}
              onChange={() => {
                if (!checkStudent.length) setRestoreFlag(!restoreFlag);
                if (checkedAll) {
                  setCheckedAll(false);
                  return setCheckStudent([row?.idAlumno]);
                }
                return isStudentChecked
                  ? setCheckStudent(
                      checkStudent.filter(
                        (idStudent) => row?.idAlumno !== idStudent,
                      ),
                    )
                  : setCheckStudent([...checkStudent, row?.idAlumno]);
              }}
              inputProps={{ 'aria-labelledby': 'transfer-list-item-all-label' }}
              color="primary"
            />
          );
        },
      },
      {
        id: 'nombre',
        label: 'Apellido y Nombre',
        width: '5%',
        orderById: 'alumno.persona.apellido',
        render: (value: any, row: objectRowType) =>
          `${row?.apellido}, ${row?.nombre}`,
      },
      {
        id: 'documento',
        label: 'Documento',
        width: '5%',
        orderById: 'alumno.persona.documento',
        render: (value: any, row: objectRowType) => row?.documento,
      },
      {
        id: 'condicion',
        label: 'Condición',
        width: '5%',
        orderById: 'alumno.condicion.descripcionCondicion',
        render: (value: any, row: objectRowType) => row?.condicion,
      },
      {
        id: 'seccionActual',
        label: 'Sección Actual',
        hideSortIcon: true,
        width: '5%',
        render: (value: any, row: objectRowType) => row?.seccion?.nombreSeccion,
      },
      {
        id: 'passStatus',
        label: 'Estado de promoción',
        width: '5%',
        hideSortIcon: true,
        noSort: true,
        render: (value: any, row: objectRowType) => {
          return (
            <SelectPassStatus
              value={row?.statusPass}
              options={yearPassStatus}
              sections={sections}
              currentYear={row?.idAnio}
              row={row}
              handleChange={(e) => {
                handleChangePassStatus(e, row);
              }}
              disabled={row?.statusPass === 3 || row?.statusPass === 6}
              localizacionData={localizacionData}
              nivel={nivel}
              materiasPendientes={[]}
              isPromocionAcompanada
            />
          );
        },
      },
      {
        id: 'passSection',
        label: 'Sección a la que pasa',
        width: '5%',
        hideSortIcon: true,
        noSort: true,
        render: (value: any, row: objectRowType) => {
          return (
            <SelectPassSection
              value={row?.sectionPass}
              statusPass={row?.statusPass}
              options={sections}
              numeroAnio={row?.currentYear?.numeroAnio}
              currentCicloLectivo={row?.cicloLectivo}
              handleChange={(e: React.FormEvent<EventTarget>) =>
                handleChangePassSection(e, row)
              }
              disabled={
                !row?.statusPass ||
                row?.statusPass === 1 ||
                row?.statusPass === 5
              }
              row={row}
              descripcionAnioSection={row?.seccion?.anio?.descripcionAnio}
              nivel={nivel}
              shoulBeNextYear={false}
            />
          );
        },
      },
      {
        id: 'secondIcon',
        label: (
          <IconButton onClick={handleClickModal}>
            <MoreVertIcon />
          </IconButton>
        ),
        width: '1%',
        hideSortIcon: true,
        noSort: true,
        align: 'center',
        render: () => <></>,
      },
    ],
    [
      checkStudent,
      checkedAll,
      handleChangePassSection,
      handleChangePassStatus,
      handleClickModal,
      localizacionData,
      nivel,
      restoreFlag,
      rowsMap,
      sections,
      setCheckStudent,
      setCheckedAll,
      setRestoreFlag,
      yearPassStatus,
    ],
  );

  return columns;
};
