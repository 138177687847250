import { EstadoProyeccionMotivoDAO } from 'src/platform/libby/dao';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { EstadoProyeccionMotivo } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'estadoproyeccionmotivo';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEstadoProyeccionMotivo',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEstadoProyeccionMotivo',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EstadoProyeccionMotivo
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EstadoProyeccionMotivo,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.EstadoProyeccionMotivo.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EstadoProyeccionMotivo, EstadoProyeccionMotivoDAO>(
  DAO_NAME,
  optionsToUse,
);

// hooks
export const useEstadoProyeccionMotivoFetchById = fetchById;
export const useEstadoProyeccionMotivoLibbyCall = libbyCall;
export const useEstadoProyeccionMotivoDAO = libbyDAO;
export const useEstadoProyeccionMotivoLibbyFetch = libbyFetch;

// components/hoc
export const EstadoProyeccionMotivoAutocomplete = entityAutocomplete;
export const EstadoProyeccionMotivoCall = renderPropCall;
export const EstadoProyeccionMotivoById = renderPropFetchById;
export const EstadoProyeccionMotivoSelect = simpleSelect;
export const EstadoProyeccionMotivoAccordionSelector = accordionSelector;
export const EstadoProyeccionMotivoSelectedChips = selectedChips;

// context
// ByIdContext
export const useEstadoProyeccionMotivoByIdContext = useByIdContext;
export const EstadoProyeccionMotivoByIdProvider = ByIdProvider;
export const EstadoProyeccionMotivoByIdContext = ByIdContext;
// GeneralContext
export const useEstadoProyeccionMotivoContext = useGeneralContext;
export const EstadoProyeccionMotivoProvider = GeneralProvider;
export const EstadoProyeccionMotivoContext = GeneralContext;
