export const API_URL = process.env.REACT_APP_API_URL as string;
export const GOOGLE_MAPS_API_KEY = process.env
  .REACT_APP_GOOGLE_MAPS_API_KEY as string;
export const GOOGLE_OAUTH_API_KEY = process.env
  .REACT_APP_GOOGLE_OAUTH_API_KEY as string;
export const GOOGLE_OAUTH_CLIENT_ID = process.env
  .REACT_APP_GOOGLE_OAUTH_CLIENT_ID as string;
export const GOOGLE_MAP_API_KEY = process.env
  .REACT_APP_GOOGLE_MAP_API_KEY as string;
export const DASHBOARD_URL = process.env.REACT_APP_DASHBOARD_URL as string;
// DEV
// export const GOOGLE_OAUTH_API_KEY = 'AIzaSyAYIlh8ZTpi3tRsOhDzzbO6KrmHhKMa2k8';
// export const GOOGLE_OAUTH_CLIENT_ID =
//  '196502985379-obtkk9v4r4994v479hnha8obslds2c84.apps.googleusercontent.com';
// export const GOOGLE_MAP_API_KEY = 'AIzaSyDszJRRSlfzenwffr9lVpagB-SA_q6ma6w';
