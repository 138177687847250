import { Situacion } from 'src/app/models/presentismo_v2/Situacion';
import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class SituacionDAO extends LibbyFetchDAO {
  constructor() {
    super('situacion', 'idSituacion');
  }

  async saveSituacion(situacion: Situacion) {
    return await this.aspect().save(situacion);
  }
}
