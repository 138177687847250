import React from 'react';
import { useMemo } from 'react';

export const useStepOneAlumnosColumn = () => {
  const columns = useMemo(
    () => [
      {
        id: 'nombre',
        label: 'Apellido y nombre',
        width: '5%',
        hideSortIcon: true,
        render: (value: any, row: any) => {
          return (
            <p>
              {row?.alumno?.persona?.apellido}, {row?.alumno?.persona?.nombre}
            </p>
          );
        },
      },
      {
        id: 'documento',
        label: 'Documento',
        width: '5%',
        hideSortIcon: true,
        render: (value: any, row: any) => {
          return <p>{row?.alumno?.persona?.documento}</p>;
        },
      },
      {
        id: 'seccion',
        label: 'Seccion',
        width: '5%',
        hideSortIcon: true,
        render: (value: any, row: any) => {
          return <p>{row?.seccion?.nombreSeccion}</p>;
        },
      },
      {
        id: 'seccionSeleccionada',
        label: 'Cambio de seccion',
        width: '5%',
        hideSortIcon: true,
        render: (value: any, row: any) => {
          return <p>{row?.newSeccion?.nombreSeccion}</p>;
        },
      },
    ],
    [],
  );

  return columns;
};
