import React, { useCallback } from 'react';
import { useTabBarSetValue, useTabBarValue } from '../../../../commons';
import { Presentismo } from '../../AcapPresentismo/Presentismo';

export const PresentismoWrapper = () => {
  const setTab = useTabBarSetValue();
  const tab = useTabBarValue();
  const hableGoto = useCallback(() => {
    setTab(tab.tabIndex + 1);
  }, [tab, setTab]);
  return (
    <Presentismo
      isInsideSchool={true}
      row={null}
      goToDevoluciones={hableGoto}
    />
  );
};
