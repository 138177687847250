import React from 'react';
import { useField } from 'react-final-form-hooks';
import { TextField } from 'src/app/components/inputs/Input/TextField';

export const TextFieldFF = ({ name, form, ...props }: any) => {
  const field = useField(name, form);

  return (
    <TextField
      disabled={props.isDisabled ?? false}
      {...props}
      {...field.input}
    />
  );
};
