import { makeBusinessEntity } from '../../../lib/libby';
import { DevolucionHabilidad } from '../../models/acap';
import { DevolucionHabilidadesDAO } from 'src/platform/libby/dao';

const DAO_NAME = 'devoluciones_habilidades';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
} = makeBusinessEntity<DevolucionHabilidad, DevolucionHabilidadesDAO>(
  DAO_NAME,
  {},
);

// hooks
export const useDevolucionHabilidadesFetchById = fetchById;
export const useDevolucionHabilidadesLibbyCall = libbyCall;
export const useDevolucionHabilidadesDAO = libbyDAO;
export const useDevolucionHabilidadesLibbyFetch = libbyFetch;
