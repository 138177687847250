export const template = {
  name: 'default',
  template: [
    {
      name: 'rol',
      type: { name: 'number' },
    },
    {
      name: 'dependenciaFuncional',
      type: { name: 'number' },
      optional: true,
    },
    {
      name: 'distrito',
      type: { name: 'array' },
      optional: true,
    },
    {
      name: 'escalafon',
      type: { name: 'string' },
      optional: true,
    },
    {
      name: 'region',
      type: { name: 'string' },
      optional: true,
    },
    {
      name: 'nivel',
      type: { name: 'number' },
      optional: true,
    },
    {
      name: 'oldDependencias',
      type: { name: 'array' },
      optional: true,
    },
  ],
};
