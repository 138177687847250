import React from 'react';
import { FieldValidator, FormApi } from 'final-form';
import { getErrorAndHelperTextNoTranslation } from '../../../utils/materialHelpers';
import {
  TextField as MaterialTextField,
  TextFieldProps as MaterialTextFieldProps,
} from '@material-ui/core';
import { useField } from 'react-final-form-hooks';
import { getHelperTextNoTranslation } from '../hooks/makeGetHelperText';

export type TextFieldV2Props = MaterialTextFieldProps;

export const TextFieldV2 = ({
  fullWidth = true,
  variant = 'outlined',
  size = 'small',
  type = 'text',
  inputProps = {},
  InputLabelProps = {},
  ...rest
}: TextFieldV2Props) => (
  <MaterialTextField
    fullWidth={fullWidth}
    variant={variant}
    size={size}
    type={type}
    inputProps={inputProps}
    InputLabelProps={InputLabelProps}
    {...rest}
  />
);

export type FormInputV2Props<T = any> = {
  name: string;
  form: FormApi<T, Partial<T>>;
  validator?: FieldValidator<any>;
} & TextFieldV2Props;

export function FormInputV2<T = any>({
  name,
  form,
  variant = 'outlined',
  validator,
  ...rest
}: FormInputV2Props<T>) {
  const field = useField(name, form, validator);
  return (
    <TextFieldV2
      variant={variant}
      {...field.input}
      {...getHelperTextNoTranslation(field.meta)}
      {...rest}
    />
  );
}
