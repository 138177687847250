import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TextField, Typography, InputAdornment } from '@material-ui/core/';

export interface MainInputTypesProps {
  value: string | number;
  handleChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  handleBlur?: (value: React.FocusEvent<HTMLInputElement>) => void;
  placeholder: string;
  label?: string;
  icon?: JSX.Element;
  type?: string;
  fullWidth?: boolean;
  rows?: number | string;
  multiline?: boolean;
  required?: boolean;
  disabled?: boolean;
  error?: boolean;
  helperText?: string;
  customStyle?: Object;
  customStyleLabel?: Object;
  name?: string;
  requiredField?: boolean;
  maxLength?: number;
  autocomplete?: string;
}

export const MainInput = ({
  label,
  icon,
  fullWidth = false,
  rows = 0,
  type,
  multiline = false,
  handleChange,
  handleBlur,
  placeholder,
  required,
  disabled,
  error = false,
  helperText = '',
  value,
  customStyle,
  customStyleLabel,
  name,
  requiredField = false,
  maxLength,
  autocomplete,
  ...props
}: MainInputTypesProps) => {
  const inputStyles = makeStyles(() => ({
    root: {
      ...customStyle,
      padding: '7px',
    },
    label: {
      ...customStyleLabel,
    },
  }));

  const classes = inputStyles();
  return (
    <article>
      {label && (
        <Typography align="left" variant="subtitle2" className={classes.label}>
          {label}
          {requiredField && <span style={{ color: '#ff0000' }}> *</span>}
        </Typography>
      )}
      <TextField
        name={name}
        InputProps={{
          disableUnderline: true,
          startAdornment: icon && (
            <InputAdornment position="start">{icon}</InputAdornment>
          ),
          className: classes.root,
          inputProps: {
            min: 0,
            maxLength: maxLength,
          },
        }}
        error={error}
        minRows={rows}
        type={type}
        fullWidth={fullWidth}
        multiline={multiline}
        helperText={error && helperText}
        placeholder={placeholder}
        required={required}
        autoComplete={autocomplete}
        disabled={disabled}
        onChange={(_value: React.ChangeEvent<HTMLInputElement>) =>
          handleChange(_value)
        }
        onBlur={(_value: React.FocusEvent<HTMLInputElement>) =>
          !!handleBlur && handleBlur(_value)
        }
        value={value}
        {...props}
      />
    </article>
  );
};
