import { useEffect } from 'react';
import { useLegajoAlumnoLibbyCall } from 'src/app/business';
import { LegajoAlumno } from 'src/app/models';
import { useLegajoLayout } from '..';

export const useLegajoAlumnoFetch = () => {
  const { params } = useLegajoLayout();

  const {
    data: legajoAlumno,
    recall: recallLegajoAlumno,
    working,
  } = useLegajoAlumnoLibbyCall<LegajoAlumno[]>({
    methodName: 'getByAlumnoId',
    params,
    noAutoCall: true,
  });

  useEffect(() => {
    recallLegajoAlumno(params);
  }, [recallLegajoAlumno, params]);

  return {
    data: {
      legajoAlumno: legajoAlumno?.[0],
    },
    recall: {
      recallLegajoAlumno: () => recallLegajoAlumno(params),
    },
    working,
  };
};
