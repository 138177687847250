import React, { useMemo } from 'react';
import { CicloLectivo, MatriculacionSeccion } from 'src/app/models';
import { InfoTableColumn } from 'src/commons';
import { CapacidadSeccionIcon } from '../components/CapacidadSeccionIcon';
import { SeccionActions } from '../components/SeccionActions';

interface SeccionesColumnsProps {
  isEnable: boolean;
  currentCicloLectivo: CicloLectivo | null;
  handleView: (item: MatriculacionSeccion) => void;
  handleEdit: (item: MatriculacionSeccion) => void;
  handleDelete: (item: MatriculacionSeccion) => void;
}

export const useSeccionesColumns = ({
  currentCicloLectivo,
  isEnable,
  handleView,
  handleEdit,
  handleDelete,
}: SeccionesColumnsProps) => {
  const columns = useMemo<InfoTableColumn<MatriculacionSeccion>[]>(() => {
    return [
      {
        id: 'cicloLectivo.anio',
        label: 'Ciclo Lectivo',
        width: '10%',
        orderById: 'cicloLectivo.anio',
      },
      {
        id: 'anio.descripcionAnio',
        label: 'Año',
        width: '5%',
        orderById: 'anio.descripcionAnio',
      },
      {
        id: 'turno.descripcionTurno',
        label: 'Turno',
        width: '5%',
        orderById: 'turno.descripcionTurno',
      },
      {
        id: 'jornada',
        label: 'Jornada',
        width: '5%',
        orderById: 'jornada',
      },
      {
        id: 'division',
        label: 'División',
        width: '5%',
        orderById: 'division',
      },
      {
        id: 'capacidadMaxima',
        label: 'Capacidad',
        width: '3%',
        orderById: 'capacidadMaxima',
        render: (cm, row) => {
          return (
            <>
              <CapacidadSeccionIcon
                capacidadOcupada={row.matCount || 0}
                capacidadMaxima={row.capacidadMaxima}
              />
              {`${row.matCount}/${cm}`}
            </>
          );
        },
      },
      {
        id: 'planEstudioNivel.planEstudio.descripcionPlanEstudio',
        label: 'Plan de estudio',
        width: '15%',
        orderById: 'planEstudioNivel.planEstudio.descripcionPlanEstudio',
      },
      {
        id: 'icon',
        label: '',
        width: '5%',
        hideSortIcon: true,
        noSort: true,
        style: { textAlign: 'right' },
        render: (_, row) => {
          return (
            <SeccionActions
              isEnabled={isEnable}
              seccion={row}
              currentCicloLectivo={currentCicloLectivo!}
              handleView={() => {
                handleView(row);
              }}
              handleEdit={() => {
                handleEdit(row);
              }}
              handleDelete={() => {
                handleDelete(row);
              }}
            />
          );
        },
      },
    ];
  }, [currentCicloLectivo, handleDelete, handleEdit, handleView, isEnable]);

  return { columns };
};
