import React, { useCallback, useMemo } from 'react';
import { Typography, Grid } from '@material-ui/core';
import { MainButton } from 'src/commons/components';
import { ButtonTypesProps } from 'src/commons/components/Footer';
import { useFormContext, FormGenericControl } from 'src/lib/templates';
import { RolSelect, DependenciaFuncionalSelect } from 'src/app/business';
import { RolFormFilters } from './RolFormFilters';
import { DependenciaFuncional } from 'src/app/models';
import { InitialValuesDefinition } from '../AddRolForm';
import { ROL } from 'src/commons';

const SPACING = 4;

export const AddRolFormItem = () => {
  const { form, handleSubmit, submitting, values, errors } =
    useFormContext<InitialValuesDefinition>();
  const {
    rol,
    dependenciaFuncional: df,
    distrito,
    escalafon,
    region,
    nivel,
    oldDependencias,
  } = values;

  const enable = useMemo(() => {
    if (rol === 12) return false;
    if (
      rol === 11 &&
      (df === 10 || df === 20 || df === 31) &&
      distrito?.length
    ) {
      // SUPERVISOR INICIAL - PRIMARIA - MEDIA
      return !rol && !df && !distrito.length;
    }
    if ((rol === 11 && df === 70) || (rol === 10 && df)) {
      // SUPERVISOR ARTISTICA o DIRECCION AREA
      return !rol && !df;
    }
    if (rol === 11 && df === 50 && escalafon) {
      // SUPERVISOR ESPECIAL
      return !rol && !df && !escalafon;
    }
    if (rol === 11 && df === 32 && region) {
      // SUPERVISOR TECNICA
      return !rol && !df && !region;
    }
    if (rol === 11 && df === 41 && nivel) {
      // SUPERVISOR NORMAL
      return !rol && !df && !nivel;
    }
    return true;
  }, [rol, df, distrito, escalafon, region, nivel]);

  const submitProps: ButtonTypesProps = {
    title: 'Guardar',
    handleOnClick: handleSubmit,
    disabled: enable || submitting || Object.keys(errors as any).length > 0,
  };

  const transformOptions = useCallback(
    (options: DependenciaFuncional[]) => {
      if (rol === ROL.DIRECCION_AREA) {
        return options.map((dep) => {
          const exist = oldDependencias.includes(dep.idDependenciaFuncional);
          return { ...dep, needToDisable: exist };
        });
      } else {
        return options;
      }
    },
    [oldDependencias, rol],
  );

  return (
    <Grid
      container
      spacing={SPACING}
      direction="column"
      style={{ paddingRight: '5%', paddingLeft: '5%', flex: 1 }}
    >
      <Grid item style={{ paddingLeft: 0, marginBottom: 20, marginTop: -45 }}>
        <Typography variant="h3">Alta de rol</Typography>
      </Grid>
      <Grid
        container
        item
        sm={12}
        spacing={SPACING}
        style={{ paddingLeft: 0, paddingRight: 0 }}
      >
        <Grid item sm={3}>
          <FormGenericControl
            name="rol"
            form={form}
            component={RolSelect}
            componentProps={{
              title: 'Rol',
              placeholder: 'Seleccioná rol',
              filter: {
                rol: [
                  { path: 'idRolUsuario', value: 12 },
                  { path: 'idRolUsuario', value: 11 },
                  { path: 'idRolUsuario', value: 10 },
                ],
              },
            }}
          />
        </Grid>
        {rol && rol !== 12 && (
          <Grid item sm={5}>
            <FormGenericControl
              name="dependenciaFuncional"
              form={form}
              component={DependenciaFuncionalSelect}
              componentProps={{
                title: 'Dependencia Funcional',
                placeholder: 'Seleccioná Dependencia Funcional',
                filter: {
                  df: [
                    {
                      path: 'idDependenciaFuncional',
                      method: 'notEquals',
                      value: 60,
                    },
                  ],
                  df2: [
                    {
                      path: 'idDependenciaFuncional',
                      method: 'notEquals',
                      value: 1,
                    },
                  ],
                },
                transformOptions,
              }}
            />
          </Grid>
        )}
        {rol === 11 && df && (
          <RolFormFilters dependenciaFuncional={df} form={form} />
        )}
      </Grid>
      <Grid container item sm={12} justifyContent="flex-end">
        <Grid item>
          <MainButton {...submitProps} />
        </Grid>
      </Grid>
    </Grid>
  );
};
