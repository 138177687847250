import { LibbyFetchDAO } from './LibbyFetchDAO';

export class LocalizacionNivelDAO extends LibbyFetchDAO {
  constructor() {
    super('localizacion_nivel', 'idLocalizcionNivel');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByLocalization(localizacionId: string) {
    return this.query().equals('localizacion', localizacionId).run();
  }
}
