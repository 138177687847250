import MobileStepper from '@material-ui/core/MobileStepper';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import moment from 'moment';
import React, { useCallback, useState, useEffect } from 'react';
import { AnyObject, LibbyObject } from 'src/commons';
import { Footer, Loading } from 'src/commons/components';
import { statusPassesRequest } from 'src/commons/const/';
import { Grid, Typography } from '@material-ui/core';
import { ModalidadType, SolicitudPases } from 'src/app/models';
import { useSolicitudPasesDAO } from 'src/app/business';
import customFormDialog from 'src/commons/services/customFormDialog';
import { ConfigurePass } from './components/ConfigurePass';
import { SelectStudentParent } from './components/SelectStudent';

const { PENDING } = statusPassesRequest;
interface PassRequestModalPropsTypes {
  idLocation: number;
  libby: LibbyObject;
  // eslint-disable-next-line
  reFetch: Function;
  nivel: number;
  modalidad?: number;
}

const PassRequestModalRaw = ({
  libby,
  reFetch,
  idLocation,
  nivel,
  modalidad,
}: PassRequestModalPropsTypes) => {
  const [formValues, setFormValues] = useState({
    turno: '',
    comment: '',
  });
  const [havePase, setHavePase] = useState(true);
  const [loading, setLoading] = useState(false);
  const solicitudPaseDAO = useSolicitudPasesDAO();
  const [submitting, setSubmitting] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [selectStudent, setSelectStudent] = useState<AnyObject | null>(null);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    const dataForm: AnyObject = {
      ...selectStudent,
      ...formValues,
    };

    try {
      const {
        idTurno,
        comment,
        localizacion: { idLocalizacion: idOriginLocation },
        alumno: { idAlumno },
        ciclo,
        nivel: { idNivel },
      } = dataForm;
      const solicitudPase = {
        ciclo: ciclo?.idCiclo,
        nivel: idNivel,
        turno: idTurno,
        estadoSolicitudPases: PENDING,
        localizacionOrigen: idOriginLocation,
        localizacionDestino: idLocation,
        alumno: idAlumno,
        fechaPedida: moment().toISOString(),
        fechaPase: moment().toISOString(),
        observacion: comment,
      };
      await libby.solicitud_pases.save(solicitudPase);
      customFormDialog.handleCancel();
      reFetch();
      setSubmitting(false);
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libby, reFetch, selectStudent, formValues]);

  const buttonConfig: any = {
    0: [
      {
        title: 'Cancel',
        handleOnClick: () => customFormDialog.handleCancel(),
        type: 'secondary',
        size: 'small',
      },
      {
        title: 'Siguiente',
        handleOnClick: () => handleNext(),
        size: 'small',
        disabled: havePase || loading,
      },
    ],
    1: [
      {
        title: 'Cancel',
        handleOnClick: () => customFormDialog.handleCancel(),
        type: 'secondary',
        size: 'small',
      },
      {
        title: 'Atrás',
        handleOnClick: handleBack,
        type: 'secondary',
        size: 'small',
        customStyle: { border: '1px solid' },
      },
      {
        title: 'Guardar',
        handleOnClick: () => handleSubmit(),
        size: 'small',
        disabled: !formValues.turno || submitting,
      },
    ],
  };

  const handleChange = (e: React.FormEvent<EventTarget>): void => {
    const target = e.target as HTMLInputElement;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  useEffect(() => {
    (async () => {
      if (selectStudent) {
        setLoading(true);
        const filter = {
          alumno: [{ path: 'alumno', value: selectStudent?.alumno?.idAlumno }],
          estado: [{ path: 'estadoSolicitudPases', value: 3 }],
        };
        const solicitudBy: SolicitudPases[] =
          await solicitudPaseDAO.getByFilter(filter);
        if (solicitudBy.length) {
          setHavePase(true);
        } else {
          setHavePase(false);
        }
        setLoading(false);
      } else {
        setHavePase(true);
      }
    })();
  }, [selectStudent, solicitudPaseDAO]);

  const passManagement1 = (
    <SelectStudentParent
      selectStudent={selectStudent}
      location={idLocation}
      setSelectStudent={setSelectStudent}
      level={modalidad === ModalidadType.Adultos && nivel}
    />
  );
  const passManagement2 = (
    <ConfigurePass
      studentSelect={selectStudent}
      formValues={formValues}
      handleChange={handleChange}
    />
  );

  const tutorialSteps = [
    { render: passManagement1 },
    { render: passManagement2 },
  ];
  const maxSteps = tutorialSteps.length;

  return (
    <>
      <div style={{ width: '100%', paddingBottom: '15px' }}>
        <div>
          {tutorialSteps[activeStep].render}
          <MobileStepper
            steps={maxSteps}
            activeStep={activeStep}
            backButton=""
            nextButton=""
          />
        </div>
      </div>
      {selectStudent && havePase && (
        <Grid
          container
          style={{
            justifyContent: 'flex-end',
            marginBottom: '20px',
            paddingRight: '20px',
          }}
        >
          {loading ? (
            <Loading />
          ) : (
            <Typography color="error">
              El alumno seleccionado ya tiene un pase iniciado.
            </Typography>
          )}
        </Grid>
      )}
      <Footer buttonConfig={buttonConfig[activeStep]} spacing={1} />
    </>
  );
};

export const PassRequestModal = DatabaseConnector(PassRequestModalRaw)(
  'solicitud_pases',
  'alumno',
);
