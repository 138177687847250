import { Localizacion } from 'src/app/models';
import { LibbyFetchDAO } from './LibbyFetchDAO';
export class LocalizacionDAO extends LibbyFetchDAO {
  constructor() {
    super('localizacion', 'idLocalizacion');
  }

  async getDetails(localizacionId?: number): Promise<Localizacion[]> {
    try {
      if (localizacionId) {
        return await this.fetchById(localizacionId);
      } else {
        return await this.query().limit(0, 1).run();
      }
    } catch (error) {
      console.error(error);
      throw new Error('Ocurrio un error al buscar detalles de la localizacion');
    }
  }
}
