import React, { useEffect, useState } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { LibbyObject } from 'src/commons';
import { useLibbyCall } from 'src/commons/hooks';
import { SelectStudent } from './SelectStudent';

const SelectStudentParentRaw = ({
  libby,
  setSelectStudent,
  selectStudent,
  location,
  nivel,
  cicloLectivo,
}: LibbyObject) => {
  const [filter, setFilter] = useState<any>({
    localizacion: [
      {
        path: 'seccion.localizacion.idLocalizacion',
        value: location,
        method: 'notEquals',
      },
    ],
    nivel: [{ path: 'seccion.nivel', value: nivel }],
  });

  return (
    !!filter && (
      <SelectStudent
        filterById={filter}
        selectStudent={selectStudent}
        setSelectStudent={setSelectStudent}
        cicloLectivo={cicloLectivo}
      />
    )
  );
};

export const SelectStudentParent = DatabaseConnector(SelectStudentParentRaw)(
  'solicitud_pases',
);
