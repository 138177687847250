import { routes } from './routes';
import { MenuItem } from '../../../../commons/const/ui/menu';

export const legajoAlumnoMenu: MenuItem = {
  key: 'legajoAlumno',
  basePath: '/legajo-alumno/:id',
  enabled: true,
  icon: 'List',
  text: 'LegajoAlumno',
  children: routes,
};
