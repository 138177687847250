import React, { useCallback, useState } from 'react';
import moment from 'moment';
import { FormContextProvider } from 'src/lib/templates/context';
import { useRolesContext } from 'src/context/RolesContext';
import { useComentarioDAO } from 'src/app/business';
import { AnyObject } from 'src/commons/types';
import customFormDialog from 'src/commons/services/customFormDialog';
import { ComentarioItemForm } from './ComentarioItemForm';
import { nuevaPublicacionTemplate } from './template';
import { Publicacion } from 'src/app/models';

export interface InitialValueDefinition {
  descripcion: string;
}

export interface NuevaPublicacionFormProps {
  initialValues: InitialValueDefinition;
  idPublicacion: number;
  raw: Publicacion;
  reFetch?: () => void;
}

export const NuevoComentarioForm = ({
  initialValues,
  raw,
  reFetch,
  idPublicacion,
}: NuevaPublicacionFormProps) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const { userInfo } = useRolesContext();

  const comentarioDAO = useComentarioDAO();
  const onSubmit = useCallback(
    async (values: InitialValueDefinition) => {
      try {
        if (raw) {
          const updatedItem: AnyObject = {
            ...raw,
            descripcion: values.descripcion,
          };
          await comentarioDAO.save(updatedItem);
          reFetch && reFetch();
          await customFormDialog.handleCancel();
        } else {
          const newItem: AnyObject = {
            descripcion: values.descripcion,
            cuentas: userInfo.id,
            publicacion: idPublicacion,
            fechaCreacion: moment().toISOString(),
          };
          await comentarioDAO.save(newItem);
          reFetch && reFetch();
          setSubmitted(true);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [comentarioDAO, userInfo, raw, reFetch, idPublicacion],
  );

  return (
    <FormContextProvider
      initialValues={initialValues}
      onSubmit={onSubmit}
      template={nuevaPublicacionTemplate}
    >
      <ComentarioItemForm
        raw={raw}
        submitted={submitted}
        setSubmitted={setSubmitted}
      />
    </FormContextProvider>
  );
};
