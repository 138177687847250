import { AniosNivelInicial, ModalOption, SelectOption } from '../informesTypes';

export const AUTHORIZED_TO_OPEN = {
  '1': 'director',
  '5': 'equipoConduccion',
};

export const PERIODO = {
  SEGUNDO_BIMESTRE: 6,
  CUARTO_BIMESTRE: 11,
};

export const PPI: SelectOption[] = [
  { descripcion: 'No' },
  { descripcion: 'Sí' },
];

export const EXCEPTUADO: SelectOption[] = [
  { descripcion: 'Sí' },
  { descripcion: 'No' },
];

export const VINCULO_PEDAGOGICO: SelectOption[] = [
  { descripcion: 'Continuamente' },
  { descripcion: 'A veces' },
  { descripcion: 'Muy pocas veces' },
  { descripcion: 'Nunca' },
];

export const MODALIDAD_ENCUENTRO_2021_1: ModalOption[] = [
  'Presencialmente',
  'Por videollamada',
  'Por whatsapp, por videollamada',
  'A través del cuaderno u otros materiales impresos',
  'Otros (Indicar en observaciones)',
];
MODALIDAD_ENCUENTRO_2021_1.sort((a, b) =>
  a.toLowerCase().localeCompare(b.toLowerCase()),
);

export const MODALIDAD_ENCUENTRO: ModalOption[] = [
  'Presencialmente',
  'Por videollamada',
  'Por Whatsapp',
  'A través del cuaderno u otros materiales impresos',
  'Otros (Indicar en observaciones)',
];
MODALIDAD_ENCUENTRO.sort((a, b) =>
  a.toLowerCase().localeCompare(b.toLowerCase()),
);

export const ANIOS_NIVEL_INICIAL: { [key in AniosNivelInicial]: number } = {
  LACTANTES: 10,
  '1_ANIO/DEAMBULANTES': 11,
  '2_ANIOS': 12,
  '3_ANIOS': 13,
  '4_ANIOS': 14,
  '5_ANIOS': 15,
  '1_Y_2_ANIOS': 16,
  '2_Y_3_ANIOS': 17,
  '3_Y_4_ANIOS': 18,
  '4_Y_5_ANIOS': 19,
  MULTIEDAD: 20,
  LACTANTES_Y_1_ANIO: 30,
};
