import React from 'react';
import { Grid } from '@material-ui/core';
import { SimpleSelect } from 'src/commons';
import { IndicadorTipoSelect } from '../../informesTypes';
import { InformescCustom } from 'src/app/models';

export const InputIndicadorTipoSelect = ({
  isInformesClosed,
  indicador,
  row,
  handleDataChange,
}: {
  isInformesClosed: boolean;
  indicador: IndicadorTipoSelect;
  row: InformescCustom;
  handleDataChange: (
    data: { name: string; value: string },
    row: InformescCustom,
  ) => void;
}) => {
  const handleRowDataChange = (
    data: { name: string; value: string },
    row: InformescCustom,
  ) => {
    handleDataChange(data, row);
  };
  return (
    <Grid style={{ minWidth: 150 }}>
      <div>
        <SimpleSelect
          placeholder="Seleccione opción"
          labelKey={indicador.labelKey}
          valueKey={indicador.valueKey}
          content={indicador.selectOptions}
          value={row.nota?.data?.[indicador.id]}
          handleChange={(event) => {
            handleRowDataChange(event.target, row);
          }}
          name={indicador.id}
          disabled={isInformesClosed}
        />
      </div>
    </Grid>
  );
};
