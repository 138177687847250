import {
  Alumno,
  AlumnoMovimiento,
  DatosAtencion,
  LegajoAlumno,
  ModalidadType,
  Persona,
} from 'src/app/models';
import {
  buildAptoFisico,
  buildFichaDocumentacion,
  buildSaludAcompanantes,
  buildSaludAtencion,
} from './utils';
import moment from 'moment';

interface InitialLegajoAlumnoFormValues {
  alumno?: Alumno;
  legajoAlumno?: LegajoAlumno;
  alumnoMovimiento?: AlumnoMovimiento;
  matriculaPersonaExterna?: any;
  datosInstitucion?: any;
  responsableDatosLaborales?: any;
  responsableDatosDomicilio?: any;
  responsableData?: any;
  modalidad: ModalidadType;
}

interface LegajoAlumnoFormValues {
  formValues: any;
  alumno: Alumno | undefined;
  datosInstitucion?: any;
  legajoAlumno: LegajoAlumno | undefined;
  matriculaPersonaExterna?: any;
  responsableDatosLaborales?: any;
  responsableDatosDomicilio?: any;
  responsableData?: any;
  modalidad: ModalidadType;
}

interface DocumentacionDatosPrincipales {
  descripcion: any;
  fechaEmision: any;
  fechaEntrega: any;
  tipoDireccion: any;
  tipoDocumento: any;
  requiereActualizacion: any;
  url?: string;
}

interface DatosAcompanante {
  apellido: string;
  nombres: string;
  telefonoAcompanante: string;
  tipoAcompanante: string;
  ocupaVacante: Array<{ label: string; value: boolean }>;
}

const saludHelpers = {
  buildDatosAcompanantes: (datosAcompanantes: DatosAcompanante[]) => {
    return datosAcompanantes?.length > 0
      ? datosAcompanantes.map((acom) => ({
          nombres: acom.nombres,
          apellido: acom.apellido,
          telefonoAcompanante: acom.telefonoAcompanante,
          tipoAcompanante: acom.tipoAcompanante,
          ocupaVacante: acom.ocupaVacante,
        }))
      : [
          {
            nombres: '',
            apellido: '',
            telefonoAcompanante: '',
            tipoAcompanante: '',
            ocupaVacante: false,
          },
        ];
  },
  buildDatosAtencion: (datosAtencion: DatosAtencion[]) => {
    return datosAtencion?.length > 0
      ? datosAtencion.map((aten) => ({
          atencionDesde: aten.atencionDesde,
          atencionHasta: aten.atencionHasta,
          establecimiento: aten.establecimiento,
        }))
      : [
          {
            atencionDesde: undefined,
            atencionHasta: undefined,
            establecimiento: '',
          },
        ];
  },
};

const buildDocumentacionPrincipales = (legajoAlumno?: LegajoAlumno) => {
  return legajoAlumno && Object.keys(legajoAlumno.fichaDocumentacion).length > 0
    ? legajoAlumno?.fichaDocumentacion?.datosPrincipales.map(
        (doc: DocumentacionDatosPrincipales) => ({
          descripcion: doc.descripcion,
          fechaEmision: doc.fechaEmision,
          fechaEntrega: doc.fechaEntrega,
          tipoDocumento: doc.tipoDocumento,
          requiereActualizacion: doc.requiereActualizacion,
          url: doc.url,
        }),
      )
    : [
        {
          descripcion: undefined,
          fechaEmision: undefined,
          fechaEntrega: undefined,
          tipoDocumento: undefined,
          requiereActualizacion: false,
          url: '',
        },
      ];
};

// TODO: Manejar "null" como "undefined", asi el formulario dice "es requerido"
export const buildInitialLegajoAlumnoFormValues = ({
  alumno,
  legajoAlumno,
  alumnoMovimiento,
  matriculaPersonaExterna,
  datosInstitucion,
  responsableData,
  responsableDatosLaborales,
  responsableDatosDomicilio,
  modalidad,
}: InitialLegajoAlumnoFormValues) => {
  const initialFormValuesModel = {
    datosPersonales: {
      datosPrincipales: {
        nombres: alumno?.persona.nombre,
        apellidos: alumno?.persona.apellido,
        genero: alumno?.persona.genero.idGenero,
        fechaNacimiento: alumno?.persona.fechaNacimiento,
        paisNacimiento: alumno?.persona.paisNacimiento.idPais,
        documento: alumno?.persona.documento || '',
        tipoDocumento: alumno?.persona?.tipoDocumento?.idTipoDocumento,
        pbIndigenaPertenece: alumno?.persona?.pbIndigenaPertenece || false,
        pbIndigenaHabla: alumno?.persona?.pbIndigenaHabla ?? '',
        obsIndigena: alumno?.persona?.obsIndigena ?? '',
        estadoCivil: alumno?.persona?.estadoCivil ?? '',
        email: legajoAlumno?.usuario,
        password: legajoAlumno?.password,
        legajo: legajoAlumno?.fichaDatosPersonales?.datosPrincipales?.legajo,
        telefono1:
          legajoAlumno?.fichaDatosPersonales?.datosPrincipales?.telefono1,
        telefono2:
          legajoAlumno?.fichaDatosPersonales?.datosPrincipales?.telefono2,
        provinciaNacimiento:
          legajoAlumno?.fichaDatosPersonales?.datosPrincipales
            ?.provinciaNacimiento, // FIXME: Por ahora se guarda en legajoAlumno
      },
      datosInstitucion: {
        cicloLectivo:
          alumnoMovimiento?.cicloLectivo?.anio ||
          datosInstitucion?.data?.cicloLectivo?.anio,
        ano:
          alumnoMovimiento?.seccion?.anio?.idAnio ||
          datosInstitucion?.data?.anio?.idAnio ||
          legajoAlumno?.fichaDatosPersonales?.datosInstitucion?.anio,

        cue:
          alumnoMovimiento?.seccion?.localizacion?.cueAnexo ||
          datosInstitucion?.data?.localizacion?.cueAnexo ||
          matriculaPersonaExterna?.localizacion?.cueAnexo,

        nivel:
          alumnoMovimiento?.seccion?.nivel?.descripcionNivel ||
          datosInstitucion?.data?.nivel?.descripcionNivel ||
          matriculaPersonaExterna?.nivel?.descripcionNivel,

        turno:
          alumnoMovimiento?.seccion?.turno?.descripcionTurno ||
          datosInstitucion?.data?.turno?.descripcionTurno,

        nombreEstablecimiento:
          alumnoMovimiento?.seccion?.localizacion?.descripcion ||
          datosInstitucion?.data?.localizacion?.descripcion ||
          matriculaPersonaExterna?.localizacion?.descripcion,

        condicionAnoAnterior:
          legajoAlumno?.fichaDatosPersonales?.datosInstitucion
            ?.condicionAnoAnterior,
        enrolledAt: moment(
          alumnoMovimiento?.enrolledAt || new Date(),
          'YYYY-MM-DD',
        ).format('YYYY-MM-DD'),
      },
    },
    residencia: {
      tipoDireccion: 'datosResidenciaTipoA', // Key necesaria para mantener consistencia en Residencia
      datosResidenciaTipoA: {
        tipoDireccion: 'datosResidenciaTipoA',
        provincia:
          legajoAlumno?.fichaResidencia?.datosResidenciaTipoA?.provincia,
        localidadBarrio:
          legajoAlumno?.fichaResidencia?.datosResidenciaTipoA?.localidadBarrio,
        codigoPostal:
          legajoAlumno?.fichaResidencia?.datosResidenciaTipoA?.codigoPostal,
        calle: legajoAlumno?.fichaResidencia?.datosResidenciaTipoA?.calle,
        altura: legajoAlumno?.fichaResidencia?.datosResidenciaTipoA?.altura,
        pisoDpto: legajoAlumno?.fichaResidencia?.datosResidenciaTipoA?.pisoDpto,
        telefono1:
          legajoAlumno?.fichaResidencia?.datosResidenciaTipoA?.telefono1,
        telefono2:
          legajoAlumno?.fichaResidencia?.datosResidenciaTipoA?.telefono2,
        observaciones:
          legajoAlumno?.fichaResidencia?.datosResidenciaTipoA?.observaciones,
      },
      datosResidenciaTipoB: {
        tipoDireccion: 'datosResidenciaTipoB',
        provincia:
          legajoAlumno?.fichaResidencia?.datosResidenciaTipoB?.provincia,
        localidadBarrio:
          legajoAlumno?.fichaResidencia?.datosResidenciaTipoB?.localidadBarrio,
        codigoPostal:
          legajoAlumno?.fichaResidencia?.datosResidenciaTipoB?.codigoPostal,
        calle: legajoAlumno?.fichaResidencia?.datosResidenciaTipoB?.calle,
        telefono1:
          legajoAlumno?.fichaResidencia?.datosResidenciaTipoB?.telefono1,
        telefono2:
          legajoAlumno?.fichaResidencia?.datosResidenciaTipoB?.telefono2,
        observaciones:
          legajoAlumno?.fichaResidencia?.datosResidenciaTipoB?.observaciones,
        manzana: legajoAlumno?.fichaResidencia?.datosResidenciaTipoB?.manzana,
        villa: legajoAlumno?.fichaResidencia?.datosResidenciaTipoB?.villa,
      },
    },
    responsables: {
      datosPrincipales: {
        apellidos: responsableData?.responsablePersona?.apellido,
        nombres: responsableData?.responsablePersona?.nombre,
        tipoDocumento:
          responsableData?.responsablePersona?.tipoDocumento?.idTipoDocumento,
        documento: responsableData?.responsablePersona?.documento,
        genero: responsableData?.responsablePersona?.genero?.idGenero,
        lazo: responsableData?.responsableVinculo?.tipoVinculo.idTipoVinculo,
        fechaNacimiento: responsableData?.responsablePersona?.fechaNacimiento,
        estadoCivil: responsableData?.responsablePersona?.estadoCivil,
        paisNacimiento:
          responsableData?.responsablePersona?.paisNacimiento?.idPais,
        provinciaNacimiento:
          responsableData?.responsablePersona?.provinciaNacimiento?.idProvincia,
        email: responsableData?.responsableCustomData?.email,
        nivelEducativo: responsableData?.responsableCustomData?.nivelEducativo,
        telefonoEmergencias: responsableData?.responsableCustomData?.celular,
        telefonoOtro: responsableData?.responsableCustomData?.linea,
        tituloEducativo:
          responsableData?.responsableCustomData?.tituloEducativoAlcanzado,
        retiraAlumno: Boolean(
          Number(responsableData?.responsableVinculo?.retiraAlumno),
        ),
        convive: Boolean(responsableData?.responsableVinculo?.convive),
        contactoEmergencia: Boolean(
          responsableData?.responsableVinculo?.contactoEmergencia,
        ),
        diplomatico: Boolean(responsableData?.responsableVinculo?.diplomatico),
      },
      datosDomicilio: {
        tipoDomicilio:
          responsableDatosDomicilio?.tipoDomicilio?.idTipoDomicilio,
        // provincia: responsableDatosDomicilio?.provincia?.idProvincia,
        provincia: responsableDatosDomicilio?.municipio?.idMunicipio,
        calle: responsableDatosDomicilio?.calle,
        altura: responsableDatosDomicilio?.altura,
        piso: responsableDatosDomicilio?.piso,
        depto: responsableDatosDomicilio?.departamento,
        codigoPostal: responsableDatosDomicilio?.codigoPostal,
        telefono1: responsableDatosDomicilio?.telefono1,
        telefono2: responsableDatosDomicilio?.telefono2,
        observaciones: responsableDatosDomicilio?.descripcion,
      },
      datosLaborales: {
        tipoDomicilio:
          responsableDatosLaborales?.tipoDomicilio?.idTipoDomicilio,
        ocupacion: responsableDatosLaborales?.ocupacion,
        establecimientoEmpresa: responsableDatosLaborales?.empresa,
        desdeHastaHorasLaborales: responsableDatosLaborales?.desdehastahoras,
        horasPorDia: responsableDatosLaborales?.horasPorDia,
        cantidadDiasSemana:
          responsableDatosLaborales?.cantDiasSemana?.idCantDiasSemana,
        provincia: responsableDatosLaborales?.provincia?.idProvincia,
        calle: responsableDatosLaborales?.calle,
        altura: responsableDatosLaborales?.altura,
        depto: responsableDatosLaborales?.depto,
        codigoPostal: responsableDatosLaborales?.codigoPostal,
        observaciones: responsableDatosLaborales?.observaciones,
        piso: responsableDatosLaborales?.piso,
      },
      busqueda: {
        buscar: '',
      },
    },
    documentacion: {
      datosPrincipales: buildDocumentacionPrincipales(legajoAlumno),
    },
    historialAcademico: {
      datosPrincipales:
        legajoAlumno?.fichaHistorialAcademico?.datosPrincipales || [],
      datosToDelete: [],
      informeEpa: legajoAlumno?.fichaHistorialAcademico?.informeEpa,
    },
    ppiPps: {},
    salud: {
      datosPrincipales: {
        alergias: legajoAlumno?.fichaSalud?.datosPrincipales?.alergias,
        sabeNadar: legajoAlumno?.fichaSalud?.datosPrincipales?.sabeNadar,
        necesidades: legajoAlumno?.fichaSalud?.datosPrincipales?.necesidades,
        medicamentos: legajoAlumno?.fichaSalud?.datosPrincipales?.medicamentos,
        observaciones:
          legajoAlumno?.fichaSalud?.datosPrincipales?.observaciones,
        observaciones2:
          legajoAlumno?.fichaSalud?.datosPrincipales?.observaciones2,
        grupoSanguineo:
          legajoAlumno?.fichaSalud?.datosPrincipales?.grupoSanguineo, // TODO: Esto va a grupoSanguineo
        tratamientoMedico:
          legajoAlumno?.fichaSalud?.datosPrincipales?.tratamientoMedico,
        seguimientoDocente:
          legajoAlumno?.fichaSalud?.datosPrincipales?.seguimientoDocente,
        accionesEmergencia:
          legajoAlumno?.fichaSalud?.datosPrincipales?.accionesEmergencia,
        calendarioVacunacion:
          legajoAlumno?.fichaSalud?.datosPrincipales?.calendarioVacunacion,
        enfermedadesCronicas:
          legajoAlumno?.fichaSalud?.datosPrincipales?.enfermedadesCronicas,
        actividadesRestringidas:
          legajoAlumno?.fichaSalud?.datosPrincipales?.actividadesRestringidas,
      },
      datosAptoFisico: {
        motivo: legajoAlumno?.fichaSalud?.datosAptoFisico?.motivo,
        aptoDesde: legajoAlumno?.fichaSalud?.datosAptoFisico?.aptoDesde,
        aptoHasta: legajoAlumno?.fichaSalud?.datosAptoFisico?.aptoHasta,
        estaEximido: legajoAlumno?.fichaSalud?.datosAptoFisico?.estaEximido,
        url: legajoAlumno?.fichaSalud?.datosAptoFisico?.url || '',
        adjuntoNombre:
          legajoAlumno?.fichaSalud?.datosAptoFisico?.metadata?.name || '',
      },
      datosCobertura: {
        numeroSocio: legajoAlumno?.fichaSalud?.datosCobertura?.numeroSocio,
        planCobertura: legajoAlumno?.fichaSalud?.datosCobertura?.planCobertura,
        tipoDeCobertura:
          legajoAlumno?.fichaSalud?.datosCobertura?.tipoDeCobertura,
        telefonoCobertura:
          legajoAlumno?.fichaSalud?.datosCobertura?.telefonoCobertura,
      },
      datosAtencion: saludHelpers.buildDatosAtencion(
        legajoAlumno?.fichaSalud?.datosAtencion,
      ),
      datosLose: {
        loseDesde: legajoAlumno?.fichaSalud?.datosLose?.loseDesde,
        loseHasta: legajoAlumno?.fichaSalud?.datosLose?.loseHasta,
      },
      datosAcompanante: saludHelpers.buildDatosAcompanantes(
        legajoAlumno?.fichaSalud.datosAcompanante,
      ),
    },
    situacionLegal: {
      datosPrincipales: {
        nombreCentro:
          legajoAlumno?.fichaSituacionLegal?.datosPrincipales?.nombreCentro,
        tipoDocumento:
          legajoAlumno?.fichaSituacionLegal?.datosPrincipales?.tipoDocumento,
        fechaVigencia:
          legajoAlumno?.fichaSituacionLegal?.datosPrincipales?.fechaVigencia,
        tipoSituacion:
          legajoAlumno?.fichaSituacionLegal?.datosPrincipales?.tipoSituacion,
        descripcionAdicional1:
          legajoAlumno?.fichaSituacionLegal?.datosPrincipales
            ?.descripcionAdicional1,
      },
      datosAdicionales: {
        descripcionAdicional2:
          legajoAlumno?.fichaSituacionLegal?.datosAdicionales
            ?.descripcionAdicional2,
      },
    },
  };
  let initialFormValues;

  switch (modalidad) {
    case ModalidadType.EspecialByC:
      // replacing only especific prop
      delete initialFormValuesModel.datosPersonales.datosInstitucion.ano;
      initialFormValues = {
        ...initialFormValuesModel,
        datosPersonales: {
          datosPrincipales:
            initialFormValuesModel.datosPersonales.datosPrincipales,
          datosInstitucion: {
            ...initialFormValuesModel.datosPersonales.datosInstitucion,
            ciclo:
              legajoAlumno?.fichaDatosPersonales?.datosInstitucion?.ciclo ||
              alumnoMovimiento?.seccion?.ciclo?.idCiclo ||
              datosInstitucion?.data?.ciclo?.idCiclo,
          },
        },
      };
      break;
    case ModalidadType.Adultos:
      delete initialFormValuesModel.datosPersonales.datosInstitucion.ano;
      initialFormValues = {
        ...initialFormValuesModel,
        datosPersonales: {
          datosPrincipales:
            initialFormValuesModel.datosPersonales.datosPrincipales,
          datosInstitucion: {
            ...initialFormValuesModel.datosPersonales.datosInstitucion,
            cicloAdultos:
              legajoAlumno?.fichaDatosPersonales?.datosInstitucion
                ?.cicloAdultos || datosInstitucion?.data?.ciclo?.idCicloAdultos,
          },
        },
      };
      break;
    case ModalidadType.EspecialAyDomiciliariaC:
      initialFormValues = initialFormValuesModel;
      break;
    default:
      initialFormValues = initialFormValuesModel;
      break;
  }
  return initialFormValues;
};

export const buildLegajoAlumnoFormValues = async ({
  alumno,
  datosInstitucion,
  legajoAlumno,
  formValues,
  responsableData,
  responsableDatosDomicilio,
  responsableDatosLaborales,
  modalidad,
}: LegajoAlumnoFormValues) => {
  const {
    datosPersonales,
    residencia,
    responsables,
    documentacion,
    historialAcademico,
    salud,
    situacionLegal,
  } = formValues;

  const idPersona = responsableData?.responsableCustomData?.idPersona;
  const idDomicilioPersona = responsableDatosDomicilio?.idDomicilioPersona;
  const idResponsable = responsableData?.responsableCustomData?.idResponsable;
  const idDatosLab = responsableDatosLaborales?.idDatosLab;
  const idVinculo = responsableData?.responsableVinculo?.idVinculo;
  const idMedioContactoEmail =
    responsableData?.responsableCustomData?.idMedioContactoEmail;
  const idMedioContactoCelular =
    responsableData?.responsableCustomData?.idMedioContactoCelular;
  const idMedioContactoLinea =
    responsableData?.responsableCustomData?.idMedioContactoLinea;

  const updatedPersonaResponsable = {
    ...(idPersona && { idPersona }),
    nombre: responsables?.datosPrincipales?.nombres,
    apellido: responsables?.datosPrincipales?.apellidos,
    fechaNacimiento: responsables?.datosPrincipales?.fechaNacimiento,
    estadoCivil: responsables?.datosPrincipales?.estadoCivil,
    tipoDocumento: {
      idTipoDocumento: responsables?.datosPrincipales?.tipoDocumento,
    },
    documento: responsables?.datosPrincipales?.documento,
    genero: { idGenero: responsables?.datosPrincipales?.genero },
    paisNacimiento: { idPais: responsables?.datosPrincipales?.paisNacimiento },
    provinciaNacimiento: {
      idProvincia: responsables?.datosPrincipales?.provinciaNacimiento,
    },
  };

  const updatedDomicilioPersona = {
    ...(idDomicilioPersona && { idDomicilioPersona }),
    ...(idPersona && { persona: { idPersona } }),
    municipio: { idMunicipio: responsables?.datosDomicilio?.provincia },
    calle: responsables?.datosDomicilio?.calle,
    altura: responsables?.datosDomicilio?.altura,
    piso: responsables?.datosDomicilio?.piso,
    departamento: responsables?.datosDomicilio?.depto,
    codigoPostal: responsables?.datosDomicilio?.codigoPostal,
    descripcion: responsables?.datosDomicilio?.observaciones,
    tipoDomicilio: {
      idTipoDomicilio: responsables?.datosDomicilio?.tipoDomicilio,
    },
    provincia: { idProvincia: responsables?.datosDomicilio?.provincia },
    telefono1: responsables?.datosDomicilio?.telefono1,
    telefono2: responsables?.datosDomicilio?.telefono2,
    tipoDireccion: { idTipoDireccion: 1 },
  };

  const updatedResponsable = {
    ...(idResponsable && { idResponsable }),
    persona: { idPersona: responsableData?.responsableCustomData?.idPersona },
    nEducativoAlcanzado: responsables?.datosPrincipales?.nivelEducativo,
    tituloEducativoAlcanzado: responsables?.datosPrincipales?.tituloEducativo,
  };

  const telefonoEmergencias =
    responsables?.datosPrincipales?.telefonoEmergencias;
  const telefonoOtro = responsables?.datosPrincipales?.telefonoOtro;
  const email = responsables?.datosPrincipales?.email;

  const updatedMedioContacto = {
    email: {
      persona: { idPersona },
      tipoMedioContacto: { idTipoMedioContacto: 2 },
      descripcionMedioContacto: email,
      principal: 0,
      ...(idMedioContactoEmail && { idMedioContacto: idMedioContactoEmail }),
      ...(idMedioContactoEmail && {
        idMedioContactoPersona: idMedioContactoEmail,
      }),
    },
    telefonoEmergencias: {
      persona: { idPersona },
      tipoMedioContacto: { idTipoMedioContacto: 1 },
      descripcionMedioContacto: telefonoEmergencias,
      principal: 0,
      ...(idMedioContactoCelular && {
        idMedioContacto: idMedioContactoCelular,
      }),
      ...(idMedioContactoCelular && {
        idMedioContactoPersona: idMedioContactoCelular,
      }),
    },
    telefonoOtro: {
      persona: { idPersona },
      tipoMedioContacto: { idTipoMedioContacto: 3 },
      descripcionMedioContacto: telefonoOtro,
      principal: 0,
      ...(idMedioContactoLinea && { idMedioContacto: idMedioContactoLinea }),
      ...(idMedioContactoLinea && {
        idMedioContactoPersona: idMedioContactoLinea,
      }),
    },
  };

  const updatedVinculo = {
    ...(idVinculo && { idVinculo }),
    alumno: { idAlumno: alumno?.idAlumno },
    responsable: {
      idResponsable: responsableData?.responsableCustomData?.idResponsable,
    },
    tipoVinculo: { idTipoVinculo: responsables?.datosPrincipales?.lazo },
    convive: Number(responsables?.datosPrincipales?.convive),
    diplomatico: Number(responsables?.datosPrincipales?.diplomatico),
    retiraAlumno: Number(responsables?.datosPrincipales?.retiraAlumno),
    contactoEmergencia: Number(
      responsables?.datosPrincipales?.contactoEmergencia,
    ),
  };

  const updatedDatosLaborales = {
    ...(idDatosLab && { idDatosLab }),
    tipoDomicilio: {
      idTipoDomicilio: responsables?.datosLaborales?.tipoDomicilio,
    },
    ocupacion: responsables?.datosLaborales?.ocupacion,
    empresa: responsables?.datosLaborales?.establecimientoEmpresa,
    desdehastahoras: responsables?.datosLaborales?.desdeHastaHorasLaborales,
    horasPorDia: responsables?.datosLaborales?.horasPorDia,
    cantDiasSemana: {
      idCantDiasSemana: responsables?.datosLaborales?.cantidadDiasSemana,
    },
    provincia: { idProvincia: responsables?.datosLaborales?.provincia },
    calle: responsables?.datosLaborales?.calle,
    altura: responsables?.datosLaborales?.altura,
    depto: responsables?.datosLaborales?.depto,
    codigoPostal: responsables?.datosLaborales?.codigoPostal,
    observaciones: responsables?.datosLaborales?.observaciones,
    piso: responsables?.datosLaborales?.piso,
    responsable: {
      idResponsable: responsableData?.responsableCustomData?.idResponsable,
    },
  };

  /**
   * checkboxes se guardan "true" o "false" en la base de datos, YA FUE
   */

  // TODO: tenemos que manejar mejor la interfaz
  // @ts-ignore
  const updatedPersona: Persona = {
    // @ts-ignore
    idPersona: alumno?.persona.idPersona,
    nombre: datosPersonales?.datosPrincipales?.nombres,
    apellido: datosPersonales?.datosPrincipales?.apellidos,
    genero: datosPersonales?.datosPrincipales?.genero,
    email: datosPersonales?.datosPrincipales?.email,
    estadoCivil: datosPersonales?.datosPrincipales?.estadoCivil,
    paisNacimiento: datosPersonales?.datosPrincipales?.paisNacimiento,
    documento: datosPersonales?.datosPrincipales?.documento,
    tipoDocumento: datosPersonales?.datosPrincipales?.tipoDocumento,
    fechaNacimiento: datosPersonales?.datosPrincipales?.fechaNacimiento,
    pbIndigenaPertenece: datosPersonales?.datosPrincipales?.pbIndigenaPertenece,
    pbIndigenaHabla: datosPersonales?.datosPrincipales?.pbIndigenaHabla,
    obsIndigena: datosPersonales?.datosPrincipales?.obsIndigena,
  };

  // const updatedEnrolledAlumn: { idInscripcionAlumno: string; anio: number } = {
  //   idInscripcionAlumno: datosInstitucion?.data.idInscripcionAlumno,
  //   anio: datosPersonales?.datosInstitucion?.ano,
  // }; // se comenta porque al hacer el GET se produce un error de validación, también ya se está usando la lógica nueva sin guardar en el /inscripcionalumno. se queda el comentario por ahora para evitar confusiones y tener trazabalidad hasta que se efectúe un refactor definitivo del Legajo
  const fichaDatosPersonales = {
    datosPrincipales: {
      email: datosPersonales?.datosPrincipales?.email,
      legajo: datosPersonales?.datosPrincipales?.legajo,
      telefono1: datosPersonales?.datosPrincipales?.telefono1,
      telefono2: datosPersonales?.datosPrincipales?.telefono2,
      provinciaNacimiento:
        datosPersonales?.datosPrincipales?.provinciaNacimiento,
    },
    datosInstitucion: {
      condicionAnoAnterior:
        datosPersonales?.datosInstitucion?.condicionAnoAnterior,
    },
  };
  // TODO
  // improve this normalization when more changes for MODALIDAD come as buildInitialLegajoAlumnoFormValues method
  if (modalidad === ModalidadType.General) {
    // @ts-ignore
    fichaDatosPersonales.datosInstitucion.anio =
      datosPersonales?.datosInstitucion?.ano;
  } else if (modalidad === ModalidadType.Adultos) {
    // @ts-ignore
    fichaDatosPersonales.datosInstitucion.cicloAdultos =
      datosPersonales?.datosInstitucion?.cicloAdultos;
  } else if (modalidad === ModalidadType.EspecialByC) {
    // @ts-ignore
    fichaDatosPersonales.datosInstitucion.ciclo =
      datosPersonales?.datosInstitucion?.ciclo;
  }

  const fichaResidencia = {
    [residencia?.tipoDireccion]: {
      calle: residencia?.[residencia?.tipoDireccion]?.calle,
      provincia: residencia?.[residencia?.tipoDireccion]?.provincia,
      telefono1: residencia?.[residencia?.tipoDireccion]?.telefono1,
      telefono2: residencia?.[residencia?.tipoDireccion]?.telefono2,
      codigoPostal: residencia?.[residencia?.tipoDireccion]?.codigoPostal,
      tipoDireccion: residencia?.[residencia?.tipoDireccion]?.tipoDireccion,
      observaciones: residencia?.[residencia?.tipoDireccion]?.observaciones,
      localidadBarrio: residencia?.[residencia?.tipoDireccion]?.localidadBarrio,
      villa:
        residencia?.tipoDireccion === 'datosResidenciaTipoB'
          ? residencia?.datosResidenciaTipoB?.villa
          : undefined,
      manzana:
        residencia?.tipoDireccion === 'datosResidenciaTipoB'
          ? residencia?.datosResidenciaTipoB?.manzana
          : undefined,
      altura:
        residencia?.tipoDireccion === 'datosResidenciaTipoA'
          ? residencia?.datosResidenciaTipoA?.altura
          : undefined,
      pisoDpto:
        residencia?.tipoDireccion === 'datosResidenciaTipoA'
          ? residencia?.datosResidenciaTipoA?.pisoDpto
          : undefined,
    },
  };

  const fichaHistorialAcademico = historialAcademico;
  const fichaDocumentacion = {
    datosPrincipales: await buildFichaDocumentacion(documentacion),
  };

  const fichaSalud = {
    datosPrincipales: {
      alergias: salud?.datosPrincipales?.alergias,
      sabeNadar: salud?.datosPrincipales?.sabeNadar,
      necesidades: salud?.datosPrincipales?.necesidades,
      medicamentos: salud?.datosPrincipales?.medicamentos,
      observaciones: salud?.datosPrincipales?.observaciones,
      observaciones2: salud?.datosPrincipales?.observaciones2,
      tratamientoMedico: salud?.datosPrincipales?.tratamientoMedico,
      seguimientoDocente: salud?.datosPrincipales?.seguimientoDocente,
      accionesEmergencia: salud?.datosPrincipales?.accionesEmergencia,
      enfermedadesCronicas: salud?.datosPrincipales?.enfermedadesCronicas,
      actividadesRestringidas: salud?.datosPrincipales?.actividadesRestringidas,
      grupoSanguineo: salud?.datosPrincipales?.grupoSanguineo,
      calendarioVacunacion: salud?.datosPrincipales?.calendarioVacunacion,
    },
    datosAptoFisico: await buildAptoFisico(salud),
    datosCobertura: {
      numeroSocio: salud?.datosCobertura?.numeroSocio,
      planCobertura: salud?.datosCobertura?.planCobertura,
      tipoDeCobertura: salud?.datosCobertura?.tipoDeCobertura,
      telefonoCobertura: salud?.datosCobertura?.telefonoCobertura,
    },
    datosAtencion: buildSaludAtencion(salud?.datosAtencion),
    datosLose: {
      loseDesde: salud?.datosLose?.loseDesde,
      loseHasta: salud?.datosLose?.loseHasta,
    },
    datosAcompanante: buildSaludAcompanantes(salud.datosAcompanante),
  };

  const fichaSituacionLegal = {
    datosPrincipales: {
      nombreCentro: situacionLegal?.datosPrincipales?.nombreCentro,
      tipoDocumento: situacionLegal?.datosPrincipales?.tipoDocumento,
      fechaVigencia: situacionLegal?.datosPrincipales?.fechaVigencia,
      tipoSituacion: situacionLegal?.datosPrincipales?.tipoSituacion,
      descripcionAdicional1:
        situacionLegal?.datosPrincipales?.descripcionAdicional1,
    },
    datosAdicionales: {
      descripcionAdicional2:
        situacionLegal?.datosAdicionales?.descripcionAdicional2,
    },
  };

  const fichaPipPps = {};

  const updatedLegajoAlumno: LegajoAlumno = {
    // @ts-ignore
    idLegajoAlumno: legajoAlumno?.idLegajoAlumno,
    // @ts-ignore
    alumno,
    fichaDatosPersonales,
    fichaResidencia,
    fichaDocumentacion,
    fichaHistorialAcademico,
    fichaSalud,
    fichaSituacionLegal,
    fichaPipPps,
  };

  return {
    updatedPersona,
    // updatedEnrolledAlumn,
    updatedLegajoAlumno,
    updatedVinculo,
    updatedResponsable,
    updatedMedioContacto,
    updatedDomicilioPersona,
    updatedDatosLaborales,
    updatedPersonaResponsable,
  };
};
