import { LibbyFetchDAO } from './LibbyFetchDAO';

export class AreaDAO extends LibbyFetchDAO {
  constructor() {
    super('area', 'idArea');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
