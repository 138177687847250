import React from 'react';
import {
  makeStyles,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { grey } from 'src/theme/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    color: grey.textPrimary,
    fontWeight: 600,
  },
  description: {
    '&& .MuiTypography-body1': {
      fontWeight: 400,
      color: grey.textPrimary,
      fontSize: 16,
    },
  },
  container: {
    paddingLeft: 0,
  },
}));

export const ListItemProperties = ({
  title,
  values,
}: {
  title?: string;
  values: {
    id: string | number;
    descripcion: string;
    subLevel?: {
      id: string | number;
      descripcion: string;
      subLevel2?: { id: string | number; descripcion: string }[];
    }[];
  }[];
}) => {
  const classes = useStyles();
  return (
    <Grid>
      {title && (
        <Typography variant="h6" className={classes.title}>
          {title}
        </Typography>
      )}
      <List>
        {values?.map(({ descripcion, id, subLevel }) => (
          <>
            <ListItem key={id} className={classes.container}>
              <ListItemText
                primary={descripcion}
                className={classes.description}
              />
            </ListItem>
            <List component="div" disablePadding>
              {subLevel &&
                subLevel.map(
                  ({ id: idSub, descripcion: descSub, subLevel2 }) => (
                    <>
                      <ListItem key={idSub} className={classes.container}>
                        <ListItemText
                          primary={descSub}
                          className={classes.description}
                        />
                      </ListItem>
                      <List component="div" disablePadding>
                        {subLevel2 &&
                          subLevel2.map(
                            ({ id: idSub2, descripcion: descSub2 }) => (
                              <ListItem
                                key={idSub2}
                                className={classes.container}
                              >
                                <ListItemText
                                  primary={descSub2}
                                  className={classes.description}
                                />
                              </ListItem>
                            ),
                          )}
                      </List>
                    </>
                  ),
                )}
            </List>
          </>
        ))}
      </List>
    </Grid>
  );
};
