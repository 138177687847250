import React, { useMemo } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/styles';
import { Button, TableRow, Tooltip } from '@material-ui/core';
import { Dias } from '../../Acap/utils';
import { OfertaGrupo } from 'src/app/models/acap/OfertasGrupos';
import { GrupoJornada } from 'src/app/models/acap/GruposJornadas';
import { MUIEditorMIESC } from '../../AcapEscuela/components/MUIEditorMIESC';

const useStyles = makeStyles({
  arrow: {
    color: '#00b3e3',
  },
  tooltip: {
    backgroundColor: '#00b3e3',
    fontSize: '13px',
  },
});

export const useReferenteViewColumns = (setTomandoPresentismo: any) => {
  const classes = useStyles();

  const columns = useMemo(() => {
    return [
      {
        id: 'accion',
        label: 'Acción',
        orderById: 'ofertaTurno.oferta.accion.nombre',
        render: (value: any, row: OfertaGrupo) => {
          let editorText;
          try {
            editorText =
              JSON.parse(row?.ofertaTurno?.oferta?.accion?.descripcion)?.text ||
              JSON.parse(row?.ofertaTurno?.oferta?.accion?.descripcion);
          } catch (e) {
            editorText = row?.ofertaTurno?.oferta?.accion?.descripcion;
          }
          let onlyDescription = '';
          if (!!editorText?.blocks) {
            editorText?.blocks.forEach((elem: any, i: number) => {
              if (elem.text !== '')
                onlyDescription = onlyDescription.concat(
                  i === 0 ? '' : '\n',
                  elem.text,
                );
            });
          }
          const editorTextUpdated =
            typeof editorText === 'string' ? editorText : onlyDescription;
          return (
            <Tooltip
              classes={classes}
              arrow
              title={<MUIEditorMIESC text={editorTextUpdated} />}
            >
              <span>{row?.ofertaTurno?.oferta?.accion?.nombre}</span>
            </Tooltip>
          );
        },
      },
      {
        id: 'grupoId',
        label: 'Grupo',
        hideSortIcon: true,
        render: (_: unknown, row: OfertaGrupo) =>
          `${row?.ofertaTurno?.oferta?.accion?.nombre}, ${row?.ofertaTurno?.turno?.descripcionTurno}, id: ${row.idOfertaGrupo}`,
      },
      {
        id: 'turno',
        label: 'Turno',
        orderById: 'ofertaTurno.turno.descripcionTurno',
        render: (value: any, row: OfertaGrupo) =>
          row?.ofertaTurno?.turno?.descripcionTurno,
      },
      {
        id: 'cupo',
        label: 'Cupo',
        hideSortIcon: true,
        render: (value: any, row: OfertaGrupo) =>
          row?.ofertaTurno?.cupo / row?.ofertaTurno?.cantGrupos,
      },
      {
        id: 'referente',
        label: 'Referente',
        hideSortIcon: true,
        render: (value: any, row: OfertaGrupo) =>
          Boolean(row?.referente?.apellido)
            ? `${row?.referente?.apellido}, ${row?.referente?.nombre}`
            : row?.referente?.nombre,
      },
      {
        id: 'fechaInicio',
        label: 'Fecha de inicio de la actividad',
        orderById: 'ofertaTurno.oferta.fechaInicio',
        customStyles: { textAlign: 'center' },
        headerAlign: 'center',
        render: (value: any, row: OfertaGrupo) =>
          row?.fechaInicio === null
            ? '-'
            : moment(row?.fechaInicio).format('DD/MM/YYYY'),
      },
      {
        id: 'fechaFin',
        label: 'Fecha de fin de la actividad',
        hideSortIcon: true,
        customStyles: { textAlign: 'center' },
        headerAlign: 'center',
        render: (value: any, row: OfertaGrupo) =>
          row?.fechaFin === null
            ? '-'
            : moment(row?.fechaFin).format('DD/MM/YYYY'),
      },
      {
        id: 'dia',
        label: 'Día',
        hideSortIcon: true,
        customStyles: { padding: '0' },
        render: (value: any, row: OfertaGrupo) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {row?.jornadas?.map((jornada: GrupoJornada, index: number) => {
                return (
                  <TableRow
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      ...(row?.jornadas?.length > 1 &&
                      row?.jornadas?.length - 1 !== index
                        ? { borderBottom: '1px solid #DFE3E8' }
                        : {}),
                      padding: '0 16px',
                    }}
                  >
                    {Dias[jornada.cupo][0] +
                      Dias[jornada.cupo].slice(1).toLowerCase()}
                  </TableRow>
                );
              })}
            </div>
          );
        },
      },
      {
        id: 'horaInicio',
        label: 'Hora inicio',
        hideSortIcon: true,
        customStyles: { padding: '0' },
        render: (value: any, row: OfertaGrupo) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {row?.jornadas?.map((jornada: GrupoJornada, index: number) => {
                return (
                  <TableRow
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      ...(row?.jornadas?.length > 1 &&
                      row?.jornadas?.length - 1 !== index
                        ? { borderBottom: '1px solid #DFE3E8' }
                        : {}),
                      padding: '0 16px',
                    }}
                  >
                    {moment(jornada.horaInicio, 'HH:mm:ss').format('HH:mm')}
                  </TableRow>
                );
              })}
            </div>
          );
        },
      },
      {
        id: 'horaFin',
        label: 'Hora fin',
        hideSortIcon: true,
        customStyles: { padding: '0' },
        render: (value: any, row: OfertaGrupo) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {row?.jornadas?.map((jornada: GrupoJornada, index: number) => {
                return (
                  <TableRow
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      ...(row?.jornadas?.length > 1 &&
                      row?.jornadas?.length - 1 !== index
                        ? { borderBottom: '1px solid #DFE3E8' }
                        : {}),
                      padding: '0 16px',
                    }}
                  >
                    {moment(jornada.horaFin, 'HH:mm:ss').format('HH:mm')}
                  </TableRow>
                );
              })}
            </div>
          );
        },
      },
      {
        id: 'button',
        label: '',
        hideSortIcon: true,
        render: (value: any, row: OfertaGrupo) => (
          <Button
            color="primary"
            variant="contained"
            component="label"
            style={{ width: '100px' }}
            onClick={() =>
              setTomandoPresentismo({
                enabled: true,
                row,
              })
            }
          >
            Acceder
          </Button>
        ),
      },
    ];
  }, [classes, setTomandoPresentismo]);

  return columns;
};
