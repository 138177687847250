import { Seccion } from 'src/app/models';
import { LibbyFetchDAO } from './LibbyFetchDAO';

export class SectionDAO extends LibbyFetchDAO {
  constructor() {
    super('seccion', 'idSeccion');
  }

  async getAll(limit = 10000): Promise<Seccion[]> {
    let data = [];
    try {
      data = await this.query().limit(0, limit).run();
    } catch (error) {
      console.error('Error request /seccion');
    }
    return data;
  }

  getFilterTable(filters: any) {
    const request = this.query();
    Object.entries(filters).map(
      (filter: any) => filter[1] && request.equals(filter[0], filter[1]),
    );
    return request.limit(0, 10000).run();
  }

  getAscDesc(order: any, value: any) {
    return this.query().orderBy(order, value).limit(0, 10000).run();
  }

  async getByLocationId(locationId: string) {
    return await this.query()
      .equals('localizacion.idLocalizacion', locationId)
      .orderBy('idSeccion')
      .limit(0, 500)
      .run();
  }

  /**
   * Se piden las secciones para colocar
   * los roles validos para solicitar
   * una autoregistración
   * @param locationId
   * @param nivel
   * @param jornada string Se debe pasar en minuscula todo
   * @returns secciones correspondientes
   */
  async getForAutoregistration(
    locationId: string,
    nivel: number,
    jornada: string,
  ) {
    if (!Boolean(jornada))
      throw new Error('Debe suministrar el tipo de jornada');
    if (!Boolean(nivel)) throw new Error('Debe suministrar el nivel');
    if (!Boolean(locationId))
      throw new Error('Debe suministrar la localización');

    return await this.query('autoregistracionJornada')
      .equals('localizacion', locationId)
      .and()
      .equals('jornada', jornada)
      .and()
      .equals('nivel', nivel)
      .limit(0, 100)
      .run();
  }

  getByLocationIdAndYear(localizacionId: number, anioId: number) {
    if (localizacionId && anioId) {
      return this.query()
        .equals('localizacion', localizacionId)
        .and()
        .equals('anio', anioId)
        .orderBy('idSeccion')
        .limit(0, 10000)
        .run();
    }
    return this.query().limit(0, 1).run();
  }
}
