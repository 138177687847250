import { FormRenderProps } from 'react-final-form-hooks';
import { AnyObject } from 'src/commons';
import { useFormContext } from 'src/lib/templates';

// TODO: complete this
export interface LegajoAlumnoForm {
  informacionGeneral: AnyObject;
  ensenanzas: any;
}

export const useLegajoAlumnoForm = <T>() => useFormContext<T>();
