import { Template, TemplateProperty } from '../../../../../../../lib/templates';

const observacionTemplate: TemplateProperty[] = [
  { name: 'idTurno', type: { name: 'string' } },
  { name: 'idPresente', type: { name: 'string' }, optional: true },
  { name: 'observacion', type: { name: 'string' }, optional: true },
];

export const ObservacionesTemplate: Template = {
  name: 'Observaciones Template',
  template: [
    {
      name: 'presentes',
      type: {
        name: 'array',
        kind: observacionTemplate,
      },
    },
    {
      name: 'selectedTurno',
      type: { name: 'string' },
      label: 'Turno',
    },
  ],
};
