/* eslint-disable class-methods-use-this */
import { LibbyFetchDAO, Filter } from './LibbyFetchDAO';

export class InscripcionAlumnoDAO extends LibbyFetchDAO {
  constructor() {
    super('inscripcion_alumno', 'idInscripcionAlumno');
  }

  async getCountFilter(
    filters: Filter,
    aspect: string,
  ): Promise<{ countofidinscripcionalumno: number }[]> {
    this.aspect(aspect);
    let query = this.query();
    query = this.applyFilters(query, filters);
    const response = await query.aggregate().count('idInscripcionAlumno').run();
    return response;
  }

  async getInscriptosFilter(
    filters: Filter,
    aspect: string,
    orderBy: string,
    direction: string,
    limit: number,
    rowsPerPage: number,
    page: number,
  ) {
    let thisAspect = this.aspect(aspect);
    let query = thisAspect.query();
    query = this.applyFilters(query, filters);
    const min = (page + 1) * rowsPerPage - rowsPerPage;
    const max = (page + 1) * rowsPerPage;
    const response = await query
      .orderBy(orderBy, direction)
      .limit(min, max)
      .run();
    return response;
  }

  getByAlumnoId(alumnoId: string) {
    return this.query().equals('id_alumno', alumnoId).run();
  }

  getByAlumnoMovimientoData({ idAlumno }: { idAlumno: number }) {
    return this.query()

      .equals('alumno.idAlumno', idAlumno)

      .run();
  }
}
