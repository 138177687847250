import React from 'react';
import {
  IconButton,
  Grid,
  makeStyles,
  Divider,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { descripcionNewLine, Footer } from 'src/commons';
import { AnyObject } from '../../../../../../../../../commons/types';
import { FormInputControlled } from '../../../../../../../../../lib/templates/components';
import {
  EspacioCurricularOption,
  EspaciosCurricularesDialog,
} from '../../../../../dialogs/EspaciosCurricularesDialog';
import { HabilidadesDialog } from '../../../../../dialogs/HabilidadesDialog';
import { grey } from '../../../../../../../../../theme/colors';
import { CommonCheckboxFormInput } from '../../../../../components';
import { usePlanificationForm } from '../../../commons/hooks';
import { useArealInterarealContext } from '../../context';
import { ContenidosEspecificosDialog } from '../../../../../dialogs/ContenidosEspecificosDialog';
import {
  useContenidoBloqueFetch,
  useContenidosEspecificosFetch,
  useEspaciosCurricularesCustomFetch,
  useHabilidadesFetch,
} from '../hooks';

const useStyles = makeStyles({
  container: {
    padding: '32px 10px',
    marginBottom: 60,
    paddingRight: 60,
  },
  listItem: {
    padding: 0,
  },
  listItemIcon: {
    marginRight: '-20px',
  },
  text: {
    color: grey.medium,
  },
  inputSize: {
    width: '32%',
  },
  background: {
    borderRadius: 5,
    backgroundColor: 'rgba(218, 218, 218, 0.17)',
  },
  size: {
    borderBottom: `1px solid ${grey.normal}`,
    backgroundColor: 'rgba(218, 218, 218, 0.17)',
    padding: 20,
    marginBottom: 15,
    borderRadius: 5,
  },
  marginDeleteIcon: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    paddingRight: 50,
  },
  paddingDeleteIcon: {
    paddingTop: 25,
  },
  divider: { width: '100%', margin: '20px 0px 15px 0px' },
  title: {
    paddingTop: 10,
    paddingLeft: 35,
  },
});

interface ContenidoAll {
  idContenidoEspecifico?: number;
  descripcion: string;
  idContenidoBloque?: string;
}

// TODO: Espacio curricular deberia setearse a null si cambian las orientaciones
// TODO; tipificar
export const EspacioCurricular = () => {
  const classes = useStyles();
  const { values, form } = usePlanificationForm();

  const { areas } = useArealInterarealContext();

  const { habilidades } = useHabilidadesFetch();
  const { contenidosEspecificos } = useContenidosEspecificosFetch();
  const { espaciosCurricularesCustom: espaciosCurriculares } =
    useEspaciosCurricularesCustomFetch();
  const { contenidosBloques } = useContenidoBloqueFetch();

  espaciosCurriculares.forEach((element: any) => {
    if (element.activo === false) {
      const index = espaciosCurriculares.indexOf(element);
      espaciosCurriculares.splice(index, 1);
    }
  });

  const addNew = () => {
    const newValue = values?.espacioCurricular?.espaciosCurriculares;
    newValue.push({
      espacioCurricular: null,
      habilidades: [],
      contenidosEspecificos: [],
    });
    form.change('espacioCurricular', {
      espaciosCurriculares: [...newValue],
    });
  };

  const onRemoveItem = (index: number) => {
    const newInput = values?.espacioCurricular?.espaciosCurriculares.filter(
      (item: any, ind: number) => index !== ind,
    );
    form.change('espacioCurricular', {
      espaciosCurriculares: [...newInput],
    });
  };
  const footerCustomStyle = { marginTop: '15px' };

  return (
    <Grid container direction="column" className={classes.container}>
      {values?.espacioCurricular?.espaciosCurriculares?.map(
        (item: AnyObject, index: number) => {
          return (
            <Grid direction="row" container>
              <Grid
                item
                xs={12}
                direction="row"
                alignItems="center"
                justify="center"
                spacing={2}
                container
                className={classes.size}
              >
                {index !== 0 && (
                  <Grid xs={12} direction="row" container>
                    <Grid item xs={12} className={classes.marginDeleteIcon}>
                      <Tooltip title="Eliminar Actividad">
                        <IconButton edge="end" aria-label="delete">
                          <DeleteIcon onClick={() => onRemoveItem(index)} />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </Grid>
                )}
                <Tooltip title={!areas?.length ? 'Seleccione un area' : ''}>
                  <Grid item className={classes.inputSize}>
                    <FormInputControlled
                      label="Espacio curricular"
                      name={`espacioCurricular.espaciosCurriculares[${index}].espacioCurricular`}
                      form={form}
                      inputProps={{
                        disabled: !areas?.length || index === 0,
                      }}
                      helperTextOnError={'Seleccione un espacio curricular'}
                      disabled={index === 0}
                      placeholder="Seleccioná espacio curricular"
                      getInputValue={(value) => {
                        const espacioSelected = !value?.id
                          ? null
                          : espaciosCurriculares.find(
                              (espacio) =>
                                espacio.idEspacioCurricular === value?.id,
                            );
                        return espacioSelected?.descripcion || '';
                      }}
                      getNewValue={async (oldValue: { id: string } | null) => {
                        const options: EspacioCurricularOption[] =
                          espaciosCurriculares.map((espacio) => ({
                            id: espacio.idEspacioCurricular,
                            descripcion: espacio.descripcion,
                          }));
                        const espacioSelected = !oldValue?.id
                          ? null
                          : espaciosCurriculares.find(
                              (espacio) =>
                                espacio.idEspacioCurricular === oldValue.id,
                            );
                        const formatted: EspacioCurricularOption | null =
                          !espacioSelected
                            ? null
                            : {
                                id: espacioSelected.idEspacioCurricular,
                                descripcion: espacioSelected.descripcion,
                              };
                        const newValue: EspacioCurricularOption | null =
                          await EspaciosCurricularesDialog.open(
                            formatted,
                            options,
                          );

                        const selectedEspacioCurricular =
                          espaciosCurriculares.find(
                            (espacio) =>
                              espacio.idEspacioCurricular === newValue?.id,
                          );

                        const bloques =
                          selectedEspacioCurricular?.materia?.tieneBloque;

                        if (!newValue) return oldValue;
                        return {
                          id: newValue.id,
                          tieneBloque: bloques,
                        };
                      }}
                    />
                  </Grid>
                </Tooltip>

                <Grid item className={classes.inputSize}>
                  <CommonCheckboxFormInput
                    label="Habilidades"
                    name={`espacioCurricular.espaciosCurriculares[${index}].habilidades`}
                    form={form}
                    placeholder="Agregar habilidades"
                    dialog={HabilidadesDialog}
                    options={habilidades}
                    optionIdKey="idHabilidades"
                  />
                </Grid>

                <Grid item alignItems="flex-end" className={classes.inputSize}>
                  <FormInputControlled
                    label="Contenidos específicos"
                    name={`espacioCurricular.espaciosCurriculares[${index}].contenidosEspecificos`}
                    form={form}
                    placeholder="Definí contenidos específicos"
                    getInputValue={(value) => {
                      if (!Array.isArray(value) || !value.length) return '';
                      const selectedEspacioCurricular =
                        espaciosCurriculares.find(
                          (espacio) =>
                            espacio.idEspacioCurricular ===
                            item.espacioCurricular.id,
                        );
                      const bloques =
                        selectedEspacioCurricular?.materia?.tieneBloque;
                      const isBloque: ContenidoAll[] = bloques
                        ? contenidosBloques
                        : contenidosEspecificos;

                      const selectContenido = isBloque
                        .filter((u) =>
                          value?.find(
                            (e) =>
                              Number(e.id) ===
                                Number(u.idContenidoEspecifico) ||
                              Number(e.id) === Number(u.idContenidoBloque),
                          ),
                        )
                        .map((contenido) => contenido.descripcion);

                      return selectContenido;
                    }}
                    getNewValue={async (oldValue) => {
                      const selectedEspacioCurricular =
                        espaciosCurriculares.find(
                          (espacio) =>
                            espacio.idEspacioCurricular ===
                            item.espacioCurricular.id,
                        );

                      const bloques =
                        selectedEspacioCurricular?.materia?.tieneBloque;
                      const isBloque: Array<any> = bloques
                        ? contenidosBloques
                        : contenidosEspecificos;

                      const _values = isBloque
                        .filter((contenido) =>
                          !selectedEspacioCurricular
                            ? false
                            : bloques
                            ? contenido?.bloque?.idMateria
                            : contenido?.materia?.idMateria ===
                              selectedEspacioCurricular?.materia?.idMateria,
                        )
                        .map(
                          ({
                            idContenidoBloque,
                            idContenidoEspecifico,
                            contenidoBloqueSubnivels,
                            contenidoEspecificoSubnivels,
                            descripcion,
                          }) => {
                            const found = oldValue.find((old: any) => {
                              const id = selectedEspacioCurricular?.materia
                                ?.tieneBloque
                                ? idContenidoBloque
                                : idContenidoEspecifico;

                              return id === old.id;
                            });

                            const contenidoSubNivel: Array<any> =
                              contenidoBloqueSubnivels ||
                              contenidoEspecificoSubnivels;
                            return {
                              id: idContenidoBloque || idContenidoEspecifico,
                              descripcion,
                              checked: !!found,
                              subLevel: contenidoSubNivel.map(
                                ({
                                  contenidoBloqueSubnivelDos,
                                  contenidoEspecificoSubnivelDos,
                                  idContenidoBloqueSubnivel,
                                  idContenidoEspecificoSubnivel,
                                  descripcion: descripcionSub,
                                }) => {
                                  const contenidosSubNivelDos: Array<any> =
                                    contenidoBloqueSubnivelDos ||
                                    contenidoEspecificoSubnivelDos;
                                  return {
                                    id: bloques
                                      ? idContenidoBloqueSubnivel
                                      : idContenidoEspecificoSubnivel,
                                    descripcion:
                                      descripcionNewLine(descripcionSub),
                                    checked: !found
                                      ? false
                                      : !!found?.subLevel.find(
                                          (level: any) =>
                                            Number(level.id) ===
                                              idContenidoEspecificoSubnivel ||
                                            Number(level.id) ===
                                              idContenidoBloqueSubnivel,
                                        ),
                                    subLevel2: !contenidosSubNivelDos
                                      ? []
                                      : contenidosSubNivelDos.map(
                                          ({
                                            idContenidoBloqueSubnivelDos,
                                            idContenidoEspecificoSubnivelDos,
                                            descripcion: descripSubNivelDos,
                                          }) => ({
                                            id:
                                              idContenidoBloqueSubnivelDos ||
                                              idContenidoEspecificoSubnivelDos,
                                            descripcion:
                                              descripcionNewLine(
                                                descripSubNivelDos,
                                              ),
                                            checked: !found
                                              ? false
                                              : !!found?.subLevel?.find(
                                                  (level: {
                                                    id: string;
                                                    subLevel2: Array<string>;
                                                  }) =>
                                                    !!level.subLevel2.find(
                                                      (levle2: any) =>
                                                        Number(levle2) ===
                                                          idContenidoBloqueSubnivelDos ||
                                                        Number(levle2) ===
                                                          idContenidoEspecificoSubnivelDos,
                                                    ),
                                                ),
                                          }),
                                        ),
                                  };
                                },
                              ),
                            };
                          },
                        );

                      const newValue = await ContenidosEspecificosDialog.open(
                        _values || [],
                      );
                      if (!newValue) {
                        return oldValue;
                      }

                      return newValue
                        .filter((_item: any) => _item.checked)
                        .map((_item: any) => ({
                          id: _item.id,
                          subLevel: _item.subLevel
                            .filter((level: any) => level.checked)
                            .map((level: any) => ({
                              id: level.id,
                              subLevel2: !level?.subLevel2.length
                                ? []
                                : level.subLevel2
                                    .filter((level2: any) => level2.checked)
                                    .map((level2: any) =>
                                      level2.id.toString(),
                                    ) || [],
                            })),
                        }));
                    }}
                  />
                </Grid>
              </Grid>

              {values.espacioCurricular.espaciosCurriculares.length !==
                index + 1 && <Divider className={classes.divider} />}
            </Grid>
          );
        },
      )}
      <Footer
        buttonConfig={[
          {
            title: 'Agregar Espacio Curricular',
            handleOnClick: () => addNew(),
          },
        ]}
        position="flexd-start"
        spacing={1}
        customStyle={footerCustomStyle}
      />
    </Grid>
  );
};
