import { CommandParameter, DAOQueryBuilder } from '@phinxlab/libby-core';
import { RESTStoreStrategy } from '@phinxlab/libby-rest-web';
import { RESTDAO } from '@phinxlab/libby-rest-web';

export class LQLBuilderV2 extends DAOQueryBuilder {
  static IN: any;
  static IS_NULL: any;
  in(field: string, value: Array<string | number>, alias = field): this {
    const type = LQLBuilderV2.IN;
    (this._config.filter as any)[Symbol(field)] = { field, value, type };
    return this;
  }
  isnull(field: string, value: boolean, alias = field): this {
    const type = LQLBuilderV2.IS_NULL;
    (this._config.filter as any)[Symbol(field)] = { field, value, type };
    return this;
  }
}
LQLBuilderV2.IN = Symbol('in');
LQLBuilderV2.IS_NULL = Symbol('isnull');

const JSConditions = {
  [DAOQueryBuilder.LOWEROREQUALS]: 'lowerorequals',
  [DAOQueryBuilder.LOWER]: 'lower',
  [DAOQueryBuilder.EQUALS]: 'equals',
  [DAOQueryBuilder.NOT_EQUALS]: 'not_equals',
  [DAOQueryBuilder.HIGHEROREQUALS]: 'higherorequals',
  [DAOQueryBuilder.HIGHER]: 'higher',
  [DAOQueryBuilder.INCLUDES]: 'includes',
  [DAOQueryBuilder.STARTS]: 'starts',
  [DAOQueryBuilder.ENDS]: 'ends',
  [LQLBuilderV2.IN]: 'in',
  [LQLBuilderV2.IS_NULL]: 'isnull',
};

export class RESTStrategyV2 extends RESTStoreStrategy {
  constructor(name: string, path: any, pk?: string) {
    super(name, path, pk);
    this.urlMaker = new RESTUrlV2(path, pk);
  }
}

export class RESTUrlV2 {
  static ACTIVE: any;
  path: string;
  pk?: string;
  reference: any;
  constructor(path: string, pk?: string) {
    this.path = path;
    this.pk = pk;
  }

  createInsert(dto: CommandParameter): string {
    return `${this.path}/`;
  }

  createUpdate(dto: CommandParameter): string {
    return `${this.path}/${dto[this.pk]}`;
  }

  createDelete(dto: CommandParameter): string {
    return `${this.path}/${dto[this.pk]}`;
  }

  createFind(command: CommandParameter): string {
    const { filter, order, limit, aggregate } = command;
    const keys = Object.getOwnPropertySymbols(filter);
    const url = [];
    //If it is a query we create a query
    if (keys.length > 0) {
      let query = this.reference;
      //We evaluate filters
      for (let i = 0; i < keys.length; i++) {
        const where = filter[keys[i]];
        if (where.type === LQLBuilderV2.RANGE) {
          url.push(
            `${where.field}[${JSConditions[LQLBuilderV2.HIGHEROREQUALS]}]=${
              where.value
            }`,
          );
          url.push(
            `${where.field}[${JSConditions[LQLBuilderV2.LOWEROREQUALS]}]=${
              where.to
            }`,
          );
        }
        if (
          where.type === LQLBuilderV2.GROUP_START ||
          where.type === LQLBuilderV2.GROUP_END ||
          where.type === LQLBuilderV2.AND ||
          where.type === LQLBuilderV2.OR
        ) {
          url.push(`${where.field}[${where.value}]`);
        } else {
          url.push(
            `${where.field}[${JSConditions[where.type]}]=${where.value}`,
          );
        }
      }
    }
    //We evaluate orders
    const sort = [];
    for (let i = 0; i < order.length; i++) {
      const o = order[i];
      sort.push((o.direction === 'desc' ? '^' : '') + o.field);
    }
    if (aggregate) {
      url.push(aggregate.toURL().join('&'));
    }
    if (sort.length) url.push(`sort=${sort.join(',')}`);

    // Conditionally add offset and limit properties to query URL
    if (limit && limit.from && limit.to) {
      url.push(`offset=${limit.from}&limit=${limit.to}`);
    } else if (limit && limit.to) {
      url.push(`offset=0&limit=${limit.to}`);
    } else if (limit && limit.from) {
      url.push(`offset=${limit.from}`);
    }

    return `${this.path}?${url.join('&')}`;
  }
}

export class DAOv2 extends RESTDAO {
  // eslint-disable-next-line
  constructor(name: string, pk: string) {
    super(name, pk);
  }
  query(): LQLBuilderV2 {
    return new LQLBuilderV2(this);
  }
}
