import { Grid, Typography } from '@material-ui/core';
import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Footer, useDebounce, Loading } from 'src/commons';
import {
  ButtonTypesProps,
  InfoTable,
  useTabBarSetValue,
} from 'src/commons/components';
import {
  NIVEL,
  ROL,
  TIPO_CONFIGURACION,
  TIPO_PERIODO,
  level,
} from 'src/commons/const';
import { primary } from 'src/theme/colors';
import { useRolesContext } from 'src/context/RolesContext';
import {
  useCopySeccionesGetRequest,
  useProyeccionEstadoLibbyFetch,
  useProyeccionLibbyFetch,
  useSeccionWithProyeccionLibbyCall,
  useProyeccionIELLibbyCall,
  useAlumnoMovimientoLibbyCall,
  useEfectivizacionCustomGetRequest,
  usePeriodoNuevoLibbyFetch,
  useCicloLectivoLibbyCall,
  useConfiguracionContext,
} from 'src/app/business';
import { FilterTablesPromotion } from './components/FilterTablesPromotion';
import { PromotionDetail } from './PromotionDetail';
import { usePromocionColumns } from '../hooks';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useSnackbar } from 'notistack';
import { useDateValidator } from 'src/commons/hooks/useDateValidator';
import customFormDialog from 'src/commons/services/customFormDialog';
import { ModalProyeccion } from './components/ModalProyeccion';
import { makeStyles } from '@material-ui/core';
import {
  CONTINUA_CON_APOYO,
  JORNADA,
  SeccionWithProyeccion,
  SolicitudPases,
  Turno,
} from 'src/app/models';
import { useProyeccionIELBulkCustomPostRequest } from 'src/app/business/businessCustomEndpoints/ProyeccionIELBulkCustom';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
import { useColegioMetaContext } from 'src/platform/context/ColegioMetaProvider';
import { useEfectivizar } from './hooks/useEfectivizar';
import { useFinalizarProyeccion } from './hooks/useFinalizarProyeccion';
import { ProyeccionEstadoDAO } from 'src/platform/libby/dao';
import { initialSchoolsToRemove } from 'src/screens/Private/GestionProyeccion/components/FooterButtons';
import { useProyeccionMatriculacionLibbyCall } from 'src/app/business/ProyeccionMatriculacion';
import { makeSearchFilter } from 'src/utils/makeSearchFilter';
import { usePaginate } from 'src/commons/hooks/usePaginate';
import { debounce } from 'lodash';
import { CURRENT_CICLO_LECTIVO } from 'src/screens/Private/Acap';
import { useLibbyCall } from 'src/lib/libby';
import useSolicitudPasesCompleto from '../hooks/useSolitudPasesCompleto';
import moment from 'moment';

const customStyles = makeStyles({
  iconColor: { color: primary.paleBlueStrong, cursor: 'pointer' },
  backgroundRow: { background: primary.paleBlue },
  loader: {
    margin: '200px 0px',
  },
  customContainer: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    backgroundColor: '#000000A0',
    height: '100vh',
    width: '100vw',
    zIndex: 10000,
  },
  loadingMessage: {
    position: 'absolute',
    marginTop: '6em',
    fontWeight: 800,
    fontFamily: 'Open Sans',
    color: 'white',
    userSelect: 'none',
  },
  buttonLoading: {
    height: '20px',
    padding: '0',
    display: 'flex',
    alignContent: 'center',
  },
  messageSearch: {
    textAlign: 'center',
    padding: '1em',
  },
});

const FECHA_OCTUBRE = '10/18/2021';

type Direction = 'asc' | 'desc';

const searchPaths = [
  'anio.descripcionAnio',
  'jornada',
  'turno.descripcionTurno',
  'division',
];

const escuelasCBOyREINGRESO = [
  '20203300',
  '20203800',
  '20203900',
  '20242200',
  '202351',
  '20234700',
  '20234800',
  '20234900',
  '20235200',
  '20240000',
  '20240700',
];

export const InitialPromotion = () => {
  const styleClasses = customStyles();
  const {
    selectedRole: { localizacionId, nivel, rol },
  } = useRolesContext();
  const { enqueueSnackbar } = useSnackbar();
  const pasesSolicitados = useSolicitudPasesCompleto();
  const [direction, setDirection] = useState<Direction>('asc');
  const [directionProyeccionEstado, setDirectionProyeccionEstado] =
    useState<Direction>('asc');
  const [showText, setShowText] = useState(true);
  const [orderByProyeccionEstado, setOrderByProyeccionEstado] = useState(false);
  const setTabValue = useTabBarSetValue();
  const { isDEI: esDEI } = useColegioMetaContext();
  const { cicloLectivo } = useColegioEfectivizadoContext();
  const [formValues, setFormValues] = useState<Promocion.FilterValues>({
    cicloLectivo: '',
    anio: '',
    turno: '',
    jornada: '',
  });
  const [search, setSearch] = useState('');
  const searchDebounce = useDebounce(search, 500);
  const [selectedSection, setSelectedSecction] =
    useState<Promocion.ActiveSection>({
      nameSection: '',
      idCurrentSection: '',
      descripcionAnioSection: '',
      idAnio: '',
    });
  const [reFetchRequest, setReFetchRequest] = useState(false);
  const enabled = useDateValidator('Proyectar');

  const [alumnoProyectado, setAlumnoProyectado] = useState<
    {
      idSeccion: string;
      cantidad: number;
    }[]
  >([]);
  const [savingProyeccionIEL, setSavingProyeccionIEL] = useState(false);

  const CURRENT_YEAR = cicloLectivo?.anio || 0;

  const filterProyeccionEstado = useMemo(() => {
    const filter = {
      nivel: [{ path: 'nivel.idNivel', value: nivel }],
      localizacion: [
        { path: 'localizacion.idLocalizacion', value: localizacionId },
      ],
    };

    return rol === ROL.SUPERVISOR.toString() ? filter : {};
  }, [rol, nivel, localizacionId]);

  const { data: configuraciones = [] } = useConfiguracionContext();

  const { data: proyeccionEstado = [], reFetch: proyeccionEstadoReFetch } =
    useProyeccionEstadoLibbyFetch({
      filter: filterProyeccionEstado,
      orderBy: 'cicloLectivo.anio',
      direction: 'desc',
    });

  // Llamo a las seccionProyeccion del colegio una vez nada mas
  const {
    data: seccionProyeccion = [],
    working,
    recall: recallSeccionProyeccion,
  } = useSeccionWithProyeccionLibbyCall({
    methodName: 'fetch',
    params: [],
    noAutoCall: true,
    aspect: 'proyeccion',
  });

  useEffect(() => {
    const filter = {
      filter: {
        anio: [
          {
            path: 'anio',
            value: [Number(formValues.anio), Number(formValues.anio) + 1],
            method: 'in',
          },
        ],
        cicloLectivo: [
          {
            path: 'cicloLectivo.anio',
            value: [CURRENT_YEAR, Number(CURRENT_YEAR) + 1],
            method: 'in',
          },
        ],
        ...(rol === ROL.SUPERVISOR.toString() && {
          nivel: [
            {
              path: 'nivel',
              value: nivel,
            },
          ],
          localizacion: [
            {
              path: 'localizacion',
              value: localizacionId,
            },
          ],
        }),
      },
      orderBy: 'anio.numeroAnio,anio.descripcionAnio',
      limit: 500,
    };
    if (
      CURRENT_YEAR &&
      !!proyeccionEstado[0]?.cicloLectivo &&
      Boolean(formValues.anio)
    ) {
      recallSeccionProyeccion(filter);
    }
  }, [
    CURRENT_YEAR,
    formValues.anio,
    localizacionId,
    nivel,
    proyeccionEstado,
    recallSeccionProyeccion,
    rol,
  ]);

  const {
    data: disableFinalizar = {
      proyectados: false,
      finalizado: false,
      notas: false,
      fecha: false,
      resultado: false,
      vacantes: false,
    },
    request,
    working: proyeccionCustomWorking,
  } = useCopySeccionesGetRequest<Promocion.DisableProyeccionResponse>({
    url: `/api/public/proyeccioncustom?idLocalizacion=${localizacionId}`,
    autoCall: true,
  });

  const filterLocalizacion = useMemo(
    () => ({
      0: [{ path: 'seccionOrigen.localizacion', value: localizacionId }],
      1: [
        {
          path: 'cicloLectivo',
          value: proyeccionEstado[0]?.cicloLectivo.idCicloLectivo,
        },
      ],
      2: [
        {
          path: 'seccionOrigen.cicloLectivo.anio',
          value: Number(CURRENT_YEAR),
        },
      ],
    }),
    [CURRENT_YEAR, localizacionId, proyeccionEstado],
  );
  const {
    data: proyecciones = [],
    working: wPromotion,
    reFetch,
  } = useProyeccionLibbyFetch({
    limit: 100000,
    aspect: 'default',
    filter: filterLocalizacion,
    enabled: !!proyeccionEstado[0]?.cicloLectivo,
  });

  const {
    data: proyeccionIEL = [],
    working: proyeccionIELWorking,
    recall: proyeccionIELRecall,
  } = useProyeccionIELLibbyCall({
    methodName: 'getProyeccionIEL',
  });

  const { request: requestProyeccionIELBulkSave } =
    useProyeccionIELBulkCustomPostRequest();

  const {
    data: alumnosMatriculados = [],
    working: matriculadosWorking,
    recall: recallAlumnoMovimiento,
  } = useAlumnoMovimientoLibbyCall({
    methodName: 'fetch',
    noAutoCall: true,
    aspect: 'matriculacion_promocion_matriculados',
  });

  const {
    data: proyeccionMatriculacion = [],
    working: workingProyeccionMatriculacion,
    recall: recallProyeccionMatricula,
  } = useProyeccionMatriculacionLibbyCall({
    methodName: 'fetch',
    aspect: 'matriculados_secciones_proyectadas',
    noAutoCall: true,
  });

  useEffect(() => {
    if (!CURRENT_YEAR) return;

    const filterAlumnoMovimiento = {
      filter: {
        0: [{ path: 'seccion.cicloLectivo.anio', value: Number(CURRENT_YEAR) }],
        1: [
          {
            path: 'seccion.localizacion.idLocalizacion',
            value: localizacionId,
          },
        ],
      },
      limit: 10000,
      aspect: 'matriculacion_promocion_matriculados',
    };
    const filterProyeccionMatricula = {
      filter: {
        0: [
          {
            path: 'seccionDestino.localizacion',
            value: localizacionId,
          },
        ],
        1: [
          {
            path: 'cicloLectivo.anio',
            value: CURRENT_YEAR,
          },
        ],
        2: [
          {
            path: 'seccionDestino.nivel',
            value: nivel,
          },
        ],
      },
      limit: 1000,
      aspect: 'matriculados_secciones_proyectadas',
    };
    recallAlumnoMovimiento(filterAlumnoMovimiento);
    recallProyeccionMatricula(filterProyeccionMatricula);
  }, [
    CURRENT_YEAR,
    localizacionId,
    nivel,
    recallAlumnoMovimiento,
    recallProyeccionMatricula,
  ]);

  const {
    inProgress,
    efectivizar,
    enabled: efectivizacionHabilitada,
  } = useEfectivizar(
    proyecciones,
    proyeccionIEL,
    seccionProyeccion,
    disableFinalizar.resultado,
    proyeccionEstado[0],
  );

  // Validacion para habilitar efectivizacion
  const { data: nextCicloLectivo = [], working: nextCicleWorking } =
    useCicloLectivoLibbyCall({
      methodName: 'getByAnio',
      params: [(cicloLectivo?.anio || moment().year()) + 1],
      noAutoCall: !Boolean(cicloLectivo),
    });

  const filterPeriodos = useMemo(() => {
    const tipoPeriodo =
      Number(nivel) === NIVEL.INICIAL
        ? TIPO_PERIODO.PRIMER_CUATRIMESTRE
        : TIPO_PERIODO.PRIMER_BIMESTRE;

    return {
      ciclo: [
        { path: 'cicloLectivo', value: nextCicloLectivo[0]?.idCicloLectivo },
      ],
      nivel: [{ path: 'nivel', value: nivel }],
      tipo: [{ path: 'tipoPeriodo.idTipoPeriodo', value: tipoPeriodo }],
    };
  }, [nextCicloLectivo, nivel]);

  const { data: periodos = [], working: periodoWorking } =
    usePeriodoNuevoLibbyFetch({
      filter: filterPeriodos,
      enabled: nextCicloLectivo.length > 0 || !nextCicleWorking,
    });

  const isDiabledEfectivizacion = useMemo(() => {
    const efectivizacionConfig = configuraciones.find(
      (config) =>
        config.tipoConfiguracion.idTipoConfiguracion ===
        TIPO_CONFIGURACION.EFECTIVIZACION,
    );

    // Si viene true, deja inhabilitado el boton de efectivizar
    if (efectivizacionConfig?.disabled) return true;
    // Si viene true, ignora la validacion por periodo
    if (efectivizacionConfig?.active) return false;
    if (periodoWorking) return true;
    // Si no hay periodo deshabilitar
    if (periodos.length === 0) return true;

    const fechaInicioCL = moment(periodos[0].fechaInicio, 'YYYY-MM-DD').format(
      'YYYY-MM-DD',
    );
    const today = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD');
    const isBeforeInicioCL = moment(today, 'YYYY-MM-DD').isBefore(
      fechaInicioCL,
    );

    // Si no ha iniciado el CL del siguiente año no es posible efectivizar
    return isBeforeInicioCL;
  }, [configuraciones, periodoWorking, periodos]);
  // ----

  const { data: alumnoMovimiento = [] } =
    useLibbyCall<Promocion.CountAlumnoMovimientoResponse>({
      daoName: 'alumno_movimiento',
      aspect: 'matriculacion_secciones_alumnoCount',
      methodName: 'getCountBySeccionFilter',
      noAutoCall: !localizacionId,
      params: [
        {
          0: [
            {
              path: 'seccion.localizacion',
              value: localizacionId,
            },
          ],
        },
        'matriculacion_secciones_alumnoCount',
      ],
    });

  const finalizarProyeccion = useFinalizarProyeccion({
    proyeccionEstado: proyeccionEstado[0],
    proyeccionEstadoReFetch,
    recallSeccionProyeccion,
    request,
  });

  const disableFinalizarProyeccionBtn = useMemo(() => {
    const proyeccionConfig = configuraciones.find(
      (config) =>
        config.tipoConfiguracion.idTipoConfiguracion ===
        TIPO_CONFIGURACION.PROYECCION,
    );
    // Si viene true, deja inhabilitado el boton de proyectar
    if (proyeccionConfig?.disabled) return true;
    // Si viene true, ignora la validacion por fechas u otro
    // if (proyeccionConfig?.active) return false

    if (enabled) {
      // la restricción por el localizacionId queda hasta un aviso
      return (
        !disableFinalizar.vacantes ||
        !disableFinalizar.proyectados ||
        proyeccionEstado[0]?.proyeccionEstadoTipo?.idProyeccionEstadoTipo !==
          1 ||
        alumnosMatriculados.length === 0
      );
    } else {
      return true;
    }
  }, [
    configuraciones,
    enabled,
    disableFinalizar.vacantes,
    disableFinalizar.proyectados,
    proyeccionEstado,
    alumnosMatriculados.length,
  ]);

  const handleChange = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const seccionFiltered = useMemo(() => {
    const currentYearSections: SeccionWithProyeccion[] = [];
    const nextYearCurrentSections: SeccionWithProyeccion[] = [];
    const nextYearSections: SeccionWithProyeccion[] = [];
    seccionProyeccion.forEach((element) => {
      if (
        element?.cicloLectivo?.anio === CURRENT_YEAR &&
        element.anio.idAnio === formValues?.anio
      ) {
        currentYearSections.push(element);
      } else if (
        element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1 &&
        element?.anio?.idAnio === formValues?.anio + 1
      ) {
        nextYearSections.push(element);
      } else if (
        element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1 &&
        element?.anio?.idAnio === formValues?.anio
      ) {
        nextYearCurrentSections.push(element);
      } else if (
        (formValues?.anio.toString() === '15' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1 &&
          element?.anio?.idAnio.toString() === formValues?.anio.toString()) ||
        (element?.anio?.idAnio.toString() === '19' &&
          formValues?.anio.toString() === '15' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1)
      ) {
        nextYearSections.push(element);
      } else if (
        (formValues?.anio.toString() === '19' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1 &&
          element?.anio?.idAnio.toString() === formValues.anio.toString()) ||
        (element.anio.idAnio.toString() === '15' &&
          formValues?.anio.toString() === '19' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1) ||
        (element?.anio?.idAnio.toString() === '14' &&
          formValues?.anio.toString() === '19' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1)
      ) {
        nextYearSections.push(element);
      } else if (
        (formValues?.anio.toString() === '14' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1 &&
          element?.anio?.idAnio.toString() ===
            (formValues?.anio + 1).toString()) ||
        (element?.anio?.idAnio.toString() === '19' &&
          formValues?.anio.toString() === '14' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1) ||
        (element?.anio?.idAnio.toString() === '18' &&
          formValues?.anio.toString() === '14' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1) ||
        (formValues?.anio.toString() === '14' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1 &&
          element?.anio?.idAnio.toString() === formValues?.anio.toString())
      ) {
        nextYearSections.push(element);
      } else if (
        (formValues?.anio.toString() === '18' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1 &&
          element?.anio?.idAnio.toString() === formValues?.anio.toString()) ||
        (element?.anio?.idAnio.toString() === '18' &&
          formValues?.anio.toString() === '13' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1) ||
        (element?.anio?.idAnio.toString() === '18' &&
          formValues?.anio.toString() === '13' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1)
      ) {
        nextYearSections.push(element);
      } else if (
        (formValues.anio.toString() === '13' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1 &&
          element?.anio?.idAnio.toString() === formValues?.anio.toString()) ||
        (element?.anio?.idAnio.toString() === '19' &&
          formValues.anio.toString() === '13' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1) ||
        (element?.anio?.idAnio.toString() === '18' &&
          formValues?.anio.toString() === '13' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1) ||
        (element?.anio?.idAnio.toString() === '17' &&
          formValues?.anio.toString() === '13' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1)
      ) {
        nextYearSections.push(element);
      } else if (
        (formValues.anio.toString() === '12' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1 &&
          element.anio.idAnio.toString() === formValues.anio.toString()) ||
        (element.anio.idAnio.toString() === '17' &&
          formValues.anio.toString() === '12' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1) ||
        (element.anio.idAnio.toString() === '16' &&
          formValues.anio.toString() === '12' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1)
      ) {
        nextYearSections.push(element);
      } else if (
        (formValues?.anio.toString() === '17' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1 &&
          element?.anio?.idAnio.toString() === formValues?.anio.toString()) ||
        (element?.anio?.idAnio.toString() === '12' &&
          formValues?.anio.toString() === '17' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1) ||
        (element?.anio?.idAnio.toString() === '16' &&
          formValues?.anio.toString() === '17' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1)
      ) {
        nextYearSections.push(element);
      } else if (
        (formValues?.anio.toString() === '11' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1 &&
          element?.anio?.idAnio === formValues.anio) ||
        (formValues?.anio.toString() === '11' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1 &&
          element?.anio?.idAnio.toString() === '16') ||
        (element?.anio?.idAnio.toString() === '17' &&
          formValues?.anio.toString() === '12' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1)
      ) {
        nextYearSections.push(element);
      } else if (
        (formValues?.anio.toString() === '10' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1 &&
          element?.anio?.idAnio.toString() === formValues?.anio.toString()) ||
        (formValues?.anio.toString() === '10' &&
          element?.cicloLectivo?.anio === Number(CURRENT_YEAR) + 1 &&
          element?.anio?.idAnio.toString() === '16')
      ) {
        nextYearSections.push(element);
      }
    });
    return { currentYearSections, nextYearCurrentSections, nextYearSections };
  }, [CURRENT_YEAR, formValues.anio, seccionProyeccion]);

  const todasNextSections = useMemo(() => {
    const nSection = seccionProyeccion?.filter(
      (seccion) =>
        seccion?.cicloLectivo?.anio ===
        seccionFiltered?.currentYearSections[0]?.cicloLectivo?.anio + 1,
    );
    return nSection;
  }, [seccionProyeccion, seccionFiltered]);

  const { columns, seccionesSiguienteCicloLectivo } = usePromocionColumns({
    alumnoMovimiento,
    proyecciones,
    setSelectedSecction,
    proyeccionEstado,
    seccionFiltered,
    todasNextSections,
    esDEI,
    rol,
    proyeccionIEL,
    escuelasCBOyREINGRESO,
  });

  const filterColumn = columns.filter((column) => {
    if (column.id !== 'busy_projected' && column.id !== 'vacancy_projected')
      return column;
  });

  useEffect(() => {
    if (proyeccionMatriculacion.length === 0) return;
    const alumnoFilter = proyeccionMatriculacion.reduce<{
      [k: string]: number;
    }>((contador, proyeccion) => {
      const idSeccion = proyeccion?.seccionDestino?.idSeccion;
      contador[idSeccion] = (contador[idSeccion] || 0) + 1;
      return contador;
    }, {});
    const seccionesFilter = Object.entries(alumnoFilter).map(
      ([idSeccion, cantidad]) => ({
        idSeccion,
        cantidad,
      }),
    );

    setAlumnoProyectado(seccionesFilter);
  }, [proyeccionMatriculacion]);

  const seccionesCicloLectivoActualConEstadoProyeccion = useMemo(() => {
    const seccionesEstadoProyeccion = seccionFiltered.currentYearSections.map(
      (seccion) => {
        let tempSeccion: Partial<Promocion.InfoTableRow> = {
          ...seccion,
        };
        const proyeccionesSeccion = proyecciones.filter(
          (proyeccion) =>
            proyeccion.seccionOrigen.idSeccion === seccion.idSeccion,
        );
        const alumnosMatriculadosDeLaSeccion = alumnosMatriculados?.filter(
          (alumnoMovimiento) =>
            alumnoMovimiento.seccion.idSeccion === seccion.idSeccion,
        );

        const isTodosProyectados =
          alumnosMatriculadosDeLaSeccion.length > 0 &&
          alumnosMatriculadosDeLaSeccion?.every((alumnoMovimiento) => {
            return proyecciones.some(
              (proyeccion) =>
                alumnoMovimiento?.alumno.idAlumno ===
                proyeccion.alumno.idAlumno,
            );
          });

        const alumnosEnProceso = proyeccionesSeccion.some(
          (proyeccion) => proyeccion.estadoPaseAnio?.idEstadoPaseAnio === 4,
        );

        const someArticula = proyeccionesSeccion.some(
          (proyeccion) => proyeccion.articula,
        );

        tempSeccion.hasPendingPase = function (pases: SolicitudPases[]) {
          if (pases.length === 0) return false;
          const idAlumnos = alumnosMatriculadosDeLaSeccion.map(
            (am) => am.alumno.idAlumno,
          );

          const PENDIENTE = 3;

          return (
            idAlumnos.filter(
              (id) =>
                pases
                  .filter(
                    (pase) =>
                      pase.estadoSolicitudPases.idEstadoSolicitudPases ===
                      PENDIENTE,
                  )
                  .map((pase) => pase.alumno.idAlumno)
                  .indexOf(id) !== -1,
            ).length > 0
          );
        };

        tempSeccion.estadoProyeccion = {
          proyectada: isTodosProyectados,
          descripcion: isTodosProyectados ? 'Proyectada' : 'No proyectada',
          alumnosEnProceso,
        };
        tempSeccion.articula = someArticula;
        tempSeccion.continuaApoyo = proyeccionesSeccion.some(
          (proyeccion) =>
            proyeccion.estadoPaseAnio.idEstadoPaseAnio === CONTINUA_CON_APOYO,
        );
        return tempSeccion;
      },
    );

    if (orderByProyeccionEstado) {
      seccionesEstadoProyeccion.sort((seccionA, seccionB) => {
        const descripcionEstadoProyeccionA =
          seccionA.estadoProyeccion?.descripcion || '';
        const descripcionEstadoProyeccionB =
          seccionB.estadoProyeccion?.descripcion || '';
        if (descripcionEstadoProyeccionA > descripcionEstadoProyeccionB)
          return directionProyeccionEstado === 'asc' ? -1 : 1;
        if (descripcionEstadoProyeccionA < descripcionEstadoProyeccionB)
          return directionProyeccionEstado === 'asc' ? 1 : -1;
        return 0;
      });
    }

    return seccionesEstadoProyeccion;
  }, [
    alumnosMatriculados,
    directionProyeccionEstado,
    orderByProyeccionEstado,
    proyecciones,
    seccionFiltered.currentYearSections,
  ]);

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    if (newOrderBy === 'projection_status') {
      setOrderByProyeccionEstado(true);
      setDirectionProyeccionEstado(
        directionProyeccionEstado === 'asc' ? 'desc' : 'asc',
      );
    } else {
      setDirection(newDirection);
      setOrderByProyeccionEstado(false);
    }
  };

  const onPromotionDetail = () => {
    setReFetchRequest(true);
    reFetch();
    request();
    setReFetchRequest(false);
  };

  const isModalDisabled = useMemo(() => {
    const hasNextYearSections = seccionesSiguienteCicloLectivo.some(
      (seccion) => seccion.cicloLectivo.anio === Number(CURRENT_YEAR) + 1,
    );
    return seccionesSiguienteCicloLectivo.length === 0 || !hasNextYearSections;
  }, [seccionesSiguienteCicloLectivo, CURRENT_YEAR]);

  const workingAll = useMemo(() => {
    return (
      working ||
      wPromotion ||
      reFetchRequest ||
      matriculadosWorking ||
      proyeccionCustomWorking ||
      workingProyeccionMatriculacion
    );
  }, [
    matriculadosWorking,
    proyeccionCustomWorking,
    reFetchRequest,
    wPromotion,
    working,
    workingProyeccionMatriculacion,
  ]);

  const handleShowModalProyeccion = useCallback(() => {
    customFormDialog.show({
      title: `Proyecciones ${Number(CURRENT_YEAR) + 1}`,
      renderComponent: (
        <Grid>
          {!workingAll && (
            <ModalProyeccion
              columns={columns.filter(
                (column) =>
                  column.id !== 'projection_status' && column.id !== 'alert',
              )}
              rows={seccionesSiguienteCicloLectivo}
              proyecciones={proyecciones}
              working={false}
              nivel={nivel}
            />
          )}
        </Grid>
      ),
      sizeWidth: 'lg',
    });
  }, [
    CURRENT_YEAR,
    columns,
    seccionesSiguienteCicloLectivo,
    proyecciones,
    nivel,
    workingAll,
  ]);

  const rowsToShow = useMemo(() => {
    return workingAll ? [] : seccionesCicloLectivoActualConEstadoProyeccion;
  }, [seccionesCicloLectivoActualConEstadoProyeccion, workingAll]);

  const rowToShow = rowsToShow.sort((a, b) => {
    const divisionA = a.division ? a.division.toLowerCase() : '';
    const divisionB = b.division ? b.division?.toLowerCase() : '';

    if (divisionA < divisionB) {
      return -1;
    }
    if (divisionA > divisionB) {
      return 1;
    }
    return 0;
  });

  const { data: efectivizacionCheck } = useEfectivizacionCustomGetRequest<[]>({
    url: `/api/public/efectivizacioncustom?localizacion=${localizacionId}&cicloLectivo=${CURRENT_CICLO_LECTIVO}`,
    autoCall:
      !!CURRENT_CICLO_LECTIVO &&
      Number(nivel) === level.SECUNDARIO &&
      !!localizacionId,
  });

  const { results, currentPage, totalPages, nexPage } = usePaginate({
    pageSize: 15,
    acumulate: true,
    items: rowToShow,
    filter: (seccion) => {
      let matchAnio = true;
      let matchJornada = true;
      let matchTurno = true;
      if (formValues.anio) {
        matchAnio =
          seccion?.anio?.idAnio.toString() === formValues.anio.toString();
      }
      if (formValues.jornada) {
        matchJornada = seccion.jornada === formValues.jornada;
      }
      if (formValues.turno) {
        matchTurno =
          seccion?.turno?.idTurno.toString() === formValues.turno.toString();
      }
      const isSearch = makeSearchFilter(searchPaths, searchDebounce)(seccion);
      return matchAnio && matchJornada && matchTurno && isSearch;
    },
  });

  const jornadaShow = useMemo(() => {
    const _jodanas: JORNADA[] = [];

    seccionProyeccion.forEach(({ jornada }) => {
      if (!_jodanas.includes(jornada as JORNADA)) {
        _jodanas.push(jornada as JORNADA);
      }
    });

    const formattedJornadas = _jodanas.map((jornada) => {
      return {
        label: jornada,
      };
    });

    return formattedJornadas;
  }, [seccionProyeccion]);

  const turnoShow = useMemo(() => {
    const _turnos: Turno[] = [];
    seccionProyeccion.forEach(({ turno }) => {
      const turn = _turnos.find((t) => t.idTurno === turno.idTurno);
      if (!turn) {
        _turnos.push(turno);
      }
    });

    return _turnos;
  }, [seccionProyeccion]);

  const informarIEL = useCallback(async () => {
    const content =
      'Esta a punto de realizar una acción que solo podrá realizar una vez y es irreversible. El proceso no podrá deshacerse.';
    const confirm = await confirmDialog.show({
      title: 'Informar a IEL',
      content,
      confirmText: 'Aceptar',
      cancelText: 'Volver',
    });
    if (confirm) {
      try {
        setSavingProyeccionIEL(true);
        await requestProyeccionIELBulkSave({});
        proyeccionIELRecall();
        enqueueSnackbar('Se informó con éxito IEL', {
          variant: 'success',
        });
        setSavingProyeccionIEL(false);
        setShowText(false);
        proyeccionEstadoReFetch();
      } catch (e) {
        setSavingProyeccionIEL(false);
        console.error(e);
        enqueueSnackbar('Ha ocurrido un error', {
          variant: 'error',
        });
      }
    }
  }, [
    requestProyeccionIELBulkSave,
    enqueueSnackbar,
    proyeccionIELRecall,
    proyeccionEstadoReFetch,
  ]);

  const cantInfomIEL = useMemo(() => {
    const validacion =
      ((proyeccionIEL.length > 0 ||
        proyeccionIELWorking ||
        savingProyeccionIEL ||
        proyeccionEstado[0]?.proyeccionEstadoTipo?.idProyeccionEstadoTipo !==
          2 ||
        ![ROL.DIRECTIVO, ROL.EQUIPO_CONDUCCION].includes(Number(rol))) &&
        Number(rol) !== ROL.DIRECCION_AREA) ||
      (Number(rol) === ROL.DIRECCION_AREA &&
        proyeccionEstado[0]?.proyeccionEstadoTipo?.idProyeccionEstadoTipo !==
          2);
    return validacion;
  }, [
    proyeccionIEL.length,
    proyeccionIELWorking,
    savingProyeccionIEL,
    proyeccionEstado,
    rol,
  ]);

  const efectivizacionCheckCustomSecundaria = useMemo(() => {
    if (
      Number(nivel) === level.SECUNDARIO &&
      proyeccionEstado[0]?.proyeccionEstadoTipo?.idProyeccionEstadoTipo ===
        ProyeccionEstadoDAO.FINALIZADA &&
      !escuelasCBOyREINGRESO.includes(localizacionId) &&
      efectivizacionCheck?.length > 0
    )
      return false;
    return true;
  }, [efectivizacionCheck, localizacionId, nivel, proyeccionEstado]);
  const buttonConfig: ButtonTypesProps[] = [
    {
      title:
        proyeccionEstado[0]?.proyeccionEstadoTipo?.idProyeccionEstadoTipo ===
        ProyeccionEstadoDAO.PENDIENTE
          ? 'Finalizar Proyección'
          : 'Proyección finalizada',
      handleOnClick: () => finalizarProyeccion(),
      disabled: disableFinalizarProyeccionBtn,
    },
  ];

  if (
    (Number(nivel) === level.INICIAL &&
      proyeccionEstado[0]?.localizacion?.establecimiento?.dependenciaFuncional
        ?.idDependenciaFuncional === 41) ||
    Number(nivel) === level.PRIMARIO ||
    Number(nivel) === level.SECUNDARIO
  )
    buttonConfig.push({
      title: savingProyeccionIEL ? (
        <Loading className={styleClasses.buttonLoading} />
      ) : proyeccionEstado[0]?.proyeccionEstadoTipo?.idProyeccionEstadoTipo ===
          ProyeccionEstadoDAO.FINALIZADA && cantInfomIEL ? (
        'Vacantes a IEL informadas'
      ) : (
        'Informar Vacantes a IEL'
      ),
      handleOnClick: () => informarIEL(),
      disabled: cantInfomIEL,
    });
  const showIELTooltip =
    !efectivizacionHabilitada &&
    proyeccionIEL?.length === 0 &&
    Number(nivel) === 1 &&
    !initialSchoolsToRemove.includes(Number(localizacionId));

  const vacantesIELInformadas =
    (proyeccionEstado[0]?.proyeccionEstadoTipo?.idProyeccionEstadoTipo ===
      ProyeccionEstadoDAO.FINALIZADA &&
      cantInfomIEL) ||
    (proyeccionEstado[0]?.proyeccionEstadoTipo?.idProyeccionEstadoTipo ===
      ProyeccionEstadoDAO.FINALIZADA &&
      Number(rol) === ROL.DIRECCION_AREA);
  buttonConfig.push({
    title: 'Efectivizar Promoción',
    handleOnClick: () => efectivizar(),
    disabled:
      isDiabledEfectivizacion ||
      !(
        efectivizacionHabilitada &&
        efectivizacionCheckCustomSecundaria &&
        vacantesIELInformadas &&
        pasesSolicitados.length === 0
      ),
    roles: [ROL.EQUIPO_CONDUCCION, ROL.DIRECTIVO, ROL.DIRECCION_AREA],
  });
  const debounceFetMore = debounce(() => {
    nexPage();
  }, 500);

  const handleMore = useCallback(() => {
    if (totalPages <= currentPage) return;
    debounceFetMore();
  }, [currentPage, debounceFetMore, totalPages]);

  const showTextIEL =
    showIELTooltip &&
    proyeccionEstado[0]?.proyeccionEstadoTipo?.idProyeccionEstadoTipo !==
      ProyeccionEstadoDAO.PENDIENTE &&
    showText;

  return !selectedSection.nameSection ? (
    <>
      <Grid container justifyContent="center" alignItems="center">
        <FilterTablesPromotion
          handleChange={handleChange}
          formValues={formValues}
          setSearch={setSearch}
          search={search}
          handleShowModalProyeccion={handleShowModalProyeccion}
          isModalDisabled={isModalDisabled}
          proyeccionEstado={proyeccionEstado[0]}
          turnos={turnoShow}
          jornadas={jornadaShow}
        />
        {Boolean(formValues.anio) || workingAll ? null : (
          <Typography className={styleClasses.messageSearch} variant="h4">
            Seleccione año para buscar
          </Typography>
        )}
        {!workingAll && Boolean(formValues.anio) && (
          <InfoTable
            working={workingAll}
            rows={results}
            columns={filterColumn}
            rowIdKey="id_seccion"
            direction={direction}
            onSortChange={handleRequestSort}
            onBottomScroll={handleMore}
          />
        )}
        {workingAll && <Loading className={`${styleClasses.loader}`} />}
        {inProgress && (
          <Grid
            className={`${styleClasses.customContainer}`}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Loading />
            <div className={`${styleClasses.loadingMessage}`}>
              <span>
                Esta operación puede demorar un poco, por favor espere.
              </span>
            </div>
          </Grid>
        )}
      </Grid>
      {!workingAll && <Footer buttonConfig={buttonConfig} spacing={3} />}
      {showTextIEL && (
        <Grid container direction="row-reverse">
          <Typography style={{ marginRight: 20 }}>
            Falta informar a IEL para efectivizar
          </Typography>
        </Grid>
      )}
    </>
  ) : (
    <PromotionDetail
      reFetchPromotion={reFetch}
      nameSection={selectedSection.nameSection}
      idCurrentSection={selectedSection.idCurrentSection}
      descripcionAnioSection={selectedSection.descripcionAnioSection}
      setSelectedSecction={setSelectedSecction}
      setTabValue={setTabValue}
      onPromotionDetail={onPromotionDetail}
      seccionFiltered={seccionFiltered}
      idAnio={selectedSection.idAnio}
    />
  );
};
