import { MenuItem } from '../../../../commons/const/ui/menu';
import { DevolucionesView } from '../../../../screens/Private/AcapEscuela/components';
import { DevolucionesFormView } from '../../../../screens/Private/AcapEscuela/components/DevolucionesForm';
import { Tabs } from '../../../../screens/Private/AcapEscuela/tabs';
import { DevolucionesDetails } from 'src/screens/Private/AcapEscuela/components/DevolucionesDetails';

export const routes: MenuItem[] = [
  {
    key: 'view',
    basePath: '/devoluciones/:idDevolucion/form',
    target: {
      component: DevolucionesFormView,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'ACAP',
    children: [],
    default: false,
  },
  {
    key: 'view',
    basePath: '/devoluciones/:id/view/:idAlumno',
    target: {
      component: DevolucionesView,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'ACAP',
    children: [],
    default: false,
  },
  {
    key: 'devoluciones',
    basePath: '/devoluciones/:id',
    target: {
      component: DevolucionesDetails,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'ACAP',
    children: [],
    default: false,
  },
  {
    key: 'acap',
    basePath: '/',
    target: {
      component: Tabs,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'ACAP',
    children: [],
    default: true,
  },
];
