import React, { useCallback, useMemo } from 'react';
import moment from 'moment';
import IconButton from '@material-ui/core/IconButton';
import ArrowForwardIosRoundedIcon from '@material-ui/icons/ArrowForwardIosRounded';
import ArrowBackIosRoundedIcon from '@material-ui/icons/ArrowBackIosRounded';
import { Box, makeStyles, TextField } from '@material-ui/core';
import { grey } from 'src/theme/colors';
import typography from 'src/theme/typography';
import cx from 'classnames';
import { PeriodoNuevo } from '../../../../../../app/models';
import { useColegioEfectivizadoContext } from '../../../../Calificaciones/context/EfectivizacionProvider';

const useStyles = makeStyles({
  dateControls: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end',
    width: '100%',
    userSelect: 'none',
  },
  title: {
    color: grey.medium,
    fontWeight: 'normal',
    fontSize: '14px',
    marginBottom: '10px',
    fontFamily: typography.fontFamily,
  },
  text: {
    fontFamily: typography.fontFamily,
    margin: 0,
    fontSize: '13px',
    color: grey.textPrimary,
    textTransform: 'capitalize',
  },
  todaySelector: {
    marginRight: '20px',
  },
  todayButton: {
    height: '44px',
    border: `1px solid ${grey.disabled}`,
    width: '80px',
    display: 'flex',
    justifyContent: 'center',
    borderRadius: 4,
    fontSize: '13px',
    marginLeft: 10,
    marginRight: 10,
  },
  todayButtonDisabled: {
    cursor: 'auto',
    color: '#595959 !important',
  },
  weekSelector: {
    flexWrap: 'nowrap',
    height: '44px',
    //  border: `1px solid ${grey.disabled}`,
    width: '270px',
    paddingLeft: 15,
    paddingRight: 15,
    borderRadius: 4,
    whiteSpace: 'nowrap',
    backgroundColor: 'rgb(237 244 252)',
  },
  daySelector: {
    flexWrap: 'nowrap',
    height: '44px',
    // border: `1px solid ${grey.disabled}`,
    paddingRight: 15,
    borderRadius: 4,
    width: 120,
    whiteSpace: 'nowrap',
    cursor: 'pointer',
    '&.next': {
      paddingLeft: 15,
    },
    backgroundColor: 'rgb(237 244 252)',
  },
  daySelectorDisabled: {
    cursor: 'auto',
  },
  icon: {
    fontSize: 15,
    color: '#38485C',
  },
  iconButton: {
    marginLeft: 10,
  },
  iconDisabled: {
    fontSize: 15,
    color: '8C8C8C',
  },
  selectLabel: {
    fontSize: 16,
    color: '#38485C',
    fontWeight: 'bold',
    fontFamily: 'Nunito',
  },
  selectInput: {
    color: '#004D40',
    fontSize: 12,
    fontFamily: 'Nunito',
    minWidth: 100,
    backgroundColor: 'rgb(237 244 252)',
    border: 'none',
    height: 38,
  },
});

interface SelectorSemanaProps {
  currentWeek: moment.Moment;
  pastDaysOfWeek: number;
  serverDate: string;
  handleChangeWeek: (value: 'next' | 'back' | 'today') => void;
  disableSelectors: boolean;
  periodoNuevos: PeriodoNuevo[];
}

const SELECTED_DAY = {
  LUNES: 0,
  MARTES: 1,
  MIERCOLES: 2,
  JUEVES: 3,
  VIERNES: 4,
  SABADO: 5,
  DOMINGO: 6,
};

export const SelectorSemana = ({
  currentWeek,
  serverDate,
  handleChangeWeek,
  disableSelectors = false,
}: SelectorSemanaProps) => {
  const classes = useStyles();

  const { cicloLectivo } = useColegioEfectivizadoContext();

  const cannotGotToPastWeek = useMemo(() => {
    const periodoActual = cicloLectivo;
    return moment(currentWeek)
      .startOf('isoWeek')
      .isSameOrBefore(periodoActual?.fechaInicio);
  }, [cicloLectivo, currentWeek]);

  const cannotGoNextWeek = useCallback(() => {
    const serverDay = moment(serverDate).format('YYYY-MM-DD');
    if (
      moment(serverDay).isSameOrAfter(moment(currentWeek).startOf('isoWeek')) &&
      moment(serverDay).isSameOrBefore(moment(currentWeek).endOf('isoWeek'))
    ) {
      return true;
    }
    return false;
  }, [currentWeek, serverDate]);

  return (
    <TextField
      label="Semana actual"
      value={
        currentWeek.startOf('isoWeek').format('DD/MM/YYYY') +
        ' | ' +
        currentWeek.endOf('isoWeek').format('DD/MM/YYYY')
      }
      InputLabelProps={{ shrink: true, className: classes.selectLabel }}
      InputProps={{
        endAdornment: (
          <Arrows
            disabledLeft={cannotGotToPastWeek || disableSelectors}
            disabledRight={cannotGoNextWeek() || disableSelectors}
            onLeft={() => handleChangeWeek('back')}
            onRight={() => handleChangeWeek('next')}
          />
        ),
        className: classes.selectInput,
        disableUnderline: true,
        readOnly: true,
      }}
    />
  );
};

interface ArrowProps {
  disabledLeft: boolean;
  disabledRight: boolean;
  onLeft: () => void;
  onRight: () => void;
}

const useStyleArrow = makeStyles((theme) => ({
  arrow: {
    width: 12,
    height: 12,
    fontWeight: 'bold',
  },
  enabled: {
    color: '#38485C',
    cursor: 'pointer',
  },
  disabled: {
    color: '#ADB7C4',
    cursor: 'default',
  },
}));

const Arrows = ({
  disabledLeft,
  disabledRight,
  onLeft,
  onRight,
}: ArrowProps) => {
  const classes = useStyleArrow();
  return (
    <Box display="flex">
      <IconButton disabled={disabledLeft} size="small" onClick={onLeft}>
        <ArrowBackIosRoundedIcon
          className={cx(
            classes.arrow,
            disabledLeft ? classes.disabled : classes.enabled,
          )}
          fontSize="small"
        />
      </IconButton>
      <IconButton disabled={disabledRight} size="small" onClick={onRight}>
        <ArrowForwardIosRoundedIcon
          className={cx(
            classes.arrow,
            disabledRight ? classes.disabled : classes.enabled,
          )}
          fontSize="small"
        />
      </IconButton>
    </Box>
  );
};
