import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Secciones } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'secciones';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idSecciones',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idSecciones',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Secciones
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Secciones,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Secciones.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Secciones>(DAO_NAME, optionsToUse);

// hooks
export const useSeccionesFetchById = fetchById;
export const useSeccionesLibbyCall = libbyCall;
export const useSeccionesDAO = libbyDAO;
export const useSeccionesLibbyFetch = libbyFetch;

// components/hoc
export const SeccionesAutocomplete = entityAutocomplete;
export const SeccionesCall = renderPropCall;
export const SeccionesById = renderPropFetchById;
export const SeccionesSelect = simpleSelect;
export const SeccionesAccordionSelector = accordionSelector;
export const SeccionesSelectedChips = selectedChips;

// context
// ByIdContext
export const useSeccionesByIdContext = useByIdContext;
export const SeccionesByIdProvider = ByIdProvider;
export const SeccionesByIdContext = ByIdContext;
// GeneralContext
export const useSeccionesContext = useGeneralContext;
export const SeccionesProvider = GeneralProvider;
export const SeccionesContext = GeneralContext;
