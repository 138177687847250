import { MenuItem } from '../../../../commons/const/ui/menu';
import { routes } from './routes';

export const informesc: MenuItem = {
  key: 'informesc',
  basePath: '/informes',
  enabled: true,
  icon: 'List',
  text: 'Informes',
  children: routes,
};
