import { Box, Grid, TextField } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useRouteMatch } from 'react-router';
import moment from 'moment';
import { EspaciosSimpDiscipPlan } from '../../../../../../../app/models';
import { OptionsModal } from '../../../../../../../commons/components';
import { makeActionButtons } from './makeActionButtons';

/* const useActionButtons = makeActionButtons(
  'idEspaciosSimpDiscipPlan',
  (row: EspaciosSimpDiscipPlan) => row.simpDiscipPlan.planificacionEstados,
); */

type Row = {
  id: string;
  nombre: string;
  tipo: { id: string; descripcion: string };
  seccion: string;
  bimestre: string;
};

export const useActividadTableColumns = () => {
  const { url } = useRouteMatch();
  /* const actionButtons = useActionButtons({
    urls: {
      edit: `${url}/actividad/alumnos`,
      feedback: `${url}/planificacionfeedback/disciplinar`,
    },
  }); */
  return useMemo(
    () => [
      {
        id: 'espacioCurricular.Area',
        label: 'Nombre',
        width: '20%',
        orderById: 'nombre',
        render: (value: any, row: Row) => row.nombre,
      },
      {
        id: 'tipo',
        label: 'Tipo',
        width: '10%',
        style: {
          whiteSpace: 'nowrap',
        },
        orderById: 'tipo.descripcion',
        render: (value: any, row: Row) => row.tipo.descripcion,
      },
      {
        id: 'espacioCurricular.PlanEstudio',
        label: 'Seccion',
        width: '10%',
        orderById: 'seccion',
        render: (value: any, row: Row) => (
          <Box whiteSpace="nowrap">{row.seccion}</Box>
        ),
      },
      {
        id: 'entrega',
        label: 'Sin entregar',
        width: '10%',
        style: {
          whiteSpace: 'nowrap',
        },
        render: () => '-',
      },
      {
        id: 'calificar',
        label: 'Sin calificar',
        width: '10%',
        style: {
          whiteSpace: 'nowrap',
        },
        render: () => '-',
      },
      {
        id: 'aprovados',
        label: 'Aprobados',
        width: '10%',
        style: {
          whiteSpace: 'nowrap',
        },
        render: () => '-',
      },
      {
        id: 'insuficiente',
        label: 'Insuficientes',
        width: '10%',
        style: {
          whiteSpace: 'nowrap',
        },
        render: () => '-',
      },
      {
        id: 'fecha',
        label: 'Fecha de finalización',
        width: '15%',
        style: {
          whiteSpace: 'nowrap',
        },
        render: () => '-',
      },
      {
        id: 'icono',
        label: '',
        width: '5%',
        style: { textAlign: 'right' },
        hideSortIcon: true,
        render: (value: any, row: EspaciosSimpDiscipPlan) => (
          <Box whiteSpace="nowrap" textAlign="right">
            {/*  <OptionsModal options={actionButtons} data={row} /> */}
          </Box>
        ),
      },
    ],
    [],
  );
};
