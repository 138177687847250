import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useEspacioCurricularByIdContext } from 'src/app/business';
import { LocalizacionById } from '../../../../../../../../app/business';
import { Establishment } from '../../commons/components/Establishment';
import { AreaEspacioCurricularChips } from '../../commons/components/AreaEspacioCurricularChips';

export interface PlanificacionDisciplinarOrientadaHeaderProps {
  localizacionId: string;
}

const planificacionHeaderStyles = makeStyles(() => ({
  container: {
    marginBottom: '20px',
  },
}));

export const PlanificacionDisciplinarOrientadaHeader = ({
  localizacionId,
}: PlanificacionDisciplinarOrientadaHeaderProps) => {
  const { data: espacioCurricular } = useEspacioCurricularByIdContext();
  return (
    <Grid container className={planificacionHeaderStyles().container}>
      <LocalizacionById id={localizacionId}>
        {({ data }) => (
          <Establishment establishmentData={data?.establecimiento} />
        )}
      </LocalizacionById>
      <AreaEspacioCurricularChips
        area={espacioCurricular?.materia.area.descripcion || ''}
        espacioCurricular={espacioCurricular?.descripcion || ''}
      />
    </Grid>
  );
};
