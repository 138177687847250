import React from 'react';
import { useBackButton, useRouteScreenTitle } from 'src/commons/hooks';
import { Grid, Button } from '@material-ui/core';

export const CiudadaniaGlobal = () => {
  useBackButton('/');
  useRouteScreenTitle('Ciudadania global');

  const handleClick = () => {
    window.open('http://ciudadaniaglobal.bue.edu.ar/login', '_blank');
  };

  return (
    <Grid container style={{ padding: 30 }}>
      <Grid item xs>
        <Button color="primary" variant="contained" onClick={handleClick}>
          Ingresar
        </Button>
      </Grid>
    </Grid>
  );
};
