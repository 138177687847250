import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Area } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'area';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idArea',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idArea',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Area
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Area,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Area.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Area>(DAO_NAME, optionsToUse);

// hooks
export const useAreaFetchById = fetchById;
export const useAreaLibbyCall = libbyCall;
export const useAreaDAO = libbyDAO;
export const useAreaLibbyFetch = libbyFetch;

// components/hoc
export const AreaAutocomplete = entityAutocomplete;
export const AreaCall = renderPropCall;
export const AreaById = renderPropFetchById;
export const AreaSelect = simpleSelect;
export const AreaAccordionSelector = accordionSelector;
export const AreaSelectedChips = selectedChips;

// context
// ByIdContext
export const useAreaByIdContext = useByIdContext;
export const AreaByIdProvider = ByIdProvider;
export const AreaByIdContext = ByIdContext;
// GeneralContext
export const useAreaContext = useGeneralContext;
export const AreaProvider = GeneralProvider;
export const AreaContext = GeneralContext;
