import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { Alert, AlertTitle, Color } from '@material-ui/lab';
import { SnackbarKey, SnackbarMessage, useSnackbar } from 'notistack';
import React, { forwardRef, useCallback } from 'react';

interface InternetConnectionToastProps {
  message: SnackbarMessage;
  key: SnackbarKey;
  severity?: Color;
  icon?: React.ReactNode;
  children: React.ReactNode | React.ReactNode[];
}

export const InternetConnectionToast = forwardRef<
  any,
  InternetConnectionToastProps
>(({ key, message, icon, severity, children }, ref) => {
  const { closeSnackbar } = useSnackbar();

  const handleDismiss = useCallback(() => {
    closeSnackbar(key);
  }, [key, closeSnackbar]);

  return (
    <Alert
      ref={ref}
      elevation={6}
      action={
        <IconButton onClick={handleDismiss}>
          <Close />
        </IconButton>
      }
      severity={severity}
      variant="filled"
      icon={icon}
    >
      <AlertTitle>{message}</AlertTitle>
      {children}
    </Alert>
  );
});
