import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { GruposSeccionAlumno } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'grupos_seccion_alumno';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idGruposSeccionAlumno',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idGruposSeccionAlumno',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.GruposSeccionAlumno
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.GruposSeccionAlumno,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.GruposSeccionAlumno.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<GruposSeccionAlumno>(DAO_NAME, optionsToUse);

// hooks
export const useGruposSeccionAlumnoFetchById = fetchById;
export const useGruposSeccionAlumnoLibbyCall = libbyCall;
export const useGruposSeccionAlumnoDAO = libbyDAO;
export const useGruposSeccionAlumnoLibbyFetch = libbyFetch;

// components/hoc
export const GruposSeccionAlumnoAutocomplete = entityAutocomplete;
export const GruposSeccionAlumnoCall = renderPropCall;
export const GruposSeccionAlumnoById = renderPropFetchById;
export const GruposSeccionAlumnoSelect = simpleSelect;
export const GruposSeccionAlumnoAccordionSelector = accordionSelector;
export const GruposSeccionAlumnoSelectedChips = selectedChips;

// context
// ByIdContext
export const useGruposSeccionAlumnoByIdContext = useByIdContext;
export const GruposSeccionAlumnoByIdProvider = ByIdProvider;
export const GruposSeccionAlumnoByIdContext = ByIdContext;
// GeneralContext
export const useGruposSeccionAlumnoContext = useGeneralContext;
export const GruposSeccionAlumnoProvider = GeneralProvider;
export const GruposSeccionAlumnoContext = GeneralContext;
