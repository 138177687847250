import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router';
import { useDebounce } from 'src/commons/hooks/useDebounce';
import { Seccion } from 'src/app/models';
import { useRolesContext } from 'src/context/RolesContext';
import {
  useSeccionLibbyCall,
  useEspacioCurricularByIdContext,
} from '../../../../../app/business';
import { FilterTableActividades } from './components';
import { ActividadTable } from './tables';
import {
  periodos,
  Periodo,
} from '../Planificacion/forms/ArealInterareal/tabs/Actividad';

export type TipoPlanificacion = {
  id: string;
  descripcion: string;
};

export type objTable = {
  id: string;
  nombre: string;
  tipo: string;
  seccion: string;
  bimestre: string;
};

const homeStyles = makeStyles(() => ({
  headerTitle: {
    fontFamily: 'Open Sans',
    fontSize: 24,
    color: '#737373',
  },
  roleHeader: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    justifyContent: 'space-between',
    paddingTop: 34,
    paddingBottom: 15,
  },
}));

const tiposPlanificaciones: TipoPlanificacion[] = [
  {
    id: 'disciplinar',
    descripcion: 'Disciplinar',
  },
  {
    id: 'disciplinarOrientado',
    descripcion: 'Disciplinar Orientado',
  },
  {
    id: 'interdisciplinar',
    descripcion: 'Interdisciplinar',
  },
  {
    id: 'interareal',
    descripcion: 'Areal/Interareal',
  },
];

export const ActividadesTable = () => {
  const { selectedRole } = useRolesContext();
  const { data: espacionCurricular, working } =
    useEspacioCurricularByIdContext();

  const { data: secciones = [], working: seccionesWorking } =
    useSeccionLibbyCall<Seccion[]>({
      methodName: 'getByLocationId',
      params: [selectedRole.localizacionId],
    });

  const seccions = secciones?.map(({ idSeccion, nombreSeccion }: Seccion) => ({
    id: idSeccion,
    title: nombreSeccion,
  }));

  const periodosSelect = periodos?.map(({ id, name }: Periodo) => ({
    id,
    title: name,
  }));

  const [selectValues, setSelectValues] = useState({
    tipoPlanificacion: '',
    seccion: '',
    bimestre: '',
  });
  const [search, setSearch] = useState('');
  const searchDebounced = useDebounce(search, 1000);

  const handleChangeSelect = useCallback(
    (event) => {
      const target = event.target as HTMLInputElement;
      setSelectValues({
        ...selectValues,
        [target.name]: target.value,
      });
    },
    [selectValues],
  );

  const handleChangeSearch = useCallback((event) => {
    const target = event.target as HTMLInputElement;
    setSearch(target.value);
  }, []);

  const arrFilterTables = [
    [
      {
        title: 'Tipo',
        placeholder: 'Seleccioná un tipo',
        labelKey: 'descripcion',
        valueKey: 'id',
        content: tiposPlanificaciones,
        handleChange: handleChangeSelect,
        value: selectValues.tipoPlanificacion,
        name: 'tipoPlanificacion',
      },
      {
        title: 'Sección',
        placeholder: 'Seleccioná una sección',
        labelKey: 'title',
        valueKey: 'id',
        content: seccions,
        handleChange: handleChangeSelect,
        value: selectValues.seccion,
        name: 'seccion',
      },
      {
        title: 'Bimestre',
        placeholder: 'Seleccioná un bimestre',
        labelKey: 'title',
        valueKey: 'id',
        content: periodosSelect,
        handleChange: handleChangeSelect,
        value: selectValues.bimestre,
        name: 'bimestre',
      },
    ],
    [
      {
        input: true,
        handleChange: handleChangeSearch,
        name: 'search',
        placeholder: 'Buscar',
        value: search,
      },
    ],
  ];

  return (
    <>
      {!working && !seccionesWorking && (
        <>
          <FilterTableActividades content={arrFilterTables} />

          <ActividadTable
            espacioCurricular={espacionCurricular}
            selectValues={selectValues}
            secciones={secciones}
            search={search}
          />
        </>
      )}
    </>
  );
};
