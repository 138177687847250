import React, { useMemo } from 'react';
import { stringify } from 'querystring';
import { useOrderByState } from 'src/commons/hooks/useOrderByState';
import { HeaderTable } from '../../../../../commons/components/HeaderTable';
import { useEspacioCurricularByIdContext } from '../../../../../app/business';
import { useEspaciocurricularseccioncustomGetRequest } from 'src/app/business/businessCustomEndpoints/espaciocurricularseccioncustom';
import { InfoTable } from '../../../../../commons/components/InfoTable';
import { useAulasVirtualesTableColumns } from './hooks';
import {
  EspacioCurricular,
  EspacioCurricularSeccion,
} from '../../../../../app/models';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';

export type estadoSeccion = {
  seccion: {
    nombreSeccion: string;
  };
};

interface AulasVirtualesTableProps {
  espacioCurricular?: EspacioCurricular;
  anio: string;
}
export const AulasVirtualesTable = ({
  espacioCurricular,
  anio,
}: AulasVirtualesTableProps) => {
  const { orderBy, direction, onOrderByDirectionChange } = useOrderByState(
    'seccion.nombreSeccion',
  );
  const { cicloLectivo } = useColegioEfectivizadoContext();
  const espacioCurricularCustomFilter = useMemo(() => {
    return stringify({
      'espacioCurricular.localizacion.idLocalizacion':
        espacioCurricular?.localizacion.idLocalizacion,
      'espacioCurricular.materia.anio': anio,
      espacioCurricular: espacioCurricular?.idEspacioCurricular,
      cicloLectivo: cicloLectivo?.idCicloLectivo,
      limit: 100,
    });
  }, [
    espacioCurricular?.localizacion.idLocalizacion,
    espacioCurricular?.idEspacioCurricular,
    anio,
    cicloLectivo?.idCicloLectivo,
  ]);

  const {
    data: espacioCurricularSecciones = [],
    working,
    request,
  } = useEspaciocurricularseccioncustomGetRequest<EspacioCurricularSeccion[]>({
    url: `/api/public/custom/espaciocurricularseccioncustom?${espacioCurricularCustomFilter}`,
    aspect: 'espacioscurriculares_aulasvirtuales',
    autoCall: true,
  });
  espacioCurricularSecciones.sort((a: estadoSeccion, b: estadoSeccion) =>
    a.seccion.nombreSeccion > b.seccion.nombreSeccion ? 1 : -1,
  );
  const columns = useAulasVirtualesTableColumns(request);
  return (
    <>
      <HeaderTable title={espacioCurricular?.localizacion.descripcion || ''} />
      <InfoTable
        rows={espacioCurricularSecciones}
        columns={columns}
        working={working}
        rowIdKey="idSeccion"
        direction={direction}
        orderBy={orderBy}
        onSortChange={onOrderByDirectionChange}
      />
    </>
  );
};
