import React from 'react';
import { Typography, Grid, Chip, makeStyles } from '@material-ui/core';
import { Bimestres, BimestrePlan, Seccion } from 'src/app/models';
import { grey } from 'src/theme/colors';
import { ListItemProperties } from '../../commons';
import { useBimestreDisciplinarLogic } from '../hooks/useBimestreDisciplinarLogic';

const useStyles = makeStyles(() => ({
  textField: {
    fontSize: 14,
    color: grey.textPrimary,
    fontWeight: 600,
  },
  valueField: {
    fontSize: 16,
    color: grey.textPrimary,
    fontWeight: 400,
    marginTop: 8,
    marginBottom: 20,
  },
  containerSeccion: {
    marginTop: 8,
    marginBottom: 20,
  },
}));
export interface BimestreProps {
  prefix: string;
  data: Bimestres;
}
export type Contenidos = {
  id: number;
  descripcion: string;
  subLevel: { id: number; descripcion: string }[];
};

interface subLevelContenido {
  id: string | number;
  subLevel2: Array<number>;
}
interface subLevelTypes {
  id: string | number;
  subLevel: Array<subLevelContenido | any>;
}

export const Bimestre = ({ prefix, data }: BimestreProps) => {
  const classes = useStyles();
  const { getfilters, loading } = useBimestreDisciplinarLogic();
  return (
    <Grid>
      {!loading &&
        data[prefix]?.map((bimestre: BimestrePlan, index: number) => {
          const {
            filterContenidos,
            filterHabilidades,
            filterInstrumentos,
            filterRecursos,
            filterSecciones,
          } = getfilters(bimestre);

          return (
            <Grid container direction="column">
              {!loading && (
                <Grid key={index}>
                  <ListItemProperties
                    title="Habilidades"
                    values={filterHabilidades}
                  />
                  <ListItemProperties
                    title="Contenidos Especifícos"
                    values={filterContenidos}
                  />
                  <ListItemProperties
                    title="Recursos"
                    values={filterRecursos}
                  />
                  <ListItemProperties
                    title="Instrumentos de evaluacion"
                    values={filterInstrumentos}
                  />
                  <Typography className={classes.textField}>
                    Actividad
                  </Typography>
                  <Typography className={classes.valueField}>
                    {bimestre?.actividad}
                  </Typography>
                  <Typography className={classes.textField}>
                    Descripcion
                  </Typography>
                  <Typography className={classes.valueField}>
                    {bimestre?.descripcion}
                  </Typography>
                  <Typography className={classes.textField}>
                    Horas cátedra
                  </Typography>
                  <Typography className={classes.valueField}>
                    {bimestre?.horasCatedra}
                  </Typography>
                  <Typography className={classes.textField}>Sección</Typography>
                  {!loading &&
                    filterSecciones?.map((item: Seccion) => (
                      <Grid
                        container
                        direction="row"
                        className={classes.containerSeccion}
                      >
                        <Chip
                          size="small"
                          label={item?.nombreSeccion}
                          color="secondary"
                        />
                      </Grid>
                    ))}
                </Grid>
              )}
            </Grid>
          );
        })}
    </Grid>
  );
};
