import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { Publicacion } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'publicacion';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPublicacion',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPublicacion',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Publicacion
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Publicacion,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Publicacion.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Publicacion>(DAO_NAME, optionsToUse);

// hooks
export const usePublicacionFetchById = fetchById;
export const usePublicacionLibbyCall = libbyCall;
export const usePublicacionDAO = libbyDAO;
export const usePublicacionLibbyFetch = libbyFetch;

// components/hoc
export const PublicacionAutocomplete = entityAutocomplete;
export const PublicacionCall = renderPropCall;
export const PublicacionById = renderPropFetchById;
export const PublicacionSelect = simpleSelect;
export const PublicacionAccordionSelector = accordionSelector;
export const PublicacionSelectedChips = selectedChips;

// context
// ByIdContext
export const usePublicacionByIdContext = useByIdContext;
export const PublicacionByIdProvider = ByIdProvider;
export const PublicacionByIdContext = ByIdContext;
// GeneralContext
export const usePublicacionContext = useGeneralContext;
export const PublicacionProvider = GeneralProvider;
export const PublicacionContext = GeneralContext;
