import { cloneDeep } from 'lodash';

export const useInformePipValidations = () => {
  const isReadyToClose = (rows: any[]) => {
    const rowsToCheck = cloneDeep(rows);
    let everyRowIsFilled = false;
    everyRowIsFilled = rowsToCheck.every((row) => {
      let testResult = false;
      testResult = row.observaciones !== '' && row.observaciones !== undefined;
      if (
        row.preguntaNumero === '12' &&
        row.observaciones === 'Sí' &&
        (row.archivoAdjunto === '' ||
          row.archivoAdjunto === null ||
          row.archivoAdjunto === undefined)
      ) {
        testResult = false;
      }
      return testResult;
    });
    return everyRowIsFilled;
  };

  return { isReadyToClose };
};
