import React from 'react';
import { makeStyles } from '@material-ui/core';
import { useField } from 'react-final-form-hooks';
import { Input, InputProps } from '../../../app/components/inputs/Input';
import { getErrorAndHelperTextNoTranslation } from '../../../utils/materialHelpers';
import { AnyObject } from 'src/commons';

// FIXME: type form props
export interface FormInputProps {
  name: string;
  label?: string;
  form: any;
  inputProps?: InputProps;
  placeholder?: string;
  type?: string;
  disabled?: boolean;
  disableErrorMessage?: boolean;
  validation?: (
    value: string | number | AnyObject,
    allValues: AnyObject,
  ) => string | undefined | void;
}

export const FormInput = ({
  type,
  name,
  form,
  label,
  disabled,
  placeholder,
  inputProps = {},
  disableErrorMessage,
  validation = () => {},
}: FormInputProps): JSX.Element => {
  const field = useField(name, form, validation);
  const errorMessage =
    !disableErrorMessage && getErrorAndHelperTextNoTranslation(field);

  return (
    <Input
      placeholder={placeholder}
      label={label}
      disabled={disabled}
      {...field.input}
      {...errorMessage}
      {...inputProps}
      type={type}
    />
  );
};
