import { Grid, Typography, makeStyles } from '@material-ui/core';
import _, { cloneDeep, get } from 'lodash';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useAlumnoMovimientoLibbyFetch,
  useGruposInscripcionesDAO,
  useGruposInscripcionesOfertaLibbyFetch,
} from 'src/app/business';
import {
  AlumnoMovimiento,
  GruposInscripciones,
  GruposSelecciones,
} from 'src/app/models';
import { Footer, InfoTable, useTabBarSetValue, Loading } from 'src/commons';
import { useAccionesDoubleTableColumns } from '../hooks/useAccionesDoubleTableColumns';
import { useAccionesSeleccionadasColumns } from '../hooks/useAccionesSeleccionadasColumns';
import { InscripcionAccionesSeleccionHeader } from './inscripcionAccionesSeleccionHeader';
import { useRolesContext } from 'src/context/RolesContext';
import { Orientacion } from '../../../../app/models/orientaciones';
import { AccionSeleccionada } from '../types';

const useStyles = makeStyles({
  buttonLoading: {
    height: '20px',
    padding: '0',
    display: 'flex',
    alignContent: 'center',
    width: '50px',
  },
});

interface InscripcionAccionesSeleccionadasProps {
  accionSeleccionadaRow: AccionSeleccionada;
  alumnosInscripciones: GruposInscripciones[];
  uniquesOrientacionesEscuela: Orientacion[];
  alumnosEscuelaMixtoCheck: AlumnoMovimiento[];
  workingAlumnosInscripciones: boolean;
  gruposSelecciones?: GruposSelecciones[];
  setAccionSeleccionadaRow: React.Dispatch<
    React.SetStateAction<AccionSeleccionada | null>
  >;
  reFetchGruposInscripciones: () => void;
}

export const InscripcionAccionesSeleccionadas = ({
  accionSeleccionadaRow,
  setAccionSeleccionadaRow,
  reFetchGruposInscripciones,
  uniquesOrientacionesEscuela,
  alumnosEscuelaMixtoCheck,
  alumnosInscripciones,
  workingAlumnosInscripciones,
  gruposSelecciones,
}: InscripcionAccionesSeleccionadasProps) => {
  const [filters, setFilters] = useState<any>();
  const [checkStudent, setCheckStudent] = useState<AlumnoMovimiento[]>([]);
  const {
    selectedRole: { localizacionId },
  } = useRolesContext();

  const [restoreFlag, setRestoreFlag] = useState(false);
  const [AlumnosMovimientosExistentes, setAlumnosMovimientosExistentes] =
    useState<any>([]);
  const [initialState, setInitialState] = useState<any>();
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [finalFilters, setFinalFilters] = useState<any>();
  const [direction, setDirection] = useState<'asc' | 'desc'>('desc');
  const setTabBarValue = useTabBarSetValue();
  const [doubleTableOrderId, setDoubleTableOrderId] = useState<string>(
    'alumno.persona.nombre',
  );
  const [directionSecond, setDirectionSecond] = useState<'asc' | 'desc'>(
    'desc',
  );
  const [doubleTableOrderIdSecond, setDoubleTableOrderIdSecond] =
    useState<string>('alumno.persona.nombre');

  const classes = useStyles();
  const saveSouldBeDisabled = useMemo(() => {
    return _.isEqual(initialState, checkStudent);
  }, [checkStudent, initialState]);

  const hasExceededTheLimit = useMemo(() => {
    const cupo = accionSeleccionadaRow?.ofertaGrupo?.ofertaTurno?.cupo || 0;
    const cantGrupo =
      accionSeleccionadaRow?.ofertaGrupo?.ofertaTurno?.cantGrupos || 0;
    const div = cantGrupo === 0 ? 0 : cupo / cantGrupo;
    return checkStudent.length > div;
  }, [
    accionSeleccionadaRow?.ofertaGrupo?.ofertaTurno?.cantGrupos,
    accionSeleccionadaRow?.ofertaGrupo?.ofertaTurno?.cupo,
    checkStudent.length,
  ]);

  const gruposInscripcionesDAO = useGruposInscripcionesDAO();

  const filterGruposInscripcionesOferta = useMemo(
    () => ({
      0: [
        {
          path: 'ofertaGrupo.idOfertaGrupo',
          value: accionSeleccionadaRow?.ofertaGrupo?.idOfertaGrupo,
        },
      ],
      notLocalizacion: [
        {
          path: 'alumnoMovimiento.seccion.localizacion',
          value: 0,
          method: 'higher',
        },
      ],
    }),
    [accionSeleccionadaRow?.ofertaGrupo?.idOfertaGrupo],
  );

  const { data: alumnosInscriptos = [], working: workingAlumnosInscriptos } =
    useGruposInscripcionesOfertaLibbyFetch({
      limit: 500,
      filter: filterGruposInscripcionesOferta,
      aspect: 'tab-acciones-seleccionadas',
    });

  const { enqueueSnackbar } = useSnackbar();

  const alumnosInscriptosOrdered = useMemo(() => {
    if (doubleTableOrderIdSecond === 'alumno.persona.nombre') {
      return cloneDeep(checkStudent).sort(
        (alumnoMovimientoA, alumnoMovimientoB) => {
          const alumnoNombreA =
            alumnoMovimientoA.alumno.persona.apellido.toLowerCase();
          const alumnoNombreB =
            alumnoMovimientoB.alumno.persona.apellido.toLowerCase();
          if (alumnoNombreA < alumnoNombreB) {
            return directionSecond === 'desc' ? -1 : 1;
          }
          if (alumnoNombreA > alumnoNombreB) {
            return directionSecond === 'desc' ? 1 : -1;
          }
          return 0;
        },
      );
    }
    if (doubleTableOrderIdSecond === 'alumno.persona.documento') {
      return cloneDeep(checkStudent).sort(
        (alumnoMovimientoA, alumnoMovimientoB) => {
          const alumnoDocumentoA = alumnoMovimientoA.alumno.persona.documento;
          const alumnoDocumentoB = alumnoMovimientoB.alumno.persona.documento;
          if (alumnoDocumentoA < alumnoDocumentoB) {
            return directionSecond === 'desc' ? -1 : 1;
          }
          if (alumnoDocumentoA > alumnoDocumentoB) {
            return directionSecond === 'desc' ? 1 : -1;
          }
          return 0;
        },
      );
    }
    if (doubleTableOrderIdSecond === 'seccion.nombreSeccion') {
      return cloneDeep(checkStudent).sort(
        (alumnoMovimientoA, alumnoMovimientoB) => {
          const alumnoSeccionA = alumnoMovimientoA.seccion.nombreSeccion;
          const alumnoSeccionB = alumnoMovimientoB.seccion.nombreSeccion;
          if (alumnoSeccionA < alumnoSeccionB) {
            return directionSecond === 'desc' ? -1 : 1;
          }
          if (alumnoSeccionA > alumnoSeccionB) {
            return directionSecond === 'desc' ? 1 : -1;
          }
          return 0;
        },
      );
    }
  }, [checkStudent, directionSecond, doubleTableOrderIdSecond]);

  const alumnosInscriptosOrderedFiltered = alumnosInscriptosOrdered?.filter(
    (alum) => alum.seccion.localizacion.idLocalizacion === localizacionId,
  );

  useEffect(() => {
    const alumnosMovimientos = alumnosInscriptos?.map(
      (alumnoInscripto: any) => {
        return {
          ...alumnoInscripto?.alumnoMovimiento,
          idGrupoInscripcion: alumnoInscripto?.idGrupoInscripcion,
        };
      },
    );
    setCheckStudent(alumnosMovimientos);
    setInitialState(alumnosMovimientos);
    setAlumnosMovimientosExistentes(alumnosMovimientos);
  }, [alumnosInscriptos]);

  const gruposInscripcionesToDelete = useMemo(() => {
    const alumnosToDelete: any = [];
    AlumnosMovimientosExistentes.forEach((alumnoMovimiento: any) => {
      const studentIsChecked = checkStudent.some((check: any) => {
        return check.idAlumnoMovimiento === alumnoMovimiento.idAlumnoMovimiento;
      });

      if (!studentIsChecked) {
        alumnosToDelete.push(alumnoMovimiento);
      }
    });
    return alumnosToDelete;
  }, [AlumnosMovimientosExistentes, checkStudent]);

  const handleSave = async () => {
    if (hasExceededTheLimit) {
      enqueueSnackbar(
        'No es posible guardar porque hay más alumnos seleccionados que cupos',
        { variant: 'error' },
      );
    } else {
      setIsSaving(true);
      try {
        const arrayToSave = checkStudent?.map((alumnoMovimiento: any) => {
          if (Boolean(alumnoMovimiento?.idGrupoInscripcion)) {
            return {
              idGrupoInscripcion: alumnoMovimiento?.idGrupoInscripcion,
              alumnoMovimiento: alumnoMovimiento?.idAlumnoMovimiento,
              ofertaGrupo: accionSeleccionadaRow?.ofertaGrupo?.idOfertaGrupo,
            };
          } else {
            return {
              alumnoMovimiento: alumnoMovimiento?.idAlumnoMovimiento,
              ofertaGrupo: accionSeleccionadaRow?.ofertaGrupo?.idOfertaGrupo,
            };
          }
        });

        if (gruposInscripcionesToDelete.length > 0) {
          for (const alumnoMovimiento of gruposInscripcionesToDelete) {
            await gruposInscripcionesDAO.remove({
              idGrupoInscripcion: alumnoMovimiento?.idGrupoInscripcion,
            });
          }
        }

        const arrayToSaveOnlyNewInscriptos = arrayToSave.filter((alumno) => {
          const alreadyInscripto = AlumnosMovimientosExistentes.find(
            (alumnoMovimientoExistente: AlumnoMovimiento) =>
              alumnoMovimientoExistente.idAlumnoMovimiento ===
              alumno.alumnoMovimiento,
          );
          return !Boolean(alreadyInscripto);
        });

        if (arrayToSaveOnlyNewInscriptos.length > 0) {
          await gruposInscripcionesDAO
            .aspect('tab-inscripciones-filter')
            .save(arrayToSaveOnlyNewInscriptos);
        }

        enqueueSnackbar('Se ha guardado con éxito', {
          variant: 'success',
        });
      } catch (e) {
        enqueueSnackbar('Error al guardar', { variant: 'error' });
      }

      setAccionSeleccionadaRow(null);
      reFetchGruposInscripciones();
      setIsSaving(false);
      setTabBarValue(2);
    }
  };

  let cupo = 0;
  let inscriptosTotales: any;

  if (accionSeleccionadaRow?.ofertaGrupo?.tipoCupo === 'Cerrado') {
    inscriptosTotales = alumnosInscripciones?.filter(
      (alumno: any) =>
        alumno?.ofertaGrupo?.idOfertaGrupo ===
        accionSeleccionadaRow?.ofertaGrupo?.idOfertaGrupo,
    );
    cupo = accionSeleccionadaRow?.ofertaGrupo?.cupoEstatal;
  } else if (accionSeleccionadaRow?.ofertaGrupo?.tipoCupo === 'Mixto') {
    if (
      accionSeleccionadaRow?.ofertaGrupo?.localizacion?.idLocalizacion ===
        localizacionId &&
      accionSeleccionadaRow?.ofertaGrupo?.cupoEstatal > 0
    ) {
      inscriptosTotales = alumnosInscripciones?.filter(
        (alumno: any) =>
          alumno?.ofertaGrupo?.idOfertaGrupo ===
            accionSeleccionadaRow?.ofertaGrupo?.idOfertaGrupo &&
          accionSeleccionadaRow?.ofertaGrupo?.localizacion?.idLocalizacion ===
            localizacionId,
      );
      cupo = accionSeleccionadaRow?.ofertaGrupo?.cupoEstatal;
    } else {
      inscriptosTotales = alumnosInscripciones?.filter(
        (alumno: any) =>
          alumno?.ofertaGrupo?.idOfertaGrupo ===
            accionSeleccionadaRow?.ofertaGrupo?.idOfertaGrupo &&
          alumnosEscuelaMixtoCheck?.find(
            (alumnoMovimiento: AlumnoMovimiento) => {
              return (
                alumnoMovimiento?.idAlumnoMovimiento ===
                alumno?.alumnoMovimiento?.idAlumnoMovimiento
              );
            },
          )?.seccion?.localizacion?.idLocalizacion !==
            accionSeleccionadaRow?.ofertaGrupo?.localizacion?.idLocalizacion,
      );
      cupo =
        accionSeleccionadaRow?.ofertaGrupo?.ofertaTurno?.cupo /
          accionSeleccionadaRow?.ofertaGrupo?.ofertaTurno?.cantGrupos -
        (accionSeleccionadaRow?.ofertaGrupo?.cupoEstatal +
          accionSeleccionadaRow?.ofertaGrupo?.cupoPrivado);
    }
  } else if (accionSeleccionadaRow?.ofertaGrupo?.tipoCupo === 'Abierto') {
    inscriptosTotales = alumnosInscripciones?.filter(
      (alumno: any) =>
        alumno?.ofertaGrupo?.idOfertaGrupo ===
        accionSeleccionadaRow?.ofertaGrupo?.idOfertaGrupo,
    );
    cupo =
      accionSeleccionadaRow?.ofertaGrupo?.ofertaTurno?.cupo /
      accionSeleccionadaRow?.ofertaGrupo?.ofertaTurno?.cantGrupos;
  }

  const inscriptosTotalesOtrasEscuelas = useMemo(() => {
    return inscriptosTotales?.filter((inscripto: any) => {
      return !initialState?.some(
        (inscriptos: any) =>
          inscriptos?.idAlumnoMovimiento ===
          inscripto?.alumnoMovimiento?.idAlumnoMovimiento,
      );
    });
  }, [initialState, inscriptosTotales]);

  const unsavedCheckedStudents = useMemo(() => {
    return checkStudent?.filter((checked: any) => {
      return !alumnosInscripciones?.some(
        (inscriptos: any) =>
          checked?.idAlumnoMovimiento ===
          inscriptos?.alumnoMovimiento?.idAlumnoMovimiento,
      );
    });
  }, [alumnosInscripciones, checkStudent]);

  const buttonConfig: any = [
    {
      title: (
        <span style={{ display: 'flex', alignItems: 'center' }}>
          <b style={{ marginRight: '5px' }}>Cupos Disponibles: </b>
          {workingAlumnosInscriptos ? (
            <Loading className={classes.buttonLoading} />
          ) : Number(cupo) -
              (Number(inscriptosTotalesOtrasEscuelas?.length) +
                Number(checkStudent?.length)) <
            0 ? (
            ''
          ) : (
            Number(cupo) -
            (Number(inscriptosTotalesOtrasEscuelas?.length) +
              Number(checkStudent?.length))
          )}
          {hasExceededTheLimit === true ? <b>0</b> : ''}
        </span>
      ),
      customStyle: { color: 'black !important' },
      disabled: true,
      type: '',
      size: 'medium',
    },
    {
      title: 'Volver',
      handleOnClick: () => setAccionSeleccionadaRow(null),
      size: 'medium',
      disabled: false,
    },
    {
      title: alumnosInscriptos?.length > 0 ? 'Editar' : 'Inscribir',
      handleOnClick: () => handleSave(),
      size: 'medium',
      disabled: saveSouldBeDisabled || workingAlumnosInscriptos,
    },
  ];

  const [filtersRequest, setFiltersRequest] = useState<any>({
    seccion: [],
    actividades: [],
  });

  const handleSearch = () => {
    setFinalFilters(filters);
    setFiltersRequest({
      seccion: [
        {
          path: 'seccion.idSeccion',
          value: filters?.seccion,
        },
      ],
      actividades: [
        {
          path: 'ofertaGrupo.ofertaTurno.oferta.accion.tipoAccion.idTipoAccion',
          value: filters?.actividad,
        },
      ],
      orientacion: [
        {
          path: 'seccion.planEstudioNivel.planEstudio.orientaciones.orientacion.idOrientacion',
          value: filters?.orientacion,
        },
      ],
      turno: [
        {
          path: 'seccion.turno',
          value: filters.turno,
        },
      ],
    });
  };

  const columns = useAccionesSeleccionadasColumns();

  const filterAlumnoMovimiento = useMemo(
    () => ({
      0: [{ path: 'seccion.anio.idAnio', value: 25 }],
      1:
        filtersRequest?.seccion?.length &&
        filtersRequest?.seccion[0]?.value !== undefined &&
        filtersRequest?.seccion[0]?.value !== ''
          ? filtersRequest?.seccion
          : [],
      2:
        filtersRequest?.orientacion?.length &&
        filtersRequest?.orientacion[0]?.value !== undefined &&
        filtersRequest?.orientacion[0]?.value !== ''
          ? filtersRequest?.orientacion
          : [],
      3:
        filtersRequest?.turno?.length &&
        filtersRequest?.turno[0]?.value !== undefined &&
        filtersRequest?.turno[0]?.value !== ''
          ? filtersRequest?.turno
          : [],
    }),
    [filtersRequest],
  );

  const { data: alumnosMovimiento = [], working: alumnosMovimientoWorking } =
    useAlumnoMovimientoLibbyFetch({
      filter: filterAlumnoMovimiento,
      limit: 5000,
    });

  const alumnosMovimientoFiltered = useMemo(() => {
    const alumnosMovimientoFiltered: AlumnoMovimiento[] = [];

    alumnosInscripciones.forEach((alumnoInscripcion) => {
      const validAlumno =
        Number(alumnoInscripcion?.ofertaGrupo?.idOfertaGrupo) ===
        Number(finalFilters?.actividad);

      const validSeccion = Boolean(finalFilters?.seccion)
        ? Number(alumnoInscripcion?.alumnoMovimiento?.seccion?.idSeccion) ===
          Number(finalFilters?.seccion)
        : true;

      const validLocalizacionAlumno =
        alumnoInscripcion?.alumnoMovimiento.seccion.localizacion
          .idLocalizacion === localizacionId;

      const validTurno = Boolean(finalFilters?.turno)
        ? Number(
            alumnoInscripcion?.alumnoMovimiento?.seccion?.turno?.idTurno,
          ) === Number(finalFilters?.turno)
        : true;

      const validOrientacion = Boolean(finalFilters?.orientacion)
        ? alumnoInscripcion?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.accionOrientaciones?.some(
            (accionOrientacion: any) =>
              accionOrientacion?.orientacion?.idOrientacion ===
              finalFilters?.orientacion,
          )
        : true;
      const alreadyInArray = alumnosMovimientoFiltered?.some(
        (alumnoMovimientoFiltered: any) => {
          return (
            alumnoMovimientoFiltered?.idAlumnoMovimiento ===
            alumnoInscripcion?.alumnoMovimiento?.idAlumnoMovimiento
          );
        },
      );
      let objectAlumno = alumnoInscripcion.alumnoMovimiento;

      if (objectAlumno?.seccion === undefined) {
        objectAlumno.seccion = {
          // @ts-ignore
          planEstudioNivel: { planEstudio: { orientaciones: [] } },
        };
      }
      // VERIFICAR
      // @ts-ignore
      objectAlumno.seccion.planEstudioNivel.planEstudio.orientaciones =
        alumnoInscripcion?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.accionOrientaciones;

      if (
        !alreadyInArray &&
        validAlumno &&
        validSeccion &&
        validTurno &&
        validOrientacion &&
        validLocalizacionAlumno
      ) {
        alumnosMovimientoFiltered.push(objectAlumno);
      }
    });

    return alumnosMovimientoFiltered;
  }, [
    alumnosInscripciones,
    finalFilters?.actividad,
    finalFilters?.orientacion,
    finalFilters?.seccion,
    finalFilters?.turno,
    localizacionId,
  ]);

  const columnsDoubleTable = useAccionesDoubleTableColumns(
    checkStudent,
    setCheckStudent,
    restoreFlag,
    setRestoreFlag,
    alumnosMovimiento,
    true,
    cupo,
    accionSeleccionadaRow,
    unsavedCheckedStudents,
    inscriptosTotalesOtrasEscuelas,
  );

  const columnsDouble = useAccionesDoubleTableColumns(
    checkStudent,
    setCheckStudent,
    restoreFlag,
    setRestoreFlag,
    alumnosMovimiento,
    false,
    cupo,
    null,
    unsavedCheckedStudents,
    inscriptosTotalesOtrasEscuelas,
  );

  const alumnosMovimientoFilteredOrderer = useMemo(() => {
    return cloneDeep(alumnosMovimientoFiltered).sort(
      (alumnoMovimientoA, alumnoMovimientoB) => {
        const alumnoNombreA = get(
          alumnoMovimientoA,
          doubleTableOrderId,
        ).toLowerCase();
        const alumnoNombreB = get(
          alumnoMovimientoB,
          doubleTableOrderId,
        ).toLowerCase();

        if (alumnoNombreA < alumnoNombreB) {
          return direction === 'desc' ? -1 : 1;
        }
        if (alumnoNombreA > alumnoNombreB) {
          return direction === 'desc' ? 1 : -1;
        }
        return 0;
      },
    );
  }, [alumnosMovimientoFiltered, direction, doubleTableOrderId]);

  const alumnoMovimientoOrderer = useMemo(() => {
    if (doubleTableOrderId === 'alumno.persona.nombre') {
      return cloneDeep(alumnosMovimiento).sort(
        (alumnoMovimientoA, alumnoMovimientoB) => {
          const alumnoNombreA =
            alumnoMovimientoA.alumno.persona.apellido.toLowerCase();
          const alumnoNombreB =
            alumnoMovimientoB.alumno.persona.apellido.toLowerCase();
          if (alumnoNombreA < alumnoNombreB) {
            return direction === 'desc' ? -1 : 1;
          }
          if (alumnoNombreA > alumnoNombreB) {
            return direction === 'desc' ? 1 : -1;
          }
          return 0;
        },
      );
    }
    if (doubleTableOrderId === 'alumno.persona.documento') {
      return cloneDeep(alumnosMovimiento).sort(
        (alumnoMovimientoA, alumnoMovimientoB) => {
          const alumnoDocumentoA = alumnoMovimientoA.alumno.persona.documento;
          const alumnoDocumentoB = alumnoMovimientoB.alumno.persona.documento;
          if (alumnoDocumentoA < alumnoDocumentoB) {
            return direction === 'desc' ? -1 : 1;
          }
          if (alumnoDocumentoA > alumnoDocumentoB) {
            return direction === 'desc' ? 1 : -1;
          }
          return 0;
        },
      );
    }
    if (doubleTableOrderId === 'seccion.nombreSeccion') {
      return cloneDeep(alumnosMovimiento).sort(
        (alumnoMovimientoA, alumnoMovimientoB) => {
          const alumnoSeccionA = alumnoMovimientoA.seccion.nombreSeccion;
          const alumnoSeccionB = alumnoMovimientoB.seccion.nombreSeccion;
          if (alumnoSeccionA < alumnoSeccionB) {
            return direction === 'desc' ? -1 : 1;
          }
          if (alumnoSeccionA > alumnoSeccionB) {
            return direction === 'desc' ? 1 : -1;
          }
          return 0;
        },
      );
    }
  }, [alumnosMovimiento, direction, doubleTableOrderId]);

  const handleRequestSort = (key: string) => {
    setDirection(direction === 'asc' ? 'desc' : 'asc');
    setDoubleTableOrderId(key);
  };

  const handleRequestSortSecond = (key: string) => {
    setDirectionSecond(directionSecond === 'asc' ? 'desc' : 'asc');
    setDoubleTableOrderIdSecond(key);
  };

  return (
    <>
      <InfoTable rows={[accionSeleccionadaRow]} columns={columns} />
      <InscripcionAccionesSeleccionHeader
        setFilters={setFilters}
        filters={filters}
        handleSearch={handleSearch}
        alumnosInscripciones={alumnosInscripciones}
        workingAlumnosInscripciones={workingAlumnosInscripciones}
        uniquesOrientacionesEscuela={uniquesOrientacionesEscuela}
        gruposSelecciones={gruposSelecciones}
      />
      <Grid container direction="row" justifyContent="space-around" xs={12}>
        <Grid item style={{ marginTop: '30px', width: '45%' }}>
          {Number(cupo) ===
          Number(inscriptosTotalesOtrasEscuelas?.length) +
            Number(checkStudent?.length) ? (
            <Typography style={{ color: 'red', marginLeft: '30px' }}>
              Cupo completo, no se pueden inscribir más alumnos a la actividad
            </Typography>
          ) : (
            ''
          )}
          <InfoTable
            columns={columnsDoubleTable}
            rows={
              alumnosMovimientoWorking || workingAlumnosInscriptos || isSaving
                ? []
                : Boolean(finalFilters?.actividad)
                ? alumnosMovimientoFilteredOrderer
                : alumnoMovimientoOrderer || []
            }
            working={
              alumnosMovimientoWorking || workingAlumnosInscriptos || isSaving
            }
            onSortChange={handleRequestSort}
          />
        </Grid>
        <Grid item style={{ marginTop: '30px', width: '45%' }}>
          <InfoTable
            onSortChange={handleRequestSortSecond}
            columns={columnsDouble}
            rows={alumnosInscriptosOrderedFiltered || []}
          />
        </Grid>
      </Grid>
      <Footer
        buttonConfig={buttonConfig}
        spacing={1}
        customStyle={{
          position: 'sticky',
          bottom: 0,
          marginTop: '15px',
          zIndex: '1000',
        }}
        display="grid"
        gridAutoFlow="column"
        gridTemplateColumns="repeat(1, 50%)"
      />
    </>
  );
};
