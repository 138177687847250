import { MenuItem } from '../../../../commons/const/ui/menu';
import { routes } from './routes';

export const PresentismoMenu: MenuItem = {
  key: 'presentismo',
  basePath: '/presentismo',
  enabled: true,
  icon: 'List',
  text: 'Presentismo',
  children: routes,
  default: true,
};
