import React from 'react';
import { useParams } from 'react-router';
import {
  useBackButton,
  useRouteScreenTitle,
} from '../../../../../../../commons/hooks';
import { EspaciosInterdiscipPlanById } from '../../../../../../../app/business';
import { InterdisciplinarForm } from './InterdisciplinarForm';
import { InterdiciplinarNew } from './components';
import { Loading } from '../../../../../../../commons/components/Loading';
import { useHistory } from 'react-router-dom';

// TODO: definir que hacer con esto
const planificacionTemplateId = 1;
export const Interdisciplinar = () => {
  // Title
  useRouteScreenTitle('Planificación Interdisciplinar');
  const history = useHistory();
  useBackButton(history.location.pathname.match(/.+\d/i)[0]);
  // @ts-ignore
  const { idPlanificacion, idEspacioCurricular } = useParams();

  return idPlanificacion === 'new' ? (
    <InterdiciplinarNew
      templateId={planificacionTemplateId}
      idEspacioCurricular={idEspacioCurricular}
    >
      {({ template, initialValues, working }) =>
        working || !template || !initialValues ? (
          <Loading />
        ) : (
          <InterdisciplinarForm
            initialValues={initialValues}
            template={template}
          />
        )
      }
    </InterdiciplinarNew>
  ) : (
    <EspaciosInterdiscipPlanById id={idPlanificacion} aspect="limit_province">
      {({ data, working }) => {
        delete data?.interdiscipPlan?.cicloLectivo;

        return working || !data ? (
          <Loading />
        ) : (
          <InterdisciplinarForm
            initialValues={data.interdiscipPlan}
            template={data.interdiscipPlan.interdiscipTempPlan}
          />
        );
      }}
    </EspaciosInterdiscipPlanById>
  );
};
