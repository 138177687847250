import React from 'react';
import { Typography, Grid, Chip, makeStyles } from '@material-ui/core';
import { Bimestres, BimestrePlan, Seccion } from 'src/app/models';
import { grey } from 'src/theme/colors';
import { ListItemProperties } from '../../../feedbacks/commons';
import { useBimestreDisciplinarLogic } from '../../../feedbacks/DisciplinarComunFeedback/hooks/useBimestreDisciplinarLogic';

const useStyles = makeStyles(() => ({
  textField: {
    fontSize: 14,
    color: grey.textPrimary,
    fontWeight: 600,
    marginBottom: 20,
  },
  valueField: {
    fontSize: 16,
    color: grey.textPrimary,
    fontWeight: 400,
    marginTop: 8,
    marginBottom: 20,
  },
  valueSeccion: {
    fontSize: 16,
    color: grey.textPrimary,
    fontWeight: 400,
  },
  containerSeccion: {
    marginTop: 8,
    marginBottom: 20,
    overflowWrap: 'break-word',
    '@media (max-width: 1600px)': {
      width: 150,
    },
    '@media (max-width: 1200px)': {
      width: 80,
    },
    '@media (max-width: 900px)': {
      width: 60,
    },
    '@media print': {
      width: '100%',
    },
  },
  itemContainer: {
    border: `1px solid ${grey.normal}`,
    borderRadius: 4,
    marginBottom: 25,
  },
  containerHabilidades: {
    borderRight: `1px solid ${grey.normal}`,
    width: 200,
    '@media (max-width: 1600px)': {
      width: 150,
    },
    '@media (max-width: 1200px)': {
      width: 100,
    },
    '@media (max-width: 900px)': {
      width: 60,
    },
    '@media print': {
      width: '100%',
      marginBottom: 20,
    },
    overflowWrap: 'break-word',
  },
  containerContenidos: {
    borderRight: `1px solid ${grey.normal}`,
    width: 500,
    '@media (max-width: 1600px)': {
      width: 200,
    },
    '@media (max-width: 1200px)': {
      width: 100,
    },
    '@media (max-width: 900px)': {
      width: 80,
    },
    '@media print': {
      width: '100%',
    },
    overflowWrap: 'break-word',
  },
  containerIntrumentos: {
    borderRight: `1px solid ${grey.normal}`,
    width: 230,
    '@media (max-width: 1600px)': {
      width: 200,
    },
    '@media (max-width: 1200px)': {
      width: 110,
    },
    '@media (max-width: 900px)': {
      width: 80,
    },
    '@media print': {
      width: '100%',
    },
    overflowWrap: 'break-word',
  },
  containerDescripcion: {
    borderRight: `1px solid ${grey.normal}`,
    width: 200,
    '@media (max-width: 1600px)': {
      width: 150,
    },
    '@media (max-width: 1200px)': {
      width: 100,
    },
    '@media (max-width: 900px)': {
      width: 60,
    },
    '@media print': {
      width: '100%',
    },
    overflowWrap: 'break-word',
  },
  containerHora: {
    borderRight: `1px solid ${grey.normal}`,
    width: 150,
    '@media (max-width: 1200px)': {
      width: 70,
    },
    '@media (max-width: 900px)': {
      width: 50,
    },
    '@media print': {
      width: '100%',
    },
    overflowWrap: 'break-word',
  },
  containerActividad: {
    borderRight: `1px solid ${grey.normal}`,
    width: 200,
    '@media (max-width: 1600px)': {
      width: 150,
    },
    '@media (max-width: 1200px)': {
      width: 80,
    },
    '@media (max-width: 900px)': {
      width: 60,
    },
    '@media print': {
      width: '100%',
    },
    overflowWrap: 'break-word',
  },
  chip: {
    width: 50,
  },
}));

export type SubLevel = {
  id: number;
  descripcion: string;
};
export interface BimestreProps {
  prefix: string;
  data: Bimestres;
}
export type Contenidos = {
  id: number;
  descripcion: string;
  subLevel: Array<SubLevel>;
};

interface subLevelContenido {
  id: string | number;
  subLevel2: number[];
}
interface subLevelTypes {
  id: string | number;
  subLevel: Array<subLevelContenido | any>;
}

export const Bimestre = ({ prefix, data }: BimestreProps) => {
  const classes = useStyles();
  const { getfilters, loading } = useBimestreDisciplinarLogic();
  const callbackFilterSeccion = (item: Seccion) => (
    <Grid container direction="row" className={classes.containerSeccion}>
      <Typography className={classes.valueSeccion}>
        {item?.nombreSeccion}
      </Typography>
    </Grid>
  );
  return (
    <Grid>
      {!loading &&
        data[prefix].map((bimestre: BimestrePlan, index: number) => {
          const {
            filterContenidos,
            filterHabilidades,
            filterInstrumentos,
            filterRecursos,
            filterSecciones,
          } = getfilters(bimestre);
          return (
            <Grid container>
              {!loading && (
                <Grid
                  item
                  container
                  spacing={2}
                  className={classes.itemContainer}
                  key={index}
                >
                  <Grid
                    item
                    spacing={2}
                    className={classes.containerHabilidades}
                  >
                    <ListItemProperties
                      title="Habilidades"
                      values={filterHabilidades}
                    />
                  </Grid>
                  <Grid item className={classes.containerContenidos}>
                    <ListItemProperties
                      title="Contenidos Especifícos"
                      values={filterContenidos}
                    />
                  </Grid>
                  <Grid item className={classes.containerHabilidades}>
                    <ListItemProperties
                      title="Recursos"
                      values={filterRecursos}
                    />
                  </Grid>
                  <Grid item className={classes.containerIntrumentos}>
                    <ListItemProperties
                      title="Instrumentos de evaluacion"
                      values={filterInstrumentos}
                    />
                  </Grid>
                  <Grid item className={classes.containerActividad}>
                    <Typography className={classes.textField}>
                      Actividad
                    </Typography>
                    <Typography className={classes.valueField}>
                      {bimestre.actividad}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.containerDescripcion}>
                    <Typography className={classes.textField}>
                      Descripcion
                    </Typography>
                    <Typography className={classes.valueField}>
                      {bimestre.descripcion}
                    </Typography>
                  </Grid>
                  <Grid item className={classes.containerHora}>
                    <Typography className={classes.textField}>
                      Horas cátedra
                    </Typography>
                    <Typography className={classes.valueField}>
                      {bimestre.horasCatedra}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.textField}>
                      Sección
                    </Typography>
                    {!loading && filterSecciones?.map(callbackFilterSeccion)}
                  </Grid>
                </Grid>
              )}
            </Grid>
          );
        })}
    </Grid>
  );
};
