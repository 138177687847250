import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Condicion } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'condicion';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idCondicion',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idCondicion',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Condicion
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Condicion,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Condicion.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Condicion>(DAO_NAME, optionsToUse);

// hooks
export const useCondicionFetchById = fetchById;
export const useCondicionLibbyCall = libbyCall;
export const useCondicionDAO = libbyDAO;
export const useCondicionLibbyFetch = libbyFetch;

// components/hoc
export const CondicionAutocomplete = entityAutocomplete;
export const CondicionCall = renderPropCall;
export const CondicionById = renderPropFetchById;
export const CondicionSelect = simpleSelect;
export const CondicionAccordionSelector = accordionSelector;
export const CondicionSelectedChips = selectedChips;

// context
// ByIdContext
export const useCondicionByIdContext = useByIdContext;
export const CondicionByIdProvider = ByIdProvider;
export const CondicionByIdContext = ByIdContext;
// GeneralContext
export const useCondicionContext = useGeneralContext;
export const CondicionProvider = GeneralProvider;
export const CondicionContext = GeneralContext;
