import { get } from 'lodash';

export const makeSearchFilter =
  <T>(filter: string[], value: string) =>
  (item: T) => {
    let isSearch = true;
    if (value) {
      isSearch = filter.some((path) => {
        const _value: string = get(item, path) || '';
        return _value
          .toString()
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase());
      });
    }
    return isSearch;
  };
