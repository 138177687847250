import React from 'react';
import { Grid } from '@material-ui/core';
import { EnsenanzaPlan, PropositosDeEnsenanza } from 'src/app/models';
import { useDisciplinarComunContext } from '../../../forms/PlanificacionDisciplinarComun/components/context';
import { ListItemProperties } from '../../../feedbacks/commons';

export interface EnsenanzasProps {
  data: EnsenanzaPlan;
}
export const Ensenanzas = ({ data }: EnsenanzasProps) => {
  const {
    data: { propositos },
    working,
  } = useDisciplinarComunContext();

  const filterPropositos = data.propositosDeEnsenanzas?.map((_item: string) => {
    const filter = propositos?.find(
      (proposito: PropositosDeEnsenanza) => proposito.idProposito === _item,
    );
    return {
      id: Number(filter?.idProposito),
      descripcion: filter?.descripcion || '',
    };
  });
  filterPropositos.push(...data.adicionales);
  return (
    <Grid container direction="column">
      {!working && <ListItemProperties values={filterPropositos} />}
    </Grid>
  );
};
