import { useCallback, useEffect, useMemo, useState } from 'react';
import { SecundarioCustom } from 'src/app/models';
import { AnyObject } from 'src/commons';
import { LibbyFetchReturn } from 'src/lib/libby';

export const useGetRows = <T = SecundarioCustom>(
  fetch: LibbyFetchReturn<T>,
  search?: string,
) => {
  const [rows, setRows] = useState<Array<T & { nota?: { data: AnyObject } }>>(
    [],
  );
  const { data = [], working: fetchWorking, fetchMore, reFetch } = fetch;

  const hasCalifications = useMemo(
    () => data?.some((obj: T) => Object.keys(obj).includes('nota')),
    [data],
  );

  const working = useMemo(() => fetchWorking, [fetchWorking]);

  const filteredRows = useMemo(() => {
    if (search) {
      return rows.filter((row) => {
        const nombreIncluyeBusqueda = row.nombre
          .toLowerCase()
          .includes(search.toLowerCase());
        const apellidoIncluyeBusqueda = row.apellido
          .toLowerCase()
          .includes(search.toLowerCase());
        return nombreIncluyeBusqueda || apellidoIncluyeBusqueda;
      });
    } else {
      return rows;
    }
  }, [rows, search]);

  const changeRow = useCallback(
    (event: React.ChangeEvent, index: number) => {
      const target = event.target;
      const updateRow = (row: T) => {
        let data = {
          ...row.nota.data,
        };
        if ('otrosApoyos' in row.nota.data && !('otrosApoyos' in target)) {
          delete data.otrosApoyos;
        }
        if (
          !target.value &&
          !Array.isArray(target.value) &&
          typeof target.value !== 'number' &&
          typeof target.value !== 'boolean' &&
          target.value !== ''
        ) {
          target.name in data && delete data[target.name];
          return {
            ...row,
            nota: {
              ...row.nota,
              data,
            },
          };
        }
        return {
          ...row,
          ...(target.name === 'calificacionAnual' && {
            calificacionAnual: target.value,
          }),
          nota: {
            ...row.nota,
            data: {
              ...data,
              [target.name]: target.value,
              ...(Array.isArray(target.value) &&
                target.value.includes('OTROS') && {
                  otrosApoyos: target?.otrosApoyos,
                }),
            },
          },
        };
      };
      const toUpdate = [...filteredRows];
      const newRows = [...rows];
      const indexRow = newRows.findIndex(
        (row) => row.idAlumno === toUpdate[index].idAlumno,
      );
      if (indexRow === -1) return;
      newRows[indexRow] = updateRow(toUpdate[index]);
      setRows(newRows);
    },
    [filteredRows, rows],
  );

  useEffect(() => {
    if (!fetchWorking) {
      const newRows: Array<T & { nota?: { data: AnyObject } }> = [];
      const dataCopy = [...data];
      dataCopy.forEach((calificacion: T & { nota?: { data: AnyObject } }) => {
        if (calificacion.nota) {
          newRows.push(calificacion);
        } else {
          newRows.push({
            ...calificacion,
            nota: {
              data: {},
              calificacion: calificacion.idCalificacion,
              abierto: calificacion.abierto,
            },
          });
        }
      });
      setRows(newRows);
    }
  }, [fetchWorking, data]);

  return {
    rows: filteredRows,
    working,
    changeRow,
    fetchMore,
    reFetch,
    data,
    hasCalifications,
  };
};
