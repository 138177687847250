import React, { useCallback, useEffect, useState } from 'react';
import { SelectProps, TextField, Typography, Chip } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from 'src/theme/colors';
import { FormControl } from '@material-ui/core';
import cx from 'classnames';
import _ from 'lodash';

const useStyles = makeStyles({
  title: {
    marginBottom: 8,
    fontSize: 13,
    fontWeight: 600,
    color: grey.medium,
  },
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 5,
    },
  },
  formControl: {
    margin: 1,
    width: '100%',
    '& .MuiOutlinedInput-input': {
      padding: '7px 1em',
    },
    '& .MuiOutlinedInput-root': {
      border: 'none',
    },
  },
});

// FIXME: type form props and autoComplete
export interface SelectAutoCompleteProps<V = any> {
  name: string;
  title: string;
  multiple?: boolean;
  form?: any;
  errors?: any;
  handleChange: any;
  disabled?: boolean;
  autoCompleteProps?: any;
  customClassNameFormControl?: string;
  options: any[];
  variant?: SelectProps['variant'];
  optionLabelKey: string;
  onFocus?: <T>(event?: React.FocusEvent<T>) => void;
  getOptionSelected?: any;
  valueToOption?: string | number | string[] | number[];
  optionValueKey?: any;
  placeholder?: string;
  customClasses?: any;
  disableCloseOnSelect?: any;
  disableText?: boolean;
  width?: string | number;
}

export const SelectAutoComplete = ({
  name,
  title,
  errors,
  options,
  autoCompleteProps,
  customClassNameFormControl,
  getOptionSelected,
  disabled,
  multiple = false,
  handleChange,
  onFocus,
  optionLabelKey = '',
  variant = 'outlined',
  valueToOption = '',
  optionValueKey,
  placeholder,
  customClasses,
  disableCloseOnSelect = false,
  disableText = false,
  width,
}: SelectAutoCompleteProps): JSX.Element => {
  const getNormalizedError = () => {
    if (errors !== undefined) {
      return errors;
    } else {
      return '';
    }
  };
  const [inputValue, setInputValue] = useState('');
  const [value, setValue] = useState<any>([]);
  const flattenObj = useCallback((ob: any) => {
    let result: any = {};
    for (const i in ob) {
      if (typeof ob[i] === 'object' && !Array.isArray(ob[i])) {
        const temp = flattenObj(ob[i]);
        for (const j in temp) {
          result[`${i}.${j}`] = temp[j];
        }
      } else {
        result[i] = ob[i];
      }
    }
    return result;
  }, []);

  const getOptionLabel = useCallback(
    (option: any) => {
      const flattenOption = flattenObj(option);
      return flattenOption[optionLabelKey] || '';
    },
    [optionLabelKey, flattenObj],
  );

  const originalClasses = useStyles();
  const classes = { ...originalClasses, ...customClasses };
  const newValuesModified = useCallback(
    (newValues, name) => {
      if (!!newValues && !multiple) {
        const flatenNewValues = flattenObj(newValues);
        const flatenData = { value: flatenNewValues[optionValueKey] || '' };
        handleChange(flatenData, name);
        setValue(flatenNewValues);
      } else if (multiple) {
        handleChange(newValues, name);
      }
    },
    [flattenObj, handleChange, multiple, optionValueKey],
  );

  useEffect(() => {
    if (valueToOption && multiple) {
      setValue(valueToOption);
    }
  }, [multiple, valueToOption]);

  useEffect(() => {
    if (!!valueToOption === false) {
      setInputValue('');
      setValue(null);
    }
  }, [name, valueToOption]);

  const onBlur = useCallback(() => {
    if (!value) {
      setTimeout(() => {
        setInputValue('');
      }, 0);
    }
  }, [value]);

  const inputValueValidation = disableText ? '' : inputValue;

  return (
    <div style={{ width: width }}>
      <Typography className={classes.title}>{title}</Typography>
      <FormControl
        variant={variant}
        className={cx(customClassNameFormControl, classes.formControl)}
      >
        <Autocomplete
          classes={{ root: classes.root }}
          disabled={disabled}
          options={options}
          clearOnBlur={true}
          multiple={multiple}
          onChange={(event, newValue) => {
            newValuesModified(newValue, name);
          }}
          value={value}
          inputValue={inputValueValidation}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            handleChange(newInputValue, name, 'onInputChange');
          }}
          id="autoComplete"
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ color: 'red' }}
              helperText={getNormalizedError()}
              variant="outlined"
              placeholder={placeholder}
              onBlur={onBlur}
            />
          )}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          clearText="Borrar"
          closeText="Cerrar"
          loadingText="Cargando..."
          noOptionsText="No hay opciones"
          openText="Abrir"
          {...(autoCompleteProps || {})}
          disableCloseOnSelect
        />
      </FormControl>
    </div>
  );
};
