import { useEffect } from 'react';
import { useFormContext } from '../context/context';
import { usePrevious } from '../../../commons/hooks';

export type Setter = { key: string; value: any };

export const useSetValuesFormOnChange = (value: any, setters: Setter[]) => {
  // por alguna razon si no importamos values rompe
  const { form, values } = useFormContext();
  const previous = usePrevious(value);
  useEffect(() => {
    if (previous !== value) {
      setters.forEach((set: Setter) => {
        form.change(set.key, set.value);
      });
    }
  }, [form, previous, setters, value]);
};
