import { RowsModified } from './RowsModified';

export function ModifyArrayPrototype<T>() {
  const periodos = new Map();
  periodos.set('diciembre', 'diciembre');
  periodos.set('febrero', 'febreroMarzo');
  // eslint-disable-next-line no-extend-native
  Array.prototype.getRowsModified = function (
    arrModified: T[],
    keys: string[],
  ) {
    const original = this;
    if (keys === undefined || !Array.isArray(keys))
      throw new Error('Debe definir la propiedad para comparar.');
    if (arrModified.length !== original.length)
      throw new Error('Los elementos tienen distintas longitud');

    const it = (function* () {
      for (let i = 0; i < arrModified.length; i++) {
        if (typeof arrModified[i] !== 'object')
          throw new Error(
            'El elemento debe ser un objeto para realizar el proceso correctamente.',
          );
        let isModified: number[] = [],
          fields: string[] = [];
        for (const key of keys) {
          const value = arrModified[i][key];
          isModified.push(Number(value !== original[i][key]));
          if (value !== original[i][key]) {
            if (periodos.get('febrero') === key) {
              fields = [...fields, 'idRecuperacionFebreroMarzo'];
            } else {
              fields = [...fields, 'idRecuperacionDiciembre'];
            }
          }
          arrModified[i].fields = fields;
        }
        if (isModified.some((n) => n === 1)) yield arrModified[i];
      }
    })();

    return new RowsModified([...it]);
  };
}
