import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Grid,
  Button,
  makeStyles,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Footer } from 'src/commons';
import customFormDialog from 'src/commons/services/customFormDialog';

export interface MultipleOptionsModalProps {
  options: any[];
  name: string;
  title: string;
  checkedOptions: any[];
  handleSave: any;
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    marginTop: theme.spacing(-3),
    marginRight: theme.spacing(1),
    position: 'relative',
  },

  item: {
    padding: 5,
  },
  items: {
    marginLeft: 40,
    marginBottom: 40,
    display: 'flex',
    flexFlow: 'column',
  },
  title: {
    marginLeft: 40,
    marginBottom: 20,
  },
}));

const handleCloseModal = async () => {
  await customFormDialog.handleCancel();
};

export const MultipleOptionsModal = ({
  options,
  name,
  title,
  checkedOptions = [],
  handleSave,
}: MultipleOptionsModalProps) => {
  const classes = useStyles();
  const [selectedOptions, setSelectedOptions] = useState<{
    name: string;
    value: any[];
  }>({ name: name, value: checkedOptions });

  const handleChange = (event: any) => {
    const option = event.target.name;
    let newSelectedOptions = [...selectedOptions?.value];
    if (newSelectedOptions.includes(option)) {
      const index = newSelectedOptions.indexOf(option);
      newSelectedOptions.splice(index, 1);
    } else {
      if (selectedOptions.value.length < 2) newSelectedOptions.push(option);
    }
    setSelectedOptions({
      name: selectedOptions.name,
      value: [...newSelectedOptions],
    });
  };

  const handleOnSave = useCallback(() => {
    handleSave(selectedOptions);
    handleCloseModal();
  }, [handleSave, selectedOptions]);

  // const valueRow = useMemo(() => {
  // 	if (selectedOptions?.length === 0) {
  // 		return { name: name, value: undefined };
  // 	} else {
  // 		return { name: name, value: selectedOptions };
  // 	}
  // }, [selectedOptions, name]);

  const buttonConfig: any = useMemo(() => {
    return [
      {
        title: 'Salir',
        size: 'medium',
        type: 'secondary',
        handleOnClick: handleCloseModal,
      },
      {
        title: 'Guardar',
        size: 'medium',
        handleOnClick: () => {
          handleOnSave();
        },
      },
    ];
  }, [handleOnSave]);

  return (
    <Grid container>
      <Grid container justify="flex-end">
        <Button className={classes.closeButton} onClick={handleCloseModal}>
          X
        </Button>
      </Grid>
      <Grid container>
        <Grid xs={12} className={classes.title}>
          <Typography>
            {title} <b>(Seleccioná hasta dos opciones)</b>
          </Typography>
        </Grid>
        <Grid className={classes.items}>
          {options.map((option) => (
            <FormControlLabel
              key={option}
              control={
                <Checkbox
                  checked={selectedOptions?.value?.includes(option)}
                  onChange={(event) => handleChange(event)}
                  name={option}
                  color="primary"
                />
              }
              label={option}
            />
          ))}
        </Grid>
      </Grid>
      <Footer buttonConfig={buttonConfig} />
    </Grid>
  );
};
