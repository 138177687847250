import React from 'react';
import { Grid } from '@material-ui/core';
import { PermissionBlocker } from 'src/lib/permission/components/PermissionBlocker';
import { BLOCKER_ID } from 'src/platform/permission/const/BlockerId';
import { FilterTablesEnrolledTypesProps } from '../../../../../../commons/types';
import { grey } from '../../../../../../theme/colors';
import {
  SimpleSelect,
  MainInput,
  MainButton,
} from '../../../../../../commons/components';
import sortAnioSelect from 'src/screens/Private/Matriculation/passManagement/utils/sortAnioSelectContent';

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  containerStyles: { marginTop: 43 },
  searchGrid: { marginTop: 11 },
  inputSearch: { marginTop: 23 },
  counterContainer: {
    paddingBottom: 0,
    paddingTop: 19,
    fontSize: 14,
    color: grey.medium,
    fontFamily: 'Open Sans',
  },
};

export const FilterTablesEnrolled = ({
  year,
  turn,
  formValues,
  search,
  registersCount,
  handleChangeSearch,
  handleChange,
  titleMainButton,
  handleOnClick,
}: FilterTablesEnrolledTypesProps) => {
  const { id_anio, id_turno } = formValues;
  return (
    <>
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        spacing={3}
        style={customStyles.containerStyles}
      >
        <Grid item xs={2}>
          <SimpleSelect
            name="id_anio"
            title="Año"
            placeholder="Seleccioná Año"
            labelKey="descripcionAnio"
            valueKey="idAnio"
            handleChange={handleChange}
            content={sortAnioSelect(year)}
            value={id_anio}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
        <Grid item xs={2}>
          <SimpleSelect
            title="Turno"
            placeholder="Seleccioná Turno"
            labelKey="descripcionTurno"
            valueKey="idTurno"
            content={turn}
            handleChange={handleChange}
            value={id_turno}
            name="id_turno"
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
        {/* <Grid */}
        {/*  item */}
        {/*  xs={2} */}
        {/*  justify="flex-start" */}
        {/*  alignItems="flex-end" */}
        {/*  style={customStyles.counterContainer} */}
        {/* > */}
        {/*  <span> */}
        {/*    {`${registersCount} resultados`} */}
        {/*  </span> */}
        {/* </Grid> */}
        <Grid
          container
          xs={6}
          justify="flex-end"
          spacing={3}
          alignItems="flex-end"
        >
          {titleMainButton && (
            <Grid item xs={3}>
              <MainButton
                title={titleMainButton}
                handleOnClick={handleOnClick}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <MainInput
              type="search"
              handleChange={handleChangeSearch}
              placeholder="Buscar"
              name="search"
              value={search}
              fullWidth
              customStyle={customStyles.inputSearch}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
