import { LibbyFetchDAO } from './LibbyFetchDAO';

export class LevelDAO extends LibbyFetchDAO {
  constructor() {
    super('nivel', 'idNivel');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getNivelesForRepositorios() {
    return this.query().orderBy('idNivel').run();
  }

  getLevelsBySchool(...levels: any) {
    // eslint-disable-next-line
    const query = this.query();
    levels.forEach((level: number, index: number) => {
      query.equals('id_nivel', level);
      levels.length - 1 !== index && query.or();
    });
    return query.limit(0, 10000).run();
  }

  getAdultLevel() {
    return this.query()
      .groupStart()
      .equals('idNivel', 2)
      .or()
      .equals('idNivel', 3)
      .groupEnd()
      .limit(0, 10000)
      .run();
  }
}
