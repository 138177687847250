import React from 'react';

export const useOrganizacionesExcelData = (
  rows: any,
  cantidadAccionesPorOrganizacion: any,
  name: any,
) => {
  const columnHeaders = [
    'Organización',
    'CUIT',
    'Sector',
    'Domicilio',
    'Referente',
    'Teléfono',
    'Mail',
    'Acciones',
  ];

  const rowsForExcel = rows.map((row: any) => {
    const accionFiltered = cantidadAccionesPorOrganizacion?.find(
      (accion: any) => accion?.entity_id_organizacion === row?.idOrganizacion,
    );
    return {
      Organización: row.nombre,
      CUIT: row.cuit,
      Sector: row.sector.nombre,
      Domicilio: row.sedes[0]?.direccion,
      Referente: Boolean(row?.referente?.apellido)
        ? `${row?.referente?.apellido}, ${row?.referente?.nombre}`
        : row?.referente?.nombre,
      Teléfono: row.referente.telefono,
      Mail: row.referente.email,
      Acciones: `${accionFiltered?.countofidaccion ?? 0}`,
    };
  });

  return {
    body: {
      columns: columnHeaders,
      data: rowsForExcel,
    },
    name,
  };
};
