import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { PerfilOrientado } from '../../models/orientaciones';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'perfil_orientado';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPerfilOrientado',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPerfilOrientado',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.PerfilOrientado
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.PerfilOrientado,
      hoc: { ...defaultOptions.hoc, ...businessOptions.PerfilOrientado.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<PerfilOrientado>(DAO_NAME, optionsToUse);

// hooks
export const usePerfilOrientadoFetchById = fetchById;
export const usePerfilOrientadoLibbyCall = libbyCall;
export const usePerfilOrientadoDAO = libbyDAO;
export const usePerfilOrientadoLibbyFetch = libbyFetch;

// components/hoc
export const PerfilOrientadoAutocomplete = entityAutocomplete;
export const PerfilOrientadoCall = renderPropCall;
export const PerfilOrientadoById = renderPropFetchById;
export const PerfilOrientadoSelect = simpleSelect;
export const PerfilOrientadoAccordionSelector = accordionSelector;
export const PerfilOrientadoSelectedChips = selectedChips;

// context
// ByIdContext
export const usePerfilOrientadoByIdContext = useByIdContext;
export const PerfilOrientadoByIdProvider = ByIdProvider;
export const PerfilOrientadoByIdContext = ByIdContext;
// GeneralContext
export const usePerfilOrientadoContext = useGeneralContext;
export const PerfilOrientadoProvider = GeneralProvider;
export const PerfilOrientadoContext = GeneralContext;
