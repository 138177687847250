import { CONTINUA_CON_APOYO } from 'src/app/models';
import Efectivizador, { SingleProyeccion } from './Efectivizador';

export default class EfectivizadorPrimaria extends Efectivizador {
  constructor(proyecciones: SingleProyeccion[], estado?: number) {
    super(proyecciones, estado);
    this.proyecciones = proyecciones;
    this.estado = estado;
  }

  hasSomePending() {
    return this.proyecciones.some(
      (proyeccion) => proyeccion.articula && proyeccion.seccionDestino === null,
    );
  }

  hasSomeIntermediateStatus() {
    return this.proyecciones.some(
      (proyeccion) => proyeccion.status === CONTINUA_CON_APOYO,
    );
  }

  canEfectivizar(): boolean {
    const can = super.canEfectivizar();
    return can && !this.hasSomePending() && !this.hasSomeIntermediateStatus();
  }
}
