import { FormConfig, useForm } from 'react-final-form-hooks';
import { useEffect, useMemo } from 'react';
import { FormApi, SubmissionErrors } from 'final-form';
import { makeFinalFormValidate } from '../validators';
import { Template } from '../types';

export type onSubmitOption = (
  values: any,
  form: FormApi<any>,
  callback?: (errors?: SubmissionErrors) => void,
) =>
  | SubmissionErrors
  | Promise<SubmissionErrors | undefined>
  | undefined
  | void;

export interface TemplateFormOptions {
  initialValues: any;
  onSubmit: onSubmitOption;
  template: Template;
}

export const useTemplateForm = ({
  initialValues,
  onSubmit,
  template,
}: TemplateFormOptions) => {
  const formParams: FormConfig<any> = useMemo(
    () => ({
      initialValues,
      onSubmit,
      validate: makeFinalFormValidate<any>(template),
    }),
    [template, initialValues, onSubmit],
  );
  const form = useForm<any>(formParams);

  useEffect(() => {
    form.form.setConfig('validate', makeFinalFormValidate<any>(template));
  }, [form.form, template]);

  return form;
};
