import React, { useCallback } from 'react';
import {
  Checkbox,
  Grid,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { SimpleSelect } from 'src/commons';
import { ActionContraturno, Filter } from '../Types';
import { usePresentismoContext } from '../../context/Context';

export const useContraturnosRow = (
  filterContraturno: Filter,
  setFilterContraturno: any,
  espaciosCurriculares: any,
  actionContracturno: ActionContraturno,
) => {
  const espaciosCurricularesMap = filterContraturno.idSeccion?.anio?.idAnio
    ? espaciosCurriculares
        ?.map((e: any) => e?.materia)
        .filter(
          (e: any, index: number, self: any[]) =>
            index === self.findIndex((t: any) => t.idMateria === e.idMateria) &&
            e.anio?.idAnio === filterContraturno?.idSeccion?.anio.idAnio,
        )
    : espaciosCurriculares
        .map((e: any) => e?.materia)
        .filter(
          (e: any, index: number, self: any[]) =>
            index === self.findIndex((t: any) => t.idMateria === e.idMateria),
        );

  const useStyles = makeStyles({
    fieldset: {
      '& .MuiInputBase-root > fieldset': {
        border: 0,
      },
    },

    selectInput: {
      backgroundColor: 'rgb(237 244 252)',
    },
    SelectColor: {
      backgroundColor: '#F9F9F9',
      '& .MuiInputBase-root > fieldset': {
        border: 0,
      },
    },
  });

  const classes = useStyles();

  const { propuestasJE } = usePresentismoContext();

  const diasContraturno: string[] = [
    'Lunes',
    'Martes',
    'Miercoles',
    'Jueves',
    'Viernes',
  ];

  const cantidadContraturno = [
    { descripcion: 0 },
    { descripcion: 1 },
    { descripcion: 2 },
  ];

  const onChangeValues = useCallback(
    (checked, dia) => {
      if (checked) {
        setFilterContraturno((prev: any) => {
          return {
            ...prev,
            objContraturnoDia: {
              ...prev.objContraturnoDia,
              [dia]: {
                ...prev.objContraturnoDia[dia],
                checked: checked,
                edit: true,
              },
            },
          };
        });
      } else {
        setFilterContraturno((prev: any) => {
          return {
            ...prev,
            objContraturnoDia: {
              ...prev.objContraturnoDia,
              [dia]: {
                esc1: null,
                esc2: null,
                checked: checked,
                edit: true,
              },
            },
          };
        });
      }
    },
    [setFilterContraturno],
  );

  const handelEspacioCurricular1 = (dia: any, value: any): void => {
    const espacioCurricular = espaciosCurriculares.filter(
      (e: any) => e.materia.idMateria === value.target.value,
    );
    setFilterContraturno((prev: any) => {
      return {
        ...prev,
        objContraturnoDia: {
          ...prev.objContraturnoDia,
          [dia]: {
            ...prev.objContraturnoDia[dia],
            esc1: espacioCurricular[0],
            edit: true,
          },
        },
      };
    });
  };

  const handelEspacioCurricular2 = (dia: any, value: any): void => {
    const espacioCurricular = espaciosCurriculares.filter(
      (e: any) => e.materia.idMateria === value.target.value,
    );
    setFilterContraturno((prev: any) => {
      return {
        ...prev,
        objContraturnoDia: {
          ...prev.objContraturnoDia,
          [dia]: {
            ...prev.objContraturnoDia[dia],
            esc2: espacioCurricular[0],
            edit: true,
          },
        },
      };
    });
  };

  const handelCantidad = (dia: any, value: any): void => {
    const cantidad = value.target.value;
    if (cantidad === 1)
      setFilterContraturno((prev: any) => {
        return {
          ...prev,
          objContraturnoDia: {
            ...prev.objContraturnoDia,
            [dia]: {
              ...prev.objContraturnoDia[dia],
              cantidad: cantidad,
              esc2: null,
              edit: true,
            },
          },
        };
      });
    else if (cantidad === 2)
      setFilterContraturno((prev: any) => {
        return {
          ...prev,
          objContraturnoDia: {
            ...prev.objContraturnoDia,
            [dia]: {
              ...prev.objContraturnoDia[dia],
              cantidad: cantidad,
              edit: true,
            },
          },
        };
      });
    else
      setFilterContraturno((prev: any) => {
        return {
          ...prev,
          objContraturnoDia: {
            ...prev.objContraturnoDia,
            [dia]: {
              ...prev.objContraturnoDia[dia],
              cantidad: cantidad,
              esc1: null,
              esc2: null,
              edit: true,
            },
          },
        };
      });
  };

  const validacionJE = (listaObjetos, idSeccion, dia) => {
    for (let objeto of listaObjetos) {
      if (objeto.seccion.idSeccion === idSeccion) {
        for (let objetoDia of objeto.dias) {
          if (objetoDia.dia.nombre === dia) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const validacionGlobal: boolean = filterContraturno.idSeccion === null;

  const rows = diasContraturno.map((dia: string, index: any) => {
    const diasConAcento = dia === 'Miercoles' ? 'Miércoles' : dia;

    const existeJe = validacionJE(
      propuestasJE,
      filterContraturno?.idSeccion?.idSeccion,
      dia,
    );

    return {
      diasContraturno: `${diasConAcento}`,
      Seleccion: (
        <Grid container>
          <Tooltip
            title={
              existeJe
                ? 'Existe una propuesta de Jornada Extendida para este día'
                : ''
            }
          >
            <Grid item xs={6}>
              <Checkbox
                color="primary"
                inputProps={{ 'aria-label': 'secondary checkbox' }}
                checked={filterContraturno!.objContraturnoDia[dia]?.checked}
                onChange={({ target: { checked } }) =>
                  onChangeValues(checked, dia)
                }
                disabled={validacionGlobal || actionContracturno.presentismo}
              />
            </Grid>
          </Tooltip>
        </Grid>
      ),
      Cantidad: (
        <Grid container>
          <Grid item xs={6}>
            <SimpleSelect
              value={filterContraturno!.objContraturnoDia[dia]?.cantidad || 0}
              labelKey="descripcion"
              valueKey="descripcion"
              name="Cantidad"
              key={`Cantidad-${index}`}
              content={
                existeJe
                  ? cantidadContraturno.filter(
                      (opcion) => opcion.descripcion !== 2,
                    )
                  : cantidadContraturno
              }
              handleChange={(value: any) => {
                handelCantidad(dia, value);
              }}
              disabled={
                !filterContraturno.objContraturnoDia[dia].checked ||
                validacionGlobal ||
                actionContracturno.presentismo
              }
              customClassNameFormControl={classes.SelectColor}
              selectClassName={
                ['Lunes', 'Miercoles', 'Viernes'].includes(dia)
                  ? classes.SelectColor
                  : ''
              }
            />
          </Grid>
          {filterContraturno!.objContraturnoDia[dia]?.checked ? (
            filterContraturno!.objContraturnoDia[dia]?.cantidad !== 0 ? null : (
              <Typography style={{ color: 'red' }}>*</Typography>
            )
          ) : null}
        </Grid>
      ),
      EspacioCurricular1: (
        <Grid container>
          <Grid item xs={10}>
            <SimpleSelect
              value={
                filterContraturno!.objContraturnoDia[dia].esc1?.materia
                  ?.idMateria
              } //
              valueKey="idMateria" //es quien compara contra el content y muestra
              name="Espacio Curricular"
              placeholder=" "
              content={espaciosCurricularesMap}
              labelKey="descripcion" //Label trabaja con el Content (es lo que VES!)
              handleChange={(value: any) => {
                handelEspacioCurricular1(dia, value);
              }}
              disabled={
                validacionGlobal ||
                !filterContraturno!.objContraturnoDia[dia]?.checked ||
                filterContraturno!.objContraturnoDia[dia]?.cantidad ===
                  undefined ||
                filterContraturno!.objContraturnoDia[dia]?.cantidad === 0 ||
                actionContracturno.presentismo
              }
              variant="standard"
              // "standard", "outlined", y "filled".
              customStyleContainer={{
                width: '350px',
              }}
            />
          </Grid>
          {filterContraturno!.objContraturnoDia[dia]?.cantidad === 1 ||
          filterContraturno!.objContraturnoDia[dia]?.cantidad === 2 ? (
            filterContraturno!.objContraturnoDia[dia]?.esc1 !== null ? null : (
              <Typography style={{ color: 'red', marginLeft: '-45px' }}>
                *
              </Typography>
            )
          ) : null}
        </Grid>
      ),
      EspacioCurricular2: (
        <Grid container>
          <Grid item xs={10}>
            <SimpleSelect
              value={
                filterContraturno!.objContraturnoDia[dia].esc2?.materia
                  ?.idMateria
              } //
              valueKey="idMateria" //es quien compara contra el content y muestraultado que el anterior
              labelKey="descripcion"
              name="Espacio Curricular"
              placeholder=" "
              content={espaciosCurricularesMap}
              variant="standard"
              handleChange={(value: any) => {
                handelEspacioCurricular2(dia, value);
              }}
              disabled={
                validacionGlobal ||
                !filterContraturno!.objContraturnoDia[dia]?.checked ||
                filterContraturno!.objContraturnoDia[dia]?.cantidad !== 2 ||
                actionContracturno.presentismo
              }
              // customStyleContainer={{
              //   width: '300px',
              //   border: '0px',
              // }}
              customClassNameFormControl={classes.fieldset}
              customStyleContainer={{
                width: '350px',
              }}

              //disableUnderlin={true}
            />
          </Grid>
          {filterContraturno!.objContraturnoDia[dia]?.cantidad === 2 ? (
            filterContraturno!.objContraturnoDia[dia]?.esc2 !== null ? null : (
              <Typography style={{ color: 'red', marginLeft: '-45px' }}>
                *
              </Typography>
            )
          ) : null}
        </Grid>
      ),
    };
  });

  return { rows };
};
