import moment from 'moment';
import { usePresentismoGestionGetRequest } from 'src/app/business';

interface AlumnoPassValidationArgsV2 {
  alumnosIds: string[];
  fechaFin?: string;
}

export interface AlumnoCheckResultV2 {
  idAlumno: string;
  hasCompletePresentismo: boolean;
  hasGeneratedBoletin: boolean;
}

export const useAlumnoPassValidationV2 = ({
  alumnosIds = [],
  fechaFin = moment().format('YYYY-MM-DD'),
}: AlumnoPassValidationArgsV2) => {
  const {
    data: alumnos = [],
    working,
    request: getStatus,
  } = usePresentismoGestionGetRequest<AlumnoCheckResultV2[]>({
    url: `/api/presentismov3/gestion?fecha_fin=${moment(fechaFin).format(
      'YYYY-MM-DD',
    )}&alumnos=${alumnosIds.join(',')}`,
    autoCall: alumnosIds.length > 0,
  });

  return { alumnos, working };
};
