import React, { useState, useMemo, useEffect } from 'react';
import { Proyeccion, UsuarioRolEstablecimiento } from 'src/app/models';
import { MainButton, calculatedIconFilling } from 'src/commons/components';
import { Checkbox, Grid, IconButton, Tooltip } from '@material-ui/core';
import { primary } from 'src/theme/colors';
import { getByProyeccionStatus } from '../utils/helpers';

export const useProyeccionColumns = (
  data: Proyeccion[],
  filter: any,
  gestionEstablecimientosFiltered: UsuarioRolEstablecimiento[],
) => {
  const [checkedProyecciones, setCheckedProyecciones] = useState<Proyeccion[]>(
    [],
  );
  useEffect(() => {
    if (!!filter?.seccionOrigen || !!filter?.estado || data.length === 0) {
      setCheckedProyecciones([]);
    }
  }, [data.length, filter?.estado, filter?.seccionOrigen]);

  const columns = useMemo(() => {
    const enableCheckbox = (row: Proyeccion) => {
      if (row.articula) {
        if (row.seccionDestino === null) {
          return true;
        } else if (
          row.seccionDestino !== null &&
          gestionEstablecimientosFiltered[0]?.rolUsuario?.descripcion ===
            'Dirección de Área'
        ) {
          return true;
        }
      }
      return false;
    };

    const allChecked = () => {
      const porGestionar = getByProyeccionStatus(data, 'gestionar');
      return (
        porGestionar.length > 0 &&
        porGestionar.length === checkedProyecciones.length
      );
    };

    return [
      {
        id: 'icon',
        width: '1%',
        label: (
          <Checkbox
            checked={allChecked()}
            onChange={(e) => {
              const checked = e.target.checked;
              setCheckedProyecciones(
                checked
                  ? data
                      .filter(
                        (proy: Proyeccion) =>
                          proy.articula && proy.seccionDestino === null,
                      )
                      .map((proy: Proyeccion) => proy)
                  : [],
              );
            }}
            inputProps={{ 'aria-labelledby': 'transfer-list-item-all-label' }}
            color="primary"
          />
        ),
        hideSortIcon: true,
        noSort: true,
        align: 'left',
        render: (_: unknown, row: Proyeccion) => {
          const isChecked = checkedProyecciones.find(
            (element: any) => element.idProyeccion === row.idProyeccion,
          );
          const allChecked = data.length === checkedProyecciones.length;
          return !enableCheckbox(row) ? (
            <Tooltip
              title="Seleccione Pase de año y sección para poder seleccionar"
              arrow
            >
              <Grid>
                <Checkbox disabled={!enableCheckbox(row)} />
              </Grid>
            </Tooltip>
          ) : (
            <Checkbox
              name={row.idProyeccion}
              checked={isChecked || allChecked}
              onChange={() => {
                setCheckedProyecciones(
                  isChecked
                    ? checkedProyecciones.filter(
                        (proy: any) =>
                          Number(row.idProyeccion) !==
                          Number(proy?.idProyeccion),
                      )
                    : [...checkedProyecciones, row],
                );
              }}
              inputProps={{ 'aria-labelledby': 'transfer-list-item-all-label' }}
              color="primary"
              disabled={!enableCheckbox(row)}
            />
          );
        },
      },
      {
        id: 'apellidoNombre',
        label: 'Apellido y Nombre',
        render: (value: any, row: Proyeccion) =>
          `${row.alumno.persona.apellido}, ${row.alumno.persona.nombre}`,
        width: '12%',
        orderById: 'alumno.persona.apellido',
      },
      {
        id: 'doc',
        label: 'Documento',
        render: (value: any, row: Proyeccion) => row.alumno.persona.documento,
        orderById: 'alumno.persona.documento',
      },
      {
        id: 'seccionOrigen.anio.idAnio',
        label: 'Año',
        render: (value: any, row: Proyeccion) =>
          row.seccionOrigen?.anio?.descripcionAnio,
        orderById: 'seccionOrigen.anio.idAnio',
      },
      {
        id: 'turno',
        label: 'Turno',
        render: (value: any, row: Proyeccion) =>
          row.seccionOrigen?.turno?.descripcionTurno,
        width: '5%',
        orderById: 'seccionOrigen.turno',
      },
      {
        id: 'jornada',
        label: 'Jornada',
        render: (value: any, row: Proyeccion) => row.seccionOrigen?.jornada,
        width: '5%',
        orderById: 'seccionOrigen.jornada',
      },
      {
        id: 'divison',
        label: 'Sección origen',
        render: (value: any, row: Proyeccion) =>
          row.seccionOrigen?.nombreSeccion,
        orderById: 'seccionOrigen.nombreSeccion',
      },
      {
        id: 'paseDeAnio',
        label: 'Pase de Año',
        width: '10%',
        render: (value: any, row: any) => (
          <>
            {calculatedIconFilling(
              row.estadoPaseAnio?.descripcionEstadoPaseAnio === 'Promociona',
            )}
            {row.estadoPaseAnio?.descripcionEstadoPaseAnio}
          </>
        ),
        orderById: 'estadoPaseAnio.descripcionEstadoPaseAnio',
      },
      {
        id: 'establecimientoOrigen',
        label: 'Establecimiento de Origen',
        render: (value: any, row: Proyeccion) =>
          row.seccionOrigen?.localizacion?.descripcion,
        width: '10%',
        orderById: 'seccionOrigen.localizacion.descripcion',
      },
      {
        id: 'establecimientoDestino',
        label: 'Establecimiento de Destino',
        render: (value: any, row: Proyeccion) =>
          row.seccionDestino?.localizacion?.descripcion,
        width: '10%',
        orderById: 'seccionDestino.localizacion.descripcion',
      },
      {
        id: 'seccion',
        label: 'Sección Destino',
        render: (value: any, row: Proyeccion) =>
          row.seccionDestino?.nombreSeccion,
        orderById: 'seccionOrigen.nombreSeccion',
      },
      {
        id: 'estado',
        label: 'Estado',
        render: (value: any, row: Proyeccion) => {
          const gestionar = row.seccionDestino === null;

          return (
            <p
              style={{
                color: gestionar ? primary.warning : primary.success,
                fontWeight: 'bold',
              }}
            >
              {gestionar ? 'A Gestionar' : 'Gestionado'}
            </p>
          );
        },
        width: '10%',
        orderById: 'seccionDestino',
      },
    ];
  }, [gestionEstablecimientosFiltered, data, checkedProyecciones]);

  return { columns, checkedProyecciones };
};
