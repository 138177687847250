import React, { FunctionComponent, ReactNode } from 'react';
import { makeStyles } from '@material-ui/styles';
import { Box, Typography } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { PermissionBlocker } from 'src/lib/permission/components/PermissionBlocker';
import { BLOCKER_ID } from 'src/platform/permission/const/BlockerId';
import { useRolesContext } from 'src/context/RolesContext';
import { useTabBarValue } from 'src/commons/components/TabBar';
import { MainButton } from '../MainButton';
import { grey } from '../../../theme/colors';
import { NIVEL } from 'src/commons/const';
import { Loading } from 'src/commons';
interface HeaderTablesTypesProps {
  disabledCopy?: boolean;
  title: string | JSX.Element;
  titleMainButton?: string;
  children?: JSX.Element;
  handleOnClick?(): void;
  onBack?: () => void;
  openSeccionModal?(): void;
  renderLeft?(): ReactNode;
  renderRight?(): ReactNode;
  showCopy?: boolean;
  disabledMain?: boolean;
}

const SECCION_TAB_INDEX = 0;

const useStyles = makeStyles({
  typography: {
    color: grey.medium,
    fontWeight: 'normal',
    verticalAlign: 'center',
  },
});

export const HeaderTable: FunctionComponent<HeaderTablesTypesProps> = ({
  disabledCopy,
  title,
  titleMainButton,
  children,
  handleOnClick,
  renderLeft,
  renderRight,
  onBack,
  openSeccionModal,
  showCopy = true,
  disabledMain = false,
}) => {
  const {
    selectedRole: { nivel },
  } = useRolesContext();

  const tabBarValue = useTabBarValue();
  const tabIndex = tabBarValue?.tabIndex;

  return (
    <>
      <div style={{ width: '100%' }}>
        <Box display="flex" p={2} paddingX={0} alignItems="center">
          <Box p={0} flexGrow={1} flexDirection="row">
            {!renderLeft && (
              // eslint-disable-next-line react-hooks/rules-of-hooks
              <Typography variant="h4" className={useStyles().typography}>
                {onBack && (
                  <ChevronLeft
                    onClick={onBack}
                    fontSize="small"
                    style={{ cursor: 'pointer' }}
                  />
                )}
                {title}
              </Typography>
            )}
            {!!renderLeft && renderLeft()}
          </Box>
          <Box p={0}>
            {titleMainButton && (
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <PermissionBlocker id={BLOCKER_ID.ADD_SECCION}>
                  <MainButton
                    title={titleMainButton}
                    handleOnClick={handleOnClick}
                    disabled={disabledMain}
                  />
                </PermissionBlocker>
                {tabIndex === SECCION_TAB_INDEX && showCopy && (
                  <PermissionBlocker id={BLOCKER_ID.COPY_SECCION}>
                    <MainButton
                      title="Copiar sección"
                      handleOnClick={openSeccionModal}
                      customStyle={{ marginLeft: 10 }}
                      disabled={disabledCopy}
                    />
                  </PermissionBlocker>
                )}
              </div>
            )}
            {!!renderRight && renderRight()}
          </Box>
        </Box>
        {children && (
          <Box display="flex" p={1} paddingX={0}>
            {children}
          </Box>
        )}
      </div>
    </>
  );
};
