import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  makeStyles,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, {
  useCallback,
  useState,
  useMemo,
  useRef,
  useEffect,
} from 'react';
import { Alumno, MotivoExceptuado } from '../../../../../../app/models';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { DateRangePicker } from 'react-date-range';
import { es } from 'date-fns/locale';
import moment from 'moment';
import { IntervalDate } from '../../../types';
import { getDateRangeHeader } from '../../../Justificacion/components';
import customFormDialog from 'src/commons/services/customFormDialog';
import { DateRangeListV3, ExceptuadosDateRangeV3 } from './types';
import confirmDialog from '../../../../../../commons/services/confirmDialog';
import { MotivosExceptuadoSelectInput } from '../../../../../../app/business/presentismo_v3/MotivosExceptuados';
import { ROL } from '../../../../../../commons';
import { useExceptuadosRolLogic } from './hooks/useExceptuadossRolLogic';

interface ExectuadosModalProps {
  initialExceptuados: DateRangeListV3;
  alumno: Alumno;
  isTabJornadaExtendida: boolean;
  rol: string;
}

const useStyles = makeStyles({
  daterange: {
    display: 'flex',
    '& > .rdrDefinedRangesWrapper': {
      display: 'none',
    },
    '& .rdrMonth': {
      padding: 0,
    },
    '& .rdrWeekDay': {
      fontFamily: 'Nunito',
      fontSize: 12,
      color: '#9FA1A4',
      textTransform: 'uppercase',
    },
  },
  selectLabel: {
    fontSize: 16,
    color: '#38485C',
    fontWeight: 'bold',
    fontFamily: 'Nunito',
  },
  selectInput: {
    backgroundColor: '#F3F6F9',
    border: 'none',
    '& > fieldset': {
      border: 'none',
    },
  },
  selectBg: {
    backgroundColor: '#F3F6F9',
  },
  periodos: {
    overflowX: 'hidden',
    height: 300,
    overflowY: 'scroll',
  },
});

export const ExceptuadosModal = ({
  initialExceptuados,
  alumno,
  isTabJornadaExtendida,
  rol,
}: ExectuadosModalProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const clonedList = initialExceptuados.clone();
  const motivosAll = useRef<MotivoExceptuado[]>([]);
  const [tempIntervals, setIntervals] = useState<DateRangeListV3>(clonedList);
  const [focusedRange, setFocusedRange] = useState<[number, number]>([0, 0]);
  const [intervalsToDelete, setIntervalsToDelete] = useState<
    ExceptuadosDateRangeV3[]
  >([]);
  const [error, setError] = useState('');
  const { enabled: isEnableToSave } = useExceptuadosRolLogic(rol);

  const isEnableToDelete = useMemo(() => {
    return rol === ROL.DIRECTIVO.toString();
  }, [rol]);

  const onCancel = useCallback(() => {
    customFormDialog.handleCancel();
  }, []);

  const validateMotivo = useCallback(() => {
    const cloneList = tempIntervals.clone();
    if (!cloneList.list[focusedRange[0]]) return;
    const notHasMotivo =
      cloneList.list[focusedRange[0]].motivoExceptuado?.idMotivoExceptuado ===
      0;
    if (notHasMotivo) {
      setError('Debes seleccionar un motivo');
    } else {
      setError('');
    }
  }, [tempIntervals, focusedRange, setError]);

  useEffect(() => {
    validateMotivo();
  }, [validateMotivo]);

  const onSave = useCallback(() => {
    if (!isEnableToSave) return;
    if (tempIntervals.atLeastOneInvalidInterval()) {
      enqueueSnackbar(
        'No es posible establecer el periodo en el rango seleccionado. Hay solapamiento de períodos',
        {
          variant: 'error',
          autoHideDuration: 4000,
        },
      );
    } else {
      customFormDialog.handleConfirm({ tempIntervals, intervalsToDelete });
    }
  }, [isEnableToSave, tempIntervals, intervalsToDelete, enqueueSnackbar]);

  const addNewInterval = useCallback(() => {
    if (!isEnableToSave) return;

    const clonedList = tempIntervals.clone();
    clonedList.insertEmptyRange(alumno);
    setIntervals(clonedList);
    setFocusedRange([tempIntervals.list.length, 0]);
  }, [isEnableToSave, alumno, setFocusedRange, setIntervals, tempIntervals]);

  const handleRangeClick = useCallback(
    (exceptuado: ExceptuadosDateRangeV3) =>
      (e: React.MouseEvent<HTMLElement>) => {
        if (!isEnableToSave) return;
        const index = tempIntervals.searchByKey(exceptuado.key, 'index');
        setFocusedRange([index as number, 0]);
      },
    [setFocusedRange, tempIntervals, isEnableToSave],
  );

  const deleteHandler = useCallback(
    (index: number) => async (e: React.MouseEvent) => {
      if (!isEnableToSave) return;
      if (!isEnableToDelete) return;
      if (tempIntervals.list[index].idExceptuado) {
        const confirm = await confirmDialog.show({
          title: 'Eliminar régimen',
          content: `Si borra el régimen especial actual, deberá cargar el presentismo en todos los días que había seleccionado nuevamente`,
          confirmText: 'Confirmar',
          cancelText: 'Cancelar',
        });
        if (!confirm) return;
        setIntervalsToDelete([...intervalsToDelete, tempIntervals.list[index]]);
      }
      const listCloned = tempIntervals.clone();
      listCloned.remove(index);
      setIntervals(listCloned);
    },
    [
      setIntervalsToDelete,
      isEnableToDelete,
      tempIntervals,
      intervalsToDelete,
      isEnableToSave,
    ],
  );

  const handleRangeSelect = useCallback(
    (range: { [k: string]: IntervalDate }) => {
      if (!isEnableToSave) return;

      const key = Object.keys(range)[0];
      const updatedExceptuado = tempIntervals.searchByKey(
        key,
      ) as ExceptuadosDateRangeV3;

      if (!updatedExceptuado) return;

      updatedExceptuado.startTime = moment(range[key].startDate).format(
        'YYYY-MM-DD',
      );
      updatedExceptuado.endTime = moment(range[key].endDate).format(
        'YYYY-MM-DD',
      );

      if (tempIntervals.isInvervalOverlapping(updatedExceptuado, key)) {
        enqueueSnackbar(
          'No es posible establecer el periodo en el rango seleccionado',
          {
            variant: 'error',
            autoHideDuration: 2000,
          },
        );
        return;
      }

      tempIntervals.updateRange(
        key,
        moment(range[key].startDate).format(),
        moment(range[key].endDate).format(),
      );
      const clonedList = tempIntervals.clone();
      setIntervals(clonedList);
    },
    [tempIntervals, isEnableToSave, enqueueSnackbar],
  );

  const disabledSave = useMemo(() => {
    if (!isEnableToSave) return true;
    const isEqual = clonedList.isEqualToList(tempIntervals);

    if (isEqual) return true;
    if (error !== '') return true;
    const notHasMotivo = tempIntervals.list.some(
      (exceptuado) => exceptuado.motivoExceptuado?.idMotivoExceptuado === 0,
    );
    if (notHasMotivo) return true;
    return false;
  }, [isEnableToSave, clonedList, tempIntervals, error]);

  const getColor = useCallback(
    (exceptuado: ExceptuadosDateRangeV3, index: number) => {
      const isFocus = focusedRange[0] === index;
      const bgColor = isFocus ? exceptuado.color : 'white';
      const color = isFocus ? 'white' : 'black';
      const border = `solid 2px ${exceptuado.color}`;
      return { bgColor, color, border };
    },
    [focusedRange],
  );

  const handleChangeMotivo = useCallback(
    (e: any) => {
      const value = e.target.value;
      const newMotivo = motivosAll.current.find(
        (motivo) => motivo.idMotivoExceptuado === value,
      );
      tempIntervals.list[focusedRange[0]].motivoExceptuado = newMotivo || {
        idMotivoExceptuado: 0,
        descripcion: 'Seleccioná un motivo',
      };
      setFocusedRange([focusedRange[0], 0]);
    },
    [focusedRange, tempIntervals],
  );

  return (
    <Container>
      <Grid container component={Box} mb={2} direction="column" spacing={2}>
        <Grid item xs={12} container direction="row" alignItems="center">
          <Grid item xs={6}>
            <Box fontFamily="Nunito" fontSize={16} fontWeight="bold">
              {alumno.persona.apellido} {alumno.persona.nombre}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <MotivosExceptuadoSelectInput
              title="Motivo"
              value={
                tempIntervals.list[focusedRange[0]]?.motivoExceptuado
                  ?.idMotivoExceptuado || ''
              }
              labelKey="descripcion"
              valueKey="idMotivoExceptuado"
              fullWidth
              orderBy="descripcion"
              handleChange={handleChangeMotivo}
              onOptionsChange={(values: MotivoExceptuado[]) => {
                motivosAll.current = values;
              }}
              onFocus={() => validateMotivo()}
              onBlur={() => validateMotivo()}
              error={error !== ''}
              helperText={error}
              disabled={tempIntervals.list.length === 0}
              InputLabelProps={{
                className: classes.selectLabel,
                shrink: true,
              }}
              InputProps={{
                className: classes.selectInput,
                disableUnderline: true,
              }}
              SelectProps={{ classes: { root: classes.selectBg } }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container>
          <Grid item xs={6} className={classes.periodos}>
            <Grid
              item
              container
              justifyContent="center"
              direction="column"
              spacing={1}
            >
              <Grid item xs={12} container justifyContent="center">
                <Button
                  size="small"
                  component={(props: any) => <Box {...props} />}
                  onClick={addNewInterval}
                  color="primary"
                  variant="contained"
                  borderRadius={50}
                >
                  Agregar periodo
                </Button>
              </Grid>

              {([...tempIntervals] as ExceptuadosDateRangeV3[]).map(
                (exceptuado, index) => {
                  const { bgColor, color, border } = getColor(
                    exceptuado,
                    index,
                  );
                  return (
                    <Grid
                      item
                      xs={12}
                      key={index}
                      container
                      justifyContent="center"
                    >
                      <Button
                        size="small"
                        component={(props: any) => (
                          <Box
                            {...props}
                            bgcolor={bgColor}
                            color={color}
                            border={border}
                            display="flex"
                            flexDirection="column"
                          />
                        )}
                        color="primary"
                        variant="contained"
                        borderRadius={50}
                        endIcon={
                          <IconButton
                            disabled={!isEnableToDelete}
                            size="small"
                            onClick={deleteHandler(index)}
                          >
                            <HighlightOffOutlinedIcon />
                          </IconButton>
                        }
                        onClick={handleRangeClick(exceptuado)}
                      >
                        <Grid
                          container
                          direction="column"
                          justifyContent="center"
                        >
                          <Box
                            paddingLeft={2}
                            textAlign="center"
                            fontFamily="Nunito"
                            fontSize={14}
                          >
                            {exceptuado.getFormattedInterval()}
                          </Box>
                          <Box
                            paddingLeft={2}
                            textAlign="center"
                            fontFamily="Nunito"
                            fontSize={11}
                            style={{ textTransform: 'none' }}
                          >
                            {exceptuado.getMotivo().descripcion}
                          </Box>
                        </Grid>
                      </Button>
                    </Grid>
                  );
                },
              )}
            </Grid>
          </Grid>

          <Grid
            item
            xs={6}
            container
            justifyContent="center"
            alignItems="center"
          >
            <Grid item>
              <DateRangePicker
                locale={es}
                className={classes.daterange}
                showMonthAndYearPickers={false}
                showDateDisplay={false}
                showPreview={false}
                ranges={[...tempIntervals]}
                onChange={handleRangeSelect}
                staticRanges={[]}
                inputRanges={[]}
                focusedRange={focusedRange}
                navigatorRenderer={getDateRangeHeader}
                weekdayDisplayFormat="eeeee"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          container
          alignItems="center"
          justifyContent="center"
        >
          {intervalsToDelete.length > 0 ? (
            <Box
              fontFamily="Nunito"
              fontSize={14}
              color="#C93B3B"
              bgcolor="#FFF4F1"
              borderColor="#C93B3B"
              padding={2}
            >
              Se borraran periodos actuales, deberá cargar el presentismo en
              todos los días que había seleccionado nuevamente
            </Box>
          ) : (
            <></>
          )}
        </Grid>
        <Grid item xs={12} component={Box} mt={3}>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
          >
            <Grid item>
              <Button variant="text" onClick={onCancel}>
                Cancelar
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={disabledSave}
                onClick={onSave}
              >
                Guardar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
};
