import moment from 'moment';
import { Presente_v3 } from 'src/app/models';
import { ESTADO_PRESENTE } from 'src/commons';
import { PresenteGeneral } from '../../TomaPresentismo/types';
import { capitalizarPalabras } from './functions';

export const getDataS = (periodoFiltrado: PresenteGeneral[]) => {
  const cantidadPresetes = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.PRESENTE,
    )
    .length.toString();

  const cantidadAusentes = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE,
    )
    .length.toString();

  const cantidadAustenesJuistificados = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE &&
        item.justificacion !== null,
    )
    .length.toString();

  const cantidadIngresosTardios = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.INGRESO_TARDIO,
    )
    .length.toString();

  const cantidadIngresosTardiosJustificados = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.INGRESO_TARDIO && item.justificacion !== null,
    )
    .length.toString();

  const cantidadRetirosAnticipados = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente ===
        ESTADO_PRESENTE.RETIRO_ANTICIPADO,
    )
    .length.toString();

  const cantidadRetirosAnticipadosJustificados = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.RETIRO_ANTICIPADO && item.justificacion !== null,
    )
    .length.toString();

  const cantidadAusentesConPresenciaEnClases = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente ===
        ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE,
    )
    .length.toString();

  const cantidadAusentesConPresenciaEnClasesJustificados = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE &&
        item.justificacion !== null,
    )
    .length.toString();

  const sinDatos = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.NO_CORRESPONDE,
    )
    .length.toString();

  const SinJustificacion = periodoFiltrado

    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE ||
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.INGRESO_TARDIO ||
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.RETIRO_ANTICIPADO ||
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE ||
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.NO_CORRESPONDE,
    )
    .filter((item) => item.justificacion === null)
    .length.toString();

  return [
    {
      Nombre: 'Presente',
      CantidadPresente: cantidadPresetes,
      tituloGuion: '-',
      turnoGuion: '-',
    },
    {
      Nombre: 'Ausente',
      CantidadPresente: cantidadAusentes,
      AusentesJustificacion: 'Ausentes Justificación',
      ausentesJustificacioncount: cantidadAustenesJuistificados,
    },
    {
      Nombre: 'Ingreso Tardío',
      CantidadPresente: cantidadIngresosTardios,
      IngresosTardiosJustificacion: 'Ingresos Tardios Justificación',
      ingresosTardiosJustificacioncount: cantidadIngresosTardiosJustificados,
    },
    {
      Nombre: 'Retiro Anticipado',
      CantidadPresente: cantidadRetirosAnticipados,
      RetirosAnticipadosJustificacion: 'Retiros Anticipados Justificación',
      retirosAnticipadosJustificacioncount:
        cantidadRetirosAnticipadosJustificados,
    },
    {
      Nombre: 'Ausente con presencia en clases',
      CantidadPresente: cantidadAusentesConPresenciaEnClases,
      AusentesConPresenciaEnClasesJustificacion:
        'Ausentes con presencia en clases Justificación',
      ausentesConPresenciaEnClasesJustificacioncount:
        cantidadAusentesConPresenciaEnClasesJustificados,
    },
    {
      Nombre: 'No Corresponde',
      CantidadPresente: sinDatos,
      SinJustificacion: 'Sin Justificación',
      sinJustificacioncount: SinJustificacion,
    },
  ];
};

export const getTableSecDataS = (periodoFiltrado: PresenteGeneral[]) => {
  const uniqueDates = [...new Set(periodoFiltrado.map((item) => item.fecha))];

  const columns = ['Día', 'Fecha', 'Presentismo', 'Justificación Ausencia'];

  const data = uniqueDates.map((date) => {
    const itemsForDate = periodoFiltrado.filter((item) => item.fecha === date);

    const diaCapitalizado = capitalizarPalabras(moment(date).format('dddd'));

    return {
      dia: diaCapitalizado,
      fecha: moment(date).format('DD-MM-YYYY'),
      presentismo: itemsForDate[0].estadoPresente.descripcion,
      justificacionAusencia: itemsForDate.some(
        (item) => item.justificacion === null,
      )
        ? 'No'
        : 'Si',
    };
  });

  return { columns, data };
};
