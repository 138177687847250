import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { EspaciosInterdiscipPlan } from '../../../../../../app/models';
import { InfoTable } from '../../../../../../commons/components/InfoTable';
import { useEspaciosInterdiscipPlanLibbyFetch } from '../../../../../../app/business';
import { useInterDisciplinarTableColumns } from './hooks';

export interface InterDisciplinarTableProps {
  idEspacioCurricular: string;
  selectValues: any;
  search: any;
  cicloFilter: {
    disciplinar: string;
    interareal: string;
    interdisciplinar: string;
    value: string;
    method: string;
  };
}

export const InterDisciplinarTable = ({
  idEspacioCurricular,
  selectValues,
  cicloFilter,
  search,
}: InterDisciplinarTableProps) => {
  const columns = useInterDisciplinarTableColumns();
  const [direction, setDirection] = React.useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState(
    'espacioCurricular.materia.area.descripcion',
  );
  const [filter, setFilter] = useState<any>({});

  const filterObj = useCallback(
    (searching) => {
      if (!idEspacioCurricular) {
        setFilter({ searching });
      } else {
        setFilter({
          selectValues,
          searching,
          cicloFilter: cicloFilter?.interdisciplinar
            ? [
                {
                  path: cicloFilter.interdisciplinar,
                  method: cicloFilter.method,
                  value: cicloFilter.value,
                },
              ]
            : [],
        });
      }
    },
    [
      cicloFilter.interdisciplinar,
      cicloFilter.method,
      cicloFilter.value,
      idEspacioCurricular,
      selectValues,
    ],
  );

  useEffect(() => {
    filterObj(search);
  }, [selectValues, search, filterObj]);

  const {
    data: planificaciones = [],
    fetchMore,
    working,
  } = useEspaciosInterdiscipPlanLibbyFetch({
    orderBy,
    direction,
    aspect: 'limit_province',
    filter,
  });

  let planificacionesFiltered: any = [];

  planificaciones.forEach((element: any) => {
    let alreadyInArray = false;

    if (
      element?.espacioCurricular?.idEspacioCurricular === idEspacioCurricular
    ) {
      planificacionesFiltered.push(element);
      alreadyInArray = true;
    }
    if (alreadyInArray === false) {
      element?.interdiscipPlan?.espacioCurricular?.espaciosCurriculares.forEach(
        (espacioCurricular: any) => {
          if (
            espacioCurricular?.espacioCurricular?.id === idEspacioCurricular
          ) {
            planificacionesFiltered.push(element);
          }
        },
      );
    }
  });

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setDirection(newDirection);
    setOrderBy(newOrderBy);
  };

  return (
    <InfoTable
      rows={planificacionesFiltered}
      columns={columns}
      working={working}
      rowIdKey="idEspaciosInterdiscipPlan"
      direction={direction}
      orderBy={orderBy}
      onSortChange={handleRequestSort}
      onBottomScroll={fetchMore}
    />
  );
};
