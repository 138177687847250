import { Grid } from '@material-ui/core';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useCopySeccionesPostRequest,
  useLocalizacionPlanEstudioNivelLibbyCall,
  usePeriodoNuevoLibbyCall,
  useSeccionDAO,
} from 'src/app/business';
import { useMatriculacionSeccionLibbyFetch } from 'src/app/business/matriculacion/MatriculacionSeccion';
import { MatriculacionSeccion } from 'src/app/models';
import {
  HeaderTable,
  InfoTable,
  Loading,
  NIVEL,
  ROL,
  useDebounce,
  useTabBarSetValue,
  useTableSortConfig,
} from 'src/commons';
import { DEFAULT_HIDE_TIME } from 'src/commons/const/ui';
import { useDateValidator } from 'src/commons/hooks/useDateValidator';
import confirmDialog from 'src/commons/services/confirmDialog';
import customFormDialog from 'src/commons/services/customFormDialog';
import { useGeneralContext } from 'src/context';
import { useRolesContext } from 'src/context/RolesContext';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
import { useGetValidYears } from 'src/screens/Private/Calificaciones/hooks/useGetValidYears';
import { useMatriculationContext } from '../../context/Context';
import { flattenPlanesEstudio } from '../../utils/flattenPlanesEstudios';
import { verifyIsIncommingYear } from '../../utils/verifyIsIncommingYear';
import { InitialFilterTable } from './components/InitialFilterTable';
import { SeccionModal } from './components/SeccionModal';
import { CopySeccionModal } from './components/SeccionModal/CopySectionModal';
import { MatriculadosScreen } from './components/SeccionModal/MatriculadosScreen';
import { useSeccionesColumns } from './hooks';
import { matriculationStyles } from './styles';
import { InitialFilter, SeccionDetail } from './types';

const pathSearch = [
  'turno.descripcionTurno',
  'anio.descripcionAnio',
  'jornada',
  'planEstudioNivel.planEstudio.descripcionPlanEstudio',
  'division',
];

type Tabs = {
  [key: string]: { [key: string]: number };
};

const TABS: Tabs = {
  INICIAL: {
    SECCIONES: 0,
    PROMOCION: 1,
    GESTION_PASES: 2,
    INSCRIPTOS: 3,
    MATRICULADOS: 4,
  },
  PRIMARIO: {
    SECCIONES: 0,
    PROMOCION: 1,
    GESTION_PASES: 2,
    INSCRIPTOS: 3,
    MATRICULADOS: 4,
  },
  SECUNDARIO: {
    SECCIONES: 0,
    PROMOCION: 1,
    GESTION_PASES: 2,
    INSCRIPTOS: 3,
    MATRICULADOS: 4,
  },
};

export const InitialSectionScreen = () => {
  const {
    selectedRole: {
      localizacionId,
      nivel,
      tipoEstablecimiento,
      rol,
      localizacion,
      modalidad,
    },
  } = useRolesContext();
  const {
    dispatch: { setSection, setCiclo },
  } = useMatriculationContext();
  const setTabBarValue = useTabBarSetValue();
  const { anios, turnos } = useGetValidYears();
  const { cicloLectivo: currentCicloLectivo } = useColegioEfectivizadoContext();
  const classes = matriculationStyles();
  const { enqueueSnackbar } = useSnackbar();
  const seccionDAO = useSeccionDAO();
  const { generalState } = useGeneralContext();

  const { orderBy, direction, onSortChange } = useTableSortConfig(
    'nombreSeccion',
    'asc',
  );

  const [filter, setFilter] = useState<InitialFilter>({
    cicloLectivo: '',
    planEstudioNivel: '',
    anio: '',
    turno: '',
    jornada: '',
    search: '',
  });

  const [seccionDetails, setSeccionDetails] = useState<SeccionDetail>({
    open: false,
  });
  const [copyModalOpen, setCopyModalOpen] = useState<boolean>(false);

  const searchDebounced = useDebounce(filter.search, 1000);

  const filterSecciones = useMemo(
    () => ({
      localizacion: [{ path: 'localizacion', value: localizacionId }],
      nivel: [{ path: 'nivel', value: nivel }],
      cicloLectivo: [
        {
          path: 'cicloLectivo',
          value: filter.cicloLectivo || currentCicloLectivo?.idCicloLectivo,
        },
      ],
      planEstudio: filter.planEstudioNivel
        ? [
            {
              path: 'planEstudioNivel',
              value: filter.planEstudioNivel,
            },
          ]
        : [],
      jornada: filter.jornada
        ? [
            {
              path: 'jornada',
              value: filter.jornada,
            },
          ]
        : [],
      anio: filter.anio
        ? [
            {
              path: 'anio',
              value: filter.anio,
            },
          ]
        : [],
      turno: filter.turno
        ? [
            {
              path: 'turno',
              value: filter.turno,
            },
          ]
        : [],
      search: searchDebounced
        ? pathSearch.map((path) => ({
            path,
            value: searchDebounced,
            method: 'includes',
          }))
        : [],
    }),
    [
      currentCicloLectivo?.idCicloLectivo,
      filter.anio,
      filter.cicloLectivo,
      filter.jornada,
      filter.planEstudioNivel,
      filter.turno,
      localizacionId,
      nivel,
      searchDebounced,
    ],
  );

  useEffect(() => {
    setSection(null);
    setCiclo(null);
  }, [setCiclo, setSection]);

  const {
    data: seccion = [],
    working,
    fetchMore,
    reFetch,
  } = useMatriculacionSeccionLibbyFetch({
    filter: filterSecciones,
    aspect: 'matriculacion_secciones',
    limit: 100,
    orderBy,
    direction,
    enabled: Boolean(currentCicloLectivo),
  });

  const enabled = useDateValidator('Secciones');

  const { data: lpens = [], working: wLpens } =
    useLocalizacionPlanEstudioNivelLibbyCall({
      methodName: 'fetch',
      params: [],
    });

  const handleView = (item: MatriculacionSeccion) => {
    if (
      verifyIsIncommingYear(
        item.cicloLectivo?.anio,
        currentCicloLectivo?.anio || 0,
      ) &&
      [NIVEL.INICIAL /* NIVEL.PRIMARIO, NIVEL.SECUNDARIO */].includes(
        Number(nivel),
      )
    ) {
      setSeccionDetails({ open: true, data: item });
    } else {
      setTabBarValue(TABS[NIVEL[Number(nivel)]].MATRICULADOS);
      setSection(item.idSeccion);
      setCiclo(item.cicloLectivo.idCicloLectivo);
    }
  };
  const handleEdit = async (item: MatriculacionSeccion) => {
    await customFormDialog.show({
      title: 'Editar sección',
      renderComponent: (
        <SeccionModal
          seccion={item.idSeccion}
          cycle={item.cicloLectivo.idCicloLectivo}
          plan_study={item.planEstudioNivel.idPlanEstudioNivel}
          anio={item.anio.idAnio}
          turn={item.turno.idTurno}
          working_day={item.jornada}
          localizacionId={localizacionId}
          dividing={item.division}
          capacity={item.capacidadMaxima}
          nivel={nivel}
          refetch={reFetch}
          tipoEstablecimiento={tipoEstablecimiento}
          modalidad={modalidad}
          edit
        />
      ),
      sizeWidth: 'md',
    });
  };

  const handleDelete = async (item: MatriculacionSeccion) => {
    const confirm = await confirmDialog.show({
      title: '¿Desea eliminar la sección?',
      content: `Una vez eliminada, la sección "${item.nombreSeccion}" no podrá recuperarse.`,
      confirmText: 'Confirmar',
      cancelText: 'Cancelar',
    });
    if (confirm) {
      try {
        await seccionDAO.remove(item);
        reFetch();
        enqueueSnackbar('La sección se eliminó con éxito', {
          variant: 'success',
          autoHideDuration: DEFAULT_HIDE_TIME,
        });
      } catch (error) {
        enqueueSnackbar('No se pudo eliminar la sección', {
          variant: 'error',
          autoHideDuration: DEFAULT_HIDE_TIME,
        });
      }
    }
  };

  const { columns } = useSeccionesColumns({
    currentCicloLectivo,
    isEnable: enabled,
    handleView,
    handleEdit,
    handleDelete,
  });

  const { data: [periodo] = [], working: periodosWorking } =
    usePeriodoNuevoLibbyCall({
      methodName: 'getFirstPeriodo',
      params: [nivel],
    });

  const today = new Date();
  const inicioPeriodo = new Date(periodo?.fechaInicio);
  const enableSectionCopy =
    periodo &&
    moment(today, 'YYYY-MM-DD').isSameOrAfter(inicioPeriodo) &&
    !periodosWorking;

  const loading = working || wLpens;

  const canUseCopiarSecciones =
    enabled ||
    Number(rol) === ROL.DIRECCION_AREA ||
    Number(rol) === ROL.EQUIPO_CONDUCCION ||
    Number(rol) === ROL.DIRECTIVO ||
    Number(rol) === ROL.SUPERVISOR;

  const hasNonCurrentYearEntries =
    seccion.some((s) => s.cicloLectivo.anio !== currentCicloLectivo?.anio) ||
    seccion?.length === 0;

  const { request, working: workingCopySecciones } =
    useCopySeccionesPostRequest();

  const handleSaveSeccionesCopiadas = async (
    copiedEntries: MatriculacionSeccion[],
  ) => {
    try {
      const newEntries = copiedEntries.map((elem) => elem.idSeccion);
      const result: any = await request({ data: newEntries });
      if (result.existError) {
        enqueueSnackbar(`${result.error.message}`, {
          variant: 'error',
          autoHideDuration: DEFAULT_HIDE_TIME,
        });
      } else {
        enqueueSnackbar('Las secciones se copiaron con éxito', {
          variant: 'success',
          autoHideDuration: DEFAULT_HIDE_TIME,
        });
      }
    } catch (error) {
      enqueueSnackbar('No se han podido copiar las secciones.', {
        variant: 'error',
        autoHideDuration: DEFAULT_HIDE_TIME,
      });
    }
    reFetch();
  };

  const isInicial = Number(nivel) === NIVEL.INICIAL;

  const handleCreate = () => {
    if (enabled) {
      customFormDialog.show({
        title: 'Crear sección',
        renderComponent: (
          <SeccionModal
            date={generalState.date}
            localizacionId={localizacionId}
            nivel={nivel}
            tipoEstablecimiento={tipoEstablecimiento}
            modalidad={modalidad}
            refetch={reFetch}
          />
        ),
        sizeWidth: 'md',
      });
    } else {
      enqueueSnackbar(
        'No es posible crear una seccion luego de el 1/10/2021 con su rol actual.',
        {
          variant: 'error',
        },
      );
    }
  };

  if (seccionDetails.open) {
    return (
      <MatriculadosScreen
        matriculacion={{
          capacidad: (seccionDetails.data?.capacidadMaxima || 0).toString(),
          seccionNombre: seccionDetails.data?.nombreSeccion || '',
          seccionId: seccionDetails.data?.idSeccion || '',
          anio: seccionDetails.data?.cicloLectivo.anio || '',
        }}
        setActiveMatriculacion={() => setSeccionDetails({ open: false })}
      />
    );
  }

  return (
    <Grid container justifyContent="center" alignItems="center">
      <HeaderTable
        title={localizacion}
        titleMainButton="Crear sección"
        handleOnClick={handleCreate}
        openSeccionModal={() => {
          if (canUseCopiarSecciones) {
            setCopyModalOpen(true);
          } else {
            enqueueSnackbar(
              'Los únicos roles permitidos para copiar secciones son Directivo, Equipo de conducción y Dirección de Area',
              {
                variant: 'error',
              },
            );
          }
        }}
        disabledCopy={
          !enableSectionCopy ||
          hasNonCurrentYearEntries ||
          !enabled ||
          !isInicial
        }
        showCopy={canUseCopiarSecciones}
        disabledMain={!enabled}
      >
        <InitialFilterTable
          anios={anios}
          turnos={turnos}
          planesEstudios={flattenPlanesEstudio(lpens)}
          filter={filter}
          setFilter={setFilter}
          currentCicloLectivo={currentCicloLectivo}
        />
      </HeaderTable>
      {loading ? (
        <Loading />
      ) : (
        <>
          {workingCopySecciones && (
            <Grid
              className={`${classes.customContainer}`}
              container
              justifyContent="center"
              alignItems="center"
            >
              <Loading />
              <div className={`${classes.loadingMessage}`}>
                <span>
                  Esta operación puede demorar un poco, por favor espere.
                </span>
              </div>
            </Grid>
          )}
          <InfoTable
            rows={seccion}
            onBottomScroll={fetchMore}
            columns={columns}
            rowIdKey="idSeccion"
            direction={direction}
            orderBy={orderBy}
            onSortChange={onSortChange}
          />
        </>
      )}
      <CopySeccionModal
        rows={seccion}
        isOpen={copyModalOpen}
        onClose={() => {
          setCopyModalOpen(false);
        }}
        onSaveCopy={handleSaveSeccionesCopiadas}
      />
    </Grid>
  );
};
