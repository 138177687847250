import React, { useEffect, useMemo, useState } from 'react';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { InfoTable, Loading, SimpleSelect } from 'src/commons';
import typography from 'src/theme/typography';
import { Calendario, Inscripciones } from './components';
import { colorSectionDiary } from './hooks';
import { columns } from './utils/columns';
import { useGruposInscripcionesLibbyFetch } from 'src/app/business';
import { useRolesContext } from 'src/context/RolesContext';
import moment from 'moment';
import { GruposInscripciones } from 'src/app/models';
import { getFechasByDays } from './utils/helperFechas';

const styles = makeStyles((item: GruposInscripciones) => ({
  imageList: {
    '& > .MuiImageListItem-item': {
      borderRadius: '70px ',
    },
  },
  infoTable: {
    '& > .MuiTable-root': {
      heigth: '100%',
      '& > .MuiTableHead-root': {
        display: 'none',
      },
    },
  },
  dayButtonPublico: {
    backgroundColor: '#db6e03 !important',
    color: 'white !important',
    cursor: 'pointer',
  },
  dayButtonAcademico: {
    backgroundColor: '#b3188b !important',
    color: 'white !important',
    cursor: 'pointer',
  },
  dayButtonSociocomunitario: {
    backgroundColor: '#f9d92d !important',
    color: 'white !important',
    cursor: 'pointer',
  },
  dayButtonProductivo: {
    backgroundColor: '#0053a3 !important',
    color: 'white !important',
    cursor: 'pointer',
  },
}));

export const Cronograma = () => {
  const [date, setDate] = useState(new Date());
  const [activeStartDate, setActiveStartDate] = useState(new Date());
  const [options, setOptions] = useState('month');

  const selectOptions = [
    { tipo: 'Mes', value: 'month' },
    { tipo: 'Agenda', value: 'diary' },
  ];

  const {
    userInfo: { id: userId },
  } = useRolesContext();

  const inscripcionesFilter = useMemo(
    () => ({
      byUsuario: [
        {
          path: 'alumnoMovimiento.alumno.persona.cuentas',
          value: userId,
        },
      ],
    }),
    [userId],
  );

  const { data = [], working } = useGruposInscripcionesLibbyFetch({
    filter: inscripcionesFilter,
    aspect: 'estudiante-list',
    limit: 100,
  });

  const fechasEncuentros = useMemo(() => {
    const dates: Date[] = [];
    data.forEach((grupo) => {
      dates.push(new Date(grupo.ofertaGrupo.fechaInicio));
      dates.push(new Date(grupo.ofertaGrupo.fechaFin));
    });

    return dates.sort((date1, date2) => date1 - date2);
  }, [data]);

  const changeDate = (e: Date) => {
    setDate(e);
  };

  const classes = styles();

  const encuentros = useMemo(() => {
    const grupoPeriodos = new Map<
      string,
      {
        _class: string;
        grupoInscripcion: GruposInscripciones;
        days: string;
        duration: string;
        activity: string;
        date: number;
        month: string;
        section: JSX.Element | undefined;
      }
    >();

    data.forEach((grupo) => {
      const encuentrosDates = getFechasByDays(
        grupo.ofertaGrupo.fechaInicio,
        grupo.ofertaGrupo.fechaFin,
        grupo.ofertaGrupo.jornadas.map((jornada) => jornada.cupo),
      );

      const colorSectionCalendar = (item: string) => {
        if (item === 'Políticas públicas') {
          return classes.dayButtonPublico;
        }
        if (item === 'Académico') {
          return classes.dayButtonAcademico;
        }
        if (item === 'Sociocomunitario') {
          return classes.dayButtonSociocomunitario;
        }
        if (item === 'Productivo') {
          return classes.dayButtonProductivo;
        }
      };

      encuentrosDates.forEach((encuentro) => {
        const jornada = grupo.ofertaGrupo.jornadas.find(
          (jornada) => jornada.cupo === moment(encuentro).weekday(),
        );
        grupoPeriodos.set(encuentro, {
          _class: String(
            colorSectionCalendar(
              grupo.ofertaGrupo.ofertaTurno.oferta.accion.organizacion.sector
                .nombre,
            ),
          ),
          grupoInscripcion: grupo,
          days: moment(encuentro).locale('ar').format('dddd'),
          duration: `${jornada?.horaInicio} - ${jornada?.horaFin}`,
          activity: grupo.ofertaGrupo.ofertaTurno.oferta.accion.nombre,
          date: Number(moment(encuentro).format('D')),
          month: moment(encuentro).locale('ar').format('MMMM'),
          section: colorSectionDiary(
            grupo.ofertaGrupo.ofertaTurno.oferta.accion.organizacion.sector
              .nombre,
          ),
        });
      });
    });

    const encuentrosArray = Array.from(grupoPeriodos.entries());

    const monthOrder: { [key: string]: number } = {
      enero: 1,
      febrero: 2,
      marzo: 3,
      abril: 4,
      mayo: 5,
      junio: 6,
      julio: 7,
      agosto: 8,
      septiembre: 9,
      octubre: 10,
      noviembre: 11,
      diciembre: 12,
    };

    encuentrosArray.sort((a, b) => {
      const monthA = monthOrder[a[1].month.toLowerCase()];
      const monthB = monthOrder[b[1].month.toLowerCase()];

      if (monthA < monthB) {
        return -1;
      } else if (monthA > monthB) {
        return 1;
      } else {
        return a[1].date - b[1].date;
      }
    });
    const encuentrosOrdenados = new Map(encuentrosArray);

    return encuentrosOrdenados;
  }, [
    classes.dayButtonAcademico,
    classes.dayButtonProductivo,
    classes.dayButtonPublico,
    classes.dayButtonSociocomunitario,
    data,
  ]);

  return working ? (
    <Loading />
  ) : (
    <>
      {data.length > 0 ? (
        <Grid container>
          <Grid item xs={12} style={{ margin: '30px 0px 15px 30px' }}>
            <Grid container alignItems="center">
              <Grid item xs={2}>
                <Typography
                  style={{
                    fontFamily: typography.fontFamily,
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  Plan de Actividades de Aproximación:
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <SimpleSelect
                  labelKey="tipo"
                  valueKey="value"
                  content={selectOptions}
                  value={options}
                  handleChange={(e: any) => {
                    setOptions(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid container style={{ display: 'flex' }} alignItems="center">
            {options === 'month' ? (
              <Calendario
                minDate={fechasEncuentros[0]}
                maxDate={fechasEncuentros[-1]}
                date={date}
                changeDate={changeDate}
                onClickDay={(value, event) => {
                  console.info(value);
                }}
                tileClassName={({ activeStartDate, date, view }) => {
                  return (
                    encuentros.get(moment(date).format('YYYY-MM-DD'))?._class ??
                    ''
                  );
                }}
                tileDisabled={({ activeStartDate, date, view }) => {
                  const days = data.map((grupo) =>
                    grupo.ofertaGrupo.jornadas.map((jornada) => jornada.cupo),
                  );

                  const encuentros = [...new Set(days.flat())];

                  return encuentros.includes(date.getDay());
                }}
                onActiveStartDateChange={({ activeStartDate }) => {
                  setActiveStartDate(activeStartDate);
                }}
              />
            ) : (
              <Grid
                item
                xs={6}
                style={{
                  height: '100%',
                  marginBottom: '15px',
                  paddingLeft: '30px',
                }}
              >
                <InfoTable
                  columns={columns || []}
                  rows={[...encuentros].map(([fecha, row]) => row)}
                  customStyle={classes.infoTable}
                />
              </Grid>
            )}
            <Grid item xs={4}>
              <Inscripciones
                grupos={data}
                activeStartDate={activeStartDate}
                classImageList={classes.imageList}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <Typography>No hay actividades registradas</Typography>
      )}
    </>
  );
};
