export enum NIVEL {
  NO_NIVEL = -1,
  GENERAL = 0,
  INICIAL = 1,
  PRIMARIO = 2,
  SECUNDARIO = 3,
  TERCIARIO = 4,
  SECUNDARIA_FUTURO = 5,
}

export type Niveles = 'INICIAL' | 'PRIMARIA' | 'SECUNDARIA' | 'TERCEARIO';

export type PeriodosDescripcion =
  | 'PRIMER_BIMESTRE'
  | 'SEGUNDO_BIMESTRE'
  | 'TERCER_BIMESTRE'
  | 'CUARTO_BIMESTRE'
  | 'PRIMER_CUATRIMESTRE'
  | 'SEGUNDO_CUATRIMESTRE';

type PeriodosNivel = {
  [key in PeriodosDescripcion]?: number;
};

type Periodos = {
  [key in Niveles]: PeriodosNivel;
};

export const PERIODOS: Periodos = {
  PRIMARIA: {
    PRIMER_BIMESTRE: 2,
    SEGUNDO_BIMESTRE: 4,
    TERCER_BIMESTRE: 12,
    CUARTO_BIMESTRE: 13,
  },
  INICIAL: {},
  SECUNDARIA: {},
  TERCEARIO: {},
};

export enum TIPO_PERIODO {
  PRIMER_BIMESTRE = 1,
  SEGUNDO_BIMESTRE = 2,
  TERCER_BIMESTRE = 3,
  CUARTO_BIMESTRE = 4,
  PRIMER_CUATRIMESTRE = 1,
  SEGUNDO_CUATRIMESTRE = 3,
  RECESO_INVERNAL = 7,
}

export enum TURNOS_JUSTIFICACION {
  TURNO_MANANA = 1,
  TURNO_TARDE = 2,
  TURNO_AMBOS = 5,
}
