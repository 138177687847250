import { AlumnoMovimientoDAO } from 'src/platform/libby/dao';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { AlumnoMovimiento } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'alumno_movimiento';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idAlumnoMovimiento',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idAlumnoMovimiento',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.AlumnoMovimiento
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.AlumnoMovimiento,
      hoc: { ...defaultOptions.hoc, ...businessOptions.AlumnoMovimiento.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<AlumnoMovimiento, AlumnoMovimientoDAO>(
  DAO_NAME,
  optionsToUse,
);

// hooks
export const useAlumnoMovimientoFetchById = fetchById;
export const useAlumnoMovimientoLibbyCall = libbyCall;
export const useAlumnoMovimientoDAO = libbyDAO;
export const useAlumnoMovimientoLibbyFetch = libbyFetch;

// components/hoc
export const AlumnoMovimientoAutocomplete = entityAutocomplete;
export const AlumnoMovimientoCall = renderPropCall;
export const AlumnoMovimientoById = renderPropFetchById;
export const AlumnoMovimientoSelect = simpleSelect;
export const AlumnoMovimientoAccordionSelector = accordionSelector;
export const AlumnoMovimientoSelectedChips = selectedChips;

// context
// ByIdContext
export const useAlumnoMovimientoByIdContext = useByIdContext;
export const AlumnoMovimientoByIdProvider = ByIdProvider;
export const AlumnoMovimientoByIdContext = ByIdContext;
// GeneralContext
export const useAlumnoMovimientoContext = useGeneralContext;
export const AlumnoMovimientoProvider = GeneralProvider;
export const AlumnoMovimientoContext = GeneralContext;
