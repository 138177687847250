import React, { useState, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { SimpleSelect } from 'src/commons/components/Select/Select';

export const EscalafonSelect = (props: any) => {
  const [escalafon, setEscalafon] = useState<string>('');

  const escalafones = useMemo(() => {
    return [
      { name: 'A', value: 'A' },
      { name: 'B', value: 'B' },
      { name: 'C', value: 'C' },
    ];
  }, []);

  const handleChange = (e: any) => {
    const esc = escalafones.find((i) => i.value === e.target.value);
    if (esc) {
      setEscalafon(esc.value);
      props.onChange(esc.value);
    } else {
      setEscalafon('');
      props.onChange(undefined);
    }
  };

  return (
    <Grid item>
      <SimpleSelect
        title="Escalafón"
        placeholder="Seleccione Escalafón"
        value={escalafon}
        valueKey="value"
        labelKey="name"
        content={escalafones}
        onChange={handleChange}
      />
    </Grid>
  );
};
