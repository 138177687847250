import { Grid, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { InfoTable, Loading } from 'src/commons';
import typography from 'src/theme/typography';
// import { columnsActivities } from './utils/columnsActivities';
import { useActividadesColumns } from './hooks/useActividadesColumns';
import { useEstudianteContext } from './contexts/EstudianteContext';
import { getFechasByDays, getHorasByFechas } from './utils/helperFechas';

export const Actividades = () => {
  const { grupos, loadingGrupos, loadingListNodos } = useEstudianteContext();

  const columns = useActividadesColumns();

  const horasAcap = useMemo(() => {
    let totalHoras = 0;
    grupos.forEach((grupo) => {
      const encuentros = getFechasByDays(
        grupo.ofertaGrupo.ofertaTurno.oferta.fechaInicio,
        grupo.ofertaGrupo.ofertaTurno.oferta.fechaFin,
        grupo.ofertaGrupo.jornadas.map((jornada) => jornada.cupo),
      );

      const horasOferta = getHorasByFechas(
        encuentros,
        grupo.ofertaGrupo.jornadas.map((jornada) => ({
          startTime: jornada.horaInicio,
          endTime: jornada.horaFin,
          day: jornada.cupo,
        })),
      );

      totalHoras += horasOferta;
    });

    return totalHoras;
  }, [grupos]);

  return (
    <Grid container>
      <Grid item xs={12} style={{ margin: '30px 0px 15px 30px' }}>
        <Typography
          style={{
            fontFamily: typography.fontFamily,
            fontSize: 14,
            fontWeight: 600,
          }}
        >
          Total Horas ACAP: {horasAcap}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {loadingGrupos || loadingListNodos ? (
          <Loading />
        ) : (
          <InfoTable columns={columns || []} rows={grupos || []} />
        )}
      </Grid>
    </Grid>
  );
};
