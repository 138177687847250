import React, { useMemo, useState } from 'react';
import {
  useBackButton,
  useRouteScreenTitle,
  useTableSortConfig,
} from '../../../../../commons/hooks';
import { useCuentasLibbyFetch } from '../../../../../app/business';
import { InfoTable } from '../../../../../commons/components/InfoTable';
import { useUsuariosListColumns } from './hooks';
import { Filter, UsuarioListTableHeader } from './components';
import { useRolesContext } from 'src/context/RolesContext';
import { Loading } from 'src/commons';

const searchFilters = [
  'idUsuario',
  'username',
  'nombreUsuario',
  'apellidoUsuario',
  'createdAt',
];

export const UsuariosList = () => {
  useBackButton('/private/configurationadmin');
  useRouteScreenTitle('Administración - Usuarios');
  const {
    userInfo: { id },
  } = useRolesContext();

  const [filter, setFilter] = useState<Filter>({ search: '' });
  const processedFilter = useMemo(
    () => ({
      search: !filter.search
        ? []
        : searchFilters.map((path) => ({
            path,
            value: filter.search,
            method: 'includes',
          })),
      yoNo: [
        {
          path: 'idUsuario',
          value: id,
          method: 'notEquals',
        },
      ],
    }),
    [filter, id],
  );

  const { orderBy, onSortChange, direction } = useTableSortConfig(
    'createdAt',
    'desc',
  );

  const {
    data = [],
    working,
    fetchMore,
    reFetch,
  } = useCuentasLibbyFetch({
    orderBy,
    direction,
    filter: processedFilter,
  });

  const [isLoading, setLoading] = useState(false);
  const columns = useUsuariosListColumns(reFetch, setLoading);

  return (
    <>
      <UsuarioListTableHeader
        filter={filter}
        onChangeFilter={setFilter}
        reFetch={reFetch}
      />
      {isLoading ? (
        <Loading />
      ) : (
        <InfoTable
          rows={data}
          columns={columns}
          working={working}
          rowIdKey="idUsuario"
          direction={direction}
          orderBy={orderBy}
          onSortChange={onSortChange}
          onBottomScroll={fetchMore}
        />
      )}
    </>
  );
};
