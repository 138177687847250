import { Button, Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import {
  useAnioLibbyFetch,
  useSeccionLibbyFetch,
  useTurnoLibbyFetch,
} from 'src/app/business';
import { GruposInscripciones, GruposSelecciones } from 'src/app/models';
import { SimpleSelect } from 'src/commons';

export const InscripcionAccionesSeleccionHeader = ({
  setFilters,
  filters,
  handleSearch,
  alumnosInscripciones,
  workingAlumnosInscripciones,
  uniquesOrientacionesEscuela,
  gruposSelecciones,
}: any) => {
  const filterAnios = useMemo(
    () => ({
      0: { path: 'nivel', value: 3 },
    }),
    [],
  );

  const filterSecciones = useMemo(
    () => ({
      0: [{ path: 'anio.idAnio', value: 25 }],
      2:
        filters?.turno !== undefined && filters?.turno !== ''
          ? [{ path: 'turno.idTurno', value: filters?.turno }]
          : [],
      3:
        filters?.orientacion !== undefined && filters?.orientacion !== ''
          ? [
              {
                path: 'planEstudioNivel.planEstudio.orientaciones.orientacion.idOrientacion',
                value: filters?.orientacion,
              },
            ]
          : [],
    }),
    [filters?.orientacion, filters?.turno],
  );

  const { data: anios = [], working: workingAnios } = useAnioLibbyFetch({
    filter: filterAnios,
  });

  const { data: secciones = [], working: workingSecciones } =
    useSeccionLibbyFetch({
      filter: filterSecciones,
      orderBy: 'nombreSeccion',
    });

  const turnos = useMemo(() => {
    const uniquesTurnos: any = [];

    secciones?.forEach((seccion: any) => {
      const alreadyInArray = uniquesTurnos?.some((turno: any) => {
        return turno?.idTurno === seccion?.turno?.idTurno;
      });

      if (!alreadyInArray) {
        uniquesTurnos.push(seccion?.turno);
      }
    });
    return uniquesTurnos;
  }, [secciones]);

  const uniquesGruposInscripciones = useMemo(() => {
    const uniquesGruposInscripciones: GruposSelecciones[] = gruposSelecciones;
    alumnosInscripciones?.forEach((alumnoInscripcion: any) => {
      const uniqueOfertaAlreadyInArray = uniquesGruposInscripciones.some(
        (gruposSelecciones) => {
          return (
            gruposSelecciones?.ofertaGrupo?.idOfertaGrupo ===
            alumnoInscripcion?.ofertaGrupo.idOfertaGrupo
          );
        },
      );

      const alumnoIsInTheSameSeccion =
        alumnoInscripcion?.alumnoMovimiento?.seccion?.idSeccion ===
        filters?.seccion;

      const validationSameSeccion = Boolean(filters?.seccion)
        ? alumnoIsInTheSameSeccion
        : true;

      const alumnoInscripcionOrientacion =
        alumnoInscripcion?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.accionOrientaciones?.some(
          (orientacion: any) => {
            return (
              orientacion?.orientacion?.idOrientacion === filters?.orientacion
            );
          },
        );

      const validationSameOrientacion = Boolean(filters?.orientacion)
        ? alumnoInscripcionOrientacion
        : true;

      if (
        !uniqueOfertaAlreadyInArray &&
        // validationSameSeccion &&
        validationSameOrientacion
      ) {
        uniquesGruposInscripciones.push(alumnoInscripcion);
      }
    });

    return uniquesGruposInscripciones;
  }, [
    alumnosInscripciones,
    filters?.orientacion,
    filters?.seccion,
    gruposSelecciones,
  ]);

  const gruposSeleccionesWithName = uniquesGruposInscripciones.map(
    (grupoInscripcion) => {
      const grupoSeleccionName = `${grupoInscripcion?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.nombre} - ${grupoInscripcion?.ofertaGrupo?.ofertaTurno?.turno?.descripcionTurno} - id ${grupoInscripcion?.ofertaGrupo?.idOfertaGrupo}`;
      return {
        ...grupoInscripcion,
        grupoSeleccionName,
      };
    },
  );

  return (
    <>
      <Grid container direction="row" xs={12}>
        <Grid item style={{ marginTop: 20, marginRight: 15 }}>
          <SimpleSelect
            title="Orientación"
            name="orientacion"
            value={filters?.orientacion}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFilters((prevState: any) => ({
                ...prevState,
                orientacion: value,
              }));
            }}
            placeholder="Seleccioná orientación"
            content={uniquesOrientacionesEscuela}
            labelKey="nombre"
            valueKey="idOrientacion"
            customStyleContainer={{ width: '20rem' }}
          />
        </Grid>
        <Grid item style={{ marginTop: 20, marginRight: 15 }}>
          <SimpleSelect
            title="Año"
            name="anio"
            value={25}
            handleChange={(e: any) => {
              console.log('e');
            }}
            placeholder="Seleccioná año"
            content={workingAnios ? [] : anios}
            labelKey="descripcionAnio"
            valueKey="idAnio"
            customStyleContainer={{ width: '20rem' }}
            disabled={true}
          />
        </Grid>
        <Grid item style={{ marginTop: 20, marginRight: 15 }}>
          <SimpleSelect
            title="Turno"
            name="turno"
            value={filters?.turno}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFilters((prevState: any) => ({
                ...prevState,
                turno: value,
              }));
            }}
            placeholder="Seleccioná turno"
            content={turnos}
            labelKey="descripcionTurno"
            valueKey="idTurno"
            customStyleContainer={{ width: '20rem' }}
          />
        </Grid>
        <Grid item style={{ marginTop: 20, marginRight: 15 }}>
          <SimpleSelect
            title="Sección"
            name="seccion"
            value={filters?.seccion}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFilters((prevState: any) => ({
                ...prevState,
                seccion: value,
              }));
            }}
            placeholder="Seleccioná sección"
            content={workingSecciones ? [] : secciones}
            labelKey="nombreSeccion"
            valueKey="idSeccion"
            customStyleContainer={{ width: '20rem' }}
          />
        </Grid>
        <Grid item style={{ marginTop: 20, marginRight: 15 }}>
          <SimpleSelect
            title="Actividades"
            name="actividad"
            value={filters?.actividad}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFilters((prevState: any) => ({
                ...prevState,
                actividad: value,
              }));
            }}
            placeholder="Seleccioná actividad"
            content={
              workingAlumnosInscripciones ? [] : gruposSeleccionesWithName
            }
            labelKey="grupoSeleccionName"
            valueKey="ofertaGrupo.idOfertaGrupo"
            customStyleContainer={{ width: '20rem' }}
          />
        </Grid>
        <Grid item style={{ marginTop: 20, marginRight: 15 }}>
          <Button
            color="primary"
            variant="contained"
            component="label"
            style={{ width: '200px', marginTop: '30px' }}
            onClick={() => handleSearch()}
          >
            Buscar Estudiante
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
