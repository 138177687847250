import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { UsuarioRolDependenciaFuncional } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'usuario_rol_dependencia_funcional';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idUsuarioRolDependenciaFuncional',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idUsuarioRolDependenciaFuncional',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.UsuarioRolDependenciaFuncional
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.UsuarioRolDependenciaFuncional,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.UsuarioRolDependenciaFuncional.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<UsuarioRolDependenciaFuncional>(DAO_NAME, optionsToUse);

// hooks
export const useUsuarioRolDependenciaFuncionalFetchById = fetchById;
export const useUsuarioRolDependenciaFuncionalLibbyCall = libbyCall;
export const useUsuarioRolDependenciaFuncionalDAO = libbyDAO;
export const useUsuarioRolDependenciaFuncionalLibbyFetch = libbyFetch;

// components/hoc
export const UsuarioRolDependenciaFuncionalAutocomplete = entityAutocomplete;
export const UsuarioRolDependenciaFuncionalCall = renderPropCall;
export const UsuarioRolDependenciaFuncionalById = renderPropFetchById;
export const UsuarioRolDependenciaFuncionalSelect = simpleSelect;
export const UsuarioRolDependenciaFuncionalAccordionSelector =
  accordionSelector;
export const UsuarioRolDependenciaFuncionalSelectedChips = selectedChips;

// context
// ByIdContext
export const useUsuarioRolDependenciaFuncionalByIdContext = useByIdContext;
export const UsuarioRolDependenciaFuncionalByIdProvider = ByIdProvider;
export const UsuarioRolDependenciaFuncionalByIdContext = ByIdContext;
// GeneralContext
export const useUsuarioRolDependenciaFuncionalContext = useGeneralContext;
export const UsuarioRolDependenciaFuncionalProvider = GeneralProvider;
export const UsuarioRolDependenciaFuncionalContext = GeneralContext;
