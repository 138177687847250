import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { grey } from '@material-ui/core/colors';
import {
  useDependenciaFuncionalLibbyFetch,
  useDistritosCustomGetRequest,
  useDistritosLocalizacionesCustomGetRequest,
  useNivelLibbyFetch,
} from 'src/app/business';
import { level, SimpleSelect } from 'src/commons';
import { FILTERS } from './table_filters';
import reportsList from './reportsConfig';
import { useReportsContext } from './contexts/ReportContext';

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
};

const LOADING_TEXT = 'Cargando datos...';

const FiltersSelect = () => {
  const {
    currentReport,
    reportCategory,
    internalFilters,
    globalFilters,
    setInternalFilters,
    setGlobalFilters,
    setSearch,
  } = useReportsContext();
  const handleChange =
    (report: string, global = false) =>
    (e) => {
      const target = e.target;
      const name = target.name;
      const value = target.value;
      if (!global) {
        setInternalFilters({
          ...internalFilters,
          [reportCategory]: {
            ...internalFilters[reportCategory],
            [name]: value,
          },
        });
      } else {
        setGlobalFilters({
          ...globalFilters,
          [name]: value,
        });
        setSearch('');
      }
    };

  const { data: niveles = [], working: nivel_working } = useNivelLibbyFetch({
    limit: 10,
  });

  const { data: df = [], working: df_working } =
    useDependenciaFuncionalLibbyFetch({
      limit: 100,
    });

  const dfFilter = useMemo(
    () =>
      globalFilters[FILTERS.dependencia_funcional] === ''
        ? ''
        : JSON.parse(globalFilters[FILTERS.dependencia_funcional]).id,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [globalFilters[FILTERS.dependencia_funcional]],
  );

  const { data: distritos = [], working: de_working } =
    useDistritosCustomGetRequest({
      url: `/api/public/custom/listadistritos?dependenciafuncional=${dfFilter}`,
      autoCall: Boolean(globalFilters[FILTERS.dependencia_funcional]),
    });

  const distritoFilter = useMemo(
    () =>
      globalFilters[FILTERS.distrito_escolar] === ''
        ? ''
        : JSON.parse(globalFilters[FILTERS.distrito_escolar]).id,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [globalFilters[FILTERS.distrito_escolar]],
  );

  const {
    data: localizaciones = [],
    working: estab_working,
    request: localizacionesRequest,
  } = useDistritosLocalizacionesCustomGetRequest({
    url: `/api/public/custom/listalocalizaciones?distrito=${distritoFilter}&dependenciafuncional=${dfFilter}`,
    autoCall: false,
  });

  const distritoEscolarFilter = globalFilters[FILTERS.distrito_escolar];
  const dependenciaFilter = globalFilters[FILTERS.dependencia_funcional];
  useEffect(() => {
    if (!!distritoEscolarFilter && !!dependenciaFilter) {
      localizacionesRequest();
    }
  }, [distritoEscolarFilter, dependenciaFilter, localizacionesRequest]);

  const nivelDependenciaFuncionalRelation = {
    [level.INICIAL]: [10, 41],
    [level.PRIMARIO]: [20, 41],
    [level.SECUNDARIO]: [31, 32, 41, 70],
  };

  return (
    <>
      <Grid item xs={1}>
        <SimpleSelect
          disabled={nivel_working}
          title={nivel_working ? LOADING_TEXT : 'Nivel'}
          content={niveles
            ?.filter((nivel) =>
              reportsList[reportCategory][
                currentReport
              ].levelPermissions.includes(nivel.idNivel),
            )
            .map((nivel) => ({
              id: JSON.stringify({
                id: nivel.idNivel,
                label: nivel.descripcionNivel,
              }),
              descripcionNivel: nivel.descripcionNivel,
            }))}
          valueKey="id"
          labelKey="descripcionNivel"
          name={FILTERS.nivel}
          placeholder="Seleccioná el nivel"
          value={globalFilters[FILTERS.nivel]}
          handleChange={handleChange(reportCategory, true)}
          customStyleTitle={customStyles.customStyleTitleSelect}
        />
      </Grid>
      {!!globalFilters[FILTERS.nivel] && (
        <Grid item xs={2}>
          <SimpleSelect
            disabled={df_working}
            title={df_working ? LOADING_TEXT : 'Dependencia Funcional'}
            content={df
              .filter((dependencia) => {
                return nivelDependenciaFuncionalRelation[
                  JSON.parse(globalFilters[FILTERS.nivel]).id
                ].includes(dependencia.idDependenciaFuncional);
              })
              .map((dependencia) => ({
                idDependenciaFuncional: JSON.stringify({
                  id: dependencia.idDependenciaFuncional,
                  label: dependencia.descripcion,
                }),
                descripcion: dependencia.descripcion,
              }))}
            valueKey="idDependenciaFuncional"
            labelKey="descripcion"
            name={FILTERS.dependencia_funcional}
            placeholder="Seleccioná la dependencia funcional"
            value={globalFilters[FILTERS.dependencia_funcional]}
            handleChange={handleChange(reportCategory, true)}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
      )}
      {!!globalFilters[FILTERS.dependencia_funcional] && (
        <Grid item xs={1}>
          <SimpleSelect
            disabled={de_working}
            title={de_working ? LOADING_TEXT : 'Distrito Escolar'}
            content={distritos.map((distrito) => ({
              idDistritoEscolar: JSON.stringify({
                id: distrito.idDistritoEscolar,
                label: distrito.nombre,
              }),
              nombre: distrito.nombre,
            }))}
            valueKey="idDistritoEscolar"
            labelKey="nombre"
            name={FILTERS.distrito_escolar}
            placeholder="Seleccioná el distrito escolar"
            value={globalFilters[FILTERS.distrito_escolar]}
            handleChange={handleChange(reportCategory, true)}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
      )}
      {!!globalFilters[FILTERS.distrito_escolar] && (
        <Grid item xs={2}>
          <SimpleSelect
            disabled={estab_working}
            title={estab_working ? LOADING_TEXT : 'Localización'}
            content={localizaciones.map((localizacion) => ({
              id: localizacion.idLocalizacion,
              descripcion: localizacion.descripcion,
            }))}
            valueKey="id"
            labelKey="descripcion"
            name={FILTERS.localizacion}
            placeholder="Seleccioná la localización"
            value={globalFilters[FILTERS.localizacion]}
            handleChange={handleChange(reportCategory, true)}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
      )}
    </>
  );
};

export default FiltersSelect;
