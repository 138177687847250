import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { InterdiscipTempPlan } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'interdiscip_temp_plan';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idInterdiscipTempPlan',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idInterdiscipTempPlan',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.InterdiscipTempPlan
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.InterdiscipTempPlan,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.InterdiscipTempPlan.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<InterdiscipTempPlan>(DAO_NAME, optionsToUse);

// hooks
export const useInterdiscipTempPlanFetchById = fetchById;
export const useInterdiscipTempPlanLibbyCall = libbyCall;
export const useInterdiscipTempPlanDAO = libbyDAO;
export const useInterdiscipTempPlanLibbyFetch = libbyFetch;

// components/hoc
export const InterdiscipTempPlanAutocomplete = entityAutocomplete;
export const InterdiscipTempPlanCall = renderPropCall;
export const InterdiscipTempPlanById = renderPropFetchById;
export const InterdiscipTempPlanSelect = simpleSelect;
export const InterdiscipTempPlanAccordionSelector = accordionSelector;
export const InterdiscipTempPlanSelectedChips = selectedChips;

// context
// ByIdContext
export const useInterdiscipTempPlanByIdContext = useByIdContext;
export const InterdiscipTempPlanByIdProvider = ByIdProvider;
export const InterdiscipTempPlanByIdContext = ByIdContext;
// GeneralContext
export const useInterdiscipTempPlanContext = useGeneralContext;
export const InterdiscipTempPlanProvider = GeneralProvider;
export const InterdiscipTempPlanContext = GeneralContext;
