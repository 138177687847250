import { FormDialog } from '../../base';
import theme from '../../../../../theme';
import {
  MultiCheckboxForm,
  MultiCheckboxFormValue,
  MultiCheckboxFormMetadata,
} from './MultiCheckboxForm';

export const MultiCheckboxDialog = FormDialog.create<
  MultiCheckboxFormValue,
  MultiCheckboxFormMetadata
>({
  customTheme: theme,
  component: MultiCheckboxForm,
  title: 'Configurar title',
});
