import { LibbyFetchDAO } from './LibbyFetchDAO';

export class TurnDAO extends LibbyFetchDAO {
  constructor() {
    super('turno', 'idTurno');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByTurnoIds(ids: Array<number>) {
    const query = this.query();
    if (ids.length > 0) {
      query.in('idTurno', ids);
    }
    return query.limit(0, 10000).run();
  }
}
