import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSnackbar } from 'notistack';
import { Grid, makeStyles } from '@material-ui/core';
import { AnyObject, LibbyObject, useLibbyCall } from 'src/commons';
import {
  useInscripcionAlumnoDAO,
  useInscripcionAlumnoCustomDAO,
} from 'src/app/business';
import { Footer, InfoTable, OptionsModal } from 'src/commons/components';
import { alumnEnrolledStatus } from 'src/commons/const';
import { useLibbyFetch } from 'src/commons/hooks';
import { useDebounce } from 'src/commons/hooks/useDebounce';
import confirmDialog from 'src/commons/services/confirmDialog';
import customFormDialog from 'src/commons/services/customFormDialog';
import { useRolesContext } from 'src/context/RolesContext';
import { useLegajoLogic } from 'src/screens/Private/LegajoAlumno';
import { primary } from 'src/theme/colors';
import { ROL } from 'src/commons/const/rol';
import { EnrolledAddStudentModal } from '../components/EnrolledAddStudentModal';
import { FilterTablesEnrolled } from './components/FilterTablesEnrolled';
import { SeccionSelect } from './components/SelectSeccion';
import { EnrolledAlumnModal } from '../components';

const useStyles = makeStyles({
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
  infoTableCustom: {
    marginBottom: '80px',
  },
  alumnoButton: {
    color: primary.lightBlue,
    margin: '0',
    textAlign: 'left',
    background: 'none',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

const AdultsEnrolledRaw = ({ libby }: LibbyObject) => {
  const roleContext = useRolesContext();
  const { enqueueSnackbar } = useSnackbar();
  const inscriocionAlumnoCustonDAO = useInscripcionAlumnoCustomDAO();
  const classes = useStyles();
  const { localizacionId, nivel: levelId, rol } = roleContext.selectedRole;
  const filterLocalization = useMemo(
    () => [{ path: 'localizacion', value: localizacionId }],
    [localizacionId],
  );
  const [direction, setDirection] = React.useState<'asc' | 'desc'>('asc');
  const [alumnRows, setAlumnRows] = useState<any>([]);
  const [orderBy, setOrderBy] = useState('alumno.persona.apellido');
  const [alumnsWithSections, setAlumnsWithSections] = useState<any>([]);
  const filterDefaultLevel = useMemo(
    () => [{ path: 'nivel.idNivel', value: levelId }],
    [levelId],
  );
  const { current: filterEstadoMatricula } = useRef([
    {
      path: 'alumno.estadoMatricula.idEstadoMatricula',
      value: alumnEnrolledStatus.INSCRIPTO,
    },
  ]);
  const { current: filterIsDeleted } = useRef([
    { path: 'isDeleted', value: false },
  ]);
  const [filterLibby, setFilterLibby] = useState<AnyObject>({
    0: filterLocalization,
    1: filterEstadoMatricula,
    2: filterIsDeleted,
  });
  const [formValues, setFormValues] = useState({
    id_plan_estudio: '',
    id_turno: '',
  });

  const [registersCount, setRegistersCount] = useState(0);
  const inscripcionAlumnoDAO = useInscripcionAlumnoDAO();

  useEffect(() => {
    inscripcionAlumnoDAO
      .getCountFilter(filterLibby)
      .then((response: { countofidinscripcionalumno: number }[]) => {
        if (!!response) {
          setRegistersCount(response[0]?.countofidinscripcionalumno);
        }
      });
  }, [inscripcionAlumnoDAO, filterLibby]);

  const [search, setSearch] = useState('');
  const searchDebounced = useDebounce(search, 1000);
  const pathSearch = useMemo(
    () => [
      {
        path: 'alumno.persona.apellido',
        value: searchDebounced,
        method: 'includes',
      },
      {
        path: 'alumno.persona.nombre',
        value: searchDebounced,
        method: 'includes',
      },
      {
        path: 'alumno.persona.documento',
        value: searchDebounced,
        method: 'includes',
      },
      {
        path: 'nivel.descripcionNivel',
        value: searchDebounced,
        method: 'includes',
      },
      {
        path: 'turno.descripcionTurno',
        value: searchDebounced,
        method: 'includes',
      },
    ],
    [searchDebounced],
  );

  const paramsFetchAlumno = {
    daoName: 'inscripcion_alumno',
    orderBy,
    direction,
    filter: filterLibby,
    limit: 100,
  };

  const {
    data: inscripcionAlumno,
    working,
    fetchMore,
    reFetch,
  } = useLibbyFetch(libby, paramsFetchAlumno);
  const { data: seccion } = useLibbyCall(libby, {
    daoName: 'seccion',
    methodName: 'getByLocationId',
    params: [localizacionId],
  });

  const paramsFetchPlanEstudioNivel = useMemo(
    () => ({
      daoName: 'localizacion_plan_estudio_nivel',
      filter: {
        0: [{ path: 'planEstudioNivel.modalidadNivel.nivel', value: levelId }],
        1: [{ path: 'localizacion', value: localizacionId }],
      },
    }),
    [localizacionId, levelId],
  );

  const { data: studyPlanContent } = useLibbyFetch(
    libby,
    paramsFetchPlanEstudioNivel,
  );
  const { data: turno } = useLibbyFetch(libby, { daoName: 'turno' });

  const handleChangeSection = useCallback(
    (e: React.FormEvent<EventTarget>, alumno: AnyObject) => {
      const target = e.target as HTMLInputElement;
      const updatedAlumnRow = alumnRows.map((item: AnyObject) => {
        if (item.idAlumno === alumno.idAlumno) {
          return { ...item, idSeccion: target.value };
        }
        return item;
      });
      setAlumnRows(updatedAlumnRow);
      const updatedAlumn = { ...alumno, idSeccion: target.value };
      if (!alumnsWithSections.length) {
        setAlumnsWithSections([updatedAlumn]);
      } else {
        const newArray = alumnsWithSections.reduce(
          (acc: any, item: AnyObject) => {
            if (
              acc.findIndex((i: AnyObject) => i.idAlumno === item.idAlumno) !==
                -1 ||
              // @ts-ignore
              updatedAlumn.idAlumno === item.idAlumno
            ) {
              return acc;
            }
            return [...acc, item];
          },
          [updatedAlumn],
        );
        setAlumnsWithSections(newArray);
        return newArray;
      }
    },
    [alumnsWithSections, alumnRows],
  );

  const removeAlumn = useCallback(
    async (nombre: string, idInscripcionAlumno: number) => {
      try {
        const confirm = await confirmDialog.show({
          title: 'Dar de baja',
          content: `¿Estás seguro que desea dar de baja a ${nombre}?`,
          confirmText: 'Confirmar',
          cancelText: 'Cancelar',
        });
        if (confirm) {
          customFormDialog.show({
            title: 'Motivo de baja',
            renderComponent: (
              <EnrolledAlumnModal
                alumn={idInscripcionAlumno}
                reFetch={reFetch}
              />
            ),
            sizeWidth: 'md',
          });
        }
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Ha ocurrido un error al intentar guardar', {
          variant: 'error',
        });
      }
    },
    [reFetch, enqueueSnackbar],
  );

  useEffect(() => {
    const alumns = inscripcionAlumno.map((item) => {
      const { idInscripcionAlumno } = item;
      const { idAlumno } = item.alumno;
      const { idCiclo, anio } = item;
      const { nombre, apellido, documento } = item.alumno.persona;
      const { idNivel, descripcionNivel } = item.nivel;
      const { descripcionTurno } = item.turno;
      return {
        idAlumno,
        idInscripcionAlumno,
        nombre: `${apellido}, ${nombre}`,
        documento,
        idAnio: anio?.idAnio,
        idCiclo,
        idNivel,
        descripcionNivel,
        idTurno: descripcionTurno,
        idSeccion: null,
        selectedSeccion: null,
      };
    });

    setAlumnRows(alumns);
  }, [inscripcionAlumno, seccion]);

  const { toLegajoAlumno } = useLegajoLogic();

  const rows = useMemo(
    () =>
      alumnRows.map((item: AnyObject) => ({
        ...item,
        selectedSeccion: (
          <SeccionSelect
            content={seccion.filter(
              (_item: AnyObject) =>
                _item.cicloLectivoAdultos?.idCiclo === item.idCiclo &&
                _item.nivel?.idNivel === item.idNivel,
            )}
            value={item.idSeccion}
            handleChange={(e: React.FormEvent<EventTarget>) =>
              handleChangeSection(e, item)
            }
          />
        ),
        nombre: (
          <button
            type="button"
            className={classes.alumnoButton}
            onClick={() => toLegajoAlumno(item?.idAlumno)}
          >
            {item?.nombre}
          </button>
        ),
        icon: (
          <OptionsModal
            options={[
              {
                label: 'Dar de baja',
                onClick: () =>
                  removeAlumn(item.nombre, item.idInscripcionAlumno),
              },
              {
                label: 'Ver legajo',
                onClick: () => toLegajoAlumno(item?.idAlumno),
              },
            ]}
          />
        ),
      })),
    [
      alumnRows,
      seccion,
      classes.alumnoButton,
      handleChangeSection,
      toLegajoAlumno,
      removeAlumn,
    ],
  );

  const filterTables = useCallback(
    (filterSearch: any) => {
      if (formValues.id_plan_estudio || formValues.id_turno) {
        const toReformFormValues = Object.entries(formValues).map(
          (filter: AnyObject) =>
            filter[1] && [{ path: filter[0], value: filter[1] }],
        );
        const toObjectLibby = {
          ...filterLibby,
          filterLocalization,
          filterSearch,
          ...toReformFormValues,
        };
        setFilterLibby(toObjectLibby);
      } else {
        setFilterLibby({
          ...filterLibby,
          0: filterLocalization,
          1: filterSearch,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formValues],
  );

  useEffect(() => {
    filterTables(pathSearch);
  }, [filterTables, formValues, pathSearch]);

  const handleChangeSearch = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setSearch(target.value);
  };

  const column = [
    {
      id: 'nombre',
      label: 'Apellido y nombre',
      width: '10%',
      orderById: 'alumno.persona.apellido',
    },
    {
      id: 'documento',
      label: 'Documento',
      width: '5%',
      orderById: 'alumno.persona.documento',
    },
    {
      id: 'descripcionNivel',
      label: 'Nivel',
      width: '5%',
      orderById: 'nivel.descripcionNivel',
    },
    {
      id: 'idTurno',
      label: 'Turno solicitado',
      width: '10%',
      orderById: 'turno.descripcionTurno',
    },
    {
      id: 'selectedSeccion',
      label: 'Ciclo a matricular',
      width: '10%',
      hideSortIcon: true,
      noSort: true,
    },
    {
      id: 'icon',
      label: '',
      width: '5%',
      hideSortIcon: true,
      noSort: true,
    },
  ];

  const handleChange = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setDirection(newDirection);
    setOrderBy(newOrderBy);
  };

  const handleSave = useCallback(async () => {
    const confirm = await confirmDialog.show({
      title: 'Matricular',
      content:
        '¿Está seguro que desea matricular al/los alumno(s) seleccionado(s)?',
      confirmText: 'Confirmar',
      cancelText: 'Cancelar',
    });
    if (confirm) {
      await inscriocionAlumnoCustonDAO.matricular(alumnsWithSections);

      const newAlumnRows = alumnRows.filter(
        (item: AnyObject) => item.idSeccion === null,
      );
      setAlumnRows(newAlumnRows);
      setAlumnsWithSections([]);
      reFetch();
    }
  }, [alumnRows, alumnsWithSections, inscriocionAlumnoCustonDAO, reFetch]);

  const handleAddStudent = () => {
    customFormDialog.show({
      title: 'Agregar Estudiante',
      renderComponent: (
        <EnrolledAddStudentModal
          reFetch={reFetch}
          localizacionId={localizacionId}
          toLegajoAlumno={toLegajoAlumno}
        />
      ),
      sizeWidth: 'md',
      customModalServices: {
        '& .MuiPaper-root': { overflowY: 'auto', overflowX: 'hidden' },
      },
    });
  };

  const buttonConfig: any = [
    {
      title: 'Matricular',
      handleOnClick: () => handleSave(),
      size: 'large',
    },
  ];

  const flatten = () =>
    studyPlanContent.map((data: AnyObject) => ({
      ...data,
      ...data.planEstudioNivel,
      ...data.planEstudioNivel.planEstudio,
    }));

  return (
    <>
      <FilterTablesEnrolled
        studyPlanContent={flatten()}
        turn={turno}
        registersCount={registersCount}
        handleChange={handleChange}
        handleChangeSearch={handleChangeSearch}
        search={search}
        formValues={formValues}
        titleMainButton={
          rol === ROL.DIRECCION_AREA.toString()
            ? 'Agregar Estudiante'
            : undefined
        }
        handleOnClick={handleAddStudent}
      />
      <>
        <InfoTable
          rows={rows}
          columns={column}
          working={working}
          rowIdKey="id_seccion"
          direction={direction}
          onSortChange={handleRequestSort}
          onBottomScroll={fetchMore}
          customStyle={classes.infoTableCustom}
        />
        <Grid className={classes.footer}>
          <Footer buttonConfig={buttonConfig} />
        </Grid>
      </>
    </>
  );
};

export const AdultsEnrolled = DatabaseConnector(AdultsEnrolledRaw)(
  'localizacion_plan_estudio_nivel',
  'turno',
  'alumno',
  'inscripcion_alumno',
  'alumno_movimiento',
  'seccion',
);
