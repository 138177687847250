import React, { useCallback, useState, useMemo } from 'react';
import { useBackButton, useRouteScreenTitle } from 'src/commons';
import { TableAlumnos } from './views/TableAlumnos';
import { TabsCargaPps } from './views/TabsCargaPps';
import { Pps } from '../../../app/models/Pps';
import { currentFormValuesType, InformePpsType } from './types';
import { PPSFormProvider } from './PPSFormProvider';

export const PpsComponent = () => {
  useRouteScreenTitle('PPS');
  const [informePps, setInformePps] = useState<InformePpsType>();
  const [data, setData] = useState<Pps[]>();
  const [currentFormValues, setCurrentFormValues] =
    useState<currentFormValuesType>({
      anio: '',
      seccion: '',
      estado: '',
    });

  const backButtonCallback = useCallback(() => {
    setInformePps(undefined);
  }, []);
  useBackButton('/private/pps', 0, backButtonCallback);

  return (
    <>
      {!informePps?.row ? (
        <TableAlumnos
          setInformePps={setInformePps}
          setData={setData}
          setCurrentFormValues={setCurrentFormValues}
          currentFormValues={currentFormValues}
        />
      ) : (
        <PPSFormProvider data={data} informePps={informePps}>
          <TabsCargaPps setInformePps={setInformePps} />
        </PPSFormProvider>
      )}
    </>
  );
};
