import { makeStyles } from '@material-ui/core/styles';
import { DRAWER_WITH_CLOSE, DRAWER_WITH_OPEN } from '../../const/ui/sideMenu';
import { grey, primary } from '../../../theme/colors/index';

const drawerWidthOpen = DRAWER_WITH_OPEN;
const drawerWithClose = DRAWER_WITH_CLOSE;

export const navBarStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    '@media print': {
      display: 'none',
    },
  },
  drawerOpenContainer: {
    transition: '0.8s',
    width: drawerWidthOpen,
    marginLeft: 15,
  },
  drawerCloseContainer: {
    transition: '0.8s',
    width: drawerWithClose,
    marginLeft: 15,
  },
  drawerPaperOpen: {
    transition: '0.8s',
    marginTop: 10,
    width: drawerWidthOpen,
  },
  drawerPaperClose: {
    transition: '0.8s',
    marginTop: 10,
    width: drawerWithClose,
    overflow: 'hidden',
  },
  iconContainer: {
    height: 37,
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  },
  iconContainerRole: {
    height: 37,
    alignItems: 'center',
    justifyContent: 'flex-start',
    display: 'flex',
    paddingLeft: 25,
  },
  selectedIcon: {
    textDecoration: 'none',
    height: 37,
    width: drawerWidthOpen,
    color: primary.white,
    backgroundColor: primary.lightBlue,
    display: 'flex',
    paddingLeft: 25,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  defaultIcon: {
    textDecoration: 'none',
    height: 37,
    width: drawerWidthOpen,
    paddingLeft: 25,
    color: grey.medium,
    backgroundColor: primary.white,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  arrowContainer: {
    flexDirection: 'row',
    alignSelf: 'center',
  },
  arrowIcon: {
    paddingTop: 10,
    fontSize: 30,
    height: 30,
    color: grey.medium,
  },
  titleStyle: {
    transition: '0.5s',
    fontSize: 13,
    paddingLeft: 15,
    color: grey.medium,
    textDecoration: 'none',
  },
  selectedTitle: {
    transition: '0.5s',
    fontSize: 13,
    paddingLeft: 15,
    color: primary.white,
    textDecoration: 'none',
  },
  iconButtonStyle: {
    backgroundColor: primary.white,
    '&:hover': {
      backgroundColor: primary.white,
    },
  },
  defaultIconRole: {
    color: grey.medium,
  },
  blankContainer: {
    height: '100%',
    cursor: 'pointer',
  },
}));
