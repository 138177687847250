import {
  InterdisciplinarFeedback,
  ArealInterarealFeedback,
  DisciplinarComunFeedback,
  DisciplinarOrientadaFeedback,
} from 'src/screens/Private/EspaciosCurriculares/EspacioCurricular/Planificacion/feedbacks';
import { MenuItem } from '../../../../../commons/const/ui/menu';

export const planificacionesFeedbackRoutes: MenuItem[] = [
  {
    key: 'interArealInterAreal',
    basePath: '/interArealInterAreal/:idPlanificacion',
    target: {
      component: ArealInterarealFeedback,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Planificacion Areal/Interareal',
    children: [],
  },
  {
    key: 'interArealInterDisciplinar',
    basePath: '/interDisciplinar/:idPlanificacion',
    target: {
      component: InterdisciplinarFeedback,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Planificacion InterDisciplinar',
    children: [],
  },
  {
    key: 'disciplinar',
    basePath: '/disciplinar/:idPlanificacion',
    target: {
      component: DisciplinarComunFeedback,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Planificacion Disciplinar',
    children: [],
  },
  {
    key: 'disciplinarOrientada',
    basePath: '/disciplinarOrientada/:idPlanificacion',
    target: {
      component: DisciplinarOrientadaFeedback,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Planificacion Disciplinar Orientada',
    children: [],
  },
];
