import { DiaDAO } from 'src/platform/libby/dao/DiaDAO';
import { makeBusinessEntity } from '../../lib/libby/generator';
import { Dia } from '../models';

const DAO_NAME = 'dia';

const {
  hook: { libbyFetch },
} = makeBusinessEntity<Dia, DiaDAO>(DAO_NAME, {});

// hooks
export const useDiasLibbyFetch = libbyFetch;
