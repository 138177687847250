import { cloneDeep } from 'lodash';

export const useInformeCuatrimestralCuartoBimestreValidations = () => {
  const someOptionalFieldsAreFilled = (requiredFields: string[], row: any) => {
    const rowToCheck = cloneDeep(row);
    let someOptionalFieldIsFilled = false;

    if (!Boolean(rowToCheck?.nota?.data)) return someOptionalFieldIsFilled;

    const rowFilledFields = Object.keys(rowToCheck?.nota?.data);

    someOptionalFieldIsFilled = rowFilledFields.some(
      (field: any) => !requiredFields.includes(field),
    );

    return someOptionalFieldIsFilled;
  };

  const requiredFieldsAreCompleted = (requiredFields: string[], row: any) => {
    const rowToCheck = cloneDeep(row);
    let allRequiredFieldsAreCompleted = true;

    if (
      !Boolean(rowToCheck?.nota?.data) ||
      Object.keys(rowToCheck?.nota?.data)?.length === 0
    )
      return allRequiredFieldsAreCompleted;

    const rowFilledFields = Object.keys(rowToCheck?.nota?.data);

    const fieldsChecked = rowFilledFields.filter((field: any) => {
      return Boolean(requiredFields.includes(field));
    });

    allRequiredFieldsAreCompleted =
      fieldsChecked.length === requiredFields.length;

    return allRequiredFieldsAreCompleted;
  };

  const validateRequiredField = (
    selectedField: string,
    requiredFields: string[],
    row: any,
  ) => {
    const rowToCheck = cloneDeep(row);
    let isRequired = false;

    if (!Boolean(rowToCheck?.nota?.data)) return isRequired;
    if (!requiredFields.find((field) => field === selectedField))
      return isRequired;

    const rowFilledFields = Object.keys(rowToCheck?.nota?.data);

    const fieldsToCheck = requiredFields.filter(
      (field: any) => field !== selectedField,
    );

    const selectedFieldIsFilled = rowFilledFields.includes(selectedField);

    const someRequiredFieldsAreFilled = rowFilledFields.some(
      (filledField: string) => {
        return fieldsToCheck.some(
          (fieldToCheck) => fieldToCheck === filledField,
        );
      },
    );

    if (
      someOptionalFieldsAreFilled(requiredFields, row) &&
      requiredFields.includes(selectedField) &&
      !selectedFieldIsFilled
    )
      isRequired = true;

    if (someRequiredFieldsAreFilled && !selectedFieldIsFilled)
      isRequired = true;

    return isRequired;
  };

  const isReadyToSave = (fieldsToValidate: string[], rows: any[]) => {
    const rowsToCheck = cloneDeep(rows);
    let everyRowIsReadyToSave = false;
    let someRowIsNotReadyToSave = true;
    if (rowsToCheck.length === 0) {
      return everyRowIsReadyToSave;
    }

    someRowIsNotReadyToSave = rowsToCheck.some((row: any) => {
      const rowToCheck = cloneDeep(row);
      const requiredAreCompleted = requiredFieldsAreCompleted(
        fieldsToValidate,
        rowToCheck,
      );
      const rowIsNotReady = !requiredAreCompleted;
      return rowIsNotReady;
    });

    everyRowIsReadyToSave = !someRowIsNotReadyToSave;
    return everyRowIsReadyToSave;
  };

  return { validateRequiredField, requiredFieldsAreCompleted, isReadyToSave };
};
