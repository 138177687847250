import { makeBusinessEntity } from '../../lib/libby/generator';

const DAO_NAME = 'geolocalizacion';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<any>(DAO_NAME);

// hooks
export const useGeolocalizacionFetchById = fetchById;
export const useGeolocalizacionLibbyCall = libbyCall;
export const useGeolocalizacionDAO = libbyDAO;
export const useGeolocalizacionLibbyFetch = libbyFetch;

// components/hoc
export const GeolocalizacionAutocomplete = entityAutocomplete;
export const GeolocalizacionCall = renderPropCall;
export const GeolocalizacionById = renderPropFetchById;
export const GeolocalizacionSelect = simpleSelect;
export const GeolocalizacionAccordionSelector = accordionSelector;
export const GeolocalizacionSelectedChips = selectedChips;

// context
// ByIdContext
export const useGeolocalizacionByIdContext = useByIdContext;
export const GeolocalizacionByIdProvider = ByIdProvider;
export const GeolocalizacionByIdContext = ByIdContext;
// GeneralContext
export const useGeolocalizacionContext = useGeneralContext;
export const GeolocalizacionProvider = GeneralProvider;
export const GeolocalizacionContext = GeneralContext;
