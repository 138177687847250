import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { ActividadExtracurricular } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'actividad_extracurricular';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idActividadExtracurricular',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idActividadExtracurricular',
      labelKey: 'descripcion',
    },
    withSimpleSelect: {
      name: 'actividadExtracurricular',
      title: 'Actividad Extracurricular',
      placeholder: 'Seleccioná materia, taller o servicio',
      labelKey: 'descripcionActividad',
      valueKey: 'idActividadExtracurricular',
    },
  },
};

const optionsToUse = !businessOptions.ActividadExtracurricular
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.ActividadExtracurricular,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.ActividadExtracurricular.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<ActividadExtracurricular>(DAO_NAME, optionsToUse);

// hooks
export const useActividadExtracurricularFetchById = fetchById;
export const useActividadExtracurricularLibbyCall = libbyCall;
export const useActividadExtracurricularDAO = libbyDAO;
export const useActividadExtracurricularLibbyFetch = libbyFetch;

// components/hoc
export const ActividadExtracurricularAutocomplete = entityAutocomplete;
export const ActividadExtracurricularCall = renderPropCall;
export const ActividadExtracurricularById = renderPropFetchById;
export const ActividadExtracurricularSelect = simpleSelect;
export const ActividadExtracurricularAccordionSelector = accordionSelector;
export const ActividadExtracurricularSelectedChips = selectedChips;

// context
// ByIdContext
export const useActividadExtracurricularByIdContext = useByIdContext;
export const ActividadExtracurricularByIdProvider = ByIdProvider;
export const ActividadExtracurricularByIdContext = ByIdContext;
// GeneralContext
export const useActividadExtracurricularContext = useGeneralContext;
export const ActividadExtracurricularProvider = GeneralProvider;
export const ActividadExtracurricularContext = GeneralContext;
