import {
  useAnioLibbyCall,
  useCicloLibbyCall,
  useNivelLibbyCall,
  useTurnoLibbyCall,
} from '../../../../../../app/business';
import { Anio, Ciclo, Nivel, Turno } from '../../../../../../app/models';

export const useRegisteredEntitiesCall = () => {
  const anio = useAnioLibbyCall<Anio[]>({ methodName: 'findAll' });
  const ciclo = useCicloLibbyCall<Ciclo[]>({ methodName: 'findAll' });
  const nivel = useNivelLibbyCall<Nivel[]>({ methodName: 'findAll' });
  const turno = useTurnoLibbyCall<Turno[]>({ methodName: 'findAll' });
  return {
    anio: anio.data || [],
    ciclo: ciclo.data || [],
    nivel: nivel.data || [],
    turno: turno.data || [],
    working:
      anio.working ||
      ciclo.working ||
      nivel.working ||
      turno.working ||
      !anio.data ||
      !ciclo.data ||
      !nivel.data ||
      !turno.data,
  };
};
