import { LibbyFetchDAO } from './LibbyFetchDAO';

export class TipoInformesDAO extends LibbyFetchDAO {
  constructor() {
    super('tipo_informes', 'idTipoInforme');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
