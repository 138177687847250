type MakeFilterProps = {
  search?: string;
  level?: string;
  cycle?: string;
  turn?: string;
  workingDay?: string;
  studyPlan?: string;
  locationId?: string;
  isAdultPromotion?: boolean;
};

export const makeFilter = ({
  search,
  level,
  cycle,
  turn,
  workingDay,
  studyPlan,
  locationId,
  isAdultPromotion,
}: MakeFilterProps) => {
  const filter: any = {}; // Todo: remove any type

  if (locationId) {
    filter.location = [
      {
        path: 'seccion.localizacion.idLocalizacion',
        value: locationId,
      },
    ];
  }

  if (search) {
    filter.search = [
      {
        path: 'alumno.persona.apellido',
        value: search,
        method: 'includes',
      },
      {
        path: 'alumno.persona.nombre',
        value: search,
        method: 'includes',
      },
      {
        path: isAdultPromotion
          ? 'seccion.division'
          : 'seccion.ciclo.descripcionCiclo',
        value: search,
        method: 'includes',
      },
      {
        path: 'seccion.turno.descripcionTurno',
        value: search,
        method: 'includes',
      },
      {
        path: 'seccion.nombreSeccion',
        value: search,
        method: 'includes',
      },
    ];
  }

  if (!isAdultPromotion && search) {
    filter.search = [
      ...filter.search,
      {
        path: 'seccion.jornada',
        value: search,
        method: 'includes',
      },
    ];
  }

  if (level) {
    filter.level = [
      {
        path: 'seccion.nivel.idNivel',
        value: level,
      },
    ];
  }

  if (cycle) {
    filter.cycle = [
      {
        path: isAdultPromotion ? 'seccion.division' : 'seccion.ciclo.idCiclo',
        method: isAdultPromotion ? 'includes' : 'equals',
        value: cycle,
      },
    ];
  }

  if (turn) {
    filter.turn = [
      {
        path: 'seccion.turno.idTurno',
        value: turn,
      },
    ];
  }

  if (!isAdultPromotion && workingDay) {
    filter.workingDay = [
      {
        path: 'seccion.jornada',
        value: workingDay,
      },
    ];
  }

  if (studyPlan) {
    filter.studyPlan = [
      {
        path: 'seccion.planEstudioNivel.idPlanEstudioNivel',
        value: studyPlan,
      },
    ];
  }

  return filter;
};
