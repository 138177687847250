import { Template } from '../../../lib/templates';

const relationIds = ['idTipoDocumento', 'idLocalizacion', 'idNivel'];

export const autoregistracionTemplate: Template = {
  name: 'Autoregistracion',
  template: [
    {
      name: 'nombre',
      type: {
        name: 'string',
      },
    },
    {
      name: 'apellido',
      type: {
        name: 'string',
      },
    },
    {
      name: 'documento',
      type: {
        name: 'string',
      },
    },
    {
      name: 'cuil',
      type: {
        name: 'string',
      },
      label: 'CUIL',
      validators: [
        {
          name: 'pattern',
          signature: ['(20|23|24|27|30|33|34)(-)?[0-9]{8}(-)?[0-9]$'],
        },
      ],
    },
    {
      name: 'cuentaBue',
      type: {
        name: 'string',
      },
      optional: true,
    },
    {
      name: 'idRolUsuario',
      type: { name: 'number' },
    },
    {
      name: 'isFuture',
      type: { name: 'boolean' },
    },
    {
      name: 'showCORole',
      type: { name: 'boolean' },
      optional: true,
    },
    {
      name: 'isHospitalary',
      type: { name: 'boolean' },
    },
    ...relationIds.map((name) => ({
      name,
      type: {
        name: 'number',
      },
    })),
  ],
};

export const autoregistracionTemplateSinPersona = {
  name: 'Autoregistracion',
  template: [
    {
      name: 'nombre',
      type: {
        name: 'string',
      },
    },
    {
      name: 'apellido',
      type: {
        name: 'string',
      },
    },
    {
      name: 'cuentaBue',
      type: {
        name: 'string',
      },
      optional: true,
    },
    {
      name: 'showCORole',
      type: { name: 'boolean' },
      optional: true,
    },
    {
      name: 'idRolUsuario',
      type: { name: 'number' },
    },
    {
      name: 'idLocalizacion',
      type: { name: 'number' },
    },
    {
      name: 'idNivel',
      type: { name: 'number' },
    },
    {
      name: 'isFuture',
      type: { name: 'boolean' },
    },
    {
      name: 'isHospitalary',
      type: { name: 'boolean' },
    },
  ],
};

export const tutorRoles = {
  name: 'roles',
  type: {
    name: 'array',
    kind: [
      {
        name: 'idSeccion',
        type: { name: 'number' },
      },
    ],
  },
};

export const docenteRoles = {
  name: 'roles',
  type: {
    name: 'array',
    kind: [
      {
        name: 'idSeccion',
        type: { name: 'number' },
      },
      {
        name: 'idNivel',
        type: { name: 'number' },
      },
      {
        name: 'idEspacioCurricular',
        type: { name: 'number' },
      },
    ],
  },
};

export const directivoRoles = {
  name: 'idNivel',
  type: { name: 'number' },
};

export const coordinadorRoles = {
  name: 'roles',
  type: {
    name: 'array',
    kind: [
      { name: 'idNivel', type: { name: 'number' } },
      { name: 'idArea', type: { name: 'number' } },
    ],
  },
};

export const coordinadorOrientacionRoles = {
  name: 'roles',
  type: {
    name: 'array',
    kind: [
      { name: 'idNivel', type: { name: 'number' } },
      { name: 'idOrientacion', type: { name: 'number' } },
    ],
  },
};
