import React from 'react';
import { FormInputV2 } from 'src/lib/templates';
import { useDevolucionOrganizacionFormContext } from '../hooks/useDevolucionOrgFormContext';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  inputContainer: {
    display: 'flex',
    // alignItems: 'center',
    width: '40%',
  },
  label: {
    margin: '0 1em 0 0',
    fontFamily: 'Open Sans',
  },
});

export const ObservacionesField = () => {
  const { form } = useDevolucionOrganizacionFormContext();
  const classes = useStyles();

  return (
    <div className={classes.inputContainer}>
      <h4 className={classes.label}>Observaciones</h4>
      <FormInputV2
        name="observaciones"
        form={form}
        multiline
        fullWidth
        placeholder="Puedes agregar una observación para el estudiante"
        minRows={6}
      />
    </div>
  );
};
