import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Supervision } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'supervision';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idSupervision',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idSupervision',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Supervision
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Supervision,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Supervision.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Supervision>(DAO_NAME, optionsToUse);

// hooks
export const useSupervisionFetchById = fetchById;
export const useSupervisionLibbyCall = libbyCall;
export const useSupervisionDAO = libbyDAO;
export const useSupervisionLibbyFetch = libbyFetch;

// components/hoc
export const SupervisionAutocomplete = entityAutocomplete;
export const SupervisionCall = renderPropCall;
export const SupervisionById = renderPropFetchById;
export const SupervisionSelect = simpleSelect;
export const SupervisionAccordionSelector = accordionSelector;
export const SupervisionSelectedChips = selectedChips;

// context
// ByIdContext
export const useSupervisionByIdContext = useByIdContext;
export const SupervisionByIdProvider = ByIdProvider;
export const SupervisionByIdContext = ByIdContext;
// GeneralContext
export const useSupervisionContext = useGeneralContext;
export const SupervisionProvider = GeneralProvider;
export const SupervisionContext = GeneralContext;
