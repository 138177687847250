import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField, makeStyles } from '@material-ui/core';
import { uniqBy } from 'lodash';
import { Loading } from 'src/commons/components/Loading';
import { Orientacion, Materia } from '../../models';
import { AnyObject } from '../../../commons/types';
import { useMateriaLibbyCall } from '../Materia';
import { useOrientacionMateriaLibbyFetch } from '../OrientacionMateria';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 5,
    },
  },
}));

type OnChangeSingle = (newValue: Orientacion) => void;
type OnChangeMulti = (newValue: Orientacion[]) => void;

export interface EspaciosOrientacionesAutocompleteProps {
  autoCompleteProps?: any;
  multiple?: boolean;
  value: Orientacion | Orientacion[];
  onChange: OnChangeSingle | OnChangeMulti;
  filter?: AnyObject;
  textFieldProps?: AnyObject;
  orientacionMateriaFilter?: AnyObject;
}

const dummyFilter = {};

export const EspaciosOrientacionesAutocomplete = ({
  multiple = false,
  autoCompleteProps,
  value,
  onChange,
  filter = dummyFilter,
  textFieldProps = dummyFilter,
  orientacionMateriaFilter,
}: EspaciosOrientacionesAutocompleteProps) => {
  const classes = useStyles();

  const {
    working,
    data: options,
    recall,
  } = useMateriaLibbyCall<Materia[]>({
    methodName: 'fetch',
    params: [],
    noAutoCall: true,
  });

  const orientacionMateriaEnabled = useMemo(() => {
    if (!!orientacionMateriaFilter) {
      return orientacionMateriaFilter.espacio.length;
    }
  }, [orientacionMateriaFilter]);

  const { data: orientacionMateria = [] } = useOrientacionMateriaLibbyFetch({
    limit: 700,
    enabled: !!orientacionMateriaEnabled,
    filter: orientacionMateriaFilter,
  });

  useEffect(() => {
    if (recall) {
      const params = { filter, limit: 1000 };
      recall(params);
    }
  }, [filter, recall]);

  const orientaciones = useMemo(
    () => (!options ? [] : options.map((item) => item.orientacion)),
    [options],
  );

  const orientacionesOptions = useMemo(() => {
    const orientacionesArray = [];
    const cicloBasico = orientaciones.filter(
      (element: any) => element?.idOrientacion === 1,
    );

    if (orientacionMateria) {
      const orientaciones = orientacionMateria.map(
        (item: any) => item.orientacion,
      );
      orientacionesArray.push(...orientaciones);
      orientacionesArray.push(...cicloBasico);
    }

    return orientacionesArray;
  }, [orientacionMateria, orientaciones]);

  const uniqueOrientations = useMemo(
    () => uniqBy(orientacionesOptions, (e) => e.idOrientacion),
    [orientacionesOptions],
  );

  const onChangeAutoComplete = useCallback(
    (event, newValue: Orientacion & Orientacion[]) => {
      onChange(newValue);
    },
    [onChange],
  );

  const getOptionLabel = useCallback(
    (option: Orientacion) => option.descripcionOrientacion,
    [],
  );

  useEffect(() => onChange(uniqueOrientations), [onChange, uniqueOrientations]);

  return (
    <>
      {!working && options && (
        <Autocomplete
          style={{ display: 'none' }}
          classes={classes}
          multiple={multiple}
          options={uniqueOrientations}
          onChange={onChangeAutoComplete}
          value={value || (multiple ? [] : undefined)}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" {...textFieldProps} />
          )}
          getOptionLabel={getOptionLabel}
          getOptionSelected={(option: Orientacion, _value: Orientacion) =>
            option.idOrientacion === _value.idOrientacion
          }
          {...(autoCompleteProps || {})}
        />
      )}
      {working && !options && <Loading />}
    </>
  );
};
