import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { EspaciosSimpDiscipPlan } from '../../../../../../app/models';
import { InfoTable } from '../../../../../../commons/components/InfoTable';
import { useEspaciosSimpDiscipPlanLibbyFetch } from '../../../../../../app/business';
import { useDisciplinarTableColumns } from './hooks';

export interface DisciplinarTableProps {
  idEspacioCurricular: string;
  selectValues: any;
  search: any;
  createdPlanificationCheck: (list: number) => void;
  cicloFilter: {
    disciplinar: string;
    interareal: string;
    interdisciplinar: string;
    value: string;
    method: string;
  };
}

export const DisciplinarTable = ({
  idEspacioCurricular,
  selectValues,
  search,
  cicloFilter,
  createdPlanificationCheck,
}: DisciplinarTableProps) => {
  const [direction, setDirection] = React.useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState(
    'espacioCurricular.materia.area.descripcion',
  );
  const [filter, setFilter] = useState<any>({
    espacio: [
      {
        path: 'espacioCurricular.idEspacioCurricular',
        value: idEspacioCurricular,
      },
    ],
  });

  const espacioCurricular = useMemo(
    () => ({
      espacio: [
        {
          path: 'espacioCurricular.idEspacioCurricular',
          value: idEspacioCurricular,
        },
      ],
    }),
    [idEspacioCurricular],
  );

  const filterObj = useCallback(
    (searching) => {
      if (!idEspacioCurricular) {
        setFilter({ ...espacioCurricular, searching });
      } else {
        setFilter({
          ...espacioCurricular,
          selectValues,
          searching,
          cicloFilter: cicloFilter?.disciplinar
            ? [
                {
                  path: cicloFilter.disciplinar,
                  method: cicloFilter.method,
                  value: cicloFilter.value,
                },
              ]
            : [],
        });
      }
    },
    [
      idEspacioCurricular,
      espacioCurricular,
      selectValues,
      cicloFilter.disciplinar,
      cicloFilter.method,
      cicloFilter.value,
    ],
  );

  useEffect(() => {
    filterObj(search);
  }, [selectValues, search, filterObj]);

  const {
    data: planinifaciones,
    fetchMore,
    working,
    reFetch,
  } = useEspaciosSimpDiscipPlanLibbyFetch({
    filter,
    orderBy,
    direction,
    aspect: 'limit_province',
  });
  const columns = useDisciplinarTableColumns(reFetch);

  useEffect(() => {
    if (createdPlanificationCheck) {
      createdPlanificationCheck(planinifaciones?.length as number);
    }
  }, [createdPlanificationCheck, planinifaciones]);

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setDirection(newDirection);
    setOrderBy(newOrderBy);
  };

  return (
    <>
      <InfoTable
        rows={planinifaciones as EspaciosSimpDiscipPlan[]}
        columns={columns}
        working={working}
        rowIdKey="idEspaciosSimpDiscipPlan"
        direction={direction}
        orderBy={orderBy}
        onSortChange={handleRequestSort}
        onBottomScroll={fetchMore}
      />
    </>
  );
};
