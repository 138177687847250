import { LibbyFetchDAO } from './LibbyFetchDAO';

export class PlanificacionDisciplinarOrientadaTemplateDAO extends LibbyFetchDAO {
  constructor() {
    super('orient_discip_temp_plan', 'idOrientDiscipTempPlan');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
