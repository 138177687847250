import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { DeudaAcademica } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'deuda_academica';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idDeudaAcademica',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idDeudaAcademica',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.DeudaAcademica
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.DeudaAcademica,
      hoc: { ...defaultOptions.hoc, ...businessOptions.DeudaAcademica.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<DeudaAcademica>(DAO_NAME, optionsToUse);

// hooks
export const useDeudaAcademicaFetchById = fetchById;
export const useDeudaAcademicaLibbyCall = libbyCall;
export const useDeudaAcademicaDAO = libbyDAO;
export const useDeudaAcademicaLibbyFetch = libbyFetch;

// components/hoc
export const DeudaAcademicaAutocomplete = entityAutocomplete;
export const DeudaAcademicaCall = renderPropCall;
export const DeudaAcademicaById = renderPropFetchById;
export const DeudaAcademicaSelect = simpleSelect;
export const DeudaAcademicaAccordionSelector = accordionSelector;
export const DeudaAcademicaSelectedChips = selectedChips;

// context
// ByIdContext
export const useDeudaAcademicaByIdContext = useByIdContext;
export const DeudaAcademicaByIdProvider = ByIdProvider;
export const DeudaAcademicaByIdContext = ByIdContext;
// GeneralContext
export const useDeudaAcademicaContext = useGeneralContext;
export const DeudaAcademicaProvider = GeneralProvider;
export const DeudaAcademicaContext = GeneralContext;
