import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { PersonalEducativoCargo } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'personal_educativo_cargo';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPersonalEducativoCargo',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPersonalEducativoCargo',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.PersonalEducativoCargo
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.PersonalEducativoCargo,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.PersonalEducativoCargo.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<PersonalEducativoCargo>(DAO_NAME, optionsToUse);

// hooks
export const usePersonalEducativoCargoFetchById = fetchById;
export const usePersonalEducativoCargoLibbyCall = libbyCall;
export const usePersonalEducativoCargoDAO = libbyDAO;
export const usePersonalEducativoCargoLibbyFetch = libbyFetch;

// components/hoc
export const PersonalEducativoCargoAutocomplete = entityAutocomplete;
export const PersonalEducativoCargoCall = renderPropCall;
export const PersonalEducativoCargoById = renderPropFetchById;
export const PersonalEducativoCargoSelect = simpleSelect;
export const PersonalEducativoCargoAccordionSelector = accordionSelector;
export const PersonalEducativoCargoSelectedChips = selectedChips;

// context
// ByIdContext
export const usePersonalEducativoCargoByIdContext = useByIdContext;
export const PersonalEducativoCargoByIdProvider = ByIdProvider;
export const PersonalEducativoCargoByIdContext = ByIdContext;
// GeneralContext
export const usePersonalEducativoCargoContext = useGeneralContext;
export const PersonalEducativoCargoProvider = GeneralProvider;
export const PersonalEducativoCargoContext = GeneralContext;
