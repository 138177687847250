import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { EspaciosDiscipOrientPlan } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'espacios_discip_orient_plan';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEspaciosDiscipOrientPlan',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEspaciosDiscipOrientPlan',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EspaciosDiscipOrientPlan
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EspaciosDiscipOrientPlan,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.EspaciosDiscipOrientPlan.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EspaciosDiscipOrientPlan>(DAO_NAME, optionsToUse);

// hooks
export const useEspaciosDiscipOrientPlanFetchById = fetchById;
export const useEspaciosDiscipOrientPlanLibbyCall = libbyCall;
export const useEspaciosDiscipOrientPlanDAO = libbyDAO;
export const useEspaciosDiscipOrientPlanLibbyFetch = libbyFetch;

// components/hoc
export const EspaciosDiscipOrientPlanAutocomplete = entityAutocomplete;
export const EspaciosDiscipOrientPlanCall = renderPropCall;
export const EspaciosDiscipOrientPlanById = renderPropFetchById;
export const EspaciosDiscipOrientPlanSelect = simpleSelect;
export const EspaciosDiscipOrientPlanAccordionSelector = accordionSelector;
export const EspaciosDiscipOrientPlanSelectedChips = selectedChips;

// context
// ByIdContext
export const useEspaciosDiscipOrientPlanByIdContext = useByIdContext;
export const EspaciosDiscipOrientPlanByIdProvider = ByIdProvider;
export const EspaciosDiscipOrientPlanByIdContext = ByIdContext;
// GeneralContext
export const useEspaciosDiscipOrientPlanContext = useGeneralContext;
export const EspaciosDiscipOrientPlanProvider = GeneralProvider;
export const EspaciosDiscipOrientPlanContext = GeneralContext;
