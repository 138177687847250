import { JustificacionMotivoDAO } from 'src/platform/libby/dao';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { JustificacionMotivo } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'justificacionMotivos';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idMotivoJustificacion',
      labelKey: 'description',
    },
    withSelectedChips: {
      idKey: 'idMotivoJustificacion',
      labelKey: 'description',
    },
    withSimpleSelect: {
      labelKey: 'description',
      valueKey: 'idMotivoJustificacion',
      title: '',
      placeholder: 'Seleccioná Motivo',
      name: 'idMotivoJustificacion',
    },
  },
};

const optionsToUse = !businessOptions.JustificacionMotivo
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.JustificacionMotivo,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.JustificacionMotivo.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    selectInput,
  },
} = makeBusinessEntity<JustificacionMotivo, JustificacionMotivoDAO>(
  DAO_NAME,
  optionsToUse,
);

// hooks
export const useJustificacionMotivoFetchById = fetchById;
export const useJustificacionMotivoLibbyCall = libbyCall;
export const useJustificacionMotivoDAO = libbyDAO;
export const useJustificacionMotivoLibbyFetch = libbyFetch;

// components/hoc
export const JustificacionMotivoAutocomplete = entityAutocomplete;
export const JustificacionMotivoCall = renderPropCall;
export const JustificacionMotivoById = renderPropFetchById;
export const JustificacionMotivoSelect = simpleSelect;

export const JustificacionMotivoSelectInput = selectInput;
