import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { OfertaTurno } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'ofertas_turnos';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idOfertasTurnos',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idOfertasTurnos',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.OfertasTurnos
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.OfertasTurnos,
      hoc: { ...defaultOptions.hoc, ...businessOptions.OfertasTurnos.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<OfertaTurno>(DAO_NAME, optionsToUse);

// hooks
export const useOfertasTurnosFetchById = fetchById;
export const useOfertasTurnosLibbyCall = libbyCall;
export const useOfertasTurnosDAO = libbyDAO;
export const useOfertasTurnosLibbyFetch = libbyFetch;

// components/hoc
export const OfertasTurnosAutocomplete = entityAutocomplete;
export const OfertasTurnosCall = renderPropCall;
export const OfertasTurnosById = renderPropFetchById;
export const OfertasTurnosSelect = simpleSelect;
export const OfertasTurnosAccordionSelector = accordionSelector;
export const OfertasTurnosSelectedChips = selectedChips;

// context
// ByIdContext
export const useOfertasTurnosByIdContext = useByIdContext;
export const OfertasTurnosByIdProvider = ByIdProvider;
export const OfertasTurnosByIdContext = ByIdContext;
// GeneralContext
export const useOfertasTurnosContext = useGeneralContext;
export const OfertasTurnosProvider = GeneralProvider;
export const OfertasTurnosContext = GeneralContext;
