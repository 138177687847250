import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { TemplatePropertyType } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'template_property_type';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idTemplatePropertyType',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idTemplatePropertyType',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.TemplatePropertyType
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.TemplatePropertyType,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.TemplatePropertyType.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<TemplatePropertyType>(DAO_NAME, optionsToUse);

// hooks
export const useTemplatePropertyTypeFetchById = fetchById;
export const useTemplatePropertyTypeLibbyCall = libbyCall;
export const useTemplatePropertyTypeDAO = libbyDAO;
export const useTemplatePropertyTypeLibbyFetch = libbyFetch;

// components/hoc
export const TemplatePropertyTypeAutocomplete = entityAutocomplete;
export const TemplatePropertyTypeCall = renderPropCall;
export const TemplatePropertyTypeById = renderPropFetchById;
export const TemplatePropertyTypeSelect = simpleSelect;
export const TemplatePropertyTypeAccordionSelector = accordionSelector;
export const TemplatePropertyTypeSelectedChips = selectedChips;

// context
// ByIdContext
export const useTemplatePropertyTypeByIdContext = useByIdContext;
export const TemplatePropertyTypeByIdProvider = ByIdProvider;
export const TemplatePropertyTypeByIdContext = ByIdContext;
// GeneralContext
export const useTemplatePropertyTypeContext = useGeneralContext;
export const TemplatePropertyTypeProvider = GeneralProvider;
export const TemplatePropertyTypeContext = GeneralContext;
