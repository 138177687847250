import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { ContenidoBloqueSubnivelDos } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'contenido_bloque_subnivel_dos';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idContenidoBloqueSubnivelDos',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idContenidoBloqueSubnivelDos',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.ContenidoBloqueSubnivelDos
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.ContenidoBloqueSubnivelDos,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.ContenidoBloqueSubnivelDos.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<ContenidoBloqueSubnivelDos>(DAO_NAME, optionsToUse);

// hooks
export const useContenidoBloqueSubnivelDosFetchById = fetchById;
export const useContenidoBloqueSubnivelDosLibbyCall = libbyCall;
export const useContenidoBloqueSubnivelDosDAO = libbyDAO;
export const useContenidoBloqueSubnivelDosLibbyFetch = libbyFetch;

// components/hoc
export const ContenidoBloqueSubnivelDosAutocomplete = entityAutocomplete;
export const ContenidoBloqueSubnivelDosCall = renderPropCall;
export const ContenidoBloqueSubnivelDosById = renderPropFetchById;
export const ContenidoBloqueSubnivelDosSelect = simpleSelect;
export const ContenidoBloqueSubnivelDosAccordionSelector = accordionSelector;
export const ContenidoBloqueSubnivelDosSelectedChips = selectedChips;

// context
// ByIdContext
export const useContenidoBloqueSubnivelDosByIdContext = useByIdContext;
export const ContenidoBloqueSubnivelDosByIdProvider = ByIdProvider;
export const ContenidoBloqueSubnivelDosByIdContext = ByIdContext;
// GeneralContext
export const useContenidoBloqueSubnivelDosContext = useGeneralContext;
export const ContenidoBloqueSubnivelDosProvider = GeneralProvider;
export const ContenidoBloqueSubnivelDosContext = GeneralContext;
