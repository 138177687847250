import { Template } from 'src/lib/templates';

export const AutoevaluacionFormTemplate: Template = {
  name: 'Autoevaluacion Estudiante Template',
  template: [
    {
      name: 'idGrupoInscripcion',
      type: { name: 'string' },
      validators: [],
    },
    {
      name: 'accion',
      type: { name: 'object' },
      validators: [],
    },
    {
      name: 'organizacion',
      type: { name: 'object' },
      validators: [],
    },
  ],
};

export const apreciacionTemplate: Template = {
  name: 'Apreciacion Auoevaluacion template',
  template: [
    {
      name: 'idApreciacion',
      type: { name: 'string' },
      optional: true,
    },
    {
      name: 'grupoInscripcion',
      type: { name: 'string' },
    },
    {
      name: 'observaciones',
      type: { name: 'string' },
      optional: true,
    },
    {
      name: 'observacionesReferente',
      type: { name: 'string' },
      optional: true,
    },
    {
      name: 'evaluaciones',
      type: {
        name: 'array',
        kind: [
          {
            name: 'idApreciacionValor',
            type: {
              name: 'string',
            },
            optional: true,
          },
          {
            name: 'criterioEvaluacion',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'idCriterioEvaluacion',
                  type: { name: 'number' },
                  optional: true,
                },
                {
                  name: 'nombre',
                  type: { name: 'string' },
                },
                {
                  name: 'activo',
                  type: {
                    name: 'boolean',
                  },
                },
                {
                  name: 'forOrganizacion',
                  type: {
                    name: 'boolean',
                  },
                },
              ],
            },
          },
          { name: 'valor', type: { name: 'number' } },
        ],
      },
      validators: [{ name: 'min', signature: [4] }],
    },
  ],
};

export const autoevaluacionTemplate: Template = {
  name: 'Autoevaluacion template',
  template: [
    {
      name: 'categoria',
      type: {
        name: 'number',
      },
    },
    {
      name: 'bloque',
      type: {
        name: 'number',
      },
    },
    {
      name: 'grupoInscripcion',
      type: {
        name: 'any',
      },
    },
    {
      name: 'perfiles',
      type: {
        name: 'object',
        kind: [
          {
            name: 'idAutoevaluacionPerfil',
            type: { name: 'number' },
            optional: true,
          },
          {
            name: 'observaciones',
            type: { name: 'string' },
            optional: true,
          },
          {
            name: 'observacionesReferente',
            type: { name: 'string' },
            optional: true,
          },
          {
            name: 'valores',
            type: {
              name: 'array',
              kind: [
                {
                  name: 'idDevolucionValor',
                  type: { name: 'string' },
                  optional: true,
                },
                {
                  name: 'idTipo',
                  type: { name: 'number' },
                },
                {
                  name: 'evaluacion',
                  type: { name: 'number' },
                },
                {
                  name: 'tab',
                  type: { name: 'string' },
                },
                {
                  name: 'idDevolucion',
                  type: { name: 'string' },
                  optional: true,
                },
              ],
            },
          },
        ],
      },
    },
    {
      name: 'habilidades',
      type: {
        name: 'object',
        kind: [
          {
            name: 'idAutoevaluacionHabilidad',
            type: { name: 'string' },
            optional: true,
          },
          {
            name: 'observaciones',
            type: { name: 'string' },
            optional: true,
          },
          {
            name: 'observacionesReferente',
            type: { name: 'string' },
            optional: true,
          },
          {
            name: 'valores',
            type: {
              name: 'array',
              kind: [
                {
                  name: 'idDevolucionValor',
                  type: { name: 'string' },
                  optional: true,
                },
                {
                  name: 'idTipo',
                  type: { name: 'number' },
                },
                {
                  name: 'evaluacion',
                  type: { name: 'number' },
                },
                {
                  name: 'tab',
                  type: { name: 'string' },
                },
                {
                  name: 'idDevolucion',
                  type: { name: 'string' },
                  optional: true,
                },
              ],
            },
          },
        ],
      },
    },
    {
      name: 'contenidos',
      type: {
        name: 'object',
        kind: [
          {
            name: 'idAutoevaluacionContenido',
            type: { name: 'string' },
            optional: true,
          },
          {
            name: 'observaciones',
            type: { name: 'string' },
            optional: true,
          },
          {
            name: 'observacionesReferente',
            type: { name: 'string' },
            optional: true,
          },
          {
            name: 'valores',
            type: {
              name: 'array',
              kind: [
                {
                  name: 'idDevolucionValor',
                  type: { name: 'string' },
                  optional: true,
                },
                {
                  name: 'idTipo',
                  type: { name: 'number' },
                },
                {
                  name: 'evaluacion',
                  type: { name: 'number' },
                },
                {
                  name: 'tab',
                  type: { name: 'string' },
                },
                {
                  name: 'idDevolucion',
                  type: { name: 'string' },
                  optional: true,
                },
              ],
            },
          },
        ],
      },
    },
  ],
  // template: [
  //   {
  //     name: 'pk',
  //     type: {
  //       name: 'string',
  //     },
  //     optional: true,
  //   },
  //   {
  //     name: 'observaciones',
  //     type: {
  //       name: 'string',
  //     },
  //     optional: true,
  //   },
  //   {
  //     name: 'observacionesReferente',
  //     type: {
  //       name: 'string',
  //     },
  //     optional: true,
  //   },
  //   {
  //     name: 'categoria',
  //     type: {
  //       name: 'number',
  //     },
  //   },
  //   {
  //     name: 'grupoInscripcion',
  //     type: {
  //       name: 'number',
  //     },
  //   },
  //   {
  //     name: 'items',
  //     type: {
  //       name: 'object',
  //       kind: [
  //         {
  //           name: 'perfiles',
  //           type: {
  //             name: 'array',
  //             kind: [
  //               {
  //                 name: 'idPerfilOrientado',
  //                 type: { name: 'number' },
  //               },
  //               {
  //                 name: 'descripcion',
  //                 type: { name: 'string' },
  //               },
  //               {
  //                 name: 'value',
  //                 type: { name: 'number' },
  //                 validators: [
  //                   { name: 'max', signature: [3] },
  //                   { name: 'min', signature: [0] },
  //                 ],
  //               },
  //             ],
  //           },
  //         },
  //         {
  //           name: 'habilidades',
  //           type: {
  //             name: 'array',
  //             kind: [
  //               {
  //                 name: 'idHabilidad',
  //                 type: { name: 'number' },
  //               },
  //               {
  //                 name: 'descripcion',
  //                 type: { name: 'string' },
  //               },
  //               {
  //                 name: 'value',
  //                 type: { name: 'number' },
  //                 validators: [
  //                   { name: 'max', signature: [3] },
  //                   { name: 'min', signature: [0] },
  //                 ],
  //               },
  //             ],
  //           },
  //         },
  //         {
  //           name: 'nodos',
  //           type: {
  //             name: 'array',
  //             kind: [
  //               {
  //                 name: 'idNodo',
  //                 type: { name: 'string' },
  //               },
  //               {
  //                 name: 'updatedAt',
  //                 type: { name: 'date' },
  //               },
  //               {
  //                 name: 'value',
  //                 type: { name: 'number' },
  //                 validators: [
  //                   { name: 'max', signature: [3] },
  //                   { name: 'min', signature: [0] },
  //                 ],
  //               },
  //               {
  //                 name: 'eje',
  //                 type: {
  //                   name: 'object',
  //                   kind: [
  //                     {
  //                       name: 'idEje',
  //                       type: { name: 'number' },
  //                     },
  //                     {
  //                       name: 'opcional',
  //                       type: { name: 'boolean' },
  //                     },
  //                     {
  //                       name: 'nombre',
  //                       type: { name: 'string' },
  //                     },
  //                   ],
  //                 },
  //               },
  //               {
  //                 name: 'contenido',
  //                 type: {
  //                   name: 'object',
  //                   kind: [
  //                     {
  //                       name: 'idContenido',
  //                       type: { name: 'string' },
  //                     },
  //                     {
  //                       name: 'visible',
  //                       type: { name: 'boolean' },
  //                     },
  //                     {
  //                       name: 'descripcion',
  //                       type: { name: 'string' },
  //                     },
  //                   ],
  //                 },
  //               },
  //               {
  //                 name: 'activo',
  //                 type: { name: 'boolean' },
  //               },
  //             ],
  //           },
  //         },
  //       ],
  //     },
  //   },
  // ],
};
