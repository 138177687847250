import React from 'react';
import { withStyles } from '@material-ui/styles';
import { Tooltip, Box, Zoom } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';

const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: 16,
    border: '1px solid #dadde9',
  },
}))(Tooltip);

interface TooltipCustonProps {
  title: NonNullable<React.ReactNode>;
  children: React.ReactElement<any, any>;
  typeTransition?: React.ComponentType<
    TransitionProps & { children?: React.ReactElement<any, any> }
  >;
}

export const Tooltips = ({
  title,
  children,
  typeTransition,
}: TooltipCustonProps) => {
  return (
    <CustomTooltip TransitionComponent={typeTransition || Zoom} title={title}>
      {children}
    </CustomTooltip>
  );
};
