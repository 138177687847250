const ESTADOS_DE_PASE = {
  PROMOCION_DIRECTA: 1,
  PERMANECE: 2,
  PROMOCION_EN_PROCESO: 4,
  PROMOCION_ACOMPAÑADA: 5,
  CONTINUA_CON_APOYO_PARA_LA_PROMOCION: 7,
  PROMOCION_CON_APOYO: 8,
};

const {
  PROMOCION_DIRECTA,
  PERMANECE,
  PROMOCION_EN_PROCESO,
  CONTINUA_CON_APOYO_PARA_LA_PROMOCION,
  PROMOCION_ACOMPAÑADA,
  PROMOCION_CON_APOYO,
} = ESTADOS_DE_PASE;

export const useFilterPassSectionSecundariaLogic = (
  options: any,
  statusPass: any,
  currentCicloLectivo: any,
  numeroAnio: any,
  descripcionAnioSection: any,
) => {
  let filteredOptionsSecundaria: any = [];
  const siguienteCicloLectivo: number = currentCicloLectivo + 1;
  const siguienteNumeroAnio: number = numeroAnio + 1;

  if ([PROMOCION_DIRECTA, PROMOCION_CON_APOYO].includes(statusPass)) {
    filteredOptionsSecundaria = options.filter((option: any) => {
      const siguienteCicloLectivoComparasion =
        option?.cicloLectivo?.anio === siguienteCicloLectivo;

      const siguienteAnioSimple =
        option?.anio?.numeroAnio === siguienteNumeroAnio;

      return siguienteAnioSimple && siguienteCicloLectivoComparasion;
    });
  } else if (statusPass === PERMANECE) {
    filteredOptionsSecundaria = options.filter((option: any) => {
      const siguienteCicloLectivoComparasion =
        option?.cicloLectivo?.anio === siguienteCicloLectivo;

      const mismoAnioSimple = option?.anio?.numeroAnio === numeroAnio;

      return mismoAnioSimple && siguienteCicloLectivoComparasion;
    });
  } else if (statusPass === PROMOCION_EN_PROCESO) {
    filteredOptionsSecundaria = options.filter((option: any) => {
      const siguienteCicloLectivoComparasion =
        option?.cicloLectivo?.anio === siguienteCicloLectivo;

      const siguienteAnioSimple =
        option?.anio?.numeroAnio === siguienteNumeroAnio;

      return siguienteAnioSimple && siguienteCicloLectivoComparasion;
    });
  }

  return filteredOptionsSecundaria;
};
