import { Grid, MobileStepper } from '@material-ui/core';
import React, { useCallback, useMemo, useState } from 'react';
import {
  useAlumnoDAO,
  usePersonaDAO,
  useMatriculaPersonaExternaDAO,
} from 'src/app/business';
import { AnyObject, LibbyObject } from 'src/commons';
import { Footer } from 'src/commons/components';
import confirmDialog from 'src/commons/services/confirmDialog';
import customFormDialog from 'src/commons/services/customFormDialog';
import { ExternalSelectStudent } from './components/ExternalSelectStudent';
import { ExternalSelectWorkShop } from './components/ExternalSelectWorkshop';

const customStyle = {
  container: { paddingBottom: '15px' },
  buttonBack: { border: '1px solid transparent' },
};

export const ExternalModal = ({
  libby,
  reFetch,
  reFetchSelect,
  localizacionId,
  toLegajoAlumno,
}: LibbyObject) => {
  const [saving, setSaving] = useState(false);
  const personaDAO = usePersonaDAO();
  const alumnoDAO = useAlumnoDAO();
  const externalPersonDAO = useMatriculaPersonaExternaDAO();
  const [formValues, setFormValues] = useState({
    actividadExtracurricular: '',
  });
  const [activeStep, setActiveStep] = useState(0);
  const [selectStudent, setSelectStudent] = useState<AnyObject | null>(null);

  const dataForm: AnyObject = useMemo(
    () => ({
      ...selectStudent,
      actividadExtracurricular: Number(formValues.actividadExtracurricular),
    }),
    [selectStudent, formValues],
  );
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleAddExternalAlumn = useCallback(
    async (alumn) => {
      try {
        setSaving(true);
        const newPerson = {
          nombre: alumn.name,
          apellido: alumn.lastName,
          genero: alumn.genre,
          paisNacimiento: alumn.country,
          documento: alumn.numDocument,
          tipoDocumento: alumn.typeDocument,
          fechaNacimiento: alumn.date,
        };
        const idPerson = await personaDAO.save(newPerson);
        const newAlumn = {
          persona: idPerson.idPersona,
          condicion: 1,
          estadoMatricula: 2,
        };
        const idAlumn = await alumnoDAO.save(newAlumn);
        const newExternalAlumn = {
          persona: idPerson.idPersona,
          alumno: idAlumn.idAlumno,
          localizacion: localizacionId,
          actividadExtracurricular: alumn.activity,
          localizacionMatriculadoEn: localizacionId,
          nivel: alumn.selectLevel,
        };
        const externalAlumn = await externalPersonDAO
          .aspect('limit_province')
          .save(newExternalAlumn);
        setSaving(false);
        toLegajoAlumno(idAlumn.idAlumno);
        await customFormDialog.handleCancel();
      } catch (e) {
        console.log(e);
      }
    },
    [personaDAO, alumnoDAO, externalPersonDAO, toLegajoAlumno, localizacionId],
  );

  const handleSubmit = useCallback(async () => {
    try {
      const { idAlumno, preview, ...externPerson } = dataForm;
      setSaving(true);
      await externalPersonDAO.aspect('limit_province').save(externPerson);
      setSaving(false);
      customFormDialog.handleCancel();
      reFetch();
      reFetchSelect();
    } catch (e) {
      await confirmDialog.show({
        title: 'Error',
        content: `El alumno ya se encuentra asignado en este taller, por favor intente nuevamente con otro taller`,
        confirmText: 'Entendido',
      });
      setSaving(false);
      customFormDialog.handleCancel();
    }
  }, [externalPersonDAO, reFetch, reFetchSelect, dataForm]);

  const buttonConfig: any = {
    0: [
      {
        title: 'Cancelar',
        handleOnClick: () => customFormDialog.handleCancel(),
        type: 'secondary',
        size: 'small',
      },
      {
        title: 'Siguiente',
        handleOnClick: () => handleNext(),
        size: 'small',
        disabled: !selectStudent,
      },
    ],
    1: [
      {
        title: 'Cancelar',
        handleOnClick: () => customFormDialog.handleCancel(),
        type: 'secondary',
        size: 'small',
        disabled: saving,
      },
      {
        title: 'Atrás',
        handleOnClick: () => handleBack(),
        type: 'secondary',
        size: 'small',
        customStyle: customStyle.buttonBack,
        disabled: saving,
      },
      {
        title: !saving ? 'Guardar' : 'Guardando...',
        handleOnClick: () => handleSubmit(),
        size: 'small',
        disabled: !formValues.actividadExtracurricular || saving,
      },
    ],
  };

  const handleChange = (e: React.FormEvent<EventTarget>): void => {
    const target = e.target as HTMLInputElement;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const screenSelectStudent = (
    <ExternalSelectStudent
      selectStudent={selectStudent}
      setSelectStudent={setSelectStudent}
      localizacionId={localizacionId}
      handleAddExternalAlumn={handleAddExternalAlumn}
    />
  );
  const screenWorkShop = (
    <ExternalSelectWorkShop
      rows={selectStudent?.preview}
      formValues={formValues}
      handleChange={handleChange}
      localizacionId={localizacionId}
    />
  );

  const tutorialSteps = [
    { render: screenSelectStudent },
    { render: screenWorkShop },
  ];
  const maxSteps = tutorialSteps.length;

  return (
    <>
      <Grid container style={customStyle.container}>
        {tutorialSteps[activeStep].render}
        <MobileStepper
          steps={maxSteps}
          activeStep={activeStep}
          backButton=""
          nextButton=""
        />
      </Grid>
      <Footer buttonConfig={buttonConfig[activeStep]} spacing={1} />
    </>
  );
};
