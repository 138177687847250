import React, { useState, useCallback } from 'react';
import { Menu, Typography, Grid, Divider } from '@material-ui/core';
import { useHistory } from 'react-router';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles } from '@material-ui/styles';
import { UserAvatar } from 'src/commons/components/UserAvatar';
import { AnyObject } from 'src/commons/types';
import { ListAvatar } from 'src/commons/components';
import { useRolesContext } from 'src/context/RolesContext';
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp';
import { primary, grey } from 'src/theme/colors';
import { RESTConnection, DatabaseConnector } from '@phinxlab/libby-rest-web';
import { ROL } from 'src/commons/const';
import { useUsuarioRolEstablecimientoDAO } from 'src/app/business';
import { useReloginDAO } from '../../../app/business/businessCustomEndpoints';

const useStyles = makeStyles({
  menuText: {
    fontFamily: 'Open Sans',
    '&:hover': {
      color: primary.lightBlue,
    },
  },
  mainContainer: {
    width: 354,
    borderRadius: 10,
  },
  headerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: 25,
  },
  userNameContainer: {
    flexDirection: 'column',
    alignSelf: 'center',
    padding: 20,
  },
  mailText: {
    color: grey.heading,
    fontSize: 14,
  },
  userText: {
    color: grey.heading,
    fontSize: 14,
    fontWeight: 700,
  },
  listContainer: {
    padding: 20,
  },
  footerContainer: {
    paddingLeft: 30,
    paddingRight: 30,
    padding: 10,
    alignItems: 'center',
    height: 45,
  },
  footerMenu: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 15,
  },
  footerText: {
    color: grey.heading,
    fontSize: 14,
    paddingLeft: 15,
    '&:hover': {
      color: primary.lightBlue,
    },
  },
  iconStyle: {
    fontSize: 20,
    color: grey.heading,
  },
});

type OptionsModalProps = {
  logout: () => void;
  libby: AnyObject;
};

const UserCardRaw = ({ logout, libby }: OptionsModalProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const history = useHistory();
  const roleContext = useRolesContext();
  const usuarioRolEstablecimientoDAO = useUsuarioRolEstablecimientoDAO();
  const userRoleList = roleContext.userRoles;
  const handleMenu = (event: AnyObject) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const reloginDAO = useReloginDAO();
  const { selectedRole } = roleContext;
  const handleClick = useCallback(
    async (roleInformation: AnyObject) => {
      if (selectedRole.rol === ROL.SUPERVISOR.toString()) {
        usuarioRolEstablecimientoDAO.save({
          idRolEstablecimiento: selectedRole.idUsuarioRol,
          localizacion: -1,
        });
      }
      const res = await reloginDAO.selectRole(
        roleInformation.idRolEstablecimiento.toString(),
      );
      const user = { ...res.user, isGuest: res.isGuest };
      // FIXME: esto es por algo que le falta a libby, quitarlo cuando libby logre reaccioner automaticamente a los cambios de tokens
      RESTConnection.defineHeader('x-chino-token', res.token);
      libby.session.notify('New session', user, res.token);
      history.push('/');
    },
    [
      libby.session,
      reloginDAO,
      selectedRole,
      usuarioRolEstablecimientoDAO,
      history,
    ],
  );

  return (
    <>
      <IconButton
        aria-label="change language"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
      >
        <UserAvatar
          name={roleContext.userInfo.name}
          avatarWidth={28}
          avatarHeight={28}
        />
      </IconButton>
      <Menu
        id="menu-appbar-language"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: classes.mainContainer }}
        open={!!anchorEl}
        onClose={handleClose}
      >
        <Grid className={classes.headerContainer}>
          <UserAvatar
            name={roleContext.userInfo.name}
            avatarWidth={65}
            avatarHeight={65}
          />
          <Grid className={classes.userNameContainer}>
            <Typography className={classes.userText}>
              {roleContext.userInfo.name}
            </Typography>
            <Typography className={classes.mailText}>
              {roleContext.userInfo.email}
            </Typography>
          </Grid>
        </Grid>

        <Divider />

        <Grid className={classes.listContainer}>
          {userRoleList.map((item: AnyObject) => (
            <ListAvatar
              key={`${item}`}
              item={item}
              onClick={() => handleClick(item)}
            />
          ))}
        </Grid>

        <Divider />

        <Grid className={classes.footerContainer}>
          <IconButton className={classes.footerMenu} onClick={logout}>
            <ExitToAppSharpIcon className={classes.iconStyle} />
            <Typography className={classes.footerText}>
              {' '}
              Cerrar Sesión{' '}
            </Typography>
          </IconButton>
        </Grid>
      </Menu>
    </>
  );
};

export const UserCard = DatabaseConnector(UserCardRaw)();
