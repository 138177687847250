import { DateRange } from 'react-date-range';
import React from 'react';
import { es } from 'date-fns/locale';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css';
import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  useMediaQuery,
} from '@material-ui/core';
import cx from 'classnames';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import moment from 'moment';
import { AppTheme } from '../../../../../theme';
import { Justificacion, Presente_v3 } from '../../../../../app/models';
import {
  COLORS,
  IndicatorColor,
} from '../../TomaPresentismo/components/Resume';

export interface DateRanges {
  startDate: Date;
  endDate: Date;
  key: 'selection' | 'indicator';
  color: IndicatorColor;
}

const useStyles = makeStyles((theme) => ({
  daterange: {
    display: 'flex',
    width: '100%',
    '& .rdrMonthsVertical': {},
    '& .rdrMonth': {
      width: '100%',
      padding: 0,
    },
    '& .rdrWeekDay': {
      fontFamily: 'Nunito',
      fontSize: 12,
      color: '#9FA1A4',
      textTransform: 'uppercase',
    },
    '& .rdrDayDisabled, & .rdrDayWeekend': {
      color: '#000000 !important',
    },
    '& .rdrDayNumber': {
      fontWeight: '500',
    },
    /*    '& .rdrStartEdge': {
           // INICIA EL RANGO le mete bordes
           borderLeft: "1px solid blue"
         },
         '& .rdrEndEdge': {
           // TERMINA EL RANGO
           borderRight: "1px solid blue"
         },
         '& .rdrInRange': {
           // CUANDO ESTA EN RANGO
           borderBottom: "1px solid blue", borderTop: "1px solid blue"
         },
         '& .rdrDayEndOfWeek': {
           // dia final le mete bordes
         },
         ' & .rdrDayStartOfWeek': {
           // INICIA SEMANA  mete bordes
         }, */
  },
}));

interface DateRangeComponentProps {
  ranges: DateRanges[];
  presentismos: Presente_v3[];
  minDate?: Date | undefined;
  maxDate?: Date | undefined;
  initialDate?: Date;
}

interface PresenteDates {
  idEstadoPresente: number;
  fecha: string;
  turno: number;
  justificacion?: Justificacion;
}

export const DateRangeComponent = ({
  ranges,
  presentismos,
  minDate,
  maxDate,
  initialDate,
}: DateRangeComponentProps) => {
  const classes = useStyles();

  return (
    <Box border="1px solid #E1E1E1" borderRadius={4} padding={2}>
      <DateRange
        className={cx(classes.daterange)}
        locale={es}
        ranges={ranges.map((r) => ({
          ...r,
          color: r.key === 'selection' ? '#E6EBF0' : 'transparent',
        }))}
        // preview={ranges.filter((r) => r.key === 'selection')[0]}
        showPreview={false}
        showDateDisplay={false}
        showMonthAndYearPickers={false}
        weekdayDisplayFormat="eeeee"
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        navigatorRenderer={getDateRangeHeader}
        onChange={(e) => console.log('cambie data range', e)}
        dayContentRenderer={DayDateRange(ranges)}
        minDate={minDate}
        maxDate={maxDate}
        shownDate={initialDate}
      />
    </Box>
  );
};

export const getDateRangeHeader = (
  currentFocusedDate: any,
  changeShownDate: (value: any, mode: any) => void,
  props: any,
) => (
  <HeaderDateRange
    changeShownDate={changeShownDate}
    currentFocusedDate={currentFocusedDate}
    props={props}
  />
);

interface HeaderDateRangeProps {
  currentFocusedDate: moment.Moment;
  changeShownDate: (value: any, mode: any) => void;
  props: any;
}

export const HeaderDateRange = ({
  changeShownDate,
  currentFocusedDate,
  props,
}: HeaderDateRangeProps) => {
  const currentDate = moment(currentFocusedDate).format('MMMM YYYY');
  const isUpXS = useMediaQuery<AppTheme>((theme) => theme.breakpoints.up('xs'));

  return (
    <Grid
      container
      direction="row"
      spacing={1}
      alignItems="center"
      justifyContent="space-between"
    >
      <Grid item>
        <IconButton
          size="small"
          onClick={() => changeShownDate(-1, 'monthOffset')}
        >
          <ChevronLeftRoundedIcon fontSize="small" />
        </IconButton>
      </Grid>
      <Grid item>
        <Box
          fontFamily="Nunito"
          fontSize={isUpXS ? 13 : 8}
          fontWeight="bold"
          color="#38485C"
        >
          {currentDate[0].toUpperCase()}
          {currentDate.slice(1)}
        </Box>
      </Grid>
      <Grid item>
        <IconButton
          size="small"
          onClick={() => changeShownDate(+1, 'monthOffset')}
        >
          <ChevronRightRoundedIcon fontSize="small" />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export const DayDateRange =
  (ranges: DateRanges[] = []) =>
  (day: moment.Moment) => {
    const current = moment(day).format('YYYY-MM-DD');
    const oneDay = ranges.find(
      (range) =>
        moment(range.startDate).format('YYYY-MM-DD') ===
        moment(day).format('YYYY-MM-DD'),
    );
    const range = ranges.find((range) => range.key === 'selection');
    const initRange = range
      ? moment(range.startDate).format('YYYY-MM-DD') ===
        moment(day).format('YYYY-MM-DD')
      : undefined;
    const endRange = range
      ? moment(range.endDate).format('YYYY-MM-DD') ===
        moment(day).format('YYYY-MM-DD')
      : undefined;
    const middleRange =
      initRange && endRange && range
        ? moment(day).format('YYYY-MM-DD') >
            moment(range.startDate).format('YYYY-MM-DD') &&
          moment(day).format('YYYY-MM-DD') <
            moment(range.endDate).format('YYYY-MM-DD')
        : undefined;

    const { typography, bgcolor } = oneDay
      ? COLORS[oneDay.color]
      : { typography: undefined, bgcolor: undefined };

    return (
      <Box
        fontFamily="Nunito"
        color={typography}
        bgcolor={bgcolor}
        position="absolute"
        width={25}
        height={25}
        display="flex"
        top={2}
        borderRadius="50%"
        className="rdrDayNumber"
      >
        {moment(day).format('DD')}
      </Box>
    );
  };
