import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { InformeEpa } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'informesepa';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idInformeEpa',
      labelKey: 'url',
    },
    withSelectedChips: {
      idKey: 'idInformeEpa',
      labelKey: 'url',
    },
  },
};

const optionsToUse = !businessOptions.InformeEpa
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.InformeEpa,
      hoc: { ...defaultOptions.hoc, ...businessOptions.InformeEpa.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<InformeEpa>(DAO_NAME, optionsToUse);

// hooks
export const useInformeEpaFetchById = fetchById;
export const useInformeEpaLibbyCall = libbyCall;
export const useInformeEpaDAO = libbyDAO;
export const useInformeEpaLibbyFetch = libbyFetch;

// components/hoc
export const InformeEpaAutocomplete = entityAutocomplete;
export const InformeEpaCall = renderPropCall;
export const InformeEpaById = renderPropFetchById;
export const InformeEpaSelect = simpleSelect;
export const InformeEpaAccordionSelector = accordionSelector;
export const InformeEpaSelectedChips = selectedChips;

// context
// ByIdContext
export const useInformeEpaByIdContext = useByIdContext;
export const InformeEpaByIdProvider = ByIdProvider;
export const InformeEpaByIdContext = ByIdContext;
// GeneralContext
export const useInformeEpaContext = useGeneralContext;
export const InformeEpaProvider = GeneralProvider;
export const InformeEpaContext = GeneralContext;
