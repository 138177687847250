import React, { useCallback, useEffect } from 'react';
import { Chip, Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import { PanelWithTitle } from '../../../../../../../commons/components/PanelWithTitle';
import {
  FormAutocomplete,
  FormFileInput,
  FormGenericControl,
  FormInput,
} from '../../../../../../../lib/templates/components';
import {
  useFormContext,
  useTemplateContext,
} from '../../../../../../../lib/templates/context';
import { RepositorioTipoItemSelect } from '../../../../../../../app/business';
import { LetterIcon } from './LetterIcon';
import { AnyObject } from '../../../../../../../commons/types';
import { REPOSITORIO_TIPO_ITEM } from '../../../../../../../commons/const';

const uploadTypes = [
  REPOSITORIO_TIPO_ITEM.AUDIO,
  REPOSITORIO_TIPO_ITEM.DOCUMENTO,
  REPOSITORIO_TIPO_ITEM.VIDEO,
  REPOSITORIO_TIPO_ITEM.PDF,
  REPOSITORIO_TIPO_ITEM.IMAGEN,
];

const nowYear = parseInt(moment().format('YYYY'), 10);
const fuenteAnios = [...new Array(500)].map((item: any, index: number) => ({
  anio: `${nowYear - index}`,
}));

const SPACING = 4;

const fileTemplate = {
  name: 'file',
  type: {
    name: 'object',
    kind: [
      {
        name: 'files',
        type: {
          name: 'any',
        },
      },
      {
        name: 'value',
        type: {
          name: 'string',
        },
      },
    ],
  },
};

export const BasicForm = () => {
  const { form, values } = useFormContext();
  const { setTemplate } = useTemplateContext();

  const setFileTemplate = useCallback(() => {
    setTemplate((oldTemplate) => {
      const newTemplate = oldTemplate.template.filter(
        (item) => item.name !== 'url',
      );
      if (!oldTemplate.template.find((item) => item.name === 'file')) {
        newTemplate.push(fileTemplate);
      }
      return {
        ...oldTemplate,
        template: newTemplate,
      };
    });
    form.change('url', undefined);
  }, [form, setTemplate]);

  const setFileUrl = useCallback(() => {
    setTemplate((oldTemplate) => {
      const newTemplate = oldTemplate.template.filter(
        (item) => item.name !== 'file',
      );
      if (!oldTemplate.template.find((item) => item.name === 'url')) {
        newTemplate.push({
          name: 'url',
          type: {
            name: 'string',
          },
        });
      }
      return {
        ...oldTemplate,
        template: newTemplate,
      };
    });
    form.change('file', undefined);
  }, [form, setTemplate]);

  useEffect(() => {
    if (
      values.url ||
      values.idRepositorioTipoItem === REPOSITORIO_TIPO_ITEM.ENLACE
    ) {
      setFileUrl();
    } else {
      setFileTemplate();
    }
  }, [setFileTemplate, setFileUrl, values.idRepositorioTipoItem, values.url]);

  const onDeleteRecurso = useCallback(() => {
    setFileTemplate();
  }, [setFileTemplate]);

  return (
    <PanelWithTitle title="Sobre el recurso" icon={<LetterIcon>1</LetterIcon>}>
      <Grid container spacing={SPACING} direction="column">
        <Grid item>
          <FormInput
            label="Nombre"
            name="nombre"
            form={form}
            placeholder="Escribí el nombre"
          />
        </Grid>
        <Grid item>
          <Grid container spacing={SPACING}>
            <Grid item sm={6}>
              <FormInput
                label="Descripción"
                name="descripcion"
                form={form}
                placeholder="Describilo"
              />
            </Grid>
            <Grid item sm={6}>
              <Grid container spacing={SPACING}>
                <Grid item sm={6}>
                  <FormInput
                    label="Fuente"
                    name="fuente"
                    form={form}
                    placeholder="Ingresá la fuente"
                  />
                </Grid>
                <Grid item sm={6}>
                  <FormAutocomplete
                    label="Año"
                    name="fuente_anio"
                    form={form}
                    options={fuenteAnios}
                    optionLabelKey="anio"
                    getOptionSelected={(option: AnyObject, value: any) =>
                      option.anio === value.anio
                    }
                    optionToValue={(option: AnyObject) => option.anio}
                    valueToOption={(value: string, options: AnyObject[]) =>
                      options.find((option) => option.anio === value)
                    }
                  />
                </Grid>
                <Grid item sm={6}>
                  <FormGenericControl
                    name="idRepositorioTipoItem"
                    form={form}
                    component={RepositorioTipoItemSelect}
                    componentProps={{
                      title: 'Tipo',
                      placeholder: 'Seleccioná tipo de recurso',
                    }}
                  />
                </Grid>
                {!!values.idRepositorioTipoItem && (
                  <Grid item sm={6}>
                    {values.idRepositorioTipoItem ===
                      REPOSITORIO_TIPO_ITEM.ENLACE && (
                      <FormInput
                        label="Url"
                        name="url"
                        form={form}
                        placeholder="Escribí la url"
                      />
                    )}
                    {uploadTypes.includes(values.idRepositorioTipoItem) && (
                      <>
                        {values.url ? (
                          <div>
                            <Typography>Recurso</Typography>
                            <Chip
                              label="Archivo"
                              onClick={() => {
                                window.open(values.url, '_blank');
                              }}
                              onDelete={onDeleteRecurso}
                            />
                          </div>
                        ) : (
                          <FormFileInput
                            label="Recurso"
                            name="file"
                            form={form}
                            placeholder="Subir"
                          />
                        )}
                      </>
                    )}
                  </Grid>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </PanelWithTitle>
  );
};
