import { makeBusinessEntity } from '../../../lib/libby/generator';
import { FechaBloqueada } from '../../models/presentismo_v2';

const DAO_NAME = 'fecha_bloqueada';

const {
  hook: { libbyFetch },
} = makeBusinessEntity<FechaBloqueada>(DAO_NAME, {});

// hooks
export const useFechaBloqueadasLibbyFetch = libbyFetch;
