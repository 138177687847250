import { Template } from 'src/lib/templates';

export const direccionAreaTemplate: Template = {
  name: 'Direccion Area',
  template: [
    {
      name: 'idLocalizacion',
      type: {
        name: 'string',
      },
    },
  ],
};
