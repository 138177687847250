import React from 'react';
import { ProyectoPlan } from 'src/app/models';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { grey } from 'src/theme/colors';

const useStyles = makeStyles(() => ({
  textField: {
    fontSize: 14,
    color: grey.textPrimary,
    fontWeight: 600,
  },
  valueField: {
    fontSize: 16,
    color: grey.textPrimary,
    fontWeight: 400,
    marginTop: 8,
    marginBottom: 20,
  },
}));

export interface ProyectoProps {
  data: ProyectoPlan;
}

export const Proyecto = ({ data }: ProyectoProps) => {
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <Typography className={classes.textField}>Nombre</Typography>
      <Typography className={classes.valueField}>{data?.nombre}</Typography>
      <Typography className={classes.textField}>Descripción</Typography>
      <Typography className={classes.valueField}>
        {data?.descripcion}
      </Typography>
    </Grid>
  );
};
