import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { GruposAulaVirtual } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'grupos_aula_virtual';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idGruposAulaVirtual',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idGruposAulaVirtual',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.GruposAulaVirtual
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.GruposAulaVirtual,
      hoc: { ...defaultOptions.hoc, ...businessOptions.GruposAulaVirtual.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<GruposAulaVirtual>(DAO_NAME, optionsToUse);

// hooks
export const useGruposAulaVirtualFetchById = fetchById;
export const useGruposAulaVirtualLibbyCall = libbyCall;
export const useGruposAulaVirtualDAO = libbyDAO;
export const useGruposAulaVirtualLibbyFetch = libbyFetch;

// components/hoc
export const GruposAulaVirtualAutocomplete = entityAutocomplete;
export const GruposAulaVirtualCall = renderPropCall;
export const GruposAulaVirtualById = renderPropFetchById;
export const GruposAulaVirtualSelect = simpleSelect;
export const GruposAulaVirtualAccordionSelector = accordionSelector;
export const GruposAulaVirtualSelectedChips = selectedChips;

// context
// ByIdContext
export const useGruposAulaVirtualByIdContext = useByIdContext;
export const GruposAulaVirtualByIdProvider = ByIdProvider;
export const GruposAulaVirtualByIdContext = ByIdContext;
// GeneralContext
export const useGruposAulaVirtualContext = useGeneralContext;
export const GruposAulaVirtualProvider = GeneralProvider;
export const GruposAulaVirtualContext = GeneralContext;
