import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Provincia } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'provincia';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idProvincia',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idProvincia',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Provincia
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Provincia,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Provincia.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Provincia>(DAO_NAME, optionsToUse);

// hooks
export const useProvinciaFetchById = fetchById;
export const useProvinciaLibbyCall = libbyCall;
export const useProvinciaDAO = libbyDAO;
export const useProvinciaLibbyFetch = libbyFetch;

// components/hoc
export const ProvinciaAutocomplete = entityAutocomplete;
export const ProvinciaCall = renderPropCall;
export const ProvinciaById = renderPropFetchById;
export const ProvinciaSelect = simpleSelect;
export const ProvinciaAccordionSelector = accordionSelector;
export const ProvinciaSelectedChips = selectedChips;

// context
// ByIdContext
export const useProvinciaByIdContext = useByIdContext;
export const ProvinciaByIdProvider = ByIdProvider;
export const ProvinciaByIdContext = ByIdContext;
// GeneralContext
export const useProvinciaContext = useGeneralContext;
export const ProvinciaProvider = GeneralProvider;
export const ProvinciaContext = GeneralContext;
