import { makeBusinessEntity } from '../../../lib/libby/generator';
import { GruposInscripcionesAlumns } from '../../../screens/Private/AcapEscuela/types';

const DAO_NAME = 'grupoincripcion_autoevaluacion';

const {
  hook: { libbyDAO, libbyFetch, libbyCall },
} = makeBusinessEntity<GruposInscripcionesAlumns>(DAO_NAME, {});

// hooks;
export const useGrupoInscripcionAutoevaluadosDAO = libbyDAO;
export const useGrupoInscripcionAutoevaluadosLibbyFetch = libbyFetch;
export const useGrupoInscripcionAutoevaluadosLibbyCall = libbyCall;
