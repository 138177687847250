import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { EspaciosInterdiscipPlan } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'espacios_interdiscip_plan';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEspaciosInterdiscipPlan',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEspaciosInterdiscipPlan',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EspaciosInterdiscipPlan
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EspaciosInterdiscipPlan,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.EspaciosInterdiscipPlan.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EspaciosInterdiscipPlan>(DAO_NAME, optionsToUse);

// hooks
export const useEspaciosInterdiscipPlanFetchById = fetchById;
export const useEspaciosInterdiscipPlanLibbyCall = libbyCall;
export const useEspaciosInterdiscipPlanDAO = libbyDAO;
export const useEspaciosInterdiscipPlanLibbyFetch = libbyFetch;

// components/hoc
export const EspaciosInterdiscipPlanAutocomplete = entityAutocomplete;
export const EspaciosInterdiscipPlanCall = renderPropCall;
export const EspaciosInterdiscipPlanById = renderPropFetchById;
export const EspaciosInterdiscipPlanSelect = simpleSelect;
export const EspaciosInterdiscipPlanAccordionSelector = accordionSelector;
export const EspaciosInterdiscipPlanSelectedChips = selectedChips;

// context
// ByIdContext
export const useEspaciosInterdiscipPlanByIdContext = useByIdContext;
export const EspaciosInterdiscipPlanByIdProvider = ByIdProvider;
export const EspaciosInterdiscipPlanByIdContext = ByIdContext;
// GeneralContext
export const useEspaciosInterdiscipPlanContext = useGeneralContext;
export const EspaciosInterdiscipPlanProvider = GeneralProvider;
export const EspaciosInterdiscipPlanContext = GeneralContext;
