import { useMemo } from 'react';
import { useTurnoLibbyFetch } from '../../../../../app/business';
import { ContraturnoDia, Presente_v3 } from '../../../../../app/models';
import { TURNOS } from '../../../../../commons';
import { TurnoOptions } from '../types';

interface GetTurnoProps {
  isJornadaExtendida: boolean;
  seccionTurno: number;
  presentismos: Presente_v3[];
  selectedDay: moment.Moment;
  contraturnosDia: ContraturnoDia[];
}

interface ReturnGetTurno {
  turnos: TurnoOptions[];
  contraturnosOptions: TurnoOptions[];
  working: boolean;
}

export const useGetTurnos = ({
  isJornadaExtendida,
  seccionTurno,
  presentismos,
  selectedDay,
  contraturnosDia,
}: GetTurnoProps): ReturnGetTurno => {
  const { data: turnos = [], working } = useTurnoLibbyFetch();

  const contraturnos = useMemo(() => {
    const contraturnosFiltered = contraturnosDia.filter(
      (dia) => dia.active && dia.contraturno.active,
    );
    const contraturnosMapped = contraturnosFiltered.reduce<{
      [k: string]: ContraturnoDia[];
    }>((acum, contraturno) => {
      const exist = acum[contraturno.dia.nombre];
      if (!exist) {
        acum = { ...acum, [contraturno.dia.nombre]: [contraturno] };
      } else {
        acum[contraturno.dia.nombre].push(contraturno);
      }
      return acum;
    }, {});
    const contraturnosOrdered = Object.values(contraturnosMapped).sort(
      (a, b) => b.length - a.length,
    );

    const agroupedContraturnos = contraturnosOrdered.reduce<{
      [k: string]: number[];
    }>((acum, contra) => {
      const _options = contra.map((c, i) => ({
        label: `CT${i + 1}`,
        values: c.idContraturnoDia,
      }));
      for (const _o of _options) {
        const exist = acum[_o.label];
        if (exist) {
          acum[_o.label].push(_o.values);
        } else {
          acum = { ...acum, [_o.label]: [_o.values] };
        }
      }
      return acum;
    }, {});

    const _options = Object.entries(agroupedContraturnos).map<TurnoOptions>(
      ([key, value]) => ({
        label: key,
        value: value.join(','),
      }),
    );

    return _options;
  }, [contraturnosDia]);

  const turnosOptions = useMemo<TurnoOptions[]>(
    () =>
      turnos
        .filter((turno) => {
          if (isJornadaExtendida) {
            return isJornadaExtendida && turno.idTurno === seccionTurno;
          } else {
            if (seccionTurno === TURNOS.DOBLE) {
              return (
                turno.descripcionTurno === 'Mañana' ||
                turno.descripcionTurno === 'Tarde' ||
                turno.descripcionTurno === 'Doble'
              );
            } else {
              return turno.idTurno === seccionTurno;
            }
          }
        })
        .map((turno) => ({
          label:
            turno.descripcionTurno === 'Doble'
              ? 'Ambos'
              : turno.descripcionTurno,
          value: turno.idTurno.toString(),
        })),
    [turnos, isJornadaExtendida, seccionTurno],
  );

  return {
    turnos: turnosOptions,
    contraturnosOptions: contraturnos,
    working,
  };
};
