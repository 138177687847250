import { orderBy } from 'lodash';
import { useMemo } from 'react';
import { useSeccionLibbyFetch } from 'src/app/business';
import { Anio, Turno } from 'src/app/models';
import { useRolesContext } from 'src/context/RolesContext';

export const useGetValidYears = () => {
  const {
    selectedRole: { localizacionId },
  } = useRolesContext();
  const localizacionFilter = useMemo(
    () => ({
      0: [{ path: 'localizacion.idLocalizacion', value: localizacionId }],
    }),
    [localizacionId],
  );
  const { data: sectionData = [], working } = useSeccionLibbyFetch({
    limit: 300,
    aspect: 'front-light',
    filter: localizacionFilter,
  });

  const fullAnios: Anio[] = [];
  const fullTurnos: Turno[] = [];

  sectionData.forEach((s) => {
    const idAnio = s.anio.idAnio;
    const idTurno = s.turno.idTurno;
    if (!fullAnios.find((fa) => fa.idAnio === idAnio)) {
      fullAnios.push(s.anio);
    }
    if (!fullTurnos.find((ft) => ft.idTurno === idTurno)) {
      fullTurnos.push(s.turno);
    }
  });

  return {
    working,
    anio: [...new Set(sectionData?.map(({ anio: { idAnio } }) => idAnio))].map(
      (year) => {
        return { path: 'idAnio', value: year };
      },
    ),
    anios: orderBy(fullAnios, 'numeroAnio', 'asc'),
    turnos: orderBy(fullTurnos, 'descripcionTurno', 'asc'),
    anioJe: [
      ...new Set(
        sectionData
          .filter(({ jornada }) => jornada === 'Extendida')
          .map(({ anio: { idAnio } }) => idAnio),
      ),
    ].map((year) => {
      return { path: 'idAnio', value: year };
    }),
  };
};
