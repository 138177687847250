import moment from 'moment';

export const getFechasByDays = (
  startDate: string,
  endDate: string,
  days: number[] = [],
) => {
  const startMoment = moment.utc(startDate);
  const endMoment = moment.utc(endDate);
  if (!startMoment.isValid || !endMoment.isValid)
    throw new Error('Las fechas debe ser una fecha valida');

  if (days.length === 0) return [];

  const dates = [];

  const iterationDays = endMoment.diff(startMoment, 'days') + 1;
  const dayOfWeek = startMoment.weekday();

  if (days.includes(dayOfWeek)) dates.push(startMoment.format('YYYY-MM-DD'));
  for (let i = 0; i < iterationDays; i++) {
    startMoment.add(1, 'days');
    const dayOfWeek = startMoment.weekday();
    if (days.includes(dayOfWeek)) dates.push(startMoment.format('YYYY-MM-DD'));
  }

  return dates;
};

export const getHorasByFechas = (
  days: string[],
  jornadas: {
    day: number;
    startTime: string;
    endTime: string;
  }[],
) => {
  if (days.length === 0 || jornadas.length === 0) return 0;
  return days.reduce((prev, current) => {
    const momentDay = moment(current).weekday();
    const jornada = jornadas.find((jor) => jor.day === momentDay);
    return (
      moment(jornada?.endTime, 'HH-mm').diff(
        moment(jornada?.startTime, 'HH-mm'),
        'hours',
      ) + prev
    );
  }, 0);
};
