import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class MetadataDAO extends LibbyFetchDAO {
  constructor() {
    super('metadata', 'idMetadata');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
