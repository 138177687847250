import React, { useMemo } from 'react';
import { useCriteriosEvaluacionLibbyFetch } from 'src/app/business/acap/CriterioEvaluacion';
import { AutoevaluacionSlider } from '../../AcapEstudiante/components/autoevaluacionComponets/AutoevaluacionSlider';
import { useDevolucionOrganizacionFormContext } from '../hooks/useDevolucionOrgFormContext';
import { Loading } from 'src/commons';

export const SliderEvaluaciones = () => {
  const criteriosFilter = useMemo(
    () => ({
      org: [
        {
          path: 'forOrganizacion',
          value: true,
        },
      ],
      activos: [
        {
          path: 'activo',
          value: true,
        },
      ],
    }),
    [],
  );
  const { data: criterios = [], working: loadingCriterios } =
    useCriteriosEvaluacionLibbyFetch({
      limit: 4,
      filter: criteriosFilter,
    });

  const { values, form } = useDevolucionOrganizacionFormContext();

  return (
    <>
      {loadingCriterios ? (
        <Loading />
      ) : (
        criterios.map((criterio, i) => (
          <div
            key={criterio.nombre}
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              fontFamily: 'Open Sans',
              alignItems: 'center',
              width: '50%',
              marginBottom: '16px',
            }}
          >
            <span style={{ marginRight: '16px', width: '35%' }}>
              {criterio.nombre}:
            </span>
            <div style={{ width: '100%', height: '50px' }}>
              <AutoevaluacionSlider
                showTitles={true}
                value={
                  values.evaluaciones.find(
                    (evaluacion) =>
                      Number(evaluacion.criterio.idCriterioEvaluacion) ===
                      Number(criterio.idCriterioEvaluacion),
                  )?.valor ?? 0
                }
                onChange={(_, newValue: number) => {
                  const newEvaluaciones = [...values.evaluaciones];
                  const idx = newEvaluaciones.findIndex(
                    (eva) =>
                      Number(eva.criterio.idCriterioEvaluacion) ===
                      Number(criterio.idCriterioEvaluacion),
                  );
                  if (Boolean(values.idDevolucionOrganizacion)) {
                    newEvaluaciones[idx].valor = newValue;
                  } else {
                    if (idx === -1) {
                      newEvaluaciones.push({
                        valor: newValue,
                        criterio,
                      });
                    } else {
                      newEvaluaciones[idx].valor = newValue;
                    }
                  }

                  form.change(`evaluaciones`, newEvaluaciones);
                }}
              />
            </div>
          </div>
        ))
      )}
    </>
  );
};
