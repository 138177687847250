import React, {
  useState,
  useCallback,
  useRef,
  useMemo,
  useEffect,
} from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import SaveAltIcon from '@material-ui/icons/SaveAlt';
import customFormDialog from 'src/commons/services/customFormDialog';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import {
  IconTables,
  IconTablesContentTypes,
  InfoTable,
  NIVEL,
} from 'src/commons';
import { useFormContext } from 'src/lib/templates';
import { useSnackbar } from 'notistack';
import { DocumentCard } from './components/DocumentCard';
import { useLegajoData } from '../../context';
import { HeaderDisciplina } from './components/HeaderDisciplina';
import { ModalHistorialAcademico } from './components/ModalHistorialAcademico';
import { CertificadoSeptimo } from 'src/app/models';
import {
  useInformesHistoryLibbyFetch,
  useDeudaAcademicaDAO,
  useCertificadosLibbyCall,
  useAlumnoMovimientoCustomLibbyFetch,
  useAlumnoMovimientoLibbyFetch,
} from 'src/app/business';
import { grey, primary } from 'src/theme/colors';
import { FilterTablesInformes } from './components/FilterInformes';
import { useRolesContext } from 'src/context/RolesContext';
import { useGeneralContext } from 'src/context/GeneralContext';
import { Epa, showEpa, EpaLabelsProvider } from './components/EPA';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
import { ANIO_PRIMARIA } from 'src/commons/const/anios_primaria';

const useStyles = makeStyles({
  title: {
    color: grey.textPrimary,
    fontSize: '20px',
    margin: '15px',
  },
  text: {
    borderRadius: 4,
    backgroundColor: primary.warningLight,
    color: grey.dark,
    padding: '10px 20px',
    fontSize: '15px',
    margin: '20px',
    maxWidth: '300px',
  },
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
});

export const HistorialAcademico = () => {
  const classes = useStyles();
  const {
    dispatch,
    generalState: {
      legajo: {
        historialAcademico: { isUpdated },
      },
    },
  } = useGeneralContext();

  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [_, setPlanDeEstudio] = useState<any>();
  const { form, values } = useFormContext();
  const { alumnoMovimiento, working, isHistoric } = useLegajoData();
  const roleInfo = useRolesContext();
  const { nivel: levelId } = roleInfo?.selectedRole;
  const historialAcademicoList = useMemo(
    () => values?.historialAcademico?.datosPrincipales || [],
    [values],
  );

  const deudaAcademicaDAO = useDeudaAcademicaDAO();
  const [currentFilter, setCurrentFilter] = useState<{
    idCicloLectivo: number | '';
    periodo: number | string;
    tipoInforme: number | '';
  }>({
    idCicloLectivo: '',
    periodo: '',
    tipoInforme: '',
  });

  const selectPlanDeEstudio = useCallback((planDeEstudioId) => {
    setPlanDeEstudio(planDeEstudioId);
  }, []);

  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();

  const { id } = useParams<{ id: string }>();

  const filter = useMemo(
    () => ({
      0: [{ path: 'alumno.idAlumno', value: id }],
      1: [{ path: 'tipoInforme', value: currentFilter.tipoInforme }],
      2: [
        {
          path: 'cicloLectivo',
          value: currentFilter.idCicloLectivo,
        },
      ],
      3: [
        // isNull es utilizado para el periodo anual
        currentFilter.periodo === 'isNull'
          ? { path: 'preview.tipoPeriodo.idTipoPeriodo IS NULL', value: true }
          : {
              path: 'preview.tipoPeriodo.idTipoPeriodo',
              value: currentFilter.periodo,
            },
      ],
      4: [{ path: 'preview.state', value: 'delete', method: 'notEquals' }],
    }),
    [
      currentFilter.idCicloLectivo,
      currentFilter.periodo,
      currentFilter.tipoInforme,
      id,
    ],
  );

  const {
    data: informes,
    working: loading,
    fetchMore,
  } = useInformesHistoryLibbyFetch({
    filter: filter,
    enabled:
      !!currentFilter.idCicloLectivo &&
      !!currentFilter.periodo &&
      !!currentFilter.tipoInforme,
  });

  const arrIcon = useCallback<(url: string) => IconTablesContentTypes>(
    (url: string) => ({
      icon: <SaveAltIcon />,
      handleOnClick: () => window.open(url, '_blank'),
    }),
    [],
  );

  const rows = useMemo(
    () =>
      informes
        ?.map(
          ({
            tipoInforme: { descripcion },
            createdAt,
            informeHistoryId,
            preview,
            localizacion,
            nombre,
          }) => {
            return {
              // TODO: AGREGAR nombre EN REPORTID
              reportId:
                descripcion +
                ' - ' +
                (preview.tipoPeriodo?.descripcionTipoPeriodo ?? 'Anual'),
              reportType: descripcion,
              periodo: preview.tipoPeriodo?.descripcionTipoPeriodo ?? 'Anual',
              location: localizacion.descripcion,
              date: moment(createdAt).format('DD/MM/YYYY'),
              icon: (
                <IconTables
                  content={[
                    arrIcon(
                      preview.urlDrive && preview.template === null
                        ? preview.urlDrive
                        : `/private/preview/${preview?.previewId}?ciclolectivo=${currentFilter.idCicloLectivo}`,
                    ),
                  ]}
                />
              ),
            };
          },
        )
        .map((row) => {
          if (Number(levelId) === NIVEL.INICIAL) {
            return {
              ...row,
              reportId: `Informe ${
                row.periodo === 'Segundo Bimestre' ? 'Cuatrimestral' : 'Final'
              }`,
              reportType: 'Informe',
              periodo: `${
                row.periodo === 'Segundo Bimestre' ? 'Cuatrimestral' : 'Final'
              }`,
            };
          }
          return row;
        }),
    [informes, arrIcon, currentFilter.idCicloLectivo, levelId],
  );
  const fetch = useCallback((filter) => {
    setCurrentFilter(filter);
  }, []);

  const column = [
    {
      id: 'reportId',
      label: 'Informe',
      width: '15%',
    },
    {
      id: 'location',
      label: 'Institución',
      width: '20%',
    },
    {
      id: 'periodo',
      label: 'Período',
      width: '10%',
    },
    {
      id: 'reportType',
      label: 'Tipo de Informe',
      width: '10%',
    },
    {
      id: 'date',
      label: 'Fecha',
      width: '10%',
    },
    {
      id: 'icon',
      label: '',
      width: '10%',
      hideSortIcon: true,
    },
  ];

  const handleRequestSort = useCallback(
    (newOrderBy: string, newDirection: 'asc' | 'desc') => {
      setDirection(newDirection);
    },
    [setDirection],
  );

  const removeItem = useCallback(
    (index: number) => {
      const newList = historialAcademicoList.filter(
        (item: any, ind: number) => {
          return index !== ind;
        },
      );
      const itemToRemove = historialAcademicoList.find(
        (item: any, ind: number) => {
          return index === ind;
        },
      );

      form.change('historialAcademico.datosPrincipales', newList);
      if (itemToRemove.idDeudaAcademica) {
        form.change('historialAcademico.datosToDelete', [
          ...values?.historialAcademico?.datosToDelete,
          itemToRemove,
        ]);
      }
    },
    [form, historialAcademicoList, values.historialAcademico.datosToDelete],
  );

  // TODO use this approach to update the list
  const addItem = useCallback(
    (item: any) => {
      const newHistorialAcademicoItem = {
        alumno: id,
        cicloLectivo: item.cicloLectivo,
        periodo: item.periodo,
        tipoDeudaAcademica: item.motivo,
        planEstudioMateria: item.espacioCurricular,
        anio: item.anio,
        apoyo: item.apoyo,
        fecha: item.fecha,
        valoracion: item.valoracion,
        calificacion: item.calificacion,
        planEstudioMat:
          item?.planEstudio.planEstudioNivel?.planEstudio
            ?.descripcionPlanEstudio,
      };

      form.change('historialAcademico.datosPrincipales', [
        ...historialAcademicoList,
        newHistorialAcademicoItem,
      ]);
    },
    [form, historialAcademicoList, id],
  );

  const fetchDeudaAcademica = useCallback(async () => {
    const result = await deudaAcademicaDAO
      .aspect('limit_deuda')
      .getByAlumno(Number(id));

    const deudaAcademicaFormatted = result?.map((item: any) => {
      return {
        idDeudaAcademica: item.idDeudaAcademica,
        espacioCurricular: item.planEstudioMateria?.materia?.descripcion,
        motivo: item.tipoDeudaAcademica?.descripcion,
        cicloLectivo: item.cicloLectivo?.anio,
        anio: item.anio?.descripcionAnio,
        fecha: item.fecha,
        apoyo: item.apoyo,
        periodo: item?.periodo?.tipoPeriodo?.descripcionTipoPeriodo,
        valoracion: item.valoracion,
        calificacion: item.calificacion,
        planEstudioMat:
          item.planEstudioMateria.planEstudio.descripcionPlanEstudio,
      };
    });

    form.change('historialAcademico.datosPrincipales', deudaAcademicaFormatted);
  }, [deudaAcademicaDAO, form, id]);

  // Se actualiza el formValues con los nuevos ids
  useEffect(() => {
    if (isUpdated) {
      fetchDeudaAcademica();
      dispatch.deudasAcademicasChanged(false);
    }
  }, [dispatch, fetchDeudaAcademica, isUpdated]);

  useEffect(() => {
    fetchDeudaAcademica();
  }, [fetchDeudaAcademica]);

  const openModal = useCallback(async () => {
    await customFormDialog.show({
      title: 'Agregar Espacio Curricular',
      renderComponent: (
        <ModalHistorialAcademico
          form={form}
          values={values}
          onSubmit={addItem}
          selectPlanDeEstudio={selectPlanDeEstudio}
          establecimientoEfectivizado={establecimientoEfectivizado}
        />
      ),
      sizeWidth: 'md',
    });
  }, [form, values, addItem, selectPlanDeEstudio, establecimientoEfectivizado]);

  const {
    data: certificados = [],
    working: workingCerts,
    recall: recallCerts,
  } = useCertificadosLibbyCall({
    methodName: 'getById',
    params: [id],
    noAutoCall: true,
  });

  const filterAM = useMemo(
    () => ({
      alumno: [
        {
          path: 'alumno',
          value: id,
        },
      ],
      cicloLectivo: [
        {
          path: 'cicloLectivo',
          value: currentFilter.idCicloLectivo,
        },
      ],
    }),
    [currentFilter.idCicloLectivo, id],
  );

  const { data: [movimiento] = [] } = useAlumnoMovimientoCustomLibbyFetch({
    filter: filterAM,
    // aspect: 'legajo_datosPersonales',
    enabled: !!currentFilter.idCicloLectivo,
  });

  const esInformeSeptimo =
    movimiento?.seccion?.anio === ANIO_PRIMARIA.SEPTIMO &&
    currentFilter.periodo === 4 &&
    currentFilter.tipoInforme === 6;

  const [certificadosState, setCertificadosState] = useState<
    CertificadoSeptimo[]
  >([]);

  const searchForCertificates = useCallback(async () => {
    await recallCerts();
  }, [recallCerts]);

  useEffect(() => {
    if (esInformeSeptimo && certificados?.length === 0 && !workingCerts) {
      searchForCertificates();
    }
  }, [certificados, esInformeSeptimo, searchForCertificates, workingCerts]);

  useEffect(() => {
    if (
      esInformeSeptimo &&
      certificadosState?.length === 0 &&
      certificados?.length > 0
    ) {
      setCertificadosState(certificados);
    }
  }, [certificados, certificadosState, recallCerts, esInformeSeptimo]);

  const mensajeSinInformes = (
    <Grid container alignContent="center" justify="center">
      <Typography align="center" className={classes.text}>
        El alumno no posee informes.
      </Typography>
    </Grid>
  );

  const sinInformes =
    !informes?.length &&
    currentFilter.tipoInforme &&
    !loading &&
    mensajeSinInformes;

  const resultadoInformeSeptimo = certificadosState?.[0]?.enlace ? (
    <Grid container alignContent="center" justify="center">
      <Typography
        align="center"
        style={{ backgroundColor: primary.successLight }}
        className={classes.text}
      >
        <a
          target="_blank"
          rel="noreferrer"
          style={{ textDecoration: 'none' }}
          href={certificadosState?.[0]?.enlace}
        >
          Descargar el certificado 7°
        </a>
      </Typography>
    </Grid>
  ) : (
    mensajeSinInformes
  );

  return (
    <>
      <Grid container spacing={4} direction="column">
        <Grid item>
          <Typography className={classes.title}>Informes</Typography>
          <FilterTablesInformes onFilterChanged={fetch} />
          {esInformeSeptimo ? resultadoInformeSeptimo : sinInformes}
          {!informes?.length && !currentFilter.tipoInforme && (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: '100px' }}
            >
              <Typography
                style={{
                  fontSize: '20px',
                  marginTop: '10px',
                  color: 'gray',
                  fontWeight: 'bold',
                }}
              >
                Seleccione ciclo lectivo, período y tipo de informe para buscar.
              </Typography>
            </Grid>
          )}
          {informes?.length ? (
            <>
              {currentFilter.tipoInforme && (
                <InfoTable
                  rowIdKey="reportId"
                  rows={rows || []}
                  working={loading}
                  columns={column}
                  onBottomScroll={fetchMore}
                  direction={direction}
                  onSortChange={handleRequestSort}
                />
              )}
            </>
          ) : (
            <> </>
          )}
        </Grid>
        {!alumnoMovimiento ? (
          <Typography>El alumno no se encuentra matriculado</Typography>
        ) : (
          <HeaderDisciplina
            openModal={openModal}
            working={working}
            isHistoric={isHistoric}
            alumnoMovimientoNivelId={alumnoMovimiento?.seccion?.nivel?.idNivel}
          >
            {historialAcademicoList?.map((item: any, index: number) => (
              <DocumentCard
                key={`${item}- ${index}`}
                onDelete={() => removeItem(index)}
                disciplinaPendiente={item}
              />
            ))}
          </HeaderDisciplina>
        )}
      </Grid>
      {showEpa(levelId, alumnoMovimiento?.seccion?.anio?.idAnio) && (
        <EpaLabelsProvider>
          <Epa />
        </EpaLabelsProvider>
      )}
    </>
  );
};
