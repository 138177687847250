import { PresentismoDAO } from 'src/platform/libby/dao';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { Presentismo } from '../../models/acap';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'presentismo';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPresentismo',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPresentismo',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Presentismo
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Presentismo,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Presentismo.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Presentismo, PresentismoDAO>(DAO_NAME, optionsToUse);

// hooks
export const usePresentismoFetchById = fetchById;
export const usePresentismoLibbyCall = libbyCall;
export const usePresentismoDAO = libbyDAO;
export const usePresentismoLibbyFetch = libbyFetch;

// components/hoc
export const PresentismoAutocomplete = entityAutocomplete;
export const PresentismoCall = renderPropCall;
export const PresentismoById = renderPropFetchById;
export const PresentismoSelect = simpleSelect;
export const PresentismoAccordionSelector = accordionSelector;
export const PresentismoSelectedChips = selectedChips;

// context
// ByIdContext
export const usePresentismoByIdContext = useByIdContext;
export const PresentismoByIdProvider = ByIdProvider;
export const PresentismoByIdContext = ByIdContext;
// GeneralContext
export const usePresentismoContext = useGeneralContext;
export const PresentismoProvider = GeneralProvider;
export const PresentismoContext = GeneralContext;
