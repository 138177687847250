import React, { useEffect, useMemo, useState } from 'react';
import customFormDialog from 'src/commons/services/customFormDialog';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { InfoTable } from 'src/commons';
import { useSnackbar } from 'notistack';
import CloseIcon from '@material-ui/icons/Close';
import { usePipDAO } from 'src/app/business';
import { useModalInformesPipPendientesColumns } from '../hooks/useModalInformesPipPendientesColumns';
import { cloneDeep } from 'lodash';
import { AlumnoMovimiento, Pip } from 'src/app/models';
import { quitarAcentos } from 'src/utils/types/quitarAcentos';

const useStyles = makeStyles((theme) => ({
  tableCustomStyle: {
    maxWidth: '95%',
    margin: 'auto',
  },
  closeButton: {
    marginTop: theme.spacing(-8),
    marginRight: theme.spacing(4),
    position: 'relative',
  },
}));

interface Modal {
  pipData: Pip[];
  alumnos: (AlumnoMovimiento & { pip?: Pip })[];
  rol: any;
  reFetch: any;
  idSeccion: string;
  working: boolean;
}

const AUTHORIZED_TO_OPEN = {
  '1': 'director',
};

export const ModalInformesPipPendientes = ({
  rol,
  pipData,
  alumnos,
  reFetch,
  idSeccion,
  working,
}: Modal) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState({ idAlumno: '', loading: false });
  const [rows, setRows] = useState<(AlumnoMovimiento & { pip?: Pip })[]>([]);
  const pipDao = usePipDAO();

  useEffect(() => {
    const alumnosPipData = alumnos
      .filter((row) => row?.seccion.idSeccion === idSeccion)
      .map((alumno) => {
        const pipInfo = pipData.find(
          (pipInfo) => pipInfo?.alumno?.idAlumno === alumno?.alumno?.idAlumno,
        );
        alumno['pip'] = pipInfo;
        return alumno;
      });

    const orderedAlumnos = alumnosPipData.sort((a, b) => {
      const _nombreA =
        `${a.alumno.persona.apellido}, ${a.alumno.persona.nombre}`.trim();
      const _nombreB =
        `${b.alumno.persona.apellido}, ${b.alumno.persona.nombre}`.trim();
      return quitarAcentos(_nombreA).localeCompare(quitarAcentos(_nombreB));
    });

    setRows(orderedAlumnos);
  }, [alumnos, idSeccion, pipData]);

  const isAuthorizedToOpen = rol in AUTHORIZED_TO_OPEN;

  const handleCloseModal = () => {
    reFetch();
    customFormDialog.handleBack();
  };

  const handleActionOverPip = useMemo(
    () =>
      async (
        idPIP: string | undefined,
        idAlumno: string,
        action: 'close' | 'open',
      ) => {
        setLoading({ idAlumno: idAlumno, loading: true });
        if (idPIP !== undefined) {
          const modifiedRows = cloneDeep(rows);
          const alumnoIndex = modifiedRows.findIndex(
            (row) => row.alumno.idAlumno === idAlumno,
          );
          if (action === 'open') {
            try {
              await pipDao.save({ idPIP, abierto: true });
              enqueueSnackbar('Se ha abierto el PIP del alumno', {
                variant: 'success',
              });

              modifiedRows[alumnoIndex].pip!.abierto = true;
            } catch (error) {
              enqueueSnackbar('No se ha podido abrir el PIP del alumno', {
                variant: 'error',
              });
            }
          } else if (action === 'close') {
            try {
              await pipDao.save({
                idPIP,
                abierto: false,
              });
              enqueueSnackbar('Se ha cerrado el PIP del alumno', {
                variant: 'success',
              });
              modifiedRows[alumnoIndex].pip!.abierto = false;
            } catch (error) {
              enqueueSnackbar('No se ha podido cerrar el PIP del alumno', {
                variant: 'error',
              });
            }
          }
          reFetch();
          setRows(modifiedRows);
          setLoading({ idAlumno: '', loading: false });
        }
      },
    [enqueueSnackbar, pipDao, reFetch, rows],
  );

  const columns = useModalInformesPipPendientesColumns(
    loading,
    isAuthorizedToOpen,
    handleActionOverPip,
  );
  return (
    <>
      <Grid item container justifyContent="flex-end">
        <Button className={classes.closeButton} onClick={handleCloseModal}>
          <CloseIcon />
        </Button>
      </Grid>
      <InfoTable
        rows={rows}
        columns={columns}
        working={working}
        rowIdKey="nombreSeccion"
        customStyle={classes.tableCustomStyle}
      />
    </>
  );
};
