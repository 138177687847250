import * as React from 'react';

export const Pps = () => (
  <svg
    version="1.2"
    baseProfile="tiny-ps"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 250 250"
    width="25"
    height="25"
  >
    <title>pps</title>
    <path
      id="PPS"
      fill="#737373"
      aria-label="PPS"
      d="M43.57 70.01L26.72 70.01L26.72 127.08L43.57 127.08Q52.92 127.08 58.02 119.65Q63.13 112.23 63.13 98.49Q63.13 84.86 58.02 77.44Q52.92 70.01 43.57 70.01ZM13.33 205L13.33 53.12L43.57 53.12Q60.21 53.12 68.7 64.72Q77.25 76.22 77.25 98.49Q77.25 120.97 68.7 132.47Q60.21 143.96 43.57 143.96L26.72 143.96L26.72 205L13.33 205ZM125.46 70.01L108.62 70.01L108.62 127.08L125.46 127.08Q134.81 127.08 139.92 119.65Q145.03 112.23 145.03 98.49Q145.03 84.86 139.92 77.44Q134.81 70.01 125.46 70.01ZM95.23 205L95.23 53.12L125.46 53.12Q142.11 53.12 150.6 64.72Q159.15 76.22 159.15 98.49Q159.15 120.97 150.6 132.47Q142.11 143.96 125.46 143.96L108.62 143.96L108.62 205L95.23 205ZM236.47 58.11L236.47 78.15Q228.85 72.55 222.08 69.81Q215.32 67.06 209.02 67.06Q198.08 67.06 192.11 73.57Q186.21 80.08 186.21 92.08Q186.21 102.16 190.12 107.34Q194.1 112.43 205.11 115.58L213.2 118.13Q228.18 122.5 235.28 133.59Q242.44 144.58 242.44 163.09Q242.44 185.16 232.76 196.56Q223.14 207.95 204.51 207.95Q197.48 207.95 189.52 205.51Q181.63 203.07 173.14 198.29L173.14 177.13Q181.3 184.15 189.12 187.71Q196.95 191.27 204.51 191.27Q215.98 191.27 222.21 184.35Q228.45 177.43 228.45 164.62Q228.45 153.43 223.94 147.12Q219.5 140.81 209.28 137.66L201.13 135.22Q186.14 130.64 179.44 120.87Q172.75 111.11 172.75 93.71Q172.75 73.57 181.96 61.97Q191.25 50.38 207.49 50.38Q214.46 50.38 221.68 52.31Q228.91 54.24 236.47 58.11Z"
    />
  </svg>
);
