import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { ROL, SimpleSelect } from 'src/commons';

import { useGetRows } from '../../../../hooks';
import { usePrimarioCustomLibbyFetch } from 'src/app/business/Calificaciones/PrimarioCustom';
import { isEqual } from 'lodash';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
import { useRolesContext } from 'src/context/RolesContext';

export type objTable = {
  id: string;
  nombre: string;
  tipo: string;
  seccion: string;
  bimestre: string;
};

const booleanMock = [
  { descripcion: 'No', id: 0 },
  { descripcion: 'Sí', id: 1 },
];

const opcionesMock = [
  { descripcion: '-' },
  { descripcion: 'Insuficiente (I)' },
  { descripcion: 'Regular (R)' },
  { descripcion: 'Bueno (B)' },
  { descripcion: 'Muy Bueno (MB)' },
  { descripcion: 'Sobresaliente (S)' },
];

let calificacionGeneralStringMock = [
  { descripcion: '-' },
  { descripcion: 'Insuficiente (I)' },
  { descripcion: 'Regular (R)' },
  { descripcion: 'Bueno (B)' },
  { descripcion: 'Muy Bueno (MB)' },
  { descripcion: 'Sobresaliente (S)' },
];

const calificacionGeneralNumericMock = [
  { descripcion: '-' },
  { descripcion: '1' },
  { descripcion: '2' },
  { descripcion: '3' },
  { descripcion: '4' },
  { descripcion: '5' },
  { descripcion: '6' },
  { descripcion: '7' },
  { descripcion: '8' },
  { descripcion: '9' },
  { descripcion: '10' },
];

const areaDeConocimientoStyle = makeStyles(() => ({
  selectSize: {
    minWidth: 150,
  },
}));

export const useAreaDeConocimientoRows = (filter: any, columns: any[]) => {
  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();
  const [readyToClose, setReadyToClose] = useState(false);
  const classes = areaDeConocimientoStyle();

  useEffect(() => {
    if (establecimientoEfectivizado) {
      calificacionGeneralStringMock = calificacionGeneralStringMock.filter(
        (item) => item.descripcion !== 'Promoción acompañada',
      );
    } else {
      calificacionGeneralStringMock = calificacionGeneralStringMock.filter(
        (item) => item.descripcion !== '-',
      );
    }
  }, [establecimientoEfectivizado]);

  const filterParams = useMemo(
    () => ({
      0: [
        {
          path: 'espacioCurricularSeccion',
          value: filter.espacioCurricularSeccion,
        },
      ],
      1: [{ path: 'calificacion.periodo', value: filter.periodo }],
    }),
    [filter.espacioCurricularSeccion, filter.periodo],
  );
  const fetchResponse = usePrimarioCustomLibbyFetch({
    aspect: 'materia-response',
    filter: filterParams,
    enabled: !!filter.espacioCurricularSeccion,
    limit: 100,
  });

  const {
    selectedRole: { rol },
  } = useRolesContext();

  const { data = [] } = fetchResponse;

  const {
    rows: _rows,
    changeRow,
    working,
    reFetch,
    hasCalifications,
  } = useGetRows(fetchResponse, filter.search);

  const isClosed = useMemo(
    () => _rows.some((row: any) => row.nota.abierto === false),
    [_rows],
  );

  useEffect(() => {
    const submitRows = _rows.filter((row) => {
      let isUpdated = false;
      const currentCalification = data?.find(
        (d) => d.idCalificacion === row.idCalificacion,
      );
      if (currentCalification?.nota) {
        isUpdated = !isEqual(currentCalification?.nota?.data, row.nota.data);
      } else if (Object.keys(row.nota.data).length) {
        isUpdated = true;
      }
      return isUpdated;
    });
    setReadyToClose(!submitRows.length);
  }, [_rows, data]);

  const makeRows = useCallback(
    (row: any, index: number, isDisabled: boolean) => {
      let rows: any = {};
      columns.forEach(({ id, label }) => {
        if (id === 'nombre' || id === 'ppi' || id === 'calificacionGeneral')
          return;
        rows[id] = (
          <Grid className={classes.selectSize}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={opcionesMock}
              value={row.nota.data[id]}
              name={id}
              key={`${id}-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          </Grid>
        );
      });

      return rows;
    },
    [changeRow, classes.selectSize, columns],
  );

  const rows = useMemo(() => {
    const isDisabled =
      _rows.some(
        (c) => c.nota?.abierto !== undefined && c.nota?.abierto === false,
      ) || Number(rol) === ROL.COORDINADOR_JE;

    return _rows.map((row, index: number) => {
      return {
        nombre: `${row?.apellido}, ${row?.nombre}`,
        ppi: (
          <Grid className={classes.selectSize}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={booleanMock}
              value={row.nota?.data?.ppi}
              name="ppi"
              key={`ppi-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          </Grid>
        ),
        ...makeRows(row, index, isDisabled),
        calificacionGeneral: (
          <Grid className={classes.selectSize}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={
                filter.anio !== 7
                  ? filter.anio === 1
                    ? // Para primer año no debe incluir Promoción acompañada
                      calificacionGeneralStringMock.filter(
                        ({ descripcion }) =>
                          descripcion !== 'Promoción acompañada',
                      )
                    : calificacionGeneralStringMock
                  : calificacionGeneralNumericMock
              }
              value={row.nota?.data?.calificacionGeneral}
              name="calificacionGeneral"
              key={`calificacionGeneral-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          </Grid>
        ),
      };
    });
  }, [rol, _rows, classes.selectSize, makeRows, filter.anio, changeRow]);

  return {
    rows,
    working,
    _rows,
    data,
    reFetch,
    isClosed,
    readyToClose,
    hasCalifications,
  };
};
