import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { ContenidoBloque } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'contenido_bloque';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idContenidoBloque',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idContenidoBloque',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.ContenidoBloque
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.ContenidoBloque,
      hoc: { ...defaultOptions.hoc, ...businessOptions.ContenidoBloque.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<ContenidoBloque>(DAO_NAME, optionsToUse);

// hooks
export const useContenidoBloqueFetchById = fetchById;
export const useContenidoBloqueLibbyCall = libbyCall;
export const useContenidoBloqueDAO = libbyDAO;
export const useContenidoBloqueLibbyFetch = libbyFetch;

// components/hoc
export const ContenidoBloqueAutocomplete = entityAutocomplete;
export const ContenidoBloqueCall = renderPropCall;
export const ContenidoBloqueById = renderPropFetchById;
export const ContenidoBloqueSelect = simpleSelect;
export const ContenidoBloqueAccordionSelector = accordionSelector;
export const ContenidoBloqueSelectedChips = selectedChips;

// context
// ByIdContext
export const useContenidoBloqueByIdContext = useByIdContext;
export const ContenidoBloqueByIdProvider = ByIdProvider;
export const ContenidoBloqueByIdContext = ByIdContext;
// GeneralContext
export const useContenidoBloqueContext = useGeneralContext;
export const ContenidoBloqueProvider = GeneralProvider;
export const ContenidoBloqueContext = GeneralContext;
