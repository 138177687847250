import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { isEqual } from 'lodash';
import { Grid, Button } from '@material-ui/core';
import { AnyObject, MainInput, SimpleSelect, useDebounce } from 'src/commons';
import {
  PeriodoSelect,
  SeccionSelect,
  AnioSelect,
  EspacioCurricularSeccionSelect,
  useLocalizacionDAO,
} from 'src/app/business';
import useGenerarExcel from 'src/commons/hooks/useGenerarExcel';
import { validateLeave } from '../../hooks';
import { useRolesContext } from 'src/context/RolesContext';
import { NIVEL, ROL } from 'src/commons/const';
import { useParams } from 'react-router';
import { useEspaciocurricularseccioncustomGetRequest } from 'src/app/business/businessCustomEndpoints/espaciocurricularseccioncustom';
import { useJeMateriasDAO } from 'src/app/business/JeMaterias';
import { useGeneralContext } from 'src/context/GeneralContext';
import {
  EfectivizacionContext,
  EfectivizacionProvider,
  useColegioEfectivizadoContext,
} from '../../context/EfectivizacionProvider';
import {
  EspacioCurricularSeccion,
  JeMateria,
  Localizacion,
} from 'src/app/models';
import { JeMateriasDAO, LocalizacionDAO } from 'src/platform/libby/dao';
import { useMountFetchById } from 'src/commons/hooks/useMountFetchById';
import { useMountFetchRecords } from 'src/commons/hooks/useMountFetchRecords';
import moment from 'moment';
import { contextType } from 'react-copy-to-clipboard';

const customStyles = {
  searchGrid: { marginTop: 10 },
  descargar: { marginBottom: '4px' },
  containerStyles: { marginTop: 23 },
};

export const CalificacionesFiltersHeader = ({
  data,
  rows,
  filter,
  excelData,
  reFetch,
  showEspacioCurricular,
  setFilter,
  idAnio,
  handleSeccionesChange,
  handlePeriodosChange,
  incompletedRows,
  readyToClose,
  setEspacioSeccionSelect,
  showView,
  anioCustomFilter = {},
  seccionCustomFilter = {},
  showJeMaterias,
  hideEDIandTutoria = false,
  handleAnioChange,
  handleJornadaExtendidaChange,
  fromEC = false,
  recuperacion,
}: any) => {
  const { cicloLectivo: cicloLectivoContext, establecimientoEfectivizado } =
    useColegioEfectivizadoContext();

  const { dispatch, generalState } = useGeneralContext();
  const generateExcel = useGenerarExcel();
  const {
    selectedRole: { nivel, rol, localizacionId },
  } = useRolesContext();
  const [initialValues, setInitialValues] = useState<any>({
    setted: false,
    values: [],
  });
  const { idEspacioCurricular, periodo } = useParams<{
    idEspacioCurricular?: string;
    periodo: string;
  }>();
  const [search, setSearch] = useState('');
  const searchDebounced = useDebounce(search, 1000);
  const [disablePeriodo, setDisablePeriodo] = useState(false);
  const [isFromEC, setIsFromEC] = useState(false);
  const anioValue = idAnio ?? filter.anio;
  const localizacionDAO = useLocalizacionDAO();
  const [enableRequest, setEnableRequest] = useState(false);
  const [isInvalidToSubmit, setInvalidToSubmit] = useState(false);

  useEffect(() => {
    setInvalidToSubmit(
      !rows?.some((row) => {
        const originalRow = data?.find(
          (item) => item.idCalificacion === row.idCalificacion,
        );
        if (!originalRow) return;
        return Boolean(originalRow.nota)
          ? !isEqual(row.nota?.data, originalRow.nota?.data)
          : Object.keys(row.nota?.data ?? {}).length > 0 &&
              Object.entries(row.nota?.data).every(
                ([_, value]) => Boolean(value) || value === false,
              );
      }),
    );
  }, [rows, data, cicloLectivoContext]);

  useEffect(() => {
    setEnableRequest(showJeMaterias || showEspacioCurricular);
  }, [showJeMaterias, showEspacioCurricular]);

  const { data: dFuncional } = useMountFetchById<Localizacion, LocalizacionDAO>(
    localizacionDAO,
    localizacionId,
    'login',
  );

  useEffect(() => {
    if (fromEC) {
      setIsFromEC(true);
    }
  }, [fromEC]);

  // Se limpia el state para que no influya en ningún otro componente
  useEffect(() => {
    return () => {
      dispatch.valuesChanged(false);
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch.valuesChanged(
      Boolean(!incompletedRows.length && !readyToClose && showView),
    );
  }, [dispatch, incompletedRows, readyToClose, showView]);

  const [seccionFilter, setSeccionFilter] = useState({
    anio: [{ path: 'anio.idAnio', value: anioValue }],
    localizacion: [{ path: 'localizacion', value: localizacionId }],
    cicloLectivo: [
      { path: 'cicloLectivo.anio', value: cicloLectivoContext?.anio },
    ],
    ...seccionCustomFilter,
  });

  useEffect(() => {
    const newFilter = {
      anio: [{ path: 'anio.idAnio', value: anioValue }],
      localizacion: [{ path: 'localizacion', value: localizacionId }],
      cicloLectivo: [
        { path: 'cicloLectivo.anio', value: cicloLectivoContext?.anio },
      ],
    };
    if (Object.keys(seccionCustomFilter).length > 0) {
      const updatedSection = {
        ...newFilter,
        ...seccionCustomFilter,
      };
      setSeccionFilter(updatedSection);
    } else {
      setSeccionFilter(newFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anioValue, localizacionId]);

  const shouldEspacioCurricularWithIdAutoCall: boolean = useMemo(
    (): boolean => localizacionId != null && Boolean(idEspacioCurricular),
    [localizacionId, idEspacioCurricular],
  );

  const shouldEspacioCurricularWithSeccionIdAutoCall: boolean = useMemo(
    (): boolean => Boolean(filter.seccion),
    [filter.seccion],
  );

  const { data: ecs = [] } = useEspaciocurricularseccioncustomGetRequest<
    EspacioCurricularSeccion[]
  >({
    url: `/api/public/custom/espaciocurricularseccioncustom?localizacion=${localizacionId}&espacioCurricular=${idEspacioCurricular}`,
    aspect: 'limit_by_espacio',
    autoCall: shouldEspacioCurricularWithIdAutoCall,
  });

  const { data: eccRequest = [], working: eccWorking } =
    useEspaciocurricularseccioncustomGetRequest<EspacioCurricularSeccion[]>({
      url: `/api/public/custom/espaciocurricularseccioncustom?seccion=${
        filter.seccion ?? ''
      }`,
      aspect: 'limit_by_espacio',
      autoCall: shouldEspacioCurricularWithSeccionIdAutoCall,
    });

  const ecc = useMemo(
    () =>
      eccRequest.filter(
        (ecs) =>
          !(
            ['Espacios de definición institucional', 'Tutoría'].includes(
              ecs.espacioCurricular.descripcion,
            ) && hideEDIandTutoria
          ),
      ),
    [eccRequest, hideEDIandTutoria],
  );

  const jeMateriasDAO = useJeMateriasDAO();
  const { data: dataJeMaterias } = useMountFetchRecords<
    JeMateria,
    JeMateriasDAO
  >(
    jeMateriasDAO,
    'getAll',
    enableRequest,
    setEnableRequest,
    // args
    100,
    'descripcion',
  );

  const roleInfo = useRolesContext();
  const { nivel: levelId } = roleInfo?.selectedRole;

  const eCurricular = [
    'ciencia',
    'lectura, escritura narrativa y oralidad',
    'comunicación y nuevas técnologías',
    'investigación en acción matemática',
  ];
  const eCurriculares = [
    'ciencias',
    'lectura, escritura y oralidad',
    'tics y programación',
  ];

  // Lengua Extranjera sólo debe incluirse en los establecimientos 'Ramón Fernández' y 'Spangenberg'
  const idLocalicacionWithLenguaExtranjera = [20070800, 20058400, 20058401];
  const JeMateriasFiltered = dataJeMaterias?.filter((jeMateria) => {
    const showMaterias =
      !eCurricular.includes(jeMateria?.descripcion?.toLowerCase()) && // <- 2021
      (filter.periodo === 4 || filter.periodo === 13);

    return (
      (jeMateria.idJEMateria === 9
        ? idLocalicacionWithLenguaExtranjera.includes(parseInt(localizacionId))
        : true) &&
      jeMateria?.anios?.some((anio) => anio?.idAnio === anioValue) &&
      (Number(levelId) === NIVEL.PRIMARIO ? showMaterias : true)
    );
  });

  useEffect(() => {
    if (handleJornadaExtendidaChange && JeMateriasFiltered) {
      handleJornadaExtendidaChange(JeMateriasFiltered);
    }
  }, [JeMateriasFiltered, handleJornadaExtendidaChange]);

  const isDocente = useMemo(() => rol === '2', [rol]);

  useEffect(() => {
    if (
      setEspacioSeccionSelect &&
      filter?.espacioCurricularSeccion &&
      !showJeMaterias
    ) {
      const espacioSeccion = ecc.find(
        (p: any) =>
          p.idEspacioCurricularSeccion === filter.espacioCurricularSeccion,
      );
      setEspacioSeccionSelect(espacioSeccion?.espacioCurricular.descripcion);
    }
  }, [filter, ecc, setEspacioSeccionSelect, showJeMaterias]);

  const esTecnicaSecundaria = useMemo(
    () =>
      dFuncional?.[0]?.establecimiento?.dependenciaFuncional
        ?.idDependenciaFuncional === 32 && Number(levelId) === NIVEL.SECUNDARIO,
    [dFuncional, levelId],
  );

  const [anioFilter, setAnioFilter] = useState({
    ...anioCustomFilter,
    nivel: [{ path: 'nivel.idNivel', value: nivel }],
  });

  //Filter que chequea que haya al menos un valor en el excel
  const excelDataChecker = useMemo(
    () =>
      excelData?.body?.data?.filter((data: any) => {
        for (const prop in data) {
          if (prop !== 'Alumno' && data[prop]) return true;
        }
        return false;
      }),
    [excelData?.body?.data],
  );

  //Flag que permite identificar que ya se está mostrando la tabla
  const showTable = useMemo(() => {
    const filters =
      filter?.anio &&
      filter?.seccion &&
      (!recuperacion ? filter?.periodo : true);
    return !showEspacioCurricular
      ? Boolean(filters)
      : Boolean(filters && filter?.espacioCurricularSeccion);
  }, [showEspacioCurricular, filter, recuperacion]);

  useEffect(() => {
    if (
      !esTecnicaSecundaria &&
      !anioFilter.anio &&
      dFuncional?.length > 0 &&
      Object.keys(anioCustomFilter).length < 0
    ) {
      setAnioFilter({
        ...anioFilter,
        anio: [{ path: 'idAnio', value: 26, method: 'notEquals' }],
      });
    }

    //Setea el valor inicial del excel
    if (!initialValues.setted && excelData?.body?.data?.length > 0) {
      setInitialValues({
        setted: true,
        values: excelDataChecker,
      });
    } else if (initialValues.setted && excelData?.body?.data?.length === 0) {
      setInitialValues({
        setted: false,
        values: [],
      });
    }
  }, [
    esTecnicaSecundaria,
    anioFilter,
    dFuncional,
    anioCustomFilter,
    excelData?.body?.data,
    initialValues.setted,
    excelDataChecker,
  ]);

  useEffect(() => {
    if (Object.keys(anioCustomFilter).length > 0) {
      const newFilter = {
        nivel: [{ path: 'nivel.idNivel', value: nivel }],
        ...anioCustomFilter,
      };
      setAnioFilter(newFilter);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const periodoFilter = useMemo(() => {
    return { nivel: [{ path: 'nivel', value: nivel }] };
  }, [nivel]);

  const onFilterChange = useCallback(
    async ({ target }) => {
      const canLeave = await validateLeave(
        incompletedRows,
        readyToClose,
        showView,
      );
      if (canLeave) {
        if (filter[target.name]) {
          if (target.name === 'anio') {
            setFilter({
              ...filter,
              [target.name]: target.value,
              seccion: '',
              espacioCurricularSeccion: '',
              jemateria: null,
            });
          } else if (target.name === 'seccion') {
            setFilter({
              ...filter,
              [target.name]: target.value,
              espacioCurricularSeccion: '',
              jemateria: null,
            });
          } else if (target.name === 'periodo') {
            setFilter({
              ...filter,
              [target.name]: target.value,
              espacioCurricularSeccion: '',
              jemateria: null,
            });
          } else if (target.name === 'espacioCurricularSeccion') {
            setFilter({
              ...filter,
              [target.name]: target.value,
              jemateria: null,
            });
          } else if (target.name === 'jemateria') {
            setFilter({
              ...filter,
              [target.name]: target.value,
            });
          }
        } else if (
          target.value &&
          Number(levelId) === NIVEL.INICIAL &&
          target.value !== 6 &&
          target.value !== 11
        ) {
          setFilter({ ...filter, [target.name]: target.value, periodo: '' });
        } else if (target.value) {
          setFilter({ ...filter, [target.name]: target.value });
        } else {
          setFilter({ ...filter, [target.name]: '' });
        }
      }
    },

    [incompletedRows, readyToClose, showView, filter, levelId, setFilter],
  );

  const onSearchChange = (e: any) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    setFilter({ ...filter, search: searchDebounced });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDebounced, setFilter]);

  const handleDescargar = useCallback(async () => {
    reFetch && reFetch();
    await generateExcel(excelData.body, excelData.name);
  }, [reFetch, excelData, generateExcel]);

  useEffect(() => {
    if (Number(levelId) === NIVEL.INICIAL) {
      setDisablePeriodo(false);
    } else {
      setDisablePeriodo(true);
    }
  }, [levelId, filter.anio]);

  const seccionValidation = idAnio === undefined;
  useEffect(() => {
    if (anioCustomFilter.anio === undefined && fromEC === true) {
      setAnioFilter({
        anio: [{ path: 'idAnio', value: idAnio }],
      });
    }
  }, [anioCustomFilter.anio, fromEC, idAnio]);
  const seccionValidationDocente = !seccionValidation && isDocente;

  return useMemo(
    () => (
      <>
        <Grid
          container
          justify="space-between"
          alignItems="flex-end"
          spacing={3}
          style={{ padding: '0 15px 15px', paddingRight: 15, marginTop: 23 }}
        >
          <Grid
            container
            xs={8}
            justify="flex-start"
            spacing={3}
            alignItems="flex-end"
            style={customStyles.searchGrid}
          >
            <Grid item xs={3}>
              <AnioSelect
                title="Año"
                placeholder="Año"
                name="anio"
                value={filter.anio}
                handleChange={onFilterChange}
                filter={anioFilter}
                onOptionsChange={handleAnioChange}
                orderBy="numeroAnio,descripcionAnio"
                limit={100}
              />
            </Grid>

            <Grid item xs={3}>
              {!!anioFilter &&
              seccionValidation &&
              Boolean(seccionFilter.anio[0].value) ? (
                <SeccionSelect
                  title="Sección"
                  placeholder="Seleccioná sección"
                  name="seccion"
                  handleChange={onFilterChange}
                  value={filter.seccion}
                  labelKey="nombreSeccion"
                  valueKey="idSeccion"
                  filter={seccionFilter}
                  onOptionsChange={handleSeccionesChange}
                  aspect="matriculacion_matriculados_seccionesFilter"
                  orderBy="nombreSeccion"
                  enableFetch={Boolean(cicloLectivoContext?.anio)}
                />
              ) : (!!anioFilter || isFromEC) &&
                seccionValidationDocente &&
                Boolean(seccionFilter.anio[0].value) ? (
                <SimpleSelect
                  title="Sección"
                  placeholder="Seleccioná sección"
                  handleChange={onFilterChange}
                  value={filter.seccion}
                  name="seccion"
                  labelKey="seccion.nombreSeccion"
                  valueKey="seccion.idSeccion"
                  filter={seccionFilter}
                  content={ecs.sort(
                    (
                      { espacioCurricular: { descripcion } },
                      { espacioCurricular: { descripcion: descripcionB } },
                    ) => {
                      return descripcion > descripcionB ? 1 : -1;
                    },
                  )}
                  orderBy="seccion.idSeccion"
                />
              ) : (
                Boolean(idEspacioCurricular) && (
                  <EspacioCurricularSeccionSelect
                    title="Sección"
                    placeholder="Seleccioná Sección"
                    name="seccion"
                    handleChange={onFilterChange}
                    value={filter.seccion || ''}
                    labelKey="seccion.nombreSeccion"
                    valueKey="seccion.idSeccion"
                    filter={seccionFilter}
                    onOptionsChange={handleSeccionesChange}
                    params={idEspacioCurricular}
                    aspect="limit_by_seccion"
                    methodName="getByEspacioCurricularId"
                  />
                )
              )}
            </Grid>
            {filter.periodo !== 0 && filter.seccion && (
              <Grid item xs={3}>
                <PeriodoSelect
                  title="Período"
                  placeholder="Período"
                  labelKey="tipoPeriodo.descripcionTipoPeriodo"
                  valueKey="idPeriodo"
                  handleChange={onFilterChange}
                  value={filter.periodo}
                  disabled={disablePeriodo}
                  filter={periodoFilter}
                  orderBy="idPeriodo"
                  direction="desc"
                  onOptionsChange={handlePeriodosChange}
                  transformOptions={(options: AnyObject[]): AnyObject[] => {
                    if (!options.length) return options;
                    if (Number(levelId) !== NIVEL.INICIAL) return options;
                    const filteredOptions = options.filter(
                      (option) => option.tipoPeriodo.idTipoPeriodo === 2,
                      // || option.tipoPeriodo.idTipoPeriodo === 4,
                    );
                    const editedOptions = filteredOptions
                      .map((option) => {
                        switch (option.tipoPeriodo.idTipoPeriodo) {
                          case 4:
                            option.tipoPeriodo.descripcionTipoPeriodo =
                              'Informe Final';
                            break;
                          case 2:
                            option.tipoPeriodo.descripcionTipoPeriodo =
                              'Informe Cuatrimestral';
                            break;
                          default:
                            break;
                        }
                        return option;
                      })
                      .filter((option) => {
                        // let showOption = false;
                        let showOption = true;
                        if (!establecimientoEfectivizado) {
                          showOption = true;
                        }
                        if (
                          establecimientoEfectivizado &&
                          option.tipoPeriodo.idTipoPeriodo === 2
                        ) {
                          showOption = true;
                        }
                        return showOption;
                      });
                    return editedOptions;
                  }}
                />
              </Grid>
            )}
            {showEspacioCurricular &&
              filter.seccion &&
              (filter.periodo || filter.periodo === 0) && (
                <Grid item xs={3}>
                  <SimpleSelect
                    title={
                      NIVEL.SECUNDARIO === Number(nivel)
                        ? 'Espacio Curricular'
                        : 'Área de conocimiento'
                    }
                    placeholder="Selecciona espacio curricular"
                    name="espacioCurricularSeccion"
                    handleChange={onFilterChange}
                    value={filter.espacioCurricularSeccion}
                    labelKey="espacioCurricular.descripcion"
                    valueKey="idEspacioCurricularSeccion"
                    content={ecc.sort(
                      (
                        { espacioCurricular: { descripcion } },
                        { espacioCurricular: { descripcion: descripcionB } },
                      ) => {
                        return descripcion > descripcionB ? 1 : -1;
                      },
                    )}
                    loading={eccWorking}
                  />
                </Grid>
              )}
            {showJeMaterias && !!filter.seccion && !!filter.periodo && (
              <Grid item xs={3}>
                <SimpleSelect
                  title={
                    NIVEL.SECUNDARIO === Number(nivel) || showJeMaterias
                      ? `Espacio ${
                          NIVEL.SECUNDARIO === Number(nivel) && showJeMaterias
                            ? 'Educativo'
                            : 'Curricular'
                        }`
                      : 'Área de conocimiento'
                  }
                  placeholder={`Selecciona espacio ${
                    NIVEL.SECUNDARIO === Number(nivel) && showJeMaterias
                      ? 'educativo'
                      : 'curricular'
                  }`}
                  name="jemateria"
                  handleChange={onFilterChange}
                  value={filter.jemateria}
                  labelKey="descripcion"
                  labelAlternativeKey="nombre_corto"
                  valueKey="idJEMateria"
                  content={JeMateriasFiltered}
                />
              </Grid>
            )}
          </Grid>

          <Grid
            container
            xs={4}
            justify="flex-end"
            spacing={3}
            alignItems="flex-end"
            style={customStyles.searchGrid}
          >
            <Grid item xs>
              <MainInput
                type="search"
                handleChange={onSearchChange}
                placeholder="Buscar"
                name="search"
                value={search}
                fullWidth
              />
            </Grid>

            <Grid item xs style={customStyles.descargar}>
              <Button
                color="primary"
                variant="contained"
                component="label"
                fullWidth
                onClick={handleDescargar}
                disabled={
                  (showTable
                    ? !(
                        initialValues.setted && initialValues.values.length > 0
                      ) || !excelDataChecker?.length
                    : true) ||
                  !showView ||
                  !isInvalidToSubmit ||
                  (establecimientoEfectivizado &&
                    [2, 3].includes(Number(nivel)) &&
                    ![1, 2, 3, 4, 5, 6, 8, 7, 10, 12, 13, 14].includes(
                      Number(filter.periodo),
                    ))
                }
              >
                Descargar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    ),
    [
      filter.anio,
      filter.seccion,
      filter.periodo,
      filter.espacioCurricularSeccion,
      filter.jemateria,
      onFilterChange,
      anioFilter,
      handleAnioChange,
      seccionValidation,
      seccionFilter,
      handleSeccionesChange,
      cicloLectivoContext?.anio,
      isFromEC,
      seccionValidationDocente,
      ecs,
      idEspacioCurricular,
      disablePeriodo,
      periodoFilter,
      handlePeriodosChange,
      showEspacioCurricular,
      nivel,
      ecc,
      eccWorking,
      showJeMaterias,
      JeMateriasFiltered,
      search,
      handleDescargar,
      showTable,
      initialValues.setted,
      initialValues.values.length,
      excelDataChecker?.length,
      showView,
      isInvalidToSubmit,
      establecimientoEfectivizado,
      levelId,
    ],
  );
};
