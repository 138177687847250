import { makeStyles } from '@material-ui/core';
import { AppTheme } from '../../../../../../../theme';

export const useAsistenciaCellStyles = makeStyles({
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
  disabled: {
    cursor: 'not-allowed',
  },
  border: {
    borderLeft: '1px solid #EBEBEB',
  },
  last: {
    borderLeft: '1px solid #EBEBEB',
    borderRight: '1px solid #EBEBEB',
  },
  firstRow: {
    borderEndStartRadius: 12,
    /*     borderBottom: '1px solid #EBEBEB', */
  },
  middleRow: {
    /*     borderBottom: '1px solid #EBEBEB', */
  },
  lastRow: {
    borderEndEndRadius: 12,
    /*     borderBottom: '1px solid #EBEBEB', */
  },
  cell: {
    cursor: 'pointer',
    height: 15,
    padding: 0,
  },
  closed: {
    backgroundColor: '#BEC0C42E',
    cursor: 'not-allowed',
  },
  current: {
    color: '#3786EE',
  },
  open: {
    backgroundColor: '#BEC0C42E',
  },
  tooltip: {
    padding: 5,
    backgroundColor: '#f5f5f9',
    color: '#38485C',
    border: '1px solid #dadde9',
  },
  focused: {
    outline: '#CCCED1 auto 1px',
  },
});

export const useAvisosCellStyles = makeStyles({
  cell: {
    padding: 0,
    height: 50,
    fontSize: 13,
    width: 60,
  },
  noBorder: {
    borderBottom: 'none',
  },
  icon: {
    width: 22,
    height: 22,
  },
  iconButton: {
    width: 24,
    height: 24,
  },
  tooltip: {
    padding: 5,
    backgroundColor: '#f5f5f9',
    color: '#38485C',
    border: '1px solid #dadde9',
  },
  text: {
    fontWeight: 'bold',
    fontFamily: 'Nunito',
    fontSize: 15,
  },
});

export const useDiasCeelStyles = makeStyles((theme) => ({
  content: {
    fontFamily: 'Nunito',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  middle: {
    borderLeft: '1px solid #EBEBEB',
  },
  last: {
    borderLeft: '1px solid #EBEBEB',
    borderRight: '1px solid #EBEBEB',
  },
  cell: {
    padding: 0,
    height: 23,
    fontSize: 13,
    fontWeight: 600,
  },
  noBorderBotton: { borderBottom: 'none' },
  closed: {
    color: '#38485C',
  },
  current: {
    color: '#3786EE',
  },
  open: {
    color: '#38485C',
    textDecoration: 'underline',
  },
  button: {
    textTransform: 'none',
    fontFamily: 'Nunito',
    fontSize: 12,
    height: 23,
    '&:hover': {
      color: '#3786EE',
    },
  },
}));

export const useJustificationCellStyles = makeStyles({
  cell: {
    padding: 0,
    height: 50,
    fontSize: 13,
  },
  noBorder: {
    borderBottom: 'none',
  },
  icon: {
    width: 22,
    height: 22,
  },
  iconButton: {
    width: 33,
    height: 33,
    backgroundColor: '#F5F9FC',
    color: '#3786EE',
  },
});

export const useStudentReportCellStyles = makeStyles({
  cell: {
    padding: 0,
    height: 50,
    fontSize: 13,
  },
  noBorder: {
    borderBottom: 'none',
  },
  icon: {
    width: 22,
    height: 22,
  },
  iconButton: {
    width: 33,
    height: 33,
    backgroundColor: '#F5F9FC',
    color: '#3786EE',
  },
});

export const useLockCellStyles = makeStyles<AppTheme>({
  content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 40,
  },
  first: {
    borderTopLeftRadius: 12,
    borderLeft: '1px solid #EBEBEB',
    borderTop: '1px solid #EBEBEB',
  },
  middle: {
    borderTop: '1px solid #EBEBEB',
    borderLeft: '1px solid #EBEBEB',
  },
  last: {
    borderTopRightRadius: 12,
    borderTop: '1px solid #EBEBEB',
    borderLeft: '1px solid #EBEBEB',
    borderRight: '1px solid #EBEBEB',
  },
  box: {
    height: 40,
    padding: 0,
  },
  icon: {
    width: 14,
    height: 13,
    padding: 6,
  },
  root: {
    border: '1px solid red',
  },
  borderTop: {
    borderTop: '1px solid #EBEBEB',
  },
  buttonLock: {
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
});

export const useObservacionesCellStyles = makeStyles({
  icon: {
    width: 24,
    height: 24,
  },
  iconButton: {
    width: 33,
    height: 33,
    backgroundColor: '#F5F9FC',
  },
  cell: {
    padding: 0,
    height: 15,
    fontSize: 10,
  },
});

export const useToolTipsDisableStyles = makeStyles({
  root: {
    border: '1px solid black',
    borderRadius: 8,
    padding: 0,
  },
  item: {
    height: 20,
    lineHeight: 1,
  },
  head: {
    height: 30,
    lineHeight: 2,
  },
  icon: {
    width: 5,
    height: 5,
  },
  listIcon: {
    minWidth: 10,
  },
});

export const useTurnoCellStyle = makeStyles<AppTheme>((theme) => ({
  content: {
    fontFamily: 'Nunito',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'inherit',
  },
  cell: {
    height: 15,
    padding: 0,
    margin: 0,
    fontWeight: 600,
    fontSize: 13,
  },
  border: {
    borderLeft: '1px solid #EBEBEB',
  },
  last: {
    borderRight: '1px solid #EBEBEB',
  },
  closed: {
    color: '#38485C',
  },
  current: {
    color: '#3786EE',
  },
  open: {
    color: '#38485C',
  },
}));
