import React from 'react';
import { ModalidadType } from 'src/app/models';
import { useRolesContext } from 'src/context/RolesContext';
import { useSaveTabIndex } from '../hooks/useSaveTabIndex';
import { AdultsPassManagement } from './Adult';
import { ByCpassManagement } from './ByC';
import { InitialPassManagement } from './Initial';

const screenModalidad: any = {
  1: <InitialPassManagement />,
  2: <ByCpassManagement />,
  3: <AdultsPassManagement />,
};

export const PassManagement = () => {
  useSaveTabIndex();
  const {
    selectedRole: { modalidad },
  } = useRolesContext();
  return screenModalidad[modalidad || ModalidadType.General];
};
