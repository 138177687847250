import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { MainButton } from 'src/commons/components';
import { ButtonTypesProps } from 'src/commons/components/Footer';
import {
  FormInput,
  useFormContext,
  FormGenericControl,
} from 'src/lib/templates';

const SPACING = 4;
const SPACE_BETWEEN = 3;

export const AddUserFormItem = () => {
  const { form, handleSubmit, errors, submitting } = useFormContext();

  const submitProps: ButtonTypesProps = {
    title: 'Guardar',
    handleOnClick: handleSubmit,
    disabled: Object.keys(errors).length !== 0 || submitting,
  };

  return (
    <Grid
      container
      spacing={SPACING}
      direction="column"
      style={{ paddingRight: '5%', paddingLeft: '5%', flex: 1 }}
    >
      <Grid item style={{ paddingLeft: 0, marginBottom: 20, marginTop: -45 }}>
        <Typography variant="h3">Nuevo Usuario</Typography>
      </Grid>
      <Grid container spacing={SPACE_BETWEEN} direction="row">
        <Grid item sm={6}>
          <Grid container spacing={SPACING}>
            <Grid item sm={6}>
              <FormInput
                label="Nombre"
                name="nombre"
                form={form}
                placeholder="Ingresá nombre"
                inputProps={{
                  style: { paddingTop: 6 },
                }}
              />
            </Grid>
            <Grid item sm={6}>
              <FormInput
                label="Apellido"
                name="apellido"
                form={form}
                placeholder="Ingresá apellido"
                inputProps={{
                  style: { paddingTop: 6 },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={6}>
          <Grid container spacing={SPACING}>
            <Grid item sm={6}>
              <FormInput
                label="Número de Documento"
                name="documento"
                form={form}
                type="number"
                placeholder="Escribí el documento"
                inputProps={{
                  style: { paddingTop: 6 },
                }}
              />
            </Grid>
            <Grid item sm={6}>
              <FormInput
                label="CUIL"
                name="cuil"
                form={form}
                placeholder="Ingresá el CUIL"
                inputProps={{
                  style: { paddingTop: 6 },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item sm={8}>
          <Grid container spacing={SPACING}>
            <Grid item sm={8}>
              <FormInput
                label="Cuenta BUE"
                name="cuentaBue"
                form={form}
                placeholder="Ingresar cuenta BUE"
                inputProps={{
                  style: { paddingTop: 6 },
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container item sm={12} justify="flex-end">
          <Grid item>
            <MainButton {...submitProps} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
