import { Button, Grid, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { InfoTable, MainInput, SimpleSelect } from 'src/commons';
import SearchIcon from '@material-ui/icons/Search';
import AddIcon from '@material-ui/icons/Add';
import { useColumnsContratuno } from '../hooks/';
import '../styles/fonts.css';
import {
  ActionContraturno,
  ContraturnosRow,
  Filter,
  PresentismoCustomEndpoint,
} from '../Types';

export const TableContraturnos = ({
  contraturnosRow,
  setAccionContraturno,
  filterContraturno,
  setFilterContraturno,
  reFetchContraturno,
  contraturnoWorking,
  espacioCurricularesWorking,
  presentes,
  presentesWorking,
  fechInit,
  fechaFin,
}: {
  contraturnosRow: ContraturnosRow[];
  setAccionContraturno: React.Dispatch<React.SetStateAction<ActionContraturno>>;
  filterContraturno: Filter;
  setFilterContraturno: React.Dispatch<React.SetStateAction<Filter>>;
  reFetchContraturno: () => void;
  contraturnoWorking: boolean;
  espacioCurricularesWorking: boolean;
  presentes: PresentismoCustomEndpoint[];
  presentesWorking: boolean;
  fechInit: string;
  fechaFin: string;
}) => {
  const [search, setSearch] = useState('');
  const fontClases = {
    fontNunito: {
      fontFamily: 'Nunito',
      color: '#0f75f9',
    },
    formNunitoSemiBold: {
      fontFamily: 'Nunito-SemiBold',
      color: '#3786EE',
    },
  };

  const useStyles = makeStyles({
    fieldset: {
      '& .MuiInputBase-root > fieldset': {
        border: '1px solid #F1F1F1',
      },
    },
    table: {
      '& tr:nth-child(odd)': {
        backgroundColor: '#F9F9F9',
      },
      '& .MuiInputBase-root > fieldset': {
        border: '1px solid #F1F1F1',
      },
      maxHeight: '500px',
    },
    selectInput: {
      backgroundColor: '#FFFFFF',
    },
    fontNunito: {
      fontFamily: 'Nunito-SemiBold',
    },
  });

  const classes = useStyles();
  const columnContraturno = useColumnsContratuno(
    setAccionContraturno,
    setFilterContraturno,
    reFetchContraturno,
    presentes,
    presentesWorking,
  );

  const filters = {
    seccion: filterContraturno.seccion,
    materia: filterContraturno.filterMateria,
    search: search.trim().toLowerCase(),
  };
  const row = contraturnosRow.filter((contraturno: ContraturnosRow) => {
    if (!filters.seccion && !filters.materia && !filters.search) {
      return true;
    }

    const evaluacionSeccion =
      !filters.seccion || contraturno.idSeccion.idSeccion === filters.seccion;
    const evaluacionMateria = !filters.materia
      ? true
      : Object.values(contraturno.objContraturnoDia).some(
          (dia: any) =>
            dia.esc1?.idEspacioCurricular === filters.materia ||
            dia.esc2?.idEspacioCurricular === filters.materia,
        );
    const evaluacionSearch = !filters.search
      ? true
      : contraturno.idSeccion.nombreSeccion
          .toLowerCase()
          .indexOf(filters.search) > -1;
    return evaluacionSeccion && evaluacionMateria && evaluacionSearch;
  });

  const ObjcontraturnoDiaMap = row.map((e: any) => e.objContraturnoDia);
  var descripciones = [];
  for (var i = 0; i < ObjcontraturnoDiaMap.length; i++) {
    for (var day in ObjcontraturnoDiaMap[i]) {
      if (ObjcontraturnoDiaMap[i][day].esc1 != null) {
        descripciones.push(ObjcontraturnoDiaMap[i][day].esc1);
      }
      if (ObjcontraturnoDiaMap[i][day].esc2 != null) {
        descripciones.push(ObjcontraturnoDiaMap[i][day].esc2);
      }
    }
  }

  const uniqueDescriptions = Array.from(new Set(descripciones));

  const rowContraturnosSeccion = contraturnosRow.map((e: any) => e.idSeccion);

  rowContraturnosSeccion.sort((a, b) => {
    var nombreA = a.nombreSeccion.toUpperCase();
    var nombreB = b.nombreSeccion.toUpperCase();
    if (nombreA < nombreB) {
      return -1;
    }
    if (nombreA > nombreB) {
      return 1;
    }
    return 0;
  });

  row.sort((a, b) => {
    var nombreA = a.seccion.toUpperCase();
    var nombreB = b.seccion.toUpperCase();
    if (nombreA < nombreB) {
      return -1;
    }
    if (nombreA > nombreB) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <Grid
        container
        direction="row"
        xs={12}
        spacing={2}
        style={{ paddingTop: '10px' }}
      >
        <Grid item xs={6} justifyContent="flex-start" container spacing={2}>
          <Grid item xs={4} style={{ marginTop: '30px' }}>
            <SimpleSelect
              name={'Seccion'}
              content={rowContraturnosSeccion}
              value={filterContraturno.seccion}
              valueKey={'idSeccion'}
              labelKey={'nombreSeccion'}
              orderBy={'nombreSeccion'}
              placeholder="Sección"
              handleChange={(e: any) => {
                const value = e?.target?.value;
                setFilterContraturno((prev: any) => ({
                  ...prev,
                  seccion: value,
                }));
              }}
              customClassNameFormControl={classes.fieldset}
              selectClassName={classes.selectInput}
              placeholderStyle={fontClases.formNunitoSemiBold}
            ></SimpleSelect>
          </Grid>
          <Grid item xs={4} style={{ marginTop: '30px' }}>
            <SimpleSelect
              //title={'Contraturno'}
              content={uniqueDescriptions}
              value={filterContraturno.filterMateria ?? ''}
              valueKey={'idEspacioCurricular'}
              labelKey={'descripcion'}
              name={'Contraturno'}
              placeholder="Espacio curricular"
              handleChange={(e: any) => {
                const value = e?.target?.value;
                setFilterContraturno((prev: any) => ({
                  ...prev,
                  filterMateria: value,
                }));
              }}
              customClassNameFormControl={classes.fieldset}
              selectClassName={classes.selectInput}
              //customStyleTitle={fontClases.formNunitoSemiBold}
              placeholderStyle={fontClases.formNunitoSemiBold}
            />
          </Grid>
        </Grid>
        <Grid
          item
          xs={6}
          justifyContent="flex-end"
          container
          spacing={1}
          style={{ marginTop: 30 }}
        >
          <Grid item xs={6}>
            <MainInput
              type="search"
              handleChange={(e: any) => {
                setSearch(e.target.value);
                setFilterContraturno((prev: any) => ({
                  ...prev,
                  seccion: '',
                  filterMateria: '',
                }));
              }}
              placeholder="Buscar por sección"
              name="Buscar por seccion"
              value={search}
              fullWidth
              icon={<SearchIcon style={{ color: '#3786EE' }} />}
              customStyle={fontClases.fontNunito}
            />
          </Grid>
          <Grid item xs={3}>
            <Button
              color="primary"
              variant="contained"
              component="label"
              fullWidth
              startIcon={<AddIcon />}
              onClick={() => {
                setFilterContraturno({
                  idcontraturno: null,
                  seccion: '',
                  idSeccion: null,
                  fechaInicio: fechInit,
                  fechaFin: fechaFin,
                  activo: false,
                  contraturnoDia: null,
                  objContraturnoDia: {
                    Lunes: {
                      esc1: null,
                      esc2: null,
                      edit: false,
                      checked: false,
                      cantidad: 0,
                    },
                    Martes: {
                      esc1: null,
                      esc2: null,
                      edit: false,
                      checked: false,
                      cantidad: 0,
                    },
                    Miercoles: {
                      esc1: null,
                      esc2: null,
                      edit: false,
                      checked: false,
                      cantidad: 0,
                    },
                    Jueves: {
                      esc1: null,
                      esc2: null,
                      edit: false,
                      checked: false,
                      cantidad: 0,
                    },
                    Viernes: {
                      esc1: null,
                      esc2: null,
                      edit: false,
                      checked: false,
                      cantidad: 0,
                    },
                  },
                });
                setAccionContraturno({
                  openModalAction: true,
                  title: 'Nuevo Contraturno',
                  presentismo: false,
                  nuevoContraturno: true,
                });
              }}
              style={{
                height: '100%',
                width: '100%',
                fontSize: '14px',
                backgroundColor: '#3786EE',
                textTransform: 'none',
              }}
              className={classes.fontNunito}
            >
              Nuevo contraturno
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid>
        <InfoTable
          rows={row}
          columns={columnContraturno}
          rowIdKey="idcontraturno"
          working={contraturnoWorking && espacioCurricularesWorking}
          customStyle={classes.table}
        />
      </Grid>
    </>
  );
};
