import React from 'react';
import { Seccion } from 'src/app/models';
import { Sede, PropuestaJE } from 'src/app/models/presentismo_v2';

export namespace presentismo {
  export enum Days {
    Lunes = 'Lunes',
    Martes = 'Martes',
    Miercoles = 'Miercoles',
    Jueves = 'Jueves',
    Viernes = 'Viernes',
    Sabado = 'Sabado',
  }

  export namespace UI {
    export interface CheckboxWithField {
      dia: Days;
      name: string;
      disabled?: boolean;
    }

    export interface ModalHeader {
      filters: PropuestaJEFilters;
      changeFilters: React.Dispatch<React.SetStateAction<PropuestaJEFilters>>;
      nivel?: string;
      currentCicloLectivo?: number | null;
    }

    export interface PropuestaTable {
      rows: PropuestaJE[];
      refetch: () => void;
      working: boolean;
    }

    export interface PropuestaHeader {
      invalidSecciones: Seccion[];
    }

    export interface SimpleSelectWithField {
      disabled?: boolean;
      dia: keyof typeof Days;
      name: string;
    }

    export interface DaysTable {
      disabled?: boolean;
      filters?: PropuestaJEFilters;
    }
  }

  export type PropuestaJe = {
    [Days.Lunes]?: MetaDayPropuestaJE;
    [Days.Martes]?: MetaDayPropuestaJE;
    [Days.Miercoles]?: MetaDayPropuestaJE;
    [Days.Jueves]?: MetaDayPropuestaJE;
    [Days.Viernes]?: MetaDayPropuestaJE;
    [Days.Sabado]?: MetaDayPropuestaJE;
    propuesta?: PropuestaJE;
  };

  export interface MetaDayPropuestaJE {
    dentro?: boolean | string;
    sede?: Sede | string | null;
    aceptado?: boolean;
    idPropuestaJeDia?: number;
  }

  export type PropuestaJeRow = {
    idDia: number;
    nombre: Days;
  };

  export interface PropuestaJEContext {
    form: any;
    values: PropuestaJe;
  }

  export enum ConfReducerTypes {
    OPEN_PROPUESTA_JE_MODAL = 'OPEN_PROPUESTA_JE_MODAL',
    CLOSE_PROPUESTA_JE_MODAL = 'CLOSE_PROPUESTA_JE_MODAL',

    OPEN_SITUACION_MODAL = 'OPEN_SITUACION_MODAL',
    CLOSE_SITUACION_MODAL = 'CLOSE_SITUACION_MODAL',

    SET_PROPUESTAS_ROWS = 'SET_PROPUESTAS_ROWS',
    SET_PROPUESTAS_WORKING = 'SET_PROPUESTAS_WORKING',
    SET_REFETCH_PROPUESTAS = 'SET_REFETCH_PROPUESTAS',
  }

  export type ConfigurationActions =
    | {
        type: typeof ConfReducerTypes.SET_PROPUESTAS_WORKING;
        payload: boolean;
      }
    | {
        type: typeof ConfReducerTypes.SET_REFETCH_PROPUESTAS;
        payload: boolean;
      }
    | {
        type: typeof ConfReducerTypes.SET_PROPUESTAS_ROWS;
        payload: PropuestaJE[];
      };

  export type ConfigurationContextType = {
    state: ConfReducerState;
    dispatch: any;
  };

  export type ConfReducerState = {};

  export type PropuestaJEFilters = {
    anio: number | string;
    seccion: number | string;
  };
}
