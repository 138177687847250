import { makeStyles } from '@material-ui/core';
import { grey, primary } from 'src/theme/colors';
// import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { TooltipClassKey } from '@material-ui/core/Tooltip';

export const useInformesStyles = () => {
  const useStyles = makeStyles(() => ({
    personalInfoContainer: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      padding: '50px 30px 30px 30px',
      fontSize: '1.5rem',
      fontFamily: 'sans-serif',
    },
    isIncompleteClose: {
      display: 'flex',
      width: '100%',
      marginLeft: '20%',
      paddingTop: '17px',
      fontFamily: 'Open Sans',
      fontSize: '14px',
      lineHeight: '22px',
    },
    incompleteMessageClose: {
      borderRadius: 4,
      display: 'flex',
      backgroundColor: primary.errorMedium,
      flexFlow: 'column',
      color: grey.dark,
      padding: '10px 20px',
    },
    personalInfoApellido: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      padding: '0px 30px',
    },
    personalInfoDNI: {
      display: 'flex',
      justifyContent: 'start',
      alignItems: 'center',
      padding: '0px 30px',
      fontWeight: 'bold',
    },
    table: {
      maxHeight: '600px',
      marginBottom: '80px',
      '& th': {
        textAlign: 'center',
      },
      '& td': {
        textAlign: 'center',
        border: 'solid 1px lavender',
      },
    },
    header: {
      color: primary.lightBlue,
      textAlign: 'center',
      display: 'flex',
    },
    adjunto: {
      position: 'relative',
      border: 'none',
      borderRadius: 10,
      padding: 10,
      backgroundColor: 'rgb(240,240,240)',
      display: 'flex',
      justifyContent: 'space-between',
      cursor: 'pointer',
      whiteSpace: 'nowrap',
    },
    adjuntoClose: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 7px',
      fontWeight: 'bold',
      fontSize: '1.5em',
    },
    disabledInput: {
      backgroundColor: '#DADADA',
    },
    adjuntoNombre: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '90%',
    },
    iconObservacion: {
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'end',
      color: primary.lightBlue,
    },
    footer: {
      display: 'flex',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
    },
    observacionesWrapper: {
      display: 'flex',
      justifyContent: 'end',
      color: 'black',
      position: 'relative',
    },
    observaciones: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      // width: '100px',
      textAlign: 'center',
      color: 'black',
    },
    observacionesText: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      // width: '70px',
      maxWidth: '40vw',
      textAlign: 'center',
      color: 'black',
    },
    rowSelect: {
      minWidth: 50,
    },
    required: {
      position: 'relative',
      borderRadius: '5px',
      border: 'solid 2px red',
      padding: '20px',
      '&::before': {
        top: 'calc(0% - 0em)',
        left: '0',
        width: '100%',
        content: '"Campo requerido"',
        color: 'red',
        position: 'absolute',
        textAlign: 'center',
        whiteSpace: 'nowrap',
      },
    },
    dimensiones: {
      color: primary.lightBlue,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    dimensionesText: {
      boxSizing: 'border-box',
      padding: '10px 20px 10px 40px',
      textAlign: 'justify',
    },
    tooltip: {
      display: 'flex',
      padding: '10px',
      color: 'white',
      fontSize: '1em',
      lineHeight: '1.2em',
    },
    tooltipIcon: {
      color: '#3f51b5',
      fontSize: '1em',
      width: '30px',
      height: '30px',
    },
  }));

  const classes = useStyles();
  return classes;
};
