import { LibbyFetchDAO } from './LibbyFetchDAO';

export class TipoDisciplinaDAO extends LibbyFetchDAO {
  constructor() {
    super('tipo_disciplina', 'idTipoDisciplina');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
