import React, { useState, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { SimpleSelect } from 'src/commons/components/Select/Select';

export const RegionSelect = (props: any) => {
  const [region, setRegion] = useState<string>('');

  const regiones = useMemo(() => {
    return [
      { name: 'I', value: 'I' },
      { name: 'II', value: 'II' },
      { name: 'III', value: 'III' },
      { name: 'IV', value: 'IV' },
      { name: 'V', value: 'V' },
      { name: 'VI', value: 'VI' },
      { name: 'VII', value: 'VII' },
      { name: 'VIII', value: 'VIII' },
    ];
  }, []);

  const handleChange = (e: any) => {
    const esc = regiones.find((i) => i.value === e.target.value);
    if (esc) {
      setRegion(esc.value);
      props.onChange(esc.value);
    } else {
      setRegion('');
      props.onChange(undefined);
    }
  };

  return (
    <Grid item>
      <SimpleSelect
        title="Región"
        placeholder="Seleccione Región"
        value={region}
        valueKey="value"
        labelKey="name"
        content={regiones}
        onChange={handleChange}
      />
    </Grid>
  );
};
