import { Box, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { MainButton } from 'src/commons/components';
import { useStyles } from '../../styles/configuration';
import customFormDialog from 'src/commons/services/customFormDialog';
import { Modal } from './PropuestaJE';
import { RolBlocker } from 'src/commons/components/Blockers/RolBlocker';
import { ROL } from 'src/commons';
import { usePresentismoContext } from '../../context/Context';
import { useRolesContext } from '../../../../../context/RolesContext';

export const PropuestaHeader = () => {
  const classes = useStyles();
  const {
    selectedRole: { nivel },
  } = useRolesContext();
  const { reFetch } = usePresentismoContext();
  const onClick = async () => {
    await customFormDialog.show({
      title: 'Propuesta Jornada Extendida',
      renderComponent: <Modal reFetch={reFetch} nivel={nivel} />,
      sizeWidth: 'md',
    });
  };

  return (
    <Grid
      container
      alignItems="center"
      classes={{
        root: classes.headerContainer,
      }}
    >
      <Grid item xs={4}>
        <Typography variant="h2" color="textPrimary">
          <Box fontFamily="Nunito">Jornada extendida</Box>
        </Typography>
      </Grid>
      <RolBlocker enableForRoles={[ROL.COORDINADOR_JE]}>
        <Grid item xs={3}>
          <MainButton handleOnClick={onClick} title="Programar Propuesta" />
        </Grid>
      </RolBlocker>
    </Grid>
  );
};
