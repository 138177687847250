import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { TipoDomicilio } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'tipo_domicilio';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idTipoDomicilio',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idTipoDomicilio',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.TipoDomicilio
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.TipoDomicilio,
      hoc: { ...defaultOptions.hoc, ...businessOptions.TipoDomicilio.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<TipoDomicilio>(DAO_NAME, optionsToUse);

// hooks
export const useTipoDomicilioFetchById = fetchById;
export const useTipoDomicilioLibbyCall = libbyCall;
export const useTipoDomicilioDAO = libbyDAO;
export const useTipoDomicilioLibbyFetch = libbyFetch;

// components/hoc
export const TipoDomicilioAutocomplete = entityAutocomplete;
export const TipoDomicilioCall = renderPropCall;
export const TipoDomicilioById = renderPropFetchById;
export const TipoDomicilioSelect = simpleSelect;
export const TipoDomicilioAccordionSelector = accordionSelector;
export const TipoDomicilioSelectedChips = selectedChips;

// context
// ByIdContext
export const useTipoDomicilioByIdContext = useByIdContext;
export const TipoDomicilioByIdProvider = ByIdProvider;
export const TipoDomicilioByIdContext = ByIdContext;
// GeneralContext
export const useTipoDomicilioContext = useGeneralContext;
export const TipoDomicilioProvider = GeneralProvider;
export const TipoDomicilioContext = GeneralContext;
