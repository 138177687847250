import { ProyeccionEstadoDAO } from 'src/platform/libby/dao';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { ProyeccionEstado } from '../models/ProyeccionEstado';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'proyeccion_estado';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idProyeccionEstado',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idProyeccionEstado',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.ProyeccionEstado
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.ProyeccionEstado,
      hoc: { ...defaultOptions.hoc, ...businessOptions.ProyeccionEstado.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<ProyeccionEstado, ProyeccionEstadoDAO>(
  DAO_NAME,
  optionsToUse,
);

// hooks
export const useProyeccionEstadoFetchById = fetchById;
export const useProyeccionEstadoLibbyCall = libbyCall;
export const useProyeccionEstadoDAO = libbyDAO;
export const useProyeccionEstadoLibbyFetch = libbyFetch;

// components/hoc
export const ProyeccionEstadoAutocomplete = entityAutocomplete;
export const ProyeccionEstadoCall = renderPropCall;
export const ProyeccionEstadoById = renderPropFetchById;
export const ProyeccionEstadoSelect = simpleSelect;
export const ProyeccionEstadoAccordionSelector = accordionSelector;
export const ProyeccionEstadoSelectedChips = selectedChips;

// context
// ByIdContext
export const useProyeccionEstadoByIdContext = useByIdContext;
export const ProyeccionEstadoByIdProvider = ByIdProvider;
export const ProyeccionEstadoByIdContext = ByIdContext;
// GeneralContext
export const useProyeccionEstadoContext = useGeneralContext;
export const ProyeccionEstadoProvider = GeneralProvider;
export const ProyeccionEstadoContext = GeneralContext;
