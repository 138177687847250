import { Motivo } from 'src/app/models/presentismo_v2/Motivo';
import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class MotivoDAO extends LibbyFetchDAO {
  constructor() {
    super('motivo', 'idMotivo');
  }

  async saveMotivo(motivo: Motivo) {
    return await this.aspect('savingMotivo').save(motivo);
  }
}
