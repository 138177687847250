import moment from 'moment';
import React, { useEffect, useMemo, useState } from 'react';
import { useTiposAccionesLibbyCall } from 'src/app/business';
import { useAccionesMinimizadasLibbyFetch } from 'src/app/business/acap/AccionesMinimizadas';
import { useOrientacionesLibbyCall } from 'src/app/business/orientaciones/Orientaciones';
import {
  ButtonTypesProps,
  Footer,
  InfoTable,
  useDebounce,
  useRouteScreenTitle,
} from 'src/commons';
import useGenerarExcel from 'src/commons/hooks/useGenerarExcel';
import { useRouterContext } from 'src/lib/router';
import { setSearchParamsMultipleWords } from 'src/utils/setSearchParamsMultipleWords';
import { useAcapEstablecimientoContext } from '../context/AcapEstablecimientoContext';
import { useAccionesExcelData } from '../hooks/useAccionesExcelData';
import { AccionesFilter } from '../types';
import { AccionesAsociadasHeader } from './components/AccionesAsociadasHeader';
import { useAccionesColumns } from './hooks/useAccionesColumns';
import {
  ACAP_SCREEN,
  ACCIONES_SCREEN,
  ORGANIZACION_SCREEN,
} from '../context/type';

export const AccionesAsociadas = () => {
  useRouteScreenTitle('Acciones asociadas');
  const {
    organizacionesScreen,
    ciclosLectivos,
    setScreen,
    setAccionesScreen,
    setOrganizacionesScreen,
  } = useAcapEstablecimientoContext();
  const generateExcel = useGenerarExcel();
  const { setMetadata } = useRouterContext();
  const [filters, setFilters] = useState<AccionesFilter>(() => {
    return {
      tipoAccion: 0,
      orientacion: 0,
      cicloLectivo: 0,
      search: '',
      organizacionName: organizacionesScreen.data?.nombre || '',
      sectorName: organizacionesScreen.data?.sector.nombre || '',
    };
  });

  useEffect(() => {
    if (ciclosLectivos.length > 0) {
      setFilters(filters => ({...filters, cicloLectivo: ciclosLectivos.find((cl) => {
        return cl.estadoCicloLectivo.idEstadoCicloLectivo === 1;
      })?.idCicloLectivo ?? 0 }))
    }
  }, [ciclosLectivos])

  const searchDebounced = useDebounce(filters.search, 1000);
  const searchFilterParams = useMemo(
    () => ({
      ...setSearchParamsMultipleWords(
        ['nombre', 'descripcion', 'tipoAccion.nombre'],
        searchDebounced,
      ),
    }),
    [searchDebounced],
  );

  const accionFilter = useMemo(
    () => ({
      0: Boolean(organizacionesScreen.data?.idOrganizacion)
        ? [
            {
              path: 'organizacion',
              value: organizacionesScreen.data?.idOrganizacion,
            },
          ]
        : [],
      1: Boolean(filters?.tipoAccion)
        ? [
            {
              path: 'tipoAccion',
              value: filters?.tipoAccion,
            },
          ]
        : [],
      2: Boolean(filters?.orientacion)
        ? [
            {
              path: 'accionOrientaciones.orientacion',
              value: filters?.orientacion,
              method: 'equals',
            },
          ]
        : [],
      3: Boolean(filters?.cicloLectivo)
        ? [
            {
              path: 'cicloLectivo',
              value: filters?.cicloLectivo,
            },
          ]
        : [],
      ...searchFilterParams,
    }),
    [
      filters?.cicloLectivo,
      filters?.orientacion,
      filters?.tipoAccion,
      organizacionesScreen.data?.idOrganizacion,
      searchFilterParams,
    ],
  );

  const {
    data: acciones = [],
    reFetch: reFetchAcciones,
    working: workingAcciones,
  } = useAccionesMinimizadasLibbyFetch({
    filter: accionFilter,
    limit: 500,
    enabled: !!organizacionesScreen.data?.idOrganizacion,
  });

  const excelData = useAccionesExcelData(
    acciones,
    `Acciones ACAP del día ${moment().format('DD/MM/YYYY')}`,
  );

  const columns = useAccionesColumns({
    refetchAcciones: reFetchAcciones,
    handleView: (data) => () => {
      setScreen(ACAP_SCREEN.ACCIONES_ASOCIADAS);
      setAccionesScreen({
        screen: ACCIONES_SCREEN.VIEW,
        data: data,
      });
    },
    handleEdit: (data) => () => {
      setScreen(ACAP_SCREEN.ACCIONES_ASOCIADAS);
      setAccionesScreen({
        screen: ACCIONES_SCREEN.EDIT,
        data: data,
      });
    },
    handleDuplicate: (data) => () => {
      const { idAccion, accionOrientaciones, ...rest } = data;

      const aoes = accionOrientaciones.map((ao) => {
        const { idAccionOrientacion, idAccion, ...rest } = ao;
        return {
          ...rest,
          idAccion: '',
          idAccionOrientacion: 0,
        };
      });
      setScreen(ACAP_SCREEN.ACCIONES_ASOCIADAS);
      setAccionesScreen({
        screen: ACCIONES_SCREEN.DUPLICATE,
        data: {
          idAccion: '',
          ...rest,
          accionOrientaciones: aoes,
        },
      });
    },
  });

  const buttonConfig: ButtonTypesProps[] = [
    {
      title: 'Volver',
      handleOnClick: () => {
        setScreen(ACAP_SCREEN.ORGANIZACIONES);
        setOrganizacionesScreen((prev) => ({
          screen: ORGANIZACION_SCREEN.MAIN,
        }));
        setMetadata((prev) => ({
          ...prev,
          title: 'Actividades de aproximación',
        }));
      },
      size: 'medium',
      disabled: false,
    },
  ];

  const { data: orientaciones = [] } = useOrientacionesLibbyCall({
    methodName: 'getAll',
  });

  const { data: tipoAcciones = [] } = useTiposAccionesLibbyCall({
    methodName: 'getAll',
  });

  return (
    <>
      <AccionesAsociadasHeader
        filters={filters}
        handleChangeFilter={setFilters}
        tipoAcciones={tipoAcciones}
        orientaciones={orientaciones}
        ciclosLectivos={ciclosLectivos}
        handleCreate={() => {
          setScreen(ACAP_SCREEN.ACCIONES_ASOCIADAS);
          setAccionesScreen({
            screen: ACCIONES_SCREEN.NEW,
          });
        }}
        disableGenerateExcel={!excelData?.body?.data?.length || workingAcciones}
        handleGenerateExcel={() => {
          generateExcel(excelData?.body, excelData?.name);
        }}
      />
      <InfoTable rows={acciones} columns={columns} working={workingAcciones} />
      <Footer buttonConfig={buttonConfig} />
    </>
  );
};
