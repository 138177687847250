import _ from 'lodash';
import { NIVEL } from 'src/commons/const';
import { establecimientoReingresoNocturosYComerciales } from '../../utils/establecimientoReingresoNocturosYComerciales';
import {
  MAPPED_PASE_ANIO_OPTIONS_PRIMARIO,
  MAPPED_PASE_ANIO_OPTIONS_SECUNDARIO,
} from '../../utils/mappedPaseAnioOptions';
import { EstadoPaseAnio, PlanEstudioNivel } from 'src/app/models';

type useSelectPassStatusOptionsProps = {
  row: any;
  options: any;
  localizacionData: any;
  nivel: any;
  idEstadoDeLaProyeccion: number;
  totalPendientes?: number;
  planEstudioNivel?: PlanEstudioNivel;
};

export const useSelectPassStatusOptions = ({
  row,
  options,
  localizacionData,
  nivel,
  idEstadoDeLaProyeccion,
  totalPendientes = 0,
  planEstudioNivel,
}: useSelectPassStatusOptionsProps) => {
  let newOptions = [];
  let isLastYear;

  if (Number(nivel) === NIVEL.INICIAL) {
    if (row?.currentYear?.idAnio === 15) {
      // el ultimo anio de inicial es 15
      isLastYear = { quinto: true, cuartoyQuinto: false };

      if (row.statusPass !== 2 && row.statusPass !== 3) {
        row.statusPass = '';
      }
    } else if (
      row?.currentYear?.idAnio === 19 ||
      row?.currentYear?.idAnio === 20
    ) {
      isLastYear = { quinto: false, cuartoyQuinto: true };
    } else {
      isLastYear = { quinto: false, cuartoyQuinto: false };
    }

    let optionsFiltered = _.cloneDeep(options);

    optionsFiltered = optionsFiltered.filter(
      (option: EstadoPaseAnio) =>
        ![4, 5, 6, 7, 8, 9, 10].includes(option.idEstadoPaseAnio),
    );
    if (
      row?.currentYear?.idAnio === 10 ||
      row?.currentYear?.idAnio === 11 ||
      row?.currentYear?.idAnio === 12 ||
      row?.currentYear?.idAnio === 13 ||
      row?.currentYear?.idAnio === 16 ||
      row?.currentYear?.idAnio === 17
    ) {
      optionsFiltered = optionsFiltered.filter(
        (option: EstadoPaseAnio) =>
          ![2, 4, 5, 6, 7, 8, 9, 10].includes(option.idEstadoPaseAnio),
      );
    }
    if (!isLastYear.quinto && !isLastYear.cuartoyQuinto) {
      newOptions = optionsFiltered.filter(
        (option: EstadoPaseAnio) => ![3].includes(option.idEstadoPaseAnio),
      );
    } else if (isLastYear.quinto) {
      newOptions = optionsFiltered.filter(
        (option: EstadoPaseAnio) => ![1].includes(option.idEstadoPaseAnio),
      );
    } else if (isLastYear.cuartoyQuinto) {
      newOptions = optionsFiltered;
    }
  } else if (Number(nivel) === NIVEL.SECUNDARIO) {
    let dependenciaFuncional =
      localizacionData[0]?.establecimiento?.dependenciaFuncional
        ?.idDependenciaFuncional;

    const perteneceAlArchivo =
      localizacionData[0]?.idLocalizacion in
      establecimientoReingresoNocturosYComerciales;

    const perteneceAlArchivoYNocturno =
      perteneceAlArchivo &&
      establecimientoReingresoNocturosYComerciales[
        localizacionData[0]?.idLocalizacion
      ]?.tipo === 'nocturno';

    const perteneceAlArchivoYReingreso =
      perteneceAlArchivo &&
      establecimientoReingresoNocturosYComerciales[
        localizacionData[0]?.idLocalizacion
      ]?.tipo === 'reingreso';

    const colegiosHibridos = ['20038300'];

    const perteneceEscuelasCBO = [
      '20242200',
      '20203900',
      '20203800',
      '20203300',
    ].includes(localizacionData[0]?.idLocalizacion);

    const tecnicoYBachiller = ['20108500', '20091100'].includes(
      localizacionData[0]?.idLocalizacion,
    );

    const {
      'Promoción directa': PROMOCION_DIRECTA,
      Egresa: EGRESA,
      Permanece: PERMANECE,
      'Promoción en proceso': PROMOCION_EN_PROCESO,
      'Con promoción acompañada': PROMOCION_CON_APOYO,
      'No completó el Plan de Estudio': NO_COMPLETO_PLAN_DE_ESTUDIOS,
    } = MAPPED_PASE_ANIO_OPTIONS_SECUNDARIO;
    // idDepdencenciaFuncional 32 = Dirección de Educación Técnica
    if (row?.currentYear?.numeroAnio === 5 && dependenciaFuncional !== 32) {
      isLastYear = true;
    } else if (
      (planEstudioNivel?.planEstudio?.idPlanEstudio === 316 ||
        planEstudioNivel?.planEstudio?.idPlanEstudio === 320) &&
      colegiosHibridos &&
      row?.currentYear?.numeroAnio === 4
    ) {
      isLastYear = true;
    } else if (
      row?.currentYear?.numeroAnio === 4 &&
      perteneceAlArchivoYNocturno &&
      row?.turno.descripcionTurno === 'Noche'
    ) {
      isLastYear = true;
    } else if (
      row?.currentYear?.numeroAnio === 4 &&
      perteneceAlArchivoYNocturno &&
      row?.turno === 'Noche'
    ) {
      isLastYear = true;
    } else if (
      row?.currentYear?.numeroAnio === 6 &&
      dependenciaFuncional === 32
    ) {
      isLastYear = true;
    } else if (
      perteneceAlArchivoYReingreso &&
      row?.currentYear?.numeroAnio === 4 &&
      planEstudioNivel?.planEstudio?.idPlanEstudio !== 218 &&
      planEstudioNivel?.planEstudio?.idPlanEstudio !== 217
    ) {
      isLastYear = true;
    } else if (
      row?.currentYear?.numeroAnio === 5 &&
      perteneceAlArchivoYReingreso &&
      (row?.idSection === '100355' || row?.idSection === '100246')
    ) {
      isLastYear = true;
    } else if (row?.currentYear?.numeroAnio === 3 && perteneceEscuelasCBO) {
      isLastYear = true;
    } else if (
      row?.currentYear?.numeroAnio === 5 &&
      tecnicoYBachiller &&
      [
        '80849',
        '80852',
        '80854',
        '70222',
        '70237',
        '96181',
        '96166',
        '86431',
        '86429',
        '86427',
      ].includes(row?.idSection)
    ) {
      isLastYear = true;
    }
    const isPrimerAnio = row?.currentYear?.numeroAnio === 1;
    let optionsClone = _.cloneDeep(options);
    const optionsReNamed: any = optionsClone.map((option: EstadoPaseAnio) => {
      return {
        idEstadoPaseAnio: option.idEstadoPaseAnio,
        descripcionEstadoPaseAnio:
          MAPPED_PASE_ANIO_OPTIONS_SECUNDARIO[option.descripcionEstadoPaseAnio][
            'description'
          ],
      };
    });

    if (isPrimerAnio) {
      if (totalPendientes <= 2) {
        newOptions = optionsReNamed.filter((option: EstadoPaseAnio) =>
          [PROMOCION_DIRECTA.id].includes(option.idEstadoPaseAnio),
        );
      } else {
        newOptions = optionsReNamed.filter((option: EstadoPaseAnio) =>
          [PROMOCION_CON_APOYO.id].includes(option.idEstadoPaseAnio),
        );
      }
    }

    if (isLastYear) {
      if (totalPendientes < 3) {
        newOptions = optionsReNamed.filter((option: EstadoPaseAnio) =>
          [EGRESA.id].includes(option.idEstadoPaseAnio),
        );
      } else {
        newOptions = optionsReNamed.filter((option: EstadoPaseAnio) =>
          [NO_COMPLETO_PLAN_DE_ESTUDIOS.id].includes(option.idEstadoPaseAnio),
        );
      }
    }

    if (!isPrimerAnio && !isLastYear) {
      if (totalPendientes <= 2) {
        newOptions = optionsReNamed.filter((option: EstadoPaseAnio) =>
          [PROMOCION_DIRECTA.id].includes(option.idEstadoPaseAnio),
        );
      } else {
        newOptions = optionsReNamed.filter((option: EstadoPaseAnio) =>
          [
            PROMOCION_EN_PROCESO.id,
            PROMOCION_CON_APOYO.id,
            PERMANECE.id,
          ].includes(option.idEstadoPaseAnio),
        );
      }
    }
  } else if (Number(nivel) === NIVEL.PRIMARIO) {
    const {
      Promociona: PROMOCIONA,
      'Promoción directa': PROMOCION_DIRECTA,
      Egresa: EGRESA,
      Permanece: PERMANECE_CON_ACOMPANAMIENTO,
      'Continúa con apoyo para la promoción': CONTINUA_CON_APOYO_PARA_PROMOCION,
      'Con promoción acompañada': PROMOCIONA_CON_PROMOCION_ACOMPANADA,
    } = MAPPED_PASE_ANIO_OPTIONS_PRIMARIO;

    const is7grado = row?.currentYear?.idAnio === 7;
    const is6y7grado = row?.currentYear?.idAnio === 9;
    const optionsClone = _.cloneDeep(options);

    const optionsReNamed: any = optionsClone.map((option: any) => {
      return {
        idEstadoPaseAnio: option.idEstadoPaseAnio,
        descripcionEstadoPaseAnio:
          MAPPED_PASE_ANIO_OPTIONS_PRIMARIO[option.descripcionEstadoPaseAnio][
            'description'
          ],
      };
    });

    if (is6y7grado) {
      newOptions = optionsReNamed.filter((option: EstadoPaseAnio) =>
        [
          EGRESA.id,
          PERMANECE_CON_ACOMPANAMIENTO.id,
          CONTINUA_CON_APOYO_PARA_PROMOCION.id,
          ...(idEstadoDeLaProyeccion === 1 ? [PROMOCION_DIRECTA.id] : []),
          ...(idEstadoDeLaProyeccion === 2 &&
          row.statusPass === PROMOCION_DIRECTA.id
            ? [PROMOCION_DIRECTA.id]
            : []),
          ...(idEstadoDeLaProyeccion === 2 &&
          [
            CONTINUA_CON_APOYO_PARA_PROMOCION.id,
            PROMOCIONA.id,
            PROMOCIONA_CON_PROMOCION_ACOMPANADA.id,
          ].includes(row.statusPass)
            ? [PROMOCIONA_CON_PROMOCION_ACOMPANADA.id, PROMOCIONA.id]
            : []),
        ].includes(option.idEstadoPaseAnio),
      );
    }

    if (is7grado) {
      newOptions = optionsReNamed.filter((option: EstadoPaseAnio) =>
        [EGRESA.id, PERMANECE_CON_ACOMPANAMIENTO.id].includes(
          option.idEstadoPaseAnio,
        ),
      );
    }

    if (!is6y7grado && !is7grado) {
      newOptions = optionsReNamed.filter((option: EstadoPaseAnio) =>
        [
          PERMANECE_CON_ACOMPANAMIENTO.id,
          CONTINUA_CON_APOYO_PARA_PROMOCION.id,
          ...(idEstadoDeLaProyeccion === 1 ? [PROMOCION_DIRECTA.id] : []),
          ...(idEstadoDeLaProyeccion === 2 &&
          row.statusPass === PROMOCION_DIRECTA.id
            ? [PROMOCION_DIRECTA.id]
            : []),
          ...(idEstadoDeLaProyeccion === 2 &&
          [
            CONTINUA_CON_APOYO_PARA_PROMOCION.id,
            PROMOCIONA.id,
            PROMOCIONA_CON_PROMOCION_ACOMPANADA.id,
          ].includes(row.statusPass)
            ? [PROMOCIONA_CON_PROMOCION_ACOMPANADA.id, PROMOCIONA.id]
            : []),
        ].includes(option.idEstadoPaseAnio),
      );
    }
  }
  return {
    newOptions,
  };
};
