import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { PropositosDeEnsenanza } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'propositos_de_ensenanza';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPropositosDeEnsenanza',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPropositosDeEnsenanza',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.PropositosDeEnsenanza
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.PropositosDeEnsenanza,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.PropositosDeEnsenanza.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<PropositosDeEnsenanza>(DAO_NAME, optionsToUse);

// hooks
export const usePropositosDeEnsenanzaFetchById = fetchById;
export const usePropositosDeEnsenanzaLibbyCall = libbyCall;
export const usePropositosDeEnsenanzaDAO = libbyDAO;
export const usePropositosDeEnsenanzaLibbyFetch = libbyFetch;

// components/hoc
export const PropositosDeEnsenanzaAutocomplete = entityAutocomplete;
export const PropositosDeEnsenanzaCall = renderPropCall;
export const PropositosDeEnsenanzaById = renderPropFetchById;
export const PropositosDeEnsenanzaSelect = simpleSelect;
export const PropositosDeEnsenanzaAccordionSelector = accordionSelector;
export const PropositosDeEnsenanzaSelectedChips = selectedChips;

// context
// ByIdContext
export const usePropositosDeEnsenanzaByIdContext = useByIdContext;
export const PropositosDeEnsenanzaByIdProvider = ByIdProvider;
export const PropositosDeEnsenanzaByIdContext = ByIdContext;
// GeneralContext
export const usePropositosDeEnsenanzaContext = useGeneralContext;
export const PropositosDeEnsenanzaProvider = GeneralProvider;
export const PropositosDeEnsenanzaContext = GeneralContext;
