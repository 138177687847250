import { useEffect, useState } from 'react';
import { WorldTimeApiResponse } from '../../types';

/**
 * Obtiene la hora de http://worldtimeapi.org/api
 * para ser usada en la aplicación como un state
 * @param tz Zona horaria que se usara
 * @returns React.State<string | null>
 */
export default function useWorldTime(tz = 'America/Argentina/Buenos_Aires') {
  const [currentDate, setCurrentDate] = useState<string>(
    new Date().toLocaleString('en-US', {
      timeZone: 'America/Argentina/Buenos_Aires',
    }),
  );

  useEffect(() => {
    (async function () {
      try {
        const response = await fetch(
          `https://worldtimeapi.org/api/timezone/${tz}`,
        );
        const json: WorldTimeApiResponse = await response.json();
        setCurrentDate(json.datetime);
      } catch (error) {
        console.error(error);
      }
    })();
  }, [tz]);

  return currentDate;
}
