import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class ObservacionescCustomDAO extends LibbyFetchDAO {
  constructor() {
    super('observacionesc_custom', 'idCalificacion');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
