import { LibbyFetchDAO } from './LibbyFetchDAO';

export class TipoVinculoDAO extends LibbyFetchDAO {
  constructor() {
    super('tipo_vinculo', 'idTipoVinculo');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
