import { Box, Button, Grid } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { MainInput, SimpleSelect } from 'src/commons';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { Filters } from '../tabs/oferta';
import {
  CicloLectivo,
  EstablecimientosAcap,
  Sector,
} from '../../../../app/models';

interface OfertaFiltersHeaderProps {
  filters: Filters;
  sectores: Sector[];
  cicloLectivos: CicloLectivo[];
  establecimientos: EstablecimientosAcap[];
  workingCiclos: boolean;
  workingSectores: boolean;
  workingEstablecimientos: boolean;
  disableDownload: boolean;
  isEnabledDetailTable: boolean;
  setFilters: React.Dispatch<React.SetStateAction<Filters>>;
  handleGenerateExecel: () => void;
  addOferta: () => void;
  setOffset: React.Dispatch<React.SetStateAction<number>>;
}

export const OfertaFiltersHeader = ({
  filters,
  sectores,
  cicloLectivos = [],
  establecimientos,
  workingCiclos,
  workingSectores,
  workingEstablecimientos,
  disableDownload = false,
  isEnabledDetailTable = false,
  setFilters,
  handleGenerateExecel,
  addOferta,
  setOffset,
}: OfertaFiltersHeaderProps) => {
  const handleChangeInput = useCallback(
    (e: any) => {
      setFilters((prev) => ({
        ...prev,
        [e.target.name]: e.target.value,
      }));
      setOffset(0);
    },
    [setFilters, setOffset],
  );
  const establecimientosOrdered = useMemo(() => {
    // Clonamos el arreglo para no modificar el original
    const clonedEstablecimientos = [...establecimientos];

    clonedEstablecimientos.sort((a, b) => {
      if (
        a.dependenciaFuncional.idDependenciaFuncional <
        b.dependenciaFuncional.idDependenciaFuncional
      ) {
        return -1;
      } else if (
        a.dependenciaFuncional.idDependenciaFuncional >
        b.dependenciaFuncional.idDependenciaFuncional
      ) {
        return 1;
      } else {
        if (a.nombre < b.nombre) {
          return -1;
        } else if (a.nombre > b.nombre) {
          return 1;
        } else {
          return 0;
        }
      }
    });

    return clonedEstablecimientos;
  }, [establecimientos]);
  return (
    <Grid component={Box} container mt={2} p={2}>
      <Grid
        item
        container
        justifyContent="space-between"
        alignItems="flex-end"
        spacing={2}
      >
        <Grid
          item
          container
          xs={12}
          sm={12}
          md={8}
          justifyContent="flex-start"
          spacing={3}
          alignItems="flex-end"
        >
          <Grid item xs={12} sm={6} md={3}>
            <SimpleSelect
              title="Ciclo Lectivo"
              name="cicloLectivo"
              value={filters.cicloLectivo}
              handleChange={handleChangeInput}
              placeholder="Seleccioná ciclo"
              content={cicloLectivos}
              labelKey="anio"
              valueKey="idCicloLectivo"
              disabled={workingCiclos}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <SimpleSelect
              title="Sector"
              name="sector"
              disabled={isEnabledDetailTable || workingSectores}
              value={filters.sector}
              handleChange={handleChangeInput}
              placeholder="Todos"
              content={sectores}
              labelKey="nombre"
              valueKey="idSector"
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <SimpleSelect
              title="Establecimiento"
              name="establecimiento"
              disabled={isEnabledDetailTable || workingEstablecimientos}
              value={filters.establecimiento}
              handleChange={handleChangeInput}
              placeholder="Todos"
              content={establecimientosOrdered}
              labelKey="nombre"
              valueKey="idEstablecimiento"
            />
          </Grid>
        </Grid>

        <Grid
          item
          container
          sm={12}
          md={4}
          justifyContent="flex-end"
          spacing={3}
          alignItems="center"
        >
          <Grid item xs>
            <MainInput
              type="search"
              disabled={isEnabledDetailTable}
              handleChange={handleChangeInput}
              placeholder="Buscar"
              name="search"
              value={filters.search}
              fullWidth
            />
          </Grid>

          <Grid item container xs>
            <Button
              color="primary"
              variant="contained"
              fullWidth
              onClick={handleGenerateExecel}
              disabled={disableDownload}
            >
              Descargar
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item component={Box} marginTop={3}>
        <Button
          variant="text"
          style={{ textTransform: 'none' }}
          endIcon={<AddCircleOutlineIcon color="primary" fontSize="large" />}
          onClick={addOferta}
        >
          Agregar oferta
        </Button>
      </Grid>
    </Grid>
  );
};
