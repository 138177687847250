import { indicadores2023 } from '../../informesConfigData/indicadores2023';
import { indicadores2022 } from '../../informesConfigData/indicadores2022';
import {
  ANIOS_NIVEL_INICIAL,
  AUTHORIZED_TO_OPEN,
  PERIODO,
} from '../../informesConfigData/informesConst';
import {
  Indicadores,
  CicloLectivoKey,
  Cuatrimestre,
  AniosNivelInicial,
} from '../../informesTypes';
import { useCalificacionesContext } from '../../../context/CalificacionesContext';
import moment from 'moment';

export const useInformeConfig = (
  establecimientoEfectivizado: boolean,
  anio: number,
  periodo: number,
) => {
  const { filter } = useCalificacionesContext();
  const INDICADORES: Indicadores = {
    '2024': indicadores2023,
    '2023': indicadores2023,
    '2022': indicadores2022,
  };

  const getConfigToUse = () => {
    let cicloLectivo: CicloLectivoKey;
    let cuatrimestre: Cuatrimestre;
    let indicadoresToReturn = [];

    const commonConfig = {
      PERIODO,
      AUTHORIZED_TO_OPEN,
      ANIOS_NIVEL_INICIAL,
      indicadores: [],
    };
    // ESTO HAAY QUE CAMBIARLO // DEBE VENIR DE LA DB ESTOS INDICAADORES POR CICLO LECTIVO
    /* if (!establecimientoEfectivizado) {
      if (periodo === commonConfig['PERIODO'].SEGUNDO_BIMESTRE) {
        cicloLectivo = '2022';
        cuatrimestre = 'I';
      }
      cicloLectivo = '2022';
      cuatrimestre = 'II';
    } else { */
    cicloLectivo = moment().year().toString() as CicloLectivoKey;
    cuatrimestre =
      periodo === commonConfig['PERIODO'].SEGUNDO_BIMESTRE ? 'I' : 'II';
    /* } */

    const indicadores = [...INDICADORES[cicloLectivo][cuatrimestre]].sort(
      (a, b) => a.orderInTable - b.orderInTable,
    );

    indicadoresToReturn = indicadores.filter((indicador) =>
      indicador.usedInAnios.find(
        (usedInAnios: AniosNivelInicial) =>
          ANIOS_NIVEL_INICIAL[usedInAnios] === anio,
      ),
    );

    const requiredFields = indicadoresToReturn
      .filter((indicador) =>
        indicador.requiredFor.find(
          (requiredFor: AniosNivelInicial) =>
            ANIOS_NIVEL_INICIAL[requiredFor] === anio,
        ),
      )
      .map((indicador) => indicador.id);

    return {
      ...commonConfig,
      indicadores: indicadoresToReturn,
      requiredFieldsToValidate: requiredFields,
    };
  };

  return {
    config: getConfigToUse(),
  };
};
