import React, { useEffect } from 'react';
import { InfoTable, Loading } from 'src/commons';
import { useIntervencionesEOEColumns } from '../hooks/useIntervencionesEOEColumns';
import confirmDialog from 'src/commons/services/confirmDialog';
import _ from 'lodash';
import { grey, primary } from 'src/theme/colors';
import { makeStyles, Typography } from '@material-ui/core';
import { useInformePPSContext } from '../InformePipContext';
import { usePPSFormContext } from '../PPSFormProvider';
import { Pps } from 'src/app/models';
import { FieldRenderProps } from 'react-final-form-hooks';
import { TABS_NAMES } from '../helpers';

export const useStyles = makeStyles({
  label: {
    marginBottom: 5,
    marginTop: 10,
    fontSize: 18,
    fontWeight: 500,
    color: grey.medium,
  },
  isIncompleteClose: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    paddingTop: '17px',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    lineHeight: '22px',
  },
  incompleteMessageClose: {
    borderRadius: 4,
    display: 'flex',
    backgroundColor: primary.errorMedium,
    flexFlow: 'column',
    color: grey.dark,
    padding: '10px 20px',
  },
});

export const IntervencionesEOE = () => {
  const { informePps } = useInformePPSContext();
  const { setCurrentTab, pps, isSaving, currentTab } = usePPSFormContext();
  const classes = useStyles();

  const adjuntoHandleChange = async (
    e: React.ChangeEvent<HTMLInputElement>,
    field: FieldRenderProps,
  ) => {
    if (e.target.files) {
      const uploadedFile = e.target.files[0];
      const typeValidation =
        uploadedFile.type === 'application/pdf' ||
        uploadedFile.type === 'image/png' ||
        uploadedFile.type === 'image/jpeg';

      if (uploadedFile?.size > 2000000) {
        await confirmDialog.show({
          title: 'Calificaciones Adjunto',
          content: 'El archivo adjunto no puede tener un tamaño mayor a 2MB',
          confirmText: 'Entendido',
        });
      } else if (!typeValidation) {
        await confirmDialog.show({
          title: 'Calificaciones Adjunto',
          content:
            'El archivo adjunto debe ser un documento del tipo PDF o una imagen de tipo PNG o JPG',
          confirmText: 'Entendido',
        });
      } else {
        field.input.onChange({
          files: e.target.files,
          name: e.target.value.substring(e.target.value.lastIndexOf('\\') + 1),
        });
      }
    }
  };

  const row = informePps?.row ?? [];

  const columns = useIntervencionesEOEColumns(adjuntoHandleChange, pps[0]);

  useEffect(() => {
    if (currentTab === TABS_NAMES.INTERVENCIONES) return;
    setCurrentTab(TABS_NAMES.INTERVENCIONES);
  }, [setCurrentTab, currentTab]);

  return (
    <>
      {isSaving ? (
        <Loading />
      ) : (
        <>
          {!(pps[0] as Pps)?.abierto && pps[0] !== undefined && (
            <div className={classes.isIncompleteClose}>
              <Typography className={classes.incompleteMessageClose}>
                El informe está cerrado.
              </Typography>
            </div>
          )}
          <Typography className={classes.label}>
            {`${informePps?.row?.alumno?.persona?.apellido}, ${informePps?.row?.alumno?.persona?.nombre} - ${informePps?.row?.alumno?.persona?.documento} - ${informePps?.row?.seccion?.nombreSeccion}`}
          </Typography>
          <InfoTable rows={[row]} columns={columns} />
        </>
      )}
    </>
  );
};
