import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { Templates } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'templates';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idTemplates',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idTemplates',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Templates
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Templates,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Templates.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Templates>(DAO_NAME, optionsToUse);

// hooks
export const useTemplatesFetchById = fetchById;
export const useTemplatesLibbyCall = libbyCall;
export const useTemplatesDAO = libbyDAO;
export const useTemplatesLibbyFetch = libbyFetch;

// components/hoc
export const TemplatesAutocomplete = entityAutocomplete;
export const TemplatesCall = renderPropCall;
export const TemplatesById = renderPropFetchById;
export const TemplatesSelect = simpleSelect;
export const TemplatesAccordionSelector = accordionSelector;
export const TemplatesSelectedChips = selectedChips;

// context
// ByIdContext
export const useTemplatesByIdContext = useByIdContext;
export const TemplatesByIdProvider = ByIdProvider;
export const TemplatesByIdContext = ByIdContext;
// GeneralContext
export const useTemplatesContext = useGeneralContext;
export const TemplatesProvider = GeneralProvider;
export const TemplatesContext = GeneralContext;
