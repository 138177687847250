import React, { useMemo, useState } from 'react';
import { useBackButton, useRouteScreenTitle } from 'src/commons/hooks';
import { Grid, Button } from '@material-ui/core';
import { useRolesContext } from 'src/context/RolesContext';
import { useLocalizacionLibbyFetch } from 'src/app/business';

export const LeerParaCrecer = () => {
  const {
    selectedRole: { localizacionId },
  } = useRolesContext();

  const filterLocalizacion = useMemo(
    () => [
      {
        path: 'idLocalizacion',
        value: localizacionId,
      },
    ],
    [localizacionId],
  );

  const { data: localizacion = [] } = useLocalizacionLibbyFetch({
    limit: 1,
    filter: filterLocalizacion,
  });

  if (localizacion.length !== 0) {
    window.open(
      `https://leerparacrecer.bue.edu.ar/${localizacion[0]?.cueAnexo}`,
      'leer',
    );
    window.location.assign('/');
  }

  return <></>;
};
