import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { ROL } from 'src/commons/const/rol';
import { AppTheme } from '../../../../../theme';
import { MainInput } from '../../../../../commons/components/MainInput';
import {
  RolAccordionSelector,
  RolSelectedChips,
  EspacioCurricularAccordionSelector,
  EspacioCurricularSelectedChips,
} from '../../../../../app/business';
import { Rol, EspacioCurricular } from '../../../../../app/models';

const useStyle = makeStyles<AppTheme>((theme) => ({
  container: {
    backgroundColor: theme.palette.common.neutral,
  },
  filterItem: {
    width: '100%',
  },
  selectedChips: {
    marginTop: theme.spacing(2),
  },
}));

export interface JoinedFilters {
  publico: Rol[];
  espacioCurricular: EspacioCurricular[];
  search: string;
}
export interface FilterProps {
  value: JoinedFilters;
  onChange: (newValue: JoinedFilters) => void;
  isEstudiante: boolean;
}

export const Filter = ({ value, onChange, isEstudiante }: FilterProps) => {
  const classes = useStyle();
  const [search, setSearch] = useState(value.search);
  const [publico, setPublico] = useState<Rol[]>(value.publico);
  const [espacioCurricular, setEspacioCurricular] = useState<
    EspacioCurricular[]
  >(value.espacioCurricular);

  const onSearchChange = useCallback(({ target: { value: _value } }) => {
    setSearch(_value);
  }, []);

  useEffect(() => {
    const debounce = setTimeout(() => {
      onChange({
        publico,
        espacioCurricular,
        search,
      });
    }, 600);
    return () => {
      clearTimeout(debounce);
    };
  }, [publico, onChange, search, espacioCurricular]);

  return (
    <Card className={classes.container}>
      <CardHeader
        title="Filtrar por"
        subheader={
          <Grid className={classes.selectedChips}>
            <RolSelectedChips items={publico} onChange={setPublico} />
          </Grid>
        }
      />
      <CardContent>
        <MainInput
          type="search"
          handleChange={onSearchChange}
          placeholder="Buscar"
          name="search"
          value={search}
          fullWidth
        />
        {!isEstudiante && (
          <Grid container direction="row" spacing={2} alignItems="stretch">
            <Grid item className={classes.filterItem}>
              <RolAccordionSelector
                value={publico}
                onChange={setPublico}
                title="Audiencia"
                filter={{
                  filter: [
                    { path: 'idRolUsuario', value: ROL.ESTUDIANTE },
                    { path: 'idRolUsuario', value: ROL.DOCENTE },
                  ],
                }}
              />
            </Grid>
          </Grid>
        )}
      </CardContent>
    </Card>
  );
};
