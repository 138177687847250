import moment from 'moment';
import { Presente_v3 } from 'src/app/models';
import { ESTADO_PRESENTE } from 'src/commons';
import { contraturnoDias } from '../../Contraturnos/Types';
import { PresenteGeneral } from '../../TomaPresentismo/types';
import { capitalizarPalabras } from './functions';

export interface ContraturnosPorDia {
  [fecha: string]: number;
}

export const getDataJSCC = (periodoFiltrado: PresenteGeneral[]) => {
  const contraturnosPorDia: ContraturnosPorDia = {};
  const idContraturnos: number[] = [];
  let idContraturnosCT1: number[] = [];
  let idContraturnosCT2: number[] = [];

  periodoFiltrado.forEach((item) => {
    const fecha = item.fecha;
    if (item.contraturnoDia !== null) {
      contraturnosPorDia[fecha] = (contraturnosPorDia[fecha] || 0) + 1;

      if (item.contraturnoDia?.idContraturnoDia) {
        idContraturnos.push(item?.contraturnoDia?.idContraturnoDia);
      }
    }
  });

  idContraturnos.sort(); // Ordenar los idContraturnos para encontrar ct1 y ct2

  const ct1 = idContraturnos[0]; // El valor más bajo será ct1
  const ct2 = idContraturnos[idContraturnos.length - 1]; // El valor más alto será ct2

  // Filtrar idContraturnosCT1 y idContraturnosCT2 basados en ct1 y ct2
  idContraturnosCT1 = idContraturnos.filter((id) => id === ct1);
  idContraturnosCT2 = idContraturnos.filter((id) => id === ct2);

  const cantidadMaximaContraturnos = Math.max(
    ...Object.values(contraturnosPorDia),
    0,
  );

  // Aquí tienes ct1 y ct2
  const idContraturnoCt1 = ct1;
  const idContraturnoCt2 = ct2;

  const cantidadPresetes = periodoFiltrado
    .filter(
      (item) =>
        item.turno !== null &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.PRESENTE,
    )
    .length.toString();
  const cantidadAusentes = periodoFiltrado
    .filter(
      (item) =>
        item.turno !== null &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE,
    )
    .length.toString();
  const turnoConAusenteJustificacion = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE &&
        item.justificacion !== null &&
        item.contraturnoDia === null,
    )
    .length.toString();

  const ct1ConAusenteJustificacion = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE &&
        item.justificacion !== null &&
        item.contraturnoDia?.idContraturnoDia === idContraturnoCt1,
    )
    .length.toString();

  const ct2ConAusenteJustificacion = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE &&
        item.justificacion !== null &&
        item.contraturnoDia?.idContraturnoDia === idContraturnoCt2,
    )
    .length.toString();

  const cantidadIngresosTardios = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia === null &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.INGRESO_TARDIO,
    )
    .length.toString();

  const cantidadIngresosTardiosJustificados = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia === null &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.INGRESO_TARDIO &&
        item.justificacion !== null,
    )
    .length.toString();

  const cantidadRetirosAnticipados = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia?.idContraturnoDia === null &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.RETIRO_ANTICIPADO,
    )
    .length.toString();

  const cantidadRetirosAnticipadosJustificados = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia === null &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.RETIRO_ANTICIPADO &&
        item.justificacion !== null,
    )
    .length.toString();

  const cantidadAusentesConPresenciaEnClases = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia?.idContraturnoDia === null &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE,
    )
    .length.toString();

  const cantidadAusentesConPresenciaEnClasesJustificados = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia === null &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE &&
        item.justificacion !== null,
    )
    .length.toString();

  const sinDatos = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia === null &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.NO_CORRESPONDE,
    )
    .length.toString();

  const SinJustificacion = periodoFiltrado
    .filter(
      (item) =>
        (item.contraturnoDia?.idContraturnoDia === null &&
          item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE) ||
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.INGRESO_TARDIO ||
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.RETIRO_ANTICIPADO ||
        item.justificacion !== null,
    )
    .length.toString();

  const ct1SinJustificacion = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE ||
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.INGRESO_TARDIO ||
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.RETIRO_ANTICIPADO ||
        (item.justificacion !== null &&
          item.contraturnoDia?.idContraturnoDia === idContraturnoCt1),
    )
    .length.toString();

  const ct2SinJustificacion = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE ||
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.INGRESO_TARDIO ||
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.RETIRO_ANTICIPADO ||
        (item.justificacion !== null &&
          item.contraturnoDia?.idContraturnoDia === idContraturnoCt2),
    )
    .length.toString();

  const ct1Presente = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia?.idContraturnoDia === idContraturnoCt1 &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.PRESENTE,
    )
    .length.toString();

  const ct2Presente = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia?.idContraturnoDia === idContraturnoCt2 &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.PRESENTE,
    )
    .length.toString();

  const ct1Ausentes = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia?.idContraturnoDia === idContraturnoCt1 &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE,
    )
    .length.toString();

  const ct2Ausentes = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia?.idContraturnoDia === idContraturnoCt2 &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE,
    )
    .length.toString();

  const JornadaExtendidaAusentesJustificados = periodoFiltrado
    .filter(
      (item) =>
        item.isJornadaExtendida === true &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE &&
        item.justificacion !== null,
    )
    .length.toString();

  const ct1CantidadIngresosTardios = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia?.idContraturnoDia === idContraturnoCt1 &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.INGRESO_TARDIO,
    )
    .length.toString();

  const ct2CantidadIngresosTardios = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia?.idContraturnoDia === idContraturnoCt2 &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.INGRESO_TARDIO,
    )
    .length.toString();

  const ct1ingnresoTardiosJustificados = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia?.idContraturnoDia === idContraturnoCt1 &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.INGRESO_TARDIO &&
        item.justificacion !== null,
    )
    .length.toString();

  const ct2ingnresoTardiosJustificados = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia?.idContraturnoDia === idContraturnoCt2 &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.INGRESO_TARDIO &&
        item.justificacion !== null,
    )
    .length.toString();

  const ct1RetirosAntisipados = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia?.idContraturnoDia === idContraturnoCt1 &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.RETIRO_ANTICIPADO,
    )
    .length.toString();

  const ct2RetirosAntisipados = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia?.idContraturnoDia === idContraturnoCt2 &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.RETIRO_ANTICIPADO,
    )
    .length.toString();

  const ct1AnticipadosJustificados = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia?.idContraturnoDia === idContraturnoCt1 &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.RETIRO_ANTICIPADO &&
        item.justificacion !== null,
    )
    .length.toString();

  const ct2AnticipadosJustificados = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia?.idContraturnoDia === idContraturnoCt2 &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.RETIRO_ANTICIPADO &&
        item.justificacion !== null,
    )
    .length.toString();

  const ct1AusentesConPresenciaEnClases = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia?.idContraturnoDia === idContraturnoCt1 &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE,
    )
    .length.toString();

  const ct2AusentesConPresenciaEnClases = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia?.idContraturnoDia === idContraturnoCt2 &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE,
    )
    .length.toString();

  const ct1AusentesConPresenciaEnClasesJustificado = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia?.idContraturnoDia === idContraturnoCt1 &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE &&
        item.justificacion !== null,
    )
    .length.toString();

  const ct2AusentesConPresenciaEnClasesJustificado = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia?.idContraturnoDia === idContraturnoCt2 &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE &&
        item.justificacion !== null,
    )
    .length.toString();

  const ct1dSinDatos = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia?.idContraturnoDia === idContraturnoCt1 &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.NO_CORRESPONDE,
    )
    .length.toString();

  const ct2dSinDatos = periodoFiltrado
    .filter(
      (item) =>
        item.contraturnoDia?.idContraturnoDia === idContraturnoCt2 &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.NO_CORRESPONDE,
    )
    .length.toString();

  //cantidadMaximaContraturnos
  const reporte = [
    {
      Nombre: 'Presente',
      CantidadPresente: cantidadPresetes,
      CT1Presente: ct1Presente,
      ...(cantidadMaximaContraturnos > 1 && { CT2Presente: ct2Presente }),
      tituloGuion: '-',
      turnoGuion: '-',
      CT1PresenteGuion: '-',
      ...(cantidadMaximaContraturnos > 1 && { CT2Presenteguion: '-' }),
    },
    {
      Nombre: 'Ausente',
      CantidadAsuntes: cantidadAusentes,
      CT1Ausente: ct1Ausentes,
      ...(cantidadMaximaContraturnos > 1 && { CT2Ausente: ct2Ausentes }),
      CTAusentesConJustificacion: 'Ausentes Justificados',
      TurnoAusenteConJustificacion: turnoConAusenteJustificacion,
      CT1AusentesConJustificacion: ct1ConAusenteJustificacion,
      ...(cantidadMaximaContraturnos > 1 && {
        CT2AusentesConJustificacion: ct2ConAusenteJustificacion,
      }),
    },
    {
      Nombre: 'Ingresos Tardíos',
      CantidadIngresosTardios: cantidadIngresosTardios,
      ct1CantidadIngresosTardios: ct1CantidadIngresosTardios,
      ...(cantidadMaximaContraturnos > 1 && {
        ct2CantidadIngresosTardios: ct2CantidadIngresosTardios,
      }),
      NombreIngresosTardiosJustificados: 'Ingresos Tardios Justificados',
      CantidadIngresosTardiosJustificados: cantidadIngresosTardiosJustificados,
      ct1ingnresoTardiosJustificados: ct1ingnresoTardiosJustificados,
      ...(cantidadMaximaContraturnos > 1 && {
        ct2ingnresoTardiosJustificados: ct2ingnresoTardiosJustificados,
      }),
    },
    {
      Nombre: 'Retiros Anticipados',
      CantidadRetirosAnticipados: cantidadRetirosAnticipados,
      ct1RetirosAntisipados: ct1RetirosAntisipados,
      ...(cantidadMaximaContraturnos > 1 && {
        ct2RetirosAnticipados: ct2RetirosAntisipados,
      }),
      NombreRetirosAnticipadosJustificados: 'Retiros Anticipados Justificados',
      CantidadRetirosAnticipadosJustificados:
        cantidadRetirosAnticipadosJustificados,
      ct1AnticipadosJustificados: ct1AnticipadosJustificados,
      ...(cantidadMaximaContraturnos > 1 && {
        ct2AnticipadosJustificados: ct2AnticipadosJustificados,
      }),
    },
    {
      Nombre: 'Ausentes con Presencia en Clases',
      CantidadAusentesConPresenciaEnClases:
        cantidadAusentesConPresenciaEnClases,
      ct1AuusentesConPresenciaEnClases: ct1AusentesConPresenciaEnClases,
      ...(cantidadMaximaContraturnos > 1 && {
        ct2AuusentesConPresenciaEnClases: ct2AusentesConPresenciaEnClases,
      }),

      NombreAusentesConPresenciaEnClasesJustificados:
        'Ausentes con Presencia en Clases',
      cantidadAusentesConPresenciaEnClasesJustificados:
        cantidadAusentesConPresenciaEnClasesJustificados,
      ct1AusentesConPresenciaEnClasesJustificado:
        ct1AusentesConPresenciaEnClasesJustificado,
      ...(cantidadMaximaContraturnos > 1 && {
        ct2AusentesConPresenciaEnClasesJustificado:
          ct2AusentesConPresenciaEnClasesJustificado,
      }),
    },
    {
      Nombre: 'No Corresponde',
      CantidadSinDatos: sinDatos,
      ct1SinDatos: ct1dSinDatos,
      ...(cantidadMaximaContraturnos > 1 && { ct2dSinDatos: ct2dSinDatos }),
      SinJustificacion: 'Sin Justificación',
      CantidadSinJustificacion: SinJustificacion,
      ct1SinJustificacion: ct1SinJustificacion,
      ...(cantidadMaximaContraturnos > 1 && {
        SinJustificacionJE: ct2SinJustificacion,
      }),
    },
  ];

  return reporte;
};

export const getTableSecDataJSCC = (periodoFiltrado: PresenteGeneral[]) => {
  const contraturnosPorDia: ContraturnosPorDia = {};

  periodoFiltrado.forEach((item) => {
    const fecha = item.fecha;
    if (item.contraturnoDia !== null) {
      contraturnosPorDia[fecha] = (contraturnosPorDia[fecha] || 0) + 1;
    }
  });

  const uniqueDates = [...new Set(periodoFiltrado.map((item) => item.fecha))];

  const cantidadMaximaContraturnos = Math.max(
    ...Object.values(contraturnosPorDia),
    0,
  );

  const data = uniqueDates.map((date) => {
    const itemsForDate = periodoFiltrado.filter((item) => item.fecha === date);
    const contraturnoItems = itemsForDate.filter(
      (item) => item.contraturnoDia !== null,
    );

    let CT1Description = '';
    let CT1IdContraturnoDia: any = null;
    let CT2Description = '';
    let CT2IdContraturnoDia: any = null;

    if (contraturnoItems.length > 0) {
      const sortedContraturnos = contraturnoItems.sort((a, b) => {
        if (a.contraturnoDia && b.contraturnoDia) {
          return (
            b.contraturnoDia.idContraturnoDia -
            a.contraturnoDia.idContraturnoDia
          );
        }
        return 0;
      });

      CT1Description = sortedContraturnos[0].estadoPresente.descripcion ?? '-';
      CT1IdContraturnoDia =
        sortedContraturnos[0].contraturnoDia?.idContraturnoDia || null;

      if (sortedContraturnos.length > 1) {
        CT2Description =
          sortedContraturnos[1].estadoPresente.descripcion ?? '-';
        CT2IdContraturnoDia =
          sortedContraturnos[1].contraturnoDia?.idContraturnoDia || null;
      }
    }

    const justificacionAusenciaTS = itemsForDate.some(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE &&
        item.justificacion !== null &&
        item.contraturnoDia === null,
    )
      ? 'SI'
      : 'NO';

    const justificacionAusenciaCT1 =
      CT1Description !== '' &&
      itemsForDate.some(
        (item) =>
          item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE &&
          item.justificacion !== null &&
          item.contraturnoDia === CT1IdContraturnoDia,
      )
        ? 'SI'
        : 'NO';

    const justificacionAusenciaCT2 =
      CT2Description !== '' &&
      itemsForDate.some(
        (item) =>
          item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE &&
          item.justificacion !== null &&
          item.contraturnoDia === CT2IdContraturnoDia,
      )
        ? 'SI'
        : 'NO';

    // ...

    const diaCapitalizado = capitalizarPalabras(moment(date).format('dddd'));

    const rowData = {
      dia: diaCapitalizado,
      fecha: moment(date).format('DD-MM-YYYY'),
      presentismo:
        itemsForDate.find((item) => item.contraturnoDia === null)
          ?.estadoPresente.descripcion || '',
      CT1: CT1Description,
      justificacionAusenciaCT1: justificacionAusenciaCT1,
      ...(cantidadMaximaContraturnos > 1 && CT2Description !== ''
        ? { CT2: CT2Description }
        : {}),
      justificacionAusenciaTS: justificacionAusenciaTS,
      ...(cantidadMaximaContraturnos > 1 && CT2IdContraturnoDia !== null
        ? { JustificacionAusenciaCT2: justificacionAusenciaCT2 }
        : {}),
    };

    // ...

    return rowData;
  });
  const columns = [
    'Día',
    'Fecha',
    'Presentismo TS',
    'CT1',
    ...(cantidadMaximaContraturnos > 1 ? ['CT2'] : []),
    'Justificación Ausencia TS',
    'Justificación Ausencia CT1',
    cantidadMaximaContraturnos > 1 ? 'Justificación Ausencia CT2' : null,
  ];

  return { columns, data };
};
