import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { RowCalification } from 'src/app/models';

export const useValidatorInformes = <T extends RowCalification>({
  fieldsToValidate,
  editedData,
  originalData,
}: {
  fieldsToValidate: string[];
  editedData: T[];
  originalData?: T[];
}) => {
  const [incompletedRows, setIncompletedRows] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [currentRows, setCurrentRows] = useState<typeof editedData>();
  const [isNotReadyToSubmit, setIsNotReadyToSubmit] = useState(false);

  useEffect(() => {
    const updatedRows = editedData.filter((row) => {
      let isUpdated = false;
      const currentCalification = originalData?.find(
        (d) => d.idCalificacion === row.idCalificacion,
      );

      if (currentCalification?.nota) {
        isUpdated =
          !isEqual(currentCalification?.nota?.data, row.nota?.data) ||
          !isEqual(
            currentCalification.calificacionAnual,
            row.calificacionAnual,
          );
      } else if (
        typeof row.nota?.data === 'object' &&
        Object.keys(row.nota?.data).length
      ) {
        isUpdated = true;
      }
      return isUpdated;
    });
    setIsNotReadyToSubmit(updatedRows.length === 0);
  }, [editedData, originalData]);

  const validator = useCallback(() => {
    let disabled = false;

    editedData.forEach((calificacion) => {
      const fieldsToValidateByRow = [...fieldsToValidate];
      const name = `${calificacion?.apellido}, ${calificacion?.nombre}`;
      const result = fieldsToValidateByRow.reduce(
        (acc: number, val: string) => {
          if (
            calificacion.nota?.data &&
            (calificacion.nota.data[val] === undefined ||
              calificacion.nota.data[val] === null ||
              calificacion.nota.data[val] === '')
          )
            ++acc;
          return acc;
        },
        0,
      );
      if (result !== 0 && Object.entries(calificacion.nota?.data).length > 0) {
        const allKeysAreEmpty = Object.keys(calificacion?.nota?.data).every(
          (key) => {
            return calificacion?.nota?.data[key] === '';
          },
        );
        if (allKeysAreEmpty) {
          disabled = false;
        } else {
          disabled = true;
        }
        if (incompletedRows.indexOf(name) === -1) {
          setIncompletedRows([...incompletedRows, name]);
        }
      } else {
        if (incompletedRows.indexOf(name) !== -1) {
          const incompletedRowsCopy = [...incompletedRows];
          incompletedRowsCopy.splice(incompletedRowsCopy.indexOf(name), 1);
          setIncompletedRows(incompletedRowsCopy);
        }
      }
    });
    setIsDisabled(disabled);
  }, [editedData, fieldsToValidate, incompletedRows]);

  useEffect(() => {
    if (currentRows !== editedData) {
      setCurrentRows(editedData);
      validator();
    }
  }, [currentRows, editedData, validator]);

  return {
    incompletedRows,
    setIncompletedRows,
    isDisabled,
    isNotReadyToSubmit,
  };
};
