import {
  CalificacionesPrimariaResponse,
  InformeValorativo,
} from 'src/app/models';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from 'src/lib/libby';
import { InformeValorativoDAO } from 'src/platform/libby/dao/Calificaciones/InformeValorativoDAO';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'informevalorativo';

const defaultOptions: PartialBusinessEntityOptions<any> = {};

const optionsToUse = !businessOptions.InformeValorativo
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.InformeValorativo,
      hoc: { ...defaultOptions.hoc, ...businessOptions.InformeValorativo.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<CalificacionesPrimariaResponse, InformeValorativoDAO>(
  DAO_NAME,
  optionsToUse,
);

// hooks
export const useInformeValorativoFetchById = fetchById;
export const useInformeValorativoLibbyCall = libbyCall;
export const useInformeValorativoDAO = libbyDAO;
export const useInformeValorativoLibbyFetch = libbyFetch;

// components/hoc
export const InformeValorativoAutocomplete = entityAutocomplete;
export const InformeValorativoCall = renderPropCall;
export const InformeValorativoById = renderPropFetchById;
export const InformeValorativoSelect = simpleSelect;
export const InformeValorativoAccordionSelector = accordionSelector;
export const InformeValorativoSelectedChips = selectedChips;

// context
// ByIdContext
export const useInformeValorativoByIdContext = useByIdContext;
export const InformeValorativoByIdProvider = ByIdProvider;
export const InformeValorativoByIdContext = ByIdContext;
// GeneralContext
export const useInformeValorativoContext = useGeneralContext;
export const InformeValorativoProvider = GeneralProvider;
export const InformeValorativoContext = GeneralContext;
