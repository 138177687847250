import { Template } from 'src/lib/templates';

export const nuevaPublicacionTemplate: Template = {
  name: 'Nueva Publicacion',
  template: [
    {
      name: 'descripcion',
      type: {
        name: 'string',
      },
    },
  ],
};
