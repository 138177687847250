import { Grid, Typography } from '@material-ui/core';
import { cloneDeep } from 'lodash';
import React, { useCallback } from 'react';
import {
  ButtonTypesProps,
  Footer,
  InfoTable,
  Loading,
  MainInput,
} from 'src/commons';
import { useTabBarSetValue } from 'src/commons/components/TabBar';
import customFormDialog from 'src/commons/services/customFormDialog';
import { useArbolCheckboxColumns } from '../hooks/useArbolCheckboxColumns';
import {
  AccionesFormValues,
  AccionesOrientacionesFormValues,
  TableContentProps,
} from '../types';
import { ArbolCheckModal } from './ArbolCheckModal';

export const TableContent = ({
  titleModal,
  type,
  formValues,
  contenidos,
  nombreColumna,
  loading = false,
  onlyView = false,
  setFormValues,
  handleSubmit,
}: TableContentProps) => {
  const setTabBarValue = useTabBarSetValue();

  const handleUpdated = useCallback(
    (state) => {
      let toSave: AccionesFormValues | null = null;
      setFormValues((prev) => {
        const newAos = cloneDeep(prev.accionOrientaciones).map((ao) => {
          if (ao.idAccionOrientacion === state.idAccionOrientacion) {
            ao = state;
          }
          return ao;
        });
        const toUpdated = {
          ...prev,
          accionOrientaciones: newAos,
        };
        toSave = toUpdated;
        return toUpdated;
      });
      handleSubmit(toSave!);
      customFormDialog.handleCancel();
    },
    [handleSubmit, setFormValues],
  );

  const handleOpenModal = useCallback(
    (row: AccionesOrientacionesFormValues) => () => {
      customFormDialog.show({
        title: titleModal,
        renderComponent: (
          <ArbolCheckModal
            contenidos={contenidos}
            type={type}
            data={row}
            onlyView={onlyView}
            handleSubmit={handleUpdated}
          />
        ),
        sizeWidth: 'md',
      });
    },
    [contenidos, handleUpdated, onlyView, titleModal, type],
  );

  const columns = useArbolCheckboxColumns({
    type,
    formValues,
    nombreColumna,
    handleEdit: handleOpenModal,
    onliView: false,
  });

  const buttonConfig: ButtonTypesProps[] = [
    {
      title: 'Salir',
      handleOnClick: () => {
        setTabBarValue(0);
      },
      size: 'medium',
      disabled: false,
    },
  ];

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Grid container style={{ rowGap: 15 }}>
          <Grid
            item
            container
            direction="row"
            justifyContent="flex-start"
            style={{ gap: 10, marginTop: 25 }}
          >
            <Grid item>
              <Grid
                item
                container
                justifyContent="flex-start"
                alignItems="center"
                style={{ gap: 10 }}
              >
                <Typography variant="h5">Sector:</Typography>
                <MainInput
                  label=""
                  type="search"
                  customStyleLabel={{ color: '#737373', paddingBottom: 15 }}
                  handleChange={(e: any) => console.log('')}
                  placeholder="Buscar"
                  name="search"
                  value={formValues.sectorName}
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>

            <Grid item>
              <Grid
                item
                container
                justifyContent="flex-start"
                alignItems="center"
                style={{ gap: 10 }}
              >
                <Typography variant="h5">Organizacion:</Typography>
                <MainInput
                  label=""
                  type="search"
                  customStyleLabel={{ color: '#737373', paddingBottom: 15 }}
                  handleChange={(e: any) => console.log('')}
                  placeholder="Buscar"
                  name="search"
                  value={formValues.organizacionName}
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            container
            justifyContent="space-between"
            alignItems="flex-end"
          >
            <InfoTable
              rows={formValues.accionOrientaciones}
              columns={columns}
            />
          </Grid>
          <Footer buttonConfig={buttonConfig} />
        </Grid>
      )}
    </>
  );
};
