import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { AtencionHospitalaria } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'atencion_hospitalaria';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idAtencionHospitalaria',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idAtencionHospitalaria',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.AtencionHospitalaria
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.AtencionHospitalaria,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.AtencionHospitalaria.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<AtencionHospitalaria>(DAO_NAME, optionsToUse);

// hooks
export const useAtencionHospitalariaFetchById = fetchById;
export const useAtencionHospitalariaLibbyCall = libbyCall;
export const useAtencionHospitalariaDAO = libbyDAO;
export const useAtencionHospitalariaLibbyFetch = libbyFetch;

// components/hoc
export const AtencionHospitalariaAutocomplete = entityAutocomplete;
export const AtencionHospitalariaCall = renderPropCall;
export const AtencionHospitalariaById = renderPropFetchById;
export const AtencionHospitalariaSelect = simpleSelect;
export const AtencionHospitalariaAccordionSelector = accordionSelector;
export const AtencionHospitalariaSelectedChips = selectedChips;

// context
// ByIdContext
export const useAtencionHospitalariaByIdContext = useByIdContext;
export const AtencionHospitalariaByIdProvider = ByIdProvider;
export const AtencionHospitalariaByIdContext = ByIdContext;
// GeneralContext
export const useAtencionHospitalariaContext = useGeneralContext;
export const AtencionHospitalariaProvider = GeneralProvider;
export const AtencionHospitalariaContext = GeneralContext;
