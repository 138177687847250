import React, { useMemo, useState, useEffect } from 'react';
import { Grid, Container, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { primary } from 'src/theme/colors';
import {
  AnioSelect,
  useAutoRegistracionLibbyCall,
  useEspacioCurricularSeccionLibbyFetch,
  SeccionSelect,
  useSeccionLibbyFetch,
  useEspacioCurricularLibbyFetch,
  useProyeccionEstadoLibbyFetch,
} from 'src/app/business';
import { EspacioCurricular } from 'src/app/models';
import { ROL } from 'src/commons/const/rol';
import { useRolesContext } from 'src/context/RolesContext';
import InputMateria from './InputMateria';
import TablaInscripciones from './TablaInscripciones';
import { useSignUpForm } from '../../../hooks/useSignUpForm';
import moment from 'moment';
import _ from 'lodash';
import { NIVEL } from 'src/commons/const';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';

const useStyles = makeStyles({
  dropDown: {
    width: '100%',
    height: 30,
  },
  dropDownContainer: {
    padding: '10px',
  },
});

interface Permission {
  idSeccion: string;
  idNivel: number;
  idEspacioCurricular: string;
}

const PLAN_ESTUDIOS_JE_BY_NIVEL: { [key: number]: number } = {
  [NIVEL.PRIMARIO]: -1,
  [NIVEL.SECUNDARIO]: -1,
};

const ID_MATERIA_JE_BY_NIVEL: { [key: number]: number } = {
  [NIVEL.PRIMARIO]: -1,
  [NIVEL.SECUNDARIO]: -2,
};

export const DocenteDropDowns = ({ isJe = false }) => {
  const { form, values } = useSignUpForm();
  const { userInfo } = useRolesContext();

  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();
  /** Array que recibe las materias y les agrega una propiedad "needToDisable"
  para poder deshabilitar un campo particular en el Select  */
  const [enabledMaterias, setEnabledMaterias] = useState<any>([]);
  //  las materias que necesita renderizar el componente TablaInscripciones */
  const [asignaturas, setAsignaturas] = useState<any>([]);
  //  los roles que serán enviados al context */
  const [roles, setRoles] = useState<Permission[]>([]);
  //  setea la materia a seleccionar  */
  const [state, setState] = useState({
    idAnio: '',
    idEspacioCurricular: '',
    idSeccion: '',
  });
  const [allEspacios, setAllEspacios] = useState<any>([]);
  const { data: autoregister = [], working: registerWorking } =
    useAutoRegistracionLibbyCall({
      methodName: 'getPendingAndApproveRoles',
      params: [
        userInfo.id,
        isJe ? ROL.DOCENTE_JE : ROL.DOCENTE,
        values.idLocalizacion,
      ],
    });

  const filter = useMemo(
    () => ({
      filter: [{ path: 'id_seccion', value: state.idSeccion }],
    }),
    [state.idSeccion],
  );

  const filterAnio = useMemo(
    () => ({
      anio: [
        { path: 'nivel', value: values.idNivel === '' ? -10 : values.idNivel },
      ],
      ...(isJe && {
        JE: [
          {
            path: 'idAnio',
            value: 21,
          },
        ],
      }),
    }),
    [values.idNivel, isJe],
  );

  const filterLocalizacion = useMemo(
    () => ({
      id: [{ path: 'localizacion', value: values?.idLocalizacion }],
      nivel: [{ path: 'nivel', value: values?.idNivel }],
    }),
    [values?.idLocalizacion, values?.idNivel],
  );

  const { data: proyeccionEstado = [], working: workingProyeccionEstado } =
    useProyeccionEstadoLibbyFetch({
      filter: filterLocalizacion,
      orderBy: 'cicloLectivo',
      direction: 'desc',
    });

  const filterSeccion = useMemo(
    () => ({
      filter: [{ path: 'localizacion', value: values?.idLocalizacion }],
      anio: [{ path: 'anio', value: state.idAnio === '' ? -10 : state.idAnio }],
      cicloLectivo: [
        {
          path: 'cicloLectivo.anio',
          value: proyeccionEstado[0]?.cicloLectivo?.anio,
        },
      ],
      ...(isJe && {
        JE: [
          {
            path: 'jornada',
            value: 'Extendida',
          },
        ],
      }),
    }),
    [state.idAnio, values.idLocalizacion, proyeccionEstado, isJe],
  );

  const { data: secciones = [] } = useSeccionLibbyFetch({
    filter: filterSeccion,
    enabled: proyeccionEstado.length > 0,
  });

  const jornadaExtendidaFilter = useMemo(
    () => ({
      localizacion: [{ path: 'localizacion', value: values?.idLocalizacion }],
      jornada: [
        { path: 'descripcion', value: 'Jornada Extendida', method: 'includes' },
      ],
      planEstudio: [
        {
          path: 'planEstudio.idPlanEstudio',
          value: PLAN_ESTUDIOS_JE_BY_NIVEL[values.idNivel],
          method: 'equals',
        },
      ],
      materia: [
        {
          path: 'materia.idMateria',
          value: ID_MATERIA_JE_BY_NIVEL[values.idNivel],
          method: 'equals',
        },
      ],
    }),
    [values?.idLocalizacion, values.idNivel],
  );

  const { data: espaciosCurricularSeccion = [], working: workingECS } =
    useEspacioCurricularSeccionLibbyFetch({
      filter,
      aspect: 'shorted',
      enabled: !!state.idSeccion,
      limit: 100,
    });

  const anioValidationJE =
    Number(state?.idAnio) === 5 ||
    Number(state?.idAnio) === 6 ||
    Number(state?.idAnio) === 7 ||
    Number(state?.idAnio) === 21;

  const isSeccionJE =
    secciones
      .find((seccion) => seccion.idSeccion === state.idSeccion)
      ?.jornada.toLowerCase() === 'extendida';

  const {
    data: espaciosCurricularesJornadaExtendida = [],
    working: workingECJE,
  } = useEspacioCurricularLibbyFetch({
    limit: 500,
    filter: jornadaExtendidaFilter,
    enabled: anioValidationJE && isSeccionJE,
  });

  useEffect(() => {
    let espaciosJE: any[] = [];
    if (anioValidationJE && isSeccionJE) {
      espaciosJE = espaciosCurricularesJornadaExtendida.map(
        (element: EspacioCurricular) => ({ espacioCurricular: { ...element } }),
      );
    }
    setAllEspacios([...espaciosJE, ...espaciosCurricularSeccion]);
  }, [
    anioValidationJE,
    espaciosCurricularSeccion,
    espaciosCurricularesJornadaExtendida,
    isSeccionJE,
  ]);

  /** al seleccionarse la sección y el año, recorre las materias y los compara con los espacios
   * curriulares a los que el usuario se anotó. Si ya existen, les agrega una propiedad
   * needToDisable que nos va a servir en los select para deshabilitar y tachar la opción
   */

  useEffect(() => {
    if (state.idSeccion && state.idAnio) {
      const contenido = allEspacios.map((espacio: any) => {
        const mate: EspacioCurricular = { ...espacio.espacioCurricular };
        for (let j = 0; j < autoregister.length; j++) {
          if (
            espacio.espacioCurricular.idEspacioCurricular ===
              autoregister[j].espacioCurricular.idEspacioCurricular &&
            autoregister[j].seccion.idSeccion === state.idSeccion
          ) {
            mate.needToDisable = true;
          }
        }
        return mate;
      });

      setEnabledMaterias(contenido);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    state.idAnio,
    state.idSeccion,
    state.idEspacioCurricular,
    autoregister,
    allEspacios,
    registerWorking,
  ]);

  /** busca en el array de espacios curricualres por id de manera de obtener el nombre
 de la materia y la sección para poder asignárselo al array asignaturas.
 también modifica el array de roles, que es el que usa el contexto
 para poder habilitar el botón de continuar.  */

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const laMateria = allEspacios.filter((espacio) => {
      return (
        espacio.espacioCurricular.idEspacioCurricular ===
        state.idEspacioCurricular
      );
    });
    const laSeccion = secciones?.filter((secc) => {
      return secc.idSeccion === state.idSeccion;
    });
    const espa = laMateria ? laMateria[0] : null;
    const elEspacio: any = { ...espa };
    elEspacio.section = laSeccion ? laSeccion[0] : null;
    const rolExists = roles.find((rol) => {
      return (
        rol.idSeccion === state.idSeccion &&
        state.idEspacioCurricular === rol.idEspacioCurricular
      );
    });

    const currentRoles = !rolExists
      ? [
          ...roles,
          {
            idSeccion: state.idSeccion,
            idNivel: elEspacio.espacioCurricular.materia.anio.nivel.idNivel,
            idEspacioCurricular: state.idEspacioCurricular,
          },
        ]
      : [...roles];
    setRoles(currentRoles);
    const areas = !rolExists ? [...asignaturas, elEspacio] : [...asignaturas];
    setAsignaturas(areas);
  };

  const handleDelete = (index: any) => {
    const arr = [...asignaturas];
    const arRoles = [...roles];
    arr.splice(index, 1);
    setAsignaturas(arr);
    arRoles.splice(index, 1);
    setRoles(arRoles);
  };

  /** luego del submit, se actualizan los roles y mandamos la validación al contexto para
   * que habilite el botón
   */

  useEffect(() => {
    form.change('roles', roles?.length ? [...roles] : null);
  }, [roles, form, asignaturas]);

  return (
    <Container>
      <Typography
        style={{
          fontFamily: 'Open Sans',
          color: 'grey.medium',
          fontSize: 18,
          marginBottom: 30,
        }}
      >
        Disciplinas Seleccionadas
      </Typography>
      <Grid>
        <TablaInscripciones
          handleDelete={handleDelete}
          asignaturas={asignaturas}
        />
      </Grid>
      <Grid>
        <form onSubmit={handleSubmit}>
          <Grid container style={{ padding: 40 }}>
            <Grid item xs={3}>
              <AnioSelect
                orderBy="descripcionAnio"
                name="idAnio"
                filter={filterAnio}
                title="Año"
                placeholder="Seleccioná un año"
                value={state.idAnio}
                handleChange={({ target }) => {
                  setState({
                    idAnio: target.value,
                    idSeccion: '',
                    idEspacioCurricular: '',
                  });
                }}
              />
            </Grid>
            <Grid item xs={3}>
              {state.idAnio ? (
                <SeccionSelect
                  labelKey="nombreSeccion"
                  orderBy="nombreSeccion"
                  name="idSeccion"
                  filter={filterSeccion}
                  title="Sección"
                  placeholder="Seleccioná una sección"
                  value={state.idSeccion}
                  aspect="calificaciones_inscriptos"
                  handleChange={({ target }) => {
                    setState({
                      ...state,
                      idSeccion: target.value,
                      idEspacioCurricular: '',
                    });
                  }}
                  enableFetch={
                    proyeccionEstado[0]?.cicloLectivo?.anio !== undefined
                  }
                  disabled={!state.idAnio || workingProyeccionEstado}
                />
              ) : null}
            </Grid>
            <Grid item xs={3}>
              {state.idAnio && state.idSeccion && !workingECS && !workingECJE && (
                <InputMateria
                  content={enabledMaterias}
                  handleChange={({ target }: any) => {
                    setState({ ...state, idEspacioCurricular: target.value });
                  }}
                  value={state.idEspacioCurricular}
                  state={state}
                />
              )}
            </Grid>
          </Grid>
          <Button
            type="submit"
            style={{
              textTransform: 'capitalize',
              backgroundColor: primary.azul,
              color: 'white',
            }}
            disabled={!state.idEspacioCurricular}
          >
            Agregar espacio curricular
          </Button>
        </form>
      </Grid>
    </Container>
  );
};
