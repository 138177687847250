import { SedeDAO } from 'src/platform/libby/dao/Presentismov2';
import { makeBusinessEntity } from '../../../lib/libby/generator';
import { Sede } from '../../models/presentismo_v2';

const DAO_NAME = 'sede';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { renderPropCall, renderPropFetchById, simpleSelect },
} = makeBusinessEntity<Sede, SedeDAO>(DAO_NAME, {});

// hooks
export const useSedesPresentismoFetchById = fetchById;
export const useSedesPresentismoLibbyCall = libbyCall;
export const useSedesPresentismoDAO = libbyDAO;
export const useSedesPresentismoLibbyFetch = libbyFetch;

// components/hoc
export const SedesPresentismoCall = renderPropCall;
export const SedesPresentismoById = renderPropFetchById;
export const SedesPresentismoSelect = simpleSelect;
