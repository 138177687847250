import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Grid, Checkbox } from '@material-ui/core';
import { useNivelLibbyFetch } from 'src/app/business';
import { InfoTable, AnyObject } from 'src/commons';
import { Nivel } from 'src/app/models';
import { useSignUpForm } from '../../hooks/useSignUpForm';

const column = [
  {
    id: 'checkbox',
    label: '',
    width: '1%',
    noSort: true,
    hideSortIcon: true,
  },
  {
    id: 'descripcionNivel',
    label: 'Nivel',
    width: '10%',
    orderById: 'descripcionNivel',
    noSort: true,
    hideSortIcon: true,
  },
];

export const Directivo = () => {
  const { form, values } = useSignUpForm();
  const [roles, setRoles] = useState<AnyObject[]>([]);

  const filter = useMemo(
    () => ({
      filter: [{ path: 'idNivel', value: values.idNivel }],
    }),
    [values.idNivel],
  );

  const {
    data: nivel,
    working: sectionWorking,
    fetchMore,
  } = useNivelLibbyFetch({
    filter,
  });

  const onChange = useCallback(
    (idNivel) => {
      setRoles((oldRoles) => {
        const position = oldRoles.findIndex(
          (old: any) => old.idNivel === idNivel,
        );
        if (position !== -1) {
          oldRoles.splice(position, 1);
          return [...oldRoles];
        }
        return [...oldRoles, { idNivel: values.idNivel }];
      });
    },
    [values.idNivel],
  );

  useEffect(() => {
    form.change('roles', roles?.length ? [...roles] : null);
  }, [roles, form]);

  const rows = useMemo(
    () =>
      nivel?.map(({ idNivel, descripcionNivel }: Nivel, index: number) => ({
        checkbox: (
          <Checkbox
            name={`roles[${index}].idNivel`}
            onChange={() => onChange(idNivel)}
          />
        ),
        idNivel,
        descripcionNivel,
      })),
    [nivel, onChange],
  );

  return (
    <Grid container direction="column">
      <Grid item>
        {nivel && (
          <InfoTable
            working={sectionWorking}
            rows={rows || []}
            columns={column || []}
            onBottomScroll={fetchMore}
          />
        )}
      </Grid>
    </Grid>
  );
};
