import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { OrientacionMateria } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'orientacion_materia';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idOrientacionMateria',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idOrientacionMateria',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.OrientacionMateria
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.OrientacionMateria,
      hoc: { ...defaultOptions.hoc, ...businessOptions.OrientacionMateria.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<OrientacionMateria>(DAO_NAME, optionsToUse);

// hooks
export const useOrientacionMateriaFetchById = fetchById;
export const useOrientacionMateriaLibbyCall = libbyCall;
export const useOrientacionMateriaDAO = libbyDAO;
export const useOrientacionMateriaLibbyFetch = libbyFetch;

// components/hoc
export const OrientacionMateriaAutocomplete = entityAutocomplete;
export const OrientacionMateriaCall = renderPropCall;
export const OrientacionMateriaById = renderPropFetchById;
export const OrientacionMateriaSelect = simpleSelect;
export const OrientacionMateriaAccordionSelector = accordionSelector;
export const OrientacionMateriaSelectedChips = selectedChips;

// context
// ByIdContext
export const useOrientacionMateriaByIdContext = useByIdContext;
export const OrientacionMateriaByIdProvider = ByIdProvider;
export const OrientacionMateriaByIdContext = ByIdContext;
// GeneralContext
export const useOrientacionMateriaContext = useGeneralContext;
export const OrientacionMateriaProvider = GeneralProvider;
export const OrientacionMateriaContext = GeneralContext;
