export enum ESTADO_PRESENTE {
  PRESENTE = 1,
  AUSENTE = 2,
  NO_CORRESPONDE = 3,
  PRESENTE_TARDE = 4,
  EXCEPTUADO = 5,
  INGRESO_TARDIO = 6,
  RETIRO_ANTICIPADO = 7,
  AUSENTE_PRESENCIA_EN_CLASE = 8,
}
