import { CertificadosSeptimoDAO } from 'src/platform/libby/dao';
import { makeBusinessEntity } from '../../lib/libby/generator';
import { CertificadoSeptimo } from '../models';
const DAO_NAME = 'certificados_septimo';
const {
  hook: { libbyFetch, fetchById, libbyDAO, libbyCall },
} = makeBusinessEntity<CertificadoSeptimo, CertificadosSeptimoDAO>(
  DAO_NAME,
  {},
);

export const useCertificadosSeptimo = libbyFetch;
export const useCertificadoSeptimoById = fetchById;
export const useCertificadosLibbyDAO = libbyDAO;
export const useCertificadosLibbyCall = libbyCall;
export const useCertificadoSeptimoLibbyFetch = libbyFetch;
