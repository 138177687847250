import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  TextField as MaterialTextField,
  TextFieldProps as MaterialTextFieldProps,
} from '@material-ui/core';

// TODO: try to do this in material theme
const useStyles = makeStyles({
  buttonRoot: {
    fontSize: 16,
    padding: '9px 12px',
  },
  noSpinner: {
    '& input[type="number"]::-webkit-inner-spin-button, & input[type="number"]::-webkit-outer-spin-button':
      {
        '-webkit-appearance': 'none',
        margin: 0,
      },
    '& input[type="number"]': {
      '-moz-appearance': 'textfield',
    },
  },
});

// POR AMOR A DIOS !!!!
export type TextFieldProps = Omit<MaterialTextFieldProps, 'variant'> | string;

export const TextField = (props: TextFieldProps) => {
  const classes = useStyles();
  const basicInputProps = { className: classes.buttonRoot };
  const { className, inputProps, ...rest } = props;
  const normalizedClassName = className
    ? { ...basicInputProps, className }
    : basicInputProps;
  const normalizedInputProps = inputProps
    ? { ...normalizedClassName, ...inputProps }
    : normalizedClassName;
  const textFieldClasses = `${classes.noSpinner} ${normalizedClassName.className}`;

  return (
    <MaterialTextField
      fullWidth
      variant="outlined"
      {...rest}
      // {...normalizedClassName}
      inputProps={normalizedInputProps}
      className={textFieldClasses}
    />
  );
};
