import React from 'react';
import { useField } from 'react-final-form-hooks';

export const FileField = ({ name, form, changeHandler, ...props }: any) => {
  const field = useField(name, form);

  return (
    <input
      onChange={(e) => {
        changeHandler(e, field);
      }}
      type="file"
      {...props}
    />
  );
};
