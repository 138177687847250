import React, { useMemo, useCallback } from 'react';
import { InfoTableColumn } from '../../../../../../../commons';
import { Checkbox, Grid, Tooltip } from '@material-ui/core';
import { JORNADA, Seccion } from '../../../../../../../app/models';
import {
  addSeccion,
  checkeSameSituacion,
  checkTurnSameSituation,
  JornadaCompletaCheckCallback,
} from '../functions/functions';
import { CheckboxInput } from '../../CheckboxInput';
import { SelectInput } from '../../../../../../../commons/components/SelectInput';
import { useTurnoLibbyCall } from '../../../../../../../app/business';
import {
  SituacionSeccion,
  SituacionV3,
} from '../../../../../../../app/models/presentismo_v3';
import { FormState } from '../../../types';
interface ColumnFormProps {
  secciones: Seccion[];
  formState: FormState;
  situaciones: SituacionV3[];
  onlyView: boolean;
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
}

/***
    hook para tratar las columnas de la tabla configuracion de secciones afectadas
*/
export const useColumnForm = ({
  secciones,
  formState,
  situaciones,
  onlyView,
  setFormState,
}: ColumnFormProps) => {
  const { data = [], working } = useTurnoLibbyCall({
    methodName: 'getByTurnoIds',
    params: [[1, 2, 5]],
  });
  const indexTD = data.findIndex((t) => t.idTurno === 5);
  if (indexTD && data.length) data[indexTD].descripcionTurno = 'Ambos';

  const TurnoDoble = '5';
  const disabledToSelect = useMemo(() => {
    return (
      formState.motivoTipoSituacion === '' || formState.tipoSituacion === ''
    );
  }, [formState]);

  /// SITUACION EDITANDO
  const situacion = useMemo(
    () => situaciones.find((s) => s.idSituacion === formState.idSituacion),
    [situaciones, formState.idSituacion],
  );

  const handleMasiveCheckJC = useCallback<
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void
  >(
    (event, checked) => {
      const _secciones: Seccion[] = [];

      secciones.forEach((seccion) => {
        const isSameSituacion = checkeSameSituacion(
          'afectaJS',
          seccion,
          formState,
          situaciones,
        );
        const toEditing = !!situacion?.secciones?.find(
          (s) => s.idSeccion === seccion.idSeccion,
        )?.afectaJE;
        const sameSituacion = toEditing ? !isSameSituacion : isSameSituacion;

        if (!sameSituacion) {
          _secciones.push(seccion);
        }
      });
      const newSeccionesAfectadas = JornadaCompletaCheckCallback(
        checked,
        _secciones,
        formState,
      );
      setFormState((prev) => ({
        ...prev,
        seccionesAfectadas: newSeccionesAfectadas,
      }));
    },
    [secciones, formState, situaciones, situacion, setFormState],
  );

  const handleTurnoSelect = useCallback(
    (seccion: Seccion) =>
      (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const target = event.target;
        setFormState((prev) => {
          const exist = prev.seccionesAfectadas.find(
            (sa) => sa.idSeccion === seccion.idSeccion,
          );
          if (exist) {
            const olds = prev.seccionesAfectadas.filter(
              (sa) => sa.idSeccion !== seccion.idSeccion,
            );
            return {
              ...prev,
              seccionesAfectadas: [
                ...olds,
                { ...exist, idTurno: target.value.toString() },
              ],
            };
          }
          return prev;
        });
      },
    [setFormState],
  );

  const handleCheckJC = useCallback(
    (seccion: Seccion) => (event: any) => {
      const existe = formState.seccionesAfectadas.find(
        (sa) => sa.idSeccion === seccion.idSeccion,
      );
      if (existe) {
        setFormState((prev) => {
          const olds = prev.seccionesAfectadas.filter(
            (sa) => sa.idSeccion !== seccion.idSeccion,
          );
          return { ...prev, seccionesAfectadas: olds };
        });
      } else {
        setFormState((prev) => {
          const newPrev: SituacionSeccion[] = [
            ...prev.seccionesAfectadas,
            {
              idSeccion: seccion.idSeccion,
              afectaJS: false,
              afectaJE: false,
              idTurno: TurnoDoble,
            },
          ];
          return { ...prev, seccionesAfectadas: newPrev };
        });
      }
    },
    [formState.seccionesAfectadas, setFormState],
  );

  const handleMasiveCheck = useCallback(
    (_: any, checked: boolean) => {
      const target = _.target;
      let newSecciones: SituacionSeccion[] = [...formState.seccionesAfectadas];
      secciones
        .filter((seccion) => {
          if (target.name === JORNADA.EXTENDIDA)
            return seccion.jornada === JORNADA.EXTENDIDA;
          return true;
        })
        .forEach((seccion) => {
          const isSameSituacion = checkeSameSituacion(
            'afectaJS',
            seccion,
            formState,
            situaciones,
          );

          const toEditing = !!situacion?.secciones?.find(
            (s) => s.idSeccion === seccion.idSeccion,
          )?.afectaJE;
          const smaeSituacion = toEditing ? !isSameSituacion : isSameSituacion;
          newSecciones = addSeccion(
            newSecciones,
            seccion,
            checked,
            target.name,
          );
        });
      setFormState((prev) => ({ ...prev, seccionesAfectadas: newSecciones }));
    },
    [formState, secciones, situacion, situaciones, setFormState],
  );

  const handleCheck = useCallback(
    (seccion: Seccion) => (_: any, checked: boolean) => {
      const target = _.target;
      const newSecciones = addSeccion(
        formState.seccionesAfectadas,
        seccion,
        checked,
        target.name,
      );
      setFormState((prev) => ({
        ...prev,
        seccionesAfectadas: [...newSecciones],
      }));
    },
    [formState, setFormState],
  );

  return useMemo<InfoTableColumn<Seccion>[]>(() => {
    if (secciones.length === 0) return [];
    const isJornadaCompleta = secciones.some(
      (s) => s.jornada === JORNADA.COMPLETA,
    );
    return [
      {
        id: 'icon',
        label: (
          <CheckboxInput
            style={{ zIndex: 10 }}
            label="J. Simple"
            labelPlacement="top"
            name={JORNADA.SIMPLE}
            color="primary"
            inputProps={{
              'aria-labelledby': 'JornadaSimpleMasive',
            }}
            onChange={handleMasiveCheck}
            disabled={
              onlyView ||
              disabledToSelect ||
              !secciones.some((s) => s.jornada === JORNADA.SIMPLE)
            }
          />
        ),
        width: '33%',
        hideSortIcon: true,
        hideColumn: isJornadaCompleta,
        align: 'center',
        render(value, row) {
          const isChecked = !!formState.seccionesAfectadas.find(
            (sa) => sa.idSeccion === row.idSeccion,
          )?.afectaJS;
          const isSameSituacion = checkeSameSituacion(
            'afectaJS',
            row,
            formState,
            !onlyView && formState.idSituacion
              ? situaciones.filter(
                  (situacion) =>
                    situacion.idSituacion !== formState.idSituacion,
                )
              : situaciones,
          );

          const sameSituacion = isSameSituacion;
          const disableCheckbox = onlyView || disabledToSelect || sameSituacion;
          const disableTooltip = onlyView || !sameSituacion;

          return (
            <Tooltip
              title="Ya existe una situación que afecta a esta sección"
              placement="left"
              disableHoverListener={disableTooltip}
              disableFocusListener={!isSameSituacion}
            >
              <Grid>
                <CheckboxInput
                  label=""
                  checked={isChecked}
                  name={JORNADA.SIMPLE}
                  color="primary"
                  disabled={
                    disableCheckbox ||
                    !secciones.some((s) => s.jornada === JORNADA.SIMPLE)
                  }
                  inputProps={{
                    'aria-labelledby': 'JornadaSimple',
                  }}
                  onChange={handleCheck(row)}
                />
              </Grid>
            </Tooltip>
          );
        },
      },
      {
        id: 'icon',
        label: (
          <CheckboxInput
            label="J. Completa"
            labelPlacement="top"
            tabIndex={-1}
            disableRipple
            color="primary"
            name={JORNADA.COMPLETA}
            inputProps={{
              'aria-labelledby': 'JornadaCompletaMasive',
            }}
            onChange={handleMasiveCheckJC}
            disabled={onlyView || disabledToSelect}
          />
        ),
        width: '33%',
        hideSortIcon: true,
        align: 'center',
        hideColumn: !isJornadaCompleta,
        render(value, row) {
          return (
            <Checkbox
              tabIndex={-1}
              disableRipple
              checked={
                !!formState.seccionesAfectadas.find(
                  (sa) => sa.idSeccion === row.idSeccion,
                )
              }
              color="primary"
              name={JORNADA.COMPLETA}
              inputProps={{
                'aria-labelledby': 'JornadaCompleta',
              }}
              onChange={handleCheckJC(row)}
              disabled={onlyView || disabledToSelect}
            />
          );
        },
      },
      {
        id: 'nombreSeccion',
        label: 'Sección',
        width: '33%',
        hideSortIcon: true,
      },
      {
        id: 'turno',
        label: 'Turno',
        width: '33%',
        hideSortIcon: true,
        hideColumn: !isJornadaCompleta,
        render: (value, row) => {
          const _seccion = formState.seccionesAfectadas.find(
            (sa) => sa.idSeccion === row.idSeccion,
          );
          const _turnos = checkTurnSameSituation(
            data,
            row,
            formState,
            situaciones.filter((s) => s.idSituacion !== formState.idSituacion),
          );
          let msg = '';
          _turnos.forEach((turno, index, all) => {
            if (all.length > 0 && all.every((a) => a.disabled)) {
              msg = `Ya existen situaciones que afectan a esta sección para ambos turnos`;
            } else if (turno.disabled) {
              msg = `Ya existe una situación que afecta a esta sección para el turno ${turno.descripcionTurno}`;
            }
          });
          if (
            !onlyView &&
            _seccion &&
            _seccion.idTurno &&
            _turnos.find((_t) => _t.idTurno === _seccion.idTurno && _t.disabled)
          ) {
            /// HAY un turno que tiene conflicto
            setFormState((prev) => {
              const rest = prev.seccionesAfectadas.filter(
                (sa) => sa.idSeccion !== row.idSeccion,
              );
              rest.push({ ..._seccion, idTurno: '' });
              return { ...prev, seccionesAfectadas: [...rest] };
            });
          }

          const isSelected = !!_seccion;
          return (
            <Tooltip
              placement="left"
              title={msg}
              disableHoverListener={onlyView || !isSelected || msg === ''}
              disableFocusListener={onlyView || !isSelected || msg === ''}
            >
              <Grid>
                <SelectInput
                  name="idTurno"
                  value={
                    isSelected
                      ? _seccion?.idTurno
                        ? _seccion?.idTurno
                        : TurnoDoble
                      : null
                  }
                  labelKey={'descripcionTurno'}
                  valueKey={'idTurno'}
                  handleChange={handleTurnoSelect(row)}
                  options={_turnos}
                  loading={working}
                  fullWidth
                  disabled={onlyView || !isSelected || disabledToSelect}
                />
              </Grid>
            </Tooltip>
          );
        },
      },
      {
        id: 'icon',
        label: (
          <CheckboxInput
            label="J. Extendida"
            labelPlacement="top"
            tabIndex={-1}
            disableRipple
            color="primary"
            name={JORNADA.EXTENDIDA}
            inputProps={{
              'aria-labelledby': 'JornadaExtenidaMasive',
            }}
            onChange={handleMasiveCheck}
            disabled={
              onlyView ||
              disabledToSelect ||
              !secciones.some((s) => s.jornada === JORNADA.EXTENDIDA)
            }
          />
        ),
        width: '33%',
        hideSortIcon: true,
        hideColumn: isJornadaCompleta,
        align: 'center',
        render(value, row) {
          if (row.jornada === JORNADA.SIMPLE) return <></>;
          const isExtendida = row.jornada !== JORNADA.EXTENDIDA;
          const isSameSituacion = checkeSameSituacion(
            'afectaJE',
            row,
            formState,
            situaciones,
          );
          const toEditing = !!situacion?.secciones?.find(
            (s) => s.idSeccion === row.idSeccion,
          )?.afectaJE;
          const smaeSituacion = toEditing ? !isSameSituacion : isSameSituacion;

          const disableCheckbox =
            onlyView || disabledToSelect || isExtendida || smaeSituacion;
          const disableTooltip = onlyView || !smaeSituacion;

          return (
            <Tooltip
              title="Ya existe una situación que afecta a esta sección"
              placement="left"
              disableHoverListener={disableTooltip}
              disableFocusListener={!isSameSituacion}
            >
              <Grid>
                <Checkbox
                  tabIndex={-1}
                  checked={
                    !!formState.seccionesAfectadas.find(
                      (sa) => sa.idSeccion === row.idSeccion,
                    )?.afectaJE
                  }
                  disabled={disableCheckbox}
                  color="primary"
                  name={JORNADA.EXTENDIDA}
                  inputProps={{
                    'aria-labelledby': 'JornadaExtendida',
                  }}
                  onChange={handleCheck(row)}
                />
              </Grid>
            </Tooltip>
          );
        },
      },
    ];
  }, [
    data,
    working,
    formState,
    secciones,
    situaciones,
    onlyView,
    situacion,
    disabledToSelect,
    handleCheckJC,
    handleMasiveCheckJC,
    handleTurnoSelect,
    handleCheck,
    handleMasiveCheck,
    setFormState,
  ]);
};
