import { ValidationErrorItem } from 'joi';
import moment from 'moment';

export type InterpolationFunction = (template: string, context: any) => string;

export type ErrorTypes = {
  [k: string]: {
    template: string;
    interpolationFunction: InterpolationFunction;
  };
};

const errorTypes: ErrorTypes = {
  'any.required': {
    template: 'El campo $1 es requerido',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label);
    },
  },
  'string.min': {
    template: 'El campo $1 necesita al menos $2 caracteres',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label).replace('$2', context.limit);
    },
  },
  'string.max': {
    template: 'El campo $1 no puede tener mas de $2 caracteres',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label).replace('$2', context.limit);
    },
  },
  'string.email': {
    template: 'El campo $1 tiene que ser un e-mail válido',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label);
    },
  },
  'string.length': {
    template: 'El campo $1 tiene que tener exactamente $2 caracteres',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label).replace('$2', context.limit);
    },
  },
  'string.empty': {
    template: 'El campo $1 no puede estar vacío',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label);
    },
  },
  'string.alphanum': {
    template: 'El campo $1 sólo puede contener caracteres alphanuméricos',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label);
    },
  },
  'string.lowercase': {
    template: 'El campo $1 sólo puede contener minúsculas',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label);
    },
  },
  'string.uppercase': {
    template: 'El campo $1 sólo puede contener mayúsculas',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label);
    },
  },
  'string.pattern.base': {
    template: 'El valor de campo $1 no es válido',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label);
    },
  },
  'string.base': {
    template: 'El campo $1 no puede estar vacío',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label);
    },
  },
  'array.min': {
    template: 'El campo $1 necesita al menos $2 elementos',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label).replace('$2', context.limit);
    },
  },
  'array.max': {
    template: 'El campo $1 no puede tener más de $2 elementos',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label).replace('$2', context.limit);
    },
  },
  'array.length': {
    template: 'El campo $1 tiene que tener exactamente $2 elementos',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label).replace('$2', context.limit);
    },
  },
  'array.hasKnown': {
    // WIP
    template: 'El campo $1 tiene que tener $2',
    interpolationFunction: (template: string, context: any) => {
      return template
        .replace('$1', context.label)
        .replace('$2', context.patternLabel);
    },
  },
  'array.hasUnknown': {
    // WIP
    template: 'El campo $1 no tiene que tener $2',
    interpolationFunction: (template: string, context: any) => {
      return template
        .replace('$1', context.label)
        .replace('$2', context.patternLabel);
    },
  },
  'boolean.base': {
    template: 'El campo $1 sólo puede tener un valor positivo o negativo',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label);
    },
  },
  'object.keys': {
    // WIP
    template: 'El campo $1 tiene que tener los siguientes elementos:...',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label);
    },
  },
  'object.xor': {
    // WIP
    template:
      'El campo $1 sólo puede tener uno de los 2 elementos seleccionado',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label);
    },
  },
  'object.with': {
    // WIP
    template:
      'El campo $1 tiene que tener [tal elemento] seleccionado a la vez con los demás',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label);
    },
  },
  'object.without': {
    // WIP
    template:
      'El campo $1 no tiene que tener [tal elemento] seleccionado a la vez con los demás',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label);
    },
  },
  'object.min': {
    template: 'El campo $1 necesita al menos $2 elementos',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label).replace('$2', context.limit);
    },
  },
  'object.max': {
    template: 'El campo $1 no puede tener más de $2 elementos',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label).replace('$2', context.limit);
    },
  },
  'object.length': {
    template: 'El campo $1 tiene que tener exactamente $2 elementos',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label).replace('$2', context.limit);
    },
  },
  'number.base': {
    template: 'El valor de campo $1 es incorrecto',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label);
    },
  },
  'number.greater': {
    template: 'El valor de campo $1 tiene que ser un número superior a $2',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label).replace('$2', context.limit);
    },
  },
  'number.less': {
    template: 'El valor de campo $1 tiene que ser un número menor a $2',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label).replace('$2', context.limit);
    },
  },
  'number.min': {
    template: 'El valor de campo $1 no puede ser un número menor a $2',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label).replace('$2', context.limit);
    },
  },
  'number.max': {
    template: 'El valor de campo $1 tiene que ser un número superior a $2',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label).replace('$2', context.limit);
    },
  },
  'number.negative': {
    template: 'El valor de campo $1 no puede ser negativo',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label);
    },
  },
  'date.base': {
    template: 'El campo $1 tiene que ser una fecha válida',
    interpolationFunction: (template: string, context: any) => {
      return template.replace('$1', context.label);
    },
  },
  'date.greater': {
    template: 'El campo $1 tiene que ser una fecha posterior a $2',
    interpolationFunction: (template: string, context: any) => {
      const value = context.limit === 'now' ? moment() : moment(context.limit);
      return template
        .replace('$1', context.label)
        .replace('$2', value.format('DD.MM.YYYY'));
    },
  },
  'date.less': {
    template: 'El campo $1 tiene que ser una fecha anterior a $2',
    interpolationFunction: (template: string, context: any) => {
      const value = context.limit === 'now' ? moment() : moment(context.limit);
      return template
        .replace('$1', context.label)
        .replace('$2', value.format('DD.MM.YYYY'));
    },
  },
  'date.max': {
    template: 'El campo $1 tiene que ser una fecha anterior a $2',
    interpolationFunction: (template: string, context: any) => {
      const value = context.limit === 'now' ? moment() : moment(context.limit);
      return template
        .replace('$1', context.label)
        .replace('$2', value.format('DD.MM.YYYY'));
    },
  },
  'date.min': {
    template: 'El campo $1 tiene que ser una fecha posterior a $2',
    interpolationFunction: (template: string, context: any) => {
      const value = context.limit === 'now' ? moment() : moment(context.limit);
      return template
        .replace('$1', context.label)
        .replace('$2', value.format('DD.MM.YYYY'));
    },
  },
};

export const getErrorMessage = (error: ValidationErrorItem) => {
  const errorType = errorTypes[error.type];
  if (!errorType) {
    return error.message;
  }

  return errorType.interpolationFunction(errorType.template, error.context);
};
