import React from 'react';
import { useParams } from 'react-router';
import {
  useBackButton,
  useRouteScreenTitle,
} from '../../../../../../../commons/hooks';
import { EspaciosArealPlanById } from '../../../../../../../app/business';
import { ArealInterarealFlow } from './ArealInterarealFlow';
import { Loading } from '../../../../../../../commons/components/Loading';

export const ArealInterarealFeedback = () => {
  // Title
  useRouteScreenTitle('Asesoramiento para la planificación Areal/Interareal');
  useBackButton();

  const { idPlanificacion } = useParams();

  return (
    <EspaciosArealPlanById id={idPlanificacion}>
      {({ data, working }) =>
        working || !data ? <Loading /> : <ArealInterarealFlow data={data} />
      }
    </EspaciosArealPlanById>
  );
};
