import { makeBusinessEntity } from '../../lib/libby/generator';
import { Informes } from '../models';

const DAO_NAME = 'informes';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Informes>(DAO_NAME);

// hooks
export const useInformesFetchById = fetchById;
export const useInformesLibbyCall = libbyCall;
export const useInformesDAO = libbyDAO;
export const useInformesLibbyFetch = libbyFetch;

// components/hoc
export const InformesAutocomplete = entityAutocomplete;
export const InformesCall = renderPropCall;
export const InformesById = renderPropFetchById;
export const InformesSelect = simpleSelect;
export const InformesAccordionSelector = accordionSelector;
export const InformesSelectedChips = selectedChips;

// context
// ByIdContext
export const useInformesByIdContext = useByIdContext;
export const InformesByIdProvider = ByIdProvider;
export const InformesByIdContext = ByIdContext;
// GeneralContext
export const useInformesContext = useGeneralContext;
export const InformesProvider = GeneralProvider;
export const InformesContext = GeneralContext;
