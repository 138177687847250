import React, { useMemo } from 'react';
import { UsuarioRolEstablecimiento } from '../../../../../../app/models';
import { useUsuarioRolEstablecimientoFetchById } from '../../../../../../app/business';

type ChildrenValue<T> = {
  data: T | null;
  working: boolean;
  raw: UsuarioRolEstablecimiento | undefined;
};

export type TransformFunction<T> = (value: UsuarioRolEstablecimiento) => T;

export interface EspaciosCurricularesEditValueProps<T> {
  idRolEstablecimiento: number;
  children: (value: ChildrenValue<T>) => JSX.Element;
  transformer: TransformFunction<T>;
}

// TODO: convertir en un componente de business makeEntityByIdTransformer
export function UsuarioRolEstablecimientoEditValue<T>({
  idRolEstablecimiento,
  children,
  transformer,
}: EspaciosCurricularesEditValueProps<T>) {
  const { data, working } =
    useUsuarioRolEstablecimientoFetchById(idRolEstablecimiento);

  const transformed = useMemo<T | null>(() => {
    if (!data || working) {
      return null;
    }
    return transformer(data);
  }, [data, transformer, working]);

  return <>{children({ data: transformed, working, raw: data })}</>;
}
