import { Proyeccion, Seccion } from 'src/app/models';

export interface SingleProyeccion {
  idProyeccion: string;
}

export interface IEfectivizador {
  canEfectivizar(): boolean;
}

export default class Efectivizador implements IEfectivizador {
  constructor(
    protected proyecciones: SingleProyeccion[],
    protected estado?: number,
  ) {}
  canEfectivizar(): boolean {
    return this.hasDoneProyecciones() && this.hasProyecciones();
  }

  hasProyecciones() {
    return this.proyecciones.length > 0;
  }

  hasDoneProyecciones() {
    return this.estado === 2;
  }
}
