import moment from 'moment';
import {
  AlumnoMap,
  DayColumn,
  DiaSemana,
  TurnoIdToAbbrMap,
  fechasFilterPresente,
} from '../types';
import { ContraturnoDia } from 'src/app/models/presentismo_v3/ContraturnoDia';
import { useMemo } from 'react';
import { useMultiPresentismoChecks } from '../context';
import { Presente_v3, Turno } from 'src/app/models';
import { TURNOS } from '../components/PresentismoTable/components';

import { weekDays } from '../components/PresentismoTable/functions/cerrarSemana';

export const usePresentismoExcelSemanalData_v3 = (
  rows: AlumnoMap[] = [],
  name: string,
  fechasFilterPresente: any,
  tipoJornada: string | null,
  contraturnoDias: ContraturnoDia[],
  localizacion: string,
  currentWeek: moment.Moment,
  isTabJornadaExtendida: boolean,
) => {
  const fechasFilterPresenteArray: fechasFilterPresente[] =
    Object.values(fechasFilterPresente);

  const turno = rows[0]?.seccion?.turno;

  const turnoIdToAbbr: TurnoIdToAbbrMap = {
    1: 'TM',
    2: 'TT',
    3: 'TN',
    4: 'TV',
    5: ['TM', 'TT'], // Para el caso del idTurno 5
  };

  const turnos = turnoIdToAbbr[turno?.idTurno] || [];

  const column: (string | DayColumn)[] = [
    'Nro Orden',
    'Estudiante',
    ...fechasFilterPresenteArray
      .map((item) => {
        const date = moment(item.value);

        if (date.isoWeekday() >= 1 && date.isoWeekday() <= 5) {
          // Lunes a viernes
          const dayOfWeek = date.format('dddd');
          const formattedDate = `${dayOfWeek
            .charAt(0)
            .toUpperCase()}${dayOfWeek.slice(1)}-${date.format('DD')}`;

          return {
            column: formattedDate,
            subColumns:
              turno != null && turno.idTurno === 5 ? [...turnos] : [turnos],
          };
        } else {
          return null;
        }
      })
      .filter((item): item is DayColumn => item !== null),
  ];

  const diaMap: { [dia: string]: DiaSemana } = {
    Lunes: 'Lunes',
    Martes: 'Martes',
    Miercoles: 'Miércoles',
    Jueves: 'Jueves',
    Viernes: 'Viernes',
  };

  const diaCantidadMap: { [dia in DiaSemana]?: number } = {};

  const filterContraturnos = (
    currentWeek: moment.Moment,
    contraturnoDias: ContraturnoDia[],
  ): any[] => {
    const semanaActual = moment(currentWeek).isoWeek();

    const contraturnosFiltrados: any[] = [];

    weekDays.forEach((day, dayId) => {
      const contraturnosSemana = contraturnoDias.filter((contraturno) => {
        const fechaInicioContraturno = moment(
          contraturno.contraturno.fechaInicio,
        );
        const fechaFinContraturno = moment(contraturno.contraturno.fechaFin);

        const semanaInicioContraturno = fechaInicioContraturno.isoWeek();
        const semanaFinContraturno = fechaFinContraturno.isoWeek();

        return (
          semanaInicioContraturno <= semanaActual &&
          semanaActual <= semanaFinContraturno &&
          contraturno.contraturno.active &&
          contraturno.dia.nombre === day
        );
      });

      contraturnosFiltrados.push(...contraturnosSemana);
    });

    return contraturnosFiltrados;
  };

  const contraturnosEnSemanaactual = filterContraturnos(
    currentWeek,
    contraturnoDias,
  );

  for (const contraturno of contraturnosEnSemanaactual) {
    const dia = contraturno.dia.nombre;
    const diaAcentuado = diaMap[dia];

    if (diaAcentuado) {
      if (diaCantidadMap[diaAcentuado]) {
        diaCantidadMap[diaAcentuado]!++;
      } else {
        diaCantidadMap[diaAcentuado] = 1;
      }
    }
  }

  const resultadoContrtunros = Object.keys(diaCantidadMap).map((dia) => ({
    dia,
    cantidad: diaCantidadMap[dia as DiaSemana]!,
  }));

  for (const result of resultadoContrtunros) {
    const matchingColumn = column.find((column) => {
      if (typeof column === 'string') {
        return false;
      }
      const diaSinNumero = column.column.split('-')[0].trim().toUpperCase();
      return diaSinNumero === result.dia.toUpperCase();
    });

    if (matchingColumn && typeof matchingColumn !== 'string') {
      if (result.cantidad === 1) {
        matchingColumn.subColumns.push('CT1');
      } else if (result.cantidad === 2) {
        matchingColumn.subColumns.push('CT1', 'CT2');
      }
    }
  }

  const transformedDates = fechasFilterPresenteArray
    .filter((item) => {
      const date = moment(item.value);
      const dayOfWeek = date.day();
      return dayOfWeek >= 1 && dayOfWeek <= 5; // Filtra solo días de lunes a viernes
    })
    .map((item) => moment(item.value).format('YYYY-MM-DD'));

  transformedDates.sort();

  // Obtener la primera y última fecha
  const startDate = moment(transformedDates[0]);
  const endDate = moment(transformedDates[transformedDates.length - 1]);

  // Crear la frase
  const weekDescription = `Semana del ${startDate.format(
    'D',
  )} al ${endDate.format('D')} de ${endDate.format('MMMM')}`;

  const metadataExcel = [
    localizacion,
    ' ',
    weekDescription,
    rows?.[0]?.seccion?.nombreSeccion,
    tipoJornada === 'Extendida'
      ? 'Jornada Extendida'
      : tipoJornada === 'Completa'
      ? 'Jornada Completa'
      : contraturnoDias.length > 0
      ? `Turno ${turno?.descripcionTurno} - Contraturno - Jornada Simple`
      : `Turno ${turno?.descripcionTurno} - Jornada Simple`,
  ];

  const { turno: turnosMultipresentismoChecks } = useMultiPresentismoChecks();

  const turnosAsistencia = useMemo(
    () =>
      TURNOS[turnosMultipresentismoChecks]
        ? TURNOS[turnosMultipresentismoChecks]
        : [],
    [turnosMultipresentismoChecks],
  );

  const compareDates = (dateA: string, dateB: moment.Moment, dayId: number) =>
    moment(dateA).format('DD/MM/YYYY') ===
    moment(dateB).startOf('isoWeek').add(dayId, 'days').format('DD/MM/YYYY');

  const data = weekDays.map((day, dayId) => {
    const current = moment(currentWeek)
      .startOf('isoWeek')
      .add(dayId, 'days')
      .format('YYYY-MM-DD');

    const filas = rows.map((row) => {
      const studentName = `${row.alumno.persona.apellido}, ${row.alumno.persona.nombre}`;

      const findPresentismo = (
        presentismos: Presente_v3[],
        turno: number | null,
        contraTurno: number | null,
        currentWeek: moment.Moment,
        dayId: number,
        row: AlumnoMap,
        isTabJornadaExtendida: boolean,
      ) => {
        const result = presentismos.find((pre) => {
          const dateComparison = compareDates(pre?.fecha, currentWeek, dayId);
          const turnoComparison =
            pre?.turno?.idTurno === turno || pre?.turno === null;
          const alumnoComparison =
            pre?.alumno.idAlumno === row?.alumno.idAlumno;
          const jornadaComparison =
            pre?.isJornadaExtendida === isTabJornadaExtendida;
          const contraturnoComparison =
            pre?.contraturnoDia?.idContraturnoDia === contraTurno ||
            pre?.contraturnoDia === null;

          return (
            dateComparison &&
            turnoComparison &&
            alumnoComparison &&
            jornadaComparison &&
            contraturnoComparison
          );
        });

        return result;
      };

      const CONTRATURNOS = row?.contraturnos.filter(
        (dia) =>
          dia?.dia?.idDia - 1 === dayId &&
          dia.active &&
          dia.contraturno.active &&
          dia.contraturno.fechaInicio <= current &&
          dia.contraturno.fechaFin >= current,
      );

      const ct = (
        row: AlumnoMap,
        findPresentismo: (
          presentismos: Presente_v3[],
          turno: number | null,
          contraTurno: number | null,
          currentWeek: moment.Moment,
          dayId: number,
          row: AlumnoMap,
          isTabJornadaExtendida: boolean,
        ) => Presente_v3 | undefined,
        CONTRATURNOS: ContraturnoDia[],
      ) => {
        const _turnos = [];
        for (let c of CONTRATURNOS) {
          let currenState = findPresentismo(
            row.presentismo,
            null,
            c.idContraturnoDia,
            currentWeek,
            dayId,
            row,
            isTabJornadaExtendida,
          );

          _turnos.push({
            state: currenState?.estadoPresente?.descripcion,
            contraturnoDia: c.idContraturnoDia || null,
          });
        }

        return _turnos;
      };

      const turnos = (
        row: AlumnoMap,
        turnosAsistencia: Turno[],
        findPresentismo: (
          presentismos: Presente_v3[],
          turno: number | null,
          contraTurno: number | null,
          currentWeek: moment.Moment,
          dayId: number,
          row: AlumnoMap,
          isTabJornadaExtendida: boolean,
        ) => Presente_v3 | undefined,
      ): any[] => {
        const _turnos = [];
        for (let t of turnosAsistencia) {
          let currenState: Presente_v3 | undefined;
          currenState = findPresentismo(
            row.presentismo,
            t.idTurno,
            null,
            currentWeek,
            dayId,
            row,
            isTabJornadaExtendida,
          );
          _turnos.push({
            state: currenState?.estadoPresente?.descripcion,
            turno: t.title,
          });
        }
        return _turnos;
      };

      const filas = [
        studentName,
        ...turnos(row, turnosAsistencia, findPresentismo),
        ...ct(row, findPresentismo, CONTRATURNOS),
      ];

      return filas;
    });

    return {
      day,
      date: current,
      filas,
    };
  });

  const transformedData: any[] = [];

  data[0].filas.forEach((fila, index) => {
    const studentName = fila[0];
    const rowData = { index: (index + 1).toString(), studentName };

    data.forEach((day, dayIndex) => {
      const attendanceInfo = day.filas[index]
        ? day.filas[index].slice(1)
        : Array(day.filas[0].length - 1).fill(' ');

      attendanceInfo.forEach((item, itemIndex) => {
        const stateValue = item && item.state ? item.state : ' ';
        rowData[`day${dayIndex + 1}_item${itemIndex + 1}`] = stateValue;
      });
    });

    transformedData.push(rowData);
  });

  const style = {
    font: {
      name: 'Arial',
      color: '#000000', // Color negro para el texto
      size: 12,
      bold: true,
    },
    fill: {
      type: 'pattern',
      patternType: 'solid',
      fgColor: '#00BFFF', // Color azul claro para el fondo
    },
    alignment: {
      horizontal: 'center',
    },
    border: {
      left: {
        style: 'thin',
        color: 'black',
      },
      right: {
        style: 'thin',
        color: 'black',
      },
      top: {
        style: 'thin',
        color: 'black',
      },
      bottom: {
        style: 'thin',
        color: 'black',
      },
    },
  };

  return {
    body: {
      metadata: metadataExcel,
      columnsNivel2: column,
      data: transformedData,
      styles: style,
    },
    name,
  };
};
