import React, { useMemo } from 'react';
import { InfoTableColumn } from '../../../../../commons';
import { EspacioCurricularSeccion } from '../../../../../app/models';
import {
  COLORS,
  IndicatorColor,
} from '../../TomaPresentismo/components/Resume';
import { Grid, Typography } from '@material-ui/core';
import LensIcon from '@material-ui/icons/Lens';
import { SumatoriaEstados } from '../types';
import { AvisosCell } from '../../TomaPresentismo/components/PresentismoTable/components';
import { AlertasECV3, AlumnoMap } from '../../TomaPresentismo/types';

interface LabelTableProps {
  name: string;
  color?: IndicatorColor;
}
const LabelTable = ({ name, color }: LabelTableProps) => {
  return (
    <Grid container alignItems="center">
      {color ? (
        <LensIcon
          style={{
            width: 10,
            height: 10,
            paddingRight: 5,
            color: COLORS[color].typography,
          }}
        />
      ) : (
        <></>
      )}
      <Typography variant="body2" color="textSecondary">
        {name}
      </Typography>
    </Grid>
  );
};

interface PresenteECColumsTableProps {
  movimiento: AlumnoMap;
  sumatoriaEstados: SumatoriaEstados;
  alertas: AlertasECV3;
}

export const usePresenteECColumsTable = ({
  sumatoriaEstados,
  alertas,
  movimiento,
}: PresenteECColumsTableProps) => {
  const columns = useMemo<InfoTableColumn<EspacioCurricularSeccion>[]>(() => {
    return [
      {
        id: 'espacioCurricular.descripcion',
        width: '30%',
        label: 'Espacio Curricular',
        hideSortIcon: true,
      },
      {
        id: 'presentes',
        width: '14%',
        label: <LabelTable name="Presente" color="GREEN" />,
        hideSortIcon: true,
        align: 'center',
        render: (_, row) => {
          return (
            sumatoriaEstados[row.espacioCurricular.idEspacioCurricular]
              ?.presente || ''
          );
        },
      },
      {
        id: 'ausentes',
        width: '14%',
        label: <LabelTable name="Asentes" color="RED" />,
        hideSortIcon: true,
        align: 'center',
        render: (_, row) => {
          return (
            sumatoriaEstados[row.espacioCurricular.idEspacioCurricular]
              ?.ausente || ''
          );
        },
      },
      {
        id: 'ausentespresente',
        width: '14%',
        label: <LabelTable name="ACP" color="ORANGE" />,
        hideSortIcon: true,
        align: 'center',
        render: (_, row) => {
          return (
            sumatoriaEstados[row.espacioCurricular.idEspacioCurricular]
              ?.ausenteConPresenciaEnClase || ''
          );
        },
      },
      {
        id: 'noCorresponde',
        width: '14%',
        label: <LabelTable name="N/C" color="GREY" />,
        hideSortIcon: true,
        align: 'center',
        render: (_, row) => {
          return (
            sumatoriaEstados[row.espacioCurricular.idEspacioCurricular]
              ?.noCorresponde || ''
          );
        },
      },
      {
        id: 'alertas',
        width: '14%',
        label: <LabelTable name="Alertas" />,
        hideSortIcon: true,
        align: 'center',
        render: (_, row) => {
          const _alerta =
            alertas[row.espacioCurricular.idEspacioCurricular]?.[
              movimiento.alumno.idAlumno
            ];
          return _alerta ? (
            <AvisosCell avisosCount={_alerta} defaultIcon="" />
          ) : (
            ''
          );
        },
      },
    ];
  }, [sumatoriaEstados, alertas, movimiento]);
  return { columns };
};
