import { REQUEST_TYPES } from 'src/lib/libby/hooks/useLibbyRequest';
import { makeEntityLibbyRequest } from '../../../lib/libby/hooks/makeEntityLibbyRequest';

const DAO_NAME = 'distritoscustom';

// hooks
export const useDistritosCustomGetRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.GET,
);
export const useDistritosCustomPostRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.POST,
);
export const useDistritosCustomPutRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.PUT,
);
export const useDistritosCustomDeleteRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.DELETE,
);
