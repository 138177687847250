import React from 'react';
import { Grid } from '@material-ui/core';
import {
  MainInput,
  SimpleSelect,
} from '../../../../../../../../commons/components';
import { AnyObject } from '../../../../../../../../commons';
import sortAnioSelectContent from '../../../../utils/sortAnioSelectContent';

const customStyles = {
  customStyleTitleSelect: { fontSize: 12, color: '#595959', marginBottom: 2 },
  inputStyle: { padding: 10, marginTop: 23 },
};

export type estadoPase = {
  descripcionSolicitudPase: string;
  idEstadoSolicitudPases: number;
};

export type estadoYear = {
  descripcionAnio: string;
  idAnio: number;
  nivel: {
    descripcionNivel: string;
    idNivel: number;
  };
  numeroAnio: number;
};

export const FilterTables = ({
  passStatus,
  typeOfPass,
  year,
  formValues,
  handleChange,
  handleChangeSearch,
  search,
}: AnyObject) => {
  const { estadoSolicitudPases, anio, localizacionDestino } = formValues;
  passStatus.sort((a: estadoPase, b: estadoPase) =>
    a.descripcionSolicitudPase > b.descripcionSolicitudPase ? 1 : -1,
  );

  return (
    <>
      <Grid container justify="flex-start" spacing={3} alignItems="center">
        <Grid item xs={4}>
          <SimpleSelect
            name="anio"
            title="Año"
            placeholder="Seleccioná Año"
            labelKey="descripcionAnio"
            valueKey="idAnio"
            handleChange={handleChange}
            content={sortAnioSelectContent(year)}
            value={anio}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
        <Grid item xs={4}>
          <SimpleSelect
            title="Estado"
            placeholder="Seleccioná Estado"
            labelKey="descripcionSolicitudPase"
            valueKey="idEstadoSolicitudPases"
            content={passStatus}
            handleChange={handleChange}
            value={estadoSolicitudPases}
            customStyleTitle={customStyles.customStyleTitleSelect}
            name="estadoSolicitudPases"
          />
        </Grid>
        <Grid item xs={4}>
          <SimpleSelect
            name="localizacionDestino"
            title="Tipo de pase"
            placeholder="Seleccioná tipo de pase"
            labelKey="label"
            valueKey="value"
            content={typeOfPass}
            handleChange={handleChange}
            value={localizacionDestino}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
      </Grid>
      <Grid
        container
        xs={8}
        justify="flex-end"
        spacing={3}
        alignItems="flex-end"
      >
        <Grid item xs={8}>
          <MainInput
            type="search"
            handleChange={handleChangeSearch}
            placeholder="Buscar"
            name="search"
            value={search}
            fullWidth
            customStyle={customStyles.inputStyle}
          />
        </Grid>
      </Grid>
    </>
  );
};
