import { Grid, Typography } from '@material-ui/core';
import React from 'react';
export const ModalAlumno = ({ row, usuarioRolEstablecimiento }: any) => {
  return (
    <>
      <Grid container direction="column" style={{ padding: '0 24px 16px' }}>
        <Grid container direction="row">
          <Typography style={{ marginRight: '10px' }}>Escuela:</Typography>
          <Typography>
            {row?.alumnoMovimiento?.seccion?.localizacion?.descripcion}
          </Typography>
        </Grid>

        <Grid container direction="row">
          <Typography style={{ marginRight: '10px' }}>DNI:</Typography>
          <Typography>
            {row?.alumnoMovimiento?.alumno?.persona?.documento}
          </Typography>
        </Grid>

        <Grid container direction="row">
          <Typography style={{ marginRight: '10px' }}>Sección:</Typography>
          <Typography>{row.alumnoMovimiento.seccion.nombreSeccion}</Typography>
        </Grid>
      </Grid>
    </>
  );
};
