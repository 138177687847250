import React from 'react';
import { useRouteScreenTitle } from '../../../commons/hooks';
import { TabBar } from '../../../commons/components/TabBar';
import { Muro } from './Muro';
import { BLOCKER_ID } from 'src/platform/permission/const';
import { PermissionBlocker } from 'src/lib/permission';

export const Comunicaciones = () => {
  useRouteScreenTitle('Comunicaciones');

  return (
    <PermissionBlocker id={BLOCKER_ID.COMUNICATIONS}>
      <TabBar content={[{ title: 'Muro', component: <Muro /> }]} />
    </PermissionBlocker>
  );
};
