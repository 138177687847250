import { Establecimiento, Persona, Vinculo } from 'src/app/models';

export interface changeResponsableArguments {
  responsableId: string | null;
  responsableCustomData: any;
  responsablePersona: Persona | null;
  responsableVinculo: Vinculo | null;
}
export interface LegajoLayoutType {
  alumnoId: string | number;
  initialView?: LegajoTabsViews;
  changeResponsableIds?: (data: changeResponsableArguments | any) => void;
  responsableData?: any;
  saving?: boolean;
  setBeingSaved?: (saved: boolean) => void;
  setIsNewResponsable?: (isNew: boolean) => void;
  isNewResponsable?: boolean;
  setIsEditResponsable?: (isEdit: boolean) => void;
  isEditResponsable?: boolean;
  saveDone?: boolean;
  setSaveDone?: (saved: boolean) => void;
  setResponsableDatosSecundarios?: (data: any) => void;
  responsableDatosSecundarios?: any;
}

export type Alumno = Persona & {
  [key: string]: any;
  establecimiento?: Establecimiento;
};

export enum LegajoTabsViews {
  RESIDENCIA = 'legajo-alumno/residencia',
  SALUD = 'legajo-alumno/SALUD',
  DOCUMENTACION = 'legajo-alumno/documentacion',
  HISTORIAL_ACADEMICO = 'legajo-alumno/historial-academico',
  PPI_PPS = 'legajo-alumno/ppi-pps',
  RESPONSABLES = 'legajo-alumno/responsables',
}
