import React from 'react';
import { TooltipColumn } from '../../components/TooltipColumn';
import { TableColumn } from '../../TableColumn';
import { FILTERS } from '../../table_filters';
import {
  CalificacionesAprobadosReport,
  CalificacionesDesaprobadasReport,
  CalificacionesDeudaReport,
  CalificacionesVinculoPedagogicoReport,
} from '../../types';

// Listado de estudiantes con calificaciones desaprobadas por materia / año / seccion / periodo
export const CALIFICACIONES_ESTU_CALIFICACIONES_COLUMNS = [
  new TableColumn<CalificacionesDesaprobadasReport>('cueanexo'),
  new TableColumn<CalificacionesDesaprobadasReport, React.ReactNode>(
    'establecimiento',
    undefined,
    (value, row) => <TooltipColumn title={value} />,
  ),
  new TableColumn<CalificacionesDesaprobadasReport>('distritoescolar'),
  new TableColumn<CalificacionesDesaprobadasReport>('apellido'),
  new TableColumn<CalificacionesDesaprobadasReport, React.ReactNode>(
    'nombre',
    undefined,
    (value, row) => <TooltipColumn title={value} />,
  ),
  new TableColumn<CalificacionesDesaprobadasReport>('tipodocumento'),
  new TableColumn<CalificacionesDesaprobadasReport>('documento'),
  new TableColumn<CalificacionesDesaprobadasReport>('materia'),
  new TableColumn<CalificacionesDesaprobadasReport>('nota', 'Calificación'),
  new TableColumn<CalificacionesDesaprobadasReport>('periodo'),
];

export const CALIFICACIONES_ESTU_CALIFICACIONES_FILTERS = [
  FILTERS.dependencia_funcional,
  FILTERS.distrito_escolar,
  FILTERS.localizacion,
  FILTERS.seccion,
  FILTERS.anio,
  FILTERS.turno,
  FILTERS.materia,
  FILTERS.periodo,
  FILTERS.dni,
];

// Listado de estudiantes con calificaciones previas cargadas por periodo
export const CALIFICACIONES_EST_PREVIA_CARGADA_COLUMNS = [
  new TableColumn<CalificacionesDeudaReport>('cue_anexo'),
  new TableColumn<CalificacionesDeudaReport, React.ReactNode>(
    'establecimiento',
    undefined,
    (value, row) => <TooltipColumn title={value} />,
  ),
  new TableColumn<CalificacionesDeudaReport>('distrito_escolar'),
  new TableColumn<CalificacionesDeudaReport>('apellido'),
  new TableColumn<CalificacionesDeudaReport, React.ReactNode>(
    'nombre',
    undefined,
    (value, row) => <TooltipColumn title={value} />,
  ),
  new TableColumn<CalificacionesDeudaReport>('tipo_documento'),
  new TableColumn<CalificacionesDeudaReport>('documento'),
  new TableColumn<CalificacionesDeudaReport>('año_deuda'),
  new TableColumn<CalificacionesDeudaReport>('año_seccion'),
  new TableColumn<CalificacionesDeudaReport, React.ReactNode>(
    'seccion',
    undefined,
    (value, row) => <TooltipColumn title={value} />,
  ),
  new TableColumn<CalificacionesDeudaReport>('materia'),
  new TableColumn<CalificacionesDeudaReport>('tipo_deuda'),
  new TableColumn<CalificacionesDeudaReport>(
    'calificacion',
    'Calificación de Previa',
  ),
  new TableColumn<CalificacionesDeudaReport>('periodo'),
  new TableColumn<CalificacionesDeudaReport>('fecha'),
];

export const CALIFICACIONES_EST_PREVIA_CARGADA_FILTERS = [
  FILTERS.dependencia_funcional,
  FILTERS.distrito_escolar,
  FILTERS.localizacion,
  FILTERS.seccion,
  FILTERS.anio,
  FILTERS.turno,
  FILTERS.tipo_previa,
  FILTERS.calificacion,
  FILTERS.periodo,
  FILTERS.dni,
];

// Listado de estudiantes que no tienen contacto a vínculo pedagógico por bimestre
export const CALIFICACIONES_EST_SIN_VINCULO_COLUMNS = [
  new TableColumn<CalificacionesVinculoPedagogicoReport>('cue_anexo'),
  new TableColumn<CalificacionesVinculoPedagogicoReport, React.ReactNode>(
    'establecimiento',
    undefined,
    (value, row) => <TooltipColumn title={value} />,
  ),
  new TableColumn<CalificacionesVinculoPedagogicoReport>('distrito_escolar'),
  new TableColumn<CalificacionesVinculoPedagogicoReport>('apellido'),
  new TableColumn<CalificacionesVinculoPedagogicoReport, React.ReactNode>(
    'nombre',
    undefined,
    (value, row) => <TooltipColumn title={value} />,
  ),
  new TableColumn<CalificacionesVinculoPedagogicoReport>('tipo_documento'),
  new TableColumn<CalificacionesVinculoPedagogicoReport>('documento'),
  new TableColumn<CalificacionesVinculoPedagogicoReport>('año'),
  new TableColumn<CalificacionesVinculoPedagogicoReport, React.ReactNode>(
    'seccion',
    undefined,
    (value, row) => <TooltipColumn title={value} />,
  ),
  new TableColumn<CalificacionesVinculoPedagogicoReport>('turno'),
  new TableColumn<CalificacionesVinculoPedagogicoReport>('materia'),
  new TableColumn<CalificacionesVinculoPedagogicoReport>('periodo'),
  new TableColumn<CalificacionesVinculoPedagogicoReport>(
    'vinculo_pedagogico',
    '¿Tiene vínculo?',
  ),
];

export const CALIFICACIONES_EST_SIN_VINCULO_FILTERS = [
  FILTERS.dependencia_funcional,
  FILTERS.distrito_escolar,
  FILTERS.localizacion,
  FILTERS.seccion,
  FILTERS.anio,
  FILTERS.turno,
  FILTERS.periodo,
  FILTERS.dni,
];

// Porcentaje de aprobados y desaprobados en los establecimientos por materia / año / seccion / periodo
export const CALIFICACIONES_EST_APROB_DESAPRO_COLUMNS = [
  new TableColumn<CalificacionesAprobadosReport>('cueanexo'),
  new TableColumn<CalificacionesAprobadosReport, React.ReactNode>(
    'establecimiento',
    undefined,
    (value, row) => <TooltipColumn title={value} />,
  ),
  new TableColumn<CalificacionesAprobadosReport>('distrito_escolar'),
  new TableColumn<CalificacionesAprobadosReport>('año'),
  new TableColumn<CalificacionesAprobadosReport, React.ReactNode>(
    'seccion',
    undefined,
    (value, row) => <TooltipColumn title={value} />,
  ),
  new TableColumn<CalificacionesAprobadosReport>('periodo'),
  new TableColumn<CalificacionesAprobadosReport>('total', 'Nro matriculados'),
  new TableColumn<CalificacionesAprobadosReport>('materia'),
  new TableColumn<CalificacionesAprobadosReport, React.ReactNode>(
    'porc_aprobados',
    null,
    (value, row) => (
      <TooltipColumn
        title={`${(
          (Number(row.cant_aprobados) / Number(row.total)) *
          100
        ).toFixed(2)}%`}
      />
    ),
  ).setWidth('10%'),
  new TableColumn<CalificacionesAprobadosReport, React.ReactNode>(
    'porc_desaprobados',
    null,
    (value, row) => (
      <TooltipColumn
        title={`${(
          (Number(row.cant_desaprobados) / Number(row.total)) *
          100
        ).toFixed(2)}%`}
      />
    ),
  ).setWidth('10%'),
  new TableColumn<CalificacionesAprobadosReport>(
    'plan_estudio',
    'Plan de Estudio',
  ),
];

export const CALIFICACIONES_EST_APROB_DESAPRO_FILTERS = [
  FILTERS.dependencia_funcional,
  FILTERS.distrito_escolar,
  FILTERS.localizacion,
  FILTERS.seccion,
  FILTERS.anio,
  FILTERS.turno,
  FILTERS.materia,
  FILTERS.periodo,
];
