import { Presente_v3 } from 'src/app/models';
import { ESTADO_PRESENTE } from 'src/commons';
import { TURNOS } from '../../TomaPresentismo/components/PresentismoTable/components';
import moment from 'moment';
import { PresenteGeneral } from '../../TomaPresentismo/types';
import { capitalizarPalabras } from './functions';

const enum TURNOS_MAP {
  MAÑANA = 1,
  TARDE = 2,
  NOCHE = 3,
  VESPERTINO = 4,
  DOBLE = 5,
}

export const getDataJC = (periodoFiltrado: PresenteGeneral[]) => {
  const turnoMañanaPresente = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.PRESENTE &&
        item.turno?.idTurno === TURNOS_MAP.MAÑANA,
    )
    .length.toString();

  const turnoTardePresentes = periodoFiltrado
    .filter(
      (item) =>
        item.turno?.idTurno === TURNOS_MAP.TARDE &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.PRESENTE,
    )
    .length.toString();

  const TurnoMañanaAusentes = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE &&
        item.turno?.idTurno === TURNOS_MAP.MAÑANA,
    )
    .length.toString();
  const turnoMañanaAusenteJustificado = periodoFiltrado
    .filter(
      (item) =>
        item.turno?.idTurno === TURNOS_MAP.MAÑANA &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE &&
        item.justificacion !== null,
    )
    .length.toString();

  const turnoMañanaIngresosTardios = periodoFiltrado
    .filter(
      (item) =>
        item.turno?.idTurno === TURNOS_MAP.MAÑANA &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.INGRESO_TARDIO,
    )
    .length.toString();

  const turnoMañanaIngresosTardiosJustificados = periodoFiltrado
    .filter(
      (item) =>
        item.turno?.idTurno === TURNOS_MAP.MAÑANA &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.INGRESO_TARDIO &&
        item.justificacion !== null,
    )
    .length.toString();

  const turnoMañanaRetiroAnticipado = periodoFiltrado
    .filter(
      (item) =>
        item.turno?.idTurno === TURNOS_MAP.MAÑANA &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.RETIRO_ANTICIPADO,
    )
    .length.toString();

  const turnoMañanaRetirosAnticipadosJustificados = periodoFiltrado
    .filter(
      (item) =>
        item.turno?.idTurno === TURNOS_MAP.MAÑANA &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.RETIRO_ANTICIPADO &&
        item.justificacion !== null,
    )
    .length.toString();

  const turnoMañanaAusentesConPresenciaEnClases = periodoFiltrado
    .filter(
      (item) =>
        item.turno?.idTurno === TURNOS_MAP.MAÑANA &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE,
    )
    .length.toString();

  const turnoMañanaAusentesConPresenciaEnClasesJustificados = periodoFiltrado
    .filter(
      (item) =>
        item.turno?.idTurno === TURNOS_MAP.MAÑANA &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE &&
        item.justificacion !== null,
    )
    .length.toString();

  const sinDatosturnoMañana = periodoFiltrado
    .filter(
      (item) =>
        item.turno?.idTurno === TURNOS_MAP.MAÑANA &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.NO_CORRESPONDE,
    )
    .length.toString();

  const turnoMañanaSinJustificacion = periodoFiltrado
    .filter(
      (item) =>
        (item.turno?.idTurno === TURNOS_MAP.MAÑANA &&
          item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE) ||
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.INGRESO_TARDIO ||
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.RETIRO_ANTICIPADO ||
        item.justificacion !== null,
    )
    .length.toString();

  const SinJustificaciontTrnoTarde = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE ||
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.INGRESO_TARDIO ||
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.RETIRO_ANTICIPADO ||
        (item.justificacion !== null &&
          item.turno?.idTurno === TURNOS_MAP.TARDE),
    )
    .length.toString();

  const turnoTardeAusente = periodoFiltrado
    .filter(
      (item) =>
        item.turno?.idTurno === TURNOS_MAP.TARDE &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE,
    )
    .length.toString();

  const turnoTardeAuseteJustificado = periodoFiltrado
    .filter(
      (item) =>
        item.turno?.idTurno === TURNOS_MAP.TARDE &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE &&
        item.justificacion !== null,
    )
    .length.toString();

  const turnoTardeIngresosTardios = periodoFiltrado
    .filter(
      (item) =>
        item.turno?.idTurno === TURNOS_MAP.TARDE &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.INGRESO_TARDIO,
    )
    .length.toString();

  const turnoTardeingnresoTardiosJustificados = periodoFiltrado
    .filter(
      (item) =>
        item.turno?.idTurno === TURNOS_MAP.TARDE &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.INGRESO_TARDIO &&
        item.justificacion !== null,
    )
    .length.toString();

  const turnoTardeRetirosAntisipados = periodoFiltrado
    .filter(
      (item) =>
        item.turno?.idTurno === TURNOS_MAP.TARDE &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.RETIRO_ANTICIPADO,
    )
    .length.toString();

  const turnoTardeAnticipadosJustificados = periodoFiltrado
    .filter(
      (item) =>
        item.turno?.idTurno === TURNOS_MAP.TARDE &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.RETIRO_ANTICIPADO &&
        item.justificacion !== null,
    )
    .length.toString();

  const turnoTardeAuusentesConPresenciaEnClases = periodoFiltrado
    .filter(
      (item) =>
        item.turno?.idTurno === TURNOS_MAP.TARDE &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE,
    )
    .length.toString();

  const turnoTardeAusentesConPresenciaEnClasesJustificado = periodoFiltrado
    .filter(
      (item) =>
        item.turno?.idTurno === TURNOS_MAP.TARDE &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE &&
        item.justificacion !== null,
    )
    .length.toString();

  const turnoTardedSinDatos = periodoFiltrado
    .filter(
      (item) =>
        item.turno?.idTurno === TURNOS_MAP.TARDE &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.NO_CORRESPONDE,
    )
    .length.toString();

  return [
    {
      Nombre: 'Presente',
      TMPresentes: turnoMañanaPresente,
      TTPresentes: turnoTardePresentes,
      ResumenJustificacionGuion: '-',
      ResumenJustificacionGuion2: '-',
      JornadaExtendidaGuion: '-',
    },
    {
      Nombre: 'Ausente',
      TMAusentes: TurnoMañanaAusentes,
      TTAusetes: turnoTardeAusente,
      NombreAusentesJustificados: 'Ausentes Justificados',
      TMAJustificados: turnoMañanaAusenteJustificado,
      TTAJustificados: turnoTardeAuseteJustificado,
    },
    {
      Nombre: 'Ingresos Tardíos',
      TMITardios: turnoMañanaIngresosTardios,
      TTITardios: turnoTardeIngresosTardios,
      NombreIngresosTardiosJustificados: 'Ingresos Tardíos Justificados',
      TMITardiosJustificados: turnoMañanaIngresosTardiosJustificados,
      TTITardiosJustificados: turnoTardeingnresoTardiosJustificados,
    },
    {
      Nombre: 'Retiros Anticipados',
      TMRetiroAnticipados: turnoMañanaRetiroAnticipado,
      TTRetirosAnticipados: turnoTardeRetirosAntisipados,
      NombreRetirosAnticipadosJustificados: 'Retiros Anticipados Justificados',
      TMRetirosAnticipadosJustificados:
        turnoMañanaRetirosAnticipadosJustificados,
      TTRetirosAnticipadosJustificados: turnoTardeAnticipadosJustificados,
    },
    {
      Nombre: 'Ausentes con Presencia en Clases',
      TMAusentesConPresenciaEnClases: turnoMañanaAusentesConPresenciaEnClases,
      TTAusentesConPResenciaEnClases: turnoTardeAuusentesConPresenciaEnClases,
      NombreAusentesConPresenciaEnClasesJustificados:
        'Ausentes con Presencia en Clases',
      TMAusentesConPresenciaEnClaseJustificados:
        turnoMañanaAusentesConPresenciaEnClasesJustificados,
      TTAusentesConPresenciaEnClasesJustificados:
        turnoTardeAusentesConPresenciaEnClasesJustificado,
    },
    {
      Nombre: 'No Corresponde',
      TMCantidadSinDatos: sinDatosturnoMañana,
      TTCantidadSinDAtos: turnoTardedSinDatos,
      SinJustificacion: 'Sin Justificación',
      TMCantidadSinJustificar: turnoMañanaSinJustificacion,
      TTCantidadSinJustificar: SinJustificaciontTrnoTarde,
    },
  ];
};

export const getTableSecDataJC = (periodoFiltrado: PresenteGeneral[]) => {
  const uniqueDates = [...new Set(periodoFiltrado.map((item) => item.fecha))];

  const columns = [
    'Día',
    'Fecha',
    'Presentismo TM',
    'Presentismo TT',
    'Justificación Ausencia TM',
    'Justificación Ausencia TT',
  ];

  const data = uniqueDates.map((date) => {
    const itemsForDate = periodoFiltrado.filter((item) => item.fecha === date);

    const diaCapitalizado = capitalizarPalabras(
      moment(itemsForDate[0].fecha).format('dddd'),
    );

    return {
      dia: diaCapitalizado,
      fecha: moment(itemsForDate[0].fecha).format('DD-MM-YYYY'),
      presentismoTM:
        itemsForDate.find((item) => item.turno?.idTurno === TURNOS_MAP.MAÑANA)
          ?.estadoPresente.descripcion ?? '-',
      presentismoTT:
        itemsForDate.find((item) => item.turno?.idTurno === TURNOS_MAP.TARDE)
          ?.estadoPresente?.descripcion ?? '-',
      justificacionAusenciaTM: itemsForDate.some(
        (item) =>
          item.justificacion === null &&
          item.turno?.idTurno === TURNOS_MAP.MAÑANA,
      )
        ? 'No'
        : 'Si',
      justificacionAusenciaTT:
        itemsForDate.filter(
          (item) =>
            item.justificacion !== null &&
            item.turno?.idTurno === TURNOS_MAP.TARDE,
        ).length > 0
          ? 'Si'
          : 'No',
    };
  });

  return { columns, data };
};
