import { Rules } from 'src/lib/permission/types';
import { nivelRules } from './nivel';
import { rolRules } from './rol';
import { futureRuleSet } from './future';
import { customRules } from './custom/customRules';

export const allPermission: Rules[] = [
  ...rolRules,
  ...nivelRules,
  ...customRules,
  futureRuleSet,
];
