import React, { FC, useCallback, useMemo, useState } from 'react';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { useObservacionesBoletinFormContext } from '../hooks';
import { FormInputV2 } from '../../../../../../../lib/templates';
import customFormDialog from '../../../../../../../commons/services/customFormDialog';
import { PeriodoNuevoSelect } from 'src/app/business';
import { Loading, TIPO_PERIODO } from 'src/commons';
import { SmallError } from 'src/commons/components/FormError';
import { PeriodoNuevo } from '../../../../../../../app/models';
import moment from 'moment';
import { ObservacionesBoletin } from '../../../../../../../app/models/presentismo_v3/ObservacionesBoletin';
import { useObservacionnesBoletinDAO } from '../../../../../../../app/business/presentismo_v3';

const useStyles = makeStyles({
  formError: {
    color: 'red',
    marginTop: '5px',
  },
  selectLabel: {
    fontSize: 16,
    letterSpacing: 0,
    color: '#38485C',
    fontWeight: 'bold',
    fontFamily: 'Nunito',
  },
  selectInput: {
    backgroundColor: '#EEF5FC',
    border: 'none',
    //  height: 38,
    '& :fieldset': {
      border: 'none',
    },
  },
  selectBg: {
    backgroundColor: '#EEF5FC',
  },
  rootInput: {
    border: 'none',
    '& > fieldset': {
      border: 'none',
    },
  },
  textAreaLabel: {
    fontSize: 15,
    letterSpacing: 0,
    color: '#38485C',
    fontWeight: 'bold',
    fontFamily: 'Nunito',
    top: -11,
  },
  button: {
    textTransform: 'none',
  },
});

export interface DaysWeek {
  id: number;
  label: string;
  value: string;
}

interface ObservacionsFormProps {
  hasJornadaExtendidaDisabled: boolean;
  nivel: string;
  isJE: boolean;
}

const SEGUNDO_BIM_DESCRIPTION = 'Segundo Bimestre / Primer cuatrimestre';
const CUARTO_BIM_DESCRIPTION = 'Cuarto Bimestre / Segundo cuatrimestre';

const getValidPeriods = (periodos: PeriodoNuevo[]) =>
  periodos
    .filter((periodo) =>
      moment(periodo.fechaInicio, 'YYYY-MM-DD').isSameOrBefore(
        moment().format('YYYY-MM-DD'),
      ),
    )
    .map((periodo) => {
      const { description } = periodo;
      let fixedDescription = periodo.description;
      if (description === SEGUNDO_BIM_DESCRIPTION) {
        fixedDescription = 'Segundo bimestre';
      } else if (description === CUARTO_BIM_DESCRIPTION) {
        fixedDescription = 'Cuarto Bimestre';
      }
      return { ...periodo, description: fixedDescription };
    });

export const ObservacionesForm: FC<ObservacionsFormProps> = ({
  hasJornadaExtendidaDisabled,
  nivel,
  isJE,
}) => {
  const [loading, setLoading] = useState(false);
  const observacionesBoletinDAO = useObservacionnesBoletinDAO();
  const { form, values, handleSubmit, errors, submitting } =
    useObservacionesBoletinFormContext();

  const classes = useStyles();

  const nivelFilter = useMemo(
    () => ({
      nivel: [
        {
          path: 'nivel',
          value: nivel,
        },
      ],
      tipoPeriodo: [
        {
          path: 'tipoPeriodo',
          value: TIPO_PERIODO.RECESO_INVERNAL,
          method: 'notEquals',
        },
      ],
    }),
    [nivel],
  );

  const getObservacion = useCallback(
    async (idPeriodo: number) => {
      try {
        setLoading(true);
        const [observacion]: ObservacionesBoletin[] =
          await observacionesBoletinDAO.getByAlumno(
            Number(values.alumnoId),
            nivel,
            isJE,
            idPeriodo,
          );

        if (observacion) {
          form.change('observaciones', observacion.observacion);
        } else {
          form.change('observaciones', '');
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    },
    [observacionesBoletinDAO, isJE, nivel, form, values],
  );

  const handlePeriodoChange = useCallback(
    (e: any) => {
      form.change('idPeriodoNuevo', e.target.value);
      if (e.target.value !== 0) {
        getObservacion(e.target.value);
      }
    },
    [form, getObservacion],
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12}>
            <PeriodoNuevoSelect
              value={values.idPeriodoNuevo}
              handleChange={handlePeriodoChange}
              placeholder="Seleccioná el período"
              labelKey="description"
              valueKey="idPeriodoNuevo"
              limit={20}
              filter={nivelFilter}
              transformOptions={(data) =>
                getValidPeriods(data as PeriodoNuevo[])
              }
              disabled={loading}
            />
            <SmallError errors={errors} name="idPeriodoNuevo" />
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <Loading />
            ) : (
              <FormInputV2
                name={`observaciones`}
                form={form}
                multiline
                placeholder="Observaciones"
                minRows={12}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                  className: classes.textAreaLabel,
                }}
                InputProps={{
                  className: classes.selectInput,
                  classes: { root: classes.rootInput },
                }}
                disabled={loading}
                inputProps={{ maxLength: 500 }}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          style={{ paddingTop: 16, paddingBottom: 16 }}
          direction="row"
          spacing={2}
          justifyContent="flex-end"
        >
          <Grid item>
            <Button
              className={classes.button}
              variant="text"
              color="primary"
              onClick={() => customFormDialog.handleCancel()}
              disabled={submitting}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item>
            {submitting ? (
              <Loading />
            ) : (
              <Button
                className={classes.button}
                onClick={() => handleSubmit()}
                variant="contained"
                color="primary"
                disabled={
                  values.observaciones === '' ||
                  [null, '', 0].includes(values.idPeriodoNuevo) ||
                  hasJornadaExtendidaDisabled
                }
              >
                Guardar
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
