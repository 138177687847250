import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { usePropuestaJEDAO } from 'src/app/business/presentismo_v2/PropuestaJE';
import { Footer } from 'src/commons/components/Footer';
import customFormDialog from 'src/commons/services/customFormDialog';
import { PropuestaJEFormProvider } from '../../../context/configuration/PropuestaJEFormProvider';
import { PresentismoContextProvider } from '../../../context/Context';
import { useStyles } from '../../../styles/propuestaJE';
import { presentismo } from '../../../types';
import { DaysTable } from './DaysTable';
import { ModalHeader } from './ModalHeader';
import { useSnackbar } from 'notistack';
import confirmDialog from 'src/commons/services/confirmDialog';
import { Presente_v3 } from '../../../../../../app/models/presentismo_v3/Presente_v3';

export const Modal = ({
  initialValues,
  show = false,
  showFooter = false,
  reFetch = () => {},
  nivel,
  currentCicloLectivo,
  presentismosAllJE,
}: {
  initialValues?: presentismo.PropuestaJe;
  show?: boolean;
  showFooter?: boolean;
  reFetch: () => void;
  nivel: string;
  currentCicloLectivo: number | null;
  presentismosAllJE: Presente_v3[];
}) => {
  const classes = useStyles();
  const [filters, setFilter] = useState<presentismo.PropuestaJEFilters>({
    anio: '',
    seccion: initialValues?.propuesta?.seccion?.idSeccion
      ? initialValues.propuesta.seccion.idSeccion
      : '',
  });
  const handleCloseModal = async () => {
    await customFormDialog.handleCancel();
  };

  const propuestaDAO = usePropuestaJEDAO();

  async function eliminarPropuesta() {
    await propuestaDAO
      .aspect('delete-propuesta')
      .remove({ idPropuestaJe: initialValues?.propuesta?.idPropuestaJe });

    handleCloseModal();
  }

  const { enqueueSnackbar } = useSnackbar();

  const confirmDelete = async () => {
    const confirm = await confirmDialog.show({
      title: 'Confirmación de Eliminación',
      content: `La propuesta que intenta eliminar contiene asistencia, ¿Desea eliminar la propuesta?`,
      confirmText: 'Eliminar',
      cancelText: 'Cancelar',
    });
    if (confirm) {
      try {
        eliminarPropuesta();
        enqueueSnackbar('Se ha eliminado con exito', {
          variant: 'success',
        });
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Error al eliminar', {
          variant: 'error',
        });
      }
    }
  };

  const buttonConfig: any = [
    {
      title: 'Cancelar',
      type: 'secondary',
      size: 'medium',
      handleOnClick: () => handleCloseModal(),
    },
    {
      title: 'Eliminar',
      handleOnClick:
        presentismosAllJE?.length > 0
          ? () => confirmDelete()
          : () => eliminarPropuesta(),
      size: 'medium',
    },
  ];

  return (
    <PresentismoContextProvider>
      <Grid
        container
        classes={{
          root: classes.modalContainer,
        }}
      >
        {initialValues ? null : (
          <Grid item xs={12}>
            <ModalHeader
              changeFilters={setFilter}
              filters={filters}
              nivel={nivel}
              currentCicloLectivo={currentCicloLectivo}
            />
          </Grid>
        )}
        {(filters.anio && filters.seccion) || Boolean(initialValues) ? (
          <PropuestaJEFormProvider
            seccion={filters.seccion}
            initialValues={initialValues}
            reFetch={reFetch}
          >
            <DaysTable disabled={show} filters={filters} />
          </PropuestaJEFormProvider>
        ) : null}

        {showFooter && <Footer buttonConfig={buttonConfig} spacing={1} />}
      </Grid>
    </PresentismoContextProvider>
  );
};
