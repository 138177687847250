import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
// import { Previews } from '../models';
import { businessOptions } from './businessOptions';
const DAO_NAME = 'previews';
const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPreviews',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPreviews',
      labelKey: 'descripcion',
    },
  },
};
const optionsToUse = !businessOptions.Previews
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Previews,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Previews.hoc },
    };
const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<any>(DAO_NAME, optionsToUse);
// hooks
export const usePreviewsFetchById = fetchById;
export const usePreviewsLibbyCall = libbyCall;
export const usePreviewsDAO = libbyDAO;
export const usePreviewsLibbyFetch = libbyFetch;
// components/hoc
export const PreviewsAutocomplete = entityAutocomplete;
export const PreviewsCall = renderPropCall;
export const PreviewsById = renderPropFetchById;
export const PreviewsSelect = simpleSelect;
export const PreviewsAccordionSelector = accordionSelector;
export const PreviewsSelectedChips = selectedChips;
// context
// ByIdContext
export const usePreviewsByIdContext = useByIdContext;
export const PreviewsByIdProvider = ByIdProvider;
export const PreviewsByIdContext = ByIdContext;
// GeneralContext
export const usePreviewsContext = useGeneralContext;
export const PreviewsProvider = GeneralProvider;
export const PreviewsContext = GeneralContext;
