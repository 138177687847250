import React, { useMemo } from 'react';
import { EspaciosSimpDiscipPlan } from '../../../../../../../../app/models';
import { InnerCard } from '../../../feedbacks/commons';
import {
  ObjetivosDeAprendizaje,
  IndicadoresDeEvaluacion,
  Bibliografia,
  Ensenanzas,
  Bimestre,
} from '../info';

export const useInfoCards = (data: EspaciosSimpDiscipPlan) =>
  useMemo<InnerCard[]>(
    () => [
      {
        title: 'Propositos de enseñanza',
        render: <Ensenanzas data={data.simpDiscipPlan.ensenanza} />,
      },
      {
        title: 'Objetivos de aprendizaje',
        render: (
          <ObjetivosDeAprendizaje
            data={data.simpDiscipPlan.objetivosDeAprendizaje}
          />
        ),
      },
      {
        title: 'Indicadores de evaluación',
        render: (
          <IndicadoresDeEvaluacion
            data={data.simpDiscipPlan.indicadoresDeEvaluacion}
          />
        ),
      },
      {
        title: 'Primer bimestre',
        render: (
          <Bimestre
            data={data.simpDiscipPlan.primerBimestre}
            prefix="primerBimestre"
          />
        ),
      },
      {
        title: 'Segundo bimestre',
        render: (
          <Bimestre
            data={data.simpDiscipPlan.segundoBimestre}
            prefix="segundoBimestre"
          />
        ),
      },
      {
        title: 'Tercer bimestre',
        render: (
          <Bimestre
            data={data.simpDiscipPlan.tercerBimestre}
            prefix="tercerBimestre"
          />
        ),
      },
      {
        title: 'Cuarto bimestre',
        render: (
          <Bimestre
            data={data.simpDiscipPlan.cuartoBimestre}
            prefix="cuartoBimestre"
          />
        ),
      },
      {
        title: 'Bibliografía',
        render: <Bibliografia data={data.simpDiscipPlan.bibliografia} />,
      },
    ],
    [data],
  );
