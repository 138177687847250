import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Bloques } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'bloques';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idBloques',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idBloques',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Bloques
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Bloques,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Bloques.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Bloques>(DAO_NAME, optionsToUse);

// hooks
export const useBloquesFetchById = fetchById;
export const useBloquesLibbyCall = libbyCall;
export const useBloquesDAO = libbyDAO;
export const useBloquesLibbyFetch = libbyFetch;

// components/hoc
export const BloquesAutocomplete = entityAutocomplete;
export const BloquesCall = renderPropCall;
export const BloquesById = renderPropFetchById;
export const BloquesSelect = simpleSelect;
export const BloquesAccordionSelector = accordionSelector;
export const BloquesSelectedChips = selectedChips;

// context
// ByIdContext
export const useBloquesByIdContext = useByIdContext;
export const BloquesByIdProvider = ByIdProvider;
export const BloquesByIdContext = ByIdContext;
// GeneralContext
export const useBloquesContext = useGeneralContext;
export const BloquesProvider = GeneralProvider;
export const BloquesContext = GeneralContext;
