import React, { useEffect, useMemo, useState, useCallback } from 'react';
import moment from 'moment';
import { Button, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useRouteScreenTitle } from 'src/commons/hooks';
import { Footer, InfoTable, Loading } from 'src/commons/components';
import {
  useProyeccionLibbyFetch,
  useProyeccionEstadoLibbyFetch,
} from 'src/app/business';
import useGenerarExcel from 'src/commons/hooks/useGenerarExcel';
import { useMatriculadosReporte } from './useMatriculadosReporte';
import { useRolesContext } from 'src/context/RolesContext';
import { Proyeccion } from 'src/app/models';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
import { useProyeccionMatriculacionLibbyFetch } from 'src/app/business/ProyeccionMatriculacion';
import _ from 'lodash';

const useStyles = makeStyles({
  gridRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  gridRowTop: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 50,
  },
  gridColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  gridColumnBottom: {
    display: 'flex',
    flexDirection: 'column',
    width: '40%',
    height: 90,
  },
  subtitle: {
    fontFamily: 'Open Sans',
    fontSize: '16px',
    color: '#595959',
  },
  exportButton: {
    display: 'flex',
    flexDirection: 'row',
  },
  printIcon: {
    marginRight: 5,
  },
  footer: {
    position: 'relative',
    backgroundColor: 'white',
    padding: '10px 0',
  },
});

export interface MatriculacionProyeccion {
  capacidad: string;
  seccionNombre: string;
  seccionId: number | string;
  anio: number | string;
}

interface MatriculadosScreenProps {
  matriculacion: MatriculacionProyeccion;
  setActiveMatriculacion: any;
}

export const MatriculadosScreen = ({
  matriculacion,
  setActiveMatriculacion,
}: MatriculadosScreenProps) => {
  const classes = useStyles();
  useRouteScreenTitle('Matriculados');

  const { selectedRole } = useRolesContext();
  const { localizacionId, nivel } = selectedRole;

  const [hasMadeCall, setHasMadeCall] = useState<boolean>(false);
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('apellidoNombre');
  const { cicloLectivo: cicloLectivoContext } = useColegioEfectivizadoContext();

  const MATRICULADO = 1;

  /*   const filterLocalizacion2 = useMemo(
      () => ({
        0: [{ path: 'localizacion', value: localizacionId }],
      }),
      [localizacionId],
    ); */

  /*   const { data: proyeccionEstado, reFetch: proyeccionEstadoReFetch }: any =
      useProyeccionEstadoLibbyFetch({
        filter: filterLocalizacion2,
      }); */
  const CURRENT_YEAR = cicloLectivoContext?.anio;

  const filterMatriculacionId = useMemo(
    () => ({
      0: [
        {
          path: 'seccionDestino.idSeccion',
          value: matriculacion.seccionId || -1,
        },
      ],
      1: [
        {
          path: 'cicloLectivo.anio',
          value: CURRENT_YEAR,
        },
      ],
      2: [
        {
          path: 'alumno.estadoMatricula.idEstadoMatricula',
          value: MATRICULADO,
          method: 'equals',
        },
      ],
    }),
    [CURRENT_YEAR, matriculacion.seccionId],
  );

  const { data, working, reFetch, error } =
    useProyeccionMatriculacionLibbyFetch({
      limit: 1000,
      aspect: 'matriculados_secciones_proyectadas',
      filter: filterMatriculacionId,
    });

  useEffect(() => {
    if (matriculacion.seccionId !== '' && !hasMadeCall) {
      reFetch();
      setHasMadeCall(false);
    }
  }, [hasMadeCall, matriculacion.seccionId, reFetch]);

  const columns = [
    {
      id: 'nombre',
      label: 'Apellido y nombre',
      width: '10%',
      orderById: 'nombre',
    },
    {
      id: 'documento',
      label: 'Documento',
      width: '5%',
      orderById: 'documento',
    },
    {
      id: 'establecimientoAnterior',
      label: 'Establecimiento Anterior',
      width: '5%',
      orderById: 'establecimientoAnterior',
    },
    {
      id: 'seccionAnterior',
      label: 'Sección anterior',
      width: '5%',
      orderById: 'seccionAnterior',
    },
  ];

  const handleRequestSort = useCallback(
    (newOrderBy: string, newDirection: 'asc' | 'desc') => {
      setDirection(newDirection);
      setOrderBy(newOrderBy);
    },
    [],
  );

  type buttonConfigProps = {
    title: string;
    handleOnClick: () => void;
    size: string;
    type: string;
  };

  const buttonConfig: buttonConfigProps[] = [
    {
      title: 'Regresar',
      handleOnClick: () => setActiveMatriculacion(null),
      size: 'large',
      type: 'primary',
    },
  ];

  const rows = useMemo(() => {
    data?.sort((a, b) =>
      a.alumno?.persona?.apellido > b.alumno?.persona?.apellido ? 1 : -1,
    );
    return (
      data?.map((row: Proyeccion) => ({
        nombre: `${row.alumno?.persona?.apellido}, ${row.alumno?.persona?.nombre}`,
        documento: row?.alumno?.persona?.documento,
        establecimientoAnterior: row?.seccionOrigen
          ? row?.seccionOrigen?.localizacion?.descripcion
          : 'No posee seccion origen.',
        seccionAnterior: row?.seccionOrigen
          ? row?.seccionOrigen?.nombreSeccion
          : 'No posee seccion origen.',
      })) || []
    );
  }, [data]);
  const generateExcel = useGenerarExcel();

  const { excelData } = useMatriculadosReporte(error ? [] : data);

  const handleDownload = useCallback(async () => {
    if (excelData.data?.length > 0)
      await generateExcel(
        excelData,
        `Reporte matriculados - ${moment().format('DD-MM-YYYY')}`,
      );
  }, [generateExcel, excelData]);

  const rowsSorted = useMemo(() => {
    const rowsCloned = _.cloneDeep(rows);
    rowsCloned.sort((a, b) => {
      const _orderBy = (obj: Proyeccion) => {
        return _.get(obj, orderBy);
      };
      return direction === 'desc'
        ? _orderBy(a)?.localeCompare(_orderBy(b))
        : _orderBy(b)?.localeCompare(_orderBy(a));
    });

    return rowsCloned;
  }, [rows, orderBy, direction]);
  return working ? (
    <Loading />
  ) : (
    <>
      <div className={classes.gridColumn}>
        <div className={classes.gridRowTop}>
          <div>
            <p className={classes.subtitle}>
              <b>Ciclo lectivo: </b>
              {matriculacion.anio}
            </p>
          </div>

          <div className={classes.exportButton}>
            <Button
              color="primary"
              variant="contained"
              component="label"
              fullWidth
              onClick={handleDownload}
              disabled={data?.length === 0 || error}
            >
              Descargar
            </Button>
          </div>
        </div>

        <div className={classes.gridRow}>
          <div className={classes.gridColumnBottom}>
            <div>
              <p className={classes.subtitle}>
                <b>Sección: </b>
                {matriculacion?.seccionNombre || ''}
              </p>
            </div>

            <div>
              <p className={classes.subtitle}>
                <b>Capacidad: </b>
                {matriculacion?.capacidad || ''}
              </p>
            </div>
          </div>

          <div className={classes.gridColumnBottom}>
            <div>
              <p className={classes.subtitle}>
                <b>Vacantes confirmadas: </b>
                {data?.length || ''}
              </p>
            </div>

            <div>
              <p className={classes.subtitle}>
                <b>Vacantes libres: </b>
                {Number(matriculacion?.capacidad) - (data?.length || 0) || ''}
              </p>
            </div>
          </div>
        </div>
      </div>

      <Grid>
        {rows.length !== 0 && !working ? (
          <Grid container direction="column" style={{ minHeight: '100vh' }}>
            <Grid item style={{ flexGrow: 0 }}>
              <InfoTable
                working={working}
                rows={rowsSorted}
                columns={columns}
                rowIdKey="seccion"
                direction={direction}
                onSortChange={handleRequestSort}
              />
            </Grid>
            <Grid item className={classes.footer}>
              <Footer buttonConfig={buttonConfig} />
            </Grid>
          </Grid>
        ) : !working ? (
          <>
            <Grid
              container
              justifyContent="center"
              alignItems="center"
              style={{ height: '400px' }}
            >
              <Typography
                style={{
                  fontSize: '40px',
                  marginTop: '50px',
                  color: 'gray',
                  fontWeight: 'bold',
                }}
              >
                Realice una proyección sobre esta sección para visualizar los
                alumnos.
              </Typography>
            </Grid>
            <Grid className={classes.footer}>
              <Footer buttonConfig={buttonConfig} />
            </Grid>
          </>
        ) : null}
      </Grid>
    </>
  );
};
