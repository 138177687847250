import React, { useMemo, useEffect, useState } from 'react';
import { Grid, TextField } from '@material-ui/core';
import { SimpleSelect } from 'src/commons';
import {
  useEspacioCurricularSeccionLibbyFetch,
  useSecundarioCustomLibbyFetch,
} from 'src/app/business';
import { useGetRows } from '../../hooks';
import { isEqual } from 'lodash';
import {
  SelectorSegundoBimestre,
  SelectorTercerBimestre,
  SelectorCuartoBimestre,
  SelectorPrimerBimestre,
} from '../../components/CalificacionesSelector/CalificacionesSelector';
import { SecundarioCustom } from 'src/app/models';
import { CalificacionesFilter } from '../';
import { setSearchParamsMultipleWords } from 'src/utils/setSearchParamsMultipleWords';
import { useColegioEfectivizadoContext } from '../../context/EfectivizacionProvider';
import { useParams } from 'react-router';

const participacionMock = [{ descripcion: 'No' }, { descripcion: 'Sí' }];

const ppiMock = [
  { descripcion: 'No', id: false },
  { descripcion: 'Sí', id: true },
];

export const useCalificacionesSecundarioRows = (
  filter: CalificacionesFilter,
  idEspacioCurricular: string,
  fromEC?: boolean,
) => {
  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();
  const [readyToClose, setReadyToClose] = useState<boolean>(false);
  const { periodo } = useParams<{ periodo: string }>();

  // el filtro para cuando entramos por la lateral
  const ecsFilterLateral = useMemo(
    () => ({
      0: [
        {
          path: 'idEspacioCurricularSeccion',
          value: filter?.espacioCurricularSeccion ?? idEspacioCurricular,
        },
      ],
    }),
    [filter, idEspacioCurricular],
  );

  // para cuando entramos desde los ECs
  const ecsFilter = useMemo(
    () => ({
      0: [
        {
          path: 'seccion.idSeccion',
          value: filter?.seccion,
        },
      ],
      1: [
        {
          path: 'espacioCurricular.idEspacioCurricular',
          value: idEspacioCurricular,
        },
      ],
    }),
    [idEspacioCurricular, filter],
  );

  const {
    data: espacioCurricularSecciones = [],
    reFetch: recall,
    working: ecsWorking,
  } = useEspacioCurricularSeccionLibbyFetch({
    limit: 100,
    filter: idEspacioCurricular ? ecsFilter : ecsFilterLateral,
    aspect: 'calificacionesSecundariaRows',
    enabled:
      Boolean(filter.seccion) &&
      (Boolean(filter.espacioCurricularSeccion) ||
        Boolean(idEspacioCurricular)),
  });
  useEffect(() => {
    if (idEspacioCurricular && filter.seccion) {
      recall();
    }
  }, [idEspacioCurricular, filter.seccion, recall]);

  const ecs = useMemo(() => {
    return espacioCurricularSecciones[0]?.idEspacioCurricularSeccion;
  }, [espacioCurricularSecciones]);

  const filterParams = useMemo(
    () => ({
      0: [
        {
          path: 'espacioCurricularSeccion',
          value: ecs ?? filter.espacioCurricularSeccion,
        },
      ],
      1: [{ path: 'calificacion.periodo', value: filter.periodo }],
    }),
    [filter.espacioCurricularSeccion, filter.periodo, ecs],
  );

  const enableSecundarioCustom = useMemo(() => {
    const isFilterValid =
      (Boolean(filter.periodo) && ecs !== undefined) ||
      Boolean(filter.espacioCurricularSeccion);

    return (
      espacioCurricularSecciones.length > 0 && isFilterValid && !ecsWorking
    );
  }, [
    espacioCurricularSecciones,
    filter.periodo,
    ecs,
    filter.espacioCurricularSeccion,
    ecsWorking,
  ]);

  const fetchResponse = useSecundarioCustomLibbyFetch({
    aspect: 'default',
    filter: filterParams,
    limit: 100,
    enabled: enableSecundarioCustom,
  });

  const { data = [] } = fetchResponse;

  const {
    rows: _rows = [],
    changeRow,
    working,
    reFetch,
    hasCalifications,
  } = useGetRows(fetchResponse, filter.search);

  const isClosed = useMemo(
    () => _rows.some((row: any) => row.nota.abierto === false),
    [_rows],
  );

  useEffect(() => {
    const submitRows = _rows.filter((row: SecundarioCustom) => {
      let isUpdated = false;
      const currentCalification = data.find(
        (d: SecundarioCustom) => d.idCalificacion === row.idCalificacion,
      );
      if (currentCalification?.nota) {
        isUpdated =
          !isEqual(currentCalification?.nota?.data, row.nota?.data) ||
          !isEqual(
            currentCalification.calificacionAnual,
            row.calificacionAnual,
          );
      } else if (Object.keys(row.nota?.data).length) {
        isUpdated = true;
      }
      return isUpdated;
    });
    setReadyToClose(!submitRows.length);
  }, [_rows, data]);

  const optionsValidation = useMemo(() => {
    const espacioCurricularName =
      espacioCurricularSecciones[0]?.espacioCurricular?.descripcion
        ?.trim()
        ?.toLowerCase() || '';

    const validNames = [
      'espacios de definición institucional',
      'tutoría',
      'tutoría 1',
      'tutoría 2',
      'espacio de definición institucional',
      'edi 1',
      'edi 2',
      'edi 3',
      'edi 4',
    ];

    const validPeriods = [1, 3, 7, 8];

    const isValidName = validNames.includes(espacioCurricularName);
    const isValidPeriod = validPeriods.includes(filter.periodo);

    const optionsValidation = isValidName && isValidPeriod;

    return optionsValidation;
  }, [espacioCurricularSecciones, filter.periodo]);

  const rows = useMemo(() => {
    const isDisabled: boolean = _rows.some(
      (c) => c.nota?.abierto !== undefined && c.nota?.abierto === false,
    );
    return _rows.map((row: SecundarioCustom, index: number) => {
      // fetch from db

      return {
        nombre: `${row?.apellido}, ${row?.nombre}`,
        ppi: (
          <Grid style={{ width: 200 }}>
            <SimpleSelect
              placeholder="Seleccione una opción"
              labelKey="descripcion"
              valueKey="id"
              content={ppiMock}
              value={row.nota?.data?.ppi}
              name="ppi"
              key="idPpi"
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          </Grid>
        ),
        participacion:
          Number(periodo) === 3 ||
          Number(periodo) === 4 ||
          filter.periodo === 8 ||
          fromEC ? (
            <TextField
              value={row.nota?.data?.participacion}
              fullWidth
              name="participacion"
              onChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          ) : (
            <Grid style={{ width: 200 }}>
              <SimpleSelect
                placeholder="Seleccione una opción"
                labelKey="descripcion"
                valueKey="descripcion"
                content={participacionMock}
                value={row.nota?.data?.participacion}
                name="participacion"
                key="idParticipacion"
                handleChange={(event) => changeRow(event, index)}
                disabled={isDisabled}
              />
            </Grid>
          ),
        valoracion: (
          <TextField
            value={row.nota?.data?.valoracion}
            fullWidth
            name="valoracion"
            onChange={(event) => changeRow(event, index)}
            disabled={isDisabled}
            inputProps={{
              maxLength: 100,
              autoComplete: 'off',
            }}
          />
        ),
        calificacion: (
          <Grid style={{ width: 200 }}>
            {filter.periodo === 1 ? (
              <SelectorPrimerBimestre
                handleChange={(event) => changeRow(event, index)}
                isDisabled={isDisabled}
                value={String(row.nota?.data?.calificacion)}
                name="calificacion"
                optionsValidation={optionsValidation}
                periodo={filter.periodo}
              />
            ) : filter.periodo === 3 || filter.periodo === 8 ? (
              <SelectorSegundoBimestre
                handleChange={(event) => changeRow(event, index)}
                isDisabled={isDisabled}
                value={String(row.nota?.data?.calificacion)}
                name="calificacion"
                periodo={filter.periodo}
                optionsValidation={optionsValidation}
              />
            ) : (
              <SelectorTercerBimestre
                handleChange={(event) => changeRow(event, index)}
                isDisabled={isDisabled}
                value={String(row.nota?.data?.calificacion)}
                name="calificacion"
                optionsValidation={optionsValidation}
                periodo={filter.periodo}
              />
            )}
          </Grid>
        ),
        ...(filter.periodo === 8 && {
          calificacionAnual: (
            <SelectorCuartoBimestre
              handleChange={(event) => changeRow(event, index)}
              isDisabled={isDisabled}
              value={row.calificacionAnual}
              name="calificacionAnual"
              optionsValidation={optionsValidation}
            />
          ),
          anualDefinitiva:
            row.calificacionAnual !== 'Adeuda' ? row.calificacionAnual : '',
        }),
      };
    });
  }, [periodo, _rows, filter.periodo, optionsValidation, changeRow, fromEC]);

  return {
    rows,
    working,
    _rows,
    data,
    reFetch,
    readyToClose,
    isClosed,
    ecs,
    hasCalifications,
    espacioCurricularSeccion: espacioCurricularSecciones,
    ecsWorking,
  };
};
