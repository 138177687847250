import { Nodo } from 'src/app/models/orientaciones';
import { IBloque } from './acciones/types';

export enum Dias {
  LUNES = 0,
  MARTES = 1,
  MIÉRCOLES = 2,
  JUEVES = 3,
  VIERNES = 4,
  SÁBADO = 5,
  DOMINGO = 6,
}

export enum Turnos {
  MAÑANA = 1,
  TARDE = 2,
  NOCHE = 3,
  VESPERTINO = 4,
  DOBLE = 5,
}

export const formatSearchDias = (value: any) => {
  if ('lunes'.includes(value.toLowerCase())) return 0;
  else if ('martes'.includes(value.toLowerCase())) return 1;
  else if ('miercoles'.includes(value.toLowerCase().replace('é', 'e')))
    return 2;
  else if ('jueves'.includes(value.toLowerCase())) return 3;
  else if ('viernes'.includes(value.toLowerCase())) return 4;
  else if ('sabado'.includes(value.toLowerCase().replace('á', 'a'))) return 5;
  else if ('domingo'.includes(value.toLowerCase())) return 6;
  else return value;
};

export const validateCupoDisponible = (
  cupoEstatal: string | number,
  cupoPrivado: string | number,
  cupoTotalGrupo: number,
  tipoCupo: string,
) => {
  const cupoCerradoCombinado =
    Number(cupoEstatal || 0) + Number(cupoPrivado || 0);
  const cupoAbierto =
    tipoCupo === 'Cerrado'
      ? 0
      : tipoCupo === 'Mixto'
      ? cupoTotalGrupo - cupoCerradoCombinado
      : tipoCupo === 'Abierto'
      ? cupoTotalGrupo
      : 0;
  const cupoDisponible =
    tipoCupo === 'Cerrado'
      ? cupoTotalGrupo - cupoCerradoCombinado
      : tipoCupo === 'Mixto'
      ? cupoTotalGrupo -
        cupoCerradoCombinado -
        (cupoAbierto > 0 ? cupoAbierto : 1)
      : 0;

  return cupoDisponible >= 0;
};

export const makeArbolNodes = (nodos: Nodo[]) => {
  const bloques: IBloque[] = [];

  nodos.forEach((row) => {
    const idNodo = row.idNodo;
    const bloque = row.bloque;
    const eje = row.eje;
    const unidad = row.unidad;
    const contenido = row.contenido;

    // Buscamos el bloque correspondiente en la estructura de `bloques`
    let bloqueExistente = bloques.find((item) => {
      return item.idBloque === bloque.idBloque;
    });

    if (!bloqueExistente) {
      // Si el bloque no existe, lo creamos con sus propiedades y el array de ejes
      bloqueExistente = {
        ...bloque,
        ejes: [],
        idNodo,
      };
      bloques.push(bloqueExistente);
    }

    // Buscamos el eje correspondiente en la estructura del bloque
    let ejeExistente = bloqueExistente.ejes.find((item) => {
      return item.idEje === eje.idEje;
    });

    if (!ejeExistente) {
      // Si el eje no existe, lo creamos con su propiedad y el array de unidades
      ejeExistente = {
        ...eje,
        unidades: [],
      };
      bloqueExistente.ejes.push(ejeExistente);
    }

    // Buscamos la unidad correspondiente en la estructura del eje
    let unidadExistente = ejeExistente.unidades.find((item) => {
      return item.idUnidad === unidad.idUnidad;
    });

    if (!unidadExistente) {
      // Si la unidad no existe, la creamos con su propiedad y el array de contenidos
      unidadExistente = {
        ...unidad,
        contenidos: [],
      };
      ejeExistente.unidades.push(unidadExistente);
    }

    // Agregamos el contenido al array de contenidos de la unidad
    unidadExistente.contenidos.push({ ...contenido });
  });

  return bloques;
};
