import React from 'react';
import { makeStyles } from '@material-ui/core';
import { ValidationError } from 'joi';
import { AnyObject } from 'src/commons/types';

const useStyles = makeStyles({
  error: {
    color: 'red',
    marginTop: '6px',
    fontSize: '0.9em',
  },
});

type Props = {
  errors?: AnyObject;
  name: string;
};

export const SmallError = ({ errors, name }: Props) => {
  const classes = useStyles();

  return errors !== undefined && name in errors ? (
    <small className={classes.error}>{errors[name]}</small>
  ) : null;
};
