import { PPSFormValues } from './types';

const genericValidation = <T>(
  errors: Partial<PPSFormValues>,
  values: Partial<PPSFormValues>,
  tab: string,
) => {
  for (const [pregunta, valor] of Object.entries(values)) {
    if (!['informe', 'observaciones'].includes(pregunta) && !Boolean(valor)) {
      errors = {
        ...errors,
        [tab]: {
          ...errors[tab],
          [pregunta]: 'Debe completar el campo',
        },
      };
    }
  }

  return errors;
};

export const validateForm =
  (currentTab: string) => (values: Partial<PPSFormValues>) => {
    let errors: any = {};

    if (Object.keys(values).length === 0) {
      return {
        todos: 'El formulario está vacio',
      };
    }

    for (const [tab, fields] of Object.entries(values)) {
      if (fields !== null) {
        switch (tab) {
          case 'antecedentes':
            if ((fields as PPSFormValues['antecedentes']).antecedentes === '') {
              errors = {
                ...errors,
                antecedentes: {
                  antecedente: 'Debe completar el campo',
                },
              };
            }
            break;
          case 'trayectoria':
            errors = validateTrayectoriaTab(
              errors,
              fields as PPSFormValues['trayectoria'],
            );
            break;
          case 'intervenciones':
            errors = validateIntervencionesTab(
              errors,
              fields as PPSFormValues['intervenciones'],
              values.intervencionesAdjunto,
            );
            break;
          case 'jornada':
            errors = validateJornadaExtTab(
              errors,
              fields as PPSFormValues['jornada'],
            );
            break;
          default:
            if (
              !['antecedentesAdjunto', 'intervencionesAdjunto'].includes(tab)
            ) {
              errors = genericValidation(errors, fields, tab);
            }
            break;
        }
      }
    }
    return currentTab !== '' ? errors[currentTab] : {};
  };

const validateTrayectoriaTab = (
  errors: { trayectoria: PPSFormValues['trayectoria'] },
  fields: Partial<PPSFormValues['trayectoria']>,
) => {
  type CURRENT_TYPE = PPSFormValues['trayectoria'];

  for (const [key, value] of Object.entries(fields)) {
    switch (key) {
      case 'cuales':
        if (
          (fields as CURRENT_TYPE)?.requirio?.toLocaleLowerCase() === 'si' &&
          ((Array.isArray(value) && value.length === 0) || !Boolean(value))
        ) {
          errors = {
            ...errors,
            trayectoria: {
              ...errors.trayectoria,
              [key]: 'Debe especificar las configuraciones de apoyo',
            },
          };
        }
        break;
      case 'cualesAjustes':
      case 'ajustesAreas':
        const emptyFields =
          (Array.isArray((fields as CURRENT_TYPE)?.cualesAjustes) &&
            (fields as CURRENT_TYPE)?.cualesAjustes.length === 0) ||
          (!Boolean((fields as CURRENT_TYPE)?.cualesAjustes) &&
            ((Array.isArray((fields as CURRENT_TYPE)?.ajustesAreas) &&
              (fields as CURRENT_TYPE)?.cualesAjustes.length === 0) ||
              !Boolean((fields as CURRENT_TYPE)?.ajustesAreas)));
        if (
          (fields as CURRENT_TYPE)?.ajustesRazonables?.toLocaleLowerCase() ===
            'si' &&
          emptyFields
        ) {
          errors = {
            ...errors,
            trayectoria: {
              ...errors.trayectoria,
              cualesAjustes: 'Debe especificar cuáles ajustes',
              ajustesAreas: 'Debe especificar las áreas de ajustes',
            },
          };
        }
        break;
      case 'contenidos':
        if (
          (fields as CURRENT_TYPE)?.requirioacompañada?.toLocaleLowerCase() ===
            'sí' &&
          !Boolean((fields as CURRENT_TYPE)?.contenidos)
        ) {
          errors = {
            ...errors,
            trayectoria: {
              ...errors.trayectoria,
              [key]: 'Debe especificar los contenidos nodales',
            },
          };
        }
        break;
      default:
        if ('observaciones' !== key && !Boolean(value)) {
          errors = {
            ...errors,
            trayectoria: {
              ...errors.trayectoria,
              [key]: 'Debe completar el campo',
            },
          };
        }
        break;
    }
  }

  return errors;
};

const validateIntervencionesTab = (
  errors: { intervenciones: PPSFormValues['intervenciones'] },
  fields: Partial<PPSFormValues['intervenciones']>,
  adjunto: {
    file: string | File;
    name: string;
  },
) => {
  type CURRENT_TYPE = PPSFormValues['intervenciones'];
  if (
    (fields as CURRENT_TYPE).derivacion?.toLocaleLowerCase() === 'sí' &&
    Object.keys(fields.informe).length === 0 &&
    typeof adjunto.files === 'string'
  ) {
    errors = {
      ...errors,
      intervenciones: {
        ...errors.intervenciones,
        adjuntoNombre: 'Debe cargar el archivo',
      },
    };
  }

  if (!Boolean((fields as CURRENT_TYPE).derivacion)) {
    errors = {
      ...errors,
      intervenciones: {
        ...errors.intervenciones,
        derivacion: 'Debe completar el campo',
      },
    };
  }
  return errors;
};

const validateJornadaExtTab = (
  errors: { jornada: PPSFormValues['jornada'] },
  fields: Partial<PPSFormValues['jornada']>,
) => {
  type CURRENT_TYPE = PPSFormValues['jornada'];

  if (
    (fields as CURRENT_TYPE).participa?.toLocaleLowerCase() === 'sí' &&
    !Boolean((fields as CURRENT_TYPE).cual)
  ) {
    errors = {
      ...errors,
      jornada: {
        ...errors.jornada,
        cual: 'Debe indicar los espacios',
      },
    };
  }

  if (!Boolean(fields.participa)) {
    errors = {
      ...errors,
      jornada: {
        ...errors.jornada,
        participa: 'Debe completar el campo',
      },
    };
  }

  return errors;
};

export const formIsValid = (values: PPSFormValues) => {
  const validActitud = [
    values.actitud?.puedeOrganizarse,
    values.actitud?.cumple,
    values.actitud?.consulta,
    values.actitud?.demuestra,
    values.actitud?.logra,
    values.actitud?.manifiesta,
  ].every(Boolean);

  const isNo = (value?: string) => value?.toLocaleLowerCase() === 'no';
  const isSi = (value?: string) => value?.toLocaleLowerCase() === 'sí';

  const validTrayectoria = (function () {
    const { trayectoria } = values;
    if (trayectoria === null) return false;
    const requirioStage =
      (isSi(trayectoria.requirio) && trayectoria.cuales.length > 0) ||
      isNo(trayectoria.requirio);
    const requirioAjustesStage =
      (isSi(trayectoria.ajustesRazonables) &&
        Boolean(trayectoria.cualesAjustes) &&
        Boolean(trayectoria.ajustesAreas)) ||
      isNo(trayectoria.ajustesRazonables);

    const promocionStage =
      (isSi(trayectoria.requirioacompañada) &&
        Boolean(trayectoria.contenidos)) ||
      isNo(trayectoria.requirioacompañada);

    const proyectoStage =
      (isSi(trayectoria.requiriopedagogico) &&
        Boolean(trayectoria.interrumpida)) ||
      isNo(trayectoria.requiriopedagogico);

    const others = Boolean(trayectoria.destaca) && Boolean(trayectoria.interes);

    return (
      requirioStage &&
      requirioAjustesStage &&
      promocionStage &&
      proyectoStage &&
      others
    );
  })();

  const validConvivencia = [
    values.convivencia?.acude,
    values.convivencia?.mantiene,
    values.convivencia?.respeta,
    values.convivencia?.vincula,
  ].every(Boolean);

  const validVinculo = [
    values.vinculo?.acompaña,
    values.vinculo?.participa,
    values.vinculo?.adulto,
  ].every(Boolean);

  const validIntervenciones =
    (isSi(values.intervenciones?.derivacion) &&
      (values.intervencionesAdjunto?.name !== '' ||
        values.intervenciones?.informe?.filename !== '')) ||
    isNo(values.intervenciones?.derivacion);

  const validAntecedentes = Boolean(values.antecedentes?.antecedentes);
  const validJE =
    (isSi(values.jornada?.participa) && Boolean(values.jornada?.cual)) ||
    isNo(values.jornada?.participa);

  return (
    validActitud &&
    validTrayectoria &&
    validConvivencia &&
    validVinculo &&
    validIntervenciones &&
    validAntecedentes &&
    validJE
  );
};
