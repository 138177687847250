import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class ConfirmacionMatriculaDAO extends LibbyFetchDAO {
  constructor() {
    super('confirmacion_matricula', 'idConfirmacionMatricula');
  }

  getAll() {
    return this.query().equals('confMatCriterio.enabled', true).run();
  }
}
