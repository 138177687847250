import React, { useCallback, useMemo } from 'react';
import { Grid, TableRow, Tooltip } from '@material-ui/core';
import { Dias } from '../utils';
import moment from 'moment';
import { useOfertasDAO } from 'src/app/business/acap/Ofertas';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useSnackbar } from 'notistack';
import { AccionOrientacion, OfertaGrupo, Ofertas } from 'src/app/models';
import {
  InfoTableColumn,
  OptionsModal,
  OptionsModalOption,
} from '../../../../commons';
import { OfertaStep } from '../tabs/oferta';
import { CURRENT_CICLO_LECTIVO } from '../tabs';

const formatterRowDetail = (row: Ofertas) => {
  let rowGroupDetail: (OfertaGrupo & {
    turno?: string;
    cupoGrupo?: number;
  })[] = [];
  row?.turnos?.forEach((turno) => {
    const newGrupos: (OfertaGrupo & {
      turno?: string;
      cupoGrupo?: number;
    })[] = [];
    turno.grupos.forEach((grupo) => {
      let moreKeys: OfertaGrupo & {
        turno?: string;
        cupoGrupo?: number;
      } = { ...grupo };
      moreKeys['turno'] = turno.turno.descripcionTurno;
      moreKeys['cupoGrupo'] = turno.cupo / turno.cantGrupos;
      newGrupos.push(moreKeys);
    });
    rowGroupDetail = [...rowGroupDetail, ...newGrupos];
  });
  const rowGroupDetailFormatted = rowGroupDetail
    .sort((a, b) => Number(a.idOfertaGrupo) - Number(b.idOfertaGrupo))
    .map((group, index) => ({
      ...group,
      organizacion: row?.accion?.organizacion?.nombre,
      Orientacion: row?.accion?.accionOrientaciones,
      accion: row?.accion?.nombre,
      nombreGrupo: `Grupo ${index + 1}`,
    }));

  return rowGroupDetailFormatted;
};

interface OfertasOptionModalProps {
  oferta: Ofertas;
  setOfertaStep: React.Dispatch<React.SetStateAction<OfertaStep>>;
  reFetchOferta: () => void;
}
const OfertasOptionModal = ({
  oferta,
  setOfertaStep,
  reFetchOferta,
}: OfertasOptionModalProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const OfertasDAO = useOfertasDAO();

  const handleViewAction = useCallback(
    (row: Ofertas) => {
      setOfertaStep((prevState) => ({
        ...prevState,
        enabledDetailTable: true,
        rowDetail: formatterRowDetail(row),
      }));
    },
    [setOfertaStep],
  );

  const handleDeleteAction = useCallback(
    async (row: Ofertas) => {
      const confirm = await confirmDialog.show({
        title: 'Eliminación de oferta',
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
        content: '¿Esta seguro que desea eliminar la oferta?',
      });
      if (confirm) {
        try {
          await OfertasDAO.remove(row);
          enqueueSnackbar('Se ha eliminado con exito', {
            variant: 'success',
          });
          reFetchOferta();
        } catch (e) {
          console.log(e);
          enqueueSnackbar('Error al eliminar', {
            variant: 'error',
          });
        }
      }
    },
    [OfertasDAO, reFetchOferta, enqueueSnackbar],
  );

  const handleEditAction = useCallback(
    (row: Ofertas) => {
      setOfertaStep((prevState: any) => ({
        ...prevState,
        enabledCreating: true,
        rowCreating: row,
      }));
    },
    [setOfertaStep],
  );

  const handleDuplicateAction = useCallback(
    (row: Ofertas) => {
      const { idOferta, turnos, ...rest } = row;

      const _turnos = turnos.map((t) => {
        const { idOfertaTurno, grupos, ...restT } = t;
        const _grupos = grupos.map((g) => {
          const { idOfertaGrupo, jornadas, ...restG } = g;
          const _jornada = jornadas.map((j) => {
            const { idGrupoJornada, ...restJ } = j;
            return { ...restJ };
          });
          return { ...restG, jornadas: _jornada };
        });
        return { ...restT, grupos: _grupos };
      });
      setOfertaStep((prevState: any) => ({
        ...prevState,
        enableDuplicate: true,
        rowCreating: { ...rest, turnos: _turnos },
      }));
    },
    [setOfertaStep],
  );

  const options = useMemo<OptionsModalOption<Ofertas>[]>(
    () => [
      {
        label: 'Ver oferta',
        onClick: handleViewAction,
      },
      {
        label: 'Editar oferta',
        onClick: handleEditAction,
        /* isDisabled:
          oferta?.accion?.cicloLectivo?.idCicloLectivo !==
            CURRENT_CICLO_LECTIVO  ,*/
      },
      {
        label: 'Duplicar oferta',
        onClick: handleDuplicateAction,
      },
      {
        label: 'Eliminar oferta',
        onClick: handleDeleteAction,
        /*  isDisabled:
          oferta?.accion?.cicloLectivo?.idCicloLectivo !==
          CURRENT_CICLO_LECTIVO, */
      },
    ],
    [
      handleViewAction,
      handleEditAction,
      handleDuplicateAction,
      handleDeleteAction,
    ],
  );
  return (
    <Grid container>
      <OptionsModal data={oferta} options={options} />
    </Grid>
  );
};

export const useOfertasColumns = (
  ofertaStep: OfertaStep,
  setOfertaStep: React.Dispatch<React.SetStateAction<OfertaStep>>,
  reFetchOferta: () => void,
) => {
  const columns = useMemo<InfoTableColumn<Ofertas>[]>(
    () => [
      {
        id: 'cicloLectivo',
        width: '2%',
        label: 'Ciclo Lectivo',
        render: (value: any, row) => row?.accion?.cicloLectivo?.anio,
        orderById: 'nombre',
        hideSortIcon: true,
      },
      {
        id: 'sector',
        label: 'Sector',
        render: (value: any, row) => row?.accion?.organizacion?.sector?.nombre,
        orderById: 'accion.organizacion.sector.nombre',
      },
      {
        id: 'organizacion',
        label: 'Organización',
        render: (value: any, row) => row?.accion?.organizacion?.nombre,
        orderById: 'accion.organizacion.nombre',
      },
      {
        id: 'accion',
        label: 'Acción',
        render: (value: any, row) => row?.accion?.nombre,
        orderById: 'accion.nombre',
      },
      {
        id: 'grupoId',
        label: 'Grupo',
        hideSortIcon: true,
        render: (_: unknown, row) => {
          const mappedIds = row?.turnos[0]?.grupos
            ?.map((grupo) => `id: ` + grupo.idOfertaGrupo)
            .join(',');
          return (
            <div style={{ whiteSpace: 'nowrap' }}>
              {mappedIds?.split(',').map((id, index) => (
                <div key={index}>{id}</div>
              ))}
            </div>
          );
        },
      },
      {
        id: 'orientacionId',
        label: 'Orientación',
        hideSortIcon: true,
        render: (_: unknown, row) => {
          const mappedOrientaciones = row?.accion.accionOrientaciones
            ?.map((orientacion) => orientacion.orientacion.nombre)
            .join(', ');
          return `${mappedOrientaciones}`;
        },
      },
      {
        id: 'hsAcap',
        label: 'HS ACAP',
        hideSortIcon: true,
        render: (value: any, row) => row?.accion?.horas,
      },
      {
        id: 'cupoTotal',
        label: 'Cupo total',
        hideSortIcon: true,
        render: (value: any, row) => {
          let horasTotales = 0;
          for (let i = 0; i < row?.turnos?.length; i++) {
            horasTotales += Number(row?.turnos[i]?.cupo);
          }
          return horasTotales;
        },
      },
      {
        id: 'turno',
        label: 'Turno',
        hideSortIcon: true,
        render: (value: any, row) => {
          let turnosAll = '';
          for (let i = 0; i < row?.turnos?.length; i++) {
            turnosAll += `T${row?.turnos[i]?.turno?.descripcionTurno[0]}${
              row?.turnos?.length > 1 && row?.turnos?.length - 1 !== i
                ? ','
                : ''
            }`;
          }
          return turnosAll;
        },
      },
      {
        id: 'dias',
        label: 'Días',
        hideSortIcon: true,
        render: (value: any, row) => {
          let diasEnGrupo: any = [];
          let diasAll = '';
          for (let i = 0; i < row?.turnos?.length; i++) {
            row?.turnos[i]?.grupos?.forEach((grupo: any) =>
              grupo?.jornadas
                ?.sort((a: any, b: any) => (a.cupo > b.cupo ? 1 : -1))
                ?.forEach((jornada: any) => {
                  const CheckDiasEnGrupo = diasEnGrupo.filter(
                    (dia: any) => dia === jornada?.cupo,
                  );
                  if (!Boolean(CheckDiasEnGrupo.length)) {
                    diasEnGrupo.push(jornada?.cupo);
                  }
                }),
            );
          }
          diasEnGrupo
            .map((dia: any) => (Dias[dia] ? Dias[dia].slice(0, 3) : Dias[dia]))
            .forEach((dia: any, index: any) => {
              if (dia !== undefined)
                diasAll += `${dia}${
                  diasEnGrupo.length > 1 && diasEnGrupo.length - 1 !== index
                    ? ','
                    : ''
                }`;
            });
          return diasAll;
        },
      },
      {
        id: 'fechaInicio',
        label: 'Fecha inicio',
        render: (value: any, row) =>
          row?.fechaInicio === null
            ? '-'
            : moment(row?.fechaInicio).format('DD/MM/YYYY'),
        orderById: 'fechaInicio',
      },
      {
        id: 'fechaFin',
        label: 'Fecha fin',
        render: (value: any, row) =>
          row?.fechaFin === null
            ? '-'
            : moment(row?.fechaFin).format('DD/MM/YYYY'),
        orderById: 'fechaFin',
      },
      {
        id: 'fechaPublicacion',
        label: 'Fecha de publicación',
        render: (value: any, row) =>
          row?.fechaPublicacion === null
            ? '-'
            : moment(row?.fechaPublicacion).format('DD/MM/YYYY'),
        orderById: 'fechaPublicacion',
      },
      {
        id: 'icon',
        label: '',
        width: '1%',
        hideSortIcon: true,
        noSort: true,
        render: (value: any, row) => (
          <OfertasOptionModal
            oferta={row}
            setOfertaStep={setOfertaStep}
            reFetchOferta={reFetchOferta}
          />
        ),
      },
    ],
    [setOfertaStep, reFetchOferta],
  );

  const columnsDetailOrg = useMemo<InfoTableColumn<any>[]>(
    () => [
      {
        id: 'organizacion',
        label: 'Organización',
        hideSortIcon: true,
        render: (value: any, row) => row?.organizacion,
      },
      {
        id: 'accion',
        label: 'Acción',
        hideSortIcon: true,
        render: (value: any, row) => row?.accion,
      },
      // {
      //   id: 'Orientación',
      //   label: 'Orientación',
      //   hideSortIcon: true,
      //   render: (value: any, row) => {
      //     return (
      //       <div>
      //         <ul style={{ padding: 0 }}>
      //           {row?.Orientacion?.map(
      //             (orientacion: AccionOrientacion, index: number) => (
      //               <li key={index} style={{ listStyleType: 'none' }}>
      //                 {orientacion?.orientacion?.nombre}
      //               </li>
      //             ),
      //           )}
      //         </ul>
      //       </div>
      //     );
      //   },
      // },
      {
        id: 'Orientación',
        label: 'Orientación',
        hideSortIcon: true,
        render: (value: any, row) => {
          return (
            <div>
              <ul style={{ padding: 0 }}>
                {row?.Orientacion?.length > 4 ? (
                  <>
                    {row.Orientacion.slice(0, 4).map(
                      (orientacion: AccionOrientacion, index: number) => (
                        <li key={index} style={{ listStyleType: 'none' }}>
                          {orientacion?.orientacion?.nombre}
                        </li>
                      ),
                    )}
                    <li
                      style={{
                        listStyleType: 'none',
                        color: 'blue',
                      }}
                    >
                      <Tooltip
                        title={
                          <ul style={{ margin: 0, padding: 0 }}>
                            {row.Orientacion.slice(4).map(
                              (
                                orientacion: AccionOrientacion,
                                index: number,
                              ) => (
                                <li
                                  key={index}
                                  style={{ listStyleType: 'none' }}
                                >
                                  {orientacion?.orientacion?.nombre}
                                </li>
                              ),
                            )}
                          </ul>
                        }
                        placement="right"
                        arrow
                        enterTouchDelay={0}
                        enterDelay={500}
                      >
                        <span style={{ cursor: 'pointer' }}>(...)</span>
                      </Tooltip>
                    </li>
                  </>
                ) : (
                  row?.Orientacion?.map(
                    (orientacion: AccionOrientacion, index: number) => (
                      <li key={index} style={{ listStyleType: 'none' }}>
                        {orientacion?.orientacion?.nombre}
                      </li>
                    ),
                  )
                )}
              </ul>
            </div>
          );
        },
      },
      {
        id: 'ID',
        label: 'ID',
        hideSortIcon: true,
        render: (value: any, row) => row?.idOfertaGrupo,
      },
      {
        id: 'turno',
        label: 'Turno',
        hideSortIcon: true,
        render: (value: any, row) => row?.turno,
      },
      {
        id: 'grupo',
        label: 'Grupo',
        hideSortIcon: true,
        render: (value: any, row) => row?.nombreGrupo,
      },
      {
        id: 'cupo',
        label: 'Cupo',
        hideSortIcon: true,
        render: (value: any, row) => row?.cupoGrupo,
      },
      {
        id: 'fechaInicio',
        label: 'Fecha inicio',
        render: (value: any, row) =>
          row?.fechaInicio === null
            ? '-'
            : moment(row?.fechaInicio).format('DD/MM/YYYY'),
        orderById: 'fechaInicio',
        hideSortIcon: true,
      },
      {
        id: 'fechaFin',
        label: 'Fecha fin',
        render: (value: any, row) =>
          row?.fechaFin === null
            ? '-'
            : moment(row?.fechaFin).format('DD/MM/YYYY'),
        orderById: 'fechaFin',
        hideSortIcon: true,
      },
      {
        id: 'dia',
        label: 'Día',
        hideSortIcon: true,
        customStyles: { padding: '0' },
        render: (value: any, row) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {row?.jornadas?.map((jornada: any, index: any) => {
                return (
                  <TableRow
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      ...(row?.jornadas?.length > 1 &&
                      row?.jornadas?.length - 1 !== index
                        ? { borderBottom: '1px solid #DFE3E8' }
                        : {}),
                      padding: '0 16px',
                    }}
                  >
                    {Dias[jornada.cupo][0] +
                      Dias[jornada.cupo].slice(1).toLowerCase()}
                  </TableRow>
                );
              })}
            </div>
          );
        },
      },
      {
        id: 'horaInicio',
        label: 'Hora inicio',
        hideSortIcon: true,
        customStyles: { padding: '0' },
        render: (value: any, row) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {row?.jornadas?.map((jornada: any, index: any) => {
                return (
                  <TableRow
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      ...(row?.jornadas?.length > 1 &&
                      row?.jornadas?.length - 1 !== index
                        ? { borderBottom: '1px solid #DFE3E8' }
                        : {}),
                      padding: '0 16px',
                    }}
                  >
                    {moment(jornada.horaInicio, 'HH:mm:ss').format('HH:mm')}
                  </TableRow>
                );
              })}
            </div>
          );
        },
      },
      {
        id: 'horaFin',
        label: 'Hora fin',
        hideSortIcon: true,
        customStyles: { padding: '0' },
        render: (value: any, row) => {
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {row?.jornadas?.map((jornada: any, index: any) => {
                return (
                  <TableRow
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      ...(row?.jornadas?.length > 1 &&
                      row?.jornadas?.length - 1 !== index
                        ? { borderBottom: '1px solid #DFE3E8' }
                        : {}),
                      padding: '0 16px',
                    }}
                  >
                    {moment(jornada.horaFin, 'HH:mm:ss').format('HH:mm')}
                  </TableRow>
                );
              })}
            </div>
          );
        },
      },
      {
        id: 'referente',
        label: 'Referente',
        hideSortIcon: true,
        render: (value: any, row) =>
          Boolean(row?.referente?.apellido)
            ? `${row?.referente?.apellido}, ${row?.referente?.nombre}`
            : row?.referente?.nombre,
      },
      {
        id: 'Sede',
        label: 'Sede',
        hideSortIcon: true,
        render: (value: any, row) => {
          const { jornadas } = row;
          return (
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              {jornadas.map((jornada: any, index: number) => (
                <TableRow
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    padding: '5px',
                    overflow: 'hidden',
                    maxHeight: '50px',
                    whiteSpace: 'nowrap',
                    ...(row?.jornadas?.length > 1 &&
                    row?.jornadas?.length - 1 !== index
                      ? { borderBottom: '1px solid #DFE3E8' }
                      : {}),
                  }}
                >
                  {jornada.sede.direccion}
                </TableRow>
              ))}
            </div>
          );
        },
      },
      {
        id: 'Establecimiento',
        label: 'Establecimiento asignado',
        hideSortIcon: true,
        render: (value: any, row) =>
          row?.localizacion?.descripcion ? row.localizacion.descripcion : '-',
      },
    ],
    [],
  );

  return ofertaStep.enabledDetailTable ? columnsDetailOrg : columns;
};
