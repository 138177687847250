/* eslint-disable class-methods-use-this */
import { LibbyFetchDAO, Filter } from './LibbyFetchDAO';

type countBySeccion = {
  countofidalumnomovimiento: string;
  entity_id_seccion: string;
};

export class AlumnoMovimientoDAO extends LibbyFetchDAO {
  constructor() {
    super('alumno_movimiento', 'idAlumnoMovimiento');
  }

  async getTotalAmount() {
    return 0;
  }

  getCountBySeccion(): countBySeccion[] {
    return this.aspect('matriculacion_secciones_alumnoCount')
      .query()
      .aggregate()
      .groupBy('entity.id_seccion')
      .count('idAlumnoMovimiento')
      .run();
  }

  async getCountBySeccionFilter(
    filters: Filter,
    aspect: string,
  ): Promise<Promocion.CountAlumnoMovimientoResponse[]> {
    let query = this.aspect(aspect).query();
    query = this.applyFilters(query, filters);
    const response = await query
      .aggregate()
      .groupBy('entity.id_seccion')
      .count('idAlumnoMovimiento')
      .run();
    return response;
  }

  async getCountAll(filters: Filter, aspect: string) {
    let thisAspect = this.aspect(aspect);
    let query = thisAspect.query();
    query = this.applyFilters(query, filters);
    const response = await query.aggregate().count('idAlumnoMovimiento').run();
    return response;
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByAlumnoId(alumnoId: string) {
    return this.query().equals('alumno.idAlumno', alumnoId).run();
  }

  getByIdCuenta(usuarioId: string) {
    return this.query().equals('alumno.persona.cuentas', usuarioId).run();
  }
}
