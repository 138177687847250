import React, { useMemo, useEffect, useState } from 'react';
import { Loading, ROL } from 'src/commons';
import { TabBar as TabBarComp } from 'src/commons/components/TabBar';
import { useSeccionLibbyFetch } from 'src/app/business';
import { PeriodosCalificacionesParam } from '../components';
import { useParams } from 'react-router';
import { CalificacionesProvider } from '../context';
import { CalificacionesSecundario } from '../CalificacionesSecundario';
import { JornadaExtendida } from './tabs/JornadaExtendida';
import { useColegioEfectivizadoContext } from '../context/EfectivizacionProvider';
import { useRolesContext } from 'src/context/RolesContext';
import { useDispatchEspaciosCurriculares } from '../hooks/useDispatchEspaciosCurriculares';
import { useSecundarioReducerContext } from '../hooks/useSecundarioState';

export const TabBarSecundario = ({
  working: validYearsWorking,
  validYears: { anio, anioJe },
}: {
  working: boolean;
  validYears: {
    anio: {
      path: string;
      value: unknown;
    }[];
    anioJe: {
      path: string;
      value: unknown;
    }[];
  };
}) => {
  const { loading } = useDispatchEspaciosCurriculares();
  const { state } = useSecundarioReducerContext();
  const { periodo } = useParams<PeriodosCalificacionesParam>();
  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();
  const { selectedRole } = useRolesContext();
  const { data: secciones = [] } = useSeccionLibbyFetch({
    limit: 200,
    aspect: 'front-light',
  });

  const [calificacionesLoaded, setCalificacionesLoaded] = useState(false);

  useEffect(() => {
    if (!loading && !calificacionesLoaded) {
      setCalificacionesLoaded(true);
    }
  }, [loading, calificacionesLoaded]);

  const jornadaExtendidaCheck =
    !loading &&
    secciones.some((seccion) => anioJe[0].value === seccion.anio.idAnio) &&
    state.seccionesJe;

  const isJeEspecial = !loading && state.jeEspecial && !state.jeComun;

  const tabs = useMemo(() => {
    let items = [];

    if (
      calificacionesLoaded &&
      Number(selectedRole.rol) !== ROL.COORDINADOR_JE
    ) {
      items.push({
        title: 'Calificaciones',
        component: (
          <CalificacionesSecundario
            periodo={Number(periodo)}
            working={validYearsWorking}
            validYears={{ anio }}
          />
        ),
      });
    }

    if (
      calificacionesLoaded &&
      jornadaExtendidaCheck &&
      [
        ROL.PRECEPTOR,
        ROL.DIRECTIVO,
        ROL.EQUIPO_CONDUCCION,
        ROL.SUPERVISOR,
        ROL.DIRECCION_AREA,
        ROL.COORDINADOR_JE,
        ROL.DOCENTE,
      ].includes(Number(selectedRole.rol))
    ) {
      items.push({
        title: 'Jornada Extendida',
        component: (
          <JornadaExtendida
            jeAnioFilter={{ anio: anioJe }}
            isJe={
              jornadaExtendidaCheck && Number(selectedRole.rol) === ROL.DOCENTE
            }
          />
        ),
      });
    }

    if (isJeEspecial && Number(selectedRole.rol) === ROL.DOCENTE) {
      items = items.filter((item) => item.title !== 'Calificaciones');
    }

    return items;
  }, [
    calificacionesLoaded,
    jornadaExtendidaCheck,
    isJeEspecial,
    periodo,
    validYearsWorking,
    anio,
    selectedRole.rol,
    anioJe,
  ]);

  return (
    <CalificacionesProvider>
      {loading ? <Loading /> : <TabBarComp content={tabs} />}
    </CalificacionesProvider>
  );
};
