import { useEffect, useState } from 'react';
import { useEspacioCurricularDAO, useSeccionDAO } from 'src/app/business';
import { SET_ESPACIOS_CURRICULARES_SECCION } from '../reducers/constants';
import { useSecundarioReducerContext } from './useSecundarioState';

export const useDispatchEspaciosCurriculares = () => {
  const ecDAO = useEspacioCurricularDAO();
  const seccionDAO = useSeccionDAO();
  const { state, dispatch } = useSecundarioReducerContext(); // Obtener estado y dispatch del contexto

  // Estado local para indicar si la carga está en curso
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true); // Inicia la carga

      try {
        const ecData = await ecDAO.getAll(1000);
        const ecDataJE = await ecDAO.getJE(200);
        const seccionData = await seccionDAO.getAll(1000);

        if (ecData.length > 0 && seccionData.length > 0) {
          dispatch({
            type: SET_ESPACIOS_CURRICULARES_SECCION,
            payload: {
              secciones: seccionData,
              espaciosCurriculares: ecData,
              espaciosCurricularesJE: ecDataJE,
            },
          });
        }
      } catch (error) {
        console.error('Error al obtener datos:', error);
      } finally {
        setLoading(false); // Marca la carga como completada
      }
    };

    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ecDAO, seccionDAO]);

  return { loading };
};
