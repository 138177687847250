import React, { useCallback, useState } from 'react';

type Meta = {
  error: any;
  pristine: boolean;
  touched: boolean;
  dirty: boolean;
};
export type GetRenderInput = {
  onBlur: <T>(event?: React.FocusEvent<T>) => void;
  onFocus: <T>(event?: React.FocusEvent<T>) => void;
  onChange: (event: React.ChangeEvent | any) => void;
  onRemove: () => void;
};
export type GetRenderOnChange = (newValue: any) => void;
export type OnChange = (
  newValue: React.ChangeEvent | any,
  index: number,
) => void;
export type GetRender = (
  value: any,
  input: GetRenderInput,
  meta: Meta,
  index: number,
) => JSX.Element;

export interface FormMultiFieldProps {
  value: any;
  onChange: OnChange;
  index: number;
  getRender: GetRender;
  onBlur: <T>(event?: React.FocusEvent<T>) => void;
  onFocus: <T>(event?: React.FocusEvent<T>) => void;
  onRemove: (index: number) => void;
  error: any;
}

export const FormMultiField = ({
  value,
  onChange,
  index,
  getRender,
  onBlur,
  onFocus,
  onRemove,
  error,
}: FormMultiFieldProps): JSX.Element => {
  const [pristine, setPristine] = useState(true);
  const [touched, setTouched] = useState(false);
  const [dirty, setDirty] = useState(false);
  const onTempChange = useCallback(
    (newValueOrEvent) => {
      const newValue = newValueOrEvent.target
        ? newValueOrEvent.target.value
        : newValueOrEvent;
      onChange(newValue, index);
      setPristine(false);
      setTouched(true);
      setDirty(true);
    },
    [onChange, index],
  );
  const onRemoveItem = useCallback(() => onRemove(index), [onRemove, index]);
  const onTempBlur = useCallback(
    (...args: any[]) => {
      onBlur(...args);
      setPristine(false);
      setTouched(true);
      setDirty(true);
    },
    [onBlur],
  );

  return (
    <>
      {getRender(
        value,
        {
          onChange: onTempChange,
          onBlur: onTempBlur,
          onFocus,
          onRemove: onRemoveItem,
        },
        { error, pristine, touched, dirty },
        index,
      )}
    </>
  );
};
