import React, { useState, useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { InfoTable } from 'src/commons/components';
import { useLibbyFetch } from 'src/commons/hooks/useLibbyFetch';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import { AnyObject, LibbyObject } from 'src/commons';
import { primary } from 'src/theme/colors';

const headerStyles = makeStyles(() => ({
  checkBox: {
    color: primary.lightBlue,
    paddingRight: '20px',
    width: '0px',
    height: '0px',
  },
}));

const StudentSelectTableRaw = ({
  libby,
  handleSelectStudent,
  selectStudent,
  formValues,
  filterById,
}: LibbyObject) => {
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('alumno.idAlumno');

  const filterTableManagement = useMemo(
    () => ({
      filter: [
        { path: formValues.path, value: formValues.params, method: 'includes' },
      ],
      ...filterById,
    }),
    [formValues, filterById],
  );

  const paramsFetchManagement = {
    orderBy,
    direction,
    daoName: 'inscripcion_alumno',
    filter: filterTableManagement,
  };

  const { data: studentRegistration, working } = useLibbyFetch(
    libby,
    paramsFetchManagement,
  );

  const classes = headerStyles();

  const column = [
    {
      id: 'name',
      label: 'Nombre y Apellido',
      width: '25%',
      orderById: 'idAlumno.idPersona.nombre',
    },
    {
      id: 'document',
      label: 'Documento',
      width: '2%',
      orderById: 'idAlumno.idPersona.documento',
    },
    {
      id: 'establishment',
      label: 'Establecimiento de origen',
      width: '20%',
      orderById: 'idLocalizacion.idEstablecimineto.nombre',
    },
    {
      id: 'level',
      label: 'Nivel',
      width: '2%',
    },
    {
      id: 'anio',
      label: 'Año',
      width: '2%',
      orderById: 'idAnio.descripcionAnio',
    },
  ];

  const usePassRequestTableLogic = () => {
    const rows = studentRegistration.map(
      (
        {
          idInscripcionAlumno,
          alumno: {
            idAlumno,
            persona: { documento, nombre, apellido },
          },
          localizacion: { descripcion },
          anio,
          nivel: { descripcionNivel },
        }: AnyObject,
        index: number,
      ) => ({
        id: idInscripcionAlumno,
        name: (
          <Grid container alignItems="center">
            <Grid item>
              <Radio
                className={classes.checkBox}
                color="default"
                name="radio-button-demo[]"
                value={selectStudent}
                checked={selectStudent?.alumno?.idAlumno === idAlumno}
                onChange={() => handleSelectStudent(studentRegistration[index])}
              />
            </Grid>
            <Grid
              item
              style={{ maxWidth: '200px' }}
            >{`${nombre} ${apellido}`}</Grid>
          </Grid>
        ),
        document: documento,
        establishment: descripcion,
        anio: anio?.descripcionAnio,
        level: descripcionNivel,
      }),
    );
    return {
      rows,
    };
  };

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  const usePassRequest = usePassRequestTableLogic();

  return (
    <InfoTable
      working={working}
      rows={usePassRequest.rows}
      columns={column}
      orderBy={orderBy}
      direction={direction}
      onSortChange={handleRequestSort}
      rowIdKey="id"
    />
  );
};

export const StudentSelectTable = DatabaseConnector(StudentSelectTableRaw)(
  'inscripcion_alumno',
);
