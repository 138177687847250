import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { PersonaActividadExtracurricular } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'persona_actividad_extracurricular';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPersonaActividadExtracurricular',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPersonaActividadExtracurricular',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.PersonaActividadExtracurricular
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.PersonaActividadExtracurricular,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.PersonaActividadExtracurricular.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<PersonaActividadExtracurricular>(DAO_NAME, optionsToUse);

// hooks
export const usePersonaActividadExtracurricularFetchById = fetchById;
export const usePersonaActividadExtracurricularLibbyCall = libbyCall;
export const usePersonaActividadExtracurricularDAO = libbyDAO;
export const usePersonaActividadExtracurricularLibbyFetch = libbyFetch;

// components/hoc
export const PersonaActividadExtracurricularAutocomplete = entityAutocomplete;
export const PersonaActividadExtracurricularCall = renderPropCall;
export const PersonaActividadExtracurricularById = renderPropFetchById;
export const PersonaActividadExtracurricularSelect = simpleSelect;
export const PersonaActividadExtracurricularAccordionSelector =
  accordionSelector;
export const PersonaActividadExtracurricularSelectedChips = selectedChips;

// context
// ByIdContext
export const usePersonaActividadExtracurricularByIdContext = useByIdContext;
export const PersonaActividadExtracurricularByIdProvider = ByIdProvider;
export const PersonaActividadExtracurricularByIdContext = ByIdContext;
// GeneralContext
export const usePersonaActividadExtracurricularContext = useGeneralContext;
export const PersonaActividadExtracurricularProvider = GeneralProvider;
export const PersonaActividadExtracurricularContext = GeneralContext;
