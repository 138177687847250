import React, { createContext, useContext, useMemo, useEffect } from 'react';
import { AnyObject } from 'src/commons';

import {
  EspacioCurricular,
  ObjetivosDeAprendizaje,
  PropositosDeEnsenanza,
  IndicadoresDeEvaluacion,
  Recursos,
  Habilidades,
  InstrumentosDeEvaluacion,
  Area,
  ContenidoEspecifico,
  ContenidoBloque,
} from 'src/app/models';
import { useRolesContext } from 'src/context/RolesContext';
import {
  useEspacioCurricularByIdContext,
  useEspacioCurricularLibbyFetch,
} from 'src/app/business';

export type SetActiveFilter = (filter: AnyObject) => void;

export interface Result {
  objetivos: ObjetivosDeAprendizaje[];
  propositos: PropositosDeEnsenanza[];
  indicadores: IndicadoresDeEvaluacion[];
  recursos: Recursos[];
  habilidades: Habilidades[];
  instrumentos: InstrumentosDeEvaluacion[];
  espaciosCurriculares: EspacioCurricular[];
  contenidosEspecificos: ContenidoEspecifico[];
  contenidosBloques: ContenidoBloque[];
  espaciosCurricularesCustom: any[];
}

export interface ArealInterarealContextValue {
  areas?: Area[];
  espaciosCurriculares?: EspacioCurricular[];
  espacioCurricular?: any;
  idAnio?: number;
  idPlanEstudio?: number;
  workingAll?: boolean;
}

export const ArealInterarealContext =
  createContext<ArealInterarealContextValue>({
    // @ts-ignore
    data: {},
    working: false,
  });

export const useArealInterarealContext = () =>
  useContext<ArealInterarealContextValue>(ArealInterarealContext);

export interface ArealInterarealProviderProps {
  children: JSX.Element;
  areas: Area[];
  idAnio?: number;
  idPlanEstudio?: number;
}

export const ArealInterarealProvider = ({
  children,
  areas,
  idAnio = 0,
  idPlanEstudio = 0,
}: ArealInterarealProviderProps) => {
  const { selectedRole } = useRolesContext();

  const paramsfetchEspaciosCurriculares = useMemo(
    () => ({
      filter: {
        areas: areas.map((area) => ({
          path: 'materia.area',
          value: area.idArea,
        })),
        localizacion: [
          {
            path: 'localizacion',
            value: selectedRole.localizacionId,
          },
        ],
        anio: [
          {
            path: 'materia.anio',
            value: idAnio,
          },
        ],
        planEstudio: [
          {
            path: 'planEstudio',
            value: idPlanEstudio,
          },
        ],
      },
      limit: 20000,
    }),
    [areas, selectedRole, idAnio, idPlanEstudio],
  );

  const { data: espaciosCurriculares = [], working: espaciosWorking } =
    useEspacioCurricularLibbyFetch(paramsfetchEspaciosCurriculares);

  const { data: espacioCurricular, working: we } =
    useEspacioCurricularByIdContext();

  const workingAll = useMemo(
    () => espaciosWorking && we,
    [espaciosWorking, we],
  );

  const value = useMemo<ArealInterarealContextValue>(
    () => ({
      areas,
      espaciosCurriculares,
      espacioCurricular,
      idAnio,
      idPlanEstudio,
      workingAll,
    }),
    [
      areas,
      espacioCurricular,
      espaciosCurriculares,
      idAnio,
      idPlanEstudio,
      workingAll,
    ],
  );
  return (
    <ArealInterarealContext.Provider value={value}>
      {children}
    </ArealInterarealContext.Provider>
  );
};
