import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Pais } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'pais';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPais',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPais',
      labelKey: 'descripcion',
    },
    withSimpleSelect: {
      labelKey: 'descripcionPais',
      valueKey: 'idPais',
      title: 'País',
      placeholder: 'Seleccioná País',
      name: 'idPais',
    },
  },
};

const optionsToUse = !businessOptions.Pais
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Pais,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Pais.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Pais>(DAO_NAME, optionsToUse);

// hooks
export const usePaisFetchById = fetchById;
export const usePaisLibbyCall = libbyCall;
export const usePaisDAO = libbyDAO;
export const usePaisLibbyFetch = libbyFetch;

// components/hoc
export const PaisAutocomplete = entityAutocomplete;
export const PaisCall = renderPropCall;
export const PaisById = renderPropFetchById;
export const PaisSelect = simpleSelect;
export const PaisAccordionSelector = accordionSelector;
export const PaisSelectedChips = selectedChips;

// context
// ByIdContext
export const usePaisByIdContext = useByIdContext;
export const PaisByIdProvider = ByIdProvider;
export const PaisByIdContext = ByIdContext;
// GeneralContext
export const usePaisContext = useGeneralContext;
export const PaisProvider = GeneralProvider;
export const PaisContext = GeneralContext;
