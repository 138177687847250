import { Button, Grid } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { SimpleSelect, Loading } from 'src/commons';
import { useRolesContext } from 'src/context/RolesContext';
import { useOfertasGruposLibbyFetch } from 'src/app/business/acap/OfertasGrupos';
import { makeStyles } from '@material-ui/styles';
import { FiltersHeaderReferenteType, filters } from '../types';
import { Accion } from 'src/app/models/acap/Acciones';
import { Turno } from 'src/app/models';

const useStyles = makeStyles({
  buttonLoading: {
    height: '20px',
    padding: '0',
    display: 'flex',
    alignContent: 'center',
  },
});

export const FiltersHeaderReferente = ({
  workingOferta,
  isReferente,
  buscarOferta,
}: FiltersHeaderReferenteType) => {
  const {
    userInfo: { email },
  } = useRolesContext();
  const classes = useStyles();
  const location = useLocation();
  const referenteOrganizacion = location.pathname.includes(
    'referente-organizacion',
  );
  const [filters, setFilters] = useState<filters>({
    accion: '',
    turno: '',
  });

  const ofertasGruposFilter = useMemo(
    () => ({
      ...(isReferente
        ? {
            organizacion: [
              {
                path: 'ofertaTurno.oferta.accion.organizacion.idOrganizacion',
                value: isReferente?.row?.idOrganizacion,
              },
            ],
          }
        : {
            referenteActividad:
              Boolean(email) && !referenteOrganizacion
                ? [{ path: 'referente.email', value: email }]
                : [],
            referenteOrganizacion:
              Boolean(email) && referenteOrganizacion
                ? [
                    {
                      path: 'ofertaTurno.oferta.accion.organizacion.referente.email',
                      value: email,
                    },
                  ]
                : [],
          }),
    }),
    [email, referenteOrganizacion, isReferente],
  );
  const { data: OfertasGrupos = [], working: workingOfertasGrupos } =
    useOfertasGruposLibbyFetch({
      filter: ofertasGruposFilter,
      enabled: Boolean(email),
    });

  const acciones = useMemo(() => {
    let acciones: Accion[] = [];
    OfertasGrupos.forEach((ofertaGrupo) => {
      if (
        !acciones.some(
          (accion) =>
            accion?.idAccion ===
            ofertaGrupo?.ofertaTurno?.oferta?.accion?.idAccion,
        )
      )
        acciones = [...acciones, ofertaGrupo?.ofertaTurno?.oferta?.accion];
    });

    return acciones;
  }, [OfertasGrupos]);

  const turnos = useMemo(() => {
    let turnos: Turno[] = [];
    OfertasGrupos.forEach((ofertaGrupo) => {
      if (
        !turnos.some(
          (turno) =>
            turno?.idTurno === ofertaGrupo?.ofertaTurno?.turno?.idTurno,
        )
      )
        turnos = [...turnos, ofertaGrupo?.ofertaTurno?.turno];
    });

    return turnos;
  }, [OfertasGrupos]);

  return (
    <>
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        style={{ marginTop: '15px', background: '#F5F5F5', padding: '30px 0' }}
      >
        <Grid
          container
          item
          md={4}
          justifyContent="center"
          alignItems="center"
          style={{
            display: 'inline-grid',
            marginTop: '30px',
          }}
        >
          <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>Acción:</p>
          <SimpleSelect
            name="accion"
            value={filters.accion}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFilters((prev) => ({ ...prev, accion: value }));
            }}
            placeholder="Todos"
            content={workingOfertasGrupos ? [] : acciones}
            labelKey="nombre"
            valueKey="idAccion"
            customStyleContainer={{ width: '20rem' }}
          />
        </Grid>
        <Grid
          container
          item
          md={4}
          justifyContent="center"
          alignItems="center"
          style={{
            display: 'inline-grid',
            marginTop: '30px',
          }}
        >
          <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>Turno:</p>
          <SimpleSelect
            name="turno"
            value={filters.turno}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFilters((prev) => ({ ...prev, turno: value }));
            }}
            placeholder="Todos"
            content={workingOfertasGrupos ? [] : turnos}
            labelKey="descripcionTurno"
            valueKey="idTurno"
            customStyleContainer={{ width: '20rem' }}
          />
        </Grid>
        <Grid
          container
          item
          md={12}
          justifyContent="center"
          alignItems="center"
          style={{
            display: 'inline-grid',
            marginTop: '30px',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            component="label"
            style={{ width: '200px', marginTop: '30px' }}
            onClick={() => buscarOferta(filters)}
            disabled={workingOferta}
          >
            {workingOferta ? (
              <Loading className={classes.buttonLoading} />
            ) : (
              'Buscar'
            )}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
