import React, { FunctionComponent, useEffect } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CodigoVinculo } from 'src/app/models';
import { useCodigoVinculoLibbyCall } from 'src/app/business';
import customFormDialog from 'src/commons/services/customFormDialog';
import { MainButton } from 'src/commons/components/MainButton';
import { useAlumnoFetch } from '../hooks/useAlumnoFetch';

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  paragraph: {
    marginRight: '10px',
  },
  grid: {
    display: 'flex',
    alignItems: 'center',
    '& > p': {
      fontFamily: 'Open Sans',
      fontSize: '16px',
      color: '#595959',
    },
  },
});

export const Header: FunctionComponent = ({ datosInstitucion }: any) => {
  const classes = useStyles();

  const {
    data: { alumno: alumnoData },
  } = useAlumnoFetch();

  const { data, loading } = datosInstitucion;

  const {
    data: codigoPost,
    recall: recallCodigoPost,
    working: workingPost,
  } = useCodigoVinculoLibbyCall({
    methodName: 'post',
    params: alumnoData?.idAlumno,
    noAutoCall: true,
  });
  const {
    data: codigoGet,
    recall: recallCodigoGet,
    working: workingGet,
  } = useCodigoVinculoLibbyCall({
    methodName: 'getByAlumno',
    params: alumnoData?.idAlumno,
    noAutoCall: true,
  });

  // const {
  //   data: { matriculaPersonaExterna },
  // } = useMatriculaPersonaExternaFetch();
  // Se iguiala a un array vacío puesto que la funcionalidad no está implementada y por los momentos no es requerida
  const matriculaPersonaExterna: any[] = [];

  const { alumno } = data || {};
  const { persona } = alumno || {};
  const { persona: personaAlumno } = alumnoData || {};

  const name = data
    ? `${persona?.nombre} ${persona?.apellido}`
    : matriculaPersonaExterna?.persona
    ? `${matriculaPersonaExterna?.persona?.nombre} ${matriculaPersonaExterna?.persona?.apellido}`
    : `${personaAlumno?.nombre} ${personaAlumno?.apellido}`;

  const document = data
    ? persona?.documento
    : matriculaPersonaExterna?.persona
    ? matriculaPersonaExterna?.persona?.documento
    : personaAlumno?.documento;

  useEffect(() => {
    if (alumnoData) recallCodigoGet(alumnoData?.idAlumno);
  }, [recallCodigoGet, codigoPost, alumnoData]);

  const closeConfirmModal = () => customFormDialog.handleCancel();

  const generateCodigo = () => recallCodigoPost(alumnoData?.idAlumno);

  const onConfirm = () => {
    generateCodigo();
    closeConfirmModal();
  };

  const showConfirm = () => {
    customFormDialog.show({
      title: 'Confirmación',
      renderComponent: (
        <>
          <Grid container direction="column" style={{ padding: '0 24px 16px' }}>
            <Grid container direction="row">
              <Typography style={{ marginRight: '10px' }}>
                ¿Desea generar nuevamente el código?
              </Typography>
            </Grid>
            <Grid
              container
              direction="row"
              justify="flex-end"
              style={{ marginTop: 16 }}
            >
              <Button onClick={onConfirm} color="primary" variant="contained">
                Sí
              </Button>
              <Button
                onClick={closeConfirmModal}
                color="primary"
                variant="outlined"
                style={{ marginLeft: 10 }}
              >
                No
              </Button>
            </Grid>
          </Grid>
        </>
      ),
      sizeWidth: 'sm',
    });
  };

  return (
    <div className={classes.root}>
      <Grid container spacing={3}>
        <Grid item xs={4} className={classes.grid}>
          <p>
            <b>Estudiante: </b>
            {loading ? 'Cargando...' : name || ''}
          </p>
        </Grid>
        <Grid item xs={4} className={classes.grid}>
          <p>
            <b>Número de documento: </b>
            {loading ? 'Cargando...' : document || ''}
          </p>
        </Grid>
        <Grid item xs={4} className={classes.grid}>
          <p className={classes.paragraph}>
            <b>Código único de vinculación: </b>
            {workingGet || workingPost
              ? 'Cargando...'
              : codigoGet?.[0]?.codigo || ''}
          </p>
          {!(workingGet || workingPost) && (
            <MainButton
              title="Generar"
              handleOnClick={
                codigoGet?.[0]?.codigo ? showConfirm : generateCodigo
              }
            />
          )}
        </Grid>
      </Grid>
    </div>
  );
};
