import { useMemo } from 'react';
import { usePermissionContext } from '../permissionContext';

export const useActivePermissionBlocker = (blockerID: number) => {
  const { activeRules } = usePermissionContext();
  return useMemo(
    () =>
      !!activeRules.blockers.find((rulesBlock) => rulesBlock.id === blockerID),
    [activeRules, blockerID],
  );
};
