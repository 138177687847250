import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { ROL } from 'src/commons/const/rol';
import { useRolesContext } from 'src/context/RolesContext';
import {
  Filter,
  JoinedFilters,
  PublicacionesList,
  PublicacionForm,
  SinPublicaciones,
} from './components';
import { usePublicacionLibbyFetch } from '../../../../app/business';
import { Loading } from '../../../../commons/components/Loading';

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: theme.spacing(2),
  },
}));

const searchFilters = [
  'descripcion',
  'cuentas.nombreUsuario',
  'cuentas.apellidoUsuario',
];

const isBottom = (threehold = 0) => {
  const windowHeight =
    'innerHeight' in window
      ? window.innerHeight
      : document.documentElement.offsetHeight;
  const { body } = document;
  const html = document.documentElement;
  const docHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight,
  );
  const windowBottom = windowHeight + window.pageYOffset + threehold;
  return windowBottom >= docHeight;
};

export const Muro = () => {
  const [filter, setFilter] = useState<JoinedFilters>({
    publico: [],
    espacioCurricular: [],
    search: '',
  });
  const { selectedRole } = useRolesContext();
  const classes = useStyles();
  const isEstudiante = Number(selectedRole.rol) === ROL.ESTUDIANTE;
  const processedFilter = useMemo(
    () => ({
      tipo: isEstudiante
        ? [{ path: 'rol', value: ROL.ESTUDIANTE }]
        : filter.publico.map((publico) => ({
            path: 'rol',
            value: publico.idRolUsuario,
          })),
      espacioCurricular: filter.espacioCurricular.map((espacioCurricular) => ({
        path: 'idEspacioCurricular',
        value: espacioCurricular.idEspacioCurricular,
      })),
      localizacion: [
        { path: 'localizacion', value: selectedRole.localizacionId },
      ],
      nivel: [{ path: 'nivel', value: selectedRole.nivel }],
      search: !filter.search
        ? []
        : searchFilters.map((path) => ({
            path,
            value: filter.search,
            method: 'includes',
          })),
    }),
    [filter, isEstudiante, selectedRole],
  );

  // TODO: verificar filtros
  const { data, working, fetchMore, reFetch } = usePublicacionLibbyFetch({
    filter: processedFilter,
    orderBy: 'fechaCreacion',
    direction: 'desc',
  });

  const handleScroll = useCallback(() => {
    const bottom = isBottom();
    if (bottom && fetchMore) {
      fetchMore();
    }
  }, [fetchMore]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <Grid container spacing={2} className={classes.container}>
      <Grid item sm={3}>
        <Filter
          value={filter}
          onChange={setFilter}
          isEstudiante={isEstudiante}
        />
      </Grid>
      <Grid item xs>
        <Grid container direction="column" spacing={4}>
          {!isEstudiante && (
            <Grid item>
              <PublicacionForm reFetch={reFetch} />
            </Grid>
          )}
          <Grid item>
            {data && (
              <>
                {data.length ? (
                  <PublicacionesList items={data} reFetch={reFetch} />
                ) : (
                  <SinPublicaciones />
                )}
              </>
            )}
            {working && <Loading />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
