import { useMemo } from 'react';
import { Accion, Organizacion } from 'src/app/models';
import { useEstudianteContext } from '../contexts/EstudianteContext';

export const useGetFilterInfo = () => {
  const { grupos } = useEstudianteContext();
  const organizaciones = useMemo(() => {
    const orgs = new Set<Organizacion>();
    grupos.forEach((grupo) =>
      orgs.add(grupo.ofertaGrupo.ofertaTurno.oferta.accion.organizacion),
    );

    return orgs;
  }, [grupos]);

  const actividades = useMemo(() => {
    const acciones = new Set<Accion>();
    grupos.forEach((grupo) =>
      acciones.add(grupo.ofertaGrupo.ofertaTurno.oferta.accion),
    );

    return acciones;
  }, [grupos]);

  return {
    actividades,
    organizaciones,
  };
};
