import { makeStyles } from '@material-ui/core';

export const matriculationStyles = makeStyles(() => ({
  descriptionPlanStudy: {
    fontSize: '13px',
    textTransform: 'capitalize',
  },
  iconButton: {
    padding: 0,
  },
  customContainer: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    backgroundColor: '#000000A0',
    height: '100vh',
    width: '100vw',
    zIndex: 10000,
  },
  loadingMessage: {
    position: 'absolute',
    marginTop: '6em',
    fontWeight: 800,
    fontFamily: 'Open Sans',
    color: 'white',
    userSelect: 'none',
  },
}));
