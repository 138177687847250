import { PlanEstudioNivel } from 'src/app/models';
import { establecimientoReingresoNocturosYComerciales } from './establecimientoReingresoNocturosYComerciales';
import { MAPPED_PASE_ANIO_OPTIONS_SECUNDARIO } from './mappedPaseAnioOptions';

const {
  Promociona: PROMOCIONA,
  'Promoción directa': PROMOCION_DIRECTA,
  Egresa: EGRESA,
  Permanece: PERMANECE,
  'Promoción en proceso': PROMOCION_EN_PROCESO,
  'Continúa con apoyo para la promoción': CONTINUA_CON_APOYO_PARA_PROMOCION,
  'Con promoción acompañada': PROMOCION_CON_APOYO,
  'No completó el Plan de Estudio': NO_COMPLETO_PLAN_DE_ESTUDIOS,
} = MAPPED_PASE_ANIO_OPTIONS_SECUNDARIO;

interface getStatusPassFromPendientesParams {
  localizacionData: any;
  totalPendientes: number;
  numeroAnio: any;
  descripcionTurno: {descripcionTurno: string,  idTurno: number}
  secciones?: string | number;
  planEstudioNivel?: PlanEstudioNivel;
}

export const getStatusPassFromPendientes = ({
  localizacionData,
  totalPendientes,
  numeroAnio,
  descripcionTurno,
  secciones,
  planEstudioNivel,
}: getStatusPassFromPendientesParams) => {
  let statusPass: number = PROMOCION_DIRECTA.id;

  const perteneceAlArchivo =
    localizacionData[0]?.idLocalizacion in
    establecimientoReingresoNocturosYComerciales;

  const perteneceAlArchivoYNocturno =
    perteneceAlArchivo &&
    establecimientoReingresoNocturosYComerciales[
      localizacionData[0]?.idLocalizacion
    ]?.tipo === 'nocturno';

  const perteneceAlArchivoYReingreso =
    perteneceAlArchivo &&
    establecimientoReingresoNocturosYComerciales[
      localizacionData[0]?.idLocalizacion
    ]?.tipo === 'reingreso';

  const colegiosHibridos = ['20038300'];

  const perteneceEscuelasCBO = [
    '20242200',
    '20203900',
    '20203800',
    '20203300',
  ].includes(localizacionData[0]?.idLocalizacion);

  const tecnicoYBachiller = ['20091100', '20108500'].includes(
    localizacionData[0]?.idLocalizacion,
  );
  if (
    localizacionData[0]?.establecimiento?.dependenciaFuncional
      ?.idDependenciaFuncional === 32 &&
    secciones !== '80849' &&
    secciones !== '80852' &&
    secciones !== '80854' &&
    secciones !== '70222' &&
    secciones !== '70237' &&
    secciones !== '86427' &&
    secciones !== '86429' &&
    secciones !== '86431' &&
    secciones !== '96166' &&
    secciones !== '96181'
  ) {
    if (totalPendientes > 2 && numeroAnio === 1) {
      statusPass = PROMOCION_CON_APOYO.id;
    } else if (totalPendientes <= 2 && numeroAnio !== 6) {
      statusPass = PROMOCION_DIRECTA.id;
    } else if (totalPendientes === 0 && numeroAnio === 6) {
      statusPass = EGRESA.id;
    } else if (totalPendientes !== 0 && numeroAnio === 6) {
      statusPass = NO_COMPLETO_PLAN_DE_ESTUDIOS.id;
    } else if (totalPendientes > 2 && [2, 3, 4, 5].includes(numeroAnio)) {
      statusPass = PROMOCION_EN_PROCESO.id;
    }
  } else if (
    colegiosHibridos &&
    (planEstudioNivel?.planEstudio?.idPlanEstudio === 320 ||
      planEstudioNivel?.planEstudio?.idPlanEstudio === 316) &&
    numeroAnio === 4
  ) {
    if (totalPendientes !== 0) {
      statusPass = NO_COMPLETO_PLAN_DE_ESTUDIOS.id;
    } else statusPass = EGRESA.id;
  } else if (
    perteneceAlArchivoYNocturno &&
    (planEstudioNivel?.planEstudio?.idPlanEstudio === 218 ||
      planEstudioNivel?.planEstudio?.idPlanEstudio === 217)
  ) {
    if (numeroAnio === 4) {
      if (totalPendientes <= 2 && numeroAnio === 4) {
        statusPass = PROMOCION_DIRECTA.id;
      } else if (totalPendientes > 2 && [4].includes(numeroAnio)) {
        statusPass = PROMOCION_EN_PROCESO.id;
      }
    }
    if (totalPendientes !== 0 && numeroAnio === 5) {
      statusPass = NO_COMPLETO_PLAN_DE_ESTUDIOS.id;
    } else statusPass = EGRESA.id;
  } else if (
    tecnicoYBachiller &&
    planEstudioNivel?.planEstudio?.idPlanEstudio === 197 &&
    (secciones === '80849' ||
      secciones === '80852' ||
      secciones === '80854' ||
      secciones === '70222' ||
      secciones === '70237' ||
      secciones === '86427' ||
      secciones === '86429' ||
      secciones === '86431' ||
      secciones === '96166' ||
      secciones === '96181')
  ) {
    if (totalPendientes !== 0 && numeroAnio === 5) {
      statusPass = NO_COMPLETO_PLAN_DE_ESTUDIOS.id;
    } else statusPass = EGRESA.id;
  } else if (perteneceAlArchivoYNocturno && descripcionTurno.descripcionTurno === 'Noche') {
    if (totalPendientes > 2 && numeroAnio === 1) {
      statusPass = PROMOCION_CON_APOYO.id;
    } else if (totalPendientes <= 2 && numeroAnio !== 4) {
      statusPass = PROMOCION_DIRECTA.id;
    } else if ( [0,1,2].includes(totalPendientes)  && numeroAnio === 4) {
      statusPass = EGRESA.id;
    } else if (totalPendientes > 2 && numeroAnio === 4) {
      statusPass = NO_COMPLETO_PLAN_DE_ESTUDIOS.id;
    } else if (totalPendientes > 2 && [2, 3].includes(numeroAnio)) {
      statusPass = PROMOCION_EN_PROCESO.id;
    }
  } else if (
    perteneceAlArchivoYReingreso &&
    planEstudioNivel?.planEstudio?.idPlanEstudio !== 218 &&
    planEstudioNivel?.planEstudio?.idPlanEstudio !== 217
  ) {
    if (totalPendientes > 2 && numeroAnio === 1) {
      statusPass = PROMOCION_CON_APOYO.id;
    } else if (totalPendientes <= 2 && numeroAnio !== 4) {
      statusPass = PROMOCION_DIRECTA.id;
    } else if (totalPendientes <= 2 && numeroAnio === 4) {
      statusPass = EGRESA.id;
    } else if (totalPendientes > 2 && numeroAnio === 4) {
      statusPass = NO_COMPLETO_PLAN_DE_ESTUDIOS.id;
    } else if (totalPendientes > 2 && [2, 3].includes(numeroAnio)) {
      statusPass = PROMOCION_EN_PROCESO.id;
    }
  } else if (perteneceEscuelasCBO && numeroAnio === 3) {
    if (totalPendientes !== 0 && numeroAnio === 3) {
      statusPass = NO_COMPLETO_PLAN_DE_ESTUDIOS.id;
    } else statusPass = EGRESA.id;
  } else {
    if (totalPendientes > 2 && numeroAnio === 1) {
      statusPass = PROMOCION_CON_APOYO.id;
    } else if (totalPendientes <= 2 && numeroAnio !== 5) {
      statusPass = PROMOCION_DIRECTA.id;
    } else if (totalPendientes === 0 && numeroAnio === 5) {
      statusPass = EGRESA.id;
    } else if (totalPendientes !== 0 && numeroAnio === 5) {
      statusPass = NO_COMPLETO_PLAN_DE_ESTUDIOS.id;
    } else if (totalPendientes > 2 && [2, 3, 4].includes(numeroAnio)) {
      statusPass = PROMOCION_EN_PROCESO.id;
    }
  }
  return statusPass;
};
