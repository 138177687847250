import React from 'react';
import { Grid } from '@material-ui/core';
import { SimpleSelect, MainInput } from 'src/commons/components';
import { FilterTablesRegisteredTypesProps } from 'src/commons';
import { grey } from 'src/theme/colors';
import { useMatriculationContext } from 'src/screens/Private/Matriculation/context/Context';
import sortAnioSelect from 'src/screens/Private/Matriculation/passManagement/utils/sortAnioSelectContent';
import { INITIAL_YEAR } from 'src/app/business';
import { useGeneralContext } from 'src/context';

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  containerStyles: { marginTop: 50 },
  searchGrid: { marginTop: 11 },
  counterContainer: {
    paddingBottom: 0,
    paddingTop: 19,
    fontSize: 14,
    color: grey.medium,
    fontFamily: 'Open Sans',
  },
};

/* const mockCicloLectivo = [
  { idCicloLectivo: '22', anio: 2020 },
  { idCicloLectivo: '5', anio: 2021 },
  { idCicloLectivo: '23', anio: 2022 },
]; */

export const FilterTablesRegistered = ({
  year,
  turn,
  section,
  condicion,
  formValues,
  ciclosLectivos,
  search,
  handleChangeSearch,
  handleChange,
  working,
}: FilterTablesRegisteredTypesProps) => {
  const {
    generalState: {
      constantes: { TipoJornada },
    },
  } = useGeneralContext();
  const jornadas = TipoJornada.map((jornada: string) => {
    return {
      idJornada: jornada,
      descripcionJornada: jornada,
    };
  });

  const validLectiveCycles = ciclosLectivos
    ? ciclosLectivos.filter((cycle: any) => cycle.anio >= INITIAL_YEAR)
    : [];

  const {
    state: { section: contextSection, ciclo },
  } = useMatriculationContext();

  const selectedSection = section.filter(
    (sec) => sec.idSeccion === contextSection,
  );

  const newCicloLectivo = validLectiveCycles.filter(
    (ciclo) =>
      ciclo?.idCicloLectivo ===
      selectedSection[0]?.cicloLectivo?.idCicloLectivo,
  );

  const sortSeccionesSelect = (secciones: any) => {
    secciones?.sort((seccionA: any, seccionB: any) => {
      const nombreSeccionA = seccionA?.nombreSeccion
        ?.toLowerCase()
        .replace(/°+/g, '° ')
        .replace(/\s+/g, ' ')
        .replace(/lactantes+/g, '0lactantes');
      const nombreSeccionB = seccionB?.nombreSeccion
        ?.toLowerCase()
        .replace(/°+/g, '° ')
        .replace(/\s+/g, ' ')
        .replace(/lactantes+/g, '0lactantes');
      if (nombreSeccionA < nombreSeccionB) {
        return -1;
      }
      if (nombreSeccionA > nombreSeccionB) {
        return 1;
      }
      return 0;
    });
    return secciones;
  };

  return (
    <>
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        spacing={1}
        style={customStyles.containerStyles}
      >
        <Grid item xs={2}>
          <SimpleSelect
            title="Sección"
            placeholder="Seleccioná Sección"
            labelKey="nombreSeccion"
            valueKey="idSeccion"
            content={sortSeccionesSelect(section)}
            handleChange={handleChange}
            value={
              contextSection
                ? contextSection
                : formValues[`seccion.nombreSeccion`]
            }
            name="seccion.nombreSeccion"
            customStyleTitle={customStyles.customStyleTitleSelect}
            disabled={working}
          />
        </Grid>
        <Grid item xs={1.5}>
          <SimpleSelect
            name="seccion.anio"
            title="Año"
            placeholder="Seleccioná Año"
            labelKey="descripcionAnio"
            valueKey="idAnio"
            handleChange={handleChange}
            content={sortAnioSelect(year)}
            value={formValues[`seccion.anio`]}
            customStyleTitle={customStyles.customStyleTitleSelect}
            disabled={working}
          />
        </Grid>
        <Grid item xs={1.5}>
          <SimpleSelect
            title="Turno"
            placeholder="Seleccioná Turno"
            labelKey="descripcionTurno"
            valueKey="idTurno"
            content={turn}
            handleChange={handleChange}
            value={formValues[`seccion.turno`]}
            name="seccion.turno"
            customStyleTitle={customStyles.customStyleTitleSelect}
            disabled={working}
          />
        </Grid>
        <Grid item xs={2}>
          <SimpleSelect
            title="Jornada"
            placeholder="Seleccioná una Jornada"
            labelKey="descripcionJornada"
            valueKey="idJornada"
            content={jornadas}
            handleChange={handleChange}
            value={formValues[`seccion.jornada`]}
            name="seccion.jornada"
            customStyleTitle={customStyles.customStyleTitleSelect}
            disabled={working}
          />
        </Grid>
        <Grid item xs={2}>
          <SimpleSelect
            title="Ciclo Lectivo"
            placeholder="Seleccioná un Ciclo Lectivo"
            labelKey="anio"
            valueKey="idCicloLectivo"
            content={
              newCicloLectivo.length > 0 ? newCicloLectivo : validLectiveCycles
            }
            handleChange={handleChange}
            value={ciclo ? ciclo : formValues[`cicloLectivo.idCicloLectivo`]}
            name="cicloLectivo.idCicloLectivo"
            customStyleTitle={customStyles.customStyleTitleSelect}
            disabled={working}
          />
        </Grid>
        <Grid item xs={1.5}>
          <SimpleSelect
            title="Condición"
            placeholder="Seleccioná Condición"
            labelKey="descripcionCondicion"
            valueKey="idCondicion"
            content={condicion.filter(
              (c) =>
                !['de baja', 'baja'].includes(
                  c.descripcionCondicion.toLowerCase(),
                ),
            )}
            handleChange={handleChange}
            value={formValues['alumno.condicion']}
            name="alumno.condicion"
            customStyleTitle={customStyles.customStyleTitleSelect}
            disabled={working}
          />
        </Grid>
        <Grid item xs={2}>
          <MainInput
            type="search"
            handleChange={handleChangeSearch}
            placeholder="Buscar"
            name="search"
            value={search}
            fullWidth
            customStyle={{ padding: 9.5, marginTop: 20 }}
            disabled={working}
          />
        </Grid>
      </Grid>
    </>
  );
};
