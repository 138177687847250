import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { Anual } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'calificacionessecundarioanual';

const defaultOptions: PartialBusinessEntityOptions<any> = {};

const optionsToUse = !businessOptions.Secundario
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Secundario,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Secundario.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Anual>(DAO_NAME, optionsToUse);

// hooks
export const useCalificacionesSecundarioAnualFetchById = fetchById;
export const useCalificacionesSecundarioAnualLibbyCall = libbyCall;
export const useCalificacionesSecundarioAnualDAO = libbyDAO;
export const useCalificacionesSecundarioAnualLibbyFetch = libbyFetch;

// components/hoc
export const calificacionesSecundarioAnualAutocomplete = entityAutocomplete;
export const calificacionesSecundarioAnualCall = renderPropCall;
export const calificacionesSecundarioAnualById = renderPropFetchById;
export const calificacionesSecundarioAnualSelect = simpleSelect;
export const calificacionesSecundarioAnualAccordionSelector = accordionSelector;
export const calificacionesSecundarioAnualSelectedChips = selectedChips;

// context
// ByIdContext
export const useCalificacionesSecundarioAnualByIdContext = useByIdContext;
export const CalificacionesSecundarioAnualByIdProvider = ByIdProvider;
export const CalificacionesSecundarioAnualByIdContext = ByIdContext;
// GeneralContext
export const useCalificacionesSecundarioAnualContext = useGeneralContext;
export const CalificacionesSecundarioAnualProvider = GeneralProvider;
export const CalificacionesSecundarioAnualContext = GeneralContext;
