import { FormState } from './types';

export const FormStateValue: FormState = {
  idSituacion: undefined,
  tipoSituacion: '',
  motivoTipoSituacion: '',
  observaciones: [],
  range: [
    {
      endDate: new Date(),
      startDate: new Date(),
      key: 'selection',
    },
  ],
  seccionesAfectadas: [],
};
