import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class ObjetivosBloquesv2DAO extends LibbyFetchDAO {
  constructor() {
    super('objetivos_bloques', 'idObjetivoBloque');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
