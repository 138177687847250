import { MenuItem } from '../../../../commons/const/ui/menu';
import { routes } from './routes';

export const configurationAdminMenu: MenuItem = {
  key: 'configurationAdmin',
  basePath: '/configurationadmin',
  enabled: true,
  icon: 'List',
  text: 'Configuration Admin',
  children: routes,
};
