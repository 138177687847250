import { useEffect } from 'react';
import { useAlumnoLibbyCall } from 'src/app/business';
import { Alumno } from 'src/app/models';
import { useLegajoLayout } from '..';

export const useAlumnoFetch = () => {
  const { params } = useLegajoLayout();

  const {
    data: alumno,
    recall: recallAlumno,
    working,
  } = useAlumnoLibbyCall<Alumno[]>({
    methodName: 'getById',
    params,
    noAutoCall: true,
  });

  useEffect(() => {
    recallAlumno(params);
  }, [recallAlumno, params]);

  return {
    data: {
      alumno: alumno?.[0],
    },
    working,
    recall: {
      recallAlumno: () => recallAlumno(params),
    },
  };
};
