import React from 'react';
import { EspaciosArealPlan, Seccion } from 'src/app/models';
import { useSeccionContext } from 'src/app/business';
import { Grid } from '@material-ui/core';
import { HeaderFeedback } from '../../commons/components/FeedbackHeader';

export type ArealInterarealFlowHeaderProps = {
  data: EspaciosArealPlan;
};

export const ArealInterarealFlowHeader = ({
  data,
}: ArealInterarealFlowHeaderProps) => {
  const { data: secciones, working } = useSeccionContext();
  const seccionesPlan = data.arealPlan.informacionGeneral.secciones;
  const filterSecciones = seccionesPlan?.map((_item: string) => {
    const filter =
      secciones?.find((_seccion: Seccion) => _seccion.idSeccion === _item) ||
      [];
    return filter;
  });

  const formateDataHeader = {
    disciplina: data.espacioCurricular.materia.descripcion,
    area: data.espacioCurricular.materia.area.descripcion,
    orientacion: '',
    seccion: filterSecciones
      .map((section: any) => section.nombreSeccion)
      .join(', '),
    fecha: data.arealPlan.ultimaActualizacion,
    autor: `${data?.arealPlan?.cuentas?.nombreUsuario} ${data?.arealPlan?.cuentas?.apellidoUsuario}`,
    nombre: data.arealPlan.proyecto.nombre,
    descripcion: data.arealPlan.proyecto.descripcion,
  };
  return (
    <Grid container direction="column">
      {!working && <HeaderFeedback data={formateDataHeader} />}
    </Grid>
  );
};
