import { IconButton, SvgIcon, SvgIconProps } from '@material-ui/core';
import * as React from 'react';
import { useLockCellStyles } from 'src/screens/Private/Presentismo/TomaPresentismo/components/PresentismoTable/css';

export const LockSolidV1 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    {/*  <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
    <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
  </svg>
);

export const LockSolid = (props: SvgIconProps) => (
  <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
    {/*  <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
  </SvgIcon>
);

export const LockCloseSolidV2 = ({
  handleOpenDay,
  isDisabled,
}: {
  handleOpenDay: () => void;
  isDisabled: boolean;
}) => {
  const classes = useLockCellStyles();
  return (
    <IconButton
      className={classes.buttonLock}
      onClick={handleOpenDay}
      disabled={isDisabled}
    >
      <SvgIcon
        className={classes.icon}
        htmlColor={'#7A7A7A'}
        fontSize="small"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
      >
        {/*  <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
        <path d="M144 144v48H304V144c0-44.2-35.8-80-80-80s-80 35.8-80 80zM80 192V144C80 64.5 144.5 0 224 0s144 64.5 144 144v48h16c35.3 0 64 28.7 64 64V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V256c0-35.3 28.7-64 64-64H80z" />
      </SvgIcon>
    </IconButton>
  );
};
