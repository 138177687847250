import { RESTConnection } from '@phinxlab/libby-rest-web';
import React, { useCallback, useMemo } from 'react';
import { Box } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router';
import { useSnackbar } from 'notistack';
import { InfoTableColumn } from '../../../../../../commons/types';
import { UsuarioRolEstablecimiento } from '../../../../../../app/models';
import { OptionsModal } from '../../../../../../commons/components/OptionsModal';
import { BLOCKER_ID } from '../../../../../../platform/permission/const';
import confirmDialog from '../../../../../../commons/services/confirmDialog';
import { loadingModal } from '../../../../../../commons/services/loadingModal';
import {
  useUsuarioRolEstablecimientoDAO,
  useUsuarioRolTraverseActionDAO,
  useUsuarioRolDependenciaFuncionalDAO,
} from '../../../../../../app/business';
import { useAdminDAO } from '../../../../../../app/business/businessCustomEndpoints/Admin';

export const useUsuariosRolesColumns = (reFetch: () => void, libby: any) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const adminDao = useAdminDAO();

  const impersonateRol = useCallback(
    async (usuarioRol: UsuarioRolEstablecimiento) => {
      const res = await adminDao.impersonate(usuarioRol.idRolEstablecimiento);
      const user = { ...res.user, isGuest: res.isGuest };
      RESTConnection.defineHeader('x-chino-token', res.token);
      libby.session.notify('New session', user, res.token);
      history.push('/');
    },
    [adminDao, libby.session, history],
  );

  const usuarioRolEstablecimientoDAO = useUsuarioRolEstablecimientoDAO();
  const usuarioRolDependenciaFuncionalDAO =
    useUsuarioRolDependenciaFuncionalDAO();
  const usuarioRolTraverseActionDAO = useUsuarioRolTraverseActionDAO();

  const makeActionButtons = useCallback(
    (row: any) => {
      return [
        {
          id: BLOCKER_ID.IMPERSONATING_ROLE,
          label: 'Impersonar',
          onClick: async (data: UsuarioRolEstablecimiento) =>
            impersonateRol(data),
          hide:
            row?.rolUsuario?.idRolUsuario === 10 ||
            row?.rolUsuario?.idRolUsuario === 11,
        },
        {
          id: BLOCKER_ID.CONFIGURATION_ADMIN_USUARIOS_BOTON_EDIT_ROL,
          label: 'Editar',
          onClick: (data: UsuarioRolEstablecimiento) => {
            return history.push(`${url}/form/${data.idRolEstablecimiento}`);
          },
          hide:
            row?.rolUsuario?.idRolUsuario === 10 ||
            row?.rolUsuario?.idRolUsuario === 11,
        },
        {
          id: BLOCKER_ID.CONFIGURATION_ADMIN_USUARIOS_BOTON_REMOVE_ROL,
          label: 'Eliminar',
          onClick: async (data: UsuarioRolEstablecimiento) => {
            const confirm = await confirmDialog.show({
              title: 'Confirmación de Eliminación',
              content: `¿Desea eliminar este rol? Esta acción no se puede deshacer`,
              confirmText: 'Eliminar',
              cancelText: 'Cancelar',
            });
            if (confirm) {
              try {
                loadingModal.open();
                const urdp =
                  await usuarioRolDependenciaFuncionalDAO.fetchByRolEstablecimientoId(
                    data.idRolEstablecimiento,
                  );

                const urta = await usuarioRolTraverseActionDAO
                  .aspect('limit_province')
                  .getByUsuarioRolEstablecimiento({
                    0: [
                      {
                        path: 'usuarioRolEstablecimiento',
                        value: data.idRolEstablecimiento,
                      },
                    ],
                  });
                // TODO: antes de intentar eliminar hay que ver si se puede eliminar o no
                if (urdp.length) {
                  await usuarioRolDependenciaFuncionalDAO.remove(urdp[0]);
                }
                if (urta.length) {
                  await usuarioRolTraverseActionDAO.remove(urta[0]);
                }
                await usuarioRolEstablecimientoDAO.remove(data);
                enqueueSnackbar('Se ha eliminado con exito', {
                  variant: 'success',
                });
                reFetch();
              } catch (e) {
                console.log(e);
                enqueueSnackbar('Ha ocurrido un error al intentar eliminar', {
                  variant: 'error',
                });
              } finally {
                loadingModal.close();
              }
            }
          },
        },
      ];
    },
    [
      enqueueSnackbar,
      history,
      reFetch,
      url,
      usuarioRolDependenciaFuncionalDAO,
      usuarioRolEstablecimientoDAO,
      usuarioRolTraverseActionDAO,
      impersonateRol,
    ],
  );

  return useMemo<InfoTableColumn<UsuarioRolEstablecimiento>[]>(
    () => [
      {
        id: 'localizacion.descripcion',
        label: 'Establecimiento',
        orderById: 'localizacion.descripcion',
      },
      {
        id: 'nivel.descripcionNivel',
        label: 'Nivel',
        orderById: 'nivel.descripcionNivel',
      },
      {
        id: 'rolUsuario.descripcion',
        label: 'Rol',
        orderById: 'rolUsuario.descripcion',
      },
      {
        id: 'dependenciaFuncional.descripcion',
        label: 'Dependencia Funcional',
        orderById: 'dependenciaFuncional.descripcion',
      },
      {
        id: 'icono',
        label: '',
        width: '5%',
        hideSortIcon: true,
        style: { textAlign: 'right' },
        render: (value: any, row: any) => (
          <Box whiteSpace="nowrap" textAlign="right">
            <OptionsModal options={makeActionButtons(row)} data={row} />
          </Box>
        ),
      },
    ],
    [makeActionButtons],
  );
};
