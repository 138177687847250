import { Box, Grid, IconButton } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ESTADO_PRESENTE,
  InfoTable,
  InfoTableColumn,
  Loading,
  NIVEL,
  SimpleSelect,
  TIPO_PERIODO,
  TURNOS,
} from 'src/commons';
import FilterListIcon from '@material-ui/icons/FilterList';
import { useRolesContext } from 'src/context/RolesContext';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Visibility } from '@material-ui/icons';
import {
  useMultiPresentismoChecks,
  usePresentismoContext,
} from '../../TomaPresentismo/context';
import moment from 'moment';
import { ObservacionesModal } from '../../TomaPresentismo/components/ObservacionesModal';
import customFormDialog from 'src/commons/services/customFormDialog';
import {
  StudentReportBodyProps,
  rowJustificativosTypes,
  rowObservacionesTypes,
} from '../types';
import { useAlertasGetRequest, useTurnoLibbyFetch } from 'src/app/business';
import { Presente_v3, UsuarioRolEstablecimiento } from 'src/app/models';
import { useEstadoPresenteLibbyFetch } from 'src/app/business/presentismo_v3/EstadoPresente';
import { useColumnsJustify } from '../util/ColumnsJustify';
import { useStyles } from '../styles';
import {
  DateRangeComponent,
  DateRanges,
  getColor,
} from '../../Justificacion/components';
import {
  ESPACIOS_CURRICULARES_ESTADOS,
  EXTENDIDA_ESTADOS,
} from '../../TomaPresentismo/utils/EstadoPresente';
import { groupBy, orderBy } from 'lodash';
import { PresenteEspacioCurricularTable } from './PresenteEspacioCurricularTable';
import { AlertasV3, PresenteGeneral } from '../../TomaPresentismo/types';
import { AvisosStudentReport } from './AvisosStudentReport';
import { CondicionesModal } from '../../TomaPresentismo/components/CondicionesModal';
import { getPeriodoByDate } from '../../TomaPresentismo/components/PresentismoTable/functions/getPeriodoByDate';

type ValueFeItem = {
  path: string;
  value: ESTADO_PRESENTE[] | [];
  method: 'in' | 'equals' | 'contains';
};

type RowHistoricoType = {
  idTipoPeriodo: number;
  descripcionTipoPeriodo: string | undefined;
  idCondicion: number | null;
  descripcionCondicion: string | undefined;
  motivo: string | null;
  descripcion: string | null;
  changedBy: UsuarioRolEstablecimiento | undefined;
};

interface BimestresPresentes {
  [key: number]: boolean;
}

const columnObservations = [
  { id: 'date', label: 'Fecha', hideSortIcon: true, width: '1%' },
  { id: 'observations', label: 'Observación', hideSortIcon: true },
  { id: 'view', label: 'Ver', hideSortIcon: true, width: '1%' },
];

const turnos = {
  mañana: 0,
  tarde: 0,
  noche: 0,
  vespertino: 0,
};

export const StudentReportBody = ({
  data,
  presentismos,
  setperiodoActual,
  workingPresentismos,
  selectedEspacioCurricular,
  setFilterReport,
  filterReport,
  periodoNuevo,
  regularidad,
}: StudentReportBodyProps) => {
  const {
    selectedRole: { nivel },
  } = useRolesContext();
  const classes = useStyles();
  const { turno } = useMultiPresentismoChecks();
  const { isJornadaExtendida } = usePresentismoContext();
  const [reportModal, setReportModal] = useState(false);

  /* SETEADO DE SELECTS */

  const [showComponents, setShowComponents] = useState(false);

  const [filteredData, setFilteredData] = useState(presentismos);
  const [filteredTable, setFilteredTable] = useState(presentismos);

  useEffect(() => {
    setFilteredData(presentismos);
    setFilteredTable(presentismos);
    setShowComponents(true);
  }, [presentismos]);

  const filterEstadoPresente = useMemo(() => {
    let _valueFe: ValueFeItem[] = [];

    if (isJornadaExtendida) {
      _valueFe = [
        {
          path: 'idEstadoPresente',
          value: EXTENDIDA_ESTADOS,
          method: 'in',
        },
      ];
    } else if (Boolean(selectedEspacioCurricular)) {
      _valueFe = [
        {
          path: 'idEstadoPresente',
          value: ESPACIOS_CURRICULARES_ESTADOS,
          method: 'in',
        },
      ];
    } else {
      _valueFe = [];
    }
    return {
      0: _valueFe,
    };
  }, [isJornadaExtendida, selectedEspacioCurricular]);

  const { data: estadoPresente = [], working: workingEstadoPresente } =
    useEstadoPresenteLibbyFetch({
      filter: filterEstadoPresente,
    });

  const { data: dataTurnos = [], working: workingTurnos } =
    useTurnoLibbyFetch();

  const fechaDesde = moment(filteredTable[0]?.periodoNuevo?.fechaInicio).format(
    'YYYY-MM-DD',
  );
  const fechaHasta = moment(filteredTable[0]?.periodoNuevo?.fechaFin).format(
    'YYYY-MM-DD',
  );

  const { data: alertasv3 = {}, working: workingAlertasV3 } =
    useAlertasGetRequest<AlertasV3>({
      url: `/api/presentismov3/alertas?seccion=${data?.seccion?.idSeccion}&alumnos=${data?.alumno?.idAlumno}&fecha_desde=${fechaDesde}&fecha_hasta=${fechaHasta}`,
      autoCall: true,
    });

  const index = filteredTable[0]?.alumno?.idAlumno;
  const idxAlert = alertasv3[index] || 0;

  let isAlertActive = false;

  for (let i = 0; i < alertasv3[data?.alumno?.idAlumno]?.length; i++) {
    if (alertasv3[data?.alumno?.idAlumno][i].active === true) {
      isAlertActive = true;
      break;
    }
  }

  /* SELECT PERIODO */

  const fechaActual = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD');
  const periodosSinReceso = periodoNuevo.filter(
    (p) => p.tipoPeriodo.idTipoPeriodo !== TIPO_PERIODO.RECESO_INVERNAL,
  );
  const periodoPorFechaActual = getPeriodoByDate(
    periodosSinReceso,
    fechaActual,
  );

  const periodosAnteriores = periodosSinReceso.filter((periodo) => {
    const fechaFin = moment(periodo.fechaInicio, 'YYYY-MM-DD');
    return fechaFin.isSameOrBefore(fechaActual);
  });

  const periodSelecteReport = [...periodosAnteriores].filter(Boolean);

  /* SELECT ESTADO */

  const filteredStatePresent = estadoPresente.filter(
    (item) => item.idEstadoPresente !== 4 && item.idEstadoPresente !== 5,
  );

  const customOrder = [1, 2, 8, 6, 7, 3];

  function compareCustomOrder(a: any, b: any) {
    return (
      customOrder.indexOf(a.idEstadoPresente) -
      customOrder.indexOf(b.idEstadoPresente)
    );
  }

  const stateSelectReport = filteredStatePresent.sort(compareCustomOrder);

  /* SELECT TURNO */

  const availableTurnos = dataTurnos?.filter((turno) => {
    if (data?.seccion.turno.idTurno === turno.idTurno) {
      return true;
    }

    if (
      data?.seccion.turno.idTurno === 5 &&
      (turno.idTurno === 1 || turno.idTurno === 2)
    ) {
      return true;
    }

    return false;
  });

  const hasNullTurno = presentismos.some((p) => p.turno === null);

  if (hasNullTurno) {
    availableTurnos.push({ idTurno: 6, descripcionTurno: 'Contraturnos' });
  }

  // Modificar la descripción si el idTurno es 5
  for (const turno of availableTurnos) {
    if (turno.idTurno === 5) {
      turno.descripcionTurno = 'Ambos';
    }
  }

  useEffect(() => {
    setFilterReport({
      periodo: periodoPorFechaActual?.tipoPeriodo.idTipoPeriodo ?? 0,
      estado: 0,
      turno: data?.seccion?.turno?.idTurno ?? 0,
    });
    if (periodoPorFechaActual) {
      setperiodoActual(periodoPorFechaActual?.tipoPeriodo.idTipoPeriodo);
    }
  }, [
    periodoPorFechaActual,
    periodoPorFechaActual?.tipoPeriodo.idTipoPeriodo,
    periodoPorFechaActual?.tipoPeriodo,
    presentismos,
    setperiodoActual,
    data,
    setFilterReport,
  ]);

  useEffect(() => {
    let newData = [...presentismos];

    if (!!filterReport?.periodo && filterReport?.periodo !== 0) {
      const filterTable = newData?.filter(
        (periodo) =>
          periodo?.periodoNuevo?.idPeriodoNuevo === filterReport?.periodo,
      );
      setFilteredTable(filterTable);
    } else {
      setFilteredTable(presentismos);
    }

    if (!!filterReport?.periodo && filterReport?.periodo !== 0) {
      newData = newData?.filter(
        (periodo) =>
          periodo?.periodoNuevo?.idPeriodoNuevo === filterReport?.periodo,
      );
    }

    if (!!filterReport?.estado) {
      newData = newData?.filter(
        (estado) =>
          estado?.estadoPresente?.idEstadoPresente === filterReport?.estado,
      );
    }

    if (filterReport?.turno === 5) {
      newData = newData?.filter(
        (turno) => turno?.seccion?.turno?.idTurno === filterReport?.turno,
      );
    } else if (filterReport?.turno === 0) {
    } else if (filterReport?.turno === 6) {
      newData = newData?.filter((turno) => turno?.turno === null);
    } else if (
      filterReport?.turno !== 5 &&
      filterReport?.turno !== 6 &&
      !selectedEspacioCurricular
    ) {
      newData = newData?.filter(
        (turno) => turno?.turno?.idTurno === filterReport?.turno,
      );
    }

    setFilteredData(newData);
  }, [filterReport, presentismos, selectedEspacioCurricular]);

  let contraturnosDias = data.contraturnos.filter((ctr) => {
    return ctr.active && ctr.contraturno.active;
  });

  const contraturnosMapped: { [k: string]: PresenteGeneral[] } = {};
  const contraturnosPorDia = groupBy(contraturnosDias, 'dia.idDia');
  Object.values(contraturnosPorDia).forEach((contraturnos) => {
    let orderContraturnos = orderBy(
      contraturnos,
      ['idContraturnoDia'],
      ['asc'],
    );
    const [ct1, ct2] = orderContraturnos.map((ctr, index) => {
      return {
        ...ctr,
        presentismos: filteredTable.filter((pre) => {
          return ctr.idContraturnoDia === pre.contraturnoDia?.idContraturnoDia;
        }),
        label: `CT${index + 1}`,
      };
    });
    if (ct1) {
      if (contraturnosMapped['CT1']) {
        contraturnosMapped['CT1'].push(...ct1.presentismos);
      } else {
        contraturnosMapped['CT1'] = ct1.presentismos;
      }
    }
    if (ct2) {
      if (contraturnosMapped['CT2']) {
        contraturnosMapped['CT2'].push(...ct2.presentismos);
      } else {
        contraturnosMapped['CT2'] = ct2.presentismos;
      }
    }
  });

  // CALENDARIO ---------------------------
  // Logica para mostar los presentismo en  el calendario
  const dataRangeCalendar = useMemo<DateRanges[]>(() => {
    const turnoDoble = filterReport.turno === TURNOS.DOBLE;
    const rangos: DateRanges[] = [];
    if (turnoDoble) {
      const grouped = groupBy(filteredData, 'fecha');
      Object.keys(grouped).forEach((key) => {
        const current = grouped[key];

        const allPresente =
          current.length > 1 &&
          current.every(
            (c) =>
              c.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.PRESENTE,
          );
        const allAusentes =
          current.length > 1 &&
          current.every(
            (c) =>
              c.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE,
          );
        const allIngresoTardio =
          current.length > 1 &&
          current.every(
            (c) =>
              c.estadoPresente.idEstadoPresente ===
              ESTADO_PRESENTE.INGRESO_TARDIO,
          );
        const allRetiroAnticipado =
          current.length > 1 &&
          current.every(
            (c) =>
              c.estadoPresente.idEstadoPresente ===
              ESTADO_PRESENTE.RETIRO_ANTICIPADO,
          );
        const allAusentePresencia =
          current.length > 1 &&
          current.every(
            (c) =>
              c.estadoPresente.idEstadoPresente ===
              ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE,
          );
        const allNoCorresponde =
          current.length > 1 &&
          current.every(
            (c) =>
              c.estadoPresente.idEstadoPresente ===
              ESTADO_PRESENTE.NO_CORRESPONDE,
          );

        if (
          allPresente ||
          allAusentes ||
          allIngresoTardio ||
          allRetiroAnticipado ||
          allAusentePresencia ||
          allNoCorresponde
        ) {
          const presente = current[0];
          const dateStart = moment(presente.fecha, 'YYYY-MM-DD');
          const dateEnd = moment(presente.fecha, 'YYYY-MM-DD');

          rangos.push({
            startDate: dateStart.toDate(),
            endDate: dateEnd.toDate(),
            key: 'indicator',
            color: getColor(presente),
          });
        }
      });
    } else {
      filteredData?.forEach((date) => {
        const dateStart = moment(date.fecha, 'YYYY-MM-DD');
        const dateEnd = moment(date.fecha, 'YYYY-MM-DD');

        const formattedDate: DateRanges = {
          startDate: dateStart.toDate(),
          endDate: dateEnd.toDate(),
          key: 'indicator',
          color: getColor(date),
        };
        rangos.push(formattedDate);
      });
    }
    return rangos;
  }, [filteredData, filterReport.turno]);
  // Fin Logica para mostar los presentismo en  el calendario

  // TABLA PRESENTISMO ---------------------------------------

  const rowsState = [
    {
      color: (
        <FiberManualRecordIcon style={{ color: 'green', fontSize: 'small' }} />
      ),
      estado: 'Presente',
      ct1: 0,
      ct2: 0,
      ...turnos,
    },
    {
      color: (
        <FiberManualRecordIcon style={{ color: 'red', fontSize: 'small' }} />
      ),
      estado: 'Ausente',
      ct1: 0,
      ct2: 0,
      ...turnos,
    },
    Boolean(selectedEspacioCurricular)
      ? ''
      : {
          color: (
            <FiberManualRecordIcon
              style={{ color: 'blue', fontSize: 'small' }}
            />
          ),
          estado: 'Ausente justificado',
          ct1: '-',
          ct2: '-',
          ...turnos,
        },
    Boolean(isJornadaExtendida)
      ? ''
      : {
          color: (
            <FiberManualRecordIcon
              style={{ color: 'orange', fontSize: 'small' }}
            />
          ),
          estado: 'Ausente con presencia en clase',
          ct1: 0,
          ct2: 0,
          ...turnos,
        },
    Boolean(selectedEspacioCurricular || isJornadaExtendida)
      ? ''
      : {
          color: (
            <FiberManualRecordIcon
              style={{ color: 'blue', fontSize: 'small' }}
            />
          ),
          estado: 'Ausente con presencia en clase justificado',
          ct1: '-',
          ct2: '-',
          ...turnos,
        },
    Boolean(selectedEspacioCurricular || isJornadaExtendida)
      ? ''
      : {
          color: (
            <FiberManualRecordIcon
              style={{ color: 'yellow', fontSize: 'small' }}
            />
          ),
          estado: 'Ingreso Tardío',
          ct1: '-',
          ct2: '-',
          ...turnos,
        },
    Boolean(selectedEspacioCurricular || isJornadaExtendida)
      ? ''
      : {
          color: (
            <FiberManualRecordIcon
              style={{ color: 'blue', fontSize: 'small' }}
            />
          ),
          estado: 'Ingreso Tardío justificado',
          ct1: '-',
          ct2: '-',
          ...turnos,
        },
    Boolean(selectedEspacioCurricular || isJornadaExtendida)
      ? ''
      : {
          color: (
            <FiberManualRecordIcon
              style={{ color: 'yellow', fontSize: 'small' }}
            />
          ),
          estado: 'Retiro anticipado',
          ct1: '-',
          ct2: '-',
          ...turnos,
        },
    Boolean(selectedEspacioCurricular || isJornadaExtendida)
      ? ''
      : {
          color: (
            <FiberManualRecordIcon
              style={{ color: 'blue', fontSize: 'small' }}
            />
          ),
          estado: 'Retiro anticipado justificado',
          ct1: '-',
          ct2: '-',
          ...turnos,
        },
    {
      color: (
        <FiberManualRecordIcon style={{ color: 'gray', fontSize: 'small' }} />
      ),
      estado: 'No Corresponde',
      ct1: 0,
      ct2: 0,
      ...turnos,
    },
  ];

  const getDescriptionCountsByTurno = (filteredTable: PresenteGeneral[]) => {
    const descriptionCountsByTurno: Record<string, Record<string, number>> = {};

    for (const item of filteredTable) {
      const descripcion = item.estadoPresente.descripcion;
      const turno = item.turno?.descripcionTurno;

      const descripcionKey = item.justificacion
        ? `${descripcion} justificado`
        : descripcion;

      if (turno) {
        if (!descriptionCountsByTurno[turno]) {
          descriptionCountsByTurno[turno] = {};
        }

        if (!descriptionCountsByTurno[turno][descripcionKey]) {
          descriptionCountsByTurno[turno][descripcionKey] = 0;
        }

        descriptionCountsByTurno[turno][descripcionKey]++;
      }
    }
    return descriptionCountsByTurno;
  };
  const getDescriptionCounts = getDescriptionCountsByTurno(filteredTable);

  const presentismoConContraturnoDia = presentismos?.filter(
    (item) => item.contraturnoDia,
  );

  const { ct1States, ct2States } = useMemo(() => {
    const ct1States: PresenteGeneral[] = [];
    const ct2States: PresenteGeneral[] = [];

    presentismoConContraturnoDia.forEach((item) => {
      const fechaActual = new Date(item.fecha);
      const idContraturnoDiaActual = item.contraturnoDia?.idContraturnoDia;

      const periodosPorNivel = periodoNuevo.filter(
        (periodo) => periodo.nivel.idNivel === Number(nivel),
      );

      const periodoEncontrado = periodosPorNivel.find((ciclo) => {
        const fechaInicio = new Date(ciclo.fechaInicio);
        const fechaFin = new Date(ciclo.fechaFin);
        return fechaActual >= fechaInicio && fechaActual <= fechaFin;
      });
      if (periodoEncontrado) {
        item.periodoNuevo = periodoEncontrado;
      }

      if (idContraturnoDiaActual !== undefined) {
        const ct1ItemCoincidente = presentismoConContraturnoDia.find(
          (otroItem) =>
            otroItem.contraturnoDia &&
            otroItem.contraturnoDia.idContraturnoDia > idContraturnoDiaActual,
        );

        const ct2ItemCoincidente = presentismoConContraturnoDia.find(
          (otroItem) =>
            otroItem.contraturnoDia &&
            otroItem.contraturnoDia.idContraturnoDia < idContraturnoDiaActual,
        );

        if (ct1ItemCoincidente) {
          ct1States.push(item);
        }

        if (ct2ItemCoincidente) {
          ct2States.push(item);
        }
      }
    });
    return { ct1States, ct2States };
  }, [presentismoConContraturnoDia, periodoNuevo, nivel]);

  const getColumnConfig = (filteredTable: PresenteGeneral[]) => {
    const contraturnoColumns = [];
    const columnsEstado = [
      { id: 'color', label: '', hideSortIcon: true, width: '1%' },
      { id: 'estado', label: 'Estado', hideSortIcon: true },
    ];

    const descripcionTurno = selectedEspacioCurricular
      ? 'mañana'
      : filteredTable[0]?.seccion?.turno?.descripcionTurno.toLowerCase();

    const isExistContratunro = filteredTable?.filter(
      (con) => con.contraturnoDia !== null,
    );

    if (descripcionTurno) {
      if (selectedEspacioCurricular) {
        columnsEstado.push({
          id: descripcionTurno,
          label: 'Asistencia General',
          hideSortIcon: true,
        });
      } else {
        switch (descripcionTurno) {
          case 'mañana':
            columnsEstado.push({
              id: descripcionTurno,
              label: 'TM',
              hideSortIcon: true,
            });
            break;
          case 'tarde':
            columnsEstado.push({
              id: descripcionTurno,
              label: 'TT',
              hideSortIcon: true,
            });
            break;
          case 'doble':
            columnsEstado.push(
              { id: 'mañana', label: 'TM', hideSortIcon: true },
              { id: 'tarde', label: 'TT', hideSortIcon: true },
            );
            break;
          case 'noche':
            columnsEstado.push({
              id: descripcionTurno,
              label: 'TN',
              hideSortIcon: true,
            });
            break;
          case 'vespertino':
            columnsEstado.push({
              id: descripcionTurno,
              label: 'TV',
              hideSortIcon: true,
            });
            break;
          default:
            break;
        }
      }
    }

    if (ct1States.length > 0 && isExistContratunro.length > 0) {
      contraturnoColumns.push({
        id: 'ct1',
        label: 'CT1',
        hideSortIcon: true,
      });
    }

    if (ct2States.length > 0 && isExistContratunro.length > 0) {
      contraturnoColumns.push({
        id: 'ct2',
        label: 'CT2',
        hideSortIcon: true,
      });
    }

    const allColumns = [...columnsEstado, ...contraturnoColumns];
    return allColumns;
  };

  const updateRowsState = (
    rows: any[],
    descriptionCounts: { [x: string]: any },
    ct1States: PresenteGeneral[],
    ct2States: PresenteGeneral[],
  ) => {
    const filteredRows = rows.filter((row) => {
      return Object.keys(row).length !== 0;
    });

    const updatedRows = filteredRows.map((row) => {
      const updatedRow = { ...row };

      for (const turno in descriptionCounts) {
        const descriptionCountsForTurno = descriptionCounts[turno];
        for (const estado in descriptionCountsForTurno) {
          if (updatedRow.estado === estado) {
            updatedRow[turno.toLocaleLowerCase()] =
              descriptionCountsForTurno[estado];
          }
        }
      }

      const ct1Count = ct1States
        ? ct1States.filter(
            (item) => item.estadoPresente.descripcion === updatedRow.estado,
          ).length
        : 0;

      const ct2Count = ct2States
        ? ct2States.filter(
            (item) => item.estadoPresente.descripcion === updatedRow.estado,
          ).length
        : '-';

      updatedRow.ct1 = ct1Count;
      updatedRow.ct2 = ct2Count;

      return updatedRow;
    });

    return updatedRows;
  };

  const updatedRowsState = updateRowsState(
    rowsState,
    getDescriptionCounts,
    contraturnosMapped.CT1,
    contraturnosMapped.CT2,
  );

  const columnState = getColumnConfig(filteredTable);

  // OBSERVACIONES Y JUSTIFICATIVOS ---------------------------------

  const columnJustify = useColumnsJustify(filteredTable, setFilteredTable);

  const [rowObservaciones, rowJustificativos] = useMemo(() => {
    const rowObservaciones: rowObservacionesTypes[] = [];
    const rowJustificativos: rowJustificativosTypes[] = [];
    filteredTable?.forEach((present) => {
      if (present.observacion) {
        const datePresent = moment(present.fecha);

        const dateFormated = moment(datePresent, 'dddd DD');

        const date = datePresent.format('DD-MM').toString();

        const observations = present.observacion || '';

        const _dataAlumno = { ...data, presentismo: [present] };

        const handleShowObservaciones = async () => {
          await customFormDialog.show({
            title: 'Observaciones',
            renderComponent: (
              <ObservacionesModal
                dayFormatted={date}
                daySelected={dateFormated}
                alumno={_dataAlumno}
                turno={selectedEspacioCurricular ? 1 : turno}
                onSubmit={() => null}
                isDisabledReportModal={!reportModal}
              />
            ),
            sizeWidth: 'sm',
          });
        };

        const view = (
          <IconButton
            className={classes.iconButton}
            size="small"
            onClick={handleShowObservaciones}
          >
            <Visibility className={classes.icon} />
          </IconButton>
        );

        rowObservaciones.push({
          date,
          observations,
          view,
        });
      }

      if (present.justificacion) {
        const datePresent = moment(present.fecha);
        const date = datePresent.format('DD-MM').toString();
        const details = present?.justificacion?.details || '';
        const turno = present.turno?.descripcionTurno || '';
        const motivo =
          present.justificacion?.motivoJustificacion?.description || '';
        const view = (
          <IconButton
            className={classes.iconButton}
            size="small"
            onClick={() => null}
          >
            <Visibility className={classes.icon} />
          </IconButton>
        );

        rowJustificativos.push({
          date,
          details,
          turno,
          motivo,
          view,
          estado: present.estadoPresente.descripcion,
          presente: present,
        });
      }
    });
    return [rowObservaciones, rowJustificativos];
  }, [
    classes.icon,
    classes.iconButton,
    data,
    filteredTable,
    reportModal,
    turno,
    selectedEspacioCurricular,
  ]);

  const selectedPeriodo = useMemo(() => {
    if (filterReport.periodo > 0) {
      return periodSelecteReport?.find(
        (p) => p?.idPeriodoNuevo === filterReport.periodo,
      );
    }
    return periodoPorFechaActual;
  }, [periodSelecteReport, filterReport.periodo, periodoPorFechaActual]);

  const rowsConditions: RowHistoricoType[] = [];
  const bimestresPresentes: BimestresPresentes = {};

  const mayusculaPrimeraLetra = (cadena: string) => {
    return cadena
      .split(' ')
      .map((palabra) => palabra.charAt(0).toUpperCase() + palabra.slice(1))
      .join(' ');
  };

  regularidad.forEach((reg) => {
    const condiciones = reg.condiciones;

    Object.entries(condiciones).forEach(([idTipoPeriodo, cond]) => {
      const bimestreId = cond.idTipoPeriodo;

      if (
        !bimestresPresentes[bimestreId] &&
        ((Number(nivel) === NIVEL.INICIAL &&
          (bimestreId === 1 || bimestreId === 3)) ||
          Number(nivel) !== NIVEL.INICIAL)
      ) {
        bimestresPresentes[bimestreId] = true;

        const tipoPeriodoEncontrado = periodosAnteriores.find((periodo) => {
          return periodo!.tipoPeriodo.idTipoPeriodo === bimestreId;
        });
        if (!tipoPeriodoEncontrado) {
          return;
        }
        const descripcionTipoPeriodo = mayusculaPrimeraLetra(
          tipoPeriodoEncontrado.description,
        );

        const idCondicion = cond.condicion ? cond.condicion.idCondicion : null;
        const descripcionCondicion = cond.condicion
          ? cond.condicion.descripcionCondicion
          : 'Datos insuficientes';

        rowsConditions.push({
          idTipoPeriodo: bimestreId,
          descripcionTipoPeriodo: descripcionTipoPeriodo,
          idCondicion: idCondicion,
          descripcionCondicion: descripcionCondicion,
          motivo: cond.motivo,
          descripcion: cond.observacion,
          changedBy: cond.changeBy,
        });
      }
    });
  });

  const rowConditionsordered = rowsConditions.sort(
    (a, b) => a.idTipoPeriodo - b.idTipoPeriodo,
  );

  const columnConditions: InfoTableColumn<RowHistoricoType>[] = [
    {
      id: 'descripcionTipoPeriodo',
      label: Number(nivel) === NIVEL.INICIAL ? 'Cuatrimestre' : 'Bimestre',
      hideSortIcon: true,
      width: '20%',
    },
    {
      id: 'descripcionCondicion',
      label: 'Condición',
      hideSortIcon: true,
    },
    {
      id: 'view',
      label: 'Ver',
      hideSortIcon: true,
      width: '1%',
      render: (_: any, row) => {
        const handleShowCondiciones = async () => {
          await customFormDialog.show({
            title: 'Histórico Condición',
            renderComponent: (
              <CondicionesModal
                reason={row?.motivo || ''}
                detail={row?.descripcion || ''}
              />
            ),
            sizeWidth: 'sm',
          });
        };

        return (
          <IconButton
            className={classes.iconButton}
            size="small"
            onClick={handleShowCondiciones}
            disabled={row.motivo === null || row.motivo === ''}
          >
            <Visibility className={classes.icon} />
          </IconButton>
        );
      },
      align: 'center',
    },
  ];

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        style={{ marginTop: '30px', padding: '10px 50px 0px 50px' }}
      >
        {workingPresentismos ||
        workingEstadoPresente ||
        workingTurnos ||
        workingAlertasV3 ? (
          <Loading />
        ) : (
          <Grid container>
            <Grid item xs={12} style={{ marginBottom: '20px' }}>
              <Box className={classes.title} component="h2">
                Resumen de asistencia
              </Box>
            </Grid>
            <Grid item xs={!showComponents ? 4 : 6}>
              <Grid container>
                <Grid item xs={!showComponents ? 10 : 6}>
                  <Grid container>
                    <Grid xs={12}>
                      <Box className={classes.filterButton}>
                        Filtro <FilterListIcon />
                      </Box>
                    </Grid>
                    <Grid xs={12}>
                      <Box className={classes.subtitle} component="h2">
                        Periodo
                      </Box>
                    </Grid>
                    <Grid xs={12} style={{ paddingRight: '50px' }}>
                      <SimpleSelect
                        name={'periodo'}
                        placeholder="Seleccione Período"
                        content={periodSelecteReport}
                        value={filterReport.periodo}
                        handleChange={(e: any) => {
                          const value =
                            e?.target?.value === '' ? 0 : e?.target?.value;
                          setperiodoActual(value);
                          setFilterReport((prev: any) => ({
                            ...prev,
                            periodo: value,
                          }));
                        }}
                        valueKey="idPeriodoNuevo"
                        labelKey="description"
                        /*       loading={workingPeriodoNuevo} */
                      />
                    </Grid>
                    <Grid xs={12} style={{ marginTop: '20px' }}>
                      <Box className={classes.subtitle} component="h2">
                        Estado
                      </Box>
                    </Grid>
                    <Grid xs={12} style={{ paddingRight: '50px' }}>
                      <SimpleSelect
                        name={'estado'}
                        value={filterReport.estado ?? 0}
                        content={stateSelectReport}
                        placeholder="Ver todos"
                        valueKey="idEstadoPresente"
                        labelKey="descripcion"
                        handleChange={(e: any) => {
                          const value =
                            e?.target?.value === '' ? 0 : e?.target?.value;
                          setFilterReport((prev: any) => ({
                            ...prev,
                            estado: value,
                          }));
                        }}
                        loading={workingEstadoPresente}
                      />
                    </Grid>
                    <Grid xs={12} style={{ marginTop: '20px' }}>
                      <Box className={classes.subtitle} component="h2">
                        Turno
                      </Box>
                    </Grid>
                    <Grid xs={12} style={{ paddingRight: '50px' }}>
                      <SimpleSelect
                        name={'turno'}
                        content={availableTurnos}
                        value={filterReport.turno ?? 0}
                        handleChange={(e: any) => {
                          const value =
                            e?.target?.value === '' ? 0 : e?.target?.value;
                          setFilterReport((prev: any) => ({
                            ...prev,
                            turno: value,
                          }));
                        }}
                        valueKey="idTurno"
                        labelKey="descripcionTurno"
                        loading={workingTurnos}
                      />
                    </Grid>
                    {isAlertActive ? (
                      <>
                        <Grid xs={12} style={{ marginTop: '20px' }}>
                          <Box className={classes.subtitle} component="h2">
                            Avisos
                          </Box>
                        </Grid>
                        <Grid xs={12}>
                          <AvisosStudentReport avisosCount={idxAlert || 0} />
                        </Grid>
                      </>
                    ) : (
                      <></>
                    )}
                  </Grid>
                </Grid>
                <Grid item xs={6}>
                  <DateRangeComponent
                    presentismos={
                      (filteredData || []) as unknown as Presente_v3[]
                    }
                    ranges={dataRangeCalendar || []}
                    minDate={
                      selectedPeriodo
                        ? new Date(selectedPeriodo?.fechaInicio)
                        : undefined
                    }
                    maxDate={
                      selectedPeriodo
                        ? new Date(selectedPeriodo?.fechaFin)
                        : undefined
                    }
                    initialDate={
                      selectedPeriodo
                        ? new Date(selectedPeriodo?.fechaInicio)
                        : undefined
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: '50px' }}>
              <InfoTable columns={columnState} rows={updatedRowsState} />
            </Grid>
            <Grid item xs={12} style={{ marginBottom: '30px' }}>
              {Number(nivel) === NIVEL.SECUNDARIO ? (
                <Grid container style={{ paddingTop: '50px' }}>
                  {Boolean(selectedEspacioCurricular) || isJornadaExtendida ? (
                    <Grid container>
                      <Grid xs={6} style={{ paddingTop: '30px' }}>
                        <Grid container>
                          <Grid xs={12}>
                            <Box className={classes.title} component="h2">
                              Observaciones
                            </Box>
                          </Grid>
                          <Grid xs={12} style={{ paddingRight: '50px' }}>
                            <InfoTable
                              columns={columnObservations}
                              rows={rowObservaciones}
                              message={'No hay observaciones cargadas'}
                              headerAlign="center"
                              customClassnameTypography={classes.textRows}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                      {selectedEspacioCurricular ? (
                        <></>
                      ) : (
                        <Grid xs={6} style={{ paddingTop: '30px' }}>
                          <Grid container>
                            <Grid xs={12} style={{ paddingLeft: '50px' }}>
                              <Box className={classes.title} component="h2">
                                Justificativos
                              </Box>
                            </Grid>
                            <Grid xs={12} style={{ paddingLeft: '50px' }}>
                              <InfoTable
                                columns={columnJustify}
                                rows={rowJustificativos}
                                message={'No hay justificaciones cargadas'}
                                headerAlign="center"
                                customClassnameTypography={classes.textRows}
                              />
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Grid>
                  ) : (
                    <Grid container>
                      <Grid item xs={6}>
                        <PresenteEspacioCurricularTable
                          movimiento={data}
                          selectedPeriodo={selectedPeriodo}
                        />{' '}
                      </Grid>
                      <Grid xs={6}>
                        <Grid container style={{ paddingLeft: '50px' }}>
                          <Grid xs={12}>
                            <Box
                              className={classes.title}
                              marginTop={'0px'}
                              component="h2"
                            >
                              Histórico Condición
                            </Box>
                          </Grid>
                          <Grid xs={12}>
                            <InfoTable
                              columns={columnConditions}
                              rows={
                                rowConditionsordered.length > 0
                                  ? rowConditionsordered
                                  : []
                              }
                              message={
                                'No hay histórico de condiciones cargadas'
                              }
                              headerAlign="center"
                              customClassnameTypography={classes.textRows}
                            />
                          </Grid>
                        </Grid>
                        {!Boolean(selectedEspacioCurricular) ? (
                          <Grid container style={{ paddingLeft: '50px' }}>
                            <Grid xs={12} style={{ paddingTop: '20px' }}>
                              <Box className={classes.title} component="h2">
                                Justificativos
                              </Box>
                            </Grid>
                            <Grid xs={12}>
                              <InfoTable
                                columns={columnJustify}
                                rows={rowJustificativos}
                                message={'No hay justificaciones cargadas'}
                                headerAlign="center"
                                customClassnameTypography={classes.textRows}
                              />
                            </Grid>
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </Grid>
                      <Grid item xs={12} style={{ paddingTop: '20px' }}>
                        <Box className={classes.title} component="h2">
                          Observaciones
                        </Box>
                      </Grid>
                      <Grid xs={12}>
                        <InfoTable
                          columns={columnObservations}
                          rows={rowObservaciones}
                          message={'No hay observaciones cargadas'}
                          headerAlign="center"
                          customClassnameTypography={classes.textRows}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Grid container>
                  <Grid xs={6} style={{ paddingTop: '30px' }}>
                    <Grid container>
                      <Grid xs={12}>
                        <Box className={classes.title} component="h2">
                          Histórico Condición
                        </Box>
                      </Grid>
                      <Grid xs={12} style={{ paddingRight: '50px' }}>
                        <InfoTable
                          columns={columnConditions}
                          rows={
                            rowConditionsordered.length > 0
                              ? rowConditionsordered
                              : []
                          }
                          message={'No hay histórico de condiciones cargadas'}
                          headerAlign="center"
                          customClassnameTypography={classes.textRows}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {selectedEspacioCurricular ? (
                    <></>
                  ) : (
                    <Grid xs={6} style={{ paddingTop: '30px' }}>
                      <Grid container>
                        <Grid xs={12} style={{ paddingLeft: '50px' }}>
                          <Box className={classes.title} component="h2">
                            Justificativos
                          </Box>
                        </Grid>
                        <Grid xs={12} style={{ paddingLeft: '50px' }}>
                          <InfoTable
                            columns={columnJustify}
                            rows={rowJustificativos}
                            message={'No hay justificaciones cargadas'}
                            headerAlign="center"
                            customClassnameTypography={classes.textRows}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  )}
                  <Grid xs={12} style={{ paddingTop: '20px' }}>
                    <Grid container>
                      <Grid xs={12}>
                        <Box className={classes.title} component="h2">
                          Observaciones
                        </Box>
                      </Grid>
                      <Grid xs={12}>
                        <InfoTable
                          columns={columnObservations}
                          rows={rowObservaciones}
                          message={'No hay observaciones cargadas'}
                          headerAlign="center"
                          customClassnameTypography={classes.textRows}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
