import React from 'react';
import { Card, CardContent, CardHeader, Typography } from '@material-ui/core';
import {
  LocalizacionSelectedChips,
  LocalizacionById,
} from '../../../../business';
import { Loading } from '../../../../../commons/components/Loading';

export interface SelectedValueProps {
  id?: string;
  onUnselectClick: () => void;
}

export const SelectedValue = ({ id, onUnselectClick }: SelectedValueProps) => {
  return (
    <Card>
      <CardHeader title="Seleccionado" />
      <CardContent>
        {!id ? (
          <Typography>No seleccionado</Typography>
        ) : (
          <LocalizacionById id={id} key={id}>
            {(result) =>
              result.working ? (
                <Loading />
              ) : !result.data ? (
                <Typography>No encontrado</Typography>
              ) : (
                <LocalizacionSelectedChips
                  items={[result.data]}
                  onChange={onUnselectClick}
                />
              )
            }
          </LocalizacionById>
        )}
      </CardContent>
    </Card>
  );
};
