import React from 'react';
import { ObjetivosDeAprendizajePlan } from 'src/app/models';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { grey } from 'src/theme/colors';

const useStyles = makeStyles(() => ({
  valueField: {
    fontSize: 16,
    color: grey.textPrimary,
    fontWeight: 400,
  },
}));
export interface ObjetivosDeAprendizajeProps {
  data: ObjetivosDeAprendizajePlan;
}

export const ObjetivosDeAprendizaje = ({
  data,
}: ObjetivosDeAprendizajeProps) => {
  const classes = useStyles();
  return (
    <Grid container direction="column">
      {data?.objetivosDeAprendizaje?.map((item: string, index: number) => (
        <Grid key={index}>
          <Typography className={classes.valueField}>{item}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};
