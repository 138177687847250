import React, { useCallback, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router';
import { useEspaciosSimpDiscipPlanDAO } from 'src/app/business/EspaciosSimpDiscipPlan';
import {
  EspacioCurricularByIdProvider,
  useSimpDiscipPlanDAO,
} from 'src/app/business';
import { TabBar } from 'src/commons/components/TabBar';
import { FormContextProvider } from 'src/lib/templates';
import { Loading } from 'src/commons';
import { FormFooter } from '../commons/components';
import { useRolesContext } from '../../../../../../../context/RolesContext';
import {
  DiscipSimTempPlan,
  NewSimpDiscipPlan,
  SimpDiscipPlan,
} from '../../../../../../../app/models';
import { tabs } from './tabs/tabs';
import { useTemplateJoiner } from '../commons/hooks';
import { PlanificacionDisciplinarHeader } from './components';
import { FormProps } from '../commons/types';
import { DisciplinarComunProvider } from './components/context';
import { PLANIFICACION_ESTADOS } from '../../../../../../../commons/const';
import { DEFAULT_HIDE_TIME } from 'src/commons/const/ui/snackbar';

const templateMap = {
  ensenanza: 'ensenanzaTemp',
  objetivosDeAprendizaje: 'objetivosDeAprendizajeTemp',
  indicadoresDeEvaluacion: 'indicadoresDeEvaluacionTemp',
  primerBimestre: 'primerBimestreTemp',
  segundoBimestre: 'segundoBimestreTemp',
  tercerBimestre: 'tercerBimestreTemp',
  cuartoBimestre: 'cuartoBimestreTemp',
  bibliografia: 'bibliografiaTemp',
};

const isSimpDiscipPlan = (
  item: SimpDiscipPlan | NewSimpDiscipPlan,
): item is SimpDiscipPlan =>
  (item as SimpDiscipPlan).idSimpDiscipPlan !== undefined;

// TODO: Camibar los hooks a la tabla correspondiente de esta planificacion
export const PlanificacionDisciplinarComunForm = ({
  initialValues,
  template,
}: FormProps<SimpDiscipPlan, NewSimpDiscipPlan, DiscipSimTempPlan>) => {
  // disabled enviar button for new planning
  const [isDisabled, setIsDisabled] = useState<Boolean>(
    !isSimpDiscipPlan(initialValues),
  );
  const [isNewPlan, setIsNewPlan] = useState(!isSimpDiscipPlan(initialValues));

  const [valuesUpdated, setValuesUpdated] = useState(initialValues);

  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedRole, userInfo } = useRolesContext();
  // @ts-ignore
  const { idEspacioCurricular } = useParams();

  // Unimos varios templates en uno solo
  const joinedTemplates = useTemplateJoiner(template, templateMap);

  const espaciosSimpDiscipPlan = useEspaciosSimpDiscipPlanDAO();
  const simpDiscipPlanDAO = useSimpDiscipPlanDAO();
  const enviarCallBack = async () => {
    const newValue = {
      ...valuesUpdated,
      planificacionEstados: {
        idPlanificacionEstados: PLANIFICACION_ESTADOS.ENVIADO,
      },
    };
    await simpDiscipPlanDAO.save(newValue);
    history.goBack();
    enqueueSnackbar('La planificación ha sido enviada con éxito', {
      variant: 'success',
      autoHideDuration: DEFAULT_HIDE_TIME,
    });
  };
  const onSubmit = useCallback(
    async (values) => {
      try {
        if (!isSimpDiscipPlan(valuesUpdated)) {
          const SimpDiscipPlanData = {
            ...values,
            localizacion: { idLocalizacion: selectedRole.localizacionId },
            discipSimTempPlan: {
              idDiscipSimTempPlan: template.idDiscipSimTempPlan,
            },
            cuentas: { idUsuario: userInfo.id },
          };
          const espacioSimpDiscipPlanData = {
            espacioCurricular: { idEspacioCurricular },
          };
          const { simpDiscipPlan } = await espaciosSimpDiscipPlan
            .aspect('limit_province')
            .save({
              SimpDiscipPlanData,
              espacioSimpDiscipPlanData,
            });
          enqueueSnackbar('Se ha guardado con éxito', {
            variant: 'success',
            autoHideDuration: DEFAULT_HIDE_TIME,
          });
          setValuesUpdated(simpDiscipPlan);
          setIsDisabled(false);
          setIsNewPlan(false);
        } else {
          const updated = {
            ...valuesUpdated,
            ...values,
            planificacionEstados: {
              idPlanificacionEstados: PLANIFICACION_ESTADOS.EN_PROCESO,
            },
          };
          updated.ultimaActualizacion = new Date();
          await simpDiscipPlanDAO.save(updated);
          enqueueSnackbar('Se ha guardado con éxito', {
            variant: 'success',
            autoHideDuration: DEFAULT_HIDE_TIME,
          });
          setValuesUpdated(updated);
          setIsDisabled(false);
        }
      } catch (e) {
        // TODO: fix error de libby
        console.log(e);
      }
    },
    [
      enqueueSnackbar,
      espaciosSimpDiscipPlan,
      idEspacioCurricular,
      valuesUpdated,
      selectedRole.localizacionId,
      userInfo.id,
      simpDiscipPlanDAO,
      template.idDiscipSimTempPlan,
    ],
  );

  const depuredInitialValue = useMemo<NewSimpDiscipPlan>(() => {
    if (!isSimpDiscipPlan(initialValues)) {
      return initialValues;
    }
    const {
      idSimpDiscipPlan,
      discipSimTempPlan,
      localizacion,
      cuentas,
      ultimaActualizacion,
      planificacionEstados: pe,
      ...rest
    } = initialValues;
    return rest;
  }, [initialValues]);

  return (
    <EspacioCurricularByIdProvider id={idEspacioCurricular}>
      <PlanificacionDisciplinarHeader
        localizacionId={selectedRole.localizacionId}
      />
      <DisciplinarComunProvider>
        {!joinedTemplates ? (
          <Loading />
        ) : (
          <FormContextProvider
            template={joinedTemplates}
            initialValues={depuredInitialValue}
            onSubmit={onSubmit}
          >
            <>
              <TabBar content={tabs} />
              <FormFooter
                enviarCallBack={enviarCallBack}
                isDisabled={isDisabled}
                handleValuesChange={({ isDisabled }) =>
                  setIsDisabled(isDisabled)
                }
                isNewPlan={isNewPlan}
              />
            </>
          </FormContextProvider>
        )}
      </DisciplinarComunProvider>
    </EspacioCurricularByIdProvider>
  );
};
