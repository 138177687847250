/* eslint-disable */
import * as React from 'react';

export const MiEscuela = (props?: React.SVGProps<SVGSVGElement>) => (
  <svg
    enableBackground="new 0 0 261 200"
    viewBox="0 0 360 150"
    width="500"
    height="250"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="m107.6 82.6h-106.3l7.7-17.3h90.8z" fill="#df6c27" />
    <path d="m82.8 82.6-28.4-28.4-28.7 28.4z" fill="#fad77f" />
    <path d="m.9 82.6h106.7v48.2h-106.7z" fill="#fad77f" />
    <path
      d="m62.6 81.8c0 4.5-3.7 8.3-8.3 8.3s-8.3-3.7-8.3-8.3c0-4.5 3.7-8.3 8.3-8.3s8.3 3.7 8.3 8.3z"
      fill="#f89a2e"
    />
    <path d="m52.2 25.2h21.1v20.7h-21.1z" fill="#00bcd7" />
    <path d="m70.2 29.6h21.1v20.7h-21.1z" fill="#00bcd7" />
    <path d="m52.3 32.3h20.9v7.2h-20.9z" fill="#fff" />
    <path d="m70.4 36.5h20.9v7.2h-20.9z" fill="#fff" />
    <path
      d="m107.4 81.1h-25.7l-26.7-25.9v-9.1h-2.9v9.3l-25.8 25.8h-25.4c-.3 0-.8.3-.8.8 0 .3.3.8.8.8h25.6c.2 0 .3 0 .5-.2l26.8-26.8 27.2 26.7c.2.2.3.2.5.2h26.1c.3 0 .8-.3.8-.8-.3-.4-.6-.8-1-.8z"
      fill="#e16c26"
    />
    <g fill="#f89a2e">
      <path d="m44.1 99.3h20.2v31.3h-20.2z" />
      <path d="m70.4 99.3h12v10.6h-12z" />
      <path d="m86.5 99.3h12v10.6h-12z" />
      <path d="m86.5 113.5h12v10.6h-12z" />
      <path d="m70.5 113.5h12v10.6h-12z" />
      <path d="m9.4 99.3h12v10.6h-12z" />
      <path d="m25.5 99.3h12v10.6h-12z" />
      <path d="m25.5 113.5h12v10.6h-12z" />
      <path d="m9.5 113.5h12v10.6h-12z" />
    </g>
    <path
      d="m125.5 96.1c0-1.7 1.3-2.9 2.7-2.9 1.7 0 2.9 1.3 2.9 2.9v1.2c1.7-2.4 3.7-4.2 7.6-4.2 3.5 0 6.2 1.9 7.4 4.4 2-2.5 4.5-4.4 8.4-4.4 5.4 0 8.8 3.4 8.8 9.6v13.3c0 1.7-1.3 2.7-2.7 2.7-1.7 0-2.9-1.3-2.9-2.7v-11.8c0-3.9-1.9-6.1-5.1-6.1-3 0-5.4 2.2-5.4 6.2v11.5c0 1.7-1.3 2.7-2.7 2.7-1.7 0-2.9-1.3-2.9-2.7v-11.6c0-3.9-2-6.1-5.1-6.1-3 0-5.4 2.4-5.4 6.2v11.5c0 1.7-1.3 2.7-2.9 2.7-1.7 0-2.7-1.3-2.7-2.7z"
      fill="#fff"
    />
    <path
      d="m170 86.8c0-1.7 1.5-2.7 3.2-2.7s3.2 1.2 3.2 2.7v.3c0 1.7-1.5 2.7-3.2 2.7s-3.2-1.2-3.2-2.7zm.5 9.3c0-1.7 1.3-2.9 2.7-2.9 1.7 0 2.9 1.3 2.9 2.9v19.7c0 1.7-1.3 2.7-2.9 2.7-1.7 0-2.7-1.3-2.7-2.7z"
      fill="#fff"
    />
    <path
      d="m184 114.8v-25.6c0-2 1.7-3.5 3.5-3.5h18.2c1.9 0 3.2 1.5 3.2 3.2s-1.5 3.2-3.2 3.2h-14.7v6.7h12.5c1.9 0 3.2 1.5 3.2 3.2 0 1.9-1.5 3.2-3.2 3.2h-12.5v7.1h15c1.9 0 3.2 1.5 3.2 3.2s-1.5 3.2-3.2 3.2h-18.5c-1.8-.4-3.5-1.9-3.5-3.9z"
      fill="#fff"
    />
    <path
      d="m213 115.9c-.8-.5-1.3-1.3-1.3-2.4 0-1.7 1.3-2.7 2.7-2.7.5 0 1 .2 1.5.3 2.4 1.5 4.9 2.4 6.9 2.4 2.2 0 3-.8 3-2v-.2c0-1.7-2.4-2.2-5.2-2.9-3.5-1-7.4-2.7-7.4-7.6v-.5c0-5.2 4-7.9 9.3-7.9 2.5 0 5.4.7 7.8 2 1 .5 1.7 1.5 1.7 2.7 0 1.7-1.3 2.7-2.9 2.7-.5 0-.8-.2-1.3-.3-2-1-3.9-1.7-5.6-1.7-1.9 0-2.5.8-2.5 1.9v.2c0 1.5 2.4 2.2 5.2 3 3.5 1.2 7.6 2.9 7.6 7.4v.2c0 5.6-4.2 8.1-9.6 8.1-3.5.2-6.7-.5-9.9-2.7z"
      fill="#fff"
    />
    <path
      d="m235.1 105.8c0-7.2 5.6-13.1 13.1-13.1 3.7 0 6.6 1 8.6 2.7.5.3 1.2 1.3 1.2 2.4 0 1.9-1.5 3.2-3.2 3.2-1 0-1.7-.3-2.2-.7-1.3-1-2.5-1.7-4.5-1.7-3.5 0-6.2 3-6.2 6.9v.2c0 3.9 2.4 7.1 6.4 7.1 2 0 3.4-.7 4.7-1.9.3-.3 1.2-.7 2-.7 1.7 0 3 1.3 3 3 0 1-.5 1.7-1 2.4-2.2 2-4.9 3.2-8.9 3.2-7.5.2-13-5.6-13-13z"
      fill="#fff"
    />
    <path
      d="m284.9 115.1c0 2-1.7 3.5-3.5 3.5-2 0-3.5-1.7-3.5-3.5v-.3c-1.7 2.2-3.7 4-7.2 4-5.4 0-8.6-3.5-8.6-9.4v-13c0-2 1.7-3.5 3.5-3.5 2 0 3.5 1.7 3.5 3.5v10.8c0 3.4 1.7 5.2 4.4 5.2s4.5-1.9 4.5-5.2v-10.8c0-2 1.7-3.5 3.5-3.5s3.5 1.7 3.5 3.5z"
      fill="#fff"
    />
    <path
      d="m302.1 119c-7.6 0-13.1-5.4-13.1-13v-.2c0-7.1 5.2-13.1 12.5-13.1 8.4 0 12.1 7.1 12.1 12.1 0 2.2-1.5 3.4-3.4 3.4h-14.2c.7 3.2 2.9 4.9 6.2 4.9 2 0 3.5-.7 5.1-1.7.5-.3 1-.5 1.9-.5 1.7 0 2.7 1.3 2.7 2.7 0 1-.5 1.7-1 2.2-2.2 2-5.1 3.2-8.8 3.2zm4.7-15.2c-.5-3.2-2.4-5.4-5.4-5.4-2.9 0-4.9 2.2-5.6 5.4z"
      fill="#fff"
    />
    <path
      d="m318.1 87.3c0-2 1.7-3.5 3.5-3.5 1.9 0 3.5 1.7 3.5 3.5v27.8c0 2-1.7 3.5-3.5 3.5-1.9 0-3.5-1.7-3.5-3.5z"
      fill="#fff"
    />
    <path
      d="m329.4 111.2c0-5.6 4.2-8.1 10.3-8.1 2.5 0 4.4.5 6.2 1v-.5c0-2.9-1.9-4.5-5.4-4.5-2 0-3.5.3-4.9.7-.5.2-.7.2-1 .2-1.7 0-2.9-1.3-2.9-2.9 0-1.3.8-2.4 2-2.7 2.4-.8 4.7-1.3 8.1-1.3 3.9 0 6.7 1 8.4 2.7 2 2 2.7 4.7 2.7 8.1v11.6c0 2-1.7 3.4-3.4 3.4-2.2 0-3.4-1.5-3.4-2.9-1.9 2-4 3.2-7.6 3.2-5.2-.4-9.1-3.1-9.1-8zm16.5-1.8v-1.4c-1.3-.5-2.7-1-4.5-1-3 0-4.9 1.3-4.9 3.4v.2c0 2 1.7 3 3.9 3 3.2.3 5.5-1.5 5.5-4.2z"
      fill="#fff"
    />
  </svg>
);
