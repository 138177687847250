import React from 'react';
import { Grid, Button, makeStyles } from '@material-ui/core';
import customFormDialog from 'src/commons/services/customFormDialog';
import { AddRolForm } from './AddRolForm';
import { Cuentas, UsuarioRolEstablecimiento } from 'src/app/models';

interface AddRolModalProps {
  usuario: Cuentas;
  roles: UsuarioRolEstablecimiento[];
  reFetch: () => void;
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    marginTop: theme.spacing(-4),
    marginRight: theme.spacing(4),
    position: 'relative',
  },
  modalContainer: {
    padding: theme.spacing(2),
  },
}));

const handleCloseModal = async () => {
  await customFormDialog.handleCancel();
};

export const AddRolModal = ({ usuario, roles, reFetch }: AddRolModalProps) => {
  const classes = useStyles();
  return (
    <Grid className={classes.modalContainer}>
      <Grid container>
        <Grid item container justifyContent="flex-end">
          <Button className={classes.closeButton} onClick={handleCloseModal}>
            X
          </Button>
        </Grid>
      </Grid>
      <AddRolForm usuario={usuario} roles={roles} reFetch={reFetch} />
    </Grid>
  );
};
