import { IconButton, TableCell } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React, { useCallback, useMemo } from 'react';
import cx from 'classnames';
import { ESTADO_PRESENTE } from '../../../../../../../commons';
import { useRolesContext } from '../../../../../../../context/RolesContext';
import { useEnableToSaveByRol } from '../../../hooks/useEnableToSaveByRol';
import { JustificacionCellProps } from '../../../types';
import { useJustificationCellStyles } from '../css';
import { usePresentismoContext } from '../../../context';
import moment from 'moment/moment';

const JUSTIFICACION_ENABLED = [
  ESTADO_PRESENTE.AUSENTE,
  ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE,
  ESTADO_PRESENTE.INGRESO_TARDIO,
  ESTADO_PRESENTE.RETIRO_ANTICIPADO,
];

export const JustificacionCell = ({
  row,
  isTabJornadaExtendida,
  onClick,
  pastDaysOfWeek,
}: JustificacionCellProps) => {
  const { currentWeek } = usePresentismoContext();
  const daySelected = moment(currentWeek)
    .startOf('isoWeek')
    .add(pastDaysOfWeek, 'days')
    .format('YYYY-MM-DD');
  const classes = useJustificationCellStyles();

  const {
    selectedRole: { rol },
  } = useRolesContext();

  const disabledByRol = useEnableToSaveByRol(
    isTabJornadaExtendida,
    Number(rol),
  );

  const handlerClick = useCallback(() => {
    onClick(row);
  }, [row, onClick]);

  const disabled = useMemo(() => {
    if (row.presentismo.length === 0) return true;
    const result = row.presentismo.filter(
      (presente) =>
        JUSTIFICACION_ENABLED.includes(
          presente.estadoPresente.idEstadoPresente,
        ) &&
        moment(presente.fecha).format('YYYY-MM-DD') ===
          moment(daySelected).format('YYYY-MM-DD'),
    );
    if (result.length === 0) return true;
    return result.every((presente) => !!presente.justificacion);
  }, [row, daySelected]);

  return (
    <TableCell align="center" className={cx(classes.cell, classes.noBorder)}>
      <IconButton
        disabled={disabledByRol || disabled}
        className={classes.iconButton}
        onClick={handlerClick}
      >
        <Edit className={classes.icon} />
      </IconButton>
    </TableCell>
  );
};
