import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { IndicadoresDeEvaluacionPlan } from 'src/app/models';
import { ListItemProperties } from '../../../feedbacks/commons';
import { useIndicadoresFetch } from '../../../forms/ArealInterareal/tabs/hooks';

export interface IndicadoresDeEvaluacionProps {
  data: IndicadoresDeEvaluacionPlan;
}

export const IndicadoresDeEvaluacion = ({
  data,
}: IndicadoresDeEvaluacionProps) => {
  const { indicadores, working } = useIndicadoresFetch();

  const filterIndicadores = useMemo(
    () =>
      data?.indicadores?.map((indicador: string) => {
        const valor = indicadores.find(
          (_indicador: any) =>
            _indicador?.idIndicadoresDeEvaluacion === indicador,
        );
        return {
          id: valor?.idIndicadoresDeEvaluacion || 1,
          descripcion: valor?.descripcion || '',
        };
      }),
    [data?.indicadores, indicadores],
  );

  filterIndicadores.push(...data?.adicionales);

  return (
    <Grid container direction="column">
      {!working && (
        <Grid>
          <ListItemProperties values={filterIndicadores} />
        </Grid>
      )}
    </Grid>
  );
};
