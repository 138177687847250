import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography, Grid } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { primary, grey } from 'src/theme/colors';

const statusPassColor: Record<string, any> = {
  Cancelado: grey.textPrimary,
  Pendiente: primary.warning,
  Rechazado: primary.error,
  Aprobado: primary.success,
};

interface IconStatusProps {
  description: string;
}

export const IconStatus = ({ description }: IconStatusProps) => {
  const statusStyles = makeStyles({
    statusName: {
      fontSize: '13',
    },
  });
  const classes = statusStyles();
  return (
    <Grid container>
      <Grid item>
        <FiberManualRecordIcon
          style={{
            fontSize: 12,
            padding: '5px',
            color: statusPassColor[description],
          }}
        />
      </Grid>
      <Grid item>
        <Typography className={classes.statusName}>{description}</Typography>
      </Grid>
    </Grid>
  );
};
