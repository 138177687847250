import { Box, Grid, Typography } from '@material-ui/core';
import React, { useState, useCallback, useMemo } from 'react';
import { OrientacionesSelectInput } from '../../../../../app/business/orientaciones/Orientaciones';
import { Loading, ROL, TRAVERSE_ACTIONS } from '../../../../../commons';
import { useNodosLibbyFetch } from '../../../../../app/business/orientaciones/Nodos';
import {
  useLocalizacionPlanEstudioNivelLibbyCall,
  usePlantaLibbyFetch,
  useUsuarioRolTraverseActionDAO,
  useUsuarioRolTraverseActionLibbyFetch,
} from '../../../../../app/business';
import { EjeWithDocente } from '../../types';
import { useSnackbar } from 'notistack';
import { Cuentas, UsuarioRolTraverseAction } from '../../../../../app/models';
import { useRolesContext } from '../../../../../context/RolesContext';
import { TableForm } from './FormView';

export const ConfiguracionForm = () => {
  const usuarioRolTraverseActionDAO = useUsuarioRolTraverseActionDAO();
  const { enqueueSnackbar } = useSnackbar();
  const [orientacion, setOrientacion] = useState('');
  const [loadingSave, setLoadingSave] = useState(false);
  const {
    selectedRole: { localizacionId },
  } = useRolesContext();

  // Orientaciiones del estaplecimineot por plan de estudio - Traera aquellas a las cuales el coordinador tiene acceso
  const { data: lizacionPlanEstudioNivel = [], working: lpenWorkiing } =
    useLocalizacionPlanEstudioNivelLibbyCall({
      methodName: 'getAll',
    });

  const orientacionIds = useMemo(() => {
    const ids = lizacionPlanEstudioNivel.reduce<number[]>((acum, item) => {
      item.planEstudioNivel.planEstudio.orientaciones
        .filter((peo) => peo.orientacion)
        .map((peo) => acum.push(peo.orientacion.idOrientacion));
      return acum;
    }, []);
    return [...new Set(ids)];
  }, [lizacionPlanEstudioNivel]);

  const filterDocentes = useMemo(() => {
    const ids: number[] = [];
    if (Boolean(orientacion)) {
      lizacionPlanEstudioNivel
        .filter((lpen) =>
          lpen.planEstudioNivel.planEstudio.orientaciones.find(
            (peo) => peo.orientacion.idOrientacion.toString() === orientacion,
          ),
        )
        .forEach((lpen) => {
          ids.push(lpen.planEstudioNivel.planEstudio.idPlanEstudio);
        });
    } else {
      lizacionPlanEstudioNivel.forEach((item) =>
        ids.push(item.planEstudioNivel.planEstudio.idPlanEstudio),
      );
    }

    const _ids = new Set(ids);
    return {
      planEstudio: [
        {
          path: 'espacioCurricular.planEstudio.idPlanEstudio',
          value: [..._ids],
          method: 'in',
        },
      ],
      seccion: [
        {
          path: 'seccion.anio',
          value: [25, 26],
          method: 'in',
        },
      ],
      materia: [
        {
          path: 'espacioCurricular.materia.anio',
          value: [25, 26],
          method: 'in',
        },
      ],
    };
  }, [lizacionPlanEstudioNivel, orientacion]);

  const {
    data: docentesPlanta = [],
    working,
    reFetch: docenteReFetch,
  } = usePlantaLibbyFetch({
    filter: filterDocentes,
    enabled: lizacionPlanEstudioNivel.length > 0,
    aspect: 'docentes',
    limit: 500,
    orderBy:
      'personalEducativo.persona.apellido,personalEducativo.persona.nombre',
  });

  const filterTraverses = useMemo(
    () => ({
      usuario: [
        {
          path: 'usuarioRolEstablecimiento.usuario.idUsuario',
          value: docentesPlanta.map(
            (d) => d.personalEducativo.persona.cuentas.idUsuario,
          ),
          method: 'in',
        },
      ],
      rol: [
        {
          path: 'usuarioRolEstablecimiento.rolUsuario', // relaciones
          value: ROL.DOCENTE,
        },
      ],
      localizacion: [
        {
          path: 'usuarioRolEstablecimiento.localizacion.idLocalizacion',
          value: localizacionId,
        },
      ],
    }),
    [docentesPlanta, localizacionId],
  );

  const {
    data: traverses = [],
    working: traverseWorking,
    reFetch: traverseReFecth,
  } = useUsuarioRolTraverseActionLibbyFetch({
    filter: filterTraverses,
    enabled: docentesPlanta.length > 0,
    limit: 500,
  });

  const orientacionesFilter = useMemo(() => {
    return {
      orientacion: [
        { path: 'idOrientacion', value: orientacionIds, method: 'in' },
      ],
    };
  }, [orientacionIds]);

  const filterNodos = useMemo(() => {
    return {
      orientacion: [{ path: 'orientacion.idOrientacion', value: orientacion }],
    };
  }, [orientacion]);

  const { data: nodos = [], working: wNodos } = useNodosLibbyFetch({
    filter: filterNodos,
    enabled: Boolean(orientacion),
    limit: 10000,
    orderBy: 'eje.nombre',
  });

  const ejesMapper = useMemo(() => {
    const ejeNodos: EjeWithDocente[] = [];

    const _docentes: Array<Cuentas & { name: string }> = [];
    docentesPlanta.forEach((item) => {
      const idUsuario = item.personalEducativo.persona.cuentas.idUsuario;
      const exist = _docentes.find((i) => i.idUsuario === idUsuario);
      if (!exist) {
        _docentes.push({
          ...item.personalEducativo.persona.cuentas,
          name:
            item.personalEducativo.persona.apellido +
            ' ' +
            item.personalEducativo.persona.nombre,
        });
      }
    });
    nodos.forEach((nodo) => {
      const idEje = nodo.eje.idEje;
      const exists = ejeNodos.find((ewd) => ewd.idEje === idEje);
      if (!exists) {
        ejeNodos.push({
          ...nodo.eje,
          nodos: [nodo],
          docenteEvaluador: _docentes,
        });
      } else {
        exists.nodos?.push(nodo);
      }
    });

    return ejeNodos;
  }, [nodos, docentesPlanta]);

  const initialValue = useMemo(() => {
    const _values: { [k: number]: string[] } = {};
    docentesPlanta.forEach((docente) => {
      const idUsuario = docente.personalEducativo.persona.cuentas.idUsuario;
      const _nodos = // A CA ESTAAN LOS ID NODOS AIGNADOS
        traverses.filter(
          (t) =>
            t.usuarioRolEstablecimiento.usuario.idUsuario === idUsuario &&
            t.traverseAction.traverseActionId === TRAVERSE_ACTIONS.NODO,
        )[0]?.value || [];
      // Recupero los ejes de los nodos

      for (const __nodo of _nodos) {
        const eje = nodos.find(
          (nodo) => nodo.idNodo.toString() === __nodo,
        )?.eje;
        if (!eje) continue;

        if (!_values[Number(eje.idEje)]) {
          _values[Number(eje.idEje)] = [idUsuario];
        } else if (
          !_values[Number(eje.idEje)].find((user) => user === idUsuario)
        ) {
          _values[Number(eje.idEje)] = [
            ..._values[Number(eje.idEje)],
            idUsuario,
          ];
        }
      }
      // Si no tiene ejes no aparece el docente
    });
    return _values;
  }, [docentesPlanta, traverses, nodos]);

  const handleChange = useCallback(
    (event) => {
      const target = event.target;
      setOrientacion(target.value?.toString());
    },
    [setOrientacion],
  );

  const handleSave = useCallback<
    (docentes: { [k: number]: string[] }) => () => Promise<void>
  >(
    (docentes) => async () => {
      setLoadingSave(true);
      const docenteTraverse: Map<string, UsuarioRolTraverseAction> = new Map<
        string,
        UsuarioRolTraverseAction
      >();
      // como guardo los docentes por eje y orientacion  o que pedo ??
      try {
        traverses.forEach((urta) => {
          if (urta.traverseAction.traverseActionId === TRAVERSE_ACTIONS.NODO) {
            docenteTraverse.set(
              urta.usuarioRolEstablecimiento.usuario.idUsuario,
              urta,
            );
          }
        });

        // tenngo a esos ddocetees con los ejes
        const docentesMap: Map<string, string[]> = new Map<string, string[]>();

        // Aca tengo los ejes que ienen docentes
        Object.entries(docentes).forEach(([k, value]) => {
          value.forEach((docente) => {
            if (docentesMap.has(docente)) {
              const _docente = docentesMap.get(docente)!;
              _docente.push(k);
              docentesMap.set(docente, _docente);
            } else {
              docentesMap.set(docente, [k]);
            }
          });
        });

        const toSave: Partial<UsuarioRolTraverseAction>[] = [];
        const toRemove: Partial<UsuarioRolTraverseAction>[] = [];
        const ids = docentesPlanta.map(
          (d) => d.personalEducativo.persona.cuentas.idUsuario,
        );
        const unniqueIds = [...new Set(ids)];

        for (const idUsuario of unniqueIds) {
          const usuarioRolTraverseAction = docenteTraverse.get(idUsuario);
          const ejes = docentesMap.get(idUsuario) || [];

          const usuarioRolEsablecimieno = traverses.find(
            (ure) =>
              ure.usuarioRolEstablecimiento.usuario.idUsuario === idUsuario,
          )?.usuarioRolEstablecimiento;
          // NO TIENE ERROR
          if (!usuarioRolEsablecimieno) continue;

          if (usuarioRolTraverseAction) {
            // Verifico si tiene ejes

            if (ejes.length > 0) {
              // Acualizo el URTA
              //recuperando nodos
              const nodosFinalTotal = [];
              for (const eje of ejes) {
                const nodosFinal = ejesMapper.find(
                  (em) => em.idEje.toString() === eje,
                )?.nodos;
                if (nodosFinal) {
                  nodosFinalTotal.push(...nodosFinal);
                }
              }
              const _toSave: Partial<any> = {
                ...usuarioRolTraverseAction,
                value: [...new Set(nodosFinalTotal.map((nodo) => nodo.idNodo))],
                traverseAction: {
                  traverseActionId: TRAVERSE_ACTIONS.NODO,
                },
                usuarioRolEstablecimiento:
                  usuarioRolTraverseAction.usuarioRolEstablecimiento,
              };
              toSave.push(_toSave);
            } else {
              // Elimino el URTA del docente
              toRemove.push(usuarioRolTraverseAction);
            }
          } else if (ejes.length > 0) {
            // Creo un URTA

            const nodosFinalTotal = [];
            for (const eje of ejes) {
              const nodosFinal = ejesMapper.find(
                (em) => em.idEje.toString() === eje,
              )?.nodos;
              if (nodosFinal) {
                nodosFinalTotal.push(...nodosFinal);
              }
            }

            const _toSave: Partial<any> = {
              value: [...nodosFinalTotal.map((nodo) => nodo.idNodo)],
              traverseAction: {
                traverseActionId: TRAVERSE_ACTIONS.NODO,
              },
              usuarioRolEstablecimiento: usuarioRolEsablecimieno,
            };
            toSave.push(_toSave);
          }
        }

        if (toSave.length > 0) {
          // Inserto o Acualizo los URTA de los docentes
          await usuarioRolTraverseActionDAO.save(toSave);
        }
        // HAAY QUE REMOVER SI QUEDA VACIO

        enqueueSnackbar('Operacion completada correctamente', {
          variant: 'success',
        });

        docenteReFetch();
        traverseReFecth();
      } catch (error) {
        enqueueSnackbar('No se pudo completar la operacion', {
          variant: 'error',
        });
      } finally {
        setLoadingSave(false);
      }
    },
    [
      traverses,
      usuarioRolTraverseActionDAO,
      docentesPlanta,
      ejesMapper,
      enqueueSnackbar,
      docenteReFetch,
      traverseReFecth,
      setLoadingSave,
    ],
  );

  return (
    <>
      <Grid component={Box} container id="filters" padding={4} marginBottom={6}>
        <Grid
          item
          xs={12}
          sm={4}
          md={3}
          container
          spacing={2}
          justifyContent="flex-start"
          alignItems="center"
        >
          <Grid item xs={12}>
            <Typography style={{ fontWeight: 'bold' }} color="textPrimary">
              Orientación
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <OrientacionesSelectInput
              label=""
              fullWidth
              value={orientacion}
              onChange={handleChange}
              handleChange={handleChange}
              filter={orientacionesFilter}
              enableFetch={lizacionPlanEstudioNivel.length > 0}
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      {working || traverseWorking ? (
        <Loading />
      ) : (
        <TableForm
          initialValue={initialValue}
          loading={wNodos || lpenWorkiing || working || traverseWorking}
          ejes={ejesMapper}
          handleSave={handleSave}
          loadingSave={loadingSave}
        />
      )}
    </>
  );
};
