import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { EstablecimientosAcap } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'establecimientos_acap';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEstablecimientosAcap',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEstablecimientosAcap',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EstablecimientosAcap
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EstablecimientosAcap,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.EstablecimientosAcap.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EstablecimientosAcap>(DAO_NAME, optionsToUse);

// hooks
export const useEstablecimientosAcapFetchById = fetchById;
export const useEstablecimientosAcapLibbyCall = libbyCall;
export const useEstablecimientosAcapDAO = libbyDAO;
export const useEstablecimientosAcapLibbyFetch = libbyFetch;

// components/hoc
export const EstablecimientosAcapAutocomplete = entityAutocomplete;
export const EstablecimientosAcapCall = renderPropCall;
export const EstablecimientosAcapById = renderPropFetchById;
export const EstablecimientosAcapSelect = simpleSelect;
export const EstablecimientosAcapAccordionSelector = accordionSelector;
export const EstablecimientosAcapSelectedChips = selectedChips;

// context
// ByIdContext
export const useEstablecimientosAcapByIdContext = useByIdContext;
export const EstablecimientosAcapByIdProvider = ByIdProvider;
export const EstablecimientosAcapByIdContext = ByIdContext;
// GeneralContext
export const useEstablecimientosAcapContext = useGeneralContext;
export const EstablecimientosAcapProvider = GeneralProvider;
export const EstablecimientosAcapContext = GeneralContext;
