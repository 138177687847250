import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { CicloLectivoSelect, INITIAL_YEAR } from 'src/app/business';
import { MainInput, SimpleSelect } from 'src/commons';
import { grey } from 'src/theme/colors';
import sortAnioSelect from '../../../passManagement/utils/sortAnioSelectContent';
import { InitialFilterTableProps } from '../types';

const jornadas = [
  {
    label: 'Completa',
  },
  {
    label: 'Extendida',
  },
  {
    label: 'Simple',
  },
];

const customStyles: { [k: string]: React.CSSProperties } = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  inputStyle: { padding: 10, marginTop: 23 },
};

export const InitialFilterTable = ({
  anios,
  turnos,
  planesEstudios,
  filter,
  currentCicloLectivo,
  setFilter,
}: InitialFilterTableProps) => {
  const filterCL = useMemo(
    () => ({
      clMin: [
        {
          path: 'anio',
          value: INITIAL_YEAR,
          method: 'higherOrEquals',
        },
      ],
      clMax: [
        {
          path: 'anio',
          value: INITIAL_YEAR + 4,
          method: 'lowerOrEquals',
        },
      ],
    }),
    [],
  );

  const handleChange = (
    e: React.ChangeEvent<{ name?: string; value: unknown }>,
  ) => {
    const name = e.target.name || '';
    const value = e.target.value;
    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const value =
    filter.cicloLectivo ||
    currentCicloLectivo?.idCicloLectivo?.toString() ||
    '';
  return (
    <Grid container justifyContent="flex-start" spacing={2} alignItems="center">
      <Grid item xs={2}>
        <CicloLectivoSelect
          title="Ciclo lectivo"
          name="cicloLectivo"
          value={value}
          placeholder="Seleccioná Ciclo"
          labelKey="anio"
          valueKey="idCicloLectivo"
          filter={filterCL}
          orderBy="anio"
          direction="desc"
          handleChange={({ target }) => {
            setFilter((prev) => ({
              ...prev,
              [target.name]: target.value,
            }));
          }}
          customStyleTitle={customStyles.customStyleTitleSelect}
        />
      </Grid>
      <Grid item xs={2}>
        <SimpleSelect
          name="anio"
          title="Año"
          placeholder="Seleccioná Año"
          labelKey="descripcionAnio"
          valueKey="idAnio"
          handleChange={handleChange}
          content={sortAnioSelect(anios)}
          value={filter.anio}
          customStyleTitle={customStyles.customStyleTitleSelect}
        />
      </Grid>
      <Grid item xs={2}>
        <SimpleSelect
          name="turno"
          title="Turno"
          placeholder="Seleccioná Turno"
          labelKey="descripcionTurno"
          valueKey="idTurno"
          content={turnos}
          handleChange={handleChange}
          value={filter.turno}
          customStyleTitle={customStyles.customStyleTitleSelect}
        />
      </Grid>
      <Grid item xs={2}>
        <SimpleSelect
          name="jornada"
          title="Jornada"
          placeholder="Seleccioná Jornada"
          labelKey="label"
          valueKey="label"
          content={jornadas}
          handleChange={handleChange}
          value={filter.jornada}
          customStyleTitle={customStyles.customStyleTitleSelect}
        />
      </Grid>
      <Grid item xs={2}>
        <SimpleSelect
          title="Plan de estudios"
          placeholder="Seleccioná Plan"
          labelKey="planEstudio.descripcionPlanEstudio"
          valueKey="idPlanEstudioNivel"
          content={planesEstudios}
          handleChange={handleChange}
          value={filter.planEstudioNivel}
          name="planEstudioNivel"
          customStyleTitle={customStyles.customStyleTitleSelect}
        />
      </Grid>
      <Grid item xs={2}>
        <Grid
          container
          justifyContent="flex-end"
          spacing={3}
          alignItems="flex-end"
        >
          <Grid item xs={12}>
            <MainInput
              type="search"
              handleChange={handleChange}
              placeholder="Buscar"
              name="search"
              value={filter.search}
              fullWidth
              customStyle={customStyles.inputStyle}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
