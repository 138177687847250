/* eslint-disable max-len,class-methods-use-this */
import { RESTDAO } from '@phinxlab/libby-rest-web';
import { DAOv2 } from '../LibbyV2';

/* Example of filter for use in fetch
 *   {
        searcher: [
          {
            path: 'nombre',
            value: search,
            // By default method is equals
          },
          {
            path: 'localizacion.descripcion',
            value: search,
            method: 'includes',
          },
        ],
        anotherFilter...
      }
 */

export interface Filter {
  [k: string]: { path: string; value: any; method?: string }[];
}

// TODO: fix this
interface FetchParams {
  filter: Filter;
  orderBy: any;
  offset: number;
  limit: number;
  direction: 'asc' | 'desc';
  aspect?: string;
}

// Extends this DAO if you want to use the useLibbyFetch hook
export class LibbyFetchDAO extends DAOv2 {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(name: string, pk: string) {
    super(name, pk);
  }

  fetch({
    filter,
    orderBy,
    offset = 0,
    limit = 20,
    direction = 'asc',
  }: Partial<FetchParams> = {}) {
    let query = this.applyFilters(this.query(), filter).limit(offset, limit);
    if (orderBy) {
      if (typeof orderBy === 'string')
        query = query.orderBy(orderBy, direction);
      else {
        orderBy.forEach((order: string) => {
          query = query.orderBy(order, direction);
        });
      }
    }
    return query.run();
  }

  // TODO: check
  fetchById(id: any, aspect?: string) {
    if (aspect) this.aspect(aspect);
    return this.query().equals(this.pk, id).run();
  }

  /*
   * Apply Filters Logic:
   *
   * - 1) Recorre todas las keys del filtro y devuelve un array de [key, value]
   * - 2) Elimina los filtros vacios
   * - 3) Toma como key del filtro el indice [0] y el valor a filtrar como el indice [1]
   * - 4) Si hay mas de un filtro agrega el item con and() a la query
   * - 5) Requiere para usar el filtro que tenga un path y un value en el indice [1] = { path, value }
   *   |NOTA| filterValue debe ser un [] de objetos { path, value }
   * - 6) Arma el filtro para los valores iguales al path => value y si hay varios los agrupa como or()
   * - 7) Cierra la query y la retorna con los filtros
   *
   */
  applyFilters(
    query: ReturnType<typeof RESTDAO.query>,
    filter: Filter = {},
  ): ReturnType<typeof RESTDAO.query> {
    Object.entries(filter)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .filter(([key, value]) => !!value && value.length)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      .forEach(([keyValue, filterValue], indexKey) => {
        if (indexKey) {
          // eslint-disable-next-line no-param-reassign
          query = query.and();
        }
        // eslint-disable-next-line no-param-reassign
        query = query.groupStart();
        filterValue.forEach(({ path, value, method = 'equals' }, index) => {
          // eslint-disable-next-line no-param-reassign
          query = query[method as keyof typeof query](`${path}`, value);
          if (index < filterValue.length - 1) {
            // eslint-disable-next-line no-param-reassign
            query = query.or();
          }
        });
        // eslint-disable-next-line no-param-reassign
        query = query.groupEnd();
      });
    return query;
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
