import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import React from 'react';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import GraphicEqIcon from '@material-ui/icons/GraphicEq';
import LinkIcon from '@material-ui/icons/Link';
import ImageIcon from '@material-ui/icons/Image';
import { contentCard } from 'src/theme/colors';

export const iconsCardRepositoryTypeItem: { [k: string]: React.ReactNode } = {
  1: <PlayCircleFilledIcon />,
  2: <PictureAsPdfIcon />,
  3: <LinkIcon />,
  4: <PictureAsPdfIcon />,
  5: <GraphicEqIcon />,
  6: <ImageIcon />,
};

export const colorTypeItem: { [k: number]: string } = {
  1: contentCard.biologia,
  2: contentCard.historia,
  3: contentCard.literatura,
  4: contentCard.biologia,
  5: contentCard.transversal,
  6: contentCard.tecnologia,
  7: contentCard.historia,
  8: contentCard.literatura,
  9: contentCard.transversal,
};
