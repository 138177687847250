import { PermissionEndpointMethod } from 'src/app/models/security/PermissionEndpointMethod';
import { LibbyFetchDAO } from './LibbyFetchDAO';
import { Rol } from 'src/app/models';

export class PermissionEndpointMethodDAO extends LibbyFetchDAO {
  constructor() {
    super('permission_endpoint_method', 'permissionEndpointMethodId');
  }

  async save(
    data: Omit<
      PermissionEndpointMethod,
      'permissionEndpointMethodId' | 'active'
    > & { rol: Rol },
  ) {
    await super.save(data);
  }
}
