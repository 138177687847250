import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { GruposSeccion } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'grupos_seccion';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idGruposSeccion',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idGruposSeccion',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.GruposSeccion
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.GruposSeccion,
      hoc: { ...defaultOptions.hoc, ...businessOptions.GruposSeccion.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<GruposSeccion>(DAO_NAME, optionsToUse);

// hooks
export const useGruposSeccionFetchById = fetchById;
export const useGruposSeccionLibbyCall = libbyCall;
export const useGruposSeccionDAO = libbyDAO;
export const useGruposSeccionLibbyFetch = libbyFetch;

// components/hoc
export const GruposSeccionAutocomplete = entityAutocomplete;
export const GruposSeccionCall = renderPropCall;
export const GruposSeccionById = renderPropFetchById;
export const GruposSeccionSelect = simpleSelect;
export const GruposSeccionAccordionSelector = accordionSelector;
export const GruposSeccionSelectedChips = selectedChips;

// context
// ByIdContext
export const useGruposSeccionByIdContext = useByIdContext;
export const GruposSeccionByIdProvider = ByIdProvider;
export const GruposSeccionByIdContext = ByIdContext;
// GeneralContext
export const useGruposSeccionContext = useGeneralContext;
export const GruposSeccionProvider = GeneralProvider;
export const GruposSeccionContext = GeneralContext;
