import React from 'react';
import { useParams } from 'react-router';
import { useBackButton, useRouteScreenTitle } from '../../../../commons/hooks';
import { RepositorioItem } from '../../../../app/models';
import {
  InitialValueDefinition,
  RepositorioABMEditValue,
  RepositorioItemForm,
} from './components';
import { Loading } from '../../../../commons/components/Loading';

const initialValue: InitialValueDefinition = {
  nombre: '',
  descripcion: '',
  fuente: '',
  fuente_anio: '',
  url: '',
  idRepositorioTipoItem: '',
  idCiclo: '',
  idNivel: '',
  idEspacioCurricular: '',
  idRepositorioAudienciaItem: '',
  idAnio: '',
  idTipoRecurso: '',
  idArea: '',
  idTransversal: '',
};

const fromRepositorioItemToInitialValue = (
  repositorioItem: RepositorioItem,
): InitialValueDefinition => ({
  nombre: repositorioItem.nombre,
  descripcion: repositorioItem.descripcion,
  fuente: repositorioItem.fuente,
  fuente_anio: repositorioItem.fuente_anio,
  url: repositorioItem.url,
  idRepositorioTipoItem:
    repositorioItem.repositorioTipoItem?.idRepositorioTipoItem,
  idRepositorioAudienciaItem:
    repositorioItem.repositorioAudienciaItem?.idRepositorioAudienciaItem,
  idNivel: repositorioItem.nivel?.idNivel,
  idCiclo: repositorioItem.ciclo?.idCiclo,
  idAnio: repositorioItem.anio?.idAnio,
  idEspacioCurricular: repositorioItem.espacioCurricular?.idEspacioCurricular,
  idTipoRecurso: repositorioItem.tipoRecurso?.idTipoRecurso,
  idArea: repositorioItem.area?.idArea,
  idTransversal: repositorioItem.transversal?.idTransversal,
});

export const RepositorioABMForm = () => {
  useBackButton('/repositorioabm');
  const { idRepositorioItem } = useParams();
  useRouteScreenTitle(
    idRepositorioItem === 'new' ? 'Cargar Recurso' : 'Editar Recurso',
  );

  return idRepositorioItem === 'new' ? (
    <RepositorioItemForm initialValue={initialValue} />
  ) : (
    <RepositorioABMEditValue<InitialValueDefinition>
      idRepositorioItem={idRepositorioItem}
      transformer={fromRepositorioItemToInitialValue}
    >
      {({ working, data, raw }) =>
        working || !data ? (
          <Loading />
        ) : (
          <RepositorioItemForm initialValue={data} raw={raw} />
        )
      }
    </RepositorioABMEditValue>
  );
};
