import { LibbyFetchDAO } from './LibbyFetchDAO';

export class FeedbackPlanOpcionDAO extends LibbyFetchDAO {
  constructor() {
    super('feedback_plan_opcion', 'idFeedbackPlanOpcion');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
