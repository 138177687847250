import { Box, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { SliderGroup, SliderInputItem } from '../../sliderGroup';
import { FormInputV2 } from '../../../../../../lib/templates';
import { useEvaluacionFormContext } from '../../../hooks/useEvaluacionFormContext';
import {
  ROL,
  useTabBarSetValue,
  useTabBarValue,
} from '../../../../../../commons';
import { useEscuelaContext } from '../../../context/EscuelaContext';
import { useRolesContext } from 'src/context/RolesContext';

export const PerfilEgresado = () => {
  const { tab, setTab } = useEscuelaContext();
  const { form, values } = useEvaluacionFormContext();
  const { tabIndex } = useTabBarValue();
  const {
    selectedRole: { rol },
  } = useRolesContext();

  const unauthorizedUser = [
    ROL.REFERENTE_ACAP,
    ROL.DIRECTIVO,
    ROL.EQUIPO_ACAP,
  ].includes(Number(rol));

  useEffect(() => {
    setTab(tabIndex);
  }, [tabIndex, setTab]);

  const hanleSliderChange = useCallback<
    (item: SliderInputItem, value: number) => void
  >(
    (item, value) => {
      const newValors = values.perfiles.valores.map((valor) => {
        let _newValue = valor;
        if (valor.idTipo.toString() === item.id.toString()) {
          _newValue.evaluacion = value;
        }
        return _newValue;
      });
      // @ts-ignore
      form.change('perfiles.valores', newValors);
    },
    [form, values.perfiles.valores],
  );

  return (
    <Grid container component={Box} paddingY={2} spacing={2}>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={6} container justifyContent="center">
          <SliderGroup
            sliders={values.perfiles.valores.map((v) => ({
              id: v.idTipo.toString(),
              label: v.descripcion,
              value: v.evaluacion,
            }))}
            onChange={hanleSliderChange}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} sm={2} md={2} lg={1}>
          <Typography
            style={{
              fontWeight: 600,
            }}
          >
            Observaciones:
          </Typography>
        </Grid>

        <Grid item container xs={12} sm={10} md={10} lg={11}>
          <Grid item xs={12} style={{ marginBottom: '10px' }}>
            <FormInputV2
              form={form}
              name={'perfiles.observaciones'}
              variant="outlined"
              multiline
              maxRows={4}
              disabled={unauthorizedUser}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
