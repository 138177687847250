import React, { useState, useMemo } from 'react';
import { AcapEstablecimientoContext } from './AcapEstablecimientoContext';
import { useCicloLectivoLibbyCall } from 'src/app/business';
import { accionesScreenValue, organizacionesScreenValue } from './initialValue';
import {
  ACAP_SCREEN,
  AcapEstablecimientoContextValue,
  AccionesScreen,
  OrganizacionesScreen,
} from './type';

export const AcapEstablecimientoContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [screen, setScreen] = useState<ACAP_SCREEN>(ACAP_SCREEN.ORGANIZACIONES);
  const [accionesScreen, setAccionesScreen] =
    useState<AccionesScreen>(accionesScreenValue);
  const [organizacionesScreen, setOrganizacionesScreen] =
    useState<OrganizacionesScreen>(organizacionesScreenValue);

  const { data: ciclosLectivos = [], working: cicloLectivoWorking } =
    useCicloLectivoLibbyCall({
      methodName: 'fetch',
      params: [
        {
          filter: {
            0: [
              {
                path: 'fechaInicio',
                value: '2023-01-01T00:00:00.000Z',
                method: 'higher',
              },
            ],
          },
        },
      ],
    });

  const value = useMemo<AcapEstablecimientoContextValue>(
    () => ({
      screen,
      accionesScreen,
      organizacionesScreen,
      ciclosLectivos,
      loading: cicloLectivoWorking,
      setScreen,
      setAccionesScreen,
      setOrganizacionesScreen,
    }),
    [
      accionesScreen,
      cicloLectivoWorking,
      ciclosLectivos,
      organizacionesScreen,
      screen,
    ],
  );

  return (
    <AcapEstablecimientoContext.Provider value={value}>
      {children}
    </AcapEstablecimientoContext.Provider>
  );
};
