import { LibbyFetchDAO } from './LibbyFetchDAO';
export class ProcesosDAO extends LibbyFetchDAO {
  constructor() {
    super('procesos', 'idProceso');
  }
  getAll() {
    return this.query().limit(0, 10000).run();
  }
  getProcessById(idProcess: number) {
    return this.query().equals('idProceso', idProcess).run();
  }
}
