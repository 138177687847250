import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
const useStyles = makeStyles({
  motivoList: {
    paddingLeft: '1rem',
  },
  label: {
    fontWeight: 'bold',
    marginRight: '1em',
  },
  text: {
    fontWeight: 'bold',
  },
  detail: {
    fontSize: '0.9em',
  },
});
const getdisabledBy = ({
  feriado,
  lowerDeadLine,
  emergencia,
  suspensionClases,
  noAuthorizedRol,
  futureDate,
  propuestaJE,
}) => {
  const motivos = [];

  if (futureDate) {
    const motivoFutureDate = {
      text: 'Fecha futura',
      detail: '',
    };
    motivos.push(motivoFutureDate);
  }

  if (feriado) {
    const motivoFeriado = {
      text: 'Día feriado',
      detail: `${feriado.description}`,
    };
    motivos.push(motivoFeriado);
  }

  if (lowerDeadLine) {
    const motivoLowerDeadLine = {
      text: 'Fecha límite',
      detail: lowerDeadLine,
    };
    motivos.push(motivoLowerDeadLine);
  }

  if (noAuthorizedRol) {
    const motivoRolNoAutorizado = {
      text: 'Rol no Autorizado',
      detail: '',
    };
    motivos.push(motivoRolNoAutorizado);
  }

  if (emergencia) {
    const motivoEmergencia = {
      text: 'Situación de Emergencia',
      detail: '',
    };
    motivos.push(motivoEmergencia);
  }

  if (suspensionClases) {
    const motivoSuspensionClases = {
      text: 'Situación de Suspensión de Clases',
      detail: '',
    };
    motivos.push(motivoSuspensionClases);
  }

  if (propuestaJE) {
    const motivoPropuestaJE = {
      text: 'No existe propuesta JE',
      detail: '',
    };
    motivos.push(motivoPropuestaJE);
  }

  if (motivos.length === 0) {
    const motivoDefault = {
      text: 'Día no habilitado',
      detail: '',
    };
    motivos.push(motivoDefault);
  }

  return motivos;
};

export const TooltipDisabledPresentismo = ({
  feriado,
  lowerDeadLine,
  emergencia,
  suspensionClases,
  noAuthorizedRol,
  futureDate,
  propuestaJE,
}) => {
  const classes = useStyles();
  return (
    <Grid>
      <div>
        <span className={classes.label}>
          {`${'No es posible tomar presentismo:'}`}
        </span>
        <ul className={classes.motivoList}>
          {getdisabledBy({
            feriado,
            lowerDeadLine,
            emergencia,
            suspensionClases,
            noAuthorizedRol,
            futureDate,
            propuestaJE,
          }).map((motivo) => (
            <li key={motivo.text}>
              <div className={classes.text}>{motivo.text}</div>
              <span className={classes.detail}>
                {motivo.detail ? `${motivo.detail ?? ''}` : ''}
              </span>
            </li>
          ))}
        </ul>
      </div>
    </Grid>
  );
};
