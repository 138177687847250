import React from 'react';
import { Grid, TextField, makeStyles } from '@material-ui/core';
import { AnioSelectInput } from 'src/app/business';
import SearchIcon from '@material-ui/icons/Search';
import { AnyObject } from 'src/commons';
import { SelectInput } from 'src/commons/components/SelectInput';
import { Anio, Seccion } from 'src/app/models';

const useStyles = makeStyles(() => ({
  selectors: {
    minWidth: 200,
  },
  contentActions: {
    paddingTop: 20,
  },
  selectLabel: {
    fontSize: 16,
    letterSpacing: 0,
    color: '#38485C',
    fontWeight: 'bold',
    fontFamily: 'Nunito',
  },

  selectInput: {
    backgroundColor: '#EEF5FC',
    border: 'none',
    height: 38,
  },
  selectBg: {
    backgroundColor: '#EEF5FC',
  },
}));

interface RegularidadHeaderProps {
  formValues: {
    anio: string;
    seccion: string;
  };
  aniosSelect: AnyObject | undefined;
  seccionesSelect: Seccion[];
  handleChange: (e: React.FormEvent<EventTarget>) => void;
  search: string;
  handleChangeSearch: (e: React.FormEvent<EventTarget>) => void;
}

export const RegularidadHeader = ({
  formValues,
  handleChange,
  aniosSelect,
  seccionesSelect,
  search,
  handleChangeSearch,
}: RegularidadHeaderProps) => {
  const { anio, seccion } = formValues;

  const classes = useStyles();

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={8}
        md={8}
        container
        justifyContent="flex-start"
        spacing={1}
        className={classes.contentActions}
      >
        <Grid item xs={2} className={classes.selectors}>
          <AnioSelectInput
            title="Año"
            placeholder="Seleccioná Año"
            labelKey="descripcionAnio"
            valueKey="idAnio"
            name="anio"
            fullWidth
            value={anio}
            orderBy="numeroAnio,descripcionAnio"
            handleChange={handleChange}
            filter={aniosSelect}
            InputLabelProps={{
              shrink: true,
              className: classes.selectLabel,
            }}
            InputProps={{
              className: classes.selectInput,
              disableUnderline: true,
            }}
            SelectProps={{ classes: { root: classes.selectBg } }}
          />
        </Grid>

        <Grid item className={classes.selectors}>
          <SelectInput
            title="Sección"
            placeholder={'Seleccioná Sección'}
            labelKey="nombreSeccion"
            valueKey="idSeccion"
            options={
              seccionesSelect?.filter((sec) => sec.anio.idAnio === anio) || []
            }
            handleChange={handleChange}
            value={seccion}
            name="seccion"
            fullWidth
            disabled={anio === ''}
            InputLabelProps={{
              shrink: true,
              className: classes.selectLabel,
            }}
            InputProps={{
              className: classes.selectInput,
              disableUnderline: true,
            }}
            SelectProps={{ classes: { root: classes.selectBg } }}
          />
        </Grid>
        <Grid
          item
          xs={3}
          className={classes.selectors}
          style={{ display: 'flex', alignItems: 'end' }}
        >
          <TextField
            name="Search"
            type="search"
            id="outlined-controlled"
            label=""
            placeholder="Buscar alumno"
            value={search}
            disabled={seccion === ''}
            onChange={handleChangeSearch}
            InputLabelProps={{
              shrink: true,
              className: classes.selectLabel,
            }}
            InputProps={{
              className: classes.selectInput,
              disableUnderline: true,
              startAdornment: (
                <SearchIcon fontSize="small" htmlColor="#38485C" />
              ),
              style: { width: 250 },
            }}
            SelectProps={{ classes: { root: classes.selectBg } }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
