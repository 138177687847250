import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { RolStatus } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'rolstatus';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idRolStatus',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idRolStatus',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.RolStatus
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.RolStatus,
      hoc: { ...defaultOptions.hoc, ...businessOptions.RolStatus.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<RolStatus>(DAO_NAME, optionsToUse);

// hooks
export const useRolStatusFetchById = fetchById;
export const useRolStatusLibbyCall = libbyCall;
export const useRolStatusDAO = libbyDAO;
export const useRolStatusLibbyFetch = libbyFetch;

// components/hoc
export const RolStatusAutocomplete = entityAutocomplete;
export const RolStatusCall = renderPropCall;
export const RolStatusById = renderPropFetchById;
export const RolStatusSelect = simpleSelect;
export const RolStatusAccordionSelector = accordionSelector;
export const RolStatusSelectedChips = selectedChips;

// context
// ByIdContext
export const useRolStatusByIdContext = useByIdContext;
export const RolStatusByIdProvider = ByIdProvider;
export const RolStatusByIdContext = ByIdContext;
// GeneralContext
export const useRolStatusContext = useGeneralContext;
export const RolStatusProvider = GeneralProvider;
export const RolStatusContext = GeneralContext;
