import { useMemo } from 'react';
import { primary } from 'src/theme/colors';

const headerStyle = {
  color: primary.lightBlue,
  textAlign: 'center',
};

export const useSintesisConceptualTableColumns = () => {
  return useMemo(
    () => [
      {
        id: 'nombre',
        label: 'Alumno',
        width: '30%',
        hideSortIcon: true,
        style: headerStyle,
      },
      {
        id: 'calificacionGeneral',
        label: 'Calificación General',
        width: '30%',
        hideSortIcon: true,
        style: headerStyle,
      },
      {
        id: 'observaciones',
        label: 'Observaciones',
        width: '30%',
        hideSortIcon: true,
        style: headerStyle,
      },
    ],
    [],
  );
};
