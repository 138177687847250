import { LibbyFetchDAO } from './LibbyFetchDAO';

export class GruposInscripcionesOfertaDAO extends LibbyFetchDAO {
  constructor() {
    super('grupos_inscripciones_ofertas', 'idGrupoInscripcion');
  }

  getAll() {
    return this.query().limit(0, 1000).run();
  }
}
