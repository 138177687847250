import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { useEffect, useMemo, useCallback, useRef } from 'react';
import {
  useMultiPresentismoChecks,
  usePresentismoContext,
} from '../../../context';
import { AlumnoMap } from '../../../types';
import { TURNOS } from '../components';
import { weekDays } from '../functions/cerrarSemana';
import { getContraturnosByDate } from '../functions/getContraturnosByDate';

const useStyle = makeStyles({
  focusabled: {
    outline: '#CCCED1 auto 1px',
  },
  borderLeft: {
    borderLeft: '1px solid #EBEBEB',
  },
  borderRight: {
    borderRight: '1px solid #EBEBEB',
  },
});

interface PersistPosition {
  target: HTMLDivElement | null;
  position: [number, number];
}

interface Day {
  id: number;
  day: string;
  nTurnos: number;
}

interface CellInfo {
  day: Day;
  target: HTMLDivElement;
}

enum CONTROLS {
  UP = 'up',
  DOWN = 'down',
  LEFT = 'left',
  RIGHT = 'right',
  ENTER = 'enter',
  TAB = 'tab',
}

interface InputKeyboardMap<T> {
  name: T;
  keys: Array<string>;
}

const inputControlMap: InputKeyboardMap<CONTROLS>[] = [
  { name: CONTROLS.UP, keys: ['ArrowUp', 'w', 'W'] },
  { name: CONTROLS.DOWN, keys: ['ArrowDown', 's', 'S'] },
  { name: CONTROLS.LEFT, keys: ['ArrowLeft', 'a', 'A'] },
  { name: CONTROLS.RIGHT, keys: ['ArrowRight', 'd', 'D'] },
  { name: CONTROLS.ENTER, keys: ['Enter'] },
  { name: CONTROLS.TAB, keys: ['Tab'] },
];

export const useGridSelection = (alumnos: AlumnoMap[] = []) => {
  const classes = useStyle();
  // table-presentismo
  const { turno } = useMultiPresentismoChecks();
  const { currentWeek, pastDaysOfWeek } = usePresentismoContext();

  const { numColumns } = useMemo(() => {
    const turnos = TURNOS[turno] || [];
    const contraturnos = alumnos[0]?.contraturnos || [];
    const columns: Day[] = [];
    for (const [idx, day] of weekDays.entries()) {
      const _day = moment(currentWeek).startOf('isoWeek').add(idx, 'days');
      const _contraturnos = getContraturnosByDate(
        contraturnos,
        moment(_day, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      );

      const _nTurnos = turnos.length + _contraturnos?.length;
      for (let j = 0; j < _nTurnos; j++) {
        columns.push({ id: idx, day, nTurnos: _nTurnos });
      }
    }
    return { numColumns: columns };
  }, [turno, alumnos, currentWeek]);

  const selected = useRef<PersistPosition>({ position: [0, 0], target: null });
  const navigation = useRef<[number, number]>([0, 0]);

  const onFocus = useCallback(
    (position: [number, number]) => (e: any) => {
      const target = e.target as HTMLDivElement;
      target.setAttribute('style', 'outline:#CCCED1 auto 1px;');
      if (
        !(
          selected.current.position[0] === position[0] &&
          selected.current.position[1] === position[1]
        )
      ) {
        selected.current.target?.classList.remove(classes.focusabled);
      }
    },
    [classes.focusabled],
  );

  const onBlur = useCallback(
    (position: [number, number]) => (e: any) => {
      const target = e.target as HTMLDivElement;
      target.removeAttribute('style');
    },
    [],
  );

  const onClik = useCallback(
    (position: [number, number]) => (e: any) => {
      const target = e.target as HTMLDivElement;
      target.classList.add(classes.focusabled);
      selected.current.position = position;
      selected.current.target = target;
      target.click();
      navigation.current = position;
    },
    [classes.focusabled],
  );

  const onKeyDown = useCallback(
    (position: [number, number]) => (e: any) => {
      const keyAction = inputControlMap
        .filter((imap) => imap.name === CONTROLS.TAB)
        .find((imap) =>
          imap.keys.some((key) => key === e.key || key === e.code),
        );
      if (!keyAction) return;
      navigation.current = position;
    },
    [],
  );

  const onKeyDownTable = useCallback(
    (matrix: Array<CellInfo[]>) => (e: any) => {
      const [x, y] = navigation.current;
      const _numColumns = matrix[0].length;
      const _numRows = matrix.length;

      const keyAction = inputControlMap
        .filter((imap) => imap.name !== CONTROLS.TAB)
        .find((imap) =>
          imap.keys.some((key) => key === e.key || key === e.code),
        );
      if (!keyAction) return;
      e.preventDefault();
      switch (keyAction.name) {
        case CONTROLS.UP:
          if (x === 0) {
            navigation.current = [x, y];
            matrix[x][y].target.focus();
          } else {
            navigation.current = [x - 1, y];
            matrix[x - 1][y].target.focus();
          }
          break;
        case CONTROLS.DOWN:
          if (x === _numRows - 1) {
            navigation.current = [x, y];
            matrix[x][y].target.focus();
          } else {
            navigation.current = [x + 1, y];
            matrix[x + 1][y].target.focus();
          }
          break;
        case CONTROLS.LEFT:
          if (x === 0 && y === 0) {
            // no hace nada sequeda alli
            navigation.current = [x, y];
            matrix[x][y].target.focus();
          } else if (y === 0) {
            // borde izq
            navigation.current = [x - 1, _numColumns - 1];
            matrix[x - 1][_numColumns - 1].target.focus();
          } else {
            // default
            navigation.current = [x, y - 1];
            matrix[x][y - 1].target.focus();
          }
          break;
        case CONTROLS.RIGHT:
          if (x === _numRows - 1 && y === _numColumns - 1) {
            // no hace nada sequeda alli
            navigation.current = [x, y];
            matrix[x][y].target.focus();
          } else if (y === _numColumns - 1) {
            // borde der
            navigation.current = [x + 1, 0];
            matrix[x + 1][0].target.focus();
          } else {
            // default
            navigation.current = [x, y + 1];
            matrix[x][y + 1].target.focus();
          }
          break;
        case CONTROLS.ENTER:
          matrix[x][y].target.focus();
          matrix[x][y].target.click();
          break;
        default:
          break;
      }
    },
    [],
  );

  useEffect(() => {
    if (alumnos.length === 0) return;
    const tableBody = document.getElementById('table-body-presentismo');
    if (!tableBody) return;
    const columns = tableBody.getElementsByClassName('asistencia-cell');
    const ArrColumns = Array.from(columns);
    for (const [key, column] of ArrColumns.entries()) {
      const isSelected = column.classList.contains('selected');
      column.classList.add(classes.borderLeft);
      column.classList.add(classes.borderRight);
      if (isSelected) {
        const numerKey = key + 1;
        const isFriday = (numerKey - 1) % 5 === 0;
        const left = ArrColumns[key - 1];
        const right = ArrColumns[key + 1];
        if (left && !isFriday) {
          left.classList.remove(classes.borderRight);
        }
        if (right) {
          right.classList.remove(classes.borderLeft);
        }
        column.classList.remove(classes.borderLeft);
        column.classList.remove(classes.borderRight);
      }
    }
  }, [pastDaysOfWeek, classes, alumnos]);

  useEffect(() => {
    if (alumnos.length === 0) return;
    const tableBody = document.getElementById('table-body-presentismo');
    if (!tableBody) return;
    const rows = tableBody.getElementsByClassName('grid-asistencia');
    const matrix: Array<CellInfo[]> = Array.from(
      Array(alumnos.length),
      () => new Array(numColumns.length),
    );
    let index = 0;
    for (let i = 0; i < alumnos.length; i++) {
      for (const [j, _day] of numColumns.entries()) {
        const _onFocus = onFocus([i, j]);
        const _onBlur = onBlur([i, j]);
        const _onKeyDown = onKeyDown([i, j]);
        const _onClick = onClik([i, j]);
        rows[index]?.setAttribute('tabindex', '0');
        rows[index]?.addEventListener('keydown', _onKeyDown);
        rows[index]?.addEventListener('focus', _onFocus);
        rows[index]?.addEventListener('blur', _onBlur);
        rows[index]?.addEventListener('click', _onClick);
        matrix[i][j] = { day: _day, target: rows[index] as HTMLDivElement };
        index += 1;
      }
    }
    const _onKeyDown = onKeyDownTable(matrix);
    tableBody?.addEventListener('keydown', _onKeyDown);
  }, [
    alumnos,
    numColumns,
    classes,
    onBlur,
    onFocus,
    onClik,
    onKeyDown,
    onKeyDownTable,
  ]);
};
