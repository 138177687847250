import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ProvinciaDAO extends LibbyFetchDAO {
  constructor() {
    super('provincia', 'idProvincia');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByPaisId(id: number) {
    return this.query().equals('pais.idPais', id).run();
  }
}
