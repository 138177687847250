import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { BloquePlan } from 'src/app/models';
import { useDisciplinarOrientadaContext } from '../../../forms/PlanificacionDisciplinarOrientada/Context';
import { ListItemProperties } from '../../commons';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));
export interface BloquesProps {
  data: BloquePlan;
}

export const Bloque = ({ data }: BloquesProps) => {
  // const classes = useStyles();
  const {
    data: { bloques },
    working,
  } = useDisciplinarOrientadaContext();

  const filterBloques = bloques.filter((bloque: any) => {
    return data?.bloques?.find((bloq: string) => bloq === bloque.idBloque);
  });
  return (
    <Grid container direction="column">
      {!working && (
        <ListItemProperties
          values={filterBloques.map(({ idBloque, descripcion }: any) => ({
            id: idBloque,
            descripcion,
          }))}
        />
      )}
    </Grid>
  );
};
