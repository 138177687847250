import { Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useEffect, useMemo, useState } from 'react';
import {
  useAlumnoMovimientoCustomDAO,
  useAlumnoMovimientoLibbyFetch,
  useLocalizacionLibbyFetch,
  useProyeccionDAO,
  useProyeccionEstadoLibbyFetch,
  useProyeccionLibbyFetch,
  useSeccionLibbyFetch,
} from 'src/app/business';
import { Footer, InfoTable, useDebounce } from 'src/commons';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useRolesContext } from 'src/context/RolesContext';
import { FilterTableAcompaniada } from '../components/filterTableAcompaniada';
import { usePromocionAcompaniadaColumns } from '../hooks/usePromocionAcompaniadaColumns';
import {
  AlumnoMovimiento,
  Proyeccion,
  Seccion,
  Localizacion,
} from 'src/app/models';
import { LibbyFetchReturn } from 'src/lib/libby/hooks';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';

export const PromocionAcompaniada = () => {
  const { selectedRole } = useRolesContext();
  const { localizacionId, nivel } = selectedRole;
  const [rowsMap, setRowsMap] = useState<any>([]);
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkStudent, setCheckStudent] = useState<string[]>([]);
  const [restoreFlag, setRestoreFlag] = useState(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [search, setSearch] = useState('');
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('alumno.persona.apellido');
  const [formValues, setFormValues] = useState({
    idSeccion: '',
    idAnio: '',
  });
  const searchDebounce = useDebounce(search, 500);
  const { cicloLectivo: cicloLectivoContext } = useColegioEfectivizadoContext();
  const filterLocalizacion2 = useMemo(
    () => ({
      0: [{ path: 'localizacion', value: localizacionId }],
    }),
    [localizacionId],
  );

  const { data: proyeccionEstado = [] }: LibbyFetchReturn<any> =
    useProyeccionEstadoLibbyFetch({
      filter: filterLocalizacion2,
      aspect: 'default',
    });

  const CURRENT_YEAR = cicloLectivoContext?.anio;

  const searchFilters = useMemo(
    () => [
      {
        path: 'alumno.persona.apellido',
        value: searchDebounce,
        method: 'includes',
      },
      {
        path: 'alumno.persona.nombre',
        value: searchDebounce,
        method: 'includes',
      },
      {
        path: 'alumno.persona.documento',
        value: searchDebounce,
        method: 'includes',
      },
    ],
    [searchDebounce],
  );

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setOrderBy(newOrderBy);
    setDirection(direction === 'asc' ? 'desc' : 'asc');
  };

  // EL FILTRO POR CICLO LECTIVO DEBERIA SER -1 AL CICLO LECTIVO DE LA ESCUELA
  const filterProyeccion = useMemo(
    () => ({
      0: [{ path: 'seccionDestino.localizacion', value: localizacionId }],
      1: [{ path: 'cicloLectivo.anio', value: CURRENT_YEAR - 1 }],
      2: [{ path: 'estadoPaseAnio.idEstadoPaseAnio', value: 5 }],
    }),
    [CURRENT_YEAR, localizacionId],
  );
  const { enqueueSnackbar } = useSnackbar();
  const alumnoMovimientoDAO = useAlumnoMovimientoCustomDAO();
  const proyeccionDAO = useProyeccionDAO();

  const {
    data: proyecciones = [],
    working,
    reFetch: reFetchProyecciones,
  } = useProyeccionLibbyFetch({
    limit: 500,
    filter: filterProyeccion,
    enabled: proyeccionEstado.length,
  });

  const filterAlumnoMovimiento = useMemo(
    () => ({
      0: [
        { path: 'seccion.localizacion.idLocalizacion', value: localizacionId },
      ],
      1: searchFilters,
      2: [{ path: 'seccion', value: formValues.idSeccion }],
    }),
    [formValues.idSeccion, localizacionId, searchFilters],
  );

  const {
    data: alumnos = [],
    working: workingAlumnoMovimiento,
    reFetch,
  } = useAlumnoMovimientoLibbyFetch({
    limit: 300,
    filter: filterAlumnoMovimiento,
    direction,
    orderBy,
    enabled: !!formValues.idSeccion,
  });

  const localizacionFilter = useMemo(
    () => ({
      0: [{ path: 'idLocalizacion', value: localizacionId }],
    }),
    [localizacionId],
  );

  const { data: localizacionData = [] }: LibbyFetchReturn<Localizacion> =
    useLocalizacionLibbyFetch({
      limit: 1,
      filter: localizacionFilter,
    });

  const filterSecciones = useMemo(
    () => ({
      1: [{ path: 'localizacion', value: localizacionId }],
    }),
    [localizacionId],
  );

  const { data: secciones = [] } = useSeccionLibbyFetch({
    limit: 500,
    filter: filterSecciones,
    orderBy: 'nombreSeccion',
  });

  const seccionesFiltered = useMemo(() => {
    const newSecciones = secciones.filter((element: Seccion) => {
      return element?.anio?.idAnio === formValues.idAnio;
    });

    if (formValues?.idAnio !== '') {
      return newSecciones;
    } else {
      return [];
    }
  }, [formValues.idAnio, secciones]);

  useEffect(() => {
    const formattedData: any = [];

    alumnos.forEach((alumno: AlumnoMovimiento) => {
      const proyeccion = proyecciones.find(
        (proyeccion: Proyeccion) =>
          proyeccion?.alumno?.idAlumno === alumno?.alumno?.idAlumno,
      );

      if (!!proyeccion) {
        formattedData.push({
          idAlumnoMovimiento: alumno?.idAlumnoMovimiento,
          idAlumno: alumno?.alumno?.idAlumno,
          estadoMatricula: alumno?.alumno?.estadoMatricula?.idEstadoMatricula,
          apellido: alumno?.alumno?.persona?.apellido,
          nombre: alumno?.alumno?.persona?.nombre,
          documento: alumno?.alumno?.persona?.documento,
          condicion: alumno?.alumno?.condicion?.descripcionCondicion,
          seccion: alumno?.seccion,
          proyeccion: proyeccion,
          statusPass: proyeccion?.estadoPaseAnio?.idEstadoPaseAnio,
          currentYear: alumno?.seccion?.anio,
          cicloLectivo: alumno?.seccion?.cicloLectivo?.anio,
          sectionPass: alumno?.seccion?.idSeccion,
        });
      }
    });

    setRowsMap(formattedData);
  }, [alumnos, proyecciones]);

  const handleChangePassStatus = (e: any, row: any) => {
    const newRows: any = rowsMap.map((element: any) => {
      if (element?.idAlumno === row?.idAlumno) {
        return {
          ...element,
          statusPass: e.target.value,
          sectionPass:
            Number(e.target.value) === 2 ? '' : row?.seccion?.idSeccion,
        };
      } else {
        return element;
      }
    });
    setRowsMap(newRows);
  };

  const handleChangePassSection = (e: any, row: any) => {
    const newRows: any = rowsMap.map((element: any) => {
      if (element?.idAlumno === row?.idAlumno) {
        return {
          ...element,
          sectionPass: e.target.value,
        };
      } else {
        return element;
      }
    });
    setRowsMap(newRows);
  };

  const onCancel = () => {
    const formattedData: any = [];

    alumnos.forEach((alumno: any) => {
      const proyeccion = proyecciones.find(
        (proyeccion: any) =>
          proyeccion?.alumno?.idAlumno === alumno?.alumno?.idAlumno,
      );

      if (!!proyeccion) {
        formattedData.push({
          idAlumnoMovimiento: alumno?.idAlumnoMovimiento,
          idAlumno: alumno?.alumno?.idAlumno,
          estadoMatricula: alumno?.alumno?.estadoMatricula?.idEstadoMatricula,
          apellido: alumno?.alumno?.persona?.apellido,
          nombre: alumno?.alumno?.persona?.nombre,
          documento: alumno?.alumno?.persona?.documento,
          condicion: alumno?.alumno?.condicion?.descripcionCondicion,
          seccion: alumno?.seccion,
          proyeccion: proyeccion,
          statusPass: proyeccion?.estadoPaseAnio?.idEstadoPaseAnio,
          currentYear: alumno?.seccion?.anio,
          cicloLectivo: alumno?.seccion?.cicloLectivo?.anio,
          sectionPass: alumno?.seccion?.idSeccion,
        });
      }
    });

    setRowsMap(formattedData);
    setCheckedAll(false);
    setCheckStudent([]);
  };

  const onSave = async () => {
    try {
      const confirm = await confirmDialog.show({
        title: 'Cambiar Sección',
        content:
          '¿Está seguro que desea cambiar la sección de el/los alumno(s) seleccionado(s)?',
        confirmText: 'Confirmar',
        cancelText: 'Cancelar',
      });
      if (confirm) {
        setIsSaving(true);
        for (let i = 0; i < rowsMap.length; i += 1) {
          if (rowsMap[i].statusPass === 2 || rowsMap[i].statusPass === 1) {
            const obj = {
              idAlumnoMovimiento: rowsMap[i].idAlumnoMovimiento,
              seccion: rowsMap[i].sectionPass,
              alumno: rowsMap[i]?.idAlumno,
              cicloLectivo: rowsMap[i]?.seccion?.cicloLectivo?.idCicloLectivo,
              estadoAlumno: rowsMap[i]?.estadoMatricula,
            };
            // eslint-disable-next-line no-await-in-loop
            await proyeccionDAO.save({
              idProyeccion: rowsMap[i]?.proyeccion?.idProyeccion,
              estadoPaseAnio: rowsMap[i]?.statusPass,
              alumno: rowsMap[i]?.idAlumno,
              articula: false,
              cicloLectivo: rowsMap[i]?.proyeccion?.idCicloLectivo,
              seccionDestino: rowsMap[i]?.proyeccion?.seccionDestino?.idSeccion,
              seccionOrigen: rowsMap[i]?.proyeccion?.seccionOrigen?.idSeccion,
            });
            // eslint-disable-next-line no-await-in-loop
            await alumnoMovimientoDAO.save(obj);
          }
        }
        reFetch();
        reFetchProyecciones();
        setIsSaving(false);
        enqueueSnackbar('Se guardó con éxito', {
          variant: 'success',
        });
      }
    } catch (e) {
      setIsSaving(false);
      console.error(e);
      enqueueSnackbar('Ha ocurrido un error', {
        variant: 'error',
      });
    }
  };

  const allCompleted = useMemo(() => {
    const isCompleted = [];

    rowsMap.forEach((row: any) => {
      if (
        row?.statusPass !== '' &&
        row?.statusPass !== undefined &&
        row?.sectionPass !== '' &&
        row?.sectionPass !== undefined
      ) {
        isCompleted.push(row);
      }
    });
    const completed = isCompleted.length === rowsMap.length;
    return completed;
  }, [rowsMap]);

  const footerButtons = [
    {
      title: 'Cancelar',
      type: 'secondary',
      handleOnClick: () => {
        onCancel();
      },
      disabled: isSaving || workingAlumnoMovimiento || working,
    },
    {
      title: 'Guardar',
      type: 'primary',
      handleOnClick: () => onSave(),
      disabled: !allCompleted || isSaving || workingAlumnoMovimiento || working,
    },
  ];

  const columns = usePromocionAcompaniadaColumns(
    secciones,
    handleChangePassStatus,
    localizacionData,
    nivel,
    handleChangePassSection,
    checkedAll,
    setCheckedAll,
    setCheckStudent,
    checkStudent,
    setRestoreFlag,
    restoreFlag,
    rowsMap,
    setRowsMap,
  );

  return (
    <>
      <FilterTableAcompaniada
        formValues={formValues}
        setFormValues={setFormValues}
        secciones={seccionesFiltered}
        setSearch={setSearch}
        search={search}
      />
      {formValues?.idSeccion ? (
        <>
          <InfoTable
            rows={isSaving || workingAlumnoMovimiento || working ? [] : rowsMap}
            columns={columns}
            working={isSaving || workingAlumnoMovimiento || working}
            onSortChange={handleRequestSort}
          />
          <Footer buttonConfig={footerButtons} spacing={3} />
        </>
      ) : (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: '400px' }}
        >
          <Typography
            style={{
              fontSize: '40px',
              marginTop: '50px',
              color: 'gray',
              fontWeight: 'bold',
            }}
          >
            Seleccione sección para continuar.
          </Typography>
        </Grid>
      )}
    </>
  );
};
