import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { SecundariaFuturo } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'secundaria_futuro';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idSecundariaFuturo',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idSecundariaFuturo',
      labelKey: 'descripcion',
    },
  },
};
const optionsToUse = !businessOptions.SecundariaFuturo
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.SecundariaFuturo,
      hoc: { ...defaultOptions.hoc, ...businessOptions.SecundariaFuturo.hoc },
    };
const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<SecundariaFuturo>(DAO_NAME, optionsToUse);

// hooks
export const useSecundariaFuturoFetchById = fetchById;
export const useSecundariaFuturoLibbyCall = libbyCall;
export const useSecundariaFuturoDAO = libbyDAO;
export const useSecundariaFuturoLibbyFetch = libbyFetch;

// components/hoc
export const SecundariaFuturoAutocomplete = entityAutocomplete;
export const SecundariaFuturoCall = renderPropCall;
export const SecundariaFuturoById = renderPropFetchById;
export const SecundariaFuturoSelect = simpleSelect;
export const SecundariaFuturoAccordionSelector = accordionSelector;
export const SecundariaFuturoSelectedChips = selectedChips;

// context
// ByIdContext
export const useSecundariaFuturoByIdContext = useByIdContext;
export const SecundariaFuturoByIdProvider = ByIdProvider;
export const SecundariaFuturoByIdContext = ByIdContext;
// GeneralContext
export const useSecundariaFuturoContext = useGeneralContext;
export const SecundariaFuturoProvider = GeneralProvider;
export const SecundariaFuturoContext = GeneralContext;
