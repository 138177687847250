import { orderBy } from 'lodash';
import moment from 'moment';
import { Organizacion } from '../../../../app/models';

const columnNames: string[] = [
  'Fecha ',
  'Organizacion ',
  'Referente Organizacion',
  'Sede ',
  'Actividad ',
  'Referente de Actividad',
  'Turno ',
  'Grupo ',
  '*',
  'Apellido y nombre',
  'Documento ',
  'Asistencia ',
];

const presentismoEnum: { [k: number]: string } = {
  1: 'Presente',
  2: 'Ausente',
  3: 'No Corresponde',
  6: 'Ingreso Tardío',
  7: 'Retiro anticipado',
};

export const useGetExcelData = (
  rows: Acap.Referente.RowOfertaGrupo[] = [],
  title: string,
  formValues: any,
  organizacionSeleccionada?: Organizacion,
) => {
  const indexOfSede =
    organizacionSeleccionada?.sedes?.findIndex(
      (sede) => sede?.idSede === formValues?.sede,
    ) || -1;

  const rowsForExcel = (
    rows.length > 1
      ? rows?.sort((a, b) =>
          a?.alumnoMovimiento?.alumno?.persona?.apellido >
          b?.alumnoMovimiento?.alumno?.persona?.apellido
            ? 1
            : -1,
        )
      : rows
  )?.map((row, index: number) => {
    return {
      'Fecha ':
        index === 0 ? moment(formValues?.fecha)?.format('YYYY-MM-DD') : '',
      'Organizacion ': index === 0 ? organizacionSeleccionada?.nombre : '',
      'Referente Organizacion':
        index === 0
          ? `${organizacionSeleccionada?.referente?.apellido}, ${organizacionSeleccionada?.referente?.nombre}`
          : '',
      Sede:
        index === 0
          ? indexOfSede !== -1
            ? organizacionSeleccionada?.sedes[indexOfSede]?.direccion
            : ''
          : '',
      Actividad:
        index === 0
          ? row?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.nombre
          : '',
      'Referente de Actividad':
        index === 0 ? row?.ofertaGrupo?.referente?.nombre : '',
      Turno:
        index === 0
          ? row?.ofertaGrupo?.ofertaTurno?.turno?.descripcionTurno
          : '',
      Grupo:
        index === 0
          ? `${row?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.nombre}, ${row?.ofertaGrupo?.ofertaTurno?.turno?.descripcionTurno}, id: ${row?.ofertaGrupo?.idOfertaGrupo}`
          : '',
      '': '',
      'Apellido y nombre': `${row.alumnoMovimiento.alumno.persona.apellido}, ${row.alumnoMovimiento.alumno.persona.nombre}`,
      'Documento ': row?.alumnoMovimiento?.alumno?.persona?.documento,
      'Asistencia ': presentismoEnum[`${row?.presentismo}`],
    };
  });

  return {
    body: {
      columns: columnNames,
      data: rowsForExcel,
    },
    name: title,
  };
};
