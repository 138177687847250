import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useConfirmacionMatriculaGeneracionDAO } from 'src/app/business/businessCustomEndpoints/matriculacion/ConfirmacionMatriculaGeneracion';
import { confirmacionBtnHandler } from '../lib/confirmacionHandler';
import { useConfirmacionMatriculaCountDAO } from 'src/app/business/businessCustomEndpoints/matriculacion/ConfirmacionMatricula';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';

export const useConfirmarMatricula = () => {
  const confirmacionCountDAO = useConfirmacionMatriculaCountDAO();
  const generacionMatriculaDAO = useConfirmacionMatriculaGeneracionDAO();
  const [confirmacionInfoLoading, setConfirmacionInfoLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const { cicloLectivo } = useColegioEfectivizadoContext();
  return {
    confirmarMatricula: async () => {
      setConfirmacionInfoLoading(true);
      try {
        const response = await confirmacionCountDAO.getInfoCount();
        setConfirmacionInfoLoading(false);
        if (response.matriculas === 0) {
          let message =
            'No es posible confirmar la matrícula debido a que no hay ninguna matrícula.';
          enqueueSnackbar(message, {
            variant: 'error',
          });
        } else {
          const isConfirmed = await confirmacionBtnHandler(
            response.matriculas,
            response.secciones,
            cicloLectivo?.anio,
          );
          if (isConfirmed) {
            /**
             * POST al server con el id
             * del colegio para armar el PDF
             */
            setConfirmacionInfoLoading(true);
            const response = await generacionMatriculaDAO.confirm();
            setConfirmacionInfoLoading(false);
            let message = '';
            if (response.link) {
              window.open(response.link, '_blank');
              window.location.reload();
              message = 'Confirmada exitosamente la matrícula del colegio.';
            } else {
              message =
                'No se pudo confirmar la matrícula, contacte con soporte.';
            }

            enqueueSnackbar(message, {
              variant: response.link ? 'success' : 'error',
            });
          }
        }
      } catch (error) {
        enqueueSnackbar('No se pudo guardar la confirmación', {
          variant: 'error',
        });
      } finally {
        setConfirmacionInfoLoading(false);
      }
    },
    confirmacionInfoLoading,
  };
};
