import { Rules } from 'src/lib/permission/types';
import { BLOCKER_ID, SIDEMENU_ID, TABBAR_BLOCKER_ID } from '../../const';

// route supervisor
export const supervisorRulesSets: Rules = {
  idRule: 'rol-11',
  routes: [
    { key: 'repositorioABM' },
    { key: 'planificacionesFeedback' },
    { key: 'aulasvirtuales' },
    { key: 'configurationAdmin' },
  ],
  blockers: [
    { id: BLOCKER_ID.REQUEST_ROL_ESTUDIANTE },
    { id: BLOCKER_ID.REQUEST_ROL_RESPONSABLE },
    { id: BLOCKER_ID.VALIDATE_SUPERVISOR_USER },
    { id: BLOCKER_ID.APPROVE_SUPERVISOR_USER },
    { id: BLOCKER_ID.APPROVE_DIRECTIVE_USER },
    { id: BLOCKER_ID.REMOVE_SUPERVISOR_ESTABLISHMENT },
    { id: BLOCKER_ID.REMOVE_DIRECTIVE_ESTABLISHMENT },
    { id: BLOCKER_ID.EDIT_PROFILE_USERS },
    { id: BLOCKER_ID.VIEW_REPOSITORY_ABM },
    { id: BLOCKER_ID.DELETE_PLANIFICATIONS },
    { id: BLOCKER_ID.EDIT_PLANIFICATIONS },
    { id: BLOCKER_ID.ADD_PLANIFICATIONS },
    { id: BLOCKER_ID.VIEW_PASS_REQUEST },
    { id: BLOCKER_ID.EDIT_ACTIVITIES },
    { id: BLOCKER_ID.DELETE_ACTIVITIES },
    { id: BLOCKER_ID.ADD_PLANIFICATIONS },
    { id: BLOCKER_ID.EDIT_PLANIFICATIONS },
    { id: BLOCKER_ID.ESTABLISHMENT_PASS_MANAGEMENT },
  ],
  sideMenu: [
    { id: SIDEMENU_ID.REPOSITORY_ABM },
    { id: SIDEMENU_ID.COMUNICATIONS },
    { id: SIDEMENU_ID.NOTIFICATIONS },
    { id: SIDEMENU_ID.CALENDAR },
    { id: SIDEMENU_ID.DOCUMENTS },
    { id: SIDEMENU_ID.ALUMNOS_AULAS_VRITUALES },
    { id: SIDEMENU_ID.CONFIGURACION_ADMIN },
  ],
  tabBars: [{ id: TABBAR_BLOCKER_ID.PRESENTISMO_CONFIGURACION }],
};
