import { EspacioCurricular } from 'src/app/models';
import { LibbyFetchDAO } from './LibbyFetchDAO';

export class EspacioCurricularDAO extends LibbyFetchDAO {
  constructor() {
    super('espacio_curricular', 'idEspacioCurricular');
  }

  async getAll(limit = 10000): Promise<EspacioCurricular[]> {
    let data = [];
    try {
      data = await this.query().limit(0, limit).run();
    } catch (error) {
      console.error(error);
    }
    return data;
  }

  async getJE(limit = 10000): Promise<EspacioCurricular[]> {
    let data = [];
    try {
      data = await this.query()
        .limit(0, limit)
        .equals('descripcion', 'Jornada Extendida')
        .or()
        .equals('descripcion', 'Jornada extendida')
        .run();
    } catch (error) {
      console.error(error);
    }
    return data;
  }

  getByLocalizacionId(localizacionId: string) {
    return this.query().equals('localizacion', localizacionId).run();
  }

  getByLocalizacionIdAndByNivel(localizacionId: string, idNivel: number) {
    return this.query()
      .equals('localizacion', localizacionId)
      .and()
      .equals('materia.anio.nivel', idNivel)
      .run();
  }

  getJornadaExtendida(idLocalizacion: number) {
    if (idLocalizacion === null)
      throw new Error('Los parametros deben ser válidos');
    return this.query()
      .limit(0, 5)
      .equals('localizacion', idLocalizacion)
      .and()
      .equals('descripcion', 'Jornada Extendida')
      .run();
  }
}
