import React, { useRef } from 'react';
import { Grid } from '@material-ui/core';
import { useRolesContext } from 'src/context/RolesContext';
import {
  SeccionProvider,
  InterdiscipPlanFeedbackCall,
  useInterdiscipPlanFeedbackLibbyFetch,
} from 'src/app/business';
import {
  InfoCard,
  PlanificacionFeedbackForm,
  PlanificacionFeedbackView,
} from '../commons/components';
import { useInfoCards } from './hooks';
import {
  EspaciosInterdiscipPlan,
  InterdiscipOrientaPlan,
  InterdiscipPlanFeedback,
} from '../../../../../../../app/models';
import { InterdisciplinarFlowHeader } from './components';
import {
  useInterdiscipPlanFeedbackDAO,
  useInterdiscipPlanDAO,
} from '../../../../../../../app/business';
import { PLANIFICACION_ESTADOS } from '../../../../../../../commons/const';
import { Loading } from '../../../../../../../commons/components/Loading';
import { InterdisciplinarProvider } from '../../forms/commons/components';
import { useFeedbackOnSubmit } from '../commons/hooks';

export interface InterdisciplinarFlowProps {
  data: EspaciosInterdiscipPlan;
}

export const InterdisciplinarFlow = ({ data }: InterdisciplinarFlowProps) => {
  const cards = useInfoCards(data);

  const interdiscipOrientaciones = data.interdiscipPlan.interdiscipOrientaPlans;
  const { selectedRole } = useRolesContext();
  const orientaciones =
    interdiscipOrientaciones?.map(
      (orientacion: InterdiscipOrientaPlan) => orientacion.orientacion,
    ) || [];
  const seccionFilter = useRef({
    filter: [{ path: 'localizacion', value: selectedRole.localizacionId }],
  });

  const interdiscipPlanFeedbackDAO = useInterdiscipPlanFeedbackDAO();
  const interdiscipPlanDAO = useInterdiscipPlanDAO();
  const onSubmit = useFeedbackOnSubmit({
    planDAO: interdiscipPlanDAO,
    feedbackDAO: interdiscipPlanFeedbackDAO,
    plan: data.interdiscipPlan,
    aspectFeedback: 'limit_province',
  });

  const idSend =
    data.interdiscipPlan.planificacionEstados.idPlanificacionEstados ===
    PLANIFICACION_ESTADOS.ENVIADO;

  return (
    <SeccionProvider filter={seccionFilter.current}>
      <>
        <InterdisciplinarFlowHeader data={data} />
        <Grid container>
          <Grid item sm={6}>
            {/*      <EspacioCurricularByIdProvider id={data.espacioCurricular.idEspacioCurricular}> */}

            <InterdisciplinarProvider
              orientaciones={orientaciones}
              idPlanEstudio={data.espacioCurricular.planEstudio.idPlanEstudio}
              idAnio={data.espacioCurricular.materia.anio.idAnio}
            >
              <InfoCard title="Planificación" cards={cards} />
            </InterdisciplinarProvider>

            {/*    </EspacioCurricularByIdProvider> */}
          </Grid>
          <Grid item sm={6}>
            <InterdiscipPlanFeedbackCall<InterdiscipPlanFeedback>
              methodName="getLastFeedback"
              params={[data.interdiscipPlan.idInterdiscipPlan]}
              aspect="limit_province"
            >
              {({ data: planFeedback, working }) =>
                working || planFeedback === undefined ? (
                  <Loading />
                ) : idSend ? (
                  <PlanificacionFeedbackForm
                    onSubmit={onSubmit}
                    data={planFeedback}
                  />
                ) : (
                  <PlanificacionFeedbackView data={planFeedback.feedback} />
                )
              }
            </InterdiscipPlanFeedbackCall>
          </Grid>
        </Grid>
      </>
    </SeccionProvider>
  );
};
