import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { FeedbackPlanItem } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'feedback_plan_item';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idFeedbackPlanItem',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idFeedbackPlanItem',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.FeedbackPlanItem
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.FeedbackPlanItem,
      hoc: { ...defaultOptions.hoc, ...businessOptions.FeedbackPlanItem.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<FeedbackPlanItem>(DAO_NAME, optionsToUse);

// hooks
export const useFeedbackPlanItemFetchById = fetchById;
export const useFeedbackPlanItemLibbyCall = libbyCall;
export const useFeedbackPlanItemDAO = libbyDAO;
export const useFeedbackPlanItemLibbyFetch = libbyFetch;

// components/hoc
export const FeedbackPlanItemAutocomplete = entityAutocomplete;
export const FeedbackPlanItemCall = renderPropCall;
export const FeedbackPlanItemById = renderPropFetchById;
export const FeedbackPlanItemSelect = simpleSelect;
export const FeedbackPlanItemAccordionSelector = accordionSelector;
export const FeedbackPlanItemSelectedChips = selectedChips;

// context
// ByIdContext
export const useFeedbackPlanItemByIdContext = useByIdContext;
export const FeedbackPlanItemByIdProvider = ByIdProvider;
export const FeedbackPlanItemByIdContext = ByIdContext;
// GeneralContext
export const useFeedbackPlanItemContext = useGeneralContext;
export const FeedbackPlanItemProvider = GeneralProvider;
export const FeedbackPlanItemContext = GeneralContext;
