import { useMemo } from 'react';
import { primary } from 'src/theme/colors';

const headerStyle = {
  color: primary.lightBlue,
  textAlign: 'center',
};

export const useColumns = () => {
  return useMemo(
    () => [
      {
        id: 'nombre',
        label: 'Alumno',
        width: '5%',
        hideSortIcon: true,
        style: headerStyle,
      },
      {
        id: 'logro',
        label: 'Curricular/Logro de los aprendizajes:',
        width: '10%',
        hideSortIcon: true,
        style: headerStyle,
      },
      {
        id: 'socioAfectiva',
        label: 'Socioafectiva',
        width: '10%',
        hideSortIcon: true,
        style: headerStyle,
      },
      {
        id: 'observaciones',
        label: 'Observaciones',
        width: '10%',
        hideSortIcon: true,
        style: headerStyle,
      },
    ],
    [],
  );
};
