import { Grid } from '@material-ui/core';
import moment from 'moment';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import { useJustificacionDAO } from '../../../../app/business/presentismo_v3/Justificacion';
import { usePresenteV3DAO } from '../../../../app/business/presentismo_v3/Presente';
import {
  ContraturnoDia,
  JustificacionFile,
  Presente_v3,
} from '../../../../app/models';
import { Loading, RAZON_JUSTIFICACION, TURNOS } from '../../../../commons';
import { FormContextProvider } from '../../../../lib/templates';
import { AlumnoMap } from '../TomaPresentismo/types';
import {
  JustificacionForm,
  JustificacionTitle,
  razonJustificacionEstadoPresente,
} from './components';
import { useGetTurnos } from './hooks';
import { JustificacionFormTemplate } from './template';
import { JustificacionInitialValue } from './template/initialValue';
import { JustificacionInitialValues, RangoFecha, StudentDetail } from './types';
import { DeepPartial } from '../../../../commons/types/DeepPartial';
import { usePresentismoContext } from '../TomaPresentismo/context';
import { getContraturnosByDate } from '../TomaPresentismo/components/PresentismoTable/functions/getContraturnosByDate';
import { getEspacioJE } from '../TomaPresentismo/utils/getEspacioJE';

interface JustificacionProps {
  data: AlumnoMap;
  handleClose: () => void;
  onNext: () => void;
  onCancel: () => void;
  isJornadaExtendida: boolean;
  selectedDay: moment.Moment;
  contraturnosDia: ContraturnoDia[];
}

export const Justificacion = ({
  data,
  isJornadaExtendida,
  onCancel,
  handleClose,
  selectedDay,
  onNext,
  contraturnosDia: ct,
}: JustificacionProps) => {
  const [contraturnosDia, setContraturnosDia] = useState<ContraturnoDia[]>([]);
  const formRef = useRef<{ presentismosAll: Presente_v3[] } | undefined>();
  const { periodoNuevo, propuestasJE } = usePresentismoContext();

  const refreshContraturnos = useCallback(
    ({ fechaDesde, fechaHasta }: RangoFecha) => {
      const contraturnosFiltered = ct.filter((dia) => {
        const diferencia = moment(fechaHasta).diff(fechaDesde, 'days');
        let isValid = false;
        for (let i = 0; i <= diferencia; i++) {
          const current = moment(fechaDesde).add(i, 'd');
          const matchDay =
            current
              .format('dddd')
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, '')
              .toLowerCase() === dia.dia.nombre.toLowerCase();
          if (
            matchDay &&
            current.isBetween(
              moment(dia.contraturno.fechaInicio),
              moment(dia.contraturno.fechaFin),
            )
          ) {
            isValid = true;
            break;
          }
        }
        return isValid;
      });
      setContraturnosDia(contraturnosFiltered);
    },
    [ct, setContraturnosDia],
  );

  const [loading, setLoading] = useState(false);
  const presenteV3DAO = usePresenteV3DAO();
  const justificacionDAO = useJustificacionDAO();
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [ausencias, setAusencias] = useState<Presente_v3[]>([]);

  const studentDetail = useMemo<StudentDetail[]>(
    () => [
      {
        title: 'Estudiante',
        description: `${data?.alumno.persona.apellido} ${data?.alumno.persona.nombre}`,
        xs: 3,
        sm: 2,
        md: 2,
      },
      {
        title: 'Año',
        description: data?.seccion.anio.descripcionAnio,
        xs: 2,
        sm: 2,
        md: 2,
      },
      {
        title: 'Sección',
        description: data?.seccion.nombreSeccion,
        xs: 4,
        sm: 3,
        md: 4,
      },
    ],
    [data],
  );

  const { turnos, contraturnosOptions, working } = useGetTurnos({
    isJornadaExtendida,
    seccionTurno: data.seccion.turno.idTurno,
    presentismos: data.presentismo,
    selectedDay,
    contraturnosDia,
  });

  const onSubmit = useCallback(
    async (values: JustificacionInitialValues) => {
      setLoading(true);
      try {
        const filesInfo: JustificacionFile[] = await Promise.all(
          values.files.map((file: File) => {
            return new Promise<JustificacionFile>((resolve) => {
              const reader = new FileReader();
              reader.onload = () => {
                resolve({
                  data: (reader.result as string).split('base64,')[1],
                  name: file.name,
                  mimeType: file.type,
                });
              };
              reader.readAsDataURL(file);
            });
          }),
        );
        const toSaveJustificacion = {
          ...values,
          files: filesInfo,
          horaSalida: values.horaSalida
            ? moment(values.horaSalida).format('HH:mm:ss')
            : null,
        };

        const res = await justificacionDAO.save(toSaveJustificacion);
        const presentes: DeepPartial<Presente_v3>[] = [];
        const propuestaJeFiedIt = propuestasJE
          .filter((seccion) => {
            return (
              seccion.seccion.idSeccion === data.seccion.idSeccion.toString()
            );
          })
          .map((item) => {
            return item.dias;
          })
          .flat()
          .map((dias) => {
            return dias.dia.idDia;
          });

        const diferencia = moment(values.fechaHasta, 'YYYY-MM-DD').diff(
          moment(values.fechaDesde, 'YYYY-MM-DD'),
          'days',
        );
        for (let i = 0; i <= diferencia; i++) {
          const current = moment(values.fechaDesde, 'YYYY-MM-DD').add(i, 'd');
          if (
            moment(current, 'YYYY-MM-DD').day() === 0 ||
            moment(current, 'YYYY-MM-DD').day() === 6
          ) {
            // SABADOS Y DOMINGO QUEDAN FUERA
            continue;
          }
          let diaJeExist = propuestaJeFiedIt.includes(
            moment(current, 'YYYY-MM-DD').day(),
          );
          if (isJornadaExtendida && !diaJeExist) {
            continue;
          }

          const periodoActual = periodoNuevo.find((periodo) =>
            moment(current, 'YYYY-MM-DD').isBetween(
              moment(periodo.fechaInicio, 'YYYY-MM-DD').format('YYYY-MM-DD'),
              moment(periodo.fechaFin, 'YYYY-MM-DD').format('YYYY-MM-DD'),
              undefined,
              '[]',
            ),
          );

          let presenteToSave: DeepPartial<Presente_v3> = {
            idPresente: undefined,
            alumno: {
              idAlumno: data.alumno.idAlumno,
            },
            estadoPresente: {
              idEstadoPresente:
                razonJustificacionEstadoPresente[values.razonJustificacion],
            },
            fecha: moment(current, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            seccion: data.seccion,
            justificacion: {
              idJustificacion: res.idJustificacion,
            },
            isJornadaExtendida,
            periodoNuevo: periodoActual,
            ...(isJornadaExtendida
              ? {
                  espacio: getEspacioJE(
                    moment(current).format('YYYY-MM-DD'),
                    data.seccion.idSeccion || '',
                    data.seccion.localizacion.descripcion,
                    propuestasJE,
                  ),
                }
              : {}),
          };

          values.turno.forEach((t) => {
            if (t.toString() === TURNOS.DOBLE.toString()) {
              const presentesDobles = ausencias.filter(
                ({ fecha, justificacion }) =>
                  moment(fecha, 'YYYY-MM-DD').isSame(
                    moment(current, 'YYYY-MM-DD'),
                  ) && !justificacion,
              );
              if (presentesDobles.length) {
                presentesDobles.forEach((a) => {
                  presentes.push({
                    ...presenteToSave,
                    idPresente: a.idPresente,
                    turno: a.turno,
                  });
                });
              } else {
                presentes.push({
                  ...presenteToSave,
                  turno: { idTurno: TURNOS.MANIANA },
                });
                presentes.push({
                  ...presenteToSave,
                  turno: { idTurno: TURNOS.TARDE },
                });
              }
            } else {
              const presente = ausencias.find(
                ({ fecha, justificacion }) =>
                  moment(fecha, 'YYYY-MM-DD').format('YYYY-MM-DD') ===
                    moment(current, 'YYYY-MM-DD').format('YYYY-MM-DD') &&
                  !justificacion,
              );

              if (presente) {
                presentes.push({
                  ...presenteToSave,
                  idPresente: presente?.idPresente,
                  turno: { idTurno: Number(t) },
                });
              } else {
                presentes.push({
                  ...presenteToSave,
                  turno: { idTurno: Number(t) },
                });
              }
            }
          });

          const contraturnosSpliced = values.contraturno
            .map((c) => c.split(','))
            .flat();
          const contraturnosDiarios = getContraturnosByDate(
            contraturnosDia,
            moment(current, 'YYYY-MM-DD').format('YYYY-MM-DD'),
          );

          contraturnosSpliced.forEach((t) => {
            contraturnosDiarios.forEach((c) => {
              if (c.idContraturnoDia === Number(t)) {
                const presentContraturno = ausencias
                  .filter((prec) => prec.contraturnoDia && !prec.justificacion)
                  .filter(
                    (prec) =>
                      prec.fecha ===
                      moment(current, 'YYYY-MM-DD').format('YYYY-MM-DD'),
                  );
                const contraturno = presentContraturno.find(
                  (a) => a.contraturnoDia?.idContraturnoDia.toString() === t,
                );

                if (!contraturno) {
                  const toSave: DeepPartial<Presente_v3> = {
                    ...presenteToSave,
                    contraturnoDia: { idContraturnoDia: Number(t) },
                  };

                  presentes.push(toSave);
                } else {
                  const toSave: DeepPartial<Presente_v3> = {
                    ...presenteToSave,
                    contraturnoDia: contraturno.contraturnoDia,
                    idPresente: contraturno.idPresente,
                  };
                  presentes.push(toSave);
                }
              }
            });
          });
        }

        const cleanedPresentes = presentes.filter((a) => {
          const index = formRef.current?.presentismosAll?.findIndex(
            (b) =>
              b.turno?.idTurno === a.turno?.idTurno &&
              a.contraturnoDia?.idContraturnoDia ===
                b.contraturnoDia?.idContraturnoDia &&
              b.alumno?.idAlumno === a.alumno.idAlumno &&
              a.fecha === b.fecha &&
              a.estadoPresente.idEstadoPresente !==
                b.estadoPresente.idEstadoPresente,
          );
          return index && index < 0;
        });

        await presenteV3DAO.aspect('basic').save(cleanedPresentes);
        setOpenConfirmDialog(true);
      } catch (error) {
        console.log('error', error);
      } finally {
        setLoading(false);
      }
    },
    [
      data,
      isJornadaExtendida,
      ausencias,
      presenteV3DAO,
      justificacionDAO,
      periodoNuevo,
      contraturnosDia,
      propuestasJE,
    ],
  );

  const _initialValues = useMemo(() => {
    const newInitials = {
      ...JustificacionInitialValue,
      turno: [],
      razonJustificacion: isJornadaExtendida
        ? RAZON_JUSTIFICACION.AUSENTE.toString()
        : '',
    };
    return newInitials;
  }, [isJornadaExtendida]);

  return (
    <Grid container>
      <Grid item container id="header" xs={12}>
        <JustificacionTitle studenDetail={studentDetail} />
      </Grid>

      <Grid item container id="footer" xs={12}>
        {!working ? (
          <FormContextProvider
            template={JustificacionFormTemplate}
            initialValues={{ ...JustificacionInitialValue, ..._initialValues }}
            onSubmit={onSubmit}
          >
            <JustificacionForm
              data={data}
              loading={loading}
              selectedDay={selectedDay}
              onCancel={onCancel}
              handleClose={handleClose}
              onNext={onNext}
              isJornadaExtendida={isJornadaExtendida}
              setAusencias={setAusencias}
              turnosOptions={turnos}
              openConfirmDialog={openConfirmDialog}
              setOpenConfirmDialog={setOpenConfirmDialog}
              contraturnosOptions={contraturnosOptions}
              contraturnosDia={contraturnosDia}
              refreshContraturnos={refreshContraturnos}
              ref={formRef}
            />
          </FormContextProvider>
        ) : (
          <Loading />
        )}
      </Grid>
    </Grid>
  );
};
