import { Grid, Slider as MuiSlider, Tooltip } from '@material-ui/core';
import { CSSProperties, withStyles } from '@material-ui/styles';
import React from 'react';

const CustomSlider = withStyles({
  root: {
    height: (props: any) => props.thickness,
  },
  thumb: {
    color: '#fff',
    width: 20,
    height: 20,
    marginTop: -8,
    marginLeft: -10,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.5)',
  },
  track: {
    height: (props: any) => props.thickness,
    color: 'rgb(72 65 191)',
  },
  rail: {
    height: (props: any) => props.thickness,
    color: 'gray',
  },
})(MuiSlider);

interface SliderInputProps {
  value: number;
  marks?: boolean;
  onChange: (event: React.ChangeEvent<{}>, value: number | number[]) => void;
  min?: number;
  max?: number;
  step?: number;
  thickness?: number;
  styles?: CSSProperties;
  disabled?: boolean;
}

export const SliderInput = ({
  min = 0,
  max = 1,
  thickness = 4,
  styles,
  disabled,
  ...rest
}: SliderInputProps) => {
  const marks = [
    { value: 0, label: 'No Evaluado', tooltipText: 'No Evaluado' },
    { value: 1, label: 'Grado 1', tooltipText: 'Mínimo' },
    { value: 2, label: 'Grado 2', tooltipText: 'Intermedio' },
    { value: 3, label: 'Grado 3', tooltipText: 'Máximo' },
  ];

  function ValueLabelComponent({
    children,
    value,
  }: {
    value: any;
    children: JSX.Element;
  }) {
    const [open, setOpen] = React.useState(false);

    const handleTooltipOpen = () => {
      setOpen(true);
    };

    const handleTooltipClose = () => {
      setOpen(false);
    };
    return (
      <Tooltip
        open={open}
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
        enterTouchDelay={0}
        placement="top"
        title={`${marks.find((mark) => mark.value === value)?.tooltipText}`}
      >
        {children}
      </Tooltip>
    );
  }
  return (
    <CustomSlider
      min={min}
      max={max}
      thickness={thickness}
      {...rest}
      style={styles}
      ValueLabelComponent={ValueLabelComponent} // Aplica tooltip de las evaluaciones
      disabled={disabled || false}
    />
  );
};
