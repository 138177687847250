import { CommonEndpointDAO } from './CommonEndpointDAO';

export class CalificacionesCustomDAO extends CommonEndpointDAO {
  constructor() {
    super('calificacionescustom');
  }

  add(calificaciones: any) {
    return this.post(calificaciones);
  }
}
