import React, { useMemo, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { Exceptuado } from 'src/app/models';
import { ExceptuadosModalProps, IntervalDate } from '../types';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRangeList } from '../TomaPresentismo/utils/DateRangeList';
import { Footer, ROL } from 'src/commons';
import { ButtonConfig } from '../functions/buttonconfig';
import moment from 'moment';
import customFormDialog from 'src/commons/services/customFormDialog';
import { DateRangeButton } from './DateRangeButton';
import { useSnackbar } from 'notistack';
import { es } from 'date-fns/locale';
import { usePresentismoContext } from '../context/Context';
import { useEnableToSaveByRol } from '../TomaPresentismo/hooks/useEnableToSaveByRol';

const useStyles = makeStyles((theme) => ({
  daterange: {
    marginTop: theme.spacing(2),
    '& > .rdrDefinedRangesWrapper': {
      display: 'none',
    },
  },
  rangePickerContainer: {
    textAlign: 'center',
  },
  chipsContainer: {
    padding: theme.spacing(2),
    maxHeight: '300px',
    overflowY: 'scroll',
  },
  addChip: {
    backgroundColor: theme.palette.primary.main,
    color: 'black',
  },
  buttonPeriods: {
    width: '100%',
    height: 44,
    backgroundColor: '#00a0d6 !important',
    color: 'white',
    textTransform: 'none',
  },
  deleteIcon: {
    color: 'gray',
    borderColor: 'black',
    position: 'absolute',
    left: '108%',
  },
}));

const cancelButton = new ButtonConfig('Cancelar', 'secondary', 'medium');
const saveButton = new ButtonConfig('Actualizar', 'secondary', 'medium');

export const EditExceptuadosModal = ({
  initialExceptuados,
  alumno,
}: ExceptuadosModalProps) => {
  const { rolCanUpdateGrid: canEditPresentismo } = usePresentismoContext();

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const clonedList = initialExceptuados.clone();
  const [tempIntervals, setIntervals] = useState<DateRangeList>(
    clonedList.isEmpty() ? clonedList.fillOne(alumno) : clonedList,
  );
  const [intervalsToDelete, setIntervalsToDelete] = useState<Exceptuado[]>([]);

  const [focusedRange, setFocusedRange] = useState<number[]>([0, 0]);

  const handleRangeSelect = (range: { [k: string]: IntervalDate }) => {
    if (!canEditPresentismo) return;
    const key = Object.keys(range)[0];
    const updatedExceptuado = tempIntervals.searchByKey(key);

    if (updatedExceptuado) {
      (updatedExceptuado as Exceptuado).startTime = moment(
        range[key].startDate,
      ).format('YYYY-MM-DD');
      (updatedExceptuado as Exceptuado).endTime = moment(
        range[key].endDate,
      ).format('YYYY-MM-DD');

      if (
        tempIntervals.isInvervalOverlapping(
          updatedExceptuado as Exceptuado,
          key,
        )
      ) {
        enqueueSnackbar(
          'No es posible establecer el periodo en el rango seleccionado',
          {
            variant: 'error',
            autoHideDuration: 2000,
          },
        );
        return;
      } else {
        tempIntervals.updateRange(
          key,
          moment(range[key].startDate).format(),
          moment(range[key].endDate).format(),
        );
        const clonedList = tempIntervals.clone();
        setIntervals(clonedList);
      }
    }
  };

  cancelButton.setClickHandler(() => customFormDialog.handleCancel());
  saveButton.setClickHandler(() => {
    if (!canEditPresentismo) return;
    if (tempIntervals.atLeastOneInvalidInterval()) {
      enqueueSnackbar(
        'No es posible establecer el periodo en el rango seleccionado. Hay solapamiento de períodos',
        {
          variant: 'error',
          autoHideDuration: 4000,
        },
      );
    } else {
      customFormDialog.handleConfirm({ tempIntervals, intervalsToDelete });
    }
  });

  const addNewInterval = () => {
    if (!canEditPresentismo) return;
    const clonedList = tempIntervals.clone();
    clonedList.insertEmptyRange(alumno);
    setIntervals(clonedList);
    setFocusedRange([tempIntervals.list.length, 0]);
  };

  const handleRangeClick =
    (exceptuado: Exceptuado) => (e: React.MouseEvent<HTMLElement>) => {
      if (!canEditPresentismo) return;
      const index = tempIntervals.searchByKey(exceptuado.key, 'index');
      setFocusedRange([index as number, 0]);
    };

  const deleteHandler = (index: number) => (e: React.MouseEvent) => {
    if (!canEditPresentismo) return;
    const listCloned = tempIntervals.clone();
    listCloned.remove(index);
    setIntervals(listCloned);
    if (tempIntervals.list[index].idExcepcion) {
      setIntervalsToDelete([...intervalsToDelete, tempIntervals.list[index]]);
    }
  };

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Grid container justifyContent="center">
          <Grid
            item
            xs={4}
            classes={{
              root: classes.chipsContainer,
            }}
          >
            <Grid container direction="column" spacing={1} alignItems="center">
              <Grid item xs={12}>
                <DateRangeButton
                  classes={{
                    root: classes.addChip,
                  }}
                  onClick={() => addNewInterval()}
                >
                  Agregar Periodo
                </DateRangeButton>
              </Grid>
              {[...tempIntervals].map((exceptuado, index: number) => (
                <Grid item xs={12}>
                  <DateRangeButton
                    onClick={handleRangeClick(exceptuado as Exceptuado)}
                    style={{
                      backgroundColor:
                        focusedRange[0] === index
                          ? (exceptuado as Exceptuado).color
                          : 'white',
                      color: focusedRange[0] === index ? 'white' : 'black',
                      border: `solid 2px ${(exceptuado as Exceptuado).color}`,
                    }}
                    interval={(exceptuado as Exceptuado).getFormattedInterval()}
                    onDelete={deleteHandler(index)}
                    classes={{
                      deleteIcon: classes.deleteIcon,
                    }}
                  ></DateRangeButton>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            xs={8}
            classes={{
              root: classes.rangePickerContainer,
            }}
          >
            <DateRangePicker
              locale={es}
              className={classes.daterange}
              showMonthAndYearPickers={false}
              ranges={[...tempIntervals]}
              onChange={handleRangeSelect}
              showDateDisplay={false}
              staticRanges={[]}
              inputRanges={[]}
              focusedRange={focusedRange}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Footer buttonConfig={[cancelButton, saveButton]} spacing={1} />
      </Grid>
    </Grid>
  );
};
