import { presentismo } from '../types';

export const validate = (values: presentismo.PropuestaJe) => {
  let errors = {};

  for (const [day, meta] of Object.entries(values)) {
    if (Boolean(meta.aceptado) && (!('dentro' in meta) || meta.dentro === '')) {
      errors = {
        ...errors,
        [day]: {
          ...errors[day],
          dentro: 'Debe seleccionar una opción',
        },
      };
    }

    if (meta.aceptado && meta.dentro === false && !Boolean(meta.sede)) {
      errors = {
        ...errors,
        [day]: {
          ...errors[day],
          sede: 'Debe colocar la sede',
        },
      };
    }
  }

  return errors;
};

export const submitDisabled = (values: presentismo.PropuestaJe) => {
  const invalidDays = Object.keys(values).length === 0;
  let allNotAcepted = false;
  for (const [day, meta] of Object.entries(values)) {
    if (day !== 'propuesta') {
      allNotAcepted = allNotAcepted || meta.aceptado;
    }
  }
  const someInvalid = Object.entries(values).some((item) => {
    const { dentro, aceptado, sede } = item[1] || {};
    return (
      (aceptado && dentro === false && [undefined, ''].includes(sede)) ||
      (aceptado && [undefined, ''].includes(dentro))
    );
  });

  return invalidDays || someInvalid || !allNotAcepted;
};
