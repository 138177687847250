import { LibbyFetchDAO } from './LibbyFetchDAO';

export class PlantaDAO extends LibbyFetchDAO {
  constructor() {
    super('planta', 'idPlanta');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByUserId(idUsuario: number) {
    return this.query()
      .equals('personalEducativo.persona.cuentas', idUsuario)
      .run();
  }

  getByCuentaId(
    idUsuario: number,
    idEspacioCurricular: number,
    idSeccion: number,
  ) {
    return this.query()
      .equals('personalEducativo.persona.cuentas', idUsuario)
      .equals('espacioCurricular', idEspacioCurricular)
      .equals('seccion', idSeccion)
      .run();
  }

  getByFilter(filter: any) {
    return this.fetch({ filter });
  }

  getByFullFilter(filter: any) {
    return this.fetch(filter);
  }

  getByLocalizacion(idUsuario: number, idLocalizacion: number) {
    return this.query()
      .equals('personalEducativo.persona.cuentas', idUsuario)
      .equals('espacioCurricular.localizacion', idLocalizacion)
      .equals('seccion.localizacion', idLocalizacion)
      .run();
  }
}
