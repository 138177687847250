import { cloneDeep } from 'lodash';
import { Anio } from 'src/app/models';

// De momento solo sirve para el orden del select de año, nivel inicial
export type estadoYear = {
  descripcionAnio: string;
  idAnio: number;
  nivel: {
    descripcionNivel: string;
    idNivel: number;
  };
  numeroAnio: number;
};

const sortAnioSelectContent = (year: Anio[]) => {
  const yearClone = cloneDeep(year);
  yearClone?.sort((yearA, yearB) => {
    const nombreYearA = yearA?.descripcionAnio
      ?.toLowerCase()
      .replace(/°+/g, '° ')
      .replace(/\s+/g, ' ')
      .replace(/lactantes+/g, '0lactantes');
    const nombreYearB = yearB?.descripcionAnio
      ?.toLowerCase()
      .replace(/°+/g, '° ')
      .replace(/\s+/g, ' ')
      .replace(/lactantes+/g, '0lactantes');
    if (nombreYearA < nombreYearB) {
      return -1;
    }
    if (nombreYearA > nombreYearB) {
      return 1;
    }
    return 0;
  });

  return yearClone;
};

export default sortAnioSelectContent;
