import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { EspaciosOrientaciones } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'espacios_orientaciones';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEspaciosOrientaciones',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEspaciosOrientaciones',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EspaciosOrientaciones
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EspaciosOrientaciones,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.EspaciosOrientaciones.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EspaciosOrientaciones>(DAO_NAME, optionsToUse);

// hooks
export const useEspaciosOrientacionesFetchById = fetchById;
export const useEspaciosOrientacionesLibbyCall = libbyCall;
export const useEspaciosOrientacionesDAO = libbyDAO;
export const useEspaciosOrientacionesLibbyFetch = libbyFetch;

// components/hoc
export const EspaciosOrientacionesCall = renderPropCall;
export const EspaciosOrientacionesById = renderPropFetchById;
export const EspaciosOrientacionesSelect = simpleSelect;
export const EspaciosOrientacionesAccordionSelector = accordionSelector;
export const EspaciosOrientacionesSelectedChips = selectedChips;

// context
// ByIdContext
export const useEspaciosOrientacionesByIdContext = useByIdContext;
export const EspaciosOrientacionesByIdProvider = ByIdProvider;
export const EspaciosOrientacionesByIdContext = ByIdContext;
// GeneralContext
export const useEspaciosOrientacionesContext = useGeneralContext;
export const EspaciosOrientacionesProvider = GeneralProvider;
export const EspaciosOrientacionesContext = GeneralContext;
