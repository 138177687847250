import React from 'react';
import { primary } from 'src/theme/colors';

export const calculatedIconFilling = (type: boolean = true) => {
  return (
    <div
      style={{
        borderRadius: 100,
        width: 8,
        height: 8,
        background: `${type ? primary.success : primary.warning}`,
        border: `1px solid ${type ? primary.success : primary.warning}`,
        display: 'inline-block',
        marginRight: 10,
      }}
    />
  );
};
