import { useMemo } from 'react';
import { Seccion } from 'src/app/models';
import { InfoTableColumn } from 'src/commons';
import { DataNormalized } from 'src/screens/Private/Matriculation/Grupos/GrupoDetails/components/GrupoDetailsHeader/types';
import { primary } from 'src/theme/colors';
import { useRolesContext } from 'src/context/RolesContext';
import { NIVEL } from 'src/commons/const';

export const useCalificacionTableColumns = (seccion: Seccion | undefined) => {
  const {
    selectedRole: { nivel },
  } = useRolesContext();
  const columnsSecundario = useMemo<InfoTableColumn<DataNormalized[]>[]>(
    () => [
      {
        id: 'fullName',
        label: seccion?.nombreSeccion ? seccion.nombreSeccion : '',
        width: '10%',
        orderById: 'fullName',
        style: {
          color: primary.lightBlue,
        },
        hideSortIcon: true,
        noSort: true,
      },
      {
        id: 'participacion',
        label: '¿Sostuvo la participación y la continuidad pedagógica?',
        width: '10%',
        orderById: 'participación',
        style: {
          color: primary.lightBlue,
        },
        hideSortIcon: true,
        noSort: true,
      },
      {
        id: 'valoracion',
        label: 'Valoración',
        width: '20%',
        orderById: 'valoracion',
        style: {
          color: primary.lightBlue,
        },
        hideSortIcon: true,
        noSort: true,
      },
      {
        id: 'calificacion',
        label: 'Calificación',
        orderById: 'calificacion',
        width: '5%',
        style: {
          color: primary.lightBlue,
        },
        hideSortIcon: true,
        noSort: true,
      },
    ],
    [seccion],
  );

  const columnsPrimario = useMemo<InfoTableColumn<DataNormalized[]>[]>(
    () => [
      {
        id: 'fullName',
        label: seccion?.nombreSeccion ? seccion.nombreSeccion : '',
        width: '10%',
        orderById: 'fullName',
        style: {
          color: primary.lightBlue,
        },
        hideSortIcon: true,
        noSort: true,
      },
      {
        id: 'vinculoPedagogico',
        label: '¿Mantuvo el vínculo pedagógico?',
        width: '5%',
        orderById: 'vinculoPedagogico',
        style: {
          color: primary.lightBlue,
        },
        hideSortIcon: true,
        noSort: true,
      },
      {
        id: 'promocionAcompanada',
        label: '¿Se encuentra en promoción acompañada?',
        width: '5%',
        orderById: 'promocionAcompanada',
        style: {
          color: primary.lightBlue,
        },
        hideSortIcon: true,
        noSort: true,
      },
      {
        id: 'espacios_consolidando_pregunta',
        label:
          'En relación a los aprendizajes 2020, ¿Se encuentra consolidando algunos espacios curriculares?',
        orderById: 'espacios_consolidando',
        width: '15%',
        style: {
          color: primary.lightBlue,
        },
        hideSortIcon: true,
        noSort: true,
      },
      {
        id: 'espaciosConsolidando',
        label: '¿Cuáles?',
        orderById: 'espaciosConsolidando',
        width: '20%',
        style: {
          color: primary.lightBlue,
        },
        hideSortIcon: true,
        noSort: true,
      },
      {
        id: 'apoyo_pregunta',
        label: '¿Posee apoyos / acompañamientos?',
        orderById: 'apoyo',
        width: '5%',
        style: {
          color: primary.lightBlue,
        },
        hideSortIcon: true,
        noSort: true,
      },
      {
        id: 'apoyo',
        label: '¿Cuáles?',
        orderById: 'apoyo',
        width: '20%',
        style: {
          color: primary.lightBlue,
        },
        hideSortIcon: true,
        noSort: true,
      },
      {
        id: 'observaciones',
        label: 'Observaciones',
        orderById: 'observaciones',
        width: '20%',
        style: {
          color: primary.lightBlue,
        },
        hideSortIcon: true,
        noSort: true,
      },
      {
        id: 'adjunto',
        label: 'Informe',
        orderById: 'adjunto_nombre',
        width: '5%',
        style: {
          color: primary.lightBlue,
        },
        hideSortIcon: true,
        noSort: true,
      },
    ],
    [seccion],
  );

  return NIVEL.SECUNDARIO === Number(nivel)
    ? columnsSecundario
    : columnsPrimario;
};
