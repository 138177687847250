import React from 'react';
import { Grid } from '@material-ui/core';
import { RepositorioItem } from '../../../../../../app/models';
import { RepositorioItemCard } from './components';

export interface RecursosListProps {
  items: RepositorioItem[];
}

export const RecursosList = ({ items }: RecursosListProps) => {
  return (
    <Grid container spacing={2}>
      {items.map((item) => (
        <Grid item sm={4}>
          <RepositorioItemCard item={item} />
        </Grid>
      ))}
    </Grid>
  );
};
