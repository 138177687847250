import { useMemo } from 'react';
import { useRolesContext } from 'src/context/RolesContext';

/**
 * Verifica que el rol actual
 * pueda abrir calificaciones
 * @param roles Lista de Roles
 * @returns boolean
 */
export const useRoleCanOpenGrade = (roles = ['1']) => {
  const {
    selectedRole: { rol },
  } = useRolesContext();

  return useMemo(() => roles.includes(rol), [rol, roles]);
};
