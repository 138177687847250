import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { EstadoCicloLectivo } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'estado_ciclo_lectivo';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEstadoCicloLectivo',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEstadoCicloLectivo',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EstadoCicloLectivo
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EstadoCicloLectivo,
      hoc: { ...defaultOptions.hoc, ...businessOptions.EstadoCicloLectivo.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EstadoCicloLectivo>(DAO_NAME, optionsToUse);

// hooks
export const useEstadoCicloLectivoFetchById = fetchById;
export const useEstadoCicloLectivoLibbyCall = libbyCall;
export const useEstadoCicloLectivoDAO = libbyDAO;
export const useEstadoCicloLectivoLibbyFetch = libbyFetch;

// components/hoc
export const EstadoCicloLectivoAutocomplete = entityAutocomplete;
export const EstadoCicloLectivoCall = renderPropCall;
export const EstadoCicloLectivoById = renderPropFetchById;
export const EstadoCicloLectivoSelect = simpleSelect;
export const EstadoCicloLectivoAccordionSelector = accordionSelector;
export const EstadoCicloLectivoSelectedChips = selectedChips;

// context
// ByIdContext
export const useEstadoCicloLectivoByIdContext = useByIdContext;
export const EstadoCicloLectivoByIdProvider = ByIdProvider;
export const EstadoCicloLectivoByIdContext = ByIdContext;
// GeneralContext
export const useEstadoCicloLectivoContext = useGeneralContext;
export const EstadoCicloLectivoProvider = GeneralProvider;
export const EstadoCicloLectivoContext = GeneralContext;
