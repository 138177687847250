import React, { useCallback, useMemo, useState, ChangeEvent } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useAlumnoMovimientoLibbyFetch } from 'src/app/business';
import { usePipLibbyFetch } from 'src/app/business/Calificaciones/Pip';
import { InfoTable, useDebounce } from 'src/commons';
import { FiltersPuenteInicialPrimaria } from './FiltersPuenteInicialPrimaria';
import { usePuenteInicialPrimariaColumns } from '../hooks/usePuenteInicialPrimariaColumns';
import { setSearchParamsMultipleWords } from 'src/utils/setSearchParamsMultipleWords';
import { AlumnoMovimiento } from 'src/app/models';
import { Pip } from 'src/app/models/calificaciones/Pip';
import { formValues } from '../../informesTypes';
import { useColegioEfectivizadoContext } from '../../../context/EfectivizacionProvider';

export const AlumnosTablePip = ({
  setInformePip,
  formValues,
  setFormValues,
  isAuthorizedToOpen,
}: {
  setInformePip: (val: {
    row: AlumnoMovimiento;
    idPIP: Pip;
    button: string;
  }) => void;
  formValues: formValues;
  setFormValues: (val: formValues) => void;
  isAuthorizedToOpen: boolean;
}) => {
  const [search, setSearch] = useState<string>('');
  const searchDebounced = useDebounce(search, 1000);
  const [orderBy, setOrderBy] = useState('alumno.persona.apellido');
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');

  const { cicloLectivo } = useColegioEfectivizadoContext();

  const searchFilterParams = useMemo(
    () => ({
      ...setSearchParamsMultipleWords(
        [
          'alumno.persona.nombre',
          'alumno.persona.apellido',
          'alumno.persona.documento',
        ],
        searchDebounced,
      ),
    }),
    [searchDebounced],
  );

  const alumnoMovimientoFilter = useMemo(() => {
    if (searchDebounced) {
      return {
        seccion: [{ path: 'seccion', value: formValues.seccion }],
        ...searchFilterParams,
      };
    }
    return {
      seccion: [{ path: 'seccion', value: formValues.seccion }],
    };
  }, [formValues.seccion, searchDebounced, searchFilterParams]);

  const { data: alumnos = [], working: workingAlumnos } =
    useAlumnoMovimientoLibbyFetch({
      limit: 500,
      filter: alumnoMovimientoFilter,
      orderBy: orderBy,
      direction: direction,
    });

  const pipAlumnos = useMemo(() => {
    if (alumnos.length > 0) {
      const filter = alumnos.map((alumno: AlumnoMovimiento) => {
        return {
          path: 'alumno',
          value: alumno.alumno?.idAlumno,
        };
      });
      return filter;
    }
  }, [alumnos]);

  const pipFilter = useMemo(
    () => ({
      alumno: pipAlumnos,
      cicloLectivo: [
        {
          path: 'cicloLectivo.idCicloLectivo',
          value: cicloLectivo?.idCicloLectivo,
        },
      ],
    }),
    [pipAlumnos, cicloLectivo],
  );

  const { data: pipData = [], reFetch } = usePipLibbyFetch({
    limit: 100,
    filter: pipFilter,
    enabled: alumnos.length > 0,
    checkDuplicationDeep: true,
    aspect: 'basic',
  });

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      const { name } = e.target;
      const newValue = { ...formValues, [name]: value };
      if (name === 'anio' && value === '') {
        newValue['seccion'] = '';
      }

      setFormValues(newValue);
    },
    [formValues, setFormValues],
  );

  const handleChangeAlumno = (
    row: AlumnoMovimiento,
    idPIP: Pip,
    button: string,
  ) => {
    setInformePip({ row, idPIP, button });
  };

  const filteredAlumnos = useMemo(() => {
    let filterAlumnos: AlumnoMovimiento[] = [];
    if (formValues?.estado !== '') {
      alumnos.forEach((alumno: AlumnoMovimiento) => {
        const pipFind = pipData.find(
          (element: any) =>
            element?.alumno?.idAlumno === alumno.alumno?.idAlumno,
        );
        if (formValues?.estado === 'No iniciado' && pipFind === undefined) {
          filterAlumnos.push(alumno);
        } else if (formValues?.estado === 'En proceso' && pipFind?.abierto) {
          filterAlumnos.push(alumno);
        } else if (
          formValues?.estado === 'Finalizado' &&
          pipFind?.abierto === false
        ) {
          filterAlumnos.push(alumno);
        }
      });
    } else {
      filterAlumnos = alumnos;
    }
    return filterAlumnos;
  }, [alumnos, formValues?.estado, pipData]);

  const showView = useMemo(
    () => formValues.anio && formValues.seccion,
    [formValues.anio, formValues.seccion],
  );

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  const columns = usePuenteInicialPrimariaColumns(
    isAuthorizedToOpen,
    handleChangeAlumno,
    pipData,
    reFetch,
  );

  return (
    <>
      <FiltersPuenteInicialPrimaria
        formValues={formValues}
        onChange={onChange}
        search={search}
        setSearch={setSearch}
      />
      {showView ? (
        <InfoTable
          rows={filteredAlumnos}
          columns={columns}
          working={workingAlumnos}
          direction={direction}
          onSortChange={handleRequestSort}
        />
      ) : (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: '400px' }}
        >
          <Typography
            style={{
              fontSize: '40px',
              marginTop: '50px',
              color: 'gray',
              fontWeight: 'bold',
            }}
          >
            Seleccione año y sección para calificar.
          </Typography>
        </Grid>
      )}
    </>
  );
};
