import { LibbyFetchDAO } from './LibbyFetchDAO';

export class EspacioCurricularDocenteDAO extends LibbyFetchDAO {
  constructor() {
    super('espacio_curricular_docente', 'idEspacioCurricularSeccion');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  // getByLocalizacionId(localizacionId: string) {
  //   return this.query().equals('localizacion', localizacionId).run();
  // }

  // getByLocalizacionIdAndByNivel(localizacionId: string, idNivel: number) {
  //   return this.query()
  //     .equals('localizacion', localizacionId)
  //     .and()
  //     .equals('materia.anio.nivel', idNivel)
  //     .run();
  // }
}
