import { REQUEST_TYPES } from 'src/lib/libby/hooks/useLibbyRequest';
import { makeEntityLibbyRequest } from '../../../lib/libby/hooks/makeEntityLibbyRequest';
import { makeBusinessEntity } from '../../../lib/libby';
import { DescargardriveDAO } from '../../../platform/libby/dao';

const DAO_NAME = 'descargardrive';

// hooks
export const useDescargardriveGetRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.GET,
);
export const useDescargardrivePostRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.POST,
);
export const useDescargardrivePutRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.PUT,
);
export const useDescargardriveDeleteRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.DELETE,
);

const {
  hook: { libbyDAO },
} = makeBusinessEntity<{ data: Buffer }, DescargardriveDAO>(DAO_NAME);

export const useDescargardriveDAO = libbyDAO;
