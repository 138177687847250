import { makeBusinessEntity } from '../../../lib/libby/generator';
import { CriterioEvaluacion } from '../../models';

const DAO_NAME = 'acap.criterios_evaluacion';

const {
  hook: { libbyDAO, libbyFetch, libbyCall },
} = makeBusinessEntity<CriterioEvaluacion>(DAO_NAME, {});

// hooks;
export const useCriteriosEvaluacionDAO = libbyDAO;
export const useCriteriosEvaluacionLibbyFetch = libbyFetch;
export const useCriteriosEvaluacionLibbyCall = libbyCall;
