import React, { FC, FormEvent } from 'react';
import { SimpleSelect } from 'src/commons/components/Select';

interface ISelectGroupPassProps {
  currentIdCycle?: number;
  currentIdLevel?: number;
  disabled?: boolean;
  handleChange(e: FormEvent<EventTarget>): void;
  isAdultPromotion: boolean;
  options: any[]; // Todo: Add section Types
  value: string;
}

export const SelectGroupPass: FC<ISelectGroupPassProps> = ({
  currentIdCycle = 0,
  currentIdLevel = 0,
  value,
  isAdultPromotion,
  handleChange,
  options = [],
  disabled,
}) => {
  /** Todo: This filter remains to be defined in the Adult promotion
   options.filter(({ nivel, ciclo }: { nivel: any; ciclo: any }) =>
   currentIdCycle === 2 && currentIdLevel === 2
   ? nivel?.idNivel === currentIdLevel + 1 && +ciclo?.idCiclo === 1
   : nivel?.idNivel === currentIdLevel && +ciclo?.idCiclo === currentIdCycle + 1,
   )
   */

  const optionsSection = isAdultPromotion
    ? options
    : options.filter(({ nivel, ciclo }: { nivel: any; ciclo: any }) =>
        currentIdCycle === 2
          ? (nivel?.idNivel === currentIdLevel &&
              +ciclo?.idCiclo === currentIdCycle) ||
            (nivel?.idNivel === currentIdLevel + 1 && +ciclo?.idCiclo === 1)
          : (nivel?.idNivel === currentIdLevel &&
              +ciclo?.idCiclo === currentIdCycle) ||
            (nivel?.idNivel === currentIdLevel && +ciclo?.idCiclo === 2),
      );

  return (
    <SimpleSelect
      content={optionsSection}
      disableUnderline
      disabled={disabled}
      handleChange={handleChange}
      labelKey="nombreSeccion"
      name="groupPass"
      placeholder={
        isAdultPromotion ? 'Seleccioná Ciclo' : 'Seleccioná Grupo/Grado'
      }
      value={value}
      valueKey="idSeccion"
      variant="standard"
    />
  );
};
