import moment from 'moment';
import React, { useMemo } from 'react';
import {
  Alumno,
  ContraturnoDia,
  EstadoPresente_v3,
  Turno,
} from '../../../../../../../app/models';
import { useMultiPresentismoChecks } from '../../../context';
import { ASISTENCIA_GENERAL, TURNOS } from '../../PresentismoTable/components';
import { Resume, ResumeRow, ResumeTurno } from '../../Resume';
import { ESTADO_PRESENTE } from '../../../../../../../commons';
import { AlumnoMap } from '../../../types';

export interface ResumenDiarioProps {
  alumnos: AlumnoMap[];
  currentWeek: moment.Moment;
  pastDaysOfWeek: number;
  contraturnosDia: ContraturnoDia[];
  isEspacioCurricular?: boolean;
}

export interface PresentesResume {
  turno?: Turno | null;
  contraturnoDia?: ContraturnoDia | null;
  alumno: Alumno;
  estadoPresente: EstadoPresente_v3;
  hasAlerta: boolean;
}

export const ResumenDiario = ({
  alumnos,
  currentWeek,
  pastDaysOfWeek,
  contraturnosDia,
  isEspacioCurricular = false,
}: ResumenDiarioProps) => {
  const { turno: seccionTurno } = useMultiPresentismoChecks();

  const currentDay = useMemo(
    () =>
      moment(currentWeek)
        .startOf('isoWeek')
        .add(pastDaysOfWeek, 'days')
        .format('YYYY-MM-DD'),
    [currentWeek, pastDaysOfWeek],
  );

  const rows = useMemo(() => {
    if (alumnos.length === 0 || seccionTurno === 0) return [];
    const result = alumnos.reduce<PresentesResume[]>((acum, alumno) => {
      const presente = alumno.presentismo
        .filter(
          (presente) =>
            moment(presente.fecha).format('YYYY-MM-DD') === currentDay,
        )
        .map<PresentesResume>(
          ({ turno, contraturnoDia, alumno: _alumno, estadoPresente }) => ({
            turno,
            contraturnoDia,
            alumno: _alumno,
            estadoPresente,
            hasAlerta: alumno.alertas?.some((a) => a.active),
          }),
        );
      acum.push(...presente);
      return acum;
    }, []);

    const resumeRows = TURNOS[seccionTurno].reduce<ResumeRow[]>(
      (acum, turno) => {
        const idTurno = turno.idTurno;
        const presenteTurno = result.filter(
          (presente) =>
            presente.turno && presente.turno.idTurno === turno.idTurno,
        );
        const presentes = presenteTurno.filter(
          (p) => p.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.PRESENTE,
        ).length;
        const ausentes = presenteTurno.filter(
          (p) => p.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE,
        ).length;
        const retiroAnticipado = presenteTurno.filter(
          (p) =>
            p.estadoPresente.idEstadoPresente ===
            ESTADO_PRESENTE.RETIRO_ANTICIPADO,
        ).length;
        const aucenciaPrecensiaEnClase = presenteTurno.filter(
          (p) =>
            p.estadoPresente.idEstadoPresente ===
            ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE,
        ).length;
        const ingresoTardio = presenteTurno.filter(
          (p) =>
            p.estadoPresente.idEstadoPresente ===
            ESTADO_PRESENTE.INGRESO_TARDIO,
        ).length;
        const noCorresponde = presenteTurno.filter(
          (p) =>
            p.estadoPresente.idEstadoPresente ===
            ESTADO_PRESENTE.NO_CORRESPONDE,
        ).length;
        const countAlertas: number = presenteTurno.filter(
          (presente) => presente.hasAlerta,
        ).length;

        const newTurno: ResumeRow = {
          presentes,
          ausentes,
          alertas: countAlertas,
          pendientes:
            alumnos.length -
            presentes -
            ausentes -
            retiroAnticipado -
            aucenciaPrecensiaEnClase -
            ingresoTardio -
            noCorresponde,
          label: turno.title as ResumeTurno,
          id: idTurno,
        };
        acum.push(newTurno);
        return acum;
      },
      [],
    );

    const contraturnosDias = isEspacioCurricular
      ? []
      : contraturnosDia.filter((dia) => {
          return (
            dia.dia.idDia === moment(currentDay).day() &&
            dia.active &&
            dia.contraturno.active &&
            dia.contraturno.fechaInicio <= currentDay &&
            dia.contraturno.fechaFin >= currentDay
          );
        });

    const contraturnos = result.filter((presente) => presente.contraturnoDia);
    const contraIds = new Set(
      contraturnosDias.map((contra) => contra.idContraturnoDia),
    );
    const _contraturnos = [...contraIds]
      .sort((a, b) => a - b)
      .reduce<ResumeRow[]>((acum, idContraturno, index) => {
        const presenteContraturno = contraturnos.filter(
          (contra) => contra.contraturnoDia!.idContraturnoDia === idContraturno,
        );
        const presentes = presenteContraturno.filter(
          (pct) =>
            pct.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.PRESENTE,
        ).length;
        const ausentes = presenteContraturno.filter(
          (pct) =>
            pct.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE,
        ).length;

        const countAlertas: number = presenteContraturno.filter(
          (presente) => presente.hasAlerta,
        ).length;

        const newTurno: ResumeRow = {
          presentes,
          ausentes,
          alertas: countAlertas,
          pendientes: alumnos.length - presentes - ausentes,
          label: `CT${index + 1}` as ResumeTurno,
          id: idContraturno,
        };
        acum.push(newTurno);
        return acum;
      }, []);
    return [...resumeRows, ..._contraturnos];
  }, [alumnos, seccionTurno, currentDay, contraturnosDia, isEspacioCurricular]);

  return <Resume rows={rows} />;
};
