import React, { useCallback, useMemo } from 'react';
import { Checkbox, Tooltip } from '@material-ui/core';
import { useLibbyCall, SimpleSelect, ROL } from 'src/commons';
import { SelectPassStatus } from '../Initial/PromotionDetail/components/SelectPassStatus';
import { SelectPassSection } from '../Initial/PromotionDetail/components/SelectPassSection';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IconButton } from '@material-ui/core';
import customFormDialog from 'src/commons/services/customFormDialog';
import { FormPromotionDialog } from '../../Promotion/Initial/PromotionDetail/components/FormPromotionDialog';
import { useSnackbar } from 'notistack';
import { useEstadoProyeccionMotivoLibbyFetch } from 'src/app/business';
import { level } from 'src/commons';
import { inputDisableLogic } from '../utils/inputDisableLogic';
import { MAPPED_PASE_ANIO_OPTIONS_PRIMARIO } from '../utils/mappedPaseAnioOptions';
import { getFilterPassSection } from '../utils/getFilterPassSection';
import { TooltipErrors } from '../components/TooltipsError';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import { ProyeccionEstado, Seccion } from 'src/app/models';
import { removeAccents } from 'src/utils';
import moment from 'moment';
import { useColegioMetaContext } from 'src/platform/context/ColegioMetaProvider';

const {
  Egresa: EGRESA,
  'Continúa con apoyo para la promoción': CONTINUA_CON_APOYO_PARA_PROMOCION,
} = MAPPED_PASE_ANIO_OPTIONS_PRIMARIO;

export const usePromocionAlumnosColumns = (
  setCheckedAll: React.Dispatch<React.SetStateAction<boolean>>,
  setCheckStudent: React.Dispatch<React.SetStateAction<number[]>>,
  checkedAll: boolean,
  studentsData: any,
  libby: any,
  setFormValues: React.Dispatch<React.SetStateAction<any>>,
  setStudentsData: React.Dispatch<React.SetStateAction<any>>,
  idCurrentSection: string,
  checkStudent: number[],
  restoreFlag: boolean,
  setRestoreFlag: React.Dispatch<React.SetStateAction<boolean>>,
  proyeccionEstado: ProyeccionEstado[],
  descripcionAnioSection: string,
  nivel: string | number,
  localizacionData: any,
  idAnio: string,
  sections: Seccion[] = [],
  rol: string,
) => {
  const { data: yearPassStatus = [] } = useLibbyCall(libby, {
    daoName: 'estado_pase_anio',
    methodName: 'getAll',
  });
  const { isDEI: esDEI } = useColegioMetaContext();

  const ID_ESTADO_DE_LA_PROYECCION = proyeccionEstado[0]
    ? proyeccionEstado[0]?.proyeccionEstadoTipo.idProyeccionEstadoTipo
    : null;

  const isEfectivizado: boolean =
    proyeccionEstado[0]?.proyeccionEstadoTipo.idProyeccionEstadoTipo === 3 &&
    proyeccionEstado[0]?.cicloLectivo.anio === moment().year();

  const { data: estadoMotivo = [] } = useEstadoProyeccionMotivoLibbyFetch({
    orderBy: 'descripcion',
  });
  const { enqueueSnackbar } = useSnackbar();

  const handleChangePassStatus = useCallback(
    (
      e: React.FormEvent<EventTarget>,
      restStudentData,
      idEstadoDeLaProyeccion,
    ) => {
      const target = e.target as HTMLInputElement;

      const currentSection = sections.find(
        (element) => element.idSeccion === idCurrentSection,
      );

      let nextSection: Seccion[] = [];

      if (level.INICIAL === Number(nivel)) {
        if (
          Number(target.value) === 1 &&
          ![16, 17, 18, 19].includes(Number(currentSection?.anio?.idAnio))
        ) {
          nextSection = sections
            .filter(
              (element) =>
                element.cicloLectivo.anio ===
                  Number(currentSection?.cicloLectivo?.anio) + 1 &&
                (Number(element.anio.idAnio) ===
                  Number(currentSection?.anio?.idAnio) + 1 ||
                  ((Number(element.anio.idAnio) === 19 ||
                    Number(element.anio.idAnio) === 18) &&
                    Number(currentSection?.anio?.idAnio === 14)) ||
                  ((Number(element.anio.idAnio) === 18 ||
                    Number(element.anio.idAnio) === 17) &&
                    Number(currentSection?.anio?.idAnio === 13)) ||
                  ((Number(element.anio.idAnio) === 17 ||
                    Number(element.anio.idAnio) === 16) &&
                    Number(currentSection?.anio?.idAnio === 12)) ||
                  (Number(element.anio.idAnio) === 16 &&
                    Number(currentSection?.anio?.idAnio === 11))),
            )
            .sort((a: any, b: any) => a.anio.idAnio - b.anio.idAnio);
        } else if (
          Number(target.value) === 2 &&
          ![16, 17, 18, 19].includes(Number(currentSection?.anio?.idAnio))
        ) {
          nextSection = sections
            .filter(
              (element) =>
                element.cicloLectivo.anio ===
                  Number(currentSection?.cicloLectivo?.anio) + 1 &&
                (Number(element.anio.idAnio) ===
                  Number(currentSection?.anio?.idAnio) ||
                  (Number(element.anio.idAnio) === 19 &&
                    Number(currentSection?.anio?.idAnio === 15)) ||
                  ((Number(element.anio.idAnio) === 19 ||
                    Number(element.anio.idAnio) === 18) &&
                    Number(currentSection?.anio?.idAnio === 14)) ||
                  ((Number(element.anio.idAnio) === 18 ||
                    Number(element.anio.idAnio) === 17) &&
                    Number(currentSection?.anio?.idAnio === 13)) ||
                  ((Number(element.anio.idAnio) === 17 ||
                    Number(element.anio.idAnio) === 16) &&
                    Number(currentSection?.anio?.idAnio === 12)) ||
                  (Number(element.anio.idAnio) === 16 &&
                    Number(currentSection?.anio?.idAnio === 11))),
            )
            .sort((a: any, b: any) => a.anio.idAnio - b.anio.idAnio);
        } else if (
          Number(target.value) === 1 &&
          [16, 17, 18, 19].includes(Number(currentSection?.anio?.idAnio))
        ) {
          nextSection = sections
            .filter(
              (element) =>
                (element.cicloLectivo.anio ===
                  Number(currentSection?.cicloLectivo?.anio) + 1 &&
                  Number(element.anio.idAnio) ===
                    Number(currentSection?.anio?.idAnio) + 1 &&
                  element.jornada === currentSection?.jornada &&
                  element.turno.descripcionTurno ===
                    currentSection?.turno?.descripcionTurno) ||
                (element.cicloLectivo.anio ===
                  Number(currentSection?.cicloLectivo?.anio) + 1 &&
                  element.anio.idAnio === currentSection?.anio?.idAnio),
            )
            .sort((a: any, b: any) => a.anio.idAnio - b.anio.idAnio);
        } else if (Number(target.value) === 2) {
          nextSection = sections
            .filter(
              (element) =>
                element.cicloLectivo.anio ===
                  Number(currentSection?.cicloLectivo?.anio) + 1 &&
                element.anio.idAnio === currentSection?.anio?.idAnio &&
                element.jornada === currentSection?.jornada &&
                element.turno.descripcionTurno ===
                  currentSection?.turno?.descripcionTurno,
            )
            .sort((a: any, b: any) => a.anio.idAnio - b.anio.idAnio);
        }

        setStudentsData((prev: any) =>
          prev.map((student: any) => {
            if (student.idStudent === restStudentData.idStudent) {
              return {
                ...student,
                statusPass: target.value,
                estadoProyeccionMotivo: '',
                sectionPass: [2, 3].includes(idEstadoDeLaProyeccion)
                  ? null
                  : nextSection.length === 0
                  ? 'Articula'
                  : nextSection[0].idSeccion,
              };
            }
            return student;
          }),
        );

        setFormValues((prev: { alumno: any }[]) => {
          const existingStudent: boolean =
            prev.findIndex(
              ({ alumno }) => alumno === restStudentData.idStudent,
            ) === -1;

          return existingStudent
            ? [
                ...prev,
                {
                  idProyeccion: restStudentData.idProyeccion,
                  idSeccion: restStudentData.sectionPass,
                  alumno: restStudentData.idStudent,
                  estadoPase: target.value,
                  originSeccion: idCurrentSection,
                  idCicloLectivo: restStudentData.idCicloLectivo,
                  currentSection: restStudentData.currentSection,
                  estadoProyeccionMotivo: '',
                },
              ].map((item: any) => {
                return !item.estadoPase ||
                  (item.estadoPase !== '2' &&
                    item.alumno === restStudentData.idStudent)
                  ? {
                      ...item,
                      estadoProyeccionMotivo: '',
                      idSeccion:
                        nextSection.length === 0
                          ? 'Articula'
                          : nextSection[0].idSeccion,
                    }
                  : item;
              })
            : [
                ...prev.map((student) =>
                  student.alumno === restStudentData.idStudent
                    ? {
                        ...student,
                        estadoPase: target.value,
                        estadoProyeccionMotivo: '',
                      }
                    : student,
                ),
              ].map((item: any) => {
                return !item.estadoPase ||
                  (item.estadoPase !== '2' &&
                    item.alumno === restStudentData.idStudent)
                  ? {
                      ...item,
                      estadoProyeccionMotivo: '',
                      idSeccion:
                        item.estadoPase === 3
                          ? '-'
                          : nextSection.length === 0
                          ? 'Articula'
                          : nextSection[0].idSeccion,
                    }
                  : item;
              });
        });
      }
      if (level.PRIMARIO === Number(nivel)) {
        const target = e.target as HTMLInputElement;

        const statusPass = Number(target.value);
        const options = [...sections];
        const currentCicloLectivo = restStudentData?.cicloLectivo;
        const numeroAnio = restStudentData?.currentYear?.numeroAnio;
        const descripcionAnioSection = restStudentData.currentSection;
        const idAnio = restStudentData?.idAnio;

        const { filteredOptions } = getFilterPassSection(
          options,
          statusPass,
          currentCicloLectivo,
          numeroAnio,
          nivel,
          descripcionAnioSection,
          idAnio,
        );

        const newStudentData = {
          ...restStudentData,
          estadoProyeccionMotivo: '',
          statusPass,
          sectionPass:
            statusPass === CONTINUA_CON_APOYO_PARA_PROMOCION.id
              ? '-'
              : statusPass === EGRESA.id
              ? null
              : filteredOptions[0]?.idSeccion ?? 'No Concurrirá',
        };

        setStudentsData((prev: any) =>
          prev.map((student: any) => {
            if (student.idStudent === restStudentData.idStudent) {
              return newStudentData;
            }
            return student;
          }),
        );

        setFormValues((prev: any) =>
          prev.map((student: any) => {
            if (student.alumno === restStudentData.idStudent) {
              student = {
                ...student,
                estadoPase: newStudentData.statusPass,
                idSeccion: newStudentData.sectionPass,
              };
            }
            return student;
          }),
        );
      }
    },
    [idCurrentSection, nivel, sections, setFormValues, setStudentsData],
  );

  const handleChangePassSection = useCallback(
    (e: React.FormEvent<EventTarget>, restStudentData) => {
      if (level.INICIAL === Number(nivel)) {
        const target = e.target as HTMLInputElement;
        setStudentsData((prev: any) =>
          prev.map((student: any) => {
            return student.idStudent === restStudentData.idStudent
              ? {
                  ...student,
                  sectionPass: target.value,
                  estadoProyeccionMotivo: null,
                }
              : student;
          }),
        );

        setFormValues((prev: any) => {
          const existingStudent: boolean =
            prev.findIndex(
              ({ alumno }: any) => alumno === restStudentData.idStudent,
            ) === -1;
          return existingStudent
            ? [
                ...prev,
                {
                  idProyeccion: restStudentData.idProyeccion,
                  idSeccion: target.value,
                  alumno: restStudentData.idStudent,
                  estadoPase: restStudentData.statusPass,
                  originSeccion: idCurrentSection,
                  idCicloLectivo: restStudentData.idCicloLectivo,
                  currentSection: restStudentData.currentSection,
                  estadoProyeccionMotivo:
                    target.value !== 'No Concurrirá'
                      ? null
                      : restStudentData.estadoProyeccionMotivo,
                },
              ]
            : [
                ...prev.map((student: any) =>
                  student.alumno === restStudentData.idStudent
                    ? target.value !== 'No Concurrirá'
                      ? {
                          ...student,
                          idSeccion: target.value,
                          estadoProyeccionMotivo: null,
                        }
                      : { ...student, idSeccion: target.value }
                    : student,
                ),
              ];
        });
      } else if (level.PRIMARIO === Number(nivel)) {
        const target = e.target as HTMLInputElement;
        const newStudentData = {
          ...restStudentData,
          sectionPass: target.value,
          estadoProyeccionMotivo: null,
        };

        setStudentsData((prev: any) =>
          prev.map((student: any) => {
            if (student.idStudent === restStudentData.idStudent) {
              return newStudentData;
            }
            return student;
          }),
        );

        setFormValues((prev: any) =>
          prev.map((student: any) => {
            if (student.alumno === restStudentData.idStudent) {
              student = {
                ...student,
                estadoProyeccionMotivo: null,
                idSeccion: newStudentData.sectionPass,
              };
            }
            return student;
          }),
        );
      }
    },

    [idCurrentSection, nivel, setFormValues, setStudentsData],
  );

  const handleChangeMotivo = useCallback(
    (e: React.FormEvent<EventTarget>, restStudentData) => {
      const target = e.target as HTMLInputElement;
      setStudentsData((prev: any) =>
        prev.map((student: any) => {
          if (student.idStudent === restStudentData.idStudent) {
            student = { ...student, estadoProyeccionMotivo: target.value };
          }
          return student;
        }),
      );

      setFormValues((prev: any) => {
        const existingStudent: boolean =
          prev.findIndex(
            ({ alumno }: any) => alumno === restStudentData.idStudent,
          ) === -1;
        return existingStudent
          ? [
              ...prev,
              {
                idProyeccion: restStudentData.idProyeccion,
                idSeccion: restStudentData.idStudent,
                alumno: restStudentData.idStudent,
                estadoPase: restStudentData.statusPass,
                originSeccion: idCurrentSection,
                idCicloLectivo: restStudentData.idCicloLectivo,
                currentSection: restStudentData.currentSection,
                estadoProyeccionMotivo: target.value,
              },
            ]
          : [
              ...prev.map((student: any) =>
                student.alumno === restStudentData.idStudent
                  ? { ...student, estadoProyeccionMotivo: target.value }
                  : student,
              ),
            ];
      });
    },
    [idCurrentSection, setFormValues, setStudentsData],
  );

  const handleClick = useCallback(
    async (row: any) => {
      if (checkStudent.length !== 0) {
        try {
          const resolve = await customFormDialog.show({
            title: 'Pase de Año',
            sizeWidth: 'xs',
            renderComponent: (
              <FormPromotionDialog
                {...{
                  yearPassStatus,
                  sections,
                  currentYear: studentsData[0].idAnio,
                  currentCicloLectivo: studentsData[0].cicloLectivo,
                  idCurrentSection,
                  row: studentsData[0],
                  descripcionAnioSection,
                  localizacionData,
                  nivel,
                  proyeccionEstado,
                }}
              />
            ),
          });
          if (resolve) {
            const { passSection, passStatus } = resolve as any;
            setStudentsData((prev: any) =>
              prev.map((item: any) =>
                checkStudent.includes(item.id) &&
                item?.sectionPass !== 'Articulado'
                  ? {
                      ...item,
                      statusPass: passStatus,
                      sectionPass: passStatus === 3 ? null : passSection,
                      estadoProyeccionMotivo:
                        passSection !== 'No Concurrirá'
                          ? null
                          : item.estadoProyeccionMotivo,
                    }
                  : item,
              ),
            );

            setFormValues(
              studentsData.map((student: any) =>
                checkStudent.includes(student.id) &&
                student?.sectionPass !== 'Articulado'
                  ? {
                      idProyeccion: student.idProyeccion,
                      idSeccion: passStatus === 3 ? null : passSection,
                      alumno: student.idStudent,
                      estadoPase: passStatus,
                      originSeccion: idCurrentSection,
                      idCicloLectivo: student.idCicloLectivo,
                      currentSection: student.currentSection,
                      estadoProyeccionMotivo:
                        passSection !== 'No Concurrirá'
                          ? null
                          : student.estadoProyeccionMotivo,
                    }
                  : {
                      idProyeccion: student.idProyeccion,
                      idSeccion: student.sectionPass,
                      alumno: student.idStudent,
                      estadoPase: student.statusPass,
                      originSeccion: idCurrentSection,
                      idCicloLectivo: student.idCicloLectivo,
                      currentSection: student.currentSection,
                      estadoProyeccionMotivo: student.estadoProyeccionMotivo,
                    },
              ),
            );
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        enqueueSnackbar('No hay alumnos seleccionados', {
          variant: 'error',
        });
      }
    },
    [
      checkStudent,
      descripcionAnioSection,
      enqueueSnackbar,
      idCurrentSection,
      localizacionData,
      nivel,
      sections,
      setFormValues,
      setStudentsData,
      studentsData,
      yearPassStatus,
      proyeccionEstado,
    ],
  );

  const columns = useMemo(() => {
    return [level.INICIAL, level.PRIMARIO].includes(Number(nivel))
      ? [
          {
            id: 'icon',
            width: '1%',
            label: (
              <Checkbox
                checked={checkedAll}
                onChange={(e) => {
                  setCheckedAll(e.target.checked);
                  return checkedAll
                    ? setCheckStudent([])
                    : setCheckStudent(
                        studentsData.map((student: any) => student.id),
                      );
                }}
                inputProps={{
                  'aria-labelledby': 'transfer-list-item-all-label',
                }}
                color="primary"
              />
            ),
            hideSortIcon: true,
            noSort: true,
            align: 'left',
            render: (value: any, row: any) => {
              const isStudentChecked =
                checkedAll || checkStudent.includes(row.id);

              return (
                <Checkbox
                  name={row.id}
                  checked={checkedAll || isStudentChecked}
                  onChange={() => {
                    if (!checkStudent.length) {
                      setRestoreFlag(!restoreFlag);
                    }

                    if (checkedAll) {
                      setCheckedAll(false);
                      const ids = studentsData
                        .filter((student) => student.id !== row.id)
                        .map((student) => student.id);

                      setCheckStudent(ids);
                    } else if (isStudentChecked) {
                      setCheckStudent((prevCheckStudent) =>
                        prevCheckStudent.filter(
                          (idStudent) => idStudent !== row.id,
                        ),
                      );
                    } else {
                      setCheckStudent((prevCheckStudent) => [
                        ...prevCheckStudent,
                        row.id,
                      ]);
                    }
                  }}
                  inputProps={{
                    'aria-labelledby': 'transfer-list-item-all-label',
                  }}
                  color="primary"
                />
              );
            },
          },
          {
            id: 'surnameName',
            label: 'Apellido y nombre',
            width: '10%',
            orderById: 'alumno.persona.apellido',
            render: (value: any, row: any) => {
              return <p>{row?.surnameName}</p>;
            },
          },
          {
            id: 'document',
            label: 'Documento',
            width: '5%',
            orderById: 'alumno.persona.documento',
            render: (value: any, row: any) => {
              return <p>{row?.document}</p>;
            },
          },
          {
            id: 'condition',
            label: 'Condición',
            width: '5%',
            hideSortIcon: true,
            orderById: 'alumno.condicion.descripcionCondicion',
            render: (value: any, row: any) => {
              return <p>{row?.condition}</p>;
            },
          },
          {
            id: 'currentSection',
            label: 'Sección actual',
            hideSortIcon: true,
            width: '7%',
            orderById: 'seccion.division',
            render: (value: any, row: any) => {
              return <p>{row?.currentSection}</p>;
            },
          },
          {
            id: 'passStatus',
            label: 'Pase de año',
            width: '5%',
            hideSortIcon: true,
            noSort: true,
            render: (value: any, row: any) => {
              if (row.sectionPass === 'Articulado') {
                return (
                  <Tooltip title="La proyección ya fue gestionada.">
                    <div>
                      <SelectPassStatus
                        value={row.statusPass}
                        options={yearPassStatus}
                        row={row}
                        handleChange={(e: React.FormEvent<EventTarget>) =>
                          handleChangePassStatus(
                            e,
                            row,
                            ID_ESTADO_DE_LA_PROYECCION,
                          )
                        }
                        disabled={
                          rol !== ROL.DIRECCION_AREA.toString()
                            ? inputDisableLogic({
                                row,
                                idEstadoDeLaProyeccion:
                                  ID_ESTADO_DE_LA_PROYECCION,
                                nivel: Number(nivel),
                                inputId: 'passStatus',
                              })
                            : false
                        }
                        nivel={nivel}
                        idEstadoDeLaProyeccion={ID_ESTADO_DE_LA_PROYECCION}
                      />
                    </div>
                  </Tooltip>
                );
              } else {
                return (
                  <SelectPassStatus
                    value={row.statusPass}
                    options={yearPassStatus}
                    row={row}
                    handleChange={(e: React.FormEvent<EventTarget>) =>
                      handleChangePassStatus(e, row, ID_ESTADO_DE_LA_PROYECCION)
                    }
                    disabled={
                      rol !== ROL.DIRECCION_AREA.toString()
                        ? inputDisableLogic({
                            row,
                            idEstadoDeLaProyeccion: ID_ESTADO_DE_LA_PROYECCION,
                            nivel: Number(nivel),
                            inputId: 'passStatus',
                          })
                        : false
                    }
                    nivel={nivel}
                    idEstadoDeLaProyeccion={ID_ESTADO_DE_LA_PROYECCION}
                  />
                );
              }
            },
          },
          {
            id: 'passSection',
            label: 'Sección a la que pasa',
            width: '5%',
            hideSortIcon: true,
            noSort: true,
            render: (value: any, row: any) => {
              if (row.sectionPass === 'Articulado') {
                return (
                  <Tooltip title="La proyección ya fue gestionada.">
                    <div>
                      <SelectPassSection
                        value={row.sectionPass}
                        statusPass={row.statusPass}
                        options={sections}
                        numeroAnio={row.currentYear.numeroAnio}
                        currentCicloLectivo={row.cicloLectivo}
                        handleChange={(e: React.FormEvent<EventTarget>) =>
                          handleChangePassSection(e, row)
                        }
                        disabled={
                          rol !== ROL.DIRECCION_AREA.toString()
                            ? inputDisableLogic({
                                row,
                                idEstadoDeLaProyeccion:
                                  ID_ESTADO_DE_LA_PROYECCION,
                                nivel: Number(nivel),
                                inputId: 'passSection',
                              })
                            : false
                        }
                        handleChangeFunction={handleChangePassSection}
                        row={row}
                        descripcionAnioSection={descripcionAnioSection}
                        nivel={nivel}
                        proyeccionEstado={proyeccionEstado}
                        idAnio={idAnio}
                      />
                    </div>
                  </Tooltip>
                );
              } else {
                return (
                  <SelectPassSection
                    value={
                      [3, 7].includes(row.statusPass) ? '-' : row.sectionPass
                    }
                    statusPass={row.statusPass}
                    options={sections}
                    numeroAnio={row.currentYear.numeroAnio}
                    currentCicloLectivo={row.cicloLectivo}
                    handleChange={(e: React.FormEvent<EventTarget>) =>
                      handleChangePassSection(e, row)
                    }
                    disabled={
                      rol !== ROL.DIRECCION_AREA.toString()
                        ? inputDisableLogic({
                            row,
                            idEstadoDeLaProyeccion: ID_ESTADO_DE_LA_PROYECCION,
                            nivel: Number(nivel),
                            inputId: 'passSection',
                          })
                        : false
                    }
                    handleChangeFunction={handleChangePassSection}
                    row={row}
                    descripcionAnioSection={descripcionAnioSection}
                    nivel={nivel}
                    proyeccionEstado={proyeccionEstado}
                    idAnio={idAnio}
                  />
                );
              }
            },
          },
          ...([level.INICIAL, level.PRIMARIO].includes(Number(nivel))
            ? [
                {
                  id: 'motivo',
                  label: 'Motivo',
                  width: '5%',
                  hideSortIcon: true,
                  noSort: true,
                  render: (_: unknown, row: any) => {
                    return (
                      <SimpleSelect
                        name="motivo"
                        value={row.estadoProyeccionMotivo}
                        handleChange={(e: React.FormEvent<EventTarget>) =>
                          handleChangeMotivo(e, row)
                        }
                        placeholder="Seleccione opción"
                        content={estadoMotivo}
                        disabled={
                          inputDisableLogic({
                            row,
                            idEstadoDeLaProyeccion: ID_ESTADO_DE_LA_PROYECCION,
                            nivel: Number(nivel),
                            inputId: 'motivo',
                          }) ||
                          !/no concurrira/i.test(removeAccents(row.sectionPass))
                        }
                        labelKey="descripcion"
                        valueKey="idEstadoProyeccionMotivo"
                        customStyleContainer={{ width: '100%' }}
                      />
                    );
                  },
                },
              ]
            : []),
          ...(isEfectivizado
            ? [
                {
                  id: 'alertas',
                  label: 'Estatus',
                  width: '1%',
                  hideSortIcon: true,
                  noSort: true,
                  align: 'center',
                  render: (_: unknown, row: any) => {
                    const messages = [];

                    if (!Boolean(row.idProyeccion)) {
                      messages.push('Debe proyectar al estudiante');
                    }

                    return (
                      <Tooltip
                        title={
                          <TooltipErrors
                            errorMessages={messages}
                            title="Error"
                          />
                        }
                      >
                        <IconButton aria-label="delete">
                          {messages.length === 0 ? (
                            <CheckIcon color="primary" />
                          ) : (
                            <ErrorIcon color="error" />
                          )}
                        </IconButton>
                      </Tooltip>
                    );
                  },
                },
              ]
            : []),
          {
            id: 'secondIcon',
            label: (
              <Tooltip
                title={`${
                  ID_ESTADO_DE_LA_PROYECCION === 1
                    ? ''
                    : 'La proyección ya fue finalizada.'
                }`}
              >
                <IconButton
                  onClick={
                    ID_ESTADO_DE_LA_PROYECCION === 1 ? handleClick : () => {}
                  }
                >
                  <MoreVertIcon />
                </IconButton>
              </Tooltip>
            ),
            width: '1%',
            hideSortIcon: true,
            noSort: true,
            align: 'center',
            render: (value: any, row: any) => <></>,
          },
        ]
      : [];
  }, [
    rol,
    nivel,
    checkedAll,
    isEfectivizado,
    ID_ESTADO_DE_LA_PROYECCION,
    handleClick,
    setCheckedAll,
    setCheckStudent,
    studentsData,
    checkStudent,
    setRestoreFlag,
    restoreFlag,
    yearPassStatus,
    handleChangePassStatus,
    sections,
    handleChangePassSection,
    descripcionAnioSection,
    proyeccionEstado,
    idAnio,
    estadoMotivo,
    handleChangeMotivo,
  ]);

  return columns;
};
