import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { Loading, MainInput, SimpleSelect } from 'src/commons';
import { useSeccionLibbyFetch } from 'src/app/business';
import { makeStyles } from '@material-ui/core/styles';
import { Anio } from 'src/app/models';

const useStyles = makeStyles({
  container: {
    marginTop: '2em',
  },
});

export const FiltersPuenteInicialPrimaria = ({
  formValues,
  onChange,
  search,
  setSearch,
}: any) => {
  const classes = useStyles();

  const estadoOptions = [
    { label: 'No iniciado' },
    { label: 'En proceso' },
    { label: 'Finalizado' },
  ];
  estadoOptions.sort((a, b) => (a.label.localeCompare(b.label) ? 1 : -1));

  const filterSeccion = useMemo(
    () => ({
      anios: [
        {
          path: 'anio',
          value: [15, 19],
          method: 'in',
        },
      ],
    }),
    [],
  );

  const { data: secciones = [], working: workingSeccion } =
    useSeccionLibbyFetch({
      limit: 500,
      filter: filterSeccion,
      orderBy: 'nombreSeccion',
    });

  const aniosValidos = useMemo(() => {
    const _anios: Anio[] = [];
    secciones.forEach((seccion) => {
      if (!_anios.some((a) => a.idAnio === seccion.anio.idAnio)) {
        _anios.push(seccion.anio);
      }
    });
    return _anios.sort((a, b) => {
      const nameA = a.descripcionAnio.trim();
      const nameB = b.descripcionAnio.trim();
      return nameA.localeCompare(nameB);
    });
  }, [secciones]);

  const seccionesFiltered = useMemo(() => {
    return secciones.filter(
      (seccion) => seccion.anio.idAnio === formValues.anio,
    );
  }, [formValues.anio, secciones]);

  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid item xs={2}>
        {workingSeccion ? (
          <Loading />
        ) : (
          <SimpleSelect
            name="anio"
            title="Año"
            placeholder="Seleccioná el año"
            labelKey="descripcionAnio"
            valueKey="idAnio"
            orderBy="numeroAnio,descripcionAnio"
            handleChange={(e: any) => onChange(e)}
            content={aniosValidos}
            value={formValues.anio}
          />
        )}
      </Grid>
      {Boolean(formValues.anio) ? (
        <Grid item xs={2}>
          {workingSeccion ? (
            <Loading />
          ) : (
            <SimpleSelect
              name="seccion"
              title="Sección"
              placeholder="Seleccioná la sección"
              labelKey="nombreSeccion"
              valueKey="idSeccion"
              handleChange={(e: any) => onChange(e)}
              content={seccionesFiltered}
              value={formValues.seccion}
              disabled={!formValues.anio}
            />
          )}
        </Grid>
      ) : null}
      {Boolean(formValues.seccion) && !workingSeccion && (
        <Grid item xs={2}>
          <SimpleSelect
            name="estado"
            title="Estado PIP"
            placeholder="Seleccioná el estado"
            labelKey="label"
            valueKey="label"
            handleChange={(e: any) => onChange(e)}
            content={estadoOptions}
            value={formValues.estado}
          />
        </Grid>
      )}
      {formValues.seccion && (
        <Grid
          container
          xs={4}
          justifyContent="flex-end"
          spacing={3}
          alignItems="flex-end"
        >
          <Grid xs={6}>
            <MainInput
              type="search"
              handleChange={(e: any) => {
                setSearch(e.target.value);
              }}
              placeholder="Buscar"
              name="search"
              value={search}
              fullWidth
              customStyle={{ marginBottom: '17px', marginLeft: '100%' }}
            />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};
