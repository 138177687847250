import React from 'react';
import { useParams } from 'react-router';
import { useBackButton, useRouteScreenTitle } from 'src/commons/hooks';
import { useRolesContext } from 'src/context/RolesContext';
import { CalificacionesSecundario } from '../../CalificacionesSecundario/CalificacionesSecundario';
import { TabBarSecundario } from '../../TabBarSecundario/TabBarSecundario';
import { TabBarCuartoBimestre } from '../../TabBarCuartoBimestre';
import { useGetValidYears } from '../../hooks/useGetValidYears';
import { CalificacionesRecuperacion } from '../../CalificacionesRecuperacion/components/CalificacionesRecuperacion';
import { useColegioEfectivizadoContext } from '../../context/EfectivizacionProvider';

export type PeriodosCalificacionesParam = {
  periodo: '1' | '2' | '3' | '4';
};

export const PERIODOS: { [key: string]: string } = {
  '1': 'PRIMER_BIMESTRE',
  '2': 'SEGUNDO_BIMESTRE',
  '3': 'TERCER_BIMESTRE',
  '4': 'CUARTO_BIMESTRE',
  '5': 'RECUPERACION',
};

export const CalificacionesPeriodos = () => {
  const { anio, anioJe, working } = useGetValidYears();
  const { establecimientoEfectivizado, waiting } =
    useColegioEfectivizadoContext();

  useBackButton('/private/calificaciones');
  useRouteScreenTitle('Calificaciones');
  const { periodo } = useParams<PeriodosCalificacionesParam>();
  const {
    selectedRole: { nivel },
  } = useRolesContext();

  const NIVELES: { [key: string]: string } = {
    '2': 'NIVEL_PRIMARIO',
    '3': 'NIVEL_SECUNDARIO',
  };

  const calificacionesSelector: {
    [key: string]: { [key: string]: JSX.Element };
  } = {
    NIVEL_PRIMARIO: {
      // Deberia buscar los indicadores segun ciclo lectivo
      PRIMER_BIMESTRE: (
        <TabBarCuartoBimestre working={working} validYears={{ anio, anioJe }} />
      ),
      SEGUNDO_BIMESTRE: (
        <TabBarCuartoBimestre working={working} validYears={{ anio, anioJe }} />
      ),
      TERCER_BIMESTRE: (
        <TabBarCuartoBimestre working={working} validYears={{ anio, anioJe }} />
      ),
      CUARTO_BIMESTRE: (
        <TabBarCuartoBimestre working={working} validYears={{ anio, anioJe }} />
      ),
    },
    NIVEL_SECUNDARIO: {
      PRIMER_BIMESTRE: (
        <CalificacionesSecundario
          periodo={Number(periodo)}
          working={working}
          validYears={{ anio }}
        />
      ),
      SEGUNDO_BIMESTRE: (
        <TabBarSecundario
          working={working}
          validYears={{ anio, anioJe: [{ path: 'idAnio', value: 21 }] }}
        />
      ),
      TERCER_BIMESTRE: (
        <CalificacionesSecundario
          periodo={Number(periodo)}
          working={working}
          validYears={{ anio }}
        />
      ),
      CUARTO_BIMESTRE: (
        <TabBarSecundario
          working={working}
          validYears={{ anio, anioJe: [{ path: 'idAnio', value: 21 }] }}
        />
      ),
      RECUPERACION: <CalificacionesRecuperacion />,
    },
  };

  return !waiting
    ? calificacionesSelector[NIVELES[nivel]]?.[PERIODOS[periodo]] ?? null
    : null;
};
