import React, { FunctionComponent } from 'react';
import { DatePicker as MuiDatePicker } from '@material-ui/pickers';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import {
  FilledInputProps,
  InputBaseComponentProps,
  makeStyles,
  TextField,
  TextFieldProps,
} from '@material-ui/core';
import { InputProps } from './Input';

interface DatePickerProps {
  value: ParsableDate;
  format?: string;
  error?: boolean;
  helperText?: string;
  clearable?: boolean;
  placeholder?: string;
  disabled?: boolean;
  disableFuture?: boolean;
  onChange: (date: MaterialUiPickersDate) => void;
  className?: any;
  fullWidth?: boolean;
  maxDate?: ParsableDate;
  minDate?: ParsableDate;
  inputProps?: InputBaseComponentProps;
  InputProps?: FilledInputProps;
  focused?: boolean;
  shouldDisableDate?: (date: MaterialUiPickersDate) => boolean;
}

export const DatePicker: FunctionComponent<DatePickerProps> = ({
  value,
  error,
  format = 'DD/MM/yyyy',
  onChange,
  clearable,
  helperText,
  placeholder = 'dd/mm/aaaa',
  disabled,
  disableFuture,
  className,
  fullWidth = false,
  maxDate,
  minDate,
  inputProps,
  InputProps,
  focused = false,
  shouldDisableDate = (date: MaterialUiPickersDate) => false,
}) => {
  const inputStyles = makeStyles(() => ({
    root: {
      padding: '7px',
    },
    buttonRoot: {
      fontSize: 16,
      padding: '9px 12px',
    },
  }));

  const classes = inputStyles();
  const basicInputProps = inputProps
    ? inputProps
    : { className: classes.buttonRoot };
  const classNameNormalized = className || classes.root;
  return (
    <MuiDatePicker
      shouldDisableDate={shouldDisableDate}
      fullWidth={fullWidth}
      error={error}
      value={value}
      okLabel="Aceptar"
      clearLabel="Limpiar"
      cancelLabel="Cancelar"
      format={format}
      onChange={onChange}
      clearable={clearable}
      helperText={helperText}
      disabled={disabled}
      disableFuture={disableFuture}
      placeholder={placeholder}
      className={classNameNormalized}
      InputProps={InputProps}
      inputProps={basicInputProps}
      inputVariant="outlined"
      maxDate={maxDate}
      minDate={minDate}
      focused={focused}
    />
  );
};
