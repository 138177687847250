import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { ObjetivosBloques } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'objetivos_bloques';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idObjetivosBloques',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idObjetivosBloques',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.ObjetivosBloques
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.ObjetivosBloques,
      hoc: { ...defaultOptions.hoc, ...businessOptions.ObjetivosBloques.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<ObjetivosBloques>(DAO_NAME, optionsToUse);

// hooks
export const useObjetivosBloquesFetchById = fetchById;
export const useObjetivosBloquesLibbyCall = libbyCall;
export const useObjetivosBloquesDAO = libbyDAO;
export const useObjetivosBloquesLibbyFetch = libbyFetch;

// components/hoc
export const ObjetivosBloquesAutocomplete = entityAutocomplete;
export const ObjetivosBloquesCall = renderPropCall;
export const ObjetivosBloquesById = renderPropFetchById;
export const ObjetivosBloquesSelect = simpleSelect;
export const ObjetivosBloquesAccordionSelector = accordionSelector;
export const ObjetivosBloquesSelectedChips = selectedChips;

// context
// ByIdContext
export const useObjetivosBloquesByIdContext = useByIdContext;
export const ObjetivosBloquesByIdProvider = ByIdProvider;
export const ObjetivosBloquesByIdContext = ByIdContext;
// GeneralContext
export const useObjetivosBloquesContext = useGeneralContext;
export const ObjetivosBloquesProvider = GeneralProvider;
export const ObjetivosBloquesContext = GeneralContext;
