import React, { FC, useCallback } from 'react';
import {
  Accordion as MuiAccordion,
  AccordionDetails as MuiAccordionDetails,
  AccordionSummary as MuiAccordionSummary,
  Box,
  Grid,
  Typography,
  IconButton,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { SliderInput } from '../../../../commons/components/SliderInput';
import { ExpandMore } from '@material-ui/icons';
import { SliderAccordionValues } from '../types';
import { useRolesContext } from 'src/context/RolesContext';
import { ROL } from 'src/commons';

export interface SliderInputItem {
  id: string;
  label: string;
  value: number;
}

interface SliderGroupProps {
  sliders: SliderInputItem[];
  onChange: (item: any, value: number) => void;
  disabled?: boolean;
}

type HandleChangeCallback = (
  item: SliderInputItem,
) => (event: React.ChangeEvent<{}>, value: number | number[]) => void;

const POSITION: {
  [k: string]: { right?: number | string; left?: number | string };
} = {
  right: { right: 0 },
  left: { right: undefined },
  middle: { right: '50%', left: '50%' },
};

const SliderLabel = ({
  label,
  position = 'left',
}: {
  label: string;
  position?: 'right' | 'left' | 'middle';
}) => {
  return (
    <Typography
      align="left"
      variant="caption"
      component={Box}
      position="relative"
    >
      <Grid
        position="absolute"
        top={-15}
        {...POSITION[position]}
        component={Box}
        padding={0}
        margin={0}
      >
        |
      </Grid>
      {label}
    </Typography>
  );
};

export const SliderGroup: FC<SliderGroupProps> = ({
  sliders = [],
  onChange,
}) => {
  const onHandleChange = useCallback<HandleChangeCallback>(
    (item) => (_, value) => {
      if (typeof value === 'number') {
        onChange(item, value);
      }
    },
    [onChange],
  );

  const {
    selectedRole: { rol },
  } = useRolesContext();

  const disabled = [
    ROL.REFERENTE_ACAP,
    ROL.DIRECTIVO,
    ROL.EQUIPO_ACAP,
  ].includes(Number(rol));

  return (
    <Grid xs={12} container spacing={4} component={Box} padding={3}>
      <Grid item container alignItems="center">
        <Grid item xs={5} />

        <Grid item xs={7} container justifyContent="space-between">
          <SliderLabel label="No evaluado" />
          <SliderLabel label="Grado 1" />
          <SliderLabel label="Grado 2" position="middle" />
          <SliderLabel label="Grado 3" position="right" />
        </Grid>
      </Grid>
      {sliders.map((slider) => (
        <Grid item container alignItems="center" spacing={3}>
          <Grid item container xs={5}>
            <Typography
              variant="body2"
              align="center"
              style={{ fontWeight: 'bold' }}
            >
              {slider.label}
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <SliderInput
              step={1}
              value={slider.value}
              max={3}
              onChange={onHandleChange(slider)}
              disabled={disabled}
            />
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

const Accordion = withStyles({
  root: {
    /*  border: '1px solid rgba(0, 0, 0, .125)', */
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
    width: '100%',
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    userSelect: 'none',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
    flexDirection: 'row-reverse',
  },
  content: {
    '&$expanded': {
      margin: '0px 0px',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: 32,
    display: 'flex',
  },
}))(MuiAccordionDetails);

type onChangeCallback = (
  item: SliderAccordionValues,
) => (event: React.ChangeEvent<{}>, value: number | number[]) => void;

interface SliderAcordionProps {
  contenido: SliderAccordionValues;
  onChange: onChangeCallback;
}
const SliderAcordion = ({ contenido, onChange }: SliderAcordionProps) => {
  const {
    selectedRole: { rol },
  } = useRolesContext();

  return (
    <Grid
      item
      xs={12}
      key={contenido.id}
      container
      justifyContent="center"
      style={{ paddingInline: 42 }}
    >
      <Grid item xs={12} container justifyContent="space-between">
        <SliderLabel label="No evaluado" />
        <SliderLabel label="Grado 1" />
        <SliderLabel label="Grado 2" position="middle" />
        <SliderLabel label="Grado 3" position="right" />
      </Grid>
      <Grid item xs={12}>
        <SliderInput
          step={1}
          value={contenido.valor || 0}
          max={3}
          onChange={onChange(contenido)}
          disabled={[
            ROL.REFERENTE_ACAP,
            ROL.DIRECTIVO,
            ROL.EQUIPO_ACAP,
          ].includes(Number(rol))}
        />
      </Grid>
    </Grid>
  );
};

const recursive = (
  contenido: SliderAccordionValues,
  onChange: onChangeCallback,
) => {
  return contenido.contenidos ? (
    <>
      <Accordion key={contenido.id}>
        <AccordionSummary
          hidden={!contenido.contenidos}
          expandIcon={
            <IconButton>
              <ExpandMore fontSize="large" style={{ color: '#13c1f3' }} />
            </IconButton>
          }
          IconButtonProps={{ style: { marginRight: '8px' } }}
        >
          <Grid container spacing={0} style={{ width: '100%' }}>
            <Grid item xs={12}>
              <Typography>{contenido.descripcion}</Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container justifyContent="center" spacing={2}>
            {(contenido.contenidos || []).map((c) => recursive(c, onChange))}
          </Grid>
        </AccordionDetails>
      </Accordion>
      {contenido.valor !== undefined ? (
        <SliderAcordion
          key={contenido.id}
          contenido={contenido}
          onChange={onChange}
        />
      ) : (
        <></>
      )}
    </>
  ) : (
    <>
      <Grid item container xs={10} key={contenido.id} spacing={2}>
        <Grid item xs={12}>
          <Typography>{contenido.descripcion}</Typography>
        </Grid>
        <Grid item xs={12}>
          {contenido.valor !== undefined ? (
            <SliderAcordion
              key={contenido.id}
              contenido={contenido}
              onChange={onChange}
            />
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    </>
  );
};

interface SliderAccordionProps {
  sliders: SliderAccordionValues[];
  onChange: (item: SliderAccordionValues, value: number) => void;
}

export const SliderAccordion: FC<SliderAccordionProps> = ({
  sliders = [],
  onChange,
}) => {
  const onHandleChange = useCallback<onChangeCallback>(
    (item) => (_, value) => {
      _.preventDefault();
      _.stopPropagation();
      if (typeof value === 'number') {
        onChange(item, value);
      }
    },
    [onChange],
  );

  return (
    <Grid xs={12} container component={Box} spacing={2}>
      {sliders.map((contenido) => recursive(contenido, onHandleChange))}
    </Grid>
  );
};
