import {
  useAnioLibbyCall,
  useCondicionLibbyCall,
} from '../../../../../../app/business';
import { Anio, Condicion } from '../../../../../../app/models';

export const useRegisteredEntitiesCall = () => {
  const condicion = useCondicionLibbyCall<Condicion[]>({
    methodName: 'findAll',
  });
  const anio = useAnioLibbyCall<Anio[]>({ methodName: 'findAll' });

  return {
    condicion: condicion.data || [],
    anio: anio.data || [],
    working: condicion.working || anio.working || !condicion.data || !anio.data,
  };
};
