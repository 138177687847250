import React from 'react';
import { useField } from 'react-final-form-hooks';
import { usePropuestaJEFormContext } from 'src/screens/Private/Presentismo/context/configuration/PropuestaJEFormProvider';
import { useStyles } from 'src/screens/Private/Presentismo/styles/propuestaJE';
import { presentismo } from 'src/screens/Private/Presentismo/types';

export const CheckboxWithField = ({
  dia,
  name,
  disabled = false,
}: presentismo.UI.CheckboxWithField) => {
  const classes = useStyles();
  const { form } = usePropuestaJEFormContext();
  if (!form) throw new Error('El Checkbox no está dentro del context');
  const field = useField(`${dia}.${name}`, form);

  if (!field.input.value && field.meta.visited)
    form.change(`${dia}.aceptado`, undefined);

  return (
    <div className={classes.flex}>
      <label htmlFor={`checkbox-${dia}`} className={classes.majorFlex}>
        {dia}
      </label>
      <input
        checked={field.input.value}
        id={`checkbox-${dia}`}
        type="checkbox"
        disabled={disabled}
        {...field.input}
      />
    </div>
  );
};
