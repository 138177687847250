import React from 'react';
import { PresenteGeneral } from '../../../types';

export const updatePresentes = (
  newPresentes: PresenteGeneral[],
  oldPresentes: PresenteGeneral[],
  setNewPresentismos: React.Dispatch<React.SetStateAction<PresenteGeneral[]>>,
) => {
  if (newPresentes.length > 0) {
    const ids = newPresentes
      .filter((p) => p.idPresente)
      .map((p) => p.idPresente);
    const filtered = oldPresentes.filter((p) => !ids.includes(p.idPresente));

    setNewPresentismos((presente) => {
      let toSave = presente.filter(
        (pre) =>
          !newPresentes.find(
            (_pre) =>
              _pre.alumno.idAlumno === pre.alumno.idAlumno &&
              _pre.isJornadaExtendida === pre.isJornadaExtendida &&
              _pre.fecha === pre.fecha &&
              (_pre.turno && pre.turno
                ? _pre.turno.idTurno === pre.turno.idTurno
                : pre.turno === _pre.turno) &&
              (_pre.contraturnoDia && pre.contraturnoDia
                ? _pre.contraturnoDia.idContraturnoDia ===
                  pre.contraturnoDia.idContraturnoDia
                : pre.contraturnoDia === _pre.contraturnoDia),
          ),
      );
      for (const newPresente of newPresentes) {
        const exist = presente.find((_pre) => {
          return (
            _pre.alumno.idAlumno === newPresente.alumno.idAlumno &&
            _pre.isJornadaExtendida === newPresente.isJornadaExtendida &&
            _pre.fecha === newPresente.fecha &&
            (_pre.turno && newPresente.turno
              ? _pre.turno.idTurno === newPresente.turno.idTurno
              : newPresente.turno === _pre.turno) &&
            (_pre.contraturnoDia && newPresente.contraturnoDia
              ? _pre.contraturnoDia.idContraturnoDia ===
                newPresente.contraturnoDia.idContraturnoDia
              : newPresente.contraturnoDia === _pre.contraturnoDia)
          );
        });
        if (exist) {
          toSave.push({ ...exist, ...newPresente });
        } else {
          toSave.push(newPresente);
        }
      }
      return toSave;
    });
    return [...filtered, ...newPresentes];
  } else {
    return oldPresentes;
  }
};
