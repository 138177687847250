import { Grid, makeStyles } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { useHistory } from 'react-router';
import { useLegajoAlumnoContext } from 'src/app/business';
import { ButtonTypesProps, Footer, ROL } from 'src/commons';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useLegajoData } from '../context';
import { useLegajoAlumnoForm } from '../hooks';
import { useDateValidator } from 'src/commons/hooks/useDateValidator';
import { useRolesContext } from '../../../../context/RolesContext';

const useStyles = makeStyles({
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

// Loading de las listas!
export const FormFooter = ({
  isHistoric,
}: {
  isHistoric: boolean | undefined;
}) => {
  const { working } = useLegajoData();
  const { handleSubmit, errors, submitting } = useLegajoAlumnoForm();
  const enabled = useDateValidator('Alumnos');
  const {
    selectedRole: { rol },
  } = useRolesContext();
  const history = useHistory();
  const classes = useStyles();
  const handleLeave = useCallback(async () => {
    if (!isHistoric) {
      const confirm = await confirmDialog.show({
        title: '¿Desea continuar?',
        content:
          'La información precargada se perderá en caso que no guarde, por favor seleccione una opción.',
        confirmText: 'Guardar',
        cancelText: 'Salir',
      });

      if (confirm) {
        await handleSubmit();
      }
    }
    history.push('/');
  }, [handleSubmit, history, isHistoric]);

  const buttonConfig: ButtonTypesProps[] = [
    {
      title: 'Salir',
      handleOnClick: handleLeave,
      typeButton: 'submit',
      type: 'secondary',
    },
    {
      title: 'Guardar',
      handleOnClick: handleSubmit,
      typeButton: 'submit',
      disabled:
        Object.keys(errors).length !== 0 || submitting || working || !enabled,
    },
  ];
  const isDireccionArea = useMemo(() => {
    return Number(rol) === ROL.DIRECCION_AREA;
  }, [rol]);
  // Si es historico se elimina el botón para guardar
  if (isHistoric && !isDireccionArea) buttonConfig.pop();

  return (
    <>
      <Grid className={classes.footer}>
        <Footer buttonConfig={buttonConfig} />
      </Grid>
    </>
  );
};
