import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Modalidad } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'modalidad';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idModalidad',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idModalidad',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Modalidad
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Modalidad,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Modalidad.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Modalidad>(DAO_NAME, optionsToUse);

// hooks
export const useModalidadFetchById = fetchById;
export const useModalidadLibbyCall = libbyCall;
export const useModalidadDAO = libbyDAO;
export const useModalidadLibbyFetch = libbyFetch;

// components/hoc
export const ModalidadAutocomplete = entityAutocomplete;
export const ModalidadCall = renderPropCall;
export const ModalidadById = renderPropFetchById;
export const ModalidadSelect = simpleSelect;
export const ModalidadAccordionSelector = accordionSelector;
export const ModalidadSelectedChips = selectedChips;

// context
// ByIdContext
export const useModalidadByIdContext = useByIdContext;
export const ModalidadByIdProvider = ByIdProvider;
export const ModalidadByIdContext = ByIdContext;
// GeneralContext
export const useModalidadContext = useGeneralContext;
export const ModalidadProvider = GeneralProvider;
export const ModalidadContext = GeneralContext;
