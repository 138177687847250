import { Login } from '../../../screens';
import { MenuCollection } from '../../../commons/const/ui/menu';

export const publicMenu: MenuCollection = [
  {
    key: 'public',
    target: {
      component: Login,
      path: '/',
    },
    basePath: '/public',
    enabled: true,
    icon: 'List',
    text: 'Public',
    children: [],
  },
];
