import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Grid, makeStyles, TablePagination } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { LibbyObject } from 'src/commons';
import { PermissionBlocker } from 'src/lib/permission/components/PermissionBlocker';
import { BLOCKER_ID } from 'src/platform/permission/const/BlockerId';
import { Footer, InfoTable, Loading } from 'src/commons/components';
import { level, ROL } from 'src/commons/const';
import { useLibbyFetch as useLibbyFetchWithAspect } from 'src/lib/libby/hooks/useLibbyFetch';
import { useDebounce } from 'src/commons/hooks/useDebounce';
import { useRolesContext } from 'src/context/RolesContext';
import {
  useAlumnoMovimientoDAO,
  useAnioLibbyFetch,
  useCertificadoSeptimoLibbyFetch,
  useCicloLectivoLibbyCall,
  useIgualarCalificacionesPostRequest,
  usePlanEstudioSeccionGetRequest,
  usePlanEstudioSeccionPostRequest,
  useProyeccionEstadoDAO,
  useProyeccionLibbyFetch,
  useTurnoLibbyFetch,
} from 'src/app/business';
import { CopiadoCalificaciones, FilterTablesRegistered } from './components';
import { useMatriculacionColumns, useRegisteredEntitiesCall } from './hooks';
import { useInformesMatriculacionGetRequest } from 'src/app/business/businessCustomEndpoints/informesMatriculacion';
import { useMatriculationContext } from '../../context/Context';
import { useDateValidator } from 'src/commons/hooks/useDateValidator';
import { useGeneralContext } from 'src/context/GeneralContext';
import customFormDialog from 'src/commons/services/customFormDialog';
import {
  AlumnoMovimiento,
  ProyeccionEstadoType,
  Seccion,
} from 'src/app/models';
import { useSnackbar } from 'notistack';
import { useStyles } from './styles';
import { ProyeccionEstadoDAO } from 'src/platform/libby/dao';
import { useMountFetchById } from 'src/commons/hooks/useMountFetchById';
import { EspacioCurricularPaseResult } from '../types';
import { useCondicionLibbyFetch } from '../../../../../app/business/Condicion';
import { primary } from 'src/theme/colors';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
import { useConfirmarMatricula } from './hooks/useConfirmarMatricula';
import { useGetMatriculaConfig } from './hooks/useGetMatriculaConfig';
import { useAlumnoPassValidationV2 } from '../../customHooks';
import _ from 'lodash';

const customStyles = makeStyles({
  iconColor: { color: primary.paleBlueStrong, cursor: 'pointer' },
  backgroundRow: { background: primary.paleBlue },
  loader: {
    margin: '200px 0px',
  },
  customContainer: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    backgroundColor: '#000000A0',
    height: '100vh',
    width: '100vw',
    zIndex: 10000,
  },
  loadingMessage: {
    position: 'absolute',
    marginTop: '6em',
    fontWeight: 800,
    fontFamily: 'Open Sans',
    color: 'white',
    userSelect: 'none',
  },
});
export const saveEquivalencias = async (resolve, request) => {
  const data: any = [];
  Object.keys(resolve).forEach((key: any) => {
    resolve[key].forEach((element: any) => {
      const alumnosSeccion: string[][] = [];
      const match: string[][] = [];

      element.alumnos.forEach((alumnoMovimiento: AlumnoMovimiento) => {
        alumnosSeccion.push([
          alumnoMovimiento.alumno.idAlumno,
          element.secciones[1],
        ]);
      });

      element.planesOrigen.forEach((espacio: any) => {
        if (Number(espacio.match)) {
          match.push([espacio.idEspacioCurricular, espacio.match]);
        }
      });

      data.push({
        alumnosSeccion,
        match,
      });
    });
  });

  try {
    await request({ data });
    return true;
  } catch (error) {
    return false;
  }
};

const InitialRegisteredRaw = ({ libby }: LibbyObject) => {
  const roleContext = useRolesContext();
  const classes = useStyles();
  const { dispatch } = useGeneralContext();

  const { working: requestWorking, request: requestIgualarCalificaciones } =
    useIgualarCalificacionesPostRequest();

  const {
    state: { section, ciclo },
    dispatch: { setSection, setCiclo },
  } = useMatriculationContext();
  const { localizacionId, nivel, rol } = roleContext.selectedRole;
  const nivelId = nivel;

  const enabled = useDateValidator('Alumnos');

  const [direction, setDirection] = React.useState<'asc' | 'desc'>('asc');
  const [alumnRows, setAlumnRows] = useState<
    Array<AlumnoMovimiento & { newSeccion?: Seccion }>
  >([]);
  const [alumnsWithSections, setAlumnsWithSections] = useState<
    typeof alumnRows
  >([]);
  const [orderBy, setOrderBy] = useState(
    'alumno.persona.apellido,alumno.persona.nombre',
  );
  const alumnoMovimientoDAOAggregate = useAlumnoMovimientoDAO();
  const [alumnosCount, setAlumnosCount] = useState([]);
  const [proyeccionFilter, setProyeccionFilter] = useState({});
  const [alumnosCountWorking, setAlumnosCountWorking] = useState(false);
  const [formValues, setFormValues] = useState({
    [`seccion.anio`]: '',
    [`seccion.turno`]: '',
    [`seccion.nombreSeccion`]: '',
    [`seccion.jornada`]: '',
    [`cicloLectivo.idCicloLectivo`]: '',
    [`alumno.condicion`]: '',
  });
  const [search, setSearch] = useState('');

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isSaving, setIsSaving] = useState(false);
  const [isSavingMatriculacion, setIsSavingMatriculacion] = useState(false);
  const [isUsingHandleSave, setIsUsingHandleSave] = useState(false);
  const { cicloLectivo: cicloLectivoContext, establecimientoEfectivizado } =
    useColegioEfectivizadoContext();
  const INITIAL_YEAR = cicloLectivoContext?.anio;

  const handleChangePage = (
    _: React.MouseEvent<HTMLButtonElement>,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const componentClasses = customStyles();
  const anioFilter = useMemo(
    () => ({
      0: [
        {
          path: 'nivel.idNivel',
          value: nivel,
        },
      ],
    }),
    [nivel],
  );

  const { data: anios = [] } = useAnioLibbyFetch({
    filter: anioFilter,
  });

  const { data: turnos = [] } = useTurnoLibbyFetch();

  const { enqueueSnackbar } = useSnackbar();

  const searchDebounced = useDebounce(search, 1000);

  const proyeccionEstadoDAO = useProyeccionEstadoDAO();

  const { data: condiciones = [] } = useCondicionLibbyFetch();

  const { data: proyeccionEstado } = useMountFetchById<
    ProyeccionEstadoType,
    ProyeccionEstadoDAO
  >(proyeccionEstadoDAO, localizacionId, 'default');

  const {
    data: alumnosInformes = [],
    working: alumnosWorking,
    request: alumnosReFetch,
  } = useInformesMatriculacionGetRequest(
    {
      searchDebounced,
      localizacionId,
      nivelId,
      rowsPerPage,
      page,
      orderBy,
      direction,
      section,
      ciclo,
    },
    formValues,
  );

  useEffect(() => {
    if (!alumnosWorking && alumnosInformes[0]?.cicloLectivo) {
      dispatch.setLegajoCicloLectivo(
        alumnosInformes[0].cicloLectivo.idCicloLectivo,
      );
    }
  }, [dispatch, alumnosInformes, alumnosWorking]);

  const aggregateFilter = useMemo(
    () => ({
      0: [
        {
          path: 'seccion.localizacion',
          value: localizacionId,
        },
      ],
      1: [
        {
          path: 'seccion.nivel',
          value: nivelId,
        },
      ],
      2: formValues[`seccion.anio`]
        ? [
            {
              path: 'seccion.anio',
              value: formValues[`seccion.anio`],
            },
          ]
        : {},
      3: section
        ? [
            {
              path: 'seccion.idSeccion',
              value: section,
            },
          ]
        : {},
      4: formValues[`seccion.turno`]
        ? [{ path: 'seccion.turno', value: formValues[`seccion.turno`] }]
        : {},
      5: ciclo
        ? [
            {
              path: 'cicloLectivo.idCicloLectivo',
              value: ciclo,
            },
          ]
        : {},
      6: formValues[`alumno.condicion`]
        ? [
            {
              path: 'alumno.condicion',
              value: formValues[`alumno.condicion`],
            },
          ]
        : {},
    }),
    [formValues, localizacionId, nivelId, section, ciclo],
  );

  useEffect(() => {
    setAlumnosCountWorking(true);
    alumnoMovimientoDAOAggregate
      .getCountAll(aggregateFilter, 'matriculacion_secciones_alumnoCount')
      .then((element: any) => {
        setAlumnosCount(element);
        setAlumnosCountWorking(false);
      });
  }, [aggregateFilter, alumnoMovimientoDAOAggregate]);

  useEffect(() => {
    setAlumnRows([]);
  }, [alumnosWorking]);

  const paramsFetchSeccion = useMemo(
    () => ({
      daoName: 'seccion',
      orderBy: 'anio',
      limit: 100,
      filter: {
        0: [{ path: 'localizacion', value: localizacionId }],
        1: [{ path: 'nivel', value: nivel }],
        2: ciclo
          ? [{ path: 'cicloLectivo', value: ciclo }]
          : [{ path: 'cicloLectivo.anio', value: INITIAL_YEAR }],
      },
      aspect: 'matriculacion_matriculados_seccionesFilter',
    }),
    [localizacionId, nivel, ciclo, INITIAL_YEAR],
  );

  const { data: secciones = [], working: seccionWorking } =
    useLibbyFetchWithAspect<Seccion>(paramsFetchSeccion);

  useEffect(() => {
    if (alumnosInformes.length) {
      const filter = alumnosInformes.map((element) => {
        return { path: 'alumno.idAlumno', value: element.alumno.idAlumno };
      });

      setProyeccionFilter({
        0: filter,
        1: [{ path: 'cicloLectivo.anio', value: Number(INITIAL_YEAR) - 1 }],
        //Falta atajar Null!
        2: [{ path: 'estadoPaseAnio.idEstadoPaseAnio', value: 5 }],
      });
    }
  }, [INITIAL_YEAR, alumnosInformes]);

  const { data: proyecciones = [] } = useProyeccionLibbyFetch({
    limit: 100,
    filter: proyeccionFilter,
    enabled: proyeccionEstado.length > 0 && !_.isEmpty(proyeccionFilter),
  });
  const handleChangeSection = useCallback(
    (
      e: React.FormEvent<EventTarget>,
      mov: AlumnoMovimiento & { newSeccion: Seccion },
    ) => {
      const newSection = secciones.find(
        (element) => element.idSeccion === e.target.value,
      )!;
      if (alumnRows.length) {
        const currentIndex = alumnRows.findIndex(
          (row) => row.alumno.idAlumno === mov.alumno.idAlumno,
        );
        const newAlumnRows = [...alumnRows];
        if (newSection) {
          newAlumnRows[currentIndex].newSeccion = newSection;
        } else {
          const { newSeccion, ...item } = newAlumnRows[currentIndex];
          newAlumnRows[currentIndex] = item;
        }

        setAlumnRows(newAlumnRows);
      } else {
        const currentIndex = alumnosInformes.findIndex(
          (row) => row.alumno.idAlumno === mov.alumno.idAlumno,
        );
        const newAlumnRows = [...alumnosInformes] as Array<
          AlumnoMovimiento & { newSeccion?: Seccion }
        >;
        if (newSection) {
          newAlumnRows[currentIndex].newSeccion = newSection;
        } else {
          const { newSeccion, ...item } = newAlumnRows[currentIndex];
          newAlumnRows[currentIndex] = item;
        }
        setAlumnRows(newAlumnRows);
      }

      const updatedAlumn = { ...mov, newSeccion: newSection ?? '' };
      if (
        newSection &&
        !alumnsWithSections.some(
          (am) => am.alumno.idAlumno === mov.alumno.idAlumno,
        )
      ) {
        setAlumnsWithSections([...alumnsWithSections, updatedAlumn]);
      } else if (
        alumnsWithSections.some(
          (am) => am.alumno.idAlumno === mov.alumno.idAlumno,
        ) &&
        e.target.value !== ''
      ) {
        const indexToSubstitute = alumnsWithSections.findIndex(
          (item) => item.alumno.idAlumno === mov.alumno.idAlumno,
        );
        const newState = [...alumnsWithSections];
        newState[indexToSubstitute] = mov;
        setAlumnsWithSections(newState);
      } else {
        setAlumnsWithSections([
          ...alumnsWithSections.filter(
            (item) => item.alumno.idAlumno !== mov.alumno.idAlumno,
          ),
        ]);
      }
    },
    [secciones, alumnRows, alumnsWithSections, alumnosInformes],
  );

  const mapData = useRegisteredEntitiesCall();

  const [nombreSeccion, setNombreSeccion] = useState('');
  const [actualizandoIdSeccion, setActualizandoIdSeccion] = useState(false);

  // Al cambiar el ciclo se consultan nuevamente las secciones
  // Estas nuevas secciones deben setearse en el formValues
  useEffect(() => {
    const seccionSeleccionada = secciones.filter(
      (s) => s.nombreSeccion === nombreSeccion,
    );
    if (
      actualizandoIdSeccion &&
      seccionSeleccionada[0] &&
      seccionSeleccionada[0].idSeccion !== formValues['seccion.nombreSeccion']
    ) {
      setSection(seccionSeleccionada[0].idSeccion);
      setFormValues({
        ...formValues,
        'seccion.nombreSeccion': seccionSeleccionada[0].idSeccion,
      });
      setActualizandoIdSeccion(false);
    }
  }, [nombreSeccion, secciones, formValues, setSection, actualizandoIdSeccion]);

  const handleChange = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    if (target.name === 'seccion.nombreSeccion') {
      setSection(target.value);
    }
    if (target.name === 'cicloLectivo.idCicloLectivo') {
      const seccionPrevia = secciones.filter(
        (s) => s.idSeccion === formValues['seccion.nombreSeccion'],
      )[0];
      if (seccionPrevia?.nombreSeccion && formValues['seccion.nombreSeccion']) {
        setActualizandoIdSeccion(true);
        setNombreSeccion(seccionPrevia?.nombreSeccion);
      }
      setCiclo(target.value);
    }
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  const handleChangeSearch = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setSearch(target.value);
  };

  const prepareQueryString = useMemo(() => {
    let secciones: string[] = [];
    alumnsWithSections.forEach((alumn) => {
      if (!alumn.newSeccion || alumn.seccion === null) return;
      secciones.push(alumn.seccion?.idSeccion);
      secciones.push(alumn.newSeccion.idSeccion);
    });
    return secciones.join(',');
  }, [alumnsWithSections]);

  const { request, working: planEstudioSeccionPostWorking } =
    usePlanEstudioSeccionPostRequest();

  const {
    data = [],
    request: planEstudioSeccionRequest,
    working: workingPlanEstudioGet,
  } = usePlanEstudioSeccionGetRequest<EspacioCurricularPaseResult>({
    url: `/api/public/custom/pasajeCalf/planestudioseccion?secciones=${prepareQueryString}`,
    autoCall: false,
  });

  const cleanRows = useCallback(() => {
    setAlumnRows([
      ...alumnRows.map((row) => {
        const { newSeccion, ...newRow } = row;

        return newRow;
      }),
    ]);
    setAlumnsWithSections([]);
  }, [setAlumnRows, alumnRows]);

  const handleSubmit = useCallback(
    async (data) => {
      const response: any = await requestIgualarCalificaciones({
        data: alumnsWithSections?.map((alumno: any) => {
          return {
            idAlumno: alumno.alumno?.idAlumno,
            idSeccionOrigen: alumno.seccion?.idSeccion,
            idSeccionDestino: alumno.newSeccion?.idSeccion,
          };
        }),
      });
      if (response?.shouldSeeSnackBar) {
        enqueueSnackbar(response?.text, {
          variant: 'success',
          autoHideDuration: 2000,
        });
      }
      const success = await saveEquivalencias(data, request);
      alumnosReFetch();
      enqueueSnackbar(
        success ? 'El proceso ha sido exitoso!' : 'Ocurrió un error!',
        {
          variant: success ? 'success' : 'error',
          autoHideDuration: 2000,
        },
      );

      cleanRows();
    },
    [
      request,
      alumnosReFetch,
      enqueueSnackbar,
      requestIgualarCalificaciones,
      alumnsWithSections,
      cleanRows,
    ],
  );

  const handleSaveMethod = async (newData: any) => {
    if (
      newData.isNotTheSamePlan.length > 0 &&
      (Number(nivel) === level.PRIMARIO || Number(nivel) === level.SECUNDARIO)
    ) {
      setIsSaving(false);
      setIsUsingHandleSave(false);
      const confirm = await customFormDialog.show({
        title: 'Copiado de calificaciones',
        sizeWidth: 'lg',
        renderComponent: (
          <CopiadoCalificaciones
            alumnsWithSections={alumnsWithSections}
            dataFormatted={newData}
            fetch={alumnosReFetch}
            cleanRows={cleanRows}
            requestIgualarCalificaciones={requestIgualarCalificaciones}
          />
        ),
      });
      enqueueSnackbar(
        confirm ? 'El proceso ha sido exitoso!' : 'Ocurrió un error!',
        {
          variant: confirm ? 'success' : 'error',
        },
      );
    } else {
      handleSubmit(newData);
    }
    setIsSaving(false);
    setIsUsingHandleSave(false);
  };

  useEffect(() => {
    if (data.length > 0 && isSaving) {
      const newData: any = { isTheSamePlan: [], isNotTheSamePlan: [] };
      data.forEach((item) => {
        const alumnos = alumnsWithSections.filter((alumno) => {
          return (
            alumno.seccion.idSeccion === item.secciones[0].toString() &&
            alumno.newSeccion?.idSeccion === item.secciones[1].toString()
          );
        });

        const alreadyInDataSamePlan = newData.isTheSamePlan.some(
          (data: any) => {
            return (
              data.secciones[0] === item.secciones[0] &&
              data.secciones[1] === item.secciones[1]
            );
          },
        );

        const alreadyInDataDifferentPlan = newData.isNotTheSamePlan.some(
          (data: any) => {
            return (
              data.secciones[0] === item.secciones[0] &&
              data.secciones[1] === item.secciones[1]
            );
          },
        );

        const isTheSamePlan =
          item.planesOrigen.length > 0 && item.planesDestino.length > 0
            ? item.planesOrigen[0].planEstudio.idPlanEstudio ===
                item.planesDestino[0].planEstudio.idPlanEstudio &&
              item.planesOrigen[0].idEspacioCurricular.includes(
                item.planesDestino[0].idEspacioCurricular,
              )
            : true;

        const doesNotExists = !(
          alreadyInDataSamePlan || alreadyInDataDifferentPlan
        );

        if (doesNotExists) {
          newData[isTheSamePlan ? 'isTheSamePlan' : 'isNotTheSamePlan'].push({
            ...item,
            isTheSamePlan,
            alumnos: alumnos,
          });
        }
      });

      handleSaveMethod(newData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleSave = useCallback(() => {
    setIsSaving(true);
    setIsUsingHandleSave(true);
    planEstudioSeccionRequest();
  }, [planEstudioSeccionRequest]);

  const working = useMemo(
    () =>
      alumnosWorking ||
      mapData.working ||
      seccionWorking ||
      planEstudioSeccionPostWorking ||
      isUsingHandleSave ||
      requestWorking,
    [
      alumnosWorking,
      mapData.working,
      seccionWorking,
      planEstudioSeccionPostWorking,
      isUsingHandleSave,
      requestWorking,
    ],
  );

  let inscriptosPendientes = useMatriculationContext().inscriptos.length > 0;
  let pasesPendientes = useMatriculationContext().passRequest.length > 0;
  let inscriptosPendientesNumber = useMatriculationContext().inscriptos.length;
  let pasesPendientesNumber = useMatriculationContext().passRequest.length;

  const checkPlurals = (
    plural: number | false,
    messageSingular: string,
    messagePlural: string,
  ) => {
    if (plural === 1) {
      return messageSingular;
    }
    if (typeof plural === 'number' && plural > 1) {
      return messagePlural;
    }
  };

  let tooltipMessage =
    (pasesPendientes &&
      inscriptosPendientes &&
      `Tienes ${pasesPendientesNumber} ${checkPlurals(
        pasesPendientesNumber,
        'gestion de pase pendiente',
        'gestiones de pases pendientes',
      )} y ${inscriptosPendientesNumber} ${checkPlurals(
        inscriptosPendientesNumber,
        'inscripto pendiente',
        'inscriptos pendientes',
      )}`) ||
    (pasesPendientes &&
      `Tienes ${pasesPendientesNumber} ${checkPlurals(
        pasesPendientesNumber,
        'gestion de pase pendiente',
        'gestiones de pases pendientes',
      )}`) ||
    (inscriptosPendientes &&
      `Tienes ${inscriptosPendientesNumber} ${checkPlurals(
        inscriptosPendientesNumber,
        'inscripto pendiente',
        'inscriptos pendientes',
      )}`);

  const workingLoading = useMemo(
    () =>
      planEstudioSeccionPostWorking ||
      isUsingHandleSave ||
      requestWorking ||
      isSavingMatriculacion,
    [
      planEstudioSeccionPostWorking,
      isUsingHandleSave,
      requestWorking,
      isSavingMatriculacion,
    ],
  );
  const {
    data: { isValid: validConfConfirmacion },
    generados,
  } = useGetMatriculaConfig() || {};

  const { confirmarMatricula, confirmacionInfoLoading } =
    useConfirmarMatricula();

  let buttonConfig: any = [
    {
      title: confirmacionInfoLoading ? <Loading width={30} /> : 'Confirmar',
      tooltip: tooltipMessage,
      disabled:
        confirmacionInfoLoading ||
        !validConfConfirmacion ||
        inscriptosPendientes ||
        pasesPendientes ||
        !establecimientoEfectivizado ||
        !(
          Number(rol) === ROL.DIRECTIVO || Number(rol) === ROL.EQUIPO_CONDUCCION
        ),
      handleOnClick: () => confirmarMatricula(),
    },
    {
      title: 'Actualizar',
      handleOnClick: () => handleSave(),
      size: 'medium',
      // Se evita actualizar los datos si no se preparó la query
      // Para evitar mensajes de errores
      disabled: !enabled || !prepareQueryString || isSaving || working,
    },
  ];

  if (generados.length > 0 && establecimientoEfectivizado) {
    buttonConfig.splice(0, 0, {
      title: 'Descargar',
      handleOnClick: () => {
        window.open(generados[0].url, '_blank');
      },
    });
  }

  const [tableRows, setTableRows] = useState<AlumnoMovimiento[]>([]);
  useEffect(() => {
    if (alumnosInformes.length > 0) {
      setTableRows([...alumnosInformes]);
    }
  }, [alumnosInformes]);

  const alumnosIds = tableRows.map((row) => row.alumno.idAlumno);
  const alumnosValidationData = useAlumnoPassValidationV2({
    alumnosIds,
  });

  const filterCertificados = useMemo(() => {
    return {
      alumnos: [
        {
          path: 'idAlumno',
          value: alumnosInformes.map((a) => a.alumno.idAlumno),
          method: 'in',
        },
      ],
    };
  }, [alumnosInformes]);

  const { data: certificados = [], working: certificadosWorking } =
    useCertificadoSeptimoLibbyFetch({
      filter: filterCertificados,
      enabled: alumnosInformes.length > 0,
    });

  const columns = useMatriculacionColumns(
    secciones,
    handleChangeSection,
    setTableRows,
    alumnosReFetch,
    proyecciones,
    setIsSavingMatriculacion,
    alumnosValidationData,
    prepareQueryString,
    alumnosWorking || certificadosWorking,
    certificados,
  );

  const rowsToShow = () => {
    return alumnosWorking ||
      certificadosWorking ||
      workingPlanEstudioGet ||
      planEstudioSeccionPostWorking
      ? []
      : alumnRows.length
      ? alumnRows
      : alumnosInformes.length
      ? tableRows
      : [];
  };

  const { data: ciclo_lectivo = [] } = useCicloLectivoLibbyCall({
    methodName: 'getAll',
  });

  useEffect(() => {
    return () => {
      setCiclo(null);
      setSection(null);
    };
  }, [setCiclo, setSection]);

  return (
    <>
      <FilterTablesRegistered
        ciclosLectivos={ciclo_lectivo}
        year={anios}
        turn={turnos}
        section={secciones}
        condicion={condiciones}
        handleChangeSearch={handleChangeSearch}
        search={search}
        handleChange={handleChange}
        formValues={formValues}
        working={working}
      />

      <>
        <InfoTable
          rows={rowsToShow()}
          columns={columns}
          working={
            alumnosWorking ||
            workingPlanEstudioGet ||
            planEstudioSeccionPostWorking ||
            actualizandoIdSeccion
          }
          rowIdKey="id_seccion"
          direction={direction}
          onSortChange={handleRequestSort}
          customStyle={classes.infoTableCustom}
        />
      </>

      <TablePagination
        className={classes.paginationContainer}
        rowsPerPageOptions={[5, 10, 25, 100]}
        component="div"
        count={alumnosCount[0]?.countofidalumnomovimiento}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={(e) =>
          handleChangeRowsPerPage(parseInt(e.target.value))
        }
        labelRowsPerPage="Filas por página"
        labelDisplayedRows={({ from, to, count }) => {
          return alumnosCountWorking || actualizandoIdSeccion
            ? `Calculando...`
            : `${from} - ${to} de ${count}`;
        }}
      />
      {workingLoading && (
        <Grid
          className={`${componentClasses.customContainer}`}
          container
          justify="center"
          alignItems="center"
        >
          <Loading />
          <div className={`${componentClasses.loadingMessage}`}>
            <span>Esta operación puede demorar un poco, por favor espere.</span>
          </div>
        </Grid>
      )}

      <PermissionBlocker id={BLOCKER_ID.EDIT_STUDENT}>
        <Grid className={classes.footer}>
          <Footer buttonConfig={buttonConfig} />
        </Grid>
      </PermissionBlocker>
    </>
  );
};

export const InitialRegistered = DatabaseConnector(InitialRegisteredRaw)(
  'anio',
  'turno',
  'alumno',
  'inscripcion_alumno',
  'seccion',
  'informes',
  'ciclo_lectivo',
  'condicion',
);
