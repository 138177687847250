import {
  Box,
  Grid,
  IconButton,
  makeStyles,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useCallback, useEffect } from 'react';
import { useMemo } from 'react';
import {
  useAlumnoDAO,
  useGenerarConstanciaPostRequest,
  useTipoInformesLibbyCall,
} from 'src/app/business';
import { AnyObject, OptionsModal, OptionsReportsModal, ROL } from 'src/commons';
import confirmDialog from 'src/commons/services/confirmDialog';
import customFormDialog from 'src/commons/services/customFormDialog';
import { useRolesContext } from 'src/context/RolesContext';
import { useActivePermissionBlocker } from 'src/lib/permission';
import { useLegajoLogic } from 'src/screens/Private/LegajoAlumno';
import { primary } from 'src/theme/colors';
import {
  ConditionRegisteredModal,
  RegisteredAlumnModal,
} from '../../components';
import { Loading, SimpleSelect } from 'src/commons/components';
import { BLOCKER_ID } from 'src/platform/permission/const/BlockerId';
import { useDateValidator } from 'src/commons/hooks/useDateValidator';
import { isValidChangeSectionYear } from '../../../utils/isValidChangeSectionYear';
import { isHistoricSection } from 'src/screens/Private/Matriculation/utils/isHistoricSection';
import {
  AlumnoMovimiento,
  CertificadoSeptimo,
  ConstanciaError,
  ConstanciaSuccess,
  Seccion,
} from 'src/app/models';
import { TooltipErrors } from '../../../Promotion/components/TooltipsError';
import { AlumnoCheckResultV2 } from '../../../customHooks';
import { setAlumnoChangeSeccionMessages } from '../../../utils/alumnoPassValidation';
import { level, TIPO_CONDICION } from 'src/commons/const';
import EditIcon from '@material-ui/icons/Edit';
import { cloneDeep } from 'lodash';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
import moment from 'moment';

const useStyles = makeStyles({
  alumnoButton: {
    color: primary.lightBlue,
    margin: '0',
    background: 'none',
    textAlign: 'left',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  iconButton: {
    width: 33,
    height: 33,
    backgroundColor: 'transparent !important',
    color: '#00b3e3',
  },
  icon: {
    width: 22,
    height: 22,
  },
});

export const useMatriculacionColumns = (
  seccion: Seccion[],
  handleChangeSection: (arg0: React.FormEvent<EventTarget>, arg1: any) => void,
  setTableRows: React.Dispatch<React.SetStateAction<AlumnoMovimiento[]>>,
  reFetch: () => void,
  proyecciones: any,
  setIsSavingMatriculacion: React.Dispatch<React.SetStateAction<boolean>>,
  alumnosValidationData: {
    alumnos: AlumnoCheckResultV2[];
    working: boolean;
  },
  prepareQueryString: string,
  alumnosWorking: boolean,
  certificados: CertificadoSeptimo[],
) => {
  const { proyeccionEstado, cicloLectivo: currentCicloLectivo } =
    useColegioEfectivizadoContext();

  const classes = useStyles();
  const roleContext = useRolesContext();
  const { enqueueSnackbar } = useSnackbar();

  const { toLegajoAlumno } = useLegajoLogic(seccion);
  const {
    userInfo,
    selectedRole: { localizacionId },
  } = roleContext;
  const editStudentBlocked = useActivePermissionBlocker(
    BLOCKER_ID.DELETE_STUDENT,
  );

  const { data: tipoInformes } = useTipoInformesLibbyCall({
    methodName: 'getAll',
  });

  const enabled = useDateValidator('Alumnos');

  const removeAlumn = useCallback(
    async (
      nombre: string,
      idAlumnoMovimiento: number,
      idAlumno: number,
      alumnoMovimiento: AlumnoMovimiento,
    ) => {
      try {
        const confirm = await confirmDialog.show({
          title: 'Dar de baja',
          content: `¿Estás seguro que desea desmatricular a ${nombre}?`,
          confirmText: 'Confirmar',
          cancelText: 'Cancelar',
        });
        if (confirm) {
          customFormDialog.show({
            title: 'Motivo de baja',
            renderComponent: (
              <RegisteredAlumnModal
                alumn={idAlumno}
                alumnMovement={idAlumnoMovimiento}
                reFetch={reFetch}
                alumnoMovimiento={alumnoMovimiento}
                localizacionId={localizacionId}
                setLoading={setIsSavingMatriculacion}
              />
            ),
            sizeWidth: 'md',
          });
        }
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Ha ocurrido un error al intentar guardar', {
          variant: 'error',
        });
      }
    },
    [reFetch, enqueueSnackbar, localizacionId, setIsSavingMatriculacion],
  );

  const alumnoDAO = useAlumnoDAO();

  const {
    selectedRole: { rol, nivel },
  } = useRolesContext();

  const canChangeCondition = useMemo(() => {
    const isInitialPrimaria = [1, 2].includes(Number(nivel));
    const isSecundaria = Number(nivel) === 3;

    return (
      (isInitialPrimaria && ROL.SUPERVISOR === Number(rol)) ||
      (isSecundaria && ROL.SUPERVISOR === Number(rol))
    );
  }, [rol, nivel]);

  const {
    data,
    request: generateConstancia,
    working,
  } = useGenerarConstanciaPostRequest<ConstanciaError | ConstanciaSuccess>();

  const globalWorking = working || alumnosValidationData.working;

  // Se abre la constancia en una tab nueva
  useEffect(() => {
    if ((data as ConstanciaSuccess)?.link)
      window.open((data as ConstanciaSuccess).link);
  }, [data]);

  return useMemo(
    () => [
      {
        id: 'nombre',
        label: 'Apellido y nombre',
        width: '15%',
        orderById: 'alumno.persona.apellido,alumno.persona.nombre',
        render: (_: never, row: AlumnoMovimiento) => (
          <button
            type="button"
            className={classes.alumnoButton}
            onClick={() => toLegajoAlumno(row?.alumno?.idAlumno)}
          >
            {row?.alumno?.persona?.apellido +
              ', ' +
              row?.alumno?.persona?.nombre}
          </button>
        ),
      },
      {
        id: 'documento',
        label: 'Documento',
        width: '5%',
        orderById: 'alumno.persona.documento',
        render: (_: never, row: AlumnoMovimiento) =>
          row?.alumno?.persona?.documento,
      },
      {
        id: 'alumno.condicion.descripcionCondicion',
        label: 'Condición',
        width: '5%',
        orderById: 'alumno.condicion.descripcionCondicion',
        render: (_: any, row: AlumnoMovimiento) => {
          const handleShowCondiciones = async () => {
            const resultado = await customFormDialog.show({
              title: '',
              renderComponent: <ConditionRegisteredModal data={row} />,
              sizeWidth: 'sm',
            });
            if (resultado) {
              const resAlumno = await alumnoDAO.save({
                ...row.alumno,
                condicion: 1,
                ...resultado,
              });
              enqueueSnackbar(
                'La condición ha sido modificada correctamente.',
                {
                  variant: 'success',
                },
              );
              setTableRows((prev) => {
                const copy = cloneDeep(prev);
                const alumno = copy.find(
                  (a) => a.alumno.idAlumno === row.alumno.idAlumno,
                );
                if (alumno) {
                  alumno.alumno = resAlumno;
                }
                return copy;
              });
            } else {
              enqueueSnackbar('La condición no ha podido ser modificada.', {
                variant: 'error',
              });
            }
          };

          return (
            <>
              {alumnosWorking ? (
                <Loading />
              ) : canChangeCondition ? (
                <Grid
                  container
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <Grid item xs={10}>
                    <Typography>
                      {row.alumno.condicion.descripcionCondicion}
                    </Typography>
                  </Grid>
                  {row.alumno.condicion.idCondicion ===
                  TIPO_CONDICION.REGULAR ? (
                    <Tooltip title={'No se puede editar la condición regular'}>
                      <Grid item xs={2}>
                        <IconButton
                          className={classes.iconButton}
                          size="small"
                          onClick={() => {}}
                          disabled={true}
                        >
                          <EditIcon className={classes.icon} />
                        </IconButton>
                      </Grid>
                    </Tooltip>
                  ) : (
                    <Grid item xs={2}>
                      <IconButton
                        className={classes.iconButton}
                        size="small"
                        onClick={handleShowCondiciones}
                      >
                        <EditIcon className={classes.icon} />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              ) : (
                <Typography>
                  {row.alumno.condicion.descripcionCondicion}
                </Typography>
              )}
            </>
          );
        },
      },
      {
        id: 'descripcionAnio',
        label: 'Año',
        width: '5%',
        orderById: 'seccion.anio.descripcionAnio',
        render: (_: never, row: AlumnoMovimiento) =>
          row?.seccion?.anio.descripcionAnio,
      },
      {
        id: 'descripcionTurno',
        label: 'Turno',
        width: '5%',
        orderById: 'seccion.turno.descripcionTurno',
        render: (_: never, row: AlumnoMovimiento) =>
          row?.seccion?.turno?.descripcionTurno,
      },
      {
        id: 'jornada',
        label: 'Jornada',
        width: '5%',
        orderById: 'seccion.jornada',
        render: (_: never, row: AlumnoMovimiento) => row?.seccion?.jornada,
      },
      {
        id: 'division',
        label: 'División',
        width: '5%',
        orderById: 'seccion.division',
        render: (_: never, row: AlumnoMovimiento) => row.seccion.division,
      },
      {
        id: 'selectedSeccion',
        label: 'Cambio de sección',
        width: '15%',
        hideSortIcon: true,
        noSort: true,
        render: (_: never, row: AlumnoMovimiento) => {
          const proyeccion = proyecciones.find(
            (p: any) => p?.alumno?.idAlumno === row?.alumno?.idAlumno,
          );

          const validChangeSection = seccion.filter(
            (_item: AnyObject) =>
              (_item?.anio.idAnio === row?.seccion?.anio?.idAnio ||
                isValidChangeSectionYear(
                  _item?.anio.idAnio,
                  row?.seccion?.anio?.idAnio,
                  !!proyeccion,
                )) &&
              _item?.nivel?.idNivel === row?.seccion?.nivel?.idNivel &&
              _item?.idSeccion !== row?.seccion?.idSeccion &&
              _item?.cicloLectivo?.anio === row?.cicloLectivo.anio,
          );
          const isHistoric = isHistoricSection({
            currentCicloLectivo:
              currentCicloLectivo?.anio || proyeccionEstado?.cicloLectivo.anio,
            cicloLectivo: row.cicloLectivo.anio,
          });
          const alumnoValidationData = alumnosValidationData.alumnos.find(
            (alumno) => alumno.idAlumno === row?.alumno?.idAlumno,
          );
          const alumnoPassValidationMessages = setAlumnoChangeSeccionMessages({
            hasPresentismo: alumnoValidationData?.hasCompletePresentismo,
            hasBoletinGenerado: alumnoValidationData?.hasGeneratedBoletin,
            isNivelInicial: Number(nivel) === level.INICIAL,
            anio: row.seccion.anio,
          });
          return (
            <Tooltip
              disableFocusListener={alumnoPassValidationMessages?.length === 0}
              disableHoverListener={alumnoPassValidationMessages?.length === 0}
              disableTouchListener={alumnoPassValidationMessages?.length === 0}
              title={
                <TooltipErrors
                  errorMessages={working ? [] : alumnoPassValidationMessages}
                  title={
                    <h4>
                      No es posible cambiar de sección porque hay acciones
                      pendientes:
                    </h4>
                  }
                />
              }
            >
              <div>
                <SimpleSelect
                  placeholder="Sección"
                  valueKey="idSeccion"
                  labelKey="nombreSeccion"
                  disabled={
                    isHistoric ||
                    alumnoPassValidationMessages?.length > 0 ||
                    alumnosValidationData.working
                  }
                  content={validChangeSection}
                  value={row?.newSeccion?.idSeccion}
                  handleChange={(e: React.FormEvent<EventTarget>) => {
                    handleChangeSection(e, row);
                  }}
                />
              </div>
            </Tooltip>
          );
        },
      },
      {
        id: 'enrolledDate',
        label: 'Fecha efectiva de matriculación',
        width: '1%',
        hideSortIcon: true,
        noSort: true,
        render: (_, row) =>
          moment(row.enrolledAt, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      },
      {
        id: 'report',
        label: 'Informes',
        width: '1%',
        hideSortIcon: true,
        noSort: true,
        render: (_: never, row: AlumnoMovimiento) => {
          const certificadoSeptimo = certificados.find(
            (C) => C.idAlumno === row.alumno.idAlumno,
          );
          return (
            <Box>
              {globalWorking ? (
                <Loading />
              ) : (
                <OptionsReportsModal
                  cicloLectivo={currentCicloLectivo}
                  reportTypes={tipoInformes}
                  reports={row?.alumno?.informesHistory}
                  userInfo={userInfo}
                  alumno={row?.alumno}
                  anio={row.cicloLectivo.anio}
                  seccion={row.seccion.anio}
                  certificadoSeptimo={certificadoSeptimo}
                />
              )}
            </Box>
          );
        },
      },
      {
        id: 'icon',
        label: '',
        width: '1%',
        hideSortIcon: true,
        noSort: true,
        render: (_: never, row: AlumnoMovimiento) => {
          const options = [
            {
              label: 'Desmatricular',
              onClick: () => {
                if (enabled) {
                  removeAlumn(
                    row?.alumno?.persona?.nombre,
                    Number(row?.idAlumnoMovimiento),
                    Number(row?.alumno?.idAlumno),
                    row,
                  );
                } else {
                  enqueueSnackbar(
                    'No es posible dar de baja a un estudiante luego de el 1/10/2021 con su rol actual.',
                    {
                      variant: 'error',
                    },
                  );
                }
              },

              hide:
                editStudentBlocked ||
                isHistoricSection({
                  currentCicloLectivo:
                    currentCicloLectivo?.anio ||
                    proyeccionEstado?.cicloLectivo?.anio,
                  cicloLectivo: row.cicloLectivo.anio,
                }),
              isDisabled: prepareQueryString,
            },
            {
              label: 'Ver legajo',
              onClick: () => {
                toLegajoAlumno(row?.alumno?.idAlumno);
              },
            },
            {
              label: 'Constancia de alumno regular',
              onClick: async () => {
                try {
                  await generateConstancia(row);
                  enqueueSnackbar('Constancia generada exitosamente', {
                    variant: 'success',
                  });
                } catch (error) {
                  enqueueSnackbar(
                    `Ocurrió un error con la generacion de constancia: ${
                      (data as ConstanciaError)?.error
                        ? (data as ConstanciaError).message
                        : ''
                    }`,
                    {
                      variant: 'error',
                    },
                  );
                }
              },
              isDisabled: row?.alumno?.condicion?.idCondicion !== 1, // Regular
            },
          ];
          return <OptionsModal loading={working} options={options} />;
        },
      },
    ],
    [
      classes.alumnoButton,
      classes.iconButton,
      classes.icon,
      toLegajoAlumno,
      alumnosWorking,
      canChangeCondition,
      alumnoDAO,
      enqueueSnackbar,
      setTableRows,
      proyecciones,
      seccion,
      currentCicloLectivo,
      proyeccionEstado?.cicloLectivo.anio,
      alumnosValidationData.alumnos,
      alumnosValidationData.working,
      nivel,
      working,
      handleChangeSection,
      certificados,
      globalWorking,
      tipoInformes,
      userInfo,
      editStudentBlocked,
      prepareQueryString,
      enabled,
      removeAlumn,
      generateConstancia,
      data,
    ],
  );
};
