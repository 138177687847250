import { JustificacionInitialValues } from '../types';

export const JustificacionInitialValue: JustificacionInitialValues = {
  razonJustificacion: '',
  fechaDesde: '',
  fechaHasta: '',
  turno: [],
  contraturno: [],
  motivoJustificacion: '',
  horaSalida: '',
  details: '',
  files: [],
};
