import { useEffect } from 'react';
import { useContenidoBloqueLibbyCall } from 'src/app/business';
import { ContenidoBloque } from 'src/app/models';
import { useArealInterarealContext } from '../../context';

export const useContenidoBloqueFetch = () => {
  const { espaciosCurriculares } = useArealInterarealContext();

  const {
    data: contenidosBloques = [],
    working,
    recall: recallContenidoBloque,
  } = useContenidoBloqueLibbyCall<ContenidoBloque[]>({
    methodName: 'filtroContenidoBloque',
    params: [espaciosCurriculares],
  });

  useEffect(() => {
    if (espaciosCurriculares) recallContenidoBloque(espaciosCurriculares);
  }, [espaciosCurriculares, recallContenidoBloque]);

  return { contenidosBloques, working };
};
