import { PropuestaJEDAO } from 'src/platform/libby/dao/Presentismov2';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { PropuestaJE } from '../../models/presentismo_v2';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'propuesta_je';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { renderPropCall, renderPropFetchById, simpleSelect },
} = makeBusinessEntity<PropuestaJE, PropuestaJEDAO>(DAO_NAME, {});

// hooks
export const usePropuestaJEFetchById = fetchById;
export const usePropuestaJELibbyCall = libbyCall;
export const usePropuestaJEDAO = libbyDAO;
export const usePropuestaJELibbyFetch = libbyFetch;

// components/hoc
export const PropuestaJECall = renderPropCall;
export const PropuestaJEById = renderPropFetchById;
export const PropuestaJESelect = simpleSelect;
