import React, { useMemo, useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import moment from 'moment';
import {
  useAnioLibbyFetch,
  useCicloLectivoLibbyFetch,
  useEspacioCurricularLibbyFetch,
  useEstadoPaseAnioLibbyFetch,
  useSeccionLibbyFetch,
  useTipoDeudaAcademicaLibbyFetch,
  useTipoPeriodoLibbyFetch,
  useTurnoLibbyFetch,
} from 'src/app/business';
import { level, MainInput, ROL, SimpleSelect } from 'src/commons';
import reportsList from './reportsConfig';
import { FILTERS } from './table_filters';
import { grey } from '@material-ui/core/colors';
import { useReportsContext } from './contexts/ReportContext';
import useDebounce from '../GestionProyeccion/hooks/useDebounce';

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  inputStyle: { padding: 10, marginTop: 23 },
};

const LOADING_TEXT = 'Cargando datos...';

const InternalSelects = ({
  reportCategory,
  working = false,
}: {
  reportCategory: string;
  working: boolean;
}) => {
  const {
    internalFilters,
    globalFilters,
    setInternalFilters,
    setGlobalFilters,
    currentReport,
    setFetchInmediatly,
    setFetchCount,
    setSearch,
    search,
  } = useReportsContext();

  const handleChange =
    (report: string, global = false) =>
    (e: React.ChangeEvent<{ value: unknown }>) => {
      setSearch('');
      const target = e.target;
      const name = target.name;
      const value = target.value;
      if (!global) {
        setInternalFilters((filters) => ({
          ...filters,
          [reportCategory]: {
            ...filters[reportCategory],
            [name]: value,
          },
        }));
      } else {
        setGlobalFilters((filters) => ({
          ...filters,
          [name]: value,
        }));
      }
    };

  useDebounce(
    () => {
      setInternalFilters((filters) => ({
        ...filters,
        [reportCategory]: {
          ...filters[reportCategory],
          [FILTERS.dni]: search,
        },
      }));
      setFetchCount(false);
      setFetchInmediatly(true);
    },
    [search, reportCategory, setFetchInmediatly],
    800,
  );

  const aniosFilter = useMemo(
    () => ({
      ...(!!globalFilters[FILTERS.nivel] && {
        nivel: [
          {
            path: 'nivel',
            value: JSON.parse(globalFilters[FILTERS.nivel])?.id,
          },
        ],
      }),
    }),
    [globalFilters],
  );

  const seccionesFilter = useMemo(
    () => ({
      historical: [
        {
          path: 'historical',
          value: true,
        },
      ],
      ...(internalFilters[reportCategory].hasOwnProperty(FILTERS.anio) &&
        Boolean(internalFilters[reportCategory][FILTERS.anio]) && {
          anio: [
            {
              path: 'anio',
              value: JSON.parse(internalFilters[reportCategory][FILTERS.anio])
                ?.id,
            },
          ],
        }),
      ...(internalFilters[reportCategory].hasOwnProperty(FILTERS.turno) &&
        Boolean(internalFilters[reportCategory][FILTERS.turno]) && {
          turno: [
            {
              path: 'turno',
              value: JSON.parse(internalFilters[reportCategory][FILTERS.turno])
                ?.id,
            },
          ],
        }),
      ...(!!globalFilters[FILTERS.localizacion] && {
        localizacion: [
          {
            path: 'localizacion',
            value: globalFilters[FILTERS.localizacion],
          },
        ],
      }),
      ciclo: [
        {
          path: 'cicloLectivo.anio',
          value: 2021,
        },
      ],
      ...(!!globalFilters[FILTERS.nivel] && {
        nivel: [
          {
            path: 'nivel',
            value: JSON.parse(globalFilters[FILTERS.nivel])?.id,
          },
        ],
      }),
    }),
    [globalFilters, internalFilters, reportCategory],
  );

  const turnosByNivelFilter = useMemo(
    () => ({
      [level.INICIAL]: [
        {
          path: 'idTurno',
          value: 1,
        },
        {
          path: 'idTurno',
          value: 2,
        },
        {
          path: 'idTurno',
          value: 5,
        },
      ],
      [level.PRIMARIO]: [
        {
          path: 'idTurno',
          value: 1,
        },
        {
          path: 'idTurno',
          value: 2,
        },
        {
          path: 'idTurno',
          value: 5,
        },
      ],
      [level.SECUNDARIO]: [
        {
          path: 'idTurno',
          value: 1,
        },
        {
          path: 'idTurno',
          value: 2,
        },
        {
          path: 'idTurno',
          value: 3,
        },
        {
          path: 'idTurno',
          value: 4,
        },
      ],
    }),
    [],
  );

  const turnosFilter = useMemo(
    () => ({
      ...(globalFilters[FILTERS.nivel] !== '' &&
        JSON.parse(globalFilters[FILTERS.nivel])?.id in turnosByNivelFilter && {
          standard:
            turnosByNivelFilter[JSON.parse(globalFilters[FILTERS.nivel])?.id],
        }),
    }),
    [globalFilters, turnosByNivelFilter],
  );

  const espaciosFilter = useMemo(
    () => ({
      localizacion: [
        {
          path: 'localizacion',
          value: globalFilters[FILTERS.localizacion],
        },
      ],
      ...(!!internalFilters[reportCategory][FILTERS.anio] && {
        anio: [
          {
            path: 'materia.anio',
            value: JSON.parse(internalFilters[reportCategory][FILTERS.anio])
              ?.id,
          },
        ],
      }),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [internalFilters[reportCategory][FILTERS.anio]],
  );

  const { data: anios = [], working: anioWorking } = useAnioLibbyFetch({
    limit: 10,
    filter: aniosFilter,
    enabled: !!globalFilters[FILTERS.nivel],
  });

  const { data: turnos = [], working: turnoWorking } = useTurnoLibbyFetch({
    limit: 10,
    filter: turnosFilter,
  });

  const { data: edosPaseAnio = [], working: paseAnioworking } =
    useEstadoPaseAnioLibbyFetch({
      limit: 30,
      enabled: reportsList[reportCategory][currentReport]?.filters.includes(
        FILTERS.edo_pase_anio,
      ),
    });

  const { data: secciones = [], working: seccionWorking } =
    useSeccionLibbyFetch({
      limit: 100,
      filter: seccionesFilter,
      enabled:
        Boolean(globalFilters[FILTERS.nivel]) &&
        Boolean(globalFilters[FILTERS.localizacion]),
      orderBy: 'nombreSeccion',
    });

  const { data: tiposPrevia = [], working: tipoPreviaWorking } =
    useTipoDeudaAcademicaLibbyFetch({
      limit: 20,
      enabled: !!globalFilters[FILTERS.localizacion],
    });

  const { data: periodos = [], working: periodosWorking } =
    useTipoPeriodoLibbyFetch({
      limit: 20,
      orderBy: 'idTipoPeriodo',
      enabled:
        !!globalFilters[FILTERS.localizacion] &&
        reportsList[reportCategory][currentReport]?.filters.includes(
          FILTERS.periodo,
        ),
    });

  const { data: materias = [], working: materiasWorking } =
    useEspacioCurricularLibbyFetch({
      limit: 500,
      filter: espaciosFilter,
      enabled: !!globalFilters[FILTERS.nivel],
      orderBy: 'materia.descripcion',
    });

  return (
    <>
      {reportsList[reportCategory][currentReport]?.filters.includes(
        FILTERS.anio,
      ) && (
        <Grid item xs={2}>
          <SimpleSelect
            disabled={anioWorking || working}
            title={anioWorking ? LOADING_TEXT : 'Año'}
            content={anios.map((anio) => ({
              idAnio: JSON.stringify({
                id: anio.idAnio,
                label: anio.descripcionAnio,
              }),
              descripcionAnio: anio.descripcionAnio,
            }))}
            valueKey="idAnio"
            labelKey="descripcionAnio"
            name={FILTERS.anio}
            placeholder="Seleccioná el Año"
            value={internalFilters[reportCategory][FILTERS.anio]}
            handleChange={handleChange(reportCategory)}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
      )}

      {reportsList[reportCategory][currentReport]?.filters.includes(
        FILTERS.turno,
      ) && (
        <Grid item xs={1}>
          <SimpleSelect
            disabled={turnoWorking || working}
            title={turnoWorking ? LOADING_TEXT : 'Turno'}
            content={turnos.map((turno) => ({
              idTurno: JSON.stringify({
                id: turno.idTurno,
                label: turno.descripcionTurno,
              }),
              descripcionTurno: turno.descripcionTurno,
            }))}
            valueKey="idTurno"
            labelKey="descripcionTurno"
            name={FILTERS.turno}
            placeholder="Seleccioná el turno"
            value={internalFilters[reportCategory][FILTERS.turno]}
            handleChange={handleChange(reportCategory)}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
      )}

      {reportsList[reportCategory][currentReport]?.filters.includes(
        FILTERS.edo_pase_anio,
      ) && (
        <Grid item xs={2}>
          <SimpleSelect
            disabled={paseAnioworking || working}
            title={paseAnioworking ? LOADING_TEXT : 'Estado pase año'}
            content={edosPaseAnio.map((edoPaseAnio) => ({
              idEstadoPaseAnio: JSON.stringify({
                id: edoPaseAnio.idEstadoPaseAnio,
                label: edoPaseAnio.descripcionEstadoPaseAnio,
              }),
              descripcionEstadoPaseAnio: edoPaseAnio.descripcionEstadoPaseAnio,
            }))}
            valueKey="idEstadoPaseAnio"
            labelKey="descripcionEstadoPaseAnio"
            name={FILTERS.edo_pase_anio}
            placeholder="Seleccioná el estado de pase de año"
            value={internalFilters[reportCategory][FILTERS.edo_pase_anio]}
            handleChange={handleChange(reportCategory)}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
      )}

      {reportsList[reportCategory][currentReport]?.filters.includes(
        FILTERS.tipo_previa,
      ) && (
        <Grid item xs={1}>
          <SimpleSelect
            disabled={tipoPreviaWorking || working}
            title={tipoPreviaWorking ? LOADING_TEXT : 'Tipo de Previa'}
            content={tiposPrevia.map((tipo) => ({
              idTipoDeudaAcademica: JSON.stringify({
                id: tipo.idTipoDeudaAcademica,
                label: tipo.descripcion,
              }),
              tipo: tipo.descripcion,
            }))}
            valueKey="idTipoDeudaAcademica"
            labelKey="tipo"
            name={FILTERS.tipo_previa}
            placeholder="Seleccioná el tipo de previa"
            value={internalFilters[reportCategory][FILTERS.tipo_previa]}
            handleChange={handleChange(reportCategory)}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
      )}

      {reportsList[reportCategory][currentReport]?.filters.includes(
        FILTERS.materia,
      ) && (
        <Grid item xs={1}>
          <SimpleSelect
            disabled={
              materiasWorking ||
              working ||
              !internalFilters[reportCategory][FILTERS.anio]
            }
            title={materiasWorking ? LOADING_TEXT : 'Materia'}
            content={materias.map((espacioCurricular) => ({
              idMateria: JSON.stringify({
                id: espacioCurricular.materia.idMateria,
                label: espacioCurricular.materia.descripcion,
              }),
              nombre: espacioCurricular.materia.descripcion,
            }))}
            valueKey="idMateria"
            labelKey="nombre"
            name={FILTERS.materia}
            placeholder="Seleccioná la materia"
            value={internalFilters[reportCategory][FILTERS.materia]}
            handleChange={handleChange(reportCategory)}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
      )}

      {reportsList[reportCategory][currentReport]?.filters.includes(
        FILTERS.periodo,
      ) && (
        <Grid item xs={1}>
          <SimpleSelect
            disabled={periodosWorking || working}
            title={periodosWorking ? LOADING_TEXT : 'Período'}
            content={periodos.map((periodo) => ({
              idTipoPeriodo: JSON.stringify({
                id: periodo.idTipoPeriodo,
                label: periodo.descripcionTipoPeriodo,
              }),
              nombre: periodo.descripcionTipoPeriodo,
            }))}
            valueKey="idTipoPeriodo"
            labelKey="nombre"
            name={FILTERS.periodo}
            placeholder="Seleccioná el período"
            value={internalFilters[reportCategory][FILTERS.periodo]}
            handleChange={handleChange(reportCategory)}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
      )}

      {reportsList[reportCategory][currentReport]?.filters.includes(
        FILTERS.seccion,
      ) && (
        <Grid item xs={1}>
          <SimpleSelect
            disabled={seccionWorking || working}
            title={seccionWorking ? LOADING_TEXT : 'Sección'}
            content={secciones.map((seccion) => ({
              idSeccion: JSON.stringify({
                id: seccion.idSeccion,
                label: seccion.nombreSeccion,
              }),
              nombre: seccion.nombreSeccion,
            }))}
            valueKey="idSeccion"
            labelKey="nombre"
            name={FILTERS.seccion}
            placeholder="Seleccioná la sección"
            value={internalFilters[reportCategory][FILTERS.seccion]}
            handleChange={handleChange(reportCategory)}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
      )}

      {reportsList[reportCategory][currentReport]?.filters.includes(
        FILTERS.dia_se_mes,
      ) && (
        <Grid item xs={2}>
          <SimpleSelect
            disabled={working}
            title="Período de tiempo"
            content={[
              { id: JSON.stringify({ id: 1, label: 'Día' }), label: 'Dia' },
              {
                id: JSON.stringify({ id: 2, label: 'Semana' }),
                label: 'Semana',
              },
              { id: JSON.stringify({ id: 3, label: 'Mes' }), label: 'Mes' },
            ]}
            valueKey="id"
            labelKey="label"
            name={FILTERS.dia_se_mes}
            placeholder="Seleccioná el período de tiempo"
            value={internalFilters[reportCategory][FILTERS.dia_se_mes]}
            handleChange={handleChange(reportCategory)}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
      )}

      {reportsList[reportCategory][currentReport]?.filters.includes(
        FILTERS.dni,
      ) && (
        <Grid item xs={4}>
          <MainInput
            disabled={working}
            type="search"
            name={FILTERS.dni}
            placeholder="Buscar DNI"
            value={search}
            handleChange={(e) => {
              if (/\D/i.test(e.target.value)) return;
              setSearch(e.target.value);
            }}
            customStyle={customStyles.inputStyle}
          />
        </Grid>
      )}
    </>
  );
};

export default InternalSelects;
