import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { Loading } from 'src/commons';
import { primary } from 'src/theme/colors';

const customStyles = makeStyles({
  iconColor: { color: primary.paleBlueStrong, cursor: 'pointer' },
  backgroundRow: { background: primary.paleBlue },
  loader: {
    margin: '200px 0px',
  },
  customContainer: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    backgroundColor: '#000000A0',
    height: '100vh',
    width: '100vw',
    zIndex: 10000,
  },
  loadingMessage: {
    position: 'absolute',
    marginTop: '6em',
    fontWeight: 800,
    fontFamily: 'Open Sans',
    color: 'white',
    userSelect: 'none',
  },
});

export const Loader = () => {
  const componentClasses = customStyles();

  return (
    <Grid
      className={`${componentClasses.customContainer}`}
      container
      justify="center"
      alignItems="center"
    >
      <Loading />
      <div className={`${componentClasses.loadingMessage}`}>
        <span>Esta operación puede demorar un poco, por favor espere.</span>
      </div>
    </Grid>
  );
};
