import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { grey } from 'src/theme/colors';
import {
  FormInput,
  useFormContext,
  FormGenericControl,
} from 'src/lib/templates';
import { TipoDocumentoSelect } from 'src/app/business';

const SPACING = 4;
const SPACE_BETWEEN = 6;

export const PersonalStep = () => {
  const { form } = useFormContext();

  return (
    <Grid
      container
      spacing={SPACING}
      direction="column"
      style={{ paddingRight: '5%', paddingLeft: '5%', flex: 1 }}
    >
      <Grid item>
        <Typography
          style={{
            fontFamily: 'Open Sans',
            color: grey.medium,
            fontSize: 20,
            marginBottom: 20,
          }}
        >
          Datos Personales
        </Typography>
        <Grid container spacing={SPACE_BETWEEN} direction="row">
          <Grid item sm={6}>
            <Grid container spacing={SPACING}>
              <Grid item sm={6}>
                <FormInput
                  label="Nombre"
                  name="nombre"
                  form={form}
                  placeholder="Ingresá nombre"
                  inputProps={{
                    style: { paddingTop: 6 },
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <FormInput
                  label="Apellido"
                  name="apellido"
                  form={form}
                  placeholder="Ingresá apellido"
                  inputProps={{
                    style: { paddingTop: 6 },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6}>
            <Grid container spacing={SPACING}>
              <Grid item sm={6}>
                <FormGenericControl
                  name="idTipoDocumento"
                  form={form}
                  component={TipoDocumentoSelect}
                  componentProps={{
                    title: 'Tipo de Documento',
                    placeholder: 'Seleccioná tipo de documento',
                    style: { backgroundColor: 'red' },
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <FormInput
                  label="Número de Documento"
                  name="documento"
                  form={form}
                  placeholder="Escribí el documento"
                  inputProps={{
                    style: { paddingTop: 6 },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6}>
            <Grid container spacing={SPACING}>
              <Grid item sm={6}>
                <FormInput
                  label="CUIL"
                  name="cuil"
                  form={form}
                  placeholder="Ingresá el CUIL"
                  inputProps={{
                    style: { paddingTop: 6 },
                  }}
                />
              </Grid>
              <Grid item sm={6}>
                <FormInput
                  label="Cuenta BUE"
                  name="cuentaBue"
                  disabled
                  form={form}
                  placeholder="Ingresá tu cuenta BUE"
                  inputProps={{
                    style: { paddingTop: 6 },
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
