import { Orientacion } from 'src/app/models/orientaciones';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { businessOptions } from '../businessOptions';
import { Orientacionesv2DAO } from '../../../platform/libby/dao';

const DAO_NAME = 'orientaciones';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idOrientaciones',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idOrientaciones',
      labelKey: 'descripcion',
    },
    withSimpleSelect: {
      labelKey: 'nombre',
      valueKey: 'idOrientacion',
      title: '',
      placeholder: 'Seleccioná orientación',
      name: 'orientación',
    },
  },
};

const optionsToUse = !businessOptions.Orientaciones
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Orientaciones,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Orientaciones.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
    selectInput,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Orientacion, Orientacionesv2DAO>(DAO_NAME, optionsToUse);

// hooks
export const useOrientacionesFetchById = fetchById;
export const useOrientacionesLibbyCall = libbyCall;
export const useOrientacionesDAO = libbyDAO;
export const useOrientacionesLibbyFetch = libbyFetch;

// components/hoc
export const OrientacionesAutocomplete = entityAutocomplete;
export const OrientacionesCall = renderPropCall;
export const OrientacionesById = renderPropFetchById;
export const OrientacionesSelect = simpleSelect;
export const OrientacionesSelectInput = selectInput;
export const OrientacionesAccordionSelector = accordionSelector;
export const OrientacionesSelectedChips = selectedChips;

// context
// ByIdContext
export const useOrientacionesByIdContext = useByIdContext;
export const OrientacionesByIdProvider = ByIdProvider;
export const OrientacionesByIdContext = ByIdContext;
// GeneralContext
export const useOrientacionesContext = useGeneralContext;
export const OrientacionesProvider = GeneralProvider;
export const OrientacionesContext = GeneralContext;
