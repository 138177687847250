import { ExceptuadosV3DAO } from '../../../platform/libby/dao/Presentismov3';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { businessOptions } from '../businessOptions';
import { ExceptuadosV3 } from '../../models/presentismo_v3';

const DAO_NAME = 'exceptuadosv3';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idExceptuadosV3',
      labelKey: 'descripcion',
    },
    withSimpleSelect: {
      labelKey: 'descripcion',
      valueKey: 'idExceptuados',
      title: 'Estado',
      placeholder: 'Seleccioná Estado',
      name: 'idExceptuados',
    },
  },
};

const optionsToUse = !businessOptions.ExceptuadosV3
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.ExceptuadosV3,
      hoc: { ...defaultOptions.hoc, ...businessOptions.ExceptuadosV3.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<ExceptuadosV3, ExceptuadosV3DAO>(DAO_NAME, optionsToUse);

// hooks
export const useExceptuadosV3FetchById = fetchById;
export const useExceptuadosV3LibbyCall = libbyCall;
export const useExceptuadosV3DAO = libbyDAO;
export const useExceptuadosV3LibbyFetch = libbyFetch;

// components/hoc
export const ExceptuadosV3Autocomplete = entityAutocomplete;
export const ExceptuadosV3Call = renderPropCall;
export const ExceptuadosV3ById = renderPropFetchById;
export const ExceptuadosV3Select = simpleSelect;
export const ExceptuadosV3AccordionSelector = accordionSelector;
export const ExceptuadosV3SelectedChips = selectedChips;

// context
// ByIdContext
export const useExceptuadosV3ByIdContext = useByIdContext;
export const ExceptuadosV3ByIdProvider = ByIdProvider;
export const ExceptuadosV3ByIdContext = ByIdContext;
// GeneralContext
export const useExceptuadosV3Context = useGeneralContext;
export const ExceptuadosV3Provider = GeneralProvider;
export const ExceptuadosV3Context = GeneralContext;
