import React, { useState, useCallback, useMemo } from 'react';
import { useForm, useField } from 'react-final-form-hooks';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Grid } from '@material-ui/core';
import {
  AnyObject,
  SeccionModalGroupGradeTypes,
  GroupGradeFormValues,
  useLibbyFetch,
  useLibbyCall,
} from 'src/commons';
import confirmDialog from 'src/commons/services/confirmDialog';
import { Footer, MainInput, SimpleSelect } from 'src/commons/components';
import customFormDialog from 'src/commons/services/customFormDialog';
import { grey } from 'src/theme/colors';
import { regexValidator } from 'src/utils/regex';
import { workingDays } from 'src/commons/const';

const customStyles = {
  customStyleTitle: { color: grey.medium, position: 'relative', top: '-12px' },
  containerGrid: { paddingRight: 20, paddingLeft: 20, paddingBottom: 17 },
  inputGrid: { paddingTop: 25 },
  footer: { marginTop: 20 },
};

const SeccionModalGroupGradeRaw = ({
  libby,
  reFetch,
  level,
  cycle,
  planStudy,
  turn,
  dividing = '',
  capacity = '',
  idSeccion,
  localizacionId,
  edit,
  nivel: rolNivel,
  workingDay,
}: SeccionModalGroupGradeTypes) => {
  const [disabled, setDisabled] = useState(false);

  const formValues = {
    level,
    cycle,
    planStudy,
    turn,
    dividing,
    capacity,
    workingDay,
  };
  // Clico para especiales son solo los 1 2 3
  const paramsFetchCiclo = useMemo(
    () => ({
      daoName: 'ciclo',
      orderBy: 'descripcionCiclo',
      filter: {
        0: [1, 2, 3].map((id) => ({ path: 'idCiclo', value: id })),
      },
    }),
    [],
  );

  const validate = (values: GroupGradeFormValues) => {
    const errors = {};
    if (
      !values.level ||
      !values.planStudy ||
      !values.turn ||
      !values.cycle ||
      !values.dividing ||
      !values.capacity ||
      !values.workingDay
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
    return errors;
  };

  const paramsFetchPlanEstudioNivel = useMemo(
    () => ({
      daoName: 'localizacion_plan_estudio_nivel',
      filter: {
        1: [{ path: 'localizacion', value: localizacionId }],
      },
    }),
    [localizacionId],
  );

  const { data: plan_estudio_nivel } = useLibbyFetch(
    libby,
    paramsFetchPlanEstudioNivel,
  );
  const { data: ciclo } = useLibbyFetch(libby, paramsFetchCiclo);
  const { data: nivel } = useLibbyCall(libby, {
    daoName: 'nivel',
    methodName: 'getLevelsBySchool',
    params: [1, 2, 3],
  });
  const { data: turno } = useLibbyFetch(libby, { daoName: 'turno' });

  const descriptionNameSection = (
    key: number | string,
    arrSelect: AnyObject,
  ) => {
    const filterObjSelect = arrSelect.find(
      (e: AnyObject) =>
        e.idNivel === key ||
        e.idTurno === key ||
        e.idCiclo === key ||
        e.label === key,
    );
    const { descripcionNivel, descripcionTurno, descripcionCiclo, label } =
      filterObjSelect || {};
    return (
      descripcionNivel || descripcionTurno || descripcionCiclo || label || ''
    );
  };

  const onSubmit = useCallback(
    async (params: GroupGradeFormValues) => {
      try {
        const iSeccionObj = {
          nombreSeccion: `${descriptionNameSection(
            params.level,
            nivel,
          )} ${descriptionNameSection(
            params.cycle,
            ciclo,
          )}  ${descriptionNameSection(
            params.turn,
            turno,
          )} ${descriptionNameSection(params.workingDay, workingDays)} ${
            params.dividing
          }`,
          capacidadRecomendada: 0,
          capacidadMaxima: Number(params.capacity),
          localizacion: localizacionId,
          nivel: params.level,
          planEstudioNivel: params.planStudy,
          turno: params.turn,
          ciclo: Number(params.cycle),
          division: params.dividing, // GRUPO
          jornada: params.workingDay,
        };
        const libbyObjectSeccion = edit
          ? { ...iSeccionObj, idSeccion }
          : iSeccionObj;
        await libby.seccion.save(libbyObjectSeccion);
        reFetch();
        customFormDialog.handleConfirm();
      } catch (e) {
        await confirmDialog.show({
          title: 'Error: Grupo/Grado repetido',
          content: `Se ha encontrado un Grupo/Grado similar en nuestra base de datos, por favor intente nuevamente con otros datos`,
          confirmText: 'Entendido',
        });
        customFormDialog.handleCancel();
      }
    },
    [libby, edit, idSeccion, nivel, turno, ciclo, reFetch, localizacionId],
  );

  const { form, handleSubmit, submitting, pristine } = useForm({
    initialValues: formValues,
    onSubmit,
    validate,
  });

  const levelForm = useField('level', form);
  const planStudyForm = useField('planStudy', form);
  const cycleForm = useField('cycle', form);
  const turnForm = useField('turn', form);
  const dividingForm = useField('dividing', form);
  const capacityForm = useField('capacity', form);
  const workingDayForm = useField('workingDay', form);

  const validateCapacity = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    const valueNumber = regexValidator.onlyNumber.test(target.value);
    if (valueNumber) {
      capacityForm.input.onChange(target.value);
    }
  };

  const buttonConfig = [
    {
      title: 'Cancelar',
      handleOnClick: () => customFormDialog.handleCancel(),
      type: 'secondary',
      size: 'small',
    },
    {
      title: edit ? 'Guardar' : 'Crear',
      handleOnClick: () => null,
      size: 'small',
      typeButton: 'submit',
      disabled: disabled || submitting || pristine,
    },
  ];

  const flatten = () =>
    plan_estudio_nivel.map((data: AnyObject) => ({
      ...data,
      ...data.planEstudioNivel,
      ...data.planEstudioNivel.planEstudio,
    }));

  type arrSelectsType = {
    input?: boolean;
    title?: string;
    label?: string;
    placeholder: string;
    labelKey?: string;
    valueKey?: string;
    content?: any[];
    name: string;
    handleChange: (event: any) => void;
    value: string;
    inputProps?: { min: number };
  };

  const arrSelects: arrSelectsType[] = [
    {
      title: 'Nivel',
      placeholder: 'Seleccioná Nivel',
      labelKey: 'descripcionNivel',
      valueKey: 'idNivel',
      content: nivel,
      name: levelForm.input.name,
      handleChange: levelForm.input.onChange,
      value: levelForm.input.value,
    },
    {
      title: 'Plan de Estudios',
      placeholder: 'Seleccioná Plan',
      labelKey: 'descripcionPlanEstudio',
      valueKey: 'idPlanEstudioNivel',
      content: flatten(),
      handleChange: planStudyForm.input.onChange,
      value: planStudyForm.input.value,
      name: planStudyForm.input.name,
    },
    {
      title: 'Ciclo',
      placeholder: 'Seleccioná Ciclo',
      labelKey: 'descripcionCiclo',
      valueKey: 'idCiclo',
      content: ciclo,
      handleChange: cycleForm.input.onChange,
      value: cycleForm.input.value,
      name: cycleForm.input.name,
    },
    {
      title: 'Turno',
      placeholder: 'Seleccioná Turno',
      labelKey: 'descripcionTurno',
      valueKey: 'idTurno',
      content: turno,
      name: turnForm.input.name,
      handleChange: turnForm.input.onChange,
      value: turnForm.input.value,
    },
    {
      title: 'Jornada',
      placeholder: 'Seleccioná Jornada',
      labelKey: 'label',
      valueKey: 'label',
      content: workingDays,
      name: workingDayForm.input.name,
      handleChange: workingDayForm.input.onChange,
      value: workingDayForm.input.value,
    },
    {
      input: true,
      label: 'Grupo',
      placeholder: 'Ingresá el nombre del Grupo',
      name: dividingForm.input.name,
      handleChange: dividingForm.input.onChange,
      value: dividingForm.input.value,
    },
    {
      input: true,
      label: 'Capacidad',
      inputProps: { min: 0 },
      placeholder: 'Definír Capacidad',
      name: capacityForm.input.name,
      handleChange: validateCapacity,
      value: capacityForm.input.value,
    },
  ];

  return (
    <Grid style={customStyles.containerGrid}>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          {arrSelects.map((e: arrSelectsType) =>
            e.input ? (
              <Grid item xs={6} style={customStyles.inputGrid}>
                <MainInput
                  {...e}
                  requiredField={!e.value}
                  handleChange={e.handleChange}
                  value={e.value}
                  fullWidth
                  customStyleLabel={customStyles.customStyleTitle}
                />
              </Grid>
            ) : (
              <Grid item xs={6}>
                <SimpleSelect
                  {...e}
                  handleChange={e.handleChange}
                  content={e.content}
                  value={e.value}
                  requiredField={!e.value}
                />
              </Grid>
            ),
          )}
        </Grid>
        <Footer
          buttonConfig={buttonConfig}
          spacing={1}
          customStyle={customStyles.footer}
        />
      </form>
    </Grid>
  );
};

export const SeccionModalGroupGrade = DatabaseConnector(
  SeccionModalGroupGradeRaw,
)(
  'nivel',
  'ciclo',
  'plan_estudio_nivel',
  'turno',
  'seccion',
  'localizacion_plan_estudio_nivel',
);
