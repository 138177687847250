import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useRolesContext } from 'src/context/RolesContext';
import {
  AnyObject,
  editCallbackGroupTypes,
  IconCircleTable,
  iconTablesCallbackTypes,
  LibbyObject,
  useLibbyFetch,
  useLibbyCall,
} from 'src/commons';
import { generatedArrSearch } from 'src/utils';
import { useDebounce } from 'src/commons/hooks/useDebounce';
import {
  HeaderTable,
  IconTables,
  InfoTable,
  useTabBarSetValue,
} from 'src/commons/components';
import customFormDialog from 'src/commons/services/customFormDialog';
import { grey, primary } from 'src/theme/colors';
import { workingDays } from 'src/commons/const';
import { useAlumnoMovimientoLibbyFetch } from 'src/app/business';
import { FilterTablesGroupGrade } from './components/FilterTablesGroupGrade';
import { SeccionModalGroupGrade } from './components/SeccionModalGroupGrade';
import confirmDialog from '../../../../../../commons/services/confirmDialog';

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  circleCapacity: { fontSize: '10px', color: primary.warning },
  iconColor: { color: primary.paleBlueStrong },
  iconDisabled: { color: primary.disabled },
};

const matriculationStyles = makeStyles(() => ({
  descriptionPlanStudy: {
    fontSize: '13px',
    textTransform: 'capitalize',
  },
  iconButton: {
    padding: 0,
  },
}));

const InitialGroupGradeRaw = ({ libby }: LibbyObject) => {
  const roleInfo = useRolesContext();
  const setTabBarValue = useTabBarSetValue();
  const { localizacionId } = roleInfo?.selectedRole;

  const filterDefault = [{ path: 'localizacion', value: localizacionId }];

  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [filterLibby, setFilterLibby] = useState<AnyObject>({
    0: filterDefault,
  });
  const [orderBy, setOrderBy] = useState('nivel');
  const [formValues, setFormValues] = useState({
    ciclo: '',
    planEstudioNivel: '',
    nivel: '',
    turno: '',
    jornada: '',
  });

  const [search, setSearch] = useState('');
  const searchDebounced = useDebounce(search, 1000);

  const pathSearch = useMemo(
    () => [
      'turno.descripcionTurno',
      'ciclo.descripcionCiclo',
      'nivel.descripcionNivel',
      'planEstudioNivel.planEstudio.descripcionPlanEstudio',
      'division',
      'jornada',
    ],
    [],
  );

  const classes = matriculationStyles();

  const paramsFetchSeccion = useMemo(() => {
    return {
      daoName: 'seccion',
      orderBy,
      direction,
      filter: filterLibby,
    };
  }, [filterLibby, direction, orderBy]);

  const paramsFetchPlanEstudioNivel = useMemo(
    () => ({
      daoName: 'localizacion_plan_estudio_nivel',
      filter: {
        1: [
          {
            path: 'localizacion',
            value: roleInfo?.selectedRole?.localizacionId,
          },
        ],
      },
    }),
    [roleInfo?.selectedRole],
  );

  const paramsFetchCiclo = useMemo(
    () => ({
      daoName: 'ciclo',
      orderBy: 'descripcionCiclo',
      filter: {
        0: [
          {
            path: 'planEstudioNivel.modalidadNivel.nivel',
            value: roleInfo?.selectedRole.nivel,
          },
        ],
      },
    }),
    [roleInfo?.selectedRole?.nivel],
  );

  const {
    data: seccion = [],
    working,
    fetchMore,
    reFetch,
  } = useLibbyFetch(libby, paramsFetchSeccion);

  const { data: plan_estudio_nivel } = useLibbyFetch(
    libby,
    paramsFetchPlanEstudioNivel,
  );
  const { data: alumno_movimiento = [] } = useAlumnoMovimientoLibbyFetch({
    limit: 100,
    aspect: 'shorted_relation',
  });
  const { data: ciclo } = useLibbyFetch(libby, paramsFetchCiclo);
  const { data: nivel } = useLibbyCall(libby, {
    daoName: 'nivel',
    methodName: 'getLevelsBySchool',
    params: [1, 2, 3],
  });
  const { data: turno } = useLibbyFetch(libby, { daoName: 'turno' });

  const flatten = () =>
    plan_estudio_nivel.map((data: AnyObject) => ({
      ...data,
      ...data.planEstudioNivel,
      ...data.planEstudioNivel.planEstudio,
    }));

  const filterTables = useCallback(
    (filterSearch: any) => {
      if (
        formValues.ciclo ||
        formValues.planEstudioNivel ||
        formValues.nivel ||
        formValues.turno ||
        formValues.jornada
      ) {
        const toReformFormValues = Object.entries(formValues)
          .filter((filter) => !!filter[1])
          .map(
            (filter: AnyObject) =>
              filter[1] && [{ path: filter[0], value: filter[1] }],
          );
        const toObjectLibby = {
          filterDefault,
          filterSearch,
          ...toReformFormValues,
        };
        setFilterLibby(toObjectLibby);
      } else {
        setFilterLibby({
          0: filterDefault,
          1: filterSearch,
        });
      }
    },
    // eslint-disable-next-line
    [formValues],
  );

  useEffect(() => {
    filterTables(generatedArrSearch(pathSearch, searchDebounced));
  }, [filterTables, searchDebounced, pathSearch]);

  const handleEdit = useCallback(
    async ({
      nivel: idNivel,
      turno: idTurno,
      division,
      capacidadMaxima,
      planEstudioNivel: idPlanEstudioNivel,
      ciclo: idCiclo,
      idSeccionRaw,
      jornada,
    }: editCallbackGroupTypes) => {
      await customFormDialog.show({
        title: 'Editar sección',
        renderComponent: (
          <SeccionModalGroupGrade
            idSeccion={idSeccionRaw}
            cycle={idCiclo}
            planStudy={idPlanEstudioNivel}
            level={idNivel}
            localizacionId={localizacionId}
            turn={idTurno}
            reFetch={reFetch}
            dividing={division}
            capacity={capacidadMaxima}
            nivel={roleInfo?.selectedRole?.nivel}
            workingDay={jornada}
            edit
          />
        ),
        sizeWidth: 'md',
      });
    },
    [localizacionId, reFetch, roleInfo?.selectedRole?.nivel],
  );

  const handleDelete = useCallback(
    async (idSeccion: string, nombreSeccion: string) => {
      const confirm = await confirmDialog.show({
        title: '¿Desea eliminar la sección?',
        content: `Una vez eliminada, la sección "${nombreSeccion}" no podrá recuperarse.`,
        confirmText: 'Confirmar',
        cancelText: 'Cancelar',
      });
      if (confirm) {
        await libby.seccion.remove({ idSeccion });
        reFetch();
      }
    },
    [libby, reFetch],
  );

  const iconTables = useCallback(
    ({
      nivel: idNivel,
      turno: idTurno,
      division,
      capacidadMaxima,
      planEstudioNivel: idPlanEstudioNivel,
      ciclo: idCiclo,
      idSeccionRaw,
      capacityStudentMovement,
      nombreSeccion,
      jornada,
    }: iconTablesCallbackTypes) => [
      {
        icon: <VisibilityIcon style={customStyles.iconColor} />,
        handleOnClick: () => setTabBarValue(4, { nombreSeccion }),
        disabled: false,
        styleIcon: {},
        class: classes.iconButton,
      },
      {
        icon: <EditIcon style={customStyles.iconColor} />,
        handleOnClick: () =>
          handleEdit({
            nivel: idNivel,
            turno: idTurno,
            division,
            capacidadMaxima,
            planEstudioNivel: idPlanEstudioNivel,
            ciclo: idCiclo,
            idSeccionRaw,
            jornada,
          }),
        disabled: false,
        styleIcon: {},
        class: classes.iconButton,
      },
      {
        icon: <DeleteIcon />,
        handleOnClick: () => handleDelete(idSeccionRaw, nombreSeccion),
        disabled: !!capacityStudentMovement.length,
        styleIcon: capacityStudentMovement.length
          ? customStyles.iconDisabled
          : customStyles.iconColor,
        class: classes.iconButton,
        tooltip:
          'No puedes eliminar ésta sección/ciclo mientras contenga estudiantes.',
      },
    ],
    [classes.iconButton, handleDelete, handleEdit, setTabBarValue],
  );

  const rows = useMemo(
    () =>
      seccion.map(
        ({
          idSeccion: idSeccionRaw,
          turno: { descripcionTurno, idTurno },
          nivel: { descripcionNivel, idNivel },
          ciclo: idCiclo,
          division,
          jornada,
          nombreSeccion,
          capacidadMaxima,
          planEstudioNivel: {
            planEstudio: { descripcionPlanEstudio },
            idPlanEstudioNivel,
          },
        }: AnyObject) => {
          const capacityStudentMovement = alumno_movimiento.filter(
            ({ seccion: { idSeccion } }: AnyObject) =>
              idSeccion === idSeccionRaw,
          );
          const arrIconTables = iconTables({
            nivel: idNivel,
            turno: idTurno,
            division,
            jornada,
            capacidadMaxima,
            planEstudioNivel: idPlanEstudioNivel,
            ciclo: idCiclo?.idCiclo,
            idSeccionRaw,
            capacityStudentMovement,
            nombreSeccion,
          });
          return {
            id_seccion: idSeccionRaw,
            cycle: idCiclo?.descripcionCiclo,
            level_id: descripcionNivel,
            turn: descripcionTurno,
            workingDay: jornada,
            group_grade: division,
            coupon: (
              <>
                <IconCircleTable
                  capacidadRecomendada={capacityStudentMovement.length}
                  capacidadMaxima={capacidadMaxima}
                />
                {`${capacityStudentMovement.length}/${capacidadMaxima}`}
              </>
            ),
            plan_study: (
              <>
                <Typography
                  component="span"
                  className={classes.descriptionPlanStudy}
                >
                  {descripcionPlanEstudio}
                </Typography>
              </>
            ),
            icon: <IconTables content={arrIconTables} />,
          };
        },
      ),
    [alumno_movimiento, classes.descriptionPlanStudy, iconTables, seccion],
  );

  const column = [
    {
      id: 'level_id',
      label: 'Nivel',
      width: '5%',
      orderById: 'nivel',
    },
    {
      id: 'cycle',
      label: 'Ciclo',
      width: '7%',
      orderById: 'ciclo',
    },
    {
      id: 'turn',
      label: 'Turno',
      width: '5%',
      orderById: 'turno',
    },
    {
      id: 'workingDay',
      label: 'Jornada',
      width: '5%',
      orderById: 'jornada',
    },
    {
      id: 'group_grade',
      label: 'Grupo/grado',
      width: '10%',
      orderById: 'division',
    },
    {
      id: 'coupon',
      label: 'Capacidad',
      width: '5%',
      orderById: 'capacidadMaxima',
    },
    {
      id: 'plan_study',
      label: 'Plan de estudio',
      width: '20%',
      orderById: 'planEstudioNivel',
    },
    {
      id: 'icon',
      label: '',
      width: '8%',
      hideSortIcon: true,
      noSort: true,
      style: { textAlign: 'right' },
    },
  ];

  const handleOnClick = () => {
    customFormDialog.show({
      title: 'Crear Grupo/Grado',
      renderComponent: (
        <SeccionModalGroupGrade
          reFetch={reFetch}
          localizacionId={localizacionId}
          nivel={roleInfo?.selectedRole?.nivel}
        />
      ),
      sizeWidth: 'md',
    });
  };

  const handleChange = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleChangeSearch = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setSearch(target.value);
  };

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setDirection(newDirection);
    setOrderBy(newOrderBy);
  };

  const arrFilterTables = [
    [
      {
        title: 'Nivel',
        placeholder: 'Seleccioná Nivel',
        labelKey: 'descripcionNivel',
        valueKey: 'idNivel',
        content: nivel,
        handleChange,
        value: formValues.nivel,
        name: 'nivel',
      },
      {
        title: 'Ciclo',
        placeholder: 'Seleccioná Ciclo',
        labelKey: 'descripcionCiclo',
        valueKey: 'idCiclo',
        content: ciclo,
        handleChange,
        value: formValues.ciclo,
        customStyleTitle: customStyles.customStyleTitleSelect,
        name: 'ciclo',
      },
      {
        name: 'turno',
        title: 'Turno',
        placeholder: 'Seleccioná Turno',
        labelKey: 'descripcionTurno',
        valueKey: 'idTurno',
        content: turno,
        handleChange,
        value: formValues.turno,
        customStyleTitle: customStyles.customStyleTitleSelect,
      },
      {
        name: 'jornada',
        title: 'Jornada',
        placeholder: 'Seleccioná Jornada',
        labelKey: 'label',
        valueKey: 'label',
        content: workingDays,
        handleChange,
        value: formValues.jornada,
        customStyleTitle: customStyles.customStyleTitleSelect,
      },
      {
        title: 'Plan de Estudios',
        placeholder: 'Seleccioná Plan',
        labelKey: 'descripcionPlanEstudio',
        valueKey: 'idPlanEstudioNivel',
        content: flatten(),
        handleChange,
        value: formValues.planEstudioNivel,
        name: 'planEstudioNivel',
        customStyleTitle: customStyles.customStyleTitleSelect,
      },
    ],
    [
      {
        input: true,
        handleChange: handleChangeSearch,
        name: 'search',
        placeholder: 'Buscar',
        value: search,
      },
    ],
  ];

  return (
    <>
      <HeaderTable
        title={roleInfo?.selectedRole?.localizacion}
        titleMainButton="Crear Grupo/Grado"
        handleOnClick={handleOnClick}
      >
        <FilterTablesGroupGrade content={arrFilterTables} />
      </HeaderTable>
      <InfoTable
        working={working}
        rows={rows}
        onBottomScroll={fetchMore}
        columns={column}
        rowIdKey="id_seccion"
        direction={direction}
        onSortChange={handleRequestSort}
      />
    </>
  );
};

export const InitialGroupGrade = DatabaseConnector(InitialGroupGradeRaw)(
  'nivel',
  'ciclo',
  'plan_estudio_nivel',
  'alumno_movimiento',
  'turno',
  'seccion',
  'localizacion_plan_estudio_nivel',
);
