import React from 'react';
import { primary } from 'src/theme/colors';
import styled from 'styled-components';

const { yellow, paleBlueStrong } = primary;

const Bar = styled.div`
  flex: 1;
  display: flex;
  height: 10px;
  width: 100%;
  background: linear-gradient(
    90deg,
    ${yellow} 0%,
    rgba(253, 211, 6, 0.3) 69.27%,
    rgba(0, 179, 227, 0.2) 76.04%,
    ${paleBlueStrong} 100%
  );
`;

export const ColorBar = () => {
  return <Bar />;
};
