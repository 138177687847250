import { JeMateriasDAO } from 'src/platform/libby/dao';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { JeMateria } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'je_materias';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idJeMaterias',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idJeMaterias',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.JeMaterias
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.JeMaterias,
      hoc: { ...defaultOptions.hoc, ...businessOptions.JeMaterias.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<JeMateria, JeMateriasDAO>(DAO_NAME, optionsToUse);

// hooks
export const useJeMateriasFetchById = fetchById;
export const useJeMateriasLibbyCall = libbyCall;
export const useJeMateriasDAO = libbyDAO;
export const useJeMateriasLibbyFetch = libbyFetch;

// components/hoc
export const JeMateriasAutocomplete = entityAutocomplete;
export const JeMateriasCall = renderPropCall;
export const JeMateriasById = renderPropFetchById;
export const JeMateriasSelect = simpleSelect;
export const JeMateriasAccordionSelector = accordionSelector;
export const JeMateriasSelectedChips = selectedChips;

// context
// ByIdContext
export const useJeMateriasByIdContext = useByIdContext;
export const JeMateriasByIdProvider = ByIdProvider;
export const JeMateriasByIdContext = ByIdContext;
// GeneralContext
export const useJeMateriasContext = useGeneralContext;
export const JeMateriasProvider = GeneralProvider;
export const JeMateriasContext = GeneralContext;
