import { LibbyFetchDAO } from './LibbyFetchDAO';

export class AlumnoDAO extends LibbyFetchDAO {
  constructor() {
    super('alumno', 'idAlumno');
  }

  getById(id: string) {
    return this.query().equals('idAlumno', id).run();
  }

  getByPersonaId(idPersona: string) {
    return this.query().equals('persona.idPersona', idPersona).run();
  }
}
