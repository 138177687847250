import { useSnackbar } from 'notistack';
import React, { useMemo } from 'react';
import confirmDialog from 'src/commons/services/confirmDialog';
import { Box, Button } from '@material-ui/core';
import { useGruposAulaVirtualDAO } from '../../../../../../app/business';
import {
  OptionsModal,
  OptionsModalOption,
} from '../../../../../../commons/components/OptionsModal';
import { EspacioCurricular, Grupos } from '../../../../../../app/models';
import { BLOCKER_ID } from '../../../../../../platform/permission/const';
import { loadingModal } from '../../../../../../commons/services/loadingModal';
import { InfoTableColumn } from '../../../../../../commons/types';

export const useAulasVirtualesGrupoTable = (
  reFetch: () => void,
  espacioCurricular?: EspacioCurricular,
) => {
  const { enqueueSnackbar } = useSnackbar();

  const gruposAulaVirtualDAO = useGruposAulaVirtualDAO();

  const actionButton = useMemo<OptionsModalOption<Grupos>[]>(
    () => [
      {
        id: BLOCKER_ID.ADD_CLASSROOMS,
        label: 'Crear Aula',
        hide: (data: Grupos) =>
          !!data.gruposEspacios.find(
            (item) => !!item.gruposAulaVirtuals.length,
          ),
        onClick: async (data) => {
          try {
            loadingModal.open();
            const gruposEspacios = data.gruposEspacios.find(
              (item) =>
                item.espacioCurricular.idEspacioCurricular ===
                espacioCurricular?.idEspacioCurricular,
            );
            if (gruposEspacios) {
              // TODO: completar
              await gruposAulaVirtualDAO.aspect('limit_espacios').save({
                gruposEspacios: gruposEspacios.idGrupoEspacios,
              });
              reFetch();
              enqueueSnackbar('Se ha creado el aula virtual', {
                variant: 'success',
              });
            }
          } catch (e) {
            console.log(e);
            enqueueSnackbar('Error al crear el aula virtual', {
              variant: 'error',
            });
          } finally {
            loadingModal.close();
          }
        },
      },
      {
        label: 'Actualizar Participantes',
        hide: (data: Grupos) =>
          !data.gruposEspacios.find((item) => !!item.gruposAulaVirtuals.length),
        onClick: async (data) => {
          try {
            loadingModal.open();
            const espacio = data.gruposEspacios.find(
              (item) =>
                item.espacioCurricular.idEspacioCurricular ===
                  espacioCurricular?.idEspacioCurricular &&
                !!item.gruposAulaVirtuals.length,
            );
            if (espacio) {
              await gruposAulaVirtualDAO
                .aspect('limit_espacios')
                .save({ ...espacio.gruposAulaVirtuals[0] });
              reFetch();
              enqueueSnackbar('Se han actualizado los participantes', {
                variant: 'success',
              });
            }
          } catch (e) {
            console.log(e);
            enqueueSnackbar('Error al actualizar los participantes', {
              variant: 'error',
            });
          } finally {
            loadingModal.close();
          }
        },
      },
      {
        label: 'Eliminar Aula',
        hide: true, // TODO: habilitar esto cuando el backend funcione
        // hide: !data.gruposEspacios.find(item => !!item.gruposAulaVirtuals.length),
        onClick: async (data) => {
          try {
            const confirm = await confirmDialog.show({
              title: 'Confirmación',
              content: `Estas seguro de eliminar esta aula? Este acción no se puede deshacer`,
              confirmText: 'Eliminar',
              cancelText: 'No',
            });
            if (confirm) {
              loadingModal.open();
              const espacio = data.gruposEspacios.find(
                (item) =>
                  item.espacioCurricular.idEspacioCurricular ===
                    espacioCurricular?.idEspacioCurricular &&
                  !!item.gruposAulaVirtuals.length,
              );
              if (espacio) {
                await gruposAulaVirtualDAO
                  .aspect('limit_espacios')
                  .remove(espacio.gruposAulaVirtuals[0]);
                enqueueSnackbar('Se ha eliminado el aula correctamente', {
                  variant: 'success',
                });
                reFetch();
              }
            }
          } catch (error) {
            enqueueSnackbar('Error al eliminar el aula', { variant: 'error' });
          } finally {
            loadingModal.close();
          }
        },
      },
    ],
    [
      espacioCurricular?.idEspacioCurricular,
      gruposAulaVirtualDAO,
      reFetch,
      enqueueSnackbar,
    ],
  );

  return useMemo<InfoTableColumn<Grupos>[]>(
    () => [
      {
        id: 'nombre',
        label: 'Nombre',
        width: '10%',
        orderById: 'nombre',
        render: (value) => <Box whiteSpace="nowrap">{`${value}`}</Box>,
      },
      {
        id: 'espacioCurricularSeccion',
        label: 'Aula virtual',
        width: '10%',
        hideSortIcon: true,
        noSort: true,
        render: (value, row) => {
          const espacio = row.gruposEspacios.find(
            (item) =>
              item.espacioCurricular.idEspacioCurricular ===
                espacioCurricular?.idEspacioCurricular &&
              !!item.gruposAulaVirtuals.length,
          );
          if (!espacio || !espacio.gruposAulaVirtuals.length) {
            return 'Aula no Creada';
          }
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                window.open(
                  espacio.gruposAulaVirtuals[0].classroomMetadata.alternateLink,
                  '_blank',
                )
              }
            >
              Ir al aula
            </Button>
          );
        },
      },
      {
        id: 'icono',
        label: '',
        width: '5%',
        hideSortIcon: true,
        noSort: true,
        style: { textAlign: 'right' },
        render: (value: any, row: Grupos) => (
          <Box whiteSpace="nowrap" textAlign="right">
            <OptionsModal options={actionButton} data={row} />
          </Box>
        ),
      },
    ],
    [actionButton, espacioCurricular],
  );
};
