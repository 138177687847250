import React, { FunctionComponent, useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import { Grid, Menu, MenuItem } from '@material-ui/core';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CheckIcon from '@material-ui/icons/Check';
import { AnyObject } from 'src/commons/types';
import { makeStyles } from '@material-ui/styles';
import { primary } from 'src/theme/colors';
import { PermissionBlocker } from 'src/lib/permission';

const useStyles = makeStyles({
  menuText: {
    fontFamily: 'Open Sans',
    '&:hover': {
      color: primary.lightBlue,
    },
  },
});

export type HideOptionFunction = (data: any) => boolean;

export interface OptionsModalOption<T = any> {
  label: string;
  onClick: (data: T) => void;
  hide?: boolean | HideOptionFunction;
  id?: number;
  isVisited?: boolean;
  isDisabled?: boolean;
}

export type OptionsModalProps<T = any> = {
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  classIcon?: any;
  options: Array<OptionsModalOption<T>>;
  customStyleIcon?: any;
  icon?: any;
  isDisabledIcon?: boolean;
  setIsOpen?: (isOpen: boolean) => void;
};

export const OptionsModal: FunctionComponent<OptionsModalProps> = ({
  color = 'primary',
  classIcon,
  options,
  isDisabledIcon = false,
  customStyleIcon,
  icon: Icon = MoreVertIcon,
  setIsOpen,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handleMenu = (event: AnyObject) => {
    setAnchorEl(event.currentTarget);
    if (setIsOpen !== undefined) {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
    if (setIsOpen !== undefined) {
      setIsOpen(false);
    }
  };

  return (
    <>
      <IconButton
        className={classIcon}
        aria-label="change language"
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleMenu}
        color={color}
        disabled={isDisabledIcon}
      >
        <Icon style={{ ...customStyleIcon }} />
      </IconButton>
      <Menu
        id="menu-appbar-language"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={!!anchorEl}
        onClose={handleClose}
      >
        {options
          .filter((item) => {
            if (item.hide === undefined) {
              return true;
            }
            return typeof item.hide === 'boolean'
              ? !item.hide
              : !item.hide(data);
          })
          .map((item: OptionsModalOption, index: number) => (
            <PermissionBlocker id={item.id || 'unset'}>
              <MenuItem
                onClick={() => item.onClick(item)}
                disabled={item.isDisabled}
                className={classes.menuText}
                key={index}
              >
                <Grid item style={{ paddingRight: 10 }}>
                  {item.label}
                </Grid>
                {item.isVisited && (
                  <Grid item>
                    <CheckIcon
                      style={{ color: primary.success, fontSize: 15 }}
                    />
                  </Grid>
                )}
              </MenuItem>
            </PermissionBlocker>
          ))}
      </Menu>
    </>
  );
};
