import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { ContenidoBloqueSubnivel } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'contenido_bloque_subnivel';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idContenidoBloqueSubnivel',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idContenidoBloqueSubnivel',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.ContenidoBloqueSubnivel
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.ContenidoBloqueSubnivel,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.ContenidoBloqueSubnivel.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<ContenidoBloqueSubnivel>(DAO_NAME, optionsToUse);

// hooks
export const useContenidoBloqueSubnivelFetchById = fetchById;
export const useContenidoBloqueSubnivelLibbyCall = libbyCall;
export const useContenidoBloqueSubnivelDAO = libbyDAO;
export const useContenidoBloqueSubnivelLibbyFetch = libbyFetch;

// components/hoc
export const ContenidoBloqueSubnivelAutocomplete = entityAutocomplete;
export const ContenidoBloqueSubnivelCall = renderPropCall;
export const ContenidoBloqueSubnivelById = renderPropFetchById;
export const ContenidoBloqueSubnivelSelect = simpleSelect;
export const ContenidoBloqueSubnivelAccordionSelector = accordionSelector;
export const ContenidoBloqueSubnivelSelectedChips = selectedChips;

// context
// ByIdContext
export const useContenidoBloqueSubnivelByIdContext = useByIdContext;
export const ContenidoBloqueSubnivelByIdProvider = ByIdProvider;
export const ContenidoBloqueSubnivelByIdContext = ByIdContext;
// GeneralContext
export const useContenidoBloqueSubnivelContext = useGeneralContext;
export const ContenidoBloqueSubnivelProvider = GeneralProvider;
export const ContenidoBloqueSubnivelContext = GeneralContext;
