import { LibbyFetchDAO } from './LibbyFetchDAO';

export class GruposDAO extends LibbyFetchDAO {
  constructor() {
    super('grupos', 'idGrupo');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByDocente(filter: any) {
    return this.fetch({ filter, limit: 100000 });
  }
}
