import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { SimpDiscipPlanArea } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'simp_discip_plan_area';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idSimpDiscipPlanArea',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idSimpDiscipPlanArea',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.SimpDiscipPlanArea
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.SimpDiscipPlanArea,
      hoc: { ...defaultOptions.hoc, ...businessOptions.SimpDiscipPlanArea.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<SimpDiscipPlanArea>(DAO_NAME, optionsToUse);

// hooks
export const useSimpDiscipPlanAreaFetchById = fetchById;
export const useSimpDiscipPlanAreaLibbyCall = libbyCall;
export const useSimpDiscipPlanAreaDAO = libbyDAO;
export const useSimpDiscipPlanAreaLibbyFetch = libbyFetch;

// components/hoc
export const SimpDiscipPlanAreaAutocomplete = entityAutocomplete;
export const SimpDiscipPlanAreaCall = renderPropCall;
export const SimpDiscipPlanAreaById = renderPropFetchById;
export const SimpDiscipPlanAreaSelect = simpleSelect;
export const SimpDiscipPlanAreaAccordionSelector = accordionSelector;
export const SimpDiscipPlanAreaSelectedChips = selectedChips;

// context
// ByIdContext
export const useSimpDiscipPlanAreaByIdContext = useByIdContext;
export const SimpDiscipPlanAreaByIdProvider = ByIdProvider;
export const SimpDiscipPlanAreaByIdContext = ByIdContext;
// GeneralContext
export const useSimpDiscipPlanAreaContext = useGeneralContext;
export const SimpDiscipPlanAreaProvider = GeneralProvider;
export const SimpDiscipPlanAreaContext = GeneralContext;
