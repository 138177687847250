import { LibbyFetchDAO } from './LibbyFetchDAO';

export class PaisDAO extends LibbyFetchDAO {
  constructor() {
    super('pais', 'idPais');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
