import { MenuItem } from '../../../../commons/const/ui/menu';
import { routes } from './routes';

export const boletinesMenu: MenuItem = {
  key: 'boletines',
  basePath: '/boletines',
  enabled: true,
  icon: 'List',
  text: 'Boletines',
  children: routes,
};
