import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import confirmDialog from 'src/commons/services/confirmDialog';
import { Footer } from 'src/commons/components';
import { usePlanificationForm } from '../../hooks';
import { useRouterContext } from 'src/lib/router';
import { useBackButton } from 'src/commons';
import { Area, Orientacion } from 'src/app/models';
import { compareObjects } from 'src/utils/compareObjects';

interface PlanificationFooterProps {
  enviarCallBack: (values: any) => void;
  isDisabled?: Boolean;
  handleValuesChange?: (isDisabled: { isDisabled: Boolean }) => void;
  areas?: Area[];
  orientaciones?: Orientacion[];
}

const useStyles = makeStyles({
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export const PlanificationFooter = ({
  enviarCallBack,
  isDisabled,
  handleValuesChange,
  areas,
  orientaciones,
}: PlanificationFooterProps) => {
  const classes = useStyles();
  const { metadata } = useRouterContext();
  const history = useHistory();
  const idx = history.location.pathname.indexOf('/planificacion');
  useBackButton(history.location.pathname.slice(0, idx));

  const {
    dirty: isDirty,
    values,
    handleSubmit,
    errors,
    submitting,
  } = usePlanificationForm();

  const [initialValues, setInitialValues] = useState(values);
  const [initialAreas, setInitialAreas] = useState(areas);
  const [initialOrientaciones, setInitialOtientaciones] =
    useState(orientaciones);

  const handleLeave = useCallback(async () => {
    if (isDirty) {
      const confirm = await confirmDialog.show({
        title: '¿Desea continuar?',
        content:
          'La información precargada se perderá en caso que no guarde, por favor seleccione una opción.',
        confirmText: 'Guardar',
        cancelText: 'Salir',
        backText: 'Cancelar',
      });
      if (confirm) {
        await handleSubmit();
      }
    }
    history.push(metadata.toPath);
  }, [metadata, isDirty, history, handleSubmit]);

  const buttonConfig = useMemo(
    () => [
      {
        title: 'Guardar',
        handleOnClick: () => {
          handleSubmit();
          setInitialAreas(areas);
          setInitialValues(values);
          setInitialOtientaciones(orientaciones);
        },
        typeButton: 'submit',
        disabled: Object.keys(errors).length !== 0 || !isDisabled,
      },
      {
        title: 'Salir',
        handleOnClick: () => handleLeave(),
        isLink: !isDisabled,
      },
      {
        title: 'Enviar',
        handleOnClick: () => enviarCallBack(values),
        typeButton: 'submit',
        disabled: Object.keys(errors).length !== 0 || isDisabled,
      },
    ],
    [
      errors,
      isDisabled,
      handleSubmit,
      areas,
      values,
      orientaciones,
      handleLeave,
      enviarCallBack,
    ],
  );

  useEffect(() => {
    // enable enviar btn when values are equal the initialValues
    // disabled enviar btn when values change
    handleValuesChange &&
      handleValuesChange({
        isDisabled: !(
          compareObjects(values, initialValues) &&
          compareObjects(
            areas ? areas : {},
            initialAreas ? initialAreas : {},
          ) &&
          compareObjects(
            orientaciones ? orientaciones : {},
            initialOrientaciones ? initialOrientaciones : {},
          )
        ),
      });
  }, [
    handleValuesChange,
    values,
    initialValues,
    areas,
    initialAreas,
    initialOrientaciones,
    orientaciones,
  ]);

  return (
    <>
      <Grid className={classes.footer}>
        <Footer
          path={metadata.toPath}
          buttonConfig={buttonConfig}
          loading={submitting}
        />
      </Grid>
    </>
  );
};
