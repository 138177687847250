import { CommonEndpointDAO } from './CommonEndpointDAO';

export class UsuariosRolesDAO extends CommonEndpointDAO {
  constructor() {
    super('usuariosroles');
  }

  addUser(
    name: string,
    lastName: string,
    documento: string,
    cuil: string,
    email: string,
  ) {
    return this.post({
      name,
      lastName,
      documento,
      cuil,
      email,
      type: 'addUser',
    });
  }

  addRol(user: any, insertType: string, idUsuario: string) {
    return this.post({ insertType, user, idUsuario, type: 'addRol' });
  }
}
