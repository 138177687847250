import React, { ReactElement } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { MainButton, NIVEL } from 'src/commons';
import { primary, grey } from 'src/theme/colors';

interface HeaderDisciplinaTypesProps {
  openModal: () => void;
  working: boolean | any;
  children: ReactElement;
  isHistoric: boolean;
  alumnoMovimientoNivelId?: number;
}
const useStyles = makeStyles({
  grayContainer: {
    background: primary.whiteLight,
    margin: '0% 15px 85px 15px',
    borderRadius: '5px',
  },
  headerGrayContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
  },
  text: {
    color: grey.textPrimary,
    fontSize: '20px',
  },
});

export const HeaderDisciplina = ({
  openModal,
  working,
  children,
  isHistoric,
  alumnoMovimientoNivelId,
}: HeaderDisciplinaTypesProps) => {
  const classes = useStyles();
  return (
    <Grid className={classes.grayContainer} item>
      <Grid item>
        {alumnoMovimientoNivelId === NIVEL.SECUNDARIO && (
          <Grid item xs={12} className={classes.headerGrayContainer}>
            <Typography className={classes.text}>
              Disciplinas Pendientes
            </Typography>
            {!isHistoric && (
              <MainButton
                title="Agregar Disciplina"
                handleOnClick={openModal}
                disabled={working}
              />
            )}
          </Grid>
        )}
      </Grid>
      {children}
    </Grid>
  );
};
