import React from 'react';
import moment from 'moment';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { OptionsModal } from 'src/commons/components';
import confirmDialog from 'src/commons/services/confirmDialog';
import customFormDialog from 'src/commons/services/customFormDialog';
import { useSnackbar } from 'notistack';
import { useComentarioDAO } from 'src/app/business';
import { useRolesContext } from 'src/context/RolesContext';
import { UserAvatar } from 'src/commons';
import { ComentarioFormModal } from '../ComentarioFormModal';

const useStyles = makeStyles((theme) => ({
  name: {
    fontWeight: 'bold',
  },
  comentarios_publicacion: {
    marginBottom: 10,
    background: '#f5f5f5',
  },
  container_comentarios: {
    marginBottom: 10,
  },
  comentario_name: {
    marginLeft: theme.spacing(1),
  },
}));

export interface ComentarioProps {
  userName: string;
  comment: {
    idComentario: number;
    cuentas: {
      idUsuario: string;
      nombreUsuario: string;
      apellidoUsuario: string;
    };
    descripcion: string;
    fechaCreacion?: string;
  };
  reFetchPublicacion?: () => void;
  idPublicacion: number;
}

export const Comentario = ({
  userName,
  comment,
  reFetchPublicacion,
  idPublicacion,
}: ComentarioProps) => {
  const classes = useStyles();
  const { userInfo } = useRolesContext();
  const { enqueueSnackbar } = useSnackbar();
  const comentarioDAO = useComentarioDAO();

  const handleDeleteComentario = async () => {
    const confirm = await confirmDialog.show({
      title: 'Confirmación de Eliminación',
      confirmText: 'Eliminar',
      cancelText: 'Cancelar',
      content: '¿Desea eliminar el comentario?',
    });
    if (confirm) {
      try {
        await comentarioDAO.remove({ idComentario: comment.idComentario });
        enqueueSnackbar('Se ha eliminado con exito', { variant: 'success' });
        reFetchPublicacion && reFetchPublicacion();
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Error al eliminar', { variant: 'error' });
      }
    }
  };

  const handleEditComentario = () => {
    customFormDialog.show({
      title: '',
      renderComponent: (
        <ComentarioFormModal
          idPublicacion={idPublicacion}
          idComentario={comment.idComentario}
          reFetch={reFetchPublicacion}
        />
      ),
      sizeWidth: 'md',
    });
  };

  return (
    <Grid
      container
      item
      direction="row"
      spacing={2}
      className={classes.container_comentarios}
    >
      <Grid item>
        <UserAvatar name={userName} avatarWidth={28} avatarHeight={28} />
      </Grid>
      <Grid item xs className={classes.comentarios_publicacion}>
        <Grid item xs>
          <Grid container item direction="row" spacing={2}>
            <Grid item xs className={classes.comentario_name}>
              <Typography
                className={classes.name}
              >{`${comment.cuentas.nombreUsuario} ${comment.cuentas.apellidoUsuario}`}</Typography>
            </Grid>
            <Grid item>
              <Typography>
                {moment(comment.fechaCreacion).format('DD / MM / YYYY')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Typography className={classes.comentario_name}>
          {comment.descripcion}
        </Typography>
      </Grid>
      {comment.cuentas.idUsuario === userInfo.id && (
        <Grid item>
          <OptionsModal
            options={[
              {
                label: 'Editar',
                onClick: handleEditComentario,
              },
              {
                label: 'Borrar',
                onClick: handleDeleteComentario,
              },
            ]}
          />
        </Grid>
      )}
    </Grid>
  );
};
