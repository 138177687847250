import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class EstadoPresenteV3DAO extends LibbyFetchDAO {
  constructor() {
    super('estado_presente', 'idEstadoPresente');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
