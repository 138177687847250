import { useEffect } from 'react';
import { RAZON_JUSTIFICACION } from '../../../../../commons';
import { useTemplateContext } from '../../../../../lib/templates';
import { useJustificacionFormContext } from './useJustificacionFormContext';

const validatorsFiles = [
  {
    name: 'empty',
    signature: [],
    errorMessage: 'Este campo es requerido',
  },
  {
    name: 'min',
    signature: [1],
    errorMessage: 'Este campo es requerido',
  },
];

export const useRazonChange = () => {
  const { form, values } = useJustificacionFormContext();
  const { setTemplate } = useTemplateContext();

  useEffect(() => {
    if (values.razonJustificacion === '') return;
    if (values.razonJustificacion === RAZON_JUSTIFICACION.AUSENTE.toString()) {
      setTemplate((template) => {
        const oldTemplate = template.template.filter(
          (temp) => temp.name !== 'files',
        );
        const filesTemplate = template.template.find(
          (temp) => temp.name === 'files',
        );
        if (!filesTemplate) return template;
        return {
          ...template,
          template: [
            ...oldTemplate,
            { ...filesTemplate, optional: false, validators: validatorsFiles },
          ],
        };
      });
    } else {
      setTemplate((template) => {
        const oldTemplate = template.template.filter(
          (temp) => temp.name !== 'files',
        );
        const filesTemplate = template.template.find(
          (temp) => temp.name === 'files',
        );
        if (!filesTemplate) return template;
        return {
          ...template,
          template: [
            ...oldTemplate,
            { ...filesTemplate, optional: true, validators: [] },
          ],
        };
      });
    }
  }, [form, values, setTemplate]);
};
