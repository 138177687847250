import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { EspacioCurricularSeccion } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'espacio_curricular_docente';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEspacioCurricularSeccion',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEspacioCurricularSeccion',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EspacioCurricular
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EspacioCurricular,
      hoc: { ...defaultOptions.hoc, ...businessOptions.EspacioCurricular.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EspacioCurricularSeccion>(DAO_NAME, optionsToUse);

// hooks
export const useEspacioCurricularDocenteFetchById = fetchById;
export const useEspacioCurricularDocenteLibbyCall = libbyCall;
export const useEspacioCurricularDocenteDAO = libbyDAO;
export const useEspacioCurricularDocenteLibbyFetch = libbyFetch;
