import React from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { grey } from 'src/theme/colors';

const useStyle = makeStyles(() => ({
  headerTitle: {
    '&& .MuiCardHeader-title': {
      color: grey.textPrimary,
      fontSize: 18,
    },
  },
}));
export interface FeedbackSeccionCardProps {
  descripcion: string;
  posicion: string;
  children: JSX.Element | JSX.Element[];
}

export const FeedbackSeccionCard = ({
  descripcion,
  posicion,
  children,
}: FeedbackSeccionCardProps) => {
  const classes = useStyle();
  return (
    <Card>
      <CardHeader
        className={classes.headerTitle}
        title={descripcion}
        action={<Typography>{posicion}</Typography>}
      />
      <CardContent>{children}</CardContent>
    </Card>
  );
};
