import { createContext, useContext } from 'react';
import {
  Escalafon,
  ModalidadType,
  Region,
  UsuarioRolEstablecimiento,
} from 'src/app/models';

// TODO: por favor arreglen el doble localizacion y el doble tipo de la modalidad
export type SelectedRole = {
  idUsuarioRol: string | number;
  rol: string;
  localizacion: string;
  localizacionId: string;
  nivel: string;
  modalidad: ModalidadType;
  tipoEstablecimiento?: string;
  isFuture?: boolean;
  region?: Region;
  escalafon?: Escalafon;
};

export type UserRoles = {
  rolDescription: string;
  schoolName: string;
  img: string;
};

export type UserInfo = {
  id: string;
  name: string;
  email: string;
  apellido: string;
};

export type RolesContextValue = {
  selectedRole: SelectedRole;
  setSelectedRole: (selectedRole: SelectedRole) => void;
  deselectRole: () => void;
  setUserRoles: (userRoles: UserRoles[]) => void;
  userRoles: UserRoles[];
  userInfo: UserInfo;
  setUserInfo: (userInfo: UserInfo) => void;
};

export const RolesContext = createContext<RolesContextValue>({
  selectedRole: {
    idUsuarioRol: '',
    localizacion: '',
    localizacionId: '',
    modalidad: 0,
    nivel: '',
    rol: '',
    tipoEstablecimiento: '',
    isFuture: false,
    region: undefined,
  },
  setSelectedRole: () => {},
  deselectRole: () => {},
  userRoles: [
    {
      rolDescription: '',
      schoolName: '',
      img: '',
    },
  ],
  setUserRoles: () => {},
  userInfo: {
    id: '',
    name: '',
    email: '',
    apellido: '',
  },
  setUserInfo: () => {},
});

export const useRolesContext = () => useContext(RolesContext);

/**
 * Verificar si una lista de roles
 * tiene un id rol especificado
 * @param role id del rol que se quiere verificar
 * @param roles Array de todos los roles que tiene el usuario loggeado
 * @returns boolean
 */
export const verifyRole = (
  role: number,
  roles: Array<UsuarioRolEstablecimiento>,
) => roles.some((rol) => rol.rolUsuario.idRolUsuario === role);
