import moment from 'moment';
import {
  AlumnoMovimiento,
  ContraturnoDia,
  PeriodoNuevo,
  SituacionSeccion,
  SituacionV3,
  TIPO_SITUACIONES,
} from '../../../../../../../app/models';
import { FechaBloqueada } from '../../../../../../../app/models/presentismo_v2';
import { ASISTENCIA_GENERAL, TURNOS } from '../components';
import { getContraturnosByDate } from './getContraturnosByDate';
import { getPeriodoByDate } from './getPeriodoByDate';
import { ESTADO_PRESENTE, TIPO_PERIODO } from '../../../../../../../commons';
import { updatePresentes } from './updatePresentes';
import { PresenteGeneral, SECCION_TURNO } from '../../../types';
import { getDaysByRange } from '../../../../../../../utils/getDaysByRange';
import { getSituacionByDate } from './getSituacionByDate';
import { DeepPartial } from '../../../../../../../commons/types/DeepPartial';

interface PresentesMasterProps {
  movimiento: AlumnoMovimiento;
  presentismo: PresenteGeneral[];
  contraturnos: ContraturnoDia[];
  feriados: FechaBloqueada[];
  situaciones: SituacionV3[];
  periodos: PeriodoNuevo[];
  turno: number;
  isJE: boolean;
  currentWeek: moment.Moment;
  seccionSelected: string;
  selectedEspacioCurricular?: string;
  setNewPresentismos: React.Dispatch<React.SetStateAction<any[]>>;
}

export const getPresentesMaster = ({
  movimiento,
  presentismo,
  contraturnos,
  feriados,
  situaciones,
  periodos,
  turno,
  isJE,
  currentWeek,
  seccionSelected,
  selectedEspacioCurricular,
  setNewPresentismos,
}: PresentesMasterProps) => {
  const turnos = selectedEspacioCurricular
    ? ASISTENCIA_GENERAL
    : TURNOS[turno]
    ? TURNOS[turno]
    : [];
  let _presentismo: PresenteGeneral[] = [];

  const curreentStar = moment(currentWeek, 'YYYY-MM-DD')
    .startOf('isoWeek')
    .format('YYYY-MM-DD');

  const curreentSEnd = moment(currentWeek, 'YYYY-MM-DD')
    .endOf('isoWeek')
    .format('YYYY-MM-DD');

  const enabledDays = getDaysByRange(
    moment(curreentStar, 'YYYY-MM-DD').format('YYYY-MM-DD'),
    moment(curreentSEnd, 'YYYY-MM-DD').format('YYYY-MM-DD'),
    true,
  );

  for (const date of enabledDays) {
    const _day = moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD');
    const _periodo = getPeriodoByDate(periodos, _day);
    if (!_periodo) {
      // console.log('No hay un periodo');
      continue;
    }
    const isRecesoInvernal =
      _periodo.tipoPeriodo.idTipoPeriodo === TIPO_PERIODO.RECESO_INVERNAL;

    // Deberia solo exisir una sittuacion para un dia / seccion / turno - sea una siuacion / motivo
    const _situacion = selectedEspacioCurricular
      ? null
      : getSituacionByDate(situaciones, _day, seccionSelected, isJE);
    let hasSeccion: SituacionSeccion | undefined;
    if (_situacion) {
      hasSeccion = _situacion.secciones.find(
        (seccion) => seccion.idSeccion === seccionSelected,
      );
    }

    const _feriado = feriados.find((feriado) => {
      const hasExceptions = Object.keys(feriado.exceptions || {}).length > 0;
      if (hasExceptions) {
        return (
          moment(feriado.date, 'YYYY-MM-DD').isSame(
            moment(_day, 'YYYY-MM-DD'),
          ) &&
          !feriado.exceptions.nivels.includes(movimiento.seccion.nivel.idNivel)
        );
      }
      return moment(feriado.date, 'YYYY-MM-DD').isSame(
        moment(_day, 'YYYY-MM-DD'),
      );
    });

    const _contraturnos = selectedEspacioCurricular
      ? []
      : getContraturnosByDate(contraturnos, _day);

    // TURNOS
    for (const _turno of turnos) {
      const isExistNC = presentismo.find(
        (presente) =>
          moment(presente.fecha, 'YYYY-MM-DD').isSame(
            moment(_day, 'YYYY-MM-DD'),
          ) &&
          presente.estadoPresente.idEstadoPresente ===
            ESTADO_PRESENTE.NO_CORRESPONDE &&
          presente.turno?.idTurno === _turno.idTurno,
      );
      if (isExistNC) continue;
      let presenteToSave: DeepPartial<PresenteGeneral> = {
        idPresente: null,
        alumno: movimiento.alumno,
        estadoPresente: {
          idEstadoPresente: ESTADO_PRESENTE.NO_CORRESPONDE,
        },
        fecha: moment(_day).format('YYYY-MM-DD'),
        turno: { idTurno: _turno.idTurno },
        seccion: movimiento.seccion,
        isJornadaExtendida: isJE,
        justificacion: null,
        contraturnoDia: null,
        periodoNuevo: _periodo,
        espacioCurricular: selectedEspacioCurricular
          ? { idEspacioCurricular: selectedEspacioCurricular }
          : null,
      };
      const exitePresente = presentismo.find(
        (presente) =>
          moment(presente.fecha, 'YYYY-MM-DD').isSame(
            moment(_day, 'YYYY-MM-DD'),
          ) &&
          presente.turno?.idTurno === _turno.idTurno &&
          presente.estadoPresente.idEstadoPresente !==
            ESTADO_PRESENTE.NO_CORRESPONDE,
      );

      // Prioridad el Feriado y el receso
      if (Boolean(_feriado) || isRecesoInvernal) {
        _presentismo.push({
          ...(exitePresente || presenteToSave),
          estadoPresente: {
            idEstadoPresente: ESTADO_PRESENTE.NO_CORRESPONDE,
          },
          periodoNuevo: _periodo,
          espacioCurricular: selectedEspacioCurricular
            ? { idEspacioCurricular: selectedEspacioCurricular }
            : null,
        } as PresenteGeneral);
      } else if (Boolean(_situacion) && Boolean(hasSeccion)) {
        const situacionTurnos =
          hasSeccion?.idTurno === SECCION_TURNO.C.toString()
            ? ['1', '2']
            : [hasSeccion?.idTurno || ''];
        const isSameTurn = situacionTurnos.includes(_turno.idTurno.toString());
        const isSuspencion =
          _situacion?.tipoSituacion.idTipoSituacion ===
          TIPO_SITUACIONES.SUSPENSION_CLASES;

        if (!isSameTurn) continue;
        if (isSuspencion) {
          _presentismo.push({
            ...(exitePresente || presenteToSave),
            estadoPresente: {
              idEstadoPresente: ESTADO_PRESENTE.NO_CORRESPONDE,
            },
            situacion: _situacion,
            periodoNuevo: _periodo,
            espacioCurricular: selectedEspacioCurricular
              ? { idEspacioCurricular: selectedEspacioCurricular }
              : null,
          } as PresenteGeneral);
        } else if (!exitePresente) {
          // ES emergencia
          // Se agrega solo si no existe un presente
          _presentismo.push({
            ...presenteToSave,
            estadoPresente: {
              idEstadoPresente: ESTADO_PRESENTE.NO_CORRESPONDE,
            },
            situacion: _situacion,
            periodoNuevo: _periodo,
            espacioCurricular: selectedEspacioCurricular
              ? { idEspacioCurricular: selectedEspacioCurricular }
              : null,
          } as PresenteGeneral);
        }
      }
    }

    //CONTRATURNOS
    for (const _contraturno of _contraturnos) {
      const isExistNC = presentismo.find(
        (presente) =>
          moment(presente.fecha, 'YYYY-MM-DD').isSame(
            moment(_day, 'YYYY-MM-DD'),
          ) &&
          presente.estadoPresente.idEstadoPresente ===
            ESTADO_PRESENTE.NO_CORRESPONDE &&
          presente.contraturnoDia?.idContraturnoDia ===
            _contraturno.idContraturnoDia,
      );
      if (isExistNC) continue;
      let presenteToSave: DeepPartial<PresenteGeneral> = {
        idPresente: null,
        alumno: movimiento.alumno,
        estadoPresente: {
          idEstadoPresente: ESTADO_PRESENTE.NO_CORRESPONDE,
        },
        fecha: moment(_day, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        seccion: movimiento.seccion,
        isJornadaExtendida: isJE,
        justificacion: null,
        contraturnoDia: _contraturno,
        turno: null,
        periodoNuevo: _periodo,
        espacioCurricular: selectedEspacioCurricular
          ? { idEspacioCurricular: selectedEspacioCurricular }
          : null,
      };
      const exitePresente = presentismo.find(
        (presente) =>
          moment(presente.fecha, 'YYYY-MM-DD').isSame(
            moment(_day, 'YYYY-MM-DD'),
          ) &&
          presente.contraturnoDia?.idContraturnoDia ===
            _contraturno.idContraturnoDia &&
          presente.estadoPresente.idEstadoPresente !==
            ESTADO_PRESENTE.NO_CORRESPONDE,
      );
      // Prioridad el Feriado y el receso
      if (Boolean(_feriado) || isRecesoInvernal) {
        _presentismo.push({
          ...(exitePresente || presenteToSave),
          estadoPresente: {
            idEstadoPresente: ESTADO_PRESENTE.NO_CORRESPONDE,
          },
          contraturnoDia: _contraturno,
          periodoNuevo: _periodo,
          espacioCurricular: selectedEspacioCurricular
            ? { idEspacioCurricular: selectedEspacioCurricular }
            : null,
        } as PresenteGeneral);
      } else if (Boolean(_situacion)) {
        // const isSameTurn = hasSeccion?.idTurno === _turno.idTurno.toString();
        const isSuspencion =
          _situacion?.tipoSituacion.idTipoSituacion ===
          TIPO_SITUACIONES.SUSPENSION_CLASES;

        // if (!isSameTurn) continue;
        if (isSuspencion) {
          _presentismo.push({
            ...(exitePresente || presenteToSave),
            estadoPresente: {
              idEstadoPresente: ESTADO_PRESENTE.NO_CORRESPONDE,
            },
            contraturnoDia: _contraturno,
            situacion: _situacion,
            periodoNuevo: _periodo,
            espacioCurricular: selectedEspacioCurricular
              ? { idEspacioCurricular: selectedEspacioCurricular }
              : null,
          } as PresenteGeneral);
        } else if (!exitePresente) {
          // ES emergencia
          // Se agrega solo si no existe un presente
          _presentismo.push({
            ...presenteToSave,
            estadoPresente: {
              idEstadoPresente: ESTADO_PRESENTE.NO_CORRESPONDE,
            },
            contraturnoDia: _contraturno,
            situacion: _situacion,
            periodoNuevo: _periodo,
            espacioCurricular: selectedEspacioCurricular
              ? { idEspacioCurricular: selectedEspacioCurricular }
              : null,
          } as PresenteGeneral);
        }
      }
    }
  }

  return updatePresentes(_presentismo, presentismo, setNewPresentismos);
};
