import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { CodigoVinculo } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'codigovinculo';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idCodigoVinculo',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idCodigoVinculo',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.CodigoVinculo
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.CodigoVinculo,
      hoc: { ...defaultOptions.hoc, ...businessOptions.CodigoVinculo.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<CodigoVinculo>(DAO_NAME, optionsToUse);

// hooks
export const useCodigoVinculoFetchById = fetchById;
export const useCodigoVinculoLibbyCall = libbyCall;
export const useCodigoVinculoDAO = libbyDAO;
export const useCodigoVinculoLibbyFetch = libbyFetch;

// components/hoc
export const CodigoVinculoAutocomplete = entityAutocomplete;
export const CodigoVinculoCall = renderPropCall;
export const CodigoVinculoById = renderPropFetchById;
export const CodigoVinculoSelect = simpleSelect;
export const CodigoVinculoAccordionSelector = accordionSelector;
export const CodigoVinculoSelectedChips = selectedChips;

// context
// ByIdContext
export const useCodigoVinculoByIdContext = useByIdContext;
export const CodigoVinculoByIdProvider = ByIdProvider;
export const CodigoVinculoByIdContext = ByIdContext;
// GeneralContext
export const useCodigoVinculoContext = useGeneralContext;
export const CodigoVinculoProvider = GeneralProvider;
export const CodigoVinculoContext = GeneralContext;
