import React, {
  FunctionComponent,
  SetStateAction,
  Dispatch,
  useState,
  useEffect,
} from 'react';
import { formatMs, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import { primary, grey } from 'src/theme/colors';
import moment from 'moment';
interface DisciplinaPendiente {
  espacioCurricular: any;
  motivo: any;
  cicloLectivo: any;
  apoyo: any;
  valoracion: any;
  calificacion: any;
  anio: any;
  periodo: any;
  fecha: any;
  submit: boolean;
  planEstudioMateria: any;
  tipoDeudaAcademica: any;
  planEstudioMat: any;
}

interface DocumentProps {
  onDelete: Dispatch<SetStateAction<any>>;
  disciplinaPendiente: DisciplinaPendiente;
}

const useStyles = makeStyles({
  deleteIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
    '& > path': {
      fill: grey.textPrimary,
    },
    margin: 'auto 0 auto auto',
  },
  label: {
    marginBottom: 5,
    fontSize: 13,
    fontWeight: 600,
    color: grey.textPrimary,
  },
  labelChild: {
    fontSize: '15px',
    color: grey.textPrimary,
    fontWeight: 'bold',
  },
  whiteContainer: {
    margin: '0',
    background: primary.white,
    borderRadius: ' 10px',
    marginBottom: '2%',
  },
  deletePosition: {
    display: 'flex',
  },
  item: {
    maxWidth: '10em',
  },
});

export const DocumentCard: FunctionComponent<DocumentProps> = ({
  onDelete,
  disciplinaPendiente,
}) => {
  const classes = useStyles();
  const {
    cicloLectivo,
    espacioCurricular,
    motivo,
    apoyo,
    valoracion,
    calificacion,
    anio,
    periodo,
    fecha,
    submit,
    planEstudioMateria,
    tipoDeudaAcademica,
    planEstudioMat,
  } = disciplinaPendiente || {};
  const [disable, setDisable] = useState<boolean>();

  let newFecha;
  let DisciplinaApoyo;

  if (
    disciplinaPendiente.apoyo === true ||
    disciplinaPendiente.apoyo?.name === true
  ) {
    DisciplinaApoyo = 'Sí';
  } else if (
    disciplinaPendiente.apoyo === false ||
    disciplinaPendiente.apoyo?.name === false
  ) {
    DisciplinaApoyo = 'No';
  } else {
    DisciplinaApoyo = '';
  }

  if (disciplinaPendiente.fecha instanceof Date) {
    let newDateObject = moment(disciplinaPendiente.fecha).format(`DD-MM-YYYY`);
    newFecha = newDateObject;
  } else if (disciplinaPendiente.fecha) {
    let newDate = moment(Date.parse(disciplinaPendiente.fecha)).format(
      `DD-MM-YYYY`,
    );
    newFecha = newDate;
  }

  useEffect(() => {
    if (
      disciplinaPendiente.motivo ===
        'Plan de Fortalecimiento de los Aprendizajes' ||
      disciplinaPendiente?.tipoDeudaAcademica?.idTipoDeudaAcademica === 1
    ) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [
    disciplinaPendiente.motivo,
    disciplinaPendiente?.tipoDeudaAcademica?.idTipoDeudaAcademica,
  ]);

  return (
    <Grid className={classes.whiteContainer} container spacing={3} xs={12}>
      <Grid item>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Typography className={classes.label}>
              Espacio Curricular
            </Typography>
            <Typography className={classes.labelChild}>
              {disciplinaPendiente.planEstudioMateria?.name ||
                disciplinaPendiente.planEstudioMateria ||
                disciplinaPendiente.espacioCurricular?.name ||
                disciplinaPendiente.espacioCurricular}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.item}>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Typography className={classes.label}>Motivo</Typography>
            <Typography className={classes.labelChild}>
              {disciplinaPendiente.motivo?.descripcion ||
                disciplinaPendiente.motivo ||
                disciplinaPendiente.tipoDeudaAcademica?.descripcion ||
                disciplinaPendiente.tipoDeudaAcademica}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Typography className={classes.label}>Ciclo Lectivo</Typography>
            <Typography className={classes.labelChild}>
              {disciplinaPendiente.cicloLectivo?.anio ||
                disciplinaPendiente.cicloLectivo}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Typography className={classes.label}>Año</Typography>
            <Typography className={classes.labelChild}>
              {disciplinaPendiente.anio?.descripcionAnio ||
                disciplinaPendiente.anio}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      {!disable && (
        <Grid item>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <Typography className={classes.label}>Fecha</Typography>
              <Typography className={classes.labelChild}>{newFecha}</Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {disable && (
        <Grid item>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <Typography className={classes.label}>
                Participó en las instancias de apoyo?
              </Typography>
              <Typography className={classes.labelChild}>
                {DisciplinaApoyo || disciplinaPendiente.apoyo?.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {disable && (
        <Grid item>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <Typography className={classes.label}>Periodo</Typography>
              <Typography className={classes.labelChild}>
                {disciplinaPendiente.periodo?.name ||
                  disciplinaPendiente.periodo}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      {disable && (
        <Grid item>
          <Grid container spacing={3} direction="column">
            <Grid item>
              <Typography className={classes.label}>Valoración</Typography>
              <Typography className={classes.labelChild}>
                {disciplinaPendiente.valoracion}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Grid item>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Typography className={classes.label}>Calificación</Typography>
            <Typography className={classes.labelChild}>
              {disciplinaPendiente.calificacion?.name ||
                disciplinaPendiente.calificacion}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={3} direction="column">
          <Grid item>
            <Typography className={classes.label}>Plan de estudio</Typography>
            <Typography className={classes.labelChild}>
              {disciplinaPendiente.planEstudioMat?.name ||
                disciplinaPendiente.planEstudioMat}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.deletePosition}>
        <DeleteIcon className={classes.deleteIcon} onClick={onDelete} />
      </Grid>
    </Grid>
  );
};
