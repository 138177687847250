import React, { useRef } from 'react';
import { Grid } from '@material-ui/core';
import { useRolesContext } from 'src/context/RolesContext';
import {
  SeccionProvider,
  EspacioCurricularByIdProvider,
  ArealPlanFeedbackCall,
  useArealPlanFeedbackDAO,
} from 'src/app/business';
import { InfoCard } from '../commons/components';
import { useInfoCards } from './hooks';
import { ArealInterarealProvider } from '../../forms/ArealInterareal/context';
import {
  ArealPlanFeedback,
  EspaciosArealPlan,
  ArealPlanArea,
} from '../../../../../../../app/models';
import { ArealInterarealFlowHeader } from './components';
import {
  PlanificacionFeedbackForm,
  PlanificacionFeedbackView,
  useFeedbackOnSubmit,
} from '../commons';
import { useArealPlanDAO } from '../../../../../../../app/business';
import { PLANIFICACION_ESTADOS } from '../../../../../../../commons/const';
import { Loading } from '../../../../../../../commons/components/Loading';

export interface ArealInterarealFlowProps {
  data: EspaciosArealPlan;
}

export const ArealInterarealFlow = ({ data }: ArealInterarealFlowProps) => {
  const cards = useInfoCards(data);

  const arealAreas = data.arealPlan.arealPlanAreas;
  const { selectedRole } = useRolesContext();
  const areas = arealAreas?.map((area: ArealPlanArea) => area.area) || [];
  const seccionFilter = useRef({
    filter: [{ path: 'localizacion', value: selectedRole.localizacionId }],
  });

  const arealPlanFeedbackDAO = useArealPlanFeedbackDAO();
  const arealPlanDAO = useArealPlanDAO();
  const onSubmit = useFeedbackOnSubmit({
    planDAO: arealPlanDAO,
    feedbackDAO: arealPlanFeedbackDAO,
    plan: data.arealPlan,
  });

  const isSend =
    data.arealPlan.planificacionEstados.idPlanificacionEstados ===
    PLANIFICACION_ESTADOS.ENVIADO;
  return (
    <>
      <SeccionProvider filter={seccionFilter.current}>
        <>
          <ArealInterarealFlowHeader data={data} />
          <Grid container>
            <Grid item sm={6}>
              <EspacioCurricularByIdProvider
                id={data.espacioCurricular.idEspacioCurricular}
              >
                <ArealInterarealProvider
                  areas={areas}
                  idAnio={data.espacioCurricular.materia.anio.idAnio}
                  idPlanEstudio={
                    data.espacioCurricular.planEstudio.idPlanEstudio
                  }
                >
                  <InfoCard title="Planificación" cards={cards} />
                </ArealInterarealProvider>
              </EspacioCurricularByIdProvider>
            </Grid>
            <Grid item sm={6}>
              <ArealPlanFeedbackCall<ArealPlanFeedback>
                methodName="getLastFeedback"
                params={[data.arealPlan.idArealPlan]}
              >
                {({ data: arealPlanFeedback, working }) =>
                  working || arealPlanFeedback === undefined ? (
                    <Loading />
                  ) : isSend ? (
                    <PlanificacionFeedbackForm
                      onSubmit={onSubmit}
                      data={arealPlanFeedback}
                    />
                  ) : (
                    <PlanificacionFeedbackView
                      data={arealPlanFeedback.feedback}
                    />
                  )
                }
              </ArealPlanFeedbackCall>
            </Grid>
          </Grid>
        </>
      </SeccionProvider>
    </>
  );
};
