import { LibbyFetchDAO } from './LibbyFetchDAO';

export class UsuarioRolDependenciaFuncionalDAO extends LibbyFetchDAO {
  constructor() {
    super('usuario_rol_dependencia_funcional', 'rolDependenciaFuncionalId');
  }

  fetchByRolEstablecimientoId = (id: number) => {
    return this.query().equals('rol_usuario_id', id).run();
  };
}
