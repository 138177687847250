import { MotivoSituacionV3DAO } from 'src/platform/libby/dao/Presentismov3';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { MotivoSituacionV3 } from '../../models/presentismo_v3';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'motivos';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { renderPropCall, renderPropFetchById, simpleSelect, selectInput },
} = makeBusinessEntity<MotivoSituacionV3, MotivoSituacionV3DAO>(DAO_NAME, {});

// hooks
export const useMotivoSituacionV3FetchById = fetchById;
export const useMotivoSituacionV3LibbyCall = libbyCall;
export const useMotivoSituacionV3DAO = libbyDAO;
export const useMotivoSituacionV3LibbyFetch = libbyFetch;

// components/hoc
export const MotivoSituacionV3Call = renderPropCall;
export const MotivoSituacionV3ById = renderPropFetchById;
export const MotivoSituacionV3Select = simpleSelect;
export const MotivoSituacionV3SelectInput = selectInput;
