import { LibbyFetchDAO } from './LibbyFetchDAO';

export class FeedbackPlanItemDAO extends LibbyFetchDAO {
  constructor() {
    super('feedback_plan_item', 'idFeedbackPlanItem');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
