import { businessOptions } from '../businessOptions';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { Recuperacion } from 'src/app/models/calificaciones/Recuperacion';

const DAO_NAME = 'calificacionesrecuperacion';

const defaultOptions: PartialBusinessEntityOptions<any> = {};

const optionsToUse = !businessOptions.SecundarioCustom
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.SecundarioCustom,
      hoc: { ...defaultOptions.hoc, ...businessOptions.SecundarioCustom.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Recuperacion>(DAO_NAME, optionsToUse);

// hooks
export const useCalificacionesRecuperacionFetchById = fetchById;
export const useCalificacionesRecuperacionLibbyCall = libbyCall;
export const useCalificacionesRecuperacionDAO = libbyDAO;
export const useCalificacionesRecuperacionLibbyFetch = libbyFetch;

// components/hoc
export const CalificacionesRecuperacionAutocomplete = entityAutocomplete;
export const CalificacionesRecuperacionCall = renderPropCall;
export const CalificacionesRecuperacionById = renderPropFetchById;
export const CalificacionesRecuperacionSelect = simpleSelect;
export const CalificacionesRecuperacionAccordionSelector = accordionSelector;
export const CalificacionesRecuperacionSelectedChips = selectedChips;

// context
// ByIdContext
export const useCalificacionesRecuperacionByIdContext = useByIdContext;
export const CalificacionesRecuperacionByIdProvider = ByIdProvider;
export const CalificacionesRecuperacionByIdContext = ByIdContext;
// GeneralContext
export const useCalificacionesRecuperacionContext = useGeneralContext;
export const CalificacionesRecuperacionProvider = GeneralProvider;
export const CalificacionesRecuperacionContext = GeneralContext;
