import React, { useMemo } from 'react';
import { useRepositorioItemFetchById } from '../../../../../app/business';
import { RepositorioItem } from '../../../../../app/models';

type ChildrenValue<T> = {
  data: T | null;
  working: boolean;
  raw: RepositorioItem | undefined;
};

export interface RepositorioABMEditValueProps<T> {
  idRepositorioItem: number;
  children: (value: ChildrenValue<T>) => JSX.Element;
  transformer: (value: RepositorioItem) => T;
}

// TODO: convertir en un componente de business makeEntityByIdTransformer
export function RepositorioABMEditValue<T>({
  idRepositorioItem,
  children,
  transformer,
}: RepositorioABMEditValueProps<T>) {
  const { data, working } = useRepositorioItemFetchById(idRepositorioItem);

  const transformed = useMemo<T | null>(() => {
    if (!data || working) {
      return null;
    }
    return transformer(data);
  }, [data, transformer, working]);

  return <>{children({ data: transformed, working, raw: data })}</>;
}
