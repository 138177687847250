import { LibbyFetchDAO } from './LibbyFetchDAO';

export class EspecialesBycDAO extends LibbyFetchDAO {
  constructor() {
    super('especiales_byc', 'idEspecialesByc');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
