import { LibbyFetchDAO } from './LibbyFetchDAO';

export class BloquesDAO extends LibbyFetchDAO {
  constructor() {
    super('bloques', 'idBloque');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
