import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { EspaciosPlanificacion } from '../../../../../../app/models';
import { InfoTable } from '../../../../../../commons/components/InfoTable';
import {
  useEspaciosSimpDiscipPlanLibbyFetch,
  useEspaciosInterdiscipPlanLibbyFetch,
} from '../../../../../../app/business';
import { useDisciplinarInterDisciplinarTableColumns } from './hooks';

interface DafaultTableProps {
  idEspacioCurricular: string;
  selectValues: any;
  search: any;
  createdPlanificationCheck: (list: number) => void;
  cicloFilter: {
    disciplinar: string;
    interareal: string;
    interdisciplinar: string;
    value: string;
    method: string;
  };
}

interface Order {
  disciplinar: string;
  interdisciplinar: string;
}

export const DisciplinarInterDisciplinarTable = ({
  idEspacioCurricular,
  selectValues,
  search,
  cicloFilter,
  createdPlanificationCheck,
}: DafaultTableProps) => {
  const espacioCurricular = useMemo(
    () => ({
      espacio: [
        {
          path: 'espacioCurricular.idEspacioCurricular',
          value: idEspacioCurricular,
        },
      ],
    }),
    [idEspacioCurricular],
  );

  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');

  // Disciplinar
  const [orderByDisciplinar, setOrderByDisciplinar] = useState(
    'espacioCurricular.materia.area.descripcion',
  );
  const [filterDisciplinar, setFilterDisciplinar] =
    useState<any>(espacioCurricular);

  // Areal
  const [orderByInterdiscip, setOrderByAreal] = useState(
    'espacioCurricular.materia.area.descripcion',
  );
  const [filterInterdiscip, setFilterInterdisciplinar] =
    useState<any>(espacioCurricular);

  const filterObj = useCallback(
    (searching) => {
      if (!idEspacioCurricular) {
        setFilterDisciplinar({ ...espacioCurricular, searching });
        setFilterInterdisciplinar({ searching });
      } else {
        setFilterDisciplinar({
          ...espacioCurricular,
          selectValues: selectValues[0]?.path?.disciplinar
            ? [
                {
                  path: selectValues[0]?.path?.disciplinar,
                  value: selectValues[0]?.value,
                },
              ]
            : [],
          searching: searching[0]?.path
            ? [
                {
                  path: searching[0]?.path.disciplinar,
                  method: searching[0]?.method,
                  value: searching[0]?.value,
                },
              ]
            : [],
          cicloFilter: cicloFilter?.disciplinar
            ? [
                {
                  path: cicloFilter.disciplinar,
                  method: cicloFilter.method,
                  value: cicloFilter.value,
                },
              ]
            : [],
        });
        // TODO: Cuando haya una api general se puede cambiar por un único filter
        setFilterInterdisciplinar({
          selectValues: selectValues[0]?.path?.interdisciplinar
            ? [
                {
                  path: selectValues[0]?.path?.interdisciplinar,
                  value: selectValues[0]?.value,
                },
              ]
            : [],
          searching: searching[0]?.path
            ? [
                {
                  path: searching[0]?.path.interdisciplinar,
                  method: searching[0]?.method,
                  value: searching[0]?.value,
                },
              ]
            : [],
          cicloFilter: cicloFilter?.interdisciplinar
            ? [
                {
                  path: cicloFilter.interdisciplinar,
                  method: cicloFilter.method,
                  value: cicloFilter.value,
                },
              ]
            : [],
        });
      }
    },
    [idEspacioCurricular, selectValues, espacioCurricular, cicloFilter],
  );
  useEffect(() => {
    filterObj(search);
  }, [selectValues, search, filterObj]);

  // TODO: Cuando haya una api general se puede cambiar por un sólo fetch
  // fetch Disciplinar
  const {
    data: planinifaciones,
    fetchMore: fetchMoreDisciplinar,
    working,
    reFetch,
  } = useEspaciosSimpDiscipPlanLibbyFetch({
    filter: filterDisciplinar,
    orderBy: orderByDisciplinar,
    direction,
    aspect: 'limit_province',
  });

  // fetch Areal
  const {
    data: planinifacionesInterdiscip = [],
    fetchMore: fetchMoreInterdiscip,
    working: workingInterdiscip,
  } = useEspaciosInterdiscipPlanLibbyFetch({
    filter: filterInterdiscip,
    orderBy: orderByInterdiscip,
    direction,
    aspect: 'limit_province',
  });

  // join fetchs
  const [joinPlanificaciones, setJoinPlanificaciones] = useState<any[]>([]);
  useEffect(() => {
    let planificacionesFiltered: any = [];
    planinifacionesInterdiscip.forEach((element: any) => {
      let alreadyInArray = false;

      if (
        element?.espacioCurricular?.idEspacioCurricular === idEspacioCurricular
      ) {
        planificacionesFiltered.push(element);
        alreadyInArray = true;
      }
      if (alreadyInArray === false) {
        element?.interdiscipPlan?.espacioCurricular?.espaciosCurriculares.forEach(
          (espacioCurricular: any) => {
            if (
              espacioCurricular?.espacioCurricular?.id === idEspacioCurricular
            ) {
              planificacionesFiltered.push(element);
            }
          },
        );
      }
    });
    setJoinPlanificaciones([
      ...(planinifaciones || []),
      ...(planificacionesFiltered || []),
    ]);
  }, [idEspacioCurricular, planinifaciones, planinifacionesInterdiscip]);

  const handleRequestSort = (
    newOrderBy: Order,
    newDirection: 'asc' | 'desc',
  ) => {
    setDirection(newDirection);
    setOrderByDisciplinar(newOrderBy.disciplinar);
    setOrderByAreal(newOrderBy.interdisciplinar);
  };

  const fetchMore = () => {
    fetchMoreDisciplinar();
    fetchMoreInterdiscip();
  };

  const columns = useDisciplinarInterDisciplinarTableColumns(reFetch);

  useEffect(() => {
    if (createdPlanificationCheck) {
      createdPlanificationCheck(planinifaciones?.length as number);
    }
  }, [createdPlanificationCheck, planinifaciones]);

  return (
    <>
      <InfoTable
        rows={joinPlanificaciones as EspaciosPlanificacion[]}
        columns={columns}
        working={working || workingInterdiscip}
        rowIdKey="idEspaciosPlanificacion"
        direction={direction}
        orderBy={orderByInterdiscip}
        onSortChange={handleRequestSort}
        onBottomScroll={fetchMore}
      />
    </>
  );
};
