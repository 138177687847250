import { isEqual, cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';
import { useCallback, useState } from 'react';
import { RowCalification, SecundarioCustom, Validable } from 'src/app/models';
import { DEFAULT_HIDE_TIME } from 'src/commons/const/ui/snackbar';
import { SecundarioDAO } from 'src/platform/libby/dao';
import { CalificacionColumn } from '../CalificacionesSecundario';
import { useCalificacionesSecundarioAnualDAO } from 'src/app/business/Calificaciones/Anual';
import { InfoTableColumn } from 'src/commons';
import { JornadaExtendidaDAO } from '../../../../platform/libby/dao/Calificaciones/JornadaExtendidaDAO';

export const useCalificacionesSubmit = <T extends RowCalification>({
  columns,
  _rows,
  data,
  filter,
  dao,
  reFetch,
  isInitial,
  isSecundario,
  ecs,
}: {
  columns: Array<InfoTableColumn<T>>;
  _rows: Array<T>;
  data: Array<T>;
  filter: any;
  dao: SecundarioDAO | JornadaExtendidaDAO;
  reFetch: () => void;
  isInitial: boolean;
  isSecundario: boolean;
  ecs: number;
}) => {
  const [submitting, setSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const anualDao = useCalificacionesSecundarioAnualDAO();
  const validateApprovedPrimario = useCallback(
    (calificacionGeneral: string) => {
      if (filter.anio === 7) {
        return Number(calificacionGeneral) > 6;
      } else {
        return (
          calificacionGeneral.includes('(B)') ||
          calificacionGeneral.includes('(MB)') ||
          calificacionGeneral.includes('(S)')
        );
      }
    },
    [filter],
  );
  const validateApprovedSecundario = useCallback(
    (calificacion: string | number) => {
      switch (true) {
        case calificacion === 'Cumplimentado':
          return true;
        case calificacion >= 6:
          return true;
        default:
          return false;
      }
    },
    [],
  );

  const removerIndicadoresInvalidos = useCallback(
    (rows: Array<SecundarioCustom>, columns: Array<CalificacionColumn>) => {
      const rowsCopy = cloneDeep(rows);
      const indicadores = columns.map(
        (indicador: CalificacionColumn) => indicador.id,
      );
      const rowsSinIndicadoresInvalidos = rowsCopy.map(
        (row: SecundarioCustom) => {
          for (const indicador in row.nota?.data) {
            if (
              indicadores.indexOf(indicador) === -1 &&
              indicador !== 'otrosApoyos'
            ) {
              delete row.nota?.data[indicador];
            }
          }
          return row;
        },
      );
      return rowsSinIndicadoresInvalidos;
    },
    [],
  );

  const onSubmit = useCallback(async () => {
    setSubmitting(true);

    const updatedRows = _rows.filter((row) => {
      let isUpdated = false;
      const currentCalification = data?.find(
        (d: any) => d.idCalificacion === row.idCalificacion,
      );
      if (currentCalification?.nota) {
        isUpdated =
          !isEqual(currentCalification?.nota?.data, row.nota?.data) ||
          !isEqual(
            currentCalification.calificacionAnual,
            row.calificacionAnual,
          );
      } else if (
        typeof row.nota?.data === 'object' &&
        Object.keys(row.nota?.data).length >= row.fieldsToValidateByRow.length
      ) {
        isUpdated = true;
      }
      return isUpdated;
    });
    const rowsSinIndicadoresInvalidos = removerIndicadoresInvalidos(
      updatedRows,
      columns,
    );

    const submitRows = rowsSinIndicadoresInvalidos.map(
      (calificacion: SecundarioCustom) => {
        if (isSecundario) {
          const { calificacion: calf } = calificacion.nota?.data;
          if (filter.jemateria) {
            return {
              ...calificacion.nota,
              seccion: filter.seccion,
              jemateria: filter.jemateria,
            };
          } else {
            return {
              ...calificacion.nota,
              espacioCurricularSeccion: ecs ?? filter.espacioCurricularSeccion,
              nota: calf,
              aprobado: validateApprovedSecundario(calf),
              idAlumno: calificacion.idAlumno,
              ...(filter.periodo === 8 && {
                idSecundarioAnual: calificacion.idSecundarioAnual,
                calificacionAnual: calificacion.calificacionAnual,
              }),
            };
          }
        }
        return { ...calificacion.nota, seccion: filter.seccion };
      },
    );

    interface AnualRows {
      idSecundarioAnual: string;
      alumno: string;
      espacioCurricularSeccion: string;
      nota: string;
    }

    try {
      let anualRows: AnualRows[] = [];
      let calificacionesRows = [];
      const nombre = dao['pk'];

      if (isSecundario) {
        if (filter.jemateria) {
          calificacionesRows = submitRows?.map((row) => {
            const resultadoJESecundario = {
              calificacion: row.calificacion,
              data: row.data,
              nota: row.nota,
              abierto: row.abierto,
              seccion: filter?.seccion,
              jemateria: filter?.jemateria,
            };
            resultadoJESecundario[nombre] = row?.[nombre];

            return resultadoJESecundario;
          });
        } else {
          anualRows = submitRows?.map((row) => {
            return {
              idSecundarioAnual: row.idSecundarioAnual,
              alumno: row.idAlumno,
              espacioCurricularSeccion: row.espacioCurricularSeccion,
              nota: row.calificacionAnual,
            };
          });
          calificacionesRows = submitRows?.map((row) => {
            return {
              idConocimiento: row?.idConocimiento,
              espacioCurricularSeccion: row.espacioCurricularSeccion,
              calificacion: row.calificacion,
              data: row.data,
              nota: row.nota,
              aprobado: row.aprobado,
            };
          });
        }
      } else {
        if (filter.jemateria) {
          calificacionesRows = submitRows?.map((row) => {
            const resultadoJEPRimario = {
              calificacion: row.calificacion,
              data: row.data,
              nota: row.nota,
              abierto: row.abierto,
              seccion: filter.seccion,
              jemateria: filter?.jemateria,
            };
            resultadoJEPRimario[nombre] = row?.[nombre];

            return resultadoJEPRimario;
          });
        } else {
          calificacionesRows = rowsSinIndicadoresInvalidos
            .filter((row) => Object.keys(row.nota?.data).length > 0)
            .map((row) => {
              const resultadoPrimario = {
                idAlumno: row.idAlumno,
                seccion: filter.seccion,
                calificacion: row.idCalificacion,
                data: row?.nota?.data,
                abierto: row.abierto,
              };

              resultadoPrimario[nombre] =
                row?.nota[Object.keys(row.nota).find((key) => key === nombre)];

              if (nombre === 'idPrimario') {
                resultadoPrimario['espacioCurricularSeccion'] =
                  filter.espacioCurricularSeccion;
                resultadoPrimario['nota'] = row.nota?.data.calificacionGeneral;
                resultadoPrimario['aprobado'] = validateApprovedPrimario(
                  row.nota?.data.calificacionGeneral,
                );
              }
              return resultadoPrimario;
            });
        }
      }

      if (calificacionesRows.length > 0) {
        const resCalificacionSecundario = await dao.save(calificacionesRows);
      }
      if (filter.periodo === 8 && !Boolean(filter.jemateria) && isSecundario) {
        const resCalificacionesSecundarioAnual = await anualDao.save(anualRows);
      }

      setSubmitting(false);
      reFetch && reFetch();
      enqueueSnackbar('¡Las calificaciones fueron guardadas con éxito!', {
        variant: 'success',
        autoHideDuration: DEFAULT_HIDE_TIME,
      });
    } catch (e) {
      console.log('error', e);
      setSubmitting(false);
    }
  }, [
    _rows,
    removerIndicadoresInvalidos,
    columns,
    data,
    isSecundario,
    filter,
    ecs,
    validateApprovedSecundario,
    dao,
    reFetch,
    enqueueSnackbar,
    validateApprovedPrimario,
    anualDao,
  ]);

  return {
    onSubmit,
    submitting,
  };
};
