/* eslint-disable react/no-array-index-key */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import {
  FormMulti,
  FormMultiCheckboxInput,
} from '../../../../../../../../../lib/templates/components';

import { useArealInterArealForm } from '../../../ArealInterareal/useArealInterArealForm';
import { CheckboxListItem } from '../../../commons/components/CheckboxListItem';
import { TextField } from '../../../../../../../../../app/components/inputs/Input';
import { IconButtonRemove } from '../../../../../../../../../commons/components/IconButtonRemove';

const useStyles = makeStyles({
  MuiOutlinedInputAdornedEnd: {
    paddingRight: 0,
  },
  buttonRoot: {
    fontSize: 16,
    padding: '9px 12px',
  },
  container: {
    padding: 20,
    marginTop: 15,
    marginBottom: 70,
    borderRadius: 5,
    backgroundColor: 'rgba(218, 218, 218, 0.17)',
  },
});

export const ObjetivosAprendizaje = (): JSX.Element => {
  const classes = useStyles();
  const { form } = useArealInterArealForm();
  return (
    <Grid container direction="column" className={classes.container}>
      <FormMulti
        label="Objetivos de Aprendizaje"
        name="objetivosDeAprendizaje.objetivosDeAprendizaje"
        form={form}
        addButtonText="Agregar Objetivo"
        getNewElement={() => ''}
      >
        {(value, input, meta) => {
          return (
            <TextField
              value={value}
              {...input}
              InputProps={{
                endAdornment: (
                  <IconButtonRemove handleOnClick={input.onRemove} />
                ),
              }}
              error={meta.touched && !!meta.error}
              helperText={meta.error}
              style={{ paddingBottom: '17px' }}
            />
          );
        }}
      </FormMulti>
    </Grid>
  );
};
