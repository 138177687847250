import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { usePresentismoContext } from '../../../context';
import moment from 'moment';
import { Grid, Tooltip } from '@material-ui/core';
import {
  EXCEPTUADOS_MOTIVOS,
  TooltipMotivoDisabled,
} from './TooltipMotivoDisabled';
import { PropuestaJEDia } from '../../../../../../../app/models/presentismo_v2';
import { useEnableToSaveByRol } from '../../../hooks/useEnableToSaveByRol';
import { useRolesContext } from '../../../../../../../context/RolesContext';
import { useAsistenciaCellStyles } from '../css';
import {
  AsistenciaCellProps,
  EstadosPresentismo,
  MOTIVOS_DISABLED,
  PresenteGeneral,
} from '../../../types';
import { ESTADO_PRESENTE, ROL } from '../../../../../../../commons';
import cx from 'classnames';
import { Indicator, ModalMenu } from '../../Resume';
import { JUSTIFICACION_ESTADOS } from '../../../utils/EstadoPresente';
import { DeepPartial } from '../../../../../../../commons/types/DeepPartial';
import { compareDates } from '../../../../../../../utils';

export const AsistenciaCell: FC<AsistenciaCellProps> = ({
  alumno,
  currentWeek,
  idDayofWeek,
  turno = null,
  estadoPresente,
  isTabJornadaExtendida,
  selectedSection,
  contraturno = null,
  modalRef,
  anchorEl,
  pastDaysOfWeek,
  disabledByOrder,
  countcell,
  handleModalClosed,
  handleModelOpen,
  isDayFuture = false,
  dataCierrePresentismo,
  selectedEspacioCurricular,
  isDisabled,
  isLockBlock,
  waitingCandados,
  isValidCicloLectivo,
}) => {
  const classes = useAsistenciaCellStyles();
  const {
    setNewPresentismos,
    dbPresentismos,
    updatePresentismos,
    newPresentismos,
    propuestasJE,
    feriados,
    periodoNuevo,
    setPastDaysOfWeek,
  } = usePresentismoContext();

  const {
    selectedRole: { rol, nivel },
  } = useRolesContext();

  let validateDateData: string[] = [];

  if (isTabJornadaExtendida) {
    validateDateData = dataCierrePresentismo?.map((d) => d.dias.je).flat();
  } else if (selectedEspacioCurricular) {
    validateDateData = dataCierrePresentismo?.map((d) => d.dias.ec).flat();
  } else {
    validateDateData = dataCierrePresentismo?.map((d) => d.dias.jc).flat();
  }

  const formatvalidatedate = validateDateData?.map((d) =>
    moment.utc(d, 'YYYY-MM-DD').format('YYYY-MM-DD'),
  );

  const fecha = moment(currentWeek).startOf('isoWeek').add(idDayofWeek, 'days');
  const isIncludedfechaCandado = formatvalidatedate.includes(
    moment.utc(fecha, 'YYYY-MM-DD').format('YYYY-MM-DD'),
  );

  const handleSelectedOption = useCallback(
    (item: EstadosPresentismo) => {
      const _periodo = periodoNuevo.find((periodo) =>
        moment(fecha, 'YYYY-MM-DD').isBetween(
          moment(periodo.fechaInicio, 'YYYY-MM-DD').format('YYYY-MM-DD'),
          moment(periodo.fechaFin, 'YYYY-MM-DD').format('YYYY-MM-DD'),
          undefined,
          '[]',
        ),
      );
      // DEBE SER UN PRESNETE COMPATIBLE CON PRESENTE V3 y PRESENTE EC
      let presenteToSave: DeepPartial<PresenteGeneral> = {
        idPresente: undefined,
        alumno: alumno.alumno,
        estadoPresente: {
          idEstadoPresente: item.idEstadoPresente,
        },
        fecha: fecha.format('YYYY-MM-DD'),
        turno: turno?.idTurno
          ? {
              idTurno: turno?.idTurno,
            }
          : null,
        contraturnoDia: contraturno?.idContraturnoDia
          ? { idContraturnoDia: contraturno?.idContraturnoDia }
          : null,
        seccion: alumno.seccion,
        isJornadaExtendida: isTabJornadaExtendida,
        periodoNuevo: _periodo,
        espacioCurricular: selectedEspacioCurricular
          ? { idEspacioCurricular: selectedEspacioCurricular }
          : undefined,
      };

      const _presenteDB = dbPresentismos.find((_pre) => {
        return (
          _pre.alumno.idAlumno === alumno.alumno.idAlumno &&
          _pre.isJornadaExtendida === isTabJornadaExtendida &&
          compareDates(_pre.fecha, fecha.format('YYYY-MM-DD')) &&
          (_pre.turno && turno
            ? _pre.turno.idTurno === turno.idTurno
            : turno === _pre.turno) &&
          (_pre.contraturnoDia && contraturno
            ? _pre.contraturnoDia.idContraturnoDia ===
              contraturno.idContraturnoDia
            : contraturno === _pre.contraturnoDia)
        );
      });

      presenteToSave = _presenteDB
        ? { ...presenteToSave, idPresente: _presenteDB.idPresente }
        : presenteToSave;

      setNewPresentismos((prev) => {
        const olds = prev.filter((pre) => {
          return !(
            presenteToSave?.alumno?.idAlumno === pre.alumno.idAlumno &&
            presenteToSave.isJornadaExtendida === pre.isJornadaExtendida &&
            compareDates(presenteToSave.fecha || '', pre.fecha) &&
            (presenteToSave.turno && pre.turno
              ? presenteToSave.turno.idTurno === pre.turno.idTurno
              : pre.turno === presenteToSave.turno) &&
            (presenteToSave.contraturnoDia && pre.contraturnoDia
              ? presenteToSave.contraturnoDia.idContraturnoDia ===
                pre.contraturnoDia.idContraturnoDia
              : pre.contraturnoDia === presenteToSave.contraturnoDia)
          );
        });
        return [...olds, { ...presenteToSave } as PresenteGeneral];
      });

      handleModalClosed();
    },
    [
      alumno.alumno,
      alumno.seccion,
      fecha,
      turno,
      contraturno,
      isTabJornadaExtendida,
      periodoNuevo,
      selectedEspacioCurricular,
      dbPresentismos,
      setNewPresentismos,
      handleModalClosed,
    ],
  );

  const today = moment().format('YYYY-MM-DD');
  const current = useMemo(
    () =>
      moment(currentWeek)
        .startOf('isoWeek')
        .add(idDayofWeek, 'days')
        .format('YYYY-MM-DD'),
    [currentWeek, idDayofWeek],
  );

  // HOY ES ANTES DE FECHA -- BLOQUEO ES FUTURO
  const isFuture = moment(today).isBefore(current);
  const isPassSeccion =
    rol === ROL.DIRECCION_AREA.toString()
      ? false
      : alumno.isChangeSeccion &&
        moment(alumno.createdAt, 'YYYY-MM-DD').isAfter(current);

  const dayIsOnPropuesta = useCallback(() => {
    const propuesta = propuestasJE.find(
      (propuesta) => propuesta.seccion.idSeccion === selectedSection,
    );

    if (!propuesta) return false;

    const dayWeek = moment(current).format('dddd');
    const parseDayWeek = dayWeek === 'miércoles' ? 'miercoles' : dayWeek;
    const dia = propuesta.dias.find(
      (propuestaDia: PropuestaJEDia) =>
        propuestaDia.dia.nombre.toLowerCase() === parseDayWeek,
    );

    return !!dia;
  }, [propuestasJE, current, selectedSection]);

  const daySelected = moment(currentWeek)
    .startOf('isoWeek')
    .add(pastDaysOfWeek, 'days');

  const isEnrolled =
    rol !== ROL.DIRECCION_AREA.toString() &&
    moment(alumno.enrolledAt, 'YYYY-MM-DD').isAfter(current);

  const isDisableByPassRequest = useMemo(() => {
    if (!alumno.solicitudPase || rol === ROL.COORDINADOR_JE.toString())
      return false;
    const approvalDate = moment(alumno.solicitudPase.fechaPase).format(
      'YYYY-MM-DD',
    );

    const isOldPresente = moment(approvalDate).isAfter(current);
    if (isOldPresente && rol !== ROL.DIRECCION_AREA.toString()) return true;
    return false;
  }, [current, alumno.solicitudPase, rol]);

  const IsExceptuado = useMemo(() => {
    const exceptuado = alumno.exceptuados.list.find((li) =>
      li.isExceptuado(moment(current)),
    );
    return exceptuado;
  }, [alumno, current]);

  const isFeriado = useMemo(
    () =>
      !!feriados.find((feriado) => {
        const hasExceptions = Object.keys(feriado.exceptions || {}).length > 0;
        if (hasExceptions) {
          return (
            moment(feriado.date, 'YYYY-MM-DD').isSame(
              moment(current, 'YYYY-MM-DD'),
            ) && !feriado.exceptions.nivels.includes(Number(nivel))
          );
        }
        return moment(feriado.date, 'YYYY-MM-DD').isSame(
          moment(current, 'YYYY-MM-DD'),
        );
      }),
    [current, feriados, nivel],
  );

  const enabledEditNC = useMemo(() => {
    if (!estadoPresente) return false;
    if (
      estadoPresente.idEstadoPresente === ESTADO_PRESENTE.NO_CORRESPONDE &&
      !(
        rol === ROL.DIRECTIVO.toString() ||
        rol === ROL.EQUIPO_CONDUCCION.toString() ||
        rol === ROL.COORDINADOR_JE.toString()
      )
    )
      return true;
    return false;
  }, [rol, estadoPresente]);

  const isDisabledTooltip = !isValidCicloLectivo
    ? true
    : isFuture ||
      (selectedEspacioCurricular && isDisabled) ||
      isLockBlock ||
      isPassSeccion ||
      (isTabJornadaExtendida && !dayIsOnPropuesta()) ||
      !!IsExceptuado ||
      isFeriado ||
      isDisableByPassRequest ||
      isEnrolled; /* ||
    isIncludedfechaCandado; */

  const motivos = useMemo(() => {
    const data: MOTIVOS_DISABLED[] = [];
    if (!isValidCicloLectivo) {
      data.push('FECHA_INVALIDA');
    }
    if (isFuture) {
      data.push('FUTURE_DATE');
    }
    if (selectedEspacioCurricular) {
      data.push('DISABLE_DAY');
    }
    if (isPassSeccion) {
      data.push('CAMBIO_DE_SECCION');
    }
    if (!dayIsOnPropuesta() && isTabJornadaExtendida) {
      data.push('PROPUESTA_JE');
    }
    if (IsExceptuado) {
      data.push(
        EXCEPTUADOS_MOTIVOS[
          IsExceptuado.motivoExceptuado?.idMotivoExceptuado || 1
        ],
      );
    }
    if (isFeriado) {
      data.push('FERIADO');
    }
    if (isDisableByPassRequest) {
      data.push('SOLICITUD_PASE');
    }
    if (isDayFuture) {
      data.push('BLOQUEO_CANDADO');
    }
    if (isIncludedfechaCandado) {
      data.push('CANDADO_BLOQUEADO');
    }
    if (isLockBlock) {
      data.push('RECESO_INVERNAL');
    }
    if (isEnrolled) {
      data.push('ENROLLED');
    }

    return data;
  }, [
    isValidCicloLectivo,
    isFuture,
    selectedEspacioCurricular,
    isPassSeccion,
    dayIsOnPropuesta,
    isTabJornadaExtendida,
    IsExceptuado,
    isFeriado,
    isDisableByPassRequest,
    isDayFuture,
    isIncludedfechaCandado,
    isLockBlock,
    isEnrolled,
  ]);

  const disabledByRol = useEnableToSaveByRol(
    isTabJornadaExtendida,
    Number(rol),
  );

  const disabledByOrderTurnos = useMemo(() => {
    let newDisablebyOrder;
    if (alumno.seccion.nivel.idNivel === 3) {
      newDisablebyOrder = false;
      return newDisablebyOrder;
    }
    if (
      alumno.seccion.nivel.idNivel === 2 ||
      alumno.seccion.nivel.idNivel === 1
    ) {
      const hasNews = !!newPresentismos.find(
        (pre) =>
          moment(pre.fecha).format('YYYY-MM-DD') ===
            daySelected.format('YYYY-MM-DD') &&
          pre.alumno.idAlumno === alumno.alumno.idAlumno,
      );
      const hasOlds = !!updatePresentismos.find(
        (pre) =>
          moment(pre.fecha).format('YYYY-MM-DD') ===
            daySelected.format('YYYY-MM-DD') &&
          pre.alumno.idAlumno === alumno.alumno.idAlumno,
      );
      if (contraturno) return false;
      newDisablebyOrder = disabledByOrder;
      return newDisablebyOrder && !(hasNews || hasOlds);
    }
    return newDisablebyOrder;
  }, [
    disabledByOrder,
    daySelected,
    alumno,
    updatePresentismos,
    newPresentismos,
    contraturno,
  ]);

  const disabled = useMemo(() => {
    return (
      isDisabled ||
      isLockBlock ||
      disabledByRol ||
      disabledByOrderTurnos ||
      enabledEditNC ||
      isFeriado ||
      isFuture ||
      isPassSeccion ||
      (isTabJornadaExtendida && !dayIsOnPropuesta()) ||
      isDisableByPassRequest ||
      isEnrolled /* ||
      isIncludedfechaCandado */
    );
  }, [
    isDisabled,
    isLockBlock,
    disabledByRol,
    disabledByOrderTurnos,
    enabledEditNC,
    isFeriado,
    isFuture,
    isPassSeccion,
    isTabJornadaExtendida,
    dayIsOnPropuesta,
    isDisableByPassRequest,
    isEnrolled,
  ]);

  const handledTableCellBClick = useCallback<
    React.MouseEventHandler<HTMLDivElement>
  >(
    (e) => {
      e.preventDefault();
      e.persist();

      if (idDayofWeek !== pastDaysOfWeek) {
        setPastDaysOfWeek(idDayofWeek);
      } else {
        handleModelOpen(e);
      }
    },
    [handleModelOpen, setPastDaysOfWeek, pastDaysOfWeek, idDayofWeek],
  );

  const getIndicatorColor = (selectedOption?: EstadosPresentismo) => {
    const selected = selectedOption?.idEstadoPresente || 0;
    if (
      JUSTIFICACION_ESTADOS.includes(selected) &&
      Boolean(selectedOption?.justificacion?.idJustificacion)
    ) {
      return 'BLUE';
    }
    return selectedOption?.color;
  };

  return (
    <Tooltip
      id="tooltip-asistencia"
      classes={{ tooltip: classes.tooltip }}
      title={<TooltipMotivoDisabled motivos={motivos} />}
      placement="top"
      disableHoverListener={!isDisabledTooltip || waitingCandados}
      disableFocusListener={!isDisabledTooltip || waitingCandados}
    >
      <Grid
        className={cx(
          classes.content,
          disabled ? classes.disabled : '',
          'grid-asistencia',
          disabled ? 'disabled' : '',
        )}
        container
        justifyContent="center"
        alignItems="center"
        onClick={handledTableCellBClick}
      >
        <Indicator
          color={getIndicatorColor(estadoPresente)}
          label={estadoPresente?.label}
        />
        {!disabled && Boolean(anchorEl) ? (
          <ModalMenu
            modalRef={modalRef}
            anchorEl={anchorEl}
            onClosed={handleModalClosed}
            onSelectOption={(estado) => handleSelectedOption(estado)}
            isTabJornadaExtendida={isTabJornadaExtendida}
            isContraturno={Boolean(contraturno)}
            isEspacioCurricular={Boolean(selectedEspacioCurricular)}
          />
        ) : (
          <></>
        )}
      </Grid>
    </Tooltip>
  );
};
