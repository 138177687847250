import React, { useCallback, useMemo, useState } from 'react';
import { nivelDefaultFilter } from 'src/commons/const';
import { useRouteScreenTitle } from '../../../../commons/hooks';
import { useRepositorioABMColumns } from './hooks';
import { RepositorioItem } from '../../../../app/models';
import { InfoTable } from '../../../../commons/components/InfoTable';
import { useRolesContext } from '../../../../context/RolesContext';
import { useRepositorioItemLibbyFetch } from '../../../../app/business';
import { JoinedFilters, RepositorioABMTableHeader } from './components';

const searchFilters = [
  'nombre',
  'repositorioTipoItem.descripcion',
  'fuente_anio',
  'fuente',
  'nivel.descripcionNivel',
  'ciclo.descripcion',
  'anio.descripcionAnio',
  'area.descripcion',
  'espacioCurricular.descripcion',
];

export const RepositorioABMList = () => {
  useRouteScreenTitle('Repositorio de recursos');

  const [filter, setFilter] = useState<JoinedFilters>({
    idTipo: '',
    idNivel: '',
    search: '',
  });
  const [orderBy, setOrderBy] = useState<string>('');
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const onSortChange = useCallback(
    (sort: string, _direction: 'asc' | 'desc') => {
      setOrderBy(sort);
      setDirection(_direction);
    },
    [],
  );

  const processedFilter = useMemo(
    () => ({
      nivel: !filter.idNivel
        ? nivelDefaultFilter.niveles.map((nivel) => ({
            ...nivel,
            path: 'nivel',
          }))
        : [{ path: 'nivel', value: filter.idNivel }],
      tipo: !filter.idTipo
        ? []
        : [
            {
              path: 'repositorioTipoItem.idRepositorioTipoItem',
              value: filter.idTipo,
            },
          ],
      search: !filter.search
        ? []
        : searchFilters.map((path) => ({
            path,
            value: filter.search,
            method: 'includes',
          })),
    }),
    [filter],
  );

  const { data, working, fetchMore, reFetch } = useRepositorioItemLibbyFetch({
    direction,
    orderBy,
    filter: processedFilter,
  });

  const columns = useRepositorioABMColumns(reFetch);

  return (
    <>
      <RepositorioABMTableHeader filter={filter} onChangeFilter={setFilter} />
      <InfoTable
        rows={data as RepositorioItem[]}
        columns={columns}
        working={working}
        rowIdKey="idRepositorioItem"
        direction={direction}
        orderBy={orderBy}
        onSortChange={onSortChange}
        onBottomScroll={fetchMore}
      />
    </>
  );
};
