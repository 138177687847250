/*
 * @param {string[]}  paths - Los paths sobre los cuales se quieren realizar las búsquedas de coincidencias.
 * @param {string=} searhValue - Valor devuelto por el input de búsqueda
 * @param {string=} searchType - Se utiliza para especificar el tipo de busqueda,
 * 	- specific se utiliza para conseguir resultados que contengan todas las coincidencias del
 *    search value.
 *  - fuzzy: conseguira cualquier resultado que por lo menos contenga uno de los valores del searchValue
 * @returns {} Devuelve un objeto que contiene las combinaciones de las palabras de búsqueda y los paths
 * en los que se quiere buscar la coincidencia
 */

export type Param = { path: string; value: string; method: string };
export type ParamsObject = {
  [key: number]: Param[];
};

const INITIAL_INDEX_FILTER = 10;

export const setSearchParamsMultipleWords = (
  paths: string[],
  searchValue: string,
  searchType?: 'specific' | 'fuzzy',
) => {
  const searchWords = searchValue
    ? searchValue
        .toLocaleLowerCase()
        .replace(/\s+/g, ' ')
        .split(' ')
        .filter((word) => !!word)
    : [];
  if (!!!searchType || searchType === 'specific') {
    let paramsObjectSpecific: ParamsObject = {};
    searchWords.forEach((valueToSearch: string, index: number) => {
      const key = index + INITIAL_INDEX_FILTER;
      paramsObjectSpecific[key] = [
        ...paths.map((path) => {
          return {
            path: path,
            value: valueToSearch,
            method: 'includes',
          };
        }),
      ];
    });
    return paramsObjectSpecific;
  } else if (searchType === 'fuzzy') {
    let paramsObjectFuzzy: Param[] = [];
    paths.forEach((path: string, index: number) => {
      paramsObjectFuzzy = [
        ...paramsObjectFuzzy,
        ...searchWords.map((word) => {
          return {
            path: path,
            value: word,
            method: 'includes',
          };
        }),
      ];
    });
    return { [INITIAL_INDEX_FILTER]: paramsObjectFuzzy };
  }
};
