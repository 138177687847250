import React from 'react';
import WarningIconMU from '@material-ui/icons/Warning';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import AlarmIcon from '@material-ui/icons/Alarm';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import { Grid, makeStyles, Tooltip } from '@material-ui/core';
import moment from 'moment';
import { AlumnoPresentismo } from '../../types';
import { Metadata } from 'src/app/models/presentismo_v2';

const useStyles = makeStyles({
  adaptacion: {
    color: 'orange',
  },
  ausenciaNaranja: {
    color: 'orange',
  },
  tooltipWarningIcon: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    height: 'fit-content',
    width: 'fit-content',
    padding: '1em',
    backgroundColor: '#eee',
    color: 'black',
    border: 'solid 0.5px #333',
    fontSize: '1rem',
  },
  tooltipLabel: {
    fontWeight: 'bold',
    marginRight: '1em',
  },
});
interface WarningIconProperty {
  currentWeek: any;
  data: AlumnoPresentismo;
  day: number;
}

export const WarningIcon = ({
  data,
  currentWeek,
  day,
}: WarningIconProperty) => {
  const classes = useStyles();
  const fecha = moment(currentWeek).add(day, 'days').format('YYYY-MM-DD');
  const isExceptuado = data?.exceptuados.hasOneWithinInterval(fecha);
  const metadataAdaptacion = data?.metadatas?.find((metadata: Metadata) => {
    return (
      metadata.metadataType.descripcion === 'Periodo Adaptacion' &&
      metadata.dates.includes(fecha)
    );
  });

  let iconToShow = '';
  if (isExceptuado) iconToShow = 'Exceptuados';
  if (metadataAdaptacion) iconToShow = 'Adaptacion';
  if (data?.ausencias?.ausenciaNaranja) iconToShow = 'AusenciaNaranja';
  if (data?.ausencias?.ausenciaRojo) iconToShow = 'AusenciaRojo';

  const IconsTypes = new Map([
    [
      'AusenciaNaranja',
      <div>
        <Tooltip
          placement={'right-end'}
          classes={{ tooltip: classes.tooltipWarningIcon }}
          title={
            <Grid>
              <div>
                <span className={classes.tooltipLabel}>
                  Total ausencias injustificadas:
                </span>
                {`${data?.ausencias?.count}`}
              </div>
            </Grid>
          }
        >
          <WarningIconMU className={classes.ausenciaNaranja} fontSize="large" />
        </Tooltip>
      </div>,
    ],
    [
      'AusenciaRojo',
      <div>
        <Tooltip
          placement={'right-end'}
          classes={{ tooltip: classes.tooltipWarningIcon }}
          title={
            <Grid>
              <div>
                <span className={classes.tooltipLabel}>
                  Total ausencias injustificadas:
                </span>
                {`${data?.ausencias?.count}`}
              </div>
            </Grid>
          }
        >
          <WarningIconMU
            className={classes.ausenciaRoja}
            color={'error'}
            fontSize="large"
          />
        </Tooltip>
      </div>,
    ],

    ['Exceptuados', <ErrorOutlineIcon color={'primary'} fontSize="large" />],
    [
      'Adaptacion',
      <div>
        <Tooltip
          placement={'right-end'}
          classes={{ tooltip: classes.tooltipWarningIcon }}
          title={
            <Grid>
              <div>
                <span className={classes.tooltipLabel}>Hora de Entrada:</span>
                {`${metadataAdaptacion?.data?.horaDeEntrada}`}
              </div>
              <div>
                <span className={classes.tooltipLabel}>Hora de Salida:</span>
                {`${metadataAdaptacion?.data?.horaDeSalida}`}
              </div>
              <div>
                <span className={classes.tooltipLabel}>Fecha Desde:</span>
                {`${metadataAdaptacion?.dates[0]}`}
              </div>
              <div>
                <span className={classes.tooltipLabel}>Fecha Hasta:</span>
                {`${
                  metadataAdaptacion?.dates[
                    metadataAdaptacion?.dates?.length - 1
                  ]
                }`}
              </div>
            </Grid>
          }
        >
          <AlarmIcon className={classes.adaptacion} fontSize="large" />
        </Tooltip>
      </div>,
    ],
    ['Emergencia', <AnnouncementIcon color={'error'} fontSize="large" />],
  ]);

  return (
    <>
      <Tooltip
        title={
          <Grid container>
            {isExceptuado && IconsTypes.get('Exceptuados')}
            {metadataAdaptacion && IconsTypes.get('Adaptacion')}
            {data?.ausencias?.ausenciaNaranja &&
              IconsTypes.get('AusenciaNaranja')}
            {data?.ausencias?.ausenciaRojo && IconsTypes.get('AusenciaRojo')}
          </Grid>
        }
      >
        {IconsTypes.get(iconToShow) ?? <></>}
      </Tooltip>
    </>
  );
};
