import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Grid, makeStyles } from '@material-ui/core';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useLibbyFetch } from 'src/commons/hooks';
import {
  Footer,
  InfoTable,
  OptionsModal,
  OptionsReportsModal,
  useTabBarValue,
} from 'src/commons/components';
import { AnyObject, LibbyObject } from 'src/commons';
import { alumnEnrolledStatus } from 'src/commons/const';
import { useRolesContext } from 'src/context/RolesContext';
import confirmDialog from 'src/commons/services/confirmDialog';
import customFormDialog from 'src/commons/services/customFormDialog';
import { useDebounce } from 'src/commons/hooks/useDebounce';
import { useLegajoLogic } from 'src/screens/Private/LegajoAlumno';
import { useSnackbar } from 'notistack';
import {
  useAlumnoMovimientoCustomLibbyFetch,
  useAlumnoMovimientoCustomDAO,
  useTipoInformesLibbyFetch,
  useInformesLibbyFetch,
  useInformesHistoryLibbyFetch,
} from 'src/app/business';
import { primary } from 'src/theme/colors';
import { RegisteredAlumnModal } from '../components';
import { FilterTablesRegistered, SeccionSelect } from './components';
import { useRegisteredEntitiesCall } from './hooks';
import {
  AlumnoMovimiento,
  CicloAdultos,
  CicloLectivo,
  CicloLectivoAdultos,
  Nivel,
  Turno,
} from 'src/app/models';

const useStyles = makeStyles({
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
  infoTableCustom: {
    marginBottom: '80px',
  },
  alumnoButton: {
    color: primary.lightBlue,
    margin: '0',
    background: 'none',
    border: 'none',
    textAlign: 'left',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

const RegisteredAdultsRaw = ({ libby }: LibbyObject) => {
  const roleContext = useRolesContext();
  const { params } = useTabBarValue();
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const { userInfo } = roleContext;
  const { localizacionId, nivel: levelId } = roleContext.selectedRole;
  const filterLocalization = useMemo(
    () => [{ path: 'seccion.localizacion', value: localizacionId }],
    [localizacionId],
  );
  const [direction, setDirection] = React.useState<'asc' | 'desc'>('asc');
  const [alumnRows, setAlumnRows] = useState<any>([]);
  const [alumnsWithSections, setAlumnsWithSections] = useState<any>([]);
  const [orderBy, setOrderBy] = useState('alumno.persona.apellido');
  const filterDefaultLevel = useMemo(
    () => [{ path: 'seccion.nivel', value: levelId }],
    [levelId],
  );
  const { current: filterMatriculation } = useRef([
    { path: 'alumno.estadoMatricula', value: alumnEnrolledStatus.MATRICULADO },
  ]);
  const [formValues, setFormValues] = useState({
    [`planEstudioNivel`]: '',
    [`seccion.nombreSeccion`]: params.nombreSeccion || '',
  });
  const [isSavingMatriculacion, setIsSavingMatriculacion] = useState(false);

  const [search, setSearch] = useState('');
  const searchDebounced = useDebounce(search, 1000);
  const filterSearch = useMemo(
    () =>
      !searchDebounced
        ? []
        : [
            {
              path: 'alumno.persona.apellido',
              value: searchDebounced,
              method: 'includes',
            },
            {
              path: 'alumno.persona.nombre',
              value: searchDebounced,
              method: 'includes',
            },
            {
              path: 'alumno.persona.documento',
              value: searchDebounced,
              method: 'includes',
            },
            // { path: 'seccion.nivel.descripcionNivel', value: searchDebounced, method: 'includes' },
            // { path: 'seccion.turno.descripcionTurno', value: searchDebounced, method: 'includes' },
            // { path: 'cicloLectivo.anio', value: searchDebounced, method: 'includes' },
          ],
    [searchDebounced],
  );

  const filterTables = useCallback(() => {
    if (formValues.planEstudioNivel || formValues[`seccion.nombreSeccion`]) {
      const toReformFormValues = Object.entries(formValues).map(
        (filter: AnyObject) =>
          filter[1] && [{ path: filter[0], value: filter[1] }],
      );
      return {
        filterLocalization,
        filterDefaultLevel,
        filterSearch,
        filterMatriculation,
        ...toReformFormValues,
      };
    }
    return {
      0: filterLocalization,
      level: filterDefaultLevel,
      1: filterSearch,
    };
  }, [
    filterDefaultLevel,
    filterLocalization,
    filterSearch,
    filterMatriculation,
    formValues,
  ]);

  const paramsFetchAlumno = useMemo(
    () => ({
      orderBy,
      direction,
      filter: filterTables(),
    }),
    [direction, filterTables, orderBy],
  );

  const paramsFetchPlanEstudioNivel = useMemo(
    () => ({
      daoName: 'localizacion_plan_estudio_nivel',
      filter: {
        0: [{ path: 'planEstudioNivel.modalidadNivel.nivel', value: levelId }],
        1: [{ path: 'localizacion', value: localizacionId }],
      },
    }),
    [localizacionId, levelId],
  );

  const [informeFilter, setInformeFilter] = useState<Object>({});
  const { data: studyPlanContent } = useLibbyFetch(
    libby,
    paramsFetchPlanEstudioNivel,
  );

  const { data: seccion } = useLibbyFetch(libby, { daoName: 'seccion' });
  const {
    data: alumnoMovimiento = [],
    working,
    fetchMore,
    reFetch,
  } = useAlumnoMovimientoCustomLibbyFetch(paramsFetchAlumno);

  const [registersCount, setRegistersCount] = useState(0);
  const { data: tipoInformes } = useTipoInformesLibbyFetch();
  const alumnoMovimientoDAO = useAlumnoMovimientoCustomDAO();
  const { data: informes } = useInformesHistoryLibbyFetch({
    filter: informeFilter,
  });

  useEffect(() => {
    alumnoMovimientoDAO
      .getTotalAmount(filterTables())
      .then((response: number) => setRegistersCount(response));
  }, [alumnoMovimientoDAO, filterTables]);

  const filterSection = seccion.filter(
    (item: AnyObject) => item.localizacion.idLocalizacion === localizacionId,
  );
  const handleChangeSection = useCallback(
    (e: React.FormEvent<EventTarget>, alumno: AnyObject) => {
      const target = e.target as HTMLInputElement;
      const updatedAlumnRow = alumnRows.map((item: AnyObject) => {
        if (item.alumno.idAlumno === alumno.alumno.idAlumno) {
          return { ...item, idSeccion: target.value };
        }
        return item;
      });
      setAlumnRows(updatedAlumnRow);
      const updatedAlumn = { ...alumno, idSeccion: target.value };
      if (!alumnsWithSections.length) {
        setAlumnsWithSections([updatedAlumn]);
      } else {
        const newArray = alumnsWithSections.reduce(
          (acc: any, item: AnyObject) => {
            if (
              acc.findIndex(
                (i: AnyObject) =>
                  i.idAlumno?.idAlumno === item.idAlumno?.idAlumno,
              ) !== -1 ||
              // @ts-ignore
              updatedAlumn.idAlumno.idAlumno === item.idAlumno.idAlumno
            ) {
              return [...acc, item];
            }
            return acc;
          },
          [updatedAlumn],
        );
        setAlumnsWithSections(newArray);
        return newArray;
      }
    },
    [alumnsWithSections, alumnRows],
  );
  const removeAlumn = useCallback(
    async (
      nombre: string,
      idAlumnoMovimiento: number,
      idAlumno: number,
      alumnoMovimiento: AlumnoMovimiento,
    ) => {
      try {
        const confirm = await confirmDialog.show({
          title: 'Dar de baja',
          content: `¿Estás seguro que desea desmatricular a ${nombre}?`,
          confirmText: 'Confirmar',
          cancelText: 'Cancelar',
        });
        if (confirm) {
          customFormDialog.show({
            title: 'Motivo de baja',
            renderComponent: (
              <RegisteredAlumnModal
                alumn={idAlumno}
                alumnMovement={idAlumnoMovimiento}
                reFetch={reFetch}
                alumnoMovimiento={alumnoMovimiento}
                localizacionId={localizacionId}
                setLoading={setIsSavingMatriculacion}
              />
            ),
            sizeWidth: 'md',
          });
        }
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Ha ocurrido un error al intentar guardar', {
          variant: 'error',
        });
      }
    },
    [reFetch, enqueueSnackbar, localizacionId, setIsSavingMatriculacion],
  );

  const mapData = useRegisteredEntitiesCall();

  useEffect(() => {
    if (!mapData.working) {
      const informeFilterParams: [] = [];
      const alumnos = alumnoMovimiento.map((item) => {
        const {
          alumno,
          idAlumnoMovimiento,
          cicloLectivo: idCicloLectivo,
        } = item;
        const { nombre, apellido, documento } = item.alumno.persona;
        const {
          idSeccion,
          division,
          cicloLectivoAdultos: idCicloLectivoAdultos,
          turno: idTurno,
          nivel: idNivel,
          cicloAdultos: idCicloAdultos,
        } = item.seccion;
        const cicloLectivo = mapData.cicloLectivo.find(
          (_item: CicloLectivo) => +_item.idCicloLectivo === +idCicloLectivo,
        ) || { anio: '-', idCicloLectivo };
        const { anio } = cicloLectivo;
        const cicloAdultos = mapData.cicloAdultos.find(
          (_item: CicloAdultos) => +_item.idCicloAdultos === +idCicloAdultos,
        ) || { idCicloAdultos, descripcion: '-' };
        const cicloLectivoAdultos = mapData.cicloLectivoAdultos.find(
          (_item: CicloLectivoAdultos) =>
            +_item.idCicloLectivoAdultos === +idCicloLectivoAdultos,
        ) || { idCicloLectivoAdultos, descripcionCicloLectivoAdulto: '-' };
        const _nivel = mapData.nivel.find(
          (_item: Nivel) => +_item.idNivel === +idNivel,
        ) || { idNivel, descripcionNivel: '-' };
        const _turno = mapData.turno.find(
          (_item: Turno) => +_item.idTurno === +idTurno,
        ) || { idTurno, descripcionTurno: '-' };
        informeFilterParams.push({ path: 'alumno', value: alumno.idAlumno });
        return {
          alumno,
          idAlumnoMovimiento,
          nombre: `${apellido} ${nombre}`,
          documento,
          idSeccion,
          division,
          descripcionNivel: _nivel?.descripcionNivel,
          descripcionTurno: _turno?.descripcionTurno,
          idNivel: _nivel?.idNivel,
          idCicloAdultos: cicloAdultos?.idCicloAdultos,
          descripcionCicloAdultos: cicloAdultos?.descripcion,
          cicloLectivo: `${cicloLectivoAdultos?.descripcionCicloLectivoAdulto} ${anio}`,
          selectSeccion: null,
        };
      });
      setInformeFilter({ 0: informeFilterParams });
      setAlumnRows(alumnos);
    }
  }, [
    alumnoMovimiento,
    mapData.cicloAdultos,
    mapData.cicloLectivo,
    mapData.cicloLectivoAdultos,
    mapData.nivel,
    mapData.turno,
    mapData.working,
  ]);

  const { toLegajoAlumno } = useLegajoLogic();

  const rows = useMemo(
    () =>
      alumnRows.map((item: AnyObject) => {
        const getInformeByIdAlumno = (idAlumno: string) =>
          informes
            ? informes.filter(
                (informe: any) => informe.alumno.idAlumno === idAlumno,
              )
            : [];

        return {
          ...item,
          selectedSeccion: (
            <SeccionSelect
              content={seccion.filter(
                (_item: AnyObject) =>
                  _item.nivel?.idNivel === item.idNivel &&
                  _item.cicloAdultos?.idCicloAdultos === item.idCicloAdultos &&
                  _item.localizacion.idLocalizacion === localizacionId,
              )}
              value={item.idSeccion}
              handleChange={(e: React.FormEvent<EventTarget>) =>
                handleChangeSection(e, item)
              }
            />
          ),
          report: (
            <Box>
              <OptionsReportsModal
                reportTypes={tipoInformes}
                reports={getInformeByIdAlumno(item.alumno.idAlumno)}
                userInfo={userInfo}
              />
            </Box>
          ),
          icon: (
            <OptionsModal
              options={[
                {
                  label: 'Desmatricular',
                  onClick: () =>
                    removeAlumn(
                      item.nombre,
                      item.idAlumnoMovimiento,
                      item.alumno.idAlumno,
                      item as AlumnoMovimiento,
                    ),
                },
                {
                  label: 'Ver legajo',
                  onClick: () => toLegajoAlumno(item.alumno.idAlumno),
                },
              ]}
            />
          ),
          nombre: (
            <button
              type="button"
              className={classes.alumnoButton}
              onClick={() => toLegajoAlumno(item.alumno.idAlumno)}
            >
              {item?.nombre}
            </button>
          ),
        };
      }),
    [
      alumnRows,
      seccion,
      classes.alumnoButton,
      localizacionId,
      handleChangeSection,
      removeAlumn,
      toLegajoAlumno,
      tipoInformes,
      userInfo,
      informes,
    ],
  );

  const column = [
    {
      id: 'nombre',
      label: 'Apellido y nombre',
      width: '15%',
      orderById: 'alumno.persona.apellido',
    },
    {
      id: 'documento',
      label: 'Documento',
      width: '5%',
      orderById: 'alumno.persona.documento',
    },
    {
      id: 'descripcionNivel',
      label: 'Nivel',
      width: '5%',
      orderById: 'seccion.nivel.descripcionNivel',
    },
    {
      id: 'cicloLectivo',
      label: 'Tipo de ciclo lectivo',
      width: '10%',
      orderById: 'seccion.cicloLectivo',
    },
    {
      id: 'descripcionTurno',
      label: 'Turno',
      width: '5%',
      orderById: 'seccion.turno.descripcionTurno',
    },
    {
      id: 'descripcionCicloAdultos',
      label: 'Ciclo',
      width: '5%',
      orderById: 'seccion.cicloAdultos.descripcion',
    },
    {
      id: 'selectedSeccion',
      label: 'Cambio de ciclo',
      width: '10%',
      hideSortIcon: true,
      noSort: true,
    },
    {
      id: 'report',
      label: 'Informes',
      width: '1%',
      hideSortIcon: true,
      noSort: true,
    },
    {
      id: 'icon',
      label: '',
      width: '1%',
      hideSortIcon: true,
      noSort: true,
    },
  ];

  const handleChange = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setDirection(newDirection);
    setOrderBy(newOrderBy);
  };

  const handleSave = useCallback(async () => {
    const confirm = await confirmDialog.show({
      title: 'Cambiar Sección',
      content:
        '¿Está seguro que desea cambiar la sección de el/los alumno(s) seleccionado(s)?',
      confirmText: 'Confirmar',
      cancelText: 'Cancelar',
    });
    if (confirm) {
      for (let i = 0; i < alumnsWithSections.length; i += 1) {
        const obj = {
          idAlumnoMovimiento: alumnsWithSections[i].idAlumnoMovimiento,
          seccion: alumnsWithSections[i].seccion,
        };
        // eslint-disable-next-line no-await-in-loop
        await alumnoMovimientoDAO.save(obj);
        reFetch();
      }
    }
  }, [alumnoMovimientoDAO, alumnsWithSections, reFetch]);

  const handleChangeSearch = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setSearch(target.value);
  };

  const buttonConfig: any = [
    {
      title: 'Actualizar',
      handleOnClick: () => handleSave(),
      size: 'medium',
    },
  ];

  const flatten = () =>
    studyPlanContent.map((data: AnyObject) => ({
      ...data,
      ...data.planEstudioNivel,
      ...data.planEstudioNivel.planEstudio,
    }));

  return (
    <>
      <FilterTablesRegistered
        studyPlanContent={flatten()}
        section={filterSection}
        handleChange={handleChange}
        formValues={formValues}
        handleChangeSearch={handleChangeSearch}
        search={search}
        registersCount={registersCount}
      />
      <>
        <InfoTable
          rows={rows}
          columns={column}
          working={working || isSavingMatriculacion}
          direction={direction}
          onSortChange={handleRequestSort}
          arraySelect={seccion}
          onBottomScroll={fetchMore}
          onSelect={() => console.log('HOLI')}
          customStyle={classes.infoTableCustom}
        />
        <Grid className={classes.footer}>
          <Footer buttonConfig={buttonConfig} />
        </Grid>
      </>
    </>
  );
};

export const RegisteredAdults = DatabaseConnector(RegisteredAdultsRaw)(
  'nivel',
  'alumno',
  'localizacion_plan_estudio_nivel',
  'inscripcion_alumno',
  'alumno_movimiento',
  'seccion',
);
