import React, { useMemo } from 'react';
import { Box, Button } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { InfoTableColumn } from 'src/commons/types/infoTableTypesProps';
import {
  EspacioCurricularSeccion,
  Seccion,
} from '../../../../../../app/models';
import {
  OptionsModal,
  OptionsModalOption,
} from '../../../../../../commons/components/OptionsModal';
import { useSeccionAulaVirtualDAO } from '../../../../../../app/business';
import confirmDialog from '../../../../../../commons/services/confirmDialog';
import { BLOCKER_ID } from '../../../../../../platform/permission/const';
import { loadingModal } from '../../../../../../commons/services/loadingModal';

export interface AulasVirtualesTableRow extends Seccion {
  espacioCurricularSeccion?: EspacioCurricularSeccion;
}

export const useAulasVirtualesTableColumns = (reFetch: () => void) => {
  const { enqueueSnackbar } = useSnackbar();
  const seccionAulaVirtualDAO = useSeccionAulaVirtualDAO();

  const actionButton = useMemo<OptionsModalOption<EspacioCurricularSeccion>[]>(
    () => [
      {
        id: BLOCKER_ID.ADD_CLASSROOMS,
        label: 'Crear Aula',
        hide: (data: EspacioCurricularSeccion) =>
          !!data.seccionAulaVirtuals.length,
        onClick: async (data) => {
          try {
            loadingModal.open();
            await seccionAulaVirtualDAO.save({
              espacioCurricularSeccion: data.idEspacioCurricularSeccion,
            });
            reFetch();
            enqueueSnackbar('Se ha creado el aula virtual', {
              variant: 'success',
            });
          } catch (e) {
            console.log(e);
            enqueueSnackbar('Error al crear el aula virtual', {
              variant: 'error',
            });
          } finally {
            loadingModal.close();
          }
        },
      },
      {
        label: 'Actualizar Participantes',
        hide: (data: EspacioCurricularSeccion) =>
          !data.seccionAulaVirtuals.length,
        onClick: async (data) => {
          try {
            loadingModal.open();
            await seccionAulaVirtualDAO.save({
              ...data.seccionAulaVirtuals[0],
              ...data,
            });
            reFetch();
            enqueueSnackbar('Se han actualizado los participantes', {
              variant: 'success',
            });
          } catch (e) {
            console.log(e);
            enqueueSnackbar('Error al actualizar los participantes', {
              variant: 'error',
            });
          } finally {
            loadingModal.close();
          }
        },
      },
      {
        label: 'Eliminar Aula',
        hide: true, // TODO: habilitar esto cuando el backend funcione
        // hide: (data: EspacioCurricularSeccion) => !data.classrooms.length,
        onClick: async (data) => {
          try {
            const confirm = await confirmDialog.show({
              title: 'Confirmación',
              content: `Estas seguro de eliminar esta aula? Este acción no se puede deshacer`,
              confirmText: 'Eliminar',
              cancelText: 'No',
            });
            if (confirm) {
              loadingModal.open();
              await seccionAulaVirtualDAO.remove(data.seccionAulaVirtuals[0]);
              enqueueSnackbar('Se ha eliminado el aula correctamente', {
                variant: 'success',
              });
              reFetch();
            }
          } catch (error) {
            enqueueSnackbar('Error al eliminar el aula', { variant: 'error' });
          } finally {
            loadingModal.close();
          }
        },
      },
    ],
    [seccionAulaVirtualDAO, reFetch, enqueueSnackbar],
  );

  return useMemo<InfoTableColumn<EspacioCurricularSeccion>[]>(
    () => [
      {
        id: 'nombreSeccion',
        label: 'Nombre',
        width: '10%',
        orderById: 'seccion.nombreSeccion',
        render: (vale, row) => (
          <Box whiteSpace="nowrap">{`${row.seccion.nombreSeccion}`}</Box>
        ),
      },
      {
        id: 'espacioCurricularSeccion',
        label: 'Aula virtual',
        width: '10%',
        hideSortIcon: true,
        noSort: true,
        render: (value, row) => {
          if (!row?.seccionAulaVirtuals?.length) {
            return 'Aula no Creada';
          }
          return (
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                window.open(
                  row.seccionAulaVirtuals.length
                    ? row.seccionAulaVirtuals[0].classroomMetadata.alternateLink
                    : '',
                  '_blank',
                )
              }
            >
              Ir al aula
            </Button>
          );
        },
      },
      {
        id: 'icono',
        label: '',
        width: '5%',
        hideSortIcon: true,
        noSort: true,
        style: { textAlign: 'right' },
        render: (value: any, row: EspacioCurricularSeccion) => (
          <Box whiteSpace="nowrap" textAlign="right">
            <OptionsModal options={actionButton} data={row} />
          </Box>
        ),
      },
    ],
    [actionButton],
  );
};
