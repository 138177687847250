import { LibbyFetchDAO } from './LibbyFetchDAO';

export class CalificacionesPrimarioDAO extends LibbyFetchDAO {
  constructor() {
    super('calificaciones_primario', 'idCalificacionPrimario');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getById(id: any) {
    return this.query().equals('alumno', id).run();
  }
}
