import React from 'react';
import { Grid, Typography } from '@material-ui/core';

export interface PanelWithTitleProps {
  title: string | JSX.Element;
  icon: JSX.Element;
  children: JSX.Element | JSX.Element[];
}

export const PanelWithTitle = ({
  title,
  icon,
  children,
}: PanelWithTitleProps) => (
  <Grid container spacing={2} direction="column">
    <Grid item>
      <Grid container spacing={2} alignItems="center">
        {!!icon && <Grid item>{icon}</Grid>}
        <Grid item>
          {typeof title !== 'string' ? (
            title
          ) : (
            <Typography variant="h4">{title}</Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
    <Grid item>{children}</Grid>
  </Grid>
);
