import React, { useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { ROL, SimpleSelect } from 'src/commons';
import { useConvivenciaCustomLibbyFetch } from 'src/app/business';
import { useGetRows } from '../../../../hooks';
import { isEqual } from 'lodash';
import { setSearchParamsMultipleWords } from 'src/utils/setSearchParamsMultipleWords';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
import { useRolesContext } from 'src/context/RolesContext';

const opcionesMock = [
  { descripcion: '-' },
  { descripcion: 'Insuficiente (I)' },
  { descripcion: 'Regular (R)' },
  { descripcion: 'Bueno (B)' },
  { descripcion: 'Muy Bueno (MB)' },
  { descripcion: 'Sobresaliente (S)' },
];

export const useConvivenciaRows = (filter: any) => {
  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();
  const [readyToClose, setReadyToClose] = useState(false);
  const {
    selectedRole: { rol },
  } = useRolesContext();

  const filterParams = useMemo(
    () => ({
      0: [{ path: 'seccion', value: filter.seccion }],
      1: [{ path: 'calificacion.periodo', value: filter.periodo }],
    }),
    [filter.seccion, filter.periodo],
  );

  const response = useConvivenciaCustomLibbyFetch({
    aspect: 'default',
    filter: filterParams,
    enabled: !!filter.periodo && !!filter.seccion,
    limit: 100,
  });
  const {
    rows: _rows,
    changeRow,
    working,
    fetchMore,
    data,
    reFetch,
    hasCalifications,
  } = useGetRows(response, filter.search);

  const isClosed = useMemo(
    () => _rows.some((row: any) => row.nota.abierto === false),
    [_rows],
  );

  useEffect(() => {
    const submitRows = _rows.filter((row: any) => {
      let isUpdated = false;
      const currentCalification = data?.find(
        (d: any) => d.idCalificacion === row.idCalificacion,
      );
      if (currentCalification?.nota) {
        isUpdated = !isEqual(currentCalification?.nota?.data, row.nota.data);
      } else if (Object.keys(row.nota.data).length) {
        isUpdated = true;
      }
      return isUpdated;
    });
    setReadyToClose(!submitRows.length);
  }, [_rows, data]);

  const rows = useMemo(() => {
    const isDisabled =
      _rows.some(
        (c) => c.nota?.abierto !== undefined && c.nota?.abierto === false,
      ) || Number(rol) === ROL.COORDINADOR_JE;

    return _rows.map((row, index: number) => {
      return {
        nombre: `${row?.apellido}, ${row?.nombre}`,
        acuerdosGrupales: (
          <Grid style={{ minWidth: 150 }}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={opcionesMock}
              value={row.nota?.data?.acuerdosGrupales}
              name="acuerdosGrupales"
              key={`acuerdosGrupales-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          </Grid>
        ),
        situacionesDeJuego: (
          <Grid style={{ minWidth: 150 }}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={opcionesMock}
              value={row.nota?.data?.situacionesDeJuego}
              name="situacionesDeJuego"
              key={`situacionesDeJuego-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          </Grid>
        ),
        resuelveConflictos: (
          <Grid style={{ minWidth: 150 }}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={opcionesMock}
              value={row.nota?.data?.resuelveConflictos}
              name="resuelveConflictos"
              key={`resuelveConflictos-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          </Grid>
        ),
        repetuosoConAdultos: (
          <Grid style={{ minWidth: 150 }}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={opcionesMock}
              value={row.nota?.data?.repetuosoConAdultos}
              name="repetuosoConAdultos"
              key={`repetuosoConAdultos-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          </Grid>
        ),
        repetuosoConSusPares: (
          <Grid style={{ minWidth: 150 }}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={opcionesMock}
              value={row.nota?.data?.repetuosoConSusPares}
              name="repetuosoConSusPares"
              key={`repetuosoConSusPares-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          </Grid>
        ),
      };
    });
  }, [rol, changeRow, _rows]);

  return {
    rows,
    _rows,
    working,
    fetchMore,
    data,
    reFetch,
    readyToClose,
    isClosed,
    hasCalifications,
  };
};
