import { useMemo } from 'react';
import { primary } from 'src/theme/colors';

const headerStyle = {
  color: primary.lightBlue,
  textAlign: 'center',
};

export const useQueHaceresTableColumns = () => {
  return useMemo(
    () => [
      {
        id: 'nombre',
        label: 'Alumno',
        width: '10%',
        hideSortIcon: true,
        style: headerStyle,
      },
      {
        id: 'organizaTareas',
        label: 'Se organiza para realizar sus tareas en clase.',
        width: '10%',
        hideSortIcon: true,
        style: headerStyle,
      },
      {
        id: 'expresaIdeas',
        label: 'Expresa sus ideas en clase.',
        width: '10%',
        hideSortIcon: true,
        style: headerStyle,
      },
      {
        id: 'asumeResponsabilidad',
        label:
          'Asume con responsabilidad su participación en tareas de equipo.',
        width: '10%',
        hideSortIcon: true,
        style: headerStyle,
      },
      {
        id: 'realizaTareas',
        label: 'Realiza tareas por sí mismo/a.',
        width: '10%',
        hideSortIcon: true,
        style: headerStyle,
      },
      {
        id: 'manifiestaInteres',
        label:
          'Manifiesta interés por aprender y pregunta cuando no comprende.',
        width: '10%',
        hideSortIcon: true,
        style: headerStyle,
      },
      {
        id: 'logrosDificultades',
        label: 'Identifica lo aprendido: sus logros y sus dificultades.',
        width: '10%',
        hideSortIcon: true,
        style: headerStyle,
      },
    ],
    [],
  );
};
