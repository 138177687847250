import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class SecundarioDAO extends LibbyFetchDAO {
  constructor() {
    super('secundario', 'idConocimiento');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
