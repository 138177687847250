import { usePersonaLibbyCall, usePersonaDAO } from 'src/app/business';
import { useEffect, useCallback } from 'react';
import { useProfileContext } from 'src/lib/profiles/ProfileContext';
import { useRolesContext } from 'src/context/RolesContext';
import { LibbyObject } from '../types';

export const useAutoregistracionCheck = () => {
  const { userInfo } = useRolesContext();
  const { setProfile } = useProfileContext();
  const personaDAO = usePersonaDAO();

  const checkPersona = useCallback(
    async (userId: number) => {
      try {
        const persona = await personaDAO.getByCuentaId(userId);
        if (!persona) {
          setProfile('autoregistracion');
        }
      } catch (e) {
        console.log(e);
      }
    },
    [personaDAO, setProfile],
  );

  return checkPersona;
};
