import React from 'react';
import moment from 'moment';
import { Grid, Typography, ListItemText, makeStyles } from '@material-ui/core';
import PrintIcon from '@material-ui/icons/Print';
import { grey, primary } from 'src/theme/colors';
import { MainButton } from 'src/commons';
import { useGeneralContext } from 'src/context/GeneralContext';

const useStyles = makeStyles(() => ({
  headerText: {
    fontFamily: 'Open Sans',
    color: grey.medium,
    fontSize: 16,
    fontWeight: 600,
    paddingRight: 6,
  },
  textValue: {
    color: grey.medium,
    fontFamily: 'Open Sans',
    fontSize: 16,
  },
  containerHeader: {
    paddingTop: 30,
    paddingBottom: 30,
    marginLeft: 40,
  },
  print: {
    position: 'relative',
    top: 5,
    marginRight: 6,
  },
  hiddenPrint: {
    '@media print': {
      display: 'none',
    },
  },
}));

const customButton = {
  button: {
    fontFamily: 'Open Sans',
    color: primary.lightBlue,
    fontSize: 16,
    fontWeight: 600,
    border: 'none',
    '&:hover': {
      background: primary.whiteLight,
      color: grey.medium,
    },
  },
  icon: {
    marginTop: 0,
    position: 'relative',
    top: -2,
  },
};

export interface HeaderFeedbackProps {
  data: {
    disciplina: string;
    area: string;
    seccion: string;
    orientacion: string;
    fecha: string;
    autor: string;
    nombre: string;
    descripcion: string;
  };
  toPrint?: boolean;
}
export const HeaderFeedback = ({
  data: {
    disciplina,
    area,
    seccion,
    fecha,
    autor,
    nombre,
    descripcion,
    orientacion,
  },
  toPrint,
}: HeaderFeedbackProps) => {
  const classes = useStyles();
  const date = moment(fecha).format('DD/MM/YYYY - HH:mm');
  const {
    generalState: {
      espacioCurricular: {
        planificacion: {
          print: { idPlanificacion: printIdPlanning },
        },
      },
    },
  } = useGeneralContext();
  return (
    <Grid container className={classes.containerHeader}>
      <Grid xs={3}>
        <ListItemText
          primary={
            <>
              <Typography
                component="span"
                color="textPrimary"
                className={classes.headerText}
              >
                Disciplina:
              </Typography>
              <span className={classes.textValue}>{disciplina}</span>
            </>
          }
        />
        <ListItemText
          primary={
            <>
              <Typography
                component="span"
                color="textPrimary"
                className={classes.headerText}
              >
                {!orientacion ? 'Área: ' : 'Orientación: '}
              </Typography>
              <span className={classes.textValue}>
                {!orientacion ? area : orientacion}
              </span>
            </>
          }
        />
      </Grid>
      <Grid xs={3}>
        {seccion && (
          <ListItemText
            primary={
              <>
                <Typography
                  component="span"
                  color="textPrimary"
                  className={classes.headerText}
                >
                  Sección:
                </Typography>
                <span className={classes.textValue}>{seccion}</span>
              </>
            }
          />
        )}
        <ListItemText
          primary={
            <>
              <Typography
                component="span"
                color="textPrimary"
                className={classes.headerText}
              >
                Autor:
              </Typography>
              <span className={classes.textValue}>{autor || ''}</span>
            </>
          }
        />
      </Grid>
      <Grid xs={3}>
        <ListItemText
          primary={
            <>
              <Typography
                component="span"
                color="textPrimary"
                className={classes.headerText}
              >
                Fecha:
              </Typography>
              <span className={classes.textValue}>{date}</span>
            </>
          }
        />
        <ListItemText
          primary={
            <>
              <Typography
                component="span"
                color="textPrimary"
                className={classes.headerText}
              >
                Nombre:
              </Typography>
              <span className={classes.textValue}>{nombre || '-'}</span>
            </>
          }
        />
      </Grid>
      {descripcion && (
        <Grid xs={3} direction="row">
          <ListItemText
            primary={
              <>
                <Typography
                  component="span"
                  color="textPrimary"
                  className={classes.headerText}
                >
                  Descripción:
                </Typography>
                <span className={classes.textValue}>{descripcion || '-'}</span>
              </>
            }
          />
        </Grid>
      )}
      {toPrint && !printIdPlanning && (
        <Grid xs={3} className={classes.hiddenPrint}>
          <MainButton
            title="Imprimir"
            type="info"
            iconLeft={<PrintIcon className={classes.print} />}
            customStyle={customButton.button}
            handleOnClick={() => window.print()}
            customStyleIcon={customButton.icon}
          />
        </Grid>
      )}
    </Grid>
  );
};
