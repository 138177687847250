import { DatosAcompanante, DatosAtencion } from 'src/app/models';
import { toBase64 } from 'src/utils';

type InitialValueFile = {
  files: File[];
  value: string;
};

const getFileProperties = async (file: InitialValueFile) => {
  const base64: string = await toBase64(file.files[0]);
  const pieces = base64.split('base64,');
  return {
    file_name: file.value.split('\\')[2],
    base64: pieces[pieces.length - 1],
    mime_type: file.files[0].type,
  };
};

export const buildFichaDocumentacion = async (doc: any) => {
  const fichaDocumentacion = await Promise.all(
    doc.datosPrincipales.map(async (d: any) => {
      let file_name;
      let base64;
      let mime_type;
      if (d.file) {
        const {
          file_name: fileName,
          base64: base,
          mime_type: mimeType,
        } = await getFileProperties(d.file);
        file_name = fileName;
        base64 = base;
        mime_type = mimeType;
      }
      return {
        tipoDocumento: d?.tipoDocumento,
        descripcion: d?.descripcion,
        fechaEmision: d?.fechaEmision,
        fechaEntrega: d?.fechaEntrega,
        tipoDireccion: d?.tipoDireccion,
        requiereActualizacion: d?.requiereActualizacion,
        metadata: d?.metadata || {},
        url: d?.url,
        file_name,
        base64,
        mime_type,
      };
    }),
  );
  return fichaDocumentacion;
};

export const buildAptoFisico = async (salud?: any) => {
  let file_name;
  let base64;
  let mime_type;
  if (salud.datosAptoFisico.file) {
    const {
      file_name: fileName,
      base64: base,
      mime_type: mimeType,
    } = await getFileProperties(salud.datosAptoFisico.file);
    file_name = fileName;
    base64 = base;
    mime_type = mimeType;
  }

  return {
    motivo: salud?.datosAptoFisico?.motivo,
    aptoDesde: salud?.datosAptoFisico?.aptoDesde,
    aptoHasta: salud?.datosAptoFisico?.aptoHasta,
    estaEximido: salud?.datosAptoFisico?.estaEximido, // boolean!
    url: salud?.datosAptoFisico?.url,
    file_name,
    base64,
    mime_type,
  };
};

export const buildSaludAcompanantes = (datosAcompanante?: DatosAcompanante[]) =>
  datosAcompanante?.map((acompanante: DatosAcompanante) => {
    return {
      apellido: acompanante.apellido,
      nombres: acompanante.nombres,
      ocupaVacante: acompanante.ocupaVacante,
      telefonoAcompanante: acompanante.telefonoAcompanante,
      tipoAcompanante: acompanante.tipoAcompanante,
    };
  });

export const buildSaludAtencion = (datosAtencion?: DatosAtencion[]) =>
  datosAtencion?.map((atencion: DatosAtencion) => {
    return {
      atencionDesde: atencion.atencionDesde,
      atencionHasta: atencion.atencionHasta,
      establecimiento: atencion.establecimiento,
    };
  });
