import React, { useState } from 'react';
import { Grid } from '@material-ui/core';
import {
  DependenciaFuncionalSelect,
  DistritoEscolarSelect,
} from 'src/app/business';
import { TableToolbar } from '../../../../../commons/components/TableToolbar';
import { useInputState } from '../../../../../commons/hooks/useInputState';
import {
  useDebounceCall,
  useInputOnChangeValue,
} from '../../../../../commons/hooks';

export type Filter = {
  search: string;
  idDistritoEscolar: string;
  idDependenciaFuncional: string;
};

export interface LocalizacionPickerTableHeaderProps {
  filter: Filter;
  onChangeFilter: (value: Filter) => void;
}

export const LocalizacionPickerTableHeader = ({
  filter,
  onChangeFilter,
}: LocalizacionPickerTableHeaderProps) => {
  const [search, onSearchChange] = useInputState<string>(filter.search);
  const [idDistritoEscolar, setIdDistritoEscolar] = useState(
    filter.idDistritoEscolar,
  );
  const [idDependenciaFuncional, setIdDependenciaFuncional] = useState(
    filter.idDependenciaFuncional,
  );
  const onDistritoEscolarChange = useInputOnChangeValue(setIdDistritoEscolar);
  const onDependenciaFuncionalChange = useInputOnChangeValue(
    setIdDependenciaFuncional,
  );

  useDebounceCall(
    () => {
      if (
        filter.search !== search ||
        filter.idDistritoEscolar !== idDistritoEscolar ||
        filter.idDependenciaFuncional !== idDependenciaFuncional
      ) {
        onChangeFilter({ search, idDistritoEscolar, idDependenciaFuncional });
      }
    },
    300,
    [filter, onChangeFilter, search, idDistritoEscolar, idDependenciaFuncional],
  );

  return (
    <Grid container justify="flex-end" alignItems="flex-end" spacing={2}>
      <Grid item xs={3}>
        <DistritoEscolarSelect
          title="Distrito Escolar"
          placeholder="Seleccioná un distrito"
          value={idDistritoEscolar}
          handleChange={onDistritoEscolarChange}
        />
      </Grid>
      <Grid item xs={3}>
        <DependenciaFuncionalSelect
          title="Dependencia Funcional"
          placeholder="Seleccioná una dependencia funcional"
          value={idDependenciaFuncional}
          handleChange={onDependenciaFuncionalChange}
        />
      </Grid>
      <Grid item sm={6}>
        <TableToolbar search={search} onSearchChange={onSearchChange} />
      </Grid>
    </Grid>
  );
};
