import { PermissionEndpointMethod } from 'src/app/models/security/PermissionEndpointMethod';
import { makeBusinessEntity } from 'src/lib/libby';
import { PermissionEndpointMethodDAO } from 'src/platform/libby/dao';

const DAO_NAME = 'permission_endpoint_method';
const {
  hook: { libbyDAO },
} = makeBusinessEntity<PermissionEndpointMethod, PermissionEndpointMethodDAO>(
  DAO_NAME,
  {},
);

export const usePermissionEndpointMethodDAO = libbyDAO;
