import React, { useContext, createContext, useMemo } from 'react';
import { useParams } from 'react-router';
import { useEspacioCurricularLibbyFetch } from 'src/app/business';
import { useEspaciocurricularseccioncustomGetRequest } from 'src/app/business/businessCustomEndpoints/espaciocurricularseccioncustom';
import { EspacioCurricular, EspacioCurricularSeccion } from 'src/app/models';
import { useRolesContext } from 'src/context/RolesContext';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';

const ECContext = createContext<{
  ec: EspacioCurricular;
  working: boolean;
  ecsWorking: boolean;
  ecs: EspacioCurricularSeccion[];
}>({
  ec: {},
  working: false,
  ecsWorking: false,
  ecs: [],
});

export const useECContext = () => useContext(ECContext);

const ECProvider = ({ children }: { children: React.ReactChild }) => {
  const {
    selectedRole: { nivel, localizacionId },
  } = useRolesContext();
  const { idEspacioCurricular } = useParams<{ idEspacioCurricular: string }>();

  const { cicloLectivo } = useColegioEfectivizadoContext();
  const { data: ecs = [], working: ecsWorking } =
    useEspaciocurricularseccioncustomGetRequest<EspacioCurricularSeccion[]>({
      url: `/api/public/custom/espaciocurricularseccioncustom?localizacion=${localizacionId}&&cicloLectivo=${cicloLectivo?.idCicloLectivo}&nivel=${nivel}`,
      aspect: 'limit_by_espacio',
      autoCall: true,
    });

  const filter = useMemo(
    () => ({
      espacio: [
        {
          path: 'idEspacioCurricular',
          value: idEspacioCurricular,
        },
      ],
    }),
    [idEspacioCurricular],
  );

  const { data: [espacioCurricular] = [], working } =
    useEspacioCurricularLibbyFetch({
      filter,
      aspect: 'sidemenu',
    });
  return (
    <ECContext.Provider
      value={{
        ec: espacioCurricular,
        working,
        ecs,
        ecsWorking,
      }}
    >
      {children}
    </ECContext.Provider>
  );
};

export default ECProvider;
