import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { EstadoPaseAnio } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'estado_pase_anio';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEstadoPaseAnio',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEstadoPaseAnio',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EstadoPaseAnio
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EstadoPaseAnio,
      hoc: { ...defaultOptions.hoc, ...businessOptions.EstadoPaseAnio.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EstadoPaseAnio>(DAO_NAME, optionsToUse);

// hooks
export const useEstadoPaseAnioFetchById = fetchById;
export const useEstadoPaseAnioLibbyCall = libbyCall;
export const useEstadoPaseAnioDAO = libbyDAO;
export const useEstadoPaseAnioLibbyFetch = libbyFetch;

// components/hoc
export const EstadoPaseAnioAutocomplete = entityAutocomplete;
export const EstadoPaseAnioCall = renderPropCall;
export const EstadoPaseAnioById = renderPropFetchById;
export const EstadoPaseAnioSelect = simpleSelect;
export const EstadoPaseAnioAccordionSelector = accordionSelector;
export const EstadoPaseAnioSelectedChips = selectedChips;

// context
// ByIdContext
export const useEstadoPaseAnioByIdContext = useByIdContext;
export const EstadoPaseAnioByIdProvider = ByIdProvider;
export const EstadoPaseAnioByIdContext = ByIdContext;
// GeneralContext
export const useEstadoPaseAnioContext = useGeneralContext;
export const EstadoPaseAnioProvider = GeneralProvider;
export const EstadoPaseAnioContext = GeneralContext;
