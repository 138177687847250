import { Container } from '@material-ui/core';
import moment from 'moment';
import React, { useMemo } from 'react';
import { FormContextProvider } from '../../../../../../lib/templates';
import { AlumnoMap } from '../../types';
import { ObservacionesForm } from './components';
import { ObservacionesTemplate } from './template';
import { IObservacionesInitialValue, Presentes } from './types';

interface ObservacionesModalProps {
  daySelected: moment.Moment;
  turno: number;
  currentWeek?: moment.Moment;
  alumno: AlumnoMap;
  onSubmit?: (values: IObservacionesInitialValue) => void;
  hasJornadaExtendidaDisabled?: boolean;
  isDisabledReportModal?: boolean;
  selectedEspacioCurricular?: string;
  dayFormatted: string;
}

export enum DAY_WEEK {
  LUNES = 'lunes',
  MARTES = 'martes',
  MIERCOLES = 'miércoles',
  JUEVES = 'jueves',
  VIERNES = 'viernes',
}

const initialValues: IObservacionesInitialValue = {
  selectedTurno: '',
  presentes: [],
};

export const ObservacionesModal = ({
  daySelected,
  turno,
  currentWeek = moment(),
  alumno,
  onSubmit = () => {},
  hasJornadaExtendidaDisabled = false,
  isDisabledReportModal = false,
  selectedEspacioCurricular,
  dayFormatted,
}: ObservacionesModalProps) => {
  const _initialValues = useMemo(() => {
    const _presentes = alumno.presentismo
      .filter((presente) => presente.fecha === daySelected.format('YYYY-MM-DD'))
      .map<Presentes>(({ idPresente, turno, observacion }) => ({
        idPresente: idPresente || '',
        idTurno: turno?.idTurno.toString() || '',
        observacion: observacion || '',
      }));
    const _turno = _presentes[0]?.idTurno || '';
    const _initial: IObservacionesInitialValue = {
      ...initialValues,
      presentes: _presentes,
      selectedTurno: _turno,
    };
    return _initial;
  }, [daySelected, alumno]);

  return (
    <Container maxWidth="lg">
      <FormContextProvider
        initialValues={_initialValues}
        onSubmit={onSubmit}
        template={ObservacionesTemplate}
      >
        <ObservacionesForm
          daySelected={dayFormatted}
          turnoSeccion={turno}
          currentWeek={currentWeek}
          alumno={alumno}
          hasJornadaExtendidaDisabled={hasJornadaExtendidaDisabled}
          isDisabledReportModal={isDisabledReportModal}
          selectedEspacioCurricular={selectedEspacioCurricular}
        />
      </FormContextProvider>
    </Container>
  );
};
