import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { Accion } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'acciones';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idAcciones',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idAcciones',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Acciones
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Acciones,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Acciones.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
    selectInput,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Accion>(DAO_NAME, optionsToUse);

// hooks
export const useAccionesFetchById = fetchById;
export const useAccionesLibbyCall = libbyCall;
export const useAccionesDAO = libbyDAO;
export const useAccionesLibbyFetch = libbyFetch;

// components/hoc
export const AccionesAutocomplete = entityAutocomplete;
export const AccionesCall = renderPropCall;
export const AccionesById = renderPropFetchById;
export const AccionesSelect = simpleSelect;
export const AccionesSelectInput = selectInput;
export const AccionesAccordionSelector = accordionSelector;
export const AccionesSelectedChips = selectedChips;

// context
// ByIdContext
export const useAccionesByIdContext = useByIdContext;
export const AccionesByIdProvider = ByIdProvider;
export const AccionesByIdContext = ByIdContext;
// GeneralContext
export const useAccionesContext = useGeneralContext;
export const AccionesProvider = GeneralProvider;
export const AccionesContext = GeneralContext;
