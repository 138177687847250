import React, { useCallback, useMemo } from 'react';
import { Box, Grid } from '@material-ui/core';
import { useInformesStyles } from './useInformesStyles';
import { InputIndicadorTipoSelect } from '../components/InputIndicadorTipoSelect';
import { InputIndicadorTipoModal } from '../components/InputIndicadorTipoModal';
import { InputIndicadorTipoTextoModal } from '../components/InputIndicadorTipoTextoModal';
import { InputIndicadorTipoAdjunto } from '../components/InputIndicadorTipoAdjunto';
import { InformescCustom } from 'src/app/models';
import {
  IndicadorTipoAdjunto,
  IndicadorTipoModal,
  IndicadorTipoSelect,
  IndicadorTipoTextoModal,
} from '../../informesTypes';

type SomeIndicador =
  | IndicadorTipoSelect[]
  | IndicadorTipoModal[]
  | IndicadorTipoTextoModal[]
  | IndicadorTipoAdjunto[];

export const useInformeCuatrimestralCuartoBimestreColumns = ({
  handleDataChange,
  isInformesClosed,
  establecimientoEfectivizado,
  config: { indicadores },
  periodoActual,
}: {
  handleDataChange: (
    data: { name: string; value: string },
    selectedRow: InformescCustom,
  ) => void;
  isInformesClosed: boolean;
  establecimientoEfectivizado: boolean;
  config: {
    indicadores: SomeIndicador;
  };
  periodoActual: number;
}) => {
  const classes = useInformesStyles();
  const renderIndicadores = useCallback(
    (indicadores: SomeIndicador) => {
      return indicadores.map((indicador) => ({
        key: indicador.id,
        id: indicador.id,
        label: <Box style={{ textAlign: 'center' }}>{indicador.label}</Box>,
        width: indicador.width,
        hideSortIcon: indicador.hideSortIcon,
        orderById: indicador.orderById,
        style: classes.header,
        render: (_: unknown, row: InformescCustom) => {
          switch (indicador.tipoIndicador) {
            case 'select':
              return (
                <InputIndicadorTipoSelect
                  isInformesClosed={isInformesClosed}
                  indicador={indicador as IndicadorTipoSelect}
                  row={row}
                  handleDataChange={handleDataChange}
                />
              );
            case 'modal':
              return (
                <InputIndicadorTipoModal
                  isInformesClosed={isInformesClosed}
                  indicador={indicador as IndicadorTipoModal}
                  row={row}
                  handleDataChange={handleDataChange}
                />
              );
            case 'texto_modal':
              return (
                <InputIndicadorTipoTextoModal
                  isInformesClosed={isInformesClosed}
                  indicador={indicador as IndicadorTipoTextoModal}
                  row={row}
                  handleDataChange={handleDataChange}
                />
              );
            case 'adjunto':
              return (
                <InputIndicadorTipoAdjunto
                  isInformesClosed={isInformesClosed}
                  indicador={indicador as IndicadorTipoAdjunto}
                  row={row}
                  handleDataChange={handleDataChange}
                />
              );
            default:
          }
        },
      }));
    },
    [classes.header, handleDataChange, isInformesClosed],
  );

  const renderMergedHeadersCellColumns = useCallback(
    (indicadores: SomeIndicador) => {
      const columnsFr = indicadores.map(() => '1fr').join(' ');
      return [
        {
          id: 'mergedHeaders',
          label: (
            <Grid>
              <Box
                style={{
                  display: 'grid',
                  gridTemplateColumns: columnsFr,
                }}
              >
                {indicadores.map((indicador) => (
                  <Box
                    key={indicador.id}
                    style={{ display: 'inline-block', textAlign: 'center' }}
                  >
                    {indicador.label}
                  </Box>
                ))}
              </Box>
            </Grid>
          ),
          hideSortIcon: true,
          orderById: '',
          style: classes.header,
          render: (_: unknown, row: InformescCustom) => (
            <Box
              style={{
                display: 'grid',
                gridTemplateColumns: columnsFr,
              }}
            >
              {indicadores.map((indicador) => (
                <InputIndicadorTipoTextoModal
                  key={indicador.id}
                  isInformesClosed={isInformesClosed}
                  indicador={indicador as IndicadorTipoTextoModal}
                  row={row}
                  handleDataChange={handleDataChange}
                />
              ))}
            </Box>
          ),
        },
      ];
    },
    [classes.header, handleDataChange, isInformesClosed],
  );

  const columns = useMemo(
    () => [
      {
        id: 'nombre',
        label: 'Alumno',
        width: '20%',
        orderById: 'alumno.persona.apellido',
        hideSortIcon: true,
        style: classes.header,
        render: (nombre: string, row: InformescCustom) =>
          `${row?.apellido}, ${nombre}`,
      },
      .../*!establecimientoEfectivizado
        ? [...renderIndicadores(indicadores)]
        : */ [
        ...renderIndicadores(
          indicadores.filter((indicador) =>
            [1].includes(indicador.orderInTable),
          ),
        ),
        ...renderMergedHeadersCellColumns(
          indicadores.filter((indicador) =>
            [2, 3].includes(indicador.orderInTable),
          ),
        ),
        ...renderIndicadores(
          indicadores.filter((indicador) =>
            [4, 5, 6, 7].includes(indicador.orderInTable),
          ),
        ),
      ],
    ],
    [
      classes.header,
      //establecimientoEfectivizado,
      renderIndicadores,
      indicadores,
      renderMergedHeadersCellColumns,
    ],
  );

  return columns;
};
