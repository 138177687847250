import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Genero } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'genero';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idGenero',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idGenero',
      labelKey: 'descripcion',
    },
    withSimpleSelect: {
      name: 'genero',
      title: 'Genero',
      placeholder: 'Seleccionar Género',
      labelKey: 'descripcionGenero',
      valueKey: 'idGenero',
    },
  },
};

const optionsToUse = !businessOptions.Genero
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Genero,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Genero.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Genero>(DAO_NAME, optionsToUse);

// hooks
export const useGeneroFetchById = fetchById;
export const useGeneroLibbyCall = libbyCall;
export const useGeneroDAO = libbyDAO;
export const useGeneroLibbyFetch = libbyFetch;

// components/hoc
export const GeneroAutocomplete = entityAutocomplete;
export const GeneroCall = renderPropCall;
export const GeneroById = renderPropFetchById;
export const GeneroSelect = simpleSelect;
export const GeneroAccordionSelector = accordionSelector;
export const GeneroSelectedChips = selectedChips;

// context
// ByIdContext
export const useGeneroByIdContext = useByIdContext;
export const GeneroByIdProvider = ByIdProvider;
export const GeneroByIdContext = ByIdContext;
// GeneralContext
export const useGeneroContext = useGeneralContext;
export const GeneroProvider = GeneralProvider;
export const GeneroContext = GeneralContext;
