/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router';
import { useRolesContext } from 'src/context/RolesContext';
import { useGeneralContext } from 'src/context';
import {
  FormInput,
  FormAutocomplete,
} from '../../../../../../../../../lib/templates/components';
import { Seccion } from '../../../../../../../../../app/models';
import {
  usePlantaLibbyFetch,
  useProyeccionEstadoLibbyFetch,
} from '../../../../../../../../../app/business';
import { useArealInterArealForm } from '../../useArealInterArealForm';
import { useGruposSeccion } from '../../../../hooks';
import { INITIAL_YEAR, useCicloLectivoLibbyFetch } from 'src/app/business';
import { useField } from 'react-final-form-hooks';
import { grey } from '@material-ui/core/colors';
import { SimpleSelect } from 'src/commons';

const customStyles = {
  container: {
    padding: '10px',
    marginBottom: 70,
  },
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  inputStyle: { padding: 10, marginTop: 23 },
};

const useStyles = makeStyles(customStyles);

export const InformacionGeneral = (): JSX.Element => {
  const classes = useStyles();
  // @ts-ignore
  const { idEspacioCurricular } = useParams();
  const { form, errors } = useArealInterArealForm();
  const { userInfo, selectedRole } = useRolesContext();
  const { generalState } = useGeneralContext();

  const filterByEspacio = useMemo(
    () => ({
      espacio: [{ path: 'espacioCurricular', value: idEspacioCurricular }],
      cuentas: [
        { path: 'personalEducativo.persona.cuentas', value: userInfo.id },
      ],
      seccion: generalState
        ? generalState.espacioCurricular.planificacion.selected.seccion
            ?.filter((item: Seccion) => typeof item.idSeccion === 'number')
            .map((item2: Seccion) => ({
              path: 'seccion.idSeccion',
              value: item2.idSeccion || 0,
            }))
        : [],
    }),
    [idEspacioCurricular, userInfo.id, generalState],
  );
  const { data: plantas = [], working: plantasWorking } = usePlantaLibbyFetch({
    filter: filterByEspacio,
    aspect: 'limit_plan_with_seccion',
  });

  const secciones = plantas.reduce<Seccion[]>((acumn, planta) => {
    if (!acumn.some((e) => e.idSeccion === planta.seccion.idSeccion)) {
      acumn.push({ ...planta.seccion });
    }
    return acumn;
  }, []);

  const { transformeGrupos, working } = useGruposSeccion([idEspacioCurricular]);

  const seccionGrupos = [...secciones, ...transformeGrupos];

  const filterProyeccionEstado = useMemo(
    () => ({
      0: [{ path: 'localizacion', value: selectedRole.localizacionId }],
    }),
    [selectedRole.localizacionId],
  );

  const { data = [] } = useProyeccionEstadoLibbyFetch({
    limit: 1,
    filter: filterProyeccionEstado,
    orderBy: 'cicloLectivo',
    direction: 'desc',
  });

  useEffect(() => {
    form.change(
      'informacionGeneral.cicloLectivo',
      data[0]?.cicloLectivo?.idCicloLectivo,
    );
  }, [data, form]);

  return (
    <Grid
      container
      direction="column"
      spacing={4}
      className={classes.container}
    >
      <Grid item>
        {!plantasWorking && !working && !!seccionGrupos.length && (
          <FormAutocomplete
            label="Secciones"
            name="informacionGeneral.secciones"
            form={form}
            errors={errors?.informacionGeneral?.secciones}
            options={seccionGrupos || []}
            optionLabelKey="nombreSeccion"
            getOptionSelected={(option: Seccion, value: any) =>
              option?.idSeccion.toString() === value?.idSeccion.toString()
            }
            optionToValue={(option: Seccion) => option?.idSeccion.toString()}
            valueToOption={(value: string[], options: Seccion[]) =>
              value.map((item) =>
                options.find((option) => option?.idSeccion.toString() === item),
              )
            }
            multiple
          />
        )}
      </Grid>
      <Grid item>
        <FormInput
          label="Tema"
          name="informacionGeneral.tema"
          form={form}
          placeholder="Escribí el tema"
        />
      </Grid>
      <Grid item>
        <FormInput
          label="Problema"
          name="informacionGeneral.problema"
          form={form}
          placeholder="Escribí el problema"
        />
      </Grid>
      <Grid item>
        <FormInput
          label="Preguntas adicionales"
          name="informacionGeneral.preguntasAdicionales"
          form={form}
          placeholder="Escribí preguntas adicionales"
        />
      </Grid>
    </Grid>
  );
};
