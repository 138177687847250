import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class InformeEpaDAO extends LibbyFetchDAO {
  constructor() {
    super('informesepa', 'idInformeEpa');
  }

  getAll() {
    return this.query().limit(0, 100).run();
  }
}
