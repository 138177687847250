import React from 'react';
import { SelectedChips, SelectedChipsProps } from 'src/commons';

export type WithSelectedChipsPropsProps<T> = Pick<
  SelectedChipsProps<T>,
  'onChange' | 'onItemDelete' | 'items'
>;

export interface WithSelectedChipsPropsDefaultProps<T> {
  labelKey: keyof T;
  idKey: keyof T;
}

export function withSelectedChips<T>(
  daoName: string,
  defaultProps: WithSelectedChipsPropsDefaultProps<T>,
) {
  return (props: WithSelectedChipsPropsProps<T>) => {
    return <SelectedChips<T> {...defaultProps} {...props} />;
  };
}
