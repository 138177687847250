import React from 'react';
import { TabBar, useRouteScreenTitle } from 'src/commons';
import { PermissionBlocker } from 'src/lib/permission';
import { BLOCKER_ID } from 'src/platform/permission/const';
import {
  Matriculacion,
  Calificaciones,
  Boletines,
  Proyeccion,
  Presentismo,
} from './Tabs';

export const Reportes = () => {
  useRouteScreenTitle('Reportes');

  return (
    <PermissionBlocker id={BLOCKER_ID.REPORTS}>
      <TabBar
        content={[
          {
            title: 'Matriculación',
            component: <Matriculacion />,
          },
          {
            title: 'Calificación',
            component: <Calificaciones />,
          },
          {
            title: 'Boletines',
            component: <Boletines />,
          },
          {
            title: 'Proyecciones',
            component: <Proyeccion />,
          },
          {
            title: 'Presentismo',
            component: <Presentismo />,
          },
        ]}
      />
    </PermissionBlocker>
  );
};
