import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { CondAnioAnt } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'cond_anio_ant';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idCondAnioAnt',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idCondAnioAnt',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.CondAnioAnt
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.CondAnioAnt,
      hoc: { ...defaultOptions.hoc, ...businessOptions.CondAnioAnt.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<CondAnioAnt>(DAO_NAME, optionsToUse);

// hooks
export const useCondAnioAntFetchById = fetchById;
export const useCondAnioAntLibbyCall = libbyCall;
export const useCondAnioAntDAO = libbyDAO;
export const useCondAnioAntLibbyFetch = libbyFetch;

// components/hoc
export const CondAnioAntAutocomplete = entityAutocomplete;
export const CondAnioAntCall = renderPropCall;
export const CondAnioAntById = renderPropFetchById;
export const CondAnioAntSelect = simpleSelect;
export const CondAnioAntAccordionSelector = accordionSelector;
export const CondAnioAntSelectedChips = selectedChips;

// context
// ByIdContext
export const useCondAnioAntByIdContext = useByIdContext;
export const CondAnioAntByIdProvider = ByIdProvider;
export const CondAnioAntByIdContext = ByIdContext;
// GeneralContext
export const useCondAnioAntContext = useGeneralContext;
export const CondAnioAntProvider = GeneralProvider;
export const CondAnioAntContext = GeneralContext;
