import { ProyeccionEstado } from 'src/app/models';
import { LibbyFetchDAO } from './LibbyFetchDAO';
import moment from 'moment';

export class ProyeccionEstadoDAO extends LibbyFetchDAO {
  static CURRENT_YEAR = moment().year();
  static PENDIENTE = 1;
  static FINALIZADA = 2;
  static EFECTIVIZADO = 3;

  constructor() {
    super('proyeccion_estado', 'idProyeccionEstado');
  }

  async isEfectivizado(nivel: string, localizacion: string) {
    const filter = {
      localizacion: [{ path: 'localizacion', value: localizacion }],
      nivel: [{ path: 'nivel', value: nivel }],
    };

    const query = this.applyFilters(this.query(), filter)
      .orderBy('cicloLectivo.anio', 'desc')
      .limit(0, 1);

    try {
      const temp: ProyeccionEstado[] = await query.run();
      return {
        establecimientoEfectivizado:
          temp.length > 0
            ? temp[0].cicloLectivo.anio >= ProyeccionEstadoDAO.CURRENT_YEAR
            : null,
        cicloLectivo: temp.length > 0 ? temp[0].cicloLectivo : null,
        data: temp.length > 0 ? temp[0] : null,
      };
    } catch (error) {
      console.error(
        'Ocurrió un error al verificar la efectivización del establecimiento: %cVerifique el request proyeccionestado',
        'color:white;font-size:16px;background-color:red;padding: 0.3em;',
      );
      return this.errors.proyeccion_estado;
    }
  }
}
