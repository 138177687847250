import { MenuItem } from '../../../../../commons/const/ui/menu';
import {
  UsuariosList,
  UsuariosRoles,
  UsuariosRolForm,
} from '../../../../../screens/Private/ConfigurationAdmin/Usuarios';

export const routes: MenuItem[] = [
  {
    key: 'configurationAdminUsuariosRolForm',
    basePath: '/roles/:idUsuario/form/:idRolEstablecimiento',
    target: {
      component: UsuariosRolForm,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Configuarciones Admin Usuarios Rol Form',
    children: [],
  },
  {
    key: 'configurationAdminUsuariosRoles',
    basePath: '/roles/:idUsuario',
    target: {
      component: UsuariosRoles,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Configuarciones Admin Usuarios Roles',
    children: [],
  },
  {
    key: 'configurationAdminUsuariosList',
    basePath: '/',
    target: {
      component: UsuariosList,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Configuarciones Admin Usuarios',
    children: [],
    default: true,
  },
];
