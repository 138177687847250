import { makeStyles, Typography } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect } from 'react';
import { InfoTable, Loading } from 'src/commons';
import { useActitudFrenteAlAprendizajeColumns } from '../hooks/useActitudFrenteAlAprendizajeColumns';
import { grey, primary } from 'src/theme/colors';
import { useInformePPSContext } from '../InformePipContext';
import { usePPSFormContext } from '../PPSFormProvider';
import { Pps } from 'src/app/models';
import { TABS_NAMES } from '../helpers';

export const useStyles = makeStyles({
  label: {
    marginBottom: 5,
    marginTop: 10,
    fontSize: 18,
    fontWeight: 500,
    color: grey.medium,
  },
  isIncompleteClose: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    paddingTop: '17px',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    lineHeight: '22px',
  },
  incompleteMessageClose: {
    borderRadius: 4,
    display: 'flex',
    backgroundColor: primary.errorMedium,
    flexFlow: 'column',
    color: grey.dark,
    padding: '10px 20px',
  },
});

export const ActitudFrenteAlAprendizaje = () => {
  const classes = useStyles();
  const { informePps } = useInformePPSContext();
  const { pps, isSaving, setCurrentTab, currentTab } = usePPSFormContext();
  const row = informePps?.row ?? [];

  useEffect(() => {
    setCurrentTab(TABS_NAMES.ACTITUD);
  }, [setCurrentTab, currentTab]);

  const columns = useActitudFrenteAlAprendizajeColumns(informePps);

  return (
    <>
      {isSaving ? (
        <Loading />
      ) : (
        <>
          {Boolean(pps[0]) && !(pps[0] as Pps).abierto && (
            <div className={classes.isIncompleteClose}>
              <Typography className={classes.incompleteMessageClose}>
                El informe está cerrado.
              </Typography>
            </div>
          )}
          <Typography className={classes.label}>
            {`${informePps?.row?.alumno?.persona?.apellido}, ${informePps?.row?.alumno?.persona?.nombre} - ${informePps?.row?.alumno?.persona?.documento} - ${informePps?.row?.seccion?.nombreSeccion}`}
          </Typography>
          <InfoTable rows={[row]} columns={columns} />
        </>
      )}
    </>
  );
};
