import { CommonEndpointDAO } from './CommonEndpointDAO';

export class InformesCalificacionDAO extends CommonEndpointDAO {
  constructor() {
    super('informesmatriculacion');
  }

  async getInformes(params) {
    const queryParams = new URLSearchParams();
    queryParams.append('seccion.idLocalizacion', `${localizacionId}`);
    queryParams.append('seccion.idNivel', `${nivelId}`);
    queryParams.append('seccion.idAnio', `${formValues[`seccion.anio`]}`);
    queryParams.append(
      'seccion.idSeccion',
      `${section ? section : formValues[`seccion.nombreSeccion`]}`,
    );
    queryParams.append('seccion.idTurno', `${formValues[`seccion.turno`]}`);
    queryParams.append('seccion.jornada', `${formValues[`seccion.jornada`]}`);
    queryParams.append(
      'cicloLectivo',
      `${ciclo ? ciclo : formValues[`cicloLectivo.idCicloLectivo`]}`,
    );
    queryParams.append('text', searchDebounced);
    queryParams.append('limit', `${rowsPerPage}`);
    queryParams.append('offset', `${rowsPerPage * page}`);
    queryParams.append('sort', `${orderBy}`);
    queryParams.append('sortOrder', `${direction}`);
    queryParams.append('sortOrder', `${direction}`);
  }
}
