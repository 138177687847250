import React, { useCallback, useState } from 'react';
import { useMemo } from 'react';
import { TabBar, useRouteScreenTitle } from 'src/commons';
import { AccionesSeleccionadas } from './tabs/AccionesSeleccionadas';
import { Inscripciones } from './tabs/Inscripciones';
import { OfertaPreseleccion } from './tabs/OfertaPreseleccion';
import { useRolesContext } from 'src/context/RolesContext';
import { ROL } from 'src/commons/const';
import { Devoluciones as EscuelaDevoluciones } from './tabs/Devoluciones';
import { Actividades, Cronograma, Devoluciones } from '../AcapEstudiante';
import { Autoevaluacion } from '../AcapEstudiante/Autoevaluacion';
import { EstudianteProvider } from '../AcapEstudiante/contexts/EstudianteContext';
import { ConfiguracionForm } from './Configuracion/components/ConfiguracionForm';
import { EscuelaProvider } from './context/EscuelaContext';
import { AccionSeleccionada, GruposInscripcionAcumulate } from './types';
import { PresentismoWrapper } from './components/PresentismoWrapper';

export const Tabs = () => {
  useRouteScreenTitle('Actividades de aproximación');
  const {
    selectedRole: { rol },
  } = useRolesContext();

  const [accionSeleccionadaRow, setAccionSeleccionadaRow] =
    useState<AccionSeleccionada | null>(null);
  const [shoudGoToTabOne, setShouldGoToTabOne] = useState(
    ROL.REFERENTE_ACAP === Number(rol),
  );

  const editGroup = useCallback((row: GruposInscripcionAcumulate) => {
    setAccionSeleccionadaRow({ ...row, isEditing: true });
    setShouldGoToTabOne(true);
  }, []);

  const allTabs = useMemo(() => {
    return Number(rol) === ROL.ESTUDIANTE
      ? [
          {
            title: 'Actividades',
            component: <Actividades />,
          },
          {
            title: 'Cronograma',
            component: <Cronograma />,
          },
          {
            title: 'Autoevaluación',
            component: <Autoevaluacion />,
          },
          {
            title: 'Devoluciones',
            component: <Devoluciones />,
          },
        ]
      : [
          {
            title: 'Oferta',
            component: <OfertaPreseleccion />,
          },
          {
            title: 'Acciones Seleccionadas',
            component: (
              <AccionesSeleccionadas
                accionSeleccionadaRow={accionSeleccionadaRow}
                setAccionSeleccionadaRow={setAccionSeleccionadaRow}
              />
            ),
          },
          {
            title: 'Inscripciones',
            component: <Inscripciones editGroup={editGroup} />,
          },
          ...(rol === ROL.COORDINADOR_ORIENTACION.toString()
            ? [
                {
                  title: 'Configuración',
                  component: <ConfiguracionForm />,
                },
              ]
            : []),
          {
            title: 'Asistencia',
            component: <PresentismoWrapper />,
          },
          {
            title: 'Devoluciones',
            component: (
              <EscuelaProvider>
                <EscuelaDevoluciones />
              </EscuelaProvider>
            ),
          },
        ];
  }, [accionSeleccionadaRow, editGroup, rol]);

  return Number(rol) === ROL.ESTUDIANTE ? (
    <EstudianteProvider>
      <TabBar
        content={allTabs}
        shoudGoToTabOne={shoudGoToTabOne}
        setShouldGoToTabOne={setShouldGoToTabOne}
      />
    </EstudianteProvider>
  ) : (
    <TabBar
      content={allTabs.filter((tab) => {
        if (Number(rol) !== ROL.PRECEPTOR) {
          return tab;
        } else {
          return /inscripciones|asistencia/gi.test(tab.title);
        }
      })}
      shoudGoToTabOne={shoudGoToTabOne}
      setShouldGoToTabOne={setShouldGoToTabOne}
    />
  );
};
