import { LibbyFetchDAO } from './LibbyFetchDAO';

export class TiposAccionesDAO extends LibbyFetchDAO {
  constructor() {
    super('tipos_acciones', 'idTipoAccion');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
