import { MenuItem } from '../../../../commons/const/ui/menu';
import { routes } from './routes';

export const PpsMenu: MenuItem = {
  key: 'pps',
  basePath: '/pps',
  enabled: true,
  icon: 'List',
  text: 'Pps',
  children: routes,
};
