import { LibbyFetchDAO } from './LibbyFetchDAO';

export class EspecialesBycNivelDAO extends LibbyFetchDAO {
  constructor() {
    super('especiales_byc_nivel', 'idEspecialesBycNivel');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
