import {
  Box,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { ReportProblemRounded } from '@material-ui/icons';
import { getIconColor } from '../TomaPresentismo/components/PresentismoTable/components';
import { AlertaMensaje, AlertasType } from '../TomaPresentismo/types';

const getAlertsMessage = (alert: AlertasType): AlertaMensaje | null => {
  if (!alert) return null;
  const [alertaUno, alertaDos, alertaTres, alertaCuatro, alertaCinco] = alert;

  if (alertaCinco.active) return { alerta: alertaCinco, texto: 'Alerta 5' };
  if (alertaCuatro.active) return { alerta: alertaCuatro, texto: 'Alerta 4' };
  if (alertaTres.active) return { alerta: alertaTres, texto: 'Alerta 3' };
  if (alertaDos.active) return { alerta: alertaDos, texto: 'Alerta 2' };
  if (alertaUno.active) return { alerta: alertaUno, texto: 'Alerta 1' };
  return null;
};

const useStyles = makeStyles({
  root: {
    border: '1px solid black',
    borderRadius: 8,
    padding: 0,
  },
  item: {
    lineHeight: 1,
  },
  head: {
    lineHeight: 2,
  },
  icon: {
    height: 20,
    marginRight: 5,
  },
  listIcon: {
    minWidth: 10,
  },
});

interface TooltipAlertProp {
  alerts: AlertasType;
}

const alertLabels = {
  countAusencias: 'Ausencias',
  countAusenciaPresenciaEnClase: 'Ausencias con presencia en clase',
  countIngresoTardio: 'Ingresos tardíos',
  countRetiroAnticipado: 'Retiros anticipados ',
};

export const TooltipAlertMessage = ({ alerts }: TooltipAlertProp) => {
  const classes = useStyles();
  const result = getAlertsMessage(alerts);

  let countAusencias,
    countAusenciaPresenciaEnClase,
    countIngresoTardio,
    countRetiroAnticipado;

  if (result) {
    const { alerta } = result;
    ({
      countAusencias,
      countAusenciaPresenciaEnClase,
      countIngresoTardio,
      countRetiroAnticipado,
    } = alerta);
  }

  return (
    <Grid container direction="column">
      <Grid item component={Box} fontFamily="Nunito,Open Sans,sans-serif">
        <ListItem dense className={classes.item}>
          <ListItemIcon className={classes.listIcon}>
            <ReportProblemRounded
              htmlColor={getIconColor(alerts)?.typography}
              className={classes.icon}
              fontSize="small"
            />
          </ListItemIcon>
          <div>
            <span
              style={{
                fontWeight: 'bold',
                fontFamily: 'Nunito,Open Sans,sans-serif',
                fontSize: 12,
                whiteSpace: 'nowrap',
              }}
            >
              {result?.texto}
            </span>
          </div>
          <ListItemText
            style={{ marginLeft: 10 }}
            primary={
              <Box width="fit-content">
                <Typography
                  component={Box}
                  fontSize={12}
                  fontWeight="bold"
                  fontFamily="Nunito,Open Sans,sans-serif"
                  noWrap
                >
                  {countAusencias !== undefined && countAusencias !== 0 ? (
                    <span>
                      {countAusencias} {alertLabels['countAusencias']}
                      <br />
                    </span>
                  ) : null}
                  {countAusenciaPresenciaEnClase !== undefined &&
                  countAusenciaPresenciaEnClase !== 0 ? (
                    <span>
                      {countAusenciaPresenciaEnClase}{' '}
                      {alertLabels['countAusenciaPresenciaEnClase']}
                      <br />
                    </span>
                  ) : null}
                  {countIngresoTardio !== undefined &&
                  countIngresoTardio !== 0 ? (
                    <span>
                      {countIngresoTardio} {alertLabels['countIngresoTardio']}
                      <br />
                    </span>
                  ) : null}
                  {countRetiroAnticipado !== undefined &&
                  countRetiroAnticipado !== 0 ? (
                    <span>
                      {countRetiroAnticipado}{' '}
                      {alertLabels['countRetiroAnticipado']}
                      <br />
                    </span>
                  ) : null}
                </Typography>
              </Box>
            }
          />
        </ListItem>
      </Grid>
    </Grid>
  );
};
