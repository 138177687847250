import { CommonEndpointDAO } from './CommonEndpointDAO';

export class ProyeccionIELBulkCustomDAO extends CommonEndpointDAO {
  constructor() {
    super('proyeccionielbulkcustom');
  }

  get(params: any = {}): any {
    return super.get(params);
  }

  post(body: Promocion.IELPostDto): any {
    return super.post(body);
  }

  put(body: any = {}): any {
    return super.put(body);
  }

  delete(body: any = {}): any {
    return super.delete(body);
  }
}
