import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { EscalafonEstablecimiento } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'escalafon_establecimiento';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEscalafonEstablecimiento',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEscalafonEstablecimiento',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EscalafonEstablecimiento
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EscalafonEstablecimiento,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.EscalafonEstablecimiento.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EscalafonEstablecimiento>(DAO_NAME, optionsToUse);

// hooks
export const useEscalafonEstablecimientoFetchById = fetchById;
export const useEscalafonEstablecimientoLibbyCall = libbyCall;
export const useEscalafonEstablecimientoDAO = libbyDAO;
export const useEscalafonEstablecimientoLibbyFetch = libbyFetch;

// components/hoc
export const EscalafonEstablecimientoAutocomplete = entityAutocomplete;
export const EscalafonEstablecimientoCall = renderPropCall;
export const EscalafonEstablecimientoById = renderPropFetchById;
export const EscalafonEstablecimientoSelect = simpleSelect;
export const EscalafonEstablecimientoAccordionSelector = accordionSelector;
export const EscalafonEstablecimientoSelectedChips = selectedChips;

// context
// ByIdContext
export const useEscalafonEstablecimientoByIdContext = useByIdContext;
export const EscalafonEstablecimientoByIdProvider = ByIdProvider;
export const EscalafonEstablecimientoByIdContext = ByIdContext;
// GeneralContext
export const useEscalafonEstablecimientoContext = useGeneralContext;
export const EscalafonEstablecimientoProvider = GeneralProvider;
export const EscalafonEstablecimientoContext = GeneralContext;
