import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ArealPlanDAO extends LibbyFetchDAO {
  constructor() {
    super('areal_plan', 'idArealPlan');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
