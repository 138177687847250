import { useState } from 'react';
import { useEstudianteContext } from '../contexts/EstudianteContext';
import { useTabBarValue } from 'src/commons';

export const useGetFilter = () => {
  const { grupos } = useEstudianteContext();
  const { params } = useTabBarValue();
  const organizacionActual =
    grupos.find((grupo) => grupo.idGrupoInscripcion === params?.id)?.ofertaGrupo
      ?.ofertaTurno?.oferta?.accion?.organizacion?.idOrganizacion ?? 0;

  const actividadActual =
    grupos.find((grupo) => grupo.idGrupoInscripcion === params?.id)?.ofertaGrupo
      ?.ofertaTurno?.oferta?.accion?.idAccion ?? '';

  const [organizacion, setOrganizacion] = useState(organizacionActual);
  const [actividad, setActividad] = useState(actividadActual);

  return {
    organizacion,
    actividad,
    setOrganizacion,
    setActividad,
  };
};
