import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { InfoTable, NIVEL, ROL, TURNOS } from '../../../../../../commons';
import {
  TipoSituacionV3SelectInput,
  MotivoTipoSituacionV3SelectInput,
} from '../../../../../../app/business/presentismo_v3';
// @ts-ignore
import { DateRangePicker } from 'react-date-range';
import { useStyles } from '../../styles';
import { getDateRangeHeader } from '../../../Justificacion/components';
import { es } from 'date-fns/locale';
import {
  SituacionGenerate,
  TIPO_SITUACIONES,
} from '../../../../../../app/models';
import { TextFieldV2 } from '../../../../../../lib/templates';
import { useColumnForm } from './hooks';
import {
  useSeccionLibbyFetch,
  useSituacionGenerateDAO,
} from '../../../../../../app/business';
import customFormDialog from '../../../../../../commons/services/customFormDialog';
import { FormState, SituacionesModalProps } from '../../types';
import moment from 'moment';
import { setWith } from 'lodash/fp';
import 'react-calendar/dist/Calendar.css';
import { cloneDeep, isEqual } from 'lodash';
import { FormStateValue } from '../../initialValue';
import { useDocumentTitlePrint } from '../../../../../../commons/hooks/useDocumentTitlePrint';
import { useSnackbar } from 'notistack';
import { usePropuestaJELibbyFetch } from 'src/app/business/presentismo_v2';
import { useLibbyCall } from '../../../../../../lib/libby';
import { getDaysByRange } from '../../../../../../utils/getDaysByRange';
import confirmDialog from '../../../../../../commons/services/confirmDialog';

export const COLOR_DAY: { [k: number]: string[] } = {
  [TIPO_SITUACIONES.EMERGENCIAS]: ['#f44336'],
  [TIPO_SITUACIONES.SUSPENSION_CLASES]: ['#e3d34a'],
};
// solo habrá una SOLA situación/motivo por TURNO y SECCION x DIA
export const SituacionesModal = ({
  onlyView = false,
  initialValue = FormStateValue,
  periodo,
  situaciones,
  rol,
  edit = false,
}: SituacionesModalProps) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [formState, setFormState] = useState<FormState>(() =>
    cloneDeep(initialValue),
  );

  let arrayFechaNoCoincidente: Date[] = [];
  const situacionGenerateDAO = useSituacionGenerateDAO();

  const { data: propuestasJE = [] } = usePropuestaJELibbyFetch({
    aspect: 'default',
  });
  const currentSituacion = useMemo(
    () =>
      situaciones.find(
        (situacion) => situacion.idSituacion === formState.idSituacion,
      ),
    [situaciones, formState],
  );

  const confirmIsSuspencion =
    Number(formState.tipoSituacion) === TIPO_SITUACIONES.SUSPENSION_CLASES;
  const confirmIsEmergencia =
    Number(formState.tipoSituacion) === TIPO_SITUACIONES.EMERGENCIAS;

  const isPrimarioInicial = useMemo(() => {
    return (
      periodo?.nivel.idNivel === NIVEL.PRIMARIO ||
      periodo?.nivel.idNivel === NIVEL.INICIAL
    );
  }, [periodo?.nivel.idNivel]);

  const filterMotivos = useMemo(
    () => ({
      motivo: [
        {
          path: 'tipoSituacion.idTipoSituacion',
          value: formState.tipoSituacion,
        },
      ],
      noMotivo: isPrimarioInicial
        ? [
            {
              path: 'motivoSituacion.idMotivoSituacion',
              value: 8,
              method: 'notEquals',
            },
          ]
        : [],
      activo: [{ path: 'activo', value: true }],
    }),
    [formState.tipoSituacion, isPrimarioInicial],
  );

  const filterPresenteCount = useMemo(
    () => ({
      secciones: [
        {
          path: 'id_seccion',
          value: formState.seccionesAfectadas.map((sa) => sa.idSeccion),
          method: 'in',
        },
      ],
      turnos: [
        {
          path: 'id_turno',
          value: [
            ...new Set(
              formState.seccionesAfectadas
                .map((sa) => {
                  if (sa.idTurno.toString() === TURNOS.DOBLE.toString()) {
                    return [TURNOS.MANIANA, TURNOS.TARDE];
                  } else {
                    return [sa.idTurno];
                  }
                })
                .flat(),
            ),
          ],
          method: 'in',
        },
      ],
      fechas: getDaysByRange(
        moment(formState.range[0].startDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        moment(formState.range[0].endDate, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        true,
      ).map((fecha) => ({
        path: 'fecha',
        value: fecha,
      })),
    }),
    [formState.range, formState.seccionesAfectadas],
  );

  const {
    data: [count] = [],
    working: workingCount,
    recall,
  } = useLibbyCall<{ countofidpresente: number }>({
    daoName: 'presente_v3',
    methodName: 'getCount',
    params: [filterPresenteCount],
    noAutoCall: true,
    aspect: 'count_presentes',
  });

  useEffect(() => {
    if (formState.seccionesAfectadas.length === 0) return;
    if (confirmIsEmergencia) return;
    recall(filterPresenteCount);
  }, [
    formState.seccionesAfectadas,
    filterPresenteCount,
    recall,
    confirmIsEmergencia,
  ]);

  const handleChange = useCallback(
    (event) => {
      const target = event.target;

      setFormState((prev) => {
        const value = setWith(() => {}, target.name, target.value, prev);
        return { ...prev, ...value };
      });
    },
    [setFormState],
  );

  const handleRangeChange = useCallback(
    (range: { selection: { startDate: Date; endDate: Date; key: string } }) => {
      if (onlyView || edit) return;
      if (
        formState.motivoTipoSituacion === '' ||
        formState.tipoSituacion === ''
      )
        return;

      let rangeTemp = range.selection;
      if (Number(formState.tipoSituacion) === TIPO_SITUACIONES.EMERGENCIAS) {
        rangeTemp.endDate = range.selection.startDate;
      }
      setFormState((prev) => ({ ...prev, range: [rangeTemp] }));
    },
    [onlyView, formState, edit],
  );

  const { data: secciones = [], working } = useSeccionLibbyFetch({
    limit: 1000,
    orderBy: 'nombreSeccion',
    aspect: 'presentismo',
  });

  const seccioneAlumnosMatriculados = useMemo(() => {
    return secciones.filter((seccion) => (seccion.matCount || 0) > 0);
  }, [secciones]);

  const columns = useColumnForm({
    formState,
    secciones,
    situaciones,
    onlyView,
    setFormState,
  });

  const handleSave = useCallback(async () => {
    if (count && count.countofidpresente > 0 && confirmIsSuspencion) {
      const confirm = await confirmDialog.show({
        title: `¿Desea continuar?`,
        content: `El día en que se quiere dar de alta una situación ya cuenta con la asistencia cargada. Si se carga una situación, la misma se perderá. ¿Desea continuar?`,
        confirmText: 'Continuar',
        cancelText: 'Cancelar',
      });
      if (confirm) {
        customFormDialog.handleConfirm({ values: formState });
      }
    } else {
      customFormDialog.handleConfirm({ values: formState });
    }
  }, [formState, count, confirmIsSuspencion]);

  const handleCancel = useCallback(() => {
    customFormDialog.handleCancel();
  }, []);
  const diabledSaved = useMemo(() => {
    if (!formState.motivoTipoSituacion || !formState.tipoSituacion) return true;
    if (formState.seccionesAfectadas.length === 0) return true;
    if (formState.seccionesAfectadas.some((sa) => sa.idTurno === ''))
      return true;
    const _initialValue: FormState = { ...FormStateValue, ...initialValue };
    let isEqualSeccions = true;

    const hasEqualLength =
      formState.seccionesAfectadas.length ===
      _initialValue.seccionesAfectadas.length;

    if (hasEqualLength) {
      // Verifico si cada objeto ees igual
      formState.seccionesAfectadas.forEach((sa) => {
        const initialSa = _initialValue.seccionesAfectadas.find(
          (_sa) => _sa.idSeccion === sa.idSeccion,
        );
        if (!isEqual(sa, initialSa)) {
          isEqualSeccions = false;
        }
      });
    }

    if (isEqual(formState, _initialValue) && isEqualSeccions) {
      return true;
    }

    return false;
  }, [formState, initialValue]);

  const posObservacion = Number(rol) === ROL.COORDINADOR_JE ? 1 : 0;

  const handleDownload = useCallback(async () => {
    try {
      const resultado: SituacionGenerate = await situacionGenerateDAO.save(
        currentSituacion,
      );

      const element = document.getElementById('print');
      if (!element) return;
      element.innerHTML = resultado.template;
      window.print();
      enqueueSnackbar('PDF generado exitozamente.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    } catch (error) {
      enqueueSnackbar('No se pudo generar el PDF.', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  }, [currentSituacion, situacionGenerateDAO, enqueueSnackbar]);

  useDocumentTitlePrint({
    beforeName: `Situación_${
      currentSituacion?.localizacion.cueAnexo
    }_${moment().format('YYYY-MM-DD')}`,
  });

  if (formState?.seccionesAfectadas[0]?.afectaJE === true) {
    const getDaysBetweenDates = function (startDate: string, endDate: string) {
      let now = moment(startDate),
        dates = [];

      while (now.isSameOrBefore(endDate)) {
        dates.push(now.format('MM/DD/YYYY'));

        now.add(1, 'days');
      }

      return dates;
    };

    const startDate = moment(periodo?.fechaInicio).format('YYYY-MM-DD');

    const endDate = moment(periodo?.fechaFin).format('YYYY-MM-DD');

    const fechaFiltrada = getDaysBetweenDates(startDate, endDate);

    const seccionesValue = formState.seccionesAfectadas.map(
      (idSeccion) => idSeccion.idSeccion,
    );

    const coincidencias = seccionesValue.map((id) => {
      const coinc = propuestasJE.filter((item) => {
        return item?.seccion?.idSeccion === id;
      });

      return coinc.map((coincidencia) => coincidencia?.dias || []).flat();
    });

    const todosLosDiasJE = coincidencias.flat(); //array con los dias de JE para esa seccion

    const dayFecha: { idDia: number; fecha: string }[] = []; //Se va a llenar con los datos seleccionados

    fechaFiltrada.forEach((dateString) => {
      const date = moment(dateString, 'MM/DD/YYYY');

      const dayOfWeek = date.format('dddd');
      if (dayOfWeek === 'lunes') {
        dayFecha.push({
          idDia: 1,

          fecha: date.format('YYYY-MM-DD'),
        });
      } else if (dayOfWeek === 'martes') {
        dayFecha.push({
          idDia: 2,

          fecha: date.format('YYYY-MM-DD'),
        });
      } else if (dayOfWeek === 'miércoles') {
        dayFecha.push({
          idDia: 3,

          fecha: date.format('YYYY-MM-DD'),
        });
      } else if (dayOfWeek === 'jueves') {
        dayFecha.push({
          idDia: 4,

          fecha: date.format('YYYY-MM-DD'),
        });
      } else if (dayOfWeek === 'viernes') {
        dayFecha.push({
          idDia: 5,

          fecha: date.format('YYYY-MM-DD'),
        });
      }
    });
    const fechasNoCoincidentes = dayFecha
      .filter(
        (day) =>
          !todosLosDiasJE.some(
            (todosDias) => day.idDia === todosDias?.dia?.idDia,
          ),
      )
      .map((filteredDay) => filteredDay.fecha);

    fechasNoCoincidentes.sort((a, b) => new Date(a) - new Date(b));
    const setFechasUnicas = new Set(fechasNoCoincidentes);

    arrayFechaNoCoincidente = Array.from(setFechasUnicas).map((fecha) =>
      moment(fecha, 'YYYY-MM-DD').toDate(),
    );
  }

  const minDate = periodo
    ? moment(periodo.fechaInicio, 'YYYY-MM-DD').toDate()
    : moment(moment().startOf('months'), 'YYYY-MM-DD').toDate();

  let maxDate = periodo
    ? moment(periodo.fechaFin, 'YYYY-MM-DD').add(1, 'days').toDate()
    : moment(new Date(), 'YYYY-MM-DD').add(1, 'month').toDate();

  if (
    formState.tipoSituacion.toString() ===
    TIPO_SITUACIONES.EMERGENCIAS.toString()
  ) {
    maxDate = new Date();
  }

  return (
    <Container component={Box} padding={3}>
      <Grid container spacing={2} component={Box} displayPrint="none">
        <Grid item xs={12} container justifyContent="space-around">
          <Grid item xs={4}>
            <TipoSituacionV3SelectInput
              label="Tipo de situación"
              name="tipoSituacion"
              valueKey="idTipoSituacion"
              labelKey="descripcion"
              handleChange={handleChange}
              value={formState.tipoSituacion}
              placeholder="Seleccioná un tipo"
              fullWidth
              orderBy="descripcion"
              InputProps={{ readOnly: onlyView || edit }}
            />
          </Grid>
          <Grid item xs={4}>
            <MotivoTipoSituacionV3SelectInput
              label="Motivo"
              name="motivoTipoSituacion"
              handleChange={handleChange}
              value={formState.motivoTipoSituacion}
              valueKey="motivoSituacion.idMotivoSituacion"
              labelKey="motivoSituacion.descripcion"
              placeholder="Seleccioná un motivo"
              fullWidth
              filter={filterMotivos}
              enableFetch={Boolean(formState.tipoSituacion)}
              disabled={!Boolean(formState.tipoSituacion)}
              orderBy="motivoSituacion.descripcion"
              InputProps={{ readOnly: onlyView || edit }}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent="center">
          <Grid item xs={10} container justifyContent="center">
            <div
              style={{
                position: 'absolute',
                height: '35%',
                width: '100%',
              }}
            ></div>
            <DateRangePicker
              locale={es}
              className={classes.daterange}
              showMonthAndYearPickers={true}
              showDateDisplay={false}
              showPreview={true}
              ranges={formState.range}
              onChange={handleRangeChange}
              rangeColors={
                COLOR_DAY[Number(formState.tipoSituacion)] || ['#353535c3']
              }
              navigatorRenderer={getDateRangeHeader}
              weekdayDisplayFormat="eeeee"
              focusedRange={[0, 0]}
              minDate={minDate}
              maxDate={maxDate}
              disabledDates={arrayFechaNoCoincidente}
              disabledDays={
                formState.motivoTipoSituacion === '' &&
                formState.tipoSituacion === '' &&
                onlyView &&
                edit
              }
            />
          </Grid>
          <Grid item xs={10}>
            <Typography>Observación</Typography>
          </Grid>
          <Grid item xs={10} container>
            <TextFieldV2
              value={formState.observaciones[posObservacion]}
              name={`observaciones[${posObservacion}]`}
              id="observaciones"
              variant="outlined"
              multiline
              fullWidth
              minRows={4}
              maxRows={5}
              onChange={handleChange}
              InputProps={{ readOnly: onlyView }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ paddingBottom: '10px' }}
        >
          <Grid item xs={10}>
            <InfoTable
              columns={columns}
              rows={seccioneAlumnosMatriculados}
              working={working}
              message="Este colegio no tiene secciones activas."
            />
          </Grid>
        </Grid>
        <Grid item xs={12} container justifyContent="space-around">
          <Grid item>
            <Button variant="outlined" color="primary" onClick={handleCancel}>
              {onlyView ? 'Cerrar' : 'Cancelar'}
            </Button>
          </Grid>
          {onlyView ? (
            <></>
          ) : (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                disabled={diabledSaved || workingCount}
                onClick={handleSave}
              >
                Guardar
              </Button>
            </Grid>
          )}
          {onlyView ? (
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={handleDownload}
              >
                Descargar
              </Button>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
      <Grid id="print" className={classes.printer}></Grid>
    </Container>
  );
};
