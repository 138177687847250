import React, {
  useCallback,
  useEffect,
  FunctionComponent,
  useMemo,
} from 'react';
import {
  Badge,
  Grid,
  Paper,
  Tab,
  Tabs,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import {
  TabBarContext,
  useTabBarSetValue,
  useTabBarValue,
} from 'src/commons/components/TabBar';
import { grey, primary } from 'src/theme/colors';
import { Loading } from 'src/commons';
import { usePermissionContext } from 'src/lib/permission';
import { useRouterContext } from 'src/lib/router';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useGeneralContext } from 'src/context/GeneralContext';
import { useCustomRedirects } from './hooks';
import { useGetMatriculaConfig } from '../../../screens/Private/Matriculation/Registered/Initial/hooks/useGetMatriculaConfig';

const tabBarStyles = makeStyles(() => ({
  container: {
    boxShadow: 'none',
    borderBottom: '1px solid lightGrey',
  },
  activeTab: {
    height: '10px',
    color: primary.lightBlue,
    textTransform: 'none',
    fontSize: 14,
  },
  defaultTab: {
    height: '10px',
    color: grey.medium,
    textTransform: 'none',
    fontSize: 14,
  },
  indicator: {
    backgroundColor: primary.lightBlue,
  },
  link: {
    textDecoration: 'none',
  },
  loading: {
    position: 'fixed',
    background: 'rgba(0, 0, 0, .15)',
    top: 0,
    left: 0,
    bottom: 0,
    zIndex: 999,
  },
}));

export interface Content {
  id?: number | string;
  title: string;
  component: React.ReactNode;
  titleNumber?: number;
  tooltipTitle?: string;
  badgeContent?: React.ReactNode;
  disabled?: boolean;
  hidden?: boolean;
}

export interface NavBarProps {
  content: Array<Content>;
  loading?: boolean;
  customStyles?: any;
  shoudGoToTabOne?: any;
  setShouldGoToTabOne?: any;
  goToTab?: (setTabBarValue: any) => void;
  defaultTab?: number;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export const TabPanel: FunctionComponent<TabPanelProps> = ({
  children,
  index,
  value,
}) => (
  <div
    role="tabpanel"
    hidden={value !== index}
    id={`simple-tabpanel-${index}`}
    aria-labelledby={`simple-tab-${index}`}
  >
    {value === index && <Grid>{children}</Grid>}
  </div>
);
// TODO: Fix aberration component
const TabBarComp: FunctionComponent<NavBarProps> = ({
  content,
  loading,
  customStyles = {},
  shoudGoToTabOne,
  setShouldGoToTabOne,
  goToTab,
}) => {
  const {
    generalState: {
      calificaciones: { valuesChanged },
    },
  } = useGeneralContext();

  const { activeRules } = usePermissionContext();
  const setTabBarValue = useTabBarSetValue();
  const { metadata } = useRouterContext();

  const setTabValue = useCallback(
    () => setTabBarValue(metadata.fromTab ?? 0),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [metadata],
  );

  useEffect(() => {
    if (goToTab) {
      goToTab(setTabBarValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goToTab]);

  useEffect(() => {
    if (shoudGoToTabOne) {
      setTabBarValue(1);
      setShouldGoToTabOne(false);
    }
  }, [setShouldGoToTabOne, setTabBarValue, shoudGoToTabOne]);

  useEffect(() => {
    if ('tab' in metadata) setTabValue();
  }, [setTabValue, metadata]);

  useEffect(() => {
    if ('newPestana' in metadata) {
      setTabBarValue(metadata.newPestana);
    }
  }, [setTabBarValue, metadata]);

  const { tabIndex } = useTabBarValue();

  const handleChange = async (_: React.ChangeEvent<any>, newValue: number) => {
    if (valuesChanged) {
      const confirm = await confirmDialog.show({
        title: '¿Desea continuar?',
        content:
          'La información precargada se perderá en caso que no guarde, por favor seleccione una opción.',
        confirmText: 'Continuar',
        cancelText: 'Seguir en esta pantalla',
      });
      if (confirm) {
        setTabBarValue(newValue);
      }
    } else {
      setTabBarValue(newValue);
    }
  };
  const classes = tabBarStyles();
  const getStyle = (index: number) =>
    index === tabIndex ? classes.activeTab : classes.defaultTab;

  const filteredContent = useMemo(
    () =>
      content.filter((item) => {
        if (!item.id) return true;

        return !activeRules.tabBars.some((tab) => tab.id === item.id);
      }),
    [activeRules.tabBars, content],
  );

  useCustomRedirects({
    filteredContent,
    setTabBarValue,
  });

  return (
    <div>
      <div className={customStyles}>
        {loading && <Loading className={classes.loading} />}
        <Paper className={classes.container}>
          <Tabs
            value={tabIndex}
            onChange={handleChange}
            classes={{ indicator: classes.indicator }}
            variant="scrollable"
            scrollButtons="auto"
            aria-label="auto-scroll-tabs"
          >
            {filteredContent.map((item, index) => {
              const typography = (
                <Typography>
                  <Tooltip
                    title={item.tooltipTitle ?? ''}
                    disableHoverListener={!item.tooltipTitle}
                    placement="right"
                  >
                    <Badge
                      badgeContent={item.badgeContent}
                      color="primary"
                      style={{ marginBottom: '10px' }}
                    >
                      <span
                        className={getStyle(index)}
                        style={{ marginRight: '10px' }}
                      >
                        {item.title}
                      </span>
                    </Badge>
                  </Tooltip>
                </Typography>
              );
              return (
                <Tab
                  key={`tab-${index}`}
                  label={typography}
                  disabled={item.disabled}
                />
              );
            })}
          </Tabs>
        </Paper>
        {filteredContent.map((item, index) => (
          <TabPanel value={tabIndex} index={index} key={index}>
            {item.component}
          </TabPanel>
        ))}
      </div>
    </div>
  );
};

export const TabBar: FunctionComponent<NavBarProps> = ({
  content,
  loading,
  shoudGoToTabOne,
  setShouldGoToTabOne,
  goToTab,
  defaultTab = 0,
}) => (
  <TabBarContext defaultTab={defaultTab}>
    <TabBarComp
      loading={loading}
      content={content.filter((c) => !c.hidden)}
      shoudGoToTabOne={shoudGoToTabOne}
      setShouldGoToTabOne={setShouldGoToTabOne}
      goToTab={goToTab}
    />
  </TabBarContext>
);
