import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Checkbox, Grid, Tooltip } from '@material-ui/core';
import {
  ObservacionesModal,
  ROL,
  SimpleSelect,
  useLibbyCall,
} from 'src/commons';
import { SelectPassStatus } from '../Initial/PromotionDetail/components/SelectPassStatus';
import { SelectPassSection } from '../Initial/PromotionDetail/components/SelectPassSection';
import { useRolesContext } from 'src/context/RolesContext';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { IconButton } from '@material-ui/core';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import EditIcon from '@material-ui/icons/Edit';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import {
  useCalificacionesSecundarioAnualLibbyFetch,
  useEspacioCurricularSeccionLibbyFetch,
  useEstadoProyeccionMotivoLibbyFetch,
  useSeccionLibbyFetch,
} from 'src/app/business';
import moment from 'moment';
import { useHandleChangePassStatus } from './PromotionDetailSecundariaColumnHooks/useHandleChangePassStatus';
import useHandleChangePassSection from './PromotionDetailSecundariaColumnHooks/useHandleChangePassSection';
import { useHandleClickModal } from './PromotionDetailSecundariaColumnHooks/useHandleClickModal';
import { usePendientesLogic } from './PromotionDetailSecundariaColumnHooks/usePendientesLogic';
import { usePendientesOpciones } from './PromotionDetailSecundariaColumnHooks/usePendientesOpciones';
import _, { groupBy, isNumber } from 'lodash';
import customFormDialog from 'src/commons/services/customFormDialog';
import { inputDisableLogic } from '../utils/inputDisableLogic';
import { TooltipErrors } from '../components/TooltipsError';
import { AlumnoMovimiento, Anual, PlanEstudioNivel } from 'src/app/models';
import { CURRENT_CICLO_LECTIVO } from 'src/screens/Private/Acap';
import { Recuperacion } from 'src/app/models/calificaciones/Recuperacion';
import { Alumno } from 'src/screens/Private/LegajoAlumno';

const escuelasCBOyREINGRESO = [
  '20203300',
  '20203800',
  '20203900',
  '20242200',
  '202351',
  '20234700',
  '20234800',
  '20234900',
  '20235200',
  '20240000',
  '20240700',
];

export const usePromocionAlumnosColumnsSecundaria = (
  setCheckedAll: any,
  setCheckStudent: any,
  checkedAll: boolean,
  studentsData: any,
  libby: any,
  setFormValues: any,
  setStudentsData: any,
  idCurrentSection: any,
  checkStudent: any,
  restoreFlag: any,
  setRestoreFlag: any,
  proyeccionEstado: any,
  descripcionAnioSection: any,
  // materiasPendientes: any,
  // setMateriasPendientes: any,
  studentMovements: AlumnoMovimiento[],
  wStudentMovement: boolean,
  localizacionData: any,
  nivel: any,
  sections: any = [],
  calificacionesAnual: Anual[],
  calificacionRecuperacion: Recuperacion[],
  planEstudioNivel?: PlanEstudioNivel,
) => {
  const {
    selectedRole: { localizacionId, rol },
  } = useRolesContext();
  const [firstRender, setFirstRender] = useState(false);
  const [tieneTodasLasCalificaciones, setTieneTodasLasCalificaciones] =
    useState(false);
  const { data: yearPassStatus = [] } = useLibbyCall(libby, {
    daoName: 'estado_pase_anio',
    methodName: 'getAll',
  });

  const isEscuelasCBOyREINGRESO =
    escuelasCBOyREINGRESO.includes(localizacionId);
  // const CURRENT_YEAR = proyeccionEstado[0]
  //   ? proyeccionEstado[0]?.cicloLectivo?.anio
  //   : moment().year();

  const specialSchoolPlan =
    localizacionId === '20235000' &&
    planEstudioNivel?.planEstudio?.idPlanEstudio !== 218 &&
    planEstudioNivel?.planEstudio?.idPlanEstudio !== 217;

  const ID_ESTADO_DE_LA_PROYECCION = proyeccionEstado[0]
    ? proyeccionEstado[0]?.proyeccionEstadoTipo.idProyeccionEstadoTipo
    : null;

  // const ID_ESTADO_DE_LA_PROYECCION = 2;  //para pruebas

  const { data: estadoMotivo = [] } = useEstadoProyeccionMotivoLibbyFetch({
    orderBy: 'descripcion',
  });

  const filterEspacioCurricularSeccion = useMemo(
    () => ({
      0: [
        {
          path: 'seccion.localizacion',
          value: localizacionId,
        },
      ],
      1: [
        {
          path: 'espacioCurricular.materia.excepcion',
          value: 'true',
          method: 'notEquals',
        },
      ],
      2: [
        {
          path: 'seccion.cicloLectivo',
          value: CURRENT_CICLO_LECTIVO,
        },
      ],
    }),
    [localizacionId],
  );

  const {
    data: espacioCurricularSeccion = [],
    working: workingEspacioCurricularSeccion,
  } = useEspacioCurricularSeccionLibbyFetch({
    limit: 10000,
    filter: filterEspacioCurricularSeccion,
  });

  const ecsBySeccion = groupBy(espacioCurricularSeccion, 'seccion.idSeccion');

  const handleChangePassStatus = useHandleChangePassStatus(
    sections,
    setStudentsData,
    setFormValues,
    nivel,
  );
  const handleChangePassSection = useHandleChangePassSection(
    setFormValues,
    idCurrentSection,
    setStudentsData,
  );
  const handleClickModal = useHandleClickModal(
    checkStudent,
    yearPassStatus,
    sections,
    studentsData,
    idCurrentSection,
    setStudentsData,
    setFormValues,
    descripcionAnioSection,
    localizacionData,
    nivel,
    // setMateriasPendientes,
    // materiasPendientes,
  );
  const handleChange = usePendientesLogic({
    setFormValues,
    setStudentsData,
    // setMateriasPendientes,
    studentsData,
    localizacionData,
    // materiasPendientes,
    options: sections,
    planEstudioNivel,
  });
  const { espaciosCurricularesPendientes } = usePendientesOpciones();

  const handleChangeMotivo = useCallback(
    (e: React.FormEvent<EventTarget>, restStudentData) => {
      const target = e.target as HTMLInputElement;
      const newMotivo = target.value;

      const newStudentData = {
        ...restStudentData,
        estadoProyeccionMotivo: newMotivo,
      };

      setStudentsData((prev: any) =>
        prev.map((student: any) => {
          if (student.idStudent === restStudentData.idStudent) {
            return newStudentData;
          }
          return student;
        }),
      );

      setFormValues((prev: any) =>
        prev.map((student: any) => {
          if (student.alumno === restStudentData.idStudent) {
            student = {
              ...student,
              estadoProyeccionMotivo: newStudentData.estadoProyeccionMotivo,
            };
          }
          return student;
        }),
      );
    },
    [setFormValues, setStudentsData],
  );

  const handleObservacionesChange = useCallback(
    (observacion, restStudentData) => {
      const newStudentData = {
        ...restStudentData,
        observacion: observacion,
      };

      setStudentsData((prev: any) =>
        prev.map((student: any) => {
          if (student.idStudent === restStudentData.idStudent) {
            return newStudentData;
          }
          return student;
        }),
      );

      setFormValues((prev: any) =>
        prev.map((student: any) => {
          if (student.alumno === restStudentData.idStudent) {
            student = {
              ...student,
              observacion: newStudentData.observacion,
            };
          }
          return student;
        }),
      );
    },
    [setFormValues, setStudentsData],
  );
  const idStudents = studentMovements?.map(
    (student) => student?.alumno?.idAlumno,
  );

  const handleObservaciones: any = useCallback(
    (row: any) => {
      customFormDialog.show({
        title: 'Observaciones',
        renderComponent: (
          <ObservacionesModal
            onAddContent={(content: string) => {
              handleObservacionesChange(content, row);
            }}
            value={row.observacion ?? ''}
          />
        ),
        sizeWidth: 'sm',
      });
    },
    [handleObservacionesChange],
  );

  // useEffect(() => {
  //   let materiasPendientesToSave: any = [];
  //   studentsData.forEach((element: any) => {
  //     const findMateria = materiasPendientes.find(
  //       (materia: any) => materia?.idAlumno === element?.idAlumno,
  //     );
  //     if (!findMateria) {
  //       materiasPendientesToSave.push({
  //         idAlumno: element.idStudent,
  //         previas: 0,
  //         pendientes: element.materiasPendientes.pendientes ?? 0,
  //       });
  //     }
  //   });

  //   if (
  //     firstRender === false &&
  //     materiasPendientesToSave.length === studentsData.length &&
  //     materiasPendientesToSave.length > 0
  //   ) {
  //     setFirstRender(true);
  //     setMateriasPendientes(materiasPendientesToSave);
  //   }
  // }, [firstRender, materiasPendientes, setMateriasPendientes, studentsData]);

  const alumnosConCalificaciones = useMemo(() => {
    const _alumnos: string[] = [];

    studentsData.forEach((alumno: Alumno) => {
      const calificacionesDelAlumno = calificacionesAnual.filter(
        (calificacion) => calificacion?.alumno?.idAlumno === alumno?.idStudent,
      );
      const recuperacionPorAlumno = calificacionRecuperacion.filter(
        (recuperacion) => recuperacion?.idAlumno === alumno.idStudent,
      );

      const materias = ecsBySeccion[alumno?.idSection] || [];

      const idsMateriaPorSeccion = materias.map(
        (materia) => materia.idEspacioCurricularSeccion,
      );

      const ecsTotales: string[] = [];

      recuperacionPorAlumno.forEach((r) => {
        if (!ecsTotales.some((et) => et === r.idEspacioCurricularSeccion)) {
          if (!!Number(r.diciembre)) {
            ecsTotales.push(r.idEspacioCurricularSeccion);
          } else if (!!Number(r.febreroMarzo)) {
            ecsTotales.push(r.idEspacioCurricularSeccion);
          }
        }
      });

      calificacionesDelAlumno.forEach((c) => {
        if (
          !ecsTotales.some(
            (et) =>
              et === c.espacioCurricularSeccion.idEspacioCurricularSeccion,
          )
        ) {
          ecsTotales.push(
            c.espacioCurricularSeccion.idEspacioCurricularSeccion,
          );
        }
      });

      const todosPresentes = idsMateriaPorSeccion.every((id) =>
        ecsTotales.includes(id),
      );

      if (todosPresentes) {
        _alumnos.push(alumno.id);
      }
    });
    return _alumnos;
  }, [
    calificacionRecuperacion,
    calificacionesAnual,
    ecsBySeccion,
    studentsData,
  ]);

  const columnSecundaria = useMemo(() => {
    const columns = [
      {
        id: 'icon',
        width: '1%',
        label: (
          <Checkbox
            checked={checkedAll}
            onChange={(e) => {
              setCheckedAll(e.target.checked);
              return checkedAll
                ? setCheckStudent([])
                : setCheckStudent(
                    studentsData.map((student: any) => student.id),
                  );
            }}
            inputProps={{ 'aria-labelledby': 'transfer-list-item-all-label' }}
            color="primary"
          />
        ),
        hideSortIcon: true,
        noSort: true,
        align: 'left',
        render: (value: any, row: any) => {
          const isStudentChecked = checkedAll || checkStudent.includes(row.id);

          return (
            <Checkbox
              name={row.id}
              checked={checkedAll || isStudentChecked}
              onChange={() => {
                if (!checkStudent.length) setRestoreFlag(!restoreFlag);
                if (checkedAll) {
                  setCheckedAll(false);
                  return setCheckStudent([row.id]);
                }
                return isStudentChecked
                  ? setCheckStudent(
                      checkStudent.filter(
                        (idStudent: any) => row.id !== idStudent,
                      ),
                    )
                  : setCheckStudent([...checkStudent, row.id]);
              }}
              inputProps={{ 'aria-labelledby': 'transfer-list-item-all-label' }}
              color="primary"
            />
          );
        },
      },
      {
        id: 'surnameName',
        label: 'Apellido y nombre',
        width: '10%',
        orderById: 'alumno.persona.apellido',
        render: (value: any, row: any) => {
          return <p>{row?.surnameName}</p>;
        },
      },
      {
        id: 'document',
        label: 'Documento',
        width: '5%',
        orderById: 'alumno.persona.documento',
        render: (value: any, row: any) => {
          return <p>{row?.document}</p>;
        },
      },
      {
        id: 'condition',
        label: 'Condición',
        width: '5%',
        hideSortIcon: true,
        noSort: true,
        orderById: 'alumno.condicion.descripcionCondicion',
        render: (value: any, row: any) => {
          return <p>{row?.condition}</p>;
        },
      },
      {
        id: 'currentSection',
        label: 'Sección actual',
        width: '7%',
        hideSortIcon: true,
        noSort: true,
        orderById: 'seccion.division',
        render: (value: any, row: any) => {
          return <p>{row?.currentSection}</p>;
        },
      },
      {
        id: 'pendientesCicloActual',
        label: 'Cantidad de espacios curriculares pendientes',
        width: '7%',
        hideSortIcon: true,
        noSort: true,
        orderById: 'seccion.division',
        render: (value: any, row: any) => {
          return (
            <SimpleSelect
              content={espaciosCurricularesPendientes}
              handleChange={(e) => handleChange(e, row)}
              labelKey="label"
              name="pendientesActuales"
              value={row?.materiasPendientes?.pendientes}
              valueKey="value"
              disableUnderline
              variant="standard"
              disabled={
                rol !== ROL.DIRECCION_AREA.toString()
                  ? inputDisableLogic({
                      row,
                      idEstadoDeLaProyeccion: ID_ESTADO_DE_LA_PROYECCION,
                      nivel: Number(nivel),
                      inputId: 'pendientesCicloActual',
                    })
                  : false
              }
            />
          );
        },
      },
      {
        id: 'passStatus',
        label: 'Pase de año',
        width: '5%',
        hideSortIcon: true,
        noSort: true,
        render: (value: any, row: any) => {
          return (
            <SelectPassStatus
              value={row.statusPass}
              planEstudioNivel={planEstudioNivel}
              options={yearPassStatus}
              row={row}
              handleChange={(e: React.FormEvent<EventTarget>) => {
                handleChangePassStatus(e, row);
              }}
              localizacionData={localizacionData}
              nivel={nivel}
              totalPendientes={row.materiasPendientes.pendientes}
              idEstadoDeLaProyeccion={ID_ESTADO_DE_LA_PROYECCION}
              disabled={
                rol !== ROL.DIRECCION_AREA.toString()
                  ? inputDisableLogic({
                      row,
                      idEstadoDeLaProyeccion: ID_ESTADO_DE_LA_PROYECCION,
                      nivel: Number(nivel),
                      inputId: 'passStatus',
                    })
                  : false
              }
            />
          );
        },
      },
      {
        id: 'passSection',
        label: 'Sección a la que pasa',
        width: '5%',
        hideSortIcon: true,
        noSort: true,
        render: (value: any, row: any) => {
          return (
            <SelectPassSection
              value={[4].includes(row.statusPass) ? '-' : row.sectionPass}
              statusPass={row.statusPass}
              options={sections}
              numeroAnio={row.currentYear.numeroAnio}
              currentCicloLectivo={row.cicloLectivo}
              handleChange={(e: React.FormEvent<EventTarget>) =>
                handleChangePassSection(e, row)
              }
              handleChangeFunction={handleChangePassSection}
              row={row}
              descripcionAnioSection={descripcionAnioSection}
              nivel={nivel}
              disabled={
                rol !== ROL.DIRECCION_AREA.toString()
                  ? inputDisableLogic({
                      row,
                      idEstadoDeLaProyeccion: ID_ESTADO_DE_LA_PROYECCION,
                      nivel: Number(nivel),
                      inputId: 'passSection',
                    })
                  : false
              }
              idAnio={row.idAnio}
            />
          );
        },
      },
      {
        id: 'motivo',
        label: 'Motivo',
        width: '5%',
        hideSortIcon: true,
        noSort: true,
        render: (value: any, row: any) => {
          return (
            <SimpleSelect
              name="motivo"
              value={row.estadoProyeccionMotivo}
              handleChange={(e: React.FormEvent<EventTarget>) =>
                handleChangeMotivo(e, row)
              }
              placeholder="Seleccione opción"
              content={estadoMotivo}
              labelKey="descripcion"
              valueKey="idEstadoProyeccionMotivo"
              customStyleContainer={{ width: '100%' }}
              disabled={inputDisableLogic({
                row,
                idEstadoDeLaProyeccion: ID_ESTADO_DE_LA_PROYECCION,
                nivel: Number(nivel),
                inputId: 'motivo',
              })}
            />
          );
        },
      },
      {
        id: 'observaciones',
        label: 'Observaciones',
        width: '10%',
        customStyles: { maxWidth: '14em' },
        style: { textAlign: 'center' },
        hideSortIcon: true,
        render: (_: unknown, row: any) => {
          const { observacion = '' } = row;
          const disabled = inputDisableLogic({
            row,
            idEstadoDeLaProyeccion: ID_ESTADO_DE_LA_PROYECCION,
            nivel: Number(nivel),
            inputId: 'observaciones',
          });
          return (
            <Grid
              container
              item
              style={{
                flexWrap: 'nowrap',
                alignItems: 'center',
              }}
            >
              <span>{`${
                !!observacion ? observacion.slice(0, 5) + '...' : ''
              }`}</span>
              <IconButton
                style={!disabled ? { color: '#00b3e3' } : {}}
                disabled={disabled}
                onClick={() => {
                  handleObservaciones(row);
                }}
              >
                <LibraryBooksIcon />
              </IconButton>
              {!disabled && !observacion ? (
                <span style={{ color: '#d52424f2' }}>
                  Este campo es requerido
                </span>
              ) : null}
            </Grid>
          );
        },
      },
      {
        id: 'alertas',
        label: 'Estatus',
        width: '1%',
        hideSortIcon: true,
        noSort: true,
        align: 'center',
        render: (_: unknown, row: any) => {
          const messages = [];

          if (!Boolean(row.idProyeccion)) {
            messages.push('Debe proyectar al estudiante');
          }

          if (
            !alumnosConCalificaciones.includes(row.id) &&
            !isEscuelasCBOyREINGRESO &&
            !specialSchoolPlan
          ) {
            messages.push('Alumno no tiene calificación definitiva');
          }
          return (
            <Tooltip
              title={<TooltipErrors errorMessages={messages} title="Error" />}
            >
              <IconButton aria-label="delete">
                {messages.length === 0 ? (
                  <CheckIcon color="primary" />
                ) : (
                  <ErrorIcon color="error" />
                )}
              </IconButton>
            </Tooltip>
          );
        },
      },
      {
        //Este es el Icon de los tres puntitos.
        id: 'secondIcon',
        label: (
          <Tooltip
            title={`${
              ID_ESTADO_DE_LA_PROYECCION === 1
                ? ''
                : 'La proyección ya fue finalizada.'
            }`}
          >
            <IconButton
              onClick={
                ID_ESTADO_DE_LA_PROYECCION === 1 ? handleClickModal : () => {}
              }
            >
              <MoreVertIcon />
            </IconButton>
          </Tooltip>
        ),
        width: '1%',
        hideSortIcon: true,
        noSort: true,
        align: 'center',
        render: (value: any, row: any) => <></>,
      },
    ];

    const isFirstYear = studentsData[0]?.currentYear?.numeroAnio === 1;

    // isFirstYear &&
    //   _.remove(columns, (column) => column.id === 'pendientesPrevias');

    return columns;
  }, [
    checkedAll,
    ID_ESTADO_DE_LA_PROYECCION,
    handleClickModal,
    studentsData,
    setCheckedAll,
    setCheckStudent,
    checkStudent,
    setRestoreFlag,
    restoreFlag,
    espaciosCurricularesPendientes,
    rol,
    nivel,
    handleChange,
    planEstudioNivel,
    yearPassStatus,
    localizacionData,
    handleChangePassStatus,
    sections,
    handleChangePassSection,
    descripcionAnioSection,
    estadoMotivo,
    handleChangeMotivo,
    handleObservaciones,
    alumnosConCalificaciones,
    isEscuelasCBOyREINGRESO,
    specialSchoolPlan,
  ]);

  return columnSecundaria;
};
