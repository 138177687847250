import React, { useCallback } from 'react';
import { ObservacionesModal, SelectWithField, SimpleSelect } from 'src/commons';
import { useMemo } from 'react';
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import EditIcon from '@material-ui/icons/Edit';
import customFormDialog from 'src/commons/services/customFormDialog';
import { primary } from 'src/theme/colors';
import {
  Alumno,
  CicloLectivo,
  EstadoAlumno,
  Seccion,
  Pps,
} from 'src/app/models';
import { usePPSFormContext } from '../PPSFormProvider';
import { FormApi } from 'final-form';

const useStyles = makeStyles(() => ({
  iconObservacion: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'end',
    color: primary.lightBlue,
    marginLeft: '20px',
  },
  observaciones: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '200px',
    marginTop: '10px',
    textAlign: 'right',
    color: 'black',
    paddingBottom: 10,
  },
}));

interface formValuesType {
  mantiene: string;
  respeta: string;
  vincula: string;
  acude: string;
  observaciones: string;
}

interface informePpsType {
  row: {
    idAlumnoMovimiento: string;
    updateAt: string;
    updatedBy: string;
    deletedAt: Date | null;
    alumno: Alumno;
    cicloLectivo: CicloLectivo;
    estadoAlumno: EstadoAlumno;
    seccion: Seccion;
    deletedBy: string | number | null;
  };
  button: string;
}

export const useConvivenciaEscolarColumns = (ppsDataAlumno: Pps) => {
  const { form } = usePPSFormContext();
  const values = (form as FormApi).getState().values;
  const classes = useStyles();
  const handleObservaciones: () => void = useCallback(() => {
    customFormDialog.show({
      title: 'Observaciones',
      renderComponent: (
        <ObservacionesModal
          content={values?.convivencia?.observaciones}
          onAddContent={(content: string) =>
            (form as FormApi).change('convivencia.observaciones', content)
          }
          value={values?.convivencia?.observaciones}
        />
      ),
      sizeWidth: 'sm',
    });
  }, [values?.convivencia?.observaciones, form]);

  const columns = useMemo(() => {
    const genericOptions = [
      { label: 'Siempre' },
      { label: 'Frecuentemente' },
      { label: 'Con poca frecuencia' },
    ];

    const isOpen = ppsDataAlumno !== undefined ? ppsDataAlumno?.abierto : true;
    const isDisabled = !isOpen;

    return [
      {
        id: 'mantiene',
        label:
          'Mantiene una comunicación sostenida y sistemática con sus docentes',
        width: '5%',
        hideSortIcon: true,
        render: () => (
          <SelectWithField
            form={form}
            name="convivencia.mantiene"
            placeholder="Seleccioná una opción"
            labelKey="label"
            valueKey="label"
            content={genericOptions}
            disabled={isDisabled}
          />
        ),
      },
      {
        id: 'respeta',
        label:
          'Respeta los acuerdos de convivencia en las diferentes instancias',
        width: '5%',
        hideSortIcon: true,
        render: () => (
          <SelectWithField
            form={form}
            name="convivencia.respeta"
            placeholder="Seleccioná una opción"
            labelKey="label"
            valueKey="label"
            content={genericOptions}
            disabled={isDisabled}
          />
        ),
      },
      {
        id: 'vincula',
        label:
          'Se vincula de manera respetuosa con los adultos y/o pares en todos sus espacios de participación',
        width: '5%',
        hideSortIcon: true,
        render: () => (
          <SelectWithField
            name="convivencia.vincula"
            form={form}
            placeholder="Seleccioná una opción"
            labelKey="label"
            valueKey="label"
            content={genericOptions}
            disabled={isDisabled}
          />
        ),
      },
      {
        id: 'acude',
        label: 'Acude con a un adulto frente a situaciones complejas',
        width: '5%',
        hideSortIcon: true,
        render: () => (
          <SelectWithField
            form={form}
            name="convivencia.acude"
            placeholder="Seleccioná una opción"
            labelKey="label"
            valueKey="label"
            content={genericOptions}
            disabled={isDisabled}
          />
        ),
      },
      {
        id: 'observaciones',
        label: 'Observaciones',
        width: '5%',
        hideSortIcon: true,
        render: () => (
          <Grid>
            {values?.convivencia?.observaciones ? (
              <>
                <Grid className={classes.iconObservacion}>
                  <div className={classes.observaciones}>
                    {values?.convivencia?.observaciones}
                  </div>
                  <IconButton
                    className={classes.iconObservacion}
                    disabled={isDisabled}
                    onClick={() => handleObservaciones()}
                  >
                    {<LibraryBooksIcon />}
                  </IconButton>
                </Grid>
              </>
            ) : (
              <Grid className={classes.iconObservacion}>
                <IconButton
                  disabled={isDisabled}
                  onClick={() => handleObservaciones()}
                >
                  {<EditIcon style={{ color: primary.lightBlue }} />}
                </IconButton>
              </Grid>
            )}
          </Grid>
        ),
      },
    ];
  }, [
    ppsDataAlumno,
    form,
    values?.convivencia?.observaciones,
    classes.iconObservacion,
    classes.observaciones,
    handleObservaciones,
  ]);

  return columns;
};
