export function capitalizarPalabras(input: string): string {
  if (!input) return '';

  const palabras = input.split(' ');

  const palabrasCapitalizadas = palabras.map((palabra) => {
    if (palabra.length === 0) return palabra;

    return palabra.charAt(0).toUpperCase() + palabra.slice(1).toLowerCase();
  });

  const resultado = palabrasCapitalizadas.join(' ');

  return resultado;
}
