import React, {
  useState,
  useMemo,
  useEffect,
  useCallback,
  useRef,
} from 'react';
import { InfoTable, useDebounce } from 'src/commons';
import {
  FormValuesOferta,
  OfertaCreacionForm,
} from '../components/OfertaCreacionForm';
import { OfertaFiltersHeader } from '../components/OfertaFiltersHeader';
import { useOfertasColumns } from '../hooks/useOfertasColumns';
import {
  useOfertasDAO,
  useOfertasLibbyFetch,
} from 'src/app/business/acap/Ofertas';
import { useReferentesDAO } from 'src/app/business/acap/Referentes';
import _, { isEqual } from 'lodash';
import { generateOfertasExcelData } from '../hooks/useOfertasExcelData';
import { useSectoresLibbyCall } from 'src/app/business/acap/Sectores';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/styles';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { Button, TablePagination } from '@material-ui/core';
import { formatSearchDias } from '../utils';
import {
  Ofertas,
  OfertaGrupo,
  Referente,
  AccionOrientacion,
} from 'src/app/models';
import { useCreateCuentaAndPersonaPostRequest } from 'src/app/business/businessCustomEndpoints/CreateCuentaAndPersona';
import {
  useCicloLectivoLibbyCall,
  useCicloLectivoLibbyFetch,
} from 'src/app/business';
import { useLibbyCall } from '../../../../lib/libby';
import useGenerarExcel from '../../../../commons/hooks/useGenerarExcel';
import { useEstablecimientosAcapLibbyCall } from 'src/app/business/acap';

const useStyles = makeStyles({
  noMargin: {
    marginTop: '0',
  },
});

export type RowDetailGrupo = OfertaGrupo & {
  turno?: string;
  cupoGrupo?: number;
} & {
  organizacion: string;
  Orientacion: AccionOrientacion[];
  accion: string;
  nombreGrupo: string;
};

export interface OfertaStep {
  enabledCreating: boolean;
  enabledDetailTable: boolean;
  enableDuplicate: boolean;
  rowCreating: any;
  rowDetail: RowDetailGrupo[] | null;
}

export interface Filters {
  sector: string;
  cicloLectivo: number | null;
  search: string;
  establecimiento: string;
}

const columnToSearch = [
  'turnos.grupos.jornadas.cupo',
  'accion.organizacion.nombre',
  'accion.accionOrientaciones.orientacion.nombre',
  'accion.nombre',
  'accion.horas',
  'turnos.cupo',
  'turnos.turno.descripcionTurno',
  'fechaInicio',
  'fechaFin',
  'fechaPublicacion',
];

export const Oferta = () => {
  const currentYear = new Date().getFullYear();

  const { data: ciclosLectivos = [], working: ciclosWorking } =
    useCicloLectivoLibbyCall({
      methodName: 'getAll',
    });

  const ciclo = ciclosLectivos.find((ciclo) => ciclo.anio === currentYear);

  const [filters, setFilters] = useState<Filters>({
    sector: '',
    cicloLectivo: ciclo?.idCicloLectivo || null,
    search: '',
    establecimiento: '',
  });
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('fechaPublicacion');
  const [ofertaStep, setOfertaStep] = useState<OfertaStep>({
    enabledCreating: false,
    enabledDetailTable: false,
    enableDuplicate: false,
    rowCreating: null,
    rowDetail: null,
  });
  const [limit, setLimit] = useState<number>(50);
  const [offset, setOffset] = useState<number>(0);
  const searchDebounced = useDebounce(filters.search, 1000);
  const { enqueueSnackbar } = useSnackbar();
  const generateExcel = useGenerarExcel();
  const classes = useStyles();
  const ReferentesDAO = useReferentesDAO();
  const ofertasDAO = useOfertasDAO();
  const { data: sectores = [], working: sectorWorking } = useSectoresLibbyCall({
    methodName: 'getAll',
  });

  const { data: establecimientosAcap = [], working: establecimientosWorking } =
    useEstablecimientosAcapLibbyCall({
      methodName: 'getAll',
    });

  useEffect(() => {
    if (ciclo) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        cicloLectivo: ciclo?.idCicloLectivo,
      }));
    }
  }, [ciclo]);

  const filterCicloLectivo = ciclosLectivos.filter(
    (cycle: any, index: number) => {
      const fechaInicio = new Date(cycle.fechaInicio);

      if (cycle.fechaInicio < '2022-01-01T00:00:00.000Z') {
        return false;
      }

      return true;
    },
  );

  const filterEstablecimientosAcap = establecimientosAcap.map(
    (e) => e.localizacion?.establecimiento,
  );

  const { request } = useCreateCuentaAndPersonaPostRequest();

  const filter = useMemo(
    () => ({
      0: Boolean(filters.sector)
        ? [{ path: 'accion.organizacion.sector', value: filters.sector }]
        : [],
      1: Boolean(filters.cicloLectivo)
        ? [
            {
              path: 'accion.cicloLectivo',
              value: filters.cicloLectivo,
            },
          ]
        : [],
      2: Boolean(searchDebounced)
        ? [
            {
              path: 'turnos.grupos.jornadas.cupo',
              value: formatSearchDias(searchDebounced),
              method: 'includes',
            },
            ...columnToSearch.map((column) => ({
              path: column,
              value: searchDebounced,
              method: 'includes',
            })),
          ]
        : [],
      3: Boolean(filters.establecimiento)
        ? [
            {
              path: 'turnos.grupos.localizacion.establecimiento',
              value: filters.establecimiento,
            },
          ]
        : [],
    }),
    [
      filters.cicloLectivo,
      filters.establecimiento,
      filters.sector,
      searchDebounced,
    ],
  );
  const {
    data: totals = [],
    working: totalWorking,
    recall: recallTotals,
  } = useLibbyCall<{ total: number }>({
    methodName: 'getTotal',
    aspect: 'ofertas_test',
    params: [filter],
    daoName: 'ofertas',
    noAutoCall: true,
  });

  useEffect(() => {
    recallTotals(filter);
  }, [recallTotals, filter]);

  const {
    data: ofertas = [],
    working: workingOfertas,
    reFetch: ofertasReFetch,
    fetchMore,
  } = useOfertasLibbyFetch({
    filter,
    enabled: Boolean(filters.cicloLectivo),
    aspect: 'ofertas_test',
    limit,
    limitDinamyc: true,
  });

  const ofertasSorted = useMemo(() => {
    const ofertasCloned = _.cloneDeep(ofertas);

    ofertasCloned.sort((a, b) => {
      return direction === 'desc'
        ? _.get(a, orderBy)?.localeCompare(_.get(b, orderBy))
        : _.get(b, orderBy)?.localeCompare(_.get(a, orderBy));
    });

    const ofertasOrdenadasPaginadas = ofertasCloned.slice(
      offset,
      limit + offset,
    );

    return ofertasOrdenadasPaginadas;
  }, [orderBy, direction, ofertas, offset, limit]);

  const [download, setDownload] = useState<Ofertas[]>([]);
  const [isDownload, setIsDownload] = useState(false);
  const filterRef = useRef(filter);
  const handleGenerateExecel = useCallback(async () => {
    try {
      const _isEqual = isEqual(filter, filterRef.current);
      setIsDownload(true);
      const isDetail = ofertaStep.enabledDetailTable;
      const title = isDetail
        ? `Oferta ACAP por turno día ${moment().format('DD/MM/YYYY')}`
        : `Ofertas ACAP del día ${moment().format('DD/MM/YYYY')}`;
      let all: any[] = [];

      if (isDetail) {
        all = ofertaStep.rowDetail || [];
      } else {
        all =
          download.length > 0 && _isEqual
            ? download
            : await ofertasDAO.aspect('ofertas_test').fetch({
                filter,
                limit: 1500,
                orderBy,
                direction,
              });
        if (download.length === 0) {
          setDownload(all);
        }
        filterRef.current = filter;
      }
      const data = generateOfertasExcelData(all, isDetail, title);
      enqueueSnackbar('Documento Generado con éxito.', { variant: 'success' });
      await generateExcel(data.body, data.name);
    } catch (error) {
      console.log('ERROR', error);
      enqueueSnackbar('No se pudo generar el documento.', { variant: 'error' });
    } finally {
      setIsDownload(false);
    }
  }, [
    generateExcel,
    download,
    filter,
    orderBy,
    direction,
    ofertaStep,
    ofertasDAO,
    enqueueSnackbar,
  ]);

  const columns = useOfertasColumns(ofertaStep, setOfertaStep, ofertasReFetch);

  const handleSave = async ({
    formValues,
    gruposValues,
    turnosValues,
    nuevaOferta = false,
    isDuplicated = false,
  }: {
    formValues: FormValuesOferta;
    gruposValues: any[];
    turnosValues: any[];
    nuevaOferta: boolean;
    isDuplicated: boolean;
  }) => {
    const filter = {
      tipo: [{ path: 'accion.tipoAccion', value: formValues?.tipoAccion }],
      accion: [{ path: 'accion.idAccion', value: formValues?.accion }],
      orientacion: [
        {
          path: 'accion.accionOrientaciones.orientacion',
          value: formValues?.orientacion?.map(
            (o) => o.orientacion.idOrientacion,
          ),
          method: 'in',
        },
      ],
      sector: [
        { path: 'accion.organizacion.sector', value: formValues?.sector },
      ],
      fechaInicio: [
        {
          path: 'fechaInicio',
          value: moment(formValues.fechaInicio, 'YYYY-MM-DD').format(
            'YYYY-MM-DD',
          ),
        },
      ],
      fechaFin: [
        {
          path: 'fechaFin',
          value: moment(formValues.fechaFin, 'YYYY-MM-DD').format('YYYY-MM-DD'),
        },
      ],
      fechaPublicacion: [
        {
          path: 'fechaPublicacion',
          value: moment(formValues.fechaPublicacion, 'YYYY-MM-DD').format(
            'YYYY-MM-DD',
          ),
        },
      ],
      cicloLectivo: [
        { path: 'accion.cicloLectivo', value: formValues.cicloLectivo },
      ],
    };

    const result: Ofertas[] =
      isDuplicated &&
      formValues?.tipoAccion !== undefined &&
      formValues?.orientacion !== undefined
        ? await ofertasDAO.aspect('ofertas_test').fetch({
            filter,
          })
        : [];

    if (result?.length > 0 && isDuplicated && !ofertaStep.enabledCreating) {
      enqueueSnackbar('Ya existe una oferta igual', {
        variant: 'error',
      });
      return;
    }

    const referentesToCreate: Map<string, Referente> = new Map();

    const arrayOfReferentes: any = [];

    gruposValues?.forEach((grupo: any) => {
      const alreadyExist = arrayOfReferentes?.some(
        (referente: any) =>
          referente?.idReferente === grupo?.referente?.idReferente,
      );

      if (!alreadyExist) {
        arrayOfReferentes.push(grupo?.referente);
      }
    });

    await request({ referentes: arrayOfReferentes });

    turnosValues.forEach((turno: any) => {
      const clonnedGruposValues = _.cloneDeep(gruposValues);
      let gruposFiltered = clonnedGruposValues.filter(
        (grupo: any) => turno.turno === grupo.turno,
      );
      gruposFiltered.forEach((grupoFiltered: any) => {
        delete grupoFiltered['turno'];
      });
      delete turno['cupoGrupos'];
      turno['grupos'] = gruposFiltered;
      turno.grupos.forEach((grupo: OfertaGrupo) => {
        if (grupo.referente.idReferente === null) {
          if (referentesToCreate.has(grupo.referente.email)) {
            grupo.referente = referentesToCreate.get(
              grupo.referente.email,
            ) as Referente;
          } else {
            delete (grupo.referente as Partial<Referente>).idReferente;
            referentesToCreate.set(grupo.referente.email, grupo.referente);
          }
        }
      });
    });

    try {
      if (referentesToCreate.size > 0) {
        const referentes: Referente[] = await ReferentesDAO.save([
          ...referentesToCreate.values(),
        ]);
        referentes.forEach(
          (ref) =>
            (referentesToCreate.get(ref.email)!.idReferente = ref.idReferente),
        );
      }

      await ofertasDAO.save({
        ...(formValues?.idOferta ? { idOferta: formValues?.idOferta } : {}),
        accion: formValues?.accion,
        fechaFin: formValues?.fechaFin,
        fechaInicio: formValues?.fechaInicio,
        fechaPublicacion: formValues?.fechaPublicacion,
        turnos: turnosValues,
      });
      enqueueSnackbar(
        isDuplicated && !ofertaStep.enabledCreating
          ? '¡La oferta se duplicó con éxito!'
          : 'Se ha guardado con éxito',
        {
          variant: 'success',
        },
      );
      ofertasReFetch();
      setOfertaStep({
        ...ofertaStep,
        enabledCreating: nuevaOferta,
        enableDuplicate: false,
        rowCreating: null,
      });
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Error al guardar', { variant: 'error' });
    }
  };

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  const handleOnPaageChange = useCallback(
    (e: any, page: number) => {
      let offset = 0;
      if (page < 0) offset = 0 * limit;
      else offset = page * limit;
      setOffset(offset);
      if (ofertas.length <= offset) {
        fetchMore();
      }
    },
    [limit, ofertas, fetchMore],
  );

  const handleRowsPerPageChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setLimit(parseInt(e.target.value));
    },
    [setLimit],
  );

  const addOferta = useCallback(() => {
    setOfertaStep((prev) => ({
      ...prev,
      enabledCreating: true,
      rowCreating: null,
    }));
  }, []);

  const handleReturn = useCallback(() => {
    setOfertaStep((prevState: any) => ({
      ...prevState,
      enabledDetailTable: false,
      rowDetail: null,
    }));
  }, [setOfertaStep]);

  return ofertaStep.enabledCreating || ofertaStep.enableDuplicate ? (
    <OfertaCreacionForm
      sectores={sectores}
      handleSave={handleSave}
      ofertaStep={ofertaStep}
      setOfertaStep={setOfertaStep}
      ofertasReFetch={ofertasReFetch}
      ciclosLectivos={filterCicloLectivo}
    />
  ) : (
    <>
      <OfertaFiltersHeader
        setFilters={setFilters}
        filters={filters}
        handleGenerateExecel={handleGenerateExecel}
        sectores={sectores}
        establecimientos={filterEstablecimientosAcap}
        workingEstablecimientos={establecimientosWorking}
        cicloLectivos={filterCicloLectivo}
        workingCiclos={ciclosWorking}
        workingSectores={sectorWorking}
        disableDownload={ofertas.length === 0 || isDownload}
        isEnabledDetailTable={ofertaStep.enabledDetailTable}
        addOferta={addOferta}
        setOffset={setOffset}
      />
      {ofertaStep.enabledDetailTable && (
        <Button
          startIcon={<ChevronLeftIcon fontSize="large" />}
          onClick={handleReturn}
          variant="text"
          size="small"
          style={{
            paddingTop: 1,
            paddingBottom: 1,
            fontSize: 14,
            textTransform: 'none',
          }}
        >
          volver
        </Button>
      )}
      <InfoTable
        paperCustomStyle={ofertaStep.enabledDetailTable ? classes.noMargin : ''}
        rows={
          ofertaStep.enabledDetailTable
            ? ofertaStep.rowDetail || []
            : ofertasSorted
        }
        columns={columns}
        working={workingOfertas}
        direction={direction}
        onSortChange={handleRequestSort}
      />
      {ofertas.length > 0 &&
        !(ofertaStep.enabledDetailTable || ofertaStep.enableDuplicate) &&
        !totalWorking && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={Number(totals[0]?.total || 0)}
            rowsPerPage={limit}
            page={offset / limit}
            onPageChange={handleOnPaageChange}
            onRowsPerPageChange={handleRowsPerPageChange}
            labelRowsPerPage="Filas por página"
            labelDisplayedRows={({ from, to, count }) => {
              return from + '-' + to + ' de ' + count;
            }}
          />
        )}
    </>
  );
};
