import { FieldValidator } from 'final-form';
import { RAZON_JUSTIFICACION } from '../../../../../commons';
import { JustificacionInitialValues } from '../types';

const validExtensions = ['png', 'jpeg', 'jpg', 'pdf'];

const isValidSize = (file: File) => !(file.size > 2200);

const isValidExtension = (file: File) =>
  validExtensions.includes(file.type.split('/')[1]);

export const validateFiles: FieldValidator<File[]> = (
  value,
  allValues,
  meta,
) => {
  const _allValues = allValues as JustificacionInitialValues;
  if (_allValues.razonJustificacion !== RAZON_JUSTIFICACION.AUSENTE.toString())
    return;
  if (value.length === 0) {
    return 'Este campo es requerido';
  }
  if (value.length > 0 && !value.every((file) => isValidExtension(file))) {
    return 'El archivo debe ser de tipo: jpg, jpeg, png, pdf.';
  }
};
