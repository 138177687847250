import { Button, Grid } from '@material-ui/core';
import React from 'react';
import { SimpleSelect, Loading } from 'src/commons';
import { useOrientacionesLibbyFetch } from 'src/app/business/orientaciones/Orientaciones';
import { useTiposAccionesLibbyFetch } from 'src/app/business/acap/TiposAcciones';
import { useTurnoLibbyFetch } from 'src/app/business';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  buttonLoading: {
    height: '20px',
    padding: '0',
    display: 'flex',
    alignContent: 'center',
  },
});

export const FiltersHeader = ({
  setFilters,
  filters,
  workingOferta,
  buscarOferta,
}: any) => {
  const classes = useStyles();

  const { data: orientaciones = [], working: workingOrientaciones } =
    useOrientacionesLibbyFetch();
  const { data: tipoAcciones = [], working: workingTipoAcciones } =
    useTiposAccionesLibbyFetch({
      limit: 500,
    });
  const { data: turnos = [], working: workingTurnos } = useTurnoLibbyFetch();

  return (
    <>
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        style={{ marginTop: '15px', background: '#F5F5F5', padding: '30px 0' }}
      >
        <Grid
          container
          item
          md={4}
          justifyContent="center"
          alignItems="center"
          style={{
            display: 'inline-grid',
            marginTop: '30px',
          }}
        >
          <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
            Orientación:
          </p>
          <SimpleSelect
            name="orientacion"
            value={filters.orientacion}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFilters((prev: any) => ({ ...prev, orientacion: value }));
            }}
            placeholder="Todos"
            content={workingOrientaciones ? [] : orientaciones}
            labelKey="nombre"
            valueKey="idOrientacion"
            customStyleContainer={{ width: '20rem' }}
          />
        </Grid>
        <Grid
          container
          item
          md={4}
          justifyContent="center"
          alignItems="center"
          style={{
            display: 'inline-grid',
            marginTop: '30px',
          }}
        >
          <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
            Tipo de acción:
          </p>
          <SimpleSelect
            name="tipoAccion"
            value={filters.tipoAccion}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFilters((prev: any) => ({ ...prev, tipoAccion: value }));
            }}
            placeholder="Todos"
            content={workingTipoAcciones ? [] : tipoAcciones}
            labelKey="nombre"
            valueKey="idTipoAccion"
            customStyleContainer={{ width: '20rem' }}
          />
        </Grid>
        <Grid
          container
          item
          md={4}
          justifyContent="center"
          alignItems="center"
          style={{
            display: 'inline-grid',
            marginTop: '30px',
          }}
        >
          <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>Turno:</p>
          <SimpleSelect
            name="turno"
            value={filters.turno}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFilters((prev: any) => ({ ...prev, turno: value }));
            }}
            placeholder="Todos"
            content={
              workingTurnos
                ? []
                : turnos.filter(
                    (turno: any) =>
                      turno.idTurno === 1 ||
                      turno.idTurno === 2 ||
                      turno.idTurno === 4,
                  )
            }
            labelKey="descripcionTurno"
            valueKey="idTurno"
            customStyleContainer={{ width: '20rem' }}
          />
        </Grid>
        <Grid
          container
          item
          md={12}
          justifyContent="center"
          alignItems="center"
          style={{
            display: 'inline-grid',
            marginTop: '30px',
          }}
        >
          <Button
            color="primary"
            variant="contained"
            component="label"
            style={{ width: '200px', marginTop: '30px' }}
            onClick={() => buscarOferta()}
            disabled={workingOferta}
          >
            {workingOferta ? (
              <Loading className={classes.buttonLoading} />
            ) : (
              'Buscar oferta'
            )}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};
