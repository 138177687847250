import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { UsuarioRolTraverseAction } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'usuario_rol_traverse_action';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idUsuarioRolTraverseAction',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idUsuarioRolTraverseAction',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.UsuarioRolTraverseAction
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.UsuarioRolTraverseAction,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.UsuarioRolTraverseAction.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<UsuarioRolTraverseAction>(DAO_NAME, optionsToUse);

// hooks
export const useUsuarioRolTraverseActionFetchById = fetchById;
export const useUsuarioRolTraverseActionLibbyCall = libbyCall;
export const useUsuarioRolTraverseActionDAO = libbyDAO;
export const useUsuarioRolTraverseActionLibbyFetch = libbyFetch;

// components/hoc
export const UsuarioRolTraverseActionAutocomplete = entityAutocomplete;
export const UsuarioRolTraverseActionCall = renderPropCall;
export const UsuarioRolTraverseActionById = renderPropFetchById;
export const UsuarioRolTraverseActionSelect = simpleSelect;
export const UsuarioRolTraverseActionAccordionSelector = accordionSelector;
export const UsuarioRolTraverseActionSelectedChips = selectedChips;

// context
// ByIdContext
export const useUsuarioRolTraverseActionByIdContext = useByIdContext;
export const UsuarioRolTraverseActionByIdProvider = ByIdProvider;
export const UsuarioRolTraverseActionByIdContext = ByIdContext;
// GeneralContext
export const useUsuarioRolTraverseActionContext = useGeneralContext;
export const UsuarioRolTraverseActionProvider = GeneralProvider;
export const UsuarioRolTraverseActionContext = GeneralContext;
