import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { TipoDeudaAcademica } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'tipo_deuda_academica';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idTipoDeudaAcademica',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idTipoDeudaAcademica',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.TipoDeudaAcademica
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.TipoDeudaAcademica,
      hoc: { ...defaultOptions.hoc, ...businessOptions.TipoDeudaAcademica.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<TipoDeudaAcademica>(DAO_NAME, optionsToUse);

// hooks
export const useTipoDeudaAcademicaFetchById = fetchById;
export const useTipoDeudaAcademicaLibbyCall = libbyCall;
export const useTipoDeudaAcademicaDAO = libbyDAO;
export const useTipoDeudaAcademicaLibbyFetch = libbyFetch;

// components/hoc
export const TipoDeudaAcademicaAutocomplete = entityAutocomplete;
export const TipoDeudaAcademicaCall = renderPropCall;
export const TipoDeudaAcademicaById = renderPropFetchById;
export const TipoDeudaAcademicaSelect = simpleSelect;
export const TipoDeudaAcademicaAccordionSelector = accordionSelector;
export const TipoDeudaAcademicaSelectedChips = selectedChips;

// context
// ByIdContext
export const useTipoDeudaAcademicaByIdContext = useByIdContext;
export const TipoDeudaAcademicaByIdProvider = ByIdProvider;
export const TipoDeudaAcademicaByIdContext = ByIdContext;
// GeneralContext
export const useTipoDeudaAcademicaContext = useGeneralContext;
export const TipoDeudaAcademicaProvider = GeneralProvider;
export const TipoDeudaAcademicaContext = GeneralContext;
