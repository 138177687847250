import React, { FC } from 'react';
import { Buttons } from '../Buttons';
import { Grid, Tooltip, makeStyles } from '@material-ui/core';
import { ObservacionesField } from '../ObservacionesField';
import { SliderEvaluaciones } from '../SliderEvaluaciones';
import { CheckboxCV } from '../CheckboxCV';
import { MainInput } from 'src/commons';
import { useParams } from 'react-router-dom';

const useStyles = makeStyles({
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    '& > div': {
      marginRight: '1em',
    },
    '& > article': {
      flex: 1,
    },
  },
  filterContainer: {
    padding: '1em',
    border: '1px solid rgba(30,30,30, 0.4)',
  },
});

const Form: FC<Acap.Organizacion.FormProps> = ({ grupo, lastElement }) => {
  const { fechaAcap } = useParams<{ fechaAcap: string }>();
  const classes = useStyles();

  const establecimiento =
    grupo.alumnoMovimiento.seccion.localizacion.establecimiento.nombre;
  const referenteOrganizacion = `${grupo.ofertaGrupo.ofertaTurno.oferta.accion.organizacion.referente.apellido} ${grupo.ofertaGrupo.ofertaTurno.oferta.accion.organizacion.referente.nombre}`;
  const referenteAccion = `${grupo.ofertaGrupo.referente.apellido} ${grupo.ofertaGrupo.referente.nombre}`;
  const organizacion =
    grupo!.ofertaGrupo.ofertaTurno.oferta.accion.organizacion.nombre;

  const estudiante = `${grupo.alumnoMovimiento.alumno.persona.apellido} ${grupo.alumnoMovimiento.alumno.persona.nombre}`;

  const actividad = grupo.ofertaGrupo.ofertaTurno.oferta.accion.nombre;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Grid
          container
          spacing={2}
          classes={{
            root: classes.filterContainer,
          }}
        >
          <Grid item xs={6} md={12}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={6}
                classes={{
                  root: classes.inputContainer,
                }}
              >
                <div style={{ fontFamily: 'Open Sans' }}>Organización:</div>
                <MainInput
                  value={organizacion}
                  handleChange={(e) => {}}
                  disabled={true}
                  placeholder=""
                  fullWidth={true}
                />
              </Grid>
              <Grid
                item
                xs={6}
                classes={{
                  root: classes.inputContainer,
                }}
              >
                <div style={{ fontFamily: 'Open Sans' }}>
                  Referente Organización:
                </div>
                <MainInput
                  value={referenteOrganizacion}
                  handleChange={(e) => {}}
                  disabled={true}
                  placeholder=""
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={12}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={8}
                classes={{
                  root: classes.inputContainer,
                }}
              >
                <div style={{ fontFamily: 'Open Sans' }}>Actividad:</div>
                <MainInput
                  value={actividad}
                  handleChange={(e) => {}}
                  disabled={true}
                  placeholder=""
                  fullWidth={true}
                />
              </Grid>
              <Grid
                item
                xs={4}
                classes={{
                  root: classes.inputContainer,
                }}
              >
                <div style={{ fontFamily: 'Open Sans' }}>Referente Acción:</div>
                <MainInput
                  value={referenteAccion}
                  handleChange={(e) => {}}
                  disabled={true}
                  placeholder=""
                  fullWidth={true}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={6} md={12}>
            <Grid container spacing={2}>
              <Grid
                item
                xs={4}
                classes={{
                  root: classes.inputContainer,
                }}
              >
                <div style={{ fontFamily: 'Open Sans' }}>Fecha:</div>
                <MainInput
                  value={fechaAcap}
                  handleChange={(e) => {}}
                  disabled={true}
                  placeholder=""
                />
              </Grid>
              <Grid
                item
                xs={4}
                classes={{
                  root: classes.inputContainer,
                }}
              >
                <div style={{ fontFamily: 'Open Sans' }}>Estudiante:</div>
                <MainInput
                  value={estudiante}
                  handleChange={(e) => {}}
                  disabled={true}
                  placeholder=""
                  fullWidth={true}
                />
              </Grid>
              <Grid
                item
                xs={4}
                classes={{
                  root: classes.inputContainer,
                }}
              >
                <div style={{ fontFamily: 'Open Sans' }}>Establecimiento:</div>
                <Tooltip
                  title={
                    grupo.alumnoMovimiento.seccion.localizacion.establecimiento
                      .nombre
                  }
                  placement="top"
                >
                  <MainInput
                    handleChange={(e) => {}}
                    disabled={true}
                    placeholder=""
                    value={establecimiento}
                    fullWidth={true}
                  />
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <SliderEvaluaciones />
      </Grid>
      <Grid item xs={12}>
        <ObservacionesField />
      </Grid>
      <Grid item xs={12}>
        <CheckboxCV />
      </Grid>
      <Buttons lastElement={lastElement} />
    </Grid>
  );
};

export default Form;
