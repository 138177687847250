import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Periodo } from '../models';

const DAO_NAME = 'periodo';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPeriodo',
      labelKey: 'idPeriodo',
    },
    withSelectedChips: {
      idKey: 'idPeriodo',
      labelKey: 'idPeriodo',
    },
    withSimpleSelect: {
      name: 'periodo',
      title: 'Período',
      placeholder: 'Período',
      labelKey: 'idPeriodo',
      valueKey: 'idPeriodo',
    },
  },
};

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Periodo>(DAO_NAME, defaultOptions);

// hooks
export const usePeriodoFetchById = fetchById;
export const usePeriodoLibbyCall = libbyCall;
export const usePeriodoDAO = libbyDAO;
export const usePeriodoLibbyFetch = libbyFetch;

// components/hoc
export const PeriodoAutocomplete = entityAutocomplete;
export const PeriodoCall = renderPropCall;
export const PeriodoById = renderPropFetchById;
export const PeriodoSelect = simpleSelect;
export const PeriodoAccordionSelector = accordionSelector;
export const PeriodoSelectedChips = selectedChips;

// context
// ByIdContext
export const usePeriodoByIdContext = useByIdContext;
export const PeriodoByIdProvider = ByIdProvider;
export const PeriodoByIdContext = ByIdContext;
// GeneralContext
export const usePeriodoContext = useGeneralContext;
export const PeriodoProvider = GeneralProvider;
export const PeriodoContext = GeneralContext;
