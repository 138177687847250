export enum ModalidadType {
  Default = 0,
  General = 1,
  EspecialByC = 2,
  Adultos = 3,
  EspecialAyDomiciliariaC = 4,
}
export interface Modalidad {
  idModalidad: ModalidadType;
  descripcion: string;
}
