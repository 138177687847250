import { CommonEndpointDAO } from './CommonEndpointDAO';

export class DescargardriveDAO extends CommonEndpointDAO {
  constructor() {
    super('descargardrive');
  }

  get(params: any = {}): any {
    return super.get(params);
  }

  post(body: any = {}): any {
    return super.post(body);
  }

  put(body: any = {}): any {
    return super.put(body);
  }

  delete(body: any = {}): any {
    return super.delete(body);
  }
}
