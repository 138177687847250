import { LibbyFetchDAO } from './LibbyFetchDAO';

export class PersonaDAO extends LibbyFetchDAO {
  constructor() {
    super('persona', 'idPersona');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  async getByCuentaId(idCuenta: number) {
    const res = await this.query().equals('cuentas', idCuenta).run();
    return res[0];
  }
}
