import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import {
  useDevolucionOrganizacionLibbyFetch,
  useEvaluacionesDevolucionesLibbyFetch,
  useReferentesLibbyFetch,
} from 'src/app/business/acap';
import { useCriteriosEvaluacionLibbyFetch } from 'src/app/business/acap/CriterioEvaluacion';
import { Loading } from 'src/commons';
import { useRolesContext } from 'src/context/RolesContext';
import { FormContextProvider } from 'src/lib/templates';
import { devOrganizacionTemplate } from './devOrganizacionTemplate';
import { useSubmitHandler } from './submitHandler';
import {
  useCuentasLibbyFetch,
  useGruposInscripcionesLibbyFetch,
} from 'src/app/business';
import Form from './Form';
import moment from 'moment';
import { getSeparateParams } from 'src/utils/decoders';
import { sortGruposAcap, useLinkedList } from 'src/utils';
import { EvaluacionDevolucion } from 'src/app/models';

export const DevolucionOrgView = () => {
  const { fechaAcap, rowGrupo } =
    useParams<Acap.Organizacion.DevolucionPathParams>();

  const [ofertaGrupo, idGrupoInscripcion] = getSeparateParams(rowGrupo);

  const gruposFilter = useMemo(
    () => ({
      grupo: [
        {
          path: 'ofertaGrupo',
          value: ofertaGrupo,
        },
      ],
    }),
    [ofertaGrupo],
  );

  const { data: grupos = [], working: loadingGrupos } =
    useGruposInscripcionesLibbyFetch({
      filter: gruposFilter,
      limit: 50,
      aspect: 'estudiante-list',
      enabled: true,
      orderBy: 'idGrupoInscripcion',
      direction: 'asc',
    });

  const { current, disabledNext, nextStudent, setCurrent } = useLinkedList({
    items: sortGruposAcap(grupos),
    loading: loadingGrupos,
    id: 'idGrupoInscripcion',
  });

  useEffect(() => {
    if (current) return;
    const sordeted = sortGruposAcap(grupos);
    const index = sordeted.findIndex(
      (grupo) =>
        Number(grupo.idGrupoInscripcion) === Number(idGrupoInscripcion),
    );
    setCurrent(sordeted[index]);
  }, [grupos, current, idGrupoInscripcion, setCurrent]);

  const {
    userInfo: { id },
  } = useRolesContext();

  const cuentaFilter = useMemo(
    () => ({
      usuario: [
        {
          path: 'idUsuario',
          value: id,
        },
      ],
    }),
    [id],
  );
  const { data: cuentas = [] } = useCuentasLibbyFetch({
    limit: 1,
    filter: cuentaFilter,
    enabled: Boolean(id),
  });

  const refFilter = useMemo(
    () => ({
      usuario: [
        {
          path: 'email',
          value: cuentas[0]?.username,
        },
      ],
    }),
    [cuentas],
  );

  const { data: referentes = [] } = useReferentesLibbyFetch({
    limit: 1,
    filter: refFilter,
    enabled: Boolean(cuentas),
  });

  const criteriosFilter = useMemo(
    () => ({
      organizacion: [
        {
          path: 'forOrganizacion',
          value: true,
        },
      ],
    }),
    [],
  );

  const { data: criteriosEvaluacion = [] } = useCriteriosEvaluacionLibbyFetch({
    limit: 4,
    filter: criteriosFilter,
  });

  const devFilter = useMemo(
    () => ({
      grupo: [
        {
          path: 'grupoInscripcion',
          value: current?.idGrupoInscripcion,
        },
      ],
      fechaAcap: [
        {
          path: 'fechaAcap',
          value: fechaAcap,
        },
      ],
    }),
    [fechaAcap, current],
  );

  const {
    data: devoluciones = [],
    working: loadingDevoluciones,
    reFetch: reFetchDev,
  } = useDevolucionOrganizacionLibbyFetch({
    filter: devFilter,
    enabled:
      Boolean(current?.idGrupoInscripcion) &&
      criteriosEvaluacion.length > 0 &&
      referentes.length > 0,
    aspect: 'basic',
    orderBy: 'updatedAt',
    direction: 'desc',
  });

  const evaluacionesDevFilter = useMemo(
    () => ({
      devolucion: [
        {
          path: 'devolucion',
          value: devoluciones[0]?.idDevolucionOrganizacion,
        },
      ],
    }),
    [devoluciones],
  );

  const {
    data: devolucionesEvaluaciones = [],
    reFetch: reFetchDevEvaluaciones,
  } = useEvaluacionesDevolucionesLibbyFetch({
    filter: evaluacionesDevFilter,
    enabled: devoluciones.length > 0,
    aspect: 'default',
  });

  const devInitialValues = useMemo(() => {
    const _devoluciones = criteriosEvaluacion.map<
      Partial<EvaluacionDevolucion>
    >((ceval) => {
      const _devolcuion = devolucionesEvaluaciones.find(
        (de) => de.criterio.idCriterioEvaluacion === ceval.idCriterioEvaluacion,
      );
      const _evaluacion: Partial<EvaluacionDevolucion> = {
        ...(_devolcuion ? _devolcuion : {}),
        devolucion: _devolcuion?.devolucion,
        criterio: ceval,
        valor: _devolcuion?.valor || 0,
      };
      return _evaluacion;
    });
    return {
      idDevolucionOrganizacion: devoluciones[0]?.idDevolucionOrganizacion,
      grupoInscripcion: current?.idGrupoInscripcion,
      observaciones: devoluciones[0]?.observaciones ?? '',
      citarCv: Number(devoluciones[0]?.citarCv ?? 0),
      evaluaciones: _devoluciones,
      createdAt: devoluciones[0]?.createdAt,
      createdBy: devoluciones[0]?.createdBy,
      fechaAcap: moment(fechaAcap)
        .set({
          hour: 0,
          minute: 0,
          second: 0,
        })
        .toISOString(),
    };
  }, [
    current,
    devoluciones,
    devolucionesEvaluaciones,
    fechaAcap,
    criteriosEvaluacion,
  ]);

  const submitHandler = useSubmitHandler(
    reFetchDev,
    reFetchDevEvaluaciones,
    nextStudent,
  );

  return loadingGrupos || loadingDevoluciones ? (
    <Loading />
  ) : grupos.length > 0 ? (
    <FormContextProvider
      template={devOrganizacionTemplate}
      onSubmit={submitHandler}
      initialValues={devInitialValues}
    >
      {Boolean(current) ? (
        <Form lastElement={disabledNext} grupo={current!} />
      ) : (
        <></>
      )}
    </FormContextProvider>
  ) : null;
};
