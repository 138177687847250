import React from 'react';
import { Grid } from '@material-ui/core';
import { grey } from 'src/theme/colors';
import {
  FilterTablesGroupGradeContentTypes,
  FilterTablesGroupGradeTypes,
  SimpleSelect,
  MainInput,
} from '../../../../../../../../commons';

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  searchGrid: { marginTop: 10 },
  containerStyles: { marginTop: 23 },
  inputSearch: { padding: 10 },
};

export const FilterTablesGroupGrade = ({ content }: any) => (
  <Grid container justify="flex-start" alignItems="center" spacing={3}>
    {content.map((e: any) =>
      e.map((t: FilterTablesGroupGradeContentTypes) =>
        !t.input ? (
          <Grid item xs={2}>
            <SimpleSelect
              {...t}
              handleChange={t.handleChange}
              content={t.content}
              value={t.value}
              customStyleTitle={customStyles.customStyleTitleSelect}
            />
          </Grid>
        ) : (
          <Grid item xs={2}>
            <Grid container spacing={3} style={customStyles.searchGrid}>
              <Grid item>
                <MainInput
                  type="search"
                  handleChange={t.handleChange}
                  placeholder="Buscar"
                  name="search"
                  value={t.value}
                  fullWidth
                  customStyle={customStyles.inputSearch}
                />
              </Grid>
            </Grid>
          </Grid>
        ),
      ),
    )}
  </Grid>
);
