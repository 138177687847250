import { MenuItem } from '../../../../commons/const/ui/menu';
import { routes } from './routes';

export const ciudadaniaGlobal: MenuItem = {
  key: 'ciudadania',
  basePath: '/ciudadania-global',
  enabled: true,
  icon: 'List',
  text: 'Ciudadania global',
  children: routes,
};
