import { ApreciacionValorDAO } from 'src/platform/libby/dao';
import { makeBusinessEntity } from '../../../lib/libby/generator';
import { ApreciacionValor } from '../../models';

const DAO_NAME = 'acap.apreciaciones_valores';

const {
  hook: { libbyDAO, libbyFetch },
} = makeBusinessEntity<ApreciacionValor, ApreciacionValorDAO>(DAO_NAME, {});

// hooks;
export const useApreciacionValorDAO = libbyDAO;
export const useApreciacionValorLibbyFetch = libbyFetch;
