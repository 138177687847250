import React, { useCallback, useMemo, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Footer, InfoTable, Loading, useBackButton } from 'src/commons';
import {
  useAreaDeConocimientoRows,
  useAreaDeConocimientoTableColumns,
  useAreaDeConocimientoValidator,
} from './hooks';
import {
  useCalificacionesSwitch,
  useCalificacionesSubmit,
  useExcelData,
  validateLeave,
} from '../../../hooks';
import { useCalificacionesContext } from '../../../context';
import { AlertMessage } from '../../../components';
import { CalificacionesFiltersHeader } from '../../../components/CalificacionesFiltersHeader/CalificacionesFiltersHeader';
import { usePrimarioDAO } from 'src/app/business/Calificaciones/Primario';
import { useHistory } from 'react-router';
import { useRolesContext } from 'src/context/RolesContext';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useColegioEfectivizadoContext } from '../../../context/EfectivizacionProvider';
import { useRoleCanOpenGrade } from '../../../hooks/useRoleCanOpenGrade';

const useStyles = makeStyles({
  table: {
    maxHeight: '600px',
    marginBottom: '80px',
  },
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export const AreaDeConocimiento = ({
  anioFilter,
}: {
  anioFilter: {
    anio: {
      path: string;
      value: unknown;
    }[];
  };
}) => {
  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();
  const classes = useStyles();
  const history = useHistory();
  const {
    selectedRole: { localizacionId },
  } = useRolesContext();
  const primarioDAO = usePrimarioDAO();
  const { filter, setFilter } = useCalificacionesContext();

  const { columns, working: columnsWorking } =
    useAreaDeConocimientoTableColumns(filter.espacioCurricularSeccion);

  const {
    rows,
    working: dataWorking,
    _rows,
    data,
    reFetch,
    readyToClose,
    isClosed,
    hasCalifications,
  } = useAreaDeConocimientoRows(filter, columns);

  const working = useMemo(
    () => dataWorking || columnsWorking,
    [columnsWorking, dataWorking],
  );

  const showView = useMemo(
    () =>
      filter.anio &&
      filter.seccion &&
      filter.periodo &&
      filter.espacioCurricularSeccion,
    [filter],
  );

  const fieldsToValidate = useMemo(
    () =>
      columns
        .map((column) => column.id)
        .filter((column) => column !== 'nombre'),
    [columns],
  );

  const { incompletedRows, isDisabled, submitRows } =
    useAreaDeConocimientoValidator({
      fieldsToValidate,
      _rows,
      filter,
      working,
      data,
    });

  const [anioSelect, setAnioSelect] = useState('');
  const [seccionSelect, setSeccionSelect] = useState('');
  const [periodoSelect, setPeriodoSelect] = useState('');
  const [espacioSeccionSelect, setEspacioSeccionSelect] = useState('');

  const { excelData } = useExcelData(
    columns,
    _rows,
    `Calificaciones - Area De Conocimiento - ${periodoSelect} - ${seccionSelect} - ${espacioSeccionSelect} - ${localizacionId}`,
    periodoSelect,
    anioSelect,
    seccionSelect,
    espacioSeccionSelect,
  );

  const { onSubmit, submitting } = useCalificacionesSubmit({
    columns,
    _rows,
    data,
    filter,
    dao: primarioDAO,
    reFetch,
    isInitial: true,
  });

  const { onSwitch, switchSubmitting } = useCalificacionesSwitch({
    _rows,
    filter,
    dao: primarioDAO,
    reFetch,
    isInitial: true,
  });

  const handleLeave = useCallback(
    async (route?: string) => {
      if (!establecimientoEfectivizado) {
        history.push(typeof route === 'string' ? route : '/');
        return;
      }
      const confirm = await confirmDialog.show({
        title: '¿Desea continuar?',
        content:
          'La información precargada se perderá en caso que no guarde, por favor seleccione una opción.',
        confirmText: 'Salir sin guardar',
        cancelText: 'Seguir en esta pantalla',
      });
      if (confirm) {
        try {
          history.push(route ?? '/');
        } catch (e) {
          console.log(e);
        }
      }
    },
    [history, establecimientoEfectivizado],
  );

  const confirmarSalirConBackButton = useCallback(() => {
    if (!(isDisabled || isClosed || submitRows)) {
      handleLeave('/private/calificaciones');
    } else {
      history.push('/private/calificaciones');
    }
  }, [handleLeave, history, isClosed, isDisabled, submitRows]);

  useBackButton('/calificaciones', 0, confirmarSalirConBackButton);

  const canOpenGrade = useRoleCanOpenGrade(['1', '5']);

  const buttonConfig: any = useMemo(() => {
    const defaultButtons: any[] = [
      {
        title: 'Salir',
        size: 'medium',
        type: 'secondary',
        handleOnClick: handleLeave,
      },
      {
        title: 'Guardar',
        size: 'medium',
        handleOnClick: onSubmit,
        disabled:
          isDisabled || isClosed || submitRows || !establecimientoEfectivizado,
      },
    ];

    if (!isClosed) {
      defaultButtons.push({
        title: 'Cerrar Calificaciones',
        size: 'medium',
        handleOnClick: () => onSwitch('close'),
        disabled:
          isDisabled ||
          !readyToClose ||
          isClosed ||
          !hasCalifications ||
          !establecimientoEfectivizado,
      });
    } else if (canOpenGrade) {
      defaultButtons.push({
        title: 'Abrir Calificaciones',
        size: 'medium',
        handleOnClick: () => onSwitch('open'),
        disabled: !establecimientoEfectivizado,
      });
    }
    return defaultButtons;
  }, [
    establecimientoEfectivizado,
    handleLeave,
    onSubmit,
    isDisabled,
    isClosed,
    submitRows,
    canOpenGrade,
    readyToClose,
    onSwitch,
    hasCalifications,
  ]);

  const handleAnioChange = (options: any) => {
    const anio = options?.find((s: any) => s.idAnio === filter.anio);
    setAnioSelect(anio?.descripcionAnio);
  };

  const handleSeccionesChange = (options: any) => {
    const seccion = options?.find((s: any) => s.idSeccion === filter.seccion);
    setSeccionSelect(seccion?.nombreSeccion);
  };

  const handlePeriodosChange = (options: any) => {
    const periodo = options?.find((p: any) => p.idPeriodo === filter.periodo);
    setPeriodoSelect(periodo?.tipoPeriodo.descripcionTipoPeriodo);
  };

  const handleEspaciosSeccionChange = (options: any) => {
    const espacioSeccion = options?.find(
      (p: any) =>
        p.idEspacioCurricularSeccion === filter.espacioCurricularSeccion,
    );
    setEspacioSeccionSelect(espacioSeccion?.espacioCurricular.descripcion);
  };

  return (
    <>
      <AlertMessage
        incompletedRows={incompletedRows}
        isDisabled={isDisabled}
        isClosed={isClosed}
        working={working}
        showView={showView}
        establecimientoEfectivizado={establecimientoEfectivizado}
      />
      <CalificacionesFiltersHeader
        filter={filter}
        setFilter={setFilter}
        showEspacioCurricular
        excelData={excelData}
        handleSeccionesChange={handleSeccionesChange}
        handlePeriodosChange={handlePeriodosChange}
        handleAnioChange={handleAnioChange}
        showView={showView}
        incompletedRows={incompletedRows}
        readyToClose={readyToClose}
        handleEspaciosSeccionChange={handleEspaciosSeccionChange}
        setEspacioSeccionSelect={setEspacioSeccionSelect}
        anioCustomFilter={anioFilter}
      />
      {showView ? (
        <>
          {working ? (
            <Loading />
          ) : (
            <InfoTable
              rows={rows}
              columns={columns}
              customStyle={classes.table}
            />
          )}
          <Grid className={classes.footer}>
            <Footer
              buttonConfig={buttonConfig}
              loading={submitting || switchSubmitting}
            />
          </Grid>
        </>
      ) : (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: '400px' }}
        >
          <Typography
            style={{
              fontSize: '40px',
              marginTop: '50px',
              color: 'gray',
              fontWeight: 'bold',
              lineHeight: '45px',
            }}
          >
            Seleccione año, sección, período y área de conocimiento para
            calificar.
          </Typography>
        </Grid>
      )}
    </>
  );
};
