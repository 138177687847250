import { LibbyFetchDAO } from './LibbyFetchDAO';

export class GruposSeccionDAO extends LibbyFetchDAO {
  constructor() {
    super('grupos_seccion', 'idGrupoSeccion');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
