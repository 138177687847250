import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useAlumnoMovimientoLibbyFetch } from 'src/app/business';
import { usePpsLibbyFetch } from 'src/app/business/Pps';
import { InfoTable, useDebounce } from 'src/commons';
import { FiltersTableAlumnos } from '../components/filtersTableAlumnos';
import { useTableAlumnosColumns } from '../hooks/useTableAlumnosColumns';
import { Loading } from 'src/commons/components/Loading';
import { setSearchParamsMultipleWords } from 'src/utils/setSearchParamsMultipleWords';

export const TableAlumnos = ({
  setInformePps,
  setData,
  setCurrentFormValues,
  currentFormValues,
}: any) => {
  const [search, setSearch] = useState<string>('');
  const searchDebounced = useDebounce(search, 1000);
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('alumno.persona.apellido');

  const searchFilterParams = useMemo(
    () => ({
      ...setSearchParamsMultipleWords(
        [
          'alumno.persona.nombre',
          'alumno.persona.apellido',
          'alumno.persona.documento',
        ],
        searchDebounced,
      ),
    }),
    [searchDebounced],
  );

  const alumnoMovimientoFilter = useMemo(() => {
    if (searchDebounced) {
      return {
        seccion: [{ path: 'seccion', value: currentFormValues?.seccion }],
        ...searchFilterParams,
      };
    }
    return {
      seccion: [{ path: 'seccion', value: currentFormValues?.seccion }],
    };
  }, [currentFormValues?.seccion, searchDebounced, searchFilterParams]);

  const { data: alumnos = [], working: workingAlumnos } =
    useAlumnoMovimientoLibbyFetch({
      limit: 500,
      filter: alumnoMovimientoFilter,
      direction,
      orderBy,
      enabled: !!currentFormValues?.seccion,
    });

  const ppsAlumnos = useMemo(() => {
    if (!!alumnos) {
      const filter = alumnos.map((alumno: any) => {
        return {
          path: 'alumno',
          value: alumno.alumno?.idAlumno,
        };
      });

      return filter;
    }
  }, [alumnos]);

  const ppsFilter = useMemo(
    () => ({
      alumno: ppsAlumnos,
    }),
    [ppsAlumnos],
  );

  const {
    data: pps = [],
    working: workingPps,
    reFetch,
  } = usePpsLibbyFetch({
    limit: 500,
    filter: ppsFilter,
    enabled: !!alumnos.length,
    checkDuplication: false,
    aspect: 'basic',
  });

  useEffect(() => {
    if (!!pps) {
      setData(pps);
    }
  }, [pps, setData]);

  const onChange = useCallback(
    (e: any) => {
      const value = e.target.value;
      const name = e.target.name;
      const newValue = { ...currentFormValues, [name]: value };
      if (name === 'anio' && value === '') {
        newValue['seccion'] = '';
      }

      setCurrentFormValues(newValue);
    },
    [currentFormValues, setCurrentFormValues],
  );

  const handleChangeAlumno = (row: any, button: any) => {
    setInformePps({ row, button });
  };

  const filteredAlumnos = useMemo(() => {
    let filterAlumnos: any = [];
    if (currentFormValues?.estado !== '') {
      alumnos.forEach((alumno: any) => {
        const ppsFind = pps.find(
          (element: any) =>
            element?.alumno?.idAlumno === alumno.alumno?.idAlumno,
        );
        if (
          currentFormValues?.estado === 'No iniciado' &&
          ppsFind === undefined
        ) {
          filterAlumnos.push(alumno);
        } else if (
          currentFormValues?.estado === 'En proceso' &&
          ppsFind?.abierto
        ) {
          filterAlumnos.push(alumno);
        } else if (
          currentFormValues?.estado === 'Finalizado' &&
          ppsFind?.abierto === false
        ) {
          filterAlumnos.push(alumno);
        }
      });
    } else {
      filterAlumnos = alumnos;
    }
    return filterAlumnos;
  }, [alumnos, currentFormValues?.estado, pps]);

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setDirection(newDirection);
    setOrderBy(newOrderBy);
  };

  const columns = useTableAlumnosColumns(handleChangeAlumno, pps, reFetch);

  return (
    <>
      <FiltersTableAlumnos
        onChange={onChange}
        search={search}
        setSearch={setSearch}
        currentFormValues={currentFormValues}
      />
      {alumnos.length &&
      currentFormValues.seccion &&
      currentFormValues?.anio ? (
        <InfoTable
          rows={filteredAlumnos}
          columns={columns}
          onSortChange={handleRequestSort}
          direction={direction}
        />
      ) : !workingAlumnos ? (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: '400px' }}
        >
          <Typography
            style={{
              fontSize: '40px',
              marginTop: '50px',
              color: 'gray',
              fontWeight: 'bold',
            }}
          >
            Seleccione año y sección para cargar informes.
          </Typography>
        </Grid>
      ) : (
        <Loading />
      )}
    </>
  );
};
