import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Footer, InfoTable, Loading, useBackButton } from 'src/commons';
import {
  useCalificacionesSecundarioRows,
  useCalificacionesSecundarioColumns,
} from './hooks';
import {
  useCalificacionesSubmit,
  useCalificacionesSwitch,
  useValidator,
} from '../hooks';
import { AlertMessage } from '../components';
import { CalificacionesFiltersHeader } from '../components/CalificacionesFiltersHeader/CalificacionesFiltersHeader';
import { useSecundarioDAO } from 'src/app/business/Calificaciones/Secundario';
import { useHistory } from 'react-router';
import { useRolesContext } from 'src/context/RolesContext';
import { PermissionBlocker } from 'src/lib/permission/components/PermissionBlocker';
import { BLOCKER_ID } from 'src/platform/permission/const/BlockerId';
import confirmDialog from 'src/commons/services/confirmDialog';
import { CalificacionColumn } from './hooks/useCalificacionesSecundarioColumns';
import { useCalificacionesSecundariaExcelData } from './hooks/useCalificacionesSecundariaExcelData';
import { useColegioEfectivizadoContext } from '../context/EfectivizacionProvider';
import { useRoleCanOpenGrade } from '../hooks/useRoleCanOpenGrade';

const useStyles = makeStyles({
  table: {
    maxHeight: '600px',
    marginBottom: '80px',
  },
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export type CalificacionesFilter = {
  anio: string;
  periodo: number;
  espacioCurricularSeccion: string;
  seccion: string;
  search: string;
};

export const CalificacionesSecundario = ({
  periodo,
  working: validYearsWorking,
  validYears,
  fromEC = false,
}: {
  periodo: number;
  working: boolean;
  validYears: {
    anio: {
      path: string;
      value: unknown;
    }[];
  };
  fromEC?: boolean;
}) => {
  const { establecimientoEfectivizado, waiting: efectWorking } =
    useColegioEfectivizadoContext();
  const periodoMapping: any = {
    1: 1, // Primer Bimestre
    2: 3, // Segundo Bimestre
    3: 7, // Tercer Bimestre
    4: 8, // Cuarto Bimestre
  };
  const idPeriodo = periodoMapping[periodo];
  const { idEspacioCurricular, idAnio } = useParams<any>();
  const classes = useStyles();
  const history = useHistory();
  const secundarioDAO = useSecundarioDAO();
  const [filter, setFilter] = useState<CalificacionesFilter>({
    anio: idAnio ?? '',
    periodo: idPeriodo,
    espacioCurricularSeccion: '',
    seccion: '',
    search: '',
  });
  const roleInfo = useRolesContext();
  const [seccionSelect, setSeccionSelect] = useState('');
  const [periodoSelect, setPeriodoSelect] = useState({
    descripcionTipoPeriodo: '',
    idTipoPeriodo: -1,
  });
  const [anioSelect, setAnioSelect] = useState('');
  const [espacioSeccionSelect, setEspacioSeccionSelect] = useState('');
  const [espacioCurricularNombre, setEspacioCurricularNombre] = useState('');
  const columns = useCalificacionesSecundarioColumns(periodo + '');
  const {
    rows,
    working,
    _rows,
    data,
    reFetch,
    readyToClose,
    isClosed,
    ecs,
    hasCalifications,
    espacioCurricularSeccion,
    ecsWorking,
  } = useCalificacionesSecundarioRows(filter, idEspacioCurricular, fromEC);

  const fieldsToValidate = useMemo(
    () =>
      columns
        .map((column: CalificacionColumn) => column.id)
        .filter(
          (column: string) =>
            column !== 'nombre' && column !== 'anualDefinitiva',
        ),
    [columns],
  );

  const { incompletedRows, isDisabled, submitRows } = useValidator({
    fieldsToValidate,
    _rows: _rows,
    filter: filter,
    data: data,
  });

  const espacioCurricularName = useMemo(() => {
    const espacioCurricularDescripcion =
      espacioCurricularSeccion[0]?.espacioCurricular?.descripcion;
    return espacioSeccionSelect !== ''
      ? espacioSeccionSelect
      : espacioCurricularDescripcion;
  }, [espacioCurricularSeccion, espacioSeccionSelect]);

  const anioEspacioCurricularSeccion = useMemo(() => {
    const anio = espacioCurricularSeccion[0]?.seccion?.cicloLectivo?.anio;
    return anio;
  }, [espacioCurricularSeccion]);

  useEffect(() => {
    setEspacioCurricularNombre(espacioCurricularName);
  }, [espacioCurricularName]);

  const { excelData } = useCalificacionesSecundariaExcelData(
    _rows,
    `Calificaciones - ${espacioCurricularName} - ${periodoSelect?.descripcionTipoPeriodo} - ${seccionSelect} - ${roleInfo?.selectedRole?.localizacionId}`,
    periodoSelect,
    anioSelect,
    anioEspacioCurricularSeccion,
    seccionSelect,
    espacioCurricularName,
  );

  const showTable = useMemo(() => {
    const filters = filter.anio && filter.seccion && filter.periodo;
    return idEspacioCurricular
      ? Boolean(filters)
      : Boolean(filters && filter.espacioCurricularSeccion);
  }, [idEspacioCurricular, filter]);

  const { onSubmit, submitting } = useCalificacionesSubmit({
    columns,
    _rows,
    data,
    filter,
    dao: secundarioDAO,
    reFetch,
    isSecundario: true,
    ecs,
    isInitial: false,
  });

  const { onSwitch, switchSubmitting } = useCalificacionesSwitch({
    filter,
    dao: secundarioDAO,
    reFetch,
    isSecundario: true,
    ecs,
  });

  const handleLeave = useCallback(
    async (route?: string) => {
      if (!establecimientoEfectivizado && ![4, 5].includes(periodo)) {
        history.push(route ?? '/');
        return;
      }
      if (incompletedRows.length || (!readyToClose && showTable)) {
        const confirm = await confirmDialog.show({
          title: '¿Desea continuar?',
          content:
            'La información precargada se perderá en caso que no guarde, por favor seleccione una opción.',
          confirmText: 'Salir sin guardar',
          cancelText: 'Seguir en esta pantalla',
        });
        if (confirm) history.push(route ?? '/');
      } else {
        history.push(route ?? '/');
      }
    },
    [
      periodo,
      history,
      incompletedRows.length,
      readyToClose,
      showTable,
      establecimientoEfectivizado,
    ],
  );

  const confirmarSalirConBackButton = useCallback(() => {
    if (!(isDisabled || isClosed || submitRows)) {
      handleLeave('/private/calificaciones');
    } else {
      history.push('/private/calificaciones');
    }
  }, [handleLeave, history, isClosed, isDisabled, submitRows]);

  useBackButton('/calificaciones', 0, confirmarSalirConBackButton);

  const canOpenGrade = useRoleCanOpenGrade(['1', '5']);
  const buttonConfig: any = useMemo(() => {
    const defaultButtons: any[] = [
      {
        title: 'Salir',
        size: 'medium',
        type: 'secondary',
        handleOnClick: () => handleLeave(),
      },
      {
        title: 'Guardar',
        size: 'medium',
        handleOnClick: onSubmit,
        disabled: isDisabled || isClosed || submitRows,
        // || (!establecimientoEfectivizado && ![4, 5].includes(periodo)),
      },
    ];

    if (!isClosed) {
      defaultButtons.push({
        title: 'Cerrar Calificaciones',
        size: 'medium',
        handleOnClick: () => onSwitch('close'),
        disabled:
          isDisabled ||
          !readyToClose ||
          isClosed ||
          !hasCalifications ||
          !submitRows,
        // || (!establecimientoEfectivizado && ![4, 5].includes(periodo)),
      });
    } else if (canOpenGrade) {
      defaultButtons.push({
        title: 'Abrir Calificaciones',
        size: 'medium',
        handleOnClick: () => onSwitch('open'),
        // disabled: !establecimientoEfectivizado && ![4, 5].includes(periodo),
      });
    }
    return defaultButtons;
  }, [
    // periodo,
    handleLeave,
    onSubmit,
    isDisabled,
    isClosed,
    submitRows,
    // establecimientoEfectivizado,
    canOpenGrade,
    readyToClose,
    hasCalifications,
    onSwitch,
  ]);

  const handleAnioChange = (options: any) => {
    const anio = options?.find((s: any) => s.idAnio === filter.anio);
    setAnioSelect(anio?.descripcionAnio);
  };

  const handleSeccionesChange = (options: any) => {
    const espacioCurricularCustomFound = options?.find(
      (s: any) =>
        s.seccion?.idSeccion === filter.seccion ||
        s.idSeccion === filter.seccion,
    );
    if (espacioCurricularCustomFound) {
      const sectionName = espacioCurricularCustomFound.nombreSeccion
        ? espacioCurricularCustomFound.nombreSeccion
        : espacioCurricularCustomFound.seccion?.nombreSeccion;
      setSeccionSelect(sectionName);
    }
  };

  const handlePeriodosChange = (options: any) => {
    const periodo = options?.find((p: any) => p.idPeriodo === filter.periodo);
    setPeriodoSelect(periodo?.tipoPeriodo);
  };

  const handleEspaciosSeccionChange = (options: any) => {
    const espacioSeccion = options?.find(
      (p: any) =>
        p.idEspacioCurricularSeccion === filter.espacioCurricularSeccion,
    );
    setEspacioSeccionSelect(espacioSeccion?.espacioCurricular.descripcion);
  };

  return (
    <>
      <AlertMessage
        incompletedRows={incompletedRows}
        isDisabled={isDisabled}
        isClosed={isClosed}
        working={working && efectWorking}
        showView={showTable}
        establecimientoEfectivizado={
          establecimientoEfectivizado || [4, 5].includes(periodo)
        }
      />
      {validYearsWorking ? (
        <Loading />
      ) : (
        <>
          <CalificacionesFiltersHeader
            data={data}
            rows={_rows}
            filter={filter}
            setFilter={setFilter}
            showEspacioCurricular={!idEspacioCurricular}
            idAnio={idAnio}
            excelData={excelData}
            showView={hasCalifications}
            handleSeccionesChange={handleSeccionesChange}
            handlePeriodosChange={handlePeriodosChange}
            handleAnioChange={handleAnioChange}
            incompletedRows={incompletedRows}
            readyToClose={readyToClose}
            handleEspaciosSeccionChange={handleEspaciosSeccionChange}
            setEspacioSeccionSelect={setEspacioSeccionSelect}
            anioCustomFilter={{ anio: validYears?.anio }}
            fromEC={fromEC}
          />
          {showTable && !ecsWorking ? (
            <>
              <InfoTable
                rows={rows}
                columns={columns}
                customStyle={classes.table}
                working={working}
              />
              <PermissionBlocker id={BLOCKER_ID.QUALIFY_ESTUDENS}>
                <Grid className={classes.footer}>
                  <Footer
                    buttonConfig={buttonConfig}
                    loading={submitting || switchSubmitting}
                  />
                </Grid>
              </PermissionBlocker>
            </>
          ) : (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: '400px' }}
            >
              <Typography
                style={{
                  fontSize: '40px',
                  marginTop: '50px',
                  color: 'gray',
                  fontWeight: 'bold',
                  lineHeight: '45px',
                }}
              >
                {!idEspacioCurricular
                  ? 'Seleccione año, sección, período y espacio curricular.'
                  : 'Seleccione año, sección y período.'}
              </Typography>
            </Grid>
          )}
        </>
      )}
    </>
  );
};
