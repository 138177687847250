import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class ContraturnoDiaDAO extends LibbyFetchDAO {
  constructor() {
    super('contraturno_dia', 'idContraturnoDia');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getContraturnoDiaByIdContraturno(idContraturno: string) {
    return this.aspect('ContraturnoDiaAsp')
      .query()
      .equals('contraturno.idContraturno', idContraturno)
      .equals('active', true)
      .run();
  }
}
