import { Grid } from '@material-ui/core';
import React from 'react';
import { grey } from 'src/theme/colors';
import {
  FilterTablesCycleOpelTypes,
  FilterTablesCycleOpelTypesContentTypes,
} from 'src/commons';
import { MainInput, SimpleSelect } from 'src/commons/components';

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  searchGrid: { marginTop: 10 },
  containerStyles: { marginTop: 23 },
  inputStyle: { padding: 10 },
};

export const FilterTablesSchoolAdults = ({
  content,
}: FilterTablesCycleOpelTypes) => (
  <>
    <Grid container justify="flex-start" alignItems="center" spacing={3}>
      {content.map((e: any) =>
        e.map((t: FilterTablesCycleOpelTypesContentTypes) =>
          !t.input ? (
            <Grid item xs={2}>
              <SimpleSelect
                {...t}
                handleChange={t.handleChange}
                content={t.content}
                value={t.value}
                customStyleTitle={customStyles.customStyleTitleSelect}
              />
            </Grid>
          ) : (
            <Grid
              container
              xs={4}
              justify="flex-end"
              spacing={3}
              alignItems="flex-end"
              style={customStyles.searchGrid}
            >
              <Grid
                container
                xs={10}
                justify="flex-end"
                spacing={3}
                alignItems="flex-end"
              >
                <Grid item xs={12}>
                  <MainInput
                    type="search"
                    handleChange={t.handleChange}
                    placeholder="Buscar"
                    name="search"
                    value={t.value}
                    fullWidth
                    customStyle={customStyles.inputStyle}
                  />
                </Grid>
              </Grid>
            </Grid>
          ),
        ),
      )}
    </Grid>
  </>
);
