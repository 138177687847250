import React from 'react';
import {
  ObjetivosPlan,
  ObjetivosDeAprendizaje as ObjetivosAprendijaze,
} from 'src/app/models';
import { Grid } from '@material-ui/core';
import { useDisciplinarComunContext } from '../../../forms/PlanificacionDisciplinarComun/components/context';
import { ListItemProperties } from '../../../feedbacks/commons';

export interface ObjetivosDeAprendizajeProps {
  data: ObjetivosPlan;
}

export const ObjetivosDeAprendizaje = ({
  data,
}: ObjetivosDeAprendizajeProps) => {
  const {
    data: { objetivos },
    working,
  } = useDisciplinarComunContext();

  const filterIndicadores = data?.objetivosDeAprendizaje?.map(
    (objetivo: string) => {
      const valor = objetivos.find(
        (_objetivo: ObjetivosAprendijaze) => _objetivo.idObjetivo === objetivo,
      );
      return {
        id: Number(valor?.idObjetivo),
        descripcion: valor?.descripcion || '',
      };
    },
  );
  filterIndicadores.push(...data.adicionales);
  return (
    <Grid container direction="column">
      {!working && <ListItemProperties values={filterIndicadores} />}
    </Grid>
  );
};
