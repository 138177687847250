import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { JustificacionMotivo, JustificacionRazon } from '../../models';
import { businessOptions } from '../businessOptions';
import { JustificacionRazonDAO } from '../../../platform/libby/dao/Presentismov3/JustificacionRazonDAO';

const DAO_NAME = 'justificacionRazon';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idRazonJustificacion',
      labelKey: 'description',
    },
    withSelectedChips: {
      idKey: 'idRazonJustificacion',
      labelKey: 'description',
    },
    withSimpleSelect: {
      labelKey: 'description',
      valueKey: 'idRazonJustificacion',
      title: '',
      placeholder: 'Seleccioná Justificación',
      name: 'idRazonJustificacion',
    },
  },
};

const optionsToUse = !businessOptions.JustificacionMotivo
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.JustificacionMotivo,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.JustificacionMotivo.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    selectInput,
  },
} = makeBusinessEntity<JustificacionRazon, JustificacionRazonDAO>(
  DAO_NAME,
  optionsToUse,
);

// hooks
export const useJustificacionRazonFetchById = fetchById;
export const useJustificacionRazonLibbyCall = libbyCall;
export const useJustificacionRazonDAO = libbyDAO;
export const useJustificacionRazonLibbyFetch = libbyFetch;

// components/hoc
export const JustificacionRazonAutocomplete = entityAutocomplete;
export const JustificacionRazonCall = renderPropCall;
export const JustificacionRazonById = renderPropFetchById;
export const JustificacionRazonSelect = simpleSelect;

export const JustificationRazonSelectInput = selectInput;
