import { Grid, Typography, makeStyles } from '@material-ui/core';
import React from 'react';

const useStyles = makeStyles((theme) => ({
  centered: {
    textAlign: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    fontSize: '24px',
    fontFamily: 'Nunito, sans-serif',
  },
  container: {
    backgroundColor: 'white',
  },
}));

export const VistaPdfJustificacionVacio = () => {
  const classes = useStyles();
  return (
    <div style={{ padding: 50 }}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        className=""
      >
        <Typography className={classes.centered}>
          No se pudo abrir el archivo
        </Typography>
      </Grid>
    </div>
  );
};
