import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { ContenidoEspecificoSubnivel } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'contenido_especifico_subnivel';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idContenidoEspecificoSubnivel',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idContenidoEspecificoSubnivel',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.ContenidoEspecificoSubnivel
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.ContenidoEspecificoSubnivel,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.ContenidoEspecificoSubnivel.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<ContenidoEspecificoSubnivel>(DAO_NAME, optionsToUse);

// hooks
export const useContenidoEspecificoSubnivelFetchById = fetchById;
export const useContenidoEspecificoSubnivelLibbyCall = libbyCall;
export const useContenidoEspecificoSubnivelDAO = libbyDAO;
export const useContenidoEspecificoSubnivelLibbyFetch = libbyFetch;

// components/hoc
export const ContenidoEspecificoSubnivelAutocomplete = entityAutocomplete;
export const ContenidoEspecificoSubnivelCall = renderPropCall;
export const ContenidoEspecificoSubnivelById = renderPropFetchById;
export const ContenidoEspecificoSubnivelSelect = simpleSelect;
export const ContenidoEspecificoSubnivelAccordionSelector = accordionSelector;
export const ContenidoEspecificoSubnivelSelectedChips = selectedChips;

// context
// ByIdContext
export const useContenidoEspecificoSubnivelByIdContext = useByIdContext;
export const ContenidoEspecificoSubnivelByIdProvider = ByIdProvider;
export const ContenidoEspecificoSubnivelByIdContext = ByIdContext;
// GeneralContext
export const useContenidoEspecificoSubnivelContext = useGeneralContext;
export const ContenidoEspecificoSubnivelProvider = GeneralProvider;
export const ContenidoEspecificoSubnivelContext = GeneralContext;
