import React, { FC, useCallback, useMemo } from 'react';
import {
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AutoevaluacionSlider } from './AutoevaluacionSlider';
import { useAutoevaluacionFormContext } from '../../hooks/useAutoevaluacionFormContext';
import { FormInputV2 } from 'src/lib/templates';
import { useEstudianteContext } from '../../contexts/EstudianteContext';
import { SliderAccordionValues, Valor } from '../../../AcapEscuela/types';
import { rebuildBloques } from 'src/screens/Private/AcapEscuela/functions/rebuildBloques';
import { SliderAccordion } from 'src/screens/Private/AcapEscuela/components/sliderGroup';

const types = ['perfiles', 'habilidades', 'contenidos'];

const Evaluador: FC<Acap.Estudiante.EvaluadorAcordionProps> = ({
  items,
  currentBloque,
  actividad,
}) => {
  const { values, form, errors } = useAutoevaluacionFormContext();
  const { data: listNodos } = useEstudianteContext();

  const listNodosFindActividad = listNodos.find(
    (e) => e.accion.idAccion === actividad,
  );
  const perfiles = useMemo(() => {
    return (
      listNodosFindActividad?.perfiles.map((p) => ({
        ...p,
        devolucion: items.valores.find(
          (v) => v.idTipo === p.idPerfilOrientado,
        ) || {
          idTipo: p.idPerfilOrientado,
          valor: 0,
          evaluacion: 0,
        },
      })) || []
    );
  }, [listNodosFindActividad, items.valores]);

  const habilidades = useMemo(() => {
    const _habilidades: any[] = [];
    listNodosFindActividad?.habilidades
      .map((h) => ({
        ...h.habilidadCategoria,
        habilidades: [
          { idHabilidad: h.idHabilidad, descripcion: h.descripcion },
        ],
      }))
      .forEach((h) => {
        const valor = items.valores.find(
          (v) => v.idTipo === h.idHabilidadCategoria,
        ) || {
          idTipo: h.idHabilidadCategoria,
          valor: 0,
          evaluacion: 0,
        };
        const hasHabi = _habilidades.find(
          (_h) => h.idHabilidadCategoria === _h.idHabilidadCategoria,
        );
        if (hasHabi) {
          hasHabi.devolucion = valor;
          hasHabi.habilidades.push(...h.habilidades);
        } else {
          _habilidades.push({ ...h, devolucion: valor });
        }
      });
    return _habilidades;
  }, [listNodosFindActividad?.habilidades, items.valores]);

  const contenidosMapped = useMemo(() => {
    const bloques = rebuildBloques(
      listNodosFindActividad!.nodos
        .filter((nodo) => Number(nodo.bloque.idBloque) === currentBloque)
        .map((nodo) => {
          const valor = (items.valores.find(
            (valor) => valor.idTipo.toString() === nodo.idNodo.toString(),
          ) || {
            idTipo: nodo.idNodo,
            evaluacion: 0,
            tab: 'contenidos',
          }) as Valor;

          return {
            ...nodo,
            devolucion: valor,
            activo: true,
          };
        }),
    );

    return bloques.map<SliderAccordionValues>((b) => ({
      id: Number(b.idBloque),
      descripcion: b.nombre,
      contenidos: b.ejes.map((e) => ({
        id: e.idEje,
        descripcion: e.nombre,
        contenidos: e.unidades.map((u) => ({
          id: u.idUnidad,
          descripcion: u.nombre,
          contenidos: u.contenidos.map((c) => {
            const valor = values.contenidos.valores.find(
              (v) => v.idTipo.toString() === c.devolucion.idTipo.toString(),
            );
            return {
              id: c.idNodo,
              descripcion: c.descripcion,
              valor: valor?.evaluacion || 0,
            };
          }),
        })),
      })),
    }));
  }, [
    listNodosFindActividad,
    currentBloque,
    items.valores,
    values.contenidos.valores,
  ]);

  const contenidosSliderChange = useCallback<
    (item: SliderAccordionValues, value: number) => void
  >(
    (item, value) => {
      const newValors = values.contenidos.valores.map((valor) => {
        let _newValue = valor;
        if (valor.idTipo.toString() === item.id.toString()) {
          _newValue.evaluacion = value;
        }
        return valor;
      });
      // @ts-ignore
      form.change('contenidos.valores', newValors);
    },
    [form, values.contenidos.valores],
  );

  const handleSliderChange = (
    value: number,
    item: Acap.Estudiante.DevolucionValores,
  ) => {
    const type = types[values.categoria - 1] as
      | 'perfiles'
      | 'habilidades'
      | 'contenidos';
    let idxDevolucionValor, previousValores;
    idxDevolucionValor = values[type].valores.findIndex(
      (valor: Acap.Estudiante.DevolucionValores) =>
        valor.idTipo === item.idTipo,
    );
    previousValores = [...values[type].valores];
    previousValores[idxDevolucionValor].evaluacion = value;
    // @ts-ignore
    form.change(`${type}.valores`, previousValores);
  };

  if (values.categoria === 1 && listNodosFindActividad?.perfiles.length === 0)
    return <Typography>No hay perfiles para evaluar.</Typography>;

  if (
    values.categoria === 2 &&
    listNodosFindActividad?.habilidades.length === 0
  )
    return <Typography>No hay habilidades para evaluar.</Typography>;

  if (values.categoria === 3 && listNodosFindActividad?.nodos.length === 0)
    return <Typography>No hay contenidos para evaluar.</Typography>;

  return (
    <Grid container>
      <Grid item xs={12}>
        {values.categoria === 1 ? (
          perfiles.map((p) => (
            <>
              <div
                style={{ marginTop: '20px', marginBottom: '20px' }}
                key={p.idPerfilOrientado}
              >
                <Typography>{p.descripcion}</Typography>
              </div>
              <div
                key={p.idPerfilOrientado}
                style={{
                  width: '45%',
                  height: '50px',
                  paddingLeft: '300px',
                  paddingTop: '20px',
                }}
              >
                <AutoevaluacionSlider
                  value={p.devolucion.evaluacion || 0}
                  onChange={(_, newValue) =>
                    handleSliderChange(newValue, p.devolucion)
                  }
                  showTitles={true}
                />
              </div>
            </>
          ))
        ) : (
          <></>
        )}
        {values.categoria === 2 ? (
          habilidades.map((h) => (
            <div>
              <Accordion
                style={{ width: '95%', border: 'none' }}
                key={h.idHabilidadCategoria}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon style={{ color: '#13c1f3' }} />}
                  IconButtonProps={{
                    style: { order: -1, marginRight: '8px' },
                  }}
                >
                  <Typography>{h.nombre}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                    }}
                  >
                    <Typography style={{ marginLeft: '50px' }}>
                      {h.habilidades.map((_h: any) => (
                        <p key={_h.idHabilidad}>{_h.descripcion}</p>
                      ))}
                    </Typography>
                  </div>
                </AccordionDetails>
              </Accordion>
              <div
                style={{
                  width: '45%',
                  height: '50px',
                  paddingLeft: '350px',
                  paddingTop: '20px',
                }}
              >
                <AutoevaluacionSlider
                  value={h.devolucion.evaluacion || 0}
                  onChange={(_, newValue) =>
                    handleSliderChange(newValue, h.devolucion)
                  }
                  showTitles={true}
                />
              </div>
            </div>
          ))
        ) : (
          <></>
        )}
        {values.categoria === 3 ? (
          contenidosMapped.map((bloque) =>
            bloque.contenidos?.map((_content) => (
              <Grid container spacing={2}>
                <Grid
                  component={Box}
                  item
                  xs={10}
                  key={_content.id}
                  marginLeft={4}
                >
                  <Typography>{_content.descripcion}</Typography>
                </Grid>
                <SliderAccordion
                  sliders={_content.contenidos || []}
                  onChange={contenidosSliderChange}
                />
              </Grid>
            )),
          )
        ) : (
          <></>
        )}
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          style={{ marginTop: '100px', display: 'flex', alignItems: 'center' }}
        >
          <Grid item xs={12} style={{ marginBottom: '20px' }}>
            <Grid container>
              <Grid
                item
                xs={1}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <Typography
                  style={{ marginRight: '10px', marginBottom: '60px' }}
                >
                  Observaciones:
                </Typography>
              </Grid>
              <Grid
                item
                xs={6}
                style={{
                  width: '750px',
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <FormInputV2
                  name={`${types[values.categoria - 1]}.observaciones`}
                  form={form}
                  multiline
                  fullWidth
                  placeholder="Puedes agregar una observación para ser vista por el docente y el referente"
                  minRows={6}
                />
                {Boolean(errors!.observacionesReferente) ? (
                  <small>{errors!.observacionesReferente}</small>
                ) : null}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Evaluador;
