import React from 'react';
import { Grid } from '@material-ui/core';
import { SimpleSelect, MainInput } from 'src/commons/components';
import { grey } from 'src/theme/colors';
import { FilterTablesRegisteredScaleBCTypesProps } from 'src/commons/types/FilterTablesRegisteredTypes';
import { workingDays } from 'src/commons/const';

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  containerStyles: { marginTop: 23 },
  counterContainer: {
    paddingBottom: 0,
    paddingTop: 19,
    fontSize: 14,
    color: grey.medium,
    fontFamily: 'Open Sans',
  },
};

export const FilterTablesRegistered = ({
  cycle,
  level,
  section,
  formValues,
  search,
  handleChangeSearch,
  handleChange,
  working,
}: FilterTablesRegisteredScaleBCTypesProps) => {
  return (
    <>
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        spacing={3}
        style={customStyles.containerStyles}
      >
        <Grid item xs={2}>
          <SimpleSelect
            name="seccion.nombreSeccion"
            title="Grupo/Grado"
            placeholder="Seleccioná Grupo/Grado"
            labelKey="nombreSeccion"
            valueKey="nombreSeccion"
            handleChange={handleChange}
            content={section}
            value={formValues[`seccion.nombreSeccion`]}
            customStyleTitle={customStyles.customStyleTitleSelect}
            disabled={working}
          />
        </Grid>
        <Grid item xs={2}>
          <SimpleSelect
            name="seccion.nivel"
            title="Nivel"
            placeholder="Seleccioná Nivel"
            labelKey="descripcionNivel"
            valueKey="idNivel"
            handleChange={handleChange}
            content={level}
            value={formValues[`seccion.nivel`]}
            customStyleTitle={customStyles.customStyleTitleSelect}
            disabled={working}
          />
        </Grid>
        <Grid item xs={2}>
          <SimpleSelect
            title="Ciclo"
            placeholder="Seleccioná Ciclo"
            labelKey="descripcionCiclo"
            valueKey="idCiclo"
            content={cycle}
            handleChange={handleChange}
            value={formValues[`seccion.ciclo`]}
            name="seccion.ciclo"
            customStyleTitle={customStyles.customStyleTitleSelect}
            disabled={working}
          />
        </Grid>
        <Grid item xs={2}>
          <SimpleSelect
            title="Jornada"
            placeholder="Seleccioná Jornada"
            labelKey="label"
            valueKey="label"
            content={workingDays}
            handleChange={handleChange}
            value={formValues[`seccion.jornada`]}
            name="seccion.jornada"
            customStyleTitle={customStyles.customStyleTitleSelect}
            disabled={working}
          />
        </Grid>
        <Grid
          container
          xs={2}
          justify="flex-end"
          spacing={3}
          alignItems="flex-end"
        >
          <Grid item xs={12}>
            <MainInput
              type="search"
              handleChange={handleChangeSearch}
              placeholder="Buscar"
              name="search"
              value={search}
              fullWidth
              customStyle={{ padding: 9.5, marginTop: 20 }}
              disabled={working}
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
