import { useMemo } from 'react';
import { useApreciacionActividadLibbyFetch } from 'src/app/business/acap/ApreciacionesActividades';
import { GruposInscripciones } from 'src/app/models';
import { useAutoevaluacionesEstudianteGetRequest } from 'src/app/business/acap/AutoevaluacionesEstudiante';

export const useGetInitialValues = (
  actividad: string,
  organizacion: number,
  grupo?: GruposInscripciones,
) => {
  const {
    data: autoevaluacionesEstudiante = [],
    working: loadingAutoevaluacionValues,
    request: refetch,
  } = useAutoevaluacionesEstudianteGetRequest<Acap.Estudiante.AutoevaluacionInitialValues>(
    {
      autoCall: Boolean(actividad) && Boolean(organizacion),
      url: `/api/acap/autoevaluaciones_estudiante?accion=${actividad}&organizacion=${organizacion}`,
    },
  );

  const apreciacionFilter = useMemo(
    () => ({
      grupo: [
        {
          path: 'grupoInscripcion',
          value: grupo?.idGrupoInscripcion,
        },
      ],
    }),
    [grupo?.idGrupoInscripcion],
  );
  const {
    data: apreciacionActividades = [],
    working: loadingApreciacion,
    reFetch: refetchApreciacion,
  } = useApreciacionActividadLibbyFetch({
    filter: apreciacionFilter,
    limit: 1,
    aspect: 'basic',
    enabled: Boolean(grupo),
  });

  const apreciacionInitialValues: Acap.Estudiante.ApreciacionInitialValues =
    useMemo(
      () => ({
        idApreciacion: apreciacionActividades[0]?.idApreciacion,
        grupoInscripcion: Boolean(grupo) ? grupo!.idGrupoInscripcion : 0,
        observaciones: apreciacionActividades[0]?.observaciones ?? '',
        observacionesReferente:
          apreciacionActividades[0]?.observacionesReferente ?? '',
        evaluaciones: apreciacionActividades[0]?.evaluaciones ?? [],
      }),
      [apreciacionActividades, grupo],
    );

  const autoevaluacionInitialValues: Acap.Estudiante.AutoevaluacionForm = {
    grupoInscripcion: grupo,
    perfiles: {
      observaciones: (
        autoevaluacionesEstudiante as Acap.Estudiante.AutoevaluacionInitialValues
      ).perfiles?.observaciones,
      idAutoevaluacionPerfil: (
        autoevaluacionesEstudiante as Acap.Estudiante.AutoevaluacionInitialValues
      ).perfiles?.idAutoevaluacionPerfil,
      valores:
        (
          autoevaluacionesEstudiante as Acap.Estudiante.AutoevaluacionInitialValues
        ).perfiles?.valores ?? [],
    },
    habilidades: {
      observaciones: (
        autoevaluacionesEstudiante as Acap.Estudiante.AutoevaluacionInitialValues
      ).habilidades?.observaciones,
      idAutoevaluacionHabilidad: (
        autoevaluacionesEstudiante as Acap.Estudiante.AutoevaluacionInitialValues
      ).habilidades?.idAutoevaluacionHabilidad,
      valores:
        (
          autoevaluacionesEstudiante as Acap.Estudiante.AutoevaluacionInitialValues
        ).habilidades?.valores ?? [],
    },
    contenidos: {
      observaciones: (
        autoevaluacionesEstudiante as Acap.Estudiante.AutoevaluacionInitialValues
      ).contenidos?.observaciones,
      idAutoevaluacionContenido: (
        autoevaluacionesEstudiante as Acap.Estudiante.AutoevaluacionInitialValues
      ).contenidos?.idAutoevaluacionContenido,
      valores:
        (
          autoevaluacionesEstudiante as Acap.Estudiante.AutoevaluacionInitialValues
        ).contenidos?.valores ?? [],
    },
    /*  categoria: 0,
    bloque: 0, */
  };

  return {
    apreciacionInitialValues,
    autoevaluacionInitialValues,
    loadingApreciacion,
    loadingAutoevaluacionValues,
    refetch,
    refetchApreciacion,
  };
};
