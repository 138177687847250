import React from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { useField } from 'react-final-form-hooks';
import { usePropuestaJEFormContext } from 'src/screens/Private/Presentismo/context/configuration/PropuestaJEFormProvider';
import { AutocompleteProps } from '../types/form';

type AutocompleteOption = {
  inputValue: string;
  nombre: string;
};

const filter = createFilterOptions<AutocompleteOption>();

export const AutocompleteWithField = ({
  disabled = false,
  ...props
}: AutocompleteProps) => {
  const { form } = usePropuestaJEFormContext();
  if (!form) throw new Error('El autocomplete no está dentro del context');
  const field = useField(`${props.dia}.${props.name}`, form);

  return (
    <>
      {field.meta.error && <span>{field.meta.error}</span>}
      <Autocomplete
        value={field.input.value}
        onChange={(_, newValue) => {
          if (typeof newValue === 'string') {
            field.input.onChange(newValue);
          } else if (newValue && newValue.inputValue) {
            field.input.onChange(newValue.inputValue);
          } else {
            field.input.onChange(newValue);
          }
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          const isEqual = filtered.some(
            (sede) =>
              sede.nombre.toLocaleLowerCase() ===
              params.inputValue.toLocaleLowerCase(),
          );
          if (params.inputValue !== '' && !isEqual) {
            filtered.push({
              inputValue: params.inputValue,
              nombre: `Agregar "${params.inputValue}"`,
            });
          }

          return filtered;
        }}
        selectOnFocus
        clearOnBlur
        handleHomeEndKeys
        options={props.datalist}
        getOptionLabel={(option) => {
          if (typeof option === 'string') {
            return option;
          }
          if (option.inputValue) {
            return option.inputValue;
          }
          return option[props.labelKey];
        }}
        renderOption={(option) => option[props.labelKey]}
        style={{ width: 300 }}
        renderInput={(params) => <TextField {...params} variant="outlined" />}
        disabled={disabled}
      />
    </>
  );
};
