const _1_GRADO = 1;
const _2_GRADO = 2;
const _3_GRADO = 3;
const _4_GRADO = 4;
const _5_GRADO = 5;
const _6_GRADO = 6;
const _7_GRADO = 7;
const _4_Y_5_GRADO = 8;
const _6_Y_7_GRADO = 9;

const PROMOCION_DIRECTA = 1;
const PERMANECE_CON_ACOMPANAMIENTO = 2;
const EGRESA = 3;
const PROMOCION_EN_PROCESO = 4;
const PROMOCION_ACOMPANADA = 5;
const COMPLETA_ESTUDIOS = 6;
const CONTINUA_CON_APOYO_PARA_LA_PROMOCION = 7;
const PROMOCIONA_CON_PROMOCION_ACOMPANADA = 8;
const NO_COMPLETO_EL_PLAN_DE_ESTUDIOS = 9;

const MAPA_OPCIONES_DE_SECCION_A_LA_QUE_PASA: {
  [key: number]: { [key: number]: number[] };
} = {};

[
  _1_GRADO,
  _2_GRADO,
  _3_GRADO,
  _4_GRADO,
  _5_GRADO,
  _6_GRADO,
  _7_GRADO,
  _4_Y_5_GRADO,
  _6_Y_7_GRADO,
].forEach((grado) => {
  MAPA_OPCIONES_DE_SECCION_A_LA_QUE_PASA[grado] = {
    [PROMOCION_DIRECTA]: [
      ...(grado === _4_Y_5_GRADO ? [_4_Y_5_GRADO, _5_GRADO] : []),
      ...(grado === _6_Y_7_GRADO ? [_6_Y_7_GRADO, _7_GRADO] : []),
      ...(grado === _7_GRADO ? [] : []),
      ...(![_4_Y_5_GRADO, _6_Y_7_GRADO, _7_GRADO].includes(grado)
        ? [grado + 1]
        : []),
    ],
    [PERMANECE_CON_ACOMPANAMIENTO]: [
      ...(grado === _4_Y_5_GRADO ? [_4_Y_5_GRADO, _5_GRADO] : []),
      ...(grado === _6_Y_7_GRADO ? [_6_Y_7_GRADO, _7_GRADO] : []),
      ...(grado === _7_GRADO ? [] : []),
      ...(![_4_Y_5_GRADO, _6_Y_7_GRADO, _7_GRADO].includes(grado)
        ? [grado]
        : []),
    ],
    [EGRESA]: [],
    [PROMOCION_EN_PROCESO]: [],
    [PROMOCION_ACOMPANADA]: [],
    [COMPLETA_ESTUDIOS]: [],
    [CONTINUA_CON_APOYO_PARA_LA_PROMOCION]: [],
    [PROMOCIONA_CON_PROMOCION_ACOMPANADA]: [],
    [NO_COMPLETO_EL_PLAN_DE_ESTUDIOS]: [],
  };
});

export const useFilterPassSectionPrimariaLogic = (
  options: any,
  statusPass: any,
  currentCicloLectivo: any,
  numeroAnio: any,
  descripcionAnioSection: any,
  shoulBeNextYear: boolean,
  idAnio: any,
) => {
  const siguienteCicloLectivo: number = currentCicloLectivo + 1;
  const filteredOptionsPrimaria = Boolean(idAnio)
    ? options.filter((option, index) => {
        return (
          MAPA_OPCIONES_DE_SECCION_A_LA_QUE_PASA[Number(idAnio)][
            statusPass
          ].includes(option?.anio?.idAnio) &&
          option?.cicloLectivo?.anio === siguienteCicloLectivo
        );
      })
    : [];

  return filteredOptionsPrimaria;
};
