import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class ExceptuadosV3DAO extends LibbyFetchDAO {
  constructor() {
    super('exceptuadosv3', 'idExceptuados');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
