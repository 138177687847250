import {
  FormDialog,
  MultiCheckboxForm,
  MultiCheckboxFormMetadata,
} from '../../../../../commons/services/dialogs';
import theme from '../../../../../theme';
import { CommonCheckboxTemplate } from '../../../../../app/models';

export const commonCheckboxDialogCreator = (title: string) =>
  FormDialog.create<Array<CommonCheckboxTemplate>, MultiCheckboxFormMetadata>({
    customTheme: theme,
    component: MultiCheckboxForm,
    title,
    metadata: { labelKey: 'descripcion' },
  });
