import { NivelSelect, DistritoEscolarSelect } from 'src/app/business';
import { nivelDefaultFilter } from 'src/commons/const/nivelDefaultFilter';
import { EscalafonSelect } from './EscalafonSelect';
import { RegionSelect } from './RegionSelect';

export type filters = {
  name: string;
  component: any;
  componentProps: any;
};

export type tDepenFuncByNiveles = {
  [key: string]: filters;
};

export const filterByDepenFunc: tDepenFuncByNiveles = {
  supInicMedPri: {
    name: 'distrito',
    component: DistritoEscolarSelect,
    componentProps: { title: 'Distrito', placeholder: 'Seleccioná distrito' },
  },
  supEsp: {
    name: 'escalafon',
    component: EscalafonSelect,
    componentProps: { title: 'Escalafon', placeholder: 'Seleccioná ciclo' },
  },
  supTec: {
    name: 'region',
    component: RegionSelect,
    componentProps: { title: 'Región', placeholder: 'Seleccioná región' },
  },
  supNorm: {
    name: 'nivel',
    component: NivelSelect,
    componentProps: {
      title: 'Nivel',
      placeholder: 'Seleccioná nivel',
      filter: nivelDefaultFilter,
    },
  },
};

export interface DependenciasFuncionalesMap {
  10: string;
  31: string;
  20: string;
  50: string;
  32: string;
  41: string;
  70: string;
}

export interface DependenciasFuncionalesNivelMap {
  10: number;
  31: number;
  20: number;
  50: number;
  32: number;
  41: number;
  70: number;
}

export const depenFuncFiltersMap: DependenciasFuncionalesMap = {
  10: 'supInicMedPri',
  31: 'supInicMedPri',
  20: 'supInicMedPri',
  50: 'supEsp',
  32: 'supTec',
  41: 'supNorm',
  70: 'supArt',
};

export const depenFuncInsertTypeMap: DependenciasFuncionalesMap = {
  10: 'supInicMedPri',
  31: 'supInicMedPri',
  20: 'supInicMedPri',
  50: 'supTecEspNorm',
  32: 'supTecEspNorm',
  41: 'supTecEspNorm',
  70: 'supArt',
};

export const depenFuncNivelMap: DependenciasFuncionalesNivelMap = {
  10: 1,
  31: 3,
  20: 2,
  50: 3,
  32: 3,
  41: 3,
  70: 3,
};

type tFiltersToCleanByDependencia = {
  [key: string]: Array<string>;
};

export const filtersToCleanByDependencia: tFiltersToCleanByDependencia = {
  supInicMedPri: ['nivel', 'escalafon', 'region'],
  supEsp: ['distrito', 'region', 'nivel'],
  supTec: ['distrito', 'escalafon', 'nivel'],
  supNorm: ['distrito', 'region', 'escalafon'],
  supArt: ['distrito', 'region', 'escalafon', 'nivel'],
};
