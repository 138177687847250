import { Box, Button, Grid, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { AnyObject, InfoTable, useRouteScreenTitle } from '../../../../commons';
import {
  OfertasSelect,
  useLocalizacionPlanEstudioNivelLibbyCall,
} from '../../../../app/business';
import { OrientacionesSelectInput } from '../../../../app/business/orientaciones/Orientaciones';
import { SectoresSelectInput } from '../../../../app/business/acap';
import { useEscuelaContext } from '../context/EscuelaContext';
import { useDevolucionesColumn } from '../hooks';
import { DevolucionesDetails } from '../components/DevolucionesDetails';
import { DevolucionesView } from '../components';
import { DEVOLUCIONES_SCREEN } from '../enums/DevolucionesScreen';
import { DevolucionesFormView } from '../components/DevolucionesForm';
import { useRolesContext } from '../../../../context/RolesContext';
import { CURRENT_CICLO_LECTIVO } from '../../Acap/tabs';
import { Orientacion } from '../../../../app/models/orientaciones';

const transformOfertas = (options: AnyObject[]) => {
  const _options: AnyObject[] = [];
  options.forEach((option) => {
    const accion = option.accion;
    if (!_options.find((a) => a.idAccion === accion.idAccion)) {
      _options.push(option.accion);
    }
  });
  return _options;
};

const DevolucionesComponennt = () => {
  const {
    filter,
    loading,
    ofertasGrupos,
    handleFilterChange,
    reFetchOfertasGrupos,
  } = useEscuelaContext();

  // Orientaciones del establecimiento por plan de estudio
  const { data: lizacionPlanEstudioNivel = [], working: lpenWorkiing } =
    useLocalizacionPlanEstudioNivelLibbyCall({
      methodName: 'getAll',
    });

  const orientaciones = useMemo(() => {
    let items: Orientacion[] = [];
    lizacionPlanEstudioNivel.forEach((item) => {
      const _orientas = item.planEstudioNivel.planEstudio.orientaciones;
      if (_orientas.length > 0) {
        for (const orientacion of _orientas) {
          if (!orientacion.orientacion) continue;
          const exist = items.find(
            (i) => i.idOrientacion === orientacion.orientacion.idOrientacion,
          );
          if (!exist) {
            items.push(orientacion.orientacion);
          }
        }
      }
    });
    return items;
  }, [lizacionPlanEstudioNivel]);

  const orientacionIds = useMemo(
    () => orientaciones.map((ori) => ori.idOrientacion),
    [orientaciones],
  );

  const orientacionesFilter = useMemo(() => {
    return orientacionIds.length === 0
      ? {}
      : {
          orientacion: [
            { path: 'idOrientacion', value: orientacionIds, method: 'in' },
          ],
        };
  }, [orientacionIds]);

  const filterOfertas = useMemo(
    () => ({
      orientacion: [
        {
          path: 'accion.accionOrientaciones.orientacion',
          value: orientacionIds,
          method: 'in',
        },
      ],
    }),
    [orientacionIds],
  );

  const { columns } = useDevolucionesColumn();

  return (
    <Grid container>
      <Grid
        component={Box}
        bgcolor="#F8F6F4"
        marginTop={3}
        padding={4}
        container
      >
        <Grid item xs={8} container spacing={2}>
          <Grid item xs={6} container alignItems="center" spacing={2}>
            <Grid item xs={4}>
              <Typography align="right">Orientación:</Typography>
            </Grid>
            <Grid item xs={8}>
              <OrientacionesSelectInput
                label=""
                fullWidth
                value={filter.orientacion}
                onChange={handleFilterChange}
                handleChange={handleFilterChange}
                filter={orientacionesFilter}
                enableFetch={lizacionPlanEstudioNivel.length > 0}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} container alignItems="center" spacing={2}>
            <Grid item xs={4}>
              <Typography align="right">Sector:</Typography>
            </Grid>
            <Grid item xs={8}>
              <SectoresSelectInput
                label=""
                name="sector"
                fullWidth
                value={filter.sector}
                onChange={handleFilterChange}
                handleChange={handleFilterChange}
                placeholder="Seleccioná el Sector"
                labelKey="nombre"
                valueKey="idSector"
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
          <Grid item xs={6} container alignItems="center" spacing={2}>
            <Grid item xs={4}>
              <Typography align="right">Acción:</Typography>
            </Grid>
            <Grid item xs={8}>
              <OfertasSelect
                label=""
                name="accion"
                value={filter.accion}
                onChange={handleFilterChange}
                handleChange={handleFilterChange}
                placeholder="Seleccioná la Acción"
                labelKey="nombre"
                valueKey="idAccion"
                filter={filterOfertas}
                transformOptions={transformOfertas}
                orderBy="accion.nombre"
                fullWidth
                enableFetch={lizacionPlanEstudioNivel.length > 0}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={4} container justifyContent="center" alignItems="center">
          <Grid item>
            <Button
              style={{ textTransform: 'none' }}
              variant="contained"
              color="primary"
              onClick={reFetchOfertasGrupos}
            >
              Ver Actividades
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid container justifyContent="center">
        <InfoTable
          columns={columns}
          rows={loading ? [] : ofertasGrupos}
          working={loading || lpenWorkiing}
        />
      </Grid>
    </Grid>
  );
};

const COMPONENT_MAP: { [k: number]: () => JSX.Element } = {
  [DEVOLUCIONES_SCREEN.DEFAULT]: DevolucionesComponennt,
  [DEVOLUCIONES_SCREEN.ENROLES]: DevolucionesDetails,
  [DEVOLUCIONES_SCREEN.VIEW]: DevolucionesView,
  [DEVOLUCIONES_SCREEN.FORM]: DevolucionesFormView,
};

export const Devoluciones = () => {
  useRouteScreenTitle('Actividades de aproximación');
  const { currentTap } = useEscuelaContext();
  const Comp = COMPONENT_MAP[currentTap] || DevolucionesComponennt;

  return (
    <Grid component={Box} container paddingY={2}>
      <Comp />
    </Grid>
  );
};
