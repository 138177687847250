import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Cuentas } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'cuentas';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idCuentas',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idCuentas',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Cuentas
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Cuentas,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Cuentas.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Cuentas>(DAO_NAME, optionsToUse);

// hooks
export const useCuentasFetchById = fetchById;
export const useCuentasLibbyCall = libbyCall;
export const useCuentasDAO = libbyDAO;
export const useCuentasLibbyFetch = libbyFetch;

// components/hoc
export const CuentasAutocomplete = entityAutocomplete;
export const CuentasCall = renderPropCall;
export const CuentasById = renderPropFetchById;
export const CuentasSelect = simpleSelect;
export const CuentasAccordionSelector = accordionSelector;
export const CuentasSelectedChips = selectedChips;

// context
// ByIdContext
export const useCuentasByIdContext = useByIdContext;
export const CuentasByIdProvider = ByIdProvider;
export const CuentasByIdContext = ByIdContext;
// GeneralContext
export const useCuentasContext = useGeneralContext;
export const CuentasProvider = GeneralProvider;
export const CuentasContext = GeneralContext;
