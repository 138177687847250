import React, { useMemo } from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router';
import { useSnackbar } from 'notistack';
import { RepositorioItem } from '../../../../../app/models';
import { InfoTableColumn } from '../../../../../commons/types';
import {
  OptionsModal,
  OptionsModalOption,
} from '../../../../../commons/components/OptionsModal';
import confirmDialog from '../../../../../commons/services/confirmDialog';
import { useRepositorioItemDAO } from '../../../../../app/business';
import { loadingModal } from '../../../../../commons/services/loadingModal';

export const useRepositorioABMColumns = (reFetch: () => void) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const repositorioItemDAO = useRepositorioItemDAO();
  const actionButtons = useMemo<OptionsModalOption<RepositorioItem>[]>(
    () => [
      {
        label: 'Abrir',
        onClick: (data: RepositorioItem) => window.open(data.url, 'blank'),
      },
      {
        label: 'Editar',
        onClick: (data: RepositorioItem) =>
          history.push(`${url}/form/${data.idRepositorioItem}`),
      },
      {
        label: 'Eliminar',
        onClick: async (data: RepositorioItem) => {
          const confirm = await confirmDialog.show({
            title: 'Confirmación de Eliminación',
            content: `¿Desea eliminar '${data.nombre}'? Esta acción no se puede deshacer`,
            confirmText: 'Eliminar',
            cancelText: 'Cancelar',
          });
          if (confirm) {
            try {
              loadingModal.open();
              await repositorioItemDAO.remove(data);
              enqueueSnackbar('Se ha eliminado con exito', {
                variant: 'success',
              });
              reFetch();
            } catch (e) {
              console.log(e);
              enqueueSnackbar('Ha ocurrido un error al intentar eliminar', {
                variant: 'error',
              });
            } finally {
              loadingModal.close();
            }
          }
        },
      },
    ],
    [enqueueSnackbar, history, reFetch, repositorioItemDAO, url],
  );

  return useMemo<InfoTableColumn<RepositorioItem>[]>(
    () => [
      {
        id: 'nombre',
        label: 'Nombre',
        align: 'left',
        width: '15%',
        orderBy: 'nombre',
      },
      {
        id: 'tipoRecurso',
        label: 'Tipo de recurso',
        align: 'left',
        width: '11%',
        orderBy: 'repositorioTipoItem.descripcion',
        render: (value, row) => row.repositorioTipoItem?.descripcion,
      },
      {
        id: 'fechaCreacion',
        label: 'Año recurso',
        align: 'center',
        width: '10%',
        orderBy: 'fuente_anio',
        render: (value, row) => row.fuente_anio,
      },
      {
        id: 'fuente',
        label: 'Fuente',
        align: 'center',
        width: '10%',
        orderBy: 'fuente',
        render: (value, row) => row.fuente,
      },
      {
        id: 'nivel',
        label: 'Nivel',
        align: 'center',
        width: '10%',
        orderBy: 'nivel.descripcionNivel',
        render: (value, row) => row.nivel?.descripcionNivel,
      },
      {
        id: 'ciclo',
        label: 'Ciclo',
        align: 'center',
        width: '10%',
        orderBy: 'ciclo.descripcion',
        render: (value, row) => row.ciclo?.descripcion,
      },
      {
        id: 'anio',
        label: 'Año',
        align: 'center',
        width: '10%',
        orderBy: 'anio.idAnio',
        render: (value, row) => row.anio?.descripcionAnio,
      },
      {
        id: 'area',
        label: 'Área',
        align: 'center',
        width: '10%',
        orderBy: 'area.descripcion',
        render: (value, row) => row.area?.descripcion,
      },
      {
        id: 'espacioCurricular',
        label: 'Espacio curricular',
        align: 'center',
        width: '15%',
        orderBy: 'espacioCurricular.descripcion',
        render: (value, row) => row.espacioCurricular?.descripcion,
      },
      {
        id: 'icono',
        label: '',
        width: '5%',
        hideSortIcon: true,
        style: { textAlign: 'right' },
        render: (value: any, row: any) => (
          <Box whiteSpace="nowrap" textAlign="right">
            <OptionsModal options={actionButtons} data={row} />
          </Box>
        ),
      },
    ],
    [actionButtons],
  );
};
