import { Grid, makeStyles, Typography } from '@material-ui/core';
import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { orderBy } from 'lodash';
import { useSnackbar } from 'notistack';
import { InfoTable, Footer, ROL } from 'src/commons';
import { useProyeccionDialogColumns } from '../hooks/useProyeccionDialogColumns';
import {
  useSeccionLibbyFetch,
  NivelSelect,
  useDistritosCustomGetRequest,
  useDistritosLocalizacionesCustomGetRequest,
} from 'src/app/business';
import { useProyeccionDAO } from 'src/app/business/Proyeccion';
import { DEFAULT_HIDE_TIME } from 'src/commons/const/ui/snackbar';
import {
  Anio,
  DistritoEscolar,
  Localizacion,
  Proyeccion,
  Turno,
} from 'src/app/models';
import { distinctList } from 'src/utils';
import { useGetLocalizacionSeccionProperties } from 'src/commons/hooks/useGetLocalizacionSeccionProperties';
import { SelectAutoComplete } from 'src/lib/templates/components/SelectAutoComplete';
import { useFilterPassSection } from './useFilterPassSection';

const formProyeccionDialog = makeStyles(() => ({
  root: {
    overflow: 'visible',
  },
  tittle: {
    fontSize: '24px',
    marginTop: '15px',
    marginLeft: '20px',
    marginBottom: '20px',
    color: '#737373',
  },
  container: {
    justifyContent: 'center',
  },
  grid: {
    marginLeft: '1.5%',
    marginRight: '1.5%',
    marginBottom: '2%',
  },
  dataContainer: {
    padding: '20px',
    justifyContent: 'center',
  },
}));

export const FormProyeccionDialog = ({
  checkedProyecciones,
  closeModal,
  reFetch,
  filtercycle,
  currentDistrito,
  currentRol,
}: {
  closeModal: () => void;
  reFetch: () => void;
  checkedProyecciones: Proyeccion[];
  filtercycle: any;
  currentDistrito: number;
  currentRol: number;
}) => {
  const [loading, setLoading] = useState(false);
  const classes = formProyeccionDialog();
  const cycle = filtercycle;
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [formValues, setFormValues] = useState<any>({
    distrito: '',
    establecimiento: '',
    anio: '',
    turno: '',
    jornada: '',
    seccion: '',
    nivel: 1,
  });
  const ProyeccionDAO = useProyeccionDAO();

  const cicloLectivo = checkedProyecciones[0]?.cicloLectivo?.anio + 1;

  const seccionFilter = useMemo(() => {
    const filters = [
      [
        {
          path: 'localizacion.idLocalizacion',
          value: formValues.establecimiento,
        },
      ],
      [
        {
          path: 'cicloLectivo.anio',
          value: cicloLectivo,
        },
      ],
    ];

    if (formValues.anio) {
      filters.push([{ path: 'anio.idAnio', value: formValues.anio }]);
    }
    if (formValues.turno) {
      filters.push([{ path: 'turno.idTurno', value: formValues.turno }]);
    }
    if (formValues.jornada) {
      filters.push([{ path: 'jornada', value: formValues.jornada }]);
    }

    return filters;
  }, [
    cicloLectivo,
    formValues.anio,
    formValues.establecimiento,
    formValues.jornada,
    formValues.turno,
  ]);

  const { data: distritos = [], working: distritosWorking } =
    useDistritosCustomGetRequest({
      url: `/api/public/custom/listadistritos?dependenciafuncional=${10}`,
      autoCall: true,
    });

  const distritoFiltradoSupervisor = distritos.filter(
    (dis) => dis.idDistritoEscolar === currentDistrito,
  );
  const {
    data: localizaciones = [],
    working: localizacionWorking,
    request: requestLocalizaciones,
  } = useDistritosLocalizacionesCustomGetRequest({
    url: `/api/public/custom/listalocalizaciones?distrito=${
      formValues.distrito
    }&dependenciafuncional=${10}`,
    autoCall: false,
  });

  useEffect(() => {
    if (formValues.distrito) {
      requestLocalizaciones();
    }
  }, [formValues.distrito, requestLocalizaciones]);

  const localizacionesSorted = useMemo(() => {
    const locations = distinctList('idLocalizacion', localizaciones);
    return orderBy([...locations], ['descripcion'], ['asc']);
  }, [localizaciones]);

  const { data: secciones, working: seccionWorking } = useSeccionLibbyFetch({
    limit: 100000,
    filter: seccionFilter,
    orderBy: 'anio.numeroAnio,anio.descripcionAnio',
  });

  const handleChange = useCallback(
    (data: any, name: any) => {
      if (data?.value) {
        setFormValues({
          ...formValues,
          [name]: data.value,
        });
      }

      const filterHierarchy: any = {
        distrito: {
          hierarchy: 1,
        },
        establecimiento: {
          hierarchy: 2,
        },
        anio: {
          hierarchy: 3,
        },
        turno: {
          hierarchy: 3,
        },
        jornada: {
          hierarchy: 3,
        },
        seccion: {
          hierarchy: 4,
        },
      };

      for (let key in filterHierarchy) {
        let propertyUndefined: any = { [key]: undefined };

        if (!data && name === key) {
          for (let secondKey in filterHierarchy) {
            if (
              filterHierarchy[secondKey]?.hierarchy >
              filterHierarchy[key]?.hierarchy
            ) {
              propertyUndefined[secondKey] = undefined;
              setFormValues({ ...formValues, ...propertyUndefined });
            }
          }
        }
      }
    },
    [formValues, setFormValues],
  );
  const { enqueueSnackbar } = useSnackbar();

  const handleSubmit = useCallback(async () => {
    setIsLoading(true);
    try {
      for (const element of checkedProyecciones) {
        await ProyeccionDAO.save({
          idProyeccion: element.idProyeccion,
          alumno: element?.alumno?.idAlumno,
          articula: element?.articula,
          cicloLectivo: element?.cicloLectivo?.idCicloLectivo,
          estadoPaseAnio: element?.estadoPaseAnio?.idEstadoPaseAnio,
          seccionDestino: formValues.seccion,
        });
      }
      enqueueSnackbar(`Las proyecciones fueron actualizadas con exito`, {
        variant: 'success',
        autoHideDuration: DEFAULT_HIDE_TIME,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    } catch (error) {
      enqueueSnackbar(`Ha ocurrido un error actualizando las proyecciones`, {
        variant: 'error',
        autoHideDuration: DEFAULT_HIDE_TIME,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
    setLoading(false);
    reFetch();
    closeModal();
  }, [
    enqueueSnackbar,
    reFetch,
    closeModal,
    ProyeccionDAO,
    checkedProyecciones,
    formValues.seccion,
  ]);

  const buttonConfig: any = [
    {
      title: 'Cancelar',
      handleOnClick: () => closeModal(),
      type: 'secondary',
      size: 'small',
      disabled: loading,
    },
    {
      title: 'Guardar',
      handleOnClick: () => handleSubmit(),
      size: 'small',
      disabled: !formValues.seccion && loading,
    },
  ];

  const columns = useProyeccionDialogColumns();

  const seccionHookFilter = useMemo(
    () => ({
      localizacion: [
        { path: 'localizacion', value: formValues.establecimiento },
      ],
      cicloLectivo: [
        {
          path: 'cicloLectivo.anio',
          value: cicloLectivo,
        },
      ],
    }),
    [formValues.establecimiento, cicloLectivo],
  );

  const { data: seccionesHook = [] } = useSeccionLibbyFetch({
    limit: 500,
    filter: seccionHookFilter,
    enabled: !!formValues.establecimiento,
  });

  const {
    aniosSorted: anios = [],
    jornadasSorted: jornadas = [],
    turnosSorted: turnos = [],
  } = useGetLocalizacionSeccionProperties(seccionesHook);

  const statusPass = checkedProyecciones[0]?.estadoPaseAnio?.idEstadoPaseAnio;
  const currentCicloLectivo = checkedProyecciones[0]?.cicloLectivo?.anio;
  const numeroAnio = checkedProyecciones[0]?.seccionOrigen?.anio?.numeroAnio;
  const descripcionAnioSection =
    checkedProyecciones[0]?.seccionOrigen?.anio?.descripcionAnio;

  const { filteredOptions = [] } = useFilterPassSection(
    secciones,
    statusPass,
    currentCicloLectivo,
    numeroAnio,
    descripcionAnioSection,
    formValues.nivel,
    false,
  );

  return (
    <>
      <InfoTable rows={checkedProyecciones} columns={columns} />
      <Typography className={classes.tittle}>
        Selección de sección destino
      </Typography>

      <Grid container className={classes.dataContainer} spacing={4}>
        <Grid item lg={2} sm={2} xs={2}>
          <SelectAutoComplete
            title="Distrito"
            placeholder="Seleccione opción"
            optionLabelKey="nombre"
            optionValueKey="idDistritoEscolar"
            valueToOption={formValues.distrito}
            handleChange={handleChange}
            options={
              currentRol === ROL.DIRECCION_AREA
                ? distritos || []
                : distritoFiltradoSupervisor
            }
            getOptionSelected={(
              option: DistritoEscolar,
              value: DistritoEscolar,
            ) => option?.nombre === value?.nombre}
            name="distrito"
          />
        </Grid>
        <Grid item lg={2} sm={2} xs={2}>
          <NivelSelect
            value={formValues.nivel}
            handleChange={(event) => handleChange(event)}
            disabled
          />
        </Grid>
        <Grid item lg={4} sm={4} xs={4}>
          <SelectAutoComplete
            title="Establecimiento"
            placeholder="Seleccione opción"
            optionLabelKey="descripcion"
            optionValueKey="idLocalizacion"
            valueToOption={formValues.establecimiento}
            handleChange={handleChange}
            options={localizacionesSorted || []}
            getOptionSelected={(option: Localizacion, value: Localizacion) =>
              option?.descripcion === value?.descripcion
            }
            name="establecimiento"
            disabled={!formValues.distrito}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.dataContainer} spacing={4}>
        <Grid item lg={2} sm={2} xs={4}>
          <SelectAutoComplete
            title="Año"
            placeholder="Seleccione opción"
            optionLabelKey="descripcionAnio"
            optionValueKey="idAnio"
            valueToOption={formValues.anio}
            handleChange={handleChange}
            options={anios || []}
            getOptionSelected={(option: Anio, value: Anio) =>
              option?.descripcionAnio === value?.descripcionAnio
            }
            name="anio"
            disabled={!formValues.establecimiento}
          />
        </Grid>
        <Grid item lg={2} sm={2} xs={4}>
          <SelectAutoComplete
            title="Turno"
            placeholder="Seleccione opción"
            optionLabelKey="descripcionTurno"
            optionValueKey="idTurno"
            valueToOption={formValues.turno}
            handleChange={handleChange}
            options={turnos || []}
            getOptionSelected={(option: Turno, value: Turno) =>
              option?.descripcionTurno === value?.descripcionTurno
            }
            name="turno"
            disabled={!formValues.establecimiento}
          />
        </Grid>
        <Grid item lg={2} sm={2} xs={4}>
          <SelectAutoComplete
            title="Jornada"
            placeholder="Seleccione opción"
            optionLabelKey="label"
            optionValueKey="label"
            valueToOption={formValues.jornada}
            handleChange={handleChange}
            options={jornadas || []}
            getOptionSelected={(option: any, value: any) =>
              option?.label === value?.label
            }
            name="jornada"
            disabled={!formValues.establecimiento}
          />
        </Grid>
        <Grid item lg={2} sm={2} xs={4}>
          <SelectAutoComplete
            title="Sección destino"
            placeholder="Seleccione opción"
            optionLabelKey="nombreSeccion"
            optionValueKey="idSeccion"
            valueToOption={formValues.seccion}
            handleChange={handleChange}
            options={filteredOptions || []}
            getOptionSelected={(option: any, value: any) =>
              option?.descripcion === value?.descripcion
            }
            name="seccion"
            disabled={!formValues.establecimiento}
          />
        </Grid>
      </Grid>

      <Footer buttonConfig={buttonConfig} loading={isLoading} spacing={1} />
    </>
  );
};
