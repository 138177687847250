/* eslint-disable react/no-array-index-key */
import React, { useEffect, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  INITIAL_YEAR,
  useCicloLectivoLibbyFetch,
  useProyeccionEstadoLibbyFetch,
  useSeccionContext,
} from 'src/app/business';
import { useParams } from 'react-router';
import {
  FormInput,
  FormAutocomplete,
} from '../../../../../../../../../lib/templates/components';
import { Seccion } from '../../../../../../../../../app/models';
import { useArealInterArealForm } from '../../../ArealInterareal/useArealInterArealForm';
import { useGruposSeccion } from '../../../../hooks';
import { SimpleSelect } from 'src/commons';
import { useField } from 'react-final-form-hooks';
import { grey } from '@material-ui/core/colors';
import { useRolesContext } from 'src/context/RolesContext';

const customStyles = {
  buttonRoot: {
    fontSize: 16,
    padding: '9px 12px',
  },
  container: {
    padding: '10px',
    marginBottom: 70,
  },
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  inputStyle: { padding: 10, marginTop: 23 },
};

const useStyles = makeStyles(customStyles);

export const InformacionGeneral = (): JSX.Element => {
  const classes = useStyles();
  const { form, values, errors } = useArealInterArealForm();
  const { data: secciones = [], working: sWorking } = useSeccionContext();
  const { idEspacioCurricular } = useParams();
  const { transformeGrupos, working } = useGruposSeccion([idEspacioCurricular]);

  const { selectedRole } = useRolesContext();

  const filterProyeccionEstado = useMemo(
    () => ({
      0: [{ path: 'localizacion', value: selectedRole.localizacionId }],
    }),
    [selectedRole.localizacionId],
  );

  const { data = [] } = useProyeccionEstadoLibbyFetch({
    limit: 1,
    filter: filterProyeccionEstado,
    orderBy: 'cicloLectivo',
    direction: 'desc',
  });

  useEffect(() => {
    form.change(
      'informacionGeneral.cicloLectivo',
      data[0]?.cicloLectivo?.idCicloLectivo,
    );
  }, [data, form]);

  const seccionGrupos = [...(secciones || []), ...transformeGrupos];
  return (
    <Grid
      container
      direction="column"
      spacing={4}
      className={classes.container}
    >
      <Grid item>
        {!working && !sWorking && !!seccionGrupos?.length && (
          <FormAutocomplete
            label="Secciones"
            name="informacionGeneral.secciones"
            form={form}
            errors={errors?.informacionGeneral?.secciones}
            options={seccionGrupos || []}
            optionLabelKey="nombreSeccion"
            getOptionSelected={(option: Seccion, value: any) =>
              option.idSeccion.toString() === value?.idSeccion.toString()
            }
            optionToValue={(option: Seccion) => option?.idSeccion.toString()}
            valueToOption={(value: string[], options: Seccion[]) =>
              value.map((item) =>
                options.find((option) => option?.idSeccion.toString() === item),
              )
            }
            multiple
          />
        )}
      </Grid>
      <Grid item>
        <FormInput
          label="Tema"
          name="informacionGeneral.tema"
          form={form}
          placeholder="Escribí el tema"
        />
      </Grid>
      <Grid item>
        <FormInput
          label="Problema"
          name="informacionGeneral.problema"
          form={form}
          placeholder="Escribí el problema"
        />
      </Grid>
      <Grid item>
        <FormInput
          label="Preguntas adicionales"
          name="informacionGeneral.preguntasAdicionales"
          form={form}
          placeholder="Escribí preguntas adicionales"
        />
      </Grid>
    </Grid>
  );
};
