import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { Informesc } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'informesc';

const defaultOptions: PartialBusinessEntityOptions<any> = {};

const optionsToUse = !businessOptions.Informesc
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Informesc,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Informesc.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Informesc>(DAO_NAME, optionsToUse);

// hooks
export const useInformescFetchById = fetchById;
export const useInformescLibbyCall = libbyCall;
export const useInformescDAO = libbyDAO;
export const useInformescLibbyFetch = libbyFetch;

// components/hoc
export const InformescAutocomplete = entityAutocomplete;
export const InformescCall = renderPropCall;
export const InformescById = renderPropFetchById;
export const InformescSelect = simpleSelect;
export const InformescAccordionSelector = accordionSelector;
export const InformescSelectedChips = selectedChips;

// context
// ByIdContext
export const useInformescByIdContext = useByIdContext;
export const InformescByIdProvider = ByIdProvider;
export const InformescByIdContext = ByIdContext;
// GeneralContext
export const useInformescContext = useGeneralContext;
export const InformescProvider = GeneralProvider;
export const InformescContext = GeneralContext;
