import {
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { ChangeEvent, useState } from 'react';
import { MatriculacionSeccion } from 'src/app/models';
import { MainButton } from 'src/commons/components';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';

const useStyles = makeStyles({
  titleContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  listTopRow: {
    marginLeft: 15,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  buttonContainer: { display: 'flex', justifyContent: 'center' },
  sortIcon: { color: '#2dbfe6' },
  textLined: {
    textDecoration: 'line-through',
    textDecorationColor: 'grey',
  },
  tooltip: {
    fontSize: '0.8em',
  },
  text: {
    color: 'grey',
  },
});

interface CopySeccionModalProps {
  isOpen: boolean;
  rows: MatriculacionSeccion[];
  onClose: () => void;
  onSaveCopy: (entries: MatriculacionSeccion[]) => void;
}

export const CopySeccionModal = ({
  isOpen,
  rows,
  onClose,
  onSaveCopy,
}: CopySeccionModalProps) => {
  const classes = useStyles();

  const [checkedRows, setRowsChecked] = useState<MatriculacionSeccion[]>([]);

  const { cicloLectivo: cicloLectivoContext } = useColegioEfectivizadoContext();

  let rowsFiltered: MatriculacionSeccion[] = [];
  rows.forEach((element) => {
    if (element.cicloLectivo.anio === cicloLectivoContext?.anio) {
      rowsFiltered.push(element);
    }
  });

  const handleCheckEntry = (
    e: ChangeEvent<HTMLInputElement>,
    item: MatriculacionSeccion,
  ) => {
    const newRows = [...checkedRows];
    if (e.target.checked) {
      newRows.push(item);
      setRowsChecked(newRows);
    } else {
      const index = newRows.findIndex(
        (elem: any) => elem.seccion === item.idSeccion,
      );
      newRows.splice(index, 1);
      setRowsChecked(newRows);
    }
  };

  const handleSelectAllEntries = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setRowsChecked([
        ...rowsFiltered.filter((elem) => !Boolean(elem.copiedTo)),
      ]);
    } else {
      setRowsChecked([]);
    }
  };

  const handleClose = () => {
    setRowsChecked([]);
    onClose();
  };

  const handleFinishCopying = () => {
    onSaveCopy(checkedRows);
    handleClose();
  };

  const allCopied =
    rowsFiltered.filter((elem) => !Boolean(elem.copiedTo)).length === 0;

  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <div className={classes.titleContainer}>
        <DialogTitle>
          <Typography color="secondary" variant="h3">
            Secciones Próximo Ciclo Lectivo
          </Typography>
        </DialogTitle>
        <div>
          <IconButton onClick={handleClose}>
            <Icon>close</Icon>
          </IconButton>
        </div>
      </div>

      <DialogContent>
        <div className={classes.listTopRow}>
          <div>
            <Checkbox
              edge="start"
              disabled={allCopied}
              checked={
                rowsFiltered.filter((elem) => !Boolean(elem.copiedTo))
                  ?.length === checkedRows?.length && !allCopied
              }
              onChange={handleSelectAllEntries}
            />
          </div>
          <Typography>Sección</Typography>
        </div>
        <Divider />
        <List>
          {rowsFiltered.map((elem, index) => (
            <React.Fragment key={`selection-copy-entry-${index}`}>
              <ListItem>
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    disabled={elem.copiedTo !== null}
                    checked={
                      !!checkedRows.find(
                        (item) => item.idSeccion === elem.idSeccion,
                      )
                    }
                    onChange={(e: any) => handleCheckEntry(e, elem)}
                  />
                </ListItemIcon>
                {elem.copiedTo !== null ? (
                  <Tooltip
                    placement="top"
                    title="Esta sección ya fue copiada anteriormente"
                    classes={{
                      tooltipPlacementTop: classes.tooltip,
                    }}
                  >
                    <ListItemText
                      classes={{
                        root: classes.textLined,
                        primary: classes.text,
                      }}
                    >
                      {elem.nombreSeccion}
                    </ListItemText>
                  </Tooltip>
                ) : (
                  <ListItemText>{elem.nombreSeccion}</ListItemText>
                )}
              </ListItem>
              <Divider />
            </React.Fragment>
          ))}
        </List>

        <div className={classes.buttonContainer}>
          <MainButton
            title="Copiar secciones"
            handleOnClick={handleFinishCopying}
            disabled={checkedRows.length === 0}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};
