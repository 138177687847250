import { LibbyFetchDAO } from './LibbyFetchDAO';

export class DiscipOrientPlanFeedbackDAO extends LibbyFetchDAO {
  constructor() {
    super('discip_orient_plan_feedback', 'idDiscipOrientPlanFeedback');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  async getLastFeedback(id: number | string) {
    // TODO: cambiar el orderBy de la pk al campo de fechaCreacion cuando este disponible
    const [feedback] = await this.query()
      .limit(0, 1)
      .orderBy('idDiscipOrientPlanFeedback', 'desc')
      .equals('discipOrientPlan', id)
      .run();
    return feedback || null;
  }
}
