import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Footer, InfoTable, PeriodosDescripcion, ROL } from 'src/commons';
import { useHistory } from 'react-router';
import { useAspectosGeneralesColumns, useAspectosGeneralesRows } from './hooks';
import { CalificacionesFiltersHeader } from '../../../components/CalificacionesFiltersHeader';
import {
  useCalificacionesSubmit,
  useValidator,
  useExcelData,
  useCalificacionesSwitch,
} from '../../../hooks';
import { useCalificacionesContext } from '../../../context';
import { AlertMessage } from '../../../components';
import { useAspectosGeneralesDAO } from 'src/app/business';
import { useRolesContext } from 'src/context/RolesContext';
import confirmDialog from 'src/commons/services/confirmDialog';
import { AspectosGeneralesProps } from '../../../types';
import { useRoleCanOpenGrade } from '../../../hooks/useRoleCanOpenGrade';
import { useColegioEfectivizadoContext } from '../../../context/EfectivizacionProvider';
import { useFieldsValidator } from './hooks/useFieldsValidator';

const PERIODOS_MAP: { [key: number]: PeriodosDescripcion } = {
  2: 'PRIMER_BIMESTRE',
  4: 'SEGUNDO_BIMESTRE',
};

const useStyles = makeStyles({
  table: {
    maxHeight: '600px',
    marginBottom: '80px',
  },
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export const AspectosGenerales = ({ anioFilter }: AspectosGeneralesProps) => {
  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();
  const { filter, setFilter } = useCalificacionesContext();
  const history = useHistory();
  const classes = useStyles();

  const {
    selectedRole: { localizacionId, rol },
  } = useRolesContext();

  const fieldsToValidate = useFieldsValidator(
    PERIODOS_MAP[filter.periodo],
    'PRIMARIA',
    establecimientoEfectivizado,
  );
  const columns = useAspectosGeneralesColumns(
    2021,
    !!establecimientoEfectivizado,
    filter.periodo,
  );

  const {
    rows = [],
    working = false,
    _rows = [],
    data = [],
    reFetch = () => {},
    isClosed = false,
    readyToClose = false,
    hasCalifications = false,
  } = useAspectosGeneralesRows(filter);
  const aspectosGeneralesDAO = useAspectosGeneralesDAO();
  const { isDisabled, submitRows, incompletedRows } = useValidator({
    fieldsToValidate,
    _rows,
    isDynamic: true,
    filter,
    data,
  });

  const [anioSelect, setAnioSelect] = useState('');
  const [seccionSelect, setSeccionSelect] = useState('');
  const [periodoSelect, setPeriodoSelect] = useState('');

  const showView = useMemo(
    () => Boolean(filter.anio && filter.seccion && filter.periodo),
    [filter],
  );

  const sortedExcelRows = _rows.sort((a, b) => {
    if (a.apellido < b.apellido) return -1;
    if (a.apellido > b.apellido) return 1;
    if (a.nombre < b.nombre) return -1;
    if (a.nombre > b.nombre) return 1;
    return 0;
  });

  const { excelData } = useExcelData(
    columns,
    sortedExcelRows,
    `Calificaciones - Aspectos Generales - ${periodoSelect} - ${seccionSelect} - ${localizacionId}`,
    periodoSelect,
    anioSelect,
    seccionSelect,
    'Aspectos generales',
  );

  const { onSubmit, submitting } = useCalificacionesSubmit({
    columns,
    _rows,
    data,
    filter,
    dao: aspectosGeneralesDAO,
    reFetch,
  });

  const { onSwitch, switchSubmitting } = useCalificacionesSwitch({
    _rows,
    filter,
    dao: aspectosGeneralesDAO,
    reFetch,
    asunto: 'Aspectos Generales',
  });

  const handleLeave = useCallback(async () => {
    if (isDisabled || isClosed || submitRows || !establecimientoEfectivizado) {
      history.push('/');
      return;
    } else {
      const confirm = await confirmDialog.show({
        title: '¿Desea continuar?',
        content:
          'La información precargada se perderá en caso que no guarde, por favor seleccione una opción.',
        confirmText: 'Salir sin guardar',
        cancelText: 'Seguir en esta pantalla',
      });
      if (confirm) {
        try {
          history.push('/');
        } catch (e) {
          console.error(e);
        }
      }
    }
  }, [history, isClosed, isDisabled, submitRows, establecimientoEfectivizado]);

  const canOpenGrade = useRoleCanOpenGrade(['1', '5']);

  const buttonConfig: any = useMemo(() => {
    const defaultButtons: any[] = [
      {
        title: 'Salir',
        size: 'medium',
        type: 'secondary',
        handleOnClick: handleLeave,
      },
      {
        title: 'Guardar',
        size: 'medium',
        handleOnClick: onSubmit,
        disabled:
          isDisabled ||
          isClosed ||
          submitRows ||
          // !establecimientoEfectivizado ||
          Number(rol) === ROL.COORDINADOR_JE,
      },
    ];

    if (!isClosed) {
      defaultButtons.push({
        title: 'Cerrar Calificaciones',
        size: 'medium',
        handleOnClick: () => onSwitch('close'),
        disabled:
          isDisabled ||
          !readyToClose ||
          isClosed ||
          !hasCalifications ||
          Number(rol) === ROL.COORDINADOR_JE,
        // || !establecimientoEfectivizado,
      });
    } else if (canOpenGrade) {
      defaultButtons.push({
        title: 'Abrir Calificaciones',
        size: 'medium',
        handleOnClick: () => onSwitch('open'),
        disabled:
          // !establecimientoEfectivizado ||
          Number(rol) === ROL.COORDINADOR_JE,
      });
    }
    return defaultButtons;
  }, [
    rol,
    // establecimientoEfectivizado,
    handleLeave,
    onSubmit,
    isDisabled,
    isClosed,
    submitRows,
    canOpenGrade,
    readyToClose,
    onSwitch,
    hasCalifications,
  ]);

  const handleAnioChange = (options: any) => {
    const anio = options?.find((s: any) => s.idAnio === filter.anio);
    setAnioSelect(anio?.descripcionAnio);
  };

  const handleSeccionesChange = (options: any) => {
    const seccion = options?.find((s: any) => s.idSeccion === filter.seccion);
    setSeccionSelect(seccion?.nombreSeccion);
  };

  const handlePeriodosChange = (options: any) => {
    const periodo = options?.find((p: any) => p.idPeriodo === filter.periodo);
    setPeriodoSelect(periodo?.tipoPeriodo.descripcionTipoPeriodo);
  };

  return (
    <>
      <AlertMessage
        incompletedRows={incompletedRows}
        isDisabled={isDisabled}
        isClosed={isClosed}
        working={working}
        showView={showView}
      />
      <CalificacionesFiltersHeader
        data={data}
        rows={_rows}
        reFetch={reFetch}
        filter={filter}
        setFilter={setFilter}
        excelData={excelData}
        handleSeccionesChange={handleSeccionesChange}
        handlePeriodosChange={handlePeriodosChange}
        handleAnioChange={handleAnioChange}
        onSubmit={onSubmit}
        showView={showView}
        incompletedRows={incompletedRows}
        readyToClose={readyToClose}
        anioCustomFilter={anioFilter}
      />
      {showView ? (
        <>
          <InfoTable
            rows={rows}
            columns={columns}
            customStyle={classes.table}
            working={working}
          />
          <Grid className={classes.footer}>
            <Footer
              buttonConfig={buttonConfig}
              loading={submitting || switchSubmitting}
            />
          </Grid>
        </>
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: '400px' }}
        >
          <Typography
            style={{
              fontSize: '40px',
              marginTop: '50px',
              color: 'gray',
              fontWeight: 'bold',
              lineHeight: '45px',
            }}
          >
            Seleccione año, sección y período para calificar.
          </Typography>
        </Grid>
      )}
    </>
  );
};
