import React from 'react';
import { Avatar } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AppTheme } from '../../../../../../../theme';

export interface LetterIconProps {
  children: string | number;
  size?: number;
}

const useStyles = makeStyles<AppTheme, LetterIconProps>((theme) => ({
  icon: {
    backgroundColor: theme.palette.info.main,
    width: (props) => theme.spacing(props.size || 3),
    height: (props) => theme.spacing(props.size || 3),
  },
}));

export const LetterIcon = (props: LetterIconProps) => {
  const { children } = props;
  const classes = useStyles(props);
  return <Avatar className={classes.icon}>{children}</Avatar>;
};
