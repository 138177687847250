import { Presente_v3, ContraturnoDia } from '../../../../../app/models';
import { TURNOS } from '../../../../../commons';
import { TurnoOptions } from '../types';
import { filtroPresentismoContraturno } from './filtroPresentismoContraturno';

export const obtenerTurno = (
  idEstadoPresente: number,
  presentismos: Presente_v3[],
) => {
  const filtrados = presentismos.filter(
    (registro) =>
      registro.estadoPresente.idEstadoPresente === idEstadoPresente &&
      !registro.justificacion &&
      registro.turno,
  );
  if (!filtrados.length) return [];

  let tieneManiana = false;
  let tieneTarde = false;
  let tieneVespertino = false;
  let tieneNoche = false;

  for (const presente of filtrados) {
    switch (presente.turno?.idTurno) {
      case TURNOS.MANIANA:
        tieneManiana = true;
        break;
      case TURNOS.TARDE:
        tieneTarde = true;
        break;
      case TURNOS.VESPERTINO:
        tieneVespertino = true;
        break;
      case TURNOS.NOCHE:
        tieneNoche = true;
        break;
      default:
        break;
    }
  }

  const turnosEncontrados = [];

  if (tieneManiana && tieneTarde) {
    turnosEncontrados.push(TURNOS.DOBLE.toString());
  } else {
    if (tieneManiana) turnosEncontrados.push(TURNOS.MANIANA.toString());
    if (tieneTarde) turnosEncontrados.push(TURNOS.TARDE.toString());
    if (tieneVespertino) turnosEncontrados.push(TURNOS.VESPERTINO.toString());
    if (tieneNoche) turnosEncontrados.push(TURNOS.NOCHE.toString());
  }

  return turnosEncontrados;
};

export const obtenerContraturno = (
  idEstadoPresente: number,
  presentismos: Presente_v3[],
) => {
  const presentismoFiltrados = presentismos.filter(
    (registro) =>
      registro.estadoPresente.idEstadoPresente === idEstadoPresente &&
      !registro.justificacion &&
      registro.contraturnoDia,
  );

  const filtrados = presentismoFiltrados
    .map((a) => a.contraturnoDia!)
    .sort((a, b) => a.idContraturnoDia - b.idContraturnoDia);

  const contraturnosFiltered = filtrados.filter(
    (dia) => dia.active && dia.contraturno.active,
  );
  const contraturnosMapped = contraturnosFiltered.reduce<{
    [k: string]: ContraturnoDia[];
  }>((acum, contraturno) => {
    const exist = acum[contraturno.dia.nombre];
    if (!exist) {
      acum = { ...acum, [contraturno.dia.nombre]: [contraturno] };
    } else {
      acum[contraturno.dia.nombre].push(contraturno);
    }
    return acum;
  }, {});
  const contraturnosOrdered = Object.values(contraturnosMapped).sort(
    (a, b) => b.length - a.length,
  );

  const agroupedContraturnos = contraturnosOrdered.reduce<{
    [k: string]: number[];
  }>((acum, contra) => {
    const _options = contra.map((c, i) => ({
      label: `CT${i + 1}`,
      values: c.idContraturnoDia,
    }));
    for (const _o of _options) {
      const exist = acum[_o.label];
      if (exist) {
        acum[_o.label].push(_o.values);
      } else {
        acum = { ...acum, [_o.label]: [_o.values] };
      }
    }
    return acum;
  }, {});

  const _options = Object.entries(agroupedContraturnos).map<TurnoOptions>(
    ([key, value]) => ({
      label: key,
      value: value.join(','),
    }),
  );

  const contraturnosFiltrados = filtroPresentismoContraturno(
    _options,
    presentismoFiltrados,
  );

  if (!contraturnosFiltrados.length) return [];

  return contraturnosFiltrados;
};
