import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  makeStyles,
} from '@material-ui/core';
import styled from 'styled-components';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { Footer } from 'src/commons';
import { Contenido, Nodo } from '../../../../app/models/orientaciones';

const PreCheckboxText = styled.span`
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: 600;
  font-style: italic;
  margin-left: -10px;
  margin-right: 5px;
`;

export type contentToSave =
  | {
      type: 'parent';
      idBloque: string;
      nombre: string;
    }
  | {
      type: 'second parent';
      idBloque: string;
      idEje: number;
      nombre: string;
    }
  | {
      type: 'third parent';
      idBloque: string;
      idEje: number;
      idUnidad: number;
      nombre: string;
    }
  | {
      type: 'child';
      idBloque: string;
      idEje: number;
      idUnidad: number | undefined;
      idContenido: number;
      descripcion: string;
      checked: boolean;
      idOrientacion: number;
    };

const useStyles = makeStyles({
  footer: {
    position: 'sticky',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export const ArbolCheckboxModal = ({
  contenido,
  tituloModal,
  handleSubmit,
  row,
  formValues,
  disableSave,
  handleCloseModal,
}: any) => {
  const [checked, setChecked] = useState<any>([]);
  const [formatContent, setFormatContent] = useState<any>([]);

  const classes = useStyles();

  useEffect(() => {
    if (tituloModal === 'CONTENIDOS') {
      const nodosFilteredAndSorted: Nodo[] = [];
      const nodoFilteredByOrientacion = (contenido as Nodo[]).filter(
        (nodo) => nodo?.orientacion?.idOrientacion === row?.idOrientacion,
      );
      const ejeContentByBloque: { [key: string]: Nodo[] } = {};
      nodoFilteredByOrientacion.forEach((nodoFiltrado) => {
        const { bloque } = nodoFiltrado;

        if (ejeContentByBloque[bloque.idBloque]) {
          ejeContentByBloque[bloque.idBloque].push(nodoFiltrado);
        } else {
          ejeContentByBloque[bloque.idBloque] = [nodoFiltrado];
        }
      });
      const objectKeys = Object.keys(ejeContentByBloque);

      objectKeys.forEach((bloqueKey) => {
        nodosFilteredAndSorted.push(...ejeContentByBloque[bloqueKey]);
      });

      const formatContentToSave: contentToSave[] = [];
      nodosFilteredAndSorted.forEach((content: Nodo) => {
        const { orientacion, unidad, eje, bloque, contenido } = content;
        const bloqueAlreadyExists = formatContentToSave?.some((contentSome) =>
          _.isEqual(Number(contentSome?.idBloque), Number(bloque?.idBloque)),
        );

        const ejeAlreadyExists = formatContentToSave?.some(
          (contentSome) =>
            'idEje' in contentSome &&
            contentSome.idEje === eje.idEje &&
            contentSome.idBloque === bloque.idBloque,
        );
        const unidadAlreadyExists = formatContentToSave?.some(
          (contentSome) =>
            'idUnidad' in contentSome &&
            Number(contentSome.idEje) === Number(eje.idEje) &&
            Number(contentSome.idBloque) === Number(bloque.idBloque) &&
            Number(contentSome.idUnidad) === Number(unidad.idUnidad),
        );
        const contenidoAlreadyExists = formatContentToSave?.some(
          (contentSome) =>
            'idContenido' in contentSome &&
            contentSome.idEje === eje.idEje &&
            contentSome.idBloque === bloque.idBloque &&
            contentSome.idUnidad === unidad.idUnidad &&
            contentSome.idContenido === Number(contenido.idContenido),
        );

        if (!bloqueAlreadyExists) {
          formatContentToSave.push({
            type: 'parent',
            idBloque: bloque?.idBloque,
            nombre: bloque?.nombre,
          });
        }
        if (!ejeAlreadyExists) {
          formatContentToSave.push({
            type: 'second parent',
            idBloque: bloque?.idBloque,
            idEje: eje?.idEje,
            nombre: eje?.nombre,
          });
        }
        if (!unidadAlreadyExists) {
          formatContentToSave.push({
            type: 'third parent',
            idBloque: bloque?.idBloque,
            idEje: eje?.idEje,
            idUnidad: unidad?.idUnidad,
            nombre: unidad?.nombre,
          });
        }
        if (!contenidoAlreadyExists) {
          const formValuesChild = formValues?.nodos?.find(
            (nodo: Nodo) => Number(nodo?.idNodo) === Number(content?.idNodo),
          );
          const alreadyExists = row?.nodos?.find(
            (nodo: Nodo) => Number(nodo?.idNodo) === Number(content.idNodo),
          );

          const checkedValue =
            (formValuesChild?.checked || alreadyExists?.checked) ?? false;

          const object = {
            type: 'child' as 'child',
            idBloque: bloque?.idBloque,
            idEje: eje?.idEje,
            idUnidad: unidad?.idUnidad,
            idContenido: Number(contenido?.idContenido),
            descripcion: contenido?.descripcion,
            checked: checkedValue,
            idOrientacion: orientacion?.idOrientacion,
            idNodo: Number(content.idNodo),
            visible: contenido?.visible,
          };
          formatContentToSave.push(object);

          setChecked((prevState: any) => [...prevState, object]);
        }
        setFormatContent(formatContentToSave);
      });
    } else if (tituloModal === 'HABILIDADES, CAPACIDADES, COMPETENCIAS') {
      const contenidoFilteredByOrientacion = contenido.filter(
        (content: any) =>
          content?.habilidadCategoria?.orientacion?.idOrientacion ===
          row?.idOrientacion,
      );
      const formatContentToSave: any = [];

      contenidoFilteredByOrientacion.forEach((content: any) => {
        const habilidadCategoria = content?.habilidadCategoria;
        const habilidad = {
          nombre: content?.descripcion,
          idHabilidad: content?.idHabilidad,
        };

        const habilidadCategoriaAlreadyExists = formatContentToSave?.some(
          (contentSome: any) =>
            _.isEqual(
              Number(contentSome?.idHabilidadCategoria),
              Number(habilidadCategoria?.idHabilidadCategoria),
            ),
        );
        const habilidadAlreadyExists = formatContentToSave?.some(
          (contentSome: any) =>
            _.isEqual(
              Number(contentSome.idHabilidad),
              Number(habilidad?.idHabilidad),
            ),
        );

        if (!habilidadCategoriaAlreadyExists) {
          formatContentToSave.push({
            type: 'parent',
            idHabilidadCategoria: habilidadCategoria?.idHabilidadCategoria,
            nombre: habilidadCategoria?.nombre,
          });
        }

        if (!habilidadAlreadyExists) {
          const formValuesChild = formValues?.habilidades?.find(
            (formValuesContent: any) =>
              formValuesContent?.idHabilidad === habilidad?.idHabilidad,
          );
          const alreadyExists = row?.habilidades?.find(
            (formValuesContent: any) =>
              formValuesContent?.idHabilidad === habilidad?.idHabilidad,
          );

          const checkedValue =
            (formValuesChild?.checked || alreadyExists?.checked) ?? false;

          const object = {
            type: 'child',
            idHabilidadCategoria: habilidadCategoria?.idHabilidadCategoria,
            idHabilidad: habilidad?.idHabilidad,
            nombre: habilidad?.nombre,
            checked: checkedValue,
            idOrientacion: habilidadCategoria?.orientacion?.idOrientacion,
          };
          formatContentToSave.push(object);

          setChecked((prevState: any) => [...prevState, object]);
        }
        setFormatContent(formatContentToSave);
      });
    } else if (tituloModal === 'PERFIL DEL EGRESADO') {
      const contenidoFilteredByOrientacion = contenido.filter(
        (content: any) =>
          content?.orientacion?.idOrientacion === row?.idOrientacion,
      );
      const formatContentToSave: any = [];

      contenidoFilteredByOrientacion.forEach((content: any) => {
        const perfilOrientado = {
          nombre: content?.descripcion,
          idPerfilOrientado: content?.idPerfilOrientado,
          orientacion: content?.orientacion,
        };

        const perfilOrientadoAlreadyExists = formatContentToSave?.some(
          (contentSome: any) =>
            _.isEqual(
              Number(contentSome?.idPerfilOrientado),
              Number(perfilOrientado?.idPerfilOrientado),
            ),
        );

        if (!perfilOrientadoAlreadyExists) {
          const alreadyExists = row?.perfiles?.find(
            (formValuesContent: any) =>
              formValuesContent?.idPerfilOrientado ===
              perfilOrientado?.idPerfilOrientado,
          );

          const checkedValue = alreadyExists?.checked;

          const object = {
            type: 'child',
            idPerfilOrientado: perfilOrientado?.idPerfilOrientado,
            nombre: perfilOrientado?.nombre,
            checked: checkedValue,
            idOrientacion: perfilOrientado?.orientacion?.idOrientacion,
          };
          formatContentToSave.push(object);

          setChecked((prevState: any) => [...prevState, object]);
        }
        setFormatContent(formatContentToSave);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttonConfig: any = [
    {
      title: 'Cancelar',
      handleOnClick: () => {
        handleCloseModal();
      },
      size: 'medium',
      disabled: false,
    },
    {
      title: 'Guardar',
      handleOnClick: () => {
        handleSubmit(tituloModal, checked);
      },
      size: 'medium',
      disabled: false,
    },
  ];

  const contenidosRender = useMemo(() => {
    const perfilParentIntermediateChecked = (idBloque: string) => {
      const formatedContentFiltered = checked?.filter(
        (contentFilter: any) =>
          Number(contentFilter?.idBloque) === Number(idBloque),
      );
      return (
        formatedContentFiltered.some((content: any) => content?.checked) &&
        formatedContentFiltered.some(
          (content: any) => content?.checked === false,
        )
      );
    };

    const perfilParentChecked = (idBloque: any) => {
      const formatedContentFiltered = checked?.filter(
        (contentFilter: any) =>
          Number(contentFilter?.idBloque) === Number(idBloque),
      );
      const everyChecked = formatedContentFiltered.every((content: any) => {
        return content?.checked;
      });
      return formatedContentFiltered?.length > 0 ? everyChecked : false;
    };

    const perfilSecondParentChecked = (idEje: number) => {
      const checkedFiltered = checked?.filter(
        (contentFilter: any) => contentFilter?.idEje === idEje,
      );
      return checkedFiltered.every((content: any) => content?.checked);
    };

    const perfilSecondParentIntermediateChecked = (idEje: number) => {
      const checkedFiltered = checked?.filter(
        (contentFilter: any) => Number(contentFilter?.idEje) === Number(idEje),
      );
      return (
        checkedFiltered.some((content: any) => content?.checked) &&
        checkedFiltered.some((content: any) => content?.checked === false)
      );
    };

    const perfilThirdParentChecked = (idUnidad: number) => {
      const checkedFiltered = checked?.filter(
        (contentFilter: any) => contentFilter?.idUnidad === idUnidad,
      );
      return checkedFiltered.every((content: any) => content?.checked);
    };

    const perfilThirdParentIntermediateChecked = (idUnidad: number) => {
      const checkedFiltered = checked?.filter(
        (contentFilter: any) =>
          Number(contentFilter?.idUnidad) === Number(idUnidad),
      );
      return (
        checkedFiltered.some((content: any) => content?.checked) &&
        checkedFiltered.some((content: any) => content?.checked === false)
      );
    };

    const handleChangeParent = (event: any, key: string) => {
      const filteredChildren = checked?.filter(
        (contentFilter: any) =>
          Number(contentFilter[key]) === Number(event.target.name),
      );
      let clonedState = _.cloneDeep(checked);
      const newChecked = filteredChildren?.map((child: any) => {
        _.remove(
          clonedState,
          (clonedCheck: any) => clonedCheck?.idNodo === child?.idNodo,
        );

        const isEveryChildChecked = filteredChildren.every(
          (filteredChild: any) => filteredChild?.checked,
        );

        if (isEveryChildChecked) {
          return {
            ...child,
            checked: false,
          };
        } else {
          return {
            ...child,
            checked: true,
          };
        }
      });

      setChecked([...clonedState, ...newChecked]);
    };

    const handleChangeChild = (event: any) => {
      const children = checked?.find(
        (content: any) =>
          Number(content?.idNodo) === Number(event?.target?.name),
      );

      let clonedState = _.cloneDeep(checked);
      _.remove(
        clonedState,
        (clonedCheck: any) =>
          Number(clonedCheck?.idNodo) === Number(children?.idNodo),
      );
      setChecked([
        ...clonedState,
        { ...children, checked: !children?.checked },
      ]);
    };

    return (
      <div>
        {formatContent?.map((content: contentToSave, index: number) => {
          if (content?.type === 'parent') {
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  ml: 3,
                }}
              >
                <FormControlLabel
                  label={'Bloque. ' + content?.nombre}
                  control={
                    <Checkbox
                      name={content?.idBloque}
                      checked={perfilParentChecked(content?.idBloque)}
                      indeterminate={perfilParentIntermediateChecked(
                        content?.idBloque,
                      )}
                      onChange={(e: any) => handleChangeParent(e, 'idBloque')}
                    />
                  }
                />
              </Box>
            );
          } else if (content?.type === 'second parent') {
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  ml: 6,
                }}
              >
                <FormControlLabel
                  label={'Eje. ' + content?.nombre}
                  control={
                    <Checkbox
                      name={content?.idEje}
                      checked={perfilSecondParentChecked(content?.idEje)}
                      indeterminate={perfilSecondParentIntermediateChecked(
                        content?.idEje,
                      )}
                      onChange={(e: any) => handleChangeParent(e, 'idEje')}
                    />
                  }
                />
              </Box>
            );
          } else if (content?.type === 'third parent') {
            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  ml: 9,
                }}
              >
                <FormControlLabel
                  label={'Unidad. ' + content?.nombre}
                  control={
                    <Checkbox
                      name={content?.idUnidad}
                      checked={perfilThirdParentChecked(content?.idUnidad)}
                      indeterminate={perfilThirdParentIntermediateChecked(
                        content?.idUnidad,
                      )}
                      onChange={(e: any) => handleChangeParent(e, 'idUnidad')}
                    />
                  }
                />
              </Box>
            );
          } else if (content?.type === 'child') {
            const findChecked = checked.find((contentFind: any) => {
              return Number(contentFind?.idNodo) === Number(content?.idNodo);
            });
            if (content?.visible === false) {
              return <></>;
            } else {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    ml: content.idUnidad ? 12 : 9,
                    marginBottom: 20,
                  }}
                >
                  <FormControlLabel
                    label={content?.descripcion}
                    control={
                      <Checkbox
                        name={content?.idNodo}
                        checked={findChecked?.checked}
                        onChange={(e: any) => handleChangeChild(e)}
                      />
                    }
                  />
                </Box>
              );
            }
          }
        })}
      </div>
    );
  }, [checked, formatContent]);

  const habilidadesRender = useMemo(() => {
    const habilidadesParentIntermediateChecked = (
      idHabilidadCategoria: any,
    ) => {
      const formatedContentFiltered = checked?.filter(
        (contentFilter: any) =>
          Number(contentFilter?.idHabilidadCategoria) ===
          Number(idHabilidadCategoria),
      );
      return (
        formatedContentFiltered.some((content: any) => content?.checked) &&
        formatedContentFiltered.some(
          (content: any) => content?.checked === false,
        )
      );
    };

    const habilidadesParentChecked = (idHabilidadCategoria: any) => {
      const formatedContentFiltered = checked?.filter(
        (contentFilter: any) =>
          Number(contentFilter?.idHabilidadCategoria) ===
          Number(idHabilidadCategoria),
      );
      const everyChecked = formatedContentFiltered.every((content: any) => {
        return content?.checked;
      });
      return formatedContentFiltered?.length > 0 ? everyChecked : false;
    };

    const handleChangeParent = (event: any, key: string) => {
      const filteredChildren = checked?.filter(
        (contentFilter: any) =>
          Number(contentFilter[key]) === Number(event.target.name),
      );
      let clonedState = _.cloneDeep(checked);
      const newChecked = filteredChildren?.map((child: any) => {
        _.remove(
          clonedState,
          (clonedCheck: any) => clonedCheck?.idHabilidad === child?.idHabilidad,
        );

        const isEveryChildChecked = filteredChildren.every(
          (filteredChild: any) => filteredChild?.checked,
        );

        if (isEveryChildChecked) {
          return {
            ...child,
            checked: false,
          };
        } else {
          return {
            ...child,
            checked: true,
          };
        }
      });

      setChecked([...clonedState, ...newChecked]);
    };

    const handleChangeChild = (event: any) => {
      const children = checked?.find(
        (content: any) =>
          Number(content?.idHabilidad) === Number(event?.target?.name),
      );

      let clonedState = _.cloneDeep(checked);
      _.remove(
        clonedState,
        (clonedCheck: any) =>
          Number(clonedCheck?.idHabilidad) === Number(children?.idHabilidad),
      );

      setChecked([
        ...clonedState,
        { ...children, checked: !children?.checked },
      ]);
    };

    return (
      <div>
        {formatContent?.map((content: any, index: number) => {
          if (content?.type === 'parent') {
            return (
              <Box sx={{ display: 'flex', flexDirection: 'column', ml: 3 }}>
                <FormControlLabel
                  label={content?.nombre}
                  control={
                    <Checkbox
                      name={content?.idHabilidadCategoria}
                      checked={habilidadesParentChecked(
                        content?.idHabilidadCategoria,
                      )}
                      indeterminate={habilidadesParentIntermediateChecked(
                        content?.idHabilidadCategoria,
                      )}
                      onChange={(e: any) =>
                        handleChangeParent(e, 'idHabilidadCategoria')
                      }
                    />
                  }
                />
              </Box>
            );
          } else if (content?.type === 'child') {
            const findChecked = checked.find((contentFind: any) => {
              return contentFind?.idHabilidad === content?.idHabilidad;
            });

            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  ml: 6,
                  marginBottom: 20,
                }}
              >
                <FormControlLabel
                  label={content?.nombre}
                  control={
                    <Checkbox
                      name={content?.idHabilidad}
                      checked={findChecked?.checked}
                      onChange={(e: any) => handleChangeChild(e)}
                    />
                  }
                />
              </Box>
            );
          }
        })}
      </div>
    );
  }, [checked, formatContent]);

  const perfilRender = useMemo(() => {
    const handleChangeChild = (event: any) => {
      const children = checked?.find(
        (content: any) =>
          Number(content?.idPerfilOrientado) === Number(event?.target?.name),
      );

      let clonedState = _.cloneDeep(checked);
      _.remove(
        clonedState,
        (clonedCheck: any) =>
          Number(clonedCheck?.idPerfilOrientado) ===
          Number(children?.idPerfilOrientado),
      );

      setChecked([
        ...clonedState,
        { ...children, checked: !children?.checked },
      ]);
    };

    return (
      <div>
        {formatContent?.map((content: any, index: number) => {
          if (content?.type === 'child') {
            const findChecked = checked.find((contentFind: any) => {
              return (
                contentFind?.idPerfilOrientado === content?.idPerfilOrientado
              );
            });

            return (
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  ml: 3,
                  marginBottom: 20,
                }}
              >
                <FormControlLabel
                  label={content?.nombre}
                  control={
                    <Checkbox
                      name={content?.idPerfilOrientado}
                      checked={findChecked?.checked}
                      onChange={(e: any) => handleChangeChild(e)}
                    />
                  }
                />
              </Box>
            );
          }
        })}
      </div>
    );
  }, [checked, formatContent]);

  if (tituloModal === 'CONTENIDOS') {
    return (
      <>
        {contenidosRender}
        <Grid className={classes.footer}>
          <Footer buttonConfig={buttonConfig} spacing={1} />
        </Grid>
      </>
    );
  } else if (tituloModal === 'HABILIDADES, CAPACIDADES, COMPETENCIAS') {
    return (
      <>
        {habilidadesRender}
        <Grid className={classes.footer}>
          <Footer buttonConfig={buttonConfig} spacing={1} />
        </Grid>
      </>
    );
  } else if (tituloModal === 'PERFIL DEL EGRESADO') {
    return (
      <>
        {perfilRender}
        <Grid className={classes.footer}>
          <Footer buttonConfig={buttonConfig} spacing={1} />
        </Grid>
      </>
    );
  } else {
    return <></>;
  }
};
