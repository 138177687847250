import { MenuItem } from '../../../../commons/const/ui/menu';
import {
  RepositorioABMForm,
  RepositorioABMList,
} from '../../../../screens/Private';

export const routes: MenuItem[] = [
  {
    key: 'repositorioABMForm',
    basePath: '/form/:idRepositorioItem',
    target: {
      component: RepositorioABMForm,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Repositorio Form ABM',
    children: [],
  },
  {
    key: 'repositorioABMList',
    basePath: '/',
    target: {
      component: RepositorioABMList,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Repositorio ABM',
    children: [],
    default: true,
  },
];
