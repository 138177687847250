import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { AutoRegistracion } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'autoregistracion';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idAutoRegistracion',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idAutoRegistracion',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.autoRegistracion
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.autoRegistracion,
      hoc: { ...defaultOptions.hoc, ...businessOptions.autoRegistracion.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<AutoRegistracion>(DAO_NAME, optionsToUse);

// hooks
export const useAutoRegistracionFetchById = fetchById;
export const useAutoRegistracionLibbyCall = libbyCall;
export const useAutoRegistracionDAO = libbyDAO;
export const useAutoRegistracionLibbyFetch = libbyFetch;

// components/hoc
export const AutoRegistracionAutocomplete = entityAutocomplete;
export const AutoRegistracionCall = renderPropCall;
export const AutoRegistracionById = renderPropFetchById;
export const AutoRegistracionSelect = simpleSelect;
export const AutoRegistracionAccordionSelector = accordionSelector;
export const AutoRegistracionSelectedChips = selectedChips;

// context
// ByIdContext
export const useAutoRegistracionByIdContext = useByIdContext;
export const AutoRegistracionByIdProvider = ByIdProvider;
export const AutoRegistracionByIdContext = ByIdContext;
// GeneralContext
export const useAutoRegistracionContext = useGeneralContext;
export const AutoRegistracionProvider = GeneralProvider;
export const AutoRegistracionContext = GeneralContext;
