import { Grid, makeStyles, Step, StepLabel, Stepper } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { Footer, Loading } from 'src/commons';
import { StepOne, StepThree, StepTwo } from '../../steps';
import customFormDialog from 'src/commons/services/customFormDialog';
import { AlumnoMovimiento, EspacioCurricular, Seccion } from 'src/app/models';
import { saveEquivalencias } from '../..';
import { usePlanEstudioSeccionPostRequest } from 'src/app/business';
import { useSnackbar } from 'notistack';
import { primary } from 'src/theme/colors';
type GroupCalificacion = {
  alumnos: AlumnoMovimiento[];
  secciones: string[];
  planesDestino: EspacioCurricular[];
  planesOrigen: EspacioCurricular[];
};

const customStyles = makeStyles({
  iconColor: { color: primary.paleBlueStrong, cursor: 'pointer' },
  backgroundRow: { background: primary.paleBlue },
  loader: {
    margin: '200px 0px',
  },
  customContainer: {
    position: 'fixed',
    top: '0px',
    left: '0px',
    backgroundColor: '#000000A0',
    height: '100vh',
    width: '100vw',
    zIndex: 10000,
  },
  loadingMessage: {
    position: 'absolute',
    marginTop: '6em',
    fontWeight: 800,
    fontFamily: 'Open Sans',
    color: 'white',
    userSelect: 'none',
  },
});

export const CopiadoCalificaciones = ({
  alumnsWithSections,
  dataFormatted,
  fetch,
  cleanRows,
  requestIgualarCalificaciones,
}: any) => {
  const [activeStep, setActiveStep] = useState(0);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [dataModified, setDataModified] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const componentClasses = customStyles();
  const allSteps: {
    [k: string]: {
      label: string;
      render: React.ReactElement;
    };
  } = {
    alumnos: {
      label: 'Alumnos',
      render: <StepOne alumnsWithSections={alumnsWithSections} />,
    },
    copiado: {
      label: 'Copiado de calificaciones',
      render: (
        <StepTwo
          dataFormatted={dataFormatted}
          currentIndex={currentIndex}
          setDataModified={setDataModified}
        />
      ),
    },
    resumen: {
      label: 'Resumen de cambios',
      render: <StepThree dataModified={dataModified} />,
    },
  };

  const { enqueueSnackbar } = useSnackbar();

  const handleLeave = () => {
    customFormDialog.handleCancel();
  };

  const { request, working: planEstudioSeccionPostWorking } =
    usePlanEstudioSeccionPostRequest();

  const handleSubmit = async () => {
    setIsSaving(true);
    const response: any = await requestIgualarCalificaciones({
      data: alumnsWithSections.map(
        (alumno: AlumnoMovimiento & { newSeccion?: Seccion }) => {
          return {
            idAlumno: alumno.alumno.idAlumno,
            idSeccionOrigen: alumno.seccion.idSeccion,
            idSeccionDestino: alumno.newSeccion?.idSeccion,
          };
        },
      ),
    });
    if (response.shouldSeeSnackBar) {
      enqueueSnackbar(response?.text, {
        variant: 'success',
        autoHideDuration: 2000,
      });
    }
    const success = await saveEquivalencias(dataFormatted, request);
    if (success) {
      customFormDialog.handleConfirm(success);
      fetch();
      cleanRows();
      console.log(isSaving);
      setIsSaving(false);
    }
  };

  const atras = () => {
    if (activeStep === 1 && currentIndex > 0) {
      setCurrentIndex((prevState: number) => prevState - 1);
    } else {
      setActiveStep((prevState: number) => prevState - 1);
    }
  };

  const siguiente = () => {
    if (
      activeStep === 1 &&
      currentIndex !== dataFormatted.isNotTheSamePlan.length - 1
    ) {
      setCurrentIndex((prevState: number) => prevState + 1);
    } else {
      setActiveStep((prevState: any) => prevState + 1);
      if (dataModified.length > 0) {
        dataFormatted.isNotTheSamePlan = dataModified;
      }
    }
  };

  const defaultButtons: any[] = [
    {
      title: 'Atras',
      size: 'medium',
      type: 'secondary',
      handleOnClick: atras,
      disabled: activeStep === 0,
    },
    {
      title: 'Siguiente',
      size: 'medium',
      type: 'secondary',
      handleOnClick: siguiente,
      disabled: activeStep === 2,
    },
    {
      title: 'Salir',
      size: 'medium',
      type: 'secondary',
      handleOnClick: handleLeave,
    },
    {
      title: 'Guardar',
      size: 'medium',
      handleOnClick: handleSubmit,
      disabled: activeStep !== 2 || planEstudioSeccionPostWorking,
    },
  ];

  const steps = useMemo(() => {
    return [allSteps.alumnos, allSteps.copiado, allSteps.resumen];
  }, [allSteps.alumnos, allSteps.copiado, allSteps.resumen]);

  return (
    <>
      <Grid
        container
        direction="column"
        justify="space-between"
        alignItems="stretch"
        style={{
          position: 'relative',
          height: '100%',
          paddingBottom: 100,
        }}
      >
        {isSaving && (
          <Grid
            className={`${componentClasses.customContainer}`}
            container
            justify="center"
            alignItems="center"
          >
            <Loading />
            <div className={`${componentClasses.loadingMessage}`}>
              <span>
                Esta operación puede demorar un poco, por favor espere.
              </span>
            </div>
          </Grid>
        )}
        <Stepper activeStep={activeStep} alternativeLabel>
          {steps.map(({ label }) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        {steps[activeStep].render}
      </Grid>
      <Footer buttonConfig={defaultButtons} loading={false} />
    </>
  );
};
