import React, { useMemo, useState } from 'react';
import { InfoTableColumn, NIVEL, Tooltips } from 'src/commons';
import { primary } from 'src/theme/colors';
import EditIcon from '@material-ui/icons/Edit';
import { Grid, IconButton } from '@material-ui/core';
import {
  AlumnoMovimiento,
  Proyeccion,
  ProyeccionEstado,
  Seccion,
  SeccionWithProyeccion,
} from 'src/app/models';
import Tooltip from '@material-ui/core/Tooltip';
import ErrorIcon from '@material-ui/icons/Error';
import CheckIcon from '@material-ui/icons/Check';
import { TooltipErrors } from '../components/TooltipsError';
import useSolicitudPases from './useSolicitudPases';
import { ROL } from 'src/commons/const/rol';
import moment from 'moment';
import { CURRENT_CICLO_LECTIVO } from '../../../Acap';
import { useDateValidator } from 'src/commons/hooks/useDateValidator';

const rolesDEI = [
  ROL.DIRECCION_AREA,
  ROL.SUPERVISOR,
  ROL.DIRECTIVO,
  ROL.EQUIPO_CONDUCCION,
];

const rolesNormal = [ROL.DIRECCION_AREA, ROL.SUPERVISOR];

const customStyles = {
  iconColor: { color: primary.paleBlueStrong, cursor: 'pointer' },
  disabledIcon: { color: 'gray' },
  backgroundRow: { background: primary.paleBlue },
};

export const usePromocionColumns: Promocion.usePromocionColumns = ({
  alumnoMovimiento,
  proyecciones,
  setSelectedSecction,
  proyeccionEstado,
  seccionFiltered,
  todasNextSections,
  esDEI,
  rol,
  proyeccionIEL,
  escuelasCBOyREINGRESO,
}) => {
  const enabled = useDateValidator('Proyectar');
  const pases = useSolicitudPases();

  const secciones = seccionFiltered.nextYearSections.filter(
    (seccion) =>
      seccion?.cicloLectivo?.anio ===
      seccionFiltered?.currentYearSections[0]?.cicloLectivo.anio + 1,
  );

  const isFinished =
    proyeccionEstado[0]?.proyeccionEstadoTipo?.idProyeccionEstadoTipo === 2;

  const isEfectivizado: boolean =
    proyeccionEstado[0]?.proyeccionEstadoTipo.idProyeccionEstadoTipo === 3 &&
    proyeccionEstado[0]?.cicloLectivo.anio + 1 === moment().year();

  const columns = useMemo<InfoTableColumn<Promocion.InfoTableRow>[]>(() => {
    const buddy = 0;
    return [
      {
        id: 'anio_id',
        label: 'Año',
        width: '7%',
        orderById: 'anio',
        render: (_, row) => <p> {row.anio.descripcionAnio}</p>,
      },
      {
        id: 'working_day',
        label: 'Jornada',
        width: '7%',
        orderById: 'jornada',
        render: (_, row) => <p>{row.jornada}</p>,
      },
      {
        id: 'turn',
        label: 'Turno',
        width: '7%',
        orderById: 'turno',
        render: (_, row) => <p>{row.turno?.descripcionTurno}</p>,
      },
      {
        id: 'dividing',
        label: 'División',
        width: '7%',
        orderById: 'division',
        render: (_, row) => <p>{row.division}</p>,
      },
      {
        id: 'coupon',
        label: 'Capacidad',
        width: '5%',
        orderById: 'capacidadMaxima',
        align: 'center',
        render: (_, row) => <p>{row.capacidadMaxima}</p>,
      },
      /* 
      SE OCULTA ESTA COLUMNA A PEDIDO DEL CLIENTE.
      {
        id: 'registered',
        label: 'Matriculados',
        width: '5%',
        align: 'center',
        hideSortIcon: true,
        noSort: true,
        hideColumn: { esDEI },
        render: (_, row) => {
          const quantityStudent = alumnoMovimiento.filter(
            (alumno: {
              countofidalumnomovimiento: string;
              entity_id_seccion: string;
            }) => alumno.entity_id_seccion === row.idSeccion,
          );
          const alumnoValue = !!Number(
            quantityStudent[0]?.countofidalumnomovimiento,
          )
            ? Number(quantityStudent[0]?.countofidalumnomovimiento) + buddy
            : 0 + buddy;
          return <p>{alumnoValue}</p>;
        },
      }, */
      {
        id: 'buddy',
        label: 'Acompañantes',
        width: '5%',
        align: 'center',
        hideSortIcon: true,
        noSort: true,
        render: (_, row) => <p>{buddy}</p>,
      },
      {
        id: 'vacancy',
        label: 'Vacantes',
        width: '5%',
        align: 'center',
        hideSortIcon: true,
        noSort: true,
        render: (_, row) => {
          const quantityStudent = alumnoMovimiento.filter(
            (alumno: {
              countofidalumnomovimiento: string;
              entity_id_seccion: string;
            }) => alumno.entity_id_seccion === row.idSeccion,
          );
          const alumnoValue = !!Number(
            quantityStudent[0]?.countofidalumnomovimiento,
          )
            ? Number(quantityStudent[0]?.countofidalumnomovimiento)
            : 0;
          const vacanty = row.capacidadMaxima - alumnoValue;
          return <p>{vacanty}</p>;
        },
      },
      {
        id: 'busy_projected',
        label: 'Ocupado proyectado',
        width: '10%',
        align: 'center',
        hideSortIcon: true,
        noSort: true,
        customStyles: customStyles.backgroundRow,
        render: (_, row) => {
          // OBTENEMOS LAS PROMOCIONES CON EL MISMO NOMBRE DE DIVISION, ANIO Y CON EL CICLO LECTIVO QUE VIENE
          let promotionPassAnio = proyecciones.filter(
            (element: any) =>
              element?.seccionDestino?.division === row?.division &&
              element?.seccionDestino?.cicloLectivo?.anio ===
                row?.cicloLectivo?.anio + 1 &&
              element?.seccionDestino?.anio?.idAnio === row?.anio?.idAnio &&
              element?.seccionDestino?.jornada === row?.jornada &&
              element?.seccionDestino?.turno?.descripcionTurno ===
                row?.turno?.descripcionTurno,
          );

          if (promotionPassAnio.length === 0) {
            promotionPassAnio = proyecciones.filter(
              (element: any) =>
                element?.seccionDestino?.nombreSeccion === row?.nombreSeccion,
            );
          }

          /* const total = promotionPassAnio.map((item: any) => {
            return item;
          }); */
          const permanecen = promotionPassAnio.filter((item: any) => {
            return (
              item.estadoPaseAnio.descripcionEstadoPaseAnio === 'Permanece'
            );
          });
          const promocionan = promotionPassAnio.filter((item: any) => {
            return (
              item.estadoPaseAnio.descripcionEstadoPaseAnio === 'Promociona'
            );
          });
          /* const articulan = promotionPassAnio.filter((item: any) => {
            return item.estadoPaseAnio.articula;
          }); */
          return (
            <Tooltips
              title={
                <Grid>
                  <p>
                    <span style={{ fontWeight: 'bold', marginRight: '1em' }}>
                      Permanecen:
                    </span>
                    {`${permanecen.length}`}
                  </p>
                  <p>
                    <span style={{ fontWeight: 'bold', marginRight: '1em' }}>
                      Promocionan:
                    </span>
                    {`${promocionan.length}`}
                  </p>
                </Grid>
              }
            >
              <p>{promotionPassAnio.length}</p>
            </Tooltips>
          );
        },
      },
      {
        id: 'vacancy_projected',
        label: 'Vacantes proyectadas',
        width: '10%',
        align: 'center',
        hideSortIcon: true,
        noSort: true,
        customStyles: customStyles.backgroundRow,
        render: (_, row) => {
          let promotionPassAnio = proyecciones.filter(
            (element: any) =>
              element?.seccionDestino?.division === row?.division &&
              element?.seccionDestino?.cicloLectivo?.anio ===
                row?.cicloLectivo?.anio + 1 &&
              element?.seccionDestino?.anio?.idAnio === row?.anio?.idAnio &&
              element?.seccionDestino?.jornada === row?.jornada &&
              element?.seccionDestino?.turno?.descripcionTurno ===
                row?.turno?.descripcionTurno,
          );

          if (promotionPassAnio.length === 0) {
            promotionPassAnio = proyecciones.filter(
              (element: any) =>
                element?.seccionDestino?.nombreSeccion === row?.nombreSeccion,
            );
          }
          // OBTENEMOS LA PROXIMA SECCION QUE TENGA EL MISMO NOMBRE DE DIVISION, ANIO Y CON EL CICLO LECTIVO QUE VIENE PARA OBTENER SU CAPACIDAD MAXIMA
          let nextSection = secciones.filter(
            (element: any) =>
              element?.division === row?.division &&
              element?.cicloLectivo?.anio === row?.cicloLectivo?.anio + 1 &&
              element?.anio?.idAnio === row?.anio?.idAnio &&
              element?.jornada === row?.jornada &&
              element?.turno?.descripcionTurno === row?.turno?.descripcionTurno,
          );

          if (nextSection.length === 0) {
            nextSection = secciones.filter(
              (element: any) => element?.nombreSeccion === row?.nombreSeccion,
            );
          }
          let vacantyProject = 0;

          if (nextSection.length && promotionPassAnio.length) {
            vacantyProject =
              nextSection[0].capacidadMaxima - promotionPassAnio.length;
          } else if (nextSection.length) {
            vacantyProject = nextSection[0].capacidadMaxima;
          }

          return <p>{vacantyProject}</p>;
        },
      },
      {
        id: 'projection_status',
        label: 'Estado Proyección',
        width: '10%',
        align: 'center',
        render: (_, row) => {
          return <p>{`${row.estadoProyeccion.descripcion}`}</p>;
        },
      },
      ...(!isEfectivizado
        ? ([
            {
              id: 'alert',
              label: 'Alertas',
              width: '5%',
              align: 'center',
              orderById: 'alert',
              render: (_, row) => {
                let bodyMessage: string[] = [];

                if (row.hasPendingPase(pases))
                  bodyMessage.push('La sección tiene pases pendientes.');
                if (
                  row?.estadoProyeccion?.alumnosEnProceso &&
                  row?.nivel?.descripcionNivel === 'Secundario' &&
                  isFinished
                )
                  bodyMessage.push(
                    'regularizar estados de alumnos con estado En Proceso',
                  );

                if (
                  !row?.calificacionesCerradas &&
                  row?.nivel?.descripcionNivel === 'Secundario' &&
                  isFinished &&
                  row?.tieneDefinitivas &&
                  !!row?.tieneCalificaciones
                )
                  bodyMessage.push('cerrar las calificaciones');

                if (
                  (!row?.tieneDefinitivas || !row?.tieneCalificaciones) &&
                  row?.nivel?.descripcionNivel === 'Secundario' &&
                  !escuelasCBOyREINGRESO.includes(
                    row.localizacion.idLocalizacion,
                  ) &&
                  isFinished &&
                  row?.tieneEstudiantes
                )
                  bodyMessage.push('Alumnos sin calificación definitiva');
                if (
                  !row?.estadoProyeccion?.proyectada &&
                  !isFinished &&
                  row?.tieneEstudiantes
                )
                  bodyMessage.push(
                    'falta proyectar sección o hay estudiantes por terminar proyección',
                  );
                if (row?.continuaApoyo)
                  bodyMessage.push(
                    'falta regularizar los alumnos con estado en proceso',
                  );

                return (
                  <Tooltip
                    title={
                      <TooltipErrors
                        isFinished={isFinished}
                        errorMessages={bodyMessage}
                        title={
                          <h4>
                            {isFinished
                              ? 'No se puede efectivizar debido a: '
                              : 'No se puede proyectar la seccion debido a: '}
                          </h4>
                        }
                      />
                    }
                  >
                    <IconButton aria-label="delete">
                      {bodyMessage.length === 0 ? (
                        <CheckIcon color="primary" />
                      ) : (
                        <ErrorIcon color="error" />
                      )}
                    </IconButton>
                  </Tooltip>
                );
              },
            },
          ] as InfoTableColumn<Promocion.InfoTableRow>[])
        : []),
      {
        id: 'icon',
        label: '',
        width: '5%',
        hideSortIcon: true,
        noSort: true,
        align: 'center',
        render: (_, row) => {
          let isDisabled = true;
          // TODO PARA EL RESTO QUEDA SIEMPREEE DISABLED
          if (esDEI && enabled) {
            const pendiente =
              proyeccionEstado[0]?.proyeccionEstadoTipo
                ?.idProyeccionEstadoTipo === 1;
            const realizado =
              proyeccionEstado[0]?.proyeccionEstadoTipo
                ?.idProyeccionEstadoTipo === 3 &&
              proyeccionEstado[0]?.cicloLectivo.idCicloLectivo ===
                CURRENT_CICLO_LECTIVO;
            if ((pendiente || realizado) && rolesDEI.includes(Number(rol))) {
              isDisabled = false;
            }
            if (
              proyeccionEstado[0]?.proyeccionEstadoTipo
                ?.idProyeccionEstadoTipo === 2 &&
              rol === ROL.DIRECCION_AREA.toString()
            ) {
              isDisabled = false;
            }
          } else {
            const pendiente =
              proyeccionEstado[0]?.proyeccionEstadoTipo
                ?.idProyeccionEstadoTipo === 1;
            const realizado =
              proyeccionEstado[0]?.proyeccionEstadoTipo
                ?.idProyeccionEstadoTipo === 3 &&
              proyeccionEstado[0]?.cicloLectivo.idCicloLectivo ===
                CURRENT_CICLO_LECTIVO;
            if (pendiente) {
              isDisabled = false;
            }
            if (realizado && rolesNormal.includes(Number(rol))) {
              isDisabled = false;
            }
            if (
              proyeccionEstado[0]?.proyeccionEstadoTipo
                ?.idProyeccionEstadoTipo === 2 &&
              !esDEI
            ) {
              isDisabled = false;
            }
          }

          return isEfectivizado ||
            (seccionFiltered.nextYearSections.length === 0 &&
              seccionFiltered.nextYearCurrentSections?.length === 0) ? (
            <Tooltip title={'Falta copiar secciones del proximo ciclo lectivo'}>
              <span>
                <IconButton
                  disabled={
                    isDisabled ||
                    isEfectivizado ||
                    (seccionFiltered.nextYearSections.length === 0 &&
                      seccionFiltered.nextYearCurrentSections?.length === 0)
                  }
                  onClick={() =>
                    setSelectedSecction({
                      idCurrentSection: row.idSeccion,
                      nameSection: row.nombreSeccion,
                      descripcionAnioSection: row.anio?.descripcionAnio,
                      idAnio: row.anio?.idAnio,
                    })
                  }
                >
                  <EditIcon
                    style={
                      isDisabled ||
                      isEfectivizado ||
                      (seccionFiltered.nextYearSections.length === 0 &&
                        seccionFiltered.nextYearCurrentSections?.length === 0)
                        ? customStyles.disabledIcon
                        : customStyles.iconColor
                    }
                  />
                </IconButton>
              </span>
            </Tooltip>
          ) : (
            <IconButton
              disabled={
                isDisabled ||
                isEfectivizado ||
                (seccionFiltered.nextYearSections.length === 0 &&
                  seccionFiltered.nextYearCurrentSections?.length === 0)
              }
              onClick={() =>
                setSelectedSecction({
                  idCurrentSection: row.idSeccion,
                  nameSection: row.nombreSeccion,
                  descripcionAnioSection: row.anio?.descripcionAnio,
                  idAnio: row.anio?.idAnio,
                })
              }
            >
              <EditIcon
                style={
                  isDisabled ||
                  isEfectivizado ||
                  (seccionFiltered.nextYearSections.length === 0 &&
                    seccionFiltered.nextYearCurrentSections?.length === 0)
                    ? customStyles.disabledIcon
                    : customStyles.iconColor
                }
              />
            </IconButton>
          );
        },
      },
    ];
  }, [
    isEfectivizado,
    alumnoMovimiento,
    proyecciones,
    secciones,
    pases,
    isFinished,
    escuelasCBOyREINGRESO,
    esDEI,
    enabled,
    seccionFiltered.nextYearSections.length,
    seccionFiltered.nextYearCurrentSections?.length,
    proyeccionEstado,
    rol,
    setSelectedSecction,
  ]);

  return { columns, seccionesSiguienteCicloLectivo: todasNextSections };
};
