import React from 'react';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import UnfoldMoreIcon from '@material-ui/icons/UnfoldMore';
import { grey, primary } from '../../../../../theme/colors';
import { InfoTableColumn } from '../../../..';

type AnyObject = { [k: string]: any };

const useStyles = makeStyles({
  root: {
    background: grey.background,
    color: grey.dark,
    fontSize: 13,
    fontWeight: 600,
    '&.MuiTableSortLabel-icon': {
      color: primary.lightBlue,
    },
  },
  customLabel: {
    fontFamily: 'Nunito',
    fontStyle: 'Italic',
    fontWeight: 600,
    fontSize: 12,
    color: '#38485C',
    marginTop: '-10px',
    marginBottom: '0px',
  },
});

interface EnhancedTableHeadProps {
  order: 'desc' | 'asc';
  orderBy?: string;
  onRequestSort: (event: AnyObject, property: any) => void;
  rowCount: number;
  headerAlign: 'left' | 'right' | 'inherit' | 'center' | 'justify';
  headCells: InfoTableColumn<any>[];
}

export function EnhancedTableHead({
  order,
  orderBy,
  onRequestSort,
  headCells,
  headerAlign,
}: EnhancedTableHeadProps) {
  const classes = useStyles();
  const createSortHandler = (property: any) => (event: AnyObject) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headCells.map(
          (
            {
              id,
              label,
              icon,
              render,
              orderById,
              noSort,
              hideSortIcon,
              minWidth,
              headCellsStyles,
              onHeadClick,
              sortLabelStyles,
              ...styles
            }: AnyObject,
            index,
            all,
          ) => (
            <TableCell
              key={id}
              align={headerAlign}
              sortDirection={orderBy === id ? order : false}
              {...styles}
              classes={{
                root: classes.root,
              }}
              style={{ minWidth, ...headCellsStyles }}
              onClick={onHeadClick ? () => onHeadClick(id) : undefined}
            >
              <TableSortLabel
                style={sortLabelStyles}
                hideSortIcon={hideSortIcon}
                active={orderBy === id || orderBy === orderById}
                disabled={hideSortIcon && id !== 'icon' && id !== 'secondIcon'}
                direction={
                  orderBy === id || orderBy === orderById ? order : 'asc'
                }
                onClick={
                  !hideSortIcon ? createSortHandler(orderById || id) : undefined
                }
                IconComponent={
                  !hideSortIcon ? StyledUnfoldMoreIcon : EmptyComponent
                }
              >
                {icon ? icon() : label}
              </TableSortLabel>
            </TableCell>
          ),
        )}
      </TableRow>
    </TableHead>
  );
}

const EmptyComponent = () => <></>;

const StyledUnfoldMoreIcon = () => <UnfoldMoreIcon color="primary" />;
