import React, { FunctionComponent } from 'react';
import { Grid, Typography, Card, IconButton } from '@material-ui/core';
import Edit from '@material-ui/icons/Edit';
import Call from '@material-ui/icons/Call';
import { makeStyles } from '@material-ui/core/styles';

export interface ResponsableCardProps {
  contactoEmergencia: boolean;
  key: string;
  lazo: string;
  nombre: string;
  onEdit: () => void;
  telefono1: string;
  telefono2?: string;
  isHistoric?: boolean;
}

const useStyles = makeStyles({
  cardContent: {
    padding: '5px 15px',
  },
  cardRetiro: {
    borderRadius: 7,
    borderBottom: '4px solid #00a0d6',
  },
  cardContacto: {
    borderRadius: 7,
    borderBottom: '4px solid #faad14',
  },
  contacto: {
    fontSize: 12,
    color: '#faad14',
    fontWeight: 700,
    marginTop: 11,
  },
  lazo: {
    fontSize: 11,
    color: '#464646',
    marginBottom: 5,
  },
  nombre: {
    fontSize: 24,
    color: '#464646',
  },
  numeros: {
    padding: '10px 0',
  },
  retiro: {
    fontSize: 12,
    color: '#00a0d6',
    fontWeight: 700,
    marginTop: 11,
  },
  telefono: {
    fontSize: 14,
    color: '#8c8c8c',
    marginRight: 15,
  },
  telIcon: {
    color: '#8c8c8c',
    fontSize: 18,
    paddingTop: 3,
    paddingRight: 3,
  },
});

export const ResponsablesCard: FunctionComponent<ResponsableCardProps> = ({
  contactoEmergencia,
  key,
  lazo,
  nombre,
  onEdit,
  telefono1,
  telefono2,
  isHistoric,
}) => {
  const classes = useStyles();
  return (
    <Grid item key={key} xs={4}>
      <Card
        classes={{
          root: contactoEmergencia ? classes.cardContacto : classes.cardRetiro,
        }}
      >
        <Grid className={classes.cardContent} container direction="column">
          <Grid item container direction="row" justify="space-between">
            <Grid item alignItems="baseline">
              <Typography
                className={
                  contactoEmergencia ? classes.contacto : classes.retiro
                }
              >
                {contactoEmergencia ? 'Contacto de Emergencia' : 'Retira'}
              </Typography>
            </Grid>
            <Grid item>
              {!isHistoric && (
                <IconButton aria-label="edit" onClick={onEdit}>
                  <Edit />
                </IconButton>
              )}
            </Grid>
          </Grid>

          <Grid item>
            <Typography
              className={classes.lazo}
            >{`Lazo con el alumno: ${lazo}`}</Typography>
          </Grid>
          <Grid item spacing={4}>
            <Typography className={classes.nombre}>{nombre}</Typography>
          </Grid>

          <Grid
            className={classes.numeros}
            item
            container
            direction="row"
            justify="flex-start"
          >
            <Call className={classes.telIcon} />
            <Typography className={classes.telefono}>{telefono1}</Typography>
            {telefono2 && <Call className={classes.telIcon} />}
            {telefono2 && (
              <Typography className={classes.telefono}>{telefono2}</Typography>
            )}
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
