import { makeBusinessEntity } from '../../../lib/libby/generator';
import { MatriculacionSeccion } from 'src/app/models';
import { MatriculacionSeccionDAO } from 'src/platform/libby/dao';

const DAO_NAME = 'matriculacion_seccion';
const {
  hook: { libbyDAO, libbyFetch },
} = makeBusinessEntity<MatriculacionSeccion, MatriculacionSeccionDAO>(
  DAO_NAME,
  {},
);

// hooks
export const useMatriculacionSeccionDAO = libbyDAO;
export const useMatriculacionSeccionLibbyFetch = libbyFetch;
