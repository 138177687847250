import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { TipoEstadoPresente } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'tipo_estado_presente';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idTipoEstadoPresente',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idTipoEstadoPresente',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.TipoEstadoPresente
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.TipoEstadoPresente,
      hoc: { ...defaultOptions.hoc, ...businessOptions.TipoEstadoPresente.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<TipoEstadoPresente>(DAO_NAME, optionsToUse);

// hooks
export const useTipoEstadoPresenteFetchById = fetchById;
export const useTipoEstadoPresenteLibbyCall = libbyCall;
export const useTipoEstadoPresenteDAO = libbyDAO;
export const useTipoEstadoPresenteLibbyFetch = libbyFetch;

// components/hoc
export const TipoEstadoPresenteAutocomplete = entityAutocomplete;
export const TipoEstadoPresenteCall = renderPropCall;
export const TipoEstadoPresenteById = renderPropFetchById;
export const TipoEstadoPresenteSelect = simpleSelect;
export const TipoEstadoPresenteAccordionSelector = accordionSelector;
export const TipoEstadoPresenteSelectedChips = selectedChips;

// context
// ByIdContext
export const useTipoEstadoPresenteByIdContext = useByIdContext;
export const TipoEstadoPresenteByIdProvider = ByIdProvider;
export const TipoEstadoPresenteByIdContext = ByIdContext;
// GeneralContext
export const useTipoEstadoPresenteContext = useGeneralContext;
export const TipoEstadoPresenteProvider = GeneralProvider;
export const TipoEstadoPresenteContext = GeneralContext;
