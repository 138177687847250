import { Box, Grid, IconButton, makeStyles, Tooltip } from '@material-ui/core';
import { Edit, Visibility, Delete } from '@material-ui/icons';
import React, { useMemo } from 'react';
import { PropuestaJE } from 'src/app/models/presentismo_v2';
import { ROL } from 'src/commons';
import { InfoTable, Loading } from 'src/commons/components';
import customFormDialog from 'src/commons/services/customFormDialog';
import { useRolesContext } from 'src/context/RolesContext';
import { primary } from 'src/theme/colors';
import { usePresentismoContext } from '../../context/Context';
import { presentismo } from '../../types';
import { ConfigurationProvider } from './ConfigurationProvider';
import { Modal } from './PropuestaJE';
import { useGeneralContext } from '../../../../../context';
import { usePresenteV3LibbyFetch } from '../../../../../app/business/presentismo_v3/Presente';

const useStyles = makeStyles(() => ({
  iconButton: {
    color: primary.lightBlue,
  },
  disableButton: {
    color: primary.disabled,
  },

  deleteButton: {
    border: `1px solid grey`,
    borderRadius: 5,
    width: '100%',
    padding: 10,
    margin: '10px 0 20px 0',
    height: 300,
  },
}));

export const PropuestaTable = () => {
  const classes = useStyles();

  const {
    reFetch,
    propuestasJE,
    propuestasWorking: working,
  } = usePresentismoContext();

  const {
    selectedRole: { rol },
  } = useRolesContext();

  const {
    generalState: { cicloLectivo: currentCicloLectivo },
  } = useGeneralContext();

  const filterJe = useMemo(() => {
    return {
      0: [{ path: 'isJornadaExtendida', value: true }],
    };
  }, []);

  const { data: presentismosAllJE = [], working: presentismoJeWorking } =
    usePresenteV3LibbyFetch({
      filter: filterJe,
      aspect: 'basicJE',
      enabled: propuestasJE.length > 0,
      limit: 30000,
    });

  const columns = [
    {
      id: 'seccion.nombreSeccion',
      label: 'Sección',
      render: (seccion: string) => seccion,
      hideSortIcon: true,
    },
    {
      id: 'acciones',
      label: 'Acciones',
      hideSortIcon: true,
      render: (_: undefined, row: PropuestaJE) => {
        const initialValues = row.dias.reduce(
          (prev: presentismo.PropuestaJe, propuestaDia) => {
            prev[propuestaDia.dia.nombre] = {
              aceptado: true,
              dentro: propuestaDia.sede === null,
              sede: propuestaDia.sede,
              idPropuestaJeDia: propuestaDia.idPropuestaJeDia,
            };
            return prev;
          },
          {},
        );

        initialValues.propuesta = row;
        return (
          <Box display="flex" width="10%">
            <Tooltip title="Ver propuesta" arrow>
              <IconButton
                onClick={async () => {
                  await customFormDialog.show({
                    title: `Propuesta Jornada Extendida - ${row.seccion.nombreSeccion}`,
                    renderComponent: (
                      <ConfigurationProvider>
                        <Modal
                          initialValues={initialValues}
                          show={true}
                          currentCicloLectivo={currentCicloLectivo}
                        />
                      </ConfigurationProvider>
                    ),
                    sizeWidth: 'md',
                  });
                }}
              >
                <Visibility className={classes.iconButton} />
              </IconButton>
            </Tooltip>

            {Number(rol) === ROL.COORDINADOR_JE && (
              <Tooltip title="Editar propuesta" arrow>
                <IconButton
                  onClick={async () => {
                    await customFormDialog.show({
                      title: `Propuesta Jornada Extendida - ${row.seccion.nombreSeccion}`,
                      renderComponent: (
                        <ConfigurationProvider>
                          <Modal
                            initialValues={initialValues}
                            currentCicloLectivo={currentCicloLectivo}
                          />
                        </ConfigurationProvider>
                      ),
                      sizeWidth: 'md',
                    });

                    reFetch();
                  }}
                >
                  <Edit className={classes.iconButton} />
                </IconButton>
              </Tooltip>
            )}
            {Number(rol) !== ROL.DIRECTIVO &&
              Number(rol) !== ROL.EQUIPO_CONDUCCION && (
                <Tooltip title="Eliminar propuesta" arrow>
                  <IconButton
                    onClick={async () => {
                      await customFormDialog.show({
                        title: `Eliminar Propuesta - ${row.seccion.nombreSeccion}`,
                        renderComponent: (
                          <ConfigurationProvider>
                            <Modal
                              initialValues={initialValues}
                              show={true}
                              showFooter={true}
                              currentCicloLectivo={currentCicloLectivo}
                              presentismosAllJE={presentismosAllJE?.filter(
                                (s) =>
                                  s.seccion.idSeccion === row.seccion.idSeccion,
                              )}
                            />
                          </ConfigurationProvider>
                        ),
                        sizeWidth: 'md',
                      });
                      reFetch();
                    }}
                    disabled={presentismoJeWorking}
                  >
                    <Delete
                      className={
                        presentismoJeWorking
                          ? classes.disableButton
                          : classes.iconButton
                      }
                    />
                  </IconButton>
                </Tooltip>
              )}
          </Box>
        );
      },
    },
  ];
  return working ? (
    <Loading />
  ) : (
    <Grid container>
      <Grid item xs={8}>
        <InfoTable rows={propuestasJE} columns={columns} />
      </Grid>
    </Grid>
  );
};
