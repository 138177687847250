import { Grid } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { AlumnoMap } from '../TomaPresentismo/types';
import { StudentReportBody, StudentReportTitle } from './components';
import { StudentDetail } from './types';
import { usePresenteV3LibbyFetch } from 'src/app/business/presentismo_v3/Presente';
import { useStudentReportExcelData_v3 } from './hooks';
import useGenerarExcel from 'src/commons/hooks/useGenerarExcel';
import { usePresentismoContext } from '../TomaPresentismo/context';
import {
  usePeriodoNuevoLibbyFetch,
  usePresentismoEspacioCurricularLibbyFetch,
  useRegularidadLibbyFetch,
} from '../../../../app/business';
import { mapPresentesByEc } from '../TomaPresentismo/utils/mapPresentesByEc';
import { NIVEL, TIPO_PERIODO } from 'src/commons';
import moment from 'moment';
import { getPeriodoByDate } from '../TomaPresentismo/components/PresentismoTable/functions/getPeriodoByDate';
import { useColegioEfectivizadoContext } from '../../Calificaciones/context/EfectivizacionProvider';
import { useRolesContext } from 'src/context/RolesContext';
import { PeriodoNuevo } from 'src/app/models';

interface StudentReportProps {
  data: AlumnoMap;
  onBack: () => void;
  listAlumnos: string[];
  selectedEspacioCurricular?: string;
}

export const StudentReport = ({
  data,
  onBack,
  listAlumnos,
  selectedEspacioCurricular,
}: StudentReportProps) => {
  const [currentAlumnoIndex, setCurrentAlumnoIndex] = useState(0);
  const [periodoActual, setperiodoActual] = useState<number>(0);
  const [filterReport, setFilterReport] = useState({
    periodo: 0,
    turno: 0,
    estado: 0,
  });

  const {
    selectedRole: { nivel },
  } = useRolesContext();

  const generateExcel = useGenerarExcel();

  useEffect(() => {
    const idxAlumno = listAlumnos.findIndex((a) => a === data.alumno.idAlumno);
    if (idxAlumno !== -1) {
      setCurrentAlumnoIndex(idxAlumno);
    }
  }, [data.alumno.idAlumno, listAlumnos]);

  const { isJornadaExtendida } = usePresentismoContext();

  const filterPeriodoNuevo = useMemo(
    () => ({
      nivel: [
        {
          path: 'nivel.idNivel',
          value: nivel,
        },
      ],
    }),
    [nivel],
  );

  const { data: periodoNuevo = [] } = usePeriodoNuevoLibbyFetch({
    filter: filterPeriodoNuevo,
  });

  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();

  const filterPresentismos = useMemo(
    () => ({
      alumno: [
        {
          path: 'alumno',
          value: listAlumnos[currentAlumnoIndex],
        },
      ],
      seccion: [
        {
          path: 'seccion',
          value: data?.seccion.idSeccion,
        },
      ],
      jornada: selectedEspacioCurricular
        ? []
        : [
            {
              path: 'isJornadaExtendida',
              value: isJornadaExtendida,
            },
          ],
      espacio: selectedEspacioCurricular
        ? [{ path: 'espacioCurricular', value: selectedEspacioCurricular }]
        : [],
    }),
    [
      currentAlumnoIndex,
      data?.seccion.idSeccion,
      isJornadaExtendida,
      listAlumnos,
      selectedEspacioCurricular,
    ],
  );

  const { data: presentismos = [], working: workingPresentismos } =
    usePresenteV3LibbyFetch({
      filter: filterPresentismos,
      aspect: 'basic',
      limit: 2000,
      enabled:
        !Boolean(selectedEspacioCurricular) &&
        listAlumnos[currentAlumnoIndex] !== undefined,
    });

  const { data: presenteEC = [], working: workingPresenteEC } =
    usePresentismoEspacioCurricularLibbyFetch({
      filter: filterPresentismos,
      aspect: 'basic',
      limit: 2000,
      enabled: Boolean(selectedEspacioCurricular),
    });

  const filterRegularidad = useMemo(
    () => ({
      alumno: [
        {
          path: 'alumno.idAlumno',
          value: data.alumno.idAlumno,
        },
      ],
    }),
    [data.alumno.idAlumno],
  );

  const { data: regularidad = [] } = useRegularidadLibbyFetch({
    filter: filterRegularidad,
    enabled: Boolean(data),
  });
  const presenteGeneral = useMemo(
    () =>
      mapPresentesByEc(
        Boolean(selectedEspacioCurricular),
        presentismos,
        presenteEC,
      ),
    [selectedEspacioCurricular, presentismos, presenteEC],
  );

  const fechaActual = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD');
  const periodosSinReceso = periodoNuevo.filter(
    (p) => p.tipoPeriodo.idTipoPeriodo !== TIPO_PERIODO.RECESO_INVERNAL,
  );
  const periodoPorFechaActual = getPeriodoByDate(
    periodosSinReceso,
    fechaActual,
  );

  const periodosAnteriores = periodosSinReceso.filter((periodo) => {
    const fechaFin = moment(periodo.fechaInicio, 'YYYY-MM-DD');
    return fechaFin.isSameOrBefore(fechaActual);
  });

  const obtenerPeriodoActual = (
    fechaActual: string,
    periodosSinReceso: PeriodoNuevo[],
    establecimientoEfectivizado: string | boolean,
    nivel: number,
  ) => {
    const periodoActual = periodosSinReceso.find((periodo) => {
      const fechaInicio = moment(
        new Date(periodo.fechaInicio),
        'YYYY-MM-DD',
      ).format('YYYY-MM-DD');
      const fechaFin = moment(new Date(periodo.fechaFin), 'YYYY-MM-DD').format(
        'YYYY-MM-DD',
      );
      return fechaActual >= fechaInicio && fechaActual <= fechaFin;
    });

    if (!periodoActual && !establecimientoEfectivizado) {
      let nivelDefaultPeriodoId = 0;

      if (periodosSinReceso.length > 0 && nivel === NIVEL.INICIAL) {
        nivelDefaultPeriodoId = 3;
      } else if (periodosSinReceso.length > 0 && nivel === NIVEL.PRIMARIO) {
        nivelDefaultPeriodoId = 8;
      } else if (periodosSinReceso.length > 0 && nivel === NIVEL.SECUNDARIO) {
        nivelDefaultPeriodoId = 12;
      }

      const periodoDefault = periodosSinReceso.find(
        (periodo) => periodo.idPeriodoNuevo === nivelDefaultPeriodoId,
      );

      return periodoDefault || null;
    }

    return periodoActual || null;
  };

  const periodoActualPorFecha = obtenerPeriodoActual(
    fechaActual,
    periodosSinReceso,
    establecimientoEfectivizado,
    Number(nivel),
  );

  let periodoTitle: string = '-';

  if (periodoActualPorFecha) {
    switch (periodoActualPorFecha.tipoPeriodo.idTipoPeriodo) {
      case 1:
        if (data.seccion.anio.nivel.idNivel === NIVEL.INICIAL) {
          periodoTitle = '1º Cuatrimestre';
        } else {
          periodoTitle = '1º Bimestre';
        }
        break;
      case 2:
        if (data.seccion.anio.nivel.idNivel !== NIVEL.INICIAL) {
          periodoTitle = '2º Bimestre';
        }
        break;
      case 3:
        if (data.seccion.anio.nivel.idNivel === NIVEL.INICIAL) {
          periodoTitle = '2º Cuatrimestre';
        } else {
          periodoTitle = '3º Bimestre';
        }
        break;
      case 4:
        if (data.seccion.anio.nivel.idNivel !== NIVEL.INICIAL) {
          periodoTitle = '4º Bimestre';
        }
        break;
      default:
        periodoTitle = periodoActualPorFecha.description;
    }
  } else {
    periodoTitle = '-';
  }

  const studentDetail = useMemo<StudentDetail[]>(
    () => [
      {
        title: 'Estudiante',
        description: `${data.alumno.persona.nombre} ${data.alumno.persona.apellido}`,
        xs: 3,
        sm: 2,
        md: 2,
      },
      {
        title: 'Año',
        description: data?.seccion.anio.descripcionAnio,
        xs: 2,
        sm: 2,
        md: 2,
      },
      {
        title: 'Sección',
        description: data?.seccion.nombreSeccion,
        xs: 4,
        sm: 3,
        md: 4,
      },
      {
        title: 'Condición',
        description: periodoTitle,
        descriptionSecondLine:
          regularidad[0]?.condiciones?.[
            periodoActualPorFecha?.tipoPeriodo.idTipoPeriodo!
          ]?.condicion?.descripcionCondicion || 'Datos insuficientes',
        xs: 6,
        sm: 6,
        md: 6,
      },
    ],
    [
      data.alumno.persona.apellido,
      data.alumno.persona.nombre,
      data?.seccion.anio.descripcionAnio,
      data?.seccion.nombreSeccion,
      periodoActualPorFecha?.tipoPeriodo.idTipoPeriodo,
      periodoTitle,
      regularidad,
    ],
  );

  const excelTrimestral = useStudentReportExcelData_v3(
    presenteGeneral,
    periodoActual,
  );

  data.presentismo = presenteGeneral;
  return (
    <Grid container>
      <Grid item xs={12} style={{ marginTop: '20px' }}>
        <StudentReportTitle
          studenDetail={studentDetail}
          handleBack={onBack}
          onNextAlu={() => {
            setCurrentAlumnoIndex(
              (prevIndex: number) => (prevIndex + 1) % listAlumnos.length,
            );
          }}
          workingPresentismos={workingPresentismos || workingPresenteEC}
          onClickExcel={() => {
            generateExcel(excelTrimestral.body, excelTrimestral.name);
          }}
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: '20px' }}>
        <StudentReportBody
          data={data}
          presentismos={presenteGeneral}
          setperiodoActual={setperiodoActual}
          workingPresentismos={workingPresentismos || workingPresenteEC}
          selectedEspacioCurricular={selectedEspacioCurricular}
          filterReport={filterReport}
          setFilterReport={setFilterReport}
          regularidad={regularidad}
          periodosAnteriores={periodosAnteriores}
          periodoNuevo={periodoNuevo}
        />
      </Grid>
    </Grid>
  );
};
