import React, { useCallback } from 'react';
import { ObservacionesModal, SelectWithField, SimpleSelect } from 'src/commons';
import { useMemo } from 'react';
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import EditIcon from '@material-ui/icons/Edit';
import customFormDialog from 'src/commons/services/customFormDialog';
import { primary } from 'src/theme/colors';
import {
  Alumno,
  CicloLectivo,
  EstadoAlumno,
  Pps,
  Seccion,
} from 'src/app/models';
import { usePPSFormContext } from '../PPSFormProvider';
import { TextFieldFF } from 'src/commons/components/FinalForm/TextField';
import { FormApi } from 'final-form';

const useStyles = makeStyles({
  adjunto: {
    border: 'none',
    borderRadius: 10,
    padding: 10,
    backgroundColor: 'rgb(240,240,240)',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    width: '100px',
  },
  adjuntoClose: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 7px',
    fontWeight: 'bold',
  },
  disabledInput: {
    backgroundColor: '#DADADA',
  },
  adjuntoNombre: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  iconObservacion: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'end',
    color: primary.lightBlue,
    marginLeft: '20px',
  },
  observaciones: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '200px',
    marginTop: '10px',
    textAlign: 'right',
    color: 'black',
    paddingBottom: 10,
  },
});

interface formValuesType {
  participa: string;
  cual: string;
  observaciones: string;
}

interface informePpsType {
  row: {
    idAlumnoMovimiento: string;
    updateAt: string;
    updatedBy: string;
    deletedAt: Date | null;
    alumno: Alumno;
    cicloLectivo: CicloLectivo;
    estadoAlumno: EstadoAlumno;
    seccion: Seccion;
    deletedBy: string | number | null;
  };
  button: string;
}

export const useJornadaExtendidaColumns = (ppsDataAlumno: Pps) => {
  const { form } = usePPSFormContext();
  const values = (form as FormApi).getState().values;
  const classes = useStyles();
  const handleObservaciones: () => void = useCallback(() => {
    customFormDialog.show({
      title: 'Observaciones',
      renderComponent: (
        <ObservacionesModal
          content={values?.jornada?.observaciones}
          onAddContent={(content: string) =>
            (form as FormApi).change('jornada.observaciones', content)
          }
          value={values?.jornada?.observaciones}
        />
      ),
      sizeWidth: 'sm',
    });
  }, [form, values?.jornada?.observaciones]);

  const columns = useMemo(() => {
    const SiNoOptions = [{ label: 'Sí' }, { label: 'No' }];

    const isOpen = ppsDataAlumno !== undefined ? ppsDataAlumno?.abierto : true;
    const isDisabled = !isOpen;

    return [
      {
        id: 'participa',
        label: '¿Participa del espacio Jornada Extendida?',
        width: '5%',
        hideSortIcon: true,
        render: () => (
          <SelectWithField
            form={form}
            name="jornada.participa"
            placeholder="Seleccioná una opción"
            labelKey="label"
            valueKey="label"
            content={SiNoOptions}
            disabled={isDisabled}
          />
        ),
      },
      {
        id: 'emptyspace',
        label: '',
        width: '5%',
        hideSortIcon: true,
        render: () => <></>,
      },
      {
        id: 'cual',
        label: '¿En qué espacios participó?',
        width: '10%',
        hideSortIcon: true,
        render: () => (
          <TextFieldFF
            form={form}
            name="jornada.cual"
            disabled={isDisabled || values?.jornada?.participa !== 'Sí'}
          />
        ),
      },
      {
        id: 'observaciones',
        label: 'Observaciones',
        width: '10%',
        hideSortIcon: true,
        render: () => (
          <Grid>
            {values?.jornada?.observaciones ? (
              <>
                <Grid className={classes.iconObservacion}>
                  <div className={classes.observaciones}>
                    {values?.jornada?.observaciones}
                  </div>
                  <IconButton
                    className={classes.iconObservacion}
                    disabled={isDisabled}
                    onClick={() => handleObservaciones()}
                  >
                    {<LibraryBooksIcon />}
                  </IconButton>
                </Grid>
              </>
            ) : (
              <Grid className={classes.iconObservacion}>
                <IconButton
                  disabled={isDisabled}
                  onClick={() => handleObservaciones()}
                >
                  {<EditIcon style={{ color: primary.lightBlue }} />}
                </IconButton>
              </Grid>
            )}
          </Grid>
        ),
      },
    ];
  }, [
    ppsDataAlumno,
    form,
    values?.jornada,
    classes.iconObservacion,
    classes.observaciones,
    handleObservaciones,
  ]);

  return columns;
};
