import { useMemo, useRef } from 'react';
import { Template, TemplateProperty } from 'src/lib/templates';
import { Templates } from 'src/app/models';

export type TemplateJoinerConfig = {
  [key: string]: string;
};

export type TemplateJoinerTemplates = {
  [key: string]: Templates | any;
};

export const useTemplateJoiner = (
  templates: TemplateJoinerTemplates,
  config: TemplateJoinerConfig,
  joinedName = 'JoinedTemplates',
): Template | null => {
  const configRef = useRef<TemplateJoinerConfig>(config);

  return useMemo<Template | null>(() => {
    if (!templates || templates.length === 0) {
      return null;
    }

    const join: TemplateProperty[] = Object.entries(configRef.current).map(
      ([name, templateKey]) => ({
        name,
        type: {
          name: 'object',
          // TODO: Mejorar esto
          kind: templates[0][templateKey].template,
        },
      }),
    );

    return { name: joinedName, template: join };
  }, [joinedName, templates]);
};
