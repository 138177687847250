import { Filter, LibbyFetchDAO } from './LibbyFetchDAO';

export class OfertasDAO extends LibbyFetchDAO {
  constructor() {
    super('ofertas', 'idOferta');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  async getTotal(filters: Filter) {
    let query = this.query();
    query = this.applyFilters(query, filters);
    const [response] = await query.aggregate().count('idOferta').run();
    const total = Number(response.countofidoferta || 0);
    return [{ total }];
  }
}
