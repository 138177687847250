import {
  useLibbyFetch,
  LibbyFetchOptions,
  LibbyFetchReturn,
} from './useLibbyFetch';

export type EntityLibbyFetchOptions = Omit<LibbyFetchOptions, 'daoName'>;

export const makeEntityLibbyFetch =
  <T>(daoName: string) =>
  (options?: EntityLibbyFetchOptions): LibbyFetchReturn<T> =>
    useLibbyFetch<T>({
      daoName,
      ...options,
    });
