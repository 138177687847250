export enum FILTERS {
  nivel = 'nivel',
  dependencia_funcional = 'df',
  distrito_escolar = 'distrito',
  localizacion = 'localizacion',
  ciclo = 'ciclo',
  seccion = 'seccion',
  anio = 'año',
  turno = 'turno',
  dni = 'dni',
  motivo = 'motivo',
  edo_pase_anio = 'estado_pase_anio',
  edo_presentismo = 'edo_presentismo',
  materia = 'materia',
  periodo = 'periodo',
  tipo_previa = 'tipo_previa',
  calificacion = 'calificacion',
}

export const TABLE_COLUMNS: { [k: string]: string } = {
  cueanexo: 'CUE',
  cue_anexo: 'CUE',
  establecimiento: 'Establecimiento',
  establecimiento_origen: 'Establecimiento Origen',
  establecimiento_destino: 'Establecimiento Destino',
  localizacion: 'Localización',
  distritoescolar: 'Distrito Escolar',
  distrito_escolar: 'Distrito Escolar',
  distrito_escolar_origen: 'Distrito Escolar Origen',
  distrito_escolar_destino: 'Distrito Escolar Destino',
  apellido: 'Apellido/s',
  nombre: 'Nombre/s',
  tipodocumento: 'Tipo de Documento',
  tipo_documento: 'Tipo de Documento',
  documento: 'Número de documento',
  genero: 'Género',
  username: 'Cuenta @alu',
  año: 'Año',
  año_seccion: 'Año Previa',
  año_deuda: 'Año Sección',
  seccion: 'Sección',
  responsables: 'Cantidad de responsables asociados',
  condicion: 'Condicion',
  turno: 'Turno',
  fecha_desmatriculado: 'Fecha en la que fue desmatriculado',
  motivo_baja: 'Motivo de baja',
  tiene_calificaciones: '¿Calificaciones registradas?',
  cantidad_alumnos: 'Cantidad de estudiantes',
  plan_estudios: 'Plan de estudio de la sección',
  cantidad_espacios: 'Cantidad de materias',
  cant_pase_anio: 'Cantidad por estado de pase de año',
  cant_promociona: 'Cantidad por promociona',
  cant_egresa: 'Cantidad por egresa',
  cant_permanece: 'Cantidad por permanece',
  porc_permanece: 'Porcentaje permanece',
  porc_egresa: 'Porcentaje egresa',
  porc_promociona: 'Porcentaje promociona',
  edo_pase_anio: 'Estado de pase de año',
  estado_pase_año: 'Estado de pase de año',
  seccion_origen: 'Sección actual',
  seccion_destino: 'Sección destino',
  edo_articula: 'Estado articula',
  esc_articula: 'Escuela donde articula',
  sec_articula: 'Sección donde articula',
  presentismo_reg: 'Presentismo registrado',
  prop_ense: 'Propuesta de enseñanza',
  cont_pedag: 'Continuidad pedagogica',
  periodo: 'Período',
  porc_edo_reg: 'Porcentaje de estados registrados',
  ec_calificados: 'Espacios curriculares calificados',
  ec_no_calificados: 'Espacios curriculares no calificados',
  ec_cerrados: 'Espacios curriculares cerrados',
  materia: 'Materia',
  calificacion: 'Calificación',
  tipo_deuda: 'Tipo de previa',
  calf_prev: 'Calificaciónes de previa',
  periodo_previa: 'Período de previa',
  fecha: 'Fecha',
  vinculo: '¿Tiene vínculo pedagogico?',
  nro_matriculados: 'Cantidad de estudiantes matriculados por sección',
  porc_aprobados: 'Porcentaje de aprobados',
  porc_desaprobados: 'Porcentaje de desaprobados',
};
