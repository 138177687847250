import { Typography, Button } from '@material-ui/core';
import React, { useCallback, useEffect } from 'react';
import { useMemo } from 'react';
import { InfoTableColumn, loadingModal } from 'src/commons';
import { usePpsDAO, useRecuperacionesstatePostRequest } from 'src/app/business';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import { useCalificacionesstatePutRequest } from 'src/app/business/businessCustomEndpoints/calificacionesstate';
import { useSnackbar } from 'notistack';
import { BoletinStateRow } from '../types';

export const useEspaciosCurricularesTable = (
  periodo: any,
  reFetch: () => void,
  reFetchModal: () => void,
  item: any,
  ppsReFetch: any,
  pps: any = [],
  reFetchBoletin: () => void,
) => {
  const { request, data = [], working } = useCalificacionesstatePutRequest();
  const {
    data: dataRecuperaciones = [],
    request: recuperacionRequest,
    workingRecuperaciones,
  } = useRecuperacionesstatePostRequest();

  const { enqueueSnackbar } = useSnackbar();
  const ppsDao = usePpsDAO();

  const handleOpenCalificaciones = useCallback(
    (row: BoletinStateRow) => {
      loadingModal.open();

      if (Number(periodo) === 14 || Number(periodo) === 15) {
        const response = recuperacionRequest({
          espacioCurricularSeccion: row.idEspaciosCurricularSeccion,
          abierto: true,
        });
        response.then(async () => {
          await reFetch();
          await reFetchModal();
          loadingModal.close();
          enqueueSnackbar('Se han abierto las calificaciones', {
            variant: 'success',
          });
        });
      } else {
        request({
          idJEMateria: row.idJEMateria,
          idEspacioCurricularSeccion: row.idEspaciosCurricularSeccion,
          idPeriodo: periodo,
          abierto: true,
          asunto: row.descripcion,
          idSeccion: row.idSeccion,
        });
      }
    },
    [
      enqueueSnackbar,
      periodo,
      reFetch,
      reFetchModal,
      recuperacionRequest,
      request,
    ],
  );

  useEffect(() => {
    if (
      working === false &&
      data?.message &&
      (Number(periodo) !== 14 || Number(periodo) !== 15)
    ) {
      reFetch();
      reFetchModal();
      loadingModal.close();
      enqueueSnackbar('Se han abierto las calificaciones', {
        variant: 'success',
      });
    }
  }, [
    data,
    dataRecuperaciones?.length,
    enqueueSnackbar,
    reFetch,
    reFetchModal,
    working,
    workingRecuperaciones,
    periodo,
  ]);

  return useMemo<InfoTableColumn[]>(() => {
    const ppsSwitch = async (state: any, ppsDataAlumno: any) => {
      const response = await ppsDao.save({
        idPPS: ppsDataAlumno?.idPPS,
        abierto: state,
      });
      if (response.existError) {
        enqueueSnackbar('Ha ocurrido un error', { variant: 'error' });
      } else {
        enqueueSnackbar('Se ha guardado con éxito', { variant: 'success' });
      }
      reFetchBoletin();
      ppsReFetch();
    };

    const columns = item?.isPps
      ? [
          {
            id: 'alumno',
            label: 'Alumno',
            width: '15%',
            orderById: 'persona.apellido',
            hideSortIcon: true,
            render: (value, row) => {
              return (
                <Typography>
                  {row?.alumno?.persona?.apellido},{' '}
                  {row?.alumno?.persona?.nombre}
                </Typography>
              );
            },
          },
          {
            id: 'cierreInformes',
            label: 'Cierre de informes',
            width: '15%',
            hideSortIcon: true,
            render: (value, row) => {
              const ppsAlumno = pps.find(
                (element: any) =>
                  Number(element?.alumno?.idAlumno) ===
                  Number(row?.alumno?.idAlumno),
              );

              if (ppsAlumno?.abierto === true) {
                return <CloseIcon style={{ fontSize: 30 }} />;
              } else if (ppsAlumno?.abierto === false) {
                return <CheckIcon style={{ fontSize: 30 }} />;
              } else if (ppsAlumno?.abierto === undefined) {
                return <p>PPS Pendiente</p>;
              }
            },
          },
          {
            id: 'acciones',
            label: 'Acciones',
            width: '15%',
            hideSortIcon: true,
            render: (value, row) => {
              const ppsAlumno = pps.find(
                (element: any) =>
                  Number(element?.alumno?.idAlumno) ===
                  Number(row?.alumno?.idAlumno),
              );
              if (row.abierto === 'true' || row.abierto === 'undefined') {
                return <></>;
              } else if (ppsAlumno?.abierto === false) {
                return (
                  <Button
                    onClick={() => ppsSwitch(true, ppsAlumno)}
                    variant="outlined"
                    style={{
                      textTransform: 'capitalize',
                    }}
                  >
                    Abrir Informe
                  </Button>
                );
              }
            },
          },
        ]
      : [
          {
            id: 'espacioCurricular',
            label: 'Espacio curricular',
            width: '15%',
            orderById: 'nombreSeccion',
            hideSortIcon: true,
            render: (value, row: BoletinStateRow) => {
              return <Typography>{row.descripcion}</Typography>;
            },
          },
          {
            id: 'cierreCalificaciones',
            label: 'Cierre de calificaciones',
            width: '15%',
            hideSortIcon: true,
            render: (value, row: BoletinStateRow) => {
              if (row.abierto === 'true') {
                return <CloseIcon style={{ fontSize: 30 }} />;
              } else if (row.abierto === 'false') {
                return <CheckIcon style={{ fontSize: 30 }} />;
              } else if (row.abierto === 'undefined') {
                return <p>Sin calificar</p>;
              }
            },
          },
          {
            id: 'acciones',
            label: 'Acciones',
            width: '15%',
            hideSortIcon: true,
            render: (value, row: BoletinStateRow) => {
              if (row.abierto === 'true' || row.abierto === 'undefined') {
                return <></>;
              } else if (row.abierto === 'false') {
                return (
                  <Button
                    onClick={() => handleOpenCalificaciones(row)}
                    variant="outlined"
                    style={{
                      textTransform: 'capitalize',
                    }}
                  >
                    Abrir Calificaciones
                  </Button>
                );
              }
            },
          },
        ];

    return columns;
  }, [
    enqueueSnackbar,
    handleOpenCalificaciones,
    item?.isPps,
    pps,
    ppsDao,
    ppsReFetch,
    reFetchBoletin,
  ]);
};
