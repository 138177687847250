import { LibbyFetchDAO } from './LibbyFetchDAO';

export class CargoDAO extends LibbyFetchDAO {
  constructor() {
    super('cargo', 'idCargo');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
