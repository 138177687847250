import React, { useMemo, useState, useEffect } from 'react';
import { useUsuarioRolTraverseActionLibbyCall } from 'src/app/business';
import { AnyObject } from 'src/commons/types';
import { UsuarioRolTraverseAction } from 'src/app/models';
import { useFormContext } from '../../../../../../../lib/templates';
import { ButtonTypesProps } from '../../../../../Footer';
import { DireccionAreaTable } from '../DireccionAreaItemTable';
import { TRAVERSE_ACTIONS } from '../../../../../../const';

export interface DireccionAreaItemFormProps {
  roleInformation: AnyObject;
}

export const DireccionAreaItemForm = ({
  roleInformation,
}: DireccionAreaItemFormProps) => {
  const { form, errors, handleSubmit, submitting } = useFormContext();
  const [valueTravers, setValueTravers] = useState<
    Array<UsuarioRolTraverseAction>
  >([]);

  const submitProps: ButtonTypesProps = {
    title: 'Ingresar',
    handleOnClick: handleSubmit,
    disabled: Object.keys(errors).length !== 0 || submitting,
  };
  const filterSupervisor = useMemo(
    () => ({
      0: [
        {
          path: 'usuarioRolEstablecimiento',
          value: roleInformation.idRolEstablecimiento,
        },
      ],
      1: [
        {
          path: 'traverseAction',
          value: TRAVERSE_ACTIONS.LOCALIZACION,
        },
        {
          path: 'traverseAction',
          value: TRAVERSE_ACTIONS.DISTRITO_ESCOLAR,
        },
      ],
    }),
    [roleInformation],
  );
  const { data: rolTraverseActions = [], working: traverseWorking } =
    useUsuarioRolTraverseActionLibbyCall<UsuarioRolTraverseAction[]>({
      methodName: 'getByUsuarioRolEstablecimiento',
      aspect: 'limit_province',
      params: [filterSupervisor],
    });

  useEffect(() => {
    if (rolTraverseActions.length) {
      setValueTravers(rolTraverseActions);
    }
  }, [rolTraverseActions]);

  return !traverseWorking ? (
    <DireccionAreaTable
      valueTravers={valueTravers}
      roleInformation={roleInformation}
      form={form}
      submitProps={submitProps}
    />
  ) : (
    <></>
  );
};
