import {
  Box,
  Grid,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from '@material-ui/core';
import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import {
  MotivoMessage,
  MOTIVOS_DISABLED,
  TooltipMotivoDisabledProps,
} from '../../../types';
import { useToolTipsDisableStyles } from '../css';

export const EXCEPTUADOS_MOTIVOS: { [k: number]: MOTIVOS_DISABLED } = {
  1: 'ESTUDIANTE_FEDERADO',
  2: 'REGIMEN_ESPECIAL',
};

const getMotivos = (motivos: MOTIVOS_DISABLED[], detail: string = '') => {
  const _motivos: MotivoMessage[] = [];

  for (const motivo of motivos) {
    switch (motivo) {
      case 'FUTURE_DATE':
        _motivos.push({ text: 'Fecha futura', detail });
        break;
      case 'DISABLE_DAY':
        _motivos.push({ text: 'Día no habilitado', detail });
        break;
      case 'FERIADO':
        _motivos.push({ text: 'Día feriado', detail });
        break;
      case 'LOWER_DEAD_LINE':
        _motivos.push({ text: 'Fecha límite', detail });
        break;
      case 'UNAUTHORIZED_ROLE':
        _motivos.push({ text: 'Rol no Autorizado', detail });
        break;
      case 'EMERGENCY':
        _motivos.push({ text: 'Situación de Emergencia', detail });
        break;
      case 'SUSPENSION_CLASSES':
        _motivos.push({ text: 'Situación de Suspensión de Clases', detail });
        break;
      case 'PROPUESTA_JE':
        _motivos.push({ text: 'No existe propuesta JE', detail });
        break;
      case 'REGIMEN_ESPECIAL':
        _motivos.push({ text: 'Ley N° 6.347/Régimen especial', detail });
        break;
      case 'ESTUDIANTE_FEDERADO':
        _motivos.push({ text: 'Estudiante Federado/Ley 5.823', detail });
        break;
      case 'BLOQUEO_CANDADO':
        _motivos.push({ text: 'Día bloqueado', detail });
        break;
      case 'SOLICITUD_PASE':
        _motivos.push({
          text: 'El estudiante estaba matriculado en otro establecimiento',
          detail,
        });
        break;
      case 'CAMBIO_DE_SECCION':
        _motivos.push({
          text: 'El estudiante estaba matriculado en otra sección',
          detail,
        });
        break;
      case 'CANDADO_BLOQUEADO':
        _motivos.push({ text: 'Día cerrado', detail });
        break;
      case 'FECHA_INVALIDA':
        _motivos.push({ text: 'Fecha invalida', detail });
        break;
      case 'RECESO_INVERNAL':
        _motivos.push({ text: 'Receso invernal', detail });
        break;
      case 'ENROLLED':
        _motivos.push({ text: 'El estudiante no estaba matriculado', detail });
        break;

      default:
        break;
    }
  }
  if (_motivos.length === 0) {
    _motivos.push({ text: 'Día no habilitado', detail });
  }
  return _motivos;
};

export const TooltipMotivoDisabled = ({
  motivos = [],
}: TooltipMotivoDisabledProps) => {
  const classes = useToolTipsDisableStyles();
  return (
    <Grid container direction="column">
      <Grid
        item
        component={Box}
        padding={1}
        fontSize={13}
        fontWeight="bold"
        fontFamily="Nunito,Open Sans,sans-serif"
      >
        No es posible tomar presentismo:
      </Grid>
      <Grid item component={Box}>
        {getMotivos(motivos).map((motivo) => (
          <ListItem key={motivo.text} className={classes.item}>
            <ListItemIcon className={classes.listIcon}>
              <FiberManualRecordIcon
                className={classes.icon}
                fontSize="small"
              />
            </ListItemIcon>
            <ListItemText
              primary={
                <Typography
                  component={Box}
                  fontSize={12}
                  lineHeight={1}
                  fontWeight="bold"
                >
                  {motivo.text}
                </Typography>
              }
              secondary={
                <Typography component={Box} fontSize={10}>
                  {motivo.detail}
                </Typography>
              }
            />
          </ListItem>
        ))}
      </Grid>
    </Grid>
  );
};
