import { Escalafon, ModalidadType, Region } from 'src/app/models';

export interface IGroups {
  id: string; // id usuario_rol_establecimiento
  name: string; // name rol
  nivel: {
    idNivel: number;
    descripcionNivel: string;
  };
  localizacion: {
    id: string;
    name: string;
    isFuture: string;
  };
  rol: {
    id: string;
    name: string;
  };
  modalidad?: ModalidadType;
  tipoEstablecimiento?: string;
  region?: Region;
  escalafon?: Escalafon;
}
export interface IMiEscuelaUser {
  id: string;
  username: string;
  domain: string;
  groupSelected?: IGroups;
  groups: Array<IGroups>;
  token: string;
}
export interface ResponseLogin {
  user: IMiEscuelaUser;
  isGuest: boolean;
}

class SessionChangeEmitterRaw {
  // eslint-disable-next-line @typescript-eslint/ban-types
  subscriptions: Function[];

  constructor() {
    this.subscriptions = [];
  }

  subscribe(
    fn: (params: { isLogged: boolean; user: IMiEscuelaUser }) => any,
  ): () => void {
    this.subscriptions.push(fn);

    return () => this.unsubscribe(fn);
  }

  unsubscribe(fn: (params: any) => any): void {
    this.subscriptions = this.subscriptions.filter((item) => item !== fn);
  }

  emitChange<F extends any[]>(...args: F): void {
    this.subscriptions.forEach((func) => func(...args));
  }
}

export const SessionChangeEmitter = new SessionChangeEmitterRaw();
