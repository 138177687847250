import { makeStyles } from '@material-ui/core';

export const primarioPrimerBimestreStyles = makeStyles({
  adjunto: {
    border: 'none',
    borderRadius: 10,
    padding: 10,
    backgroundColor: 'rgb(240,240,240)',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    width: '100px',
  },
  adjuntoClose: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 7px',
    fontWeight: 'bold',
  },
  disabledInput: {
    backgroundColor: '#DADADA',
  },
  adjuntoNombre: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
});
