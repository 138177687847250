import React, { useMemo, useState } from 'react';
import moment from 'moment';
import { Box } from '@material-ui/core';
import { useHistory, useRouteMatch } from 'react-router';
import { InfoTableColumn } from '../../../../../../commons/types';
import { Cuentas } from '../../../../../../app/models';
import {
  OptionsModal,
  OptionsModalOption,
} from '../../../../../../commons/components/OptionsModal';
import { BLOCKER_ID } from '../../../../../../platform/permission/const';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useCuentasDAO } from 'src/app/business';
import { useSnackbar } from 'notistack';

export const useUsuariosListColumns = (
  reFetch: () => void,
  setLoading: React.Dispatch<React.SetStateAction<boolean>>,
) => {
  const { url } = useRouteMatch();
  const history = useHistory();
  const usuariosDAO = useCuentasDAO();
  const { enqueueSnackbar } = useSnackbar();
  const actionButtons = useMemo<OptionsModalOption<Cuentas>[]>(
    () => [
      {
        id: BLOCKER_ID.CONFIGURATION_ADMIN_USUARIOS_BOTON_ROLES,
        label: 'Ver Roles',
        onClick: (data: Cuentas) =>
          history.push(`${url}/roles/${data.idUsuario}`),
      },
      {
        id: BLOCKER_ID.CONFIGURATION_ADMIN_USUARIOS_ELIMINAR_USUARIO,
        label: 'Dar de baja',
        onClick: async (usuario: Cuentas) => {
          const intentionToDelete = await confirmDialog.show({
            title: '¿Está seguro de dar de baja al usuario?',
            content: `Está cerca de eliminar a ${usuario.username}`,
            confirmText: 'Si, eliminar',
            cancelText: 'No, cancelar',
          });

          if (intentionToDelete) {
            setLoading(true);
            try {
              await usuariosDAO.remove({
                idUsuario: usuario.idUsuario,
              });
              enqueueSnackbar('Usuario eliminado exitosamente', {
                variant: 'success',
              });
            } catch (error) {
              enqueueSnackbar('Ocurrió un error.', {
                variant: 'error',
              });
            } finally {
              setLoading(false);
              reFetch();
            }
          }
        },
      },
    ],
    [enqueueSnackbar, history, reFetch, setLoading, url, usuariosDAO],
  );
  return useMemo<InfoTableColumn<Cuentas>[]>(
    () => [
      {
        id: 'username',
        label: 'Usuario',
        orderById: 'username',
      },
      {
        id: 'nombreUsuario',
        label: 'Nombre',
        orderById: 'nombreUsuario',
      },
      {
        id: 'apellidoUsuario',
        label: 'Apellido',
        orderById: 'apellidoUsuario',
      },
      {
        id: 'createdAt',
        label: 'Fecha de Creación',
        orderById: 'createdAt',
        render: (fecha) => moment(fecha).format('DD/MM/YYYY'),
      },
      {
        id: 'icono',
        label: '',
        width: '5%',
        hideSortIcon: true,
        style: { textAlign: 'right' },
        render: (_: unknown, row: any) => (
          <Box whiteSpace="nowrap" textAlign="right">
            <OptionsModal options={actionButtons} data={row} />
          </Box>
        ),
      },
    ],
    [actionButtons],
  );
};
