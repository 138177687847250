import React, { useState, useCallback, useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { LibbyObject } from 'src/commons';
import { grey } from 'src/theme/colors';
import { useLibbyFetch } from 'src/commons/hooks';
import { SimpleSelect, MainInput, Footer } from 'src/commons/components';
import customFormDialog from 'src/commons/services/customFormDialog';
import { useInscripcionAlumnoCustomDAO } from 'src/app/business';

interface EnrolledAlumnModalProps {
  libby: LibbyObject;
  alumn: number;
  badgeInscriptosReFetch: () => void;
  // eslint-disable-next-line
  reFetch: Function;
}

const useEnrolledAlumnModalStyles = makeStyles(() => ({
  text: {
    color: grey.medium,
    paddingBottom: 8,
  },
}));

export const EnrolledAlumnModalRaw = ({
  libby,
  alumn,
  reFetch,
  badgeInscriptosReFetch,
}: EnrolledAlumnModalProps) => {
  const classes = useEnrolledAlumnModalStyles();
  const inscriocionAlumnoCustonDAO = useInscripcionAlumnoCustomDAO();
  const [idMotivo, setIdMotivo] = useState<any>('');
  const [descripcionMotivo, setDescripcionMotivo] = useState<any>('');

  const paramsFetchMotivos = useMemo(
    () => ({
      daoName: 'baja_motivo',
      filter: {
        1: [{ path: 'tipo', value: 'Inscripcion' }],
      },
    }),
    [],
  );

  const { data: motivosDeBaja, working } = useLibbyFetch(
    libby,
    paramsFetchMotivos,
  );
  const actionsRemove = useCallback(
    async (
      idInscripcionAlumno: number,
      motivo: string,
      descripcion: string,
    ) => {
      customFormDialog.handleCancel();
      try {
        await inscriocionAlumnoCustonDAO.desmatricular({
          idInscripcionAlumno,
          isDeleted: true,
          bajaMotivo: motivo,
          descripcionBajaMotivo: descripcion,
        });
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
      }
      reFetch();
      badgeInscriptosReFetch();
    },
    [reFetch, badgeInscriptosReFetch, inscriocionAlumnoCustonDAO],
  );

  const buttonConfig = [
    {
      title: 'Cancelar',
      type: 'secondary',
      handleOnClick: () => customFormDialog.handleCancel(),
    },
    {
      title: 'Aceptar',
      handleOnClick: () => actionsRemove(alumn, idMotivo, descripcionMotivo),
      disabled: !idMotivo || !descripcionMotivo,
    },
  ];
  return (
    <>
      <Grid container style={{ paddingBottom: '10px' }}>
        <Grid container style={{ padding: '24px' }}>
          <Grid item xs={12} style={{ paddingBottom: '20px' }}>
            {!working && (
              <SimpleSelect
                value={idMotivo}
                handleChange={(e) => setIdMotivo(e.target.value)}
                content={motivosDeBaja}
                title="Seleccioná el motivo de baja"
                placeholder="Seleccioná el motivo de baja"
                labelKey="descripcion"
                valueKey="idMotivo"
              />
            )}
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.text}>
              Descripción de baja
            </Typography>
            <MainInput
              fullWidth
              handleChange={(e) => setDescripcionMotivo(e.target.value)}
              value={descripcionMotivo}
              placeholder="    Describí­ el motivo de baja"
            />
          </Grid>
        </Grid>
        <Footer buttonConfig={buttonConfig} />
      </Grid>
    </>
  );
};

export const EnrolledAlumnModal = DatabaseConnector(EnrolledAlumnModalRaw)(
  'inscripcion_alumno',
  'baja_motivo',
);
