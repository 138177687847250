import { AulasVirtuales } from 'src/screens/Private/AulasVirtuales/AulasVirtuales';
import { MenuItem } from '../../../../commons/const/ui/menu';

export const routes: MenuItem[] = [
  {
    key: 'aulasVirtualesMain',
    basePath: '/',
    target: {
      component: AulasVirtuales,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'AulasVirtuales',
    children: [],
    default: true,
  },
];
