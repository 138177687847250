import { useEffect, useMemo, useState } from 'react';
import { useLocalizacionLibbyFetch } from '../../../../../../../app/business';
import { NIVEL } from '../../../../../../../commons';
import { usePresentismoContext } from '../../../context';
import { useRolesContext } from '../../../../../../../context/RolesContext';
import { useValidFilterAnio } from '../../../hooks/useValidFilterAnio';

type QueryFilter = { path: string; value: string | number; method?: string };

type AnioFilter = {
  nivel: QueryFilter[];
  anio?: QueryFilter[];
};

export const useAnioFilter = (idNivel: string, localizacionId: string) => {
  const [anioFilter, setAnioFilter] = useState<AnioFilter>({
    nivel: [{ path: 'nivel.idNivel', value: idNivel }],
  });
  const { isJornadaExtendida } = usePresentismoContext();

  const { anio: validAnio, working } = useValidFilterAnio();

  const filterLocalizacion = useMemo(
    () => ({
      0: [{ path: 'idLocalizacion', value: localizacionId }],
    }),
    [localizacionId],
  );
  const { data: dFuncional = [], working: dbWorking } =
    useLocalizacionLibbyFetch({
      limit: 1,
      filter: filterLocalizacion,
      aspect: 'dependencia_funcional',
    });
  const esTecnicaSecundaria = useMemo(
    () =>
      dFuncional?.[0]?.establecimiento?.dependenciaFuncional
        ?.idDependenciaFuncional === 32 && Number(idNivel) === NIVEL.SECUNDARIO,
    [dFuncional, idNivel],
  );

  useEffect(() => {
    if (
      !esTecnicaSecundaria &&
      !anioFilter.anio &&
      dFuncional?.length > 0 &&
      validAnio.length > 0
    ) {
      setAnioFilter({
        ...anioFilter,
        anio:
          isJornadaExtendida && idNivel.toString() === '3'
            ? [{ path: 'idAnio', value: 21 }]
            : [...validAnio],
      });
    }
  }, [
    esTecnicaSecundaria,
    anioFilter,
    dFuncional,
    validAnio,
    isJornadaExtendida,
    idNivel,
  ]);

  return [anioFilter, setAnioFilter];
};
