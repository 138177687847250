import { Box } from '@material-ui/core';
import React, { useMemo, useCallback } from 'react';
import { useRouteMatch } from 'react-router';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import confirmDialog from 'src/commons/services/confirmDialog';
import {
  useEspaciosSimpDiscipPlanDAO,
  useSimpDiscipPlanDAO,
} from 'src/app/business';
import {
  EspaciosPlanificacion,
  EspaciosInterdiscipPlan,
} from '../../../../../../../app/models';
import { OptionsModal } from '../../../../../../../commons/components';
import { makeActionButtons } from './makeActionButtons';

const useActionButtons = makeActionButtons(
  'idEspaciosSimpDiscipPlan',
  (row: EspaciosPlanificacion) => row.simpDiscipPlan.planificacionEstados,
  'simpDiscipPlan',
);

const useActionButtonsInterDisciplinar = makeActionButtons(
  'idEspaciosInterdiscipPlan',
  (row: EspaciosInterdiscipPlan) => row.interdiscipPlan.planificacionEstados,
  'interdiscipPlan',
);

export const useDisciplinarInterDisciplinarTableColumns = (
  reFetch: () => void,
) => {
  const { url } = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();

  const espaciosSimpDiscipPlan = useEspaciosSimpDiscipPlanDAO();
  const simpDiscipPlanDAO = useSimpDiscipPlanDAO();

  const handleDeletePlanificacionSimp = useCallback(
    async (planificacion) => {
      try {
        const confirm = await confirmDialog.show({
          title: 'Confirmación',
          content: `¿Esta seguro que desea eliminar la planificacion?`,
          confirmText: 'Eliminar',
          cancelText: 'No',
        });
        if (confirm) {
          await espaciosSimpDiscipPlan.remove({
            idEspaciosSimpDiscipPlan: planificacion.idEspaciosSimpDiscipPlan,
          });

          await simpDiscipPlanDAO.remove({
            idSimpDiscipPlan: planificacion.simpDiscipPlan.idSimpDiscipPlan,
          });
          enqueueSnackbar('Se ha eliminado con éxito', { variant: 'success' });

          reFetch();
        }
      } catch (e) {
        enqueueSnackbar('Ha ocurrido un error al intentar eliminar', {
          variant: 'error',
        });
      }
    },
    [espaciosSimpDiscipPlan, simpDiscipPlanDAO, reFetch, enqueueSnackbar],
  );

  const actionButtons = useActionButtons({
    urls: {
      edit: `${url}/planificacion/disciplinar`,
      feedback: `${url}/planificacionfeedback/disciplinar`,
      preview: `${url}/planificacionpreview/disciplinar`,
    },
    helper: {
      delete: handleDeletePlanificacionSimp,
    },
  });

  const actionButtonsInterDisciplinar = useActionButtonsInterDisciplinar({
    urls: {
      edit: `${url}/planificacion/interDisciplinar`,
      feedback: `${url}/planificacionfeedback/interDisciplinar`,
      preview: `${url}/planificacionpreview/interDisciplinar`,
    },
    helper: {
      delete: () => console.log('TODO: eliminar'),
    },
  });

  return useMemo(
    () => [
      {
        id: 'espacioCurricular.Area',
        label: 'Áreas/orientación',
        width: '15%',
        orderById: {
          disciplinar: 'espacioCurricular.materia.area.descripcion',
          interdisciplinar:
            'interdiscipPlan.interdiscipOrientaPlans.orientacion.descripcionOrientacion',
        },
        render: (value: any, row: EspaciosPlanificacion) => (
          <>
            {row.simpDiscipPlan
              ? row.espacioCurricular.materia.area.descripcion
              : row?.interdiscipPlan?.interdiscipOrientaPlans
                  .map((item) => item.orientacion.descripcionOrientacion)
                  .join(', ')}
          </>
        ),
      },
      {
        id: 'espacioCurricular.PlanEstudio',
        label: 'Proyecto',
        width: '15%',
        orderById: {
          disciplinar: 'espacioCurricular.materia.descripcion',
          interdisciplinar:
            'espacioCurricular.planEstudio.descripcionPlanEstudio',
        },
        render: (value: any, row: EspaciosPlanificacion) => (
          <Box whiteSpace="nowrap">
            {row.simpDiscipPlan
              ? row.espacioCurricular.materia.descripcion
              : row.interdiscipPlan.proyecto.nombre}
          </Box>
        ),
      },
      {
        id: 'tipo',
        label: 'Tipo',
        width: '13%',
        style: {
          whiteSpace: 'nowrap',
        },
        hideSortIcon: true,
        render: (value: any, row: EspaciosPlanificacion) =>
          row.simpDiscipPlan ? 'Disciplinar' : 'Interdisciplinar',
      },
      {
        id: 'simpDiscipPlan.ultimaActualizacion',
        label: 'Ultima modificación',
        width: '10%',
        orderById: {
          disciplinar: 'simpDiscipPlan.ultimaActualizacion',
          interdisciplinar: 'interdiscipPlan.ultimaActualizacion',
        },
        render: (value: any, row: EspaciosPlanificacion) => (
          <Box whiteSpace="nowrap">
            {row.simpDiscipPlan
              ? row.simpDiscipPlan.ultimaActualizacion
                ? moment(row.simpDiscipPlan.ultimaActualizacion).format(
                    'DD/MM/YYYY - HH:mm',
                  )
                : '-'
              : row.interdiscipPlan.ultimaActualizacion
              ? moment(row.interdiscipPlan.ultimaActualizacion).format(
                  'DD/MM/YYYY - HH:mm',
                )
              : '-'}
          </Box>
        ),
      },
      {
        id: 'estado',
        label: 'Estado',
        width: '10%',
        style: { textAlign: 'center' },
        orderById: {
          disciplinar: 'simpDiscipPlan.planificacionEstados.nombre',
          interdisciplinar: 'interdiscipPlan.planificacionEstados.nombre',
        },
        render: (value: any, row: EspaciosPlanificacion) => {
          return (
            <Box whiteSpace="nowrap" textAlign="center">
              {row.simpDiscipPlan
                ? row.simpDiscipPlan.planificacionEstados.nombre ?? '-'
                : row.interdiscipPlan.planificacionEstados.nombre ?? '-'}
            </Box>
          );
        },
      },
      {
        id: 'nombre',
        label: 'Creado Por',
        width: '10%',
        style: { textAlign: 'center' },
        orderById: {
          disciplinar: 'simpDiscipPlan.cuentas.nombreUsuario',
          interdisciplinar: 'interdiscipPlan.cuentas.nombreUsuario',
        },
        render: (value: any, row: EspaciosPlanificacion) => {
          return (
            <Box whiteSpace="nowrap" textAlign="center">
              {row.simpDiscipPlan
                ? `${row.simpDiscipPlan.cuentas.nombreUsuario} ${row.simpDiscipPlan.cuentas.apellidoUsuario}` ??
                  '-'
                : `${row.interdiscipPlan.cuentas.nombreUsuario} ${row.interdiscipPlan.cuentas.apellidoUsuario}` ??
                  '-'}
            </Box>
          );
        },
      },
      {
        id: 'icono',
        label: '',
        width: '5%',
        style: { textAlign: 'right' },
        hideSortIcon: true,
        render: (value: any, row: EspaciosPlanificacion) => (
          <Box whiteSpace="nowrap" textAlign="right">
            <OptionsModal
              options={
                row.simpDiscipPlan
                  ? actionButtons
                  : actionButtonsInterDisciplinar
              }
              data={row}
            />
          </Box>
        ),
      },
    ],
    [actionButtons, actionButtonsInterDisciplinar],
  );
};
