import { PartialBusinessEntityOptions } from '../../../lib/libby/generator';

export const businessOptions: {
  [k: string]: PartialBusinessEntityOptions<any>;
} = {
  Anio: {
    hoc: {
      withSimpleSelect: {
        labelKey: 'descripcionAnio',
        valueKey: 'idAnio',
        title: 'Año',
        placeholder: 'Seleccioná Año',
        name: 'idAnio',
      },
      withAccordionSelector: {
        idKey: 'idAnio',
        labelKey: 'descripcionAnio',
      },
      withSelectedChips: {
        idKey: 'idAnio',
        labelKey: 'descripcionAnio',
      },
    },
  },
  Area: {
    hoc: {
      withSimpleSelect: {
        labelKey: 'descripcion',
        valueKey: 'idArea',
        title: 'Area',
        placeholder: 'Seleccioná Area',
        name: 'idArea',
      },
    },
  },
  Disciplina: {
    hoc: {
      withSimpleSelect: {
        labelKey: 'descripcionTipoDisciplina',
        valueKey: 'idTipoDisciplina',
        title: 'Disciplina',
        placeholder: 'Seleccioná Disciplina',
        name: 'idTipoDisciplina',
      },
    },
  },
  Orientacion: {
    hoc: {
      withSimpleSelect: {
        labelKey: 'descripcionOrientacion',
        valueKey: 'idOrientacion',
        title: 'Orientacion',
        placeholder: 'Seleccioná Orientacion',
        name: 'idOrientacion',
      },
      withAccordionSelector: {
        idKey: 'idOrientacion',
        labelKey: 'descripcionOrientacion',
      },
    },
  },
  Seccion: {
    hoc: {
      withSimpleSelect: {
        labelKey: 'nombreSeccion',
        valueKey: 'idSeccion',
        title: 'Sección',
        placeholder: 'Seleccioná Sección',
        name: 'idSeccion',
      },
    },
  },
  Ciclo: {
    hoc: {
      withAccordionSelector: {
        idKey: 'idCiclo',
        labelKey: 'descripcionCiclo',
      },
      withSelectedChips: {
        idKey: 'idCiclo',
        labelKey: 'descripcionCiclo',
      },
      withSimpleSelect: {
        valueKey: 'idCiclo',
        labelKey: 'descripcionCiclo',
        title: 'Ciclo',
        placeholder: 'Seleccioná ciclo',
        name: 'idCiclo',
      },
    },
  },
  Nivel: {
    hoc: {
      withEntityAutocomplete: {
        optionLabelKey: 'descripcionNivel',
      },
      withSimpleSelect: {
        labelKey: 'descripcionNivel',
        name: 'nivel',
        placeholder: 'Seleccioná Nivel',
        title: 'Nivel',
        valueKey: 'idNivel',
      },
    },
  },
  RepositorioTipoItem: {
    hoc: {
      withSimpleSelect: {
        valueKey: 'idRepositorioTipoItem',
        labelKey: 'descripcion',
        title: 'Tipo',
        placeholder: 'Seleccioná Tipo',
        name: 'RepositorioTipoItem',
      },
    },
  },
  EspacioCurricular: {
    hoc: {
      withSimpleSelect: {
        valueKey: 'idEspacioCurricular',
        labelKey: 'descripcion',
        title: 'Espacio Curricular',
        placeholder: 'Seleccioná espacio curricular',
        name: 'idEspacioCurricular',
      },
      withEntityAutocomplete: {
        optionLabelKey: 'descripcion',
      },
      withSelectedChips: {
        idKey: 'idEspacioCurricular',
        labelKey: 'descripcion',
      },
    },
  },
  EspacioCurricularSeccion: {
    hoc: {
      withSimpleSelect: {
        labelKey: 'seccion.nombreSeccion',
        valueKey: 'seccion.idSeccion',
        title: 'Sección',
        placeholder: 'Seleccioná sección',
        name: 'idEspacioCurricularSeccion',
      },
    },
  },
  RepositorioAudienciaItem: {
    hoc: {
      withSimpleSelect: {
        valueKey: 'idRepositorioAudienciaItem',
        labelKey: 'descripcion',
        title: 'Audiencia',
        placeholder: 'Seleccioná audiencia',
        name: 'idRepositorioAudienciaItem',
      },
    },
  },
  RepositorioTipoRecurso: {
    hoc: {
      withSimpleSelect: {
        valueKey: 'idTipoRecurso',
        labelKey: 'descripcion',
        title: 'Tipo recurso',
        placeholder: 'Seleccioná tipo de recurso',
        name: 'idTipoRecurso',
      },
    },
  },
  RepositorioAreas: {
    hoc: {
      withSimpleSelect: {
        valueKey: 'idArea',
        labelKey: 'descripcion',
        title: 'Area',
        placeholder: 'Seleccioná area',
        name: 'idArea',
      },
    },
  },
  RepositorioEspaciosCurriculares: {
    hoc: {
      withSimpleSelect: {
        valueKey: 'idEspacioCurricular',
        labelKey: 'descripcion',
        title: 'Espacio Curricular',
        placeholder: 'Seleccioná espacio curricular',
        name: 'idEspacioCurricular',
      },
    },
  },
  RepositorioCiclo: {
    hoc: {
      withSimpleSelect: {
        valueKey: 'idCiclo',
        labelKey: 'descripcion',
        title: 'Ciclo',
        placeholder: 'Seleccioná ciclo',
        name: 'idCiclo',
      },
    },
  },
  RepositorioTransversales: {
    hoc: {
      withSimpleSelect: {
        valueKey: 'idTransversal',
        labelKey: 'descripcion',
        title: 'Transversales',
        placeholder: 'Seleccioná transversal',
        name: 'idTransversal',
      },
    },
  },
  Turno: {
    hoc: {
      withSimpleSelect: {
        title: 'Turno',
        labelKey: 'descripcionTurno',
        valueKey: 'idTurno',
        placeholder: 'Seleccioná turno',
        name: 'idTurno',
      },
    },
  },
  ActividadExtracurricular: {
    hoc: {
      withSimpleSelect: {
        title: 'Taller/Servicio/Materia Especial',
        labelKey: 'descripcionActividad',
        valueKey: 'idActividadExtracurricular',
        placeholder: 'Seleccioná el Taller/Servicio/Materia Especial',
        name: 'idActividadExtracurricular',
      },
    },
  },
  CicloLectivo: {
    hoc: {
      withSimpleSelect: {
        title: 'Ciclo Lectivo',
        labelKey: 'anio',
        valueKey: 'idCicloLectivo',
        placeholder: 'Seleccioná ciclo',
        name: 'idCicloLectivo',
      },
    },
  },
  LocalizacionNivel: {
    hoc: {
      withSimpleSelect: {
        title: 'Nivel',
        labelKey: 'nivel.descripcionNivel',
        valueKey: 'nivel.idNivel',
        placeholder: 'Seleccioná nivel',
        name: 'idNivel',
      },
    },
  },
  PlanEstudioNivel: {
    hoc: {
      withSimpleSelect: {
        title: 'Plan Estudio',
        labelKey: 'planEstudio.descripcionPlanEstudio',
        valueKey: 'planEstudio.idPlanEstudio',
        placeholder: 'Seleccioná plan estudio',
        name: 'idPlanEstudio',
      },
    },
  },
  TipoDocumento: {
    hoc: {
      withSimpleSelect: {
        title: 'Tipo de Documento',
        labelKey: 'descripcionTipoDocumento',
        valueKey: 'idTipoDocumento',
        placeholder: 'Seleccioná tipo de documento',
        name: 'idTipoDocumento',
      },
    },
  },
  Materia: {
    hoc: {
      withSimpleSelect: {
        title: 'Materia',
        labelKey: 'descripcion',
        valueKey: 'idMateria',
        placeholder: 'Seleccioná la materia',
        name: 'idMateria',
      },
    },
  },
  LocalizacionPlanEstudioNivel: {
    hoc: {
      withSimpleSelect: {
        title: 'Plan de Estudio',
        labelKey: 'planEstudioNivel.planEstudio.descripcionPlanEstudio',
        valueKey: 'planEstudioNivel.planEstudio.idPlanEstudio',
        placeholder: 'Seleccioná el plan de estudio',
        name: 'idPlanEstudio',
      },
    },
  },
  PlanEstudioMateria: {
    hoc: {
      withSimpleSelect: {
        title: 'Plan de Estudio Materia',
        labelKey: 'materia.descripcion',
        valueKey: 'materia.idMateria',
        placeholder: 'Seleccioná la materia',
        name: 'idMateria',
      },
    },
  },
  Rol: {
    hoc: {
      withSimpleSelect: {
        title: 'Rol',
        labelKey: 'descripcion',
        valueKey: 'idRolUsuario',
        placeholder: 'Seleccioná rol',
        name: 'idRolUsuario',
      },
    },
  },
  DistritoEscolar: {
    hoc: {
      withSimpleSelect: {
        title: 'Distrito Escolar',
        labelKey: 'nombre',
        valueKey: 'idDistritoEscolar',
        placeholder: 'Seleccioná distrito escolar',
        name: 'idDistritoEscolar',
      },
    },
  },
  Modalidad: {
    hoc: {
      withSimpleSelect: {
        title: 'Modalidad',
        labelKey: 'descripcion',
        valueKey: 'idModalidad',
        placeholder: 'Seleccioná una modalidad',
        name: 'idModalidad',
      },
    },
  },
  RolStatus: {
    hoc: {
      withSimpleSelect: {
        title: 'Estado',
        labelKey: 'descripcion',
        valueKey: 'idRolStatus',
        placeholder: 'Seleccioná estado',
        name: 'idRolStatus',
      },
    },
  },
  Localizacion: {
    hoc: {
      withSimpleSelect: {
        title: 'Establecimiento',
        labelKey: 'descripcion',
        valueKey: 'idLocalizacion',
        placeholder: 'Seleccioná establecimiento',
        name: 'idLocalizacion',
      },
    },
  },
  DependenciaFuncional: {
    hoc: {
      withSimpleSelect: {
        title: 'Dependencia Funcional',
        labelKey: 'descripcion',
        valueKey: 'idDependenciaFuncional',
        placeholder: 'Seleccioná dependencia funcional',
        name: 'idDependenciaFuncional',
      },
    },
  },
  Permisos: {
    hoc: {
      withEntityAutocomplete: {
        optionLabelKey: 'description',
      },
    },
  },
  Endpoint: {
    hoc: {
      withEntityAutocomplete: {
        optionLabelKey: 'endpointName',
      },
    },
  },
  EndpointMethod: {
    hoc: {
      withEntityAutocomplete: {
        optionLabelKey: 'methodName',
      },
    },
  },
  Orientaciones: {
    hoc: {
      withSimpleSelect: {
        labelKey: 'nombre',
        valueKey: 'idOrientacion',
        title: '',
        placeholder: 'Seleccioná orientación',
        name: 'orientacion',
      },
      withAccordionSelector: {
        idKey: 'idOrientacion',
        labelKey: 'nombre',
      },
    },
  },
};
