import React from 'react';
import { useRolesContext } from 'src/context/RolesContext';
import { ByCGroupGrade } from './ByCGroupGrade';
import { SchoolAdults } from './SchoolAdults';
import { InitialSectionScreen } from './Initial/InitialSectionScreen';

const screenModalidad: Record<string, JSX.Element> = {
  1: <InitialSectionScreen />,
  2: <ByCGroupGrade />,
  3: <SchoolAdults />,
};

export const Section = () => {
  const { selectedRole } = useRolesContext();

  return !selectedRole?.modalidad ? (
    <></>
  ) : (
    screenModalidad[selectedRole.modalidad]
  );
};
