import { Box, Button, Grid, Typography } from '@material-ui/core';
import React from 'react';
import { SimpleSelect } from 'src/commons';
import { TiposAccionesSelect } from 'src/app/business/acap/TiposAcciones';
import { TurnoSelectInput } from 'src/app/business';

const filterAccion = {
  0: [
    {
      path: 'idTipoAccion',
      value: [1, 2, 3, 4, 5, 6],
      method: 'in',
    },
  ],
};

const filterTurno = {
  0: [
    {
      path: 'idTurno',
      value: [1, 2, 4],
      method: 'in',
    },
  ],
};

export const AccionesSeleccionadasFiltersHeader = ({
  filters,
  setFilters,
  handleSearch,
  uniquesOrientacionesEscuela,
  isLoading,
}: Acap.Referente.AccionesSeleccionadasFilterProps) => {
  return (
    <Grid
      container
      justifyContent="space-around"
      alignItems="center"
      spacing={2}
      style={{ marginTop: '15px', background: '#F5F5F5', padding: '30px 0' }}
    >
      <Grid container item xs={12} sm={6} md={4}>
        <Grid item component={Box} marginY={2}>
          <Typography variant="h5">Orientación:</Typography>
        </Grid>
        <Grid item xs={12}>
          <SimpleSelect
            name="orientacion"
            value={filters?.orientacion || ''}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFilters((prevState: any) => ({
                ...prevState,
                orientacion: value,
              }));
            }}
            placeholder="Todos"
            content={uniquesOrientacionesEscuela}
            labelKey="nombre"
            valueKey="idOrientacion"
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={6} md={4}>
        <Grid component={Box} xs={12} marginY={2}>
          <Typography variant="h5">Tipo de acción:</Typography>
        </Grid>
        <Grid item xs={12}>
          <TiposAccionesSelect
            name="tipoAccion"
            value={filters?.tipoAccion || ''}
            placeholder="Todos"
            labelKey="nombre"
            valueKey="idTipoAccion"
            fullWidth
            InputProps={{
              disableUnderline: true,
              style: { padding: '7px 1em', margin: 1 },
            }}
            filter={filterAccion}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFilters((prevState: any) => ({
                ...prevState,
                tipoAccion: value,
              }));
            }}
          />
        </Grid>
      </Grid>
      <Grid container item xs={12} sm={6} md={4}>
        <Grid component={Box} xs={12} marginY={2}>
          <Typography variant="h5">Turno:</Typography>
        </Grid>
        <Grid item xs={12}>
          <TurnoSelectInput
            label=""
            name="turno"
            value={filters?.turno || ''}
            placeholder="Todos"
            labelKey="descripcionTurno"
            valueKey="idTurno"
            fullWidth
            InputProps={{
              disableUnderline: true,
              style: { padding: '7px 1em', margin: 1 },
            }}
            filter={filterTurno}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFilters((prevState: any) => ({
                ...prevState,
                turno: value,
              }));
            }}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        md={12}
        justifyContent="center"
        alignItems="center"
        style={{
          display: 'inline-grid',
          marginTop: '30px',
        }}
      >
        <Button
          color="primary"
          variant="contained"
          component="label"
          style={{ width: '200px', marginTop: '30px' }}
          onClick={() => handleSearch()}
          disabled={
            isLoading &&
            (Boolean(filters?.orientacion) ||
              Boolean(filters?.turno) ||
              Boolean(filters?.tipoAccion))
          }
        >
          Ver selección
        </Button>
      </Grid>
    </Grid>
  );
};
