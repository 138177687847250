import { makeStyles } from '@material-ui/core';

export const useApreciacionStyles = makeStyles({
  error: {
    color: 'red',
    listStyleType: 'none',
  },
  obsContainer: {
    display: 'grid',
    gridTemplateColumns: 'max-content 1fr',
    gap: '1em',
    marginBottom: '2em',
  },
  obsTitle: {
    margin: 0,
    padding: 0,
  },
});
