import { MenuItem } from '../../../../../commons/const/ui/menu';
import { routes } from './routes';

export const usuariosMenu: MenuItem = {
  key: 'configurationAdminUsuarios',
  basePath: '/usuarios',
  enabled: true,
  icon: 'List',
  text: 'Configuration Admin Usuarios',
  children: routes,
};
