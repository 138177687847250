import { makeEntityLibbyCall } from '../../../lib/libby/hooks/makeEntityLibbyCall';
import { makeEntityLibbyDAO } from '../../../lib/libby/hooks/makeEntityLibbyDAO';
import { withRenderPropCall } from '../../../lib/libby/hoc/withRenderPropCall';

const DAO_NAME = 'admin';

// hooks
export const useAdminLibbyCall = makeEntityLibbyCall(DAO_NAME);
export const useAdminDAO = makeEntityLibbyDAO(DAO_NAME);

// components/hoc
export const AdminCall = withRenderPropCall(DAO_NAME);
