import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Alumno } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'alumno';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idAlumno',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idAlumno',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Alumno
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Alumno,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Alumno.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Alumno>(DAO_NAME, optionsToUse);

// hooks
export const useAlumnoFetchById = fetchById;
export const useAlumnoLibbyCall = libbyCall;
export const useAlumnoDAO = libbyDAO;
export const useAlumnoLibbyFetch = libbyFetch;

// components/hoc
export const AlumnoAutocomplete = entityAutocomplete;
export const AlumnoCall = renderPropCall;
export const AlumnoById = renderPropFetchById;
export const AlumnoSelect = simpleSelect;
export const AlumnoAccordionSelector = accordionSelector;
export const AlumnoSelectedChips = selectedChips;

// context
// ByIdContext
export const useAlumnoByIdContext = useByIdContext;
export const AlumnoByIdProvider = ByIdProvider;
export const AlumnoByIdContext = ByIdContext;
// GeneralContext
export const useAlumnoContext = useGeneralContext;
export const AlumnoProvider = GeneralProvider;
export const AlumnoContext = GeneralContext;
