import React, { useState, MouseEvent } from 'react';
import { Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/styles';
import typography from 'src/theme/typography';
import { MenuList } from '../MenuList';
import { primary, grey } from '../../../theme/colors';
import { Tooltip } from '@material-ui/core';

interface MenuListTypesProps {
  title: string;
  handleOnClick: (event: MouseEvent) => void;
}

interface ButtonTypesProps {
  title: React.ReactNode;
  disabled?: boolean;
  size?: string;
  handleOnClick?: (event: MouseEvent) => void;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  type?: string;
  typeButton?: any;
  customStyle?: any;
  customStyleIcon?: any;
  ListMenu?: MenuListTypesProps[];
  ringStyle?: boolean;
  tooltip?: string;
  altDisabledTextColor?: string;
}

const sizeText: Record<string, any> = {
  small: 11,
  medium: 14,
  large: 15,
};

const paddingButton: Record<string, any> = {
  small: '3px 16px',
  medium: '7px 16px',
  large: '8px 16px',
};

const backgroundColor: Record<string, any> = {
  primary: primary.lightBlue,
  secondary: 'transparent',
  cancel: primary.error,
  success: primary.success,
  info: 'transparent',
};

const backgroundColorDisabled: Record<string, any> = {
  primary: grey.textSecondary,
  secondary: 'transparent',
  cancel: primary.error,
  success: primary.success,
  info: 'transparent',
};

const backgroundColorHover: Record<string, any> = {
  primary: primary.lightBlue,
  secondary: 'transparent',
  cancel: primary.error,
  success: primary.success,
  info: 'transparent',
};
const textColorButton: Record<string, any> = {
  primary: primary.white,
  secondary: primary.lightBlue,
  cancel: primary.white,
  success: primary.white,
  error: primary.white,
  info: grey.textPrimary,
};

const borderButton: Record<string, string> = {
  info: `1px solid ${grey.normal}`,
};

export const MainButton = ({
  title,
  size = 'large',
  iconLeft,
  iconRight,
  disabled = false,
  type = 'primary',
  handleOnClick,
  customStyle,
  customStyleIcon,
  ListMenu,
  typeButton = 'button',
  ringStyle = false,
  tooltip = '',
  altDisabledTextColor = '',
}: ButtonTypesProps) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const buttonStyles = makeStyles(() => ({
    button: {
      background: backgroundColor[type],
      display: 'flex',
      color: textColorButton[type],
      fontWeight: 'normal',
      fontFamily: typography.fontFamily,
      fontSize: sizeText[size],
      textTransform: 'none',
      padding: paddingButton[size],
      borderRadius: 4,
      border: borderButton[type],
      position: 'relative',
      transition: 'all 0.3s ease-in-out 0s',
      '&:disabled': {
        color: altDisabledTextColor || primary.white,
        background: backgroundColorDisabled[type],
      },
      '&:hover': {
        background: backgroundColorHover[type],
      },
      ...customStyle,
    },
    icon: {
      marginTop: 8,
      ...customStyleIcon,
    },
    '@keyframes ring': {
      '0%': {
        width: '30px',
        height: '30px',
        opacity: 1,
      },
      '100%': {
        width: '120px',
        height: '120px',
        opacity: 0,
      },
    },
    after: {
      '&::after': {
        content: '""',
        width: '30px',
        height: '30px',
        borderRadius: '100%',
        border: `6px solid ${backgroundColor[type]}`,
        position: 'absolute',
        zIndex: -1,
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        animation: '$ring 2s ease-out infinite',
      },
    },
  }));

  const classes = buttonStyles();

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(13),
      border: '1px solid #dadde9',
      marginLeft: '-150px',
      marginBottom: '20px',
      fontFamily: 'Open Sans',
    },
  }))(Tooltip);

  return (
    <>
      <HtmlTooltip title={tooltip} placement="top-start">
        <span>
          <Button
            type={typeButton}
            classes={{
              root: classes.button,
              ...(ringStyle && { text: classes.after }),
            }}
            startIcon={
              iconLeft && <div className={classes.icon}>{iconLeft}</div>
            }
            endIcon={
              iconRight && <div className={classes.icon}>{iconRight}</div>
            }
            disabled={disabled}
            onClick={ListMenu ? handleOpenMenu : handleOnClick}
          >
            {title}
          </Button>
        </span>
      </HtmlTooltip>
      {ListMenu && (
        <MenuList
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          ListMenu={ListMenu}
        />
      )}
    </>
  );
};
