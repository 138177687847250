export const columns = [
  {
    id: 'name',
    label: 'Nombre y Apellido',
    width: '20%',
    orderById: 'idAlumno.idPersona.nombre',
  },
  {
    id: 'document',
    label: 'Documento',
    width: '10%',
    orderById: 'idAlumno.idPersona.documento',
  },
  {
    id: 'establishment',
    label: 'Establecimiento de origen',
    width: '20%',
    orderById: 'idLocalizacion.idEstablecimineto.nombre',
  },
  {
    id: 'level',
    label: 'Nivel',
    width: '10%',
  },
];
