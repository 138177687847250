import { MenuItem } from '../../../../commons/const/ui/menu';
import { routes } from './routes';

export const AcapMenu: MenuItem = {
  key: 'acap',
  basePath: '/acapescuela',
  enabled: true,
  icon: 'List',
  text: 'ACAP',
  children: routes,
  default: true,
};
