import React, { useMemo } from 'react';
import { useSeccionContext } from 'src/app/business';
import { Grid } from '@material-ui/core';
import { EspaciosInterdiscipPlan } from 'src/app/models';
import { HeaderFeedback } from '../../../feedbacks/commons/components/FeedbackHeader';

export type InterdisciplinarFlowHeaderProps = {
  data: EspaciosInterdiscipPlan;
  toPrint?: boolean;
};

export const InterdisciplinarFlowHeader = ({
  data,
  toPrint,
}: InterdisciplinarFlowHeaderProps) => {
  const { working } = useSeccionContext();
  const formateDataHeader = useMemo(
    () => ({
      disciplina: data.espacioCurricular.materia.descripcion,
      area: data.espacioCurricular.materia.area.descripcion,
      orientacion:
        data?.espacioCurricular?.planEstudio?.orientacion
          ?.descripcionOrientacion,
      seccion: '',
      fecha: data.interdiscipPlan.ultimaActualizacion,
      autor: `${data?.interdiscipPlan?.cuentas?.nombreUsuario} ${data?.interdiscipPlan?.cuentas?.apellidoUsuario}`,
      nombre: data.interdiscipPlan.proyecto.nombre,
      descripcion: data.interdiscipPlan.proyecto.descripcion,
    }),
    [
      data.interdiscipPlan.ultimaActualizacion,
      data.espacioCurricular.materia.descripcion,
      data?.interdiscipPlan?.cuentas?.nombreUsuario,
      data?.interdiscipPlan?.cuentas?.apellidoUsuario,
      data.interdiscipPlan.proyecto.nombre,
      data.interdiscipPlan.proyecto.descripcion,
      data.espacioCurricular.materia.area.descripcion,
      data?.espacioCurricular?.planEstudio?.orientacion?.descripcionOrientacion,
    ],
  );
  return (
    <Grid container direction="column">
      {!working && (
        <HeaderFeedback data={formateDataHeader} toPrint={toPrint} />
      )}
    </Grid>
  );
};
