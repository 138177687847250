import { NIVEL } from 'src/commons/const';
import { getFilterPassSectionInicialLogic } from './getFilterPassSectionInicialLogic';
import { getFilterPassSectionPrimariaLogic } from './getFilterPassSectionPrimariaLogic';
import { getFilterPassSectionSecundariaLogic } from './getFilterPassSectionSecundariaLogic';
import { Turno } from 'src/app/models';

export const getFilterPassSection = (
  options: any,
  statusPass: number,
  currentCicloLectivo: number,
  numeroAnio: number,
  nivel: string | number,
  descripcionAnioSection?: string,
  idAnio?: number,
  turno?: Turno,
) => {
  let filteredOptions;

  switch (Number(nivel)) {
    case NIVEL.INICIAL:
      filteredOptions = getFilterPassSectionInicialLogic(
        options,
        statusPass,
        currentCicloLectivo,
        numeroAnio,
        descripcionAnioSection,
      );
      break;
    case NIVEL.PRIMARIO:
      filteredOptions = getFilterPassSectionPrimariaLogic(
        options,
        statusPass,
        currentCicloLectivo,
        idAnio,
        turno,
      );
      break;
    case NIVEL.SECUNDARIO:
      filteredOptions = getFilterPassSectionSecundariaLogic(
        options,
        statusPass,
        currentCicloLectivo,
        idAnio,
        turno,
      );
      break;
  }

  return { filteredOptions };
};
