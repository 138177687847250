import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { MainInput, SimpleSelect } from 'src/commons';
import useGenerarExcel from 'src/commons/hooks/useGenerarExcel';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';

const customStyles = {
  searchGrid: { marginTop: 10 },
  descargar: { marginBottom: '4px' },
  containerStyles: { marginTop: 23 },
};

export const OrganizacionFiltersHeader = ({
  setFilters,
  filters,
  setSearch,
  handleChangeSearch,
  search,
  excelData,
  setIsCreatingAnOrganization,
  sectores,
}: any) => {
  const generateExcel = useGenerarExcel();

  return (
    <>
      <Grid container direction="column">
        <Grid
          container
          justify="space-between"
          alignItems="flex-end"
          spacing={3}
          style={{ padding: '0 15px 15px', paddingRight: 15, marginTop: 23 }}
        >
          <Grid
            container
            xs={8}
            justify="flex-start"
            spacing={3}
            alignItems="flex-end"
          >
            <Grid item xs={3}>
              <SimpleSelect
                title="Sector"
                name="sector"
                value={filters.sector}
                handleChange={(e: any) =>
                  setFilters({ sector: e.target.value })
                }
                placeholder="Seleccioná sector"
                content={sectores}
                labelKey="nombre"
                valueKey="idSector"
              />
            </Grid>
          </Grid>

          <Grid
            container
            xs={4}
            justify="flex-end"
            spacing={3}
            alignItems="flex-end"
            style={customStyles.searchGrid}
          >
            <Grid item xs>
              <MainInput
                type="search"
                handleChange={handleChangeSearch}
                placeholder="Buscar"
                name="search"
                value={search}
                fullWidth
              />
            </Grid>

            <Grid item xs style={customStyles.descargar}>
              <Button
                color="primary"
                variant="contained"
                component="label"
                fullWidth
                onClick={() => generateExcel(excelData?.body, excelData?.name)}
                disabled={!excelData?.body?.data?.length}
              >
                Descargar
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          spacing={3}
          style={{ padding: '0 15px 15px', paddingRight: 15, marginTop: 23 }}
        >
          <Typography>Agregar organización</Typography>
          <IconButton
            onClick={() =>
              setIsCreatingAnOrganization({ enabled: true, row: null })
            }
          >
            <AddCircleOutlineIcon color="primary" />
          </IconButton>
        </Grid>
      </Grid>
    </>
  );
};
