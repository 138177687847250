import moment from 'moment';
import {
  ContraturnoDia,
  ExceptuadosV3,
  Presente_v3,
} from '../../../../../../../app/models';
import { AlumnoMap } from '../../../types';
import { ExceptuadosDateRangeV3 } from '../../ExceptuadosModal/types';
import { TURNOS } from '../components';

const findDeSemana = ['sábado', 'domingo'];

export const toExceptuados = (data: Array<any>) =>
  data.map<ExceptuadosV3>((item) => ({
    idExceptuado: item.idExceptuado,
    alumno: item.alumno,
    startTime: item.startTime,
    endTime: item.endTime,
    startedBy: item.startedBy || 0,
    endedBy: item.endedBy || 0,
    motivoExceptuado: item.motivoExceptuado!,
  }));

export const transformExceptuados = (data: ExceptuadosDateRangeV3[]) => {
  return data.map(
    ({ key, color, startDate, endDate, endTime, startTime, ...rest }) => ({
      ...rest,
      startTime: moment(startTime).format(),
      endTime: moment(endTime).format(),
    }),
  );
};

/**
 * Retorna los presentismos nuevos o los que ya existen para los periodos de regimen especial
 *
 *  @param {ExceptuadosV3[]} exceptuados Periodos de regimen especial
 *  @param {Presente_v3[]} allPresentismo Todos los presentismo obtenidos para los periodos nuevos, actualizados, y borrados
 *  @param {AlumnoMap} alumnoMap Informacion del alumno
 *  @param {number} turnoSeccion Id del turno de la seccion
 *  @param {boolean} isJE Define si es jornada estendida
 *  @returns {{presentismos: Presente_v3[]}} Presentes
 *
 */
export const getPresentesByExceptuados = (
  exceptuados: ExceptuadosV3[],
  allPresentismo: Presente_v3[],
  { contraturnos, seccion }: AlumnoMap,
  turnoSeccion: number,
  isJE: boolean,
) => {
  const turnos = TURNOS[turnoSeccion] ? TURNOS[turnoSeccion] : [];

  let _presentismo: Presente_v3[] = [];
  for (const exceptuado of exceptuados) {
    const dias = moment(exceptuado.endTime).diff(exceptuado.startTime, 'days');
    for (let i = 0; i <= dias; i++) {
      const current = moment(exceptuado.startTime).add(i, 'd');
      const isFindeSemana = findDeSemana.includes(
        moment(current).format('dddd'),
      );
      if (isFindeSemana) continue;
      const _contraturnos = contraturnos.filter(
        (contraturno) =>
          contraturno?.dia?.idDia === moment(current).day() &&
          contraturno.active &&
          contraturno.contraturno.active &&
          contraturno.contraturno.fechaInicio <=
            moment(current).format('YYYY-MM-DD') &&
          contraturno.contraturno.fechaFin >=
            moment(current).format('YYYY-MM-DD'),
      );

      for (const _turno of turnos) {
        const isExistNC = allPresentismo.find(
          (presente) =>
            moment(presente.fecha).format('YYYY-MM-DD') ===
              moment(current).format('YYYY-MM-DD') &&
            presente.estadoPresente.idEstadoPresente === 3 &&
            presente.turno?.idTurno === _turno.idTurno,
        );
        if (isExistNC) continue;
        let presenteToSave: any = {
          idPresente: null,
          alumno: exceptuado.alumno,
          estadoPresente: {
            idEstadoPresente: 3,
            descripcion: 'No corresponde',
          },
          fecha: moment(current).format('YYYY-MM-DD'),
          turno: { idTurno: _turno.idTurno },
          seccion,
          isJornadaExtendida: isJE,
          justificacion: null,
          contraturnoDia: null,
        };
        const exitePresente = allPresentismo.find(
          (presente) =>
            moment(presente.fecha).format('YYYY-MM-DD') ===
              moment(current).format('YYYY-MM-DD') &&
            presente.turno?.idTurno === _turno.idTurno &&
            presente.estadoPresente.idEstadoPresente !== 3,
        );
        if (exitePresente) {
          _presentismo.push({
            ...exitePresente,
            estadoPresente: {
              ...exitePresente.estadoPresente,
              idEstadoPresente: 3,
            },
          });
        } else {
          _presentismo.push({ ...presenteToSave });
        }
      }
      for (const _contraturno of _contraturnos) {
        const isExistNC = allPresentismo.find(
          (presente) =>
            moment(presente.fecha).format('YYYY-MM-DD') ===
              moment(current).format('YYYY-MM-DD') &&
            presente.estadoPresente.idEstadoPresente === 3 &&
            presente.contraturnoDia?.idContraturnoDia ===
              _contraturno.idContraturnoDia,
        );
        if (isExistNC) continue;
        let presenteToSave: any = {
          idPresente: null,
          alumno: exceptuado.alumno,
          estadoPresente: {
            idEstadoPresente: 3,
            descripcion: 'No corresponde',
          },
          fecha: moment(current).format('YYYY-MM-DD'),
          seccion,
          isJornadaExtendida: isJE,
          justificacion: null,
          contraturnoDia: _contraturno,
          turno: null,
        };
        const exitePresente = allPresentismo.find(
          (presente) =>
            moment(presente.fecha).format('YYYY-MM-DD') ===
              moment(current).format('YYYY-MM-DD') &&
            presente.contraturnoDia?.idContraturnoDia ===
              _contraturno.idContraturnoDia &&
            presente.estadoPresente.idEstadoPresente !== 3,
        );
        if (exitePresente) {
          _presentismo.push({
            ...exitePresente,
            estadoPresente: {
              ...exitePresente.estadoPresente,
              idEstadoPresente: 3,
            },
            isJornadaExtendida: isJE,
          });
        } else {
          _presentismo.push({ ...presenteToSave });
        }
      }
    }
  }
  return { presentismos: _presentismo };
};

/**
 * Retorna los presentismos a eliminar para los periodos de regimen especial
 *
 *  @param {ExceptuadosV3[]} exceptuados Periodos de regimen especial
 *  @param {Presente_v3[]} allPresentes Todos los presentismo obtenidos para los periodos nuevos, actualizados, y borrados
 *  @param {ContraturnoDia[]} contraturnos Todo los contraturnos del alumno
 *  @param {number} turnoSeccion Id del turno de la seccion
 *  @param {boolean} isJE Define si es jornada estendida
 *  @returns {{presentismos: Presente_v3[]}} Presentes
 *
 */
export const getPresentesByExceptuadosToDelete = (
  exceptuados: ExceptuadosV3[],
  allPresentes: Presente_v3[],
  contraturnos: ContraturnoDia[],
  turnoSeccion: number,
  isJE: boolean,
) => {
  const turnos = TURNOS[turnoSeccion] ? TURNOS[turnoSeccion] : [];

  let _presentismo: Presente_v3[] = [];
  for (const exceptuado of exceptuados) {
    const dias = moment(exceptuado.endTime).diff(exceptuado.startTime, 'days');
    for (let i = 0; i <= dias; i++) {
      const current = moment(exceptuado.startTime).add(i, 'd');
      const isFindeSemana = findDeSemana.includes(
        moment(current).format('dddd'),
      );
      if (isFindeSemana) continue;
      const _contraturnos = contraturnos.filter(
        (contraturno) =>
          contraturno?.dia?.idDia === moment(current).day() &&
          contraturno.active &&
          contraturno.contraturno.active &&
          contraturno.contraturno.fechaInicio <=
            moment(current).format('YYYY-MM-DD') &&
          contraturno.contraturno.fechaFin >=
            moment(current).format('YYYY-MM-DD'),
      );
      for (const _turno of turnos) {
        const hasPresente = allPresentes.filter(
          (presente) =>
            moment(presente.fecha).format('YYYY-MM-DD') ===
              moment(current).format('YYYY-MM-DD') &&
            presente.turno?.idTurno === _turno.idTurno,
        );
        if (!hasPresente) continue;
        _presentismo.push(...hasPresente);
      }
      for (const _contraturno of _contraturnos) {
        const hasPresente = allPresentes.filter(
          (presente) =>
            moment(presente.fecha).format('YYYY-MM-DD') ===
              moment(current).format('YYYY-MM-DD') &&
            presente.contraturnoDia?.idContraturnoDia ===
              _contraturno.idContraturnoDia,
        );
        if (!hasPresente) continue;
        _presentismo.push(...hasPresente);
      }
    }
  }
  return { presentismos: _presentismo };
};

/**
 * Retorna los presentismos a eliminar para los periodos de regimen especial actualizados
 *
 *  @param {ExceptuadosV3[]} newExceptuados Periodos de regimen especial
 *  @param {ExceptuadosV3[]} oldExceptuados Todo los contraturnos del alumno
 *  @param {Presente_v3[]} presentismos Todos los presentismo obtenidos para los periodos nuevos, actualizados, y borrados
 *  @param {boolean} isJE Define si es jornada estendida
 *  @returns {{toDelete: Presente_v3[]}} Presentes a eliminar
 *
 */
export const getPresentismoToDeleteByUpdatedExceptuado = (
  newExceptuados: ExceptuadosV3[],
  oldExceptuados: ExceptuadosV3[],
  presentismos: Presente_v3[],
  isJE: boolean,
) => {
  let oldPresentes: Presente_v3[] = [];
  for (const oldExceptuado of oldExceptuados) {
    const dias = moment(oldExceptuado.endTime).diff(
      oldExceptuado.startTime,
      'days',
    );
    for (let i = 0; i <= dias; i++) {
      const current = moment(oldExceptuado.startTime).add(i, 'd');
      const isFindeSemana = findDeSemana.includes(
        moment(current).format('dddd'),
      );
      if (isFindeSemana) continue;
      const exitePresentes = presentismos.filter(
        (presente) =>
          moment(presente.fecha).format('YYYY-MM-DD') ===
          moment(current).format('YYYY-MM-DD'),
      );
      if (!exitePresentes) continue;
      oldPresentes.push(...exitePresentes);
    }
  }
  let newPresentes: Presente_v3[] = [];
  for (const newExceptuado of newExceptuados) {
    const dias = moment(newExceptuado.endTime).diff(
      newExceptuado.startTime,
      'days',
    );
    for (let i = 0; i <= dias; i++) {
      const current = moment(newExceptuado.startTime).add(i, 'd');
      const isFindeSemana = findDeSemana.includes(
        moment(current).format('dddd'),
      );
      if (isFindeSemana) continue;
      const exitePresentes = presentismos.filter(
        (presente) =>
          moment(presente.fecha).format('YYYY-MM-DD') ===
          moment(current).format('YYYY-MM-DD'),
      );
      if (!exitePresentes) continue;
      newPresentes.push(...exitePresentes);
    }
  }
  const toDelete = [];

  for (const oldPresente of oldPresentes) {
    const exists = newPresentes.includes(oldPresente);
    if (!exists) {
      toDelete.push(oldPresente);
    }
  }
  return { toDelete };
};
