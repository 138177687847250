import React from 'react';

interface errorsType {
  errorMessages: string[];
  isFinished?: boolean;
  title: React.ReactNode;
}

export const TooltipErrors = ({ errorMessages, title }: errorsType) => {
  return (
    <div>
      {Boolean(errorMessages) && errorMessages.length > 0 ? (
        <div>
          {title}
          <ul>
            {errorMessages.map((err) => (
              <li>{err}</li>
            ))}
          </ul>
        </div>
      ) : (
        <h4>No hay alertas para mostrar</h4>
      )}
    </div>
  );
};
