import { useCallback, useMemo } from 'react';
import { useGetJEColumns } from 'src/screens/Private/Calificaciones/hooks/useGetJEColumns';
import { primary } from 'src/theme/colors';

const headerStyle = {
  color: primary.lightBlue,
  textAlign: 'center',
};

function camelize(str: string) {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, '');
}

export const useJornadaExtendidaTableColumns = (idJEm: string) => {
  const { columns, working } = useGetJEColumns(idJEm);

  const makeColumns = useCallback(() => {
    const newColumns = columns.map((column: string) => ({
      id: camelize(column),
      label: column,
      width: '15%',
      hideSortIcon: true,
      style: headerStyle,
    }));
    return newColumns;
  }, [columns]);

  return useMemo(
    () => ({
      working,
      columns: [
        {
          id: 'alumno',
          label: 'Alumno',
          width: '20%',
          hideSortIcon: true,
          style: headerStyle,
        },
        ...makeColumns(),
        {
          id: 'observaciones',
          label: 'Observaciones',
          width: '15%',
          align: 'center',
          hideSortIcon: true,
          style: headerStyle,
        },
      ],
    }),
    [makeColumns, working],
  );
};
