import { LibbyFetchDAO } from './LibbyFetchDAO';

export class TipoPeriodoDAO extends LibbyFetchDAO {
  constructor() {
    super('tipo_periodo', 'idTipoPeriodo');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
