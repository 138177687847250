import { ROL } from 'src/commons/const/rol';
import { LibbyFetchDAO } from './LibbyFetchDAO';

type FetchByUserLocalizacionAndNivel = {
  userId: number;
  nivelId: number;
  localizacionId: number;
  rolUsuarioId: number;
};

export class UsuarioRolEstablecimientoDAO extends LibbyFetchDAO {
  constructor() {
    super('usuario_rol_establecimiento', 'idRolEstablecimiento');
  }

  fetchByUserId = (id: number) => {
    this.query()
      .equals('id_usuario', id)
      .orderBy('id_localizacion', 'asc')
      .orderBy('id_nivel', 'asc')
      .run();
  };

  getUsuarioRolStudent(idCuenta: any) {
    return this.query()
      .equals('usuario', idCuenta)
      .and()
      .equals('rolUsuario', ROL.ESTUDIANTE)
      .run();
  }

  fetchByUserLocalizacionAndNivel = ({
    userId,
    localizacionId,
    rolUsuarioId,
    nivelId,
  }: FetchByUserLocalizacionAndNivel) => {
    return this.query()
      .equals('usuario.idUsuario', userId)
      .equals('localizacion.idLocalizacion', localizacionId)
      .equals('nivel.idNivel', nivelId)
      .equals('rolUsuario.idRolUsuario', rolUsuarioId)
      .orderBy('id_localizacion', 'asc')
      .orderBy('id_nivel', 'asc')
      .run();
  };

  getByNivel(userId: number, nivelId: number, localizacionId: number) {
    return this.aspect('login')
      .query()
      .equals('usuario', userId)
      .and()
      .equals('rolUsuario', ROL.DIRECTIVO)
      .and()
      .equals('localizacion', localizacionId)
      .and()
      .equals('nivel', nivelId)
      .run();
  }
}
