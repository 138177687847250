/* eslint-disable react/destructuring-assignment */
// eslint-disable-next-line max-classes-per-file
import React, { Component } from 'react';
import { render } from 'react-dom';
import { LoadingModal as LoadingModalComponent } from '../../components/LoadingModal';

class ServiceInstanceHandler {
  get ref(): LoadingModal {
    return this._ref;
  }

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  private _ref: LoadingModal = new LoadingModal({});

  constructor() {
    this.init();
  }

  init() {
    const containerElement = document.createElement('div');
    document.body.appendChild(containerElement);
    return render(
      <LoadingModal
        setRef={(service: LoadingModal) => {
          this._ref = service;
        }}
      />,
      containerElement,
    );
  }
}

interface LoadingModalProps {
  setRef?: (service: LoadingModal) => void;
}

interface LoadingModalState {
  open: boolean;
}

class LoadingModal extends Component<LoadingModalProps, LoadingModalState> {
  static create(): LoadingModal {
    const instance = new ServiceInstanceHandler();
    return instance.ref;
  }

  constructor(props: any) {
    super(props);

    this.state = {
      open: false,
    };
  }

  componentDidMount(): void {
    const { setRef } = this.props;
    if (setRef) {
      setRef(this);
    }
  }

  open() {
    this.setState({ open: true });
  }

  close() {
    this.setState({ open: false });
  }

  render() {
    return <LoadingModalComponent open={this.state.open} />;
  }
}

export const loadingModal = LoadingModal.create();
