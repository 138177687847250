import { Bloques, EspacioCurricular } from 'src/app/models';
import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ContenidoBloqueDAO extends LibbyFetchDAO {
  constructor() {
    super('contenido_bloque', 'idContenidoBloque');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  filtroContenidoBloque(params: EspacioCurricular[] = []) {
    const idMaterias = params?.reduce<number[]>((acc, item) => {
      if (!acc.includes(item.materia.idMateria)) {
        return [...acc, item.materia.idMateria];
      }
      return acc;
    }, []);
    let query = this.query();
    query.groupStart();
    if (!idMaterias.length) {
      query = query.notEquals('bloque.idMateria', -1);
    } else {
      idMaterias.forEach((idMateria, index: number) => {
        if (index !== 0) {
          query = query.or();
        }
        query = query.equals('bloque.idMateria', idMateria);
      });
    }
    query.groupEnd();
    query.orderBy('idContenidoBloque', 'asc');
    query.orderBy('contenidoBloqueSubnivels.idContenidoBloqueSubnivel', 'asc');
    query.orderBy(
      'contenidoBloqueSubnivels.contenidoBloqueSubnivelDos.idContenidoBloqueSubnivelDos',
      'asc',
    );
    return query.limit(0, 10000).run();
  }

  filtroContenidoBloqueDisciplinar(params: Bloques[] = []) {
    let query = this.query();
    query.groupStart();
    if (!params.length) {
      query = query.notEquals('bloque.idBloque', -1);
    } else {
      params.forEach((bloque, index: number) => {
        if (index !== 0) {
          query = query.or();
        }
        query = query.equals('bloque.idBloque', bloque.idBloque);
      });
    }
    query.groupEnd();
    query.orderBy('idContenidoBloque', 'asc');
    query.orderBy('contenidoBloqueSubnivels.idContenidoBloqueSubnivel', 'asc');
    query.orderBy(
      'contenidoBloqueSubnivels.contenidoBloqueSubnivelDos.idContenidoBloqueSubnivelDos',
      'asc',
    );
    return query.limit(0, 10000).run();
  }
}
