import { useEffect, useMemo } from 'react';
import { DependenciaFuncional } from 'src/app/models';
import { LibbyObject } from '../types';
import {
  useUsuarioRolEstablecimientoLibbyFetch,
  useCuentasFetchById,
  useReloginDAO,
} from '../../app/business';
import { UsuarioRolEstablecimiento } from '../../app/models';
import { useRolesContext } from '../../context/RolesContext';
import { useAutoregistracionCheck } from './useAutoregistracionCheck';

type UsuarioRolEstablecimientoExtended = {
  dependenciaFuncional?: DependenciaFuncional;
};

type FilterRoles = UsuarioRolEstablecimiento &
  UsuarioRolEstablecimientoExtended;

const searchFilters = [
  'usuario.apellidoUsuario',
  'usuario.nombreUsuario',
  'usuario.username',
  'rolUsuario.descripcion',
  'localizacion.descripcion',
  'localizacion.cueAnexo',
  'nivel.descripcionNivel',
];

// TODO: esto se deberia resolver de otra manera
export const useFillRoleUser = (libby: LibbyObject, search?: string) => {
  const checkPersona = useAutoregistracionCheck();
  const roleContext = useRolesContext();
  const { data: cuentas, working: wCuentas } = useCuentasFetchById(
    libby.session.user.id,
  );

  const filteruser = useMemo(
    () => ({
      usuario: [{ path: 'usuario', value: libby.session.user.id }],
      search: !search
        ? []
        : searchFilters.map((path) => ({
            path,
            value: search,
            method: 'includes',
          })),
    }),
    [libby, search],
  );

  const result = useUsuarioRolEstablecimientoLibbyFetch({
    filter: filteruser,
    orderBy: 'localizacion.idLocalizacion',
    aspect: 'home',
  });

  const { data: rolesUser = [], working } = result;

  useEffect(() => {
    if (rolesUser.length && !working) {
      const filterRoles = rolesUser.map((_item: FilterRoles) => ({
        localizacionId: _item.localizacion.idLocalizacion,
        modalidad: _item.localizacion?.establecimiento?.modalidad?.idModalidad,
        schoolName: _item.localizacion.descripcion,
        rolDescription: _item.rolUsuario.descripcion,
        nivel: _item.nivel.descripcionNivel,
        idNivel: _item.nivel.idNivel,
        rol: _item.rolUsuario?.idRolUsuario,
        img: 'url("/static/media/Directivos.84e9b500.png")',
        idRolEstablecimiento: _item.idRolEstablecimiento,
        dependenciaFuncional: _item.dependenciaFuncional,
      }));
      const userInfo = {
        id: rolesUser[0].usuario.idUsuario,
        name: rolesUser[0].usuario.nombreUsuario,
        email: rolesUser[0].usuario.username,
        apellido: rolesUser[0].usuario.apellidoUsuario,
      };
      roleContext.setUserRoles(filterRoles);
      roleContext.setUserInfo(userInfo);

      checkPersona(+rolesUser[0].usuario.idUsuario);
    } else if (cuentas?.idUsuario && !wCuentas) {
      const userInfo = {
        id: cuentas.idUsuario,
        name: cuentas.nombreUsuario,
        email: cuentas.username,
        apellido: cuentas.apellidoUsuario,
      };
      roleContext.setUserInfo(userInfo);
      checkPersona(+cuentas?.idUsuario);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [rolesUser]);

  return result;
};
