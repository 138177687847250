import moment from 'moment';
import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class ObservacionesBoletinDAO extends LibbyFetchDAO {
  constructor() {
    super('observacionesboletin', 'idObservacionBoletin');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByAlumno(
    idAlumno: number,
    nivel: string,
    isJE: boolean = false,
    idPeriodo: number,
  ) {
    /*     const today = moment().format('YYYY-MM-DD'); */
    return (
      this.query()
        .equals('alumno.idAlumno', idAlumno)
        /*       .lowerOrEquals('periodoNuevo.fechaInicio', today as unknown as number)
      .higherOrEquals('periodoNuevo.fechaFin', today as unknown as number) */
        .equals('periodoNuevo', idPeriodo)
        .equals('periodoNuevo.nivel', Number(nivel))
        .equals('isJornadaExtendida', isJE as unknown as number)
        .limit(0, 10000)
        .run()
    );
  }
}
