import { isEqual } from 'lodash';
import moment from 'moment';
import { DateRange } from '../../../../../../../app/interfaces/DateRange';
import { Alumno, MotivoExceptuado } from '../../../../../../../app/models';
import { randomText } from '../../../../functions/randomText';
import { COLORS_PALETTE } from '../../../../TomaPresentismoOLD/utils/colors';

export class ExceptuadosDateRangeV3 implements DateRange {
  idExceptuado?: string;
  motivoExceptuado?: MotivoExceptuado;
  startTime: string | null;
  endTime?: string;
  startDate: Date;
  endDate?: Date;
  key: string;
  color?: string;

  endedBy: number | null;
  alumno: Alumno;
  startedBy: number | null;
  isEdited: boolean;
  autoFocus?: boolean;
  disabled?: boolean;
  toDeleted?: boolean;

  constructor(
    startTime: string | null,
    alumno: Alumno,
    endTime?: string,
    idExceptuado?: string,
    motivoExceptuado?: MotivoExceptuado,
    color?: string,
    isEdited?: boolean,
    toDeleted?: boolean,
  ) {
    const tz = Math.abs(moment().utcOffset());
    this.key = randomText(10);
    this.idExceptuado = idExceptuado;
    this.startTime = startTime;
    this.startDate = moment(startTime).add(tz, 'minutes').toDate();
    this.endDate = moment(endTime).add(tz, 'minutes').toDate();
    this.endTime = endTime;
    this.endedBy = null;
    this.alumno = alumno;
    this.startedBy = null;
    this.isEdited = isEdited || false;
    const indexColor = Math.floor(Math.random() * 20);
    this.color = color ?? `#${COLORS_PALETTE[indexColor]}`;
    this.motivoExceptuado = motivoExceptuado;
    this.toDeleted = toDeleted || false;
  }

  getFormattedInterval() {
    return `${moment(this.startDate).format('DD-MM-YYYY')} - ${moment(
      this.endDate,
    ).format('DD-MM-YYYY')}`;
  }

  getMotivo(): MotivoExceptuado {
    return (
      this.motivoExceptuado || {
        idMotivoExceptuado: 0,
        descripcion: 'Seleccioná un motivo',
      }
    );
  }

  isExceptuado(day: moment.Moment) {
    if (this.startTime && this.endTime) {
      return (
        day.isBetween(this.startTime, this.endTime) ||
        day.isSame(this.startTime) ||
        day.isSame(this.endTime)
      );
    } else if (this.endTime === null) {
      return day.isSameOrAfter(this.startTime);
    }
  }

  isClosed() {
    return Boolean(this.startTime) && Boolean(this.endTime);
  }

  isOpen() {
    return Boolean(this.startTime) && !Boolean(this.endTime);
  }

  isBetweenARange(range: DateRange) {
    return (
      moment(this.startTime).isBetween(range.startTime, range.endTime) &&
      moment(this.endTime).isBetween(range.startTime, range.endTime)
    );
  }

  containsDate(date: string) {
    return moment(date).isBetween(this.startTime, this.endTime);
  }

  containsRange(range: DateRange) {
    return (
      moment(range.startTime).isBetween(this.startTime, this.endTime) &&
      moment(range.endTime).isBetween(this.startTime, this.endTime)
    );
  }

  static isBetweenExceptuadoClosed(
    day: moment.Moment,
    exceptuados: ExceptuadosDateRangeV3[],
  ) {
    return exceptuados.some((exceptuado) => exceptuado.isExceptuado(day));
  }

  static searchClosestEndTimeNull(
    day: moment.Moment,
    exceptuados: ExceptuadosDateRangeV3[],
  ) {
    const items = exceptuados
      .filter((excep) => moment(excep.startTime).isBefore(day))
      .sort((excep1, excep2) => {
        if (excep1.startTime === excep2.startTime) return 0;
        return moment(excep1.startTime).isAfter(excep2.startTime) ? -1 : 1;
      });

    if (items.length > 0) {
      return items[0];
    } else {
      return false;
    }
  }
}

export class DateRangeListV3 {
  list: Array<ExceptuadosDateRangeV3> = [];
  private indexOpenedInterval: number | false = false;
  private tz: number;

  constructor(list: Array<ExceptuadosDateRangeV3> = []) {
    this.tz = Math.abs(moment().utcOffset());
    this.list = list.map((exceptuado) => {
      return new ExceptuadosDateRangeV3(
        moment(exceptuado.startTime).format(),
        exceptuado.alumno,
        moment(exceptuado.endTime).format(),
        exceptuado?.idExceptuado,
        exceptuado.motivoExceptuado,
        exceptuado.color,
        exceptuado.isEdited,
      );
    });
  }

  remove(index: number) {
    this.list.splice(index, 1);
  }

  isEqualToList(list: DateRangeListV3) {
    return isEqual(this.list, list.list);
  }

  isEmpty() {
    return this.list.length === 0;
  }

  hasOneWithinInterval(date: string) {
    const opened = this.list.find((interval) => interval.isOpen());
    return (
      (opened && moment(date).isSameOrAfter(opened.startTime)) ||
      this.getClosedIntervals().some((interval) => {
        return (
          moment(date).isSameOrAfter(interval.startTime) &&
          moment(date).isSameOrBefore(interval.endTime)
        );
      })
    );
  }

  isInvervalOverlapping(range: DateRange, exceptMe = '') {
    return (
      this.isDateOverlapping(range.startTime!, exceptMe) ||
      this.isDateOverlapping(range.endTime!, exceptMe) ||
      this.list.some((interval) => range.containsRange(interval))
    );
  }

  updateRange(key: string, startTime: string, endTime?: string) {
    const indexToUpdate = this.list.findIndex(
      (localRange) => localRange.key === key,
    );
    this.list[indexToUpdate].startTime = startTime;
    this.list[indexToUpdate].endTime = endTime;
    if (this.list[indexToUpdate].idExceptuado) {
      this.list[indexToUpdate].isEdited = true;
    }
  }

  getRowsToSave() {
    return this.list.map((interval) => {
      const { key, color, startDate, endDate, endTime, startTime, ...rest } =
        interval;
      const newStartTime = moment(startTime).format();
      const newEndTime = moment(endTime).format();
      return {
        ...rest,
        startTime: newStartTime,
        endTime: newEndTime,
      };
    });
  }

  searchByKey(key: string, type = 'item') {
    return 'index' === type
      ? this.list.findIndex((localRange) => localRange.key === key)
      : this.list.find((localRange) => localRange.key === key);
  }

  clone() {
    return new DateRangeListV3(this.list);
  }

  [Symbol.iterator]() {
    let i = 0;
    return {
      next: () => {
        if (this.list.length === 0)
          return {
            value: [],
            done: true,
          };

        const range = this.list[i++];
        return {
          value: range,
          done: i > this.list.length,
        };
      },
    };
  }

  isDateOverlapping(date: string, exceptMe = '') {
    const closedIntervals = this.getClosedIntervals();
    if (this.list.length === 0 || closedIntervals.length === 0) return false;

    return closedIntervals
      .filter((item) => {
        if (exceptMe) return item.key !== exceptMe;

        return item;
      })
      .some(
        (interval) =>
          moment(date).isBetween(interval.startTime, interval.endTime) ||
          moment(date).isSame(interval.startTime) ||
          moment(date).isSame(interval.endTime),
      );
  }

  static dayAtMidnight(day?: string) {
    let midnight;
    if (day) {
      midnight = moment(day).startOf('day');
    } else {
      midnight = moment().startOf('day');
    }
    return midnight.format();
  }

  insertEmptyRange(alumno: Alumno) {
    this.list.push(
      new ExceptuadosDateRangeV3(
        DateRangeListV3.dayAtMidnight(),
        alumno,
        DateRangeListV3.dayAtMidnight(),
        undefined,
        { idMotivoExceptuado: 0, descripcion: 'Seleccioná un motivo' },
      ),
    );
  }

  fillOne(alumno: Alumno) {
    this.list.push(
      new ExceptuadosDateRangeV3(
        DateRangeListV3.dayAtMidnight(),
        alumno,
        DateRangeListV3.dayAtMidnight(),
      ),
    );
    return this;
  }

  setList(list: Array<ExceptuadosDateRangeV3>) {
    this.list = list;
  }

  getClosedIntervals() {
    return this.list.filter(
      (interval) => interval.startTime && interval.endTime,
    );
  }

  verifyDate(date: string) {
    if (this.isDateOverlapping(date))
      throw new Error('Existe solapación de fechas.');

    this.hasIntervalOpened();

    if (
      this.indexOpenedInterval !== false &&
      this.indexOpenedInterval !== -1 &&
      moment(date)
        .add(this.tz, 'minutes')
        .isBefore(this.list[this.indexOpenedInterval].startTime)
    ) {
      throw new Error('Primero se debe cerrar el intervalo anterior');
    }
  }

  addDate(date: string, alumno: Alumno) {
    this.verifyDate(date);
    if (this.indexOpenedInterval !== false && this.indexOpenedInterval !== -1) {
      const tempExceptuado = new ExceptuadosDateRangeV3(
        this.list[this.indexOpenedInterval].startTime,
        alumno,
        moment(date).add(this.tz, 'minutes').format('YYYY-MM-DD'),
        this.list[this.indexOpenedInterval].idExceptuado,
      );

      const closed = this.getClosedIntervals();
      if (
        closed.some(
          (interval) =>
            moment(interval.startTime).isBetween(
              tempExceptuado.startTime,
              tempExceptuado.endTime,
            ) ||
            moment(interval.endTime).isBetween(
              tempExceptuado.startTime,
              tempExceptuado.endTime,
            ),
        )
      ) {
        throw new Error(
          'Hay intervalos cerrados entre el intervalo que quiere cerrar.',
        );
      }
      this.list[this.indexOpenedInterval] = tempExceptuado;
    } else {
      const tempInterval = new ExceptuadosDateRangeV3(
        moment(date).add(this.tz, 'minutes').format('YYYY-MM-DD'),
        alumno,
      );
      if (this.hasAdjacentIntervals(date))
        tempInterval.endTime = moment(date)
          .add(this.tz, 'minutes')
          .format('YYYY-MM-DD');
      this.list.push(tempInterval);
    }
  }

  hasAdjacentIntervals(date: string) {
    const nextDay = moment(date).add(1, 'days');
    const previousDay = moment(date).subtract(1, 'days');
    const hasRightSide = this.list.some((interval) =>
      moment(interval.startTime).isSame(nextDay),
    );
    const hasLeftSide = this.list.some((interval) =>
      moment(interval.endTime).isSame(previousDay),
    );

    return hasLeftSide && hasRightSide;
  }

  hasIntervalOpened() {
    if (this.list.length === 0) this.indexOpenedInterval = false;

    this.indexOpenedInterval = this.list.findIndex(
      (interval) => interval.endTime === null || interval.endTime === undefined,
    );
  }

  atLeastOneInvalidInterval() {
    return this.list.some((interval) =>
      this.isInvervalOverlapping(interval, interval.key),
    );
  }
}
