import { primary } from 'src/theme/colors';
import { AddStudenFormValue, OptionType, SearchFilter } from './types';
import { makeStyles } from '@material-ui/core';
import { NIVEL } from 'src/commons';

export const optionsTypes: OptionType[] = [
  {
    value: 1,
    label: 'Apellido',
    path: 'apellido',
  },
  {
    value: 2,
    label: 'Documento',
    path: 'documento',
  },
  {
    value: 3,
    label: 'Establecimiento de origen',
    path: 'localizacion',
  },
];

export const initialvalue: SearchFilter = {
  search: '',
  selected: optionsTypes[0],
};

export const addStudenInitialValue: AddStudenFormValue = {
  nombre: '',
  apellido: '',
  pais: 0,
  genero: 0,
  cicloLectivo: 0,
  nivel: 0,
  turno: 0,
  actividad: '',
  tipoDocumento: 0,
  documento: '',
  anio: 0,
  fechaNacimiento: new Date('1991/01/01'),
};

const niveles = [NIVEL.INICIAL, NIVEL.PRIMARIO, NIVEL.SECUNDARIO];

export const filterNivel = {
  nivel: [
    {
      path: 'idNivel',
      value: niveles,
      method: 'in',
    },
  ],
};

export const filterGenero = {
  genero: [
    {
      path: 'idGenero',
      value: 3,
      method: 'notEquals',
    },
  ],
  1: [
    {
      path: 'idGenero',
      value: -1,
      method: 'notEquals',
    },
  ],
};

export const customStyle = {
  container: { padding: '15px' },
  footer: { paddingTop: '15px' },
  buttonBack: { border: '1px solid transparent' },
};

export const addStudentFormStyles = makeStyles(() => ({
  mainContainer: {
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    alignContent: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
  },
  inputContainer: {
    flexDirection: 'row',
    marginTop: 10,
    justifyContent: 'space-between',
  },
  selectContainer: {
    justifyContent: 'space-between',
    '& .MuiOutlinedInput-root': {
      width: '95%',
    },
  },
  customInput: {
    width: '20%',
    backgroundColor: 'red',
  },
  customStyleTitleSelect: {
    fontSize: 12,
    marginBottom: 2,
  },
  buttonContainer: {
    marginTop: 30,
  },
  datePicker: {
    paddingLeft: 0,
    '& .MuiOutlinedInput-root': {
      border: `1px solid ${primary.white}`,
    },
  },
  labelAsterisk: {
    color: 'red',
  },
  paddingTypography: {
    paddingBottom: 5,
  },
  apellidoSelect: {
    paddingLeft: 13,
    paddingBottom: 5,
  },
  disabledDate: {
    width: '100%',
    padding: 7,
  },
}));
