import { SignUp } from 'src/screens/Private/SignUp';
import { MenuItem } from '../../../../commons/const/ui/menu';

export const routes: MenuItem[] = [
  {
    key: 'signUpMain',
    basePath: '/',
    target: {
      component: SignUp,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'SignUp',
    children: [],
    default: true,
  },
];
