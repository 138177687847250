import React, { useCallback, useState, useEffect } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import { LibbyObject, AnyObject, useDebounce } from 'src/commons';
import { MainInput, SimpleSelect } from 'src/commons/components';
import { TableSelectStudent } from './TableSelectStudent';
import { selectColumns } from '../../../../../utils/const';

const headerStyles = makeStyles(() => ({
  containerTable: {
    padding: '24px',
    paddingTop: '0px',
  },
}));

const SelectStudentRaw = ({
  setSelectStudent,
  selectStudent,
  filterById,
  cicloLectivo,
}: LibbyObject) => {
  const [formValues, setFormValues] = useState({
    select: '1',
    params: '',
    path: 'alumno.persona.apellido',
  });

  const [search, setSearch] = useState('');

  const searchDevounced = useDebounce(search, 500);

  useEffect(() => {
    setFormValues({
      ...formValues,
      params: searchDevounced,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchDevounced]);

  const handleSelectStudent = useCallback(
    (student: AnyObject) => {
      setSelectStudent(student);
    },
    [setSelectStudent],
  );

  const handleChangeSelect = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    const pathValue: any = selectColumns.filter(
      ({ value }: any) => value === target.value,
    );
    setFormValues({
      ...formValues,
      select: target.value,
      path: pathValue.length && pathValue[0].path,
    });
  };

  const handleChangeParams = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setSearch(target.value);
  };

  const classes = headerStyles();

  return (
    <Grid container className={classes.containerTable} spacing={2}>
      <Grid item xs={4}>
        <SimpleSelect
          placeholder=""
          labelKey="label"
          valueKey="value"
          handleChange={handleChangeSelect}
          value={formValues.select}
          name="column"
          content={selectColumns}
        />
      </Grid>
      <Grid item xs={8}>
        <MainInput
          type="search"
          placeholder=""
          name="search"
          handleChange={handleChangeParams}
          value={search}
          fullWidth
          customStyle={{ padding: 9.5 }}
        />
      </Grid>
      <Grid item xs={12}>
        {formValues.params && (
          <TableSelectStudent
            filterById={filterById}
            formValues={formValues}
            handleSelectStudent={handleSelectStudent}
            selectStudent={selectStudent}
            cicloLectivo={cicloLectivo}
          />
        )}
      </Grid>
    </Grid>
  );
};

export const SelectStudent = DatabaseConnector(SelectStudentRaw)(
  'alumno',
  'alumno_movimiento',
  'inscripcion_alumno',
  'solicitud_pases',
);
