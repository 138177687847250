import { Button, Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  useAlumnoDAO,
  useAlumnoExisteDAO,
  useAlumnoExisteGetRequest,
} from 'src/app/business';
import { CicloLectivo } from 'src/app/models';
import {
  InfoTable,
  Loading,
  alumnEnrolledStatus,
  useDebounce,
} from 'src/commons';
import {
  AlumnoExistsResponse,
  DataSearchSanatized,
} from 'src/platform/libby/dao';
import { useAddStudenColumns } from './useAddStudenColumns';
import { AddStudenFormValue, SearchFilter } from './types';
import { AddStudentForm } from './AddStudentForm';
import {
  AlumnAdd,
  AlumnExistResponse,
  RecuperaAlumno,
} from '../EnrolledAddStudentModal';
import customFormDialog from 'src/commons/services/customFormDialog';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useSnackbar } from 'notistack';

interface AddStudenBodyProps {
  filter: SearchFilter;
  localizacionId: string;
  cicloLectivo?: CicloLectivo;
  nivelId: string;
  isCommonSchool?: boolean;
  reFetch?: () => void;
  toLegajoAlumno: (id: string | number) => void;
}

export const AddStudentBody = ({
  filter,
  cicloLectivo,
  localizacionId,
  nivelId,
  isCommonSchool,
  reFetch,
  toLegajoAlumno,
}: AddStudenBodyProps) => {
  const [loading, setLoading] = useState(false);
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('idAlumno');
  const [selected, setSelected] = useState<DataSearchSanatized | null>(null);
  const searchDebounced = useDebounce(filter.search, 1000);
  const [reinscribiendo, setReinscribiendo] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const alumnoDAO = useAlumnoDAO();
  const alumnoExisteDAO = useAlumnoExisteDAO();

  const { data, working, request } =
    useAlumnoExisteGetRequest<AlumnoExistsResponse>({
      autoCall: false,
      url: `/api/public/alumnoexiste?${filter.selected.path}=${searchDebounced}`,
    });

  useEffect(() => {
    if (searchDebounced === '') return;
    request();
  }, [request, searchDebounced]);

  const rows = useMemo<DataSearchSanatized[]>(() => {
    const _rows: DataSearchSanatized[] = [];
    if (data?.alumnos) {
      _rows.push(
        ...data.alumnos.map((r, i, all) => {
          const hasDuplicate = all.some(
            (a) =>
              a.idAlumno !== r.idAlumno &&
              a.apellido === r.apellido &&
              a.nombre === r.nombre &&
              a.documento === r.documento,
          );
          return { ...r, isDuplicado: hasDuplicate };
        }),
      );
    }

    return _.orderBy(_rows, orderBy, direction);
  }, [data?.alumnos, direction, orderBy]);

  const columns = useAddStudenColumns({
    selected,
    setSelected,
    cicloLectivo: cicloLectivo || null,
    localizacionId,
    nivelId,
  });

  const handleRequestSort = useCallback(
    (newOrderBy: string, newDirection: 'asc' | 'desc') => {
      setOrderBy(newOrderBy);
      setDirection(newDirection);
    },
    [setOrderBy, setDirection],
  );

  const handleSubmit = async (values: AddStudenFormValue) => {
    try {
      setLoading(true);
      const isInscripto =
        (selected?.inscripcion?.anio || 0) >= (cicloLectivo?.anio || 0);
      // Parsiando la data para los endpoint actuales
      const toSave: RecuperaAlumno | AlumnAdd = {
        name: values.nombre,
        lastName: values.apellido,
        country: values.pais,
        genre: values.genero,
        cicloLectivo: values.cicloLectivo,
        selectLevel: values.nivel.toString(),
        turn: values.turno.toString(),
        activity: values.actividad,
        typeDocument: values.tipoDocumento,
        numDocument: values.documento,
        idAnio: values.anio.toString(),
        date: values.fechaNacimiento,
        localizacionPrevia: isInscripto ? selected?.inscripcion?.cueAnexo : '',
      };
      // Si todo esta ok, y podemos inscribirlo , entonces
      // Si tiene inscripcion para el CL del colegio o superior
      // le deleteamos esa inscricpion y le generamos una nueva en el colegio
      // Si no tiene o es vieja generamos una nueva

      // Si es alumno nuevo simplemente generamos
      // La persona, el alumno, y la inscripcion
      //
      if (selected) {
        // const alumnoData = await alumnoDAO.getById(selected.idAlumno);
        const alumnoExisteResponse: AlumnExistResponse =
          await alumnoExisteDAO.recuperaAlumno({
            ...toSave,
            idAlumno: selected.idAlumno.toString(),
            localizacionId,
            isMatriculado: false,
          });
        if (alumnoExisteResponse.existError) {
          throw new Error('Se ha producido un error al dar de alta el alumno');
        }
        reFetch && reFetch();
        customFormDialog.handleCancel();
      } else {
        const alumnoExisteResponse: AlumnExistResponse =
          await alumnoExisteDAO.validate({
            ...toSave,
            localizacionId,
          });

        const alumnoData = await alumnoDAO.getById(
          alumnoExisteResponse.idAlumno,
        );

        if (alumnoExisteResponse.isExists) {
          if (alumnoExisteResponse.isDeleted) {
            // alumno exists and is deleted
            const confirm = await confirmDialog.show({
              title: 'Confirmación alta alumno',
              confirmText: 'Confirmar',
              cancelText: 'Cancelar',
              content: `El alumno se encuentra inscripto y dado de baja. ¿Desea reinscribirlo y darlo de alta?`,
            });
            if (confirm) {
              const alumnoExisteResponseRecupera: AlumnExistResponse =
                await alumnoExisteDAO.recuperaAlumno({
                  ...toSave,
                  localizacionId,
                  idAlumno: alumnoExisteResponse.idAlumno.toString(),
                  localizacionPrevia: alumnoExisteResponse.idLocalizacion,
                  isMatriculado:
                    alumnoData?.idEstadoMatricula ===
                    alumnEnrolledStatus.MATRICULADO,
                });

              if (alumnoExisteResponseRecupera.existError) {
                throw new Error(
                  'Se ha producido un error al dar de alta el alumno',
                );
              }
            }
          } else {
            // alumno exists then throw and error
            throw new Error(alumnoExisteResponse.message);
          }
        }

        if (alumnoExisteResponse.existError) {
          throw new Error('Se ha producido un error al agregar el alumno');
        }

        setLoading(false);
        !alumnoExisteResponse.isDeleted &&
          toLegajoAlumno(alumnoExisteResponse.idAlumno);
        alumnoExisteResponse.isDeleted && reFetch && reFetch();
        customFormDialog.handleCancel();
      }
      enqueueSnackbar('Se agrego al estdiante correctamente!', {
        variant: 'success',
      });
    } catch (e) {
      const err = e as any;
      enqueueSnackbar(err.message, { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  if (working || loading) {
    return <Loading />;
  }

  if (searchDebounced === '') {
    return (
      <Grid container justifyContent="center">
        <Typography variant="h4">Realice una búsqueda</Typography>
      </Grid>
    );
  }

  if (rows.length === 0 && !working) {
    return (
      <Grid container>
        <Typography variant="h4">
          El alumno ingresado no se encuentra en la base de datos. Por favor,
          completar los siguientes campos para darlo de alta:
        </Typography>
        <AddStudentForm
          idLocalizacion={localizacionId}
          isCommonSchool={isCommonSchool}
          cicloLectivo={cicloLectivo!}
          handleSubmit={handleSubmit}
        />
      </Grid>
    );
  }

  if (reinscribiendo) {
    const _initials: AddStudenFormValue = {
      nombre: selected?.nombre || '',
      apellido: selected?.apellido || '',
      pais: selected?.idPaisNacimiento || 0,
      genero: selected?.idGenero || 0,
      cicloLectivo: cicloLectivo?.idCicloLectivo || 0,
      nivel: Number(nivelId || 0),
      turno: 0,
      actividad: '',
      tipoDocumento: selected?.idTipoDocumento || 0,
      documento: selected?.documento || '',
      anio: 0,
      fechaNacimiento: selected?.fechaNacimiento
        ? new Date(selected?.fechaNacimiento)
        : new Date('1990/01/01'),
    };
    return (
      <Grid container>
        <AddStudentForm
          idLocalizacion={localizacionId}
          isCommonSchool={isCommonSchool}
          cicloLectivo={cicloLectivo!}
          initialValue={_initials}
          handleSubmit={handleSubmit}
        />
      </Grid>
    );
  }

  return (
    <Grid container>
      <Typography variant="h4">Seleccione estudiante a reinscribir.</Typography>
      <InfoTable
        working={working}
        rows={rows}
        columns={columns}
        orderBy={orderBy}
        direction={direction}
        onSortChange={handleRequestSort}
        rowIdKey="idAlumno"
      />
      <Grid item container>
        <Button
          disabled={!selected}
          onClick={() => {
            setReinscribiendo(true);
          }}
        >
          Reinscribir
        </Button>
      </Grid>
    </Grid>
  );
};
