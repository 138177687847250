import { useMemo } from 'react';
import { useEspacioCurricularCustomLibbyFetch } from 'src/app/business';
import { useRolesContext } from 'src/context/RolesContext';
import { useArealInterarealContext } from '../../context';

export const useEspaciosCurricularesCustomFetch = () => {
  const { areas, idAnio, idPlanEstudio } = useArealInterarealContext();
  const { selectedRole } = useRolesContext();

  const paramsfetchEspaciosCurriculares = useMemo(
    () => ({
      filter: {
        areas: areas?.map((area) => ({
          path: 'materia.area',
          value: area.idArea,
        })),
        localizacion: [
          {
            path: 'localizacion',
            value: selectedRole.localizacionId,
          },
        ],
        anio: [
          {
            path: 'materia.anio',
            value: idAnio,
          },
        ],
        planEstudio: [
          {
            path: 'planEstudio',
            value: idPlanEstudio,
          },
        ],
      },
      limit: 20000,
    }),
    [areas, selectedRole, idAnio, idPlanEstudio],
  );

  const { data: espaciosCurricularesCustom = [], working } =
    useEspacioCurricularCustomLibbyFetch(paramsfetchEspaciosCurriculares);

  return { espaciosCurricularesCustom, working };
};
