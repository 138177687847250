import React from 'react';
import { Card, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  root: {
    width: 308,
    height: 137,
    marginRight: 20,
    marginTop: 20,
    marginBottom: 20,
  },
});

export interface CardItemContainerProps {
  children: JSX.Element | JSX.Element[];
}

export const CardItemContainer = ({ children }: CardItemContainerProps) => {
  const classes = useStyles();
  return <Card className={classes.root}>{children}</Card>;
};
