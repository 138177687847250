import { AnyObject } from 'src/commons';

export const SET_SECTION = 'SET_SECTION';
export const SET_CICLO = 'SET_CICLO';

export const setSection = (section: AnyObject) => ({
  type: SET_SECTION,
  payload: { section },
});

export const setCiclo = (ciclo: string) => ({
  type: SET_CICLO,
  payload: { ciclo },
});
