import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { EspaciosAreas } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'espacios_areas';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEspaciosAreas',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEspaciosAreas',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EspaciosAreas
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EspaciosAreas,
      hoc: { ...defaultOptions.hoc, ...businessOptions.EspaciosAreas.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EspaciosAreas>(DAO_NAME, optionsToUse);

// hooks
export const useEspaciosAreasFetchById = fetchById;
export const useEspaciosAreasLibbyCall = libbyCall;
export const useEspaciosAreasDAO = libbyDAO;
export const useEspaciosAreasLibbyFetch = libbyFetch;

// components/hoc
export const EspaciosAreasCall = renderPropCall;
export const EspaciosAreasById = renderPropFetchById;
export const EspaciosAreasSelect = simpleSelect;
export const EspaciosAreasAccordionSelector = accordionSelector;
export const EspaciosAreasSelectedChips = selectedChips;

// context
// ByIdContext
export const useEspaciosAreasByIdContext = useByIdContext;
export const EspaciosAreasByIdProvider = ByIdProvider;
export const EspaciosAreasByIdContext = ByIdContext;
// GeneralContext
export const useEspaciosAreasContext = useGeneralContext;
export const EspaciosAreasProvider = GeneralProvider;
export const EspaciosAreasContext = GeneralContext;
