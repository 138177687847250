import { Grid, Typography } from '@material-ui/core';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import React, { useCallback, useMemo, useState } from 'react';
import { Footer, Loading } from 'src/commons';
import { CalificacionesFiltersHeader } from '../../components/CalificacionesFiltersHeader';
import {
  useExcelData,
  useCalificacionesSwitch,
  validateLeave,
} from '../../hooks';
import { useCalificacionesContext } from '../../context';
import { useInformescDAO } from 'src/app/business';
import { AlertMessage } from 'src/screens/Private/Calificaciones/components';
import { useHistory } from 'react-router';
import { useRolesContext } from 'src/context/RolesContext';
import { useReadyToClose } from './hooks/useReadyToClose';
import InformeCuatrimestralSegundoBimestre from './components/InformeCuatrimestralSegundoBimestre';
import InformeCuatrimestralCuartoBimestre from './components/InformeCuatrimestralCuartoBimestre';
import { useInformeCuatrimestralCuartoBimestreValidations } from './hooks/useInformeCuatrimestralValidations';
import { useInformeConfig } from './hooks/useInformeConfig';
import { useColegioEfectivizadoContext } from '../../context/EfectivizacionProvider';
import { useValidatorInformes } from './hooks/useValidatorInformes';
import { useCalificacionesInicialSubmit } from './hooks/useCalificacionesInicialSubmit';
import { ButtonConfig } from 'src/screens/Private/Presentismo/functions/buttonconfig';
import { useInformeStyles } from '../styles/informe';
import { Anio, InformescCustom, Periodo, Seccion } from 'src/app/models';

const InformeCuatrimestralRaw = ({
  validYears,
  working: validYearsWorking,
}: {
  working: boolean;
  validYears: {
    anio: {
      path: string;
      value: unknown;
    }[];
  };
}) => {
  const classes = useInformeStyles();
  const history = useHistory();
  const [rows, setRows] = useState<InformescCustom[]>([]);
  const [editedRows, setEditedRows] = useState<InformescCustom[]>([]);
  const { filter, setFilter } = useCalificacionesContext();
  const [seccionSelect, setSeccionSelect] = useState<string | undefined>('');
  const [periodoSelect, setPeriodoSelect] = useState<string | undefined>('');
  const [anioSelect, setAnioSelect] = useState<string | undefined>('');
  const [espacioSeccionSelect] = useState('');
  const readyToClose = useReadyToClose(editedRows, rows);
  const { isReadyToSave } = useInformeCuatrimestralCuartoBimestreValidations();
  const [reloadTable, setReloadTable] = useState(false);
  const informescDAO = useInformescDAO();
  const {
    selectedRole: { localizacionId, rol },
  } = useRolesContext();

  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();

  const isClosed = useMemo(
    () => rows.some((row) => row?.nota?.abierto === false),
    [rows],
  );

  const hasCalifications = useMemo(
    () => rows.some((obj) => Object.keys(obj).includes('nota')),
    [rows],
  );

  const informesConfig = useInformeConfig(
    establecimientoEfectivizado,
    filter.anio,
    filter.periodo,
  );

  const {
    config: {
      PERIODO,
      AUTHORIZED_TO_OPEN,
      indicadores,
      requiredFieldsToValidate,
    },
  } = informesConfig;

  const isAuthorizedToOpen = rol in AUTHORIZED_TO_OPEN;

  const onDataChange = (
    oldData: InformescCustom[],
    newData: InformescCustom[],
  ) => {
    setRows(oldData);
    setEditedRows(newData);
  };

  const handleAnioChange = (options: Anio[]) => {
    const anio = options?.find((s) => s.idAnio === filter.anio);
    setAnioSelect(anio?.descripcionAnio);
  };

  const handleSeccionesChange = (options: Seccion[]) => {
    const seccion = options?.find((s) => s.idSeccion === filter.seccion);
    setSeccionSelect(seccion?.nombreSeccion);
  };

  const handlePeriodosChange = (options: Periodo[]) => {
    const periodo = options?.find((p) => p.idPeriodo === filter.periodo);
    setPeriodoSelect(periodo?.tipoPeriodo.descripcionTipoPeriodo);
  };

  const { incompletedRows, isDisabled, isNotReadyToSubmit } =
    useValidatorInformes<InformescCustom>({
      fieldsToValidate: requiredFieldsToValidate,
      editedData: editedRows,
      originalData: rows,
    });

  const showView = useMemo(
    () => filter.anio && filter.seccion && filter.periodo,
    [filter],
  );

  const { onSubmit, submitting } = useCalificacionesInicialSubmit({
    columns: indicadores,
    editedRows: editedRows,
    originalRows: rows,
    seccionId: filter.seccion,
    dao: informescDAO,
    reFetch: () => {
      setReloadTable(!reloadTable);
    },
  });

  const { onSwitch, switchSubmitting } = useCalificacionesSwitch({
    filter: filter,
    reFetch: () => {
      setReloadTable(!reloadTable);
    },
    asunto: 'Informes',
  });

  const { excelData } = useExcelData(
    [
      {
        id: 'alumno',
        label: 'Alumno',
        orderInTable: 0,
        hideSortIcon: true,
        orderById: '',
        tipoIndicador: 'texto_modal',
        width: '10%',
      },
      ...indicadores,
    ],
    rows,
    `Calificaciones - Informe cuatrimestral - ${periodoSelect} - ${seccionSelect} - ${localizacionId}`,
    periodoSelect,
    anioSelect,
    seccionSelect,
    espacioSeccionSelect,
  );

  const handleLeave = useCallback(async () => {
    const canLeave = await validateLeave(
      incompletedRows,
      readyToClose,
      showView,
    );
    if (canLeave) history.push('/');
  }, [history, incompletedRows, readyToClose, showView]);

  const buttonConfig = useMemo(() => {
    const btnSalir = new ButtonConfig('Salir', 'secondary');
    const btnGuardar = new ButtonConfig('Guardar');

    btnSalir.setClickHandler(handleLeave);
    btnGuardar.setClickHandler(onSubmit);

    btnGuardar.disabledFor(
      isDisabled ||
        isClosed ||
        isNotReadyToSubmit ||
        !isReadyToSave(requiredFieldsToValidate, editedRows),
    );

    const defaultButtons = [btnSalir, btnGuardar];

    if (!isClosed) {
      const btnCerrar = new ButtonConfig('Cerrar informes');
      btnCerrar.setClickHandler(() => onSwitch('close'));
      btnCerrar.disabledFor(
        isDisabled || !readyToClose || isClosed || !hasCalifications,
      );
      defaultButtons.push(btnCerrar);
    } else if (isAuthorizedToOpen) {
      const btnAbrir = new ButtonConfig('Abrir informes');
      btnAbrir.setClickHandler(() => onSwitch('open'));
      defaultButtons.push(btnAbrir);
    }
    return defaultButtons;
  }, [
    handleLeave,
    onSubmit,
    isDisabled,
    isClosed,
    isNotReadyToSubmit,
    isReadyToSave,
    requiredFieldsToValidate,
    editedRows,
    isAuthorizedToOpen,
    readyToClose,
    hasCalifications,
    onSwitch,
  ]);
  return (
    <>
      <AlertMessage
        incompletedRows={incompletedRows}
        isDisabled={isDisabled}
        isClosed={isClosed}
        working={false}
        showView={showView}
        showEfectivizacionAlert={false}
      />
      {validYearsWorking ? (
        <Loading />
      ) : (
        <>
          <CalificacionesFiltersHeader
            data={editedRows}
            rows={rows}
            filter={filter}
            setFilter={setFilter}
            excelData={excelData}
            handleSeccionesChange={handleSeccionesChange}
            handlePeriodosChange={handlePeriodosChange}
            handleAnioChange={handleAnioChange}
            showView={showView}
            incompletedRows={incompletedRows}
            readyToClose={readyToClose}
            anioCustomFilter={{ anio: validYears?.anio }}
          />
          {showView ? (
            <>
              {filter.periodo === PERIODO.SEGUNDO_BIMESTRE &&
              !establecimientoEfectivizado ? (
                <InformeCuatrimestralSegundoBimestre
                  reloadTable={reloadTable}
                  onDataChange={onDataChange}
                  establecimientoEfectivizado={establecimientoEfectivizado}
                  config={{
                    indicadores,
                  }}
                />
              ) : (
                <InformeCuatrimestralCuartoBimestre
                  reloadTable={reloadTable}
                  onDataChange={onDataChange}
                  establecimientoEfectivizado={establecimientoEfectivizado}
                  config={{
                    indicadores,
                  }}
                />
              )}
              <Grid className={classes.footer}>
                <Footer
                  buttonConfig={buttonConfig}
                  loading={submitting || switchSubmitting}
                />
              </Grid>
            </>
          ) : (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: '400px' }}
            >
              <Typography
                style={{
                  fontSize: '40px',
                  marginTop: '50px',
                  color: 'gray',
                  fontWeight: 'bold',
                  lineHeight: '45px',
                }}
              >
                Seleccione año, sección y período para calificar.
              </Typography>
            </Grid>
          )}
        </>
      )}
    </>
  );
};

export const InformeCuatrimestral = DatabaseConnector(InformeCuatrimestralRaw)(
  'alumno_movimiento',
  'informesc',
);
