import { SectionWithProyeccionDAO } from 'src/platform/libby/dao';
import { makeBusinessEntity } from '../../../lib/libby/generator';
import { SeccionWithProyeccion } from '../../models';

const DAO_NAME = 'seccion_with_proyeccion';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
} = makeBusinessEntity<SeccionWithProyeccion, SectionWithProyeccionDAO>(
  DAO_NAME,
);

// hooks
export const useSeccionWithProyeccionFetchById = fetchById;
export const useSeccionWithProyeccionLibbyCall = libbyCall;
export const useSeccionWithProyeccionDAO = libbyDAO;
export const useSeccionWithProyeccionLibbyFetch = libbyFetch;
