import React, { useMemo, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { permissionsStyles } from './styles/main';
import { RolAutocomplete } from 'src/app/business';
import { EndpointAutoComplete } from 'src/app/business/security/Endpoint';
import { PermissionAutoComplete } from 'src/app/business/security/Permissions';
import { EndpointMethodAutoComplete } from 'src/app/business/security/Method';
import { Method } from 'src/app/models/security/Method';
import { Endpoint } from 'src/app/models/security/Endpoint';
import { Permission } from 'src/app/models/security/Permission';
import { usePermissionEndpointMethodDAO } from 'src/app/business/security/PermissionEndpointMethod';
import { useSnackbar } from 'notistack';
import { Loading, MainButton } from 'src/commons';
import { Rol } from 'src/app/models';

export default function PermissionsAssign() {
  const [permission, setPermission] = useState<Permission>({
    permissionId: 0,
    description: '',
    module: {
      moduleId: 0,
      name: '',
      description: '',
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const [rol, setRol] = useState<Rol>({
    idRolUsuario: 0,
    descripcion: '',
  });
  const [endpoint, setEndpoint] = useState<Endpoint>({
    endpointId: 0,
    endpointName: '',
  });

  const [method, setMethod] = useState<Method>({
    methodId: 0,
    methodName: '',
  });

  const dao = usePermissionEndpointMethodDAO();

  const { enqueueSnackbar } = useSnackbar();
  const permissionsClasses = permissionsStyles();

  const rolFilter = useMemo(
    () => ({
      noRol: [
        {
          path: 'descripcion',
          value: 'no_rol',
          method: 'notEquals',
        },
      ],
    }),
    [],
  );
  const submit = async () => {
    try {
      setIsLoading(true);
      const response = await dao.save({
        endpoint,
        method,
        permission,
        rol,
      });
      console.info(response);
      enqueueSnackbar('Permiso asignado correctamente', {
        variant: 'success',
      });
      setIsLoading(false);
    } catch (error) {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
      setIsLoading(false);
    }
  };
  return (
    <Grid
      container
      spacing={2}
      classes={{
        root: permissionsClasses.formContainer,
      }}
    >
      <Grid item xs={12}>
        <Typography
          classes={{
            root: permissionsClasses.autoCompleteStyle,
          }}
        >
          Rol
        </Typography>
        <RolAutocomplete
          filter={rolFilter}
          value={rol}
          onChange={(rol) => setRol(rol as Rol)}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          classes={{
            root: permissionsClasses.autoCompleteStyle,
          }}
        >
          Permiso
        </Typography>
        <PermissionAutoComplete
          value={permission}
          onChange={(permission) => setPermission(permission as Permission)}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          classes={{
            root: permissionsClasses.autoCompleteStyle,
          }}
        >
          Endpoint
        </Typography>
        <EndpointAutoComplete
          value={endpoint}
          onChange={(endpoint) => setEndpoint(endpoint as Endpoint)}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography
          classes={{
            root: permissionsClasses.autoCompleteStyle,
          }}
        >
          Método HTTP
        </Typography>
        <EndpointMethodAutoComplete
          value={method}
          onChange={(method) => setMethod(method as Method)}
        />
      </Grid>
      <Grid item xs={12}>
        {isLoading ? (
          <Loading />
        ) : (
          <MainButton
            handleOnClick={(e) => submit()}
            title="Guardar"
            customStyle={{
              alignSelf: 'end',
            }}
          />
        )}
      </Grid>
    </Grid>
  );
}
