import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Proceso } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'procesos';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idProcesos',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idProcesos',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Procesos
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Procesos,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Procesos.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Proceso>(DAO_NAME, optionsToUse);

// hooks
export const useProcesosFetchById = fetchById;
export const useProcesosLibbyCall = libbyCall;
export const useProcesosDAO = libbyDAO;
export const useProcesosLibbyFetch = libbyFetch;

// components/hoc
export const ProcesosAutocomplete = entityAutocomplete;
export const ProcesosCall = renderPropCall;
export const ProcesosById = renderPropFetchById;
export const ProcesosSelect = simpleSelect;
export const ProcesosAccordionSelector = accordionSelector;
export const ProcesosSelectedChips = selectedChips;

// context
// ByIdContext
export const useProcesosByIdContext = useByIdContext;
export const ProcesosByIdProvider = ByIdProvider;
export const ProcesosByIdContext = ByIdContext;
// GeneralContext
export const useProcesosContext = useGeneralContext;
export const ProcesosProvider = GeneralProvider;
export const ProcesosContext = GeneralContext;
