import { useMemo } from 'react';
import { useJeIndicadoresLibbyFetch } from 'src/app/business/Calificaciones/JeIndicadores';

export const useGetJEColumns = (idJEm: string) => {
  const indFilter = useMemo(
    () => ({ 0: [{ path: 'jemateria', value: idJEm }] }),
    [idJEm],
  );

  const { data: indicadoresEvaluacion = [], working: indicadoresWorking } =
    useJeIndicadoresLibbyFetch({
      filter: indFilter,
      enabled: Boolean(idJEm),
      checkDuplicationDeep: true,
    });

  const columns = useMemo(
    () => indicadoresEvaluacion.map((indicador: any) => indicador.pregunta),
    [indicadoresEvaluacion],
  );
  return {
    columns,
    working: indicadoresWorking,
  };
};
