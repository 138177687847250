import { MotivoDAO } from 'src/platform/libby/dao/Presentismov2';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { Motivo } from '../../models/presentismo_v2';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'motivo';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { renderPropCall, renderPropFetchById, simpleSelect },
} = makeBusinessEntity<Motivo, MotivoDAO>(DAO_NAME, {});

// hooks
export const useMotivoFetchById = fetchById;
export const useMotivoLibbyCall = libbyCall;
export const useMotivoDAO = libbyDAO;
export const useMotivoLibbyFetch = libbyFetch;

// components/hoc
export const MotivoCall = renderPropCall;
export const MotivoById = renderPropFetchById;
export const MotivoSelect = simpleSelect;
