import { Preview } from 'src/screens/Private/Preview';
import { MenuItem } from '../../../../commons/const/ui/menu';

export const routes: MenuItem[] = [
  {
    key: 'pdf',
    basePath: '/',
    target: {
      component: Preview,
      path: '/:idPreview',
    },
    enabled: true,
    icon: 'List',
    text: 'Visualizar Pdf',
    children: [],
    default: true,
  },
];
