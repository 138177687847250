import theme from '../../../../../../theme';
import {
  ContenidosEspecificoForm,
  ContenidosEspecificosFormValue,
} from './ContenidosEspecificoForm';
import { FormDialog } from '../../../../../../commons/services/dialogs';

export const ContenidosEspecificosDialog = FormDialog.create<
  ContenidosEspecificosFormValue[]
>({
  customTheme: theme,
  component: ContenidosEspecificoForm,
  title: 'Contenidos Especificos',
  maxWidth: 'md',
});
