import { LibbyFetchDAO } from './LibbyFetchDAO';

export class FeedbackPlanSeccionDAO extends LibbyFetchDAO {
  constructor() {
    super('feedback_plan_seccion', 'idFeedbackPlanSeccion');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getActiveItems() {
    return this.query().equals('items.active', true).run();
  }
}
