import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { TipoVinculo } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'tipo_vinculo';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idTipoVinculo',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idTipoVinculo',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.TipoVinculo
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.TipoVinculo,
      hoc: { ...defaultOptions.hoc, ...businessOptions.TipoVinculo.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<TipoVinculo>(DAO_NAME, optionsToUse);

// hooks
export const useTipoVinculoFetchById = fetchById;
export const useTipoVinculoLibbyCall = libbyCall;
export const useTipoVinculoDAO = libbyDAO;
export const useTipoVinculoLibbyFetch = libbyFetch;

// components/hoc
export const TipoVinculoAutocomplete = entityAutocomplete;
export const TipoVinculoCall = renderPropCall;
export const TipoVinculoById = renderPropFetchById;
export const TipoVinculoSelect = simpleSelect;
export const TipoVinculoAccordionSelector = accordionSelector;
export const TipoVinculoSelectedChips = selectedChips;

// context
// ByIdContext
export const useTipoVinculoByIdContext = useByIdContext;
export const TipoVinculoByIdProvider = ByIdProvider;
export const TipoVinculoByIdContext = ByIdContext;
// GeneralContext
export const useTipoVinculoContext = useGeneralContext;
export const TipoVinculoProvider = GeneralProvider;
export const TipoVinculoContext = GeneralContext;
