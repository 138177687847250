import React from 'react';
import { Content } from '../../../../../../../../commons/components/TabBar';
import { InformacionGeneral } from './InformacionGeneral';
import { Proyecto } from './Proyecto';
import { ObjetivosAprendizaje } from './ObjetivosAprendizaje';
import { Actividad } from './Actividad';
import { IndicadoresDeEvaluacion } from './IndicadoresDeEvaluacion';
import { Bibliografía } from './Bibliografía';
import { EspacioCurricular } from './EspacioCurricular';

export const tabs: Content[] = [
  {
    title: 'Información General',
    component: <InformacionGeneral />,
  },
  {
    title: 'Proyecto',
    component: <Proyecto />,
  },
  {
    title: 'Objetivos de Aprendizaje',
    component: <ObjetivosAprendizaje />,
  },
  {
    title: 'Espacio Curricular',
    component: <EspacioCurricular />,
  },
  {
    title: 'Actividad',
    component: <Actividad />,
  },
  {
    title: 'Indicadores de Evaluación',
    component: <IndicadoresDeEvaluacion />,
  },
  {
    title: 'Bibliografía',
    component: <Bibliografía />,
  },
];
