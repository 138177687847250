import React from 'react';
import { AutoevaluacionSlider } from './AutoevaluacionSlider';
import { Grid, Typography } from '@material-ui/core';
import { useEstudianteContext } from '../../contexts/EstudianteContext';
import { useApreciacionFormContext } from '../../hooks/useApreciacionFormContext';
import { CriterioEvaluacion } from 'src/app/models';
import { FormInputV2 } from 'src/lib/templates';
import { ButtonTypesProps, Footer, Loading } from 'src/commons';
import { useApreciacionStyles } from '../../styles/apreciacionStyles';

export const Apreciacion = () => {
  const { criterios } = useEstudianteContext();
  const { values, form, submitting, handleSubmit, errors } =
    useApreciacionFormContext();

  const classes = useApreciacionStyles();

  const sliderChangeHandler = (
    criterio: CriterioEvaluacion,
    newValue: number,
  ) => {
    const evaluaciones = [...values.evaluaciones];
    const evaluacionIndex = values.evaluaciones.findIndex(
      (evaluacion) =>
        evaluacion.criterioEvaluacion.idCriterioEvaluacion ===
        criterio.idCriterioEvaluacion,
    );

    if (evaluacionIndex > -1) {
      evaluaciones[evaluacionIndex].valor = newValue;
    } else {
      evaluaciones.push({
        valor: newValue,
        criterioEvaluacion: criterio,
      });
    }
    form.change('evaluaciones', evaluaciones);
  };

  const footerButtons: ButtonTypesProps[] = [
    {
      title: 'Guardar Apreciación',
      disabled: submitting || form.getState().invalid,
      handleOnClick: () => handleSubmit(),
    },
  ];

  return submitting ? (
    <Loading />
  ) : (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: '50px',
        marginLeft: '50px',
      }}
    >
      <Grid container>
        <Grid item xs={12} lg={7}>
          {criterios
            .sort((a, b) =>
              a.idCriterioEvaluacion > b.idCriterioEvaluacion ? 1 : -1,
            )
            .map((criterio, index) => (
              <div
                key={criterio.nombre}
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '75%',
                  marginBottom: '16px',
                }}
              >
                <Typography style={{ marginRight: '16px', width: '35%' }}>
                  {criterio.nombre}:
                </Typography>
                <div style={{ width: '100%', height: '50px' }}>
                  <AutoevaluacionSlider
                    value={
                      values.evaluaciones.find(
                        (evaluacion) =>
                          evaluacion.criterioEvaluacion.idCriterioEvaluacion ===
                          criterio.idCriterioEvaluacion,
                      )?.valor ?? 0
                    }
                    onChange={(_, newValue: number) => {
                      sliderChangeHandler(criterio, newValue);
                    }}
                    showTitles={index === 0}
                  />
                </div>
              </div>
            ))}
        </Grid>
      </Grid>
      <Grid
        container
        style={{ marginTop: '100px', display: 'flex', alignItems: 'center' }}
      >
        <Grid item xs={12} style={{ marginBottom: '20px' }}>
          <Grid container style={{ marginBottom: '20px' }}>
            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={{ marginRight: '10px', marginBottom: '60px' }}>
                Observaciones:
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                width: '750px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <FormInputV2
                name="observaciones"
                form={form}
                multiline
                fullWidth
                placeholder="Puedes agregar una observación para ser vista por el docente y el referente"
                minRows={6}
              />
              {Boolean(errors!.observaciones) ? (
                <small>{errors!.observaciones}</small>
              ) : null}
            </Grid>
          </Grid>

          <Grid container>
            <Grid item xs={1} style={{ display: 'flex', alignItems: 'center' }}>
              <Typography style={{ marginRight: '10px', marginBottom: '60px' }}>
                Observaciones sobre el referente de acción :
              </Typography>
            </Grid>
            <Grid
              item
              xs={6}
              style={{
                width: '750px',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <FormInputV2
                name="observacionesReferente"
                form={form}
                multiline
                fullWidth
                placeholder="Puedes agregar una observación que no verá el referente"
                minRows={6}
              />
              {Boolean(errors!.observacionesReferente) ? (
                <small>{errors!.observacionesReferente}</small>
              ) : null}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Footer buttonConfig={footerButtons} spacing={3} />
    </div>
  );
};
