import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import React from 'react';
import { MainInput, SimpleSelect } from 'src/commons';
import { AccionesAsociadasHeaderProps } from '../../types';

const customStyles = {
  searchGrid: { marginTop: 10 },
  descargar: { marginBottom: '4px' },
  containerStyles: { marginTop: 23 },
};

export const AccionesAsociadasHeader = ({
  filters,
  disableGenerateExcel,
  tipoAcciones = [],
  orientaciones = [],
  ciclosLectivos = [],
  handleCreate,
  handleGenerateExcel,
  handleChangeFilter,
}: AccionesAsociadasHeaderProps) => {
  return (
    <>
      <Grid container direction="column">
        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={3}
          style={{ padding: '0 15px 15px', paddingRight: 15, marginTop: 23 }}
        >
          <Grid
            container
            xs={8}
            justifyContent="flex-start"
            spacing={3}
            alignItems="flex-end"
          >
            <Grid item xs={3}>
              <MainInput
                label="Sector"
                type="search"
                customStyleLabel={{ color: '#737373', paddingBottom: 15 }}
                handleChange={() => {}}
                placeholder="Buscar"
                name="search"
                value={filters.sectorName}
                fullWidth
                disabled
              />
            </Grid>

            <Grid item xs={3}>
              <MainInput
                label="Organización"
                type="search"
                customStyleLabel={{ color: '#737373', paddingBottom: 15 }}
                handleChange={() => {}}
                placeholder="Buscar"
                name="search"
                value={filters.organizacionName}
                fullWidth
                disabled
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={3}
          style={{ padding: '0 15px 15px', paddingRight: 15, marginTop: 23 }}
        >
          <Grid
            container
            xs={8}
            justifyContent="flex-start"
            spacing={3}
            alignItems="flex-end"
          >
            <Grid item xs={3}>
              <SimpleSelect
                title="Ciclo Lectivo"
                name="cicloLectivo"
                value={filters.cicloLectivo}
                handleChange={(e) => {
                  const value = e.target?.value ? Number(e.target.value) : 0;
                  handleChangeFilter((prevState) => ({
                    ...prevState,
                    cicloLectivo: value,
                  }));
                }}
                placeholder="Seleccioná ciclo"
                content={ciclosLectivos}
                labelKey="anio"
                valueKey="idCicloLectivo"
              />
            </Grid>

            <Grid item xs={3}>
              <SimpleSelect
                title="Tipo de acción"
                name="tipoAccion"
                value={filters.tipoAccion}
                handleChange={(e) => {
                  const value = e.target?.value ? Number(e.target.value) : 0;
                  handleChangeFilter((prevState) => ({
                    ...prevState,
                    tipoAccion: value,
                  }));
                }}
                placeholder="Seleccioná tipo de accion"
                content={tipoAcciones}
                labelKey="nombre"
                valueKey="idTipoAccion"
              />
            </Grid>

            <Grid item xs={3}>
              <SimpleSelect
                title="Orientaciones"
                name="orientacion"
                value={filters.orientacion}
                handleChange={(e) => {
                  const value = e.target?.value ? Number(e.target.value) : 0;
                  handleChangeFilter((prevState) => ({
                    ...prevState,
                    orientacion: value,
                  }));
                }}
                placeholder="Seleccioná orientación"
                content={orientaciones}
                labelKey="nombre"
                valueKey="idOrientacion"
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          justifyContent="space-between"
          alignItems="flex-end"
          spacing={3}
          style={{
            padding: '0 15px 15px',
            paddingRight: 15,
            marginTop: 23,
            flexWrap: 'nowrap',
          }}
        >
          <Grid
            container
            alignItems="center"
            spacing={3}
            style={{ padding: '0 15px 15px', paddingRight: 15, marginTop: 23 }}
          >
            <Typography>Agregar Acción</Typography>
            <IconButton
              onClick={handleCreate}
              /* onClick={() => {
              setFormValues({
                idAccion: '',
                tipoDeAccion: 0,
                nombre: '',
                accionOrientaciones: [],
                descripcion: '',
                horas: '',
                perfiles: [],
                habilidades: [],
                contenidos: [],
              });
              setIsCreatingAnAccion({
                enabled: true,
                accion: {
                  isCreatingAccion: true,
                  organizacion: accion.organizacion,
                },
                row: null,
                isDisabled: false,
              });
            }} */
            >
              <AddCircleOutlineIcon color="primary" />
            </IconButton>
          </Grid>

          <Grid
            container
            xs={4}
            justifyContent="flex-end"
            spacing={3}
            alignItems="flex-end"
            style={customStyles.searchGrid}
          >
            <Grid item xs>
              <MainInput
                type="search"
                handleChange={(e) =>
                  handleChangeFilter((prev) => ({
                    ...prev,
                    search: e.target?.value?.toString(),
                  }))
                }
                placeholder="Buscar"
                name="search"
                value={filters.search}
                fullWidth
              />
            </Grid>

            <Grid item xs style={customStyles.descargar}>
              <Button
                color="primary"
                variant="contained"
                component="label"
                fullWidth
                onClick={handleGenerateExcel}
                disabled={disableGenerateExcel}
              >
                Descargar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
