import React from 'react';
import { useFormContext } from 'src/lib/templates';
import { Grid } from '@material-ui/core';
import {
  DocenteDropDowns,
  Tutor,
  Coordinador,
  Directivo,
  CoordinadorOrientacion,
} from '../Tablas';

const roleTables: Record<string, JSX.Element> = {
  2: <DocenteDropDowns />,
  17: <DocenteDropDowns isJe={true} />,
  3: <Coordinador />,
  16: <Directivo />,
  13: <CoordinadorOrientacion />,
  4: <Directivo />,
  5: <Directivo />,
  1: <Directivo />,
  8: <Tutor />,
  7: <Tutor />,
};

const SPACING = 4;

export const RolDetailsStep = () => {
  const { values } = useFormContext();
  return (
    <>
      <Grid
        container
        spacing={SPACING}
        direction="column"
        style={{ paddingRight: '5%', paddingLeft: '5%', flex: 1 }}
      >
        {values && roleTables[+values.idRolUsuario]}
      </Grid>
    </>
  );
};
