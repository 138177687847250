import { PresentismoAsistenciaDAO } from 'src/platform/libby/dao';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { PresentismoAsistencia } from '../../models/acap';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'presentismo_asistencia';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPresentismo',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPresentismo',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.PresentismoAsistencia
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.PresentismoAsistencia,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.PresentismoAsistencia.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<PresentismoAsistencia, PresentismoAsistenciaDAO>(
  DAO_NAME,
  optionsToUse,
);

// hooks
export const usePresentismoAsistenciaFetchById = fetchById;
export const usePresentismoAsistenciaLibbyCall = libbyCall;
export const usePresentismoAsistenciaDAO = libbyDAO;
export const usePresentismoAsistenciaLibbyFetch = libbyFetch;

// components/hoc
export const PresentismoAsistenciaAutocomplete = entityAutocomplete;
export const PresentismoAsistenciaCall = renderPropCall;
export const PresentismoAsistenciaById = renderPropFetchById;
export const PresentismoAsistenciaSelect = simpleSelect;
export const PresentismoAsistenciaAccordionSelector = accordionSelector;
export const PresentismoAsistenciaSelectedChips = selectedChips;

// context
// ByIdContext
export const usePresentismoAsistenciaByIdContext = useByIdContext;
export const PresentismoByAsistenciaIdProvider = ByIdProvider;
export const PresentismoByAsistenciaIdContext = ByIdContext;
// GeneralContext
export const usePresentismoAsistenciaContext = useGeneralContext;
export const PresentismoAsistenciaProvider = GeneralProvider;
export const PresentismoAsistenciaContext = GeneralContext;
