export enum DEPENDENCIA_FUNCIONAL {
  DIRECCION_EDUCACION_ADULTO_ADOLESCENTE = 60,
  DIRECCION_EDUCACION_INICIAL = 10,
  DIRECCION_EDUCACION_MEDIA = 31,
  DIRECCION_EDUCACION_PRIMARIA = 20,
  DIRECCION_EDUCACION_ARTISTICA = 70,
  DIRECCION_EDUCACION_ESPECIAL = 50,
  DIRECCION_EDUCACION_TECNICA = 32,
  DIRECCION_ESCUELAS_NORMALES = 41,
  DIRECCION_GRAL_ESCUELA_MAESTROS = 1,
}
