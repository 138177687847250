import React from 'react';
import { ObjetivosOrientaPlan, Objetivos } from 'src/app/models';
import { Grid } from '@material-ui/core';
import { useDisciplinarOrientadaContext } from '../../../forms/PlanificacionDisciplinarOrientada/Context';
import { ListItemProperties } from '../../../feedbacks/commons';

export interface ObjetivosDeAprendizajeProps {
  data: ObjetivosOrientaPlan;
}

export const ObjetivosDeAprendizaje = ({
  data,
}: ObjetivosDeAprendizajeProps) => {
  // const classes = useStyles();
  const {
    data: { objetivos },
    working,
  } = useDisciplinarOrientadaContext();

  const filterObjetivos = data?.objetivosDeAprendizaje?.map(
    (objetivo: Objetivos) => {
      const filterObj = objetivos[objetivo.bloque];
      const objs = filterObj?.values
        ?.filter((valor: any) =>
          objetivo.objetivos?.find(
            (_objetivo: string) => valor.idObjetivoBloque === _objetivo,
          ),
        )
        .map(({ idObjetivoBloque, descripcion }: any) => ({
          id: idObjetivoBloque,
          descripcion,
        }));
      if (objetivo?.adicionales?.length) objs?.push(...objetivo.adicionales);
      return {
        objetivos: objs,
        bloque: filterObj?.bloque,
      };
    },
  );

  return (
    <Grid container direction="column">
      {!working &&
        filterObjetivos.map((objetivo: any) => (
          <ListItemProperties
            title={objetivo?.bloque?.descripcion}
            values={objetivo?.objetivos || []}
          />
        ))}
    </Grid>
  );
};
