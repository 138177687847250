/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormMulti } from '../../../../../../../../../lib/templates/components';
import { TextField } from '../../../../../../../../../app/components/inputs';
import { IconButtonRemove } from '../../../../../../../../../commons/components/IconButtonRemove';
import { usePlanificationForm } from '../../../commons/hooks';

const useStyles = makeStyles({
  formContainer: {
    padding: 16,
    margin: '5px 0',
    marginBottom: '70px',
  },
  textField: {
    paddingBottom: '17px',
  },
});

export const Bibliografía = (): JSX.Element => {
  const { form, errors } = usePlanificationForm();
  const classes = useStyles();

  return (
    <Grid spacing={2} className={classes.formContainer}>
      <FormMulti
        label="Bibliografía"
        name="bibliografia.bibliografia"
        form={form}
        addButtonText="Agregar Bibliografía"
        getNewElement={() => ''}
      >
        {(value, input, meta) => {
          return (
            <TextField
              value={value}
              {...input}
              InputProps={{
                endAdornment: (
                  <IconButtonRemove handleOnClick={input.onRemove} />
                ),
              }}
              error={meta.touched && !!meta.error}
              helperText="El campo Bibliografia no puede estar vacío"
              className={classes.textField}
            />
          );
        }}
      </FormMulti>
    </Grid>
  );
};
