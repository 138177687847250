import { Grid, TablePagination } from '@material-ui/core';
import { cloneDeep, get } from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import {
  useOrganizacionesDAO,
  useOrganizacionesLibbyFetch,
} from 'src/app/business';
import { useSectoresLibbyCall } from 'src/app/business/acap';
import { useCreateCuentaAndPersonaPostRequest } from 'src/app/business/businessCustomEndpoints/CreateCuentaAndPersona';
import { InfoTable, useDebounce, useRouteScreenTitle } from 'src/commons';
import useGenerarExcel from 'src/commons/hooks/useGenerarExcel';
import { usePaginate } from 'src/commons/hooks/usePaginate';
import { useLibbyCall } from 'src/lib/libby';
import { setSearchParamsMultipleWords } from 'src/utils/setSearchParamsMultipleWords';
import { ReferenteView } from '../../AcapReferente';
import { AccionesAsociadas } from '../accionesAsociadas/AccionesAsociadas';
import { useAcapEstablecimientoContext } from '../context/AcapEstablecimientoContext';
import { ORGANIZACION_SCREEN } from '../context/type';
import { useOrganizacionesExcelData } from '../hooks/useOrganizacionesExcelData';
import {
  CountAccionesByOrganizacion,
  HandleSubmit,
  OrganizacionFilter,
  SedeFormInitialValue,
} from '../types';
import { OrganizacionForm } from './components/OrganizacionForm';
import { OrganizacionHeader } from './components/OrganizacionHeader';
import { useOrganizacionesColumns } from './hooks/useOrganizacionesColumns';
import { makeInitialValue } from './utils/makeInitialValue';

const searchPath = [
  'nombre',
  'cuit',
  'email',
  'telefono',
  'referente.nombre',
  'referente.email',
  'referente.telefono',
  'sector.nombre',
  'sedes.direccion',
];

export const Organizacion = () => {
  useRouteScreenTitle('Actividades de aproximación');
  const { organizacionesScreen, setOrganizacionesScreen } =
    useAcapEstablecimientoContext();
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('nombre');
  const [limit, setLimit] = useState<number>(10);
  const [filters, setFilters] = useState<OrganizacionFilter>({
    sector: 0,
    cicloLectivo: '',
    search: '',
  });
  const { enqueueSnackbar } = useSnackbar();
  const generateExcel = useGenerarExcel();
  const organizacionesDAO = useOrganizacionesDAO();

  const searchDebounced = useDebounce(filters.search, 1000);

  const filterOrganizaciones = useMemo(
    () => ({
      0: Boolean(filters.sector)
        ? [{ path: 'sector', value: filters.sector }]
        : [],
      ...setSearchParamsMultipleWords(searchPath, searchDebounced),
    }),
    [filters.sector, searchDebounced],
  );

  const {
    data: organizaciones = [],
    working: workingOrganizaciones,
    reFetch: organizacionesReFetch,
  } = useOrganizacionesLibbyFetch({
    filter: filterOrganizaciones,
    limit: 5000,
    orderBy: 'nombre',
    direction: 'asc',
  });

  const {
    data: cantidadAccionesPorOrganizacion = [],
    working: workingAccionesCount,
  } = useLibbyCall<CountAccionesByOrganizacion>({
    daoName: 'acciones',
    methodName: 'getQuantityByOrganization',
  });

  const excelData = useOrganizacionesExcelData(
    organizaciones,
    cantidadAccionesPorOrganizacion,
    `Organizaciones ACAP del día ${moment(new Date(), 'YYYY-MM-DD').format(
      'DD/MM/YYYY',
    )}`,
  );

  const { data: sectores = [], working: workingSectores } =
    useSectoresLibbyCall({
      methodName: 'getAll',
    });

  const organizacionesSorted = useMemo(() => {
    const organizacionesCloned = cloneDeep(organizaciones);

    return organizacionesCloned.sort((a, b) => {
      return direction === 'asc'
        ? get(a, orderBy)?.localeCompare(get(b, orderBy))
        : get(b, orderBy)?.localeCompare(get(a, orderBy));
    });
  }, [organizaciones, direction, orderBy]);

  const { results, currentPage, nexPage, prevPage } = usePaginate({
    items: organizacionesSorted,
    pageSize: limit,
  });

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setDirection(newDirection);
    setOrderBy(newOrderBy);
  };

  const columns = useOrganizacionesColumns({
    cantidadAccionesPorOrganizacion,
    organizacionesReFetch,
    handleEdit: (data) => () => {
      setOrganizacionesScreen({
        screen: ORGANIZACION_SCREEN.ORGANIZACION_FORM,
        data: data,
      });
    },
    handleViewAcciones: (data) => () => {
      setOrganizacionesScreen({
        screen: ORGANIZACION_SCREEN.ACCIONES,
        data: data,
      });
    },
    handleViewReferente: (data) => () => {
      setOrganizacionesScreen({
        screen: ORGANIZACION_SCREEN.REFERENTE,
        data: data,
      });
    },
  });

  const { request } = useCreateCuentaAndPersonaPostRequest();

  const handleSubmit = useCallback<HandleSubmit>(
    async (values, toAcciones) => {
      try {
        const {
          referente: { isResetPassword, idReferente, ...rest },
          idOrganizacion,
          ...restValues
        } = values;

        const toSaveReferente = {
          ...rest,
          ...(idReferente ? { idReferente } : {}),
          shouldResetPassword: isResetPassword,
          previousEmail: organizacionesScreen.data?.referente.email || '',
        };

        await request({
          referentes: [{ ...toSaveReferente }],
        });

        const allSedes: SedeFormInitialValue[] = [
          {
            idSede: restValues.idSede,
            direccion: restValues.domicilio,
            turnos: restValues.turnos,
            isMain: true,
          },
          ...restValues.sedes,
        ];

        const organizacionGuardada = await organizacionesDAO.save({
          ...(idOrganizacion ? { idOrganizacion } : {}),
          nombre: restValues.nombre,
          cuit: restValues.cuit,
          telefono: restValues.telefono,
          email: restValues.email,
          vigenciaStarts: restValues.fechaInicio,
          vigenciaEnds: restValues.fechaFin,
          sector: restValues.sector,
          sedes: allSedes.map((sede) => ({
            ...sede,
            turnos: [{ idTurno: 1, descripcionTurno: 'Mañana' }],
          })),
          referente: { ...toSaveReferente },
        });

        enqueueSnackbar('Se ha guardado con exito', {
          variant: 'success',
        });

        if (toAcciones) {
          setOrganizacionesScreen({
            screen: ORGANIZACION_SCREEN.ACCIONES,
            data: organizacionGuardada,
          });
        } else {
          setOrganizacionesScreen({
            screen: ORGANIZACION_SCREEN.MAIN,
          });
        }
        organizacionesReFetch();
      } catch (error) {
        const err = error as any;
        console.log(err);
        if (
          err.message.includes(
            'key value violates unique constraint "organizaciones_unique" at data duplicate key value violates unique constraint "organizaciones_unique"',
          )
        )
          enqueueSnackbar('Error al guardar, existe un CUIT igual', {
            variant: 'error',
          });
        else if (
          err.message.includes(
            'duplicate key value violates unique constraint "referentes_unique" at data duplicate key value violates unique constraint "referentes_unique"',
          )
        )
          enqueueSnackbar(
            'Error al guardar, existe un mail de referente igual',
            { variant: 'error' },
          );
        else enqueueSnackbar('Error al guardar', { variant: 'error' });
      }
    },
    [
      organizacionesScreen.data?.referente.email,
      request,
      organizacionesDAO,
      enqueueSnackbar,
      organizacionesReFetch,
      setOrganizacionesScreen,
    ],
  );

  const isReferente = useMemo(() => ({enabled: true, row: organizacionesScreen.data!}), [organizacionesScreen.data])

  if (organizacionesScreen.screen === ORGANIZACION_SCREEN.REFERENTE) {
    return (
      <ReferenteView
        isReferente={isReferente}
        handleBack={() => {
          setOrganizacionesScreen({
            screen: ORGANIZACION_SCREEN.MAIN,
          });
        }}
      />
    );
  } else if (organizacionesScreen.screen === ORGANIZACION_SCREEN.ACCIONES) {
    return <AccionesAsociadas />;
  } else if (
    organizacionesScreen.screen === ORGANIZACION_SCREEN.ORGANIZACION_FORM
  ) {
    return (
      <OrganizacionForm
        sectores={sectores}
        initialValueForm={makeInitialValue(organizacionesScreen.data!)}
        handleSubmit={handleSubmit}
        handleCancel={() => {
          setOrganizacionesScreen({
            screen: ORGANIZACION_SCREEN.MAIN,
          });
        }}
      />
    );
  }

  return (
    <Grid>
      <OrganizacionHeader
        filters={filters}
        sectores={sectores}
        disableGenerateExcel={
          !excelData?.body?.data?.length || workingAccionesCount
        }
        handleChangeFilter={setFilters}
        handleGenerateExcel={() => {
          generateExcel(excelData?.body, excelData?.name);
        }}
        handleCreate={() => {
          setOrganizacionesScreen({
            screen: ORGANIZACION_SCREEN.ORGANIZACION_FORM,
          });
        }}
      />
      <InfoTable
        rows={results}
        columns={columns}
        working={
          workingOrganizaciones || workingSectores || workingAccionesCount
        }
        direction={direction}
        onSortChange={handleRequestSort}
      />
      <TablePagination
        rowsPerPageOptions={[10, 25, 50, 100]}
        component="div"
        count={organizaciones.length}
        rowsPerPage={limit}
        page={currentPage - 1}
        onPageChange={(_, page) => {
          if (currentPage - 1 <= page) {
            nexPage();
          } else {
            prevPage();
          }
        }}
        onRowsPerPageChange={(e) => {
          setLimit(parseInt(e.target.value));
        }}
        labelRowsPerPage="Filas por página"
        labelDisplayedRows={({ from, to, count }) => {
          return from + '-' + to + ' de ' + count;
        }}
      />
    </Grid>
  );
};
