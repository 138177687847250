import React from 'react';
import { useParams } from 'react-router';
import {
  useBackButton,
  useRouteScreenTitle,
} from '../../../../../../../commons/hooks';
import { EspaciosArealPlanById } from '../../../../../../../app/business';
import { ArealInterarealPreviewFlow } from './ArealInterarealPreviewFlow';
import { Loading } from '../../../../../../../commons/components/Loading';
import { useHistory } from 'react-router-dom';
import { useRouterContext } from 'src/lib/router/RouterContext';

export const ArealInterarealPreview = ({
  idPlan,
  setReadyToPrint,
}: {
  idPlan?: number;
  setReadyToPrint?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  // Title
  const {
    metadata: { title },
  } = useRouterContext();
  useRouteScreenTitle(
    idPlan ? title : 'Planificación Areal/Interareal Vista Previa',
  );

  const history = useHistory();
  const idx = history.location.pathname.indexOf('/planificacionpreview');
  useBackButton(history.location.pathname.slice(0, idx));
  // @ts-ignore
  const { idPlanificacion } = useParams();

  return (
    <EspaciosArealPlanById id={idPlan || idPlanificacion}>
      {({ data, working }) =>
        working || !data ? (
          <Loading />
        ) : (
          <>
            <ArealInterarealPreviewFlow data={data} />
            {setReadyToPrint && setReadyToPrint(true)}
          </>
        )
      }
    </EspaciosArealPlanById>
  );
};
