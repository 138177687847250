import { Rules } from 'src/lib/permission/types';
import { TABBAR_BLOCKER_ID, SIDEMENU_ID } from '../../const';

export const inicial: Rules = {
  idRule: 'nivel-1',
  routes: [{ key: 'calificaciones' }],
  blockers: [],
  sideMenu: [],
  tabBars: [
    { id: TABBAR_BLOCKER_ID.ESPACIO_CURRICULAR_PLANIFICACION_TAB },
    { id: TABBAR_BLOCKER_ID.ESPACIO_CURRICULAR_ACTIVIDADES_TAB },
    { id: TABBAR_BLOCKER_ID.ESPACIO_CURRICULAR_CALIFICACIONES_TAB },
    { id: TABBAR_BLOCKER_ID.PRESENTISMO_CONFIGURACION },
  ],
};
