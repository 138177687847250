import { PropuestaJEDiaDAO } from 'src/platform/libby/dao/Presentismov2';
import { makeBusinessEntity } from '../../../lib/libby/generator';
import { PropuestaJEDia } from '../../models/presentismo_v2';

const DAO_NAME = 'propuesta_je_dia';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { renderPropCall, renderPropFetchById, simpleSelect },
} = makeBusinessEntity<PropuestaJEDia, PropuestaJEDiaDAO>(DAO_NAME, {});

// hooks
export const usePropuestaJEDiaFetchById = fetchById;
export const usePropuestaJEDiaLibbyCall = libbyCall;
export const usePropuestaJEDiaDAO = libbyDAO;
export const usePropuestaJEDiaLibbyFetch = libbyFetch;

// components/hoc
export const PropuestaJEDiaCall = renderPropCall;
export const PropuestaJEDiaById = renderPropFetchById;
export const PropuestaJEDiaSelect = simpleSelect;
