import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { ArealPlanFeedback } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'areal_plan_feedback';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idArealPlanFeedback',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idArealPlanFeedback',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.ArealPlanFeedback
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.ArealPlanFeedback,
      hoc: { ...defaultOptions.hoc, ...businessOptions.ArealPlanFeedback.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<ArealPlanFeedback>(DAO_NAME, optionsToUse);

// hooks
export const useArealPlanFeedbackFetchById = fetchById;
export const useArealPlanFeedbackLibbyCall = libbyCall;
export const useArealPlanFeedbackDAO = libbyDAO;
export const useArealPlanFeedbackLibbyFetch = libbyFetch;

// components/hoc
export const ArealPlanFeedbackAutocomplete = entityAutocomplete;
export const ArealPlanFeedbackCall = renderPropCall;
export const ArealPlanFeedbackById = renderPropFetchById;
export const ArealPlanFeedbackSelect = simpleSelect;
export const ArealPlanFeedbackAccordionSelector = accordionSelector;
export const ArealPlanFeedbackSelectedChips = selectedChips;

// context
// ByIdContext
export const useArealPlanFeedbackByIdContext = useByIdContext;
export const ArealPlanFeedbackByIdProvider = ByIdProvider;
export const ArealPlanFeedbackByIdContext = ByIdContext;
// GeneralContext
export const useArealPlanFeedbackContext = useGeneralContext;
export const ArealPlanFeedbackProvider = GeneralProvider;
export const ArealPlanFeedbackContext = GeneralContext;
