import moment from 'moment';
import React, { FC } from 'react';
import { SimpleSelect } from 'src/commons/components/Select';
import { getFilterPassSection } from '../../../utils/getFilterPassSection';
import { NIVEL } from 'src/commons/const';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
interface ISelectPassSectionProps {
  value: string;
  handleChange(value: React.ChangeEvent<any>): void;
  numeroAnio?: number | undefined;
  disabled?: boolean;
  options: any;
  title?: string;
  currentCicloLectivo?: any;
  statusPass?: any;
  handleChangeFunction?: any;
  row?: any;
  setRow?: any;
  descripcionAnioSection: any;
  nivel: any;
  shoulBeNextYear?: boolean | undefined;
  proyeccionEstado?: any;
  idAnio?: any;
}

export const SelectPassSection: FC<ISelectPassSectionProps> = ({
  value,
  handleChange,
  numeroAnio,
  options = [],
  disabled,
  title,
  currentCicloLectivo,
  statusPass,
  handleChangeFunction,
  row = [],
  descripcionAnioSection,
  nivel,
  shoulBeNextYear = true,
  proyeccionEstado = [],
  idAnio,
}) => {
  const { cicloLectivo: cicloLectivoContext } = useColegioEfectivizadoContext();
  options.sort((seccionA: any, seccionB: any) => {
    const nombreSeccionA = seccionA?.nombreSeccion?.toLowerCase();
    const nombreSeccionB = seccionB?.nombreSeccion?.toLowerCase();

    if (nombreSeccionA > nombreSeccionB) {
      return 1;
    }
    if (nombreSeccionA < nombreSeccionB) {
      return -1;
    }
    return 0;
  });

  let { filteredOptions = [] } = getFilterPassSection(
    options,
    statusPass,
    currentCicloLectivo,
    numeroAnio,
    nivel,
    descripcionAnioSection,
    idAnio,
  );

  if (row?.sectionPass === 'Articulado') {
    filteredOptions.push({
      nombreSeccion: 'Articulado',
      idSeccion: 'Articulado',
      cicloLectivo: cicloLectivoContext?.anio,
    });
  }

  if (Number(nivel) === NIVEL.INICIAL) {
    if (statusPass === 3) {
      filteredOptions.push({
        nombreSeccion: '-',
        idSeccion: '-',
        cicloLectivo: cicloLectivoContext?.anio,
      });
    } else {
      if (
        proyeccionEstado[0]?.localizacion?.establecimiento?.dependenciaFuncional
          ?.idDependenciaFuncional === 41
      ) {
        filteredOptions.push({
          nombreSeccion: 'No Concurrirá',
          idSeccion: 'No Concurrirá',
          cicloLectivo: cicloLectivoContext?.anio,
        });
      } else {
        filteredOptions.push(
          {
            nombreSeccion: 'No Concurrirá',
            idSeccion: 'No Concurrirá',
            cicloLectivo: cicloLectivoContext?.anio,
          },
          {
            nombreSeccion: 'Articula',
            idSeccion: 'Articula',
            cicloLectivo: cicloLectivoContext?.anio,
          },
        );
      }
    }
  } else if (Number(nivel) === NIVEL.PRIMARIO && shoulBeNextYear) {
    filteredOptions.push({
      nombreSeccion: 'No Concurrirá',
      idSeccion: 'No Concurrirá',
      cicloLectivo: cicloLectivoContext?.anio,
    });

    if (statusPass === 7 || statusPass === 3) {
      filteredOptions = [
        {
          nombreSeccion: '-',
          idSeccion: '-',
          cicloLectivo: cicloLectivoContext?.anio,
        },
      ];
    }
  } else if (Number(nivel) === NIVEL.SECUNDARIO) {
    if (statusPass === 4 || statusPass === 3 || statusPass === 9) {
      filteredOptions = [
        {
          nombreSeccion: '-',
          idSeccion: '-',
          cicloLectivo: cicloLectivoContext?.anio,
        },
      ];
    } else {
      filteredOptions.push({
        nombreSeccion: 'No Concurrirá',
        idSeccion: 'No Concurrirá',
        cicloLectivo: cicloLectivoContext?.anio,
      });
    }
  }

  const optionsSelect =
    !shoulBeNextYear && statusPass !== 2 ? options : filteredOptions;

  return (
    <SimpleSelect
      name="passSection"
      title={title}
      value={value ?? optionsSelect[0]?.idSeccion}
      handleChange={handleChange}
      placeholder="Seleccioná Sección"
      content={optionsSelect}
      labelKey="nombreSeccion"
      valueKey="idSeccion"
      disableUnderline
      disabled={disabled}
      variant="standard"
    />
  );
};
