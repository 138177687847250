import React, { useMemo, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import {
  DistritoEscolarSelect,
  useLocalizacionLibbyFetch,
} from 'src/app/business';
import { MainButton } from 'src/commons/components/MainButton';
import { UsuarioRolTraverseAction } from 'src/app/models';
import { MainInput } from '../../../../../MainInput';
import { InfoTable } from '../../../../../InfoTable';
import { ROL, TRAVERSE_ACTIONS } from '../../../../../../const';
import { FormRadio } from '../../../../../../../lib/templates';
import { useDebounce } from '../../../../../../hooks';
import { ButtonTypesProps } from '../../../../../Footer';

const useStyles = makeStyles((theme) => ({
  tableCustomStyle: {
    maxHeight: '400px',
  },
}));

interface DirectionAreaTableProps {
  valueTravers: Array<UsuarioRolTraverseAction>;
  roleInformation: any;
  form: any;
  submitProps: ButtonTypesProps;
}

const localizacionFixedFilter = {
  fixedLocalizacionSkip: [{ path: 'cueAnexo', value: 0, method: 'higher' }],
};

const searchPath = [
  'establecimiento.dependenciaFuncional.descripcion',
  'establecimiento.modalidad.descripcion',
  'establecimiento.distritoEscolar.nombre',
  'descripcion',
  'cueAnexo',
  'domicilioEstablecimiento.calle',
  'domicilioEstablecimiento.altura',
];

const column = [
  {
    id: 'cueAnexo',
    label: 'CUE',
    width: '5%',
    orderById: 'cueAnexo',
  },
  {
    id: 'anexo',
    label: 'Anexo',
    width: '2%',
    orderById: 'anexo',
  },
  {
    id: 'establecimiento',
    label: 'Establecimiento',
    width: '20%',
    orderById: 'descripcion',
  },
  {
    id: 'dependenciaFuncional',
    label: 'Dependencia Funcional',
    width: '2%',
    orderById: 'establecimiento.dependenciaFuncional.idDependenciaFuncional',
  },
  {
    id: 'distrito',
    label: 'Distrito',
    width: '2%',
    orderById: 'establecimiento.distritoEscolar.nombre',
  },
  {
    id: 'direccion',
    label: 'Dirección',
    width: '10%',
    orderById: 'domicilioEstablecimiento.descripcion',
  },
];

const isSupervisor = (id: number) => [ROL.SUPERVISOR].includes(id);

export const DireccionAreaTable = ({
  valueTravers,
  roleInformation,
  form,
  submitProps,
}: DirectionAreaTableProps) => {
  const classes = useStyles();
  const [orderBy, setOrderBy] = useState('cueAnexo');
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [distrito, setDistrito] = useState('');
  const [search, setSearch] = useState('');
  const searchDebounced = useDebounce(search, 1000);

  const filterSelect = useMemo(
    () => ({
      distrito:
        valueTravers.length &&
        valueTravers
          .find(
            (r) =>
              r.traverseAction.traverseActionId ===
              TRAVERSE_ACTIONS.DISTRITO_ESCOLAR,
          )
          ?.value.map((i) => ({ path: 'idDistritoEscolar', value: i })),
    }),
    [valueTravers],
  );

  const filterLocalizacion = useMemo(
    () => ({
      distrito: distrito
        ? [
            {
              path: 'establecimiento.distritoEscolar.idDistritoEscolar',
              value: distrito,
            },
          ]
        : null,
      dependenciaFuncional: roleInformation?.dependenciaFuncional
        ?.idDependenciaFuncional
        ? [
            {
              path: 'establecimiento.dependenciaFuncional.idDependenciaFuncional',
              value:
                roleInformation?.dependenciaFuncional?.idDependenciaFuncional,
            },
          ]
        : null,
      localizaciones: isSupervisor(roleInformation.rolUsuario.idRolUsuario)
        ? valueTravers.length
          ? valueTravers.find(
              (r) =>
                r.traverseAction.traverseActionId ===
                TRAVERSE_ACTIONS.DISTRITO_ESCOLAR,
            )
            ? valueTravers
                .find(
                  (r) =>
                    r.traverseAction.traverseActionId ===
                    TRAVERSE_ACTIONS.DISTRITO_ESCOLAR,
                )
                ?.value.map((d) => ({
                  path: 'establecimiento.distritoEscolar',
                  value: d,
                }))
            : valueTravers
                .find(
                  (r) =>
                    r.traverseAction.traverseActionId ===
                    TRAVERSE_ACTIONS.LOCALIZACION,
                )
                ?.value.map((l) => ({ path: 'idLocalizacion', value: l }))
          : roleInformation.dependenciaFuncional
          ? []
          : [
              {
                path: 'idLocalizacion',
                value: -99999,
              },
            ]
        : null,
      search: searchDebounced
        ? searchPath.map((item) => ({
            path: item,
            value: searchDebounced,
            method: 'includes',
          }))
        : null,
    }),
    [roleInformation, distrito, valueTravers, searchDebounced],
  );

  const { data, fetchMore, working } = useLocalizacionLibbyFetch({
    orderBy,
    direction,
    filter: filterLocalizacion,
    fixedFilter: localizacionFixedFilter,
  });

  const rows = useMemo(() => {
    return data?.map(
      ({
        idLocalizacion,
        cueAnexo,
        anexo,
        descripcion,
        establecimiento,
        domicilioEstablecimiento,
      }) => ({
        cueAnexo: (
          <Grid container alignItems="center">
            <Grid item>
              <FormRadio
                form={form}
                name="idLocalizacion"
                value={idLocalizacion}
              />
            </Grid>
            <Grid item style={{ maxWidth: '200px' }}>
              {cueAnexo.slice(0, -2)}
            </Grid>
          </Grid>
        ),
        anexo: anexo.padStart(2, '0'),
        establecimiento: descripcion,
        modalidad: establecimiento?.modalidad?.descripcion,
        dependenciaFuncional:
          establecimiento?.dependenciaFuncional?.descripcion,
        distrito: establecimiento?.distritoEscolar?.nombre,
        direccion: `${domicilioEstablecimiento?.calle} ${domicilioEstablecimiento?.altura} (${domicilioEstablecimiento?.codigoPostal})`,
      }),
    );
  }, [data, form]);

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  return (
    <Grid
      container
      spacing={4}
      direction="column"
      style={{ paddingRight: '4%', paddingLeft: '4%', flex: 1 }}
    >
      <Grid item container direction="row" style={{ paddingBottom: 0 }}>
        <Grid container spacing={4} justify="flex-start" alignItems="center">
          <Grid item xs={3} style={{ paddingLeft: 0 }}>
            <DistritoEscolarSelect
              title="Distrito Escolar"
              placeholder="Seleccioná un distrito"
              value={distrito}
              handleChange={({ target }) => {
                setDistrito(target.value);
              }}
              filter={filterSelect}
            />
          </Grid>
          <Grid item xs={8} justify="flex-end" alignItems="flex-end">
            <Grid xs={6} justify="flex-end" alignItems="flex-end">
              <MainInput
                type="search"
                handleChange={({ target }) => {
                  setSearch(target.value);
                }}
                placeholder="Buscar"
                name="search"
                value={search}
                fullWidth
                customStyle={{ padding: 9.5, marginTop: 30 }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <InfoTable
        working={working}
        rows={rows || []}
        columns={column}
        orderBy={orderBy}
        direction={direction}
        onSortChange={handleRequestSort}
        rowIdKey="id"
        onBottomScroll={fetchMore}
        customStyle={classes.tableCustomStyle}
      />
      <Grid container xs justify="flex-end" style={{ paddingTop: 20 }}>
        <Grid item>
          <MainButton {...submitProps} />
        </Grid>
      </Grid>
    </Grid>
  );
};
