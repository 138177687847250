import React, { useMemo } from 'react';

export const useAlumnosColumns = () => {
  const columns = useMemo(
    () => [
      {
        id: 'nombreCompleto',
        label: 'Apellido y nombre',
        render: (value: any, row: any) => (
          <span style={{ color: '#00b3e3' }}>
            {row?.alumno?.persona?.apellido +
              ', ' +
              row?.alumno?.persona?.nombre}
          </span>
        ),
        orderById: '',
      },
      {
        id: 'documento',
        label: 'Documento',
        hideSortIcon: true,
        render: (value: any, row: any) => row?.alumno?.persona?.documento,
      },
      {
        id: 'codicion',
        label: 'Condición',
        hideSortIcon: true,
        render: (value: any, row: any) =>
          row?.alumno?.condicion?.descripcionCondicion,
      },
      {
        id: 'año',
        label: 'Año',
        hideSortIcon: true,
        render: (value: any, row: any) => row?.seccion?.anio?.descripcionAnio,
      },
      {
        id: 'turno',
        label: 'Turno',
        hideSortIcon: true,
        render: (value: any, row: any) => row?.seccion?.turno?.descripcionTurno,
      },
      {
        id: 'jornada',
        label: 'Jornada',
        hideSortIcon: true,
        render: (value: any, row: any) => row?.seccion?.jornada,
      },
      {
        id: 'division',
        label: 'División',
        hideSortIcon: true,
        render: (value: any, row: any) => row?.seccion?.division,
      },
    ],
    [],
  );

  return columns;
};
