import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { MainInput } from 'src/commons';
import { MainButton, SimpleSelect } from 'src/commons/components';
import { grey } from 'src/theme/colors';
import { useAnioLibbyFetch } from 'src/app/business';
import { workingDays } from 'src/commons/const';
import moment from 'moment';
import { useRolesContext } from 'src/context/RolesContext';

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  containerStyles: { marginTop: 23, flexWrap: 'nowrap' },
  searchGrid: { marginTop: 10 },
  buttonStyle: { marginTop: 15 },
};

export const FilterTableAcompaniada = ({
  formValues,
  setFormValues,
  secciones,
  setSearch = [],
  search,
}: any) => {
  const roleContext = useRolesContext();
  const { localizacionId, nivel } = roleContext.selectedRole;

  const filterAnio = useMemo(
    () => ({
      1: [{ path: 'nivel.idNivel', value: nivel }],
      2: [{ path: 'idAnio', value: 7, method: 'notEquals' }],
    }),
    [nivel],
  );

  const { data: anios = [] } = useAnioLibbyFetch({
    filter: filterAnio,
  });

  return (
    <>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        spacing={3}
        style={customStyles.containerStyles}
      >
        <Grid item xs={2}>
          <SimpleSelect
            name="anio"
            title="Año"
            placeholder="Seleccioná el Año"
            labelKey="descripcionAnio"
            valueKey="idAnio"
            handleChange={(e: any) =>
              setFormValues({ idAnio: e.target.value, idSeccion: '' })
            }
            content={anios}
            value={formValues?.idAnio}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
        <Grid item xs={2}>
          <SimpleSelect
            name="seccion"
            title="Seccion"
            placeholder="Seleccioná la Sección"
            labelKey="nombreSeccion"
            valueKey="idSeccion"
            handleChange={(e: any) =>
              setFormValues({ ...formValues, idSeccion: e.target.value })
            }
            content={secciones}
            value={formValues?.idSeccion}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
        <Grid
          container
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          spacing={4}
        >
          <Grid item xs={2}>
            <Grid
              container
              spacing={3}
              alignItems="flex-end"
              style={customStyles.buttonStyle}
            >
              <MainButton
                title="Anexo VI"
                // handleOnClick={handleShowModalProyeccion}
                disabled={true}
              />
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid
              container
              justify="flex-end"
              spacing={3}
              alignItems="flex-end"
              style={customStyles.searchGrid}
            >
              <Grid item xs={12}>
                <MainInput
                  name="search"
                  handleChange={(e) => setSearch(e.target.value)}
                  placeholder="Buscar"
                  type="search"
                  value={search}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
