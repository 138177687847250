export type ConditionType = {
  REGULAR: number;
  BAJA: number;
  NO_REGULAR: number;
  DATOS_INSUFICIENTES: number;
};

export const TIPO_CONDICION: ConditionType = {
  REGULAR: 1,
  BAJA: 2,
  NO_REGULAR: 3,
  DATOS_INSUFICIENTES: 4,
};
