import { isEqual } from 'lodash';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { PrimarioCustom } from 'src/app/models';
import { CalificacionesFilter } from 'src/screens/Private/Calificaciones/context';

type ValidatorArgs = {
  fieldsToValidate: string[];
  _rows: Array<PrimarioCustom & { nota: { data: object } }>;
  isDynamic: boolean;
  filter: CalificacionesFilter;
  working: boolean;
  data: PrimarioCustom[];
};

export const useAreaDeConocimientoValidator = ({
  fieldsToValidate,
  _rows,
  isDynamic,
  filter,
  working,
  data,
}: ValidatorArgs) => {
  const [incompletedRows, setIncompletedRows] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [currentRows, setCurrentRows] = useState();
  const [currentFilter, setCurrentFilter] = useState();
  const [submitRows, setSubmitRows] = useState(false);

  useEffect(() => {
    setSubmitRows(
      !_rows.some((row) => {
        const originalRow = data.find(
          (item) => item.idCalificacion === row.idCalificacion,
        );
        if (!originalRow) return;

        return Boolean(originalRow.nota)
          ? !isEqual(row.nota?.data, originalRow.nota?.data)
          : Object.keys(row.nota?.data).length > 0 &&
              Object.entries(row.nota?.data).every(([_, value]) =>
                Boolean(value),
              );
      }),
    );
  }, [_rows, data]);

  const validator = useCallback(() => {
    let disabled = false;
    if (filter !== currentFilter) {
      setCurrentFilter(filter);
      setIncompletedRows([]);
    }
    _rows.forEach((r) => {
      const fieldsToValidateByRow = [...fieldsToValidate];
      if (isDynamic) {
        if (r.nota.data.espaciosConsolidandoPregunta === 'Sí') {
          fieldsToValidateByRow.push('espaciosConsolidando');
        }
        if (r.nota.data.apoyoPregunta === 'Sí') {
          fieldsToValidateByRow.push('apoyo');
        }
      }
      r.fieldsToValidateByRow = fieldsToValidateByRow;
      const name = `${r?.apellido}, ${r?.nombre}`;
      const result = fieldsToValidateByRow.reduce(
        (acc: number, val: string) => {
          if (
            r.nota.data[val] === undefined ||
            r.nota.data[val] === null ||
            r.nota.data[val] === ''
          )
            ++acc;
          return acc;
        },
        0,
      );
      if (result !== fieldsToValidateByRow.length && result !== 0) {
        disabled = true;
        if (incompletedRows.indexOf(name) === -1) {
          setIncompletedRows([...incompletedRows, name]);
        }
      } else {
        if (incompletedRows.indexOf(name) !== -1) {
          const incompletedRowsCopy = [...incompletedRows];
          incompletedRowsCopy.splice(incompletedRowsCopy.indexOf(name), 1);
          setIncompletedRows(incompletedRowsCopy);
        }
      }
    });
    setIsDisabled(disabled);
  }, [
    incompletedRows,
    _rows,
    isDynamic,
    filter,
    currentFilter,
    fieldsToValidate,
  ]);

  useEffect(() => {
    if (currentRows !== _rows && !working) {
      setCurrentRows(_rows);
      validator();
    }
  }, [currentRows, _rows, validator, working]);

  return {
    incompletedRows,
    setIncompletedRows,
    isDisabled,
    submitRows,
  };
};
