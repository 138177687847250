import { Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const AntSwitch = withStyles((theme) => ({
  root: {
    width: 40,
    height: 20,
    padding: 2,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,

    '&$checked': {
      transform: 'translateX(16px)',
      color: theme.palette.common.white,

      '& + $track': {
        backgroundColor: theme.palette.primary.main,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: '#52d869',
      border: '6px solid #fff',
    },
  },
  thumb: {
    width: 18,
    height: 18,
  },
  track: {
    borderRadius: 20 / 2,
    border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    transition: theme.transitions.create(['background-color', 'border']),
  },
}))(Switch);
