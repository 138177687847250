import { Grid, Tooltip } from '@material-ui/core';
import React from 'react';
import { MainButton } from 'src/commons';
import { useReportsContext } from '../contexts/ReportContext';
import { FILTERS } from '../table_filters';

const HeaderButtons = ({
  disabledSearch,
  disabledDownload,
  downloadHandler,
  searchHandler,
  hasRecords,
}: {
  disabledSearch?: boolean;
  disabledDownload?: boolean;
  downloadHandler: (e) => void;
  searchHandler: (e) => void;
  hasRecords: boolean;
}) => {
  const { globalFilters } = useReportsContext();

  return (
    <Grid container spacing={1}>
      <Grid item xs={6}>
        <MainButton
          title="Buscar"
          handleOnClick={searchHandler}
          customStyle={{ marginLeft: 10 }}
          disabled={!!!globalFilters[FILTERS.nivel] || disabledSearch}
          ringStyle={hasRecords}
        />
      </Grid>
      <Grid item xs={6}>
        <MainButton
          title="Descargar"
          handleOnClick={downloadHandler}
          customStyle={{ marginLeft: 10 }}
          disabled={disabledDownload || !!!globalFilters[FILTERS.nivel]}
        />
      </Grid>
    </Grid>
  );
};

export default HeaderButtons;
