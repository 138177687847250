import { IconButton, SvgIcon, SvgIconProps } from '@material-ui/core';
import * as React from 'react';
import { useLockCellStyles } from 'src/screens/Private/Presentismo/TomaPresentismo/components/PresentismoTable/css';

export const LockOpenSolidV1 = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    {/*  <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
    <path d="M352 144c0-44.2 35.8-80 80-80s80 35.8 80 80v48c0 17.7 14.3 32 32 32s32-14.3 32-32V144C576 64.5 511.5 0 432 0S288 64.5 288 144v48H64c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V256c0-35.3-28.7-64-64-64H352V144z" />
  </svg>
);

export const LockOpenSolid = (props: SvgIconProps) => (
  <SvgIcon {...props} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
    {/*  <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
    <path d="M352 144c0-44.2 35.8-80 80-80s80 35.8 80 80v48c0 17.7 14.3 32 32 32s32-14.3 32-32V144C576 64.5 511.5 0 432 0S288 64.5 288 144v48H64c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V256c0-35.3-28.7-64-64-64H352V144z" />
  </SvgIcon>
);

export const LockOpenSolidV2 = ({
  handleCloseDay,
  isDisabled,
}: {
  handleCloseDay: () => void;
  isDisabled: boolean;
}) => {
  const classes = useLockCellStyles();
  return (
    <IconButton
      className={classes.buttonLock}
      onClick={handleCloseDay}
      disabled={isDisabled}
    >
      <SvgIcon
        className={classes.icon}
        htmlColor={isDisabled ? '#7A7A7A' : '#3786EE'}
        fontSize="small"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 576 512"
      >
        {/*  <!--! Font Awesome Pro 6.3.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. --> */}
        <path d="M352 144c0-44.2 35.8-80 80-80s80 35.8 80 80v48c0 17.7 14.3 32 32 32s32-14.3 32-32V144C576 64.5 511.5 0 432 0S288 64.5 288 144v48H64c-35.3 0-64 28.7-64 64V448c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V256c0-35.3-28.7-64-64-64H352V144z" />
      </SvgIcon>
    </IconButton>
  );
};
