import { isEqual } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { RowCalification } from 'src/app/models';
import { CalificacionesFilter } from '../CalificacionesSecundario';

export const useValidator = <T extends RowCalification>({
  fieldsToValidate,
  _rows,
  isDynamic,
  filter,
  data,
  isTercerBimestre,
}: {
  fieldsToValidate: string[] | null;
  _rows: T[];
  isDynamic?: boolean;
  filter: CalificacionesFilter;
  data: T[];
  isTercerBimestre?: boolean;
}) => {
  const [incompletedRows, setIncompletedRows] = useState<string[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [currentRows, setCurrentRows] = useState<typeof _rows>();
  const [currentFilter, setCurrentFilter] = useState();
  const [isInvalidToSubmit, setInvalidToSubmit] = useState(false);

  isTercerBimestre = [7, 10, 12].includes(filter.periodo);

  useEffect(() => {
    setInvalidToSubmit(
      !_rows.some((row) => {
        const originalRow = data.find(
          (item) => item.idCalificacion === row.idCalificacion,
        );
        if (!originalRow) return;

        return Boolean(originalRow.nota)
          ? !isEqual(row.nota?.data, originalRow.nota?.data)
          : Object.keys(row.nota?.data).length > 0 &&
              Object.entries(row.nota?.data).every(
                ([_, value]) => Boolean(value) || value === false,
              );
      }),
    );
  }, [_rows, data]);

  const validator = useCallback(() => {
    if (filter !== currentFilter) {
      setCurrentFilter(filter);
      setIncompletedRows([]);
    }
    let tempIncompletedRows = [...incompletedRows];
    _rows.forEach((calificacion) => {
      const fieldsToValidateByRow =
        fieldsToValidate !== null ? [...fieldsToValidate] : [];
      if (isDynamic && isTercerBimestre) {
        if (calificacion.nota?.data?.acompaniamientoAreaPregunta === 'Sí') {
          fieldsToValidateByRow.push('acompaniamientoAreaPregunta');
          fieldsToValidateByRow.push('acompaniamientoArea');
          fieldsToValidateByRow.push('apoyoPregunta');
        } else if (
          calificacion.nota?.data?.acompaniamientoAreaPregunta === 'No'
        ) {
          fieldsToValidateByRow.push('acompaniamientoAreaPregunta');
          fieldsToValidateByRow.push('apoyoPregunta');
        }
        if (calificacion.nota?.data?.apoyoPregunta === 'Sí') {
          fieldsToValidateByRow.push('apoyo');
        }
      }

      if (isDynamic && [2, 8, 11, 13].includes(filter.periodo)) {
        if (calificacion.nota?.data?.acompaniamientoPregunta === 'Sí') {
          fieldsToValidateByRow.push('acompaniamientoPregunta');
          fieldsToValidateByRow.push('acompaniamientoArea');
          fieldsToValidateByRow.push('apoyoPregunta');
        } else if (calificacion.nota?.data?.acompaniamientoPregunta === 'No') {
          fieldsToValidateByRow.push('acompaniamientoPregunta');
          fieldsToValidateByRow.push('apoyoPregunta');
        }
        if (calificacion.nota?.data?.apoyoPregunta === 'Sí') {
          fieldsToValidateByRow.push('apoyo');
        }
      }

      calificacion.fieldsToValidateByRow = fieldsToValidateByRow;
      const name = `${calificacion?.apellido}, ${calificacion?.nombre}`;
      const result = fieldsToValidateByRow.reduce(
        (acc: number, val: string) => {
          if (['calificacionAnual', 'anualDefinitiva'].includes(val)) {
            if (
              calificacion[val] === undefined ||
              calificacion[val] === null ||
              calificacion[val] === ''
            )
              ++acc;
            return acc;
          }

          if (
            calificacion.nota?.data &&
            (calificacion.nota.data[val] === undefined ||
              calificacion.nota.data[val] === null ||
              calificacion.nota.data[val] === '')
          )
            ++acc;
          return acc;
        },
        0,
      );

      if (result > 0 && result < fieldsToValidateByRow.length) {
        if (incompletedRows.indexOf(name) === -1) {
          tempIncompletedRows = [...incompletedRows, name];
        }
      } else {
        if (incompletedRows.indexOf(name) !== -1) {
          const incompletedRowsCopy = [...incompletedRows];
          incompletedRowsCopy.splice(incompletedRowsCopy.indexOf(name), 1);
          tempIncompletedRows = incompletedRowsCopy;
        }
      }

      setIncompletedRows(tempIncompletedRows);
    });

    setIsDisabled(tempIncompletedRows.length > 0);
  }, [
    filter,
    currentFilter,
    _rows,
    fieldsToValidate,
    isDynamic,
    isTercerBimestre,
    incompletedRows,
  ]);

  useEffect(() => {
    if (currentRows !== _rows) {
      setCurrentRows(_rows);
      validator();
    }
  }, [currentRows, _rows, validator]);

  return {
    incompletedRows,
    setIncompletedRows,
    isDisabled,
    submitRows: isInvalidToSubmit,
  };
};
