import { LibbyFetchDAO } from './LibbyFetchDAO';

export class TipoDocumentoDAO extends LibbyFetchDAO {
  constructor() {
    super('tipo_documento', 'idTipoDocumento');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
