import React from 'react';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import { Box, Button, Typography } from '@material-ui/core';

interface AttachFileProps {
  file: File;
  onDelete: (value: File) => void;
}
export const AttachFileView = ({ file, onDelete }: AttachFileProps) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      border="1px solid #38485C"
      bgcolor="#F3F6F9"
      borderRadius={4}
      height={48}
      py={0.5}
      px={1}
      mt={1}
    >
      <Box display="flex">
        <DescriptionOutlinedIcon htmlColor="#38485C" />
        <Box
          ml={1}
          fontSize={16}
          fontWeight="bold"
          fontFamily="Nunito"
          color="#007BC7"
        >
          {`${
            file.name.split('.')[0].length > 32
              ? file.name.split('.')[0].split('').slice(0, 32).join('')
              : file.name.split('.')[0]
          }.${file.name.split('.')[1]}`}
        </Box>
      </Box>
      <Box
        component={Button}
        color="#C93B3B"
        onClick={() => onDelete(file)}
        fontFamily="Nunito"
        fontWeight="bold"
        fontSize={13}
        style={{ textTransform: 'none' }}
      >
        Eliminar
      </Box>
    </Box>
  );
};
