import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { InfoTable, AnyObject } from 'src/commons';
import { useLocalizacionPlanEstudioNivelLibbyFetch } from 'src/app/business';
import { Grid, Typography } from '@material-ui/core';
import { useSignUpForm } from '../../hooks/useSignUpForm';
import { Orientacion } from 'src/app/models/orientaciones';
import { useOrientacionesLibbyFetch } from 'src/app/business/orientaciones/Orientaciones';

const columns = [
  {
    id: 'checkbox',
    label: '',
    width: '1%',
    noSort: true,
    hideSortIcon: true,
  },
  {
    id: 'orientacion',
    label: 'Orientación',
    width: '10%',
    noSort: true,
    hideSortIcon: true,
  },
];

export const CoordinadorOrientacion = () => {
  const { form, values } = useSignUpForm();
  const [roles, setRoles] = useState<AnyObject[]>([]);

  const filterOrientaciones = useMemo(
    () => ({
      localizacion: [
        {
          path: 'localizacion',
          value: values.idLocalizacion,
        },
      ],
      nivel: [
        {
          path: 'planEstudioNivel.modalidadNivel.nivel',
          value: values.idNivel,
        },
      ],
    }),
    [values.idLocalizacion, values.idNivel],
  );

  const { data = [], working } = useLocalizacionPlanEstudioNivelLibbyFetch({
    limit: 500,
    filter: filterOrientaciones,
  });

  const orientaciones = useMemo(() => {
    let items: Orientacion[] = [];
    data.forEach((item) => {
      const _orientas = item.planEstudioNivel.planEstudio.orientaciones;
      if (_orientas.length > 0) {
        for (const orientacion of _orientas) {
          if (!orientacion.orientacion) continue;
          const exist = items.find(
            (i) => i.idOrientacion === orientacion.orientacion.idOrientacion,
          );
          if (!exist) {
            items.push(orientacion.orientacion);
          }
        }
      }
    });
    return items;
  }, [data]);

  const filterPlanEstudioOr = useMemo(
    () => ({
      pk: orientaciones.map(({ idOrientacion }) => ({
        path: 'idOrientacion',
        value: idOrientacion,
      })),
    }),
    [orientaciones],
  );
  // No se si es necesario olver a llamar - talvez si es posible deshabilitar dichas orientaciones
  const { data: orientacionesData = [], working: orientasWorking } =
    useOrientacionesLibbyFetch({
      enabled: !working && orientaciones.length > 0,
      filter: filterPlanEstudioOr,
    });

  const onChange = useCallback(
    (idOrientacion: number) => {
      setRoles((oldRoles) => {
        const position = oldRoles.findIndex(
          (old) => old.idOrientacion === idOrientacion,
        );
        if (position !== -1) {
          return oldRoles.filter((rol) => rol.idOrientacion !== idOrientacion);
        }
        return [...oldRoles, { idOrientacion, idNivel: +values.idNivel }];
      });
    },
    [values.idNivel, setRoles],
  );

  useEffect(() => {
    form.change('roles', roles?.length ? [...roles] : null);
  }, [roles, form]);

  const rows = useMemo(
    () =>
      orientacionesData.map((item, index: number) => {
        return {
          checkbox: (
            <input
              name="idLocalizacion"
              key={index}
              type="radio"
              value={item.idOrientacion}
              onChange={() => onChange(item.idOrientacion)}
            />
          ),
          orientacion: <Typography>{item.nombre}</Typography>,
        };
      }),
    [orientacionesData, onChange],
  );

  return (
    <Grid container direction="column">
      <Grid item xs={12}>
        <Typography>Seleccioná las Orientaciones</Typography>
      </Grid>
      <Grid item xs={12}>
        <InfoTable
          working={working || orientasWorking}
          rows={rows || []}
          columns={columns || []}
        />
      </Grid>
    </Grid>
  );
};
