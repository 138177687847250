import React, { useMemo } from 'react';
import { EspaciosArealPlan } from '../../../../../../../../app/models';
import { InnerCard } from '../../../feedbacks/commons';
import {
  InformacionGeneral,
  Proyecto,
  ObjetivosDeAprendizaje,
  EspacioCurricular,
  Actividad,
  IndicadoresDeEvaluacion,
  Bibliografia,
} from '../info';

export const useInfoCards = (data: EspaciosArealPlan) =>
  useMemo<InnerCard[]>(
    () => [
      {
        title: 'Información General',
        render: <InformacionGeneral data={data.arealPlan.informacionGeneral} />,
      },
      {
        title: 'Proyecto',
        render: <Proyecto data={data.arealPlan.proyecto} />,
      },
      {
        title: 'Objetivos de aprendizaje',
        render: (
          <ObjetivosDeAprendizaje
            data={data.arealPlan.objetivosDeAprendizaje}
          />
        ),
      },
      {
        title: 'Espacio Curricular',
        render: <EspacioCurricular data={data.arealPlan.espacioCurricular} />,
      },
      {
        title: 'Actividad',
        render: <Actividad data={data.arealPlan.actividad} />,
      },
      {
        title: 'Indicadores de evaluación',
        render: (
          <IndicadoresDeEvaluacion
            data={data.arealPlan.indicadoresDeEvaluacion}
          />
        ),
      },
      {
        title: 'Bibliografía',
        render: <Bibliografia data={data.arealPlan.bibliografia} />,
      },
    ],
    [data],
  );
