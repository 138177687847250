import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Planificacion } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'planificacion';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPlanificacion',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPlanificacion',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Planificacion
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Planificacion,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Planificacion.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Planificacion>(DAO_NAME, optionsToUse);

// hooks
export const usePlanificacionFetchById = fetchById;
export const usePlanificacionLibbyCall = libbyCall;
export const usePlanificacionDAO = libbyDAO;
export const usePlanificacionLibbyFetch = libbyFetch;

// components/hoc
export const PlanificacionAutocomplete = entityAutocomplete;
export const PlanificacionCall = renderPropCall;
export const PlanificacionById = renderPropFetchById;
export const PlanificacionSelect = simpleSelect;
export const PlanificacionAccordionSelector = accordionSelector;
export const PlanificacionSelectedChips = selectedChips;

// context
// ByIdContext
export const usePlanificacionByIdContext = useByIdContext;
export const PlanificacionByIdProvider = ByIdProvider;
export const PlanificacionByIdContext = ByIdContext;
// GeneralContext
export const usePlanificacionContext = useGeneralContext;
export const PlanificacionProvider = GeneralProvider;
export const PlanificacionContext = GeneralContext;
