import { LibbyFetchDAO } from './LibbyFetchDAO';

export class GeolocalizacionDAO extends LibbyFetchDAO {
  constructor() {
    super('geolocalizacion', 'idGeolocalizacion');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  locationQuery({ direccion, localidad, altura, villa, manzana }: any) {
    return this.query()
      .equals('direccion', direccion)
      .equals('localidad', localidad)
      .equals('altura', altura)
      .equals('manzana', manzana)
      .equals('villa', villa)
      .run();
  }
}
