import { LibbyFetchDAO } from './LibbyFetchDAO';

export class InterdiscipOrientaPlanDAO extends LibbyFetchDAO {
  constructor() {
    super('interdiscip_orienta_plan', 'idInterdiscipOrientaPlan');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
