import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Grid, Checkbox, Typography } from '@material-ui/core';
import { AnioSelect, useSeccionLibbyFetch } from 'src/app/business';
import { InfoTable, AnyObject } from 'src/commons';
import { useFormContext } from 'src/lib/templates';
import { grey } from 'src/theme/colors';
import { useSignUpForm } from '../../hooks/useSignUpForm';

const column = [
  {
    id: 'checkbox',
    label: '',
    width: '1%',
    noSort: true,
    hideSortIcon: true,
  },
  {
    id: 'nombreSeccion',
    label: 'Seccion',
    width: '10%',
    orderById: 'nmbreSeccion',
    noSort: true,
    hideSortIcon: true,
  },
  {
    id: 'planStudy',
    label: 'Plan de Estudio',
    width: '20%',
    orderById: 'planEstudioNivel',
    noSort: true,
    hideSortIcon: true,
  },
];

export const Tutor = () => {
  const { form, values, errors } = useSignUpForm();
  const [state, setState] = useState({
    idAnio: '',
  });
  const [roles, setRoles] = useState<AnyObject[]>([]);

  const filter = useMemo(
    () => ({
      anio: [{ path: 'anio.idAnio', value: state.idAnio }],
      localizacion: [
        { path: 'localizacion.idLocalizacion', value: values?.idLocalizacion },
      ],
    }),
    [state.idAnio, values.idLocalizacion],
  );

  const {
    data: seccion,
    working: sectionWorking,
    fetchMore,
  } = useSeccionLibbyFetch({
    filter,
  });

  const onChange = useCallback((permissions) => {
    setRoles((oldRoles) => {
      const position = oldRoles.findIndex(
        (old: any) => old.idSeccion === permissions.idSeccion,
      );
      if (position !== -1) {
        oldRoles.splice(position, 1);
        return [...oldRoles];
      }
      return [...oldRoles, { ...permissions }];
    });
  }, []);

  useEffect(() => {
    form.change('roles', roles?.length ? [...roles] : null);
  }, [roles, form]);

  const rows = useMemo(() => {
    return seccion?.map(
      (
        {
          idSeccion,
          nombreSeccion,
          nivel: { idNivel },
          planEstudioNivel: {
            planEstudio: { descripcionPlanEstudio },
          },
        }: AnyObject,
        index: number,
      ) => {
        return {
          checkbox: (
            <Checkbox
              name={`roles[${index}].idSeccion`}
              onChange={() => onChange({ idSeccion, idNivel })}
            />
          ),
          seccion: idSeccion,
          nombreSeccion,
          planStudy: descripcionPlanEstudio,
        };
      },
    );
  }, [seccion, onChange]);

  return (
    <>
      <Typography
        style={{
          fontFamily: 'Open Sans',
          color: grey.medium,
          fontSize: 20,
          marginBottom: 30,
        }}
      >
        Seleccioná un año
      </Typography>
      <Grid container direction="column">
        <Grid item xs={3}>
          <AnioSelect
            name="idAnio"
            title="Año"
            placeholder="Seleccioná un año"
            value={state.idAnio}
            handleChange={({ target }) => {
              form.change('roles', null);
              setState({ ...state, idAnio: target.value });
            }}
          />
        </Grid>
        <Grid item>
          {state.idAnio && (
            <InfoTable
              working={sectionWorking}
              rows={rows || []}
              columns={column || []}
              onBottomScroll={fetchMore}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
};
