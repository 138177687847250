import React, { useEffect } from 'react';
import { InfoTable, Loading } from 'src/commons';
import { useVinculoFamiliaEscuelaColumns } from '../hooks/useVinculoFamiliaEscuelaColumns';
import { grey, primary } from 'src/theme/colors';
import { makeStyles, Typography } from '@material-ui/core';
import { useInformePPSContext } from '../InformePipContext';
import { usePPSFormContext } from '../PPSFormProvider';
import { Pps } from 'src/app/models';
import { TABS_NAMES } from '../helpers';

export const useStyles = makeStyles({
  label: {
    marginBottom: 5,
    marginTop: 10,
    fontSize: 18,
    fontWeight: 500,
    color: grey.medium,
  },
  isIncompleteClose: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
    paddingTop: '17px',
    fontFamily: 'Open Sans',
    fontSize: '14px',
    lineHeight: '22px',
  },
  incompleteMessageClose: {
    borderRadius: 4,
    display: 'flex',
    backgroundColor: primary.errorMedium,
    flexFlow: 'column',
    color: grey.dark,
    padding: '10px 20px',
  },
});

export const VinculoFamiliaEscuela = () => {
  const { informePps } = useInformePPSContext();
  const { isSaving, pps, setCurrentTab, currentTab } = usePPSFormContext();
  const classes = useStyles();

  const row = informePps?.row ?? [];

  const columns = useVinculoFamiliaEscuelaColumns(pps[0]);

  useEffect(() => {
    if (currentTab === TABS_NAMES.VINCULO) return;
    setCurrentTab(TABS_NAMES.VINCULO);
  }, [setCurrentTab, currentTab]);

  return (
    <>
      {isSaving ? (
        <Loading />
      ) : (
        <>
          {!(pps[0] as Pps)?.abierto && pps[0] !== undefined && (
            <div className={classes.isIncompleteClose}>
              <Typography className={classes.incompleteMessageClose}>
                El informe está cerrado.
              </Typography>
            </div>
          )}
          <Typography className={classes.label}>
            {`${informePps?.row?.alumno?.persona?.apellido}, ${informePps?.row?.alumno?.persona?.nombre} - ${informePps?.row?.alumno?.persona?.documento} - ${informePps?.row?.seccion?.nombreSeccion}`}
          </Typography>
          <InfoTable rows={[row]} columns={columns} />
        </>
      )}
    </>
  );
};
