import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState, useCallback, useMemo } from 'react';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import customFormDialog from 'src/commons/services/customFormDialog';
import { VistaPdfJustificacion } from './VistaPdfJustificacion';
import { usePresenteV3DAO } from 'src/app/business/presentismo_v3/Presente';
import { rowJustificativosTypes } from '../types';
import { useSnackbar } from 'notistack';
import { PresenteGeneral } from '../../TomaPresentismo/types';
import { Justificacion, JustificacionFile } from '../../../../../app/models';
import { FileInput } from '../../Justificacion/components/FileInput';
import { useJustificacionDAO } from '../../../../../app/business/presentismo_v3/Justificacion';
import { validateFiles } from '../../Justificacion/validators';
import moment from 'moment';
import { VistaPdfJustificacionVacio } from './vistaPdfJustificacionVacio';
import { ROL } from '../../../../../commons';

const handleOpenFile = async (row: any) => {
  try {
    await customFormDialog.show({
      title: 'Ver Archivo',
      renderComponent: row?.presente?.justificacion?.files[0] ? (
        <VistaPdfJustificacion data={row?.presente?.justificacion?.files[0]} />
      ) : (
        <VistaPdfJustificacionVacio />
      ),
    });
  } catch (error) {
    console.log('error al visualziar PDF', error);
  }
};

export const ModalJustificacion = ({
  row,
  data,
  setData,
  authorizedRole,
}: {
  row: rowJustificativosTypes;
  data: PresenteGeneral[];
  setData: React.Dispatch<React.SetStateAction<PresenteGeneral[]>>;
  authorizedRole: number;
}) => {
  const presenteDAO = usePresenteV3DAO();
  const [disableButton, setdisableButton] = useState(false);
  const [errorUpload, setErrorUpload] = useState<string>('');
  const { enqueueSnackbar } = useSnackbar();
  const justificacionDAO = useJustificacionDAO();
  const isAuthorizedRol = authorizedRole !== ROL.DOCENTE;

  const desasociarJustificacionHandler = async (
    row: rowJustificativosTypes,
  ) => {
    try {
      setdisableButton(true);
      const respuesta = await presenteDAO.aspect('basic').save({
        ...row.presente,
        justificacion: null,
      });

      if (!respuesta) {
        customFormDialog.handleCancel();
        return;
      }
      const updatedPresentismo = data.map((pre) =>
        pre.idPresente === row.presente.idPresente
          ? { ...pre, justificacion: null }
          : pre,
      );
      setData(updatedPresentismo as PresenteGeneral[]);

      setdisableButton(false);
      enqueueSnackbar('La justificación fue eliminada con éxito', {
        variant: 'success',
      });
      customFormDialog.handleCancel();
    } catch (error) {
      console.log('error al eliminar la justificacion en presentismo', error);
      enqueueSnackbar('Error al guardar, favor de intentar nuevamente', {
        variant: 'error',
      });
    }
  };

  const handleUploadFiles = useCallback(
    (justificacion: Justificacion | null) =>
      async (e: React.ChangeEvent<HTMLInputElement>) => {
        setdisableButton(true);
        if (!row) return;
        setErrorUpload('');
        try {
          const target = e.target;
          const files = [...(target.files as FileList)];
          const result = validateFiles(files, row);

          if (result) {
            setErrorUpload(result);
            return;
          }
          const filesInfo: JustificacionFile[] = await Promise.all(
            files.map((file: File) => {
              return new Promise<JustificacionFile>((resolve) => {
                const reader = new FileReader();
                reader.onload = () => {
                  resolve({
                    data: (reader.result as string).split('base64,')[1],
                    name: file.name,
                    mimeType: file.type,
                  });
                };
                reader.readAsDataURL(file);
              });
            }),
          );
          // GUARDFAMOS DE UNA en la DB
          const updatedJustificacion = await justificacionDAO.save({
            ...justificacion,
            files: filesInfo,
          });

          const updatedPresentismo = data.map((pre) =>
            pre.justificacion?.idJustificacion ===
            row.presente.justificacion?.idJustificacion
              ? { ...pre, justificacion: updatedJustificacion }
              : pre,
          );
          setData(updatedPresentismo as PresenteGeneral[]);
          enqueueSnackbar('El archivo fue subido con éxito', {
            variant: 'success',
          });
          customFormDialog.handleCancel();
        } catch (error) {
          enqueueSnackbar('Error al guardar, favor de intentar nuevamente', {
            variant: 'error',
          });
        } finally {
          setdisableButton(false);
        }
      },
    [row, data, justificacionDAO, setErrorUpload, enqueueSnackbar, setData],
  );

  const isExistFile = useMemo(() => {
    const file = row?.presente?.justificacion?.files[0];
    if (!file) return false;
    const paserData: JustificacionFile = JSON.parse(file);
    if (!paserData.data) return false;
    return true;
  }, [row]);

  return (
    <div>
      <Paper
        style={{
          padding: '20px',
          maxWidth: '600px',
          margin: '0 auto',
        }}
      >
        <Box display="flex" mt={2} mb={2}>
          <Box flex={1} ml={5}>
            <Typography variant="subtitle1">
              <strong>Estado:</strong>
            </Typography>
            <Typography>
              {row?.presente?.justificacion?.razonJustificacion?.description}
            </Typography>
          </Box>
          <Box flex={1}>
            <Typography variant="subtitle1">
              <strong>Fecha:</strong>
            </Typography>
            <Typography>
              {moment(row?.presente?.fecha, 'YYYY-MM-DD').format('DD/MM/YYYY')}
            </Typography>
          </Box>
        </Box>

        <Box display="flex" mt={2} ml={5} mb={4}>
          <Box flex={1}>
            <Typography variant="subtitle1">
              <strong>Motivos de Justificación:</strong>
            </Typography>
          </Box>
          <Box flex={1} style={{ marginLeft: '50px' }}>
            <TextField
              fullWidth
              id="standard-disabled"
              multiline
              defaultValue={
                row.presente.justificacion?.motivoJustificacion?.description ||
                ''
              }
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                style: {
                  padding: '5px',
                  marginLeft: '-30px',
                },
              }}
            />
          </Box>
        </Box>

        <Box display="flex" mt={2} ml={5} mb={4}>
          <Box flex={1}>
            <Typography variant="subtitle1">
              <strong>Detalles:</strong>
            </Typography>
          </Box>
          <Box flex={1} style={{ marginLeft: '50px' }}>
            <TextField
              fullWidth
              id="standard-disabled"
              multiline
              defaultValue={row.presente.justificacion?.details || ''}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                style: {
                  padding: '5px',
                  marginLeft: '-30px',
                },
              }}
            />
          </Box>
        </Box>

        <Box display="flex" mt={2} ml={5} mb={4}>
          <Box flex={1}>
            <Typography variant="subtitle1">
              <strong>Turno:</strong>
            </Typography>
          </Box>
          <Box flex={1} style={{ marginLeft: '50px' }}>
            <TextField
              fullWidth
              id="standard-disabled"
              multiline
              defaultValue={row.presente.turno?.descripcionTurno}
              InputProps={{
                readOnly: true,
                disableUnderline: true,
                style: {
                  padding: '5px',
                  marginLeft: '-30px',
                },
              }}
            />
          </Box>
        </Box>

        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <Button
              disabled={false}
              startIcon={<AttachFileIcon />}
              variant="contained"
              onClick={() => handleOpenFile(row)}
              style={{
                backgroundColor: '#3786EE',
                color: 'white',
                marginTop: '20px',
              }}
            >
              Ver Archivo
            </Button>
          </Grid>
          <Grid item>
            <FileInput
              disabled={isExistFile || disableButton}
              onChange={handleUploadFiles(row.presente.justificacion)}
              onBlur={() => {}}
              onFocus={() => {}}
              error={Boolean(errorUpload)}
              helperText={errorUpload}
            />
          </Grid>
        </Grid>
        <Box mt={3} display="flex" justifyContent="space-between">
          {isAuthorizedRol ? (
            <Button
              variant="contained"
              style={{
                backgroundColor: disableButton
                  ? 'rgb(179, 193, 207)'
                  : '#df2b2b',
                color: 'white',
              }}
              onClick={() => {
                desasociarJustificacionHandler(row);
              }}
              disabled={disableButton}
            >
              Eliminar
            </Button>
          ) : null}
          <Button
            variant="contained"
            onClick={() => {
              customFormDialog.handleCancel();
            }}
            style={{ backgroundColor: '#3786EE', color: 'white' }}
          >
            Cancelar
          </Button>
        </Box>
      </Paper>
    </div>
  );
};
