export enum TRAVERSE_ACTIONS {
  LOCALIZACION = 1,
  NIVEL = 2,
  ANIO = 3,
  SECCION = 4,
  ESPACIO_CURRICULAR = 5,
  CUENTAS = 6,
  MATERIA = 7,
  AREA = 8,
  ORIENTACION = 9,
  DISTRITO_ESCOLAR = 10,
  EJE = 11,
  NODO = 12,
}
