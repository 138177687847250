import { useEffect } from 'react';
import { useAlumnoMovimientoLibbyCall } from 'src/app/business';
import { AlumnoMovimiento } from 'src/app/models';
import { useLegajoLayout } from '..';

export const useAlumnoMovimientoFetch = () => {
  const { params } = useLegajoLayout();

  const {
    data: alumnoMovimiento,
    recall: recallAlumnoMovimiento,
    working,
  } = useAlumnoMovimientoLibbyCall<AlumnoMovimiento[]>({
    methodName: 'getByAlumnoId',
    params,
    noAutoCall: true,
    aspect: 'legajo_datosPersonales',
  });

  useEffect(() => {
    recallAlumnoMovimiento(params);
  }, [recallAlumnoMovimiento, params]);

  return {
    data: {
      alumnoMovimiento: alumnoMovimiento?.[0],
    },
    recall: {
      recallAlumnoMovimiento: () => recallAlumnoMovimiento(params),
    },
    working,
  };
};
