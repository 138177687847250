import { useMemo } from 'react';
import {
  useEspacioCurricularSeccionLibbyFetch,
  usePlanEstudioMateriaLibbyFetch,
} from 'src/app/business';
import { useIndicadoresEvaluacionLibbyFetch } from 'src/app/business/IndicadoresEvaluacion';

export const useGetColumns = (idEcs: string, periodo: number = 4) => {
  const ecsFilter = useMemo(
    () => ({ 0: [{ path: 'idEspacioCurricularSeccion', value: idEcs }] }),
    [idEcs],
  );
  const { data: ecs = [], working: espacioCurricularSeccionWorking } =
    useEspacioCurricularSeccionLibbyFetch({
      filter: ecsFilter,
      enabled: !!idEcs,
      limit: 500,
      aspect: 'useGetColumns',
    });
  const planEstudioMateriaFilter = useMemo(
    () => ({
      0: [
        {
          path: 'planEstudio',
          value: ecs[0]?.espacioCurricular.planEstudio.idPlanEstudio,
        },
      ],
      1: [
        {
          path: 'materia',
          value: ecs[0]?.espacioCurricular.materia.idMateria,
        },
      ],
    }),
    [ecs],
  );
  const { data: planEstudioMateria = [], working: planEstudioMateriaWorking } =
    usePlanEstudioMateriaLibbyFetch({
      filter: planEstudioMateriaFilter,
      enabled: !!ecs[0]?.espacioCurricular.materia.idMateria,
    });
  const indicadoresEvaluacionFilter = useMemo(
    () => ({
      0: [
        {
          path: 'planEstudioMateria',
          value: planEstudioMateria[0]?.idPlanEstudioMateria,
        },
      ],
      1: [{ path: 'periodo', value: periodo }],
    }),
    [planEstudioMateria, periodo],
  );

  const { data: indicadoresEvaluacion = [], working: indicadoresWorking } =
    useIndicadoresEvaluacionLibbyFetch({
      filter: indicadoresEvaluacionFilter,
      enabled: !!planEstudioMateria[0]?.idPlanEstudioMateria,
      orderBy: 'orderNro',
    });

  const columns = useMemo(
    () => indicadoresEvaluacion.map((indicador: any) => indicador.pregunta),
    [indicadoresEvaluacion],
  );

  return {
    columns,
    working:
      espacioCurricularSeccionWorking ||
      planEstudioMateriaWorking ||
      indicadoresWorking,
  };
};
