import { LibbyFetchDAO } from './LibbyFetchDAO';

export class PlanStudyLevelDAO extends LibbyFetchDAO {
  constructor() {
    super('plan_estudio_nivel', 'idPlanEstudioNivel');
  }

  getByNivel(nivel: number) {
    return this.query()
      .equals('modalidadNivel.nivel', nivel)
      .limit(0, 10000)
      .run();
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
