import React, { useMemo, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { FormGenericControl } from 'src/lib/templates/components';
import { filtersByNivel, nivelesMap, NivelesMap } from './filtersByNivelConsts';

export interface FiltersByNivelProps {
  nivel: any;
  form: any;
}

type tFiltersToCleanByNiveles = {
  [key: string]: Array<string>;
};

const filtersToCleanByNiveles: tFiltersToCleanByNiveles = {
  inicial: [
    'idRepositorioAudienciaItem',
    'idEspacioCurricular',
    'idArea',
    'idTransversal',
  ],
  primario: ['idEspacioCurricular', 'idRepositorioAudienciaItem', 'idArea'],
  secundario: ['idTransversal', 'idArea'],
};

const mapNivel = (idNivel: keyof NivelesMap): string => nivelesMap[idNivel];

export const FiltersByNivel = ({ nivel, form }: FiltersByNivelProps) => {
  const nivelMapped = useMemo(() => mapNivel(nivel), [nivel]);

  useEffect(() => {
    if (nivelMapped) {
      filtersToCleanByNiveles[nivelMapped].forEach((filter: string) => {
        form.change(filter, undefined);
      });
    }
  }, [nivelMapped, form]);

  return (
    <>
      {nivelMapped &&
        filtersByNivel[nivelMapped].map((filter: any) => (
          <Grid item md={6} sm={12} key={filter.name}>
            <FormGenericControl
              name={filter.name}
              form={form}
              component={filter.component}
              componentProps={filter.componentProps}
            />
          </Grid>
        ))}
    </>
  );
};
