import { LibbyFetchDAO } from './LibbyFetchDAO';

export class EstadoMatriculaDAO extends LibbyFetchDAO {
  constructor() {
    super('estado_matricula', 'idEstadoMatricula');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
