import React from 'react';
import { useCallback } from 'react';
import { FormPromotionDialog } from '../../Initial/PromotionDetail/components/FormPromotionDialog';
import customFormDialog from 'src/commons/services/customFormDialog';
import { useSnackbar } from 'notistack';

export const useHandleClickModal = (
  checkStudent: string | any[],
  yearPassStatus: any,
  sections: any,
  studentsData: any[],
  idCurrentSection: any,
  setStudentsData: (arg0: (prev: any) => any) => void,
  setFormValues: (arg0: any) => void,
  descripcionAnioSection: any,
  localizacionData: any,
  nivel: any,
  // setMateriasPendientes: any,
  // materiasPendientes: any,
) => {
  const { enqueueSnackbar } = useSnackbar();

  const handleClickModal = useCallback(
    async (row: any) => {
      if (checkStudent.length !== 0) {
        try {
          const resolve = await customFormDialog.show({
            title: 'Pase de Año',
            sizeWidth: 'xs',
            renderComponent: (
              <FormPromotionDialog
                {...{
                  yearPassStatus,
                  sections,
                  currentYear: studentsData[0].idAnio,
                  currentCicloLectivo: studentsData[0].cicloLectivo,
                  idCurrentSection,
                  row: studentsData[0],
                  descripcionAnioSection,
                  localizacionData,
                  nivel,
                  // materiasPendientes,
                }}
              />
            ),
          });
          if (resolve) {
            const {
              passSection,
              passStatus,
              pendientesActuales,
              pendientesPrevias,
            } = resolve as any;

            // const newMateriasPendientes = materiasPendientes.map(
            //   (materia: any) => {
            //     return {
            //       idPreviasProyecciones: materia?.idPreviasProyecciones,
            //       idAlumno: materia?.idAlumno,
            //       previas: pendientesPrevias,
            //       pendientes: pendientesActuales,
            //     };
            //   },
            // );

            // setMateriasPendientes(newMateriasPendientes);

            //FIXME si se quita setTimeout no se guarda la seccion en studentsData
            //al poner setStudentsData en el setTimeout se rompe setFormValues, al estar los dos en el setTimeout funcionan correctamente
            setTimeout(() => {
              setStudentsData((prev: any) =>
                prev.map((item: any) =>
                  checkStudent.includes(item.id) &&
                  item?.sectionPass !== 'Articulado'
                    ? {
                        ...item,
                        statusPass: passStatus,
                        sectionPass: passSection,
                        observacion: null,
                        materiasPendientes: {
                          ...item.materiasPendientes,
                          pendientes: pendientesActuales,
                        },
                      }
                    : item,
                ),
              );
              setFormValues(
                studentsData
                  .filter(
                    (item: any) =>
                      checkStudent.includes(item.id) &&
                      item?.sectionPass !== 'Articulado',
                  )
                  .map((student: any) => ({
                    idProyeccion: student.idProyeccion,
                    idSeccion: passSection,
                    alumno: student.idStudent,
                    estadoPase: passStatus,
                    originSeccion: idCurrentSection,
                    idCicloLectivo: student.idCicloLectivo,
                    currentSection: student.currentSection,
                    observacion: null,
                    materiasPendientes: {
                      ...student.materiasPendientes,
                      pendientes: pendientesActuales,
                    },
                  })),
              );
            }, 1000);
          }
        } catch (e) {
          console.error(e);
        }
      } else {
        enqueueSnackbar('No hay alumnos seleccionados', {
          variant: 'error',
        });
      }
    },

    [
      checkStudent,
      descripcionAnioSection,
      enqueueSnackbar,
      idCurrentSection,
      localizacionData,
      nivel,
      sections,
      setFormValues,
      setStudentsData,
      studentsData,
      yearPassStatus,
    ],
  );

  return handleClickModal;
};
