import React, { useCallback } from 'react';
import { useSeccionContext } from 'src/app/business';
import {
  Bloques,
  ContenidoBloque,
  ContenidoBloqueSubnivel,
  ContenidoBloqueSubnivelDos,
  BimestreOrientPlan,
  Seccion,
} from 'src/app/models';
import { useParams } from 'react-router';
import { useDisciplinarOrientadaContext } from '../../../forms/PlanificacionDisciplinarOrientada/Context';
import { useGruposSeccion } from '../../../hooks';

export const useBimestreLogic = () => {
  const { idEspacioCurricular } = useParams();
  const { data: secciones, working: _working } = useSeccionContext();
  const { transformeGrupos, working: gruposSeccion } = useGruposSeccion([
    idEspacioCurricular,
  ]);

  const {
    data: { habilidades, recursos, instrumentos, contenidos, bloques },
    working,
  } = useDisciplinarOrientadaContext();

  const getfilters = useCallback(
    (bimestre: BimestreOrientPlan) => {
      const filterBloque = bloques
        ?.filter((_bloque: Bloques) =>
          bimestre?.bloque?.find(
            (blq: string) => _bloque?.idBloque.toString() === blq,
          ),
        )
        .map(({ idBloque, descripcion }: Bloques) => ({
          id: idBloque,
          descripcion,
        }));

      const filterHabilidades = habilidades
        .filter((habilidad) =>
          bimestre?.habilidad?.find(
            (_habilidad) => habilidad.idHabilidades === Number(_habilidad),
          ),
        )
        .map(({ idHabilidades, descripcion }) => ({
          id: idHabilidades,
          descripcion,
        }));

      const filterRecursos = recursos
        .filter((recurso) =>
          bimestre?.recursos?.find(
            (_recurso) => Number(_recurso) === recurso.idRecursos,
          ),
        )
        .map(({ idRecursos, descripcion }) => ({
          id: idRecursos,
          descripcion,
        }));
      const filterInstrumentos = instrumentos
        .filter((instrumento) =>
          bimestre?.instrumentosEvaluacion?.find(
            (_instrumento) =>
              Number(_instrumento) === instrumento.idInstrumentosDeEvaluacion,
          ),
        )
        .map(({ idInstrumentosDeEvaluacion, descripcion }) => ({
          id: idInstrumentosDeEvaluacion,
          descripcion,
        }));

      const filterContenidos = contenidos
        ?.filter((contenido: ContenidoBloque) =>
          filterBloque.find(
            (blq: { id: string; descripcion: string }) =>
              contenido.bloque.idBloque === blq.id,
          ),
        )
        .map((contenido: ContenidoBloque) => ({
          id: contenido.idContenidoBloque,
          descripcion: contenido.descripcion,
          subLevel: contenido.contenidoBloqueSubnivels
            .filter((subNivel: ContenidoBloqueSubnivel) =>
              bimestre.contenidosEspecificos.find(
                (_contenido: {
                  id: string;
                  subLevel: { id: string; subLevel2: Array<string> }[];
                }) =>
                  subNivel.idContenidoBloqueSubnivel.toString() ===
                  _contenido.id,
              ),
            )
            .map((subNivel: ContenidoBloqueSubnivel) => ({
              id: subNivel.idContenidoBloqueSubnivel,
              descripcion: subNivel.descripcion,
              subLevel2: subNivel.contenidoBloqueSubnivelDos
                .filter((subNivel2: ContenidoBloqueSubnivelDos) =>
                  bimestre?.contenidosEspecificos?.find(
                    (_contenido: {
                      id: string;
                      subLevel: { id: string; subLevel2: Array<string> }[];
                    }) =>
                      !!_contenido.subLevel.find(
                        (_contenido2: {
                          id: string;
                          subLevel2: Array<string>;
                        }) =>
                          _contenido2.subLevel2.find(
                            (valor: string) =>
                              valor ===
                              subNivel2.idContenidoBloqueSubnivelDos.toString(),
                          ),
                      ),
                  ),
                )
                .map(
                  ({
                    idContenidoBloqueSubnivelDos,
                    descripcion,
                  }: ContenidoBloqueSubnivelDos) => ({
                    id: idContenidoBloqueSubnivelDos,
                    descripcion,
                  }),
                ),
            })),
        }));

      const filterSecciones = [
        ...(secciones || []),
        ...transformeGrupos,
      ].filter((_seccion: Seccion) =>
        bimestre?.seccion?.find(
          (valor: string) => _seccion.idSeccion === valor,
        ),
      );

      return {
        filterSecciones,
        filterContenidos,
        filterHabilidades,
        filterRecursos,
        filterInstrumentos,
        filterBloque,
      };
    },
    [
      bloques,
      contenidos,
      recursos,
      habilidades,
      instrumentos,
      secciones,
      transformeGrupos,
    ],
  );
  return { getfilters, loading: working || _working || gruposSeccion };
};
