import { REQUEST_TYPES } from 'src/lib/libby/hooks/useLibbyRequest';
import { makeEntityLibbyRequest } from '../../../lib/libby/hooks/makeEntityLibbyRequest';

const DAO_NAME = 'espaciocurricularseccioncustom';

// hooks
export const useEspaciocurricularseccioncustomGetRequest =
  makeEntityLibbyRequest(DAO_NAME, REQUEST_TYPES.GET);
export const useEspaciocurricularseccioncustomPostRequest =
  makeEntityLibbyRequest(DAO_NAME, REQUEST_TYPES.POST);
export const useEspaciocurricularseccioncustomPutRequest =
  makeEntityLibbyRequest(DAO_NAME, REQUEST_TYPES.PUT);
export const useEspaciocurricularseccioncustomDeleteRequest =
  makeEntityLibbyRequest(DAO_NAME, REQUEST_TYPES.DELETE);
