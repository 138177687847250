import React, { FunctionComponent, ReactNode, useCallback } from 'react';
import { useField } from 'react-final-form-hooks';
import { Input, InputProps } from '../../../app/components/inputs/Input';
import { getErrorAndHelperTextNoTranslation } from '../../../utils/materialHelpers';

// FIXME: type form props
export interface FormInputControlledProps {
  name: string;
  label: string;
  icon: any;
  form: any;
  inputProps?: InputProps;
  placeholder?: string;
  helperText?: string;
  helperTextOnError?: string;
  getNewValue: (oldValue: any) => Promise<any> | any;
  getInputValue: (value: any) => ReactNode;
  disabled?: boolean;
}

export const FormInputControlled = ({
  name,
  form,
  label,
  icon,
  placeholder,
  getNewValue,
  getInputValue,
  inputProps = {},
  disabled,
  helperText = '',
  helperTextOnError = '',
}: FormInputControlledProps): JSX.Element => {
  const field = useField(name, form);

  const onClick = useCallback(async () => {
    if (!disabled) {
      const newValue = await getNewValue(field.input.value);
      field.input.onChange(newValue);
    }
  }, [field.input, getNewValue, disabled]);

  const { onChange, ...restInput } = field.input;

  const { error, helperText: errorText } =
    getErrorAndHelperTextNoTranslation(field);

  const setHelperTextToShow = () => {
    let text: string | undefined = '';
    if (error && !helperTextOnError) {
      text = errorText;
    } else if (error && helperTextOnError) {
      text = helperTextOnError;
    } else if (!error && helperText) {
      text = helperText;
    }
    return text;
  };

  return (
    <Input
      placeholder={placeholder}
      label={label}
      icon={icon}
      {...restInput}
      error={error}
      helperText={setHelperTextToShow()}
      {...inputProps}
      onClick={onClick}
      value={getInputValue(field.input.value)}
    />
  );
};
