import moment from 'moment';
import { useEffect, useState } from 'react';

export const useDebounceDate = (
  value: moment.Moment,
  delay: number,
): moment.Moment => {
  const [debouncedValue, setDebouncedValue] = useState<moment.Moment>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};
