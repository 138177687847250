import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { Loading, MainInput, SimpleSelect } from 'src/commons';
import { useSeccionLibbyFetch } from 'src/app/business';
import { makeStyles } from '@material-ui/core/styles';
import { Anio } from 'src/app/models';

interface formValues {
  anio: string;
  seccion: string;
  estado: string;
}

const useStyles = makeStyles({
  container: {
    marginTop: '2em',
  },
});

const estadoOptions = [
  { label: 'No iniciado' },
  { label: 'En proceso' },
  { label: 'Finalizado' },
];

export const FiltersTableAlumnos = ({
  onChange,
  search,
  setSearch,
  currentFormValues,
}: any) => {
  const classes = useStyles();

  const filterSeccion = useMemo(
    () => ({
      anios: [
        {
          path: 'anio',
          value: [7, 9],
          method: 'in',
        },
      ],
    }),
    [],
  );

  const { data: secciones = [], working: workingSeccion } =
    useSeccionLibbyFetch({
      limit: 500,
      filter: filterSeccion,
      orderBy: 'nombreSeccion',
    });

  const aniosValidos = useMemo(() => {
    const _anios: Anio[] = [];
    secciones.forEach((seccion) => {
      if (!_anios.some((a) => a.idAnio === seccion.anio.idAnio)) {
        _anios.push(seccion.anio);
      }
    });
    return _anios.sort((a, b) => {
      const nameA = a.descripcionAnio.trim();
      const nameB = b.descripcionAnio.trim();
      return nameA.localeCompare(nameB);
    });
  }, [secciones]);

  const seccionesFiltered = useMemo(() => {
    return secciones.filter(
      (seccion) => seccion.anio.idAnio === currentFormValues.anio,
    );
  }, [currentFormValues.anio, secciones]);

  return (
    <Grid container spacing={1} className={classes.container}>
      <Grid item xs={2}>
        {!workingSeccion ? (
          <SimpleSelect
            name="anio"
            title="Año"
            placeholder="Seleccioná el año"
            labelKey="descripcionAnio"
            valueKey="idAnio"
            handleChange={(e: any) => onChange(e)}
            content={aniosValidos}
            value={currentFormValues?.anio}
          />
        ) : (
          <Loading />
        )}
      </Grid>
      <Grid item xs={2}>
        {Boolean(currentFormValues.anio) ? (
          !workingSeccion ? (
            <SimpleSelect
              name="seccion"
              title="Sección"
              placeholder="Seleccioná la sección"
              labelKey="nombreSeccion"
              valueKey="idSeccion"
              handleChange={(e: any) => onChange(e)}
              content={seccionesFiltered}
              value={currentFormValues?.seccion}
              disabled={!currentFormValues.anio}
            />
          ) : (
            <Loading />
          )
        ) : null}
      </Grid>
      <Grid item xs={2}>
        {Boolean(currentFormValues.seccion) && !workingSeccion ? (
          <SimpleSelect
            name="estado"
            title="Estado"
            placeholder="Seleccioná el estado"
            labelKey="label"
            valueKey="label"
            handleChange={(e: any) => onChange(e)}
            content={estadoOptions}
            value={currentFormValues.estado}
          />
        ) : null}
      </Grid>
      <Grid
        container
        xs={4}
        justifyContent="flex-end"
        spacing={3}
        alignItems="flex-end"
      >
        <Grid xs={6}>
          <MainInput
            type="search"
            handleChange={(e: any) => {
              setSearch(e.target.value);
            }}
            placeholder="Buscar"
            name="search"
            value={search}
            fullWidth
            customStyle={{ marginBottom: '17px', marginLeft: '100%' }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
