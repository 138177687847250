import React, { FC } from 'react';
import {
  Chart as ChartJS,
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
} from 'chart.js';
import { Radar } from 'react-chartjs-2';
import { EVALUACIONES } from '../../../screens/Private/AcapEstudiante/components/devolucionesComponents/DevolucionOrganizacion';
import { getWordsOfText } from 'src/utils/textHelpers';

const MAX_CHART_SCALE = 3;
const MIN_CHART_SCALE = 0;

const COLOR_DIANA = '#00A0D655';

const charCodes = Array.from(Array(26)).map((_, i) => i + 65);
const alphabet = charCodes.map((code) => String.fromCharCode(code));

ChartJS.register(
  RadialLinearScale,
  PointElement,
  LineElement,
  Filler,
  Tooltip,
  Legend,
);

const MIN_AXIS_COUNT = 4;

const DianaChart: FC<Acap.Estudiante.DianaProps> = ({
  evaluaciones,
  labels,
  title,
}) => {
  const getLabels = (labels: string[]) => {
    const letters = alphabet.slice(0, labels.length);
    const diff = MIN_AXIS_COUNT - letters.length;
    if (diff > 0) {
      for (let i = 0; i < diff; i++) {
        letters.push('');
      }
    }
    return letters;
  };

  const data = {
    labels: getLabels(labels),
    datasets: [
      {
        label: title,
        data: evaluaciones,
        backgroundColor: COLOR_DIANA,
        borderColor: COLOR_DIANA,
        borderWidth: 1,
      },
    ],
  };

  return (
    <Radar
      options={{
        scales: {
          r: {
            ticks: {
              stepSize: 1,
              count: 4,
              callback: (value: number) => EVALUACIONES[value],
            },
            suggestedMin: MIN_CHART_SCALE,
            suggestedMax: MAX_CHART_SCALE,
            pointLabels: {
              callback: (value: string) => {
                if (value) {
                  const words = getWordsOfText(value);
                  if (value !== '' && words.length > 4) {
                    return [...words.slice(0, 4), '...'].join(' ');
                  } else {
                    return value;
                  }
                }
                return value;
              },
            },
          },
        },
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (context) => {
                const idx = alphabet.findIndex(
                  (letter) => letter === context.label,
                );

                return labels[idx];
              },
            },
            enabled: false,
            external: function (context) {
              let tooltipEl = document.getElementById('chartjs-tooltip');

              if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                document.body.appendChild(tooltipEl);
              }
              // logic for hide tooltip change condition after the unknow req
              let tooltipModel = context.tooltip;
              if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = '0';
                return;
              }

              tooltipEl.classList.remove('above', 'below', 'no-transform');
              if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
              } else {
                tooltipEl.classList.add('no-transform');
              }

              function getBody(bodyItem: { lines: any }) {
                return bodyItem.lines;
              }

              if (tooltipModel.body) {
                let titleLines = tooltipModel.title || [];
                let bodyLines = tooltipModel.body.map(getBody);

                let innerHtml = '<thead>';

                titleLines.forEach(function (title) {
                  innerHtml += '<tr><th>' + title + '</th></tr>';
                });
                innerHtml += '</thead><tbody>';

                bodyLines.forEach(function (body, i) {
                  let colors = tooltipModel.labelColors[i];
                  let style = 'background:' + colors.backgroundColor;
                  style += '; border-color:' + colors.borderColor;
                  style += '; border-width: 2px';
                  let span = '<span style="' + style + '"></span>';
                  innerHtml += '<tr><td>' + span + body + '</td></tr>';
                });
                innerHtml += '</tbody>';

                let tableRoot = tooltipEl.querySelector('table');
                if (tableRoot) {
                  tableRoot.innerHTML = innerHtml;
                } else {
                  console.error('tableRoot doest exist');
                }
              }

              let position = context.chart.canvas.getBoundingClientRect();

              tooltipEl.style.opacity = '1';
              tooltipEl.style.position = 'absolute';
              tooltipEl.style.left = position.left + window.scrollX + 'px';
              tooltipEl.style.top =
                position.top + window.scrollY + tooltipModel.caretY + 'px';
              tooltipEl.style.maxWidth = '500px';
              tooltipEl.style.fontFamily = 'Open Sans';
              tooltipEl.style.padding = '10px';
              tooltipEl.style.backgroundColor = 'rgba(0,0,0,0.7)';
              tooltipEl.style.color = 'white';
              tooltipEl.style.zIndex = '1000';
            },
          },
        },
      }}
      data={data}
    />
  );
};

export default DianaChart;
