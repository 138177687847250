import React, { useMemo } from 'react';
import {
  makeStyles,
  Grid,
  IconButton,
  Divider,
  Tooltip,
} from '@material-ui/core';
import { Footer } from 'src/commons';
import DeleteIcon from '@material-ui/icons/Delete';
import { optionsSort } from 'src/utils';
import { AnyObject } from '../../../../../../../../../commons/types';
import {
  FormAutocomplete,
  FormInput,
} from '../../../../../../../../../lib/templates/components';
import { RecursosDialog } from '../../../../../dialogs';
import { grey } from '../../../../../../../../../theme/colors';
import { usePlanificationForm } from '../../../commons/hooks';
import { CommonCheckboxFormInput } from '../../../../../components';
import { useRecursosFetch } from '../hooks';

const useStyles = makeStyles({
  container: {
    padding: '32px 10px',
    marginBottom: 60,
    paddingRight: 60,
  },
  containerForm: {
    borderBottom: `1px solid ${grey.normal}`,
    backgroundColor: 'rgba(218, 218, 218, 0.17)',
    padding: 20,
    marginBottom: 15,
    borderRadius: 5,
  },
  listItem: {
    padding: 0,
  },
  listItemIcon: {
    marginRight: '-20px',
  },
  text: {
    color: grey.medium,
  },
  divider: { width: '100%', margin: '20px 0px 15px 0px' },
  marginDeleteIcon: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    paddingRight: 30,
  },
  title: {
    paddingTop: 10,
  },
});

export type Periodo = { id: number; name: string };

export const periodos: Periodo[] = [
  { id: 1, name: '1º Bimestre' },
  { id: 2, name: '2º Bimestre' },
  { id: 3, name: '3º Bimestre' },
  { id: 4, name: '4º Bimestre' },
];

export const Actividad = () => {
  const classes = useStyles();
  const { values, form, errors } = usePlanificationForm();
  const { recursos } = useRecursosFetch();

  const footerCustomStyle = { marginTop: '15px' };

  const addNew = () => {
    const newValue = values?.actividad?.actividades;
    newValue.push({
      nombre: '',
      descripcion: '',
      horasCatedra: '',
      recursos: [],
      periodo: [],
    });
    form.change('actividad', {
      ...values.actividad,
      actividades: [...newValue],
    });
  };

  const onRemoveItem = (index: any) => {
    const newInput = values?.actividad?.actividades.filter(
      (item: any, ind: number) => {
        return index !== ind;
      },
    );
    form.change('actividad', {
      ...values.actividad,
      actividades: [...newInput],
    });
  };
  const isLastItem = values.actividad.actividades.length;

  const sortedResources = useMemo(() => optionsSort(recursos), [recursos]);
  return (
    <Grid container direction="column" className={classes.container}>
      {values?.actividad?.actividades?.map((item: AnyObject, index: number) => (
        <Grid direction="row" className={classes.containerForm}>
          <Grid xs={12} direction="row" container>
            <Grid item xs={12} className={classes.marginDeleteIcon}>
              <Tooltip title="Eliminar Actividad">
                <IconButton edge="end" aria-label="delete" key={index}>
                  <DeleteIcon onClick={() => onRemoveItem(index)} />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
          <Grid item xs={12} direction="row" spacing={2} container>
            <Grid item xs={4}>
              <FormInput
                key={index}
                label="Nombre"
                name={`actividad.actividades[${index}].nombre`}
                form={form}
                placeholder="Escribí el nombre de la actividad"
              />
            </Grid>
            <Grid item xs={4}>
              <FormInput
                key={index}
                label="Descripcion"
                name={`actividad.actividades[${index}].descripcion`}
                form={form}
                placeholder="Describí la actividad"
              />
            </Grid>
            <Grid item xs={4}>
              <CommonCheckboxFormInput
                label="Recursos"
                name={`actividad.actividades[${index}].recursos`}
                form={form}
                placeholder="Ingresá los recursos a usar"
                dialog={RecursosDialog}
                options={sortedResources}
                optionIdKey="idRecursos"
              />
            </Grid>
            <Grid item xs={2}>
              <FormInput
                key={index}
                label="Horas cátedra"
                name={`actividad.actividades[${index}].horasCatedra`}
                form={form}
                placeholder="Ingresá horas"
              />
            </Grid>
            <Grid item xs={2}>
              <FormAutocomplete
                key={index}
                label="Período"
                name={`actividad.actividades[${index}].periodo`}
                form={form}
                options={periodos}
                optionLabelKey="name"
                valueToOption={(value: string[], options: Periodo[]) =>
                  value.map((periodo) =>
                    options.find((_item) => _item.id.toString() === periodo),
                  )
                }
                optionToValue={(option: Periodo) => option.id.toString()}
                multiple
              />
            </Grid>

            {isLastItem !== index + 1 && (
              <Divider className={classes.divider} />
            )}
          </Grid>
        </Grid>
      ))}
      <Footer
        buttonConfig={[
          {
            title: 'Agregar Actividad',
            handleOnClick: () => addNew(),
          },
        ]}
        position="flexd-center"
        spacing={1}
        customStyle={footerCustomStyle}
      />
      <Grid item xs={3} className={classes.containerForm}>
        <FormAutocomplete
          label="Período de la calificación final del proyecto"
          name="actividad.periodoDeCalificacionFinalDelProyecto"
          form={form}
          options={periodos}
          optionLabelKey="name"
          valueToOption={(value: string[], options: Periodo[]) => {
            if (value.length > 1) {
              value.splice(0, 1);
              return value.map((periodo) =>
                options.find((_item) => _item.id.toString() === periodo),
              );
            }
            return value.map((periodo) =>
              options.find((_item) => _item.id.toString() === periodo),
            );
          }}
          optionToValue={(option: Periodo) => option.id.toString()}
          multiple
        />
      </Grid>
    </Grid>
  );
};
