import { InputIndicadorTipoModal } from '../InformeCuatrimestral/components/InputIndicadorTipoModal';
import {
  AniosNivelInicial,
  Cuatrimestre,
  IndicadorTipoAdjunto,
  IndicadorTipoModal,
  IndicadorTipoSelect,
  IndicadorTipoTextoModal,
} from '../informesTypes';
import { ANIOS_NIVEL_INICIAL, PPI } from './informesConst';

export const indicadores2022: {
  [key in Cuatrimestre]:
    | IndicadorTipoSelect[]
    | IndicadorTipoModal[]
    | IndicadorTipoTextoModal[]
    | IndicadorTipoAdjunto[];
} = {
  I: [
    {
      id: 'ppi',
      label: '¿Posee PPI?',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      labelKey: 'descripcion',
      valueKey: 'descripcion',
      selectOptions: PPI,
      tipoIndicador: 'select',
      orderInTable: 1,
      usedInAnios: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
      requiredFor: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
    },
    {
      id: 'aspectosEspecificos',
      label:
        '¿Cuál es el área y los aspectos específicos de la misma, que considera a fortalecer durante esta  segunda etapa del año?',
      width: '20%',
      hideSortIcon: true,
      orderById: '',
      modalTitle: 'Aspectos Específicos',
      tipoIndicador: 'texto_modal',
      orderInTable: 2,
      usedInAnios: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
      requiredFor: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
    },
    {
      id: 'vinculoPares',
      label:
        '¿Cuál o cuáles aspectos necesita continuar trabajando en relación al vínculo con pares y docentes en la presencialidad  plena?',
      width: '20%',
      hideSortIcon: true,
      orderById: '',
      modalTitle: 'Vínculos',
      tipoIndicador: 'texto_modal',
      orderInTable: 3,
      usedInAnios: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
      requiredFor: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
    },
    {
      id: 'observaciones',
      label: 'Observaciones',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      modalTitle: 'Observaciones',
      tipoIndicador: 'texto_modal',
      orderInTable: 4,
      usedInAnios: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
      requiredFor: [],
    },
    {
      id: 'informe',
      label: 'Informe',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      acceptFileType: '.pdf',
      tipoIndicador: 'adjunto',
      orderInTable: 5,
      usedInAnios: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
      requiredFor: Object.keys(ANIOS_NIVEL_INICIAL).filter(
        (anio) =>
          anio !== '5_ANIOS' && anio !== 'MULTIEDAD' && anio !== '4_Y_5_ANIOS',
      ) as AniosNivelInicial[],
    },
    {
      id: 'informeMusica',
      label: 'Informe Música',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      acceptFileType: '.pdf',
      tipoIndicador: 'adjunto',
      orderInTable: 6,
      usedInAnios: ['4_Y_5_ANIOS'],
      requiredFor: [],
    },
    {
      id: 'informeEdFisica',
      label: 'Informe Ed. Física',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      acceptFileType: '.pdf',
      tipoIndicador: 'adjunto',
      orderInTable: 7,
      usedInAnios: ['4_Y_5_ANIOS'],
      requiredFor: [],
    },
    {
      id: 'informesCurriculares',
      label: 'Informes Curriculares: Ed. Física/Música',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      acceptFileType: '.pdf',
      tipoIndicador: 'adjunto',
      orderInTable: 7,
      usedInAnios: ['5_ANIOS'],
      requiredFor: [],
    },
  ],
  II: [
    {
      id: 'ppi',
      label: '¿Posee PPI?',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      labelKey: 'descripcion',
      valueKey: 'descripcion',
      selectOptions: PPI,
      tipoIndicador: 'select',
      orderInTable: 1,
      usedInAnios: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
      requiredFor: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
    },
    {
      id: 'aspectosEspecificos',
      label:
        '¿Cuál es el área y los aspectos especificos de la misma, que considera a fortalecer durante esta segunda etapa del año?',
      width: '20%',
      hideSortIcon: true,
      orderById: '',
      modalTitle: 'Aspectos Específicos',
      tipoIndicador: 'texto_modal',
      orderInTable: 2,
      usedInAnios: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
      requiredFor: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
    },
    {
      id: 'vinculoPares',
      label:
        '¿Cuál o cuáles aspectos necesita continuar trabajando en relación al vínculo con pares y docentes en la presencialidad plena?',
      width: '20%',
      hideSortIcon: true,
      orderById: '',
      modalTitle: 'Vínculos',
      tipoIndicador: 'texto_modal',
      orderInTable: 3,
      usedInAnios: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
      requiredFor: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
    },
    {
      id: 'observaciones',
      label: 'Observaciones',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      modalTitle: 'Observaciones',
      tipoIndicador: 'texto_modal',
      orderInTable: 4,
      usedInAnios: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
      requiredFor: [],
    },
    {
      id: 'informe',
      label: 'Informe',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      acceptFileType: '.pdf',
      tipoIndicador: 'adjunto',
      orderInTable: 5,
      usedInAnios: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
      requiredFor: [],
    },
    {
      id: 'informeMusica',
      label: 'Informe Música',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      acceptFileType: '.pdf',
      tipoIndicador: 'adjunto',
      orderInTable: 6,
      usedInAnios: ['4_Y_5_ANIOS', '5_ANIOS', 'MULTIEDAD'],
      requiredFor: ['4_Y_5_ANIOS', '5_ANIOS', 'MULTIEDAD'],
    },
    {
      id: 'informeEdFisica',
      label: 'Informe Ed. Física',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      acceptFileType: '.pdf',
      tipoIndicador: 'adjunto',
      orderInTable: 7,
      usedInAnios: ['4_Y_5_ANIOS', '5_ANIOS', 'MULTIEDAD'],
      requiredFor: ['4_Y_5_ANIOS', '5_ANIOS', 'MULTIEDAD'],
    },
  ],
};
