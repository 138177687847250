import { useCallback, useEffect } from 'react';

export default function useDebounce(
  fn: () => void,
  dependencies: Array<any>,
  delay: number,
): void {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const callback = useCallback(fn, dependencies);

  useEffect(() => {
    const timeout = setTimeout(callback, delay);

    return () => clearTimeout(timeout);
  }, [callback, delay]);
}
