import { LegajoAlumnoScreen } from 'src/screens/Private/LegajoAlumno/LegajoAlumno';
import { MenuItem } from '../../../../commons/const/ui/menu';

export const routes: MenuItem[] = [
  {
    key: 'legajoAlumnoMain',
    basePath: '/',
    target: {
      component: LegajoAlumnoScreen,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'LegajoAlumno',
    children: [],
    default: true,
  },
];
