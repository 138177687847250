import { Observacionesc } from 'src/app/models';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from 'src/lib/libby';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'observacionesc_custom';

const defaultOptions: PartialBusinessEntityOptions<any> = {};

const optionsToUse = !businessOptions.ObservacionescCustom
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.ObservacionescCustom,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.ObservacionescCustom.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Observacionesc>(DAO_NAME, optionsToUse);

// hooks
export const useObservacionescCustomFetchById = fetchById;
export const useObservacionescCustomLibbyCall = libbyCall;
export const useObservacionescCustomDAO = libbyDAO;
export const useObservacionescCustomLibbyFetch = libbyFetch;

// components/hoc
export const ObservacionescCustomAutocomplete = entityAutocomplete;
export const ObservacionescCustomCall = renderPropCall;
export const ObservacionescCustomById = renderPropFetchById;
export const ObservacionescCustomSelect = simpleSelect;
export const ObservacionescCustomAccordionSelector = accordionSelector;
export const ObservacionescCustomSelectedChips = selectedChips;

// context
// ByIdContext
export const useObservacionescCustomByIdContext = useByIdContext;
export const ObservacionescCustomByIdProvider = ByIdProvider;
export const ObservacionescCustomByIdContext = ByIdContext;
// GeneralContext
export const useObservacionescCustomContext = useGeneralContext;
export const ObservacionescCustomProvider = GeneralProvider;
export const ObservacionescCustomContext = GeneralContext;
