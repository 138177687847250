import React from 'react';

const DiasHabiIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    enable-background="new 0 0 24 24"
    height="14px"
    viewBox="0 0 24 24"
    width="14px"
    fill="#ffffff"
  >
    <g>
      <rect fill="none" height="24" width="24" />
    </g>
    <g>
      <g>
        <g>
          <path d="M21,3H3C1.9,3,1,3.9,1,5v8h2V5h18v16c1.1,0,2-0.9,2-2V5C23,3.9,22.1,3,21,3z" />
        </g>
        <g>
          <circle cx="9" cy="10" r="4" />
        </g>
        <g>
          <path d="M15.39,16.56C13.71,15.7,11.53,15,9,15c-2.53,0-4.71,0.7-6.39,1.56C1.61,17.07,1,18.1,1,19.22V22h16v-2.78 C17,18.1,16.39,17.07,15.39,16.56z" />
        </g>
      </g>
    </g>
  </svg>
);

export default DiasHabiIcon;
