import React, { useEffect, useMemo } from 'react';
import moment from 'moment';
import { uniqBy } from 'lodash';
import { useHistory } from 'react-router';
import { Box, Grid, Tooltip, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { DatePicker } from 'src/app/components';
import { MainButton, SimpleSelect } from 'src/commons';
import {
  Accion,
  GrupoJornada,
  GruposInscripciones,
  OfertaGrupo,
  Ofertas,
  Referente,
  Sedes,
  Turno,
} from 'src/app/models';
import { getFechasByDays } from '../../AcapEstudiante/utils/helperFechas';
import { TextFieldV2 } from '../../../../lib/templates';
import { useColegioEfectivizadoContext } from '../../Calificaciones/context/EfectivizacionProvider';

const useStyles = makeStyles({
  datePickerStyles: {
    border: 0,
    fontSize: '13px',
  },
});

export const FiltersHeader = ({
  inscriptos = [],
  formValues,
  setFormValues,
  setFilters,
  ofertas = [],
  ofertasGrupos = [],
  workingAll,
  isInsideSchool,
  organizacionesEscuela,
  organizacionSeleccionada,
  row,
  acciones,
  isReferenteAccion,
  ofertaReferenteAccion,
  setShowTable,
}: any) => {
  const { cicloLectivo: cicloLectivoContext } = useColegioEfectivizadoContext();

  const history = useHistory();
  const filteredOrganizaciones = inscriptos
    .filter(
      (i) =>
        i?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.cicloLectivo
          ?.idCicloLectivo === cicloLectivoContext?.idCicloLectivo,
    )
    .map(
      (io) =>
        io?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.organizacion
          ?.idOrganizacion,
    );

  const uniqueOrganizaciones = [...new Set(filteredOrganizaciones)];

  const organizacionesEscuelaFiltered = organizacionesEscuela.filter((org) =>
    uniqueOrganizaciones.includes(org.idOrganizacion),
  );
  const isReferenteOrganizacionAccion =
    history?.location?.pathname?.includes('referente');
  const ofertasGruposMapped = useMemo(() => {
    const gruposFormatted = ofertasGrupos?.map((ofertaGrupo: any) => {
      return {
        ...ofertaGrupo,
        label: `${ofertaGrupo?.ofertaTurno?.oferta?.accion?.nombre}, ${ofertaGrupo?.ofertaTurno?.turno?.descripcionTurno}, id: ${ofertaGrupo?.idOfertaGrupo}`,
      };
    });
    if (isReferenteAccion) {
      return ofertasGrupos
        ?.filter((grupo: any) => {
          return ofertaReferenteAccion.some(
            (oferta: any) =>
              grupo?.ofertaTurno?.oferta?.idOferta ===
                oferta?.ofertaTurno?.oferta?.idOferta &&
              oferta?.idOfertaGrupo === grupo?.idOfertaGrupo,
          );
        })
        .map((ofertaGrupo: any) => {
          return {
            ...ofertaGrupo,
            label: `${ofertaGrupo?.ofertaTurno?.oferta?.accion?.nombre}, ${ofertaGrupo?.ofertaTurno?.turno?.descripcionTurno}, id: ${ofertaGrupo?.idOfertaGrupo}`,
          };
        });
    }
    return isInsideSchool
      ? gruposFormatted.filter((grupo: any) =>
          inscriptos.some(
            (inscripto: GruposInscripciones) =>
              inscripto?.ofertaGrupo?.idOfertaGrupo === grupo?.idOfertaGrupo,
          ),
        )
      : gruposFormatted;
  }, [
    ofertasGrupos,
    inscriptos,
    isInsideSchool,
    isReferenteAccion,
    ofertaReferenteAccion,
  ]);

  const sedesFiltered = useMemo(() => {
    if (isReferenteAccion) {
      const grupos = ofertaReferenteAccion?.filter(
        (oferta: OfertaGrupo) =>
          oferta?.ofertaTurno?.oferta?.idOferta ===
          row?.ofertaTurno?.oferta?.idOferta,
      );
      return organizacionSeleccionada?.sedes.filter((sede: Sedes) =>
        grupos?.some(
          (ofertaGrupo: OfertaGrupo) =>
            ofertaGrupo.jornadas?.[0]?.sede?.idSede === sede?.idSede,
        ),
      );
    }
    return isInsideSchool && organizacionSeleccionada?.sedes
      ? organizacionSeleccionada?.sedes.filter((sede: Sedes) =>
          (inscriptos as GruposInscripciones[]).some((inscripto) =>
            inscripto?.ofertaGrupo?.jornadas.some(
              (jornada) => jornada?.sede?.idSede === sede?.idSede,
            ),
          ),
        )
      : organizacionSeleccionada?.sedes;
  }, [
    isInsideSchool,
    inscriptos,
    organizacionSeleccionada,
    ofertaReferenteAccion,
    isReferenteAccion,
    row,
  ]);

  useEffect(() => {
    if (row !== undefined && !isInsideSchool) {
      const idSedes = row?.jornadas?.map(
        (item: GrupoJornada) => item.sede.idSede,
      );

      const idSedesDistintos = [...new Set(idSedes)];
      let filterSede = idSedesDistintos.length >= 1 ? idSedesDistintos : null;

      setFormValues({
        ...formValues,
        ofertaGrupo: row?.idOfertaGrupo,
        turno: row?.ofertaTurno.turno.idTurno,
        referenteActividad: row?.referente?.idReferente,
        actividad: row?.ofertaTurno?.oferta?.accion?.idAccion,
        ...(filterSede !== null && { sede: formValues?.sede || filterSede[0] }),
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles();

  const referentes = useMemo(() => {
    let arrayReferentes: Referente[] = [];

    ofertasGrupos?.forEach((ofertaGrupo: OfertaGrupo) => {
      arrayReferentes.push(ofertaGrupo?.referente);
    });

    const filteredByNameArr = uniqBy(arrayReferentes, 'email');

    if (isReferenteAccion) {
      return filteredByNameArr.filter((ref) => {
        return ref?.idReferente === row?.referente?.idReferente;
      });
    }

    return isInsideSchool
      ? filteredByNameArr.filter((referente) =>
          inscriptos.some(
            (inscripto: GruposInscripciones) =>
              inscripto?.ofertaGrupo?.referente?.idReferente ===
              referente?.idReferente,
          ),
        )
      : filteredByNameArr;
  }, [ofertasGrupos, inscriptos, isInsideSchool, isReferenteAccion, row]);

  const turnos = useMemo(() => {
    const turnosArray: Turno[] = [];

    ofertas?.forEach((oferta: Ofertas) => {
      oferta?.turnos?.forEach((ofertaTurno) => {
        const turnoAlreadyInArray = turnosArray?.some(
          (turnoArray) => ofertaTurno?.turno?.idTurno === turnoArray?.idTurno,
        );

        if (!turnoAlreadyInArray) {
          turnosArray?.push(ofertaTurno?.turno);
        }
      });
    });

    if (isReferenteAccion) {
      const sede = formValues?.sede;
      return turnosArray.filter((turno) =>
        ofertaReferenteAccion.some((ofertaGrupo: OfertaGrupo) =>
          sede
            ? row?.ofertaTurno?.oferta?.idOferta ===
                ofertaGrupo?.ofertaTurno?.oferta?.idOferta &&
              turno?.idTurno === ofertaGrupo?.ofertaTurno?.turno?.idTurno &&
              ofertaGrupo?.jornadas?.[0]?.sede?.idSede === sede
            : row?.ofertaTurno?.oferta?.idOferta ===
                ofertaGrupo?.ofertaTurno?.oferta?.idOferta &&
              turno?.idTurno === ofertaGrupo?.ofertaTurno?.turno?.idTurno,
        ),
      );
    }

    return isInsideSchool
      ? turnosArray.filter((turno) =>
          (inscriptos as GruposInscripciones[]).some(
            (inscripto) =>
              inscripto?.ofertaGrupo?.ofertaTurno?.turno?.idTurno ===
              turno?.idTurno,
          ),
        )
      : turnosArray;
  }, [
    ofertas,
    inscriptos,
    isInsideSchool,
    isReferenteAccion,
    row,
    ofertaReferenteAccion,
    formValues,
  ]);

  const accionesFiltered = useMemo(() => {
    let accionesByOrganizacion = acciones;
    if (formValues?.organizacion) {
      accionesByOrganizacion = acciones.filter(
        (accion: Accion) =>
          accion.organizacion.idOrganizacion === formValues.organizacion,
      );
    }
    if (isReferenteAccion) {
      return accionesByOrganizacion.filter(
        (accion: Accion) =>
          accion?.idAccion === row?.ofertaTurno?.oferta?.accion?.idAccion,
      );
    }
    return isInsideSchool
      ? accionesByOrganizacion.filter((accion: Accion) =>
          (inscriptos as GruposInscripciones[]).some(
            (inscripto) =>
              inscripto?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.idAccion ===
              accion?.idAccion,
          ),
        )
      : accionesByOrganizacion;
  }, [
    isInsideSchool,
    acciones,
    inscriptos,
    isReferenteAccion,
    row,
    formValues,
  ]);

  const encuentros = useMemo(() => {
    if (isInsideSchool) {
      const encuentros: String[] = [];
      ofertasGrupos.forEach((grupo: OfertaGrupo) => {
        const tempEnc = getFechasByDays(
          moment(grupo?.fechaInicio).format('YYYY-MM-DD'),
          moment(grupo?.fechaFin).format('YYYY-MM-DD'),
          grupo?.jornadas?.map((jornada: GrupoJornada) => jornada.cupo),
        );
        encuentros.push(...tempEnc);
      });
      return [...new Set(encuentros)];
    } else {
      return getFechasByDays(
        moment(
          row?.fechaInicio === null
            ? row?.ofertaTurno?.oferta?.fechaInicio
            : row?.fechaInicio,
        ).format('YYYY-MM-DD'),
        moment(
          row?.fechaFin === null
            ? row?.ofertaTurno?.oferta?.fechaFin
            : row?.fechaFin,
        ).format('YYYY-MM-DD'),
        row?.jornadas?.map((jornada: GrupoJornada) => jornada.cupo),
      );
    }
  }, [row, isInsideSchool, ofertasGrupos]);

  const actividadFiltro = (
    <Grid item xs={3} container>
      <Grid item xs={12}>
        <Typography>Actividad:</Typography>
      </Grid>
      <Grid item xs={12}>
        <SimpleSelect
          name="sede"
          value={formValues?.actividad}
          handleChange={(e: any) => {
            setShowTable(true);
            setFormValues({ ...formValues, actividad: e.target.value });
          }}
          content={accionesFiltered}
          valueKey="idAccion"
          labelKey="nombre"
          placeholder="Seleccione una actividad"
          loading={workingAll}
        />
      </Grid>
    </Grid>
  );

  const referenteFiltro = (
    <Grid item xs={3} container>
      <Grid item xs={12}>
        <Typography>Referente de Actividad:</Typography>
      </Grid>
      <Grid item xs={12}>
        <SimpleSelect
          disabled={isInsideSchool ? !formValues?.sede : false}
          name="sede"
          value={formValues?.referenteActividad}
          handleChange={(e: any) => {
            setShowTable(true);
            setFormValues({
              ...formValues,
              referenteActividad: e.target.value,
            });
          }}
          content={referentes}
          valueKey="idReferente"
          labelKey="nombre"
          placeholder="Seleccione un referente"
          loading={workingAll}
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid
      container
      component={Box}
      border="1px solid grey"
      marginY={2}
      padding={2}
      spacing={2}
    >
      <Grid item xs={3} container>
        <Grid item xs={12}>
          <Typography>Fecha:</Typography>
        </Grid>
        <Grid item xs={12}>
          <DatePicker
            fullWidth
            disableFuture={true}
            shouldDisableDate={(date) => {
              if (date) {
                return !encuentros?.includes(moment(date).format('YYYY-MM-DD'));
              } else {
                return false;
              }
            }}
            value={formValues?.fecha}
            onChange={(e) => {
              setShowTable(true);
              setFormValues({ ...formValues, fecha: e });
            }}
            disabled={isInsideSchool ? !formValues?.organizacion : workingAll}
            className={classes.datePickerStyles}
          />
        </Grid>
      </Grid>
      <Grid item xs={3} container>
        <Grid item xs={12}>
          <Typography>Organización:</Typography>
        </Grid>
        <Grid item xs={12}>
          {!isInsideSchool ? (
            <TextFieldV2
              value={organizacionSeleccionada?.nombre}
              placeholder="Organizacion"
              InputProps={{
                disableUnderline: true,
                readOnly: true,
              }}
            />
          ) : (
            <SimpleSelect
              name="organizacion"
              value={formValues?.organizacion}
              loading={workingAll}
              handleChange={(e: any) =>
                setFormValues({
                  organizacion: e.target.value,
                })
              }
              content={organizacionesEscuelaFiltered}
              valueKey="idOrganizacion"
              labelKey="nombre"
              placeholder="Seleccione una Organización"
            />
          )}
        </Grid>
      </Grid>
      <Grid item xs={3} container>
        <Grid item xs={12}>
          <Typography>Referente Organización:</Typography>
        </Grid>
        <Grid item xs={12}>
          <Tooltip
            title={
              organizacionSeleccionada
                ? `${organizacionSeleccionada?.referente?.apellido}, ${organizacionSeleccionada?.referente?.nombre}`
                : ''
            }
          >
            <span>
              <TextFieldV2
                value={
                  organizacionSeleccionada
                    ? `${organizacionSeleccionada?.referente?.apellido}, ${organizacionSeleccionada?.referente?.nombre}`
                    : ''
                }
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
                placeholder="Referente Organización"
              />
            </span>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={3} container>
        <Grid item xs={12}>
          <Typography>Sede:</Typography>
        </Grid>
        <Grid item xs={12}>
          <SimpleSelect
            name="sede"
            value={formValues?.sede}
            handleChange={(e: any) => {
              setShowTable(true);
              setFormValues({ ...formValues, sede: e.target.value });
            }}
            content={sedesFiltered}
            valueKey="idSede"
            labelKey="direccion"
            placeholder="Seleccione una sede"
            loading={workingAll}
          />
        </Grid>
      </Grid>
      {isReferenteOrganizacionAccion ? referenteFiltro : actividadFiltro}
      {isReferenteOrganizacionAccion ? actividadFiltro : referenteFiltro}
      <Grid item xs={3} container>
        <Grid item xs={12}>
          <Typography>Turno:</Typography>
        </Grid>
        <Grid item xs={12}>
          <SimpleSelect
            name="sede"
            value={formValues?.turno}
            handleChange={(e: any) => {
              setShowTable(true);
              setFormValues({ ...formValues, turno: e.target.value });
            }}
            content={turnos}
            valueKey="idTurno"
            labelKey="descripcionTurno"
            placeholder="seleccione turno"
            loading={workingAll}
          />
        </Grid>
      </Grid>
      <Grid item xs={3} container>
        <Grid item xs={12}>
          <Typography>Grupo:</Typography>
        </Grid>
        <Grid item xs={12}>
          <SimpleSelect
            disabled={!formValues?.turno}
            name="sede"
            value={formValues?.ofertaGrupo}
            handleChange={(e: any) => {
              setShowTable(true);
              setFormValues({ ...formValues, ofertaGrupo: e.target.value });
            }}
            content={ofertasGruposMapped}
            valueKey="idOfertaGrupo"
            labelKey="label"
            placeholder="seleccione grupo"
            loading={workingAll}
          />
        </Grid>
      </Grid>
      <Grid item xs={12} container justifyContent="center" alignItems="center">
        <MainButton
          title={'Buscar'}
          customStyle={{ marginTop: '30px' }}
          handleOnClick={() => {
            setShowTable(false);
            setFilters();
          }}
          disabled={
            workingAll ||
            !Boolean(
              isInsideSchool
                ? formValues?.ofertaGrupo &&
                    formValues?.actividad &&
                    formValues?.referenteActividad
                : formValues?.sede,
            )
          }
        />
      </Grid>
    </Grid>
  );
};
