import { makeBusinessEntity } from '../../lib/libby/generator';

const DAO_NAME = 'boletin_alumno';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<any>(DAO_NAME);

// hooks
export const useBoletinAlumnoFetchById = fetchById;
export const useBoletinAlumnoLibbyCall = libbyCall;
export const useBoletinAlumnoDAO = libbyDAO;
export const useBoletinAlumnoLibbyFetch = libbyFetch;

// components/hoc
export const BoletinAlumnoAutocomplete = entityAutocomplete;
export const BoletinAlumnoCall = renderPropCall;
export const BoletinAlumnoById = renderPropFetchById;
export const BoletinAlumnoSelect = simpleSelect;
export const BoletinAlumnoAccordionSelector = accordionSelector;
export const BoletinAlumnoSelectedChips = selectedChips;

// context
// ByIdContext
export const useBoletinAlumnoByIdContext = useByIdContext;
export const BoletinAlumnoByIdProvider = ByIdProvider;
export const BoletinAlumnoByIdContext = ByIdContext;
// GeneralContext
export const useBoletinAlumnoContext = useGeneralContext;
export const BoletinAlumnoProvider = GeneralProvider;
export const BoletinAlumnoContext = GeneralContext;
