import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { MainInput, useDebounce, SimpleSelect } from 'src/commons';
import {
  PeriodoSelect,
  CicloLectivoSelect,
  TipoInformesSelect,
  usePeriodoLibbyFetch,
} from 'src/app/business';
import { NIVEL } from 'src/commons/const';
import { useRolesContext } from 'src/context/RolesContext';
import { cloneDeep } from 'lodash';
const customStyles = {
  searchGrid: { marginTop: 10 },
  descargar: { marginBottom: '4px' },
  containerStyles: { marginTop: 23 },
};

export const FilterTablesInformes = ({ onFilterChanged }: any) => {
  const {
    selectedRole: { nivel },
  } = useRolesContext();
  const [search, setSearch] = useState<string>('');
  const [filter, setFilter] = useState<any>({
    idCicloLectivo: '',
    periodo: '',
    tipoInforme: '',
  });

  const filterPeriodoNivel = useMemo(
    () => ({
      0: [{ path: 'nivel', value: nivel }],
    }),
    [nivel],
  );

  const { data: periodos = [] } = usePeriodoLibbyFetch({
    limit: 50,
    filter: filterPeriodoNivel,
    orderBy: 'tipoPeriodo.idTipoPeriodo',
  });

  const periodosAdjusted = useMemo(() => {
    let periodosAdjusted = cloneDeep(periodos);
    if (Number(nivel) === NIVEL.SECUNDARIO) {
      const anual = {
        idPeriodo: 'isNull',
        mesFin: null,
        mesInicio: null,
        nivel: {
          idNivel: 3,
          descripcionNivel: 'Secundario',
        },
        tipoPeriodo: {
          descripcionTipoPeriodo: 'Anual',
          idTipoPeriodo: 'isNull',
        },
      };
      if (
        !periodosAdjusted.some(
          (periodo) => periodo.tipoPeriodo.descripcionTipoPeriodo === 'Anual',
        )
      ) {
        periodosAdjusted.push(anual);
      }
    } else if (Number(nivel) === NIVEL.INICIAL) {
      periodosAdjusted = periodosAdjusted
        .filter((periodo) =>
          [2, 4].includes(Number(periodo.tipoPeriodo.idTipoPeriodo)),
        )
        .map((periodo) => ({
          ...periodo,
          tipoPeriodo: {
            ...periodo.tipoPeriodo,
            descripcionTipoPeriodo:
              Number(periodo.tipoPeriodo.idTipoPeriodo) === 2
                ? 'Informe Cuatrimestral'
                : 'Informe Final',
          },
        }));
    }
    return periodosAdjusted;
  }, [nivel, periodos]);

  const onFilterChange = useCallback(
    async ({ target }) => {
      if (target.value) {
        setFilter({ ...filter, [target.name]: target.value });
      } else {
        setFilter({ ...filter, [target.name]: '' });
      }
    },

    [filter, setFilter],
  );

  useEffect(() => {
    onFilterChanged(filter);
  }, [filter, onFilterChanged]);

  return useMemo(
    () => (
      <>
        <Grid
          container
          justify="space-between"
          alignItems="flex-end"
          spacing={3}
          style={{ padding: '0 15px 15px', paddingRight: 15, marginTop: 23 }}
        >
          <Grid
            container
            xs={8}
            justify="flex-start"
            spacing={3}
            alignItems="flex-end"
            style={customStyles.searchGrid}
          >
            <Grid item xs={3}>
              <CicloLectivoSelect
                title="Ciclo lectivo"
                placeholder="Ciclo lectivo"
                name="idCicloLectivo"
                labelKey="anio"
                valueKey="idCicloLectivo"
                value={filter.idCicloLectivo}
                handleChange={onFilterChange}
                orderBy="anio"
                direction="desc"
              />
            </Grid>
            {filter.idCicloLectivo && (
              <Grid item xs={3}>
                <SimpleSelect
                  title={'Período'}
                  placeholder="Seleccione opción"
                  labelKey="tipoPeriodo.descripcionTipoPeriodo"
                  valueKey="tipoPeriodo.idTipoPeriodo"
                  content={periodosAdjusted}
                  value={filter.periodo}
                  name="periodo"
                  handleChange={onFilterChange}
                  orderBy={'tipoPeriodo.idTipoPeriodo'}
                />
              </Grid>
            )}

            {filter.idCicloLectivo && filter.periodo && (
              <Grid item xs={3}>
                <TipoInformesSelect
                  name="tipoInforme"
                  title="Tipo informe"
                  placeholder="Tipo informe"
                  labelKey="descripcion"
                  valueKey="idTipoInforme"
                  handleChange={onFilterChange}
                  value={filter.tipoInforme}
                  orderBy="idTipoInforme"
                  transformOptions={(options) =>
                    Number(nivel) === NIVEL.INICIAL
                      ? options.map((op) =>
                          op.idTipoInforme === 1
                            ? { ...op, descripcion: 'Informe' }
                            : op,
                        )
                      : options
                  }
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </>
    ),
    [
      filter.idCicloLectivo,
      filter.periodo,
      filter.tipoInforme,
      nivel,
      onFilterChange,
      periodosAdjusted,
    ],
  );
};
