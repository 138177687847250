import { PeriodoNuevoDAO } from 'src/platform/libby/dao';
import { makeBusinessEntity } from '../../lib/libby/generator';
import { PeriodoNuevo } from '../models';

const DAO_NAME = 'PeriodoNuevo';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<PeriodoNuevo, PeriodoNuevoDAO>(DAO_NAME);

// hooks
export const usePeriodoNuevoFetchById = fetchById;
export const usePeriodoNuevoLibbyCall = libbyCall;
export const usePeriodoNuevoDAO = libbyDAO;
export const usePeriodoNuevoLibbyFetch = libbyFetch;

// components/hoc
export const PeriodoNuevoAutocomplete = entityAutocomplete;
export const PeriodoNuevoCall = renderPropCall;
export const PeriodoNuevoById = renderPropFetchById;
export const PeriodoNuevoSelect = simpleSelect;
export const PeriodoNuevoAccordionSelector = accordionSelector;
export const PeriodoNuevoSelectedChips = selectedChips;

// context
// ByIdContext
export const usePeriodoNuevoByIdContext = useByIdContext;
export const PeriodoNuevoByIdProvider = ByIdProvider;
export const PeriodoNuevoByIdContext = ByIdContext;
// GeneralContext
export const usePeriodoNuevoContext = useGeneralContext;
export const PeriodoNuevoProvider = GeneralProvider;
export const PeriodoNuevoContext = GeneralContext;
