type PassesRequest = {
  APPROVED: number;
  CANCELLED: number;
  PENDING: number;
  REJECTED: number;
};

export const statusPassesRequest: PassesRequest = {
  APPROVED: 1,
  CANCELLED: 4,
  PENDING: 3,
  REJECTED: 2,
};
