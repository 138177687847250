import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { RepositorioEspaciosCurriculares } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'repositorio_espacios_curriculares';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEspacioCurricular',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEspacioCurricular',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.RepositorioEspaciosCurriculares
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.RepositorioEspaciosCurriculares,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.RepositorioEspaciosCurriculares.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<RepositorioEspaciosCurriculares>(DAO_NAME, optionsToUse);

// hooks
export const useRepositorioEspaciosCurricularesFetchById = fetchById;
export const useRepositorioEspaciosCurricularesLibbyCall = libbyCall;
export const useRepositorioEspaciosCurricularesDAO = libbyDAO;
export const useRepositorioEspaciosCurricularesLibbyFetch = libbyFetch;

// components/hoc
export const RepositorioEspaciosCurricularesAutocomplete = entityAutocomplete;
export const RepositorioEspaciosCurricularesCall = renderPropCall;
export const RepositorioEspaciosCurricularesById = renderPropFetchById;
export const RepositorioEspaciosCurricularesSelect = simpleSelect;
export const RepositorioEspaciosCurricularesAccordionSelector =
  accordionSelector;
export const RepositorioEspaciosCurricularesSelectedChips = selectedChips;

// context
// ByIdContext
export const useRepositorioEspaciosCurricularesByIdContext = useByIdContext;
export const RepositorioEspaciosCurricularesByIdProvider = ByIdProvider;
export const RepositorioEspaciosCurricularesByIdContext = ByIdContext;
// GeneralContext
export const useRepositorioEspaciosCurricularesContext = useGeneralContext;
export const RepositorioEspaciosCurricularesProvider = GeneralProvider;
export const RepositorioEspaciosCurricularesContext = GeneralContext;
