import { makeStyles } from '@material-ui/core';
import { Error } from '@material-ui/icons';
import React from 'react';

interface FormErrorComponentProps {
  helperText: React.ReactNode;
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
  },
  icon: {
    width: 14,
    height: 14,
    paddingRight: 3,
  },
});

export const FormErrorComponent = ({ helperText }: FormErrorComponentProps) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {helperText ? <Error className={classes.icon} /> : <></>}
      {helperText}
    </div>
  );
};
