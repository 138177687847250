import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { Grid, Tooltip, IconButton, makeStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { SimpleSelect } from 'src/commons';
import customFormDialog from 'src/commons/services/customFormDialog';
import { MultipleOptionsModal } from '../MultipleOptionsModal';
import { useAspectosGeneralesCustomLibbyFetch } from 'src/app/business';
import { useGetRows } from '../../../../hooks';
import { isEqual } from 'lodash';
import { setSearchParamsMultipleWords } from 'src/utils/setSearchParamsMultipleWords';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';

const useStyles = makeStyles({
  table: {
    maxHeight: '600px',
    marginBottom: '80px',
  },
  rowSelect: {
    minWidth: 150,
  },
});

const quantityMock = [
  { descripcion: '-' },
  { descripcion: 'Siempre' },
  { descripcion: 'Casi siempre' },
  { descripcion: 'Pocas veces' },
  { descripcion: 'Muy pocas veces' },
];

const booleanMock = [{ descripcion: 'No' }, { descripcion: 'Sí' }];

const espacios = [
  'MATEMÁTICA',
  'PRÁCTICAS DEL LENGUAJE',
  'CONOCIMIENTO DEL MUNDO (1er Ciclo)',
  'CIENCIAS SOCIALES (2do ciclo)',
  'CIENCIAS NATURALES (2do ciclo)',
  'MATERIAS CURRICULARES',
];

const apoyos = [
  'PPI',
  'M+M',
  'MATE',
  'MAI',
  'MAPED',
  'E.O.E',
  'JORNADA EXTENDIDA',
  'RED COMUNITARIA DE APOYO ESCOLAR',
  'OTROS',
];

export const useAspectosGeneralesRows = (filter: any) => {
  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();
  const classes = useStyles();
  const [readyToClose, setReadyToClose] = useState<boolean>(false);

  const filterParams = useMemo(
    () => ({
      0: [{ path: 'seccion', value: filter.seccion }],
      1: [{ path: 'calificacion.periodo', value: filter.periodo }],
    }),
    [filter.seccion, filter.periodo],
  );

  const fetchResponse = useAspectosGeneralesCustomLibbyFetch({
    aspect: 'default',
    filter: filterParams,
    enabled:
      Boolean(filter.periodo) &&
      Boolean(filter.anio) &&
      Boolean(filter.seccion),
    limit: 100,
  });
  const { data = [] } = fetchResponse;

  const {
    rows: _rows = [],
    changeRow,
    working,
    reFetch,
    hasCalifications,
  } = useGetRows(fetchResponse, filter.search);

  const handleEditModal = useCallback(
    (
      index: number,
      options: any[],
      changeRow: any,
      name: string,
      title: string,
    ) => {
      customFormDialog.show({
        title: '',
        renderComponent: (
          <MultipleOptionsModal
            index={index}
            options={options}
            changeRow={changeRow}
            name={name}
            rows={_rows}
            title={title}
          />
        ),
        sizeWidth: 'sm',
      });
    },
    [_rows],
  );

  const isClosed = useMemo(
    () => _rows.some((row) => row.nota?.abierto === false),
    [_rows],
  );

  useEffect(() => {
    const submitRows = _rows.filter((row) => {
      let isUpdated = false;
      const currentCalification = data?.find(
        (d: any) => d.idCalificacion === row.idCalificacion,
      );
      if (currentCalification?.nota) {
        isUpdated = !isEqual(currentCalification?.nota?.data, row.nota?.data);
      } else if (Object.keys(row.nota?.data).length) {
        isUpdated = true;
      }
      return isUpdated;
    });
    setReadyToClose(!submitRows.length);
  }, [_rows, data]);

  useEffect(() => {
    _rows.forEach((row, index: number) => {
      if (
        row.nota?.data?.espaciosConsolidandoPregunta !== 'Sí' &&
        row.nota?.data?.espaciosConsolidando?.length
      ) {
        changeRow(
          { target: { name: 'espaciosConsolidando', value: undefined } },
          index,
        );
      }
      if (
        row.nota?.data?.apoyoPregunta !== 'Sí' &&
        row.nota?.data?.apoyo?.length
      ) {
        changeRow({ target: { name: 'apoyo', value: undefined } }, index);
      }
    });
  }, [_rows, changeRow]);

  const rows = useMemo(() => {
    const isDisabled =
      _rows.some(
        (c) => c.nota?.abierto !== undefined && c.nota?.abierto === false,
      ) || !establecimientoEfectivizado;

    return _rows.map((row, index: number) => {
      return {
        nombre: `${row?.apellido}, ${row?.nombre}`,
        vinculoPedagogico: (
          <Grid className={classes.rowSelect}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={quantityMock}
              value={row.nota?.data?.vinculoPedagogico}
              name="vinculoPedagogico"
              key={`vinculoPedagogico-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          </Grid>
        ),
        promocionAcompanada: (
          <Grid className={classes.rowSelect}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={booleanMock}
              value={row.nota?.data?.promocionAcompanada}
              name="promocionAcompanada"
              key={`promocionAcompanada-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          </Grid>
        ),
        espaciosConsolidandoPregunta: (
          <Grid className={classes.rowSelect}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={booleanMock}
              value={row.nota?.data?.espaciosConsolidandoPregunta}
              name="espaciosConsolidandoPregunta"
              key={`espaciosConsolidandoPregunta-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          </Grid>
        ),
        espaciosConsolidando: (
          <Grid
            className={classes.rowSelect}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Tooltip title="Espacios Curriculares">
              <IconButton
                edge="end"
                aria-label="Espacios Curriculares"
                disabled={
                  row.nota?.data?.espaciosConsolidandoPregunta !== 'Sí' ||
                  isDisabled
                }
                onClick={() =>
                  handleEditModal(
                    index,
                    espacios,
                    changeRow,
                    'espaciosConsolidando',
                    'Espacios Curriculares',
                  )
                }
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        ),
        apoyoPregunta: (
          <Grid className={classes.rowSelect}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={booleanMock}
              value={row.nota?.data?.apoyoPregunta}
              name="apoyoPregunta"
              key={`apoyoPregunta-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          </Grid>
        ),
        apoyo: (
          <Grid
            className={classes.rowSelect}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Tooltip title="Apoyos / Acompañamiento">
              <IconButton
                edge="end"
                aria-label="Apoyos / Acompañamiento"
                disabled={row.nota?.data?.apoyoPregunta !== 'Sí' || isDisabled}
                onClick={() =>
                  handleEditModal(index, apoyos, changeRow, 'apoyo', 'Apoyos')
                }
              >
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Grid>
        ),
        organizaParticipa: (
          <Grid className={classes.rowSelect}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={quantityMock}
              value={row.nota?.data?.organizaParticipa}
              name="organizaParticipa"
              key={`organizaParticipa-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          </Grid>
        ),
        compromisoAprendizaje: (
          <Grid className={classes.rowSelect}>
            <SimpleSelect
              placeholder="Seleccione opción"
              labelKey="descripcion"
              valueKey="descripcion"
              content={quantityMock}
              value={row.nota?.data?.compromisoAprendizaje}
              name="compromisoAprendizaje"
              key={`compromisoAprendizaje-${index}`}
              handleChange={(event) => changeRow(event, index)}
              disabled={isDisabled}
            />
          </Grid>
        ),
      };
    });
  }, [changeRow, _rows, handleEditModal, classes, establecimientoEfectivizado]);

  return {
    rows,
    working,
    _rows,
    data,
    reFetch,
    readyToClose,
    isClosed,
    hasCalifications,
  };
};
