import {
  FormControlLabel,
  FormControlLabelProps,
  CheckboxProps as MuiCheckboxProps,
  Checkbox as MuiCheckbox,
} from '@material-ui/core';
import React from 'react';

interface CheckboxProps extends MuiCheckboxProps {
  label: string;
  labelPlacement?: 'bottom' | 'top' | 'end' | 'start';
}

export const CheckboxInput = ({
  label,
  labelPlacement,
  ...rest
}: CheckboxProps) => {
  return (
    <FormControlLabel
      label={label}
      labelPlacement={labelPlacement}
      control={<MuiCheckbox {...rest} />}
    />
  );
};
