import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import moment from 'moment';
import React, { useState, useMemo } from 'react';
import MUIEditor, { MUIEditorState } from 'react-mui-draft-wysiwyg';
import { Nodo, PerfilOrientado } from 'src/app/models/orientaciones';
import { Loading } from 'src/commons';
import { convertFromRaw, RawDraftContentState } from 'draft-js';
import { useAccionesOrientacionesLibbyFetch } from '../../../../app/business/acap';

const useStyles = makeStyles({
  container: {
    height: '70vh',
  },
  input: {
    color: 'black',
  },
  textField: {
    color: 'black',
  },
  card: {
    minWidth: 275,
  },
  description: {
    textAlign: 'justify',
  },
});
const editorConfig = {
  toolbar: { visible: false },
  draftEditor: { readOnly: true },
  editor: {
    wrapperElement: 'div',
    style: {
      padding: 0,
      //borderTop: '1px solid gray',
    },
  },
};

const diaString = (dia: number) => {
  let diaName;
  switch (dia) {
    case 0:
      diaName = 'Lunes';
      break;
    case 1:
      diaName = 'Martes';
      break;
    case 2:
      diaName = 'Miercoles';
      break;
    case 3:
      diaName = 'Jueves';
      break;
    case 4:
      diaName = 'Viernes';
      break;
    case 5:
      diaName = 'Sabado';
      break;
    case 6:
      diaName = 'Domingo';
      break;
  }

  return diaName;
};

export const DetalleOferta = ({ row }: any) => {
  const classes = useStyles();
  let rawContent: RawDraftContentState = {
    blocks: [
      {
        data: {},
        depth: 0,
        entityRanges: [],
        inlineStyleRanges: [],
        key: '2vm6d',
        text: '',
        type: '',
      },
    ],
    entityMap: {},
  };
  try {
    rawContent = JSON.parse(row?.ofertaTurno?.oferta?.accion?.descripcion);
  } catch (e) {
    rawContent.blocks[0].text = row?.ofertaTurno?.oferta?.accion?.descripcion;
  }

  const [editorState, setEditorState] = useState(
    MUIEditorState.createWithContent(editorConfig, convertFromRaw(rawContent)),
  );
  const onChange = (newState) => {
    setEditorState(newState);
  };

  const filterAos = useMemo(() => {
    return {
      aosIds: [
        {
          path: 'idAccionOrientacion',
          value: row?.ofertaTurno?.oferta?.accion?.accionOrientaciones?.map(
            (ao: any) => ao.idAccionOrientacion,
          ),
          method: 'in',
        },
      ],
    };
  }, [row]);

  const { data: aos = [], working } = useAccionesOrientacionesLibbyFetch({
    filter: filterAos,
  });

  const isSameDate = () =>
    row?.ofertaTurno?.oferta?.fechaFin ===
    row?.ofertaTurno?.oferta?.fechaInicio;

  return (
    <>
      {working ? (
        <Loading />
      ) : (
        <Grid container>
          <Grid container style={{ marginTop: '2%', marginLeft: '2%' }}>
            <Grid container direction="row">
              <Typography style={{ marginRight: '10px' }}>
                <span style={{ fontWeight: 'bold' }}>Organización:</span>
                {` ${row?.ofertaTurno?.oferta?.accion?.organizacion?.nombre}`}
              </Typography>
              <Typography
                style={{
                  position: 'absolute',
                  marginLeft: '55%',
                }}
              >
                <span style={{ fontWeight: 'bold' }}>Sector:</span>
                {` ${row?.ofertaTurno?.oferta?.accion?.organizacion?.sector?.nombre}`}
              </Typography>
            </Grid>
            <Grid container direction="row" style={{ marginTop: '2%' }}>
              <Typography style={{ maxWidth: '600px' }}>
                <span style={{ fontWeight: 'bold' }}>Acción:</span>
                {` ${row?.ofertaTurno?.oferta?.accion?.nombre}`}
              </Typography>
              <Typography style={{ position: 'absolute', marginLeft: '55%' }}>
                <span style={{ fontWeight: 'bold' }}>Tipo de Acción:</span>{' '}
                {` ${row?.ofertaTurno?.oferta?.accion?.tipoAccion?.nombre}`}
              </Typography>
            </Grid>
            <Grid container direction="row" style={{ marginTop: '2%' }}>
              <Typography>
                <span style={{ fontWeight: 'bold' }}>Horas ACAP:</span>{' '}
                {` ${row?.ofertaTurno?.oferta?.accion?.horas}`}
              </Typography>
              <Typography style={{ position: 'absolute', marginLeft: '55%' }}>
                <span style={{ fontWeight: 'bold' }}>Referente acción:</span>{' '}
                {Boolean(row?.referente?.apellido)
                  ? ` ${row?.referente?.apellido}, ${row?.referente?.nombre} , ${row?.referente?.email}`
                  : ` ${row?.referente?.nombre}`}
              </Typography>
            </Grid>
            <Grid container direction="row" style={{ marginTop: '2%' }}>
              {isSameDate() ? (
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Fecha:</span>{' '}
                  {` ${moment(row?.ofertaTurno?.oferta?.fechaInicio).format(
                    'DD/MM/YYYY',
                  )}`}
                </Typography>
              ) : (
                <Typography>
                  <span style={{ fontWeight: 'bold' }}>Fecha inicio:</span>{' '}
                  {` ${moment(row?.ofertaTurno?.oferta?.fechaInicio).format(
                    'DD/MM/YYYY',
                  )}`}
                </Typography>
              )}

              {!isSameDate() && (
                <Typography style={{ position: 'absolute', marginLeft: '55%' }}>
                  <span style={{ fontWeight: 'bold' }}>Fecha Fin:</span>{' '}
                  {` ${moment(row?.ofertaTurno?.oferta?.fechaFin).format(
                    'DD/MM/YYYY',
                  )}`}
                </Typography>
              )}
            </Grid>
          </Grid>

          <Grid
            container
            style={{
              backgroundColor: '#e4e7ec',
              marginTop: '2%',
            }}
          >
            <Grid container direction="column">
              <Typography
                style={{
                  fontSize: '36px',
                  color: '#5d656c',
                  marginLeft: '2%',
                  marginTop: '2%',
                }}
              >
                Cursada
              </Typography>

              <Grid container justifyContent="center" alignItems="center">
                <Grid
                  style={{
                    backgroundColor: 'white',
                    marginTop: '2%',
                    width: '98%',
                    height: '100%',
                    boxShadow: '0px 1px 4px 0px rgba(0,0,0,0.50)',
                  }}
                >
                  <Grid container direction="row">
                    <Typography
                      style={{ position: 'absolute', marginLeft: '2%' }}
                    >
                      Dia
                    </Typography>
                    <Typography
                      style={{ position: 'absolute', marginLeft: '10%' }}
                    >
                      Hora inicio
                    </Typography>
                    <Typography
                      style={{ position: 'absolute', marginLeft: '18%' }}
                    >
                      Hora fin
                    </Typography>
                    <Typography
                      style={{ position: 'absolute', marginLeft: '26%' }}
                    >
                      Sede
                    </Typography>
                  </Grid>
                  {row?.jornadas
                    ?.sort((a: any, b: any) => (a.cupo > b.cupo ? 1 : -1))
                    .map((jornada: any, index: number) => {
                      const margin = index + 3;
                      return (
                        <Grid
                          container
                          direction="row"
                          style={{ marginTop: `${margin}%` }}
                        >
                          <Typography
                            style={{
                              position: 'absolute',
                              marginLeft: '2%',
                            }}
                          >
                            {diaString(jornada?.cupo)}
                          </Typography>
                          <Typography
                            style={{
                              position: 'absolute',
                              marginLeft: '10%',
                            }}
                          >
                            {jornada?.horaInicio}
                          </Typography>
                          <Typography
                            style={{
                              position: 'absolute',
                              marginLeft: '18%',
                            }}
                          >
                            {jornada?.horaFin}
                          </Typography>
                          <Typography
                            style={{
                              position: 'absolute',
                              marginLeft: '26%',
                            }}
                          >
                            {jornada?.sede?.direccion}
                          </Typography>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>

            <Grid container direction="column" style={{ marginTop: '4%' }}>
              <Typography
                style={{
                  fontSize: '36px',
                  color: '#5d656c',
                  marginLeft: '2%',
                  marginTop: '2%',
                }}
              >
                Descripción
              </Typography>

              <Grid container justifyContent="center" alignItems="center">
                <Grid
                  style={{
                    backgroundColor: 'white',
                    marginTop: '2%',
                    width: '98%',
                    height: '100%',
                    wordBreak: 'break-all',
                    boxShadow: '0px 1px 4px 0px rgba(0,0,0,0.50)',
                  }}
                >
                  <Card className={classes.card}>
                    <CardContent>
                      <Typography className={classes.description}>
                        <MUIEditor
                          editorState={editorState}
                          onChange={onChange}
                          config={editorConfig}
                        />
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Grid>

            {aos.map((accionOrientacion) => {
              let habilidadesAgrupadas: any[] = [];

              accionOrientacion?.habilidades.forEach((habilidadCategoria) => {
                const habilidadCategoriaExists = habilidadesAgrupadas?.some(
                  (habilidadAgrupada: any) => {
                    return (
                      habilidadAgrupada?.idHabilidadCategoria ===
                      habilidadCategoria?.habilidadCategoria
                        ?.idHabilidadCategoria
                    );
                  },
                );

                const habilidadCategoriaExisteConHabilidad =
                  habilidadesAgrupadas?.some((habilidadAgrupada: any) => {
                    return habilidadAgrupada?.habilidades?.some(
                      (habilidadAg: any) =>
                        habilidadAg?.idHabilidad ===
                        habilidadCategoria?.idHabilidad,
                    );
                  });

                if (!habilidadCategoriaExists) {
                  habilidadesAgrupadas.push({
                    ...habilidadCategoria?.habilidadCategoria,
                    habilidades: [
                      {
                        idHabilidad: habilidadCategoria?.idHabilidad,
                        descripcion: habilidadCategoria?.descripcion,
                      },
                    ],
                  });
                } else if (
                  habilidadCategoriaExists &&
                  !habilidadCategoriaExisteConHabilidad
                ) {
                  const habilidadAgrupada = habilidadesAgrupadas?.find(
                    (habilidad: any) => {
                      return (
                        habilidad?.idHabilidadCategoria ===
                        habilidadCategoria?.habilidadCategoria
                          ?.idHabilidadCategoria
                      );
                    },
                  );

                  habilidadAgrupada?.habilidades?.push({
                    idHabilidad: habilidadCategoria?.idHabilidad,
                    descripcion: habilidadCategoria?.descripcion,
                  });

                  _.remove(
                    habilidadesAgrupadas,
                    (habilidad: any) =>
                      habilidad?.idHabilidadCategoria ===
                      habilidadCategoria?.habilidadCategoria
                        ?.idHabilidadCategoria,
                  );

                  habilidadesAgrupadas.push(habilidadAgrupada);
                }
              });

              let nodosConRelaciones: Nodo[] = accionOrientacion?.nodos?.filter(
                (nodo) =>
                  Boolean(nodo.bloque) &&
                  Boolean(nodo.eje) &&
                  Boolean(nodo.unidad) &&
                  Boolean(nodo.contenido),
              );

              return (
                <>
                  <Grid
                    container
                    direction="column"
                    style={{ marginTop: '4%' }}
                  >
                    <Typography
                      style={{
                        fontSize: '30px',
                        color: '#5d656c',
                        marginLeft: '2%',
                        marginTop: '2%',
                      }}
                    >
                      <span style={{ fontSize: '36px' }}>Orientación:</span>{' '}
                      {accionOrientacion?.orientacion?.nombre}
                    </Typography>

                    <Typography
                      style={{
                        fontSize: '36px',
                        color: '#5d656c',
                        marginLeft: '2%',
                        marginTop: '2%',
                      }}
                    >
                      Perfil del Egresado
                    </Typography>

                    <Grid container justifyContent="center" alignItems="center">
                      <Grid
                        style={{
                          backgroundColor: 'white',
                          marginTop: '2%',
                          width: '98%',
                          height: '100%',
                          wordBreak: 'break-all',
                          boxShadow: '0px 1px 4px 0px rgba(0,0,0,0.50)',
                        }}
                      >
                        {accionOrientacion?.perfiles?.map(
                          (perfil: PerfilOrientado, index: number) => {
                            return (
                              <Typography
                                style={{
                                  color: '##B1B6B9',
                                  marginLeft: '1%',
                                  marginTop: index === 0 ? '1%' : '0%',
                                }}
                              >{`- ${perfil?.descripcion}`}</Typography>
                            );
                          },
                        )}
                      </Grid>
                    </Grid>

                    <Typography
                      style={{
                        fontSize: '30px',
                        color: '#5d656c',
                        marginLeft: '2%',
                        marginTop: '6%',
                      }}
                    >
                      Habilidades, Capacidades y Competencias
                    </Typography>

                    <Grid container justifyContent="center" alignItems="center">
                      <Grid
                        style={{
                          backgroundColor: 'white',
                          marginTop: '2%',
                          width: '98%',
                          height: '100%',
                          wordBreak: 'break-all',
                          boxShadow: '0px 1px 4px 0px rgba(0,0,0,0.50)',
                        }}
                      >
                        {habilidadesAgrupadas?.map(
                          (habilidadAgrupada, index: number) => {
                            return (
                              <>
                                <Typography
                                  style={{
                                    color: '##B1B6B9',
                                    marginLeft: '1%',
                                    marginTop: '1%',
                                  }}
                                >{`${habilidadAgrupada?.nombre}`}</Typography>
                                {habilidadAgrupada?.habilidades?.map(
                                  (habilidad: any) => {
                                    return (
                                      <Typography
                                        style={{
                                          color: '##B1B6B9',
                                          marginLeft: '1%',
                                          // marginTop:
                                          //   habilidadIndex === 0 ? '1%' : '0%',
                                        }}
                                      >{`- ${habilidad?.descripcion}`}</Typography>
                                    );
                                  },
                                )}
                              </>
                            );
                          },
                        )}
                      </Grid>
                    </Grid>

                    <Typography
                      style={{
                        fontSize: '36px',
                        color: '#5d656c',
                        marginLeft: '2%',
                        marginTop: '6%',
                      }}
                    >
                      Contenidos
                    </Typography>

                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      style={{ marginBottom: '4%' }}
                    >
                      <Grid
                        style={{
                          backgroundColor: 'white',
                          marginTop: '2%',
                          width: '98%',
                          height: '100%',
                          wordBreak: 'break-all',
                          boxShadow: '0px 1px 4px 0px rgba(0,0,0,0.50)',
                        }}
                      >
                        {nodosConRelaciones.map(
                          (nodoSeleccionado: Nodo, index: number, original) => {
                            const sameNodo = nodosConRelaciones.filter(
                              (nodoPadreSeleccionado: Nodo) => {
                                return (
                                  nodoPadreSeleccionado.bloque?.idBloque ===
                                    nodoSeleccionado?.bloque?.idBloque &&
                                  nodoPadreSeleccionado?.eje?.idEje ===
                                    nodoSeleccionado?.eje?.idEje &&
                                  nodoPadreSeleccionado?.unidad?.idUnidad ===
                                    nodoSeleccionado?.unidad?.idUnidad
                                );
                              },
                            );
                            nodosConRelaciones = nodosConRelaciones?.filter(
                              (nodo: Nodo) => {
                                return !sameNodo
                                  .map(
                                    (nodoSeleccionado: Nodo) =>
                                      nodoSeleccionado.idNodo,
                                  )
                                  .includes(nodo.idNodo);
                              },
                            );

                            if (sameNodo?.length > 0) {
                              return (
                                <>
                                  <Typography
                                    style={{
                                      color: '##B1B6B9',
                                      marginLeft: '1%',
                                      marginTop: '1%',
                                    }}
                                  >{`Bloque: ${nodoSeleccionado?.bloque?.nombre}`}</Typography>
                                  <Typography
                                    style={{
                                      color: '##B1B6B9',
                                      marginLeft: '1%',
                                    }}
                                  >{`Eje: ${nodoSeleccionado?.eje?.nombre}`}</Typography>
                                  <Typography
                                    style={{
                                      color: '##B1B6B9',
                                      marginLeft: '1%',
                                    }}
                                  >{`Unidad: ${nodoSeleccionado?.unidad?.nombre}`}</Typography>
                                  {sameNodo?.map(
                                    (nodo: Nodo, contenidoIndex: number) => {
                                      return (
                                        <Typography
                                          style={{
                                            color: '##B1B6B9',
                                            marginLeft: '1%',
                                            // marginTop:
                                            //   habilidadIndex === 0 ? '1%' : '0%',
                                          }}
                                        >{`- ${nodo?.contenido.descripcion}`}</Typography>
                                      );
                                    },
                                  )}
                                </>
                              );
                            }
                          },
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </Grid>
      )}
    </>
  );
};
