import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { OfertasGruposDAO } from '../../../platform/libby/dao';
import { OfertaGrupo } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'ofertas_grupos';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idOfertasGrupos',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idOfertasGrupos',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.OfertasGrupos
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.OfertasGrupos,
      hoc: { ...defaultOptions.hoc, ...businessOptions.OfertasGrupos.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    accordionSelector,
    selectedChips,
    selectInput,
  },
} = makeBusinessEntity<OfertaGrupo, OfertasGruposDAO>(DAO_NAME, optionsToUse);

// hooks
export const useOfertasGruposFetchById = fetchById;
export const useOfertasGruposLibbyCall = libbyCall;
export const useOfertasGruposDAO = libbyDAO;
export const useOfertasGruposLibbyFetch = libbyFetch;

// components/hoc
export const OfertasGruposAutocomplete = entityAutocomplete;
export const OfertasGruposCall = renderPropCall;
export const OfertasGruposById = renderPropFetchById;
export const OfertasGruposSelect = selectInput;
export const OfertasGruposAccordionSelector = accordionSelector;
export const OfertasGruposSelectedChips = selectedChips;
