import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { GruposCicloLectivo } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'grupos_ciclo_lectivo';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idGruposCicloLectivo',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idGruposCicloLectivo',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.GruposCicloLectivo
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.GruposCicloLectivo,
      hoc: { ...defaultOptions.hoc, ...businessOptions.GruposCicloLectivo.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<GruposCicloLectivo>(DAO_NAME, optionsToUse);

// hooks
export const useGruposCicloLectivoFetchById = fetchById;
export const useGruposCicloLectivoLibbyCall = libbyCall;
export const useGruposCicloLectivoDAO = libbyDAO;
export const useGruposCicloLectivoLibbyFetch = libbyFetch;

// components/hoc
export const GruposCicloLectivoAutocomplete = entityAutocomplete;
export const GruposCicloLectivoCall = renderPropCall;
export const GruposCicloLectivoById = renderPropFetchById;
export const GruposCicloLectivoSelect = simpleSelect;
export const GruposCicloLectivoAccordionSelector = accordionSelector;
export const GruposCicloLectivoSelectedChips = selectedChips;

// context
// ByIdContext
export const useGruposCicloLectivoByIdContext = useByIdContext;
export const GruposCicloLectivoByIdProvider = ByIdProvider;
export const GruposCicloLectivoByIdContext = ByIdContext;
// GeneralContext
export const useGruposCicloLectivoContext = useGeneralContext;
export const GruposCicloLectivoProvider = GeneralProvider;
export const GruposCicloLectivoContext = GeneralContext;
