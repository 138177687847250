import {
  Button,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { useState, useMemo, useCallback } from 'react';
import {
  InfoTable,
  InfoTableColumn,
  useTableSortConfig,
  useDebounce,
} from '../../../../../../commons';
import {
  useConfirmacionMatriculaLocalizacionLibbyFetch,
  useConfirmacionMatriculaLocalizacionDAO,
} from '../../../../../../app/business/ConfiguracionMatricula/ConfirmacionMatriculaLocalizacion';
import { ConfirmacionMatriculaLocalizacion } from '../../../../../../app/models';
import moment from 'moment';
import { useLocalizacionLibbyFetch } from 'src/app/business';
import { useSnackbar } from 'notistack';
import { Delete } from '@material-ui/icons';

const columns: InfoTableColumn<ConfirmacionMatriculaLocalizacion>[] = [
  {
    id: 'localizacion.cueAnexo',
    label: 'CUE',
    width: '5%',
    orderById: 'localizacion.cueAnexo',
  },
  {
    id: 'localizacion.anexo',
    label: 'Anexo',
    width: '5%',
    orderById: 'localizacion.anexo',
  },
  {
    id: 'localizacion.establecimiento.nombre',
    label: 'Establecimiento',
    width: '40%',
    orderById: 'localizacion.establecimiento.nombre',
  },
  {
    id: 'localizacion.establecimiento.dependenciaFuncional.descripcion',
    label: 'Dependencia Funcional',
    width: '30%',
    orderById:
      'localizacion.establecimiento.dependenciaFuncional.idDependenciaFuncional',
  },
  {
    id: 'localizacion.establecimiento.distritoEscolar.nombre',
    label: 'Distrito',
    width: '5%',
    orderById: 'localizacion.establecimiento.distritoEscolar.nombre',
  },
  {
    id: 'createdAt',
    label: 'Fecha',
    width: '10%',
    orderById: 'createdAt',
    render: (value, row) => moment(value).format('DD/MM/YYYY'),
  },
];

export const LocalizacionesActivas = () => {
  const [cue, setCue] = useState<string>('');
  const cueSearchDebounced: string = useDebounce(cue, 500);
  const confirmacionMatriculaLocalizacionDAO =
    useConfirmacionMatriculaLocalizacionDAO();
  const { enqueueSnackbar } = useSnackbar();

  const cueFilter = useMemo(
    () => ({
      cue: [
        {
          path: 'cueAnexo',
          value: cueSearchDebounced,
          method: 'includes',
        },
      ],
    }),
    [cueSearchDebounced],
  );

  const { orderBy, onSortChange, direction } = useTableSortConfig(
    'createdAt',
    'desc',
  );

  const {
    data: localizacionesActivadas = [],
    working: laWorking,
    reFetch: refetchActivados,
  } = useConfirmacionMatriculaLocalizacionLibbyFetch({
    orderBy,
    direction,
  });

  const { data: localizaciones = [], working: locWorking } =
    useLocalizacionLibbyFetch({
      filter: cueFilter,
      enabled: cueSearchDebounced !== '',
    });

  const localizacionesMapped = localizaciones.map((loc) => ({
    localizacion: { ...loc },
  }));

  const confirmacionDAO = useConfirmacionMatriculaLocalizacionDAO();

  const saveConfirmacion = async (row: ConfirmacionMatriculaLocalizacion) => {
    await confirmacionDAO.save({
      ...row,
    });
    refetchActivados();
  };

  const removeLocalizacion = useCallback<
    (item: ConfirmacionMatriculaLocalizacion) => () => Promise<void>
  >(
    (item) => async () => {
      try {
        await confirmacionMatriculaLocalizacionDAO.remove(item);
        enqueueSnackbar('CUE desactivado correctamente', {
          variant: 'success',
        });
        refetchActivados();
      } catch (error) {
        enqueueSnackbar('No se pudo desactivar el CUE', { variant: 'error' });
      }
    },
    [confirmacionMatriculaLocalizacionDAO, enqueueSnackbar, refetchActivados],
  );

  const establecimientosCUEColumns = columns.filter(
    (elem) => elem.id !== 'createdAt',
  );
  const establecimientosCUEColumnsUpdated: InfoTableColumn<ConfirmacionMatriculaLocalizacion>[] =
    [
      ...establecimientosCUEColumns,
      {
        id: 'isActive',
        label: '',
        width: '10%',
        hideSortIcon: true,
        render: (value, row) => {
          return (
            <Button
              onClick={() => {
                saveConfirmacion(row);
              }}
              color="primary"
              variant="contained"
              disabled={
                laWorking ||
                localizacionesActivadas.some(
                  (loc) =>
                    loc.localizacion.idLocalizacion ===
                    row.localizacion.idLocalizacion,
                )
              }
            >
              Activar
            </Button>
          );
        },
      },
    ];

  const columnsActivas = useMemo(() => {
    const actionColumn: InfoTableColumn<ConfirmacionMatriculaLocalizacion> = {
      id: 'action',
      label: '',
      hideSortIcon: true,
      render: (_, row) => (
        <Tooltip title="Desactivar CUE">
          <IconButton onClick={removeLocalizacion(row)}>
            <Delete />
          </IconButton>
        </Tooltip>
      ),
    };
    return [...columns, actionColumn];
  }, [removeLocalizacion]);

  return (
    <Grid container direction="column" style={{ marginBottom: 40 }}>
      <Grid item>
        <Typography
          style={{ fontWeight: 'bold', marginTop: 40 }}
          variant="body1"
          color="textPrimary"
        >
          Establecimientos por CUE
        </Typography>
      </Grid>
      <Grid item>
        <TextField
          placeholder="Ingrese CUE"
          onChange={(e) => setCue(e.target.value)}
          value={cue}
          style={{ marginTop: 10, border: '50%' }}
        />
      </Grid>
      <Grid item>
        <InfoTable
          rows={cueSearchDebounced !== '' ? localizacionesMapped : []}
          columns={establecimientosCUEColumnsUpdated}
          rowIdKey="idMatricula"
          direction={direction}
          orderBy={orderBy}
          onSortChange={onSortChange}
          working={locWorking}
        />
      </Grid>

      <Grid item>
        <Typography
          style={{ fontWeight: 'bold' }}
          variant="body1"
          color="textPrimary"
        >
          Establecimientos activados
        </Typography>
      </Grid>
      <Grid>
        <InfoTable
          rows={localizacionesActivadas}
          columns={columnsActivas}
          rowIdKey="idMatricula"
          direction={direction}
          orderBy={orderBy}
          onSortChange={onSortChange}
          working={laWorking}
        />
      </Grid>
    </Grid>
  );
};
