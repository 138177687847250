import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { CommonEndpointDAO } from './CommonEndpointDAO';
import { AnyObject } from '../../../commons/types/types';

export class AlumnoExisteDAO extends CommonEndpointDAO {
  constructor() {
    super('alumnoexiste');
  }

  validate(dataAlumn: AlumnAdd) {
    return this.post({ ...dataAlumn });
  }

  recuperaAlumno(alumn: RecuperaAlumno) {
    return this.post({ ...alumn });
  }

  getAlumno(search: any) {
    return this.get([search]);
  }
}

interface AlumnAdd {
  name: string;
  lastName: string;
  country: string;
  genre: string;
  selectLevel: string;
  turn: string;
  activity: string;
  typeDocument: string;
  numDocument: string;
  idAnio: string;
  date: ParsableDate;
  localizacionId: string | number;
}

interface RecuperaAlumno extends AlumnAdd {
  idAlumno: string;
  localizacionPrevia: string;
  isMatriculado?: boolean;
}
export interface Search {
  documento?: string;
  apellido?: string;
  localizacion?: string;
}

export interface AlumnoExistsResponse {
  isExists: boolean;
  message: string;
  existError: boolean;
  alumnos: DataSearchSanatized[];
}

export interface GetAlumnos {
  isExists: boolean;
  message: string;
  existError: boolean;
  alumnos?: AlumnAdd[];
}

export interface ResultAlumnosSanatized {
  name: string;
  lastName: string;
  country: number;
  genre: number;
  turn: number;
  typeDocument: number;
  numDocument: number;
  idAnio: number;
  date: string;
  localizacionId: string | number;
  isDeleted: boolean;
  descripcion: string;
  descripcionNivel: string;
  idAlumno: number;
  idInscripcionAlumno: number;
  hasMatriculacion: boolean;
}

interface CUESanetized {
  idlocalizacion: number;
  descripcion: string;
  cueAnexo: number;
  efectivizado?: number;
}

export interface DataSearchSanatized {
  idAlumno: number;
  nombre: string;
  apellido: string;
  documento: string;
  descripcionTipoDocumento: string;
  descripcionGenero: string;
  idPaisNacimiento: number;
  idTipoDocumento: number;
  idGenero: number;
  fechaNacimiento: string;
  isDuplicado?: boolean;
  proyeccion: {
    idProyeccion: number;
    idEstadoPaseAnio: number;
    estadoPaseAnio: string;
    seccionOrigen: string;
    seccionDestino: string | null;
    cicloLectivo: number;
    idCicloLectivo: number;
    idNivel: number;
    descripcionNivel: string;
    cueOrigen: CUESanetized;
    cueDestino: CUESanetized;
  } | null;
  matricula: {
    idAlumnoMovimiento: number;
    idAlumno: number;
    deletedAt: null;
    nombreSeccion: string;
    cueAnexo: number;
    descripcion: string;
    idCicloLectivo: number;
    anio: number;
    idNivel: number;
    descripcionNivel: string;
  } | null;
  inscripcion: {
    idInscripcionAlumno: number;
    idAlumno: number;
    isDeleted: boolean;
    idCicloLectivo: number;
    anio: number;
    idNivel: number;
    descripcionNivel: string;
    cueAnexo: number;
    descripcion: string;
    deletedAt: string | null;
  } | null;
}

export interface AlumnToSave {
  name: string;
  lastName: string;
  country: string | number;
  genre: string | number;
  cicloLectivo: string | number;
  selectLevel: string;
  turn: string;
  activity: string;
  typeDocument: string | number;
  numDocument: string | number;
  idAnio: string;
  date: ParsableDate;
  idAlumno?: string | number;
  localizacionPrevia?: string | number;
}
