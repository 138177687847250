import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class Orientacionesv2DAO extends LibbyFetchDAO {
  constructor() {
    super('orientaciones', 'idOrientacion');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
