import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { InterdiscipPlanFeedback } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'interdiscip_plan_feedback';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idInterdiscipPlanFeedback',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idInterdiscipPlanFeedback',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.InterdiscipPlanFeedback
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.InterdiscipPlanFeedback,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.InterdiscipPlanFeedback.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<InterdiscipPlanFeedback>(DAO_NAME, optionsToUse);

// hooks
export const useInterdiscipPlanFeedbackFetchById = fetchById;
export const useInterdiscipPlanFeedbackLibbyCall = libbyCall;
export const useInterdiscipPlanFeedbackDAO = libbyDAO;
export const useInterdiscipPlanFeedbackLibbyFetch = libbyFetch;

// components/hoc
export const InterdiscipPlanFeedbackAutocomplete = entityAutocomplete;
export const InterdiscipPlanFeedbackCall = renderPropCall;
export const InterdiscipPlanFeedbackById = renderPropFetchById;
export const InterdiscipPlanFeedbackSelect = simpleSelect;
export const InterdiscipPlanFeedbackAccordionSelector = accordionSelector;
export const InterdiscipPlanFeedbackSelectedChips = selectedChips;

// context
// ByIdContext
export const useInterdiscipPlanFeedbackByIdContext = useByIdContext;
export const InterdiscipPlanFeedbackByIdProvider = ByIdProvider;
export const InterdiscipPlanFeedbackByIdContext = ByIdContext;
// GeneralContext
export const useInterdiscipPlanFeedbackContext = useGeneralContext;
export const InterdiscipPlanFeedbackProvider = GeneralProvider;
export const InterdiscipPlanFeedbackContext = GeneralContext;
