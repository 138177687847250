import React, { useRef } from 'react';
import {
  Typography,
  Grid,
  Menu,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { grey, primary } from 'src/theme/colors';
import ClearIcon from '@material-ui/icons/Clear';
import { useRolesContext } from 'src/context/RolesContext';
import { SupportOptionsType } from './DayCell';
import { ROLES_DOCENTES } from '../utils/rolesPresentismo';
import { useCellsContext } from '../../context/CellsProvider';
import { EstadoPresente } from 'src/app/models/presentismo_v2';
import { usePresentismoContext } from '../../context/Context';

const useStyles = makeStyles({
  presente: {
    color: primary.success,
    backgroundColor: primary.successLight,
    '&:hover': {
      color: primary.white,
      backgroundColor: primary.succesDark,
    },
    borderLeft: `3px solid ${primary.success}`,
    marginBottom: 2,
  },
  'ingreso-tardío': {
    color: 'hsl(54deg 100% 25%)',
    backgroundColor: 'hsl(54deg 100% 60%)',
    '&:hover': {
      backgroundColor: 'hsl(54deg 100% 40%)',
    },
    borderLeft: `3px solid hsl(54deg 100% 40%)`,
    marginBottom: 2,
  },
  'ausente-con-presencia-en-clase': {
    color: 'hsl(40deg 100% 25%)',
    backgroundColor: 'hsl(40deg 100% 60%)',
    '&:hover': {
      backgroundColor: 'hsl(40deg 100% 50%)',
    },
    borderLeft: `3px solid hsl(40deg 100% 50%)`,
    marginBottom: 2,
  },
  'retiro-anticipado': {
    color: 'hsl(54deg 100% 25%)',
    backgroundColor: 'hsl(54deg 100% 60%)',
    '&:hover': {
      backgroundColor: 'hsl(54deg 100% 40%)',
    },
    borderLeft: `3px solid hsl(54deg 100% 40%)`,
    marginBottom: 2,
  },
  ausente: {
    color: primary.error,
    backgroundColor: primary.errorMedium,
    '&:hover': {
      color: primary.white,
      backgroundColor: primary.error,
    },
    borderLeft: `3px solid ${primary.error}`,
  },
  'no-corresponde': {
    color: primary.black,
    backgroundColor: 'hsl(0deg 0% 82%)',
    '&:hover': {
      color: primary.white,
      backgroundColor: 'hsl(0deg 0% 62%)',
    },
    borderLeft: `3px solid hsl(0deg 0% 62%)`,
  },
  capitalizeSmall: {
    textTransform: 'capitalize',
    fontSize: '0.8rem',
  },
});

export type HideOptionFunction = (data: any) => boolean;

export const OptionsModalPresentismo = ({
  positionRef,
  onClose,
  onSelect,
  actualRow,
}: any) => {
  const { estadosPresentismo } = useCellsContext();
  const {
    state: { isJornadaExtendidaTab },
  } = usePresentismoContext();
  const classes = useStyles();
  const {
    selectedRole: { rol },
  } = useRolesContext();

  const ref = useRef(null);

  return (
    <Menu
      id="menu-appbar-language"
      anchorEl={positionRef}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open
      style={{
        transform: 'translateX(10em) translateY(0px)',
        maxWidth: '17em',
      }}
    >
      <Grid
        container
        direction="row"
        style={{
          paddingBottom: 4,
          justifyContent: 'space-between',
          flexWrap: 'nowrap',
          height: '1em',
          marginLeft: 6,
        }}
      >
        <Typography variant="subtitle2" className={classes.capitalizeSmall}>
          Alumno
        </Typography>
        <IconButton size="small">
          <ClearIcon
            style={{ color: grey.medium, fontSize: 20 }}
            onClick={onClose}
          />
        </IconButton>
      </Grid>
      <Grid
        container
        direction="column"
        style={{
          paddingBottom: 4,
          alignItems: 'center',
        }}
      >
        <Typography variant="subtitle2" className={classes.capitalizeSmall}>
          {actualRow.alumno.persona.apellido.toLowerCase() + ', '}
        </Typography>

        <Typography variant="subtitle2" className={classes.capitalizeSmall}>
          {actualRow.alumno.persona.nombre.toLowerCase()}
        </Typography>
      </Grid>
      {estadosPresentismo
        .filter((estadoPresente: EstadoPresente) => {
          return isJornadaExtendidaTab
            ? ['Presente', 'Ausente', 'No Corresponde'].includes(
                estadoPresente.descripcion,
              )
            : estadoPresente;
        })
        .map((estadoPresente: EstadoPresente, index: number) => (
          <MenuItem
            onClick={() => onSelect(estadoPresente)}
            key={index}
            className={`${
              classes[
                estadoPresente.descripcion.toLowerCase().replaceAll(' ', '-')
              ]
            }`}
            ref={index === 0 ? ref : null}
          >
            <Grid item style={{ paddingRight: 10 }}>
              {estadoPresente.descripcion}
            </Grid>
          </MenuItem>
        ))}
    </Menu>
  );
};
