import React, { FC } from 'react';
import { FormRenderProps, useField } from 'react-final-form-hooks';
import {
  Chip,
  createStyles,
  Grid,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FormDialog } from 'src/commons/services/dialogs/base/formDialog';
import {
  HabilidadesDialog,
  RecursosDialog,
  InstrumentosEvaluacionDialog,
  SeccionesDialog,
} from '../../../../../dialogs';

interface Props {
  form: FormRenderProps<any>['form'];
  options: any;
  name: string;
  label: string;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      // TODO: review styles
      width: 'inherit',
    },
    chipsContainer: {
      minHeight: 48,
      cursor: 'pointer',
      boxSizing: 'border-box',
      backgroundColor: '#fff', // TODO: Add color from palette
      border: '2px solid #D9D9D9', // TODO: Add color from palette
      borderRadius: '4px',
    },
    list: {
      boxSizing: 'border-box',
      padding: 4,
    },
    chip: {
      justifyContent: 'flex-start',
      margin: '1px 0',
      maxWidth: '100%',
    },
  }),
);

const MAP_DIALOG: Record<string, FormDialog<any>> = {
  Habilidad: HabilidadesDialog,
  'Contenidos Especificos': RecursosDialog,
  Recursos: RecursosDialog,
  'Instrumentos de evaluación': InstrumentosEvaluacionDialog,
  Sección: SeccionesDialog,
};

export const FormCheckboxDialog: FC<Props> = ({
  form,
  options,
  name,
  label,
}) => {
  const classes = useStyles();
  const field = useField(name, form);
  const Dialog = MAP_DIALOG[label];

  const showModal = async () => {
    const value = await Dialog.open(options);
    if (value) field.input.onChange(value);
  };

  const onDelete = (id: any) => {
    field.input.onChange(
      options.map((item: any) =>
        item.id === id ? { ...item, checked: false } : item,
      ),
    );
  };

  const existingOptions = options.filter((item: any) => item.checked);
  // TODO: remove any types
  return (
    <Grid>
      <Typography variant="body1">{label}</Typography>
      <Grid container className={classes.chipsContainer} onClick={showModal}>
        {existingOptions.length ? (
          <List disablePadding className={classes.list}>
            {options.map(
              (item: any, idx: number) =>
                item.checked && (
                  <ListItem
                    key={`list-item-${idx}`}
                    dense
                    disableGutters
                    classes={{ root: classes.root }}
                  >
                    <Chip
                      label={item.descripcion}
                      onDelete={() => onDelete(item.id)}
                      className={classes.chip}
                    />
                  </ListItem>
                ),
            )}
          </List>
        ) : (
          <Grid container alignItems="center">
            <Typography variant="caption">{`Seleccioná ${label}`}</Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
