import React from 'react';
import { Grid, TablePagination } from '@material-ui/core';
import { InfoTable } from 'src/commons';
import { useReportsContext } from './contexts/ReportContext';
import reports from './reportsConfig';

const TableFactory = ({
  rows,
  reportCategory,
  totalRows,
}: {
  rows: any[];
  reportCategory: string;
  totalRows: number;
}) => {
  const {
    offset,
    setOffset,
    currentReport,
    setOrderBy,
    setOrderDirection,
    orderDirection,
    setLimit,
    limit,
    setQueryParams,
    setFetchInmediatly,
  } = useReportsContext();
  const columns = reports[reportCategory][currentReport]?.tableColumns;

  const handleSortRequest = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setOrderDirection(newDirection);
    setOrderBy(newOrderBy);
    setQueryParams((params) => {
      const newParams = new URLSearchParams(params);
      newParams.set('orderBy', newOrderBy);
      newParams.set('orderDir', newDirection);
      return newParams.toString();
    });
    setFetchInmediatly(true);
  };

  return !!currentReport ? (
    <Grid item>
      <InfoTable
        columns={columns}
        rows={rows}
        rowIdKey={'uid'}
        direction={orderDirection}
        onSortChange={handleSortRequest}
      />
      {rows.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={totalRows}
          rowsPerPage={limit}
          page={offset / limit}
          onPageChange={(e, page) => {
            const offset = page * limit;
            setOffset(offset);
            setQueryParams((params) => {
              const newParams = new URLSearchParams(params);
              newParams.set('offset', offset);
              return newParams.toString();
            });
            setFetchInmediatly(true);
          }}
          onRowsPerPageChange={(e) => {
            setQueryParams((params) => {
              const newParams = new URLSearchParams(params);
              newParams.set('limit', e.target.value);
              return newParams.toString();
            });
            setLimit(parseInt(e.target.value));
          }}
          labelRowsPerPage="Filas por página"
          labelDisplayedRows={({ from, to, count }) => {
            return from + '-' + to + ' de ' + count;
          }}
        />
      )}
    </Grid>
  ) : null;
};

export default TableFactory;
