import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React from 'react';
import { useInfoPlataformaLibbyFetch } from 'src/app/business';
import { BuenosAiresCiudad } from 'src/assets/images/svgs/BuenosAiresCiudad';
import { primary } from 'src/theme/colors';

const LoginFooterStyles = makeStyles({
  lightContent: {
    backgroundColor: '#474747',
    paddingLeft: 38,
    paddingRight: 28,
  },
  darkContent: {
    backgroundColor: 'rgba(38, 38, 38, 1)',
  },
  footerTextContainer: {
    padding: '5px 0',
  },
  footerText: {
    fontFamily: 'Open Sans',
    color: primary.white,
    fontSize: 14,
    textAlign: 'right',
  },
  downText: {
    fontFamily: 'Open Sans',
    color: primary.white,
    fontSize: 12,
    marginLeft: 28,
  },
  logoBsAs: {
    marginTop: 20,
  },
});

export const LoginFooter = () => {
  const classes = LoginFooterStyles();

  const { data = [] } = useInfoPlataformaLibbyFetch();

  const dataFiltrada = data[0]?.descripcion;
  return (
    <Grid
      container
      direction="column"
      style={{ position: 'fixed', bottom: 0, zIndex: 200 }}
    >
      <Grid
        container
        className={classes.lightContent}
        direction="row"
        justifyContent="space-between"
      >
        <Grid item className={classes.logoBsAs}>
          <BuenosAiresCiudad fill="white" />
        </Grid>
        <Grid item>
          <Grid
            container
            direction="column"
            justifyContent="flex-end"
            alignContent="flex-end"
            className={classes.footerTextContainer}
          >
            {dataFiltrada?.map((d) => {
              return (
                <Typography className={classes.footerText}>
                  {d.cargo} | {d.nombre}
                </Typography>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.darkContent}>
        <p className={classes.downText}>
          Todos los derechos reservados © 2021 Dirección General de Proyectos y
          Tecnología Educativa
        </p>
      </Grid>
    </Grid>
  );
};
