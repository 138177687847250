import { makeEntityLibbyCall } from '../../../lib/libby/hooks/makeEntityLibbyCall';
import { makeEntityLibbyDAO } from '../../../lib/libby/hooks/makeEntityLibbyDAO';
import { withRenderPropCall } from '../../../lib/libby/hoc/withRenderPropCall';
import { InscripcionAlumnoCustomDAO } from 'src/platform/libby/dao';

const DAO_NAME = 'inscripcion_alumno_custom';

// hooks
export const useInscripcionAlumnoCustomLibbyCall =
  makeEntityLibbyCall(DAO_NAME);
export const useInscripcionAlumnoCustomDAO =
  makeEntityLibbyDAO<InscripcionAlumnoCustomDAO>(DAO_NAME);

// components/hoc
export const InscripcionAlumnoCustomCall = withRenderPropCall(DAO_NAME);
