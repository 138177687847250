import { LocalizacionPlanEstudioNivel, PlanEstudioNivel } from 'src/app/models';

export const flattenPlanesEstudio = (
  planes: LocalizacionPlanEstudioNivel[],
) => {
  const unqPlanes: PlanEstudioNivel[] = [];
  planes.forEach((data) => {
    if (
      !unqPlanes.find(
        (up) =>
          up.idPlanEstudioNivel === data.planEstudioNivel.idPlanEstudioNivel,
      )
    ) {
      unqPlanes.push(data.planEstudioNivel);
    }
  });

  return unqPlanes;
};
