import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import {
  FormFileInput,
  FormGenericControl,
  FormCheckbox,
  FormInput,
} from 'src/lib/templates/components';
import { publicacionRolFilter } from 'src/commons/const';
import { ButtonTypesProps } from 'src/commons/components/Footer';
import { UserAvatar, MainButton } from 'src/commons/components';
import { RolSelect } from 'src/app/business';
import { useRolesContext } from 'src/context/RolesContext';
import { useFormContext } from 'src/lib/templates/context';
import { Publicacion } from 'src/app/models';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
  },
  textarea: {
    padding: 0,
  },
  selectsContainer: {
    marginTop: theme.spacing(2),
  },
}));

export interface PublicacionItemFormProps {
  raw?: Publicacion;
  submitted: boolean;
  setSubmitted: any;
}

export const PublicacionItemForm = ({
  raw,
  submitted,
  setSubmitted,
}: PublicacionItemFormProps) => {
  const { form, handleSubmit, submitting, errors } = useFormContext();
  const classes = useStyles();
  const { userInfo } = useRolesContext();

  if (submitted && !submitting) {
    form.reset();
    setSubmitted(false);
  }

  const submitProps: ButtonTypesProps = {
    title: 'Publicar',
    handleOnClick: handleSubmit,
    disabled: Object.keys(errors).length !== 0 || submitting,
  };

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid container item direction="row" spacing={2}>
        <Grid item className={classes.avatar}>
          <UserAvatar
            name={raw ? raw.cuentas.nombreUsuario : userInfo.name}
            avatarWidth={28}
            avatarHeight={28}
          />
        </Grid>
        <Grid item xs>
          <FormInput
            disableErrorMessage
            inputProps={{ multiline: true, className: classes.textarea }}
            name="descripcion"
            form={form}
            placeholder="Escribe un comentario"
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        direction="row"
        justify="space-between"
        className={classes.selectsContainer}
      >
        <Grid container xs alignItems="flex-end" justify="space-between">
          <Grid container item spacing={2} xs={6} alignItems="flex-end">
            <Grid item xs={5}>
              <FormGenericControl
                name="idRol"
                form={form}
                disableErrorMessage
                component={RolSelect}
                componentProps={{
                  placeholder: 'Público',
                  title: 'Audiencia',
                  filter: publicacionRolFilter,
                }}
              />
            </Grid>
            <Grid item xs={5}>
              <FormCheckbox
                name="comentable"
                form={form}
                label="Admitir comentarios"
              />
            </Grid>
          </Grid>
          <Grid container item spacing={3} xs={6} justify="flex-end">
            <Grid item xs={9}>
              <FormFileInput
                label=""
                name="file"
                form={form}
                placeholder="Subir"
              />
            </Grid>
            <Grid container item xs={3} justify="flex-end">
              <MainButton {...submitProps} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
