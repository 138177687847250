import {
  Grid,
  Typography,
  IconButton,
  makeStyles,
  Box,
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import { grey } from 'src/theme/colors';
import { useSedesDAO } from 'src/app/business/acap/Sedes';
import { Footer, MainInput, SimpleSelect, Loading } from 'src/commons';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useTurnoLibbyFetch } from 'src/app/business';
import { useReferentesLibbyFetch } from 'src/app/business/acap';
import { useInputValidator } from '../hooks/useInputValidator';
import { SedeComponent } from './SedeComponent';
import { DatePicker } from 'src/app/components/inputs/Input/DatePicker';
import TextField from '@material-ui/core/TextField';
import { Autocomplete } from '@material-ui/lab';
import confirmDialog from 'src/commons/services/confirmDialog';
import _ from 'lodash';
import CachedIcon from '@material-ui/icons/Cached';

type QueryFilter = { path: string; value: string | number; method?: string };
type ReferenteFilter = {
  referente: QueryFilter[];
};

const useStyles = makeStyles({
  buttonLoading: {
    height: '20px',
    padding: '0',
    display: 'flex',
    alignContent: 'center',
  },
  labelAsterisk: {
    color: 'red',
  },
  boxHeight: {
    minHeight: '3.5em',
    maxHeight: '6.5em',
    width: '24.5rem',
    marginBottom: '1em',
  },
});

export const OrganizacionCreacionForm = ({
  sectores,
  handleSave,
  isCreatingAnOrganization,
  setIsCreatingAnOrganization,
  organizacionesReFetch,
}: any) => {
  const [loading, setLoading] = useState<any>(false);
  const [formValues, setFormValues] = useState<any>({
    fechaFin: null,
    fechaInicio: null,
  });
  const [stDashCuitState, setStDashCuitState] = useState(true);
  const [ndDashCuitState, setNdDashCuitState] = useState(true);
  const [sedesValues, setSedesValues] = useState<any>([]);
  const [mainSedeValues, setMainSedeValues] = useState<any>([
    { isMain: true, turnos: [{ idTurno: 1, descripcionTurno: 'Mañana' }] },
  ]);
  const [sedesCount, setSedesCount] = useState<any>([]);
  const [initialFormValues, setInitialFormValues] = useState<any>();
  const [keyPressed, setKeyPressed] = useState<string | null>();
  const [shouldResetPassword, setShouldResetPassword] =
    useState<boolean>(false);
  const [previousReferenteEmail, setPreviousReferenteEmail] =
    useState<string>();
  const [emailFieldDirty, setEmailFieldDirty] = useState<boolean>(false);
  const [referenteFilter, setReferenteFilter] = useState<
    undefined | ReferenteFilter
  >(undefined);

  const resetPassword = () => {
    setShouldResetPassword(true);
    setFormValues({
      ...formValues,
      referente: { ...formValues.referente, codigo: 'Miescuela123' },
    });
  };

  const { data: referentes = [], working: workingReferente } =
    useReferentesLibbyFetch({
      filter: referenteFilter,
    });

  const [inputValidator, error] = useInputValidator();

  const classes = useStyles();

  const SedesDAO = useSedesDAO();

  const CUIT_LIMIT = 13;
  const TELEFONO_LIMIT = 20;
  const DEFAULT_LIMIT = 70;

  const guardarDisabled = useMemo(() => {
    const disabled =
      !formValues?.nombre ||
      !formValues?.sector ||
      !formValues?.fechaInicio ||
      !formValues?.fechaFin ||
      !formValues?.domicilio ||
      !formValues?.referente?.nombre ||
      !formValues?.referente?.apellido ||
      !formValues?.referente?.email ||
      !formValues?.referente?.telefono ||
      !formValues?.referente?.codigo;

    const sedesCheck = sedesValues.filter((sede: any) => sede?.direccion);
    return disabled || !(sedesValues.length === sedesCheck.length);
  }, [
    formValues?.nombre,
    formValues?.sector,
    formValues?.fechaInicio,
    formValues?.fechaFin,
    formValues?.domicilio,
    formValues?.referente?.nombre,
    formValues?.referente?.apellido,
    formValues?.referente?.email,
    formValues?.referente?.telefono,
    formValues?.referente?.codigo,
    sedesValues,
  ]);
  useEffect(() => {
    if (isCreatingAnOrganization?.row !== null) {
      const mainSede = isCreatingAnOrganization?.row?.sedes.find(
        (sede: any) => sede?.isMain === true,
      );

      const formValuesObject = {
        idOrganizacion: isCreatingAnOrganization?.row?.idOrganizacion,
        nombre: isCreatingAnOrganization?.row?.nombre,
        sector: isCreatingAnOrganization?.row?.sector?.idSector,
        cuit: isCreatingAnOrganization?.row?.cuit,
        domicilio: mainSede?.direccion,
        telefono: isCreatingAnOrganization?.row?.telefono,
        email: isCreatingAnOrganization?.row?.email,
        turnos: mainSede?.turnos,
        fechaInicio: isCreatingAnOrganization?.row?.vigenciaStarts,
        fechaFin: isCreatingAnOrganization?.row?.vigenciaEnds,
        referente: {
          ...(isCreatingAnOrganization?.row?.referente?.idReferente
            ? {
                idReferente:
                  isCreatingAnOrganization?.row?.referente?.idReferente,
              }
            : {}),
          nombre: isCreatingAnOrganization?.row?.referente?.nombre,
          telefono: isCreatingAnOrganization?.row?.referente?.telefono,
          email: isCreatingAnOrganization?.row?.referente?.email,
          codigo:
            isCreatingAnOrganization?.row?.referente?.codigo ?? 'Miescuela123',
          apellido: isCreatingAnOrganization?.row?.referente?.apellido,
        },
      };

      let count: any = [];
      const sedesArray: any = [];
      const mainSedeArray: any = [];
      isCreatingAnOrganization?.row?.sedes?.forEach((sede: any, index: any) => {
        if (sede?.isMain === false) {
          count.push(index - 1);
          sedesArray.push({
            idSede: sede?.idSede,
            direccion: sede?.direccion,
            turnos: sede?.turnos,
            isMain: sede?.isMain,
            nombre: sede?.nombre,
          });
        } else {
          mainSedeArray.push({
            idSede: sede?.idSede,
            direccion: sede?.direccion,
            turnos: sede?.turnos,
            isMain: sede?.isMain,
          });
        }
      });
      setSedesCount(count);
      setSedesValues(sedesArray);
      setMainSedeValues(mainSedeArray);
      setFormValues(formValuesObject);
      setInitialFormValues(formValuesObject);
    }
  }, [isCreatingAnOrganization?.row]);

  useEffect(() => {
    if (referentes.length === 1) {
      setReferenteFilter(undefined);
      setFormValues((prevFormValues: any) => {
        return {
          ...prevFormValues,
          referente: {
            ...(referentes[0]?.idReferente
              ? { idReferente: referentes[0]?.idReferente }
              : {}),
            nombre: referentes[0]?.nombre,
            apellido: referentes[0]?.apellido,
            telefono: referentes[0]?.telefono,
            email: referentes[0]?.email,
            codigo: referentes[0]?.codigo,
          },
        };
      });
    } else if (referentes.length === 0 && referenteFilter) {
      setReferenteFilter(undefined);
      setFormValues((prevFormValues: any) => {
        return {
          ...prevFormValues,
          referente: {
            ...prevFormValues?.referente,
            idReferente: null,
          },
        };
      });
    }
  }, [referenteFilter, referentes]);

  const filterTurno = useMemo(
    () => ({
      0: [{ path: 'idTurno', value: 5, method: 'notEquals' }],
    }),
    [],
  );

  const { data: turnos = [] } = useTurnoLibbyFetch({
    filter: filterTurno,
  });

  const agregarSede = () => {
    setSedesCount([...sedesCount, sedesCount.length + 1]);
    setSedesValues([...sedesValues, {}]);
  };

  const borrarSede = async (index: any, sede: any) => {
    if (sede?.idSede) {
      setLoading(true);
      await SedesDAO.remove(sede);
    }
    setSedesCount((prev: any) =>
      prev.filter((elem: any, indexFiltered: any) => index !== indexFiltered),
    );
    setSedesValues((prev: any) =>
      prev.filter((elem: any, indexFiltered: any) => index !== indexFiltered),
    );
    setLoading(false);
  };
  const readonlyButtonConfig: any = [
    {
      title: 'Salir',
      handleOnClick: async () => {
        const IsEqual = _.isEqual(initialFormValues, formValues);
        let confirm;

        const someFieldsExist =
          Boolean(formValues?.nombre) ||
          Boolean(formValues?.sector) ||
          Boolean(formValues?.fechaInicio) ||
          Boolean(formValues?.fechaFin) ||
          Boolean(formValues?.telefono) ||
          Boolean(formValues?.email) ||
          Boolean(formValues?.domicilio) ||
          Boolean(formValues?.referente?.nombre) ||
          Boolean(formValues?.referente?.apellido) ||
          Boolean(formValues?.referente?.email) ||
          Boolean(formValues?.referente?.codigo) ||
          Boolean(formValues?.referente?.telefono);

        const sedesCheck = sedesValues.filter((sede: any) => sede?.direccion);
        const hasSomething =
          someFieldsExist ||
          (sedesValues.length === sedesCheck.length && sedesCheck.length > 0);

        if (
          (!IsEqual && initialFormValues !== undefined) ||
          (hasSomething && initialFormValues === undefined)
        ) {
          confirm = await confirmDialog.show({
            title: '¿Desea continuar?',
            content:
              'La información precargada se perderá en caso que no guarde, por favor seleccione una opción.',
            confirmText: 'Salir sin guardar',
            cancelText: 'Seguir en esta pantalla',
          });

          if (confirm) {
            organizacionesReFetch();
            setIsCreatingAnOrganization({ enabled: false, row: null });
          }
        } else {
          organizacionesReFetch();
          setIsCreatingAnOrganization({ enabled: false, row: null });
        }
      },
      type: 'secondary',
      size: 'medium',
    },
    {
      title: loading ? (
        <Loading className={classes.buttonLoading} />
      ) : (
        'Guardar'
      ),
      handleOnClick: async () => {
        setLoading(true);
        await handleSave({
          formValues,
          sedesValues: [...mainSedeValues, ...sedesValues],
          accionesAsociadas: false,
          resetPassword: shouldResetPassword,
          previousEmail: previousReferenteEmail,
        });
        setLoading(false);
      },
      type: 'primary',
      size: 'medium',
      disabled: loading || guardarDisabled || error.emailMain || error.emailRef,
    },
    {
      title: loading ? (
        <Loading className={classes.buttonLoading} />
      ) : (
        'Guardar y asociar acción'
      ),
      handleOnClick: async () => {
        setLoading(true);
        await handleSave({
          formValues,
          sedesValues: [...mainSedeValues, ...sedesValues],
          accionesAsociadas: true,
          resetPassword: shouldResetPassword,
        });
        setLoading(false);
      },
      type: 'primary',
      size: 'medium',
      disabled: loading || guardarDisabled || error.emailMain || error.emailRef,
    },
  ];

  const mainSede = isCreatingAnOrganization?.row?.sedes?.find(
    (sede: any) => sede?.isMain === true,
  );

  document.addEventListener('keydown', (event) => {
    setKeyPressed(event.key);
  });
  return (
    <>
      <Grid style={{ border: '1px solid black', marginTop: '15px' }}>
        <p
          style={{
            marginLeft: '15px',
            color: grey.heading,
            fontSize: 22,
            fontFamily: 'Open Sans',
          }}
        >
          Datos de la organización
        </p>
        <Grid container alignItems="center">
          <Grid
            container
            item
            md={6}
            justifyContent="center"
            alignItems="center"
            style={{
              display: 'inline-grid',
            }}
          >
            <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
              Nombre:
              <span className={classes.labelAsterisk}>
                {formValues?.nombre ? '' : '*'}
              </span>
            </p>
            <MainInput
              value={formValues?.nombre || ''}
              handleChange={(e) => {
                const value = e?.target?.value;
                if (value.charAt(0) === ' ') {
                  return '';
                }
                setFormValues((prevState: any) => ({
                  ...prevState,
                  nombre: value,
                }));
              }}
              maxLength={DEFAULT_LIMIT}
              placeholder=""
              error={error.nombreOrg}
              fullWidth
              customStyle={{
                width: '30em',
              }}
            />
          </Grid>
          <Grid
            container
            item
            md={6}
            justifyContent="center"
            alignItems="center"
            style={{
              display: 'inline-grid',
            }}
          >
            <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>CUIT:</p>
            <MainInput
              value={formValues?.cuit || ''}
              handleChange={(e) => {
                let value = e?.target?.value;
                const dash = '-';
                const limitDashes = value.split('-').length < 3;
                if (inputValidator(value, 'cuit') && keyPressed !== dash) {
                  setFormValues((prevState: any) => ({
                    ...prevState,
                    cuit: value,
                  }));
                  if (
                    value.charAt(1) &&
                    stDashCuitState === true &&
                    limitDashes
                  ) {
                    value = value.concat(dash);
                    setStDashCuitState(false);
                  }
                  if (value.length === 1 || 0) {
                    setStDashCuitState(true);
                  }
                  if (
                    value.charAt(10) &&
                    ndDashCuitState === true &&
                    limitDashes
                  ) {
                    value = value.concat(dash);
                    setNdDashCuitState(false);
                  }
                  if (value.length === 11) {
                    setNdDashCuitState(true);
                  }
                }
                if (
                  value.length === 2 &&
                  ndDashCuitState === true &&
                  limitDashes
                ) {
                  value = value.concat(dash);
                  setNdDashCuitState(false);
                }
                if (value.length === 1 || 0) {
                  setNdDashCuitState(true);
                }
              }}
              type="string"
              maxLength={CUIT_LIMIT}
              error={error.cuit}
              helperText="Ingrese hasta 11 numeros"
              placeholder=""
              fullWidth
              customStyle={{
                width: '30em',
              }}
            />
          </Grid>
          <Grid
            container
            item
            md={6}
            alignItems="center"
            justifyContent="center"
            style={{
              display: 'inline-grid',
            }}
          >
            <p
              style={{
                fontFamily: 'Open Sans',
                marginRight: '8px',
                paddingLeft: '8px',
              }}
            >
              Sector:
              <span className={classes.labelAsterisk}>
                {formValues?.sector ? '' : '*'}
              </span>
            </p>
            <Box p={1} width="24.4em" style={{ paddingRight: '9px' }}>
              <SimpleSelect
                name="sector"
                value={formValues?.sector}
                handleChange={(e: any) => {
                  const value = e?.target?.value;
                  setFormValues((prevState: any) => ({
                    ...prevState,
                    sector: value,
                  }));
                }}
                placeholder="Seleccioná sector"
                content={sectores?.length > 0 ? sectores : []}
                labelKey="nombre"
                valueKey="idSector"
              />
            </Box>
          </Grid>
          <Grid
            container
            item
            md={6}
            justifyContent="center"
            alignItems="center"
            style={{
              display: 'inline-grid',
            }}
          >
            <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
              Domicilio:
              <span className={classes.labelAsterisk}>
                {formValues?.domicilio ? '' : '*'}
              </span>
            </p>
            <MainInput
              value={formValues?.domicilio || ''}
              handleChange={(e) => {
                const value = e?.target?.value;
                if (value.charAt(0) === ' ') {
                  return '';
                }
                if (inputValidator(value, 'domicilio')) {
                  setFormValues((prevState: any) => ({
                    ...prevState,
                    domicilio: value,
                  }));
                  setMainSedeValues((prevState: any) => [
                    {
                      ...prevState[0],
                      direccion: value,
                    },
                  ]);
                }
              }}
              maxLength={DEFAULT_LIMIT}
              error={error.domicilio}
              helperText={'No ingrese caracteres especiales'}
              placeholder=""
              fullWidth
              customStyle={{
                width: '30em',
              }}
            />
          </Grid>
          <Grid
            container
            item
            md={6}
            alignItems="center"
            justifyContent="center"
            style={{
              display: 'inline-grid',
            }}
          >
            <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
              Teléfono:
            </p>
            <MainInput
              value={formValues?.telefono || ''}
              handleChange={(e) => {
                const value = e?.target?.value;
                if (inputValidator(value, 'telefonoMain')) {
                  setFormValues((prevState: any) => ({
                    ...prevState,
                    telefono: value,
                  }));
                }
              }}
              placeholder=""
              error={error.telefonoMain}
              helperText="Ingrese solo números"
              maxLength={TELEFONO_LIMIT}
              fullWidth
              customStyle={{
                width: '30em',
              }}
            />
          </Grid>
          <Grid
            container
            item
            md={6}
            justifyContent="center"
            alignItems="center"
            style={{
              display: 'inline-grid',
            }}
          >
            <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>Mail:</p>
            <MainInput
              value={formValues?.email || ''}
              handleChange={(e) => {
                let value = e?.target?.value;
                if (/[|°!"#$%&/()=?¡`\\^~¬¿'{´:;[¨+*},<>]/.test(value)) {
                  return value.slice();
                }
                inputValidator(value, 'emailMain');
                setFormValues((prevState: any) => ({
                  ...prevState,
                  email: value.trim(),
                }));
              }}
              error={error.emailMain}
              helperText="Ingrese un mail válido"
              placeholder=""
              fullWidth
              customStyle={{
                width: '30em',
              }}
            />
          </Grid>
          <Grid
            container
            item
            md={6}
            justifyContent="center"
            alignItems="center"
            style={{
              display: 'none',
            }}
          >
            <p
              style={{
                fontFamily: 'Open Sans',
                marginRight: '8px',
                paddingLeft: '8px',
              }}
            >
              Turnos:
              <span className={classes.labelAsterisk}>
                {formValues?.turnos?.length ? '' : '*'}
              </span>
            </p>
            <Grid>
              <Autocomplete
                multiple
                id="idTurno"
                options={turnos?.length > 0 ? turnos : []}
                disableCloseOnSelect
                value={mainSedeValues[0]?.turnos || mainSede?.turnos}
                placeholder="Seleccioná turnos"
                onChange={(e: any, newValue) => {
                  const value = newValue;
                  setFormValues((prevState: any) => ({
                    ...prevState,
                    turnos: value,
                  }));
                  setMainSedeValues((prevState: any) => [
                    {
                      ...prevState[0],
                      turnos: value,
                    },
                  ]);
                }}
                classes={{ inputRoot: classes.boxHeight }}
                getOptionLabel={(option: any) => option.descripcionTurno}
                renderOption={(props: any) => (
                  <li {...props}>{props.descripcionTurno}</li>
                )}
                renderInput={(params: any) => (
                  <TextField {...params} placeholder="" />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            item
            md={6}
            justifyContent="center"
            alignItems="center"
            style={{
              marginTop: '30px',
            }}
          >
            <Typography style={{ fontFamily: 'Open Sans' }}>
              Agregar otra Sede
            </Typography>
            <IconButton onClick={() => agregarSede()}>
              <AddCircleOutlineIcon color="primary" />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      {sedesCount.map((sede: number, index: number) => {
        return (
          <SedeComponent
            index={index}
            sedesValues={sedesValues}
            turnos={turnos}
            setSedesValues={setSedesValues}
            borrarSede={borrarSede}
            loading={loading}
          />
        );
      })}
      <Grid style={{ border: '1px solid black', marginTop: '15px' }}>
        <p
          style={{
            marginLeft: '15px',
            marginTop: '15px',
            marginBottom: '15px',
            color: grey.heading,
            fontSize: 22,
            fontFamily: 'Open Sans',
          }}
        >
          Datos del referente de la organización
        </p>
        <Grid container style={{ marginBottom: '20px' }}>
          <Grid
            container
            item
            md={6}
            justifyContent="center"
            alignItems="center"
            style={{
              display: 'inline-grid',
            }}
          >
            <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
              Nombre del referente organización:
              <span className={classes.labelAsterisk}>
                {formValues?.referente?.nombre ? '' : '*'}
              </span>
            </p>
            <MainInput
              name="referente"
              value={formValues?.referente?.nombre || ''}
              handleChange={(e) => {
                const value = e?.target?.value;
                if (value.charAt(0) === ' ') {
                  return '';
                }
                if (inputValidator(value, 'nombre referente')) {
                  setFormValues((prevState: any) => ({
                    ...prevState,
                    referente: { ...prevState?.referente, nombre: value },
                  }));
                }
              }}
              maxLength={DEFAULT_LIMIT}
              error={error.nombreRef}
              helperText="Sin caracteres especiales ni números"
              placeholder=""
              fullWidth
              customStyle={{
                width: '30em',
              }}
            />
          </Grid>
          <Grid
            container
            item
            md={6}
            justifyContent="center"
            alignItems="center"
            style={{
              display: 'inline-grid',
            }}
          >
            <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
              Apellido del referente organización:
              <span className={classes.labelAsterisk}>
                {formValues?.referente?.apellido ? '' : '*'}
              </span>
            </p>
            <MainInput
              name="referenteApellido"
              value={formValues?.referente?.apellido || ''}
              handleChange={(e) => {
                const value = e?.target?.value;
                if (value.charAt(0) === ' ') {
                  return '';
                }
                if (inputValidator(value, 'apellido referente')) {
                  setFormValues((prevState: any) => ({
                    ...prevState,
                    referente: { ...prevState?.referente, apellido: value },
                  }));
                }
              }}
              maxLength={DEFAULT_LIMIT}
              error={error.nombreRef}
              helperText="Sin caracteres especiales ni números"
              placeholder=""
              fullWidth
              customStyle={{
                width: '30em',
              }}
            />
          </Grid>
          <Grid
            container
            item
            md={6}
            justifyContent="center"
            alignItems="center"
            style={{
              display: 'inline-grid',
            }}
          >
            <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
              Teléfono:
              <span className={classes.labelAsterisk}>
                {formValues?.referente?.telefono ? '' : '*'}
              </span>
            </p>
            <MainInput
              name="telefonoReferente"
              value={formValues?.referente?.telefono || ''}
              handleChange={(e) => {
                const value = e?.target?.value;
                if (inputValidator(value, 'telefonoMain')) {
                  setFormValues((prevState: any) => ({
                    ...prevState,
                    referente: { ...prevState?.referente, telefono: value },
                  }));
                }
              }}
              maxLength={TELEFONO_LIMIT}
              error={error.telefonoMain}
              helperText="Ingrese solo números"
              placeholder=""
              fullWidth
              customStyle={{
                width: '30em',
              }}
            />
          </Grid>
          <Grid
            container
            item
            md={6}
            justifyContent="center"
            alignItems="center"
            style={{
              display: 'inline-grid',
            }}
          >
            <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
              Mail:
              <span className={classes.labelAsterisk}>
                {formValues?.referente?.email ? '' : '*'}
              </span>
            </p>
            <MainInput
              name="mailReferente"
              value={formValues?.referente?.email || ''}
              handleChange={(e) => {
                const value = e?.target?.value;
                if (/[|°!"#$%&/()=?¡`\\^~¬¿'{´:;[¨+*},<>]/.test(value)) {
                  return value.slice();
                }
                inputValidator(value, 'emailRef');
                setPreviousReferenteEmail(formValues?.referente?.email);
                setEmailFieldDirty(true);
                setFormValues((prevState: any) => ({
                  ...prevState,
                  referente: { ...prevState?.referente, email: value.trim() },
                }));
              }}
              handleBlur={(e) => {
                if (emailFieldDirty && !!!error.emailRef) {
                  setReferenteFilter({
                    referente: [
                      {
                        path: 'email',
                        value: e.target.value,
                      },
                    ],
                  });
                }
              }}
              error={error.emailRef}
              helperText="Ingrese un mail válido"
              placeholder=""
              fullWidth
              customStyle={{
                width: '30em',
              }}
            />
            {emailFieldDirty && workingReferente && (
              <span style={{ color: '#00bcd4', pointerEvents: 'none' }}>
                {'Verificando existencia de correo...'}
              </span>
            )}
          </Grid>
          <Grid
            container
            item
            md={6}
            justifyContent="center"
            alignItems="center"
            style={{
              display: 'inline-grid',
              marginLeft: '20px',
            }}
          >
            <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
              Código ingreso:
            </p>
            <Grid container direction="row">
              <MainInput
                name="codigo"
                value={formValues?.referente?.codigo}
                handleChange={() => {}}
                placeholder="no habilitado"
                fullWidth
                customStyle={{
                  width: '30em',
                }}
                disabled={true}
              />
              <IconButton onClick={() => resetPassword()}>
                <CachedIcon color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid style={{ border: '1px solid black', marginTop: '15px' }}>
        <p
          style={{
            marginLeft: '15px',
            marginTop: '15px',
            marginBottom: '0px',
            color: grey.heading,
            fontSize: 22,
            fontFamily: 'Open Sans',
          }}
        >
          Vigencia
        </p>
        <Grid container style={{ marginBottom: '20px' }}>
          <Grid
            container
            item
            md={6}
            justifyContent="center"
            alignItems="center"
            style={{
              display: 'inline-grid',
            }}
          >
            <p style={{ fontFamily: 'Open Sans', marginLeft: '8px' }}>
              Fecha de inicio:
              <span className={classes.labelAsterisk}>
                {formValues?.fechaInicio ? '' : '*'}
              </span>
            </p>
            <DatePicker
              value={formValues?.fechaInicio}
              onChange={(_date: any) => {
                setFormValues({
                  ...formValues,
                  fechaInicio: _date?.toDate(),
                  ...(_date?.toDate().getTime() >
                  new Date(formValues?.fechaFin).getTime()
                    ? { fechaFin: _date?.toDate() }
                    : {}),
                });
              }}
            />
          </Grid>
          <Grid
            container
            item
            md={6}
            justifyContent="center"
            alignItems="center"
            style={{
              display: 'inline-grid',
            }}
          >
            <p style={{ fontFamily: 'Open Sans', marginLeft: '8px' }}>
              Fecha de fin:
              <span className={classes.labelAsterisk}>
                {formValues?.fechaFin ? '' : '*'}
              </span>
            </p>
            <DatePicker
              value={formValues?.fechaFin}
              disabled={formValues?.fechaInicio ? false : true}
              onChange={(_date: any) => {
                if (
                  _date.toDate().getTime() >=
                  new Date(formValues?.fechaInicio).getTime()
                ) {
                  setFormValues({
                    ...formValues,
                    fechaFin: _date?.toDate(),
                  });
                }
              }}
              minDate={formValues?.fechaInicio}
            />
          </Grid>
        </Grid>
      </Grid>
      <Footer buttonConfig={readonlyButtonConfig} />
    </>
  );
};
