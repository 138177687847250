import { CommonEndpointDAO } from './CommonEndpointDAO';

export class ReloginDAO extends CommonEndpointDAO {
  constructor() {
    super('relogin');
  }

  selectRole(
    idUsuarioRolEstablecimiento: string,
    nivel: string,
    localizacion: string,
  ) {
    return this.post({ id: idUsuarioRolEstablecimiento, nivel, localizacion });
  }

  unselectRole() {
    return this.post({});
  }
}
