import React from 'react';
import { useRouteMatch } from 'react-router';
import { useRouteScreenTitle } from '../../../commons/hooks';
import { BLOCKER_ID } from '../../../platform/permission/const';
import { CONFIGURATION_IMAGE_CARD } from '../../../commons/const/configurationImageCard';
import {
  MenuCardList,
  MenuCardItem,
} from '../../../commons/components/MenuCardList';
import { PermissionBlocker } from 'src/lib/permission';

export const configurationItems: MenuCardItem[] = [
  {
    id: BLOCKER_ID.CONFIGURATION_ESPACIO_CURRICULAR,
    title: 'Espacios Curriculares',
    img: CONFIGURATION_IMAGE_CARD.ESPACIO_CURRICULAR,
    path: '/espacioscurriculares',
  },
];

export const Configuration = () => {
  useRouteScreenTitle('Configuración');
  const match = useRouteMatch();
  return (
    <PermissionBlocker id={BLOCKER_ID.CONFIGURATIONS}>
      <MenuCardList items={configurationItems} url={match.url} />
    </PermissionBlocker>
  );
};
