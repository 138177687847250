import * as React from 'react';

export interface ActiveCustomIcon {
  isActive: boolean;
}

export const CreatecSideBarIcon = ({
  isActive,
}: ActiveCustomIcon): JSX.Element => {
  const color = isActive ? '#FFFFFF' : '#737373';
  return (
    <svg
      viewBox="45 50 690 800"
      height="27px"
      display="flex"
      padding-right="0px"
    >
      <path
        d="M675.898,407.053v-8.208c0-64.812-52.729-117.539-117.537-117.539H418.936v-50.221  c31.634-8.388,55.018-37.252,55.018-71.483c0-40.781-33.173-73.953-73.952-73.953c-40.778,0-73.956,33.172-73.956,73.953  c0,34.231,23.386,63.096,55.017,71.483v50.221H241.639c-64.811,0-117.539,52.728-117.539,117.539v8.208  c-45.9,8.871-80.675,49.351-80.675,97.797c0,48.509,34.849,89.014,80.819,97.831c3.067,62.093,54.55,111.671,117.395,111.671  h316.723c62.843,0,114.323-49.578,117.392-111.671c45.971-8.817,80.822-49.322,80.822-97.831  C756.575,456.402,721.801,415.924,675.898,407.053z M124.1,563.604c-24.803-8.013-42.797-31.315-42.797-58.755  c0-27.434,17.994-50.735,42.797-58.75V563.604z M363.922,159.602c0-19.893,16.183-36.078,36.078-36.078  c19.893,0,36.078,16.186,36.078,36.078S419.893,195.68,400,195.68C380.105,195.68,363.922,179.493,363.922,159.602z   M638.026,596.813L638.026,596.813c-0.001,43.927-35.74,79.661-79.665,79.661H241.639c-43.928,0-79.664-35.734-79.664-79.661  V398.845c0-43.925,35.736-79.661,79.664-79.661h316.723c43.925,0,79.664,35.736,79.664,79.661V596.813z M675.898,563.604V446.1  c24.802,8.015,42.799,31.316,42.799,58.75C718.697,532.288,700.7,555.592,675.898,563.604z"
        fill={color}
      />
      <path
        d="M538.119,400.611c-30.806,0-55.865,25.063-55.865,55.865c0,30.806,25.061,55.866,55.865,55.866  c30.804,0,55.867-25.062,55.867-55.866C593.986,425.675,568.923,400.611,538.119,400.611z M538.119,474.466  c-9.92,0-17.99-8.065-17.99-17.988c0-9.92,8.07-17.993,17.99-17.993c9.921,0,17.992,8.073,17.992,17.993  C556.111,466.399,548.04,474.466,538.119,474.466z"
        fill={color}
      />
      <path
        d="M261.881,400.611c-30.807,0-55.866,25.063-55.866,55.865c0,30.806,25.06,55.866,55.866,55.866  c30.803,0,55.866-25.062,55.866-55.866C317.747,425.675,292.684,400.611,261.881,400.611z M261.881,474.466  c-9.92,0-17.991-8.065-17.991-17.988c0-9.92,8.071-17.993,17.991-17.993c9.919,0,17.991,8.073,17.991,17.993  C279.872,466.399,271.8,474.466,261.881,474.466z"
        fill={color}
      />
      <path
        d="M451.604,571.221H348.395c-10.46,0-18.939,8.479-18.939,18.934c0,10.461,8.479,18.938,18.939,18.938h103.211  c10.457,0,18.937-8.479,18.937-18.938C470.542,579.698,462.063,571.221,451.604,571.221z"
        fill={color}
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M675.898,407.053v-8.208c0-64.812-52.729-117.539-117.537-117.539H418.936v-50.221  c31.634-8.388,55.018-37.252,55.018-71.483c0-40.781-33.173-73.953-73.952-73.953c-40.778,0-73.956,33.172-73.956,73.953  c0,34.231,23.386,63.096,55.017,71.483v50.221H241.639c-64.811,0-117.539,52.728-117.539,117.539v8.208  c-45.9,8.871-80.675,49.351-80.675,97.797c0,48.509,34.849,89.014,80.819,97.831c3.067,62.093,54.55,111.671,117.395,111.671  h316.723c62.843,0,114.323-49.578,117.392-111.671c45.971-8.817,80.822-49.322,80.822-97.831  C756.575,456.402,721.801,415.924,675.898,407.053z M124.1,563.604c-24.803-8.013-42.797-31.315-42.797-58.755  c0-27.434,17.994-50.735,42.797-58.75V563.604z M363.922,159.602c0-19.893,16.183-36.078,36.078-36.078  c19.893,0,36.078,16.186,36.078,36.078S419.893,195.68,400,195.68C380.105,195.68,363.922,179.493,363.922,159.602z   M638.026,596.813L638.026,596.813c-0.001,43.927-35.74,79.661-79.665,79.661H241.639c-43.928,0-79.664-35.734-79.664-79.661  V398.845c0-43.925,35.736-79.661,79.664-79.661h316.723c43.925,0,79.664,35.736,79.664,79.661V596.813z M675.898,563.604V446.1  c24.802,8.015,42.799,31.316,42.799,58.75C718.697,532.288,700.7,555.592,675.898,563.604z"
        fill={color}
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M538.119,400.611c-30.806,0-55.865,25.063-55.865,55.865c0,30.806,25.061,55.866,55.865,55.866  c30.804,0,55.867-25.062,55.867-55.866C593.986,425.675,568.923,400.611,538.119,400.611z M538.119,474.466  c-9.92,0-17.99-8.065-17.99-17.988c0-9.92,8.07-17.993,17.99-17.993c9.921,0,17.992,8.073,17.992,17.993  C556.111,466.399,548.04,474.466,538.119,474.466z"
        fill={color}
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M261.881,400.611c-30.807,0-55.866,25.063-55.866,55.865c0,30.806,25.06,55.866,55.866,55.866  c30.803,0,55.866-25.062,55.866-55.866C317.747,425.675,292.684,400.611,261.881,400.611z M261.881,474.466  c-9.92,0-17.991-8.065-17.991-17.988c0-9.92,8.071-17.993,17.991-17.993c9.919,0,17.991,8.073,17.991,17.993  C279.872,466.399,271.8,474.466,261.881,474.466z"
        fill={color}
      />
      <path
        xmlns="http://www.w3.org/2000/svg"
        d="M451.604,571.221H348.395c-10.46,0-18.939,8.479-18.939,18.934c0,10.461,8.479,18.938,18.939,18.938h103.211  c10.457,0,18.937-8.479,18.937-18.938C470.542,579.698,462.063,571.221,451.604,571.221z"
        fill={color}
      />
    </svg>
  );
};
