import React from 'react';
import { useParams } from 'react-router';
import {
  useBackButton,
  useRouteScreenTitle,
} from '../../../../../../../commons/hooks';
import { PlanificacionDisciplinarOrientadaNew } from './components';
import { PlanificacionDisciplinarOrientadoForm } from './PlanificacionDisciplinarOrientadaForm';
import { EspaciosDiscipOrientPlanById } from '../../../../../../../app/business/EspaciosDiscipOrientPlan';
import { useHistory } from 'react-router-dom';
import { Loading } from '../../../../../../../commons/components/Loading';

// TODO: definir que hacer con esto
const planificacionTemplateId = 1;
export const PlanificacionDisciplinarOrientada = () => {
  // Title
  useRouteScreenTitle('Planificación Disciplinar Orientado');
  const history = useHistory();
  useBackButton(history.location.pathname.match(/.+\d/i)[0]);
  // @ts-ignore
  const { idPlanificacion } = useParams();

  return idPlanificacion === 'new' ? (
    <PlanificacionDisciplinarOrientadaNew templateId={planificacionTemplateId}>
      {({ template, initialValues, working }) =>
        working || !template || !initialValues ? (
          <Loading />
        ) : (
          <PlanificacionDisciplinarOrientadoForm
            initialValues={initialValues}
            template={template}
          />
        )
      }
    </PlanificacionDisciplinarOrientadaNew>
  ) : (
    <EspaciosDiscipOrientPlanById id={idPlanificacion} aspect="limit_province">
      {({ data, working }) => {
        delete data?.discipOrientPlan?.cicloLectivo;

        return working || !data ? (
          <Loading />
        ) : (
          <PlanificacionDisciplinarOrientadoForm
            initialValues={data.discipOrientPlan}
            template={data.discipOrientPlan.orientDiscipTempPlan}
          />
        );
      }}
    </EspaciosDiscipOrientPlanById>
  );
};
