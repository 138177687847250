import React from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import { ActividadPlan } from 'src/app/models';
import { grey } from 'src/theme/colors';
import {
  periodos,
  Periodo,
} from '../../../forms/ArealInterareal/tabs/Actividad';
import { useInterdisciplinarContext } from '../../../forms/commons/components';
import { ListItemProperties } from '../../../feedbacks/commons';

const useStyles = makeStyles(() => ({
  textField: {
    fontSize: 14,
    color: grey.textPrimary,
    fontWeight: 600,
  },
  valueField: {
    fontSize: 16,
    color: grey.textPrimary,
    fontWeight: 400,
    marginTop: 8,
    marginBottom: 20,
  },
  itemContainer: {
    border: `1px solid ${grey.normal}`,
    borderRight: 0,
    borderRadius: 4,
    marginBottom: 25,
    width: '80%',
  },
  containerList: {
    borderRight: `1px solid ${grey.normal}`,
    width: 500,
    '@media (max-width: 1600px)': {
      width: 150,
    },
    '@media (max-width: 1200px)': {
      width: 100,
    },
    '@media (max-width: 900px)': {
      width: 60,
    },
    '@media print': {
      width: '100%',
    },
    overflowWrap: 'break-word',
  },
  containerDescripcion: {
    borderRight: `1px solid ${grey.normal}`,
    width: 300,
    '@media (max-width: 1600px)': {
      width: 150,
    },
    '@media (max-width: 1200px)': {
      width: 200,
    },
    '@media (max-width: 900px)': {
      width: 60,
    },
    '@media print': {
      width: '100%',
    },
    overflowWrap: 'break-word',
  },
  containerPeriodo: {
    width: 250,
    borderRight: `1px solid ${grey.normal}`,
    '@media (max-width: 1600px)': {
      width: 150,
    },
    '@media (max-width: 1200px)': {
      width: 100,
    },
    '@media (max-width: 900px)': {
      width: 60,
    },
    '@media print': {
      width: '100%',
    },
    overflowWrap: 'break-word',
  },
  containerPeriodoCali: {
    width: 400,
  },
  itemContainerPeriodo: {
    border: `1px solid ${grey.normal}`,
    borderLeft: 0,
    borderRadius: 4,
    marginBottom: 25,
    width: '20%',
  },
  containerHoras: {
    borderRight: `1px solid ${grey.normal}`,
    width: 190,
    '@media (max-width: 1200px)': {
      width: 100,
    },
    '@media (max-width: 900px)': {
      width: 50,
    },
    '@media print': {
      width: '100%',
    },
    overflowWrap: 'break-word',
  },
}));
export interface ActividadProps {
  data: ActividadPlan;
}

export const Actividad = ({ data }: ActividadProps) => {
  const classes = useStyles();
  const {
    data: { recursos },
    working,
  } = useInterdisciplinarContext();

  const filterPeriodoCalificacion =
    data?.periodoDeCalificacionFinalDelProyecto?.map((periodo: string) =>
      periodos.find((_periodo: Periodo) => periodo === _periodo.id.toString()),
    );
  const filterActividades = data?.actividades?.map((actividad: any) => {
    const filterRecursos = actividad?.recursos?.map((recurso: string) =>
      recursos.find((_recursos: any) => recurso === _recursos.idRecursos),
    );
    const filterPeriodo = actividad?.periodo?.map((periodo: string) =>
      periodos.find((_periodo: Periodo) => periodo === _periodo.id.toString()),
    );

    return {
      ...actividad,
      recursos: filterRecursos,
      periodo: filterPeriodo,
    };
  });

  return (
    <Grid container>
      {!working &&
        filterActividades.map(
          (
            { nombre, periodo, recursos: _recursos, descripcion, horasCatedra },
            index: number,
          ) => (
            <Grid
              container
              spacing={2}
              className={classes.itemContainer}
              key={index}
            >
              <Grid item className={classes.containerDescripcion}>
                <Typography className={classes.textField}>Nombre</Typography>
                <Typography className={classes.valueField}>{nombre}</Typography>
              </Grid>
              <Grid item className={classes.containerDescripcion}>
                <Typography className={classes.textField}>
                  Descripción
                </Typography>
                <Typography className={classes.valueField}>
                  {descripcion}
                </Typography>
              </Grid>
              <Grid item spacing={2} className={classes.containerList}>
                {_recursos[0] && (
                  <ListItemProperties
                    title="Recursos"
                    values={_recursos?.map((h: any) => ({
                      id: h.idRecursos,

                      descripcion: h.descripcion,
                    }))}
                  />
                )}
              </Grid>
              <Grid item className={classes.containerHoras}>
                <Typography className={classes.textField}>
                  Horas cátedra
                </Typography>
                <Typography className={classes.valueField}>
                  {horasCatedra}
                </Typography>
              </Grid>
              <Grid item className={classes.containerPeriodo}>
                <Typography className={classes.textField}>Período</Typography>
                {periodo.map((p: any) => (
                  <Typography key={p.id} className={classes.valueField}>
                    {p.name}
                  </Typography>
                ))}
              </Grid>
            </Grid>
          ),
        )}
      <Grid container spacing={2} className={classes.itemContainerPeriodo}>
        <Grid item className={classes.containerPeriodoCali}>
          <Typography className={classes.textField}>
            Periodo de calificación final del proyecto
          </Typography>
          {!working &&
            filterPeriodoCalificacion.map((p: any) => (
              <Typography key={p.id} className={classes.valueField}>
                {p.name}
              </Typography>
            ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
