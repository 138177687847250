import React, { FC, useMemo } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { SimpleSelect } from 'src/commons/components/Select';
import { makeIconColor } from './utils';
import _ from 'lodash';
import { useSelectPassStatusOptions } from '../../../hooks/SelectPassStatusOptions/useSelectPassStatusOptions';
import { PlanEstudioNivel } from 'src/app/models';

const useStyles = makeStyles(() =>
  createStyles({
    icon: { fontSize: 12, padding: '0px 5px 0px 5pxc' },
  }),
);

interface ISelectPassStatusProps {
  value: string | number;
  handleChange(value: React.ChangeEvent<any>): void;
  options: any;
  title?: string;
  row?: any;
  disabled?: boolean;
  localizacionData?: any;
  nivel: any;
  isPromocionAcompanada?: boolean;
  idEstadoDeLaProyeccion?: number;
  totalPendientes?: number;
  planEstudioNivel?: PlanEstudioNivel;
}

const year_pass_status_map: { [key: string]: string } = {
  '2': 'No Promovido',
  '3': 'Egresa',
  '1': 'Promovido',
  '4': 'Promoción en proceso',
  '5': 'Pase a otro establecimiento',
  '6': 'Completa estudios',
  '9': 'No completó el Plan de Estudios',
};

export const SelectPassStatus: FC<ISelectPassStatusProps> = ({
  value,
  handleChange,
  options,
  title,
  row = {},
  disabled,
  localizacionData,
  nivel,
  isPromocionAcompanada,
  idEstadoDeLaProyeccion,
  totalPendientes = 0,
  planEstudioNivel,
}) => {
  const classes = useStyles();

  const { newOptions } = useSelectPassStatusOptions({
    row,
    options,
    localizacionData,
    nivel,
    idEstadoDeLaProyeccion: idEstadoDeLaProyeccion ?? 1,
    totalPendientes,
    planEstudioNivel,
  });
  const passStatus = useMemo(() => {
    const optionsPromocionAcompaniada = _.cloneDeep(newOptions);
    optionsPromocionAcompaniada
      .map((estadoPase: any) => {
        estadoPase.descripcionEstadoPaseAnio =
          year_pass_status_map[estadoPase.idEstadoPaseAnio];
        return estadoPase;
      })
      .sort((estadoPaseA: any, estadoPaseB: any) => {
        const descripcionEstadoPaseA = estadoPaseA?.descripcionEstadoPaseAnio
          ?.toLowerCase()
          .replace(/\s+/g, '')
          .replace(/promovido+/g, '0');
        const descripcionEstadoPaseB = estadoPaseB?.descripcionEstadoPaseAnio
          ?.toLowerCase()
          .replace(/\s+/g, '')
          .replace(/promovido+/g, '0');

        if (descripcionEstadoPaseA < descripcionEstadoPaseB) {
          return -1;
        }
        if (descripcionEstadoPaseA > descripcionEstadoPaseB) {
          return 1;
        }
        return 0;
      });

    return (
      isPromocionAcompanada ? optionsPromocionAcompaniada : newOptions
    ).map((item: { idEstadoPaseAnio: any }) => ({
      icon: (
        <FiberManualRecordIcon
          className={classes.icon}
          style={{
            color: makeIconColor[item.idEstadoPaseAnio],
            textAlign: 'center',
          }}
        />
      ),
      ...item,
    }));
  }, [classes.icon, isPromocionAcompanada, newOptions]);
  return (
    <SimpleSelect
      title={title}
      name="passStatus"
      value={value}
      handleChange={handleChange}
      placeholder="Seleccioná Estado"
      content={passStatus}
      labelKey="descripcionEstadoPaseAnio"
      valueKey="idEstadoPaseAnio"
      disableUnderline
      variant="standard"
      disabled={disabled}
    />
  );
};
