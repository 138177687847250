import React from 'react';
import { useParams } from 'react-router';
import { EspaciosDiscipOrientPlanById } from 'src/app/business';
import {
  useBackButton,
  useRouteScreenTitle,
} from '../../../../../../../commons/hooks';
import { Loading } from '../../../../../../../commons/components/Loading';
import { DisciplinarOrientadaFlow } from './DisciplinarOrientadaFlow';

export const DisciplinarOrientadaFeedback = () => {
  // Title
  useRouteScreenTitle(
    'Asesoramiento para la planificación Disciplinar orientada',
  );
  useBackButton();
  // @ts-ignore
  const { idPlanificacion } = useParams();

  return (
    <EspaciosDiscipOrientPlanById id={idPlanificacion} aspect="limit_province">
      {({ data, working }) =>
        // @ts-ignore
        working || !data ? (
          <Loading />
        ) : (
          <DisciplinarOrientadaFlow data={data} />
        )
      }
    </EspaciosDiscipOrientPlanById>
  );
};
