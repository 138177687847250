import { makeBusinessEntity } from 'src/lib/libby';
import { InfoPlataforma } from '../models';

const DAO_NAME = 'info_plataforma';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
} = makeBusinessEntity<InfoPlataforma>(DAO_NAME);

// hooks
export const useInfoPlataformaFetchById = fetchById;
export const useInfoPlataformaLibbyCall = libbyCall;
export const useInfoPlataformaDAO = libbyDAO;
export const useInfoPlataformaLibbyFetch = libbyFetch;
