import React, { useEffect, useState } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { LibbyObject } from 'src/commons';
import { useLibbyCall } from 'src/commons/hooks';
import { SelectStudent } from './SelectStudent';

const SelectStudentParentRaw = ({
  libby,
  setSelectStudent,
  selectStudent,
  location,
  level,
}: LibbyObject) => {
  const [filter, setFilter] = useState<any>({
    localizacion: [
      { path: 'localizacion', value: location, method: 'notEquals' },
    ],
    nivel: level ? [{ path: 'nivel', value: level }] : [],
  });

  return (
    !!filter && (
      <SelectStudent
        filterById={filter}
        selectStudent={selectStudent}
        setSelectStudent={setSelectStudent}
      />
    )
  );
};

export const SelectStudentParent = DatabaseConnector(SelectStudentParentRaw)(
  'solicitud_pases',
);
