export type levelType = {
  GENERAL: number;
  INICIAL: number;
  PRIMARIO: number;
  SECUNDARIO: number;
  TERCIARIO: number;
};

export const level: levelType = {
  GENERAL: 0,
  INICIAL: 1,
  PRIMARIO: 2,
  SECUNDARIO: 3,
  TERCIARIO: 4,
};
