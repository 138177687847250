import { makeEntityLibbyCall } from '../../../lib/libby/hooks/makeEntityLibbyCall';
import { makeEntityLibbyDAO } from '../../../lib/libby/hooks/makeEntityLibbyDAO';
import { REQUEST_TYPES } from 'src/lib/libby/hooks/useLibbyRequest';
import { makeEntityLibbyRequest } from '../../../lib/libby/hooks/makeEntityLibbyRequest';
import { AlumnoExisteDAO } from 'src/platform/libby/dao';

const DAO_NAME = 'alumnoexiste';

// hooks
export const useAlumnoExisteLibbyCall = makeEntityLibbyCall(DAO_NAME);
export const useAlumnoExisteDAO = makeEntityLibbyDAO<AlumnoExisteDAO>(DAO_NAME);

export const useAlumnoExisteGetRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.GET,
);
export const useAlumnoExistePostRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.POST,
);
export const useAlumnoExistePutRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.PUT,
);
export const useAlumnoExisteDeleteRequest = makeEntityLibbyRequest(
  DAO_NAME,
  REQUEST_TYPES.DELETE,
);
