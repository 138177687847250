import moment from 'moment';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { CicloLectivo } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'ciclo_lectivo';

export const INITIAL_YEAR = moment('2021-01-01T04:00:00.000Z').year();

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idCicloLectivo',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idCicloLectivo',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.CicloLectivo
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.CicloLectivo,
      hoc: { ...defaultOptions.hoc, ...businessOptions.CicloLectivo.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<CicloLectivo>(DAO_NAME, optionsToUse);

// hooks
export const useCicloLectivoFetchById = fetchById;
export const useCicloLectivoLibbyCall = libbyCall;
export const useCicloLectivoDAO = libbyDAO;
export const useCicloLectivoLibbyFetch = libbyFetch;

// components/hoc
export const CicloLectivoAutocomplete = entityAutocomplete;
export const CicloLectivoCall = renderPropCall;
export const CicloLectivoById = renderPropFetchById;
export const CicloLectivoSelect = simpleSelect;
export const CicloLectivoAccordionSelector = accordionSelector;
export const CicloLectivoSelectedChips = selectedChips;

// context
// ByIdContext
export const useCicloLectivoByIdContext = useByIdContext;
export const CicloLectivoByIdProvider = ByIdProvider;
export const CicloLectivoByIdContext = ByIdContext;
// GeneralContext
export const useCicloLectivoContext = useGeneralContext;
export const CicloLectivoProvider = GeneralProvider;
export const CicloLectivoContext = GeneralContext;
