import { MenuItem } from '../../../../commons/const/ui/menu';
import { PpsComponent } from '../../../../screens/Private/PPS/Pps';

export const routes: MenuItem[] = [
  {
    key: 'pps',
    basePath: '/',
    target: {
      component: PpsComponent,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'PPS',
    children: [],
    default: true,
  },
];
