import React from 'react';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { grey } from 'src/theme/colors';
import { TextField, TextFieldProps } from './TextField';

export const useFormInputStyles = makeStyles({
  label: {
    marginBottom: 5,
    fontSize: 13,
    fontWeight: 600,
    color: grey.medium,
    marginLeft: 13,
  },
});

export type InputProps = TextFieldProps;

export const Input = (props: InputProps): JSX.Element => {
  const { label, icon, ...rest } = props;
  const classes = useFormInputStyles();
  return (
    <div>
      {label && (
        <Typography className={classes.label}>
          {label}
          {icon && icon}
        </Typography>
      )}
      <TextField {...rest} />
    </div>
  );
};
