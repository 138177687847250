import {
  useLibbyCall,
  LibbyCallOptions,
  LibbyCallReturn,
} from './useLibbyCall';

export type EntityLibbyCallOptions = Omit<LibbyCallOptions, 'daoName'>;

export const makeEntityLibbyCall =
  <T>(daoName: string) =>
  (options: EntityLibbyCallOptions): LibbyCallReturn<T> =>
    useLibbyCall<T>({
      daoName,
      ...options,
    });
