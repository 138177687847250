import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { TipoMedioContacto } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'tipo_medio_contacto';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idTipoMedioContacto',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idTipoMedioContacto',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.TipoMedioContacto
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.TipoMedioContacto,
      hoc: { ...defaultOptions.hoc, ...businessOptions.TipoMedioContacto.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<TipoMedioContacto>(DAO_NAME, optionsToUse);

// hooks
export const useTipoMedioContactoFetchById = fetchById;
export const useTipoMedioContactoLibbyCall = libbyCall;
export const useTipoMedioContactoDAO = libbyDAO;
export const useTipoMedioContactoLibbyFetch = libbyFetch;

// components/hoc
export const TipoMedioContactoAutocomplete = entityAutocomplete;
export const TipoMedioContactoCall = renderPropCall;
export const TipoMedioContactoById = renderPropFetchById;
export const TipoMedioContactoSelect = simpleSelect;
export const TipoMedioContactoAccordionSelector = accordionSelector;
export const TipoMedioContactoSelectedChips = selectedChips;

// context
// ByIdContext
export const useTipoMedioContactoByIdContext = useByIdContext;
export const TipoMedioContactoByIdProvider = ByIdProvider;
export const TipoMedioContactoByIdContext = ByIdContext;
// GeneralContext
export const useTipoMedioContactoContext = useGeneralContext;
export const TipoMedioContactoProvider = GeneralProvider;
export const TipoMedioContactoContext = GeneralContext;
