import React, { FunctionComponent, useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableHead,
  TableRow,
  Radio,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useLegajoData } from 'src/screens/Private/LegajoAlumno/index';
import { Loading } from 'src/commons/components/Loading';
import { SimpleSelect, MainInput } from 'src/commons';
import { ModalBase } from 'src/commons/const/ui';
import { primary } from 'src/theme/colors';
import { useResponsableLibbyCall, useVinculoDAO } from 'src/app/business';
import { Responsable, Vinculo } from 'src/app/models';

const useStyles = makeStyles({
  button: {
    textTransform: 'capitalize',
  },
  buttonCancel: {
    textTransform: 'capitalize',
    color: primary.lightBlue,
  },
  centered: {
    marginTop: 90,
  },
  resultsContainer: {
    minHeight: 300,
  },
  searchButton: {
    height: 43,
    textTransform: 'none',
    color: '#595959',
  },
  searchInput: {
    height: 30,
  },
  sinResponsablesDisclaimer: {
    color: '#464646',
    fontSize: 16,
    textAlign: 'center',
  },
  sinResponsablesAgregar: {
    color: primary.lightBlue,
    fontSize: 16,
    textAlign: 'center',
    cursor: 'pointer',
  },
  tableContainer: {
    marginTop: 10,
  },
  tableBody: {
    fontSize: 13,
  },
  tableHead: {
    backgroundColor: '#f5f5f5',
    color: '#464646',
  },
});

const searchOptions = [
  {
    label: 'Apellido',
    optionId: 1,
  },
  {
    label: 'Documento',
    optionId: 2,
  },
];

const placeholders = ['Ingresá apellido', 'Ingresá documento'];
const tableHeadConfig = [' ', 'Apellido y Nombre', 'Documento'];

interface AddResponsableModalProps {
  onAddResponsable: () => void;
  onAddSelectedResponsable: () => void;
  open: boolean;
  onClose: () => void;
  vinculos: Vinculo[];
}

export const AddResponsableModal: FunctionComponent<
  AddResponsableModalProps
> = ({
  open,
  onClose,
  onAddResponsable,
  onAddSelectedResponsable,
  vinculos,
}) => {
  const classes = useStyles();

  const vinculoDAO = useVinculoDAO();

  const [searchValue, setSearchValue] = useState<string>('');
  const [searchType, setSearchType] = useState<number>(1);
  const [searchSubmitted, setSearchSubmitted] = useState<boolean>(false);
  const [selectedResponsable, setSelectedResponsable] =
    useState<Responsable | null>(null);
  const [updatedResponsables, setResponsables] = useState<Responsable[]>([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const {
    data: responsables,
    recall,
    working,
  } = useResponsableLibbyCall({
    methodName: searchType === 1 ? 'getByApellido' : 'getByDocumento',
    params: [searchValue],
    noAutoCall: true,
  });

  const { alumno: idAlumno } = useLegajoData();

  useEffect(() => {
    responsables?.length > 0
      ? setResponsables(responsables)
      : setResponsables([]);
  }, [responsables]);

  const searchForResponsables = () => {
    setResponsables([]);
    recall(searchValue);
    setSearchSubmitted(true);
    setSelectedResponsable(null);
  };

  const closeModal = () => {
    setSearchValue('');
    setSearchSubmitted(false);
    setSearchType(1);
    setSelectedResponsable(null);
    setResponsables([]);
    onClose();
  };

  const handleSelectSearchType = (e: any) => {
    setSearchType(e.target.value);
  };

  const handleSelectResponsable = (e: any) => {
    const responsable = updatedResponsables.find(
      (responsableResult: Responsable) =>
        responsableResult.persona.idPersona === e.target.value,
    );
    responsable && setSelectedResponsable(responsable);
  };

  const addSelectedResponsable = async () => {
    await vinculoDAO.save({
      alumno: idAlumno,
      tipoVinculo: -1,
      responsable: selectedResponsable?.idResponsable,
      contactoEmergencia: 0,
    });
    onAddSelectedResponsable();
    closeModal();
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setRowsPerPage(value);
    setPage(0);
  };

  const modalContentSearchBar = (
    <Grid item container direction="row" spacing={3}>
      <Grid item xs={3}>
        <SimpleSelect
          labelKey="label"
          valueKey="optionId"
          content={searchOptions}
          handleChange={handleSelectSearchType}
          value={searchType}
          placeholder=""
        />
      </Grid>
      <Grid item xs={6}>
        <MainInput
          type="search"
          handleChange={(e) => setSearchValue(e.target.value)}
          placeholder={placeholders[searchType - 1]}
          name="search"
          value={searchValue}
          fullWidth
          customStyle={classes.searchInput}
        />
      </Grid>
      <Grid item xs={3}>
        <Button
          onClick={searchForResponsables}
          disabled={searchValue === '' || working}
          variant="outlined"
          className={classes.searchButton}
          autoFocus
          fullWidth
        >
          Buscar
        </Button>
      </Grid>
    </Grid>
  );

  const modalContentWithResults = (
    <Grid className={classes.resultsContainer} container direction="column">
      {modalContentSearchBar}
      <Grid item>
        <TableContainer className={classes.tableContainer} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {tableHeadConfig.map((heading) => (
                  <TableCell
                    classes={{
                      head: classes.tableHead,
                      body: classes.tableBody,
                    }}
                  >
                    {heading}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {updatedResponsables
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((responsableResult: Responsable) => {
                  const {
                    persona: { apellido, nombre, documento },
                    idResponsable,
                  } = responsableResult;
                  const responsableExists = !!vinculos.find(
                    (vinculo: Vinculo) =>
                      vinculo.responsable.idResponsable === idResponsable,
                  );
                  return (
                    <TableRow>
                      <TableCell>
                        <Radio
                          value={responsableResult.persona.idPersona}
                          checked={
                            selectedResponsable?.persona.idPersona ===
                            responsableResult.persona.idPersona
                          }
                          onChange={handleSelectResponsable}
                          size="small"
                          disabled={responsableExists}
                        />
                      </TableCell>
                      <TableCell>{`${apellido}, ${nombre}`}</TableCell>
                      <TableCell>{documento}</TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={updatedResponsables.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={(e) =>
            handleChangeRowsPerPage(parseInt(e.target.value))
          }
          labelRowsPerPage="Filas por página"
          labelDisplayedRows={({ from, to, count }) => {
            return from + '-' + to + ' de ' + count;
          }}
        />
      </Grid>
    </Grid>
  );

  const modalContentNoResults = (
    <Grid className={classes.resultsContainer} container direction="column">
      {modalContentSearchBar}
      <Grid className={classes.centered} item container direction="column">
        {!searchSubmitted ? (
          <div />
        ) : searchSubmitted && working ? (
          <Loading />
        ) : (
          <Grid item>
            <Typography className={classes.sinResponsablesDisclaimer}>
              Parece que el responsable aún no está en la base de datos
            </Typography>
            <Typography
              onClick={onAddResponsable}
              className={classes.sinResponsablesAgregar}
            >
              Registrar Responsable
            </Typography>
          </Grid>
        )}
      </Grid>
    </Grid>
  );

  const addModalActions = (
    <>
      <Button
        onClick={closeModal}
        variant="text"
        size="small"
        className={classes.buttonCancel}
      >
        Cancelar
      </Button>
      <Button
        onClick={addSelectedResponsable}
        color="primary"
        variant="contained"
        size="small"
        autoFocus
        className={classes.button}
        disabled={!selectedResponsable}
      >
        Agregar
      </Button>
    </>
  );

  return (
    <ModalBase
      open={open}
      onClose={closeModal}
      title="Agregar Responsable"
      content={
        updatedResponsables.length > 0 && searchSubmitted
          ? modalContentWithResults
          : modalContentNoResults
      }
      actions={addModalActions}
      customTheme={null}
    />
  );
};
