import { useMemo } from 'react';
import { PERIODOS } from 'src/commons';
import { primary } from 'src/theme/colors';
import { cloneDeep } from 'lodash';

const headerStyle = {
  color: primary.lightBlue,
  textAlign: 'center',
};

export const useAspectosGeneralesColumns = (
  anio: number,
  efectivizado = false,
  periodo: number,
) => {
  return useMemo(() => {
    const columns = [
      {
        id: 'nombre',
        label: 'Alumno',
        width: '10%',
        style: headerStyle,
        hideSortIcon: true,
        showFor: [
          PERIODOS.PRIMARIA.PRIMER_BIMESTRE,
          PERIODOS.PRIMARIA.SEGUNDO_BIMESTRE,
          PERIODOS.PRIMARIA.CUARTO_BIMESTRE,
          'all',
        ],
      },
      {
        id: 'vinculoPedagogico',
        label: '¿Mantuvo el vínculo pedagógico?',
        width: '10%',
        style: headerStyle,
        align: 'center',
        hideSortIcon: true,
        noSort: true,
      },
      {
        id: 'promocionAcompanada',
        label: '¿Promocionó con acompañamiento?',
        width: '10%',
        style: headerStyle,
        align: 'center',
        hideSortIcon: true,
        noSort: true,
        showFor: [PERIODOS.PRIMARIA.PRIMER_BIMESTRE],
      },
      {
        id: 'espaciosConsolidandoPregunta',
        label: `En relación a los aprendizajes ${anio}, ¿Se encuentra consolidando algunos espacios curriculares?`,
        width: '10%',
        style: headerStyle,
        align: 'center',
        hideSortIcon: true,
        noSort: true,
      },
      {
        id: 'espaciosConsolidando',
        label: '¿Cuáles Espacios?',
        width: '10%',
        style: headerStyle,
        align: 'center',
        hideSortIcon: true,
        noSort: true,
      },
      {
        id: 'acompaniamientoPromocion',
        label: `¿Inicia el período de acompañamiento para la promoción?`,
        width: '10%',
        style: headerStyle,
        align: 'center',
        hideSortIcon: true,
        noSort: true,
      },
      {
        id: 'acompaniamientoPregunta',
        label:
          '¿Necesita acompañamiento para promocionar el grado que está cursando?',
        width: '10%',
        style: headerStyle,
        align: 'center',
        hideSortIcon: true,
        noSort: true,
        showFor: [PERIODOS.PRIMARIA.CUARTO_BIMESTRE],
      },
      {
        id: 'acompaniamientoAreaPregunta',
        label: '¿Inicia el período de acompañamiento para la promoción?',
        width: '10%',
        style: headerStyle,
        align: 'center',
        hideSortIcon: true,
        noSort: true,
        showFor: [PERIODOS.PRIMARIA.TERCER_BIMESTRE],
      },
      {
        id: 'acompaniamientoArea',
        label:
          '¿En qué área/s se focaliza el acompañamiento para la promoción?',
        width: '10%',
        style: headerStyle,
        align: 'center',
        hideSortIcon: true,
        noSort: true,
        showFor: [
          PERIODOS.PRIMARIA.TERCER_BIMESTRE,
          PERIODOS.PRIMARIA.CUARTO_BIMESTRE,
        ],
      },
      {
        id: 'apoyoPregunta',
        label: '¿Posee apoyos / acompañamientos?',
        width: '10%',
        style: headerStyle,
        align: 'center',
        hideSortIcon: true,
        noSort: true,
        showFor: [
          PERIODOS.PRIMARIA.PRIMER_BIMESTRE,
          PERIODOS.PRIMARIA.SEGUNDO_BIMESTRE,
          PERIODOS.PRIMARIA.CUARTO_BIMESTRE,
          'all',
        ],
      },
      {
        id: 'apoyo',
        label:
          periodo === 4 || periodo === 12 || periodo === 13
            ? '¿Cuáles?'
            : '¿Cuáles Apoyos?',
        width: '10%',
        style: headerStyle,
        align: 'center',
        hideSortIcon: true,
        noSort: true,
        showFor: [
          PERIODOS.PRIMARIA.PRIMER_BIMESTRE,
          PERIODOS.PRIMARIA.SEGUNDO_BIMESTRE,
          PERIODOS.PRIMARIA.CUARTO_BIMESTRE,
          'all',
        ],
      },
      {
        id: 'organizaParticipa',
        label:
          '¿Se organiza y participa en actividades propuestas a través de los diversos formatos ajustándose a las pautas de trabajo?',
        width: '10%',
        style: headerStyle,
        align: 'center',
        hideSortIcon: true,
        noSort: true,
        showFor: [
          PERIODOS.PRIMARIA.PRIMER_BIMESTRE,
          PERIODOS.PRIMARIA.SEGUNDO_BIMESTRE,
        ],
      },
      {
        id: 'comprometeReconoce',
        label:
          '¿Se compromete con su aprendizaje reconociendo logros y dificultades?',
        width: '10%',
        style: headerStyle,
        align: 'center',
        hideSortIcon: true,
        noSort: true,
        showFor: [
          PERIODOS.PRIMARIA.PRIMER_BIMESTRE,
          PERIODOS.PRIMARIA.SEGUNDO_BIMESTRE,
        ],
      },
    ];
    return columns.filter(
      (column) =>
        column.showFor?.includes(periodo) || column.showFor?.includes('all'),
    );
  }, [anio, periodo]);
};
