import moment from 'moment';
import React, {
  useCallback,
  useMemo,
  useRef,
  useState,
  useEffect,
} from 'react';
import { AsistenciaCell } from './AsistenciaCell';
import {
  ContraturnoDia,
  Justificacion,
  Presente_v3,
} from '../../../../../../../app/models';
import {
  useMultiPresentismoChecks,
  usePresentismoContext,
} from '../../../context';
import { ASISTENCIA_GENERAL, TURNOS } from './TurnoCells';
import { Box, Grid, TableCell } from '@material-ui/core';
import { PropuestaJEDia } from '../../../../../../../app/models/presentismo_v2';
import { useAsistenciaCellStyles } from '../css';
import { useSelectedDayStyles } from '../hooks';
import cx from 'classnames';
import { useRolesContext } from '../../../../../../../context/RolesContext';
import { ROL } from '../../../../../../../commons';
import {
  AsistenciaCellComponentProps,
  PresenteGeneral,
  RenderAsistenciasCell,
  _Turno,
} from '../../../types';
import { ESTADOS_PRESENTISMO } from '../../../utils/EstadoPresente';
import { getContraturnosByDate } from '../functions/getContraturnosByDate';

const compareDates = (dateA: string, dateB: moment.Moment, dayId: number) =>
  moment(dateA, 'YYYY-MM-DD').isSame(
    moment(dateB, 'YYYY-MM-DD')
      .startOf('isoWeek')
      .add(dayId, 'days')
      .format('YYYY-MM-DD'),
  );
export const AsistenciaCellComponent = ({
  row,
  currentWeek,
  day,
  dayId,
  lastRow,
  isTabJornadaExtendida,
  selectedSection,
  pastDaysOfWeek,
  isDayFuture = false,
  setAmountCells,
  dataCierrePresentismo,
  selectedEspacioCurricular,
  isDisabled: isDisabledDay,
  isLockBlock,
  waitingCandados,
  isValidCicloLectivo,
}: AsistenciaCellComponentProps) => {
  const classes = useAsistenciaCellStyles();
  const selectedClasses = useSelectedDayStyles();

  const { newPresentismos, updatePresentismos, propuestasJE } =
    usePresentismoContext();
  const {
    selectedRole: { rol },
  } = useRolesContext();
  const { turno } = useMultiPresentismoChecks();
  const current = moment(currentWeek)
    .startOf('isoWeek')
    .add(dayId, 'days')
    .format('YYYY-MM-DD');

  const daySelected = moment(currentWeek)
    .startOf('isoWeek')
    .add(pastDaysOfWeek, 'days')
    .format('YYYY-MM-DD');
  const CONTRATURNOS = useMemo<ContraturnoDia[]>(() => {
    return getContraturnosByDate(row?.contraturnos || [], current);
  }, [current, row?.contraturnos]);

  const [anchorEl, setAnchorEl] = useState<HTMLElement[]>([]);
  const modalRef = useRef<any>();

  const findPresentismo = useCallback(
    (
      presentismos: PresenteGeneral[],
      turno: number | null,
      contraTurno: number | null,
    ) => {
      return presentismos.find(
        (pre) =>
          compareDates(pre?.fecha, currentWeek, dayId) &&
          (pre?.turno?.idTurno === turno || pre?.turno === null) &&
          pre?.alumno.idAlumno === row?.alumno.idAlumno &&
          pre?.isJornadaExtendida === isTabJornadaExtendida &&
          (pre?.contraturnoDia?.idContraturnoDia === contraTurno ||
            pre?.contraturnoDia === null ||
            pre?.contraturnoDia === undefined),
      );
    },
    [currentWeek, dayId, row?.alumno.idAlumno, isTabJornadaExtendida],
  );

  const turnosAsistencia = useMemo(() => {
    let _turnos: _Turno[] = [];
    if (selectedEspacioCurricular) {
      _turnos = ASISTENCIA_GENERAL;
    } else {
      _turnos = TURNOS[turno] ? TURNOS[turno] : [];
    }
    return _turnos;
  }, [turno, selectedEspacioCurricular]);

  const turnos = useMemo<RenderAsistenciasCell[]>(() => {
    const _turnos: RenderAsistenciasCell[] = [];
    for (let t of turnosAsistencia) {
      let currenState: PresenteGeneral | undefined;
      let justificacion: Justificacion | undefined | null;
      currenState = findPresentismo(row.presentismo, t.idTurno, null);

      justificacion = currenState?.justificacion;
      if (newPresentismos.length > 0) {
        let newCurrentState = findPresentismo(newPresentismos, t.idTurno, null);
        currenState = newCurrentState ? newCurrentState : currenState;
      }
      if (updatePresentismos.length > 0) {
        let newCurrentState = findPresentismo(
          updatePresentismos,
          t.idTurno,
          null,
        );
        currenState = newCurrentState ? newCurrentState : currenState;
      }

      const state = ESTADOS_PRESENTISMO.find(
        (ep) =>
          ep.idEstadoPresente === currenState?.estadoPresente.idEstadoPresente,
      );

      _turnos.push({
        state: currenState
          ? {
              ...state!,
              fecha: currenState.fecha,
              justificacion: currenState?.justificacion || null,
            }
          : undefined,
        turno: t,
        justificacion,
      });
    }
    return _turnos;
  }, [
    row,
    newPresentismos,
    updatePresentismos,
    turnosAsistencia,
    findPresentismo,
  ]);

  const ct = useMemo(() => {
    const _contraturnos: RenderAsistenciasCell[] = [];
    if (selectedEspacioCurricular) return _contraturnos;
    for (let c of CONTRATURNOS) {
      let currenState: PresenteGeneral | undefined;
      let justificacion: Justificacion | undefined | null;
      currenState = findPresentismo(row.presentismo, null, c.idContraturnoDia);
      justificacion = currenState?.justificacion;
      if (newPresentismos.length > 0) {
        let newCurrentState = findPresentismo(
          newPresentismos,
          null,
          c.idContraturnoDia,
        );
        currenState = newCurrentState ? newCurrentState : currenState;
      }
      if (updatePresentismos.length > 0) {
        let newCurrentState = findPresentismo(
          updatePresentismos,
          null,
          c.idContraturnoDia,
        );
        currenState = newCurrentState ? newCurrentState : currenState;
      }
      const state = ESTADOS_PRESENTISMO.find(
        (ep) =>
          ep.idEstadoPresente === currenState?.estadoPresente.idEstadoPresente,
      );

      _contraturnos.push({
        state: currenState
          ? {
              ...state!,
              fecha: currenState.fecha,
              justificacion: currenState?.justificacion,
            }
          : undefined,
        contraturnoDia: c || null,
        justificacion,
      });
    }
    return _contraturnos;
  }, [
    selectedEspacioCurricular,
    row,
    newPresentismos,
    updatePresentismos,
    findPresentismo,
    CONTRATURNOS,
  ]);

  // HOY ES DESPUES DE FECHA -- GRISEO FECHA ES PASADO
  const isPast = moment().isAfter(current);
  // HOY ES ANTES DE FECHA -- BLOQUEO ES FUTURO
  const isFuture = moment().isBefore(current);

  const dayIsOnPropuesta = useCallback(() => {
    const propuesta = propuestasJE.find(
      (propuesta) => propuesta.seccion.idSeccion === selectedSection,
    );

    if (!propuesta) return false;

    const dayWeek = moment(current).format('dddd');
    const parseDayWeek = dayWeek === 'miércoles' ? 'miercoles' : dayWeek;
    const dia = propuesta.dias.find(
      (propuestaDia: PropuestaJEDia) =>
        propuestaDia.dia.nombre.toLowerCase() === parseDayWeek,
    );

    return !!dia;
  }, [propuestasJE, current, selectedSection]);

  const hasJornadaExtendidaDisabled =
    isTabJornadaExtendida && rol === ROL.DIRECTIVO.toString();

  const isDisabled =
    isFuture ||
    isDisabledDay ||
    isLockBlock ||
    (isTabJornadaExtendida && !dayIsOnPropuesta()) ||
    hasJornadaExtendidaDisabled;

  const classNameDiv = useMemo(() => {
    const isLunes = day === 'Lunes';
    const isViernes = day === 'Viernes';
    const classBorder = isViernes ? classes.last : classes.border;
    const classLastRow = lastRow
      ? isLunes
        ? classes.firstRow
        : isViernes
        ? classes.lastRow
        : classes.middleRow
      : '';

    const getClass = () => {
      if (current === daySelected) return '';
      if (isPast) return classes.open;
      if (isDisabled) return classes.closed;
      return '';
    };
    return cx(classes.content, classLastRow, getClass());
  }, [day, lastRow, classes, current, isPast, isDisabled, daySelected]);

  const handleModalClosed = useCallback(() => {
    setAnchorEl([]);
  }, [setAnchorEl]);

  const handleModelOpen = useCallback<
    (i: number) => React.MouseEventHandler<HTMLDivElement>
  >(
    (index: number) => (event) => {
      const target = event.target as HTMLElement;
      const isDisabled = target.classList.contains('disabled');
      setAnchorEl((prevState) => {
        if (isDisabled) {
          return prevState;
        } else {
          const copy = [...prevState];
          copy[index] = target;
          return copy;
        }
      });
    },
    [],
  );

  const cells: RenderAsistenciasCell[] = [...turnos, ...ct];

  setAmountCells(cells.length);

  return (
    <TableCell
      align="center"
      colSpan={2}
      className={cx(
        classes.cell,
        current === daySelected ? selectedClasses.selected : '',
        current === daySelected ? 'selected' : '',
        'asistencia-cell',
      )}
    >
      <Grid
        component={Box}
        width="100%"
        height="100%"
        display="flex"
        position="relative"
        className={classNameDiv}
      >
        {cells?.map(({ turno, contraturnoDia, state }, i, all) => (
          <AsistenciaCell
            countcell={all.length}
            key={`asistenciacell-${
              turno?.idTurno || contraturnoDia?.idContraturnoDia
            }-${i}`}
            turno={turno}
            contraturno={contraturnoDia}
            alumno={row}
            dayOfWeek={day}
            idDayofWeek={dayId}
            currentWeek={currentWeek}
            estadoPresente={state}
            lastRow={lastRow}
            isTabJornadaExtendida={isTabJornadaExtendida}
            selectedSection={selectedSection}
            pastDaysOfWeek={pastDaysOfWeek}
            modalRef={modalRef}
            anchorEl={anchorEl[i]}
            handleModelOpen={handleModelOpen(i)}
            handleModalClosed={handleModalClosed}
            disabledByOrder={
              i > 0 && all[i - 1] ? all[i - 1].state === undefined : false
            }
            isDayFuture={isDayFuture}
            selectedEspacioCurricular={selectedEspacioCurricular}
            dataCierrePresentismo={dataCierrePresentismo}
            isDisabled={isDisabledDay}
            isLockBlock={isLockBlock}
            waitingCandados={waitingCandados}
            isValidCicloLectivo={isValidCicloLectivo}
          />
        ))}
      </Grid>
    </TableCell>
  );
};
