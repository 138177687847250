import { useCallback } from 'react';
import confirmDialog from 'src/commons/services/confirmDialog';

const validFileTypes = [
  'application/pdf',
  'pdf',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'application/msword',
  'image/jpeg',
  'jpg',
  'jpeg',
  'jpe',
  'image/png',
  'image/webp',
  'webp',
];

const fileSizes = {
  '2MB': { size: 2000000, description: '2MB' },
  '4MB': { size: 4000000, description: '4MB' },
  '6MB': { size: 6000000, description: '6MB' },
};

const fileSize = fileSizes['2MB'];

const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () =>
      resolve(reader?.result?.replace('data:', '')?.replace(/^.+,/, ''));
    reader.onerror = (error) => reject(error);
  });

export const useHandleFileUpload = () => {
  const handleFileUpload: any = useCallback(async (event: any, row: any) => {
    const target = event.target as HTMLInputElement;
    let informe = {};
    if (row?.nota?.data?.[target.name]) {
      informe = { ...row?.nota?.data?.[target.name] };
    }

    if (target.files) {
      const uploadedFile = target.files[0];

      if (uploadedFile?.size > fileSize.size) {
        const confirm = await confirmDialog.show({
          title: `Informes adjunto`,
          content: `El archivo adjunto no puede tener un tamaño mayor a ${fileSize.description}`,
          confirmText: 'Entendido',
        });
        if (confirm) {
          return { name: target.name, value: null };
        }
      }

      if (!validFileTypes.includes(uploadedFile.type)) {
        const confirm = await confirmDialog.show({
          title: `Informes adjunto`,
          content:
            'El archivo adjunto debe ser un documento del tipo PDF, Word o imagen',
          confirmText: 'Entendido',
        });
        if (confirm) {
          return { name: target.name, value: null };
        }
      }
      informe = {
        filename: uploadedFile.name,
        base64: await toBase64(uploadedFile),
        mimeType: uploadedFile.type,
      };
      return { name: target.name, value: { ...informe } };
    }
    if (target.value === 'adjuntoClose') {
      const confirm = await confirmDialog.show({
        title: `Informes adjunto`,
        content:
          '¿Está seguro que desea eliminar el archivo adjunto para este informe?',
        confirmText: 'Confirmar',
        cancelText: 'Cancelar',
      });
      if (confirm) {
        return { name: target.name, value: null };
      }
    }
  }, []);

  return handleFileUpload;
};
