import React, { useMemo } from 'react';
import { ESTADO_PRESENTE, NIVEL } from 'src/commons';
import { useRolesContext } from 'src/context/RolesContext';
import { getDataJE, getTableSecDataJE } from '../util/excelUtilsJE';
import {
  ContraturnosPorDia,
  getDataJC,
  getDataJCJ,
  getDataJSCC,
  getDataS,
  getTableSecDataJC,
  getTableSecDataJCJ,
  getTableSecDataJSCC,
  getTableSecDataS,
} from '../util';
import { TablaSecundaria } from '../types';
import { PresenteGeneral } from '../../TomaPresentismo/types';
import { usePresentismoContext } from '../../TomaPresentismo/context';
import {
  getDataEC,
  getTableSecDataEC,
} from '../util/excelUtilsEspacioCurricular';
import moment from 'moment';
import { getDaysByRange } from 'src/utils/getDaysByRange';
import { capitalizarPalabras } from '../util/functions';
import { useFechaBloqueadasV3LibbyFetch } from 'src/app/business';

export const useStudentReportExcelData_v3 = (
  presentismos: PresenteGeneral[],
  periodoSelect: Number,
) => {
  const { selectedRole } = useRolesContext();
  const { periodoNuevo } = usePresentismoContext();

  const periodoActual = periodoNuevo.find(
    (item) => item.idPeriodoNuevo === periodoSelect,
  );

  const name = `Reporte_${presentismos[0]?.alumno?.persona.apellido}_${presentismos[0]?.alumno?.persona.nombre}_${periodoActual?.description}`;

  const periodoFiltrado = presentismos?.filter((presentismo) => {
    return (
      presentismo?.periodoNuevo?.idPeriodoNuevo ===
      periodoActual?.idPeriodoNuevo
    );
  });

  const contraturnosPorDia: ContraturnosPorDia = {};

  periodoFiltrado.forEach((item) => {
    const fecha = item.fecha;
    if (item.contraturnoDia !== null) {
      contraturnosPorDia[fecha] = (contraturnosPorDia[fecha] || 0) + 1;
    }
  });

  const cantidadMaximaContraturnos = Math.max(
    ...Object.values(contraturnosPorDia),
    0,
  );

  const fechaInicio = periodoActual ? periodoActual.fechaInicio : '';
  const fechaFin = periodoActual ? periodoActual.fechaFin : '';
  const diasHabilitadosBimestre = getDaysByRange(fechaInicio, fechaFin, true);

  const filterFecha = useMemo(
    () => ({
      0: [
        {
          path: 'date',
          value: fechaInicio,
          method: 'higherOrEquals',
        },
      ],
      1: [
        {
          path: 'date',
          value: fechaFin,
          method: 'lowerOrEquals',
        },
      ],
      2: [
        {
          path: 'active',
          value: 'true',
        },
      ],
    }),
    [fechaInicio, fechaFin],
  );
  const { data: feriados = [], working: feriadosW } =
    useFechaBloqueadasV3LibbyFetch({
      limit: 100,
      filter: filterFecha,
      enabled: fechaFin !== '' || fechaInicio !== '',
    });

  const feriadosPorNivel = feriados.filter((dia) => {
    const hasExceptions = Object.keys(dia.exceptions || {}).length > 0;
    if (hasExceptions) {
      return !dia.exceptions.nivels.includes(Number(selectedRole.nivel));
    } else return true;
  });

  let apellido: string = capitalizarPalabras(
    presentismos[0]?.alumno?.persona.apellido,
  );
  let nombre: string = capitalizarPalabras(
    presentismos[0]?.alumno?.persona.nombre,
  );
  let condicion: string = capitalizarPalabras(
    presentismos[0]?.alumno?.condicion?.descripcionCondicion,
  );

  const diasTotales = diasHabilitadosBimestre.length - feriadosPorNivel.length;

  const metadata = [
    ['Establecimiento', selectedRole.localizacion],
    ['Cue Anexo', selectedRole.localizacionId],
    ['Apellido y Nombre', `${apellido}, ${nombre}`],
    ['DNI', presentismos[0]?.alumno?.persona.documento],
    ['Condición', condicion],
    ['Año', presentismos[0]?.seccion?.anio.descripcionAnio],
    ['Turno', presentismos[0]?.seccion?.turno?.descripcionTurno],
    ['Sección', presentismos[0]?.seccion?.nombreSeccion],
    ['Período', periodoActual?.description],
    ['Días de clases', diasTotales.toString()],
  ];

  //coplumn JE primaria 5to 6to 7mo. Je en secundaria :1er año
  // Resumen Presentismo
  const column = [
    'Resumen Presentismo',
    ' ',
    'Jornada Extendida',
    'Resumen Justificacion',
    ' ',
    'Jornada Extendida',
  ];

  const columnJE = [
    'Resumen Presentismo',
    ' ',
    'Jornada Extendida',
    'Resumen Justificacion',
  ];
  //columnas para Jornada Completa en ambos niveles sin JE
  const columnJC = [
    'Resumen Presentismo',
    'TM',
    'TT',
    'Resumen Justificaciones',
    'TM',
    'TT',
  ];
  //columa para Jornada Simple con Contraturnos
  const columnJSCC = [
    'Resumen Presentismo',
    presentismos[0]?.seccion?.turno?.descripcionTurno === 'Mañana'
      ? 'TM'
      : 'TT',
    'CT1',
    ...(cantidadMaximaContraturnos > 1 ? ['CT2'] : []),
    'Resumen Justificaciones',
    presentismos[0]?.seccion?.turno?.descripcionTurno === 'Mañana'
      ? 'TM'
      : 'TT',
    'CT1',
    ...(cantidadMaximaContraturnos > 1 ? ['CT2'] : []),
  ];
  //columa para Jornada Simple sin Contraturnos/JE
  const columnJCJ = [
    'Resumen Presentismo',
    presentismos[0]?.seccion?.turno?.descripcionTurno === 'Mañana'
      ? 'TM'
      : 'TT',
    'CT1',
    'JE',
    'Resumen Justificaciones',
    presentismos[0]?.seccion?.turno?.descripcionTurno === 'Mañana'
      ? 'TM'
      : 'TT',
    'CT1',
    'JE',
  ];
  //columna Simple

  const columnS = ['Resumen Presentismo', ' ', 'Resumen Justificaciones', ' '];

  // columns EC
  const columnsEC = ['Resumen Presentismo', ' '];

  let columns: string[] = [];
  let data: any[] = [];
  let tablesec: TablaSecundaria = {
    columns: [],
    data: [],
  };

  //filtros!

  const isSimple = periodoFiltrado.some(
    (item) => item.seccion.jornada === 'Simple',
  );

  const isJCompleta = periodoFiltrado.some(
    (item) => item.seccion.jornada === 'Completa',
  );

  const isContraturno = periodoFiltrado.some(
    (item) => item.contraturnoDia !== null,
  );

  const isJornadaExtendida = periodoFiltrado.some(
    (items) => items.isJornadaExtendida === true,

    //&& items.seccion.jornada === 'Simple',
  ); //?jornada.Extendida? o aplica existe simple con JE

  const isJornadaExtendidaSecundariaPrimerAnio = periodoFiltrado.some(
    (item) =>
      item.seccion?.anio?.idAnio === 21 && item.isJornadaExtendida === true,
  );

  const isJornadaCompletaSINJE = periodoFiltrado.some(
    (item) =>
      item.isJornadaExtendida === false &&
      item.seccion.jornada === 'Completa' &&
      item.contraturnoDia === null,
  );

  const isContraturnoJornadaExtendida = periodoFiltrado.some(
    (item) =>
      item.seccion.jornada === 'Extendida' && item.contraturnoDia !== null,
  );

  const isNotContraturnoJornadaExtendida = periodoFiltrado.every(
    (item) =>
      item.seccion.jornada === 'Extendida' && item.contraturnoDia === null,
  );

  const isEcCheck = periodoFiltrado.some((p) => Boolean(p.espacioCurricular));

  if (isSimple && !isContraturno && !isJornadaExtendida && !isEcCheck) {
    columns = columnS;
    data = getDataS(periodoFiltrado);
    tablesec = getTableSecDataS(periodoFiltrado);
  } else if (
    isJCompleta &&
    !isContraturno &&
    !isJornadaExtendida &&
    !isEcCheck
  ) {
    columns = columnJC;
    data = getDataJC(periodoFiltrado);
    tablesec = getTableSecDataJC(periodoFiltrado);
  } else if (isContraturno && isSimple && !isEcCheck) {
    columns = columnJSCC;
    data = getDataJSCC(periodoFiltrado);
    tablesec = getTableSecDataJSCC(periodoFiltrado);
  } else if (
    selectedRole.nivel === NIVEL.PRIMARIO.toString() &&
    typeof presentismos[0]?.seccion?.anio.idAnio === 'number' &&
    [5, 6, 7, 5, 7].includes(presentismos[0]?.seccion?.anio.idAnio) &&
    isJornadaExtendida &&
    !isEcCheck
  ) {
    const periodosJEPri = periodoFiltrado.filter((periodo) => {
      return (
        periodo.estadoPresente.idEstadoPresente !==
          ESTADO_PRESENTE.INGRESO_TARDIO &&
        periodo.estadoPresente.idEstadoPresente !==
          ESTADO_PRESENTE.RETIRO_ANTICIPADO &&
        periodo.estadoPresente.idEstadoPresente !==
          ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE
      );
    });
    columns = columnJE;
    data = getDataJE(periodosJEPri);
    tablesec = getTableSecDataJE(periodosJEPri);
  } else if (
    selectedRole.nivel === NIVEL.SECUNDARIO.toString() &&
    isJornadaExtendidaSecundariaPrimerAnio &&
    !isEcCheck
  ) {
    const periodosJESec = presentismos.filter((periodo) => {
      return (
        periodo.estadoPresente.idEstadoPresente !==
          ESTADO_PRESENTE.INGRESO_TARDIO &&
        periodo.estadoPresente.idEstadoPresente !==
          ESTADO_PRESENTE.RETIRO_ANTICIPADO &&
        periodo.estadoPresente.idEstadoPresente !==
          ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE
      );
    });
    columns = columnJE;
    data = getDataJE(periodosJESec);
    tablesec = getTableSecDataJE(periodosJESec);
  } else if (
    selectedRole.nivel === NIVEL.SECUNDARIO.toString() &&
    isContraturnoJornadaExtendida &&
    !isEcCheck
  ) {
    columns = columnJSCC;
    data = getDataJSCC(periodoFiltrado);
    tablesec = getTableSecDataJSCC(periodoFiltrado);
  } else if (isEcCheck) {
    // IS EC
    const periodosEC = periodoFiltrado.filter((periodo) => {
      return (
        periodo.estadoPresente.idEstadoPresente !==
          ESTADO_PRESENTE.INGRESO_TARDIO &&
        periodo.estadoPresente.idEstadoPresente !==
          ESTADO_PRESENTE.RETIRO_ANTICIPADO
      );
    });
    columns = columnsEC;
    data = getDataEC(periodosEC);
    tablesec = getTableSecDataEC(periodosEC);

    metadata.push([
      'Espacio Curricular',
      presentismos[0].espacioCurricular?.descripcion || '',
    ]);
  } else if (isNotContraturnoJornadaExtendida) {
    columns = columnS;
    data = getDataS(periodoFiltrado);
    tablesec = getTableSecDataS(periodoFiltrado);
  }

  const style = {
    font: {
      name: 'Arial',
      color: '#000000', // Color negro para el texto
      size: 12,
      bold: true,
    },
    fill: {
      type: 'pattern',
      patternType: 'solid',
      fgColor: '#b8d7de',
    },
    border: {
      left: {
        style: 'thin',
        color: 'black',
      },
      right: {
        style: 'thin',
        color: 'black',
      },
      top: {
        style: 'thin',
        color: 'black',
      },
      bottom: {
        style: 'thin',
        color: 'black',
      },
    },
  };

  return {
    body: {
      metadata: metadata,
      columns: columns,
      data: data,
      tabla2: tablesec,
      styles: style,
    },
    name,
  };
};
