import { Template } from 'src/lib/templates';

export const nuevaPublicacionTemplate: Template = {
  name: 'Nueva Publicacion',
  template: [
    {
      name: 'descripcion',
      type: {
        name: 'string',
      },
    },
    {
      name: 'idRol',
      type: {
        name: 'number | undefined',
      },
      optional: true,
    },
    {
      name: 'idEspacioCurricular',
      type: {
        name: 'number | undefined',
      },
      optional: true,
    },
    {
      name: 'comentable',
      type: {
        name: 'boolean',
      },
    },
    {
      name: 'file',
      type: {
        name: 'object',
        kind: [
          {
            name: 'files',
            type: {
              name: 'any',
            },
            optional: true,
          },
          {
            name: 'value',
            type: {
              name: 'string',
            },
            optional: true,
          },
        ],
      },
      optional: true,
    },
  ],
};
