import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { FormInput } from 'src/lib/templates/components';
import { ButtonTypesProps } from 'src/commons/components/Footer';
import { UserAvatar, MainButton } from 'src/commons/components';
import { useRolesContext } from 'src/context/RolesContext';
import { useFormContext } from 'src/lib/templates/context';
import { Publicacion } from 'src/app/models';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: 0,
  },
  avatar: {
    display: 'flex',
    alignItems: 'center',
  },
  textarea: {
    padding: 0,
  },
}));

export interface ComentarioItemForProps {
  raw: Publicacion;
  submitted: boolean;
  setSubmitted: any;
}

export const ComentarioItemForm = ({
  raw,
  submitted,
  setSubmitted,
}: ComentarioItemForProps) => {
  const { form, handleSubmit, submitting, errors, values } = useFormContext();
  const classes = useStyles();
  const { userInfo } = useRolesContext();

  if (submitted && !submitting) {
    form.reset();
    setSubmitted(false);
  }

  const submitProps: ButtonTypesProps = {
    title: 'Comentar',
    handleOnClick: handleSubmit,
    disabled: Object.keys(errors).length !== 0 || submitting,
  };

  return (
    <Grid
      container
      item
      direction="row"
      spacing={2}
      className={classes.formContainer}
    >
      <Grid item className={classes.avatar}>
        <UserAvatar
          name={raw ? raw.cuentas.nombreUsuario : userInfo.name}
          avatarWidth={28}
          avatarHeight={28}
        />
      </Grid>
      <Grid item xs>
        <FormInput
          disableErrorMessage
          inputProps={{ multiline: true, className: classes.textarea }}
          name="descripcion"
          form={form}
          placeholder="Escribe un comentario"
        />
      </Grid>
      <Grid container item justify="flex-end">
        <MainButton {...submitProps} />
      </Grid>
    </Grid>
  );
};
