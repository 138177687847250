import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { CalificacionesSecundario } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'calificaciones_secundario';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idCalificacionesSecundario',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idCalificacionesSecundario',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.CalificacionesSecundario
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.CalificacionesSecundario,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.CalificacionesSecundario.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<CalificacionesSecundario>(DAO_NAME, optionsToUse);

// hooks
export const useCalificacionesSecundarioFetchById = fetchById;
export const useCalificacionesSecundarioLibbyCall = libbyCall;
export const useCalificacionesSecundarioDAO = libbyDAO;
export const useCalificacionesSecundarioLibbyFetch = libbyFetch;

// components/hoc
export const CalificacionesSecundarioAutocomplete = entityAutocomplete;
export const CalificacionesSecundarioCall = renderPropCall;
export const CalificacionesSecundarioById = renderPropFetchById;
export const CalificacionesSecundarioSelect = simpleSelect;
export const CalificacionesSecundarioAccordionSelector = accordionSelector;
export const CalificacionesSecundarioSelectedChips = selectedChips;

// context
// ByIdContext
export const useCalificacionesSecundarioByIdContext = useByIdContext;
export const CalificacionesSecundarioByIdProvider = ByIdProvider;
export const CalificacionesSecundarioByIdContext = ByIdContext;
// GeneralContext
export const useCalificacionesSecundarioContext = useGeneralContext;
export const CalificacionesSecundarioProvider = GeneralProvider;
export const CalificacionesSecundarioContext = GeneralContext;
