export const generatedArrSearch = (pathSearch: any, value: any) => {
  const toArr: any = [];
  pathSearch.forEach((params: any) => {
    toArr.push({
      path: params,
      value,
      method: 'includes',
    });
  });
  return toArr;
};
