import _ from 'lodash';
import moment from 'moment';
import { EstadoPresente, Metadata } from 'src/app/models/presentismo_v2';
import { AlumnoPresentismo } from '../../context/Context';

export const actualizarPresentismo = ({
  alumno,
  fecha,
  turno = undefined,
  estadoPresente,
}: {
  alumno: AlumnoPresentismo;
  fecha: string;
  turno?: number;
  estadoPresente: EstadoPresente;
}) => {
  return alumno.presentismo.map((presentismo) => {
    return moment(presentismo.fecha).isSame(moment(fecha)) &&
      (!presentismo.turno || Number(presentismo.turno) === turno)
      ? {
          estadoPresente: {
            idEstadoPresente: estadoPresente.idEstadoPresente,
            descripcion: estadoPresente.descripcion,
          },
          fecha,
          turno,
          idPresente: presentismo?.idPresente,
        }
      : presentismo;
  });
};

export const actualizarMetadata = ({
  day,
  alumno,
  metadata,
}: {
  day: string;
  alumno: AlumnoPresentismo;
  metadata: Metadata;
}) => {
  const otherMetadatas = alumno.metadatas.filter(
    (meta) =>
      !(
        (!!meta.idMetadata && meta.idMetadata === metadata.idMetadata) ||
        (meta.metadataType.idMetadataType ===
          metadata.metadataType.idMetadataType &&
          meta.dates.includes(
            metadata.dates
              ? metadata.dates[0]
              : moment(day).format('YYYY-MM-DD'),
          ))
      ),
  );
  alumno.metadatas = [
    ...otherMetadatas,
    {
      ...metadata,
      dates: metadata?.dates ?? [moment(day).format('YYYY-MM-DD')],
      data: metadata.data,
    },
  ];
};
