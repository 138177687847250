import React from 'react';
import { useParams } from 'react-router';
import {
  useBackButton,
  useRouteScreenTitle,
} from '../../../../../../../commons/hooks';
import { PlanificacionDisciplinarComunForm } from './PlanificacionDisciplinarComunForm';
import { EspaciosSimpDiscipPlanById } from '../../../../../../../app/business/EspaciosSimpDiscipPlan';
import { PlanificacionDisciplinarNew } from './components';
import { Loading } from '../../../../../../../commons/components/Loading';
import { useHistory } from 'react-router-dom';

// TODO: quitar esto y obtener el ultimo template activo
const planificacionTemplateId = 1;
export const PlanificacionDisciplinarComun = () => {
  const history = useHistory();
  useBackButton(history.location.pathname.match(/.+\d/i)[0]);
  useRouteScreenTitle('Planificación Disciplinar');
  // @ts-ignore
  const { idPlanificacion } = useParams();

  return idPlanificacion === 'new' ? (
    <PlanificacionDisciplinarNew templateId={planificacionTemplateId}>
      {({ template, initialValues, working }) =>
        working || !template || !initialValues ? (
          <Loading />
        ) : (
          <PlanificacionDisciplinarComunForm
            initialValues={initialValues}
            template={template}
          />
        )
      }
    </PlanificacionDisciplinarNew>
  ) : (
    <EspaciosSimpDiscipPlanById id={idPlanificacion} aspect="limit_province">
      {({ data, working }) => {
        delete data?.simpDiscipPlan?.cicloLectivo;

        return working || !data ? (
          <Loading />
        ) : (
          <PlanificacionDisciplinarComunForm
            initialValues={data.simpDiscipPlan}
            template={data.simpDiscipPlan.discipSimTempPlan}
          />
        );
      }}
    </EspaciosSimpDiscipPlanById>
  );
};
