import { Grid, IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import _ from 'lodash';
import React, { useState, useEffect, useMemo } from 'react';
import { MainInput, SimpleSelect, Loading } from 'src/commons';
import { JornadaComponent } from './JornadaComponent';
import { useInputValidator } from '../hooks/useInputValidator';
import { grey } from 'src/theme/colors';
import { validateCupoDisponible } from '../utils';
import { useReferentesLibbyFetch } from 'src/app/business/acap';
import CachedIcon from '@material-ui/icons/Cached';
import { DatePicker } from 'src/app/components/inputs/Input/DatePicker';
import moment from 'moment';

type QueryFilter = { path: string; value: string | number; method?: string };
type ReferenteFilter = {
  referente: QueryFilter[];
};

export const GrupoComponent = ({
  index,
  gruposValues,
  turnosValues,
  ofertaStep,
  localizaciones,
  organizacion,
  turnos,
  setGruposValues,
  borrarSede,
  minDateOferta,
  maxDateOferta,
  isDuplicating = false,
}: any) => {
  const [inputValidator, error] = useInputValidator();
  const [loading, setLoading] = useState(false);
  const [loadingJornada, setLoadingJornada] = useState(false);
  const [emailFieldDirty, setEmailFieldDirty] = useState<boolean>(false);
  const [jornadasCount, setJornadasCount] = useState<any>([1]);
  const [jornadasValues, setJornadasValues] = useState<any>([{}]);
  const [referenteFilter, setReferenteFilter] = useState<
    undefined | ReferenteFilter
  >(undefined);

  useEffect(() => {
    if (
      (gruposValues[index]?.referente?.email === undefined ||
        gruposValues[index]?.referente?.edited) &&
      organizacion
    ) {
      const referenteObject = {
        ...(organizacion?.referente?.idReferente
          ? { idReferente: organizacion?.referente?.idReferente }
          : {}),
        email: organizacion?.referente?.email,
        telefono: organizacion?.referente?.telefono,
        nombre: organizacion?.referente?.nombre,
        apellido: organizacion?.referente?.apellido,
        codigo: organizacion?.referente?.codigo ?? 'Miescuela123',
        edited: true,
      };
      gruposValues[index].referente = referenteObject;
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizacion]);

  const { data: referentes = [], working: workingReferente } =
    useReferentesLibbyFetch({
      filter: referenteFilter,
    });

  useEffect(() => {
    if (referentes.length === 1) {
      setReferenteFilter(undefined);
      setGruposValues((prevGruposValues) => {
        prevGruposValues[index].referente = { ...referentes[0] };
        return prevGruposValues;
      });
    }
  }, [index, referenteFilter, referentes, setGruposValues]);

  useEffect(() => {
    if (referentes.length === 0 && !!referenteFilter) {
      const groupWithReferenteNotCreated = gruposValues.find(
        (grupo) =>
          grupo?.referente?.email === referenteFilter.referente[0].value,
      );
      if (groupWithReferenteNotCreated) {
        setReferenteFilter(undefined);
        setGruposValues((prevGruposValues) => {
          prevGruposValues[index].referente = {
            ...groupWithReferenteNotCreated.referente,
            idReferente: null,
          };
          return prevGruposValues;
        });
      }
    }
  }, [
    gruposValues,
    index,
    referenteFilter,
    referentes.length,
    setGruposValues,
  ]);

  useEffect(() => {
    if (
      ofertaStep?.rowCreating !== null &&
      (gruposValues[index]?.idOfertaGrupo || ofertaStep.enableDuplicate)
    ) {
      let countJornadas: any = [];
      gruposValues[index]?.jornadas?.forEach((jornada: any, index: any) => {
        countJornadas.push(index - 1);
      });
      setJornadasCount(countJornadas);
      setJornadasValues(gruposValues[index]?.jornadas || []);
    }
  }, [
    ofertaStep?.rowCreating,
    ofertaStep.enableDuplicate,
    gruposValues,
    index,
  ]);

  const agregarJornada = () => {
    let jornadaObject: any = {};
    if (jornadasValues?.length > 0) {
      for (const property in jornadasValues[0]) {
        if (property !== 'cupo' && property !== 'idGrupoJornada')
          jornadaObject[property] = jornadasValues[0][property];
      }
    }

    setJornadasCount([...jornadasCount, jornadasCount.length + 1]);
    setJornadasValues([...jornadasValues, jornadaObject]);
    const clonnedGruposValues = _.cloneDeep(gruposValues);
    clonnedGruposValues[index]['jornadas'] = [...jornadasValues, jornadaObject];
    setGruposValues(clonnedGruposValues);
  };
  const borrarJornada = async (index: any, sede: any, indexGrupo: any) => {
    setJornadasCount((prev: any) =>
      prev.filter((elem: any, indexFiltered: any) => index !== indexFiltered),
    );
    setJornadasValues((prev: any) => {
      const jornadaFiltered = prev.filter(
        (elem: any, indexFiltered: any) => index !== indexFiltered,
      );
      return jornadaFiltered;
    });
    setLoadingJornada(false);
    setGruposValues((prev: any) => {
      const jornadaFiltered = prev[indexGrupo]?.jornadas?.filter(
        (elem: any, indexFiltered: any) => index !== indexFiltered,
      );
      prev[indexGrupo]['jornadas'] = jornadaFiltered;
      return prev;
    });
  };

  const handleChangeGrupo = (e: any, resetPasswordReferente?: boolean) => {
    const clonnedGruposValues = _.cloneDeep(gruposValues);

    if (clonnedGruposValues.length - 1 >= index) {
      clonnedGruposValues[index].referente = {
        ...(clonnedGruposValues[index].referente ?? {
          idReferente: null,
          nombre: '',
          apellido: '',
          email: '',
          telefono: '',
          codigo: '',
          shouldResetPassword: false,
        }),
      };
      if (e.target.name.includes('referente')) {
        if (e.target.name === 'referenteNombre') {
          clonnedGruposValues[index].referente.nombre = e.target.value;
        } else if (e.target.name === 'referenteTelefono') {
          clonnedGruposValues[index].referente.telefono = e.target.value;
        } else if (e.target.name === 'referenteEmail') {
          clonnedGruposValues[index].referente.email = e.target.value;
        } else if (e.target.name === 'referenteApellido') {
          clonnedGruposValues[index].referente.apellido = e.target.value;
        } else if (e.target.name === 'referenteCodigo') {
          clonnedGruposValues[index].referente.codigo = e.target.value;
          if (resetPasswordReferente)
            clonnedGruposValues[index].referente.shouldResetPassword = true;
        } else if (e.target.name === 'referentePreviousEmail') {
          clonnedGruposValues[index].referente.previousEmail = e.target.value;
        }
        const gruposValuesReferenteEdited = clonnedGruposValues.map((grupo) => {
          if (
            grupo?.referente?.email ===
            clonnedGruposValues[index].referente.email
          ) {
            return {
              ...grupo,
              referente: {
                ...grupo.referente,
                idReferente: grupo.referente.idReferente ?? null,
                nombre:
                  e.target.name === 'referenteNombre'
                    ? e.target.value
                    : grupo.referente.nombre,
                apellido:
                  e.target.name === 'referenteApellido'
                    ? e.target.value
                    : grupo.referente.apellido,
                telefono:
                  e.target.name === 'referenteTelefono'
                    ? e.target.value
                    : grupo.referente.telefono,
              },
            };
          } else {
            return { ...grupo };
          }
        });
        setGruposValues(gruposValuesReferenteEdited);
      } else {
        clonnedGruposValues[index][e.target.name] = e.target.value;
        setGruposValues(clonnedGruposValues);
      }
    }
  };

  const resetPassword = () => {
    const mimicEvent = {
      target: {
        value: 'Miescuela123',
        name: 'referenteCodigo',
      },
    };
    const resetPasswordReferente = true;

    handleChangeGrupo(mimicEvent, resetPasswordReferente);
  };

  const disableCupoCerrado = !(
    gruposValues[index]?.tipoCupo === 'Cerrado' ||
    gruposValues[index]?.tipoCupo === 'Mixto'
  );
  const turnoGrupoOferta = turnosValues.filter(
    (turno: any) => gruposValues[index]?.turno === turno.turno,
  );
  const cupoTotalGrupo =
    turnoGrupoOferta[0]?.cupo / turnoGrupoOferta[0]?.cantGrupos;
  const cupoCerradoCombinado =
    Number(gruposValues[index]?.cupoEstatal || 0) +
    Number(gruposValues[index]?.cupoPrivado || 0);
  const cupoAbierto =
    gruposValues[index]?.tipoCupo === 'Cerrado'
      ? 0
      : gruposValues[index]?.tipoCupo === 'Mixto'
      ? cupoTotalGrupo - cupoCerradoCombinado
      : gruposValues[index]?.tipoCupo === 'Abierto'
      ? cupoTotalGrupo
      : 0;

  const cupoDisponible =
    gruposValues[index]?.tipoCupo === 'Cerrado'
      ? cupoTotalGrupo - cupoCerradoCombinado
      : gruposValues[index]?.tipoCupo === 'Mixto'
      ? cupoTotalGrupo -
        cupoCerradoCombinado -
        (cupoAbierto > 0 ? cupoAbierto : 1)
      : 0;
  const jornadasContent = [
    { cupo: 0, descripcion: 'Lunes' },
    { cupo: 1, descripcion: 'Martes' },
    { cupo: 2, descripcion: 'Miércoles' },
    { cupo: 3, descripcion: 'Jueves' },
    { cupo: 4, descripcion: 'Viernes' },
    { cupo: 5, descripcion: 'Sábado' },
    { cupo: 6, descripcion: 'Domingo' },
  ];

  const TELEFONO_LIMIT = 20;
  const DEFAULT_LIMIT = 70;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const cupoObligatorio = useMemo(() => {
    const cupoCheck = [gruposValues[index]].filter((grupo: any) => {
      if (grupo?.tipoCupo === 'Mixto' || grupo?.tipoCupo === 'Cerrado') {
        if (grupo?.cupoEstatal > 0) return grupo?.cupoEstatal;
        else if (grupo?.cupoPrivado > 0) return grupo?.cupoPrivado;
      } else return true;
    });
    return cupoCheck.length;
  }, [gruposValues, index]);

  const estPrivado = [gruposValues[index]].filter((grupo: any) => {
    if (grupo?.tipoCupo === 'Mixto' || grupo?.tipoCupo === 'Cerrado') {
      if (grupo?.cupoPrivado > 0) return grupo?.establecimientoPrivado;
      else if (
        grupo?.cupoEstatal > 0 &&
        (grupo?.cupoPrivado === 0 || grupo?.cupoPrivado === '')
      )
        return true;
    } else return true;
  });
  const estPublico = [gruposValues[index]].filter((grupo: any) => {
    if (grupo?.tipoCupo === 'Mixto' || grupo?.tipoCupo === 'Cerrado') {
      if (grupo?.cupoEstatal > 0) return grupo?.localizacion;
      else if (
        grupo?.cupoPrivado > 0 &&
        (grupo?.cupoEstatal === 0 || grupo?.cupoEstatal === '')
      )
        return true;
    } else return true;
  });
  const tipoCupo = gruposValues[index]?.tipoCupo;
  const comparision = gruposValues[index]?.referente?.apellido || '';
  const codigoComparison = gruposValues[index]?.referente?.codigo || '';

  return (
    <>
      <Grid style={{ border: '1px solid black', marginTop: '15px' }}>
        {loading ? (
          <Loading />
        ) : (
          <Grid container style={{ marginBottom: '20px' }}>
            <Grid
              container
              item
              md={12}
              alignItems="center"
              justifyContent="space-between"
            >
              <p
                style={{
                  marginLeft: '15px',
                  marginTop: '15px',
                  marginBottom: '15px',
                  color: grey.heading,
                  fontSize: 22,
                  fontFamily: 'Open Sans',
                  fontWeight: '700',
                }}
              >
                Grupo {index + 1}{' '}
                {gruposValues.length > 1 && (
                  <IconButton
                    onClick={async () => {
                      setLoading(true);
                      await borrarSede(index, gruposValues[index]);
                      setLoading(false);
                    }}
                  >
                    <DeleteIcon color="primary" />
                  </IconButton>
                )}
              </p>
              <Typography
                style={{
                  fontFamily: 'Open Sans',
                  marginRight: '20px',
                  fontWeight: '700',
                }}
              >
                {cupoTotalGrupo
                  ? `Cupo disponible: ${cupoDisponible} / Total: ${cupoTotalGrupo}`
                  : 'Elija un turno para el grupo'}
              </Typography>
            </Grid>
            <Grid
              container
              style={{ marginBottom: '20px', paddingLeft: '15px' }}
            >
              <Grid container item md={4} alignItems="center">
                <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                  Turno:
                  <span style={{ color: 'red' }}>
                    {gruposValues[index]?.turno ? '' : '*'}
                  </span>
                </p>
                <SimpleSelect
                  name="turno"
                  value={
                    Boolean(gruposValues) ? gruposValues[index]?.turno : null
                  }
                  handleChange={(e: any) => {
                    const gruposCheck = gruposValues.filter(
                      (grupo: any) => grupo.turno === e.target.value,
                    );
                    const cantidadGrupoTurno = turnosValues.filter(
                      (turnoValue: any) => turnoValue.turno === e.target.value,
                    )[0]?.cantGrupos;
                    if (gruposCheck.length < cantidadGrupoTurno) {
                      handleChangeGrupo(e);
                    }
                  }}
                  placeholder="Seleccioná turnos"
                  content={
                    turnos?.length > 0
                      ? turnos.filter(
                          (turno: any) =>
                            turnosValues
                              .map((turnoIn: any) => turnoIn?.turno)
                              .indexOf(turno.idTurno) !== -1,
                        )
                      : []
                  }
                  labelKey="descripcionTurno"
                  valueKey="idTurno"
                  style={{
                    width: '30em',
                    marginBottom: '20px',
                  }}
                />
              </Grid>

              <Grid container item md={4} alignItems="center">
                <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                  Fecha de inicio:
                  <span style={{ color: 'red' }}>
                    {gruposValues[index]?.fechaInicio ? '' : '*'}
                  </span>
                </p>

                <DatePicker
                  value={gruposValues[index]?.fechaInicio || null}
                  helperText={
                    !!minDateOferta && !!maxDateOferta
                      ? ''
                      : 'Debe seleccionar fechas de oferta primero'
                  }
                  disabled={minDateOferta && maxDateOferta ? false : true}
                  onChange={(_date: any) => {
                    let gruposValuesCopy = gruposValues;
                    gruposValuesCopy[index].fechaInicio =
                      moment(_date).format('YYYY-MM-DD');
                    setGruposValues([...gruposValuesCopy]);
                  }}
                  minDate={minDateOferta}
                  maxDate={maxDateOferta}
                />
              </Grid>

              <Grid container item md={4} alignItems="center">
                <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                  Fecha de fin:
                  <span style={{ color: 'red' }}>
                    {gruposValues[index]?.fechaFin ? '' : '*'}
                  </span>
                </p>
                <DatePicker
                  value={gruposValues[index]?.fechaFin || null}
                  disabled={gruposValues[index]?.fechaInicio ? false : true}
                  onChange={(_date: any) => {
                    let gruposValuesCopy = gruposValues;
                    gruposValuesCopy[index].fechaFin =
                      moment(_date).format('YYYY-MM-DD');
                    setGruposValues([...gruposValuesCopy]);
                  }}
                  minDate={gruposValues[index]?.fechaInicio}
                  maxDate={maxDateOferta}
                />
              </Grid>

              <Grid
                container
                item
                md={6}
                alignItems="center"
                style={{
                  marginTop: '20px',
                }}
              >
                <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                  Tipo de cupo:
                  <span style={{ color: 'red' }}>{tipoCupo ? '' : '*'}</span>
                </p>
                <SimpleSelect
                  name="tipoCupo"
                  value={
                    Boolean(gruposValues) ? gruposValues[index]?.tipoCupo : null
                  }
                  handleChange={(e: any) => {
                    const value = e?.target?.value;
                    let gruposValuesCopy = gruposValues;
                    if (value === 'Abierto') {
                      gruposValuesCopy[index].establecimientoPrivado = '';
                      gruposValuesCopy[index].localizacion = null;
                      gruposValuesCopy[index].cupoEstatal = 0;
                      gruposValuesCopy[index].cupoPrivado = 0;

                      setGruposValues(gruposValuesCopy);
                    } else {
                      gruposValuesCopy[index].cupoEstatal = 0;
                      gruposValuesCopy[index].cupoPrivado = 0;
                    }
                    handleChangeGrupo(e);
                  }}
                  placeholder="Seleccioná el tipo de cupo"
                  content={[
                    { tipoCupo: 'Mixto' },
                    { tipoCupo: 'Abierto' },
                    { tipoCupo: 'Cerrado' },
                  ]}
                  labelKey="tipoCupo"
                  valueKey="tipoCupo"
                  style={{
                    width: '30em',
                    marginBottom: '20px',
                  }}
                />
              </Grid>
              <Grid
                container
                item
                md={6}
                alignItems="center"
                style={{
                  marginTop: '20px',
                }}
              >
                <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                  Cupo abierto:
                </p>
                <MainInput
                  value={cupoAbierto || ''}
                  disabled={true}
                  handleChange={() => {}}
                  placeholder="0"
                  fullWidth
                />
              </Grid>
              <Grid
                container
                item
                md={6}
                alignItems="center"
                style={{
                  marginTop: '20px',
                }}
              >
                <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                  Cupo cerrado gestión estatal:
                  <span style={{ color: 'red' }}>
                    {cupoObligatorio ? '' : '*'}
                  </span>
                </p>
                <MainInput
                  value={gruposValues[index]?.cupoEstatal || ''}
                  name="cupoEstatal"
                  disabled={disableCupoCerrado}
                  handleChange={(e) => {
                    if (
                      Number(e.target.value) >= 0 &&
                      validateCupoDisponible(
                        Number(e.target.value || 0),
                        Number(gruposValues[index]?.cupoPrivado || 0),
                        cupoTotalGrupo,
                        gruposValues[index]?.tipoCupo,
                      )
                    ) {
                      handleChangeGrupo(e);
                    }
                  }}
                  placeholder="0"
                  fullWidth
                />
              </Grid>
              <Grid
                container
                item
                md={6}
                alignItems="center"
                style={{
                  marginTop: '20px',
                }}
              >
                <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                  Establecimiento de gestión pública:
                  <span style={{ color: 'red' }}>
                    {estPublico.length ? '' : '*'}
                  </span>
                </p>
                <SimpleSelect
                  name="localizacion"
                  disabled={disableCupoCerrado}
                  value={
                    Boolean(gruposValues)
                      ? gruposValues[index]?.localizacion
                      : null
                  }
                  handleChange={(e: any) => {
                    handleChangeGrupo(e);
                  }}
                  placeholder="Seleccioná establecimiento"
                  content={localizaciones?.length > 0 ? localizaciones : []}
                  labelKey="localizacion.descripcion"
                  valueKey="localizacion.idLocalizacion"
                  style={{
                    width: '30em',
                    marginBottom: '20px',
                  }}
                />
              </Grid>
              <Grid
                container
                item
                md={6}
                alignItems="center"
                style={{
                  marginTop: '20px',
                }}
              >
                <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                  Cupo cerrado gestión privada:
                  <span style={{ color: 'red' }}>
                    {cupoObligatorio ? '' : '*'}
                  </span>
                </p>
                <MainInput
                  value={gruposValues[index]?.cupoPrivado || ''}
                  name="cupoPrivado"
                  disabled={disableCupoCerrado}
                  handleChange={(e) => {
                    if (
                      Number(e.target.value) >= 0 &&
                      validateCupoDisponible(
                        Number(gruposValues[index]?.cupoEstatal || 0),
                        Number(e.target.value || 0),
                        cupoTotalGrupo,
                        gruposValues[index]?.tipoCupo,
                      )
                    ) {
                      handleChangeGrupo(e);
                    }
                  }}
                  placeholder="0"
                  fullWidth
                />
              </Grid>
              <Grid
                container
                item
                md={6}
                alignItems="center"
                style={{
                  marginTop: '20px',
                }}
              >
                <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                  Establecimiento de gestión privada:
                  <span style={{ color: 'red' }}>
                    {estPrivado.length ? '' : '*'}
                  </span>
                </p>
                <MainInput
                  value={gruposValues[index]?.establecimientoPrivado || ''}
                  name="establecimientoPrivado"
                  disabled={disableCupoCerrado}
                  handleChange={(e) => {
                    if (e.target.value.charAt(0) === ' ') {
                      return '';
                    }
                    handleChangeGrupo(e);
                  }}
                  placeholder=""
                  maxLength={DEFAULT_LIMIT}
                  fullWidth
                  customStyle={{
                    width: '20rem',
                  }}
                />
              </Grid>
              <Grid
                container
                item
                md={2}
                alignItems="center"
                style={{
                  marginTop: '20px',
                }}
              >
                <p
                  style={{
                    fontFamily: 'Open Sans',
                    marginRight: '8px',
                    width: '180px',
                    minHeight: '40px',
                  }}
                >
                  Mail:
                  <span style={{ color: 'red' }}>
                    {gruposValues[index]?.referente?.email ? '' : '*'}
                  </span>
                </p>
                <MainInput
                  customStyle={{ width: '90%' }}
                  value={gruposValues[index]?.referente?.email || ''}
                  handleChange={(e) => {
                    const value: any = e?.target?.value;
                    if (/[|°!"#$%&/()=?¡`\\^~¬¿'{´:;[¨+*},<>]/.test(value)) {
                      return value.slice();
                    }
                    const mimicEventReset = {
                      target: {
                        value: gruposValues[index]?.referente?.email,
                        name: 'referentePreviousEmail',
                      },
                    };

                    handleChangeGrupo(mimicEventReset);
                    inputValidator(value, 'emailRef');
                    const mimicEvent = {
                      target: {
                        value: value.trim(),
                        name: 'referenteEmail',
                      },
                    };
                    setEmailFieldDirty(true);
                    handleChangeGrupo(mimicEvent);
                  }}
                  handleBlur={(e) => {
                    if (emailFieldDirty && !!!error.emailRef) {
                      setReferenteFilter({
                        referente: [
                          {
                            path: 'email',
                            value: e.target.value,
                          },
                        ],
                      });
                    }
                  }}
                  placeholder=""
                  error={error.emailRef}
                  helperText={'Ingrese un mail válido'}
                  disabled={workingReferente}
                />
                {emailFieldDirty && workingReferente && (
                  <span style={{ color: '#00bcd4', pointerEvents: 'none' }}>
                    {'Verificando existencia de correo...'}
                  </span>
                )}
              </Grid>

              <Grid
                container
                item
                md={2}
                alignItems="center"
                style={{
                  marginTop: '20px',
                }}
              >
                <p
                  style={{
                    fontFamily: 'Open Sans',
                    marginRight: '8px',
                    width: '180px',
                    minHeight: '40px',
                  }}
                >
                  Nombre referente de acción:
                  <span style={{ color: 'red' }}>
                    {gruposValues[index]?.referente?.nombre ? '' : '*'}
                  </span>
                </p>
                <MainInput
                  value={gruposValues[index]?.referente?.nombre || ''}
                  customStyle={{ width: '90%' }}
                  handleChange={(e) => {
                    const value: any = e?.target?.value;
                    const mimicEvent = {
                      target: {
                        value: value,
                        name: 'referenteNombre',
                      },
                    };
                    if (value.charAt(0) === ' ') {
                      return '';
                    }
                    if (inputValidator(value, 'nombre referente')) {
                      handleChangeGrupo(mimicEvent);
                    }
                  }}
                  placeholder=""
                  maxLength={DEFAULT_LIMIT}
                  error={error.nombreRef}
                  helperText="Sin caracteres especiales ni números"
                  disabled={workingReferente}
                />
              </Grid>
              <Grid
                container
                item
                md={2}
                alignItems="center"
                style={{
                  marginTop: '20px',
                }}
              >
                <p
                  style={{
                    fontFamily: 'Open Sans',
                    marginRight: '8px',
                    width: '180px',
                    minHeight: '40px',
                  }}
                >
                  Apellido referente de acción:
                  <span style={{ color: 'red' }}>
                    {gruposValues[index]?.referente?.apellido ? '' : '*'}
                  </span>
                </p>
                <MainInput
                  value={comparision}
                  customStyle={{ width: '90%' }}
                  handleChange={(e) => {
                    const value: any = e?.target?.value;
                    const mimicEvent = {
                      target: {
                        value: value,
                        name: 'referenteApellido',
                      },
                    };
                    if (value.charAt(0) === ' ') {
                      return '';
                    }
                    if (inputValidator(value, 'apellido referente')) {
                      handleChangeGrupo(mimicEvent);
                    }
                  }}
                  placeholder=""
                  maxLength={DEFAULT_LIMIT}
                  error={error.apellidoRef}
                  helperText="Sin caracteres especiales ni números"
                  disabled={workingReferente}
                />
              </Grid>
              <Grid
                container
                item
                md={2}
                alignItems="center"
                style={{
                  marginTop: '20px',
                }}
              >
                <p
                  style={{
                    fontFamily: 'Open Sans',
                    marginRight: '8px',
                    width: '180px',
                    minHeight: '40px',
                  }}
                >
                  Teléfono:
                  <span style={{ color: 'red' }}>
                    {gruposValues[index]?.referente?.telefono ? '' : '*'}
                  </span>
                </p>
                <MainInput
                  value={gruposValues[index]?.referente?.telefono || ''}
                  customStyle={{ width: '90%' }}
                  handleChange={(e) => {
                    const value: any = e?.target?.value;
                    const mimicEvent = {
                      target: {
                        value: value,
                        name: 'referenteTelefono',
                      },
                    };
                    if (inputValidator(value, 'telefonoMain')) {
                      handleChangeGrupo(mimicEvent);
                    }
                  }}
                  placeholder=""
                  maxLength={TELEFONO_LIMIT}
                  error={error.telefonoMain}
                  helperText="Ingrese solo números"
                  disabled={workingReferente}
                />
              </Grid>
              <Grid
                container
                item
                md={4}
                alignItems="center"
                style={{
                  marginTop: '20px',
                }}
              >
                <p
                  style={{
                    fontFamily: 'Open Sans',
                    marginRight: '8px',
                    width: '180px',
                    minHeight: '40px',
                  }}
                >
                  Código ingreso:
                </p>
                <Grid container direction="row">
                  <MainInput
                    value={codigoComparison}
                    disabled={true}
                    handleChange={() => {}}
                    placeholder="Sin codigo"
                    fullWidth
                    customStyle={{
                      width: '7rem',
                    }}
                  />
                  <IconButton onClick={() => resetPassword()}>
                    <CachedIcon color="primary" />
                  </IconButton>
                </Grid>
              </Grid>
              <Grid
                container
                item
                md={12}
                alignItems="center"
                style={{
                  marginTop: '20px',
                }}
              >
                <IconButton
                  disabled={jornadasValues?.length >= jornadasContent?.length}
                  onClick={() => agregarJornada()}
                >
                  {jornadasValues?.length >= jornadasContent?.length ? (
                    <Typography
                      style={{ fontFamily: 'Open Sans', fontWeight: '700' }}
                    >
                      Máximo de jornadas alcanzado
                    </Typography>
                  ) : (
                    <>
                      <Typography style={{ fontFamily: 'Open Sans' }}>
                        Agregar jornada
                      </Typography>
                      <AddCircleOutlineIcon
                        color="primary"
                        style={{ marginLeft: '5px' }}
                      />
                    </>
                  )}
                </IconButton>
              </Grid>
              {jornadasCount.map((jornada: number, indexJornada: number) => {
                return (
                  <JornadaComponent
                    index={indexJornada}
                    indexGrupo={index}
                    jornadasValues={jornadasValues}
                    handleChangeGrupo={handleChangeGrupo}
                    jornadas={jornadasContent}
                    organizacion={organizacion}
                    setJornadasValues={setJornadasValues}
                    borrarJornada={borrarJornada}
                    loading={loadingJornada}
                  />
                );
              })}
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};
