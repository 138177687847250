import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { MedioContacto } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'medio_contacto';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idMedioContacto',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idMedioContacto',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.MedioContacto
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.MedioContacto,
      hoc: { ...defaultOptions.hoc, ...businessOptions.MedioContacto.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<MedioContacto>(DAO_NAME, optionsToUse);

// hooks
export const useMedioContactoFetchById = fetchById;
export const useMedioContactoLibbyCall = libbyCall;
export const useMedioContactoDAO = libbyDAO;
export const useMedioContactoLibbyFetch = libbyFetch;

// components/hoc
export const MedioContactoAutocomplete = entityAutocomplete;
export const MedioContactoCall = renderPropCall;
export const MedioContactoById = renderPropFetchById;
export const MedioContactoSelect = simpleSelect;
export const MedioContactoAccordionSelector = accordionSelector;
export const MedioContactoSelectedChips = selectedChips;

// context
// ByIdContext
export const useMedioContactoByIdContext = useByIdContext;
export const MedioContactoByIdProvider = ByIdProvider;
export const MedioContactoByIdContext = ByIdContext;
// GeneralContext
export const useMedioContactoContext = useGeneralContext;
export const MedioContactoProvider = GeneralProvider;
export const MedioContactoContext = GeneralContext;
