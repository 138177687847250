import React from 'react';
import { MenuItem } from '../../../../commons/const/ui/menu';
import {
  EspaciosCurriculares,
  EspacioCurricular,
} from '../../../../screens/Private';
import { planificacionesRoutes } from './planificaciones';
import { planificacionesFeedbackRoutes } from './planificacionesFeedback';
import { planificacionesPreviewRoutes } from './planificacionPreview';

export const routes: MenuItem[] = [
  {
    key: 'planificaciones',
    basePath: '/:idEspacioCurricular/:idAnio/planificacion',
    enabled: true,
    icon: 'List',
    text: 'Planificaciones',
    children: planificacionesRoutes,
  },
  {
    key: 'planificacionesFeedback',
    basePath: '/:idEspacioCurricular/:idAnio/planificacionfeedback',
    enabled: true,
    icon: 'List',
    text: 'Planificaciones Feedback',
    children: planificacionesFeedbackRoutes,
  },
  {
    key: 'planificacionesPreview',
    basePath: '/:idEspacioCurricular/:idAnio/planificacionpreview',
    enabled: true,
    icon: 'List',
    text: 'Planificaciones Preview',
    children: planificacionesPreviewRoutes,
  },
  {
    key: 'tablaespacioscurriculares',
    basePath: '/:idEspacioCurricular/:idAnio',
    target: {
      component: EspacioCurricular,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'TablaEspaciosCurriculares',
    children: [],
  },
  {
    key: 'espacioscurricularesCards',
    basePath: '/',
    target: {
      component: EspaciosCurriculares,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'EspaciosCurriculares',
    children: [],
    default: true,
  },
];
