import { makeBusinessEntity } from '../../lib/libby/generator';
import { TipoInformes } from '../models';

const DAO_NAME = 'tipo_informes';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<TipoInformes>(DAO_NAME);

// hooks
export const useTipoInformesFetchById = fetchById;
export const useTipoInformesLibbyCall = libbyCall;
export const useTipoInformesDAO = libbyDAO;
export const useTipoInformesLibbyFetch = libbyFetch;

// components/hoc
export const TipoInformesAutocomplete = entityAutocomplete;
export const TipoInformesCall = renderPropCall;
export const TipoInformesById = renderPropFetchById;
export const TipoInformesSelect = simpleSelect;
export const TipoInformesAccordionSelector = accordionSelector;
export const TipoInformesSelectedChips = selectedChips;

// context
// ByIdContext
export const useTipoInformesByIdContext = useByIdContext;
export const TipoInformesByIdProvider = ByIdProvider;
export const TipoInformesByIdContext = ByIdContext;
// GeneralContext
export const useTipoInformesContext = useGeneralContext;
export const TipoInformesProvider = GeneralProvider;
export const TipoInformesContext = GeneralContext;
