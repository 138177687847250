import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import { Update } from '@material-ui/icons';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import React, { useMemo, useCallback } from 'react';
import {
  usePeriodoInicioLibbyFetch,
  PeriodoInicioCall,
  usePeriodoInicioDAO,
} from '../../../../../../app/business/presentismo_v3';
import { PeriodoInicio } from '../../../../../../app/models';
import { Loading } from '../../../../../../commons';
import customFormDialog from '../../../../../../commons/services/customFormDialog';
import { ModalAdaptacion } from '../../../components/ModalAdaptacion';
import {
  PeriodoInicialModal,
  PeriodoInicioSave,
} from '../../../components/PeriodoInicialModal';
import { Jornada } from '../../context';
import { AlumnoMap } from '../../types';
import { NIVELES_AUTHORIZED } from '../../utils/rolesPresentismo';

interface JustificacionesEspecialesProps {
  nivel: number;
  currentWeek: moment.Moment;
  serverDate: string;
  pastDaysOfWeek: number;
  rows: AlumnoMap[];
  selectedSeccion: string | null;
  reFetchAll: () => void;
}

const useStyles = makeStyles({
  button: {
    textTransform: 'none',
    backgroundColor: '#007BC7',
    width: 165,
    heigth: 35,
  },
});

export const JustificacionesEspeciales = ({
  nivel,
  selectedSeccion,
  currentWeek,
  rows,
  pastDaysOfWeek,
  serverDate,
  reFetchAll,
}: JustificacionesEspecialesProps) => {
  const classes = useStyles();
  const periodoInicialDAO = usePeriodoInicioDAO();
  const { enqueueSnackbar } = useSnackbar();

  const isDisabled = useMemo(() => {
    return (
      moment(currentWeek)
        .startOf('isoWeek')
        .add(pastDaysOfWeek, 'days')
        .isAfter(moment(serverDate)) ||
      rows.length === 0 ||
      !selectedSeccion
    );
  }, [currentWeek, pastDaysOfWeek, serverDate, selectedSeccion, rows]);

  const onSavePeriodoInicial = useCallback(
    async (values: PeriodoInicioSave[]) => {
      const toSaves = values.filter(
        (periodo) =>
          !periodo.idPeriodoInicio ||
          (periodo.idPeriodoInicio && periodo.value),
      );
      const deletePeriodos = values.filter((periodo) => !periodo.value);

      if (toSaves.length > 0) {
        try {
          await periodoInicialDAO.save(toSaves);
          enqueueSnackbar('Periodos guardados', { variant: 'success' });
          reFetchAll();
        } catch (error) {
          enqueueSnackbar('No se pudo guardar los periodos', {
            variant: 'error',
          });
        }
      }
      if (deletePeriodos.length > 0) {
        try {
          for (const periodo of deletePeriodos) {
            await periodoInicialDAO.remove(periodo);
          }
          enqueueSnackbar('Periodos eliminados', { variant: 'success' });
          reFetchAll();
        } catch (error) {
          enqueueSnackbar('No se pudo eliminar los periodos', {
            variant: 'error',
          });
        }
      }
    },
    [enqueueSnackbar, reFetchAll, periodoInicialDAO],
  );

  const handleOnClickAdaptacion = useCallback(() => {
    customFormDialog.show({
      title: 'Periodo de Inicio',
      renderComponent: (
        <PeriodoInicialModal
          rows={rows}
          day={moment(currentWeek)
            .startOf('isoWeek')
            .add(pastDaysOfWeek, 'days')
            .format('YYYY-MM-DD')}
          onSavePeriodoInicial={onSavePeriodoInicial}
        />
      ),
      sizeWidth: 'md',
    });
  }, [currentWeek, pastDaysOfWeek, rows, onSavePeriodoInicial]);

  return (
    <Grid xs={12} item hidden={!NIVELES_AUTHORIZED.includes(Number(nivel))}>
      <Box
        pb={0.2}
        color="#38485C"
        letterSpacing={0}
        fontSize={14}
        fontWeight="bold"
        fontFamily="Nunito, Open Sans"
      >
        Justificaciones especiales
      </Box>
      <Button
        disabled={isDisabled}
        onClick={handleOnClickAdaptacion}
        className={classes.button}
        startIcon={<Update fontSize="large" />}
        color="primary"
        variant="contained"
      >
        Periodo de inicio
      </Button>
    </Grid>
  );
};
