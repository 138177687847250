import React, { useMemo, useEffect, useState } from 'react';
import {
  makeStyles,
  Grid,
  IconButton,
  Divider,
  Tooltip,
  Typography,
  List,
  FormControlLabel,
  Checkbox,
  Modal,
  Zoom,
  Box,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import Visibility from '@material-ui/icons/Visibility';
import { descripcionNewLine, MainButton } from 'src/commons';
import { optionsSort } from 'src/utils';
import { useParams } from 'react-router';
import { usePlanificationFormDisciplinarComun } from '../../hooks';
import { AnyObject } from '../../../../../../../../../commons/types';
import { RecursosDialog } from '../../../../../dialogs/RecursosDialog';
import {
  FormInput,
  FormInputControlled,
} from '../../../../../../../../../lib/templates/components';
import { HabilidadesDialog } from '../../../../../dialogs/HabilidadesDialog';
import { CommonCheckboxFormInput } from '../../../../../components';
import { InstrumentosEvaluacionDialog } from '../../../../../dialogs/InstrumentosEvaluacionDialog';
import { SeccionesDialog } from '../../../../../dialogs/SeccionesDialog';
import { ContenidosEspecificosDialog } from '../../../../../dialogs/ContenidosEspecificosDialog';
import { grey } from '../../../../../../../../../theme/colors';
import { useDisciplinarComunContext } from '../../components/context/DisciplinarComunContext';
import { useSeccionContext } from '../../../../../../../../../app/business';
import { useGruposSeccion } from '../../../../hooks';

import typography from 'src/theme/typography';
import { red } from '@material-ui/core/colors';
import theme from 'src/theme';

const CustomTooltip = ({ details }: any) => {
  const styles = makeStyles((theme) => ({
    label: {
      color: grey.textPrimary,
      fontSize: 14,
      fontFamily: typography.fontFamily,
    },
    labelPrimary: {
      background: `${grey.background}`,
      borderTop: `1px solid ${grey.normal}`,
      borderBottom: `1px solid ${grey.normal}`,
      padding: '5px 10px',
      marginBottom: 10,
    },
    listContainer: {
      padding: 10,
    },
    listItem: {
      paddingLeft: 20,
      paddingTop: 0,
      paddingBottom: 0,
    },
    listItemSub: {
      paddingLeft: 20,
    },
    modalContainer: {
      position: 'absolute',
      top: 'calc(50% - 300px)',
      left: 'calc(50% - 300px)',
      width: 600,
      height: 500,
      backgroundColor: 'white',
      overflowY: 'scroll',
    },
    iconBtn: {
      padding: 0,
      marginLeft: theme.spacing(1),
    },
  }));
  const classes = styles();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <>
      <IconButton
        edge="end"
        aria-label="details"
        onClick={handleOpen}
        onMouseEnter={() => handleOpen()}
        className={classes.iconBtn}
      >
        <Visibility />
      </IconButton>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Zoom in={open}>
          <Box
            className={classes.modalContainer}
            onMouseLeave={() => handleClose()}
          >
            {details.map((item) => (
              <List
                key={item.id}
                dense
                component="div"
                role="list"
                disablePadding
                className={classes.listContainer}
              >
                <Grid container className={classes.labelPrimary}>
                  <FormControlLabel
                    control={
                      <Checkbox checked={item.checked} color="primary" />
                    }
                    label={item.descripcion}
                    className={classes.label}
                  />
                </Grid>
                {item.checked &&
                  item.subLevel.map((sub) => (
                    <List
                      key={sub.id}
                      dense
                      component="div"
                      role="list"
                      className={classes.listItem}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox checked={sub.checked} color="primary" />
                        }
                        label={sub.descripcion}
                        className={classes.label}
                      />
                      {sub?.subLevel2?.map((sub2) => (
                        <List
                          key={sub2.id}
                          dense
                          component="div"
                          role="list"
                          className={classes.listItemSub}
                        >
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={sub2.checked}
                                color="primary"
                              />
                            }
                            label={sub2.descripcion}
                            className={classes.label}
                          />
                        </List>
                      ))}
                    </List>
                  ))}
              </List>
            ))}
          </Box>
        </Zoom>
      </Modal>
    </>
  );
};
export interface BimestreProps {
  prefix: string;
  name: string;
}

const useStyles = makeStyles({
  container: {
    padding: '32px 10px',
    marginBottom: 60,
    paddingRight: 60,
  },
  containerForm: {
    borderBottom: `1px solid ${grey.normal}`,
    backgroundColor: 'rgba(218, 218, 218, 0.17)',
    padding: 20,
    marginBottom: 15,
    borderRadius: 5,
  },
  listItem: {
    padding: 0,
  },
  listItemIcon: {
    marginRight: '-20px',
  },
  text: {
    color: grey.medium,
  },
  divider: { width: '100%', margin: '20px 0px 15px 0px' },
  marginDeleteIcon: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    paddingRight: 30,
  },
  actividad: {
    paddingBottom: 10,
  },
  title: {
    paddingTop: 10,
  },
  column: {
    marginTop: 'auto',
  },
});

const customStyleButton = { width: '160px' };

export const Bimestre = ({ prefix, name }: BimestreProps) => {
  const { values, form } = usePlanificationFormDisciplinarComun();
  const { data: secciones } = useSeccionContext();
  const { idEspacioCurricular } = useParams();
  const { transformeGrupos } = useGruposSeccion([idEspacioCurricular]);

  const seccionGrupos = [...(secciones || []), ...transformeGrupos];
  const {
    data: { habilidades, recursos, instrumentos, contenidosEspecificos },
  } = useDisciplinarComunContext();
  const classes = useStyles();

  const innerValue = values && values[prefix] && values[prefix][name];
  const addNew = () => {
    const newValue = innerValue;
    newValue.push({
      habilidad: [],
      contenidosEspecificos: [],
      recursos: [],
      intrumentosDeEvaluacion: [],
      actividad: '',
      descripcion: '',
      horasCatedra: '',
      seccion: [],
    });
    form.change(prefix, {
      [prefix]: [...newValue],
    });
  };

  const onRemoveItem = (index: any) => {
    const newInput = innerValue.filter(
      (item: any, ind: number) => index !== ind,
    );
    form.change(prefix, {
      [prefix]: [...newInput],
    });
  };
  const isLastItem = innerValue.length;

  const sortedResources = useMemo(() => optionsSort(recursos), [recursos]);

  const sortedInstruments = useMemo(
    () => optionsSort(instrumentos),
    [instrumentos],
  );

  const [details, setDetails] = useState([]);
  useEffect(() => {
    const newDetails = contenidosEspecificos
      .map((contenido) => {
        const found = values[name][name][0].contenidosEspecificos.find(
          (old: any) => old.id === contenido.idContenidoEspecifico,
        );
        const contenidoSubNivel = contenido.contenidoEspecificoSubnivels;
        return !!found
          ? {
              id: contenido.idContenidoEspecifico,
              descripcion: contenido.descripcion,
              checked: !!found,
              subLevel: contenidoSubNivel
                .map(
                  ({
                    contenidoEspecificoSubnivelDos,
                    idContenidoEspecificoSubnivel,
                    descripcion: descripcionSub,
                  }) => {
                    const contenidosSubNivelDos =
                      contenidoEspecificoSubnivelDos;
                    return !!found?.subLevel.find(
                      (level: any) =>
                        Number(level.id) === idContenidoEspecificoSubnivel,
                    )
                      ? {
                          id: idContenidoEspecificoSubnivel,
                          descripcion:
                            descripcionNewLine(descripcionSub)[0].props
                              .children,
                          checked: !found
                            ? false
                            : !!found?.subLevel.find(
                                (level: any) =>
                                  Number(level.id) ===
                                  idContenidoEspecificoSubnivel,
                              ),
                          subLevel2: !contenidosSubNivelDos
                            ? []
                            : contenidosSubNivelDos
                                .map(
                                  ({
                                    idContenidoEspecificoSubnivelDos,
                                    descripcion: descripSubNivelDos,
                                  }) =>
                                    !!found?.subLevel?.find(
                                      (level: {
                                        id: string;
                                        subLevel2: Array<string>;
                                      }) =>
                                        !!level.subLevel2.find(
                                          (levle2: any) =>
                                            Number(levle2) ===
                                            idContenidoEspecificoSubnivelDos,
                                        ),
                                    )
                                      ? {
                                          id: idContenidoEspecificoSubnivelDos,
                                          descripcion:
                                            descripcionNewLine(
                                              descripSubNivelDos,
                                            )[0].props.children,
                                          checked: !found
                                            ? false
                                            : !!found?.subLevel?.find(
                                                (level: {
                                                  id: string;
                                                  subLevel2: Array<string>;
                                                }) =>
                                                  !!level.subLevel2.find(
                                                    (levle2: any) =>
                                                      Number(levle2) ===
                                                      idContenidoEspecificoSubnivelDos,
                                                  ),
                                              ),
                                        }
                                      : null,
                                )
                                .filter((a) => a !== null),
                        }
                      : null;
                  },
                )
                .filter((a) => a !== null),
            }
          : null;
      })
      .filter((a) => a !== null);
    setDetails(newDetails);
  }, [contenidosEspecificos, name, values]);

  return (
    <Grid container direction="column" className={classes.container}>
      {!innerValue
        ? null
        : innerValue.map((item: AnyObject, index: number) => {
            return (
              <Grid direction="row" className={classes.containerForm}>
                <Grid xs={12} direction="row" container>
                  <Grid item xs={8} className={classes.title}>
                    <Typography variant="h3">
                      {innerValue[index].actividad}
                    </Typography>
                  </Grid>

                  <Grid item xs={4} className={classes.marginDeleteIcon}>
                    <Tooltip title="Eliminar Actividad">
                      <IconButton edge="end" aria-label="delete">
                        <DeleteIcon onClick={() => onRemoveItem(index)} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1}>
                    &nbsp;
                  </Grid>
                </Grid>
                <Grid item xs={12} direction="row" spacing={2} container>
                  <Grid item xs={4} className={classes.column}>
                    <CommonCheckboxFormInput
                      label="Habilidad"
                      name={`${prefix}.${name}[${index}].habilidad`}
                      form={form}
                      placeholder="Seleccioná habilidad"
                      dialog={HabilidadesDialog}
                      options={habilidades}
                      optionIdKey="idHabilidades"
                    />
                  </Grid>
                  <Grid item xs={4} className={classes.column}>
                    <FormInputControlled
                      label="Contenidos específicos"
                      icon={<CustomTooltip details={details} />}
                      name={`${prefix}.${name}[${index}].contenidosEspecificos`}
                      form={form}
                      placeholder="Seleccioná contenidos específicos"
                      getInputValue={(value) => {
                        if (!Array.isArray(value) || !value.length) return '';

                        const selectContenido = contenidosEspecificos
                          .filter((u) =>
                            value?.find(
                              (e) =>
                                Number(e.id) ===
                                Number(u.idContenidoEspecifico),
                            ),
                          )
                          .map((contenido) => contenido.descripcion);

                        return selectContenido;
                      }}
                      getNewValue={async (oldValue) => {
                        const _values = contenidosEspecificos.map(
                          (contenido) => {
                            const found = oldValue.find(
                              (old: any) =>
                                old.id === contenido.idContenidoEspecifico,
                            );
                            const contenidoSubNivel =
                              contenido.contenidoEspecificoSubnivels;
                            return {
                              id: contenido.idContenidoEspecifico,
                              descripcion: contenido.descripcion,
                              checked: !!found,
                              subLevel: contenidoSubNivel.map(
                                ({
                                  contenidoEspecificoSubnivelDos,
                                  idContenidoEspecificoSubnivel,
                                  descripcion: descripcionSub,
                                }) => {
                                  const contenidosSubNivelDos =
                                    contenidoEspecificoSubnivelDos;
                                  return {
                                    id: idContenidoEspecificoSubnivel,
                                    descripcion:
                                      descripcionNewLine(descripcionSub),
                                    checked: !found
                                      ? false
                                      : !!found?.subLevel.find(
                                          (level: any) =>
                                            Number(level.id) ===
                                            idContenidoEspecificoSubnivel,
                                        ),
                                    subLevel2: !contenidosSubNivelDos
                                      ? []
                                      : contenidosSubNivelDos.map(
                                          ({
                                            idContenidoEspecificoSubnivelDos,
                                            descripcion: descripSubNivelDos,
                                          }) => ({
                                            id: idContenidoEspecificoSubnivelDos,
                                            descripcion:
                                              descripcionNewLine(
                                                descripSubNivelDos,
                                              ),
                                            checked: !found
                                              ? false
                                              : !!found?.subLevel?.find(
                                                  (level: {
                                                    id: string;
                                                    subLevel2: Array<string>;
                                                  }) =>
                                                    !!level.subLevel2.find(
                                                      (levle2: any) =>
                                                        Number(levle2) ===
                                                        idContenidoEspecificoSubnivelDos,
                                                    ),
                                                ),
                                          }),
                                        ),
                                  };
                                },
                              ),
                            };
                          },
                        );
                        const newValue = await ContenidosEspecificosDialog.open(
                          _values || [],
                        );
                        if (!newValue) {
                          return oldValue;
                        }
                        return newValue
                          .filter((_item: any) => _item.checked)
                          .map((_item: any) => ({
                            id: _item.id,
                            subLevel: _item.subLevel
                              .filter((level: any) => level.checked)
                              .map((level: any) => ({
                                id: level.id,
                                subLevel2: !level?.subLevel2.length
                                  ? []
                                  : level.subLevel2
                                      .filter((level2: any) => level2.checked)
                                      .map((level2: any) =>
                                        level2.id.toString(),
                                      ) || [],
                              })),
                          }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={4} className={classes.column}>
                    <CommonCheckboxFormInput
                      label="Recursos"
                      name={`${prefix}.${name}[${index}].recursos`}
                      form={form}
                      placeholder="Seleccioná recursos"
                      dialog={RecursosDialog}
                      options={sortedResources}
                      optionIdKey="idRecursos"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CommonCheckboxFormInput
                      label="Instrumentos de evaluación"
                      name={`${prefix}.${name}[${index}].intrumentosDeEvaluacion`}
                      form={form}
                      placeholder="Seleccioná instrumentos de evaluación"
                      dialog={InstrumentosEvaluacionDialog}
                      options={sortedInstruments}
                      optionIdKey="idInstrumentosDeEvaluacion"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      label="Actividad"
                      name={`${prefix}.${name}[${index}].actividad`}
                      form={form}
                      placeholder="Ingresá nombre de la actividad"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      label="Descripción"
                      name={`${prefix}.${name}[${index}].descripcion`}
                      form={form}
                      placeholder="Describí la actividad"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      label="Horas cátedra"
                      name={`${prefix}.${name}[${index}].horasCatedra`}
                      form={form}
                      placeholder="Ingresá horas"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CommonCheckboxFormInput
                      label="Sección"
                      name={`${prefix}.${name}[${index}].seccion`}
                      form={form}
                      placeholder="Seleccioná sección"
                      dialog={SeccionesDialog}
                      options={seccionGrupos || []}
                      optionIdKey="idSeccion"
                      optionLabelKey="nombreSeccion"
                    />
                  </Grid>
                </Grid>
                {isLastItem !== index + 1 && (
                  <Divider className={classes.divider} />
                )}
              </Grid>
            );
          })}
      <MainButton
        title="Agregar Actividad"
        handleOnClick={() => addNew()}
        customStyle={customStyleButton}
      />
    </Grid>
  );
};
