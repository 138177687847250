import {
  useDevolucionContenidosDAO,
  useDevolucionHabilidadesDAO,
  useDevolucionPerfilesDAO,
  useDevolucionesEvaluacionesDAO,
} from 'src/app/business/acap';
import { useEstudianteContext } from '../contexts/EstudianteContext';

export const useAutoevaluacionSubmit = (
  cb: (
    /* categoria: number, bloque: number, */ error?: { message: string },
  ) => void,
) => {
  const devPerfilDAO = useDevolucionPerfilesDAO();
  const devHabilidadesDAO = useDevolucionHabilidadesDAO();
  const devContenidosDAO = useDevolucionContenidosDAO();
  const evaluacionesDevDAO = useDevolucionesEvaluacionesDAO();
  const { data } = useEstudianteContext();

  return async (form: Acap.Estudiante.AutoevaluacionForm) => {
    try {
      if (data[0].perfiles.length > 0) {
        const devPerfilesSaved = await devPerfilDAO.aspect('basic').save({
          idAutoevaluacionPerfil: form.perfiles.idAutoevaluacionPerfil,
          observaciones: form.perfiles.observaciones ?? '',
          observacionesReferente: null,
          grupoInscripcion: form.grupoInscripcion!,
        });
        await evaluacionesDevDAO.save(
          form.perfiles.valores.map((evaluacionDev) => ({
            ...evaluacionDev,
            idDevolucion: devPerfilesSaved.idAutoevaluacionPerfil,
          })),
        );
      }
      if (data[0].habilidades.length > 0) {
        const devHabSaved = await devHabilidadesDAO.aspect('basic').save({
          idAutoevaluacionHabilidad: form.habilidades.idAutoevaluacionHabilidad,
          observaciones: form.habilidades.observaciones ?? '',
          observacionesReferente: null,
          grupoInscripcion: form.grupoInscripcion!,
        });
        await evaluacionesDevDAO.save(
          form.habilidades.valores.map((evaluacion) => ({
            ...evaluacion,
            idDevolucion: devHabSaved.idAutoevaluacionHabilidad,
          })),
        );
      }

      if (data[0].nodos.length > 0) {
        const devContenidoSaved = await devContenidosDAO.aspect('basic').save({
          idAutoevaluacionContenido: form.contenidos.idAutoevaluacionContenido,
          observaciones: form.contenidos.observaciones ?? '',
          observacionesReferente: null,
          grupoInscripcion: form.grupoInscripcion!,
        });
        await evaluacionesDevDAO.save(
          form.contenidos.valores.map((evaluacion) => ({
            ...evaluacion,
            idDevolucion: devContenidoSaved.idAutoevaluacionContenido,
          })),
        );
      }
      cb();
    } catch (error) {
      cb(
        /* form.categoria, form.bloque, */ {
          message: (error as Error).message,
        },
      );
    }
  };
};
