import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class ConfirmacionMatriculaLocalizacionDAO extends LibbyFetchDAO {
  constructor() {
    super(
      'confirmacion_matricula_localizacion',
      'idConfirmacionMatriculaLocalizacion',
    );
  }
}
