import { LibbyFetchDAO } from './LibbyFetchDAO';

export class CodigoVinculoDAO extends LibbyFetchDAO {
  constructor() {
    super('codigovinculo', 'idCodigoVinculo');
  }

  post(alumnoId: string): any {
    return super.save({ alumno: alumnoId });
  }

  getByAlumno(alumnoId: string) {
    return this.query().equals('alumno', alumnoId).run();
  }
}
