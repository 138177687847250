export enum TABBAR_BLOCKER_ID {
  // TABS ESPACIOS CURRICULARES
  ESPACIO_CURRICULAR_PLANIFICACION_TAB = 1,
  ESPACIO_CURRICULAR_ACTIVIDADES_TAB,
  ESPACIO_CURRICULAR_CALIFICACIONES_TAB,
  ESPACIO_CURRICULAR_AULAS_VIRTUALES_TAB,
  ESPACIO_CURRICULAR_AULAS_VIRTUALES_GRUPOS_TAB,
  ESPACIO_CURRICULAR_TOMAR_ASISTENCIA = 6,
  // TABS MATRICULACION
  MATRICULACION_SECCION,
  MATRICULACION_PROMOTION,
  MATRICULACION_PASS_MANAGEMENT,
  MATRICULACION_MATRICULADOS,
  MATRICULACION_EXTERNOS,
  MATRICULACION_GRUPOS,
  MATRICULACION_INSCRIPTOS,
  PROMOCION_ACOMPANIADA,
  // TABS CALIFICACIONES
  CALIFICACION_PRIMARIO_ASPECTOS_GENERALES,
  CALIFICACION_PRIMARIO_QUEHACERES,
  CALIFICACION_PRIMARIO_CONVIVENCIA,
  CALIFICACION_PRIMARIO_AREA_CONOCIMIENTO,
  CALIFICACION_PRIMARIO_OBSERVACIONES,
  CALIFICACION_PRIMARIO_SINTESIS_CONCEPTUAL,
  CALIFICACION_PRIMARIO_JE,
  INFORME_VALORATIVO,
  // PRESENTISMO
  PRESENTISMO_JE,
  PRESENTISMO_TOMA_GENERAL,
  PRESENTISMO_CONFIGURACION,
  PRESENTISMO_REPORTE,
  PRESENTISMO_CONTRATURNO,
  PRESENTISMO_SITUACIONES,
  PRESENTISMO_REGULARIDAD,
  // MODULO PERMISOS
  PERMISSION_MODULE_PEM_FORM,
}
