import { TableColumn } from '../../TableColumn';
import { FILTERS } from '../../table_filters';
import { BoletinesECCalificadosReport } from '../../types';

// Cantidad de espacios curriculares calificados y sin calificar (Ejemplo 5/10), Porcentaje de espacios C. Calificados, Cantidad de espacios curriculares Cerrados (2/10), Porcentaje de EC cerrados.
export const BOLETINES_EC_CALIFICADOS_COLUMNS = [
  new TableColumn<BoletinesECCalificadosReport>('cue_anexo'),
  new TableColumn<BoletinesECCalificadosReport>('establecimiento'),
  new TableColumn<BoletinesECCalificadosReport>('distrito_escolar'),
  new TableColumn<BoletinesECCalificadosReport>('año'),
  new TableColumn<BoletinesECCalificadosReport>('seccion'),
  new TableColumn<BoletinesECCalificadosReport>('periodo'),
  new TableColumn<BoletinesECCalificadosReport>(
    'calificados',
    'EC Calificados',
  ),
  new TableColumn<BoletinesECCalificadosReport, React.ReactNode>(
    'no_calificados',
    'EC no calificados',
    (value, row) => {
      return Number(row.espacios_curriculares_total) - Number(row.calificados);
    },
  ),
  new TableColumn<BoletinesECCalificadosReport>('cerrados', 'EC Cerrados'),
];

export const BOLETINES_EC_CALIFICADOS_FILTERS = [
  FILTERS.dependencia_funcional,
  FILTERS.distrito_escolar,
  FILTERS.localizacion,
  FILTERS.seccion,
  FILTERS.anio,
  FILTERS.turno,
  FILTERS.periodo,
];
