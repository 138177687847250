import { PresentismoEspacioCurricularDAO } from 'src/platform/libby/dao/Presentismov3';
import { makeBusinessEntity } from '../../lib/libby/generator';
import { PresentismoEspacioCurricular } from '../models';

const DAO_NAME = 'presente_espacio_curricular';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    selectInput,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<
  PresentismoEspacioCurricular,
  PresentismoEspacioCurricularDAO
>(DAO_NAME);

// hooks
export const usePresentismoEspacioCurricularFetchById = fetchById;
export const usePresentismoEspacioCurricularLibbyCall = libbyCall;
export const usePresentismoEspacioCurricularDAO = libbyDAO;
export const usePresentismoEspacioCurricularLibbyFetch = libbyFetch;

// components/hoc
export const PresentismoEspacioCurricularAutocomplete = entityAutocomplete;
export const PresentismoEspacioCurricularCall = renderPropCall;
export const PresentismoEspacioCurricularById = renderPropFetchById;
export const PresentismoEspacioCurricularSelect = selectInput;
export const PresentismoEspacioCurricularAccordionSelector = accordionSelector;
export const PresentismoEspacioCurricularSelectedChips = selectedChips;

// context
// ByIdContext
export const usePresentismoEspacioCurricularByIdContext = useByIdContext;
export const PresentismoEspacioCurricularByIdProvider = ByIdProvider;
export const PresentismoEspacioCurricularByIdContext = ByIdContext;
// GeneralContext
export const usePresentismoEspacioCurricularContext = useGeneralContext;
export const PresentismoEspacioCurricularProvider = GeneralProvider;
export const PresentismoEspacioCurricularContext = GeneralContext;
