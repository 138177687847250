import React, { FunctionComponent } from 'react';
import { CalendarContainer } from '../../styles/CalendarStyles';
import Calendar from 'react-calendar';
import { Grid } from '@material-ui/core';

interface CalendarioProps {
  date: Date;
  changeDate: (e: any) => void;
  minDate?: Date;
  maxDate?: Date;
  onClickDay: (value: Date, event: unknown) => void;
  tileDisabled: (tile: {
    activeStartDate: Date;
    date: Date;
    view: string;
  }) => boolean;
  tileClassName: (tile: {
    activeStartDate: Date;
    date: Date;
    view: string;
  }) => string;
  onActiveStartDateChange: (params: {
    activeStartDate: Date;
    action: string;
    value: Date;
    view: string;
  }) => void;
}

export const Calendario: FunctionComponent<CalendarioProps> = ({
  changeDate,
  date,
  minDate,
  maxDate,
  onClickDay,
  tileDisabled,
  tileClassName,
  onActiveStartDateChange,
}) => {
  return (
    <>
      <Grid
        item
        xs={6}
        style={{
          height: '100%',
          marginBottom: '15px',
          paddingLeft: '30px',
        }}
      >
        <CalendarContainer>
          <Calendar
            minDate={minDate}
            maxDate={maxDate}
            onChange={changeDate}
            value={date}
            onClickDay={onClickDay}
            formatShortWeekday={(locale, value) =>
              ['D', 'L', 'M', 'M', 'J', 'V', 'S'][value.getDay()]
            }
            tileDisabled={tileDisabled}
            tileClassName={tileClassName}
            onActiveStartDateChange={onActiveStartDateChange}
          />
        </CalendarContainer>
      </Grid>
    </>
  );
};
