import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { ArealPlan } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'areal_plan';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idArealPlan',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idArealPlan',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.ArealPlan
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.ArealPlan,
      hoc: { ...defaultOptions.hoc, ...businessOptions.ArealPlan.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<ArealPlan>(DAO_NAME, optionsToUse);

// hooks
export const useArealPlanFetchById = fetchById;
export const useArealPlanLibbyCall = libbyCall;
export const useArealPlanDAO = libbyDAO;
export const useArealPlanLibbyFetch = libbyFetch;

// components/hoc
export const ArealPlanAutocomplete = entityAutocomplete;
export const ArealPlanCall = renderPropCall;
export const ArealPlanById = renderPropFetchById;
export const ArealPlanSelect = simpleSelect;
export const ArealPlanAccordionSelector = accordionSelector;
export const ArealPlanSelectedChips = selectedChips;

// context
// ByIdContext
export const useArealPlanByIdContext = useByIdContext;
export const ArealPlanByIdProvider = ByIdProvider;
export const ArealPlanByIdContext = ByIdContext;
// GeneralContext
export const useArealPlanContext = useGeneralContext;
export const ArealPlanProvider = GeneralProvider;
export const ArealPlanContext = GeneralContext;
