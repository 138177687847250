import { LibbyFetchDAO } from '../LibbyFetchDAO';
import moment from 'moment';

export class Presente_v3DAO extends LibbyFetchDAO {
  constructor() {
    super('presente_v3', 'idPresente');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByAlumnoTurnoFecha(filter: {
    idAlumno: number;
    idTurno: number;
    fechaDesde: string;
    fechaHasta: string;
  }) {
    return (
      this.query()
        // .groupStart()
        .equals('alumno.idAlumno', filter.idAlumno)
        .equals('turno.idTurno', filter.idTurno)
        .higherOrEquals(
          'fecha',
          moment(filter.fechaDesde).format('YYYY-M-D HH:mm:ss'),
        )
        .lowerOrEquals(
          'fecha',
          moment(filter.fechaHasta).format('YYYY-M-D HH:mm:ss'),
        )
        // .groupEnd()
        .run()
    );
  }

  async getCount(filters: any) {
    this.aspect('count_presentes');
    let query = this.query();
    query = this.applyFilters(query, filters);
    const response = await query.aggregate().count('idPresente').run();
    return response;
  }
}
