import React, { useCallback } from 'react';
import { Typography } from '@material-ui/core';
import { useField } from 'react-final-form-hooks';
import { MainButton } from '../../../../commons/components/MainButton';
import {
  FormMultiCheckboxField,
  GetRender,
} from './components/FormMultiCheckboxField';

export interface FormMultiCheckboxInputProps {
  name: string;
  label: string;
  form: any;
  getItemKey?: (value: any, index: number) => string;
  children: GetRender;
  addButtonText?: string;
  getNewElement: (values: any) => any | Promise<any>;
  renderAddButton?: (onClick: () => void) => JSX.Element;
  buttonCustomStyle?: { [index: string]: any };
}

// <TextField
//   key={`${item}${index}`}
//   value={item}
//   onChange={({ target: { value } }) => {
//     const newInput = [...objetivos.input.value];
//     newInput[index] = value;
//     objetivos.input.onChange(newInput);
//   }}
// />

const customStyleButton = { marginTop: '15px' };

export const FormMultiCheckboxInput = ({
  name,
  form,
  label,
  getItemKey,
  children,
  addButtonText,
  getNewElement,
  renderAddButton,
  buttonCustomStyle,
}: FormMultiCheckboxInputProps): JSX.Element => {
  const field = useField(name, form);
  const onChange = useCallback(
    (value, index) => {
      const newValues = field.input.value ? [...field.input.value] : [];
      newValues[index] = value;
      field.input.onChange(newValues);
    },
    [field.input],
  );
  const onAddClick = useCallback(async () => {
    const newInput = [...field.input.value];
    const newElement = await getNewElement(newInput);
    newInput.push(newElement);
    field.input.onChange(newInput);
  }, [field.input, getNewElement]);
  const onRemoveItem = useCallback(
    (index) => {
      const newInput = field.input.value.filter(
        (item: any, ind: number) => index !== ind,
      );
      field.input.onChange(newInput);
    },
    [field.input],
  );
  return (
    <div>
      <Typography>{label}</Typography>
      {!field.input.value
        ? null
        : field.input.value.map((item: any, index: number) => {
            const error = !field.meta.error ? null : field.meta.error[index];
            return (
              <FormMultiCheckboxField
                key={getItemKey ? getItemKey(item, index) : `${index}`}
                value={item}
                onChange={onChange}
                index={index}
                getRender={children}
                onBlur={field.input.onBlur}
                onFocus={field.input.onFocus}
                onRemove={onRemoveItem}
                error={error}
              />
            );
          })}
      {renderAddButton ? (
        renderAddButton(onAddClick)
      ) : (
        <MainButton
          title={addButtonText}
          handleOnClick={onAddClick}
          customStyle={{ ...buttonCustomStyle, customStyleButton }}
        />
      )}
    </div>
  );
};
