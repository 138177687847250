import React, { useMemo } from 'react';
import { useEscuelaContext } from '../../context/EscuelaContext';
import {
  FormContextProvider,
  makeInitialValue,
} from '../../../../../lib/templates';
import { FormView } from './components/FormView';
import { template } from '../../template/template';
import { InitialValue, _Contenido, _Habilidades } from '../../types';
import { useEvaluacionSubmit } from '../../hooks';
import { Loading } from '../../../../../commons';
import { makeInitialDevolution } from '../../functions/makeInitialDevoluciones';
import { makeValuesDevolution } from '../../functions/makeValuesDevoluciones';

/* 
lunes -> 0
martes -> 1
*/
const initialValue = makeInitialValue<InitialValue>(template.template);

export const DevolucionesFormView = () => {
  const {
    alumnoSelected,
    inscriptos,
    espacioCurricularSeccion,
    loading: working,
  } = useEscuelaContext();

  const _initial = useMemo(() => {
    let _init = initialValue;
    if (working) return;
    const current = inscriptos.find(
      (i) => i.idGrupoInscripcion === alumnoSelected?.idGrupoInscripcion,
    );
    if (current) {
      const { perfiles, habilidades, contenidos } = current;
      initialValue.perfiles = perfiles.filter(
        (p) =>
          p.createdBy?.idUsuario !==
          current.alumnoMovimiento.alumno.persona.cuentas.idUsuario,
      )[0];

      const _habilidad = habilidades.filter(
        (p) =>
          p.createdBy?.idUsuario !==
          current.alumnoMovimiento.alumno.persona.cuentas.idUsuario,
      )[0];
      initialValue.habilidades = {
        ..._habilidad,
        valores: makeValuesDevolution(
          _habilidad.valores.map((v) => v.devolucion),
        ),
      };

      const _contenidos = contenidos.filter(
        (p) =>
          p.createdBy?.idUsuario !==
          current.alumnoMovimiento.alumno.persona.cuentas.idUsuario,
      );

      initialValue.contenidos =
        _contenidos[0]?.idAutoevaluacionContenido === 0
          ? makeInitialDevolution<_Contenido>(
              'idAutoevaluacionContenido',
              _contenidos[0]?.valores.map((v) => v.devolucion) || [],
            )
          : {
              ..._contenidos[0],
              valores: makeValuesDevolution(
                _contenidos[0]?.valores.map((v) => v.devolucion) || [],
              ),
            };
      initialValue.grupoInscripcion = current;
      initialValue.espacioCurricular =
        espacioCurricularSeccion[0]?.espacioCurricular;
    }
    return _init;
  }, [alumnoSelected, working, inscriptos, espacioCurricularSeccion]);

  const { handleSubmit, loading } = useEvaluacionSubmit();

  return (
    <FormContextProvider
      initialValues={_initial}
      template={template}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {loading || working ? <Loading /> : <FormView />}
    </FormContextProvider>
  );
};
