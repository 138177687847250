import React, { useEffect, useRef, useMemo, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Fab, Grid } from '@material-ui/core';
import { Loading, useRouteScreenTitle } from 'src/commons';
import { useParams } from 'react-router';
import { GetAppOutlined } from '@material-ui/icons';
import { useRolesContext } from 'src/context/RolesContext';
import { useInformesHistoryLibbyCall } from 'src/app/business';
import { grey } from '../../../theme/colors';
import { useAttachmentFiles, useDocumentTitlePrint } from './hooks';
import { useGenerarinformePostRequest } from 'src/app/business/businessCustomEndpoints/generarinforme';
import { fixTemplate } from './utils';

export const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(6),
    width: '100%',
    position: 'relative',
  },
  boxButton: {
    position: 'absolute',
    zIndex: 1,
    marginLeft: '700px',
  },
  actionButtonsContainer: {
    position: 'absolute',
    top: 0,
    right: theme.spacing(2),
    zIndex: 1,
  },
  actionButtons: {
    display: 'flex',
    width: '130px',
    justifyContent: 'end',
  },
  previewContainer: {
    width: '800px',
  },
  title: {
    fontFamily: 'Open Sans',
    color: grey.medium,
    fontSize: 30,
  },
  htmlPage: {
    pageBreakAfter: 'avoid',
  },
}));

export const Preview = () => {
  useRouteScreenTitle('Visualización');
  const classes = useStyles();
  const { idPreview } = useParams<any>();

  const {
    selectedRole: { nivel },
  } = useRolesContext();

  const {
    data: informesHistory = [],
    working: ihWorking,
    recall,
  } = useInformesHistoryLibbyCall({
    methodName: 'fetch',
    noAutoCall: true,
  });

  useEffect(() => {
    if (!idPreview) return;
    recall({
      filter: {
        0: [{ path: 'preview.previewId', value: idPreview }],
      },
      orderBy: 'informeHistoryId',
      direction: 'desc',
      limit: 1,
    });
  }, [idPreview, recall]);

  const informesHistoryFixed = useMemo(
    () => fixTemplate(informesHistory),
    [informesHistory],
  );

  const { renderDocuments } = useAttachmentFiles(nivel);

  useDocumentTitlePrint(informesHistoryFixed[0]);
  const { request: generate } = useGenerarinformePostRequest<any>();

  const htmlRef = useRef<any>(null);
  const reportTemplate = useMemo(
    () => informesHistoryFixed[0]?.preview?.template,
    [informesHistoryFixed],
  );
  const previewId = useMemo(
    () => informesHistoryFixed[0]?.preview?.previewId,
    [informesHistoryFixed],
  );

  const onDownload = useCallback(() => {
    generate({ previewId });
    window.print();
  }, [generate, previewId]);

  useEffect(() => {
    if (ihWorking || !reportTemplate || htmlRef.current == null) return;
    htmlRef.current.innerHTML = reportTemplate;
    renderDocuments(
      informesHistoryFixed[0]?.alumno?.idAlumno || '',
      informesHistoryFixed[0]?.preview?.tipoPeriodo?.idTipoPeriodo || 0,
    );
  }, [reportTemplate, ihWorking, renderDocuments, informesHistoryFixed]);

  return ihWorking ? (
    <Loading />
  ) : informesHistory.length === 0 ? (
    <Grid container justifyContent="center" className={classes.root}>
      <h1 className={classes.title}>Previsualización no encontrada</h1>
    </Grid>
  ) : (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid component={Box} displayPrint="none" className={classes.boxButton}>
        <Grid className={classes.actionButtons}>
          <Fab color="primary" aria-label="add" onClick={onDownload}>
            <GetAppOutlined />
          </Fab>
        </Grid>
      </Grid>
      <Grid className={classes.previewContainer}>
        <div className={classes.htmlPage} ref={htmlRef}></div>
      </Grid>
    </Grid>
  );
};
