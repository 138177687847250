/* eslint-disable class-methods-use-this */
import { LibbyFetchDAO, Filter } from './LibbyFetchDAO';

export class AlumnoMovimientoCustomDAO extends LibbyFetchDAO {
  constructor() {
    super('alumno_movimiento_custom', 'idAlumnoMovimiento');
  }

  async getTotalAmount(filters: Filter) {
    return 0;
    // let query = this.query();
    // query = this.applyFilters(query, filters);
    // const response = await query.aggregate().count('idAlumnoMovimiento').run();
    // const amount = !response ? 0 : response[0].countofidalumnomovimiento;
    //
    // return amount;
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByAlumnoId(alumnoId: string) {
    return this.query().equals('alumno.idAlumno', alumnoId).run();
  }

  getByIdCuenta(usuarioId: string) {
    return this.query().equals('alumno.persona.cuentas', usuarioId).run();
  }
}
