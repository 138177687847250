import React, { useCallback, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { ModalFormComponentProps } from '../../../../commons/const/ui/dialogs/ModalForm';
import { useLocalizacionLibbyFetch } from '../../../business';
import { useTableSortConfig } from '../../../../commons/hooks';
import { InfoTable } from '../../../../commons/components/InfoTable';
import { useLocalizacionPickerColumns } from './hooks';
import {
  Filter,
  LocalizacionPickerTableHeader,
  SelectedValue,
} from './components';

const SPACING = 2;

const searchFilters = [
  'establecimiento.dependenciaFuncional.descripcion',
  'establecimiento.modalidad.descripcion',
  'establecimiento.distritoEscolar.nombre',
  'descripcion',
  'idLocalizacion',
  'domicilioEstablecimiento.calle',
  'domicilioEstablecimiento.altura',
];

export const LocalizacionPicker = ({
  onChange,
  value,
}: ModalFormComponentProps<string | null>) => {
  const [filter, setFilter] = useState<Filter>({
    search: '',
    idDistritoEscolar: '',
    idDependenciaFuncional: '',
  });

  const processedFilter = useMemo(
    () => ({
      search: !filter.search
        ? []
        : searchFilters.map((path) => ({
            path,
            value: filter.search,
            method: 'includes',
          })),
      distrito: !filter.idDistritoEscolar
        ? []
        : [
            {
              path: 'establecimiento.distritoEscolar',
              value: filter.idDistritoEscolar,
            },
          ],
      dependencia: !filter.idDependenciaFuncional
        ? []
        : [
            {
              path: 'establecimiento.dependenciaFuncional',
              value: filter.idDependenciaFuncional,
            },
          ],
    }),
    [filter],
  );

  const { orderBy, onSortChange, direction } =
    useTableSortConfig('descripcion');
  const {
    data = [],
    working,
    fetchMore,
  } = useLocalizacionLibbyFetch({
    orderBy,
    direction,
    filter: processedFilter,
  });

  const onItemSelect = useCallback(
    (event) => {
      onChange(event.target.value);
    },
    [onChange],
  );

  const columns = useLocalizacionPickerColumns(value, onItemSelect);

  return (
    <Grid container spacing={SPACING} direction="column">
      <Grid item>
        <SelectedValue id={value} onUnselectClick={() => onChange('')} />
      </Grid>
      <Grid item>
        <LocalizacionPickerTableHeader
          filter={filter}
          onChangeFilter={setFilter}
        />
        <InfoTable
          rows={data}
          columns={columns}
          working={working}
          rowIdKey="idRolEstablecimiento"
          direction={direction}
          orderBy={orderBy}
          onSortChange={onSortChange}
          onBottomScroll={fetchMore}
        />
      </Grid>
    </Grid>
  );
};
