import { DevolucionEvaluacion } from 'src/app/models/acap';
import { makeBusinessEntity } from '../../../lib/libby';
import { DevolucionesEvaluacionesDAO } from 'src/platform/libby/dao';

const DAO_NAME = 'devoluciones_evaluaciones';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
} = makeBusinessEntity<DevolucionEvaluacion, DevolucionesEvaluacionesDAO>(
  DAO_NAME,
  {},
);

// hooks
export const useDevolucionesEvaluacionesFetchById = fetchById;
export const useDevolucionesEvaluacionesLibbyCall = libbyCall;
export const useDevolucionesEvaluacionesDAO = libbyDAO;
export const useDevolucionesEvaluacionesLibbyFetch = libbyFetch;
