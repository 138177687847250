import React from 'react';
import { Typography, Grid, Chip } from '@material-ui/core';
import { InformacionGeneralPlan } from 'src/app/models';
import { useSeccionContext } from 'src/app/business';
import { makeStyles } from '@material-ui/styles';
import { grey } from 'src/theme/colors';
import { useParams } from 'react-router';
import { useGruposSeccion } from '../../../hooks';

const useStyles = makeStyles(() => ({
  textField: {
    fontSize: 14,
    color: grey.textPrimary,
    fontWeight: 600,
  },
  valueField: {
    fontSize: 16,
    color: grey.textPrimary,
    fontWeight: 400,
    marginTop: 8,
    marginBottom: 20,
  },
  containerSeccion: {
    marginTop: 8,
    marginBottom: 20,
  },
}));

export interface InformacionGeneralProps {
  data: InformacionGeneralPlan;
}
export const InformacionGeneral = ({ data }: InformacionGeneralProps) => {
  const classes = useStyles();
  const { idEspacioCurricular } = useParams();
  const { data: secciones = [], working: sWorking } = useSeccionContext();
  const { transformeGrupos, working } = useGruposSeccion([idEspacioCurricular]);

  const filterSecciones = [...(secciones || []), ...transformeGrupos].filter(
    (item) => data.secciones.find((_item) => item.idSeccion === _item),
  );

  return (
    <Grid container direction="column">
      <Typography className={classes.textField}>Sección</Typography>
      {!working &&
        !sWorking &&
        filterSecciones?.map((item: any) => (
          <Grid container direction="row" className={classes.containerSeccion}>
            <Chip size="small" label={item.nombreSeccion} color="secondary" />
          </Grid>
        ))}
      <Typography className={classes.textField}>Tema</Typography>
      <Typography className={classes.valueField}>{data?.tema}</Typography>
      <Typography className={classes.textField}>Problema</Typography>
      <Typography className={classes.valueField}>{data?.problema}</Typography>
      <Typography className={classes.textField}>
        Preguntas adicionales
      </Typography>
      <Typography className={classes.valueField}>
        {data?.preguntasAdicionales}
      </Typography>
    </Grid>
  );
};
