import React, {
  useMemo,
  SetStateAction,
  Dispatch,
  useState,
  createContext,
  useContext,
  useEffect,
  useCallback,
} from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useReportsQueryGetRequest } from 'src/app/business/businessCustomEndpoints/reports';
import { HeaderTable, Loading, MainButton, ROL } from 'src/commons';
import InternalSelects from '../InternalSelects';
import TableFactory from '../TableFactory';
import { FILTERS } from '../table_filters';
import { SelectReportList } from '../components/SelectReportList';
import _ from 'lodash';
import { useRolesContext } from 'src/context/RolesContext';
import { useReportDAO } from 'src/app/business/Report';
import { DatabaseConnector } from '@phinxlab/libby-core';
import {
  GlobalFilters,
  InternalFilters,
  ReportsContext,
} from './ReportContext';
import HeaderButtons from '../components/HeaderButtons';
import { Loader } from '../components/ReportLoader';
import { usePollingReport } from '../hooks/usePollingReport';
import moment from 'moment';

const ReportsProvider = ({
  reportCategory,
  libby,
}: {
  reportCategory: string;
  libby: any;
}) => {
  const { selectedRole } = useRolesContext();
  const initialInternalFilters = useMemo(
    (): InternalFilters => ({
      matriculacion: {},
      calificaciones: {},
      boletines: {},
      presentismo: {},
      proyeccion: {},
    }),
    [],
  );

  const initialGlobalFilter = useMemo(
    (): GlobalFilters => ({
      [FILTERS.nivel]:
        !!selectedRole.nivel &&
        ![ROL.NO_ROL, ''].includes(Number(selectedRole.rol))
          ? JSON.stringify({ id: selectedRole.nivel })
          : '',
      [FILTERS.distrito_escolar]: '',
      [FILTERS.dependencia_funcional]: '',
      [FILTERS.localizacion]: selectedRole.localizacionId,
      [FILTERS.ciclo]: '',
    }),
    [selectedRole],
  );

  const [fetchCount, setFetchCount] = useState(true);
  const [orderBy, setOrderBy] = useState<string>('');
  const [limit, setLimit] = useState<number>(10);
  const [offset, setOffset] = useState<number>(0);
  const [orderDirection, setOrderDirection] = useState<'asc' | 'desc'>('asc');
  const [currentReport, setCurrentReport] = useState<string>('');
  const [queryParams, setQueryParams] = useState<string>('');
  const [search, setSearch] = useState<string>('');
  const [internalFilters, setInternalFilters] = useState<InternalFilters>(
    initialInternalFilters,
  );
  const [globalFilters, setGlobalFilters] =
    useState<GlobalFilters>(initialGlobalFilter);
  const [fetchInmediatly, setFetchInmediatly] = useState<boolean>(false);

  const localizacion = globalFilters[FILTERS.localizacion];

  const url = useMemo(
    () => `/api/reportes/query?${queryParams}`,
    [queryParams],
  );
  const urlToCount = useMemo(() => {
    const params = new URLSearchParams(queryParams);
    if (params.has('orderBy')) params.delete('orderBy');
    if (params.has('orderDir')) params.delete('orderDir');
    if (params.has('limit')) params.delete('limit');
    if (params.has('offset')) params.delete('offset');
    if (!params.has('table')) params.set('table', currentReport);

    return `/api/reportes/query?${params.toString()}&count=d`;
  }, [queryParams, currentReport]);

  const {
    data: records = [],
    working,
    request,
    setResponse,
  } = useReportsQueryGetRequest({ url, autoCall: false });

  const {
    data: totalRows = [],
    request: countRequest,
    working: countWorking,
  } = useReportsQueryGetRequest({ url: urlToCount, autoCall: false });

  const filtersParam = useMemo(
    () => ({
      ...(globalFilters.hasOwnProperty(FILTERS.localizacion) &&
        !!localizacion && {
          idLocalizacion: localizacion,
        }),
      ...(globalFilters.hasOwnProperty(FILTERS.nivel) &&
        !!globalFilters[FILTERS.nivel] && {
          idNivel: JSON.parse(globalFilters[FILTERS.nivel])?.id,
        }),
      ...(globalFilters.hasOwnProperty(FILTERS.ciclo) &&
        !!globalFilters[FILTERS.ciclo] && {
          cicloLectivo: JSON.parse(globalFilters[FILTERS.ciclo])?.label,
        }),
      ...(internalFilters.hasOwnProperty(reportCategory) &&
        internalFilters[reportCategory].hasOwnProperty(FILTERS.anio) &&
        !!internalFilters[reportCategory][FILTERS.anio] && {
          año: JSON.parse(internalFilters[reportCategory][FILTERS.anio])?.label,
        }),
      ...(internalFilters.hasOwnProperty(reportCategory) &&
        internalFilters[reportCategory].hasOwnProperty(FILTERS.seccion) &&
        !!internalFilters[reportCategory][FILTERS.seccion] && {
          seccion: JSON.parse(internalFilters[reportCategory][FILTERS.seccion])
            ?.label,
        }),
      ...(internalFilters.hasOwnProperty(reportCategory) &&
        internalFilters[reportCategory].hasOwnProperty(FILTERS.turno) &&
        !!internalFilters[reportCategory][FILTERS.turno] && {
          turno: JSON.parse(internalFilters[reportCategory][FILTERS.turno])
            ?.label,
        }),
      ...(internalFilters.hasOwnProperty(reportCategory) &&
        internalFilters[reportCategory].hasOwnProperty(FILTERS.dni) &&
        !!internalFilters[reportCategory][FILTERS.dni] && {
          documento: JSON.parse(internalFilters[reportCategory][FILTERS.dni]),
        }),
      ...(internalFilters.hasOwnProperty(reportCategory) &&
        internalFilters[reportCategory].hasOwnProperty(FILTERS.edo_pase_anio) &&
        !!internalFilters[reportCategory][FILTERS.edo_pase_anio] && {
          estadoPaseAño: JSON.parse(
            internalFilters[reportCategory][FILTERS.edo_pase_anio],
          )?.label,
        }),
      ...(internalFilters.hasOwnProperty(reportCategory) &&
        internalFilters[reportCategory].hasOwnProperty(FILTERS.materia) &&
        !!internalFilters[reportCategory][FILTERS.materia] && {
          materia: JSON.parse(internalFilters[reportCategory][FILTERS.materia])
            ?.label,
        }),
      ...(internalFilters.hasOwnProperty(reportCategory) &&
        internalFilters[reportCategory].hasOwnProperty(FILTERS.periodo) &&
        !!internalFilters[reportCategory][FILTERS.periodo] && {
          periodo: JSON.parse(internalFilters[reportCategory][FILTERS.periodo])
            ?.label,
        }),
      ...(!!internalFilters[reportCategory][FILTERS.calificacion] && {
        calificacion: JSON.parse(
          internalFilters[reportCategory][FILTERS.calificacion],
        )?.label,
      }),
      ...(!!internalFilters[reportCategory][FILTERS.tipo_previa] && {
        tipoDeuda: JSON.parse(
          internalFilters[reportCategory][FILTERS.tipo_previa],
        )?.label,
      }),
    }),
    [globalFilters, internalFilters, reportCategory, localizacion],
  );

  useEffect(() => {
    if (localizacion === '') return;

    const queryParams = new URLSearchParams();
    queryParams.append('table', currentReport);
    queryParams.append('limit', `${limit}`);
    if (!!offset) queryParams.append('offset', '0');
    if (!!orderBy) queryParams.append('orderBy', orderBy);
    if (!!orderDirection && !!orderBy)
      queryParams.append('orderDir', orderDirection);

    for (const [key, value] of Object.entries(filtersParam)) {
      if (value) queryParams.append(key, value);
    }
    setQueryParams(queryParams.toString());
  }, [
    filtersParam,
    limit,
    offset,
    orderBy,
    currentReport,
    orderDirection,
    reportCategory,
    localizacion,
  ]);

  useEffect(() => {
    if (fetchInmediatly) {
      request();
      setFetchInmediatly(false);
    }
  }, [fetchInmediatly, request]);

  useEffect(() => {
    if (localizacion !== '' && fetchCount) countRequest();
    setFetchCount(true);
    setResponse([]);
    setOffset(0);
  }, [countRequest, globalFilters, setResponse, localizacion, fetchCount]);

  const onChange = useCallback(
    (e) => {
      const target = e.target;
      const value = target.value;
      setInternalFilters(initialInternalFilters);
      setGlobalFilters(initialGlobalFilter);
      setCurrentReport(value);
      setResponse([]);
      setSearch('');
    },
    [
      setInternalFilters,
      setGlobalFilters,
      setCurrentReport,
      setResponse,
      initialInternalFilters,
      initialGlobalFilter,
    ],
  );

  useEffect(() => {
    setResponse([]);
  }, [localizacion, setResponse]);

  const searchHandler = (e) => {
    if (
      Array.isArray(totalRows) &&
      totalRows.length > 0 &&
      Number(totalRows[0].count) === 0
    )
      return;
    request();
  };

  const { setDownloadLink, setReportId, setInPolling, inPolling } =
    usePollingReport(libby.session.user.id);

  const reportDAO = useReportDAO();
  const downloadHandler = async () => {
    setDownloadLink('');
    setInPolling(true);

    const reportRequested = await reportDAO.save({
      name: `${currentReport}-${moment().format('D-M-YY_HH-mm-ss')}`,
      query: {
        table: currentReport,
        limit: 0,
        ...filtersParam,
      },
      reportType: 1,
      createdBy: libby.session.user.id,
    });
    setReportId(reportRequested.reportId);
  };

  const showInternalSelects =
    ![ROL.NO_ROL, 0].includes(Number(selectedRole.rol)) ||
    Boolean(localizacion);

  const tableCount =
    Array.isArray(totalRows) && totalRows.length > 0
      ? Number(totalRows[0].count)
      : 0;

  const disableSearchBtn =
    !!!currentReport ||
    !!!globalFilters[FILTERS.nivel] ||
    working ||
    tableCount === 0 ||
    countWorking ||
    localizacion === '';

  const disableDownloadBtn = disableSearchBtn || inPolling || countWorking;

  return (
    <ReportsContext.Provider
      value={{
        currentReport,
        internalFilters,
        globalFilters,
        setCurrentReport,
        setInternalFilters,
        setGlobalFilters,
        reportCategory,
        setOrderDirection,
        setOrderBy,
        orderDirection,
        setLimit,
        limit,
        setOffset,
        offset,
        totalRows: totalRows.length > 0 ? totalRows[0]?.count : 0,
        request,
        queryParams,
        setQueryParams,
        setFetchInmediatly,
        setFetchCount,
        search,
        setSearch,
      }}
    >
      <HeaderTable
        title="Buscar"
        showCopy={false}
        renderLeft={() => (
          <SelectReportList
            reportCategory={reportCategory}
            onChange={onChange}
          />
        )}
        renderRight={() => (
          <HeaderButtons
            disabledSearch={disableSearchBtn}
            disabledDownload={disableDownloadBtn}
            searchHandler={searchHandler}
            downloadHandler={downloadHandler}
            hasRecords={
              tableCount > 0 && !disableSearchBtn && records.length === 0
            }
          />
        )}
      >
        {showInternalSelects && (
          <Grid container justify="flex-start" spacing={2} alignItems="center">
            <InternalSelects
              working={countWorking}
              reportCategory={reportCategory}
            />
          </Grid>
        )}
      </HeaderTable>
      {inPolling && <Loader />}
      <TableFactory
        totalRows={tableCount}
        rows={records}
        reportCategory={reportCategory}
      />
      {(working || countWorking) && <Loading />}
    </ReportsContext.Provider>
  );
};

export default DatabaseConnector(ReportsProvider)();
