import React from 'react';
import { Grid, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MainButton } from '../MainButton';
import { primary } from '../../../theme/colors';
import { Loading } from '../Loading';
import { Link } from 'react-router-dom';
import { useRolesContext } from 'src/context/RolesContext';

export interface ButtonTypesProps {
  title: React.ReactNode;
  disabled?: boolean;
  typeButton?: string;
  size?: string;
  handleOnClick?: () => void;
  icon?: JSX.Element;
  type?: string;
  customStyle?: any;
  customStyleIcon?: any;
  isLink?: boolean;
  roles?: number[];
  tooltip?: string;
  hidden?: boolean;
}

interface FooterTypesProps {
  buttonConfig: Array<ButtonTypesProps>;
  position?: any;
  display?: string;
  gridAutoFlow?: string;
  gridTemplateColumns?: string;
  spacing?: any;
  customStyle?: any;
  loading?: boolean;
  path?: string;
}

export const Footer = ({
  buttonConfig,
  position = 'flex-end',
  spacing = 4,
  display,
  gridAutoFlow,
  gridTemplateColumns,
  customStyle,
  loading,
  path,
}: FooterTypesProps) => {
  const footerStyles = makeStyles(() => ({
    containerFooter: {
      background: primary.white,
      padding: '10px 20px',
      boxShadow: '0px -7px 3px rgba(0, 0, 0, 0.1);',
      ...customStyle,
    },
  }));
  const classes = footerStyles();
  const {
    selectedRole: { rol },
  } = useRolesContext();

  return (
    <Grid container className={classes.containerFooter} spacing={0}>
      <Grid item xs={12}>
        <Grid
          container
          justify={position}
          spacing={spacing}
          style={{
            ...(display && { display }),
            ...(gridAutoFlow && { gridAutoFlow }),
            ...(gridTemplateColumns && { gridTemplateColumns }),
          }}
        >
          {loading ? (
            <Grid item>
              <Loading />
            </Grid>
          ) : (
            buttonConfig
              .filter((b) => !b.hidden)
              .filter(
                (button) =>
                  ('roles' in button && button.roles!.includes(Number(rol))) ||
                  !('roles' in button),
              )
              .map((value: ButtonTypesProps, index: number) => (
                <Grid key={index} item>
                  {!value.isLink ? (
                    Boolean(value.tooltip) ? (
                      <MainButton
                        title={value.title}
                        typeButton={value.typeButton}
                        size={value.size}
                        type={value.type}
                        handleOnClick={value.handleOnClick}
                        disabled={value.disabled}
                        customStyle={value.customStyle}
                        customStyleIcon={value.customStyleIcon}
                        tooltip={value.tooltip}
                      />
                    ) : (
                      <MainButton
                        title={value.title}
                        typeButton={value.typeButton}
                        size={value.size}
                        type={value.type}
                        handleOnClick={value.handleOnClick}
                        disabled={value.disabled}
                        customStyle={value.customStyle}
                        customStyleIcon={value.customStyleIcon}
                      />
                    )
                  ) : (
                    <Link style={{ textDecoration: 'none' }} to={path}>
                      <MainButton
                        title={value.title}
                        typeButton={value.typeButton}
                        size={value.size}
                        type={value.type}
                        disabled={value.disabled}
                        customStyle={value.customStyle}
                        customStyleIcon={value.customStyleIcon}
                      />
                    </Link>
                  )}
                </Grid>
              ))
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
