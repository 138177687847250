import React, { FC } from 'react';
import {
  Grid,
  FormControlLabel,
  Checkbox,
  ListItemIcon,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  Typography,
  createStyles,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormMultiCheckboxInput,
  FormMultiCheckbox,
} from 'src/lib/templates/components';
import { TextField } from 'src/app/components/inputs';
import { grey } from 'src/theme/colors';
import { CheckboxListItem } from '../../../commons/components/CheckboxListItem';
import { usePlanificationFormDisciplinarOrientada } from '../../hooks';
import { useDisciplinarOrientadaContext } from '../../Context';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: '32px 10px',
      marginBottom: 60,
    },
    listItem: {
      padding: 0,
    },
    listItemIcon: {
      marginRight: '-20px',
    },
    text: {
      color: grey.medium,
    },
  }),
);

export const ObjetivosAprendizaje: FC = () => {
  const classes = useStyles();
  const { form, errors, values } = usePlanificationFormDisciplinarOrientada();
  const { data, working } = useDisciplinarOrientadaContext();
  const buttonCustomStyle = { margin: '10px 0px 25px 0' };
  const bloques = values?.bloque.bloques;
  const select = bloques.map((bloque: any) => data.objetivos[bloque]);

  // TODO: Rename FormMultiCheckbox and refactor checkbox list
  return (
    <Grid container direction="column" className={classes.container}>
      {select.map((obj: any, indexs: number) => {
        return (
          <>
            <FormMultiCheckbox
              label={`Bloque: ${obj.bloque.descripcion}`}
              name={`objetivosDeAprendizaje.objetivosDeAprendizaje[${indexs}].objetivos`}
              // @ts-ignore
              options={obj.values}
              form={form}
              idKey="idObjetivoBloque"
            />
            <FormMultiCheckboxInput
              key={indexs}
              label=""
              name={`objetivosDeAprendizaje.objetivosDeAprendizaje[${indexs}].adicionales`}
              form={form}
              addButtonText="Agregar Objetivo"
              buttonCustomStyle={buttonCustomStyle}
              getNewElement={(value) => ({
                id: -1 - 1 * value.filter((item: any) => item.id < 0).length, // use negative id to validate new items
                checked: false,
                descripcion: '',
              })}
            >
              {(value, input, meta, index) => (
                <CheckboxListItem
                  inputPlaceholder="Nuevo objetivo"
                  value={value}
                  input={input}
                  meta={meta}
                  index={index}
                />
              )}
            </FormMultiCheckboxInput>
          </>
        );
      })}
    </Grid>
  );
};
