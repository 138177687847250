import React, { useMemo, useContext, useState } from 'react';
import { LibbyFetchOptions } from 'src/lib/libby';
import { RecuperacionFactory } from '../../CalificacionesSecundario/factories/RecuperacionFactory';
import { FACTORIES_TYPES } from '../helpers/factories';
import { PPICalificacionFactory } from '../../CalificacionesSecundario/factories/PPICalificacionFactory';
import { CalificacionDefinitivaFactory } from '../../CalificacionesSecundario/factories/CalificacionDefinitivaFactory';

type CalificacionesSecundarioContextType<T = RecuperacionFactory> = {
  factory: T;
  periodo: string;
};

const CalificacionesSecundarioContext =
  React.createContext<CalificacionesSecundarioContextType>({
    factory: new RecuperacionFactory(),
    periodo: '',
  });

export const useCalificacionesSecundarioContext = function <T>() {
  return useContext<CalificacionesSecundarioContextType<T>>(
    CalificacionesSecundarioContext,
  );
};
export const CalificacionesSecundarioProvider = ({
  children,
  periodo,
}: {
  children: React.ReactNode;
  periodo: string;
}) => {
  const factory = useMemo(() => {
    switch (Number(periodo)) {
      case FACTORIES_TYPES.CUARTO_BIMESTRE:
        return new CalificacionDefinitivaFactory();
      case FACTORIES_TYPES.RECUPERACION:
        return new RecuperacionFactory();
      default:
        return new PPICalificacionFactory();
    }
  }, [periodo]);

  return (
    <CalificacionesSecundarioContext.Provider value={{ factory, periodo }}>
      {children}
    </CalificacionesSecundarioContext.Provider>
  );
};
