type typeOfPassType = {
  label: string;
  value: string;
};

export const typeOfPass: typeOfPassType[] = [
  {
    label: 'Entrada',
    value: 'entrada',
  },
  {
    label: 'Salida',
    value: 'salida',
  },
];
