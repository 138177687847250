import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  titleContainer: {
    marginTop: '24px',
    paddingBottom: '0 !important',
  },
  title: {
    color: '#595959',
    fontSize: '20px',
  },
  trashIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  whiteSpace: {
    height: 75,
  },
  secondaryContainer: {
    background: '#F5F5F5',
    padding: theme.spacing(2),
  },
  secondaryContainerAtencion: {
    width: '95%',
    padding: theme.spacing(2),
  },
  datePicker: {
    paddingLeft: 0,
    padding: 0,
  },
  deleteIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
    '& > path': {
      fill: '#494949',
    },
  },
  secondaryContainerTitle: {
    background: '#F5F5F5',
    paddingBottom: theme.spacing(2),
  },
  headerGrayContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
    '& > span': {
      fontSize: '20px',
      fontFamily: 'Open Sans',
      color: '#595959',
    },
  },
  divider: {
    width: '100%',
    margin: '20px 0px 15px 0px',
  },
  containerButton: {
    background: '#F5F5F5',
    padding: theme.spacing(2),
    paddingBottom: 20,
    paddingTop: 30,
  },
  containerButtonAtencion: {
    marginTop: theme.spacing(4),
  },
  file: {
    width: '80%',
    border: '1px solid #cfcfcf',
    padding: '0em 2em',
    borderRadius: '5px',
    height: '3.5em',
    backgroundColor: 'white',
  },
  fileLabel: {
    paddingRight: '2em',
    fontSize: '15px',
    width: '100%',
    textAlign: 'left',
  },
  fileDeleteIcon: {
    '&:hover': {
      color: '#e29191',
    },
  },
  label: {
    marginBottom: 5,
    fontSize: 13,
    fontWeight: 600,
    color: '#7b7b7b',
  },
  fileInput: {
    color: 'blue',
  },
}));
