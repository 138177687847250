import { PropuestaJE } from 'src/app/models/presentismo_v2';
import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class PropuestaJEDiaDAO extends LibbyFetchDAO {
  constructor() {
    super('propuesta_je_dia', 'idPropuestaJeDia');
  }

  getByPropuesta(propuesta: PropuestaJE) {
    return this.aspect('saving')
      .query()
      .equals('propuesta', propuesta.idPropuestaJe)
      .run();
  }
}
