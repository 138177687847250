import React from 'react';
import { Typography, Grid, Chip, makeStyles } from '@material-ui/core';
import {
  InstrumentosDeEvaluacion,
  Recursos,
  Habilidades,
  BimestreOrientada,
  BimestreOrientPlan,
} from 'src/app/models';
import { grey } from 'src/theme/colors';
import { ListItemProperties } from '../../commons';
import { useBimestreLogic } from '../hooks/useBimestreLogic';

const useStyles = makeStyles(() => ({
  textField: {
    fontSize: 14,
    color: grey.textPrimary,
    fontWeight: 600,
  },
  valueField: {
    fontSize: 16,
    color: grey.textPrimary,
    fontWeight: 400,
    marginTop: 8,
    marginBottom: 20,
  },
  containerSeccion: {
    marginTop: 8,
    marginBottom: 20,
  },
}));
export interface BimestreProps {
  prefix: string;
  data: BimestreOrientada;
}

export type SubLevel2 = {
  id: number;
  descripcion: string;
};
export type ContenidosOrientada = {
  id: number;
  descripcion: string;
  subLevel: { id: number; descripcion: string; subLevel2: SubLevel2[] }[];
};

export const Bimestre = ({ prefix, data }: BimestreProps) => {
  const classes = useStyles();
  const { getfilters, loading } = useBimestreLogic();

  const callbackFilterSeccion = (item: any) => (
    <Grid container direction="row" className={classes.containerSeccion}>
      <Chip size="small" label={item?.nombreSeccion} color="secondary" />
    </Grid>
  );

  return (
    <Grid>
      {!loading &&
        data[prefix]?.map((bimestre: BimestreOrientPlan, index: number) => {
          const {
            filterContenidos,
            filterHabilidades,
            filterInstrumentos,
            filterRecursos,
            filterSecciones,
            filterBloque,
          } = getfilters(bimestre);
          return (
            <Grid container direction="column">
              {!loading && (
                <Grid key={index}>
                  <ListItemProperties title="Bloque" values={filterBloque} />
                  <ListItemProperties
                    title="Habilidades"
                    values={filterHabilidades}
                  />
                  <ListItemProperties
                    title="Contenidos Específicos"
                    values={filterContenidos}
                  />
                  <ListItemProperties
                    title="Recursos"
                    values={filterRecursos}
                  />
                  <ListItemProperties
                    title="Instrumentos de evaluacion"
                    values={filterInstrumentos}
                  />
                  <Typography className={classes.textField}>
                    Actividad
                  </Typography>
                  <Typography className={classes.valueField}>
                    {bimestre?.actividad}
                  </Typography>
                  <Typography className={classes.textField}>
                    Descripción
                  </Typography>
                  <Typography className={classes.valueField}>
                    {bimestre?.descripcion}
                  </Typography>
                  <Typography className={classes.textField}>
                    Horas cátedra
                  </Typography>
                  <Typography className={classes.valueField}>
                    {bimestre?.horasCatedra}
                  </Typography>
                  <Typography className={classes.textField}>Sección</Typography>
                  {!loading && filterSecciones.map(callbackFilterSeccion)}
                </Grid>
              )}
            </Grid>
          );
        })}
    </Grid>
  );
};
