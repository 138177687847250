import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { grey } from 'src/theme/colors';
import {
  MainInput,
  SimpleSelect,
  typeEstablishment,
  level,
  ROL,
} from 'src/commons';
import {
  AnioSelect,
  useLocalizacionPlanEstudioNivelLibbyFetch,
} from 'src/app/business';
import { useRolesContext } from 'src/context/RolesContext';
import { LocalizacionPlanEstudioNivel } from 'src/app/models';
import { useFiltersLogic } from '../hooks';
import { JoinedFilters } from '../types';

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  inputStyle: { padding: 10, marginTop: 23 },
};

export interface FilterProps {
  filter: JoinedFilters;
  onChangeFilter: Dispatch<SetStateAction<JoinedFilters>>;
  disabled: boolean;
}

const { SCHOOLD_TECHNIQUE } = typeEstablishment;

export const FilterEspacioCurricular = ({
  filter,
  onChangeFilter,
  disabled,
}: FilterProps) => {
  const { selectedRole } = useRolesContext();
  const {
    search,
    onSearchChange,
    idAnio,
    onAnioChange,
    idPlanEstudio,
    onPlanEstudioChange,
  } = useFiltersLogic({ filter, onChangeFilter });

  const filterAnio = useMemo(
    () => ({
      0: [{ path: 'nivel', value: selectedRole.nivel }],
      1:
        selectedRole.tipoEstablecimiento === SCHOOLD_TECHNIQUE ||
        Number(selectedRole.nivel) === level.PRIMARIO ||
        Number(selectedRole.nivel) === level.INICIAL
          ? {}
          : [{ path: 'numeroAnio', value: 6, method: 'notEquals' }],
    }),
    [selectedRole],
  );

  const filterPlanEstudio = useMemo(
    () => ({
      0: [
        {
          path: 'planEstudioNivel.modalidadNivel.nivel',
          value: selectedRole.nivel,
        },
      ],
      1: [{ path: 'localizacion', value: selectedRole?.localizacionId }],
    }),
    [selectedRole],
  );

  const { data: plaEstudioNivel } = useLocalizacionPlanEstudioNivelLibbyFetch({
    filter: filterPlanEstudio,
    aspect: 'espacios_curriculares',
  });

  const flatten = () =>
    plaEstudioNivel?.map((data: LocalizacionPlanEstudioNivel) => ({
      ...data,
      ...data.planEstudioNivel,
      ...data.planEstudioNivel.planEstudio,
    }));
  return (
    <Grid
      container
      justify="flex-start"
      alignItems="center"
      spacing={3}
      style={{ marginTop: 20 }}
    >
      <Grid item xs={2}>
        <AnioSelect
          handleChange={onAnioChange}
          title="Año"
          valueKey="idAnio"
          labelKey="descripcionAnio"
          placeholder="Seleccioná Año"
          value={idAnio}
          orderBy="numeroAnio,descripcionAnio"
          name="idAnio"
          filter={filterAnio}
          customStyleTitle={customStyles.customStyleTitleSelect}
          disabled={disabled}
        />
      </Grid>
      <Grid item xs={2}>
        <SimpleSelect
          title="Plan de estudios"
          placeholder="Seleccioná Plan"
          labelKey="descripcionPlanEstudio"
          valueKey="idPlanEstudio"
          content={flatten() || []}
          handleChange={(e) => {
            const pelopa = e as React.ChangeEvent<HTMLInputElement>;
            onPlanEstudioChange(pelopa);
          }}
          value={idPlanEstudio}
          name="espacioCurricular.planEstudio"
          customStyleTitle={customStyles.customStyleTitleSelect}
          disabled={disabled}
        />
      </Grid>
      <Grid container item xs={8} justify="flex-end">
        <Grid item xs={4}>
          <MainInput
            type="search"
            handleChange={onSearchChange}
            placeholder="Buscar"
            name="search"
            value={search}
            fullWidth
            customStyle={customStyles.inputStyle}
            disabled={disabled}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
