import {
  useRequest,
  LibbyRequestOptions,
  LibbyRequestReturn,
} from './useLibbyRequest';

export const makeEntityLibbyRequest =
  (daoName: string, type: string) =>
  <T>(
    options?: Omit<LibbyRequestOptions, 'daoName' | 'type'>,
  ): LibbyRequestReturn<T> =>
    useRequest({
      daoName,
      type,
      ...options,
    });
