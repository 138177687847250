import { ContenidoNodo, IBloque } from '../types';

export const rebuildBloques = (nodos: ContenidoNodo[]) => {
  const bloques: IBloque[] = [];

  nodos.forEach((row) => {
    const idNodo = row.idNodo;
    const bloque = row.bloque;
    const eje = row.eje;
    const unidad = row.unidad;
    const contenido = row.contenido;
    const devolucion = row.devolucion;

    // Buscamos el bloque correspondiente en la estructura de `bloques`
    let bloqueExistente = bloques.find((item) => {
      return item.idBloque === bloque.idBloque;
    });

    if (!bloqueExistente) {
      // Si el bloque no existe, lo creamos con sus propiedades y el array de ejes
      bloqueExistente = {
        ...bloque,
        ejes: [],
      };
      bloques.push(bloqueExistente);
    }

    // Buscamos el eje correspondiente en la estructura del bloque
    let ejeExistente = bloqueExistente.ejes.find((item) => {
      return item.idEje === eje.idEje;
    });

    if (!ejeExistente) {
      // Si el eje no existe, lo creamos con su propiedad y el array de unidades
      ejeExistente = {
        ...eje,
        unidades: [],
      };
      bloqueExistente.ejes.push(ejeExistente);
    }

    // Buscamos la unidad correspondiente en la estructura del eje
    let unidadExistente = ejeExistente.unidades.find((item) => {
      return item.idUnidad === unidad.idUnidad;
    });

    if (!unidadExistente) {
      // Si la unidad no existe, la creamos con su propiedad y el array de contenidos
      unidadExistente = {
        ...unidad,
        contenidos: [],
      };
      ejeExistente.unidades.push(unidadExistente);
    }

    // Agregamos el contenido al array de contenidos de la unidad
    unidadExistente.contenidos.push({ ...contenido, idNodo, devolucion });
  });

  return bloques;
};
