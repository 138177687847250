import React, { useState, useEffect, useMemo, useCallback } from 'react';
import {
  Grid,
  Button,
  makeStyles,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { Footer } from 'src/commons';
import customFormDialog from 'src/commons/services/customFormDialog';

export interface MultipleOptionsModalProps {
  index: number;
  options: any[];
  changeRow: any;
  name: string;
  rows: any[];
  title: string;
  isInitial?: boolean;
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    marginTop: theme.spacing(-3),
    marginRight: theme.spacing(1),
    position: 'relative',
  },

  item: {
    padding: 5,
  },
  items: {
    marginLeft: 40,
    marginBottom: 40,
    display: 'flex',
    flexFlow: 'column',
  },
  title: {
    marginLeft: 40,
    marginBottom: 20,
  },
}));

const handleCloseModal = async () => {
  await customFormDialog.handleCancel();
};

export const MultipleOptionsModal = ({
  index,
  options,
  changeRow,
  name,
  rows,
  title,
  isInitial,
}: MultipleOptionsModalProps) => {
  const classes = useStyles();
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const row = useMemo(() => rows[index], [rows, index]);

  useEffect(() => {
    if (row.nota.data[name]) {
      setSelectedOptions(row.nota.data[name]);
    }
  }, [row, name]);

  const handleChange = useCallback(
    (e: any, value: any) => {
      if (isInitial) {
        if (value && !selectedOptions.includes(e.target.name)) {
          if (selectedOptions.length < 2) {
            setSelectedOptions([...selectedOptions, e.target.name]);
          }
        } else {
          const selectedOptionsCopy = [...selectedOptions];
          selectedOptionsCopy.splice(
            selectedOptionsCopy.indexOf(e.target.name),
            1,
          );
          setSelectedOptions([...selectedOptionsCopy]);
        }
      } else {
        if (value && !selectedOptions.includes(e.target.name)) {
          setSelectedOptions([...selectedOptions, e.target.name]);
        } else {
          const selectedOptionsCopy = [...selectedOptions];
          selectedOptionsCopy.splice(
            selectedOptionsCopy.indexOf(e.target.name),
            1,
          );
          setSelectedOptions([...selectedOptionsCopy]);
        }
      }
    },
    [selectedOptions, isInitial],
  );

  const valueRow = useMemo(() => {
    if (selectedOptions.length === 0) {
      return { name: name, value: undefined };
    } else {
      return { name: name, value: selectedOptions };
    }
  }, [selectedOptions, name]);

  const buttonConfig: any = useMemo(() => {
    return [
      {
        title: 'Salir',
        size: 'medium',
        type: 'secondary',
        handleOnClick: handleCloseModal,
      },
      {
        title: 'Guardar',
        size: 'medium',
        handleOnClick: () => {
          changeRow({ target: valueRow }, index);
          handleCloseModal();
        },
      },
    ];
  }, [changeRow, index, valueRow]);

  return (
    <Grid container>
      <Grid container justify="flex-end">
        <Button className={classes.closeButton} onClick={handleCloseModal}>
          X
        </Button>
      </Grid>
      <Grid container>
        <Grid xs={12} className={classes.title}>
          <Typography>{title}</Typography>
        </Grid>
        <Grid className={classes.items}>
          {options.map((option) => (
            <FormControlLabel
              key={option}
              control={
                <Checkbox
                  checked={selectedOptions.includes(option)}
                  onChange={handleChange}
                  name={option}
                  color="primary"
                />
              }
              label={option}
            />
          ))}
        </Grid>
      </Grid>
      <Footer buttonConfig={buttonConfig} />
    </Grid>
  );
};
