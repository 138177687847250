import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { OfertasDAO } from '../../../platform/libby/dao';
import { Ofertas } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'ofertas';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idOfertas',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idOfertas',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Ofertas
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Ofertas,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Ofertas.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    accordionSelector,
    selectedChips,
    selectInput,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Ofertas, OfertasDAO>(DAO_NAME, optionsToUse);

// hooks
export const useOfertasFetchById = fetchById;
export const useOfertasLibbyCall = libbyCall;
export const useOfertasDAO = libbyDAO;
export const useOfertasLibbyFetch = libbyFetch;

// components/hoc
export const OfertasAutocomplete = entityAutocomplete;
export const OfertasCall = renderPropCall;
export const OfertasById = renderPropFetchById;
export const OfertasSelect = selectInput;
export const OfertasAccordionSelector = accordionSelector;
export const OfertasSelectedChips = selectedChips;

// context
// ByIdContext
export const useOfertasByIdContext = useByIdContext;
export const OfertasByIdProvider = ByIdProvider;
export const OfertasByIdContext = ByIdContext;
// GeneralContext
export const useOfertasContext = useGeneralContext;
export const OfertasProvider = GeneralProvider;
export const OfertasContext = GeneralContext;
