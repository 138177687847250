/*
  Básado en la tabla anio
  ID   Descripción
  4  | 4° Grado
  5  | 5° Grado
  8  | 4° y 5° Grado
 
  6  | 6° Grado
  7  | 7° Grado
  9  | 6° y 7° Grado

  11 | 1 año / Deambulantes

  12 | 2 años
  16 | 1 y 2 años
  17 | 2 y 3 años

  13 | 3 años
  18 | 3 y 4 años

  14 | 4 años
  19 | 4 y 5 años

  15 | 5 años
*/

const anioRelacionesPromocionAcompaniada: Record<number, number[]> = {
  2: [1],
  3: [2],
  4: [8, 3],
  5: [8, 4],
  8: [4, 5, 3],
  6: [9, 5],
  7: [9, 6],
  9: [6, 7, 5],

  11: [16],
  12: [16, 17],
  16: [11, 12],

  13: [17, 18],
  17: [12, 13],

  14: [18, 19],
  18: [13, 14],

  15: [19],
  19: [14, 15],
};

// TODO: Esto debe ser agnóstico al back.
// Para ello implementar la api de constantes cuando esté disponible
const anioRelaciones: Record<number, number[]> = {
  4: [8],
  5: [8],
  8: [4, 5],

  6: [9],
  7: [9],
  9: [6, 7],

  // INICIAL
  10: [30], // Lactantes
  30: [10, 11], // Lactantes y 1 año

  11: [16, 30], // 1 año / Deambulantes
  12: [16, 17],
  16: [11, 12],

  13: [17, 18],
  17: [12, 13],

  14: [18, 19],
  18: [13, 14],

  15: [19],
  19: [14, 15],
};

// Permitir que los alumnos puedan cambiarse a secciones de su mismo anio o equivalentes
export const isValidChangeSectionYear = (
  idAnio: number, // ID Anio de la Seccion
  rowIdAnio: number, // ID Anio del la inscripcion
  hasProyeccionAcompaniada: boolean,
) => {
  const validation = !!(idAnio === rowIdAnio || hasProyeccionAcompaniada
    ? anioRelacionesPromocionAcompaniada[rowIdAnio]?.includes(idAnio)
    : anioRelaciones[rowIdAnio]?.includes(idAnio));

  return validation;
};
