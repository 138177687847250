import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { ArealPlanArea } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'areal_plan_area';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idArealPlanArea',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idArealPlanArea',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.ArealPlanArea
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.ArealPlanArea,
      hoc: { ...defaultOptions.hoc, ...businessOptions.ArealPlanArea.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<ArealPlanArea>(DAO_NAME, optionsToUse);

// hooks
export const useArealPlanAreaFetchById = fetchById;
export const useArealPlanAreaLibbyCall = libbyCall;
export const useArealPlanAreaDAO = libbyDAO;
export const useArealPlanAreaLibbyFetch = libbyFetch;

// components/hoc
export const ArealPlanAreaAutocomplete = entityAutocomplete;
export const ArealPlanAreaCall = renderPropCall;
export const ArealPlanAreaById = renderPropFetchById;
export const ArealPlanAreaSelect = simpleSelect;
export const ArealPlanAreaAccordionSelector = accordionSelector;
export const ArealPlanAreaSelectedChips = selectedChips;

// context
// ByIdContext
export const useArealPlanAreaByIdContext = useByIdContext;
export const ArealPlanAreaByIdProvider = ByIdProvider;
export const ArealPlanAreaByIdContext = ByIdContext;
// GeneralContext
export const useArealPlanAreaContext = useGeneralContext;
export const ArealPlanAreaProvider = GeneralProvider;
export const ArealPlanAreaContext = GeneralContext;
