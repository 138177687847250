import { primary } from 'src/theme/colors';
import { PERIODOS } from '../..';

export type CalificacionColumn = {
  id: string;
  label: string;
  width: string;
  orderById?: string;
  style: { [k: string]: string };
  hideSortIcon: boolean;
  noSort: boolean;
};

export const useCalificacionesSecundarioColumns = (periodo: string) => {
  const defaultColumns: Array<CalificacionColumn> = [
    {
      id: 'nombre',
      label: 'Alumno',
      width: '10%',
      orderById: 'nombre',
      style: {
        color: primary.lightBlue,
      },
      hideSortIcon: true,
      noSort: true,
    },
    {
      id: 'ppi',
      label: 'PPI',
      width: '2%',
      style: {
        color: primary.lightBlue,
      },
      hideSortIcon: true,
      noSort: true,
    },
    {
      id: 'valoracion',
      label: 'Valoración',
      width: '15%',
      orderById: 'valoracion',
      style: {
        color: primary.lightBlue,
      },
      hideSortIcon: true,
      noSort: true,
    },
    {
      id: 'calificacion',
      label: 'Calificación',
      orderById: 'calificacion',
      width: '5%',
      style: {
        color: primary.lightBlue,
      },
      hideSortIcon: true,
      noSort: true,
    },
  ];

  if (PERIODOS[periodo] === 'CUARTO_BIMESTRE') {
    defaultColumns.push({
      id: 'calificacionAnual',
      label: 'Calificación Anual',
      orderById: 'calificacionAnual',
      width: '5%',
      style: {
        color: primary.lightBlue,
      },
      hideSortIcon: true,
      noSort: true,
    });
    defaultColumns.push({
      id: 'anualDefinitiva',
      label: 'Calificación Definitiva',
      orderById: 'anualDefinitiva',
      width: '5%',
      style: {
        color: primary.lightBlue,
      },
      hideSortIcon: true,
      noSort: true,
    });
  }

  return defaultColumns;
};
