import { ROL } from '../../../../../../../../commons';
import {
  depenFuncInsertTypeMap,
  depenFuncNivelMap,
} from '../AddRolFormItem/RolFormFilters/filtersByDepFuncConsts';
import { escalafonMap } from './escalafonMap';
import { regionMap } from './regionMap';

export interface Values {
  dependenciaFuncional: 10 | 31 | 20 | 50 | 32 | 41 | 70;
  rol: number;
  distrito?: any[];
  nivel?: number;
  region?: 'I' | 'II' | 'III' | 'IV' | 'V' | 'VI' | 'VII' | 'VIII';
  escalafon?: 'A' | 'B' | 'C';
}

export const makeUser = (values: Values) => {
  const { dependenciaFuncional, rol, distrito, nivel, escalafon, region } =
    values;
  if (rol === ROL.EQUIPO_ACAP) {
    return {
      insertType: 'equipoACAP',
      user: {},
    };
  }
  if (rol === 10) {
    return {
      insertType: 'direccionArea',
      user: {
        DF: [[dependenciaFuncional, depenFuncNivelMap[dependenciaFuncional]]],
      },
    };
  }
  if (rol === 11) {
    const insertType = depenFuncInsertTypeMap[dependenciaFuncional];
    switch (insertType) {
      case 'supInicMedPri':
      case 'supArt':
        return {
          insertType,
          user: {
            DE: distrito ? distrito.map((d) => d.idDistritoEscolar) : null,
            DF: dependenciaFuncional,
            nivel: depenFuncNivelMap[dependenciaFuncional],
          },
        };
      case 'supTecEspNorm':
        if (dependenciaFuncional === 32 && !!region) {
          // Tecnicas
          return {
            insertType,
            user: {
              nivel: 3,
              localizaciones: regionMap[region],
              DF: dependenciaFuncional,
            },
          };
        }
        if (dependenciaFuncional === 50 && !!escalafon) {
          // Especiales
          return {
            insertType,
            user: {
              nivel: 3,
              localizaciones: escalafonMap[escalafon],
              DF: dependenciaFuncional,
            },
          };
        }
        if (dependenciaFuncional === 41) {
          // Normales
          return {
            insertType,
            user: {
              nivel,
              localizaciones: null,
              DF: dependenciaFuncional,
            },
          };
        }
        break;
      default:
        return null;
    }
  }
};
