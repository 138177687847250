import React from 'react';
import { useParams } from 'react-router';
import { EspaciosInterdiscipPlanById } from 'src/app/business';
import {
  useBackButton,
  useRouteScreenTitle,
} from '../../../../../../../commons/hooks';
import { InterdisciplinarPreviewFlow } from './InterdisciplinarPreviewFlow';
import { Loading } from '../../../../../../../commons/components/Loading';
import { useHistory } from 'react-router-dom';
import { useRouterContext } from 'src/lib/router/RouterContext';

export const InterdisciplinarPreview = ({
  idPlan,
  setReadyToPrint,
}: {
  idPlan?: number;
  setReadyToPrint?: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  // Title
  const {
    metadata: { title },
  } = useRouterContext();
  useRouteScreenTitle(
    idPlan ? title : 'Planificación Interdisciplinar Vista Previa',
  );

  const history = useHistory();
  const idx = history.location.pathname.indexOf('/planificacionpreview');
  useBackButton(history.location.pathname.slice(0, idx));
  // @ts-ignore
  const { idPlanificacion } = useParams();

  return (
    <EspaciosInterdiscipPlanById
      id={idPlan || idPlanificacion}
      aspect="limit_province"
    >
      {({ data, working }) =>
        working || !data ? (
          <Loading />
        ) : (
          <>
            <InterdisciplinarPreviewFlow data={data} />
            {setReadyToPrint && setReadyToPrint(true)}
          </>
        )
      }
    </EspaciosInterdiscipPlanById>
  );
};
