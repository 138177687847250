import React from 'react';
import {
  Typography,
  Grid,
  Chip,
  makeStyles,
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
} from '@material-ui/core';
import {
  IndicadoresDeEvaluacionPlan,
  IndicadoresPlan,
  Adicionales,
} from 'src/app/models';
import {
  periodos,
  Periodo,
} from '../../../forms/ArealInterareal/tabs/Actividad';
import { ListItemProperties } from '../../commons';
import { useInterdisciplinarContext } from '../../../forms/commons/components';
import { useDisciplinarComunContext } from '../../../forms/PlanificacionDisciplinarComun/components/context';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));
export interface IndicadoresDeEvaluacionProps {
  data: IndicadoresPlan;
}

export const IndicadoresDeEvaluacion = ({
  data,
}: IndicadoresDeEvaluacionProps) => {
  // const classes = useStyles();
  const {
    data: { indicadores },
    working,
  } = useDisciplinarComunContext();

  const filterIndicadores = data?.indicadoresDeEvaluacion?.map(
    (indicador: string) => {
      const valor = indicadores.find(
        (_indicador: any) => _indicador.idIndicadoresDeEvaluacion === indicador,
      );
      return {
        id: valor?.idIndicadoresDeEvaluacion || 1,
        descripcion: valor?.descripcion || '',
      };
    },
  );
  filterIndicadores.push(...data.adicionales);
  return (
    <Grid container direction="column">
      {!working && (
        <Grid>
          <ListItemProperties values={filterIndicadores} />
        </Grid>
      )}
    </Grid>
  );
};
