import { boolean } from 'joi';
import { LibbyCallReturn, makeEntityLibbyCall } from '../hooks';

export interface WithRenderPropCallProps<T> {
  methodName: string;
  params?: any[];
  aspect?: string;
  noAutoCall?: boolean;
  children: (result: LibbyCallReturn<T>) => JSX.Element;
}

export const withRenderPropCall = <T>(daoName: string) => {
  const useHook = makeEntityLibbyCall<T>(daoName);
  return ({
    methodName,
    params = [],
    aspect,
    noAutoCall = false,
    children,
  }: WithRenderPropCallProps<T>) => {
    const result = useHook({ methodName, params, aspect, noAutoCall });
    return children(result);
  };
};
