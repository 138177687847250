import { DevolucionPerfilesDAO } from 'src/platform/libby/dao';
import { makeBusinessEntity } from '../../../lib/libby';
import { DevolucionPerfil } from '../../models/acap';

const DAO_NAME = 'devoluciones_perfiles';

const {
  hook: { libbyCall, libbyDAO, libbyFetch },
} = makeBusinessEntity<DevolucionPerfil, DevolucionPerfilesDAO>(DAO_NAME, {});

// hooks
export const useDevolucionPerfilesLibbyCall = libbyCall;
export const useDevolucionPerfilesDAO = libbyDAO;
export const useDevolucionPerfilesLibbyFetch = libbyFetch;
