import { Grid, Typography, Tooltip } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  useGeneroLibbyFetch,
  usePaisLibbyFetch,
  useTipoDocumentoLibbyFetch,
  useProvinciaLibbyFetch,
  useAnioLibbyFetch,
  useCicloLibbyFetch,
  useCicloAdultosLibbyFetch,
} from 'src/app/business';
import {
  Genero,
  Pais,
  Provincia,
  TipoDocumento,
  Anio,
  ModalidadType,
  Ciclo,
  CicloAdultos,
} from 'src/app/models';
import {
  FormCheckbox,
  FormDatePicker,
  FormDatePickerV2,
  FormInput,
  useFormContext,
} from 'src/lib/templates';
import { pais, tipoDocumento, NIVEL, ROL } from 'src/commons/const';
import { primary } from 'src/theme/colors';
import { useRolesContext } from 'src/context/RolesContext';
import LockIcon from '@material-ui/icons/Lock';
import { CustomFormAutocomplete as FormAutocomplete } from '../components';
import { useLegajoData } from '../context';
import { useParams } from 'react-router';
import { useColegioEfectivizadoContext } from '../../Calificaciones/context/EfectivizacionProvider';
import moment from 'moment';
import { useFormInputStyles } from 'src/app/components';

const EQUIPO_CONDUCCION = '5';

const { ID_PAIS_ARG } = pais;

const { DNI_TEMPORAL } = tipoDocumento;

interface EstadoCivil {
  name: string;
  value: string;
}

interface RouteParamsLegajo {
  id: string;
}

interface CondicionAnio {
  name: string;
  value: string;
}

// TODO: Revisar valores
const estadosCiviles: EstadoCivil[] = [
  {
    name: 'Soltero/a',
    value: 'Soltero/a',
  },
  {
    name: 'Casado/a',
    value: 'Casado/a',
  },
  {
    name: 'Divorciado/a',
    value: 'Divorciado/a',
  },
  {
    name: 'Viudo/a',
    value: 'Viudo/a',
  },
  {
    name: 'Concubinato',
    value: 'Viudo/a',
  },
];

const condicionesAnio: CondicionAnio[] = [
  {
    name: 'Nuevo ingresante no repitente',
    value: 'Nuevo ingresante no repitente',
  },
  {
    name: 'Nuevo ingresante repitente',
    value: 'Nuevo ingresante repitente',
  },
  {
    name: 'Reinscripto no repitente',
    value: 'Reinscripto no repitente',
  },
  {
    name: 'Reinscripto repitente',
    value: 'Reinscripto repitente',
  },
];

const useStyles = makeStyles({
  titleContainer: {
    paddingBottom: '0 !important',
  },
  dataContainer: {
    padding: '20px',
  },
  title: {
    color: '#595959',
    fontSize: '20px',
  },
  grayContainer: {
    background: '#F5F5F5',
    borderRadius: '5px',
    padding: 20,
  },
  blankSpace: {
    height: '75px',
  },
  observacion: {
    marginTop: '-25px',
  },
  emailContainer: {
    flexDirection: 'row',
    display: 'flex',
    alignContent: 'center',
    alignItems: 'center',
  },
  formInputContainer: {
    width: '95%',
  },
  iconContainer: {
    marginTop: 20,
    marginLeft: 10,
  },
  datePicker: {
    paddingLeft: 0,
  },
  checkBox: {
    marginTop: 15,
  },
  datosInstitucion: {
    marginLeft: 30,
  },
  yearInput: {
    marginLeft: 11,
    marginTop: 8,
  },
  turnoInput: {
    marginRight: 10,
  },
  fechaEfectivaInput: {
    marginLeft: 17,
    marginBottom: 5,
  },
  cicloLectivoInput: {
    marginLeft: -12,
  },
  condicionAnioAnteriorInput: {
    marginTop: 10,
    marginLeft: 12,
  },
  tipoDocumentoInput: {
    marginTop: 8,
    marginLeft: 10,
  },
  generoInput: {
    marginLeft: 10,
  },
  estadoCivilInput: {
    marginLeft: 10,
  },
});

const YearField = ({
  alumno,
  alumnoMovimiento,
  datosInstitucion,
  anio,
  ciclo,
  cicloAdultos,
  form,
  modalidad,
  tipoEstablecimiento,
  isHistoric,
  ...props
}: any) => {
  const cicloInscripcion = datosInstitucion?.data?.cicloLectivo?.anio;
  const cicloMatriculacion = alumnoMovimiento?.cicloLectivo?.anio;

  let isDisabledAnio = true;

  if (cicloInscripcion && cicloMatriculacion) {
    isDisabledAnio = cicloInscripcion === cicloMatriculacion;
  }

  if (cicloInscripcion && !cicloMatriculacion) {
    isDisabledAnio = false;
  }

  let field = (
    <FormAutocomplete
      placeholder="Seleccioná año"
      label="Año"
      name="datosPersonales.datosInstitucion.ano"
      form={form}
      options={
        anio?.filter((item: any) => {
          if (tipoEstablecimiento === 'TEC') {
            return item.nivel.idNivel === 3 || item.nivel.idNivel !== 3;
          }
          return (
            (item.nivel.idNivel === 3 && item.numeroAnio !== 6) ||
            item.nivel.idNivel !== 3
          );
        }) || []
      }
      optionLabelKey="descripcionAnio"
      optionToValue={(option: Anio) => option?.idAnio}
      valueToOption={(value: any, options: Anio[]) =>
        options.find((option: Anio) => option?.idAnio === value)
      }
      getOptionSelected={(option: Anio, value: Anio) =>
        option?.idAnio === value?.idAnio
      }
      disabled={isDisabledAnio || isHistoric}
      {...props}
    />
  );

  if (modalidad === ModalidadType.EspecialByC) {
    field = (
      <FormAutocomplete
        placeholder="Seleccioná ciclo"
        label="Ciclo"
        name="datosPersonales.datosInstitucion.ciclo"
        form={form}
        options={ciclo || []}
        optionLabelKey="descripcionCiclo"
        optionToValue={(option: Ciclo) => option?.idCiclo}
        valueToOption={(value: any, options: Ciclo[]) =>
          options.find((option: Ciclo) => option?.idCiclo === value)
        }
        getOptionSelected={(option: Ciclo, value: Ciclo) =>
          option?.idCiclo === value?.idCiclo
        }
        disabled={
          alumno?.estadoMatricula?.idEstadoMatricula === 1 || isHistoric
        }
        {...props}
      />
    );
  } else if (modalidad === ModalidadType.Adultos) {
    field = (
      <FormAutocomplete
        placeholder="Seleccioná ciclo"
        label="Ciclo adultos"
        name="datosPersonales.datosInstitucion.cicloAdultos"
        form={form}
        options={cicloAdultos || []}
        optionLabelKey="descripcion"
        optionToValue={(option: CicloAdultos) => option?.idCicloAdultos}
        valueToOption={(value: any, options: CicloAdultos[]) =>
          options.find(
            (option: CicloAdultos) => option?.idCicloAdultos === value,
          )
        }
        getOptionSelected={(option: CicloAdultos, value: CicloAdultos) =>
          option?.idCicloAdultos === value?.idCicloAdultos
        }
        disabled={alumno?.estadoMatricula?.idEstadoMatricula === 1}
        {...props}
      />
    );
  }

  return field;
};

export const PersonalInformation: FunctionComponent = () => {
  const classes = useStyles();
  const classesInput = useFormInputStyles();
  const { alumnoMovimiento, alumno, isHistoric, datosInstitucion } =
    useLegajoData();
  const { data: paises } = usePaisLibbyFetch({
    orderBy: 'descripcionPais',
    limit: 200,
  });

  const { data: tiposDeDocumento } = useTipoDocumentoLibbyFetch();
  const { data: generos } = useGeneroLibbyFetch();
  const { seccion } = alumnoMovimiento || {};
  const [documentoInicial, setDocumentoInicial] = useState(false);
  const { form, values, errors, initialValues } = useFormContext();
  const {
    selectedRole: { rol, modalidad, tipoEstablecimiento },
  } = useRolesContext();

  const { id } = useParams<RouteParamsLegajo>();
  const queryParams = new URLSearchParams(window.location.search);
  const legajoEditYear = queryParams.get('year');

  const idPaisNacimiento = useMemo(
    () =>
      values?.datosPersonales?.datosPrincipales?.paisNacimiento || ID_PAIS_ARG,
    [values],
  );
  const filterProvinciasByPais = useMemo(
    () => ({
      pais: [{ path: 'pais.idPais', value: idPaisNacimiento }],
    }),
    [idPaisNacimiento],
  );

  const { data: provincias } = useProvinciaLibbyFetch({
    filter: filterProvinciasByPais,
    limit: 200,
    orderBy: 'nombreProvincia',
  });

  const idNivel = useMemo(
    () =>
      NIVEL[
        values?.datosPersonales?.datosInstitucion?.nivel
          ?.toString()
          .toUpperCase()
      ],
    [values],
  );

  const filterAnioByNivel = useMemo(
    () => ({
      nivel: [{ path: 'nivel.idNivel', value: idNivel || -1 }],
    }),
    [idNivel],
  );

  const { data: anio } = useAnioLibbyFetch({
    filter: filterAnioByNivel,
    limit: 200,
    orderBy: 'descripcionAnio',
  });

  const { data: ciclo } = useCicloLibbyFetch({
    limit: 200,
    orderBy: 'descripcionCiclo',
  });

  const { data: cicloAdultos } = useCicloAdultosLibbyFetch({
    limit: 200,
    orderBy: 'descripcion',
  });

  useEffect(() => {
    if (rol === EQUIPO_CONDUCCION) {
      const isModificable =
        initialValues.datosPersonales.datosPrincipales.tipoDocumento ===
        DNI_TEMPORAL;
      setDocumentoInicial(
        !!initialValues.datosPersonales.datosPrincipales.documento &&
          !isModificable,
      );
    }
  }, [
    initialValues?.datosPersonales.datosPrincipales.documento,
    initialValues?.datosPersonales.datosPrincipales.tipoDocumento,
    rol,
  ]);

  const generosFiltrados = generos?.filter(
    ({ idGenero }) => idGenero !== -1 && idGenero !== 3,
  );

  const isDireccionArea = useMemo(() => {
    let currentYear = values?.datosPersonales?.datosInstitucion.ano;
    let validYear = false;
    const targetIdAnios = [26, 25, 24, 23];
    if (anio && anio.length > 0 && Number(rol) === ROL.DIRECCION_AREA) {
      if (
        targetIdAnios.includes(Number(currentYear)) &&
        Number(currentYear) === Number(legajoEditYear)
      ) {
        validYear = true;
      } else {
        validYear = false;
      }
    }
    return validYear;
  }, [rol, anio, legajoEditYear, values]);

  const { cicloLectivo } = useColegioEfectivizadoContext();

  const fechaInicioCiclo = moment(
    cicloLectivo?.fechaInicio,
    'YYYY-MM-DD',
  ).format('YYYY-MM-DD');
  const minDate = fechaInicioCiclo || moment(new Date()).startOf('year');
  const today = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD');
  const fechaFinCiclo = moment(cicloLectivo?.fechaFin, 'YYYY-MM-DD').format(
    'YYYY-MM-DD',
  );
  const isBeforeInit = moment(today, 'YYYY-MM-DD').isBefore(fechaInicioCiclo);
  let maxDate = moment(today, 'YYYY-MM-DD').isBefore(fechaFinCiclo)
    ? today
    : fechaFinCiclo;
  if (isBeforeInit) {
    maxDate = fechaInicioCiclo;
  }

  return (
    <Grid container spacing={4} direction="row">
      <Grid className={classes.titleContainer} item xs={12}>
        <Typography
          style={{ marginTop: '24px' }}
          variant="h5"
          className={classes.title}
        >
          Datos del Alumno
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <FormInput
          disabled={isHistoric && !isDireccionArea}
          placeholder="Ingresá apellidos"
          name="datosPersonales.datosPrincipales.apellidos"
          label="Apellidos"
          form={form}
        />
      </Grid>
      <Grid item xs={5}>
        <FormInput
          disabled={isHistoric && !isDireccionArea}
          placeholder="Ingresá nombres"
          label="Nombres"
          name="datosPersonales.datosPrincipales.nombres"
          form={form}
        />
      </Grid>
      <Grid item className={classes.tipoDocumentoInput} xs={6}>
        <FormAutocomplete
          placeholder="Seleccioná tipo de documento"
          label="Tipo de documento"
          name="datosPersonales.datosPrincipales.tipoDocumento"
          form={form}
          options={tiposDeDocumento || []}
          optionLabelKey="descripcionTipoDocumento"
          optionToValue={(option: TipoDocumento) => option?.idTipoDocumento}
          valueToOption={(value, options: TipoDocumento[]) =>
            options.find(
              (option: TipoDocumento) => option.idTipoDocumento === value,
            )
          }
          getOptionSelected={(option: TipoDocumento, value: TipoDocumento) =>
            option.idTipoDocumento === value.idTipoDocumento
          }
          disabled={documentoInicial || (isHistoric && !isDireccionArea)}
        />
      </Grid>
      <div style={{ marginLeft: '5px', marginTop: '15px' }}>
        <FormInput
          placeholder="Ingresá número de documento"
          label="Número de documento"
          name="datosPersonales.datosPrincipales.documento"
          form={form}
          disabled={documentoInicial || (isHistoric && !isDireccionArea)}
        />
      </div>
      <Grid item className={classes.generoInput} xs={6}>
        <FormAutocomplete
          disabled={isHistoric && !isDireccionArea}
          placeholder="Seleccioná género"
          label="Género"
          name="datosPersonales.datosPrincipales.genero"
          form={form}
          options={generosFiltrados || []}
          optionLabelKey="descripcionGenero"
          optionToValue={(option: Genero) => option?.idGenero}
          valueToOption={(value, options: Genero[]) =>
            options.find((option) => option?.idGenero === value)
          }
          getOptionSelected={(option: Genero, value: Genero) =>
            option?.idGenero === value?.idGenero
          }
        />
      </Grid>
      <Grid item xs={5}>
        <FormDatePicker
          disabled={isHistoric && !isDireccionArea}
          name="datosPersonales.datosPrincipales.fechaNacimiento"
          label="Fecha de nacimiento"
          form={form}
          className={classes.datePicker}
        />
      </Grid>
      <Grid item className={classes.estadoCivilInput} xs={6}>
        <FormAutocomplete
          disabled={isHistoric && !isDireccionArea}
          placeholder="Seleccioná estado civil"
          label="Estado civil"
          name="datosPersonales.datosPrincipales.estadoCivil"
          form={form}
          options={estadosCiviles || []}
          optionLabelKey="name"
          optionToValue={(option: EstadoCivil) => option?.value}
          valueToOption={(value, options: EstadoCivil[]) =>
            options.find((option) => option.value === value)
          }
          getOptionSelected={(option: EstadoCivil, value: EstadoCivil) =>
            option?.value === value?.value
          }
        />
      </Grid>
      <Grid item xs={3}>
        <FormAutocomplete
          disabled={isHistoric && !isDireccionArea}
          placeholder="Seleccioná país"
          label="País de nacimiento"
          name="datosPersonales.datosPrincipales.paisNacimiento"
          form={form}
          options={paises || []}
          optionLabelKey="descripcionPais"
          optionToValue={(option: Pais) => option?.idPais}
          valueToOption={(value, options: Pais[]) =>
            options.find((option) => option?.idPais === value)
          }
          getOptionSelected={(option: Pais, value: Pais) =>
            option?.idPais === value?.idPais
          }
        />
      </Grid>
      <Grid item xs={2}>
        <FormAutocomplete
          disabled={isHistoric && !isDireccionArea}
          placeholder="Seleccioná provincia"
          label="Provincia de nacimiento"
          name="datosPersonales.datosPrincipales.provinciaNacimiento"
          form={form}
          options={provincias || []}
          optionLabelKey="nombreProvincia"
          optionToValue={(option: Provincia) => option?.idProvincia}
          valueToOption={(value, options: Provincia[]) =>
            options.find((option) => option?.idProvincia === value)
          }
          getOptionSelected={(option: Provincia, value: Provincia) =>
            option?.idProvincia === value?.idProvincia
          }
        />
      </Grid>
      <Grid item lg={6} sm={12} xs={12} className={classes.emailContainer}>
        <Grid className={classes.formInputContainer}>
          <FormInput
            disabled={isHistoric && !isDireccionArea}
            placeholder="Ingresá correo electrónico"
            label="Correo electrónico"
            name="datosPersonales.datosPrincipales.email"
            form={form}
          />
        </Grid>
        <Grid className={classes.iconContainer}>
          <Tooltip
            title={initialValues.datosPersonales.datosPrincipales.password}
          >
            <CopyToClipboard
              text={initialValues.datosPersonales.datosPrincipales.password}
            >
              <LockIcon style={{ color: primary.lightBlue }} />
            </CopyToClipboard>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid item xs={2}>
        <FormInput
          disabled={isHistoric && !isDireccionArea}
          placeholder="Ingresá teléfono"
          label="Teléfono 1"
          name="datosPersonales.datosPrincipales.telefono1"
          form={form}
          type="number"
        />
      </Grid>
      <Grid item xs={2}>
        <FormInput
          disabled={isHistoric && !isDireccionArea}
          placeholder="Ingresá teléfono"
          label="Teléfono 2"
          name="datosPersonales.datosPrincipales.telefono2"
          form={form}
          type="number"
        />
      </Grid>
      <Grid item xs={6}>
        <FormInput
          disabled={isHistoric && !isDireccionArea}
          placeholder="Ingresá legajo"
          label="Legajo"
          name="datosPersonales.datosPrincipales.legajo"
          form={form}
          type={'number'}
        />
      </Grid>
      <Grid item xs={6}>
        <Grid container direction={'column'} spacing={4}>
          <Grid className={classes.checkBox} item xs={3}>
            <FormCheckbox
              disabled={isHistoric && !isDireccionArea}
              label="Pertenece a población indígena"
              name="datosPersonales.datosPrincipales.pbIndigenaPertenece"
              form={form}
            />
          </Grid>
          {values.datosPersonales.datosPrincipales.pbIndigenaPertenece && (
            <Grid item xs={10}>
              <FormInput
                disabled={isHistoric && !isDireccionArea}
                placeholder="Ingresar la poblacion y/o lengua que habla"
                label="Población indigena y/o lengua que habla"
                name="datosPersonales.datosPrincipales.pbIndigenaHabla"
                form={form}
              />
            </Grid>
          )}
          {values.datosPersonales.datosPrincipales.pbIndigenaPertenece && (
            <Grid item xs={10} className={classes.observacion}>
              <FormInput
                disabled={isHistoric && !isDireccionArea}
                placeholder="Ingresar la observacion"
                label="Observaciones"
                name="datosPersonales.datosPrincipales.obsIndigena"
                form={form}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
      <Grid container spacing={4} className={classes.grayContainer}>
        <Grid className={classes.datosInstitucion} item xs={12}>
          <Typography className={classes.title}>
            Datos de la institución
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <FormInput
            label="Nombre del establecimiento"
            disabled
            name="datosPersonales.datosInstitucion.nombreEstablecimiento"
            form={form}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            label="CUE"
            disabled
            name="datosPersonales.datosInstitucion.cue"
            form={form}
          />
        </Grid>
        <Grid item xs={6}>
          <FormInput
            name="datosPersonales.datosInstitucion.nivel"
            disabled
            label="Nivel"
            form={form}
          />
        </Grid>
        <Grid item className={classes.yearInput} xs={3}>
          <YearField
            isHistoric={isHistoric}
            modalidad={modalidad}
            alumno={alumno}
            anio={anio}
            ciclo={ciclo}
            cicloAdultos={cicloAdultos}
            form={form}
            tipoEstablecimiento={tipoEstablecimiento}
            alumnoMovimiento={alumnoMovimiento}
            datosInstitucion={datosInstitucion}
          />
        </Grid>
        <Grid item className={classes.turnoInput} xs={2}>
          <FormInput
            disabled
            name="datosPersonales.datosInstitucion.turno"
            label="Turno"
            form={form}
          />
        </Grid>
        <Grid item className={classes.condicionAnioAnteriorInput} xs={6}>
          <FormAutocomplete
            disabled={isHistoric}
            placeholder="Seleccioná condición del año anterior"
            label="Condición del año anterior"
            name="datosPersonales.datosInstitucion.condicionAnoAnterior"
            form={form}
            options={condicionesAnio || []}
            optionLabelKey="name"
            optionToValue={(option: CondicionAnio) => option?.value}
            valueToOption={(value, options: CondicionAnio[]) =>
              options.find((option: CondicionAnio) => option.value === value)
            }
            getOptionSelected={(option: CondicionAnio, value: CondicionAnio) =>
              option.value === value.value
            }
          />
        </Grid>
        <Grid item className={classes.cicloLectivoInput} xs={3}>
          <FormInput
            label="Ciclo lectivo"
            disabled
            name="datosPersonales.datosInstitucion.cicloLectivo"
            form={form}
          />
        </Grid>
        <Grid item className={classes.fechaEfectivaInput} xs={2}>
          <FormDatePicker
            label="Fecha efectiva de matriculación"
            disabled={
              ![ROL.SUPERVISOR, ROL.DIRECCION_AREA].includes(Number(rol)) ||
              !alumnoMovimiento
            }
            name="datosPersonales.datosInstitucion.enrolledAt"
            form={form}
            minDate={minDate}
            maxDate={maxDate}
            clearable={false}
            isNowrap={true}
          />
        </Grid>
        {/* <Typography component="span" variant="caption">{alumnoMovimiento ? '' : "El alumno no esta matriculado"}</Typography> */}
      </Grid>
      <Grid className={classes.blankSpace} item>
        <br />
      </Grid>
    </Grid>
  );
};
