import React, { FunctionComponent, createRef } from 'react';
import { SnackbarProvider } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import { IconButton } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  success: { position: 'relative', top: 'calc(100vh - 140px)' },
  error: { position: 'relative', top: 'calc(100vh - 140px)' },
  warning: { position: 'relative', top: 'calc(100vh - 140px)' },
  info: { position: 'relative', top: 'calc(100vh - 140px)' },
}));

export const ToastProvider: FunctionComponent<any> = ({ children }) => {
  const classes = useStyles();
  const notistackRef = createRef<any>();
  const onClickDismiss = (key: any) => () => {
    notistackRef.current.closeSnackbar(key);
  };

  return (
    <SnackbarProvider
      maxSnack={3}
      preventDuplicate
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
      ref={notistackRef}
      action={(key) => (
        <IconButton onClick={onClickDismiss(key)} color="inherit">
          <CloseIcon />
        </IconButton>
      )}
    >
      {children}
    </SnackbarProvider>
  );
};
