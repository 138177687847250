import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { InfoTable } from 'src/commons';
import { useCalificacionesContext } from '../../../context';
import { useInformeCuatrimestralCuartoBimestreColumns } from '../hooks/useInformeCuatrimestralCuartoBimestreColumns';
import { useInformescCustomLibbyFetch } from 'src/app/business/Calificaciones/InformescCustom';
import { setSearchParamsMultipleWords } from 'src/utils/setSearchParamsMultipleWords';
import { useInformeStyles } from '../../styles/informe';
import { Informesc, InformescCustom } from 'src/app/models';

const InformeCuatrimestralCuartoBimestre = ({
  onDataChange,
  reloadTable,
  establecimientoEfectivizado,
  config,
}: {
  onDataChange: (oldData: InformescCustom[], newData: InformescCustom[]) => {};
  reloadTable: Boolean;
  establecimientoEfectivizado: boolean;
  config: {
    indicadores: any;
  };
}) => {
  const classes = useInformeStyles();
  const { filter } = useCalificacionesContext();
  const [editedRows, setEditedRows] = useState<InformescCustom[]>([]);

  const filterParams = useMemo(
    () => ({
      0: [{ path: 'seccion', value: filter.seccion }],
      1: [{ path: 'calificacion.periodo', value: filter.periodo }],
    }),
    [filter.seccion, filter.periodo],
  );

  const {
    data: informesRows = [],
    working,
    reFetch,
  } = useInformescCustomLibbyFetch({
    aspect: 'default',
    filter: filterParams,
    limit: 100,
    enabled: true,
  });

  useEffect(() => {
    setEditedRows([]);
    reFetch();
  }, [reloadTable, reFetch]);

  useEffect(() => {
    if (filter.seccion !== '') {
      setEditedRows([]);
    }
  }, [filter.seccion]);

  useEffect(() => {
    onDataChange(informesRows, editedRows);
  }, [editedRows, informesRows, onDataChange]);

  const handleDataChange = (
    data: { name: string; value: string },
    selectedRow: InformescCustom,
  ) => {
    const clonnedSelectedRow: InformescCustom = JSON.parse(
      JSON.stringify(selectedRow),
    );
    const newInformes: InformescCustom[] = JSON.parse(
      JSON.stringify(editedRows.length > 0 ? editedRows : informesRows),
    );
    const rowToChangeIndex = newInformes?.findIndex(
      (row) => row.idAlumno === clonnedSelectedRow?.idAlumno,
    );
    if (rowToChangeIndex !== -1) {
      let nota: Informesc = { ...clonnedSelectedRow?.nota };
      nota.calificacion =
        clonnedSelectedRow?.nota?.calificacion ??
        clonnedSelectedRow.idCalificacion;

      if (data?.value) {
        nota['data'] = {
          ...newInformes[rowToChangeIndex]?.nota?.data,
          ...{ [data.name]: data.value },
        };
        if (Array.isArray(data?.value) && !data?.value?.length) {
          delete nota?.data?.[data?.name];
        }
      } else {
        delete nota?.data?.[data?.name];
      }
      newInformes[rowToChangeIndex]['nota'] = nota;
      setEditedRows(newInformes);
    }
  };

  const rows = editedRows.length === 0 ? informesRows : editedRows;

  const rowsFilter =
    filter.search !== ''
      ? rows.filter((r) => {
          const nombreYapellido = `${r.apellido} ${r.nombre}`;
          return nombreYapellido
            .toLowerCase()
            .includes(filter.search.toLowerCase().replace(/,/g, ''));
        })
      : rows;

  const periodoActual = filter.periodo;
  const columns = useInformeCuatrimestralCuartoBimestreColumns({
    handleDataChange,
    isInformesClosed: rows.some((row) => row?.abierto === false),
    establecimientoEfectivizado,
    config,
    periodoActual,
  });

  return (
    <>
      <InfoTable
        columns={columns}
        rows={rowsFilter}
        customStyle={classes.table}
        working={working}
      />
    </>
  );
};

export default InformeCuatrimestralCuartoBimestre;
