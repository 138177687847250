import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { DiscipOrientPlan } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'discip_orient_plan';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idDiscipOrientPlan',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idDiscipOrientPlan',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.DiscipOrientPlan
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.DiscipOrientPlan,
      hoc: { ...defaultOptions.hoc, ...businessOptions.DiscipOrientPlan.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<DiscipOrientPlan>(DAO_NAME, optionsToUse);

// hooks
export const useDiscipOrientPlanFetchById = fetchById;
export const useDiscipOrientPlanLibbyCall = libbyCall;
export const useDiscipOrientPlanDAO = libbyDAO;
export const useDiscipOrientPlanLibbyFetch = libbyFetch;

// components/hoc
export const DiscipOrientPlanAutocomplete = entityAutocomplete;
export const DiscipOrientPlanCall = renderPropCall;
export const DiscipOrientPlanById = renderPropFetchById;
export const DiscipOrientPlanSelect = simpleSelect;
export const DiscipOrientPlanAccordionSelector = accordionSelector;
export const DiscipOrientPlanSelectedChips = selectedChips;

// context
// ByIdContext
export const useDiscipOrientPlanByIdContext = useByIdContext;
export const DiscipOrientPlanByIdProvider = ByIdProvider;
export const DiscipOrientPlanByIdContext = ByIdContext;
// GeneralContext
export const useDiscipOrientPlanContext = useGeneralContext;
export const DiscipOrientPlanProvider = GeneralProvider;
export const DiscipOrientPlanContext = GeneralContext;
