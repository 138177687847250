import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, IconButton, makeStyles, TextField } from '@material-ui/core';
import { useGetRows } from '../../../../hooks';
import { useInformeValorativoLibbyFetch } from 'src/app/business/Calificaciones';
import { isEqual } from 'lodash';
import customFormDialog from 'src/commons/services/customFormDialog';
import { ObservacionesModal } from 'src/commons/components/ObservacionesModal';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { primary } from 'src/theme/colors';
import EditIcon from '@material-ui/icons/Edit';
import { setSearchParamsMultipleWords } from 'src/utils/setSearchParamsMultipleWords';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
import { ROL } from 'src/commons';
import { useRolesContext } from 'src/context/RolesContext';

const useStyles = makeStyles(() => ({
  iconObservacion: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    color: primary.lightBlue,
    maxWidth: '100%',
  },
  observaciones: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '150px',
    textAlign: 'right',
    color: 'black',
  },
}));

export const useRows = (filter: any) => {
  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();
  const classes = useStyles();
  const [readyToClose, setReadyToClose] = useState<boolean>(false);

  const filterParams = useMemo(
    () => ({
      0: [{ path: 'seccion', value: filter.seccion }],
      1: [{ path: 'calificacion.periodo', value: filter.periodo }],
    }),
    [filter.seccion, filter.periodo],
  );

  const response = useInformeValorativoLibbyFetch({
    aspect: 'default',
    filter: filterParams,
    enabled: !!filter.periodo && !!filter.anio && !!filter.seccion,
    limit: 100,
    checkDuplication: false,
  });
  const {
    rows: _rows,
    changeRow,
    working,
    fetchMore,
    data,
    reFetch,
    hasCalifications,
  } = useGetRows(response, filter.search);

  const isClosed = useMemo(
    () => _rows.some((row) => row.nota?.abierto === false),
    [_rows],
  );

  useEffect(() => {
    const submitRows = _rows.filter((row: any) => {
      let isUpdated = false;
      const currentCalification = data?.find(
        (d: any) => d.idCalificacion === row.idCalificacion,
      );
      if (currentCalification?.nota) {
        isUpdated = !isEqual(currentCalification?.nota?.data, row.nota.data);
      } else if (Object.keys(row.nota.data).length) {
        isUpdated = true;
      }
      return isUpdated;
    });
    setReadyToClose(!submitRows.length);
  }, [_rows, data]);

  const {
    selectedRole: { rol },
  } = useRolesContext();

  const changeHandler: any = useCallback(
    (
      index: number,
      name: string,
      value: string,
      title: string,
      maxLength: number,
    ) => {
      customFormDialog.show({
        title,
        renderComponent: (
          <ObservacionesModal
            onAddContent={(content: string) =>
              changeRow(
                {
                  target: {
                    name,
                    value: content,
                  },
                },
                index,
              )
            }
            index={index}
            value={value}
            maxLength={maxLength}
          />
        ),
        sizeWidth: 'sm',
      });
    },
    [changeRow],
  );

  const rows = useMemo(() => {
    const isDisabled =
      _rows.some(
        (c) => c.nota?.abierto !== undefined && c.nota?.abierto === false,
      ) || Number(rol) === ROL.COORDINADOR_JE;
    return _rows.map((row, index: number) => {
      return {
        nombre: `${row?.apellido}, ${row?.nombre}`,
        observaciones: row.nota?.data?.observaciones ? (
          <Grid container className={classes.iconObservacion}>
            <Grid item xs={11} className={classes.observaciones}>
              {row.nota?.data?.observaciones}
            </Grid>
            <Grid item xs={1}>
              <IconButton
                className={classes.iconObservacion}
                disabled={isDisabled}
                onClick={() =>
                  changeHandler(
                    index,
                    'observaciones',
                    row.nota?.data?.observaciones,
                    'Observaciones',
                    2000,
                  )
                }
              >
                <LibraryBooksIcon />
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          <Grid className={classes.iconObservacion}>
            <IconButton
              disabled={isDisabled}
              onClick={() =>
                changeHandler(
                  index,
                  'observaciones',
                  row.nota?.data?.observaciones,
                  'Observaciones',
                  2000,
                )
              }
            >
              <EditIcon
                style={{
                  color:
                    row.abierto !== undefined && row.abierto === false
                      ? primary.disabled
                      : primary.lightBlue,
                }}
              />
            </IconButton>
          </Grid>
        ),
        logro: row.nota?.data?.logro ? (
          <Grid container className={classes.iconObservacion}>
            <Grid item xs={11} className={classes.observaciones}>
              {row.nota?.data?.logro}
            </Grid>
            <Grid item xs={1}>
              <IconButton
                className={classes.iconObservacion}
                disabled={isDisabled}
                onClick={() =>
                  changeHandler(
                    index,
                    'logro',
                    row.nota?.data?.logro,
                    'Curricular/Logro de los aprendizajes',
                    2000,
                  )
                }
              >
                <LibraryBooksIcon />
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          <Grid className={classes.iconObservacion}>
            <IconButton
              disabled={isDisabled}
              onClick={() =>
                changeHandler(
                  index,
                  'logro',
                  row.nota?.data?.logro,
                  'Curricular/Logro de los aprendizajes',
                  2000,
                )
              }
            >
              <EditIcon
                style={{
                  color:
                    row.abierto !== undefined && row.abierto === false
                      ? primary.disabled
                      : primary.lightBlue,
                }}
              />
            </IconButton>
          </Grid>
        ),
        socioAfectiva: row.nota?.data?.socioAfectiva ? (
          <Grid container className={classes.iconObservacion}>
            <Grid item xs={11} className={classes.observaciones}>
              {row.nota?.data?.socioAfectiva}
            </Grid>
            <Grid item xs={1} justifyContent="center">
              <IconButton
                className={classes.iconObservacion}
                disabled={isDisabled}
                onClick={() =>
                  changeHandler(
                    index,
                    'socioAfectiva',
                    row.nota?.data?.socioAfectiva,
                    'Socioafectiva',
                    2000,
                  )
                }
              >
                {<LibraryBooksIcon />}
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          <Grid className={classes.iconObservacion}>
            <IconButton
              disabled={isDisabled}
              onClick={() =>
                changeHandler(
                  index,
                  'socioAfectiva',
                  row.nota?.data?.socioAfectiva,
                  'Socioafectiva',
                  2000,
                )
              }
            >
              {
                <EditIcon
                  style={{
                    color:
                      row.abierto !== undefined && row.abierto === false
                        ? primary.disabled
                        : primary.lightBlue,
                  }}
                />
              }
            </IconButton>
          </Grid>
        ),
      };
    });
  }, [rol, _rows, classes, changeHandler]);

  return {
    rows,
    _rows,
    working,
    fetchMore,
    data,
    reFetch,
    isClosed,
    readyToClose,
    hasCalifications,
  };
};
