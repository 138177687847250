import { useMemo } from 'react';
import { useSolicitudPasesLibbyFetch } from 'src/app/business';
import { useRolesContext } from 'src/context/RolesContext';

const useSolicitudPases = (idEstado: string = '3', limit = 100) => {
  const {
    selectedRole: { localizacionId, nivel },
  } = useRolesContext();

  const filter = useMemo(
    () => ({
      nivel: [
        {
          path: 'nivel',
          value: nivel,
        },
      ],
      estado: [
        {
          path: 'estadoSolicitudPases',
          value: idEstado, // default pendiente
        },
      ],
      localizacion: [
        {
          path: 'localizacionOrigen',
          value: localizacionId,
        },
      ],
    }),
    [nivel, idEstado, localizacionId],
  );

  const { data = [] } = useSolicitudPasesLibbyFetch({
    filter,
    limit,
  });

  return data;
};

export default useSolicitudPases;
