import { MenuItem } from '../../../../commons/const/ui/menu';
import { ConfigurationAdmin } from '../../../../screens/Private/ConfigurationAdmin';
import { usuariosMenu } from './usuariosMenu';
import { aprobacionRolesMenu } from './aprobacionRolesMenu';
import { configuracionMatriculaMenu } from './configuracionMatriculaMenu';

export const routes: MenuItem[] = [
  usuariosMenu,
  aprobacionRolesMenu,
  configuracionMatriculaMenu,
  {
    key: 'configurationAdminList',
    basePath: '/',
    target: {
      component: ConfigurationAdmin,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Configuarciones Admin',
    children: [],
    default: true,
  },
];
