import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { Contraturno } from '../../models';
import { businessOptions } from '../businessOptions';
import { ContraturnoDAO } from 'src/platform/libby/dao/Presentismov3';

const DAO_NAME = 'contraturno';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idContraturno',
      labelKey: 'description',
    },
    withSelectedChips: {
      idKey: 'idContraturno',
      labelKey: 'description',
    },
    withSimpleSelect: {
      labelKey: 'description',
      valueKey: 'idContraturno',
      title: '',
      placeholder: 'Seleccioná Contraturno',
      name: 'id',
    },
  },
};

const optionsToUse = !businessOptions.contraturno
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.contraturno,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.contraturno.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
  },
} = makeBusinessEntity<Contraturno, ContraturnoDAO>(DAO_NAME, optionsToUse);

// hooks
export const useContraturnoFetchById = fetchById;
export const useContraturnoLibbyCall = libbyCall;
export const useContraturnoDAO = libbyDAO;
export const useContraturnoLibbyFetch = libbyFetch;

// components/hoc
export const ContraturnoAutocomplete = entityAutocomplete;
export const ContraturnoCall = renderPropCall;
export const ContraturnoById = renderPropFetchById;
export const ContraturnoSelect = simpleSelect;
