import React, { useMemo, useRef } from 'react';
import { Grid } from '@material-ui/core';
import { useRolesContext } from 'src/context/RolesContext';
import { SeccionProvider } from 'src/app/business';
import { useInfoCards } from './hooks';
import {
  EspaciosInterdiscipPlan,
  InterdiscipOrientaPlan,
} from '../../../../../../../app/models';
import { InterdisciplinarFlowHeader } from './components';
import { InterdisciplinarProvider } from '../../forms/commons/components';
import { InfoCard } from '../../feedbacks/commons';

export interface InterdisciplinarPreviewFlowProps {
  data: EspaciosInterdiscipPlan;
}

export const InterdisciplinarPreviewFlow = ({
  data,
}: InterdisciplinarPreviewFlowProps) => {
  const cards = useInfoCards(data);

  const interdiscipOrientaciones = data.interdiscipPlan.interdiscipOrientaPlans;
  const { selectedRole } = useRolesContext();
  const orientaciones = useMemo(
    () =>
      interdiscipOrientaciones?.map(
        (orientacion: InterdiscipOrientaPlan) => orientacion.orientacion,
      ) || [],
    [interdiscipOrientaciones],
  );

  const seccionFilter = useRef({
    filter: [{ path: 'localizacion', value: selectedRole.localizacionId }],
  });

  return (
    <SeccionProvider filter={seccionFilter.current}>
      <>
        <InterdisciplinarFlowHeader data={data} toPrint />
        <Grid container>
          <Grid item sm={12}>
            <InterdisciplinarProvider
              orientaciones={orientaciones}
              idPlanEstudio={data.espacioCurricular.planEstudio.idPlanEstudio}
              idAnio={data.espacioCurricular.materia.anio.idAnio}
            >
              <InfoCard title="Planificación" cards={cards} />
            </InterdisciplinarProvider>
          </Grid>
        </Grid>
      </>
    </SeccionProvider>
  );
};
