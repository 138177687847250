import { useMemo } from 'react';
import { ButtonTypesProps } from '../components/Footer';

export type SaveCancelButtonConfigOptions = {
  onSubmit: (...params: any[]) => any;
  onCancel: (...params: any[]) => any;
  disableSave?: boolean;
};

export const useSaveCancelButtonConfig = ({
  onSubmit,
  onCancel,
  disableSave = false,
}: SaveCancelButtonConfigOptions) =>
  useMemo<ButtonTypesProps[]>(
    () => [
      {
        title: 'Cancelar',
        handleOnClick: onCancel,
        typeButton: 'submit',
      },
      {
        title: 'Guardar',
        handleOnClick: onSubmit,
        typeButton: 'submit',
        disabled: disableSave,
      },
    ],
    [onCancel, onSubmit, disableSave],
  );
