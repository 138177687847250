import moment from 'moment';
import { useMemo } from 'react';
import { useLocalizacionLibbyFetch } from 'src/app/business';
import { useRolesContext } from 'src/context/RolesContext';
import { ROL } from '../const/rol';
import { NIVEL } from 'src/commons/const';
import { useGeneralContext } from 'src/context';

export const useDateValidator = (permiso: string) => {
  const {
    selectedRole: { rol: rolUsuario, nivel: nivelRol, localizacionId },
  } = useRolesContext();

  const { generalState } = useGeneralContext();

  const filterLocalizacion = useMemo(
    () => ({
      localizacion: [
        {
          path: 'idLocalizacion',
          value: localizacionId,
        },
      ],
    }),
    [localizacionId],
  );

  const { data: localizacion }: any = useLocalizacionLibbyFetch({
    limit: 1,
    filter: filterLocalizacion,
    aspect: 'dependencia_funcional',
  });

  const DEPENDENCIA_FUNCIONAL =
    localizacion[0]?.establecimiento?.dependenciaFuncional
      ?.idDependenciaFuncional;

  const FechaServer = generalState?.date;

  const fechaOctubre = '08/22/2022';
  const fechaNoviembre = '11/1/2022';
  const fechaSeptiembre = '09/30/2022';
  const fechaInicioAgosto = '08/26/24';
  const fechaInicioSeptiembre = '09/27/24';
  const fechaFinalSeptiembre = '09/29/24';
  // Por lo visto la efectivizacion de habilita cada inicio de ciclo lectivo ( periodo primer bimentre en adelante )
  // Fecha coincide con la del inico del periodo, toma dicha fecha para habilitar
  const fechaHabilitarEfectivizacion = '02/26/2024';
  const newFechaServer = moment(FechaServer).format('YYYY-MM-DD');
  const enabled = useMemo(() => {
    const permisosOctubre = ['Alumnos', 'Secciones', 'GestionDePases'];

    const permisosNoviembre = [
      'Alumnos',
      'Secciones',
      'Proyectar',
      'GestionDePases',
    ];

    const resultOctubre = permisosOctubre.find(
      (element: any) => element === permiso,
    );
    const resultNoviembre = permisosNoviembre.find(
      (element: any) => element === permiso,
    );

    const jerarquicosValidacionInicial =
      Number(nivelRol) === NIVEL.INICIAL &&
      (rolUsuario === String(ROL.DIRECTIVO) ||
        rolUsuario === String(ROL.EQUIPO_CONDUCCION) ||
        rolUsuario === String(ROL.SUPERVISOR));

    const validacionFechaSeptiembre = moment(newFechaServer).isAfter(
      moment(fechaFinalSeptiembre).format('YYYY-MM-DD'),
    );

    const validacionFinalizarProyeccion = moment(
      FechaServer,
      'YYYY-MM-DD',
    ).isBetween(
      moment(fechaInicioSeptiembre, 'YYYY-MM-DD'),
      moment(fechaFinalSeptiembre, 'YYYY-MM-DD'),
    );

    const validacionCrearCopiarSecciones = moment(FechaServer).isBetween(
      moment(fechaInicioAgosto, 'MM/DD/YY').startOf('day'),
      moment(fechaFinalSeptiembre, 'MM/DD/YY').endOf('day'),
    );

    const nivelValidation = Number(nivelRol) === NIVEL.INICIAL;
    // 41 = Dirección de Escuelas Normales
    const dependenciaFuncionalValidacion =
      Number(DEPENDENCIA_FUNCIONAL) === 41 &&
      rolUsuario === String(ROL.DIRECTIVO);

    const dfNormalesInicialValidacion =
      Number(nivelRol) === NIVEL.INICIAL &&
      Number(DEPENDENCIA_FUNCIONAL) === 41 &&
      (rolUsuario === String(ROL.DIRECTIVO) ||
        rolUsuario === String(ROL.EQUIPO_CONDUCCION) ||
        rolUsuario === String(ROL.SUPERVISOR));

    if (dfNormalesInicialValidacion) return true;

    const dfInicialValidacion =
      Number(nivelRol) === NIVEL.INICIAL &&
      Number(DEPENDENCIA_FUNCIONAL) === 10 &&
      (rolUsuario === String(ROL.DIRECTIVO) ||
        rolUsuario === String(ROL.SUPERVISOR) ||
        rolUsuario === String(ROL.EQUIPO_CONDUCCION));

    const jerarquicosInicialValidacion =
      Number(nivelRol) === NIVEL.INICIAL &&
      (rolUsuario === String(ROL.DIRECTIVO) ||
        rolUsuario === String(ROL.EQUIPO_CONDUCCION) ||
        rolUsuario === String(ROL.SUPERVISOR));

    if (dfInicialValidacion && permiso === 'GestionDePases') return false;

    if (
      dfInicialValidacion &&
      validacionCrearCopiarSecciones &&
      permiso === 'Secciones'
    ) {
      return true;
    }
    const validacionEfectivizar = moment(newFechaServer).isAfter(
      moment(fechaHabilitarEfectivizacion).format('YYYY-MM-DD'),
    );

    if (Number(nivelRol) !== NIVEL.SECUNDARIO && permiso === 'Efectivizar')
      return true;

    if (jerarquicosInicialValidacion && permiso === 'Alumnos') return true;

    if (jerarquicosInicialValidacion && permiso === 'GestionDePases')
      return true;

    if (
      jerarquicosInicialValidacion &&
      dfInicialValidacion &&
      validacionFinalizarProyeccion &&
      permiso === 'Proyectar'
    )
      return true;
    if (!dependenciaFuncionalValidacion) {
      if (!!resultOctubre && nivelValidation) {
        const validacionFecha = moment(FechaServer, 'YYYY-MM-DD').isAfter(
          moment(fechaOctubre, 'YYYY-MM-DD'),
        );
        if (
          rolUsuario === String(ROL.DIRECTIVO) ||
          rolUsuario === String(ROL.EQUIPO_CONDUCCION) ||
          rolUsuario === String(ROL.SUPERVISOR)
        ) {
          if (validacionFecha) {
            return false;
          }
        }
      } else if (!!resultNoviembre && nivelValidation) {
        const validacionFecha = moment(FechaServer, 'YYYY-MM-DD').isAfter(
          moment(fechaNoviembre, 'YYYY-MM-DD'),
        );
        if (
          rolUsuario === String(ROL.DIRECTIVO) ||
          rolUsuario === String(ROL.EQUIPO_CONDUCCION) ||
          rolUsuario === String(ROL.SUPERVISOR)
        ) {
          if (validacionFecha) {
            return false;
          }
        }
      }
    }

    if (
      (jerarquicosInicialValidacion &&
        validacionFechaSeptiembre &&
        permiso === 'Proyectar') ||
      (dfInicialValidacion &&
        validacionFechaSeptiembre &&
        permiso === 'Secciones')
    ) {
      return false;
    }

    if (Number(nivelRol) === NIVEL.INICIAL && !validacionCrearCopiarSecciones)
      return false;

    return true;
  }, [
    DEPENDENCIA_FUNCIONAL,
    FechaServer,
    nivelRol,
    permiso,
    rolUsuario,
    newFechaServer,
  ]);

  return enabled;
};
