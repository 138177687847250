import React from 'react';
import { Box, IconButton, Tooltip } from '@material-ui/core';
import customFormDialog from 'src/commons/services/customFormDialog';
import { Visibility } from '@material-ui/icons';
import { ModalJustificacion } from '../components';
import { rowJustificativosTypes } from '../types';
import { Presente_v3 } from 'src/app/models';
import { PresenteGeneral } from '../../TomaPresentismo/types';
import { InfoTableColumn } from '../../../../../commons';
import { useRolesContext } from '../../../../../context/RolesContext';

export const useColumnsJustify = (
  filteredData: PresenteGeneral[],
  setFilteredData: React.Dispatch<React.SetStateAction<PresenteGeneral[]>>,
) => {
  const roleContext = useRolesContext();
  const { selectedRole } = roleContext;
  const selectedRoleData = Number(selectedRole.rol);

  const handleOpenModalJustificacion = async (row: rowJustificativosTypes) => {
    try {
      await customFormDialog.show({
        title: 'Visualizar Justificación',
        renderComponent: (
          <ModalJustificacion
            row={row}
            data={filteredData}
            setData={setFilteredData}
            authorizedRole={selectedRoleData}
          />
        ),
        sizeWidth: 'md',
      });
    } catch (error) {
      console.log('error', error);
    }
  };

  const columnJustify: InfoTableColumn<rowJustificativosTypes>[] = [
    { id: 'date', label: 'Fecha', hideSortIcon: true },
    { id: 'estado', label: 'Estado', hideSortIcon: true },
    { id: 'motivo', label: 'Motivo', hideSortIcon: true },
    { id: 'details', label: 'Detalle', hideSortIcon: true },
    { id: 'turno', label: 'Turno', hideSortIcon: true },
    {
      id: 'view',
      label: 'Ver',
      hideSortIcon: true,
      render: (value: any, row) => {
        return (
          <Box display="flex" justifyContent="center">
            <Box p={1}>
              <Tooltip title="Ver">
                <IconButton
                  style={{ color: '#3786EE' }}
                  onClick={() => handleOpenModalJustificacion(row)}
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        );
      },
    },
  ];

  return columnJustify;
};
