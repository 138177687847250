import {
  PartialBusinessEntityOptions,
  makeBusinessEntity,
} from 'src/lib/libby';
import { businessOptions } from '../businessOptions';
import { Endpoint } from 'src/app/models/security/Endpoint';

const DAO_NAME = 'endpoint';

const defaultOptions: PartialBusinessEntityOptions<any> = {};

const optionsToUse = !businessOptions.Endpoint
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Endpoint,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Endpoint.hoc },
    };

const {
  hoc: { simpleSelect, entityAutocomplete },
} = makeBusinessEntity<Endpoint>(DAO_NAME, optionsToUse);

export const EndpointAutoComplete = entityAutocomplete;
export const EndpointSimpleSelect = simpleSelect;
