import React, { useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { TabBar, useDebounce } from 'src/commons';
import { useOrientacionesLibbyFetch } from 'src/app/business/orientaciones/Orientaciones';
import { useHabilidadesLibbyFetch } from 'src/app/business/orientaciones/Habilidades';
import { usePerfilOrientadoLibbyFetch } from 'src/app/business/orientaciones/PerfilOrientado';
import { useNodosLibbyFetch } from 'src/app/business/orientaciones/Nodos';
import { setSearchParamsMultipleWords } from 'src/utils/setSearchParamsMultipleWords';
import { Accion, Organizacion as OrganizacionModel } from 'src/app/models';
import { useAccionesMinimizadasLibbyFetch } from 'src/app/business/acap/AccionesMinimizadas';
import { Organizacion } from './tabs/organizacion';
import { Oferta } from './tabs/oferta';
import { DetalleAccion } from './tabs/acciones/detalleAccion';
import { ArbolCheckbox } from './tabs/acciones/arbolCheckbox';

export const CURRENT_CICLO_LECTIVO = 24;

const initialFormValues = {
  idAccion: '',
  tipoDeAccion: 0,
  nombre: '',
  accionOrientaciones: [],
  descripcion: '',
  horas: 0,
  perfiles: [],
  habilidades: [],
  contenidos: [],
  cicloLectivo: CURRENT_CICLO_LECTIVO,
};

const initialAction = {
  enabled: false,
  row: null,
  isDisabled: false,
  accion: null,
  duplicateAction: false,
  postDuplicateStep: false,
};

export type CreatinAnAction = Omit<typeof initialAction, 'row' | 'accion'> & {
  row: null | Accion;
  accion: {
    isCreatingAccion: boolean;
    organizacion: OrganizacionModel;
  };
};
export type FormValues = typeof initialFormValues;

export const Tabs = () => {
  const [isCreatingAnAccion, setIsCreatingAnAccion] = useState(initialAction);

  const [accionesAsociadasFilters, setAccionesAsociadasFilters] = useState({
    tipoAccion: null,
    orientacion: null,
    cicloLectivo: CURRENT_CICLO_LECTIVO,
  });

  const [search, setSearch] = useState('');
  const searchDebounced = useDebounce(search, 1000);

  const searchFilterParams = useMemo(
    () => ({
      ...setSearchParamsMultipleWords(
        ['nombre', 'descripcion', 'tipoAccion.nombre'],
        searchDebounced,
      ),
    }),
    [searchDebounced],
  );

  const [accion, setAccion] = useState({
    isCreatingAccion: false,
    organizacion: {},
  });

  const [formValues, setFormValues] = useState(initialFormValues);
  const [initialState, setInitialState] = useState();
  const [needsToUpdate, setNeedsToUpdate] = useState(false);
  const [disableSave, setDisableSave] = useState(false);

  const { data: orientaciones = [] } = useOrientacionesLibbyFetch();

  const {
    data: nodos = [],
    reFetch: nodosRefetch,
    working: workingNodos,
  } = useNodosLibbyFetch({
    limit: 5000,
    orderBy: 'bloque.idBloque,eje.idEje,unidad.idUnidad,contenido.idContenido',
  });
  const {
    data: habilidades = [],
    reFetch: habilidadesReFetch,
    working: workingHabilidades,
  } = useHabilidadesLibbyFetch({
    limit: 5000,
  });
  const {
    data: perfil_orientado = [],
    reFetch: perfilOrientadoReFetch,
    working: workingPerfiles,
  } = usePerfilOrientadoLibbyFetch({
    limit: 5000,
  });

  useEffect(() => {
    const currentStateIsEqual = _.isEqual(initialState, formValues);

    const isEmpty =
      formValues?.tipoDeAccion === 0 ||
      formValues?.nombre === '' ||
      formValues?.accionOrientaciones?.length === 0 ||
      formValues?.descripcion === '' ||
      formValues?.horas === 0 ||
      !Boolean(formValues?.cicloLectivo) ||
      currentStateIsEqual;

    setDisableSave(isEmpty);
  }, [
    formValues,
    formValues?.descripcion,
    formValues?.horas,
    formValues?.nombre,
    formValues?.accionOrientaciones?.length,
    formValues?.tipoDeAccion,
    formValues?.cicloLectivo,
    initialState,
  ]);

  const AccionFilter = useMemo(
    () => ({
      0: Boolean(accion?.organizacion?.idOrganizacion)
        ? [
            {
              path: 'organizacion',
              value: accion?.organizacion?.idOrganizacion,
            },
          ]
        : [],
      1: Boolean(accionesAsociadasFilters?.tipoAccion)
        ? [
            {
              path: 'tipoAccion',
              value: accionesAsociadasFilters?.tipoAccion,
            },
          ]
        : [],
      2: Boolean(accionesAsociadasFilters?.orientacion)
        ? [
            {
              path: 'accionOrientaciones.orientacion',
              value: accionesAsociadasFilters?.orientacion,
              method: 'equals',
            },
          ]
        : [],
      3: Boolean(accionesAsociadasFilters?.cicloLectivo)
        ? [
            {
              path: 'cicloLectivo',
              value: accionesAsociadasFilters?.cicloLectivo,
            },
          ]
        : [],
      ...searchFilterParams,
    }),
    [
      accion?.organizacion?.idOrganizacion,
      accionesAsociadasFilters?.cicloLectivo,
      accionesAsociadasFilters?.orientacion,
      accionesAsociadasFilters?.tipoAccion,
      searchFilterParams,
    ],
  );

  const {
    data: acciones = [],
    reFetch: reFetchAcciones,
    working: workingAcciones,
  } = useAccionesMinimizadasLibbyFetch({
    filter: AccionFilter,
    limit: 500,
    enabled: !!accion?.organizacion?.idOrganizacion,
  });

  useEffect(() => {
    if (needsToUpdate) {
      nodosRefetch();
      habilidadesReFetch();
      perfilOrientadoReFetch();
      reFetchAcciones();
      setNeedsToUpdate(false);
    }
  }, [
    nodosRefetch,
    habilidadesReFetch,
    needsToUpdate,
    perfilOrientadoReFetch,
    reFetchAcciones,
  ]);

  const allTabs = useMemo(
    () => [
      {
        title: 'Organizaciones',
        component: (
          <Organizacion
            setIsCreatingAnAccion={setIsCreatingAnAccion}
            setAccion={setAccion}
            accion={accion}
            setFormValues={setFormValues}
            acciones={acciones}
            workingAcciones={workingAcciones}
            filters={accionesAsociadasFilters}
            setFilters={setAccionesAsociadasFilters}
            reFetchAcciones={reFetchAcciones}
            searchState={{ search, setSearch }}
          />
        ),
      },
      {
        title: 'Oferta',
        component: <Oferta />,
      },
    ],
    [
      accion,
      acciones,
      workingAcciones,
      accionesAsociadasFilters,
      reFetchAcciones,
      search,
    ],
  );

  const tabsCreacionAcciones = useMemo(
    () => [
      {
        title: 'Detalle de la Acción',
        component: (
          <DetalleAccion
            isCreatingAnAccion={isCreatingAnAccion}
            setIsCreatingAnAccion={setIsCreatingAnAccion}
            formValues={formValues}
            setFormValues={setFormValues}
            setNeedsToUpdate={setNeedsToUpdate}
            disableSave={disableSave}
            workingNodos={workingNodos}
            workingHabilidades={workingHabilidades}
            workingPerfiles={workingPerfiles}
            setInitialState={setInitialState}
            initialState={initialState}
            idOrganizacion={accion?.organizacion?.idOrganizacion}
          />
        ),
      },
      {
        title: 'Perfil del Egresado',
        component: (
          <ArbolCheckbox
            isCreatingAnAccion={isCreatingAnAccion}
            nombreColumna="Perfil del Egresado"
            tituloModal="PERFIL DEL EGRESADO"
            formValues={formValues}
            setFormValues={setFormValues}
            orientaciones={orientaciones}
            contenido={perfil_orientado}
            setNeedsToUpdate={setNeedsToUpdate}
            setIsCreatingAnAccion={setIsCreatingAnAccion}
            disableSave={disableSave}
            initialState={initialState}
            setInitialState={setInitialState}
          />
        ),
        disabled:
          isCreatingAnAccion.row === null || isCreatingAnAccion.duplicateAction,
      },
      {
        title: 'Habilidades',
        component: (
          <ArbolCheckbox
            isCreatingAnAccion={isCreatingAnAccion}
            nombreColumna="Competencias"
            tituloModal="HABILIDADES, CAPACIDADES, COMPETENCIAS"
            formValues={formValues}
            setFormValues={setFormValues}
            orientaciones={orientaciones}
            contenido={habilidades}
            setNeedsToUpdate={setNeedsToUpdate}
            setIsCreatingAnAccion={setIsCreatingAnAccion}
            disableSave={disableSave}
            initialState={initialState}
            setInitialState={setInitialState}
          />
        ),
        disabled:
          isCreatingAnAccion.row === null || isCreatingAnAccion.duplicateAction,
      },
      {
        title: 'Contenidos',
        component: (
          <ArbolCheckbox
            isCreatingAnAccion={isCreatingAnAccion}
            nombreColumna="Contenido"
            tituloModal="CONTENIDOS"
            formValues={formValues}
            setFormValues={setFormValues}
            orientaciones={orientaciones}
            contenido={nodos.filter(
              (nodo) =>
                Boolean(nodo.orientacion) &&
                Boolean(nodo.bloque) &&
                Boolean(nodo.eje) &&
                Boolean(nodo.unidad) &&
                Boolean(nodo.contenido),
            )}
            setNeedsToUpdate={setNeedsToUpdate}
            setIsCreatingAnAccion={setIsCreatingAnAccion}
            disableSave={disableSave}
            initialState={initialState}
            setInitialState={setInitialState}
          />
        ),
        disabled:
          isCreatingAnAccion.row === null || isCreatingAnAccion.duplicateAction,
      },
    ],
    [
      isCreatingAnAccion,
      formValues,
      disableSave,
      workingNodos,
      workingHabilidades,
      workingPerfiles,
      initialState,
      orientaciones,
      perfil_orientado,
      habilidades,
      nodos,
      accion?.organizacion?.idOrganizacion,
    ],
  );

  const tabs = isCreatingAnAccion?.enabled ? tabsCreacionAcciones : allTabs;

  return <TabBar content={tabs} />;
};
