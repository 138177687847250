import { level, levelType, ROL } from 'src/commons';
import { FILTERS, TABLE_COLUMNS } from './table_filters';
import {
  BOLETINES_EC_CALIFICADOS_COLUMNS,
  BOLETINES_EC_CALIFICADOS_FILTERS,
} from './Tabs/boletines/params';
import {
  CALIFICACIONES_ESTU_CALIFICACIONES_COLUMNS,
  CALIFICACIONES_ESTU_CALIFICACIONES_FILTERS,
  CALIFICACIONES_EST_APROB_DESAPRO_COLUMNS,
  CALIFICACIONES_EST_APROB_DESAPRO_FILTERS,
  CALIFICACIONES_EST_PREVIA_CARGADA_COLUMNS,
  CALIFICACIONES_EST_PREVIA_CARGADA_FILTERS,
  CALIFICACIONES_EST_SIN_VINCULO_COLUMNS,
  CALIFICACIONES_EST_SIN_VINCULO_FILTERS,
} from './Tabs/calificaciones/params';
import {
  MATRICULADOS_DESMATRICULADOS_COLUMNS,
  MATRICULADOS_DESMATRICULADOS_FILTERS,
  MATRICULADOS_ESTUDIANTES_COLEGIO_COLUMNS,
  MATRICULADOS_ESTUDIANTES_COLEGIO_FILTERS,
  MATRICULADOS_LISTADO_INSCRIPTOS_COLUMNS,
  MATRICULADOS_LISTADO_INSCRIPTOS_FILTERS,
  MATRICULADOS_SECCION_ANIO_COLUMNS,
  MATRICULADOS_SECCION_ANIO_FILTERS,
} from './Tabs/matriculation/params';
import {
  PRESENTISMO_DIA_COLUMNS,
  PRESENTISMO_DIA_FILTERS,
  PRESENTISMO_MES_COLUMNS,
  PRESENTISMO_MES_FILTERS,
  PRESENTISMO_SECCION_DIA_COLUMNS,
  PRESENTISMO_SECCION_DIA_FILTERS,
  PRESENTISMO_SECCION_MES_COLUMNS,
  PRESENTISMO_SECCION_MES_FILTERS,
  PRESENTISMO_SECCION_SEMANA_COLUMNS,
  PRESENTISMO_SECCION_SEMANA_FILTERS,
  PRESENTISMO_SEMANA_COLUMNS,
  PRESENTISMO_SEMANA_FILTERS,
} from './Tabs/presentismo/params';
import {
  PROYECCION_CANT_ESTU_PROMOCIONAN_COLUMNS,
  PROYECCION_CANT_ESTU_PROMOCIONAN_FILTERS,
  PROYECCION_LISTADO_ARTICULAN_INICAL_COLUMNS,
  PROYECCION_LISTADO_ARTICULAN_INICAL_FILTERS,
  PROYECCION_LISTADO_PROMOCIONAN_COLUMNS,
  PROYECCION_LISTADO_PROMOCIONAN_FILTERS,
} from './Tabs/proyeccion/params';
import {
  BoletinesECCalificadosReport,
  CalificacionesDesaprobadasReport,
  MatriculacionCantidadEstudiantesReport,
  MatriculacionListadoEstudiantesReport,
  MatriculacionSeccionAnioReport,
  PresentismoEstudianteReport,
  ProyeccionArticulanReport,
  ProyeccionCantidadPromocionanReport,
  ProyeccionListadoPromocionanReport,
  TableColumn,
} from './types';

export const getColumns = (reportColumns: string[]) => {
  return Object.keys(TABLE_COLUMNS)
    .filter((key) => reportColumns.includes(key))
    .map((key) => ({
      id: key,
      label: TABLE_COLUMNS[key],
    }));
};

const reportsList: {
  [k: string]: {
    [k: string]: {
      tableKey: string;
      filters: FILTERS[];
      cannotSee: number[];
      levelPermissions: number[];
      tableColumns: TableColumn<
        | MatriculacionSeccionAnioReport
        | MatriculacionListadoEstudiantesReport
        | MatriculacionCantidadEstudiantesReport
        | ProyeccionCantidadPromocionanReport
        | ProyeccionListadoPromocionanReport
        | ProyeccionArticulanReport
        | CalificacionesDesaprobadasReport
        | PresentismoEstudianteReport
        | BoletinesECCalificadosReport,
        string | React.ReactNode
      >[];
      label: string;
    };
  };
} = {
  matriculacion: {
    matriculadosSeccionAnio: {
      cannotSee: [ROL.PRECEPTOR],
      filters: MATRICULADOS_SECCION_ANIO_FILTERS,
      levelPermissions: [level.INICIAL, level.PRIMARIO, level.SECUNDARIO],
      tableColumns: MATRICULADOS_SECCION_ANIO_COLUMNS,
      label: 'Matriculados por sección/año',
    },
    estudiantesInscriptos: {
      cannotSee: [ROL.PRECEPTOR],
      filters: MATRICULADOS_LISTADO_INSCRIPTOS_FILTERS,
      levelPermissions: [level.INICIAL, level.PRIMARIO, level.SECUNDARIO],
      tableColumns: MATRICULADOS_LISTADO_INSCRIPTOS_COLUMNS,
      label: 'Listado de estudiantes inscriptos',
    },
    listadoDesmatriculados: {
      cannotSee: [ROL.PRECEPTOR],
      filters: MATRICULADOS_DESMATRICULADOS_FILTERS,
      levelPermissions: [level.INICIAL, level.PRIMARIO, level.SECUNDARIO],
      tableColumns: MATRICULADOS_DESMATRICULADOS_COLUMNS,
      label: 'Historial de alumnos desmatriculados',
    },
    matriculacionCantidadAlumnosSeccion: {
      cannotSee: [ROL.PRECEPTOR],
      filters: MATRICULADOS_ESTUDIANTES_COLEGIO_FILTERS,
      levelPermissions: [level.INICIAL, level.PRIMARIO, level.SECUNDARIO],
      tableColumns: MATRICULADOS_ESTUDIANTES_COLEGIO_COLUMNS,
      label:
        'Cantidad de estudiantes en todo el colegio, por año, por sección y detallando plan de estudio',
    },
  },
  proyeccion: {
    proyeccionEstadoCuantitativo: {
      cannotSee: [ROL.PRECEPTOR],
      filters: PROYECCION_CANT_ESTU_PROMOCIONAN_FILTERS,
      levelPermissions: [level.INICIAL, level.PRIMARIO, level.SECUNDARIO],
      tableColumns: PROYECCION_CANT_ESTU_PROMOCIONAN_COLUMNS,
      label:
        'Cantidad y porcentajes de estudiantes que pasan de año o promocionan y de aquellos que permanecen',
    },
    proyeccionEstadoAño: {
      cannotSee: [ROL.PRECEPTOR],
      filters: PROYECCION_LISTADO_PROMOCIONAN_FILTERS,
      levelPermissions: [level.INICIAL, level.PRIMARIO, level.SECUNDARIO],
      tableColumns: PROYECCION_LISTADO_PROMOCIONAN_COLUMNS,
      label:
        'Listado de estudiantes que pasan de año o promocionan y de aquellos que permanecen',
    },
    proyeccionArticulanInicial: {
      cannotSee: [ROL.PRECEPTOR],
      filters: PROYECCION_LISTADO_ARTICULAN_INICAL_FILTERS,
      levelPermissions: [level.INICIAL],
      tableColumns: PROYECCION_LISTADO_ARTICULAN_INICAL_COLUMNS,
      label: 'Listado de Estudiantes que articulan en el nivel inicial',
    },
  },
  calificaciones: {
    calificacionesDesaprobadas: {
      cannotSee: [ROL.PRECEPTOR],
      tableKey: 'documento',
      filters: CALIFICACIONES_ESTU_CALIFICACIONES_FILTERS,
      levelPermissions: [level.PRIMARIO, level.SECUNDARIO],
      tableColumns: CALIFICACIONES_ESTU_CALIFICACIONES_COLUMNS,
      label:
        'Listado de estudiantes con calificaciones desaprobadas por materia / año / seccion / periodo',
    },
    calificacionesDeudaAcademica: {
      cannotSee: [ROL.PRECEPTOR],
      filters: CALIFICACIONES_EST_PREVIA_CARGADA_FILTERS,
      levelPermissions: [level.INICIAL, level.PRIMARIO, level.SECUNDARIO],
      tableColumns: CALIFICACIONES_EST_PREVIA_CARGADA_COLUMNS,
      label:
        'Listado de estudiantes con calificaciones previas cargadas por periodo',
    },
    calificacionesVinculoPedagogico: {
      cannotSee: [ROL.PRECEPTOR],
      filters: CALIFICACIONES_EST_SIN_VINCULO_FILTERS,
      levelPermissions: [level.PRIMARIO, level.SECUNDARIO],
      tableColumns: CALIFICACIONES_EST_SIN_VINCULO_COLUMNS,
      label:
        'Listado de estudiantes que no tienen contacto a vínculo pedagógico por bimestre',
    },
    calificacionesAprobadosCuantitativo: {
      cannotSee: [ROL.PRECEPTOR],
      filters: CALIFICACIONES_EST_APROB_DESAPRO_FILTERS,
      levelPermissions: [level.PRIMARIO, level.SECUNDARIO],
      tableColumns: CALIFICACIONES_EST_APROB_DESAPRO_COLUMNS,
      label:
        'Porcentaje de aprobados y desaprobados en los establecimientos por materia / año / seccion / periodo',
    },
  },
  boletines: {
    calificacionesEstado: {
      cannotSee: [],
      filters: BOLETINES_EC_CALIFICADOS_FILTERS,
      levelPermissions: [level.PRIMARIO, level.SECUNDARIO],
      tableColumns: BOLETINES_EC_CALIFICADOS_COLUMNS,
      label: 'Cantidad de espacios curriculares calificados y sin calificar.',
    },
  },
  presentismo: {
    presentismoDia: {
      cannotSee: [ROL.PRECEPTOR],
      filters: PRESENTISMO_DIA_FILTERS,
      levelPermissions: [level.INICIAL, level.PRIMARIO, level.SECUNDARIO],
      tableColumns: PRESENTISMO_DIA_COLUMNS,
      label: 'Reporte de presentismo por estudiante por día',
    },
    presentismoSemana: {
      cannotSee: [ROL.PRECEPTOR],
      filters: PRESENTISMO_SEMANA_FILTERS,
      levelPermissions: [level.INICIAL, level.PRIMARIO, level.SECUNDARIO],
      tableColumns: PRESENTISMO_SEMANA_COLUMNS,
      label: 'Reporte de presentismo por estudiante por semana',
    },
    presentismoMes: {
      cannotSee: [ROL.PRECEPTOR],
      filters: PRESENTISMO_MES_FILTERS,
      levelPermissions: [level.INICIAL, level.PRIMARIO, level.SECUNDARIO],
      tableColumns: PRESENTISMO_MES_COLUMNS,
      label: 'Reporte de presentismo por estudiante por mes',
    },
    presentismoSeccionDia: {
      cannotSee: [ROL.PRECEPTOR],
      filters: PRESENTISMO_SECCION_DIA_FILTERS,
      levelPermissions: [level.INICIAL, level.PRIMARIO, level.SECUNDARIO],
      tableColumns: PRESENTISMO_SECCION_DIA_COLUMNS,
      label: 'Reporte de presentismo por seccion por dia',
    },
    presentismoSeccionSemana: {
      cannotSee: [ROL.PRECEPTOR],
      filters: PRESENTISMO_SECCION_SEMANA_FILTERS,
      levelPermissions: [level.INICIAL, level.PRIMARIO, level.SECUNDARIO],
      tableColumns: PRESENTISMO_SECCION_SEMANA_COLUMNS,
      label: 'Reporte de presentismo por seccion por semana',
    },
    presentismoSeccionMes: {
      cannotSee: [ROL.PRECEPTOR],
      filters: PRESENTISMO_SECCION_MES_FILTERS,
      levelPermissions: [level.INICIAL, level.PRIMARIO, level.SECUNDARIO],
      tableColumns: PRESENTISMO_SECCION_MES_COLUMNS,
      label: 'Reporte de presentismo por seccion por mes',
    },
    // porcentaje_estados_presentismo: {
    //   filters: CANT_PORC_PRESENTISMO_FILTERS,
    //   permissions: [level.INICIAL, level.PRIMARIO, level.SECUNDARIO],
    //   tableColumns: CANT_PORC_PRESENTISMO_COLUMNS,
    //   label:
    //     'Cantidad y Porcentaje de estados de presentismo por estudiante, por mes, por periodo de calificacion y por año',
    // },
  },
};

export default reportsList;
