const objectToString = Object.prototype.toString;

const isError = (value: Error) =>
  objectToString.call(value) === '[object Error]';
// This is for libby errors
export const errorMessages = new Set([
  'TypeError: Failed to fetch', // Chrome
  'TypeError: NetworkError when attempting to fetch resource.', // Firefox
  'TypeError: The Internet connection appears to be offline.', // Safari 16
  'TypeError: Load failed', // Safari 17+
  'TypeError: Network request failed', // `cross-fetch`
  'TypeError: fetch failed', // Undici (Node.js)
  // This is for DAOs errors
  'Error: TypeError: Failed to fetch', // Chrome
  'Error: TypeError: NetworkError when attempting to fetch resource.', // Firefox
  'Error: TypeError: The Internet connection appears to be offline.', // Safari 16
  'Error: TypeError: Load failed', // Safari 17+
  'Error: TypeError: Network request failed', // `cross-fetch`
  'Error: TypeError: fetch failed', // Undici (Node.js)
]);

export function isNetworkError(error: Error) {
  const isValid =
    error &&
    isError(error) &&
    error.name === 'Error' &&
    typeof error.message === 'string';

  if (!isValid) {
    return false;
  }

  // We do an extra check for Safari 17+ as it has a very generic error message.
  // Network errors in Safari have no stack.
  if (error.message === 'TypeError: Load failed') {
    return error.stack === undefined;
  }

  return errorMessages.has(error.message);
}
