import React from 'react';
import {
  NewDiscipOrientPlan,
  OrientDiscipTempPlan,
} from '../../../../../../../../app/models';
import { useOrientDiscipTempPlanFetchById } from '../../../../../../../../app/business';
import { useDisciplinarOrientadaContext } from '../Context';

const initialValues: NewDiscipOrientPlan = {
  bloque: {
    bloques: [],
  },
  ensenanza: {
    propositosDeEnsenanza: [],
  },
  objetivosDeAprendizaje: {
    objetivosDeAprendizaje: [],
  },
  indicadoresEvaluacion: {
    indicadoresEvaluacion: [],
  },
  primerBimestre: {
    primerBimestre: [
      {
        bloque: [],
        habilidad: [],
        contenidosEspecificos: [],
        recursos: [],
        instrumentosEvaluacion: [],
        actividad: '',
        descripcion: '',
        horasCatedra: '',
        // TODO: this must be an array of string
        seccion: [],
      },
    ],
  },
  segundoBimestre: {
    segundoBimestre: [
      {
        bloque: [],
        habilidad: [],
        contenidosEspecificos: [],
        recursos: [],
        instrumentosEvaluacion: [],
        actividad: '',
        descripcion: '',
        horasCatedra: '',
        // TODO: this must be an array of string
        seccion: [],
      },
    ],
  },
  tercerBimestre: {
    tercerBimestre: [
      {
        bloque: [],
        habilidad: [],
        contenidosEspecificos: [],
        recursos: [],
        instrumentosEvaluacion: [],
        actividad: '',
        descripcion: '',
        horasCatedra: '',
        // TODO: this must be an array of string
        seccion: [],
      },
    ],
  },
  cuartoBimestre: {
    cuartoBimestre: [
      {
        bloque: [],
        habilidad: [],
        contenidosEspecificos: [],
        recursos: [],
        instrumentosEvaluacion: [],
        actividad: '',
        descripcion: '',
        horasCatedra: '',
        // TODO: this must be an array of string
        seccion: [],
      },
    ],
  },
  bibliografia: { bibliografia: [] },
};

export type childrenFunc = ({
  template,
  working,
  initialValues,
}: {
  template?: OrientDiscipTempPlan;
  working: boolean;
  initialValues: NewDiscipOrientPlan;
}) => JSX.Element;

export interface PlanificacionDisciplinarOrientadaNewProps {
  children: childrenFunc;
  templateId: string | number;
}

export const PlanificacionDisciplinarOrientadaNew = ({
  children,
  templateId,
}: PlanificacionDisciplinarOrientadaNewProps) => {
  const { data: template, working } =
    useOrientDiscipTempPlanFetchById(templateId);
  const { working: loading } = useDisciplinarOrientadaContext();

  return (
    <>{children({ template, initialValues, working: working || loading })}</>
  );
};
