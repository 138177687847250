import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSnackbar } from 'notistack';
import { Grid, makeStyles } from '@material-ui/core';
import { AnyObject, LibbyObject, useLibbyCall } from 'src/commons';
import { Footer, InfoTable, OptionsModal } from 'src/commons/components';
import { alumnEnrolledStatus, level, schoolYear } from 'src/commons/const';
import { useLibbyFetch } from 'src/commons/hooks';
import confirmDialog from 'src/commons/services/confirmDialog';
import customFormDialog from 'src/commons/services/customFormDialog';
import { useRolesContext } from 'src/context/RolesContext';
import { useDebounce } from 'src/commons/hooks/useDebounce';
import { useLegajoLogic } from 'src/screens/Private/LegajoAlumno';
import {
  useInscripcionAlumnoLibbyFetch,
  useInscripcionAlumnoDAO,
  useInscripcionAlumnoCustomDAO,
} from 'src/app/business';
import { primary } from 'src/theme/colors';
import { ROL } from 'src/commons/const/rol';
import { FilterTablesEnrolled, SeccionSelect } from './components';
import { EnrolledAlumnModal } from '../components';
import { EnrolledAddStudentModal } from '../components/EnrolledAddStudentModal';

const useStyles = makeStyles({
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
  infoTableCustom: {
    marginBottom: '80px',
  },
  alumnoButton: {
    color: primary.lightBlue,
    margin: '0',
    background: 'none',
    textAlign: 'left',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

const ByCenrolledRaw = ({ libby }: LibbyObject) => {
  const roleContext = useRolesContext();
  const { enqueueSnackbar } = useSnackbar();
  const inscriocionAlumnoCustonDAO = useInscripcionAlumnoCustomDAO();
  const classes = useStyles();

  const { localizacionId, rol } = roleContext.selectedRole;
  const filterLocalization = [{ path: 'localizacion', value: localizacionId }];
  const { current: filterEstadoMatricula } = useRef([
    {
      path: 'alumno.estadoMatricula.idEstadoMatricula',
      value: alumnEnrolledStatus.INSCRIPTO,
    },
  ]);
  const { current: filterIsDeleted } = useRef([
    { path: 'isDeleted', value: false },
  ]);
  const [direction, setDirection] = React.useState<'asc' | 'desc'>('asc');
  const [alumnRows, setAlumnRows] = useState<AnyObject[]>([]);
  const [orderBy, setOrderBy] = useState('alumno.persona.apellido');
  const [alumnsWithSections, setAlumnsWithSections] = useState<any>([]);
  const [filterLibby, setFilterLibby] = useState<AnyObject>({
    filterLocalization,
    filterEstadoMatricula,
    filterIsDeleted,
  });
  const [formValues, setFormValues] = useState({
    id_nivel: '',
    id_turno: '',
  });
  const [registersCount, setRegistersCount] = useState(0);
  const inscripcionAlumnoDAO = useInscripcionAlumnoDAO();

  useEffect(() => {
    inscripcionAlumnoDAO
      .getCountFilter(filterLibby)
      .then((response: { countofidinscripcionalumno: number }[]) => {
        if (!!response) {
          setRegistersCount(response[0]?.countofidinscripcionalumno);
        }
      });
  }, [inscripcionAlumnoDAO, filterLibby]);

  const [search, setSearch] = useState('');
  const searchDebounced = useDebounce(search, 1000);
  const pathSearch = useMemo(
    () => [
      {
        path: 'alumno.persona.apellido',
        value: searchDebounced,
        method: 'includes',
      },
      {
        path: 'alumno.persona.nombre',
        value: searchDebounced,
        method: 'includes',
      },
      {
        path: 'alumno.persona.documento',
        value: searchDebounced,
        method: 'includes',
      },
      {
        path: 'nivel.descripcionNivel',
        value: searchDebounced,
        method: 'includes',
      },
      {
        path: 'turno.descripcionTurno',
        value: searchDebounced,
        method: 'includes',
      },
    ],
    [searchDebounced],
  );

  const {
    data: inscripcionAlumno = [],
    working,
    fetchMore,
    reFetch,
  } = useInscripcionAlumnoLibbyFetch({
    orderBy,
    direction,
    filter: filterLibby,
    limit: 1000,
  });
  const { data: seccion } = useLibbyCall(libby, {
    daoName: 'seccion',
    methodName: 'getByLocationId',
    params: [localizacionId],
  });
  const { data: nivel } = useLibbyFetch(libby, { daoName: 'nivel' });
  const { data: turno } = useLibbyFetch(libby, { daoName: 'turno' });

  const filterLevel = nivel.filter(
    (item: AnyObject) =>
      item.idNivel === level.INICIAL ||
      item.idNivel === level.PRIMARIO ||
      item.idNivel === level.SECUNDARIO,
  );

  const handleChangeSection = useCallback(
    (e: React.FormEvent<EventTarget>, alumno: AnyObject) => {
      const target = e.target as HTMLInputElement;
      const updatedAlumnRow = alumnRows.map((item: AnyObject) =>
        item.idAlumno === alumno.idAlumno
          ? { ...item, idSeccion: target.value }
          : item,
      );
      setAlumnRows(updatedAlumnRow);
      const updatedAlumn = { ...alumno, idSeccion: target.value };
      if (!alumnsWithSections.length) {
        setAlumnsWithSections([updatedAlumn]);
      } else {
        const newArray = alumnsWithSections.reduce(
          (acc: any, item: AnyObject) => {
            if (
              acc.findIndex((i: AnyObject) => i.idAlumno === item.idAlumno) !==
                -1 ||
              // @ts-ignore
              updatedAlumn.idAlumno === item.idAlumno
            ) {
              return acc;
            }
            return [...acc, item];
          },
          [updatedAlumn],
        );
        setAlumnsWithSections(newArray);
        return newArray;
      }
    },
    [alumnsWithSections, alumnRows],
  );

  const removeAlumn = useCallback(
    async (nombre: string, idInscripcionAlumno: number) => {
      try {
        const confirm = await confirmDialog.show({
          title: 'Dar de baja',
          content: `¿Estás seguro que desea dar de baja a ${nombre}?`,
          confirmText: 'Confirmar',
          cancelText: 'Cancelar',
        });
        if (confirm) {
          customFormDialog.show({
            title: 'Motivo de baja',
            renderComponent: (
              <EnrolledAlumnModal
                alumn={idInscripcionAlumno}
                reFetch={reFetch}
              />
            ),
            sizeWidth: 'md',
          });
        }
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Ha ocurrido un error al intentar guardar', {
          variant: 'error',
        });
      }
    },
    [reFetch, enqueueSnackbar],
  );

  useEffect(() => {
    const alumns = inscripcionAlumno.map((item) => ({
      idAlumno: item?.alumno?.idAlumno,
      idInscripcionAlumno: item?.idInscripcionAlumno,
      nombre: `${item?.alumno?.persona?.apellido}, ${item?.alumno?.persona?.nombre}`,
      documento: item?.alumno?.persona?.documento,
      idAnio: item?.anio?.idAnio,
      idCiclo: item?.ciclo?.idCiclo,
      idNivel: item?.nivel?.idNivel,
      descripcionNivel: item?.nivel?.descripcionNivel,
      idTurno: item?.turno?.descripcionTurno,
      idSeccion: null,
      selectedSeccion: null,
    }));

    setAlumnRows(alumns);
  }, [inscripcionAlumno, seccion]);

  const { toLegajoAlumno } = useLegajoLogic();

  const rows = useMemo(
    () =>
      alumnRows.map((item: AnyObject) => ({
        ...item,
        selectedSeccion: (
          <SeccionSelect
            content={seccion.filter(
              (_item: AnyObject) => _item.nivel?.idNivel === item.idNivel,
            )}
            value={item.idSeccion}
            handleChange={(e: React.FormEvent<EventTarget>) =>
              handleChangeSection(e, item)
            }
          />
        ),
        nombre: (
          <button
            type="button"
            className={classes.alumnoButton}
            onClick={() => toLegajoAlumno(item?.idAlumno)}
          >
            {item?.nombre}
          </button>
        ),
        icon: (
          <OptionsModal
            options={[
              {
                label: 'Dar de baja',
                onClick: () =>
                  removeAlumn(item.nombre, item.idInscripcionAlumno),
              },
              {
                label: 'Ver legajo',
                onClick: () => toLegajoAlumno(item?.idAlumno),
              },
            ]}
          />
        ),
      })),
    [
      alumnRows,
      seccion,
      classes.alumnoButton,
      handleChangeSection,
      toLegajoAlumno,
      removeAlumn,
    ],
  );

  const filterTables = useCallback(
    (filterSearch: any) => {
      if (formValues.id_turno || formValues.id_nivel) {
        const toReformFormValues = Object.entries(formValues).map(
          (filter: AnyObject) =>
            filter[1] && [{ path: filter[0], value: filter[1] }],
        );
        const toObjectLibby = {
          ...filterLibby,
          filterLocalization,
          filterSearch,
          ...toReformFormValues,
        };
        setFilterLibby(toObjectLibby);
      } else {
        setFilterLibby({
          ...filterLibby,
          0: filterLocalization,
          1: filterSearch,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formValues],
  );

  useEffect(() => {
    filterTables(pathSearch);
  }, [filterTables, formValues, pathSearch]);

  const column = [
    {
      id: 'nombre',
      label: 'Apellido y nombre',
      width: '10%',
      orderById: 'alumno.persona.apellido',
    },
    {
      id: 'documento',
      label: 'Documento',
      width: '5%',
      orderById: 'alumno.persona.documento',
    },
    {
      id: 'descripcionNivel',
      label: 'Nivel',
      width: '5%',
      orderById: 'nivel.descripcionNivel',
    },
    {
      id: 'idTurno',
      label: 'Turno solicitado',
      width: '5%',
      orderById: 'turno.descripcionTurno',
    },
    {
      id: 'selectedSeccion',
      label: 'Grupo a matricular',
      width: '10%',
      hideSortIcon: true,
      noSort: true,
    },
    {
      id: 'icon',
      label: '',
      width: '5%',
      hideSortIcon: true,
      noSort: true,
    },
  ];

  const handleChange = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setDirection(newDirection);
    setOrderBy(newOrderBy);
  };

  const handleSave = useCallback(async () => {
    const confirm = await confirmDialog.show({
      title: 'Matricular',
      content:
        '¿Está seguro que desea matricular al/los alumno(s) seleccionado(s)?',
      confirmText: 'Confirmar',
      cancelText: 'Cancelar',
    });
    if (confirm) {
      await inscriocionAlumnoCustonDAO.matricular(alumnsWithSections);

      const newAlumnRows = alumnRows.filter(
        (item: AnyObject) => item.idSeccion === null,
      );
      setAlumnRows(newAlumnRows);
      setAlumnsWithSections([]);
      reFetch();
    }
  }, [alumnRows, alumnsWithSections, reFetch, inscriocionAlumnoCustonDAO]);

  const handleChangeSearch = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setSearch(target.value);
  };

  const buttonConfig: any = [
    {
      title: 'Matricular',
      handleOnClick: () => handleSave(),
      size: 'large',
    },
  ];

  const handleAddStudent = () => {
    customFormDialog.show({
      title: 'Agregar Estudiante',
      renderComponent: (
        <EnrolledAddStudentModal
          reFetch={reFetch}
          localizacionId={localizacionId}
          toLegajoAlumno={toLegajoAlumno}
        />
      ),
      sizeWidth: 'md',
      customModalServices: {
        '& .MuiPaper-root': { overflowY: 'auto', overflowX: 'hidden' },
      },
    });
  };

  return (
    <>
      <FilterTablesEnrolled
        level={filterLevel}
        turn={turno}
        registersCount={registersCount}
        handleChange={handleChange}
        handleChangeSearch={handleChangeSearch}
        search={search}
        formValues={formValues}
        titleMainButton={
          rol === ROL.DIRECCION_AREA.toString()
            ? 'Agregar Estudiante'
            : undefined
        }
        handleOnClick={handleAddStudent}
      />
      <>
        <InfoTable
          rows={rows}
          columns={column}
          working={working}
          rowIdKey="idScaleBCAlumn"
          direction={direction}
          onSortChange={handleRequestSort}
          onBottomScroll={fetchMore}
          customStyle={classes.infoTableCustom}
        />
        <Grid className={classes.footer}>
          <Footer buttonConfig={buttonConfig} />
        </Grid>
      </>
    </>
  );
};

export const ByCenrolled = DatabaseConnector(ByCenrolledRaw)(
  'nivel',
  'turno',
  'alumno',
  'inscripcion_alumno',
  'alumno_movimiento',
  'seccion',
);
