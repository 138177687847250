import { useEffect, useState } from 'react';
import { LibbyFetchDAO } from 'src/platform/libby/dao';

/**
 * Hook que hace un request de un registro con id
 * espera y lo almacena en un state.
 */
export const useMountFetchById = <T, DAO extends LibbyFetchDAO>(
  dao: DAO,
  id: string,
  aspect?: string,
) => {
  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean | null>(null);

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      setLoading(true);
      try {
        const results = await dao.fetchById(id, aspect);
        if (mounted) {
          setData(results);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setData([]);
      }
    };

    fetch();

    return () => {
      mounted = false;
    };
  }, [id, dao, aspect]);

  return {
    data,
    loading,
  };
};
