import { Anio } from './Anio';
import { Ciclo } from './Ciclo';
import { CicloAdultos } from './CicloAdultos';
import { CicloLectivo } from './CicloLectivo';
import { CicloLectivoAdultos } from './CicloLectivoAdultos';
import { Localizacion } from './Localizacion';
import { Nivel } from './Nivel';
import { PlanEstudioNivel } from './PlanEstudioNivel';
import { Turno } from './Turno';
import { UnidadEducativa } from './UnidadEducativa';

export type SeccionWithProyeccion = Seccion & {
  tieneCalificaciones: boolean;
  tieneEstudiantes: boolean;
  tieneDefinitivas: boolean;
  calificacionesCerradas: boolean;
};

export const JORNDADAS = ['Simple', 'Completa', 'Extendida'];

export enum JORNADA {
  SIMPLE = 'Simple',
  COMPLETA = 'Completa',
  EXTENDIDA = 'Extendida',
}

export interface Seccion {
  idSeccion: string;
  nombreSeccion: string;
  jornada: string;
  capacidadRecomendada: number;
  capacidadMaxima: number;
  division: string;
  anio: Anio;
  ciclo: Ciclo;
  cicloAdultos: CicloAdultos;
  cicloLectivo: CicloLectivo;
  cicloLectivoAdultos: CicloLectivoAdultos;
  localizacion: Localizacion;
  nivel: Nivel;
  planEstudioNivel: PlanEstudioNivel;
  turno: Turno;
  unidadEducativa: UnidadEducativa;
  copiedTo: number | null;
  matCount?: number;
}

export interface MatriculacionSeccion extends Seccion {
  hasProyectados: boolean;
  hasProyecciones: boolean;
}
