import React from 'react';
import { Grid, Button, makeStyles, Typography } from '@material-ui/core';
import { AnyObject } from 'src/commons/types';
import customFormDialog from 'src/commons/services/customFormDialog';
import { DireccionAreaForm } from './DireccionAreaForm';

export interface DireccionAreaModalProps {
  roleInformation: AnyObject;
  libby: any;
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    marginTop: theme.spacing(-8),
    marginRight: theme.spacing(4),
    position: 'relative',
  },
  modalContainer: {
    padding: theme.spacing(2),
  },
}));

const handleCloseModal = async () => {
  await customFormDialog.handleCancel();
};

export const DireccionAreaModal = ({
  roleInformation,
  libby,
}: DireccionAreaModalProps) => {
  const classes = useStyles();
  return (
    <Grid container className={classes.modalContainer}>
      <Grid container>
        <Grid item container style={{ paddingLeft: 35, marginBottom: 30 }}>
          <Typography variant="h3">Seleccione Institución</Typography>
        </Grid>
        <Grid item container justify="flex-end">
          <Button className={classes.closeButton} onClick={handleCloseModal}>
            X
          </Button>
        </Grid>
      </Grid>
      <DireccionAreaForm roleInformation={roleInformation} libby={libby} />
    </Grid>
  );
};
