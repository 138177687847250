import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class JustificacionMotivoDAO extends LibbyFetchDAO {
  constructor() {
    super('justificacionMotivos', 'idMotivoJustificacion');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
