import { routes } from './routes';
import { MenuCollection } from '../../../commons/const/ui/menu';

export const sinEstablecimientoMenu: MenuCollection = [
  {
    key: 'sinEstablecimiento',
    basePath: '/establecimientos',
    enabled: true,
    icon: 'List',
    text: 'Establecimientos',
    children: routes,
    default: true,
  },
];
