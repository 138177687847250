import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { OrientDiscipTempPlan } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'orient_discip_temp_plan';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idOrientDiscipTempPlan',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idOrientDiscipTempPlan',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.OrientDiscipTempPlan
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.OrientDiscipTempPlan,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.OrientDiscipTempPlan.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<OrientDiscipTempPlan>(DAO_NAME, optionsToUse);

// hooks
export const useOrientDiscipTempPlanFetchById = fetchById;
export const useOrientDiscipTempPlanLibbyCall = libbyCall;
export const useOrientDiscipTempPlanDAO = libbyDAO;
export const useOrientDiscipTempPlanLibbyFetch = libbyFetch;

// components/hoc
export const OrientDiscipTempPlanAutocomplete = entityAutocomplete;
export const OrientDiscipTempPlanCall = renderPropCall;
export const OrientDiscipTempPlanById = renderPropFetchById;
export const OrientDiscipTempPlanSelect = simpleSelect;
export const OrientDiscipTempPlanAccordionSelector = accordionSelector;
export const OrientDiscipTempPlanSelectedChips = selectedChips;

// context
// ByIdContext
export const useOrientDiscipTempPlanByIdContext = useByIdContext;
export const OrientDiscipTempPlanByIdProvider = ByIdProvider;
export const OrientDiscipTempPlanByIdContext = ByIdContext;
// GeneralContext
export const useOrientDiscipTempPlanContext = useGeneralContext;
export const OrientDiscipTempPlanProvider = GeneralProvider;
export const OrientDiscipTempPlanContext = GeneralContext;
