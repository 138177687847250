import { Grid, Typography } from '@material-ui/core';
import React, { FC, useEffect, useMemo, useState } from 'react';
import {
  useDevolucionContenidosLibbyCall,
  useDevolucionHabilidadesLibbyCall,
  useDevolucionPerfilesLibbyCall,
  useDevolucionesEvaluacionesLibbyCall,
} from 'src/app/business/acap';
import {
  Loading,
  MainInput,
  SimpleSelect,
  TRAVERSE_ACTIONS,
} from 'src/commons';
import { useRolesContext } from 'src/context/RolesContext';
import typography from 'src/theme/typography';
import { useEstudianteContext } from '../../contexts/EstudianteContext';
import {
  usePersonaLibbyCall,
  useUsuarioRolTraverseActionLibbyCall,
} from 'src/app/business';
import {
  DevolucionContenido,
  DevolucionEvaluacion,
  Persona,
} from 'src/app/models';
import DianaChart from 'src/commons/components/Diana/DianaChart';
import { useRadarStyles } from './DevolucionOrganizacion';
import { rebuildBloques } from '../../../AcapEscuela/functions/rebuildBloques';
import { Valor } from '../../../AcapEscuela/types';
import { Eje, Nodo } from '../../../../../app/models/orientaciones';
import { getCommonValues } from '../../../../../utils';
import { cloneDeep, set } from 'lodash';

const getBloques = (
  currentEje: number,
  nodos: Nodo[],
  evaluaciones: DevolucionEvaluacion[],
  devolucionContenido: DevolucionContenido,
) => {
  const bloques = rebuildBloques(
    nodos
      .filter((nodo) => nodo.eje.idEje === currentEje)
      .map((nodo) => {
        const valor = evaluaciones
          .filter(
            (eva) =>
              eva.tab === 'contenidos' &&
              eva.idDevolucion ===
                devolucionContenido.idAutoevaluacionContenido,
          )
          .find((eva) => eva.idTipo.toString() === nodo.idNodo.toString());
        return {
          ...nodo,
          devolucion: valor as Valor,
        };
      }),
  );
  return bloques;
};

export const DevolucionDocente: FC<
  Acap.Estudiante.DevolucionDocenteTabProps
> = ({ actividad, organizacion }) => {
  const classes = useRadarStyles();
  const {
    data: inscripcionNodos,
    grupos,
    loadingGrupos,
    loadingListNodos,
  } = useEstudianteContext();
  const {
    selectedRole: { localizacionId },
    userInfo: { id: userId },
  } = useRolesContext();

  const [currentEje, setCurrentEje] = useState(0);

  const grupo = useMemo(
    () =>
      grupos.find(
        (grupo) =>
          grupo.ofertaGrupo.ofertaTurno.oferta.accion.idAccion === actividad &&
          grupo.ofertaGrupo.ofertaTurno.oferta.accion.organizacion
            .idOrganizacion === organizacion,
      )!,
    [actividad, grupos, organizacion],
  );

  const devFilter = useMemo(
    () => ({
      estudiante: [
        {
          path: 'grupoInscripcion.alumnoMovimiento.alumno.persona.cuentas',
          value: userId,
        },
      ],
      grupo: [
        {
          path: 'grupoInscripcion',
          value: grupo?.idGrupoInscripcion,
        },
      ],
      created: [
        {
          path: 'createdBy',
          value: userId,
          method: 'notEquals',
        },
      ],
    }),
    [grupo, userId],
  );

  const {
    data: perfiles = [],
    working: wPerfiles,
    recall: rcallPerfiles,
  } = useDevolucionPerfilesLibbyCall({
    methodName: 'fetch',
    noAutoCall: true,
    params: [],
    aspect: 'basic',
  });

  const {
    data: habilidades = [],
    working: wHabilidades,
    recall: rcallHabilidades,
  } = useDevolucionHabilidadesLibbyCall({
    methodName: 'fetch',
    noAutoCall: true,
    params: [],
    aspect: 'basic',
  });

  const {
    data: contenidos = [],
    working: wContenidos,
    recall: recallContenidos,
  } = useDevolucionContenidosLibbyCall({
    methodName: 'fetch',
    noAutoCall: true,
    params: [],
    aspect: 'basic',
  });

  const {
    data: evaluaciones = [],
    working: wEvaluaciones,
    recall: recallEvaluaciones,
  } = useDevolucionesEvaluacionesLibbyCall({
    methodName: 'fetch',
    noAutoCall: true,
    params: [],
  });

  /// Cuando comparten un EJE entrer dos docentes,
  const {
    data: urtas = [],
    working: wUrtas,
    recall: recallUrtas,
  } = useUsuarioRolTraverseActionLibbyCall({
    methodName: 'fetch',
    noAutoCall: true,
    params: [],
  });

  const {
    data: personas = [],
    working: wPersonas,
    recall: recallPersonas,
  } = usePersonaLibbyCall({
    methodName: 'fetch',
    noAutoCall: true,
    params: [],
  });

  const loadingDev = useMemo(
    () => wHabilidades || wPerfiles || wContenidos || wEvaluaciones || wUrtas,
    [wPerfiles, wHabilidades, wContenidos, wEvaluaciones, wUrtas],
  );

  useEffect(() => {
    if (
      !(
        Boolean(devFilter.grupo[0].value) &&
        Boolean(devFilter.estudiante[0].value)
      )
    )
      return;
    const filter = {
      filter: devFilter,
      limit: 1,
      orderBy: 'updatedAt',
      direction: 'desc',
      aspect: 'basic',
    };
    rcallPerfiles(filter);
    rcallHabilidades(filter);
    recallContenidos({
      filter: devFilter,
      limit: 5,
      orderBy: 'updatedAt',
      direction: 'desc',
      aspect: 'basic',
    });
  }, [devFilter, rcallPerfiles, rcallHabilidades, recallContenidos]);

  useEffect(() => {
    if (wPerfiles || wHabilidades || wContenidos) return;
    const ids: number[] = [];
    if (perfiles.length > 0) ids.push(perfiles[0].idAutoevaluacionPerfil);
    if (habilidades.length > 0)
      ids.push(habilidades[0].idAutoevaluacionHabilidad);
    if (contenidos.length > 0)
      contenidos.forEach((dc) => {
        ids.push(dc.idAutoevaluacionContenido);
      });
    if (contenidos.length === 0 || ids.length === 0) return;
    recallEvaluaciones({
      filter: {
        devoluciones: [
          {
            path: 'idDevolucion',
            value: ids,
            method: 'in',
          },
        ],
      },
      limit: 2000,
    });

    const idDocentes: string[] = [];
    contenidos.forEach((dc) => {
      const id = dc.createdBy.idUsuario;
      const id2 = dc.updatedBy.idUsuario;
      idDocentes.push(id);
      if (id2) {
        idDocentes.push(id2);
      }
    });
    const _filter = {
      traverse: [{ path: 'traverseAction', value: TRAVERSE_ACTIONS.NODO }],
      docentes: [
        {
          path: 'usuarioRolEstablecimiento.usuario',
          value: idDocentes,
          method: 'in',
        },
      ],
      localizacion: [
        {
          path: 'usuarioRolEstablecimiento.localizacion',
          value: localizacionId,
        },
      ],
    };
    recallUrtas({
      filter: _filter,
      limit: 10000,
    });
    recallPersonas({
      filter: {
        usuario: [
          {
            path: 'cuentas',
            value: idDocentes,
            method: 'in',
          },
        ],
      },
      limit: 20,
    });
  }, [
    perfiles,
    habilidades,
    contenidos,
    wPerfiles,
    wHabilidades,
    wContenidos,
    localizacionId,
    recallEvaluaciones,
    recallUrtas,
    recallPersonas,
  ]);

  const ejes = useMemo<Eje[]>(() => {
    const ejesDuplicados = inscripcionNodos
      .filter((aao) => aao.accion.idAccion === actividad)
      .map((item) => Array.from(new Set(item.nodos.map((nodo) => nodo.eje))))
      .flat();
    return [...new Set(ejesDuplicados.map((eje) => JSON.stringify(eje)))].map(
      (str) => JSON.parse(str),
    );
  }, [inscripcionNodos, actividad]);

  const nodosAcciones = useMemo(() => {
    const _nodos: Nodo[] = [];
    inscripcionNodos
      .filter((ao) => ao.accion.idAccion === actividad)
      .forEach((accionOrientacion) =>
        accionOrientacion.nodos.forEach((nodo) => {
          if (!_nodos.find((_nodo) => _nodo.idNodo === nodo.idNodo)) {
            _nodos.push(nodo);
          }
        }),
      );
    return _nodos;
  }, [inscripcionNodos, actividad]);

  const ejesMapper = useMemo(() => {
    let _ejes: {
      [k: number]: {
        observacion: string;
        docente?: Persona;
        valores: { evaluacion: number; label: string }[];
      };
    } = {};

    contenidos.forEach((devc) => {
      const docentes = [{ id: devc.createdBy.idUsuario, ejes: [] }];
      if (
        devc.updatedBy &&
        devc.createdBy.idUsuario !== devc.updatedBy.idUsuario
      ) {
        docentes.push({ id: devc.updatedBy.idUsuario, ejes: [] });
      }

      const evals = evaluaciones.filter(
        (_eval) =>
          _eval.idDevolucion.toString() ===
            devc.idAutoevaluacionContenido.toString() &&
          _eval.tab === 'contenidos',
      );

      docentes.forEach((docente) => {
        const nodosDocente =
          urtas.find(
            (urta) =>
              urta.usuarioRolEstablecimiento.usuario.idUsuario === docente.id,
          )?.value || [];
        const commoNodos = getCommonValues(
          nodosAcciones.map((nodo) => nodo.idNodo.toString()),
          nodosDocente,
        );

        const _docente = personas.find(
          (persona) => persona.cuentas.idUsuario === docente.id,
        );

        for (const eje of ejes) {
          const bloques = getBloques(
            Number(eje.idEje || 0),
            nodosAcciones.filter((nodo) =>
              commoNodos.includes(nodo.idNodo.toString()),
            ),
            evals,
            devc,
          );
          const contenidoValores: { evaluacion: number; label: string }[] = [];
          bloques.forEach((bloq) => {
            bloq.ejes.forEach((eje) => {
              eje.unidades.forEach((unid) => {
                unid.contenidos.forEach((cont) => {
                  contenidoValores.push({
                    evaluacion: cont?.devolucion?.evaluacion ?? 0,
                    label: cont.descripcion,
                  });
                });
              });
            });
          });
          if (contenidoValores.length === 0) continue;
          const _map = set(cloneDeep(_ejes), Number(eje.idEje || 0), {
            docente: _docente,
            observacion: devc.observaciones,
            valores: contenidoValores,
          });
          _ejes = { ..._map };
        }
      });
    });

    return _ejes;
  }, [contenidos, ejes, urtas, personas, evaluaciones, nodosAcciones]);

  const data = useMemo(() => {
    if (currentEje === 0) return [];

    return [
      {
        criterio: 'Perfil del Egresado',
        borderColor: '#24d983',
        color: '179,251,217',
        values: evaluaciones
          .filter(
            (eva) =>
              eva.tab === 'perfiles' &&
              perfiles[0].idAutoevaluacionPerfil === eva.idDevolucion,
          )
          .map((eva) => ({
            evaluacion: eva.evaluacion ?? 0,
            label:
              inscripcionNodos[0].perfiles.find(
                (perfil) =>
                  Number(perfil.idPerfilOrientado) === Number(eva.idTipo),
              )?.descripcion ?? '',
          })),
        observaciones: perfiles[0]?.observaciones ?? '',
      },
      {
        criterio: 'Habilidades, Capacidades y Competencias',
        borderColor: '#24d983',
        color: '179,251,217',
        values: inscripcionNodos[0].habilidades.reduce<any[]>((acum, item) => {
          const id = item.habilidadCategoria.idHabilidadCategoria;
          const valor = evaluaciones
            .filter(
              (eva) =>
                eva.tab === 'habilidades' &&
                eva.idDevolucion === habilidades[0].idAutoevaluacionHabilidad,
            )
            .find((eva) => eva.idTipo.toString() === id.toString());
          if (!acum.find((a) => a.id === id)) {
            acum.push({
              id,
              label: item.habilidadCategoria.nombre,
              evaluacion: valor?.evaluacion ?? 0,
            });
          }
          return acum;
        }, []),
        observaciones: habilidades[0]?.observaciones ?? '',
      },
      {
        criterio: 'Contenidos del eje',
        borderColor: '#24d983',
        color: '179,251,217',
        values: ejesMapper[currentEje]?.valores || [],
        observaciones: ejesMapper[currentEje]?.observacion ?? '',
      },
    ];
  }, [
    ejesMapper,
    currentEje,
    habilidades,
    perfiles,
    evaluaciones,
    inscripcionNodos,
  ]);

  return loadingGrupos || loadingListNodos || loadingDev ? (
    <Loading />
  ) : !Boolean(organizacion) || !Boolean(actividad) ? (
    <Typography>Seleccioná la acción y la organización para buscar.</Typography>
  ) : perfiles.length === 0 &&
    habilidades.length === 0 &&
    contenidos.length === 0 &&
    loadingDev !== null &&
    !loadingDev ? (
    <Typography>No hay devoluciones registradas</Typography>
  ) : Boolean(grupo) ? (
    <Grid container>
      <Grid item xs={12}>
        <Grid container style={{ padding: '15px 0px 10px 30px' }} spacing={2}>
          <Grid item xs={4}>
            <Grid container>
              <Grid
                item
                xs={6}
                style={{ display: 'flex', alignItems: 'center', gap: 4 }}
              >
                <Typography
                  style={{
                    fontFamily: typography.fontFamily,
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  Eje:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <SimpleSelect
                  customStyleTitle={{
                    fontFamily: typography.fontFamily,
                    fontSize: 14,
                    fontWeight: 600,
                    color: '#000000',
                  }}
                  labelKey="nombre"
                  valueKey="idEje"
                  content={ejes}
                  value={currentEje}
                  handleChange={(e: any) => setCurrentEje(e.target.value)}
                  customStyleContainer={{ width: 'auto' }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={4}
            style={{ display: 'flex', alignItems: 'center', gap: 4 }}
          >
            <Grid container>
              <Grid
                item
                xs={6}
                style={{ display: 'flex', alignItems: 'center', gap: 4 }}
              >
                <Typography
                  style={{
                    fontFamily: typography.fontFamily,
                    fontSize: 14,
                    fontWeight: 600,
                  }}
                >
                  Docente:
                </Typography>
              </Grid>
              <Grid item xs={6}>
                {wPersonas ? (
                  <Loading />
                ) : (
                  <MainInput
                    label=""
                    disabled
                    value={`${
                      ejesMapper[currentEje]?.docente?.apellido || ''
                    } ${ejesMapper[currentEje]?.docente?.nombre || ''}`}
                    handleChange={() => null}
                    placeholder={''}
                    fullWidth
                  />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {data.map((item) => {
        return (
          <Grid
            item
            xs={4}
            style={{
              padding: '10px 30px 15px 30px',
              justifyContent: 'space-between',
            }}
          >
            <Typography
              style={{
                fontFamily: typography.fontFamily,
                fontSize: 14,
                fontWeight: 600,
                textAlign: 'center',
              }}
            >
              {item.criterio}
            </Typography>
            <Grid
              item
              xs={12}
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <div className={classes.radar}>
                <DianaChart
                  title={item.criterio}
                  labels={item.values.map((value) => value.label)}
                  evaluaciones={item.values.map((value) => value.evaluacion)}
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <MainInput
                label="Observaciones"
                disabled
                value={item.observaciones}
                handleChange={() => null}
                placeholder={''}
                fullWidth
                multiline
              />
            </Grid>
          </Grid>
        );
      })}
    </Grid>
  ) : (
    <Typography>
      El estudiante no está inscripto en esta oferta ACAP.
    </Typography>
  );
};
