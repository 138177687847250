import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import {
  ActividadExtracurricularSelect,
  AnioSelect,
  CicloLectivoSelect,
  GeneroSelect,
  NivelSelect,
  PaisSelect,
  TipoDocumentoSelect,
  TurnoSelect,
  useAlumnoExisteGetRequest,
  useLocalizacionLibbyFetch,
} from 'src/app/business';
import { DatePicker } from 'src/app/components/inputs';
import { CicloLectivo } from 'src/app/models';
import { useDebounce } from 'src/commons';
import { MainButton, MainInput } from 'src/commons/components';
import { DEPENDENCIA_FUNCIONAL } from 'src/commons/const';
import { AlumnoExistsResponse } from 'src/platform/libby/dao/AlumnoExisteDAO';
import { primary } from 'src/theme/colors';
import {
  addStudenInitialValue,
  addStudentFormStyles,
  filterGenero,
  filterNivel,
} from './const';
import { AddStudenFormValue } from './types';

interface AddStudentFormProps {
  initialValue?: AddStudenFormValue;
  cicloLectivo: CicloLectivo;
  idLocalizacion: string;
  isExternal?: boolean;
  isCommonSchool?: boolean;
  handleSubmit: (values: AddStudenFormValue) => void;
}

export const AddStudentForm = ({
  initialValue = addStudenInitialValue,
  cicloLectivo,
  isCommonSchool = true,
  isExternal = false,
  idLocalizacion,
  handleSubmit,
}: AddStudentFormProps) => {
  const classes = addStudentFormStyles();
  const [formValue, setFormValue] = useState<AddStudenFormValue>({
    ...initialValue,
  });

  const documentDebounce = useDebounce(formValue.documento, 500);

  const {
    data,
    working: checkWorking,
    request,
  } = useAlumnoExisteGetRequest<AlumnoExistsResponse>({
    autoCall: false,
    url: `/api/public/alumnoexiste?documento=${documentDebounce}`,
  });

  useEffect(() => {
    if (initialValue.documento) return;
    if (!documentDebounce || !formValue.tipoDocumento) return;
    request();
  }, [
    documentDebounce,
    request,
    formValue.tipoDocumento,
    initialValue.documento,
  ]);

  const existing = !!data && data?.isExists && data?.alumnos?.length > 1;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const value = e.target.value;
    setFormValue((prev) => ({ ...prev, [name]: value }));
  };

  const validateFormSave = () => {
    const inputValidetor =
      !formValue.nombre ||
      !formValue.apellido ||
      !formValue.pais ||
      !formValue.genero ||
      !formValue.anio ||
      !formValue.cicloLectivo ||
      !formValue.turno ||
      !formValue.tipoDocumento ||
      !formValue.documento ||
      !formValue.fechaNacimiento ||
      existing ||
      checkWorking;

    if (isCommonSchool) return inputValidetor || !formValue.nivel;
    if (isExternal) return inputValidetor || !formValue.actividad;
    return inputValidetor;
  };

  const filterLocalizacion = useMemo(
    () => ({
      localizacion: [
        {
          path: 'idLocalizacion',
          value: idLocalizacion,
        },
      ],
    }),
    [idLocalizacion],
  );

  const { data: [localizacion] = [], working } = useLocalizacionLibbyFetch({
    limit: 1,
    filter: filterLocalizacion,
    aspect: 'dependencia_funcional',
  });

  const filterCL = useMemo(() => {
    const isDFInicial =
      localizacion?.establecimiento?.dependenciaFuncional
        ?.idDependenciaFuncional ===
      DEPENDENCIA_FUNCIONAL.DIRECCION_EDUCACION_INICIAL;
    const anioActual = cicloLectivo.anio;
    return {
      clValidos: [
        {
          path: 'anio',
          value: anioActual,
          method: isDFInicial ? 'higherOrEquals' : 'equals',
        },
      ],
    };
  }, [
    cicloLectivo.anio,
    localizacion?.establecimiento?.dependenciaFuncional?.idDependenciaFuncional,
  ]);

  return (
    <Grid container className={classes.mainContainer}>
      {existing ? (
        <Grid container style={{ backgroundColor: 'orange' }}>
          <Typography>
            Existen coincidencias para este documento. Realize nuevamente una
            busqueda
          </Typography>
        </Grid>
      ) : (
        <></>
      )}

      <Grid container className={classes.inputContainer}>
        <Grid item xs={6}>
          <Typography className={classes.paddingTypography}>
            Nombre
            <span className={classes.labelAsterisk}>
              {formValue.nombre ? '' : ' *'}
            </span>
          </Typography>
          <MainInput
            value={formValue.nombre}
            handleChange={handleChange}
            placeholder="Nombre"
            name="nombre"
            fullWidth
            customStyleLabel={{
              fontSize: 12,
              paddingBottom: 10,
              backgroundColor: 'blue',
            }}
            disabled={!!initialValue.nombre}
            customStyle={{ width: '95%' }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.apellidoSelect}>
            Apellido
            <span className={classes.labelAsterisk}>
              {formValue.apellido ? '' : ' *'}
            </span>
          </Typography>
          <MainInput
            name="apellido"
            value={formValue.apellido}
            handleChange={handleChange}
            placeholder="Apellido"
            fullWidth
            customStyleLabel={{
              fontSize: 12,
              paddingBottom: 10,
              marginLeft: 0,
            }}
            customStyle={{ width: '95%' }}
            disabled={!!initialValue?.apellido}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.inputContainer}>
        <Grid className={classes.selectContainer} item xs={4}>
          <Typography className={classes.paddingTypography}>
            País
            <span className={classes.labelAsterisk}>
              {formValue.pais ? '' : ' *'}
            </span>
          </Typography>
          <PaisSelect
            orderBy="descripcionPais"
            handleChange={handleChange}
            title=""
            value={formValue.pais}
            name="pais"
            labelKey="descripcionPais"
            valueKey="idPais"
            customStyleTitle={{ color: primary.black, fontSize: 12 }}
            disabled={!!initialValue?.pais}
          />
        </Grid>
        <Grid className={classes.selectContainer} item xs={4}>
          <Typography className={classes.paddingTypography}>
            Genero
            <span className={classes.labelAsterisk}>
              {formValue.genero ? '' : ' *'}
            </span>
          </Typography>
          <GeneroSelect
            handleChange={handleChange}
            title=""
            value={formValue.genero}
            name="genero"
            valueKey="idGenero"
            labelKey="descripcionGenero"
            customStyleTitle={{ color: primary.black, fontSize: 12 }}
            disabled={!!initialValue?.genero}
            filter={filterGenero}
          />
        </Grid>
        <Grid className={classes.selectContainer} item xs={4}>
          <Typography className={classes.paddingTypography}>
            Fecha de nacimiento
            <span className={classes.labelAsterisk}>
              {formValue.fechaNacimiento ? '' : ' *'}
            </span>
          </Typography>
          <DatePicker
            value={formValue.fechaNacimiento}
            onChange={(_date) => {
              if (!_date) return;
              setFormValue((prev) => ({
                ...prev,
                fechaNacimiento: _date.toDate(),
              }));
            }}
            className={classes.datePicker}
            placeholder="Ingrese la fecha"
            fullWidth
            disableFuture
            clearable
            disabled={!!initialValue?.fechaNacimiento}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.inputContainer}>
        <Grid className={classes.selectContainer} item xs={6}>
          <Typography className={classes.paddingTypography}>
            Tipo de documento
            <span className={classes.labelAsterisk}>
              {formValue.tipoDocumento ? '' : ' *'}
            </span>
          </Typography>
          <TipoDocumentoSelect
            name="tipoDocumento"
            handleChange={handleChange}
            orderBy="descripcionTipoDocumento"
            title=""
            value={formValue.tipoDocumento}
            customStyleTitle={{ color: primary.black, fontSize: 12 }}
            disabled={!!initialValue?.tipoDocumento}
          />
        </Grid>
        <Grid className={classes.selectContainer} item xs={6}>
          <Typography className={classes.paddingTypography}>
            Número de documento
            <span className={classes.labelAsterisk}>
              {formValue.documento ? '' : ' *'}
            </span>
          </Typography>
          <MainInput
            placeholder="Escribí el documento"
            fullWidth
            name="documento"
            handleChange={handleChange}
            value={formValue.documento}
            customStyleLabel={{ fontSize: 12, paddingBottom: 15 }}
            disabled={!!initialValue?.documento}
            customStyle={{ width: '95%' }}
          />
        </Grid>
        <Grid container className={classes.inputContainer}>
          <Grid className={classes.selectContainer} item xs={6}>
            <Typography className={classes.paddingTypography}>
              Ciclo Lectivo
              <span className={classes.labelAsterisk}>
                {formValue.cicloLectivo ? '' : ' *'}
              </span>
            </Typography>
            <CicloLectivoSelect
              title=""
              name="cicloLectivo"
              placeholder="Seleccioná Ciclo"
              value={formValue.cicloLectivo}
              handleChange={handleChange}
              filter={filterCL}
              disabled={working}
            />
          </Grid>
          <Grid className={classes.selectContainer} item xs={6}>
            <Typography className={classes.paddingTypography}>
              Turno
              <span className={classes.labelAsterisk}>
                {formValue.turno ? '' : ' *'}
              </span>
            </Typography>
            <TurnoSelect
              handleChange={handleChange}
              title=""
              value={formValue.turno}
              name="turno"
              customStyleTitle={{ color: primary.black, fontSize: 12 }}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.inputContainer}>
          {isExternal && (
            <Grid className={classes.selectContainer} item xs={6}>
              <Typography className={classes.paddingTypography}>
                Actividad Extracurricular
                <span className={classes.labelAsterisk}>
                  {formValue.actividad ? '' : ' *'}
                </span>
              </Typography>
              <ActividadExtracurricularSelect
                handleChange={handleChange}
                title=""
                value={formValue.actividad}
                name="actividad"
                customStyleTitle={{ color: primary.black, fontSize: 12 }}
              />
            </Grid>
          )}
          {isCommonSchool && (
            <Grid className={classes.selectContainer} item xs={6}>
              <Typography className={classes.paddingTypography}>
                Nivel
                <span className={classes.labelAsterisk}>
                  {formValue.nivel ? '' : ' *'}
                </span>
              </Typography>
              <NivelSelect
                handleChange={handleChange}
                title=""
                value={formValue.nivel}
                name="nivel"
                filter={filterNivel}
                customStyleTitle={{ color: primary.black, fontSize: 12 }}
              />
            </Grid>
          )}
          <Grid className={classes.selectContainer} item xs={6}>
            <Typography className={classes.paddingTypography}>
              Año
              <span className={classes.labelAsterisk}>
                {formValue.anio ? '' : ' *'}
              </span>
            </Typography>
            <AnioSelect
              orderBy="numeroAnio,descripcionAnio"
              handleChange={handleChange}
              title=""
              value={formValue.anio}
              name="anio"
              customStyleTitle={{ color: primary.black, fontSize: 12 }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.buttonContainer}>
        <MainButton
          title="Guardar Estudiante"
          size="medium"
          disabled={validateFormSave()}
          handleOnClick={() => {
            handleSubmit(formValue);
          }}
        />
      </Grid>
    </Grid>
  );
};
