import React, { useState } from 'react';
import { CardContent, Grid, Typography, makeStyles } from '@material-ui/core';
import Card from '@material-ui/core/Card';
// @ts-ignore
import MUIEditor, { MUIEditorState } from 'react-mui-draft-wysiwyg';
import { convertFromRaw } from 'draft-js';
type Props = {
  description: string;
};

const useStyles = makeStyles({
  container: {
    height: '70vh',
  },
  input: {
    color: 'black',
  },
  textField: {
    color: 'black',
  },
  card: {
    minWidth: 275,
  },
  description: {
    textAlign: 'justify',
  },
});
const editorConfig = {
  toolbar: { visible: false },
  draftEditor: { readOnly: true },
  editor: {
    wrapperElement: 'div',
    style: {
      padding: 0,
      borderTop: '1px solid gray',
    },
  },
};

let rawContent = {
  blocks: [
    {
      data: {},
      depth: 0,
      entityRanges: [],
      inlineStyleRanges: [],
      key: '2vm6d',
      text: '',
      type: '',
    },
  ],
  entityMap: {},
};

const ModalDescripcion = ({ description }: Props) => {
  const classes = useStyles();

  try {
    rawContent = JSON.parse(description);
  } catch (e) {
    rawContent.blocks[0].text = description;
  }

  const [editorState, setEditorState] = useState(
    MUIEditorState.createWithContent(editorConfig, convertFromRaw(rawContent)),
  );
  const onChange = (newState: any) => {
    setEditorState(newState);
  };
  return (
    <Grid
      container
      classes={{
        root: classes.container,
      }}
    >
      <Grid item xs={12}>
        <Card className={classes.card}>
          <CardContent>
            <Typography className={classes.description}>
              <MUIEditor
                editorState={editorState}
                onChange={onChange}
                config={editorConfig}
              />
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default ModalDescripcion;
