import React from 'react';
import { Button } from '@material-ui/core';
import { IndicadorTipoAdjunto } from '../../informesTypes';
import { useInformesStyles } from '../hooks/useInformesStyles';
import { useHandleFileUpload } from '../hooks/useHandleFileUpload';
import { InformescCustom } from 'src/app/models';

export const InputIndicadorTipoAdjunto = ({
  isInformesClosed,
  indicador,
  row,
  handleDataChange,
}: {
  isInformesClosed: boolean;
  indicador: IndicadorTipoAdjunto;
  row: InformescCustom;
  handleDataChange: (
    data: { name: string; value: string },
    row: InformescCustom,
  ) => void;
}) => {
  const classes = useInformesStyles();
  const handleFileUpload = useHandleFileUpload();
  const handleFileChange = async (
    event: any,
    row: InformescCustom,
    validFileTypes: string,
    titleInfoModal: string,
  ) => {
    const data = await handleFileUpload(
      event,
      row,
      validFileTypes.split(','),
      titleInfoModal,
    );
    handleDataChange(data, row);
  };

  return (
    <div>
      {Boolean(row.nota?.data?.[indicador.id]) ? (
        <div
          className={classes.adjunto}
          onClick={() =>
            !isInformesClosed
              ? handleFileChange(
                  {
                    target: { name: indicador.id, value: 'adjuntoClose' },
                  },
                  row,
                  indicador.acceptFileType,
                  indicador.label,
                )
              : null
          }
        >
          <div className={classes.adjuntoNombre}>
            {row.nota?.data?.[indicador.id]?.filename}
          </div>
          <div className={classes.adjuntoClose}>x</div>
        </div>
      ) : (
        <div className={`${null}`}>
          <Button
            variant="contained"
            component="label"
            color="primary"
            fullWidth
            disabled={isInformesClosed}
          >
            ADJUNTAR
            <input
              type="file"
              accept={indicador.acceptFileType}
              name={indicador.id}
              hidden
              onChange={(event) =>
                handleFileChange(
                  event,
                  row,
                  indicador.acceptFileType,
                  indicador.label,
                )
              }
              disabled={isInformesClosed}
            />
          </Button>
        </div>
      )}
    </div>
  );
};
