import React, { FC, ReactNode, useCallback, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { primary } from 'src/theme/colors';
import { ModalBase, ModalBaseProps } from '../ModalBase';
import { AnyObject } from '../../../../types/types';

export interface ModalFormComponentProps<T, S = AnyObject> {
  value: any;
  onChange: (newValue: any) => void | Promise<void>;
  initialValue: T;
  isLoading: boolean;
  metadata?: S;
}

const buttonStyles = makeStyles(() => ({
  button: {
    textTransform: 'capitalize',
  },
  buttonCancel: {
    textTransform: 'capitalize',
    color: primary.lightBlue,
  },
}));

export interface ModalFormProps<T = any, S = AnyObject>
  extends Omit<Omit<ModalBaseProps, 'content'>, 'actions'> {
  confirmButtonText?: ReactNode;
  cancelButtonText?: ReactNode;
  component: FC<ModalFormComponentProps<T, S>>;
  initialValue: T;
  metadata?: S;
  onCancel: () => void;
  onConfirm: (newValue: T) => void;
  preConfirm?: (newValue: T) => T | Promise<T>;
  maxWidth?: any;
}

const dummyMetadata = {};

export const ModalForm = ({
  confirmButtonText = 'Agregar',
  cancelButtonText = 'Cancelar',
  component: Component,
  initialValue,
  open,
  onCancel,
  onConfirm,
  preConfirm,
  metadata = dummyMetadata,
  ...modalsBaseProps
}: ModalFormProps) => {
  const [value, setValue] = useState(initialValue);
  const [loading, setLoading] = useState(false);
  const onConfirmButton = useCallback(async () => {
    if (!preConfirm) {
      onConfirm(value);
    } else {
      try {
        setLoading(true);
        const newValue = await preConfirm(value);
        onConfirm(newValue);
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLoading(false);
      }
    }
  }, [onConfirm, preConfirm, value]);

  useEffect(() => {
    if (open) {
      setValue(initialValue);
    }
    // eslint-disable-next-line
  }, [open]);

  const classes = buttonStyles();

  const content = !open ? null : (
    <Component
      value={value}
      onChange={setValue}
      initialValue={initialValue}
      isLoading={loading}
      metadata={metadata}
    />
  );
  const actions = (
    <>
      <Button
        onClick={onCancel}
        disabled={loading}
        variant="text"
        size="small"
        className={classes.buttonCancel}
      >
        {cancelButtonText}
      </Button>
      <Button
        onClick={onConfirmButton}
        disabled={loading}
        color="primary"
        variant="contained"
        size="small"
        autoFocus
        className={classes.button}
      >
        {confirmButtonText}
      </Button>
    </>
  );
  return (
    <ModalBase
      {...modalsBaseProps}
      open={open}
      content={content}
      actions={actions}
    />
  );
};
