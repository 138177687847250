import React, { FC, useMemo } from 'react';
import { ButtonTypesProps, FooterButtons, Loading } from 'src/commons';
import { useDevolucionOrganizacionFormContext } from '../hooks/useDevolucionOrgFormContext';
import { useHistory, useLocation } from 'react-router';

export const Buttons: FC<Acap.Organizacion.FooterButtonsProps> = ({
  lastElement,
}) => {
  const history = useHistory();
  const { state } = useLocation();

  const { submitting, handleSubmit, values, initialValues, form } =
    useDevolucionOrganizacionFormContext();

  const disableButton = useMemo(() => {
    if (
      values.evaluaciones.some((e) => e.valor > 0) ||
      values.observaciones !== ''
    )
      return false;

    return true;
  }, [values]);

  const footerButtons: ButtonTypesProps[] = useMemo(
    () => [
      {
        title: submitting ? (
          <Loading />
        ) : lastElement ? (
          'Guardar'
        ) : (
          'Guardar y continuar'
        ),
        disabled: disableButton || submitting || form.getState().invalid,
        handleOnClick: () => handleSubmit(),
      },
      {
        title: 'Volver',
        disabled: false,
        handleOnClick: () =>
          history.push(`/establecimientos/referente-organizacion`, {
            state,
          }),
      },
    ],
    [
      disableButton,
      submitting,
      form,
      state,
      history,
      lastElement,
      handleSubmit,
    ],
  );

  return <FooterButtons buttons={footerButtons} />;
};
