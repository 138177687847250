import React from 'react';
import { useSeccionContext } from 'src/app/business';
import { Grid } from '@material-ui/core';
import { EspaciosInterdiscipPlan } from 'src/app/models';
import { HeaderFeedback } from '../../commons/components/FeedbackHeader';

export type InterdisciplinarFlowHeaderProps = {
  data: EspaciosInterdiscipPlan;
};

export const InterdisciplinarFlowHeader = ({
  data,
}: InterdisciplinarFlowHeaderProps) => {
  const { working } = useSeccionContext();

  const formateDataHeader = {
    disciplina: data.espacioCurricular.materia.descripcion,
    area: data.espacioCurricular.materia.area.descripcion,
    orientacion:
      data?.espacioCurricular?.planEstudio?.orientacion?.descripcionOrientacion,
    seccion: '',
    fecha: data.interdiscipPlan.ultimaActualizacion,
    autor: `${data?.interdiscipPlan?.cuentas?.nombreUsuario} ${data?.interdiscipPlan?.cuentas?.apellidoUsuario}`,
    nombre: data.interdiscipPlan.proyecto.nombre,
    descripcion: data.interdiscipPlan.proyecto.descripcion,
  };
  return (
    <Grid container direction="column">
      {!working && <HeaderFeedback data={formateDataHeader} />}
    </Grid>
  );
};
