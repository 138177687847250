import React from 'react';
import { useBackButton, useRouteScreenTitle } from 'src/commons/hooks';
import { Grid, Button } from '@material-ui/core';

export const PlataformaEvaluaciones = () => {
  useBackButton('/');
  useRouteScreenTitle('Plataforma de evaluaciones presenciales');

  const handleClick = () => {
    window.open(
      'https://usuarios.bue.edu.ar/auth/realms/ticmas/protocol/openid-connect/auth?client_id=front-exams-reviews&redirect_uri=https%3A%2F%2Fevaluaciones.bue.edu.ar%2F&response_mode=fragment&response_type=code&scope=openid&kc_idp_hint=google',
      '_blank',
    );
  };

  return (
    <Grid container style={{ padding: 30 }}>
      <Grid item xs>
        <Button color="primary" variant="contained" onClick={handleClick}>
          Ingresar
        </Button>
      </Grid>
    </Grid>
  );
};
