import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid } from '@material-ui/core';
import { grey } from 'src/theme/colors';
import { orderBy } from 'lodash';
import { Loading, MainInput, SimpleSelect } from 'src/commons';
import {
  INITIAL_YEAR,
  useCicloLectivoLibbyFetch,
  useEstadoPaseAnioLibbyFetch,
  useSeccionLibbyFetch,
  useDistritosCustomGetRequest,
  useDistritosLocalizacionesCustomGetRequest,
  NivelSelect,
} from 'src/app/business';
import { distinctList } from 'src/utils';
import { useGetLocalizacionSeccionProperties } from 'src/commons/hooks/useGetLocalizacionSeccionProperties';
import { ESTADO_PASE_ANIO } from 'src/commons/const/estadoPaseAnio';

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  inputStyle: { padding: 10, marginTop: 23 },
};

export const ProyeccionTableHeader = ({
  selectsDisabled,
  filterValues,
  handleChange,
  handleSearchChange,
  search,
}: GestionProyeccion.ProyeccionTableHeaderProps) => {
  const {
    cycle,
    distrito,
    anio,
    estado,
    localizacion,
    seccionOrigen,
    jornada,
    turno,
    estadoProyeccion,
    nivel,
  } = filterValues;

  const estadoValues = [
    { descripcion: 'A Gestionar' },
    { descripcion: 'Gestionado' },
  ];

  const turnoFilter = useMemo(() => [{ path: 'turno', value: turno }], [turno]);
  const anioFilter = useMemo(() => [{ path: 'anio', value: anio }], [anio]);
  const jornadaFilter = useMemo(
    () => [{ path: 'jornada', value: jornada }],
    [jornada],
  );
  const localizacionFilter = useMemo(
    () => [{ path: 'localizacion', value: localizacion }],
    [localizacion],
  );
  const cycloLectivoFilter = useMemo(
    () => [{ path: 'cicloLectivo.idCicloLectivo', value: cycle }],
    [cycle],
  );

  const { data: status = [], working } = useEstadoPaseAnioLibbyFetch({
    limit: 20,
  });

  const filteredStatus =
    status.filter((s) => s.descripcionEstadoPaseAnio !== 'Egresa') || [];

  const filteredStatusInicial =
    status.filter(
      (s) =>
        s.descripcionEstadoPaseAnio === ESTADO_PASE_ANIO.PERMANECE ||
        s.descripcionEstadoPaseAnio === ESTADO_PASE_ANIO.PROMOCIONA,
    ) || [];

  const [libbyFilter, setLibbyFilter] = useState({
    anio: anioFilter,
    jornada: jornadaFilter,
    turno: turnoFilter,
  });

  const filterProyecciones = useCallback(
    () =>
      setLibbyFilter({
        ...(!!anio && { anio: anioFilter }),
        ...(!!jornada && { jornada: jornadaFilter }),
        ...(!!turno && { turno: turnoFilter }),
        localizacion: localizacionFilter,
        cicloLectivo: cycloLectivoFilter,
      }),
    [
      anio,
      anioFilter,
      jornada,
      jornadaFilter,
      localizacionFilter,
      turno,
      turnoFilter,
      cycloLectivoFilter,
    ],
  );

  useEffect(() => filterProyecciones(), [filterProyecciones]);

  const { data: lectiveCycles, working: ciclesWorking } =
    useCicloLectivoLibbyFetch({
      limit: 50,
    });

  const { data: distritos = [], working: distritosWorking } =
    useDistritosCustomGetRequest({
      url: `/api/public/custom/listadistritos?dependenciafuncional=${10}`,
      autoCall: true,
    });

  const {
    data: localizaciones = [],
    working: localizacionWorking,
    request: requestLocalizaciones,
  } = useDistritosLocalizacionesCustomGetRequest({
    url: `/api/public/custom/listalocalizaciones?distrito=${distrito}&dependenciafuncional=${10}`,
    autoCall: false,
  });

  useEffect(() => {
    if (distrito) requestLocalizaciones();
  }, [distrito, requestLocalizaciones]);

  const localizacionesSorted = useMemo(() => {
    const locations = distinctList('idLocalizacion', localizaciones);
    return orderBy([...locations], ['descripcion'], ['asc']);
  }, [localizaciones]);

  const seccionHookFilter = useMemo(
    () => ({
      localizacion: [{ path: 'localizacion', value: localizacion }],
      ciclo: [{ path: 'cicloLectivo', value: cycle }],
    }),
    [localizacion, cycle],
  );

  const { data: seccionesHook = [], working: seccionWorking } =
    useSeccionLibbyFetch({
      limit: 500,
      filter: seccionHookFilter,
      enabled: Boolean(localizacion),
    });

  const validLectiveCycles = lectiveCycles
    ? lectiveCycles.filter(
        (cycle) => cycle.anio >= INITIAL_YEAR && cycle.anio <= 2024,
      )
    : [];

  const {
    aniosSorted: anios = [],
    jornadasSorted: jornadas = [],
    turnosSorted: turnos = [],
  } = useGetLocalizacionSeccionProperties(seccionesHook);

  return (
    <>
      <Grid container>
        <Grid container justify="flex-start" spacing={2} alignItems="center">
          <Grid item xs={1}>
            {ciclesWorking ? (
              <Loading />
            ) : (
              <SimpleSelect
                disabled={selectsDisabled}
                title="Ciclo lectivo"
                placeholder="Seleccioná Ciclo"
                labelKey="anio"
                valueKey="idCicloLectivo"
                content={validLectiveCycles}
                handleChange={handleChange}
                value={cycle}
                customStyleTitle={customStyles.customStyleTitleSelect}
                name="cycle"
              />
            )}
          </Grid>
          {Boolean(cycle) ? (
            <Grid item xs={1}>
              {distritosWorking ? (
                <Loading />
              ) : (
                <SimpleSelect
                  disabled={!cycle}
                  title="Distrito"
                  placeholder="Seleccioná Distrito"
                  labelKey="nombre"
                  valueKey="idDistritoEscolar"
                  content={distritos}
                  handleChange={handleChange}
                  value={distrito}
                  customStyleTitle={customStyles.customStyleTitleSelect}
                  name="distrito"
                />
              )}
            </Grid>
          ) : null}
          {[cycle, distrito].every(Boolean) ? (
            <Grid item xs={1}>
              <NivelSelect
                value={nivel}
                handleChange={(event) => handleChange(event)}
                customStyleTitle={customStyles.customStyleTitleSelect}
                disabled
              />
            </Grid>
          ) : null}
          {[cycle, distrito, nivel].every(Boolean) ? (
            <Grid item xs={3}>
              {localizacionWorking ? (
                <Loading />
              ) : (
                <SimpleSelect
                  title="Establecimiento origen"
                  placeholder="Seleccione Opcion"
                  labelKey="descripcion"
                  valueKey="idLocalizacion"
                  content={localizacionesSorted}
                  value={localizacion}
                  name="localizacion"
                  customStyleTitle={customStyles.customStyleTitleSelect}
                  handleChange={handleChange}
                  disabled={!distrito}
                />
              )}
            </Grid>
          ) : null}
          {[cycle, distrito, nivel, localizacion].every(Boolean) ? (
            <Grid item xs={2}>
              {working ? (
                <Loading />
              ) : (
                <SimpleSelect
                  title="Pase de año"
                  placeholder="Seleccioná Pase de año"
                  labelKey="descripcionEstadoPaseAnio"
                  valueKey="idEstadoPaseAnio"
                  content={nivel === 1 ? filteredStatusInicial : filteredStatus}
                  handleChange={handleChange}
                  value={estado}
                  customStyleTitle={customStyles.customStyleTitleSelect}
                  name="estado"
                  disabled={!localizacion}
                />
              )}
            </Grid>
          ) : null}
        </Grid>
        {[cycle, distrito, nivel, localizacion].every(Boolean) ? (
          <Grid
            container
            justify="flex-start"
            spacing={2}
            alignItems="center"
            style={{ marginTop: '10px' }}
          >
            <Grid item xs={3}>
              {seccionWorking ? (
                <Loading />
              ) : (
                <SimpleSelect
                  title="Sección origen"
                  placeholder="Seleccione opción"
                  labelKey="nombreSeccion"
                  valueKey="idSeccion"
                  content={seccionesHook}
                  value={seccionOrigen}
                  name="seccionOrigen"
                  customStyleTitle={customStyles.customStyleTitleSelect}
                  handleChange={handleChange}
                  disabled={!localizacion}
                />
              )}
            </Grid>
            <Grid item xs={1}>
              {Boolean(seccionOrigen) ? (
                <SimpleSelect
                  title="Año"
                  placeholder="Seleccioná Año"
                  labelKey="descripcionAnio"
                  valueKey="idAnio"
                  content={anios}
                  handleChange={handleChange}
                  value={anio}
                  customStyleTitle={customStyles.customStyleTitleSelect}
                  name="anio"
                  disabled={!localizacion}
                />
              ) : null}
            </Grid>
            <Grid item xs={1}>
              {Boolean(seccionOrigen) ? (
                <SimpleSelect
                  title="Jornada"
                  placeholder="Seleccioná Jornada"
                  labelKey="label"
                  valueKey="label"
                  content={jornadas}
                  handleChange={handleChange}
                  value={jornada}
                  customStyleTitle={customStyles.customStyleTitleSelect}
                  name="jornada"
                  disabled={!localizacion}
                />
              ) : null}
            </Grid>
            <Grid item xs={1}>
              {Boolean(seccionOrigen) ? (
                <SimpleSelect
                  title="Turno"
                  placeholder="Seleccioná Turno"
                  labelKey="descripcionTurno"
                  valueKey="idTurno"
                  content={turnos}
                  handleChange={handleChange}
                  value={turno}
                  customStyleTitle={customStyles.customStyleTitleSelect}
                  name="turno"
                  disabled={!localizacion}
                />
              ) : null}
            </Grid>

            <Grid item xs={2}>
              {Boolean(seccionOrigen) ? (
                <SimpleSelect
                  title="Estado"
                  placeholder="Seleccioná Estado"
                  labelKey="descripcion"
                  valueKey="descripcion"
                  content={estadoValues}
                  handleChange={handleChange}
                  value={estadoProyeccion}
                  customStyleTitle={customStyles.customStyleTitleSelect}
                  name="estadoProyeccion"
                  disabled={!localizacion}
                />
              ) : null}
            </Grid>
          </Grid>
        ) : null}
      </Grid>
      <Grid
        container
        xs={3}
        justify="flex-end"
        spacing={2}
        alignItems="flex-start"
      >
        <Grid item xs={12}>
          <MainInput
            type="search"
            handleChange={handleSearchChange}
            placeholder="Buscar alumno o documento"
            name="search"
            value={search}
            fullWidth
            customStyle={customStyles.inputStyle}
          />
        </Grid>
      </Grid>
    </>
  );
};
