import { LibbyFetchDAO } from '../LibbyFetchDAO';

type Propuesta = {
  seccion: number;
};

export class PropuestaJEDAO extends LibbyFetchDAO {
  constructor() {
    super('propuesta_je', 'idPropuestaJe');
  }

  async savePropuesta(propuesta: Propuesta) {
    return await this.aspect('saving').save(propuesta);
  }
}
