import { Grid, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import React, { useMemo } from 'react';
import { InfoTableColumn } from 'src/commons';
import { primary } from 'src/theme/colors';
import {
  AccionesOrientacionesFormValues,
  ArbolCheckboxColumnsProps,
} from '../types';

export const useArbolCheckboxColumns = ({
  type,
  nombreColumna,
  onliView = false,
  handleEdit,
}: ArbolCheckboxColumnsProps) => {
  const columns = useMemo<InfoTableColumn<AccionesOrientacionesFormValues>[]>(
    () => [
      {
        id: 'nombreOrientacion',
        width: '10%',
        label: 'Orientación',
        render: (value) => {
          return value;
        },
        hideSortIcon: true,
      },
      {
        id: 'seleccionados',
        width: '80%',
        label: nombreColumna,
        render: (value, row) => {
          let descripciones = '';
          switch (type) {
            case 'perfiles':
              descripciones = '';
              row.perfiles.forEach((perfil, index, all) => {
                const alreadyReachedCap = descripciones?.includes('...');
                if (descripciones?.length > 200 && !alreadyReachedCap) {
                  descripciones += `${perfil?.descripcion}...`;
                } else if (
                  index === all?.length - 1 &&
                  descripciones?.length < 200
                ) {
                  descripciones += `${perfil?.descripcion}`;
                } else if (descripciones?.length < 200) {
                  descripciones += `${perfil?.descripcion}, `;
                }
              });
              break;
            case 'habilidades':
              descripciones = '';
              row.habilidades.forEach((habilidad, index, all) => {
                const alreadyReachedCap = descripciones?.includes('...');
                if (descripciones?.length > 200 && !alreadyReachedCap) {
                  descripciones += `${habilidad?.descripcion}...`;
                } else if (
                  index === all?.length - 1 &&
                  descripciones?.length < 200
                ) {
                  descripciones += `${habilidad?.descripcion}`;
                } else if (descripciones?.length < 200) {
                  descripciones += `${habilidad?.descripcion}, `;
                }
              });
              break;
            case 'nodos':
              descripciones = '';
              row.nodos.forEach((nodo, index, all) => {
                const alreadyReachedCap = descripciones?.includes('...');
                if (descripciones?.length > 200 && !alreadyReachedCap) {
                  descripciones += `${nodo?.contenido.descripcion}...`;
                } else if (
                  index === all?.length - 1 &&
                  descripciones?.length < 200
                ) {
                  descripciones += `${nodo?.contenido.descripcion}`;
                } else if (descripciones?.length < 200) {
                  descripciones += `${nodo?.contenido.descripcion}, `;
                }
              });
              break;

            default:
              break;
          }
          return descripciones;
        },
        orderById: 'nombre',
        hideSortIcon: true,
      },
      {
        id: 'editButton',
        width: '5%',
        label: '',
        render: (_, row) => {
          return (
            <Grid
              container
              direction="row"
              justifyContent="space-around"
              alignItems="center"
            >
              <Grid item xs={4}>
                <IconButton onClick={handleEdit(row)} disabled={onliView}>
                  <EditIcon
                    style={
                      onliView
                        ? { color: '#8C8C8C' }
                        : { color: primary.paleBlueStrong }
                    }
                  />
                </IconButton>
              </Grid>
            </Grid>
          );
        },
        hideSortIcon: true,
      },
    ],
    [handleEdit, nombreColumna, onliView, type],
  );

  return columns;
};
