import React, { useMemo, useCallback, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { UnfoldMore } from '@material-ui/icons';
import confirmDialog from 'src/commons/services/confirmDialog';
import {
  AsistenciaCellComponent,
  AvisosCell,
  DiasCell,
  JustificacionCell,
  LockCell,
  ObservacionesCell,
  StudentReportCell,
  TurnosCell,
} from './components';
import cx from 'classnames';
import {
  Box,
  Checkbox,
  Grid,
  TableContainer,
  TableSortLabel,
} from '@material-ui/core';
import moment, { Moment } from 'moment';
import { Loading, NIVEL, ROL, TIPO_PERIODO } from '../../../../../../commons';
import {
  useMultiPresentismoChecks,
  usePresentismoContext,
} from '../../context';
import { AlumnoMap, DiaCellState, PresentismoTableProps } from '../../types';
import { useGridSelection } from './hooks/useGridSelection';
import { StyledLockRow, StyledTableRow, useStyles } from '../../../styles';
import {
  useCierrePresenteV3DAO,
  useCierrePresenteV3LibbyFetch,
} from 'src/app/business/presentismo_v3';
import { useRolesContext } from 'src/context/RolesContext';
import {
  getDateRangeBetweenDates,
  isWeekClosed,
} from '../../utils/DateRangeList';
import { RolBlocker } from 'src/commons/components/Blockers/RolBlocker';
import { CerrarSemanaBtn } from './components/CerrarSemanaBtn';
import { weekDays } from './functions/cerrarSemana';
import { HabilitarDiasDocenteBoton } from '../DiasHabilitadosModal/BotonDiasHabilitados';
import { PresentismosValidosProvider } from '../../context/PresentismosValidosContext';
import { getPeriodoByDate } from './functions/getPeriodoByDate';
import { DiasCierre } from 'src/app/models';
import { JornadaComponent } from '../../../../Acap/components/JornadaComponent';
import { useColegioEfectivizadoContext } from '../../../../Calificaciones/context/EfectivizacionProvider';
import { periodos } from '../../../../EspaciosCurriculares/EspacioCurricular/Planificacion/forms/ArealInterareal/tabs/Actividad';

const PresentismoTableRaw = ({
  currentWeek,
  serverDate,
  pastDaysOfWeek,
  rows = [],
  working,
  tipoJornada,
  justification,
  studentReport,
  isTabJornadaExtendida,
  reFetchAll,
  selectedSection,
  direction = 'asc',
  setDirection,
  setPastDaysOfWeek,
  selectedEspacioCurricular,
  presentes,
  prestesTrimestral,
  workingPresentismoTrimestral,
  diasHabilitados,
  reFetchDiasHabiles,
  diasHabilesWorking,
}: PresentismoTableProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [amountCells, setAmountCells] = useState(0);

  const {
    userInfo: { id: userId },
    selectedRole: { nivel },
  } = useRolesContext();
  const [waitingCandados, setWaitingCandados] = useState(false);
  const filterCierrePresentismo = useMemo(
    () => ({
      0: [
        {
          path: 'seccion.idSeccion',
          value: rows[0]?.seccion?.idSeccion,
        },
      ],
    }),
    [rows],
  );

  const {
    data: cierresPresentismo = [],
    working: workingCierre,
    reFetch: reFetchCierre,
  } = useCierrePresenteV3LibbyFetch({
    filter: filterCierrePresentismo,
    enabled: rows.length > 0,
    aspect: 'presentismo',
  });

  const getState = useCallback<
    (day: moment.Moment, daySelected: string) => DiaCellState
  >(
    (day, daySelected) => {
      if (
        moment(day).format('YYYY/MM/DD') ===
        moment(daySelected).format('YYYY/MM/DD')
      ) {
        return 'current';
      } else if (moment(day).isAfter(moment(serverDate))) {
        return 'open';
      }
      return 'closed';
    },
    [serverDate],
  );

  const { situaciones, periodoNuevo } = usePresentismoContext();

  const { alumnos, setAlumnos } = useMultiPresentismoChecks();

  const handleSelectedDiaCell = useCallback(
    (selected: moment.Moment) => {
      const index = selected.format('e');
      setPastDaysOfWeek(Number(index));
    },
    [setPastDaysOfWeek],
  );

  const daySelected = moment(currentWeek)
    .startOf('isoWeek')
    .add(pastDaysOfWeek, 'days')
    .format('YYYY-MM-DD');

  const presentComplete = useMemo(() => {
    const presentArray: { day: string; fullPresenteismo: boolean }[] = [];

    weekDays.forEach((day) => {
      let numPresent = 0;

      rows.forEach((nd) => {
        nd.presentismo.forEach((presentismos) => {
          const presenteeismDay = new Date(presentismos.fecha);
          if (weekDays[presenteeismDay.getDay()] === day) {
            numPresent++;
          }
        });
      });

      const fullPresenteismo = numPresent >= amountCells * rows.length;

      presentArray.push({
        day,
        fullPresenteismo,
      });
    });

    return presentArray;
  }, [rows, amountCells]);

  const isDayFuture = moment(currentWeek).isAfter(moment(serverDate));
  const { cicloLectivo } = useColegioEfectivizadoContext();

  let cicloLectivoStart = moment(
    cicloLectivo?.fechaInicio,
    'YYYY-MM-DD',
  ).format('YYYY-MM-DD');
  let cicloLectivoEnd = moment(cicloLectivo?.fechaFin, 'YYYY-MM-DD').format(
    'YYYY-MM-DD',
  );

  const isValidCicloLectivo = moment(currentWeek, 'YYYY-MM-DD').isBetween(
    cicloLectivoStart,
    cicloLectivoEnd,
    undefined,
    '[]',
  );

  const { dayCells, lockCells, turnosCells, situacionesDisabledDays } =
    useMemo(() => {
      let _dayCells: JSX.Element[] = [];
      let _lockCells: JSX.Element[] = [];
      let _turnosCells: JSX.Element[] = [];
      let _situacionesDisabledDays: number[] = [];

      weekDays.forEach((day, idx) => {
        const _day = moment(currentWeek).startOf('isoWeek').add(idx, 'days');
        let isDisable = false;
        let isLockBlock = false;
        let tieneDiasHabilitados = false;
        if (diasHabilitados.length > 0) {
          isDisable = !diasHabilitados[0].dias
            .map((dia) => dia.id)
            .includes(idx + 1);
        }
        const _periodo = getPeriodoByDate(
          periodoNuevo,
          moment(_day).format('YYYY-MM-DD'),
        );
        /* if (!_periodo) {
          console.log('No hay un periodo');
        } */
        const isRecesoInvernal =
          _periodo?.tipoPeriodo.idTipoPeriodo === TIPO_PERIODO.RECESO_INVERNAL;

        if (isRecesoInvernal) {
          isLockBlock = true;
        }

        if (diasHabilitados.length !== 0) {
          tieneDiasHabilitados = true;
        }
        const contraturnos = rows[0]?.contraturnos.filter((dia) => {
          return (
            dia?.dia?.idDia - 1 === idx &&
            dia.active &&
            dia.contraturno.active &&
            dia.contraturno.fechaInicio <= moment(_day).format('YYYY-MM-DD') &&
            dia.contraturno.fechaFin >= moment(_day).format('YYYY-MM-DD')
          );
        });
        const current = moment(_day).format('YYYY-MM-DD');

        const isDayFuture = moment(_day).isAfter(moment(serverDate));

        const isFuture = moment().isBefore(current);

        const _situaciones = situaciones.filter((situacion) => {
          return (
            moment(_day, 'YYYY-MM-DD').isBetween(
              moment(situacion.fechaDesde, 'YYYY-MM-DD').format('YYYY-MM-DD'),
              moment(situacion.fechaHasta, 'YYYY-MM-DD').format('YYYY-MM-DD'),
              undefined,
              '[]',
            ) &&
            situacion.secciones.find(
              (seccion) =>
                (seccion.idSeccion === selectedSection &&
                  seccion.afectaJE === isTabJornadaExtendida) ||
                seccion.afectaJS === (isTabJornadaExtendida === false),
            )
          );
        });

        _situaciones.forEach((situacion) => {
          const desde = moment(situacion.fechaDesde).day();
          const hasta = moment(situacion.fechaHasta).day();
          for (let i = desde; i <= hasta; i++) {
            if (!_situacionesDisabledDays.includes(i - 1)) {
              _situacionesDisabledDays.push(i - 1);
            }
          }
        });

        _dayCells.push(
          <DiasCell
            key={`diascell-${day}-${idx}`}
            day={_day.format('D')}
            dayOfWeek={day}
            state={getState(_day, daySelected)}
            onSelecte={() => {
              if (isFuture) return;
              handleSelectedDiaCell(_day);
            }}
            isSelected={daySelected === current}
            isDayFuture={isDayFuture}
            isDisabled={isDisable}
          />,
        );

        _lockCells.push(
          <LockCell
            key={`lockcell-${day}-${idx}`}
            align="center"
            colSpan={2}
            locked={getState(_day, daySelected) === 'closed'}
            first={idx === 0}
            last={idx === weekDays.length - 1}
            isSelected={daySelected === current}
            dataCierrePresentismo={cierresPresentismo}
            isDayFuture={isDayFuture}
            presentComplete={presentComplete}
            day={_day.format('YYYY-MM-DD')}
            seccion={rows[0]?.seccion?.idSeccion}
            reFetchCierre={reFetchCierre}
            alumnos={rows}
            presentes={presentes}
            selectedEspacioCurricular={selectedEspacioCurricular}
            isDisabled={isDisable}
            isLockBlock={isLockBlock}
            tieneDiasHabilitados={tieneDiasHabilitados}
            isTabJornadaExtendida={isTabJornadaExtendida}
            setWaitingCandados={setWaitingCandados}
          />,
        );

        _turnosCells.push(
          <TurnosCell
            key={`turnocell-${day}-${idx}`}
            contraturnos={contraturnos}
            day={_day.format('YYYY-MM-DD')}
            last={idx === weekDays.length - 1}
            state={getState(_day, daySelected)}
            tipoJornada={tipoJornada}
            isTabJornadaExtendida={isTabJornadaExtendida}
            isSelected={daySelected === current}
            allAlumnos={rows}
            selectedSection={selectedSection}
            situaciones={_situaciones}
            isDayFuture={isDayFuture}
            selectedEspacioCurricular={selectedEspacioCurricular}
            dataCierrePresentismo={cierresPresentismo}
            isDisabled={isDisable}
            isValidCicloLectivo={isValidCicloLectivo}
            tieneDiasHabilitados={tieneDiasHabilitados}
            isLockBlock={isLockBlock}
          />,
        );
      });
      setLoading(false);
      return {
        dayCells: _dayCells,
        lockCells: _lockCells,
        turnosCells: _turnosCells,
        situacionesDisabledDays: _situacionesDisabledDays,
      };
    }, [
      currentWeek,
      diasHabilitados,
      rows,
      serverDate,
      situaciones,
      getState,
      daySelected,
      cierresPresentismo,
      presentComplete,
      reFetchCierre,
      presentes,
      selectedEspacioCurricular,
      tipoJornada,
      isTabJornadaExtendida,
      selectedSection,
      periodoNuevo,
      handleSelectedDiaCell,
      isValidCicloLectivo,
    ]);
  const upperCaseFirstLetter = (wholeFrase: string) => {
    let words = wholeFrase.split(' ').filter((word) => !!word);
    return words.map((word) => word[0] + word.toLowerCase().slice(1)).join(' ');
  };
  const cierrePresentismoDAO = useCierrePresenteV3DAO();
  const days = getDateRangeBetweenDates(
    currentWeek.startOf('isoWeek').format('YYYY-MM-DD'),
    currentWeek
      .startOf('isoWeek')
      .add(weekDays.length - 1, 'days')
      .format('YYYY-MM-DD'),
  );

  const toggleWeekStatusHandler = async () => {
    const weekClosed = isWeekClosed(
      currentWeek.startOf('isoWeek').format('YYYY-MM-DD'),
      isTabJornadaExtendida
        ? cierresPresentismo[0]?.dias.je || []
        : cierresPresentismo[0]?.dias.jc || [],
    );

    const confirm = await confirmDialog.show({
      title: `${weekClosed ? 'Abrir' : 'Cerrar'} asistencia de la semana`,
      content: `¿Está seguro que desea ${
        weekClosed ? 'abrir' : 'cerrar'
      } la asistencia de la semana?`,
      secondLine: `Recuerde que solo puede volver abrir un rol jerárquico.`,
      confirmText: 'Confirmar',
      cancelText: 'Cancelar',
    });
    setWaitingCandados(true);

    if (confirm) {
      let dias: DiasCierre = {
        je: cierresPresentismo[0]?.dias.je || [],
        jc: cierresPresentismo[0]?.dias.jc || [],
      };
      if (weekClosed) {
        if (isTabJornadaExtendida)
          dias.je = cierresPresentismo[0]?.dias?.je.filter(
            (day) => !days.includes(moment.utc(day).format('YYYY-MM-DD')),
          );
        else
          dias.jc = cierresPresentismo[0]?.dias?.jc.filter(
            (day) => !days.includes(moment.utc(day).format('YYYY-MM-DD')),
          );
      } else {
        if (isTabJornadaExtendida)
          dias.je = [
            ...(cierresPresentismo[0]?.dias?.je || []),
            ...days.filter(
              (day) =>
                !(cierresPresentismo[0]?.dias?.je || []).some((_dia) =>
                  moment.utc(day).isSame(moment(_dia)),
                ),
            ),
          ];
        else
          dias.jc = [
            ...(cierresPresentismo[0]?.dias?.jc || []),
            ...days.filter(
              (day) =>
                !(cierresPresentismo[0]?.dias?.jc || []).some((_dia) =>
                  moment.utc(day).isSame(moment(_dia)),
                ),
            ),
          ];
      }

      await cierrePresentismoDAO.aspect('presentismo').save({
        idCierre: cierresPresentismo[0]?.idCierre,
        dias,
        seccion: rows[0]?.seccion?.idSeccion,
        closed_at: new Date(),
        closed_by: userId,
      });
      reFetchCierre();
    }
    setWaitingCandados(false);
  };

  const handleCheck = useCallback(
    (row: AlumnoMap) => () => {
      const existe = alumnos?.find(
        (e) => e.alumno.idAlumno === row.alumno.idAlumno,
      );
      if (existe) {
        setAlumnos((prev) =>
          prev.filter((pre) => pre.alumno.idAlumno !== row.alumno.idAlumno),
        );
      } else {
        setAlumnos((prev) => [
          ...prev,
          ...[
            {
              idPresente: null,
              alumno: row.alumno,
              fecha: '',
              seccion: row.seccion,
              isJornadaExtendida: isTabJornadaExtendida,
            },
          ],
        ]);
      }
    },
    [alumnos, isTabJornadaExtendida, setAlumnos],
  );

  useGridSelection(rows);

  return (
    <TableContainer className={classes.root}>
      {!working && !loading ? (
        <Table id="table-presentismo" aria-label="simple table">
          <TableHead className={classes.headerSticky}>
            <PresentismosValidosProvider
              seccion={rows[0]?.seccion?.idSeccion}
              currentWeek={currentWeek}
              id={selectedEspacioCurricular}
            >
              <Grid component={StyledLockRow}>
                <TableCell align="center" style={{ border: 0 }} colSpan={1} />
                <TableCell align="center" colSpan={2} />
                {lockCells}
                <TableCell
                  className={classes.cellButton}
                  align="center"
                  colSpan={3}
                >
                  <RolBlocker
                    enableForRoles={[
                      ROL.DIRECTIVO,
                      ROL.EQUIPO_CONDUCCION,
                      ROL.PRECEPTOR,
                      ROL.DOCENTE,
                    ]}
                  >
                    <Grid
                      component={Box}
                      position="absolute"
                      top={-10}
                      left={0}
                      right={0}
                      bottom={0}
                      margin="auto"
                    >
                      {isTabJornadaExtendida ? null : selectedEspacioCurricular ? null : workingCierre ||
                        waitingCandados ? (
                        <Loading />
                      ) : (
                        <CerrarSemanaBtn
                          rolesCanClose={[
                            ROL.DOCENTE,
                            ROL.PRECEPTOR,
                            ROL.DIRECTIVO,
                            ROL.EQUIPO_CONDUCCION,
                          ]}
                          rolesCanOpen={[ROL.DIRECTIVO, ROL.EQUIPO_CONDUCCION]}
                          seccion={rows[0]?.seccion?.idSeccion}
                          cierres={
                            isTabJornadaExtendida
                              ? cierresPresentismo[0]?.dias.je || []
                              : cierresPresentismo[0]?.dias.jc || []
                          }
                          currentWeek={currentWeek}
                          clickHandler={toggleWeekStatusHandler}
                        />
                      )}
                    </Grid>
                  </RolBlocker>
                  {diasHabilesWorking ? (
                    <Loading />
                  ) : selectedEspacioCurricular ? (
                    <Grid
                      component={Box}
                      position="absolute"
                      top={-2}
                      left={0}
                      right={0}
                      bottom={0}
                      margin="auto"
                    >
                      <HabilitarDiasDocenteBoton
                        reFetchDiasHabiles={reFetchDiasHabiles}
                        selectedSeccion={selectedSection}
                        selectedEspacioCurricular={selectedEspacioCurricular}
                        diasHabilitados={diasHabilitados}
                      />
                    </Grid>
                  ) : null}
                </TableCell>
                <TableCell align="center" style={{ border: 0 }} colSpan={1} />
              </Grid>

              <TableRow className={cx(classes.headLock)}>
                <TableCell
                  align="center"
                  padding="checkbox"
                  className={cx(classes.cell)}
                  rowSpan={2}
                >
                  <div
                    className={cx(classes.checkboxCell, classes.border)}
                  ></div>
                </TableCell>
                <TableCell
                  className={cx(classes.cell)}
                  colSpan={2}
                  rowSpan={2}
                  align="center"
                  sortDirection={direction}
                >
                  <TableSortLabel
                    active
                    direction={direction}
                    onClick={() =>
                      setDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                    }
                    IconComponent={() => (
                      <UnfoldMore
                        className={classes.sortIcon}
                        htmlColor="#38485C"
                        fontSize="small"
                      />
                    )}
                  >
                    <Grid
                      component={Box}
                      fontSize={13}
                      color="#38485C"
                      fontWeight={600}
                      fontFamily="Nunito"
                    >
                      Apellido y nombre
                    </Grid>
                  </TableSortLabel>
                </TableCell>
                {dayCells}
                <TableCell
                  className={cx(classes.cell, classes.noBorderBotton)}
                  colSpan={3}
                  align="center"
                />
                <TableCell
                  className={cx(classes.cell, classes.special)}
                  align="center"
                  colSpan={1}
                  rowSpan={2}
                >
                  <div
                    style={{ height: '100%' }}
                    className={cx(classes.checkboxCell, classes.endCell)}
                  />
                </TableCell>
              </TableRow>
              <TableRow className={classes.headLock}>
                {turnosCells}
                <TableCell
                  className={cx(classes.cell, classes.special)}
                  align="center"
                >
                  <Grid
                    component={Box}
                    fontSize={13}
                    color="#38485C"
                    fontWeight={600}
                    fontFamily="Nunito"
                  >
                    Avisos
                  </Grid>
                </TableCell>
                {selectedEspacioCurricular ? null : (
                  <TableCell
                    className={cx(classes.cell, classes.special)}
                    align="center"
                  >
                    <Grid
                      component={Box}
                      fontSize={13}
                      color="#38485C"
                      fontWeight={600}
                      fontFamily="Nunito"
                    >
                      Justificación
                    </Grid>
                  </TableCell>
                )}

                <TableCell
                  className={cx(classes.cell, classes.special)}
                  align="center"
                >
                  <Grid
                    component={Box}
                    fontSize={13}
                    color="#38485C"
                    fontWeight={600}
                  >
                    Ver
                  </Grid>
                </TableCell>
                {selectedEspacioCurricular ? (
                  <TableCell className={cx(classes.cell)} />
                ) : null}
              </TableRow>
            </PresentismosValidosProvider>
          </TableHead>
          <TableBody id="table-body-presentismo">
            {rows.length > 0 &&
              rows.map((row, i, total) => {
                return (
                  <Grid
                    component={StyledTableRow}
                    key={row.alumno.idAlumno}
                    role="checkbox"
                    tabIndex={-1}
                    onClick={() => {}}
                  >
                    <TableCell
                      align="center"
                      component="th"
                      padding="checkbox"
                      scope="row"
                      colSpan={1}
                      className={cx(classes.cell)}
                    >
                      <div className={classes.checkboxCell}>
                        <Checkbox
                          checked={
                            !!alumnos?.find(
                              (e) => e.alumno.idAlumno === row.alumno.idAlumno,
                            )
                          }
                          onChange={handleCheck(row)}
                          inputProps={{
                            'aria-labelledby': 'transfer-list-item-all-label',
                          }}
                          color="primary"
                        />
                      </div>
                    </TableCell>
                    <TableCell className={classes.nameCell} colSpan={2}>
                      <Grid
                        component={Box}
                        fontSize={14}
                        color="#38485C"
                        fontWeight={500}
                        fontFamily="Nunito"
                      >
                        {upperCaseFirstLetter(row.alumno.persona.apellido) +
                          ' ' +
                          upperCaseFirstLetter(row.alumno.persona.nombre)}
                      </Grid>
                    </TableCell>
                    {weekDays.map((day, dayId) => {
                      const _day = moment(currentWeek)
                        .startOf('isoWeek')
                        .add(dayId, 'days');

                      let isDisable = false;
                      let isLockBlock = false;

                      if (selectedEspacioCurricular) {
                        if (diasHabilitados.length === 0) {
                          isDisable = true;
                        } else {
                          isDisable = !diasHabilitados[0].dias
                            .map((dia) => dia.id)
                            .includes(dayId + 1);
                        }
                      } else if (situacionesDisabledDays.includes(dayId)) {
                        isDisable = true;
                      }

                      const _periodo = getPeriodoByDate(
                        periodoNuevo,
                        moment(_day).format('YYYY-MM-DD'),
                      );
                      if (!_periodo) {
                        isDisable = true;
                      }
                      !isValidCicloLectivo
                        ? (isDisable = true)
                        : (isDisable = false);

                      const isRecesoInvernal =
                        _periodo?.tipoPeriodo.idTipoPeriodo ===
                        TIPO_PERIODO.RECESO_INVERNAL;

                      if (isRecesoInvernal) {
                        isLockBlock = true;
                      }

                      return (
                        <AsistenciaCellComponent
                          key={`asistencia-${dayId}-${row.alumno.idAlumno}`}
                          lastRow={i === total.length - 1}
                          row={row}
                          currentWeek={currentWeek}
                          day={day}
                          dayId={dayId}
                          isTabJornadaExtendida={isTabJornadaExtendida}
                          selectedSection={selectedSection}
                          pastDaysOfWeek={pastDaysOfWeek}
                          isDayFuture={isDayFuture}
                          setAmountCells={setAmountCells}
                          dataCierrePresentismo={cierresPresentismo}
                          selectedEspacioCurricular={selectedEspacioCurricular}
                          isDisabled={isDisable}
                          isLockBlock={isLockBlock}
                          waitingCandados={waitingCandados}
                          isValidCicloLectivo={isValidCicloLectivo}
                        />
                      );
                    })}
                    <AvisosCell avisosCount={row.alertas || 0} />
                    {selectedEspacioCurricular ? null : (
                      <JustificacionCell
                        row={row}
                        onClick={justification}
                        isTabJornadaExtendida={isTabJornadaExtendida}
                        pastDaysOfWeek={pastDaysOfWeek}
                      />
                    )}

                    <StudentReportCell
                      row={row}
                      onClick={studentReport}
                      prestesTrimestral={prestesTrimestral}
                      workingPresentismoTrimestral={
                        workingPresentismoTrimestral
                      }
                    />
                    <ObservacionesCell
                      alumno={row}
                      currentWeek={currentWeek}
                      pastDaysOfWeek={pastDaysOfWeek}
                      reFetchAll={reFetchAll}
                      isTabJornadaExtendida={isTabJornadaExtendida}
                      setLoading={setLoading}
                      dataCierrePresentismo={cierresPresentismo}
                      selectedEspacioCurricular={selectedEspacioCurricular}
                    />
                    {selectedEspacioCurricular ? (
                      <TableCell className={cx(classes.cell)} />
                    ) : null}
                  </Grid>
                );
              })}
          </TableBody>
        </Table>
      ) : (
        <Grid container justifyContent="center" alignItems="center">
          <Loading />
        </Grid>
      )}
    </TableContainer>
  );
};

export const PresentismoTable = React.memo(PresentismoTableRaw);
