import {
  ArealInterarealPreview,
  DisciplinarComunPreview,
  InterdisciplinarPreview,
  DisciplinarOrientadaPreview,
} from 'src/screens/Private/EspaciosCurriculares/EspacioCurricular/Planificacion/preview';
import { MenuItem } from '../../../../../commons/const/ui/menu';

export const planificacionesPreviewRoutes: MenuItem[] = [
  {
    key: 'interArealInterAreal',
    basePath: '/interArealInterAreal/:idPlanificacion',
    target: {
      component: ArealInterarealPreview,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Planificacion Areal/Interareal',
    children: [],
  },
  {
    key: 'interArealInterDisciplinar',
    basePath: '/interDisciplinar/:idPlanificacion',
    target: {
      component: InterdisciplinarPreview,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Planificacion InterDisciplinar',
    children: [],
  },
  {
    key: 'diciplinar',
    basePath: '/disciplinar/:idPlanificacion',
    target: {
      component: DisciplinarComunPreview,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Planificacion Disciplinar',
    children: [],
  },
  {
    key: 'disciplinarOrientada',
    basePath: '/disciplinarOrientada/:idPlanificacion',
    target: {
      component: DisciplinarOrientadaPreview,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Planificacion Disciplinar Orientada',
    children: [],
  },
];
