import React from 'react';
import { UserAvatar } from 'src/commons/components';
import { AnyObject } from 'src/commons/types';
import { grey } from 'src/theme/colors';
import { makeStyles } from '@material-ui/styles';
import { Typography, Grid, IconButton } from '@material-ui/core';
import { ROL } from 'src/commons/const';

interface ListAvatarTypes {
  item: AnyObject;
  onClick: () => void;
}

const useStyles = makeStyles({
  listContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: 12,
    paddingTop: 12,
    '&:hover': {
      backgroundColor: 'white',
    },
  },
  textContainer: {
    paddingLeft: 10,
    flexDirection: 'column',
    alignItems: 'center',
  },
  titleStyle: {
    color: grey.heading,
    fontSize: 11,
  },
  subtitleStyle: {
    fontSize: 12,
    fontWeight: 700,
    textAlign: 'left',
    color: grey.heading,
  },
  secondaryTitleStyle: {
    paddingLeft: 7,
    fontSize: 11,
    color: grey.disabled,
  },
  titlesRow: {
    display: 'flex',
    flexDirection: 'row',
  },
});

export const ListAvatar = ({ item, onClick }: ListAvatarTypes) => {
  const classes = useStyles();

  return (
    <Grid>
      <IconButton className={classes.listContainer} onClick={onClick}>
        <Grid>
          <UserAvatar avatarWidth={30} avatarHeight={30} img={item.img} />
        </Grid>
        <Grid className={classes.textContainer}>
          <Grid className={classes.titlesRow}>
            <Typography className={classes.titleStyle}>
              {item.rolDescription}
            </Typography>
            {item.nivel && item.rol !== ROL.DIRECCION_AREA && (
              <Typography className={classes.secondaryTitleStyle}>
                {item.nivel}
              </Typography>
            )}
          </Grid>
          {item.rol === ROL.DIRECCION_AREA ? (
            <Typography className={classes.subtitleStyle}>
              {item.dependenciaFuncional?.descripcion}
            </Typography>
          ) : (
            <Typography className={classes.subtitleStyle}>
              {item.schoolName}
            </Typography>
          )}
        </Grid>
      </IconButton>
    </Grid>
  );
};
