import React, { FunctionComponent } from 'react';
import { InitialGroupGrade } from './Initial';

export const ByCGroupGrade: FunctionComponent = () => {
  const rol = 'normal'; // sestose va a cambiar cuando tengamos el manejo de roles
  switch (rol) {
    default:
      return <InitialGroupGrade />;
  }
};
