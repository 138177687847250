import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Container,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { TimePicker } from '@material-ui/pickers';
import { Delete } from '@material-ui/icons';

import moment from 'moment';

import { CURRENT_CICLO_LECTIVO } from '../../../../Acap';
import { useCicloLectivoFetchById } from '../../../../../../app/business';
import { DatePickerV2 } from '../../../../../../app/components/inputs/Input/DatePickerV2';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import {
  DiasHabilitados,
  IWeekDays,
} from '../../../../../../app/models/DiasHabilitados';
import { DeepPartial } from '../../../../../../commons/types/DeepPartial';
import customFormDialog from '../../../../../../commons/services/customFormDialog';
import ConfirmationDialog from './ConfirmacionDialogo';
import { PeriodoNuevo } from '../../../../../../app/models';

const useStyles = makeStyles((theme) => ({
  daterange: {
    marginTop: theme.spacing(2),
    '& > .rdrDefinedRangesWrapper': {
      display: 'none',
    },
  },
  rangePickerContainer: {
    textAlign: 'center',
  },
  chipsContainer: {
    padding: theme.spacing(2),
    maxHeight: '300px',
    overflowY: 'scroll',
  },
  addChip: {
    backgroundColor: theme.palette.primary.main,
    color: 'black',
  },
  buttonPeriods: {
    width: '100%',
    height: 44,
    backgroundColor: '#00a0d6 !important',
    color: 'white',
    textTransform: 'none',
  },
  deleteIcon: {
    color: 'gray',
    borderColor: 'black',
    position: 'absolute',
    left: '108%',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  titleOne: {
    marginTop: '1px',
    marginBottom: '10px',
    'box-shadow': '0px 1px 0px 0px rgba(0, 0, 0, 0.3)',
    paddingBottom: '5px',
  },
  subTitleOne: {
    marginTop: '1px',
    marginBottom: '15px',
  },
  subTitleTwo: {
    marginTop: '10px',
    marginBottom: '5px',
  },
  datePickerLabel: {
    fontSize: 16,
    color: '#8A98A8',
    fontWeight: 'bold',
    fontFamily: 'Nunito',
    top: -13,
  },
  datePickerInput: {
    backgroundColor: '#F3F6F9',
    border: 'none',
    height: 50,
    display: 'flex',
    alignItems: 'flex-end',
    '& > fieldset': {
      border: 'none',
    },
    '& > input': {
      padding: '2px 6px',
    },
  },
  selectBg: {
    backgroundColor: '#F3F6F9',
  },
  timePicker: {
    width: '75px',
  },
  selectedDayContainer: {
    marginBottom: '5px',
  },
  openContainerBottoms: {
    marginTop: '10px',
    marginBottom: '10px',
  },
}));

const weekDays: Omit<IWeekDays, 'horaInicio' | 'horaFin'>[] = [
  { name: 'Lunes', id: 1 },
  { name: 'Martes', id: 2 },
  { name: 'Miercoles', id: 3 },
  { name: 'Jueves', id: 4 },
  { name: 'Viernes', id: 5 },
];

interface ModalDiasHabilitadosProps {
  initialValue: DeepPartial<DiasHabilitados>;
  saveDaysEnable: (
    daysState: IWeekDays[],
    selectedInitialDate: string,
    selectedFinalDate: string,
  ) => void;
  dataDias: DiasHabilitados[];
  diasPeriodoNuevo: PeriodoNuevo[];
}

export const ModalDiasHabilitados = ({
  initialValue,
  saveDaysEnable,
  dataDias,
  diasPeriodoNuevo,
}: ModalDiasHabilitadosProps) => {
  const classes = useStyles();
  const [daysState, setDaysState] = useState<IWeekDays[]>(
    (initialValue.dias as IWeekDays[]) || [],
  );
  const [daySelected, setDaySelected] = useState(false);
  const [isConfirmationOpen, setConfirmationOpen] = useState(false);
  const [selectedFinalDate, setSelectedFinalDate] = useState(
    moment().format('YYYY-MM-DD'),
  );
  const cicloLectivo = useCicloLectivoFetchById(CURRENT_CICLO_LECTIVO);

  const [selectedInitialDate, setSelectedInitialDate] = useState(
    moment().format('YYYY-MM-DD'),
  );

  const daySelectedController = () => {
    setDaySelected(true);
  };

  const handleClose = () => {
    customFormDialog.handleCancel();
  };

  const handleOpenConfirmation = () => {
    setConfirmationOpen(true);
  };

  const handleCloseConfirmation = () => {
    setConfirmationOpen(false);
  };

  const deleteDay = (day: IWeekDays) => {
    setDaysState((prevState) => {
      return prevState.filter((_day) => _day.id !== day.id);
    });
  };

  const handleDaySelection = (
    day: Omit<IWeekDays, 'horaInicio' | 'horaFin'>,
  ) => {
    daySelectedController();
    setDaysState((prevState) => {
      let cleanDay = { ...day, horaInicio: null, horaFin: null };
      return [...prevState, cleanDay];
    });
  };

  const isDisable = useMemo(() => {
    let invalidHour = daysState.some((state) => {
      return moment(state.horaInicio, 'HH:mm:ss').isAfter(
        moment(state.horaFin, 'HH:mm:ss'),
      );
    }, []);

    let noDays = daysState.length === 0;
    let emptyInitialDate = selectedInitialDate === null;
    let emptyFinalDate = selectedFinalDate === null;
    let invalidFechaDesde = moment(selectedInitialDate).isAfter(
      moment().format('YYYY-MM-DD'),
    );
    let invalidFechaHasta =
      moment(selectedFinalDate).isBefore(selectedInitialDate);

    let emptyInitialHour = false;
    let emptyFinalHour = false;
    daysState.forEach((state) => {
      if (state.horaInicio === null) {
        emptyInitialHour = true;
      }
      if (state.horaFin === null) {
        emptyFinalHour = true;
      }
    });

    let message = '';

    if (
      noDays ||
      emptyInitialDate ||
      emptyFinalDate ||
      emptyInitialHour ||
      emptyFinalHour ||
      invalidHour ||
      invalidFechaDesde ||
      invalidFechaHasta
    ) {
      if (daysState.length === 0) {
        message = 'Verificar datos';
      } else if (daysState.length > 0 && emptyInitialDate) {
        message = 'Fecha desde requerida';
      } else if (daysState.length > 0 && emptyFinalDate) {
        message = 'Fecha hasta requerida';
      } else if (emptyInitialHour) {
        message = 'Hora inicio inválida';
      } else if (emptyFinalHour) {
        message = 'Hora fin inválida';
      } else if (invalidHour) {
        message = 'Verificar horarios';
      } else if (invalidFechaDesde) {
        message = 'Fecha inicio inválida';
      } else if (invalidFechaHasta) {
        message = 'Verificar fechas';
      } else {
        message = '';
      }
    }

    return {
      isDisabled:
        noDays ||
        emptyInitialDate ||
        emptyFinalDate ||
        emptyInitialHour ||
        emptyFinalHour ||
        invalidHour ||
        invalidFechaDesde ||
        invalidFechaHasta,
      message,
    };
  }, [daysState, selectedInitialDate, selectedFinalDate]);

  const handleDayChange = useCallback(
    (day: IWeekDays, name: keyof IWeekDays) =>
      (date: MaterialUiPickersDate) => {
        if (!date) {
          return;
        }

        setDaysState((prevState) => {
          const index = prevState.findIndex((i) => i.id === day.id);
          if (index !== -1) {
            const updatedItem = { ...prevState[index], [name]: date.toDate() };
            const updatedState = [...prevState];
            updatedState[index] = updatedItem;
            return updatedState;
          }
          return prevState;
        });
      },
    [],
  );

  const handleInitialDateChange = (date: string) => {
    setSelectedInitialDate(date);
  };

  let setFinalDate: boolean = useMemo(() => false, []);
  const handleFinalDateChange = (date: string) => {
    setSelectedFinalDate(date);
  };

  let inicioCiclo = useMemo(() => {
    if (cicloLectivo.data)
      return moment(diasPeriodoNuevo[0].fechaInicio, 'YYYY-MM-DD');
    return null;
  }, [cicloLectivo.data, diasPeriodoNuevo]);

  let finCiclo = useMemo(() => {
    if (cicloLectivo.data)
      return moment(cicloLectivo.data.fechaFin, 'YYYY-MM-DD');
    return null;
  }, [cicloLectivo.data]);

  const formattedStartDate = moment(
    inicioCiclo || initialValue.fechaDesde,
    'YYYY-MM-DD',
  ).format('YYYY-MM-DD');
  const formattedEndDate = moment(
    finCiclo || initialValue.fechaHasta,
    'YYYY-MM-DD',
  ).format('YYYY-MM-DD');

  useEffect(() => {
    if (formattedStartDate) {
      setSelectedInitialDate(formattedStartDate);
    }
  }, [formattedStartDate]);

  useEffect(() => {
    if (formattedEndDate) {
      setSelectedFinalDate(formattedEndDate);
    }
  }, [formattedEndDate]);

  return (
    <Container maxWidth="sm">
      <Grid container direction="column">
        <Grid item className={classes.titleOne}>
          <Typography variant="h4">Habilitar días de clases</Typography>
        </Grid>
        <Grid item>
          <Grid container xs={12}>
            <Grid item xs={12} className={classes.subTitleOne}>
              <Typography variant="body1">¿Qué días dictas clases?</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                {weekDays.map((day) => {
                  return (
                    <Grid item>
                      <Button
                        disabled={!!daysState.find((ds) => ds.id === day.id)}
                        variant="contained"
                        color="primary"
                        onClick={() => handleDaySelection(day)}
                      >
                        {day.name}
                      </Button>
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid container>
          {daysState.length > 0 ? (
            <Grid item xs={12} className={classes.subTitleTwo}>
              <Typography variant="h5">
                ¿En qué horario dictas clases?
              </Typography>
            </Grid>
          ) : null}
          {daysState.map((day) => {
            return (
              <Grid
                item
                container
                xs={12}
                justifyContent="space-between"
                direction="row"
                className={classes.selectedDayContainer}
              >
                <Grid item container xs={12} spacing={1}>
                  <Grid item>
                    <Button variant="contained" color="primary">
                      {day.name}
                    </Button>
                  </Grid>
                  <Grid item>
                    <TimePicker
                      name={'horaInicio'}
                      className={classes.timePicker}
                      value={day.horaInicio}
                      clearable
                      placeholder={'00:00'}
                      disabled={false}
                      onChange={handleDayChange(day, 'horaInicio')}
                    />
                  </Grid>
                  <Grid item>
                    <TimePicker
                      name={'horaFin'}
                      className={classes.timePicker}
                      value={day.horaFin}
                      clearable
                      placeholder={'00:00'}
                      disabled={false}
                      onChange={handleDayChange(day, 'horaFin')}
                    />
                  </Grid>
                  <Grid item>
                    <IconButton
                      aria-label="delete"
                      onClick={() => deleteDay(day)}
                    >
                      <Delete />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
          {daySelected && (
            <Grid container justifyContent="flex-start" spacing={1}>
              <Grid item xs={12} className={classes.subTitleTwo}>
                <Typography variant="h5">Periodo</Typography>
              </Grid>
              <Grid item container spacing={1}>
                <Grid item>
                  <DatePickerV2
                    value={selectedInitialDate}
                    clearable
                    format="DD/MM/yyyy"
                    placeholder="dd/mm/aaaa"
                    onChange={(date: any) =>
                      handleInitialDateChange(date?.format() || null)
                    }
                    label="Fecha desde"
                    InputLabelProps={{
                      className: classes.datePickerLabel,
                      shrink: false,
                    }}
                    InputProps={{
                      className: classes.datePickerInput,
                      disableUnderline: true,
                    }}
                    SelectProps={{ classes: { root: classes.selectBg } }}
                  />
                </Grid>
                <Grid item>
                  <DatePickerV2
                    value={selectedFinalDate}
                    clearable
                    format="DD/MM/YYYY"
                    placeholder="dd/mm/aaaa"
                    onChange={(date: any) =>
                      handleFinalDateChange(date?.format() || null)
                    }
                    label="Fecha hasta"
                    InputLabelProps={{
                      className: classes.datePickerLabel,
                      shrink: false,
                    }}
                    InputProps={{
                      className: classes.datePickerInput,
                      disableUnderline: true,
                    }}
                    SelectProps={{ classes: { root: classes.selectBg } }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          container
          className={classes.openContainerBottoms}
          justifyContent="flex-end"
          spacing={1}
        >
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleClose()}
            >
              Cancelar
            </Button>
          </Grid>
          <Tooltip
            title={isDisable.message}
            open={isDisable.isDisabled}
            placement="top"
            arrow
          >
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={
                  !dataDias[0]
                    ? () =>
                        saveDaysEnable(
                          daysState,
                          selectedInitialDate,
                          selectedFinalDate,
                        )
                    : () => handleOpenConfirmation()
                }
                disabled={isDisable.isDisabled}
              >
                Habilitar
              </Button>
            </Grid>
          </Tooltip>
        </Grid>
        <ConfirmationDialog
          open={isConfirmationOpen}
          onClose={handleCloseConfirmation}
          onConfirm={() =>
            saveDaysEnable(daysState, selectedInitialDate, selectedFinalDate)
          }
          title="¿Desea Continuar?, los presentismos cargados podrían perderse y deberá
          volver a cargarlos"
        />
      </Grid>
    </Container>
  );
};
