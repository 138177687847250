import { Box } from '@material-ui/core';
import React, { useMemo, useCallback } from 'react';
import { useRouteMatch } from 'react-router';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import confirmDialog from 'src/commons/services/confirmDialog';
import {
  useEspaciosSimpDiscipPlanDAO,
  useSimpDiscipPlanDAO,
  useArealPlanDAO,
  useEspaciosArealPlanDAO,
  useArealPlanAreaDAO,
} from 'src/app/business';
import { EspaciosPlanificacion } from '../../../../../../../app/models';
import { OptionsModal } from '../../../../../../../commons/components';
import { makeActionButtons } from './makeActionButtons';

const useActionButtons = makeActionButtons(
  'idEspaciosSimpDiscipPlan',
  (row: EspaciosPlanificacion) => row.simpDiscipPlan.planificacionEstados,
  'simpDiscipPlan',
);

const useActionButtonsAreal = makeActionButtons(
  'idEspaciosArealPlan',
  (row: EspaciosPlanificacion) => row.arealPlan.planificacionEstados,
  'arealPlan',
);

export const useDisciplinarInterArealTableColumns = (
  reFetch: () => void,
  reFetchAreal: () => void,
) => {
  const { url } = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();

  const espaciosSimpDiscipPlan = useEspaciosSimpDiscipPlanDAO();
  const simpDiscipPlanDAO = useSimpDiscipPlanDAO();

  const handleDeletePlanificacionSimp = useCallback(
    async (planificacion) => {
      try {
        const confirm = await confirmDialog.show({
          title: 'Confirmación',
          content: `¿Esta seguro que desea eliminar la planificacion?`,
          confirmText: 'Eliminar',
          cancelText: 'No',
        });
        if (confirm) {
          await espaciosSimpDiscipPlan.remove({
            idEspaciosSimpDiscipPlan: planificacion.idEspaciosSimpDiscipPlan,
          });

          await simpDiscipPlanDAO.remove({
            idSimpDiscipPlan: planificacion.simpDiscipPlan.idSimpDiscipPlan,
          });
          enqueueSnackbar('Se ha eliminado con éxito', { variant: 'success' });

          reFetch();
        }
      } catch (e) {
        enqueueSnackbar('Ha ocurrido un error al intentar eliminar', {
          variant: 'error',
        });
      }
    },
    [espaciosSimpDiscipPlan, simpDiscipPlanDAO, reFetch, enqueueSnackbar],
  );

  const actionButtons = useActionButtons({
    urls: {
      edit: `${url}/planificacion/disciplinar`,
      feedback: `${url}/planificacionfeedback/disciplinar`,
      preview: `${url}/planificacionpreview/disciplinar`,
    },
    helper: {
      delete: handleDeletePlanificacionSimp,
    },
  });

  const espaciosArealPlan = useEspaciosArealPlanDAO();
  const arealPlanDAO = useArealPlanDAO();
  const arealPlanArea = useArealPlanAreaDAO();

  const handleDeletePlanificacionAreal = useCallback(
    async (planificacion) => {
      const espaciosArealPlans = await espaciosArealPlan
        .query()
        .equals('id_areal_plan', planificacion.arealPlan.idArealPlan)
        .run();

      try {
        const confirm = await confirmDialog.show({
          title: 'Confirmación',
          content: `¿Esta seguro que desea eliminar la planificacion?`,
          confirmText: 'Eliminar',
          cancelText: 'No',
        });
        if (confirm) {
          for (let element of espaciosArealPlans) {
            await espaciosArealPlan.remove({
              idEspaciosArealPlan: element.idEspaciosArealPlan,
            });
          }
          for (let element of planificacion.arealPlan.arealPlanAreas) {
            await arealPlanArea.remove({
              idArealPlanArea: element.idArealPlanArea,
            });
          }
          await arealPlanDAO.remove({
            idArealPlan: planificacion.arealPlan.idArealPlan,
          });
          enqueueSnackbar('Se ha eliminado con éxito', { variant: 'success' });
          reFetchAreal();
        }
      } catch (e) {
        enqueueSnackbar('Ha ocurrido un error al intentar eliminar', {
          variant: 'error',
        });
        reFetchAreal();
      }
    },
    [
      espaciosArealPlan,
      arealPlanDAO,
      enqueueSnackbar,
      reFetchAreal,
      arealPlanArea,
    ],
  );

  const actionButtonsAreal = useActionButtonsAreal({
    urls: {
      edit: `${url}/planificacion/interArealInterAreal`,
      feedback: `${url}/planificacionfeedback/interArealInterAreal`,
      preview: `${url}/planificacionpreview/interArealInterAreal`,
    },
    helper: {
      delete: handleDeletePlanificacionAreal,
    },
  });

  return useMemo(
    () => [
      {
        id: 'espacioCurricular.Area',
        label: 'Áreas/orientación',
        width: '15%',
        orderById: {
          disciplinar: 'espacioCurricular.materia.area.descripcion',
          areal: 'arealPlan.arealPlanAreas.area.descripcion',
        },
        render: (value: any, row: EspaciosPlanificacion) => (
          <>
            {row.simpDiscipPlan
              ? row.espacioCurricular.materia.area.descripcion
              : row.arealPlan.arealPlanAreas
                  .map((item) => item.area.descripcion)
                  .join(', ')}
          </>
        ),
      },
      {
        id: 'espacioCurricular.PlanEstudio',
        label: 'Proyecto',
        width: '15%',
        orderById: {
          disciplinar: 'espacioCurricular.materia.descripcion',
          areal: 'espacioCurricular.planEstudio.descripcionPlanEstudio',
        },
        render: (value: any, row: EspaciosPlanificacion) => (
          <Box whiteSpace="nowrap">
            {row.simpDiscipPlan
              ? row.espacioCurricular.materia.descripcion
              : row.arealPlan.proyecto.nombre}
          </Box>
        ),
      },
      {
        id: 'tipo',
        label: 'Tipo',
        width: '13%',
        style: {
          whiteSpace: 'nowrap',
        },
        hideSortIcon: true,
        render: (value: any, row: EspaciosPlanificacion) =>
          row.simpDiscipPlan ? 'Disciplinar' : 'Areal/InterAreal',
      },
      {
        id: 'simpDiscipPlan.ultimaActualizacion',
        label: 'Ultima modificación',
        width: '10%',
        orderById: {
          disciplinar: 'simpDiscipPlan.ultimaActualizacion',
          areal: 'arealPlan.ultimaActualizacion',
        },
        render: (value: any, row: EspaciosPlanificacion) => (
          <Box whiteSpace="nowrap">
            {row.simpDiscipPlan
              ? row.simpDiscipPlan.ultimaActualizacion
                ? moment(row.simpDiscipPlan.ultimaActualizacion).format(
                    'DD/MM/YYYY - HH:mm',
                  )
                : '-'
              : row.arealPlan.ultimaActualizacion
              ? moment(row.arealPlan.ultimaActualizacion).format(
                  'DD/MM/YYYY - HH:mm',
                )
              : '-'}
          </Box>
        ),
      },
      {
        id: 'estado',
        label: 'Estado',
        width: '10%',
        style: { textAlign: 'center' },
        orderById: {
          disciplinar: 'simpDiscipPlan.planificacionEstados.nombre',
          areal: 'arealPlan.planificacionEstados.nombre',
        },
        render: (value: any, row: EspaciosPlanificacion) => {
          return (
            <Box whiteSpace="nowrap" textAlign="center">
              {row.simpDiscipPlan
                ? row.simpDiscipPlan.planificacionEstados.nombre ?? '-'
                : row.arealPlan.planificacionEstados.nombre ?? '-'}
            </Box>
          );
        },
      },
      {
        id: 'nombre',
        label: 'Creado Por',
        width: '10%',
        style: { textAlign: 'center' },
        orderById: {
          disciplinar: 'simpDiscipPlan.cuentas.nombreUsuario',
          areal: 'arealPlan.cuentas.nombreUsuario',
        },
        render: (value: any, row: EspaciosPlanificacion) => {
          return (
            <Box whiteSpace="nowrap" textAlign="center">
              {row.simpDiscipPlan
                ? `${row.simpDiscipPlan.cuentas.nombreUsuario} ${row.simpDiscipPlan.cuentas.apellidoUsuario}` ??
                  '-'
                : `${row.arealPlan.cuentas.nombreUsuario} ${row.arealPlan.cuentas.apellidoUsuario}` ??
                  '-'}
            </Box>
          );
        },
      },
      {
        id: 'icono',
        label: '',
        width: '5%',
        style: { textAlign: 'right' },
        hideSortIcon: true,
        render: (value: any, row: EspaciosPlanificacion) => (
          <Box whiteSpace="nowrap" textAlign="right">
            <OptionsModal
              options={row.simpDiscipPlan ? actionButtons : actionButtonsAreal}
              data={row}
            />
          </Box>
        ),
      },
    ],
    [actionButtons, actionButtonsAreal],
  );
};
