import React from 'react';
import { Grid } from '@material-ui/core';
import { SimpleSelect } from 'src/commons/components/Select';

export interface descripcionType {
  descripcion: string;
}

function InputMateria({ content, value, handleChange, state }: any) {
  content.sort((a: descripcionType, b: descripcionType) =>
    a?.descripcion > b?.descripcion ? 1 : -1,
  );
  return (
    <Grid>
      <SimpleSelect
        content={content}
        value={value}
        placeholder="Seleccioná un Espacio Curricular"
        title="Espacio Curricular"
        labelKey="descripcion"
        disabled={!state.idSeccion || !state.idAnio}
        handleChange={handleChange}
        valueKey="idEspacioCurricular"
      />
    </Grid>
  );
}

export default InputMateria;
