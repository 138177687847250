import { useEffect, useRef, useCallback, useState } from 'react';
import { LibbyObject } from '../../types/types';

/*
  This hook is used to call a method in a DAO with libby. The call is trigger inside an useEffect.

  params:
  - libby: Libby object provided by DatabaseConnector
  - Options object:
    - daoName: name of the dao to use (must be connected with the libby HOC)
    - methodName: name of the method to call in the dao
    - params: params to be passed like arguments to the dao call
  returns an object with:
  - data: result of the call, before success is an empty array
  - working: boolean, can be used like a loading state
  - recall : function to call the method again, this function can received params that
             override the passed like options. If none passed it will use the passed
             like options
  Example of use
  const {
    data: establishments, working,
  } = useLibbyCall(libby, {
    daoName: 'establishment',
    methodName: 'fetchByLocation',
    params: [lat, lng],
  });
 */

export type OptionsLibbyCall = {
  daoName?: string;
  methodName?: string;
  params?: any[];
  noAutoCall?: boolean;
};

export const useLibbyCall = (
  libby: LibbyObject,
  {
    daoName = '',
    methodName = '',
    params = [],
    noAutoCall = false,
  }: OptionsLibbyCall,
) => {
  const paramsRef = useRef(params);
  const [working, setWorking] = useState(false);
  const recall = useCallback(
    async (...newParams) => {
      if (!daoName || !methodName) {
        // eslint-disable-next-line
        console.error('Required options: daoName and methodName');
        return null;
      }
      if (!libby[daoName] || !libby[daoName][methodName]) {
        // eslint-disable-next-line
        console.error('Cannot found dao or method in dao');
        return null;
      }
      const paramsToBeUsed = newParams.length ? newParams : paramsRef.current;
      setWorking(true);
      const result = await libby[daoName][methodName](...paramsToBeUsed);
      setWorking(false);
      return result;
    },
    [daoName, libby, methodName],
  );
  useEffect(() => {
    if (!noAutoCall) {
      recall();
    }
  }, [noAutoCall, recall]);

  return !libby[daoName] ? {} : { data: libby[daoName].data, working, recall };
};
