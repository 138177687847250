import React from 'react';
import { useField } from 'react-final-form-hooks';
import { SimpleSelect } from '../Select';
import { inputStyles } from './styles';

export const SelectWithField = ({
  disabled = false,
  name,
  width,
  ...props
}: any) => {
  const field = useField(name, props.form);
  const classes = inputStyles();

  return (
    <div style={width !== undefined ? (width = { width }) : undefined}>
      <SimpleSelect {...props} {...field.input} disabled={disabled} />
      {field.meta.touched && field.meta.error && (
        <span className={classes.spanError}>{field.meta.error}</span>
      )}
    </div>
  );
};
