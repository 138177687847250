import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class CierreDAO extends LibbyFetchDAO {
  constructor() {
    super('cierre', 'idCierre');
  }

  getAll() {
    return this.query().limit(0, 1000).run();
  }
}
