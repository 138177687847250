import { MenuItem } from '../../../../../commons/const/ui/menu';
import {
  ArealInterareal,
  PlanificacionDisciplinarOrientada,
  Interdisciplinar,
  PlanificacionDisciplinarComun,
} from '../../../../../screens/Private/EspaciosCurriculares/EspacioCurricular/Planificacion/forms';

export const planificacionesRoutes: MenuItem[] = [
  {
    key: 'interArealInterAreal',
    basePath: '/interArealInterAreal/:idPlanificacion',
    target: {
      component: ArealInterareal,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Planificacion Areal/Interareal',
    children: [],
  },
  {
    key: 'interArealInterDisciplinar',
    basePath: '/interDisciplinar/:idPlanificacion',
    target: {
      component: Interdisciplinar,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Planificacion InterDisciplinar',
    children: [],
  },
  {
    key: 'disciplinar',
    basePath: '/disciplinar/:idPlanificacion',
    target: {
      component: PlanificacionDisciplinarComun,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Planificacion Disciplinar',
    children: [],
  },
  {
    key: 'disciplinarOrientada',
    basePath: '/disciplinarOrientada/:idPlanificacion',
    target: {
      component: PlanificacionDisciplinarOrientada,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Planificacion Disciplinar Orientada',
    children: [],
  },
];
