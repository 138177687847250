import { useCallback } from 'react';
import { Alumno, Seccion } from 'src/app/models';
import { getFilterPassSection } from '../../utils/getFilterPassSection';
import { MAPPED_PASE_ANIO_OPTIONS_SECUNDARIO } from '../../utils/mappedPaseAnioOptions';

const {
  Egresa: EGRESA,
  'Promoción en proceso': PROMOCION_EN_PROCESO,
  'Con promoción acompañada': PROMOCION_CON_APOYO,
  'No completó el Plan de Estudio': NO_COMPLETO_PLAN_DE_ESTUDIOS,
} = MAPPED_PASE_ANIO_OPTIONS_SECUNDARIO;

export const useHandleChangePassStatus = (
  sections: Seccion[],
  setStudentsData: (arg0: (prev: any) => any) => void,
  setFormValues: (arg0: (prev: { alumno: Alumno }[]) => any[]) => void,
  nivel: number,
) => {
  const handleChangePassStatus = useCallback(
    (e: React.FormEvent<EventTarget>, restStudentData) => {
      const target = e.target as HTMLInputElement;

      const statusPass = Number(target.value);
      const options = [...sections];
      const currentCicloLectivo = restStudentData?.cicloLectivo;
      const numeroAnio = restStudentData?.currentYear?.numeroAnio;
      const descripcionAnioSection = restStudentData.currentSection;
      const idAnio = restStudentData?.idAnio;

      const { filteredOptions } = getFilterPassSection(
        options,
        statusPass,
        currentCicloLectivo,
        numeroAnio,
        nivel,
        descripcionAnioSection,
        idAnio,
        restStudentData.turno,
      );

      const newStudentData = {
        ...restStudentData,
        statusPass,
        sectionPass: [PROMOCION_EN_PROCESO.id].includes(statusPass)
          ? '-'
          : [EGRESA.id].includes(statusPass)
          ? null
          : filteredOptions[0]?.idSeccion ?? 'No Concurrirá',
        observacion: [
          NO_COMPLETO_PLAN_DE_ESTUDIOS.id,
          PROMOCION_CON_APOYO.id,
        ].includes(statusPass)
          ? restStudentData.observacion
          : null,
      };

      setStudentsData((prev: any) =>
        prev.map((student: any) => {
          if (student.idStudent === restStudentData.idStudent) {
            return newStudentData;
          }
          return student;
        }),
      );

      setFormValues((prev: any) =>
        prev.map((student: any) => {
          if (student.alumno === restStudentData.idStudent) {
            student = {
              ...student,
              estadoPase: newStudentData.statusPass,
              idSeccion: newStudentData.sectionPass,
              observacion: newStudentData.observacion,
            };
          }
          return student;
        }),
      );
    },
    [nivel, sections, setFormValues, setStudentsData],
  );

  return handleChangePassStatus;
};
