import React, { useRef } from 'react';
import { Grid } from '@material-ui/core';
import { useRolesContext } from 'src/context/RolesContext';
import {
  SeccionProvider,
  EspacioCurricularByIdProvider,
} from 'src/app/business';
import { useInfoCards } from './hooks';
import { EspaciosSimpDiscipPlan } from '../../../../../../../app/models';
import { DisciplinarComunProvider } from '../../forms/PlanificacionDisciplinarComun/components/context';
import { DisciplinarComunFlowHeader } from './components';
import { InfoCard } from '../../feedbacks/commons';

export interface DisciplinarComunPreviewFlowProps {
  data: EspaciosSimpDiscipPlan;
}

export const DisciplinarComunPreviewFlow = ({
  data,
}: DisciplinarComunPreviewFlowProps) => {
  const cards = useInfoCards(data);

  const { selectedRole } = useRolesContext();

  const seccionFilter = useRef({
    filter: [{ path: 'localizacion', value: selectedRole.localizacionId }],
  });

  return (
    <SeccionProvider filter={seccionFilter.current}>
      <>
        <DisciplinarComunFlowHeader data={data} toPrint />
        <Grid container>
          <Grid item sm={12}>
            <EspacioCurricularByIdProvider
              id={data.espacioCurricular.idEspacioCurricular}
            >
              <DisciplinarComunProvider>
                <InfoCard title="Planificación" cards={cards} />
              </DisciplinarComunProvider>
            </EspacioCurricularByIdProvider>
          </Grid>
        </Grid>
      </>
    </SeccionProvider>
  );
};
