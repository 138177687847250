import { PresenteGeneral } from '../types';
import {
  Presente_v3,
  PresentismoEspacioCurricular,
} from '../../../../../app/models';

export const mapPresentesByEc = (
  isEspacioCurricular: Boolean,
  presentes: Presente_v3[],
  presenteEC: PresentismoEspacioCurricular[],
) => {
  let presentismo: PresenteGeneral[] = [];
  if (isEspacioCurricular) {
    presentismo = presenteEC.map<PresenteGeneral>((pEc) => ({
      idPresente: pEc.idPresenteEspacioCurricular.toString(),
      alumno: pEc.alumno,
      fecha: pEc.fecha,
      estadoPresente: pEc.estadoPresente,
      espacioCurricular: pEc.espacioCurricular,
      seccion: pEc.seccion,
      periodoNuevo: pEc.periodoNuevo,
      justificacion: pEc.justificacion || null,
      turno: pEc.turno || null,
      situacion: pEc.situacion || null,
      isJornadaExtendida: false,
      observacion: pEc.observacion,
      contraturnoDia: null,
    }));
  } else {
    presentismo = presentes.map<PresenteGeneral>((pEc) => ({
      idPresente: pEc.idPresente,
      alumno: pEc.alumno,
      fecha: pEc.fecha,
      estadoPresente: pEc.estadoPresente,
      espacioCurricular: null,
      seccion: pEc.seccion,
      periodoNuevo: pEc.periodoNuevo,
      justificacion: pEc.justificacion || null,
      turno: pEc.turno || null,
      situacion: pEc.situacion || null,
      isJornadaExtendida: pEc.isJornadaExtendida,
      espacio: pEc.espacio,
      observacion: pEc.observacion,
      contraturnoDia: pEc.contraturnoDia || null,
    }));
  }
  return presentismo;
};
