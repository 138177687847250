import { Box, Button, Grid, TextField, Typography } from '@material-ui/core';
import React, { useMemo, useCallback } from 'react';
import { AnyObject, ROL, TabBar } from '../../../../../../commons';
import { PerfilEgresado } from './PerfilEgresado';
import { HabilidadesYCompetencias } from './HabilidadesYCompetecias';
import { Contenidos } from './Contenidos';
import { useEvaluacionFormContext } from '../../../hooks/useEvaluacionFormContext';
import { DEVOLUCIONES_SCREEN } from '../../../enums/DevolucionesScreen';
import { useEscuelaContext } from '../../../context/EscuelaContext';
import { useRolesContext } from 'src/context/RolesContext';

const allTabs = [
  {
    title: 'Perfil Egresado',
    component: <PerfilEgresado />,
    default: false,
  },
  {
    title: 'Habilidades y competencias',
    component: <HabilidadesYCompetencias />,
  },
  {
    title: 'Contenidos',
    component: <Contenidos />,
  },
];

export const FormView = () => {
  const { setCurrentTap, loading, tab, setTab, disabledNext } =
    useEscuelaContext();
  const { form, values, errors, handleSubmit } = useEvaluacionFormContext();

  const {
    selectedRole: { rol },
  } = useRolesContext();

  const disableSave = useMemo(() => {
    if (Object.entries(errors as AnyObject).length > 0) return true;

    const isRolAcap = [
      ROL.REFERENTE_ACAP,
      ROL.DIRECTIVO,
      ROL.EQUIPO_ACAP,
    ].includes(Number(rol));

    return isRolAcap;
  }, [errors, rol]);

  const handleGotoTAB = useCallback(
    (setTabValue) => {
      if (tab !== 0) {
        setTabValue(tab);
      }
    },
    [tab],
  );

  return (
    <Grid container>
      <Grid item>
        <Typography variant="h3">Realizar Devolución</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        container
        component={Box}
        padding={4}
        height="auto"
        marginY={4}
        border={'1px solid #000000'}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            container
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={3} sm={5}>
              <Typography align="right" variant="h5">
                Estudiante:
              </Typography>
            </Grid>
            <Grid item xs={9} sm={7}>
              <TextField
                label=""
                fullWidth
                value={`${
                  values.grupoInscripcion?.alumnoMovimiento?.alumno?.persona
                    .apellido || ''
                } ${
                  values.grupoInscripcion?.alumnoMovimiento?.alumno?.persona
                    ?.nombre || '-'
                }`}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            container
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={3} sm={5}>
              <Typography align="right" variant="h5">
                Espacio curricular:
              </Typography>
            </Grid>
            <Grid item xs={9} sm={7}>
              <TextField
                label=""
                fullWidth
                value={values.espacioCurricular?.descripcion || ''}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            container
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={3} sm={5}>
              <Typography align="right" variant="h5">
                Acción:
              </Typography>
            </Grid>
            <Grid item xs={9} sm={7}>
              <TextField
                label=""
                fullWidth
                value={
                  values.grupoInscripcion?.ofertaGrupo?.ofertaTurno?.oferta
                    ?.accion.nombre || ''
                }
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TabBar content={allTabs} goToTab={handleGotoTAB} />
      </Grid>
      <Grid item xs={12} container justifyContent="center" spacing={3}>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            component="label"
            style={{ marginTop: '20px' }}
            onClick={() => {
              if (tab !== 0) setTab(0);
              setCurrentTap(DEVOLUCIONES_SCREEN.ENROLES);
            }}
          >
            Volver
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            component="label"
            disabled={loading || disableSave}
            style={{ marginTop: '20px' }}
            onClick={() => {
              form.change('tab', '');
              handleSubmit();
            }}
          >
            Guardar
          </Button>
        </Grid>
        {values.tab && !disabledNext && (
          <Grid item>
            <Button
              color="primary"
              variant="contained"
              component="label"
              disabled={loading || disableSave}
              style={{ marginTop: '20px' }}
              onClick={() => {
                form.change('tab', '2');
                handleSubmit();
              }}
            >
              Guardar y continuar
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
