import { Box, Grid, TextField, Typography } from '@material-ui/core';
import React from 'react';
import { SliderGroup, SliderInputItem } from '../../sliderGroup';
import { Loading, ROL } from 'src/commons';
import { useEscuelaContext } from '../../../context/EscuelaContext';
import { useRolesContext } from 'src/context/RolesContext';
import DianaChart from 'src/commons/components/Diana/DianaChart';
import { useRadarStyles } from 'src/screens/Private/AcapEstudiante/components';

interface ApreciacionAccionProps {
  apreciacion: Acap.Estudiante.ApreciacionInitialValues;
  loading: boolean;
}

export const ApreciacionAccion = ({
  apreciacion,
  loading = false,
}: ApreciacionAccionProps) => {
  const { criterios } = useEscuelaContext();
  const {
    selectedRole: { rol },
  } = useRolesContext();
  const classes = useRadarStyles();

  return loading ? (
    <Loading />
  ) : (
    <Grid container component={Box} paddingY={2} id="PADRE">
      <Grid container spacing={2} id="HIJO">
        <Grid
          item
          xs={12}
          lg={6}
          alignItems="center"
          justifyContent="center"
          style={{ marginTop: '6px', marginBottom: '6px', display: 'flex' }}
        >
          <div className={classes.radar}>
            <DianaChart
              title="Apreciación de la acción"
              labels={apreciacion.evaluaciones.map(
                (eva) => eva.criterioEvaluacion.nombre,
              )}
              evaluaciones={apreciacion.evaluaciones.map((v) => v.valor)}
            />
          </div>
        </Grid>
        <Grid item xs={12} lg={6} container justifyContent="center">
          <SliderGroup
            sliders={criterios
              .filter((c) => !c.forOrganizacion)
              .map<SliderInputItem>((criterio, index) => {
                const _eval = apreciacion.evaluaciones.find(
                  (evaluacion) =>
                    evaluacion.criterioEvaluacion.idCriterioEvaluacion ===
                    criterio.idCriterioEvaluacion,
                );
                return {
                  id: _eval?.idApreciacionValor || index.toString(),
                  label: criterio.nombre,
                  value: _eval?.valor ?? 0,
                };
              })}
            onChange={() => {}}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} container spacing={2}>
        <Grid item xs={12} sm={2} md={2} lg={1}>
          <Typography
            style={{
              fontWeight: 600,
            }}
          >
            Observaciones:
          </Typography>
        </Grid>

        <Grid item container xs={12} sm={10} md={10} lg={11}>
          <Grid item xs={12} style={{ marginBottom: '10px' }}>
            <TextField
              label=""
              fullWidth
              value={apreciacion.observaciones}
              multiline
              minRows={3}
              InputProps={{
                disableUnderline: true,
                readOnly: true,
              }}
            />
          </Grid>
          {Number(rol) ===
          ROL.DOCENTE /** El docente es el unico que puede ver estas observaciones (sorry por el missmatch de observaciones) */ ? (
            <Grid item xs={12}>
              <TextField
                label=""
                fullWidth
                value={apreciacion.observacionesReferente}
                multiline
                minRows={3}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};
