import React from 'react';
import { Grid } from '@material-ui/core';
import { EnsenanzasOrientaPlan, Propositos } from 'src/app/models';
import { useDisciplinarOrientadaContext } from '../../../forms/PlanificacionDisciplinarOrientada/Context';
import { ListItemProperties } from '../../../feedbacks/commons';

export interface EnsenanzasOrientadaProps {
  data: EnsenanzasOrientaPlan;
}
export const Ensenanzas = ({ data }: EnsenanzasOrientadaProps) => {
  const {
    data: { propositos },
    working,
  } = useDisciplinarOrientadaContext();

  const filterPropositos = data.propositosDeEnsenanza?.map(
    (proposito: Propositos) => {
      const filterEnsenanzas = propositos[proposito.bloque];
      const final = filterEnsenanzas?.values
        ?.filter((valor: any) =>
          proposito.ensenanzas?.find(
            (ensenanza: string) => valor.idPropositoBloque === ensenanza,
          ),
        )
        .map(({ idPropositoBloque, descripcion }: any) => ({
          id: idPropositoBloque,
          descripcion,
        }));
      if (proposito?.adicionales?.length) final?.push(...proposito.adicionales);
      return {
        ensenanzas: final,
        bloque: filterEnsenanzas?.bloque,
      };
    },
  );

  return (
    <Grid container direction="column">
      {!working &&
        filterPropositos.map((proposito: any) => (
          <ListItemProperties
            title={proposito?.bloque?.descripcion || ''}
            values={proposito?.ensenanzas || []}
          />
        ))}
    </Grid>
  );
};
