import React, { useCallback } from 'react';
import { useSeccionContext } from 'src/app/business';
import { useParams } from 'react-router';
import {
  Seccion,
  BimestrePlan,
  ContenidoEspecifico,
  ContenidoEspecificoSubnivel,
  ContenidoEspecificoSubnivelDos,
} from 'src/app/models';
import { useDisciplinarComunContext } from '../../../forms/PlanificacionDisciplinarComun/components/context';
import { useGruposSeccion } from '../../../hooks';

export const useBimestreDisciplinarLogic = () => {
  const { idEspacioCurricular } = useParams();
  const { data: secciones, working: _working } = useSeccionContext();
  const { transformeGrupos, working: gruposWorking } = useGruposSeccion([
    idEspacioCurricular,
  ]);

  const {
    data: { habilidades, recursos, instrumentos, contenidosEspecificos },
    working,
  } = useDisciplinarComunContext();

  const getfilters = useCallback(
    (bimestre: BimestrePlan) => {
      const filterHabilidades = habilidades
        .filter((habilidad) =>
          bimestre?.habilidad?.find(
            (_habilidad) => habilidad.idHabilidades === Number(_habilidad),
          ),
        )
        .map(({ idHabilidades, descripcion }) => ({
          id: idHabilidades,
          descripcion,
        }));

      const filterRecursos = recursos
        .filter((recurso) =>
          bimestre?.recursos?.find(
            (_recurso) => Number(_recurso) === recurso.idRecursos,
          ),
        )
        .map(({ idRecursos, descripcion }) => ({
          id: idRecursos,
          descripcion,
        }));
      const filterInstrumentos = instrumentos
        .filter((instrumento) =>
          bimestre?.intrumentosDeEvaluacion?.find(
            (_instrumento) =>
              Number(_instrumento) === instrumento.idInstrumentosDeEvaluacion,
          ),
        )
        .map(({ idInstrumentosDeEvaluacion, descripcion }) => ({
          id: idInstrumentosDeEvaluacion,
          descripcion,
        }));

      const filterContenidos = contenidosEspecificos
        .filter((item) =>
          bimestre?.contenidosEspecificos?.find(
            (_item) =>
              item.idContenidoEspecifico.toString() === _item.id.toString(),
          ),
        )
        .map((contenido: ContenidoEspecifico) => ({
          id: contenido.idContenidoEspecifico,
          descripcion: contenido.descripcion,
          subLevel: contenido.contenidoEspecificoSubnivels
            .filter((subNivel: ContenidoEspecificoSubnivel) =>
              bimestre?.contenidosEspecificos?.find((_contenido) =>
                _contenido.subLevel.find(
                  (_subLevel) =>
                    subNivel.idContenidoEspecificoSubnivel.toString() ===
                    _subLevel.id.toString(),
                ),
              ),
            )
            .map((subNivel: ContenidoEspecificoSubnivel) => ({
              id: subNivel.idContenidoEspecificoSubnivel,
              descripcion: subNivel.descripcion,
              subLevel2: subNivel.contenidoEspecificoSubnivelDos
                .filter((subNivel2: ContenidoEspecificoSubnivelDos) =>
                  bimestre?.contenidosEspecificos?.find(
                    (_contenido) =>
                      !!_contenido.subLevel.find((_contenido2) =>
                        _contenido2.subLevel2.find(
                          (valor: string) =>
                            valor ===
                            subNivel2.idContenidoEspecificoSubnivelDos.toString(),
                        ),
                      ),
                  ),
                )
                .map(
                  ({
                    idContenidoEspecificoSubnivelDos,
                    descripcion,
                  }: ContenidoEspecificoSubnivelDos) => ({
                    id: idContenidoEspecificoSubnivelDos,
                    descripcion,
                  }),
                ),
            })),
        }));

      const filterSecciones = [
        ...(secciones || []),
        ...transformeGrupos,
      ].filter((_seccion: Seccion) =>
        bimestre?.seccion?.find(
          (valor: string) => _seccion.idSeccion === valor,
        ),
      );

      return {
        filterSecciones,
        filterContenidos,
        filterHabilidades,
        filterRecursos,
        filterInstrumentos,
      };
    },
    [
      contenidosEspecificos,
      recursos,
      habilidades,
      instrumentos,
      secciones,
      transformeGrupos,
    ],
  );
  return { getfilters, loading: working || _working || gruposWorking };
};
