import { AlumnoMovimientoCustomDAO } from 'src/platform/libby/dao';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { AlumnoMovimientoCustom } from '../models/AlumnoMovimientoCustom';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'alumno_movimiento_custom';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idAlumnoMovimiento',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idAlumnoMovimiento',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.AlumnoMovimiento
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.AlumnoMovimiento,
      hoc: { ...defaultOptions.hoc, ...businessOptions.AlumnoMovimiento.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<AlumnoMovimientoCustom, AlumnoMovimientoCustomDAO>(
  DAO_NAME,
  optionsToUse,
);

// hooks
export const useAlumnoMovimientoCustomFetchById = fetchById;
export const useAlumnoMovimientoCustomLibbyCall = libbyCall;
export const useAlumnoMovimientoCustomDAO = libbyDAO;
export const useAlumnoMovimientoCustomLibbyFetch = libbyFetch;

// components/hoc
export const AlumnoMovimientoCustomAutocomplete = entityAutocomplete;
export const AlumnoMovimientoCustomCall = renderPropCall;
export const AlumnoMovimientoCustomById = renderPropFetchById;
export const AlumnoMovimientoCustomSelect = simpleSelect;
export const AlumnoMovimientoCustomAccordionSelector = accordionSelector;
export const AlumnoMovimientoCustomSelectedChips = selectedChips;

// context
// ByIdContext
export const useAlumnoMovimientoCustomByIdContext = useByIdContext;
export const AlumnoMovimientoCustomByIdProvider = ByIdProvider;
export const AlumnoMovimientoCustomByIdContext = ByIdContext;
// GeneralContext
export const useAlumnoMovimientoCustomContext = useGeneralContext;
export const AlumnoMovimientoCustomProvider = GeneralProvider;
export const AlumnoMovimientoCustomContext = GeneralContext;
