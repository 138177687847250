import { ConfiguracionDAO } from 'src/platform/libby/dao';
import { makeBusinessEntity } from '../../lib/libby/generator';
import { Configuracion } from '../models';

const DAO_NAME = 'configuracion';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  context: {
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Configuracion, ConfiguracionDAO>(DAO_NAME);

// hooks
export const useConfiguracionFetchById = fetchById;
export const useConfiguracionLibbyCall = libbyCall;
export const useConfiguracionDAO = libbyDAO;
export const useConfiguracionLibbyFetch = libbyFetch;

// GeneralContext
export const useConfiguracionContext = useGeneralContext;
export const ConfiguracionProvider = GeneralProvider;
export const ConfiguracionContext = GeneralContext;
