import { useHistory } from 'react-router';
import { useMemo, useEffect, useState } from 'react';
import { BLOCKER_ID } from 'src/platform/permission/const';
import { useRolesContext } from 'src/context/RolesContext';
import { ROL } from 'src/commons/const/rol';
import { PlanificacionEstados } from '../../../../../../../app/models/PlanificacionEstados';
import { PLANIFICACION_ESTADOS } from '../../../../../../../commons/const';
import { useGeneralContext } from 'src/context/GeneralContext';
import { isHistoricSection } from 'src/screens/Private/Matriculation/utils/isHistoricSection';
import { useProyeccionEstadoLibbyFetch } from 'src/app/business';

export type GetEstadoFunction = (row: any) => PlanificacionEstados;

export type ActionButtons = {
  urls: {
    edit: string;
    feedback: string;
    preview: string;
  };
  helper?: any;
};

export const makeActionButtons =
  (pkKey: string, getEstado: GetEstadoFunction, pkKeyUser: string) =>
  ({ helper, urls: { edit, feedback, preview } }: ActionButtons) => {
    const history = useHistory();
    const { selectedRole, userInfo } = useRolesContext();
    const {
      generalState: { cicloLectivo: cicloLectivoState },
      dispatch,
    } = useGeneralContext();
    const [currentCicloLectivo, setCurrentCicloLectivo] =
      useState(cicloLectivoState);

    const { localizacionId } = selectedRole;
    const filterProyeccionEstado = useMemo(
      () => ({
        0: [{ path: 'localizacion', value: localizacionId }],
      }),
      [localizacionId],
    );

    // Se consulta el estado para obtener el ciclo lectivo actual
    // siempre que éste no exista en el generalState
    const { data: proyeccionEstado = [] } = useProyeccionEstadoLibbyFetch({
      filter: filterProyeccionEstado,
      enabled: !Boolean(cicloLectivoState),
      orderBy: 'cicloLectivo',
      direction: 'desc',
    });
    useEffect(() => {
      if (proyeccionEstado[0]?.cicloLectivo.anio) {
        setCurrentCicloLectivo(proyeccionEstado[0]?.cicloLectivo.anio);
      }
    }, [proyeccionEstado]);

    return useMemo(
      () => [
        {
          id: BLOCKER_ID.EDIT_PLANIFICATIONS,
          label: 'Editar',
          onClick: (data: any) => history.push(`${edit}/${data[pkKey]}`),
          hide: (row: any) => {
            const estado = getEstado(row);
            const isTheSame = (element: { espacioCurricular?: { id: any } }) =>
              element.espacioCurricular?.id ===
              row.espacioCurricular.idEspacioCurricular;

            const resultInterdiscipPlan =
              row?.interdiscipPlan?.espacioCurricular?.espaciosCurriculares.some(
                isTheSame,
              );

            const resultArealdiscipPlan =
              row?.arealPlan?.espacioCurricular?.espaciosCurriculares.some(
                isTheSame,
              );

            const cicloLectivo =
              row[
                row.arealPlan
                  ? 'arealPlan'
                  : row.interdiscipPlan
                  ? 'interdiscipPlan'
                  : row.discipOrientPlan
                  ? 'discipOrientPlan'
                  : 'simpDiscipPlan'
              ].cicloLectivo.anio;

            if (
              isHistoricSection({
                cicloLectivo,
                currentCicloLectivo,
              })
            ) {
              return true;
            }
            if (
              resultInterdiscipPlan === true ||
              resultArealdiscipPlan === true
            ) {
              return false;
            } else {
              return !(
                row[pkKeyUser].cuentas.idUsuario === userInfo.id &&
                [
                  PLANIFICACION_ESTADOS.REQUIERE_CAMBIOS,
                  PLANIFICACION_ESTADOS.EN_PROCESO,
                  PLANIFICACION_ESTADOS.APROBADO,
                ].includes(estado.idPlanificacionEstados)
              );
            }
          },
        },
        {
          id: BLOCKER_ID.ASESORE_APPROVE_PLANIFICATIONS,
          label: 'Asesoramiento',
          onClick: (data: any) => history.push(`${feedback}/${data[pkKey]}`),
          hide: (row: any) => {
            const estado = getEstado(row);
            if (
              selectedRole.rol === ROL.DOCENTE.toString() &&
              PLANIFICACION_ESTADOS.REQUIERE_CAMBIOS ===
                estado.idPlanificacionEstados
            ) {
              return false;
            }
            if (
              (selectedRole.rol === ROL.ASESOR_PEDAGOGICO.toString() ||
                selectedRole.rol === ROL.COORDINADOR_DE_AREA.toString()) &&
              PLANIFICACION_ESTADOS.ENVIADO === estado.idPlanificacionEstados
            ) {
              return false;
            }
            return true;
          },
        },
        {
          label: 'Vista previa',
          onClick: (data: any) => history.push(`${preview}/${data[pkKey]}`),
        },
        {
          label: 'Imprimir',
          onClick: (data: any) => {
            dispatch.printPlanificacion({
              idPlanificacion: data[pkKey],
              type: pkKey,
              download: false,
            });
          },
          hide: (row: any) => {
            const cicloLectivo =
              row[
                row.arealPlan
                  ? 'arealPlan'
                  : row.interdiscipPlan
                  ? 'interdiscipPlan'
                  : row.discipOrientPlan
                  ? 'discipOrientPlan'
                  : 'simpDiscipPlan'
              ].cicloLectivo.anio;

            return !isHistoricSection({
              cicloLectivo,
              currentCicloLectivo,
            });
          },
        },
        {
          label: 'Descargar',
          onClick: async (data: any) => {
            dispatch.printPlanificacion({
              idPlanificacion: data[pkKey],
              type: pkKey,
              download: true,
            });
          },
          hide: (row: any) => {
            const cicloLectivo =
              row[
                row.arealPlan
                  ? 'arealPlan'
                  : row.interdiscipPlan
                  ? 'interdiscipPlan'
                  : row.discipOrientPlan
                  ? 'discipOrientPlan'
                  : 'simpDiscipPlan'
              ].cicloLectivo.anio;

            return !isHistoricSection({
              cicloLectivo,
              currentCicloLectivo,
            });
          },
        },
        {
          label: 'Borrar',
          onClick: (data: any) => helper.delete(data),
          hide: (row: any) => {
            const estado = getEstado(row);

            const cicloLectivo =
              row[
                row.arealPlan
                  ? 'arealPlan'
                  : row.interdiscipPlan
                  ? 'interdiscipPlan'
                  : row.discipOrientPlan
                  ? 'discipOrientPlan'
                  : 'simpDiscipPlan'
              ].cicloLectivo.anio;

            if (
              isHistoricSection({
                cicloLectivo,
                currentCicloLectivo,
              })
            ) {
              return true;
            }
            if (
              selectedRole.rol === ROL.DOCENTE.toString() &&
              PLANIFICACION_ESTADOS.EN_PROCESO ===
                estado.idPlanificacionEstados &&
              Object.keys(row || []).includes(pkKeyUser) &&
              row[pkKeyUser].cuentas.idUsuario === userInfo.id
            ) {
              return false;
            }

            return true;
          },
        },
      ],
      [
        history,
        edit,
        feedback,
        preview,
        selectedRole.rol,
        helper,
        userInfo.id,
        currentCicloLectivo,
        dispatch,
      ],
    );
  };
