import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class SecundarioCustomDAO extends LibbyFetchDAO {
  constructor() {
    super('secundario_custom', 'idCalificacion');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
