import React, { useState, useMemo } from 'react';
import { InfoTable, Loading } from 'src/commons/components';
import { makeStyles } from '@material-ui/core/styles';
import { Radio, Grid, Typography } from '@material-ui/core';
import { AnyObject, LibbyObject } from 'src/commons';
import { primary, grey } from 'src/theme/colors';
import { AddStudentForm } from 'src/screens/Private/Matriculation/Enrolled/components/EnrolledAddStudentModal';
import { useInscripcionAlumnoLibbyFetch } from 'src/app/business';

const useStyles = makeStyles(() => ({
  checkBox: {
    color: primary.lightBlue,
    paddingRight: '20px',
    width: '0px',
    height: '0px',
  },
  textContainer: {
    display: 'flex',
    flexDirection: 'column',
    height: 400,
    alignItems: 'center',
    justifyContent: 'center',
  },
  messageText: {
    fontSize: 16,
    color: grey.heading,
    marginBottom: 10,
  },
  linkText: {
    fontSize: 16,
    color: primary.lightBlue,
  },
}));

export const StudentSelectTable = ({
  handleSelectStudent,
  selectStudent,
  formValues,
  handleAddExternalAlumn,
  localizacionId,
}: LibbyObject) => {
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('alumno.idAlumno');

  const filterTableManagement = useMemo(
    () => ({
      0: [{ path: 'localizacion', value: localizacionId, method: 'notEquals' }],
      filter: [
        { path: formValues.path, value: formValues.params, method: 'includes' },
      ],
    }),
    [formValues, localizacionId],
  );

  const { data: studentRegistration = [], working = true } =
    useInscripcionAlumnoLibbyFetch({
      orderBy,
      direction,
      filter: filterTableManagement,
    });

  const classes = useStyles();

  const column = [
    {
      id: 'name',
      label: 'Nombre y Apellido',
      width: '25%',
      orderById: 'alumno.persona.nombre',
    },
    {
      id: 'document',
      label: 'Documento',
      width: '2%',
      orderById: 'alumno.persona.documento',
    },
    {
      id: 'establishment',
      label: 'Establecimiento de origen',
      width: '20%',
      orderById: 'localizacion.descripcion',
    },
    {
      id: 'anio',
      label: 'Año',
      width: '2%',
      orderById: 'anio.descripcionAnio',
    },
    {
      id: 'condition',
      label: 'Condición',
      width: '2%',
      orderById: 'condicion.descripcionCondicion',
    },
  ];

  const usePassRequestTableLogic = useMemo(() => {
    const rows = studentRegistration.map(
      (
        {
          idInscripcionAlumno,
          alumno: {
            idAlumno,
            persona: { documento, nombre, apellido },
          },
          localizacion: { descripcion },
          anio: { descripcionAnio },
          condicion: { descripcionCondicion },
        }: AnyObject,
        index: number,
      ) => ({
        id: idInscripcionAlumno,
        name: (
          <>
            <Radio
              className={classes.checkBox}
              color="default"
              name="radio-button-demo[]"
              value={selectStudent}
              checked={selectStudent?.alumno === idAlumno}
              onChange={() => handleSelectStudent(studentRegistration[index])}
            />
            {`${nombre} ${apellido}`}
          </>
        ),
        document: documento,
        establishment: descripcion,
        anio: descripcionAnio,
        condition: descripcionCondicion,
      }),
    );
    return {
      rows,
    };
  }, [studentRegistration, handleSelectStudent, selectStudent, classes]);

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  return working ? (
    <Loading />
  ) : (
    <>
      {usePassRequestTableLogic.rows.length ? (
        <InfoTable
          working={working}
          rows={usePassRequestTableLogic.rows}
          columns={column}
          orderBy={orderBy}
          direction={direction}
          onSortChange={handleRequestSort}
          rowIdKey="id"
        />
      ) : (
        <Grid>
          <Typography className={classes.messageText}>
            El alumno ingresado no se encuentra en la base de datos. Por favor,
            completar los siguientes campos para darlo de alta:
          </Typography>
          <AddStudentForm
            handleAddExternalAlumn={handleAddExternalAlumn}
            external
          />
        </Grid>
      )}
    </>
  );
};
