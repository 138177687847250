import React, {
  useCallback,
  useEffect,
  useRef,
  useState,
  useMemo,
} from 'react';
import { SnackbarKey, useSnackbar } from 'notistack';
import {
  ErrorInternetConnection,
  InternetConnectionToast,
  SuccessInternetConnection,
} from '../components/InternetConnectionMessage';
import { API_URL } from '../../config';
import { useInterval } from './useInterval';
import { uniqueId } from 'lodash';
import { AnyObject } from '../types';
import {
  getFromLocalStorage,
  removeToLocalStorage,
  setToLocalStorage,
} from '../../utils';
import { useRolesContext } from '../../context/RolesContext';

interface Props {
  hasError: boolean;
  seccionId: string;
  info?: AnyObject | AnyObject[];
}

type TypeMessage = 'success' | 'error';

const unsupportedUserAgentsPattern =
  /Windows.*Chrome|Windows.*Firefox|Linux.*Chrome/;
const inBrowser = typeof navigator !== 'undefined';
// TODO: solucionar el error de las keys del enqueueSnackbar

/**
 * Hook para mostrar informacion cuando no hay conexion a internet, a partir de los errores de libby de los endpoints
 * @param errors Array de string que contiene los errores cuando falla un endpoint
 * return @show metodo usado para mostrar el mensaje de "Sin internet" e iniciar el intervalo de chequeo
 * para saber si re restablece la conexion, mostrando el mensaje correspondiente
 */
export const useInternetConnectionCheck = ({
  hasError = false,
  seccionId,
  info,
}: Props) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [isConnected, setIsConnected] = useState(() => {
    return inBrowser && typeof navigator.onLine === 'boolean'
      ? navigator.onLine
      : true;
  });

  const [initCheck, setInitCheck] = useState(false);
  const snackbarRef = useRef<SnackbarKey>();

  useEffect(() => {
    const handleStatusChange = () => {
      setIsConnected(navigator.onLine);
    };

    // Listen to the online status
    window.addEventListener('online', handleStatusChange);

    // Listen to the offline status
    window.addEventListener('offline', handleStatusChange);

    return () => {
      window.removeEventListener('online', handleStatusChange);
      window.removeEventListener('offline', handleStatusChange);
    };
  }, [isConnected]);

  const {
    userInfo: { id },
    selectedRole: { nivel, localizacionId, rol },
  } = useRolesContext();

  const identifier = `backup-${id}-${rol}-${nivel}-${localizacionId}-${seccionId}`;

  const backupInfo = useCallback(() => {
    if (!info) return;
    setToLocalStorage(identifier, info);
  }, [info, identifier]);

  const restoreBackup = useCallback(() => {
    return getFromLocalStorage(identifier);
  }, [identifier]);

  const cleanBackup = useCallback(() => {
    removeToLocalStorage(identifier);
  }, [identifier]);

  const show = useCallback(
    (type: TypeMessage, id: string = uniqueId()) => {
      if (snackbarRef.current) {
        closeSnackbar(snackbarRef.current);
      }
      // Muestra el toast
      const _message = type === 'success' ? '' : 'Error de conexión';

      return enqueueSnackbar(_message, {
        key: type + id,
        variant: type,
        content: (key, message) => {
          return (
            <InternetConnectionToast
              message={message}
              key={key}
              severity={type}
            >
              {type === 'success' ? (
                <SuccessInternetConnection />
              ) : (
                <ErrorInternetConnection />
              )}
            </InternetConnectionToast>
          );
        },
        persist: true,
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    },
    [enqueueSnackbar, closeSnackbar],
  );

  const checkStatus = useCallback(async () => {
    try {
      const result = await fetch(API_URL + '/platform/status', {
        method: 'POST',
      });
      if (result.ok) {
        setInitCheck(false);

        snackbarRef.current = show('success');
      }
    } catch (error) {
      console.log('error', error);
    }
  }, [show]);

  useInterval(checkStatus, 30000, initCheck);

  const showMessage = useCallback(
    (type: TypeMessage) => {
      if (initCheck) return;
      if (type === 'error') {
        setInitCheck(true);
      }
      snackbarRef.current = show(type);
      backupInfo();
    },
    [initCheck, show, backupInfo],
  );

  useEffect(() => {
    if (initCheck) return;
    if (!hasError && isConnected) return;
    showMessage('error');
    backupInfo();
    setInitCheck(true);
  }, [
    initCheck,
    showMessage,
    closeSnackbar,
    backupInfo,
    isConnected,
    hasError,
  ]);

  return { show: showMessage, restoreBackup, cleanBackup };
};
