import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useRolesContext } from 'src/context/RolesContext';
import { SideMenu, ColorBar, Header } from '..';
import { MenuCollection } from '../../const/ui/menu';
import { useRouterContext } from '../../../lib/router';
import { useFillRoleUser } from '../../hooks/useFillRoleUser';
import { LibbyObject } from '../../types';
import { EfectivizacionProvider } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
import ColegioMetaProvider from 'src/platform/context/ColegioMetaProvider';

const useStyles = makeStyles(() => ({
  container: {
    width: '100%',
    height: '100%',
  },
  menuContainer: {
    width: '95%',
  },
  sideAndContent: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

interface MainLayoutProps {
  children: React.ReactNode;
  menu: MenuCollection;
  libby: LibbyObject;
}

const MainLayoutRaw: FunctionComponent<MainLayoutProps> = ({
  libby,
  children,
}) => {
  const classes = useStyles();
  const location = useLocation();
  const { metadata } = useRouterContext();
  const { data } = useFillRoleUser(libby);
  const { selectedRole } = useRolesContext();

  // TODO: fix header title aberration
  return (
    <ColegioMetaProvider>
      <EfectivizacionProvider>
        <div className={classes.container}>
          <ColorBar />
          <div className={classes.sideAndContent}>
            <SideMenu />
            <div className={classes.menuContainer}>
              <Header
                title={
                  metadata.title
                    ? metadata.title
                    : location.pathname.includes('matriculation')
                    ? 'Matriculación'
                    : 'Roles'
                }
                user={
                  !data || !data[0]
                    ? ''
                    : `${data[0].usuario.nombreUsuario} ${data[0].usuario.apellidoUsuario}`
                }
                backButton={metadata.showBackButton}
                school={selectedRole.localizacion}
              />
              <main>
                <div />
                {children}
              </main>
            </div>
          </div>
        </div>
      </EfectivizacionProvider>
    </ColegioMetaProvider>
  );
};

export const MainLayout = DatabaseConnector(MainLayoutRaw)();
