import React from 'react';
import { useMemo } from 'react';
import { OptionsModal } from 'src/commons';
import { usePpsDAO } from 'src/app/business';
import { useRolesContext } from 'src/context/RolesContext';
import { ROL } from 'src/commons/const/rol';
import { Alumno, CicloLectivo, EstadoAlumno, Seccion } from 'src/app/models';

type rowType = {
  row: {
    idAlumnoMovimiento: string;
    updateAt: string;
    updatedBy: string;
    deletedAt: Date | null;
    alumno: Alumno;
    cicloLectivo: CicloLectivo;
    estadoAlumno: EstadoAlumno;
    seccion: Seccion;
    deletedBy: string | number | null;
  };
};

export const useTableAlumnosColumns = (
  handleChangeAlumno: (e: rowType, t: string) => void,
  pps: any,
  reFetch: () => void,
) => {
  const ppsDao = usePpsDAO();
  const { selectedRole } = useRolesContext();
  const columns = useMemo(() => {
    const openPps = async (idPPS: number) => {
      await ppsDao.save({ idPPS, abierto: true });
      reFetch();
    };

    const openPpsValidation = () => {
      return [ROL.DIRECTIVO, ROL.EQUIPO_CONDUCCION].includes(
        Number(selectedRole.rol),
      );
    };

    return [
      {
        id: 'nombre',
        label: 'Apellido y nombre',
        width: '10%',
        orderById: 'alumno.persona.apellido',
        render: (_: unknown, row: any) =>
          `${row?.alumno?.persona?.apellido}, ${row?.alumno?.persona?.nombre}`,
      },
      {
        id: 'documento',
        label: 'Documento',
        width: '10%',
        orderById: 'alumno.persona.documento',
        render: (_: unknown, row: any) => row?.alumno?.persona?.documento,
      },
      {
        id: 'estado',
        label: 'Estado',
        hideSortIcon: true,
        width: '5%',
        render: (_: unknown, row: any) => {
          const currentPps = pps.find(
            (element: any) =>
              element?.alumno?.idAlumno === row?.alumno?.idAlumno,
          );
          let status;

          if (currentPps === undefined) {
            status = 'No iniciado';
          } else if (currentPps?.abierto) {
            status = 'En proceso';
          } else if (!currentPps?.abierto) {
            status = 'Finalizado';
          }
          return <p>{status}</p>;
        },
      },
      {
        id: 'icon',
        label: 'Informe PPS',
        width: '1%',
        hideSortIcon: true,
        noSort: true,
        align: 'center',
        render: (_: unknown, row: any) => {
          const currentPps = pps.find(
            (element: any) =>
              element?.alumno?.idAlumno === row?.alumno?.idAlumno,
          );

          const idPPs =
            currentPps !== undefined ? currentPps?.idPPS : undefined;

          const options = [];

          if (idPPs === undefined) {
            options.push({
              label: 'Cargar',
              onClick: () => handleChangeAlumno(row, 'cargar'),
            });
          } else if (Boolean(idPPs) && currentPps.abierto) {
            options.push({
              label: 'Editar',
              onClick: () => handleChangeAlumno(row, 'editar'),
            });
          } else if (
            Boolean(idPPs) &&
            !currentPps?.abierto &&
            openPpsValidation()
          ) {
            options.push({
              label: 'Abrir informe',
              onClick: () => openPps(idPPs),
            });
            options.push({
              label: 'Visualizar',
              onClick: () => handleChangeAlumno(row, 'visualizar'),
            });
          } else if (
            Boolean(idPPs) &&
            !currentPps?.abierto &&
            !openPpsValidation()
          ) {
            options.push({
              label: 'Visualizar',
              onClick: () => handleChangeAlumno(row, 'visualizar'),
            });
          }

          return <OptionsModal options={options} />;
        },
      },
      {
        id: 'space',
        label: '',
        hideSortIcon: true,
        width: '15%',
        render: () => <></>,
      },
    ];
  }, [handleChangeAlumno, pps, ppsDao, reFetch, selectedRole.rol]);

  return columns;
};
