import { LibbyFetchDAO } from './LibbyFetchDAO';

export class EstadoAlumnoDAO extends LibbyFetchDAO {
  constructor() {
    super('estado_alumno', 'idEstadoAlumno');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
