import { useMemo } from 'react';
import { useGeneralContext } from 'src/context/GeneralContext';

export const useCalificacionesRecuperacionExcelData = (
  data: any,
  name: string,
  periodo: string,
  anioSelect: string,
  seccionSelect: string,
  espacioCurricular: string,
) => {
  const {
    generalState: { cicloLectivo },
  } = useGeneralContext();

  const excelData = useMemo(() => {
    let metadata: Array<string> | [] = [
      periodo,
      `Ciclo ${String(cicloLectivo)}`,
      seccionSelect,
      espacioCurricular,
    ];

    if (anioSelect) {
      // Se evita repetir el año para las secciones que ya incluyen el año en su descripción
      const seccionConcatAnio = seccionSelect.includes(String(anioSelect))
        ? seccionSelect
        : `${String(anioSelect)} ${seccionSelect}`;

      metadata[2] = seccionConcatAnio;
    }
    const columnHeaders = [
      'Alumno',
      'Calificación Diciembre',
      'Calificación Febrero-Marzo',
      'Calificación Definitiva',
    ];

    const rows = data.map((row: any) => ({
      Alumno: `${row?.apellido}, ${row.nombre}`,
      'Calificación Diciembre': `${row?.diciembre ?? ''}`,
      'Calificación Febrero-Marzo': `${row?.febreroMarzo ?? ''}`,
      'Calificación Definitiva': (() => {
        if (row?.diciembre === 'En Proceso' && row?.febreroMarzo) {
          return `${row.febreroMarzo}`;
        } else {
          return `${row?.diciembre ?? ''}`;
        }
      })(),
    }));

    return {
      body: {
        metadata,
        columns: columnHeaders,
        data: rows,
      },
      name,
    };
  }, [
    anioSelect,
    cicloLectivo,
    data,
    espacioCurricular,
    name,
    periodo,
    seccionSelect,
  ]);

  return { excelData };
};
