import { MenuItem } from '../../../../commons/const/ui/menu';
import { routes } from './routes';

export const comunicacionesMenu: MenuItem = {
  key: 'comunicaciones',
  basePath: '/comunicaciones',
  enabled: true,
  icon: 'List',
  text: 'Comunicaciones',
  children: routes,
};
