import React, { ReactNode, useMemo, useState } from 'react';
import cx from 'classnames';
import { makeStyles } from '@material-ui/core/styles';
import {
  MenuItem,
  FormControl,
  Select,
  Typography,
  SelectProps,
  FormHelperText,
  Tooltip,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import _ from 'lodash';
import typography from 'src/theme/typography';
import { grey } from 'src/theme/colors';
import { AnyObject } from '../../types';
import { primary } from '../../../theme/colors';
import { Loading } from '../Loading';
import { Grid } from '@material-ui/core';

export interface SimpleSelectProps {
  title?: string;
  content?: any;
  value: string | number | string[] | number[];
  name?: string;
  handleChange?: (
    value: React.ChangeEvent<{ value: string | number | AnyObject }>,
  ) => void;
  onChange?: (value: React.ChangeEvent<{ value: unknown }>) => void;
  valueKey?: any;
  labelKey?: string;
  placeholder?: string;
  customStyleTitle?: any;
  disableUnderline?: SelectProps['disableUnderline'];
  disabled?: any;
  variant?: SelectProps['variant'];
  error?: boolean;
  helperText?: string;
  loading?: boolean;
  onBlur?: <T>(event?: React.FocusEvent<T>) => void;
  onFocus?: <T>(event?: React.FocusEvent<T>) => void;
  multiple?: boolean;
  customClassNameFormControl?: string;
  [x: string]: any;
  requiredField?: boolean;
  selectStyles?: any;
  selectClassName?: string;
  placeholderStyle?: AnyObject;
  tooltipText?: string;
}

export const SimpleSelect = ({
  title,
  content,
  handleChange,
  onChange,
  value,
  name = '',
  valueKey = '',
  labelKey = '',
  labelAlternativeKey = null,
  placeholder,
  customStyleTitle,
  disabled,
  disableUnderline = false,
  variant = 'outlined',
  error,
  helperText,
  loading,
  onBlur,
  onFocus,
  multiple,
  customStyleContainer,
  customClassNameFormControl,
  requiredField = false,
  selectStyles,
  selectClassName,
  placeholderStyle,
  tooltipText,
}: SimpleSelectProps) => {
  const useStyles = makeStyles({
    formControl: {
      margin: 1,
      width: '100%',
      '& .MuiOutlinedInput-input': {
        padding: '7px 1em',
      },
      '& .MuiOutlinedInput-root': {
        border: 'none',
      },
    },
    title: {
      color: grey.medium,
      fontWeight: 'normal',
      fontSize: '14px',
      marginBottom: '10px',
      fontFamily: typography.fontFamily,
    },
    select: {
      fontFamily: typography.fontFamily,
      color: grey.textPrimary,
      fontSize: '13px',
      maxWidth: '100%',
      width: '100%',
      display: 'block',
      paddingRight: '10px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    placeHolder: {
      fontFamily: typography.fontFamily,
      color: grey.textPrimary,
      fontSize: '13px',
      maxWidth: '100%',
      overflow: 'hidden',
      display: 'block',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      ...placeholderStyle,
    },
    selectStyles,
  });
  const classes = useStyles();

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const renderValue = useMemo(() => {
    if (!multiple) {
      return undefined;
    }
    return (values: unknown) => {
      if (!values || (!(values as any[]).length && placeholder !== undefined))
        return placeholder;
      return (values as any[])
        .map((item: any) => {
          const option = content?.find(
            (con: any) => `${_.get(con, valueKey)}` === `${item}`,
          );
          return !option ? '-' : _.get(option, labelKey);
        })
        .join(', ') as ReactNode;
    };
  }, [content, labelKey, multiple, placeholder, valueKey]);

  const notMatchWithOptions = () => {
    // TODO: La funcion estaba devolviendo false cuando la propiedad que se pasaba por el valueKey contenia "."
    // if (valueKey.includes('.')) {
    //   return false;
    // }

    const notMatch = content?.every((option: any) => {
      return _.get(option, valueKey) !== value;
    });
    if (value === undefined || value === null || notMatch) {
      return true;
    }
    return false;
  };

  return (
    <Grid style={customStyleContainer}>
      {title && (
        <Typography className={classes.title} style={customStyleTitle}>
          {title?.charAt(0) === '¿'
            ? title?.charAt(0) +
              title?.charAt(1).toUpperCase() +
              title?.toLowerCase().slice(2)
            : title?.charAt(0).toUpperCase() + title?.toLowerCase().slice(1)}
          {requiredField && <span style={{ color: '#ff0000' }}> *</span>}
        </Typography>
      )}
      {/*
        TODO
        Fix this bug, an option could be using loading from top level
        MIESC-670
      */}
      {loading ? (
        <Loading />
      ) : (
        <FormControl
          variant={variant}
          className={cx(customClassNameFormControl, classes.formControl)}
          error={error}
        >
          <Tooltip title={tooltipText ? tooltipText : ''} placement="top">
            <Select
              name={name}
              value={notMatchWithOptions() ? '' : value}
              displayEmpty
              onChange={onChange || handleChange}
              disableUnderline={disableUnderline}
              disabled={disabled}
              IconComponent={ExpandMoreIcon}
              onBlur={onBlur}
              onFocus={onFocus}
              multiple={multiple}
              renderValue={renderValue}
              className={selectClassName}
            >
              {placeholder !== undefined && (
                <MenuItem
                  style={{ backgroundColor: primary.white, opacity: 1 }}
                  value=""
                >
                  <Typography className={classes.placeHolder}>
                    {placeholder?.charAt(0).toUpperCase() +
                      placeholder?.toLowerCase().slice(1)}
                  </Typography>
                </MenuItem>
              )}

              {content
                ?.filter((e: AnyObject) => e !== null)
                .map((item: AnyObject) => {
                  const theValue = _.get(item, valueKey);
                  return (
                    <MenuItem
                      key={theValue}
                      style={{
                        opacity: 1,
                        textDecoration: item.needToDisable
                          ? 'line-through'
                          : 'none',
                      }}
                      value={theValue}
                      disabled={item.needToDisable}
                    >
                      <Typography
                        className={cx(classes.select, classes.selectStyles)}
                      >
                        {!!item.icon && item.icon}
                        {_.get(
                          item,
                          item[labelAlternativeKey]
                            ? labelAlternativeKey
                            : labelKey,
                        )}
                      </Typography>
                    </MenuItem>
                  );
                })}
            </Select>
          </Tooltip>
          {!!helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
      )}
    </Grid>
  );
};
