export const useFilterPassSectionInicialLogic = (
  options: any,
  statusPass: any,
  currentCicloLectivo: any,
  numeroAnio: any,
  descripcionAnioSection: any,
) => {
  let filteredOptionsInicial: any = [];
  const siguienteCicloLectivo: number = currentCicloLectivo + 1;
  const siguienteNumeroAnio: number = numeroAnio + 1;

  // promociona
  if (statusPass === 1) {
    filteredOptionsInicial = options.filter((option: any) => {
      const siguienteCicloLectivoComparasion =
        option?.cicloLectivo?.anio === siguienteCicloLectivo;

      // si es anio simple, pasas al anio +1, simples y combinados, y al +2 combinados
      const siguienteAnioSimple =
        descripcionAnioSection.indexOf('y') === -1 &&
        (option?.anio?.numeroAnio === siguienteNumeroAnio ||
          (option?.anio?.numeroAnio === numeroAnio + 2 &&
            option?.anio?.descripcionAnio.indexOf('y') !== -1));

      // si es anio combinado pasas al mismo anio, al anio +1 simples y combinados y al +2 simples y combinados
      const siguienteAnioCombinado =
        descripcionAnioSection.indexOf('y') !== -1 &&
        (option?.anio?.numeroAnio === siguienteNumeroAnio ||
          (option?.anio?.numeroAnio === numeroAnio + 2 &&
            option?.anio?.descripcionAnio.indexOf('y') !== -1) ||
          option?.anio?.numeroAnio === numeroAnio);

      return (
        (option?.anio?.descripcionAnio === 'Multiedad' &&
          siguienteCicloLectivoComparasion) ||
        (siguienteAnioSimple && siguienteCicloLectivoComparasion) ||
        (siguienteAnioCombinado && siguienteCicloLectivoComparasion)
      );
    });
    // permanece
  } else if (statusPass === 2) {
    filteredOptionsInicial = options.filter((option: any) => {
      const siguienteCicloLectivoComparasion =
        option?.cicloLectivo?.anio === siguienteCicloLectivo;

      // si es anio simple, permaneces en el mismo numero de anio y en los +1 combinados
      const mismoAnioSimple =
        descripcionAnioSection.indexOf('y') === -1 &&
        (option?.anio?.numeroAnio === numeroAnio ||
          (option?.anio?.numeroAnio === numeroAnio + 1 &&
            option?.anio?.descripcionAnio.indexOf('y') !== -1));

      // si es anio combinado, permaneces en el mismo numero de anio, en el anio + 1 combinado y en el anio -1
      const mismoAnioCombinado =
        descripcionAnioSection.indexOf('y') !== -1 &&
        (option?.anio?.numeroAnio === numeroAnio ||
          (option?.anio?.numeroAnio === numeroAnio + 1 &&
            option?.anio?.descripcionAnio.indexOf('y') !== -1) ||
          option?.anio?.numeroAnio === numeroAnio - 1);

      return (
        (option?.anio?.descripcionAnio === 'Multiedad' &&
          siguienteCicloLectivoComparasion) ||
        (mismoAnioSimple && siguienteCicloLectivoComparasion) ||
        (mismoAnioCombinado && siguienteCicloLectivoComparasion)
      );
    });
  }

  return filteredOptionsInicial;
};
