import { LibbyFetchDAO } from './LibbyFetchDAO';

export class DeudaAcademicaDAO extends LibbyFetchDAO {
  constructor() {
    super('deuda_academica', 'idDeudaAcademica');
  }

  getByAlumno(alumnoId: number) {
    return this.query().equals('alumno', alumnoId).limit(0, 10000).run();
  }
}
