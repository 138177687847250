import moment from 'moment';
import { PeriodoNuevo } from '../../../../../../../app/models';

export const getPeriodoByDate = (periodos: PeriodoNuevo[], date: string) => {
  const _periodo = periodos.find((periodo) =>
    moment(date).isBetween(
      moment(periodo.fechaInicio, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      moment(periodo.fechaFin, 'YYYY-MM-DD').format('YYYY-MM-DD'),
      undefined,
      '[]',
    ),
  );
  return _periodo;
};
