import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from 'src/lib/libby';
import { LocalizacionDAO } from 'src/platform/libby/dao';
import { Localizacion } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'localizacion';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idLocalizacion',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idLocalizacion',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Localizacion
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Localizacion,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Localizacion.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Localizacion, LocalizacionDAO>(DAO_NAME, optionsToUse);

// hooks
export const useLocalizacionFetchById = fetchById;
export const useLocalizacionLibbyCall = libbyCall;
export const useLocalizacionDAO = libbyDAO;
export const useLocalizacionLibbyFetch = libbyFetch;

// components/hoc
export const LocalizacionAutocomplete = entityAutocomplete;
export const LocalizacionCall = renderPropCall;
export const LocalizacionById = renderPropFetchById;
export const LocalizacionSelect = simpleSelect;
export const LocalizacionAccordionSelector = accordionSelector;
export const LocalizacionSelectedChips = selectedChips;

// context
// ByIdContext
export const useLocalizacionByIdContext = useByIdContext;
export const LocalizacionByIdProvider = ByIdProvider;
export const LocalizacionByIdContext = ByIdContext;
// GeneralContext
export const useLocalizacionContext = useGeneralContext;
export const LocalizacionProvider = GeneralProvider;
export const LocalizacionContext = GeneralContext;
