import { Checkbox, Grid, IconButton, Tooltip } from '@material-ui/core';
import React from 'react';
import { useMemo } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { AlumnoMovimiento } from 'src/app/models';
import { InfoTableColumn } from '../../../../commons';
import { Orientacion } from 'src/app/models/orientaciones';
import { useRolesContext } from 'src/context/RolesContext';
import { isSameLocalization } from '../functions/helpers';

export const useAccionesDoubleTableColumns = (
  checkStudent: AlumnoMovimiento[],
  setCheckStudent: React.Dispatch<React.SetStateAction<AlumnoMovimiento[]>>,
  restoreFlag: boolean,
  setRestoreFlag: React.Dispatch<React.SetStateAction<boolean>>,
  alumnosMovimiento: AlumnoMovimiento[],
  shouldSeeCheckboxes: boolean,
  cupo: number,
  accionSeleccionadaRow: any,
  unsavedCheckedStudents: any,
  inscriptosTotalesOtrasEscuelas: any,
) => {
  const {
    selectedRole: { localizacionId },
  } = useRolesContext();

  const checkedAll =
    Number(cupo) ===
    Number(inscriptosTotalesOtrasEscuelas?.length) +
      Number(checkStudent?.length);
  const columnsDoubleTable = useMemo<InfoTableColumn<any>[]>(
    () => [
      {
        id: 'icon',
        width: '1%',
        hideColum: !shouldSeeCheckboxes,
        label: (
          <Checkbox
            checked={checkedAll}
            onChange={(e) => {
              const amovNotChecked = alumnosMovimiento.filter((am) => {
                const orientacionValidacion =
                  accionSeleccionadaRow?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.accionOrientaciones?.filter(
                    (accionOrientacion: any) => {
                      const hasSomeOrientacionInCommon =
                        am?.seccion?.planEstudioNivel?.planEstudio?.orientaciones?.some(
                          (planEstudioOrientacion: any) => {
                            return (
                              Number(
                                planEstudioOrientacion?.orientacion
                                  ?.idOrientacion,
                              ) ===
                              Number(
                                accionOrientacion?.orientacion?.idOrientacion,
                              )
                            );
                          },
                        );
                      return hasSomeOrientacionInCommon;
                    },
                  );
                const isSameLocalizacion = isSameLocalization.call(
                  am,
                  localizacionId,
                );
                let shouldBeDisabled =
                  !Boolean(orientacionValidacion?.length) ||
                  !isSameLocalizacion;

                return (
                  !checkStudent.some(
                    (amChecked) =>
                      amChecked.idAlumnoMovimiento === am.idAlumnoMovimiento,
                  ) && !shouldBeDisabled
                );
              });
              return checkedAll
                ? setCheckStudent((checked) =>
                    checked.filter(
                      (am) => !isSameLocalization.call(am, localizacionId),
                    ),
                  )
                : setCheckStudent((checked) => [
                    ...checked,
                    ...amovNotChecked.slice(
                      0,
                      Number(cupo) -
                        (Number(inscriptosTotalesOtrasEscuelas?.length) +
                          Number(checkStudent?.length)),
                    ),
                  ]);
            }}
            inputProps={{
              'aria-labelledby': 'transfer-list-item-all-label',
            }}
            color="primary"
          />
        ),
        hideSortIcon: true,
        noSort: true,
        align: 'left',
        render: (value: string, row: AlumnoMovimiento) => {
          const orientacionValidacion =
            accionSeleccionadaRow?.orientaciones?.filter(
              (orientacion: Orientacion) => {
                const hasSomeOrientacionInCommon =
                  row?.seccion?.planEstudioNivel?.planEstudio?.orientaciones?.some(
                    (planEstudioOrientacion) => {
                      return (
                        Number(
                          planEstudioOrientacion?.orientacion?.idOrientacion,
                        ) === Number(orientacion?.idOrientacion)
                      );
                    },
                  );
                return hasSomeOrientacionInCommon;
              },
            );
          const tooltipTitles = [];

          const isDifferentOrientacion = orientacionValidacion?.length === 0;

          if (isDifferentOrientacion) {
            tooltipTitles.push(
              'No es posible seleccionar este alumno con las orientaciones que posee la oferta.',
            );
          }

          let shouldBeDisabled = isDifferentOrientacion;
          const isStudentChecked = checkStudent.some(
            (student) => student.idAlumnoMovimiento === row.idAlumnoMovimiento,
          );

          if (shouldBeDisabled) {
            return (
              <>
                <Tooltip title={tooltipTitles.join('. ')}>
                  <span>
                    <Checkbox
                      name={row.idAlumnoMovimiento}
                      checked={isStudentChecked}
                      disabled={
                        (!checkStudent.some(
                          (am) =>
                            am.idAlumnoMovimiento === row.idAlumnoMovimiento,
                        ) &&
                          Number(cupo) ===
                            Number(inscriptosTotalesOtrasEscuelas?.length) +
                              Number(checkStudent?.length)) ||
                        shouldBeDisabled
                      }
                      onChange={() => {}}
                      inputProps={{
                        'aria-labelledby': 'transfer-list-item-all-label',
                      }}
                      color="primary"
                    />
                  </span>
                </Tooltip>
              </>
            );
          } else {
            return (
              <Checkbox
                name={row.idAlumnoMovimiento}
                checked={isStudentChecked}
                disabled={
                  !checkStudent.some(
                    (am) => am.idAlumnoMovimiento === row.idAlumnoMovimiento,
                  ) &&
                  Number(cupo) ===
                    Number(inscriptosTotalesOtrasEscuelas?.length) +
                      Number(checkStudent?.length)
                }
                onChange={() => {
                  if (
                    Number(cupo) ===
                      Number(inscriptosTotalesOtrasEscuelas?.length) +
                        Number(checkStudent?.length) &&
                    !checkStudent.some(
                      (am) => am.idAlumnoMovimiento === row.idAlumnoMovimiento,
                    )
                  )
                    return;
                  if (!checkStudent.length) setRestoreFlag(!restoreFlag);
                  if (checkedAll) {
                    return setCheckStudent(
                      checkStudent.filter(
                        (idStudent: any) =>
                          row.idAlumnoMovimiento !==
                          idStudent.idAlumnoMovimiento,
                      ),
                    );
                  }
                  return isStudentChecked
                    ? setCheckStudent(
                        checkStudent.filter(
                          (idStudent: any) =>
                            row.idAlumnoMovimiento !==
                            idStudent.idAlumnoMovimiento,
                        ),
                      )
                    : setCheckStudent([...checkStudent, row]);
                }}
                inputProps={{
                  'aria-labelledby': 'transfer-list-item-all-label',
                }}
                color="primary"
              />
            );
          }
        },
      },
      {
        id: 'nombre',
        label: 'Apellido y nombre',
        render: (value: any, row: any) =>
          `${row?.alumno?.persona?.apellido}, ${row?.alumno?.persona?.nombre}`,
        orderById: 'alumno.persona.nombre',
      },
      {
        id: 'documento',
        label: 'Documento',
        render: (value: any, row: any) => row?.alumno?.persona?.documento,
        orderById: 'alumno.persona.documento',
      },
      {
        id: 'seccion',
        label: 'Sección',
        render: (value: any, row: any) => row?.seccion?.nombreSeccion,
        orderById: 'seccion.nombreSeccion',
      },
      {
        id: 'deleteicon',
        label: '',
        hideSortIcon: true,
        noSort: true,
        width: '5%',
        hideColumn: shouldSeeCheckboxes,
        render: (_: unknown, row: AlumnoMovimiento) => {
          const isStudentChecked =
            checkedAll ||
            checkStudent.some(
              (student) =>
                student.idAlumnoMovimiento === row.idAlumnoMovimiento,
            );

          return (
            <Grid item>
              <IconButton
                disabled={isSameLocalization.call(row, localizacionId)}
              >
                <DeleteIcon
                  color="primary"
                  onClick={() => {
                    if (!checkStudent.length) setRestoreFlag(!restoreFlag);
                    if (checkedAll) {
                      return setCheckStudent(
                        checkStudent.filter(
                          (student) =>
                            row.idAlumnoMovimiento !==
                            student.idAlumnoMovimiento,
                        ),
                      );
                    }
                    return isStudentChecked
                      ? setCheckStudent(
                          checkStudent.filter(
                            (student) =>
                              row.idAlumnoMovimiento !==
                              student.idAlumnoMovimiento,
                          ),
                        )
                      : setCheckStudent([...checkStudent, row]);
                  }}
                />
              </IconButton>
            </Grid>
          );
        },
        orderById: '',
      },
    ],
    [
      shouldSeeCheckboxes,
      checkedAll,
      alumnosMovimiento,
      setCheckStudent,
      accionSeleccionadaRow?.ofertaGrupo?.ofertaTurno?.oferta?.accion
        ?.accionOrientaciones,
      accionSeleccionadaRow?.orientaciones,
      checkStudent,
      cupo,
      inscriptosTotalesOtrasEscuelas?.length,
      setRestoreFlag,
      restoreFlag,
      localizacionId,
    ],
  );

  return columnsDoubleTable;
};
