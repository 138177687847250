import React from 'react';
import { TableColumn } from '../../TableColumn';
import { FILTERS } from '../../table_filters';
import {
  ProyeccionArticulanReport,
  ProyeccionCantidadPromocionanReport,
  ProyeccionListadoPromocionanReport,
} from '../../types';
import { TooltipColumn } from '../../components/TooltipColumn';
import { Typography } from '@material-ui/core';

// Cantidad y porcentajes de estudiantes que pasan de año o promocionan y de aquellos que permanecen
export const PROYECCION_CANT_ESTU_PROMOCIONAN_COLUMNS = [
  new TableColumn<ProyeccionCantidadPromocionanReport>('cue_anexo'),
  new TableColumn<ProyeccionCantidadPromocionanReport, React.ReactNode>(
    'establecimiento_origen',
    undefined,
    (value, row) => <TooltipColumn title={value} />,
  ),
  new TableColumn<ProyeccionCantidadPromocionanReport>('distrito_escolar'),
  new TableColumn<ProyeccionCantidadPromocionanReport>('seccion'),
  new TableColumn<ProyeccionCantidadPromocionanReport>('cant_promociona'),
  new TableColumn<ProyeccionCantidadPromocionanReport>('cant_permanece'),
  new TableColumn<ProyeccionCantidadPromocionanReport>('cant_egresa'),
  new TableColumn<ProyeccionCantidadPromocionanReport, React.ReactNode>(
    'porc_promociona',
    undefined,
    (value, row) => (
      <Typography>
        {(Number(row.cant_promociona) / Number(row.total)) * 100}%
      </Typography>
    ),
  ),
  new TableColumn<ProyeccionCantidadPromocionanReport, React.ReactNode>(
    'porc_permanece',
    undefined,
    (value, row) => (
      <Typography>
        {(Number(row.cant_permanece) / Number(row.total)) * 100}%
      </Typography>
    ),
  ),
  new TableColumn<ProyeccionCantidadPromocionanReport, React.ReactNode>(
    'porc_egresa',
    undefined,
    (value, row) => (
      <Typography>
        {(Number(row.cant_egresa) / Number(row.total)) * 100}%
      </Typography>
    ),
  ),
];

export const PROYECCION_CANT_ESTU_PROMOCIONAN_FILTERS = [
  FILTERS.dependencia_funcional,
  FILTERS.distrito_escolar,
  FILTERS.localizacion,
  FILTERS.seccion,
  FILTERS.anio,
  FILTERS.turno,
  FILTERS.edo_pase_anio,
];

// Listado de estudiantes que pasan de año o promocionan y de aquellos que permanecen
export const PROYECCION_LISTADO_PROMOCIONAN_COLUMNS = [
  new TableColumn<ProyeccionListadoPromocionanReport>('cue_anexo'),
  new TableColumn<ProyeccionCantidadPromocionanReport, React.ReactNode>(
    'establecimiento_origen',
    undefined,
    (value, row) => <TooltipColumn title={value} />,
  ),
  new TableColumn<ProyeccionCantidadPromocionanReport, React.ReactNode>(
    'establecimiento_destino',
    undefined,
    (value, row) => <TooltipColumn title={value} />,
  ),
  new TableColumn<ProyeccionListadoPromocionanReport>(
    'distrito_escolar_origen',
  ),
  new TableColumn<ProyeccionListadoPromocionanReport>(
    'distrito_escolar_destino',
  ),
  new TableColumn<ProyeccionListadoPromocionanReport>('apellido'),
  new TableColumn<ProyeccionCantidadPromocionanReport, React.ReactNode>(
    'nombre',
    undefined,
    (value, row) => <TooltipColumn title={value} />,
  ),
  new TableColumn<ProyeccionListadoPromocionanReport>('tipo_documento'),
  new TableColumn<ProyeccionListadoPromocionanReport>('documento'),
  new TableColumn<ProyeccionListadoPromocionanReport>('seccion_origen'),
  new TableColumn<ProyeccionListadoPromocionanReport>(
    'estado_pase_año',
  ).setMinWidth('100px'),
  new TableColumn<ProyeccionListadoPromocionanReport>('seccion_destino'),
];

export const PROYECCION_LISTADO_PROMOCIONAN_FILTERS = [
  FILTERS.dependencia_funcional,
  FILTERS.distrito_escolar,
  FILTERS.localizacion,
  FILTERS.seccion,
  FILTERS.anio,
  FILTERS.turno,
  FILTERS.edo_pase_anio,
  FILTERS.dni,
];

// Listado de Estudiantes que articulan en el nivel inicial
export const PROYECCION_LISTADO_ARTICULAN_INICAL_COLUMNS = [
  new TableColumn<ProyeccionArticulanReport>('cueanexodestino', 'CUE'),
  new TableColumn<ProyeccionCantidadPromocionanReport, React.ReactNode>(
    'establecimientodestino',
    'Establecimiento Destino',
    (value, row) => <TooltipColumn title={value} />,
  ),
  new TableColumn<ProyeccionArticulanReport>(
    'distritoescolardestino',
    'D.E Destino',
  ),
  new TableColumn<ProyeccionArticulanReport>('apellido'),
  new TableColumn<ProyeccionCantidadPromocionanReport, React.ReactNode>(
    'nombre',
    undefined,
    (value, row) => <TooltipColumn title={value} />,
  ),
  new TableColumn<ProyeccionArticulanReport>('tipodocumento'),
  new TableColumn<ProyeccionArticulanReport>('documento'),
  new TableColumn<ProyeccionArticulanReport>(
    'estadopaseaño',
    'Estado Articula',
  ),
  new TableColumn<ProyeccionArticulanReport>(
    'secciondestino',
    'Sección destino',
  ),
];

export const PROYECCION_LISTADO_ARTICULAN_INICAL_FILTERS = [
  FILTERS.dependencia_funcional,
  FILTERS.distrito_escolar,
  FILTERS.localizacion,
  FILTERS.seccion,
  FILTERS.anio,
  FILTERS.dni,
];
