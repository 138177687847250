import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Pedagogia } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'pedagogia';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPedagogia',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPedagogia',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Pedagogia
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Pedagogia,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Pedagogia.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Pedagogia>(DAO_NAME, optionsToUse);

// hooks
export const usePedagogiaFetchById = fetchById;
export const usePedagogiaLibbyCall = libbyCall;
export const usePedagogiaDAO = libbyDAO;
export const usePedagogiaLibbyFetch = libbyFetch;

// components/hoc
export const PedagogiaAutocomplete = entityAutocomplete;
export const PedagogiaCall = renderPropCall;
export const PedagogiaById = renderPropFetchById;
export const PedagogiaSelect = simpleSelect;
export const PedagogiaAccordionSelector = accordionSelector;
export const PedagogiaSelectedChips = selectedChips;

// context
// ByIdContext
export const usePedagogiaByIdContext = useByIdContext;
export const PedagogiaByIdProvider = ByIdProvider;
export const PedagogiaByIdContext = ByIdContext;
// GeneralContext
export const usePedagogiaContext = useGeneralContext;
export const PedagogiaProvider = GeneralProvider;
export const PedagogiaContext = GeneralContext;
