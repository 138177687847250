import { Template } from '../../../../../../../lib/templates';

export const ObservacionesBoletinTemplate: Template = {
  name: 'Observaciones Boletin Template',
  template: [
    {
      name: 'observaciones',
      type: { name: 'string' },
    },
    {
      name: 'idPeriodoNuevo',
      type: {
        name: 'number',
      },
    },
    {
      name: 'alumnoId',
      type: { name: 'string' },
    },
  ],
};
