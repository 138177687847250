import { MenuItem } from '../../../../commons/const/ui/menu';
import { routes } from './routes';

export const repositorioABMMenu: MenuItem = {
  key: 'repositorioABM',
  basePath: '/repositorioabm',
  enabled: true,
  icon: 'List',
  text: 'Repositorio ABM',
  children: routes,
};
