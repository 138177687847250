import { Grid, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useRouteScreenTitle } from 'src/commons';
import { DASHBOARD_URL } from 'src/config';
import { useRolesContext } from 'src/context/RolesContext';
import { TokenManager } from 'src/platform/libby/TokenManager';

const Dashboard = () => {
  const {
    selectedRole: { idUsuarioRol, rol, localizacionId, nivel },
  } = useRolesContext();
  useRouteScreenTitle('Tablero');
  const [token, setToken] = useState<string | null | boolean>('');

  useEffect(() => {
    const manager = new TokenManager();
    const getToken = async () => {
      const token = await manager.retrieve();
      setToken(token);
    };

    getToken();
  }, []);

  return Boolean(idUsuarioRol) && Boolean(token) ? (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <iframe
          src={`${DASHBOARD_URL}/auth?accessToken=${token}&groupSelectedId=${idUsuarioRol}&idRolEstablecimiento=${idUsuarioRol}&idRolUsuario=${rol}&idLocalizacion=${localizacionId}&idNivel=${nivel}`}
          frameborder="0"
          title="tablero"
          width={window.innerWidth - 100}
          height={window.innerHeight - 50}
        ></iframe>
      </Grid>
    </Grid>
  ) : (
    <Typography>Debe ingresar a un colegio para ver el Dashboard</Typography>
  );
};

export default Dashboard;
