import { makeStyles } from '@material-ui/core';

export const useSelectedDayStyles = makeStyles({
  rowLock: {
    '&:last-child > .selected:after,.selected:before': {
      borderTopRightRadius: 12,
      borderBottom: '3px solid #BEC0C4',
    },
  },
  selected: {
    position: 'relative',
    zIndex: 0,
    '&:after,:before': {
      content: "''",
      position: 'absolute',
      top: 0,
      right: -2,
      bottom: 0,
      left: -1,
      pointerEvents: 'none',
    },
    '&:after': {
      zIndex: -1,
      mixBlendMode: 'screen',
      borderLeft: '3px solid #BEC0C4',
      borderRight: '3px solid #BEC0C4',
    },
    '&:before': {
      zIndex: -2,
      background: '#539bfc',
      boxShadow: '0px 4px 7px rgba(0, 0, 0, 0.2)',
    },
  },
});
