import { Box, IconButton, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import React from 'react';
import { PermissionBlocker } from 'src/lib/permission';
import { BLOCKER_ID } from 'src/platform/permission/const';
import { verifyIsIncommingYear } from '../../../utils/verifyIsIncommingYear';
import { CicloLectivo, MatriculacionSeccion } from 'src/app/models';
import { useRolesContext } from 'src/context/RolesContext';
import { NIVEL } from 'src/commons';
import { isNextCicloLectivo } from '../../../utils/isNextCicloLectivo';
import { matriculationStyles } from '../styles';
import { grey, primary } from 'src/theme/colors';
import { isHistoricSection } from '../../../utils/isHistoricSection';
import moment from 'moment';

interface SeccionActionsProps {
  seccion: MatriculacionSeccion;
  isEnabled: boolean;
  currentCicloLectivo: CicloLectivo;
  handleView: React.MouseEventHandler<HTMLButtonElement>;
  handleEdit: React.MouseEventHandler<HTMLButtonElement>;
  handleDelete: React.MouseEventHandler<HTMLButtonElement>;
}

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  circleCapacity: { fontSize: '10px', color: primary.warning },
  iconColor: { color: primary.paleBlueStrong },
  iconDisabled: { color: primary.disabled },
};

export const SeccionActions = ({
  seccion,
  isEnabled,
  currentCicloLectivo,
  handleView,
  handleEdit,
  handleDelete,
}: SeccionActionsProps) => {
  const {
    selectedRole: { nivel },
  } = useRolesContext();
  const classes = matriculationStyles();

  const buttonValidation =
    (verifyIsIncommingYear(
      seccion.cicloLectivo.anio,
      currentCicloLectivo.anio,
    ) &&
      ![NIVEL.INICIAL].includes(Number(nivel))) ||
    (!(
      (
        seccion.hasProyectados &&
        isNextCicloLectivo(seccion.cicloLectivo.anio, currentCicloLectivo.anio)
      )
      //  Number(cicloLectivo.anio) ===
      //  Number(proyeccionEstado[0]?.cicloLectivo.anio + 1)
    ) &&
      verifyIsIncommingYear(
        seccion.cicloLectivo.anio,
        currentCicloLectivo.anio,
      ));
  //  Number(cicloLectivo.anio) !==
  //  Number(proyeccionEstado[0]?.cicloLectivo.anio)

  const isHistoric = isHistoricSection({
    currentCicloLectivo: currentCicloLectivo.anio,
    cicloLectivo: seccion.cicloLectivo.anio,
  });

  /* la validacion ahora seria, si la seccion tiene alumnos o si la seccion pertenece a alguna proyeccion y es del ciclo lectivo del anio siguiente, no deberia poder borrarse*/
  const canNotRemoveSeccion =
    (!(
      (seccion.matCount || 0) === 0 &&
      !verifyIsIncommingYear(
        seccion.cicloLectivo?.anio,
        currentCicloLectivo.anio,
      )
    ) &&
      (!!seccion.matCount || !buttonValidation)) ||
    !isEnabled ||
    (seccion.hasProyecciones &&
      seccion.cicloLectivo.anio === Number(moment().format('YYYY')) + 1) ||
    isHistoric;

  const tooltipText = buttonValidation ? 'Sin proyeccion' : '';
  return (
    <Box display="flex" width="92%">
      <Tooltip title={tooltipText} arrow>
        <Box p={1}>
          <IconButton
            className={classes.iconButton}
            disabled={buttonValidation}
            onClick={handleView}
          >
            <VisibilityIcon
              style={
                buttonValidation
                  ? customStyles.iconDisabled
                  : customStyles.iconColor
              }
            />
          </IconButton>
        </Box>
      </Tooltip>
      <PermissionBlocker id={BLOCKER_ID.EDIT_SECCION}>
        <Box p={1}>
          <Tooltip
            disableTouchListener={isEnabled || !isHistoric}
            disableFocusListener={isEnabled || !isHistoric}
            disableHoverListener={isEnabled || !isHistoric}
            title={
              isHistoric
                ? 'No se pueden editar secciones historicas'
                : 'No es posible editar una seccion despues del 1/10/2021 con su rol actual.'
            }
            arrow
          >
            <>
              <IconButton className={classes.iconButton} onClick={handleEdit}>
                <EditIcon
                  style={
                    !isEnabled || isHistoric
                      ? customStyles.iconDisabled
                      : customStyles.iconColor
                  }
                />
              </IconButton>
            </>
          </Tooltip>
        </Box>
      </PermissionBlocker>
      <PermissionBlocker id={BLOCKER_ID.DELETE_SECCIONS}>
        <Box p={1}>
          <Tooltip
            disableTouchListener={!canNotRemoveSeccion}
            disableFocusListener={!canNotRemoveSeccion}
            disableHoverListener={!canNotRemoveSeccion}
            title={
              isHistoric
                ? 'No se pueden eliminar secciones historicas'
                : 'No se puede eliminar ésta sección/ciclo mientras contenga estudiantes o existan proyecciones'
            }
            arrow
          >
            <>
              <IconButton
                className={classes.iconButton}
                onClick={handleDelete}
                disabled={canNotRemoveSeccion}
                style={
                  canNotRemoveSeccion
                    ? customStyles.iconDisabled
                    : customStyles.iconColor
                }
              >
                <DeleteIcon
                  style={
                    canNotRemoveSeccion
                      ? customStyles.iconDisabled
                      : customStyles.iconColor
                  }
                />
              </IconButton>
            </>
          </Tooltip>
        </Box>
      </PermissionBlocker>
    </Box>
  );
};
