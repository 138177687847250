import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Orientacion } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'orientacion';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idOrientacion',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idOrientacion',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Orientacion
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Orientacion,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Orientacion.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Orientacion>(DAO_NAME, optionsToUse);

// hooks
export const useOrientacionFetchById = fetchById;
export const useOrientacionLibbyCall = libbyCall;
export const useOrientacionDAO = libbyDAO;
export const useOrientacionLibbyFetch = libbyFetch;

// components/hoc
export const OrientacionAutocomplete = entityAutocomplete;
export const OrientacionCall = renderPropCall;
export const OrientacionById = renderPropFetchById;
export const OrientacionSelect = simpleSelect;
export const OrientacionAccordionSelector = accordionSelector;
export const OrientacionSelectedChips = selectedChips;

// context
// ByIdContext
export const useOrientacionByIdContext = useByIdContext;
export const OrientacionByIdProvider = ByIdProvider;
export const OrientacionByIdContext = ByIdContext;
// GeneralContext
export const useOrientacionContext = useGeneralContext;
export const OrientacionProvider = GeneralProvider;
export const OrientacionContext = GeneralContext;
