import { Button } from '@material-ui/core';
import React, { FC, useMemo } from 'react';
import { useStyles } from 'src/screens/Private/Presentismo/styles';
import { buttonHasToBeDisabled } from '../functions/cerrarSemana';
import { isWeekClosed } from '../../../utils/DateRangeList';
import { useRolesContext } from 'src/context/RolesContext';
import { Loading } from 'src/commons';
import { usePresentismosValidosContext } from '../../../context/PresentismosValidosContext';

export const CerrarSemanaBtn: FC<Presentismo.CerrarSemanaProps> = ({
  currentWeek,
  cierres = [],
  clickHandler,
  rolesCanClose = [],
  rolesCanOpen = [],
}) => {
  const classes = useStyles();

  const {
    selectedRole: { rol },
  } = useRolesContext();

  const {
    working,
    data: { alumnosDays },
  } = usePresentismosValidosContext();

  const canRolToggle = useMemo(() => {
    const isClosed = isWeekClosed(
      currentWeek.startOf('isoWeek').format('YYYY-MM-DD'),
      cierres,
    );
    return isClosed
      ? rolesCanOpen.includes(Number(rol))
      : rolesCanClose.includes(Number(rol));
  }, [currentWeek, cierres, rol, rolesCanClose, rolesCanOpen]);

  return working ? (
    <Loading />
  ) : (
    <Button
      classes={{ disabled: classes.buttonDisabled }}
      className={classes.button}
      disabled={buttonHasToBeDisabled(alumnosDays) || !canRolToggle}
      variant="contained"
      size="small"
      color="inherit"
      onClick={clickHandler}
    >
      {`${
        isWeekClosed(
          currentWeek.startOf('isoWeek').format('YYYY-MM-DD'),
          cierres,
        )
          ? 'Abrir'
          : 'Cerrar'
      } Semana`}
    </Button>
  );
};
