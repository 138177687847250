import { SituacionDAO } from 'src/platform/libby/dao/Presentismov2';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { Situacion } from '../../models/presentismo_v2';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'situacion';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { renderPropCall, renderPropFetchById, simpleSelect },
} = makeBusinessEntity<Situacion, SituacionDAO>(DAO_NAME, {});

// hooks
export const useSituacionFetchById = fetchById;
export const useSituacionLibbyCall = libbyCall;
export const useSituacionDAO = libbyDAO;
export const useSituacionLibbyFetch = libbyFetch;

// components/hoc
export const SituacionCall = renderPropCall;
export const SituacionById = renderPropFetchById;
export const SituacionSelect = simpleSelect;
