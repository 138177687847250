import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import _, { cloneDeep } from 'lodash';
import { TablePagination } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useOfertasGruposLibbyFetch } from 'src/app/business/acap/OfertasGrupos';
import { InfoTable, useRouteScreenTitle } from 'src/commons';
import { useLocalizacionPlanEstudioNivelLibbyFetch } from 'src/app/business';
import { useProyeccionEstadoLibbyFetch } from 'src/app/business';
import customFormDialog from 'src/commons/services/customFormDialog';
import { Footer, Loading } from 'src/commons';
import {
  useGruposSeleccionesLibbyFetch,
  useGruposSeleccionesDAO,
} from 'src/app/business/acap/GruposSelecciones';
import { Ofertas, OfertaGrupo } from 'src/app/models';
import { Orientacion } from 'src/app/models/orientaciones';
import { useOfertasGruposMinimalGetRequest } from 'src/app/business/acap/OfertasGruposMinimal';
import { useRolesContext } from 'src/context/RolesContext';
import { useOfertaPreseleccionColumns } from '../hooks/useOfertaPreseleccionColumns';
import { FiltersHeader } from '../components/FiltersHeader';
import { getUniqueItems } from '../functions/helpers';
import { DetalleOferta } from '../components/detalleOferta';
import { FilterRequest, OfertaFilters } from '../types';

const useStyles = makeStyles({
  marginTop: {
    marginTop: '60px',
  },
  buttonLoading: {
    height: '20px',
    padding: '0',
    display: 'flex',
    alignContent: 'center',
  },
});

export const OfertaPreseleccion = () => {
  useRouteScreenTitle('Actividades de aproximación');
  const {
    selectedRole: { localizacionId },
  } = useRolesContext();

  const establecimientosFilter = useMemo(
    () => ({
      user: [{ path: 'localizacion', value: localizacionId }],
    }),
    [localizacionId],
  );

  const { data: proyeccionEstado = [] } = useProyeccionEstadoLibbyFetch({
    filter: establecimientosFilter,
    limit: 1,
    orderBy: 'cicloLectivo',
    direction: 'desc',
  });

  const idCicloLectivo = proyeccionEstado?.[0]?.cicloLectivo?.idCicloLectivo;

  const [filters, setFilters] = useState<OfertaFilters>({
    orientacion: '',
    tipoAccion: '',
    turno: '',
  });

  const [checkedOfertas, setCheckedOfertas] = useState<Ofertas[]>([]);
  const [totalHorasACAP, setTotalHorasACAP] = useState(0);
  const [loadingSeleccion, setLoadingSeleccion] = useState(false);
  const [filterRequest, setFilterRequest] = useState<FilterRequest>({
    orientacion: [],
    tipoAccion: [],
    turno: [],
  });

  useEffect(() => {
    if (idCicloLectivo && !filterRequest.anio)
      setFilterRequest({
        ...filterRequest,
        anio: [
          {
            path: 'ofertaTurno.oferta.accion.cicloLectivo.idCicloLectivo',
            value: idCicloLectivo,
          },
        ],
      });
  }, [idCicloLectivo, filterRequest]);

  const [direction, setDirection] = useState<'asc' | 'desc'>('desc');
  const [limit, setLimit] = useState(50);
  const [offset, setOffset] = useState(0);
  const [ofertaGrupoFull, setOfertaGrupoFull] = useState<OfertaGrupo[]>([]);
  const [page, setPage] = useState(0);
  const [orderBy, setOrderBy] = useState(
    'ofertaTurno.oferta.accion.organizacion.nombre',
  );

  const classes = useStyles();
  const gruposSeleccionesDAO = useGruposSeleccionesDAO();
  const { enqueueSnackbar } = useSnackbar();

  const { data: localizacionPlanEstudioNivel = [] } =
    useLocalizacionPlanEstudioNivelLibbyFetch({
      limit: 500,
    });

  const uniquesOrientacionesEscuela: Orientacion[] = useMemo(() => {
    return getUniqueItems(
      localizacionPlanEstudioNivel
        .map((item) =>
          item.planEstudioNivel.planEstudio.orientaciones.map(
            (peOrientacion) => peOrientacion.orientacion,
          ),
        )
        .flat(),
    );
  }, [localizacionPlanEstudioNivel]);

  const ofertasGruposFilter = useMemo(() => {
    let query: string[] = [];
    if (Boolean(filters.orientacion)) {
      query.push(`filters[orientacion]=${filters.orientacion}`);
    }

    if (Boolean(filters.tipoAccion)) {
      query.push(`filters[tipoAccion]=${filters.tipoAccion}`);
    }

    if (Boolean(filters.turno)) {
      query.push(`filters[turno]=${filters.turno}`);
    }

    if (query.length > 0) {
      return `&${query.join('&')}`;
    } else {
      return '';
    }
  }, [filters]);

  const {
    data: ofertasGrupos = [],
    working: workingOfertasGrupos,
    request,
  } = useOfertasGruposMinimalGetRequest<OfertaGrupo[]>({
    url: `/api/acap/ofertas_minimal?limit=1500${ofertasGruposFilter}`,
    autoCall: idCicloLectivo !== undefined && !!filterRequest.anio,
  });

  const {
    data: gruposSeleccionados = [],
    working: workingGruposSeleccionados,
    reFetch: reFetchGruposSeleccionados,
  } = useGruposSeleccionesLibbyFetch({
    limit: 500,
    aspect: 'super-minimal',
  });

  const working = workingOfertasGrupos || workingGruposSeleccionados;

  const ofertasGruposFiltered = useMemo(
    () =>
      ofertasGrupos.filter(
        (grupo) =>
          !(
            grupo?.tipoCupo === 'Cerrado' &&
            grupo?.cupoEstatal > 0 &&
            grupo?.localizacion?.idLocalizacion !== localizacionId
          ) && !(grupo?.tipoCupo === 'Cerrado' && grupo?.cupoEstatal === 0),
      ),
    [localizacionId, ofertasGrupos],
  );

  const ofertasGruposFilteredCorrectos = ofertasGruposFiltered.filter(
    (e) =>
      e.ofertaTurno?.oferta?.fechaInicio !== null &&
      e.ofertaTurno?.oferta?.accion?.cicloLectivo?.idCicloLectivo ===
        idCicloLectivo,
  );

  const ofertasGruposPaginadas = ofertasGruposFilteredCorrectos.slice(
    offset,
    limit + offset,
  );

  const handleOpenModal = (row: any) => {
    customFormDialog.show({
      title: 'Vista Previa',
      renderComponent: <DetalleOferta row={row} />,
      sizeWidth: 'lg',
    });
  };

  const { selectedRole } = useRolesContext();

  const columns = useOfertaPreseleccionColumns({
    checkedOfertas,
    setCheckedOfertas,
    ofertasGruposFiltered,
    gruposSeleccionados,
    working,
    setTotalHorasACAP,
    handleOpenModal,
    selectedRole,
    orientacionesValidas: uniquesOrientacionesEscuela.map(
      (orientacion) => orientacion?.idOrientacion,
    ),
  });

  const ofertasGruposOrderer = useMemo(() => {
    return cloneDeep(ofertasGruposPaginadas).sort(
      (ofertaGrupoA, ofertaGrupoB) => {
        const nombreOrganizacionA = _.get(ofertaGrupoA, orderBy).toLowerCase();
        const nombreOrganizacionB = _.get(ofertaGrupoB, orderBy).toLowerCase();
        if (nombreOrganizacionA < nombreOrganizacionB) {
          return direction === 'asc' ? -1 : 1;
        }
        if (nombreOrganizacionA > nombreOrganizacionB) {
          return direction === 'desc' ? -1 : 1;
        }
        return 0;
      },
    );
  }, [direction, ofertasGruposPaginadas, orderBy]);

  const horasACAPSeleccionados = gruposSeleccionados
    .filter(
      (grupoSeleccion) =>
        ofertasGruposFilteredCorrectos.findIndex(
          (grupo) =>
            grupo?.idOfertaGrupo === grupoSeleccion?.ofertaGrupo?.idOfertaGrupo,
        ) !== -1,
    )
    .reduce((previousValue, currentValue) => {
      return (
        previousValue +
        currentValue?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.horas
      );
    }, 0);

  const readonlyButtonConfig: any = [
    {
      title: (
        <span>
          <b>Total horas ACAP: </b> {horasACAPSeleccionados + totalHorasACAP}
        </span>
      ),
      customStyle: { color: 'black !important' },
      disabled: true,
      type: '',
      size: 'medium',
    },
    {
      title: loadingSeleccion ? (
        <Loading className={classes.buttonLoading} />
      ) : (
        'Seleccionar'
      ),
      handleOnClick: async () => {
        setLoadingSeleccion(true);
        let ofertaGrupoSeleccionados: any = [];
        checkedOfertas.forEach((id: any) => {
          ofertaGrupoSeleccionados = [
            ...ofertaGrupoSeleccionados,
            { localizacion: localizacionId, ofertaGrupo: id },
          ];
        });
        try {
          await gruposSeleccionesDAO
            .aspect('tab-acciones-seleccionadas')
            .save(ofertaGrupoSeleccionados);
          setCheckedOfertas([]);
          enqueueSnackbar('Se ha seleccionado con éxito', {
            variant: 'success',
          });
        } catch (e) {
          console.log(e);
          enqueueSnackbar('Error al seleccionar', { variant: 'error' });
        }
        setCheckedOfertas([]);
        setTotalHorasACAP(0);
        reFetchGruposSeleccionados();
        setLoadingSeleccion(false);
      },
      disabled:
        working ||
        loadingSeleccion ||
        checkedOfertas.length === 0 ||
        !ofertasGruposFiltered.filter(
          (grupo) =>
            gruposSeleccionados.findIndex(
              (seleccionado) =>
                grupo?.idOfertaGrupo ===
                seleccionado?.ofertaGrupo?.idOfertaGrupo,
            ) === -1,
        ).length,
      type: 'primary',
      size: 'medium',
    },
  ];

  const handleRequestSort = useCallback(
    (orderBy: string, _direction: 'asc' | 'desc') => {
      //setDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
      setDirection(_direction);
      setOrderBy(orderBy);
    },
    [],
  );

  return (
    <>
      <>
        <FiltersHeader
          setFilters={setFilters}
          filters={filters}
          workingOferta={working}
          buscarOferta={() => request()}
        />
        <InfoTable
          paperCustomStyle={classes.marginTop}
          rows={loadingSeleccion || working ? [] : ofertasGruposOrderer}
          columns={columns}
          working={loadingSeleccion || working}
          onSortChange={handleRequestSort}
          orderBy={orderBy}
          direction={direction}
        />
        {ofertasGruposFilteredCorrectos?.length > 0 &&
          !(loadingSeleccion || working) && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={ofertasGruposFilteredCorrectos?.length}
              rowsPerPage={limit}
              page={offset / limit}
              onPageChange={(e, page) => {
                let offset = 0;
                if (page < 0) offset = 0 * limit;
                else offset = page * limit;
                setOffset(offset);
                setPage(page);
              }}
              onRowsPerPageChange={(e) => {
                setLimit(parseInt(e.target.value));
              }}
              labelRowsPerPage="Filas por página"
              labelDisplayedRows={({ from, to, count }) => {
                return from + '-' + to + ' de ' + count;
              }}
            />
          )}
        <Footer
          customStyle={{ color: 'black !important' }}
          position="center"
          buttonConfig={readonlyButtonConfig}
        />
      </>
    </>
  );
};
