import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { useHistory } from 'react-router';
import confirmDialog from 'src/commons/services/confirmDialog';
import { ButtonTypesProps, Footer, useBackButton } from 'src/commons';
import { usePlanificationFormDisciplinarComun } from '../../../PlanificacionDisciplinarComun/hooks';
import { useRouterContext } from 'src/lib/router';
import { compareObjects } from 'src/utils/compareObjects';

interface FormFooterProps {
  enviarCallBack: (values: any) => void;
  isDisabled?: Boolean;
  handleValuesChange?: (isDisabled: { isDisabled: Boolean }) => void;
  isNewPlan?: Boolean;
}

const useStyles = makeStyles({
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export const FormFooter = ({
  enviarCallBack,
  isDisabled,
  handleValuesChange,
  isNewPlan,
}: FormFooterProps) => {
  const classes = useStyles();
  const history = useHistory();
  const idx = history.location.pathname.indexOf('/planificacion');
  useBackButton(history.location.pathname.slice(0, idx));
  const { metadata } = useRouterContext();

  const {
    dirty: isDirty,
    handleSubmit,
    submitting,
    values,
  } = usePlanificationFormDisciplinarComun();

  const [initialValues, setInitialValues] = useState(values);

  const handleLeave = useCallback(async () => {
    if (isDirty) {
      const confirm = await confirmDialog.show({
        title: '¿Desea continuar?',
        content:
          'La información precargada se perderá en caso que no guarde, por favor seleccione una opción.',
        confirmText: 'Guardar',
        cancelText: 'Salir',
        backText: 'Cancelar',
      });
      if (confirm) {
        await handleSubmit();
      }
    }
    history.push(metadata.toPath);
  }, [metadata, history, handleSubmit, isDirty]);

  const buttonConfig: ButtonTypesProps[] = useMemo(
    () => [
      {
        title: 'Guardar',
        handleOnClick: () => {
          handleSubmit();
          setInitialValues(values);
        },
        typeButton: 'submit',
        disabled: !isDisabled,
      },
      {
        title: 'Salir',
        handleOnClick: () => handleLeave(),
        typeButton: 'submit',
        isLink: !isDisabled,
      },
      {
        title: 'Enviar',
        handleOnClick: () => enviarCallBack(values),
        typeButton: 'submit',
        disabled: isDisabled || isNewPlan,
      },
    ],
    [handleSubmit, isDisabled, enviarCallBack, isNewPlan, handleLeave, values],
  );

  useEffect(() => {
    // enable enviar btn when values are equal the initialValues
    // disabled enviar btn when values change
    handleValuesChange &&
      handleValuesChange({
        isDisabled: !compareObjects(values, initialValues),
      });
  }, [handleValuesChange, values, initialValues]);

  return (
    <>
      <Grid className={classes.footer}>
        <Footer
          path={metadata.toPath}
          buttonConfig={buttonConfig}
          loading={submitting}
        />
      </Grid>
    </>
  );
};
