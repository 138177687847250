import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { CicloLectivoAdultos } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'ciclo_lectivo_adultos';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idCicloLectivoAdultos',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idCicloLectivoAdultos',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.CicloLectivoAdultos
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.CicloLectivoAdultos,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.CicloLectivoAdultos.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<CicloLectivoAdultos>(DAO_NAME, optionsToUse);

// hooks
export const useCicloLectivoAdultosFetchById = fetchById;
export const useCicloLectivoAdultosLibbyCall = libbyCall;
export const useCicloLectivoAdultosDAO = libbyDAO;
export const useCicloLectivoAdultosLibbyFetch = libbyFetch;

// components/hoc
export const CicloLectivoAdultosAutocomplete = entityAutocomplete;
export const CicloLectivoAdultosCall = renderPropCall;
export const CicloLectivoAdultosById = renderPropFetchById;
export const CicloLectivoAdultosSelect = simpleSelect;
export const CicloLectivoAdultosAccordionSelector = accordionSelector;
export const CicloLectivoAdultosSelectedChips = selectedChips;

// context
// ByIdContext
export const useCicloLectivoAdultosByIdContext = useByIdContext;
export const CicloLectivoAdultosByIdProvider = ByIdProvider;
export const CicloLectivoAdultosByIdContext = ByIdContext;
// GeneralContext
export const useCicloLectivoAdultosContext = useGeneralContext;
export const CicloLectivoAdultosProvider = GeneralProvider;
export const CicloLectivoAdultosContext = GeneralContext;
