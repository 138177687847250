import { NIVEL } from './nivel';
import { ROL } from './rol';

export const ROLES_BY_NIVEL: Record<NIVEL, ROL[]> = {
  [NIVEL.NO_NIVEL]: [ROL.DIRECTIVO, ROL.EQUIPO_CONDUCCION],
  [NIVEL.GENERAL]: [ROL.DIRECTIVO, ROL.EQUIPO_CONDUCCION, ROL.DOCENTE],
  [NIVEL.INICIAL]: [ROL.DIRECTIVO, ROL.EQUIPO_CONDUCCION, ROL.DOCENTE],
  [NIVEL.PRIMARIO]: [
    ROL.DIRECTIVO,
    ROL.EQUIPO_CONDUCCION,
    ROL.DOCENTE,
    ROL.COORDINADOR_JE,
  ],
  [NIVEL.SECUNDARIO]: [
    ROL.DIRECTIVO,
    ROL.EQUIPO_CONDUCCION,
    ROL.DOCENTE,
    ROL.PRECEPTOR,
    ROL.COORDINADOR_JE,
  ],
  [NIVEL.TERCIARIO]: [ROL.DIRECTIVO, ROL.EQUIPO_CONDUCCION, ROL.DOCENTE],
  [NIVEL.SECUNDARIA_FUTURO]: [
    ROL.DIRECTIVO,
    ROL.EQUIPO_CONDUCCION,
    ROL.DOCENTE,
    ROL.COORDINADOR_DE_AREA,
    ROL.ASESOR_PEDAGOGICO,
    ROL.PRECEPTOR,
    ROL.COORDINADOR_JE,
  ],
};
