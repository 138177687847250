import React, { useEffect, useState } from 'react';

import {
  DisciplinarComunPreview,
  DisciplinarOrientadaPreview,
  ArealInterarealPreview,
  InterdisciplinarPreview,
} from '../preview';
import { useGeneralContext } from 'src/context/GeneralContext';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';

// Se renderiza de forma oculta la vista para poder pasarla a PDF
// Posiblemente sea mejor llevar esto a backend
export const PDFdoc = ({
  idPlanificacion,
  type,
  download,
}: {
  idPlanificacion: number;
  type: string;
  download: boolean;
}) => {
  const { dispatch } = useGeneralContext();

  const [readytoPrint, setReadyToPrint] = useState(false);

  useEffect(() => {
    const pageToPrint = document.getElementById('printPage');
    if (pageToPrint && readytoPrint) {
      const downloadPdf = async () => {
        const canvas = await html2canvas(pageToPrint);

        // A4 -> 210mm * 297mm
        const widthPage = 210;
        const heightPage = 297;

        const pdfDoc = getPdfFromCanvas({
          canvas,
          widthPage,
          heightPage,
        });

        download
          ? pdfDoc.save(`Planificación-${idPlanificacion}-${type.slice(2)}.pdf`)
          : window.open(pdfDoc.output('bloburl'));

        dispatch.printPlanificacion({
          idPlanificacion: null,
          type: null,
          download: false,
        });
      };
      setTimeout(() => downloadPdf(), 3000);
    }
  }, [dispatch, download, idPlanificacion, readytoPrint, type]);

  const typePreview = new Map([
    [
      'idEspaciosArealPlan',
      <ArealInterarealPreview
        idPlan={idPlanificacion}
        setReadyToPrint={setReadyToPrint}
      />,
    ],
    [
      'idEspaciosSimpDiscipPlan',
      <DisciplinarComunPreview
        idPlan={idPlanificacion}
        setReadyToPrint={setReadyToPrint}
      />,
    ],
    [
      'idEspaciosDiscipOrientPlan',
      <DisciplinarOrientadaPreview
        idPlan={idPlanificacion}
        setReadyToPrint={setReadyToPrint}
      />,
    ],
    [
      'idEspaciosInterdiscipPlan',
      <InterdisciplinarPreview
        idPlan={idPlanificacion}
        setReadyToPrint={setReadyToPrint}
      />,
    ],
  ]);

  return (
    <div style={{ position: 'relative' }}>
      <div style={{ position: 'absolute' }}>
        <div
          style={{
            // border: '1px solid red',
            overflow: 'scroll',
            height: 50,
            width: 50,
          }}
        >
          <div
            id="printPage"
            style={{
              backgroundColor: 'white',
              width: 1920,
            }}
          >
            {typePreview.get(type)}
          </div>
        </div>
      </div>
      <div
        style={{
          // border: '1px solid green',
          position: 'absolute',
          height: 100,
          width: '100%',
          backgroundColor: 'white',
        }}
      />
    </div>
  );
};

const getPdfFromCanvas = ({
  canvas,
  widthPage = 210,
  heightPage = 297,
}: {
  canvas: HTMLCanvasElement;
  widthPage?: number;
  heightPage?: number;
}) => {
  const pdfDoc = new jsPDF();

  // proporción del canvas
  const ratio = canvas.width / canvas.height;

  // alto proporcional del canvas para un ancho dado
  const heightCanva = widthPage / ratio;

  const totalPages = Math.ceil(heightCanva / heightPage);

  for (let i = 0; i <= totalPages; i++) {
    const offset = -heightPage * i;

    pdfDoc.addImage(canvas, 'JPEG', 0, offset, widthPage, heightCanva);
    if (i + 1 < totalPages) {
      pdfDoc.addPage();
    }
  }
  return pdfDoc;
};
