import { useSnackbar } from 'notistack';
import { useCallback } from 'react';
import { useProyeccionEstadoDAO } from 'src/app/business';
import { DEFAULT_HIDE_TIME } from 'src/commons/const/ui';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useRolesContext } from 'src/context/RolesContext';

export const useFinalizarProyeccion: Promocion.useFinalizarProyeccion = ({
  proyeccionEstado,
  proyeccionEstadoReFetch,
  request,
  recallSeccionProyeccion,
}) => {
  const {
    selectedRole: { localizacionId },
  } = useRolesContext();
  const { enqueueSnackbar } = useSnackbar();
  const proyeccionEstadoDAO = useProyeccionEstadoDAO();

  const content =
    'Esta a punto de finalizar la proyección para esta promoción. El proceso no podrá deshacerse.';
  const post = useCallback(async () => {
    const confirm = await confirmDialog.show({
      title: 'Finalizar proyección',
      content,
      confirmText: 'Confirmar proyección',
      cancelText: 'Volver',
    });

    if (confirm) {
      try {
        const finalizarResponse = await proyeccionEstadoDAO.save({
          idProyeccionEstado: proyeccionEstado?.idProyeccionEstado,
          localizacion: {
            idLocalizacion: localizacionId,
          },
          proyeccionEstadoTipo: {
            idProyeccionEstadoTipo: 2,
          },
        });
        if (!finalizarResponse?.existError) {
          enqueueSnackbar('La finalización se realizó con éxito.', {
            variant: 'success',
            autoHideDuration: DEFAULT_HIDE_TIME,
          });
        } else {
          throw new Error(finalizarResponse?.error?.message);
        }
      } catch (error) {
        enqueueSnackbar(
          'La finalización no pudo realizarse. Por favor inténtelo nuevamente.',
          {
            variant: 'error',
            autoHideDuration: DEFAULT_HIDE_TIME,
          },
        );
      }
      proyeccionEstadoReFetch();
      recallSeccionProyeccion();
      request();
    }
  }, [
    enqueueSnackbar,
    localizacionId,
    proyeccionEstado?.idProyeccionEstado,
    proyeccionEstadoDAO,
    proyeccionEstadoReFetch,
    recallSeccionProyeccion,
    request,
  ]);

  return post;
};
