import {
  PropuestaJEDAO,
  PropuestaJEDiaDAO,
} from 'src/platform/libby/dao/Presentismov2';
import { presentismo } from '../types';

export default class PropuestaJEService {
  private values: presentismo.PropuestaJe = {};
  static ERROR_MESSAGES = {
    duplicate: 'Esta propuesta ya está registrada.',
  };

  constructor(
    private seccion: number,
    private propuestaDao: PropuestaJEDAO,
    private propuestaDiaDao: PropuestaJEDiaDAO,
  ) {}

  setValues(values: presentismo.PropuestaJe) {
    this.values = values;

    return this;
  }

  /**
   * Aqui ya tenemos acceso
   * a los valores del formulario
   * y la seccion seleccionada
   */
  async submit() {
    const dias = [
      presentismo.Days.Lunes,
      presentismo.Days.Martes,
      presentismo.Days.Miercoles,
      presentismo.Days.Jueves,
      presentismo.Days.Viernes,
      presentismo.Days.Sabado,
    ];
    const items = [];
    try {
      if (!this.values.propuesta) {
        this.values.propuesta = await this.propuestaDao.savePropuesta({
          seccion: this.seccion,
        });
      }
      for (const [day, meta] of Object.entries(this.values)) {
        if (meta.aceptado) {
          items.push({
            sede:
              typeof meta.sede === 'string' ? { nombre: meta.sede } : meta.sede,
            dia: dias.findIndex((item) => item === day) + 1,
            propuesta: this.values.propuesta,
            idPropuestaJeDia: meta.idPropuestaJeDia,
          });
        }
      }
      await this.propuestaDiaDao.aspect('saving').save(items);
    } catch (error) {
      if (
        Object.keys(this.propuestaDao.errors).length > 0 ||
        Object.keys(this.propuestaDiaDao.errors).length > 0
      ) {
        await this.propuestaDao.aspect('delete-propuesta').remove({
          idPropuestaJe: this.values.propuesta?.idPropuestaJe,
        }); // Eliminamos la propuesta si el save de los dias falla.
        throw new Error(
          PropuestaJEService.ERROR_MESSAGES[
            error.message.match(/duplicate/gi)[0]
          ] ?? 'Ocurrió un error inesperado',
        );
      }
    }
  }
}
