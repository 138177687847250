import { useCallback, useEffect, useRef, useState } from 'react';
import { useLibby } from '@phinxlab/libby-rest-web';
export interface LibbyCallOptions {
  daoName: string;
  methodName: string;
  params?: any[];
  noAutoCall?: boolean;
  aspect?: string;
}

export interface LibbyCallReturn<T> {
  data?: T[];
  working: boolean;
  recall: (...args: any[]) => Promise<T[]>;
  error?: Error;
}

export const useLibbyCall = <T>({
  daoName,
  methodName,
  params = [],
  noAutoCall = false,
  aspect,
}: LibbyCallOptions): LibbyCallReturn<T> => {
  const { libby } = useLibby([daoName]);
  const paramsRef = useRef(params);
  const [working, setWorking] = useState(false);
  const [data, setData] = useState<T[]>();
  const [error, setError] = useState<Error | undefined>();

  const recall = useCallback(
    async (...newParams) => {
      if (!libby[daoName] || !libby[daoName][methodName]) {
        // eslint-disable-next-line
        console.error('Cannot found dao or method in dao');
        return [] as T[];
      }
      if (aspect) {
        libby[daoName].aspect(aspect);
      }
      const paramsToBeUsed = newParams.length ? newParams : paramsRef.current;
      setWorking(true);
      setError(undefined);
      try {
        const result = await libby[daoName][methodName](...paramsToBeUsed);
        if (
          Object.keys(libby[daoName].errors).length > 0 &&
          !!libby[daoName].errors[daoName]
        ) {
          setError(libby[daoName].errors[daoName]);
        }
        setData(result);
        setWorking(false);
        return result as T[];
      } catch (e) {
        setWorking(false);
        setError(e as Error);
        throw e;
      }
    },
    [daoName, libby, methodName, aspect],
  );
  useEffect(() => {
    if (!noAutoCall) {
      recall();
    }
  }, [noAutoCall, recall]);

  return { data, working, recall, error };
};
