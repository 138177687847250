import React from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import { ActividadPlan } from 'src/app/models';
import { grey } from 'src/theme/colors';
import {
  periodos,
  Periodo,
} from '../../../forms/ArealInterareal/tabs/Actividad';
import { ListItemProperties } from '../../commons';
import { useInterdisciplinarContext } from '../../../forms/commons/components';

const useStyles = makeStyles(() => ({
  textField: {
    fontSize: 14,
    color: grey.textPrimary,
    fontWeight: 600,
  },
  valueField: {
    fontSize: 16,
    color: grey.textPrimary,
    fontWeight: 400,
    marginTop: 8,
    marginBottom: 20,
  },
}));
export interface ActividadProps {
  data: ActividadPlan;
}

export const Actividad = ({ data }: ActividadProps) => {
  const classes = useStyles();
  const {
    data: { recursos },
    working,
  } = useInterdisciplinarContext();

  const filterPeriodoCalificacion =
    data?.periodoDeCalificacionFinalDelProyecto?.map((periodo: string) =>
      periodos.find((_periodo: Periodo) => periodo === _periodo.id.toString()),
    );
  const filterActividades = data?.actividades?.map((actividad: any) => {
    const filterRecursos = actividad?.recursos?.map((recurso: string) =>
      recursos.find((_recursos: any) => recurso === _recursos.idRecursos),
    );
    const filterPeriodo = actividad?.periodo?.map((periodo: string) =>
      periodos.find((_periodo: Periodo) => periodo === _periodo.id.toString()),
    );

    return {
      ...actividad,
      recursos: filterRecursos,
      periodo: filterPeriodo,
    };
  });

  return (
    <Grid container direction="column">
      {!working &&
        filterActividades.map(
          (
            { nombre, periodo, recursos: _recursos, descripcion, horasCatedra },
            index: number,
          ) => (
            <Grid key={index}>
              <Typography className={classes.textField}>Nombre</Typography>
              <Typography className={classes.valueField}>{nombre}</Typography>
              <Typography className={classes.textField}>Descripción</Typography>
              <Typography className={classes.valueField}>
                {descripcion}
              </Typography>
              {_recursos[0] && (
                <ListItemProperties
                  title="Recursos"
                  values={_recursos?.map((h: any) => ({
                    id: h.idRecursos,
                    descripcion: h.descripcion,
                  }))}
                />
              )}
              <Typography className={classes.textField}>
                Horas cátedra
              </Typography>
              <Typography className={classes.valueField}>
                {horasCatedra}
              </Typography>
              <Typography className={classes.textField}>Período</Typography>
              {periodo.map((p: any) => (
                <Typography key={p.id} className={classes.valueField}>
                  {p.name}
                </Typography>
              ))}
            </Grid>
          ),
        )}
      <Typography className={classes.textField}>
        Periodo de calificación final del proyecto
      </Typography>
      {!working &&
        filterPeriodoCalificacion.map((p: any) => (
          <Typography key={p.id} className={classes.valueField}>
            {p.name}
          </Typography>
        ))}
    </Grid>
  );
};
