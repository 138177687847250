import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Grid } from '@material-ui/core';
import { PermissionBlocker } from '../../../lib/permission/components';
import { CardButton } from '../../const/ui/cards';

export type MenuCardItem = {
  id: string | number;
  title: string;
  img: string;
  path: string;
};

export interface MenuCardListProps {
  items: MenuCardItem[];
  url: string;
}

export const MenuCardList = ({ items, url }: MenuCardListProps) => {
  const history = useHistory();

  const onCardClick = useCallback(
    (item: MenuCardItem) => {
      history.push(`${url}${item.path}`);
    },
    [history, url],
  );
  return (
    <Grid container spacing={2}>
      {items.map((item) => (
        <PermissionBlocker key={item.id} id={item.id}>
          <Grid item xs={12} md={4} lg={3}>
            <CardButton<MenuCardItem>
              title={item.title}
              image={item.img}
              data={item}
              onClick={onCardClick}
            />
          </Grid>
        </PermissionBlocker>
      ))}
    </Grid>
  );
};
