import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { FooterButtons } from '../../../../../../../commons/components/FooterButtons';
import { useFormContext } from '../../../../../../../lib/templates/context';
import { useSaveCancelButtonConfig } from '../../../../../../../commons/hooks';

export const FormFooter = () => {
  const { submitting, handleSubmit } = useFormContext();
  const history = useHistory();
  const onCancel = useCallback(() => history.goBack(), [history]);
  const buttons = useSaveCancelButtonConfig({
    onSubmit: handleSubmit,
    onCancel,
  });
  return <FooterButtons buttons={buttons} loading={submitting} />;
};
