import { Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import {
  Acompanante,
  AptoFisico,
  AtencionHospitalaria,
  Cobertura,
  DatosPrincipales,
  LOSE,
} from './components';
import { useLegajoData } from '../../context';

export const Health: FunctionComponent = () => {
  const { isHistoric } = useLegajoData();

  return (
    <Grid container direction="column" spacing={4}>
      <Grid item>
        <DatosPrincipales isHistoric={isHistoric} />
      </Grid>
      <Grid item>
        <AptoFisico isHistoric={isHistoric} />
      </Grid>
      <Grid item>
        <Cobertura isHistoric={isHistoric} />
      </Grid>
      <Grid item>
        <AtencionHospitalaria isHistoric={isHistoric} />
      </Grid>
      <Grid item>
        <LOSE isHistoric={isHistoric} />
      </Grid>
      <Grid item>
        <Acompanante isHistoric={isHistoric} />
      </Grid>
    </Grid>
  );
};
