import { MenuItem } from '../../../../../commons/const/ui/menu';
import { AprobacionRoles } from '../../../../../screens/Private/ConfigurationAdmin/AprobacionRoles';

export const aprobacionRolesMenu: MenuItem = {
  key: 'configurationAdminAprobacionRoles',
  basePath: '/aprobacionroles',
  target: {
    component: AprobacionRoles,
    path: '/',
  },
  enabled: true,
  icon: 'List',
  text: 'Configuration Admin Aprobacion Roles',
  children: [],
};
