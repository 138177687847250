import React from 'react';
import { Grid } from '@material-ui/core';
import { loading } from '../../../assets/images';

export interface LoadingProps {
  className?: string;
  width?: number;
}

export const Loading = ({ className = '', width = 50 }: LoadingProps) => (
  <Grid
    className={className}
    container
    justifyContent="center"
    alignItems="center"
  >
    <img src={loading} width={width} alt="loading" />
  </Grid>
);
