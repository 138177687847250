import React, {
  useCallback,
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react';
import { useSnackbar } from 'notistack';
import { useParams, useHistory } from 'react-router';
import {
  Area,
  ArealPlan,
  ArealPlanArea,
  ArealTempPlan,
  EspaciosArealPlan,
  NewArealPlan,
} from 'src/app/models';
import { useRolesContext } from 'src/context/RolesContext';
import { useGeneralContext } from 'src/context';
import {
  useArealPlanAreaDAO,
  useArealPlanDAO,
  useEspacioCurricularFetchById,
  useEspaciosArealPlanDAO,
} from 'src/app/business';
import { Loading } from 'src/commons';
import { SeccionProvider } from '../../../../../../../app/business';
import { TabBar } from '../../../../../../../commons/components/TabBar';
import { PLANIFICACION_ESTADOS } from '../../../../../../../commons/const';
import { PlanificationFooter } from '../commons/components';
import { FormContextProvider } from '../../../../../../../lib/templates';
import { FormProps } from '../commons/types';
import { useTemplateJoiner } from '../commons/hooks';
import { tabs } from './tabs';
import { ArealInterarealHeader } from './components';
import { ArealInterarealProvider } from './context';
import { DEFAULT_HIDE_TIME } from 'src/commons/const/ui/snackbar';

const isArealPlan = (item: ArealPlan | NewArealPlan): item is ArealPlan =>
  (item as ArealPlan).idArealPlan !== undefined;

export const ArealInterarealForm = ({
  initialValues,
  template,
}: FormProps<ArealPlan, NewArealPlan, ArealTempPlan>) => {
  const [areas, setAreas] = useState<Area[]>(
    initialValues.arealPlanAreas.map((value) => value.area),
  );

  // disabled enviar button for new plannings
  const [isDisabled, setIsDisabled] = useState<Boolean>(
    !isArealPlan(initialValues),
  );
  const [valuesUpdated, setValuesUpdated] = useState(initialValues);

  // route params
  const history = useHistory();
  const { selectedRole, userInfo } = useRolesContext();
  const { generalState } = useGeneralContext();
  // @ts-ignore
  const { idEspacioCurricular } = useParams();
  const { enqueueSnackbar } = useSnackbar();

  const { data: espacioCurricular } =
    useEspacioCurricularFetchById(idEspacioCurricular);

  const areaByEspacioCurricular = useMemo(() => {
    // @ts-ignore
    const arealPlan = initialValues.arealPlanAreas.find(
      (a: ArealPlanArea) =>
        a.area.idArea === espacioCurricular?.materia.area.idArea,
    );
    return arealPlan && arealPlan.area;
  }, [espacioCurricular, initialValues]);

  useEffect(() => {
    if (isArealPlan(initialValues)) {
      setAreas(initialValues.arealPlanAreas.map((value) => value.area));
    } else if (areaByEspacioCurricular) {
      setAreas([{ ...areaByEspacioCurricular }]);
    }
  }, [initialValues, areaByEspacioCurricular]);

  const templateMap = {
    informacionGeneral: 'informacionGeneralTemp',
    actividad: 'actividadTemp',
    bibliografia: 'bibliografiaTemp',
    espacioCurricular: 'espacioCurricularTemp',
    indicadoresDeEvaluacion: 'indicadoresDeEvaluacionTemp',
    objetivosDeAprendizaje: 'objetivosDeAprendizajeTemp',
    proyecto: 'proyectoTemp',
  };

  // Unimos varios templates en uno solo
  const joinedTemplates = useTemplateJoiner(template, templateMap);

  const arealPlanAreaDAO = useArealPlanAreaDAO();
  const espaciosArealPlanDAO = useEspaciosArealPlanDAO();
  const arealPlanDAO = useArealPlanDAO();
  const enviarCallBack = async () => {
    const newValue = {
      ...valuesUpdated,
      planificacionEstados: {
        idPlanificacionEstados: PLANIFICACION_ESTADOS.ENVIADO,
      },
    };
    await arealPlanDAO.save(newValue);
    history.goBack();
    enqueueSnackbar('La planificación ha sido enviada con éxito', {
      variant: 'success',
      autoHideDuration: DEFAULT_HIDE_TIME,
    });
  };
  const onSubmit = useCallback(
    async (values) => {
      try {
        if (!isArealPlan(valuesUpdated)) {
          const planificacionData = {
            ...values,
            localizacion: { idLocalizacion: selectedRole.localizacionId },
            arealTempPlan: { idArealTempPlan: template.idArealTempPlan },
            cuentas: { idUsuario: userInfo.id },
          };
          // Se guarda la nueva planificación
          const arealPlan: ArealPlan = await arealPlanDAO.save(
            planificacionData,
          );
          let espaciosArealPlan: EspaciosArealPlan | undefined;
          for (const element of values.espacioCurricular.espaciosCurriculares) {
            const idEspacioCurricular = element.espacioCurricular.id;

            const espacioCurricular = {
              idEspacioCurricular: idEspacioCurricular,
            };

            espaciosArealPlan = await espaciosArealPlanDAO
              .aspect('limit_province')
              .save({
                espacioCurricular,
                arealPlan,
              });
          }
          // Se le agregan las Areas a la nueva planificación
          await Promise.all(
            areas.map((area) => {
              const data: Omit<ArealPlanArea, 'idArealPlanArea'> = {
                area,
                arealPlan: { idArealPlan: arealPlan.idArealPlan },
              };
              return arealPlanAreaDAO.save(data);
            }),
          );
          enqueueSnackbar('Se ha guardado con éxito', {
            variant: 'success',
            autoHideDuration: DEFAULT_HIDE_TIME,
          });

          const arealWithAreas = {
            ...planificacionData,
            idArealPlan: arealPlan.idArealPlan,
          };
          // Se consulta la planificación con el id obtenido
          // Para obtener el objeto creado incluyendo sus areas
          // De esta forma se lo podría enviar sin necesidad de salir del formulario
          const arealPlanUpdatedAreas: ArealPlan = await arealPlanDAO.save(
            arealWithAreas,
          );

          setValuesUpdated(arealPlanUpdatedAreas);
          setIsDisabled(false);
        } else {
          // update
          const updatedInterDiscip = {
            ...valuesUpdated,
            ...values,
            planificacionEstados: {
              idPlanificacionEstados: PLANIFICACION_ESTADOS.EN_PROCESO,
            },
          };
          updatedInterDiscip.ultimaActualizacion = new Date();
          const updated: ArealPlan = await arealPlanDAO.save(
            updatedInterDiscip,
          );

          // Adding news
          const newAreas = areas.filter(
            (area) =>
              !valuesUpdated.arealPlanAreas.find(
                (item) => item.area.idArea === area.idArea,
              ),
          );
          if (newAreas.length) {
            await Promise.all(
              newAreas.map((area) => {
                const data: Omit<ArealPlanArea, 'idArealPlanArea'> = {
                  area,
                  arealPlan: { idArealPlan: updated.idArealPlan },
                };
                return arealPlanAreaDAO.save(data);
              }),
            );
          }
          // Removing
          const toRemove = valuesUpdated.arealPlanAreas.filter(
            (item) => !areas.find((area) => area.idArea === item.area.idArea),
          );
          if (toRemove.length) {
            await Promise.all(
              toRemove.map((item) => arealPlanAreaDAO.remove(item)),
            );
          }
          enqueueSnackbar('Se ha guardado con éxito', {
            variant: 'success',
            autoHideDuration: DEFAULT_HIDE_TIME,
          });
          setValuesUpdated(updated);
          setIsDisabled(false);
        }
      } catch (e) {
        // TODO: fix error de libby
        console.log(e);
      }
    },
    [
      valuesUpdated,
      selectedRole.localizacionId,
      template.idArealTempPlan,
      userInfo.id,
      espaciosArealPlanDAO,
      areas,
      enqueueSnackbar,
      arealPlanAreaDAO,
      arealPlanDAO,
    ],
  );

  const onChangeAreas = useCallback(
    (value) => {
      if (areaByEspacioCurricular) {
        const defaultValue = value.find(
          (a: Area) => a.idArea === areaByEspacioCurricular.idArea,
        );
        if (defaultValue) {
          setAreas(value);
        } else {
          setAreas([areaByEspacioCurricular, ...value]);
        }
      } else {
        setAreas(value);
      }
    },
    [setAreas, areaByEspacioCurricular],
  );

  let filterAnio: any = [];
  if (generalState?.espacioCurricular.planificacion.selected.anio) {
    filterAnio = [
      {
        path: 'anio.idAnio',
        value: generalState?.espacioCurricular.planificacion.selected.anio,
      },
    ];
  }

  const seccionFilter = useRef({
    filterAnio,
    1: [{ path: 'localizacion', value: selectedRole?.localizacionId }],
  });

  const depuredInitialValue = useMemo<NewArealPlan>(() => {
    if (!isArealPlan(initialValues)) {
      // TODO: remove el tsignore
      const {
        // @ts-ignore
        arealPlanAreas,
        ...rest
      } = initialValues;

      return rest;
    }
    const {
      idArealPlan,
      arealPlanAreas,
      arealTempPlan,
      cuentas,
      localizacion,
      ultimaActualizacion,
      planificacionEstados: pe,
      ...rest
    } = initialValues;
    return rest;
  }, [initialValues]);

  return (
    <>
      <ArealInterarealHeader
        localizacionId={selectedRole.localizacionId}
        idEspacioCurricular={idEspacioCurricular}
        areas={areas}
        onChangeAreas={onChangeAreas}
        espacioCurricular={espacioCurricular}
      />
      {!joinedTemplates ? (
        <Loading />
      ) : (
        <SeccionProvider filter={seccionFilter.current}>
          <ArealInterarealProvider
            areas={areas}
            idAnio={espacioCurricular?.materia.anio.idAnio}
            idPlanEstudio={espacioCurricular?.planEstudio.idPlanEstudio}
          >
            <FormContextProvider
              template={joinedTemplates}
              initialValues={depuredInitialValue}
              onSubmit={onSubmit}
            >
              <>
                <TabBar content={tabs} />
                <PlanificationFooter
                  enviarCallBack={enviarCallBack}
                  handleValuesChange={({ isDisabled }) =>
                    setIsDisabled(isDisabled)
                  }
                  isDisabled={isDisabled}
                  areas={areas}
                />
              </>
            </FormContextProvider>
          </ArealInterarealProvider>
        </SeccionProvider>
      )}
    </>
  );
};
