import { AccionesFormValues } from './types';

export const accionesInitialValue: AccionesFormValues = {
  tipoAccion: 0,
  nombre: '',
  accionOrientaciones: [],
  descripcion: '',
  horas: 0,
  cicloLectivo: 0,
  organizacionName: '',
  sectorName: '',
  autoCompleteValue: { entity_nombre: '' },
  autoCompleteInputValue: '',
  editorTextValue: '',
  idOrganizacion: 0,
  idAccion: '',
};
