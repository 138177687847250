import React, { useMemo } from 'react';
import { Button, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import customFormDialog from 'src/commons/services/customFormDialog';
import { JustificacionFile } from '../../../../../app/models';

export const VistaPdfJustificacion = ({ data: file }: { data: string }) => {
  const infoFile = useMemo(() => {
    const info: JustificacionFile = JSON.parse(file);
    return info;
  }, [file]);

  return (
    <Grid style={{ width: '100%', height: '500px', position: 'relative' }}>
      <Button
        style={{
          position: 'absolute',
          top: '-45px',
          right: '30px',
          zIndex: 1,
        }}
        onClick={() => {
          customFormDialog.handleCancel();
        }}
      >
        <CloseIcon fontSize="large" />
      </Button>

      <iframe
        title={infoFile.name}
        datatype={infoFile.mimeType}
        frame-ancestors="https://drive.google.com"
        src={`https://drive.google.com/file/d/${
          infoFile.data.split('/')[5]
        }/preview`}
        style={{
          width: '98%', // Ajusta el ancho al 100% para evitar desplazamiento horizontal
          height: 'calc(100% - 30px)', // Ajusta la altura para evitar desplazamiento vertical
          paddingLeft: '10px',
        }}
      />
    </Grid>
  );
};
