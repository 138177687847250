import React, { useState, useEffect, useMemo } from 'react';
import { Grid, makeStyles, Button } from '@material-ui/core';
import { Comentario as ComentarioModel } from 'src/app/models';
import { Comentario } from '../Comentario';

const useStyles = makeStyles((theme) => ({
  comentariosList: {
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

interface ComentarioListProps {
  comentarios?: ComentarioModel[];
  userName: string;
  reFetchPublicacion: () => void;
  idPublicacion: number;
}

export const ComentariosList = ({
  comentarios,
  userName,
  reFetchPublicacion,
  idPublicacion,
}: ComentarioListProps) => {
  const [allComments, setAllComments] = useState(true);
  const [firstComments, setFirstComments] = useState<ComentarioModel[]>([]);
  const classes = useStyles();

  const handleComments = () => {
    setAllComments(!allComments);
  };

  const moreThanThreeComments = useMemo(
    () => comentarios && comentarios.length > 3,
    [comentarios],
  );

  useEffect(() => {
    if (comentarios && moreThanThreeComments) {
      setFirstComments([comentarios[0], comentarios[1], comentarios[2]]);
      setAllComments(false);
    }
  }, [comentarios, moreThanThreeComments]);

  return (
    <Grid container>
      <Grid container item className={classes.comentariosList}>
        {moreThanThreeComments && (
          <Button onClick={handleComments}>
            {allComments ? 'Menos Comentarios' : 'Más Comentarios'}
          </Button>
        )}
      </Grid>
      {allComments
        ? comentarios &&
          comentarios.map((comment) => (
            <Comentario
              key={idPublicacion}
              userName={userName}
              comment={comment}
              reFetchPublicacion={reFetchPublicacion}
              idPublicacion={idPublicacion}
            />
          ))
        : firstComments &&
          firstComments.map((comment) => (
            <Comentario
              key={idPublicacion}
              userName={userName}
              comment={comment}
              reFetchPublicacion={reFetchPublicacion}
              idPublicacion={idPublicacion}
            />
          ))}
    </Grid>
  );
};
