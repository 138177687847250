import React from 'react';
import { Grid, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import customFormDialog from 'src/commons/services/customFormDialog';
import { ObservacionesModal } from 'src/commons';
import { IndicadorTipoTextoModal } from '../../informesTypes';
import { useInformesStyles } from '../hooks/useInformesStyles';
import { InformescCustom } from 'src/app/models';

export const InputIndicadorTipoTextoModal = ({
  isInformesClosed,
  indicador,
  row,
  handleDataChange,
}: {
  isInformesClosed: boolean;
  indicador: IndicadorTipoTextoModal;
  row: InformescCustom;
  handleDataChange: (
    data: { name: string; value: string },
    row: InformescCustom,
  ) => void;
}) => {
  const classes = useInformesStyles();

  const handleTextField: any = (title: string, name: string, row: any) => {
    customFormDialog.show({
      title: title,
      renderComponent: (
        <ObservacionesModal
          content={row?.nota?.data?.[name]}
          onAddContent={(content: string) => {
            handleDataChange({ name, value: content }, row);
          }}
          value={row.nota?.data?.[name]}
        />
      ),
      sizeWidth: 'sm',
    });
  };

  return (
    <>
      <Grid>
        {row.nota?.data?.[indicador.id] ? (
          <Grid
            className={classes.rowSelect}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid className={`${classes.notes} `}>
              <div className={`${classes.notesText}`}>
                {row.nota?.data?.[indicador.id]}
              </div>
              <IconButton
                className={classes.iconNotes}
                disabled={isInformesClosed}
                onClick={() => {
                  handleTextField(indicador.modalTitle, indicador.id, row);
                }}
              >
                <LibraryBooksIcon />
              </IconButton>
            </Grid>
          </Grid>
        ) : (
          <Grid
            className={classes.rowSelect}
            style={{
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Grid className={`${classes.notes}`}>
              <IconButton
                className={classes.iconNotes}
                disabled={isInformesClosed}
                onClick={() => {
                  handleTextField(indicador.modalTitle, indicador.id, row);
                }}
              >
                <EditIcon />
              </IconButton>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};
