import { LibbyFetchDAO } from './LibbyFetchDAO';

export class DatosLaboralesDAO extends LibbyFetchDAO {
  constructor() {
    super('datos_laborales_resp', 'idDatosLab');
  }

  getByResponableId(id: string) {
    return this.query().equals('responsable.idResponsable', id).run();
  }
}
