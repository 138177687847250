import React, { useCallback, useState } from 'react';
import { Grid, InputAdornment, Typography } from '@material-ui/core';
import { useBackButton, useRouteScreenTitle } from '../../../../commons/hooks';
import { Input } from '../../../../app/components/inputs/Input';
import { Loading } from '../../../../commons/components/Loading';
import {
  LocalizacionById,
  LocalizacionSelectedChips,
} from '../../../../app/business';
import { LocalizacionPickerDialog } from '../../../../app/dialogs/LocalizacionPickerDialog';
import { GestionRoles } from './components/GestionRoles';

const SPACING = 4;

export const AprobacionRoles = () => {
  useBackButton('/private/configurationadmin');
  useRouteScreenTitle('Administración - Aprobación de Roles');

  const [idLocalizacion, setLocalizacion] = useState('');

  const onClick = useCallback(async () => {
    const value = await LocalizacionPickerDialog.open(idLocalizacion);
    if (value !== null) {
      setLocalizacion(value);
    }
  }, [idLocalizacion]);

  return (
    <Grid container direction="column" spacing={SPACING}>
      <Grid item>
        <Input
          placeholder={!idLocalizacion ? 'Selecciona Establecimiento' : ''}
          label="Establecimiento"
          onClick={onClick}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                {idLocalizacion && (
                  <LocalizacionById id={idLocalizacion} key={idLocalizacion}>
                    {(result) =>
                      result.working ? (
                        <Loading />
                      ) : !result.data ? (
                        <Typography>No encontrado</Typography>
                      ) : (
                        <LocalizacionSelectedChips
                          items={[result.data]}
                          onChange={() => setLocalizacion('')}
                        />
                      )
                    }
                  </LocalizacionById>
                )}
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item>
        {idLocalizacion && <GestionRoles idLocalizacion={idLocalizacion} />}
      </Grid>
    </Grid>
  );
};
