import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ModalidadNivelDAO extends LibbyFetchDAO {
  constructor() {
    super('modalidad_nivel', 'idModalidadNivel');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByNivelAndModalidad(idModalidad: number, idNivel: number) {
    return this.query()
      .equals('modalidad', idModalidad)
      .equals('nivel', idNivel)
      .run();
  }
}
