import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { TurnDAO } from '../../platform/libby/dao';
import { Turno } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'turno';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idTurno',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idTurno',
      labelKey: 'descripcion',
    },
    withSimpleSelect: {
      name: 'turno',
      title: 'Turno solicitado',
      placeholder: 'Seleccionar Turno',
      labelKey: 'descripcionTurno',
      valueKey: 'idTurno',
    },
  },
};

const optionsToUse = !businessOptions.Turno
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Turno,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Turno.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
    selectInput,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Turno, TurnDAO>(DAO_NAME, optionsToUse);

// hooks
export const useTurnoFetchById = fetchById;
export const useTurnoLibbyCall = libbyCall;
export const useTurnoDAO = libbyDAO;
export const useTurnoLibbyFetch = libbyFetch;

// components/hoc
export const TurnoAutocomplete = entityAutocomplete;
export const TurnoCall = renderPropCall;
export const TurnoById = renderPropFetchById;
export const TurnoSelect = simpleSelect;
export const TurnoSelectInput = selectInput;
export const TurnoAccordionSelector = accordionSelector;
export const TurnoSelectedChips = selectedChips;

// context
// ByIdContext
export const useTurnoByIdContext = useByIdContext;
export const TurnoByIdProvider = ByIdProvider;
export const TurnoByIdContext = ByIdContext;
// GeneralContext
export const useTurnoContext = useGeneralContext;
export const TurnoProvider = GeneralProvider;
export const TurnoContext = GeneralContext;
