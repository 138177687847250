import React, { useMemo } from 'react';
import { useRouteScreenTitle } from '../../../commons/hooks';
import { TabBar } from '../../../commons/components/TabBar';
import { TomaPresentismo } from './TomaPresentismo';
import { PresentismoContextProvider } from './context/Context';
import { AnyObject, Loading, NIVEL, ROL } from 'src/commons';
import { useRolesContext } from 'src/context/RolesContext';
import {
  useAutoRegistracionLibbyFetch,
  useSeccionLibbyFetch,
  useEspacioCurricularLibbyFetch,
  useProyeccionEstadoLibbyFetch,
} from 'src/app/business';
import { BLOCKER_ID, TABBAR_BLOCKER_ID } from 'src/platform/permission/const';
import { PermissionBlocker } from 'src/lib/permission';
import {
  NIVELES_JE,
  ROLES_DOCENTES,
  ROLES_JERARQUICOS,
  ROLES_JERARQUICOS_JORNADA_EXTENDIDA,
  ROLES_JORNADA_EXTENDIDA,
  ROLES_REGULARIDAD,
} from './TomaPresentismo/utils/rolesPresentismo';
import { ConfigurationProvider } from './components/configuration/ConfigurationProvider';
import { Configuration } from './components/configuration';
import { Reportes } from './Reporte';
import { RolBlocker } from 'src/commons/components/Blockers/RolBlocker';
import { Contraturnos } from './Contraturnos';
import { ConfiguracionSituaciones } from './ConfiguracionSituaciones';
import { regexValidator } from '../../../utils/regex';
import { Regularidad } from './Regularidad';

const MATERIAS_JE = [-1, -2];

export const Presentismo = () => {
  useRouteScreenTitle('Presentismo');

  const {
    selectedRole: { nivel, rol, localizacionId },
  } = useRolesContext();

  const filterLocalizacion = useMemo(
    () => ({
      id: [{ path: 'localizacion', value: localizacionId }],
      nivel: [{ path: 'nivel', value: nivel }],
    }),
    [nivel, localizacionId],
  );
  const { data: proyeccionEstado = [], working: workingProyeccionEstado } =
    useProyeccionEstadoLibbyFetch({
      filter: filterLocalizacion,
      orderBy: 'cicloLectivo',
      direction: 'desc',
    });

  const filter = useMemo(() => {
    const filter: AnyObject = {
      rolStatus: [{ path: 'rolStatus.idRolStatus', value: 2 }],
      seccion: [{ path: 'seccion.activo', value: true }],
    };

    if (proyeccionEstado.length) {
      filter.ciclo = [
        {
          path: 'seccion.cicloLectivo',
          value: proyeccionEstado[0]?.cicloLectivo?.anio,
        },
      ];
    }

    return filter;
  }, [proyeccionEstado]);

  const seccionesFilter = useMemo(() => {
    const filter: AnyObject = {
      localizacion: [{ path: 'localizacion', value: localizacionId }],
      nivel: [{ path: 'nivel', value: nivel }],
    };
    if (proyeccionEstado.length) {
      filter.ciclo = [
        {
          path: 'cicloLectivo',
          value: proyeccionEstado[0].cicloLectivo.idCicloLectivo,
        },
      ];
    }
    return filter;
  }, [localizacionId, nivel, proyeccionEstado]);

  const { data = [], working } = useAutoRegistracionLibbyFetch({
    filter: filter,
    aspect: 'limit_localization',
    enabled: ROLES_DOCENTES.includes(Number(rol)),
  });

  const { data: seccionesData = [] } = useSeccionLibbyFetch({
    filter: seccionesFilter,
    aspect: 'presentismo',
    enabled:
      ROLES_JERARQUICOS.includes(Number(rol)) &&
      proyeccionEstado[0]?.cicloLectivo &&
      proyeccionEstado[0]?.cicloLectivo?.idCicloLectivo !== undefined,
    limit: 1000,
  });

  const rolJerarquicoValidation =
    ROLES_JERARQUICOS.includes(Number(rol)) &&
    seccionesData.some(
      (seccion) => seccion.jornada.toLowerCase() === 'extendida',
    );

  const seccionJeValidation = seccionesData.some((seccion) => {
    return seccion.jornada.toLowerCase() === 'extendida';
  });
  // esto dice si es un docente de JE
  const rolDocenteValidation =
    ROLES_DOCENTES.includes(Number(rol)) &&
    data
      .map((autoreg) => autoreg.espacioCurricular)
      .every((ec) => MATERIAS_JE.includes(ec.materia.idMateria));

  const rolJornadaExtendida =
    ROLES_JORNADA_EXTENDIDA.includes(Number(rol)) &&
    data
      .map((autoreg) => autoreg.espacioCurricular)
      .every((ec) => MATERIAS_JE.includes(ec.materia.idMateria));

  const enableJornadaExtendida =
    NIVELES_JE.includes(Number(nivel)) && rolJornadaExtendida;

  const enableJornadaExtendidaDirectorEquipoConduccion =
    rol === ROL.DIRECTIVO.toString() || rol === ROL.EQUIPO_CONDUCCION.toString()
      ? seccionesData.some(
          (seccion) => seccion.jornada.toLowerCase() === 'extendida',
        )
      : true;

  const enableGeneral =
    data
      .map((autoreg) => autoreg.espacioCurricular)
      .some((ec) => ec.materia.idMateria !== -1) ||
    ROLES_JERARQUICOS.filter((rol) => rol !== ROL.COORDINADOR_JE).includes(
      Number(rol),
    ) ||
    ROL.COORDINADOR_JE !== Number(rol);

  const { data: espaciosCurriculares = [] } = useEspacioCurricularLibbyFetch({
    limit: 200,
    aspect: 'sidemenu',
  });

  // const canSeeReport =
  //   ROLES_JERARQUICOS.filter(
  //     (rol) => rol !== ROL.COORDINADOR_JE && rol !== ROL.DIRECCION_AREA,
  //   ).includes(Number(rol)) || ROL.DOCENTE === Number(rol);
  const canSeeReport = false;

  const canSeeSituacionesJerarquicos =
    (Number(rol) === ROL.EQUIPO_CONDUCCION || Number(rol) === ROL.DIRECTIVO) &&
    (Number(nivel) === NIVEL.INICIAL ||
      Number(nivel) === NIVEL.PRIMARIO ||
      Number(nivel) === NIVEL.SECUNDARIO);

  const canSeeSituacionesCoordinadorJE =
    Number(rol) === ROL.COORDINADOR_JE &&
    (Number(nivel) === NIVEL.PRIMARIO || Number(nivel) === NIVEL.SECUNDARIO);

  const materiasJE = espaciosCurriculares.some((ec) =>
    ec.descripcion.toLowerCase().includes('jornada extendida'),
  );

  const rolJerarquico = ROLES_JERARQUICOS.includes(Number(rol));

  const rolJerarquicoJornadaExtendida =
    ROLES_JERARQUICOS_JORNADA_EXTENDIDA.includes(Number(rol));

  const rolRegularidad = ROLES_REGULARIDAD.includes(Number(rol));

  const disabledContraturno =
    rolDocenteValidation || rol.toString() === ROL.COORDINADOR_JE.toString();

  const tabs = useMemo(() => {
    const tabs = [];

    if (enableGeneral && !rolDocenteValidation) {
      tabs.push({
        title: 'Toma de asistencia',
        component: (
          <TomaPresentismo
            isTabJornadaExtendida={false}
            espacioCurricularList={[]}
          />
        ),
        id: TABBAR_BLOCKER_ID.PRESENTISMO_TOMA_GENERAL,
      });
    }

    if (nivel === '3' && !disabledContraturno) {
      tabs.push({
        title: 'Contraturnos',
        component: <Contraturnos />,
        id: TABBAR_BLOCKER_ID.PRESENTISMO_CONTRATURNO,
      });
    }

    if ((materiasJE || seccionJeValidation) && rolJerarquicoJornadaExtendida) {
      tabs.push({
        title: 'Configuración JE',
        component: (
          <ConfigurationProvider>
            <Configuration />
          </ConfigurationProvider>
        ),
        id: TABBAR_BLOCKER_ID.PRESENTISMO_CONFIGURACION,
      });
    }

    if (
      enableJornadaExtendida &&
      enableJornadaExtendidaDirectorEquipoConduccion
    ) {
      tabs.push({
        title: 'Asistencia JE',
        component: (
          <TomaPresentismo
            isTabJornadaExtendida={true}
            espacioCurricularList={[]}
          />
        ),
        id: TABBAR_BLOCKER_ID.PRESENTISMO_JE,
      });
    }

    if (canSeeReport) {
      tabs.push({
        title: 'Reportes',
        component: (
          <RolBlocker
            enableForRoles={[
              ROL.DOCENTE,
              ROL.EQUIPO_CONDUCCION,
              ROL.DIRECTIVO,
              ROL.SUPERVISOR,
            ]}
          >
            <Reportes />
          </RolBlocker>
        ),
        id: TABBAR_BLOCKER_ID.PRESENTISMO_REPORTE,
      });
    }

    if (canSeeSituacionesJerarquicos || canSeeSituacionesCoordinadorJE) {
      tabs.push({
        title: 'Configuración de Situaciones',
        component: (
          <ConfiguracionSituaciones
            materiasJE={materiasJE}
            isPrimariaCoordinadorJE={canSeeSituacionesCoordinadorJE}
          />
        ),
        id: TABBAR_BLOCKER_ID.PRESENTISMO_SITUACIONES,
      });
    }

    if (rolRegularidad) {
      tabs.push({
        title: 'Regularidad',
        component: (
          <PresentismoContextProvider>
            <Regularidad />
          </PresentismoContextProvider>
        ),
        id: TABBAR_BLOCKER_ID.PRESENTISMO_REGULARIDAD,
      });
    }

    return tabs;
  }, [
    enableGeneral,
    rolDocenteValidation,
    nivel,
    disabledContraturno,
    materiasJE,
    seccionJeValidation,
    rolJerarquicoJornadaExtendida,
    enableJornadaExtendida,
    enableJornadaExtendidaDirectorEquipoConduccion,
    canSeeReport,
    canSeeSituacionesJerarquicos,
    canSeeSituacionesCoordinadorJE,
    rolRegularidad,
  ]);

  return (
    <PermissionBlocker id={BLOCKER_ID.PRESENTISMO}>
      <PresentismoContextProvider>
        {!working ? <TabBar content={tabs} /> : <Loading />}
      </PresentismoContextProvider>
    </PermissionBlocker>
  );
};
