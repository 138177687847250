import { useEffect, useRef } from 'react';

export const useInterval = (
  callback: () => Promise<void>,
  delay: number,
  enabled: boolean = true,
) => {
  const savedCallback = useRef<() => void>();
  const id = useRef<any>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    if (!enabled) {
      clearInterval(id.current);
      return;
    }
    const tick = () => {
      if (savedCallback.current) savedCallback.current();
    };
    if (delay !== null) {
      id.current = setInterval(tick, delay);
      return () => clearInterval(id.current);
    }
  }, [delay, enabled]);

  const resetInterval = () => {
    if (!enabled) return;
    clearInterval(id.current);
    const tick = () => {
      if (savedCallback.current) savedCallback.current();
    };
    id.current = setInterval(tick, delay);
  };

  return resetInterval;
};
