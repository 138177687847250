import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';

export default function TablaInscripciones({ asignaturas, handleDelete }) {
  return (
    <Grid style={{ padding: 30, backgroundColor: '#F5F5F5' }}>
      <Grid container>
        <Grid item xs={3}>
          <Typography
            style={{
              fontFamily: 'Open Sans',
              color: 'grey.medium',
              fontSize: 18,
              marginBottom: 30,
            }}
          >
            Año
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{
              fontFamily: 'Open Sans',
              color: 'grey.medium',
              fontSize: 18,
              marginBottom: 30,
            }}
          >
            Sección
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography
            style={{
              fontFamily: 'Open Sans',
              color: 'grey.medium',
              fontSize: 18,
              marginBottom: 30,
            }}
          >
            Espacio Curricular
          </Typography>
        </Grid>
      </Grid>
      <Grid>
        {asignaturas !== [] ? (
          <Grid>
            {asignaturas.map((asignatura, index) => {
              return (
                <Grid key={index} container>
                  <Grid item xs={3}>
                    <Typography
                      style={{
                        fontFamily: 'Open Sans',
                        color: 'grey.medium',
                        fontSize: 18,
                        marginBottom: 30,
                      }}
                    >
                      {asignatura?.espacioCurricular?.descripcion ===
                      'Jornada Extendida'
                        ? asignatura?.section?.anio?.descripcionAnio
                        : asignatura?.espacioCurricular?.materia?.anio
                            ?.descripcionAnio}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      style={{
                        fontFamily: 'Open Sans',
                        color: 'grey.medium',
                        fontSize: 18,
                        marginBottom: 30,
                      }}
                    >
                      {asignatura?.section?.nombreSeccion}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      style={{
                        fontFamily: 'Open Sans',
                        color: 'grey.medium',
                        fontSize: 18,
                        marginBottom: 30,
                      }}
                    >
                      {asignatura?.espacioCurricular?.descripcion}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <DeleteIcon onClick={() => handleDelete(index)} />
                  </Grid>
                </Grid>
              );
            })}
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
}
