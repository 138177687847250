import { ConfirmacionMatriculaGenerateDAO } from 'src/platform/libby/dao/confirmacionMatricula/ConfirmacionMatriculaGenerateDAO';
import { makeBusinessEntity } from '../../../lib/libby/generator';
import { ConfirmacionMatriculaGenerate } from 'src/app/models';

const DAO_NAME = 'confirmacion_matricula_generate';
const {
  hook: { libbyDAO, libbyFetch },
} = makeBusinessEntity<
  ConfirmacionMatriculaGenerate,
  ConfirmacionMatriculaGenerateDAO
>(DAO_NAME, {});

// hooks
export const useConfirmacionMatriculaGenerateDAO = libbyDAO;
export const useConfirmacionMatriculaGenerateLibbyFetch = libbyFetch;
