import React, { useCallback } from 'react';
import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import {
  iconsCardRepositoryTypeItem,
  colorTypeItem,
} from 'src/screens/Private/Repositorio/RepositorioList/const/colorTypeItem';
import { primary } from 'src/theme/colors';
import { RepositorioItem } from '../../../../../../../app/models';

const useStyles = makeStyles((theme) => ({
  titleText: {
    alignSelf: 'flex-start',
    fontSize: 24,
    color: primary.black,
    fontFamily: 'Open Sans',
    fontWeight: 'normal',
  },
  description: {
    marginTop: theme.spacing(1),
  },
  header: {
    paddingBottom: theme.spacing(0),
  },
  divMateria: {
    marginBottom: theme.spacing(1),
  },
  fontFooter: {
    marginTop: theme.spacing(3),
  },
  font: {
    fontWeight: 'bold',
  },
}));

export interface RepositorioItemCardProps {
  item: RepositorioItem;
}

export const RepositorioItemCard = ({ item }: RepositorioItemCardProps) => {
  const onClick = useCallback(() => window.open(item.url, 'blank'), [item.url]);
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <Card raised>
        <CardActionArea onClick={onClick}>
          <CardHeader
            className={classes.header}
            avatar={
              iconsCardRepositoryTypeItem[
                item.repositorioTipoItem.idRepositorioTipoItem
              ]
            }
          />
          <CardContent>
            <div className={classes.divMateria}>
              <span
                className={classes.font}
                style={{
                  color: colorTypeItem[item?.tipoRecurso?.idTipoRecurso],
                }}
              >
                {item?.espacioCurricular?.descripcion}
              </span>
            </div>
            <Typography className={classes.titleText}>{item.nombre}</Typography>
            <Typography className={classes.description}>
              {item.descripcion}
            </Typography>
            <div>
              <div className={classes.fontFooter}>
                <Typography className={classes.font} variant="caption">
                  Año:
                  {item.fuente_anio}| Fuente:
                  {item.fuente}
                </Typography>
              </div>
            </div>
          </CardContent>
        </CardActionArea>
        <Grid
          style={{
            backgroundColor: colorTypeItem[item?.tipoRecurso?.idTipoRecurso],
            height: 10,
          }}
        />
      </Card>
    </Grid>
  );
};
