import React, { FC, useEffect, useMemo } from 'react';
import { grey } from 'src/theme/colors';
import { makeStyles } from '@material-ui/core/styles';
import { useField } from 'react-final-form-hooks';
import { FormMultiCheckbox } from 'src/lib/templates/components';
import { usePlanificationFormDisciplinarOrientada } from '../../hooks';
import { useDisciplinarOrientadaContext } from '../../Context';
import { SimpleSelect } from 'src/commons';
import {
  INITIAL_YEAR,
  useCicloLectivoLibbyFetch,
  useProyeccionEstadoLibbyFetch,
} from 'src/app/business';
import { useRolesContext } from 'src/context/RolesContext';

const customStyles = {
  formContainer: {
    padding: 16,
    margin: '20px 0',
  },
  listItem: {
    padding: 0,
  },
  listItemIcon: {
    marginRight: '-20px',
  },
  text: {
    color: grey.medium,
  },
};

const useStyles = makeStyles(customStyles);

export const Bloque: FC = () => {
  const { form } = usePlanificationFormDisciplinarOrientada();
  const { data } = useDisciplinarOrientadaContext();

  const fieldEnsenanzas = useField('ensenanza.propositosDeEnsenanza', form);
  const fieldObjetivos = useField(
    'objetivosDeAprendizaje.objetivosDeAprendizaje',
    form,
  );
  const fieldIndicadores = useField(
    'indicadoresEvaluacion.indicadoresEvaluacion',
    form,
  );

  const onchangeBloques = (newValor: Array<string>) => {
    const addBloque = newValor.map((bloq: string) => ({
      bloque: bloq,
    }));
    fieldEnsenanzas.input.onChange(addBloque);
    fieldObjetivos.input.onChange(addBloque);
    fieldIndicadores.input.onChange(addBloque);
  };

  const { selectedRole } = useRolesContext();

  const filterProyeccionEstado = useMemo(
    () => ({
      0: [{ path: 'localizacion', value: selectedRole.localizacionId }],
    }),
    [selectedRole.localizacionId],
  );

  const { data: proyeccionEstado = [] } = useProyeccionEstadoLibbyFetch({
    limit: 1,
    filter: filterProyeccionEstado,
    orderBy: 'cicloLectivo',
    direction: 'desc',
  });

  useEffect(() => {
    form.change(
      'bloque.cicloLectivo',
      proyeccionEstado[0]?.cicloLectivo?.idCicloLectivo,
    );
  }, [proyeccionEstado, form]);

  return (
    <>
      <FormMultiCheckbox
        name="bloque.bloques"
        // @ts-ignore
        options={data?.bloques}
        form={form}
        idKey="idBloque"
        onChange={(valor: Array<string>) => onchangeBloques(valor)}
      />
    </>
  );
};
