import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLibby } from '@phinxlab/libby-rest-web';
import { AnyObject, usePrevious } from 'src/commons';
import { useLibbyCall } from './useLibbyCall';
import { TokenManager } from 'src/platform/libby/TokenManager';
import { API_URL } from 'src/config';

export enum REQUEST_TYPES {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  DELETE = 'delete',
}

const methodTypes: any = {
  get: 'get',
  post: 'post',
  put: 'put',
  delete: 'delete',
};

export interface LibbyRequestOptions {
  type: string;
  daoName: string;
  autoCall?: boolean;
  aspect?: string;
  params?: any[];
  url?: string;
}

export interface LibbyRequestReturn<T> {
  error?: { existError: boolean; error: string };
  data?: T;
  working: boolean;
  request: (...args: any[]) => Promise<T>;
  setResponse: Dispatch<SetStateAction<any[]>>;
}

const useTokenManager = () => {
  const tokenManager = TokenManager.create();
  const [token, setToken] = useState<any>('');

  const getToken = useCallback(async () => {
    const newToken = await tokenManager.retrieve();
    setToken(newToken);
  }, [tokenManager]);

  useEffect(() => {
    getToken();
  }, [getToken]);

  return token;
};

const useFetch = ({ url, autoCall, aspect }: any) => {
  const token = useTokenManager();
  const [data, setResponse] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [working, setWorking] = useState(false);

  const makeRequest = useCallback(async () => {
    if (token) {
      setWorking(true);
      await fetch(`${API_URL}${url}`, {
        headers: {
          'Content-Type': 'application/json',
          'x-chino-token': token,
          'x-chino-aspect': aspect,
        },
      })
        .then(async (res) => {
          if (res.status >= 400) {
            throw await res.json();
          }
          return res.json();
        })
        .then((response) => setResponse(response))
        .catch((error) => setError(error));
      setWorking(false);
    }
  }, [aspect, token, url]);

  const response = useMemo(
    () => ({ data, error, working, recall: makeRequest, setResponse }),
    [makeRequest, data, error, working, setResponse],
  );

  useEffect(() => {
    if (autoCall) {
      makeRequest();
    }
  }, [makeRequest, autoCall]);

  return response;
};

export const useRequest = ({
  type,
  daoName,
  aspect,
  autoCall = false,
  params = [],
  url = '',
}: LibbyRequestOptions) => {
  const methodType: any = methodTypes[type];
  if (methodType === undefined)
    throw new Error(
      'method type not found, options are: get, post, put, delete',
    );
  if (methodType !== REQUEST_TYPES.GET && url)
    throw new Error('url param is only available for get method');

  const { data, working, recall } = useLibbyCall<any>({
    daoName: daoName,
    methodName: methodType,
    params,
    noAutoCall: url ? true : !autoCall,
    aspect: aspect,
  });
  // FOR GET REQUESTS
  const {
    working: fetchWorking,
    data: fetchData,
    recall: fetchRecall,
    error,
    setResponse,
  } = useFetch({ url, autoCall, aspect });

  const api = useMemo(() => {
    let responseObj = {};
    if (url) {
      responseObj = {
        working: fetchWorking,
        data: fetchData,
        request: fetchRecall,
        error,
        setResponse,
      };
    } else {
      responseObj = { data, working, request: recall, setResponse };
    }
    return responseObj;
  }, [
    url,
    fetchWorking,
    fetchData,
    fetchRecall,
    error,
    data,
    working,
    recall,
    setResponse,
  ]);

  return api;
};
