import React, { useMemo } from 'react';
import { useGruposLibbyFetch } from '../../../../../app/business';
import { useOrderByState } from '../../../../../commons/hooks';
import { InfoTable } from '../../../../../commons/components/InfoTable';
import { useAulasVirtualesGrupoTable } from './hooks';
import { EspacioCurricular } from '../../../../../app/models';

interface AulasVirtualesGrupoTableProps {
  espacioCurricular?: EspacioCurricular;
}

export const AulasVirtualesGrupoTable = ({
  espacioCurricular,
}: AulasVirtualesGrupoTableProps) => {
  const { orderBy, direction, onOrderByDirectionChange } =
    useOrderByState('nombre');

  const fixedFilter = useMemo(
    () => ({
      fixedEspacio: [
        {
          path: 'gruposEspacios.espacioCurricular',
          value: espacioCurricular?.idEspacioCurricular,
        },
      ],
    }),
    [espacioCurricular],
  );

  const {
    data: grupos = [],
    working,
    fetchMore,
    reFetch,
  } = useGruposLibbyFetch({
    orderBy,
    direction,
    fixedFilter,
  });

  const columns = useAulasVirtualesGrupoTable(reFetch, espacioCurricular);

  return (
    <>
      <InfoTable
        rows={grupos}
        columns={columns}
        working={working}
        rowIdKey="idGrupo"
        direction={direction}
        orderBy={orderBy}
        onSortChange={onOrderByDirectionChange}
        onBottomScroll={fetchMore}
      />
    </>
  );
};
