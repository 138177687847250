import { Box, IconButton, Tooltip } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React from 'react';
import confirmDialog from 'src/commons/services/confirmDialog';
import { primary } from 'src/theme/colors';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

import { useContraturnoDAO } from 'src/app/business/presentismo_v3/Contraturno';
import '../styles/fonts.css';
import { useRolesContext } from 'src/context/RolesContext';
import { ROL } from 'src/commons';
import {
  ActionContraturno,
  Filter,
  PresentismoCustomEndpoint,
  contraturnoPorDias,
} from '../Types';

export const useColumnsContratuno = (
  setAccionContraturno: React.Dispatch<React.SetStateAction<ActionContraturno>>,
  setFilterContraturno: React.Dispatch<React.SetStateAction<Filter>>,
  reFetchContraturno: () => void,
  presentes: PresentismoCustomEndpoint[],
  presentesWorking: boolean,
) => {
  const { enqueueSnackbar } = useSnackbar();
  const customStyles = {
    iconColor: { color: '#3786EE' },
    iconDisabled: { color: primary.disabled },
  };

  const headerStyle = {
    fontFamily: 'Nunito-bold',
    fontStyle: 'italic',
    color: '#38485C',
    fontSize: '16px',
    margin: 0,
    fontWeight: 'normal',
  };

  const headerStyle2 = {
    fontFamily: 'Nunito-mediumItalic',
    fontStyle: 'italic',
    color: '#38485C',
    fontSize: '14px',
    margin: 0,
    fontWeight: 'normal',
  };

  const colorColums = {
    backgroundColor: '#FFFFFF',
  };

  //const { data: presentismo } = usePresentismo

  const contraturnoDAO = useContraturnoDAO();

  const handleDelecte = async (row: any, existeContraturno: boolean) => {
    const confirm = await confirmDialog.show({
      title: 'Confirmación de Eliminación',
      content: existeContraturno
        ? `Usted está intentando eliminar un contraturno con asistencias, ¿Desea continuar?`
        : `¿Desea eliminar el contraturno de la seccion ´${row.idSeccion.nombreSeccion}´?`,

      confirmText: 'Eliminar',
      cancelText: 'Cancelar',
    });
    if (confirm) {
      try {
        await contraturnoDAO.save({
          idContraturno: row.idcontraturno,
          active: false,
        });
        reFetchContraturno();
        enqueueSnackbar('Se ha eliminado con exito', {
          variant: 'success',
        });
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Error al eliminar', {
          variant: 'error',
        });
      }
    }
  };

  const handelEdit = async (row: any) => {
    const confirm = await confirmDialog.show({
      title: 'Confirmación de Edición',
      content: `Usted esta intentando editar un contraturno con asistencias, solo podra editar la "Fecha Hasta". ¿Desea continuar?`,
      confirmText: 'Continuar',
      cancelText: 'Cancelar',
    });
    if (confirm) {
      try {
        setFilterContraturno(row);

        setAccionContraturno({
          openModalAction: true,
          disableSelectAction: true,
          title: 'Editar Contraturno',
          presentismo: true,
        });
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Error al editar', {
          variant: 'error',
        });
      }
    }
  };

  const {
    selectedRole: { rol, nivel },
  } = useRolesContext();
  const permitionROL =
    Number(rol) === ROL.DIRECTIVO || Number(rol) === ROL.EQUIPO_CONDUCCION;

  const columnPrincipal = [
    {
      id: 'seccion',
      label: 'Sección',
      width: '10%',
      noSort: true,
      hideSortIcon: true,
      headCellsStyles: colorColums,
    },
    {
      id: 'esc1',
      label: (
        <div>
          <p style={headerStyle}>Espacio Curricular</p>
          <p style={headerStyle2}>Contraturno 1</p>
        </div>
      ),
      width: '10%',
      hideSortIcon: true,
      textAlign: 'center',
      render: (value: any, row: any) => {
        const contraturnos1 = Object.values(row.objContraturnoDia)
          .map((materia: any) => materia.esc1?.descripcion)
          .filter((e: any) => e !== undefined)
          .map((materia: any) => <li>{materia}</li>);
        return (
          <ul style={{ listStyle: 'none', marginLeft: '-40px' }}>
            {contraturnos1}
          </ul>
        );
      },
      headCellsStyles: colorColums,
    },
    {
      id: 'esc2',
      label: (
        <div>
          <p style={headerStyle}>Espacio Curricular</p>
          <p style={headerStyle2}>Contraturno 2</p>
        </div>
      ),
      width: '10%',
      hideSortIcon: true,
      render: (value: any, row: any) => {
        const contraturnos1 = Object.values(row.objContraturnoDia)
          .map((materia: any) => materia.esc2?.descripcion)
          .filter((e: any) => e !== undefined)
          .map((materia: any) => <li>{materia}</li>);
        return (
          <ul style={{ listStyle: 'none', marginLeft: '-40px' }}>
            {contraturnos1}
          </ul>
        );
      },
      headCellsStyles: colorColums,
    },
    {
      id: 'dias',
      label: 'Días',
      width: '10%',
      hideSortIcon: true,
      render: (value: any, row: any) => {
        const dias = Object.keys(row.objContraturnoDia)
          .filter((e) => row.objContraturnoDia[e].esc1)

          .map((dia: string) => (
            <li>{dia === 'Miercoles' ? 'Miércoles' : dia}</li>
          ));

        return (
          <ul style={{ listStyle: 'none', marginLeft: '-40px' }}>{dias}</ul>
        );
      },
      headCellsStyles: colorColums,
    },
    {
      id: 'fechaInicio',
      label: 'Fecha inicio',
      width: '10%',
      hideSortIcon: true,
      headCellsStyles: colorColums,
    },
    {
      id: 'fechaFin',
      label: 'Fecha fin',
      width: '10%',
      hideSortIcon: true,
      headCellsStyles: colorColums,
    },
    {
      id: 'icono',
      label: 'Acción',
      width: '2%',
      //   orderById: '',
      hideSortIcon: true,
      noSort: true,
      style: { textAlign: 'right' },
      headCellsStyles: colorColums,
      render: (value: any, row: any) => {
        return (
          <Box display="flex" justifyContent="center">
            <Box p={1}>
              <Tooltip title="Editar">
                <IconButton
                  style={
                    presentesWorking
                      ? customStyles.iconDisabled
                      : customStyles.iconColor
                  }
                  onClick={() => {
                    const existePresentes = presentes.some(
                      (item: PresentismoCustomEndpoint) =>
                        item.id_seccion.toString() ===
                          row.idSeccion.idSeccion.toString() &&
                        item.existe_registro === true &&
                        row.contraturnoDias.some(
                          (dia: contraturnoPorDias) =>
                            dia.idContraturnoDia === item.id_contraturno_dia,
                        ),
                    );

                    if (existePresentes) {
                      handelEdit(row);
                    } else {
                      setFilterContraturno(row);
                      setAccionContraturno({
                        openModalAction: true,
                        disableSelectAction: true,
                        title: 'Editar Contraturno',
                        nuevoContraturno: false,
                      });
                    }
                  }}
                  disabled={presentesWorking}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Box>
            <Box p={1}>
              <Tooltip title="Eliminar">
                <IconButton
                  style={
                    permitionROL && !presentesWorking
                      ? customStyles.iconColor
                      : customStyles.iconDisabled
                  }
                  onClick={() => {
                    const existePresentes = presentes.some(
                      (item: PresentismoCustomEndpoint) =>
                        item.id_seccion.toString() ===
                          row.idSeccion.idSeccion.toString() &&
                        item.existe_registro === true &&
                        row.contraturnoDias.some(
                          (dia: contraturnoPorDias) =>
                            dia.idContraturnoDia === item.id_contraturno_dia,
                        ),
                    );
                    handleDelecte(row, existePresentes);
                  }}
                  disabled={!permitionROL}
                >
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        );
      },
    },
  ];
  return columnPrincipal;
};
