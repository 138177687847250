import { useSnackbar } from 'notistack';
import { useCallback, useState, useMemo } from 'react';
import { useRecuperacionesstatePostRequest } from 'src/app/business/businessCustomEndpoints/recuperacionesstate';
import { DEFAULT_HIDE_TIME } from 'src/commons/const/ui';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useRolesContext } from 'src/context/RolesContext';

type params = {
  ecs?: string;
  filter?: any;
  reFetch?: any;
};

export const useRecuperacionSwitch = ({ filter, reFetch, ecs }: params) => {
  const [switchSubmitting, setSwitchSubmitting] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectedRole: { rol },
  } = useRolesContext();

  const content = useMemo(
    () =>
      rol === '1'
        ? `Una vez cerradas las calificaciones no podrán ser modificadas nuevamente por un docente, solo podrán modificarse nuevamente por un directivo ¿Desea continuar?`
        : `Una vez iniciado el proceso de cierre de calificaciones el mismo no puede revertirse, para ello contáctese con el directivo según corresponda. ¿Desea continuar?`,
    [rol],
  );

  const { request } = useRecuperacionesstatePostRequest();

  const onSwitch = useCallback(
    async (openOrClose) => {
      const isOpen = openOrClose === 'open';
      const confirm = isOpen
        ? true
        : await confirmDialog.show({
            title: '¿Desea continuar?',
            content,
            confirmText: 'Cerrar',
            cancelText: 'Salir',
          });
      if (confirm) {
        try {
          setSwitchSubmitting(true);
          const rowsTo = {
            espacioCurricularSeccion: ecs ?? filter.espacioCurricularSeccion,
            abierto: isOpen,
          };

          await request(rowsTo);
          reFetch && reFetch();
          if (isOpen) {
            enqueueSnackbar('¡Las calificaciones fueron abiertas con éxito!', {
              variant: 'success',
              autoHideDuration: DEFAULT_HIDE_TIME,
            });
          } else if (openOrClose === 'close') {
            enqueueSnackbar('¡Las calificaciones fueron cerradas con éxito!', {
              variant: 'success',
              autoHideDuration: DEFAULT_HIDE_TIME,
            });
          }
          setSwitchSubmitting(false);
        } catch (e) {
          console.log(e);
          setSwitchSubmitting(false);
        }
      }
    },
    [ecs, enqueueSnackbar, filter, reFetch, content, request],
  );

  return { onSwitch, switchSubmitting };
};
