import React, { useMemo, useCallback, useState } from 'react';
import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import { useObservacionesFormContext } from '../hooks';
import { FormInputV2 } from '../../../../../../../lib/templates';
import { ASISTENCIA_GENERAL, TURNOS } from '../../PresentismoTable/components';
import { FormRadioGroup } from '../../../../../../../lib/templates/components/FormRadioGroup';
import moment from 'moment';
import customFormDialog from '../../../../../../../commons/services/customFormDialog';
import { AlumnoMap } from '../../../types';

interface ObservacionesFormProps {
  turnoSeccion: number;
  currentWeek: moment.Moment;
  alumno: AlumnoMap;
  daySelected: string;
  hasJornadaExtendidaDisabled: boolean;
  isDisabledReportModal?: boolean;
  selectedEspacioCurricular?: string;
}

const useStyles = makeStyles({
  selectLabel: {
    fontSize: 16,
    letterSpacing: 0,
    color: '#38485C',
    fontWeight: 'bold',
    fontFamily: 'Nunito',
  },
  selectInput: {
    backgroundColor: '#EEF5FC',
    border: 'none',
    //  height: 38,
    '& :fieldset': {
      border: 'none',
    },
  },
  selectBg: {
    backgroundColor: '#EEF5FC',
  },
  rootInput: {
    border: 'none',
    '& > fieldset': {
      border: 'none',
    },
  },
  textAreaLabel: {
    fontSize: 15,
    letterSpacing: 0,
    color: '#38485C',
    fontWeight: 'bold',
    fontFamily: 'Nunito',
    top: -11,
  },
  button: {
    textDecoration: 'none',
  },
});

export interface DaysWeek {
  id: number;
  label: string;
  value: string;
}

export const ObservacionesForm = ({
  turnoSeccion,
  daySelected,
  hasJornadaExtendidaDisabled,
  isDisabledReportModal = false,
  selectedEspacioCurricular,
}: ObservacionesFormProps) => {
  const classes = useStyles();
  const { form, values, errors, initialValues, handleSubmit } =
    useObservacionesFormContext();

  const [presenteIndx, setPresenteIndx] = useState(0);

  const disabled = useMemo(() => {
    const { selectedTurno: xT, ...rest } = initialValues;
    const { selectedTurno, ...valuesRest } = values;

    return (
      Object.keys(errors as any).length !== 0 ||
      JSON.stringify(rest) === JSON.stringify(valuesRest)
    );
  }, [initialValues, values, errors]);

  const { options } = useMemo(() => {
    return {
      turnos: TURNOS[turnoSeccion],
      options: selectedEspacioCurricular
        ? ASISTENCIA_GENERAL
        : TURNOS[turnoSeccion].map((turno) => ({
            ...turno,
            disabled: !values.presentes.find(
              (v) => v.idTurno === turno.idTurno.toString(),
            ),
          })),
    };
  }, [turnoSeccion, values, selectedEspacioCurricular]);

  const handleChange = useCallback(
    (turno: string) => {
      const index = values.presentes.findIndex((e) => e.idTurno === turno);
      setPresenteIndx(index);
    },
    [values.presentes],
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={6}>
            <Box fontFamily="Nunito" fontSize={15} fontWeight="bold">
              {daySelected}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <FormRadioGroup
              row
              label={
                <Box
                  fontSize={13}
                  color="#38485C"
                  fontFamily="Nunito,Open Sans, sans-serif"
                  fontWeight="bold"
                >
                  Turno
                </Box>
              }
              name="selectedTurno"
              labelKey="title"
              valueKey="idTurno"
              options={options}
              form={form}
              optionToValue={(value, options) => {
                handleChange(value);
                return value;
              }}
              valueToOption={(value, options) => {
                return value;
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormInputV2
              label="Observaciones"
              name={`presentes[${presenteIndx}].observacion`}
              form={form}
              multiline
              placeholder="detalle"
              minRows={12}
              disabled={isDisabledReportModal || values.selectedTurno === ''}
              fullWidth
              InputLabelProps={{
                shrink: true,
                className: classes.textAreaLabel,
              }}
              InputProps={{
                className: classes.selectInput,
                classes: { root: classes.rootInput },
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Grid
          container
          style={{ paddingTop: 16, paddingBottom: 16 }}
          direction="row"
          spacing={2}
          justifyContent="flex-end"
        >
          <Grid item>
            <Button
              className={classes.button}
              variant="text"
              color="primary"
              onClick={() => customFormDialog.handleCancel()}
            >
              Cancelar
            </Button>
          </Grid>
          {isDisabledReportModal ? (
            <></>
          ) : (
            <Grid item>
              <Button
                className={classes.button}
                onClick={() => handleSubmit()}
                variant="contained"
                color="primary"
                disabled={disabled || hasJornadaExtendidaDisabled}
              >
                Guardar
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
