import React, { FunctionComponent, useEffect, useState } from 'react';
import { ROL, NIVEL } from 'src/commons/const';
import { DatabaseStore } from './platform/libby/DatabaseStore';
import { IMiEscuelaUser, SessionChangeEmitter } from './platform/libby/session';
import { PhinxApp } from './lib/profiles/PhinxApp';
import { useProfileContext } from './lib/profiles/ProfileContext';
import { useRolesContext } from './context/RolesContext';
import { Loading } from './commons/components/Loading';
import { usePermissionContext } from './lib/permission/permissionContext';
import { ModalidadType } from './app/models';

// Initialize Libby
DatabaseStore.init();

export const App: FunctionComponent = () => {
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [initialProfileLoaded, setInitialProfileLoaded] = useState(false);
  const [isLogged, setIsLogged] = useState<boolean | undefined>(undefined);
  const { setProfile } = useProfileContext();
  const { selectedRole, setSelectedRole } = useRolesContext();
  const { activateRulesSet, rulesSets, desactivateAllRulesSets } =
    usePermissionContext();

  useEffect(() => {
    const subscriptor = ({
      isLogged: logged,
      user,
    }: {
      isLogged: boolean;
      user: IMiEscuelaUser;
    }) => {
      if (user.groupSelected) {
        const idRol = Number(user.groupSelected.rol.id);
        const { idNivel } = user.groupSelected.nivel;
        desactivateAllRulesSets();
        activateRulesSet(`rol-${idRol}`);
        activateRulesSet(`nivel-${idNivel}`);

        if (idRol === ROL.DOCENTE && idNivel === NIVEL.SECUNDARIO) {
          activateRulesSet(`custom-1-rol-${idRol}-nivel-${idNivel}`);
        }

        if (idRol === ROL.DIRECCION_AREA && idNivel !== NIVEL.INICIAL) {
          activateRulesSet(`rol-${idRol}-no-inicial`);
        }
        if (idRol === ROL.SUPERVISOR && idNivel === NIVEL.INICIAL) {
          activateRulesSet(`rol-${idRol}-nivel-${idNivel}`);
        }
        if (user.groupSelected.modalidad)
          activateRulesSet(`modalidad-${user.groupSelected.modalidad}`);
        if (!user.groupSelected.localizacion.isFuture) {
          activateRulesSet('no-future');
        }
        setSelectedRole({
          idUsuarioRol: user.groupSelected.id,
          rol: user.groupSelected.rol.id,
          localizacionId: user.groupSelected.localizacion.id,
          localizacion: user.groupSelected.localizacion.name,
          nivel: user.groupSelected.nivel.idNivel.toString(),
          modalidad:
            Number(user.groupSelected.modalidad) || ModalidadType.Default,
          tipoEstablecimiento: user.groupSelected.tipoEstablecimiento,
          isFuture: !!user.groupSelected.localizacion.isFuture,
          region: user.groupSelected.region,
          escalafon: user.groupSelected.escalafon,
        });
      } else {
        desactivateAllRulesSets();
      }
      setIsLogged(logged);
      setTimeout(() => setIsFirstLoading(false), 0);
    };
    SessionChangeEmitter.subscribe(subscriptor);

    return () => SessionChangeEmitter.unsubscribe(subscriptor);
  }, [setSelectedRole, activateRulesSet, desactivateAllRulesSets, rulesSets]);

  useEffect(() => {
    if (!isFirstLoading) {
      if (!isLogged) {
        setProfile('public');
      } else if (
        selectedRole?.localizacion &&
        selectedRole.rol !== '12' /* Equipo ACAP goes with sinEstablecimiento */
      ) {
        setProfile('private');
      } else {
        setProfile('sinEstablecimiento');
      }
      setInitialProfileLoaded(true);
    }
  }, [isFirstLoading, isLogged, selectedRole, setProfile]);

  return !initialProfileLoaded ? <Loading /> : <PhinxApp />;
};
