import {
  makeEntityFetchById,
  makeEntityLibbyCall,
  makeEntityLibbyDAO,
  makeEntityLibbyFetch,
} from '../hooks';

export const makeBusinessHooks = <T, DAO>(daoName: string) => {
  const fetchById = makeEntityFetchById<T>(daoName);
  const libbyCall = makeEntityLibbyCall<T>(daoName);
  const libbyDAO = makeEntityLibbyDAO<DAO>(daoName);
  const libbyFetch = makeEntityLibbyFetch<T>(daoName);

  return {
    fetchById,
    libbyCall,
    libbyDAO,
    libbyFetch,
  };
};
