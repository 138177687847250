import { ROL } from 'src/commons/const/rol';
import { LibbyFetchDAO } from './LibbyFetchDAO';
import { ROL_STATUS } from '../../../commons/const/rolStatus';

export class AutoRegistracionDAO extends LibbyFetchDAO {
  constructor() {
    super('autoregistracion', 'idAutoregistracion');
  }

  getByNivelRoles(userId: number, nivelId: number, localizacionId: number) {
    return this.aspect('login')
      .query()
      .equals('usuario', userId)
      .and()
      .equals('nivel', nivelId)
      .and()
      .equals('localizacion', localizacionId)
      .and()
      .notEquals('rolUsuario', ROL.DOCENTE)
      .and()
      .notEquals('rolUsuario', ROL.COORDINADOR_DE_AREA)
      .and()
      .groupStart()
      .equals('rolStatus', ROL_STATUS.PENDIENTE)
      .or()
      .equals('rolStatus', ROL_STATUS.APROBADO)
      .groupEnd()
      .run();
  }

  getPendingAndApproveRoles(
    userId: number,
    rolId: number,
    localizacionId: number,
  ) {
    return this.aspect('loginPendientes')
      .query()
      .equals('usuario', userId)
      .and()
      .equals('rolUsuario', rolId)
      .and()
      .equals('localizacion', localizacionId)
      .and()
      .groupStart()
      .equals('rolStatus', ROL_STATUS.PENDIENTE)
      .or()
      .equals('rolStatus', ROL_STATUS.APROBADO)
      .groupEnd()
      .run();
  }

  getApproveRol(userId: number, rolId: number, localizacionId: number) {
    return this.query()
      .equals('usuario', userId)
      .and()
      .equals('rolUsuario', rolId)
      .and()
      .equals('localizacion', localizacionId)
      .and()
      .equals('rolStatus', ROL_STATUS.APROBADO)
      .run();
  }

  getPendingRequests(userId: string | number) {
    return this.query()
      .equals('usuario', userId)
      .and()
      .equals('rolStatus', ROL_STATUS.PENDIENTE)
      .run();
  }
}
