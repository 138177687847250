import { Template } from '../../../../lib/templates';

const relationIds = ['idRepositorioTipoItem', 'idNivel', 'idCiclo', 'idAnio'];

export const repositorioItemTemplate: Template = {
  name: 'Repositorio Item ABM',
  template: [
    {
      name: 'nombre',
      type: {
        name: 'string',
      },
    },
    {
      name: 'descripcion',
      type: {
        name: 'string',
      },
    },
    {
      name: 'fuente',
      type: {
        name: 'string',
      },
      optional: true,
    },
    {
      name: 'fuente_anio',
      type: {
        name: 'string',
      },
      optional: true,
    },
    {
      name: 'url',
      type: {
        name: 'string',
      },
    },
    {
      name: 'idRepositorioAudienciaItem',
      type: {
        name: 'number',
      },
      optional: true,
    },
    {
      name: 'idEspacioCurricular',
      type: {
        name: 'number',
      },
      optional: true,
    },
    {
      name: 'idArea',
      type: {
        name: 'number',
      },
      optional: true,
    },
    {
      name: 'idTransversal',
      type: {
        name: 'number',
      },
      optional: true,
    },
    {
      name: 'idTipoRecurso',
      type: {
        name: 'number',
      },
      optional: true,
    },
    ...relationIds.map((name) => ({
      name,
      type: {
        name: 'number',
      },
    })),
  ],
};
