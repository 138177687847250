import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { CicloAdultos } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'ciclo_adultos';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idCicloAdultos',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idCicloAdultos',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.CicloAdultos
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.CicloAdultos,
      hoc: { ...defaultOptions.hoc, ...businessOptions.CicloAdultos.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<CicloAdultos>(DAO_NAME, optionsToUse);

// hooks
export const useCicloAdultosFetchById = fetchById;
export const useCicloAdultosLibbyCall = libbyCall;
export const useCicloAdultosDAO = libbyDAO;
export const useCicloAdultosLibbyFetch = libbyFetch;

// components/hoc
export const CicloAdultosAutocomplete = entityAutocomplete;
export const CicloAdultosCall = renderPropCall;
export const CicloAdultosById = renderPropFetchById;
export const CicloAdultosSelect = simpleSelect;
export const CicloAdultosAccordionSelector = accordionSelector;
export const CicloAdultosSelectedChips = selectedChips;

// context
// ByIdContext
export const useCicloAdultosByIdContext = useByIdContext;
export const CicloAdultosByIdProvider = ByIdProvider;
export const CicloAdultosByIdContext = ByIdContext;
// GeneralContext
export const useCicloAdultosContext = useGeneralContext;
export const CicloAdultosProvider = GeneralProvider;
export const CicloAdultosContext = GeneralContext;
