import { MenuItem } from '../../../../commons/const/ui/menu';
import { EspaciosCurriculares } from '../../../../screens/Private/EspaciosCurriculares';
import { routes } from './routes';

export const espaciosCurricularesMenu: MenuItem = {
  key: 'espacioscurriculares',
  basePath: '/espacioscurriculares',
  enabled: true,
  icon: 'List',
  text: 'EspaciosCurriculares',
  children: routes,
  default: true,
};
