import moment from 'moment';
import {
  PropuestaJE,
  PropuestaJEDia,
} from '../../../../../app/models/presentismo_v2';

export const getEspacioJE = (
  fecha: string,
  section: string,
  localizacion: string,
  propuestas: PropuestaJE[],
) => {
  const propuesta = propuestas.find(
    (propuesta) => propuesta.seccion.idSeccion === section,
  );
  let espacio = '';
  const dia = propuesta?.dias.find(
    (propuestaDia: PropuestaJEDia) =>
      propuestaDia.dia.nombre.toLowerCase() === moment(fecha).format('dddd'),
  );
  if (dia?.sede === null) {
    espacio = localizacion;
  } else if (Boolean(dia?.sede)) {
    espacio = dia!.sede!.nombre;
  }
  return espacio;
};
