import { FormDialog } from '../../base';
import theme from '../../../../../theme';
import {
  RadioButtonsForm,
  RadioButtonsFormValue,
  RadioButtonsFormMetadata,
} from './RadioButtonsForm';

export const RadioButtonsDialog = FormDialog.create<
  RadioButtonsFormValue,
  RadioButtonsFormMetadata<RadioButtonsFormValue>
>({
  customTheme: theme,
  component: RadioButtonsForm,
  title: 'Configurar title',
});
