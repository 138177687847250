import React, { FC, useMemo } from 'react';
import cx from 'classnames';
import {
  Typography,
  TableBody,
  Paper,
  TableRow,
  Table,
  TableContainer,
  Grid,
} from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import { makeStyles } from '@material-ui/styles';
import { loading } from 'src/assets/images';
import { InfoTableProps } from 'src/commons/types';
import _ from 'lodash';
import { EnhancedTableHead } from './components/EnhancedTableHead';
import { grey } from '../../../theme/colors';

import { DRAWER_WITH_CLOSE } from '../../const/ui/sideMenu';
import { useTableOperations } from './hooks/useTableOperations';

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: '15px',
  },
  tableContainer: {
    maxHeight: '450px',
    maxWidth: `calc(100vw - ${DRAWER_WITH_CLOSE}px - 30px)`,
  },
  search: {
    display: 'flex',
    justifyContent: 'center',
  },
  caption: {
    height: '15px',
  },
  typography: {
    fontSize: 13,
    color: grey.dark,
    textOverflow: 'true',
  },
  arrayContainer: {
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  seccionNames: {
    '&:hover': {
      fontWeight: 600,
    },
  },
  textCenter: {
    textAlign: 'center',
  },
});

const InfoTableRaw: FC<InfoTableProps> = ({
  columns,
  rows,
  onBottomScroll,
  onRowClick,
  rowIdKey = 'id',
  onSortChange,
  orderBy,
  direction: order = 'asc',
  working,
  customStyle,
  customClassnameTypography,
  paperCustomStyle,
  headerAlign = 'left',
  message = 'No hay datos para esta solicitud',
}: InfoTableProps) => {
  const classes = useStyles();
  const { handleScroll, handleRequestSort } = useTableOperations(
    order,
    onSortChange,
    onBottomScroll,
  );

  const _columns = useMemo(
    () => columns.filter((column) => !column.hideColumn),
    [columns],
  );

  return (
    <>
      <Paper className={`${classes.root} ${paperCustomStyle}`}>
        <TableContainer
          className={`${classes.tableContainer} ${customStyle}`}
          onScroll={handleScroll}
        >
          <Table stickyHeader aria-label="sticky table">
            <EnhancedTableHead
              headerAlign={headerAlign}
              headCells={_columns}
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={!rows ? 0 : rows.length}
            />
            <TableBody>
              {rows.length > 0 &&
                rows.map((row: any) => (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={row[rowIdKey]}
                    onClick={onRowClick ? () => onRowClick(row) : undefined}
                    style={{ ...row.customStyles }}
                  >
                    <>
                      {_columns.map((column) => {
                        const value = _.get(row, column.id);
                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            style={{ ...column.customStyles }}
                          >
                            <Typography
                              component="span"
                              className={cx(
                                customClassnameTypography,
                                classes.typography,
                              )}
                            >
                              {column.render
                                ? column.render(value, row)
                                : value}
                            </Typography>
                          </TableCell>
                        );
                      })}
                    </>
                  </TableRow>
                ))}
              {rows.length === 0 && !working && (
                <TableRow>
                  <TableCell colSpan={_columns.length}>
                    <Typography className={classes.textCenter} variant="h4">
                      {message}
                    </Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          {working && (
            <Grid container justifyContent="center" alignItems="center">
              <img src={loading} width={50} alt="loading" />
            </Grid>
          )}
        </TableContainer>
      </Paper>
    </>
  );
};

export const InfoTable = React.memo(InfoTableRaw);
