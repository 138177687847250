import React, { useCallback } from 'react';
import { ROL } from 'src/commons/const';
import customFormDialog from 'src/commons/services/customFormDialog';
import { RESTConnection, DatabaseConnector } from '@phinxlab/libby-rest-web';
import { makeStyles, Grid } from '@material-ui/core';
import { AnyObject } from 'src/commons/types';
import { UsuarioRolEstablecimiento } from 'src/app/models';
import { useReloginDAO } from '../../../app/business/businessCustomEndpoints';
import { CardItem, DireccionAreaModal } from './components';
import { OptionsModalOption } from '../OptionsModal';

const cardStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'row',
  },
});

interface CardProps {
  isAssignature?: boolean;
  content: AnyObject[];
  libby: AnyObject;
  appendRender?: JSX.Element | JSX.Element[];
  handleMenuIcon?: OptionsModalOption<UsuarioRolEstablecimiento>[];
}

const CardComponentRaw = ({
  isAssignature,
  handleMenuIcon,
  content,
  libby,
  appendRender,
}: CardProps) => {
  const classes = cardStyles();
  const reloginDAO = useReloginDAO();
  const isDirectorAreaOrSupervisor = (id: number) =>
    [ROL.DIRECCION_AREA, ROL.SUPERVISOR].includes(id);

  const handleClick = useCallback(
    async (roleInformation: AnyObject) => {
      if (isDirectorAreaOrSupervisor(roleInformation.rolUsuario.idRolUsuario)) {
        customFormDialog.show({
          title: '',
          renderComponent: (
            <DireccionAreaModal
              roleInformation={roleInformation}
              libby={libby}
            />
          ),
          sizeWidth: 'lg',
        });
      } else {
        const res = await reloginDAO.selectRole(
          roleInformation.idRolEstablecimiento.toString(),
        );
        const user = { ...res.user, isGuest: res.isGuest };
        // FIXME: esto es por algo que le falta a libby, quitarlo cuando libby logre reaccioner automaticamente a los cambios de tokens
        RESTConnection.defineHeader('x-chino-token', res.token);
        libby.session.notify('New session', user, res.token);
      }
    },
    [libby, reloginDAO],
  );
  // TODO: check where else this component is used and what data type it receives when assignature is true
  return (
    <Grid container className={classes.container}>
      {content?.map((item: AnyObject, index: number) => (
        <CardItem
          item={item}
          handleMenuIcon={handleMenuIcon}
          handleClick={handleClick}
          isAssignature={isAssignature}
          key={index}
        />
      ))}
      {appendRender}
    </Grid>
  );
};

export const CardComponent = DatabaseConnector(CardComponentRaw)();
