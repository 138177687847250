import { MenuItem } from '../../../../commons/const/ui/menu';
import { CiudadaniaGlobal } from 'src/screens/Private/CiudadaniaGlobal';

export const routes: MenuItem[] = [
  {
    key: 'ciudadania',
    basePath: '/',
    target: {
      component: CiudadaniaGlobal,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Ciudadania Global',
    children: [],
    default: true,
  },
];
