import React from 'react';
import { Grid } from '@material-ui/core';
import { MainButton } from '../MainButton';
import { MainInput } from '../MainInput';
import { AnyObject } from '../../types';

export interface TableToolbarProps {
  search: string;
  onSearchChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onNewClick?: () => void;
  buttonTitle?: string;
  buttonRender?: () => JSX.Element;
  searchContainerProps?: AnyObject;
}

export const TableToolbar = ({
  search,
  onSearchChange,
  buttonRender,
  buttonTitle,
  onNewClick,
  searchContainerProps = {},
}: TableToolbarProps) => {
  return (
    <Grid container spacing={1} justify="flex-end">
      {(onNewClick || buttonRender) && (
        <Grid item>
          {buttonRender ? (
            buttonRender()
          ) : (
            <MainButton title={buttonTitle} handleOnClick={onNewClick} />
          )}
        </Grid>
      )}
      <Grid item {...searchContainerProps}>
        <MainInput
          type="search"
          handleChange={onSearchChange}
          placeholder="Buscar"
          name="search"
          value={search}
          fullWidth
        />
      </Grid>
    </Grid>
  );
};
