import {
  Box,
  Grid,
  GridSize,
  makeStyles,
  Paper,
  SvgIconTypeMap,
  Typography,
} from '@material-ui/core';
import { get } from 'lodash';
import React from 'react';
import cx from 'classnames';
import { ReportProblemRounded } from '@material-ui/icons';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import { Indicator, IndicatorColor, IndicatorVariant } from './Indicador';

interface ResumeColumn {
  id: string;
  xs?: GridSize;
  label: string;
  fontSize?: number;
  color?: IndicatorColor;
  variant?: IndicatorVariant;
  icon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
}

export type ResumeTurno = 'M' | 'T' | 'V' | 'N' | 'CT1' | 'CT2';

export interface ResumeRow<T = ResumeTurno> {
  id: number | null;
  label: T;
  presentes: number;
  ausentes: number;
  pendientes: number;
  alertas: number;
}

export const defaultColumns: ResumeColumn[] = [
  { id: 'label', label: '', xs: 2 },
  { id: 'presentes', label: 'P', color: 'GREEN', variant: 'contained', xs: 2 },
  { id: 'ausentes', label: 'A', color: 'RED', variant: 'contained', xs: 2 },
  {
    id: 'pendientes',
    label: 'Sin carga',
    color: 'GREY',
    variant: 'outline',
    fontSize: 13,
    xs: 3,
  },
  {
    id: 'alertas',
    label: 'Alerta',
    color: 'RED',
    variant: 'outline',
    fontSize: 13,
    icon: ReportProblemRounded,
    xs: 3,
  },
];

export const _rows: [ResumeRow<'M'>, ResumeRow<'T'>] = [
  { id: 1, label: 'M', presentes: 30, ausentes: 5, pendientes: 10, alertas: 4 },
  { id: 2, label: 'T', presentes: 30, ausentes: 10, pendientes: 7, alertas: 0 },
];

interface ResumeProps {
  columns?: ResumeColumn[];
  rows: ResumeRow[];
}

const useStyles = makeStyles(() => ({
  paper: {
    width: 310,
    padding: 16,
    borderRadius: 13,
    border: '1px solid #F1EAEA',
    boxShadow: '0px 3px 6px #9FA1A42E',
  },
  column: {
    padding: 1.5,
  },
  row: {
    alignItems: 'center',
    borderRadius: 6,
    height: 27,
  },
  text: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    color: '#38485C', // GRISULADO
    fontWeight: 'bold',
    fontFamily: 'Nunito',
    fontSize: 13,
  },
  bg: {
    backgroundColor: '#F9F9F9',
  },
  borderLeft: {
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
  },
  borderRight: {
    borderTopRightRadius: 6,
    borderBottomRightRadius: 6,
  },
}));

export const Resume = ({ columns = defaultColumns, rows }: ResumeProps) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paper}>
      <Typography variant="body1">
        <Box
          component="span"
          color="#38485C"
          fontSize={18}
          fontWeight="bold"
          fontFamily="Nunito"
        >
          Resumen
        </Box>
      </Typography>
      <Grid container>
        <Grid
          item
          container
          direction="row"
          className={classes.row}
          justifyContent="space-around"
        >
          {columns.map((column) => (
            <Grid
              item
              key={column.label}
              xs={column.xs}
              className={classes.column}
            >
              <Indicator {...column} />
            </Grid>
          ))}
        </Grid>
        {rows.map((row, i) => (
          <Grid
            key={row.id}
            item
            container
            direction="row"
            className={cx(classes.row, i === 0 ? classes.bg : '')}
          >
            {columns.map((column) => {
              const value = get(row, column.id);
              const isNum = typeof value === 'number';
              const _value = isNum ? (value > 0 ? value : '-') : value;
              return (
                <Grid item xs={column.xs} key={column.label}>
                  <Typography variant="caption" className={classes.text}>
                    {_value}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        ))}
      </Grid>
    </Paper>
  );
};
