import React, { FC } from 'react';
import { Grid, createStyles } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormMultiCheckboxInput,
  FormMultiCheckbox,
} from 'src/lib/templates/components';
import { TextField } from 'src/app/components/inputs';
import { grey } from 'src/theme/colors';
import { usePlanificationFormDisciplinarOrientada } from '../../hooks';
import { useDisciplinarOrientadaContext } from '../../Context';
import { CheckboxListItem } from '../../../commons/components';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: '32px 10px',
      marginBottom: 60,
    },
    listItem: {
      padding: 0,
    },
    listItemIcon: {
      marginRight: '-20px',
    },
    text: {
      color: grey.medium,
    },
  }),
);

export const IndicadoresEvaluacion: FC = () => {
  const classes = useStyles();
  const { form, errors, values } = usePlanificationFormDisciplinarOrientada();
  const {
    data: { bloques, indicadores },
    working,
  } = useDisciplinarOrientadaContext();

  const filterBloques = bloques.filter((bloq: any) =>
    values.bloque.bloques.find((bloqValue: any) => bloq.idBloque === bloqValue),
  );

  const buttonCustomStyle = { margin: '10px 0px 25px 0' };

  // TODO: Rename FormMultiCheckbox and refactor checkbox list
  return (
    <Grid container direction="column" className={classes.container}>
      {!working &&
        filterBloques.map((eva: any, indexs: number) => {
          return (
            <>
              <FormMultiCheckbox
                label={`Bloque: ${eva.descripcion}`}
                name={`indicadoresEvaluacion.indicadoresEvaluacion[${indexs}].indicadores`}
                // @ts-ignore
                options={indicadores}
                form={form}
                idKey="idIndicadoresDeEvaluacion"
              />
              <FormMultiCheckboxInput
                label=""
                name={`indicadoresEvaluacion.indicadoresEvaluacion[${indexs}].adicionales`}
                form={form}
                addButtonText="Agregar Indicador"
                buttonCustomStyle={buttonCustomStyle}
                getNewElement={(value) => ({
                  id: -1 - 1 * value.filter((item: any) => item.id < 0).length, // use negative id to validate new items
                  checked: false,
                  descripcion: '',
                })}
              >
                {(value, input, meta, index) => (
                  <CheckboxListItem
                    inputPlaceholder="Nuevo indicador"
                    value={value}
                    input={input}
                    meta={meta}
                    index={index}
                  />
                )}
              </FormMultiCheckboxInput>
            </>
          );
        })}
    </Grid>
  );
};
