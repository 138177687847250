import {
  FormDialog,
  OpenOptions,
} from '../../../../../../commons/services/dialogs/base/formDialog';
import {
  RadioButtonsForm,
  RadioButtonsFormMetadata,
} from '../../../../../../commons/services/dialogs/implementations';
import theme from '../../../../../../theme';

export type EspacioCurricularOption = {
  id: number | string;
  descripcion: string;
};

const defaultMetadata: RadioButtonsFormMetadata<EspacioCurricularOption> = {
  options: [],
  getId: ({ id }) => `${id}`,
  getLabel: ({ descripcion }) => descripcion,
};

class EspaciosCurricularesDialog {
  private service: FormDialog<
    EspacioCurricularOption | null,
    RadioButtonsFormMetadata<EspacioCurricularOption>
  >;

  constructor() {
    this.service = FormDialog.create<
      EspacioCurricularOption | null,
      RadioButtonsFormMetadata<EspacioCurricularOption>
    >({
      customTheme: theme,
      component: RadioButtonsForm,
      title: 'Espacio Curricular',
      metadata: defaultMetadata,
    });
  }

  // This is to simplify the call
  open(
    initialValues: EspacioCurricularOption | null,
    options: EspacioCurricularOption[],
    metadata?: Omit<
      RadioButtonsFormMetadata<EspacioCurricularOption>,
      'options'
    >,
    openOptions?: OpenOptions,
  ) {
    const paramMetadata = metadata || {};
    const joinedMetadata: RadioButtonsFormMetadata<EspacioCurricularOption> = {
      options,
      ...paramMetadata,
    };
    return this.service.open(initialValues, joinedMetadata, openOptions);
  }
}

const instance = new EspaciosCurricularesDialog();

export { instance as EspaciosCurricularesDialog };
