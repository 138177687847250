import { useRouteMatch } from 'react-router';
import { Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useMemo, useCallback } from 'react';
import moment from 'moment';
import {
  useEspaciosDiscipOrientPlanDAO,
  useDiscipOrientPlanDAO,
} from 'src/app/business';
import confirmDialog from 'src/commons/services/confirmDialog';
import { makeActionButtons } from './makeActionButtons';
import { EspaciosDiscipOrientPlan } from '../../../../../../../app/models';
import { OptionsModal } from '../../../../../../../commons/components/OptionsModal';

const useActionButtons = makeActionButtons(
  'idEspaciosDiscipOrientPlan',
  (row: EspaciosDiscipOrientPlan) => row.discipOrientPlan.planificacionEstados,
  'discipOrientPlan',
);

export const useDisciplinarOrientadoTableColumns = (reFetch: () => void) => {
  const { url } = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();

  const espaciosDiscipOrientPlanDAO = useEspaciosDiscipOrientPlanDAO();
  const discipOrientPlanDAO = useDiscipOrientPlanDAO();

  const handleDeletePlanificacionOrientada = useCallback(
    async (planificacion) => {
      try {
        const confirm = await confirmDialog.show({
          title: 'Confirmación',
          content: `¿Esta seguro que desea eliminar la planificacion?`,
          confirmText: 'Eliminar',
          cancelText: 'No',
        });
        if (confirm) {
          const id =
            planificacion.discipOrientPlan.idDiscipOrientPlan.toString();
          const iddos = planificacion.idEspaciosDiscipOrientPlan;

          await espaciosDiscipOrientPlanDAO.remove({
            idEspaciosDiscipOrientPlan: iddos,
          });

          await discipOrientPlanDAO.remove({
            idDiscipOrientPlan: id,
          });
          enqueueSnackbar('Se ha eliminado con éxito', { variant: 'success' });
          reFetch();
        }
      } catch (e) {
        enqueueSnackbar('Ha ocurrido un error al intentar eliminar', {
          variant: 'error',
        });
      }
    },
    [
      espaciosDiscipOrientPlanDAO,
      discipOrientPlanDAO,
      enqueueSnackbar,
      reFetch,
    ],
  );

  const actionButtons = useActionButtons({
    urls: {
      edit: `${url}/planificacion/disciplinarOrientada`,
      feedback: `${url}/planificacionfeedback/disciplinarOrientada`,
      preview: `${url}/planificacionpreview/disciplinarOrientada`,
    },
    helper: {
      delete: handleDeletePlanificacionOrientada,
    },
  });
  return useMemo(
    () => [
      {
        id: 'espacioCurricular.Area',
        label: 'Áreas',
        width: '15%',
        orderById: 'espacioCurricular.materia.area.descripcion',
        render: (value: any, row: EspaciosDiscipOrientPlan) =>
          row.espacioCurricular.materia.area.descripcion,
      },
      {
        id: 'espacioCurricular.PlanEstudio',
        label: 'Proyecto',
        width: '15 %',
        orderById: 'espacioCurricular.planEstudio.descripcionPlanEstudio',
        render: (value: any, row: EspaciosDiscipOrientPlan) => (
          <Box whiteSpace="nowrap">
            {row.espacioCurricular.materia.descripcion}
          </Box>
        ),
      },
      {
        id: 'tipo',
        label: 'Tipo',
        width: '10%',
        style: {
          whiteSpace: 'nowrap',
        },
        hideSortIcon: true,
        render: () => 'Disciplinar',
      },
      {
        id: 'discipOrientPlan.ultimaActualizacion',
        label: 'Ultima modificación',
        width: '35%',
        render: (value: any, row: EspaciosDiscipOrientPlan) => (
          <Box whiteSpace="nowrap">
            {row.discipOrientPlan.ultimaActualizacion
              ? moment(row.discipOrientPlan.ultimaActualizacion).format(
                  'DD/MM/YYYY - HH:mm',
                )
              : '-'}
          </Box>
        ),
      },
      {
        id: 'estado',
        label: 'Estado',
        width: '10%',
        orderById: 'discipOrientPlan.planificacionEstados.nombre',
        render: (value: any, row: EspaciosDiscipOrientPlan) => {
          return (
            <Box whiteSpace="nowrap">
              {row.discipOrientPlan.planificacionEstados.nombre ?? '-'}
            </Box>
          );
        },
      },
      {
        id: 'nombre',
        label: 'Creado Por',
        width: '10%',
        style: { textAlign: 'center' },
        orderById: 'discipOrientPlan.cuentas.nombreUsuario',
        render: (value: any, row: EspaciosDiscipOrientPlan) => {
          return (
            <Box whiteSpace="nowrap" textAlign="center">
              {`${row.discipOrientPlan.cuentas.nombreUsuario} ${row.discipOrientPlan.cuentas.apellidoUsuario}` ??
                '-'}
            </Box>
          );
        },
      },
      {
        id: 'icono',
        label: '',
        width: '5%',
        style: { textAlign: 'right' },
        hideSortIcon: true,
        render: (value: any, row: any) => (
          <Box whiteSpace="nowrap" textAlign="right">
            <OptionsModal options={actionButtons} data={row} />
          </Box>
        ),
      },
    ],
    [actionButtons],
  );
};
