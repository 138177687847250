import { cloneDeep } from 'lodash';
import moment from 'moment';
import {
  actualizarMetadata,
  actualizarPresentismo,
  crearPresentismo,
} from '../TomaPresentismoOLD/utils/estadoPresentismo';
import {
  AlumnoPresentismo,
  GeneralActions,
  InitialState,
  MetadataModified,
} from '../types';

import { Point } from '../TomaPresentismo/utils/Grid';

import {
  SHOW_TABLE_MODAL,
  SELECT_CURRENT_WEEK,
  SET_ROWS,
  MAKE_DAY_SELECTION,
  IS_MODAL_OPEN,
  ROW_INDEX,
  SET_IDX_DAY_SELECTION,
  SET_CHECKED_ROWS,
  SET_META_DATA,
  SET_EXCEPTUADOS,
  SET_EXCEPTUADOS_LIST,
  SET_ESTADO_EMERGENCIA,
  SET_SUSPENSION_CLASES,
  SET_TIPO_JORNADA,
  SET_SAVE_DISABLE,
  SET_ENABLE_AUTO_SAVE,
  SET_GRID_POSITION,
  SET_IS_JORNADA_EXTENDIDA_TAB,
  SET_ALUMNO_TO_SEARCH,
  SET_OPEN_JUSTIFICACION,
} from './actions';

import {} from 'notistack';
import { ExceptuadoError } from '../functions/ExceptuadoError';

const LOWER_DEADLINE = '2022/01/01';

export const initialState: InitialState = {
  daySelection: { date: null, selection: '' },
  currentWeek: moment().startOf('isoWeek'),
  showTableModal: { show: false, target: null, id: '' },
  rows: [],
  isModalOpen: false,
  index: 0,
  idxDaySelected: Number(moment().format('e')),
  checkedRows: [],
  tipoJornada: '',
  saveDisable: true,
  autoSave: true,
  exceptuadoError: null,
  exceptuadosToDelete: [],
  gridPosition: new Point(0, 0),
  isJornadaExtendidaTab: false,
  alumnoToSearch: '',
  lowerDeadLineForTomaPresentismo: LOWER_DEADLINE,
  openJustificacion: { row: {}, open: false },
};

export const initialContextValue = {
  state: initialState,
  dispatch: {
    openTableModal: () => {},
    selectWeek: () => {},
    setRows: () => {},
    makeDaySelection: () => {},
    setIsModalOpen: () => {},
    setRowIndex: () => {},
    setIdxDaySelected: () => {},
    setCheckedRows: () => {},
    setMetaData: () => {},
    setEstadoEmergencia: () => {},
    setSuspensionClases: () => {},
    setExceptuados: () => {},
    setTipoJornada: () => {},
    setSaveDisable: () => {},
    setEnableAutoSave: () => {},
    setGridPosition: () => {},
    setIsJornadaExtendidaTab: () => {},
    setOpenJustificacion: () => {},
  },
  serverDate: null,
  filter: {
    anio: '',
    periodo: 4,
    seccion: '',
  },
  setFilter: () => {},
  propuestasWorking: false,
  rolCanUpdateGrid: false,
  reFetch: () => {},
  feriados: [],
  propuestasJE: [],
};

export const reducer = (state = initialState, action: GeneralActions) => {
  switch (action.type) {
    case SET_ALUMNO_TO_SEARCH:
      return {
        ...state,
        alumnoToSearch: action.payload,
      };
    case SET_ROWS:
      const { rows } = action.payload;
      return {
        ...state,
        daySelection: { date: null, selection: '' },
        rows,
        exceptuadoError: null,
        exceptuadosToDelete: [],
      };
    case SHOW_TABLE_MODAL:
      const showTableModal = action.payload;
      return {
        ...state,
        showTableModal,
        exceptuadoError: null,
      };
    case SET_GRID_POSITION:
      const point = action.payload;
      return {
        ...state,
        gridPosition: point,
      };
    case SELECT_CURRENT_WEEK:
      const { currentWeek } = action.payload;
      return {
        ...state,
        currentWeek,
        exceptuadoError: null,
      };
    case MAKE_DAY_SELECTION:
      const { selection } = action.payload;
      return {
        ...state,
        daySelection: selection,
        exceptuadoError: null,
      };
    case IS_MODAL_OPEN:
      const { open } = action.payload;
      return {
        ...state,
        exceptuadoError: null,
        isModalOpen: open,
      };
    case ROW_INDEX:
      const { index } = action.payload;
      return {
        ...state,
        exceptuadoError: null,
        index,
      };
    case SET_IDX_DAY_SELECTION:
      return {
        ...state,
        idxDaySelected: action.payload,
        exceptuadoError: null,
      };
    case SET_CHECKED_ROWS:
      return {
        ...state,
        exceptuadoError: null,
        checkedRows: [...action.payload],
      };
    case SET_META_DATA:
      const rowsMetadata = setData({
        ...action.payload,
        rows: cloneDeep(state.rows),
        checkedRows: state.checkedRows,
      });
      return {
        ...state,
        rows: rowsMetadata,
        exceptuadoError: null,
      };
    case SET_ESTADO_EMERGENCIA:
      const rowsEstadoEmergencia = setData({
        ...action.payload,
        rows: cloneDeep(state.rows),
        checkedRows: state.rows.map(
          (row: AlumnoPresentismo) => row.alumno.idAlumno,
        ),
      });
      return {
        ...state,
        rows: rowsEstadoEmergencia,
        exceptuadoError: null,
      };
    case SET_SUSPENSION_CLASES:
      const rowsSuspensionClases = setData({
        ...action.payload,
        rows: cloneDeep(state.rows),
        checkedRows: state.rows.map(
          (row: AlumnoPresentismo) => row.alumno.idAlumno,
        ),
      });
      return {
        ...state,
        rows: rowsSuspensionClases,
        exceptuadoError: null,
      };
    case SET_EXCEPTUADOS:
      let errors = new ExceptuadoError();
      const selectedDay = action.payload.day;
      const cloned = cloneDeep(state.rows);
      const movimiento = cloned.find(
        (row: AlumnoPresentismo) =>
          row.idAlumnoMovimiento === action.payload.movimiento,
      )!;

      try {
        movimiento.exceptuados.addDate(selectedDay, movimiento.alumno);
      } catch (error) {
        errors.add({
          error: error.message,
        });
      }
      if (errors.hasErrors()) {
        return {
          ...state,
          exceptuadoError: errors,
        };
      }

      return {
        ...state,
        rows: setData({
          ...action.payload,
          rows: cloned,
          checkedRows: state.checkedRows,
        }),
      };
    case SET_EXCEPTUADOS_LIST:
      const { list, idMovimiento, toDelete } = action.payload;
      const clonedRows = [...state.rows];
      const indexUpdated = clonedRows.findIndex(
        (row) => row.idAlumnoMovimiento === idMovimiento,
      )!;
      clonedRows[indexUpdated].exceptuados = list;

      return {
        ...state,
        rows: clonedRows,
        exceptuadosToDelete: toDelete,
      };
    case SET_TIPO_JORNADA:
      return {
        ...state,
        tipoJornada: action.payload,
        exceptuadoError: null,
      };
    case SET_SAVE_DISABLE:
      return {
        ...state,
        saveDisable: action.payload,
        exceptuadoError: null,
      };
    case SET_ENABLE_AUTO_SAVE:
      return {
        ...state,
        autoSave: action.payload,
        exceptuadoError: null,
      };
    case SET_IS_JORNADA_EXTENDIDA_TAB:
      return {
        ...state,
        isJornadaExtendidaTab: action.payload,
        exceptuadoError: null,
      };
    case SET_OPEN_JUSTIFICACION:
      return {
        ...state,
        openJustificacion: {
          row: action.payload.row,
          open: action.payload.open,
        },
      };

    default:
      return {
        ...state,
        exceptuadoError: null,
      };
  }
};

const setData = ({
  day,
  row,
  metadata,
  multiple,
  rows,
  checkedRows,
}: {
  row?: AlumnoPresentismo;
  day: string;
  multiple: boolean;
  rows: AlumnoPresentismo[];
  checkedRows?: string[];
  metadata: MetadataModified;
}) => {
  const newRows = rows.map((movement: AlumnoPresentismo) => {
    const isEmergencia = metadata?.metadataType?.descripcion === 'Emergencia';
    const isSuspencionClases =
      metadata?.metadataType?.descripcion === 'Suspensión de clases';
    const turnos =
      movement.seccion.jornada === 'Simple' ||
      movement.seccion.jornada === 'Extendida'
        ? [movement.seccion.turno.idTurno]
        : [1, 2];
    if (
      movement.alumno.idAlumno === row?.alumno.idAlumno ||
      (multiple && checkedRows?.includes(movement.alumno?.idAlumno))
    ) {
      const oldMetadata = movement.metadatas.find((meta) => {
        return (
          (!!meta.idMetadata && meta.idMetadata === metadata.idMetadata) ||
          (meta.metadataType.idMetadataType ===
            metadata.metadataType.idMetadataType &&
            meta.dates.includes(metadata.dates ? metadata.dates[0] : day))
        );
      });

      if (oldMetadata) {
        const metadataToUpdate = {
          ...oldMetadata,
          data: metadata.data,
          dates: metadata.dates,
        };

        switch (metadata.metadataType.descripcion) {
          case 'Virtualidad':
            if (movement?.exceptuados.hasOneWithinInterval(day)) {
              metadataToUpdate.value = true;
            } else {
              metadataToUpdate.value = !Boolean(oldMetadata?.value);
            }
            break;
          case 'Observaciones':
            if (metadata.data.observacion === '') {
              metadataToUpdate.value = !Boolean(oldMetadata?.value);
            }
            break;
          default:
            metadataToUpdate.value = !Boolean(oldMetadata?.value);
            break;
        }

        actualizarMetadata({
          day,
          alumno: movement,
          metadata: metadataToUpdate,
        });
      } else {
        const metadataToCreate = {
          ...metadata,
          idAlumno: movement.alumno.idAlumno,
          value: true,
          dates: metadata?.dates ?? [moment(day).format('YYYY-MM-DD')],
        };
        movement.metadatas.push(metadataToCreate);

        if (isEmergencia || isSuspencionClases) {
          if (metadataToCreate.value) {
            turnos.forEach((idTurno) => {
              const existePresentismo = movement.presentismo.some(
                (p: { fecha: string; turno: string }) =>
                  Number(p.turno) === idTurno && p.fecha === day,
              );
              if (existePresentismo) {
                movement.presentismo = actualizarPresentismo({
                  alumno: movement,
                  estadoPresente: {
                    idEstadoPresente: 3,
                    descripcion: 'No Corresponde',
                  },
                  fecha: day,
                  turno: idTurno,
                });
              } else {
                const nuevoPresentismo = {
                  estadoPresente: {
                    idEstadoPresente: 3,
                    descripcion: 'No Corresponde',
                  },
                  fecha: day,
                  turno: idTurno,
                };
                movement.presentismo.push(nuevoPresentismo);
              }
            });
          }
        }
      }
    }
    return movement;
  });
  return newRows;
};
