import { Home } from '../../../screens';
import { MenuItem } from '../../../commons/const/ui';
import { GestionPromocion, Reportes } from 'src/screens/Private';
import { AcapEstablecimientoScreen } from 'src/screens/Private/Acap/AcapEstablecimiento';
import { ReferenteView } from 'src/screens/Private/AcapReferente';
import RolesPermisos from 'src/screens/Private/AcapReferente/RolesPermisos/RolesPermisos';
import { DevolucionOrgView } from 'src/screens/Private/AcapEscuela/components/Organizacion';

export const routes: MenuItem[] = [
  {
    key: 'home',
    basePath: '/home',
    target: {
      component: Home,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Home',
    children: [],
  },
  {
    key: 'gestionPromocion',
    basePath: '/gestion-promocion',
    target: {
      component: GestionPromocion,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Home',
    children: [],
  },
  {
    key: 'reportes',
    basePath: '/reportes',
    target: {
      component: Reportes,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Reportes',
    children: [],
  },
  {
    key: 'acap',
    basePath: '/acap',
    target: {
      component: AcapEstablecimientoScreen, // ACA
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Acap',
    children: [],
  },
  {
    key: 'acapReferente',
    basePath: '/referente-organizacion',
    target: {
      component: ReferenteView,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Referente Organización',
    children: [],
  },
  {
    key: 'acapReferente',
    basePath: '/referente-accion',
    target: {
      component: ReferenteView,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Referente Acción',
    children: [],
  },
  {
    key: 'permisos-roles',
    basePath: '/permisos-roles',
    target: {
      component: RolesPermisos,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Permisos de Roles',
    children: [],
  },
  {
    key: 'devolucion_organizacion',
    basePath: '/organizacion/devolucion/:rowGrupo?/:fechaAcap?',
    target: {
      component: DevolucionOrgView,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Devolución Organización',
    children: [],
    default: false,
  },
];
