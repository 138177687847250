import { makeBusinessEntity } from '../../../lib/libby';
import { EvaluacionDevolucion } from '../../models/acap';
import { EvaluacionesDevolucionesDAO } from 'src/platform/libby/dao';

const DAO_NAME = 'evaluaciones_devoluciones';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
} = makeBusinessEntity<EvaluacionDevolucion, EvaluacionesDevolucionesDAO>(
  DAO_NAME,
  {},
);

// hooks
export const useEvaluacionesDevolucionesFetchById = fetchById;
export const useEvaluacionesDevolucionesLibbyCall = libbyCall;
export const useEvaluacionesDevolucionesDAO = libbyDAO;
export const useEvaluacionesDevolucionesLibbyFetch = libbyFetch;
