import React, { FunctionComponent } from 'react';
import { GOOGLE_MAP_API_KEY } from '../../../config';

interface MapProps {
  lat?: string;
  lng?: string;
  width?: number | string;
  height?: number | string;
}

export const Map: FunctionComponent<MapProps> = ({
  lat,
  lng,
  width = '600',
  height = '450',
}) => (
  <iframe
    allowFullScreen
    width={width}
    height={height}
    frameBorder="0"
    title="Google Maps"
    style={{ border: 0 }}
    src={`https://www.google.com/maps/embed/v1/place?q=${lat},${lng}&key=${GOOGLE_MAP_API_KEY}`}
  />
);
