import React from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import { IndicadoresDeEvaluacionPlan } from 'src/app/models';
import { ListItemProperties } from '../../commons';
import { useInterdisciplinarContext } from '../../../forms/commons/components';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
}));
export interface IndicadoresDeEvaluacionProps {
  data: IndicadoresDeEvaluacionPlan;
}

export const IndicadoresDeEvaluacion = ({
  data,
}: IndicadoresDeEvaluacionProps) => {
  // const classes = useStyles();
  const {
    data: { indicadores },
    working,
  } = useInterdisciplinarContext();

  const filterIndicadores = data?.indicadores?.map((indicador: string) => {
    const valor = indicadores.find(
      (_indicador: any) => _indicador?.idIndicadoresDeEvaluacion === indicador,
    );
    return {
      id: valor?.idIndicadoresDeEvaluacion || 1,
      descripcion: valor?.descripcion || '',
    };
  });
  filterIndicadores.push(...data.adicionales);
  return (
    <Grid container direction="column">
      {!working && (
        <Grid>
          <ListItemProperties values={filterIndicadores} />
        </Grid>
      )}
    </Grid>
  );
};
