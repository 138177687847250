import { Button, Grid, Typography } from '@material-ui/core';
import { isEmpty, isNil } from 'ramda';
import React, { FunctionComponent, useCallback } from 'react';
import { DatosAtencion } from 'src/app/models';
import { useFormContext } from 'src/lib/templates';
import { useStyles } from '../styles';
import { AtencionHospitalariaCard } from './AtencionHospitalariaCard';

export const AtencionHospitalaria: FunctionComponent<{
  isHistoric: boolean;
}> = ({ isHistoric }) => {
  const classes = useStyles();
  const { form, values } = useFormContext();

  const addNew = useCallback(() => {
    const newValue = values?.salud?.datosAtencion;
    newValue.push({
      atencionDesde: undefined,
      atencionHasta: undefined,
      establecimiento: '',
    });
    form.change('salud', {
      ...values.salud,
      datosAtencion: [...newValue],
    });
  }, [values.salud, form]);

  const onRemoveItem = useCallback(
    (index: any) => {
      const newInput = values?.salud?.datosAtencion.filter(
        (item: any, ind: number) => index !== ind,
      );
      form.change('salud', {
        ...values.salud,
        datosAtencion: [...newInput],
      });
    },
    [values.salud, form],
  );

  const disabledButton = (datosAtencion: DatosAtencion[]) => {
    const { atencionDesde, atencionHasta, establecimiento } =
      datosAtencion[datosAtencion.length - 1];

    return (
      isEmpty(establecimiento) || isNil(atencionDesde) || isNil(atencionHasta)
    );
  };

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      className={classes.secondaryContainer}
    >
      <Grid item>
        <Grid item xs={12} className={classes.secondaryContainerTitle}>
          <Typography className={classes.title} variant="h5">
            Atención hospitalaria/domiciliaria (opcional)
          </Typography>
        </Grid>
        {values.salud.datosAtencion.map((value: any, index: number) => (
          <AtencionHospitalariaCard
            key={index}
            index={index}
            onRemoveItem={onRemoveItem}
            isHistoric={isHistoric}
          />
        ))}
      </Grid>
      <Grid item className={classes.containerButton}>
        <Button
          variant="outlined"
          onClick={addNew}
          disabled={disabledButton(values?.salud?.datosAtencion) || isHistoric}
        >
          Agregar Atención
        </Button>
      </Grid>
    </Grid>
  );
};
