import React from 'react';
import { Typography, Grid, Chip, makeStyles } from '@material-ui/core';
import { BimestreOrientada, BimestreOrientPlan, Seccion } from 'src/app/models';
import { grey } from 'src/theme/colors';
import { ListItemProperties } from '../../../feedbacks/commons';
import { useBimestreLogic } from '../../../feedbacks/DisciplinarOrientadaFeedback/hooks/useBimestreLogic';

const useStyles = makeStyles(() => ({
  textField: {
    fontSize: 14,
    color: grey.textPrimary,
    fontWeight: 600,
    marginBottom: 20,
  },
  valueField: {
    fontSize: 16,
    color: grey.textPrimary,
    fontWeight: 400,
    marginTop: 8,
    marginBottom: 20,
  },
  containerSeccion: {
    marginTop: 8,
    marginBottom: 20,
    overflowWrap: 'break-word',
    '@media (max-width: 1600px)': {
      width: 100,
    },
    '@media (max-width: 1200px)': {
      width: 80,
    },
    '@media (max-width: 900px)': {
      width: 60,
    },
    '@media print': {
      width: '100%',
    },
  },
  itemContainer: {
    border: `1px solid ${grey.normal}`,
    borderRadius: 4,
    marginBottom: 25,
  },
  valueSeccion: {
    fontSize: 16,
    color: grey.textPrimary,
    fontWeight: 400,
  },
  containerList: {
    borderRight: `1px solid ${grey.normal}`,
    width: 200,
    '@media (max-width: 1600px)': {
      width: 150,
    },
    '@media (max-width: 1200px)': {
      width: 100,
    },
    '@media (max-width: 900px)': {
      width: 60,
    },
    '@media print': {
      width: '100%',
      marginBottom: 20,
    },
    overflowWrap: 'break-word',
  },
  containerContenidos: {
    borderRight: `1px solid ${grey.normal}`,
    width: 350,
    '@media (max-width: 1600px)': {
      width: 200,
    },
    '@media (max-width: 1200px)': {
      width: 100,
    },
    '@media (max-width: 900px)': {
      width: 80,
    },
    '@media print': {
      width: '100%',
    },
    overflowWrap: 'break-word',
  },
  containerIntrumentos: {
    borderRight: `1px solid ${grey.normal}`,
    width: 230,
    '@media (max-width: 1600px)': {
      width: 200,
    },
    '@media (max-width: 1200px)': {
      width: 110,
    },
    '@media (max-width: 900px)': {
      width: 80,
    },
    '@media print': {
      width: '100%',
    },
    overflowWrap: 'break-word',
  },
  containerDescripcion: {
    borderRight: `1px solid ${grey.normal}`,
    width: 180,
    '@media (max-width: 1600px)': {
      width: 150,
    },
    '@media (max-width: 1200px)': {
      width: 100,
    },
    '@media (max-width: 900px)': {
      width: 60,
    },
    '@media print': {
      width: '100%',
    },
    overflowWrap: 'break-word',
  },
  containerHora: {
    borderRight: `1px solid ${grey.normal}`,
    width: 100,
    '@media (max-width: 1200px)': {
      width: 70,
    },
    '@media (max-width: 900px)': {
      width: 50,
    },
    '@media print': {
      width: '100%',
    },
    overflowWrap: 'break-word',
  },
  containerBloque: {
    borderRight: `1px solid ${grey.normal}`,
    width: 200,
    '@media (max-width: 1600px)': {
      width: 150,
    },
    '@media (max-width: 1200px)': {
      width: 100,
    },
    '@media (max-width: 900px)': {
      width: 60,
    },
    '@media print': {
      width: '100%',
    },
    overflowWrap: 'break-word',
  },
  containerActividad: {
    borderRight: `1px solid ${grey.normal}`,
    width: 200,
    '@media (max-width: 1600px)': {
      width: 150,
    },
    '@media (max-width: 1200px)': {
      width: 80,
    },
    '@media (max-width: 900px)': {
      width: 60,
    },
    '@media print': {
      width: '100%',
    },
    overflowWrap: 'break-word',
  },
}));
export interface BimestreProps {
  prefix: string;
  data: BimestreOrientada;
}

export type SubLevel2 = {
  id: number;
  descripcion: string;
};

export type SubLevel = {
  id: number;
  descripcion: string;
  subLevel2: SubLevel2[];
};
export type ContenidosOrientada = {
  id: number;
  descripcion: string;
  subLevel: Array<SubLevel>;
};

export const Bimestre = ({ prefix, data }: BimestreProps) => {
  const { getfilters, loading } = useBimestreLogic();
  const classes = useStyles();

  const callbackFilterSeccion = (item: Seccion) => (
    <Grid container direction="row" className={classes.containerSeccion}>
      <Typography className={classes.valueSeccion}>
        {item?.nombreSeccion}
      </Typography>
    </Grid>
  );

  return (
    <Grid container>
      {!loading &&
        data[prefix]?.map((bimestre: BimestreOrientPlan, index: number) => {
          const {
            filterContenidos,
            filterHabilidades,
            filterInstrumentos,
            filterRecursos,
            filterSecciones,
            filterBloque,
          } = getfilters(bimestre);

          return (
            <Grid
              container
              spacing={2}
              className={classes.itemContainer}
              key={index}
            >
              <Grid item spacing={2} className={classes.containerBloque}>
                <ListItemProperties title="Bloque" values={filterBloque} />
              </Grid>
              <Grid item spacing={2} className={classes.containerList}>
                <ListItemProperties
                  title="Habilidades"
                  values={filterHabilidades}
                />
              </Grid>
              <Grid item className={classes.containerContenidos}>
                <ListItemProperties
                  title="Contenidos Específicos"
                  values={filterContenidos}
                />
              </Grid>
              <Grid item spacing={2} className={classes.containerList}>
                <ListItemProperties title="Recursos" values={filterRecursos} />
              </Grid>
              <Grid item className={classes.containerIntrumentos}>
                <ListItemProperties
                  title="Instrumentos de evaluacion"
                  values={filterInstrumentos}
                />
              </Grid>
              <Grid item className={classes.containerActividad}>
                <Typography className={classes.textField}>Actividad</Typography>
                <Typography className={classes.valueField}>
                  {bimestre?.actividad}
                </Typography>
              </Grid>
              <Grid item className={classes.containerDescripcion}>
                <Typography className={classes.textField}>
                  Descripción
                </Typography>
                <Typography className={classes.valueField}>
                  {bimestre?.descripcion}
                </Typography>
              </Grid>
              <Grid item className={classes.containerHora}>
                <Typography className={classes.textField}>
                  Horas cátedra
                </Typography>
                <Typography className={classes.valueField}>
                  {bimestre?.horasCatedra}
                </Typography>
              </Grid>
              <Grid item>
                <Typography className={classes.textField}>Sección</Typography>
                {!loading && filterSecciones?.map(callbackFilterSeccion)}
              </Grid>
            </Grid>
          );
        })}
    </Grid>
  );
};
