import React, { useMemo } from 'react';
import { EspaciosArealPlan } from 'src/app/models';
import { useSeccionContext } from 'src/app/business';
import { Grid } from '@material-ui/core';
import { useParams } from 'react-router';
import { HeaderFeedback } from '../../../feedbacks/commons/components/FeedbackHeader';
import { useGruposSeccion } from '../../../hooks';

export type ArealInterarealFlowHeaderProps = {
  data: EspaciosArealPlan;
  toPrint?: boolean;
};

export const ArealInterarealFlowHeader = ({
  data,
  toPrint,
}: ArealInterarealFlowHeaderProps) => {
  const { data: secciones, working: wSeccion } = useSeccionContext();
  const { idEspacioCurricular } = useParams();
  const { transformeGrupos, working } = useGruposSeccion([idEspacioCurricular]);
  const filterSecciones = useMemo(
    () =>
      [...(secciones || []), ...transformeGrupos].filter((item) =>
        data?.arealPlan.informacionGeneral.secciones?.find(
          (_item) => _item === item.idSeccion,
        ),
      ),
    [secciones, data?.arealPlan.informacionGeneral.secciones, transformeGrupos],
  );

  const formateDataHeader = useMemo(
    () => ({
      disciplina: data.espacioCurricular.materia.descripcion,
      area: data.espacioCurricular.materia.area.descripcion,
      orientacion: '',
      seccion: filterSecciones
        .map((seccion: any) => seccion?.nombreSeccion)
        .join(', '),
      fecha: data.arealPlan.ultimaActualizacion,
      autor: `${data?.arealPlan?.cuentas?.nombreUsuario} ${data?.arealPlan?.cuentas?.apellidoUsuario}`,
      nombre: data.arealPlan.proyecto.nombre,
      descripcion: data.arealPlan.proyecto.descripcion,
    }),
    [
      filterSecciones,
      data.arealPlan?.cuentas?.apellidoUsuario,
      data.arealPlan.proyecto.descripcion,
      data.arealPlan.proyecto.nombre,
      data.arealPlan.ultimaActualizacion,
      data.espacioCurricular.materia.area.descripcion,
      data.espacioCurricular.materia.descripcion,
      data?.arealPlan?.cuentas?.nombreUsuario,
    ],
  );

  return (
    <Grid container direction="column">
      {!working && !wSeccion && (
        <HeaderFeedback data={formateDataHeader} toPrint={toPrint} />
      )}
    </Grid>
  );
};
