import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { TipoPlanificacion } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'tipo_planificacion';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idTipoPlanificacion',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idTipoPlanificacion',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.TipoPlanificacion
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.TipoPlanificacion,
      hoc: { ...defaultOptions.hoc, ...businessOptions.TipoPlanificacion.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<TipoPlanificacion>(DAO_NAME, optionsToUse);

// hooks
export const useTipoPlanificacionFetchById = fetchById;
export const useTipoPlanificacionLibbyCall = libbyCall;
export const useTipoPlanificacionDAO = libbyDAO;
export const useTipoPlanificacionLibbyFetch = libbyFetch;

// components/hoc
export const TipoPlanificacionAutocomplete = entityAutocomplete;
export const TipoPlanificacionCall = renderPropCall;
export const TipoPlanificacionById = renderPropFetchById;
export const TipoPlanificacionSelect = simpleSelect;
export const TipoPlanificacionAccordionSelector = accordionSelector;
export const TipoPlanificacionSelectedChips = selectedChips;

// context
// ByIdContext
export const useTipoPlanificacionByIdContext = useByIdContext;
export const TipoPlanificacionByIdProvider = ByIdProvider;
export const TipoPlanificacionByIdContext = ByIdContext;
// GeneralContext
export const useTipoPlanificacionContext = useGeneralContext;
export const TipoPlanificacionProvider = GeneralProvider;
export const TipoPlanificacionContext = GeneralContext;
