import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import moment from 'moment';
import MobileStepper from '@material-ui/core/MobileStepper';
import { LibbyObject, AnyObject } from 'src/commons';
import customFormDialog from 'src/commons/services/customFormDialog';
import { Footer, Loading } from 'src/commons/components';
import {
  useProyeccionEstadoLibbyFetch,
  useSolicitudPasesDAO,
} from 'src/app/business';
import { CicloLectivo, SolicitudPases } from 'src/app/models';
import { statusPassesRequest } from 'src/commons/const/';
import { Grid, Typography } from '@material-ui/core';
import { SelectStudentParent } from './components/SelectStudent';
import { ConfigurePass } from './components/ConfigurePass';
import { useSnackbar } from 'notistack';
import { DEFAULT_HIDE_TIME } from 'src/commons/const/ui/snackbar';

const { PENDING } = statusPassesRequest;
interface PassRequestModalPropsTypes {
  idLocation: number;
  libby: LibbyObject;
  // eslint-disable-next-line
  reFetch: Function;
  nivel: number;
  badgeGestionDePaseReFetch: () => void;
  cicloLectivo: CicloLectivo;
}

const PassRequestModalRaw = ({
  libby,
  reFetch,
  idLocation,
  nivel,
  badgeGestionDePaseReFetch,
  cicloLectivo,
}: PassRequestModalPropsTypes) => {
  const [formValues, setFormValues] = useState({
    idTurno: '',
    comment: '',
  });
  const [havePase, setHavePase] = useState(true);
  const [loading, setLoading] = useState(false);
  const solicitudPaseDAO = useSolicitudPasesDAO();
  const [submitting, setSubmitting] = useState(false);

  const [activeStep, setActiveStep] = useState(0);
  const [selectStudent, setSelectStudent] = useState<AnyObject | null>(null);
  const { enqueueSnackbar } = useSnackbar();

  const studentSeccionFilter = useMemo(
    () => ({
      localizacion: [
        {
          path: 'localizacion',
          value: selectStudent?.seccion?.localizacion?.idLocalizacion,
        },
      ],
      nivel: [
        {
          path: 'nivel',
          value: selectStudent?.seccion?.nivel?.idNivel,
        },
      ],
    }),
    [selectStudent],
  );

  const { data: proyeccionEstado = [], working: loadingPE } =
    useProyeccionEstadoLibbyFetch({
      filter: studentSeccionFilter,
      limit: 1,
      orderBy: 'cicloLectivo',
      direction: 'desc',
      enabled: Boolean(selectStudent?.seccion?.localizacion?.idLocalizacion),
    });

  const [cicloLectivoDistinto, setCicloLectivoDistinto] =
    useState<boolean>(false);

  useEffect(() => {
    (async () => {
      if (selectStudent && proyeccionEstado.length > 0) {
        const cicloLectivoOrigen =
          proyeccionEstado?.[0]?.cicloLectivo?.idCicloLectivo;
        if (
          cicloLectivoOrigen &&
          cicloLectivoOrigen !== cicloLectivo?.idCicloLectivo
        ) {
          setCicloLectivoDistinto(true);
        } else {
          setCicloLectivoDistinto(false);
        }
      }
    })();
  }, [selectStudent, cicloLectivo, proyeccionEstado, cicloLectivoDistinto]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    const dataForm: AnyObject = {
      ...selectStudent,
      ...formValues,
    };

    try {
      const {
        idTurno,
        comment,
        seccion: {
          localizacion: { idLocalizacion: idOriginLocation },
        },
        seccion: {
          nivel: { idNivel },
        },
        alumno: { idAlumno },
      } = dataForm;

      const solicitudPase = {
        estadoSolicitudPases: PENDING,
        localizacionOrigen: idOriginLocation,
        localizacionDestino: idLocation,
        anio: dataForm.seccion?.anio?.idAnio ?? null,
        nivel: idNivel,
        alumno: idAlumno,
        turno: idTurno,
        fechaPedida: moment().toISOString(),
        fechaPase: moment().toISOString(),
        observacion: comment,
      };
      await libby.solicitud_pases.save(solicitudPase);
      enqueueSnackbar(`La solicitud de pase se ha realizado con éxito.`, {
        variant: 'success',
        autoHideDuration: DEFAULT_HIDE_TIME,
      });
      customFormDialog.handleCancel();
      reFetch();
      badgeGestionDePaseReFetch();
      setSubmitting(false);
    } catch (e) {
      enqueueSnackbar(
        `Ha ocurrido un error al intentar realizar la solicitud de pase, por favor intente de nuevo.`,
        { variant: 'error', autoHideDuration: DEFAULT_HIDE_TIME },
      );
      setSubmitting(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [libby, reFetch, selectStudent, formValues, badgeGestionDePaseReFetch]);

  const buttonConfig: any = {
    0: [
      {
        title: 'Cancel',
        handleOnClick: () => customFormDialog.handleCancel(),
        type: 'secondary',
        size: 'small',
      },
      {
        title: 'Siguiente',
        handleOnClick: () => handleNext(),
        size: 'small',
        disabled: havePase || cicloLectivoDistinto || loading,
      },
    ],
    1: [
      {
        title: 'Cancel',
        handleOnClick: () => customFormDialog.handleCancel(),
        type: 'secondary',
        size: 'small',
      },
      {
        title: 'Atrás',
        handleOnClick: () => handleBack(),
        type: 'secondary',
        size: 'small',
        customStyle: { border: '1px solid' },
      },
      {
        title: 'Guardar',
        handleOnClick: () => handleSubmit(),
        size: 'small',
        disabled: !formValues.idTurno || submitting,
      },
    ],
  };

  const handleChange = (e: React.FormEvent<EventTarget>): void => {
    const target = e.target as HTMLInputElement;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  useEffect(() => {
    (async () => {
      if (selectStudent) {
        setLoading(true);
        const filter = {
          alumno: [{ path: 'alumno', value: selectStudent?.alumno?.idAlumno }],
          estado: [{ path: 'estadoSolicitudPases', value: 3 }],
        };
        const solicitudBy: SolicitudPases[] = await solicitudPaseDAO.fetch({
          filter,
          aspect: 'matriculacion_gestionDePases_solicitudDePasesRequest',
        });
        if (solicitudBy.length) {
          setHavePase(true);
        } else {
          setHavePase(false);
        }
        setLoading(false);
      } else {
        setHavePase(true);
      }
    })();
  }, [selectStudent, solicitudPaseDAO]);

  const passManagement1 = (
    <SelectStudentParent
      selectStudent={selectStudent}
      location={idLocation}
      nivel={nivel}
      setSelectStudent={setSelectStudent}
      cicloLectivo={cicloLectivo}
    />
  );
  const passManagement2 = (
    <ConfigurePass
      studentSelect={selectStudent}
      formValues={formValues}
      handleChange={handleChange}
    />
  );

  const tutorialSteps = [
    { render: passManagement1 },
    { render: passManagement2 },
  ];
  const maxSteps = tutorialSteps.length;

  const hasError = havePase || cicloLectivoDistinto;

  return (
    <>
      <div style={{ width: '100%', paddingBottom: '15px' }}>
        <div>
          {tutorialSteps[activeStep].render}
          <MobileStepper
            steps={maxSteps}
            activeStep={activeStep}
            backButton=""
            nextButton=""
          />
        </div>
      </div>
      {selectStudent && hasError && (
        <Grid
          container
          style={{
            justifyContent: 'flex-end',
            marginBottom: '20px',
            paddingRight: '20px',
          }}
        >
          {loading || loadingPE ? (
            <Loading />
          ) : cicloLectivoDistinto ? (
            <Typography color="error">
              Alumno matriculado en otro ciclo lectivo
            </Typography>
          ) : (
            <Typography color="error">
              El alumno seleccionado ya tiene un pase iniciado.
            </Typography>
          )}
        </Grid>
      )}
      <Footer buttonConfig={buttonConfig[activeStep]} spacing={1} />
    </>
  );
};

export const PassRequestModal = DatabaseConnector(PassRequestModalRaw)(
  'solicitud_pases',
  'alumno',
);
