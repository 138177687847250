import {
  Grid,
  Typography,
  IconButton,
  makeStyles,
  Tooltip,
} from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import {
  Footer,
  MainInput,
  SimpleSelect,
  Loading,
  level,
  ButtonTypesProps,
} from 'src/commons';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { useOrganizacionesLibbyFetch } from 'src/app/business/acap/Organizaciones';
import { useAccionesSoloOrientacionesLibbyFetch } from 'src/app/business/acap';
import { useTiposAccionesLibbyFetch } from 'src/app/business/acap/TiposAcciones';
import {
  useTurnoLibbyFetch,
  useLocalizacionPlanEstudioNivelLibbyFetch,
} from 'src/app/business';
import { TurnoComponent } from './TurnoComponent';
import { GrupoComponent } from './GrupoComponent';
import { DatePicker } from 'src/app/components/inputs/Input/DatePicker';
import { Turnos } from '../utils';
import _ from 'lodash';
import confirmDialog from 'src/commons/services/confirmDialog';
import {
  AccionOrientacion,
  CicloLectivo,
  Localizacion,
  Sector,
} from 'src/app/models';
import { OfertaStep } from '../tabs/oferta';
import { Box } from '@material-ui/core';
import { useGeneralContext } from 'src/context';

const useStyles = makeStyles({
  buttonLoading: {
    height: '20px',
    padding: '0',
    display: 'flex',
    alignContent: 'center',
  },
  tooltip: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    height: 'fit-content',
    width: 'fit-content',
    padding: '1em',
    backgroundColor: '#eee',
    color: 'black',
    border: 'solid 0.5px #333',
    fontSize: '1rem',
  },
});

export interface FormValuesOferta {
  idOferta?: number;
  cicloLectivo?: number;
  fechaPublicacion?: string;
  fechaInicio?: string;
  fechaFin?: string;
  accion?: string;
  tipoAccion?: string;
  orientacion?: AccionOrientacion[];
  horasAcap?: string;
  sector?: string;
  organizacion?: string;
}

interface InitialValue {
  formValues: FormValuesOferta;
  gruposValues: any[];
  turnosValues: any[];
  jornadasValues: any[];
}

interface OfertaCreacionFormProps {
  sectores: Sector[];
  handleSave: ({
    formValues,
    gruposValues,
    turnosValues,
    nuevaOferta,
  }: any) => Promise<void>;
  ofertaStep: OfertaStep;
  setOfertaStep: React.Dispatch<React.SetStateAction<OfertaStep>>;
  ofertasReFetch: () => void;
  ciclosLectivos: CicloLectivo[];
}

export const OfertaCreacionForm = ({
  sectores,
  handleSave,
  ofertaStep,
  setOfertaStep,
  ofertasReFetch,
  ciclosLectivos,
}: OfertaCreacionFormProps) => {
  const [loading, setLoading] = useState(false);

  const { generalState} = useGeneralContext()

  const currentYear = new Date(generalState.date.toString()).getFullYear();

  const ciclo = ciclosLectivos.find((ciclo) => ciclo.anio === currentYear);

  const [formValues, setFormValues] = useState<FormValuesOferta>({
    cicloLectivo: ciclo?.idCicloLectivo,
  });

  const [initialValues, setInitialValues] = useState<InitialValue>();
  const [turnosValues, setTurnosValues] = useState([{}]);
  const [turnosCount, setTurnosCount] = useState([1]);
  const [gruposCount, setGruposCount] = useState([1]);
  const [gruposValues, setGruposValues] = useState([{ jornadas: [{}] }]);

  const filterSector = useMemo(
    () => ({
      0: Boolean(
        formValues?.sector
          ? [
              {
                path: 'sector.idSector',
                value: formValues?.sector,
              },
            ]
          : [],
      ),
    }),
    [formValues?.sector],
  );

  const filterAcciones = useMemo(
    () => ({
      0: Boolean(formValues?.tipoAccion)
        ? [
            {
              path: 'tipoAccion.idTipoAccion',
              value: formValues?.tipoAccion,
            },
          ]
        : [],
      1: Boolean(formValues?.organizacion)
        ? [
            {
              path: 'organizacion.idOrganizacion',
              value: formValues?.organizacion,
            },
          ]
        : [],
      2: Boolean(formValues?.cicloLectivo)
        ? [
            {
              path: 'cicloLectivo',
              value: formValues?.cicloLectivo,
            },
          ]
        : [],
      actives: [
        {
          path: 'deletedAt',
          value: null,
          method: 'isnull',
        },
      ],
    }),
    [
      formValues?.organizacion,
      formValues?.tipoAccion,
      formValues?.cicloLectivo,
    ],
  );

  const { data: acciones = [], working: workingAcciones } =
    useAccionesSoloOrientacionesLibbyFetch({
      limit: 100,
      filter: filterAcciones,
      enabled:
        Boolean(formValues?.tipoAccion) ||
        Boolean(formValues?.organizacion) ||
        Boolean(formValues?.cicloLectivo),
    });

  const { data: tipoAcciones = [], working: workingTipoAcciones } =
    useTiposAccionesLibbyFetch({
      limit: 500,
    });

  const { data: organizaciones = [], working: workingOrganizaciones } =
    useOrganizacionesLibbyFetch({
      limit: 500,
      filter: filterSector,
    });

  const { data: turnos = [] } = useTurnoLibbyFetch();
  const filteredTurnos = turnos.filter(
    (turno) => turno.idTurno !== Turnos.DOBLE && turno.idTurno !== Turnos.NOCHE,
  );

  useEffect(() => {
    if (ciclo) {
      setFormValues((prevFilters) => ({
        ...prevFilters,
        cicloLectivo: ciclo?.idCicloLectivo,
      }));
    }
  }, [ciclo]);

  const classes = useStyles();
  const guardarDisabled = useMemo(() => {
    const disabled =
      !formValues?.fechaPublicacion ||
      !formValues?.fechaInicio ||
      !formValues?.fechaFin ||
      !formValues?.accion;

    const turnosCheck = turnosValues.filter(
      (turno: any) => turno?.cupo && turno?.cantGrupos && turno?.turno,
    );

    const gruposCheck = gruposValues.filter((grupo: any) => {
      const valoresObligatorios =
        grupo?.tipoCupo &&
        grupo?.turno &&
        grupo?.fechaFin &&
        grupo?.fechaInicio &&
        grupo?.referente?.nombre &&
        grupo?.referente?.apellido &&
        grupo?.referente?.telefono &&
        grupo?.referente?.email;
      if (grupo?.tipoCupo === 'Mixto' || grupo?.tipoCupo === 'Cerrado') {
        if (grupo?.cupoEstatal > 0 && grupo?.cupoPrivado > 0)
          return (
            valoresObligatorios &&
            grupo?.localizacion &&
            grupo?.establecimientoPrivado
          );
        else if (
          grupo?.cupoEstatal > 0 &&
          (grupo?.cupoPrivado === 0 || grupo?.cupoPrivado === '')
        )
          return valoresObligatorios && grupo?.localizacion;
        else if (
          (grupo?.cupoEstatal === 0 || grupo?.cupoEstatal === '') &&
          grupo?.cupoPrivado > 0
        )
          return valoresObligatorios && grupo?.establecimientoPrivado;
        else
          return (
            valoresObligatorios &&
            grupo?.localizacion &&
            grupo?.establecimientoPrivado &&
            grupo?.cupoEstatal &&
            grupo?.cupoPrivado
          );
      } else {
        return valoresObligatorios;
      }
    });
    let jornadasTotal = 0;
    let jornadasChecked = 0;
    let jornadaHoraCheck = 0;
    gruposValues.forEach((grupo: any) => {
      const jornadasCheck = grupo?.jornadas?.filter(
        (jornada: any) =>
          jornada?.cupo >= 0 &&
          jornada?.cupo !== '' &&
          jornada?.horaInicio &&
          jornada?.horaFin &&
          jornada?.sede,
      );
      const horaCheck = grupo?.jornadas?.filter(
        (jornada: any) => jornada?.horaInicio > jornada?.horaFin,
      );
      jornadasChecked += jornadasCheck ? jornadasCheck.length : 0;
      jornadasTotal += grupo?.jornadas ? grupo?.jornadas.length : 0;
      jornadaHoraCheck += horaCheck ? horaCheck.length : 0;
    });
    const disableJornadas =
      !(jornadasTotal === jornadasChecked) || Boolean(jornadaHoraCheck);
    const disableTurnos = !(turnosValues.length === turnosCheck.length);
    const disableGrupos = !(gruposValues.length === gruposCheck.length);

    return disabled || disableTurnos || disableGrupos || disableJornadas;
  }, [
    formValues?.fechaFin,
    formValues?.fechaInicio,
    formValues?.fechaPublicacion,
    formValues?.accion,
    turnosValues,
    gruposValues,
  ]);

  const hasSomething = useMemo(() => {
    const hasValues =
      formValues?.fechaPublicacion ||
      formValues?.fechaInicio ||
      formValues?.fechaFin ||
      formValues?.accion;

    const turnosCheck = turnosValues.filter(
      (turno: any) =>
        turno?.cupo || turno?.cantGrupos || turno?.turno || turno?.cupoGrupos,
    );
    const gruposCheck = gruposValues.filter((grupo: any) => {
      const valoresObligatorios =
        grupo?.tipoCupo ||
        grupo?.turno ||
        grupo?.fechaFin ||
        grupo?.fechaInicio ||
        grupo?.referente?.nombre ||
        grupo?.referente?.apellido ||
        grupo?.referente?.telefono ||
        grupo?.referente?.email ||
        grupo?.referente?.codigo;
      if (grupo?.tipoCupo === 'Mixto' || grupo?.tipoCupo === 'Cerrado') {
        if (grupo?.cupoEstatal > 0 && grupo?.cupoPrivado > 0)
          return (
            valoresObligatorios ||
            grupo?.localizacion ||
            grupo?.establecimientoPrivado
          );
        else if (
          grupo?.cupoEstatal > 0 &&
          (grupo?.cupoPrivado === 0 || grupo?.cupoPrivado === '')
        )
          return valoresObligatorios || grupo?.localizacion;
        else if (
          (grupo?.cupoEstatal === 0 || grupo?.cupoEstatal === '') &&
          grupo?.cupoPrivado > 0
        )
          return valoresObligatorios || grupo?.establecimientoPrivado;
        else
          return (
            valoresObligatorios ||
            grupo?.localizacion ||
            grupo?.establecimientoPrivado ||
            grupo?.cupoEstatal ||
            grupo?.cupoPrivado
          );
      } else {
        return valoresObligatorios;
      }
    });
    let jornadasChecked = 0;
    gruposValues.forEach((grupo: any) => {
      const jornadasCheck = grupo?.jornadas?.filter(
        (jornada: any) =>
          (jornada?.cupo >= 0 && jornada?.cupo !== '') ||
          jornada?.horaInicio ||
          jornada?.horaFin ||
          jornada?.sede,
      );
      jornadasChecked += jornadasCheck ? jornadasCheck.length : 0;
    });
    const hasJornadas = Boolean(jornadasChecked);
    const hasTurnos = Boolean(turnosCheck.length);
    const hasGrupos = Boolean(gruposCheck.length);

    return hasValues || hasTurnos || hasGrupos || hasJornadas;
  }, [
    formValues?.accion,
    formValues?.fechaFin,
    formValues?.fechaInicio,
    formValues?.fechaPublicacion,
    gruposValues,
    turnosValues,
  ]);

  const emailReferenteCheck = useMemo(() => {
    const checkEmail = gruposValues.map((grupo: any) => {
      const emailDomain =
        grupo?.referente?.email?.split('@')[1]?.split('.') || [];
      return (grupo?.referente?.email !== '' &&
        grupo?.referente?.email[0] === '-') ||
        emailDomain.some(
          (domain: string) =>
            domain[0] === '-' || domain[domain?.length - 1] === '-',
        )
        ? false
        : grupo?.referente?.email !== ''
        ? // eslint-disable-next-line
          /^[a-zA-Z\'\-_.\u00C0-\u017F\d-]+@(?:[a-zA-Z0-9\'\-\u00C0-\u017F]+\.)+[A-Za-z]+$/.test(
            grupo?.referente?.email,
          )
        : true;
    });

    return checkEmail.includes(false);
  }, [gruposValues]);

  useEffect(() => {
    if (ofertaStep?.rowCreating !== null) {
      const formValuesObject: FormValuesOferta = {
        idOferta: ofertaStep?.rowCreating?.idOferta,
        fechaFin: ofertaStep?.rowCreating?.fechaFin,
        fechaInicio: ofertaStep?.rowCreating?.fechaInicio,
        fechaPublicacion: ofertaStep?.rowCreating?.fechaPublicacion,
        accion: ofertaStep?.rowCreating?.accion?.idAccion,
        tipoAccion: ofertaStep?.rowCreating?.accion?.tipoAccion?.idTipoAccion,
        organizacion:
          ofertaStep?.rowCreating?.accion?.organizacion?.idOrganizacion,
        sector: ofertaStep?.rowCreating?.accion?.organizacion?.sector?.idSector,
        horasAcap: ofertaStep?.rowCreating?.accion?.horas,
        orientacion: ofertaStep?.rowCreating?.accion?.accionOrientaciones,
        cicloLectivo:
          ofertaStep?.rowCreating?.accion?.cicloLectivo?.idCicloLectivo,
      };
      let countTurnos: any = [];
      let countGrupos: any = [];
      const turnosArray: any = [];
      const gruposArray: any = [];
      ofertaStep?.rowCreating?.turnos?.forEach((turno: any, index: any) => {
        countTurnos.push(index - 1);
        const cupoPorGrupo = turno?.cupo / turno?.cantGrupos;
        turnosArray.push({
          idOfertaTurno: turno?.idOfertaTurno,
          cupo: turno?.cupo,
          cantGrupos: turno?.cantGrupos,
          turno: turno?.turno?.idTurno,
          cupoGrupos: cupoPorGrupo,
        });
        turno?.grupos
          ?.sort((a: any, b: any) => a.idOfertaGrupo - b.idOfertaGrupo)
          .forEach((grupo: any, index: any) => {
            countGrupos.push(index - 1);
            gruposArray.push({
              cupoEstatal: grupo?.cupoEstatal,
              cupoPrivado: grupo?.cupoPrivado,
              establecimientoPrivado: grupo?.establecimientoPrivado,
              localizacion: grupo?.localizacion?.idLocalizacion,
              idOfertaGrupo: grupo?.idOfertaGrupo,
              turno: turno?.turno?.idTurno,
              tipoCupo: grupo?.tipoCupo,
              fechaFin: grupo?.fechaFin,
              fechaInicio: grupo?.fechaInicio,
              referente: {
                idReferente: grupo?.referente?.idReferente,
                nombre: grupo?.referente?.nombre,
                apellido: grupo?.referente?.apellido,
                telefono: grupo?.referente?.telefono,
                email: grupo?.referente?.email,
                codigo: grupo?.referente?.codigo ?? 'Miescuela123',
              },
              jornadas: grupo?.jornadas,
            });
          });
      });

      setTurnosCount(countTurnos);
      setGruposCount(countGrupos);
      setTurnosValues(turnosArray);
      setGruposValues(gruposArray);
      setFormValues(formValuesObject);
      setInitialValues({
        formValues: formValuesObject,
        gruposValues: gruposArray,
        turnosValues: turnosArray,
        jornadasValues: gruposArray.map((grupo: any) => ({
          jornadas: grupo?.jornadas,
          fechaFin: grupo?.fechaFin,
          fechaInicio: grupo?.fechaInicio,
        })),
      });
    }
  }, [ofertaStep?.rowCreating]);

  const filterSecundariaFuturo = useMemo(
    () => ({
      0: [
        {
          path: 'anio',
          value: 25,
        },
      ],
      1: [
        {
          path: 'establecimiento.dependenciaFuncional',
          method: 'notEquals',
          value: 32,
        },
      ],
    }),
    [],
  );

  const filterLocalizacion = useMemo(
    () => ({
      1: [
        {
          path: 'planEstudioNivel.modalidadNivel.nivel',
          value: 3,
        },
      ],
      2: [
        {
          path: 'localizacion.establecimiento.dependenciaFuncional',
          value: 32,
          method: 'notEquals',
        },
      ],
      3: [
        {
          path: 'localizacion.establecimiento.dependenciaFuncional',
          value: 31,
        },
        {
          path: 'localizacion.establecimiento.dependenciaFuncional',
          value: 41,
        },
        {
          path: 'localizacion.establecimiento.dependenciaFuncional',
          value: 70,
        },
      ],
      4: [
        {
          path: 'localizacion.establecimiento.cue',
          value: 202347,
          method: 'notEquals',
        },
      ],
      5: [
        {
          path: 'localizacion.establecimiento.cue',
          value: 202348,
          method: 'notEquals',
        },
      ],
      6: [
        {
          path: 'localizacion.establecimiento.cue',
          value: 202349,
          method: 'notEquals',
        },
      ],
      7: [
        {
          path: 'localizacion.establecimiento.cue',
          value: 202351,
          method: 'notEquals',
        },
      ],
      8: [
        {
          path: 'localizacion.establecimiento.cue',
          value: 202352,
          method: 'notEquals',
        },
      ],
      9: [
        {
          path: 'localizacion.establecimiento.cue',
          value: 202400,
          method: 'notEquals',
        },
      ],
      10: [
        {
          path: 'localizacion.establecimiento.cue',
          value: 202033,
          method: 'notEquals',
        },
      ],
      11: [
        {
          path: 'localizacion.establecimiento.cue',
          value: 202407,
          method: 'notEquals',
        },
      ],
      12: [
        {
          path: 'localizacion.establecimiento.cue',
          value: 202038,
          method: 'notEquals',
        },
      ],
      13: [
        {
          path: 'localizacion.establecimiento.cue',
          value: 202039,
          method: 'notEquals',
        },
      ],
      14: [
        {
          path: 'localizacion.establecimiento.cue',
          value: 202422,
          method: 'notEquals',
        },
      ],
      15: [
        {
          path: 'localizacion',
          value: 22222200,
          method: 'notEquals',
        },
      ],
      16: [
        {
          path: 'localizacion',
          value: 20093001,
          method: 'notEquals',
        },
      ],
    }),
    [],
  );

  const { data: localizaciones = [] } =
    useLocalizacionPlanEstudioNivelLibbyFetch({
      limit: 5000,
      filter: filterLocalizacion,
      aspect: 'acap_localizacion',
      orderBy:
        'localizacion.establecimiento.dependenciaFuncional.idDependenciaFuncional,localizacion.establecimiento.distritoEscolar.idDistritoEscolar',
    });

  const localizacionFiltered = useMemo(() => {
    const localizacionFilteredArray: Localizacion[] = [];

    localizaciones?.forEach((localizacion: any, index: number) => {
      const alreadyExistsInArray = localizacionFilteredArray.some(
        (localizacionFilteredSome: any) => {
          return (
            localizacionFilteredSome?.localizacion?.idLocalizacion ===
            localizacion?.localizacion?.idLocalizacion
          );
        },
      );

      if (
        !alreadyExistsInArray &&
        (!localizacion?.localizacion?.descripcion?.includes('DEMO') ||
          localizacion?.localizacion?.descripcion ===
            'ESCUELA DEMO SECUNDARIA DEL FUTURO' ||
          localizacion?.localizacion?.descripcion === 'ESCUELA DEMO COMÚN')
      ) {
        localizacionFilteredArray?.push(localizacion);
      }
    });

    return localizacionFilteredArray;
  }, [localizaciones]);

  const sortedOrganizaciones = organizaciones.sort((orgA, orgB) => {
    if (orgA.nombre < orgB.nombre) {
      return -1;
    }
    if (orgA.nombre > orgB.nombre) {
      return 1;
    }
    return 0;
  });

  const agregarTurno = () => {
    setTurnosCount([...turnosCount, turnosCount.length + 1]);
    setTurnosValues([...turnosValues, {}]);
  };
  const agregarGrupo = () => {
    setGruposCount([...gruposCount, gruposCount.length + 1]);
    setGruposValues([...gruposValues, {}]);
  };

  const borrarTurno = async (index: number, sede: any) => {
    setTurnosCount((prev: any) =>
      prev.filter(
        (elem: any, indexFiltered: number) => index !== indexFiltered,
      ),
    );
    setTurnosValues((prev: any) =>
      prev.filter(
        (elem: any, indexFiltered: number) => index !== indexFiltered,
      ),
    );
  };
  const borrarGrupo = async (index: number, sede: any) => {
    setGruposCount((prev: any) =>
      prev.filter(
        (elem: any, indexFiltered: number) => index !== indexFiltered,
      ),
    );
    setGruposValues((prev: any) =>
      prev.filter(
        (elem: any, indexFiltered: number) => index !== indexFiltered,
      ),
    );
  };

  const isEquals = useMemo(() => {
    const isEqual = _.isEqual(initialValues, {
      formValues: formValues,
      gruposValues: gruposValues,
      turnosValues: turnosValues,
      jornadasValues: gruposValues.map((grupo: any) => ({
        jornadas: grupo?.jornadas,
        fechaFin: grupo?.fechaFin,
        fechaInicio: grupo?.fechaInicio,
      })),
    });
    return isEqual;
  }, [initialValues, formValues, gruposValues, turnosValues]);

  const noDuplicateAccion = ofertaStep.enableDuplicate
    ? (formValues?.accion?.length || 0) > 0 ||
      formValues.accion?.length !== undefined
    : false;

  const readonlyButtonConfig: ButtonTypesProps[] = [
    {
      title: 'Salir',
      handleOnClick: async () => {
        let confirm;
        if (
          (!isEquals && initialValues !== undefined) ||
          (hasSomething && initialValues === undefined)
        ) {
          confirm = await confirmDialog.show({
            title: '¿Desea continuar?',
            content:
              'La información precargada se perderá en caso que no guarde, por favor seleccione una opción.',
            confirmText: 'Salir sin guardar',
            cancelText: 'Seguir en esta pantalla',
          });

          if (confirm) {
            ofertasReFetch();
            setOfertaStep((prevState: any) => ({
              ...prevState,
              enabledCreating: false,
              enableDuplicate: false,
              rowCreating: null,
            }));
          }
        } else {
          ofertasReFetch();
          setOfertaStep((prevState: any) => ({
            ...prevState,
            enabledCreating: false,
            enableDuplicate: false,
            rowCreating: null,
          }));
        }
      },
      type: 'secondary',
      size: 'medium',
    },
    {
      title: loading ? (
        <Loading className={classes.buttonLoading} />
      ) : ofertaStep.enableDuplicate ? (
        'Duplicar'
      ) : (
        'Guardar'
      ),
      handleOnClick: async () => {
        setLoading(true);
        await handleSave({
          formValues,
          gruposValues,
          turnosValues,
          isDuplicated: true,
        });
        setLoading(false);
      },
      type: 'primary',
      size: 'medium',
      disabled:
        loading || isEquals || guardarDisabled || emailReferenteCheck /* ||
        noDuplicateAccion, */,
    },
    {
      hidden: ofertaStep.enableDuplicate,
      title: loading ? (
        <Loading className={classes.buttonLoading} />
      ) : (
        'Guardar y agregar nueva oferta'
      ),
      handleOnClick: async () => {
        setLoading(true);
        await handleSave({
          formValues,
          gruposValues,
          turnosValues,
          nuevaOferta: true,
        });
        setFormValues(undefined);
        setTurnosValues([{}]);
        setTurnosCount([1]);
        setGruposCount([1]);
        setGruposValues([{}]);
        setLoading(false);
      },
      type: 'primary',
      size: 'medium',
      disabled: loading || isEquals || guardarDisabled || emailReferenteCheck,
    },
  ];

  const isDuplicating = ofertaStep.enableDuplicate;

  const accionError = !workingAcciones
    ? isDuplicating && acciones.length === 0
    : false;
  const accionHelperText = accionError
    ? 'Es necesario duplicar acción para poder duplicar la oferta'
    : ' ';

  let maxGruposGlobal =
    turnosValues
      ?.map((turno: any) => Number(turno?.cantGrupos))
      .reduce((a: any, b: any) => a + b, 0) || 0;

  return !loading ? (
    <>
      <Grid
        container
        justifyContent="space-around"
        alignItems="center"
        style={{ marginTop: '15px' }}
      >
        <Grid
          container
          item
          md={6}
          alignItems="center"
          style={{
            marginTop: '30px',
          }}
        >
          <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>Sector:</p>
          <SimpleSelect
            name="sector"
            value={formValues?.sector}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFormValues((prevState: any) => ({
                ...prevState,
                sector: value,
              }));
            }}
            placeholder="Seleccioná sector"
            content={sectores?.length > 0 ? sectores : []}
            labelKey="nombre"
            valueKey="idSector"
            customStyleContainer={{ width: '60%' }}
            disabled={ofertaStep.enableDuplicate}
          />
        </Grid>
        <Grid
          container
          item
          md={6}
          alignItems="center"
          style={{
            marginTop: '30px',
          }}
        >
          <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
            Organización:
          </p>
          <SimpleSelect
            name="organizacion"
            value={formValues?.organizacion || ''}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFormValues((prevState: any) => ({
                ...prevState,
                organizacion: value,
              }));
            }}
            placeholder="Seleccioná organización"
            content={workingOrganizaciones ? [] : sortedOrganizaciones}
            labelKey="nombre"
            valueKey="idOrganizacion"
            disabled={ofertaStep.enableDuplicate}
            customStyleContainer={{ width: '60%' }}
          />
        </Grid>

        <Grid
          container
          item
          md={6}
          alignItems="center"
          style={{
            marginTop: '20px',
          }}
        >
          <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
            Ciclo Lectivo:
          </p>

          <SimpleSelect
            name="CicloLectivo"
            value={formValues?.cicloLectivo || ''}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFormValues((prevState: any) => ({
                ...prevState,
                cicloLectivo: value,
              }));
            }}
            placeholder="Seleccioná ciclo"
            content={ciclosLectivos}
            labelKey="anio"
            valueKey="idCicloLectivo"
            customStyleContainer={{ width: '55%' }}
          />
        </Grid>

        <Grid
          container
          item
          md={6}
          alignItems="center"
          style={{
            marginTop: '20px',
          }}
        >
          <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
            Tipo de acción:
          </p>
          <SimpleSelect
            name="tipoAccion"
            value={formValues?.tipoAccion || ''}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFormValues((prevState: any) => ({
                ...prevState,
                tipoAccion: value,
              }));
            }}
            placeholder="Seleccioná tipo de acción"
            content={workingTipoAcciones ? [] : tipoAcciones}
            labelKey="nombre"
            valueKey="idTipoAccion"
            disabled={ofertaStep.enableDuplicate}
            customStyleContainer={{ width: '60%' }}
          />
        </Grid>
        <Grid
          container
          item
          md={6}
          alignItems="center"
          style={{
            marginTop: '20px',
          }}
        >
          <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
            Acción:{' '}
            <span style={{ color: 'red' }}>
              {formValues?.accion ? '' : '*'}
            </span>
          </p>

          <SimpleSelect
            name="accion"
            value={formValues?.accion || ''}
            handleChange={(e: any) => {
              const value = e?.target?.value;
              setFormValues((prevState: any) => ({
                ...prevState,
                accion: value,
                accionOrientaciones: acciones?.find(
                  (accion) => accion.idAccion === value,
                )?.accionOrientaciones,
                organizacion: acciones?.find(
                  (accion) => accion.idAccion === value,
                )?.organizacion?.idOrganizacion,
                sector: acciones?.find((accion) => accion.idAccion === value)
                  ?.organizacion?.sector?.idSector,
                horasAcap: acciones?.find((accion) => accion.idAccion === value)
                  ?.horas,
              }));
            }}
            placeholder="Seleccioná acción"
            content={workingAcciones ? [] : acciones}
            labelKey="nombre"
            valueKey="idAccion"
            customStyleContainer={{ width: '60%' }}
            error={accionError}
            helperText={accionHelperText}
            disabled={!formValues?.organizacion}
          />
        </Grid>
        <Grid
          container
          item
          md={8}
          alignItems="center"
          style={{
            marginTop: '20px',
          }}
        >
          <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
            Orientación:
          </p>
          <Tooltip
            title={
              formValues?.orientacion !== undefined
                ? formValues?.orientacion
                    ?.map((el: any) => el.orientacion.nombre)
                    .join(', ')
                : formValues?.accionOrientaciones
                    ?.map((el: any) => el.orientacion.nombre)
                    .join(', ')
            }
          >
            <span>
              <MainInput
                value={
                  formValues?.orientacion !== undefined
                    ? formValues?.orientacion
                        ?.map((el: any) => el.orientacion.nombre)
                        .join(', ')
                    : formValues?.accionOrientaciones
                        ?.map((el: AccionOrientacion) => el.orientacion.nombre)
                        .join(', ')
                }
                disabled={true}
                handleChange={() => {}}
                placeholder="Orientaciones de la acción"
                fullWidth
                customStyle={{
                  width: '35rem',
                }}
              />
            </span>
          </Tooltip>
        </Grid>
        <Grid
          container
          item
          md={4}
          alignItems="center"
          style={{
            marginTop: '20px',
          }}
        >
          <p
            style={{
              fontFamily: 'Open Sans',
              marginRight: '8px',
              width: '25%',
            }}
          >
            Cantidad de horas ACAP:
          </p>
          <MainInput
            value={formValues?.horasAcap || ''}
            disabled={true}
            handleChange={() => {}}
            placeholder="0"
            fullWidth
          />
        </Grid>
        <Grid
          container
          item
          md={4}
          alignItems="center"
          style={{
            marginTop: '20px',
          }}
        >
          <p style={{ fontFamily: 'Open Sans' }}>
            Fecha de inicio:{' '}
            <span style={{ color: 'red' }}>
              {formValues?.fechaInicio ? '' : '*'}
            </span>
          </p>
          <DatePicker
            value={formValues?.fechaInicio || null}
            onChange={(_date: any) => {
              setFormValues({
                ...formValues,
                fechaInicio: _date?.toDate(),
                ...(_date?.toDate().getTime() >
                new Date(formValues?.fechaFin).getTime()
                  ? { fechaFin: _date?.toDate() }
                  : {}),
              });
            }}
          />
        </Grid>
        <Grid
          container
          item
          md={4}
          alignItems="center"
          style={{
            marginTop: '20px',
          }}
        >
          <Typography>
            Fecha fin:{' '}
            <Box component="span" style={{ color: 'red' }}>
              {Boolean(formValues.fechaFin) ? '' : '*'}
            </Box>
          </Typography>

          <DatePicker
            value={formValues?.fechaFin || null}
            disabled={formValues?.fechaInicio ? false : true}
            onChange={(_date: any) => {
              if (
                _date.toDate().getTime() >=
                new Date(formValues?.fechaInicio).getTime()
              ) {
                setFormValues({
                  ...formValues,
                  fechaFin: _date?.toDate(),
                });
              }
            }}
            minDate={formValues?.fechaInicio}
          />
        </Grid>
        <Grid
          container
          item
          md={4}
          alignItems="center"
          style={{
            marginTop: '20px',
          }}
        >
          <p style={{ fontFamily: 'Open Sans' }}>
            Fecha de publicación:{' '}
            <span style={{ color: 'red' }}>
              {formValues?.fechaPublicacion ? '' : '*'}
            </span>
          </p>
          <DatePicker
            value={formValues?.fechaPublicacion || null}
            disabled={formValues?.fechaInicio ? false : true}
            onChange={(_date: any) => {
              if (
                _date.toDate().getTime() <
                new Date(formValues?.fechaInicio).getTime()
              ) {
                setFormValues({
                  ...formValues,
                  fechaPublicacion: _date?.toDate(),
                });
              }
            }}
            maxDate={formValues?.fechaInicio}
          />
        </Grid>
      </Grid>
      <Grid
        container
        item
        md={12}
        alignItems="center"
        style={{
          marginTop: '20px',
        }}
      >
        <IconButton
          disabled={turnosValues.length >= filteredTurnos.length}
          onClick={() => agregarTurno()}
        >
          {turnosValues.length >= filteredTurnos.length ? (
            <Typography style={{ fontFamily: 'Open Sans', fontWeight: '700' }}>
              Máximo de turnos alcanzado
            </Typography>
          ) : (
            <>
              <Typography style={{ fontFamily: 'Open Sans' }}>
                Agregar turno
              </Typography>
              <AddCircleOutlineIcon
                color="primary"
                style={{ marginLeft: '5px' }}
              />
            </>
          )}
        </IconButton>
      </Grid>
      {turnosCount.map((turno: any, index: number) => {
        return (
          <TurnoComponent
            index={index}
            turnosValues={turnosValues}
            turnos={filteredTurnos}
            setTurnosValues={setTurnosValues}
            borrarTurno={borrarTurno}
            loading={loading}
            isDuplicating={isDuplicating}
          />
        );
      })}
      <Grid
        container
        item
        md={12}
        justifyContent="space-between"
        alignItems="center"
        style={{
          marginTop: '20px',
        }}
      >
        <IconButton
          disabled={
            gruposValues.length >= maxGruposGlobal ||
            turnosValues.some((turno: any) => turno.cantGrupos === 0)
          }
          onClick={() => agregarGrupo()}
        >
          {gruposValues.length >= maxGruposGlobal ? (
            <Typography style={{ fontFamily: 'Open Sans', fontWeight: '700' }}>
              Máximo de grupos alcanzado. Aumente en grupos por turno.
            </Typography>
          ) : (
            <>
              <Typography style={{ fontFamily: 'Open Sans' }}>
                Agregar grupo
              </Typography>
              <AddCircleOutlineIcon
                color="primary"
                style={{ marginLeft: '5px' }}
              />
            </>
          )}
        </IconButton>
        <Typography
          style={{
            fontFamily: 'Open Sans',
            marginRight: '20px',
            fontWeight: '700',
          }}
        >
          Grupos disponibles:{' '}
          {maxGruposGlobal - gruposCount.length < 0
            ? 0
            : maxGruposGlobal - gruposCount.length}
        </Typography>
      </Grid>
      {gruposCount.map((grupo: any, index: number) => {
        return (
          <GrupoComponent
            index={index}
            gruposValues={gruposValues}
            turnosValues={turnosValues}
            ofertaStep={ofertaStep}
            localizaciones={localizacionFiltered}
            organizacion={
              organizaciones?.filter(
                (org) => org.idOrganizacion === formValues?.organizacion,
              )[0]
            }
            turnos={filteredTurnos}
            setGruposValues={setGruposValues}
            borrarSede={borrarGrupo}
            loading={loading}
            minDateOferta={formValues?.fechaInicio}
            maxDateOferta={formValues?.fechaFin}
          />
        );
      })}
      <Footer buttonConfig={readonlyButtonConfig} />
    </>
  ) : (
    <Loading />
  );
};
