import { LibbyFetchDAO } from './LibbyFetchDAO';

export class BajaMotivoDAO extends LibbyFetchDAO {
  constructor() {
    super('baja_motivo', 'idMotivo');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
