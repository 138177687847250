import { Box, makeStyles } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState } from 'react';
import {
  useCicloLectivoLibbyCall,
  useLocalizacionPlanEstudioNivelLibbyFetch,
  usePlanEstudioMateriaLibbyFetch,
  useProyeccionEstadoLibbyFetch,
  useSeccionDAO,
  useTurnoLibbyCall,
} from 'src/app/business';
import { Anio, PlanEstudioNivel } from 'src/app/models';
import { AnyObject, typeEstablishment } from 'src/commons';
import {
  ButtonTypesProps,
  Footer,
  Loading,
  MainInput,
  SimpleSelect,
} from 'src/commons/components';
import confirmDialog from 'src/commons/services/confirmDialog';
import customFormDialog from 'src/commons/services/customFormDialog';
import { regexValidator } from 'src/utils/regex';

const { SCHOOLD_TECHNIQUE } = typeEstablishment;

const customStyleTitle = {
  color: '#737373',
  position: 'relative',
  top: '-12px',
};

const useStyles = makeStyles({
  buttonLoading: {
    height: '20px',
    padding: '0',
    display: 'flex',
    alignContent: 'center',
  },
});

interface SeccionModalPropsTypes {
  date: string | null;
  anio: number;
  turn: number;
  working_day: string;
  localizacionId: string;
  dividing: number | string;
  capacity: string;
  plan_study: number;
  cycle: number;
  seccion: string;
  edit: boolean;
  tipoEstablecimiento: string;
  nivel?: number;
  refetch: () => void;
  modalidad: number;
}

let arrWorkingDay = [
  {
    label: 'Completa',
  },
  {
    label: 'Simple',
  },
];

export const SeccionModal = ({
  cycle,
  plan_study,
  anio,
  tipoEstablecimiento,
  turn,
  working_day,
  dividing = '',
  capacity = '',
  seccion,
  localizacionId,
  nivel,
  edit,
  refetch,
  modalidad,
  date,
}: SeccionModalPropsTypes) => {
  const [formValues, setFormValues] = useState({
    cycle,
    plan_study, // 1
    anio,
    turn,
    working_day,
    dividing,
    capacity,
  });
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const seccionDAO = useSeccionDAO();

  const { data: turnos = [], working: workingTurnos } = useTurnoLibbyCall({
    methodName: 'getAll',
  });

  const { data: lpens = [], working: workingLpens } =
    useLocalizacionPlanEstudioNivelLibbyFetch({
      limit: 10000,
    });

  const planesEstudioNivel = useMemo(() => {
    const _planes: PlanEstudioNivel[] = [];
    lpens.forEach((lpen) => {
      const idPlanEstudioNivel = lpen.planEstudioNivel.idPlanEstudioNivel;
      if (!_planes.some((pe) => pe.idPlanEstudioNivel === idPlanEstudioNivel)) {
        _planes.push(lpen.planEstudioNivel);
      }
    });
    return _planes;
  }, [lpens]);

  const filterMaterias = useMemo(() => {
    return {
      planes: [
        {
          path: 'planEstudio',
          value: planesEstudioNivel.map((pe) => pe.planEstudio.idPlanEstudio),
          method: 'in',
        },
      ],
    };
  }, [planesEstudioNivel]);

  const { data: planesMaterias = [] } = usePlanEstudioMateriaLibbyFetch({
    filter: filterMaterias,
    enabled: lpens.length > 0 && !workingLpens,
    limit: 10000,
  });

  const aniosByplanEstudio = useMemo<{ [k: number]: Anio[] }>(() => {
    const _aniosByplanEstudio: { [k: number]: Anio[] } = {};

    planesMaterias.forEach((pm) => {
      const idPlanEstudio = pm.planEstudio.idPlanEstudio;
      const idPlanEstudioNivel = planesEstudioNivel.find(
        (pen) => pen.planEstudio.idPlanEstudio === idPlanEstudio,
      )?.idPlanEstudioNivel;

      if (!idPlanEstudioNivel) return;
      const anio = pm.materia.anio;
      if (tipoEstablecimiento === SCHOOLD_TECHNIQUE && anio.idAnio === 26)
        return;
      if (_aniosByplanEstudio[idPlanEstudioNivel]) {
        const anios = _aniosByplanEstudio[idPlanEstudioNivel];
        if (!anios.some((a) => a.idAnio === anio.idAnio)) {
          _aniosByplanEstudio[idPlanEstudioNivel].push(anio);
        }
      } else {
        _aniosByplanEstudio[idPlanEstudioNivel] = [anio];
      }
    });

    return _aniosByplanEstudio;
  }, [planesEstudioNivel, planesMaterias, tipoEstablecimiento]);

  planesMaterias.forEach((pm) => {
    const idPlanEstudio = pm.planEstudio.idPlanEstudio;
    const idPlanEstudioNivel = planesEstudioNivel.find(
      (pen) => pen.planEstudio.idPlanEstudio === idPlanEstudio,
    )?.idPlanEstudioNivel;

    if (!idPlanEstudioNivel) return;
    const anio = pm.materia.anio;
    if (tipoEstablecimiento === SCHOOLD_TECHNIQUE && anio.idAnio === 26) return;
    if (aniosByplanEstudio[idPlanEstudioNivel]) {
      const anios = aniosByplanEstudio[idPlanEstudioNivel];
      if (!anios.some((a) => a.idAnio === anio.idAnio)) {
        aniosByplanEstudio[idPlanEstudioNivel].push(anio);
      }
    } else {
      aniosByplanEstudio[idPlanEstudioNivel] = [anio];
    }
  });

  const filterLocalizacion = useMemo(
    () => ({
      id: [{ path: 'localizacion', value: localizacionId }],
    }),
    [localizacionId],
  );

  const classes = useStyles();

  const { data: proyeccionEstado = [], working: workingProyeccionEstado } =
    useProyeccionEstadoLibbyFetch({
      filter: filterLocalizacion,
      orderBy: 'cicloLectivo',
      direction: 'desc',
    });

  const descriptionNameSection = (key: number, arrSelect: AnyObject) => {
    const filterObjSelect = arrSelect.find(
      (e: AnyObject) => e.idAnio === key || e.idTurno === key,
    );
    const { descripcionAnio, descripcionTurno } = filterObjSelect || {};
    return descripcionAnio || descripcionTurno || '';
  };

  const handleSubmit = useCallback(async () => {
    try {
      const obj = {
        nombreSeccion: `${descriptionNameSection(
          formValues.anio,
          aniosByplanEstudio[formValues.plan_study],
        )} ${descriptionNameSection(formValues.turn, turnos)} ${
          formValues.working_day
        } ${formValues.dividing}`,
        jornada: formValues.working_day,
        capacidadRecomendada: 0,
        capacidadMaxima: Number(formValues.capacity),
        anio: { idAnio: formValues.anio },
        localizacion: { idLocalizacion: localizacionId },
        planEstudioNivel: { idPlanEstudioNivel: formValues.plan_study },
        cicloLectivo: { idCicloLectivo: formValues.cycle },
        turno: { idTurno: formValues.turn },
        division: formValues.dividing,
        nivel: { idNivel: nivel },
        ciclo: null,
        cicloLectivoAdulto: null,
      };
      if (edit) {
        let response = await seccionDAO.save({ ...obj, idSeccion: seccion });

        if (response) {
          enqueueSnackbar('La sección se modificó con éxito', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
          setLoading(false);
        }
      } else {
        let response = await seccionDAO.save(obj);
        if (response) {
          setLoading(false);
          enqueueSnackbar('La sección se creó con éxito', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      }
      refetch();

      customFormDialog.handleCancel();
      setLoading(false);
    } catch (e) {
      await confirmDialog.show({
        title: 'Error: Sección repetida',
        content: `Se ha encontrado una sección similar en nuestra base de datos, por favor intente nuevamente con otros datos`,
        confirmText: 'Entendido',
      });
      // eslint-disable-next-line
      // console.log(e);
      setLoading(false);
    }
  }, [
    formValues.anio,
    formValues.plan_study,
    formValues.turn,
    formValues.working_day,
    formValues.dividing,
    formValues.capacity,
    formValues.cycle,
    aniosByplanEstudio,
    turnos,
    localizacionId,
    nivel,
    edit,
    refetch,
    seccionDAO,
    seccion,
    enqueueSnackbar,
  ]);

  const jornadas = useMemo(() => {
    const _jornadas = [...arrWorkingDay];
    if (
      [1, 2].includes(Number(nivel)) ||
      (Number(nivel) === 3 && formValues.anio === 21)
    ) {
      _jornadas.push({
        label: 'Extendida',
      });
      return _jornadas;
    } else {
      return _jornadas.filter((item) => item.label !== 'Extendida');
    }
  }, [formValues.anio, nivel]);

  const buttonConfig: ButtonTypesProps[] = [
    {
      title: 'Cancelar',
      handleOnClick: () => customFormDialog.handleCancel(),
      type: 'secondary',
      size: 'small',
    },
    {
      title: loading ? (
        <Loading className={classes.buttonLoading} />
      ) : edit ? (
        'Guardar'
      ) : (
        'Crear'
      ),
      handleOnClick: () => {
        setLoading(true);
        handleSubmit();
      },
      size: 'small',
      disabled:
        !formValues.anio ||
        !formValues.capacity ||
        !formValues.cycle ||
        !formValues.dividing ||
        !formValues.plan_study ||
        !formValues.turn ||
        !formValues.working_day ||
        loading,
    },
  ];

  const validateCapacity = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    const valueNumber = regexValidator.onlyNumber.test(target.value);
    if (valueNumber) {
      setFormValues({
        ...formValues,
        [target.name]: target.value,
      });
    }
  };

  const handleChange = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const anioCicloLectivoColegio = proyeccionEstado[0]?.cicloLectivo.anio;

  const { data: ciclosLectivos = [], working } = useCicloLectivoLibbyCall({
    methodName: 'getAll',
  });

  const currentYear = new Date(date ?? '').getFullYear();

  const ciclosLectivosValidos = ciclosLectivos.filter((cl) =>
    anioCicloLectivoColegio < currentYear
      ? cl.anio >= anioCicloLectivoColegio && cl.anio <= currentYear
      : cl.anio >= anioCicloLectivoColegio,
  );
  function filterAnios(planEstudio: number) {
    if (aniosByplanEstudio[planEstudio]) {
      aniosByplanEstudio[planEstudio] = aniosByplanEstudio[planEstudio].filter(
        (anio) => anio.idAnio !== 20,
      );
    }
  }
  filterAnios(formValues.plan_study);

  function sortAnios(planEstudio: number) {
    if (aniosByplanEstudio[planEstudio]) {
      aniosByplanEstudio[planEstudio].sort(
        (a, b) => a.numeroAnio - b.numeroAnio,
      );
    }
  }

  sortAnios(formValues.plan_study);

  return (
    <>
      <div style={{ width: '100%', paddingBottom: '15px' }}>
        <Box
          display="flex"
          p={1}
          bgcolor="background.paper"
          alignItems="center"
        >
          <Box p={1} width="50%">
            <SimpleSelect
              title="Ciclo lectivo"
              requiredField={!formValues.cycle}
              placeholder="Seleccioná Ciclo"
              labelKey="anio"
              valueKey="idCicloLectivo"
              content={ciclosLectivosValidos}
              handleChange={handleChange}
              value={formValues.cycle}
              name="cycle"
              disabled={edit || workingProyeccionEstado || working}
            />
          </Box>

          <Box p={1} width="50%">
            <SimpleSelect
              title="Plan de estudios"
              requiredField={!formValues.plan_study}
              placeholder="Seleccioná Plan"
              labelKey="planEstudio.descripcionPlanEstudio"
              valueKey="idPlanEstudioNivel"
              content={planesEstudioNivel}
              handleChange={handleChange}
              value={formValues.plan_study}
              name="plan_study"
              disabled={edit || workingProyeccionEstado}
            />
          </Box>
        </Box>
        <Box display="flex" p={1} bgcolor="background.paper">
          <Box p={1} width="50%">
            <SimpleSelect
              name="anio"
              title="Año"
              requiredField={!formValues.anio}
              placeholder="Seleccioná Año"
              labelKey="descripcionAnio"
              valueKey="idAnio"
              handleChange={handleChange}
              content={aniosByplanEstudio[formValues.plan_study] || []}
              value={formValues.anio || ''}
              disabled={edit || workingProyeccionEstado}
            />
          </Box>
          <Box p={1} width="50%">
            <SimpleSelect
              name="turn"
              title="Turno"
              requiredField={!formValues.turn}
              placeholder="Seleccioná Turno"
              labelKey="descripcionTurno"
              valueKey="idTurno"
              content={turnos}
              loading={workingTurnos}
              handleChange={handleChange}
              value={formValues.turn}
            />
          </Box>
        </Box>
        <Box display="flex" p={1} bgcolor="background.paper">
          <Box p={1} width="50%">
            <SimpleSelect
              name="working_day"
              placeholder="Seleccioná Jornada"
              labelKey="label"
              title="Jornada"
              valueKey="label"
              content={jornadas}
              handleChange={handleChange}
              value={formValues.working_day}
              requiredField={!formValues.working_day}
              disabled={edit || workingProyeccionEstado}
            />
          </Box>
          <Box p={1.2} width="50%" alignSelf="flex-end">
            <MainInput
              name="dividing"
              label="División"
              handleChange={handleChange}
              requiredField={!formValues.dividing}
              placeholder="Ingresá división"
              value={formValues.dividing}
              fullWidth
              customStyleLabel={customStyleTitle}
            />
          </Box>
        </Box>
        <Box width="49%" p={1} bgcolor="background.paper">
          <Box p={1} flexGrow={1}>
            <MainInput
              name="capacity"
              label="Capacidad"
              requiredField={!formValues.capacity}
              handleChange={validateCapacity}
              placeholder="Definí capacidad"
              value={formValues.capacity}
              fullWidth
              customStyleLabel={customStyleTitle}
            />
          </Box>
        </Box>
      </div>
      <Footer buttonConfig={buttonConfig} spacing={1} />
    </>
  );
};
