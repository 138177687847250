import _ from 'lodash';
import moment from 'moment';
import { DateRange } from 'src/app/interfaces/DateRange';
import { Alumno, Exceptuado } from 'src/app/models';
import { weekDays } from '../components/PresentismoTable/functions/cerrarSemana';

export const getDateRangeBetweenDates = (fromDate: string, toDate: string) => {
  const dates = [];

  const start = moment(fromDate);
  const end = moment(toDate);

  while (start.isSameOrBefore(end)) {
    dates.push(start.format('YYYY-MM-DD'));
    start.add(1, 'days');
  }

  return dates;
};

/**
 * Verifica si todos los dias de la
 * semana comenzando en startOfWeek
 * estan en el array days
 * @returns boolean
 */
export const isWeekClosed = (startOfWeek: string, days: string[]) => {
  if (days.length === 0) return false;
  const _weekDays = getDateRangeBetweenDates(
    startOfWeek,
    moment(startOfWeek)
      .add(weekDays.length - 1, 'days')
      .format('YYYY-MM-DD'),
  ); // Los dias en string en formato YYYY-MM-DD de la semana actual

  return _weekDays.every((dayOfWeek) =>
    days.map((day) => moment.utc(day).format('YYYY-MM-DD')).includes(dayOfWeek),
  );
};

export class DateRangeList {
  list: Array<DateRange & Exceptuado> = [];
  private indexOpenedInterval: number | false = false;
  private tz: number;

  constructor(list: Array<DateRange & Exceptuado> = []) {
    this.tz = Math.abs(moment().utcOffset());
    this.list = list.map((exceptuado) => {
      return new Exceptuado(
        moment(exceptuado.startTime).format(),
        exceptuado.alumno,
        moment(exceptuado.endTime).format(),
        exceptuado?.idExcepcion,
        exceptuado.color,
      );
    });
  }

  remove(index: number) {
    this.list.splice(index, 1);
  }

  isEqualToList(list: DateRangeList) {
    return _.isEqual(this.list, list.list);
  }

  isEmpty() {
    return this.list.length === 0;
  }

  hasOneWithinInterval(date: string) {
    const opened = this.list.find((interval) => interval.isOpen());
    return (
      (opened && moment(date).isSameOrAfter(opened.startTime)) ||
      this.getClosedIntervals().some((interval) => {
        return (
          moment(date).isSameOrAfter(interval.startTime) &&
          moment(date).isSameOrBefore(interval.endTime)
        );
      })
    );
  }

  isInvervalOverlapping(range: DateRange, exceptMe = '') {
    return (
      this.isDateOverlapping(range.startTime!, exceptMe) ||
      this.isDateOverlapping(range.endTime!, exceptMe) ||
      this.list.some((interval) => range.containsRange(interval))
    );
  }

  updateRange(key: string, startTime: string, endTime?: string) {
    const indexToUpdate = this.list.findIndex(
      (localRange) => localRange.key === key,
    );
    this.list[indexToUpdate].startTime = startTime;
    this.list[indexToUpdate].endTime = endTime;
  }

  getRowsToSave() {
    return this.list.map((interval) => {
      const {
        value,
        key,
        color,
        startDate,
        endDate,
        endTime,
        startTime,
        ...rest
      } = interval;
      const newStartTime = moment(startTime).format();
      const newEndTime = moment(endTime).format();
      return {
        ...rest,
        startTime: newStartTime,
        endTime: newEndTime,
      };
    });
  }

  searchByKey(key: string, type = 'item') {
    return 'index' === type
      ? this.list.findIndex((localRange) => localRange.key === key)
      : this.list.find((localRange) => localRange.key === key);
  }

  clone() {
    return new DateRangeList(this.list);
  }

  [Symbol.iterator]() {
    let i = 0;
    return {
      next: () => {
        if (this.list.length === 0)
          return {
            value: [],
            done: true,
          };

        const range = this.list[i++];
        return {
          value: range,
          done: i > this.list.length,
        };
      },
    };
  }

  isDateOverlapping(date: string, exceptMe = '') {
    const closedIntervals = this.getClosedIntervals();
    if (this.list.length === 0 || closedIntervals.length === 0) return false;

    return closedIntervals
      .filter((item) => {
        if (exceptMe) return item.key !== exceptMe;

        return item;
      })
      .some(
        (interval) =>
          moment(date).isBetween(interval.startTime, interval.endTime) ||
          moment(date).isSame(interval.startTime) ||
          moment(date).isSame(interval.endTime),
      );
  }

  static dayAtMidnight(day?: string) {
    let midnight;
    if (day) {
      midnight = moment(day).startOf('day');
    } else {
      midnight = moment().startOf('day');
    }
    return midnight.format();
  }

  insertEmptyRange(alumno: Alumno) {
    this.list.push(
      new Exceptuado(
        DateRangeList.dayAtMidnight(),
        alumno,
        DateRangeList.dayAtMidnight(),
      ),
    );
  }

  fillOne(alumno: Alumno) {
    this.list.push(
      new Exceptuado(
        DateRangeList.dayAtMidnight(),
        alumno,
        DateRangeList.dayAtMidnight(),
      ),
    );
    return this;
  }

  setList(list: Array<DateRange & Exceptuado>) {
    this.list = list;
  }

  getClosedIntervals() {
    return this.list.filter(
      (interval) => interval.startTime && interval.endTime,
    );
  }

  verifyDate(date: string) {
    if (this.isDateOverlapping(date))
      throw new Error('Existe solapación de fechas.');

    this.hasIntervalOpened();

    if (
      this.indexOpenedInterval !== false &&
      this.indexOpenedInterval !== -1 &&
      moment(date)
        .add(this.tz, 'minutes')
        .isBefore(this.list[this.indexOpenedInterval].startTime)
    ) {
      throw new Error('Primero se debe cerrar el intervalo anterior');
    }
  }

  addDate(date: string, alumno: Alumno) {
    this.verifyDate(date);
    if (this.indexOpenedInterval !== false && this.indexOpenedInterval !== -1) {
      const tempExceptuado = new Exceptuado(
        this.list[this.indexOpenedInterval].startTime,
        alumno,
        moment(date).add(this.tz, 'minutes').format('YYYY-MM-DD'),
        this.list[this.indexOpenedInterval].idExcepcion,
      );

      const closed = this.getClosedIntervals();
      if (
        closed.some(
          (interval) =>
            moment(interval.startTime).isBetween(
              tempExceptuado.startTime,
              tempExceptuado.endTime,
            ) ||
            moment(interval.endTime).isBetween(
              tempExceptuado.startTime,
              tempExceptuado.endTime,
            ),
        )
      ) {
        throw new Error(
          'Hay intervalos cerrados entre el intervalo que quiere cerrar.',
        );
      }
      this.list[this.indexOpenedInterval] = tempExceptuado;
    } else {
      const tempInterval = new Exceptuado(
        moment(date).add(this.tz, 'minutes').format('YYYY-MM-DD'),
        alumno,
      );
      if (this.hasAdjacentIntervals(date))
        tempInterval.endTime = moment(date)
          .add(this.tz, 'minutes')
          .format('YYYY-MM-DD');
      this.list.push(tempInterval);
    }
  }

  hasAdjacentIntervals(date: string) {
    const nextDay = moment(date).add(1, 'days');
    const previousDay = moment(date).subtract(1, 'days');
    const hasRightSide = this.list.some((interval) =>
      moment(interval.startTime).isSame(nextDay),
    );
    const hasLeftSide = this.list.some((interval) =>
      moment(interval.endTime).isSame(previousDay),
    );

    return hasLeftSide && hasRightSide;
  }

  hasIntervalOpened() {
    if (this.list.length === 0) this.indexOpenedInterval = false;

    this.indexOpenedInterval = this.list.findIndex(
      (interval) => interval.endTime === null || interval.endTime === undefined,
    );
  }

  atLeastOneInvalidInterval() {
    return this.list.some((interval) =>
      this.isInvervalOverlapping(interval, interval.key),
    );
  }
}
