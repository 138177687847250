import { CommonEndpointDAO } from './CommonEndpointDAO';

export class AdminDAO extends CommonEndpointDAO {
  constructor() {
    super('admin');
  }

  impersonate(idUsuarioRolEstablecimiento: string) {
    return this.post({ id: idUsuarioRolEstablecimiento });
  }

  backToAdmin() {
    return this.post({});
  }
}
