import { makeBusinessEntity } from 'src/lib/libby';
import { HistoricoCondiciones } from '../models';

const DAO_NAME = 'historico_condiciones';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<HistoricoCondiciones>(DAO_NAME);

// hooks
export const useHistoricoCondicionesFetchById = fetchById;
export const useHistoricoCondicionesLibbyCall = libbyCall;
export const useHistoricoCondicionesDAO = libbyDAO;
export const useHistoricoCondicionesLibbyFetch = libbyFetch;
