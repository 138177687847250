import { NIVEL, ROL } from 'src/commons';

export const ROLES_JERARQUICOS = [
  ROL.DIRECTIVO,
  ROL.EQUIPO_CONDUCCION,
  ROL.SUPERVISOR,
  ROL.DIRECCION_AREA,
  ROL.PRECEPTOR,
  ROL.COORDINADOR_JE,
  ROL.PRECEPTOR,
];

export const ROLES_JORNADA_EXTENDIDA = [
  ROL.COORDINADOR_JE,
  ROL.DOCENTE,
  ROL.DIRECTIVO,
  ROL.EQUIPO_CONDUCCION,
];

export const ROLES_JERARQUICOS_JORNADA_EXTENDIDA = [
  ROL.EQUIPO_CONDUCCION,
  ROL.SUPERVISOR,
  ROL.DIRECCION_AREA,
  ROL.COORDINADOR_JE,
  ROL.DIRECTIVO,
];

export const ROLES_DOCENTES = [ROL.DOCENTE];

export const AUTHORIZED_ROLES = [
  ROL.DIRECTIVO,
  ROL.EQUIPO_CONDUCCION,
  ROL.SUPERVISOR,
  ROL.DIRECCION_AREA,
];

export const NIVELES_AUTHORIZED = [NIVEL.INICIAL];
export const NIVELES_JE = [NIVEL.PRIMARIO, NIVEL.SECUNDARIO];

export const ROLES_REGIMEN_ESPECIAL = [ROL.DIRECTIVO, ROL.EQUIPO_CONDUCCION];

export const ROLES_REGULARIDAD = [
  ROL.DIRECCION_AREA,
  ROL.SUPERVISOR,
  ROL.DIRECTIVO,
  ROL.EQUIPO_CONDUCCION,
  ROL.DOCENTE,
  ROL.PRECEPTOR,
];
