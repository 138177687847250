import {
  Button,
  Checkbox,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  TextField,
  Typography,
  Tooltip,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import ErrorIcon from '@material-ui/icons/Error';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { useCallback, useState } from 'react';
import { Footer } from 'src/commons';
import customFormDialog from 'src/commons/services/customFormDialog';
import { TimePicker } from '@material-ui/pickers';
import { MuiPickersUtilsProvider } from '@material-ui/pickers/MuiPickersUtilsProvider';
import { DateRangePicker } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { es } from 'date-fns/locale';
import { addDays } from 'date-fns';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import { AlumnoPresentismo, ModalAdaptacionProps } from '../types';
import { cloneDeep, isEqual } from 'lodash';
import { AlumnoMap } from '../TomaPresentismo/components/Table';
import { Alumno, PeriodoInicio } from '../../../../app/models';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const useStyles = makeStyles((theme) => ({
  modal: {},
  modalBody: {
    display: 'grid',
    gridTemplateAreas: `'a a a a a a a a a'
  					            'h h h h h h h h h'
  					            'b b b b . c c c c'
  					            'b b b b . c c c c'
  					            'b b b b . c c c c'
  					            'b b b b . c c c c'
  					            'b b b b . c c c c'
  					            'b b b b . c c c c'
                        'b b b b . d d d d'
                        'b b b b . d d d d'
                        'b b b b . g g g g'
                        'b b b b . f f f f'`,
    gridTemplateColumns: 'repeat(10,5rem)',
    justifyContent: 'center',
    marginBottom: '20px',
    userSelect: 'none',
  },
  modalItem: {
    display: 'grid',
    gridTemplateRows: '1.5rem 1fr',
    gridTemplateColumns: '1fr',
    justifyContent: 'center',
    boxSizing: 'border-box',
    width: '370px',
  },
  modalItemLabel: {
    fontWeight: 'bold',
    textAlign: 'center',
  },
  modalItemBody: {
    display: 'flex',
    justifyContent: 'center',
  },
  seccion: {
    gridArea: 'a',
    width: '100%',
  },
  message: {
    gridArea: 'h',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  messageText: {
    width: '90%',
    height: '20px',
    backgroundColor: 'yellow',
    margin: 0,
    padding: 5,
    textAlign: 'center',
    fontSize: '0.9rem',
    fontFamily: 'sans-serif',
  },
  alumnos: {
    gridArea: 'b',
    width: '370px',
  },
  calendario: {
    gridArea: 'c',
  },
  containerHoras: {
    gridArea: 'd',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },

  horaEntrada: {
    width: '170px',
  },
  horaSalida: {
    width: '170px',
  },
  eliminarPeriodo: {
    gridArea: 'f',
    height: '100%',
    width: '370px',
    border: '1px solid #ff00008f',
    borderRadius: '2px',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    textTransform: 'none',
    padding: 0,
    '&:hover': {
      backgroundColor: '#ff000030',
    },
  },
  observaciones: {
    gridArea: 'g',
  },
  observacionesInput: {
    // height: '50px',
  },
  listaAlumnos: {
    overflowY: 'scroll',
    border: 'solid 1px black',
    borderRadius: '5px',
    height: '550px',
  },

  listaAlumnosHeader: {
    boxShadow: '0px 1px 5px 1px black',
    backgroundColor: '#e5e5e5',
    '&>div': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  },
  listaAlumnosHeaderText: {
    display: 'inline-flex',
    justifyContent: 'center',
    '&>span': {
      display: 'inline',
      fontSize: '1rem',
      fontWeight: 'bold',
    },
  },
  listaItem: {
    '&:nth-child(2n)': {
      backgroundColor: '#F1F1F1',
    },
  },
  readyToRequest: {
    color: 'green',
    cursor: 'help',
  },
  readyToRequestButDelete: {
    color: '#ff00008f',
    cursor: 'help',
  },
  notReadyToRequest: {
    color: 'orange',
    cursor: 'help',
  },
  daterangeContainer: {
    border: 'solid 1px #c6c5c5',
    marginBottom: '10px',
    position: 'relative',
  },
  dateRangeContainerHover: {
    '&:hover::after': {
      content: '"Primero debe seleccionar uno o más alumnos."',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 1000,
      height: '230px',
      width: '335px',
      padding: '1em',
      backgroundColor: '#eeeeeed1',
      color: 'black',
      border: 'solid 0.5px #333',
      fontSize: '1rem',
      position: 'absolute',
      top: '45px',
      left: '0px',
    },
  },
  daterange: {
    '& > .rdrDefinedRangesWrapper': {
      display: 'none',
    },
    '& > .rdrCalendarWrapper': {
      fontFamily: 'sans-serif',
      textTransform: 'capitalize',
      height: '270px',
    },
    '& .rdrInfiniteMonths': {
      height: '240px !important',
    },
  },
  required: {
    border: 'solid 1px red',
    cursor: 'help',
  },
  tooltip: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
    height: 'fit-content',
    width: 'fit-content',
    padding: '1em',
    backgroundColor: '#eee',
    color: 'black',
    border: 'solid 0.5px #333',
    fontSize: '1rem',
  },
}));

const capitalizePhrase = (phrase: string) => {
  const phraseCapitalized = phrase
    .toLowerCase()
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
  return phraseCapitalized;
};

const dateFormatFromHourAndMinute = (time: string) => {
  const date = moment();
  const hour = Number(time.split(':')[0]);
  const minute = Number(time.split(':')[1]);
  date.set({ hour: hour, minute: minute });
  return date.toDate();
};

const isTimeEquals = (dateA: Date | null, dateB: Date | null) => {
  if (!dateA && !dateB) return true;
  if (!dateA || !dateB) return false;
  const timeA = moment(dateA).format('HH:mm:ss');
  const timeB = moment(dateB).format('HH:mm:ss');
  return timeA === timeB;
};

const isDatesEquals = (dateA: Date | null, dateB: Date | null) => {
  if (!dateA && !dateB) return true;
  if (!dateA || !dateB) return false;
  const compareA = moment(dateA).format('YYYY-MM-DD');
  const compareB = moment(dateB).format('YYYY-MM-DD');
  return compareA === compareB;
};

const isPeriodEquals = (
  periodoA: PeriodoInicioAdaptacion,
  periodoB: PeriodoInicioAdaptacion,
) => {
  const {
    alumno: alumnoA,
    timeIn: timeInA,
    timeOut: timeOutA,
    selectionRange: { startDate: startDateA, endDate: endDateA },
    ...restA
  } = periodoA;
  const {
    alumno,
    timeIn,
    timeOut,
    selectionRange: { startDate, endDate },
    ...rest
  } = periodoB;
  const isRestEquals = isEqual(rest, restA);
  const istimeInEquals = isTimeEquals(timeIn, timeInA);
  const istimeOutEquals = isTimeEquals(timeOut, timeOutA);
  const isStartDateEquals = isDatesEquals(startDate, startDateA);
  const isEndDateEquals = isDatesEquals(endDate, endDateA);
  return (
    isRestEquals &&
    istimeInEquals &&
    istimeOutEquals &&
    isStartDateEquals &&
    isEndDateEquals
  );
};

const isEdited = (
  periodoAdaptacion: PeriodoInicioAdaptacion,
  periodoAdaptacionInitial: PeriodoInicioAdaptacion,
) => {
  return !isPeriodEquals(
    {
      ...periodoAdaptacion,
      edited: false,
      showDateRange: false,
      idPeriodoInicio: 0,
      readyToRequest: false,
    },
    {
      ...periodoAdaptacionInitial,
      edited: false,
      showDateRange: false,
      idPeriodoInicio: 0,
      readyToRequest: false,
    },
  );
};

export interface DateRange {
  startDate: Date | null;
  endDate: Date | null;
  key: 'selection';
}

// ESTA ES
interface PeriodoInicioAdaptacion
  extends Omit<PeriodoInicio, 'timeIn' | 'timeOut' | 'idPeriodoInicio'> {
  idPeriodoInicio?: number;
  timeIn: Date | null;
  timeOut: Date | null;
  value: boolean;
  readyToRequest: boolean;
  edited: boolean;
  showDateRange: boolean;
  selectionRange: DateRange;
}
// ESRTA ES
const periodoInicioInitialValue: PeriodoInicioAdaptacion = {
  timeIn: null,
  timeOut: null,
  observaciones: '',
  fechaDesde: '',
  fechaHasta: '',
  alumno: {} as Alumno,
  selectionRange: {
    startDate: null,
    endDate: null,
    key: 'selection',
  },
  value: false,
  readyToRequest: false,
  edited: false,
  showDateRange: false,
};

export interface PeriodoInicioSave
  extends Omit<PeriodoInicio, 'idPeriodoInicio'> {
  idPeriodoInicio?: number;
  value: boolean;
}

// ESTA ES
export interface PeriodoInicialModalProps {
  day: string;
  rows: AlumnoMap[];
  onSavePeriodoInicial: (values: PeriodoInicioSave[]) => void;
}
// ESTAS
interface PeriodoInicialMap extends Omit<AlumnoMap, 'periodoInicio'> {
  periodoInicio: PeriodoInicioAdaptacion;
  periodoInicioInitValue: PeriodoInicioAdaptacion;
}

export const PeriodoInicialModal = ({
  rows,
  onSavePeriodoInicial,
}: PeriodoInicialModalProps) => {
  const classes = useStyles();

  const [messageText, setMessageText] = useState('');
  const [checkedRows, setCheckedRows] = useState<string[]>([]);
  const [showDataRange, setShowDataRange] = useState(false);
  const [periodoAdaptacion, setPeriodoAdaptacion] =
    useState<PeriodoInicioAdaptacion>(periodoInicioInitialValue);

  const [listaEstudiantes, setListaEstudiantes] = useState<PeriodoInicialMap[]>(
    rows.map((estudiante) => {
      let periodoInitialValue = cloneDeep(periodoInicioInitialValue);
      if (!!estudiante.periodoInicio) {
        const _preriodo: PeriodoInicioAdaptacion = {
          ...periodoInitialValue,
          ...estudiante.periodoInicio,
          alumno: { ...estudiante.alumno },
          timeIn: dateFormatFromHourAndMinute(estudiante.periodoInicio.timeIn),
          timeOut: dateFormatFromHourAndMinute(
            estudiante.periodoInicio.timeOut,
          ),
          observaciones: estudiante.periodoInicio.observaciones,
          selectionRange: {
            ...periodoInitialValue.selectionRange,
            startDate: moment(estudiante.periodoInicio.fechaDesde)
              .set({ hour: 12 })
              .toDate(),
            endDate: moment(estudiante.periodoInicio.fechaHasta)
              .set({ hour: 12 })
              .toDate(),
          },
          showDateRange: true,
        };
        periodoInitialValue = _preriodo;
      }

      const periodoAdaptacionInitial = cloneDeep(periodoInitialValue);
      return {
        ...estudiante,
        periodoInicio: periodoInitialValue,
        periodoInicioInitValue: periodoAdaptacionInitial,
      };
    }),
  );

  const setPeriodoAdaptacionToShow = useCallback(
    (checkedRows: string[]) => {
      if (checkedRows.length === 1) {
        const estudiante = listaEstudiantes.find(
          (estudiante) => estudiante.alumno.idAlumno === checkedRows[0],
        );
        setShowDataRange(estudiante!.periodoInicio.showDateRange);
        setMessageText('');
        return estudiante!.periodoInicio;
      } else if (checkedRows.length > 1) {
        const rowsSelected = listaEstudiantes.filter((estudiante) =>
          checkedRows.includes(estudiante.alumno.idAlumno),
        );

        const periodosAdaptacion = rowsSelected.map<PeriodoInicioAdaptacion>(
          (estudiante) => ({
            ...cloneDeep(estudiante.periodoInicio),
          }),
        );
        const sampleAdaptacion = cloneDeep(periodosAdaptacion[0]);
        const compareA = {
          ...sampleAdaptacion,
          edited: false,
          showDateRange: false,
          idPeriodoInicio: 0,
          readyToRequest: false,
        };

        const everyPeriodoAdaptacionAreEquals = periodosAdaptacion.every(
          (periodoAdaptacion) => {
            const compareB = {
              ...periodoAdaptacion,
              edited: false,
              showDateRange: false,
              idPeriodoInicio: 0,
              readyToRequest: false,
            };
            return isPeriodEquals(compareA, compareB);
          },
        );

        if (everyPeriodoAdaptacionAreEquals) {
          setShowDataRange(sampleAdaptacion.showDateRange);
          setMessageText('');
          return sampleAdaptacion;
        } else {
          setShowDataRange(false);
          setMessageText(
            'Los estudiantes tienen periodos de adaptación distintos por lo que los campos se muestran vacíos.',
          );
          return periodoInicioInitialValue;
        }
      }
      setShowDataRange(false);
      return periodoInicioInitialValue;
    },
    [listaEstudiantes],
  );

  const isReadyToRequest = (periodoAdaptacion: PeriodoInicioAdaptacion) => {
    const {
      idPeriodoInicio,
      timeIn,
      timeOut,
      value,
      selectionRange: { startDate, endDate },
    } = periodoAdaptacion;
    const dataFullFilled = !!timeIn && !!timeOut && !!startDate && !!endDate;
    return dataFullFilled || (!!idPeriodoInicio && value === false);
  };

  const updateSelected = (periodoAdaptacion: PeriodoInicioAdaptacion) => {
    const listaUpdated = listaEstudiantes.map((estudiante) => {
      const idPeriodoInicio = estudiante.periodoInicioInitValue.idPeriodoInicio;
      if (checkedRows.includes(estudiante.alumno.idAlumno)) {
        periodoAdaptacion = {
          ...periodoAdaptacion,
          idPeriodoInicio,
          readyToRequest: isReadyToRequest({
            ...periodoAdaptacion,
            idPeriodoInicio,
          }),
          edited: isEdited(
            periodoAdaptacion,
            estudiante.periodoInicioInitValue,
          ),
        };
        estudiante.periodoInicio = cloneDeep(periodoAdaptacion);
      }
      setPeriodoAdaptacion(periodoAdaptacion);
      setShowDataRange(periodoAdaptacion.showDateRange);
      return estudiante;
    });

    setListaEstudiantes(listaUpdated);
  };

  const handleCloseModal = useCallback(
    () => {
      // setEnableAutoSave(true);
      customFormDialog.handleCancel();
    },
    [
      /* setEnableAutoSave */
    ],
  );

  const handleSaveAdaptacion = useCallback(() => {
    const rowsToSave = listaEstudiantes
      .filter((estudiante) => estudiante.periodoInicio.readyToRequest)
      .map<PeriodoInicioSave>(
        ({
          alumno,
          periodoInicio: {
            timeIn,
            timeOut,
            idPeriodoInicio,
            observaciones,
            selectionRange: { startDate, endDate },
            value,
          },
        }) => ({
          idPeriodoInicio: idPeriodoInicio,
          alumno,
          observaciones,
          fechaDesde: startDate ? moment(startDate).format('YYYY-MM-DD') : '',
          fechaHasta: endDate ? moment(endDate).format('YYYY-MM-DD') : '',
          timeIn: timeIn ? moment(timeIn).format('HH:mm') : '',
          timeOut: timeOut ? moment(timeOut).format('HH:mm') : '',
          value,
        }),
      );

    onSavePeriodoInicial(rowsToSave);
    handleCloseModal();
  }, [handleCloseModal, onSavePeriodoInicial, listaEstudiantes]);

  const handleRangeChange = (ranges: any) => {
    updateSelected({
      ...periodoAdaptacion,
      selectionRange: {
        startDate: moment(ranges.selection.startDate)
          .set({ hour: 12 })
          .toDate(),
        endDate: moment(ranges.selection.endDate).set({ hour: 12 }).toDate(),
        key: 'selection',
      },
      value: true,
      showDateRange: true,
    });
  };

  const handleHoraEntradaChange = (time: MaterialUiPickersDate) => {
    if (
      periodoAdaptacion.timeOut === null ||
      moment(time).isBefore(moment(periodoAdaptacion.timeOut))
    ) {
      setMessageText('');
      updateSelected({
        ...periodoAdaptacion,
        timeIn: time ? time.toDate() : null,
        value: true,
      });
    } else {
      setMessageText('La hora de salida debe ser mayor a la hora de entrada.');
    }
  };

  const handleHoraSalidaChange = (time: MaterialUiPickersDate) => {
    if (
      periodoAdaptacion.timeIn === null ||
      moment(periodoAdaptacion.timeIn).isBefore(moment(time))
    ) {
      setMessageText('');
      updateSelected({
        ...periodoAdaptacion,
        timeOut: time ? time.toDate() : null,
        value: true,
      });
    } else {
      setMessageText('La hora de salida debe ser mayor a la hora de entrada.');
    }
  };

  const handleObservacionesChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    updateSelected({
      ...periodoAdaptacion,
      observaciones: event.target.value,
      value: true,
    });
  };

  const handleEliminar = () => {
    updateSelected({
      ...periodoInicioInitialValue,
      value: false,
    });
  };

  const handleToggle = (idAlumno: string) => () => {
    const currentIndex = checkedRows.indexOf(idAlumno);
    const newChecked = [...checkedRows];

    if (currentIndex === -1) {
      newChecked.push(idAlumno);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setPeriodoAdaptacion(setPeriodoAdaptacionToShow(newChecked));
    setCheckedRows(newChecked);
  };

  const handleToggleAll = () => {
    let newChecked: string[] = [];
    if (listaEstudiantes.length !== checkedRows.length) {
      newChecked = listaEstudiantes.map(
        (estudiante) => estudiante.alumno.idAlumno,
      );
    }
    setPeriodoAdaptacion(setPeriodoAdaptacionToShow(newChecked));
    setCheckedRows(newChecked);
  };

  const buttonConfig: any = [
    {
      title: 'Cancelar',
      handleOnClick: () => handleCloseModal(),
      type: 'secondary',
      size: 'medium',
    },
    {
      title: 'Actualizar Periodos',
      handleOnClick: () => handleSaveAdaptacion(),
      size: 'medium',
      disabled: !(
        listaEstudiantes.some(
          (estudiante) => estudiante.periodoInicio.edited,
        ) &&
        listaEstudiantes
          .filter((estudiante) => estudiante.periodoInicio.edited)
          .every((estudiante) => estudiante.periodoInicio.readyToRequest)
      ),
    },
  ];

  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <Grid container className={classes.modal}>
        <Grid container item className={classes.modalBody}>
          <Grid container className={`${classes.modalItem} ${classes.seccion}`}>
            <Typography className={classes.modalItemLabel}>
              {rows[0].seccion.nombreSeccion}
            </Typography>
          </Grid>
          <Grid container className={`${classes.modalItem} ${classes.message}`}>
            <p className={!!messageText ? classes.messageText : ''}>{`${
              !!messageText ? messageText : ''
            }`}</p>
          </Grid>
          <Grid container className={`${classes.modalItem} ${classes.alumnos}`}>
            <Typography className={classes.modalItemLabel}>
              Selección de alumnos
            </Typography>
            <List
              className={classes.listaAlumnos}
              subheader={
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  classes={{ root: classes.listaAlumnosHeader }}
                >
                  <div>
                    <Checkbox
                      edge="start"
                      checked={rows.length === checkedRows.length}
                      tabIndex={-1}
                      disableRipple
                      color="primary"
                      inputProps={{
                        'aria-labelledby': 'Apellido y nombre',
                      }}
                      onClick={handleToggleAll}
                    />
                    <ListItemText
                      id={'apellidoNombre'}
                      primary={`Apellido y nombre`}
                      classes={{ root: classes.listaAlumnosHeaderText }}
                    />
                  </div>
                </ListSubheader>
              }
            >
              {listaEstudiantes.map((estudiante) => (
                <ListItem
                  key={estudiante.alumno.idAlumno}
                  role={undefined}
                  dense
                  button
                  onClick={handleToggle(estudiante.alumno.idAlumno)}
                  classes={{ root: classes.listaItem }}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={checkedRows.includes(estudiante.alumno.idAlumno)}
                      tabIndex={-1}
                      disableRipple
                      color="primary"
                      inputProps={{
                        'aria-labelledby': estudiante.alumno.idAlumno,
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    id={estudiante.alumno.idAlumno}
                    primary={`${capitalizePhrase(
                      estudiante.alumno.persona.apellido,
                    )} ${capitalizePhrase(estudiante.alumno.persona.nombre)}`}
                  />
                  {estudiante.periodoInicio.edited ? (
                    estudiante.periodoInicio.readyToRequest ? (
                      <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        title={`${
                          estudiante.periodoInicio.value
                            ? 'Todos los campos han sido llenados correctamente.'
                            : 'El periodo de adaptación va a ser eliminado.'
                        }`}
                      >
                        <CheckCircleIcon
                          className={
                            estudiante.periodoInicio.value
                              ? classes.readyToRequest
                              : classes.readyToRequestButDelete
                          }
                        />
                      </Tooltip>
                    ) : (
                      <Tooltip
                        classes={{ tooltip: classes.tooltip }}
                        title={`Hay campos requeridos vacíos.`}
                      >
                        <ErrorIcon className={classes.notReadyToRequest} />
                      </Tooltip>
                    )
                  ) : (
                    ''
                  )}
                </ListItem>
              ))}
            </List>
          </Grid>
          <Grid
            container
            className={`${classes.modalItem} ${classes.calendario}`}
          >
            <Typography className={classes.modalItemLabel}>
              <span>
                Selección de Fecha{' '}
                {!showDataRange && checkedRows.length > 0 ? (
                  <Tooltip
                    classes={{ tooltip: classes.tooltip }}
                    title={'Este campo es requerido.'}
                  >
                    <span style={{ color: 'red' }}>*</span>
                  </Tooltip>
                ) : (
                  ''
                )}
              </span>
            </Typography>
            <span
              className={`${classes.daterangeContainer} ${
                checkedRows.length < 1 ? classes.dateRangeContainerHover : ''
              }`}
              onMouseEnter={() => {
                if (checkedRows.length > 0) {
                  if (periodoAdaptacion.selectionRange.startDate === null) {
                    setPeriodoAdaptacion({
                      ...periodoAdaptacion,
                      selectionRange: {
                        startDate: moment(new Date())
                          .set({ hour: 12 })
                          .toDate(),
                        endDate: moment(new Date()).set({ hour: 12 }).toDate(),
                        key: 'selection',
                      },
                    });
                    setShowDataRange(true);
                  }
                }
              }}
              onMouseLeave={() => {
                if (checkedRows.length > 0) {
                  if (periodoAdaptacion.showDateRange === false) {
                    setPeriodoAdaptacion({
                      ...periodoAdaptacion,
                      selectionRange: {
                        startDate: null,
                        endDate: null,
                        key: 'selection',
                      },
                    });
                    setShowDataRange(false);
                  }
                }
              }}
            >
              <DateRangePicker
                locale={es}
                className={classes.daterange}
                showMonthAndYearPickers={false}
                showDateDisplay={false}
                rangeColors={
                  checkedRows.length === 0 ? ['#fb8c00'] : ['#fb8c00']
                }
                ranges={showDataRange ? [periodoAdaptacion.selectionRange] : []}
                onChange={handleRangeChange}
                focusedRange={[0, 0]}
                direction="vertical"
                scroll={{ enabled: true }}
                minDate={addDays(new Date(), -90)}
                maxDate={addDays(new Date(), 90)}
              />
            </span>
          </Grid>
          <Grid
            container
            className={`${classes.modalItem} ${classes.containerHoras}`}
          >
            <Grid
              container
              className={`${classes.modalItem} ${classes.horaEntrada}`}
            >
              <Typography className={classes.modalItemLabel}>
                <span>
                  Hora de Entrada{' '}
                  {!!!periodoAdaptacion.timeIn && checkedRows.length > 0 ? (
                    <Tooltip
                      classes={{ tooltip: classes.tooltip }}
                      title={'Este campo es requerido.'}
                    >
                      <span style={{ color: 'red' }}>*</span>
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </span>
              </Typography>
              <TimePicker
                value={periodoAdaptacion.timeIn}
                onChange={handleHoraEntradaChange}
                emptyLabel={'-- : --'}
                orientation={'portrait'}
              />
            </Grid>
            <Grid
              container
              className={`${classes.modalItem} ${classes.horaSalida}`}
            >
              <Typography className={classes.modalItemLabel}>
                <span>
                  Hora de Salida{' '}
                  {!!!periodoAdaptacion.timeOut && checkedRows.length > 0 ? (
                    <Tooltip
                      classes={{ tooltip: classes.tooltip }}
                      title={'Este campo es requerido.'}
                    >
                      <span style={{ color: 'red' }}>*</span>
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </span>
              </Typography>
              <TimePicker
                value={periodoAdaptacion.timeOut}
                onChange={handleHoraSalidaChange}
                emptyLabel={'-- : --'}
                orientation={'portrait'}
              />
            </Grid>
          </Grid>

          <Button
            variant="contained"
            className={classes.eliminarPeriodo}
            endIcon={<DeleteIcon color={'error'} />}
            onClick={handleEliminar}
          >
            Restablecer periodo
          </Button>
          <Grid
            container
            className={`${classes.modalItem} ${classes.observaciones}`}
          >
            <Typography className={classes.modalItemLabel}>
              Observaciones
            </Typography>
            <TextField
              id="outlined-multiline-flexible"
              variant="outlined"
              multiline
              minRows={4}
              maxRows={5}
              value={periodoAdaptacion.observaciones}
              onChange={handleObservacionesChange}
              className={classes.observacionesInput}
            />
          </Grid>
        </Grid>
        <Footer buttonConfig={buttonConfig} spacing={1} />
      </Grid>
    </MuiPickersUtilsProvider>
  );
};
