import React, { useCallback, useEffect, useMemo } from 'react';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { uniqBy } from 'lodash';
import { Area, Materia } from '../../models';
import { AnyObject } from '../../../commons/types';
import { useMateriaLibbyCall } from '../Materia';

const useStyles = makeStyles(() => ({
  root: {
    '& .MuiOutlinedInput-root': {
      padding: 5,
    },
  },
}));

type OnChangeSingle = (newValue: Area) => void;
type OnChangeMulti = (newValue: Area[]) => void;

export interface EspaciosAreasAutocompleteProps {
  autoCompleteProps?: any;
  multiple?: boolean;
  value: Area | Area[];
  onChange: OnChangeSingle | OnChangeMulti;
  filter?: AnyObject;
  textFieldProps?: AnyObject;
}

const dummyFilter = {};

// TODO: add loading
export const EspaciosAreasAutocomplete = ({
  multiple = false,
  autoCompleteProps,
  value,
  onChange,
  filter = dummyFilter,
  textFieldProps = dummyFilter,
}: EspaciosAreasAutocompleteProps) => {
  const classes = useStyles();
  const {
    working,
    data: options,
    recall,
  } = useMateriaLibbyCall<Materia[]>({
    methodName: 'fetch',
    params: [],
    noAutoCall: true,
  });

  useEffect(() => {
    if (recall && filter?.espacios.length !== 0) {
      const params = { filter, limit: 20000 };
      recall(params);
    }
  }, [filter, recall]);

  const areas = useMemo(
    () =>
      !options
        ? []
        : options
            .filter((option) =>
              Array.isArray(value)
                ? value.findIndex(
                    (val: any) => val.idArea === option.area.idArea,
                  ) < 0
                : value.idArea === option.area.idArea,
            )
            .map((item: any) => item.area),
    [options, value],
  );

  const onChangeAutoComplete = useCallback(
    (event, newValue: Area & Area[]) => {
      onChange(newValue);
    },
    [onChange],
  );

  const uniqueAreas = uniqBy(areas, (e) => e.idArea);

  const getOptionLabel = useCallback((option: Area) => option.descripcion, []);
  return (
    <>
      {!working && options && (
        <Autocomplete
          classes={classes}
          multiple={multiple}
          options={uniqueAreas}
          onChange={onChangeAutoComplete}
          value={value || (multiple ? [] : undefined)}
          renderInput={(params) => (
            <TextField {...params} variant="outlined" {...textFieldProps} />
          )}
          getOptionLabel={getOptionLabel}
          getOptionSelected={(option: Area, _value: Area) =>
            option.idArea === _value.idArea
          }
          {...(autoCompleteProps || {})}
        />
      )}
    </>
  );
};
