import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class CriteriosEvaluacionDAO extends LibbyFetchDAO {
  constructor() {
    super('acap.criterios_evaluacion', 'idCriterioEvaluacion');
  }

  getAllActivos(): Promise<any> {
    return this.query()
      .equals('activo', true)
      .orderBy('idCriterioEvaluacion')
      .run();
  }
}
