import { SecundarioCustom } from 'src/app/models';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'secundario_custom';

const defaultOptions: PartialBusinessEntityOptions<any> = {};

const optionsToUse = !businessOptions.SecundarioCustom
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.SecundarioCustom,
      hoc: { ...defaultOptions.hoc, ...businessOptions.SecundarioCustom.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<SecundarioCustom>(DAO_NAME, optionsToUse);

// hooks
export const useSecundarioCustomFetchById = fetchById;
export const useSecundarioCustomLibbyCall = libbyCall;
export const useSecundarioCustomDAO = libbyDAO;
export const useSecundarioCustomLibbyFetch = libbyFetch;

// components/hoc
export const SecundarioCustomAutocomplete = entityAutocomplete;
export const SecundarioCustomCall = renderPropCall;
export const SecundarioCustomById = renderPropFetchById;
export const SecundarioCustomSelect = simpleSelect;
export const SecundarioCustomAccordionSelector = accordionSelector;
export const SecundarioCustomSelectedChips = selectedChips;

// context
// ByIdContext
export const useSecundarioCustomByIdContext = useByIdContext;
export const SecundarioCustomByIdProvider = ByIdProvider;
export const SecundarioCustomByIdContext = ByIdContext;
// GeneralContext
export const useSecundarioCustomContext = useGeneralContext;
export const SecundarioCustomProvider = GeneralProvider;
export const SecundarioCustomContext = GeneralContext;
