import React, { useCallback } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { makeStyles } from '@material-ui/core/styles';
import { MiEscuela, BuenosAiresCiudadLogin } from 'src/assets/images/svgs';
import { LibbyObject } from 'src/commons';
import { LoginFooter, LoginForm } from './components';
import { LoginCard } from './components/LoginCard.tsx';
import { GoogleApi } from '../../../app/services';
import { useSnackbar } from 'notistack';
import {
  BuenosAiresCiudadContainer,
  Container,
  DescriptionText,
  DescriptionTextTitle,
  FormContainer,
  IconContainer,
  LogoBienvenida,
  ParentContainer,
} from './Login.styles';

const LoginStyles = makeStyles(() => ({
  snackBar: {
    marginBottom: '21px',
  },
}));

const LoginRaw = ({ libby }: LibbyObject) => {
  const classes = LoginStyles();
  const { enqueueSnackbar } = useSnackbar();

  const loginAction = useCallback(
    async (user, password) => {
      try {
        const res = await libby.session.login(user, password, {
          username: user,
          password,
        });
        if (!res) {
          // eslint-disable-next-line
          enqueueSnackbar(
            'El usuario ingresado no es válido. Intentalo nuevamente.',
            {
              className: classes.snackBar,
              variant: 'warning',
            },
          );
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
      }
    },
    [classes.snackBar, enqueueSnackbar, libby.session],
  );

  const googleSignIn = useCallback(async () => {
    try {
      const token = await GoogleApi.singIn();
      const res = await libby.session.login('google', 'sign in', {
        provider: 'google',
        id_token: token,
      });
      if (!res) {
        // eslint-disable-next-line
        enqueueSnackbar('Error de usuario, use un dominio correcto', {
          className: classes.snackBar,
          variant: 'warning',
        });
      }
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      if (e.message === 'Dominio no permitido') {
        // eslint-disable-next-line
        enqueueSnackbar(e.message, {
          className: classes.snackBar,
          variant: 'warning',
        });
      } else {
        // eslint-disable-next-line
        enqueueSnackbar(
          'Ha ocurrido un error al intentar iniciar sesión con Google',
          {
            className: classes.snackBar,
            variant: 'error',
          },
        );
      }
    }
  }, [classes.snackBar, enqueueSnackbar, libby.session]);

  return (
    <ParentContainer>
      <Container>
        <LogoBienvenida>
          <BuenosAiresCiudadContainer>
            <BuenosAiresCiudadLogin />
          </BuenosAiresCiudadContainer>

          <IconContainer>
            <MiEscuela />
          </IconContainer>

          <DescriptionTextTitle>¡Te damos la bienvenida!</DescriptionTextTitle>
          <DescriptionText>
            Sigamos conectados/as: docentes, estudiantes y familias.
            <br />
            ¡Toda tu escuela en una plataforma!
          </DescriptionText>
        </LogoBienvenida>

        <FormContainer>
          <LoginForm
            handleLogin={(user, password) => loginAction(user, password)}
            onGoogleSignInClick={googleSignIn}
          />
        </FormContainer>
      </Container>

      <LoginCard />

      <LoginFooter />
    </ParentContainer>
  );
};

export const Login = DatabaseConnector(LoginRaw)();
