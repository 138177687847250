import { Proyeccion, Seccion } from 'src/app/models';

interface SingleProyeccion {
  articula: boolean;
  seccionDestino: null | object;
}

export function getByProyeccionStatus<
  T extends { articula: boolean; seccionDestino: null | object },
>(data: SingleProyeccion[], status = 'gestionado') {
  return data.filter((item: T) => {
    if ('' === status) {
      return data;
    } else if ('gestionado' === status) {
      return !item.articula || (item.articula && item.seccionDestino !== null);
    } else {
      return item.articula && item.seccionDestino === null;
    }
  });
}
