import React, { useCallback, useMemo } from 'react';
import { useField } from 'react-final-form-hooks';
import { InputAdornment } from '@material-ui/core';
import { IPickerDialog } from '../../../commons/types';
import { getErrorAndHelperTextNoTranslation } from '../../../utils/materialHelpers';
import { Input, InputProps } from '../../../app/components/inputs/Input';

export interface FormDialogPickerProps<T = any> {
  name: string;
  form: any;
  placeholder?: string;
  disabled?: boolean;
  label: string;
  inputProps?: InputProps;
  dialogInstance: IPickerDialog;
  getSelectedRender: (
    value: string | string[] | undefined | null,
    onChange: (value: string | string[] | undefined | null) => void,
  ) => JSX.Element | JSX.Element[];
}

export const FormDialogPicker = ({
  name,
  form,
  placeholder,
  disabled,
  label,
  inputProps,
  dialogInstance,
  getSelectedRender,
}: FormDialogPickerProps) => {
  const field = useField(name, form);
  const onClick = useCallback(async () => {
    const value = await dialogInstance.open(field.input.value);
    if (value !== null) {
      field.input.onChange(value);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.input.value, field.input.onChange, dialogInstance]);
  const joinedInputProps = useMemo(() => {
    const { InputProps: baseInputProps = {} } = inputProps || {};
    const joinedInputsProps = {
      ...baseInputProps,
      startAdornment: (
        <InputAdornment position="start">
          {getSelectedRender(field.input.value, field.input.onChange)}
        </InputAdornment>
      ),
    };
    return { ...inputProps, InputProps: joinedInputsProps };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputProps, field.input.value, field.input.onChange]);
  return (
    <Input
      placeholder={placeholder}
      label={label}
      disabled={disabled}
      {...field.input}
      onClick={onClick}
      onChange={undefined}
      value=""
      {...getErrorAndHelperTextNoTranslation(field)}
      {...joinedInputProps}
    />
  );
};
