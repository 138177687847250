import { Box, Radio, Tooltip } from '@material-ui/core';
import React from 'react';
import { CicloLectivo } from 'src/app/models';
import { InfoTableColumn } from 'src/commons';
import { DataSearchSanatized } from 'src/platform/libby/dao';

interface EnrolledStudenColumnsProps {
  cicloLectivo: CicloLectivo | null;
  selected: DataSearchSanatized | null;
  localizacionId: string;
  nivelId: string;
  setSelected: React.Dispatch<React.SetStateAction<DataSearchSanatized | null>>;
}

/*
 const isMatriculado =
       data.matricula.id_ciclo_lectivo === this.cicloLectivo.idCicloLectivo;
     const hasProyeccion =
       data.proyeccion.ciclo_lectivo === this.cicloLectivo.anio - 1;
     const isEgresa =
       data.proyeccion.id_estado_pase_anio === ESTADOS_PASES.EGRESA;

     if (isMatriculado) {
       // NO SE PUEDE INSCRIBIR YA SE ENCUENTRA MATRICULADO
       // Mostramos en que colegio esta matriculado ( nivel, anio, cl, cue )
     } else if (hasProyeccion && !isEgresa) {
       // NO SE PUEDE INSCRIBIR YA QUE TIENE UN PROYECCION PARA EL SIGUIENTE CICLO LECTIVO
       // Mostramos en que colegio esta matriculado y a que colegio ira ( nivel, anio, cl, cue, cue destino)
     } else {
       // SI SE PUEDE INSCRIBIR ALUMNO
       // Si tiene una inscripcion mostramos donde esta inscripto ( cue, cl )
       // Si no no mostramos nada
     }
     // Si no existen resultados
     // POR LOCALIZACION --> Crear nuevo
     // POR APELLIDO --> Crear nuevo
     // POR DOCUMENTO --> Sugerir la busqueda por apellido --> crear nuevo
 
 */

const getAlumnStatus = (row: DataSearchSanatized, cl: CicloLectivo | null) => {
  const clAnio = cl?.anio || 0;
  const isMatriculado = (row.matricula?.anio || 0) >= clAnio;
  const hasProyeccion = row.proyeccion?.cicloLectivo === clAnio - 1;
  const isEgresa = row.proyeccion?.idEstadoPaseAnio === 3; // EGRESA;
  const cueDestinoIsEfectivizado =
    (row.proyeccion?.cueDestino.efectivizado || 0) >= (cl?.idCicloLectivo || 0);

  const hasCueDestino = Boolean(row.proyeccion?.cueDestino.idlocalizacion);

  const isInscripto =
    (row.inscripcion?.anio || 0) >= clAnio && !row.inscripcion?.isDeleted;

  return {
    isMatriculado,
    isProyectado:
      hasProyeccion && !(isEgresa || cueDestinoIsEfectivizado) && hasCueDestino,
    isInscripto,
  };
};
interface MessageCompProps {
  messages: string[];
}
const MessageComp = ({ messages = [] }: MessageCompProps) => {
  return (
    <Box>
      <Box component="ul" ml={2} p={0}>
        {messages.map((msg) => (
          <Box key={msg} component="li">
            {msg}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

const hasSameLocation = (
  localizacionId: string | number,
  studentLocalizacionId: string | number,
) => {
  return Number(localizacionId) === Number(studentLocalizacionId);
};

export const useAddStudenColumns = ({
  cicloLectivo,
  selected,
  localizacionId,
  setSelected,
}: EnrolledStudenColumnsProps) => {
  const columns: InfoTableColumn<DataSearchSanatized>[] = [
    {
      id: 'radio',
      label: '',
      width: '5%',
      hideSortIcon: true,
      style: { textAlign: 'right' },
      render: (_, row) => {
        let disabled = false;
        const messages: string[] = [];
        const { isInscripto, isMatriculado, isProyectado } = getAlumnStatus(
          row,
          cicloLectivo,
        );
        let cueanexo = 0;
        if (isMatriculado) {
          // NO SE PUEDE INSCRIBIR YA SE ENCUENTRA MATRICULADO
          // Mostramos en que colegio esta matriculado ( nivel, anio, cl, cue )
          messages.push(
            'El estudiante se encuentra matriculado. Para reinscribirlo se debe iniciar el proceso de gestión de pase.',
          );
          disabled = true;
          cueanexo = row.matricula?.cueAnexo || 0;
        } else if (isInscripto) {
          cueanexo = row.inscripcion?.cueAnexo || 0;
          if (hasSameLocation(localizacionId, cueanexo)) {
            disabled = true;
            messages.push(
              'El estudiante ya se encuentra inscripto en este establecimiento.',
            );
          }
        } else if (isProyectado) {
          // NO SE PUEDE INSCRIBIR YA QUE TIENE UN PROYECCION PARA EL SIGUIENTE CICLO LECTIVO
          // Mostramos en que colegio esta matriculado y a que colegio ira ( nivel, anio, cl, cue, cue destino)
          messages.push('El alumno tiene una proyección.');
          cueanexo = row.proyeccion?.cueOrigen.cueAnexo || 0;
          disabled = true;
        }

        if (row.isDuplicado) {
          messages.push('Este alumno esta duplicado.');
          disabled = true;
        }

        return (
          <Tooltip
            title={<MessageComp messages={messages} />}
            disableFocusListener={!disabled}
            disableHoverListener={!disabled}
            disableTouchListener={!disabled}
          >
            <Box component="span">
              <Radio
                checked={row.idAlumno === selected?.idAlumno}
                onChange={() => {
                  setSelected(row);
                }}
                disabled={disabled}
                value={row.idAlumno}
                name="radio-button-demo"
                inputProps={{ 'aria-label': 'A' }}
              />
            </Box>
          </Tooltip>
        );
      },
    },
    {
      id: 'nombre',
      label: 'Nombre y Apellido',
      width: '20%',
      orderById: '[apellido, nombre]',
      render: (_, row) => {
        return row.nombre + ' ' + row.apellido;
      },
    },
    {
      id: 'descripcionTipoDocumento',
      label: 'Tipo documento',
      width: '10%',
      orderById: 'descripcionTipoDocumento',
    },
    {
      id: 'documento',
      label: 'Documento',
      width: '10%',
      orderById: 'documento',
    },
    {
      id: 'descripcionGenero',
      label: 'Genero',
      width: '10%',
      orderById: 'descripcionGenero',
    },
    {
      id: 'establishment',
      label: 'Establecimiento de origen',
      width: '20%',
      hideSortIcon: true,
      // orderById: 'idLocalizacion.idEstablecimineto.nombre',
      render: (_, row) => {
        const { isInscripto, isMatriculado, isProyectado } = getAlumnStatus(
          row,
          cicloLectivo,
        );

        let establecimiento =
          row.inscripcion?.descripcion ||
          row.proyeccion?.cueOrigen?.descripcion ||
          '-';
        if (isMatriculado) {
          establecimiento = row.matricula?.descripcion || '';
        } else if (isInscripto) {
          establecimiento = row.inscripcion?.descripcion || '';
        } else if (isProyectado) {
          establecimiento = row.proyeccion?.cueOrigen?.descripcion || '';
        }
        return establecimiento;
      },
    },
    {
      id: 'level',
      label: 'Nivel',
      width: '10%',
      hideSortIcon: true,
      render: (_, row) => {
        const { isInscripto, isMatriculado, isProyectado } = getAlumnStatus(
          row,
          cicloLectivo,
        );
        let level =
          row.inscripcion?.descripcionNivel ||
          row.proyeccion?.descripcionNivel ||
          '-';
        if (isMatriculado) {
          level = row.matricula?.descripcionNivel || '';
        } else if (isInscripto) {
          level = row.inscripcion?.descripcionNivel || '';
        } else if (isProyectado) {
          level = row.proyeccion?.descripcionNivel || '';
        }
        return level;
      },
    },
  ];

  return columns;
};
