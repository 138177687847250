import { Seccion } from 'src/app/models';
import { Orientacion } from 'src/app/models/orientaciones';
import { AnyObject } from 'src/commons';

export const getUniqueItems = (items: AnyObject[]) => {
  const stringifiedItems = items.map((item) => JSON.stringify(item));

  return [...new Set(stringifiedItems)].map((item) => JSON.parse(item));
};

export const isSameLocalization = function (idLocalizacion: string) {
  return (
    (this as { seccion: Seccion }).seccion.localizacion.idLocalizacion ===
    idLocalizacion
  );
};
