import {
  GruposInscripcionesDAO,
  GruposInscripcionesOfertaDAO,
} from 'src/platform/libby/dao';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { GruposInscripciones } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'grupos_inscripciones';
const DAO_NAME_GI_OFERTAS = 'grupos_inscripciones_ofertas'; // DAO para gruposinscripciones traverse unico

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idGrupoInscripcion',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idGrupoInscripcion',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.GruposInscripciones
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.GruposInscripciones,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.GruposInscripciones.hoc,
      },
    };

const {
  hook: { libbyCall, libbyDAO, libbyFetch },
  hoc: { selectInput },
} = makeBusinessEntity<GruposInscripciones, GruposInscripcionesDAO>(
  DAO_NAME,
  optionsToUse,
);

const {
  hook: { libbyFetch: newLibbyFetch },
} = makeBusinessEntity<GruposInscripciones, GruposInscripcionesOfertaDAO>(
  DAO_NAME_GI_OFERTAS,
  {},
);

// hooks
export const useGruposInscripcionesLibbyCall = libbyCall;
export const useGruposInscripcionesDAO = libbyDAO;
export const useGruposInscripcionesLibbyFetch = libbyFetch;
export const useGruposInscripcionesOfertaLibbyFetch = newLibbyFetch;

// components/hoc
export const GruposInscripcionesSelect = selectInput;
