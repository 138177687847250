import { useSnackbar } from 'notistack';
import React, { useCallback, useState, useMemo } from 'react';
import { useCalificacionesRecuperacionDAO } from 'src/app/business';
import { DEFAULT_HIDE_TIME } from 'src/commons/const/ui';
import { useCalificacionesSecundarioContext } from '../context/CalificacionesSecundarioContext';
import {
  RecuperacionFactory,
  RecuperacionResponse,
} from '../../CalificacionesSecundario/factories/RecuperacionFactory';

export const useRecuperacionesSubmit = (
  rows: Array<RecuperacionResponse>,
  reFetch: () => void,
  ecs: string,
) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const recuperacionesDAO = useCalificacionesRecuperacionDAO();
  const { factory } = useCalificacionesSecundarioContext<RecuperacionFactory>();

  const onSubmit = useCallback(async () => {
    setSubmitting(true);
    const updatedRows = factory
      .getData()
      .getRowsModified(rows, ['diciembre', 'febreroMarzo']);
    try {
      await updatedRows.save(recuperacionesDAO, ecs);
      setSubmitting(false);
      reFetch && reFetch();
      enqueueSnackbar('¡Las calificaciones fueron guardadas con éxito!', {
        variant: 'success',
        autoHideDuration: DEFAULT_HIDE_TIME,
      });
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  }, [factory, rows, enqueueSnackbar, reFetch, recuperacionesDAO, ecs]);

  return {
    onSubmit,
    submitting,
  };
};
