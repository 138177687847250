import { useSnackbar } from 'notistack';
import { useCallback, useState, useMemo } from 'react';
import { useCalificacionesstatePutRequest } from '../../../../app/business/businessCustomEndpoints/calificacionesstate';
import { DEFAULT_HIDE_TIME } from '../../../../commons/const/ui/snackbar';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useRolesContext } from 'src/context/RolesContext';

type params = {
  isSecundario?: boolean;
  isInitial?: boolean;
  isJE?: boolean;
  jemateria?: number;
  asunto?: string;
  ecs?: string;
  filter?: any;
  reFetch?: any;
};

export const useCalificacionesSwitch = ({
  filter,
  reFetch,
  isInitial,
  ecs,
  asunto,
  isSecundario,
  isJE,
  jemateria,
}: params) => {
  const [switchSubmitting, setSwitchSubmitting] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectedRole: { rol },
  } = useRolesContext();

  const { request } = useCalificacionesstatePutRequest();

  const onSwitch = useCallback(
    async (openOrClose) => {
      const isOpen = openOrClose === 'open';
      const confirm =
        isOpen || rol !== '2' // rol docente
          ? true
          : await confirmDialog.show({
              title: 'Cierre de calificaciones',
              content:
                'Una vez cerradas las calificaciones no podrán ser modificadas nuevamente, deberá solicitar autorización de un directivo o coordinador para poder modificarlas.',
              confirmText: 'Cerrar calificaciones',
              cancelText: 'Seguir editando',
            });
      if (confirm) {
        try {
          setSwitchSubmitting(true);
          let rowsTo: Object = {};
          if (isInitial) {
            rowsTo = {
              idEspacioCurricularSeccion: filter.espacioCurricularSeccion,
              idPeriodo: filter.periodo,
              abierto: isOpen,
            };
          } else if (isSecundario) {
            rowsTo = {
              idEspacioCurricularSeccion: ecs
                ? ecs
                : filter.espacioCurricularSeccion,
              idPeriodo: filter.periodo,
              abierto: isOpen,
            };
          } else if (isJE) {
            rowsTo = {
              idSeccion: filter.seccion,
              asunto,
              idPeriodo: filter.periodo,
              abierto: isOpen,
              idJEMateria: jemateria,
            };
          } else {
            rowsTo = {
              idSeccion: filter.seccion,
              asunto,
              idPeriodo: filter.periodo,
              abierto: isOpen,
            };
          }

          await request(rowsTo);

          reFetch && reFetch();
          if (isOpen) {
            enqueueSnackbar('¡Las calificaciones fueron abiertas con éxito!', {
              variant: 'success',
              autoHideDuration: DEFAULT_HIDE_TIME,
            });
          } else if (openOrClose === 'close') {
            enqueueSnackbar('¡Las calificaciones fueron cerradas con éxito!', {
              variant: 'success',
              autoHideDuration: DEFAULT_HIDE_TIME,
            });
          }
          setSwitchSubmitting(false);
        } catch (e) {
          console.log(e);
          setSwitchSubmitting(false);
        }
      }
    },
    [
      asunto,
      ecs,
      enqueueSnackbar,
      filter.espacioCurricularSeccion,
      filter.periodo,
      filter.seccion,
      isInitial,
      isSecundario,
      reFetch,
      request,
      rol,
      isJE,
      jemateria,
    ],
  );

  return { onSwitch, switchSubmitting };
};
