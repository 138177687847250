import React, { useState, useCallback, useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Grid, Typography, makeStyles } from '@material-ui/core';
import { alumnEnrolledStatus, LibbyObject } from 'src/commons';
import { grey } from 'src/theme/colors';
import { useLibbyFetch } from 'src/commons/hooks';
import { SimpleSelect, MainInput, Footer } from 'src/commons/components';
import customFormDialog from 'src/commons/services/customFormDialog';
import { AlumnoMovimiento } from '../../../../../app/models';

interface RegisteredAlumnModalProps {
  libby: LibbyObject;
  alumn: number;
  alumnMovement: number;
  reFetch: () => void;
  alumnoMovimiento: AlumnoMovimiento;
  localizacionId: number;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

const useRegisteredAlumnModalStyles = makeStyles(() => ({
  text: {
    color: grey.medium,
    paddingBottom: 8,
  },
}));

export const RegisteredAlumnModalRaw = ({
  libby,
  alumn,
  alumnMovement,
  reFetch,
  alumnoMovimiento,
  localizacionId,
  setLoading,
}: RegisteredAlumnModalProps) => {
  const classes = useRegisteredAlumnModalStyles();
  const [idMotivo, setIdMotivo] = useState<string>('');
  const [descripcionMotivo, setDescripcionMotivo] = useState<string>('');

  const paramsFetchMotivos = useMemo(
    () => ({
      daoName: 'baja_motivo',
      filter: {
        1: [{ path: 'tipo', value: 'Matriculacion' }],
      },
    }),
    [],
  );

  const { data: motivosDeBaja, working } = useLibbyFetch(
    libby,
    paramsFetchMotivos,
  );

  const actionsRemove = useCallback(
    async (motivo: string, descripcion: string) => {
      customFormDialog.handleCancel();
      try {
        setLoading(true);
        const inscripcionAlumno =
          await libby.inscripcion_alumno.getByAlumnoMovimientoData({
            idAlumno: Number(alumnoMovimiento.alumno.idAlumno),
          });

        if (!inscripcionAlumno.length) {
          await libby.inscripcion_alumno.save({
            descripcionBajaMotivo: '',
            alumno: alumnoMovimiento.alumno,
            anio: alumnoMovimiento.seccion.anio,
            cicloLectivo: alumnoMovimiento.cicloLectivo,
            localizacion: { idLocalizacion: localizacionId },
            nivel: alumnoMovimiento.seccion.nivel,
            turno: alumnoMovimiento.seccion.turno,
            condicion: { idCondicion: 1 },
          });
        }

        await libby.alumno_movimiento.aspect('limit_plan').remove({
          idAlumnoMovimiento: alumnMovement,
          idAlumno: alumn,
          bajaMotivo: motivo,
          descripcionBajaMotivo: descripcion,
        });
        await libby.alumno.save({
          ...alumnoMovimiento.alumno,
          estadoMatricula: {
            idEstadoMatricula: alumnEnrolledStatus.INSCRIPTO,
          },
        });
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
      } finally {
        setLoading(false);
      }
      reFetch();
    },
    [
      alumn,
      alumnMovement,
      libby,
      reFetch,
      alumnoMovimiento,
      localizacionId,
      setLoading,
    ],
  );

  const buttonConfig = [
    {
      title: 'Cancelar',
      type: 'secondary',
      handleOnClick: () => customFormDialog.handleCancel(),
    },
    {
      title: 'Aceptar',
      handleOnClick: () => actionsRemove(idMotivo, descripcionMotivo),
      disabled: !idMotivo || !descripcionMotivo,
    },
  ];
  return (
    <>
      {!working && (
        <Grid container style={{ paddingBottom: '10px' }}>
          <Grid container style={{ padding: '24px' }}>
            <Grid item xs={12} style={{ paddingBottom: '20px' }}>
              <SimpleSelect
                value={idMotivo}
                handleChange={(e) => setIdMotivo(e.target.value)}
                content={motivosDeBaja}
                title="Seleccioná el motivo de baja"
                placeholder="Seleccioná el motivo de baja"
                labelKey="descripcion"
                valueKey="idMotivo"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography className={classes.text}>
                Descripción de baja
              </Typography>
              <MainInput
                fullWidth
                handleChange={(e) => setDescripcionMotivo(e.target.value)}
                value={descripcionMotivo}
                placeholder="    Describí­ el motivo de baja"
              />
            </Grid>
          </Grid>
          <Footer buttonConfig={buttonConfig} />
        </Grid>
      )}
    </>
  );
};

export const RegisteredAlumnModal = DatabaseConnector(RegisteredAlumnModalRaw)(
  'inscripcion_alumno',
  'baja_motivo',
  'alumno_movimiento',
  'alumno',
);
