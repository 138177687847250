import React from 'react';
import { Grid, TextField } from '@material-ui/core';
import { Recuperacion } from 'src/app/models';
import { AnyObject, InfoTableColumn, SimpleSelect } from 'src/commons';
import { primary } from 'src/theme/colors';
import { ColumnBuilder } from './ColumnBuilder';
import { CalificacionesFilter } from '../../CalificacionesRecuperacion/hooks/useInitSecundario';
import { isEqual } from 'lodash';
import {
  SelectorRecuperacionDic,
  SelectorRecuperacionFeb,
} from '../../components/CalificacionesSelector';

export type RecuperacionResponse = {
  abiertoDiciembre?: boolean;
  abiertoFebreroMarzo?: boolean;
  idAlumno: string;
  nombre: string;
  apellido: string;
  diciembre?: string;
  febreroMarzo?: string;
  idRecuperacionDiciembre?: number;
  idRecuperacionFebreroMarzo?: number;
};

export type RecuperacionRow = {
  alumno: string;
  diciembre: React.ReactElement;
  febreroMarzo: React.ReactElement;
  definitiva?: number;
};

export class RecuperacionFactory
  implements ColumnBuilder<RecuperacionResponse, RecuperacionRow>
{
  filter: CalificacionesFilter = {
    anio: '',
    periodo: -1,
    espacioCurricularSeccion: '',
    seccion: '',
    search: '',
  };
  private rows: Array<RecuperacionResponse> = [];
  private changeHandler = (
    e: React.ChangeEvent<{ value: unknown }>,
    i: number,
  ) => {};

  setConfig(config: AnyObject) {
    if ('changeHandler' in config) this.changeHandler = config.changeHandler;
  }

  validateConfig() {
    if (!this.changeHandler) throw new Error('Debe definirse un changeHandler');
  }

  getRows(rows: Array<RecuperacionResponse>) {
    this.validateConfig();
    const isDisabled: boolean = rows.some(
      (recuperacion: RecuperacionResponse) =>
        'abiertoDiciembre' in recuperacion && !recuperacion.abiertoDiciembre,
    );

    return rows.map((row: RecuperacionResponse, index: number) => {
      return {
        alumno: `${row.apellido}, ${row.nombre}`,
        diciembre: (
          <SelectorRecuperacionDic
            handleChange={(event) => this.changeHandler(event, index)}
            isDisabled={isDisabled}
            value={row.diciembre}
            name="diciembre"
          />
        ),
        febreroMarzo: (
          <SelectorRecuperacionFeb
            handleChange={(event) => this.changeHandler(event, index)}
            isDisabled={isDisabled || row?.diciembre !== 'En Proceso'}
            value={row.febreroMarzo}
            name="febreroMarzo"
          />
        ),
        definitiva:
          Number(row?.diciembre) >= 6 ? row.diciembre : row.febreroMarzo,
      };
    });
  }

  getColumns(): Array<InfoTableColumn<RecuperacionResponse>> {
    return [
      {
        id: 'alumno',
        label: 'Alumno',
        width: '10%',
        orderById: 'nombre',
        style: {
          color: primary.lightBlue,
        },
        hideSortIcon: true,
        noSort: true,
      },
      {
        id: 'diciembre',
        label: 'Diciembre',
        width: '10%',
        orderById: 'diciembre',
        style: {
          color: primary.lightBlue,
        },
        hideSortIcon: true,
        noSort: true,
      },
      {
        id: 'febreroMarzo',
        label: 'Febrero - Marzo',
        width: '10%',
        orderById: 'febreroMarzo',
        style: {
          color: primary.lightBlue,
        },
        hideSortIcon: true,
        noSort: true,
      },
      {
        id: 'definitiva',
        label: 'Calificación Definitiva',
        width: '10%',
        orderById: 'definitiva',
        style: {
          color: primary.lightBlue,
        },
        hideSortIcon: true,
        noSort: true,
      },
    ];
  }

  getFieldsToValidate() {
    return [];
  }

  hasCalifications() {
    return this.rows.some(
      (calf: RecuperacionResponse) =>
        Boolean(calf.diciembre) || Boolean(calf.febreroMarzo),
    );
  }

  changeRow(
    event: React.ChangeEvent<{ value: unknown }>,
    initialRows: Array<RecuperacionResponse>,
    index: number,
  ): Array<RecuperacionResponse> {
    const target = event.target;
    const updateRow = (row: RecuperacionResponse) => {
      if (
        !target.value &&
        !Array.isArray(target.value) &&
        typeof target.value !== 'number' &&
        typeof target.value !== 'boolean' &&
        target.value !== ''
      ) {
        return row;
      }
      return {
        ...row,
        [target.name]: target.value,
        ...(target.name === 'diciembre' &&
          target.value === '' && { definitiva: '', febreroMarzo: '' }),
        ...(target.name === 'diciembre' &&
          target.value >= 6 && {
            febreroMarzo: '',
            definitiva: target.value,
            aprobado: true,
          }),
        ...(target.name === 'diciembre' &&
          target.value === 'En proceso' && {
            definitiva: '',
            aprobado: false,
          }),
      };
    };
    const newRows = [...initialRows];
    newRows[index] = updateRow(newRows[index]);
    return newRows;
  }

  getSubmitRows(
    rows: Array<RecuperacionResponse>,
  ): Array<RecuperacionResponse> {
    return rows.filter((row: RecuperacionResponse) => {
      let isUpdated = false;
      const currentCalification = this.rows.find(
        (recuperacion: RecuperacionResponse) =>
          recuperacion.idCalificacion === row.idCalificacion,
      );
      isUpdated = !isEqual(currentCalification, row);

      return isUpdated;
    });
  }

  isClosed(): boolean {
    return this.rows.some(
      (row: RecuperacionResponse) => row.abiertoDiciembre === false,
    );
  }

  setRows(rows: Array<RecuperacionResponse>): void {
    this.rows = rows;
  }

  isAValidRow(row: RecuperacionResponse): boolean {
    let isUpdated = false;
    const currentCalification = this.rows.find(
      (recuperacion: RecuperacionResponse) =>
        recuperacion.idAlumno === row.idAlumno,
    );

    isUpdated = !isEqual(currentCalification, row);

    return isUpdated;
  }

  getData(): Array<RecuperacionResponse> {
    return this.rows;
  }
}
