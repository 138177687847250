import React, { useCallback } from 'react';
import {
  HeaderTable,
  MainButton,
  useBackButton,
  LibbyObject,
  Loading,
} from 'src/commons';
import { useLibbyFetch } from 'src/commons/hooks';
import { useParams } from 'react-router-dom';
import { PermissionBlocker } from 'src/lib/permission';
import { BLOCKER_ID } from 'src/platform/permission/const';
import { FilterTablesPlanificacion } from './forms/PlanificacionDisciplinarOrientada/components/FilterTablesPlanificacion';
import { usePlanificationTable } from './hooks';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useGeneralContext } from 'src/context/GeneralContext';
import { PDFdoc } from './Print/PDFdoc';

export const PlanificacionTableWithLibby = ({ libby }: LibbyObject) => {
  const {
    generalState: {
      espacioCurricular: {
        planificacion: {
          print: { idPlanificacion, type, download },
        },
      },
    },
  } = useGeneralContext();

  useBackButton('/private/espacioscurriculares');

  const { data: ciclo_lectivo } = useLibbyFetch(libby, {
    daoName: 'ciclo_lectivo',
  });

  const { idEspacioCurricular } = useParams();
  const {
    Table,
    objSelectPath,
    objSearchPath,
    cicloFilter,
    arrFilterTables,
    espacioCurricular,
    crearPlanificaciones,
    createdPlanificationCheck,
  } = usePlanificationTable({ ciclo_lectivo });

  const renderButton = useCallback(
    () => (
      <PermissionBlocker id={BLOCKER_ID.ADD_PLANIFICATIONS}>
        <MainButton
          title="Crear Planificación"
          ListMenu={crearPlanificaciones}
        />
      </PermissionBlocker>
    ),
    [crearPlanificaciones],
  );

  return (
    <>
      <HeaderTable
        title={espacioCurricular?.localizacion.descripcion}
        renderRight={renderButton}
      />
      <FilterTablesPlanificacion content={arrFilterTables} />
      <Table
        cicloFilter={cicloFilter}
        idEspacioCurricular={idEspacioCurricular}
        selectValues={objSelectPath}
        search={objSearchPath}
        createdPlanificationCheck={createdPlanificationCheck}
      />
      {idPlanificacion && (
        <>
          <Loading />
          <PDFdoc
            idPlanificacion={idPlanificacion}
            type={type}
            download={download}
          />
        </>
      )}
    </>
  );
};

export const PlanificacionTable = DatabaseConnector(
  PlanificacionTableWithLibby,
)('ciclo_lectivo');
