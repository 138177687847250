import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';
import { AnioSelect, SeccionSelect } from 'src/app/business';
import { usePropuestaJELibbyFetch } from 'src/app/business/presentismo_v2';
import { AnyObject } from 'src/commons';
import { presentismo } from '../../../types';

export const ModalHeader = ({
  changeFilters,
  filters,
  nivel = '',
}: presentismo.UI.ModalHeader) => {
  const { data: propuestas = [], working } = usePropuestaJELibbyFetch({
    aspect: 'default',
  });

  const seccionFilter = useMemo(
    () => ({
      anio: [
        {
          path: 'anio',
          value: filters.anio,
        },
      ],
      // cicloLectivo: [
      //   {
      //     path: 'cicloLectivo.anio',
      //     value: currentCicloLectivo ?? moment().format('YYYY'),
      //   },
      // ],
    }),
    [filters.anio],
  );

  const anioFilter = useMemo(
    () => ({
      0:
        nivel === '2'
          ? [
              {
                path: 'idAnio',
                value: 5,
              },
              {
                path: 'idAnio',
                value: 6,
              },
              {
                path: 'idAnio',
                value: 7,
              },
              {
                path: 'idAnio',
                value: 9,
              },
            ]
          : [
              {
                path: 'idAnio',
                value: 21,
              },
            ],
    }),
    [nivel],
  );

  const invalidSecciones = propuestas.map((propuesta) => propuesta.seccion);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Grid container spacing={3}>
          <Grid item xs={3}>
            <AnioSelect
              title="Seleccioná el año"
              labelKey="descripcionAnio"
              valueKey="idAnio"
              value={filters.anio}
              filter={anioFilter}
              handleChange={(e) =>
                changeFilters({
                  seccion: '',
                  anio: e.target.value,
                })
              }
              orderBy="descripcionAnio"
              limit={30}
            />
          </Grid>
          {Boolean(filters.anio) && !working && (
            <Grid item xs={3}>
              <SeccionSelect
                title="Seleccioná la sección"
                labelKey="nombreSeccion"
                valueKey="idSeccion"
                orderBy="nombreSeccion"
                aspect="presentismo"
                limit={40}
                value={filters.seccion}
                handleChange={(e) =>
                  changeFilters({
                    ...filters,
                    seccion: e.target.value,
                  })
                }
                transformOptions={(secciones: Array<AnyObject>): AnyObject[] =>
                  secciones.map((seccion) => ({
                    ...seccion,
                    needToDisable: invalidSecciones
                      .map((item) => item.idSeccion)
                      .includes(seccion.idSeccion),
                  }))
                }
                enableFetch={Boolean(filters.anio)}
                filter={seccionFilter}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
