import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class InformeValorativoDAO extends LibbyFetchDAO {
  constructor() {
    super('informevalorativo', 'idInformeValorativo');
  }

  getAll() {
    return this.query().limit(0, 1000).run();
  }
}
