type workingDay = {
  label: string;
};

export const workingDays: workingDay[] = [
  {
    label: 'Completa',
  },
  {
    label: 'Extendida',
  },
  {
    label: 'Simple',
  },
];
