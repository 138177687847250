import { FieldState } from 'final-form';
import {} from 'react-final-form-hooks';
export type TranslationFunction = (str: string) => string;

type NonFunctionPropertyNames<T> = {
  [K in keyof T]: T[K] extends Function ? never : K;
}[keyof T];
type NonFunctionProperties<T> = Pick<T, NonFunctionPropertyNames<T>>;

type FieldMetaState<T> = NonFunctionProperties<FieldState<T>>;
interface MakeHelperTextProps {
  error: boolean;
  helperText: string;
}

export const makeGetHelperText =
  (t: TranslationFunction = (str: string) => str) =>
  (meta: FieldMetaState<any>): MakeHelperTextProps => {
    let helperTextEnd = '';
    const text = meta.touched && meta.error && t(meta.error);
    if (text && typeof text === 'object') {
      helperTextEnd = Object.values<string>(text)[0];
    } else {
      helperTextEnd = text;
    }
    return { error: !!helperTextEnd, helperText: helperTextEnd };
  };

export const getHelperTextNoTranslation = makeGetHelperText();
