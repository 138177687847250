import { Alumno } from 'src/app/models';
import { PpsDAO } from 'src/platform/libby/dao';
import { getFileProperties } from 'src/utils';
import { PPSFormValues } from './types';

export const submit = async (
  values: PPSFormValues,
  dao: PpsDAO,
  alumno: Alumno,
) => {
  let intervencionFilename;
  let intervencionBase64;
  let intervencionMimeType;

  let antecedentesFilename;
  let antecedentesBase64;
  let antecedentesMimeType;
  if (Boolean(values.intervencionesAdjunto?.files)) {
    const { file_name, base64, mime_type } = await getFileProperties(
      values.intervencionesAdjunto.files as File[],
      values.intervencionesAdjunto.name,
    );
    intervencionFilename = file_name;
    intervencionBase64 = base64;
    intervencionMimeType = mime_type;
  } else if (Boolean(values.intervenciones?.informe?.filename)) {
    intervencionFilename = values.intervenciones?.informe?.filename;
  }

  if (Boolean(values.antecedentesAdjunto?.files)) {
    const { file_name, base64, mime_type } = await getFileProperties(
      values.antecedentesAdjunto.files as File[],
      values.antecedentesAdjunto.name,
    );
    antecedentesFilename = file_name;
    antecedentesBase64 = base64;
    antecedentesMimeType = mime_type;
  } else if (Boolean(values.antecedentes?.informe?.filename)) {
    antecedentesFilename = values.antecedentes?.informe?.filename;
  }

  const { adjunto, adjuntoNombre, ...intervencionesRest } =
    values.intervenciones;
  const {
    adjuntoDiscapacidad,
    adjuntoNombreDiscapacidad,
    ...antecedentesRest
  } = values.antecedentes || {};

  const data = {
    actitud: { ...values.actitud },
    trayectoria: { ...values.trayectoria },
    convivencia: { ...values.convivencia },
    vinculo: { ...values.vinculo },
    intervenciones: {
      ...intervencionesRest,
      informe: {
        base64: intervencionBase64,
        mimeType: intervencionMimeType,
        filename: intervencionFilename,
        url: values.intervenciones.informe?.url,
      },
    },
    antecedentes: {
      ...antecedentesRest,
      informe: {
        base64: antecedentesBase64,
        mimeType: antecedentesMimeType,
        filename: antecedentesFilename,
        url: values.antecedentes?.informe?.url,
      },
    },
    jornada: { ...values.jornada },
  };

  const response = await dao.save({
    idPPS: values.idPPS,
    alumno: alumno.idAlumno,
    ...data,
  });

  return response;
};

export enum TABS_NAMES {
  ACTITUD = 'actitud',
  TRAYECTORIA = 'trayectoria',
  CONVIVENCIA = 'convivencia',
  VINCULO = 'vinculo',
  INTERVENCIONES = 'intervenciones',
  ANTECEDENTES = 'antecedentes',
  JORNADA = 'jornada',
  EMPTY = '',
}

export const TABS_MAP = {
  [TABS_NAMES.ACTITUD]: 0,
  [TABS_NAMES.TRAYECTORIA]: 1,
  [TABS_NAMES.CONVIVENCIA]: 2,
  [TABS_NAMES.VINCULO]: 3,
  [TABS_NAMES.INTERVENCIONES]: 4,
  [TABS_NAMES.ANTECEDENTES]: 5,
  [TABS_NAMES.JORNADA]: 6,
  [TABS_NAMES.EMPTY]: -1,
};
