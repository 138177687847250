import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { RolStatusSelect, RolSelect } from '../../../../../../app/business';
import { FiltersLogicReturn } from '../hooks';
import { useGeneralContext } from 'src/context';
import { SimpleSelect } from 'src/commons';

export type FilterProps = FiltersLogicReturn;

export const Filters = ({
  idRolStatus,
  idRolUsuario,
  onRolChange,
  onEstadoChange,
}: FilterProps) => {
  const filters = useMemo(
    () => ({
      id: [
        {
          path: 'idRolUsuario',
          method: 'notEquals',
          value: -1,
        },
      ],
      id2: [
        {
          path: 'idRolUsuario',
          method: 'notEquals',
          value: 6,
        },
      ],
      id4: [
        {
          path: 'idRolUsuario',
          method: 'notEquals',
          value: 8,
        },
      ],
      id5: [
        {
          path: 'idRolUsuario',
          method: 'notEquals',
          value: 9,
        },
      ],
      id6: [
        {
          path: 'idRolUsuario',
          method: 'notEquals',
          value: 17,
        },
      ],
    }),
    [],
  );

  const generalContext = useGeneralContext();
  const rolStatus = generalContext?.generalState?.constantes?.RolStatus;

  return (
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <RolSelect
          title="Rol"
          placeholder="Seleccioná el rol"
          value={idRolUsuario}
          handleChange={onRolChange}
          orderBy="descripcion"
          direction="asc"
          filter={filters}
        />
      </Grid>
      <Grid item xs={3}>
        <SimpleSelect
          title="Estado"
          placeholder="Seleccioná el estado"
          labelKey="descripcion"
          valueKey="idRolStatus"
          value={idRolStatus}
          handleChange={onEstadoChange}
          orderBy="descripcion"
          direction="asc"
          content={rolStatus}
        />
      </Grid>
    </Grid>
  );
};
