import React from 'react';
export const usePipInformeRows = () => {
  return [
    {
      preguntaNumero: '1',
      dimensiones:
        '¿Cómo fue el vínculo de la familia con la comunidad educativa? ¿Qué estrategias se implementaron desde la escuela para sostener la continuidad pedagógica y cómo respondió la familia a las mismas?',
      tooltipText:
        'Se visualiza la situación familiar del alumno/a y se tendrá en cuenta el acompañamiento y participación de la familia, así como los aspectos de salud del niño/a y su escolaridad previa (tiempo, continuidad y modalidad).',
      observaciones: '',
      archivoAdjunto: '',
    },
    {
      preguntaNumero: '2',
      dimensiones: '¿Cómo se vincula e interactúa con sus pares y docentes?',
      tooltipText:
        'Esta dimensión recorrerá la vida socio-afectiva del niño/a, su posibilidad de relacionarse con los otros (pares y docentes), su capacidad para conocer sus posibilidades, de expresar sus emociones, de confiar en sus ideas y escuchar la de los demás. Además ofrecerá una visión acerca de la posibilidad el niño/a de integrarse a proyectos cooperativos, generar y respetar acuerdos de trabajo comunes, como también su capacidad de trabajo en equipo y liderazgo.',
      observaciones: '',
      archivoAdjunto: '',
    },
    {
      preguntaNumero: '3',
      dimensiones:
        '¿Cómo participa e interactúa en las propuestas de juego, ya sean organizadas por el equipo docente o en el juego libre con sus pares?',
      tooltipText:
        'Esta dimensión identifica de qué manera el juego está presente en la vida del alumno/a, visibilizando sus modos de participación, creación y producción de propuestas lúdicas en formatos individuales y colectivos. Esto permitirá evaluar las actitudes en relación a la competencia, el desempeño en los juegos y la aceptación de reglas.',
      observaciones: '',
      archivoAdjunto: '',
    },
    {
      preguntaNumero: '4',
      dimensiones:
        '¿Cómo se vincula con los lenguajes expresivos y con la posibilidad de situarse frente a una producción artística tanto para disfrutarla como para producir distintas formas personales de expresión?',
      tooltipText:
        'Esta dimensión ofrece una proyección de la manera en que el niño se relaciona con la producción artística, desde su propia producción e interpretación, en lo literario, musical, corporal y lo plástico. Se visualizará su capacidad de sensibilización ante el hecho artístico, su posibilidad de expresar su imaginación y creatividad en sus producciones desde variadas formas. A tal efecto se deberá trabajar conjuntamente con el docente de Educación Física y Música.',
      observaciones: '',
      archivoAdjunto: '',
    },
    {
      preguntaNumero: '5',
      dimensiones:
        '¿Cómo se vincula con su propio cuerpo, con los objetos y los espacios? ¿Cómo ha desarrollado su autonomía?',
      tooltipText:
        'Esta dimensión ubica de qué manera el alumno/a se relaciona con su cuerpo, con el espacio, y el desarrollo de la autonomía y confianza en sí mismo, en su cuerpo y sus capacidades motoras para tareas y juegos. Ello también permitirá registrar lo referido al cuidado y seguridad personal y en relación a otros.',
      observaciones: '',
      archivoAdjunto: '',
    },
    {
      preguntaNumero: '6',
      dimensiones:
        '¿Cómo se expresa e interacciona a través de los diferentes lenguajes verbales y no verbales con los/las otros/as niños/as, con el conocimiento y con el mundo cultural?',
      tooltipText:
        'Esta dimensión recoge indicios de las posibilidades comunicativas del niño/a en las prácticas de oralidad, lectura y escritura en diversas situaciones, con múltiples y variados  soporte. A su vez, pretende reflejar la capacidad de escucha, de participación en contextos de diálogos e intercambios, de elaboración de preguntas y la posibilidad de manifestar emociones y necesidades.',
      observaciones: '',
      archivoAdjunto: '',
    },
    {
      preguntaNumero: '7',
      dimensiones:
        '¿Cómo se expresa de prácticas de oralidad, lectura y escritura en diversidad de situaciones, con múltiples soportes?',
      tooltipText: '',
      observaciones: '',
      archivoAdjunto: '',
    },
    {
      preguntaNumero: '8',
      dimensiones:
        '¿Qué estrategias o acciones pone en juego para resolver desafíos matemáticos?',
      tooltipText:
        'Esta dimensión abarca lo referido a la resolución de situaciones problemáticas, desde el uso de variadas estrategias confrontando respuestas y posibles soluciones. Describe la relación de los niños/as con los diferentes sentidos y usos de los números y la interpretación y producción de información espacial.',
      observaciones: '',
      archivoAdjunto: '',
    },
    {
      preguntaNumero: '9',
      dimensiones:
        '¿Cómo se relaciona con la variedad de situaciones y oportunidades que experimenta en interacción con el ambiente social y natural?',
      tooltipText:
        'Esta dimensión aborda la relación del niño/a con el medio, en un entramado social y natural. Resignifica de qué manera el niño se relaciona con los diferentes fenómenos del ambiente, sus materiales y objetos. Los observa, los explora, identifica sus cambios y propiedades. Asimismo, describe de qué manera se relaciona con los diferentes espacios sociales, los trabajos, la historia, los diferentes modos de vida y cómo se acerca a la cultura y a los rituales y festejos que constituyen la memoria colectiva.',
      observaciones: '',
      archivoAdjunto: '',
    },
    {
      preguntaNumero: '10',
      dimensiones: (
        <div>
          <p
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            Sección a completar con participación de la familia
          </p>
          <p
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            ¿Cómo transitó la familia la escolarización del niño y el pasaje del
            nivel inicial al nivel primario?
          </p>
          <p
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            ¿Qué expectativas tiene para 1er grado del nivel primario?
          </p>
          <p
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            ¿Qué aspectos le inquietan?
          </p>
        </div>
      ),
      tooltipText:
        'En la sección completada con la familia se tiene en cuenta la voz de las mismas. Ello consiste en un registro de expectativas sobre la escolaridad del niño/a, el pasaje del nivel Inicial al nivel Primario, ansiedades, dudas y miedos. Así entonces, en la reunión evaluativa de mitad de año llevada a cabo con las familias -determinada por la agenda educativa de cada ciclo lectivo- se entregará a las mismas una ficha para que sea completada y firmada.',
      observaciones: '',
      archivoAdjunto: '',
    },
    {
      preguntaNumero: '11',
      dimensiones:
        'Sección a contemplar a partir de las escrituras y dibujos del alumno/a.',
      tooltipText:
        'En esta sección se tendrán en cuenta las escrituras y dibujos que vayan completando durante el año por el alumno/a. Al respecto, el docente deberá escanear una de las últimas producciones (dibujos, números, palabras) que den muestra significativa de su evolución e incluir, también previo escaneo, algún registro anecdótico y/o entrevistas de participación específica, de existir, que ilumine las narraciones del docente.(espacio para adjuntar un archivo)',
      observaciones: '',
      archivoAdjunto: '',
    },
    {
      preguntaNumero: '12',
      dimensiones:
        '¿Tuvo intervenciones por parte del Equipo de Orientación Escolar o de equipos interdisciplinarios?',
      tooltipText: 'Sí/No',
      observaciones: '',
      archivoAdjunto: '',
    },
    {
      preguntaNumero: '13',
      dimensiones: 'Observaciones',
      tooltipText: 'Campo de texto abierto',
      observaciones: '',
      archivoAdjunto: '',
    },
  ];
};
