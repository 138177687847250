import { useCallback } from 'react';
import { getFilterPassSection } from '../../utils/getFilterPassSection';
import { getStatusPassFromPendientes } from '../../utils/getStatusPassFromPendientes';
import { MAPPED_PASE_ANIO_OPTIONS_SECUNDARIO } from '../../utils/mappedPaseAnioOptions';
import { PlanEstudioNivel } from 'src/app/models';
export const usePendientesLogic = ({
  setFormValues,
  setStudentsData,
  // setMateriasPendientes,
  studentsData,
  localizacionData,
  // materiasPendientes,
  options = [],
  planEstudioNivel,
}: {
  setFormValues: any;
  setStudentsData: any;
  // setMateriasPendientes: any;
  studentsData: any;
  localizacionData: any;
  // materiasPendientes: any;
  options: [];
  planEstudioNivel?: PlanEstudioNivel;
}) => {
  const handleChange = useCallback(
    (e, restStudentData) => {
      const {
        Egresa: EGRESA,
        'Promoción en proceso': PROMOCION_EN_PROCESO,
        'Con promoción acompañada': PROMOCION_CON_APOYO,
        'No completó el Plan de Estudio': NO_COMPLETO_PLAN_DE_ESTUDIOS,
      } = MAPPED_PASE_ANIO_OPTIONS_SECUNDARIO;
      const name = e.target.name;
      const value = e.target.value;

      if (value !== '') {
        const materiasPendientesToSave: {
          idAlumno: number;
          previas: number;
          pendientes: number;
          idPreviasProyecciones: number;
        }[] = [];
        let statusPass: number;
        const totalPendientes = Number(value);

        statusPass = getStatusPassFromPendientes({
          localizacionData: localizacionData,
          totalPendientes: totalPendientes,
          numeroAnio: restStudentData?.currentYear?.numeroAnio,
          descripcionTurno: restStudentData?.turno,
          secciones: restStudentData?.idSection,
          planEstudioNivel: planEstudioNivel,
        });

        const currentCicloLectivo = restStudentData?.cicloLectivo;
        const numeroAnio = restStudentData?.currentYear?.numeroAnio;
        const descripcionAnioSection = restStudentData.currentSection;
        const idAnio = restStudentData?.idAnio;
        const nivel = restStudentData?.currentYear?.nivel?.idNivel;
        const { filteredOptions }: any = getFilterPassSection(
          options,
          statusPass,
          currentCicloLectivo,
          numeroAnio,
          nivel,
          descripcionAnioSection,
          idAnio,
          restStudentData.turno,
        );
          const newStudentData = {
          ...restStudentData,
          statusPass,
          sectionPass: [PROMOCION_EN_PROCESO.id].includes(statusPass)
            ? '-'
            : [EGRESA.id, NO_COMPLETO_PLAN_DE_ESTUDIOS.id].includes(statusPass)
            ? '-'
            : filteredOptions[0]?.idSeccion ?? 'No Concurrirá',
          observacion: [
            NO_COMPLETO_PLAN_DE_ESTUDIOS.id,
            PROMOCION_CON_APOYO.id,
          ].includes(statusPass)
            ? restStudentData.observacion
            : null,
          materiasPendientes: {
            ...restStudentData.materiasPendientes,
            pendientes: totalPendientes,
          },
        };

        setStudentsData((prev: any) =>
          prev.map((student: any) => {
            if (student.idStudent === restStudentData.idStudent) {
              return newStudentData;
            }
            return student;
          }),
        );

        setFormValues((prev: any) =>
          prev.map((student: any) => {
            if (student.alumno === restStudentData.idStudent) {
              student = {
                ...student,
                estadoPase: newStudentData.statusPass,
                idSeccion: newStudentData.sectionPass,
                observacion: newStudentData.observacion,
                materiasPendientes: newStudentData.materiasPendientes,
              };
            }

            return student;
          }),
        );

        // studentsData.forEach((element: any) => {
        //   const materiaFind = materiasPendientes.find(
        //     (materia: any) => materia?.idAlumno === element.idStudent,
        //   );
        //   if (element.idStudent === restStudentData.idStudent) {
        //     materiasPendientesToSave.push({
        //       idPreviasProyecciones: materiaFind?.idPreviasProyecciones,
        //       idAlumno: element?.idStudent,
        //       previas:
        //         name === 'pendientesPrevias' ? value : materiaFind?.previas,
        //       pendientes:
        //         name === 'pendientesActuales' ? value : materiaFind?.pendientes,
        //     });
        //   } else {
        //     materiasPendientesToSave.push({
        //       idPreviasProyecciones: materiaFind?.idPreviasProyecciones,
        //       idAlumno: element?.idStudent,
        //       previas: materiaFind?.previas,
        //       pendientes: materiaFind?.pendientes,
        //     });
        //   }
        // });
        // setMateriasPendientes(materiasPendientesToSave);
      }
    },

    [
      localizacionData,
      options,
      setFormValues,
      setStudentsData,
      planEstudioNivel,
    ],
  );
  return handleChange;
};
