import { SituacionV3DAO } from 'src/platform/libby/dao/Presentismov3';
import { makeBusinessEntity } from '../../../lib/libby/generator';
import { SituacionV3 } from '../../models/presentismo_v3';

const DAO_NAME = 'situaciones';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { renderPropCall, renderPropFetchById, simpleSelect },
} = makeBusinessEntity<SituacionV3, SituacionV3DAO>(DAO_NAME, {});

// hooks
export const useSituacionV3FetchById = fetchById;
export const useSituacionV3LibbyCall = libbyCall;
export const useSituacionV3DAO = libbyDAO;
export const useSituacionV3LibbyFetch = libbyFetch;

// components/hoc
export const SituacionV3Call = renderPropCall;
export const SituacionV3ById = renderPropFetchById;
export const SituacionV3Select = simpleSelect;
