import { Chip, Grid, Typography, makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import {
  FormCheckbox,
  FormDatePicker,
  FormFileInput,
  useFormContext,
} from 'src/lib/templates';
import { CustomFormAutocomplete as FormAutocomplete } from '../../../components';
import { useStyles } from '../styles';

const motivosEximido = [
  {
    id: 1,
    value: 'Salud',
  },
  {
    id: 2,
    value: 'Alumna madre de familia',
  },
  {
    id: 3,
    value: 'Trabajo',
  },
  {
    id: 4,
    value: 'Estudio',
  },
  {
    id: 5,
    value: 'Escuela nocturna',
  },
];

export const AptoFisico: FunctionComponent<{ isHistoric: boolean }> = ({
  isHistoric,
}) => {
  const { form, values } = useFormContext();

  const classes = useStyles();

  const formatLabelText = (labelText: string) => {
    return labelText.includes('\\') ? labelText.split('\\')[2] : labelText;
  };

  return (
    <Grid
      container
      spacing={2}
      direction="column"
      className={classes.secondaryContainer}
    >
      <Grid item>
        <Typography className={classes.title} variant="h5">
          Apto físico (opcional)
        </Typography>
      </Grid>
      <Grid item>
        <Grid container spacing={4}>
          <Grid item lg={4} md={4} sm={6} xs={6}>
            <FormDatePicker
              disabled={isHistoric}
              placeholder="Seleccioná fecha"
              label="Desde"
              name="salud.datosAptoFisico.aptoDesde"
              form={form}
              className={classes.datePicker}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={6}>
            <FormDatePicker
              disabled={isHistoric}
              placeholder="Seleccioná fecha"
              label="Hasta"
              name="salud.datosAptoFisico.aptoHasta"
              form={form}
              className={classes.datePicker}
            />
          </Grid>
          <Grid item lg={4} md={4} sm={6} xs={6}>
            {values.salud?.datosAptoFisico.url ? (
              <div>
                <Typography className={classes.label}>Comprobante</Typography>
                <Chip
                  classes={{
                    root: classes.files,
                    label: classes.fileLabel,
                    deleteIcon: classes.fileDeleteIcon,
                  }}
                  label={formatLabelText(
                    values?.salud?.datosAptoFisico?.adjuntoNombre,
                  )}
                  onClick={() => {
                    window.open(values.salud?.datosAptoFisico.url, '_blank');
                  }}
                  onDelete={() =>
                    !isHistoric &&
                    form.change('salud.datosAptoFisico.url', undefined)
                  }
                />
              </div>
            ) : (
              <FormFileInput
                disabled={isHistoric}
                label="Adjuntar comprobante"
                name="salud.datosAptoFisico.file"
                form={form}
                placeholder="Subir archivo"
                validFileTypes={[
                  'application/pdf',
                  '.pdf',
                  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
                  'application/msword',
                  'image/jpeg',
                  '.jpg',
                  '.jpeg',
                  '.jpe',
                  'image/png',
                  'image/webp',
                  '.webp',
                ]}
                maxFileSize={'2MB'}
                custom
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={4}>
          <Grid item xs={4}>
            <FormCheckbox
              disabled={isHistoric}
              label="Está eximido"
              name="salud.datosAptoFisico.estaEximido"
              form={form}
            />
          </Grid>
          {!!values.salud.datosAptoFisico.estaEximido && (
            <>
              <Grid item xs={4}>
                <FormAutocomplete
                  disabled={isHistoric}
                  label="Motivo"
                  options={motivosEximido}
                  name="salud.datosAptoFisico.motivo"
                  placeholder="Seleccioná motivo"
                  optionLabelKey="value"
                  form={form}
                  optionToValue={(option: any) => option?.value}
                  valueToOption={(value: any, options: any[]) =>
                    options.find((option: any) => option?.value === value)
                  }
                  getOptionSelected={(option: any, value: any) =>
                    option?.value === value?.value
                  }
                />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
