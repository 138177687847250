import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  useRef,
} from 'react';
import { Grid, Button } from '@material-ui/core';
import { grey } from 'src/theme/colors';
import {
  EspacioCurricularSeccionSelect,
  PeriodoSelect,
  SeccionSelect,
  AnioSelect,
} from 'src/app/business';
import { MainInput, SimpleSelect } from 'src/commons';
import { useParams } from 'react-router';
import { useRolesContext } from 'src/context/RolesContext';
import { useEspaciocurricularseccioncustomGetRequest } from './../../../../../../app/business/businessCustomEndpoints/espaciocurricularseccioncustom';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
import { CURRENT_CICLO_LECTIVO } from 'src/screens/Private/Acap';

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  searchGrid: { marginTop: 10 },
  containerStyles: { marginTop: 23 },
  inputSearch: { padding: 10 },
};

export const FilterTableCalificaciones = ({
  filterPeriodo,
  filterEspacioCurricular,
  filterSeccion,
  handleChangeSearch,
  search,
  handleDescargar,
  filterAnio,
  handleSelectChange,
  setEspacioCurricularSeccion,
  // setEspacioSeccionSelect,
  setFilterEspacioCurricular,
  enableDescargar = true,
  anioCustomFilter = {},
  handleAnioChange,
  handlePeriodosChange,
}: any) => {
  const { idEspacioCurricular, periodo } = useParams<any>();
  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();
  const { cicloLectivo } = useColegioEfectivizadoContext();
  const [hasEspacioCurricular, setHasEspacioCurricular] = useState<any>();
  const [periodoDisabled, setPeriodoDisabled] = useState<boolean>(false);
  const onFilterChange = useCallback(
    async ({ target }) => {
      handleSelectChange(target);
    },

    [handleSelectChange],
  );

  const {
    selectedRole: { rol, localizacionId },
  } = useRolesContext();

  const isDocente = useMemo(() => rol === '2', [rol]);

  const shouldEspacioCurricularWithIdAutoCall: boolean = useMemo(
    (): boolean => localizacionId != null && idEspacioCurricular != null,
    [localizacionId, idEspacioCurricular],
  );

  const shouldEspacioCurricularWithSeccionIdAutoCall: boolean = useMemo(
    (): boolean => filterSeccion.seccion != null,
    [filterSeccion.seccion],
  );

  const { data: ecs = [] } = useEspaciocurricularseccioncustomGetRequest<any>({
    url: `/api/public/custom/espaciocurricularseccioncustom?localizacion=${localizacionId}&cicloLectivo=${cicloLectivo?.idCicloLectivo}&espacioCurricular=${idEspacioCurricular}`,
    aspect: 'limit_by_espacio',
    autoCall: shouldEspacioCurricularWithIdAutoCall,
  });

  const { data: ecc = [] } = useEspaciocurricularseccioncustomGetRequest<any>({
    url: `/api/public/custom/espaciocurricularseccioncustom?seccion=${
      filterSeccion.seccion ?? ''
    }&cicloLectivo=${cicloLectivo?.idCicloLectivo}`,
    aspect: 'limit_by_espacio',
    autoCall: shouldEspacioCurricularWithSeccionIdAutoCall,
  });

  useEffect(() => {
    if (typeof idEspacioCurricular === 'undefined') {
      setHasEspacioCurricular(false);
    } else {
      setHasEspacioCurricular(true);
    }
  }, [idEspacioCurricular]);

  const seccionFilter = useMemo(
    () => ({
      anio: [{ path: 'anio.idAnio', value: filterAnio.anio }],
    }),
    [filterAnio.anio],
  );

  useEffect(() => {
    const espacioSeccion = ecc?.find(
      (p: any) => p.seccion.idSeccion === filterSeccion.seccion,
    );
    if (espacioSeccion) {
      setFilterEspacioCurricular({
        espacioCurricularSeccion: espacioSeccion.idEspacioCurricularSeccion,
      });
    }
    if (ecc.length > 0) {
      setEspacioCurricularSeccion(ecc);
    }
  }, [
    ecc,
    filterSeccion.seccion,
    setEspacioCurricularSeccion,
    setFilterEspacioCurricular,
  ]);

  const { current: periodoFilter } = useRef({
    id: [{ path: 'idPeriodo', value: filterPeriodo.id_periodo }],
  });

  useEffect(() => {
    if (
      hasEspacioCurricular === true &&
      typeof filterSeccion.seccion !== 'undefined'
    ) {
      setPeriodoDisabled(false);
    } else if (
      hasEspacioCurricular === false &&
      filterAnio.anio &&
      filterSeccion.seccion
    ) {
      setPeriodoDisabled(false);
    }
  }, [
    filterAnio.anio,
    filterSeccion.name,
    filterSeccion.seccion,
    hasEspacioCurricular,
  ]);

  const isDisabled = useMemo(
    () =>
      !!filterSeccion.seccion && !!periodoFilter && hasEspacioCurricular
        ? !filterSeccion.seccion || !filterPeriodo
        : !filterEspacioCurricular.espacioCurricularSeccion ||
          !filterSeccion.seccion ||
          !filterAnio.anio ||
          !filterPeriodo,
    [
      filterSeccion.seccion,
      periodoFilter,
      hasEspacioCurricular,
      filterPeriodo,
      filterEspacioCurricular.espacioCurricularSeccion,
      filterAnio.anio,
    ],
  );
  return useMemo(
    () => (
      <>
        <Grid
          container
          justify="space-between"
          alignItems="flex-end"
          spacing={3}
          style={{ padding: '0 15px 15px', paddingRight: 15, marginTop: 23 }}
        >
          <Grid
            container
            xs={8}
            justify="flex-start"
            spacing={3}
            alignItems="flex-end"
            style={customStyles.searchGrid}
          >
            {!hasEspacioCurricular && (
              <Grid item xs={3}>
                <AnioSelect
                  title="Año"
                  placeholder="Año"
                  name="anio"
                  value={filterAnio.anio}
                  handleChange={onFilterChange}
                  onOptionsChange={handleAnioChange}
                  filter={anioCustomFilter}
                  enableFetch={!filterAnio}
                  limit={100}
                />
              </Grid>
            )}

            <Grid item xs={3}>
              {!hasEspacioCurricular && filterAnio.anio && (
                <SeccionSelect
                  title="Sección"
                  placeholder="Seleccioná Sección"
                  name="seccion"
                  handleChange={onFilterChange}
                  value={filterSeccion.seccion || ''}
                  labelKey="nombreSeccion"
                  valueKey="idSeccion"
                  filter={seccionFilter}
                  disabled={!filterAnio.anio}
                />
              )}
              {hasEspacioCurricular && isDocente ? (
                <SimpleSelect
                  title="Sección"
                  placeholder="Seleccioná Sección"
                  name="seccion"
                  handleChange={onFilterChange}
                  value={filterSeccion.seccion || ''}
                  labelKey="seccion.nombreSeccion"
                  valueKey="seccion.idSeccion"
                  filter={seccionFilter}
                  content={ecs.sort(
                    (
                      { espacioCurricular: { descripcion } },
                      { espacioCurricular: { descripcion: descripcionB } },
                    ) => {
                      return descripcion > descripcionB ? 1 : -1;
                    },
                  )}
                  orderBy="seccion.idSeccion"
                />
              ) : (
                hasEspacioCurricular && (
                  <EspacioCurricularSeccionSelect
                    title="Sección"
                    handleChange={onFilterChange}
                    value={filterSeccion.seccion || ''}
                    labelKey="seccion.nombreSeccion"
                    valueKey="seccion.idSeccion"
                    aspect="limit_by_seccion"
                    methodName="getByEspacioCurricularId"
                    params={idEspacioCurricular}
                    orderBy="seccion.idSeccion"
                  />
                )
              )}
            </Grid>
            {!periodoDisabled && (
              <Grid item xs={3}>
                <PeriodoSelect
                  name="periodo"
                  title="Período"
                  disabled={true}
                  placeholder="Período"
                  labelKey="tipoPeriodo.descripcionTipoPeriodo"
                  valueKey="idPeriodo"
                  handleChange={onFilterChange}
                  value={filterPeriodo.id_periodo}
                  params={periodoFilter}
                  orderBy="idPeriodo"
                  onOptionsChange={handlePeriodosChange}
                />
              </Grid>
            )}

            {!hasEspacioCurricular && filterSeccion.seccion && (
              <Grid item xs={3}>
                <SimpleSelect
                  title="Espacio Curricular"
                  placeholder="Selecciona espacio curricular"
                  name="espacioCurricularSeccion"
                  handleChange={onFilterChange}
                  value={filterEspacioCurricular.espacioCurricularSeccion || ''}
                  labelKey="espacioCurricular.descripcion"
                  valueKey="idEspacioCurricularSeccion"
                  content={
                    ecc
                      ? ecc.sort(
                          (
                            { espacioCurricular: { descripcion } },
                            {
                              espacioCurricular: { descripcion: descripcionB },
                            },
                          ) => {
                            return descripcion > descripcionB ? 1 : -1;
                          },
                        )
                      : []
                  }
                />
              </Grid>
            )}
          </Grid>

          <Grid
            container
            xs={4}
            justify="flex-end"
            spacing={3}
            alignItems="flex-end"
            style={customStyles.searchGrid}
          >
            <Grid item xs>
              <MainInput
                type="search"
                handleChange={handleChangeSearch}
                placeholder="Buscar"
                name="search"
                value={search}
                fullWidth
              />
            </Grid>

            <Grid item xs>
              <Button
                color="primary"
                variant="contained"
                component="label"
                fullWidth
                onClick={handleDescargar}
                disabled={
                  isDisabled ||
                  !enableDescargar ||
                  (!establecimientoEfectivizado &&
                    ![4, 5].includes(Number(periodo)))
                }
              >
                Descargar
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </>
    ),
    [
      periodo,
      establecimientoEfectivizado,
      hasEspacioCurricular,
      filterAnio,
      onFilterChange,
      handleAnioChange,
      anioCustomFilter,
      filterSeccion.seccion,
      seccionFilter,
      isDocente,
      ecs,
      idEspacioCurricular,
      periodoDisabled,
      filterPeriodo.id_periodo,
      periodoFilter,
      handlePeriodosChange,
      filterEspacioCurricular.espacioCurricularSeccion,
      ecc,
      handleChangeSearch,
      search,
      handleDescargar,
      isDisabled,
      enableDescargar,
    ],
  );
};
