import React, { useState } from 'react';
import { ButtonTypesProps, Footer } from 'src/commons';
import { CicloLectivo } from 'src/app/models';
import { Grid } from '@material-ui/core';
import { customStyle, initialvalue } from './const';
import { SearchFilter } from './types';
import { AddStudenFilter } from './AddStudenFilter';
import customFormDialog from 'src/commons/services/customFormDialog';
import { AddStudentBody } from './AddStudentBody';

interface AddStudentModalProps {
  cicloLectivo: CicloLectivo;
  localizacionId: string;
  isCommonSchool?: boolean;
  nivelId: string;
  toLegajoAlumno: (id: string | number) => void;
}
export const AddStudentModal = (props: AddStudentModalProps) => {
  const [filter, setFilter] = useState<SearchFilter>(initialvalue);

  const buttonConfig: ButtonTypesProps[] = [
    {
      title: 'Cancelar',
      handleOnClick: () => customFormDialog.handleCancel(),
      type: 'secondary',
      size: 'small',
    },
  ];

  return (
    <Grid container>
      <Grid item container style={customStyle.container}>
        <AddStudenFilter filter={filter} setFilter={setFilter} />
      </Grid>

      <Grid item container style={customStyle.container}>
        <AddStudentBody filter={filter} {...props} />
      </Grid>

      <Grid item container style={customStyle.footer}>
        <Footer buttonConfig={buttonConfig} spacing={1} />
      </Grid>
    </Grid>
  );
};
