import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { LegajoAnioLectivo } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'legajo_anio_lectivo';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idLegajoAnioLectivo',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idLegajoAnioLectivo',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.LegajoAnioLectivo
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.LegajoAnioLectivo,
      hoc: { ...defaultOptions.hoc, ...businessOptions.LegajoAnioLectivo.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<LegajoAnioLectivo>(DAO_NAME, optionsToUse);

// hooks
export const useLegajoAnioLectivoFetchById = fetchById;
export const useLegajoAnioLectivoLibbyCall = libbyCall;
export const useLegajoAnioLectivoDAO = libbyDAO;
export const useLegajoAnioLectivoLibbyFetch = libbyFetch;

// components/hoc
export const LegajoAnioLectivoAutocomplete = entityAutocomplete;
export const LegajoAnioLectivoCall = renderPropCall;
export const LegajoAnioLectivoById = renderPropFetchById;
export const LegajoAnioLectivoSelect = simpleSelect;
export const LegajoAnioLectivoAccordionSelector = accordionSelector;
export const LegajoAnioLectivoSelectedChips = selectedChips;

// context
// ByIdContext
export const useLegajoAnioLectivoByIdContext = useByIdContext;
export const LegajoAnioLectivoByIdProvider = ByIdProvider;
export const LegajoAnioLectivoByIdContext = ByIdContext;
// GeneralContext
export const useLegajoAnioLectivoContext = useGeneralContext;
export const LegajoAnioLectivoProvider = GeneralProvider;
export const LegajoAnioLectivoContext = GeneralContext;
