import React, { useRef } from 'react';
import { Grid } from '@material-ui/core';
import { useRolesContext } from 'src/context/RolesContext';
import {
  SeccionProvider,
  EspacioCurricularByIdProvider,
} from 'src/app/business';
import { EspaciosDiscipOrientPlan } from 'src/app/models';
import { FeedbackTemplate } from 'src/commons';
import { useInfoCards } from './hooks';
import { DisciplinarOrientadaProvider } from '../../forms/PlanificacionDisciplinarOrientada/Context';
import { DisciplinarOrientadaFlowHeader } from './components';
import { InfoCard } from '../../feedbacks/commons';

export interface DisciplinarOrientadaPreviewFlowProps {
  data: EspaciosDiscipOrientPlan;
}

export type FeedbackTemplateCompleteValue = {
  feedback: FeedbackTemplate[];
};

export const DisciplinarOrientadaPreviewFlow = ({
  data,
}: DisciplinarOrientadaPreviewFlowProps) => {
  const cards = useInfoCards(data);

  const { selectedRole } = useRolesContext();

  const seccionFilter = useRef({
    filter: [{ path: 'localizacion', value: selectedRole.localizacionId }],
  });
  // @ts-ignore
  const header = <DisciplinarOrientadaFlowHeader data={data} toPrint />;
  return (
    <SeccionProvider filter={seccionFilter.current}>
      <>
        {header}
        <Grid container>
          <Grid item sm={12}>
            <EspacioCurricularByIdProvider
              id={data.espacioCurricular.idEspacioCurricular}
            >
              <DisciplinarOrientadaProvider>
                <InfoCard title="Planificación" cards={cards} />
              </DisciplinarOrientadaProvider>
            </EspacioCurricularByIdProvider>
          </Grid>
        </Grid>
      </>
    </SeccionProvider>
  );
};
