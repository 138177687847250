import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { Justificacion } from '../../models';
import { businessOptions } from '../businessOptions';
import { JustificacionDAO } from '../../../platform/libby/dao/Presentismov3/JustificacionDAO';

const DAO_NAME = 'justificacion';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idJustificacion',
      labelKey: 'description',
    },
    withSelectedChips: {
      idKey: 'idJustificacion',
      labelKey: 'description',
    },
    withSimpleSelect: {
      labelKey: 'description',
      valueKey: 'idJustificacion',
      title: '',
      placeholder: 'Seleccioná Justificación',
      name: 'id',
    },
  },
};

const optionsToUse = !businessOptions.JustificacionMotivo
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.JustificacionMotivo,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.JustificacionMotivo.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
  },
} = makeBusinessEntity<Justificacion, JustificacionDAO>(DAO_NAME, optionsToUse);

// hooks
export const useJustificacionFetchById = fetchById;
export const useJustificacionLibbyCall = libbyCall;
export const useJustificacionDAO = libbyDAO;
export const useJustificacionLibbyFetch = libbyFetch;

// components/hoc
export const JustificacionAutocomplete = entityAutocomplete;
export const JustificacionCall = renderPropCall;
export const JustificacionById = renderPropFetchById;
export const JustificacionSelect = simpleSelect;
