import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Grid } from '@material-ui/core';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { useRolesContext } from 'src/context/RolesContext';
import { AnyObject, LibbyObject, level, MainInput } from 'src/commons';
import {
  Footer,
  HeaderTable,
  InfoTable,
  Loading,
} from 'src/commons/components';
import { useDebounce, useTableSortConfig } from 'src/commons/hooks';
import {
  useAlumnoMovimientoLibbyFetch,
  useCalificacionesRecuperacionLibbyFetch,
  useCalificacionesSecundarioAnualLibbyFetch,
  useEspacioCurricularSeccionLibbyFetch,
  useLocalizacionLibbyFetch,
  usePreviasProyeccionesCustomPostRequest,
  useProyeccionEstadoLibbyFetch,
  useProyeccionLibbyFetch,
  useSeccionLibbyFetch,
} from 'src/app/business';
import { usePromocionAlumnosColumns } from '../../hooks/usePromocionAlumnosColumns';
import { useSnackbar } from 'notistack';
import { useProyeccionBulkCustomPostRequest } from 'src/app/business/businessCustomEndpoints/proyeccionbulkcustom';
import { usePromocionAlumnosColumnsSecundaria } from '../../hooks';
import { buildRows } from '../../utils/buildRows';
import { usePreviasProyeccionesLibbyFetch } from 'src/app/business/PreviasProyecciones';
import _, { groupBy } from 'lodash';
import { PreviasProyecciones, Proyeccion, Seccion } from 'src/app/models';
import { setSearchParamsMultipleWords } from 'src/utils/setSearchParamsMultipleWords';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';

interface PromotionScreenProp {
  libby: LibbyObject;
  nameSection: string;
  idCurrentSection: string;
  setSelectedSecction: React.Dispatch<
    React.SetStateAction<{
      nameSection: string;
      idCurrentSection: string;
      idAnio: string;
    }>
  >;
  reFetchPromotion: () => void;
  setTabValue: any;
  onPromotionDetail: any;
  descripcionAnioSection: any;
  seccionFiltered: {
    currentYearSections: Seccion[];
    nextYearCurrentSections: Seccion[];
    nextYearSections: Seccion[];
  };
  idAnio: any;
}

const PromotionScreenRaw: FunctionComponent<PromotionScreenProp> = ({
  libby,
  nameSection,
  idCurrentSection,
  setSelectedSecction,
  reFetchPromotion,
  setTabValue,
  onPromotionDetail,
  descripcionAnioSection,
  seccionFiltered,
  idAnio,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const {
    selectedRole: { localizacionId, nivel, rol },
  } = useRolesContext();
  const { orderBy, onSortChange, direction } = useTableSortConfig(
    'alumno.persona.apellido',
  );
  // Todo: remove any and AnyObject, move logic to custom hooks
  const [studentsData, setStudentsData] = useState([]);
  const [studentsDatabackup, setStudentsDatabackup] = useState([]);
  const [formValues, setFormValues] = useState([]);
  const [idsMateriasSeccion, setIdsMateriasSeccion] = useState<string[]>([]);
  const [formValuesBackup, setFormValuesBackup] = useState([]);
  const [allCompleted, setAllCompleted] = useState(false);
  const [validationObs, setValidationObs] = useState(false);
  const [checkedAll, setCheckedAll] = useState(false);
  const [checkStudent, setCheckStudent] = useState<string[]>([]);
  const [restoreFlag, setRestoreFlag] = useState(false);
  const [search, setSearch] = useState('');
  const searchDebounce = useDebounce(search, 1000);
  const [isSaving, setIsSaving] = useState(false);
  // const [materiasPendientes, setMateriasPendientes] = useState<any>([]);
  const [noConcurrirayNotieneMotivo, setNoConcurrirayNotieneMotivo] =
    useState(false);
  const [notieneSeccion, setNotieneSeccion] = useState(false);

  const seccionesFilter = useMemo(
    () => ({
      anio: [{ path: 'anio', value: [24, 25, 26], method: 'in' }],
    }),
    [],
  );

  const { data: secciones = [] } = useSeccionLibbyFetch({
    filter: seccionesFilter,
    limit: 50,
  });
  let isNocturna = secciones.some((e) => e?.turno?.idTurno === 3);

  let isTecnica = secciones.filter((e) => e.anio.idAnio === 26).length > 0;

  const { request: proyeccionRequest } = useProyeccionBulkCustomPostRequest();
  const { request: previasRequest } = usePreviasProyeccionesCustomPostRequest();

  const filterAlumnoMovimiento = useMemo(
    () => ({
      1: [{ path: 'seccion.idSeccion', value: idCurrentSection }],
      ...setSearchParamsMultipleWords(
        [
          'alumno.persona.nombre',
          'alumno.persona.apellido',
          'alumno.persona.documento',
        ],
        searchDebounce,
      ),
    }),
    [idCurrentSection, searchDebounce],
  );
  const paramsFetch = useMemo(
    () => ({
      orderBy,
      direction,
      filter: filterAlumnoMovimiento,
      // aspect: 'promotion',
      limit: 100,
    }),
    [direction, filterAlumnoMovimiento, orderBy],
  );
  const { data: studentMovements = [], working: wStudentMovement } =
    useAlumnoMovimientoLibbyFetch(paramsFetch);
  const proyeccionEstadoFilter = useMemo(
    () => ({
      0: [{ path: 'localizacion', value: localizacionId }],
    }),
    [localizacionId],
  );

  const { data: proyeccionEstado, working: wProyeccionEstado }: any =
    useProyeccionEstadoLibbyFetch({
      filter: proyeccionEstadoFilter,
      orderBy: 'cicloLectivo.anio',
      direction: 'desc',
    });
  const { cicloLectivo: cicloLectivoContext } = useColegioEfectivizadoContext();

  const CURRENT_YEAR = Number(cicloLectivoContext?.anio ?? '');

  const sections = useMemo(() => {
    return [
      ...seccionFiltered.nextYearSections,
      ...seccionFiltered.currentYearSections,
      ...seccionFiltered.nextYearCurrentSections,
    ];
  }, [
    seccionFiltered.currentYearSections,
    seccionFiltered.nextYearSections,
    seccionFiltered.nextYearCurrentSections,
  ]);

  const localizacionFilter = useMemo(
    () => ({
      0: [{ path: 'idLocalizacion', value: localizacionId }],
    }),
    [localizacionId],
  );

  const { data: localizacionData = [], working: wLocalizacionData }: any =
    useLocalizacionLibbyFetch({
      limit: 1,
      filter: localizacionFilter,
    });

  const filterSaecciones = useMemo(
    () => ({
      0: [
        { path: 'cicloLectivo.anio', value: CURRENT_YEAR },
        {
          path: 'cicloLectivo.anio',
          value: Number(CURRENT_YEAR) + 1,
        },
      ],
      1: [{ path: 'localizacion', value: localizacionId }],
    }),
    [CURRENT_YEAR, localizacionId],
  );

  const { data: seccionesFromFetch = [], working: wSeccionesFromFetch } =
    useSeccionLibbyFetch({
      limit: 5000,
      filter: filterSaecciones,
      orderBy: 'nombreSeccion',
    });

  const filterEspacioCurricularSeccion = useMemo(
    () => ({
      0: [
        {
          path: 'seccion.localizacion',
          value: localizacionId,
        },
      ],
      1: [
        {
          path: 'espacioCurricular.materia.excepcion',
          value: 'true',
          method: 'notEquals',
        },
      ],
      2: [
        {
          path: 'seccion.cicloLectivo',
          value: cicloLectivoContext?.idCicloLectivo,
        },
      ],
    }),
    [localizacionId, cicloLectivoContext?.idCicloLectivo],
  );

  const {
    data: espacioCurricularSeccion = [],
    working: workingEspacioCurricularSeccion,
  } = useEspacioCurricularSeccionLibbyFetch({
    limit: 10000,
    filter: filterEspacioCurricularSeccion,
    enabled: Number(nivel) === 3,
  });

  const MateriaSeccionMemo = useMemo(() => {
    const MateriaPorSeccion = espacioCurricularSeccion?.filter(
      (espacioCurricular) =>
        espacioCurricular.seccion.idSeccion ===
        studentMovements[0]?.seccion?.idSeccion,
    );
    const idsMateriaPorSeccion = MateriaPorSeccion.map(
      (materia) => materia.idEspacioCurricularSeccion,
    );

    setIdsMateriasSeccion(idsMateriaPorSeccion);
  }, [espacioCurricularSeccion, studentMovements]);

  const tMovements = useMemo(
    () =>
      studentMovements.map((data: any) => ({
        ...data,
        ...data.alumno,
        ...data.cicloLectivo,
        // idEstadoAlumno y descripcionPase posiblemente ya no se necesiten
        idEstadoAlumno: data.estadoAlumno?.idEstadoPase,
        descripcionPase: data.estadoAlumno?.descripcionPase,
        ...data.seccion,
      })),
    [studentMovements],
  );

  const yearPassPromotionFilter = useMemo(
    () => ({
      0: [{ path: 'seccionOrigen.cicloLectivo.anio', value: CURRENT_YEAR }],
      1: [
        {
          path: 'alumno',
          value: studentMovements.map(
            (studentMovement) => studentMovement.alumno.idAlumno,
          ),
          method: 'in',
        },
      ],
    }),
    [CURRENT_YEAR, studentMovements],
  );

  const { data: yearPassPromotions = null, working: wYearPassPromotions } =
    useProyeccionLibbyFetch({
      limit: 2500,
      aspect: 'default',
      filter: yearPassPromotionFilter,
      enabled: studentMovements.length > 0,
    });

  const previasProyeccionesFilters = useMemo(() => {
    const filters = tMovements.map((element: any) => {
      return { path: 'alumno', value: element.idAlumno };
    });
    return filters;
  }, [tMovements]);

  const previasProyeccionesFilter = useMemo(
    () => ({
      alumnos: previasProyeccionesFilters,
      cicloLectivo: [
        { path: 'cicloLectivo', value: cicloLectivoContext?.idCicloLectivo },
      ],
    }),
    [previasProyeccionesFilters, cicloLectivoContext?.idCicloLectivo],
  );

  const {
    data: previasProyeccionesData = [],
    working: wPreviasProyeccionesData,
  } = usePreviasProyeccionesLibbyFetch({
    limit: 500,
    filter: previasProyeccionesFilter,
    enabled:
      !!previasProyeccionesFilters.length && Number(nivel) === level.SECUNDARIO,
  });

  const filterCalificaciones = useMemo(
    () => ({
      0: [
        {
          path: 'espacioCurricularSeccion.seccion',
          value: idCurrentSection,
        },
      ],
      1: [
        {
          path: 'espacioCurricularSeccion.seccion.cicloLectivo',
          value: cicloLectivoContext?.idCicloLectivo,
        },
      ],
      2: [
        {
          path: 'nota',
          value: 'Adeuda',
          method: 'notEquals',
        },
      ],
    }),
    [idCurrentSection, cicloLectivoContext?.idCicloLectivo],
  );
  const { data: calificacionesAnual = [], working: wCalificacionAnual } =
    useCalificacionesSecundarioAnualLibbyFetch({
      limit: 10000,
      aspect: 'definitiva',
      filter: filterCalificaciones,
      enabled: Number(nivel) === level.SECUNDARIO,
    });

  const filterParams = useMemo(
    () => ({
      0: [
        {
          path: 'periodo.idPeriodo',
          value: [14, 15],
          method: 'in',
        },
      ],
      1: [
        {
          path: 'espacioCurricularSeccion.seccion',
          value: idCurrentSection,
        },
      ],
    }),
    [idCurrentSection],
  );

  const {
    data: calificacionRecuperacion = [],
    working: wCalificacionRecuperacion,
  } = useCalificacionesRecuperacionLibbyFetch({
    filter: filterParams,
    limit: 100000,
    aspect: 'recuperacionesPromocion',
    checkDuplication: false,
    enabled:
      Number(nivel) === level.SECUNDARIO &&
      idsMateriasSeccion.length > 0 &&
      espacioCurricularSeccion.length > 0 &&
      !workingEspacioCurricularSeccion &&
      !wCalificacionAnual,
  });

  const recuperacionByAlumno = groupBy(calificacionRecuperacion, 'idAlumno');

  const onSearchChange = (e: any) => {
    setSearch(e.target.value);
  };
  // useEffect(() => {
  //   if (previasProyeccionesData.length > 0) {
  //     const previasProyeccionesOnlyIds = previasProyeccionesData.map(
  //       (previa: any) => {
  //         return {
  //           idPreviasProyecciones: previa?.idPreviasProyecciones,
  //           idAlumno: previa?.alumno?.idAlumno,
  //           previas: previa?.previas,
  //           pendientes: previa?.pendientes,
  //         };
  //       },
  //     );
  //     setMateriasPendientes(previasProyeccionesOnlyIds);
  //   }
  // }, [previasProyeccionesData]);

  const nextSection = useMemo(() => {
    const currentSection = sections.filter((element: any) => {
      return element.idSeccion === idCurrentSection;
    });

    let nextSection: any = [];
    nextSection = sections.filter(
      (element: any) =>
        (element?.cicloLectivo?.anio ===
          currentSection[0]?.cicloLectivo?.anio + 1 &&
          element?.anio?.numeroAnio ===
            currentSection[0]?.anio?.numeroAnio + 1) ||
        (currentSection[0]?.anio?.idAnio === 19 &&
          element?.cicloLectivo?.anio ===
            currentSection[0]?.cicloLectivo?.anio + 1 &&
          (element?.anio?.idAnio === currentSection[0]?.anio?.idAnio ||
            element?.anio?.idAnio === 15)),
    );

    nextSection.sort((a: any, b: any) => a.anio.idAnio - b.anio.idAnio);
    return nextSection[0];
  }, [idCurrentSection, sections]);
  const currentSection: any = useMemo(() => {
    const section = sections.filter(
      (element: any) => element.idSeccion === idCurrentSection,
    );
    if (section.length !== 0) {
      return section[0].anio.idAnio === 15 ? 3 : 1;
    } else {
      return '';
    }
  }, [idCurrentSection, sections]);
  useEffect(() => {
    if (
      !wStudentMovement &&
      !wLocalizacionData &&
      !wPreviasProyeccionesData &&
      !wProyeccionEstado &&
      !wSeccionesFromFetch &&
      !wYearPassPromotions &&
      yearPassPromotions !== null
    ) {
      const flattenYearPassPromotions = yearPassPromotions?.map(
        (data: any) => ({
          ...data,
          ...data.alumno,
          ...data.estadoPase,
          ...{ sectionPass: data.idSeccion },
        }),
      );

      const students = buildRows({
        flattenStudentMovements: tMovements,
        flattenYearPassPromotions,
        currentSection,
        nextSection,
        // materiasPendientes,
        nivel,
        seccionesFromFetch,
        previasProyeccionesData,
        isTecnica,
        isNocturna,
        localizacionId,
      });

      const initialFormValues = students.map((student: any) => {
        return {
          alumno: student.idStudent,
          currentSection: student.currentSection,
          estadoPase: student.statusPass,
          idCicloLectivo: student.idCicloLectivo,
          idProyeccion: student.idProyeccion,
          idSeccion: student.sectionPass,
          originSeccion: idCurrentSection,
          mesesConcurridos: student.mesesConcurridos,
          estadoProyeccionMotivo: student.estadoProyeccionMotivo,
          observacion: student.observacion,
          materiasPendientes: student.materiasPendientes,
        };
      });

      const bkpStudents = _.cloneDeep(students);
      const bkpFormValues = _.cloneDeep(initialFormValues);
      setStudentsData(students);
      setFormValues(initialFormValues);
      setStudentsDatabackup(bkpStudents);
      setFormValuesBackup(bkpFormValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    localizacionData,
    previasProyeccionesData,
    // materiasPendientes,
    proyeccionEstado,
    seccionesFromFetch,
    studentMovements,
    yearPassPromotions,
    tMovements,
    wStudentMovement,
    wLocalizacionData,
    wPreviasProyeccionesData,
    wProyeccionEstado,
    wSeccionesFromFetch,
    wYearPassPromotions,
  ]);

  useEffect(() => {
    if (formValues.length > 0) {
      let isAllCompleted: any = [];
      formValues.forEach((element: any) => {
        let ready = false;
        const egresaOCompletaEstudios =
          element?.estadoPase === 3 || element?.estadoPase === 6;

        const noCompletaEstudios = element?.estadoPase === 9;

        const seccionYEstadosValidos =
          element?.idSeccion !== undefined &&
          element?.idSeccion !== '' &&
          element?.estadoPase !== '';

        const permaneceOPromocionaConApoyo =
          element?.estadoPase === 2 || element?.estadoPase === 8;

        const tieneObservaciones =
          element?.observacion !== null &&
          element?.observacion !== '' &&
          element?.observacion !== undefined;

        const noConcurrira = element?.idSeccion === 'No Concurrirá';

        const tieneMotivo = !['', undefined, null].some(
          (value) => value === element?.estadoProyeccionMotivo,
        );

        if (Number(nivel) === level.INICIAL) {
          if (egresaOCompletaEstudios) {
            ready = true;
          } else if (seccionYEstadosValidos) {
            if (noConcurrira && tieneMotivo) {
              ready = true;
            } else if (!noConcurrira) {
              ready = true;
            }
          }
        } else if (Number(nivel) === level.PRIMARIO) {
          if (egresaOCompletaEstudios) {
            ready = true;
          } else if (seccionYEstadosValidos) {
            if (noConcurrira && tieneMotivo) {
              ready = true;
            } else if (!noConcurrira) {
              ready = true;
            }
          }
        } else if (Number(nivel) === level.SECUNDARIO) {
          if (egresaOCompletaEstudios || noCompletaEstudios) {
            ready = true;
          } else if (seccionYEstadosValidos) {
            if (noConcurrira && tieneMotivo) {
              if (permaneceOPromocionaConApoyo && tieneObservaciones) {
                ready = true;
              } else if (!permaneceOPromocionaConApoyo) {
                ready = true;
              }
            } else if (!noConcurrira) {
              if (permaneceOPromocionaConApoyo && tieneObservaciones) {
                ready = true;
              } else if (!permaneceOPromocionaConApoyo) {
                ready = true;
              }
            }
          }
        }

        if (ready) isAllCompleted.push(element);
      });

      setAllCompleted(isAllCompleted.length === formValues.length);
    }

    const noConcurrirayNotieneMotivoCheck = formValues.filter(
      (obj) =>
        obj.idSeccion === 'No Concurrirá' &&
        (obj.estadoProyeccionMotivo === '' ||
          obj.estadoProyeccionMotivo === null ||
          obj.estadoProyeccionMotivo === undefined) &&
        obj.estadoPase !== 3 &&
        obj.estadoPase !== 9,
    );
    if (noConcurrirayNotieneMotivoCheck.length > 0) {
      setNoConcurrirayNotieneMotivo(true);
    } else {
      setNoConcurrirayNotieneMotivo(false);
    }

    const notieneSeccionCheck = formValues.filter((obj) => {
      return (
        (obj.estadoPase !== 3 &&
          obj.estadoPase !== 9 &&
          obj.idSeccion === '') ||
        !obj.estadoPase
      );
    });
    if (notieneSeccionCheck.length > 0) {
      setNotieneSeccion(true);
    } else {
      setNotieneSeccion(false);
    }

    /*  TODO REFACTION CODIGO DE ABAJO PARA CHECKEAR POR ALUMNO
    Importante
    Se deshabilito para poder finalizar proyeccion DEI  ticket MIESC-3986 fecha 17/08 

    const egresaOCompletaEstudios = formValues.filter(
      (obj) => obj.estadoPase === 3,
    );
    if (egresaOCompletaEstudios) {
      setNoConcurrirayNotieneMotivo(false);
    }*/

    if (Number(nivel) === level.SECUNDARIO) {
      const hasUndefinedObservations = formValues.some(
        (obj) => [2, 8].includes(obj.estadoPase) && !Boolean(obj.observacion),
      );
      setValidationObs(hasUndefinedObservations);
    }
  }, [formValues, nivel]);

  const errorDeSeccionOriginVacio: any = () => {
    throw new Error('No se encontró la sección de origen');
  };

  const onSave = useCallback(async () => {
    try {
      setIsSaving(true);
      const proyecciones: Proyeccion[] = [];
      const materiasPendientesToSave: PreviasProyecciones[] = [];
      formValues.forEach((studentMovement, index) => {
        const {
          idProyeccion,
          idSeccion,
          estadoPase,
          idCicloLectivo,
          originSeccion,
          mesesConcurridos,
          estadoProyeccionMotivo,
          observacion,
          materiasPendientes,
          ...rest
        } = studentMovement;
        if (idProyeccion && idSeccion !== 'Articulado') {
          proyecciones.push({
            idProyeccion,
            seccionOrigen: originSeccion || errorDeSeccionOriginVacio(),
            estadoPaseAnio: estadoPase || null,
            cicloLectivo: idCicloLectivo,
            articula: idSeccion === 'Articula' ? true : false,
            seccionDestino:
              idSeccion === 'Articula' ||
              idSeccion === 'No Concurrirá' ||
              idSeccion === '-' ||
              idSeccion === ''
                ? null
                : idSeccion,
            mesesConcurridos: mesesConcurridos,
            estadoProyeccionMotivo:
              idSeccion === 'No Concurrirá' ? estadoProyeccionMotivo : null,
            observacion: observacion,
            ...rest,
          });
          if (level.SECUNDARIO === Number(nivel)) {
            const materiasPendientesFull: PreviasProyecciones = {
              idPreviasProyecciones: materiasPendientes.idPreviasProyecciones,
              previas: materiasPendientes.previas,
              pendientes: materiasPendientes.pendientes,
              alumno: materiasPendientes.alumno,
              cicloLectivo: cicloLectivoContext ? cicloLectivoContext : null,
            };
            materiasPendientesToSave.push(materiasPendientesFull);
          }
        } else if (idSeccion !== 'Articulado') {
          proyecciones.push({
            seccionOrigen: originSeccion || errorDeSeccionOriginVacio(),
            seccionDestino:
              idSeccion === 'Articula' ||
              estadoPase === 3 ||
              idSeccion === 'No Concurrirá' ||
              idSeccion === '-' ||
              idSeccion === ''
                ? null
                : idSeccion,
            estadoPaseAnio: estadoPase || null,
            articula: idSeccion === 'Articula' ? true : false,
            cicloLectivo: idCicloLectivo,
            mesesConcurridos: mesesConcurridos,
            estadoProyeccionMotivo:
              idSeccion === 'No Concurrirá' ? estadoProyeccionMotivo : null,
            observacion: observacion,
            ...rest,
          });
          if (level.SECUNDARIO === Number(nivel)) {
            materiasPendientesToSave.push(materiasPendientes);
          }
        }
      });
      const value = await proyeccionRequest({ data: proyecciones });
      if (level.SECUNDARIO === Number(nivel)) {
        await previasRequest({ data: materiasPendientesToSave });
      }
      onPromotionDetail(value);
      setFormValues([]);
      setSelectedSecction({
        nameSection: '',
        idCurrentSection: '',
        idAnio: '',
      });
      enqueueSnackbar('Se guardó con éxito', {
        variant: 'success',
      });
    } catch (e) {
      setIsSaving(false);
      console.error(e);
      enqueueSnackbar(`Ha ocurrido un error: ${e}`, {
        variant: 'error',
      });
    }
  }, [
    cicloLectivoContext,
    formValues,
    proyeccionRequest,
    nivel,
    onPromotionDetail,
    setSelectedSecction,
    enqueueSnackbar,
    previasRequest,
  ]);

  const onCancel = () => {
    setStudentsData(studentsDatabackup);
    setFormValues(formValuesBackup);
    setCheckStudent([]);
    setCheckedAll(false);
    setRestoreFlag(!restoreFlag);

    // const newMateriasPendientes = materiasPendientes.map((element: any) => {
    //   return {
    //     idAlumno: element?.idAlumno,
    //     previas: 0,
    //     pendientes: 0,
    //   };
    // });

    // setMateriasPendientes(newMateriasPendientes);
  };

  let existeModificacion = _.isEqual(formValues, formValuesBackup);
  let longitudIguales = allCompleted;
  const footerButtons = [
    {
      title: [level.PRIMARIO, level.SECUNDARIO].includes(Number(nivel))
        ? 'Reestablecer valores'
        : 'Cancelar',
      type: 'secondary',
      handleOnClick: () => {
        onCancel();
      },
      disabled: _.isEqual(formValues, formValuesBackup),
    },
    {
      title: 'Guardar',
      type: 'primary',
      handleOnClick: () => onSave(),
      disabled:
        noConcurrirayNotieneMotivo ||
        isSaving ||
        validationObs ||
        notieneSeccion ||
        studentsData.length === 0,
    },
  ];

  const renderSearch = () => (
    <Grid>
      <MainInput
        type="search"
        handleChange={onSearchChange}
        placeholder="Buscar"
        name="search"
        value={search}
        fullWidth
      />
    </Grid>
  );
  const columns = usePromocionAlumnosColumns(
    setCheckedAll,
    setCheckStudent,
    checkedAll,
    studentsData,
    libby,
    setFormValues,
    setStudentsData,
    idCurrentSection,
    checkStudent,
    restoreFlag,
    setRestoreFlag,
    proyeccionEstado,
    descripcionAnioSection,
    nivel,
    localizacionData,
    idAnio,
    seccionesFromFetch,
    rol,
  );

  const columnSecundaria = usePromocionAlumnosColumnsSecundaria(
    setCheckedAll,
    setCheckStudent,
    checkedAll,
    studentsData,
    libby,
    setFormValues,
    setStudentsData,
    idCurrentSection,
    checkStudent,
    restoreFlag,
    setRestoreFlag,
    proyeccionEstado,
    descripcionAnioSection,
    studentMovements,
    wStudentMovement,
    localizacionData,
    nivel,
    seccionesFromFetch,
    calificacionesAnual,
    calificacionRecuperacion,
    tMovements[0]?.planEstudioNivel,
  );

  const columnValidation =
    Number(nivel) === 1 || Number(nivel) === 2 ? columns : columnSecundaria;
  return (
    <>
      <Grid container justify="center" alignItems="center">
        <HeaderTable
          title={`Sección: ${nameSection}`}
          onBack={() =>
            setSelectedSecction({
              nameSection: '',
              idCurrentSection: '',
              idAnio,
            })
          }
          renderRight={renderSearch}
        />
        {wStudentMovement ||
        wLocalizacionData ||
        wPreviasProyeccionesData ||
        wProyeccionEstado ||
        wSeccionesFromFetch ||
        wYearPassPromotions ||
        wCalificacionAnual ||
        wCalificacionRecuperacion ||
        isSaving ? (
          <Loading />
        ) : (
          <InfoTable
            rows={studentsData}
            columns={columnValidation}
            orderBy={orderBy}
            direction={direction}
            onSortChange={onSortChange}
            working={
              wStudentMovement ||
              wLocalizacionData ||
              wPreviasProyeccionesData ||
              wProyeccionEstado ||
              wSeccionesFromFetch ||
              wYearPassPromotions
            }
          />
        )}
      </Grid>
      <Footer buttonConfig={footerButtons} spacing={3} />
    </>
  );
};

export const PromotionDetail = DatabaseConnector(PromotionScreenRaw)(
  'alumno_movimiento',
  'promocion_pase_anio',
  'estado_pase_anio',
  'seccion',
);
