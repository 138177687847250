import React, { useMemo } from 'react';
import { EspaciosInterdiscipPlan } from '../../../../../../../../app/models';
import { InnerCard } from '../../commons/components';
import {
  InformacionGeneral,
  Proyecto,
  ObjetivosDeAprendizaje,
  EspacioCurricular,
  Actividad,
  IndicadoresDeEvaluacion,
  Bibliografia,
} from '../info';
// TODO: finish with data
export const useInfoCards = (data: EspaciosInterdiscipPlan) =>
  useMemo<InnerCard[]>(
    () => [
      {
        title: 'Información General',
        render: (
          <InformacionGeneral data={data.interdiscipPlan.informacionGeneral} />
        ),
      },
      {
        title: 'Proyecto',
        render: <Proyecto data={data.interdiscipPlan.proyecto} />,
      },
      {
        title: 'Objetivos de aprendizaje',
        render: (
          <ObjetivosDeAprendizaje
            data={data.interdiscipPlan.objetivosDeAprendizaje}
          />
        ),
      },
      {
        title: 'Espacio Curricular',
        render: (
          <EspacioCurricular data={data.interdiscipPlan.espacioCurricular} />
        ),
      },
      {
        title: 'Actividad',
        render: <Actividad data={data.interdiscipPlan.actividad} />,
      },
      {
        title: 'Indicadores de evaluación',
        render: (
          <IndicadoresDeEvaluacion
            data={data.interdiscipPlan.indicadoresDeEvaluacion}
          />
        ),
      },
      {
        title: 'Bibliografía',
        render: <Bibliografia data={data.interdiscipPlan.bibliografia} />,
      },
    ],
    [data],
  );
