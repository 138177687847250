import { Box, Button, Grid, Typography } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import {
  Loading,
  MainInput,
  SimpleSelect,
  TabBar,
  useTabBarSetValue,
  useTabBarValue,
} from 'src/commons';
import { useRolesContext } from 'src/context/RolesContext';
import typography from 'src/theme/typography';
import { DevolucionDocente, DevolucionOrganizacion } from './components';
import { useEstudianteContext } from './contexts/EstudianteContext';
import { useGetFilter } from './hooks/useGetFilter';
import { useDevolucionOrganizacionLibbyFetch } from 'src/app/business/acap';

export const Devoluciones = () => {
  const roleContext = useRolesContext();
  const setTabBarValue = useTabBarSetValue();
  const { params } = useTabBarValue();
  const [shoudGoToTabOne, setShouldGoToTabOne] = useState(false);
  const { grupos, loadingGrupos: workingGrupoInscripcion } =
    useEstudianteContext();

  const { actividad, organizacion, setActividad, setOrganizacion } =
    useGetFilter();

  const devolucionesFilter = useMemo(
    () => ({
      grupoInscripcion: [
        {
          path: 'grupoInscripcion',
          value: grupos.find(
            (grupo) =>
              Number(
                grupo.ofertaGrupo.ofertaTurno.oferta.accion.organizacion
                  .idOrganizacion,
              ) === Number(organizacion) &&
              Number(grupo.ofertaGrupo.ofertaTurno.oferta.accion.idAccion) ===
                Number(actividad),
          )?.idGrupoInscripcion,
        },
      ],
    }),
    [grupos, organizacion, actividad],
  );

  const {
    data: devolucionesOrganizacion = [],
    working: workingDevolucionesOrganizacion,
  } = useDevolucionOrganizacionLibbyFetch({
    aspect: 'basic',
    enabled:
      grupos.length > 0 &&
      Boolean(devolucionesFilter.grupoInscripcion[0].value),
    filter: devolucionesFilter,
    orderBy: 'fechaAcap,updatedAt',
    direction: 'desc',
    limit: 1000,
  });

  const currentGrupo = grupos.find((grupo) =>
    Boolean(params.id)
      ? grupo.idGrupoInscripcion === params.id
      : grupo.ofertaGrupo.ofertaTurno.oferta.accion.idAccion === actividad &&
        grupo.ofertaGrupo.ofertaTurno.oferta.accion.organizacion
          .idOrganizacion === organizacion,
  );

  const referente = grupos?.filter(
    (grupo) => grupo.idGrupoInscripcion === params.id,
  );

  const allTabs = useMemo(
    () => [
      {
        title: 'Devolución Organización',
        component: (
          <DevolucionOrganizacion
            actividad={actividad}
            organizacion={organizacion}
            devolucionesOrganizacion={devolucionesOrganizacion}
            workingDevolucionesOrganizacion={workingDevolucionesOrganizacion}
            workingGrupoInscripcion={workingGrupoInscripcion}
          />
        ),
      },
      {
        title: 'Devolución Docente',
        component: (
          <DevolucionDocente
            actividad={actividad}
            organizacion={organizacion}
          />
        ),
      },
    ],
    [
      actividad,
      devolucionesOrganizacion,
      organizacion,
      workingDevolucionesOrganizacion,
      workingGrupoInscripcion,
    ],
  );

  const refAccion = Boolean(devolucionesOrganizacion)
    ? `${devolucionesOrganizacion[0]?.grupoInscripcion?.ofertaGrupo?.jornadas[0]?.sede?.organizacion?.referente?.apellido}, ${devolucionesOrganizacion[0]?.grupoInscripcion?.ofertaGrupo?.jornadas[0]?.sede?.organizacion?.referente?.nombre}`
    : `${referente[0]?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.organizacion?.referente?.apellido}, ${referente[0]?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.organizacion?.referente?.nombre}`;

  return (
    <Grid container>
      <Grid item xs={12} style={{ margin: '30px 0px 15px 0px' }}>
        <Box
          height={'auto'}
          style={{
            border: '0.5px solid #afc0d0;',
            boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.25)',
            borderRadius: 8,
          }}
        >
          <Grid container style={{ padding: '15px 0px 10px 30px' }}>
            <Grid item xs={4}>
              <Grid
                container
                style={{ flexWrap: 'wrap', flexDirection: 'column' }}
              >
                <Grid
                  item
                  xs={2}
                  style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                >
                  <Typography
                    style={{
                      fontFamily: typography.fontFamily,
                      fontSize: 14,
                      fontWeight: 600,
                      marginBottom: 10,
                    }}
                  >
                    Estudiante:
                  </Typography>
                </Grid>
                <Grid item xs={9} style={{ marginBottom: 16 }}>
                  <MainInput
                    label=""
                    disabled
                    value={`${roleContext.userInfo.apellido} ${roleContext.userInfo.name}`}
                    handleChange={() => null}
                    placeholder={''}
                    fullWidth={true}
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={4}
              style={{ display: 'flex', alignItems: 'center', gap: 4 }}
            >
              <Grid
                container
                style={{ flexWrap: 'wrap', flexDirection: 'column' }}
              >
                <Grid
                  item
                  xs={3}
                  style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                >
                  <Typography
                    style={{
                      fontFamily: typography.fontFamily,
                      fontSize: 14,
                      fontWeight: 600,
                      marginBottom: 10,
                    }}
                  >
                    Establecimiento:
                  </Typography>
                </Grid>
                <Grid item xs={9} style={{ marginBottom: 16 }}>
                  <MainInput
                    label=""
                    disabled
                    value={`${roleContext.selectedRole.localizacion}`}
                    handleChange={() => null}
                    placeholder={''}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              padding: '10px 15px 15px 30px',
            }}
          >
            <Grid item xs={3} style={{ marginLeft: 0 }}>
              <SimpleSelect
                title="Organización"
                disabled={Boolean(params.id)}
                name="Organización"
                value={organizacion}
                handleChange={(e) => setOrganizacion(e.target.value)}
                placeholder="Organización"
                content={[
                  ...new Set(
                    grupos.map((grupo) =>
                      JSON.stringify(
                        grupo.ofertaGrupo.ofertaTurno.oferta.accion
                          .organizacion,
                      ),
                    ),
                  ),
                ].map((grupo) => JSON.parse(grupo))}
                labelKey="nombre"
                valueKey="idOrganizacion"
                customStyleContainer={{ width: '80%' }}
                customStyleTitle={{
                  fontFamily: typography.fontFamily,
                  fontSize: 14,
                  fontWeight: 600,
                  color: '#000000',
                }}
              />
            </Grid>
            <Grid item xs={3} style={{ marginLeft: 30 }}>
              <SimpleSelect
                title="Actividad"
                disabled={Boolean(params.id)}
                name="Actividad"
                value={actividad}
                handleChange={(e) => setActividad(e.target.value)}
                placeholder="Nombre de la Actividad"
                content={[
                  ...new Set(
                    grupos
                      .filter(
                        (grupo) =>
                          grupo.ofertaGrupo.ofertaTurno.oferta.accion
                            .organizacion.idOrganizacion === organizacion,
                      )
                      .map((grupo) =>
                        JSON.stringify(
                          grupo.ofertaGrupo.ofertaTurno.oferta.accion,
                        ),
                      ),
                  ),
                ].map((accion) => JSON.parse(accion))}
                labelKey="nombre"
                valueKey="idAccion"
                customStyleContainer={{ width: '80%' }}
                customStyleTitle={{
                  fontFamily: typography.fontFamily,
                  fontSize: 14,
                  fontWeight: 600,
                  color: '#000000',
                }}
              />
            </Grid>
            <Grid
              item
              xs={3}
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: 4,
                marginLeft: 30,
              }}
            >
              <Grid container style={{ marginTop: 20 }}>
                <Grid
                  item
                  xs={4}
                  style={{ display: 'flex', alignItems: 'center', gap: 4 }}
                >
                  <Typography
                    style={{
                      fontFamily: typography.fontFamily,
                      fontSize: 14,
                      fontWeight: 600,
                    }}
                  >
                    Referente acción:
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  {workingDevolucionesOrganizacion ? (
                    <Loading />
                  ) : (
                    <MainInput
                      label=""
                      disabled
                      value={
                        refAccion === 'undefined, undefined' ? '' : refAccion
                      }
                      handleChange={() => null}
                      placeholder={''}
                      fullWidth
                    />
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={12}>
        {Boolean(currentGrupo) && organizacion > 0 && Number(actividad) > 0 ? (
          <TabBar
            content={allTabs}
            shoudGoToTabOne={shoudGoToTabOne}
            setShouldGoToTabOne={setShouldGoToTabOne}
          />
        ) : null}
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '15px',
        }}
      >
        <Button
          color="primary"
          variant="contained"
          component="label"
          style={{ marginTop: '20px' }}
          onClick={() => setTabBarValue(0)}
        >
          Volver
        </Button>
      </Grid>
    </Grid>
  );
};
