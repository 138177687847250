import React, { ReactNode } from 'react';
import styled from 'styled-components';

export const FullScreenLayout = ({ children }: { children: ReactNode }) => {
  return <Container>{children}</Container>;
};

const Container = styled.div`
  display: flex;
`;
