export enum SIDEMENU_ID {
  // Side Menu
  MATRICULACION = 1,
  NOTIFICATIONS,
  COMUNICATIONS,
  CURRICULAR_SPACES,
  DOCUMENTS,
  CALENDAR,
  REPOSITORY,
  REPOSITORY_ABM,
  LEGAJO,
  CONFIGURACION,
  CONFIGURACION_ADMIN,
  GESTIONROLES,
  COMUNICACIONES,
  ALUMNOS_AULAS_VRITUALES,
  BOLETINES,
  PRESENTISMO,
  INFORMESC,
  CALIFICACIONES,
  APRENDIZAJE_BASADO_EN_PROYECTOS,
  PPS,
  CREATEC,
  LEERPARACRECER,
  ACAP,
  ACAP_ESCUELA,
  CIUDADANIA_GLOBAL,
  PERMISOS,
  DASHBOARD,
}
