import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Planta } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'planta';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPlanta',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPlanta',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Planta
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Planta,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Planta.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Planta>(DAO_NAME, optionsToUse);

// hooks
export const usePlantaFetchById = fetchById;
export const usePlantaLibbyCall = libbyCall;
export const usePlantaDAO = libbyDAO;
export const usePlantaLibbyFetch = libbyFetch;

// components/hoc
export const PlantaAutocomplete = entityAutocomplete;
export const PlantaCall = renderPropCall;
export const PlantaById = renderPropFetchById;
export const PlantaSelect = simpleSelect;
export const PlantaAccordionSelector = accordionSelector;
export const PlantaSelectedChips = selectedChips;

// context
// ByIdContext
export const usePlantaByIdContext = useByIdContext;
export const PlantaByIdProvider = ByIdProvider;
export const PlantaByIdContext = ByIdContext;
// GeneralContext
export const usePlantaContext = useGeneralContext;
export const PlantaProvider = GeneralProvider;
export const PlantaContext = GeneralContext;
