import { Grid } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { FunctionComponent, useMemo } from 'react';
import { useEstablecimientoLibbyFetch } from 'src/app/business';
import { Establecimiento } from 'src/app/models';
import { FormDatePicker, useFormContext } from 'src/lib/templates';
import { CustomFormAutocomplete as FormAutocomplete } from '../../../components';
import { useStyles } from '../styles';

interface AtencionHospitalariaCardProps {
  index: number;
  onRemoveItem: (index: number) => any;
  isHistoric?: boolean;
}

export const AtencionHospitalariaCard: FunctionComponent<
  AtencionHospitalariaCardProps
> = ({ index, onRemoveItem, isHistoric }) => {
  const { form } = useFormContext();
  const classes = useStyles();
  const filterEstablecimientosAyC = useMemo(
    () => ({
      filter: [{ path: 'modalidad', value: 4 }],
    }),
    [],
  );
  const paramsFetchEstablecimientosAyC = {
    daoName: 'establecimiento',
    filter: filterEstablecimientosAyC,
  };
  const { data: establecimientosAyC } = useEstablecimientoLibbyFetch(
    paramsFetchEstablecimientosAyC,
  );

  return (
    <>
      <Grid item xs={12}>
        <Grid container spacing={4} style={{ width: '100%' }}>
          <Grid item lg={7} md={6} sm={10} xs={12}>
            <FormAutocomplete
              disabled={isHistoric}
              placeholder="Seleccioná establecimiento"
              label="Establecimiento"
              name={`salud.datosAtencion[${index}].establecimiento`}
              form={form}
              optionLabelKey="nombre"
              options={establecimientosAyC || []}
              optionToValue={(option: Establecimiento) =>
                option?.idEstablecimiento
              }
              valueToOption={(value, options: Establecimiento[]) =>
                options.find((option) => option.idEstablecimiento === value)
              }
              getOptionSelected={(
                option: Establecimiento,
                value: Establecimiento,
              ) => option.idEstablecimiento === value.idEstablecimiento}
            />
          </Grid>
          <Grid item lg={2} md={3} sm={6} xs={6}>
            <FormDatePicker
              disabled={isHistoric}
              placeholder="Seleccioná fecha"
              label="Desde"
              name={`salud.datosAtencion[${index}].atencionDesde`}
              form={form}
              className={classes.datePicker}
            />
          </Grid>
          <Grid item lg={2} md={3} sm={6} xs={6}>
            <FormDatePicker
              disabled={isHistoric}
              placeholder="Seleccioná fecha"
              label="Hasta"
              name={`salud.datosAtencion[${index}].atencionHasta`}
              form={form}
              className={classes.datePicker}
            />
          </Grid>
          <Grid
            container
            item
            lg={1}
            md={1}
            sm={1}
            xs={1}
            alignContent="center"
            justify="flex-end"
            className={classes.containerButtonAtencion}
          >
            <Grid item>
              {!!index && !isHistoric && (
                <DeleteIcon
                  className={classes.deleteIcon}
                  onClick={() => onRemoveItem(index)}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
