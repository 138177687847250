import { Template } from '../../../../../lib/templates';
export const JustificacionFormTemplate: Template = {
  name: 'Justificacion Template',
  template: [
    {
      name: 'razonJustificacion',
      type: { name: 'string' },
      validators: [
        {
          name: 'empty',
          signature: [],
          errorMessage: 'Este campo es requerido',
        },
        {
          name: 'required',
          signature: [],
          errorMessage: 'Este campo es requerido',
        },
      ],
    },
    {
      name: 'fechaDesde',
      type: { name: 'string' },
      validators: [
        {
          name: 'empty',
          signature: [],
          errorMessage: 'Este campo es requerido',
        },
        {
          name: 'required',
          signature: [],
          errorMessage: 'Este campo es requerido',
        },
      ],
    },
    {
      name: 'fechaHasta',
      type: { name: 'string' },
      validators: [
        {
          name: 'empty',
          signature: [],
          errorMessage: 'Este campo es requerido',
        },
        {
          name: 'required',
          signature: [],
          errorMessage: 'Este campo es requerido',
        },
      ],
    },
    {
      name: 'turno',
      type: { name: 'array', kind: 'string' },
      optional: true,
    },
    {
      name: 'contraturno',
      type: { name: 'array', kind: 'string' },
      optional: true,
    },
    {
      name: 'motivoJustificacion',
      type: { name: 'string' },
      validators: [
        {
          name: 'empty',
          signature: [],
          errorMessage: 'Este campo es requerido',
        },
        {
          name: 'required',
          signature: [],
          errorMessage: 'Este campo es requerido',
        },
      ],
    },
    {
      name: 'horaSalida',
      type: { name: 'string' },
      validators: [
        {
          name: 'empty',
          signature: [],
          errorMessage: 'Este campo es requerido',
        },
        {
          name: 'required',
          signature: [],
          errorMessage: 'Este campo es requerido',
        },
      ],
    },
    {
      name: 'details',
      type: { name: 'string' },
      validators: [
        {
          name: 'empty',
          signature: [],
          errorMessage: 'Este campo es requerido',
        },
        {
          name: 'required',
          signature: [],
          errorMessage: 'Este campo es requerido',
        },
      ],
    },
    {
      name: 'files',
      type: { name: 'array', kind: 'any' },
      validators: [
        {
          name: 'empty',
          signature: [],
          errorMessage: 'Este campo es requerido',
        },
        {
          name: 'min',
          signature: [1],
          errorMessage: 'Este campo es requerido',
        },
      ],
    },
  ],
};
