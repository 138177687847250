import {
  Card,
  CardContent,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { MainButton } from 'src/commons';
import { grey, primary } from '../../../../../theme/colors';
import { loginCards } from '../../../../../assets/images/imagesLoginCards';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 420,
    height: 210,
    backgroundColor: '#FFF',
  },
  cardContent: {
    flexDirection: 'row',
    display: 'flex',
    height: 180,
  },
  textAssignatureContainer: {
    width: '75%',
    height: '100%',
  },
  assignatureTitle: {
    fontSize: 24,
    fontWeight: 500,
    color: grey.heading,
    fontFamily: 'Open Sans',
    paddingLeft: 12,
  },
  assignatureTitleRecursos: {
    fontSize: 22,
    fontWeight: 500,
    color: grey.heading,
    fontFamily: 'Open Sans',
    paddingLeft: 12,
    marginBottom: 8,
    lineHeight: 1.2,
  },
  anioText: {
    marginTop: theme.spacing(1),
    fontSize: 12,
    color: grey.heading,
    fontFamily: 'Open Sans',
    paddingLeft: 12,
    fontWeight: 500,
  },
  anioContainer: {
    marginBottom: theme.spacing(1),
  },
  imageContainer: {
    width: '25%',
    height: '100%',
  },
  papper: {
    width: '98%',
    flexDirection: 'row',
    // height: 640,
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(16),
    backgroundColor: 'transparent',
  },
  GridClase: {
    flexDirection: 'row',
    display: 'flex',
    marginTop: theme.spacing(5),
  },
  styleA: {
    textDecoration: 'none',
  },
  imageCard: {
    width: '90%',
  },
  apoyoContainer: {
    paddingTop: theme.spacing(1),
  },
  cardContainer: {
    margin: 5,
  },
}));

export const LoginCard = () => {
  const classes = useStyles();

  return (
    <Paper id="card" className={classes.papper} elevation={0}>
      <Grid
        container
        className={classes.GridClase}
        justifyContent="space-evenly"
        alignItems="center"
      >
        <Grid item className={classes.cardContainer}>
          <div
            style={{
              backgroundColor: primary.violet,
              height: 10,
              width: 420,
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
            }}
          />
          <Card className={classes.root} raised>
            <CardContent className={classes.cardContent}>
              <Grid className={classes.imageContainer}>
                <img
                  src={loginCards.recursos}
                  className={classes.imageCard}
                  alt="recursos"
                />
              </Grid>
              <Grid className={classes.textAssignatureContainer}>
                <Grid item>
                  <Typography className={classes.assignatureTitleRecursos}>
                    Espacio Virtual
                    <br />
                    de Apoyo Escolar
                  </Typography>
                </Grid>
                <Grid item className={classes.anioContainer}>
                  <Typography className={classes.anioText}>
                    Accedé a aulas virtuales y a acompañamiento de
                    orientadores/as en línea para profundizar los contenidos
                    escolares. Requiere inscripción
                  </Typography>
                </Grid>
                <a
                  className={classes.styleA}
                  href="https://www.buenosaires.gob.ar/educacion/estudiantes/espacio-virtual-de-apoyo-escolar"
                >
                  <MainButton
                    customStyle={{
                      marginTop: 15,
                      width: 180,
                      marginLeft: 14,
                      whiteSpace: 'nowrap',
                    }}
                    title="Conocer más"
                    type="primary"
                    size="medium"
                    typeButton="submit"
                  />
                </a>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item className={classes.cardContainer}>
          <div
            style={{
              backgroundColor: primary.warning,
              height: 10,
              width: 420,
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
            }}
          />
          <Card className={classes.root} raised>
            <CardContent className={classes.cardContent}>
              <Grid className={classes.imageContainer}>
                <img
                  src={loginCards.apoyoEscolar}
                  className={classes.imageCard}
                  alt="apoyo"
                />
              </Grid>
              <Grid className={classes.textAssignatureContainer}>
                <Grid item>
                  <Typography className={classes.assignatureTitle}>
                    Apoyo escolar
                  </Typography>
                </Grid>
                <Grid item className={classes.apoyoContainer}>
                  <Typography className={classes.anioText}>
                    Descubrí una nueva forma de aprender con los videos de apoyo
                    escolar para todos los niveles educativos.
                  </Typography>
                </Grid>
                <a
                  className={classes.styleA}
                  href="https://www.youtube.com/c/educacionBA"
                >
                  <MainButton
                    customStyle={{ marginTop: 37, width: 180, marginLeft: 14 }}
                    title="Acceder a los videos"
                    type="primary"
                    size="medium"
                    typeButton="submit"
                  />
                </a>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item className={classes.cardContainer}>
          <div
            style={{
              backgroundColor: primary.success,
              height: 10,
              width: 420,
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
            }}
          />
          <Card className={classes.root} raised>
            <CardContent className={classes.cardContent}>
              <Grid className={classes.imageContainer}>
                <img
                  src={loginCards.bibliotecaDigital}
                  className={classes.imageCard}
                  alt="biblioteca"
                />
              </Grid>
              <Grid className={classes.textAssignatureContainer}>
                <Grid item>
                  <Typography className={classes.assignatureTitle}>
                    Biblioteca Digital
                  </Typography>
                </Grid>
                <Grid item className={classes.anioContainer}>
                  <Typography className={classes.anioText}>
                    Explorá recursos didácticos como videos, actividades y
                    libros, sobre diversas áreas temáticas, organizados por
                    sala, grado, año y asignatura.
                  </Typography>
                </Grid>
                <a
                  className={classes.styleA}
                  href="https://biblioteca-digital.bue.edu.ar/"
                >
                  <MainButton
                    customStyle={{ marginTop: 25, width: 180, marginLeft: 14 }}
                    title="Descubrir los recursos"
                    type="primary"
                    size="medium"
                    typeButton="submit"
                  />
                </a>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item className={classes.cardContainer}>
          <div
            style={{
              backgroundColor: primary.azul,
              height: 10,
              width: 420,
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
            }}
          />
          <Card className={classes.root} raised>
            <CardContent className={classes.cardContent}>
              <Grid className={classes.imageContainer}>
                <img
                  src={loginCards.escuelaDeMaestros}
                  className={classes.imageCard}
                  alt="maestros"
                />
              </Grid>
              <Grid className={classes.textAssignatureContainer}>
                <Grid item>
                  <Typography className={classes.assignatureTitle}>
                    Escuela de Maestros
                  </Typography>
                </Grid>
                <Grid item className={classes.anioContainer}>
                  <Typography className={classes.anioText}>
                    Accedé a cursos virtuales para fortalecer las estrategias
                    didácticas en tiempos de enseñanza no presencial y continuar
                    acompañandonos a las familias y a los chicos.
                  </Typography>
                </Grid>
                <a
                  className={classes.styleA}
                  href="https://escuelademaestros.bue.edu.ar/materiales/"
                >
                  <MainButton
                    customStyle={{ marginTop: 25, width: 180, marginLeft: 14 }}
                    title="Accede a la escuela"
                    type="primary"
                    size="medium"
                    typeButton="submit"
                  />
                </a>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item className={classes.cardContainer}>
          <div
            style={{
              backgroundColor: primary.dark,
              height: 10,
              width: 420,
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
            }}
          />
          <Card className={classes.root} raised>
            <CardContent className={classes.cardContent}>
              <Grid className={classes.imageContainer}>
                <img
                  src={loginCards.mesLectura}
                  className={classes.imageCard}
                  alt="maestros"
                />
              </Grid>
              <Grid className={classes.textAssignatureContainer}>
                <Grid item>
                  <Typography className={classes.assignatureTitle}>
                    Mes de la lectura
                  </Typography>
                </Grid>
                <Grid item className={classes.anioContainer}>
                  <Typography className={classes.anioText}>
                    Te proponemos un viaje a través de narraciones, encuentros
                    con autores, juegos, talleres y visitas virtuales a museos
                    para que descubras nuevos mundos.
                  </Typography>
                </Grid>
                <a
                  className={classes.styleA}
                  href="https://view.genial.ly/60cb571f9045a30d269226b8/interactive-image-mes-de-la-lectura"
                >
                  <MainButton
                    customStyle={{ marginTop: 25, width: 180, marginLeft: 14 }}
                    title="Ingresá a la página"
                    type="primary"
                    size="medium"
                    typeButton="submit"
                  />
                </a>
              </Grid>
            </CardContent>
          </Card>
        </Grid>

        <Grid item className={classes.cardContainer}>
          <div
            style={{
              backgroundColor: primary.lightBlue,
              height: 10,
              width: 420,
              borderTopRightRadius: 10,
              borderTopLeftRadius: 10,
            }}
          />
          <Card className={classes.root} raised>
            <CardContent className={classes.cardContent}>
              <Grid className={classes.imageContainer}>
                <img
                  src={loginCards.ciudadaniaGlobal}
                  className={classes.imageCard}
                  alt="Ciudadania global"
                />
              </Grid>
              <Grid className={classes.textAssignatureContainer}>
                <Grid item>
                  <Typography className={classes.assignatureTitle}>
                    Ciudadanía Global
                  </Typography>
                </Grid>
                <Grid item className={classes.anioContainer}>
                  <Typography className={classes.anioText}>
                    Sumate y descubrí propuestas didácticas y recursos
                    interactivos, multimedia y gamificados sobre Educación
                    Ambiental para trabajar en la escuela y en casa.
                  </Typography>
                </Grid>
                <a
                  className={classes.styleA}
                  href="http://ciudadaniaglobal.bue.edu.ar/register/teacher"
                >
                  <MainButton
                    customStyle={{
                      marginTop: 25,
                      width: 180,
                      marginLeft: 14,
                      padding: 0,
                      height: 40,
                    }}
                    title="Ingresá a la plataforma"
                    type="primary"
                    size="medium"
                    typeButton="submit"
                  />
                </a>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  );
};
