import { Box, Button, Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import { DownloadIcon } from '../../../../../../assets/images/svgs';
import cx from 'classnames';
import { Loading } from 'src/commons';

const useStyles = makeStyles({
  buttonDescarga: {
    color: '#3786EE',
    textTransform: 'none',
    backgroundColor: '#F5F9FC',
    fontSize: 14,
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: '#D1DBE2',
      textTransform: 'none',
      padding: '8px 16px',
    },
  },
  buttonGuardar: {
    color: 'white',
    textTransform: 'none',
    backgroundColor: '#3786EE',
    fontSize: 14,
    padding: '8px 16px',
    '&:hover': {
      backgroundColor: '#00478F',
      textTransform: 'none',
      padding: '8px 16px',
    },
  },
  icon: {
    fontSize: '15px !important',
  },
});

interface PresentismoFooterProps {
  disabledSave?: boolean;
  isLoading: boolean;
  disabledDownload?: boolean;
  onDownload: () => void;
  onSave: () => void;
}
export const PresentismoFooter = ({
  disabledDownload,
  disabledSave,
  onSave,
  onDownload,
  isLoading = false,
}: PresentismoFooterProps) => {
  const classes = useStyles();

  return (
    <Grid
      component={Box}
      display="flex"
      bgcolor="white"
      paddingX={2}
      boxShadow="0px -7px 3px rgba(0, 0, 0, 0.1)"
      paddingY={2}
      position="fixed"
      left={0}
      bottom={0}
      right={0}
      zIndex={1}
    >
      <Grid container justifyContent="flex-end" alignItems="center" spacing={3}>
        <Grid item>
          <Button
            className={classes.buttonDescarga}
            onClick={onDownload}
            variant="text"
            color="primary"
            disabled={disabledDownload}
            endIcon={
              <DownloadIcon
                className={classes.icon}
                fontSize="small"
                width={15}
                height={15}
              />
            }
          >
            Descargar
          </Button>
        </Grid>
        <Grid item>
          {isLoading ? (
            <Loading />
          ) : (
            <Button
              className={classes.buttonGuardar}
              onClick={onSave}
              variant="contained"
              color="primary"
              disabled={disabledSave}
            >
              Guardar
            </Button>
          )}
        </Grid>
        <Grid item></Grid>
      </Grid>
    </Grid>
  );
};
