import { SituacionGenerate } from '../../../app/models';
import { makeBusinessEntity } from '../../../lib/libby/generator';
import { SituacionGenerateDAO } from '../../../platform/libby/dao/Presentismov3';

const DAO_NAME = 'situacion_generate';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { renderPropCall, renderPropFetchById, simpleSelect },
} = makeBusinessEntity<SituacionGenerate, SituacionGenerateDAO>(DAO_NAME, {});

// hooks
export const useSituacionGenerateFetchById = fetchById;
export const useSituacionGenerateLibbyCall = libbyCall;
export const useSituacionGenerateDAO = libbyDAO;
export const useSituacionGenerateLibbyFetch = libbyFetch;

// components/hoc
export const SituacionGenerateCall = renderPropCall;
export const SituacionGenerateById = renderPropFetchById;
export const SituacionGenerateSelect = simpleSelect;
