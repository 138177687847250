import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ArealPlanFeedbackDAO extends LibbyFetchDAO {
  constructor() {
    super('areal_plan_feedback', 'idArealPlanFeedback');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  async getLastFeedback(id: number | string) {
    // TODO: cambiar el orderBy de la pk al campo de fechaCreacion cuando este disponible
    const [feedback] = await this.query()
      .limit(0, 1)
      .orderBy('idArealPlanFeedback', 'desc')
      .equals('arealPlan', id)
      .run();
    return feedback || null;
  }
}
