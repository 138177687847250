import { useCallback, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { useHistory } from 'react-router';
import { useRolesContext } from 'src/context/RolesContext';

const useStyles = makeStyles({
  table: {
    maxHeight: '600px',
    marginBottom: '80px',
  },
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export type CalificacionesFilter = {
  anio: string;
  periodo: number;
  espacioCurricularSeccion: string;
  seccion: string;
  search: string;
};

export const useInitSecundario = (idPeriodo: number) => {
  const { idEspacioCurricular, idAnio } = useParams<any>();
  const classes = useStyles();
  const history = useHistory();
  const [filter, setFilter] = useState<CalificacionesFilter>({
    anio: idAnio ?? '',
    periodo: idPeriodo,
    espacioCurricularSeccion: '',
    seccion: '',
    search: '',
  });
  const roleInfo = useRolesContext();
  const [seccionSelect, setSeccionSelect] = useState('');
  const [periodoSelect, setPeriodoSelect] = useState('');
  const [espacioSeccionSelect, setEspacioSeccionSelect] = useState('');

  return {
    idEspacioCurricular,
    idAnio,
    classes,
    history,
    filter,
    setFilter,
    roleInfo,
    seccionSelect,
    setSeccionSelect,
    periodoSelect,
    setPeriodoSelect,
    espacioSeccionSelect,
    setEspacioSeccionSelect,
  };
};
