import React, { useMemo, useCallback, useState } from 'react';
import { useSnackbar } from 'notistack';
import customFormDialog from 'src/commons/services/customFormDialog';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useUsuariosRolesDAO } from 'src/app/business/businessCustomEndpoints';
import { Grid } from '@material-ui/core';
import { Loading } from 'src/commons/components/Loading';
import { FormContextProvider } from 'src/lib/templates';
import { AddUserFormItem } from './AddUserFormItem';
import { template } from './template';

interface AddUserFormProps {
  reFetch: () => void;
}

export const AddUserForm = ({ reFetch }: AddUserFormProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const usuariosRolesDAO = useUsuariosRolesDAO();
  const { enqueueSnackbar } = useSnackbar();

  const initialValues = useMemo(
    () => ({
      nombre: '',
      apellido: '',
      cuentaBue: '',
    }),
    [],
  );

  const onSubmit = useCallback(
    async (values) => {
      const confirm = await confirmDialog.show({
        title: 'Confirmación de Alta Nuevo Usuario',
        confirmText: 'Confirmar',
        cancelText: 'Cancelar',
        content: `¿Desea dar de alta el usuario ${values.cuentaBue}?`,
      });
      if (confirm) {
        try {
          setSubmitting(true);
          const usuariosRolesResponse = await usuariosRolesDAO.addUser(
            values.nombre.trim(),
            values.apellido.trim(),
            values.documento.trim(),
            values.cuil.trim(),
            values.cuentaBue.trim(),
          );
          if (usuariosRolesResponse.status === 500) {
            throw new Error(`${usuariosRolesResponse.message}`);
          }
          await customFormDialog.handleCancel();
          enqueueSnackbar(`El usuario se ha guardado con éxito`, {
            variant: 'success',
          });
          reFetch();
        } catch (e) {
          console.log(e);
          enqueueSnackbar(`${e}`, { variant: 'error' });
          await customFormDialog.handleCancel();
        }
      }
    },
    [usuariosRolesDAO, enqueueSnackbar, reFetch],
  );

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      style={{ marginTop: 20, paddingBottom: 20 }}
    >
      <FormContextProvider
        template={template}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {submitting ? <Loading /> : <AddUserFormItem />}
      </FormContextProvider>
    </Grid>
  );
};
