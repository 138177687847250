import { InputIndicadorTipoModal } from '../InformeCuatrimestral/components/InputIndicadorTipoModal';
import {
  AniosNivelInicial,
  Cuatrimestre,
  IndicadorTipoAdjunto,
  IndicadorTipoModal,
  IndicadorTipoSelect,
  IndicadorTipoTextoModal,
} from '../informesTypes';
import { ANIOS_NIVEL_INICIAL, PPI } from './informesConst';

export const indicadores2023: {
  [key in Cuatrimestre]:
    | IndicadorTipoSelect[]
    | IndicadorTipoModal[]
    | IndicadorTipoTextoModal[]
    | IndicadorTipoAdjunto[];
} = {
  I: [
    {
      id: 'ppi',
      label: '¿Posee PPI?',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      labelKey: 'descripcion',
      valueKey: 'descripcion',
      selectOptions: PPI,
      tipoIndicador: 'select',
      orderInTable: 1,
      usedInAnios: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
      requiredFor: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
    },
    {
      id: 'aspectosEspecificos',
      label:
        '¿Cuál es el área y los aspectos específicos de la misma, que considera a fortalecer durante esta  segunda etapa del año?',
      width: '20%',
      hideSortIcon: true,
      orderById: '',
      modalTitle: 'Aspectos Específicos',
      tipoIndicador: 'texto_modal',
      orderInTable: 2,
      usedInAnios: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
      requiredFor: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
    },
    {
      id: 'vinculoPares',
      label:
        '¿Cuál o cuáles aspectos necesita continuar trabajando en relación al vínculo con pares y docentes?',
      width: '30%',
      hideSortIcon: true,
      orderById: '',
      modalTitle: 'Vínculos',
      tipoIndicador: 'texto_modal',
      orderInTable: 3,
      usedInAnios: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
      requiredFor: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
    },
    {
      id: 'observaciones',
      label: 'Observaciones',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      modalTitle: 'Observaciones',
      tipoIndicador: 'texto_modal',
      orderInTable: 4,
      usedInAnios: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
      requiredFor: [],
    },
    {
      id: 'informe',
      label: 'Informe',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      acceptFileType: '.pdf',
      tipoIndicador: 'adjunto',
      orderInTable: 5,
      usedInAnios: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
      requiredFor: Object.keys(ANIOS_NIVEL_INICIAL).filter(
        (anio) => anio !== 'MULTIEDAD',
      ) as AniosNivelInicial[],
    },
    {
      id: 'informeMusica',
      label: 'Informe Música',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      acceptFileType: '.pdf',
      tipoIndicador: 'adjunto',
      orderInTable: 6,
      usedInAnios: ['4_Y_5_ANIOS', '5_ANIOS'],
      requiredFor: ['5_ANIOS'],
    },
    {
      id: 'informeEdFisica',
      label: 'Informe Ed. Física',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      acceptFileType: '.pdf',
      tipoIndicador: 'adjunto',
      orderInTable: 7,
      usedInAnios: ['4_Y_5_ANIOS', '5_ANIOS'],
      requiredFor: ['5_ANIOS'],
    },
    {
      id: 'informesCurriculares',
      label: 'Informes Curriculares: Ed. Física/Música',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      acceptFileType: '.pdf',
      tipoIndicador: 'adjunto',
      orderInTable: 7,
      usedInAnios: [],
      requiredFor: [],
    },
  ],
  II: [
    {
      id: 'ppi',
      label: '¿Posee PPI?',
      width: '5%',
      hideSortIcon: true,
      orderById: '',
      labelKey: 'descripcion',
      valueKey: 'descripcion',
      selectOptions: PPI,
      tipoIndicador: 'select',
      orderInTable: 1,
      usedInAnios: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
      requiredFor: [
        '4_ANIOS',
        '5_ANIOS',
        '1_Y_2_ANIOS',
        '2_Y_3_ANIOS',
        '3_Y_4_ANIOS',
        '4_Y_5_ANIOS',
      ],
    },
    {
      id: 'aspectosEspecificos',
      label: '¿Cuál es el área o los aspectos que fueron fortalecidos?',
      width: '15%',
      hideSortIcon: true,
      orderById: '',
      modalTitle: 'Aspectos Específicos',
      tipoIndicador: 'texto_modal',
      orderInTable: 2,
      usedInAnios: [],
      requiredFor: [],
    },
    {
      id: 'vinculoPares',
      label:
        '¿Cuál o cuáles aspectos necesita continuar trabajando en relación al vínculo con pares y docentes?',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      modalTitle: 'Vínculos',
      tipoIndicador: 'texto_modal',
      orderInTable: 3,
      usedInAnios: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
      requiredFor: ['4_ANIOS', '3_Y_4_ANIOS'],
    },
    {
      id: 'actividadesVinculadas',
      label:
        'Durante la implementación de las actividades vinculadas a la articulación entre sala de 5 y 1° grado, ¿En qué propuestas participó el/la niño/a?',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      modalTitle: 'actividadesVinculadas',
      tipoIndicador: 'texto_modal',
      orderInTable: 4,
      usedInAnios: ['4_Y_5_ANIOS', '5_ANIOS'],
      requiredFor: [],
    },

    {
      id: 'sistemaDeRegistro',
      label:
        '¿Qué sistema de registro utilizó el/la niño/a que dé cuenta del proceso de alfabetización? ',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      modalTitle: 'actividadesVinculadas',
      tipoIndicador: 'texto_modal',
      orderInTable: 4,
      usedInAnios: ['4_Y_5_ANIOS', '5_ANIOS'],
      requiredFor: [],
    },
    {
      id: 'observaciones',
      label: 'Observaciones',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      modalTitle: 'Observaciones',
      tipoIndicador: 'texto_modal',
      orderInTable: 4,
      usedInAnios: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
      requiredFor: [],
    },
    {
      id: 'informe',
      label: 'Informe',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      acceptFileType: '.pdf',
      tipoIndicador: 'adjunto',
      orderInTable: 5,
      usedInAnios: Object.keys(ANIOS_NIVEL_INICIAL) as AniosNivelInicial[],
      requiredFor: ['4_ANIOS', '3_Y_4_ANIOS'],
    },
    {
      id: 'informeMusica',
      label: 'Informe Música',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      acceptFileType: '.pdf',
      tipoIndicador: 'adjunto',
      orderInTable: 6,
      usedInAnios: ['4_Y_5_ANIOS', '5_ANIOS', 'MULTIEDAD'],
      requiredFor: ['5_ANIOS', 'MULTIEDAD'],
    },
    {
      id: 'informeEdFisica',
      label: 'Informe Ed. Física',
      width: '10%',
      hideSortIcon: true,
      orderById: '',
      acceptFileType: '.pdf',
      tipoIndicador: 'adjunto',
      orderInTable: 7,
      usedInAnios: ['4_Y_5_ANIOS', '5_ANIOS', 'MULTIEDAD'],
      requiredFor: ['5_ANIOS', 'MULTIEDAD'],
    },
  ],
};
