import {
  useAutoRegistracionLibbyFetch,
  useSeccionLibbyFetch,
} from 'src/app/business';
import { useMemo } from 'react';
import { useRolesContext } from 'src/context/RolesContext';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
import { ROLES_DOCENTES, ROLES_JERARQUICOS } from '../utils/rolesPresentismo';
import { usePresentismoContext } from '../context';

export const useGetValidYearsPresentismo = () => {
  const {
    selectedRole: { rol, nivel, localizacionId },
    userInfo: { id: userId },
  } = useRolesContext();

  const { cicloLectivo } = useColegioEfectivizadoContext();

  const { isJornadaExtendida } = usePresentismoContext();
  const autoregFilter = useMemo(
    () => ({
      usuario: [{ path: 'usuario', value: userId }],
      rolStatus: [{ path: 'rolStatus.idRolStatus', value: 2 }],
      ciclo: [{ path: 'seccion.cicloLectivo', value: 23 }],
      seccion: [{ path: 'seccion.activo', value: true }],
      materia: [
        {
          path: 'espacioCurricular.materia.idMateria',
          value: -1,
          method: isJornadaExtendida ? 'equals' : 'notEquals',
        },
      ],
    }),
    [isJornadaExtendida, userId],
  );

  const seccionesFilter = useMemo(
    () => ({
      localizacion: [{ path: 'localizacion', value: localizacionId }],
      nivel: [{ path: 'nivel', value: nivel }],
      ciclo: [{ path: 'cicloLectivo', value: cicloLectivo?.idCicloLectivo }],
      ...(isJornadaExtendida && {
        je: [
          {
            path: 'jornada',
            value: 'Extendida',
          },
        ],
      }),
    }),
    [isJornadaExtendida, localizacionId, nivel, cicloLectivo],
  );

  const { data = [], working } = useAutoRegistracionLibbyFetch({
    filter: autoregFilter,
    aspect: 'presentismo',
    enabled: ROLES_DOCENTES.includes(Number(rol)) && Boolean(userId),
  });

  const { data: seccionesData = [] } = useSeccionLibbyFetch({
    filter: seccionesFilter,
    aspect: 'presentismo',
    enabled:
      ROLES_JERARQUICOS.includes(Number(rol)) &&
      cicloLectivo !== null &&
      cicloLectivo.idCicloLectivo !== undefined,
    limit: 1000,
  });

  const secciones = ROLES_DOCENTES.includes(Number(rol))
    ? [
        ...new Set(
          data
            .filter((autoreg) =>
              autoreg.seccion.matCount ? autoreg.seccion.matCount > 0 : true,
            )
            .map((row) => row.seccion),
        ),
      ]
    : seccionesData;

  const uniqAnios = [
    ...new Set(secciones.map((s) => s.anio.idAnio.toString())),
  ];

  const aniosFilterMapped = uniqAnios.map((uniq) => ({
    path: 'idAnio',
    value: uniq,
  }));

  // if (
  //   isJornadaExtendida &&
  //   rol === ROL.DIRECTIVO.toString() &&
  //   !secciones.length &&
  //   !aniosFilterMapped.length
  // ) {
  //   aniosFilterMapped.push({
  //     path: 'idAnio',
  //     value: -123123123,
  //   });
  // }

  return {
    working,
    anio: aniosFilterMapped,
  };
};
