import { DatePickerProps } from '@material-ui/pickers';
import { FieldValidator } from 'final-form';
import React, { FunctionComponent } from 'react';
import { useField } from 'react-final-form-hooks';
import { DatePickerV2 } from 'src/app/components';
import { getHelperTextNoTranslation } from '../hooks/makeGetHelperText';
import { FormErrorComponent } from './FormErrorComponent';

export type FormDatePickerV2Props = {
  form: any;
  name: string;
  label: string;
  clearable?: boolean;
  placeholder?: string;
  disabled?: boolean;
  validator?: FieldValidator<any>;
} & Omit<DatePickerProps, 'value' | 'onChange'>;

export const FormDatePickerV2: FunctionComponent<FormDatePickerV2Props> = ({
  name,
  clearable = true,
  disabled,
  form,
  label,
  format = 'DD/MM/yyyy',
  placeholder = 'dd/mm/aaaa',
  fullWidth = false,
  helperText: customHelperText,
  validator,
  ...rest
}) => {
  const field = useField(name, form, validator);

  const { error, helperText } = getHelperTextNoTranslation(field.meta);
  return (
    <DatePickerV2
      value={field.input.value || null}
      clearable={clearable}
      placeholder={placeholder}
      disabled={disabled}
      error={!!customHelperText || error}
      helperText={
        <FormErrorComponent helperText={customHelperText || helperText} />
      }
      onChange={(_date) => field.input.onChange(_date?.format())}
      label={label}
      onFocus={field.input.onFocus}
      onBlur={field.input.onBlur}
      {...rest}
    />
  );
};
