import { LibbyFetchDAO } from './LibbyFetchDAO';

export class PromocionPaseAnioDAO extends LibbyFetchDAO {
  constructor() {
    super('promocion_pase_anio', 'idPromocionPaseAnio');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByAlumnoId(alumnoId: string) {
    return this.query().equals('alumno.idAlumno', alumnoId).run();
  }
}
