import { Anio, PeriodoNuevo } from 'src/app/models';
import { NIVEL, TIPO_PERIODO } from 'src/commons';
import { useRolesContext } from '../../../../context/RolesContext';
import { ANIOS_NIVEL_INICIAL } from '../../Calificaciones/Informes/informesConfigData/informesConst';

interface SetValidationMessagesArgs {
  hasPresentismo: boolean | undefined;
  hasBoletinGenerado: boolean | undefined;
  isNivelInicial: boolean | undefined;
  anio: Anio;
}
const includeErrorReportIds = [
  ANIOS_NIVEL_INICIAL['4_ANIOS'],
  ANIOS_NIVEL_INICIAL['4_Y_5_ANIOS'],
  ANIOS_NIVEL_INICIAL['5_ANIOS'],
];

export const setAlumnoPassValidationMessages = ({
  hasPresentismo,
  hasBoletinGenerado,
  isNivelInicial,
  anio,
}: SetValidationMessagesArgs) => {
  const gestionErrorMessages: string[] = [];

  const shouldAddGeneralErrorMessage =
    !hasPresentismo ||
    (!hasBoletinGenerado &&
      isNivelInicial &&
      includeErrorReportIds.includes(Number(anio.idAnio)));

  if (shouldAddGeneralErrorMessage) {
    gestionErrorMessages.push(
      'No es posible aprobar el pase porque hay acciones pendientes:',
    );
    gestionErrorMessages.push('');
  }

  if (!hasPresentismo) {
    gestionErrorMessages.push('• Carga de asistencia.');
  }

  if (!hasBoletinGenerado) {
    if (isNivelInicial && includeErrorReportIds.includes(Number(anio.idAnio))) {
      gestionErrorMessages.push('• Generación de informe.');
    } else if (!isNivelInicial) {
      gestionErrorMessages.push('• Generación de boletín.');
    }
  }

  return gestionErrorMessages;
};

export const setAlumnoChangeSeccionMessages = ({
  hasPresentismo,
  hasBoletinGenerado,
  isNivelInicial,
  anio,
}: SetValidationMessagesArgs) => {
  const gestionErrorMessages: string[] = [];

  if (!hasPresentismo) {
    gestionErrorMessages.push('• Carga de asistencia.');
  }

  if (!hasBoletinGenerado) {
    if (isNivelInicial && includeErrorReportIds.includes(Number(anio.idAnio))) {
      gestionErrorMessages.push('• Generación de informe.');
    } else if (!isNivelInicial) {
      gestionErrorMessages.push('• Generación de boletín.');
    }
  }

  return gestionErrorMessages;
};

export const calculatePeriodoTipoPrevio = (
  tipoPeriodoActual: number,
  nivel: number,
  periodos: PeriodoNuevo[],
): PeriodoNuevo => {
  let tipoPeriodoAnterior: number;
  if (nivel === NIVEL.PRIMARIO || nivel === NIVEL.SECUNDARIO) {
    tipoPeriodoAnterior =
      tipoPeriodoActual === TIPO_PERIODO.PRIMER_BIMESTRE
        ? TIPO_PERIODO.CUARTO_BIMESTRE
        : tipoPeriodoActual - 1;
  } else {
    tipoPeriodoAnterior =
      tipoPeriodoActual === TIPO_PERIODO.SEGUNDO_CUATRIMESTRE
        ? TIPO_PERIODO.PRIMER_CUATRIMESTRE
        : TIPO_PERIODO.SEGUNDO_CUATRIMESTRE;
  }
  return periodos.find(
    (periodo) =>
      periodo.tipoPeriodo?.idTipoPeriodo === tipoPeriodoAnterior &&
      periodo.nivel.idNivel === nivel,
  );
};
