import { EstadoPresenteV3DAO } from './../../../platform/libby/dao/Presentismov3/EstadoPresenteV3DAO';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { businessOptions } from '../businessOptions';
import { EstadoPresente_v3 } from '../../models/presentismo_v3';

const DAO_NAME = 'estado_presente';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEstadoPresente',
      labelKey: 'descripcion',
    },
    withSimpleSelect: {
      labelKey: 'descripcion',
      valueKey: 'idEstadoPresente',
      title: 'Estado',
      placeholder: 'Seleccioná Estado',
      name: 'idEstadoPresente',
    },
  },
};

const optionsToUse = !businessOptions.EstadoPresente_v3
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EstadoPresenteV3,
      hoc: { ...defaultOptions.hoc, ...businessOptions.EstadoPresenteV3.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EstadoPresente_v3, EstadoPresenteV3DAO>(
  DAO_NAME,
  optionsToUse,
);

// hooks
export const useEstadoPresenteFetchById = fetchById;
export const useEstadoPresenteLibbyCall = libbyCall;
export const useEstadoPresenteDAO = libbyDAO;
export const useEstadoPresenteLibbyFetch = libbyFetch;

// components/hoc
export const EstadoPresenteAutocomplete = entityAutocomplete;
export const EstadoPresenteCall = renderPropCall;
export const EstadoPresenteById = renderPropFetchById;
export const EstadoPresenteSelect = simpleSelect;
export const EstadoPresenteAccordionSelector = accordionSelector;
export const EstadoPresenteSelectedChips = selectedChips;

// context
// ByIdContext
export const useEstadoPresenteByIdContext = useByIdContext;
export const EstadoPresenteByIdProvider = ByIdProvider;
export const EstadoPresenteByIdContext = ByIdContext;
// GeneralContext
export const useEstadoPresenteContext = useGeneralContext;
export const EstadoPresenteProvider = GeneralProvider;
export const EstadoPresenteContext = GeneralContext;
