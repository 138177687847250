import { Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { useMemo, useEffect, useState, useCallback } from 'react';
import {
  useProyeccionLibbyFetch,
  useUsuarioRolEstablecimientoLibbyFetch,
  useAlumnoMovimientoDAO,
  useProyeccionEstadoLibbyFetch,
  useSeccionLibbyFetch,
  useLocalizacionLibbyFetch,
  useProyeccionIELLibbyFetch,
} from 'src/app/business';
import confirmDialog from 'src/commons/services/confirmDialog';
import { DEPENDENCIA_FUNCIONAL, HeaderTable, InfoTable } from 'src/commons';
import customFormDialog from 'src/commons/services/customFormDialog';
import { FormProyeccionDialog } from '../components/formProyeccionDialog';
import { ProyeccionTableHeader } from '../components/ProyeccionTableHeader';
import useDebounce from '../hooks/useDebounce';
import { useProyeccionColumns } from '../hooks/useProyeccionColumns';
import { ROL } from 'src/commons/const/rol';
import { level } from 'src/commons';
import moment from 'moment';
import { useGeneralContext } from 'src/context';
import { useProyeccionIELBulkCustomPostRequest } from 'src/app/business/businessCustomEndpoints/ProyeccionIELBulkCustom';
import { LoadingStyled } from '../components/styled/Loading';
import { useFilters } from '../hooks/useFilters';
import { getByProyeccionStatus } from '../utils/helpers';
import { FooterButtons } from '../components/FooterButtons';
import { useRolesContext } from 'src/context/RolesContext';
import { PROYECCION_ESTADO_TIPO } from 'src/commons';

const initialFilters = {
  cycle: 0,
  distrito: 0,
  anio: 0,
  estado: 0,
  searchDebounced: '',
  seccionOrigen: 0,
  localizacion: 0,
  jornada: '',
  turno: 0,
  estadoProyeccion: '',
  nivel: 1,
};

export const Gestion = () => {
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('seccionOrigen.anio.idAnio');
  const [search, setSearch] = useState('');
  const [filter, setFilter] =
    useState<GestionProyeccion.Filters>(initialFilters);
  const [alumno_movimiento, setAlumno_Movimiento] = useState([]);
  const [currentDistrito, setCurrentDistrito] = useState(0);
  const [savingProyeccionIEL, setSavingProyeccionIEL] = useState(false);
  const { generalState } = useGeneralContext();
  const FechaServer = generalState?.date;
  const { enqueueSnackbar } = useSnackbar();
  const { userInfo } = useRolesContext();

  const {
    filterCycle,
    filterLocalizacion,
    filterJornada,
    filterTurno,
    filterSeccionOrigen,
    filterDistrito,
    filterAnio,
    filterEstado,
    filterArticula,
    filterSearch,
  } = useFilters(filter);

  const [gestionFilter, setGestionFilter] = useState<Global.LibbyFilter>({
    cycle: filterCycle,
    distrito: filterDistrito,
    anio: filterAnio,
    search: filterSearch,
    proyeccion: filterArticula,
    estado: filterEstado,
    establecimiento: filterLocalizacion,
    jornada: filterJornada,
    turno: filterTurno,
    seccionOrigen: filterSeccionOrigen,
  });

  const establecimientosFilter = useMemo(
    () => ({
      user: [{ path: 'usuario', value: userInfo.id }],
      rol: [
        {
          path: 'rolUsuario.idRolUsuario',
          value: [
            ROL.DIRECTIVO,
            ROL.DIRECCION_AREA,
            ROL.SUPERVISOR,
            ROL.EQUIPO_CONDUCCION,
            ROL.PRECEPTOR,
          ],
          method: 'in',
        },
      ],
    }),
    [userInfo.id],
  );

  const alumnoMovimientoDAO = useAlumnoMovimientoDAO();

  const { request: requestProyeccionIELBulkSave } =
    useProyeccionIELBulkCustomPostRequest();

  const filterLocalizacionProyEstado = useMemo(
    () => ({
      0: [{ path: 'localizacion', value: filter.localizacion }],
    }),
    [filter.localizacion],
  );

  const { data: proyeccionEstado = [] } = useProyeccionEstadoLibbyFetch({
    filter: filterLocalizacionProyEstado,
    enabled: Boolean(filter.localizacion),
    orderBy: 'cicloLectivo.anio',
    direction: 'desc',
  });

  const filterProyeccionIEL = useMemo(
    () => ({
      0: [{ path: 'localizacion', value: filter.localizacion }],
      1: [
        {
          path: 'cicloLectivo.anio',
          value: Number(proyeccionEstado[0]?.cicloLectivo.anio || '') + 1,
        },
      ],
      2: [{ path: 'nivel', value: filter.nivel }],
    }),
    [filter.localizacion, proyeccionEstado, filter.nivel],
  );

  const { data: seccionData = [] } = useSeccionLibbyFetch({
    limit: 1000,
    enabled:
      proyeccionEstado.length > 0 &&
      proyeccionEstado[0]?.cicloLectivo?.anio !== undefined,
    filter: filterProyeccionIEL,
  });

  const { data: establecimientos = [] } =
    useUsuarioRolEstablecimientoLibbyFetch({
      filter: establecimientosFilter,
      limit: 5000,
      enabled: Boolean(userInfo.id),
    });

  // Establecimientos DEI
  const gestionEstablecimientosFiltered = useMemo(() => {
    return establecimientos.filter(
      (usuarioRolEstablecimiento) =>
        [level.INICIAL, -1].includes(
          Number(usuarioRolEstablecimiento.nivel.idNivel),
        ) &&
        usuarioRolEstablecimiento.localizacion.establecimiento
          .dependenciaFuncional.idDependenciaFuncional ===
          DEPENDENCIA_FUNCIONAL.DIRECCION_EDUCACION_INICIAL,
    );
  }, [establecimientos]);

  const paramsFetch = useMemo(
    () => ({
      filter: gestionFilter,
      limit: 60,
      orderBy: orderBy,
      direction: direction,
      enabled: Boolean(filter.localizacion),
    }),
    [gestionFilter, orderBy, direction, filter.localizacion],
  );

  const {
    data = [],
    working,
    fetchMore,
    reFetch,
  } = useProyeccionLibbyFetch(paramsFetch);

  const proyeccionesFiltered = useMemo(() => {
    const currentEstado = filter.estadoProyeccion.trim().toLocaleLowerCase() as
      | 'agestionar'
      | 'gestionado'
      | '';

    if (currentEstado === '') return data;

    return getByProyeccionStatus(data, currentEstado);
  }, [data, filter.estadoProyeccion]);

  const { columns, checkedProyecciones } = useProyeccionColumns(
    data,
    filter,
    gestionEstablecimientosFiltered,
  );

  const handleCloseModal = useCallback(async () => {
    await customFormDialog.handleCancel();
  }, []);
  const handleClick = useCallback(async () => {
    if (checkedProyecciones.length !== 0) {
      try {
        const resolve = await customFormDialog.show({
          title: 'Gestionar Proyección',
          sizeWidth: 'lg',
          renderComponent: (
            <FormProyeccionDialog
              reFetch={reFetch}
              filtercycle={filter.cycle}
              closeModal={handleCloseModal}
              checkedProyecciones={checkedProyecciones}
              currentDistrito={currentDistrito}
              // ACA DEBERIA SER EL ESTABLECIMIENTO QUE SELECCIONO !!
              currentRol={establecimientos[0]?.rolUsuario?.idRolUsuario}
            />
          ),
        });
        if (resolve) {
        }
      } catch (e) {
        console.error(e);
      }
    } else {
      enqueueSnackbar('No hay alumnos seleccionados', {
        variant: 'error',
      });
    }
  }, [
    handleCloseModal,
    reFetch,
    checkedProyecciones,
    enqueueSnackbar,
    filter.cycle,
    currentDistrito,
    establecimientos,
  ]);

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setDirection(newDirection);
    setOrderBy(newOrderBy);
  };

  const filterLocalizacionSearch = useMemo(
    () => ({
      localizacion: [
        {
          path: 'idLocalizacion',
          value: filter.localizacion,
        },
      ],
    }),
    [filter.localizacion],
  );

  const { data: establecimientoData } = useLocalizacionLibbyFetch({
    limit: 1,
    filter: filterLocalizacionSearch,
    aspect: 'dependencia_funcional',
    enabled: Boolean(filter.localizacion),
  });

  const isEstablecimientoDEI =
    establecimientoData?.[0]?.establecimiento?.dependenciaFuncional
      ?.idDependenciaFuncional ===
    DEPENDENCIA_FUNCIONAL.DIRECCION_EDUCACION_INICIAL;

  const supervisorEnabled = useMemo(() => {
    const hasSupervisor = gestionEstablecimientosFiltered.filter(
      (place) => place.rolUsuario.idRolUsuario === ROL.SUPERVISOR,
    ).length;

    const hasDirectivo = gestionEstablecimientosFiltered.filter(
      (place) => place.rolUsuario.idRolUsuario === ROL.DIRECTIVO,
    ).length;

    const hasEquipoConduccion = gestionEstablecimientosFiltered.filter(
      (place) => place.rolUsuario.idRolUsuario === ROL.EQUIPO_CONDUCCION,
    ).length;

    const hasDireccionArea = gestionEstablecimientosFiltered.filter(
      (place) => place.rolUsuario.idRolUsuario === ROL.DIRECCION_AREA,
    ).length;

    const isRestrictedRole = Boolean(
      hasDirectivo || hasEquipoConduccion || hasSupervisor,
    );

    const FECHAINICIOAGOSTO = '08/26/24';
    const FECHAFINSEPTIEMBRE = '09/27/24';
    const newFechaServer = moment(FechaServer).format('YYYY-MM-DD');

    const validDateForPromotionAndReports = moment(newFechaServer).isBetween(
      moment(FECHAINICIOAGOSTO).format('YYYY-MM-DD'),
      moment(FECHAFINSEPTIEMBRE).format('YYYY-MM-DD'),
      undefined,
      '[]',
    );

    const validacion = hasDireccionArea
      ? true
      : isRestrictedRole &&
        isEstablecimientoDEI &&
        validDateForPromotionAndReports;

    return validacion;
  }, [FechaServer, gestionEstablecimientosFiltered, isEstablecimientoDEI]);

  const alumnosFilter = useMemo(
    () => ({
      0: [
        {
          path: 'seccion.localizacion',
          value: filter.localizacion,
        },
      ],
    }),
    [filter.localizacion],
  );

  useEffect(() => {
    if (alumno_movimiento.length === 0 && filter.localizacion !== 0) {
      alumnoMovimientoDAO
        .getCountBySeccionFilter(
          alumnosFilter,
          'matriculacion_secciones_alumnoCount',
        )
        .then((response: any) => {
          setAlumno_Movimiento(response);
        });
    }
  }, [
    alumnosFilter,
    alumnoMovimientoDAO,
    alumno_movimiento.length,
    filter.localizacion,
  ]);

  const ielFilter = useMemo(
    () => ({
      cue: [
        {
          path: 'cueAnexo',
          value: filter.localizacion,
        },
      ],
      ciclo: [
        {
          path: 'cicloLectivo',
          value: proyeccionEstado[0]?.cicloLectivo?.idCicloLectivo,
        },
      ],
    }),
    [filter.localizacion, proyeccionEstado],
  );

  const { data: proyeccionesIel = [], reFetch: refetchIel } =
    useProyeccionIELLibbyFetch({
      filter: ielFilter,
      enabled: Boolean(filter.localizacion) && Boolean(proyeccionEstado[0]),
    });

  const informarIEL = useCallback(async () => {
    const content =
      'Esta a punto de enviar la información a IEL, desea continuar?';
    const confirm = await confirmDialog.show({
      title: 'Informar a IEL',
      content,
      confirmText: 'Aceptar',
      cancelText: 'Volver',
    });
    if (confirm) {
      try {
        await requestProyeccionIELBulkSave({
          data: {
            nivel: filter.nivel,
            localizacion: filter.localizacion,
            cicloLectivo: filter.cycle,
          },
        });
        await refetchIel();
        enqueueSnackbar('Se informó con éxito IEL', {
          variant: 'success',
        });
        setSavingProyeccionIEL(false);
      } catch (e) {
        setSavingProyeccionIEL(false);
        console.error(e);
        enqueueSnackbar('Ha ocurrido un error', {
          variant: 'error',
        });
      }
    }
  }, [
    filter.localizacion,
    filter.nivel,
    refetchIel,
    filter.cycle,
    enqueueSnackbar,
    requestProyeccionIELBulkSave,
  ]);
  // Solo para Direccion de Area DEI
  const informarIELValidacion = useMemo(() => {
    const porGestionar = proyeccionesFiltered.some(
      (proy) => proy.seccionDestino === null && proy.articula,
    );
    const isProyeccionFinalizada =
      proyeccionEstado.length > 0
        ? proyeccionEstado[0]?.proyeccionEstadoTipo?.idProyeccionEstadoTipo ===
          PROYECCION_ESTADO_TIPO.FINALIZADO
        : true;
    const isDifferentCiclos =
      filter.cycle !== proyeccionEstado[0]?.cicloLectivo?.idCicloLectivo;
    const isValidRol = gestionEstablecimientosFiltered.some(
      (establecimiento) =>
        establecimiento.rolUsuario.idRolUsuario === ROL.DIRECCION_AREA,
    );
    return (
      porGestionar ||
      savingProyeccionIEL ||
      !isProyeccionFinalizada ||
      isDifferentCiclos ||
      !isValidRol
    );
  }, [
    proyeccionesFiltered,
    proyeccionEstado,
    filter.cycle,
    gestionEstablecimientosFiltered,
    savingProyeccionIEL,
  ]);

  const filterProyecciones = useCallback(
    () =>
      setGestionFilter({
        ...(!!filter.anio && { anio: filterAnio }),
        ...(!!filter.searchDebounced && { search: filterSearch }),
        ...(!!filter.estado && { estado: filterEstado }),
        ...(!!filter.jornada && { jornada: filterJornada }),
        ...(!!filter.turno && { turno: filterTurno }),
        ...(!!filter.seccionOrigen && { seccionOrigen: filterSeccionOrigen }),
        proyeccion: filterArticula,
        establecimiento: filterLocalizacion,
        cycle: filterCycle,
        distrito: filterDistrito,
      }),
    [
      filter.anio,
      filter.searchDebounced,
      filter.estado,
      filter.jornada,
      filter.turno,
      filter.seccionOrigen,
      filterCycle,
      filterAnio,
      filterDistrito,
      filterSearch,
      filterEstado,
      filterLocalizacion,
      filterJornada,
      filterTurno,
      filterSeccionOrigen,
      filterArticula,
    ],
  );

  useEffect(() => {
    filterProyecciones();
  }, [filterProyecciones]);

  const handleChange = useCallback(
    (e) => {
      const name = e.target.name;
      const value = e.target.value;

      if (name === 'distrito') {
        setCurrentDistrito(value);
        setFilter((prev) => ({
          ...prev,
          [name]: value,
          anio: 0,
          jornada: '',
          turno: 0,
          localizacion: 0,
        }));
      } else if (name === 'cycle') {
        setFilter((prev) => ({
          ...initialFilters,
          [name]: value,
        }));
      } else {
        setFilter((prev) => ({
          ...prev,
          [name]: value,
        }));
      }
    },
    [setFilter],
  );

  const handleSearchChange = useCallback(
    (e) => {
      const search = e.target.value;
      setSearch(search);
    },
    [setSearch],
  );

  useDebounce(
    () => setFilter({ ...filter, searchDebounced: search }),
    [search],
    800,
  );

  return (
    <>
      <HeaderTable title="Alumnos">
        <ProyeccionTableHeader
          search={search}
          handleChange={handleChange}
          handleSearchChange={handleSearchChange}
          filterValues={filter}
          selectsDisabled={working}
        />
      </HeaderTable>
      {Boolean(filter.localizacion) ? (
        <>
          <InfoTable
            working={working}
            rows={proyeccionesFiltered}
            columns={columns}
            onSortChange={handleRequestSort}
            direction={direction}
            onBottomScroll={fetchMore}
          />
          <FooterButtons
            titleIEL={
              savingProyeccionIEL ? (
                <LoadingStyled />
              ) : (
                'Informar Vacantes a IEL'
              )
            }
            informarIEL={informarIEL}
            manageProyeccion={handleClick}
            localizacion={Number(filter.localizacion)}
            disabledIEL={informarIELValidacion || working}
            disabledManagement={
              !supervisorEnabled ||
              proyeccionesFiltered.length === 0 ||
              checkedProyecciones.length === 0
            }
          />
        </>
      ) : null}

      <Grid
        container
        justify="center"
        alignItems="center"
        style={{ height: '400px' }}
      >
        <Typography
          style={{
            fontSize: '40px',
            marginTop: '50px',
            color: 'gray',
            fontWeight: 'bold',
          }}
        >
          {!filter.cycle || !filter.distrito || !filter.localizacion
            ? 'Seleccione ciclo lectivo, distrito y establecimiento.'
            : ''}
        </Typography>
      </Grid>
    </>
  );
};
