export const escalafonMap = {
  A: [20116300, 20006000, 20230500, 20093700, 20017900],
  B: [
    20009600, 20040500, 20075200, 20079300, 20006200, 20132700, 20005700,
    20134900, 20138700, 20054700, 20041600, 20147700, 20005200, 20031200,
    20158200, 20016200, 20008000, 20294500, 20294501, 20040900, 20112300,
  ],
  C: [
    20124100, 20193800, 20191400, 20050500, 20191300, 20156600, 20034500,
    20135000, 20153700, 20103900, 20095200, 20078700, 20108300, 20033700,
    20191600, 20191500, 20101200, 20101200, 20099300, 20085100, 20090500,
    20085800, 20075000, 20172300, 20069700, 20072200, 20106000, 20069800,
  ],
};
