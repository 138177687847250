import React, { useMemo } from 'react';
import { useRouteScreenTitle } from '../../../commons/hooks';
import { TabBar } from '../../../commons/components/TabBar';
import { RepositorioList } from './RepositorioList';
import { useNivelLibbyCall } from '../../../app/business';
import { Nivel } from '../../../app/models';
import { Loading } from '../../../commons/components/Loading';

export const Repositorio = () => {
  useRouteScreenTitle('Repositorio de recursos');

  const { data, working } = useNivelLibbyCall<Nivel[]>({
    methodName: 'getNivelesForRepositorios',
  });

  const tabs = useMemo(() => {
    if (working || !data) {
      return null;
    }
    return data
      .filter((tab) => tab.idNivel !== 0 && tab.idNivel !== 4)
      .map((nivel) => ({
        title: nivel.descripcionNivel,
        component: <RepositorioList idNivel={nivel.idNivel} />,
      }));
  }, [data, working]);

  return !tabs ? <Loading /> : <TabBar content={tabs} />;
};
