import React, { useMemo } from 'react';
import {
  makeStyles,
  Grid,
  IconButton,
  Divider,
  Typography,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { descripcionNewLine, MainButton } from 'src/commons';
import { useSeccionContext } from 'src/app/business';
import { ContenidoBloque } from 'src/app/models';
import { optionsSort } from 'src/utils';
import { useParams } from 'react-router';
import { usePlanificationFormDisciplinarOrientada } from '../../hooks';
import { AnyObject } from '../../../../../../../../../commons/types';
import { RecursosDialog } from '../../../../../dialogs/RecursosDialog';
import {
  FormInput,
  FormInputControlled,
} from '../../../../../../../../../lib/templates/components';
import { HabilidadesDialog } from '../../../../../dialogs/HabilidadesDialog';
import { CommonCheckboxFormInput } from '../../../../../components';
import { InstrumentosEvaluacionDialog } from '../../../../../dialogs/InstrumentosEvaluacionDialog';
import { SeccionesDialog } from '../../../../../dialogs/SeccionesDialog';
import { ContenidosEspecificosDialog } from '../../../../../dialogs/ContenidosEspecificosDialog';
import { grey } from '../../../../../../../../../theme/colors';
import { BloquesDialog } from '../../../../../dialogs';
import { useDisciplinarOrientadaContext } from '../../Context';
import { useGruposSeccion } from '../../../../hooks';

export interface BimestreProps {
  prefix: string;
  name: string;
}

const useStyles = makeStyles({
  container: {
    padding: '32px 10px',
    marginBottom: 60,
    paddingRight: 60,
  },
  containerForm: {
    borderBottom: `1px solid ${grey.normal}`,
    backgroundColor: 'rgba(218, 218, 218, 0.17)',
    padding: 20,
    marginBottom: 15,
    borderRadius: 5,
  },
  listItem: {
    padding: 0,
  },
  listItemIcon: {
    marginRight: '-20px',
  },
  text: {
    color: grey.medium,
  },
  divider: { width: '100%', margin: '20px 0px 15px 0px' },
  title: {
    paddingTop: 10,
  },
  marginDeleteIcon: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    paddingRight: 30,
  },
});

const customStyleButton = { width: '160px' };

export const Bimestre = ({ prefix, name }: BimestreProps) => {
  const { values, form } = usePlanificationFormDisciplinarOrientada();
  const classes = useStyles();
  const { data: secciones } = useSeccionContext();
  const { idEspacioCurricular } = useParams();
  const { transformeGrupos, working } = useGruposSeccion([idEspacioCurricular]);

  const seccionGrupos = [...(secciones || []), ...transformeGrupos];
  const {
    data: { habilidades, recursos, instrumentos, bloques, contenidos },
  } = useDisciplinarOrientadaContext();
  const bloqueValues = values.bloque.bloques;
  const names = bloqueValues.map((bloque: any) => {
    let newValue = {};
    bloques.forEach((bloq: any) => {
      if (bloq.idBloque === bloque) {
        newValue = { ...bloq };
      }
    });
    return newValue;
  });

  const innerValue = values && values[prefix] && values[prefix][name];
  const addNew = () => {
    const newValue = innerValue;
    newValue.push({
      ...innerValue[0],
      recursos: [],
      actividad: '',
      habilidad: [],
      descripcion: '',
      horasCatedra: '',
      contenidosEspecificos: [],
      instrumentosEvaluacion: [],
      seccion: [],
      bloque: bloques
        .filter((bloque: any) => bloque.checked)
        .map((bloque: any) => ({ ...bloque, checked: false })),
    });
    form.change(prefix, {
      [name]: [...newValue],
    });
  };
  const onRemoveItem = (index: any) => {
    const newInput = innerValue.filter(
      (item: any, ind: number) => index !== ind,
    );
    form.change(prefix, {
      [name]: [...newInput],
    });
  };
  const isLastItem = innerValue.length;

  const sortedResources = useMemo(() => optionsSort(recursos), [recursos]);
  const sortedInstruments = useMemo(
    () => optionsSort(instrumentos),
    [instrumentos],
  );

  return (
    <Grid container direction="column" className={classes.container}>
      {!innerValue
        ? null
        : innerValue.map((item: AnyObject, index: number) => {
            return (
              <Grid direction="row" container className={classes.containerForm}>
                <Grid xs={12} direction="row" container>
                  <Grid item xs={8} className={classes.title}>
                    <Typography variant="h3">
                      {innerValue[index].actividad}
                    </Typography>
                  </Grid>

                  <Grid item xs={4} className={classes.marginDeleteIcon}>
                    <Tooltip title="Eliminar Actividad">
                      <IconButton edge="end" aria-label="delete">
                        <DeleteIcon onClick={() => onRemoveItem(index)} />
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid item xs={1}>
                    &nbsp;
                  </Grid>
                </Grid>
                <Grid item xs={12} direction="row" spacing={2} container>
                  <Grid item xs={4}>
                    <CommonCheckboxFormInput
                      label="Bloque"
                      name={`${prefix}.${name}[${index}].bloque`}
                      form={form}
                      placeholder="Seleccioná bloque"
                      dialog={BloquesDialog}
                      options={names}
                      optionIdKey="idBloque"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CommonCheckboxFormInput
                      label="Habilidad"
                      name={`${prefix}.${name}[${index}].habilidad`}
                      form={form}
                      placeholder="Seleccioná habilidad"
                      dialog={HabilidadesDialog}
                      options={habilidades}
                      optionIdKey="idHabilidades"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInputControlled
                      label="Contenidos específicos"
                      name={`${prefix}.${name}[${index}].contenidosEspecificos`}
                      form={form}
                      placeholder="Seleccioná contenidos específicos"
                      getInputValue={(value) => {
                        if (!Array.isArray(value) || !value.length) return '';

                        const selectContenido = contenidos
                          .filter((u) =>
                            value?.find(
                              (e) =>
                                Number(e.id) === Number(u.idContenidoBloque),
                            ),
                          )
                          .map((contenido) => contenido.descripcion);

                        return selectContenido;
                      }}
                      getNewValue={async (oldValue) => {
                        const filterContenidos = contenidos.filter(
                          (contenido: ContenidoBloque) =>
                            item.bloque.find(
                              (blq: string) =>
                                contenido.bloque.idBloque === blq,
                            ),
                        );
                        const _values = filterContenidos.map(
                          (contenido: ContenidoBloque) => {
                            const found = oldValue.find(
                              (old: any) =>
                                old.id === contenido.idContenidoBloque,
                            );
                            return {
                              id: contenido.idContenidoBloque,
                              descripcion: contenido.descripcion,
                              checked: !!found,
                              subLevel: contenido.contenidoBloqueSubnivels.map(
                                (nivel) => ({
                                  id: nivel.idContenidoBloqueSubnivel,
                                  descripcion: descripcionNewLine(
                                    nivel.descripcion,
                                  ),
                                  checked: !found
                                    ? false
                                    : !!found?.subLevel.find(
                                        (level: any) =>
                                          Number(level.id) ===
                                          nivel.idContenidoBloqueSubnivel,
                                      ),
                                  subLevel2:
                                    nivel.contenidoBloqueSubnivelDos.map(
                                      (nivel2) => ({
                                        id: nivel2.idContenidoBloqueSubnivelDos,
                                        descripcion: descripcionNewLine(
                                          nivel2.descripcion,
                                        ),
                                        checked: !found
                                          ? false
                                          : !!found?.subLevel?.find(
                                              (level: {
                                                id: string;
                                                subLevel2: Array<string>;
                                              }) =>
                                                !!level.subLevel2.find(
                                                  (levle2: any) =>
                                                    Number(levle2) ===
                                                    nivel2.idContenidoBloqueSubnivelDos,
                                                ),
                                            ),
                                      }),
                                    ),
                                }),
                              ),
                            };
                          },
                        );
                        const newValue = await ContenidosEspecificosDialog.open(
                          _values || [],
                        );
                        if (!newValue) {
                          return oldValue;
                        }
                        return newValue
                          .filter((_item: any) => _item.checked)
                          .map((_item: any) => ({
                            id: _item.id,
                            subLevel: _item.subLevel
                              .filter((level: any) => level.checked)
                              .map((level: any) => ({
                                id: level.id,
                                subLevel2: level.subLevel2
                                  .filter((level2: any) => level2.checked)
                                  .map((level2: any) => level2.id.toString()),
                              })),
                          }));
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CommonCheckboxFormInput
                      label="Recursos"
                      name={`${prefix}.${name}[${index}].recursos`}
                      form={form}
                      placeholder="Seleccioná recursos"
                      dialog={RecursosDialog}
                      options={sortedResources}
                      optionIdKey="idRecursos"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <CommonCheckboxFormInput
                      label="Instrumentos de evaluación"
                      name={`${prefix}.${name}[${index}].instrumentosEvaluacion`}
                      form={form}
                      placeholder="Seleccioná instrumentos de evaluación"
                      dialog={InstrumentosEvaluacionDialog}
                      options={sortedInstruments}
                      optionIdKey="idInstrumentosDeEvaluacion"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      label="Actividad"
                      name={`${prefix}.${name}[${index}].actividad`}
                      form={form}
                      placeholder="Ingresá nombre de la actividad"
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <FormInput
                      label="Descripción"
                      name={`${prefix}.${name}[${index}].descripcion`}
                      form={form}
                      placeholder="Describí la actividad"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <FormInput
                      label="Horas cátedra"
                      name={`${prefix}.${name}[${index}].horasCatedra`}
                      form={form}
                      placeholder="Ingresá horas"
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <CommonCheckboxFormInput
                      label="Sección"
                      name={`${prefix}.${name}[${index}].seccion`}
                      form={form}
                      placeholder="Seleccioná sección"
                      dialog={SeccionesDialog}
                      options={seccionGrupos || []}
                      optionIdKey="idSeccion"
                      optionLabelKey="nombreSeccion"
                    />
                  </Grid>
                </Grid>
                {isLastItem !== index + 1 && (
                  <Divider className={classes.divider} />
                )}
              </Grid>
            );
          })}
      <MainButton
        title="Agregar Actividad"
        handleOnClick={() => addNew()}
        customStyle={customStyleButton}
      />
    </Grid>
  );
};
