import React from 'react';
import { Grid } from '@material-ui/core';
import { FilterTablesPassManagementTypesProps } from 'src/commons';
import {
  MainInput,
  SimpleSelect,
} from '../../../../../../../../commons/components';

const customStyles = {
  customStyleTitleSelect: { fontSize: 12, color: '#595959', marginBottom: 2 },
};

export const FilterTables = ({
  cycle,
  typeOfPass,
  level,
  formValues,
  handleChange,
  handleChangeSearch,
  search,
}: FilterTablesPassManagementTypesProps) => {
  const { ciclo, nivel, localizacionDestino } = formValues;

  return (
    <>
      <Grid container justify="flex-start" spacing={3} alignItems="center">
        <Grid item xs={4}>
          <SimpleSelect
            name="nivel"
            title="Nivel"
            placeholder="Seleccioná Nivel"
            labelKey="descripcionNivel"
            valueKey="idNivel"
            handleChange={handleChange}
            content={level}
            value={nivel}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
        <Grid item xs={4}>
          <SimpleSelect
            title="Ciclo"
            placeholder="Seleccioná Ciclo"
            labelKey="descripcionCiclo"
            valueKey="idCiclo"
            content={cycle}
            handleChange={handleChange}
            value={ciclo}
            customStyleTitle={customStyles.customStyleTitleSelect}
            name="ciclo"
          />
        </Grid>
        <Grid item xs={4}>
          <SimpleSelect
            name="localizacionDestino"
            title="Tipo de pase"
            placeholder="Seleccioná tipo de pase"
            labelKey="label"
            valueKey="value"
            content={typeOfPass}
            handleChange={handleChange}
            value={localizacionDestino}
            customStyleTitle={customStyles.customStyleTitleSelect}
          />
        </Grid>
      </Grid>
      <Grid
        container
        xs={8}
        justify="flex-end"
        spacing={3}
        alignItems="flex-end"
      >
        <Grid item xs={8}>
          <MainInput
            type="search"
            handleChange={handleChangeSearch}
            placeholder="Buscar"
            name="search"
            value={search}
            fullWidth
            customStyle={{ padding: 9.5, marginBottom: 3 }}
          />
        </Grid>
      </Grid>
    </>
  );
};
