import { useEffect } from 'react';

interface DocumentTitlePrint {
  beforeName: string;
  afterName?: string;
}
/**
 * Setea al nombre del documento cuando este se va a guardar
 *  */
export const useDocumentTitlePrint = ({
  beforeName,
  afterName = 'BA | miEscuela',
}: DocumentTitlePrint) => {
  useEffect(() => {
    // set document title before printing in order to save pdf with name
    window.addEventListener('beforeprint', () => {
      document.title = beforeName || '';
    });

    // set default title after printing
    window.addEventListener('afterprint', () => {
      document.title = afterName;
    });
  }, [beforeName, afterName]);
};
