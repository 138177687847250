import { makeBusinessEntity } from '../../lib/libby/generator';
import { LegajoAlumno } from '../models';

const DAO_NAME = 'legajo_alumno';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<LegajoAlumno>(DAO_NAME);

export {
  fetchById as useLegajoAlumnoFetchById,
  libbyCall as useLegajoAlumnoLibbyCall,
  libbyDAO as useLegajoAlumnoDAO,
  libbyFetch as useLegajoAlumnoLibbyFetch,
  // components/hoc
  entityAutocomplete as LegajoAlumnoAutocomplete,
  renderPropCall as LegajoAlumnoCall,
  renderPropFetchById as LegajoAlumnoById,
  simpleSelect as LegajoAlumnoSelect,
  // context
  // ByIdContext
  useByIdContext as useLegajoAlumnoByIdContext,
  ByIdProvider as LegajoAlumnoByIdProvider,
  ByIdContext as LegajoAlumnoByIdContext,
  // GeneralContext
  useGeneralContext as useLegajoAlumnoContext,
  GeneralProvider as LegajoAlumnoProvider,
  GeneralContext as LegajoAlumnoContext,
};
