import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { InfoTable, AnyObject } from 'src/commons';
import {
  useAutoRegistracionLibbyCall,
  useEspacioCurricularLibbyCall,
} from 'src/app/business';
import { Grid, Checkbox, Typography } from '@material-ui/core';
import { Area, AutoRegistracion, EspacioCurricular } from 'src/app/models';
import { ROL } from 'src/commons/const/rol';
import { useRolesContext } from 'src/context/RolesContext';

import { useSignUpForm } from '../../hooks/useSignUpForm';

const column = [
  {
    id: 'checkbox',
    label: '',
    width: '1%',
    noSort: true,
    hideSortIcon: true,
  },
  {
    id: 'area',
    label: 'Area',
    width: '10%',
    noSort: true,
    hideSortIcon: true,
  },
];

export const Coordinador = () => {
  const { form, values } = useSignUpForm();
  const [roles, setRoles] = useState<AnyObject[]>([]);
  const { userInfo } = useRolesContext();

  const { data: autoregister = [], working: autoWorking } =
    useAutoRegistracionLibbyCall<AutoRegistracion[]>({
      methodName: 'getPendingAndApproveRoles',
      params: [userInfo.id, ROL.COORDINADOR_DE_AREA, values.idLocalizacion],
    });

  const { data: espaciosCurriculares, working } = useEspacioCurricularLibbyCall<
    EspacioCurricular[]
  >({
    methodName: 'getByLocalizacionIdAndByNivel',
    params: [values.idLocalizacion, values.idNivel],
    aspect: 'short',
  });

  const areas = useMemo<Area[]>(() => {
    if (working || !espaciosCurriculares) {
      return [];
    }
    return espaciosCurriculares.reduce<Area[]>((acum, espacio) => {
      if (!acum.find((item) => item.idArea === espacio.materia.area.idArea)) {
        return [...acum, espacio.materia.area];
      }
      return acum;
    }, []);
  }, [espaciosCurriculares, working]);

  const onChange = useCallback(
    (idArea: number) => {
      setRoles((oldRoles) => {
        const position = oldRoles.findIndex(
          (old: any) => old.idArea === idArea,
        );
        if (position !== -1) {
          oldRoles.splice(position, 1);
          return [...oldRoles];
        }
        return [...oldRoles, { idArea, idNivel: +values.idNivel }];
      });
    },
    [values.idNivel],
  );

  useEffect(() => {
    form.change('roles', roles?.length ? [...roles] : null);
  }, [roles, form]);

  const rows = useMemo(
    () =>
      areas?.map((area, index) => {
        const isUsed = !!autoregister.find(
          (item) => item.area.idArea === area.idArea,
        );
        return {
          checkbox: (
            <Checkbox
              key={index}
              disabled={isUsed}
              onChange={() => onChange(area.idArea)}
            />
          ),
          area: (
            <Typography color={isUsed ? 'textSecondary' : 'inherit'}>
              {area.descripcion}
            </Typography>
          ),
        };
      }),
    [areas, autoregister, onChange],
  );

  return (
    <Grid container direction="column">
      <Grid item>
        <InfoTable working={working} rows={rows || []} columns={column || []} />
      </Grid>
    </Grid>
  );
};
