import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useMemo,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import { AlumnoPresente } from '../types';

export type Jornada = 'Completa' | 'Simple' | 'Extendida';
export interface IMultiPresentismoChecksValue {
  setMasiveChecks: Dispatch<SetStateAction<boolean>>;
  masiveChecks: boolean;
  setAlumnos: Dispatch<SetStateAction<AlumnoPresente[]>>;
  alumnos: AlumnoPresente[];
  setTurno: Dispatch<SetStateAction<number>>;
  turno: number;
  jornada: Jornada | null;
  setJornada: Dispatch<SetStateAction<Jornada | null>>;
}

const InitialContextValue: IMultiPresentismoChecksValue = {
  masiveChecks: false,
  setMasiveChecks: () => {},
  alumnos: [],
  setAlumnos: () => {},
  turno: 0,
  setTurno: () => {},
  jornada: null,
  setJornada: () => {},
};

export const MultiPresentismoChecks =
  createContext<IMultiPresentismoChecksValue>(InitialContextValue);

export interface IMultiPresentismoChecksProvider {
  children: ReactNode | ReactNode[];
}

export const MultiPresentismoChecksProvider: FC<
  IMultiPresentismoChecksProvider
> = ({ children }) => {
  const [masiveChecks, setMasiveChecks] = useState<boolean>(false);
  const [alumnos, setAlumnos] = useState<AlumnoPresente[]>([]);
  const [turno, setTurno] = useState<number>(0);
  const [jornada, setJornada] = useState<Jornada | null>(null);

  const value = useMemo(
    () => ({
      masiveChecks,
      setMasiveChecks,
      setAlumnos,
      alumnos,
      turno,
      setTurno,
      jornada,
      setJornada,
    }),
    [
      masiveChecks,
      setMasiveChecks,
      setAlumnos,
      alumnos,
      turno,
      setTurno,
      jornada,
      setJornada,
    ],
  );

  return (
    <MultiPresentismoChecks.Provider value={value}>
      {children}
    </MultiPresentismoChecks.Provider>
  );
};

export const useMultiPresentismoChecks = () =>
  useContext<IMultiPresentismoChecksValue>(MultiPresentismoChecks);
