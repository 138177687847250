import Button from '@material-ui/core/Button';
import React, { useCallback, useMemo } from 'react';
import {
  DiasHabilitadosCall,
  useDiasHabilitadosDAO,
} from '../../../../../../app/business/DiasHabilitados';
import { Loading } from '../../../../../../commons';
import { DiasHabilitados } from '../../../../../../app/models/DiasHabilitados';
import { ModalDiasHabilitados } from './ModalDiasHabilitados';
import customFormDialog from '../../../../../../commons/services/customFormDialog';
import { DeepPartial } from '../../../../../../commons/types/DeepPartial';
import { useSnackbar } from 'notistack';
import { useRolesContext } from '../../../../../../context/RolesContext';
import DiasHabiIcon from '../../../../../../stories/components/DiasHabIcont';
import { makeStyles } from '@material-ui/core';
import { usePresentismoContext } from '../../context/PresentismoContext';
interface BottonDiasHabilitadosPros {
  isEspacioCurricular: boolean;
  data: DiasHabilitados[];
  selectedSeccion?: string;
  selectedEspaciosCurriculares?: string;
  reFetchDiasHabiles: () => void;
}

const useStyles = makeStyles({
  buttonContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  icon: {
    marginTop: '4px',
    marginRight: '8px',
  },
});

const BotonDiasHabilitados = ({
  data,
  selectedSeccion,
  selectedEspaciosCurriculares,
  reFetchDiasHabiles,
}: BottonDiasHabilitadosPros) => {
  const rolesContext = useRolesContext();
  const classes = useStyles();
  const selectedRoleId: string | number = rolesContext.userInfo.id;
  const initialValue = useMemo(() => {
    const { seccion, espacioCurricular, ...rest } = data[0] || {};
    return {
      ...rest,
      seccion: seccion || { idSeccion: selectedSeccion },
      espacioCurricular: espacioCurricular || {
        idEspacioCurricular: selectedEspaciosCurriculares,
      },
    };
  }, [data, selectedSeccion, selectedEspaciosCurriculares]);

  const { enqueueSnackbar } = useSnackbar();
  const diasHabilitadosDAO = useDiasHabilitadosDAO();
  const { periodoNuevo: diasPeriodoNuevo } = usePresentismoContext();

  const saveDaysEnable = useCallback(
    async (daysState, selectedInitialDate, selectedFinalDate) => {
      try {
        const saveDataDay: DeepPartial<DiasHabilitados> = {
          ...initialValue,
          dias: daysState,
          fechaDesde: selectedInitialDate,
          fechaHasta: selectedFinalDate,
          createdBy: Number(selectedRoleId),
        };
        await diasHabilitadosDAO.aspect('default').save(saveDataDay);
        enqueueSnackbar('Habilitaciones guardadas', { variant: 'success' });
        reFetchDiasHabiles();
      } catch (error) {
        console.log(error);
        enqueueSnackbar('No se pudo guardar las habilitaciones', {
          variant: 'error',
        });
      } finally {
        customFormDialog.handleCancel();
      }
    },
    [
      diasHabilitadosDAO,
      initialValue,
      enqueueSnackbar,
      selectedRoleId,
      reFetchDiasHabiles,
    ],
  );

  let diasPeriodoSelected = diasPeriodoNuevo.filter((periodo) => {
    return periodo.tipoPeriodo.idTipoPeriodo === 1;
  });

  const handleClick = useCallback(() => {
    customFormDialog.show({
      title: '', // data[0] ? 'Editar dias' : 'Habilitar Dias',
      renderComponent: (
        <ModalDiasHabilitados
          initialValue={initialValue}
          saveDaysEnable={saveDaysEnable}
          dataDias={data}
          diasPeriodoNuevo={diasPeriodoSelected}
        />
      ),
      sizeWidth: 'sm',
    });
  }, [initialValue, saveDaysEnable, data, diasPeriodoSelected]);
  interface CustomIconWrapperProps {
    className?: string;
  }
  const CustomIconWrapper: React.FC<CustomIconWrapperProps> = ({
    className,
  }) => (
    <div className={className}>
      <DiasHabiIcon />
    </div>
  );
  return (
    <Button
      size="small"
      variant="contained"
      color="primary"
      onClick={handleClick}
      style={{
        textTransform: 'none',
        backgroundColor: '#3786EE',
        fontFamily: 'Nunito',
      }}
    >
      <CustomIconWrapper className={classes.icon}>
        <DiasHabiIcon />
      </CustomIconWrapper>
      {data[0] ? 'Editar dias' : 'Habilitar Dias'}
    </Button>
  );
};

export default BotonDiasHabilitados;

interface HabilitarDiasDocenteBotonProps {
  selectedSeccion?: string;
  selectedEspacioCurricular?: string;
  reFetchDiasHabiles: () => void;
  diasHabilitados: DiasHabilitados[];
}
export const HabilitarDiasDocenteBoton = ({
  selectedEspacioCurricular,
  selectedSeccion,
  reFetchDiasHabiles,
  diasHabilitados,
}: HabilitarDiasDocenteBotonProps) => {
  return (
    <BotonDiasHabilitados
      data={diasHabilitados}
      isEspacioCurricular={Boolean(selectedEspacioCurricular)}
      selectedEspaciosCurriculares={selectedEspacioCurricular}
      selectedSeccion={selectedSeccion}
      reFetchDiasHabiles={reFetchDiasHabiles}
    />
  );
};
