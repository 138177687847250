export const COLORS_PALETTE = [
  '3C1518',
  '69140E',
  'A44200',
  'D58936',
  '130303',
  '902D41',
  '222222',
  '1C5D99',
  '23001E',
  'FFBA49',
  '1D1128',
  '5941A9',
  'F8333C',
  '4E4187',
  '5D2E8C',
  '470024',
  '251101',
  '214E34',
  '102B3F',
  '062726',
];
