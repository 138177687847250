import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ArealPlanAreaDAO extends LibbyFetchDAO {
  constructor() {
    super('areal_plan_area', 'idArealPlanArea');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getById(id: number | string) {
    return this.query().equals('id_areal_plan', id).run();
  }
}
