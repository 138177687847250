import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { FeedbackPlanOpcion } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'feedback_plan_opcion';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idFeedbackPlanOpcion',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idFeedbackPlanOpcion',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.FeedbackPlanOpcion
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.FeedbackPlanOpcion,
      hoc: { ...defaultOptions.hoc, ...businessOptions.FeedbackPlanOpcion.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<FeedbackPlanOpcion>(DAO_NAME, optionsToUse);

// hooks
export const useFeedbackPlanOpcionFetchById = fetchById;
export const useFeedbackPlanOpcionLibbyCall = libbyCall;
export const useFeedbackPlanOpcionDAO = libbyDAO;
export const useFeedbackPlanOpcionLibbyFetch = libbyFetch;

// components/hoc
export const FeedbackPlanOpcionAutocomplete = entityAutocomplete;
export const FeedbackPlanOpcionCall = renderPropCall;
export const FeedbackPlanOpcionById = renderPropFetchById;
export const FeedbackPlanOpcionSelect = simpleSelect;
export const FeedbackPlanOpcionAccordionSelector = accordionSelector;
export const FeedbackPlanOpcionSelectedChips = selectedChips;

// context
// ByIdContext
export const useFeedbackPlanOpcionByIdContext = useByIdContext;
export const FeedbackPlanOpcionByIdProvider = ByIdProvider;
export const FeedbackPlanOpcionByIdContext = ByIdContext;
// GeneralContext
export const useFeedbackPlanOpcionContext = useGeneralContext;
export const FeedbackPlanOpcionProvider = GeneralProvider;
export const FeedbackPlanOpcionContext = GeneralContext;
