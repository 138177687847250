import React, { FunctionComponent } from 'react';
import { InitialSchoolAdults } from './Initial';

export const SchoolAdults: FunctionComponent = () => {
  const rol = 'normal'; // sestose va a cambiar cuando tengamos el manejo de roles
  switch (rol) {
    default:
      return <InitialSchoolAdults />;
  }
};
