import { Box, Grid } from '@material-ui/core';
import React from 'react';
import { StudentDetail } from '../types';

interface JustificacionTitleProps {
  studenDetail: StudentDetail[];
}

export const JustificacionTitle = ({
  studenDetail,
}: JustificacionTitleProps) => {
  return (
    <Grid container justifyContent="flex-start" alignItems="center" spacing={2}>
      <Grid item xs={3} sm={3} md={3}>
        <Box
          component="h2"
          color="#38485C"
          fontFamily="Nunito"
          fontSize={30}
          fontWeight="bold"
        >
          Justificación
        </Box>
      </Grid>
      <Grid item xs={9} sm={9} md={8} container spacing={2}>
        {studenDetail.map((studen, index, all) => {
          return (
            <Box
              key={`justificaciontable-${studen.title}-${index}`}
              display="flex"
              flexDirection="column"
              pr={4}
              pl={2}
              borderRight={
                index < all.length - 1 ? '1px solid #B3C1CF' : undefined
              }
            >
              <Box color="#B3C1CF" fontFamily="Nunito" fontSize={16}>
                {studen.title}
              </Box>
              <Box
                color="#3786EE"
                fontFamily="Nunito"
                fontSize={16}
                fontWeight="bold"
              >
                {studen.description}
              </Box>
            </Box>
          );
        })}
      </Grid>
    </Grid>
  );
};
