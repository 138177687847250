import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Establecimiento } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'establecimiento';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEstablecimiento',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEstablecimiento',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Establecimiento
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Establecimiento,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Establecimiento.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Establecimiento>(DAO_NAME, optionsToUse);

// hooks
export const useEstablecimientoFetchById = fetchById;
export const useEstablecimientoLibbyCall = libbyCall;
export const useEstablecimientoDAO = libbyDAO;
export const useEstablecimientoLibbyFetch = libbyFetch;

// components/hoc
export const EstablecimientoAutocomplete = entityAutocomplete;
export const EstablecimientoCall = renderPropCall;
export const EstablecimientoById = renderPropFetchById;
export const EstablecimientoSelect = simpleSelect;
export const EstablecimientoAccordionSelector = accordionSelector;
export const EstablecimientoSelectedChips = selectedChips;

// context
// ByIdContext
export const useEstablecimientoByIdContext = useByIdContext;
export const EstablecimientoByIdProvider = ByIdProvider;
export const EstablecimientoByIdContext = ByIdContext;
// GeneralContext
export const useEstablecimientoContext = useGeneralContext;
export const EstablecimientoProvider = GeneralProvider;
export const EstablecimientoContext = GeneralContext;
