import React, { useEffect, useState } from 'react';
import { useRouteScreenTitle } from 'src/commons/hooks';
import { CalificacionesPrimarioSwitch } from './components/CalificacionesPrimarioSwitch';
import { CalificacionesSecundario } from './CalificacionesSecundario';
import { useRolesContext } from 'src/context/RolesContext';
import { NIVEL } from 'src/commons/const';
import { CalificacionesSecundarioSwitch } from '../EspaciosCurriculares/EspacioCurricular/Calificaciones/components/CalificacionesSecundarioSwitch';
import { CalificacionesSecundarioTable } from '../EspaciosCurriculares/EspacioCurricular/Calificaciones/CalificacionesSecundario';
import { PermissionBlocker } from 'src/lib/permission/components/PermissionBlocker';
import { BLOCKER_ID } from 'src/platform/permission/const/BlockerId';
import { useColegioEfectivizadoContext } from './context/EfectivizacionProvider';
import { Loading } from 'src/commons';
import { useRouterContext } from 'src/lib/router';

export const Calificaciones = () => {
  useRouteScreenTitle('Calificaciones');
  const {
    selectedRole: { nivel },
  } = useRolesContext();
  const [periodoSecundario, setPeriodoSecundario] = useState('');
  const { establecimientoEfectivizado, waiting } =
    useColegioEfectivizadoContext();

  const { setMetadata } = useRouterContext();

  useEffect(() => {
    setMetadata((prev) => {
      delete prev.fromTab;
      return { ...prev };
    });
  }, [setMetadata]);

  switch (Number(nivel)) {
    case NIVEL.PRIMARIO:
      return waiting ? (
        <Loading />
      ) : (
        <PermissionBlocker id={BLOCKER_ID.CALIFICATIONS}>
          <CalificacionesPrimarioSwitch />
        </PermissionBlocker>
      );
    case NIVEL.SECUNDARIO:
      return waiting ? (
        <Loading />
      ) : (
        <PermissionBlocker id={BLOCKER_ID.CALIFICATIONS}>
          {!periodoSecundario ? (
            <CalificacionesSecundarioSwitch
              setPeriodoSecundario={setPeriodoSecundario}
            />
          ) : periodoSecundario === '1' && !establecimientoEfectivizado ? (
            <CalificacionesSecundarioTable />
          ) : (
            <CalificacionesSecundario periodo={periodoSecundario} />
          )}
        </PermissionBlocker>
      );
    default:
      return null;
  }
};
