import moment from 'moment';
import { AlumnoMap } from '../types';
import { ContraturnoDia } from 'src/app/models/presentismo_v3/ContraturnoDia';

const capitalizePhrase = (phrase: string) => {
  const phraseCapitalized = phrase
    .toLowerCase()
    .split(' ')
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(' ');
  return phraseCapitalized;
};

export const usePresentismoExcelData_v3 = (
  rows: AlumnoMap[] = [],
  name: string,
  day: string,
  tipoJornada: string,
  contraturnoDias: ContraturnoDia[],
  localizacion: string,
) => {
  const fechaDay = moment(day).toDate();
  const diaSemana = fechaDay.toLocaleDateString('es-ES', { weekday: 'long' });
  let diaSemanaCapitalizado =
    diaSemana.charAt(0).toUpperCase() + diaSemana.slice(1);

  if (diaSemanaCapitalizado === 'Miércoles') {
    diaSemanaCapitalizado = diaSemanaCapitalizado.replace(
      'Miércoles',
      'Miercoles',
    );
  }

  const contraturnosFilter = contraturnoDias.filter(
    (contraturno) =>
      contraturno.dia.nombre === diaSemanaCapitalizado &&
      contraturno.active === true &&
      contraturno.contraturno.active === true,
  );

  const styleAusenteJustificado = {
    font: {
      color: '#00BFFF',
      name: 'Arial',
      size: 12,
      bold: true,
    },
  };

  // const columnHeaders = ['Nro Orden', 'Estudiante', 'Presentismo', 'Modalidad'];
  const rowsForExcel = rows.map((row, index: number) => {
    const estudianteApellidoCapitalized = capitalizePhrase(
      row?.alumno.persona.apellido,
    );
    const estudianteNombreCapitalized = capitalizePhrase(
      row?.alumno.persona.nombre,
    );

    const descripcionPosible = [
      'Ausente',
      'Ausente con presencia en clase',
      'Retiro anticipado',
      'Ingreso Tardío',
    ];
    if (tipoJornada === 'Completa') {
      const presentismoDelDiaTurnoManana = row.presentismo?.find(
        (presentismoObject) => {
          return (
            presentismoObject.fecha === day &&
            presentismoObject.turno?.idTurno === 1
          );
        },
      );
      const presentismoDelDiaTurnoTarde = row.presentismo?.find(
        (presentismoObject) => {
          return (
            presentismoObject.fecha === day &&
            presentismoObject.turno?.idTurno === 2
          );
        },
      );
      return {
        'Nro Orden': (index + 1).toString(),
        Estudiante: `${estudianteApellidoCapitalized}, ${estudianteNombreCapitalized}`,
        PresentismoTM:
          descripcionPosible.includes(
            presentismoDelDiaTurnoManana?.estadoPresente?.descripcion || '',
          ) && presentismoDelDiaTurnoManana?.justificacion !== null
            ? {
                text: presentismoDelDiaTurnoManana?.estadoPresente?.descripcion,
                style: styleAusenteJustificado,
              }
            : presentismoDelDiaTurnoManana?.estadoPresente?.descripcion ?? ' ',
        PresentismoTT:
          descripcionPosible.includes(
            presentismoDelDiaTurnoTarde?.estadoPresente?.descripcion || '',
          ) && presentismoDelDiaTurnoTarde?.justificacion !== null
            ? {
                text: presentismoDelDiaTurnoTarde?.estadoPresente?.descripcion,
                style: styleAusenteJustificado,
              }
            : presentismoDelDiaTurnoTarde?.estadoPresente?.descripcion ?? ' ',
      };
    } else {
      const presentismoDelDia = row.presentismo?.find((presentismoObject) => {
        return (
          presentismoObject.fecha === day &&
          presentismoObject.contraturnoDia === null
        );
      });
      const precentismoCt1 = row.presentismo?.filter(
        (presente) =>
          presente.contraturnoDia != null &&
          presente.contraturnoDia.idContraturnoDia ===
            contraturnosFilter[0]?.idContraturnoDia,
      );
      const precentismoCt2 = row.presentismo?.filter(
        (presente) =>
          presente.contraturnoDia != null &&
          presente.contraturnoDia.idContraturnoDia ===
            contraturnosFilter[1]?.idContraturnoDia,
      );
      return {
        'Nro Orden': (index + 1).toString(),
        Estudiante: `${estudianteApellidoCapitalized}, ${estudianteNombreCapitalized}`,
        Presentismo:
          descripcionPosible.includes(
            presentismoDelDia?.estadoPresente?.descripcion || '',
          ) && presentismoDelDia?.justificacion !== null
            ? {
                text: presentismoDelDia?.estadoPresente?.descripcion,
                style: styleAusenteJustificado,
              }
            : presentismoDelDia?.estadoPresente?.descripcion ?? ' ',
        CT1:
          descripcionPosible.includes(
            precentismoCt1[0]?.estadoPresente?.descripcion || '',
          ) && precentismoCt1[0]?.justificacion !== null
            ? {
                text: precentismoCt1[0]?.estadoPresente?.descripcion,
                style: styleAusenteJustificado,
              }
            : precentismoCt1[0]?.estadoPresente?.descripcion ?? ' ',
        CT2:
          descripcionPosible.includes(
            precentismoCt2[0]?.estadoPresente?.descripcion || '',
          ) && precentismoCt2[0]?.justificacion !== null
            ? {
                text: precentismoCt2[0]?.estadoPresente?.descripcion,
                style: styleAusenteJustificado,
              }
            : precentismoCt2[0]?.estadoPresente?.descripcion ?? ' ',
      };
    }
  });

  const customColumnHeaderJornadaSimple = {
    'Nro Orden': 'Nro. Orden',
    Estudiante: 'Estudiante',
    Presentismo: 'Presentismo',
    ...(contraturnosFilter?.length === 1 ? { CT1: 'CT1' } : {}),
    ...(contraturnosFilter?.length === 2 ? { CT1: 'CT1', CT2: 'CT2' } : {}),
  };

  const customColumnHeaderJornadaCompleta = {
    'Nro Orden': 'Nro. Orden',
    Estudiante: 'Estudiante',
    PresentismoTM: 'Presentismo Turno Mañana',
    PresentismoTT: 'Presentismo Turno Tarde',
  };
  const fechaFormatted = `${moment(day).format('dddd DD')} de ${moment(
    day,
  ).format('MMMM')}`;
  // Cada objeto representa una línea en el excel
  const excelHeaders = [
    { localizacion: localizacion },
    { lineaVacia: '' },
    { fecha: capitalizePhrase(fechaFormatted) },
    { seccion: `${rows[0]?.seccion?.nombreSeccion}` },
    {
      turno:
        tipoJornada === 'Completa'
          ? 'TM y TT'
          : `Turno ${rows[0]?.seccion?.turno?.descripcionTurno}`,
      jornada: `Jornada ${rows[0]?.seccion?.jornada}`,
    },
    { lineaVacia: '' },
    tipoJornada === 'Completa'
      ? customColumnHeaderJornadaCompleta
      : customColumnHeaderJornadaSimple,
  ];
  const formatedRowsForExcel = [...excelHeaders, ...rowsForExcel];

  return {
    body: {
      // columns: columnHeaders,
      data: formatedRowsForExcel,
    },
    name,
  };
};
