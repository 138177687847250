import React, { useMemo } from 'react';
import { useRouteScreenTitle } from 'src/commons/hooks/useRouteScreenTitle';
import { NIVEL } from 'src/commons/const';
import { PlanificacionTable } from './Planificacion/PlanificacionTable';
import { TabBar } from '../../../../commons/components/TabBar';
import { useSecundariaFuturoLibbyFetch } from '../../../../app/business';
import { ActividadesTable } from './Actividades/ActividadesTable';
import { AulasVirtualesTable } from './AulasVirtuales/AulasVirtualesTable';
import { TABBAR_BLOCKER_ID } from '../../../../platform/permission/const';
import { AulasVirtualesGrupoTable } from './AulasVirtualesGrupos';
import { useRolesContext } from 'src/context/RolesContext';
import { Calificaciones } from './Calificaciones/Calificaciones';
import { TomaAsistenciaTabWrapper } from './TomaAsistenciaTab/TomaAsistenciaTabWrapper';
import { ROL } from '../../../../../src/commons/const';
import { useECContext } from './components/ECProvider';
import { useParams } from 'react-router';
import { RouteParamsEC } from '../../Acap/types';

export const EspacioCurricularTab = () => {
  const {
    selectedRole: { nivel },
  } = useRolesContext();
  const { ec: espacioCurricular, working: ecWorking } = useECContext();

  useRouteScreenTitle(
    `Espacios Curriculares${
      !espacioCurricular ? '' : ` (${espacioCurricular.descripcion})`
    }`,
  );
  const anio = useParams<RouteParamsEC>().idAnio;

  const establecimiento =
    espacioCurricular?.localizacion.establecimiento?.idEstablecimiento;
  const {
    selectedRole: { rol },
  } = useRolesContext();
  const filterParams = useMemo(
    () => ({
      0: [
        {
          path: 'anio',
          value: anio,
        },
      ],
      1: [{ path: 'establecimiento', value: establecimiento }],
    }),
    [anio, establecimiento],
  );
  const { data: secundariF = [], working: wSecundariaF } =
    useSecundariaFuturoLibbyFetch({
      filter: filterParams,
      enabled: !!anio && !!establecimiento,
    });
  const arrTabBar = useMemo(() => {
    const tabs: any[] = [];

    if (
      Number(nivel) === NIVEL.SECUNDARIO &&
      (Number(rol) === ROL.DOCENTE ||
        Number(rol) === ROL.DIRECTIVO ||
        Number(rol) === ROL.EQUIPO_CONDUCCION ||
        Number(rol) === ROL.PRECEPTOR)
    ) {
      const tomaAsistencia = {
        id: TABBAR_BLOCKER_ID.ESPACIO_CURRICULAR_TOMAR_ASISTENCIA,
        title: 'Toma de Asistencia',
        component: <TomaAsistenciaTabWrapper />,
      };

      tabs.splice(0, 0, tomaAsistencia);
    }

    if (Number(rol) !== ROL.DOCENTE) {
      const aulasVirtuales = {
        id: TABBAR_BLOCKER_ID.ESPACIO_CURRICULAR_AULAS_VIRTUALES_TAB,
        title: 'Aulas Virtuales',
        component: (
          <AulasVirtualesTable
            espacioCurricular={espacioCurricular}
            anio={anio}
          />
        ),
      };
      const AulasVirtualesGrupos = {
        id: TABBAR_BLOCKER_ID.ESPACIO_CURRICULAR_AULAS_VIRTUALES_GRUPOS_TAB,
        title: 'Aulas Virtuales (Grupos)',
        component: (
          <AulasVirtualesGrupoTable espacioCurricular={espacioCurricular} />
        ),
      };
      tabs.splice(tabs.length, 0, aulasVirtuales, AulasVirtualesGrupos);
    }

    if (Number(nivel) === NIVEL.SECUNDARIO) {
      const calificaciones = {
        id: TABBAR_BLOCKER_ID.ESPACIO_CURRICULAR_CALIFICACIONES_TAB,
        title: 'Calificaciones',
        component: <Calificaciones />,
      };
      tabs.splice(tabs.length, 0, calificaciones);
    }
    if (secundariF?.length && Number(rol) !== ROL.DOCENTE) {
      const planificaciones = {
        id: TABBAR_BLOCKER_ID.ESPACIO_CURRICULAR_PLANIFICACION_TAB,
        title: 'Planificación',
        component: <PlanificacionTable />,
      };
      const actividades = {
        id: TABBAR_BLOCKER_ID.ESPACIO_CURRICULAR_ACTIVIDADES_TAB,
        title: 'Actividades',
        component: <ActividadesTable />,
      };
      tabs.splice(tabs.length, 0, planificaciones, actividades);
    }

    const tabsFiltered = tabs.filter(
      (excluido) =>
        excluido.title !== 'Planificación' &&
        excluido.title !== 'Actividades' &&
        excluido.title !== 'Aulas Virtuales (Grupos)',
    );
    return tabsFiltered;
  }, [nivel, secundariF, anio, espacioCurricular, rol]);

  const show = useMemo(
    () => !ecWorking && !wSecundariaF && !!secundariF && !!espacioCurricular,
    [ecWorking, wSecundariaF, secundariF, espacioCurricular],
  );

  return <>{show && <TabBar content={arrTabBar} />}</>;
};
