export enum AutoevaluacionTabEnum {
  PERFILES = 'perfiles',
  HABILIDADES = 'habilidades',
  CONTENIDOS = 'contenidos',
}

export interface DevolucionEvaluacion {
  idDevolucionValor: number;
  idDevolucion: number;
  idTipo: number;
  tab: AutoevaluacionTabEnum;
  evaluacion: number;
}
