import React, { useEffect, useState } from 'react';
import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  Theme,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { grey } from 'src/theme/colors';
import {
  useFeedbackPlanSeccionLibbyCall,
  useFeedbackPlanOpcionLibbyCall,
} from '../../../../../../../../../app/business';
import {
  FeedbackPlanSeccion,
  FeedbackPlanOpcion,
  FeedbackPlanItem,
} from '../../../../../../../../../app/models';
import {
  FormAutocomplete,
  FormInput,
} from '../../../../../../../../../lib/templates/components';
import { useFormContext } from '../../../../../../../../../lib/templates/context';
import { FeedbackItemCard } from '../FeedbackItemCard';
import { FeedbackSeccionCard } from '../FeedbackSeccionCard';
import { FeedbackTemplate } from '../../../../../../../../../commons/types';

const useStyles = makeStyles((theme: Theme) => ({
  headerTitle: {
    '&& .MuiCardHeader-title': {
      color: grey.textPrimary,
      fontSize: 24,
      paddingTop: theme.spacing(2),
    },
  },
  cardContent: {
    paddingTop: 0,
  },
  FormInput: {
    paddingTop: 7,
  },
}));

interface FeedbackTemplateWithPos extends FeedbackTemplate {
  position: number;
}

interface FeedBackFormProps {
  edit: boolean;
}

type NormalizedValues = {
  [k: number]: FeedbackTemplateWithPos;
};

const normalize = (values: FeedbackTemplate[]) =>
  values.reduce<NormalizedValues>(
    (acum, item, index) => ({
      ...acum,
      [item.item]: { ...item, position: index },
    }),
    {},
  );

export const FeedbackForm = ({ edit }: FeedBackFormProps) => {
  const classes = useStyles();
  const { form } = useFormContext();
  const [normalizedValues, setNormalizedValues] = useState<NormalizedValues>(
    {},
  );
  const { data: secciones } = useFeedbackPlanSeccionLibbyCall<
    FeedbackPlanSeccion[]
  >({
    methodName: 'getActiveItems',
  });
  const { data: opciones } = useFeedbackPlanOpcionLibbyCall<
    FeedbackPlanOpcion[]
  >({
    methodName: 'findAll',
  });
  let itemCounter = 0;

  useEffect(() => {
    if (secciones) {
      const allItems = secciones
        .reduce<FeedbackPlanItem[]>(
          (acum, seccion) => [...acum, ...seccion.items],
          [],
        )
        .map<FeedbackTemplate>((item) => ({ item: item.idFeedbackPlanItem }));
      if (edit) {
        // @ts-ignore
        form.change('feedback', allItems);
      }
      setNormalizedValues(normalize(allItems));
    }
  }, [secciones, form, edit]);

  return (
    <Card>
      <CardHeader title="Feedback" className={classes.headerTitle} />
      <CardContent className={classes.cardContent}>
        {secciones &&
          opciones &&
          !!Object.keys(normalizedValues).length &&
          secciones.map((seccion: FeedbackPlanSeccion, index: number) => (
            <Card key={seccion.idFeedbackPlanSeccion}>
              <FeedbackSeccionCard
                key={seccion.idFeedbackPlanSeccion}
                descripcion={seccion.descripcion}
                posicion={`${index + 1}/${secciones.length}`}
              >
                {seccion.items.map((item) => {
                  itemCounter += 1;
                  const { position } =
                    normalizedValues[item.idFeedbackPlanItem];
                  return (
                    <FeedbackItemCard
                      key={item.idFeedbackPlanItem}
                      descripcion={`${itemCounter}. ${item.descripcion}`}
                      opcion={
                        <FormAutocomplete
                          label=""
                          placeholder="Seleccioná"
                          name={`feedback[${position}].opcion`}
                          form={form}
                          options={opciones}
                          optionLabelKey="descripcion"
                          valueToOption={(
                            value: number,
                            options: FeedbackPlanOpcion[],
                          ) =>
                            options.find(
                              (_item) => _item.idFeedbackPlanOpcion === value,
                            )
                          }
                          optionToValue={(option: FeedbackPlanOpcion) =>
                            option.idFeedbackPlanOpcion
                          }
                        />
                      }
                      comentario={
                        <Grid className={classes.FormInput}>
                          <FormInput
                            label=""
                            name={`feedback[${position}].comentario`}
                            form={form}
                            placeholder="Comentarios"
                          />
                        </Grid>
                      }
                    />
                  );
                })}
              </FeedbackSeccionCard>
              <Divider />
            </Card>
          ))}
      </CardContent>
    </Card>
  );
};
