import { useEffect } from 'react';

export const useDebounceCall = (
  cb: () => any,
  timeout: number,
  deps: any[],
) => {
  useEffect(() => {
    const debounce = setTimeout(() => {
      cb();
    }, timeout);
    return () => clearTimeout(debounce);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
