import React, { useCallback } from 'react';
import { Grid, IconButton } from '@material-ui/core';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { makeStyles } from '@material-ui/styles';
import { primary } from 'src/theme/colors';
import { FormApi } from 'final-form';
import { useField } from 'react-final-form-hooks';
import customFormDialog from 'src/commons/services/customFormDialog';
import { ObservacionesModal } from '../ObservacionesModal';
import EditIcon from '@material-ui/icons/Edit';

const useStyles = makeStyles((theme) => ({
  iconObservacion: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'end',
    color: primary.lightBlue,
  },
  observaciones: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '50px',
    marginTop: '10px',
    textAlign: 'right',
    color: 'black',
    paddingBottom: 10,
  },
  disabled: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'end',
    color: primary.dark,
    marginLeft: '5px',
  },
}));

export const PenTextField = ({
  form,
  name,
  disabled,
}: {
  form: FormApi;
  name: string;
  disabled: boolean;
}) => {
  const classes = useStyles();
  const field = useField(name, form);

  const handleObservaciones = useCallback(() => {
    customFormDialog.show({
      title: 'Observaciones',
      renderComponent: (
        <ObservacionesModal
          content={field.input.value}
          onAddContent={
            (content: any) => field.input.onChange(content)
            // (form as FormApi).change('trayectoria.observaciones', content)
          }
          value={field.input.value}
        />
      ),
      sizeWidth: 'sm',
    });
  }, [field.input]);

  return (
    <Grid>
      {Boolean(field.input.value) ? (
        <>
          <Grid className={classes.iconObservacion}>
            <div className={classes.observaciones}>{field.input.value}</div>
            <IconButton
              className={disabled ? classes.disabled : classes.iconObservacion}
              disabled={disabled}
              onClick={() => handleObservaciones()}
            >
              {<LibraryBooksIcon />}
            </IconButton>
          </Grid>
        </>
      ) : (
        <Grid className={classes.iconObservacion}>
          <IconButton disabled={disabled} onClick={() => handleObservaciones()}>
            {
              <EditIcon
                style={{
                  color: disabled ? primary.disabled : primary.lightBlue,
                }}
              />
            }
          </IconButton>
        </Grid>
      )}
    </Grid>
  );
};
