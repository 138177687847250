import React, { useMemo } from 'react';
import { ESTADO_PRESENTE, InfoTable } from '../../../../../commons';
import {
  useAlertasGetRequest,
  useEspacioCurricularSeccionLibbyFetch,
  usePresentismoEspacioCurricularLibbyFetch,
} from '../../../../../app/business';
import { AlertasECV3, AlumnoMap } from '../../TomaPresentismo/types';
import { PeriodoNuevo } from '../../../../../app/models';
import moment from 'moment';
import { Grid, Typography } from '@material-ui/core';
import { SumatoriaEstados } from '../types';
import { usePresenteECColumsTable } from '../hooks/usePresenteECColumsTable';

interface PresenteEspacioCurricularTableProps {
  movimiento: AlumnoMap;
  selectedPeriodo?: PeriodoNuevo;
}

export const PresenteEspacioCurricularTable = ({
  movimiento,
  selectedPeriodo,
}: PresenteEspacioCurricularTableProps) => {
  const filterEspacioCurricularSeccion = useMemo(
    () => ({
      0: [
        {
          path: 'seccion.idSeccion',
          value: movimiento.seccion.idSeccion,
        },
      ],
    }),
    [movimiento],
  );

  const {
    data: espacioCurricularSeccion = [],
    working: workingEspacioCurricularSeccion,
  } = useEspacioCurricularSeccionLibbyFetch({
    filter: filterEspacioCurricularSeccion,
    orderBy: 'espacioCurricular.descripcion',
    direction: 'asc',
  });

  const filterPresentismo = useMemo(
    () => ({
      seccion: [
        {
          path: 'seccion.idSeccion',
          value: movimiento?.seccion.idSeccion,
        },
      ],
      alumnos: [
        {
          path: 'alumno',
          value: movimiento?.alumno.idAlumno,
        },
      ],
    }),
    [movimiento],
  );
  const {
    data: presentismoEspacioCurricular = [],
    working: workingPresentismoEspacioCurricular,
  } = usePresentismoEspacioCurricularLibbyFetch({
    aspect: 'basic',
    filter: filterPresentismo,
  });

  const idsECs = useMemo(
    () =>
      espacioCurricularSeccion.map(
        (ec) => ec.espacioCurricular.idEspacioCurricular,
      ),
    [espacioCurricularSeccion],
  );

  const fechaDesde = moment(selectedPeriodo?.fechaInicio).format('YYYY-MM-DD');
  const fechaHasta = moment(selectedPeriodo?.fechaFin).format('YYYY-MM-DD');

  const { data: alertasv3 = {}, working: alertasv3Working } =
    useAlertasGetRequest<AlertasECV3>({
      url: `/api/presentismov3/alertas?seccion=${movimiento?.seccion?.idSeccion}&alumnos=${movimiento?.alumno?.idAlumno}&fecha_desde=${fechaDesde}&fecha_hasta=${fechaHasta}&espacio=${idsECs}`,
      autoCall: Boolean(selectedPeriodo) && espacioCurricularSeccion.length > 0,
    });

  const loading = useMemo(
    () =>
      workingEspacioCurricularSeccion ||
      alertasv3Working ||
      workingPresentismoEspacioCurricular,
    [
      workingEspacioCurricularSeccion,
      workingPresentismoEspacioCurricular,
      alertasv3Working,
    ],
  );

  const sumatoriaEstados = useMemo<SumatoriaEstados>(() => {
    const _suma: SumatoriaEstados = {};
    if (!Boolean(selectedPeriodo)) return _suma;
    presentismoEspacioCurricular.forEach((item) => {
      const espacioCurricularId = item?.espacioCurricular?.idEspacioCurricular;

      const estadoId = item?.estadoPresente?.idEstadoPresente;
      const periodoNuevoId = item?.periodoNuevo?.idPeriodoNuevo;

      if (selectedPeriodo?.idPeriodoNuevo !== periodoNuevoId) {
        return;
      }

      if (!_suma[espacioCurricularId]) {
        _suma[espacioCurricularId] = {
          presente: 0,
          ausente: 0,
          ausenteConPresenciaEnClase: 0,
          noCorresponde: 0,
          alerta: null,
        };
      }

      if (estadoId === ESTADO_PRESENTE.PRESENTE) {
        _suma[espacioCurricularId].presente++;
      } else if (estadoId === ESTADO_PRESENTE.AUSENTE) {
        _suma[espacioCurricularId].ausente++;
      } else if (estadoId === ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE) {
        _suma[espacioCurricularId].ausenteConPresenciaEnClase++;
      } else if (estadoId === ESTADO_PRESENTE.NO_CORRESPONDE) {
        _suma[espacioCurricularId].noCorresponde++;
      }
    });
    return _suma;
  }, [presentismoEspacioCurricular, selectedPeriodo]);

  const { columns } = usePresenteECColumsTable({
    sumatoriaEstados,
    alertas: alertasv3,
    movimiento,
  });

  return (
    <Grid container>
      <Grid xs={12}>
        <Typography
          variant="h1"
          color="textPrimary"
          style={{
            color: '#38485C',
            fontFamily: 'Nunito',
            fontSize: 24,
            fontWeight: 'bold',
          }}
        >
          Asistencia por espacio curricular
        </Typography>
      </Grid>

      <Grid xs={12}>
        <InfoTable
          columns={columns}
          rows={espacioCurricularSeccion}
          working={loading}
        />
      </Grid>
    </Grid>
  );
};
