import React from 'react';
import { SelectWithField, SimpleSelect } from 'src/commons';
import { useMemo } from 'react';
import { Button, makeStyles } from '@material-ui/core';
import { Pps } from 'src/app/models';
import { usePPSFormContext } from '../PPSFormProvider';
import { FormApi } from 'final-form';
import { FileField } from 'src/commons/components/FinalForm/FileField';
import { FieldRenderProps } from 'react-final-form-hooks';

const useStyles = makeStyles({
  adjunto: {
    border: 'none',
    borderRadius: 10,
    padding: 10,
    backgroundColor: 'rgb(240,240,240)',
    display: 'flex',
    justifyContent: 'space-between',
    cursor: 'pointer',
    width: '100px',
  },
  adjuntoClose: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '0 7px',
    fontWeight: 'bold',
  },
  disabledInput: {
    backgroundColor: '#DADADA',
  },
  adjuntoNombre: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  hide: {
    display: 'none',
  },
});

export const useIntervencionesEOEColumns = (
  adjuntoHandleChange: (
    e: React.ChangeEvent<HTMLInputElement>,
    field: FieldRenderProps,
  ) => void,
  ppsDataAlumno: Pps,
) => {
  const { form, pps } = usePPSFormContext();
  const values = (form as FormApi).getState().values;
  const classes = useStyles();
  const columns = useMemo(() => {
    const SiNoOptions = [{ label: 'Sí' }, { label: 'No' }];

    const isOpen = ppsDataAlumno !== undefined ? ppsDataAlumno?.abierto : true;
    const isDisabled = !isOpen;

    const handleDeleteInforme = () => {
      if (!isDisabled) {
        (form as FormApi).change('intervencionesAdjunto', {
          files: '',
          name: '',
        });
        (form as FormApi).change('intervenciones.informe', {});
      }
    };

    return [
      {
        id: 'derivacion',
        label: '¿Hubo derivación al EOE o de equipos interdisciplinarios?',
        width: '5%',
        hideSortIcon: true,
        render: () => (
          <SelectWithField
            form={form}
            name="intervenciones.derivacion"
            placeholder="Seleccioná una opción"
            labelKey="label"
            valueKey="label"
            content={SiNoOptions}
            disabled={isDisabled}
          />
        ),
      },
      {
        id: 'emptyspace',
        label: '',
        width: '10%',
        hideSortIcon: true,
        render: () => <></>,
      },
      {
        id: 'informe',
        label: 'Adjuntar informe',
        hideSortIcon: true,
        width: '5%',
        render: () => (
          <>
            {Boolean(values?.intervencionesAdjunto?.name) ? (
              <div
                className={classes.adjunto}
                onClick={() => {
                  if (!isDisabled) handleDeleteInforme();
                }}
              >
                <div className={classes.adjuntoNombre}>
                  {values?.intervencionesAdjunto?.name}
                </div>
                <div className={classes.adjuntoClose}>x</div>
              </div>
            ) : null}
            <Button
              variant="contained"
              component="label"
              color="primary"
              fullWidth
              classes={{
                root: Boolean(values?.intervencionesAdjunto?.name)
                  ? classes.hide
                  : '',
              }}
              disabled={
                isDisabled || values?.intervenciones?.derivacion !== 'Sí'
              }
            >
              Adjuntar
              <FileField
                form={form}
                type="file"
                accept="application/pdf"
                name="intervencionesAdjunto"
                hidden
                changeHandler={adjuntoHandleChange}
              />
            </Button>
          </>
        ),
      },
      {
        id: 'emptyspace',
        label: '',
        width: '10%',
        hideSortIcon: true,
        render: () => <></>,
      },
    ];
  }, [
    adjuntoHandleChange,
    classes.adjunto,
    classes.adjuntoClose,
    classes.adjuntoNombre,
    classes.hide,
    form,
    ppsDataAlumno,
    values?.intervenciones?.derivacion,
    values?.intervencionesAdjunto?.name,
  ]);

  return columns;
};
