import React, { useState, useMemo } from 'react';
import { uniqWith, uniqBy } from 'lodash';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { InfoTable } from 'src/commons/components';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import { AnyObject, LibbyObject } from 'src/commons';
import { primary } from 'src/theme/colors';
import { useAlumnoMovimientoLibbyFetch } from 'src/app/business';

const headerStyles = makeStyles(() => ({
  checkBox: {
    color: primary.lightBlue,
    paddingRight: '20px',
    width: '0px',
    height: '0px',
  },
  rowLabel: {
    cursor: 'pointer',
  },
}));

const TableSelectStudentRaw = ({
  libby,
  handleSelectStudent,
  selectStudent,
  filterById,
  formValues,
  cicloLectivo,
}: LibbyObject) => {
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('alumno.persona.nombre');

  const cicloLectivoActual = Number(cicloLectivo?.idCicloLectivo);

  const filterTableManagement = useMemo(
    () => ({
      filter: [
        { path: formValues.path, value: formValues.params, method: 'includes' },
      ],
      cicloLectivo: [
        { path: 'cicloLectivo', value: cicloLectivoActual },
        { path: 'cicloLectivo', value: cicloLectivoActual + 1 },
      ],
      ...filterById,
    }),
    [formValues, filterById, cicloLectivoActual],
  );

  const { data: studentRegistrationData = [], working } =
    useAlumnoMovimientoLibbyFetch({
      orderBy,
      direction,
      limit: 100,
      aspect: 'pass_management',
      filter: filterTableManagement,
    });

  const studentRegistrationByLatestId = studentRegistrationData.sort((a, b) =>
    Number(a.idAlumnoMovimiento) < Number(b.idAlumnoMovimiento) ? 1 : -1,
  );

  const studentRegistration = uniqBy(
    studentRegistrationByLatestId,
    (elem) => elem.alumno.idAlumno,
  );

  const classes = headerStyles();

  const column = [
    {
      id: 'name',
      label: 'Nombre y Apellido',
      width: '25%',
      orderById: 'alumno.persona.nombre',
    },
    {
      id: 'document',
      label: 'Documento',
      width: '2%',
      orderById: 'alumno.persona.documento',
    },
    {
      id: 'establishment',
      label: 'Establecimiento de origen',
      width: '20%',
      orderById: 'seccion.localizacion.establecimiento.nombre',
    },
    {
      id: 'anio',
      label: 'Año',
      width: '2%',
      orderById: 'seccion.anio.descripcionAnio',
    },
  ];

  const usePassRequestTableLogic = () => {
    const rows = studentRegistration.map(
      (
        {
          idAlumnoMovimiento,
          alumno: {
            idAlumno,
            persona: { documento, nombre, apellido },
          },
          seccion: {
            localizacion: { descripcion },
          },
          seccion: { anio },
        }: AnyObject,
        index: number,
      ) => ({
        id: <label htmlFor={idAlumnoMovimiento}> idAlumnoMovimiento </label>,
        name: (
          <Grid container alignItems="center">
            <Grid item>
              <Radio
                id={idAlumnoMovimiento}
                className={classes.checkBox}
                color="default"
                name="radio-button-demo[]"
                value={selectStudent}
                checked={selectStudent?.alumno?.idAlumno === idAlumno}
                onChange={() => handleSelectStudent(studentRegistration[index])}
              />
            </Grid>
            <Grid item style={{ maxWidth: '200px' }}>
              <label
                className={classes.rowLabel}
                htmlFor={idAlumnoMovimiento}
              >{`${nombre} ${apellido}`}</label>
            </Grid>
          </Grid>
        ),
        // document: documento,
        document: (
          <label className={classes.rowLabel} htmlFor={idAlumnoMovimiento}>
            {documento}
          </label>
        ),
        establishment: (
          <label className={classes.rowLabel} htmlFor={idAlumnoMovimiento}>
            {descripcion}
          </label>
        ),
        anio: (
          <label className={classes.rowLabel} htmlFor={idAlumnoMovimiento}>
            {anio?.descripcionAnio}
          </label>
        ),
      }),
    );
    return {
      rows,
    };
  };
  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  const usePassRequest = usePassRequestTableLogic();

  return (
    <InfoTable
      working={working}
      rows={usePassRequest.rows}
      columns={column}
      orderBy={orderBy}
      direction={direction}
      onSortChange={handleRequestSort}
      rowIdKey="id"
    />
  );
};

export const TableSelectStudent = DatabaseConnector(TableSelectStudentRaw)();
