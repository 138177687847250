import { AnyObject } from '../../../../../../../commons/types';
import { useFormContext } from '../../../../../../../lib/templates/context';

// TODO: complete this
export interface ArealInterarealForm {
  informacionGeneral: AnyObject;
  espacioCurricular: AnyObject;
  actividad: AnyObject;
  bibliografia: AnyObject;
  proyecto: AnyObject;
  objetivosAprendizaje: AnyObject;
  indicadoresDeEvaluacion: AnyObject;
}

export const useArealInterArealForm = () =>
  useFormContext<ArealInterarealForm>();
