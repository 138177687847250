import { LibbyFetchDAO } from './LibbyFetchDAO';

export class GruposCicloLectivoDAO extends LibbyFetchDAO {
  constructor() {
    super('grupos_ciclo_lectivo', 'idGrupoCicloLectivo');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
