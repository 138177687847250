import { Seccion, Turno } from 'src/app/models';
import { MAPPED_PASE_ANIO_OPTIONS_PRIMARIO } from './mappedPaseAnioOptions';

const _1_GRADO = 1;
const _2_GRADO = 2;
const _3_GRADO = 3;
const _4_GRADO = 4;
const _5_GRADO = 5;
const _6_GRADO = 6;
const _7_GRADO = 7;
const _4_Y_5_GRADO = 8;
const _6_Y_7_GRADO = 9;

const {
  Promociona: PROMOCIONA,
  'Promoción directa': PROMOCION_DIRECTA,
  Egresa: EGRESA,
  Permanece: PERMANECE_CON_ACOMPANAMIENTO,
  'Promoción en proceso': PROMOCION_EN_PROCESO,
  'Promoción acompañada': PROMOCION_ACOMPANADA,
  'Completa estudios': COMPLETA_ESTUDIOS,
  'Continúa con apoyo para la promoción': CONTINUA_CON_APOYO_PARA_PROMOCION,
  'Con promoción acompañada': PROMOCIONA_CON_PROMOCION_ACOMPANADA,
  'No completó el Plan de Estudio': NO_COMPLETO_PLAN_DE_ESTUDIOS,
} = MAPPED_PASE_ANIO_OPTIONS_PRIMARIO;

const MAPA_OPCIONES_DE_SECCION_A_LA_QUE_PASA: {
  [key: number]: { [key: number]: number[] };
} = {};

[
  _1_GRADO,
  _2_GRADO,
  _3_GRADO,
  _4_GRADO,
  _5_GRADO,
  _6_GRADO,
  _7_GRADO,
  _4_Y_5_GRADO,
  _6_Y_7_GRADO,
].forEach((grado) => {
  MAPA_OPCIONES_DE_SECCION_A_LA_QUE_PASA[grado] = {
    [PROMOCION_DIRECTA.id]: [
      ...(grado === _4_Y_5_GRADO ? [_5_GRADO, _4_Y_5_GRADO] : []),
      ...(grado === _4_GRADO ? [_5_GRADO, _4_Y_5_GRADO] : []),
      ...(grado === _5_GRADO ? [_6_Y_7_GRADO, _6_GRADO] : []),
      ...(grado === _6_GRADO ? [_6_Y_7_GRADO, _7_GRADO] : []),
      ...(grado === _6_Y_7_GRADO ? [_6_Y_7_GRADO, _7_GRADO] : []),
      ...(grado === _7_GRADO ? [] : []),
      ...(![_4_Y_5_GRADO, _6_Y_7_GRADO, _7_GRADO].includes(grado)
        ? [grado + 1]
        : []),
    ],
    [PROMOCIONA.id]: [
      ...(grado === _4_Y_5_GRADO ? [_4_Y_5_GRADO, _5_GRADO] : []),
      ...(grado === _6_Y_7_GRADO ? [_6_Y_7_GRADO, _7_GRADO] : []),
      ...(grado === _7_GRADO ? [] : []),
      ...(![_4_Y_5_GRADO, _6_Y_7_GRADO, _7_GRADO].includes(grado)
        ? [grado + 1]
        : []),
    ],
    [PERMANECE_CON_ACOMPANAMIENTO.id]: [
      ...(grado === _4_Y_5_GRADO ? [_4_Y_5_GRADO, _5_GRADO, _4_GRADO] : []),
      ...(grado === _6_Y_7_GRADO ? [_6_Y_7_GRADO, _7_GRADO, _6_GRADO] : []),
      ...(grado === _7_GRADO ? [_6_Y_7_GRADO, _7_GRADO] : []),
      ...(![_4_Y_5_GRADO].includes(grado) ? [grado] : []),
    ],
    [EGRESA.id]: [],
    [PROMOCION_EN_PROCESO.id]: [],
    [PROMOCION_ACOMPANADA.id]: [],
    [COMPLETA_ESTUDIOS.id]: [],
    [CONTINUA_CON_APOYO_PARA_PROMOCION.id]: [],
    [PROMOCIONA_CON_PROMOCION_ACOMPANADA.id]: [
      ...(grado === _4_Y_5_GRADO ? [_4_Y_5_GRADO, _5_GRADO] : []),
      ...(grado === _6_Y_7_GRADO ? [_6_Y_7_GRADO, _7_GRADO] : []),
      ...(grado === _7_GRADO ? [] : []),
      ...(![_4_Y_5_GRADO, _6_Y_7_GRADO, _7_GRADO].includes(grado)
        ? [grado + 1]
        : []),
    ],
    [NO_COMPLETO_PLAN_DE_ESTUDIOS.id]: [],
  };
});

export const getFilterPassSectionPrimariaLogic = (
  options: Seccion[],
  statusPass: number,
  currentCicloLectivo: number,
  idAnio?: number,
  turno?: Turno,
) => {
  const siguienteCicloLectivo = currentCicloLectivo + 1;
  const filteredOptionsPrimaria = Boolean(idAnio)
    ? options
        .filter((option: Seccion) => {
          return (
            MAPA_OPCIONES_DE_SECCION_A_LA_QUE_PASA[Number(idAnio)][
              statusPass
            ]?.includes(Number(option?.anio?.idAnio)) &&
            option?.cicloLectivo?.anio === siguienteCicloLectivo
          );
        })
        .sort((a: Seccion, b: Seccion) => {
          if (turno && a.turno?.idTurno === turno.idTurno) {
            return -1;
          } else if (turno && b.turno?.idTurno === turno.idTurno) {
            return 1;
          } else {
            return 0;
          }
        })
    : [];

  return filteredOptionsPrimaria;
};
