import { capitalize } from 'src/utils';
import { NIVEL } from 'src/commons/const';
import { getFilterPassSection } from './getFilterPassSection';
import {
  MAPPED_PASE_ANIO_OPTIONS_PRIMARIO,
  MAPPED_PASE_ANIO_OPTIONS_SECUNDARIO,
} from './mappedPaseAnioOptions';
import { PreviasProyecciones, Seccion } from 'src/app/models';
import { useSeccionLibbyFetch } from 'src/app/business';

export const buildRows = ({
  flattenStudentMovements,
  flattenYearPassPromotions,
  currentSection,
  nextSection,
  // materiasPendientes = [],
  nivel,
  seccionesFromFetch = [],
  previasProyeccionesData = [],
  isTecnica,
}: {
  flattenStudentMovements: any;
  flattenYearPassPromotions: any;
  currentSection: any;
  nextSection: any;
  // materiasPendientes: any;
  nivel: string | number;
  seccionesFromFetch: Seccion[];
  previasProyeccionesData: PreviasProyecciones[];
  isTecnica: boolean;
  isNocturna: boolean;
  localizacionId: string | number;
}) => {
  const localizacionExcepciones = [
    '20048700',
    '20056300',
    '20062300',
    '20171700',
    '20063700',
    '20085900',
    '20094400',
    '20111700',
    '20113700',
    '20125000',
    '20126700',
    '20142300',
    '20160600',
    '20107900',
    '20039000',
    '20235100',
    '20234700',
    '20234800',
    '20234900',
    '20235000',
    '20235200',
    '20240000',
    '20240700',
    '20203300',
    '20203800',
    '20203900',
    '20242200',
    '20111300',
    '20141200',
    '20066500'
  ];

  const options = [...seccionesFromFetch];
  const students = flattenStudentMovements
    .map((studentMovement: any) => {
      return {
        ...studentMovement,
        ...flattenYearPassPromotions.find(
          (ypm: any) => ypm.alumno.idAlumno === studentMovement.alumno.idAlumno,
        ),
      };
    })
    .map((element: any) => {
      const {
        'Promoción directa': PROMOCION_DIRECTA,
        Egresa: EGRESA,
        'Continúa con apoyo para la promoción':
          CONTINUA_CON_APOYO_PARA_PROMOCION,
      } = MAPPED_PASE_ANIO_OPTIONS_PRIMARIO;

      const {
        Egresa: COMPLETO_PLAN_DE_ESTUDIOS,
        'Promoción en proceso': PROMOCION_EN_PROCESO,
        'No completó el Plan de Estudio': NO_COMPLETO_PLAN_DE_ESTUDIOS,
        'Con promoción acompañada': PROMOCION_CON_APOYO,
      } = MAPPED_PASE_ANIO_OPTIONS_SECUNDARIO;
      let seccionPass;
      let seccionPassSecundaria;
      let statusPass: number;
      let pendientesAlumno: PreviasProyecciones = {
        idPreviasProyecciones: null,
        alumno: element?.alumno.idAlumno,
        previas: 0,
        pendientes: 0,
        cicloLectivo: element?.cicloLectivo,
      };
      const currentCicloLectivo = element?.cicloLectivo?.anio;
      const cicloLectivoActual = element?.cicloLectivo;
      const numeroAnio = element?.numeroAnio
        ? element?.numeroAnio
        : element?.seccion?.anio?.numeroAnio;
      const descripcionAnioSection = element?.nombreSeccion;
      const idAnio = element?.anio?.idAnio;
      if (Number(nivel) === NIVEL.INICIAL) {
        statusPass = element?.estadoPaseAnio?.idEstadoPaseAnio ?? 1;
        // Si tiene seccion destino y articula, entonces fue articulado
        if (element?.seccionDestino !== null && element?.articula) {
          seccionPass = 'Articulado';

          // Si no tiene seccion destino, no tiene una proyeccion y se encontro la siguiente seccion, se muestra esta
        } else if (
          !Boolean(element?.seccionDestino) &&
          nextSection !== undefined &&
          nextSection?.idSeccion !== '' &&
          !element?.idProyeccion
        ) {
          seccionPass = nextSection?.idSeccion;

          // Si tiene seccion destino, se muestra esta
        } else if (
          Boolean(element?.seccionDestino) &&
          element?.seccionDestino?.idSeccion
        ) {
          seccionPass = element?.seccionDestino?.idSeccion;

          // Si articula, se muestra Articula
        } else if (element.articula) {
          seccionPass = 'Articula';

          // Si egresa, la seccion destino es null y no articula, entonces se muestra: no concurrira
        } else if (
          element?.estadoPaseAnio?.idEstadoPaseAnio === 3 ||
          currentSection === 3 ||
          (element?.seccionDestino === null && element.articula === false)
        ) {
          if (
            element?.estadoPaseAnio?.idEstadoPaseAnio === 3 ||
            currentSection === 3
          ) {
            statusPass = 3;
            seccionPass = '-';
          } else seccionPass = 'No Concurrirá';
        }
      } else {
        statusPass = element?.estadoPaseAnio?.idEstadoPaseAnio;
        if (!statusPass) {
          statusPass =
            element.anio.idAnio !== 7 ? PROMOCION_DIRECTA.id : EGRESA.id;
        }
        if (Number(nivel) === NIVEL.SECUNDARIO) {
          const pendientesAlumnoFounded = previasProyeccionesData.find(
            (previa: any) =>
              element?.alumno?.idAlumno === previa?.alumno.idAlumno,
          );
          pendientesAlumno = {
            ...pendientesAlumno,
            ...(pendientesAlumnoFounded
              ? {
                  idPreviasProyecciones:
                    pendientesAlumnoFounded.idPreviasProyecciones,
                  pendientes: pendientesAlumnoFounded.pendientes,
                  previas: pendientesAlumnoFounded.previas,
                  cicloLectivo: cicloLectivoActual,
                }
              : {}),
          };

          if (
            (element?.localizacion?.idLocalizacion === '20091100' ||
              element?.localizacion?.idLocalizacion === '20108500') &&
            element?.planEstudioNivel?.idPlanEstudioNivel === 197 &&
            [
              '80849',
              '80852',
              '80854',
              '70222',
              '70237',
              '96181',
              '96166',
              '86431',
              '86429',
              '86427',
            ].includes(element?.idSeccion)
          ) {
            //Escuela con plan estudio bachiller y tecnico
            if (pendientesAlumno.pendientes > 0) {
              statusPass = 9;
              seccionPassSecundaria = '-';
            } else {
              statusPass = EGRESA.id;
              seccionPassSecundaria = '-';
            }
          }

          //Tipo CBO

          if (
            element?.localizacion?.idLocalizacion === '20203300' ||
            element?.localizacion?.idLocalizacion === '20203800' ||
            element?.localizacion?.idLocalizacion === '20203900' ||
            element?.localizacion?.idLocalizacion === '20242200'
          ) {
            if (element?.anio?.idAnio === 23) {
              if (
                element?.estadoPaseAnio?.idEstadoPaseAnio === 9 ||
                pendientesAlumno.pendientes > 0
              ) {
                statusPass = 9;
                seccionPassSecundaria = '-';
              } else {
                statusPass = 3;
                seccionPassSecundaria = '-';
              }
            }
          }

          //TIPO 1
          if (
            element?.localizacion?.idLocalizacion === '20048700' ||
            element?.localizacion?.idLocalizacion === '20062300' ||
            element?.localizacion?.idLocalizacion === '20063700' ||
            element?.localizacion?.idLocalizacion === '20085900' ||
            element?.localizacion?.idLocalizacion === '20094400' ||
            element?.localizacion?.idLocalizacion === '20113700' ||
            element?.localizacion?.idLocalizacion === '20125000' ||
            element?.localizacion?.idLocalizacion === '20126700' ||
            element?.localizacion?.idLocalizacion === '20056300'
          ) {
            if (
              element?.anio?.idAnio === 24 &&
              (element?.turno?.idTurno === 1 || element?.turno?.idTurno === 2)
            ) {
              if (element?.estadoPaseAnio?.idEstadoPaseAnio === 8) {
                statusPass = 8;
                seccionPassSecundaria = nextSection?.idSeccion;
              } else if (element?.estadoPaseAnio?.idEstadoPaseAnio === 2) {
                statusPass = 2;
                seccionPassSecundaria = nextSection?.idSeccion;
              } else if (element?.estadoPaseAnio?.idEstadoPaseAnio === 4) {
                statusPass = 4;
                seccionPassSecundaria = nextSection?.idSeccion;
              } else {
                statusPass = PROMOCION_DIRECTA.id;
                seccionPassSecundaria = nextSection?.idSeccion;
              }
            } else if (
              element?.anio?.idAnio === 24 &&
              (element?.turno?.idTurno === 3 || element?.turno?.idTurno === 4)
            ) {
              if (element?.estadoPaseAnio?.idEstadoPaseAnio === 9) {
                statusPass = 9;
                seccionPassSecundaria = '-';
              } else {
                statusPass = EGRESA.id;
                seccionPassSecundaria = '-';
              }
            }
            if (
              element?.anio?.idAnio === 25 &&
              (element?.turno?.idTurno === 1 || element?.turno?.idTurno === 2)
            ) {
              if (element?.estadoPaseAnio?.idEstadoPaseAnio === 9) {
                statusPass = 9;
                seccionPassSecundaria = '-';
              } else {
                statusPass = EGRESA.id;
                seccionPassSecundaria = '-';
              }
            }
          }
          //Tipo 2
          if (element?.localizacion?.idLocalizacion === '20171700') {
            if (element?.anio?.idAnio === 24) {
              statusPass = PROMOCION_DIRECTA.id;
              seccionPassSecundaria = nextSection?.idSeccion;
            }
            if (element?.anio?.idAnio === 25) {
              if (element?.estadoPaseAnio?.idEstadoPaseAnio === 9) {
                statusPass = 9;
                seccionPassSecundaria = '-';
              } else {
                statusPass = EGRESA.id;
                seccionPassSecundaria = '-';
              }
            }
          }

          //Tipo 3
          if (
            element?.localizacion?.idLocalizacion === '20111700' ||
            element?.localizacion?.idLocalizacion === '20142300' ||
            element?.localizacion?.idLocalizacion === '20160600' ||
            element?.localizacion?.idLocalizacion === '20039000' ||
            element?.localizacion?.idLocalizacion === '20111300' ||
            element?.localizacion?.idLocalizacion === '20066500' ||
            element?.localizacion?.idLocalizacion === '20141200'
          ) {
            if (
              element?.anio?.idAnio === 24 &&
              (element?.turno?.idTurno === 3 || element?.turno?.idTurno === 4)
            ) {
              if (element?.estadoPaseAnio?.idEstadoPaseAnio === 9) {
                statusPass = 9;
                seccionPassSecundaria = '-';
              } else {
                statusPass = EGRESA.id;
                seccionPassSecundaria = '-';
              }
            }
          }

          //Tipo 4
          if (element?.localizacion?.idLocalizacion === '20107900') {
            if (element?.anio?.idAnio === 24) {
              if (element?.estadoPaseAnio?.idEstadoPaseAnio === 8) {
                statusPass = 8;
                seccionPassSecundaria = nextSection?.idSeccion;
              } else if (element?.estadoPaseAnio?.idEstadoPaseAnio === 2) {
                statusPass = 2;
                seccionPassSecundaria = nextSection?.idSeccion;
              } else if (element?.estadoPaseAnio?.idEstadoPaseAnio === 4) {
                statusPass = 4;
                seccionPassSecundaria = nextSection?.idSeccion;
              } else {
                statusPass = PROMOCION_DIRECTA.id;
                seccionPassSecundaria = nextSection?.idSeccion;
              }
            }
            if (element?.anio?.idAnio === 25) {
              statusPass = PROMOCION_DIRECTA.id;
              seccionPassSecundaria = nextSection?.idSeccion;
            }
            if (element?.anio?.idAnio === 26) {
              if (element?.estadoPaseAnio?.idEstadoPaseAnio === 9) {
                statusPass = 9;
                seccionPassSecundaria = '-';
              } else {
                statusPass = EGRESA.id;
                seccionPassSecundaria = '-';
              }
            }
          }

          //TIPO 5
          if (
            element?.localizacion?.idLocalizacion === '20235100' ||
            element?.localizacion?.idLocalizacion === '20234700' ||
            element?.localizacion?.idLocalizacion === '20234800' ||
            element?.localizacion?.idLocalizacion === '20234900' ||
            element?.localizacion?.idLocalizacion === '20235200' ||
            element?.localizacion?.idLocalizacion === '20240000' ||
            element?.localizacion?.idLocalizacion === '20240700'
          ) {
            if (element?.anio?.idAnio === 24) {
              if (element?.estadoPaseAnio?.idEstadoPaseAnio === 9) {
                statusPass = 9;
                seccionPassSecundaria = '-';
              } else {
                statusPass = EGRESA.id;
                seccionPassSecundaria = '-';
              }
            }
          }

          //RAWSON
          if (element?.localizacion?.idLocalizacion === '20038300') {
            if (
              element?.anio?.idAnio === 24 &&
              element.planEstudioNivel?.planEstudio?.idPlanEstudio !== 316 &&
              element.planEstudioNivel?.planEstudio?.idPlanEstudio !== 320
            ) {
              if (element?.estadoPaseAnio?.idEstadoPaseAnio === 8) {
                statusPass = 8;
                seccionPassSecundaria = nextSection?.idSeccion;
              } else if (element?.estadoPaseAnio?.idEstadoPaseAnio === 2) {
                statusPass = 2;
                seccionPassSecundaria = nextSection?.idSeccion;
              } else if (element?.estadoPaseAnio?.idEstadoPaseAnio === 4) {
                statusPass = 4;
                seccionPassSecundaria = nextSection?.idSeccion;
              } else {
                statusPass = PROMOCION_DIRECTA.id;
                seccionPassSecundaria = nextSection?.idSeccion;
              }
            } else if (
              element?.anio?.idAnio === 24 &&
              (element.planEstudioNivel?.planEstudio?.idPlanEstudio === 316 ||
                element.planEstudioNivel?.planEstudio?.idPlanEstudio === 320)
            ) {
              if (pendientesAlumno.pendientes > 0) {
                statusPass = 9;
                seccionPassSecundaria = '-';
              } else {
                statusPass = EGRESA.id;
                seccionPassSecundaria = '-';
              }
            }
            if (
              element?.anio?.idAnio === 25 &&
              element.planEstudioNivel?.planEstudio?.idPlanEstudio !== 316 &&
              element.planEstudioNivel?.planEstudio?.idPlanEstudio !== 320
            ) {
              if (pendientesAlumno.pendientes > 0) {
                statusPass = 9;
                seccionPassSecundaria = '-';
              } else {
                statusPass = EGRESA.id;
                seccionPassSecundaria = '-';
              }
            }
          }

          //HIBRIDO
          if (element?.localizacion?.idLocalizacion === '20235000') {
            if (
              element?.anio?.idAnio === 24 &&
              element.planEstudioNivel?.planEstudio?.idPlanEstudio === 218
            ) {
              if (element?.estadoPaseAnio?.idEstadoPaseAnio === 8) {
                statusPass = 8;
                seccionPassSecundaria = nextSection?.idSeccion;
              } else if (element?.estadoPaseAnio?.idEstadoPaseAnio === 2) {
                statusPass = 2;
                seccionPassSecundaria = nextSection?.idSeccion;
              } else if (element?.estadoPaseAnio?.idEstadoPaseAnio === 4) {
                statusPass = 4;
                seccionPassSecundaria = nextSection?.idSeccion;
              } else {
                statusPass = PROMOCION_DIRECTA.id;
                seccionPassSecundaria = nextSection?.idSeccion;
              }
            } else if (
              element?.anio?.idAnio === 24 &&
              element.planEstudioNivel?.planEstudio?.idPlanEstudio !== 218 &&
              element.planEstudioNivel?.planEstudio?.idPlanEstudio !== 217
            ) {
              if (element?.estadoPaseAnio?.idEstadoPaseAnio === 9) {
                statusPass = 9;
                seccionPassSecundaria = '-';
              } else {
                statusPass = EGRESA.id;
                seccionPassSecundaria = '-';
              }
            }
            if (
              element?.anio?.idAnio === 25 &&
              (element.planEstudioNivel?.planEstudio?.idPlanEstudio === 218 ||
                element.planEstudioNivel?.planEstudio?.idPlanEstudio === 217)
            ) {
              if (pendientesAlumno.pendientes > 0) {
                statusPass = 9;
                seccionPassSecundaria = '-';
              } else {
                statusPass = EGRESA.id;
                seccionPassSecundaria = '-';
              }
            }
          }

          //TECNICAS
          if (
            isTecnica &&
            !localizacionExcepciones.includes(
              element?.localizacion?.idLocalizacion,
            ) &&
            element?.planEstudioNivel?.idPlanEstudioNivel !== 197 &&
            ![
              '80849',
              '80852',
              '80854',
              '70222',
              '70237',
              '96181',
              '96166',
              '86431',
              '86429',
              '86427',
            ].includes(element?.idSeccion)
          ) {
            if (
              pendientesAlumno.pendientes > 2 &&
              element?.anio?.idAnio === 21
            ) {
              statusPass = PROMOCION_CON_APOYO.id;
            }
            if (
              pendientesAlumno.pendientes > 2 &&
              (element?.anio?.idAnio === 22 ||
                element?.anio?.idAnio === 23 ||
                element?.anio?.idAnio === 24 ||
                element?.anio?.idAnio === 25) &&
              element?.estadoPaseAnio?.idEstadoPaseAnio === 8
            ) {
              statusPass = PROMOCION_CON_APOYO.id;
            } else if (
              pendientesAlumno.pendientes > 2 &&
              (element?.anio?.idAnio === 22 ||
                element?.anio?.idAnio === 23 ||
                element?.anio?.idAnio === 24 ||
                element?.anio?.idAnio === 25) &&
              element?.estadoPaseAnio?.idEstadoPaseAnio === 2
            ) {
              statusPass = 2;
            } else if (
              pendientesAlumno.pendientes > 2 &&
              (element?.anio?.idAnio === 22 ||
                element?.anio?.idAnio === 23 ||
                element?.anio?.idAnio === 24 ||
                element?.anio?.idAnio === 25) &&
              (element?.estadoPaseAnio?.idEstadoPaseAnio !== 8 ||
                element?.estadoPaseAnio?.idEstadoPaseAnio !== 4)
            ) {
              statusPass = PROMOCION_EN_PROCESO.id;
            }
            if (element?.anio?.idAnio === 25) {
              if (element?.estadoPaseAnio?.idEstadoPaseAnio === 8) {
                statusPass = 8;
                seccionPassSecundaria = nextSection?.idSeccion;
              } else if (element?.estadoPaseAnio?.idEstadoPaseAnio === 2) {
                statusPass = 2;
                seccionPassSecundaria = nextSection?.idSeccion;
              } else if (element?.estadoPaseAnio?.idEstadoPaseAnio === 4) {
                statusPass = 4;
                seccionPassSecundaria = nextSection?.idSeccion;
              } else {
                statusPass = PROMOCION_DIRECTA.id;
                seccionPassSecundaria = nextSection?.idSeccion;
              }
            }
            if (element?.anio?.idAnio === 26) {
              if (element?.estadoPaseAnio?.idEstadoPaseAnio === 9) {
                statusPass = 9;
                seccionPassSecundaria = '-';
              } else {
                statusPass = EGRESA.id;
                seccionPassSecundaria = '-';
              }
            }
          }
          if (
            !isTecnica &&
            (element?.localizacion?.idLocalizacion === '20007900' ||
              !localizacionExcepciones.includes(
                element?.localizacion?.idLocalizacion,
              )) &&
            element.planEstudioNivel?.planEstudio?.idPlanEstudio !== 316 &&
            element.planEstudioNivel?.planEstudio?.idPlanEstudio !== 320
          ) {
            if (
              pendientesAlumno.pendientes > 2 &&
              element?.anio?.idAnio === 21
            ) {
              statusPass = PROMOCION_CON_APOYO.id;
            }
            if (
              pendientesAlumno.pendientes > 2 &&
              (element?.anio?.idAnio === 22 ||
                element?.anio?.idAnio === 23 ||
                element?.anio?.idAnio === 24) &&
              element?.estadoPaseAnio?.idEstadoPaseAnio === 8
            ) {
              statusPass = PROMOCION_CON_APOYO.id;
            } else if (
              pendientesAlumno.pendientes > 2 &&
              (element?.anio?.idAnio === 22 ||
                element?.anio?.idAnio === 23 ||
                element?.anio?.idAnio === 24) &&
              element?.estadoPaseAnio?.idEstadoPaseAnio === 2
            ) {
              statusPass = 2;
            } else if (
              pendientesAlumno.pendientes > 2 &&
              (element?.anio?.idAnio === 22 ||
                element?.anio?.idAnio === 23 ||
                element?.anio?.idAnio === 24) &&
              (element?.estadoPaseAnio?.idEstadoPaseAnio !== 8 ||
                element?.estadoPaseAnio?.idEstadoPaseAnio !== 4)
            ) {
              statusPass = PROMOCION_EN_PROCESO.id;
            }
            if (element?.anio?.idAnio === 25) {
              if (pendientesAlumno.pendientes > 0) {
                statusPass = 9;
                seccionPassSecundaria = '-';
              } else {
                statusPass = EGRESA.id;
                seccionPassSecundaria = '-';
              }
            }
          }
          if (statusPass === PROMOCION_EN_PROCESO.id) {
            statusPass = PROMOCION_EN_PROCESO.id;
            seccionPassSecundaria = '-';
          }
        }

        if (element.idProyeccion && element.seccionDestino) {
          seccionPass = element?.seccionDestino?.idSeccion;
        } else if (!element.idProyeccion) {
          const { filteredOptions } = getFilterPassSection(
            options,
            statusPass,
            currentCicloLectivo,
            numeroAnio,
            nivel,
            descripcionAnioSection,
            idAnio,
            element?.turno,
          );
          if (element?.seccionDestino?.idSeccion) {
            seccionPass = element?.seccionDestino?.idSeccion;
          } else {
            seccionPass = filteredOptions[0]?.idSeccion ?? '-';
          }
        } else {
          if (
            [
              PROMOCION_EN_PROCESO.id,
              CONTINUA_CON_APOYO_PARA_PROMOCION.id,
            ].includes(statusPass)
          ) {
            seccionPass = '-';
          } else if (
            [
              EGRESA.id,
              COMPLETO_PLAN_DE_ESTUDIOS.id,
              NO_COMPLETO_PLAN_DE_ESTUDIOS.id,
            ].includes(statusPass)
          ) {
            seccionPass = '-';
          } else {
            const { filteredOptions } = getFilterPassSection(
              options,
              statusPass,
              currentCicloLectivo,
              numeroAnio,
              nivel,
              descripcionAnioSection,
              idAnio,
              element?.turno,
            );

            seccionPass =
              filteredOptions[0]?.idSeccion &&
              element.estadoProyeccionMotivo === null
                ? filteredOptions[0]?.idSeccion
                : 'No Concurrirá';
          }
        }
      }

      return {
        id: element?.idAlumnoMovimiento,
        surnameName: capitalize(
          `${element?.persona?.apellido}, ${element?.persona?.nombre}`.toLowerCase(),
        ),
        document: element?.persona?.documento,
        condition: element?.alumno?.condicion?.descripcionCondicion,
        currentYear: element?.anio,
        currentSection: element?.nombreSeccion,
        statusPass: statusPass,
        idStudent: element?.alumno?.idAlumno,
        idSection: element?.idSeccion,
        sectionPass: seccionPass ? seccionPass : seccionPassSecundaria,
        idProyeccion: element?.idProyeccion,
        idAnio: element?.anio?.idAnio,
        cicloLectivo: element?.cicloLectivo?.anio,
        idCicloLectivo: element?.cicloLectivo?.idCicloLectivo,
        materiasPendientes: pendientesAlumno,
        mesesConcurridos: element?.mesesConcurridos,
        estadoProyeccionMotivo: element?.estadoProyeccionMotivo
          ?.idEstadoProyeccionMotivo
          ? element?.estadoProyeccionMotivo?.idEstadoProyeccionMotivo
          : null,
        observacion: element?.observacion,
        turno: element?.turno,
      };
    });

  return students;
};
