import React, { useContext, useState } from 'react';
import { useParams } from 'react-router';

export type CalificacionesFilter = {
  anio: string;
  periodo: number;
  seccion: string;
  espacioCurricular: string;
  ecs: any;
  jemateria: string | null;
  search: string;
};

const CalificacionesContext = React.createContext<{
  filter: CalificacionesFilter;
  setFilter: React.Dispatch<React.SetStateAction<CalificacionesFilter>>;
}>({
  filter: {
    anio: '',
    periodo: 0,
    seccion: '',
    espacioCurricular: '',
    ecs: '',
    jemateria: null,
    search: '',
  },
  setFilter: () => {},
});

export const useCalificacionesContext = () => useContext(CalificacionesContext);

const PERIODOS_MAPPING = {
  '1': 2,
  '2': 4,
  '3': 12,
  '4': 13,
};

export const CalificacionesProvider = ({ children }: any) => {
  const { periodo } = useParams<{ periodo: '1' | '2' | '3' | '4' }>();
  const idPeriodo = PERIODOS_MAPPING[periodo];

  const [filter, setFilter] = useState<CalificacionesFilter>({
    anio: '',
    periodo: idPeriodo,
    seccion: '',
    espacioCurricular: '',
    ecs: [],
    jemateria: null,
  });
  return (
    <CalificacionesContext.Provider value={{ filter, setFilter }}>
      {children}
    </CalificacionesContext.Provider>
  );
};
