import { Container, Grid } from '@material-ui/core';
import React, { useMemo, useCallback } from 'react';
import { Loading, useBackButton, useRouteScreenTitle } from 'src/commons';
import { SwitchList } from './components';
import { Dependencia, ISwitchItem, InitialValue } from './interface/SwitchItem';
import {
  useConfirmacionMatriculaLibbyCall,
  useConfirmacionMatriculaDAO,
} from '../../../../app/business/ConfiguracionMatricula';
import { ConfirmacionMatricula } from '../../../../app/models';
import { LocalizacionesActivas } from './components/LocalizacionesActivas';
import { useDependenciaFuncionalNivelLibbyCall } from '../../../../app/business/DependenciaFuncionalNivel';
import { orderBy } from 'lodash';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { useCicloLectivoLibbyCall } from 'src/app/business';

export const ConfiguracionMatricula = () => {
  useBackButton('/private/configurationadmin');
  useRouteScreenTitle('Confirmar Matrícula');
  const confirmacionMatriculaDAO = useConfirmacionMatriculaDAO();
  const { enqueueSnackbar } = useSnackbar();

  const { data: dependenciaNivels = [], working: dNWorking } =
    useDependenciaFuncionalNivelLibbyCall({
      methodName: 'getAll',
    });

  const {
    data: confirmacionMatriculas = [],
    working,
    recall,
  } = useConfirmacionMatriculaLibbyCall({
    methodName: 'getAll',
  });

  const { data: cicloLectivo = [] } = useCicloLectivoLibbyCall({
    methodName: 'findAll',
  });

  const fechaActual = moment();
  const anioActual = fechaActual.year();

  let cicloLectivoActual = cicloLectivo.find((ciclo) => {
    return ciclo.anio === anioActual;
  });

  const mapped = useMemo(() => {
    const initialvalue: InitialValue = {};
    let periodo: Partial<ConfirmacionMatricula> = {
      fechaDesde: moment().format('YYYY-MM-DD'),
      fechaHasta: moment().format('YYYY-MM-DD'),
    };

    const _periodo = confirmacionMatriculas.find(
      (c) =>
        c.confMatCriterio.criterio.idCriterio === 2 &&
        c.cicloLectivo?.idCicloLectivo === cicloLectivoActual?.idCicloLectivo,
    ); // PERIODO

    if (_periodo) {
      periodo = {
        ..._periodo,
        fechaDesde: _periodo.fechaDesde,
        fechaHasta: _periodo.fechaHasta,
      };
    }

    const _mapped = dependenciaNivels.reduce<ISwitchItem[]>((acum, item) => {
      const idNivel = item.nivel.idNivel;

      const confirmacionMatricula = confirmacionMatriculas
        .filter((c) => c.confMatCriterio.criterio.idCriterio !== 2)
        .find(
          (cm) =>
            cm.confMatCriterio.valor ===
            item.idDependenciaFuncionalNivel.toString(),
        );

      if (!confirmacionMatricula) return acum;

      const _dependencia: Dependencia = {
        ...item.dependenciaFuncional,
        configuracion: confirmacionMatricula,
        checked: confirmacionMatricula.activo,
      };
      initialvalue[confirmacionMatricula.idConfirmacionMatricula.toString()] =
        confirmacionMatricula.activo;
      const existNivel = acum.find((i) => i.idNivel === idNivel);
      if (existNivel) {
        existNivel.dependencias.push(_dependencia);
        existNivel.checked = existNivel.dependencias.every(
          (d) => d.configuracion.activo,
        );
        const newAcum = acum.filter((a) => a.idNivel !== idNivel);
        newAcum.push(existNivel);
        acum = newAcum;
      } else {
        acum.push({
          name: item.nivel.descripcionNivel.toLowerCase(),
          ...item.nivel,
          dependencias: [_dependencia],
          checked: false,
        });
      }
      return acum;
    }, []);
    return {
      mapped: orderBy(_mapped, 'idNivel', 'asc'),
      initialvalue,
      periodo,
    };
  }, [
    cicloLectivoActual?.idCicloLectivo,
    confirmacionMatriculas,
    dependenciaNivels,
  ]);

  const handleSubmit = useCallback(
    async (values: ConfirmacionMatricula[]) => {
      try {
        const onlyChange = values
          .filter(
            (v) =>
              mapped.initialvalue[v.idConfirmacionMatricula?.toString()] !==
              v.activo,
          )
          .map((cm) => {
            let _cm = cm;
            if (_cm.activo && _cm.confMatCriterio.criterio.idCriterio === 1) {
              _cm.fechaDesde = moment()
                .set({ hour: 0, minute: 0, second: 0 })
                .toISOString();
              _cm.fechaHasta = moment()
                .add(13, 'day')
                .set({ hour: 23, minute: 59, second: 59 })
                .toISOString();
            }
            return _cm;
          });

        await confirmacionMatriculaDAO.save(onlyChange);
        enqueueSnackbar('Se guardó con éxito', {
          variant: 'success',
        });
        recall();
      } catch (error) {
        console.log('Error too save', error);
        enqueueSnackbar('Ocurrio un error al guardar', {
          variant: 'error',
        });
      }
    },
    [confirmacionMatriculaDAO, mapped, enqueueSnackbar, recall],
  );

  return (
    <Container>
      <Grid container>
        <Grid item></Grid>
        <Grid container>
          {working || dNWorking ? (
            <Loading />
          ) : (
            <SwitchList
              periodo={mapped.periodo as ConfirmacionMatricula}
              items={mapped.mapped}
              initialValue={mapped.initialvalue}
              onSubmit={handleSubmit}
            />
          )}
        </Grid>
        <LocalizacionesActivas />
      </Grid>
    </Container>
  );
};
