import { LibbyFetchDAO } from './LibbyFetchDAO';

export class GruposDocentesDAO extends LibbyFetchDAO {
  constructor() {
    super('grupos_docentes', 'idGrupoDocente');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByUserdId(idUsuario: number, idLocalizacion: number) {
    return this.query()
      .equals('planta.personalEducativo.persona.cuentas', idUsuario)
      .equals('planta.espacioCurricular.localizacion', idLocalizacion)
      .equals('planta.seccion.localizacion', idLocalizacion)
      .limit(0, 10000)
      .run();
  }
}
