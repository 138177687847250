import { useEffect, useState } from 'react';
import { NIVEL } from 'src/commons/const';
import { useFilterPassSectionInicialLogic } from '../hooks/useFilterPassSectionInicialLogic';
import { useFilterPassSectionPrimariaLogic } from '../hooks/useFilterPassSectionPrimariaLogic';
import { useFilterPassSectionSecundariaLogic } from '../hooks/useFilterPassSectionSecundariaLogic';

export const useFilterPassSection = (
  options: any,
  statusPass: any,
  currentCicloLectivo: any,
  numeroAnio: any,
  descripcionAnioSection: any,
  nivel: any,
  shoulBeNextYear: boolean,
  idAnio: any,
) => {
  let filteredOptions;

  const filteredOptionsInicial = useFilterPassSectionInicialLogic(
    options,
    statusPass,
    currentCicloLectivo,
    numeroAnio,
    descripcionAnioSection,
  );

  const filteredOptionsSecundaria = useFilterPassSectionSecundariaLogic(
    options,
    statusPass,
    currentCicloLectivo,
    numeroAnio,
    descripcionAnioSection,
  );

  const filteredOptionsPrimaria = useFilterPassSectionPrimariaLogic(
    options,
    statusPass,
    currentCicloLectivo,
    numeroAnio,
    descripcionAnioSection,
    shoulBeNextYear,
    idAnio,
  );

  switch (Number(nivel)) {
    case NIVEL.INICIAL:
      filteredOptions = filteredOptionsInicial;
      break;
    case NIVEL.PRIMARIO:
      filteredOptions = filteredOptionsPrimaria;
      break;
    case NIVEL.SECUNDARIO:
      filteredOptions = filteredOptionsSecundaria;
      break;
  }

  return { filteredOptions };
};
