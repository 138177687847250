type nivel = {
  path: string;
  value: number;
};

export interface NivelFilter {
  niveles: nivel[];
}

export const nivelDefaultFilter: NivelFilter = {
  niveles: [
    { path: 'idNivel', value: 1 },
    { path: 'idNivel', value: 2 },
    { path: 'idNivel', value: 3 },
  ],
};
