import { useMemo } from 'react';
import { Seccion } from 'src/app/models';
import { useGeneralContext } from 'src/context/GeneralContext';

export const useCalificacionesSecundariaExcelData = (
  data: any,
  name: string,
  periodo: { descripcionTipoPeriodo: string; idTipoPeriodo: number },
  anioSelect: string,
  anioEspacioCurricularSeccion: number,
  seccionSelect: string,
  espacioCurricular: string,
) => {
  const excelData = useMemo(() => {
    let metadata: Array<string> | [] = [
      periodo?.descripcionTipoPeriodo,
      `Año ${String(anioEspacioCurricularSeccion)}`,
      seccionSelect,
      espacioCurricular,
    ];

    if (anioSelect) {
      // Se evita repetir el año para las secciones que ya incluyen el año en su descripción
      const seccionConcatAnio = seccionSelect.includes(String(anioSelect))
        ? seccionSelect
        : `${String(anioSelect)} ${seccionSelect}`;

      metadata[2] = seccionConcatAnio;
    }
    const columnHeaders = ['Alumno', 'PPI', 'Valoración', 'Calificación'];

    // Cuarto bimestre
    if (periodo?.idTipoPeriodo === 4) {
      columnHeaders.push('Calificación Anual', 'Calificación Definitiva');
    }

    const rows = data.map((row: any) => ({
      Alumno: `${row?.apellido}, ${row.nombre}`,
      PPI:
        row?.nota?.data?.ppi === false
          ? 'No'
          : row?.nota?.data?.ppi
          ? 'Sí'
          : '',
      Valoración: `${row?.nota?.data?.valoracion ?? ''}`,
      Calificación: `${row?.nota?.data?.calificacion ?? ''}`,
      'Calificación Anual': `${row?.calificacionAnual ?? ''}`,
      'Calificación Definitiva': `${row?.calificacionAnual ?? ''}`,
    }));

    return {
      body: {
        metadata,
        columns: columnHeaders,
        data: rows,
      },
      name,
    };
  }, [
    anioEspacioCurricularSeccion,
    anioSelect,
    data,
    espacioCurricular,
    name,
    periodo?.descripcionTipoPeriodo,
    periodo?.idTipoPeriodo,
    seccionSelect,
  ]);

  return { excelData };
};
