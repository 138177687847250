import React, {
  FunctionComponent,
  useState,
  SetStateAction,
  Dispatch,
} from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, Chip, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { SimpleSelect } from 'src/commons';
import {
  FormCheckbox,
  FormDatePicker,
  FormInput,
  useFormContext,
  FormFileInput,
} from 'src/lib/templates';
import { CustomFormAutocomplete as FormAutocomplete } from '../../components';
import { useLegajoData } from '../../context';

interface Props {
  onDocumentSectionChange?: () => void;
  documentValue?: string;
}

type TipoDocumento = {
  name: string;
  value: number;
};

const useStyles = makeStyles({
  root: {
    marginTop: '32px',
    marginBottom: '32px',
    width: '100%',
    background: '#F5F5F5',
    minHeight: '300px',
    color: '#595959',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > span': {
      fontSize: '20px',
      margin: '0.83em 0',
    },
    '& > svg': {
      margin: 'auto 0',
      '&:hover': {
        cursor: 'pointer',
      },
    },
  },
  container: {
    padding: '32px 20px',
    fontFamily: 'Open Sans',
    fontWeight: 'normal',
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: ' auto auto auto',
    gridTemplateRows: 'auto auto',
    gap: '0px 0px',
  },
  font: {
    fontFamily: 'Open Sans',
  },
  buttonRoot: {
    border: 'none !important',
    background: 'none',
    '& > fieldset': { border: 'none' },
    '& > input': { '&::after': { content: 'Requiere actualización' } },
  },
  grayContainer: {
    background: '#F5F5F5',
    margin: '5% 15px 0 15px',
    borderRadius: '5px',
  },
  headerGrayContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
    '& > span': {
      fontSize: '20px',
      fontFamily: 'Open Sans',
      color: '#595959',
    },
  },
  deleteIcon: {
    '&:hover': {
      cursor: 'pointer',
    },
    '& > path': {
      fill: '#494949',
    },
  },
  datePicker: {
    paddingLeft: 0,
  },
  checkBox: {
    marginTop: 15,
  },
});

const optionsDocumentType: TipoDocumento[] = [
  { name: 'Certificados', value: 1 },
  { name: 'Notificaciones', value: 2 },
  { name: 'Otros', value: 3 },
];

export const Documentation: FunctionComponent = () => {
  const { isHistoric } = useLegajoData();

  const [card, setCard] = useState<{ current: number }[]>([
    {
      current: 0,
    },
  ]);

  const addNewDocument = () => setCard([...card, { current: card.length + 1 }]);
  const { form, values } = useFormContext();

  const deleteCard = (currentCardToDelete: number) => {
    const updateTabs = card.filter(
      ({ current }) => current !== currentCardToDelete,
    );
    setCard(updateTabs);
  };

  return (
    <>
      <Grid item>
        {card.map(({ current }, i) => (
          <DocumentCard
            key={current}
            values={values}
            index={i}
            setCard={() => deleteCard(current)}
            form={form}
            isHistoric={isHistoric}
          />
        ))}
      </Grid>
      <Grid item style={{ marginTop: '5%' }}>
        {!isHistoric && (
          <Button variant="outlined" color="primary" onClick={addNewDocument}>
            Agregar
          </Button>
        )}
      </Grid>
      <Grid item style={{ height: 75 }}>
        <br />
      </Grid>
    </>
  );
};

interface DocumentProps {
  setCard: Dispatch<SetStateAction<any>>;
  index: number;
  form: any;
  values: any;
  isHistoric?: boolean;
}

const DocumentCard: FunctionComponent<DocumentProps> = ({
  form,
  values,
  setCard,
  index,
  isHistoric,
}) => {
  const classes = useStyles();
  return (
    <Grid container spacing={4} direction="column">
      <Grid item className={classes.grayContainer}>
        <Grid item xs={12} className={classes.headerGrayContainer}>
          <span>Documentación (Opcional) </span>
          {!isHistoric && (
            <DeleteIcon className={classes.deleteIcon} onClick={setCard} />
          )}
        </Grid>
        <Grid container spacing={4}>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormAutocomplete
              disabled={isHistoric}
              placeholder="Seleccioná tipo de documento"
              label="Tipo de documento"
              name={`documentacion.datosPrincipales[${index}].tipoDocumento`}
              form={form}
              options={optionsDocumentType}
              optionLabelKey="name"
              optionToValue={(option: { value: any }) => option?.value}
              valueToOption={(value: any, options: any[]) =>
                options.find((option: any) => option.value === value)
              }
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <FormDatePicker
                  disabled={isHistoric}
                  label="Fecha de emisión"
                  name={`documentacion.datosPrincipales[${index}].fechaEmision`}
                  form={form}
                  className={classes.datePicker}
                />
              </Grid>
              <Grid item xs={6}>
                <FormDatePicker
                  disabled={isHistoric}
                  label="Fecha de entrega"
                  name={`documentacion.datosPrincipales[${index}].fechaEntrega`}
                  form={form}
                  className={classes.datePicker}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <FormInput
              disabled={isHistoric}
              placeholder="Escribe una descripción"
              label="Descripción"
              name={`documentacion.datosPrincipales[${index}].descripcion`}
              form={form}
            />
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Grid container>
              <Grid xs={6}>
                {values.documentacion?.datosPrincipales[index]?.url ? (
                  <div>
                    <Chip
                      label="Archivo"
                      onClick={() => {
                        window.open(
                          values.documentacion.datosPrincipales[index].url,
                          '_blank',
                        );
                      }}
                      onDelete={() =>
                        !isHistoric &&
                        form.change(
                          `documentacion.datosPrincipales[${index}].url`,
                          undefined,
                        )
                      }
                    />
                  </div>
                ) : (
                  <FormFileInput
                    disabled={isHistoric}
                    label="Adjuntar documento"
                    name={`documentacion.datosPrincipales[${index}].file`}
                    form={form}
                    placeholder="Subir"
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <Grid className={classes.checkBox}>
                  <FormCheckbox
                    disabled={isHistoric}
                    name={`documentacion.datosPrincipales[${index}].requiereActualizacion`}
                    label="Requiere actualización"
                    form={form}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
