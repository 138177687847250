import { AnyObject } from 'src/commons';
import { useFormContext } from 'src/lib/templates';

// TODO: complete this
export interface PlanificationFormDisciplinarComun {
  informacionGeneral: AnyObject;
  ensenanzas: any;
}

export const usePlanificationFormDisciplinarComun = () => useFormContext<any>();
