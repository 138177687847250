import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRolesContext } from 'src/context/RolesContext';

import { CalificacionesFilter } from '../../CalificacionesSecundario';
import { useCalificacionesSecundarioContext } from '../context/CalificacionesSecundarioContext';
import {
  RecuperacionFactory,
  RecuperacionResponse,
} from '../../CalificacionesSecundario/factories/RecuperacionFactory';

export const useValidator = (_rows: RecuperacionResponse[]) => {
  const [incompletedRows, setIncompletedRows] = useState<string[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [currentRows, setCurrentRows] = useState<typeof _rows>();
  const [isValidToSubmit, setValidToSubmit] = useState<boolean>(false);
  const { factory } = useCalificacionesSecundarioContext<RecuperacionFactory>();

  const {
    selectedRole: { rol },
  } = useRolesContext();

  const hasPermissionsToOpen = useMemo(() => ['1', '5'].includes(rol), [rol]);

  useEffect(() => {
    const rowsToSubmit = factory
      .getData()
      .getRowsModified(_rows, ['diciembre', 'febreroMarzo']);
    if (rowsToSubmit.rows.length === 0) {
      setValidToSubmit(true);
    } else {
      setValidToSubmit(false);
    }
  }, [factory, _rows]);

  const validator = useCallback(() => {
    let disabled = false;
    _rows.forEach((calificacion: RecuperacionResponse) => {
      const currentName = `${calificacion?.apellido}, ${calificacion?.nombre}`;

      const hasDiciembre = Number(calificacion?.diciembre) !== 0;
      const hasFebrero = Number(calificacion?.febreroMarzo) > 0;

      if (!hasDiciembre && !hasFebrero) {
        disabled = true;
        if (!incompletedRows.some((names) => names === currentName))
          setIncompletedRows([...incompletedRows, currentName]);
      } else {
        if (incompletedRows.some((name) => name === currentName)) {
          const incompletedRowsCopy = [...incompletedRows];
          incompletedRowsCopy.splice(
            incompletedRowsCopy.indexOf(currentName),
            1,
          );
          setIncompletedRows(incompletedRowsCopy);
        }
      }
    });
    setIsDisabled(disabled);
  }, [_rows, incompletedRows]);

  useEffect(() => {
    if (currentRows !== _rows) {
      setCurrentRows(_rows);
      validator();
    }
  }, [currentRows, _rows, validator]);

  return {
    incompletedRows,
    setIncompletedRows,
    isDisabled,
    hasPermissionsToOpen,
    submitRows: isValidToSubmit,
  };
};
