import { ESTADO_PRESENTE } from '../../../../../commons';
import { EstadosPresentismo } from '../types';

export const ESTADOS_PRESENTISMO: EstadosPresentismo[] = [
  {
    label: 'P',
    idEstadoPresente: ESTADO_PRESENTE.PRESENTE,
    descripcion: 'Presente',
    color: 'GREEN',
    jornadaExtendida: true,
  },
  {
    label: 'A',
    idEstadoPresente: ESTADO_PRESENTE.AUSENTE,
    descripcion: 'Ausente',
    color: 'RED',
    jornadaExtendida: true,
  },
  {
    label: 'APC',
    idEstadoPresente: ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE,
    descripcion: 'Ausente con presencia en clases',
    color: 'ORANGE',
  },
  {
    label: 'IT',
    idEstadoPresente: ESTADO_PRESENTE.INGRESO_TARDIO,
    descripcion: 'Ingreso tardio',
    color: 'YELLOW',
  },
  {
    label: 'RA',
    idEstadoPresente: ESTADO_PRESENTE.RETIRO_ANTICIPADO,
    descripcion: 'Retiro anticipado',
    color: 'YELLOW',
  },
  {
    label: 'NC',
    idEstadoPresente: ESTADO_PRESENTE.NO_CORRESPONDE,
    descripcion: 'No corresponde',
    color: 'GREY',
    jornadaExtendida: true,
  },
];

export const CONTRATURNO_ESTADOS = [
  ESTADO_PRESENTE.PRESENTE,
  ESTADO_PRESENTE.AUSENTE,
  ESTADO_PRESENTE.NO_CORRESPONDE,
  ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE,
];
export const EXTENDIDA_ESTADOS = [
  ESTADO_PRESENTE.PRESENTE,
  ESTADO_PRESENTE.AUSENTE,
  ESTADO_PRESENTE.NO_CORRESPONDE,
];
export const ESPACIOS_CURRICULARES_ESTADOS = [
  ESTADO_PRESENTE.PRESENTE,
  ESTADO_PRESENTE.AUSENTE,
  ESTADO_PRESENTE.NO_CORRESPONDE,
  ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE,
];

export const JUSTIFICACION_ESTADOS = [
  ESTADO_PRESENTE.AUSENTE,
  ESTADO_PRESENTE.RETIRO_ANTICIPADO,
  ESTADO_PRESENTE.INGRESO_TARDIO,
  ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE,
];
