import { CalificacionesstateDAO } from './dao/CalificacionesstateDAO';
import { CustomInformeshistoryDAO } from './dao/CustomInformeshistoryDAO';
import { DescargardriveDAO } from './dao/DescargarDriveDAO';
import { EspaciocurricularseccioncustomDAO } from './dao/EspaciocurricularseccioncustomDAO';
import { GenerarInformeDAO } from './dao/GenerarInformeDAO';
/* eslint-disable class-methods-use-this */
import { Database, Libby, RESTSessionStrategy } from '@phinxlab/libby-rest-web';
import { API_URL } from '../../config';
import {
  AccionesDAO,
  AccionesMinimizadasDAO,
  AccionesSoloOrientacionesDAO,
  ActivityExtraCurricularDAO,
  AdminDAO,
  AlertasDAO,
  AlumnoDAO,
  AlumnoExisteDAO,
  AlumnoMovimientoCustomDAO,
  AlumnoMovimientoDAO,
  AnioDAO,
  AnualDAO,
  ApreciacionActividadDAO,
  AreaDAO,
  ArealPlanAreaDAO,
  ArealPlanDAO,
  ArealPlanFeedbackDAO,
  AspectosGeneralesCustomDAO,
  AspectosGeneralesDAO,
  AtencionHospitalariaDAO,
  AutoRegistracionDAO,
  AutoevaluacionesEstudianteDAO,
  BajaMotivoDAO,
  BloquesDAO,
  Bloquesv2DAO,
  BoletinAlumnoDAO,
  CalendarioVacunacionDAO,
  CalificacionesCustomDAO,
  CalificacionesPrimarioDAO,
  CalificacionesSecundarioDAO,
  CantDiasSemanaLabDAO,
  CargoDAO,
  CertificadosSeptimoDAO,
  CicloAdultosDAO,
  CodigoVinculoDAO,
  ComentarioDAO,
  CondAnioAntDAO,
  CondicionDAO,
  ConfiguracionDAO,
  ConfirmacionMatriculaCountDAO,
  ConstanciaCondicionDAO,
  ConstantesDAO,
  ContenidoBloqueDAO,
  ContenidoEspecificoDAO,
  ContenidosDAO,
  ContraturnoPresenteCustomDAO,
  ConvivenciaCustomDAO,
  ConvivenciaDAO,
  CriteriosEvaluacionDAO,
  CuentasDAO,
  CycleDAO,
  CycleLective,
  CycleLectiveAdults,
  DatosLaboralesDAO,
  DependenciaFuncionalDAO,
  DependenciaFuncionalNivelDAO,
  DeudaAcademicaDAO,
  DevolucionContenidosDAO,
  DevolucionHabilidadesDAO,
  DevolucionOrganizacionDAO,
  DevolucionPerfilesDAO,
  DevolucionesEvaluacionesDAO,
  DiaDAO,
  DiscipOrientPlanDAO,
  DiscipOrientPlanFeedbackDAO,
  DisciplinaDAO,
  DistritoEscolarDAO,
  DistritosCustomDAO,
  DistritosLocalizacionesCustomDAO,
  DomicilioEstablecimientoDAO,
  DomicilioPersonaDAO,
  EfectivizacionCheckCustomDAO,
  EfectivizarProyeccionDAO,
  EjesDAO,
  EndpointDAO,
  EscalafonEstablecimientoDAO,
  EspacioCurricularCustomDAO,
  EspacioCurricularDAO,
  EspacioCurricularDocenteDAO,
  EspacioCurricularPlanDAO,
  EspacioCurricularSeccionDAO,
  EspaciosArealPlanDAO,
  EspaciosAreasDAO,
  EspaciosDiscipOrientPlanDAO,
  EspaciosInterdiscipPlanDAO,
  EspaciosOrientacionesDAO,
  EspaciosSimpDiscipPlanDAO,
  EspecialesBycDAO,
  EspecialesBycNivelDAO,
  EstablecimientoDAO,
  EstablecimientosAcapDAO,
  EstadoAlumnoDAO,
  EstadoCicloLectivoDAO,
  EstadoCivilDAO,
  EstadoDisciplinaDAO,
  EstadoMatriculaDAO,
  EstadoPaseAnioDAO,
  EstadoPresenteDAO,
  EstadoPromocionPaseAnioDAO,
  EstadoPropuestaDAO,
  EstadoProyeccionMotivoDAO,
  EstadoSolicitudPasesDAO,
  EvaluacionesDevolucionesDAO,
  ExceptuadoDAO,
  ExternalPersonEnrolledDAO,
  FeedbackPlanItemDAO,
  FeedbackPlanOpcionDAO,
  FeedbackPlanSeccionDAO,
  GenerarExcelDAO,
  GeneroDAO,
  GeolocalizacionDAO,
  GrupoInscripcionAutoevaluadosDAO,
  GrupoSeleccionCustomDAO,
  GruposAulaVirtualDAO,
  GruposCicloLectivoDAO,
  GruposDAO,
  GruposDocentesDAO,
  GruposEspaciosDAO,
  GruposExtraAlumnoDAO,
  GruposExtracurricularDAO,
  GruposInscripcionesDAO,
  GruposInscripcionesOfertaDAO,
  GruposPlanEstudioNivelDAO,
  GruposSeccionAlumnoDAO,
  GruposSeccionDAO,
  GruposSeleccionesDAO,
  GruposTurnosDAO,
  HabilidadesCategoriasDAO,
  HabilidadesDAO,
  Habilidadesv2DAO,
  HistoricoCondicionesDAO,
  IndicadoresDeEvaluacionDAO,
  IndicadoresEvaluacionDAO,
  InfoPlataformaDAO,
  InformeEpaDAO,
  InformesCalificacionDAO,
  InformesDAO,
  InformesGeneradosDAO,
  InformesHistoryDAO,
  InformescCustomDAO,
  InformescDAO,
  InscripcionAlumnoCustomDAO,
  InscripcionAlumnoDAO,
  InstrumentosDeEvaluacionDAO,
  InterdiscipOrientaPlanDAO,
  InterdiscipPlanDAO,
  InterdiscipPlanFeedbackDAO,
  JeIndicadoresDAO,
  JeMateriasDAO,
  JornadaExtendidaDAO,
  LegajoAlumnoDAO,
  LegajoAlumnoTemplateDAO,
  LegajoAnioLectivoDAO,
  LevelDAO,
  ListEstudianteNodosDAO,
  LocalizacionDAO,
  LocalizacionNivelDAO,
  LocalizacionPlanEstudioNivelDAO,
  MateriaDAO,
  MatriculaPersonaExternaDAO,
  MatriculacionSeccionDAO,
  MedioContactoDAO,
  MethodDAO,
  ModalidadDAO,
  ModalidadNivelDAO,
  MunicipioDAO,
  NivelEduAlcanzadoDAO,
  ObjetivosBloquesDAO,
  ObjetivosBloquesv2DAO,
  ObjetivosDeAprendizajeDAO,
  ObservacionescCustomDAO,
  ObservacionescDAO,
  OfertasDAO,
  OfertasGruposDAO,
  OfertasGruposMinimalDAO,
  OrientacionDAO,
  OrientacionMateriaDAO,
  Orientacionesv2DAO,
  PaisDAO,
  PassRequestStatusDAO,
  PedagogiaDAO,
  PerfilOrientadoDAO,
  PeriodoDAO,
  PeriodoNuevoDAO,
  PermissionDAO,
  PermissionEndpointMethodDAO,
  PersonaDAO,
  PersonalEducativoCargoDAO,
  PersonalEducativoDAO,
  PipDAO,
  PlanEstudioMateriaDAO,
  PlanEstudioOrientacionDAO,
  PlanEstudioSeccionDAO,
  PlanSocialDAO,
  PlanStudyDAO,
  PlanStudyLevelDAO,
  PlanificacionArealTemplateDAO,
  PlanificacionDAO,
  PlanificacionDisciplinarOrientadaTemplateDAO,
  PlanificacionDisciplinarTemplateDAO,
  PlanificacionInterdisciplinarTemplateDAO,
  PlanificacionTemplateDAO,
  PlantaDAO,
  PpsDAO,
  PresenteDAO,
  PresentismoAsistenciaDAO,
  PreviasProyeccionesCustomDAO,
  PreviasProyeccionesDAO,
  PrimarioCustomDAO,
  PrimarioDAO,
  ProcesosDAO,
  PromocionPaseAnioDAO,
  PropositosBloquesDAO,
  PropositosBloquesv2DAO,
  PropositosDeEnsenanzaDAO,
  PropositosOrientacionDAO,
  ProvinciaDAO,
  ProyeccionBulkCustomDAO,
  ProyeccionCustomDAO,
  ProyeccionDAO,
  ProyeccionEstadoDAO,
  ProyeccionEstadoTipoDAO,
  ProyeccionIELBulkCustomDAO,
  ProyeccionIELDAO,
  ProyeccionMatriculacionDAO,
  PublicacionDAO,
  QueHaceresCustomDAO,
  QueHaceresDAO,
  RecuperacionDAO,
  RecuperacionesStateDAO,
  RecursosDAO,
  RegularidadDAO,
  ReloginDAO,
  RemoveCardCustomDAO,
  ReporteDAO,
  RepositorioAreasDAO,
  RepositorioAudienciaItemDAO,
  RepositorioCicloDAO,
  RepositorioEspaciosCurricularesDAO,
  RepositorioItemDAO,
  RepositorioTipoItemDAO,
  RepositorioTipoRecursoDAO,
  RepositorioTransversalesDAO,
  ResponsableDAO,
  RolDAO,
  RolStatusDAO,
  SeccionAulaVirtualDAO,
  SeccionTieneProyectadosDAO,
  SeccionesDAO,
  SeccionesVaciasCustomDAO,
  SectionDAO,
  SectionWithProyeccionDAO,
  SecundariaFuturoDAO,
  SecundarioCustomDAO,
  SecundarioDAO,
  SimpDiscipPlanDAO,
  SimpDiscipPlanFeedbackDAO,
  SintesisConceptualCustomDAO,
  SintesisConceptualDAO,
  SolicitudPaseDAO,
  SupervisionDAO,
  TipoAcompananteDAO,
  TipoDeudaAcademicaDAO,
  TipoDireccionDAO,
  TipoDisciplinaDAO,
  TipoDocumentoDAO,
  TipoDomicilioDAO,
  TipoEstablecimientoDAO,
  TipoEstadoPresenteDAO,
  TipoInformesDAO,
  TipoMedioContactoDAO,
  TipoPeriodoDAO,
  TipoVinculoDAO,
  TiposAccionesDAO,
  TurnDAO,
  UnidadEducativaDAO,
  UsuarioRolDependenciaFuncionalDAO,
  UsuarioRolEstablecimientoDAO,
  UsuarioRolTraverseActionDAO,
  UsuariosRolesDAO,
  VinculoDAO,
  tipoCalificacionDAO,
} from './dao';

import { DAO_NAME } from '../../app/business/DiasHabilitados';
import { DatabaseStore } from './DatabaseStoreInterface';
import { RESTStrategyV2 } from './LibbyV2';
import { TokenManager } from './TokenManager';
import { AccionesOrientacionesCustomDAO } from './dao/AccionesOrientacionesCustomDAO';
import { AccionesOrientacionesDAO } from './dao/AccionesOrientacionesDAO';
import { InformeValorativoDAO } from './dao/Calificaciones/InformeValorativoDAO';
import { CreateCuentaAndPersonaDAO } from './dao/CreateCuentaAndPersonaDAO';
import { IgualarCalificacionesDAO } from './dao/IgualarCalificacionesDAO';
import { OrganizacionesDAO } from './dao/OrganizacionesDAO';
import { NodosDAO } from './dao/Orientaciones';
import { PresentismoGestionCustomDAO } from './dao/PresentismoGestionCustomDAO';
import {
  FechaBloqueadaDAO,
  MetadataDAO,
  MetadataTypeDAO,
  MotivoDAO,
  Presentev2DAO,
  PropuestaJEDAO,
  PropuestaJEDiaDAO,
  ReportePresentismoDAO,
  SedeDAO,
  SituacionDAO,
} from './dao/Presentismov2';
import {
  CierreDAO,
  ContraturnoDAO,
  ContraturnoDiaDAO,
  DiasHabilitadosDAO,
  ExceptuadosV3DAO,
  FechaBloqueadaV3DAO,
  JustificacionDAO,
  JustificacionMotivoDAO,
  JustificacionRazonDAO,
  MotivoSituacionV3DAO,
  MotivoTipoSituacionV3DAO,
  MotivosExceptuadoDAO,
  ObservacionesBoletinDAO,
  PeriodoInicioDAO,
  Presente_v3DAO,
  PresentismoEspacioCurricularDAO,
  PresentismoTrimestralV3CustomDAO,
  SituacionGenerateDAO,
  SituacionV3DAO,
  TipoSituacionV3DAO,
  ValidPresentismosByWeekDAO,
} from './dao/Presentismov3';
import { EstadoPresenteV3DAO } from './dao/Presentismov3/EstadoPresenteV3DAO';
import { PresentesStudentCustomDAO } from './dao/Presentismov3/PresentesStudentCustomDAO';
import { ReferentesDAO } from './dao/ReferentesDAO';
import { ReportDAO } from './dao/ReportDAO';
import { ReportDownloadDAO } from './dao/ReportDownloadDAO';
import { SectoresDAO } from './dao/SectoresDAO';
import { SedesDAO } from './dao/SedesDAO';
import { ApreciacionValorDAO } from './dao/acap/ApreciacionValorDAO';
import {
  ConfirmacionMatriculaDAO as ConfirmacionMatricula2DAO,
  ConfirmacionMatriculaCriterioDAO,
  ConfirmacionMatriculaGenerateDAO,
  ConfirmacionMatriculaLocalizacionDAO,
  VerificacionConfMatriculaDAO,
} from './dao/confirmacionMatricula';
import { ConfirmacionMatriculaGeneracionDAO } from './dao/matriculacion/ConfirmacionMatriculaGeneracionDAO';
import { SessionChangeEmitter } from './session';
class DatabaseStoreRaw implements DatabaseStore {
  chinoTokenEnvironmet: string;

  url: string;

  constructor() {
    this.chinoTokenEnvironmet = 'MiEscuelaApp';
    this.url = API_URL;
  }

  addSession(): void {
    const Session = RESTSessionStrategy.init(this.url);
    Session.setTokenManager(TokenManager.create());
    Database.addSessionStrategy(Session);
    Session.addSessionListener({
      // TODO: remove any type
      newSessionState: (state: any) => {
        const { user } = Session;
        const isLogged: boolean = !!user && user.isGuest === false;
        SessionChangeEmitter.emitChange({ isLogged, user, state });
      },
    });
    Database.addSessionStrategy(Session);
  }

  registerPresentismoSchema() {
    Database.register(
      new RESTStrategyV2('sede', '/api/presentismov3/sedes'),
      SedeDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'presentes_student',
        '/api/public/presentismov3/presentes_custom',
      ),
      PresentesStudentCustomDAO,
    );

    Database.register(new RESTStrategyV2('dia', '/api/public/dias'), DiaDAO);

    Database.register(
      new RESTStrategyV2('fecha_bloqueada', '/api/fechas_bloqueadas'),
      FechaBloqueadaDAO,
    );

    Database.register(
      new RESTStrategyV2('presentismo.reporte', '/api/presentismov2/reporte'),
      ReportePresentismoDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'presentismos_valid_week',
        '/api/presentismov3/presentismos_validos',
      ),
      ValidPresentismosByWeekDAO,
    );
  }

  registerMatriculacionSchema() {
    Database.register(
      new RESTStrategyV2(
        'constancia_condicion',
        '/api/matriculacion/constancia',
      ),
      ConstanciaCondicionDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'confirmacion_matricular_count',
        '/api/matriculacion/confirmacion_count',
      ),
      ConfirmacionMatriculaCountDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'confirmacion_matricular_pdf',
        '/api/matriculacion/confirmacion',
      ),
      ConfirmacionMatriculaGeneracionDAO,
    );
  }

  register(): void {
    this.registerOrientacionesSchema();
    this.registerMatriculacionSchema();
    this.registerPresentismoSchema();
    this.registerACAP5Schema();
    Database.register(
      new RESTStrategyV2(
        'informesmatriculacion',
        '/api/documents/custom/informesmatriculacion',
      ),
      InformesCalificacionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'presentismogestioncustom',
        '/api/presentismov3/gestion',
      ),
      PresentismoGestionCustomDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'calificacionesstate',
        '/api/calificaciones/calificacionesstate',
      ),
      CalificacionesstateDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'recuperacionesstate',
        '/api/calificaciones/recuperacionesstate',
      ),
      RecuperacionesStateDAO,
    );
    Database.register(
      new RESTStrategyV2('descargardrive', '/api/public/descargardrive'),
      DescargardriveDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'informeshistory',
        '/api/documents/custom/informeshistory',
      ),
      CustomInformeshistoryDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'espaciocurricularseccioncustom',
        '/api/public/espaciocurricularseccioncustom',
      ),
      EspaciocurricularseccioncustomDAO,
    );
    Database.register(
      new RESTStrategyV2('generarinforme', '/api/public/generarinforme'),
      GenerarInformeDAO,
    );
    Database.register(
      new RESTStrategyV2('alumno', '/api/public/alumno', 'idAlumno'),
      AlumnoDAO,
    );
    Database.register(
      new RESTStrategyV2('rol', '/api/public/rol', 'idRolUsuario'),
      RolDAO,
    );
    Database.register(
      new RESTStrategyV2('persona', '/api/public/persona', 'idPersona'),
      PersonaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'inscripcion_alumno',
        '/api/public/inscripcionalumno',
        'idInscripcionAlumno',
      ),
      InscripcionAlumnoDAO,
    );
    Database.register(
      new RESTStrategyV2('anio', '/api/public/anio', 'anio_id'),
      AnioDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'ciclo_lectivo',
        '/api/public/ciclolectivo',
        'ciclo_lectivo_id',
      ),
      CycleLective,
    );
    Database.register(
      new RESTStrategyV2(
        'plan_estudio',
        '/api/public/planestudio',
        'plan_estudio_id',
      ),
      PlanStudyDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'matricula_persona_externa',
        '/api/public/matriculapersonaexterna',
        'idMatriculaPersonaExterna',
      ),
      ExternalPersonEnrolledDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'actividad_extra_curricular',
        '/api/public/actividadextracurricular',
        'idActividadExtracurricular',
      ),
      ActivityExtraCurricularDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'ciclo_lectivo_adultos',
        '/api/public/ciclolectivoadultos',
        'idCicloLectivoAdultos',
      ),
      CycleLectiveAdults,
    );
    Database.register(
      new RESTStrategyV2(
        'plan_estudio_nivel',
        '/api/public/planestudionivel',
        'plan_estudio_nivel_id',
      ),
      PlanStudyLevelDAO,
    );
    Database.register(
      new RESTStrategyV2('turno', '/api/public/turno', 'idTurno'),
      TurnDAO,
    );
    Database.register(
      new RESTStrategyV2('seccion', '/api/public/seccion', 'idSeccion'),
      SectionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'seccion_with_proyeccion',
        '/api/public/seccion_meta_proyeccion',
        'idSeccion',
      ),
      SectionWithProyeccionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'solicitud_pases',
        '/api/public/solicitudpases',
        'idSolicitudPases',
      ),
      SolicitudPaseDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'alumno_movimiento',
        '/api/public/alumnomovimiento',
        'idAlumnoMovimiento',
      ),
      AlumnoMovimientoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'informesepa',
        '/api/documents/informeepa',
        'idInformeEpa',
      ),
      InformeEpaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'alumno_movimiento_custom',
        '/api/public/custom/alumnomovimientocustom',
        'idAlumnoMovimiento',
      ),
      AlumnoMovimientoCustomDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'promocion_pase_anio',
        '/api/public/promocionpaseanio',
        'idPromocionPaseAnio',
      ),
      PromocionPaseAnioDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'estado_pase_anio',
        '/api/public/estadopaseanio',
        'idEstadoPaseAnio',
      ),
      EstadoPaseAnioDAO,
    );
    Database.register(
      new RESTStrategyV2('nivel', '/api/public/nivel', 'idNivel'),
      LevelDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'estado_solicitud_pases',
        '/api/public/estadosolicitudpases',
        'idEstadoSolicitudPases',
      ),
      PassRequestStatusDAO,
    );
    Database.register(
      new RESTStrategyV2('nivel', '/api/public/nivel', 'idNivel'),
      LevelDAO,
    );
    Database.register(
      new RESTStrategyV2('ciclo', '/api/public/ciclo', 'idCiclo'),
      CycleDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'tipo_disciplina',
        '/api/public/tipodisciplina',
        'idTipoDisciplina',
      ),
      TipoDisciplinaDAO,
    );
    Database.register(
      new RESTStrategyV2('cargo', '/api/public/cargo', 'idCargo'),
      CargoDAO,
    );
    Database.register(
      new RESTStrategyV2('condicion', '/api/public/condicion', 'idCondicion'),
      CondicionDAO,
    );
    Database.register(
      new RESTStrategyV2('cuentas', '/api/public/cuentas', 'idUsuario'),
      CuentasDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'dependencia_funcional',
        '/api/public/dependenciafuncional',
        'idDependenciaFuncional',
      ),
      DependenciaFuncionalDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'disciplina',
        '/api/public/tipodisciplina',
        'idDisciplina',
      ),
      DisciplinaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'distrito_escolar',
        '/api/public/distritoescolar',
        'idDistritoEscolar',
      ),
      DistritoEscolarDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'matricula_persona_externa',
        '/api/public/matriculapersonaexterna',
        'idMatriculaPersonaExterna',
      ),
      MatriculaPersonaExternaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'domicilio_establecimiento',
        '/api/public/domicilioestablecimiento',
        'idDomicilioEstablecimiento',
      ),
      DomicilioEstablecimientoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'domicilio_persona',
        '/api/public/domiciliopersona',
        'idDomicilioPersona',
      ),
      DomicilioPersonaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'escalafon_establecimiento',
        '/api/public/escalafonestablecimiento',
        'idEscalafonEstablecimiento',
      ),
      EscalafonEstablecimientoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'boletin_alumno',
        '/api/public/boletinalumno',
        'idBoletinAlumno',
      ),
      BoletinAlumnoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'especiales_byc',
        '/api/public/especialesbyc',
        'idEspecialesByc',
      ),
      EspecialesBycDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'especiales_byc_nivel',
        '/api/public/especialesbycnivel',
        'idEspecialesBycNivel',
      ),
      EspecialesBycNivelDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'establecimiento',
        '/api/public/establecimiento',
        'idEstablecimiento',
      ),
      EstablecimientoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'estado_alumno',
        '/api/public/estadoalumno',
        'idEstadoAlumno',
      ),
      EstadoAlumnoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'estado_ciclo_lectivo',
        '/api/public/estadociclolectivo',
        'idEstadoCicloLectivo',
      ),
      EstadoCicloLectivoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'estado_disciplina',
        '/api/public/estadodisciplina',
        'idEstadoDisciplina',
      ),
      EstadoDisciplinaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'estado_matricula',
        '/api/public/estadomatricula',
        'idEstadoMatricula',
      ),
      EstadoMatriculaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'estado_matricula',
        '/api/public/estadomatricula',
        'idEstadoMatricula',
      ),
      EstadoMatriculaDAO,
    );
    Database.register(
      new RESTStrategyV2('grupos', '/api/public/grupos', 'idGrupo'),
      GruposDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'estado_promocion_pase_anio',
        '/api/public/estadopromocionpaseanio',
        'idEstadoPase',
      ),
      EstadoPromocionPaseAnioDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'estado_solicitud_pases',
        '/api/public/estadosolicitudpases',
        'idEstadoSolicitudPases',
      ),
      EstadoSolicitudPasesDAO,
    );
    Database.register(
      new RESTStrategyV2('genero', '/api/public/genero', 'idGenero'),
      GeneroDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'localizacion',
        '/api/public/localizacion',
        'idLocalizacion',
      ),
      LocalizacionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'localizacion_nivel',
        '/api/public/localizacionnivel',
        'idLocalizcionNivel',
      ),
      LocalizacionNivelDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'medio_contacto',
        '/api/public/mediocontacto',
        'idMedioContacto',
      ),
      MedioContactoDAO,
    );
    Database.register(
      new RESTStrategyV2('modalidad', '/api/public/modalidad', 'idModalidad'),
      ModalidadDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'modalidad_nivel',
        '/api/public/modalidadnivel',
        'idModalidadNivel',
      ),
      ModalidadNivelDAO,
    );
    Database.register(
      new RESTStrategyV2('municipio', '/api/public/municipio', 'idMunicipio'),
      MunicipioDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'orientacion',
        '/api/public/orientacion',
        'idOrientacion',
      ),
      OrientacionDAO,
    );
    Database.register(
      new RESTStrategyV2('pais', '/api/public/pais', 'idPais'),
      PaisDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'personal_educativo_cargo',
        '/api/public/personaleducativocargo',
        'idPersonalEducativoCargo',
      ),
      PersonalEducativoCargoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'personal_educativo',
        '/api/public/personaleducativo',
        'idPersonalEducativo',
      ),
      PersonalEducativoDAO,
    );
    Database.register(
      new RESTStrategyV2('provincia', '/api/public/provincia', 'idProvincia'),
      ProvinciaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'responsable',
        '/api/public/responsable',
        'idResponsable',
      ),
      ResponsableDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'supervision',
        '/api/public/supervision',
        'idSupervision',
      ),
      SupervisionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'ciclo_adultos',
        '/api/public/cicloadultos',
        'idCicloAdultos',
      ),
      CicloAdultosDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'tipo_disciplina',
        '/api/public/tipodisciplina',
        'idTipoDisciplina',
      ),
      TipoDisciplinaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'tipo_documento',
        '/api/public/tipodocumento',
        'idTipoDocumento',
      ),
      TipoDocumentoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'tipo_establecimiento',
        '/api/public/tipoestablecimiento',
        'idTipoEstablecimiento',
      ),
      TipoEstablecimientoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'tipo_medio_contacto',
        '/api/public/tipomediocontacto',
        'idTipoMedioContacto',
      ),
      TipoMedioContactoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'tipo_vinculo',
        '/api/public/tipovinculo',
        'idTipoVinculo',
      ),
      TipoVinculoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'unidad_educativa',
        '/api/public/unidadeducativa',
        'idUnidadEducativa',
      ),
      UnidadEducativaDAO,
    );
    Database.register(
      new RESTStrategyV2('vinculo', '/api/public/vinculo', 'idVinculo'),
      VinculoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'planificacion',
        '/api/public/planificacion',
        'idPlanificacion',
      ),
      PlanificacionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'espacio_curricular_plan',
        '/api/public/espaciocurricularplan',
        'idEspacioCurricularPlan',
      ),
      EspacioCurricularPlanDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'espacio_curricular_custom',
        '/api/custom/espaciocurricularcustom',
        'idEspacioCurricularPlan',
      ),
      EspacioCurricularCustomDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'discip_orient_plan',
        '/api/public/disciporientplan',
        'idDiscipOrientPlan',
      ),
      DiscipOrientPlanDAO,
    );
    Database.register(
      new RESTStrategyV2('nivel', '/api/public/nivel', 'idNivel'),
      LevelDAO,
    );
    Database.register(
      new RESTStrategyV2('ciclo', '/api/public/ciclo', 'idCiclo'),
      CycleDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'usuario_rol_establecimiento',
        '/api/public/usuariorolestablecimiento',
        'idRolEstablecimiento',
      ),
      UsuarioRolEstablecimientoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'usuario_rol_dependencia_funcional',
        '/api/public/usuarioroldependenciafuncional',
        'rolDependenciaFuncionalId',
      ),
      UsuarioRolDependenciaFuncionalDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'espacio_curricular',
        '/api/public/espaciocurricular',
        'idEspacioCurricular',
      ),
      EspacioCurricularDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'espacio_curricular_docente',
        '/api/custom/espaciocurriculardocente',
        'idEspacioCurricularSeccion',
      ),
      EspacioCurricularDocenteDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'establecimiento',
        '/api/public/establecimiento',
        'idEstablecimiento',
      ),
      EstablecimientoDAO,
    );
    Database.register(
      new RESTStrategyV2('area', '/api/public/area', 'idArea'),
      AreaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'planificacion_template',
        '/api/templates/planificaciontemplate',
        'idPlanificacionTemplate',
      ),
      PlanificacionTemplateDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'discip_sim_temp_plan',
        '/api/templates/discipsimtempplan',
        'idDiscipSimTempPlan',
      ),
      PlanificacionDisciplinarTemplateDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'simp_discip_plan',
        '/api/public/simpdiscipplan',
        'idSimpDiscipPlan',
      ),
      SimpDiscipPlanDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'areal_temp_plan',
        '/api/templates/arealtempplan',
        'idArealTempPlan',
      ),
      PlanificacionArealTemplateDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'interdiscip_temp_plan',
        '/api/templates/interdisciptempplan',
        'idInterdiscipTempPlan',
      ),
      PlanificacionInterdisciplinarTemplateDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'interdiscip_plan',
        '/api/public/interdiscipplan',
        'idInterdiscipPlan',
      ),
      InterdiscipPlanDAO,
    );
    Database.register(
      new RESTStrategyV2('areal_plan', '/api/public/arealplan', 'idArealPlan'),
      ArealPlanDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'areal_plan_area',
        '/api/public/arealplanarea',
        'idArealPlanArea',
      ),
      ArealPlanAreaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'espacio_curricular',
        '/api/public/espaciocurricular',
        'idEspacioCurricular',
      ),
      EspacioCurricularDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'orient_discip_temp_plan',
        '/api/templates/orientdisciptempplan',
        'idOrientDiscipTempPlan',
      ),
      PlanificacionDisciplinarOrientadaTemplateDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'espacios_simp_discip_plan',
        '/api/public/espaciossimpdiscipplan',
        'idEspaciosSimpDiscipPlan',
      ),
      EspaciosSimpDiscipPlanDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'espacios_interdiscip_plan',
        '/api/public/espaciosinterdiscipplan',
        'idEspaciosInterdiscipPlan',
      ),
      EspaciosInterdiscipPlanDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'espacios_areal_plan',
        '/api/public/espaciosarealplan',
        'idEspaciosArealPlan',
      ),
      EspaciosArealPlanDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'espacios_areas',
        '/api/public/espaciosareas',
        'idEspaciosAreas',
      ),
      EspaciosAreasDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'espacios_orientaciones',
        '/api/public/espaciosorientaciones',
        'idEspaciosOrientaciones',
      ),
      EspaciosOrientacionesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'espacios_discip_orient_plan',
        '/api/public/espaciosdisciporientplan',
        'idEspaciosDiscipOrientPlan',
      ),
      EspaciosDiscipOrientPlanDAO,
    );
    Database.register(
      new RESTStrategyV2('recursos', '/api/public/recursos', 'idRecursos'),
      RecursosDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'habilidades',
        '/api/public/habilidades',
        'idHabilidades',
      ),
      HabilidadesDAO,
    );
    Database.register(
      new RESTStrategyV2('secciones', '/api/public/secciones', 'idSecciones'),
      SeccionesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'instrumentos_de_evaluacion',
        '/api/public/instrumentosdeevaluacion',
        'idInstrumentosDeEvaluacion',
      ),
      InstrumentosDeEvaluacionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'interdiscip_orienta_plan',
        '/api/public/interdisciporientaplan',
        'idInterdiscipOrientaPlan',
      ),
      InterdiscipOrientaPlanDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'objetivos_de_aprendizaje',
        '/api/public/objetivosdeaprendizaje',
        'idObjetivosDeAprendizaje',
      ),
      ObjetivosDeAprendizajeDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'indicadores_de_evaluacion',
        '/api/public/indicadoresdeevaluacion',
        'idIndicadoresDeEvaluacion',
      ),
      IndicadoresDeEvaluacionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'indicadores_evaluacion',
        '/api/calificaciones/indicadoresevaluacion',
        'idIndicadoresEvaluacion',
      ),
      IndicadoresEvaluacionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'propositos_de_ensenanza',
        '/api/public/propositosdeensenanza',
        'idPropositosDeEnsenanza',
      ),
      PropositosDeEnsenanzaDAO,
    );
    Database.register(
      new RESTStrategyV2('bloques', '/api/public/bloques', 'idBloque'),
      BloquesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'propositos_bloques',
        '/api/public/propositosbloques',
        'idPropositoBloque',
      ),
      PropositosBloquesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'objetivos_bloques',
        '/api/public/objetivosbloques',
        'idObjetivoBloque',
      ),
      ObjetivosBloquesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'contenido_especifico',
        '/api/public/contenidoespecifico',
        'idContenidoEspecifico',
      ),
      ContenidoEspecificoDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'localizacion_plan_estudio_nivel',
        '/api/public/localizacionplanestudionivel',
        'idPlanEstudioLocalizacion',
      ),
      LocalizacionPlanEstudioNivelDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'contenido_bloque',
        '/api/public/contenidobloque',
        'idContenidoBloque',
      ),
      ContenidoBloqueDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'feedback_plan_seccion',
        '/api/public/feedbackplanseccion',
        'idFeedbackPlanSeccion',
      ),
      FeedbackPlanSeccionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'feedback_plan_item',
        '/api/public/feedbackplanitem',
        'idFeedbackPlanItem',
      ),
      FeedbackPlanItemDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'areal_plan_feedback',
        '/api/public/arealplanfeedback',
        'idArealPlanFeedback',
      ),
      ArealPlanFeedbackDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'feedback_plan_opcion',
        '/api/public/feedbackplanopcion',
        'idFeedbackPlanOpcion',
      ),
      FeedbackPlanOpcionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'simp_discip_plan_feedback',
        '/api/public/simpdiscipplanfeedback',
        'idSimpDiscipPlanFeedback',
      ),
      SimpDiscipPlanFeedbackDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'interdiscip_plan_feedback',
        '/api/public/interdiscipplanfeedback',
        'idInterdiscipPlanFeedback',
      ),
      InterdiscipPlanFeedbackDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'discip_orient_plan_feedback',
        '/api/public/disciporientplanfeedback',
        'idDiscipOrientPlanFeedback',
      ),
      DiscipOrientPlanFeedbackDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'repositorio_item',
        '/api/repositorio/repositorioitem',
        'idRepositorioItem',
      ),
      RepositorioItemDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'repositorio_tipo_item',
        '/api/repositorio/repositoriotipoitem',
        'idRepositorioTipoItem',
      ),
      RepositorioTipoItemDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'repositorio_tipo_recurso',
        '/api/repositorio/repositoriotiporecurso',
        'idTipoRecurso',
      ),
      RepositorioTipoRecursoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'repositorio_audiencia_item',
        '/api/repositorio/repositorioaudienciaitem',
        'idRepositorioAudienciaItem',
      ),
      RepositorioAudienciaItemDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'repositorio_areas',
        '/api/repositorio/repositorioareas',
        'idRepositorioArea',
      ),
      RepositorioAreasDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'repositorio_ciclo',
        '/api/repositorio/repositoriociclo',
        'idRepositorioCiclo',
      ),
      RepositorioCicloDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'repositorio_transversales',
        '/api/repositorio/repositoriotransversales',
        'idTransversal',
      ),
      RepositorioTransversalesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'repositorio_espacios_curriculares',
        '/api/repositorio/repositorioespacioscurriculares',
        'idRepositorioEspaciosCurriculares',
      ),
      RepositorioEspaciosCurricularesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'legajo_alumno',
        '/api/public/legajoalumno',
        'idLegajoAlumno',
      ),
      LegajoAlumnoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'legajo_alumno_template',
        '/api/templates/legajoalumnotemplate',
        'idLegajoAlumnoTemplate',
      ),
      LegajoAlumnoTemplateDAO,
    );
    Database.register(
      new RESTStrategyV2('baja_motivo', '/api/public/bajamotivo', 'idMotivo'),
      BajaMotivoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'actividad_extracurricular',
        '/api/public/actividadextracurricular',
        'idActividadExtracurricular',
      ),
      ActivityExtraCurricularDAO,
    );
    Database.register(
      new RESTStrategyV2('planta', '/api/public/planta', 'idPlanta'),
      PlantaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'grupos_seccion_alumno',
        '/api/public/gruposseccionalumno',
        'idGruposSeccionAlumno',
      ),
      GruposSeccionAlumnoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'grupos_extra_alumno',
        '/api/public/gruposextraalumno',
        'idGruposExtraAlumno',
      ),
      GruposExtraAlumnoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'grupos_turnos',
        '/api/public/gruposturnos',
        'idGrupoTurno',
      ),
      GruposTurnosDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'geolocalizacion',
        '/api/public/geolocalizacion',
        'idGeolocalizacion',
      ),
      GeolocalizacionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'grupos_espacios',
        '/api/public/gruposespacios',
        'idGrupoEspacios',
      ),
      GruposEspaciosDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'grupos_extracurricular',
        '/api/public/gruposextracurricular',
        'idGrupoExtracurricular',
      ),
      GruposExtracurricularDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'grupos_docentes',
        '/api/public/gruposdocentes',
        'idGrupoDocente',
      ),
      GruposDocentesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'grupos_ciclo_lectivo',
        '/api/public/gruposciclolectivo',
        'idGrupoCicloLectivo',
      ),
      GruposCicloLectivoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'grupos_plan_estudio_nivel',
        '/api/public/gruposplanestudionivel',
        'idGrupoPlanEstudioNivel',
      ),
      GruposPlanEstudioNivelDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'grupos_seccion',
        '/api/public/gruposseccion',
        'idGrupoSeccion',
      ),
      GruposSeccionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'plan_estudio_orientacion',
        '/api/public/planestudioorientacion',
        'idPlanEstudioOrientacion',
      ),
      PlanEstudioOrientacionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'atencion_hospitalaria',
        '/api/public/atencionhospitalaria',
        'idAtencionHtlaria',
      ),
      AtencionHospitalariaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'calendario_vacunacion',
        '/api/public/calendariovacunacion',
        'idCalenVacunacion',
      ),
      CalendarioVacunacionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'cant_dias_semana_lab',
        '/api/public/cantdiassemanalab',
        'idCantDiasSemana',
      ),
      CantDiasSemanaLabDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'cond_anio_ant',
        '/api/public/condanioant',
        'idCondAnioAnt',
      ),
      CondAnioAntDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'datos_laborales_resp',
        '/api/public/datoslaboralesresp',
        'idDatosLab',
      ),
      DatosLaboralesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'estado_civil',
        '/api/public/estadocivil',
        'idEstadoCivil',
      ),
      EstadoCivilDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'legajo_anio_lectivo',
        '/api/public/legajoaniolectivo',
        'idAnioLectivo',
      ),
      LegajoAnioLectivoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'nivel_educ_alcanzado',
        '/api/public/niveleducalcanzado',
        'idNivelEducAlcan',
      ),
      NivelEduAlcanzadoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'plan_social',
        '/api/public/plansocial',
        'idPlanSocial',
      ),
      PlanSocialDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'tipo_acompaniante',
        '/api/public/tipoacompaniante',
        'idTipoAcom',
      ),
      TipoAcompananteDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'tipo_direccion',
        '/api/public/tipodireccion',
        'idTipoDireccion',
      ),
      TipoDireccionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'tipo_domicilio',
        '/api/public/tipodomicilio',
        'idTipoDomicilio',
      ),
      TipoDomicilioDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'espacio_curricular_seccion',
        '/api/public/espaciocurricularseccion',
        'idEspacioCurricularSeccion',
      ),
      EspacioCurricularSeccionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'plan_estudio_materia',
        '/api/public/planestudiomateria',
        'idPlanEstudioMateria',
      ),
      PlanEstudioMateriaDAO,
    );
    Database.register(
      new RESTStrategyV2('rolstatus', '/api/public/rolstatus', 'idRolStatus'),
      RolStatusDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'autoregistracion',
        '/api/public/autoregistracion',
        'idAutoregistracion',
      ),
      AutoRegistracionDAO,
    );
    Database.register(
      new RESTStrategyV2('materia', '/api/public/materia', 'idMateria'),
      MateriaDAO,
    );
    Database.register(
      new RESTStrategyV2('relogin', '/platform/relogin', 'pkrelogin'),
      ReloginDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'inscripcion_alumno_custom',
        '/api/public/inscripcionalumnocustom',
        'pkInscripcionAlumnoCustom',
      ),
      InscripcionAlumnoCustomDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'usuario_rol_traverse_action',
        '/api/security/usuarioRolTraverseAction',
        'usuarioRolTraverseAction',
      ),
      UsuarioRolTraverseActionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'permission',
        '/api/security/permission',
        'permissionId',
      ),
      PermissionDAO,
    );
    Database.register(
      new RESTStrategyV2('endpoint', '/api/security/endpoint', 'endpointId'),
      EndpointDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'permission_endpoint_method',
        '/api/security/permissionendpointmethod',
        'permissionEndpointMethodId',
      ),
      PermissionEndpointMethodDAO,
    );
    Database.register(
      new RESTStrategyV2('endpoint_method', '/api/security/method', 'methodId'),
      MethodDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'comentario',
        '/api/comunicacion/comentario',
        'idComentario',
      ),
      ComentarioDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'publicacion',
        '/api/comunicacion/publicacion',
        'idPublicacion',
      ),
      PublicacionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'secundaria_futuro',
        '/api/public/secundariaFuturo',
        'idSecundariaFuturo',
      ),
      SecundariaFuturoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'seccion_aula_virtual',
        '/api/public/seccionaulavirtual',
        'idSeccionAulaVirtual',
      ),
      SeccionAulaVirtualDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'grupos_aula_virtual',
        '/api/public/gruposaulavirtual',
        'idGruposAulaVirtual',
      ),
      GruposAulaVirtualDAO,
    );
    Database.register(
      new RESTStrategyV2('admin', '/platform/admin', 'pkadmin'),
      AdminDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'tipo_informes',
        '/api/public/tipoInformes',
        'idTipoInforme',
      ),
      TipoInformesDAO,
    );
    Database.register(
      new RESTStrategyV2('informes', '/api/public/informes', 'idInforme'),
      InformesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'usuariosroles',
        '/api/public/usuariosroles',
        'pkusuariosroles',
      ),
      UsuariosRolesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'alumnoexiste',
        '/api/public/alumnoexiste',
        'pkalumnoexiste',
      ),
      AlumnoExisteDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'informesgenerados',
        '/api/public/informesgenerados',
        'idProceso',
      ),
      InformesGeneradosDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'calificaciones_secundario',
        '/api/public/calificacionessecundario',
        'idCalificacionSecundario',
      ),
      CalificacionesSecundarioDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'calificaciones_primario',
        '/api/public/calificacionesprimario',
        'idCalificacionPrimario',
      ),
      CalificacionesPrimarioDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'tipo_calificacion',
        '/api/public/tipoCalificacion',
        'idTipoCalificacion',
      ),
      tipoCalificacionDAO,
    );
    Database.register(
      new RESTStrategyV2('periodo', '/api/public/periodo', 'idPeriodo'),
      PeriodoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'tipo_periodo',
        '/api/public/tipoPeriodo',
        'idTipoPeriodo',
      ),
      TipoPeriodoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'PeriodoNuevo',
        '/api/public/periodonuevo',
        'idPeriodoNuevo',
      ),
      PeriodoNuevoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'calificacionescustom',
        '/api/public/custom/calificacionescustom',
        'pkcalificaciones',
      ),
      CalificacionesCustomDAO,
    );
    Database.register(
      new RESTStrategyV2('proyeccioncustom', '/api/public/proyeccioncustom'),
      ProyeccionCustomDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'distritoscustom',
        '/api/public/custom/listadistritos',
      ),
      DistritosCustomDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'distritoslocalizacionescustom',
        '/api/public/custom/listalocalizaciones',
      ),
      DistritosLocalizacionesCustomDAO,
    );
    Database.register(
      new RESTStrategyV2('removecardcustom', '/api/public/custom/removecard'),
      RemoveCardCustomDAO,
    );
    Database.register(
      new RESTStrategyV2('procesos', '/api/public/procesos', 'idProceso'),
      ProcesosDAO,
    );
    Database.register(
      new RESTStrategyV2('generarexcel', '/api/public/generarexcel'),
      GenerarExcelDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'informesc',
        '/api/calificaciones/informesc',
        'idInformesc',
      ),
      InformescDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'que_haceres_custom',
        '/api/calificaciones/quehacerescustom',
        'idQueHaceres',
      ),
      QueHaceresCustomDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'que_haceres',
        '/api/calificaciones/quehaceres',
        'idQueHaceres',
      ),
      QueHaceresDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'aspectos_generales_custom',
        '/api/calificaciones/aspectosgeneralescustom',
        'idAspectosGenerales',
      ),
      AspectosGeneralesCustomDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'informesc_custom',
        '/api/calificaciones/informescustom',
        'idInformesc',
      ),
      InformescCustomDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'aspectos_generales',
        '/api/calificaciones/aspectosgenerales',
        'idAspectosGenerales',
      ),
      AspectosGeneralesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'convivencia',
        '/api/calificaciones/convivencia',
        'idConvivencia',
      ),
      ConvivenciaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'convivencia_custom',
        '/api/calificaciones/convivenciacustom',
        'idConvivencia',
      ),
      ConvivenciaCustomDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'informeshistory',
        '/api/documents/informeshistory',
        'informeHistoryId',
      ),
      InformesHistoryDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'tipo_deuda_academica',
        '/api/public/tipodeudaacademica',
        'idTipoDeudaAcademica',
      ),
      TipoDeudaAcademicaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'deuda_academica',
        '/api/public/deudaacademica',
        'idDeudaAcademica',
      ),
      DeudaAcademicaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'primario_custom',
        '/api/calificaciones/primariocustom',
        'idPrimario',
      ),
      PrimarioCustomDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'primario',
        '/api/calificaciones/primario',
        'idPrimario',
      ),
      PrimarioDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'secundario_custom',
        '/api/calificaciones/secundariocustom',
        'idConocimiento',
      ),
      SecundarioCustomDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'secundario',
        '/api/calificaciones/secundario',
        'idConocimiento',
      ),
      SecundarioDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'calificacionessecundarioanual',
        '/api/calificaciones/calificacionessecundarioanual',
        'idSecundarioAnual',
      ),
      AnualDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'observacionesc',
        '/api/calificaciones/observacionesc',
        'idObservacionesc',
      ),
      ObservacionescDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'observacionesc_custom',
        '/api/calificaciones/observacionesccustom',
        'idObservacionesc',
      ),
      ObservacionescCustomDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'estado_propuesta',
        '/api/presentismo/estadopropuesta',
        'idEstadoPropuesta',
      ),
      EstadoPropuestaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'tipo_estado_propuesta',
        '/api/presentismo/tipoestadopresente',
        'idTipoEstadoPresente',
      ),
      TipoEstadoPresenteDAO,
    );
    Database.register(
      new RESTStrategyV2('presente', '/api/presentismo/presente', 'idPresente'),
      PresenteDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'pedagogia',
        '/api/presentismo/pedagogia',
        'idPedagogia',
      ),
      PedagogiaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'estado_presente',
        '/api/presentismo/estadopresente',
        'idEstadoPresente',
      ),
      EstadoPresenteDAO,
    );
    Database.register(
      new RESTStrategyV2('reporte', '/api/presentismo/reporte'),
      ReporteDAO,
    );
    Database.register(
      new RESTStrategyV2('reportesquery', '/api/reportes/query'),
      ReportDAO,
    );
    Database.register(
      new RESTStrategyV2('report', '/api/documents/report', 'reportId'),
      ReportDownloadDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'proyeccion',
        '/api/public/proyeccion',
        'idProyeccion',
      ),
      ProyeccionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'proyeccionmatriculacion',
        '/api/public/proyeccionmatriculacion',
        'idProyeccion',
      ),
      ProyeccionMatriculacionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'proyeccion_estado',
        '/api/public/proyeccionestado',
        'idProyeccionEstado',
      ),
      ProyeccionEstadoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'proyeccion_estado_tipo',
        '/api/public/proyeccionestadotipo',
        'idProyeccionEstadoTipo',
      ),
      ProyeccionEstadoTipoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'proyeccionbulkcustom',
        '/api/public/proyeccionbulkcustom',
      ),
      ProyeccionBulkCustomDAO,
    );
    Database.register(
      new RESTStrategyV2('seccionesvacias', '/api/public/seccionesvacias'),
      SeccionesVaciasCustomDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'proyeccionefectivizar',
        '/api/public/proyeccionefectivizar',
      ),
      EfectivizarProyeccionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'secciontieneproyectados',
        '/api/public/custom/secciontieneproyectados',
      ),
      SeccionTieneProyectadosDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'orientacion_materia',
        '/api/public/orientacionmateria',
        'idOrientacionMateria',
      ),
      OrientacionMateriaDAO,
    );
    Database.register(
      new RESTStrategyV2('pps', '/api/calificaciones/pps', 'idPps'),
      PpsDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'sintesis_conceptual',
        '/api/calificaciones/sintesisconceptual',
        'idSintesisConceptual',
      ),
      SintesisConceptualDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'sintesis_conceptual_custom',
        '/api/calificaciones/sintesisconceptualcustom',
      ),
      SintesisConceptualCustomDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'jornada_extendida',
        '/api/calificaciones/jornadaextendida',
        'idJornadaExtendida',
      ),
      JornadaExtendidaDAO,
    );
    Database.register(
      new RESTStrategyV2('je_materias', '/api/public/jemateria', 'idJeMateria'),
      JeMateriasDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'je_indicadores',
        '/api/calificaciones/jeindicador',
        'idJeIndicadores',
      ),
      JeIndicadoresDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'previas_proyecciones',
        '/api/public/previasproyecciones',
        'idPreviasProyecciones',
      ),
      PreviasProyeccionesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'previasproyeccionescustom',
        '/api/public/custom/previasproyeccionescustom',
      ),
      PreviasProyeccionesCustomDAO,
    );
    Database.register(
      new RESTStrategyV2('pip', '/api/calificaciones/pip', 'idPip'),
      PipDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'calificacionesrecuperacion',
        '/api/calificaciones/recuperacion',
      ),
      RecuperacionDAO,
    );
    Database.register(
      new RESTStrategyV2('constantes', '/api/public/constantes'),
      ConstantesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'presente',
        '/api/presentismov2/presente',
        'idPresente',
      ),
      Presentev2DAO,
    );
    Database.register(
      new RESTStrategyV2(
        'estado_presente',
        '/api/presentismov3/estado_presente',
        'idEstadoPresente',
      ),
      EstadoPresenteV3DAO,
    );
    Database.register(
      new RESTStrategyV2(
        'metadata',
        '/api/presentismov2/metadata',
        'idMetadata',
      ),
      MetadataDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'metadata_type',
        '/api/presentismov2/metadatatype',
        'idMetadataType',
      ),
      MetadataTypeDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'metadata_type',
        '/api/presentismov2/metadatatype',
        'idMetadataType',
      ),
      MetadataTypeDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'exceptuados',
        '/api/public/exceptuados',
        'idExcepcion',
      ),
      ExceptuadoDAO,
    );
    Database.register(
      new RESTStrategyV2('alertas', '/api/presentismov2/alertas'),
      AlertasDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'planesestudioseccion',
        '/api/public/custom/pasajeCalf/planestudioseccion',
      ),
      PlanEstudioSeccionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'codigovinculo',
        '/api/public/codigovinculo',
        'idCodigoVinculo',
      ),
      CodigoVinculoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'organizaciones',
        '/api/acap/organizacion',
        'idOrganizacion',
      ),
      OrganizacionesDAO,
    );
    Database.register(
      new RESTStrategyV2('referentes', '/api/acap/referente', 'idReferente'),
      ReferentesDAO,
    );
    Database.register(
      new RESTStrategyV2('sectores', '/api/acap/sector', 'idSector'),
      SectoresDAO,
    );
    Database.register(
      new RESTStrategyV2('sedes', '/api/acap/sede', 'idSede'),
      SedesDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'tipos_acciones',
        '/api/acap/tiposacciones',
        'idTipoAccion',
      ),
      TiposAccionesDAO,
    );
    Database.register(
      new RESTStrategyV2('ofertas', '/api/acap/ofertas', 'idOferta'),
      OfertasDAO,
    );
    Database.register(
      new RESTStrategyV2('acciones', '/api/acap/acciones', 'idAccion'),
      AccionesDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'accionesMinimizadas',
        '/api/acap/acciones_minimizadas',
        'idAccion',
      ),
      AccionesMinimizadasDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'tipos_acciones',
        '/api/acap/tiposacciones',
        'idTipoAccion',
      ),
      TiposAccionesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'ofertas_grupos',
        '/api/acap/ofertasgrupos',
        'idOfertaGrupo',
      ),
      OfertasGruposDAO,
    );
    Database.register(
      new RESTStrategyV2('ofertas_grupos_minimal', '/api/acap/ofertas_minimal'),
      OfertasGruposMinimalDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'grupos_selecciones',
        '/api/acap/gruposselecciones',
        'idGrupoSeleccion',
      ),
      GruposSeleccionesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'grupos_inscripciones',
        '/api/acap/gruposinscripciones',
        'idGrupoInscripcion',
      ),
      GruposInscripcionesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'grupos_inscripciones_ofertas',
        '/api/acap/gruposinscripciones_ofertas',
        'idGrupoInscripcion',
      ),
      GruposInscripcionesOfertaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'igualarcalificaciones',
        '/api/public/calificaciones/igualarcalificaciones',
      ),
      IgualarCalificacionesDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'informevalorativo',
        '/api/calificaciones/informevalorativo',
        'idInformeValorativo',
      ),
      InformeValorativoDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'configuracion',
        '/api/public/configuracion',
        'idConfiguracion',
      ),
      ConfiguracionDAO,
    );
  }

  registerOrientacionesSchema() {
    Database.register(
      new RESTStrategyV2('nodos', '/api/orientaciones/nodos', 'idNodo'),
      NodosDAO,
    );
    Database.register(
      new RESTStrategyV2('bloques', '/api/orientaciones/bloques', 'idBloque'),
      Bloquesv2DAO,
    );
    Database.register(
      new RESTStrategyV2(
        'contenidos',
        '/api/orientaciones/contenidos',
        'idContenido',
      ),
      ContenidosDAO,
    );
    Database.register(
      new RESTStrategyV2('ejes', '/api/orientaciones/ejes', 'idEje'),
      EjesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'orientaciones.habilidades',
        '/api/orientaciones/habilidades',
        'idHabilidad',
      ),
      Habilidadesv2DAO,
    );
    Database.register(
      new RESTStrategyV2(
        'habilidades_categorias',
        '/api/orientaciones/habilidadescategorias',
        'idHabilidadCategoria',
      ),
      HabilidadesCategoriasDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'objetivos_bloques',
        '/api/orientaciones/objetivosbloques',
        'idObjetivoBloque',
      ),
      ObjetivosBloquesv2DAO,
    );
    Database.register(
      new RESTStrategyV2(
        'orientaciones',
        '/api/orientaciones/orientaciones',
        'idOrientacion',
      ),
      Orientacionesv2DAO,
    );
    Database.register(
      new RESTStrategyV2(
        'perfil_orientado',
        '/api/orientaciones/perfilesorientados',
        'idPerfilOrientado',
      ),
      PerfilOrientadoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'propositos_bloques',
        '/api/orientaciones/propositosbloques',
        'idPropositoBloque',
      ),
      PropositosBloquesv2DAO,
    );
    Database.register(
      new RESTStrategyV2(
        'propositos_orientacion',
        '/api/orientaciones/propositosorientacion',
        'idPropositoOrientacion',
      ),
      PropositosOrientacionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'establecimientos_acap',
        '/api/acap/establecimientos',
        'idEstablecimientosAcap',
      ),
      EstablecimientosAcapDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'createcuentaandpersona',
        '/api/public/createcuentaandpersona',
      ),
      CreateCuentaAndPersonaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'presentismo_asistencia',
        '/api/acap/presentismo_asistencia',
        'idPresentismo',
      ),
      PresentismoAsistenciaDAO,
    );
    Database.register(
      new RESTStrategyV2('dia', '/api/public/dias', 'idDia'),
      DiaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'propuesta_je',
        '/api/presentismov3/propuestas_je',
        'idPropuestaJe',
      ),
      PropuestaJEDAO,
    );

    Database.register(
      new RESTStrategyV2('cierre', '/api/presentismov3/cierre', 'idCierre'),
      CierreDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'presentismotrimestralv3',
        '/api/presentismov3/presentismotrimestralv3',
      ),
      PresentismoTrimestralV3CustomDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'propuesta_je_dia',
        '/api/presentismov3/propuestas_je_dias',
        'idPropuestaJeDia',
      ),
      PropuestaJEDiaDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'estadoproyeccionmotivo',
        '/api/public/estadoproyeccionmotivo',
        'idEstadoProyeccionMotivo',
      ),
      EstadoProyeccionMotivoDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'proyeccioniel',
        '/api/public/proyeccioniel',
        'idProyeccionIEL',
      ),
      ProyeccionIELDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'proyeccionielbulkcustom',
        '/api/public/proyeccionielbulkcustom',
      ),
      ProyeccionIELBulkCustomDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'contraturnopresentecustom',
        '/api/public/contraturnopresente',
      ),
      ContraturnoPresenteCustomDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'situacion',
        '/api/presentismov2/situaciones',
        'idSituacion',
      ),
      SituacionDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'certificados_septimo',
        '/api/certificados_septimo',
        'idAlumno',
      ),
      CertificadosSeptimoDAO,
    );

    Database.register(
      new RESTStrategyV2('motivo', '/api/presentismov2/motivos', 'idMotivo'),
      MotivoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'justificacionMotivos',
        '/api/presentismov3/motivos_justificacion',
        'idMotivoJustificacion',
      ),
      JustificacionMotivoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'justificacionRazon',
        '/api/presentismov3/razon_justificacion',
        'idRazonJustificacion',
      ),
      JustificacionRazonDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'justificacion',
        '/api/presentismov3/justificacion',
        'idJustificacion',
      ),
      JustificacionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'presente_v3',
        '/api/presentismov3/presente',
        'idPresente',
      ),
      Presente_v3DAO,
    );
    Database.register(
      new RESTStrategyV2('contraturno', '/api/contraturno', 'idContraturno'),
      ContraturnoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'contraturno_dia',
        '/api/contraturno_dia',
        'idContraturnoDia',
      ),
      ContraturnoDiaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'periodoInicio',
        '/api/presentismov3/periodo_inicio',
        'idPeriodoInicio',
      ),
      PeriodoInicioDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'observacionesboletin',
        '/api/presentismov3/observacion_boletin',
        'idObservacionBoletin',
      ),
      ObservacionesBoletinDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'exceptuadosv3',
        '/api/presentismov3/exceptuados',
        'idExceptuado',
      ),
      ExceptuadosV3DAO,
    );
    Database.register(
      new RESTStrategyV2(
        'fecha_bloqueada_v3',
        '/api/presentismov3/fechas_bloqueadas',
      ),
      FechaBloqueadaV3DAO,
    );
    Database.register(
      new RESTStrategyV2(
        'motivo_exceptuado',
        '/api/presentismov3/motivos_exceptuado',
      ),
      MotivosExceptuadoDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'situaciones',
        '/api/presentismov3/situaciones',
        'idSituacion',
      ),
      SituacionV3DAO,
    );
    Database.register(
      new RESTStrategyV2(
        'motivo_tipo_situacion',
        '/api/presentismov3/motivo_situacion',
      ),
      MotivoTipoSituacionV3DAO,
    );
    Database.register(
      new RESTStrategyV2('motivos', '/api/presentismov3/motivos'),
      MotivoSituacionV3DAO,
    );
    Database.register(
      new RESTStrategyV2(
        'tipo_situacion',
        '/api/presentismov3/tipos-situaciones',
      ),
      TipoSituacionV3DAO,
    );
    Database.register(
      new RESTStrategyV2(
        'confirmacion_matricula',
        '/api/public/confirmacionmatricula',
        'idConfirmacionMatricula',
      ),
      ConfirmacionMatricula2DAO,
    );
    Database.register(
      new RESTStrategyV2(
        'confirmacion_matricula_generate',
        '/api/public/confirmacionmatriculagenerate',
        'idConfirmacionMatriculaGenerate',
      ),
      ConfirmacionMatriculaGenerateDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'verificacion_conf_matricula',
        '/api/public/matriculacion/verificacion_conf_matricula',
      ),
      VerificacionConfMatriculaDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'confirmacion_matricula_criterio',
        '/api/public/confirmacionmatriculacriterio',
      ),
      ConfirmacionMatriculaCriterioDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'confirmacion_matricula_localizacion',
        '/api/public/confirmacionmatriculalocalizacion',
      ),
      ConfirmacionMatriculaLocalizacionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'dependencia_funcional_nivel',
        '/api/public/dependenciafuncionalnivel',
      ),
      DependenciaFuncionalNivelDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'motivo_situacion',
        '/api/presentismov3/motivo_situacion',
      ),
      MotivoTipoSituacionV3DAO,
    );
    Database.register(
      new RESTStrategyV2(
        'presente_espacio_curricular',
        '/api/presentismov3/presente_espacio_curricular',
        'idPresenteEspacioCurricular',
      ),
      PresentismoEspacioCurricularDAO,
    );
    Database.register(
      new RESTStrategyV2(
        DAO_NAME,
        '/api/presentismov3/diashabilitados',
        'idDiasHabilitados',
      ),
      DiasHabilitadosDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'devoluciones_habilidades',
        '/api/acap/devoluciones_habilidades',
        'idAutoevaluacionHabilidad',
      ),
      DevolucionHabilidadesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'devoluciones_contenidos',
        '/api/acap/devoluciones_contenidos',
        'idAutoevaluacionContenido',
      ),
      DevolucionContenidosDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'devoluciones_perfiles',
        '/api/acap/devoluciones_perfiles',
        'idAutoevaluacionPerfil',
      ),
      DevolucionPerfilesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'devoluciones_organizacion',
        '/api/acap/devoluciones_organizacion',
        'idDevolucionOrganizacion',
      ),
      DevolucionOrganizacionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'devoluciones_evaluaciones',
        '/api/acap/devoluciones_evaluaciones',
        'idDevolucionValor',
      ),
      DevolucionesEvaluacionesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'evaluaciones_devoluciones',
        '/api/acap/evaluaciones_devoluciones',
        'idDevolucionEvaluacion',
      ),
      EvaluacionesDevolucionesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'situacion_generate',
        '/api/presentismov3/situacionGenerate',
        'idSituacionGenerate',
      ),
      SituacionGenerateDAO,
    );
  }

  registerACAP5Schema() {
    Database.register(
      new RESTStrategyV2(
        'acciones_solo_orientaciones',
        '/api/acap/acciones_solo_orientaciones',
      ),
      AccionesSoloOrientacionesDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'triada_acap_inscripcion',
        '/api/acap/inscripcion_nodos',
      ),
      ListEstudianteNodosDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'accionesorientaciones',
        '/api/acap/accionesorientaciones',
        'idAccionOrientacion',
      ),
      AccionesOrientacionesDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'acap.criterios_evaluacion',
        '/api/acap/criterios_evaluacion',
        'idCriterioEvaluacion',
      ),
      CriteriosEvaluacionDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'acap.apreciaciones_actividades',
        '/api/acap/apreciaciones_actividades',
        'idApreciacion',
      ),
      ApreciacionActividadDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'acap.apreciaciones_valores',
        '/api/acap/apreciaciones_valores',
        'idApreciacionValor',
      ),
      ApreciacionValorDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'acap.autoevaluacion_estudiante',
        '/api/acap/autoevaluaciones_estudiante',
      ),
      AutoevaluacionesEstudianteDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'acciones_orientaciones_custom',
        '/api/acap/accionesorientacionescustom',
        'idAccionorientacion',
      ),
      AccionesOrientacionesCustomDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'grupoincripcion_autoevaluacion',
        '/api/acap/grupoincripcion_autoevaluacion',
        'idGrupoInscripcion',
      ),
      GrupoInscripcionAutoevaluadosDAO,
    );
    Database.register(
      new RESTStrategyV2(
        'acap.grupos_selecciones_custom',
        '/api/acap/grupos_selecciones',
      ),
      GrupoSeleccionCustomDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'efectivizacion_custom',
        '/api/public/efectivizacioncustom',
      ),
      EfectivizacionCheckCustomDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'historico_condiciones',
        '/api/public/historicos_condiciones',
        'idHistoricoCondicion',
      ),
      HistoricoCondicionesDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'regularidad',
        '/api/public/regularidad',
        'idRegularidad',
      ),
      RegularidadDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'info_plataforma',
        '/api/public/info_plataforma',
        'idInfoPlataforma',
      ),
      InfoPlataformaDAO,
    );

    Database.register(
      new RESTStrategyV2(
        'matriculacion_seccion',
        '/api/matriculacion/matriculacionseccion',
        'idSeccion',
      ),
      MatriculacionSeccionDAO,
    );
  }

  init(): void {
    Libby.App = this.chinoTokenEnvironmet;
    this.addSession();
    this.register();
  }
}
const i = new DatabaseStoreRaw();
export { i as DatabaseStore };
