import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { Presente } from '../../models/presentismo_v2';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'presente';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPresente',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPresente',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Presente
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Presente,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Presente.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Presente>(DAO_NAME, optionsToUse);

// hooks
export const usePresenteFetchById = fetchById;
export const usePresenteLibbyCall = libbyCall;
export const usePresenteDAO = libbyDAO;
export const usePresenteLibbyFetch = libbyFetch;

// components/hoc
export const PresenteAutocomplete = entityAutocomplete;
export const PresenteCall = renderPropCall;
export const PresenteById = renderPropFetchById;
export const PresenteSelect = simpleSelect;
export const PresenteAccordionSelector = accordionSelector;
export const PresenteSelectedChips = selectedChips;

// context
// ByIdContext
export const usePresenteByIdContext = useByIdContext;
export const PresenteByIdProvider = ByIdProvider;
export const PresenteByIdContext = ByIdContext;
// GeneralContext
export const usePresenteContext = useGeneralContext;
export const PresenteProvider = GeneralProvider;
export const PresenteContext = GeneralContext;
