import React, { useMemo } from 'react';
import customFormDialog from 'src/commons/services/customFormDialog';
import { Button, Grid, makeStyles } from '@material-ui/core';
import { InfoTable } from 'src/commons';
import CloseIcon from '@material-ui/icons/Close';
import { useEspaciosCurricularesTable } from '../hooks/useEspaciosCurricularesTable';
import { useCalificacionesstateGetRequest } from 'src/app/business/businessCustomEndpoints/calificacionesstate';
import { usePpsLibbyFetch } from 'src/app/business';
import { BoletinStateRow, ResponseGenerarInforme } from '../types';
import { AlumnoMovimiento } from 'src/app/models';
import { quitarAcentos } from 'src/utils/types/quitarAcentos';

const useStyles = makeStyles((theme) => ({
  tableCustomStyle: {
    maxWidth: '95%',
    margin: 'auto',
  },
  closeButton: {
    marginTop: theme.spacing(-8),
    marginRight: theme.spacing(4),
    position: 'relative',
  },
}));

interface Modal {
  item: ResponseGenerarInforme & {
    idPeriodo: number;
    alumnos: AlumnoMovimiento[];
    idNivel: number;
    isPip: boolean;
    isPps: boolean;
  };
  request: () => void;
  reFetchBoletin: () => void;
}

export const ModalEspaciosCurricularesPendientes = ({
  item,
  request,
  reFetchBoletin,
}: Modal) => {
  const classes = useStyles();

  const {
    data: espaciosCurricularesFetch = [],
    request: reFetch,
    working,
  } = useCalificacionesstateGetRequest<BoletinStateRow[]>({
    url: `/api/calificaciones/calificacionesstate?secciones=${item.seccion.idSeccion}&nivel=${item.idNivel}&periodo=${item.idPeriodo}`,
    autoCall: true,
  });

  const ppsFilter = useMemo(
    () => ({
      alumnos: [
        {
          path: 'alumno.idAlumno',
          value: item?.pps?.map((_pps) => _pps.alumno.idAlumno),
          method: 'in',
        },
      ],
    }),
    [item?.pps],
  );

  const {
    data: pps = [],
    reFetch: ppsReFetch,
    working: ppsWorking,
  } = usePpsLibbyFetch({
    limit: 1000,
    filter: ppsFilter,
    enabled: item?.alumnos.length > 0,
    checkDuplication: false,
    aspect: 'basic',
  });
  const handleCloseModal = () => {
    customFormDialog.handleCancel();
  };
  const columns = useEspaciosCurricularesTable(
    item.idPeriodo,
    request,
    reFetch,
    item,
    ppsReFetch,
    pps,
    reFetchBoletin,
  );

  const alumnosFiltered = useMemo(
    () =>
      item?.alumnos
        .filter(
          (element) => element?.seccion?.idSeccion === item?.seccion?.idSeccion,
        )
        .sort((a, b) => {
          const _nombreA =
            `${a.alumno.persona.apellido}, ${a.alumno.persona.nombre}`.trim();
          const _nombreB =
            `${b.alumno.persona.apellido}, ${b.alumno.persona.nombre}`.trim();
          return quitarAcentos(_nombreA).localeCompare(quitarAcentos(_nombreB));
        }),
    [item],
  );

  const espaciosOrdered = useMemo(
    () =>
      espaciosCurricularesFetch.sort(
        (ecA: { descripcion: string }, ecB: { descripcion: string }) => {
          return ecA.descripcion > ecB.descripcion &&
            !ecB.descripcion.includes('Extendida')
            ? 1
            : -1;
        },
      ),
    [espaciosCurricularesFetch],
  );

  const modalRows = item?.isPps ? alumnosFiltered : espaciosOrdered;

  return (
    <>
      <Grid item container justifyContent="flex-end">
        <Button className={classes.closeButton} onClick={handleCloseModal}>
          <CloseIcon />
        </Button>
      </Grid>
      <>
        {!working && !ppsWorking && (
          <InfoTable
            working={working || ppsWorking}
            rows={modalRows}
            columns={columns}
            rowIdKey="nombreSeccion"
            customStyle={classes.tableCustomStyle}
          />
        )}
      </>
    </>
  );
};
