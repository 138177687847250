interface selectColumnType {
  value: string;
  label: string;
  path: string;
}

export const selectColumns: selectColumnType[] = [
  {
    value: '1',
    label: 'Apellido',
    path: 'alumno.persona.apellido',
  },
  {
    value: '2',
    label: 'Documento',
    path: 'alumno.persona.documento',
  },
  {
    value: '3',
    label: 'Establecimiento de origen',
    path: 'seccion.localizacion.descripcion',
  },
];

export interface PassRequestRow {
  Establacemiento_origen: string;
  alumno: React.ReactElement;
  cycle: string;
  document: string;
  establecimiento_destino: string;
  estado: React.ReactElement;
  fecha: string;
  icon: React.ReactElement;
  id: string;
  level: string;
  option: any;
}
