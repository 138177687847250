import { makeBusinessEntity } from '../../../lib/libby/generator';
import { AccionesMinimizadas } from '../../models';

const DAO_NAME = 'accionesMinimizadas';

const {
  hook: { libbyDAO, libbyFetch, libbyCall },
} = makeBusinessEntity<AccionesMinimizadas>(DAO_NAME, {});

// hooks
export const useAccionesMinimizadasDAO = libbyDAO;
export const useAccionesMinimizadasLibbyFetch = libbyFetch;
export const useAccionesMinimizadasLibbyCall = libbyCall;
