import { CommonEndpointDAO } from './CommonEndpointDAO';

export class RecuperacionesStateDAO extends CommonEndpointDAO {
  constructor() {
    super('recuperacionesstate');
  }

  post(body: any = {}): any {
    return super.post(body);
  }
}
