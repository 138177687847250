import React, { FC, useCallback, useMemo, useState, useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { PresentismoTable } from './PresentismoTable';
import {
  NIVEL,
  useDebounce,
  useDebounceCall,
  useInternetConnectionCheck,
} from '../../../../../commons';
import { PresentismoHeader } from './PresentismoHeader';
import moment from 'moment';
import {
  AlertasECV3,
  AlertasV3,
  AlumnoMap,
  JustificacionOpen,
  PresenteGeneral,
  PrestesTrimestral,
  StudentReportOpen,
} from '../types';
import useWorldTime from '../hooks/useWorldTime';
import {
  useAlertasGetRequest,
  useAlumnoMovimientoCustomLibbyFetch,
  useAlumnoMovimientoLibbyFetch,
  usePresentismoEspacioCurricularLibbyFetch,
  useSolicitudPasesLibbyFetch,
} from '../../../../../app/business';
import { setSearchParamsMultipleWords } from '../../../../../utils/setSearchParamsMultipleWords';
import {
  usePresenteV3DAO,
  usePresenteV3LibbyFetch,
} from '../../../../../app/business/presentismo_v3/Presente';
import { usePresentismoEspacioCurricularDAO } from '../../../../../app/business';
import { cloneDeep } from 'lodash';
import { PeriodoInicio, Seccion } from '../../../../../app/models';
import {
  Jornada,
  useMultiPresentismoChecks,
  usePresentismoContext,
} from '../context';
import {
  useExceptuadosV3LibbyCall,
  usePeriodoInicioLibbyFetch,
  usePresentismoTrimestralV3CustomGet,
} from '../../../../../app/business/presentismo_v3';
import { NIVELES_AUTHORIZED } from '../utils/rolesPresentismo';
import { useRolesContext } from '../../../../../context/RolesContext';
import useGenerarExcel from '../../../../../commons/hooks/useGenerarExcel';
import { usePresentismoExcelData_v3 } from '../hooks/usePresentismoExcelData_v3';
import { useSnackbar } from 'notistack';
import { useEnableToSaveByRol } from '../hooks/useEnableToSaveByRol';
import { useContraturnoDiaLibbyFetch } from '../../../../../app/business/presentismo_v3/ContraturnoDia';
import { Justificacion } from '../../Justificacion/Justificacion';
import {
  DateRangeListV3,
  ExceptuadosDateRangeV3,
} from './ExceptuadosModal/types';
import { PresentismoFooter } from './PresentismoFooter';
import { getEspacioJE } from '../utils/getEspacioJE';
import { StudentReport } from '../../ReporteAlumno';
import { CustomDialog } from './PresentismoTable/components';
import { usePresentismoExcelSemanalData_v3 } from '../hooks/usePresentismoExcelSemanalData_v3';
import { mapPresentesByEc } from '../utils/mapPresentesByEc';
import { JUSTIFICACION_ESTADOS } from '../utils/EstadoPresente';
import { getPresentesMaster } from './PresentismoTable/functions/getPresentesMaster';
import { useDiasHabilitadosLibbyFetch } from '../../../../../app/business/DiasHabilitados';
import { useParams } from 'react-router';
import { usePresentismoExcelSemanalGeneralConJEData_v3 } from '../hooks';
import { useECContext } from 'src/screens/Private/EspaciosCurriculares/EspacioCurricular/components/ECProvider';
import { useRouterContext } from 'src/lib/router';
import { isNetworkError } from 'src/utils/isNetworkError';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
export interface TableProps {
  isTabJornadaExtendida: boolean;
  espacioCurricularSelected?: string;
}
interface StructureUrlProps {
  idEspacioCurricular: string;
  idAnio: string;
}

export const Table: FC<TableProps> = ({
  isTabJornadaExtendida,
  espacioCurricularSelected,
}) => {
  const { ecs: espacioCurricularList } = useECContext();
  const { idAnio } = useParams<StructureUrlProps>();
  const serverDate = useWorldTime();
  const [isLoading, setIsLoading] = useState(false);
  const [loadingAlumnos, setLoadingAlumnos] = useState(false);
  const [anio, setAnio] = useState<string | null>(idAnio);
  const [selectedEspacioCurricular, setSelectedEspacioCurricular] = useState(
    espacioCurricularSelected,
  );
  const { metadata } = useRouterContext();

  const secionEC = espacioCurricularList.find(
    (ec) => ec.idEspacioCurricularSeccion === selectedEspacioCurricular,
  )?.seccion;

  const [section, setSection] = useState<string | null>(
    secionEC?.idSeccion || metadata.idSeccion || null,
  );
  const [openJustificacion, setOpenJustificacion] = useState<JustificacionOpen>(
    {
      open: false,
      row: null,
    },
  );
  const [openStudentReport, setOpenStudentReport] = useState<StudentReportOpen>(
    {
      open: false,
      row: null,
    },
  );
  const [tipoJornada, setTipoJornada] = useState<Jornada | null>(null);
  const [alumnoToSearch, setSearch] = useState('');
  const [alumnoPresentismo, setAlumnoPresentismo] = useState<AlumnoMap[]>([]);

  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [jornadaExtendidaValidationDone, setJornadaExtendidaValidationDone] =
    useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);

  const presenteV3DAO = usePresenteV3DAO();
  const presenteECDAO = usePresentismoEspacioCurricularDAO();
  const { enqueueSnackbar } = useSnackbar();
  const generateExcel = useGenerarExcel();
  const {
    newPresentismos,
    updatePresentismos,
    currentWeekState,
    setNewPresentismos,
    setUpdatePresentismos,
    setDBPresentismos,
    setIsJornadaExtendida,
    propuestasJE,
    currentWeek,
    pastDaysOfWeek,
    setCurrentWeek,
    setPastDaysOfWeek,
    feriados,
    situaciones,
    periodoNuevo,
    loading,
  } = usePresentismoContext();

  const { turno, setAlumnos, setTurno, setMasiveChecks } =
    useMultiPresentismoChecks();

  const searchDebounced = useDebounce(alumnoToSearch, 1000);

  const {
    selectedRole: { nivel, localizacion, localizacionId, rol },
    userInfo: { id: userInfoId },
  } = useRolesContext();

  const { cicloLectivo } = useColegioEfectivizadoContext();

  // ALUMNOS SEARCH
  const filterSeccion = useMemo(
    () => ({
      seccion: [{ path: 'seccion.idSeccion', value: section }],
      ...setSearchParamsMultipleWords(
        [
          'alumno.persona.nombre',
          'alumno.persona.apellido',
          'alumno.persona.documento',
        ],
        searchDebounced,
      ),
    }),
    [searchDebounced, section],
  );
  // END ALUMNOS SEARCH
  const {
    data: alumnos = [],
    reFetch: reFetchAlumnos,
    working: alumnosWorking,
    error: errorAlumnos,
  } = useAlumnoMovimientoLibbyFetch({
    limit: 100,
    filter: filterSeccion,
    enabled: Boolean(section),
    orderBy: 'alumno.persona.apellido,alumno.persona.nombre',
    aspect: 'presentismo',
  });

  //Presentismos Trimestral

  const mapeoAlu = alumnos?.map((e) => e.alumno.idAlumno) || [];

  const {
    data: prestesTrimestral = [],
    working: workingPresentismoTrimestral,
    request: refetchPresentismoTrimestral,
  } = usePresentismoTrimestralV3CustomGet<PrestesTrimestral[]>({
    url: `/api/presentismov3/presentismotrimestralv3?nivel=${nivel}&idsAlumnos=${mapeoAlu}`,
    autoCall: alumnos.length > 0 && Boolean(nivel),
  });

  //CONTRATURNOS SEARCH

  const filterContraturnos = useMemo(
    () => ({
      seccion: [
        { path: 'contraturno.seccionCustom.idSeccion', value: section },
      ],
    }),
    [section],
  );

  const {
    data: contraturnosDia = [],
    reFetch: reFetchContraturnos,
    working: contraturnosWorking,
    error: errorContraturno,
  } = useContraturnoDiaLibbyFetch({
    limit: 300,
    filter: filterContraturnos,
    enabled:
      Boolean(section) &&
      !isTabJornadaExtendida &&
      Number(nivel) === NIVEL.SECUNDARIO,
    aspect: 'ContraturnoDiaAsp',
  });
  // PRESENTES Y EXECTUADOS
  const arrayFilter = useMemo(
    () => ({
      path: 'alumno',
      value: alumnos.map((am) => am.alumno.idAlumno),
      method: 'in',
    }),
    [alumnos],
  );

  const fechasFilterPresente = useMemo(
    () =>
      [...Array(6).keys()].map((n) => ({
        path: 'fecha',
        value: moment(currentWeek)
          .startOf('isoWeek')
          .add(n, 'days')
          .format('YYYY-MM-DD'),
      })),
    [currentWeek],
  );

  const filterPresente = useMemo(
    () => ({
      alumnos: [arrayFilter],
      fechas: fechasFilterPresente,
      seccion: [{ path: 'seccion.idSeccion', value: section }],
      jornada: [
        {
          path: 'isJornadaExtendida',
          value: isTabJornadaExtendida,
        },
      ],
    }),
    [arrayFilter, fechasFilterPresente, section, isTabJornadaExtendida],
  );

  const filterExceptuados = useMemo(
    () => ({
      alumnos: [arrayFilter],
    }),
    [arrayFilter],
  );

  const {
    data: presentes = [],
    reFetch: reFetchPresentes,
    working: presentesWorking,
    error: errorPresente,
  } = usePresenteV3LibbyFetch({
    filter: filterPresente,
    enabled: alumnos.length > 0,
    limit: 20000,
    aspect: 'basic',
  });

  const {
    data: exceptuados = [],
    working: exceptuadosWorking,
    recall,
    error: errorExceptuados,
  } = useExceptuadosV3LibbyCall({
    methodName: 'fetch',
    params: [],
    noAutoCall: true,
    aspect: 'presentismo',
  });

  const excelDataDiario = usePresentismoExcelData_v3(
    alumnoPresentismo,
    `Reporte Diario Presentismo ${moment(currentWeek)
      .startOf('isoWeek')
      .add(pastDaysOfWeek, 'days')
      .format('DD-MM-YYYY')}`,
    moment(currentWeek)
      .startOf('isoWeek')
      .add(pastDaysOfWeek, 'days')
      .format('YYYY-MM-DD'),
    tipoJornada || '',
    contraturnosDia,
    localizacion,
  );

  const filterMovimiento = useMemo(
    () => ({
      seccion: [{ path: 'seccion', value: section, method: 'notEquals' }],
      alumnos: [arrayFilter],
      deleted: [{ path: 'deletedAt', value: false, method: 'isnull' }],
      localizacion: [{ path: 'seccion.localizacion', value: localizacionId }],
      cicloLectivo: [
        { path: 'cicloLectivo', value: cicloLectivo?.idCicloLectivo },
      ],
    }),
    [section, arrayFilter, localizacionId, cicloLectivo?.idCicloLectivo],
  );

  const {
    data: amolds = [],
    working: amoldsWorking,
    error: errorAlumnoMovimiento,
  } = useAlumnoMovimientoCustomLibbyFetch({
    filter: filterMovimiento,
    orderBy: 'deletedAt',
    direction: 'desc',
    enabled: alumnos.length > 0 && section !== null && Boolean(cicloLectivo),
    limit: 100,
  });

  const filterDiasHabilitados = useMemo(
    () => ({
      espacioCurricular: [
        {
          path: 'espacioCurricular.idEspacioCurricular',
          value: selectedEspacioCurricular,
        },
      ],
      seccion: [{ path: 'seccion.idSeccion', value: section }],
      createdBy: [{ path: 'createdBy', value: userInfoId }],
    }),
    [section, selectedEspacioCurricular, userInfoId],
  );
  const {
    data: diasHabiles = [],
    reFetch: reFetchDiasHabiles,
    working: diasHabilesWorking,
    error: errorDiasHabiles,
  } = useDiasHabilitadosLibbyFetch({
    filter: filterDiasHabilitados,
    aspect: 'default',
    orderBy: 'seccion.idSeccion',
    direction: 'desc',
    enabled: Boolean(selectedEspacioCurricular) && Boolean(section),
  });

  let reportText = '';

  if (tipoJornada === 'Simple') {
    reportText = `Reporte semanal Simple - ${
      alumnoPresentismo?.[0]?.seccion?.nombreSeccion
    } - (${moment(currentWeek)
      .startOf('isoWeek')
      .add(pastDaysOfWeek, 'days')
      .format('DD/MM/YYYY')})fecha inicio`;
  } else if (tipoJornada === 'Extendida') {
    reportText = `Reporte Jornada Extendida - ${
      alumnoPresentismo?.[0]?.seccion?.nombreSeccion
    } - ${moment().format('DD/MM/YYYY')}`;
  } else if (tipoJornada === 'Completa') {
    reportText = `Reporte Jornada Completa - ${
      alumnoPresentismo?.[0]?.seccion?.nombreSeccion
    } - ${moment().format('DD/MM/YYYY')}`;
  } else {
    reportText = `Reporte Semanal Presentismo ${moment(currentWeek)
      .startOf('isoWeek')
      .add(pastDaysOfWeek, 'days')
      .format('DD-MM-YYYY')} - ${moment(currentWeek)
      .endOf('isoWeek')
      .format('DD-MM-YYYY')}`;
  }

  const propuestaJE = useMemo(() => {
    return propuestasJE.filter(
      (propuesta) => propuesta.seccion.idSeccion === section,
    );
  }, [propuestasJE, section]);

  const excelDataSemanal = usePresentismoExcelSemanalData_v3(
    alumnoPresentismo,
    reportText,
    fechasFilterPresente,
    tipoJornada,
    contraturnosDia,
    localizacion,
    currentWeek,
    isTabJornadaExtendida,
  );

  const { getExcel } = usePresentismoExcelSemanalGeneralConJEData_v3(
    alumnoPresentismo,
    reportText,
    fechasFilterPresente,
    tipoJornada,
    contraturnosDia,
    localizacion,
    currentWeek,
    propuestaJE,
  );

  const handleOnConfirm = useCallback(
    async (selectedOptions) => {
      if (selectedOptions.dailyChecked) {
        generateExcel(excelDataDiario?.body, excelDataDiario?.name);
      } else {
        if (isTabJornadaExtendida || Boolean(espacioCurricularSelected)) {
          generateExcel(excelDataSemanal?.body, excelDataSemanal?.name);
        } else {
          const filterExcel = {
            ...filterPresente,
            jornada: [
              {
                path: 'isJornadaExtendida',
                value: true,
              },
            ],
          };

          const presentes: PresenteGeneral[] = await presenteV3DAO
            .aspect('basic')
            .fetch({ filter: filterExcel, limit: 2000 });

          const alumnoExcelSemanal = alumnoPresentismo.map((alumno) => {
            const presentesJE = presentes.filter(
              (p) => p.alumno.idAlumno === alumno.alumno.idAlumno,
            );

            return {
              ...alumno,
              presentismo: [...alumno.presentismo, ...presentesJE],
            };
          });
          const excelSemanal = getExcel(alumnoExcelSemanal);
          generateExcel(excelSemanal.body, excelSemanal?.name);
        }
      }

      setDialogOpen(false);
    },
    [
      generateExcel,
      excelDataDiario?.body,
      excelDataDiario?.name,
      isTabJornadaExtendida,
      espacioCurricularSelected,
      excelDataSemanal?.body,
      excelDataSemanal?.name,
      filterPresente,
      presenteV3DAO,
      alumnoPresentismo,
      getExcel,
    ],
  );

  const filterPresenteEC = useMemo(
    () => ({
      seccion: [{ path: 'seccion.idSeccion', value: section }],
      alumnos: [arrayFilter],
      fechas: fechasFilterPresente,
      espacio: [
        { path: 'espacioCurricular', value: selectedEspacioCurricular },
      ],
    }),
    [arrayFilter, fechasFilterPresente, selectedEspacioCurricular, section],
  );

  // PresentismoEC
  const {
    data: presenteEC = [],
    reFetch: reFetchPresenteEC,
    working: presenteECWorking,
    error: errorPresenteEC,
  } = usePresentismoEspacioCurricularLibbyFetch({
    limit: 300,
    filter: filterPresenteEC,
    enabled: Boolean(selectedEspacioCurricular) && !!alumnos.length,
    aspect: 'basic',
  });

  const filterSolicitud = useMemo(
    () => ({
      alumnos: [arrayFilter],
      localizacion: [
        {
          path: 'localizacionDestino.idLocalizacion',
          value: localizacionId,
        },
      ],
      estado: [
        {
          path: 'estadoSolicitudPases.idEstadoSolicitudPases',
          value: 1,
        },
      ],
      nivel: [
        {
          path: 'nivel.idNivel',
          value: nivel,
        },
      ],
    }),
    [arrayFilter, localizacionId, nivel],
  );

  const {
    data: passRequest = [],
    working,
    error: errorSolicitudPase,
  } = useSolicitudPasesLibbyFetch({
    filter: filterSolicitud,
    enabled: alumnos.length > 0,
    aspect: 'presentismo',
  });

  useEffect(() => {
    if (arrayFilter !== undefined && alumnos.length > 0) {
      recall({
        filter: filterExceptuados,
        enabled: arrayFilter !== undefined && alumnos.length > 0,
        limit: 20000,
      });
    }
  }, [recall, alumnos, arrayFilter, filterExceptuados]);

  const filterPeriodoInicio = useMemo(
    () => ({
      alumnos: arrayFilter,
    }),
    [arrayFilter],
  );

  const {
    data: periodoInicio = [],
    working: periodoInicioWorking,
    reFetch: reFetchPeriodoInicial,
    error: errorPeriodoInicio,
  } = usePeriodoInicioLibbyFetch({
    filter: filterPeriodoInicio,
    enabled:
      NIVELES_AUTHORIZED.includes(Number(nivel)) &&
      arrayFilter !== undefined &&
      alumnos.length > 0,
    limit: 20000,
  });

  const reFetchAll = useCallback(() => {
    /*   reFetchExceptuados(); */
    reFetchAlumnos();
    reFetchPresentes();
    reFetchPeriodoInicial();
    reFetchContraturnos();
    reFetchPresenteEC();
    refetchPresentismoTrimestral();
  }, [
    reFetchAlumnos,
    reFetchPresentes,
    reFetchPeriodoInicial,
    reFetchContraturnos,
    reFetchPresenteEC,
    refetchPresentismoTrimestral,
  ]);

  const errorsPresentismo = useMemo(() => {
    const errors = [
      errorAlumnoMovimiento,
      errorAlumnos,
      errorContraturno,
      errorDiasHabiles,
      errorExceptuados,
      errorPeriodoInicio,
      errorPresente,
      errorPresenteEC,
      errorSolicitudPase,
    ];
    const validErrors = errors.filter(Boolean);

    const hasError = validErrors.some((error) => {
      return isNetworkError(error);
    });

    return { hasError, errors: validErrors };
  }, [
    errorAlumnoMovimiento,
    errorAlumnos,
    errorContraturno,
    errorDiasHabiles,
    errorExceptuados,
    errorPeriodoInicio,
    errorPresente,
    errorPresenteEC,
    errorSolicitudPase,
  ]);

  const backup = useMemo<PresenteGeneral[]>(
    () => [...newPresentismos, ...updatePresentismos],
    [newPresentismos, updatePresentismos],
  );

  const { show, cleanBackup, restoreBackup } = useInternetConnectionCheck({
    hasError: errorsPresentismo.hasError,
    info: backup,
    seccionId: section || '',
  });

  useEffect(() => {
    const backup: PresenteGeneral[] = restoreBackup();
    if (!backup) return;
    setNewPresentismos(backup);
  }, [restoreBackup, setNewPresentismos]);

  const [isSavingPresentismo, setIsSavingPresentismos] = useState(false);

  const savePresentismos = useCallback(async () => {
    setIsSavingPresentismos(true);
    try {
      const toSave = [];
      if (newPresentismos.length > 0) {
        const _toSave = newPresentismos.map(({ idPresente, ...presente }) => ({
          ...presente,
          isJornadaExtendida: isTabJornadaExtendida,
          espacio: isTabJornadaExtendida
            ? getEspacioJE(
                moment(presente.fecha).format('YYYY-MM-DD'),
                section || '',
                localizacion,
                propuestasJE,
              )
            : null,
          ...(selectedEspacioCurricular
            ? { idPresenteEspacioCurricular: Number(idPresente) }
            : { idPresente: idPresente }),
        }));
        toSave.push(..._toSave);
      }
      if (updatePresentismos.length > 0) {
        const _toSave = updatePresentismos.map(
          ({ idPresente, ...presente }) => ({
            ...presente,
            isJornadaExtendida: isTabJornadaExtendida,
            espacio: isTabJornadaExtendida
              ? getEspacioJE(
                  moment(presente.fecha).format('YYYY-MM-DD'),
                  section || '',
                  localizacion,
                  propuestasJE,
                )
              : null,
            ...(selectedEspacioCurricular
              ? { idPresenteEspacioCurricular: Number(idPresente) }
              : { idPresente: idPresente }),
          }),
        );
        toSave.push(..._toSave);
      }
      selectedEspacioCurricular
        ? await presenteECDAO.aspect('basic').save(toSave)
        : await presenteV3DAO.aspect('basic').save(toSave);
      setNewPresentismos([]);
      setUpdatePresentismos([]);
      setAlumnos([]);
      setMasiveChecks(false);
      reFetchAll();
      cleanBackup();
    } catch (error) {
      // ERROR
      const err = error as any;
      if (isNetworkError(err)) {
        show('error');
      }
    } finally {
      setIsSavingPresentismos(false);
    }
  }, [
    newPresentismos,
    updatePresentismos,
    selectedEspacioCurricular,
    presenteECDAO,
    presenteV3DAO,
    setNewPresentismos,
    setUpdatePresentismos,
    setAlumnos,
    setMasiveChecks,
    reFetchAll,
    cleanBackup,
    isTabJornadaExtendida,
    section,
    localizacion,
    propuestasJE,
    show,
  ]);

  useDebounceCall(
    () => {
      if (newPresentismos.length > 0 || updatePresentismos.length > 0) {
        savePresentismos();
      }
    },
    15000,
    [newPresentismos, updatePresentismos, savePresentismos],
  );

  const diableByRol = useEnableToSaveByRol(isTabJornadaExtendida, Number(rol));

  //ALERTAS V3
  const idAlumnosFilter = useMemo(
    () => arrayFilter.value.join(','),
    [arrayFilter],
  );

  const fechaDesde = fechasFilterPresente[0].value;
  const fechaHasta =
    fechasFilterPresente[fechasFilterPresente.length - 1].value;
  const {
    data: alertasv3 = {},
    request: requestAlertasV3,
    working: alertasv3Working,
  } = useAlertasGetRequest<AlertasV3>({
    url: `/api/presentismov3/alertas?seccion=${section}&alumnos=${idAlumnosFilter}&fecha_desde=${fechaDesde}&fecha_hasta=${fechaHasta}`,
    autoCall: false,
  });

  const {
    data: alertasECv3 = {},
    request: requestAlertasECV3,
    working: alertasECv3Working,
  } = useAlertasGetRequest<AlertasECV3>({
    url: `/api/presentismov3/alertas?seccion=${section}&alumnos=${idAlumnosFilter}&fecha_desde=${fechaDesde}&fecha_hasta=${fechaHasta}&espacio=${selectedEspacioCurricular}`,
  });

  const _alertasV3 = useMemo(() => {
    if (selectedEspacioCurricular) {
      if (alertasECv3[selectedEspacioCurricular]) {
        return alertasECv3[selectedEspacioCurricular];
      } else {
        return alertasv3;
      }
    } else {
      return alertasv3;
    }
  }, [selectedEspacioCurricular, alertasECv3, alertasv3]);
  const loadingGeneral = useMemo(
    () =>
      presentesWorking ||
      exceptuadosWorking ||
      alumnosWorking ||
      periodoInicioWorking ||
      alertasv3Working ||
      loading ||
      working ||
      contraturnosWorking ||
      presenteECWorking ||
      amoldsWorking ||
      diasHabilesWorking ||
      alertasECv3Working,
    [
      presentesWorking,
      exceptuadosWorking,
      alumnosWorking,
      periodoInicioWorking,
      alertasv3Working,
      loading,
      working,
      contraturnosWorking,
      presenteECWorking,
      amoldsWorking,
      diasHabilesWorking,
      alertasECv3Working,
    ],
  );

  useEffect(() => {
    if (section && idAlumnosFilter) {
      if (selectedEspacioCurricular) {
        requestAlertasECV3();
      } else {
        requestAlertasV3();
      }
    }
  }, [
    section,
    requestAlertasV3,
    requestAlertasECV3,
    idAlumnosFilter,
    selectedEspacioCurricular,
  ]);

  useEffect(() => {
    if (loadingAlumnos && loadingGeneral) {
      setIsLoading(true);
    }
    if (loadingAlumnos && isLoading && !loadingGeneral) {
      setIsLoading(false);
      setLoadingAlumnos(false);
    }
  }, [isLoading, loadingAlumnos, loadingGeneral]);

  const presenteGeneral = useMemo(
    () =>
      mapPresentesByEc(
        Boolean(selectedEspacioCurricular),
        presentes,
        presenteEC,
      ),
    [selectedEspacioCurricular, presentes, presenteEC],
  );

  // MAP ALUMNO PRESENTISMO
  useEffect(() => {
    if (loadingAlumnos || isLoading || loadingGeneral || loadingAlumnos) return;
    if (alumnos.length > 0) {
      const rows = cloneDeep(alumnos);
      const arrayForMap: AlumnoMap[] = rows
        .map<AlumnoMap>((row, index) => {
          let presentismo: PresenteGeneral[] = [];
          presenteGeneral.forEach((pGeneral) => {
            if (pGeneral.alumno.idAlumno === row.alumno.idAlumno) {
              presentismo.push({ ...pGeneral });
            }
          });

          // TODO : AGREGAR FILTRO EN EL DAO D:
          const exceptuadosList = new DateRangeListV3(
            exceptuados
              .filter(
                (excep) =>
                  excep.alumno.idAlumno === row.alumno.idAlumno &&
                  !excep.deletedAt,
              )
              .map(
                (item) =>
                  new ExceptuadosDateRangeV3(
                    item.startTime,
                    item.alumno,
                    item.endTime,
                    item.idExceptuado,
                    item.motivoExceptuado,
                  ),
              ),
          );

          const periodo: PeriodoInicio | undefined = periodoInicio.find(
            (periodo) => periodo.alumno.idAlumno === row.alumno.idAlumno,
          );

          // SOLICITUDES DE PASE
          const solicitud = passRequest.find(
            (pass) => pass.alumno.idAlumno === row.alumno.idAlumno,
          );

          let _presentes: PresenteGeneral[] = getPresentesMaster({
            movimiento: row,
            periodos: periodoNuevo,
            presentismo: presentismo,
            contraturnos: contraturnosDia,
            feriados,
            situaciones,
            isJE: isTabJornadaExtendida,
            turno,
            currentWeek,
            seccionSelected: section || '',
            setNewPresentismos,
            selectedEspacioCurricular,
          });

          return {
            ...row,
            periodoInicio: periodo,
            positionY: index,
            exceptuados: exceptuadosList,
            alertas: _alertasV3[row.alumno.idAlumno],
            contraturnos: contraturnosDia,
            solicitudPase: solicitud,
            presentismo: _presentes,
            isChangeSeccion: amolds.some(
              (am) => am.alumno.idAlumno === row.alumno.idAlumno,
            ),
          };
        })
        .sort((estudianteA, estudianteB) => {
          const apellidoYNombreEstudianteA =
            `${estudianteA.alumno.persona.apellido} ${estudianteA.alumno.persona.nombre}`.toLocaleLowerCase();
          const apellidoYNombreEstudianteB =
            `${estudianteB.alumno.persona.apellido} ${estudianteB.alumno.persona.nombre}`.toLocaleLowerCase();

          if (direction === 'asc') {
            return apellidoYNombreEstudianteA.localeCompare(
              apellidoYNombreEstudianteB,
            );
          } else {
            return apellidoYNombreEstudianteB.localeCompare(
              apellidoYNombreEstudianteA,
            );
          }
        });

      if (!isTabJornadaExtendida) {
        setAlumnoPresentismo(arrayForMap);
      } else if (isTabJornadaExtendida && jornadaExtendidaValidationDone) {
        setAlumnoPresentismo(arrayForMap);
      }
    }
  }, [
    setAlumnoPresentismo,
    alumnos,
    presenteGeneral,
    exceptuados,
    feriados,
    turno,
    currentWeek,
    periodoInicio,
    jornadaExtendidaValidationDone,
    isTabJornadaExtendida,
    direction,
    contraturnosDia,
    passRequest,
    situaciones,
    section,
    periodoNuevo,
    setNewPresentismos,
    loadingGeneral,
    loadingAlumnos,
    isLoading,
    selectedEspacioCurricular,
    amolds,
    diasHabiles,
    _alertasV3,
  ]);

  useEffect(() => {
    setDBPresentismos(presenteGeneral);
  }, [presenteGeneral, setDBPresentismos]);

  const handleSeccionesChange = useCallback(
    (secciones: Seccion[]) => {
      if (secciones.length > 0 && section) {
        const _seccion = secciones.filter(
          ({ idSeccion }) => Number(section) === Number(idSeccion),
        )[0];
        setTipoJornada(_seccion.jornada as Jornada);
        setTurno(_seccion.turno.idTurno);
        setAlumnos([]);
        setMasiveChecks(false);
      }
    },
    [section, setTipoJornada, setTurno, setMasiveChecks, setAlumnos],
  );

  const onNextAlumnoJustificacion = () => {
    const findNextAlumno = (index: number): AlumnoMap | undefined => {
      if (!alumnoPresentismo[index]) return undefined;

      const ausencia = alumnoPresentismo[index].presentismo.find(
        ({ estadoPresente }) =>
          JUSTIFICACION_ESTADOS.includes(estadoPresente?.idEstadoPresente || 0),
      );

      if (ausencia) {
        return alumnoPresentismo[index];
      }

      if (!ausencia) {
        return findNextAlumno(index + 1);
      }
    };

    const indexCurrentAlumno = alumnoPresentismo.findIndex(
      (alumno) =>
        alumno.idAlumnoMovimiento ===
        (openJustificacion.row as any).idAlumnoMovimiento,
    );

    const nextAlumno = findNextAlumno(indexCurrentAlumno + 1);

    if (!nextAlumno) {
      return enqueueSnackbar('No existe estudiante con ausencia', {
        variant: 'error',
      });
    }
    setOpenJustificacion({
      ...openJustificacion,
      row: cloneDeep(nextAlumno),
    });
  };

  const handleCloseJustificacion = () => {
    setOpenJustificacion({ open: false, row: null });
    reFetchAll();
  };

  useEffect(() => {
    if (anio) {
      setCurrentWeek(moment().startOf('isoWeek')); // SEMANA  ACTUAL
      setPastDaysOfWeek(Number(moment().format('e'))); // DIAS QUE YA PASARON DE LA SEMANA ACTUAL
    }
  }, [anio, setCurrentWeek, setPastDaysOfWeek]);

  const handleChangeWeek = useCallback(
    async (value: 'next' | 'back' | 'today') => {
      const changeWeekFunction = (value: 'next' | 'back' | 'today') => {
        let newDate = null;
        switch (value) {
          case 'next':
            newDate = moment(currentWeekState).add(1, 'weeks');
            break;
          case 'back':
            newDate = moment(currentWeekState).subtract(1, 'weeks');
            break;
          case 'today':
            newDate = moment(serverDate).startOf('isoWeek');
            break;
          default:
            newDate = moment(serverDate);
            break;
        }
        setCurrentWeek(newDate);
      };

      changeWeekFunction(value);
      setLoadingAlumnos(true);
    },
    [currentWeekState, serverDate, setCurrentWeek],
  );

  useEffect(() => {
    setIsJornadaExtendida(isTabJornadaExtendida);
  }, [isTabJornadaExtendida, setIsJornadaExtendida]);

  useEffect(() => {
    if (!jornadaExtendidaValidationDone && isTabJornadaExtendida) {
      setAlumnoPresentismo([]);
    }
  }, [
    jornadaExtendidaValidationDone,
    setAlumnoPresentismo,
    isTabJornadaExtendida,
  ]);

  const listadeAlumnos = alumnos?.map((alu) => alu.alumno.idAlumno);

  return (
    <>
      {!openJustificacion.open && !openStudentReport.open && (
        <PresentismoHeader
          currentWeek={currentWeek}
          pastDaysOfWeek={pastDaysOfWeek}
          serverDate={serverDate}
          handleChangeWeek={handleChangeWeek}
          setPastDaysOfWeek={setPastDaysOfWeek}
          selectedAnio={anio}
          onAnioSelect={setAnio}
          anioDisabled={openJustificacion.open}
          selectedSeccion={section}
          onSeccionSelect={setSection}
          seccionDisabled={openJustificacion.open}
          handleSeccionesChange={handleSeccionesChange}
          isTabJornadaExtendida={isTabJornadaExtendida}
          search={alumnoToSearch}
          handleSearchChange={setSearch}
          alumnoPresentismo={alumnoPresentismo}
          tipoJornada={tipoJornada}
          reFetchAll={reFetchAll}
          setJornadaExtendidaValidationDone={setJornadaExtendidaValidationDone}
          contraturnosDia={contraturnosDia}
          loadingGeneral={loadingGeneral}
          espaciosCurriculares={espacioCurricularList}
          selectedEspacioCurricular={selectedEspacioCurricular}
          setSelectedEspacioCurricular={setSelectedEspacioCurricular}
        />
      )}
      {section ? (
        <Grid container>
          {openJustificacion.open ? (
            <Justificacion
              data={openJustificacion.row!}
              handleClose={handleCloseJustificacion}
              onCancel={() =>
                setOpenJustificacion({ ...openJustificacion, open: false })
              }
              onNext={onNextAlumnoJustificacion}
              isJornadaExtendida={isTabJornadaExtendida}
              contraturnosDia={contraturnosDia}
              selectedDay={moment(currentWeek)
                .startOf('isoWeek')
                .add(pastDaysOfWeek, 'days')}
            />
          ) : openStudentReport.open ? (
            <StudentReport
              data={openStudentReport.row!}
              onBack={() => {
                reFetchPresentes();
                setOpenStudentReport({ ...openStudentReport, open: false });
              }}
              listAlumnos={listadeAlumnos}
              selectedEspacioCurricular={selectedEspacioCurricular}
            />
          ) : (
            <PresentismoTable
              tipoJornada={tipoJornada}
              currentWeek={currentWeek}
              serverDate={serverDate}
              pastDaysOfWeek={pastDaysOfWeek}
              rows={alumnoPresentismo}
              working={loadingGeneral}
              justification={(row) =>
                setOpenJustificacion({ open: true, row: row })
              }
              studentReport={(row) =>
                setOpenStudentReport({ open: true, row: row })
              }
              isTabJornadaExtendida={isTabJornadaExtendida}
              reFetchAll={reFetchAll}
              selectedSection={section}
              direction={direction}
              setDirection={setDirection}
              setPastDaysOfWeek={setPastDaysOfWeek}
              selectedEspacioCurricular={selectedEspacioCurricular}
              presentes={presentes}
              prestesTrimestral={prestesTrimestral}
              workingPresentismoTrimestral={workingPresentismoTrimestral}
              diasHabilitados={diasHabiles}
              diasHabilesWorking={diasHabilesWorking}
              reFetchDiasHabiles={reFetchDiasHabiles}
            />
          )}
          {!openStudentReport.open && !openJustificacion.open ? (
            <div>
              <PresentismoFooter
                disabledDownload={
                  newPresentismos?.length > 0 || updatePresentismos?.length > 0
                }
                isLoading={isSavingPresentismo}
                disabledSave={
                  diableByRol ||
                  (newPresentismos?.length === 0 &&
                    updatePresentismos?.length === 0)
                }
                onDownload={() => setDialogOpen(true)}
                onSave={savePresentismos}
              />
              <CustomDialog
                open={dialogOpen}
                onClose={() => setDialogOpen(false)}
                title="Descargar reporte de asistencia"
                content="¿Qué reporte desea descargar?"
                confirmText="Continuar"
                cancelText="Cancelar"
                onConfirm={handleOnConfirm}
              />
            </div>
          ) : (
            <></>
          )}
        </Grid>
      ) : (
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: '400px' }}
        >
          <Typography
            style={{
              fontSize: '40px',
              marginTop: '50px',
              color: 'gray',
              fontWeight: 'bold',
              lineHeight: '50px',
            }}
          >
            Realice una nueva búsqueda para la toma de presentismo
          </Typography>
        </Grid>
      )}
    </>
  );
};
