import { LibbyFetchDAO } from './LibbyFetchDAO';

export class PersonalEducativoDAO extends LibbyFetchDAO {
  constructor() {
    super('personal_educativo', 'idPersonalEducativo');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByIdCuentas(idCuenta: number) {
    return this.query().equals('persona.cuentas', idCuenta).run();
  }
}
