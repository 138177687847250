import { EventEmitter } from '../../utils';
import { GOOGLE_OAUTH_API_KEY, GOOGLE_OAUTH_CLIENT_ID } from '../../config';

const { gapi } = window;

class GoogleApi {
  private SCOPE = 'https://www.googleapis.com/auth/userinfo.email';

  private readonly allowedDomains: string[] = ['bue.edu.ar', 'dinmax.com'];

  private _idToken = '';

  private auth?: gapi.auth2.GoogleAuth;

  private eventEmitter: EventEmitter = new EventEmitter();

  get idToken(): string {
    return this._idToken;
  }

  set idToken(value: string) {
    this._idToken = value;
    this.eventEmitter.emit({ idToken: value });
  }

  constructor() {
    gapi.load('client:auth2', this.initialize.bind(this));
  }

  async initialize() {
    await gapi.client.init({
      apiKey: GOOGLE_OAUTH_API_KEY,
      clientId: GOOGLE_OAUTH_CLIENT_ID,
      scope: this.SCOPE,
    });
    this.auth = gapi.auth2.getAuthInstance();

    this.auth?.isSignedIn.listen(this.setSigninStatus.bind(this));

    this.setSigninStatus();
  }

  async singIn(): Promise<string> {
    await this.auth?.signOut();
    const user = await this.auth?.signIn();
    if (user) {
      const domain = user.getHostedDomain();
      if (!domain || !this.allowedDomains.find((dom) => domain.includes(dom))) {
        throw new Error('Dominio no permitido');
      }
      return user.getAuthResponse().id_token;
    }
    return '';
  }

  setSigninStatus() {
    this.idToken =
      this.auth?.currentUser.get().getAuthResponse().id_token || '';
  }

  subscribe(fn: (...params: any[]) => any) {
    return this.eventEmitter.subscribe(fn);
  }
}

const i: GoogleApi = new GoogleApi();
export { i as GoogleApi };
