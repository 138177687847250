import React, { ChangeEvent, FC } from 'react';
import { useField } from 'react-final-form-hooks';
import { getErrorAndHelperTextNoTranslation } from '../../../utils/materialHelpers';
import { getHelperTextNoTranslation } from '../hooks/makeGetHelperText';
import { FormErrorComponent } from './FormErrorComponent';

type FunctionComponent<T> = (props: T) => JSX.Element;

export interface FormGenericControlV2Props<T = any> {
  name: string;
  form: any;
  componentProps?: T;
  component: FunctionComponent<T> | FC<T>;
  optionToValue?: (value: any) => any;
  valueToOption?: (value: any) => any;
}

export const FormGenericControlV2 = ({
  component: Comp,
  componentProps = {},
  form,
  name,
  valueToOption,
  optionToValue,
}: FormGenericControlV2Props) => {
  const field = useField(name, form);
  const { helperText, error } = getHelperTextNoTranslation(field.meta);
  return (
    <Comp
      {...componentProps}
      onFocus={field.input.onFocus}
      onBlur={field.input.onBlur}
      value={
        field.input.value
          ? valueToOption
            ? valueToOption(field.input.value)
            : field.input.value
          : ''
      }
      onChange={(event: ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        field.input.onChange(
          optionToValue ? optionToValue(newValue) : newValue,
        );
      }}
      helperText={<FormErrorComponent helperText={helperText} />}
      error={error}
    />
  );
};
