import React, { FC } from 'react';
import { Grid, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormMultiCheckboxInput,
  FormMultiCheckbox,
} from 'src/lib/templates/components';
import { grey } from 'src/theme/colors';
import { usePlanificationFormDisciplinarOrientada } from '../../hooks';
import { useDisciplinarOrientadaContext } from '../../Context';
import { CheckboxListItem } from '../../../commons/components';

const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      padding: '32px 10px',
      marginBottom: 60,
    },
    listItem: {
      padding: 0,
    },
    listItemIcon: {
      marginRight: '-20px',
    },
    text: {
      color: grey.medium,
    },
  }),
);

export const PropositosDeEnsenanza: FC = () => {
  const classes = useStyles();
  const { form, errors, values } = usePlanificationFormDisciplinarOrientada();
  const { data, working } = useDisciplinarOrientadaContext();
  const bloques = values?.bloque.bloques;
  const select = bloques.map((bloque: any) => data.propositos[bloque]);
  const buttonCustomStyle = { margin: '10px 0px 25px 0' };

  // TODO: Rename FormMultiCheckbox and refactor checkbox list
  return (
    <Grid container direction="column" className={classes.container}>
      {select.map((prop: any, indexs: number) => {
        return (
          <>
            <FormMultiCheckbox
              label={`Bloque: ${prop.bloque.descripcion}`}
              name={`ensenanza.propositosDeEnsenanza[${indexs}].ensenanzas`}
              // @ts-ignore
              options={prop.values}
              form={form}
              idKey="idPropositoBloque"
            />
            <FormMultiCheckboxInput
              key={indexs}
              label=""
              name={`ensenanza.propositosDeEnsenanza[${indexs}].adicionales`}
              form={form}
              addButtonText="Agregar Propósito"
              buttonCustomStyle={buttonCustomStyle}
              getNewElement={(value) => ({
                id: -1 - 1 * value.filter((item: any) => item.id < 0).length, // use negative id to validate new items
                checked: false,
                descripcion: '',
              })}
            >
              {(value, input, meta, index) => (
                <CheckboxListItem
                  key={index}
                  inputPlaceholder="Nuevo propósito"
                  value={value}
                  input={input}
                  meta={meta}
                  index={index}
                />
              )}
            </FormMultiCheckboxInput>
          </>
        );
      })}
    </Grid>
  );
};
