import { Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { useField } from 'react-final-form-hooks';
import { DatePicker, useFormInputStyles } from 'src/app/components';
import { getErrorAndHelperTextNoTranslation } from '../../../utils/materialHelpers';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';

export interface FormDatePickerProps {
  form: any;
  name: string;
  label: string;
  clearable?: boolean;
  placeholder?: string;
  disabled?: boolean;
  className?: any;
  minDate?: ParsableDate;
  maxDate?: ParsableDate;
  isNowrap?: boolean;
}

export const FormDatePicker: FunctionComponent<FormDatePickerProps> = ({
  name,
  clearable = true,
  placeholder,
  disabled,
  form,
  label,
  className,
  minDate,
  maxDate,
  isNowrap,
}) => {
  const field = useField(name, form);
  const classes = useFormInputStyles();
  const date = field.input.value || null;
  return (
    <>
      <Typography
        className={classes.label}
        style={isNowrap ? { whiteSpace: 'nowrap' } : undefined}
      >
        {label}
      </Typography>
      <DatePicker
        value={date}
        clearable={clearable}
        placeholder={placeholder}
        disabled={disabled}
        error={!!field.meta.error}
        helperText={field.meta.error}
        onChange={(_date) => field.input.onChange(_date?.toDate())}
        className={className}
        minDate={minDate}
        maxDate={maxDate}
      />
    </>
  );
};
