import React from 'react';
import { Content } from '../../../../../../../../commons/components/TabBar';
import { PropositosEnsenanza } from './PropositosEnsenanza';
import { ObjetivosAprendizaje } from './ObjetivosAprendizaje';
import { IndicadoresEvaluacion } from './IndicadoresEvaluacion';
import { PrimerBimestre } from './PrimerBimestre';
import { SegundoBimestre } from './SegundoBimestre';
import { TercerBimestre } from './TercerBimestre';
import { CuartoBimestre } from './CuartoBimestre';
import { Bibliografia } from './Bibliografia';

export const tabs: Content[] = [
  {
    title: 'Propósitos de enseñanza',
    component: <PropositosEnsenanza />,
  },
  {
    title: 'Objetivos de Aprendizaje',
    component: <ObjetivosAprendizaje />,
  },
  {
    title: 'Indicadores de evaluación',
    component: <IndicadoresEvaluacion />,
  },
  {
    title: 'Primer Bimestre',
    component: <PrimerBimestre />,
  },
  {
    title: 'Segundo Bimestre',
    component: <SegundoBimestre />,
  },
  {
    title: 'Tercer Bimestre',
    component: <TercerBimestre />,
  },
  {
    title: 'Cuarto Bimestre',
    component: <CuartoBimestre />,
  },
  {
    title: 'Bibliografía',
    component: <Bibliografia />,
  },
];
