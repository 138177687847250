import _ from 'lodash';
import moment from 'moment';
import React, { useCallback, useState, useMemo, useEffect } from 'react';
import {
  Drawer,
  Grid,
  List,
  ListItem,
  IconButton,
  Typography,
  SvgIcon,
} from '@material-ui/core';
import { NavLink, useLocation, useHistory } from 'react-router-dom';
import AssignmentIndOutlinedIcon from '@material-ui/icons/AssignmentIndOutlined';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import FolderOpenOutlinedIcon from '@material-ui/icons/FolderOpenOutlined';
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined';
import GroupOutlinedIcon from '@material-ui/icons/GroupOutlined';
import VideoLabelIcon from '@material-ui/icons/VideoLabel';
import ReportIcon from '@material-ui/icons/Report';
import NotificationsNoneOutlinedIcon from '@material-ui/icons/NotificationsNoneOutlined';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import SmsOutlinedIcon from '@material-ui/icons/SmsOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import ForumIcon from '@material-ui/icons/Forum';
import RateReviewRoundedIcon from '@material-ui/icons/RateReviewRounded';
import LocalLibraryOutlined from '@material-ui/icons/LocalLibraryOutlined';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import GlobeIcon from '@material-ui/icons/Public';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import { InsertChart } from '@material-ui/icons';
import TimelineIcon from '@material-ui/icons/Timeline';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import { RESTConnection, DatabaseConnector } from '@phinxlab/libby-rest-web';
import { BLOCKER_ID, SIDEMENU_ID } from 'src/platform/permission/const';
import { ROL } from 'src/commons/const/rol';
import { usePermissionSideFilter } from 'src/lib/permission/hooks/usePermissionSideFilter';
import {
  useEspacioCurricularLibbyFetch,
  useUsuarioRolEstablecimientoDAO,
  useUsuarioRolEstablecimientoLibbyFetch,
} from 'src/app/business';
import { useEstablecimientosAcapLibbyFetch } from 'src/app/business/acap';
import { PermissionBlocker } from 'src/lib/permission';
import { level } from 'src/commons';
import { CreatecSideBarIcon } from 'src/assets/images/svgs/CreatecSideBarIcon';
import { PlataformaEvaluacionesIcon } from 'src/assets/images/svgs/PlataformaEvaluacionesIcon';
import { useEspaciocurricularseccioncustomGetRequest } from 'src/app/business/businessCustomEndpoints/espaciocurricularseccioncustom';
import { useGeneralContext } from 'src/context';
import { LogoBA } from '../../../assets/images/svgs/LogoBA';
import { Pps } from '../../../assets/images/svgs/Pps';
import { useRolesContext } from '../../../context/RolesContext';
import { useReloginDAO } from '../../../app/business/businessCustomEndpoints';
import { LibbyObject, EspacioCurricularSeccion } from '../../types';
import { EnviromentBlocker } from '../Blockers/EnviromentBlocker';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
import { navBarStyles } from './styles';
import { CURRENT_CICLO_LECTIVO } from 'src/screens/Private/Acap';

const getBoletinesLeyendByNivel = ({ nivel }: { nivel: string }) => {
  return nivel === '1'
    ? 'Generación de Informes'
    : nivel === '2'
    ? 'Boletines e Informes'
    : 'Boletines';
};

const PATH_GESTION_ROLES = '/private/gestionRoles';

const SideMenuRaw = ({ libby }: { libby: LibbyObject }) => {
  const [isOpen, setIsOpen] = useState(false);
  const classes = navBarStyles();
  const location = useLocation();
  const { deselectRole, selectedRole, userInfo } = useRolesContext();
  const { localizacionId, nivel } = selectedRole;
  const { cicloLectivo } = useColegioEfectivizadoContext();
  const { data: ecs = [] } = useEspaciocurricularseccioncustomGetRequest<
    EspacioCurricularSeccion[]
  >({
    url: `/api/public/custom/espaciocurricularseccioncustom?localizacion=${selectedRole?.localizacionId}&cicloLectivo=${CURRENT_CICLO_LECTIVO}&nivel=${selectedRole.nivel}&limit=1`,
    aspect: 'sidemenu',
    autoCall: Boolean(selectedRole?.localizacionId && selectedRole?.nivel),
  });

  const { data: espaciosCurriculares = [] } = useEspacioCurricularLibbyFetch({
    limit: 200,
    aspect: 'sidemenu',
  });

  const isOnlyJE = ecs.length === 0 && Number(selectedRole.rol) === ROL.DOCENTE;

  const filterEscuelasAcap = useMemo(
    () => ({
      search: [
        {
          path: 'habilitado',
          value: true,
        },
      ],
    }),
    [],
  );

  const { data: establecimientosAcap = [] } = useEstablecimientosAcapLibbyFetch(
    {
      limit: 500,
      filter: filterEscuelasAcap,
      checkDuplication: false,
      checkDuplicationDeep: false,
      aspect: 'sidemenu',
    },
  );

  const showCORole = useMemo(() => {
    return (
      establecimientosAcap.some(
        (item) =>
          Number(item.localizacion.idLocalizacion) === Number(localizacionId) &&
          Number(selectedRole?.nivel) === 3,
      ) ||
      (Number(selectedRole?.nivel) === 3 &&
        [ROL.ESTUDIANTE, ROL.DOCENTE].includes(Number(selectedRole.rol)))
    );
  }, [establecimientosAcap, localizacionId, selectedRole]);

  // dependencia funcional 10 = Dirección de Educación Inicial
  const establecimientosFilter = useMemo(
    () => ({
      user: [{ path: 'usuario', value: libby.session.user.id }],
      rol: [
        { path: 'rolUsuario.idRolUsuario', value: ROL.DIRECTIVO },
        { path: 'rolUsuario.idRolUsuario', value: ROL.DIRECCION_AREA },
        { path: 'rolUsuario.idRolUsuario', value: ROL.SUPERVISOR },
        { path: 'rolUsuario.idRolUsuario', value: ROL.EQUIPO_CONDUCCION },
        { path: 'rolUsuario.idRolUsuario', value: ROL.PRECEPTOR },
        { path: 'rolUsuario.idRolUsuario', value: ROL.ADMIN },
        { path: 'rolUsuario.idRolUsuario', value: ROL.EQUIPO_ACAP },
      ],
    }),
    [libby.session.user.id],
  );

  const { data: establecimientos = [] } =
    useUsuarioRolEstablecimientoLibbyFetch({
      filter: establecimientosFilter,
      limit: 5000,
      aspect: 'sidemenu',
      enabled: Boolean(libby.session.user.id),
    });

  const gestionEstablecimientosFiltered = useMemo(() => {
    return establecimientos.filter(
      (usuarioRolEstablecimiento) =>
        [level.INICIAL, -1].includes(
          Number(usuarioRolEstablecimiento.nivel.idNivel),
        ) &&
        usuarioRolEstablecimiento.localizacion.establecimiento
          .dependenciaFuncional.idDependenciaFuncional === 10,
    );
  }, [establecimientos]);

  const jinDirectivos = useMemo(
    () =>
      establecimientos.filter(
        (place) =>
          Number(place.rolUsuario.idRolUsuario) === ROL.DIRECTIVO &&
          Number(place.rolUsuario.idRolUsuario) !== ROL.EQUIPO_CONDUCCION &&
          Number(place.rolUsuario.idRolUsuario) !== ROL.PRECEPTOR &&
          place.localizacion.cueAnexo.endsWith('00'),
      ),
    [establecimientos],
  );

  const generalContext = useGeneralContext();

  const initOctuber = moment('10/01/2021');
  const initNovember = moment('10/30/2021');

  const validDateForPromotion = useMemo(() => {
    const hasDireccion = gestionEstablecimientosFiltered.filter(
      (place) =>
        place.rolUsuario.idRolUsuario === ROL.SUPERVISOR ||
        place.rolUsuario.idRolUsuario === ROL.DIRECCION_AREA,
    ).length;

    if (hasDireccion) {
      return true;
    } else {
      const fecha = generalContext?.generalState.date;
      const validDateForPromotionAndReports = moment(fecha).isBetween(
        initOctuber,
        initNovember,
      );
      return validDateForPromotionAndReports;
    }
  }, [
    generalContext?.generalState.date,
    gestionEstablecimientosFiltered,
    initNovember,
    initOctuber,
  ]);

  const canPromote =
    gestionEstablecimientosFiltered.filter(
      (place) =>
        place.rolUsuario.idRolUsuario === ROL.SUPERVISOR ||
        place.rolUsuario.idRolUsuario === ROL.DIRECCION_AREA,
    ).length +
      jinDirectivos.length >
      0 &&
    validDateForPromotion &&
    (Number(selectedRole.rol) === ROL.NO_ROL || selectedRole.rol === '');

  const canSeeAcap = establecimientos.find(
    (place) => place.rolUsuario.idRolUsuario === ROL.EQUIPO_ACAP,
  );

  useEffect(() => {
    if (Number(selectedRole?.rol) === ROL.ESTUDIANTE)
      history.push('/private/comunicaciones');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClickAcap = async () => {
    const id: string = canSeeAcap!.idRolEstablecimiento;
    const res = await reloginDAO.selectRole(id);
    const user = { ...res.user, isGuest: res.isGuest };
    RESTConnection.defineHeader('x-chino-token', res.token);
    libby.session.notify('New session', user, res.token);
  };

  const canSeeReports =
    (establecimientos.length > 0 &&
      ([
        ROL.DIRECTIVO,
        ROL.DIRECCION_AREA,
        ROL.EQUIPO_CONDUCCION,
        ROL.NO_ROL,
        ROL.SUPERVISOR,
        ROL.PRECEPTOR,
      ].includes(Number(selectedRole.rol)) ||
        selectedRole.rol === '')) ||
    Number(selectedRole.rol) === ROL.DIRECCION_AREA;

  const sideMenuIcons = useMemo(() => {
    let defaultItems = [
      {
        id: SIDEMENU_ID.NOTIFICATIONS,
        title: 'Notificaciones',
        icon: <NotificationsNoneOutlinedIcon titleAccess="Notificaciones" />,
        path: '/',
      },
      {
        id: SIDEMENU_ID.DASHBOARD,
        title: 'Tablero',
        icon: (
          <InsertChart
            className={classes.defaultIconRole}
            titleAccess="Tablero"
          />
        ),
        path: '/private/tablero',
      },
      {
        id: SIDEMENU_ID.COMUNICATIONS,
        title: 'Comunicaciones',
        icon: <SmsOutlinedIcon titleAccess="Comunicaciones" />,
        path: '/private/establecimiento',
      },
      {
        id: SIDEMENU_ID.DOCUMENTS,
        title: 'Documentos',
        icon: <FolderOpenOutlinedIcon titleAccess="Documentos" />,
        path: '/',
      },
      {
        id: SIDEMENU_ID.CALENDAR,
        title: 'Calendario',
        icon: <CalendarTodayIcon titleAccess="Calendario" />,
        path: '/',
      },
      {
        id: SIDEMENU_ID.COMUNICACIONES,
        title: 'Comunicaciones',
        icon: <ForumIcon />,
        path: '/private/comunicaciones',
      },
      {
        id: SIDEMENU_ID.MATRICULACION,
        title: 'Matriculación',
        icon: <AssignmentIndOutlinedIcon titleAccess="Matriculación" />,
        path: '/private/matriculation',
      },
      {
        id: SIDEMENU_ID.CURRICULAR_SPACES,
        title: 'Espacios Curriculares',
        icon: <AssignmentOutlinedIcon titleAccess="Espacios Curriculares" />,
        path: '/private/espacioscurriculares',
      },
      {
        id: SIDEMENU_ID.CALIFICACIONES,
        title: 'Calificaciones',
        icon: <RateReviewRoundedIcon titleAccess="Calificaciones" />,
        path: '/private/calificaciones',
      },
      {
        id: SIDEMENU_ID.BOLETINES,
        title: getBoletinesLeyendByNivel({ nivel }),
        icon: (
          <VideoLabelIcon titleAccess={getBoletinesLeyendByNivel({ nivel })} />
        ),
        path: '/private/boletines',
      },
      // {
      //   id: SIDEMENU_ID.REPOSITORY,
      //   title: 'Repositorio',
      //   icon: <GroupWorkOutlinedIcon titleAccess="Repositorio" />,
      //   path: '/private/repositorio',
      // },
      {
        id: SIDEMENU_ID.GESTIONROLES,
        title: 'Gestion de Roles',
        icon: <GroupOutlinedIcon titleAccess="Gestion de Roles" />,
        path: '/private/gestionRoles',
      },
      {
        id: SIDEMENU_ID.REPOSITORY_ABM,
        title: 'Repositorio ABM',
        icon: <GroupWorkOutlinedIcon titleAccess="Repositorio ABM" />,
        path: '/private/repositorioabm',
      },
      {
        id: SIDEMENU_ID.CONFIGURACION_ADMIN,
        title: 'Configuración Admin',
        icon: <SettingsIcon titleAccess="Configuración Admin" />,
        path: '/private/configurationadmin',
      },
      {
        id: SIDEMENU_ID.ALUMNOS_AULAS_VRITUALES,
        title: 'Aulas Virtuales',
        icon: <VideoLabelIcon titleAccess="Aulas Virtuales" />,
        path: '/private/aulasvirtuales',
      },
    ];

    const addinformes =
      Number(nivel) === 1 &&
      selectedRole.rol !== ROL.ESTUDIANTE.toString() &&
      cicloLectivo?.idCicloLectivo === 25;
    if (addinformes) {
      defaultItems.splice(8, 0, {
        id: SIDEMENU_ID.INFORMESC,
        title: 'Informes',
        icon: <ReportIcon titleAccess="Informes" />,
        path: '/private/informes',
      });
    }

    const materiasJE = espaciosCurriculares.some((ec) =>
      ec.descripcion.toLowerCase().includes('jornada extendida'),
    );

    const hidePresentismo =
      (Number(nivel) === 3 &&
        ((selectedRole.rol === ROL.DOCENTE.toString() && !materiasJE) ||
          selectedRole.rol === ROL.REFERENTE_ACAP.toString())) ||
      selectedRole.rol === ROL.ADMIN.toString() ||
      selectedRole.rol === ROL.COORDINADOR_ORIENTACION.toString();

    if (!hidePresentismo) {
      defaultItems.push({
        id: SIDEMENU_ID.PRESENTISMO,
        title: 'Presentismo',
        icon: <EmojiPeopleIcon titleAccess="Presentismo" />,
        path: '/private/presentismo',
      });
    }

    if (
      Number(nivel) === 2 &&
      !isOnlyJE &&
      [ROL.DIRECTIVO, ROL.EQUIPO_CONDUCCION, ROL.DOCENTE].includes(
        Number(selectedRole.rol),
      ) &&
      cicloLectivo?.idCicloLectivo === CURRENT_CICLO_LECTIVO
    ) {
      defaultItems.push({
        id: SIDEMENU_ID.PPS,
        title: 'PPS',
        icon: <Pps />,
        path: '/private/pps',
      });
    }

    if (showCORole) {
      defaultItems.push({
        id: SIDEMENU_ID.ACAP_ESCUELA,
        title: 'ACAP',
        icon: <BusinessCenterIcon titleAccess="ACAP" />,
        path: '/private/acapescuela',
      });
    }

    if (
      Number(nivel) === level.PRIMARIO &&
      (Number(selectedRole.rol) === ROL.DIRECTIVO ||
        Number(selectedRole.rol) === ROL.EQUIPO_CONDUCCION)
    ) {
      defaultItems.push({
        id: SIDEMENU_ID.LEERPARACRECER,
        title: 'Leer para crecer',
        icon: <MenuBookIcon titleAccess="Leer para crecer" />,
        path: '/private/leerparacrecer',
      });
    }

    const addAprendizajes = Number(nivel) === 2 || Number(nivel) === 3;
    const addCreatec = Number(nivel) === 2 || Number(nivel) === 3;
    const addPlataformaEvaluciones = Number(nivel) === 2;

    if (addAprendizajes) {
      defaultItems.push({
        id: SIDEMENU_ID.APRENDIZAJE_BASADO_EN_PROYECTOS,
        title: 'Aprendizaje basado en proyectos',
        icon: (
          <LocalLibraryOutlined titleAccess="Aprendizaje basado en proyectos" />
        ),
        path: '/private/aprendizajeBasadoEnProyectos',
      });
    }
    if (
      Number(selectedRole.rol) !== ROL.REFERENTE_ACAP &&
      Number(selectedRole.rol) !== ROL.COORDINADOR_ORIENTACION &&
      addCreatec
    ) {
      defaultItems.push({
        id: SIDEMENU_ID.CREATEC,
        title: 'Createc',
        icon: (
          <SvgIcon titleAccess="Createc">
            <CreatecSideBarIcon
              isActive={'/private/createc' === location.pathname}
            />
          </SvgIcon>
        ),
        path: '/private/createc',
      });
    }

    if (
      (Number(selectedRole.rol) === ROL.DOCENTE ||
        Number(selectedRole.rol) === ROL.DIRECTIVO ||
        Number(selectedRole.rol) === ROL.EQUIPO_CONDUCCION ||
        Number(selectedRole.rol) === ROL.SUPERVISOR) &&
      addPlataformaEvaluciones
    ) {
      defaultItems.push({
        id: SIDEMENU_ID.CREATEC,
        title: 'Plataforma evaluaciones',
        icon: (
          <SvgIcon titleAccess="Plataforma evaluciones">
            <PlataformaEvaluacionesIcon
              isActive={'/private/evaluaciones' === location.pathname}
            />
          </SvgIcon>
        ),
        path: '/private/evaluaciones',
      });
    }

    if (Number(selectedRole.rol) === ROL.NO_ROL) {
      defaultItems.push({
        id: SIDEMENU_ID.PERMISOS,
        title: 'Permisos de Roles',
        icon: (
          <SvgIcon titleAccess="Ciudadanía Global">
            <GlobeIcon />
          </SvgIcon>
        ),
        path: '/private/permisos',
      });
    }

    defaultItems.push({
      id: SIDEMENU_ID.CIUDADANIA_GLOBAL,
      title: 'Ciudadanía Global',
      icon: (
        <SvgIcon titleAccess="Ciudadanía Global">
          <GlobeIcon />
        </SvgIcon>
      ),
      path: '/private/ciudadania-global',
    });

    if (Number(nivel) === level.INICIAL) {
      defaultItems.filter((item) => {
        defaultItems = defaultItems.filter(
          (item) => item.id !== SIDEMENU_ID.CALIFICACIONES,
        );
      });
    }

    return defaultItems;
  }, [
    nivel,
    classes.defaultIconRole,
    selectedRole.rol,
    cicloLectivo?.idCicloLectivo,
    espaciosCurriculares,
    isOnlyJE,
    showCORole,
    location.pathname,
  ]);

  const newSidemMap = usePermissionSideFilter(
    selectedRole.rol === ROL.EQUIPO_ACAP.toString() ||
      selectedRole.rol === ROL.NO_ROL.toString() ||
      selectedRole.rol === ''
      ? []
      : sideMenuIcons,
  );

  const reloginDAO = useReloginDAO();
  const usuarioRolEstablecimientoDAO = useUsuarioRolEstablecimientoDAO();
  const history = useHistory();

  const onDeselectRole = useCallback(async () => {
    if (selectedRole.rol === ROL.SUPERVISOR.toString()) {
      usuarioRolEstablecimientoDAO.save({
        idRolEstablecimiento: selectedRole.idUsuarioRol,
        localizacion: -1,
      });
    }
    const res = await reloginDAO.unselectRole();
    const user = { ...res.user, isGuest: res.isGuest };
    // FIXME: esto es por algo que le falta a libby, quitarlo cuando libby logre reaccioner automaticamente a los cambios de tokens
    RESTConnection.defineHeader('x-chino-token', res.token);
    libby.session.notify('New session', user, res.token);
    if (
      selectedRole.rol === '' ||
      selectedRole.rol === '-1' ||
      selectedRole.rol === ROL.EQUIPO_ACAP.toString()
    )
      history.replace('/establecimientos/home');
    deselectRole();
  }, [
    history,
    deselectRole,
    libby.session,
    selectedRole,
    usuarioRolEstablecimientoDAO,
    reloginDAO,
  ]);

  useEffect(() => {
    if (Number(selectedRole.rol) === ROL.ADMIN)
      history.replace('/private/configurationadmin');
  }, [selectedRole.rol, history]);

  return (
    <div className={classes.root}>
      <nav
        className={
          isOpen ? classes.drawerOpenContainer : classes.drawerCloseContainer
        }
      >
        <Drawer
          classes={{
            paper: isOpen ? classes.drawerPaperOpen : classes.drawerPaperClose,
          }}
          variant="persistent"
          open
        >
          <Grid>
            <LogoBA />
          </Grid>
          <Grid container justifyContent="center">
            <IconButton
              onClick={() => setIsOpen(!isOpen)}
              className={classes.iconButtonStyle}
            >
              {isOpen ? (
                <KeyboardArrowRightIcon
                  style={{ transform: 'rotate(180deg)', transition: '0.8s' }}
                />
              ) : (
                <KeyboardArrowRightIcon style={{ transition: '0.8s' }} />
              )}
            </IconButton>
          </Grid>
          <List>
            {selectedRole.rol !== ROL.ESTUDIANTE.toString() && (
              <ListItem
                button
                disableGutters
                className={classes.iconContainerRole}
                onClick={onDeselectRole}
              >
                <PermIdentityIcon
                  className={classes.defaultIconRole}
                  titleAccess="Roles"
                />
                {isOpen && (
                  <Typography className={classes.titleStyle}>Roles</Typography>
                )}
              </ListItem>
            )}
            {canPromote && (
              <PermissionBlocker id={BLOCKER_ID.GESTION_PROYECCION}>
                <ListItem
                  button
                  disableGutters
                  className={classes.iconContainer}
                >
                  <NavLink
                    to="/establecimientos/gestion-promocion"
                    className={
                      'gestion-proyeccion' === location.pathname
                        ? classes.selectedIcon
                        : classes.defaultIcon
                    }
                  >
                    <TimelineIcon
                      className={classes.defaultIconRole}
                      titleAccess="Gestión de Proyección"
                    />
                    {isOpen && (
                      <Typography
                        className={
                          'gestion-proyeccion' === location.pathname
                            ? classes.selectedTitle
                            : classes.titleStyle
                        }
                      >
                        Gestión de Proyección
                      </Typography>
                    )}
                  </NavLink>
                </ListItem>
              </PermissionBlocker>
            )}

            {canSeeReports && (
              <EnviromentBlocker
                environments={[
                  'miescuela.bue.edu.ar',
                  'demo.miescuela.phinxlab.com',
                  'dev.miescuela.phinxlab.com',
                  'miescuela-staging.phinxlab.com',
                ]}
              >
                <ListItem
                  button
                  disableGutters
                  className={classes.iconContainer}
                >
                  <NavLink
                    to={
                      Number(selectedRole.rol) === ROL.NO_ROL ||
                      selectedRole.rol === ''
                        ? '/establecimientos/reportes'
                        : '/private/reportes'
                    }
                    className={
                      'reportes' === location.pathname
                        ? classes.selectedIcon
                        : classes.defaultIcon
                    }
                  >
                    <InsertChart
                      className={classes.defaultIconRole}
                      titleAccess="Reportes"
                    />
                    {isOpen && (
                      <Typography
                        className={
                          'reportes' === location.pathname
                            ? classes.selectedTitle
                            : classes.titleStyle
                        }
                      >
                        Reportes
                      </Typography>
                    )}
                  </NavLink>
                </ListItem>
              </EnviromentBlocker>
            )}

            {canSeeAcap && !localizacionId && (
              <ListItem button disableGutters className={classes.iconContainer}>
                <NavLink
                  onClick={() => handleClickAcap()}
                  to="/establecimientos/acap"
                  className={
                    'gestion-proyeccion' === location.pathname
                      ? classes.selectedIcon
                      : classes.defaultIcon
                  }
                >
                  <BusinessCenterIcon
                    className={classes.defaultIconRole}
                    titleAccess="ACAP"
                  />
                  {isOpen && (
                    <Typography
                      className={
                        'gestion-proyeccion' === location.pathname
                          ? classes.selectedTitle
                          : classes.titleStyle
                      }
                    >
                      ACAP
                    </Typography>
                  )}
                </NavLink>
              </ListItem>
            )}

            {Number(selectedRole.rol) === ROL.NO_ROL &&
            Number(userInfo.id) === -2 ? (
              <ListItem button disableGutters className={classes.iconContainer}>
                <NavLink
                  to="/establecimientos/permisos-roles"
                  className={classes.defaultIcon}
                >
                  <VpnKeyIcon
                    className={classes.defaultIconRole}
                    titleAccess="Permisos"
                  />
                  {isOpen && (
                    <Typography
                      className={
                        'permisos-roles' === location.pathname
                          ? classes.selectedTitle
                          : classes.titleStyle
                      }
                    >
                      Permisos de Roles
                    </Typography>
                  )}
                </NavLink>
              </ListItem>
            ) : null}

            {newSidemMap.map((item, index) => (
              <ListItem
                button
                key={index}
                disableGutters
                className={classes.iconContainer}
              >
                <NavLink
                  id={`nav-${item.title}`}
                  to={item.path}
                  className={
                    location.pathname.includes(item.path)
                      ? classes.selectedIcon
                      : classes.defaultIcon
                  }
                >
                  {item.icon}
                  {isOpen && (
                    <Typography
                      onClick={() =>
                        item.path === PATH_GESTION_ROLES && setIsOpen(false)
                      }
                      className={
                        item.path === location.pathname
                          ? classes.selectedTitle
                          : classes.titleStyle
                      }
                    >
                      {item.title}
                    </Typography>
                  )}
                </NavLink>
              </ListItem>
            ))}
          </List>
          <Grid
            container
            onClick={() => setIsOpen(!isOpen)}
            className={classes.blankContainer}
          />
        </Drawer>
      </nav>
    </div>
  );
};

export const SideMenu = DatabaseConnector(SideMenuRaw)();
