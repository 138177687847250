import React, { useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { useGestionRolesColumns } from './hooks';
import {
  useRouteScreenTitle,
  useTableSortConfig,
} from '../../../commons/hooks';
import { useRolesContext } from '../../../context/RolesContext';
import { JoinedFilters } from './components/GestionRolesHeader/types';
import { HeaderTable } from '../../../commons/components/HeaderTable';
import { InfoTable } from '../../../commons/components/InfoTable';
import { GestionRolesHeader } from './components/GestionRolesHeader';
import { useAutoRegistracionLibbyFetch } from '../../../app/business/AutoRegistracion';
import { BLOCKER_ID } from 'src/platform/permission/const';
import { PermissionBlocker } from 'src/lib/permission';

const searchFilters = [
  'usuario.apellidoUsuario',
  'usuario.nombreUsuario',
  'usuario.username',
  'rolUsuario.descripcion',
  'seccion.division',
  'espacioCurricular.descripcion',
  'rolStatus.descripcion',
];

const useStyles = makeStyles({
  typography: {
    fontSize: 12,
  },
});

export const GestionRoles = () => {
  const classes = useStyles();
  useRouteScreenTitle('Gestión de roles');
  const { selectedRole } = useRolesContext();
  const [filter, setFilter] = useState<JoinedFilters>({
    idRolUsuario: '',
    idRolStatus: '',
    search: '',
  });

  const processedFilter = useMemo(
    () => ({
      localizacion: [
        {
          path: 'localizacion.idLocalizacion',
          value: selectedRole.localizacionId,
        },
      ],
      ...(filter.idRolUsuario !== '' && {
        rol: [{ path: 'rolUsuario.idRolUsuario', value: filter.idRolUsuario }],
      }),
      ...(filter.idRolStatus !== '' && {
        estado: [{ path: 'rolStatus.idRolStatus', value: filter.idRolStatus }],
      }),
      ...(filter.search !== '' && {
        search: searchFilters.map((path) => ({
          path,
          value: filter.search,
          method: 'includes',
        })),
      }),
    }),
    [filter, selectedRole.localizacionId],
  );

  const { orderBy, onSortChange, direction } = useTableSortConfig([
    'rolStatus.idRolStatus',
    'usuario.apellidoUsuario',
  ]);
  const {
    data = [],
    working,
    fetchMore,
    reFetch,
  } = useAutoRegistracionLibbyFetch({
    direction,
    filter: processedFilter,
    aspect: 'limit_localization',
    orderBy,
  });
  const { column, rows, checkedUserRoles, handleModalGestionRoles } =
    useGestionRolesColumns(reFetch, data);

  return (
    <PermissionBlocker id={BLOCKER_ID.ROLES_MANAGEMENT}>
      <>
        <HeaderTable title={selectedRole.localizacion}>
          <GestionRolesHeader
            filter={filter}
            onChangeFilter={setFilter}
            handleModalGestionRoles={handleModalGestionRoles}
            hidden={
              (checkedUserRoles.length === rows.length ||
                checkedUserRoles.length) &&
              rows.length
            }
          />
        </HeaderTable>
        <InfoTable
          working={working}
          rows={rows}
          columns={column}
          rowIdKey="idGrupo"
          orderBy={typeof orderBy === 'string' ? orderBy : orderBy[0]}
          direction={direction}
          onSortChange={onSortChange}
          onBottomScroll={fetchMore}
          customClassnameTypography={classes.typography}
        />
      </>
    </PermissionBlocker>
  );
};
