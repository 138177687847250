import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useEspacioCurricularLibbyFetch } from 'src/app/business';
import { EspacioCurricularTab } from './EspacioCurricularTab';
import { useBackButton } from '../../../../commons/hooks';
import ECProvider from './components/ECProvider';

export const EspacioCurricular = () => {
  useBackButton('/private/espacioscurriculares');

  return (
    <ECProvider>
      <EspacioCurricularTab />
    </ECProvider>
  );
};
