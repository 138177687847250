import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useInputOnChangeValue } from '../../../../../../../commons/hooks';
import { JoinedFilters } from '../types';

export interface FilterLogicOptions {
  filter: JoinedFilters;
  onChangeFilter: Dispatch<SetStateAction<JoinedFilters>>;
}

export interface FiltersLogicReturn {
  search: string;
  idNivel: string;
  idTipo: string;
  onNivelChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onTipoChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
  onSearchChange: (value: React.ChangeEvent<HTMLInputElement>) => void;
}

export const useFiltersLogic = ({
  filter,
  onChangeFilter,
}: FilterLogicOptions): FiltersLogicReturn => {
  const [search, setSearch] = useState(filter.search);
  const [idNivel, setIdNivel] = useState(filter.idNivel);
  const [idTipo, setIdTipo] = useState(filter.idTipo);

  const onNivelChange = useInputOnChangeValue(setIdNivel);
  const onTipoChange = useInputOnChangeValue(setIdTipo);
  const onSearchChange = useInputOnChangeValue(setSearch);

  useEffect(() => {
    const debounce = setTimeout(() => {
      onChangeFilter((oldValue) => ({ ...oldValue, idNivel, idTipo, search }));
    }, 600);
    return () => {
      clearTimeout(debounce);
    };
  }, [idNivel, idTipo, search, onChangeFilter]);

  return {
    search,
    idNivel,
    idTipo,
    onNivelChange,
    onTipoChange,
    onSearchChange,
  };
};
