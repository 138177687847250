import { routes } from './routes';
import { MenuCollection } from '../../../commons/const/ui/menu';

export const autoregistracionMenu: MenuCollection = [
  {
    key: 'signUp',
    basePath: '/sign-up',
    enabled: true,
    icon: 'List',
    text: 'SignUp',
    children: routes,
  },
];
