import { Rules } from '../../../../lib/permission/types';
import { SIDEMENU_ID } from '../../const';

export const adminRuleSet: Rules = {
  idRule: 'rol-6',
  routes: [
    { key: 'matriculation' },
    { key: 'legajoAlumno' },
    { key: 'repositorioABM' },
    { key: 'planificacionesFeedback' },
    { key: 'configuration' },
    { key: 'gestionRoles' },
    { key: 'espacioscurriculares' },
    { key: 'signUp' },
    { key: 'aulasvirtuales' },
  ],
  blockers: [],
  sideMenu: [
    { id: SIDEMENU_ID.MATRICULACION },
    { id: SIDEMENU_ID.NOTIFICATIONS },
    { id: SIDEMENU_ID.CALIFICACIONES },
    { id: SIDEMENU_ID.BOLETINES },
    { id: SIDEMENU_ID.DASHBOARD },
    { id: SIDEMENU_ID.REPOSITORY_ABM },
    { id: SIDEMENU_ID.COMUNICATIONS },
    { id: SIDEMENU_ID.CURRICULAR_SPACES },
    { id: SIDEMENU_ID.DOCUMENTS },
    { id: SIDEMENU_ID.CALENDAR },
    { id: SIDEMENU_ID.LEGAJO },
    { id: SIDEMENU_ID.CONFIGURACION },
    { id: SIDEMENU_ID.GESTIONROLES },
    { id: SIDEMENU_ID.ALUMNOS_AULAS_VRITUALES },
  ],
  tabBars: [],
};
