type cycleType = {
  CICLO_1: string;
  CICLO_2: string;
  CICLO_3: string;
};

export const cycle: cycleType = {
  CICLO_1: '1',
  CICLO_2: '2',
  CICLO_3: '3',
};
