import React, { createContext, ReactNode, useContext } from 'react';
import { LibbyFetchByIdReturn, makeEntityFetchById } from '../hooks';

export function makeByIdContext<T>(daoName: string) {
  const useHook = makeEntityFetchById<T>(daoName);

  const ByIdContext = createContext<LibbyFetchByIdReturn<T>>({
    data: undefined,
    working: false,
  });

  const useByIdContext = () => useContext<LibbyFetchByIdReturn<T>>(ByIdContext);

  interface ByIdProviderProps {
    children: ReactNode;
    id: string;
  }

  const ByIdProvider = ({ children, id }: ByIdProviderProps) => {
    const value = useHook(id);

    return (
      <ByIdContext.Provider value={value}>{children}</ByIdContext.Provider>
    );
  };

  return {
    ByIdContext,
    ByIdProvider,
    useByIdContext,
  };
}
