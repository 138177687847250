import { ExceptuadoErrorPayload } from '../types';

export class ExceptuadoError {
  private errors: ExceptuadoErrorPayload[] = [];
  constructor(errors: ExceptuadoErrorPayload[] = []) {
    this.errors = errors;
  }

  displayErrors() {
    return [...new Set(this.errors)].map((payload) => payload.error).join('\n');
  }

  add(payload: ExceptuadoErrorPayload) {
    if (!this.errors.map((error) => error.error).includes(payload.error))
      this.errors.push(payload);
  }

  hasErrors() {
    return this.errors.length > 0;
  }
}
