import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { businessOptions } from '../businessOptions';
import { Presente_v3 } from '../../models';

const DAO_NAME = 'presente_v3';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPresenteV3',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPresenteV3',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.PresenteV3
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.PresenteV3,
      hoc: { ...defaultOptions.hoc, ...businessOptions.PresenteV3.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Presente_v3>(DAO_NAME, optionsToUse);

// hooks
export const usePresenteV3FetchById = fetchById;
export const usePresenteV3LibbyCall = libbyCall;
export const usePresenteV3DAO = libbyDAO;
export const usePresenteV3LibbyFetch = libbyFetch;

// components/hoc
export const PresenteV3Autocomplete = entityAutocomplete;
export const PresenteV3Call = renderPropCall;
export const PresenteV3ById = renderPropFetchById;
export const PresenteV3Select = simpleSelect;
export const PresenteV3AccordionSelector = accordionSelector;
export const PresenteV3SelectedChips = selectedChips;

// context
// ByIdContext
export const usePresenteV3ByIdContext = useByIdContext;
export const PresenteV3ByIdProvider = ByIdProvider;
export const PresenteV3ByIdContext = ByIdContext;
// GeneralContext
export const usePresenteV3Context = useGeneralContext;
export const PresenteV3Provider = GeneralProvider;
export const PresenteV3Context = GeneralContext;
