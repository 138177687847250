import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import { UsuarioRolEstablecimiento } from '../../../../../../../app/models';
import { FormFooter } from '../../../../../RepositorioABM/RepositorioABMForm/components/RepositorioItemForm/components/FormFooter';
import { FormContextProvider } from '../../../../../../../lib/templates/context';
import { rolTemplate } from '../../template';
import { useUsuarioRolEstablecimientoDAO } from '../../../../../../../app/business';
import { FormContent } from './components';

export interface InitialValueDefinition {
  idLocalizacion: string | number;
  idNivel: string | number;
  idRolUsuario: string | number;
}

export interface MainFormProps {
  initialValue: InitialValueDefinition;
  raw?: UsuarioRolEstablecimiento;
}

export const MainForm = ({ initialValue, raw }: MainFormProps) => {
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const usuarioRolEstablecimientoDAO = useUsuarioRolEstablecimientoDAO();
  const onSubmit = useCallback(
    async (values: InitialValueDefinition) => {
      try {
        const transformed = {
          localizacion: values.idLocalizacion,
          nivel: values.idNivel,
          rolUsuario: values.idRolUsuario,
        };
        const toSave = raw ? { ...raw, ...transformed } : transformed;
        await usuarioRolEstablecimientoDAO.save(toSave);
        enqueueSnackbar('Se ha guardado con éxito', { variant: 'success' });
        history.goBack();
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Error al guardar', { variant: 'error' });
      }
    },
    [raw, usuarioRolEstablecimientoDAO, enqueueSnackbar, history],
  );
  return (
    <FormContextProvider
      initialValues={initialValue}
      onSubmit={onSubmit}
      template={rolTemplate}
    >
      <FormContent />
      <FormFooter />
    </FormContextProvider>
  );
};
