import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { DEFAULT_HIDE_TIME } from 'src/commons/const/ui/snackbar';
import { usePipDAO, usePipLibbyFetch } from 'src/app/business';
import { usePipInformeRows } from '../hooks/usePipInformeRows';
import { usePipInformeColumns } from '../hooks/usePipInformeColumns';
import { InfoTable, Footer, AnyObject } from 'src/commons';
import { cloneDeep } from 'lodash';
import { useInformePipValidations } from '../hooks/useInformePipValidations';
import { useInformesStyles } from '../hooks/usePipInformeStyles';
import confirmDialog from 'src/commons/services/confirmDialog';
import { AlumnoMovimiento } from 'src/app/models';
import { Pip } from 'src/app/models/calificaciones/Pip';
import { useColegioEfectivizadoContext } from '../../../context/EfectivizacionProvider';

export const CargaPip = ({
  informePip,
  setInformePip,
  isAuthorizedToOpen,
}: {
  informePip: {
    row: AlumnoMovimiento;
    idPIP: Pip;
    button: string;
  };
  setInformePip: (val: {
    row: AlumnoMovimiento;
    idPIP: Pip;
    button: string;
  }) => void;
  isAuthorizedToOpen: boolean;
}) => {
  const classes = useInformesStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [rows, setRows] = useState(usePipInformeRows());
  const [isLoading, setIsLoading] = useState(false);
  const [disableSaveButton, setDisableSaveButton] = useState(true);
  const [disableCloseButton, setDisableCloseButton] = useState(false);
  const { isReadyToClose } = useInformePipValidations();
  const pipDao = usePipDAO();

  const { cicloLectivo } = useColegioEfectivizadoContext();

  const pipFilter = useMemo(
    () => ({
      pip: [
        {
          path: 'alumno',
          value: informePip?.row?.alumno?.idAlumno,
        },
      ],
      cicloLectivo: [
        {
          path: 'cicloLectivo.idCicloLectivo',
          value: cicloLectivo?.idCicloLectivo,
        },
      ],
    }),
    [informePip?.row?.alumno?.idAlumno, cicloLectivo],
  );

  const {
    data: pipRequestResult = [],
    working: workingPip,
    reFetch,
  } = usePipLibbyFetch({
    limit: 1,
    checkDuplication: false,
    filter: pipFilter,
    aspect: 'basic',
  });

  const pipFromRequest = pipRequestResult[0];

  const pipDataAlumno = pipFromRequest ?? informePip.currentPip;

  const abiertoValidation = pipDataAlumno?.abierto ?? true;

  const isReadOnly = informePip.button === 'visualizar';

  useEffect(() => {
    if (pipRequestResult.length > 0) {
      const rowsWithPipData = cloneDeep(rows).map((row) => {
        if (row.observaciones === '' && Boolean(pipDataAlumno.idPIP)) {
          row.observaciones = pipDataAlumno?.data[row?.preguntaNumero];
        }
        if (
          row.preguntaNumero === '11' &&
          row.archivoAdjunto === '' &&
          pipDataAlumno?.data.adjuntoDibujo
        ) {
          row.archivoAdjunto = pipDataAlumno?.data.adjuntoDibujo;
        }
        if (
          row.preguntaNumero === '12' &&
          row.archivoAdjunto === '' &&
          pipDataAlumno?.data.adjuntoInforme
        ) {
          row.archivoAdjunto = pipDataAlumno?.data.adjuntoInforme;
        }
        return row;
      });

      setRows(rowsWithPipData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pipRequestResult]);

  const buildRowsWithPipDataAlumno = useCallback(() => {
    const rowsWithPipData = cloneDeep(rows).map((row) => {
      if (row.observaciones === '' && Boolean(pipDataAlumno?.idPIP)) {
        row.observaciones = pipDataAlumno?.data[row?.preguntaNumero];
      }
      if (
        row.preguntaNumero === '11' &&
        row.archivoAdjunto === '' &&
        pipDataAlumno?.data.adjuntoDibujo
      ) {
        row.archivoAdjunto = pipDataAlumno?.data.adjuntoDibujo;
      }
      if (
        row.preguntaNumero === '12' &&
        row.archivoAdjunto === '' &&
        pipDataAlumno?.data.adjuntoInforme
      ) {
        row.archivoAdjunto = pipDataAlumno?.data.adjuntoInforme;
      }
      return row;
    });

    return rowsWithPipData;
  }, [pipDataAlumno, rows]);

  const handleDataChange = (data: any, selectedRow: any) => {
    const rowsToEdit = cloneDeep(rows);
    const rowToEditIndex = rowsToEdit.findIndex(
      (row) => row.preguntaNumero === selectedRow.preguntaNumero,
    );
    rowsToEdit[rowToEditIndex][data?.name] = data?.value;
    if (
      selectedRow.preguntaNumero === '12' &&
      data?.name === 'observaciones' &&
      data?.value.toLowerCase() === 'no'
    ) {
      rowsToEdit[rowToEditIndex].archivoAdjunto = '';
    }

    if (
      selectedRow.preguntaNumero === '12' &&
      data?.name === 'observaciones' &&
      data?.value.toLowerCase() === 'sí'
    ) {
    }
    setRows(rowsToEdit);
    setDisableSaveButton(
      () =>
        selectedRow.preguntaNumero === '12' &&
        data?.name === 'observaciones' &&
        data?.value.toLowerCase() === 'sí' &&
        selectedRow.archivoAdjunto === '',
    );
  };

  const buttonConfig: any = useMemo(() => {
    const handleLeave = async () => {
      if (!disableSaveButton) {
        const confirm = await confirmDialog.show({
          title: '¿Desea salir?',
          content:
            'La información precargada se perderá en caso que no guarde, por favor seleccione una opción.',
          confirmText: 'Salir',
          cancelText: 'Permanecer en esta pantalla',
        });
        if (confirm) {
          setInformePip({});
        }
      } else {
        setInformePip({});
      }
    };

    const onSubmit = async () => {
      setIsLoading(true);
      setDisableSaveButton(true);
      const data: AnyObject = {};
      data['adjuntoDibujo'] = rows.find(
        (row) => row.preguntaNumero === '11',
      )?.archivoAdjunto;
      data['adjuntoInforme'] = rows.find(
        (row) => row.preguntaNumero === '12',
      )?.archivoAdjunto;

      for (const row of rows) {
        data[row.preguntaNumero] = row.observaciones;
      }
      try {
        if (pipDataAlumno?.idPIP) {
          const alumnoPip = {
            idPIP: pipDataAlumno?.idPIP,
            alumno: informePip?.row?.alumno?.idAlumno,
            data,
          };
          const infoPIP = await pipDao.save(alumnoPip);
          setInformePip((prevState: any) => ({
            ...prevState,
            currentPip: infoPIP,
          }));
        } else {
          const alumnoPip = {
            alumno: informePip?.row?.alumno?.idAlumno,
            data,
          };
          const infoPIP = await pipDao.save(alumnoPip);
          setInformePip((prevState: any) => ({
            ...prevState,
            currentPip: infoPIP,
          }));
        }
        reFetch && reFetch();
        enqueueSnackbar(
          '¡Los cambios en el PIP del estudiante se han guardado con éxito!',
          {
            variant: 'success',
            autoHideDuration: DEFAULT_HIDE_TIME,
          },
        );
        setDisableSaveButton(true);
        setDisableCloseButton(false);
      } catch (e) {
        reFetch && reFetch();
        enqueueSnackbar(
          '¡Los cambios en el PIP del estudiante no se han podido guardar!',
          {
            variant: 'error',
            autoHideDuration: DEFAULT_HIDE_TIME,
          },
        );
        setDisableSaveButton(false);
      }
      setIsLoading(false);
    };

    const pipSwitch = async (state: any) => {
      setIsLoading(true);
      const content = isAuthorizedToOpen
        ? `Una vez cerrado el PIP no podrá ser modificado nuevamente por un docente, solo podrá modificarse nuevamente por un directivo y el equipo de conducción ¿Desea continuar?`
        : `Una vez iniciado el proceso de cierre de PIP el mismo no puede revertirse, para ello deberá ponerse en contacto con el directivo o el equipo de conducción según corresponda. ¿Desea continuar?`;

      const confirm = state
        ? true
        : await confirmDialog.show({
            title: '¿Desea continuar?',
            content: content,
            confirmText: 'Cerrar',
            cancelText: 'Salir',
          });
      if (confirm) {
        try {
          await pipDao.save({ ...pipDataAlumno, abierto: state });
          reFetch && reFetch();
          if (state) {
            enqueueSnackbar('¡El PIP del alumno fue abierto con éxito!', {
              variant: 'success',
              autoHideDuration: DEFAULT_HIDE_TIME,
            });
          } else {
            enqueueSnackbar('¡El PIP del alumno fue cerrado con éxito!', {
              variant: 'success',
              autoHideDuration: DEFAULT_HIDE_TIME,
            });
          }
        } catch (e) {
          enqueueSnackbar('¡El PIP del estudiante no se ha podido cerrar!', {
            variant: 'error',
            autoHideDuration: DEFAULT_HIDE_TIME,
          });
        }
      }
      setIsLoading(false);
    };

    const defaultButtons: any[] = [
      {
        title: 'Salir',
        size: 'medium',
        type: 'secondary',
        handleOnClick: handleLeave,
      },
      {
        title: 'Guardar',
        size: 'medium',
        handleOnClick: onSubmit,
        disabled: isLoading || isReadOnly || disableSaveButton,
      },
    ];

    if (abiertoValidation) {
      defaultButtons.push({
        title: 'Cerrar PIP',
        size: 'medium',
        handleOnClick: () => pipSwitch(false),
        disabled:
          isLoading ||
          isReadOnly ||
          !isReadyToClose(buildRowsWithPipDataAlumno()) ||
          disableCloseButton ||
          !disableSaveButton,
      });
    } else if (isAuthorizedToOpen) {
      defaultButtons.push({
        title: 'Abrir PIP',
        size: 'medium',
        handleOnClick: () => pipSwitch(true),
        disabled:
          isLoading ||
          !isAuthorizedToOpen ||
          (isAuthorizedToOpen && isReadOnly),
      });
    }
    return defaultButtons;
  }, [
    abiertoValidation,
    buildRowsWithPipDataAlumno,
    disableCloseButton,
    disableSaveButton,
    enqueueSnackbar,
    informePip?.row?.alumno?.idAlumno,
    isAuthorizedToOpen,
    isLoading,
    isReadOnly,
    isReadyToClose,
    pipDao,
    pipDataAlumno,
    reFetch,
    rows,
    setInformePip,
  ]);

  const columns = usePipInformeColumns(
    isLoading,
    isReadOnly,
    handleDataChange,
    pipDataAlumno,
    rows,
  );

  return (
    <>
      <Grid container className={`${classes.personalInfoContainer}`}>
        <Grid item xs={3} className={`${classes.personalInfoApellido}`}>
          {`${informePip?.row?.alumno?.persona?.apellido}, ${informePip?.row?.alumno?.persona?.nombre}`}
        </Grid>
        <Grid item xs={2} className={`${classes.personalInfoDNI}`}>
          {`DNI:  ${informePip?.row?.alumno?.persona?.documento}`}
        </Grid>
        {!abiertoValidation ? (
          <Grid item xs={6}>
            <div className={classes.isIncompleteClose}>
              <Typography className={classes.incompleteMessageClose}>
                El PIP se encuentra cerrado.
              </Typography>
            </div>
          </Grid>
        ) : null}
      </Grid>
      <InfoTable
        columns={columns}
        rows={rows}
        customStyle={classes.table}
        working={false}
      />
      <Grid className={classes.footer}>
        <Footer buttonConfig={buttonConfig} loading={workingPip || isLoading} />
      </Grid>
    </>
  );
};
