import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
interface ConfirmationDialogueTypes {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
}
const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  title,
}: ConfirmationDialogueTypes) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Confirmación</DialogTitle>
      <DialogContent>
        <DialogContentText>{title}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancelar
        </Button>
        <Button
          onClick={() => {
            onClose();
            onConfirm();
          }}
          color="primary"
        >
          Aceptar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
