/* eslint-disable no-useless-escape */
import { get, set, unset } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { AllBoolean } from '../../types';
import { DeepPartial } from 'src/commons/types/DeepPartial';

const emailRegex =
  /^[a-zA-Z\'\-_.\u00C0-\u017F\d-]+@(?:[a-zA-Z0-9\'\-\u00C0-\u017F]+\.)+[A-Za-z]+$/;
const arrayRegex = /\[\s*\d+\s*\]/;

const isValidEmail = (email: string) => {
  const emailDomain = email?.split('@')[1]?.split('.') || [];
  if (email === '' || email[0] === '-') return false;
  const isValidDomain = emailDomain.some(
    (domain) => domain[0] === '-' || domain[domain?.length - 1] === '-',
  );
  return emailRegex.test(email) || isValidDomain;
};

interface ValidatorOptions<T> {
  /**
   * Se configura los valores que seran opcionales y se omitiran las validaciones
   * Por defecto son requeridos
   */
  optionals: DeepPartial<AllBoolean<T>>;
}

export function useInputValidator<T extends object>(
  values: T,
  options?: ValidatorOptions<T>,
) {
  const [touched, setTouched] = useState<Partial<AllBoolean<T>>>({});

  const errors = useMemo(() => {
    const _errors: Partial<AllBoolean<T>> = {};
    const validate = (data: object, parentPath?: string) => {
      Object.entries(data).forEach(([path, value]) => {
        if (
          !Array.isArray(value) &&
          typeof value === 'object' &&
          value !== null &&
          value !== undefined
        ) {
          validate(value, path);
        } else if (
          Array.isArray(value) &&
          value !== null &&
          value !== undefined
        ) {
          value.map((_v, index) => {
            const currentPath = parentPath
              ? `${parentPath}[${index}]`
              : `${path}[${index}]`;
            validate(_v, currentPath);
          });
        } else {
          const currentPath = (
            parentPath ? `${parentPath}.${path}` : path
          ).replace(arrayRegex, '[0]');
          const isOpcional = !!get(options?.optionals, currentPath);
          if (isOpcional) return;
          let _value = true;
          if (!Boolean(value)) {
            _value = true;
          } else {
            _value = false;
          }
          if (path === 'telefono' && value !== '') {
            _value = !/^[0-9-]+$/.test(value);
          } else if (path === 'direccion' && value !== '') {
            _value = !/^[a-zA-Z\'\-\u00C0-\u017F\s\d]+$/.test(value);
          } else if (path === 'domicillio' && value !== '') {
            _value = !/^[a-zA-Z\'\-\.\u00C0-\u017F\s\d]+$/.test(value);
          } else if (path === 'cuit') {
            _value = !/^[\d-]+$/.test(value) || value.length < 13;
          } else if (path === 'email') {
            _value = !isValidEmail(value);
          } else if (
            (path === 'nombre' || path === 'apellido') &&
            value !== ''
          ) {
            _value = !/^[a-zA-Z\u00C0-\u017F\s]+$/.test(value);
          }
          if (_value) {
            set(_errors, currentPath, _value);
          } else {
            unset(_errors, currentPath);
          }
        }
      });
    };
    validate(values);
    return _errors;
  }, [options?.optionals, values]);

  const handleTouche = useCallback<
    React.FocusEventHandler<HTMLTextAreaElement | HTMLInputElement>
  >((e) => {
    const name = e.target.name;
    if (!name) return;
    setTouched((prev) => {
      const newValue = set(prev, name, true);
      return { ...newValue };
    });
  }, []);

  return { errors, touched, handleTouche, setTouched };
}
