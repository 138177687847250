import React, {
  createContext,
  FunctionComponent,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useParams, withRouter } from 'react-router-dom';
import {
  useDatosLaboralesRespLibbyCall,
  useDomicilioPersonaLibbyCall,
} from 'src/app/business';
import { DatosLaboralesResp, DomicilioPersona } from 'src/app/models';
import { LegajoLayoutType, changeResponsableArguments } from '../types';

const LegajoDelAlumnoProviderRaw: FunctionComponent = ({ children }) => {
  const { id } = useParams<{ id: string }>();
  const [alumnoId, setAlumnoId] = useState<number>(Number(id));
  const [responsableData, setResponsableData] = useState<any>(null);
  const [responsableDatosSecundarios, setResponsableDatos] =
    useState<any>(undefined);
  const [saving, setIsSavingStatus] = useState<boolean>(false);
  const [saveDone, setSaveDoneStatus] = useState<boolean>(false);
  const [isNewResponsable, setNewResponsable] = useState<boolean>(false);
  const [isEditResponsable, setEditResponsable] = useState<boolean>(false);

  useEffect(() => {
    setAlumnoId(Number(id));
  }, [id]);

  const changeResponsableIds = (data: changeResponsableArguments) => {
    setResponsableData(data);
  };

  const setResponsableDatosSecundarios = (data: any) => {
    setResponsableDatos(data);
  };

  const setBeingSaved = (saved: boolean) => {
    setIsSavingStatus(saved);
  };

  const setSaveDone = (saved: boolean) => {
    setSaveDoneStatus(saved);
  };

  const setIsNewResponsable = (isNew: boolean) => {
    setNewResponsable(isNew);
  };

  const setIsEditResponsable = (isEdit: boolean) => {
    setEditResponsable(isEdit);
  };

  const value = useMemo(() => {
    return {
      alumnoId,
      responsableData,
      changeResponsableIds,
      saving,
      setBeingSaved,
      setIsNewResponsable,
      isNewResponsable,
      isEditResponsable,
      setIsEditResponsable,
      saveDone,
      setSaveDone,
      setResponsableDatosSecundarios,
      responsableDatosSecundarios,
    };
  }, [
    alumnoId,
    responsableData,
    saving,
    saveDone,
    isNewResponsable,
    isEditResponsable,
    responsableDatosSecundarios,
  ]);

  return (
    <LegajoLayoutContext.Provider value={value}>
      {children}
    </LegajoLayoutContext.Provider>
  );
};

export const LegajoDelAlumnoProvider = withRouter(LegajoDelAlumnoProviderRaw);

export const LegajoLayoutContext = createContext<LegajoLayoutType>(
  {} as LegajoLayoutType,
);

export const useLegajoLayout = () => {
  const context = useContext(LegajoLayoutContext);
  const params = useMemo(() => [String(context.alumnoId)], [context.alumnoId]);
  const responsableData = useMemo(
    () => context.responsableData,
    [context.responsableData],
  );
  const saving = useMemo(() => context.saving, [context.saving]);
  const responsableDatosSecundarios = useMemo(
    () => context.responsableDatosSecundarios,
    [context.responsableDatosSecundarios],
  );
  const [fetchedDatosSecundarios, setFetchedDatosSecundarios] =
    useState<boolean>(false);

  const {
    data: responsableDatosLaboralesFetched,
    recall: recallDatosLaborales,
    working: workingDatosLaborales,
  } = useDatosLaboralesRespLibbyCall<DatosLaboralesResp[]>({
    methodName: 'getByResponableId',
    params: [responsableData?.responsableId],
    noAutoCall: true,
  });

  const {
    data: responsableDomicilioFetched,
    recall: recallDomicilio,
    working: workingDomicilio,
  } = useDomicilioPersonaLibbyCall<DomicilioPersona[]>({
    methodName: 'getByPersonaId',
    params: [responsableData?.responsablePersona?.idPersona],
    noAutoCall: true,
  });

  const responsableDatosLaborales = useMemo(
    () =>
      responsableDatosSecundarios === null
        ? null
        : responsableDatosLaboralesFetched,
    [responsableDatosLaboralesFetched, responsableDatosSecundarios],
  );
  const responsableDomicilio = useMemo(
    () =>
      responsableDatosSecundarios === null ? null : responsableDomicilioFetched,
    [responsableDomicilioFetched, responsableDatosSecundarios],
  );

  useEffect(() => {
    if (
      responsableDomicilio !== undefined &&
      responsableDomicilio?.[0]?.persona?.idPersona ===
        responsableData?.responsableCustomData?.idPersona
    ) {
      context.setResponsableDatosSecundarios?.({
        responsableDatosLaborales,
        responsableDatosDomicilio: responsableDomicilio,
      });
      setFetchedDatosSecundarios(true);
    }
  }, [
    responsableDatosLaborales,
    responsableDomicilio,
    fetchedDatosSecundarios,
    responsableData,
    context,
  ]);

  useEffect(() => {
    if (
      responsableData?.responsableCustomData?.idResponsable &&
      responsableData?.responsableCustomData?.idPersona &&
      !fetchedDatosSecundarios
    ) {
      recallDatosLaborales([
        responsableData?.responsableCustomData?.idResponsable,
      ]);
      recallDomicilio([responsableData?.responsableCustomData?.idPersona]);
    }
  }, [
    responsableData,
    fetchedDatosSecundarios,
    recallDatosLaborales,
    recallDomicilio,
  ]);

  useEffect(() => {
    if (responsableData?.responsableCustomData?.idResponsable)
      setFetchedDatosSecundarios(false);
  }, [responsableData]);

  return {
    form: context,
    params,
    data: {
      responsableData,
      responsableDatosLaborales:
        responsableDatosSecundarios?.responsableDatosLaborales?.[0],
      responsableDatosDomicilio:
        responsableDatosSecundarios?.responsableDatosDomicilio?.[0],
    },
    working: workingDatosLaborales || workingDomicilio || saving,
  };
};

const LegajoData = createContext<any>(undefined);

export const LegajoDataProvider = ({ value, children }: any) => {
  return <LegajoData.Provider value={value}>{children}</LegajoData.Provider>;
};

export const useLegajoData = () => useContext(LegajoData);
