import { Presente_v3 } from 'src/app/models';
import { TurnoOptions } from '../types';

export const filtroPresentismoContraturno = (
  contraturnosOptions: TurnoOptions[],
  ausencias: Presente_v3[],
) => {
  const _contraturnosOptions: TurnoOptions[] = [];

  contraturnosOptions.forEach((contraturno) => {
    const ids = contraturno.value.split(',');
    const samePresentismoIds: string[] = [];
    ids.forEach((id) => {
      const hasSameId = ausencias.some(
        (ausencias) =>
          ausencias.contraturnoDia?.idContraturnoDia.toString() === id,
      );

      if (hasSameId) {
        samePresentismoIds.push(id);
      }
    });

    _contraturnosOptions.push({
      ...contraturno,
      value: samePresentismoIds.join(',') || '0',
      disabled: samePresentismoIds.length === 0,
    });
  });

  return _contraturnosOptions;
};
