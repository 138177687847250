import React, { FunctionComponent } from 'react';
import { makeStyles } from '@material-ui/core';
import { ColorBar, Header } from '..';
import { MenuCollection } from '../../const/ui/menu';
import { useGeneralContext } from 'src/context';
import { useBackButton } from 'src/commons/hooks';

const useStyles = makeStyles(() => ({
  container: {
    width: '100vw',
    height: '100vh',
    margin: -8,
  },
  menuContainer: {
    flexDirection: 'row',
    flex: 1,
    paddingLeft: 25,
    paddingRight: 25,
  },
  sideAndContent: {
    display: 'flex',
    flexDirection: 'row',
  },
}));

interface NoMenuLayoutProps {
  children: React.ReactNode;
  menu: MenuCollection;
}

export const NoMenuLayout: FunctionComponent<NoMenuLayoutProps> = ({
  children,
}) => {
  const classes = useStyles();
  const {
    generalState: { persona },
  } = useGeneralContext();
  useBackButton('/establecimientos/home');

  return (
    <div className={classes.container}>
      <ColorBar />
      <div className={classes.menuContainer}>
        {persona ? (
          <Header
            title="Solicitar Usuario"
            backToProfile="sinEstablecimiento"
            backButton
          />
        ) : (
          <Header
            title="Solicitar Usuario"
            backToProfile="sinEstablecimiento"
          />
        )}
        <main>
          <div />
          {children}
        </main>
      </div>
    </div>
  );
};
