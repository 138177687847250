import React, { useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { AnyObject } from 'src/commons';
import {
  LocalizacionById,
  EspaciosOrientacionesAutocomplete,
  usePlanEstudioMateriaLibbyFetch,
} from '../../../../../../../../app/business';
import { Establishment } from '../../commons/components/Establishment';
import { Orientacion } from '../../../../../../../../app/models';
import { grey } from '../../../../../../../../theme/colors';

const useStyles = makeStyles({
  title: {
    fontSize: 13,
    fontWeight: 700,
    color: grey.medium,
  },
  orientacionTextField: {
    minWidth: 383,
    height: 40,
  },
});

export type InterdisciplinarHeaderProps = {
  localizacionId: string;
  orientaciones: Orientacion[];
  espacioCurricular: AnyObject | undefined;
  onChangeOrientaciones: (newOrientaciones: Orientacion[]) => void;
};

export const InterdisciplinarHeader = ({
  localizacionId,
  espacioCurricular,
  orientaciones,
  onChangeOrientaciones,
}: InterdisciplinarHeaderProps) => {
  const classes = useStyles();

  const filterPlanEstudio = useMemo(
    () => ({
      filter: {
        planEstudio: [
          {
            path: 'planEstudio',
            value: espacioCurricular?.planEstudio.idPlanEstudio || 0,
          },
        ],
        anio: [
          {
            path: 'materia.anio',
            value: espacioCurricular?.materia.anio.idAnio || 0,
          },
        ],
      },
      autoCall: espacioCurricular?.materia.anio.idAnio || 0,
    }),
    [espacioCurricular],
  );

  const { data: planEstudioMateria, working } =
    usePlanEstudioMateriaLibbyFetch(filterPlanEstudio);

  const filter = useMemo(
    () => ({
      espacio: planEstudioMateria?.map((item) => ({
        path: 'idMateria',
        value: item.materia.idMateria,
      })),
      cicloBasico: [
        {
          path: 'orientacion.idOrientacion',
          value: 1,
        },
      ],
    }),
    [planEstudioMateria],
  );

  const orientacionMateriaFilter = useMemo(
    () => ({
      espacio: planEstudioMateria?.map((item) => ({
        path: 'materia',
        value: item.materia.idMateria,
      })),
    }),
    [planEstudioMateria],
  );

  return (
    <Grid container style={{ paddingBottom: 10 }}>
      <LocalizacionById id={localizacionId}>
        {({ data }) => (
          <Establishment establishmentData={data?.establecimiento} />
        )}
      </LocalizacionById>
      <Grid container spacing={1} alignItems="center" style={{ paddingTop: 5 }}>
        <Grid item>
          {!working && (
            <EspaciosOrientacionesAutocomplete
              filter={filter}
              orientacionMateriaFilter={orientacionMateriaFilter}
              value={orientaciones}
              onChange={onChangeOrientaciones}
              multiple
              textFieldProps={{ className: classes.orientacionTextField }}
            />
          )}
        </Grid>
      </Grid>
    </Grid>
  );
};
