import React, { useRef } from 'react';
import { Grid } from '@material-ui/core';
import { useRolesContext } from 'src/context/RolesContext';
import {
  SeccionProvider,
  EspacioCurricularByIdProvider,
  SimpDiscipPlanFeedbackCall,
  useSimpDiscipPlanFeedbackDAO,
} from 'src/app/business';
import {
  InfoCard,
  PlanificacionFeedbackForm,
  PlanificacionFeedbackView,
} from '../commons/components';
import { useInfoCards } from './hooks';
import {
  SimpDiscipPlanFeedback,
  EspaciosSimpDiscipPlan,
} from '../../../../../../../app/models';
import { useSimpDiscipPlanDAO } from '../../../../../../../app/business';
import { PLANIFICACION_ESTADOS } from '../../../../../../../commons/const';
import { Loading } from '../../../../../../../commons/components/Loading';
import { DisciplinarComunProvider } from '../../forms/PlanificacionDisciplinarComun/components/context';
import { DisciplinarComunFlowHeader } from './components';
import { useFeedbackOnSubmit } from '../commons';

export interface DisciplinarComunFlowProps {
  data: EspaciosSimpDiscipPlan;
}

export const DisciplinarComunFlow = ({ data }: DisciplinarComunFlowProps) => {
  const cards = useInfoCards(data);

  const { selectedRole } = useRolesContext();

  const seccionFilter = useRef({
    filter: [{ path: 'localizacion', value: selectedRole.localizacionId }],
  });

  const simpDiscipPlanFeedbackDAO = useSimpDiscipPlanFeedbackDAO();
  const simpDiscipPlanDAO = useSimpDiscipPlanDAO();
  const onSubmit = useFeedbackOnSubmit({
    planDAO: simpDiscipPlanDAO,
    feedbackDAO: simpDiscipPlanFeedbackDAO,
    plan: data.simpDiscipPlan,
    aspectFeedback: 'limit_province',
  });

  const isSend =
    data.simpDiscipPlan.planificacionEstados.idPlanificacionEstados ===
    PLANIFICACION_ESTADOS.ENVIADO;

  return (
    <SeccionProvider filter={seccionFilter.current}>
      <>
        <DisciplinarComunFlowHeader data={data} />
        <Grid container>
          <Grid item sm={6}>
            <EspacioCurricularByIdProvider
              id={data.espacioCurricular.idEspacioCurricular}
            >
              <DisciplinarComunProvider>
                <InfoCard title="Planificación" cards={cards} />
              </DisciplinarComunProvider>
            </EspacioCurricularByIdProvider>
          </Grid>
          <Grid item sm={6}>
            <SimpDiscipPlanFeedbackCall<SimpDiscipPlanFeedback>
              methodName="getLastFeedback"
              params={[data.simpDiscipPlan.idSimpDiscipPlan]}
              aspect="limit_province"
            >
              {({ data: planFeedback, working }) =>
                working || planFeedback === undefined ? (
                  <Loading />
                ) : isSend ? (
                  <PlanificacionFeedbackForm
                    onSubmit={onSubmit}
                    data={planFeedback}
                  />
                ) : (
                  <PlanificacionFeedbackView data={planFeedback.feedback} />
                )
              }
            </SimpDiscipPlanFeedbackCall>
          </Grid>
        </Grid>
      </>
    </SeccionProvider>
  );
};
