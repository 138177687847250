import React from 'react';
import { useRolesContext } from 'src/context/RolesContext';
import { RolHasNotPermission } from 'src/lib/permission/components/RolHasNotPermission';

interface RolBlockerProps {
  children: React.ReactElement;
  enableForRoles: number[];
}

export const RolBlocker = ({
  enableForRoles = [],
  children,
}: RolBlockerProps) => {
  const {
    selectedRole: { rol },
  } = useRolesContext();

  return enableForRoles.includes(Number(rol)) ? children : null;
};
