import React, { useCallback, useState } from 'react';
import moment from 'moment';
import customFormDialog from 'src/commons/services/customFormDialog';
import { toBase64 } from 'src/utils';
import { FormContextProvider } from 'src/lib/templates/context';
import { useRolesContext } from 'src/context/RolesContext';
import { usePublicacionDAO } from 'src/app/business';
import { AnyObject } from 'src/commons/types';
import { PublicacionItemForm } from './PublicacionItemForm';
import { nuevaPublicacionTemplate } from './template';
import { Publicacion } from 'src/app/models';

type InitialValueFile = {
  files: File[];
  value: string;
};

export interface InitialValueDefinition {
  descripcion: string;
  file?: InitialValueFile;
  idRol?: string | number;
  comentable: boolean;
  idEspacioCurricular?: string | number;
}

export interface NuevaPublicacionFormProps {
  initialValues: InitialValueDefinition;
  raw?: Publicacion;
  reFetch?: () => void;
}

const getFileProperties = async (file: InitialValueFile) => {
  const base64: string = await toBase64(file.files[0]);
  const pieces = base64.split('base64,');
  return {
    file_name: file.value,
    base64: pieces[pieces.length - 1],
    mime_type: file.files[0].type,
  };
};

export const NuevaPublicacionForm = ({
  initialValues,
  raw,
  reFetch,
}: NuevaPublicacionFormProps) => {
  const { userInfo, selectedRole } = useRolesContext();
  const [submitted, setSubmitted] = useState<boolean>(false);
  const publicacionDAO = usePublicacionDAO();
  const onSubmit = useCallback(
    async (values: InitialValueDefinition) => {
      try {
        if (raw) {
          const updatedItem: AnyObject = {
            ...raw,
            descripcion: values.descripcion,
            rol: values.idRol === '' ? null : values.idRol,
            comentable: values.comentable,
            idEspacioCurricular: values.idEspacioCurricular,
          };
          if (values.files) {
            const { file_name, base64, mime_type } = await getFileProperties(
              values.files,
            );
            updatedItem.file_name = file_name;
            updatedItem.base64 = base64;
            updatedItem.mime_type = mime_type;
          }
          await publicacionDAO.save(updatedItem);
          setTimeout(() => {
            reFetch && reFetch();
          }, 1000);
          await customFormDialog.handleCancel();
        } else {
          const newItem: AnyObject = {
            descripcion: values.descripcion,
            cuentas: userInfo.id,
            rol: values.idRol,
            comentable: values.comentable,
            fechaCreacion: moment().toISOString(),
            idEspacioCurricular: values.idEspacioCurricular,
            localizacion: selectedRole.localizacionId,
            nivel: selectedRole.nivel,
          };
          if (values.files) {
            const { file_name, base64, mime_type } = await getFileProperties(
              values.files,
            );
            newItem.file_name = file_name;
            newItem.base64 = base64;
            newItem.mime_type = mime_type;
          }
          await publicacionDAO.save(newItem);
          setTimeout(() => {
            reFetch && reFetch();
          }, 1000);
          setSubmitted(true);
          // enqueueSnackbar('Se ha guardado con éxito', { variant: 'success' });
        }
      } catch (e) {
        console.log(e);
        // enqueueSnackbar('Error al guardar', { variant: 'error' });
      }
    },
    [publicacionDAO, userInfo, raw, reFetch, selectedRole],
  );

  return (
    <FormContextProvider
      initialValues={initialValues}
      onSubmit={onSubmit}
      template={nuevaPublicacionTemplate}
    >
      <PublicacionItemForm
        raw={raw}
        submitted={submitted}
        setSubmitted={setSubmitted}
      />
    </FormContextProvider>
  );
};
