import moment from 'moment';
import React, {
  FC,
  ReactNode,
  createContext,
  useContext,
  useMemo,
  Dispatch,
  SetStateAction,
  useState,
} from 'react';
import { usePropuestaJELibbyFetch } from '../../../../../app/business/presentismo_v2';
import {
  useFechaBloqueadasV3LibbyFetch,
  useSituacionV3LibbyFetch,
} from '../../../../../app/business/presentismo_v3';
import {
  PeriodoNuevo,
  Presente_v3,
  SituacionV3,
} from '../../../../../app/models';
import {
  FechaBloqueada,
  PropuestaJE,
} from '../../../../../app/models/presentismo_v2';
import { useDebounceDate } from '../../../../../commons';
import {
  usePeriodoNuevoLibbyCall,
  usePresentismoEspacioCurricularLibbyFetch,
} from '../../../../../app/business';
import { useRolesContext } from '../../../../../context/RolesContext';
import { AlumnoMap, PresenteGeneral } from '../types';
import { useParams } from 'react-router';

export interface PresentismoContextValue {
  newPresentismos: PresenteGeneral[];
  updatePresentismos: PresenteGeneral[];
  dbPresentismos: PresenteGeneral[];
  isJornadaExtendida: boolean;
  propuestasJE: PropuestaJE[];
  feriados: FechaBloqueada[];
  currentWeek: moment.Moment;
  pastDaysOfWeek: number;
  currentWeekState: moment.Moment;
  situaciones: SituacionV3[];
  loading: boolean;
  periodoNuevo: PeriodoNuevo[];
  setNewPresentismos: Dispatch<SetStateAction<PresenteGeneral[]>>;
  setUpdatePresentismos: Dispatch<SetStateAction<PresenteGeneral[]>>;
  setDBPresentismos: Dispatch<SetStateAction<PresenteGeneral[]>>;
  setIsJornadaExtendida: Dispatch<SetStateAction<boolean>>;
  setCurrentWeek: React.Dispatch<React.SetStateAction<moment.Moment>>;
  setPastDaysOfWeek: React.Dispatch<React.SetStateAction<number>>;
}

const InitialContextValue: PresentismoContextValue = {
  isJornadaExtendida: false,
  propuestasJE: [],
  feriados: [],
  newPresentismos: [],
  updatePresentismos: [],
  dbPresentismos: [],
  currentWeek: moment().startOf('isoWeek'),
  pastDaysOfWeek: Number(moment().format('e')),
  currentWeekState: moment().startOf('isoWeek'),
  situaciones: [],
  loading: false,
  periodoNuevo: [],
  setNewPresentismos: () => {},
  setUpdatePresentismos: () => {},
  setDBPresentismos: () => {},
  setIsJornadaExtendida: () => {},
  setCurrentWeek: () => {},
  setPastDaysOfWeek: () => {},
};

export const PresentismoContext =
  createContext<PresentismoContextValue>(InitialContextValue);

export interface IPresentismoContextProvider {
  children: ReactNode | ReactNode[];
}

export const PresentismoContextProvider: FC<IPresentismoContextProvider> = ({
  children,
}) => {
  const [newPresentismos, setNewPresentismos] = useState<PresenteGeneral[]>([]);
  const [dbPresentismos, setDBPresentismos] = useState<PresenteGeneral[]>([]);
  const [updatePresentismos, setUpdatePresentismos] = useState<
    PresenteGeneral[]
  >([]);
  const [isJornadaExtendida, setIsJornadaExtendida] = useState(false);
  const [currentWeek, setCurrentWeek] = useState<moment.Moment>(
    moment().startOf('isoWeek'),
  );
  const [pastDaysOfWeek, setPastDaysOfWeek] = useState<number>(
    Number(moment().format('e')),
  );

  const currentWeekDebounce = useDebounceDate(currentWeek, 2000);

  const {
    selectedRole: { nivel },
  } = useRolesContext();

  const { data: propuestasJE = [], working: propuestasWorking } =
    usePropuestaJELibbyFetch({ limit: 30, aspect: 'default' });

  const filterFeriados = useMemo(
    () => ({
      actives: [{ path: 'active', value: true }],
      fechas: [
        {
          path: 'date',
          value: [...Array(6).keys()].map(
            (n) =>
              `'${moment(currentWeekDebounce)
                .startOf('isoWeek')
                .add(n, 'days')
                .format('YYYY-MM-DD')}'`,
          ),
          method: 'in',
        },
      ],
    }),
    [currentWeekDebounce],
  );

  // FERIADOS : DESHABILITAR LAS CELDAS EN LAS QUE COINCIDA CON UN FERIADO , NO SE PODRA EDITAR
  const { data: feriados = [], working: feriadosW } =
    useFechaBloqueadasV3LibbyFetch({
      limit: 100,
      filter: filterFeriados,
    });
  // RECESO INVERNAL : DESHABILITAR CELDAS PARA ESTE PERIODO, SET NC
  const { data: periodoNuevo = [], working: periodoNuevoWorking } =
    usePeriodoNuevoLibbyCall({
      methodName: 'getPeriodoActual',
      params: [nivel],
      aspect: 'basic',
    });

  const filterSituaciones = useMemo(() => {
    const dates = [...Array(6).keys()].map(
      (n) =>
        `${moment(currentWeekDebounce)
          .startOf('isoWeek')
          .add(n, 'days')
          .format('YYYY-MM-DD')}`,
    );

    return {
      desde: [
        {
          path: 'fechaDesde',
          value: `${dates[0]}`,
          method: 'higherOrEquals',
        },
      ],
      hasta: [
        {
          path: 'fechaHasta',
          value: `${dates[dates.length - 1]}`,
          method: 'lowerOrEquals',
        },
      ],
    };
  }, [currentWeekDebounce]);

  const { data: situaciones = [], working: situacionWorking } =
    useSituacionV3LibbyFetch({
      limit: 100,
      filter: filterSituaciones,
      aspect: 'default',
    });

  const value = useMemo<PresentismoContextValue>(
    () => ({
      newPresentismos,
      setNewPresentismos,
      updatePresentismos,
      setUpdatePresentismos,
      dbPresentismos,
      setDBPresentismos,
      isJornadaExtendida,
      setIsJornadaExtendida,
      propuestasJE,
      feriados,
      setCurrentWeek,
      setPastDaysOfWeek,
      currentWeek: currentWeekDebounce,
      pastDaysOfWeek,
      currentWeekState: currentWeek,
      situaciones,
      periodoNuevo,
      loading:
        propuestasWorking ||
        feriadosW ||
        periodoNuevoWorking ||
        situacionWorking,
    }),
    [
      newPresentismos,
      setNewPresentismos,
      updatePresentismos,
      setUpdatePresentismos,
      dbPresentismos,
      isJornadaExtendida,
      setIsJornadaExtendida,
      propuestasJE,
      feriados,
      setCurrentWeek,
      setPastDaysOfWeek,
      currentWeek,
      currentWeekDebounce,
      pastDaysOfWeek,
      situaciones,
      propuestasWorking,
      feriadosW,
      periodoNuevo,
      periodoNuevoWorking,
      situacionWorking,
    ],
  );

  return (
    <PresentismoContext.Provider value={value}>
      {children}
    </PresentismoContext.Provider>
  );
};
export const usePresentismoContext = () =>
  useContext<PresentismoContextValue>(PresentismoContext);
