import React from 'react';
import { SelectWithField, SimpleSelect } from 'src/commons';
import { useDevolucionOrganizacionFormContext } from '../hooks/useDevolucionOrgFormContext';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    width: '40%',
  },
  label: {
    marginRight: '1em',
    fontFamily: 'Open Sans',
  },
});

const options = [
  {
    name: 'Si',
    id: 1,
  },
  {
    name: 'No',
    id: 0,
  },
];

export const CheckboxCV = () => {
  const classes = useStyles();
  const { form } = useDevolucionOrganizacionFormContext();
  return (
    <div className={classes.inputContainer}>
      <label className={classes.label} htmlFor="cvIpt">
        ¿Puede citarme como referencia en su CV?
      </label>
      <SelectWithField
        placeholder="Seleccioná una opción"
        name="citarCv"
        form={form}
        content={options}
        labelKey="name"
        valueKey="id"
      />
    </div>
  );
};
