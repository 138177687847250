import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { EspacioCurricular } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'espacio_curricular_custom';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEspacioCurricular',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEspacioCurricular',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EspacioCurricular
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EspacioCurricular,
      hoc: { ...defaultOptions.hoc, ...businessOptions.EspacioCurricular.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    selectInput,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EspacioCurricular>(DAO_NAME, optionsToUse);

// hooks
export const useEspacioCurricularCustomFetchById = fetchById;
export const useEspacioCurricularCustomLibbyCall = libbyCall;
export const useEspacioCurricularCustomDAO = libbyDAO;
export const useEspacioCurricularCustomLibbyFetch = libbyFetch;

// components/hoc
export const EspacioCurricularCustomAutocomplete = entityAutocomplete;
export const EspacioCurricularCustomCall = renderPropCall;
export const EspacioCurricularCustomById = renderPropFetchById;
export const EspacioCurricularCustomSelect = selectInput;
export const EspacioCurricularCustomAccordionSelector = accordionSelector;
export const EspacioCurricularCustomSelectedChips = selectedChips;

// context
// ByIdContext
export const useEspacioCurricularCustomByIdContext = useByIdContext;
export const EspacioCurricularCustomByIdProvider = ByIdProvider;
export const EspacioCurricularCustomByIdContext = ByIdContext;
// GeneralContext
export const useEspacioCurricularCustomContext = useGeneralContext;
export const EspacioCurricularCustomProvider = GeneralProvider;
export const EspacioCurricularCustomContext = GeneralContext;
