import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { RepositorioTipoItem } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'repositorio_tipo_item';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idRepositorioTipoItem',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idRepositorioTipoItem',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.RepositorioTipoItem
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.RepositorioTipoItem,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.RepositorioTipoItem.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<RepositorioTipoItem>(DAO_NAME, optionsToUse);

// hooks
export const useRepositorioTipoItemFetchById = fetchById;
export const useRepositorioTipoItemLibbyCall = libbyCall;
export const useRepositorioTipoItemDAO = libbyDAO;
export const useRepositorioTipoItemLibbyFetch = libbyFetch;

// components/hoc
export const RepositorioTipoItemAutocomplete = entityAutocomplete;
export const RepositorioTipoItemCall = renderPropCall;
export const RepositorioTipoItemById = renderPropFetchById;
export const RepositorioTipoItemSelect = simpleSelect;
export const RepositorioTipoItemAccordionSelector = accordionSelector;
export const RepositorioTipoItemSelectedChips = selectedChips;

// context
// ByIdContext
export const useRepositorioTipoItemByIdContext = useByIdContext;
export const RepositorioTipoItemByIdProvider = ByIdProvider;
export const RepositorioTipoItemByIdContext = ByIdContext;
// GeneralContext
export const useRepositorioTipoItemContext = useGeneralContext;
export const RepositorioTipoItemProvider = GeneralProvider;
export const RepositorioTipoItemContext = GeneralContext;
