import { useEffect, useState } from 'react';
import { LibbyFetchDAO } from 'src/platform/libby/dao';

/**
 * Hook que hace un request de un registro con id
 * espera y lo almacena en un state.
 */
export const useMountFetchRecords = <T, DAO extends LibbyFetchDAO>(
  dao: DAO,
  method: string,
  enableRequest = true,
  setEnableRequest: React.Dispatch<React.SetStateAction<boolean>>,
  ...args: Array<string | number>
) => {
  if (typeof dao[method] !== 'function')
    throw new Error(`El DAO ${dao.name} no tiene definido el método ${method}`);

  const [data, setData] = useState<T[]>([]);
  const [loading, setLoading] = useState<boolean | null>(null);

  useEffect(() => {
    let mounted = true;
    const fetch = async () => {
      setLoading(true);
      const results = await dao[method](...args);
      setData(results);
      setLoading(false);
    };

    if (enableRequest) {
      fetch();
      setEnableRequest(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enableRequest]);

  return {
    data,
    loading,
  };
};
