import React, { createContext, useContext } from 'react';
import { InformePipContextType } from './types';

export const InformePPSContext = createContext<InformePipContextType>({
  informePps: { row: {}, button: '' },
  setInformePps: () => {},
  isDirector: false,
  data: [],
  isSaving: false,
});

export const useInformePPSContext = () => useContext(InformePPSContext);

export const InformePpsContextProvider = (
  props: InformePipContextType & { children: React.ReactNode },
) => {
  return (
    <InformePPSContext.Provider value={props}>
      {props.children}
    </InformePPSContext.Provider>
  );
};
