import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { Filter, JoinedFilters, RecursosList, Sorter } from './components';
import { useRepositorioItemLibbyFetch } from '../../../../app/business';
import { Loading } from '../../../../commons/components/Loading';

const searchFilters = [
  'nombre',
  'descripcion',
  'espacioCurricular.descripcion',
  'repositorioTipoItem.descripcion',
  'repositorioAudienciaItem.descripcion',
];

const isBottom = (threehold = 0) => {
  const windowHeight =
    'innerHeight' in window
      ? window.innerHeight
      : document.documentElement.offsetHeight;
  const { body } = document;
  const html = document.documentElement;
  const docHeight = Math.max(
    body.scrollHeight,
    body.offsetHeight,
    html.clientHeight,
    html.scrollHeight,
    html.offsetHeight,
  );
  const windowBottom = windowHeight + window.pageYOffset + threehold;
  return windowBottom >= docHeight;
};

export interface RepositorioListProps {
  idNivel?: number | string;
}

export const RepositorioList = ({ idNivel }: RepositorioListProps) => {
  const [filter, setFilter] = useState<JoinedFilters>({
    tipos: [],
    ciclos: [],
    audiencia: [],
    search: '',
    anios: [],
    espacioCurricular: [],
    transversales: [],
    areas: [],
  });
  const [orderBy, setOrderBy] = useState<string>('');
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const onSortChange = useCallback(
    (sort: string, _direction: 'asc' | 'desc') => {
      setOrderBy(sort);
      setDirection(_direction);
    },
    [],
  );
  const processedFilter = useMemo(
    () => ({
      nivel: idNivel === undefined ? [] : [{ path: 'nivel', value: idNivel }],
      tipo: filter.tipos.map((tipo) => ({
        path: 'repositorioTipoItem',
        value: tipo.idRepositorioTipoItem,
      })),
      ciclo: filter.ciclos.map((ciclo) => ({
        path: 'ciclo.idCiclo',
        value: ciclo.idCiclo,
      })),
      anio: filter.anios.map((anio) => ({
        path: 'anio.idAnio',
        value: anio.idAnio,
      })),
      audiencia: filter.audiencia.map((audiencia) => ({
        path: 'repositorioAudienciaItem',
        value: audiencia.idRepositorioAudienciaItem,
      })),
      espacioCurricular: filter.espacioCurricular.map((espacioCurricular) => ({
        path: 'espacioCurricular.idEspacioCurricular',
        value: espacioCurricular.idEspacioCurricular,
      })),
      transversal: filter.transversales.map((transversal) => ({
        path: 'transversal',
        value: transversal.idTransversal,
      })),
      area: filter.areas.map((area) => ({
        path: 'area.idArea',
        value: area.idArea,
      })),
      search: !filter.search
        ? []
        : searchFilters.map((path) => ({
            path,
            value: filter.search,
            method: 'includes',
          })),
    }),
    [filter, idNivel],
  );

  const { data, working, fetchMore } = useRepositorioItemLibbyFetch({
    direction,
    orderBy,
    filter: processedFilter,
  });

  const handleScroll = useCallback(() => {
    const bottom = isBottom(300);
    if (bottom && fetchMore) {
      fetchMore();
    }
  }, [fetchMore]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  return (
    <Grid container spacing={2}>
      <Grid item sm={3}>
        <Filter value={filter} onChange={setFilter} idNivel={idNivel} />
      </Grid>
      <Grid item sm={9}>
        <Grid container direction="column" spacing={2}>
          <Grid item>
            <Sorter value={orderBy} onChange={onSortChange} />
          </Grid>
          <Grid item>
            {!!data && (
              <>
                {!!data.length && !working ? (
                  <RecursosList items={data} />
                ) : (
                  <Typography variant="h1">
                    No hay recursos que mostrar. Intenta cambiar los filtros o
                    busca en otro nivel.
                  </Typography>
                )}
              </>
            )}
            {working && <Loading />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
