import { LibbyFetchDAO } from './LibbyFetchDAO';

export class GruposInscripcionesDAO extends LibbyFetchDAO {
  constructor() {
    super('grupos_inscripciones', 'idGrupoInscripcion');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByOfertaGrupo(id: number) {
    return this.query().equals('ofertaGrupo.idOfertaGrupo', id).run();
  }
}
