import React, { useMemo, useState, useEffect } from 'react';
import { useEspaciocurricularseccioncustomGetRequest } from 'src/app/business/businessCustomEndpoints/espaciocurricularseccioncustom';
import { usePresenteV3LibbyFetch } from 'src/app/business/presentismo_v3/Presente';
import { useContraturnoLibbyFetch } from 'src/app/business/presentismo_v3/Contraturno';
import {
  useContraturnoDiaDAO,
  useContraturnoDiaLibbyFetch,
} from 'src/app/business/presentismo_v3/ContraturnoDia';
import { useRolesContext } from 'src/context/RolesContext';
import { AccionContraturnos } from './components';
import { TableContraturnos } from './components';
import { useContraturnoDAO } from '../../../../app/business/presentismo_v3/Contraturno';
import {
  ActionContraturno,
  contraturnoDias,
  contraturnoPorDias,
  ContraturnosRow,
  Filter,
  ObjContraturno,
} from './Types';
import { EspacioCurricularSeccion } from 'src/app/models';
import { usePresentesStudentCustomGet } from 'src/app/business/presentismo_v3/presentesStudentCustom';
import { useColegioEfectivizadoContext } from '../../Calificaciones/context/EfectivizacionProvider';
import moment from 'moment';

export const Contraturnos = () => {
  const roleContext = useRolesContext();

  const action: ActionContraturno = {
    openModalAction: false,
    disableSelectAction: false,
    title: '',
    presentismo: false,
    nuevoContraturno: false,
  };

  const filters: Filter = {
    idcontraturno: null,
    seccion: '',
    anio: 0,
    filterMateria: '',
    idSeccion: null,
    fechaInicio: '',
    fechaFin: '',
    activo: false,
    contraturnoDia: null,
    editContraurno: false,
    objContraturnoDia: {
      Lunes: null,
      Martes: null,
      Miércoles: null,
      Jueves: null,
      Viernes: null,
    },
  };

  const [filterContraturno, setFilterContraturno] = useState(filters);
  const [actionContracturno, setAccionContraturno] = useState(action);
  const { localizacionId, nivel } = roleContext.selectedRole;
  const { id } = roleContext.userInfo;
  const { cicloLectivo } = useColegioEfectivizadoContext();
  const contraturnoDAO = useContraturnoDAO();
  const contraturnoDiaDAO = useContraturnoDiaDAO();

  const fechInit = moment(cicloLectivo?.fechaInicio, 'YYYY-MM-DD').format(
    'YYYY-MM-DD',
  );
  const fechaFin = moment(cicloLectivo?.fechaFin, 'YYYY-MM-DD').format(
    'YYYY-MM-DD',
  );

  const contraturnofiltros = useMemo(() => {
    return {
      seccion: [{ path: 'seccionCustom.localizacion', value: localizacionId }],
      activo: [{ path: 'active', value: true }],
    };
  }, [localizacionId]);

  const {
    data: contraturno = [],
    working: contraturnoWorking,
    reFetch: reFetchContraturno,
  } = useContraturnoLibbyFetch({
    filter: contraturnofiltros,
    limit: 1000,
  });

  const filterContraturnoDia = useMemo(() => {
    return {
      seccion: [{ path: 'active', value: true }],
      contraturno: [{ path: 'contraturno.active', value: true }],
      contraturnoid: [
        {
          path: 'contraturno.idContraturno',
          value: contraturno.map((e) => e.idContraturno),
          method: 'in',
        },
      ],
      Locaizacion: [
        {
          path: 'contraturno.seccionCustom.localizacion',
          value: localizacionId,
        },
      ],
    };
  }, [contraturno, localizacionId]);

  const {
    data: contraturnoDiaData = [],
    working: contraturnoDiaWorking,
    reFetch: reFetchContraturnoDia,
  } = useContraturnoDiaLibbyFetch({
    filter: filterContraturnoDia,
    aspect: 'ContraturnoDiaAsp',
    limit: 1000,
    enabled: contraturnoWorking && contraturno.length > 0,
  });

  const {
    data: ecs = [],
    request,
    working,
  } = useEspaciocurricularseccioncustomGetRequest<EspacioCurricularSeccion[]>({
    url: `/api/public/custom/espaciocurricularseccioncustom?localizacion=${localizacionId}&cicloLectivo=${cicloLectivo?.idCicloLectivo}&nivel=${nivel}`,
    aspect: 'Contraturnos',
  });

  let buscarPresentismos = '';
  if (contraturnoDiaData.length > 0) {
    for (let i = 0; i < contraturnoDiaData.length; i++) {
      const objeto = contraturnoDiaData[i];
      const idContraturnoDia = objeto.idContraturnoDia.toString();
      const idSeccion = objeto.contraturno.seccionCustom!.idSeccion.toString();

      buscarPresentismos += `(${idContraturnoDia},${idSeccion})`;

      if (i !== contraturnoDiaData.length - 1) {
        buscarPresentismos += ',';
      }
    }
  }

  const { data: presentismoCustom = [], working: presentesCustomWorking } =
    usePresentesStudentCustomGet({
      url: `/api/presentismov3/presentes_custom?datos=${buscarPresentismos}`,
      // aspect: 'Falta crear',
      autoCall:
        contraturnoDiaWorking &&
        contraturnoDiaData.length > 0 &&
        buscarPresentismos !== '',
    });

  useEffect(() => {
    request();
  }, [contraturnoDiaData.length, request]);

  const espaciosCurriculares = ecs
    .filter(
      (e) =>
        e.seccion.jornada === 'Extendida' || e.seccion.jornada === 'Simple',
    )
    .map((e) => e.espacioCurricular);

  const secciones = ecs
    .filter(
      (e) =>
        e.seccion.jornada === 'Extendida' || e.seccion.jornada === 'Simple',
    )
    .map((item) => {
      return {
        ...item.seccion,
      };
    });

  // quita duplicados
  const seccionesUnicas = secciones.reduce((acc, current) => {
    const x = acc.find((item) => item.idSeccion === current.idSeccion);
    if (!x) {
      return acc.concat([current]);
    } else {
      return acc;
    }
  }, []);

  const resultado = contraturno?.map((item: any) => {
    item.contraturnoDias = contraturnoDiaData?.filter(
      (cd: any) => cd.contraturno.idContraturno === item.idContraturno,
    );
    return item;
  });

  const getContraturnoDia = (dia: string): ObjContraturno => ({
    esc1: null,
    esc2: null,
    cantidad: 0,
    edit: false,
    checked: false,
  });

  const contraturnosRow = resultado?.map((contraturno): ContraturnosRow => {
    const contraturnoDias: contraturnoDias = {
      Lunes: getContraturnoDia('Lunes'),
      Martes: getContraturnoDia('Martes'),
      Miercoles: getContraturnoDia('Miercoles'),
      Jueves: getContraturnoDia('Jueves'),
      Viernes: getContraturnoDia('Viernes'),
    };
    contraturno.contraturnoDias.forEach(
      (diaContraturno: contraturnoPorDias) => {
        if (!Boolean(contraturnoDias[diaContraturno.dia.nombre].esc1)) {
          contraturnoDias[diaContraturno.dia.nombre].esc1 =
            diaContraturno.EspacioCurricular;

          contraturnoDias[diaContraturno.dia.nombre].cantidad = 1;
          contraturnoDias[diaContraturno.dia.nombre].checked = true;
        } else {
          contraturnoDias[diaContraturno.dia.nombre].esc2 =
            diaContraturno.EspacioCurricular;
          contraturnoDias[diaContraturno.dia.nombre].cantidad = 2;
        }
      },
    );

    return {
      idcontraturno: contraturno.idContraturno,
      idSeccion: contraturno.seccionCustom,
      seccion: contraturno.seccionCustom.nombreSeccion,
      anio: contraturno.seccionCustom.anio,
      fechaInicio: contraturno.fechaInicio,
      fechaFin: contraturno.fechaFin,
      activo: contraturno.active,
      editContraurno: false,
      createdAt: contraturno.createdAt,
      contraturnoDias: contraturno.contraturnoDias,
      objContraturnoDia: contraturnoDias,
    };
  });

  return (
    <>
      {actionContracturno.openModalAction ? (
        <AccionContraturnos
          actionContracturno={actionContracturno}
          setAccionContraturno={setAccionContraturno}
          filterContraturno={filterContraturno}
          setFilterContraturno={setFilterContraturno}
          contraturnosRow={contraturnosRow}
          espacioCurriculares={espaciosCurriculares}
          contraturnoDAO={contraturnoDAO}
          contraturnoDiaDAO={contraturnoDiaDAO}
          reFetchContraturno={reFetchContraturno}
          reFetchContraturnoDia={reFetchContraturnoDia}
          userIdActual={id}
          seccionesECS={seccionesUnicas}
          fechInit={fechInit}
        />
      ) : (
        <TableContraturnos
          contraturnosRow={contraturnosRow}
          setAccionContraturno={setAccionContraturno}
          filterContraturno={filterContraturno}
          setFilterContraturno={setFilterContraturno}
          reFetchContraturno={reFetchContraturno}
          contraturnoWorking={contraturnoWorking}
          espacioCurricularesWorking={working}
          presentes={presentismoCustom}
          presentesWorking={presentesCustomWorking}
          fechInit={fechInit}
          fechaFin={fechaFin}
        />
      )}
    </>
  );
};
