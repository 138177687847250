import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  title: {
    color: '#38485C',
    fontFamily: 'Nunito',
    fontSize: 24,
    fontWeight: 'bold',
  },
  subtitle: {
    color: '#38485C',
    fontFamily: 'Nunito',
    fontSize: 18,
    fontWeight: 'bold',
  },
  filterButton: {
    backgroundColor: 'white',
    textTransform: 'none',
    color: '#3786EE',
    fontWeight: 'bold',
    fontFamily: 'Nunito',
    fontSize: 18,
    display: 'flex',
    alignItems: 'center',
  },
  iconButton: {
    width: 33,
    height: 33,
    backgroundColor: 'transparent',
    color: '#3786EE',
  },
  icon: {
    width: 22,
    height: 22,
  },
  textRows: {
    display: 'flex',
    justifyContent: 'center',
  },
});
