import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { PlanificacionEstados } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'planificacion_estados';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPlanificacionEstados',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPlanificacionEstados',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.PlanificacionEstados
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.PlanificacionEstados,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.PlanificacionEstados.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<PlanificacionEstados>(DAO_NAME, optionsToUse);

// hooks
export const usePlanificacionEstadosFetchById = fetchById;
export const usePlanificacionEstadosLibbyCall = libbyCall;
export const usePlanificacionEstadosDAO = libbyDAO;
export const usePlanificacionEstadosLibbyFetch = libbyFetch;

// components/hoc
export const PlanificacionEstadosAutocomplete = entityAutocomplete;
export const PlanificacionEstadosCall = renderPropCall;
export const PlanificacionEstadosById = renderPropFetchById;
export const PlanificacionEstadosSelect = simpleSelect;
export const PlanificacionEstadosAccordionSelector = accordionSelector;
export const PlanificacionEstadosSelectedChips = selectedChips;

// context
// ByIdContext
export const usePlanificacionEstadosByIdContext = useByIdContext;
export const PlanificacionEstadosByIdProvider = ByIdProvider;
export const PlanificacionEstadosByIdContext = ByIdContext;
// GeneralContext
export const usePlanificacionEstadosContext = useGeneralContext;
export const PlanificacionEstadosProvider = GeneralProvider;
export const PlanificacionEstadosContext = GeneralContext;
