import { SectionDAO } from 'src/platform/libby/dao';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { Seccion } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'seccion';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idSeccion',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idSeccion',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Seccion
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Seccion,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Seccion.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
    selectInput,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Seccion, SectionDAO>(DAO_NAME, optionsToUse);

// hooks
export const useSeccionFetchById = fetchById;
export const useSeccionLibbyCall = libbyCall;
export const useSeccionDAO = libbyDAO;
export const useSeccionLibbyFetch = libbyFetch;

// components/hoc
export const SeccionAutocomplete = entityAutocomplete;
export const SeccionCall = renderPropCall;
export const SeccionById = renderPropFetchById;
export const SeccionSelect = simpleSelect;
export const SeccionSelectInput = selectInput;
export const SeccionAccordionSelector = accordionSelector;
export const SeccionSelectedChips = selectedChips;

// context
// ByIdContext
export const useSeccionByIdContext = useByIdContext;
export const SeccionByIdProvider = ByIdProvider;
export const SeccionByIdContext = ByIdContext;
// GeneralContext
export const useSeccionContext = useGeneralContext;
export const SeccionProvider = GeneralProvider;
export const SeccionContext = GeneralContext;
