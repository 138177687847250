import { addDays } from 'date-fns';
import { FieldValidator } from 'final-form';
import moment from 'moment';

type ValidateHasta = (justificacionRange: number) => FieldValidator<string>;

export const validateHasta: ValidateHasta =
  (justificacionRange: number) => (value, allValues: any, meta) => {
    if (value === null || value === '') {
      return 'Este campo es requerido';
    }
    if (value !== null && moment(allValues.fechaDesde).diff(value, 'd') > 0) {
      return 'La fecha de inicio debe ser menor a la finalizacion';
    }

    if (
      !meetsCriteria(
        {
          fromDate: moment(allValues.fechaDesde).format('YYYY-MM-DD'),
          toDate: moment(value).format('YYYY-MM-DD'),
        },
        justificacionRange,
      )
    ) {
      return `El motivo seleccionado sólo permite justificar ${justificacionRange} días`;
    }
  };

const meetsCriteria = (
  dateRange: { fromDate: string; toDate: string },
  maxDaysDifference: number,
): boolean => {
  if (maxDaysDifference <= 0) return true;
  const ONE_DAY_MS = 86400000; // 1 day in milliseconds

  const fromDate = new Date(dateRange.fromDate);
  const toDate = new Date(dateRange.toDate);

  const diffTime = toDate.getTime() - fromDate.getTime();
  const diffDays = Math.floor(diffTime / ONE_DAY_MS) + 1;

  return diffDays <= maxDaysDifference;
};
