import { Template, TemplateProperty } from '../../../../lib/templates';

const ValorTemplate: TemplateProperty[] = [
  {
    name: 'idTipo',
    type: { name: 'number' },
  },
  {
    name: 'descripcion',
    type: { name: 'string' },
  },
  {
    name: 'evaluacion',
    type: { name: 'number' },
  },
  {
    name: 'idDevolucion',
    type: { name: 'string' },
    optional: true,
  },
  {
    name: 'idDevolucionValor',
    type: { name: 'number' },
    optional: true,
  },
  {
    name: 'tab',
    type: { name: 'string' },
  },
];

export const template: Template = {
  name: 'Evaluacion el estudiante',
  template: [
    {
      name: 'grupoInscripcion',
      type: {
        name: 'any',
      },
    },
    {
      name: 'espacioCurricular',
      type: {
        name: 'any',
      },
    },
    {
      name: 'perfiles',
      type: {
        name: 'object',
        kind: [
          {
            name: 'idAutoevaluacionPerfil',
            type: { name: 'number' },
            optional: true,
          },
          {
            name: 'observaciones',
            type: { name: 'string' },
            optional: true,
          },
          {
            name: 'observacionesReferente',
            type: { name: 'string' },
            optional: true,
          },
          {
            name: 'createdAt',
            type: { name: 'string' },
            optional: true,
          },
          {
            name: 'createdBy',
            type: { name: 'any' },
            optional: true,
          },
          {
            name: 'updatedAt',
            type: { name: 'string' },
            optional: true,
          },
          { name: 'fechaAcap', type: { name: 'any' }, optional: true },
          {
            name: 'valores',
            type: {
              name: 'array',
              kind: ValorTemplate,
            },
          },
        ],
      },
    },
    {
      name: 'habilidades',
      type: {
        name: 'object',
        kind: [
          {
            name: 'idAutoevaluacionHabilidad',
            type: { name: 'number' },
            optional: true,
          },
          {
            name: 'observaciones',
            type: { name: 'string' },
            optional: true,
          },
          {
            name: 'observacionesReferente',
            type: { name: 'string' },
            optional: true,
          },
          {
            name: 'createdAt',
            type: { name: 'string' },
            optional: true,
          },
          {
            name: 'createdBy',
            type: { name: 'any' },
            optional: true,
          },
          {
            name: 'updatedAt',
            type: { name: 'string' },
            optional: true,
          },
          { name: 'fechaAcap', type: { name: 'any' }, optional: true },
          {
            name: 'valores',
            type: {
              name: 'array',
              kind: ValorTemplate,
            },
          },
        ],
      },
    },
    {
      name: 'tab',
      type: { name: 'string' },
      optional: true,
    },
    {
      name: 'contenidos',
      type: {
        name: 'object',
        kind: [
          {
            name: 'idAutoevaluacionContenido',
            type: { name: 'number' },
            optional: true,
          },
          {
            name: 'observaciones',
            type: { name: 'string' },
            optional: true,
          },
          {
            name: 'observacionesReferente',
            type: { name: 'string' },
            optional: true,
          },
          {
            name: 'createdAt',
            type: { name: 'string' },
            optional: true,
          },
          {
            name: 'createdBy',
            type: { name: 'any' },
            optional: true,
          },
          {
            name: 'updatedAt',
            type: { name: 'string' },
            optional: true,
          },
          {
            name: 'updatedBy',
            type: { name: 'any' },
            optional: true,
          },
          { name: 'fechaAcap', type: { name: 'any' }, optional: true },
          {
            name: 'valores',
            type: {
              name: 'array',
              kind: ValorTemplate,
            },
          },
        ],
      },
    },
  ],
};
