import {
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { ChangeEvent, useState } from 'react';
import { AlumnoMovimiento } from 'src/app/models';
import customFormDialog from 'src/commons/services/customFormDialog';

const useStyles = makeStyles({
  button: {
    textDecoration: 'none',
  },
  buttonCancel: {
    textDecoration: 'none',
    paddingRight: '15px',
    '&:hover': {
      background: 'transparent',
    },
  },
  title: {
    fontSize: 16,
    color: '#737373',
    fontWeight: 600,
    marginBottom: '20px',
  },
  textMultiline: {
    '& > .MuiInputBase-root > .MuiInput-inputMultiline': {
      padding: '4px 7px !important',
    },
  },
});

interface ConditionRegisteredModalTypes {
  data: AlumnoMovimiento;
}

export const ConditionRegisteredModal = ({
  data,
}: ConditionRegisteredModalTypes) => {
  const [formValues, setFormValues] = useState({
    motivo: '',
    observacion: '',
  });

  const classes = useStyles();

  const [reasonError, setReasonError] = useState(false);

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
    if (name === 'motivo') {
      setReasonError(value.trim() === '');
    }
  };

  return (
    <Container maxWidth="lg">
      <Grid item xs={12} style={{ marginBottom: '20px' }}>
        <Typography className={classes.title}>
          ¿Desea cambiar la condición de {data.alumno.persona.apellido},{' '}
          {data.alumno.persona.nombre}?
        </Typography>
        <Typography>
          Se actualizará la condición una vez completados los siguientes campos:
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        style={{ paddingBottom: '20px', paddingRight: '30px' }}
      >
        <Grid container>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              paddingBottom: '20px',
            }}
          >
            <Typography style={{ paddingRight: '10px' }}>
              <span style={{ color: 'red' }}>*</span>
              Motivo:
            </Typography>
            <TextField
              name="motivo"
              value={formValues.motivo}
              onChange={handleInputChange}
              fullWidth
              required
              error={reasonError}
              helperText={reasonError ? 'Debe ingresar un motivo.' : ''}
            />
          </Grid>
          <Grid
            item
            xs={12}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <Typography style={{ paddingRight: '10px', paddingLeft: '7px' }}>
              Detalle:
            </Typography>
            <TextField
              name="observacion"
              value={formValues.observacion}
              onChange={handleInputChange}
              fullWidth
              className={classes.textMultiline}
              multiline
              minRows={6}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        xs={12}
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          paddingBottom: '15px',
        }}
      >
        <Button
          className={classes.buttonCancel}
          variant="text"
          color="primary"
          onClick={() => customFormDialog.handleCancel()}
        >
          Cancelar
        </Button>
        <Button
          className={classes.button}
          variant="contained"
          color="primary"
          onClick={() => customFormDialog.handleConfirm(formValues)}
          disabled={formValues.motivo === ''}
        >
          Aceptar
        </Button>
      </Grid>
    </Container>
  );
};
