import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { Box, Grid, makeStyles } from '@material-ui/core';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  FormEvent,
} from 'react';
import { AnyObject, LibbyObject } from 'src/commons';
import {
  Footer,
  InfoTable,
  OptionsModal,
  OptionsReportsModal,
  useTabBarValue,
} from 'src/commons/components';
import { cycle, level } from 'src/commons/const';
import { useLibbyFetch } from 'src/commons/hooks';
import { useDebounce } from 'src/commons/hooks/useDebounce';
import confirmDialog from 'src/commons/services/confirmDialog';
import customFormDialog from 'src/commons/services/customFormDialog';
import { useRolesContext } from 'src/context/RolesContext';
import { useLegajoLogic } from 'src/screens/Private/LegajoAlumno';
import { useSnackbar } from 'notistack';
import {
  useAlumnoMovimientoCustomLibbyFetch,
  useAlumnoMovimientoCustomDAO,
  useTipoInformesLibbyFetch,
  useInformesHistoryLibbyFetch,
} from 'src/app/business';
import { primary } from 'src/theme/colors';
import { RegisteredAlumnModal } from '../components';
import { FilterTablesRegistered } from './Components/FilterTablesRegistered';
import { SeccionSelect } from './Components/SeccionSelect';
import { useRegisteredEntitiesCall } from './hooks';
import {
  Alumno,
  AlumnoMovimiento,
  Anio,
  Ciclo,
  InformesHistory,
  Nivel,
  Turno,
} from 'src/app/models';

interface buttonConfigProps {
  title: string;
  handleOnClick: () => void;
  size: string;
  disable: boolean;
}

const useStyles = makeStyles({
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
  infoTableCustom: {
    marginBottom: '80px',
  },
  alumnoButton: {
    color: primary.lightBlue,
    margin: '0',
    background: 'none',
    textAlign: 'left',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

const ScaleBCRegisteredRaw = ({ libby }: LibbyObject) => {
  const roleContext = useRolesContext();
  const { enqueueSnackbar } = useSnackbar();
  const { params } = useTabBarValue();
  const classes = useStyles();
  const { userInfo } = roleContext;
  const { localizacionId } = roleContext.selectedRole;
  const filterLocalization = useMemo(
    () => [{ path: 'seccion.localizacion', value: localizacionId }],
    [localizacionId],
  );
  const [alumnRows, setAlumnRows] = useState<any>([]);
  const [alumnsWithSections, setAlumnsWithSections] = useState<any>([]);
  const [direction, setDirection] = React.useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('alumno.persona.apellido');
  const [formValues, setFormValues] = useState({
    [`seccion.nivel`]: '',
    [`seccion.ciclo`]: '',
    [`seccion.jornada`]: '',
    [`seccion.nombreSeccion`]: params.nombreSeccion || '',
  });
  const [isSavingMatriculacion, setIsSavingMatriculacion] = useState(false);

  const [search, setSearch] = useState('');
  const searchDebounced = useDebounce(search, 1000);
  const filterSearch = useMemo(
    () =>
      !searchDebounced
        ? []
        : [
            {
              path: 'alumno.persona.apellido',
              value: searchDebounced,
              method: 'includes',
            },
            {
              path: 'alumno.persona.nombre',
              value: searchDebounced,
              method: 'includes',
            },
            {
              path: 'alumno.persona.documento',
              value: searchDebounced,
              method: 'includes',
            },
            {
              path: 'seccion.division',
              value: searchDebounced,
              method: 'includes',
            },
            {
              path: 'seccion.jornada',
              value: searchDebounced,
              method: 'includes',
            },
          ],
    [searchDebounced],
  );

  const filterTables = useCallback(() => {
    if (
      formValues[`seccion.nivel`] ||
      formValues[`seccion.ciclo`] ||
      formValues[`seccion.jornada`] ||
      formValues[`seccion.nombreSeccion`]
    ) {
      const toReformFormValues = Object.entries(formValues).map(
        (filter: AnyObject) =>
          filter[1] && [{ path: filter[0], value: filter[1] }],
      );
      return { filterLocalization, filterSearch, ...toReformFormValues };
    }
    return {
      0: filterLocalization,
      1: filterSearch,
    };
  }, [filterLocalization, filterSearch, formValues]);

  const paramsFetchAlumno = useMemo(
    () => ({
      orderBy,
      direction,
      filter: filterTables(),
    }),
    [direction, filterTables, orderBy],
  );
  const paramsFetchSeccion = useMemo(
    () => ({
      daoName: 'seccion',
      orderBy: 'nivel',
      filter: {
        0: [{ path: 'localizacion.idLocalizacion', value: localizacionId }],
      },
    }),
    [localizacionId],
  );

  const [registersCount, setRegistersCount] = useState(0);
  const alumnoMovimientoDAO = useAlumnoMovimientoCustomDAO();

  useEffect(() => {
    alumnoMovimientoDAO
      .getTotalAmount(filterTables())
      .then((response: number) => setRegistersCount(response));
  }, [alumnoMovimientoDAO, filterTables]);

  const {
    data: alumnoMovimiento = [],
    working,
    reFetch,
  } = useAlumnoMovimientoCustomLibbyFetch(paramsFetchAlumno);

  const [informeFilter, setInformeFilter] = useState<Object>({});
  const { data: informes } = useInformesHistoryLibbyFetch({
    filter: informeFilter,
  });
  const { data: seccion } = useLibbyFetch(libby, paramsFetchSeccion);
  const { data: nivel } = useLibbyFetch(libby, { daoName: 'nivel' });
  const { data: ciclo } = useLibbyFetch(libby, { daoName: 'ciclo' });
  const { data: tipoInformes } = useTipoInformesLibbyFetch();

  const filterLevel = nivel.filter(
    (item: AnyObject) =>
      item.idNivel === level.INICIAL ||
      item.idNivel === level.PRIMARIO ||
      item.idNivel === level.SECUNDARIO,
  );

  const filterCycle = ciclo.filter(
    (item: AnyObject) =>
      item.idCiclo === cycle.CICLO_1 || item.idCiclo === cycle.CICLO_2,
  );

  const handleChangeSection = useCallback(
    (e: React.FormEvent<EventTarget>, alumno: AnyObject) => {
      const target = e.target as HTMLInputElement;
      const updatedAlumnRow = alumnRows.map((item: AnyObject) => {
        if (item.alumno.idAlumno === alumno.alumno.idAlumno) {
          return { ...item, idSeccion: target.value };
        }
        return item;
      });
      setAlumnRows(updatedAlumnRow);
      const updatedAlumn = { ...alumno, idSeccion: target.value };
      if (!alumnsWithSections.length) {
        setAlumnsWithSections([updatedAlumn]);
      } else {
        const newArray = alumnsWithSections.reduce(
          (acc: Alumno[], item: AnyObject) => {
            if (
              acc.findIndex(
                (i: AnyObject) => i.alumno.idAlumno === item.alumno.idAlumno,
              ) !== -1 ||
              // @ts-ignore
              updatedAlumn.alumno.idAlumno === item.alumno.idAlumno
            ) {
              return acc;
            }
            return [...acc, item];
          },
          [updatedAlumn],
        );
        setAlumnsWithSections(newArray);
        return newArray;
      }
    },
    [alumnsWithSections, alumnRows],
  );

  const removeAlumn = useCallback(
    async (
      nombre: string,
      idAlumnoMovimiento: number,
      idAlumno: number,
      alumnoMovimiento: AlumnoMovimiento,
    ) => {
      try {
        const confirm = await confirmDialog.show({
          title: 'Dar de baja',
          content: `¿Estás seguro que desea desmatricular a ${nombre}?`,
          confirmText: 'Confirmar',
          cancelText: 'Cancelar',
        });
        if (confirm) {
          customFormDialog.show({
            title: 'Motivo de baja',
            renderComponent: (
              <RegisteredAlumnModal
                alumn={idAlumno}
                alumnMovement={idAlumnoMovimiento}
                reFetch={reFetch}
                alumnoMovimiento={alumnoMovimiento}
                localizacionId={localizacionId}
                setLoading={setIsSavingMatriculacion}
              />
            ),
            sizeWidth: 'md',
          });
        }
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Ha ocurrido un error al intentar guardar', {
          variant: 'error',
        });
      }
    },
    [reFetch, enqueueSnackbar, localizacionId, setIsSavingMatriculacion],
  );

  const mapData = useRegisteredEntitiesCall();

  useEffect(() => {
    if (!mapData.working) {
      const informeFilterParams: Object = [];
      const alumns = alumnoMovimiento.map((item) => {
        const { idAlumnoMovimiento: _alumnoMovimiento } = item;
        const { alumno } = item;
        const { apellido, documento, nombre } = alumno?.persona;
        const {
          division,
          anio: idAnio,
          ciclo: idCiclo,
          turno: idTurno,
          nivel: idNivel,
          jornada,
        } = item.seccion;
        const _ciclo = mapData.ciclo.find(
          (_item: Ciclo) => +_item.idCiclo === +idCiclo,
        ) || { idCiclo, descripcionCiclo: '-' };
        const _nivel = mapData.nivel.find(
          (_item: Nivel) => +_item.idNivel === +idNivel,
        ) || { idNivel, descripcionNivel: '-' };
        const turno = mapData.turno.find(
          (_item: Turno) => +_item.idTurno === +idTurno,
        ) || { idTurno, descripcionTurno: '-' };
        const anio = mapData.anio.find(
          (_item: Anio) => +_item.idAnio === +idAnio,
        ) || { idAnio, descripcionAnio: '-', nivel: _nivel };
        informeFilterParams.push({ path: 'alumno', value: alumno.idAlumno });

        return {
          nombre: `${apellido}, ${nombre}`,
          documento,
          descripcionNivel: _nivel?.descripcionNivel,
          idCiclo: _ciclo?.idCiclo,
          descripcionCiclo: _ciclo?.descripcionCiclo,
          descripcionTurno: turno?.descripcionTurno,
          idNivel: _nivel?.idNivel,
          division,
          idSeccion: null,
          anio,
          alumno,
          _alumnoMovimiento,
          jornada,
        };
      });
      setInformeFilter({ 0: informeFilterParams });
      setAlumnRows(alumns);
    }
  }, [
    alumnoMovimiento,
    mapData.anio,
    mapData.ciclo,
    mapData.nivel,
    mapData.turno,
    mapData.working,
  ]);

  const { toLegajoAlumno } = useLegajoLogic();

  const rows = useMemo(
    () =>
      alumnRows.map((item: AnyObject) => {
        const getInformeByIdAlumno = (idAlumno: string) =>
          informes
            ? informes.filter(
                (informe: InformesHistory) =>
                  informe.alumno.idAlumno === idAlumno,
              )
            : [];
        return {
          ...item,
          idSeccion: (
            <SeccionSelect
              content={seccion.filter(
                (_item: AnyObject) =>
                  _item.ciclo?.idCiclo === item.idCiclo &&
                  _item.nivel?.idNivel === item.idNivel &&
                  _item.division !== item.division,
              )}
              value={item.idSeccion}
              handleChange={(e: React.FormEvent<EventTarget>) =>
                handleChangeSection(e, item)
              }
            />
          ),
          nombre: (
            <button
              type="button"
              className={classes.alumnoButton}
              onClick={() => toLegajoAlumno(item.alumno.idAlumno)}
            >
              {item?.nombre}
            </button>
          ),
          report: (
            <Box>
              <OptionsReportsModal
                reportTypes={tipoInformes}
                reports={getInformeByIdAlumno(item.alumno.idAlumno)}
                userInfo={userInfo}
              />
            </Box>
          ),
          icon: (
            <OptionsModal
              options={[
                {
                  label: 'Desmatricular',
                  onClick: () =>
                    removeAlumn(
                      item.nombre,
                      item._alumnoMovimiento,
                      item.alumno.idAlumno,
                      item,
                    ),
                },
                {
                  label: 'Ver legajo',
                  onClick: () => toLegajoAlumno(item.alumno.idAlumno),
                },
              ]}
            />
          ),
        };
      }),
    [
      alumnRows,
      classes.alumnoButton,
      handleChangeSection,
      removeAlumn,
      seccion,
      toLegajoAlumno,
      tipoInformes,
      userInfo,
      informes,
    ],
  );

  const columns = [
    {
      id: 'nombre',
      label: 'Apellido y nombre',
      width: '5%',
      orderById: 'alumno.persona.apellido',
    },
    {
      id: 'documento',
      label: 'Documento',
      width: '5%',
      orderById: 'alumno.persona.documento',
    },
    {
      id: 'descripcionNivel',
      label: 'Nivel',
      width: '5%',
      orderById: 'seccion.nivel',
    },
    {
      id: 'descripcionCiclo',
      label: 'Ciclo',
      width: '5%',
      orderById: 'seccion.ciclo',
    },
    {
      id: 'descripcionTurno',
      label: 'Turno',
      width: '5%',
      orderById: 'seccion.turno',
    },
    {
      id: 'jornada',
      label: 'Jornada',
      width: '5%',
      orderById: 'seccion.jornada',
    },
    {
      id: 'division',
      label: 'Grupo/grado',
      width: '5%',
      orderById: 'seccion.division',
    },
    {
      id: 'idSeccion',
      label: 'Cambio de grupo',
      width: '5%',
      hideSortIcon: true,
      noSort: true,
    },
    {
      id: 'report',
      label: 'Informes',
      width: '1%',
      hideSortIcon: true,
      noSort: true,
    },
    {
      id: 'icon',
      label: '',
      width: '1%',
      hideSortIcon: true,
      noSort: true,
    },
  ];

  const handleChange = (e: FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setDirection(newDirection);
    setOrderBy(newOrderBy);
  };

  const buttonConfig: buttonConfigProps[] = [
    {
      title: 'Matricular',
      handleOnClick: () => saveData(),
      size: 'large',
      disable: true,
    },
  ];

  const handleChangeSearch = (e: FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setSearch(target.value);
  };

  const saveData = async () => {
    for (let i = 0; i < alumnsWithSections.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      await alumnoMovimientoDAO.save({
        idAlumnoMovimiento: alumnsWithSections[i]._alumnoMovimiento,
        seccion: alumnsWithSections[i].seccion,
      });
    }
    reFetch();
  };

  return (
    <>
      <FilterTablesRegistered
        level={filterLevel}
        cycle={filterCycle}
        section={seccion}
        registersCount={registersCount}
        handleChange={handleChange}
        formValues={formValues}
        handleChangeSearch={handleChangeSearch}
        search={search}
        working={working || mapData.working}
      />
      <InfoTable
        columns={columns}
        rows={rows}
        working={working || mapData.working || isSavingMatriculacion}
        direction={direction}
        onSortChange={handleRequestSort}
        customStyle={classes.infoTableCustom}
      />
      <Grid className={classes.footer}>
        <Footer buttonConfig={buttonConfig} />
      </Grid>
    </>
  );
};

export const ScaleBCRegistered = DatabaseConnector(ScaleBCRegisteredRaw)(
  'alumno',
  'seccion',
  'nivel',
  'ciclo',
);
