import React from 'react';
import { Table } from './components/Table';
import {
  MultiPresentismoChecksProvider,
  PresentismoContextProvider,
} from './context';

export const TomaPresentismo = ({
  isTabJornadaExtendida,
  espacioCurricularSelected,
}: {
  isTabJornadaExtendida: boolean;
  espacioCurricularSelected?: string;
}) => {
  return (
    <MultiPresentismoChecksProvider>
      <PresentismoContextProvider>
        <Table
          isTabJornadaExtendida={isTabJornadaExtendida}
          espacioCurricularSelected={espacioCurricularSelected}
        />
      </PresentismoContextProvider>
    </MultiPresentismoChecksProvider>
  );
};
