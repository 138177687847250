import { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useSnackbar } from 'notistack';
import {
  ArealPlan,
  DiscipOrientPlan,
  InterdiscipPlan,
  SimpDiscipPlan,
} from '../../../../../../../../app/models';
import { FEEDBACK_PLAN_OPCION_APROBADO } from '../../../../../../../../commons/const/FeedbackPlanOpcion';
import { PLANIFICACION_ESTADOS } from '../../../../../../../../commons/const';
import { AnyObject } from '../../../../../../../../commons/types';
import { FeedbackTemplateCompleteValue } from '../components/PlanificacionFeedbackForm';

type Plan = SimpDiscipPlan | ArealPlan | DiscipOrientPlan | InterdiscipPlan;

export interface UseFeedbackOnSubmitOptions {
  plan: Plan;
  feedbackDAO: AnyObject;
  planDAO: AnyObject;
  aspectFeedback?: string;
  aspectPlanDAO?: string;
}
export const getToSave = (
  plan: Plan,
  values: FeedbackTemplateCompleteValue,
) => {
  if ((plan as SimpDiscipPlan).idSimpDiscipPlan) {
    return {
      simpDiscipPlan: plan,
      feedback: values,
    };
  }
  if ((plan as ArealPlan).idArealPlan) {
    return {
      arealPlan: plan,
      feedback: values,
    };
  }
  if ((plan as DiscipOrientPlan).idDiscipOrientPlan) {
    return {
      discipOrientPlan: plan,
      feedback: values,
    };
  }
  return {
    interdiscipPlan: plan,
    feedback: values,
  };
};

export const useFeedbackOnSubmit = ({
  plan,
  feedbackDAO,
  planDAO,
  aspectFeedback,
  aspectPlanDAO,
}: UseFeedbackOnSubmitOptions) => {
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  return useCallback(
    async (values: FeedbackTemplateCompleteValue) => {
      try {
        const toSave = getToSave(plan, values);
        await feedbackDAO.aspect(aspectFeedback).save(toSave);
        const isAllApproved: boolean =
          !!values.feedback.length &&
          values.feedback.every(
            (item) => item.opcion === FEEDBACK_PLAN_OPCION_APROBADO,
          );
        const planificacionEstado = isAllApproved
          ? PLANIFICACION_ESTADOS.APROBADO
          : PLANIFICACION_ESTADOS.REQUIERE_CAMBIOS;
        const toUpdate = { ...plan, planificacionEstados: planificacionEstado };
        await planDAO.aspect(aspectPlanDAO).save(toUpdate);
        history.goBack();
      } catch (e) {
        console.log(e);
        enqueueSnackbar('Ha ocurrido un error al intentar guardar', {
          variant: 'error',
        });
      }
    },
    [
      enqueueSnackbar,
      feedbackDAO,
      history,
      plan,
      planDAO,
      aspectPlanDAO,
      aspectFeedback,
    ],
  );
};
