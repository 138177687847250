import { presentismo } from '../../types';

export const configurationInitialReducer: presentismo.ConfReducerState = {
  propuestasRows: [],
  working: false,
  refetchPropuestas: false,
};

export const configurationReducer = (
  state: presentismo.ConfReducerState,
  action: presentismo.ConfigurationActions,
) => {
  switch (action.type) {
    default:
      return {
        ...state,
      };
  }
};
