import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';
import { Typography } from '@material-ui/core';
import { TablePagination } from '@material-ui/core';
import {
  useGruposInscripcionesOfertaLibbyFetch,
  useLocalizacionPlanEstudioNivelLibbyFetch,
} from 'src/app/business';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useGruposSeleccionesDAO } from 'src/app/business/acap/GruposSelecciones';
import { InfoTable } from 'src/commons';
import { AccionesSeleccionadasFiltersHeader } from '../components/accionesSeleccionadasFiltersHeader';
import { InscripcionAccionesSeleccionadas } from '../components/inscripcionAccionesSeleccionadas';
import { useAccionesSeleccionadasColumns } from '../hooks/useAccionesSeleccionadasColumns';
import { AccionSeleccionada } from '../types';
import { GruposSelecciones } from 'src/app/models';
import { useGruposSeleccionesLibbyFetch } from 'src/app/business/acap/GruposSelecciones';
import { getUniqueItems } from '../functions/helpers';

const access = (path: string, object: any) => {
  return path.split('.').reduce((o, i) => o[i], object);
};

interface AccionesSeleccionadasProps {
  accionSeleccionadaRow: AccionSeleccionada | null;
  setAccionSeleccionadaRow: React.Dispatch<
    React.SetStateAction<AccionSeleccionada | null>
  >;
}

export const AccionesSeleccionadas = ({
  setAccionSeleccionadaRow,
  accionSeleccionadaRow,
}: AccionesSeleccionadasProps) => {
  const [filters, setFilters] =
    useState<null | Acap.Referente.GetGruposSeleccionesFilters>(null);
  const [filtersRequest, setFiltersRequest] =
    useState<null | Acap.Referente.GetGruposSeleccionesFilters>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [direction, setDirection] = useState<'asc' | 'desc'>('desc');
  const [orderBy, SetOrderBy] = useState(
    'ofertaGrupo.ofertaTurno.oferta.accion.organizacion.nombre',
  );
  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);

  const { enqueueSnackbar } = useSnackbar();
  const gruposSeleccionesDAO = useGruposSeleccionesDAO();

  const {
    data: localizacionPlanEstudioNivel = [],
    working: planEstudioLoading,
  } = useLocalizacionPlanEstudioNivelLibbyFetch({
    limit: 500,
  });

  const uniquesOrientacionesEscuela = useMemo(() => {
    return getUniqueItems(
      localizacionPlanEstudioNivel
        .map((item) =>
          item.planEstudioNivel.planEstudio.orientaciones.map(
            (peOrientacion) => peOrientacion.orientacion,
          ),
        )
        .flat(),
    );
  }, [localizacionPlanEstudioNivel]);

  useEffect(() => {
    if (!accionSeleccionadaRow?.isEditing) {
      setAccionSeleccionadaRow(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = () => {
    setFiltersRequest({
      orientacion: filters!.orientacion,
      tipoAccion: filters!.tipoAccion,
      turno: filters!.turno,
    });
    const url = new URLSearchParams('/api/acap/grupos_selecciones');
    if (Boolean(filters?.orientacion)) {
      url.append('orientacion', filters!.orientacion);
    }

    if (Boolean(filters?.tipoAccion)) {
      url.append('tipoAccion', filters!.tipoAccion);
    }

    if (Boolean(filters?.turno)) {
      url.append('turno', filters!.turno);
    }
  };

  const filterSelecciones = useMemo(
    () => ({
      ...(filtersRequest?.orientacion && {
        orientacion: [
          {
            path: 'ofertaGrupo.ofertaTurno.oferta.accion.accionOrientaciones.orientacion.idOrientacion',
            value: filtersRequest.orientacion,
          },
        ],
      }),
      ...(filtersRequest?.tipoAccion && {
        tipoAccion: [
          {
            path: 'ofertaGrupo.ofertaTurno.oferta.accion.tipoAccion.idTipoAccion',
            value: filtersRequest.tipoAccion,
          },
        ],
      }),
      ...(filtersRequest?.turno && {
        turno: [
          {
            path: 'ofertaGrupo.ofertaTurno.turno.idTurno',
            value: filtersRequest.turno,
          },
        ],
      }),
    }),
    [filtersRequest],
  );

  const {
    data: gruposSelecciones = [],
    working: workingGruposSelecciones,
    reFetch: request,
  } = useGruposSeleccionesLibbyFetch({
    limit: 500,
    aspect: 'super-minimal',
    filter: filterSelecciones,
  });

  const removeGrupoSeleccion = async (idGrupoSeleccion: number | string) => {
    const confirm = await confirmDialog.show({
      title: 'Eliminación de oferta',
      confirmText: 'Eliminar',
      cancelText: 'Cancelar',
      content:
        'Eliminar la oferta tambien eliminara las inscripciones realizadas ¿Desea confirmar?',
    });
    if (confirm) {
      setIsDeleting(true);
      try {
        await gruposSeleccionesDAO.remove({ idGrupoSeleccion });
        request();
        enqueueSnackbar('Se ha borrado con éxito', {
          variant: 'success',
        });
        setIsDeleting(false);
      } catch (e: any) {
        console.error(e.message);
        setIsDeleting(false);
        enqueueSnackbar('Ha ocurrido un error al intentar borrar', {
          variant: 'error',
        });
      }
    }
  };

  const filterGruposInscripcionesOferta = useMemo(
    () => ({
      selecciones: [
        {
          path: 'ofertaGrupo',
          value: (gruposSelecciones as GruposSelecciones[]).map(
            (grupoSeleccion) => grupoSeleccion.ofertaGrupo.idOfertaGrupo,
          ),
          method: 'in',
        },
      ],
      notLocalizacion: [
        {
          path: 'alumnoMovimiento.seccion.localizacion',
          value: 0,
          method: 'higher',
        },
      ],
      fechaNotNull: [
        {
          path: 'ofertaGrupo.ofertaTurno.oferta.fechaInicio',
          value: false,
          method: 'isnull',
        },
      ],
    }),
    [gruposSelecciones],
  );

  const {
    data: alumnosInscriptos = [],
    reFetch: reFetchGruposInscripciones,
    working: workingAlumnosInscriptos,
  } = useGruposInscripcionesOfertaLibbyFetch({
    limit: 1000,
    filter: filterGruposInscripcionesOferta,
    aspect: 'tab-acciones-seleccionadas',
    enabled: (gruposSelecciones as GruposSelecciones[]).length > 0,
  });

  const columns = useAccionesSeleccionadasColumns(
    removeGrupoSeleccion,
    setAccionSeleccionadaRow,
    true,
    alumnosInscriptos,
    alumnosInscriptos.map((grupo) => grupo.alumnoMovimiento),
  );

  const totalHorasACAP = (gruposSelecciones as GruposSelecciones[])
    .filter(
      (grupoSeleccion) =>
        alumnosInscriptos.findIndex(
          (grupo) =>
            grupo?.ofertaGrupo?.idOfertaGrupo ===
            grupoSeleccion?.ofertaGrupo?.idOfertaGrupo,
        ) !== -1,
    )
    .reduce((previousValue: number, currentValue) => {
      return (
        previousValue +
        currentValue?.ofertaGrupo?.ofertaTurno?.oferta?.accion?.horas
      );
    }, 0);

  const gruposSeleccionesPaginadas = (
    gruposSelecciones as GruposSelecciones[]
  ).slice(offset, limit + offset);

  const gruposSeleccionesOrderer = useMemo(
    () =>
      cloneDeep(gruposSeleccionesPaginadas).sort(
        (ofertaSeleccionA, ofertaSeleccionB) => {
          const nombreOrganizacionA = (
            access(orderBy, ofertaSeleccionA) || ''
          ).toLowerCase();
          const nombreOrganizacionB = (
            access(orderBy, ofertaSeleccionB) || ''
          ).toLowerCase();
          if (nombreOrganizacionA < nombreOrganizacionB) {
            return direction === 'desc' ? -1 : 1;
          }
          if (nombreOrganizacionA > nombreOrganizacionB) {
            return direction === 'desc' ? 1 : -1;
          }
          return 0;
        },
      ),
    [direction, gruposSeleccionesPaginadas, orderBy],
  );

  const handleRequestSort = useCallback((key: string) => {
    setDirection((prev) => (prev === 'asc' ? 'desc' : 'asc'));
    SetOrderBy(key);
  }, []);

  return (
    <>
      {accionSeleccionadaRow === null ? (
        <>
          <AccionesSeleccionadasFiltersHeader
            filters={filters}
            setFilters={setFilters}
            handleSearch={handleSearch}
            uniquesOrientacionesEscuela={uniquesOrientacionesEscuela}
            isLoading={
              planEstudioLoading ||
              workingGruposSelecciones ||
              workingAlumnosInscriptos
            }
          />
          <Typography style={{ marginTop: 40, fontWeight: 600 }}>
            Total Horas ACAP: {totalHorasACAP}
          </Typography>
          <InfoTable
            paperCustomStyle={{ margin: '0px' }}
            rows={
              workingGruposSelecciones || isDeleting || workingAlumnosInscriptos
                ? []
                : gruposSeleccionesOrderer
            }
            columns={columns}
            working={
              workingGruposSelecciones || isDeleting || workingAlumnosInscriptos
            }
            // eslint-disable-next-line react/jsx-no-duplicate-props
            // direction={direction}
            onSortChange={handleRequestSort}
          />
          {(gruposSelecciones as GruposSelecciones[]).length > 0 &&
            !(
              workingGruposSelecciones ||
              isDeleting ||
              workingAlumnosInscriptos
            ) && (
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={(gruposSelecciones as GruposSelecciones[]).length}
                rowsPerPage={limit}
                page={offset / limit}
                onPageChange={(e, page) => {
                  let offset = 0;
                  if (page < 0) offset = 0 * limit;
                  else offset = page * limit;
                  setOffset(offset);
                }}
                onRowsPerPageChange={(e) => {
                  setLimit(parseInt(e.target.value));
                }}
                labelRowsPerPage="Filas por página"
                labelDisplayedRows={({ from, to, count }) => {
                  return from + '-' + to + ' de ' + count;
                }}
              />
            )}
        </>
      ) : (
        <InscripcionAccionesSeleccionadas
          accionSeleccionadaRow={accionSeleccionadaRow}
          setAccionSeleccionadaRow={setAccionSeleccionadaRow}
          reFetchGruposInscripciones={reFetchGruposInscripciones}
          uniquesOrientacionesEscuela={uniquesOrientacionesEscuela}
          alumnosEscuelaMixtoCheck={alumnosInscriptos.map(
            (grupo) => grupo.alumnoMovimiento,
          )}
          alumnosInscripciones={alumnosInscriptos}
          gruposSelecciones={gruposSelecciones}
          workingAlumnosInscripciones={workingAlumnosInscriptos}
        />
      )}
    </>
  );
};
