import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ConfiguracionDAO extends LibbyFetchDAO {
  constructor() {
    super('configuracion', 'idConfiguracion');
  }

  getByTipo(tipo: number) {
    return this.query().equals('tipoConfiguracion', tipo).run();
  }
}
