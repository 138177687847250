import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { MotivoExceptuado } from '../../models';
import { businessOptions } from '../businessOptions';
import { MotivosExceptuadoDAO } from 'src/platform/libby/dao/Presentismov3';

const DAO_NAME = 'motivo_exceptuado';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idMotivoExceptuado',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idMotivoExceptuado',
      labelKey: 'descripcion',
    },
    withSimpleSelect: {
      labelKey: 'descripcion',
      valueKey: 'idMotivoExceptuado',
      title: '',
      placeholder: 'Seleccioná un Motivo',
      name: 'id',
    },
  },
};

const optionsToUse = !businessOptions.MotivosExceptuado
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.MotivosExceptuado,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.MotivosExceptuado.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    selectInput,
  },
} = makeBusinessEntity<MotivoExceptuado, MotivosExceptuadoDAO>(
  DAO_NAME,
  optionsToUse,
);

// hooks
export const useMotivosExceptuadoFetchById = fetchById;
export const useMotivosExceptuadoLibbyCall = libbyCall;
export const useMotivosExceptuadoDAO = libbyDAO;
export const useMotivosExceptuadoLibbyFetch = libbyFetch;

// components/hoc
export const MotivosExceptuadoAutocomplete = entityAutocomplete;
export const MotivosExceptuadoCall = renderPropCall;
export const MotivosExceptuadoById = renderPropFetchById;
export const MotivosExceptuadoSelect = simpleSelect;
export const MotivosExceptuadoSelectInput = selectInput;
