import moment from 'moment';
import { ContraturnoDia } from '../../../../../../../app/models';

export const getContraturnosByDate = (
  contraturnos: ContraturnoDia[],
  date: string,
) => {
  const _contraturnos = contraturnos.filter((contraturno) => {
    return (
      contraturno?.dia?.idDia === moment(date, 'YYYY-MM-DD').day() &&
      contraturno.active &&
      contraturno.contraturno.active &&
      moment(date, 'YYYY-MM-DD').isBetween(
        moment(contraturno.contraturno.fechaInicio, 'YYYY-MM-DD').format(
          'YYYY-MM-DD',
        ),
        moment(contraturno.contraturno.fechaFin, 'YYYY-MM-DD').format(
          'YYYY-MM-DD',
        ),
        undefined,
        '[]',
      )
    );
  });
  return _contraturnos;
};
