import React, { FunctionComponent, useMemo } from 'react';
import { Grid, Typography, IconButton } from '@material-ui/core';
import Delete from '@material-ui/icons/Delete';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormInput,
  FormAutocomplete,
  FormCheckbox,
  FormDatePicker,
  useFormContext,
} from 'src/lib/templates';
import {
  Provincia,
  TipoDocumento,
  Pais,
  Genero,
  TipoVinculo,
  EstadoCivil,
  TipoDomicilio,
  NivelEducAlcanzado,
  CantDiasSemanaLab,
  Municipio,
} from 'src/app/models';
import {
  useTipoVinculoLibbyFetch,
  useTipoDocumentoLibbyFetch,
  useGeneroLibbyFetch,
  usePaisLibbyFetch,
  useEstadoCivilLibbyFetch,
  useNivelEducAlcanzadoLibbyFetch,
  useTipoDomicilioLibbyFetch,
  useCantDiasSemanaLabLibbyFetch,
  useMunicipioLibbyFetch,
  useProvinciaLibbyFetch,
} from 'src/app/business';
import { pais } from 'src/commons';

const { ID_PAIS_ARG } = pais;

const useStyles = makeStyles({
  container: {
    marginBottom: 90,
    background: '#f5f5f5',
    marginTop: 20,
    borderRadius: '5px',
    padding: 10,
  },
  firstTitle: {
    color: '#595959',
    fontSize: 20,
    marginTop: 10,
  },
  firstTitleContainer: {
    display: 'flex',
    justifyContent: 'row',
  },
  subtitle: {
    color: '#595959',
    fontSize: 18,
    marginTop: 24,
  },
  title: {
    color: '#595959',
    fontSize: 20,
    marginTop: 24,
  },
  titleContainer: {
    marginTop: 24,
    paddingBottom: '0 !important',
  },
});

interface ResponsablesFormProps {
  onCancel: () => void;
  onDelete: () => void;
  editResponsableId: any | undefined;
}

export const ResponsablesForm: FunctionComponent<ResponsablesFormProps> = ({
  onCancel,
  onDelete,
  editResponsableId,
}) => {
  const classes = useStyles();

  const { form, values } = useFormContext();

  const { data: tiposDocumento } = useTipoDocumentoLibbyFetch();
  const { data: generos } = useGeneroLibbyFetch();
  const { data: vinculos } = useTipoVinculoLibbyFetch();
  const { data: paises } = usePaisLibbyFetch({ orderBy: 'descripcionPais' });
  const { data: estadosCiviles } = useEstadoCivilLibbyFetch();
  const { data: nivelesEducativos } = useNivelEducAlcanzadoLibbyFetch();
  const { data: tiposDomicilio } = useTipoDomicilioLibbyFetch();
  const { data: diasPorSemana } = useCantDiasSemanaLabLibbyFetch();
  const { data: municipios } = useMunicipioLibbyFetch();
  const { data: provinciasAll } = useProvinciaLibbyFetch();

  const idPaisNacimiento = useMemo(
    () => values?.responsables?.datosPrincipales?.paisNacimiento || ID_PAIS_ARG,
    [values],
  );

  const filterProvinciasByPais = useMemo(
    () => ({
      pais: [{ path: 'pais.idPais', value: idPaisNacimiento }],
    }),
    [idPaisNacimiento],
  );

  const { data: provincias } = useProvinciaLibbyFetch({
    filter: filterProvinciasByPais,
    limit: 200,
    orderBy: 'nombreProvincia',
  });

  return (
    <Grid className={classes.container} container direction="column">
      <Grid container spacing={3} item direction="column">
        <Grid container item direction="row" justify="space-between">
          <div className={classes.firstTitleContainer}>
            <IconButton aria-label="back" onClick={onCancel}>
              <ChevronLeft />
            </IconButton>
            <Typography className={classes.firstTitle}>
              1. Responsable
            </Typography>
          </div>
          <Grid item>
            <IconButton
              disabled={!editResponsableId}
              aria-label="delete"
              onClick={onDelete}
            >
              <Delete />
            </IconButton>
          </Grid>
        </Grid>

        <Grid item container direction="row" spacing={3}>
          <Grid item xs={6}>
            <FormInput
              label="Apellidos"
              name="responsables.datosPrincipales.apellidos"
              form={form}
              placeholder="Escribí el Apellido"
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              label="Nombres"
              name="responsables.datosPrincipales.nombres"
              form={form}
              placeholder="Escribí el Nombre"
            />
          </Grid>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={6}>
            <FormAutocomplete
              placeholder="Seleccioná Tipo de Documento"
              label="Tipo de Documento"
              name="responsables.datosPrincipales.tipoDocumento"
              form={form}
              options={tiposDocumento || []}
              optionLabelKey="descripcionTipoDocumento"
              optionToValue={(option: TipoDocumento) => option?.idTipoDocumento}
              valueToOption={(value: any, options: TipoDocumento[]) =>
                options.find((_item) => _item.idTipoDocumento === value)
              }
              getOptionSelected={() => {}}
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              label="Número de Documento"
              name="responsables.datosPrincipales.documento"
              form={form}
              placeholder="Escribí el Número de Documento"
            />
          </Grid>
        </Grid>

        <Grid item container direction="row" spacing={3}>
          <Grid item xs={6}>
            <FormAutocomplete
              placeholder="Seleccioná Género"
              label="Género"
              name="responsables.datosPrincipales.genero"
              form={form}
              options={generos || []}
              optionLabelKey="descripcionGenero"
              optionToValue={(option: Genero) => option?.idGenero}
              valueToOption={(value: any, options: Genero[]) =>
                options.find((_item) => _item.idGenero === value)
              }
              getOptionSelected={() => {}}
            />
          </Grid>
          <Grid item xs={6}>
            <FormAutocomplete
              placeholder="Seleccioná Lazo"
              label="Lazo"
              name="responsables.datosPrincipales.lazo"
              form={form}
              options={vinculos || []}
              optionLabelKey="descripcionTipoVinculo"
              optionToValue={(option: TipoVinculo) => option?.idTipoVinculo}
              valueToOption={(value: any, options: TipoVinculo[]) =>
                options.find((_item) => _item.idTipoVinculo === value)
              }
              getOptionSelected={() => {}}
            />
          </Grid>
        </Grid>

        <Grid item container direction="row" spacing={3}>
          <Grid item xs={3}>
            <FormDatePicker
              name="responsables.datosPrincipales.fechaNacimiento"
              label="Fecha de nacimiento"
              form={form}
            />
          </Grid>

          <Grid item xs={3}>
            <FormAutocomplete
              placeholder="Seleccioná País"
              label="País de Nacimiento"
              name="responsables.datosPrincipales.paisNacimiento"
              form={form}
              options={paises || []}
              optionLabelKey="descripcionPais"
              optionToValue={(option: Pais) => option?.idPais}
              valueToOption={(value: any, options: Pais[]) =>
                options.find((_item) => _item.idPais === value)
              }
            />
          </Grid>

          <Grid item xs={6}>
            <FormAutocomplete
              placeholder="Seleccioná Provincia"
              label="Provincia de Nacimiento"
              name="responsables.datosPrincipales.provinciaNacimiento"
              form={form}
              options={provincias || []}
              optionLabelKey="nombreProvincia"
              optionToValue={(option: Provincia) => option?.idProvincia}
              valueToOption={(value: any, options: Provincia[]) =>
                options.find((_item) => _item.idProvincia === value)
              }
            />
          </Grid>
        </Grid>

        <Grid item container direction="row" spacing={3}>
          <Grid item xs={6}>
            <FormInput
              label="E-mail"
              name="responsables.datosPrincipales.email"
              form={form}
              placeholder="Escribí el Correo Electrónico"
            />
          </Grid>
          <Grid item xs={3}>
            <FormInput
              label="Teléfono de Emergencias"
              name="responsables.datosPrincipales.telefonoEmergencias"
              form={form}
              placeholder="Escribí un Teléfono"
              type="number"
            />
          </Grid>
          <Grid item xs={3}>
            <FormInput
              label="Otro Teléfono opcional"
              name="responsables.datosPrincipales.telefonoOtro"
              form={form}
              placeholder="Escribí un Teléfono"
              type="number"
            />
          </Grid>
        </Grid>

        <Grid item container direction="row" spacing={3}>
          <Grid item xs={6}>
            <FormAutocomplete
              placeholder="Seleccioná el Nivel Educativo Alcanzado"
              label="Nivel Educativo Alcanzado"
              name="responsables.datosPrincipales.nivelEducativo"
              form={form}
              options={nivelesEducativos || []}
              optionLabelKey="descripcion"
              optionToValue={(option: NivelEducAlcanzado) =>
                option?.idNivelEducAlcan
              }
              valueToOption={(value: any, options: NivelEducAlcanzado[]) =>
                options.find((_item) => _item.idNivelEducAlcan === value)
              }
            />
          </Grid>

          <Grid item xs={6}>
            <FormInput
              label="Título educativo"
              name="responsables.datosPrincipales.tituloEducativo"
              form={form}
              placeholder="Ingresá el máximo título alcanzado"
            />
          </Grid>
        </Grid>

        <Grid item container direction="row" spacing={3}>
          <Grid item xs={6}>
            <FormAutocomplete
              placeholder="Seleccioná Estado Civil"
              label="Estado Civil"
              name="responsables.datosPrincipales.estadoCivil"
              form={form}
              options={estadosCiviles || []}
              optionLabelKey="descripcion"
              optionToValue={(option: EstadoCivil) => option?.idEstadoCivil}
              valueToOption={(value: any, options: EstadoCivil[]) =>
                options.find((_item) => _item.idEstadoCivil === Number(value))
              }
            />
          </Grid>

          <Grid item container direction="column" xs={6}>
            <Grid item container>
              <Grid item xs={6}>
                <FormCheckbox
                  label="Contacto de emergencia"
                  name="responsables.datosPrincipales.contactoEmergencia"
                  form={form}
                />
              </Grid>
              <Grid item xs={6}>
                <FormCheckbox
                  label="Diplomático"
                  name="responsables.datosPrincipales.diplomatico"
                  form={form}
                />
              </Grid>
            </Grid>

            <Grid item container>
              <Grid item xs={6}>
                <FormCheckbox
                  label="Retira alumno"
                  name="responsables.datosPrincipales.retiraAlumno"
                  form={form}
                />
              </Grid>
              <Grid item xs={6}>
                <FormCheckbox
                  label="Convive con alumno"
                  name="responsables.datosPrincipales.convive"
                  form={form}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3} item direction="column">
        <Grid item>
          <Typography className={classes.title}>2. Domicilio</Typography>
        </Grid>

        <Grid item container direction="row" spacing={3}>
          <Grid item xs={6}>
            <FormAutocomplete
              placeholder="Seleccioná Tipo de Domicilio"
              label="Tipo de Domicilio"
              name="responsables.datosDomicilio.tipoDomicilio"
              form={form}
              options={tiposDomicilio || []}
              optionLabelKey="descripcion"
              optionToValue={(option: TipoDomicilio) => option?.idTipoDomicilio}
              valueToOption={(value: any, options: TipoDomicilio[]) =>
                options.find((_item) => _item.idTipoDomicilio === value)
              }
            />
          </Grid>
          <Grid item xs={6}>
            {/* // La decisión sobre qué campo se va usar se tomará luego, por ahora usamos el de municipio, en caso contrario no se podrá guardar el formulario */}

            {/* <FormAutocomplete
              placeholder="Seleccioná Provincia"
              label="Provincia"
              name="responsables.datosDomicilio.provincia"
              form={form}
              options={provincias || []}
              optionLabelKey="nombreProvincia"
              optionToValue={(option: Provincia) => option?.idProvincia}
              valueToOption={(value: any, options: Provincia[]) =>
                options.find((_item) => _item.idProvincia === value)
              }
            /> */}
            <FormAutocomplete
              placeholder="Seleccioná Municipio"
              label="Municipio"
              name="responsables.datosDomicilio.provincia"
              form={form}
              options={municipios || []}
              optionLabelKey="descripcionMunicipio"
              optionToValue={(option: Municipio) => option?.idMunicipio}
              valueToOption={(value: any, options: Municipio[]) =>
                options.find((_item) => _item.idMunicipio === value)
              }
            />
          </Grid>
        </Grid>

        <Grid item container direction="row" spacing={3}>
          <Grid item xs={3}>
            <FormInput
              label="Calle"
              name="responsables.datosDomicilio.calle"
              form={form}
              placeholder="Escribí Calle"
            />
          </Grid>
          <Grid item xs={3}>
            <FormInput
              label="Altura"
              name="responsables.datosDomicilio.altura"
              form={form}
              placeholder="Escribí Altura"
            />
          </Grid>
          <Grid item xs={3}>
            <FormInput
              label="Piso"
              name="responsables.datosDomicilio.piso"
              form={form}
              placeholder="Indicá Piso"
            />
          </Grid>
          <Grid item xs={3}>
            <FormInput
              label="Depto"
              name="responsables.datosDomicilio.depto"
              form={form}
              placeholder="Indicá Departamento"
            />
          </Grid>
        </Grid>

        <Grid item container direction="row" spacing={3}>
          <Grid item xs={6}>
            <FormInput
              label="Código Postal"
              name="responsables.datosDomicilio.codigoPostal"
              form={form}
              placeholder="Ingresá Código Postal"
              type="number"
            />
          </Grid>
          <Grid item xs={3}>
            <FormInput
              label="Teléfono 1"
              name="responsables.datosDomicilio.telefono1"
              form={form}
              placeholder="Ingresá un Teléfono"
              type="number"
            />
          </Grid>
          <Grid item xs={3}>
            <FormInput
              label="Teléfono 2 (opcional)"
              name="responsables.datosDomicilio.telefono2"
              form={form}
              placeholder="Ingresá un Teléfono"
              type="number"
            />
          </Grid>
        </Grid>

        <Grid item container direction="row" spacing={3}>
          <Grid item xs={6}>
            <FormInput
              label="Observaciones (opcional)"
              name="responsables.datosDomicilio.observaciones"
              form={form}
              placeholder="Escribí Observaciones"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3} item direction="column">
        <Grid item>
          <Typography className={classes.title}>
            3. Datos Laborales (opcionales)
          </Typography>
        </Grid>
        <Grid item container direction="row" spacing={3}>
          <Grid item xs={6}>
            <FormInput
              label="Establecimiento/Empresa"
              name="responsables.datosLaborales.establecimientoEmpresa"
              form={form}
              placeholder="Ingresá Establecimiento o Empresa"
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              label="Ocupación"
              name="responsables.datosLaborales.ocupacion"
              form={form}
              placeholder="Ingresá Ocupación"
            />
          </Grid>
        </Grid>

        <Grid item>
          <Typography className={classes.subtitle}>Jornada Laboral</Typography>
        </Grid>

        <Grid item container direction="row" spacing={3}>
          <Grid item xs={6}>
            <FormInput
              label="Desde/Hasta"
              name="responsables.datosLaborales.desdeHastaHorasLaborales"
              form={form}
              placeholder="9:00-18:00"
            />
          </Grid>
          <Grid item xs={3}>
            <FormInput
              label="Horas por Día"
              name="responsables.datosLaborales.horasPorDia"
              form={form}
              placeholder="9"
            />
          </Grid>
          <Grid item xs={3}>
            <FormAutocomplete
              placeholder="Días por Semana"
              label="Cantidad de dias por semana"
              name="responsables.datosLaborales.cantidadDiasSemana"
              form={form}
              options={diasPorSemana || []}
              optionLabelKey="descripcion"
              optionToValue={(option: CantDiasSemanaLab) =>
                option?.idCantDiasSemana
              }
              valueToOption={(value: any, options: CantDiasSemanaLab[]) =>
                options.find(
                  (_item) => _item.idCantDiasSemana === Number(value),
                )
              }
              getOptionSelected={() => {}}
            />
          </Grid>
        </Grid>

        <Grid item>
          <Typography className={classes.subtitle}>
            Domicilio Laboral
          </Typography>
        </Grid>

        <Grid item container direction="row" spacing={3}>
          <Grid item xs={6}>
            <FormAutocomplete
              placeholder="Seleccioná Tipo de Domicilio"
              label="Tipo de Domicilio"
              name="responsables.datosLaborales.tipoDomicilio"
              form={form}
              options={tiposDomicilio || []}
              optionLabelKey="descripcion"
              optionToValue={(option: TipoDomicilio) => option?.idTipoDomicilio}
              valueToOption={(value: any, options: TipoDomicilio[]) =>
                options.find((_item) => _item.idTipoDomicilio === value)
              }
            />
          </Grid>
          <Grid item xs={6}>
            <FormAutocomplete
              placeholder="Seleccioná Provincia"
              label="Provincia"
              name="responsables.datosLaborales.provincia"
              form={form}
              options={provinciasAll || []}
              optionLabelKey="nombreProvincia"
              optionToValue={(option: Provincia) => option?.idProvincia}
              valueToOption={(value: any, options: Provincia[]) =>
                options.find((_item) => _item.idProvincia === value)
              }
            />
          </Grid>
        </Grid>

        <Grid item container direction="row" spacing={3}>
          <Grid item xs={3}>
            <FormInput
              label="Calle"
              name="responsables.datosLaborales.calle"
              form={form}
              placeholder="Escribí Calle"
            />
          </Grid>
          <Grid item xs={3}>
            <FormInput
              label="Altura"
              name="responsables.datosLaborales.altura"
              form={form}
              placeholder="Escribí Altura"
            />
          </Grid>
          <Grid item xs={3}>
            <FormInput
              label="Piso"
              name="responsables.datosLaborales.piso"
              form={form}
              placeholder="Indicá Piso"
            />
          </Grid>
          <Grid item xs={3}>
            <FormInput
              label="Depto"
              name="responsables.datosLaborales.depto"
              form={form}
              placeholder="Indicá Departamento"
            />
          </Grid>
        </Grid>

        <Grid item container direction="row" spacing={3}>
          <Grid item xs={6}>
            <FormInput
              label="Código Postal"
              name="responsables.datosLaborales.codigoPostal"
              form={form}
              placeholder="Ingresá Código Postal"
            />
          </Grid>
          <Grid item xs={6}>
            <FormInput
              label="Observaciones (opcional)"
              name="responsables.datosLaborales.observaciones"
              form={form}
              placeholder="Escribí Observaciones"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
