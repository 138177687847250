import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { ContraturnoDia } from '../../models';
import { businessOptions } from '../businessOptions';
import {
  ContraturnoDAO,
  ContraturnoDiaDAO,
} from 'src/platform/libby/dao/Presentismov3';

const DAO_NAME = 'contraturno_dia';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idContraturnoDia',
      labelKey: 'description',
    },
    withSelectedChips: {
      idKey: 'idContraturnoDia',
      labelKey: 'description',
    },
    withSimpleSelect: {
      labelKey: 'idContraturnoDia',
      valueKey: 'description',
      title: '',
      placeholder: 'Seleccioná ContraturnoDia',
      name: 'id',
    },
  },
};

const optionsToUse = !businessOptions.contraturnoDia
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.contraturnoDia,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.contraturnoDia.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
  },
} = makeBusinessEntity<ContraturnoDia, ContraturnoDiaDAO>(
  DAO_NAME,
  optionsToUse,
);

// hooks
export const useContraturnoDiaFetchById = fetchById;
export const useContraturnoDiaLibbyCall = libbyCall;
export const useContraturnoDiaDAO = libbyDAO;
export const useContraturnoDiaLibbyFetch = libbyFetch;

// components/hoc
export const ContraturnoDiaAutocomplete = entityAutocomplete;
export const ContraturnoDiaCall = renderPropCall;
export const ContraturnoDiaById = renderPropFetchById;
export const ContraturnoDiaSelect = simpleSelect;
