import { ProyeccionIEL } from 'src/app/models';
import Efectivizador, { SingleProyeccion } from './Efectivizador';
import { initialSchoolsToRemove } from 'src/screens/Private/GestionProyeccion/components/FooterButtons';

export default class EfectivizadorInicial extends Efectivizador {
  private proyeccionesIEL: { idProyeccionIEL: string }[];
  private localizacion: string;
  constructor(
    proyecciones: SingleProyeccion[],
    proyeccionesIEL: { idProyeccionIEL: string }[],
    localizacion: string,
    estado?: number,
  ) {
    super(proyecciones, estado);
    this.proyeccionesIEL = proyeccionesIEL;
    this.localizacion = localizacion;
  }

  hasInformedIEL() {
    return (
      this.proyeccionesIEL.length > 0 ||
      initialSchoolsToRemove.includes(Number(this.localizacion))
    );
  }

  canEfectivizar(): boolean {
    const can = super.canEfectivizar();
    return can && this.hasInformedIEL();
  }
}
