import React, { useCallback } from 'react';
import { CardContent, makeStyles } from '@material-ui/core';
import { grey, secondary } from '../../../../theme/colors';
import { AnyObject } from '../../../types';

const useStyles = makeStyles({
  cardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  headerContent: {
    backgroundColor: secondary.headerCard,
    height: 33,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 12,
  },
  headerText: {
    color: grey.heading,
    fontSize: 11,
    fontFamily: 'Open Sans',
  },
  textAssignatureContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    paddingTop: 45,
    paddingBottom: 12,
    paddingLeft: 12,
  },
  assignatureTitle: {
    alignSelf: 'flex-start',
    fontSize: 24,
    fontWeight: 600,
    color: grey.heading,
    fontFamily: 'Open Sans',
    textShadow: '1px 1px 2px black',
  },
  assignatureText: {
    alignSelf: 'flex-start',
    fontSize: 14,
    color: grey.heading,
    fontFamily: 'Open Sans',
    textShadow: '1px 1px 2px black',
  },
});

export interface CardItemContentAssignatureProps {
  item: AnyObject;
  handleTableClick: (item: AnyObject) => void;
}

export const CardItemContentAssignature = ({
  item,
  handleTableClick,
}: CardItemContentAssignatureProps) => {
  const classes = useStyles();
  const onClick = useCallback(
    () => handleTableClick(item),
    [handleTableClick, item],
  );
  return (
    <CardContent className={classes.cardContent} onClick={onClick}>
      <div className={classes.headerContent}>
        <span className={classes.headerText}>
          {item.materia?.area?.descripcion}
        </span>
      </div>
      <div className={classes.textAssignatureContainer}>
        <span className={classes.assignatureTitle}>{item.descripcion}</span>
        <span className={classes.assignatureText}>
          {item?.materia?.descripcion}
        </span>
      </div>
    </CardContent>
  );
};
