import React from 'react';
import { useParams } from 'react-router';
import {
  useBackButton,
  useRouteScreenTitle,
} from '../../../../../../../commons/hooks';
import { ArealInterarealNew } from './components';
import { ArealInterarealForm } from './ArealInterarealForm';
import { EspaciosArealPlanById } from '../../../../../../../app/business';
import { Loading } from '../../../../../../../commons/components/Loading';
import { useHistory } from 'react-router-dom';

// TODO: definir que hacer con esto
const planificacionTemplateId = 1;

export const ArealInterareal = () => {
  // Title
  useRouteScreenTitle('Planificación Areal/Interareal');
  const history = useHistory();
  useBackButton(history.location.pathname.match(/.+\d/i)[0]);
  // @ts-ignore
  const { idPlanificacion, idEspacioCurricular } = useParams();

  return idPlanificacion === 'new' ? (
    <ArealInterarealNew
      templateId={planificacionTemplateId}
      idEspacioCurricular={idEspacioCurricular}
    >
      {({ template, initialValues, working }) =>
        working || !template || !initialValues ? (
          <Loading />
        ) : (
          <ArealInterarealForm
            template={template}
            initialValues={initialValues}
          />
        )
      }
    </ArealInterarealNew>
  ) : (
    <EspaciosArealPlanById id={idPlanificacion} aspect="limit_province">
      {({ data, working }) => {
        delete data?.arealPlan?.cicloLectivo;

        return working || !data ? (
          <Loading />
        ) : (
          <ArealInterarealForm
            template={data.arealPlan.arealTempPlan}
            initialValues={data.arealPlan}
          />
        );
      }}
    </EspaciosArealPlanById>
  );
};
