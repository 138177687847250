import { LibbyFetchDAO } from './LibbyFetchDAO';

export class EspacioCurricularSeccionDAO extends LibbyFetchDAO {
  constructor() {
    super('espacio_curricular_seccion', 'idEspacioCurricularSeccion');
  }

  getAll() {
    return this.query().limit(0, 100).run();
  }

  getByEspacioCurricularId(espacioCurricularId: string) {
    return this.query().equals('espacioCurricular', espacioCurricularId).run();
  }

  getBySeccionId(seccionId: string) {
    return this.query().equals('seccion', seccionId).run();
  }

  getByEspacioCurricularAndSeccion(
    idEspacioCurricular: number | string,
    idSeccion: number | string,
  ) {
    return this.query()
      .equals('espacioCurricular', idEspacioCurricular)
      .equals('seccion', idSeccion)
      .run();
  }

  getByFullFilter(filter: any) {
    return this.fetch(filter);
  }

  getBySecciones(filter: any) {
    return this.fetch({ filter });
  }
}
