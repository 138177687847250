import React from 'react';
import { Slider, Tooltip, Typography, withStyles } from '@material-ui/core';

interface AutoevaluacionSliderProps {
  value: number;
  showTitles: boolean;
  onChange: (event: React.ChangeEvent<{}>, value: any) => void;
  color?: string; // Propiedad opcional para el color del Slider
  thickness?: number; // Propiedad opcional para el grosor del Slider
}
const CustomSlider = withStyles({
  root: {
    height: (props: AutoevaluacionSliderProps) => props.thickness || 4,
  },
  thumb: {
    color: '#fff',
    width: 20,
    height: 20,
    marginTop: -8,
    marginLeft: -10,
    boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.5)',
  },
  track: {
    height: (props: AutoevaluacionSliderProps) => props.thickness || 4,
  },
  rail: {
    height: (props: AutoevaluacionSliderProps) => props.thickness || 4,
  },
})(Slider);

export const AutoevaluacionSlider = ({
  value,
  onChange,
  showTitles,
  color = 'rgb(72 65 191)',
  thickness = 4, // Valor predeterminado para el grosor
}: AutoevaluacionSliderProps) => {
  const marks = [
    { value: 0, label: 'No Evaluado', tooltipText: 'No Evaluado' },
    { value: 1, label: 'Grado 1', tooltipText: 'Mínimo' },
    { value: 2, label: 'Grado 2', tooltipText: 'Intermedio' },
    { value: 3, label: 'Grado 3', tooltipText: 'Máximo' },
  ];

  const sliderStyles = {
    color: color,
  };

  function ValueLabelComponent({
    children,
    value,
  }: {
    value: any;
    children: JSX.Element;
  }) {
    const [open, setOpen] = React.useState(false);

    const handleTooltipOpen = () => {
      setOpen(true);
    };

    const handleTooltipClose = () => {
      setOpen(false);
    };
    return (
      <Tooltip
        open={open}
        onOpen={handleTooltipOpen}
        onClose={handleTooltipClose}
        enterTouchDelay={0}
        placement="top"
        title={`${marks.find((mark) => mark.value === value)?.tooltipText}`}
      >
        {children}
      </Tooltip>
    );
  }

  return (
    <div>
      {showTitles && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          {marks.map((mark, index) => (
            <Typography
              key={mark.value}
              style={index === 1 ? { marginLeft: '-33px' } : undefined}
            >
              {mark.label}
            </Typography>
          ))}
        </div>
      )}

      <CustomSlider
        value={value}
        onChange={onChange}
        step={1}
        min={0}
        max={3}
        valueLabelDisplay="auto"
        style={sliderStyles}
        thickness={thickness} // Aplica el grosor personalizado al Slider
        ValueLabelComponent={ValueLabelComponent} // Aplica tooltip de las evaluaciones
      />
    </div>
  );
};
