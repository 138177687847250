import React from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import { EspacioPlan, EspacioCurricular as EC } from 'src/app/models';
import { grey } from 'src/theme/colors';
import { ListItemProperties } from '../../commons';
import { useInterdisciplinarContext } from '../../../forms/commons/components';

const useStyles = makeStyles(() => ({
  textField: {
    fontSize: 14,
    color: grey.textPrimary,
    fontWeight: 600,
  },
  textContenido: {
    fontSize: 14,
    color: grey.textPrimary,
    fontWeight: 600,
    marginBottom: 20,
  },
  valueField: {
    fontSize: 16,
    color: grey.textPrimary,
    fontWeight: 400,
    marginTop: 8,
    marginBottom: 20,
  },
}));
export interface EspacioCurricularProps {
  data: EspacioPlan;
}

export interface FilterEspacios {
  espacios: EC; // Omit<EC, 'localizacion' | 'planEstudio' | 'materia'>;
  habilidades: { idHabilidades: number; descripcion: string };
  contenidos: {
    idContenidoEspecifico: number;
    descripcion: string;
    contenidoEspecificoSubnivels: {
      idContenidoEspecificoSubnivel: number;
      descripcion: string;
    }[];
  }[];
}

export const EspacioCurricular = ({ data }: EspacioCurricularProps) => {
  const classes = useStyles();
  const {
    data: {
      espaciosCurriculares,
      habilidades,
      contenidosEspecificos,
      contenidosBloques,
    },
    working,
  } = useInterdisciplinarContext();

  const filterEspacios = data?.espaciosCurriculares.map((espacio) => {
    const espacios = espaciosCurriculares.find(
      (espacioCurric: EC) =>
        espacioCurric?.idEspacioCurricular === espacio?.espacioCurricular?.id,
    );
    const filterHabilidades = espacio?.habilidades.map((habilidad: string) => {
      const select = habilidades.find(
        (_habilidad: any) => habilidad === _habilidad.idHabilidades,
      );
      return select;
    });

    const espacioBloque = espacio?.espacioCurricular?.tieneBloque;
    const isBloque: Array<any> = espacioBloque
      ? contenidosBloques
      : contenidosEspecificos;
    const filterContenidos: Array<any> = [];
    espacio?.contenidosEspecificos?.forEach(
      (contenido: { id: string; subLevel: Array<string> }) => {
        const select = isBloque
          .filter(
            (_contenido) =>
              Number(contenido.id) ===
                Number(_contenido?.idContenidoEspecifico) ||
              Number(contenido.id) === Number(_contenido?.idContenidoBloque),
          )
          .map((_contenido) => ({
            id:
              _contenido?.idContenidoEspecifico ||
              _contenido?.idContenidoBloque,
            descripcion: _contenido.descripcion,
            subLevel: contenido.subLevel.map((subLevel: any) => {
              const contenidoSubNivelAll = espacioBloque
                ? _contenido.contenidoBloqueSubnivels
                : _contenido.contenidoEspecificoSubnivels;

              const sub = contenidoSubNivelAll.find(
                (_subLevel: any) =>
                  Number(_subLevel?.idContenidoEspecificoSubnivel) ===
                    Number(subLevel.id) ||
                  Number(_subLevel?.idContenidoBloqueSubnivel) ===
                    Number(subLevel.id),
              );
              const contenidoSubNivelDosAll = espacioBloque
                ? sub?.contenidoBloqueSubnivelDos
                : sub?.contenidoEspecificoSubnivelDos;
              return {
                id:
                  sub?.idContenidoEspecificoSubnivel ||
                  sub?.idContenidoBloqueSubnivel,
                descripcion: sub?.descripcion || '',
                subLevel2: contenidoSubNivelDosAll.filter((_subLevel2: any) =>
                  subLevel?.subLevel2.find(
                    (valor: any) =>
                      _subLevel2?.idContenidoEspecificoSubnivelDos?.toString() ===
                        valor ||
                      _subLevel2?.idContenidoBloqueSubnivelDos?.toString() ===
                        valor,
                  ),
                ),
              };
            }),
          }));
        filterContenidos.push(...select);
      },
    );

    return {
      espacios,
      habilidades: filterHabilidades,
      contenidos: filterContenidos,
    };
  });

  return (
    <Grid container direction="column">
      {!working &&
        filterEspacios?.map(
          ({ espacios, contenidos, habilidades: _habilidades }) => (
            <Grid key={espacios?.idEspacioCurricular}>
              <Typography className={classes.textField}>
                Espacio Curricular
              </Typography>
              <Typography className={classes.valueField}>
                {espacios?.descripcion}
              </Typography>
              {_habilidades[0] && (
                <ListItemProperties
                  title="Habilidades"
                  values={_habilidades?.map((h: any) => ({
                    id: h.idHabilidades,
                    descripcion: h.descripcion,
                  }))}
                />
              )}
              <ListItemProperties
                title="Contenidos Específicos"
                values={contenidos}
              />
            </Grid>
          ),
        )}
    </Grid>
  );
};
