import { IconButton, TableCell } from '@material-ui/core';
import { Visibility } from '@material-ui/icons';
import React, { useCallback } from 'react';
import { useStudentReportCellStyles } from '../css';
import { StudentReportCellProps } from '../../../types';
import cx from 'classnames';
import { validate } from '@material-ui/pickers';

export const StudentReportCell = ({
  row,
  onClick,
  prestesTrimestral = [],
  workingPresentismoTrimestral,
}: StudentReportCellProps) => {
  const classes = useStudentReportCellStyles();

  const handleClick = useCallback(() => {
    onClick(row);
  }, [onClick, row]);

  return (
    <TableCell align="center" className={cx(classes.cell)}>
      <IconButton
        disabled={
          (row?.presentismo.length <= 0 &&
            prestesTrimestral.some(
              (p) =>
                p.id_alumno === row.alumno.idAlumno &&
                p.existe_resultado === false,
            )) ||
          workingPresentismoTrimestral
        }
        className={classes.iconButton}
        size="small"
        onClick={handleClick}
      >
        <Visibility className={classes.icon} />
      </IconButton>
    </TableCell>
  );
};
