import React, { useMemo } from 'react';
import { CicloLectivoSelect, SeccionSelect } from 'src/app/business';
import { Grid, makeStyles } from '@material-ui/core';
import { primary } from 'src/theme/colors';
import {
  Footer,
  InfoTable,
  OptionsModal,
  SimpleSelect,
} from 'src/commons/components';
import moment from 'moment';
import { useCicloLectivoLibbyFetch } from 'src/app/business';
import { useSnackbar } from 'notistack';
import { isValidChangeSectionYear } from '../../../utils/isValidChangeSectionYear';
import { Seccion } from 'src/app/models';

const useStyles = makeStyles({
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
  infoTableCustom: {
    marginBottom: '80px',
  },
  alumnoButton: {
    color: primary.lightBlue,
    margin: '0',
    textAlign: 'left',
    background: 'none',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export const useInscripcionColumns = (
  seccion: Seccion[],
  handleChangeSection: any,
  toLegajoAlumno: any,
  removeAlumn: any,
  editStudentBlocked: any,
  handleChangeCicloLectivo: any,
  ciclosLectivos: any,
  enabled: boolean,
) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const sortSeccionesSelect = (secciones: any) => {
    secciones?.sort((seccionA: any, seccionB: any) => {
      const nombreSeccionA = seccionA?.nombreSeccion
        ?.toLowerCase()
        .replace(/°+/g, '° ')
        .replace(/\s+/g, ' ')
        .replace(/lactantes+/g, '0lactantes');
      const nombreSeccionB = seccionB?.nombreSeccion
        ?.toLowerCase()
        .replace(/°+/g, '° ')
        .replace(/\s+/g, ' ')
        .replace(/lactantes+/g, '0lactantes');
      if (nombreSeccionA < nombreSeccionB) {
        return -1;
      }
      if (nombreSeccionA > nombreSeccionB) {
        return 1;
      }
      return 0;
    });

    return secciones;
  };

  const columns = useMemo(
    () => [
      {
        id: 'nombre',
        label: 'Apellido y nombre',
        width: '5%',
        orderById: 'alumno.persona.apellido',
        render: (value: any, row: any) => (
          <button
            type="button"
            className={classes.alumnoButton}
            onClick={() => toLegajoAlumno(row?.idAlumno)}
          >
            {row?.nombre}
          </button>
        ),
      },
      {
        id: 'documento',
        label: 'Documento',
        width: '5%',
        orderById: 'alumno.persona.documento',
        render: (value: any, row: any) => <p>{row.documento}</p>,
      },
      {
        id: 'descripcionAnio',
        label: 'Año',
        width: '5%',
        orderById: 'anio.descripcionAnio',
        render: (value: any, row: any) => <p>{row.descripcionAnio}</p>,
      },
      {
        id: 'idCondicion',
        label: 'Condición',
        width: '5%',
        orderById: 'condicion.descripcionCondicion',
        render: (value: any, row: any) => <p>{row.idCondicion}</p>,
      },
      {
        id: 'idTurno',
        label: 'Turno solicitado',
        width: '10%',
        orderById: 'turno.descripcionTurno',
        render: (value: any, row: any) => {
          return <p>{row.idTurno}</p>;
        },
      },
      // {
      //   id: 'descripcionProyeccion',
      //   label: 'Proyectado',
      //   width: '10%',
      //   orderById: 'turno.descripcionTurno',
      //   hideSortIcon: true,
      //   render: (value: any, row: any) => {
      //     return <p>{row?.proyeccion}</p>;
      //   },
      // },
      // {
      //   id: 'idCicloLectivo',
      //   label: 'Ciclo lectivo',
      //   width: '10%',
      //   orderById: 'cicloLectivo.anio',
      //   hideSortIcon: true,
      //   render: (value: any, row: any) => {
      //     return (
      //       <SimpleSelect
      //         handleChange={(e: React.FormEvent<EventTarget>) =>
      //           handleChangeCicloLectivo(e, row)
      //         }
      //         value={row.cicloLectivo}
      //         name="cicloLectivo"
      //         title=""
      //         placeholder="Seleccione ciclo lectivo"
      //         content={ciclosLectivos}
      //         labelKey="anio"
      //         valueKey="anio"
      //       />
      //     );
      //   },
      // },
      {
        id: 'selectedSeccion',
        label: 'Sección a matricular',
        width: '10%',
        hideSortIcon: true,
        noSort: true,
        render: (value: any, row: any) => {
          return (
            <SimpleSelect
              // disabled={!row.cicloLectivo}
              content={sortSeccionesSelect(
                seccion.filter(
                  (_item) =>
                    (_item?.anio.idAnio === row?.idAnio ||
                      isValidChangeSectionYear(
                        Number(_item.anio?.idAnio),
                        row.idAnio,
                        false,
                      )) &&
                    _item.nivel?.idNivel === row.idNivel &&
                    _item.cicloLectivo?.anio === row.cicloLectivo,
                ),
              )}
              value={row.idSeccion as string}
              handleChange={(e: React.FormEvent<EventTarget>) =>
                handleChangeSection(e, row)
              }
              placeholder="Seleccione Sección"
              labelKey="nombreSeccion"
              valueKey="idSeccion"
            />
          );
        },
      },
      {
        id: 'icon',
        label: '',
        width: '5%',
        hideSortIcon: true,
        noSort: true,
        render: (value: any, row: any) => (
          <OptionsModal
            options={[
              {
                label: 'Dar de baja',
                onClick: () => {
                  if (enabled) {
                    removeAlumn(row.nombre, Number(row.idInscripcionAlumno));
                  } else {
                    enqueueSnackbar(
                      'No es posible dar de baja a un estudiante luego de el 1/10/2021 con su rol actual.',
                      {
                        variant: 'error',
                      },
                    );
                  }
                },
                hide: editStudentBlocked,
              },
              {
                label: 'Ver legajo',
                onClick: () => toLegajoAlumno(row?.idAlumno),
              },
            ]}
          />
        ),
      },
    ],
    [
      classes.alumnoButton,
      editStudentBlocked,
      enabled,
      enqueueSnackbar,
      handleChangeSection,
      removeAlumn,
      seccion,
      toLegajoAlumno,
    ],
  );

  return columns;
};
