import { Rules } from 'src/lib/permission/types';
import { SIDEMENU_ID } from '../../const';

export const secundario: Rules = {
  idRule: 'nivel-3',
  routes: [
    // { key: 'presentismo' }
  ],
  blockers: [],
  sideMenu: [
    { id: SIDEMENU_ID.INFORMESC },
    // { id: SIDEMENU_ID.PRESENTISMO }
  ],
  tabBars: [],
};
