import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { TipoActividad } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'tipo_actividad';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idTipoActividad',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idTipoActividad',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.TipoActividad
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.TipoActividad,
      hoc: { ...defaultOptions.hoc, ...businessOptions.TipoActividad.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<TipoActividad>(DAO_NAME, optionsToUse);

// hooks
export const useTipoActividadFetchById = fetchById;
export const useTipoActividadLibbyCall = libbyCall;
export const useTipoActividadDAO = libbyDAO;
export const useTipoActividadLibbyFetch = libbyFetch;

// components/hoc
export const TipoActividadAutocomplete = entityAutocomplete;
export const TipoActividadCall = renderPropCall;
export const TipoActividadById = renderPropFetchById;
export const TipoActividadSelect = simpleSelect;
export const TipoActividadAccordionSelector = accordionSelector;
export const TipoActividadSelectedChips = selectedChips;

// context
// ByIdContext
export const useTipoActividadByIdContext = useByIdContext;
export const TipoActividadByIdProvider = ByIdProvider;
export const TipoActividadByIdContext = ByIdContext;
// GeneralContext
export const useTipoActividadContext = useGeneralContext;
export const TipoActividadProvider = GeneralProvider;
export const TipoActividadContext = GeneralContext;
