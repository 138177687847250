import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { LocalizacionPlanEstudio } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'localizacion_plan_estudio';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idLocalizacionPlanEstudio',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idLocalizacionPlanEstudio',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.LocalizacionPlanEstudio
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.LocalizacionPlanEstudio,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.LocalizacionPlanEstudio.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<LocalizacionPlanEstudio>(DAO_NAME, optionsToUse);

// hooks
export const useLocalizacionPlanEstudioFetchById = fetchById;
export const useLocalizacionPlanEstudioLibbyCall = libbyCall;
export const useLocalizacionPlanEstudioDAO = libbyDAO;
export const useLocalizacionPlanEstudioLibbyFetch = libbyFetch;

// components/hoc
export const LocalizacionPlanEstudioAutocomplete = entityAutocomplete;
export const LocalizacionPlanEstudioCall = renderPropCall;
export const LocalizacionPlanEstudioById = renderPropFetchById;
export const LocalizacionPlanEstudioSelect = simpleSelect;
export const LocalizacionPlanEstudioAccordionSelector = accordionSelector;
export const LocalizacionPlanEstudioSelectedChips = selectedChips;

// context
// ByIdContext
export const useLocalizacionPlanEstudioByIdContext = useByIdContext;
export const LocalizacionPlanEstudioByIdProvider = ByIdProvider;
export const LocalizacionPlanEstudioByIdContext = ByIdContext;
// GeneralContext
export const useLocalizacionPlanEstudioContext = useGeneralContext;
export const LocalizacionPlanEstudioProvider = GeneralProvider;
export const LocalizacionPlanEstudioContext = GeneralContext;
