import { Grid, makeStyles, Stepper, Step, StepLabel } from '@material-ui/core';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import { SimpleSelect } from 'src/commons';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

const useStyles = makeStyles((theme) => ({
  '@keyframes right': {
    '0%': {
      opacity: 0,
      transform: 'translateX(-2em)',
    },
    '50%': {
      opacity: 0.9,
      transform: 'translateX(10em)',
    },
    '80%': {
      opacity: 0.9,
      transform: 'translateX(10em)',
    },
    '95%': {
      opacity: 0,
      transform: 'translateX(10em)',
    },
    '100%': {
      opacity: 0,
      transform: 'translateX(-2em)',
    },
  },
  currentEspacios: {
    // backgroundColor: 'red',
    marginLeft: '5vh',
  },
  siguientesEspacios: {
    // backgroundColor: 'blue',
    marginRight: '5vh',
  },
  firstContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  currentEspaciosContainer: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-around',
  },
  SiguientesEspaciosContainer: {
    display: 'flex',
    flexDirection: 'column',
    // justifyContent: 'space-around',
  },
  espacioCurricularParagraph: {
    width: '40vh',
    paddingLeft: '10px',
    backgroundColor: '#F2F2F2',
  },
  paragraph: {
    fontFamily: 'Open Sans',
  },
  espacioCurricular: {
    fontFamily: 'Open Sans',
    borderBottom: '1px solid #d6d6d6',
    margin: '0',
    paddingBottom: 16,
    paddingTop: 16,
  },
  espaciosCurricularesParagraph: {
    width: '40vh',
    paddingLeft: '10px',
  },
  selects: {
    padding: '5px 0',
  },
  gridButton: {
    display: 'flex',
    flexDirection: 'column',
    height: 549,
  },
  icon: {
    height: 50,
    width: 50,
    animation: `$right ${theme.transitions.easing.easeInOut}`,
    animationIterationCount: 'infinite',
    animationDuration: '4s',
    marginTop: '100%',
  },
  stepperGrid: {
    alignSelf: 'flex-start',
  },
  stepper: {
    paddingTop: 0,
  },
}));

export const StepTwo = ({
  dataFormatted = [],
  currentIndex = 0,
  setDataModified,
}: any) => {
  const classes = useStyles();
  const [formValues, setFormValues] = useState<any>();
  const [selectOptions, setSelectOptions] = useState(
    dataFormatted.isNotTheSamePlan,
  );

  useEffect(() => {
    setDataModified(selectOptions);
  }, [selectOptions, setDataModified]);

  // tachamos las opciones iniciales que se comparten entre los espacios origen y destino, tambien agregamos la opcion de No trasladar
  // tambien agregamos el match a los espacios que coinciden
  const buildSelectOptions = (respuestaClonned: any) => {
    respuestaClonned.forEach((respuesta: any) => {
      respuesta.planesDestino.forEach((espacio: any) => {
        const espacioActualSearch = respuesta.planesOrigen.find(
          (element: any) => element.descripcion === espacio.descripcion,
        );
        if (espacioActualSearch) {
          espacio.needToDisable = true;
          espacioActualSearch.match = espacio?.idEspacioCurricular;
          espacioActualSearch.matchDescription = espacio?.descripcion;
        } else {
        }
      });
      const noTrasladarAlreadyExist = respuesta.planesDestino.some(
        (element: any) => {
          return element.idEspacioCurricular === 'No trasladar';
        },
      );
      if (!noTrasladarAlreadyExist) {
        respuesta.planesDestino.push({
          descripcion: 'No trasladar',
          idEspacioCurricular: 'No trasladar',
        });
      }
    });
    setSelectOptions(respuestaClonned);
  };

  // funcion para ordenar los elementos de dos arrays de forma que los que sean iguales esten en el mismo
  // indice y los que sean distintos al final
  const sortEspacios = () => {
    const biggerArrayEquals: any = [];
    const biggerArrayNotEquals: any = [];
    const smallerArrayEquals: any = [];
    const selectOptionsClonned = _.cloneDeep(selectOptions);

    const biggerIndex =
      selectOptionsClonned[currentIndex].planesOrigen.length >
      selectOptionsClonned[currentIndex].planesDestino.length
        ? 'planesOrigen'
        : 'planesDestino';

    const smallerIndex =
      biggerIndex === 'planesDestino' ? 'planesOrigen' : 'planesDestino';

    selectOptionsClonned[currentIndex][biggerIndex].forEach((espacio: any) => {
      const sameEspacioMenorArray = selectOptionsClonned[currentIndex][
        smallerIndex
      ].find((element: any) => espacio.descripcion === element.descripcion);

      if (sameEspacioMenorArray !== undefined) {
        biggerArrayEquals.push(espacio);
        smallerArrayEquals.push(sameEspacioMenorArray);
        _.remove(
          selectOptionsClonned[currentIndex][smallerIndex],
          (n: any) => n.descripcion === sameEspacioMenorArray.descripcion,
        );
      } else {
        biggerArrayNotEquals.push(espacio);
      }
    });

    selectOptionsClonned[currentIndex][biggerIndex] = [
      ...biggerArrayEquals,
      ...biggerArrayNotEquals,
    ];

    selectOptionsClonned[currentIndex][smallerIndex] = [
      ...smallerArrayEquals,
      ...selectOptionsClonned[currentIndex][smallerIndex],
    ];

    buildSelectOptions(selectOptionsClonned);
  };

  useEffect(() => {
    sortEspacios();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentIndex]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '') {
      const respuestaClonned = _.cloneDeep(selectOptions);
      respuestaClonned.forEach((respuesta: any) => {
        respuesta.planesDestino.forEach((espacio: any, index: number) => {
          const espacioCurricularOrigen =
            respuesta.planesOrigen[Number(e.target.name)];
          if (
            espacio.idEspacioCurricular === e.target.value &&
            espacio.descripcion !== 'No trasladar'
          ) {
            espacio.needToDisable = true;
          } else if (
            espacio.idEspacioCurricular === espacioCurricularOrigen.match
          ) {
            espacio.needToDisable = false;
          }
        });
        respuesta.planesOrigen.forEach((espacio: any, index: number) => {
          if (index === Number(e.target.name)) {
            const espacioDestino = respuesta.planesDestino[index];
            espacio.match = e.target.value;
            espacio.matchDescription = espacioDestino?.descripcion;
          }
        });
      });

      // setFormValues(newState);
      setSelectOptions(respuestaClonned);
    }
  };

  const steps = useMemo(() => {
    return dataFormatted.isNotTheSamePlan.map(
      (respuesta: any, index: number) => {
        return {
          label: `Grupo ${index + 1}`,
          render: <></>,
        };
      },
    );
  }, [dataFormatted]);

  const selectValue = (index: number, espacioCurricular: any) => {
    const planDestinoIndex =
      selectOptions[currentIndex].planesDestino.length - 1;

    if (espacioCurricular.match !== undefined) {
      return espacioCurricular.match;
    } else {
      return selectOptions[currentIndex].planesDestino[planDestinoIndex]
        .idEspacioCurricular;
    }
  };

  return (
    <>
      <Grid container className={classes.firstContainer}>
        <Grid item className={classes.currentEspacios}>
          <Grid container className={classes.currentEspaciosContainer}>
            <div className={classes.espacioCurricularParagraph}>
              <p className={classes.paragraph}>
                Espacios curriculares de{' '}
                {
                  selectOptions[currentIndex].planesOrigen[0]?.planEstudio
                    ?.descripcionPlanEstudio
                }
              </p>
            </div>
            <div className={classes.espaciosCurricularesParagraph}>
              {selectOptions[currentIndex].planesOrigen.map(
                (espacioCurricular: any) => (
                  <>
                    <p className={classes.espacioCurricular}>
                      {espacioCurricular.descripcion}
                    </p>
                  </>
                ),
              )}
            </div>
          </Grid>
        </Grid>
        <Grid className={classes.gridButton}>
          <Grid className={classes.stepperGrid}>
            <Stepper
              className={classes.stepper}
              activeStep={currentIndex}
              alternativeLabel
            >
              {steps.map(({ label }: { label: string }) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Grid>

          <ArrowForwardIosIcon color="primary" className={classes.icon} />
        </Grid>
        <Grid item className={classes.siguientesEspacios}>
          <Grid container className={classes.SiguientesEspaciosContainer}>
            <div className={classes.espacioCurricularParagraph}>
              <p className={classes.paragraph}>
                Espacios curriculares de{' '}
                {
                  selectOptions[currentIndex].planesDestino[0]?.planEstudio
                    ?.descripcionPlanEstudio
                }
              </p>
            </div>
            <div className={classes.espaciosCurricularesParagraph}>
              {selectOptions[currentIndex].planesOrigen.map(
                (espacioCurricular: any, index: any) => (
                  <div className={classes.selects}>
                    <SimpleSelect
                      title=""
                      labelKey="descripcion"
                      valueKey="idEspacioCurricular"
                      content={selectOptions[currentIndex].planesDestino}
                      handleChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        handleChange(e)
                      }
                      value={selectValue(index, espacioCurricular)}
                      name={`${index}`}
                    />
                  </div>
                ),
              )}
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};
