import { useMemo } from 'react';
import { useProyeccionEstadoLibbyFetch } from 'src/app/business';
import { useGeneralContext } from 'src/context/GeneralContext';
import { useRolesContext } from 'src/context/RolesContext';
const booleanToSpanish = (value: boolean) => {
  return value ? 'Si' : 'No';
};

export const useExcelData = (
  columns: any,
  _rows: any,
  name: string,
  periodo: string,
  anioSelect: string,
  seccionSelect: string,
  espacioCurricular: string,
) => {
  const { selectedRole } = useRolesContext();

  const establecimientosFilter = useMemo(
    () => ({
      user: [{ path: 'localizacion', value: selectedRole?.localizacionId }],
    }),
    [selectedRole?.localizacionId],
  );

  const { data: proyeccionEstado = [] }: any = useProyeccionEstadoLibbyFetch({
    filter: establecimientosFilter,
    limit: 1,
    orderBy: 'cicloLectivo',
    direction: 'desc',
  });

  const cicloLectivo = proyeccionEstado[0]?.cicloLectivo?.anio;

  const excelData = useMemo(() => {
    let metadata: Array<string> | [] = [];

    if (anioSelect && seccionSelect) {
      // Se evita repetir el año para las secciones que ya incluyen el año en su descripción
      const seccionConcatAnio = seccionSelect.includes(String(anioSelect))
        ? seccionSelect
        : `${String(anioSelect)} ${seccionSelect}`;

      metadata = [
        periodo,
        `Año ${String(cicloLectivo)}`,
        seccionConcatAnio,
        espacioCurricular,
      ];
    }

    if (_rows && columns) {
      const columsTemp = [...columns];
      if (name.toLowerCase().includes('aspectos generales')) {
        columsTemp.push({
          id: 'otrosApoyos',
          label: 'Otros apoyos',
        });
      }
      return {
        body: {
          metadata,
          columns: columsTemp.map((c: any) => c.label),
          data: _rows.map((row: any) => {
            const columnAlumno = columns.some((columna: any) => {
              return columna?.id === 'alumno';
            });
            let normalizedRow: any;
            if (columnAlumno) {
              normalizedRow = {
                alumno: `${row?.apellido}, ${row?.nombre}`,
              };
            } else {
              normalizedRow = {
                nombre: `${row?.apellido}, ${row?.nombre}`,
              };
            }

            for (const key in row?.nota?.data) {
              normalizedRow[key] = row.nota?.data[key];
            }
            const response: any = {};
            columsTemp.forEach((column: any) => {
              const current = normalizedRow[column.id];
              response[column.label] = Array.isArray(current)
                ? current.join(', ')
                : typeof current === 'boolean'
                ? booleanToSpanish(current)
                : typeof current === 'number'
                ? String(current)
                : current || '';
            });
            return response;
          }),
        },
        name: name,
      };
    }
  }, [
    cicloLectivo,
    anioSelect,
    seccionSelect,
    _rows,
    columns,
    periodo,
    espacioCurricular,
    name,
  ]);

  return { excelData };
};
