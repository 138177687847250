import { Rules } from 'src/lib/permission/types';
import { BLOCKER_ID, TABBAR_BLOCKER_ID } from '../../const';

// route director
export const direccionAreaNoInicial: Rules = {
  idRule: 'rol-10-no-inicial',
  routes: [],
  blockers: [
    { id: BLOCKER_ID.DELETE_SECCIONS },
    { id: BLOCKER_ID.EDIT_SECCION },
    { id: BLOCKER_ID.ESTABLISHMENT_PASS_MANAGEMENT },
    { id: BLOCKER_ID.DELETE_STUDENT },
  ],
  sideMenu: [],
  tabBars: [{ id: TABBAR_BLOCKER_ID.PRESENTISMO_CONFIGURACION }],
};
