import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ReferentesDAO extends LibbyFetchDAO {
  constructor() {
    super('referentes', 'idReferente');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
