import { ModalidadType } from 'src/app/models';

type t = {
  [K in number]: any;
};

export const templates: t = {
  [ModalidadType.General]: [
    {
      idLegajoAlumnoTemplate: 1,
      datosPersonalesTemp: {
        idTemplates: 24,
        name: 'Datos personales',
        template: [
          {
            name: 'datosPrincipales',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'apellidos',
                  type: {
                    name: 'string',
                  },
                  label: 'Apellidos',
                },
                {
                  name: 'nombres',
                  type: {
                    name: 'string',
                  },
                  label: 'Nombres',
                },
                {
                  name: 'tipoDocumento',
                  type: {
                    name: 'number',
                  },
                  label: 'Tipo de Documento',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'documento',
                  type: {
                    name: 'string',
                  },
                  label: 'Número de Documento',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9]+$'],
                    },
                  ],
                },
                {
                  name: 'genero',
                  type: {
                    name: 'number',
                  },
                  label: 'Género',
                  optional: true,
                  validators: [
                    {
                      name: 'min',
                      signature: [-1],
                    },
                  ],
                },
                {
                  name: 'fechaNacimiento',
                  type: {
                    name: 'date',
                  },
                  label: 'Fecha de Nacimiento',
                  validators: [
                    {
                      name: 'less',
                      signature: ['now'],
                    },
                  ],
                },
                {
                  name: 'estadoCivil',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Estado Civil',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'paisNacimiento',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'País de Nacimiento',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'provinciaNacimiento',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Provincia de Nacimiento',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'email',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Correo Electrónico',
                  validators: [
                    {
                      name: 'email',
                      signature: [
                        {
                          tlds: false,
                        },
                      ],
                    },
                  ],
                },
                {
                  name: 'password',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'password',
                },
                {
                  name: 'telefono1',
                  type: {
                    name: 'string',
                  },
                  label: 'Teléfono 1',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefono2',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono 2',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'legajo',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Legajo',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'pbIndigenaPertenece',
                  label: 'Pertenencia Indígena',
                  optional: true,
                  type: {
                    name: 'boolean',
                  },
                },
                {
                  name: 'obsIndigena',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Observaciones',
                },
                {
                  name: 'pbIndigenaHabla',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'pbIndigenaHabla',
                },
              ],
            },
          },
          {
            name: 'datosInstitucion',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'nombreEstablecimiento',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Nombre de Establecimiento',
                },
                {
                  name: 'cue',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'CUE',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'nivel',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Nivel',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z ]+$'],
                    },
                  ],
                },
                {
                  name: 'turno',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Turno',
                },
                {
                  name: 'condicionAnoAnterior',
                  type: {
                    name: 'string',
                  },
                  label: 'Condición del Año Anterior',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                  optional: true,
                },
                {
                  name: 'ano',
                  type: {
                    name: 'number',
                  },
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                  optional: true,
                  label: 'Año',
                },
                {
                  name: 'cicloLectivo',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Ciclo Lectivo',
                  validators: [
                    {
                      name: 'min',
                      signature: [2020],
                    },
                    {
                      name: 'max',
                      signature: [2100],
                    },
                  ],
                },
                {
                  name: 'enrolledAt',
                  type: {
                    name: 'date',
                  },
                  optional: true,
                  label: 'Fecha efectiva de matriculación',
                },
              ],
            },
            optional: true,
          },
        ],
      },
      documentacionTemp: {
        idTemplates: 28,
        name: 'Documentación',
        template: [
          {
            name: 'datosPrincipales',
            type: {
              name: 'array',
              kind: [
                {
                  name: 'tipoDocumento',
                  optional: true,
                  type: {
                    name: 'number',
                  },
                  label: 'Tipo de Documento',
                },
                {
                  name: 'fechaEmision',
                  optional: true,
                  type: {
                    name: 'date',
                  },
                  label: 'Fecha de Emisión',
                },
                {
                  name: 'fechaEntrega',
                  optional: true,
                  type: {
                    name: 'date',
                  },
                  label: 'Fecha de Entrega',
                },
                {
                  name: 'descripcion',
                  optional: true,
                  type: {
                    name: 'string',
                  },
                  label: 'Descripción',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
                {
                  name: 'requiereActualizacion',
                  optional: true,
                  type: {
                    name: 'boolean',
                  },
                  label: 'Requiere Actualización',
                },
                {
                  name: 'url',
                  type: {
                    name: 'string',
                  },
                  label: 'url',
                  optional: true,
                },
                {
                  name: 'file',
                  optional: true,
                  type: {
                    name: 'object',
                    kind: [
                      {
                        name: 'files',
                        type: {
                          name: 'any',
                        },
                      },
                      {
                        name: 'value',
                        type: {
                          name: 'string',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      historialAcademicoTemp: {
        idTemplates: 29,
        name: 'Historial Académico',
        template: [
          {
            name: 'datosPrincipales',
            type: {
              name: 'array',
              kind: [
                {
                  optional: true,
                  name: 'espacioCurricular',
                  type: {
                    name: 'any',
                  },
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  optional: true,
                  name: 'planEstudioMat',
                  type: {
                    name: 'any',
                  },
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  optional: true,
                  name: 'idDeudaAcademica',
                  type: {
                    name: 'any',
                  },
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  optional: true,
                  name: 'motivo',
                  type: {
                    name: 'any',
                  },
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'tipoDeudaAcademica',
                  type: {
                    name: 'any',
                  },
                  optional: true,
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'anio',
                  type: {
                    name: 'any',
                  },
                  optional: true,
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'fecha',
                  type: {
                    name: 'any',
                  },
                  optional: true,
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'apoyo',
                  type: {
                    name: 'any',
                  },
                  optional: true,
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'periodo',
                  type: {
                    name: 'any',
                  },
                  optional: true,
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'valoracion',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'calificacion',
                  type: {
                    name: 'any',
                  },
                  optional: true,
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'cicloLectivo',
                  type: {
                    name: 'any',
                  },
                  optional: true,
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'planEstudioMateria',
                  type: {
                    name: 'any',
                  },
                  optional: true,
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'alumno',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  validators: [
                    {
                      name: 'min',
                      signature: [-1000],
                    },
                  ],
                },
              ],
            },
          },
          {
            optional: true,
            name: 'datosToDelete',
            type: {
              name: 'any',
              kind: [],
            },
          },
          {
            name: 'informeEpa',
            type: {
              name: 'any',
            },
            optional: true,
          },
        ],
      },
      ppiPpsTemp: {
        idTemplates: 30,
        name: 'PPI/PPS',
        template: [],
      },
      residenciaTemp: {
        idTemplates: 25,
        name: 'Residencia',
        template: [
          {
            name: 'tipoDireccion',
            type: {
              name: 'string',
            },
            label: 'Tipo de Dirección',
            optional: true,
            validators: [
              {
                name: 'min',
                signature: [1],
              },
            ],
          },
          {
            name: 'datosResidenciaTipoA',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'tipoDireccion',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Tipo de Dirección',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'provincia',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Provincia',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'localidadBarrio',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Localidad/Barrio',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'codigoPostal',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Código Postal',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'calle',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Calle',
                },
                {
                  name: 'altura',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Altura',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[0-9]+$'],
                    },
                  ],
                },
                {
                  name: 'pisoDpto',
                  type: {
                    name: 'string',
                  },
                  label: 'Piso/Departamento (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefono1',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono 1',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefono2',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono 2 (opcional)',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'observaciones',
                  type: {
                    name: 'string',
                  },
                  label: 'Observaciones (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
              ],
            },
          },
          {
            name: 'datosResidenciaTipoB',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'tipoDireccion',
                  optional: true,
                  type: {
                    name: 'string',
                  },
                  label: 'Tipo de Dirección',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'provincia',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Provincia',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'localidadBarrio',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Localidad/Barrio',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'codigoPostal',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Código Postal',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'calle',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Calle',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefono1',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono 1',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefono2',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono 2 (opcional)',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'observaciones',
                  type: {
                    name: 'string',
                  },
                  label: 'Observaciones (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
                {
                  name: 'manzana',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Manzana',
                },
                {
                  name: 'villa',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Villa',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
      responsablesTemp: {
        idTemplates: 31,
        name: 'Responsables',
        template: [
          {
            name: 'datosPrincipales',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'apellidos',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Apellidos',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z ]+$'],
                    },
                  ],
                },
                {
                  name: 'nombres',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Nombres',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z ]+$'],
                    },
                  ],
                },
                {
                  name: 'tipoDocumento',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Tipo de Documento',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'documento',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Número de Documento',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[0-9]+$'],
                    },
                  ],
                },
                {
                  name: 'genero',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Género',
                  validators: [
                    {
                      name: 'min',
                      signature: [-1],
                    },
                  ],
                },
                {
                  name: 'lazo',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Lazo',
                  validators: [
                    {
                      name: 'min',
                      signature: [-1],
                    },
                  ],
                },
                {
                  name: 'fechaNacimiento',
                  type: {
                    name: 'date',
                  },
                  optional: true,
                  label: 'Fecha de Nacimiento',
                },
                {
                  name: 'estadoCivil',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Estado Civil',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'paisNacimiento',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'País de Nacimiento',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'provinciaNacimiento',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Provincia de Nacimiento',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'email',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Correo Electrónico',
                  validators: [
                    {
                      name: 'email',
                      signature: [
                        {
                          tlds: false,
                        },
                      ],
                    },
                  ],
                },
                {
                  name: 'nivelEducativo',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Nivel Educativo Alcanzado',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'telefonoEmergencias',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono de Emergencias',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9-+ ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefonoOtro',
                  type: {
                    name: 'string',
                  },
                  label: 'Otro Teléfono (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9-+ ]+$'],
                    },
                  ],
                },
                {
                  name: 'tituloEducativo',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Título educativo',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+A-Za-z ]+$'],
                    },
                  ],
                },
                {
                  name: 'adicionales',
                  optional: true,
                  type: {
                    name: 'array',
                    kind: 'number',
                  },
                },
                {
                  name: 'convive',
                  optional: true,
                  type: {
                    name: 'boolean',
                  },
                },
                {
                  name: 'diplomatico',
                  optional: true,
                  type: {
                    name: 'boolean',
                  },
                },
                {
                  name: 'retiraAlumno',
                  optional: true,
                  type: {
                    name: 'boolean',
                  },
                },
                {
                  name: 'contactoEmergencia',
                  optional: true,
                  type: {
                    name: 'boolean',
                  },
                },
              ],
            },
          },
          {
            name: 'datosDomicilio',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'tipoDomicilio',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Tipo de Domicilio',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'provincia',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Provincia',
                },
                {
                  name: 'calle',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Calle',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9. ]+$'],
                    },
                  ],
                },
                {
                  name: 'altura',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Altura',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'piso',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Piso',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'depto',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Departamento',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'codigoPostal',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Código Postal',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefono1',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono 1',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9-+ ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefono2',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono 2 (opcional)',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9-+ ]+$'],
                    },
                  ],
                },
                {
                  name: 'observaciones',
                  type: {
                    name: 'string',
                  },
                  label: 'Observaciones (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
              ],
            },
          },
          {
            name: 'datosLaborales',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'establecimientoEmpresa',
                  type: {
                    name: 'string',
                  },
                  label: 'Establecimiento/Empresa',
                  optional: true,
                },
                {
                  name: 'ocupacion',
                  type: {
                    name: 'string',
                  },
                  label: 'Ocupación',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'desdeHastaHorasLaborales',
                  type: {
                    name: 'string',
                  },
                  label: 'Desde',
                  optional: true,
                },
                {
                  name: 'horasPorDia',
                  type: {
                    name: 'number',
                  },
                  label: 'Horas por Día',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[0-9]+$'],
                    },
                  ],
                },
                {
                  name: 'cantidadDiasSemana',
                  type: {
                    name: 'number',
                  },
                  label: 'Cantidad de dias por semana',
                  optional: true,
                },
                {
                  name: 'tipoDomicilio',
                  type: {
                    name: 'number',
                  },
                  label: 'Tipo de Domicilio',
                  optional: true,
                },
                {
                  name: 'provincia',
                  type: {
                    name: 'number',
                  },
                  label: 'Provincia',
                  optional: true,
                },
                {
                  name: 'calle',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Calle',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9. ]+$'],
                    },
                  ],
                },
                {
                  name: 'altura',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Altura',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'piso',
                  type: {
                    name: 'string',
                  },
                  label: 'Piso (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'depto',
                  type: {
                    name: 'string',
                  },
                  label: 'Departamento (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'codigoPostal',
                  type: {
                    name: 'string',
                  },
                  label: 'Código Postal',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'observaciones',
                  type: {
                    name: 'string',
                  },
                  label: 'Observaciones (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
              ],
            },
          },
          {
            name: 'busqueda',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'buscar',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
      saludTemp: {
        idTemplates: 26,
        name: 'Salud',
        template: [
          {
            name: 'datosPrincipales',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'grupoSanguineo',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Grupo y Factor Sanguíneo',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'calendarioVacunacion',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Calendario de Vacunación',
                },
                {
                  name: 'alergias',
                  type: {
                    name: 'string',
                  },
                  label: 'Alérgias (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'enfermedadesCronicas',
                  type: {
                    name: 'array',
                    kind: 'number',
                  },
                  label: 'El alumno posee enfermedades crónicas',
                  optional: true,
                },
                {
                  name: 'observaciones',
                  type: {
                    name: 'string',
                  },
                  label: 'Observaciones (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
                {
                  name: 'seguimientoDocente',
                  type: {
                    name: 'string',
                  },
                  label: 'Seguimiento Docente (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'tratamientoMedico',
                  type: {
                    name: 'string',
                  },
                  label: 'Tratamiento Médico (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'medicamentos',
                  type: {
                    name: 'string',
                  },
                  label: 'Medicamentos (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'necesidades',
                  type: {
                    name: 'string',
                  },
                  label: 'Necesidades Educativas Especiales (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'actividadesRestringidas',
                  type: {
                    name: 'string',
                  },
                  label: 'Actividades Restringidas por orden médica (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'accionesEmergencia',
                  type: {
                    name: 'string',
                  },
                  label: 'Acciones en Caso de Emergencia (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'observaciones2',
                  type: {
                    name: 'string',
                  },
                  label: 'Observaciones (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
                {
                  name: 'sabeNadar',
                  type: {
                    name: 'boolean',
                  },
                  label: 'Sabe nadar (opcional)',
                  optional: true,
                },
              ],
            },
            optional: true,
          },
          {
            name: 'datosAptoFisico',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'aptoDesde',
                  type: {
                    name: 'date',
                  },
                  label: 'Desde',
                  optional: true,
                },
                {
                  name: 'aptoHasta',
                  type: {
                    name: 'date',
                  },
                  label: 'Hasta',
                  optional: true,
                },
                {
                  name: 'url',
                  type: {
                    name: 'string',
                  },
                  label: 'url',
                  optional: true,
                },
                {
                  name: 'adjuntoNombre',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                },
                {
                  name: 'file',
                  optional: true,
                  type: {
                    name: 'object',
                    kind: [
                      {
                        name: 'files',
                        type: {
                          name: 'any',
                        },
                      },
                      {
                        name: 'value',
                        type: {
                          name: 'string',
                        },
                      },
                    ],
                  },
                },
                {
                  name: 'motivo',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Motivo',
                },
                {
                  name: 'estaEximido',
                  type: {
                    name: 'boolean',
                  },
                  label: 'Está Eximido',
                  optional: true,
                },
              ],
            },
            optional: true,
          },
          {
            name: 'datosCobertura',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'planCobertura',
                  type: {
                    name: 'number',
                  },
                  label: 'Plan',
                  optional: true,
                },
                {
                  name: 'tipoDeCobertura',
                  type: {
                    name: 'string',
                  },
                  label: 'Tipo de Cobertura',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'numeroSocio',
                  type: {
                    name: 'string',
                  },
                  label: 'Número de Socio',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefonoCobertura',
                  type: {
                    name: 'string',
                  },
                  label: 'Teléfono',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
              ],
            },
            optional: true,
          },
          {
            name: 'datosAtencion',
            type: {
              name: 'array',
              kind: [
                {
                  name: 'establecimiento',
                  type: {
                    name: 'string',
                  },
                  label: 'Establecimiento',
                  optional: true,
                },
                {
                  name: 'atencionDesde',
                  type: {
                    name: 'date',
                  },
                  label: 'Desde',
                  optional: true,
                },
                {
                  name: 'atencionHasta',
                  type: {
                    name: 'date',
                  },
                  label: 'Hasta',
                  optional: true,
                },
              ],
            },
            optional: true,
          },
          {
            name: 'datosLose',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'loseDesde',
                  type: {
                    name: 'date',
                  },
                  label: 'Desde',
                  optional: true,
                },
                {
                  name: 'loseHasta',
                  type: {
                    name: 'date',
                  },
                  label: 'Hasta',
                  optional: true,
                },
              ],
            },
            optional: true,
          },
          {
            name: 'datosAcompanante',
            type: {
              name: 'array',
              kind: [
                {
                  name: 'nombres',
                  type: {
                    name: 'string',
                  },
                  label: 'Nombre',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z ]+$'],
                    },
                  ],
                },
                {
                  name: 'apellido',
                  type: {
                    name: 'string',
                  },
                  label: 'Apellido',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefonoAcompanante',
                  type: {
                    name: 'string',
                  },
                  label: 'Teléfono',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'tipoAcompanante',
                  type: {
                    name: 'string',
                  },
                  label: 'Tipo de Acompañante',
                  optional: true,
                },
                {
                  name: 'ocupaVacante',
                  type: {
                    name: 'boolean',
                  },
                  optional: true,
                },
              ],
            },
            optional: true,
          },
        ],
      },
      situacionLegalTemp: {
        idTemplates: 27,
        name: 'Situación legal',
        template: [
          {
            name: 'datosPrincipales',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'tipoDocumento',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Tipo de Documento',
                },
                {
                  name: 'fechaVigencia',
                  type: {
                    name: 'date',
                  },
                  label: 'Desde',
                  optional: true,
                },
                {
                  name: 'tipoSituacion',
                  type: {
                    name: 'string',
                  },
                  label: 'Tipo de Situación',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
                {
                  name: 'nombreCentro',
                  type: {
                    name: 'string',
                  },
                  label: 'Nombre del Centro de Encierro',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'descripcionAdicional1',
                  type: {
                    name: 'string',
                  },
                  label: 'Descripción Adicional',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
              ],
            },
          },
          {
            name: 'datosAdicionales',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'descripcionAdicional2',
                  type: {
                    name: 'string',
                  },
                  label: 'Descripción Adicional',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
  [ModalidadType.EspecialByC]: [
    {
      idLegajoAlumnoTemplate: 1,
      datosPersonalesTemp: {
        idTemplates: 24,
        name: 'Datos personales',
        template: [
          {
            name: 'datosPrincipales',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'apellidos',
                  type: {
                    name: 'string',
                  },
                  label: 'Apellidos',
                },
                {
                  name: 'nombres',
                  type: {
                    name: 'string',
                  },
                  label: 'Nombres',
                },
                {
                  name: 'tipoDocumento',
                  type: {
                    name: 'number',
                  },
                  label: 'Tipo de Documento',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'documento',
                  type: {
                    name: 'string',
                  },
                  label: 'Número de Documento',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[0-9]+$'],
                    },
                  ],
                },
                {
                  name: 'genero',
                  type: {
                    name: 'number',
                  },
                  label: 'Género',
                  optional: true,
                  validators: [
                    {
                      name: 'min',
                      signature: [-1],
                    },
                  ],
                },
                {
                  name: 'fechaNacimiento',
                  type: {
                    name: 'date',
                  },
                  label: 'Fecha de Nacimiento',
                  validators: [
                    {
                      name: 'less',
                      signature: ['now'],
                    },
                  ],
                },
                {
                  name: 'estadoCivil',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Estado Civil',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'paisNacimiento',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'País de Nacimiento',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'provinciaNacimiento',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Provincia de Nacimiento',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'email',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Correo Electrónico',
                  validators: [
                    {
                      name: 'email',
                      signature: [
                        {
                          tlds: false,
                        },
                      ],
                    },
                  ],
                },
                {
                  name: 'password',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'password',
                },
                {
                  name: 'telefono1',
                  type: {
                    name: 'string',
                  },
                  label: 'Teléfono 1',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefono2',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono 2',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'legajo',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Legajo',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'pbIndigenaPertenece',
                  label: 'Pertenencia Indígena',
                  optional: true,
                  type: {
                    name: 'boolean',
                  },
                },
                {
                  name: 'obsIndigena',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Observaciones',
                },
                {
                  name: 'pbIndigenaHabla',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'pbIndigenaHabla',
                },
              ],
            },
          },
          {
            name: 'datosInstitucion',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'nombreEstablecimiento',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Nombre de Establecimiento',
                },
                {
                  name: 'cue',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'CUE',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'nivel',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Nivel',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z ]+$'],
                    },
                  ],
                },
                {
                  name: 'turno',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Turno',
                },
                {
                  name: 'condicionAnoAnterior',
                  type: {
                    name: 'string',
                  },
                  label: 'Condición del Año Anterior',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                  optional: true,
                },
                {
                  name: 'ciclo',
                  type: {
                    name: 'number',
                  },
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                  optional: true,
                  label: 'Ciclo',
                },
                {
                  name: 'cicloLectivo',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Ciclo Lectivo',
                  validators: [
                    {
                      name: 'min',
                      signature: [2020],
                    },
                    {
                      name: 'max',
                      signature: [2100],
                    },
                  ],
                },
                {
                  name: 'enrolledAt',
                  type: {
                    name: 'date',
                  },
                  optional: true,
                  label: 'Fecha efectiva de matriculación',
                },
              ],
            },
            optional: true,
          },
        ],
      },
      documentacionTemp: {
        idTemplates: 28,
        name: 'Documentación',
        template: [
          {
            name: 'datosPrincipales',
            type: {
              name: 'array',
              kind: [
                {
                  name: 'tipoDocumento',
                  optional: true,
                  type: {
                    name: 'number',
                  },
                  label: 'Tipo de Documento',
                },
                {
                  name: 'fechaEmision',
                  optional: true,
                  type: {
                    name: 'date',
                  },
                  label: 'Fecha de Emisión',
                },
                {
                  name: 'fechaEntrega',
                  optional: true,
                  type: {
                    name: 'date',
                  },
                  label: 'Fecha de Entrega',
                },
                {
                  name: 'descripcion',
                  optional: true,
                  type: {
                    name: 'string',
                  },
                  label: 'Descripción',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
                {
                  name: 'requiereActualizacion',
                  optional: true,
                  type: {
                    name: 'boolean',
                  },
                  label: 'Requiere Actualización',
                },
                {
                  name: 'url',
                  type: {
                    name: 'string',
                  },
                  label: 'url',
                  optional: true,
                },
                {
                  name: 'file',
                  optional: true,
                  type: {
                    name: 'object',
                    kind: [
                      {
                        name: 'files',
                        type: {
                          name: 'any',
                        },
                      },
                      {
                        name: 'value',
                        type: {
                          name: 'string',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      ppiPpsTemp: {
        idTemplates: 30,
        name: 'PPI/PPS',
        template: [],
      },
      residenciaTemp: {
        idTemplates: 25,
        name: 'Residencia',
        template: [
          {
            name: 'tipoDireccion',
            type: {
              name: 'string',
            },
            label: 'Tipo de Dirección',
            optional: true,
            validators: [
              {
                name: 'min',
                signature: [1],
              },
            ],
          },
          {
            name: 'datosResidenciaTipoA',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'tipoDireccion',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Tipo de Dirección',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'provincia',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Provincia',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'localidadBarrio',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Localidad/Barrio',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'codigoPostal',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Código Postal',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'calle',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Calle',
                },
                {
                  name: 'altura',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Altura',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[0-9]+$'],
                    },
                  ],
                },
                {
                  name: 'pisoDpto',
                  type: {
                    name: 'string',
                  },
                  label: 'Piso/Departamento (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefono1',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono 1',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefono2',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono 2 (opcional)',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'observaciones',
                  type: {
                    name: 'string',
                  },
                  label: 'Observaciones (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
              ],
            },
          },
          {
            name: 'datosResidenciaTipoB',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'tipoDireccion',
                  optional: true,
                  type: {
                    name: 'string',
                  },
                  label: 'Tipo de Dirección',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'provincia',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Provincia',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'localidadBarrio',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Localidad/Barrio',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'codigoPostal',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Código Postal',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'calle',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Calle',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefono1',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono 1',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefono2',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono 2 (opcional)',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'observaciones',
                  type: {
                    name: 'string',
                  },
                  label: 'Observaciones (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
                {
                  name: 'manzana',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Manzana',
                },
                {
                  name: 'villa',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Villa',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
      responsablesTemp: {
        idTemplates: 31,
        name: 'Responsables',
        template: [
          {
            name: 'datosPrincipales',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'apellidos',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Apellidos',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z ]+$'],
                    },
                  ],
                },
                {
                  name: 'nombres',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Nombres',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z ]+$'],
                    },
                  ],
                },
                {
                  name: 'tipoDocumento',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Tipo de Documento',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'documento',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Número de Documento',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[0-9]+$'],
                    },
                  ],
                },
                {
                  name: 'genero',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Género',
                  validators: [
                    {
                      name: 'min',
                      signature: [-1],
                    },
                  ],
                },
                {
                  name: 'lazo',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Lazo',
                  validators: [
                    {
                      name: 'min',
                      signature: [-1],
                    },
                  ],
                },
                {
                  name: 'fechaNacimiento',
                  type: {
                    name: 'date',
                  },
                  optional: true,
                  label: 'Fecha de Nacimiento',
                },
                {
                  name: 'estadoCivil',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Estado Civil',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'paisNacimiento',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'País de Nacimiento',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'provinciaNacimiento',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Provincia de Nacimiento',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'email',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Correo Electrónico',
                  validators: [
                    {
                      name: 'email',
                      signature: [
                        {
                          tlds: false,
                        },
                      ],
                    },
                  ],
                },
                {
                  name: 'nivelEducativo',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Nivel Educativo Alcanzado',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'telefonoEmergencias',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono de Emergencias',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9-+ ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefonoOtro',
                  type: {
                    name: 'string',
                  },
                  label: 'Otro Teléfono (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9-+ ]+$'],
                    },
                  ],
                },
                {
                  name: 'tituloEducativo',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Título educativo',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+A-Za-z ]+$'],
                    },
                  ],
                },
                {
                  name: 'adicionales',
                  optional: true,
                  type: {
                    name: 'array',
                    kind: 'number',
                  },
                },
                {
                  name: 'convive',
                  optional: true,
                  type: {
                    name: 'boolean',
                  },
                },
                {
                  name: 'diplomatico',
                  optional: true,
                  type: {
                    name: 'boolean',
                  },
                },
                {
                  name: 'retiraAlumno',
                  optional: true,
                  type: {
                    name: 'boolean',
                  },
                },
                {
                  name: 'contactoEmergencia',
                  optional: true,
                  type: {
                    name: 'boolean',
                  },
                },
              ],
            },
          },
          {
            name: 'datosDomicilio',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'tipoDomicilio',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Tipo de Domicilio',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'provincia',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Provincia',
                },
                {
                  name: 'calle',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Calle',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9. ]+$'],
                    },
                  ],
                },
                {
                  name: 'altura',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Altura',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'piso',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Piso',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'depto',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Departamento',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'codigoPostal',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Código Postal',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefono1',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono 1',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9-+ ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefono2',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono 2 (opcional)',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9-+ ]+$'],
                    },
                  ],
                },
                {
                  name: 'observaciones',
                  type: {
                    name: 'string',
                  },
                  label: 'Observaciones (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
              ],
            },
          },
          {
            name: 'datosLaborales',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'establecimientoEmpresa',
                  type: {
                    name: 'string',
                  },
                  label: 'Establecimiento/Empresa',
                  optional: true,
                },
                {
                  name: 'ocupacion',
                  type: {
                    name: 'string',
                  },
                  label: 'Ocupación',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'desdeHastaHorasLaborales',
                  type: {
                    name: 'string',
                  },
                  label: 'Desde',
                  optional: true,
                },
                {
                  name: 'horasPorDia',
                  type: {
                    name: 'number',
                  },
                  label: 'Horas por Día',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[0-9]+$'],
                    },
                  ],
                },
                {
                  name: 'cantidadDiasSemana',
                  type: {
                    name: 'number',
                  },
                  label: 'Cantidad de dias por semana',
                  optional: true,
                },
                {
                  name: 'tipoDomicilio',
                  type: {
                    name: 'number',
                  },
                  label: 'Tipo de Domicilio',
                  optional: true,
                },
                {
                  name: 'provincia',
                  type: {
                    name: 'number',
                  },
                  label: 'Provincia',
                  optional: true,
                },
                {
                  name: 'calle',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Calle',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9. ]+$'],
                    },
                  ],
                },
                {
                  name: 'altura',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Altura',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'piso',
                  type: {
                    name: 'string',
                  },
                  label: 'Piso (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'depto',
                  type: {
                    name: 'string',
                  },
                  label: 'Departamento (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'codigoPostal',
                  type: {
                    name: 'string',
                  },
                  label: 'Código Postal',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'observaciones',
                  type: {
                    name: 'string',
                  },
                  label: 'Observaciones (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
              ],
            },
          },
          {
            name: 'busqueda',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'buscar',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
      saludTemp: {
        idTemplates: 26,
        name: 'Salud',
        template: [
          {
            name: 'datosPrincipales',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'grupoSanguineo',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Grupo y Factor Sanguíneo',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'calendarioVacunacion',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Calendario de Vacunación',
                },
                {
                  name: 'alergias',
                  type: {
                    name: 'string',
                  },
                  label: 'Alérgias (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'enfermedadesCronicas',
                  type: {
                    name: 'array',
                    kind: 'number',
                  },
                  label: 'El alumno posee enfermedades crónicas',
                  optional: true,
                },
                {
                  name: 'observaciones',
                  type: {
                    name: 'string',
                  },
                  label: 'Observaciones (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
                {
                  name: 'seguimientoDocente',
                  type: {
                    name: 'string',
                  },
                  label: 'Seguimiento Docente (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'tratamientoMedico',
                  type: {
                    name: 'string',
                  },
                  label: 'Tratamiento Médico (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'medicamentos',
                  type: {
                    name: 'string',
                  },
                  label: 'Medicamentos (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'necesidades',
                  type: {
                    name: 'string',
                  },
                  label: 'Necesidades Educativas Especiales (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'actividadesRestringidas',
                  type: {
                    name: 'string',
                  },
                  label: 'Actividades Restringidas por orden médica (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'accionesEmergencia',
                  type: {
                    name: 'string',
                  },
                  label: 'Acciones en Caso de Emergencia (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'observaciones2',
                  type: {
                    name: 'string',
                  },
                  label: 'Observaciones (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
                {
                  name: 'sabeNadar',
                  type: {
                    name: 'boolean',
                  },
                  label: 'Sabe nadar (opcional)',
                  optional: true,
                },
              ],
            },
            optional: true,
          },
          {
            name: 'datosAptoFisico',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'aptoDesde',
                  type: {
                    name: 'date',
                  },
                  label: 'Desde',
                  optional: true,
                },
                {
                  name: 'aptoHasta',
                  type: {
                    name: 'date',
                  },
                  label: 'Hasta',
                  optional: true,
                },
                {
                  name: 'url',
                  type: {
                    name: 'string',
                  },
                  label: 'url',
                  optional: true,
                },
                {
                  name: 'adjuntoNombre',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                },
                {
                  name: 'file',
                  optional: true,
                  type: {
                    name: 'object',
                    kind: [
                      {
                        name: 'files',
                        type: {
                          name: 'any',
                        },
                      },
                      {
                        name: 'value',
                        type: {
                          name: 'string',
                        },
                      },
                    ],
                  },
                },
                {
                  name: 'motivo',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Motivo',
                },
                {
                  name: 'estaEximido',
                  type: {
                    name: 'boolean',
                  },
                  label: 'Está Eximido',
                  optional: true,
                },
              ],
            },
            optional: true,
          },
          {
            name: 'datosCobertura',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'planCobertura',
                  type: {
                    name: 'number',
                  },
                  label: 'Plan',
                  optional: true,
                },
                {
                  name: 'tipoDeCobertura',
                  type: {
                    name: 'string',
                  },
                  label: 'Tipo de Cobertura',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'numeroSocio',
                  type: {
                    name: 'string',
                  },
                  label: 'Número de Socio',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefonoCobertura',
                  type: {
                    name: 'string',
                  },
                  label: 'Teléfono',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
              ],
            },
            optional: true,
          },
          {
            name: 'datosAtencion',
            type: {
              name: 'array',
              kind: [
                {
                  name: 'establecimiento',
                  type: {
                    name: 'string',
                  },
                  label: 'Establecimiento',
                  optional: true,
                },
                {
                  name: 'atencionDesde',
                  type: {
                    name: 'date',
                  },
                  label: 'Desde',
                  optional: true,
                },
                {
                  name: 'atencionHasta',
                  type: {
                    name: 'date',
                  },
                  label: 'Hasta',
                  optional: true,
                },
              ],
            },
            optional: true,
          },
          {
            name: 'datosLose',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'loseDesde',
                  type: {
                    name: 'date',
                  },
                  label: 'Desde',
                  optional: true,
                },
                {
                  name: 'loseHasta',
                  type: {
                    name: 'date',
                  },
                  label: 'Hasta',
                  optional: true,
                },
              ],
            },
            optional: true,
          },
          {
            name: 'datosAcompanante',
            type: {
              name: 'array',
              kind: [
                {
                  name: 'nombres',
                  type: {
                    name: 'string',
                  },
                  label: 'Nombre',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z ]+$'],
                    },
                  ],
                },
                {
                  name: 'apellido',
                  type: {
                    name: 'string',
                  },
                  label: 'Apellido',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefonoAcompanante',
                  type: {
                    name: 'string',
                  },
                  label: 'Teléfono',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'tipoAcompanante',
                  type: {
                    name: 'string',
                  },
                  label: 'Tipo de Acompañante',
                  optional: true,
                },
                {
                  name: 'ocupaVacante',
                  type: {
                    name: 'boolean',
                  },
                  optional: true,
                },
              ],
            },
            optional: true,
          },
        ],
      },
      situacionLegalTemp: {
        idTemplates: 27,
        name: 'Situación legal',
        template: [
          {
            name: 'datosPrincipales',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'tipoDocumento',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Tipo de Documento',
                },
                {
                  name: 'fechaVigencia',
                  type: {
                    name: 'date',
                  },
                  label: 'Desde',
                  optional: true,
                },
                {
                  name: 'tipoSituacion',
                  type: {
                    name: 'string',
                  },
                  label: 'Tipo de Situación',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
                {
                  name: 'nombreCentro',
                  type: {
                    name: 'string',
                  },
                  label: 'Nombre del Centro de Encierro',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'descripcionAdicional1',
                  type: {
                    name: 'string',
                  },
                  label: 'Descripción Adicional',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
              ],
            },
          },
          {
            name: 'datosAdicionales',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'descripcionAdicional2',
                  type: {
                    name: 'string',
                  },
                  label: 'Descripción Adicional',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
  [ModalidadType.Adultos]: [
    {
      idLegajoAlumnoTemplate: 1,
      datosPersonalesTemp: {
        idTemplates: 24,
        name: 'Datos personales',
        template: [
          {
            name: 'datosPrincipales',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'apellidos',
                  type: {
                    name: 'string',
                  },
                  label: 'Apellidos',
                },
                {
                  name: 'nombres',
                  type: {
                    name: 'string',
                  },
                  label: 'Nombres',
                },
                {
                  name: 'tipoDocumento',
                  type: {
                    name: 'number',
                  },
                  label: 'Tipo de Documento',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'documento',
                  type: {
                    name: 'string',
                  },
                  label: 'Número de Documento',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[0-9]+$'],
                    },
                  ],
                },
                {
                  name: 'genero',
                  type: {
                    name: 'number',
                  },
                  label: 'Género',
                  optional: true,
                  validators: [
                    {
                      name: 'min',
                      signature: [-1],
                    },
                  ],
                },
                {
                  name: 'fechaNacimiento',
                  type: {
                    name: 'date',
                  },
                  label: 'Fecha de Nacimiento',
                  validators: [
                    {
                      name: 'less',
                      signature: ['now'],
                    },
                  ],
                },
                {
                  name: 'estadoCivil',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Estado Civil',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'paisNacimiento',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'País de Nacimiento',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'provinciaNacimiento',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Provincia de Nacimiento',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'email',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Correo Electrónico',
                  validators: [
                    {
                      name: 'email',
                      signature: [
                        {
                          tlds: false,
                        },
                      ],
                    },
                  ],
                },
                {
                  name: 'password',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'password',
                },
                {
                  name: 'telefono1',
                  type: {
                    name: 'string',
                  },
                  label: 'Teléfono 1',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefono2',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono 2',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'legajo',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Legajo',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'pbIndigenaPertenece',
                  label: 'Pertenencia Indígena',
                  optional: true,
                  type: {
                    name: 'boolean',
                  },
                },
                {
                  name: 'obsIndigena',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Observaciones',
                },
                {
                  name: 'pbIndigenaHabla',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'pbIndigenaHabla',
                },
              ],
            },
          },
          {
            name: 'datosInstitucion',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'nombreEstablecimiento',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Nombre de Establecimiento',
                },
                {
                  name: 'cue',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'CUE',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'nivel',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Nivel',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z ]+$'],
                    },
                  ],
                },
                {
                  name: 'turno',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Turno',
                },
                {
                  name: 'condicionAnoAnterior',
                  type: {
                    name: 'string',
                  },
                  label: 'Condición del Año Anterior',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                  optional: true,
                },
                {
                  name: 'cicloAdultos',
                  type: {
                    name: 'number',
                  },
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                  optional: true,
                  label: 'Ciclo Adultos',
                },
                {
                  name: 'cicloLectivo',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Ciclo Lectivo',
                  validators: [
                    {
                      name: 'min',
                      signature: [2020],
                    },
                    {
                      name: 'max',
                      signature: [2100],
                    },
                  ],
                },
                {
                  name: 'enrolledAt',
                  type: {
                    name: 'date',
                  },
                  optional: true,
                  label: 'Fecha efectiva de matriculación',
                },
              ],
            },
            optional: true,
          },
        ],
      },
      documentacionTemp: {
        idTemplates: 28,
        name: 'Documentación',
        template: [
          {
            name: 'datosPrincipales',
            type: {
              name: 'array',
              kind: [
                {
                  name: 'tipoDocumento',
                  optional: true,
                  type: {
                    name: 'number',
                  },
                  label: 'Tipo de Documento',
                },
                {
                  name: 'fechaEmision',
                  optional: true,
                  type: {
                    name: 'date',
                  },
                  label: 'Fecha de Emisión',
                },
                {
                  name: 'fechaEntrega',
                  optional: true,
                  type: {
                    name: 'date',
                  },
                  label: 'Fecha de Entrega',
                },
                {
                  name: 'descripcion',
                  optional: true,
                  type: {
                    name: 'string',
                  },
                  label: 'Descripción',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
                {
                  name: 'requiereActualizacion',
                  optional: true,
                  type: {
                    name: 'boolean',
                  },
                  label: 'Requiere Actualización',
                },
                {
                  name: 'url',
                  type: {
                    name: 'string',
                  },
                  label: 'url',
                  optional: true,
                },
                {
                  name: 'file',
                  optional: true,
                  type: {
                    name: 'object',
                    kind: [
                      {
                        name: 'files',
                        type: {
                          name: 'any',
                        },
                      },
                      {
                        name: 'value',
                        type: {
                          name: 'string',
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      ppiPpsTemp: {
        idTemplates: 30,
        name: 'PPI/PPS',
        template: [],
      },
      residenciaTemp: {
        idTemplates: 25,
        name: 'Residencia',
        template: [
          {
            name: 'tipoDireccion',
            type: {
              name: 'string',
            },
            label: 'Tipo de Dirección',
            optional: true,
            validators: [
              {
                name: 'min',
                signature: [1],
              },
            ],
          },
          {
            name: 'datosResidenciaTipoA',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'tipoDireccion',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Tipo de Dirección',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'provincia',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Provincia',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'localidadBarrio',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Localidad/Barrio',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'codigoPostal',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Código Postal',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'calle',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Calle',
                },
                {
                  name: 'altura',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Altura',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[0-9]+$'],
                    },
                  ],
                },
                {
                  name: 'pisoDpto',
                  type: {
                    name: 'string',
                  },
                  label: 'Piso/Departamento (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefono1',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono 1',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefono2',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono 2 (opcional)',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'observaciones',
                  type: {
                    name: 'string',
                  },
                  label: 'Observaciones (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
              ],
            },
          },
          {
            name: 'datosResidenciaTipoB',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'tipoDireccion',
                  optional: true,
                  type: {
                    name: 'string',
                  },
                  label: 'Tipo de Dirección',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'provincia',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Provincia',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'localidadBarrio',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Localidad/Barrio',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'codigoPostal',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Código Postal',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'calle',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Calle',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefono1',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono 1',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefono2',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono 2 (opcional)',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'observaciones',
                  type: {
                    name: 'string',
                  },
                  label: 'Observaciones (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
                {
                  name: 'manzana',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Manzana',
                },
                {
                  name: 'villa',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Villa',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
      responsablesTemp: {
        idTemplates: 31,
        name: 'Responsables',
        template: [
          {
            name: 'datosPrincipales',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'apellidos',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Apellidos',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z ]+$'],
                    },
                  ],
                },
                {
                  name: 'nombres',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Nombres',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z ]+$'],
                    },
                  ],
                },
                {
                  name: 'tipoDocumento',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Tipo de Documento',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'documento',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Número de Documento',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[0-9]+$'],
                    },
                  ],
                },
                {
                  name: 'genero',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Género',
                  validators: [
                    {
                      name: 'min',
                      signature: [-1],
                    },
                  ],
                },
                {
                  name: 'lazo',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Lazo',
                  validators: [
                    {
                      name: 'min',
                      signature: [-1],
                    },
                  ],
                },
                {
                  name: 'fechaNacimiento',
                  type: {
                    name: 'date',
                  },
                  optional: true,
                  label: 'Fecha de Nacimiento',
                },
                {
                  name: 'estadoCivil',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Estado Civil',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'paisNacimiento',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'País de Nacimiento',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'provinciaNacimiento',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Provincia de Nacimiento',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'email',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Correo Electrónico',
                  validators: [
                    {
                      name: 'email',
                      signature: [
                        {
                          tlds: false,
                        },
                      ],
                    },
                  ],
                },
                {
                  name: 'nivelEducativo',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Nivel Educativo Alcanzado',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'telefonoEmergencias',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono de Emergencias',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9-+ ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefonoOtro',
                  type: {
                    name: 'string',
                  },
                  label: 'Otro Teléfono (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9-+ ]+$'],
                    },
                  ],
                },
                {
                  name: 'tituloEducativo',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Título educativo',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+A-Za-z ]+$'],
                    },
                  ],
                },
                {
                  name: 'adicionales',
                  optional: true,
                  type: {
                    name: 'array',
                    kind: 'number',
                  },
                },
                {
                  name: 'convive',
                  optional: true,
                  type: {
                    name: 'boolean',
                  },
                },
                {
                  name: 'diplomatico',
                  optional: true,
                  type: {
                    name: 'boolean',
                  },
                },
                {
                  name: 'retiraAlumno',
                  optional: true,
                  type: {
                    name: 'boolean',
                  },
                },
                {
                  name: 'contactoEmergencia',
                  optional: true,
                  type: {
                    name: 'boolean',
                  },
                },
              ],
            },
          },
          {
            name: 'datosDomicilio',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'tipoDomicilio',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Tipo de Domicilio',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'provincia',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Provincia',
                },
                {
                  name: 'calle',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Calle',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9. ]+$'],
                    },
                  ],
                },
                {
                  name: 'altura',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Altura',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'piso',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Piso',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'depto',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Departamento',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'codigoPostal',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Código Postal',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefono1',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono 1',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9-+ ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefono2',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Teléfono 2 (opcional)',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9-+ ]+$'],
                    },
                  ],
                },
                {
                  name: 'observaciones',
                  type: {
                    name: 'string',
                  },
                  label: 'Observaciones (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
              ],
            },
          },
          {
            name: 'datosLaborales',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'establecimientoEmpresa',
                  type: {
                    name: 'string',
                  },
                  label: 'Establecimiento/Empresa',
                  optional: true,
                },
                {
                  name: 'ocupacion',
                  type: {
                    name: 'string',
                  },
                  label: 'Ocupación',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'desdeHastaHorasLaborales',
                  type: {
                    name: 'string',
                  },
                  label: 'Desde',
                  optional: true,
                },
                {
                  name: 'horasPorDia',
                  type: {
                    name: 'number',
                  },
                  label: 'Horas por Día',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[0-9]+$'],
                    },
                  ],
                },
                {
                  name: 'cantidadDiasSemana',
                  type: {
                    name: 'number',
                  },
                  label: 'Cantidad de dias por semana',
                  optional: true,
                },
                {
                  name: 'tipoDomicilio',
                  type: {
                    name: 'number',
                  },
                  label: 'Tipo de Domicilio',
                  optional: true,
                },
                {
                  name: 'provincia',
                  type: {
                    name: 'number',
                  },
                  label: 'Provincia',
                  optional: true,
                },
                {
                  name: 'calle',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Calle',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9. ]+$'],
                    },
                  ],
                },
                {
                  name: 'altura',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Altura',
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'piso',
                  type: {
                    name: 'string',
                  },
                  label: 'Piso (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'depto',
                  type: {
                    name: 'string',
                  },
                  label: 'Departamento (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'codigoPostal',
                  type: {
                    name: 'string',
                  },
                  label: 'Código Postal',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'observaciones',
                  type: {
                    name: 'string',
                  },
                  label: 'Observaciones (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
              ],
            },
          },
          {
            name: 'busqueda',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'buscar',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
      saludTemp: {
        idTemplates: 26,
        name: 'Salud',
        template: [
          {
            name: 'datosPrincipales',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'grupoSanguineo',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Grupo y Factor Sanguíneo',
                  validators: [
                    {
                      name: 'min',
                      signature: [1],
                    },
                  ],
                },
                {
                  name: 'calendarioVacunacion',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Calendario de Vacunación',
                },
                {
                  name: 'alergias',
                  type: {
                    name: 'string',
                  },
                  label: 'Alérgias (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'enfermedadesCronicas',
                  type: {
                    name: 'array',
                    kind: 'number',
                  },
                  label: 'El alumno posee enfermedades crónicas',
                  optional: true,
                },
                {
                  name: 'observaciones',
                  type: {
                    name: 'string',
                  },
                  label: 'Observaciones (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
                {
                  name: 'seguimientoDocente',
                  type: {
                    name: 'string',
                  },
                  label: 'Seguimiento Docente (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'tratamientoMedico',
                  type: {
                    name: 'string',
                  },
                  label: 'Tratamiento Médico (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'medicamentos',
                  type: {
                    name: 'string',
                  },
                  label: 'Medicamentos (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'necesidades',
                  type: {
                    name: 'string',
                  },
                  label: 'Necesidades Educativas Especiales (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'actividadesRestringidas',
                  type: {
                    name: 'string',
                  },
                  label: 'Actividades Restringidas por orden médica (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'accionesEmergencia',
                  type: {
                    name: 'string',
                  },
                  label: 'Acciones en Caso de Emergencia (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'observaciones2',
                  type: {
                    name: 'string',
                  },
                  label: 'Observaciones (opcional)',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
                {
                  name: 'sabeNadar',
                  type: {
                    name: 'boolean',
                  },
                  label: 'Sabe nadar (opcional)',
                  optional: true,
                },
              ],
            },
            optional: true,
          },
          {
            name: 'datosAptoFisico',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'aptoDesde',
                  type: {
                    name: 'date',
                  },
                  label: 'Desde',
                  optional: true,
                },
                {
                  name: 'aptoHasta',
                  type: {
                    name: 'date',
                  },
                  label: 'Hasta',
                  optional: true,
                },
                {
                  name: 'url',
                  type: {
                    name: 'string',
                  },
                  label: 'url',
                  optional: true,
                },
                {
                  name: 'adjuntoNombre',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                },
                {
                  name: 'file',
                  optional: true,
                  type: {
                    name: 'object',
                    kind: [
                      {
                        name: 'files',
                        type: {
                          name: 'any',
                        },
                      },
                      {
                        name: 'value',
                        type: {
                          name: 'string',
                        },
                      },
                    ],
                  },
                },
                {
                  name: 'motivo',
                  type: {
                    name: 'string',
                  },
                  optional: true,
                  label: 'Motivo',
                },
                {
                  name: 'estaEximido',
                  type: {
                    name: 'boolean',
                  },
                  label: 'Está Eximido',
                  optional: true,
                },
              ],
            },
            optional: true,
          },
          {
            name: 'datosCobertura',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'planCobertura',
                  type: {
                    name: 'number',
                  },
                  label: 'Plan',
                  optional: true,
                },
                {
                  name: 'tipoDeCobertura',
                  type: {
                    name: 'string',
                  },
                  label: 'Tipo de Cobertura',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'numeroSocio',
                  type: {
                    name: 'string',
                  },
                  label: 'Número de Socio',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefonoCobertura',
                  type: {
                    name: 'string',
                  },
                  label: 'Teléfono',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
              ],
            },
            optional: true,
          },
          {
            name: 'datosAtencion',
            type: {
              name: 'array',
              kind: [
                {
                  name: 'establecimiento',
                  type: {
                    name: 'string',
                  },
                  label: 'Establecimiento',
                  optional: true,
                },
                {
                  name: 'atencionDesde',
                  type: {
                    name: 'date',
                  },
                  label: 'Desde',
                  optional: true,
                },
                {
                  name: 'atencionHasta',
                  type: {
                    name: 'date',
                  },
                  label: 'Hasta',
                  optional: true,
                },
              ],
            },
            optional: true,
          },
          {
            name: 'datosLose',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'loseDesde',
                  type: {
                    name: 'date',
                  },
                  label: 'Desde',
                  optional: true,
                },
                {
                  name: 'loseHasta',
                  type: {
                    name: 'date',
                  },
                  label: 'Hasta',
                  optional: true,
                },
              ],
            },
            optional: true,
          },
          {
            name: 'datosAcompanante',
            type: {
              name: 'array',
              kind: [
                {
                  name: 'nombres',
                  type: {
                    name: 'string',
                  },
                  label: 'Nombre',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z ]+$'],
                    },
                  ],
                },
                {
                  name: 'apellido',
                  type: {
                    name: 'string',
                  },
                  label: 'Apellido',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z ]+$'],
                    },
                  ],
                },
                {
                  name: 'telefonoAcompanante',
                  type: {
                    name: 'string',
                  },
                  label: 'Teléfono',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[+0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'tipoAcompanante',
                  type: {
                    name: 'string',
                  },
                  label: 'Tipo de Acompañante',
                  optional: true,
                },
                {
                  name: 'ocupaVacante',
                  type: {
                    name: 'boolean',
                  },
                  optional: true,
                },
              ],
            },
            optional: true,
          },
        ],
      },
      situacionLegalTemp: {
        idTemplates: 27,
        name: 'Situación legal',
        template: [
          {
            name: 'datosPrincipales',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'tipoDocumento',
                  type: {
                    name: 'number',
                  },
                  optional: true,
                  label: 'Tipo de Documento',
                },
                {
                  name: 'fechaVigencia',
                  type: {
                    name: 'date',
                  },
                  label: 'Desde',
                  optional: true,
                },
                {
                  name: 'tipoSituacion',
                  type: {
                    name: 'string',
                  },
                  label: 'Tipo de Situación',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
                {
                  name: 'nombreCentro',
                  type: {
                    name: 'string',
                  },
                  label: 'Nombre del Centro de Encierro',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9 ]+$'],
                    },
                  ],
                },
                {
                  name: 'descripcionAdicional1',
                  type: {
                    name: 'string',
                  },
                  label: 'Descripción Adicional',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
              ],
            },
          },
          {
            name: 'datosAdicionales',
            type: {
              name: 'object',
              kind: [
                {
                  name: 'descripcionAdicional2',
                  type: {
                    name: 'string',
                  },
                  label: 'Descripción Adicional',
                  optional: true,
                  validators: [
                    {
                      name: 'pattern',
                      signature: ['^[A-Za-z0-9!-/¿!¡? ]+$'],
                    },
                  ],
                },
              ],
            },
          },
        ],
      },
    },
  ],
};
