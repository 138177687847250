import React from 'react';
import { makeStyles, Typography, Grid } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
    border: '1px solid #ccc',
    borderRadius: '5px',
  },
  textarea: {
    padding: theme.spacing(1.5),
  },
}));

export const SinPublicaciones = () => {
  const classes = useStyles();
  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Grid item>
        <Typography variant="h1" className={classes.textarea}>
          Aún no hay publicaciones
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.textarea}>
          Compartí contenido con tus contactos y empezá a interactuar
        </Typography>
      </Grid>
    </Grid>
  );
};
