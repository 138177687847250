import { level } from 'src/commons/const';
import {
  MAPPED_PASE_ANIO_OPTIONS_PRIMARIO,
  MAPPED_PASE_ANIO_OPTIONS_SECUNDARIO,
} from './mappedPaseAnioOptions';

export const inputDisableLogic = ({
  row,
  idEstadoDeLaProyeccion,
  nivel,
  inputId,
}: {
  row: any;
  idEstadoDeLaProyeccion: number;
  nivel: number;
  inputId: string;
}) => {
  let disabled = false;

  const isProyeccionFinalizada = idEstadoDeLaProyeccion === 2;
  const { statusPass, sectionPass, estadoProyeccionMotivo, observacion } = row;

  if (nivel === level.INICIAL) {
    switch (inputId) {
      case 'passStatus':
        disabled = isProyeccionFinalizada;
        return disabled;
      case 'passSection':
        disabled = [3, 7].includes(statusPass);
        return disabled;
      case 'motivo':
        disabled = !sectionPass || sectionPass !== 'No Concurrirá';
        return disabled;
      default:
        disabled = false;
        return disabled;
    }
  }

  if (nivel === level.PRIMARIO) {
    const {
      Egresa: EGRESA,
      'Promoción en proceso': PROMOCION_EN_PROCESO,
      'Continúa con apoyo para la promoción': CONTINUA_CON_APOYO_PARA_PROMOCION,
      'Promoción directa': PROMOCION_DIRECTA,
    } = MAPPED_PASE_ANIO_OPTIONS_PRIMARIO;

    switch (inputId) {
      case 'passStatus':
        disabled =
          sectionPass === 'Articulado' ||
          (isProyeccionFinalizada &&
            statusPass !== CONTINUA_CON_APOYO_PARA_PROMOCION.id);
        return disabled;
      case 'passSection':
        disabled =
          !statusPass ||
          statusPass === EGRESA.id ||
          statusPass === PROMOCION_EN_PROCESO.id ||
          (isProyeccionFinalizada && statusPass === PROMOCION_DIRECTA.id) ||
          sectionPass === 'Articulado';
        return disabled;
      case 'motivo':
        disabled =
          !sectionPass ||
          (sectionPass === 'No Concurrirá' && statusPass === EGRESA.id) ||
          sectionPass !== 'No Concurrirá';
        return disabled;
      default:
        disabled = true;
        return disabled;
    }
  }

  if (nivel === level.SECUNDARIO) {
    const {
      Egresa: EGRESA,
      Permanece: PERMANECE,
      'Promoción en proceso': PROMOCION_EN_PROCESO,
      'Con promoción acompañada': PROMOCION_CON_APOYO,
      'No completó el Plan de Estudio': NO_COMPLETO_PLAN_DE_ESTUDIOS,
      'Promoción directa': PROMOCION_DIRECTA,
    } = MAPPED_PASE_ANIO_OPTIONS_SECUNDARIO;
    switch (inputId) {
      case 'pendientesCicloActual':
        disabled = idEstadoDeLaProyeccion === PERMANECE.id;
        return disabled;
      case 'passStatus':
        disabled =
          isProyeccionFinalizada &&
          statusPass.toString() !== PROMOCION_EN_PROCESO.id.toString();
        return disabled;
      case 'passSection':
        disabled =
          !statusPass ||
          (isProyeccionFinalizada && statusPass !== PROMOCION_CON_APOYO.id) ||
          (isProyeccionFinalizada && statusPass !== PERMANECE.id) ||
          (isProyeccionFinalizada &&
            statusPass !== NO_COMPLETO_PLAN_DE_ESTUDIOS.id);
        return disabled;
      case 'motivo':
        disabled =
          !sectionPass ||
          (sectionPass === 'No Concurrirá' &&
            (statusPass === EGRESA.id ||
              statusPass === NO_COMPLETO_PLAN_DE_ESTUDIOS.id)) ||
          sectionPass !== 'No Concurrirá';
        return disabled;
      case 'observaciones':
        disabled =
          !statusPass ||
          (statusPass !== PERMANECE.id &&
            statusPass !== PROMOCION_CON_APOYO.id);
        return disabled;
      default:
        disabled = false;
        return disabled;
    }
  }
};
