import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  filterContainer: {
    marginTop: '1em',
  },

  searchInput: {
    marginTop: '1em',
  },
});
