import { TableCell } from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import {
  useExceptuadosV3DAO,
  useObservacionnesBoletinDAO,
} from '../../../../../../../app/business/presentismo_v3';
import { usePresenteV3DAO } from '../../../../../../../app/business/presentismo_v3/Presente';
import { Presente_v3 } from '../../../../../../../app/models';
import { ObservacionesBoletin } from '../../../../../../../app/models/presentismo_v3/ObservacionesBoletin';
import {
  OptionsModal,
  OptionsModalOption,
  ROL,
  TIPO_PERIODO,
} from '../../../../../../../commons';
import customFormDialog from '../../../../../../../commons/services/customFormDialog';
import { useRolesContext } from '../../../../../../../context/RolesContext';
import { useMultiPresentismoChecks } from '../../../context';
import { ObservacionesModal } from '../../ObservacionesModal';
import { IObservacionesInitialValue } from '../../ObservacionesModal/types';
import { useSnackbar } from 'notistack';
import { ObservacionesBoletinModal } from '../../ObservacionesBoletinModal';
import { IObservacionesBoletinInitialValue } from '../../ObservacionesBoletinModal/types';
import moment from 'moment';
import { ExceptuadosModal } from '../../ExceptuadosModal';
import {
  DateRangeListV3,
  ExceptuadosDateRangeV3,
} from '../../ExceptuadosModal/types';
import { ObservacionesCellProps, PresenteGeneral } from '../../../types';
import {
  getPresentesByExceptuados,
  getPresentesByExceptuadosToDelete,
  getPresentismoToDeleteByUpdatedExceptuado,
  toExceptuados,
  transformExceptuados,
} from '../hooks';
import { usePresentismoContext } from '../../../context';
import { getEspacioJE } from '../../../utils/getEspacioJE';
import { useObservacionesCellStyles } from '../css';
import { DeepPartial } from 'chart.js/types/utils';
import { ASISTENCIA_GENERAL } from './TurnoCells';
import { usePresentismoEspacioCurricularDAO } from '../../../../../../../app/business';

const findDeSemana = ['sábado', 'domingo'];

export const ObservacionesCell = ({
  alumno,
  currentWeek,
  pastDaysOfWeek,
  reFetchAll,
  isTabJornadaExtendida,
  setLoading,
  dataCierrePresentismo,
  selectedEspacioCurricular,
}: ObservacionesCellProps) => {
  const classes = useObservacionesCellStyles();
  const presenteDAO = usePresenteV3DAO();
  const espaciosCurricularPresenteDAO = usePresentismoEspacioCurricularDAO();
  const { turno } = useMultiPresentismoChecks();
  const observacionesBoletinDAO = useObservacionnesBoletinDAO();
  const exceptuadosDAO = useExceptuadosV3DAO();
  const { enqueueSnackbar } = useSnackbar();

  const {
    userInfo: { id },
    selectedRole: { nivel, rol, localizacion },
  } = useRolesContext();

  const { propuestasJE, periodoNuevo } = usePresentismoContext();

  const hasJornadaExtendidaDisabled =
    isTabJornadaExtendida &&
    (rol === ROL.DIRECTIVO.toString() ||
      rol === ROL.EQUIPO_CONDUCCION.toString());

  const handleSubmit = useCallback(
    async (values: IObservacionesInitialValue) => {
      try {
        customFormDialog.handleCancel();
        const { selectedTurno, ...rest } = values;
        const toSave: PresenteGeneral[] = [];
        Object.values(rest).map((value) => {
          for (const observacion of value) {
            const presente = alumno.presentismo.find(
              (presente) => presente.idPresente === observacion.idPresente,
            );
            if (presente) {
              const { cicloLectivo, ...rest } = presente;
              toSave.push({
                ...rest,
                observacion: observacion.observacion,
                ...(selectedEspacioCurricular
                  ? { idPresenteEspacioCurricular: Number(presente.idPresente) }
                  : { idPresente: presente.idPresente! }),
              });
            }
          }
        });
        if (toSave.length > 0) {
          selectedEspacioCurricular
            ? await espaciosCurricularPresenteDAO.aspect('basic').save(toSave)
            : await presenteDAO
                .aspect('ContraturnoPresentismoAspect')
                .save(toSave);
          reFetchAll();
        }
      } catch (error) {
        console.log('Error');
      }
    },
    [
      alumno,
      presenteDAO,
      reFetchAll,
      selectedEspacioCurricular,
      espaciosCurricularPresenteDAO,
    ],
  );

  const handleBoletinSubmit = useCallback(
    async (values: IObservacionesBoletinInitialValue) => {
      try {
        const { observaciones, idPeriodoNuevo, ...rest } = values;
        const toSave: DeepPartial<ObservacionesBoletin> = {
          ...rest,
          alumno: alumno.alumno,
          periodoNuevo: { idPeriodoNuevo },
          observacion: observaciones,
          isJornadaExtendida: isTabJornadaExtendida,
        };
        if (toSave) {
          await observacionesBoletinDAO.save(toSave);
          enqueueSnackbar('Observacion guardada', { variant: 'success' });
        }
      } catch (error) {
        console.log('Error');
        enqueueSnackbar('No se pudo guardar la observacion', {
          variant: 'error',
        });
      } finally {
        customFormDialog.handleCancel();
      }
    },
    [alumno, isTabJornadaExtendida, observacionesBoletinDAO, enqueueSnackbar],
  );

  const handleExectuadosSubmit = useCallback(
    async (toSave: DateRangeListV3, toDeleted: ExceptuadosDateRangeV3[]) => {
      setLoading(true);
      try {
        const isSeccionJE = alumno.seccion.jornada === 'Extendida';
        const _olds = toExceptuados([...alumno.exceptuados.list]);
        const exceptuadosList = [...toSave.getRowsToSave()];
        const _exceptuadosToDelete = transformExceptuados(toDeleted);
        const _newExceptuados = exceptuadosList.filter(
          (exceptuados) => !exceptuados.idExceptuado,
        );
        const _updateExceptuados = exceptuadosList.filter(
          (exceptuados) => exceptuados.isEdited && exceptuados.idExceptuado,
        );
        const _allExceptuados = [
          ..._newExceptuados,
          ..._updateExceptuados,
          ..._exceptuadosToDelete,
        ];
        let fechasMap: string[] = [];
        for (const except of _allExceptuados) {
          if (except.idExceptuado) {
            const original = _olds.find(
              (old) => old.idExceptuado === except.idExceptuado,
            );
            if (!original) continue;

            const dias = moment(original.endTime).diff(
              original.startTime,
              'days',
            );
            for (let i = 0; i <= dias; i++) {
              const current = moment(original.startTime).add(i, 'd');
              const isFindeSemana = findDeSemana.includes(
                moment(current).format('dddd'),
              );
              if (isFindeSemana) continue;
              fechasMap.push(current.format('YYYY-MM-DD'));
            }
          } else {
            const dias = moment(except.endTime).diff(except.startTime, 'days');
            for (let i = 0; i <= dias; i++) {
              const current = moment(except.startTime).add(i, 'd');
              const isFindeSemana = findDeSemana.includes(
                moment(current).format('dddd'),
              );
              if (isFindeSemana) continue;
              fechasMap.push(current.format('YYYY-MM-DD'));
            }
          }
        }
        const filter = {
          fechas: [
            {
              path: 'fecha',
              value: fechasMap.map((f) => `'${f}'`),
              method: 'in',
            },
          ],
          alumno: [{ path: 'alumno.idAlumno', value: alumno.alumno.idAlumno }],
          seccion: [
            { path: 'seccion.idSeccion', value: alumno.seccion.idSeccion },
          ],
          jordana: isSeccionJE
            ? []
            : [{ path: 'isJornadaExtendida', value: isTabJornadaExtendida }],
        };
        const presentes: Presente_v3[] = await presenteDAO
          .aspect('basic')
          .fetch({ filter, limit: 2000 });
        const _allPresentes = presentes.reduce<Presente_v3[]>((acum, item) => {
          const exist = acum.find((a) => a.idPresente === item.idPresente);
          if (!exist) {
            acum.push(item);
          }
          return acum;
        }, []);

        const toFilter = toExceptuados(_allExceptuados);
        const { toDelete } = getPresentismoToDeleteByUpdatedExceptuado(
          toFilter,
          _olds,
          _allPresentes,
          isTabJornadaExtendida,
        );
        const { presentismos } = getPresentesByExceptuados(
          toExceptuados(_allExceptuados),
          _allPresentes,
          alumno,
          turno,
          isTabJornadaExtendida,
        );
        const { presentismos: deletePresentes } =
          getPresentesByExceptuadosToDelete(
            toExceptuados(toDeleted),
            _allPresentes,
            alumno.contraturnos,
            turno,
            isTabJornadaExtendida,
          );
        const _allToDelete = [...toDelete, ...deletePresentes];
        const exceptuadoToSave = toExceptuados([
          ..._newExceptuados,
          ..._updateExceptuados,
        ]);
        const exceptuadosToDelete = toExceptuados(_exceptuadosToDelete);

        if (isSeccionJE) {
          const presentesJE = presentismos
            .filter(
              (pre) =>
                pre.turno && pre.turno.idTurno === alumno.seccion.turno.idTurno,
            )
            .map((pre) => {
              const _espacioJE = getEspacioJE(
                moment(pre.fecha).format('YYYY-MM-DD'),
                alumno.seccion.idSeccion,
                localizacion,
                propuestasJE,
              );
              return { ...pre, isJornadaExtendida: true, espacio: _espacioJE };
            });
          presentismos.push(...presentesJE);
        }

        if (exceptuadoToSave.length > 0) {
          await exceptuadosDAO.save(exceptuadoToSave);
          if (presentismos.length > 0) {
            await presenteDAO
              .aspect('ContraturnoPresentismoAspect')
              .save(presentismos);
          }
        }
        if (exceptuadosToDelete.length > 0) {
          for (const _delet of exceptuadosToDelete) {
            _delet.deletedBy = Number(id);
            _delet.deletedAt = moment().format();
            await exceptuadosDAO.save(_delet);
          }
          for (const _presente of _allToDelete) {
            await presenteDAO.aspect('basic').remove(_presente);
          }
        }
        reFetchAll();
        enqueueSnackbar('Operación exitosa', { variant: 'success' });
      } catch (error) {
        console.log('Error', error);
        enqueueSnackbar('No se pudo completar la operación', {
          variant: 'error',
        });
      } finally {
        setLoading(false);
      }
    },
    [
      enqueueSnackbar,
      reFetchAll,
      exceptuadosDAO,
      presenteDAO,
      id,
      alumno,
      turno,
      isTabJornadaExtendida,
      propuestasJE,
      localizacion,
      setLoading,
    ],
  );

  const handleShowObservaciones = useCallback(async () => {
    const daySelected = moment(currentWeek)
      .startOf('isoWeek')
      .add(pastDaysOfWeek, 'days');

    const dayFormatted =
      daySelected.format('dddd')[0].toUpperCase() +
      daySelected.format('dddd').slice(1) +
      ' ' +
      daySelected.format('DD');
    await customFormDialog.show({
      title: 'Observaciones Diarias',
      renderComponent: (
        <ObservacionesModal
          daySelected={daySelected}
          dayFormatted={dayFormatted}
          currentWeek={currentWeek}
          alumno={alumno}
          turno={
            selectedEspacioCurricular ? ASISTENCIA_GENERAL[0].idTurno : turno
          }
          onSubmit={handleSubmit}
          hasJornadaExtendidaDisabled={hasJornadaExtendidaDisabled}
          selectedEspacioCurricular={selectedEspacioCurricular}
        />
      ),
      sizeWidth: 'sm',
    });
  }, [
    currentWeek,
    alumno,
    turno,
    pastDaysOfWeek,
    handleSubmit,
    hasJornadaExtendidaDisabled,
    selectedEspacioCurricular,
  ]);

  const handleShowObservacionesBoletin = useCallback(async () => {
    try {
      await customFormDialog.show({
        title: 'Observaciones Boletín',
        renderComponent: (
          <ObservacionesBoletinModal
            periodos={periodoNuevo}
            onSubmit={handleBoletinSubmit}
            hasJornadaExtendidaDisabled={hasJornadaExtendidaDisabled}
            nivel={nivel}
            alumno={alumno.alumno}
            isJE={isTabJornadaExtendida}
          />
        ),
        sizeWidth: 'sm',
      });
    } catch (error) {}
  }, [
    handleBoletinSubmit,
    alumno,
    nivel,
    hasJornadaExtendidaDisabled,
    isTabJornadaExtendida,
    periodoNuevo,
  ]);

  const handleShowObservacionesExceptuados = useCallback(async () => {
    try {
      const { tempIntervals, intervalsToDelete } = await customFormDialog.show<{
        tempIntervals: DateRangeListV3;
        intervalsToDelete: ExceptuadosDateRangeV3[];
      }>({
        title: 'Régimen Especial',
        renderComponent: (
          <ExceptuadosModal
            initialExceptuados={alumno.exceptuados}
            alumno={alumno.alumno}
            isTabJornadaExtendida={isTabJornadaExtendida}
            rol={rol}
          />
        ),
        sizeWidth: 'md',
      });
      if (tempIntervals) {
        await handleExectuadosSubmit(tempIntervals, intervalsToDelete);
      }
    } catch (error) {}
  }, [alumno, isTabJornadaExtendida, rol, handleExectuadosSubmit]);

  const disabled = useMemo(() => {
    const daySelected = moment(currentWeek)
      .startOf('isoWeek')
      .add(pastDaysOfWeek, 'days');
    const hasPresente = alumno.presentismo.some(
      (presente) => presente.fecha === daySelected.format('YYYY-MM-DD'),
    );
    return !hasPresente;
  }, [alumno.presentismo, currentWeek, pastDaysOfWeek]);

  const disabledPadlook = useMemo(() => {
    const daySelected = moment(currentWeek)
      .startOf('isoWeek')
      .add(pastDaysOfWeek, 'days')
      .format('YYYY-MM-DD');
    let validateDateData: string[] = [];
    if (isTabJornadaExtendida) {
      validateDateData = dataCierrePresentismo?.map((d) => d.dias.je).flat();
    } else if (selectedEspacioCurricular) {
      validateDateData = dataCierrePresentismo?.map((d) => d.dias.ec).flat();
    } else {
      validateDateData = dataCierrePresentismo?.map((d) => d.dias.jc).flat();
    }
    const formatvalidatedate = validateDateData?.map(
      (d) => moment.utc(d).format('YYYY-MM-DD'), // Usa moment.utc() para manejar la zona horaria de forma consistente
    );
    const presente = formatvalidatedate.includes(daySelected);
    return presente;
  }, [
    currentWeek,
    dataCierrePresentismo,
    isTabJornadaExtendida,
    pastDaysOfWeek,
    selectedEspacioCurricular,
  ]);

  const options: OptionsModalOption[] = [
    {
      label: 'Ver observaciones diarias',
      onClick: handleShowObservaciones,
      isDisabled: disabled || disabledPadlook,
    },
    {
      label: 'Ver observaciones boletín',
      onClick: handleShowObservacionesBoletin,
      isDisabled: disabledPadlook,
    },
  ];
  if (!(Number(nivel) === 1)) {
    options.push({
      label: 'Régimen Especial',
      onClick: handleShowObservacionesExceptuados,
      isDisabled: disabledPadlook,
    });
  }
  const optionsEspacioCurricular: OptionsModalOption[] = [
    {
      label: 'Ver observaciones diarias',
      onClick: handleShowObservaciones,
      isDisabled: disabled || disabledPadlook,
    },
  ];

  return (
    <TableCell align="center" className={classes.cell}>
      <OptionsModal
        closeOnExternalAction
        options={selectedEspacioCurricular ? optionsEspacioCurricular : options}
        classIcon={classes.iconButton}
        customStyleIcon={{
          width: 24,
          height: 24,
          color: '#3786EE',
        }}
      />
    </TableCell>
  );
};
