import { makeEntityLibbyCall } from '../../../lib/libby/hooks/makeEntityLibbyCall';
import { makeEntityLibbyDAO } from '../../../lib/libby/hooks/makeEntityLibbyDAO';
import { withRenderPropCall } from '../../../lib/libby/hoc/withRenderPropCall';

const DAO_NAME = 'relogin';

// hooks
export const useReloginLibbyCall = makeEntityLibbyCall(DAO_NAME);
export const useReloginDAO = makeEntityLibbyDAO(DAO_NAME);

// components/hoc
export const ReloginCall = withRenderPropCall(DAO_NAME);
