import moment from 'moment';
import { useSnackbar } from 'notistack';
import {
  useDevolucionOrganizacionDAO,
  useEvaluacionesDevolucionesDAO,
} from 'src/app/business/acap';
import { DevolucionOrganizacion } from 'src/app/models';

export const useSubmitHandler = (
  reFetchDev: () => void,
  reFetchDevEva: () => void,
  nextStudent: (callback?: (() => void) | undefined) => Promise<void>,
) => {
  const { enqueueSnackbar } = useSnackbar();

  const devolucionOrganizacionDAO = useDevolucionOrganizacionDAO();
  const evaluacionesDevolucionDAO = useEvaluacionesDevolucionesDAO();
  return async (form: Acap.Organizacion.DevolucionFormData) => {
    try {
      let devolucion: DevolucionOrganizacion;
      devolucion = await devolucionOrganizacionDAO.aspect('saving').save({
        idDevolucionOrganizacion: form.idDevolucionOrganizacion,
        observaciones: form.observaciones,
        citarCv: Boolean(form.citarCv),
        grupoInscripcion: form.grupoInscripcion,
        createdBy: form?.createdBy,
        createdAt: form?.createdAt,
        fechaAcap: moment.utc(form.fechaAcap).format('YYYY-MM-DD'),
      });
      if (form.evaluaciones.length > 0) {
        await evaluacionesDevolucionDAO.aspect('default').save(
          form.evaluaciones.map((evaluacion) => ({
            idDevolucionEvaluacion: evaluacion.idDevolucionEvaluacion,
            criterio: evaluacion.criterio.idCriterioEvaluacion,
            valor: evaluacion.valor,
            devolucion: devolucion.idDevolucionOrganizacion,
          })),
        );
      }

      enqueueSnackbar('Se guardó la devolución', { variant: 'success' });
    } catch (e) {
      enqueueSnackbar('Ha ocurrido un problema con el guardado', {
        variant: 'error',
      });
    } finally {
      nextStudent();
      reFetchDev();
      reFetchDevEva();
    }
  };
};
