import React, { Dispatch, SetStateAction } from 'react';
import { Grid } from '@material-ui/core';
import { MainButton } from 'src/commons';
import { Filters } from './components';
import { useFiltersLogic, FilterLogicOptions } from './hooks';
import { MainInput } from '../../../../../../../../commons/components/MainInput';
import { JoinedFilters } from './types';

export type GestionRolesHeaderProps = {
  filter: JoinedFilters;
  onChangeFilter: Dispatch<SetStateAction<JoinedFilters>>;
  handleModalGestionRoles: () => void;
  hidden: number | boolean;
};

export const GestionRolesHeader = ({
  filter,
  onChangeFilter,
  handleModalGestionRoles,
  hidden,
}: GestionRolesHeaderProps) => {
  const props = {
    filter,
    onChangeFilter,
  };
  const { search, onSearchChange, ...rest } = useFiltersLogic(props);
  return (
    <Grid container justify="space-between">
      <Grid item xs={6}>
        <Filters {...rest} />
      </Grid>
      <Grid item xs={2}>
        {hidden ? (
          <MainButton
            title="Gestioná los roles"
            handleOnClick={handleModalGestionRoles}
            customStyle={{ marginTop: 20 }}
          />
        ) : null}
      </Grid>
      <Grid item xs={3}>
        <MainInput
          type="search"
          handleChange={onSearchChange}
          placeholder="Buscar"
          name="search"
          value={search}
          fullWidth
          customStyle={{ marginTop: 20 }}
        />
      </Grid>
    </Grid>
  );
};
