import {
  Box,
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
} from '@material-ui/core';
import React, { useCallback, useMemo } from 'react';
import { InfoTable, InfoTableColumn, ROL } from 'src/commons';
import TimelineIcon from '@material-ui/icons/Timeline';
import TuneIcon from '@material-ui/icons/Tune';
import moment from 'moment';
import { useDevolucionDetailColumn } from '../hooks';
import { useEscuelaContext } from '../context/EscuelaContext';
import { DEVOLUCIONES_SCREEN } from '../enums/DevolucionesScreen';
import { GruposInscripcionesAlumns } from '../types';
import { PermissionBlocker } from '../../../../lib/permission';
import { useRolesContext } from 'src/context/RolesContext';

export const DevolucionesDetails = () => {
  const {
    ofertaSelected,
    inscriptos,
    loading,
    docentesNodos,
    setAlumnoSelected,
    setCurrentTap,
    setOfertaSelected,
  } = useEscuelaContext();

  const {
    selectedRole: { rol },
  } = useRolesContext();

  let canViewOnly = useMemo(
    () => Number(rol) === ROL.DOCENTE && docentesNodos.length === 0,
    [rol, docentesNodos],
  );

  const onClickViewDevolutions = useCallback<
    (item: GruposInscripcionesAlumns) => () => void
  >(
    (item) => () => {
      setAlumnoSelected(item);
      setCurrentTap(DEVOLUCIONES_SCREEN.VIEW);
    },
    [setAlumnoSelected, setCurrentTap],
  );

  const handleRealizarDevolucion = useCallback<
    (item: GruposInscripcionesAlumns) => () => void
  >(
    (item) => () => {
      setAlumnoSelected(item);
      setCurrentTap(DEVOLUCIONES_SCREEN.FORM);
    },
    [setCurrentTap, setAlumnoSelected],
  );

  const confirmContentsVisible = useCallback((row) => {
    if (row?.contenidos?.length === 0) return true;
    const devolucionesDocente = row.contenidos.filter(
      (contenido: GruposInscripcionesAlumns['contenidos']) =>
        Object.keys(contenido.createdBy).length === 1,
    );
    return devolucionesDocente.some(
      (contenido: GruposInscripcionesAlumns['contenidos']) =>
        contenido?.valores?.length === 0,
    );
  }, []);

  const { columns: actividadSeleccionadaColumns, mostrarAlumnos } =
    useDevolucionDetailColumn();
  const returnTableColumns = useMemo<
    InfoTableColumn<GruposInscripcionesAlumns>[]
  >(() => {
    return [
      {
        id: 'name',
        label: 'Apellido y nombre',
        width: '30%',
        hideSortIcon: true,
        render: (_, row) => {
          return `${row.alumnoMovimiento.alumno.persona.apellido} ${row.alumnoMovimiento.alumno.persona.nombre}`;
        },
      },
      {
        id: 'section',
        label: 'Sección',
        width: '25%',
        hideSortIcon: true,
        render: (_, row) => {
          return row.alumnoMovimiento.seccion.nombreSeccion;
        },
      },
      {
        id: 'createdAt',
        label: 'Fecha última devolución',
        width: '30%',
        align: 'center',
        hideSortIcon: true,
        render: (value, row) => {
          const _value =
            row.perfiles[0] || row.habilidades[0] || row.contenidos[0];
          const fecha = _value?.updatedAt || _value?.createdAt;
          return fecha ? moment(fecha).format('DD/MM/YYYY') : '-';
        },
      },
      {
        id: 'Icon',
        label: '',
        width: '15%',
        hideSortIcon: true,
        render: (_, row) => {
          const hasAutoEval =
            row.contenidos.filter(
              (c) =>
                c.createdBy?.idUsuario ===
                row.alumnoMovimiento.alumno.persona.cuentas.idUsuario,
            ).length > 0;
          return (
            <>
              <PermissionBlocker id={1}>
                <Tooltip title={'Realizar devolución'}>
                  <IconButton
                    aria-label="tune"
                    onClick={handleRealizarDevolucion(row)}
                    disabled={confirmContentsVisible(row) || canViewOnly}
                  >
                    <TuneIcon
                      color={
                        canViewOnly || confirmContentsVisible(row)
                          ? 'disabled'
                          : 'primary'
                      }
                    />
                  </IconButton>
                </Tooltip>
              </PermissionBlocker>

              <Tooltip
                title={
                  hasAutoEval
                    ? 'Ver Devoluciones de la organización y Autoevaluacióndel Estudiante'
                    : 'El estudiante no posee devoluciones'
                }
              >
                <IconButton
                  aria-label="time"
                  onClick={onClickViewDevolutions(row)}
                  disabled={!hasAutoEval}
                >
                  <TimelineIcon color={hasAutoEval ? 'primary' : 'disabled'} />
                </IconButton>
              </Tooltip>
            </>
          );
        },
      },
    ];
  }, [
    handleRealizarDevolucion,
    confirmContentsVisible,
    canViewOnly,
    onClickViewDevolutions,
  ]);

  return (
    <Grid container component={Box} padding={2} spacing={3}>
      <Grid item>
        <Typography variant="h3">Devoluciones</Typography>
      </Grid>
      <Grid
        item
        xs={12}
        component={Box}
        border="1px solid grey"
        padding={3}
        marginTop={2}
      >
        <Typography variant="h5">Actividad Seleccionada</Typography>
        <InfoTable
          rows={ofertaSelected && !loading ? [ofertaSelected] : []}
          columns={actividadSeleccionadaColumns}
          rowIdKey="idGrupoInscripcion"
          working={loading}
        />
      </Grid>
      <Grid
        item
        xs={12}
        component={Box}
        border="1px solid grey"
        padding={3}
        marginTop={6}
      >
        <Typography variant="h5">
          Estudiantes inscriptos a la actividad
        </Typography>
        {mostrarAlumnos && (
          <InfoTable
            rows={loading ? [] : inscriptos}
            columns={returnTableColumns}
            rowIdKey="idMatriculasss"
            working={loading}
          />
        )}
      </Grid>
      <Grid item container justifyContent="center">
        <Button
          variant="contained"
          color="primary"
          style={{ textTransform: 'none' }}
          onClick={() => {
            setCurrentTap(DEVOLUCIONES_SCREEN.DEFAULT);
            setOfertaSelected(undefined);
          }}
        >
          Volver
        </Button>
      </Grid>
    </Grid>
  );
};
