import { Box, Divider, makeStyles, Menu, MenuItem } from '@material-ui/core';
import { Indicator } from './Indicador';
import React, { MutableRefObject, useMemo } from 'react';
import { EstadosPresentismo } from '../../types';
import {
  CONTRATURNO_ESTADOS,
  ESPACIOS_CURRICULARES_ESTADOS,
  ESTADOS_PRESENTISMO,
  EXTENDIDA_ESTADOS,
} from '../../utils/EstadoPresente';
import { is } from 'ramda';

const useStyle = makeStyles({
  papper: {
    width: 200,
  },
});

export interface ModalMenuProps {
  title?: string;
  onSelectOption: (value: EstadosPresentismo) => void;
  modalRef: MutableRefObject<any | null>;
  anchorEl?: Element | ((element: Element) => Element) | null;
  onClosed: () => void;
  isTabJornadaExtendida: boolean;
  isContraturno?: boolean;
  isEspacioCurricular: boolean;
}

export const ModalMenu = ({
  title = 'Indicar asistencia del alumno',
  modalRef,
  anchorEl,
  onClosed,
  onSelectOption,
  isTabJornadaExtendida,
  isContraturno = false,
  isEspacioCurricular = false,
}: ModalMenuProps) => {
  const classes = useStyle();
  const estadosPresentismos = useMemo(() => {
    let estadosValidos = ESTADOS_PRESENTISMO.map((e) => e.idEstadoPresente);
    if (isTabJornadaExtendida) {
      estadosValidos = EXTENDIDA_ESTADOS;
    }
    if (isContraturno) {
      estadosValidos = CONTRATURNO_ESTADOS;
    }
    if (isEspacioCurricular) {
      estadosValidos = ESPACIOS_CURRICULARES_ESTADOS;
    }
    return ESTADOS_PRESENTISMO.filter((estado) =>
      estadosValidos.includes(estado.idEstadoPresente),
    );
  }, [isTabJornadaExtendida, isContraturno, isEspacioCurricular]);

  return (
    <Menu
      id="indicator-menu"
      classes={{ paper: classes.papper }}
      ref={modalRef}
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      transformOrigin={{
        vertical: -50,
        horizontal: 'center',
      }}
      onClose={(e: any, r) => {
        e?.stopPropagation();
        onClosed();
      }}
    >
      <Box
        height={35}
        display="flex"
        paddingX={3}
        paddingTop={1}
        fontSize={14}
        lineHeight={1}
        whiteSpace="initial"
        fontFamily="Nunito"
      >
        {title}
      </Box>
      <Box paddingX={2} paddingBottom={1}>
        <Divider />
      </Box>
      {estadosPresentismos.map((option, index) => {
        return (
          <MenuItem
            autoFocus={index === 0}
            style={{ paddingLeft: 12, paddingRight: 12 }}
            key={option.idEstadoPresente}
            onClick={(e) => {
              e.stopPropagation();
              onSelectOption(option);
            }}
          >
            <Indicator color={option.color} label={option.label} />
            <Box
              pl={1}
              fontFamily="Nunito"
              whiteSpace="initial"
              fontSize={14}
              lineHeight={1}
              color="#38485C"
            >
              {option.descripcion}
            </Box>
          </MenuItem>
        );
      })}
    </Menu>
  );
};
