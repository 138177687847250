import { isEqual } from 'lodash';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { JornadaExtendida } from 'src/app/models';
import { AnyObject } from 'src/commons';

type ValidatorProps = {
  fieldsToValidate: string[];
  _rows: Array<JornadaExtendida & { nota?: AnyObject }>;
  isDynamic?: boolean;
  filter: any;
  working: boolean;
  data: JornadaExtendida[];
};

export const useJornadaExtendidaValidator = ({
  fieldsToValidate,
  _rows,
  isDynamic = false,
  filter,
  working,
  data,
}: ValidatorProps) => {
  const [incompletedRows, setIncompletedRows] = useState<string[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);
  const [currentRows, setCurrentRows] = useState<typeof _rows>();
  const [currentFilter, setCurrentFilter] = useState();
  const [isInvalidToSubmit, setInvalidToSubmit] = useState(false);

  useEffect(() => {
    setInvalidToSubmit(
      !_rows.some((row) => {
        const originalRow = data.find(
          (item) => item.idCalificacion === row.idCalificacion,
        );
        if (!originalRow) return;

        return Boolean(originalRow.nota)
          ? !isEqual(row.nota?.data, originalRow.nota?.data)
          : Object.keys(row.nota?.data).length > 0 &&
              Object.entries(row.nota?.data).every(
                ([_, value]) => Boolean(value) || value === false,
              );
      }),
    );
  }, [_rows, data]);

  const validator = useCallback(() => {
    if (filter !== currentFilter) {
      setCurrentFilter(filter);
      setIncompletedRows([]);
    }
    let tempIncompletedRows = [...incompletedRows];
    _rows.forEach((r) => {
      const fieldsToValidateByRow = [...fieldsToValidate];
      if (isDynamic) {
        if (r.nota.data.espaciosConsolidandoPregunta === 'Sí') {
          fieldsToValidateByRow.push('espaciosConsolidando');
        }
        if (r.nota.data.apoyoPregunta === 'Sí') {
          fieldsToValidateByRow.push('apoyo');
        }
      }
      const name = `${r?.apellido}, ${r?.nombre}`;
      const result = fieldsToValidateByRow.reduce(
        (acc: number, val: string) => {
          if (!Boolean(r.nota.data[val])) ++acc;
          return acc;
        },
        0,
      );
      r.fieldsToValidateByRow = fieldsToValidateByRow;
      if (result > 0 && result < fieldsToValidateByRow.length) {
        if (incompletedRows.indexOf(name) === -1) {
          tempIncompletedRows = [...incompletedRows, name];
        }
      } else {
        if (incompletedRows.indexOf(name) !== -1) {
          const incompletedRowsCopy = [...incompletedRows];
          incompletedRowsCopy.splice(incompletedRowsCopy.indexOf(name), 1);
          tempIncompletedRows = incompletedRowsCopy;
        }
      }
      setIncompletedRows(tempIncompletedRows);
    });

    setIsDisabled(tempIncompletedRows.length > 0);
  }, [
    incompletedRows,
    _rows,
    isDynamic,
    filter,
    currentFilter,
    fieldsToValidate,
  ]);

  useEffect(() => {
    if (currentRows !== _rows && !working) {
      setCurrentRows(_rows);
      validator();
    }
  }, [currentRows, _rows, validator, working]);

  return {
    incompletedRows,
    setIncompletedRows,
    isDisabled,
    submitRows: isInvalidToSubmit,
  };
};
