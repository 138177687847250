import { Grid, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import { MainInput, SimpleSelect, Loading } from 'src/commons';
import { useInputValidator } from '../hooks/useInputValidator';

export const TurnoComponent = ({
  index,
  turnosValues,
  turnos,
  setTurnosValues,
  borrarTurno,
  isDuplicating = false,
}: any) => {
  const [inputValidator, error] = useInputValidator();
  const [loading, setLoading] = useState(false);
  const [turno, setTurno] = useState<any>({
    cupo: 0,
    cantGrupos: 0,
    cupoGrupos: 0,
  });

  const handleChangeTurno = (e: any, extraValue: any = undefined) => {
    const clonnedTurnosValues = _.cloneDeep(turnosValues);
    if (clonnedTurnosValues.length - 1 >= index) {
      clonnedTurnosValues[index][e.target.name] = e.target.value;
      if (extraValue)
        clonnedTurnosValues[index]['cupo'] =
          Number(e.target.value) * Number(extraValue);
    } else {
      clonnedTurnosValues.push({ [e.target.name]: e.target.value });
    }
    setTurnosValues(clonnedTurnosValues);
  };

  useEffect(() => {
    if (turnosValues[index]?.idOfertaTurno || isDuplicating) {
      setTurno({
        cupos: turnosValues[index]?.cupos,
        cantGrupos: turnosValues[index]?.cantGrupos,
        cupoGrupos: turnosValues[index]?.cupoGrupos,
      });
    }
  }, [turnosValues, index, isDuplicating]);

  return (
    <>
      <Grid style={{ marginTop: '15px' }}>
        {loading ? (
          <Loading />
        ) : (
          <Grid container>
            <Grid container item md={3} alignItems="center">
              <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                <IconButton
                  onClick={async () => {
                    setLoading(true);
                    await borrarTurno(index, turnosValues[index]);
                    setLoading(false);
                  }}
                  style={{
                    visibility: turnosValues.length > 1 ? 'visible' : 'hidden',
                  }}
                >
                  <DeleteIcon color="primary" />
                </IconButton>{' '}
                Turno:{' '}
                <span style={{ color: 'red' }}>
                  {turnosValues[index]?.turno ? '' : '*'}
                </span>
              </p>
              <SimpleSelect
                name="turno"
                value={
                  Boolean(turnosValues) ? turnosValues[index]?.turno : null
                }
                handleChange={(e: any) => {
                  const turnosCheck = turnosValues.filter(
                    (turno: any) => turno?.turno === e.target.value,
                  );
                  if (!turnosCheck.length) {
                    handleChangeTurno(e);
                  }
                }}
                placeholder="Seleccioná turnos"
                content={turnos?.length > 0 ? turnos : []}
                labelKey="descripcionTurno"
                valueKey="idTurno"
                style={{
                  width: '30em',
                  marginBottom: '20px',
                }}
              />
            </Grid>
            <Grid container item md={3} alignItems="center">
              <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                Grupos por turno:{' '}
                <span style={{ color: 'red' }}>
                  {turnosValues[index]?.cantGrupos ? '' : '*'}
                </span>
              </p>
              <MainInput
                value={turnosValues[index]?.cantGrupos}
                name={'cantGrupos'}
                handleChange={(e: any) => {
                  const value: any = e?.target?.value;
                  const cupo_ = Number(value) * turno.cupoGrupos;
                  setTurno({
                    ...turno,
                    cupos: Number(value) * turno.cupoGrupos,
                    cantGrupos: Number(value),
                  });
                  handleChangeTurno(e, turno.cupoGrupos);
                }}
                placeholder="0"
                customStyle={{
                  width: '50%',
                }}
              />
            </Grid>
            <Grid container item md={3} alignItems="center">
              <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                Cupo por grupo:{' '}
                <span style={{ color: 'red' }}>
                  {turnosValues[index]?.cupoGrupos ? '' : '*'}
                </span>
              </p>
              <MainInput
                value={turnosValues[index]?.cupoGrupos}
                name="cupoGrupos"
                handleChange={(e) => {
                  const value: any = e?.target?.value;
                  setTurno({
                    ...turno,
                    cupos: Number(value) * turno.cantGrupos,
                    cupoGrupos: Number(value),
                  });
                  handleChangeTurno(e, turno.cantGrupos);
                }}
                placeholder="0"
                // error={error.nombreOrg}
                // helperText={'No ingrese caracteres especiales'}
                customStyle={{
                  width: '50%',
                }}
              />
            </Grid>
            <Grid container item md={3} alignItems="center">
              <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                Cupo total turno:
              </p>
              <MainInput
                name="cupo"
                value={turnosValues[index]?.cupo}
                handleChange={() => {}}
                placeholder="0"
                disabled={true}
                customStyle={{
                  width: '50%',
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  );
};
