import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class PrimarioDAO extends LibbyFetchDAO {
  constructor() {
    super('primario', 'idPrimario');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
