import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { MatriculaPersonaExterna } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'matricula_persona_externa';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idMatriculaPersonaExterna',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idMatriculaPersonaExterna',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.MatriculaPersonaExterna
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.MatriculaPersonaExterna,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.MatriculaPersonaExterna.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<MatriculaPersonaExterna>(DAO_NAME, optionsToUse);

// hooks
export const useMatriculaPersonaExternaFetchById = fetchById;
export const useMatriculaPersonaExternaLibbyCall = libbyCall;
export const useMatriculaPersonaExternaDAO = libbyDAO;
export const useMatriculaPersonaExternaLibbyFetch = libbyFetch;

// components/hoc
export const MatriculaPersonaExternaAutocomplete = entityAutocomplete;
export const MatriculaPersonaExternaCall = renderPropCall;
export const MatriculaPersonaExternaById = renderPropFetchById;
export const MatriculaPersonaExternaSelect = simpleSelect;
export const MatriculaPersonaExternaAccordionSelector = accordionSelector;
export const MatriculaPersonaExternaSelectedChips = selectedChips;

// context
// ByIdContext
export const useMatriculaPersonaExternaByIdContext = useByIdContext;
export const MatriculaPersonaExternaByIdProvider = ByIdProvider;
export const MatriculaPersonaExternaByIdContext = ByIdContext;
// GeneralContext
export const useMatriculaPersonaExternaContext = useGeneralContext;
export const MatriculaPersonaExternaProvider = GeneralProvider;
export const MatriculaPersonaExternaContext = GeneralContext;
