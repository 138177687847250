import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { PreviasProyecciones } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'previas_proyecciones';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPreviasProyecciones',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPreviasProyecciones',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.PreviasProyecciones
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.PreviasProyecciones,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.PreviasProyecciones.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<PreviasProyecciones>(DAO_NAME, optionsToUse);

// hooks
export const usePreviasProyeccionesFetchById = fetchById;
export const usePreviasProyeccionesLibbyCall = libbyCall;
export const usePreviasProyeccionesDAO = libbyDAO;
export const usePreviasProyeccionesLibbyFetch = libbyFetch;

// components/hoc
export const PreviasProyeccionesAutocomplete = entityAutocomplete;
export const PreviasProyeccionesCall = renderPropCall;
export const PreviasProyeccionesById = renderPropFetchById;
export const PreviasProyeccionesSelect = simpleSelect;
export const PreviasProyeccionesAccordionSelector = accordionSelector;
export const PreviasProyeccionesSelectedChips = selectedChips;

// context
// ByIdContext
export const usePreviasProyeccionesByIdContext = useByIdContext;
export const PreviasProyeccionesByIdProvider = ByIdProvider;
export const PreviasProyeccionesByIdContext = ByIdContext;
// GeneralContext
export const usePreviasProyeccionesContext = useGeneralContext;
export const PreviasProyeccionesProvider = GeneralProvider;
export const PreviasProyeccionesContext = GeneralContext;
