import moment from 'moment';
import { ESTADO_PRESENTE } from 'src/commons';
import { PresenteGeneral } from '../../TomaPresentismo/types';
import { capitalizarPalabras } from './functions';

export const getDataEC = (periodoFiltrado: PresenteGeneral[]) => {
  const cantidadPresetes = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.PRESENTE,
    )
    .length.toString();

  const cantidadAusentes = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE,
    )
    .length.toString();

  const cantidadAusentesConPresenciaEnClases = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente ===
        ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE,
    )
    .length.toString();

  const sinDatos = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.NO_CORRESPONDE,
    )
    .length.toString();

  return [
    {
      Nombre: 'Presente',
      CantidadPresente: cantidadPresetes,
    },
    {
      Nombre: 'Ausente',
      CantidadPresente: cantidadAusentes,
    },
    {
      Nombre: 'Ausente con presencia en clases',
      CantidadPresente: cantidadAusentesConPresenciaEnClases,
    },
    {
      Nombre: 'No Corresponde',
      CantidadPresente: sinDatos,
    },
  ];
};

export const getTableSecDataEC = (periodoFiltrado: PresenteGeneral[]) => {
  const uniqueDates = [...new Set(periodoFiltrado.map((item) => item.fecha))];

  const columns = ['Día', 'Fecha', 'Presentismo'];

  const data = uniqueDates.map((date) => {
    const itemsForDate = periodoFiltrado.filter((item) => item.fecha === date);

    const diaCapitalizado = capitalizarPalabras(moment(date).format('dddd'));

    return {
      dia: diaCapitalizado,
      fecha: moment(date).format('DD-MM-YYYY'),
      presentismo: itemsForDate[0].estadoPresente.descripcion,
    };
  });

  return { columns, data };
};
