import React from 'react';
import { TabBar, useRouteScreenTitle } from 'src/commons';
import { PermissionBlocker } from 'src/lib/permission';
import { BLOCKER_ID } from 'src/platform/permission/const';
import { Gestion } from './tabs';

export const GestionPromocion = () => {
  useRouteScreenTitle('Gestión de Proyección');

  return (
    <PermissionBlocker id={BLOCKER_ID.GESTION_PROYECCION}>
      <TabBar
        content={[
          {
            id: 77,
            title: 'Gestión de Proyección',
            component: <Gestion />,
          },
        ]}
      />
    </PermissionBlocker>
  );
};
