import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import cx from 'classnames';
import moment from 'moment';
import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { COLOR_DAY } from 'src/screens/Private/Presentismo/ConfiguracionSituaciones/components';
import { ESTADO_PRESENTE, ROL, TURNOS_MAP } from '../../../../../../../commons';
import { DeepPartial } from '../../../../../../../commons/types/DeepPartial';
import { useRolesContext } from '../../../../../../../context/RolesContext';
import {
  useMultiPresentismoChecks,
  usePresentismoContext,
} from '../../../context';
import { usePresentismosValidosContext } from '../../../context/PresentismosValidosContext';
import { useEnableToSaveByRol } from '../../../hooks/useEnableToSaveByRol';
import {
  AlumnoMap,
  EstadosPresentismo,
  PresenteGeneral,
  RenderTurnosCell,
  SECCION_TURNO,
  SeccionTurno,
  TurnosCellProps,
} from '../../../types';
import { ModalMenu } from '../../Resume';
import { useTurnoCellStyle } from '../css';
import { useSelectedDayStyles } from '../hooks';

export const TURNOS: SeccionTurno = {
  [SECCION_TURNO.M]: [{ title: 'M', idTurno: 1, colSpan: 2 }],
  [SECCION_TURNO.T]: [{ title: 'T', idTurno: 2, colSpan: 2 }],
  [SECCION_TURNO.N]: [{ title: 'N', idTurno: 3, colSpan: 2 }],
  [SECCION_TURNO.V]: [{ title: 'V', idTurno: 4, colSpan: 2 }],
  [SECCION_TURNO.C]: [
    { title: 'M', idTurno: 1, colSpan: 1 },
    { title: 'T', idTurno: 2, colSpan: 1 },
  ],
};

export const ASISTENCIA_GENERAL = [
  { title: 'Asistencia General', idTurno: 1, colSpan: 2 },
];

export const TurnosCell: FC<TurnosCellProps> = ({
  day,
  state,
  last,
  isValidCicloLectivo,
  isTabJornadaExtendida,
  isSelected,
  contraturnos = [],
  allAlumnos = [],
  selectedSection,
  situaciones = [],
  isDayFuture = false,
  selectedEspacioCurricular,
  dataCierrePresentismo,
  tieneDiasHabilitados,
  isDisabled,
  isLockBlock,
}) => {
  const classes = useTurnoCellStyle();
  const selectedClasses = useSelectedDayStyles();
  const stateClassName = classes[state] || '';

  const { data } = usePresentismosValidosContext();

  const { alumnos, turno } = useMultiPresentismoChecks();
  const {
    selectedRole: { rol, nivel },
  } = useRolesContext();
  const {
    setNewPresentismos,
    dbPresentismos,
    feriados,
    propuestasJE,
    periodoNuevo,
  } = usePresentismoContext();
  const modalRef = useRef();
  const [anchorEl, setAnchorEl] = useState<HTMLElement[]>([]);

  const isFuture = moment().isBefore(day);
  const isFeriado = useMemo(
    () =>
      !!feriados.find((feriado) => {
        const hasExceptions = Object.keys(feriado.exceptions || {}).length > 0;
        if (hasExceptions) {
          return (
            moment(feriado.date, 'YYYY-MM-DD').isSame(
              moment(day, 'YYYY-MM-DD'),
            ) && !feriado.exceptions.nivels.includes(Number(nivel))
          );
        }
        return moment(feriado.date, 'YYYY-MM-DD').isSame(
          moment(day, 'YYYY-MM-DD'),
        );
      }),
    [day, feriados, nivel],
  );

  const handleModalClosed = useCallback(() => {
    setAnchorEl([]);
  }, [setAnchorEl]);

  const isDisableByPassRequest = useCallback(
    (alumno: AlumnoMap) => {
      if (!alumno.solicitudPase) return false;
      const approvalDate = moment(alumno.solicitudPase.fechaPase).format(
        'YYYY-MM-DD',
      );
      const isOldPresente = moment(approvalDate).isAfter(day);
      if (isOldPresente) return true;
      return false;
    },
    [day],
  );

  const dayIsOnPropuesta = useCallback(() => {
    const propuesta = propuestasJE.find(
      (propuesta) => propuesta.seccion.idSeccion === selectedSection,
    );

    if (!propuesta) return false;

    const dayWeek = moment(day).format('dddd');
    const parseDayWeek = dayWeek === 'miércoles' ? 'miercoles' : dayWeek;
    const dia = propuesta.dias.find(
      (propuestaDia) => propuestaDia.dia.nombre.toLowerCase() === parseDayWeek,
    );

    return !!dia;
  }, [propuestasJE, day, selectedSection]);

  const isValidateRol = useCallback(
    (rol: string, alumno: AlumnoMap) => {
      const validateIDPresente = alumno.presentismo.some(
        (e) =>
          e.fecha === day &&
          e.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.NO_CORRESPONDE,
      );
      const validateRol =
        validateIDPresente &&
        (Number(rol) === ROL.DOCENTE || Number(rol) === ROL.PRECEPTOR)
          ? true
          : false;
      return validateRol;
    },
    [day],
  );

  const handleSelectedOption = useCallback(
    (item: EstadosPresentismo, cell: RenderTurnosCell) => {
      let presentismo: DeepPartial<PresenteGeneral>[] = [];
      const { idContraturnoDia, idTurno } = cell;
      const _periodo = periodoNuevo.find((periodo) =>
        moment(day, 'YYYY-MM-DD').isBetween(
          moment(periodo.fechaInicio, 'YYYY-MM-DD').format('YYYY-MM-DD'),
          moment(periodo.fechaFin, 'YYYY-MM-DD').format('YYYY-MM-DD'),
          undefined,
          '[]',
        ),
      );
      if (alumnos.length === 0) {
        // Masivo
        for (let alumno of allAlumnos) {
          const hasPassRequest = isDisableByPassRequest(alumno);
          const validateRolPresente = isValidateRol(rol, alumno);
          const isEnrolled = moment(alumno.enrolledAt, 'YYYY-MM-DD').isAfter(
            day,
          );
          const isPassSeccion =
            alumno.isChangeSeccion && moment(alumno.createdAt).isAfter(day);
          if (hasPassRequest) continue;
          if (validateRolPresente) continue;
          if (isPassSeccion) continue;
          if (isEnrolled) continue;
          presentismo.push({
            idPresente: null,
            alumno: alumno.alumno,
            estadoPresente: {
              idEstadoPresente: item.idEstadoPresente,
              descripcion: item.descripcion,
            },
            fecha: moment(day, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            seccion: {
              ...alumno.seccion,
            },
            turno: idTurno
              ? {
                  idTurno: idTurno,
                }
              : null,
            contraturnoDia: idContraturnoDia
              ? { idContraturnoDia: idContraturnoDia }
              : null,
            isJornadaExtendida: isTabJornadaExtendida,
            periodoNuevo: _periodo,
            espacioCurricular: selectedEspacioCurricular
              ? { idEspacioCurricular: selectedEspacioCurricular }
              : undefined,
          });
        }
      } else {
        // Only alumns selected by checkbox
        for (let alumno of alumnos) {
          const _alumno = allAlumnos.find(
            (alu) => alu.alumno.idAlumno === alumno.alumno.idAlumno,
          );
          if (!_alumno) continue;
          const validateRolPresente = isValidateRol(rol, _alumno);
          const isEnrolled = moment(_alumno.enrolledAt, 'YYYY-MM-DD').isAfter(
            day,
          );
          const hasPassRequest = isDisableByPassRequest(_alumno);
          const isPassSeccion =
            _alumno.isChangeSeccion && moment(_alumno.createdAt).isAfter(day);
          if (hasPassRequest) continue;
          if (validateRolPresente) continue;
          if (isPassSeccion) continue;
          if (isEnrolled) continue;
          presentismo.push({
            idPresente: undefined,
            alumno: alumno.alumno,
            estadoPresente: {
              idEstadoPresente: item.idEstadoPresente,
              descripcion: item.descripcion,
            },
            fecha: moment(day, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            seccion: {
              ...alumno.seccion,
            },
            turno: idTurno
              ? {
                  idTurno: idTurno,
                }
              : null,
            contraturnoDia: idContraturnoDia
              ? { idContraturnoDia: idContraturnoDia }
              : null,
            isJornadaExtendida: alumno.isJornadaExtendida,
            periodoNuevo: _periodo,
            espacioCurricular: selectedEspacioCurricular
              ? { idEspacioCurricular: selectedEspacioCurricular }
              : undefined,
          });
        }
      }

      presentismo = presentismo.map((pre) => {
        const dbPre = dbPresentismos.find((_pre) => {
          return (
            _pre.alumno.idAlumno === pre.alumno?.idAlumno &&
            _pre.isJornadaExtendida === pre.isJornadaExtendida &&
            _pre.fecha === pre.fecha &&
            (_pre.turno && pre.turno
              ? _pre.turno.idTurno === pre.turno.idTurno
              : pre.turno === _pre.turno) &&
            (_pre.contraturnoDia && pre.contraturnoDia
              ? _pre.contraturnoDia.idContraturnoDia ===
                pre.contraturnoDia.idContraturnoDia
              : pre.contraturnoDia === _pre.contraturnoDia)
          );
        });
        return dbPre ? { ...pre, idPresente: dbPre.idPresente } : pre;
      });

      setNewPresentismos((presente) => {
        let toSave = presente.filter(
          (pre) =>
            !presentismo.find(
              (_pre) =>
                _pre.alumno?.idAlumno === pre.alumno.idAlumno &&
                _pre.isJornadaExtendida === pre.isJornadaExtendida &&
                _pre.fecha === pre.fecha &&
                (_pre.turno && pre.turno
                  ? _pre.turno.idTurno === pre.turno.idTurno
                  : pre.turno === _pre.turno) &&
                (_pre.contraturnoDia && pre.contraturnoDia
                  ? _pre.contraturnoDia.idContraturnoDia ===
                    pre.contraturnoDia.idContraturnoDia
                  : pre.contraturnoDia === _pre.contraturnoDia),
            ),
        );
        for (const newPresente of presentismo) {
          const exist = presente.find((_pre) => {
            return (
              _pre.alumno.idAlumno === newPresente.alumno?.idAlumno &&
              _pre.isJornadaExtendida === newPresente.isJornadaExtendida &&
              _pre.fecha === newPresente.fecha &&
              (_pre.turno && newPresente.turno
                ? _pre.turno.idTurno === newPresente.turno.idTurno
                : newPresente.turno === _pre.turno) &&
              (_pre.contraturnoDia && newPresente.contraturnoDia
                ? _pre.contraturnoDia.idContraturnoDia ===
                  newPresente.contraturnoDia.idContraturnoDia
                : newPresente.contraturnoDia === _pre.contraturnoDia)
            );
          });
          if (exist) {
            toSave.push({ ...exist, ...(newPresente as PresenteGeneral) });
          } else {
            toSave.push(newPresente as PresenteGeneral);
          }
        }

        return toSave;
      });

      handleModalClosed();
    },
    [
      alumnos,
      setNewPresentismos,
      handleModalClosed,
      allAlumnos,
      isDisableByPassRequest,
      isValidateRol,
      rol,
      day,
      isTabJornadaExtendida,
      dbPresentismos,
      periodoNuevo,
      selectedEspacioCurricular,
    ],
  );

  const onClick = useCallback(
    (event: React.MouseEvent<HTMLElement>, index: number) => {
      setAnchorEl((prevState) => {
        const copy = [...prevState];
        copy[index] = event.currentTarget;
        return copy;
      });
    },
    [],
  );

  const disabledByRol = useEnableToSaveByRol(
    isTabJornadaExtendida,
    Number(rol),
  );

  const key = isTabJornadaExtendida
    ? 'je'
    : selectedEspacioCurricular
    ? 'ec'
    : 'jc';

  const validateDateData = dataCierrePresentismo
    ?.map((d) => d.dias[key])
    .flat();

  const formatvalidatedate = validateDateData?.map((d) =>
    moment(d, 'YYYY-MM-DD').format('YYYY-MM-DD'),
  );

  const itIsPadlockLock = formatvalidatedate.includes(
    moment(day).format('YYYY-MM-DD'),
  );

  const isEnableMasiveCheck = useMemo(() => {
    if (itIsPadlockLock && !selectedEspacioCurricular) return false;
    if (!isValidCicloLectivo) return false;
    if (isDayFuture) return false;
    if (isLockBlock) return false;
    if (isDisabled) return false;
    if (!tieneDiasHabilitados && selectedEspacioCurricular) return false;
    if (isFuture) return false;
    if (disabledByRol) return false;
    if (isFeriado) return false;
    if (isTabJornadaExtendida && !dayIsOnPropuesta()) return false;
    if (isSelected) return true;
    return false;
  }, [
    isDayFuture,
    isLockBlock,
    isDisabled,
    tieneDiasHabilitados,
    selectedEspacioCurricular,
    isFuture,
    disabledByRol,
    isFeriado,
    isTabJornadaExtendida,
    dayIsOnPropuesta,
    isSelected,
    itIsPadlockLock,
    isValidCicloLectivo,
  ]);

  const classesName = useMemo(() => {
    if (isSelected) return;
    return last ? classes.last : classes.border;
  }, [classes, isSelected, last]);

  const getClass = useCallback(
    (i: number, all: RenderTurnosCell[]) => {
      if (i === 0 && !isSelected) return classes.border;
      if (i > 0 && i < all.length) return classes.border;
      if (i === all.length - 1) return classes.last;
    },
    [classes, isSelected],
  );

  const celdas: RenderTurnosCell[] = useMemo(() => {
    const _celdas: RenderTurnosCell[] = [];
    let turnosCells = TURNOS[turno] ? TURNOS[turno] : [];
    if (selectedEspacioCurricular) {
      ASISTENCIA_GENERAL.forEach((ag) => {
        _celdas.push({
          label: ag.title,
          idTurno: ag.idTurno,
          descripcion: ag.title,
        });
      });
    } else {
      turnosCells.forEach((turno) => {
        const _hasSituacion = situaciones.find((situacion) =>
          situacion.secciones.find(
            (seccion) =>
              (seccion.idTurno === turno.idTurno.toString() ||
                seccion.idTurno === SECCION_TURNO.C.toString()) &&
              seccion.idSeccion === selectedSection &&
              (seccion.afectaJE === isTabJornadaExtendida ||
                (seccion.afectaJE === false &&
                  seccion.afectaJS === false &&
                  isTabJornadaExtendida === false)),
          ),
        );

        let _situacion:
          | {
              tipo: number;
              descripcion: string;
              motivo: string;
            }
          | undefined = undefined;

        if (_hasSituacion) {
          _situacion = {
            tipo: _hasSituacion?.tipoSituacion.idTipoSituacion,
            descripcion: _hasSituacion?.tipoSituacion.descripcion,
            motivo: _hasSituacion?.motivoSituacion.descripcion,
          };
        }
        _celdas.push({
          label: turno.title,
          idTurno: turno.idTurno,
          descripcion: TURNOS_MAP[turno.idTurno] || '',
          situacion: _situacion,
        });
      });

      contraturnos.forEach((contraturno, index) => {
        _celdas.push({
          label: `CT${index + 1}`,
          idContraturnoDia: contraturno.idContraturnoDia,
          materia: contraturno.EspacioCurricular.descripcion,
          descripcion: `CT${index + 1}`,
        });
      });
    }
    return _celdas;
  }, [
    isTabJornadaExtendida,
    contraturnos,
    turno,
    selectedSection,
    situaciones,
    selectedEspacioCurricular,
  ]);

  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      fontFamily: 'Open Sans',
    },
  }))(Tooltip);

  return (
    <TableCell
      colSpan={2}
      align="center"
      className={cx(
        classes.cell,
        classes[state],
        isSelected ? selectedClasses.selected : '',
        isSelected ? 'selected' : '',
      )}
    >
      <Grid component={Box} width="100%" height="100%" display="flex">
        {turno ? (
          celdas.map((t, i, all) => (
            <Grid
              item
              key={`turnocell-${t.idTurno || t.idContraturnoDia}-${i}`}
              className={cx(
                classes.content,
                classes[state],
                getClass(i, all),
                last && i === all.length - 1 && !isSelected ? classes.last : '',
              )}
            >
              {t.label}
              {!t.situacion && isEnableMasiveCheck && (
                <>
                  <IconButton
                    onClick={(event) => onClick(event, i)}
                    size={'small'}
                    style={{ marginRight: '-20px', zIndex: 3 }}
                    className={stateClassName}
                  >
                    <ExpandMoreIcon fontSize={'inherit'} />
                  </IconButton>
                  <ModalMenu
                    title={`Indicar asistencia del turno ${t.descripcion.toLocaleLowerCase()}`}
                    modalRef={modalRef}
                    onSelectOption={(estado) => handleSelectedOption(estado, t)}
                    anchorEl={anchorEl[i]}
                    onClosed={handleModalClosed}
                    isTabJornadaExtendida={isTabJornadaExtendida}
                    isContraturno={Boolean(t.idContraturnoDia)}
                    isEspacioCurricular={Boolean(selectedEspacioCurricular)}
                  />
                </>
              )}
              {t.situacion && (
                <Grid
                  component={Box}
                  display="flex"
                  paddingLeft={2.5}
                  color={COLOR_DAY[t.situacion.tipo || 0][0]}
                >
                  <HtmlTooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">
                          • {t.situacion.descripcion}
                        </Typography>
                        <p>- {t.situacion.motivo}</p>
                      </React.Fragment>
                    }
                  >
                    <AnnouncementIcon />
                  </HtmlTooltip>
                </Grid>
              )}
            </Grid>
          ))
        ) : (
          <div
            style={{ height: '100%' }}
            className={cx(
              classes.content,
              last ? classes.last : classes.border,
            )}
          />
        )}
      </Grid>
    </TableCell>
  );
};
