import React, { useState } from 'react';
import { CargaPip } from './components/CargaPip';
import { AlumnosTablePip } from './components/AlumnosTablePip';
import { useRolesContext } from 'src/context/RolesContext';
import { formValues, InformePipState } from '../informesTypes';

const AUTHORIZED_TO_OPEN = {
  '1': 'director',
  '5': 'equipo de conduccion',
};

export const PuenteInicialPrimaria = () => {
  const [informePip, setInformePip] = useState<InformePipState>();
  const [formValues, setFormValues] = useState<formValues>({
    anio: '',
    seccion: '',
    estado: '',
  });

  const {
    selectedRole: { rol },
  } = useRolesContext();

  const isAuthorizedToOpen = rol in AUTHORIZED_TO_OPEN;

  return (
    <>
      {!informePip?.row ? (
        <AlumnosTablePip
          setInformePip={setInformePip}
          formValues={formValues}
          setFormValues={setFormValues}
          isAuthorizedToOpen={isAuthorizedToOpen}
        />
      ) : (
        <CargaPip
          informePip={informePip}
          setInformePip={setInformePip}
          isAuthorizedToOpen={isAuthorizedToOpen}
        />
      )}
    </>
  );
};
