import { useEffect } from 'react';
import { useContenidoEspecificoLibbyCall } from 'src/app/business';
import { ContenidoEspecifico } from 'src/app/models';
import { useArealInterarealContext } from '../../context';

export const useContenidosEspecificosFetch = () => {
  const { areas } = useArealInterarealContext();

  const {
    data: contenidosEspecificos = [],
    working,
    recall: recallContenidoEspecifico,
  } = useContenidoEspecificoLibbyCall<ContenidoEspecifico[]>({
    methodName: 'filtroAreas',
    params: areas,
  });
  useEffect(() => {
    if (areas) recallContenidoEspecifico(areas);
  }, [areas, recallContenidoEspecifico]);

  return { contenidosEspecificos, working };
};
