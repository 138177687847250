import { makeBusinessEntity } from '../../lib/libby/generator';
import { TipoPeriodo } from '../models';

const DAO_NAME = 'tipo_periodo';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<TipoPeriodo>(DAO_NAME);

// hooks
export const useTipoPeriodoFetchById = fetchById;
export const useTipoPeriodoLibbyCall = libbyCall;
export const useTipoPeriodoDAO = libbyDAO;
export const useTipoPeriodoLibbyFetch = libbyFetch;

// components/hoc
export const TipoPeriodoAutocomplete = entityAutocomplete;
export const TipoPeriodoCall = renderPropCall;
export const TipoPeriodoById = renderPropFetchById;
export const TipoPeriodoSelect = simpleSelect;
export const TipoPeriodoAccordionSelector = accordionSelector;
export const TipoPeriodoSelectedChips = selectedChips;

// context
// ByIdContext
export const useTipoPeriodoByIdContext = useByIdContext;
export const TipoPeriodoByIdProvider = ByIdProvider;
export const TipoPeriodoByIdContext = ByIdContext;
// GeneralContext
export const useTipoPeriodoContext = useGeneralContext;
export const TipoPeriodoProvider = GeneralProvider;
export const TipoPeriodoContext = GeneralContext;
