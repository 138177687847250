import React from 'react';
import { FormApi, SubmissionErrors } from 'final-form';
import { FormContextProvider } from '../../../../../../../../../lib/templates/context';
import { FeedbackForm } from './FeedbackForm';
import { Template } from '../../../../../../../../../lib/templates';
import { FeedbackTemplate } from '../../../../../../../../../commons/types';
import { FormFooter } from '../FormFooter';

export type FeedbackTemplateCompleteValue = {
  feedback: FeedbackTemplate[];
};

const initialValues: FeedbackTemplateCompleteValue = {
  feedback: [],
};

const template: Template = {
  name: 'Feedback Template',
  template: [
    {
      name: 'feedback',
      type: {
        name: 'array',
        kind: [
          {
            name: 'item',
            type: { name: 'number' },
          },
          {
            name: 'opcion',
            type: { name: 'number' },
          },
          {
            name: 'comentario',
            type: { name: 'string' },
            optional: true,
          },
        ],
      },
    },
  ],
};

export interface PlanificacionFeedbackFormProps {
  onSubmit: (
    values: FeedbackTemplateCompleteValue,
    form: FormApi<FeedbackTemplateCompleteValue, any>,
    callback?: (errors?: SubmissionErrors) => void,
  ) =>
    | SubmissionErrors
    | Promise<SubmissionErrors | undefined>
    | undefined
    | void;
  data?: FeedbackTemplateCompleteValue | any;
}

export const PlanificacionFeedbackForm = ({
  onSubmit,
  data,
}: PlanificacionFeedbackFormProps) => (
  <FormContextProvider
    initialValues={data?.feedback || initialValues}
    onSubmit={onSubmit}
    template={template}
  >
    <>
      <FeedbackForm edit={!data} />
      <FormFooter />
    </>
  </FormContextProvider>
);
