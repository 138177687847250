import { LibbyFetchDAO } from './LibbyFetchDAO';

export class BoletinAlumnoDAO extends LibbyFetchDAO {
  constructor() {
    super('boletin_alumno', 'idBoletinAlumno');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
