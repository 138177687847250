import { LibbyFetchDAO } from './LibbyFetchDAO';

export class UnidadEducativaDAO extends LibbyFetchDAO {
  constructor() {
    super('unidad_educativa', 'idUnidadEducativa');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
