import { Typography } from '@material-ui/core';
import React from 'react';
import { Footer } from 'src/commons';
import customFormDialog from 'src/commons/services/customFormDialog';

export const ModalConfirmarSalida = ({ exit, saveAndExit }: any) => {
  const footerButtons = [
    {
      title: 'Salir',
      type: 'primary',
      handleOnClick: () => {
        exit();
        customFormDialog.handleCancel();
      },
    },
    {
      title: 'Salir y Guardar',
      type: 'primary',
      handleOnClick: () => {
        saveAndExit();
      },
    },
    {
      title: 'Permanecer en esta pantalla',
      type: 'primary',
      handleOnClick: () => customFormDialog.handleCancel(),
    },
  ];

  return (
    <>
      <Typography style={{ marginLeft: '15px', marginBottom: '15px' }}>
        La información precargada se perderá en caso que no guarde, por favor
        seleccione una opción.
      </Typography>
      <Footer buttonConfig={footerButtons} spacing={3} position="center" />
    </>
  );
};
