import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { PlanEstudioNivel } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'plan_estudio_nivel';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPlanEstudioNivel',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPlanEstudioNivel',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.PlanEstudioNivel
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.PlanEstudioNivel,
      hoc: { ...defaultOptions.hoc, ...businessOptions.PlanEstudioNivel.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<PlanEstudioNivel>(DAO_NAME, optionsToUse);

// hooks
export const usePlanEstudioNivelFetchById = fetchById;
export const usePlanEstudioNivelLibbyCall = libbyCall;
export const usePlanEstudioNivelDAO = libbyDAO;
export const usePlanEstudioNivelLibbyFetch = libbyFetch;

// components/hoc
export const PlanEstudioNivelAutocomplete = entityAutocomplete;
export const PlanEstudioNivelCall = renderPropCall;
export const PlanEstudioNivelById = renderPropFetchById;
export const PlanEstudioNivelSelect = simpleSelect;
export const PlanEstudioNivelAccordionSelector = accordionSelector;
export const PlanEstudioNivelSelectedChips = selectedChips;

// context
// ByIdContext
export const usePlanEstudioNivelByIdContext = useByIdContext;
export const PlanEstudioNivelByIdProvider = ByIdProvider;
export const PlanEstudioNivelByIdContext = ByIdContext;
// GeneralContext
export const usePlanEstudioNivelContext = useGeneralContext;
export const PlanEstudioNivelProvider = GeneralProvider;
export const PlanEstudioNivelContext = GeneralContext;
