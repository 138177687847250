import { JeMateria } from 'src/app/models';
import { LibbyFetchDAO } from './LibbyFetchDAO';

export class JeMateriasDAO extends LibbyFetchDAO {
  constructor() {
    super('je_materias', 'idJeMateria');
  }

  async getMaterias(
    idJeMateria: number,
    limit = 50,
    orderBy = 'descripcion',
    aspect?: string,
  ) {
    if (aspect) this.aspect(aspect);

    const data: JeMateria[] = await this.fetch({
      orderBy,
      limit,
    });

    const jornadaExtendidaMateriaDescripcion = data.find(
      (element: JeMateria) => element.idJEMateria === idJeMateria,
    )?.descripcion;

    return {
      data,
      descripcion: jornadaExtendidaMateriaDescripcion,
    };
  }

  async getAll(
    limit = 50,
    orderBy = 'descripcion',
    aspect?: string,
    filters?: {
      [k: string | number]: {
        value: string | number;
        path: string;
        method: string;
      }[];
    },
  ) {
    if (aspect) this.aspect(aspect);

    const data: JeMateria[] = await this.fetch({
      orderBy,
      limit,
      ...(Boolean(filters) && { filter: filters }),
    });

    return data;
  }
}
