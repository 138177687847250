import { LibbyFetchDAO } from './LibbyFetchDAO';

export class LocalizacionPlanEstudioNivelDAO extends LibbyFetchDAO {
  constructor() {
    super('localizacion_plan_estudio_nivel', 'idPlanEstudioLocalizacion');
  }

  getByLocalizationAndLevel(
    localization: number,
    level: number,
    modalidad: number,
  ) {
    this.query()
      .equals('localizacion', localization)
      .equals('planEstudioNivel.modalidadNivel.nivel', level)
      .equals('planEstudioNivel.modalidadNivel.modalidad', modalidad)
      .run();
  }

  getByIdLocalizacion(id: number | string) {
    return this.query().equals('localizacion', id).run();
  }
}
