import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Anio } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'anio';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idAnio',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idAnio',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Anio
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Anio,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Anio.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
    selectInput,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Anio>(DAO_NAME, optionsToUse);

// hooks
export const useAnioFetchById = fetchById;
export const useAnioLibbyCall = libbyCall;
export const useAnioDAO = libbyDAO;
export const useAnioLibbyFetch = libbyFetch;

// components/hoc
export const AnioAutocomplete = entityAutocomplete;
export const AnioCall = renderPropCall;
export const AnioById = renderPropFetchById;
export const AnioSelect = simpleSelect;

export const AnioSelectInput = selectInput;
export const AnioAccordionSelector = accordionSelector;
export const AnioSelectedChips = selectedChips;

// context
// ByIdContext
export const useAnioByIdContext = useByIdContext;
export const AnioByIdProvider = ByIdProvider;
export const AnioByIdContext = ByIdContext;
// GeneralContext
export const useAnioContext = useGeneralContext;
export const AnioProvider = GeneralProvider;
export const AnioContext = GeneralContext;
