import React, { useContext } from 'react';

export type EpaLabelsContextType = {
  [k: string]: string;
};

const initialContextValues = {
  moduleName: 'EPA',
  participatedLabel:
    '¿Participó del Proyecto de Articulación Primaria - Secundaria (Empezando primer año) Ciclo 2022?',
  informeTxtLabel: 'Informe',
  fileInputLabel: 'Adjuntar informe',
  placeholderFileInput: 'Seleccione un archivo',
};

export const useEpaLabelsContext = () => useContext(EpaLabelsContext);

export const EpaLabelsContext = React.createContext<EpaLabelsContextType>({});

/**
 * Providers de etiquetas de EPA
 * @param children
 * @returns
 */
export const EpaLabelsProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  return (
    <EpaLabelsContext.Provider value={initialContextValues}>
      {children}
    </EpaLabelsContext.Provider>
  );
};
