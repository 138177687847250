import { makeStyles } from '@material-ui/core';
import { grey, primary } from 'src/theme/colors';
import { withStyles } from '@material-ui/core/styles';
import TableRow from "@material-ui/core/TableRow";

export const tableStyles = makeStyles({
  table: {
    '& td': {
      border: `1px solid ${grey.dividers}`,
      padding: '6px',
    },
    '& th': {
      border: `1px solid ${grey.dividers}`,
      textAlign: 'center',
      padding: '6px',
    },
    marginBottom: '60px',
  },
  day: {
    width: 60,
    height: 40,
  },
  iconObservacion: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'end',
    color: primary.lightBlue,
    marginLeft: '20px',
  },
  observaciones: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '400px',
    marginTop: '10px',
    textAlign: 'right',
    color: 'black',
  },
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: '#F9F9F9',
    },
    '& th': {
      borderBottom: 'none',
    },
    '& td': {
      borderBottom: 'none',
    },
    height: 50,
    borderLeft: '1px solid #EBEBEB',
    borderRight: '1px solid #EBEBEB',
    '&:last-child > .selected:after,.selected:before': {
      borderBottomLeftRadius: 17,
      borderBottomRightRadius: 17,
      borderBottom: '3px solid #BEC0C4',
    },
  },
}))(TableRow);

export const StyledLockRow = withStyles({
  root: {
    height: 40,
    aligneItems: 'flex-end',
    '&:first-child > .selected:after,.selected:before': {
      borderTopRightRadius: 17,
      borderTopLeftRadius: 17,
      borderTop: '3px solid #BEC0C4',
    },
  },
})(TableRow);

export const useStyles = makeStyles({
  root: {
    paddingTop: 10,
    marginTop: 10,
    scrollbarWidth: 'thin',
    scrollbarColor: '#38485C #DFE7F0',
    '&::-webkit-scrollbar': {
      width: 12,
    },
    '&::-webkit-scrollbar-track': {
      background: '#DFE7F0',
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#38485C',
      borderRadius: 3,
    },
    height: '100%',
    maxHeight: 800,
    minWidth: 1000,
    marginBottom: 60,
  },
  table: { minWidth: 1000, maxHeight: 400 },
  headLock: { height: 20 },
  cell: {
    padding: 0,
    height: 15,
    fontSize: 10,
  },
  border: {
    borderLeft: '1px solid #EBEBEB',
    borderTop: '1px solid #EBEBEB',
    borderStartStartRadius: 12,
  },
  icon: {
    width: 24,
    height: 24,
  },
  cellButton: { padding: 0, height: 15, position: 'relative' },
  button: {
    color: '#007BC7',
    textTransform: 'none',
    backgroundColor: '#F5F9FC',
    boxShadow: 'none',
    height: 34,
    fontSize: 13,
    borderRadius: 4,
    fontFamily: 'Nunito',
  },
  buttonDisabled: {
    color: '#9EAAB8!important',
    backgroundColor: '#F5F9FC!important',
    height: 34,
  },
  names: { paddingLeft: 10 },
  nameCell: { width: 70 },
  sortIcon: { height: 10, width: 10 },
  special: {
    width: 50,
  },
  checkboxCell: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 50,
  },
  iconButton: {
    width: 33,
    height: 33,
    backgroundColor: '#F5F9FC',
    color: '#3786EE',
  },
  endCell: {
    borderRight: '1px solid #EBEBEB',
    borderTop: '1px solid #EBEBEB',
    borderStartEndRadius: 12,
  },
  noBorderBotton: { borderBottom: 'none' },
  headerSticky: {
    top: -10,
    left: 0,
    background: 'solid',
    zIndex: 1,
    position: 'sticky',
    backgroundColor: '#ffff',
  },
});