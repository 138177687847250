import React from 'react';
import { TableColumn } from '../../TableColumn';
import { FILTERS } from '../../table_filters';
import {
  PresentismoEstudianteDia,
  PresentismoEstudianteMes,
  PresentismoEstudianteReport,
  PresentismoEstudianteSemana,
  PresentismoSeccionReport,
} from '../../types';
import { TooltipColumn } from '../../components/TooltipColumn';

// Reporte de presentismo por estudiante por día
export const PRESENTISMO_DIA_COLUMNS = [
  new TableColumn<PresentismoEstudianteDia>('cue_anexo'),
  new TableColumn<PresentismoEstudianteDia>('establecimiento'),
  new TableColumn<PresentismoEstudianteDia>('distrito_escolar'),
  new TableColumn<PresentismoEstudianteDia>('apellido'),
  new TableColumn<PresentismoEstudianteDia>('nombre'),
  new TableColumn<PresentismoEstudianteDia>('tipo_documento'),
  new TableColumn<PresentismoEstudianteDia>('documento'),
  new TableColumn<PresentismoEstudianteDia>('año'),
  new TableColumn<PresentismoEstudianteDia>('seccion'),
  new TableColumn<PresentismoEstudianteDia>(
    'presente',
    'Presentismo registrado',
  ),
  new TableColumn<PresentismoEstudianteDia>(
    'propuesta',
    'Propuesta de Enseñanza',
  ),
  new TableColumn<PresentismoEstudianteDia>(
    'continuidad',
    'Continuidad Pedagógica',
  ),
];

export const PRESENTISMO_DIA_FILTERS = [
  FILTERS.dependencia_funcional,
  FILTERS.distrito_escolar,
  FILTERS.localizacion,
  FILTERS.seccion,
  FILTERS.anio,
  FILTERS.turno,
  FILTERS.dni,
];

// Reporte de presentismo por estudiante por semana
export const PRESENTISMO_SEMANA_COLUMNS = [
  new TableColumn<PresentismoEstudianteSemana>('cue_anexo'),
  new TableColumn<PresentismoEstudianteSemana>('establecimiento'),
  new TableColumn<PresentismoEstudianteSemana>('distrito_escolar'),
  new TableColumn<PresentismoEstudianteSemana>('apellido'),
  new TableColumn<PresentismoEstudianteSemana>('nombre'),
  new TableColumn<PresentismoEstudianteSemana>('tipo_documento'),
  new TableColumn<PresentismoEstudianteSemana>('documento'),
  new TableColumn<PresentismoEstudianteSemana>('año'),
  new TableColumn<PresentismoEstudianteSemana>('seccion'),
  new TableColumn<PresentismoEstudianteSemana, React.ReactNode>(
    'presentes',
    'Presentismo registrado',
    (value, row) => (
      <TooltipColumn
        title={`${((Number(row.presentes) / Number(row.total)) * 100).toFixed(
          2,
        )}%`}
      />
    ),
  ),
  new TableColumn<PresentismoEstudianteSemana>(
    'propuesta_mixta',
    'Propuesta mixta',
  ),
  new TableColumn<PresentismoEstudianteSemana>(
    'propuesta_presencial',
    'Propuesta presencial',
  ),
  new TableColumn<PresentismoEstudianteSemana>(
    'propuesta_virtual',
    'Propuesta virtual',
  ),
  new TableColumn<PresentismoEstudianteSemana>(
    'continuidad',
    'Continuidad Pedagógica',
  ),
];

export const PRESENTISMO_SEMANA_FILTERS = [
  FILTERS.dependencia_funcional,
  FILTERS.distrito_escolar,
  FILTERS.localizacion,
  FILTERS.seccion,
  FILTERS.anio,
  FILTERS.turno,
  FILTERS.dni,
];

// Reporte de presentismo por estudiante por mes
export const PRESENTISMO_MES_COLUMNS = [
  new TableColumn<PresentismoEstudianteMes>('cue_anexo'),
  new TableColumn<PresentismoEstudianteMes>('establecimiento'),
  new TableColumn<PresentismoEstudianteMes>('distrito_escolar'),
  new TableColumn<PresentismoEstudianteMes>('apellido'),
  new TableColumn<PresentismoEstudianteMes>('nombre'),
  new TableColumn<PresentismoEstudianteMes>('tipo_documento'),
  new TableColumn<PresentismoEstudianteMes>('documento'),
  new TableColumn<PresentismoEstudianteMes>('año'),
  new TableColumn<PresentismoEstudianteMes>('seccion'),
  new TableColumn<PresentismoEstudianteMes, React.ReactNode>(
    'presentes',
    'Presentismo registrado',
    (value, row) => (
      <TooltipColumn
        title={`${((Number(row.presentes) / Number(row.total)) * 100).toFixed(
          2,
        )}%`}
      />
    ),
  ),
  new TableColumn<PresentismoEstudianteMes>(
    'propuesta_mixta',
    'Propuesta mixta',
  ),
  new TableColumn<PresentismoEstudianteMes>(
    'propuesta_presencial',
    'Propuesta presencial',
  ),
  new TableColumn<PresentismoEstudianteMes>(
    'propuesta_virtual',
    'Propuesta virtual',
  ),
  new TableColumn<PresentismoEstudianteMes>(
    'continuidad',
    'Continuidad Pedagógica',
  ),
];

export const PRESENTISMO_MES_FILTERS = [
  FILTERS.dependencia_funcional,
  FILTERS.distrito_escolar,
  FILTERS.localizacion,
  FILTERS.seccion,
  FILTERS.anio,
  FILTERS.turno,
  FILTERS.dni,
];

// Reporte de presentismo por estudiante por mes
export const PRESENTISMO_SECCION_DIA_COLUMNS = [
  new TableColumn<PresentismoSeccionReport>('cue_anexo'),
  new TableColumn<PresentismoSeccionReport>('establecimiento'),
  new TableColumn<PresentismoSeccionReport>('distrito_escolar'),
  new TableColumn<PresentismoSeccionReport>('año'),
  new TableColumn<PresentismoSeccionReport>('seccion'),
  new TableColumn<PresentismoSeccionReport, React.ReactNode>(
    'presentes',
    'Presentismo registrado',
    (value, row) => (
      <TooltipColumn
        title={`${((Number(row.presentes) / Number(row.total)) * 100).toFixed(
          2,
        )}%`}
      />
    ),
  ),
  new TableColumn<PresentismoSeccionReport, React.ReactNode>(
    'ausentes',
    'Ausentismo registrado',
    (value, row) => (
      <TooltipColumn
        title={`${((Number(row.ausentes) / Number(row.total)) * 100).toFixed(
          2,
        )}%`}
      />
    ),
  ),
  new TableColumn<PresentismoSeccionReport>(
    'propuesta_mixta',
    'Propuesta mixta',
  ),
  new TableColumn<PresentismoSeccionReport>(
    'propuesta_presencial',
    'Propuesta presencial',
  ),
  new TableColumn<PresentismoSeccionReport>(
    'propuesta_virtual',
    'Propuesta virtual',
  ),
  new TableColumn<PresentismoSeccionReport>(
    'continuidad',
    'Continuidad Pedagógica',
  ),
];

export const PRESENTISMO_SECCION_DIA_FILTERS = [
  FILTERS.dependencia_funcional,
  FILTERS.distrito_escolar,
  FILTERS.localizacion,
  FILTERS.seccion,
  FILTERS.anio,
  FILTERS.turno,
];

// Reporte de presentismo por estudiante por mes
export const PRESENTISMO_SECCION_SEMANA_COLUMNS = [
  new TableColumn<PresentismoSeccionReport>('cue_anexo'),
  new TableColumn<PresentismoSeccionReport>('establecimiento'),
  new TableColumn<PresentismoSeccionReport>('distrito_escolar'),
  new TableColumn<PresentismoSeccionReport>('año'),
  new TableColumn<PresentismoSeccionReport>('seccion'),
  new TableColumn<PresentismoSeccionReport, React.ReactNode>(
    'presentismo_reg',
    'Presentismo',
    (value, row) => (
      <TooltipColumn
        title={`${((Number(row.presentes) / Number(row.total)) * 100).toFixed(
          2,
        )}%`}
      />
    ),
  ),
  new TableColumn<PresentismoSeccionReport>(
    'propuesta_mixta',
    'Propuesta mixta',
  ),
  new TableColumn<PresentismoSeccionReport>(
    'propuesta_presencial',
    'Propuesta presencial',
  ),
  new TableColumn<PresentismoSeccionReport>(
    'propuesta_virtual',
    'Propuesta virtual',
  ),
  new TableColumn<PresentismoSeccionReport>(
    'continuidad',
    'Continuidad Pedagógica',
  ),
];

export const PRESENTISMO_SECCION_SEMANA_FILTERS = [
  FILTERS.dependencia_funcional,
  FILTERS.distrito_escolar,
  FILTERS.localizacion,
  FILTERS.seccion,
  FILTERS.anio,
  FILTERS.turno,
];

// Reporte de presentismo por estudiante por mes
export const PRESENTISMO_SECCION_MES_COLUMNS = [
  new TableColumn<PresentismoSeccionReport>('cue_anexo'),
  new TableColumn<PresentismoSeccionReport>('establecimiento'),
  new TableColumn<PresentismoSeccionReport>('distrito_escolar'),
  new TableColumn<PresentismoSeccionReport>('año'),
  new TableColumn<PresentismoSeccionReport>('seccion'),
  new TableColumn<PresentismoSeccionReport, React.ReactNode>(
    'presentismo_reg',
    'Presentismo',
    (value, row) => (
      <TooltipColumn
        title={`${((Number(row.presentes) / Number(row.total)) * 100).toFixed(
          2,
        )}%`}
      />
    ),
  ),
  new TableColumn<PresentismoSeccionReport>(
    'propuesta_mixta',
    'Propuesta mixta',
  ),
  new TableColumn<PresentismoSeccionReport>(
    'propuesta_presencial',
    'Propuesta presencial',
  ),
  new TableColumn<PresentismoSeccionReport>(
    'propuesta_virtual',
    'Propuesta virtual',
  ),
  new TableColumn<PresentismoSeccionReport>(
    'continuidad',
    'Continuidad Pedagógica',
  ),
];

export const PRESENTISMO_SECCION_MES_FILTERS = [
  FILTERS.dependencia_funcional,
  FILTERS.distrito_escolar,
  FILTERS.localizacion,
  FILTERS.seccion,
  FILTERS.anio,
  FILTERS.turno,
];
