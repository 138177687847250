import { Seccion, Turno } from 'src/app/models';
import { MAPPED_PASE_ANIO_OPTIONS_SECUNDARIO } from './mappedPaseAnioOptions';

const _1_ANIO = 21;
const _2_ANIO = 22;
const _3_ANIO = 23;
const _4_ANIO = 24;
const _5_ANIO = 25;
const _6_ANIO = 26;

const {
  Promociona: PROMOCIONA,
  'Promoción directa': PROMOCION_DIRECTA,
  Egresa: EGRESA,
  Permanece: PERMANECE,
  'Promoción en proceso': PROMOCION_EN_PROCESO,
  'Promoción acompañada': PROMOCION_ACOMPANADA,
  'Completa estudios': COMPLETA_ESTUDIOS,
  'Continúa con apoyo para la promoción': CONTINUA_CON_APOYO_PARA_PROMOCION,
  'Con promoción acompañada': PROMOCION_CON_APOYO,
  'No completó el Plan de Estudio': NO_COMPLETO_PLAN_DE_ESTUDIOS,
} = MAPPED_PASE_ANIO_OPTIONS_SECUNDARIO;

const MAPA_OPCIONES_DE_SECCION_A_LA_QUE_PASA: {
  [key: number]: { [key: number]: number[] };
} = {};

[_1_ANIO, _2_ANIO, _3_ANIO, _4_ANIO, _5_ANIO, _6_ANIO].forEach((ANIO) => {
  MAPA_OPCIONES_DE_SECCION_A_LA_QUE_PASA[ANIO] = {
    [PROMOCION_DIRECTA.id]: [
      ...(ANIO === _1_ANIO ? [_2_ANIO] : []),
      ...(ANIO === _2_ANIO ? [_3_ANIO] : []),
      ...(ANIO === _3_ANIO ? [_4_ANIO] : []),
      ...(ANIO === _4_ANIO ? [_5_ANIO] : []),
      ...(ANIO === _5_ANIO ? [_6_ANIO] : []),
      ...(ANIO === _6_ANIO ? [] : []),
    ],
    [PROMOCIONA.id]: [
      ...(ANIO === _1_ANIO ? [_2_ANIO] : []),
      ...(ANIO === _2_ANIO ? [_3_ANIO] : []),
      ...(ANIO === _3_ANIO ? [_4_ANIO] : []),
      ...(ANIO === _4_ANIO ? [_5_ANIO] : []),
      ...(ANIO === _5_ANIO ? [_6_ANIO] : []),
      ...(ANIO === _6_ANIO ? [] : []),
    ],
    [PERMANECE.id]: [
      ...(ANIO === _1_ANIO ? [] : []),
      ...(ANIO === _2_ANIO ? [_2_ANIO] : []),
      ...(ANIO === _3_ANIO ? [_3_ANIO] : []),
      ...(ANIO === _4_ANIO ? [_4_ANIO] : []),
      ...(ANIO === _5_ANIO ? [_5_ANIO] : []),
      ...(ANIO === _6_ANIO ? [] : []),
    ],
    [EGRESA.id]: [],
    [PROMOCION_EN_PROCESO.id]: [
      ...(ANIO === _1_ANIO ? [] : []),
      ...(ANIO === _2_ANIO ? [] : []),
      ...(ANIO === _3_ANIO ? [] : []),
      ...(ANIO === _4_ANIO ? [] : []),
      ...(ANIO === _5_ANIO ? [] : []),
      ...(ANIO === _6_ANIO ? [] : []),
    ],
    [PROMOCION_ACOMPANADA.id]: [],
    [COMPLETA_ESTUDIOS.id]: [
      ...(ANIO === _1_ANIO ? [] : []),
      ...(ANIO === _2_ANIO ? [] : []),
      ...(ANIO === _3_ANIO ? [] : []),
      ...(ANIO === _4_ANIO ? [] : []),
      ...(ANIO === _5_ANIO ? [] : []),
      ...(ANIO === _6_ANIO ? [] : []),
    ],
    [CONTINUA_CON_APOYO_PARA_PROMOCION.id]: [],
    [PROMOCION_CON_APOYO.id]: [
      ...(ANIO === _1_ANIO ? [_2_ANIO] : []),
      ...(ANIO === _2_ANIO ? [_3_ANIO] : []),
      ...(ANIO === _3_ANIO ? [_4_ANIO] : []),
      ...(ANIO === _4_ANIO ? [_5_ANIO] : []),
      ...(ANIO === _5_ANIO ? [_6_ANIO] : []),
      ...(ANIO === _6_ANIO ? [] : []),
    ],
    [NO_COMPLETO_PLAN_DE_ESTUDIOS.id]: [
      ...(ANIO === _1_ANIO ? [] : []),
      ...(ANIO === _2_ANIO ? [] : []),
      ...(ANIO === _3_ANIO ? [] : []),
      ...(ANIO === _4_ANIO ? [] : []),
      ...(ANIO === _5_ANIO ? [] : []),
      ...(ANIO === _6_ANIO ? [] : []),
    ],
  };
});

export const getFilterPassSectionSecundariaLogic = (
  options: any,
  statusPass: any,
  currentCicloLectivo: any,
  idAnio: any,
  turno?: Turno,
) => {
  const siguienteCicloLectivo: number = currentCicloLectivo + 1;
  const filteredOptionsSecundaria = Boolean(idAnio)
    ? options
        .filter((option: Seccion) => {
          return (
            MAPA_OPCIONES_DE_SECCION_A_LA_QUE_PASA[Number(idAnio)][
              statusPass
            ]?.includes(Number(option?.anio?.idAnio)) &&
            option?.cicloLectivo?.anio === siguienteCicloLectivo
          );
        })
        .sort((a: Seccion, b: Seccion) => {
          if (turno && a.turno?.idTurno === turno.idTurno) {
            return -1;
          } else if (turno && b.turno?.idTurno === turno.idTurno) {
            return 1;
          } else {
            return 0;
          }
        })
    : [];

  return filteredOptionsSecundaria;
};
