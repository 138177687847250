import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { Box, Checkbox, IconButton, Tooltip } from '@material-ui/core';
import moment from 'moment';
import CheckIcon from '@material-ui/icons/Check';
import CancelIcon from '@material-ui/icons/Cancel';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import { loadingModal } from 'src/commons';
import { useSnackbar } from 'notistack';
import { useUsuarioRolTraverseActionDAO } from 'src/app/business/security/UsuarioRolTraverseAction';
import { ROL } from 'src/commons/const/rol';
import { ROL_STATUS } from 'src/commons/const/rolStatus';
import customFormDialog from 'src/commons/services/customFormDialog';
import {
  AutoRegistracion,
  UsuarioRolEstablecimiento,
  UsuarioRolTraverseAction,
  Planta,
  Rol,
  Nivel,
  Localizacion,
  Area,
  RolStatus,
  Seccion,
  EspacioCurricular,
  Cuentas,
} from '../../../../app/models';
import confirmDialog from '../../../../commons/services/confirmDialog';
import { InfoTableColumn } from '../../../../commons/types';
import { primary } from '../../../../theme/colors';
import {
  useUsuarioRolEstablecimientoDAO,
  usePlantaDAO,
  usePersonalEducativoDAO,
  useModalidadNivelDAO,
  useAutoRegistracionDAO,
  usePersonaDAO,
} from '../../../../app/business';
import { FormGestionRolesDialog } from '../components/GestionRolesHeader/components';

const customStyles = {
  iconColor: { color: primary.paleBlueStrong },
  iconDisabled: { color: primary.disabled },
};

interface AutoRegistracionIcon extends AutoRegistracion {
  icon: JSX.Element;
}

interface AutoregisterMultiple {
  rolUsuario: Rol;
  nivel: Nivel;
  usuario: Cuentas;
  localizacion: Localizacion;
  areas: Array<Area>;
  rolStatus: RolStatus;
  espacioSeccion: { seccion: Seccion; espacio: EspacioCurricular }[];
}

const useGroupListColumnsStyles = makeStyles(() => ({
  iconButton: {
    padding: 0,
  },
}));

enum ACTION_TYPE {
  SAVE = 1,
  REMOVE = 2,
}

enum TRAVERSE_ID {
  SECCION = 4,
  ESPACIO_CURRICULAR = 5,
  AREA = 8,
}

export const useGestionRolesColumns = (
  reFetch: () => void,
  data: AutoRegistracion[],
) => {
  const [checkedUserRoles, setCheckedUserRoles] = useState<number[]>([]);
  const [userRolesData, setUserRolesData] = useState<AutoRegistracion[]>([]);

  useEffect(() => {
    setUserRolesData(data);
  }, [data, setUserRolesData]);

  const classes = useGroupListColumnsStyles();
  const { enqueueSnackbar } = useSnackbar();
  const autoRegistracionDAO = useAutoRegistracionDAO();
  const usuarioRolEstablecimiento = useUsuarioRolEstablecimientoDAO();
  const usuarioRolTraverseActionDAO = useUsuarioRolTraverseActionDAO();
  const modalidadNivelDAO = useModalidadNivelDAO();
  const personalEducativoDAO = usePersonalEducativoDAO();
  const plantaDAO = usePlantaDAO();
  const personaDAO = usePersonaDAO();

  const handleAddRemoveUsuarioRolEstablecimiento = useCallback(
    async (row: AutoregisterMultiple, actionType: ACTION_TYPE) => {
      const rol = row.rolUsuario.idRolUsuario;
      // va a devolver un unico valor para la misma combinacion
      const usuarioEstablecimientoResponse: UsuarioRolEstablecimiento[] =
        await usuarioRolEstablecimiento.fetchByUserLocalizacionAndNivel({
          userId: row.usuario.idUsuario,
          rolUsuarioId: rol,
          localizacionId: row.localizacion.idLocalizacion,
          nivelId: row.nivel.idNivel,
        });

      if (usuarioEstablecimientoResponse.length === 0) {
        console.log(
          'No se encontraron registros correspondientes. Procesamiento omitido.',
        );
        return;
      }

      const registrosAprobados: AutoRegistracion[] =
        await autoRegistracionDAO.getApproveRol(
          row.usuario.idUsuario,
          rol,
          row.localizacion.idLocalizacion,
        );

      const ids =
        rol === ROL.DOCENTE
          ? [TRAVERSE_ID.SECCION, TRAVERSE_ID.ESPACIO_CURRICULAR]
          : rol === ROL.COORDINADOR_DE_AREA
          ? [TRAVERSE_ID.AREA]
          : [];

      if (actionType === ACTION_TYPE.REMOVE) {
        const traverse: UsuarioRolTraverseAction[] =
          await usuarioRolTraverseActionDAO
            .aspect('limit_province')
            .getByUsuarioRolEstablecimiento({
              0: [
                {
                  path: 'usuarioRolEstablecimiento',
                  value: usuarioEstablecimientoResponse[0].idRolEstablecimiento,
                },
              ],
              1: ids.map((id) => ({ path: 'traverseAction', value: id })),
            });
        const removeUsuarioRol = !(registrosAprobados.length > 1);
        const espacioCurricularOverOne =
          registrosAprobados.filter(
            (el) =>
              el?.espacioCurricular?.idEspacioCurricular ===
              row?.espacioSeccion[0]?.espacio?.idEspacioCurricular,
          ).length > 1;
        const seccionOverOne =
          registrosAprobados.filter(
            (el) =>
              el?.seccion?.idSeccion ===
              row?.espacioSeccion[0]?.seccion?.idSeccion,
          ).length > 1;

        if (traverse?.length > 0) {
          let empytraverse = false;

          const toUpdate = traverse?.map((item) => {
            const items = [...item.value];

            if (item.traverseAction.traverseActionId === TRAVERSE_ID.SECCION) {
              row.espacioSeccion.forEach((i) => {
                const index = items.indexOf(i.seccion.idSeccion);
                if (index > -1 && !seccionOverOne) items.splice(index, 1);
              });
              empytraverse = items.length === 0;
            } else if (
              item.traverseAction.traverseActionId ===
              TRAVERSE_ID.ESPACIO_CURRICULAR
            ) {
              row.espacioSeccion.forEach((i) => {
                const index = items.indexOf(i.espacio.idEspacioCurricular);
                if (index > -1 && !espacioCurricularOverOne)
                  items.splice(index, 1);
              });
            } else {
              row.areas.forEach((i) => {
                const index = items.indexOf(i.idArea.toString());
                if (index > -1) items.splice(index, 1);
              });

              empytraverse = items.length === 0;
            }
            return { ...item, value: items };
          });

          if (empytraverse) {
            await Promise.all(
              toUpdate.map((item) =>
                usuarioRolTraverseActionDAO
                  .aspect('limit_province')
                  .remove(item),
              ),
            );
            removeUsuarioRol &&
              (await usuarioRolEstablecimiento.remove(
                usuarioEstablecimientoResponse[0],
              ));
          } else {
            await Promise.all(
              toUpdate.map((item) =>
                usuarioRolTraverseActionDAO.aspect('limit_province').save(item),
              ),
            );
          }
          if (rol === ROL.DOCENTE) {
            const filterSeccion = {
              user: [
                {
                  path: 'personalEducativo.persona.cuentas',
                  value: row.usuario.idUsuario,
                },
              ],
              espacio: row.espacioSeccion.map((espacio) => ({
                path: 'espacioCurricular',
                value: espacio.espacio.idEspacioCurricular,
              })),
              seccion: row.espacioSeccion.map((seccion) => ({
                path: 'seccion',
                value: seccion.seccion.idSeccion,
              })),
            };

            const planta: Planta[] = await plantaDAO.getByFilter(filterSeccion);
            if (planta.length > 0) {
              await Promise.all(
                planta.map((item) =>
                  plantaDAO.aspect('limit_plan').remove(item),
                ),
              );
            }
          }
        } else {
          removeUsuarioRol &&
            (await usuarioRolEstablecimiento.remove(
              usuarioEstablecimientoResponse[0],
            ));
        }
      }
    },
    [
      usuarioRolEstablecimiento,
      usuarioRolTraverseActionDAO,
      plantaDAO,
      autoRegistracionDAO,
    ],
  );

  const handleReject = useCallback(
    async ({ icon, ...row }: AutoRegistracionIcon) => {
      const isRechazado = row.rolStatus.idRolStatus === ROL_STATUS.RECHAZADO;
      const confirm = await confirmDialog.show({
        title: `Confirmación de ${
          isRechazado
            ? 'Eliminación de Rechazo'
            : row.rolStatus.idRolStatus === ROL_STATUS.APROBADO
            ? 'Eliminación'
            : 'Rechazo'
        }`,
        content: `¿Desea ${
          isRechazado
            ? 'eliminar el rechazo de'
            : row.rolStatus.idRolStatus === ROL_STATUS.APROBADO
            ? 'eliminar'
            : 'rechazar'
        } a ${row.usuario.apellidoUsuario},  ${row.usuario.nombreUsuario}?`,
        confirmText: isRechazado
          ? 'Eliminar Rechazo'
          : row.rolStatus.idRolStatus === ROL_STATUS.APROBADO
          ? 'Eliminar'
          : 'Rechazar',
        cancelText: 'Cancelar',
      });
      if (confirm) {
        try {
          loadingModal.open();
          if (isRechazado) {
            await autoRegistracionDAO.aspect('limit_localization').save({
              ...row,
              rolStatus: { idRolStatus: ROL_STATUS.ELIMINADO },
            });
          } else {
            if (+row.rolStatus.idRolStatus !== ROL_STATUS.PENDIENTE) {
              const { area, espacioCurricular, seccion, ...rest } = row;

              const rowT: AutoregisterMultiple = {
                ...rest,
                espacioSeccion: [{ espacio: espacioCurricular, seccion }],
                areas: [area],
              };

              await handleAddRemoveUsuarioRolEstablecimiento(
                rowT,
                ACTION_TYPE.REMOVE,
              );
            }
            await autoRegistracionDAO.aspect('limit_localization').save({
              ...row,
              rolStatus: {
                idRolStatus:
                  row.rolStatus.idRolStatus === ROL_STATUS.APROBADO
                    ? ROL_STATUS.ELIMINADO
                    : ROL_STATUS.RECHAZADO,
              },
            });
          }
          enqueueSnackbar(
            `El rol ha sido ${
              row.rolStatus.idRolStatus === ROL_STATUS.APROBADO
                ? 'eliminado'
                : 'rechazado'
            } con éxito.`,
            { variant: 'success' },
          );
          reFetch();
        } catch (e) {
          console.log(e);
          enqueueSnackbar(
            `Ha ocurrido un error al intentar ${
              row.rolStatus.idRolStatus === ROL_STATUS.APROBADO
                ? 'eliminar'
                : 'rechazar'
            } el rol.`,
            {
              variant: 'error',
            },
          );
        } finally {
          loadingModal.close();
        }
      }
    },
    [
      enqueueSnackbar,
      reFetch,
      handleAddRemoveUsuarioRolEstablecimiento,
      autoRegistracionDAO,
    ],
  );

  const handleApprove = useCallback(
    async ({ icon, ...row }: AutoRegistracionIcon) => {
      const confirm = await confirmDialog.show({
        title: 'Confirmación de Aprobación',
        content: `¿Desea aprobar a ${row.usuario.apellidoUsuario},  ${row.usuario.nombreUsuario}?`,
        confirmText: 'Aprobar',
        cancelText: 'Cancelar',
      });
      if (confirm) {
        try {
          loadingModal.open();
          const { area, espacioCurricular, seccion, ...rest } = row;
          await autoRegistracionDAO
            .aspect('limit_localization')
            .save({ ...row, rolStatus: { idRolStatus: ROL_STATUS.APROBADO } });
          enqueueSnackbar('Se rol se ha aprobado con éxito.', {
            variant: 'success',
          });
          reFetch();
        } catch (e) {
          console.log(e);
          enqueueSnackbar('Ha ocurrido un error al intentar aprobar el rol.', {
            variant: 'error',
          });
        } finally {
          loadingModal.close();
        }
      }
    },
    [enqueueSnackbar, reFetch, autoRegistracionDAO],
  );

  const handleModalGestionRoles = useCallback(async () => {
    try {
      const res = await customFormDialog.show({
        title: 'Gestionar roles',
        sizeWidth: 'xs',
        renderComponent: (
          <FormGestionRolesDialog
            userRoles={userRolesData}
            selectedUserRoles={checkedUserRoles}
          />
        ),
      });
      if (res) {
        const ACTION_SELECT = res as number;
        const rolesUserSelect = userRolesData.filter((item) =>
          checkedUserRoles.includes(item.idAutoregistracion),
        );

        const agrupados = rolesUserSelect.reduce<AutoregisterMultiple[]>(
          (acum, rol) => {
            const idRol = rol.rolUsuario.idRolUsuario;
            const idUser = rol.usuario.idUsuario;
            const { idLocalizacion } = rol.localizacion;
            const acumFind = acum.find(
              (item) =>
                item.rolUsuario.idRolUsuario === idRol &&
                item.localizacion.idLocalizacion === idLocalizacion &&
                item.usuario.idUsuario === idUser,
            );
            if (acumFind) {
              acumFind?.espacioSeccion.push({
                espacio: rol.espacioCurricular,
                seccion: rol.seccion,
              });
              acumFind?.areas.push(rol.area);
            } else {
              const { area, espacioCurricular, seccion, ...rest } = rol;
              acum.push({
                ...rest,
                espacioSeccion: [{ espacio: espacioCurricular, seccion }],
                areas: [area],
              });
            }
            return acum;
          },
          [],
        );

        loadingModal.open();
        if (ACTION_SELECT === ACTION_TYPE.SAVE) {
          for (const item of rolesUserSelect) {
            await autoRegistracionDAO.aspect('limit_localization').save({
              ...item,
              rolStatus: { idRolStatus: ROL_STATUS.APROBADO },
            });
          }
          enqueueSnackbar('Los roles se han aprobado con éxito.', {
            variant: 'success',
          });

          reFetch();
        } else {
          for (const item of agrupados) {
            if (+item.rolStatus.idRolStatus !== ROL_STATUS.PENDIENTE) {
              await handleAddRemoveUsuarioRolEstablecimiento(
                item,
                ACTION_TYPE.REMOVE,
              );
            }
          }

          for (const item of rolesUserSelect) {
            await autoRegistracionDAO.aspect('limit_localization').save({
              ...item,
              rolStatus: {
                idRolStatus:
                  item.rolStatus.idRolStatus === ROL_STATUS.APROBADO
                    ? ROL_STATUS.ELIMINADO
                    : ROL_STATUS.RECHAZADO,
              },
            });
          }
          enqueueSnackbar('Los roles se han rechazado con éxito.', {
            variant: 'success',
          });
          reFetch();
        }
      }
    } catch (e) {
      console.log(e);
      enqueueSnackbar('Ha ocurrido un error, intente nuevamente', {
        variant: 'error',
      });
    } finally {
      loadingModal.close();
    }
  }, [
    checkedUserRoles,
    userRolesData,
    autoRegistracionDAO,
    enqueueSnackbar,
    handleAddRemoveUsuarioRolEstablecimiento,
    reFetch,
  ]);

  const column = useMemo<InfoTableColumn<AutoRegistracion>[]>(
    () => [
      {
        id: 'icon',
        width: '1%',
        label: '',
        hideSortIcon: true,
        noSort: true,
        align: 'left',
        icon: () => (
          <Checkbox
            checked={
              checkedUserRoles.length > 0 &&
              userRolesData.filter(
                (rol) => rol.rolStatus?.idRolStatus === ROL_STATUS.PENDIENTE,
              ).length === checkedUserRoles.length
            }
            onChange={(e) => {
              userRolesData.filter(
                (rol) => rol.rolStatus?.idRolStatus === ROL_STATUS.PENDIENTE,
              ).length === checkedUserRoles.length
                ? setCheckedUserRoles([])
                : setCheckedUserRoles(
                    userRolesData
                      .filter(
                        (item) =>
                          item.rolStatus?.idRolStatus === ROL_STATUS.PENDIENTE,
                      )
                      .map((rol) => rol.idAutoregistracion),
                  );
            }}
            inputProps={{ 'aria-labelledby': 'transfer-list-item-all-label' }}
            color="primary"
          />
        ),
        render: (value: any, { idAutoregistracion, rolStatus }: any) => {
          const isSelected = checkedUserRoles.includes(idAutoregistracion);
          return rolStatus?.idRolStatus !== ROL_STATUS.PENDIENTE ? null : (
            <Checkbox
              name={idAutoregistracion?.toString()}
              checked={isSelected}
              onChange={() => {
                isSelected
                  ? setCheckedUserRoles(
                      checkedUserRoles.filter(
                        (idKey) => idAutoregistracion !== idKey,
                      ),
                    )
                  : setCheckedUserRoles([
                      ...checkedUserRoles,
                      idAutoregistracion,
                    ]);
              }}
              inputProps={{ 'aria-labelledby': 'transfer-list-item-all-label' }}
              color="primary"
            />
          );
        },
      },
      {
        id: 'apellidoNombre',
        label: 'Apellido y Nombre',
        width: '2%',
        orderById: 'usuario.apellidoUsuario',
        render: (value, row) =>
          `${row.usuario.apellidoUsuario},  ${row.usuario.nombreUsuario}`,
      },
      {
        id: 'cuenta',
        label: 'Cuenta BUE',
        width: '2%',
        orderById: 'usuario.username',
        render: (value, row) => row.usuario.username || '-',
      },
      {
        id: 'rol',
        label: 'Rol',
        width: '2%',
        orderById: 'rolUsuario?.descripcion',
        render: (value, row: AutoRegistracion) => {
          let description = row.rolUsuario?.descripcion || '-';
          if (row.orientacion) {
            description += ` - ${row.orientacion.nombre}`;
          }

          return description;
        },
      },
      {
        id: 'seccion',
        label: 'Seccion',
        width: '5%',
        orderById: 'seccion.nombreSeccion',
        render: (value, row) => row.seccion?.nombreSeccion ?? '-',
      },
      {
        id: 'area',
        label: 'Area',
        width: '5%',
        orderById: 'area.descripcion',
        render: (value, row) => row.area?.descripcion ?? '-',
      },
      {
        id: 'espacio_curricular',
        label: 'Espacio curricular',
        width: '5%',
        orderById: 'espacioCurricular.descripcion',
        render: (value, row) => row.espacioCurricular?.descripcion || '-',
      },
      {
        id: 'nivel',
        label: 'Nivel',
        width: '5%',
        orderById: 'nivel.descripcionNivel',
        render: (value, row) => row.nivel?.descripcionNivel || '-',
      },
      {
        id: 'fechaSolicitud',
        label: 'Fecha de Solicitud',
        width: '5%',
        orderById: 'fechaSolicitud',
        render: (value, row) =>
          row.fechaSolicitud
            ? moment(row?.fechaSolicitud).format('DD/MM/YYYY - HH:mm')
            : '-',
      },
      {
        id: 'estado',
        label: 'Estado',
        width: '5%',
        orderById: 'rolStatus.idRolStatus',
        render: (value, row) => row.rolStatus?.descripcion || '-',
      },
      {
        id: 'icono',
        label: 'Acciones',
        width: '2%',
        hideSortIcon: true,
        style: { textAlign: 'right' },
        render: (value: any, row: any) => (
          <>
            {row.rolStatus.idRolStatus !== ROL_STATUS.ELIMINADO && (
              <Box display="flex" width="92%">
                <Box p={1}>
                  <Tooltip title="Aprobar" aria-label="add">
                    <IconButton
                      disabled={
                        ROL_STATUS.APROBADO === row.rolStatus.idRolStatus ||
                        ROL_STATUS.RECHAZADO === row.rolStatus.idRolStatus
                      }
                      className={classes.iconButton}
                      style={
                        ROL_STATUS.APROBADO === row.rolStatus.idRolStatus ||
                        ROL_STATUS.RECHAZADO === row.rolStatus.idRolStatus
                          ? customStyles.iconDisabled
                          : customStyles.iconColor
                      }
                      onClick={() => handleApprove(row)}
                    >
                      <CheckIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
                <Box p={1}>
                  <Tooltip
                    title={
                      row.rolStatus.idRolStatus === ROL_STATUS.RECHAZADO
                        ? 'Eliminar'
                        : row.rolStatus.idRolStatus === ROL_STATUS.APROBADO
                        ? 'Eliminar'
                        : 'Rechazar'
                    }
                    aria-label="Eliminar"
                  >
                    <IconButton
                      className={classes.iconButton}
                      disabled={false}
                      onClick={() => handleReject(row)}
                      style={customStyles.iconColor}
                    >
                      {row.rolStatus.idRolStatus === ROL_STATUS.RECHAZADO ? (
                        <DeleteIcon />
                      ) : row.rolStatus.idRolStatus === ROL_STATUS.APROBADO ? (
                        <DeleteIcon />
                      ) : (
                        <CancelIcon />
                      )}
                    </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            )}
          </>
        ),
      },
    ],
    [
      classes,
      handleApprove,
      handleReject,
      userRolesData,
      checkedUserRoles,
      setCheckedUserRoles,
    ],
  );

  return {
    column,
    rows: userRolesData,
    checkedUserRoles,
    handleModalGestionRoles,
  };
};
