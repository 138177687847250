import { Box, Grid, IconButton, Tooltip, Typography } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { RegularidadHeader } from './components';
import {
  InfoTable,
  InfoTableColumn,
  Loading,
  NIVEL,
  TIPO_PERIODO,
  useDebounce,
} from 'src/commons';
import { useRolesContext } from 'src/context/RolesContext';
import {
  useAlumnoMovimientoLibbyFetch,
  usePeriodoNuevoLibbyCall,
  useRegularidadLibbyFetch,
  useSeccionLibbyFetch,
} from 'src/app/business';
import { AlumnoMovimiento } from 'src/app/models';
import { Visibility } from '@material-ui/icons';
import { AlertasType, StudentReportOpen } from '../TomaPresentismo/types';
import { StudentReport } from '../ReporteAlumno';
import { DateRangeListV3 } from '../TomaPresentismo/components/ExceptuadosModal/types';
import { useContraturnoDiaLibbyFetch } from 'src/app/business/presentismo_v3/ContraturnoDia';
import { groupBy } from 'lodash';
import { useAnioFilter } from '../TomaPresentismo/components/PresentismoHeader/hooks';

export const Regularidad = () => {
  const { selectedRole } = useRolesContext();

  const [formValues, setFormValues] = useState({
    anio: '',
    seccion: '',
  });

  const [openStudentReport, setOpenStudentReport] = useState<StudentReportOpen>(
    {
      open: false,
      row: null,
    },
  );

  const [search, setSearch] = useState('');

  const searchDebounced = useDebounce(search, 1000);

  const [anioFilter] = useAnioFilter(
    selectedRole.nivel,
    selectedRole.localizacionId,
  );

  const seccionesFilter = useMemo(
    () => ({
      anio: [{ path: 'anio', value: formValues.anio }],
      localizacion: [
        {
          path: 'localizacion.idLocalizacion',
          value: selectedRole.localizacionId,
        },
      ],
    }),
    [formValues.anio, selectedRole.localizacionId],
  );

  const { data: secciones } = useSeccionLibbyFetch({
    filter: seccionesFilter,
    orderBy: 'nombreSeccion',
    enabled: formValues.anio !== '',
  });

  const alumnoMovimFilter = useMemo(
    () => ({
      seccion: [{ path: 'seccion.idSeccion', value: formValues.seccion }],
      search: !searchDebounced
        ? []
        : [
            {
              path: 'alumno.persona.apellido',
              value: searchDebounced,
              method: 'includes',
            },
            {
              path: 'alumno.persona.nombre',
              value: searchDebounced,
              method: 'includes',
            },
          ],
    }),
    [formValues.seccion, searchDebounced],
  );

  const { data: alumnoMovimiento = [], working: alumnoMovimientoWorking } =
    useAlumnoMovimientoLibbyFetch({
      filter: alumnoMovimFilter,
      orderBy: 'alumno.persona.apellido',
      enabled: formValues.seccion !== '',
    });

  const { data: [periodo] = [], working: periodosWorking } =
    usePeriodoNuevoLibbyCall({
      methodName: 'getFirstPeriodo',
      params: [selectedRole.nivel],
    });

  const filterContraturnos = useMemo(
    () => ({
      seccion: [
        {
          path: 'contraturno.seccionCustom.idSeccion',
          value: formValues.seccion,
        },
      ],
    }),
    [formValues.seccion],
  );

  const { data: contraturnosDia = [] } = useContraturnoDiaLibbyFetch({
    limit: 300,
    filter: filterContraturnos,
    enabled: Boolean(formValues.seccion),
    aspect: 'ContraturnoDiaAsp',
  });

  const filterRegularidad = useMemo(
    () => ({
      alumno: [
        {
          path: 'alumno.idAlumno',
          value: alumnoMovimiento.map((am) => am.alumno.idAlumno),
          method: 'in',
        },
      ],
    }),
    [alumnoMovimiento],
  );

  const { data: regularidad = [], working: regularidadWorking } =
    useRegularidadLibbyFetch({
      filter: filterRegularidad,
      enabled: alumnoMovimiento.length > 0,
    });

  const regularidadPorAlumno = groupBy(regularidad, 'alumno.idAlumno');

  const columns: InfoTableColumn<AlumnoMovimiento>[] = [
    {
      id: 'nombre',
      label: 'Apellido y Nombre',
      width: '25%',
      hideSortIcon: true,
      render: (_: any, row) => (
        <Typography>
          {row.alumno.persona.apellido.toUpperCase()},{' '}
          {row.alumno.persona.nombre.toUpperCase()}
        </Typography>
      ),
    },

    {
      id: 'primerBimestre',
      label: 'Primer Bimestre',
      hideSortIcon: true,
      render: (_: any, row) => {
        const idAlumno = row.alumno.idAlumno;
        const condiciones =
          regularidadPorAlumno[idAlumno]?.[0]?.condiciones[
            TIPO_PERIODO.PRIMER_BIMESTRE
          ];
        const periodosValidos = [
          TIPO_PERIODO.PRIMER_BIMESTRE,
          TIPO_PERIODO.PRIMER_CUATRIMESTRE,
        ];
        const condicionDelAlumno = periodosValidos.includes(
          periodo.tipoPeriodo.idTipoPeriodo,
        )
          ? condiciones?.condicion?.descripcionCondicion ||
            'Datos insuficientes'
          : '-';

        return (
          <Grid container>
            <Grid item xs={5}>
              <Typography>{condicionDelAlumno}</Typography>
            </Grid>
            <Grid item xs={2}>
              {regularidadPorAlumno[idAlumno]?.[0]?.condiciones[
                TIPO_PERIODO.PRIMER_BIMESTRE
              ] === undefined ||
              regularidadPorAlumno[idAlumno]?.[0]?.condiciones[
                TIPO_PERIODO.PRIMER_BIMESTRE
              ]?.changeBy === null ? (
                <></>
              ) : (
                <Tooltip title={'Modificado por supervisor'}>
                  <Typography
                    style={{
                      color: '#3786EE',
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    S
                  </Typography>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        );
      },
    },
    {
      id: 'segundoBimestre',
      label: 'Segundo Bimestre',
      hideSortIcon: true,
      render: (_: any, row) => {
        const idAlumno = row.alumno.idAlumno;
        const condiciones =
          regularidadPorAlumno[idAlumno]?.[0]?.condiciones[
            TIPO_PERIODO.SEGUNDO_BIMESTRE
          ];
        const periodosValidos = [
          TIPO_PERIODO.SEGUNDO_BIMESTRE,
          TIPO_PERIODO.PRIMER_CUATRIMESTRE,
        ];
        const condicionDelAlumno = periodosValidos.includes(
          periodo.tipoPeriodo.idTipoPeriodo,
        )
          ? condiciones?.condicion?.descripcionCondicion ||
            'Datos insuficientes'
          : '-';

        return (
          <Grid container>
            <Grid item xs={5}>
              <Typography>{condiciones ? condicionDelAlumno : '-'}</Typography>
            </Grid>
            <Grid item xs={2}>
              {regularidadPorAlumno[idAlumno]?.[0]?.condiciones[
                TIPO_PERIODO.SEGUNDO_BIMESTRE
              ] === undefined ||
              regularidadPorAlumno[idAlumno]?.[0]?.condiciones[
                TIPO_PERIODO.SEGUNDO_BIMESTRE
              ]?.changeBy === null ? (
                <></>
              ) : (
                <Tooltip title={'Modificado por supervisor'}>
                  <Typography
                    style={{
                      color: '#3786EE',
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    S
                  </Typography>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        );
      },
    },
    {
      id: 'tercerBimestre',
      label: 'Tercer Bimestre',
      hideSortIcon: true,
      render: (_, row) => {
        const idAlumno = row.alumno.idAlumno;
        const condiciones =
          regularidadPorAlumno[idAlumno]?.[0]?.condiciones[
            TIPO_PERIODO.TERCER_BIMESTRE
          ];
        const periodosValidos = [
          TIPO_PERIODO.TERCER_BIMESTRE,
          TIPO_PERIODO.SEGUNDO_CUATRIMESTRE,
        ];
        const condicionDelAlumno = periodosValidos.includes(
          periodo.tipoPeriodo.idTipoPeriodo,
        )
          ? condiciones?.condicion?.descripcionCondicion ||
            'Datos insuficientes'
          : '-';

        return (
          <Grid container>
            <Grid item xs={5}>
              <Typography>{condicionDelAlumno}</Typography>
            </Grid>
            <Grid item xs={2}>
              {regularidadPorAlumno[idAlumno]?.[0]?.condiciones[
                TIPO_PERIODO.TERCER_BIMESTRE
              ] === undefined ||
              regularidadPorAlumno[idAlumno]?.[0]?.condiciones[
                TIPO_PERIODO.TERCER_BIMESTRE
              ]?.changeBy === null ? (
                <></>
              ) : (
                <Tooltip title={'Modificado por supervisor'}>
                  <Typography
                    style={{
                      color: '#3786EE',
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    S
                  </Typography>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        );
      },
    },
    {
      id: 'cuartoBimestre',
      label: 'Cuarto Bimestre',
      hideSortIcon: true,
      render: (_: any, row) => {
        const idAlumno = row.alumno.idAlumno;
        const condiciones =
          regularidadPorAlumno[idAlumno]?.[0]?.condiciones[
            TIPO_PERIODO.CUARTO_BIMESTRE
          ];
        const periodosValidos = [
          TIPO_PERIODO.CUARTO_BIMESTRE,
          TIPO_PERIODO.SEGUNDO_CUATRIMESTRE,
        ];
        const condicionDelAlumno = periodosValidos.includes(
          periodo.tipoPeriodo.idTipoPeriodo,
        )
          ? condiciones?.condicion?.descripcionCondicion ||
            'Datos insuficientes'
          : '-';

        return (
          <Grid container>
            <Grid item xs={5}>
              <Typography>{condicionDelAlumno}</Typography>
            </Grid>
            <Grid item xs={2}>
              {regularidadPorAlumno[idAlumno]?.[0]?.condiciones[
                TIPO_PERIODO.CUARTO_BIMESTRE
              ] === undefined ||
              regularidadPorAlumno[idAlumno]?.[0]?.condiciones[
                TIPO_PERIODO.CUARTO_BIMESTRE
              ]?.changeBy === null ? (
                <></>
              ) : (
                <Tooltip title={'Modificado por supervisor'}>
                  <Typography
                    style={{
                      color: '#3786EE',
                      textAlign: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    S
                  </Typography>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        );
      },
    },
    {
      id: 'ver',
      label: 'Ver',
      hideSortIcon: true,
      width: '5%',
      render: (_: any, row) => {
        return (
          <Box display="flex" justifyContent="center">
            <Box p={1}>
              <Tooltip title="Ver">
                <IconButton
                  style={{ color: '#3786EE' }}
                  onClick={() => {
                    setOpenStudentReport({
                      open: true,
                      row: {
                        ...row,
                        positionY: 0,
                        exceptuados: new DateRangeListV3([]),
                        alertas: {} as AlertasType,
                        contraturnos: contraturnosDia,
                        presentismo: [],
                        isChangeSeccion: false,
                      },
                    });
                  }}
                >
                  <Visibility />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        );
      },
      align: 'center',
    },
  ];

  const handleChange = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleChangeSearch = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setSearch(target.value);
  };

  const isNivelInitial = Number(selectedRole.nivel) === NIVEL.INICIAL;

  return (
    <Grid container>
      {openStudentReport.open ? (
        <StudentReport
          data={openStudentReport.row!}
          onBack={() => {
            setOpenStudentReport({ ...openStudentReport, open: false });
          }}
          listAlumnos={alumnoMovimiento?.map((alum) => alum.alumno.idAlumno)}
        />
      ) : (
        <>
          <Grid item xs={12}>
            <RegularidadHeader
              formValues={formValues}
              handleChange={handleChange}
              aniosSelect={anioFilter}
              seccionesSelect={secciones || []}
              search={search}
              handleChangeSearch={handleChangeSearch}
            />
          </Grid>
          {formValues.seccion === '' ? (
            <Grid
              item
              xs={12}
              style={{
                height: '400px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                style={{
                  fontSize: '40px',
                  marginTop: '50px',
                  color: 'gray',
                  fontWeight: 'bold',
                  lineHeight: '50px',
                }}
              >
                Realice una nueva búsqueda para ver resultados
              </Typography>
            </Grid>
          ) : (
            <Grid item xs={12} style={{ marginTop: '40px' }}>
              {alumnoMovimientoWorking ? (
                <Loading />
              ) : (
                <InfoTable
                  rows={alumnoMovimiento || []}
                  columns={columns}
                  working={alumnoMovimientoWorking}
                />
              )}
            </Grid>
          )}
        </>
      )}
    </Grid>
  );
};
