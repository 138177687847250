import React from 'react';
import { UsuarioRolEstablecimiento } from 'src/app/models';
import { CardItemContentAssignature } from './CardItemContentAssignature';
import { AnyObject } from '../../../types';
import { CardItemContainer } from './CardItemContainer';
import { OptionsModalOption } from '../../OptionsModal';
import { CardItemContent } from './CardItemContent';

export interface CardItemProps {
  item: AnyObject;
  handleClick: (item: AnyObject) => void;
  isAssignature?: boolean;
  handleMenuIcon?: OptionsModalOption<UsuarioRolEstablecimiento>[];
}

export const CardItem = ({
  item,
  handleClick,
  handleMenuIcon,
  isAssignature,
}: CardItemProps) => (
  <CardItemContainer>
    {isAssignature ? (
      <CardItemContentAssignature item={item} handleTableClick={handleClick} />
    ) : (
      <CardItemContent
        item={item}
        handleMenuIcon={handleMenuIcon}
        handleClick={handleClick}
      />
    )}
  </CardItemContainer>
);
