import { useSnackbar } from 'notistack';
import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { usePpsDAO } from 'src/app/business';
import { Pps } from 'src/app/models';
import { Footer, ROL, TabBar } from 'src/commons';
import { useRolesContext } from 'src/context/RolesContext';
import { InformePpsContextProvider } from '../InformePipContext';
import { usePPSFormContext } from '../PPSFormProvider';
import {
  ActitudFrenteAlAprendizaje,
  TrayectoriaEscolar,
  ConvivenciaEscolar,
  VinculoFamiliaEscuela,
  IntervencionesEOE,
  AntecedentesDeSalud,
  JornadaExtendida,
} from '../tabs';
import { InformePpsType } from '../types';
import { formIsValid } from '../validators';
import { get, isEqual } from 'lodash';
import { TABS_NAMES } from '../helpers';

const tabs = [
  {
    id: TABS_NAMES.ACTITUD,
    title: 'Actitud frente al aprendizaje',
    component: <ActitudFrenteAlAprendizaje />,
  },
  {
    id: TABS_NAMES.TRAYECTORIA,
    title: 'Trayectoria escolar',
    component: <TrayectoriaEscolar />,
  },
  {
    id: TABS_NAMES.CONVIVENCIA,
    title: 'Convivencia escolar',
    component: <ConvivenciaEscolar />,
  },
  {
    id: TABS_NAMES.VINCULO,
    title: 'Vínculo familia - escuela',
    component: <VinculoFamiliaEscuela />,
  },
  {
    id: TABS_NAMES.INTERVENCIONES,
    title: 'Intervenciones EOE y de equipos interdisciplinarios',
    component: <IntervencionesEOE />,
  },
  {
    id: TABS_NAMES.ANTECEDENTES,
    title: 'Antecedentes de salud',
    component: <AntecedentesDeSalud />,
  },
  {
    id: TABS_NAMES.JORNADA,
    title: 'Jornada extendida',
    component: <JornadaExtendida />,
  },
];

export const TabsCargaPps = ({
  setInformePps,
}: {
  setInformePps: React.Dispatch<React.SetStateAction<InformePpsType>>;
}) => {
  const {
    selectedRole: { rol },
  } = useRolesContext();
  const { reFetch, pps, form, isSaving, data, informePps, currentTab } =
    usePPSFormContext();

  const [isLoading, setLoading] = useState(false);

  const isDirector = useMemo(() => rol === '1' || rol === '5', [rol]);

  const ppsDao = usePpsDAO();

  const { enqueueSnackbar } = useSnackbar();

  const formState = form.getState();

  const buttonConfig: any = useMemo(() => {
    const handleLeave = () => {
      setInformePps({});
    };

    const ppsSwitch = async (state: any) => {
      setLoading(true);
      const response = await ppsDao.save({
        idPPS: (pps[0] as Pps)?.idPPS,
        abierto: state,
      });
      if (response.existError) {
        enqueueSnackbar('Ha ocurrido un error', { variant: 'error' });
      } else {
        enqueueSnackbar('Se ha guardado con éxito', { variant: 'success' });
      }
      setLoading(false);
      reFetch();
    };

    const initialValues = get(formState.initialValues, currentTab);
    const values = get(formState.values, currentTab);

    const isEquals = isEqual(initialValues, values);

    const defaultButtons: any[] = [
      {
        title: 'Salir',
        size: 'medium',
        type: 'secondary',
        handleOnClick: handleLeave,
      },
      {
        title: 'Guardar',
        size: 'medium',
        // handleOnClick: onSubmit,
        typeButton: 'submit',
        disabled: formState.invalid || isSaving || isEquals,
      },
    ];

    const openPpsValidation = () =>
      ![ROL.DIRECTIVO, ROL.EQUIPO_CONDUCCION].includes(Number(rol));

    if (pps[0]?.abierto ?? true) {
      defaultButtons.push({
        title: 'Cerrar PPS',
        size: 'medium',
        handleOnClick: () => ppsSwitch(false),
        disabled:
          !Boolean((pps[0] as Pps)?.idPPS) ||
          !formIsValid(formState.values) ||
          formState.dirty,
      });
    } else if (isDirector) {
      defaultButtons.push({
        title: 'Abrir PPS',
        size: 'medium',
        handleOnClick: () => ppsSwitch(true),
        disabled: openPpsValidation() || formState.dirty,
      });
    }
    return defaultButtons;
  }, [
    formState.invalid,
    formState.dirty,
    formState.values,
    isSaving,
    pps,
    isDirector,
    setInformePps,
    ppsDao,
    reFetch,
    enqueueSnackbar,
    rol,
    formState.initialValues,
    currentTab,
  ]);

  const handleGotoTAB = useCallback(() => {
    if (currentTab) {
      const index = tabs.findIndex((tab) => tab.id === currentTab);
      if (index === -1) return 0;
      return index;
    } else {
      return 0;
    }
  }, [currentTab]);

  return (
    <InformePpsContextProvider
      informePps={informePps}
      setInformePps={setInformePps}
      isDirector={isDirector}
      data={data}
      isSaving={isSaving}
    >
      <TabBar defaultTab={handleGotoTAB()} content={tabs} />
      <Footer buttonConfig={buttonConfig} />
    </InformePpsContextProvider>
  );
};
