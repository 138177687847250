import { Sede } from 'src/app/models/presentismo_v2';
import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class SedeDAO extends LibbyFetchDAO {
  constructor() {
    super('sede', 'idSede');
  }

  saveSede(sede: Sede) {
    this.save(sede);
  }
}
