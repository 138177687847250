import { LibbyFetchDAO } from './LibbyFetchDAO';

export class AnioDAO extends LibbyFetchDAO {
  constructor() {
    super('anio', 'idAnio');
  }

  getByNivel(nivel: number) {
    return this.query().equals('nivel', nivel).limit(0, 10000).run();
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
