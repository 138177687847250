import React from 'react';
import { AccionOrientacion } from 'src/app/models/acap/AccionesOrientaciones';

export const useAccionesExcelData = (rows: any, name: any) => {
  const columnHeaders = [
    'Acción',
    'Tipo de acción',
    'Orientaciones',
    'Descripción',
  ];

  const rowsForExcel = rows.map((row: any) => {
    let orientaciones = '';
    row?.accionOrientaciones.forEach(
      (orientacion: AccionOrientacion, index: any) => {
        if (index === row?.accionOrientaciones.length - 1) {
          orientaciones += orientacion.orientacion.nombre;
        } else {
          orientaciones += `${orientacion.orientacion.nombre}, `;
        }
      },
    );

    return {
      Acción: row.nombre,
      'Tipo de acción': row.tipoAccion?.nombre,
      Orientaciones: orientaciones,
      Descripción: row?.descripcion,
    };
  });

  return {
    body: {
      columns: columnHeaders,
      data: rowsForExcel,
    },
    name,
  };
};
