import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { InterdiscipOrientaPlan } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'interdiscip_orienta_plan';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idInterdiscipOrientaPlan',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idInterdiscipOrientaPlan',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.InterdiscipOrientaPlan
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.InterdiscipOrientaPlan,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.InterdiscipOrientaPlan.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<InterdiscipOrientaPlan>(DAO_NAME, optionsToUse);

// hooks
export const useInterdiscipOrientaPlanFetchById = fetchById;
export const useInterdiscipOrientaPlanLibbyCall = libbyCall;
export const useInterdiscipOrientaPlanDAO = libbyDAO;
export const useInterdiscipOrientaPlanLibbyFetch = libbyFetch;

// components/hoc
export const InterdiscipOrientaPlanAutocomplete = entityAutocomplete;
export const InterdiscipOrientaPlanCall = renderPropCall;
export const InterdiscipOrientaPlanById = renderPropFetchById;
export const InterdiscipOrientaPlanSelect = simpleSelect;
export const InterdiscipOrientaPlanAccordionSelector = accordionSelector;
export const InterdiscipOrientaPlanSelectedChips = selectedChips;

// context
// ByIdContext
export const useInterdiscipOrientaPlanByIdContext = useByIdContext;
export const InterdiscipOrientaPlanByIdProvider = ByIdProvider;
export const InterdiscipOrientaPlanByIdContext = ByIdContext;
// GeneralContext
export const useInterdiscipOrientaPlanContext = useGeneralContext;
export const InterdiscipOrientaPlanProvider = GeneralProvider;
export const InterdiscipOrientaPlanContext = GeneralContext;
