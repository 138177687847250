import { routes } from './routes';
import { MenuItem } from '../../../../commons/const/ui/menu';

export const gestionRolesMenu: MenuItem = {
  key: 'gestionRoles',
  basePath: '/gestionRoles',
  enabled: true,
  icon: 'List',
  text: 'GestionRoles',
  children: routes,
};
