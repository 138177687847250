import {
  Box,
  Button,
  Grid,
  List,
  Typography,
  makeStyles,
} from '@material-ui/core';
import React, { useCallback, useState, useMemo } from 'react';
import { SwitchItem } from './SwitchItem';
import { NestedSwitchList } from './NestedSwitchList';
import {
  IDependennciaCallback,
  ISwitchItemCallback,
  ISwitchList,
  InitialValue,
} from '../interface/SwitchItem';
import { DatePickerV2 } from '../../../../../app/components';
import moment from 'moment';
import { ConfirmacionMatricula } from '../../../../../app/models';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';

const useStyles = makeStyles({
  selectLabel: {
    fontSize: 16,
    color: '#38485C',
    fontWeight: 'bold',
    fontFamily: 'Nunito',
  },
  selectInput: {
    backgroundColor: '#F3F6F9',
    border: 'none',
    '& > fieldset': {
      border: 'none',
    },
  },
  selectBg: {
    backgroundColor: '#F3F6F9',
  },
  datePickerLabel: {
    fontSize: 16,
    color: '#8A98A8',
    fontWeight: 'bold',
    fontFamily: 'Nunito',
    top: -13,
  },
  datePickerInput: {
    backgroundColor: '#F3F6F9',
    border: 'none',
    height: 50,
    display: 'flex',
    alignItems: 'flex-end',
    '& > fieldset': {
      border: 'none',
    },
    '& > input': {
      padding: '2px 6px',
    },
  },
  datePickerRoot: {
    paddingLeft: '0px',
    paddingRight: '0px',
  },
});

export const SwitchList = ({
  periodo,
  items = [],
  initialValue,
  onSubmit,
}: ISwitchList) => {
  const classes = useStyles();
  const [state, setState] = useState<InitialValue>(initialValue);
  const [fechaDesde, setFechaDesde] = useState<string>(periodo.fechaDesde);
  const [fechaHasta, setFechaHasta] = useState<string>(periodo.fechaHasta);

  const initialNiveles = useMemo(
    () =>
      items.reduce<InitialValue>((acum, item) => {
        if (!acum[item.idNivel]) {
          acum[item.idNivel] =
            item.checked ||
            item.dependencias.every((d) => d.configuracion.activo);
        }
        return acum;
      }, {}),
    [items],
  );
  const [niveles, setNiveles] = useState<InitialValue>(initialNiveles);

  const handleSwicth = useCallback<IDependennciaCallback>(
    (item) => (_, checked) => {
      const newState = { ...state };
      newState[item.configuracion.idConfirmacionMatricula?.toString()] =
        checked;
      setState(newState);
    },
    [setState, state],
  );

  const handleNiveles = useCallback<ISwitchItemCallback>(
    (item) => (_, checked) => {
      const newState = { ...niveles };
      newState[item.idNivel] = checked;
      const _newState = { ...state };
      for (const _item of item.dependencias) {
        _newState[_item.configuracion.idConfirmacionMatricula?.toString()] =
          checked;
      }
      setNiveles(newState);
      setState(_newState);
    },
    [niveles, state, setNiveles, setState],
  );

  const _items = useMemo(
    () =>
      items.map((i) => {
        return {
          ...i,
          checked: niveles[i.idNivel],
          onSwicth: handleNiveles,
          dependencias: i.dependencias.map((d) => {
            return {
              ...d,
              checked: state[d.configuracion.idConfirmacionMatricula],
              onSwicth: niveles[i.idNivel] ? undefined : handleSwicth,
            };
          }),
        };
      }),
    [items, state, niveles, handleSwicth, handleNiveles],
  );

  const handleSubmit = useCallback(() => {
    const _values = items.reduce<ConfirmacionMatricula[]>(
      (acum, { dependencias }) => {
        for (const item of dependencias) {
          acum.push({
            ...item.configuracion,
            activo:
              state[item.configuracion.idConfirmacionMatricula?.toString()],
          });
        }
        return acum;
      },
      [],
    );
    const _fechaDesde = moment(fechaDesde)
      .set({ hour: 0, minute: 0, second: 0 })
      .toISOString();
    const _fechaHasta = moment(fechaHasta)
      .set({ hour: 23, minute: 59, second: 59 })
      .toISOString();
    _values.push({
      ...periodo,
      fechaDesde: _fechaDesde,
      fechaHasta: _fechaHasta,
    });
    onSubmit(_values);
  }, [onSubmit, periodo, fechaDesde, fechaHasta, state, items]);

  const validateDates = useMemo(() => {
    if (fechaDesde && fechaHasta) {
      if (moment(fechaHasta).isBefore(fechaDesde))
        return 'La fecha de fin debe ser mayor a la fecha de inicio';
    }
    return '';
  }, [fechaHasta, fechaDesde]);

  const disabled = useMemo(() => {
    if (!fechaDesde || fechaDesde === '') return true;
    if (!fechaHasta || fechaHasta === '') return true;
    if (validateDates) return true;
    if (
      JSON.stringify(state) === JSON.stringify(initialValue) &&
      JSON.stringify(niveles) === JSON.stringify(initialNiveles) &&
      moment(fechaHasta).isSame(periodo.fechaHasta) &&
      moment(fechaDesde).isSame(periodo.fechaDesde)
    )
      return true;
    return false;
  }, [
    fechaDesde,
    fechaHasta,
    initialValue,
    state,
    niveles,
    initialNiveles,
    validateDates,
    periodo,
  ]);

  const disableDays = useCallback<(day: MaterialUiPickersDate) => boolean>(
    (day) => {
      if (!day) return false;
      return moment(moment(day).format('YYYY-MM-DD')).isBefore(
        moment(fechaHasta).format('YYYY-MM-DD'),
      );
    },
    [fechaHasta],
  );

  const handleFechaDesde = useCallback(
    (_date: MaterialUiPickersDate) => {
      const newDate = _date;

      setFechaDesde(newDate?.format() || fechaDesde);
      setFechaHasta(newDate?.add(15, 'days')?.format() || fechaHasta);
    },
    [fechaDesde, fechaHasta, setFechaDesde, setFechaHasta],
  );

  return (
    <Grid container component={Box} paddingY={4} spacing={3}>
      {/* <Grid item xs={12} container spacing={1}>
        <Grid item>
          <DatePickerV2
            value={fechaDesde}
            clearable={false}
            format="DD/MM/yyyy"
            placeholder="dd/mm/aaaa"
            onChange={handleFechaDesde}
            label="Fecha desde"
            InputLabelProps={{
              className: classes.datePickerLabel,
              shrink: false,
            }}
            InputProps={{
              className: classes.datePickerInput,
              disableUnderline: true,
            }}
            SelectProps={{ classes: { root: classes.selectBg } }}
          />
        </Grid>
        <Grid item>
          <DatePickerV2
            value={fechaHasta}
            clearable={false}
            format="DD/MM/yyyy"
            placeholder="dd/mm/aaaa"
            onChange={(_date) => setFechaHasta(_date?.format() || fechaHasta)}
            label="Fecha hasta"
            shouldDisableDate={disableDays}
            InputLabelProps={{
              className: classes.datePickerLabel,
              shrink: false,
            }}
            InputProps={{
              className: classes.datePickerInput,
              disableUnderline: true,
            }}
            SelectProps={{ classes: { root: classes.selectBg } }}
          />
        </Grid>
      </Grid> */}
      <Grid item xs={12}>
        {validateDates && (
          <Typography color="error">{validateDates}</Typography>
        )}
      </Grid>
      <Grid item xs={6}>
        <List
          component="nav"
          disablePadding
          style={{ width: '100%', maxWidth: 360 }}
        >
          {_items.map((item) => {
            return (
              <Grid key={item.name}>
                <SwitchItem {...item} />
                <NestedSwitchList items={item.dependencias || []} />
              </Grid>
            );
          })}
        </List>
      </Grid>
      <Grid item container justifyContent="flex-end">
        <Grid item>
          <Button
            disabled={disabled}
            style={{ textTransform: 'none' }}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Guardar
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
