import React, { useMemo } from 'react';
import { useComentarioFetchById } from 'src/app/business';

type ChildrenValue<T> = {
  data: T | null;
  working: boolean;
  raw: any | undefined;
};

export interface ComentarioEditValueProps<T> {
  idComentario: number;
  children: (value: ChildrenValue<T>) => JSX.Element;
  transformer: (value: any) => T;
}

// TODO: convertir en un componente de business makeEntityByIdTransformer
export function ComentarioEditValue<T>({
  idComentario,
  children,
  transformer,
}: ComentarioEditValueProps<T>) {
  const { data, working } = useComentarioFetchById(idComentario);

  const transformed = useMemo<T | null>(() => {
    if (!data || working) {
      return null;
    }
    return transformer(data);
  }, [data, transformer, working]);

  return <>{children({ data: transformed, working, raw: data })}</>;
}
