import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { EstadoMatricula } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'estado_matricula';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEstadoMatricula',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEstadoMatricula',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EstadoMatricula
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EstadoMatricula,
      hoc: { ...defaultOptions.hoc, ...businessOptions.EstadoMatricula.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EstadoMatricula>(DAO_NAME, optionsToUse);

// hooks
export const useEstadoMatriculaFetchById = fetchById;
export const useEstadoMatriculaLibbyCall = libbyCall;
export const useEstadoMatriculaDAO = libbyDAO;
export const useEstadoMatriculaLibbyFetch = libbyFetch;

// components/hoc
export const EstadoMatriculaAutocomplete = entityAutocomplete;
export const EstadoMatriculaCall = renderPropCall;
export const EstadoMatriculaById = renderPropFetchById;
export const EstadoMatriculaSelect = simpleSelect;
export const EstadoMatriculaAccordionSelector = accordionSelector;
export const EstadoMatriculaSelectedChips = selectedChips;

// context
// ByIdContext
export const useEstadoMatriculaByIdContext = useByIdContext;
export const EstadoMatriculaByIdProvider = ByIdProvider;
export const EstadoMatriculaByIdContext = ByIdContext;
// GeneralContext
export const useEstadoMatriculaContext = useGeneralContext;
export const EstadoMatriculaProvider = GeneralProvider;
export const EstadoMatriculaContext = GeneralContext;
