import { useState } from 'react';
import { isEqual } from 'lodash';

export const useReadyToClose = (informesRows, data) => {
  // const [readyToClose, setReadyToClose] = useState<boolean>(false);

  const submitRows = informesRows.filter((row: any) => {
    let isUpdated = false;
    const currentCalification = data?.find(
      (d: any) => d.idCalificacion === row.idCalificacion,
    );
    if (currentCalification?.nota) {
      isUpdated = !isEqual(currentCalification?.nota?.data, row?.nota?.data);
      // } else if (Object.keys(row?.nota?.data).length) {
    } else if (row?.nota?.data) {
      isUpdated = true;
    }
    return isUpdated;
  });
  // setReadyToClose(!submitRows.length);
  const readyToClose = !submitRows.length;
  return readyToClose;
};
