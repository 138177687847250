import { MenuItem } from '../../../../commons/const/ui/menu';
import { routes } from './routes';

export const plataformaEvaluaciones: MenuItem = {
  key: 'evaluaciones',
  basePath: '/evaluaciones',
  enabled: true,
  icon: 'List',
  text: 'Plataforma de evaluaciones presenciales',
  children: routes,
};
