import { Area, EspacioCurricular } from 'src/app/models';
import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ContenidoEspecificoDAO extends LibbyFetchDAO {
  constructor() {
    super('contenido_especifico', 'idContenidoEspecifico');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  filtroEspacioCurricular(params: any = []) {
    const [param] = params;
    let query = this.query();
    query.groupStart();
    if (typeof param === 'number') {
      query.equals('materia.idMateria', param);
    } else {
      // eslint-disable-next-line
      if (!params?.length) {
        query = query.equals('materia.idMateria', -1);
      } else {
        params?.forEach((espacio: EspacioCurricular, index: number) => {
          if (index !== 0) {
            query = query.or();
          }
          query = query.equals(
            'materia.idMateria',
            espacio?.materia.idMateria || '',
          );
        });
      }
    }
    query.groupEnd();
    query.orderBy('idContenidoEspecifico', 'asc');
    query.orderBy(
      'contenidoEspecificoSubnivels.idContenidoEspecificoSubnivel',
      'asc',
    );
    query.orderBy(
      'contenidoEspecificoSubnivels.contenidoEspecificoSubnivelDos.idContenidoEspecificoSubnivelDos',
      'asc',
    );
    return query.limit(0, 10000).run();
  }

  filtroAreas(params: any = []) {
    let query = this.query();
    query.groupStart();
    if (!params?.length) {
      query = query.notEquals('materia.area', -1);
    } else {
      params.forEach((area: Area, index: number) => {
        if (index !== 0) {
          query = query.or();
        }
        query = query.equals('materia.area', area.idArea);
      });
    }
    query.groupEnd();
    query.orderBy('idContenidoEspecifico', 'asc');
    query.orderBy(
      'contenidoEspecificoSubnivels.idContenidoEspecificoSubnivel',
      'asc',
    );
    query.orderBy(
      'contenidoEspecificoSubnivels.contenidoEspecificoSubnivelDos.idContenidoEspecificoSubnivelDos',
      'asc',
    );
    return query.limit(0, 10000).run();
  }
}
