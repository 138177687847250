export const useGetLocalizacionSeccionProperties = (secciones: any) => {
  let anios: any = [];
  let jornadas: any = [];
  let turnos: any = [];

  secciones.forEach((seccion: any) => {
    const anio = seccion?.anio;
    const jornada = seccion?.jornada;
    const turno = seccion?.turno;

    const anioFind = anios.find(
      (element: any) => element?.idAnio === anio?.idAnio,
    );
    const jornadaFind = jornadas.find(
      (element: any) => element?.label === jornada,
    );
    const turnoFind = turnos.find(
      (element: any) => element?.idTurno === turno?.idTurno,
    );

    // no queremos traer ni los que tengan valores null, ni los que sean de otro nivel que no sea inicial por el momento
    if (
      !anioFind &&
      anio !== null &&
      anio !== 'null' &&
      seccion?.nivel?.idNivel === 1
    ) {
      anios.push(anio);
    }
    if (
      !jornadaFind &&
      jornada !== null &&
      jornada !== 'null' &&
      seccion?.nivel?.idNivel === 1
    ) {
      jornadas.push({ label: jornada });
    }
    if (
      !turnoFind &&
      turno !== null &&
      turno !== 'null' &&
      seccion?.nivel?.idNivel === 1
    ) {
      turnos.push(turno);
    }
  });

  const aniosSorted = anios.sort(function (a: any, b: any) {
    return a.numeroAnio - b.numeroAnio;
  });

  function compareJornada(a: any, b: any) {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  }

  function compareTurno(a: any, b: any) {
    if (a.descripcionTurno < b.descripcionTurno) {
      return -1;
    }
    if (a.descripcionTurno > b.descripcionTurno) {
      return 1;
    }
    return 0;
  }

  const jornadasSorted = jornadas.sort(compareJornada);
  const turnosSorted = turnos.sort(compareTurno);

  return { aniosSorted, jornadasSorted, turnosSorted };
};
