import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class JustificacionDAO extends LibbyFetchDAO {
  constructor() {
    super('justificacion', 'idJustificacion');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
