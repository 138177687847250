import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class HabilidadesCategoriasDAO extends LibbyFetchDAO {
  constructor() {
    super('habilidades_categorias', 'idHabilidadCategoria');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
