import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useFormContext } from '../../../../../../../../lib/templates/context';
import { FormGenericControl } from '../../../../../../../../lib/templates/components';
import {
  LocalizacionById,
  LocalizacionPlanEstudioNivelSelect,
  LocalizacionSelectedChips,
  RolSelect,
  useLocalizacionDAO,
  useSecundariaFuturoDAO,
} from '../../../../../../../../app/business';
import { LocalizacionPlanEstudioNivel } from '../../../../../../../../app/models';
import { AnyObject } from '../../../../../../../../commons/types';
import { useSetValuesFormOnChange } from '../../../../../../../../lib/templates/hooks';
import { ROLES_BY_NIVEL } from '../../../../../../../../commons/const/rolesByNivel';
import { NIVEL } from '../../../../../../../../commons/const/nivel';
import { LocalizacionPickerDialog } from '../../../../../../../../app/dialogs/LocalizacionPickerDialog';
import { FormDialogPicker } from '../../../../../../../../lib/templates/components/FormDialogPicker';
import { Loading } from '../../../../../../../../commons/components/Loading';

const SPACING = 4;

const transformNivelOptions = (options: LocalizacionPlanEstudioNivel[]) =>
  options.reduce<LocalizacionPlanEstudioNivel[]>((acum, item) => {
    if (
      !acum.find(
        (_item) =>
          _item.planEstudioNivel.modalidadNivel.nivel.idNivel ===
          item.planEstudioNivel.modalidadNivel.nivel.idNivel,
      )
    ) {
      return [...acum, item];
    }
    return acum;
  }, []);

export const FormContent = () => {
  const { form, values } = useFormContext<AnyObject>();

  // Adjusting reaction on values changes
  useSetValuesFormOnChange(values.idLocalizacion, [
    { key: 'idNivel', value: '' },
    { key: 'idRolUsuario', value: '' },
  ]);
  useSetValuesFormOnChange(values.idNivel, [
    { key: 'idRolUsuario', value: '' },
  ]);

  // Filtering
  const selectedLocalizacionFilter = useMemo<AnyObject | null>(() => {
    if (!values.idLocalizacion) return null;
    return {
      localizacion: [{ path: 'localizacion', value: values.idLocalizacion }],
    };
  }, [values.idLocalizacion]);

  const secundariaFuturoDAO = useSecundariaFuturoDAO();
  const [loadingSecundariaFuturo, setLoadingSecundariaFuturo] = useState(false);
  const localizacionDAO = useLocalizacionDAO();

  useEffect(() => {
    (async () => {
      if (values.idLocalizacion) {
        setLoadingSecundariaFuturo(true);
        const [localizacion] = await localizacionDAO.fetchById(
          values.idLocalizacion,
        );
        if (localizacion) {
          const items = await secundariaFuturoDAO.getByEstablecimiento(
            localizacion.establecimiento.idEstablecimiento,
          );
          form.change('isFuture', items.length > 0);
        }
        setLoadingSecundariaFuturo(false);
      }
    })();
  }, [form, values.idLocalizacion, localizacionDAO, secundariaFuturoDAO]);

  const rolFilter = useMemo<AnyObject | null>(() => {
    if (!values.idLocalizacion || !values.idNivel || loadingSecundariaFuturo)
      return null;
    const rolToUse =
      values.isFuture &&
      values.idNivel.toString() === NIVEL.SECUNDARIO.toString()
        ? ROLES_BY_NIVEL[NIVEL.SECUNDARIA_FUTURO]
        : ROLES_BY_NIVEL[values.idNivel as NIVEL];
    // TODO: setear la logica de roles disponibles por nivel y establecimiento
    return {
      allowedRoles: rolToUse.map((id) => ({ path: 'idRolUsuario', value: id })),
    };
  }, [
    loadingSecundariaFuturo,
    values.idLocalizacion,
    values.idNivel,
    values.isFuture,
  ]);

  const getSelectedLocalizacionRender = useCallback(
    (value: string | string[] | undefined | null, onChange) => {
      if (!value) return <></>;
      if (Array.isArray(value)) return <>Not suported for multiple values</>;
      return (
        <LocalizacionById id={value} key={value}>
          {(result) =>
            result.working ? (
              <Loading />
            ) : !result.data ? (
              <Typography>No encontrado</Typography>
            ) : (
              <LocalizacionSelectedChips
                items={[result.data]}
                onChange={() => onChange('')}
              />
            )
          }
        </LocalizacionById>
      );
    },
    [],
  );
  return (
    <Grid container spacing={SPACING} direction="column">
      <Grid item>
        <FormDialogPicker
          name="idLocalizacion"
          form={form}
          label="Establecimiento"
          dialogInstance={LocalizacionPickerDialog}
          placeholder={
            values.idLocalizacion ? '' : 'Seleccioná el establecimiento'
          }
          getSelectedRender={getSelectedLocalizacionRender}
        />
      </Grid>
      <Grid item>
        {selectedLocalizacionFilter && (
          <FormGenericControl
            name="idNivel"
            form={form}
            component={LocalizacionPlanEstudioNivelSelect}
            componentProps={{
              transformOptions: transformNivelOptions,
              title: 'Nivel',
              placeholder: 'Seleccioná el nivel',
              filter: selectedLocalizacionFilter,
              labelKey:
                'planEstudioNivel.modalidadNivel.nivel.descripcionNivel',
              valueKey: 'planEstudioNivel.modalidadNivel.nivel.idNivel',
            }}
          />
        )}
      </Grid>
      <Grid item>
        {!!values.idNivel && loadingSecundariaFuturo && <Loading />}
        {rolFilter && (
          <FormGenericControl
            name="idRolUsuario"
            form={form}
            component={RolSelect}
            componentProps={{
              title: 'Rol',
              placeholder: 'Seleccioná el rol',
              filter: rolFilter,
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};
