import { AnyObject, TURNOS } from '../../../../commons';
import moment from 'moment/moment';

export const filterAusencias = ({
  idAlumno,
  turnos = [],
  fechaDesde,
  fechaHasta,
  idEstadoPresente,
  isJornadaExtendida,
  contraturnos = [],
}: {
  idAlumno?: string;
  turnos?: Array<string>;
  fechaDesde?: string;
  fechaHasta?: string;
  idEstadoPresente?: string;
  isJornadaExtendida?: boolean;
  contraturnos?: Array<string>;
}) => {
  const filter: AnyObject = {};
  if (idAlumno) {
    filter.alumnos = [{ path: 'alumno.idAlumno', value: idAlumno }];
  }
  filter.turno = [];
  if (turnos?.length > 0) {
    const turnoFiltrado = turnos
      .map((turno) => {
        if (turno === TURNOS.DOBLE.toString()) {
          return [TURNOS.MANIANA, TURNOS.TARDE];
        } else {
          return [turno];
        }
      })
      .flat();
    filter.turno = [
      { path: 'turno.idTurno', value: turnoFiltrado, method: 'in' },
    ];
  }
  if (contraturnos.length > 0) {
    filter.turno.push({
      path: 'contraturnoDia',
      value: contraturnos,
      method: 'in',
    });
  }
  if (fechaDesde) {
    filter.fechaDesde = [
      {
        path: 'fecha',
        value: moment(fechaDesde).format('YYYY-MM-DD'), //.format('YYYY-M-D HH:mm:ss'),
        method: 'higherOrEquals',
      },
    ];
  }
  if (fechaHasta) {
    filter.fechaHasta = [
      {
        path: 'fecha',
        value: moment(fechaHasta).format('YYYY-MM-DD'), //.format('YYYY-M-D HH:mm:ss'),
        method: 'lowerOrEquals',
      },
    ];
  }
  if (idEstadoPresente) {
    filter.presente = [
      { path: 'estadoPresente.idEstadoPresente', value: idEstadoPresente },
    ];
  }

  filter.isJornadaExtendida = [
    { path: 'isJornadaExtendida', value: isJornadaExtendida },
  ];

  return filter;
};
