import React, { useMemo, useCallback, useState } from 'react';
import customFormDialog from 'src/commons/services/customFormDialog';
import { Grid } from '@material-ui/core';
import { AnyObject } from 'src/commons/types';
import { Loading } from 'src/commons/components/Loading';
import {
  useUsuarioRolTraverseActionDAO,
  useUsuarioRolEstablecimientoDAO,
} from 'src/app/business';
import { RESTConnection } from '@phinxlab/libby-rest-web';
import { useReloginDAO } from 'src/app/business/businessCustomEndpoints';
import { UsuarioRolTraverseAction } from 'src/app/models';
import { FormContextProvider } from 'src/lib/templates';
import { TRAVERSE_ACTIONS, ROL } from 'src/commons/const';
import { DireccionAreaItemForm } from './components/DireccionAreaItemForm';
import { direccionAreaTemplate } from './template';
import { NivelesModal } from '../NivelesModal';

export interface DireccionAreaFormProps {
  roleInformation: AnyObject;
  libby: any;
}
export const DireccionAreaForm = ({
  roleInformation,
  libby,
}: DireccionAreaFormProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const usuarioRolTraverseActionDAO = useUsuarioRolTraverseActionDAO();
  const usuarioRolEstablecimientoDAO = useUsuarioRolEstablecimientoDAO();
  const initialValues = useMemo(() => ({ idLocalizacion: null }), []);
  const reloginDAO = useReloginDAO();
  const isSupervisor = (id: number) => [ROL.SUPERVISOR].includes(id);
  const onSubmit = useCallback(
    async (values) => {
      setSubmitting(true);
      if (
        isSupervisor(roleInformation.rolUsuario.idRolUsuario) ||
        roleInformation.dependenciaFuncional.idDependenciaFuncional !== 41
      ) {
        await usuarioRolEstablecimientoDAO.save({
          ...roleInformation,
          localizacion: values.idLocalizacion,
        });
        const res = await reloginDAO.selectRole(
          roleInformation.idRolEstablecimiento.toString(),
          roleInformation.nivel.idNivel,
          values.idLocalizacion,
        );
        const user = { ...res.user, isGuest: res.isGuest };
        RESTConnection.defineHeader('x-chino-token', res.token);
        libby.session.notify('New session', user, res.token);
        await customFormDialog.handleCancel();
      } else {
        customFormDialog.show({
          title: '',
          renderComponent: (
            <NivelesModal
              localizacion={values.idLocalizacion}
              ure={roleInformation.idRolEstablecimiento}
            />
          ),
          sizeWidth: 'sm',
        });
      }
    },
    [roleInformation, usuarioRolEstablecimientoDAO, libby, reloginDAO],
  );

  return (
    <Grid
      container
      direction="column"
      alignItems="stretch"
      style={{ position: 'relative', height: '100%', paddingBottom: 100 }}
    >
      <FormContextProvider
        template={direccionAreaTemplate}
        initialValues={initialValues}
        onSubmit={onSubmit}
      >
        {submitting ? (
          <Loading />
        ) : (
          <DireccionAreaItemForm roleInformation={roleInformation} />
        )}
      </FormContextProvider>
    </Grid>
  );
};
