import { Grid } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  AnioSelect,
  PeriodoSelect,
  useLocalizacionLibbyFetch,
} from 'src/app/business';
import { NIVEL } from 'src/commons/const';
import { useRolesContext } from 'src/context/RolesContext';
import { AnyObject, Loading } from 'src/commons';
import { useColegioEfectivizadoContext } from '../../Calificaciones/context/EfectivizacionProvider';
import { useGetValidYearsPresentismo } from '../../Presentismo/TomaPresentismo/hooks/useGetValidYearsPresentismo';

type QueryFilter = { path: string; value: string | number; method?: string };

type AnioFilter = {
  nivel: QueryFilter[];
  anio?: QueryFilter[];
  validAnios?: QueryFilter[];
};

export const FilterTablesBoletines = ({ filter, setFilter, levelId }: any) => {
  const { establecimientoEfectivizado, working } =
    useColegioEfectivizadoContext();
  const onFilterChange = useCallback(
    async ({ target }) =>
      setFilter({ ...filter, [target.name]: target.value ?? '' }),

    [filter, setFilter],
  );

  const {
    selectedRole: { nivel },
  } = useRolesContext();

  const [anioFilter, setAnioFilter] = useState<AnioFilter>({
    nivel: [{ path: 'nivel.idNivel', value: nivel }],
  });

  const tecnicaFilter = useMemo(
    () => ({
      tecnica: [
        {
          path: 'establecimiento.dependenciaFuncional',
          value: 32,
        },
      ],
    }),
    [],
  );

  const { data: dFuncional = [] } = useLocalizacionLibbyFetch({
    filter: tecnicaFilter,
    limit: 100,
  });

  const esTecnicaSecundaria = useMemo(
    () => dFuncional.length > 0 && Number(levelId) === NIVEL.SECUNDARIO,
    [dFuncional, levelId],
  );

  const { anio: validAnio } = useGetValidYearsPresentismo();

  useEffect(() => {
    if (!esTecnicaSecundaria && !anioFilter.anio && validAnio.length > 0) {
      setAnioFilter({
        ...anioFilter,
        anio: [...validAnio],
      });
    }
  }, [esTecnicaSecundaria, anioFilter, validAnio]);

  /* 
    TODO: ver de ir habilitando los periodos a medida que pasan la fechas de los periodos
    Deberia plantearse,
    Una vez pasado el primer periodo habilitar la generacion de boletines de dicho periodo
  */

  const periodoFilter = useMemo(() => {
    return {
      nivel: [{ path: 'nivel.idNivel', value: nivel }],
      ...(establecimientoEfectivizado
        ? {
            periodos: [
              {
                path: 'tipoPeriodo',
                value: 1,
              },
              {
                path: 'tipoPeriodo',
                value: 2,
              },
            ],
          }
        : {
            periodos: [
              {
                path: 'tipoPeriodo',
                value: 1,
              },
              {
                path: 'tipoPeriodo',
                value: 2,
              },
              {
                path: 'tipoPeriodo',
                value: 3,
              },
              {
                path: 'tipoPeriodo',
                value: 4,
              },
              {
                path: 'tipoPeriodo',
                value: 5,
              },
              {
                path: 'tipoPeriodo',
                value: 6,
              },
              // PARA PIP PPS SOLO PRIMARIA E INICIAL
            ],
          }),
    };
    // }
  }, [nivel, establecimientoEfectivizado]);

  return useMemo(
    () => (
      <>
        <Grid
          container
          justify="flex-start"
          alignItems="flex-end"
          spacing={3}
          style={{ padding: '0 15px 15px', paddingRight: 15, marginTop: 23 }}
        >
          <Grid item xs={3}>
            {working ? (
              <Loading />
            ) : (
              <PeriodoSelect
                title="Período"
                placeholder="Período"
                labelKey="tipoPeriodo.descripcionTipoPeriodo"
                valueKey="idPeriodo"
                handleChange={onFilterChange}
                value={filter.periodo}
                orderBy="idPeriodo"
                direction="asc"
                filter={periodoFilter}
                transformOptions={(params: AnyObject[]): AnyObject[] => {
                  if (params.length === 0 || Number(levelId) !== NIVEL.INICIAL)
                    return params;
                  const editedParams = params.filter((param) =>
                    [2, 4].includes(param.tipoPeriodo.idTipoPeriodo),
                  );
                  const cuartoBimestreIndex = editedParams.findIndex(
                    (param) => param.tipoPeriodo.idTipoPeriodo === 4,
                  );

                  const segundoBimestreIndex = editedParams.findIndex(
                    (param) => param.tipoPeriodo.idTipoPeriodo === 2,
                  );

                  if (cuartoBimestreIndex !== -1) {
                    editedParams[
                      cuartoBimestreIndex
                    ].tipoPeriodo.descripcionTipoPeriodo = 'Informe Final';
                  }

                  if (segundoBimestreIndex !== -1) {
                    editedParams[
                      segundoBimestreIndex
                    ].tipoPeriodo.descripcionTipoPeriodo =
                      'Informe Cuatrimestral';
                  }
                  return editedParams;
                }}
              />
            )}
          </Grid>
          {Boolean(filter.periodo) ? (
            <Grid item xs={3}>
              <AnioSelect
                title="Año"
                placeholder="Año"
                name="anio"
                orderBy="numeroAnio,descripcionAnio"
                value={filter.anio}
                handleChange={onFilterChange}
                filter={anioFilter}
              />
            </Grid>
          ) : null}
        </Grid>
      </>
    ),
    [
      working,
      onFilterChange,
      filter.periodo,
      filter.anio,
      periodoFilter,
      anioFilter,
      levelId,
    ],
  );
};
