import { Rules } from 'src/lib/permission/types';
import { BLOCKER_ID, SIDEMENU_ID, TABBAR_BLOCKER_ID } from '../../const';

// route director
export const direccionAreaRuleSet: Rules = {
  idRule: 'rol-10',
  routes: [
    { key: 'repositorioABM' },
    { key: 'planificacionesFeedback' },
    { key: 'aulasvirtuales' },
    { key: 'configurationAdmin' },
  ],
  blockers: [
    { id: BLOCKER_ID.REQUEST_ROL_ESTUDIANTE },
    { id: BLOCKER_ID.REQUEST_ROL_RESPONSABLE },
    { id: BLOCKER_ID.REQUEST_ROL_BUE },
    { id: BLOCKER_ID.APPROVE_DIRECTIVE_USER },
    { id: BLOCKER_ID.VALIDATE_USER_BY_SCHOOL },
    { id: BLOCKER_ID.VALIDATE_USER_INTEC },
    { id: BLOCKER_ID.VIEW_REPOSITORY_ABM },
  ],
  sideMenu: [
    { id: SIDEMENU_ID.REPOSITORY_ABM },
    { id: SIDEMENU_ID.COMUNICATIONS },
    { id: SIDEMENU_ID.NOTIFICATIONS },
    { id: SIDEMENU_ID.CALENDAR },
    { id: SIDEMENU_ID.DOCUMENTS },
    { id: SIDEMENU_ID.ALUMNOS_AULAS_VRITUALES },
    { id: SIDEMENU_ID.CONFIGURACION_ADMIN },
    // { id: SIDEMENU_ID.CALIFICACIONES },
  ],
  tabBars: [{ id: TABBAR_BLOCKER_ID.PRESENTISMO_CONFIGURACION }],
};
