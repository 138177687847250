/* eslint-disable no-useless-escape */
import {
  Grid,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { cloneDeep, isEqual, set } from 'lodash';
import React, { useCallback, useMemo, useState } from 'react';
import { DatePickerV2 } from 'src/app/components';
import { ButtonTypesProps, Footer, Loading } from 'src/commons';
import { SelectInput } from 'src/commons/components/SelectInput';
import confirmDialog from 'src/commons/services/confirmDialog';
import { grey } from 'src/theme/colors';
import {
  OrganizacionFormInitialValue,
  OrganizacionFormProps,
} from '../../types';
import { InputComponent } from './InputComponent';
import { ReferenteOrganizacionForm } from './ReferenteOrganizacionForm';
import { SedesForm } from './SedesForm';
import { useInputValidator } from '../hooks/useInputValidator';
import { sedeInitialValue } from '../initialValues';

const CUIT_LIMIT = 13;
const TELEFONO_LIMIT = 20;
const DEFAULT_LIMIT = 70;

const useStyles = makeStyles({
  buttonLoading: {
    height: '20px',
    padding: '0',
    display: 'flex',
    alignContent: 'center',
  },
  labelAsterisk: {
    color: 'red',
  },
  boxHeight: {
    minHeight: '3.5em',
    maxHeight: '6.5em',
    width: '24.5rem',
    marginBottom: '1em',
  },
});

export const OrganizacionForm = ({
  sectores = [],
  initialValueForm,
  handleCancel,
  handleSubmit,
}: OrganizacionFormProps) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const copiaInicial = cloneDeep({ ...initialValueForm });
  const [formValues, setFormValues] = useState<OrganizacionFormInitialValue>({
    ...copiaInicial,
  });

  const handleFormChange = useCallback<
    React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
  >((e) => {
    let value = e.target.value;
    const name = e.target.name;
    if (name.charAt(0) === ' ') {
      return '';
    }
    const handleValidate = (prev: OrganizacionFormInitialValue) => {
      if (name === 'cuit') {
        if (value !== '' && !/^[0-9-]+$/.test(value)) return { ...prev };
        if (prev.cuit.length > value.length) {
          // BORRANDO
          if (value.length === 3) {
            value = value.slice(0, 2);
          } else if (value.length === 11) {
            value = value.slice(0, 10);
          }
        } else if (prev.cuit.length < value.length) {
          if (value.length === 2) {
            value = value.slice(0, 2) + '-' + value.slice(2);
          } else if (value.length === 10) {
            value = value.slice(0, 10) + '-' + value.slice(10);
          }
        }
      }
      if (name === 'email') {
        if (/[|°!"#$%&/()=?¡`\\^~¬¿'{´:;[¨+*},<>]/.test(value)) {
          value = value.slice();
        }
        value = value.trim();
      }
      const newValue = set({ ...prev }, name, value);
      return { ...newValue };
    };
    setFormValues(handleValidate);
  }, []);

  const handleAddSede = useCallback(() => {
    setFormValues((prev) => {
      const clone = cloneDeep(prev);
      const olds = clone.sedes;
      olds.push({ ...sedeInitialValue });
      return { ...prev, sedes: olds };
    });
  }, [setFormValues]);

  const { errors, touched, handleTouche, setTouched } =
    useInputValidator<OrganizacionFormInitialValue>(formValues, {
      optionals: {
        idOrganizacion: true,
        idSede: true,
        nombre: true,
        referente: {
          idReferente: true,
          codigo: true,
          isResetPassword: true,
        },
        sedes: [
          {
            idSede: true,
            nombre: true,
            isMain: true,
            turnos: [{ idTurno: true, descripcionTurno: true }],
          },
        ],
        turnos: [{ idTurno: true, descripcionTurno: true }],
      },
    });

  const isEqualData = useMemo(() => {
    const { sedes: initialSedes, ...initial } = initialValueForm || {
      sedes: [],
    };
    const { sedes, ...values } = formValues;
    const isEquals = isEqual(initial, values);
    const isEqualSedesLength = initialSedes.length === sedes.length;
    const isEqualSedes = isEqual(
      JSON.stringify(initialSedes),
      JSON.stringify(sedes),
    );
    return isEquals && isEqualSedesLength && isEqualSedes;
  }, [formValues, initialValueForm]);

  const disabled = useMemo(() => {
    const hasErrors = Object.entries(errors).length > 0;

    return isEqualData || hasErrors;
  }, [errors, isEqualData]);

  const preventToLeft = useMemo(() => {
    const { sedes, referente, ...values } = formValues;
    const hasSomeReferenteValue = Object.values(referente).some((valor) =>
      Boolean(valor),
    );
    const hasSomeValue = Object.values(values).some((valor) => Boolean(valor));
    const hasSomeSede = sedes.filter((s) => s.direccion).length > 0;
    return (
      !isEqualData &&
      (hasSomeSede || hasSomeValue || hasSomeSede || hasSomeReferenteValue)
    );
  }, [formValues, isEqualData]);

  const footerButtons = useMemo<ButtonTypesProps[]>(
    () => [
      {
        title: 'Salir',
        handleOnClick: async () => {
          if (preventToLeft) {
            const confirm = await confirmDialog.show({
              title: '¿Desea continuar?',
              content:
                'La información precargada se perderá en caso que no guarde, por favor seleccione una opción.',
              confirmText: 'Salir sin guardar',
              cancelText: 'Seguir en esta pantalla',
            });

            if (confirm) {
              handleCancel();
            }
          } else {
            handleCancel();
          }
        },
        type: 'secondary',
        size: 'medium',
      },
      {
        title: loading ? (
          <Loading className={classes.buttonLoading} />
        ) : (
          'Guardar'
        ),
        handleOnClick: async () => {
          setLoading(true);
          await handleSubmit(formValues, false);
          setLoading(false);
        },
        type: 'primary',
        size: 'medium',
        disabled: loading || disabled,
      },
      {
        title: loading ? (
          <Loading className={classes.buttonLoading} />
        ) : (
          'Guardar y asociar acción'
        ),
        handleOnClick: async () => {
          setLoading(true);
          await handleSubmit(formValues, true);
          setLoading(false);
        },
        type: 'primary',
        size: 'medium',
        disabled: loading || disabled,
      },
    ],
    [
      classes.buttonLoading,
      disabled,
      formValues,
      handleCancel,
      handleSubmit,
      loading,
      preventToLeft,
    ],
  );

  const formInfo = useMemo(
    () => ({
      initialValues: { ...initialValueForm },
      errors,
      touched,
      handleTouche,
      setTouched,
    }),
    [errors, handleTouche, setTouched, touched, initialValueForm],
  );

  return (
    <>
      <Grid
        style={{ border: '1px solid black', marginTop: '15px', padding: 20 }}
      >
        <Grid item container>
          <Typography variant="h4" style={{ color: grey.heading }}>
            Datos de la organización
          </Typography>
        </Grid>
        <Grid item container spacing={2} style={{ padding: 20 }}>
          <InputComponent>
            <Typography variant="h5" style={{ marginBottom: 10 }}>
              Nombre:
              <span className={classes.labelAsterisk}>
                {!errors?.nombre ? '' : '*'}
              </span>
            </Typography>
            <TextField
              variant="outlined"
              name="nombre"
              value={formValues.nombre}
              onChange={handleFormChange}
              margin="dense"
              size="small"
              fullWidth
              style={{ width: '25em' }}
              inputProps={{ maxLength: DEFAULT_LIMIT }}
              helperText=" "
              onFocus={handleTouche}
              onBlur={handleTouche}
            />
          </InputComponent>
          <InputComponent>
            <Typography variant="h5" style={{ marginBottom: 10 }}>
              CUIT:
            </Typography>
            <TextField
              variant="outlined"
              name="cuit"
              value={formValues.cuit}
              onChange={handleFormChange}
              margin="dense"
              size="small"
              fullWidth
              style={{ width: '25em' }}
              inputProps={{ maxLength: CUIT_LIMIT }}
              helperText={
                touched.cuit && errors.cuit ? 'Ingrese hasta 11 numeros' : ' '
              }
              error={touched.cuit && errors.cuit}
              onFocus={handleTouche}
              onBlur={handleTouche}
            />
          </InputComponent>
          <InputComponent>
            <Typography variant="h5" style={{ marginBottom: 10 }}>
              Sector:
              <span className={classes.labelAsterisk}>
                {!errors?.sector ? '' : '*'}
              </span>
            </Typography>
            <SelectInput
              name="sector"
              value={formValues?.sector}
              handleChange={handleFormChange}
              placeholder="Seleccioná sector"
              options={sectores}
              labelKey="nombre"
              valueKey="idSector"
              fullWidth
              variant="outlined"
              InputProps={{ style: { height: 48 }, name: 'sector' }}
              inputProps={{ style: { height: 46 }, name: 'sector' }}
              style={{ width: '25em' }}
              helperText=" "
              onFocus={handleTouche}
              onBlur={handleTouche}
            />
          </InputComponent>
          <InputComponent>
            <Typography variant="h5" style={{ marginBottom: 10 }}>
              Domicilio:
              <span className={classes.labelAsterisk}>
                {!errors?.domicilio ? '' : '*'}
              </span>
            </Typography>
            <TextField
              variant="outlined"
              name="domicilio"
              value={formValues.domicilio}
              onChange={handleFormChange}
              margin="dense"
              size="small"
              fullWidth
              style={{ width: '25em' }}
              inputProps={{ maxLength: DEFAULT_LIMIT }}
              helperText={
                touched.domicilio && errors.domicilio
                  ? 'No ingrese caracteres especiales'
                  : ' '
              }
              error={touched.domicilio && errors.domicilio}
              onFocus={handleTouche}
              onBlur={handleTouche}
            />
          </InputComponent>
          <InputComponent>
            <Typography variant="h5" style={{ marginBottom: 10 }}>
              Teléfono:
            </Typography>
            <TextField
              variant="outlined"
              name="telefono"
              type="phone"
              value={formValues.telefono}
              onChange={handleFormChange}
              margin="dense"
              size="small"
              fullWidth
              helperText={
                touched.telefono && errors.telefono
                  ? 'Ingrese solo números'
                  : ' '
              }
              error={touched.telefono && errors.telefono}
              style={{ width: '25em' }}
              inputProps={{ maxLength: TELEFONO_LIMIT }}
              onFocus={handleTouche}
              onBlur={handleTouche}
            />
          </InputComponent>
          <InputComponent>
            <Typography variant="h5" style={{ marginBottom: 10 }}>
              Mail:
            </Typography>
            <TextField
              variant="outlined"
              name="email"
              type="email"
              value={formValues.email}
              onChange={handleFormChange}
              margin="dense"
              size="small"
              fullWidth
              helperText={
                touched.email && errors.email ? 'Ingrese un mail válido' : ' '
              }
              error={touched.email && errors.email}
              style={{ width: '25em' }}
              onFocus={handleTouche}
              onBlur={handleTouche}
            />
          </InputComponent>
        </Grid>
        <Grid container item md={6} justifyContent="center" alignItems="center">
          <Typography style={{ fontFamily: 'Open Sans' }}>
            Agregar otra Sede
          </Typography>
          <IconButton onClick={handleAddSede}>
            <AddCircleOutlineIcon color="primary" />
          </IconButton>
        </Grid>
      </Grid>
      <SedesForm
        formValues={formValues}
        formInfo={cloneDeep(formInfo)}
        handleFormChange={handleFormChange}
        setFormValues={setFormValues}
      />
      <ReferenteOrganizacionForm
        formValues={formValues}
        formInfo={cloneDeep(formInfo)}
        handleFormChange={handleFormChange}
        setFormValues={setFormValues}
      />
      <Grid
        container
        style={{ border: '1px solid black', marginTop: '15px', padding: 20 }}
      >
        <Grid item container>
          <Typography variant="h4" style={{ color: grey.heading }}>
            Vigencia
          </Typography>
        </Grid>
        <Grid item container spacing={2} style={{ padding: 20 }}>
          <InputComponent>
            <Typography variant="h5" style={{ marginBottom: 10 }}>
              Fecha de inicio:
              <span className={classes.labelAsterisk}>
                {!errors?.fechaInicio ? '' : '*'}
              </span>
            </Typography>
            <DatePickerV2
              value={formValues?.fechaInicio}
              onChange={(_date) => {
                if (!_date) return;
                const newDate = _date.toDate();
                setFormValues((prev) => {
                  if (prev?.fechaFin) {
                    const fechaFin = new Date(prev?.fechaFin);
                    return {
                      ...prev,
                      fechaInicio: newDate,
                      ...(newDate.getTime() > fechaFin.getTime()
                        ? { fechaFin: _date?.toDate() }
                        : {}),
                    };
                  } else {
                    return {
                      ...prev,
                      fechaInicio: newDate,
                    };
                  }
                });
              }}
              inputProps={{ style: { width: '25em', padding: 12 } }}
              helperText={
                errors.fechaInicio && touched.fechaInicio
                  ? 'Ingrese una fecha valida'
                  : ' '
              }
              error={errors.fechaInicio && touched.fechaInicio}
            />
          </InputComponent>
          <InputComponent>
            <Typography variant="h5" style={{ marginBottom: 10 }}>
              Fecha de fin:
              <span className={classes.labelAsterisk}>
                {!errors?.fechaFin ? '' : '*'}
              </span>
            </Typography>
            <DatePickerV2
              value={formValues?.fechaFin}
              disabled={!formValues?.fechaInicio}
              onChange={(_date) => {
                if (!_date) return;
                if (!formValues.fechaInicio) return;
                const newDate = _date.toDate();
                if (newDate.getTime() >= formValues.fechaInicio.getTime()) {
                  setFormValues((prev) => {
                    return {
                      ...prev,
                      fechaFin: newDate,
                    };
                  });
                }
              }}
              fullWidth
              minDate={formValues?.fechaInicio}
              inputProps={{ style: { width: '25em', padding: 12 } }}
              helperText={
                errors.fechaFin && touched.fechaFin
                  ? 'Ingrese una fecha valida'
                  : ' '
              }
              error={errors.fechaFin && touched.fechaFin}
            />
          </InputComponent>
        </Grid>
      </Grid>
      <Footer buttonConfig={footerButtons} />
    </>
  );
};
