import React, { useMemo, useState } from 'react';
import { ButtonTypesProps, Footer, Loading, SimpleSelect } from 'src/commons';
import Evaluador from './Evaluador';
import { useAutoevaluacionFormContext } from '../../hooks/useAutoevaluacionFormContext';
import { useEstudianteContext } from '../../contexts/EstudianteContext';
import { Typography } from '@material-ui/core';

const categorias = [
  {
    idCategoria: 1,
    categoria: 'Perfil del Egresado',
  },
  {
    idCategoria: 2,
    categoria: 'Habilidades',
  },
  {
    idCategoria: 3,
    categoria: 'Contenidos',
  },
];

export const AutoevaluacionTab = ({
  actividad,
  currentBloque,
  selectedCategoria,
  setCurrentBloque,
  setSelectedCategoria,
}: {
  actividad: string;
  currentBloque: number;
  selectedCategoria: number;
  setCurrentBloque: React.Dispatch<React.SetStateAction<number>>;
  setSelectedCategoria: React.Dispatch<React.SetStateAction<number>>;
}) => {
  const { form, values, submitting, handleSubmit } =
    useAutoevaluacionFormContext();
  const { data: listNodos } = useEstudianteContext();
  const items = useMemo(() => {
    switch (selectedCategoria) {
      case 1:
        return values.perfiles;
      case 2:
        return values.habilidades;
      default:
        return values.contenidos;
    }
  }, [
    selectedCategoria,
    values.perfiles,
    values.habilidades,
    values.contenidos,
  ]);

  const accionOrientacion = useMemo(() => {
    return listNodos.filter((ao) => ao.accion.idAccion === actividad);
  }, [listNodos, actividad]);

  const footerButtons: ButtonTypesProps[] = [
    {
      title: 'Guardar Autoevaluación',
      disabled:
        submitting ||
        form.getState().invalid ||
        [
          accionOrientacion[0].habilidades.length === 0,
          accionOrientacion[0].perfiles.length === 0,
          accionOrientacion[0].nodos.length === 0,
        ].every(Boolean),
      handleOnClick: () => {
        setSelectedCategoria(selectedCategoria);
        setCurrentBloque(currentBloque);
        handleSubmit();
      },
    },
  ];
  /* const [currentBloques, setCurrentBloques] = useState(values.bloque);
  const [selectedCategoria, setSelectedCategoria] = useState(values.categoria); */

  interface Bloques {
    idBloque: number;
    nombre: string;
  }

  const bloques = useMemo(() => {
    const bloques: Bloques[] = [];
    accionOrientacion
      .find((e) => e.accion.idAccion === actividad)
      ?.nodos.forEach((nodo) => {
        if (
          bloques.findIndex(
            (bloque) => bloque.idBloque === parseInt(nodo.bloque.idBloque, 10),
          ) < 0
        ) {
          bloques.push({
            idBloque: Number(nodo.bloque.idBloque),
            nombre: nodo.bloque.nombre,
          });
        }
      });
    return bloques;
  }, [actividad, accionOrientacion]);

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', marginTop: '20px' }}>
        <div style={{ marginRight: '50px', marginLeft: '10px' }}>
          <Typography>Categoría:</Typography>
          <SimpleSelect
            name="categoria"
            value={selectedCategoria}
            handleChange={(e) => {
              setSelectedCategoria(e.target.value as 1 | 2 | 3);
              //form.change('categoria', e.target.value as 1 | 2 | 3);
            }}
            placeholder="Seleccioná la categoría"
            content={categorias}
            labelKey="categoria"
            valueKey="idCategoria"
            customStyleContainer={{ width: '250px' }}
          />
        </div>
        {selectedCategoria === 3 ? (
          <div>
            <Typography>Bloque:</Typography>
            <SimpleSelect
              name="Bloque"
              value={currentBloque}
              handleChange={(e) => {
                setCurrentBloque(e.target.value);
                // form.change('bloque', e.target.value);
              }}
              placeholder="Seleccioná el bloque"
              content={bloques}
              labelKey="nombre"
              valueKey="idBloque"
              customStyleContainer={{ width: '250px' }}
            />
          </div>
        ) : null}
      </div>
      {submitting ? (
        <Loading />
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            marginTop: '50px',
            marginLeft: '50px',
          }}
        >
          <Evaluador
            items={items}
            currentBloque={currentBloque}
            actividad={actividad}
          />

          <Footer buttonConfig={footerButtons} spacing={3} />
        </div>
      )}
    </div>
  );
};
