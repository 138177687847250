import React from 'react';
import { ModalidadType } from 'src/app/models/Modalidad';
import { useRolesContext } from 'src/context/RolesContext';
import { useSaveTabIndex } from '../hooks/useSaveTabIndex';
import { AdultsEnrolled } from './Adults';
import { ByCenrolled } from './ByC';
import { InitialEnrolled } from './Initial';

const screenModalidad: any = {
  1: <InitialEnrolled />,
  2: <ByCenrolled />,
  3: <AdultsEnrolled />,
};
export const Enrolled = () => {
  useSaveTabIndex();
  const {
    selectedRole: { modalidad },
  } = useRolesContext();
  return screenModalidad[modalidad || ModalidadType.General];
};
