import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { GruposExtraAlumno } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'grupos_extra_alumno';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idGruposExtraAlumno',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idGruposExtraAlumno',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.GruposExtraAlumno
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.GruposExtraAlumno,
      hoc: { ...defaultOptions.hoc, ...businessOptions.GruposExtraAlumno.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<GruposExtraAlumno>(DAO_NAME, optionsToUse);

// hooks
export const useGruposExtraAlumnoFetchById = fetchById;
export const useGruposExtraAlumnoLibbyCall = libbyCall;
export const useGruposExtraAlumnoDAO = libbyDAO;
export const useGruposExtraAlumnoLibbyFetch = libbyFetch;

// components/hoc
export const GruposExtraAlumnoAutocomplete = entityAutocomplete;
export const GruposExtraAlumnoCall = renderPropCall;
export const GruposExtraAlumnoById = renderPropFetchById;
export const GruposExtraAlumnoSelect = simpleSelect;
export const GruposExtraAlumnoAccordionSelector = accordionSelector;
export const GruposExtraAlumnoSelectedChips = selectedChips;

// context
// ByIdContext
export const useGruposExtraAlumnoByIdContext = useByIdContext;
export const GruposExtraAlumnoByIdProvider = ByIdProvider;
export const GruposExtraAlumnoByIdContext = ByIdContext;
// GeneralContext
export const useGruposExtraAlumnoContext = useGeneralContext;
export const GruposExtraAlumnoProvider = GeneralProvider;
export const GruposExtraAlumnoContext = GeneralContext;
