import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { RepositorioAudienciaItem } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'repositorio_audiencia_item';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idRepositorioAudienciaItem',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idRepositorioAudienciaItem',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.RepositorioAudienciaItem
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.RepositorioAudienciaItem,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.RepositorioAudienciaItem.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<RepositorioAudienciaItem>(DAO_NAME, optionsToUse);

// hooks
export const useRepositorioAudienciaItemFetchById = fetchById;
export const useRepositorioAudienciaItemLibbyCall = libbyCall;
export const useRepositorioAudienciaItemDAO = libbyDAO;
export const useRepositorioAudienciaItemLibbyFetch = libbyFetch;

// components/hoc
export const RepositorioAudienciaItemAutocomplete = entityAutocomplete;
export const RepositorioAudienciaItemCall = renderPropCall;
export const RepositorioAudienciaItemById = renderPropFetchById;
export const RepositorioAudienciaItemSelect = simpleSelect;
export const RepositorioAudienciaItemAccordionSelector = accordionSelector;
export const RepositorioAudienciaItemSelectedChips = selectedChips;

// context
// ByIdContext
export const useRepositorioAudienciaItemByIdContext = useByIdContext;
export const RepositorioAudienciaItemByIdProvider = ByIdProvider;
export const RepositorioAudienciaItemByIdContext = ByIdContext;
// GeneralContext
export const useRepositorioAudienciaItemContext = useGeneralContext;
export const RepositorioAudienciaItemProvider = GeneralProvider;
export const RepositorioAudienciaItemContext = GeneralContext;
