import { Grid } from '@material-ui/core';
import React, { useState, useCallback } from 'react';
import { useSnackbar } from 'notistack';
import { useAlumnoExisteDAO } from 'src/app/business/businessCustomEndpoints';
import { Footer, Loading } from 'src/commons/components';
import customFormDialog from 'src/commons/services/customFormDialog';
import confirmDialog from 'src/commons/services/confirmDialog';
import { EnrolledSelectStudent } from './EnrolledSelectStudent';
import { AlumnAdd, RecuperaAlumno } from './AddStudentForm';
import { CicloLectivo } from 'src/app/models';
import { useAlumnoDAO } from 'src/app/business';
import { alumnEnrolledStatus } from 'src/commons';

const customStyle = {
  container: { paddingBottom: '15px' },
  buttonBack: { border: '1px solid transparent' },
};

export interface AlumnExistResponse {
  isExists: boolean;
  message: string;
  idAlumno: number;
  existError: boolean;
  isDeleted?: boolean;
  idLocalizacion?: number;
}

interface EnrolledAddStudentModalProps {
  toLegajoAlumno: (id: string | number) => void;
  localizacionId: string | number;
  commonSchool?: boolean;
  cicloLectivoActual?: CicloLectivo;
  reFetch?: () => void;
}

export const EnrolledAddStudentModal = ({
  localizacionId,
  toLegajoAlumno,
  commonSchool = false,
  reFetch,
  cicloLectivoActual,
}: EnrolledAddStudentModalProps) => {
  const [loading, setLoading] = useState(false);
  const alumnoExisteDAO = useAlumnoExisteDAO();
  const alumnoDAO = useAlumnoDAO();
  const { enqueueSnackbar } = useSnackbar();

  const buttonConfig = [
    {
      title: 'Cancelar',
      handleOnClick: () => customFormDialog.handleCancel(),
      type: 'secondary',
      size: 'small',
    },
  ];

  const handleAddExternalAlumn = useCallback(
    async (alumn: AlumnAdd | RecuperaAlumno, isDeleted?: boolean) => {
      try {
        setLoading(true);
        if (!isDeleted) {
          const alumnoExisteResponse: AlumnExistResponse =
            await alumnoExisteDAO.validate({
              ...alumn,
              localizacionId,
            });

          const alumnoData = await alumnoDAO.getById(alumn.idAlumno);

          if (alumnoExisteResponse.isExists) {
            if (alumnoExisteResponse.isDeleted) {
              // alumno exists and is deleted
              const confirm = await confirmDialog.show({
                title: 'Confirmación alta alumno',
                confirmText: 'Confirmar',
                cancelText: 'Cancelar',
                content: `El alumno se encuentra inscripto y dado de baja. ¿Desea reinscribirlo y darlo de alta?`,
              });
              if (confirm) {
                const alumnoExisteResponseRecupera: AlumnExistResponse =
                  await alumnoExisteDAO.recuperaAlumno({
                    ...alumn,
                    localizacionId,
                    idAlumno: alumnoExisteResponse.idAlumno,
                    localizacionPrevia: alumnoExisteResponse.idLocalizacion,
                    isMatriculado:
                      alumnoData?.idEstadoMatricula ===
                      alumnEnrolledStatus.MATRICULADO,
                  });

                if (alumnoExisteResponseRecupera.existError) {
                  throw new Error(
                    'Se ha producido un error al dar de alta el alumno',
                  );
                }
              }
            } else {
              // alumno exists then throw and error
              throw new Error(alumnoExisteResponse.message);
            }
          }

          if (alumnoExisteResponse.existError) {
            throw new Error('Se ha producido un error al agregar el alumno');
          }

          setLoading(false);
          !alumnoExisteResponse.isDeleted &&
            toLegajoAlumno(alumnoExisteResponse.idAlumno);
          alumnoExisteResponse.isDeleted && reFetch && reFetch();
          customFormDialog.handleCancel();
        } else {
          const alumnoData = await alumnoDAO.getById(alumn.idAlumno);

          const alumnoExisteResponse: AlumnExistResponse =
            await alumnoExisteDAO.recuperaAlumno({
              ...alumn,
              localizacionId,
              isMatriculado:
                alumnoData?.idEstadoMatricula ===
                alumnEnrolledStatus.MATRICULADO,
            });

          if (alumnoExisteResponse.existError) {
            throw new Error(
              'Se ha producido un error al dar de alta el alumno',
            );
          }
          setLoading(false);
          // toLegajoAlumno(alumnoExisteResponse.idAlumno);
          reFetch && reFetch();
          customFormDialog.handleCancel();
        }
      } catch (e) {
        console.log(e);
        enqueueSnackbar(e.message, { variant: 'error' });
        setLoading(false);
      }
    },
    [
      enqueueSnackbar,
      toLegajoAlumno,
      localizacionId,
      alumnoExisteDAO,
      reFetch,
      alumnoDAO,
    ],
  );

  return (
    <>
      <Grid container style={customStyle.container}>
        {loading ? (
          <Loading />
        ) : (
          <EnrolledSelectStudent
            localizacionId={localizacionId}
            commonSchool={commonSchool}
            cicloLectivoActual={cicloLectivoActual}
            handleAddExternalAlumn={handleAddExternalAlumn}
          />
        )}
      </Grid>
      <Footer buttonConfig={buttonConfig} spacing={1} />
    </>
  );
};
