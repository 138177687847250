import { Chip } from '@material-ui/core';
import React from 'react';
import { DateRangeButtonProps } from '../types';

export const DateRangeButton = ({
  interval,
  children,
  ...rest
}: DateRangeButtonProps) => {
  return (
    <Chip
      label={children ?? interval}
      clickable
      color="primary"
      variant="outlined"
      {...rest}
    />
  );
};
