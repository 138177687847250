import { Tooltip, Typography } from '@material-ui/core';
import React from 'react';

export const TooltipColumn = ({
  title,
  charLength = 15,
}: {
  title: string;
  charLength?: number;
}) => {
  return title?.length > 15 ? (
    <Tooltip title={title}>
      <Typography>{`${title.slice(0, charLength)}...`}</Typography>
    </Tooltip>
  ) : (
    <Typography>{title}</Typography>
  );
};
