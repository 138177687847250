import React, { useMemo, useState } from 'react';
import {
  Box,
  Grid,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { Loading, MainInput, TabBar, useTabBarValue } from 'src/commons';
import { useRolesContext } from 'src/context/RolesContext';
import { SimpleSelect } from '../../../commons/components/Select/Select';

import { Apreciacion, AutoevaluacionTab } from './components';
import { useEstudianteContext } from './contexts/EstudianteContext';
import { useGetFilterInfo } from './hooks/useGetFilterInfo';
import { FormContextProvider } from 'src/lib/templates';
import {
  apreciacionTemplate,
  autoevaluacionTemplate,
} from './utils/autoevaluacionTemplate';
import { useGetFilter } from './hooks/useGetFilter';
import { useApreciacionSubmit } from './hooks/useApreciacionSubmit';
import { useAutoevaluacionSubmit } from './hooks/useAutoevaluacionSubmit';
import { useGetInitialValues } from './hooks/useGetInitialValues';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  miDiv: {
    border: '1px solid',
    borderColor: 'rgba(0, 0, 0, 0.2)',
    borderRadius: '4px',
    height: '100px',
    width: '90%',
    marginTop: '10px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  },
});

export const Autoevaluacion = () => {
  const [shoudGoToTabOne, setShouldGoToTabOne] = useState(false);
  const { userInfo, selectedRole } = useRolesContext();
  const { grupos } = useEstudianteContext();
  const { params } = useTabBarValue();

  const estudiante = `${userInfo.apellido} ${userInfo.name}`;

  const { organizaciones, actividades } = useGetFilterInfo();

  const { actividad, organizacion, setActividad, setOrganizacion } =
    useGetFilter();
  const currentGrupo = grupos.find((grupo) =>
    Boolean(params.id)
      ? grupo.idGrupoInscripcion === params.id
      : grupo.ofertaGrupo.ofertaTurno.oferta.accion.idAccion === actividad &&
        grupo.ofertaGrupo.ofertaTurno.oferta.accion.organizacion
          .idOrganizacion === organizacion,
  );

  const {
    loadingApreciacion,
    apreciacionInitialValues,
    autoevaluacionInitialValues,
    loadingAutoevaluacionValues,
    refetch,
    refetchApreciacion,
  } = useGetInitialValues(actividad, organizacion, currentGrupo);

  const { enqueueSnackbar } = useSnackbar();

  const apreciacionSubmitHandler = useApreciacionSubmit((error) => {
    enqueueSnackbar(
      error
        ? 'Ha ocurrido un error al guardar. Por favor inténtelo nuevamente.'
        : 'Se ha guardado con éxito',
      {
        variant: error ? 'error' : 'success',
      },
    );
    refetch();
    refetchApreciacion();
  });

  const [currentBloque, setCurrentBloque] = useState(0);
  const [categorias, setCategorias] = useState(0);
  const autoevaluacionSubmitHandler = useAutoevaluacionSubmit(
    (/*_categoria, _bloque, */ error) => {
      enqueueSnackbar(
        error
          ? 'Ha ocurrido un error al guardar. Por favor inténtelo nuevamente.'
          : 'Se ha guardado con éxito',
        {
          variant: error ? 'error' : 'success',
        },
      );
      refetch();
      setCategorias(categorias);
      setCurrentBloque(currentBloque);
    },
  );
  const allTabs = useMemo(() => {
    return [
      {
        title: 'Apreciación de la actividad',
        component: loadingApreciacion ? (
          <Loading />
        ) : (
          <FormContextProvider
            onSubmit={apreciacionSubmitHandler}
            initialValues={apreciacionInitialValues}
            template={apreciacionTemplate}
          >
            <Apreciacion />
          </FormContextProvider>
        ),
      },
      {
        title: 'Autoevaluación',
        component: loadingAutoevaluacionValues ? (
          <Loading />
        ) : (
          <FormContextProvider
            onSubmit={autoevaluacionSubmitHandler}
            initialValues={{
              ...autoevaluacionInitialValues,
              categoria: categorias,
              bloque: currentBloque,
            }}
            template={autoevaluacionTemplate}
          >
            <AutoevaluacionTab
              actividad={actividad}
              currentBloque={currentBloque}
              selectedCategoria={categorias}
              setCurrentBloque={setCurrentBloque}
              setSelectedCategoria={setCategorias}
            />
          </FormContextProvider>
        ),
      },
    ];
  }, [
    actividad,
    apreciacionInitialValues,
    apreciacionSubmitHandler,
    autoevaluacionInitialValues,
    autoevaluacionSubmitHandler,
    categorias,
    loadingApreciacion,
    loadingAutoevaluacionValues,
    currentBloque,
  ]);
  const { loadingGrupos, loadingListNodos } = useEstudianteContext();
  return loadingGrupos || loadingListNodos ? (
    <Loading />
  ) : (
    <>
      <Grid
        container
        component={Box}
        height="auto"
        border="1px solid rgba(0, 0, 0, 0.2)"
        borderRadius={4}
        padding={4}
        marginY={4}
        marginRight={2}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3} container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h5">Estudiante:</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                label=""
                fullWidth
                value={estudiante}
                InputProps={{
                  disableUnderline: true,
                  readOnly: true,
                }}
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h5">Establecimiento:</Typography>
            </Grid>
            <Grid item xs={6}>
              <Tooltip title={selectedRole.localizacion} placement="top">
                <TextField
                  label=""
                  fullWidth
                  value={selectedRole.localizacion}
                  InputProps={{
                    disableUnderline: true,
                    readOnly: true,
                  }}
                />
              </Tooltip>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h5">Organización:</Typography>
            </Grid>
            <Grid item xs={6}>
              <SimpleSelect
                disabled={Boolean(params.id)}
                name="Organización"
                value={organizacion}
                handleChange={(e) => setOrganizacion(e.target.value)}
                placeholder="Organización"
                content={[...organizaciones]}
                labelKey="nombre"
                valueKey="idOrganizacion"
                fullWidth
              />
            </Grid>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3} container alignItems="center">
            <Grid item xs={6}>
              <Typography variant="h5">Actividad:</Typography>
            </Grid>
            <Grid item xs={6}>
              <SimpleSelect
                disabled={Boolean(params.id)}
                name="Actividad"
                value={actividad}
                handleChange={(e) => setActividad(e.target.value)}
                placeholder="Nombre de la Actividad"
                content={[...actividades].filter(
                  (accion) =>
                    accion.organizacion.idOrganizacion === organizacion,
                )}
                labelKey="nombre"
                valueKey="idAccion"
                fullWidth
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {Boolean(currentGrupo) && organizacion > 0 && Number(actividad) > 0 ? (
        <TabBar
          content={allTabs}
          loading={false}
          shoudGoToTabOne={shoudGoToTabOne}
          setShouldGoToTabOne={setShouldGoToTabOne}
        />
      ) : null}
    </>
  );
};
