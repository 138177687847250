import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class PropositosOrientacionDAO extends LibbyFetchDAO {
  constructor() {
    super('propositos_orientacion', 'idPropositoOrientacion');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
