import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Ciclo } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'ciclo';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idCiclo',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idCiclo',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Ciclo
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Ciclo,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Ciclo.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Ciclo>(DAO_NAME, optionsToUse);

// hooks
export const useCicloFetchById = fetchById;
export const useCicloLibbyCall = libbyCall;
export const useCicloDAO = libbyDAO;
export const useCicloLibbyFetch = libbyFetch;

// components/hoc
export const CicloAutocomplete = entityAutocomplete;
export const CicloCall = renderPropCall;
export const CicloById = renderPropFetchById;
export const CicloSelect = simpleSelect;
export const CicloAccordionSelector = accordionSelector;
export const CicloSelectedChips = selectedChips;

// context
// ByIdContext
export const useCicloByIdContext = useByIdContext;
export const CicloByIdProvider = ByIdProvider;
export const CicloByIdContext = ByIdContext;
// GeneralContext
export const useCicloContext = useGeneralContext;
export const CicloProvider = GeneralProvider;
export const CicloContext = GeneralContext;
