import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { InformesHistory } from '../models/documents/InformesHistory';
// import { InformesHistory } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'informeshistory';
const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idInformesHistory',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idInformesHistory',
      labelKey: 'descripcion',
    },
  },
};
const optionsToUse = !businessOptions.InformesHistory
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.InformesHistory,
      hoc: { ...defaultOptions.hoc, ...businessOptions.InformesHistory.hoc },
    };
const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<InformesHistory>(DAO_NAME, optionsToUse);
// hooks
export const useInformesHistoryFetchById = fetchById;
export const useInformesHistoryLibbyCall = libbyCall;
export const useInformesHistoryDAO = libbyDAO;
export const useInformesHistoryLibbyFetch = libbyFetch;
// components/hoc
export const InformesHistoryAutocomplete = entityAutocomplete;
export const InformesHistoryCall = renderPropCall;
export const InformesHistoryById = renderPropFetchById;
export const InformesHistorySelect = simpleSelect;
export const InformesHistoryAccordionSelector = accordionSelector;
export const InformesHistorySelectedChips = selectedChips;
// context
// ByIdContext
export const useInformesHistoryByIdContext = useByIdContext;
export const InformesHistoryByIdProvider = ByIdProvider;
export const InformesHistoryByIdContext = ByIdContext;
// GeneralContext
export const useInformesHistoryContext = useGeneralContext;
export const InformesHistoryProvider = GeneralProvider;
export const InformesHistoryContext = GeneralContext;
