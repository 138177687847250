import moment from 'moment';
import { LibbyFetchDAO } from './LibbyFetchDAO';
import { TIPO_PERIODO } from 'src/commons';

export class PeriodoNuevoDAO extends LibbyFetchDAO {
  constructor() {
    super('PeriodoNuevo', 'idPeriodoNuevo');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getFirstPeriodo(nivel: number) {
    return this.query()
      .equals('tipoPeriodo', TIPO_PERIODO.PRIMER_BIMESTRE)
      .equals('nivel', nivel)
      .run();
  }

  getPeriodoActual(nivel: string) {
    const today = moment().format('YYYY-MM-DD');
    return (
      this.query()
        /*   .lowerOrEquals('fecha_inicio', today as unknown as number)
        .and()
        .higherOrEquals('fecha_fin', today as unknown as number)
        .and() */
        .equals('nivel', Number(nivel))
        .orderBy('fechaInicio', 'asc')
        .limit(0, 100)
        .run()
    );
  }

  getPeriodoActualByDate(nivel: string) {
    const today = moment().format('YYYY-MM-DD');
    return this.query()
      .lowerOrEquals('fecha_inicio', today as unknown as number)
      .and()
      .higherOrEquals('fecha_fin', today as unknown as number)
      .and()
      .equals('nivel', Number(nivel))
      .limit(0, 100)
      .run();
  }
}
