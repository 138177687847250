import {
  Box,
  Grid,
  IconButton,
  TextField,
  Typography,
  makeStyles,
} from '@material-ui/core';
import Cached from '@material-ui/icons/Cached';
import React, { useCallback, useEffect } from 'react';
import { useReferentesLibbyCall } from 'src/app/business/acap';
import { grey, primary } from 'src/theme/colors';
import {
  OrganizacionFormInitialValue,
  ReferenteOrganizacionFormProps,
} from '../../types';
import { InputComponent } from './InputComponent';

const TELEFONO_LIMIT = 20;
const DEFAULT_LIMIT = 70;

const useStyles = makeStyles({
  labelAsterisk: {
    color: 'red',
  },
  boxHeight: {
    minHeight: '3.5em',
    maxHeight: '6.5em',
    width: '24.5rem',
  },
  iconColor: { color: '#207dd9' },
  iconDisabled: { color: primary.disabled },
});

export const ReferenteOrganizacionForm = ({
  formValues,
  formInfo,
  handleFormChange,
  setFormValues,
}: ReferenteOrganizacionFormProps<OrganizacionFormInitialValue>) => {
  const classes = useStyles();

  const { recall, working } = useReferentesLibbyCall({
    methodName: 'fetch',
    noAutoCall: true,
  });

  useEffect(() => {
    if (formValues.referente.email === '') return;
    if (!formInfo.touched.referente?.email) return;
    if (formInfo.errors.referente?.email) return;
    if (formInfo.initialValues?.referente?.email === formValues.referente.email)
      return;

    const verifyEmail = async () => {
      try {
        // tal vez falte validar aca el mai a mano
        const referenteFilter = {
          filter: {
            referente: [
              {
                path: 'email',
                value: formValues.referente.email,
              },
            ],
          },
          limit: 1,
        };
        const [referente] = await recall(referenteFilter);

        if (referente) {
          setFormValues((prev) => {
            const newReferente = {
              ...prev.referente,
              ...referente,
            };
            return { ...prev, referente: newReferente };
          });
        } else {
          setFormValues((prev) => {
            const newReferente = {
              ...prev.referente,
              idReferente: null,
            };
            return { ...prev, referente: newReferente };
          });
        }
      } catch (error) {
        console.log(error);
      }
    };
    verifyEmail();
  }, [
    formInfo.errors.referente?.email,
    formInfo.initialValues?.referente?.email,
    formInfo.touched.referente?.email,
    formValues.referente.email,
    recall,
    setFormValues,
  ]);

  const nombreError =
    formInfo.errors?.referente?.nombre && formInfo.touched.referente?.nombre;
  const apellidoError =
    formInfo.errors?.referente?.apellido &&
    formInfo.touched.referente?.apellido;
  const felefonoError =
    formInfo.errors?.referente?.telefono &&
    formInfo.touched.referente?.telefono;
  const emailError =
    formInfo.errors?.referente?.email && formInfo.touched.referente?.email;

  const helperTextEmail = emailError
    ? 'Ingrese un mail válido'
    : working
    ? 'Verificando existencia de correo...'
    : ' ';

  const handleResetPassword = useCallback(() => {
    setFormValues((prev) => {
      return {
        ...prev,
        referente: {
          ...prev.referente,
          isResetPassword: true,
          codigo: 'Miescuela123',
        },
      };
    });
  }, [setFormValues]);

  return (
    <Grid
      container
      style={{ border: '1px solid black', marginTop: '15px', padding: 20 }}
    >
      <Grid item container>
        <Typography variant="h4" style={{ color: grey.heading }}>
          Datos del referente de la organización
        </Typography>
      </Grid>
      <Grid item container spacing={2} style={{ padding: 20 }}>
        <InputComponent>
          <Typography variant="h5" style={{ marginBottom: 10 }}>
            Nombre del referente organización:
            <span className={classes.labelAsterisk}>
              {!formInfo.errors?.referente?.nombre ? '' : '*'}
            </span>
          </Typography>
          <TextField
            variant="outlined"
            name="referente.nombre"
            value={formValues.referente.nombre}
            onChange={handleFormChange}
            margin="dense"
            size="small"
            fullWidth
            style={{ width: '25em' }}
            inputProps={{ maxLength: DEFAULT_LIMIT }}
            helperText={
              nombreError ? 'Sin caracteres especiales ni números' : ' '
            }
            error={nombreError}
            onFocus={formInfo.handleTouche}
            onBlur={formInfo.handleTouche}
          />
        </InputComponent>
        <InputComponent>
          <Typography variant="h5" style={{ marginBottom: 10 }}>
            Apellido del referente organización:
            <span className={classes.labelAsterisk}>
              {!formInfo.errors?.referente?.apellido ? '' : '*'}
            </span>
          </Typography>
          <TextField
            variant="outlined"
            name="referente.apellido"
            value={formValues.referente.apellido}
            onChange={handleFormChange}
            margin="dense"
            size="small"
            fullWidth
            style={{ width: '25em' }}
            inputProps={{ maxLength: DEFAULT_LIMIT }}
            helperText={
              apellidoError ? 'Sin caracteres especiales ni números' : ' '
            }
            error={apellidoError}
            onFocus={formInfo.handleTouche}
            onBlur={formInfo.handleTouche}
          />
        </InputComponent>
        <InputComponent>
          <Typography variant="h5" style={{ marginBottom: 10 }}>
            Teléfono:
            <span className={classes.labelAsterisk}>
              {formValues?.referente?.telefono ? '' : '*'}
            </span>
          </Typography>
          <TextField
            variant="outlined"
            name="referente.telefono"
            value={formValues.referente.telefono}
            onChange={handleFormChange}
            margin="dense"
            size="small"
            fullWidth
            style={{ width: '25em' }}
            inputProps={{ maxLength: TELEFONO_LIMIT }}
            helperText={felefonoError ? 'Ingrese solo números' : ' '}
            error={felefonoError}
            onFocus={formInfo.handleTouche}
            onBlur={formInfo.handleTouche}
          />
        </InputComponent>
        <InputComponent>
          <Typography variant="h5" style={{ marginBottom: 10 }}>
            Mail:
            <span className={classes.labelAsterisk}>
              {formValues?.referente?.email ? '' : '*'}
            </span>
          </Typography>
          <TextField
            variant="outlined"
            name="referente.email"
            value={formValues.referente.email}
            onChange={handleFormChange}
            margin="dense"
            size="small"
            fullWidth
            style={{ width: '25em' }}
            helperText={helperTextEmail}
            error={emailError}
            onFocus={formInfo.handleTouche}
            onBlur={formInfo.handleTouche}
            FormHelperTextProps={{
              style: { color: working ? '#00bcd4' : undefined },
            }}
          />
        </InputComponent>
        <InputComponent>
          <Typography variant="h5" style={{ marginBottom: 10 }}>
            Código ingreso:
          </Typography>
          <Grid style={{ position: 'relative' }}>
            <TextField
              variant="outlined"
              name="referente.codigo"
              value={formValues.referente.codigo}
              margin="dense"
              size="small"
              fullWidth
              style={{ width: '25em' }}
              placeholder="no habilitado"
              disabled
            />
            <Grid
              component={Box}
              display="flex"
              position="absolute"
              top="12px"
              right="-41px"
            >
              <IconButton onClick={handleResetPassword}>
                <Cached color="primary" />
              </IconButton>
            </Grid>
          </Grid>
        </InputComponent>
      </Grid>
    </Grid>
  );
};
