import React, { useCallback, useMemo } from 'react';
import { Grid, IconButton, Tooltip, Button } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import { ObservacionesModal, SimpleSelect } from 'src/commons';
import customFormDialog from 'src/commons/services/customFormDialog';
import { useHandleFileUpload } from './useHandlePipFileUpload';
import { useInformesStyles } from './usePipInformeStyles';
import { useInformePipValidations } from './useInformePipValidations';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { InfoIcon } from 'src/assets/images/svgs/InfoIcon';

const RESPUESTAS_PREDETERMINADAS = [
  { descripcion: 'No' },
  { descripcion: 'Sí' },
];

const ROWS_WITH_ADJUNTO = ['11', '12'];
const ROWS_WITH_RESPUESTAS_PREDETERMINADAS = ['12'];
const ROWS_WITH_REQUIRED_ADJUNTO = ['12'];

export const usePipInformeColumns = (
  isLoading: boolean,
  isReadOnly: boolean,
  handleDataChange: any,
  pipDataAlumno: any,
  rows: any,
) => {
  const classes = useInformesStyles();

  const handleObservaciones: any = useCallback(
    (name: string, row: any) => {
      customFormDialog.show({
        title: 'Observaciones',
        renderComponent: (
          <ObservacionesModal
            content={row?.observaciones}
            onAddContent={(content: string) => {
              handleDataChange({ name, value: content }, row);
            }}
            value={row?.observaciones}
          />
        ),
        sizeWidth: 'sm',
      });
    },
    [handleDataChange],
  );

  const isClosed = pipDataAlumno ? !pipDataAlumno?.abierto : false;

  const isDisabled = isReadOnly || isClosed || isLoading;

  const handleRowDataChange = useCallback(
    (data: any, row: any) => {
      handleDataChange(data, row);
    },
    [handleDataChange],
  );

  const handleFileUpload = useHandleFileUpload();

  const handleFileChange = useCallback(
    async (event: any, row: any) => {
      const data = await handleFileUpload(event, row);
      handleDataChange(data, row);
    },
    [handleDataChange, handleFileUpload],
  );

  return useMemo(
    () => [
      {
        id: 'dimensiones',
        label: 'Dimensiones',
        width: '30%',
        orderById: 'dimensiones',
        hideSortIcon: true,
        style: classes.header,
        render: (value: any, row: any) => (
          <Grid className={`${classes.dimensiones}`} style={{ minWidth: 150 }}>
            <div className={`${classes.dimensionesText}`}>
              {row?.dimensiones}
            </div>
            <Tooltip
              classes={{ tooltip: classes.tooltip }}
              title={row.tooltipText}
            >
              <span>
                <InfoIcon />
              </span>
            </Tooltip>
          </Grid>
        ),
      },
      {
        id: 'observaciones',
        label: 'Observaciones',
        width: '45%',
        hideSortIcon: true,
        orderById: '',
        style: classes.header,
        render: (value: any, row: any) => {
          return (
            <>
              <Grid
                className={`${classes.observacionesWrapper}
								} `}
              >
                {ROWS_WITH_RESPUESTAS_PREDETERMINADAS.includes(
                  row?.preguntaNumero,
                ) ? (
                  <Grid style={{ minWidth: '100%' }}>
                    <div>
                      <SimpleSelect
                        placeholder="Seleccione opción"
                        labelKey="descripcion"
                        valueKey="descripcion"
                        content={RESPUESTAS_PREDETERMINADAS}
                        value={row?.observaciones}
                        handleChange={(event) => {
                          handleRowDataChange(event.target, row);
                        }}
                        name="observaciones"
                        disabled={isDisabled}
                      />
                    </div>
                  </Grid>
                ) : row?.observaciones ? (
                  <Grid className={`${classes.observaciones} `}>
                    <div className={`${classes.observacionesText}`}>
                      {`${row?.observaciones}`}
                    </div>
                    <IconButton
                      className={classes.iconObservacion}
                      disabled={isDisabled}
                      onClick={() => {
                        handleObservaciones('observaciones', row);
                      }}
                    >
                      <LibraryBooksIcon />
                    </IconButton>
                  </Grid>
                ) : (
                  <Grid className={`${classes.observaciones}`}>
                    <IconButton
                      className={classes.iconObservacion}
                      disabled={isDisabled}
                      onClick={() => {
                        handleObservaciones('observaciones', row);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                  </Grid>
                )}
              </Grid>
            </>
          );
        },
      },
      {
        id: 'archivoAdjunto',
        label: 'Archivo Adjunto',
        width: '15%',
        hideSortIcon: true,
        orderById: '',
        style: classes.header,
        render: (value: any, row: any) => (
          <>
            {ROWS_WITH_ADJUNTO.includes(row?.preguntaNumero) ? (
              <div>
                {Boolean(row?.archivoAdjunto) ? (
                  <div
                    className={classes.adjunto}
                    onClick={() =>
                      !isDisabled
                        ? handleFileChange(
                            {
                              target: {
                                name: 'archivoAdjunto',
                                value: 'adjuntoClose',
                              },
                            },
                            row,
                          )
                        : null
                    }
                  >
                    <div className={classes.adjuntoNombre}>
                      {row?.archivoAdjunto?.filename}
                    </div>
                    <div className={classes.adjuntoClose}>x</div>
                  </div>
                ) : (
                  <div className={`${null}`}>
                    <Button
                      variant="contained"
                      component="label"
                      color="primary"
                      fullWidth
                      disabled={
                        isDisabled ||
                        (ROWS_WITH_REQUIRED_ADJUNTO.includes(
                          row?.preguntaNumero,
                        ) &&
                          row.observaciones !== 'Sí')
                      }
                    >
                      ADJUNTAR
                      <input
                        type="file"
                        accept=".pdf,.docx,.jpg,.jpeg,.jpe,.png,.webp"
                        name="archivoAdjunto"
                        hidden
                        onChange={(event) => handleFileChange(event, row)}
                        disabled={
                          isDisabled ||
                          (ROWS_WITH_REQUIRED_ADJUNTO.includes(
                            row?.preguntaNumero,
                          ) &&
                            row.observaciones !== 'Sí')
                        }
                      />
                    </Button>
                  </div>
                )}
              </div>
            ) : null}
          </>
        ),
      },
    ],
    [
      classes.adjunto,
      classes.adjuntoClose,
      classes.adjuntoNombre,
      classes.dimensiones,
      classes.dimensionesText,
      classes.header,
      classes.iconObservacion,
      classes.observaciones,
      classes.observacionesText,
      classes.observacionesWrapper,
      classes.tooltip,
      handleFileChange,
      handleObservaciones,
      handleRowDataChange,
      isDisabled,
    ],
  );
};
