import { Valor } from '../types';

export const makeValuesDevolution = <K extends Valor = Valor>(valores: K[]) => {
  const devolution: K[] = [];
  valores.forEach((v) => {
    if (!devolution.find((d) => d.idTipo === v.idTipo)) {
      devolution.push(v);
    }
  });
  return devolution;
};
