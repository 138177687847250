import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ObjetivosDeAprendizajeDAO extends LibbyFetchDAO {
  constructor() {
    super('objetivos_de_aprendizaje', 'idObjetivosDeAprendizaje');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
