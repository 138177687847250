import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { LocalizacionNivel } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'localizacion_nivel';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idLocalizacionNivel',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idLocalizacionNivel',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.LocalizacionNivel
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.LocalizacionNivel,
      hoc: { ...defaultOptions.hoc, ...businessOptions.LocalizacionNivel.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<LocalizacionNivel>(DAO_NAME, optionsToUse);

// hooks
export const useLocalizacionNivelFetchById = fetchById;
export const useLocalizacionNivelLibbyCall = libbyCall;
export const useLocalizacionNivelDAO = libbyDAO;
export const useLocalizacionNivelLibbyFetch = libbyFetch;

// components/hoc
export const LocalizacionNivelAutocomplete = entityAutocomplete;
export const LocalizacionNivelCall = renderPropCall;
export const LocalizacionNivelById = renderPropFetchById;
export const LocalizacionNivelSelect = simpleSelect;
export const LocalizacionNivelAccordionSelector = accordionSelector;
export const LocalizacionNivelSelectedChips = selectedChips;

// context
// ByIdContext
export const useLocalizacionNivelByIdContext = useByIdContext;
export const LocalizacionNivelByIdProvider = ByIdProvider;
export const LocalizacionNivelByIdContext = ByIdContext;
// GeneralContext
export const useLocalizacionNivelContext = useGeneralContext;
export const LocalizacionNivelProvider = GeneralProvider;
export const LocalizacionNivelContext = GeneralContext;
