import React, { useMemo } from 'react';
import {
  EspacioCurricular as EspacioCurricularModels,
  EspacioPlan,
  ContenidoAll,
  ContenidoType,
} from 'src/app/models';
import { useArealInterarealContext } from '../../../forms/ArealInterareal/context';
import {
  useContenidoBloqueFetch,
  useContenidosEspecificosFetch,
  useHabilidadesFetch,
} from '../../../forms/ArealInterareal/tabs/hooks';

export interface FilterEspacios {
  espacios: Omit<
    EspacioCurricularModels,
    'localizacion' | 'planEstudio' | 'materia'
  >;
  habilidades: { idHabilidades: number; descripcion: string };
  contenidos: Array<ContenidoType>;
}

export const useEspacioCurricularLogic = (espacioPlan: EspacioPlan) => {
  const { espaciosCurriculares, workingAll } = useArealInterarealContext();

  const { habilidades } = useHabilidadesFetch();
  const { contenidosBloques } = useContenidoBloqueFetch();
  const { contenidosEspecificos } = useContenidosEspecificosFetch();

  const filterEspacios = useMemo(
    () =>
      espacioPlan?.espaciosCurriculares?.map((espacio: any) => {
        const espacios = espaciosCurriculares?.filter(
          (_espacio: any) =>
            _espacio?.idEspacioCurricular === espacio?.espacioCurricular?.id,
        )[0];
        const filterHabilidades = espacio?.habilidades.map(
          (habilidad: string) => {
            const select = habilidades.find(
              (_habilidad: any) => habilidad === _habilidad?.idHabilidades,
            );
            return select;
          },
        );

        const espacioBloque = espacio?.espacioCurricular?.tieneBloque;
        const isBloque: ContenidoAll[] = espacioBloque
          ? contenidosBloques
          : contenidosEspecificos;
        const filterContenidos: Array<any> = [];
        espacio?.contenidosEspecificos?.forEach(
          (contenido: { id: string; subLevel: Array<string> }) => {
            const select = isBloque
              .filter(
                (_contenido) =>
                  Number(contenido.id) ===
                    Number(_contenido?.idContenidoEspecifico) ||
                  Number(contenido.id) ===
                    Number(_contenido?.idContenidoBloque),
              )
              .map((_contenido) => ({
                id:
                  _contenido?.idContenidoEspecifico ||
                  _contenido?.idContenidoBloque,
                descripcion: _contenido.descripcion,
                subLevel: contenido.subLevel.map((subLevel: any) => {
                  const contenidoSubNivelAll: any = espacioBloque
                    ? _contenido?.contenidoBloqueSubnivels
                    : _contenido?.contenidoEspecificoSubnivels;

                  const sub = contenidoSubNivelAll.find(
                    (_subLevel: any) =>
                      Number(_subLevel?.idContenidoEspecificoSubnivel) ===
                        Number(subLevel.id) ||
                      Number(_subLevel?.idContenidoBloqueSubnivel) ===
                        Number(subLevel.id),
                  );
                  const contenidoSubNivelDosAll = espacioBloque
                    ? sub?.contenidoBloqueSubnivelDos
                    : sub?.contenidoEspecificoSubnivelDos;
                  return {
                    id:
                      sub?.idContenidoEspecificoSubnivel ||
                      sub?.idContenidoBloqueSubnivel,
                    descripcion: sub?.descripcion || '',
                    subLevel2: contenidoSubNivelDosAll.filter(
                      (_subLevel2: any) =>
                        subLevel?.subLevel2.find(
                          (valor: any) =>
                            _subLevel2?.idContenidoEspecificoSubnivelDos?.toString() ===
                              valor ||
                            _subLevel2?.idContenidoBloqueSubnivelDos?.toString() ===
                              valor,
                        ),
                    ),
                  };
                }),
              }));
            filterContenidos.push(...select);
          },
        );

        return {
          espacios,
          habilidades: filterHabilidades,
          contenidos: filterContenidos,
        };
      }),
    [
      contenidosEspecificos,
      contenidosBloques,
      espacioPlan?.espaciosCurriculares,
      habilidades,
      espaciosCurriculares,
    ],
  );

  return { filterEspacios, loading: workingAll };
};
