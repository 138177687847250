import { LibbyFetchDAO } from './LibbyFetchDAO';

export class InformesDAO extends LibbyFetchDAO {
  constructor() {
    super('informes', 'idInforme');
  }

  getAll() {
    return this.query().orderBy('idInforme', 'asc').limit(0, 10000).run();
  }

  getByIdAlumno(filter: any) {
    return this.fetch(filter);
  }
}
