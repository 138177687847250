import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AnyObject, InfoTableColumn } from '../../../../commons';
import { OfertaGrupo } from '../../../../app/models';
import { GruposInscripcionesSelect } from '../../../../app/business';
import { useEscuelaContext } from '../context/EscuelaContext';

export const columnsDetail: InfoTableColumn<OfertaGrupo>[] = [
  {
    id: 'ofertaTurno.oferta.accion.nombre',
    label: 'Acción',
    width: '20%',
    hideSortIcon: true,
  },
  {
    id: 'ofertaTurno.oferta.accion.tipoAccion.nombre',
    label: 'Tipo de acción',
    width: '10%',
    hideSortIcon: true,
  },
  {
    id: 'ofertaTurno.oferta.accion.organizacion.sector.nombre',
    label: 'Sector',
    width: '5%',
    hideSortIcon: true,
  },
  {
    id: 'ofertaTurno.oferta.accion.organizacion.nombre',
    label: 'Organización',
    width: '20%',
    hideSortIcon: true,
  },
  {
    id: 'equipo',
    label: 'Equipo',
    width: '10%',
    hideSortIcon: true,
    render: (value: string, row: OfertaGrupo) => {
      return (
        <>
          <p>{row.idOfertaGrupo}</p>
        </>
      );
    },
  },
];

export const useDevolucionDetailColumn = () => {
  const { filter, ofertaSelected, handleFilterChange } = useEscuelaContext();
  const [mostrarAlumnos, setMostrarAlumnos] = useState(false);
  const [seccionMostrar, setSeccionMostrar] = useState('');

  useEffect(() => {
    setMostrarAlumnos(false);
    if (filter.seccion !== undefined && filter.seccion !== '') {
      setMostrarAlumnos(true);
    }
  }, [filter]);

  const filterIncriptos = useMemo(() => {
    return {
      oferta: [
        {
          path: 'ofertaGrupo.idOfertaGrupo',
          value: ofertaSelected?.idOfertaGrupo || 0,
        },
      ],
    };
  }, [ofertaSelected]);

  const generateOptions = useCallback((options: AnyObject[]) => {
    const _options: AnyObject[] = [];
    options.forEach((option) => {
      const seccion = option.alumnoMovimiento.seccion;
      if (!_options.find((a) => a.idSeccion === seccion.idSeccion)) {
        _options.push(option.alumnoMovimiento.seccion);
      }
    });

    return _options;
  }, []);

  const transformOptions = useCallback(
    (options: AnyObject[]) => {
      const _options = generateOptions(options);

      if (_options.length === 1) {
        setSeccionMostrar(_options[0]?.idSeccion);
        setMostrarAlumnos(true);
      }
      return _options;
    },
    [generateOptions],
  );

  const _columns = useMemo<InfoTableColumn<OfertaGrupo>[]>(() => {
    const firstRow = {
      id: 'ofertaTurno.oferta.accion.seccion',
      label: 'Sección',
      width: '20%',
      hideSortIcon: true,
      render: () => {
        return (
          <GruposInscripcionesSelect
            label=""
            name="seccion"
            fullWidth
            value={
              seccionMostrar !== '' && seccionMostrar !== undefined
                ? seccionMostrar
                : filter.seccion
            }
            onChange={handleFilterChange}
            handleChange={handleFilterChange}
            placeholder="Seleccione una sección"
            labelKey="nombreSeccion"
            valueKey="idSeccion"
            transformOptions={transformOptions}
            filter={filterIncriptos}
            orderBy="alumnoMovimiento.seccion.nombreSeccion"
            enableFetch={Boolean(ofertaSelected)}
            InputLabelProps={{
              shrink: true,
            }}
          />
        );
      },
    };

    return [{ ...firstRow }, ...columnsDetail];
  }, [
    filterIncriptos,
    ofertaSelected,
    handleFilterChange,
    filter,
    seccionMostrar,
    transformOptions,
  ]);

  return { columns: _columns, mostrarAlumnos: mostrarAlumnos };
};
