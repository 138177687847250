import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { RepositorioItem } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'repositorio_item';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idRepositorioItem',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idRepositorioItem',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.RepositorioItem
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.RepositorioItem,
      hoc: { ...defaultOptions.hoc, ...businessOptions.RepositorioItem.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<RepositorioItem>(DAO_NAME, optionsToUse);

// hooks
export const useRepositorioItemFetchById = fetchById;
export const useRepositorioItemLibbyCall = libbyCall;
export const useRepositorioItemDAO = libbyDAO;
export const useRepositorioItemLibbyFetch = libbyFetch;

// components/hoc
export const RepositorioItemAutocomplete = entityAutocomplete;
export const RepositorioItemCall = renderPropCall;
export const RepositorioItemById = renderPropFetchById;
export const RepositorioItemSelect = simpleSelect;
export const RepositorioItemAccordionSelector = accordionSelector;
export const RepositorioItemSelectedChips = selectedChips;

// context
// ByIdContext
export const useRepositorioItemByIdContext = useByIdContext;
export const RepositorioItemByIdProvider = ByIdProvider;
export const RepositorioItemByIdContext = ByIdContext;
// GeneralContext
export const useRepositorioItemContext = useGeneralContext;
export const RepositorioItemProvider = GeneralProvider;
export const RepositorioItemContext = GeneralContext;
