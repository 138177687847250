import React, { useEffect } from 'react';
import { useField } from 'react-final-form-hooks';
import { usePropuestaJEFormContext } from 'src/screens/Private/Presentismo/context/configuration/PropuestaJEFormProvider';
import { presentismo } from 'src/screens/Private/Presentismo/types';
import { SimpleSelect } from '../Select';

export const SimpleSelectWithField = ({
  disabled = false,
  name,
  dia,
  ...props
}: presentismo.UI.SimpleSelectWithField) => {
  const { form } = usePropuestaJEFormContext();
  if (!form) throw new Error('El Select no está dentro del context');
  const field = useField(`${dia}.${name}`, form);

  if (field.input.value) form.change(`${dia}.sede`, null);
  return (
    <>
      {field.meta.touched && field.meta.error && (
        <span>{field.meta.error}</span>
      )}
      <SimpleSelect {...props} {...field.input} disabled={disabled} />
    </>
  );
};
