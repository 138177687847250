import { MenuItem } from '../../../../commons/const/ui/menu';
import { routes } from './routes';

export const aprendizajeBasadoEnProyectos: MenuItem = {
  key: 'aprendizajeBasadoEnProyectos',
  basePath: '/aprendizajeBasadoEnProyectos',
  enabled: true,
  icon: 'List',
  text: 'Aprendizaje basado en proyectos',
  children: routes,
};
