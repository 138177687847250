import moment from 'moment';
import { DiasHabilitados } from './../../../../../app/models/DiasHabilitados';
import {
  Alumno,
  AlumnoMovimiento,
  CicloLectivo,
  Cierre,
  ContraturnoDia,
  EspacioCurricular,
  Justificacion,
  PeriodoInicio,
  PeriodoNuevo,
  Presente_v3,
  PresentismoEspacioCurricular,
  Seccion,
  SituacionV3,
  SolicitudPases,
  Turno,
} from '../../../../../app/models';
import { EstadoPresente_v3 } from '../../../../../app/models/presentismo_v3/EstadoPresente_v3';
import { DateRangeListV3 } from '../components/ExceptuadosModal/types';
import { IndicatorColor } from '../components/Resume';
import { Jornada } from '../context';
import React from 'react';
import { EstadoPresente } from '../../../../../app/models/presentismo_v2';
import { date } from '../../../../../context/GeneralContext/actions';

export interface AsistenciaAcum {
  turno: number | null;
  presentes: number;
  ausentes: number;
  pendientes: number;
  otros: number;
  alertas: number;
}

export interface AsistenciaResumen {
  day: moment.Moment;
  rows: AsistenciaAcum[];
}

export interface JustificacionOpen {
  open: boolean;
  row: AlumnoMap | null;
}

export interface StudentReportOpen {
  open: boolean;
  row: AlumnoMap | null;
}

export interface RenderTurnosCell {
  label: string;
  idTurno?: number;
  materia?: string;
  idContraturnoDia?: number;
  descripcion: string;
  situacion?: { tipo?: number; descripcion?: string };
}

export interface RenderAsistenciasCell {
  state?: StateChange;
  turno?: _Turno;
  contraturnoDia?: ContraturnoDia;
  justificacion?: Justificacion | null;
}

export interface Alerta {
  active: boolean;
  total: number;
}

export interface AlertaTipoUno extends Alerta {
  countRetiroAnticipado: number;
  countIngresoTardio: number;
}
export interface AlertaTipoDosYTres extends Alerta {
  countAusencias: number;
}
export interface AlertaTipoCuatroCinco extends Alerta {
  countAusencias: number;
  countRetiroAnticipado: number;
  countIngresoTardio: number;
  countAusenciaPresenciaEnClase: number;
}
export type AlertasType = [
  AlertaTipoUno,
  AlertaTipoDosYTres,
  AlertaTipoDosYTres,
  AlertaTipoCuatroCinco,
  AlertaTipoCuatroCinco,
];

export interface AlertaGeneric {
  type: number;
  active: boolean;
  total: number;
  countAusencias: number;
  countRetiroAnticipado: number;
  countIngresoTardio: number;
  countAusenciaPresenciaEnClase: number;
}

export interface AlertasV3 {
  [k: string]: AlertasType;
}

export interface AlertasECV3 {
  [k: string]: AlertasV3;
}

export interface AlumnoMap extends AlumnoMovimiento {
  contraturnos: ContraturnoDia[];
  positionY?: number;
  presentismo: PresenteGeneral[];
  periodoInicio?: PeriodoInicio;
  exceptuados: DateRangeListV3;
  ausencias?: {
    ausenciaNaranja: boolean | undefined;
    ausenciaRojo: boolean | undefined;
    count: number | undefined;
  };
  alertas: AlertasType;
  solicitudPase?: SolicitudPases;
  isChangeSeccion: boolean;
}

export interface AlertaMessage {
  color: IndicatorColor;
  messages: Array<string>;
}

export interface PresenteGeneral {
  idPresente: string | null;
  alumno: Alumno;
  fecha: string;
  estadoPresente: EstadoPresente_v3;
  periodoNuevo: PeriodoNuevo;
  seccion: Seccion;
  turno: Turno | null;
  isJornadaExtendida?: boolean;
  espacio?: string;
  contraturnoDia: ContraturnoDia | null;
  justificacion: Justificacion | null;
  observacion?: string;
  situacion: SituacionV3 | null;
  espacioCurricular: EspacioCurricular | null;
  cicloLectivo?: CicloLectivo;
}

export interface AlumnoPresente {
  idPresente: string | null;
  alumno: Alumno;
  fecha: string;
  seccion: Seccion;
  isJornadaExtendida: boolean;
  estadoPresente?: Partial<EstadoPresente_v3> | null;
  turno?: Partial<Turno> | null;
  contraturnoDia?: Partial<ContraturnoDia> | null;
  periodoNuevo?: PeriodoNuevo;
}

export interface EstadosPresentismo {
  label: string;
  descripcion: string;
  color: IndicatorColor;
  idEstadoPresente: number;
  justificacion?: Justificacion | null;
  jornadaExtendida?: boolean;
}

export interface PresentismoTableProps {
  currentWeek: moment.Moment;
  pastDaysOfWeek: number;
  serverDate: string;
  rows: AlumnoMap[];
  working?: boolean;
  tipoJornada: Jornada | null;
  justification: (row: AlumnoMap) => void;
  studentReport: (row: AlumnoMap) => void;
  isTabJornadaExtendida: boolean;
  reFetchAll: () => void;
  selectedSection: string;
  direction: 'asc' | 'desc';
  setDirection: React.Dispatch<React.SetStateAction<'asc' | 'desc'>>;
  setPastDaysOfWeek: React.Dispatch<React.SetStateAction<number>>;
  selectedEspacioCurricular?: string;
  presentes: Presente_v3[];
  prestesTrimestral?: PrestesTrimestral[];
  workingPresentismoTrimestral?: boolean;
  diasHabilitados: DiasHabilitados[];
  diasHabilesWorking: Boolean;
  reFetchDiasHabiles: () => void;
}

export interface StateChange extends EstadosPresentismo {
  fecha: string;
}

export interface AsistenciaCellProps {
  alumno: AlumnoMap;
  currentWeek: moment.Moment;
  dayOfWeek: string;
  idDayofWeek: number;
  turno?: _Turno;
  state?: Presente_v3;
  estadoPresente?: StateChange;
  lastRow: boolean;
  handleSelectedOption?: (item: EstadosPresentismo) => void;
  isTabJornadaExtendida: boolean;
  selectedSection: string;
  pastDaysOfWeek: number;
  contraturno?: ContraturnoDia;
  modalRef: React.MutableRefObject<undefined>;
  anchorEl: Element | ((element: Element) => Element) | null;
  handleModalClosed: () => void;
  handleModelOpen: React.MouseEventHandler<HTMLDivElement>;
  disabledByOrder: boolean;
  countcell: number;
  isDayFuture: boolean;
  dataCierrePresentismo: Cierre[];
  selectedEspacioCurricular?: string;
  isDisabled: boolean;
  isLockBlock: boolean;
  waitingCandados: boolean;
  isValidCicloLectivo: boolean;
}

export interface AsistenciaCellComponentProps {
  row: AlumnoMap;
  currentWeek: moment.Moment;
  day: string;
  dayId: number;
  lastRow: boolean;
  isTabJornadaExtendida: boolean;
  selectedSection: string;
  pastDaysOfWeek: number;
  isDayFuture: boolean;
  setAmountCells: React.Dispatch<React.SetStateAction<number>>;
  dataCierrePresentismo: Cierre[];
  selectedEspacioCurricular?: string;
  isDisabled: boolean;
  isLockBlock: boolean;
  waitingCandados: boolean;
  isValidCicloLectivo: boolean;
}

export interface AvisosCellProps {
  avisosCount: AlertasType;
  defaultIcon?: React.ReactNode;
  onClick?: () => void;
}

export type DiaCellState = 'closed' | 'current' | 'open';

export interface JustificacionCellProps {
  row: AlumnoMap;
  isTabJornadaExtendida: boolean;
  onClick: (value: AlumnoMap) => void;
  pastDaysOfWeek: number;
}

export interface StudentReportCellProps {
  row: AlumnoMap;
  onClick: (value: AlumnoMap) => void;
  prestesTrimestral?: PrestesTrimestral[];
  workingPresentismoTrimestral?: boolean;
}

export interface PrestesTrimestral {
  id_alumno: string;
  existe_resultado: boolean;
}
export interface LockCellProps {
  locked: boolean;
  first?: boolean;
  last?: boolean;
  colSpan?: number;
  align?: 'left' | 'center' | 'right';
  isSelected: boolean;
  handleClose?: () => void;
  isDayFuture: boolean;
  day: string;
  dataCierrePresentismo: Cierre[];
  seccion: string | null;
  reFetchCierre: () => void;
  presentComplete: { day: string; fullPresenteismo: boolean }[];
  alumnos: AlumnoMap[];
  presentes: Presente_v3[];
  selectedEspacioCurricular?: string;
  isDisabled: boolean;
  isLockBlock: boolean;
  tieneDiasHabilitados: boolean;
  isTabJornadaExtendida: boolean;
  setWaitingCandados: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ObservacionesCellProps {
  alumno: AlumnoMap;
  currentWeek: moment.Moment;
  pastDaysOfWeek: number;
  reFetchAll: () => void;
  isTabJornadaExtendida: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  dataCierrePresentismo: Cierre[];
  selectedEspacioCurricular?: string;
}

export type MOTIVOS_DISABLED =
  | 'FUTURE_DATE'
  | 'DISABLE_DAY'
  | 'FERIADO'
  | 'LOWER_DEAD_LINE'
  | 'UNAUTHORIZED_ROLE'
  | 'EMERGENCY'
  | 'SUSPENSION_CLASSES'
  | 'PROPUESTA_JE'
  | 'DISABLED_DAY'
  | 'REGIMEN_ESPECIAL'
  | 'ESTUDIANTE_FEDERADO'
  | 'BLOQUEO_CANDADO'
  | 'SOLICITUD_PASE'
  | 'CAMBIO_DE_SECCION'
  | 'CANDADO_BLOQUEADO'
  | 'RECESO_INVERNAL'
  | 'FECHA_INVALIDA'
  | 'ENROLLED';

export interface MotivoMessage {
  text: string;
  detail: string;
}

export interface TooltipMotivoDisabledProps {
  motivos?: MOTIVOS_DISABLED[];
}

export interface _Turno {
  title: string;
  idTurno: number;
  colSpan: number;
}
export interface SeccionTurno {
  [k: number]: _Turno[];
}

export enum SECCION_TURNO {
  M = 1,
  T = 2,
  N = 3,
  V = 4,
  C = 5,
}

export interface TurnosCellProps {
  state: 'closed' | 'current' | 'open';
  tipoJornada: Jornada | null;
  last: boolean;
  isTabJornadaExtendida: boolean;
  isSelected: boolean;
  day: string;
  contraturnos: ContraturnoDia[];
  allAlumnos: AlumnoMap[];
  selectedSection: string;
  situaciones: SituacionV3[];
  isDayFuture: boolean;
  selectedEspacioCurricular?: string;
  dataCierrePresentismo: Cierre[];
  isDisabled: boolean;
  isLockBlock: boolean;
  tieneDiasHabilitados: boolean;
  isValidCicloLectivo: boolean;
}

export interface AlertaActiva {
  active: boolean;
  countAusencias?: number;
  countAusenciaPresenciaEnClase?: number;
  countIngresoTardio?: number;
  countRetiroAnticipado?: number;
  total: number;
}

export interface AlertaMensaje {
  alerta: AlertaActiva;
  texto: string;
}

export interface fechasFilterPresente {
  path: string;
  value: string;
}

export interface TurnoIdToAbbrMap {
  [key: number]: string | string[];
}

export interface DayColumn {
  column: string;
  subColumns: (string | string[])[];
}

export type DiaSemana = 'Lunes' | 'Martes' | 'Miércoles' | 'Jueves' | 'Viernes';

export interface excelList {
  state?: StateChange;
  turno?: Turno;
  contraturnoDia?: ContraturnoDia;
}
