import { useCallback, useMemo } from 'react';
import { useGetColumns } from 'src/screens/Private/Calificaciones/hooks/useGetColumns';
import { primary } from 'src/theme/colors';
import { camelize } from 'src/utils';

const headerStyle = {
  color: primary.lightBlue,
  textAlign: 'center',
};

export const useAreaDeConocimientoTableColumns = (
  idEcs: string,
  periodo?: number,
) => {
  const { columns, working } = useGetColumns(idEcs, periodo);

  const makeColumns = useCallback(() => {
    const newColumns = columns.map((column: string) => ({
      id: camelize(column),
      label: column,
      width: '15%',
      hideSortIcon: true,
      style: headerStyle,
    }));
    return newColumns;
  }, [columns]);

  return useMemo(
    () => ({
      working,
      columns: [
        {
          id: 'nombre',
          label: 'Alumno',
          width: '20%',
          hideSortIcon: true,
          style: headerStyle,
        },
        {
          id: 'ppi',
          label: 'PPI',
          width: '5%',
          hideSortIcon: true,
          style: headerStyle,
        },
        ...makeColumns(),
        {
          id: 'calificacionGeneral',
          label: 'Calificación General.',
          width: '15%',
          hideSortIcon: true,
          style: headerStyle,
        },
      ],
    }),
    [makeColumns, working],
  );
};
