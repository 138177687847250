import React from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import { BibliografiaPlan } from 'src/app/models';
import { grey } from 'src/theme/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    maxWidth: 752,
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },
  title: {
    margin: theme.spacing(4, 0, 2),
  },
  text: {
    fontSize: 16,
    color: grey.textPrimary,
  },
}));
export interface BibliografiaProps {
  data: BibliografiaPlan;
}

export const Bibliografia = ({ data }: BibliografiaProps) => {
  const classes = useStyles();

  return (
    <Grid container direction="column">
      {data?.bibliografia?.map((item: string, index: number) => (
        <Grid key={index}>
          <Typography className={classes.text}>{item}</Typography>
        </Grid>
      ))}
    </Grid>
  );
};
