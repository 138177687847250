import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  modalContainer: {
    padding: '3em',
  },
  flex: {
    display: 'flex',
  },
  majorFlex: {
    flex: 1,
  },
  btnSubmitContainer: {
    padding: '10px',
  },
}));
