import React, { useEffect, useMemo } from 'react';
import { AnyObject, RAZON_JUSTIFICACION } from '../../../../../commons';
import {
  useFormContext,
  useTemplateContext,
} from '../../../../../lib/templates';
import { JustificacionInitialValue } from '../template/initialValue';
import { JustificacionInitialValues } from '../types';

export const useJustificacionLogic = () => {
  const { form, values, errors } = useFormContext<AnyObject>();
  const { setTemplate } = useTemplateContext();

  const valuesForm: JustificacionInitialValues = useMemo(
    () => ({ ...JustificacionInitialValue, ...values }),
    [values],
  );

  useEffect(() => {
    if (
      valuesForm.razonJustificacion === RAZON_JUSTIFICACION.AUSENTE.toString()
    ) {
      setTemplate((template) => {
        const [temp] = template.template.filter(
          (property) => property.name === 'horaSalida',
        );
        const filtered = template.template.filter(
          (property) => property.name !== 'horaSalida',
        );
        return {
          ...template,
          template: [...filtered, { ...temp, optional: true }],
        };
      });
    } else {
      setTemplate((template) => {
        const [temp] = template.template.filter(
          (property) => property.name === 'horaSalida',
        );
        const filtered = template.template.filter(
          (property) => property.name !== 'horaSalida',
        );
        return {
          ...template,
          template: [...filtered, { ...temp, optional: false }],
        };
      });
    }
  }, [setTemplate, valuesForm, form]);
};
