import React, { useCallback, useMemo, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Footer, InfoTable, useBackButton } from 'src/commons';
import { useQueHaceresGeneralRows, useQueHaceresTableColumns } from './hooks';
import {
  useCalificacionesSubmit,
  useValidator,
  useExcelData,
  validateLeave,
  useCalificacionesSwitch,
} from '../../../hooks';
import { useCalificacionesContext } from '../../../context';
import { CalificacionesFiltersHeader } from '../../../components/CalificacionesFiltersHeader';
import { AlertMessage } from '../../../components';
import { useQuehaceresDAO } from 'src/app/business';
import { useHistory } from 'react-router';
import { useRolesContext } from 'src/context/RolesContext';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useColegioEfectivizadoContext } from '../../../context/EfectivizacionProvider';
import { useRoleCanOpenGrade } from '../../../hooks/useRoleCanOpenGrade';

export type objTable = {
  id: string;
  nombre: string;
  tipo: string;
  seccion: string;
  bimestre: string;
};

const useStyles = makeStyles({
  table: {
    maxHeight: '600px',
    marginBottom: '80px',
  },
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

const fieldsToValidate = [
  'organizaTareas',
  'expresaIdeas',
  'asumeResponsabilidad',
  'realizaTareas',
  'manifiestaInteres',
  'manifiestaInteres',
  'logrosDificultades',
];

export const QueHaceresEstudiante = ({
  anioFilter,
}: {
  anioFilter: {
    anio: {
      path: string;
      value: unknown;
    }[];
  };
}) => {
  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();
  const classes = useStyles();
  const history = useHistory();
  const {
    selectedRole: { localizacionId },
  } = useRolesContext();
  const queHaceresDAO = useQuehaceresDAO();
  const { filter, setFilter } = useCalificacionesContext();

  const columns = useQueHaceresTableColumns();
  const {
    rows,
    working,
    _rows,
    data,
    reFetch,
    isClosed,
    readyToClose,
    hasCalifications,
  } = useQueHaceresGeneralRows(filter);
  const { incompletedRows, isDisabled, submitRows } = useValidator({
    fieldsToValidate: fieldsToValidate,
    _rows,
    filter,
    data,
  });

  const showView = useMemo(
    () => filter.anio && filter.seccion && filter.periodo,
    [filter],
  );

  const [anioSelect, setAnioSelect] = useState('');
  const [seccionSelect, setSeccionSelect] = useState('');
  const [periodoSelect, setPeriodoSelect] = useState('');

  const { excelData } = useExcelData(
    columns,
    _rows,
    `Calificaciones - Quehaceres - ${periodoSelect} - ${seccionSelect} - ${localizacionId}`,
    periodoSelect,
    anioSelect,
    seccionSelect,
    'Quehaceres de/la Estudiante',
  );

  const { onSubmit, submitting } = useCalificacionesSubmit({
    columns,
    _rows,
    data,
    filter,
    dao: queHaceresDAO,
    reFetch,
  });

  const { onSwitch, switchSubmitting } = useCalificacionesSwitch({
    _rows,
    filter,
    dao: queHaceresDAO,
    reFetch,
    asunto: 'Quehaceres',
  });

  const handleLeave = useCallback(
    async (route?: string) => {
      if (!establecimientoEfectivizado) {
        history.push(typeof route === 'string' ? route : '/');
        return;
      }
      const confirm = await confirmDialog.show({
        title: '¿Desea continuar?',
        content:
          'La información precargada se perderá en caso que no guarde, por favor seleccione una opción.',
        confirmText: 'Salir sin guardar',
        cancelText: 'Seguir en esta pantalla',
      });
      if (confirm) {
        try {
          history.push(route ?? '/');
        } catch (e) {
          console.log(e);
        }
      }
    },
    [history, establecimientoEfectivizado],
  );

  const confirmarSalirConBackButton = useCallback(() => {
    if (!(isDisabled || isClosed || submitRows)) {
      handleLeave('/private/calificaciones');
    } else {
      history.push('/private/calificaciones');
    }
  }, [handleLeave, history, isClosed, isDisabled, submitRows]);

  useBackButton('/calificaciones', 0, confirmarSalirConBackButton);

  const canOpenGrade = useRoleCanOpenGrade(['1', '5']);

  const buttonConfig: any = useMemo(() => {
    const defaultButtons: any[] = [
      {
        title: 'Salir',
        size: 'medium',
        type: 'secondary',
        handleOnClick: handleLeave,
      },
      {
        title: 'Guardar',
        size: 'medium',
        handleOnClick: onSubmit,
        disabled:
          isDisabled || isClosed || submitRows || !establecimientoEfectivizado,
      },
    ];

    if (!isClosed) {
      defaultButtons.push({
        title: 'Cerrar Calificaciones',
        size: 'medium',
        handleOnClick: () => onSwitch('close'),
        disabled:
          isDisabled ||
          !readyToClose ||
          isClosed ||
          !hasCalifications ||
          !establecimientoEfectivizado,
      });
    } else if (canOpenGrade) {
      defaultButtons.push({
        title: 'Abrir Calificaciones',
        size: 'medium',
        handleOnClick: () => onSwitch('open'),
        disabled: !establecimientoEfectivizado,
      });
    }
    return defaultButtons;
  }, [
    establecimientoEfectivizado,
    handleLeave,
    onSubmit,
    isDisabled,
    isClosed,
    submitRows,
    canOpenGrade,
    readyToClose,
    onSwitch,
    hasCalifications,
  ]);

  const handleAnioChange = (options: any) => {
    const anio = options?.find((s: any) => s.idAnio === filter.anio);
    setAnioSelect(anio?.descripcionAnio);
  };

  const handleSeccionesChange = (options: any) => {
    const seccion = options?.find((s: any) => s.idSeccion === filter.seccion);
    setSeccionSelect(seccion?.nombreSeccion);
  };

  const handlePeriodosChange = (options: any) => {
    const periodo = options?.find((p: any) => p.idPeriodo === filter.periodo);
    setPeriodoSelect(periodo?.tipoPeriodo.descripcionTipoPeriodo);
  };

  return (
    <>
      <AlertMessage
        incompletedRows={incompletedRows}
        isDisabled={isDisabled}
        isClosed={isClosed}
        working={working}
        showView={showView}
        establecimientoEfectivizado={establecimientoEfectivizado}
      />
      <CalificacionesFiltersHeader
        filter={filter}
        setFilter={setFilter}
        excelData={excelData}
        handleSeccionesChange={handleSeccionesChange}
        handlePeriodosChange={handlePeriodosChange}
        handleAnioChange={handleAnioChange}
        showView={showView}
        incompletedRows={incompletedRows}
        readyToClose={readyToClose}
        anioCustomFilter={anioFilter}
      />
      {showView ? (
        <>
          <InfoTable
            rows={rows}
            columns={columns}
            customStyle={classes.table}
            working={working}
          />
          <Grid className={classes.footer}>
            <Footer
              buttonConfig={buttonConfig}
              loading={submitting || switchSubmitting}
            />
          </Grid>
        </>
      ) : (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: '400px' }}
        >
          <Typography
            style={{
              fontSize: '40px',
              marginTop: '50px',
              color: 'gray',
              fontWeight: 'bold',
              lineHeight: '45px',
            }}
          >
            Seleccione año, sección y período para calificar.
          </Typography>
        </Grid>
      )}
    </>
  );
};
