import React, { useCallback } from 'react';
import { ObservacionesModal, SelectWithField, SimpleSelect } from 'src/commons';
import { useMemo } from 'react';
import { TextField } from 'src/app/components/inputs/Input/TextField';
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import EditIcon from '@material-ui/icons/Edit';
import customFormDialog from 'src/commons/services/customFormDialog';
import { primary } from 'src/theme/colors';
import {
  Alumno,
  CicloLectivo,
  EstadoAlumno,
  Seccion,
  Pps,
} from 'src/app/models';
import { usePPSFormContext } from '../PPSFormProvider';
import { TextFieldFF } from 'src/commons/components/FinalForm/TextField';
import { FormApi } from 'final-form';

const useStyles = makeStyles(() => ({
  iconObservacion: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'end',
    color: primary.lightBlue,
    marginLeft: '20px',
  },
  observaciones: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '200px',
    marginTop: '10px',
    textAlign: 'right',
    color: 'black',
    paddingBottom: 10,
  },
}));

interface formValuesType {
  acompaña: string;
  participa: string;
  adulto: string;
  observaciones: string;
}

interface informePpsType {
  row: {
    idAlumnoMovimiento: string;
    updateAt: string;
    updatedBy: string;
    deletedAt: Date | null;
    alumno: Alumno;
    cicloLectivo: CicloLectivo;
    estadoAlumno: EstadoAlumno;
    seccion: Seccion;
    deletedBy: string | number | null;
  };
  button: string;
}

export const useVinculoFamiliaEscuelaColumns = (ppsDataAlumno: Pps) => {
  const classes = useStyles();
  const { form } = usePPSFormContext();
  const values = (form as FormApi).getState().values;
  const handleObservaciones: () => void = useCallback(() => {
    customFormDialog.show({
      title: 'Observaciones',
      renderComponent: (
        <ObservacionesModal
          content={values?.vinculo?.observaciones}
          onAddContent={(content: string) =>
            (form as FormApi).change('vinculo.observaciones', content)
          }
          value={values?.vinculo?.observaciones}
        />
      ),
      sizeWidth: 'sm',
    });
  }, [form, values?.vinculo?.observaciones]);

  const columns = useMemo(() => {
    const genericOptions = [
      { label: 'Siempre' },
      { label: 'Frecuentemente' },
      { label: 'Con poca frecuencia' },
    ];

    const isOpen = ppsDataAlumno !== undefined ? ppsDataAlumno?.abierto : true;
    const isDisabled = !isOpen;

    return [
      {
        id: 'acompaña',
        label: '¿Acompaña la trayectoria escolar del alumno?',
        width: '5%',
        hideSortIcon: true,
        render: () => (
          <SelectWithField
            form={form}
            name="vinculo.acompaña"
            placeholder="Seleccioná una opción"
            labelKey="label"
            valueKey="label"
            content={genericOptions}
            disabled={isDisabled}
          />
        ),
      },
      {
        id: 'participa',
        label: '¿Participa de las actividades propuestas por la escuela?',
        hideSortIcon: true,
        width: '5%',
        render: () => (
          <SelectWithField
            form={form}
            name="vinculo.participa"
            placeholder="Seleccioná una opción"
            labelKey="label"
            valueKey="label"
            content={genericOptions}
            disabled={isDisabled}
          />
        ),
      },
      {
        id: 'adulto',
        label: '¿Quién es el adulto referente ante la escuela?',
        width: '5%',
        hideSortIcon: true,
        render: () => (
          <TextFieldFF
            name="vinculo.adulto"
            form={form}
            disabled={isDisabled}
          />
        ),
      },
      {
        id: 'observaciones',
        label: 'Observaciones',
        width: '5%',
        hideSortIcon: true,
        render: () => (
          <Grid>
            {values?.vinculo?.observaciones ? (
              <>
                <Grid className={classes.iconObservacion}>
                  <div className={classes.observaciones}>
                    {values?.vinculo?.observaciones}
                  </div>
                  <IconButton
                    className={classes.iconObservacion}
                    disabled={isDisabled}
                    onClick={() => handleObservaciones()}
                  >
                    {<LibraryBooksIcon />}
                  </IconButton>
                </Grid>
              </>
            ) : (
              <Grid className={classes.iconObservacion}>
                <IconButton
                  disabled={isDisabled}
                  onClick={() => handleObservaciones()}
                >
                  {<EditIcon style={{ color: primary.lightBlue }} />}
                </IconButton>
              </Grid>
            )}
          </Grid>
        ),
      },
    ];
  }, [
    classes.iconObservacion,
    classes.observaciones,
    form,
    handleObservaciones,
    ppsDataAlumno,
    values?.vinculo?.observaciones,
  ]);

  return columns;
};
