import React, { useMemo, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { Footer, ButtonTypesProps } from 'src/commons';
import { useFormContext, useTemplateContext } from 'src/lib/templates';
import { useRolesContext } from 'src/context/RolesContext';
import { docenteRoles } from '../template';
import { useProfileContext } from 'src/lib/profiles/ProfileContext';

interface FormFooterProps {
  activeStep: number;
  setActiveStep: (next: number) => void;
  persona: boolean;
  maxSteps: number;
}

export const FormFooter = ({
  activeStep,
  setActiveStep,
  persona,
  maxSteps,
}: FormFooterProps) => {
  const { handleSubmit, errors, values, form } = useFormContext();
  const { setTemplate } = useTemplateContext();
  const { selectedRole } = useRolesContext();

  useEffect(() => {
    if (+selectedRole.rol === 2) {
      setTemplate((oldTemplate) => {
        const newTemplate = oldTemplate.template.filter(
          (item) => item.name !== 'roles',
        );
        newTemplate.push(docenteRoles);
        return {
          ...oldTemplate,
          template: newTemplate,
        };
      });
      form.change('idLocalizacion', +selectedRole.localizacionId);
      form.change('idRolUsuario', +selectedRole.rol);
      form.change('idNivel', +selectedRole.nivel);
      form.change('isFuture', !!selectedRole.isFuture);
      form.change('isHospitalary', false);
      setActiveStep(3);
    }
  }, [selectedRole, setActiveStep, form, setTemplate]);

  const stepValidation: Record<string, any> = useMemo(() => {
    return persona
      ? {
          0: ['idLocalizacion'],
          1: ['idNivel'],
          2: ['idRolUsuario'],
          3: ['roles'],
        }
      : {
          0: ['nombre', 'apellido', 'idTipoDocumento', 'documento', 'cuil'],
          1: ['idLocalizacion'],
          2: ['idNivel'],
          3: ['idRolUsuario'],
          4: ['roles'],
        };
  }, [persona]);

  const checkDisabled = useMemo(
    () =>
      stepValidation[activeStep].reduce(
        (acum: boolean, actual: string) => actual in errors || acum,
        false,
      ),
    [activeStep, errors, stepValidation],
  );

  const checkSubmit = useMemo(
    () =>
      activeStep === maxSteps ||
      (activeStep === maxSteps - 1 &&
        !['2', '3', '13', '17'].includes(values.idRolUsuario)),
    [values.idRolUsuario, activeStep, maxSteps],
  );

  const { setProfile } = useProfileContext();

  const buttonConfig: ButtonTypesProps[] = [
    {
      title: 'Salir',
      typeButton: 'button',
      handleOnClick: () => {
        setProfile('sinEstablecimiento');
      },
    },
    ...(activeStep !== 0
      ? [
          {
            title: 'Atrás',
            type: 'secondary',
            handleOnClick: () => {
              activeStep > 0 &&
                setActiveStep(
                  values.isHospitalary ? activeStep - 2 : activeStep - 1,
                );
            },
          },
        ]
      : []),
    {
      title: 'Continuar',
      typeButton: checkSubmit ? 'submit' : 'button',
      disabled: checkDisabled,
      handleOnClick: () => {
        checkSubmit
          ? handleSubmit(values)
          : setActiveStep(
              values.isHospitalary ? activeStep + 2 : activeStep + 1,
            );
      },
    },
  ];

  return (
    <Grid container style={{ position: 'fixed', bottom: 0, left: 0 }}>
      <Footer buttonConfig={buttonConfig} loading={false} />
    </Grid>
  );
};
