import { PpsDAO } from 'src/platform/libby/dao';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Pps } from '../models/Pps';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'pps';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPps',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idPps',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Pps
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Pps,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Pps.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Pps, PpsDAO>(DAO_NAME, optionsToUse);

// hooks
export const usePpsFetchById = fetchById;
export const usePpsLibbyCall = libbyCall;
export const usePpsDAO = libbyDAO;
export const usePpsLibbyFetch = libbyFetch;

// components/hoc
export const PpsAutocomplete = entityAutocomplete;
export const PpsCall = renderPropCall;
export const PpsById = renderPropFetchById;
export const PpsSelect = simpleSelect;
export const PpsAccordionSelector = accordionSelector;
export const PpsSelectedChips = selectedChips;

// context
// ByIdContext
export const usePpsByIdContext = useByIdContext;
export const PpsByIdProvider = ByIdProvider;
export const PpsByIdContext = ByIdContext;
// GeneralContext
export const usePpsContext = useGeneralContext;
export const PpsProvider = GeneralProvider;
export const PpsContext = GeneralContext;
