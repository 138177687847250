import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { GruposDocentes } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'grupos_docentes';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idGruposDocentes',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idGruposDocentes',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.GruposDocentes
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.GruposDocentes,
      hoc: { ...defaultOptions.hoc, ...businessOptions.GruposDocentes.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<GruposDocentes>(DAO_NAME, optionsToUse);

// hooks
export const useGruposDocentesFetchById = fetchById;
export const useGruposDocentesLibbyCall = libbyCall;
export const useGruposDocentesDAO = libbyDAO;
export const useGruposDocentesLibbyFetch = libbyFetch;

// components/hoc
export const GruposDocentesAutocomplete = entityAutocomplete;
export const GruposDocentesCall = renderPropCall;
export const GruposDocentesById = renderPropFetchById;
export const GruposDocentesSelect = simpleSelect;
export const GruposDocentesAccordionSelector = accordionSelector;
export const GruposDocentesSelectedChips = selectedChips;

// context
// ByIdContext
export const useGruposDocentesByIdContext = useByIdContext;
export const GruposDocentesByIdProvider = ByIdProvider;
export const GruposDocentesByIdContext = ByIdContext;
// GeneralContext
export const useGruposDocentesContext = useGeneralContext;
export const GruposDocentesProvider = GeneralProvider;
export const GruposDocentesContext = GeneralContext;
