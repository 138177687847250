import { LibbyFetchDAO } from './LibbyFetchDAO';

export class CycleDAO extends LibbyFetchDAO {
  constructor() {
    super('ciclo', 'idCiclo');
  }

  getAll() {
    return this.query().orderBy('idCiclo').limit(0, 10000).run();
  }

  getBCCycle() {
    return this.query()
      .groupStart()
      .equals('idCiclo', 1)
      .or()
      .equals('idCiclo', 2)
      .groupEnd()
      .orderBy('idCiclo')
      .limit(0, 10000)
      .run();
  }
}
