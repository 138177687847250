import { MenuItem } from '../../../../commons/const/ui/menu';
import { routes } from './routes';

export const createc: MenuItem = {
  key: 'createc',
  basePath: '/createc',
  enabled: true,
  icon: 'List',
  text: 'Createc',
  children: routes,
};
