import { Box, Grid, Typography } from '@material-ui/core';
import React, { useCallback, useMemo, useEffect } from 'react';
import { useEvaluacionFormContext } from '../../../hooks/useEvaluacionFormContext';
import { SliderAccordion } from '../../sliderGroup';
import { FormInputV2 } from '../../../../../../lib/templates';
import { useEscuelaContext } from '../../../context/EscuelaContext';
import { IHabilidadEvaluacion, SliderAccordionValues } from '../../../types';
import { ROL, useTabBarValue } from '../../../../../../commons';
import { useRolesContext } from 'src/context/RolesContext';

export const HabilidadesYCompetencias = () => {
  const { form, values } = useEvaluacionFormContext();
  const { alumnoSelected, setTab } = useEscuelaContext();
  const { tabIndex } = useTabBarValue();
  const {
    selectedRole: { rol },
  } = useRolesContext();

  const unauthorizedUser = [
    ROL.REFERENTE_ACAP,
    ROL.DIRECTIVO,
    ROL.EQUIPO_ACAP,
  ].includes(Number(rol));

  useEffect(() => {
    setTab(tabIndex);
  }, [tabIndex, setTab]);

  const hanleSliderChange = useCallback<
    (item: SliderAccordionValues, value: number) => void
  >(
    (item, value) => {
      const newValors = values.habilidades.valores.map((valor) => {
        let _newValue = valor;
        if (valor.idTipo.toString() === item.id.toString()) {
          _newValue.evaluacion = value;
        }
        return _newValue;
      });
      // @ts-ignore
      form.change('habilidades.valores', newValors);
    },
    [form, values.habilidades.valores],
  );

  const conenidosMapped = useMemo<SliderAccordionValues[]>(() => {
    if (!alumnoSelected) return [];

    const _habilidades: IHabilidadEvaluacion[] = [];
    alumnoSelected.habilidades[0]?.valores.forEach(
      ({ habilidadCategoria, devolucion, ...habilidad }) => {
        let categoriaExiste = _habilidades.find((item) => {
          return (
            item.idHabilidadCategoria ===
            habilidadCategoria.idHabilidadCategoria
          );
        });

        if (!categoriaExiste) {
          categoriaExiste = {
            ...habilidadCategoria,
            habilidades: [],
            devolucion,
          };
          _habilidades.push(categoriaExiste);
        }

        let habilidadExiste = categoriaExiste.habilidades.find((item) => {
          return item.idHabilidad === habilidad.idHabilidad;
        });

        if (!habilidadExiste) {
          habilidadExiste = habilidad;
          categoriaExiste.habilidades.push(habilidadExiste);
        }
      },
    );

    const _result = _habilidades.map<SliderAccordionValues>((habilidad) => {
      const valor = values.habilidades.valores.find(
        (v) => v.idTipo.toString() === habilidad.devolucion.idTipo.toString(),
      );
      return {
        id: habilidad.idHabilidadCategoria,
        descripcion: habilidad.nombre,
        contenidos: habilidad.habilidades.map((_h) => ({
          id: _h.idHabilidad,
          descripcion: _h.descripcion,
        })),
        valor: valor?.evaluacion || 0,
      };
    });

    return _result;
  }, [values, alumnoSelected]);

  return (
    <Grid container component={Box} paddingY={2}>
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={6} container justifyContent="center">
          <Grid item component={Box} paddingX={3}>
            <SliderAccordion
              sliders={conenidosMapped}
              onChange={hanleSliderChange}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} container component={Box} marginTop={4}>
        <Grid item xs={12} sm={2} md={2} lg={1}>
          <Typography
            style={{
              fontWeight: 600,
            }}
          >
            Observaciones:
          </Typography>
        </Grid>

        <Grid item container xs={12} sm={10} md={10} lg={11}>
          <Grid item xs={12} style={{ marginBottom: '10px' }}>
            <FormInputV2
              form={form}
              name={'habilidades.observaciones'}
              variant="outlined"
              multiline
              maxRows={4}
              disabled={unauthorizedUser}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
