import { useEffect, useState } from 'react';
import { useProyeccionEstadoDAO } from 'src/app/business';
import { CicloLectivo, ProyeccionEstado } from 'src/app/models';

export const useIsLocalizacionEfectivizada = (
  localizacionId: string,
  nivel: string,
) => {
  const proyeccionEstadoDAO = useProyeccionEstadoDAO();

  const [waiting, setWaiting] = useState(false);

  const [efectivizado, setEfectivizado] = useState<string | boolean>(false);
  const [proyeccionEstado, setProyeccionEstado] =
    useState<null | ProyeccionEstado>(null);
  useEffect(() => {
    (async () => {
      if (!Boolean(localizacionId)) return;
      setWaiting(true);
      const res = await proyeccionEstadoDAO.isEfectivizado(
        nivel,
        localizacionId,
      );

      setEfectivizado(res.establecimientoEfectivizado);
      setProyeccionEstado(res.data);
      setWaiting(false);
    })();
  }, [localizacionId, nivel, proyeccionEstadoDAO]);

  return {
    establecimientoEfectivizado: efectivizado,
    cicloLectivo:
      proyeccionEstado === null ? null : proyeccionEstado?.cicloLectivo,
    proyeccionEstado,
    waiting,
  };
};
