import { useMemo } from 'react';
import { useGruposInscripcionesLibbyFetch } from 'src/app/business';
import { useRolesContext } from 'src/context/RolesContext';

export const useGetGruposEstudiante = () => {
  const {
    userInfo: { id },
  } = useRolesContext();
  const filter = useMemo(
    () => ({
      estudiante: [
        {
          path: 'alumnoMovimiento.alumno.persona.cuentas',
          value: id,
        },
      ],
    }),
    [id],
  );
  const { data = [], working } = useGruposInscripcionesLibbyFetch({
    aspect: 'tab-actividades',
    filter,
    limit: 100,
  });

  return { data, working };
};
