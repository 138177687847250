import React, { useContext, createContext, FC, useMemo } from 'react';
import { useGetValidPresentismosByWeek } from 'src/app/business/businessCustomEndpoints/presentismov3/cerrarsemana';
import { weekDays } from '../components/PresentismoTable/functions/cerrarSemana';
import { Presentismo } from 'types/presentismo';

export const PresentismosValidosContext =
  createContext<Presentismo.ValidPresentismoResponse>({
    data: {
      alumnosDays: [],
      days: [],
    },
    working: false,
  });

export const usePresentismosValidosContext = () =>
  useContext(PresentismosValidosContext);

export const PresentismosValidosProvider: FC<
  Presentismo.PresentismoValidosContextProps
> = ({ children, currentWeek, seccion, id }) => {
  const {
    data: validPresentismos = {
      days: [],
      alumnosDays: [],
    },
    working,
  } = useGetValidPresentismosByWeek<{
    alumnosDays: {
      isValid: boolean;
      idAlumno: string;
      validDays: boolean[];
    }[];
    days: {
      [k: string]: boolean;
    }[];
  }>({
    url: `/api/presentismov3/presentismos_validos?week=${[
      currentWeek.startOf('isoWeek').format('YYYY-MM-DD'),
      currentWeek
        .startOf('isoWeek')
        .add(weekDays.length - 1, 'days')
        .format('YYYY-MM-DD'),
    ].join(',')}&seccion=${seccion}&ec=${id}`,
    autoCall: Boolean(seccion) && Boolean(currentWeek),
  });

  const value = useMemo(
    () => ({
      data: validPresentismos,
      working,
    }),
    [working, validPresentismos],
  );

  return (
    <PresentismosValidosContext.Provider value={value}>
      {children}
    </PresentismosValidosContext.Provider>
  );
};
