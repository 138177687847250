import {
  PartialBusinessEntityOptions,
  makeBusinessEntity,
} from 'src/lib/libby';
import { businessOptions } from '../businessOptions';
import { Permission } from 'src/app/models/security/Permission';

const DAO_NAME = 'permission';

const defaultOptions: PartialBusinessEntityOptions<any> = {};

const optionsToUse = !businessOptions.Permisos
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Permisos,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Permisos.hoc },
    };

const {
  hoc: { simpleSelect, entityAutocomplete },
} = makeBusinessEntity<Permission>(DAO_NAME, optionsToUse);

export const PermissionAutoComplete = entityAutocomplete;
export const PermissionSimpleSelect = simpleSelect;
