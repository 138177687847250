import { EspacioCurricularDAO } from 'src/platform/libby/dao';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { EspacioCurricular } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'espacio_curricular';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEspacioCurricular',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEspacioCurricular',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EspacioCurricular
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EspacioCurricular,
      hoc: { ...defaultOptions.hoc, ...businessOptions.EspacioCurricular.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectInput,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EspacioCurricular, EspacioCurricularDAO>(
  DAO_NAME,
  optionsToUse,
);

// hooks
export const useEspacioCurricularFetchById = fetchById;
export const useEspacioCurricularLibbyCall = libbyCall;
export const useEspacioCurricularDAO = libbyDAO;
export const useEspacioCurricularLibbyFetch = libbyFetch;

// components/hoc
export const EspacioCurricularAutocomplete = entityAutocomplete;
export const EspacioCurricularCall = renderPropCall;
export const EspacioCurricularById = renderPropFetchById;
export const EspacioCurricularSelect = simpleSelect;
export const EspacioCurricularSelectInput = selectInput;
export const EspacioCurricularAccordionSelector = accordionSelector;
export const EspacioCurricularSelectedChips = selectedChips;

// context
// ByIdContext
export const useEspacioCurricularByIdContext = useByIdContext;
export const EspacioCurricularByIdProvider = ByIdProvider;
export const EspacioCurricularByIdContext = ByIdContext;
// GeneralContext
export const useEspacioCurricularContext = useGeneralContext;
export const EspacioCurricularProvider = GeneralProvider;
export const EspacioCurricularContext = GeneralContext;
