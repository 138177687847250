import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { SimpDiscipPlan } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'simp_discip_plan';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idSimpDiscipPlan',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idSimpDiscipPlan',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.SimpDiscipPlan
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.SimpDiscipPlan,
      hoc: { ...defaultOptions.hoc, ...businessOptions.SimpDiscipPlan.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<SimpDiscipPlan>(DAO_NAME, optionsToUse);

// hooks
export const useSimpDiscipPlanFetchById = fetchById;
export const useSimpDiscipPlanLibbyCall = libbyCall;
export const useSimpDiscipPlanDAO = libbyDAO;
export const useSimpDiscipPlanLibbyFetch = libbyFetch;

// components/hoc
export const SimpDiscipPlanAutocomplete = entityAutocomplete;
export const SimpDiscipPlanCall = renderPropCall;
export const SimpDiscipPlanById = renderPropFetchById;
export const SimpDiscipPlanSelect = simpleSelect;
export const SimpDiscipPlanAccordionSelector = accordionSelector;
export const SimpDiscipPlanSelectedChips = selectedChips;

// context
// ByIdContext
export const useSimpDiscipPlanByIdContext = useByIdContext;
export const SimpDiscipPlanByIdProvider = ByIdProvider;
export const SimpDiscipPlanByIdContext = ByIdContext;
// GeneralContext
export const useSimpDiscipPlanContext = useGeneralContext;
export const SimpDiscipPlanProvider = GeneralProvider;
export const SimpDiscipPlanContext = GeneralContext;
