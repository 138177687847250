/* eslint-disable react/no-array-index-key */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import {
  FormMultiCheckboxInput,
  FormMultiCheckbox,
} from '../../../../../../../../../lib/templates/components';
import { grey } from '../../../../../../../../../theme/colors';
import { usePlanificationForm } from '../../../commons/hooks';
import { useInterdisciplinarContext } from '../../../commons/components/Context/InterdisciplinarContext';
import { CheckboxListItem } from '../../../commons/components/CheckboxListItem';
import { useArealInterarealContext } from '../../context';
import { useIndicadoresFetch } from '../hooks';

const useStyles = makeStyles({
  buttonRoot: {
    fontSize: 16,
    padding: '9px 12px',
  },
  container: {
    padding: 20,
    marginTop: 15,
    marginBottom: 35,
    borderRadius: 5,
    backgroundColor: 'rgba(218, 218, 218, 0.17)',
  },
  input: {
    backgroundColor: 'white',
    alignItems: 'center',
    fontSize: 13,
  },
  label: {
    color: grey.disabled,
    fontSize: 16,
  },
  title: {
    marginBottom: 8,
    fontSize: 13,
    fontWeight: 600,
    color: grey.medium,
  },
});

export const IndicadoresDeEvaluacion = () => {
  const classes = useStyles();
  const { form } = usePlanificationForm();
  const { indicadores } = useIndicadoresFetch();

  const buttonCustomStyle = { margin: '10px 0px 25px 0' };

  return (
    <Grid container direction="column" className={classes.container}>
      <FormMultiCheckbox
        name="indicadoresDeEvaluacion.indicadores"
        // @ts-ignore
        options={indicadores}
        form={form}
        idKey="idIndicadoresDeEvaluacion"
      />
      <FormMultiCheckboxInput
        label=""
        name="indicadoresDeEvaluacion.adicionales"
        form={form}
        addButtonText="Agregar Indicador"
        buttonCustomStyle={buttonCustomStyle}
        getNewElement={(value) => ({
          checked: false,
          descripcion: '',
          id: -1 - 1 * value.filter((item: any) => item.id < 0).length, // use negative id to validate new items
        })}
      >
        {(value, input, meta, index) => (
          <CheckboxListItem
            inputPlaceholder="Nuevo objetivo"
            value={value}
            input={input}
            meta={meta}
            index={index}
          />
        )}
      </FormMultiCheckboxInput>
    </Grid>
  );
};
