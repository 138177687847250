import { Grid, Tooltip } from '@material-ui/core';
import React, { useMemo } from 'react';
import { useDiasLibbyFetch } from 'src/app/business';
import { useSedesPresentismoLibbyFetch } from 'src/app/business/presentismo_v2/Sede';
import { useContraturnoDiaLibbyFetch } from 'src/app/business/presentismo_v3/ContraturnoDia';
import {
  CheckboxWithField,
  InfoTable,
  Loading,
  MainButton,
  SimpleSelectWithField,
} from 'src/commons/components';
import { AutocompleteWithField } from 'src/commons/components/FinalForm/Autocomplete';
import { usePropuestaJEFormContext } from '../../../context/configuration/PropuestaJEFormProvider';
import { submitDisabled } from '../../../functions';
import { useStyles } from '../../../styles/propuestaJE';
import { presentismo } from '../../../types';
import moment from 'moment';

export const DaysTable = ({ disabled, filters }: presentismo.UI.DaysTable) => {
  const { values, form } = usePropuestaJEFormContext();
  if (!values) throw new Error('La tabla no está dentro del context');

  const sabadoFilter = useMemo(
    () => ({
      0: [
        {
          path: 'idDia',
          value: 1,
        },
        {
          path: 'idDia',
          value: 2,
        },
        {
          path: 'idDia',
          value: 3,
        },
        {
          path: 'idDia',
          value: 4,
        },
        {
          path: 'idDia',
          value: 5,
        },
      ],
    }),
    [],
  );

  const filterContrautnroDia = useMemo(
    () => ({
      0: [
        {
          path: 'contraturno.seccionCustom.idSeccion',
          value: filters?.seccion,
        },
      ],
      1: [{ path: 'active', value: true }],
      2: [{ path: 'contraturno.active', value: true }],
    }),
    [filters?.seccion],
  );
  const { data: contraturnoDias = [] } = useContraturnoDiaLibbyFetch({
    limit: 1000,
    filter: filterContrautnroDia,
    aspect: 'ContraturnoDiaAsp',
  });

  const daysCounter: any = {};

  const filteredContraturnoDias = contraturnoDias.filter((elemento) => {
    const fechaInicio = moment(elemento.contraturno.fechaInicio);
    const fechaFin = moment(elemento.contraturno.fechaFin);
    const fechaHoy = moment();

    return fechaHoy.isBetween(fechaInicio, fechaFin, null, '[]');
  });

  filteredContraturnoDias.forEach((item: any) => {
    const dayName: any = item.dia.nombre;
    if (daysCounter[dayName]) {
      daysCounter[dayName]++;
    } else {
      daysCounter[dayName] = 1;
    }
  });

  const repeatedDays = Object.keys(daysCounter).filter(
    (dayName) => daysCounter[dayName] > 1,
  );

  const { data: dias = [], working: diasWorking } = useDiasLibbyFetch({
    limit: 500,
    filter: sabadoFilter,
  });

  const { data: sedes = [], working: sedesWorking } =
    useSedesPresentismoLibbyFetch({
      limit: 500,
    });

  const columns = [
    {
      id: 'nombre',
      label: 'Día',
      width: '10%',
      hideSortIcon: true,
      render: (dia: presentismo.Days) => {
        return (
          <Tooltip
            title={
              repeatedDays.includes(dia)
                ? 'Existen contraturnos para este día'
                : ''
            }
            placement="right"
            arrow
          >
            <Grid>
              <CheckboxWithField
                disabled={repeatedDays.includes(dia) || disabled}
                name="aceptado"
                dia={dia}
              />
            </Grid>
          </Tooltip>
        );
      },
    },
    {
      id: 'dentro',
      label: 'Dentro del establecimiento',
      hideSortIcon: true,
      width: '40%',
      render: (_: undefined, row: presentismo.PropuestaJeRow) => {
        const content = [
          {
            value: false,
            label: 'No',
          },
          {
            value: true,
            label: 'Si',
          },
        ];

        return Boolean(values[row.nombre]?.aceptado) ? (
          <SimpleSelectWithField
            data-testid={`select-${row.nombre}-option`}
            content={content}
            valueKey="value"
            labelKey="label"
            dia={row.nombre}
            name="dentro"
            disabled={disabled}
            placeholder="Seleccioná una opción"
          />
        ) : null;
      },
    },
    {
      id: 'sede',
      label: 'Otra sede',
      sortable: false,
      width: '40%',
      hideSortIcon: true,
      render: (_: undefined, row: presentismo.PropuestaJeRow) => {
        return values[row.nombre]?.dentro === false &&
          values[row.nombre]?.aceptado ? (
          <AutocompleteWithField
            datalist={sedes}
            valueKey="idSede"
            labelKey="nombre"
            dia={row.nombre}
            name="sede"
            disabled={disabled}
          />
        ) : null;
      },
    },
  ];

  const classes = useStyles();

  return diasWorking || sedesWorking ? (
    <Loading />
  ) : (
    <Grid container>
      <Grid item xs={12}>
        <InfoTable columns={columns} rows={dias} />
        <div className={classes.btnSubmitContainer}>
          {!disabled && (
            <MainButton
              disabled={
                submitDisabled(values) ||
                form.getState().pristine ||
                form.getState().hasValidationErrors ||
                form.getState().submitting
              }
              title={
                form.getState().submitting ? <Loading /> : 'Guardar Propuesta'
              }
              typeButton="submit"
              customStyle={{
                margin: 'auto',
              }}
            />
          )}
        </div>
      </Grid>
    </Grid>
  );
};
