import { Exceptuado } from 'src/app/models';
import { AnyObject } from 'src/commons';
import { DateRangeList } from '../TomaPresentismo/utils/DateRangeList';
import { Point } from '../TomaPresentismo/utils/Grid';

export const SELECT_CURRENT_WEEK = 'SELECT_CURRENT_WEEK';

export const SHOW_TABLE_MODAL = 'SHOW_TABLE_MODAL';

export const SET_ROWS = 'SET_ROWS';
export const MAKE_DAY_SELECTION = 'MAKE_DAY_SELECTION';
export const IS_MODAL_OPEN = 'IS_MODAL_OPEN';
export const ROW_INDEX = 'ROW_INDEX';
export const SET_IDX_DAY_SELECTION = 'SET_IDX_DAY_SELECTION';
export const SET_CHECKED_ROWS = 'SET_CHECKED_ROWS';
export const SET_META_DATA = 'SET_META_DATA';
export const SET_EXCEPTUADOS = 'SET_EXCEPTUADOS';
export const SET_EXCEPTUADOS_LIST = 'SET_EXCEPTUADOS_LIST';
export const SET_ESTADO_EMERGENCIA = 'SET_ESTADO_EMERGENCIA';
export const SET_SUSPENSION_CLASES = 'SET_SUSPENSION_CLASES';
export const SET_TIPO_JORNADA = 'SET_TIPO_JORNADA';
export const SET_SAVE_DISABLE = 'SET_SAVE_DISABLE';
export const SET_ENABLE_AUTO_SAVE = 'SET_ENABLE_AUTO_SAVE';
export const SET_GRID_POSITION = 'SET_GRID_POSITION';
export const SET_IS_JORNADA_EXTENDIDA_TAB = 'SET_IS_JORNADA_EXTENDIDA_TAB';
export const SET_ALUMNO_TO_SEARCH = 'SET_ALUMNO_TO_SEARCH';
export const SET_OPEN_JUSTIFICACION = 'SET_OPEN_JUSTIFICACION';

export const selectWeek = (currentWeek: AnyObject) => {
  return {
    type: SELECT_CURRENT_WEEK,
    payload: { currentWeek },
  };
};

export const makeDaySelection = (selection: AnyObject) => {
  return {
    type: MAKE_DAY_SELECTION,
    payload: { selection },
  };
};

export const openTableModal = (payload: AnyObject) => ({
  type: SHOW_TABLE_MODAL,
  payload,
});

export const setRows = (rows: AnyObject) => ({
  type: SET_ROWS,
  payload: { rows },
});

export const setIsModalOpen = (open: boolean) => ({
  type: IS_MODAL_OPEN,
  payload: { open },
});

export const setGridPosition = (point: Point) => ({
  type: SET_GRID_POSITION,
  payload: point,
});

export const setRowIndex = (index: number) => ({
  type: ROW_INDEX,
  payload: { index },
});

export const setIdxDaySelected = (index: number) => ({
  type: SET_IDX_DAY_SELECTION,
  payload: index,
});

export const setCheckedRows = (ids: string[]) => ({
  type: SET_CHECKED_ROWS,
  payload: ids,
});

export const setMetaData = (metadata: any) => ({
  type: SET_META_DATA,
  payload: metadata,
});

export const setExceptuados = (metadata) => ({
  type: SET_EXCEPTUADOS,
  payload: metadata,
});

export const setExceptuadosList = (
  idMovimiento: string,
  list: DateRangeList,
  toDelete?: Exceptuado[],
) => ({
  type: SET_EXCEPTUADOS_LIST,
  payload: { idMovimiento, list, toDelete },
});

export const setEstadoEmergencia = (metadata) => ({
  type: SET_ESTADO_EMERGENCIA,
  payload: JSON.parse(JSON.stringify(metadata)),
});

export const setSuspensionClases = (metadata) => ({
  type: SET_SUSPENSION_CLASES,
  payload: metadata,
});

export const setAlumnoToSearch = (alumno: string) => ({
  type: SET_ALUMNO_TO_SEARCH,
  payload: alumno,
});

export const setTipoJornada = (jornada) => ({
  type: SET_TIPO_JORNADA,
  payload: jornada,
});

export const setSaveDisable = (value) => ({
  type: SET_SAVE_DISABLE,
  payload: value,
});

export const setEnableAutoSave = (value) => ({
  type: SET_ENABLE_AUTO_SAVE,
  payload: value,
});

export const setIsJornadaExtendidaTab = (value: boolean) => ({
  type: SET_IS_JORNADA_EXTENDIDA_TAB,
  payload: value,
});

export const setOpenJustificacion = (value) => ({
  type: SET_OPEN_JUSTIFICACION,
  payload: value,
});
