import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { DiscipSimTempPlan } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'discip_sim_temp_plan';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idDiscipSimTempPlan',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idDiscipSimTempPlan',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.DiscipSimTempPlan
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.DiscipSimTempPlan,
      hoc: { ...defaultOptions.hoc, ...businessOptions.DiscipSimTempPlan.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<DiscipSimTempPlan>(DAO_NAME, optionsToUse);

// hooks
export const useDiscipSimTempPlanFetchById = fetchById;
export const useDiscipSimTempPlanLibbyCall = libbyCall;
export const useDiscipSimTempPlanDAO = libbyDAO;
export const useDiscipSimTempPlanLibbyFetch = libbyFetch;

// components/hoc
export const DiscipSimTempPlanAutocomplete = entityAutocomplete;
export const DiscipSimTempPlanCall = renderPropCall;
export const DiscipSimTempPlanById = renderPropFetchById;
export const DiscipSimTempPlanSelect = simpleSelect;
export const DiscipSimTempPlanAccordionSelector = accordionSelector;
export const DiscipSimTempPlanSelectedChips = selectedChips;

// context
// ByIdContext
export const useDiscipSimTempPlanByIdContext = useByIdContext;
export const DiscipSimTempPlanByIdProvider = ByIdProvider;
export const DiscipSimTempPlanByIdContext = ByIdContext;
// GeneralContext
export const useDiscipSimTempPlanContext = useGeneralContext;
export const DiscipSimTempPlanProvider = GeneralProvider;
export const DiscipSimTempPlanContext = GeneralContext;
