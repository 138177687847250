import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { AnyObject } from 'src/commons';
import {
  useObjetivosDeAprendizajeLibbyCall,
  SeccionProvider,
  useContenidoEspecificoLibbyCall,
  useEspacioCurricularByIdContext,
  usePropositosDeEnsenanzaLibbyCall,
  useIndicadoresDeEvaluacionLibbyCall,
  useHabilidadesLibbyCall,
  useRecursosLibbyCall,
  useInstrumentosDeEvaluacionLibbyCall,
} from 'src/app/business';
import {
  ContenidoEspecifico,
  EspacioCurricular,
  ObjetivosDeAprendizaje,
  PropositosDeEnsenanza,
  Seccion,
} from 'src/app/models';
import { useGeneralContext } from 'src/context';
import { makeEntityByEspacio } from '../../../../commons/hooks';
import {
  Habilidades,
  IndicadoresDeEvaluacion,
  InstrumentosDeEvaluacion,
  Recursos,
} from '../../../../../../../../../../app/models';
import { useRolesContext } from '../../../../../../../../../../context/RolesContext';

export type SetActiveFilter = (filter: AnyObject) => void;

export interface Result {
  objetivos: ObjetivosDeAprendizaje[];
  propositos: PropositosDeEnsenanza[];
  indicadores: IndicadoresDeEvaluacion[];
  recursos: Recursos[];
  habilidades: Habilidades[];
  instrumentos: InstrumentosDeEvaluacion[];
  contenidosEspecificos: ContenidoEspecifico[];
}

export interface DisciplinarComunContextValue {
  data: Result;
  working: boolean;
}

const filterMateria = (espacio?: EspacioCurricular) => ({
  filter: {
    materia: [{ path: 'id_materia', value: espacio?.materia.idMateria || '' }],
  },
  limit: 20000,
});

const filterVoid = () => ({
  filter: {},
  limit: 20000,
});

const useObjetivosDAO = makeEntityByEspacio<ObjetivosDeAprendizaje[]>(
  useObjetivosDeAprendizajeLibbyCall,
  filterMateria,
);

const usePropositosDAO = makeEntityByEspacio<PropositosDeEnsenanza[]>(
  usePropositosDeEnsenanzaLibbyCall,
  filterMateria,
);

const useIndicadoresDAO = makeEntityByEspacio<IndicadoresDeEvaluacion[]>(
  useIndicadoresDeEvaluacionLibbyCall,
  filterVoid,
);

const useHabilidadesDAO = makeEntityByEspacio<Habilidades[]>(
  useHabilidadesLibbyCall,
  filterVoid,
);

const useRecursosDAO = makeEntityByEspacio<Recursos[]>(
  useRecursosLibbyCall,
  filterVoid,
);

const useInstrumentosDAO = makeEntityByEspacio<InstrumentosDeEvaluacion[]>(
  useInstrumentosDeEvaluacionLibbyCall,
  filterVoid,
);

export const DisciplinarComunContext =
  createContext<DisciplinarComunContextValue>({
    // @ts-ignore
    data: {},
    working: false,
  });

export const useDisciplinarComunContext = () =>
  useContext<DisciplinarComunContextValue>(DisciplinarComunContext);

export interface DisciplinarComunProviderProps {
  filter?: AnyObject;
  children: JSX.Element;
}

export const DisciplinarComunProvider = ({
  children,
}: DisciplinarComunProviderProps) => {
  const { selectedRole } = useRolesContext();
  const { generalState } = useGeneralContext();
  const { data: espacioCurricular, working: eWorking } =
    useEspacioCurricularByIdContext();

  const { data: objetivos, working: oWorking } =
    useObjetivosDAO(espacioCurricular);
  const { data: propositos, working: pWorking } =
    usePropositosDAO(espacioCurricular);
  const { data: indicadores, working: iWorking } =
    useIndicadoresDAO(espacioCurricular);
  const { data: recursos, working: rWorking } =
    useRecursosDAO(espacioCurricular);
  const { data: habilidades, working: hWorking } =
    useHabilidadesDAO(espacioCurricular);
  const { data: instrumentos, working: instWorking } =
    useInstrumentosDAO(espacioCurricular);

  const {
    data: contenidosEspecificos,
    working: ceWorking,
    recall: recallContenidoEspecifico,
  } = useContenidoEspecificoLibbyCall<ContenidoEspecifico[]>({
    methodName: 'filtroEspacioCurricular',
    params: [espacioCurricular?.materia.idMateria],
  });

  useEffect(() => {
    if (espacioCurricular)
      recallContenidoEspecifico([espacioCurricular?.materia.idMateria]);
  }, [espacioCurricular, recallContenidoEspecifico]);

  let filterAnio: any = [];
  if (generalState?.espacioCurricular.planificacion.selected.anio) {
    filterAnio = [
      {
        path: 'anio.idAnio',
        value: generalState?.espacioCurricular.planificacion.selected.anio,
      },
    ];
  }

  const seccionFilter = useRef({
    filterAnio,
    1: [{ path: 'localizacion', value: selectedRole?.localizacionId }],
    seccion: generalState
      ? generalState.espacioCurricular.planificacion.selected.seccion
          ?.filter((item: Seccion) => typeof item.idSeccion === 'number')
          .map((item2: Seccion) => ({
            path: 'seccion.idSeccion',
            value: item2.idSeccion || 0,
          }))
      : [],
  });

  const workingAll =
    oWorking ||
    pWorking ||
    iWorking ||
    rWorking ||
    hWorking ||
    instWorking ||
    ceWorking ||
    eWorking;

  const value = useMemo<DisciplinarComunContextValue>(
    () => ({
      data: {
        objetivos: objetivos || [],
        propositos: propositos || [],
        indicadores: indicadores || [],
        recursos: recursos || [],
        habilidades: habilidades || [],
        instrumentos: instrumentos || [],
        contenidosEspecificos: contenidosEspecificos || [],
      },
      working: workingAll,
    }),
    [
      objetivos,
      propositos,
      indicadores,
      recursos,
      habilidades,
      instrumentos,
      contenidosEspecificos,
      workingAll,
    ],
  );

  return (
    <SeccionProvider filter={seccionFilter.current}>
      <DisciplinarComunContext.Provider value={value}>
        {children}
      </DisciplinarComunContext.Provider>
    </SeccionProvider>
  );
};
