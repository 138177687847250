import { LibbyFetchDAO } from './LibbyFetchDAO';

export class DomicilioEstablecimientoDAO extends LibbyFetchDAO {
  constructor() {
    super('domicilio_establecimiento', 'idDomicilioEstablecimiento');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
