import { CommonEndpointDAO } from './CommonEndpointDAO';

export class GenerarExcelDAO extends CommonEndpointDAO {
  constructor() {
    super('generarexcel');
  }

  generate(data: any) {
    return this.post({ ...data });
  }
}
