export enum ANIO_PRIMARIA {
  PRIMER = 1,
  SEGUNDO = 2,
  TERCER = 3,
  CUARTO = 4,
  QUINTO = 5,
  SEXTO = 6,
  SEPTIMO = 7,
  CUARTO_QUINTO = 8,
  SEXTO_SEPTIMO = 9,
}
