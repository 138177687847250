import { LibbyFetchDAO } from './LibbyFetchDAO';

export class CycleLective extends LibbyFetchDAO {
  constructor() {
    super('ciclo_lectivo', 'idCicloLectivo');
  }
  // Este es el comportamiento por defecto deseado
  getAll() {
    return this.query()
      .higherOrEquals('anio', 2021)
      .orderBy('anio', 'desc')
      .limit(0, 10000)
      .run();
  }

  getByAnio(anio: number) {
    return this.query().equals('anio', anio).run();
  }
}
