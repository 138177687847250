import { useMemo } from 'react';
import { AnyObject } from '../types';

export type NormalizedData<T> = {
  [key: string]: T;
};

export const useNormalizeData = <T extends AnyObject = AnyObject>(
  data: T[] | undefined,
  idKey: keyof T,
) =>
  useMemo(
    () =>
      !data
        ? {}
        : data.reduce<NormalizedData<T>>(
            (acum, item) => ({
              ...acum,
              [item[idKey] as string]: item,
            }),
            {},
          ),
    [data, idKey],
  );
