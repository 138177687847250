import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useMemo, useEffect, useState } from 'react';
import { useReportLibbyFetch } from 'src/app/business/Report';

// Hook para la gestion del polling en reportes
export const usePollingReport = (userId: number) => {
  const [reportId, setReportId] = useState(0);
  const [pollingEnabled, setPollingEnabled] = useState<boolean>(false);
  const [downloadLink, setDownloadLink] = useState<string>('');
  const [inPolling, setInPolling] = useState(false);
  const [data, setData] = useState([]);

  const { enqueueSnackbar } = useSnackbar();

  const pollingFilter = useMemo(
    () => ({
      ...(!!reportId && { reportId: [{ path: 'reportId', value: reportId }] }),
      createdBy: [{ path: 'createdBy', value: userId }],
    }),
    [reportId, userId],
  );

  // Declare the report polling fetch
  const { data: pollingData = [], reFetch } = useReportLibbyFetch({
    filter: pollingFilter,
    enabled: !!reportId,
  });

  useEffect(() => {
    const records = _.cloneDeep(pollingData);
    setData(records);
  }, [pollingData, setData]);

  useEffect(() => {
    if (!!!reportId) return;
    const isAnyPending = data.some(
      (report) => ![4, 5].includes(report.reportStatus.reportStatusId),
    );
    setPollingEnabled(isAnyPending);
    if (
      !isAnyPending &&
      data.some((report) => [4, 5].includes(report.reportStatus.reportStatusId))
    ) {
      setInPolling(false);
      setData([]);
      const isSuccess = data.some(
        (report) => report.reportStatus.reportStatusId === 4,
      );
      enqueueSnackbar(
        isSuccess
          ? 'Reporte descargado exitosamente!'
          : 'Ocurrió un error al generar el reporte.',
        {
          variant: isSuccess ? 'success' : 'error',
        },
      );
      if (isSuccess)
        setDownloadLink(
          data.find((report) => report.reportStatus.reportStatusId === 4).link,
        );
    }
  }, [data, setPollingEnabled, reportId, enqueueSnackbar]);

  // Start the polling
  useEffect(() => {
    if (!pollingEnabled) return;
    const interval = setInterval(async () => {
      reFetch();
    }, 5000);

    return () => clearInterval(interval);
  }, [reFetch, pollingEnabled]);

  useEffect(() => {
    if (downloadLink !== '') {
      setDownloadLink('');
      window.location.assign(downloadLink);
    }
  }, [downloadLink]);

  return {
    pollingEnabled,
    setDownloadLink,
    inPolling,
    setInPolling,
    setReportId,
  };
};
