import React, { useCallback, useMemo, useEffect, useState } from 'react';
import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Checkbox, Grid, IconButton, Tooltip } from '@material-ui/core';
import { ObservacionesModal, OptionsModal } from 'src/commons';
import { primary } from 'src/theme/colors';
import { DayCell } from '../components/DayCell';
import customFormDialog from 'src/commons/services/customFormDialog';
import { usePresentismoContext } from '../../context/Context';
import { WarningIcon } from '../components/WarningIcon';
import { AssistanceIcon } from '../components/AssistanceIcon';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import { actualizarPresentismo } from '../../TomaPresentismoOLD/utils/estadoPresentismo';
import { useGeneralContext as useAppGeneralContext } from 'src/context/GeneralContext/GeneralContext';
import { useRolesContext } from 'src/context/RolesContext';
import { Metadata } from 'src/app/models/presentismo_v2';
import { Alumno, Exceptuado } from 'src/app/models';
import { AlumnoPresentismo, PresenteModified, presentismo } from '../../types';
import { EditExceptuadosModal } from '../../components/EditExceptuadosModal';
import { DateRangeList } from '../utils/DateRangeList';
import { ToastProvider } from 'src/commons/components/ToastMessage';
import { JustificacionScreen } from '../../Justificacion/JustificacionScreen';
import {
  Edit as EditIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';
import { capitalize } from '../../../../../utils';

const useStyles = makeStyles(() => ({
  headerDayCell: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '60px',
    maxWidth: '75px',
    '@media (min-width:1280px)': {
      maxWidth: '100%',
    },
  },
  iconObservacion: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'end',
    color: primary.lightBlue,
    marginLeft: '20px',
  },
  observaciones: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '400px',
    marginTop: '10px',
    textAlign: 'right',
    color: 'black',
  },
  today: {
    color: '#03a1d6',
  },
  todayCircle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '2.2em',
    height: '2.2em',
    color: 'white',
    backgroundColor: '#03a1d6',
    borderRadius: '50%',
  },
  days: {
    display: 'flex',
    justifyContent: 'center',
  },
  required: {
    position: 'relative',
    borderRadius: '5px',
    boxShadow: '0 0 5px 2px red',
    '&::after': {
      width: '100%',
      content: '"Campo requerido"',
      color: 'red',
      position: 'absolute',
      textAlign: 'center',
    },
  },
  columnSelected: {
    filter: 'brightness(150%)',
  },
  columnNotSelected: {
    filter: 'brightness(90%)',
  },
  disabled: {
    '&:hover': {
      cursor: 'not-allowed',
    },
  },
  disabledColor: {
    color: 'grey',
  },
  celdaNombreApellido: {
    textTransform: 'capitalize',
    minWidth: '8rem',
    padding: '0 1rem',
  },
}));

const TOOLTIP_MESSAGE: { [key: string]: { [key: string]: string } } = {
  '1': {
    ausenciaJustificada: 'Ausencia justificada',
    retiroAnticipado: 'Modificación Horaria',
    virtualidad: 'Virtualidad',
  },
  '2': {
    ausenciaJustificada: 'Ausencia justificada',
    retiroAnticipado: 'Retiro anticipado / Ingreso tardío',
    virtualidad: 'Virtualidad',
  },
  '3': {
    ausenciaJustificada: 'Ausencia justificada',
    retiroAnticipado: 'Retiro anticipado / Ingreso tardío',
    virtualidad: 'Virtualidad',
  },
};
// NO SE USA - DEPRECADO
export const useTomaPresentismoColumns_v3 = (tipoJornada: string) => {
  const esJornadaCompleta = tipoJornada === 'Completa';

  const { generalState } = useAppGeneralContext();

  const roleContext = useRolesContext();
  const { nivel } = roleContext.selectedRole;

  const {
    dispatch: { setExceptuadosList },
  } = usePresentismoContext();

  const classes = useStyles();
  const {
    state: { rows, currentWeek, idxDaySelected, daySelection, checkedRows },
    dispatch: {
      setRows,
      setIdxDaySelected,
      setCheckedRows,
      // setMetaData,
      setEnableAutoSave,
      setOpenJustificacion,
    },
    serverDate,
  } = usePresentismoContext();

  //TODO: ver como cambiar esto;
  const checkEstadoEmergencia = useCallback((fecha) => {
    /* const search = rows[0]?.metadatas?.find(
        (metadata: Metadata) =>
          metadata.dates.includes(fecha) &&
          metadata.metadataType.descripcion === 'Emergencia',
      );*/
    // const isEmergencia = Boolean(search?.value);
    const isEmergencia = false;
    return isEmergencia;
  }, []);

  const checkSuspensionClases = useCallback((fecha) => {
    /* const search = rows[0]?.metadatas?.find(
        (metadata: Metadata) =>
          metadata.dates.includes(fecha) &&
          metadata.metadataType.descripcion === 'Suspensión de clases',
      );*/
    // const isSuspensionClases = Boolean(search?.value);
    const isSuspensionClases = false;
    return isSuspensionClases;
  }, []);

  const searchObservacion = useCallback(
    (value: AlumnoPresentismo) => {
      const rowObservacion = rows.filter(
        (movement) => movement?.alumno?.idAlumno === value?.alumno?.idAlumno,
      );
      return rowObservacion[0].observaciones;
    },
    [rows],
  );

  const handleObservaciones = useCallback(
    async (row: AlumnoPresentismo, day: string) => {
      setEnableAutoSave(false);
      /*const metadata = row.metadatas?.find(
        (meta) =>
          Number(meta.metadataType.idMetadataType) === 14 &&
          meta.dates.includes(day),
      ) as Metadata & { value: boolean };*/ await customFormDialog.show({
        title: 'Observaciones',
        renderComponent: (
          <ObservacionesModal
            content={searchObservacion(row)}
            onAddContent={(content: string) => {
              /* setMetaData({
                day,
                row,
                metadata: {
                  metadataType: {
                    idMetadataType: 14,
                    descripcion: 'Observaciones',
                  },
                  value: content !== '',
                  data: { observacion: content },
                },
              });*/
            }}
            // value={metadata?.data?.observacion}
            value={'test de observacion'}
          />
        ),
        sizeWidth: 'sm',
      });
      setEnableAutoSave(true);
    },
    //[searchObservacion, setEnableAutoSave, setMetaData],
    [searchObservacion, setEnableAutoSave],
  );

  const justificacionModal = useCallback(
    (row, day) => {
      setOpenJustificacion({ row: row, open: true });
    },
    [setOpenJustificacion],
  );

  const selectDayCell = useCallback(
    ({ day, estadoPresente, row, turno, multiple }) => {
      const newRows = rows.map((movement) => {
        if (
          movement.alumno?.idAlumno === row?.alumno?.idAlumno ||
          (multiple && checkedRows.includes(movement.alumno?.idAlumno))
        ) {
          const turnos = turno !== 3 ? [turno] : [1, 2];

          turnos.forEach((idTurno) => {
            const existePresentismo = movement.presentismo.some(
              (p: { fecha: string; turno: string }) =>
                Number(p.turno) === idTurno && p.fecha === day,
            );
            if (existePresentismo) {
              movement.presentismo = actualizarPresentismo({
                alumno: movement,
                estadoPresente: estadoPresente,
                fecha: day,
                turno: idTurno,
              });
            } else {
              const nuevoPresentismo = {
                estadoPresente: estadoPresente,
                fecha: day,
                turno: idTurno,
              };
              movement.presentismo.push(nuevoPresentismo);
            }
          });
          return movement;
        } else {
          return movement;
        }
      });
      setRows(newRows);
    },
    [checkedRows, rows, setRows],
  );

  useEffect(() => {
    if (daySelection.selection) {
      const day = moment(currentWeek)
        .add(idxDaySelected, 'days')
        .format('YYYY-MM-DD');
      selectDayCell({
        day,
        estadoPresente: daySelection.selection.selection,
        turno: daySelection.selection.turno,
        multiple: true,
      });
    }
  }, [
    currentWeek,
    daySelection,
    daySelection.selection,
    esJornadaCompleta,
    idxDaySelected,
    selectDayCell,
  ]);

  const isToday = useCallback(
    (day, circle) => {
      if (day === moment(serverDate).format('YYYY/MM/DD')) {
        return circle ? classes.todayCircle : classes.today;
      }
    },
    [classes, serverDate],
  );

  // chequeamos que todos los alumnos seleccionados tengan para el dia seleccionado ausente en por lo menos uno de sus turnos
  const someCheckedStudentHasAusente = useMemo(() => {
    const someHasAusente = rows.some((row) => {
      if (checkedRows.includes(row.alumno.idAlumno)) {
        const presentismosOfToday = row.presentismo.filter(
          (presente: PresenteModified) => {
            const diaSeleccionado = moment(currentWeek)
              .add(idxDaySelected, 'days')
              .format('YYYY-MM-DD');
            return moment(presente.fecha).isSame(moment(diaSeleccionado));
          },
        );
        if (presentismosOfToday.length > 0) {
          return presentismosOfToday.some((presente: PresenteModified) => {
            return presente.estadoPresente.idEstadoPresente === 2;
          });
        } else {
          return false;
        }
      }
      return true;
    });
    return someHasAusente;
  }, [checkedRows, currentWeek, idxDaySelected, rows]);

  const columns = useMemo(() => {
    const days = ['LUNES', 'MARTES', 'MIÉRCOLES', 'JUEVES', 'VIERNES'];

    const canDayForward = (selectedDay: string) => {
      let enabled = true;

      const currentServerDate = generalState.date;

      if (moment(selectedDay).isAfter(moment(currentServerDate))) {
        enabled = false;
      }

      return enabled;
    };

    const getDayColumn = ({ days }) => {
      return days.map((day, idx) => {
        return {
          id: idx,
          label: (
            <Grid style={{ width: '100%' }}>
              <Box
                className={isToday(
                  moment(currentWeek).add(idx, 'days').format('YYYY/MM/DD'),
                  false,
                )}
              >
                {capitalize(day)}{' '}
                {moment(currentWeek).add(idx, 'days').format('D')}
              </Box>
              <Box>
                <Grid container style={{ width: '100%' }}>
                  <Grid
                    justifyContent="center"
                    item
                    xs={6}
                    style={{ border: 'solid 1px gray' }}
                  >
                    M
                  </Grid>
                  <Grid justifyContent="center" item xs={6}>
                    T
                  </Grid>
                </Grid>
              </Box>
              {checkEstadoEmergencia(
                moment(currentWeek).add(idx, 'days').format('YYYY-MM-DD'),
              ) && <AnnouncementIcon color="error" />}
              {checkSuspensionClases(
                moment(currentWeek).add(idx, 'days').format('YYYY-MM-DD'),
              ) && <AnnouncementIcon style={{ color: 'orange' }} />}
            </Grid>
          ),
          onHeadClick: () => {
            if (
              canDayForward(
                moment(currentWeek).add(idx, 'days').format('YYYY/MM/DD'),
              )
            ) {
              setIdxDaySelected(idx);
            }
          },
          hideSortIcon: true,
          headCellsStyles:
            idx === idxDaySelected
              ? { backgroundColor: '#c8eff6', padding: 0 }
              : { padding: 0 },
          customStyles: { padding: '0px', height: '8em' },
          width: '6%',
          sortLabelStyles: { width: '100%', justifyContent: 'center' },
          render: (value: any, row: AlumnoPresentismo) => (
            <Box
              key={`cell-${row.idAlumnoMovimiento}-${idx}`}
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr',
                height: '100%',
              }}
              className={`${
                idx === idxDaySelected
                  ? classes.columnSelected
                  : classes.columnNotSelected
              }`}
            >
              <DayCell
                setIdxDaySelected={setIdxDaySelected}
                day={idx}
                data={row}
                onSelect={selectDayCell}
                turno={row?.seccion?.turno?.idTurno}
                esJornadaCompleta={false}
              />
              {row.contraturno && (
                <DayCell
                  setIdxDaySelected={setIdxDaySelected}
                  day={idx}
                  data={row}
                  onSelect={selectDayCell}
                  turno={row?.seccion?.turno?.idTurno}
                  esJornadaCompleta={false}
                />
              )}
            </Box>
          ),
        };
      });
    };

    const getDayColumnJornadaCompleta = ({ days }) => {
      return days.map((day, idx) => {
        return {
          id: idx,
          icon: () => (
            <Grid>
              <Box
                className={isToday(
                  moment(currentWeek).add(idx, 'days').format('YYYY/MM/DD'),
                  false,
                )}
                onClick={() => {
                  if (
                    canDayForward(
                      moment(currentWeek).add(idx, 'days').format('YYYY/MM/DD'),
                    )
                  ) {
                    setIdxDaySelected(idx);
                  }
                }}
              >
                {day} {moment(currentWeek).add(idx, 'days').format('D')}
                <Box
                  style={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                    height: '100%',
                  }}
                >
                  <Box>Mañana</Box>
                  <Box>Tarde</Box>
                </Box>
              </Box>
              {checkEstadoEmergencia(
                moment(currentWeek).add(idx, 'days').format('YYYY-MM-DD'),
              ) && <AnnouncementIcon color="error" />}
              {checkSuspensionClases(
                moment(currentWeek).add(idx, 'days').format('YYYY-MM-DD'),
              ) && <AnnouncementIcon style={{ color: 'orange' }} />}
            </Grid>
          ),
          onHeadClick: (id: number) => {
            if (
              !canDayForward(
                moment(currentWeek).add(idx, 'days').format('YYYY/MM/DD'),
              )
            ) {
              setIdxDaySelected(idx);
            }
          },
          hideSortIcon: true,
          headCellsStyles:
            idx === idxDaySelected ? { backgroundColor: '#c8eff6' } : {},
          customStyles: {
            padding: '0px',
            height: '8em',
          },
          width: '10%',
          render: (value: any, row: AlumnoPresentismo) => {
            const date = moment(currentWeek)
              .add(idx, 'days')
              .format('YYYY-MM-DD');
            const contraturnos = row.contraturnos;
            // Si se registro presentismo para el turno mañana se habilita el turno tarde
            let existTM = false;
            const containTM = row.presentismo?.filter(
              ({ turno }: { turno: number }) => turno === 1, // 1 --> turno mañana
            );
            if (containTM[0]) {
              const fechasTM = containTM.map((p: { fecha: string }) => p.fecha);
              if (fechasTM.includes(date)) {
                existTM = true;
              }
            }
            return (
              <Box
                key={`cell-${row.idAlumnoMovimiento}-${idx}`}
                style={{
                  display: 'grid',
                  gridTemplateColumns: '1fr 1fr',
                  height: '100%',
                  padding: '0px',
                }}
                className={`${
                  idx === idxDaySelected
                    ? classes.columnSelected
                    : classes.columnNotSelected
                }`}
              >
                <DayCell
                  setIdxDaySelected={setIdxDaySelected}
                  day={idx}
                  data={row}
                  onSelect={selectDayCell}
                  turno={1}
                  esJornadaCompleta={esJornadaCompleta}
                />
                <DayCell
                  setIdxDaySelected={setIdxDaySelected}
                  day={idx}
                  data={row}
                  onSelect={selectDayCell}
                  turno={2}
                  existTM={existTM}
                  esJornadaCompleta={esJornadaCompleta}
                />
                {contraturnos &&
                  contraturnos.map(() => (
                    <DayCell
                      setIdxDaySelected={setIdxDaySelected}
                      day={idx}
                      data={row}
                      onSelect={selectDayCell}
                      turno={2}
                      existTM={existTM}
                      esJornadaCompleta={esJornadaCompleta}
                    />
                  ))}
              </Box>
            );
          },
        };
      });
    };

    const day = moment(currentWeek)
      .add(idxDaySelected, 'days')
      .format('YYYY-MM-DD');
    const isDisabled = moment(currentWeek)
      .add(idxDaySelected, 'days')
      .isAfter(moment(serverDate));
    const idAlumnos = rows.map(
      ({ alumno: { idAlumno } }: { alumno: Alumno }) => idAlumno,
    );

    const columnsWithDays = [
      {
        id: 'icon',
        width: '1%',
        label: '',
        hideSortIcon: true,
        noSort: true,
        align: 'left',
        icon: () => (
          <Checkbox
            checked={checkedRows.length === idAlumnos.length}
            onChange={() => {
              if (checkedRows.length === idAlumnos.length) {
                setCheckedRows([]);
              } else {
                setCheckedRows(idAlumnos);
              }
            }}
            inputProps={{ 'aria-labelledby': 'transfer-list-item-all-label' }}
            color="primary"
          />
        ),
        render: (value: any, row: AlumnoPresentismo) => {
          const isSelected = checkedRows.includes(row?.alumno?.idAlumno);
          return (
            <Checkbox
              checked={isSelected}
              onChange={() => {
                isSelected
                  ? setCheckedRows(
                      checkedRows.filter(
                        (idKey) => row?.alumno?.idAlumno !== idKey,
                      ),
                    )
                  : setCheckedRows([...checkedRows, row?.alumno?.idAlumno]);
              }}
              inputProps={{
                'aria-labelledby': 'transfer-list-item-all-label',
              }}
              color="primary"
            />
          );
        },
      },
      {
        id: 'nombre',
        label: 'Apellido y nombre',
        width: '5%',
        render: (value: any, row: AlumnoPresentismo) => {
          const nombreEstudiante = row?.alumno?.persona?.nombre
            ?.toLowerCase()
            .split(' ')[0];
          const apellidoEstudiante = row?.alumno?.persona?.apellido
            ?.toLowerCase()
            .split(' ')[0];
          return (
            <div className={classes.celdaNombreApellido}>
              {`${apellidoEstudiante}, ${nombreEstudiante}`}
            </div>
          );
        },
      },
      {
        id: 'avisos',
        label: 'Avisos',
        width: '1%',
        hideSortIcon: true,
        render: (value: any, row: AlumnoPresentismo) => (
          <Grid style={{ display: 'flex', justifyContent: 'center' }}>
            <WarningIcon
              currentWeek={currentWeek}
              day={idxDaySelected}
              data={row}
            />
          </Grid>
        ),
      },
      {
        id: 'justificacion',
        label: 'Justificación',
        width: '1%',
        hideSortIcon: true,
        render: (value: any, row: AlumnoPresentismo) => {
          let isEnabled = false;
          const presentismosOfToday = row?.presentismo.filter(
            (presente: PresenteModified) => {
              const diaSeleccionado = moment(currentWeek)
                .add(idxDaySelected, 'days')
                .format('YYYY-MM-DD');
              return moment(presente.fecha).isSame(moment(diaSeleccionado));
            },
          );
          if (presentismosOfToday.length > 0) {
            isEnabled = presentismosOfToday.some(
              (presente: PresenteModified) => {
                return (
                  presente.estadoPresente.idEstadoPresente === 2 ||
                  presente.estadoPresente.idEstadoPresente === 6 ||
                  presente.estadoPresente.idEstadoPresente === 7 ||
                  presente.estadoPresente.idEstadoPresente === 8
                );
              },
            );
          }
          return (
            <IconButton
              onClick={() => {
                if (!isDisabled && isEnabled) {
                  /* setMetaData({
                    day,
                    row,
                    metadata: {
                      metadataType: {
                        idMetadataType: 10,
                        descripcion: 'Ausencia Justificada',
                      },
                      value: !ausenciaMetadata?.value,
                      data: {},
                      idMetadata: ausenciaMetadata?.idMetadata,
                    },
                  });*/
                  justificacionModal(row, day);
                }
              }}
              className={`${isDisabled && !isEnabled ? classes.disabled : ''}`}
            >
              <EditIcon color={isEnabled ? 'primary' : 'disabled'} />
              {/* <AssistanceIcon
                disabled={!isDisabled && isEnabled}
                rows={rows}
                data={row}
                day={day}
                type={'SpellcheckIcon'}
              />*/}
            </IconButton>
          );
        },
      },
      {
        id: 'retiroIngreso',
        label: 'Ver',
        /*icon: () => (
          <Tooltip title={TOOLTIP_MESSAGE[nivel].retiroAnticipado}>
            <IconButton
              onClick={() => {
                if (!isDisabled) {
                  /!* setMetaData({
                    day,
                    metadata: {
                      metadataType: {
                        idMetadataType: 11,
                        descripcion: 'Retiro Anticipado',
                      },
                      data: {},
                    },
                    multiple: true,
                  });*!/
                }
              }}
              className={`${isDisabled ? classes.disabled : ''}`}
            >
              <AssistanceIcon type={'UpdateIcon'} />
            </IconButton>
          </Tooltip>
        ),*/
        width: '1%',
        hideSortIcon: true,
        render: (value: any, row: AlumnoPresentismo) => {
          let enable = false;
          row.presentismo.forEach((presente) => {
            if (presente?.estadoPresente?.idEstadoPresente === 2) {
              enable = true;
            }
          });
          return (
            /*<IconButton
              onClick={() => {
                if (!isDisabled) {
                  /!*setMetaData({
                    day,
                    row,
                    metadata: {
                      metadataType: {
                        idMetadataType: 11,
                        descripcion: 'Retiro Anticipado',
                      },
                      value: !retiroMetadata?.value,
                      data: {},
                      idMetadata: retiroMetadata?.idMetadata,
                    },
                  });*!/
                }
              }}
              className={`${isDisabled ? classes.disabled : ''}`}
            >
              <AssistanceIcon
                rows={rows}
                data={row}
                day={day}
                type={'UpdateIcon'}
              />
            </IconButton>*/

            <VisibilityIcon />
          );
        },
      },
      {
        id: 'observaciones',
        label: '',
        width: '1%',
        customStyles: { maxWidth: '14em' },
        style: { textAlign: 'center' },
        hideSortIcon: true,
        render: (_: unknown, row: AlumnoPresentismo) => {
          const options = [
            {
              label: 'Ver observaciones',
              onClick: () => handleObservaciones(row, day),
            },
            {
              label: 'Exceptuado',
              onClick: async () => {
                const { tempIntervals: list, intervalsToDelete: toDelete } =
                  await customFormDialog.show<{
                    tempIntervals: DateRangeList;
                    intervalsToDelete: Exceptuado[];
                  }>({
                    title: `Exceptuado - ${row.alumno.persona.nombre} ${row.alumno.persona.apellido}`,
                    renderComponent: (
                      <ToastProvider>
                        <EditExceptuadosModal
                          initialExceptuados={row.exceptuados}
                          alumno={row.alumno}
                        />
                      </ToastProvider>
                    ),
                    sizeWidth: 'md',
                  });
                if (list)
                  setExceptuadosList({
                    idMovimiento: row.idAlumnoMovimiento,
                    list,
                    toDelete,
                  });
              },
            },
          ];
          return <OptionsModal closeOnExternalAction options={options} />;
        },
      },
    ];

    if (esJornadaCompleta) {
      getDayColumnJornadaCompleta({ days }).forEach((day, idx) => {
        columnsWithDays.splice(2 + idx, 0, day);
      });
    } else {
      getDayColumn({ days }).forEach((day, idx) => {
        columnsWithDays.splice(2 + idx, 0, day);
      });
    }

    return columnsWithDays;
  }, [
    currentWeek,
    idxDaySelected,
    serverDate,
    rows,
    esJornadaCompleta,
    generalState.date,
    isToday,
    checkEstadoEmergencia,
    checkSuspensionClases,
    setIdxDaySelected,
    classes.columnSelected,
    classes.columnNotSelected,
    classes.celdaNombreApellido,
    classes.disabled,
    selectDayCell,
    checkedRows,
    setCheckedRows,
    //  setMetaData,
    handleObservaciones,
    setExceptuadosList,
    justificacionModal,
  ]);

  return columns;
};
