import React from 'react';
import { useMemo, useCallback } from 'react';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import DeleteIcon from '@material-ui/icons/Delete';
import { Grid, IconButton, Typography, makeStyles } from '@material-ui/core';
import { primary } from 'src/theme/colors';
import { InfoTableColumn, OptionsModal, OptionsModalOption } from 'src/commons';
import customFormDialog from 'src/commons/services/customFormDialog';
import { AccionesMinimizadas } from 'src/app/models';
import { AccionesColumnsProps } from '../../types';
import ModalDescripcion from '../components/ModalDescripcion';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useAccionesDAO } from 'src/app/business';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles({
  descripcionContainer: {
    display: 'flex',
    alignItems: 'center',
  },
});

export const useAccionesColumns = ({
  refetchAcciones,
  handleView,
  handleEdit,
  handleDuplicate,
}: AccionesColumnsProps) => {
  const classes = useStyles();
  const accionDAO = useAccionesDAO();
  const { enqueueSnackbar } = useSnackbar();

  const onDescriptionClick = (description: any) => async () => {
    await customFormDialog.show({
      title: 'Descripción de Acción',
      sizeWidth: 'lg',
      renderComponent: <ModalDescripcion description={description} />,
    });
  };

  const handleRemoveAccion = useCallback(
    async (row: AccionesMinimizadas) => {
      const confirm = await confirmDialog.show({
        title: `¿Desea eliminar la acción '${row.nombre}'?`,
        content: `Eliminar la acción también eliminará la oferta. Esto no puede deshacerse. ¿Desea continuar?`,
        confirmText: 'Eliminar',
        cancelText: 'Cancelar',
      });
      if (confirm) {
        try {
          await accionDAO.remove({ idAccion: row?.idAccion });
          enqueueSnackbar(
            `La acción '${row.nombre}' se ha eliminado con éxito`,
            {
              variant: 'success',
            },
          );
          refetchAcciones();
        } catch (e) {
          enqueueSnackbar('Error al eliminar acción.', { variant: 'error' });
        }
      }
    },
    [accionDAO, enqueueSnackbar, refetchAcciones],
  );
  const columns = useMemo<InfoTableColumn<AccionesMinimizadas>[]>(
    () => [
      {
        id: 'cicloLectivo.anio',
        width: '2%',
        label: 'Ciclo Lectivo',
        render: (value) => value,
        hideSortIcon: true,
      },
      {
        id: 'nombre',
        width: '15%',
        label: 'Acción',
        render: (value) => value,
        hideSortIcon: true,
      },
      {
        id: 'tipoAccion.nombre',
        width: '5%',
        label: 'Tipo de acción',
        render: (value) => value || '',
        hideSortIcon: true,
      },
      {
        id: 'orientaciones',
        width: '20%',
        label: 'Orientaciones',
        render: (_, row) => {
          let orientaciones = '';
          row.accionOrientaciones.forEach((orientacionIteration, index) => {
            if (index === row?.accionOrientaciones.length - 1) {
              orientaciones += orientacionIteration?.orientacion.nombre;
            } else {
              orientaciones += `${orientacionIteration?.orientacion.nombre}, `;
            }
          });

          return orientaciones;
        },
        hideSortIcon: true,
      },
      {
        id: 'descripcion',
        width: '15%',
        label: 'Descripción',
        render: (value) => {
          return (
            <div className={classes.descripcionContainer}>
              <Typography>Ver descripción</Typography>
              <IconButton onClick={onDescriptionClick(value)}>
                <VisibilityIcon style={{ color: primary.paleBlueStrong }} />
              </IconButton>
            </div>
          );
        },
        hideSortIcon: true,
      },
      {
        id: 'menu',
        width: '10%',
        label: '',
        hideSortIcon: true,
        noSort: true,
        render: (_, row) => {
          const options: OptionsModalOption[] = [
            {
              label: 'Ver acción',
              onClick: handleView(row),
              /* setIsCreatingAnAccion({
                  enabled: true,
                  accion: accion,
                  row: row,
                  isDisabled: true,
                }) */ hide: false,
            },
            {
              label: 'Duplicar acción',
              onClick: handleDuplicate(row),
              /* setIsCreatingAnAccion({
                  enabled: true,
                  accion: {
                    isCreatingAccion: true,
                    organizacion: accion.organizacion,
                  },
                  row: row,
                  isDisabled: false,
                  duplicateAction: true,
                }) */ hide: false,
            },
            {
              label: 'Editar acción',
              onClick: handleEdit(row),
              /* setIsCreatingAnAccion({
                  enabled: true,
                  accion: accion,
                  row: row,
                  isDisabled: false,
                }) */
              //hide: row?.cicloLectivo?.idCicloLectivo !== 24,
            },
            {
              label: 'Eliminar acción',
              onClick: () => handleRemoveAccion(row),
              //hide: row?.cicloLectivo?.idCicloLectivo !== 24,
            },
          ];
          return <OptionsModal options={options} />;
        },
      },
    ],
    [
      classes.descripcionContainer,
      handleDuplicate,
      handleEdit,
      handleRemoveAccion,
      handleView,
    ],
  );

  return columns;
};
