import React from 'react';
import { Typography, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { grey } from 'src/theme/colors';
import { AnyObject } from 'src/commons';

const useStyles = makeStyles({
  typography: {
    color: grey.medium,
    fontWeight: 'bold',
    verticalAlign: 'center',
    fontSize: 13,
  },
  schoold: {
    color: grey.medium,
    verticalAlign: 'center',
    fontSize: 13,
  },
});

export const Establishment = ({ establishmentData }: AnyObject) => {
  return (
    <Grid container style={{ padding: '15px 0', backgroundColor: '#fff' }}>
      <Grid container spacing={1} alignItems="center">
        <Grid item>
          <Typography className={useStyles().typography}>
            Establecimiento:
          </Typography>
        </Grid>
        <Grid item>
          <Typography className={useStyles().schoold}>
            {establishmentData?.nombre}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
