import { Valor } from '../types';

export const makeInitialDevolution = <T, K extends Valor = Valor>(
  pk: keyof T,
  valores: K[],
) => {
  const devolution: K[] = [];
  valores.forEach((v) => {
    if (!devolution.find((d) => d.idTipo === v.idTipo)) {
      devolution.push({
        ...v,
        evaluacion: 0,
        idDevolucionValor: undefined,
        idDevolucion: undefined,
      });
    }
  });
  return {
    [pk]: 0,
    observaciones: '',
    observacionesReferente: '',
    fechaAcap: '',
    createdAt: undefined,
    createdBy: undefined,
    valores: devolution,
  } as T;
};
