import React, { useMemo, useState } from 'react';
import { Loading, OptionsModal } from 'src/commons';
import { usePipDAO } from 'src/app/business';
import { AlumnoMovimiento } from 'src/app/models';
import { Pip } from 'src/app/models/calificaciones/Pip';

export const usePuenteInicialPrimariaColumns = (
  isAuthorizedToOpen: boolean,
  handleChangeAlumno: (
    row: AlumnoMovimiento,
    idPIP: Pip,
    button: string,
  ) => void,
  pipData: any,
  reFetch: any,
) => {
  const pipDao = usePipDAO();
  const [isLoading, setLoading] = useState(false);

  const columns = useMemo(() => {
    const openPip = async (idPIP: any) => {
      if (idPIP !== undefined) {
        setLoading(true);
        await pipDao.save({ idPIP, abierto: true });
        reFetch();
        setLoading(false);
      }
    };

    return [
      {
        id: 'nombre',
        label: 'Apellido y nombre',
        width: '20%',
        orderById: 'alumno.persona.apellido',
        render: (_: unknown, row: any) =>
          `${row?.alumno?.persona?.apellido}, ${row?.alumno?.persona?.nombre}`,
      },
      {
        id: 'documento',
        label: 'DNI',
        width: '10%',
        orderById: 'alumno.persona.documento',
        render: (_: unknown, row: any) => row?.alumno?.persona?.documento,
      },
      {
        id: 'estado',
        label: 'Estado',
        width: '10%',
        hideSortIcon: true,
        render: (_: unknown, row: any) => {
          const currentPip = pipData.find(
            (element: AlumnoMovimiento) =>
              element?.alumno?.idAlumno === row?.alumno?.idAlumno,
          );
          let status;

          if (currentPip === undefined) {
            status = 'No iniciado';
          } else if (currentPip?.abierto) {
            status = 'En proceso';
          } else if (!currentPip?.abierto) {
            status = 'Finalizado';
          }
          return <p>{status}</p>;
        },
      },
      {
        id: 'icon',
        label: 'Informe PIP',
        width: '10%',
        hideSortIcon: true,
        noSort: true,
        align: 'center',
        render: (_: unknown, row: any) => {
          const currentPip = pipData.find(
            (element: AlumnoMovimiento) =>
              element?.alumno?.idAlumno === row?.alumno?.idAlumno,
          );

          const idPIP = currentPip?.idPIP;

          const options = [];
          if (currentPip === undefined) {
            options.push({
              label: 'Cargar',
              onClick: () => handleChangeAlumno(row, currentPip, 'cargar'),
            });
          } else if (currentPip?.abierto) {
            options.push({
              label: 'Editar',
              onClick: () => handleChangeAlumno(row, currentPip, 'editar'),
            });
          } else {
            options.push({
              label: 'Visualizar',
              onClick: () => handleChangeAlumno(row, currentPip, 'visualizar'),
            });
          }
          if (
            currentPip !== undefined &&
            !currentPip?.abierto &&
            isAuthorizedToOpen
          ) {
            const optionAbrir = {
              label: 'Abrir informe',
              onClick: () => openPip(idPIP),
            };
            options.push(optionAbrir);
          }
          return isLoading ? <Loading /> : <OptionsModal options={options} />;
        },
      },
      {
        id: 'space',
        label: '',
        hideSortIcon: true,
        width: '50%',
        render: () => <></>,
      },
    ];
  }, [
    pipDao,
    reFetch,
    pipData,
    isAuthorizedToOpen,
    isLoading,
    handleChangeAlumno,
  ]);

  return columns;
};
