import React, { useEffect, useMemo } from 'react';
import { AnyObject, SimpleSelect } from '../../../commons';
import {
  SelectInput,
  SelectInputProps,
} from '../../../commons/components/SelectInput';
import { makeEntityLibbyCall } from '../hooks';

export interface EntitySelectProps extends AnyObject {
  value: string | string[] | number;
  handleChange: (value: any) => void;
  title?: string;
  placeholder?: string;
  labelKey?: string;
  valueKey?: string;
  name?: string;
  filter?: AnyObject;
  multiple?: boolean;
  disabled?: boolean;
  customStyleTitle?: any;
  orderBy?: string;
  direction?: string;
  onOptionsChange?: (options: any) => void;
  transformOptions?: (options: AnyObject[]) => AnyObject[];
  aspect?: string;
  methodName?: string;
  params?: any;
  enableFetch?: boolean;
  limit?: number;
  selectClassName?: string;
  customClassNameFormControl?: string;
}

export interface EntitySelectDefaultProps {
  labelKey: string;
  valueKey: string;
  title: string;
  placeholder: string;
  name: string;
  orderBy?: string;
  direction?: string;
}

const dummyFilter = {};

export const withSimpleSelect = (
  daoName: string,
  defaultProps: EntitySelectDefaultProps,
) => {
  const useHook = makeEntityLibbyCall<AnyObject>(daoName);
  return (props: EntitySelectProps) => {
    const {
      filter = dummyFilter,
      orderBy,
      direction = 'asc',
      onOptionsChange,
      transformOptions,
      aspect,
      methodName,
      params,
      enableFetch,
      limit = 200000,
      ...restProps
    } = props;

    // TODO: improve this
    const {
      working,
      data = [],
      recall,
    } = useHook({
      methodName: methodName || 'fetch',
      params: [],
      noAutoCall: true,
      aspect,
    });
    useEffect(() => {
      if (enableFetch === undefined || enableFetch === true) {
        if (recall) {
          const newParams = params || {
            filter,
            limit: limit,
            orderBy,
            direction,
          };
          recall(newParams);
        }
      }
    }, [filter, recall, direction, orderBy, params, enableFetch, limit]);
    useEffect(() => {
      if (onOptionsChange) {
        onOptionsChange(data);
      }
    }, [data, onOptionsChange]);
    const content = useMemo(() => {
      if (!transformOptions) {
        return data;
      }
      return transformOptions(data);
    }, [data, transformOptions]);
    return (
      <SimpleSelect
        content={content}
        loading={working}
        {...defaultProps}
        {...restProps}
      />
    );
  };
};

type EntitySelectInputProps = {
  filter?: AnyObject;
  orderBy?: string;
  direction?: string;
  onOptionsChange?: (options: any) => void;
  transformOptions?: (options: AnyObject[]) => AnyObject[];
  aspect?: string;
  methodName?: string;
  params?: any;
  enableFetch?: boolean;
  limit?: number;
} & Omit<SelectInputProps, 'options' | 'loading'>;

export const withSelectInput = (
  daoName: string,
  defaultProps: EntitySelectDefaultProps,
) => {
  const useHook = makeEntityLibbyCall<AnyObject>(daoName);
  return (props: EntitySelectInputProps) => {
    const {
      filter = dummyFilter,
      orderBy,
      direction = 'asc',
      onOptionsChange,
      transformOptions,
      aspect,
      methodName,
      params,
      enableFetch,
      limit = 200000,
      ...restProps
    } = props;

    // TODO: improve this
    const {
      working,
      data = [],
      recall,
    } = useHook({
      methodName: methodName || 'fetch',
      params: [],
      noAutoCall: true,
      aspect,
    });
    useEffect(() => {
      if (enableFetch === undefined || enableFetch === true) {
        if (recall) {
          const newParams = params || {
            filter,
            limit: limit,
            orderBy,
            direction,
          };
          recall(newParams);
        }
      }
    }, [filter, recall, direction, orderBy, params, enableFetch, limit]);
    useEffect(() => {
      if (onOptionsChange) {
        onOptionsChange(data);
      }
    }, [data, onOptionsChange]);
    const content = useMemo(() => {
      if (!transformOptions) {
        return data;
      }
      return transformOptions(data);
    }, [data, transformOptions]);
    return (
      <SelectInput
        options={content}
        loading={working}
        {...defaultProps}
        {...restProps}
      />
    );
  };
};
