import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { Sector } from '../../models/acap';

import { businessOptions } from '../businessOptions';

const DAO_NAME = 'sectores';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idSectores',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idSectores',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Sectores
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Sectores,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Sectores.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
    selectInput,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Sector>(DAO_NAME, optionsToUse);

// hooks
export const useSectoresFetchById = fetchById;
export const useSectoresLibbyCall = libbyCall;
export const useSectoresDAO = libbyDAO;
export const useSectoresLibbyFetch = libbyFetch;

// components/hoc
export const SectoresAutocomplete = entityAutocomplete;
export const SectoresCall = renderPropCall;
export const SectoresById = renderPropFetchById;
export const SectoresSelect = simpleSelect;
export const SectoresSelectInput = selectInput;
export const SectoresAccordionSelector = accordionSelector;
export const SectoresSelectedChips = selectedChips;

// context
// ByIdContext
export const useSectoresByIdContext = useByIdContext;
export const SectoresByIdProvider = ByIdProvider;
export const SectoresByIdContext = ByIdContext;
// GeneralContext
export const useSectoresContext = useGeneralContext;
export const SectoresProvider = GeneralProvider;
export const SectoresContext = GeneralContext;
