import { RESTDAO } from '@phinxlab/libby-rest-web';
import { AnyObject } from '../../../commons/types';

export interface GetQueryParam {
  key: string;
  value: string;
  method?: string;
}

export class CommonEndpointDAO extends RESTDAO {
  protected pk: string;

  constructor(name: string, pk?: string) {
    super(name, pk || `pk${name}`);

    this.pk = pk || `pk${name}`;
  }

  get(params: GetQueryParam[] = []) {
    let query = this.query();

    params.forEach((param) => {
      const { key, value, method = 'equals' } = param;
      query = query[method](key, value);
    });

    return query.run();
  }

  post(body: AnyObject) {
    const { [this.pk]: pk, ...restBody } = body;

    return this.save(restBody);
  }

  put(body: AnyObject) {
    return this.save({ [this.pk]: 'dummyPK', ...body });
  }

  delete(body: AnyObject) {
    return this.remove({ [this.pk]: 'dummyPK', ...body });
  }
}
