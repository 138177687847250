import { LibbyFetchDAO } from './LibbyFetchDAO';

export class MatriculaPersonaExternaDAO extends LibbyFetchDAO {
  constructor() {
    super('matricula_persona_externa', 'idMatriculaPersonaExterna');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByAlumnoId(idAlumno: string) {
    return this.query().equals('alumno', idAlumno).run();
  }
}
