import React from 'react';
import { SimpleSelect } from 'src/commons';

type SelectorItem = {
  descripcion: string;
  id: number | string;
};

const estrategiaCalfCuantitativa: Array<SelectorItem> = [
  { descripcion: '-', id: '-' },
  { descripcion: '1', id: '1' },
  { descripcion: '2', id: '2' },
  { descripcion: '3', id: '3' },
  { descripcion: '4', id: '4' },
  { descripcion: '5', id: '5' },
  { descripcion: '6', id: '6' },
  { descripcion: '7', id: '7' },
  { descripcion: '8', id: '8' },
  { descripcion: '9', id: '9' },
  { descripcion: '10', id: '10' },
];

const estrategiaCalfTutoriaEdi: Array<SelectorItem> = [
  { descripcion: '-', id: '-' },
  { descripcion: 'En Proceso', id: 'En Proceso' },
  { descripcion: 'Cumplimentado', id: 'Cumplimentado' },
  { descripcion: 'No cumplimentado', id: 'No cumplimentado' },
];

const estrategiaCalfCualitativa: Array<SelectorItem> = [
  { descripcion: '-', id: '-' },
  { descripcion: 'En Proceso', id: 'En Proceso' },
  { descripcion: 'Suficiente', id: 'Suficiente' },
  { descripcion: 'Avanzado', id: 'Avanzado' },
];

const estrategiaCalfMixta: Array<SelectorItem> = [
  { descripcion: 'Adeuda', id: 'Adeuda' },
  { descripcion: '6', id: '6' },
  { descripcion: '7', id: '7' },
  { descripcion: '8', id: '8' },
  { descripcion: '9', id: '9' },
  { descripcion: '10', id: '10' },
];

const estrategiaEspaciosEspeciales: Array<SelectorItem> = [
  { descripcion: 'Adeuda', id: 'Adeuda' },
  { descripcion: 'Cumplimentado', id: 'Cumplimentado' },
];

const estrategiaEspaciosEspecialesCalificaciones: Array<SelectorItem> = [
  { descripcion: '-', id: '-' },
  { descripcion: 'Adeuda', id: 'Adeuda' },
  { descripcion: 'Cumplimentado', id: 'Cumplimentado' },
];

const estrategiaCalAnualfTutoriaEdi: Array<SelectorItem> = [
  { descripcion: 'Cumplimentado', id: 'Cumplimentado' },
  { descripcion: 'No cumplimentado', id: 'No cumplimentado' },
];

const estrategiaRecuperacionDic: Array<SelectorItem> = (function* () {
  yield { descripcion: 'En Proceso', id: 'En Proceso' };
  let i = 6;
  while (i <= 10) {
    yield { descripcion: i + '', id: i + '' };
    i++;
  }
})();

const estrategiaRecuperacionFebMar: Array<SelectorItem> = (function* () {
  let i = 1;
  while (i <= 10) {
    yield { descripcion: i + '', id: i + '' };
    i++;
  }
})();

export const Selector =
  (content: Array<SelectorItem>) =>
  ({
    value,
    handleChange,
    isDisabled,
    name,
    periodo,
    optionsValidation,
  }: {
    value: number | string;
    handleChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
    isDisabled: boolean;
    name: string;
    periodo: number;
    optionsValidation: any;
  }) => {
    let options = content;
    if (name === 'calificacion' && optionsValidation && periodo === 8) {
      options = estrategiaCalfTutoriaEdi;
    } else if (name === 'calificacion' && optionsValidation && periodo === 3) {
      options = estrategiaCalfTutoriaEdi;
    } else if (name === 'calificacion' && optionsValidation && periodo === 7) {
      options = estrategiaCalfTutoriaEdi;
    } else if (name === 'calificacion' && optionsValidation && periodo === 1) {
      options = estrategiaCalfTutoriaEdi;
    } else if (name === 'calificacionAnual' && optionsValidation) {
      options = estrategiaCalAnualfTutoriaEdi;
    } else {
      options = content;
    }

    return (
      <SimpleSelect
        placeholder="Seleccione una opción"
        labelKey="descripcion"
        valueKey="id"
        content={options} // map with calificacion type in db
        value={value}
        name={name}
        handleChange={handleChange}
        disabled={isDisabled}
      />
    );
  };

export const SelectorPrimerBimestre = Selector(estrategiaCalfCualitativa);
export const SelectorSegundoBimestre = Selector(estrategiaCalfCuantitativa);
export const SelectorTercerBimestre = Selector(estrategiaCalfCualitativa);
export const SelectorCuartoBimestre = Selector(estrategiaCalfMixta);
export const SelectorRecuperacionDic = Selector([...estrategiaRecuperacionDic]);
export const SelectorRecuperacionFeb = Selector([
  ...estrategiaRecuperacionFebMar,
]);
