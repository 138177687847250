import React from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Grid, Typography } from '@material-ui/core';
import { LibbyObject, AnyObject } from 'src/commons';
import { InfoTable, SimpleSelect, MainInput } from 'src/commons/components';
import { grey } from 'src/theme/colors';
import { useLibbyFetch } from '../../../../../../../../../commons/hooks/useLibbyFetch';

const headerStyles = makeStyles(() => ({
  title: {
    fontWeight: 'normal',
    color: grey.medium,
    fontFamily: '"Open Sans", sans-serif',
    paddingBottom: '11px',
  },
  titleText: {
    color: grey.medium,
    fontWeight: 'normal',
    fontSize: '12px',
    fontFamily: '"Open Sans", sans-serif',
    marginBottom: '2px',
  },
  root: {
    color: grey.medium,
    fontSize: 13,
    fontFamily: '"Open Sans", sans-serif',
  },
  container: {
    paddingBottom: '15px',
  },
  containerTable: {
    paddingRight: '24px',
    paddingLeft: '24px',
    marginBottom: '20px',
  },
}));

const customStyles = {
  customStyleTitleSelect: { fontSize: 12, color: '#595959', marginBottom: 2 },
};

interface FormValuesTypes {
  turno: string;
  params: string;
}

interface ConfigurePassProps {
  libby: LibbyObject;
  studentSelect: AnyObject;
  handleChange: (value: React.ChangeEvent<{ value: unknown }>) => void;
  formValues: FormValuesTypes;
}

const ConfigurePassRaw = ({
  libby,
  studentSelect,
  formValues,
  handleChange,
}: ConfigurePassProps) => {
  const { data: studentTurn } = useLibbyFetch(libby, { daoName: 'turno' });

  const {
    alumno: {
      idAlumno,
      persona: { documento, nombre, apellido },
    },
    nivel: { descripcionNivel },
    localizacion: { descripcion },
  } = studentSelect;

  const rows = [
    {
      id: idAlumno,
      name: (
        <Box
          alignContent="center"
          justifyContent="center"
        >{`${nombre} ${apellido}`}</Box>
      ),
      document: documento,
      establishment: descripcion,
      level: descripcionNivel,
    },
  ];

  const classes = headerStyles();

  const column = [
    {
      id: 'name',
      label: 'Nombre y Apellido',
      width: '20%',
    },
    {
      id: 'document',
      label: 'Documento',
      width: '3%',
    },
    {
      id: 'establishment',
      label: 'Establecimiento de origen',
      width: '20%',
    },
    {
      id: 'level',
      label: 'Nivel',
      width: '3%',
    },
  ];

  return (
    <>
      <Grid container className={classes.container}>
        <Grid item xs={12} className={classes.containerTable}>
          <InfoTable rows={rows} columns={column} />
        </Grid>
        <Grid container style={{ padding: '24px' }}>
          <Typography variant="h4" className={classes.title}>
            Configuración de Pase
          </Typography>
          <Grid item xs={12} style={{ paddingBottom: '20px' }}>
            <SimpleSelect
              name="turno"
              title="Turno sugerido"
              placeholder="Seleccionar Turno"
              labelKey="descripcionTurno"
              valueKey="idTurno"
              content={studentTurn}
              handleChange={handleChange}
              value={formValues.turno}
              customStyleTitle={customStyles.customStyleTitleSelect}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography className={classes.titleText}>Observaciones</Typography>
            <MainInput
              rows={4}
              name="comment"
              handleChange={handleChange}
              placeholder="Escribe algun dato importante"
              value={formValues.params}
              fullWidth
              multiline
            />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const ConfigurePass = DatabaseConnector(ConfigurePassRaw)(
  'turno',
  'alumno_movimiento',
);
