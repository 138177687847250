import styled from 'styled-components';

export const CalendarContainer = styled.div`
  /* ~~~ container styles ~~~ */
  max-width: 350px;
  margin: auto;
  margin-top: 20px;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 3px;
  /* ~~~ navigation styles ~~~ */
  .react-calendar__navigation {
    display: flex;
    .react-calendar__navigation__label {
      font-family: Nunito;
      font-weight: bold;
      text-transform: uppercase;
    }
    .react-calendar__navigation__arrow {
      flex-grow: 0.333;
    }
  }
  /* ~~~ label styles ~~~ */
  .react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    background: radial-gradient(
          circle at 100% 100%,
          #ffffff 0,
          #ffffff 5px,
          transparent 5px
        )
        0% 0%/10px 10px no-repeat,
      radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 5px, transparent 5px)
        100% 0%/10px 10px no-repeat,
      radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 5px, transparent 5px)
        0% 100%/10px 10px no-repeat,
      radial-gradient(circle at 0 0, #ffffff 0, #ffffff 5px, transparent 5px)
        100% 100%/10px 10px no-repeat,
      linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 10px)
        calc(100% - 20px) no-repeat,
      linear-gradient(#ffffff, #ffffff) 50% 50% / calc(100% - 20px)
        calc(100% - 10px) no-repeat,
      linear-gradient(
        90deg,
        #1964ad 0%,
        #503a9b 25%,
        #a63485 50%,
        #c17543 75%,
        #f1de49 100%
      );
    border-radius: 10px;
    padding: 10px 0px 10px 0px;
    box-sizing: border-box;
  }
  .react-calendar__month-view__weekdays__weekday {
    display: flex;
    align-items: center;
    justify-content: center;
    & > abbr {
      font-family: Nunito;
      font-size: 13px;
      text-decoration: none !important;
    }
  }
  /* ~~~ button styles ~~~ */
  button {
    margin: 3px;
    background-color: #ffffff;
    border: 0;
    border-radius: 3px;
    color: black;
    padding: 5px 0;
    &:active {
      background-color: #ededed;
    }
    &.react-calendar__month-view__days__day {
      width: 27px;
      border-radius: 50%;
      align-self: center;
      justify-self: center;
      & > abbr {
        font-family: Nunito;
      }
    }
  }
  /* ~~~ day grid styles ~~~ */
  .react-calendar__month-view__days {
    display: grid !important;
    grid-template-columns: repeat(7, 1fr);

    .react-calendar__tile {
      max-width: initial !important;
    }
    .react-calendar__tile--range {
      background-color: #d7d7d7;
    }
  }
  /* ~~~ neighboring month & weekend styles ~~~ */
  .react-calendar__month-view__days__day--neighboringMonth {
    opacity: 0.7;
    color: #c8c8c8;
  }
  .react-calendar__month-view__days__day--weekend {
    color: #c8c8c8;
  }
  /* ~~~ other view styles ~~~ */
  .react-calendar__year-view__months,
  .react-calendar__decade-view__years,
  .react-calendar__century-view__decades {
    display: grid !important;
    grid-template-columns: 20% 20% 20% 20% 20%;
    &.react-calendar__year-view__months {
      grid-template-columns: 33.3% 33.3% 33.3%;
    }

    .react-calendar__tile {
      max-width: initial !important;
    }
  }
`;
