import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class Habilidadesv2DAO extends LibbyFetchDAO {
  constructor() {
    super('orientaciones.habilidades', 'idHabilidad');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
