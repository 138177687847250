import { LibbyFetchDAO } from './LibbyFetchDAO';

export class InstrumentosDeEvaluacionDAO extends LibbyFetchDAO {
  constructor() {
    super('instrumentos_de_evaluacion', 'idInstrumentosDeEvaluacion');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
