import React, { useCallback, useMemo } from 'react';
import { Grid, Typography, Button, makeStyles } from '@material-ui/core';
import customFormDialog from 'src/commons/services/customFormDialog';
import { NivelesForm } from './NivelesForm';
import { RESTConnection } from '@phinxlab/libby-rest-web';
import { FormContextProvider } from 'src/lib/templates';
import { useReloginDAO } from 'src/app/business';
import { DatabaseConnector } from '@phinxlab/libby-core';

interface NivelesModalProps {
  localizacion: string;
  ure: string;
  libby: any;
}

const useStyles = makeStyles((theme) => ({
  closeButton: {
    marginTop: theme.spacing(-8),
    marginRight: theme.spacing(4),
    position: 'relative',
  },
  modalContainer: {
    padding: theme.spacing(2),
  },
}));

const handleModalClose = async () => {
  await customFormDialog.handleCancel();
};

const template = {
  name: 'Niveles | Direccion de Area',
  template: [
    {
      name: 'nivel',
      type: {
        name: 'string',
      },
    },
  ],
};

const initialValues = {
  nivel: '',
};

export const NivelesModalRaw = function ({
  localizacion,
  ure,
  libby,
}: NivelesModalProps) {
  const classes = useStyles();
  const reloginDAO = useReloginDAO();

  const onSubmit = useCallback(
    async ({ nivel }) => {
      const res = await reloginDAO.selectRole(ure, nivel, localizacion);
      const user = { ...res.user, isGuest: res.isGuest };
      RESTConnection.defineHeader('x-chino-token', res.token);
      libby.session.notify('New session', user, res.token);
      await customFormDialog.handleCancel();
    },
    [localizacion, reloginDAO, libby, ure],
  );

  return (
    <Grid
      container
      classes={{
        root: classes.modalContainer,
      }}
    >
      <Grid container>
        <Grid item container style={{ paddingLeft: 35, marginBottom: 30 }}>
          <Typography variant="h3">Seleccione el nivel</Typography>
        </Grid>
        <Grid item container justify="flex-end">
          <Button
            classes={{
              root: classes.closeButton,
            }}
            onClick={handleModalClose}
          >
            X
          </Button>
        </Grid>
      </Grid>
      <FormContextProvider
        initialValues={initialValues}
        onSubmit={onSubmit}
        template={template}
      >
        <NivelesForm />
      </FormContextProvider>
    </Grid>
  );
};

export const NivelesModal = DatabaseConnector(NivelesModalRaw)();
