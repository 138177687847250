import { useCallback, useEffect, useState } from 'react';

const initialState = {
  selectsDisabled: {
    espacioCurricular: true,
    cicloLectivo: true,
    año: true,
    fecha: true,
    valoracion: true,
    calificacion: true,
    participoInstanciasApoyo: true,
    periodo: true,
  },
  calificacionContent: 'calificacionesPalabras',
};

const useMotivoSelect = () => {
  const [selectStatus, setSelectStatus] = useState<any>(initialState);
  const [motivo, setMotivo] = useState('');
  const motivoSelect = useCallback(() => {
    if (motivo === '') {
      setSelectStatus(initialState);
    } else if (
      motivo === 'E.C. Pendientes' ||
      motivo === 'Pendiente por Cambio de Plan de Estudios'
    ) {
      setSelectStatus({
        selectsDisabled: {
          espacioCurricular: false,
          cicloLectivo: false,
          año: false,
          fecha: false,
          valoracion: true,
          calificacion: false,
          participoInstanciasApoyo: true,
          periodo: true,
        },
        calificacionContent: 'calificacionesNumericas',
      });
    } else if (motivo === 'Plan de Fortalecimiento de los Aprendizajes') {
      setSelectStatus({
        selectsDisabled: {
          espacioCurricular: false,
          cicloLectivo: false,
          año: false,
          fecha: true,
          valoracion: false,
          calificacion: false,
          participoInstanciasApoyo: false,
          periodo: false,
        },
        calificacionContent: 'calificacionesPalabras',
      });
    }
  }, [motivo]);

  useEffect(() => {
    motivoSelect();
  }, [motivoSelect]);

  return { selectStatus, setMotivo };
};

export default useMotivoSelect;
