import React from 'react';
import moment from 'moment';
import { useMemo } from 'react';
import { useHistory } from 'react-router';
import {
  Button,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  makeStyles,
  withStyles,
} from '@material-ui/core';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import TuneIcon from '@material-ui/icons/Tune';
import { SimpleSelect } from 'src/commons';
import {
  DevolucionOrganizacion,
  GruposInscripciones,
  OfertaGrupo,
} from 'src/app/models';
import { getFechasByDays } from '../../AcapEstudiante/utils/helperFechas';
import { formatDateTooltip, AcapDate } from 'src/utils/formatDateTooltip';

const useStyles = makeStyles({
  disabledButton: {
    color: ' #000000',
  },
  tooltipItem: {
    marginTop: '3px',
    marginBottom: '3px',
  },
});

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}))(Tooltip);

export const usePresentismoAcapColumns = (
  estadosPresente: any,
  handleChangeAlumnos: any,
  handleOpenModal: any,
  devoluciones: DevolucionOrganizacion[],
  isInsideSchool: boolean,
  goToDevoluciones: () => void,
  baseRow?: OfertaGrupo | null,
  currentDate?: string,
) => {
  const history = useHistory();
  const classes = useStyles();
  const columns = useMemo(() => {
    return [
      {
        id: 'apellidoNombre',
        label: 'Apellido y Nombre',
        render: (value: any, row: any) => {
          return (
            <Grid container direction="row" alignItems="center">
              <Typography>
                {`${row.alumnoMovimiento.alumno.persona.apellido}, ${row.alumnoMovimiento.alumno.persona.nombre}`}
              </Typography>
              <IconButton onClick={() => handleOpenModal(row)}>
                <AccountCircleIcon color="primary" />
              </IconButton>
            </Grid>
          );
        },
        width: '12%',
        orderById: 'nombre',
      },
      {
        id: 'documento',
        label: 'Documento',
        render: (value: any, row: any) =>
          row?.alumnoMovimiento?.alumno?.persona?.documento,
        width: '12%',
        orderById: 'alumnoMovimiento.alumno.persona.documento',
      },
      {
        id: 'asistencia',
        label: 'Asistencia',
        hideSortIcon: true,
        render: (value: any, row: any) => {
          return (
            <SimpleSelect
              value={row?.presentismo}
              handleChange={(e) => handleChangeAlumnos(row, e?.target?.value)}
              content={estadosPresente}
              placeholder="seleccione estado presente"
              valueKey="idEstadoPresente"
              labelKey="descripcion"
              selectStyles={
                row?.presentismo === 2
                  ? { color: 'red' }
                  : row?.presentismo === 1
                  ? { color: 'green' }
                  : row?.presentismo === 6
                  ? { color: '#faad14' }
                  : row?.presentismo === 3
                  ? { color: 'black' }
                  : row?.presentismo === 7
                  ? { color: 'black' }
                  : {}
              }
            />
          );
        },
        width: '12%',
      },
      {
        id: 'cantidadDeDevoluciones',
        label: 'Cantidad de Devoluciones',
        hideSortIcon: true,
        render: (_: unknown, row: Acap.Referente.RowOfertaGrupo) => {
          const encuentros = getFechasByDays(
            row.ofertaGrupo.ofertaTurno.oferta.fechaInicio,
            row.ofertaGrupo.ofertaTurno.oferta.fechaFin,
            row.ofertaGrupo.jornadas.map((jornada) => jornada.cupo),
          );
          const devs = devoluciones.filter(
            (dev) =>
              dev.grupoInscripcion.idGrupoInscripcion ===
              row.idGrupoInscripcion,
          );

          let devolucionesFechasTooltip = () => {
            let devolucioneNumber: JSX.Element[] | string | AcapDate[];
            if (devs[0]) {
              devolucioneNumber = devs.map((dev) => {
                const formattedDate = formatDateTooltip(dev.fechaAcap);
                return <p className={classes.tooltipItem}>{formattedDate}</p>;
              });
            } else {
              devolucioneNumber = 'No hay devoluciones';
            }
            return devolucioneNumber;
          };

          let tooltipMessage = devolucionesFechasTooltip();

          return (
            <LightTooltip
              title={
                <React.Fragment>
                  <Typography color="primary">
                    Devoluciones Realizadas
                  </Typography>
                  {tooltipMessage}
                </React.Fragment>
              }
              placement="right"
            >
              <Button className={classes.disabledButton}>
                {devs.length}/{encuentros.length}
              </Button>
            </LightTooltip>
          );
        },
        width: '12%',
      },
      {
        id: 'devoluciones',
        label: 'Devoluciones',
        hideSortIcon: true,
        render: (_: unknown, row: GruposInscripciones) => {
          const onClick = !isInsideSchool
            ? () => {
                const url = `/establecimientos/organizacion/devolucion/${
                  row.ofertaGrupo.idOfertaGrupo
                }-${row.idGrupoInscripcion}/${moment(currentDate).format(
                  'YYYY-MM-DD',
                )}`;
                history.push(url, { row: baseRow });
              }
            : goToDevoluciones;
          return (
            <IconButton onClick={onClick}>
              <TuneIcon color="primary" />
            </IconButton>
          );
        },
        width: '5%',
      },
    ];
  }, [
    handleOpenModal,
    estadosPresente,
    handleChangeAlumnos,
    devoluciones,
    history,
    currentDate,
    baseRow,
    isInsideSchool,
    classes.disabledButton,
    classes.tooltipItem,
    goToDevoluciones,
  ]);

  return columns;
};
