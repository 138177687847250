import React from 'react';
import { useDiscipSimTempPlanFetchById } from '../../../../../../../../app/business';
import {
  DiscipSimTempPlan,
  NewSimpDiscipPlan,
} from '../../../../../../../../app/models';
import { useDisciplinarComunContext } from './context';

const initialValues: NewSimpDiscipPlan = {
  ensenanza: {
    propositosDeEnsenanzas: [],
    adicionales: [],
  },
  objetivosDeAprendizaje: {
    objetivosDeAprendizaje: [],
    adicionales: [],
  },
  indicadoresDeEvaluacion: {
    indicadoresDeEvaluacion: [],
    adicionales: [],
  },
  primerBimestre: {
    primerBimestre: [
      {
        habilidad: [],
        contenidosEspecificos: [],
        recursos: [],
        intrumentosDeEvaluacion: [],
        actividad: '',
        descripcion: '',
        horasCatedra: '',
        seccion: [],
      },
    ],
  },
  segundoBimestre: {
    segundoBimestre: [
      {
        habilidad: [],
        contenidosEspecificos: [],
        recursos: [],
        intrumentosDeEvaluacion: [],
        actividad: '',
        descripcion: '',
        horasCatedra: '',
        seccion: [],
      },
    ],
  },
  tercerBimestre: {
    tercerBimestre: [
      {
        habilidad: [],
        contenidosEspecificos: [],
        recursos: [],
        intrumentosDeEvaluacion: [],
        actividad: '',
        descripcion: '',
        horasCatedra: '',
        seccion: [],
      },
    ],
  },
  cuartoBimestre: {
    cuartoBimestre: [
      {
        habilidad: [],
        contenidosEspecificos: [],
        recursos: [],
        intrumentosDeEvaluacion: [],
        actividad: '',
        descripcion: '',
        horasCatedra: '',
        seccion: [],
      },
    ],
  },
  bibliografia: { bibliografia: [] },
};

export type childrenFunc = ({
  template,
  working,
  initialValues,
}: {
  template?: DiscipSimTempPlan;
  working: boolean;
  initialValues: NewSimpDiscipPlan;
}) => JSX.Element;

export interface PlanificacionDisciplinarNewProps {
  children: childrenFunc;
  templateId: string | number;
}

export const PlanificacionDisciplinarNew = ({
  children,
  templateId,
}: PlanificacionDisciplinarNewProps) => {
  const { data: template, working } = useDiscipSimTempPlanFetchById(templateId);
  const { working: _working } = useDisciplinarComunContext();

  return (
    <>
      {children({
        template,
        initialValues: JSON.parse(JSON.stringify(initialValues)),
        working: _working || working,
      })}
    </>
  );
};
