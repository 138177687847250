import { CommonEndpointDAO } from './CommonEndpointDAO';

export class InscripcionAlumnoCustomDAO extends CommonEndpointDAO {
  constructor() {
    super('inscripcion_alumno_custom', 'pkInscripcionAlumnoCustom');
  }

  async matricular(alumnos: Array<Alumnos>) {
    return await this.post({ alumnos });
  }

  desmatricular(data: InscriptoAlumno) {
    return this.remove({ ...data, pkInscripcionAlumnoCustom: 5 });
  }
}

interface InscriptoAlumno {
  idInscripcionAlumno: number;
  isDeleted: boolean;
  bajaMotivo: string;
  descripcionBajaMotivo: string;
}

interface Alumnos {
  idInscripcionAlumno?: string;
  idAlumno?: string;
  idAnio: number;
  descripcionAnio: string;
  nombre: string;
  documento: string;
  idCondicion: string;
  idTurno: string;
  idNivel: number;
  idSeccion: string | null;
  selectedSeccion: string | null;
  idSeccionDestino?: string | null;
}
