import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { EstadoAlumno } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'estado_alumno';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEstadoAlumno',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEstadoAlumno',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EstadoAlumno
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EstadoAlumno,
      hoc: { ...defaultOptions.hoc, ...businessOptions.EstadoAlumno.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EstadoAlumno>(DAO_NAME, optionsToUse);

// hooks
export const useEstadoAlumnoFetchById = fetchById;
export const useEstadoAlumnoLibbyCall = libbyCall;
export const useEstadoAlumnoDAO = libbyDAO;
export const useEstadoAlumnoLibbyFetch = libbyFetch;

// components/hoc
export const EstadoAlumnoAutocomplete = entityAutocomplete;
export const EstadoAlumnoCall = renderPropCall;
export const EstadoAlumnoById = renderPropFetchById;
export const EstadoAlumnoSelect = simpleSelect;
export const EstadoAlumnoAccordionSelector = accordionSelector;
export const EstadoAlumnoSelectedChips = selectedChips;

// context
// ByIdContext
export const useEstadoAlumnoByIdContext = useByIdContext;
export const EstadoAlumnoByIdProvider = ByIdProvider;
export const EstadoAlumnoByIdContext = ByIdContext;
// GeneralContext
export const useEstadoAlumnoContext = useGeneralContext;
export const EstadoAlumnoProvider = GeneralProvider;
export const EstadoAlumnoContext = GeneralContext;
