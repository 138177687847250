import { useEffect, useState } from 'react';
import { useInscripcionAlumnoLibbyCall } from 'src/app/business';
import { InscripcionAlumno } from 'src/app/models';
import { useLegajoLayout } from '..';

export const useDatosInstitucionFetch = () => {
  const { params } = useLegajoLayout();
  const {
    data: datosInstitucion,
    recall: recallDatosInstitucion,
    working,
  } = useInscripcionAlumnoLibbyCall({
    methodName: 'getByAlumnoId',
    params,
    noAutoCall: true,
    aspect: 'legajoAlumno_datosPersonales',
  });

  useEffect(() => {
    recallDatosInstitucion(params);
  }, [recallDatosInstitucion, params]);

  return {
    data: {
      datosInstitucion: {
        data: datosInstitucion?.[0],
        loading: working,
      },
    },
    recall: {
      recallDatosInstitucion: () => recallDatosInstitucion(params),
    },
  };
};
