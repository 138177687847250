import { Grid, makeStyles } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import { FormDatePicker, FormInput, useFormContext } from 'src/lib/templates';
import { CustomFormAutocomplete as FormAutocomplete } from '../components';
import { useLegajoData } from '../context';

const useStyles = makeStyles({
  root: {
    marginTop: '32px',
    marginBottom: '32px',
    width: '100%',
    background: '#F5F5F5',
    minHeight: '300px',
    color: '#595959',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > span': {
      fontSize: '20px',
      margin: '0.83em 0',
    },
  },
  container: {
    padding: '32px 20px',
    fontFamily: 'Open Sans',
    fontWeight: 'normal',
  },
  font: {
    fontFamily: 'Open Sans',
  },
  grayContainer: {
    background: '#F5F5F5',
    margin: '3% 15px 0 15px',
    borderRadius: '5px',
  },
  headerGrayContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '15px',
    '& > span': {
      fontSize: '20px',
      fontFamily: 'Open Sans',
      color: '#595959',
    },
  },
  whiteSpace: {
    height: 120,
  },
  datePicker: {
    paddingLeft: 0,
  },
});

type TipoDocumento = {
  name: string;
  value: number;
};

const optionsTipoSituacionLegal: TipoDocumento[] = [
  { name: 'Certificados', value: 1 },
  { name: 'Notificaciones', value: 2 },
  { name: 'Otros', value: 3 },
];

export const SituacionLegal: FunctionComponent = () => {
  const classes = useStyles();
  const { form } = useFormContext();
  const { isHistoric } = useLegajoData();

  return (
    <>
      <Grid container spacing={4} direction="column">
        <Grid className={classes.grayContainer} item spacing={4}>
          <Grid item>
            <Grid item xs={12} className={classes.headerGrayContainer}>
              <span>El estudiante está privado de su libertad (opcional)</span>
            </Grid>
          </Grid>
          <Grid container spacing={3}>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormInput
                disabled={isHistoric}
                placeholder="Describí el tipo de situación"
                label="Tipo de situación"
                name="situacionLegal.datosPrincipales.tipoSituacion"
                form={form}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormDatePicker
                disabled={isHistoric}
                label="Fecha de vigencia"
                name="situacionLegal.datosPrincipales.fechaVigencia"
                form={form}
                className={classes.datePicker}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormAutocomplete
                disabled={isHistoric}
                placeholder="Seleccioná tipo de documento"
                label="Tipo de documento"
                name="situacionLegal.datosPrincipales.tipoDocumento"
                form={form}
                options={optionsTipoSituacionLegal || []}
                optionLabelKey="name"
                optionToValue={(option) => option?.value}
                valueToOption={(value, options) =>
                  options.find((option: any) => option?.value === value)
                }
                getOptionSelected={(option: any, value: any) =>
                  option?.value === value.value
                }
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormInput
                disabled={isHistoric}
                label="Nombre del centro de encierro"
                placeholder="Escribí el nombre del centro de encierro"
                name="situacionLegal.datosPrincipales.nombreCentro"
                form={form}
              />
            </Grid>
            <Grid item lg={6} md={6} sm={12} xs={12}>
              <FormInput
                disabled={isHistoric}
                label="Descripción adicional"
                placeholder=" Escribí cualquier comentario adicional"
                name="situacionLegal.datosPrincipales.descripcionAdicional1"
                form={form}
                inputProps={{ multiline: true }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container spacing={4} direction="column">
          <Grid item className={classes.grayContainer}>
            <Grid container>
              <Grid item xs={12} className={classes.headerGrayContainer}>
                <span>
                  El estudiante posee una orden de restricción (opcional)
                </span>
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item lg={6} md={6} sm={12} xs={12}>
                <FormInput
                  disabled={isHistoric}
                  label="Descripción adicional"
                  placeholder=" Escribí cualquier comentario adicional"
                  name="situacionLegal.datosAdicionales.descripcionAdicional2"
                  form={form}
                  inputProps={{ multiline: true }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.whiteSpace}>
        <br />
      </Grid>
    </>
  );
};
