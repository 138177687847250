import React, { useState } from 'react';
import {
  EspaciosSimpDiscipPlan,
  EspaciosArealPlan,
  EspacioCurricular,
  EspaciosDiscipOrientPlan,
  BimestreOrientPlan,
  Seccion,
  EspaciosInterdiscipPlan,
} from '../../../../../../app/models';
import { InfoTable } from '../../../../../../commons/components/InfoTable';
import {
  useEspaciosArealPlanLibbyCall,
  useEspaciosDiscipOrientPlanLibbyCall,
  useEspaciosSimpDiscipPlanLibbyCall,
  useEspaciosInterdiscipPlanLibbyCall,
} from '../../../../../../app/business';
import { useActividadTableColumns } from './hooks';
import { makeEntityByEspacio } from '../../Planificacion/forms/commons/hooks';
import {
  periodos,
  Periodo,
} from '../../Planificacion/forms/ArealInterareal/tabs/Actividad';

export interface ActividadTableProps {
  espacioCurricular: EspacioCurricular | undefined;
  secciones: Seccion[] | undefined;
  selectValues: {
    tipoPlanificacion: string;
    seccion: string;
    bimestre: string;
  };
  search: any;
}

type Row = {
  id: string;
  nombre: string;
  tipo: { id: string; descripcion: string };
  seccion: string;
  bimestre: string;
};

type Actividad = {
  nombre: string;
  periodo: Array<string>;
  recursos: Array<string>;
  descripcion: string;
  horasCatedra: string;
};

const filterEspacio = (espacio?: EspacioCurricular) => ({
  filter: {
    espacio: [
      {
        path: 'id_espacio_curricular',
        value: espacio?.idEspacioCurricular || '',
      },
    ],
  },
  limit: 20000,
});
const useEspaciosArealPlanDAO = makeEntityByEspacio<EspaciosArealPlan[]>(
  useEspaciosArealPlanLibbyCall,
  filterEspacio,
  undefined,
  'limit_province',
);

const useEspaciosInterdisciplinarPlanDAO = makeEntityByEspacio<
  EspaciosInterdiscipPlan[]
>(
  useEspaciosInterdiscipPlanLibbyCall,
  filterEspacio,
  undefined,
  'limit_province',
);

const useEspaciosDisciplinarOrientadaPlanDAO = makeEntityByEspacio<
  EspaciosDiscipOrientPlan[]
>(
  useEspaciosDiscipOrientPlanLibbyCall,
  filterEspacio,
  undefined,
  'limit_province',
);

const useEspaciosDisciplinarPlanDAO = makeEntityByEspacio<
  EspaciosSimpDiscipPlan[]
>(
  useEspaciosSimpDiscipPlanLibbyCall,
  filterEspacio,
  undefined,
  'limit_province',
);

const bimestres = [
  'primerBimestre',
  'segundoBimestre',
  'tercerBimestre',
  'cuartoBimestre',
];

export const ActividadTable = ({
  espacioCurricular,
  secciones,
  selectValues,
  search,
}: ActividadTableProps) => {
  const columns = useActividadTableColumns();
  const { data: espaciosArealplan = [], working: arealWorking } =
    useEspaciosArealPlanDAO(espacioCurricular);
  const {
    data: espaciosInterdisciplinarplan = [],
    working: interdisciplinarWorking,
  } = useEspaciosInterdisciplinarPlanDAO(espacioCurricular);
  const { data: espaciosDiscipOrient = [], working: orientadaWorking } =
    useEspaciosDisciplinarOrientadaPlanDAO(espacioCurricular);
  const { data: espaciosDisciplinar = [], working: disciplinarWorking } =
    useEspaciosDisciplinarPlanDAO(espacioCurricular);
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('nombre');

  const normalizeOrientada: Array<Row> = [];
  espaciosDiscipOrient?.forEach(
    ({ discipOrientPlan }: EspaciosDiscipOrientPlan, index: number) => {
      bimestres.forEach((name: string, indexB: number) => {
        // @ts-ignore
        discipOrientPlan[name][name].forEach(
          ({ actividad, seccion }: BimestreOrientPlan, _index: number) =>
            seccion.forEach((item: string) =>
              normalizeOrientada.push({
                id: `${discipOrientPlan.idDiscipOrientPlan}-${item}-orientada-${
                  index + _index
                }-${name}`,
                nombre: actividad,
                tipo: {
                  id: 'disciplinarOrientado',
                  descripcion: 'Disciplinar Orientado',
                },
                seccion:
                  secciones?.find((sec: Seccion) => sec.idSeccion === item)
                    ?.nombreSeccion || '',
                bimestre:
                  periodos.find(
                    (_periodo: Periodo) => indexB + 1 === _periodo.id,
                  )?.name || '',
              }),
            ),
        );
      });
    },
  );

  const normalizeAreal: Array<Row> = [];
  espaciosArealplan?.forEach(
    ({ arealPlan }: EspaciosArealPlan, index: number) => {
      arealPlan?.actividad?.actividades.map((act: Actividad, _index: number) =>
        arealPlan?.informacionGeneral?.secciones?.forEach((item: string) =>
          act?.periodo?.forEach((_item: string) =>
            normalizeAreal.push({
              id: `${item}-areal-${arealPlan.idArealPlan}-${
                index + _index
              }-${_item}`,
              nombre: act.nombre,
              tipo: { id: 'interareal', descripcion: 'Areal/Interareal' },
              seccion:
                secciones?.find((sec: Seccion) => sec.idSeccion === item)
                  ?.nombreSeccion || '',
              bimestre:
                periodos.find(
                  (_periodo: Periodo) => _item === _periodo.id.toString(),
                )?.name || '',
            }),
          ),
        ),
      );
    },
  );

  const normalizeDisciplinar: Array<Row> = [];
  espaciosDisciplinar?.forEach(
    ({ simpDiscipPlan }: EspaciosSimpDiscipPlan, index: number) => {
      bimestres.forEach((name: string, indexB: number) => {
        // @ts-ignore
        simpDiscipPlan[name][name].forEach(
          ({ actividad, seccion }: BimestreOrientPlan, _index: number) =>
            seccion?.forEach((item: string) =>
              normalizeDisciplinar.push({
                id: `${simpDiscipPlan.idSimpDiscipPlan}-${item}-disciplinar-${
                  index + _index
                }-${name}`,
                nombre: actividad,
                tipo: { id: 'disciplinar', descripcion: 'Disciplinar' },
                seccion:
                  secciones?.find((sec: Seccion) => sec.idSeccion === item)
                    ?.nombreSeccion || '',
                bimestre:
                  periodos.find(
                    (_periodo: Periodo) => indexB + 1 === _periodo.id,
                  )?.name || '',
              }),
            ),
        );
      });
    },
  );

  const normalizeInterdisciplinar: Array<Row> = [];
  espaciosInterdisciplinarplan?.forEach(
    ({ interdiscipPlan }: EspaciosInterdiscipPlan, index: number) => {
      interdiscipPlan?.actividad?.actividades?.map(
        (act: Actividad, _index: number) =>
          interdiscipPlan?.informacionGeneral?.secciones?.forEach(
            (item: string) =>
              act?.periodo?.forEach((_item: string) =>
                normalizeInterdisciplinar.push({
                  id: `${item}-interdisciplinar-${
                    interdiscipPlan.idInterdiscipPlan
                  }-${index + _index}-${_item}`,
                  nombre: act.nombre,
                  tipo: {
                    id: 'interdisciplinar',
                    descripcion: 'Interdisciplinar',
                  },
                  seccion:
                    secciones?.find((sec: Seccion) => sec.idSeccion === item)
                      ?.nombreSeccion || '',
                  bimestre:
                    periodos.find(
                      (_periodo: Periodo) => _item === _periodo.id.toString(),
                    )?.name || '',
                }),
              ),
          ),
      );
    },
  );

  let rowsTable: Array<Row> = [
    ...normalizeAreal,
    ...normalizeOrientada,
    ...normalizeDisciplinar,
    ...normalizeInterdisciplinar,
  ];

  if (selectValues.seccion) {
    rowsTable = [...normalizeAreal, ...normalizeOrientada].filter(
      (row: Row) =>
        row.seccion ===
        secciones?.find(
          (sec: Seccion) => sec.idSeccion === selectValues.seccion,
        )?.nombreSeccion,
    );
  }

  if (selectValues.bimestre) {
    rowsTable = rowsTable.filter(
      (row: Row) =>
        row.bimestre ===
        periodos?.find(
          (periodo: Periodo) =>
            periodo.id.toString() === selectValues.bimestre.toString(),
        )?.name,
    );
  }

  if (selectValues.tipoPlanificacion) {
    rowsTable = rowsTable.filter(
      (row: Row) => row.tipo.id === selectValues.tipoPlanificacion,
    );
  }
  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setDirection(newDirection);
    setOrderBy(newOrderBy);
  };

  rowsTable.sort((a, b) => {
    let result = 0;
    if (direction === 'asc') {
      // @ts-ignore
      result = a[orderBy] > b[orderBy] ? 1 : a[orderBy] < b[orderBy] ? -1 : 0;
    } else {
      // @ts-ignore
      result = a[orderBy] < b[orderBy] ? 1 : a[orderBy] > b[orderBy] ? -1 : 0;
    }
    return result;
  });

  return (
    <>
      <InfoTable
        rows={
          espacioCurricular
            ? rowsTable.filter((row: any) =>
                row.nombre.toLowerCase().includes(search.toLowerCase()),
              )
            : []
        }
        columns={columns}
        working={
          arealWorking ||
          orientadaWorking ||
          interdisciplinarWorking ||
          disciplinarWorking
        }
        rowIdKey="id"
        direction={direction}
        orderBy={orderBy}
        onSortChange={handleRequestSort}
        /*  onBottomScroll={fetchMore} */
      />
    </>
  );
};
