import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { ConvivenciaCustom } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'convivencia_custom';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idConvivenciaCustom',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idConvivenciaCustom',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.ConvivenciaCustom
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.ConvivenciaCustom,
      hoc: { ...defaultOptions.hoc, ...businessOptions.ConvivenciaCustom.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<ConvivenciaCustom>(DAO_NAME, optionsToUse);

// hooks
export const useConvivenciaCustomFetchById = fetchById;
export const useConvivenciaCustomLibbyCall = libbyCall;
export const useConvivenciaCustomDAO = libbyDAO;
export const useConvivenciaCustomLibbyFetch = libbyFetch;

// components/hoc
export const ConvivenciaCustomAutocomplete = entityAutocomplete;
export const ConvivenciaCustomCall = renderPropCall;
export const ConvivenciaCustomById = renderPropFetchById;
export const ConvivenciaCustomSelect = simpleSelect;
export const ConvivenciaCustomAccordionSelector = accordionSelector;
export const ConvivenciaCustomSelectedChips = selectedChips;

// context
// ByIdContext
export const useConvivenciaCustomByIdContext = useByIdContext;
export const ConvivenciaCustomByIdProvider = ByIdProvider;
export const ConvivenciaCustomByIdContext = ByIdContext;
// GeneralContext
export const useConvivenciaCustomContext = useGeneralContext;
export const ConvivenciaCustomProvider = GeneralProvider;
export const ConvivenciaCustomContext = GeneralContext;
