import React from 'react';
import {
  MainButton,
  SimpleSelect,
  FilterTablesExternalTypesProps,
  FilterTablesExternalContentTypes,
  MainInput,
} from 'src/commons';
import { Grid } from '@material-ui/core';
import { grey } from 'src/theme/colors';
import { useDateValidator } from 'src/commons/hooks/useDateValidator';

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  searchGrid: { marginTop: 10 },
  containerStyles: { marginTop: 23 },
  inputSearch: { marginBottom: 12 },
  button: { padding: 10 },
};

export const FilterTablesExternal = ({
  content,
  titleMainButton,
  handleOnClick,
}: FilterTablesExternalTypesProps) => {
  const enabled = useDateValidator('Alumnos');
  return (
    <Grid
      container
      justify="flex-start"
      alignItems="center"
      spacing={3}
      style={customStyles.containerStyles}
    >
      {content.map((e: any) =>
        e.map((t: FilterTablesExternalContentTypes) =>
          !t.input ? (
            <Grid item xs={2}>
              <SimpleSelect
                {...t}
                handleChange={t.handleChange}
                content={t.content}
                value={t.value}
                customStyleTitle={customStyles.customStyleTitleSelect}
              />
            </Grid>
          ) : (
            <Grid
              container
              xs={8}
              justify="flex-end"
              spacing={3}
              alignItems="flex-end"
              style={customStyles.searchGrid}
            >
              <Grid item xs={3}>
                {titleMainButton && (
                  <MainButton
                    title={titleMainButton}
                    handleOnClick={handleOnClick}
                    customStyle={customStyles.button}
                    disabled={!enabled}
                  />
                )}
              </Grid>

              <Grid
                container
                xs={4}
                justify="flex-end"
                spacing={2}
                alignItems="flex-end"
              >
                <Grid item xs={12}>
                  <MainInput
                    type="search"
                    handleChange={t.handleChange}
                    placeholder="Buscar"
                    name="search"
                    value={t.value}
                    fullWidth
                    customStyle={customStyles.inputSearch}
                  />
                </Grid>
              </Grid>
            </Grid>
          ),
        ),
      )}
    </Grid>
  );
};
