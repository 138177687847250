import { LibbyFetchDAO } from './LibbyFetchDAO';
export class CertificadosSeptimoDAO extends LibbyFetchDAO {
  constructor() {
    super('certificados_septimo', 'idAlumno');
  }

  getById(id: string) {
    return this.query().equals('idAlumno', id).run();
  }
}
