import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class RecuperacionDAO extends LibbyFetchDAO {
  constructor() {
    super('calificacionesrecuperacion', 'idRecuperacion');
  }

  getAll() {
    return this.query().limit(0, 100).run();
  }
}
