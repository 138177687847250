import React, { useCallback, useMemo } from 'react';
import { Grid } from '@material-ui/core';
import { MainButton } from 'src/commons/components/MainButton';
import customFormDialog from 'src/commons/services/customFormDialog';
import { useParams } from 'react-router';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import {
  useBackButton,
  useRouteScreenTitle,
  useTableSortConfig,
} from '../../../../../commons/hooks';
import {
  useCuentasFetchById,
  useUsuarioRolEstablecimientoLibbyFetch,
} from '../../../../../app/business';
import { InfoTable } from '../../../../../commons/components/InfoTable';
import { useUsuariosRolesColumns } from './hooks';
import { ROL } from '../../../../../commons/const/rol';
import { LibbyObject } from '../../../../../commons/types/types';
import { AddRolModal } from './AddRolModal/AddRolModal';

const UsuariosRolesRaw = ({ libby }: LibbyObject) => {
  useBackButton('/private/configurationadmin');
  const { idUsuario } = useParams<{ idUsuario: string }>();
  const { data: usuario } = useCuentasFetchById(idUsuario);

  useRouteScreenTitle(`${usuario ? usuario.username : 'Usuario'} - Roles`);

  const { orderBy, onSortChange, direction } = useTableSortConfig(
    'localizacion.descripcion',
  );
  const fixedFilter = useMemo(
    () => ({
      usuarioFilter: [{ path: 'usuario', value: idUsuario }],
      adminSkip: [
        { path: 'rolUsuario', value: ROL.ADMIN, method: 'notEquals' },
      ],
    }),
    [idUsuario],
  );

  const {
    data = [],
    working,
    fetchMore,
    reFetch,
  } = useUsuarioRolEstablecimientoLibbyFetch({
    fixedFilter,
    orderBy,
    direction,
  });

  const columns = useUsuariosRolesColumns(reFetch, libby);

  const openRolModal = useCallback(() => {
    customFormDialog.show({
      title: '',
      renderComponent: (
        <AddRolModal usuario={usuario!} roles={data} reFetch={reFetch} />
      ),
      sizeWidth: 'md',
    });
  }, [usuario, data, reFetch]);

  return (
    <Grid container direction="column" style={{ marginTop: 20 }}>
      <Grid item sm={12}>
        <MainButton
          title="Agregar Rol"
          disabled={!usuario}
          handleOnClick={openRolModal}
        />
      </Grid>
      <InfoTable
        rows={data}
        columns={columns}
        working={working}
        rowIdKey="idRolEstablecimiento"
        direction={direction}
        orderBy={orderBy}
        onSortChange={onSortChange}
        onBottomScroll={fetchMore}
      />
    </Grid>
  );
};

export const UsuariosRoles = DatabaseConnector(UsuariosRolesRaw)('admin');
