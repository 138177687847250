import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { makeStyles } from '@material-ui/core/styles';
import {
  HeaderTable,
  InfoTable,
  IconTables,
  useTabBarSetValue,
} from 'src/commons/components';
import customFormDialog from 'src/commons/services/customFormDialog';
import { primary, grey } from 'src/theme/colors';
import { useRolesContext } from 'src/context/RolesContext';
import {
  AnyObject,
  iconTablesCycleOpelCallbackTypes,
  editCallbackCycleOpelTypes,
  LibbyObject,
  useLibbyFetch,
  IconCircleTable,
  useLibbyCall,
} from 'src/commons';
import { generatedArrSearch } from 'src/utils';
import { useDebounce } from 'src/commons/hooks/useDebounce';
import { useAlumnoMovimientoLibbyFetch } from 'src/app/business';
import { useRouterContext } from 'src/lib/router';
import { FilterTablesSchoolAdults } from './components/FilterTablesSchoolAdults';
import { SeccionModalSchoolAdults } from './components/SeccionModalSchoolAdults';
import confirmDialog from '../../../../../../commons/services/confirmDialog';

const customStyles = {
  customStyleTitleSelect: {
    fontSize: 12,
    color: grey.textPrimary,
    marginBottom: 2,
  },
  circleCapacity: { fontSize: '10px', color: primary.warning },
  iconColor: { color: primary.paleBlueStrong },
  iconDisabled: { color: primary.disabled },
};

const matriculationStyles = makeStyles(() => ({
  descriptionPlanStudy: {
    fontSize: '13px',
    textTransform: 'capitalize',
  },
  iconButton: {
    padding: 0,
  },
}));

const MATRICULATION_TAB_INDEX = 4;

const InitialSchoolAdultsRaw = ({ libby }: LibbyObject) => {
  const roleInfo = useRolesContext();
  const setTabBarValue = useTabBarSetValue();
  const { localizacionId, nivel: levelId } = roleInfo?.selectedRole;

  const filterDefault = [{ path: 'localizacion', value: localizacionId }];
  const filterLevel = useMemo(
    () => [{ path: 'nivel', value: levelId }],
    [levelId],
  );

  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [filterLibby, setFilterLibby] = useState<AnyObject>({
    0: filterDefault,
    1: filterLevel,
  });
  const [orderBy, setOrderBy] = useState('cicloLectivoAdultos');
  const [formValues, setFormValues] = useState({
    cicloLectivoAdultos: '',
    planEstudioNivel: '',
    nivel: '',
    turno: '',
  });
  const [search, setSearch] = useState('');

  const searchDebounced = useDebounce(search, 1000);

  const { metadata } = useRouterContext();

  // useEffect(() => {
  //   if (metadata.title === 'Estudiante') {
  //     setTabBarValue(MATRICULATION_TAB_INDEX);
  //   }
  // }, [metadata, setTabBarValue]);

  const pathSearch = useMemo(
    () => [
      'turno.descripcionTurno',
      'cicloLectivoAdultos.descripcionCicloLectivoAdulto',
      'nivel.descripcionNivel',
      'planEstudioNivel.planEstudio.descripcionPlanEstudio',
      'division',
    ],
    [],
  );

  const classes = matriculationStyles();

  const paramsFetchSeccion = useMemo(
    () => ({
      daoName: 'seccion',
      orderBy,
      direction,
      filter: filterLibby,
    }),
    [filterLibby, direction, orderBy],
  );

  const {
    data: seccion,
    working,
    fetchMore,
    reFetch,
  } = useLibbyFetch(libby, paramsFetchSeccion);
  const paramsFetchPlanEstudioNivel = useMemo(
    () => ({
      daoName: 'localizacion_plan_estudio_nivel',
      filter: {
        0: [
          {
            path: 'planEstudioNivel.modalidadNivel.nivel',
            value: roleInfo?.selectedRole.nivel,
          },
        ],
        1: [
          {
            path: 'localizacion',
            value: roleInfo?.selectedRole?.localizacionId,
          },
        ],
      },
    }),
    [roleInfo?.selectedRole],
  );

  const { data: planEstudioNivel } = useLibbyFetch(
    libby,
    paramsFetchPlanEstudioNivel,
  );
  const { data: alumno_movimiento = [] } = useAlumnoMovimientoLibbyFetch({
    limit: 100,
    aspect: 'shorted_relation',
  });
  const { data: ciclo_lectivo_adultos } = useLibbyFetch(libby, {
    daoName: 'ciclo_lectivo_adultos',
  });

  const { data: nivel } = useLibbyCall(libby, {
    daoName: 'nivel',
    methodName: 'getLevelsBySchool',
    params: [roleInfo?.selectedRole.nivel],
  });
  const { data: turno } = useLibbyFetch(libby, { daoName: 'turno' });

  const flatten = () =>
    planEstudioNivel.map((data: AnyObject) => ({
      ...data,
      ...data.planEstudioNivel,
      ...data.planEstudioNivel.planEstudio,
    }));

  const filterTables = useCallback(
    (filterSearch: any) => {
      if (
        formValues.cicloLectivoAdultos ||
        formValues.planEstudioNivel ||
        formValues.nivel ||
        formValues.turno
      ) {
        const toReformFormValues = Object.entries(formValues)
          .filter((filter) => !!filter[1])
          .map(
            (filter: AnyObject) =>
              filter[1] && [{ path: filter[0], value: filter[1] }],
          );
        const toObjectLibby = {
          filterDefault,
          filterLevel,
          filterSearch,
          ...toReformFormValues,
        };
        setFilterLibby(toObjectLibby);
      } else {
        setFilterLibby({
          0: filterDefault,
          1: filterLevel,
          2: filterSearch,
        });
      }
    },
    // eslint-disable-next-line
    [formValues],
  );

  useEffect(() => {
    filterTables(generatedArrSearch(pathSearch, searchDebounced));
  }, [filterTables, searchDebounced, pathSearch]);

  const handleEdit = useCallback(
    async ({
      idNivel,
      idTurno,
      division,
      capacidadMaxima,
      idPlanEstudioNivel,
      idCicloLectivoAdultos,
      idSeccionRaw,
    }: editCallbackCycleOpelTypes) => {
      await customFormDialog.show({
        title: 'Editar sección',
        renderComponent: (
          <SeccionModalSchoolAdults
            idSeccion={idSeccionRaw}
            cycleLectiveAdults={idCicloLectivoAdultos}
            plan_study={idPlanEstudioNivel}
            level={idNivel}
            reFetch={reFetch}
            turn={idTurno}
            localizacionId={localizacionId}
            dividing={division}
            capacity={capacidadMaxima}
            edit
          />
        ),
        sizeWidth: 'md',
      });
    },
    [localizacionId, reFetch],
  );

  const handleDelete = useCallback(
    async (idSeccion: string, nombreSeccion: string) => {
      const confirm = await confirmDialog.show({
        title: '¿Desea eliminar la sección?',
        content: `Una vez eliminada, la sección "${nombreSeccion}" no podrá recuperarse.`,
        confirmText: 'Confirmar',
        cancelText: 'Cancelar',
      });
      if (confirm) {
        await libby.seccion.remove({ idSeccion });
        reFetch();
      }
    },
    [libby, reFetch],
  );

  const iconTables = useCallback(
    ({
      idNivel,
      idTurno,
      division,
      capacidadMaxima,
      idPlanEstudioNivel,
      idCicloLectivoAdultos,
      idSeccionRaw,
      capacityStudentMovement,
      nombreSeccion,
    }: iconTablesCycleOpelCallbackTypes) => [
      {
        icon: <VisibilityIcon style={customStyles.iconColor} />,
        handleOnClick: () => setTabBarValue(4, { nombreSeccion }),
        disabled: false,
        styleIcon: {},
        class: classes.iconButton,
      },
      {
        icon: <EditIcon style={customStyles.iconColor} />,
        handleOnClick: () =>
          handleEdit({
            idNivel,
            idTurno,
            division,
            capacidadMaxima,
            idPlanEstudioNivel,
            idCicloLectivoAdultos,
            idSeccionRaw,
          }),
        disabled: false,
        styleIcon: {},
        class: classes.iconButton,
      },
      {
        icon: <DeleteIcon />,
        handleOnClick: () => handleDelete(idSeccionRaw, nombreSeccion),
        disabled: !!capacityStudentMovement.length,
        styleIcon: capacityStudentMovement.length
          ? customStyles.iconDisabled
          : customStyles.iconColor,
        class: classes.iconButton,
        tooltip:
          'No puedes eliminar ésta sección/ciclo mientras contenga estudiantes.',
      },
    ],
    [classes.iconButton, handleDelete, handleEdit, setTabBarValue],
  );

  const schoolAdultsRows = useMemo(
    () =>
      seccion
        .filter((item) => item.cicloLectivoAdultos !== null)
        .map(
          ({
            idSeccion: idSeccionRaw,
            turno: { descripcionTurno, idTurno },
            nivel: { descripcionNivel, idNivel },
            cicloAdultos,
            cicloLectivoAdultos: {
              descripcionCicloLectivoAdulto,
              idCicloLectivoAdultos,
            },
            division,
            nombreSeccion,
            capacidadMaxima,
            planEstudioNivel: {
              planEstudio: { descripcionPlanEstudio },
              idPlanEstudioNivel,
            },
          }: AnyObject) => {
            const capacityStudentMovement = alumno_movimiento.filter(
              ({ seccion: { idSeccion } }: AnyObject) =>
                idSeccion === idSeccionRaw,
            );
            const arrIconTables = iconTables({
              idNivel,
              idTurno,
              division: cicloAdultos?.idCicloAdultos,
              capacidadMaxima,
              idPlanEstudioNivel,
              idCicloLectivoAdultos,
              idSeccionRaw,
              capacityStudentMovement,
              nombreSeccion,
            });
            return {
              seccion: idSeccionRaw,
              cycleLectiveAdults: descripcionCicloLectivoAdulto,
              levelId: descripcionNivel,
              turn: descripcionTurno,
              cicloOpel: cicloAdultos?.descripcion || division || '',
              coupon: (
                <>
                  <IconCircleTable
                    capacidadRecomendada={capacityStudentMovement.length}
                    capacidadMaxima={capacidadMaxima}
                  />
                  {`${capacityStudentMovement.length}/${capacidadMaxima}`}
                </>
              ),
              planStudy: descripcionPlanEstudio,
              icon: <IconTables content={arrIconTables} />,
            };
          },
        ),
    [alumno_movimiento, iconTables, seccion],
  );

  const column = [
    {
      id: 'cycleLectiveAdults',
      label: 'Tipo de ciclo lectivo',
      width: '10%',
      orderById: 'cicloLectivoAdultos',
    },
    {
      id: 'levelId',
      label: 'Nivel',
      width: '5%',
      orderById: 'nivel',
    },
    {
      id: 'turn',
      label: 'Turno',
      width: '5%',
      orderById: 'turno',
    },
    {
      id: 'cicloOpel',
      label: 'Ciclo/OPEL',
      width: '10%',
      orderById: 'division',
    },
    {
      id: 'coupon',
      label: 'Capacidad',
      width: '5%',
      orderById: 'capacidadMaxima',
    },
    {
      id: 'planStudy',
      label: 'Plan de estudio',
      width: '20%',
      orderById: 'planEstudioNivel',
    },
    {
      id: 'icon',
      label: '',
      width: '8%',
      hideSortIcon: true,
      noSort: true,
      style: { textAlign: 'right' },
    },
  ];

  const handleOnClick = () => {
    customFormDialog.show({
      title: 'Crear Ciclo/OPEL',
      renderComponent: (
        <SeccionModalSchoolAdults
          reFetch={reFetch}
          localizacionId={localizacionId}
          level={levelId}
        />
      ),
      sizeWidth: 'md',
    });
  };

  const handleChange = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleChangeSearch = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setSearch(target.value);
  };
  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setDirection(newDirection);
    setOrderBy(newOrderBy);
  };

  const arrFilterTables = [
    [
      {
        title: 'Nivel',
        placeholder: 'Seleccioná nivel',
        labelKey: 'descripcionNivel',
        valueKey: 'idNivel',
        content: nivel,
        handleChange,
        value: formValues.nivel,
        name: 'nivel',
      },
      {
        title: 'Tipo de ciclo lectivo',
        placeholder: 'Seleccioná tipo de ciclo lectivo',
        labelKey: 'descripcionCicloLectivoAdulto',
        valueKey: 'idCicloLectivoAdultos',
        content: ciclo_lectivo_adultos,
        handleChange,
        value: formValues.cicloLectivoAdultos,
        customStyleTitle: customStyles.customStyleTitleSelect,
        name: 'cicloLectivoAdultos',
      },
      {
        name: 'turno',
        title: 'Turno',
        placeholder: 'Seleccioná turno',
        labelKey: 'descripcionTurno',
        valueKey: 'idTurno',
        content: turno,
        handleChange,
        value: formValues.turno,
        customStyleTitle: customStyles.customStyleTitleSelect,
      },
      {
        title: 'Plan de Estudios',
        placeholder: 'Seleccioná plan',
        labelKey: 'descripcionPlanEstudio',
        valueKey: 'idPlanEstudioNivel',
        content: flatten(),
        handleChange,
        value: formValues.planEstudioNivel,
        name: 'planEstudioNivel',
        customStyleTitle: customStyles.customStyleTitleSelect,
      },
    ],
    [
      {
        input: true,
        handleChange: handleChangeSearch,
        name: 'search',
        placeholder: 'Buscar',
        value: search,
      },
    ],
  ];

  return (
    <>
      <HeaderTable
        title={roleInfo?.selectedRole?.localizacion}
        titleMainButton="Crear ciclo/OPEL"
        handleOnClick={handleOnClick}
      >
        <FilterTablesSchoolAdults content={arrFilterTables} />
      </HeaderTable>
      <InfoTable
        working={working}
        rows={schoolAdultsRows}
        onBottomScroll={fetchMore}
        columns={column}
        rowIdKey="seccion"
        direction={direction}
        onSortChange={handleRequestSort}
      />
    </>
  );
};

export const InitialSchoolAdults = DatabaseConnector(InitialSchoolAdultsRaw)(
  'nivel',
  'ciclo_lectivo_adultos',
  'plan_estudio_nivel',
  'alumno_movimiento',
  'localizacion_plan_estudio_nivel',
  'turno',
  'seccion',
);
