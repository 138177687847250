import { any } from 'joi';
import {
  Persona,
  Rol,
  Seccion,
  TipoActividad,
  TipoDocumento,
  TipoInformes,
  TipoPeriodo,
  RolStatus,
} from 'src/app/models';
import {
  DisciplinarInterArealTable,
  DisciplinarOrientadoInterDisciplinarTable,
  DisciplinarInterDisciplinarTable,
} from 'src/screens/Private/EspaciosCurriculares/EspacioCurricular/Planificacion/tables';
import {
  CHANGE_NEW_USER,
  SELECT_PLANIFICATION,
  SELECT_PLANIFICATION_ANIO,
  SELECT_CICLO_LECTIVO,
  SELECT_CONSTANTES,
  VALUES_CHANGED,
  DATE,
  DEUDAS_ACADEMICAS_CHANGED,
  SET_LEGAJO_CICLO_LECTIVO,
  PRINT_PLANIFICACION,
  changeNewUser,
  selectCicloLectivo,
  selectConstantes,
  selectPlanification,
  selectPlanificationAnio,
  valuesChanged,
  deudasAcademicasChanged,
  date,
  setLegajoCicloLectivo,
  printPlanificacion,
} from './actions';

export type GeneralActions =
  | ReturnType<typeof changeNewUser>
  | ReturnType<typeof selectCicloLectivo>
  | ReturnType<typeof selectConstantes>
  | ReturnType<typeof selectPlanification>
  | ReturnType<typeof selectPlanificationAnio>
  | ReturnType<typeof valuesChanged>
  | ReturnType<typeof deudasAcademicasChanged>
  | ReturnType<typeof date>
  | ReturnType<typeof setLegajoCicloLectivo>
  | ReturnType<typeof printPlanificacion>;

/*interface GeneralActions {
  type: string;
  payload?: AnyObject | any;
}*/

// TODO: tipificar el state
export const initialState = {
  cicloLectivo: null as number | null,
  espacioCurricular: {
    planificacion: {
      selected: {
        anio: undefined as number | undefined,
        planificacion: {
          id: 'disciplinarInterArealTable',
          descripcion: 'Ambos tipos' as string | undefined,
          component: DisciplinarInterArealTable,
        },
        seccion: undefined as Seccion[] | undefined,
      },
      print: {
        idPlanificacion: null as null | number,
        type: null as null | string,
        download: false as boolean,
      },
    },
  },
  persona: null as Persona | null,
  constantes: {
    TipoJornada: ['Simple', 'Completa', 'Extendida'],
  } as {
    Rol?: Rol[];
    TipoPeriodo?: TipoPeriodo[];
    TipoDocumento?: TipoDocumento[];
    TipoActividad?: TipoActividad[];
    TipoInformes?: TipoInformes[];
    RolStatus?: RolStatus[];
  },
  legajo: {
    historialAcademico: { isUpdated: false },
    idDicloLectivo: null,
  },
  calificaciones: { valuesChanged: false },
  date: null,
};

export const generalReducer = (
  state = initialState,
  action: GeneralActions,
): typeof initialState => {
  switch (action.type) {
    case SELECT_CICLO_LECTIVO:
      return {
        ...state,
        cicloLectivo: action.payload,
      };
    case SELECT_CONSTANTES:
      return {
        ...state,
        constantes: { ...state.constantes, ...action.payload },
      };
    case SELECT_PLANIFICATION:
      return {
        ...state,
        espacioCurricular: {
          planificacion: {
            selected: {
              ...state.espacioCurricular.planificacion.selected,
              ...action.payload,
              planificacion: {
                ...state.espacioCurricular.planificacion.selected.planificacion,
                ...action.payload.planificacion,
              },
            },
            print: { ...state.espacioCurricular.planificacion.print },
          },
        },
      };
    case PRINT_PLANIFICACION:
      return {
        ...state,
        espacioCurricular: {
          planificacion: {
            print: {
              idPlanificacion: action.payload.idPlanificacion,
              type: action.payload.type,
              download: action.payload.download,
            },
            selected: { ...state.espacioCurricular.planificacion.selected },
          },
        },
      };
    case SELECT_PLANIFICATION_ANIO:
      const {
        payload: { anio, bloque, seccion, isCommonMatter },
      } = action;
      return {
        ...state,
        espacioCurricular: {
          planificacion: {
            selected: {
              ...state.espacioCurricular.planificacion.selected,
              planificacion: {
                descripcion: undefined,
                id: bloque
                  ? 'disciplinarOrientadoInterDisciplinarTable'
                  : isCommonMatter
                  ? 'disciplinarInterArealTable'
                  : 'disciplinarInterDisciplinarTable',
                component: bloque
                  ? DisciplinarOrientadoInterDisciplinarTable
                  : isCommonMatter
                  ? DisciplinarInterArealTable
                  : DisciplinarInterDisciplinarTable,
              },
              anio,
              seccion,
            },
            print: { ...state.espacioCurricular.planificacion.print },
          },
        },
      };
    case CHANGE_NEW_USER:
      const persona = action.payload;
      return {
        ...state,
        persona,
      };
    case VALUES_CHANGED:
      return {
        ...state,
        calificaciones: {
          valuesChanged: action.payload,
        },
      };
    case DEUDAS_ACADEMICAS_CHANGED:
      return {
        ...state,
        legajo: {
          historialAcademico: {
            isUpdated: action.payload,
          },
          idDicloLectivo: state.legajo.idDicloLectivo,
        },
      };
    case DATE:
      return {
        ...state,
        date: action.payload,
      };
    case SET_LEGAJO_CICLO_LECTIVO:
      return {
        ...state,
        legajo: {
          idDicloLectivo: action.payload,
          historialAcademico: {
            isUpdated: state.legajo.historialAcademico.isUpdated,
          },
        },
      };
    default:
      return {
        ...state,
      };
  }
};
