import React, { useCallback } from 'react';
import { Typography, Grid, makeStyles } from '@material-ui/core';
import { EspacioPlan, Habilidades } from 'src/app/models';
import { grey } from 'src/theme/colors';
import { ListItemProperties } from '../../../feedbacks/commons';
import { useEspacioCurricularLogic } from '../hooks/useEspacioCurricularLogic';

const useStyles = makeStyles(() => ({
  textField: {
    fontSize: 14,
    color: grey.textPrimary,
    fontWeight: 600,
  },
  valueField: {
    fontSize: 16,
    color: grey.textPrimary,
    fontWeight: 400,
    marginTop: 8,
    marginBottom: 20,
  },
}));
export interface EspacioCurricularProps {
  data: EspacioPlan;
}

export const EspacioCurricular = ({ data }: EspacioCurricularProps) => {
  const classes = useStyles();
  const { filterEspacios, loading } = useEspacioCurricularLogic(data);

  const getHabilidades = useCallback(
    (habilidades: Habilidades[]) =>
      habilidades?.map((habilidad) => ({
        id: habilidad.idHabilidades,
        descripcion: habilidad.descripcion,
      })),
    [],
  );

  return (
    <Grid container direction="column">
      {!loading &&
        filterEspacios.map(
          ({ espacios, contenidos, habilidades: _habilidades }) => (
            <Grid key={espacios?.idEspacioCurricular}>
              <Typography className={classes.textField}>
                Espacio Curricular
              </Typography>
              <Typography className={classes.valueField}>
                {espacios?.descripcion}
              </Typography>
              {_habilidades[0] && (
                <ListItemProperties
                  title="Habilidades"
                  values={getHabilidades(_habilidades)}
                />
              )}
              <ListItemProperties
                title="Contenidos Específicos"
                values={contenidos}
              />
            </Grid>
          ),
        )}
    </Grid>
  );
};
