import { LibbyFetchDAO } from './LibbyFetchDAO';

export class TipoEstadoPresenteDAO extends LibbyFetchDAO {
  constructor() {
    super('tipo_estado_propuesta', 'idTipoEstadoPropuesta');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
