import { LibbyFetchDAO } from './LibbyFetchDAO';

export class GruposPlanEstudioNivelDAO extends LibbyFetchDAO {
  constructor() {
    super('grupos_plan_estudio_nivel', 'idGrupoPlanEstudioNivel');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
