import { TipoSituacionV3DAO } from 'src/platform/libby/dao/Presentismov3';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { TipoSituacionV3 } from '../../models/presentismo_v3';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'tipo_situacion';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { renderPropCall, renderPropFetchById, simpleSelect, selectInput },
} = makeBusinessEntity<TipoSituacionV3, TipoSituacionV3DAO>(DAO_NAME, {});

// hooks
export const useTipoSituacionV3FetchById = fetchById;
export const useTipoSituacionV3LibbyCall = libbyCall;
export const useTipoSituacionV3DAO = libbyDAO;
export const useTipoSituacionV3LibbyFetch = libbyFetch;

// components/hoc
export const TipoSituacionV3Call = renderPropCall;
export const TipoSituacionV3ById = renderPropFetchById;
export const TipoSituacionV3Select = simpleSelect;
export const TipoSituacionV3SelectInput = selectInput;
