import React, { useCallback, useMemo, useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import { Footer, InfoTable, ROL, useBackButton } from 'src/commons';
import { useConvivenciaRows, useConvivenciaTableColumns } from './hooks';
import {
  useCalificacionesSwitch,
  useCalificacionesSubmit,
  useExcelData,
  useValidator,
  validateLeave,
} from '../../../hooks';
import { useCalificacionesContext } from '../../../context';
import { CalificacionesFiltersHeader } from '../../../components/CalificacionesFiltersHeader/CalificacionesFiltersHeader';
import { AlertMessage } from '../../../components';
import { useConvivenciaDAO } from 'src/app/business';
import { useHistory } from 'react-router';
import { useRolesContext } from 'src/context/RolesContext';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useRoleCanOpenGrade } from '../../../hooks/useRoleCanOpenGrade';
import { useColegioEfectivizadoContext } from '../../../context/EfectivizacionProvider';

const useStyles = makeStyles({
  table: {
    maxHeight: '600px',
    marginBottom: '80px',
  },
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

const fieldsToValidate = [
  'acuerdosGrupales',
  'situacionesDeJuego',
  'resuelveConflictos',
  'repetuosoConAdultos',
  'repetuosoConSusPares',
];

export const Convivencia = ({
  anioFilter,
}: {
  anioFilter: {
    anio: {
      path: string;
      value: unknown;
    }[];
  };
}) => {
  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();
  const classes = useStyles();
  const {
    selectedRole: { localizacionId, rol },
  } = useRolesContext();
  const convivenciaDAO = useConvivenciaDAO();
  const history = useHistory();
  const { filter, setFilter } = useCalificacionesContext();

  const columns = useConvivenciaTableColumns();
  const {
    rows,
    working,
    _rows,
    data,
    reFetch,
    isClosed,
    readyToClose,
    hasCalifications,
  } = useConvivenciaRows(filter);
  const { incompletedRows, isDisabled, submitRows } = useValidator({
    fieldsToValidate: fieldsToValidate,
    _rows,
    filter,
    data,
  });

  const showView = useMemo(
    () => filter.anio && filter.seccion && filter.periodo,
    [filter],
  );

  const [anioSelect, setAnioSelect] = useState('');
  const [seccionSelect, setSeccionSelect] = useState('');
  const [periodoSelect, setPeriodoSelect] = useState('');

  const { excelData } = useExcelData(
    columns,
    _rows,
    `Calificaciones - Convivencia - ${periodoSelect} - ${seccionSelect} - ${localizacionId}`,
    periodoSelect,
    anioSelect,
    seccionSelect,
    'Convivencia',
  );

  const { onSubmit, submitting } = useCalificacionesSubmit({
    columns,
    _rows,
    data,
    filter,
    dao: convivenciaDAO,
    reFetch,
  });

  const { onSwitch, switchSubmitting } = useCalificacionesSwitch({
    _rows,
    filter,
    dao: convivenciaDAO,
    reFetch,
    asunto: 'Convivencia',
  });

  const handleLeave = useCallback(
    async (route?: string) => {
      if (
        isDisabled ||
        isClosed ||
        submitRows ||
        !establecimientoEfectivizado
      ) {
        history.push('/');
        return;
      } else {
        const confirm = await confirmDialog.show({
          title: '¿Desea continuar?',
          content:
            'La información precargada se perderá en caso que no guarde, por favor seleccione una opción.',
          confirmText: 'Salir sin guardar',
          cancelText: 'Seguir en esta pantalla',
        });
        if (confirm) {
          try {
            history.push(route ?? '/');
          } catch (e) {
            console.log(e);
          }
        }
      }
    },
    [history, isClosed, isDisabled, submitRows, establecimientoEfectivizado],
  );

  const confirmarSalirConBackButton = useCallback(() => {
    if (
      !(isDisabled || isClosed || submitRows || !establecimientoEfectivizado)
    ) {
      handleLeave('/private/calificaciones');
    } else {
      history.push('/private/calificaciones');
    }
  }, [
    handleLeave,
    history,
    isClosed,
    isDisabled,
    submitRows,
    establecimientoEfectivizado,
  ]);

  useBackButton('/calificaciones', 0, confirmarSalirConBackButton);

  const canOpenGrade = useRoleCanOpenGrade(['1', '5']);

  const buttonConfig: any = useMemo(() => {
    const defaultButtons: any[] = [
      {
        title: 'Salir',
        size: 'medium',
        type: 'secondary',
        handleOnClick: handleLeave,
      },
      {
        title: 'Guardar',
        size: 'medium',
        handleOnClick: onSubmit,
        disabled:
          isDisabled ||
          isClosed ||
          submitRows ||
          // !establecimientoEfectivizado ||
          Number(rol) === ROL.COORDINADOR_JE,
      },
    ];

    if (!isClosed) {
      defaultButtons.push({
        title: 'Cerrar Calificaciones',
        size: 'medium',
        handleOnClick: () => onSwitch('close'),
        disabled:
          isDisabled ||
          !readyToClose ||
          isClosed ||
          !hasCalifications ||
          Number(rol) === ROL.COORDINADOR_JE,
        // || !establecimientoEfectivizado,
      });
    } else if (canOpenGrade) {
      defaultButtons.push({
        title: 'Abrir Calificaciones',
        size: 'medium',
        handleOnClick: () => onSwitch('open'),
        disabled:
          // !establecimientoEfectivizado ||
          Number(rol) === ROL.COORDINADOR_JE,
      });
    }
    return defaultButtons;
  }, [
    rol,
    // establecimientoEfectivizado,
    handleLeave,
    onSubmit,
    isDisabled,
    isClosed,
    submitRows,
    canOpenGrade,
    readyToClose,
    hasCalifications,
    onSwitch,
  ]);

  const handleAnioChange = (options: any) => {
    const anio = options?.find((s: any) => s.idAnio === filter.anio);
    setAnioSelect(anio?.descripcionAnio);
  };

  const handleSeccionesChange = (options: any) => {
    const seccion = options?.find((s: any) => s.idSeccion === filter.seccion);
    setSeccionSelect(seccion?.nombreSeccion);
  };

  const handlePeriodosChange = (options: any) => {
    const periodo = options?.find((p: any) => p.idPeriodo === filter.periodo);
    setPeriodoSelect(periodo?.tipoPeriodo.descripcionTipoPeriodo);
  };

  return (
    <>
      <AlertMessage
        incompletedRows={incompletedRows}
        isDisabled={isDisabled}
        isClosed={isClosed}
        working={working}
        showView={showView}
        establecimientoEfectivizado={establecimientoEfectivizado}
      />
      <CalificacionesFiltersHeader
        data={data}
        rows={_rows}
        filter={filter}
        setFilter={setFilter}
        excelData={excelData}
        handleSeccionesChange={handleSeccionesChange}
        handlePeriodosChange={handlePeriodosChange}
        handleAnioChange={handleAnioChange}
        showView={showView}
        incompletedRows={incompletedRows}
        readyToClose={readyToClose}
        anioCustomFilter={anioFilter}
      />
      {showView ? (
        <>
          <InfoTable
            rows={rows}
            columns={columns}
            customStyle={classes.table}
            working={working}
          />
          <Grid className={classes.footer}>
            <Footer
              buttonConfig={buttonConfig}
              loading={submitting || switchSubmitting}
            />
          </Grid>
        </>
      ) : (
        <Grid
          container
          justify="center"
          alignItems="center"
          style={{ height: '400px' }}
        >
          <Typography
            style={{
              fontSize: '40px',
              marginTop: '50px',
              color: 'gray',
              fontWeight: 'bold',
              lineHeight: '45px',
            }}
          >
            Seleccione año, sección y período para calificar.
          </Typography>
        </Grid>
      )}
    </>
  );
};
