import React, { useEffect } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { grey } from '../../../../../theme/colors';
import { LocalizacionPlanEstudioNivel, Nivel } from '../../../../../app/models';
import { FormRadio } from '../../../../../lib/templates/components';
import { LocalizacionPlanEstudioNivelCall } from '../../../../../app/business';
import { Loading } from '../../../../../commons/components/Loading';
import { useFormContext } from '../../../../../lib/templates/context';

const SPACING = 4;

export const NivelStep = () => {
  const { form, values } = useFormContext();

  return (
    <Grid
      container
      spacing={SPACING}
      direction="column"
      style={{ paddingRight: '5%', paddingLeft: '5%', flex: 1 }}
    >
      <Grid item>
        <Typography
          style={{
            fontFamily: 'Open Sans',
            color: grey.medium,
            fontSize: 20,
          }}
        >
          Nivel
        </Typography>
        <LocalizacionPlanEstudioNivelCall<LocalizacionPlanEstudioNivel[]>
          methodName="getByIdLocalizacion"
          params={[values.idLocalizacion]}
        >
          {({ working, data }) =>
            working || !data ? (
              <Loading />
            ) : (
              <Grid container direction="row" style={{ marginTop: 20 }}>
                {data
                  .reduce<Nivel[]>((acum, item) => {
                    if (
                      !acum.find(
                        (nivel) =>
                          nivel.idNivel ===
                          item.planEstudioNivel.modalidadNivel.nivel.idNivel,
                      )
                    ) {
                      return [
                        ...acum,
                        item.planEstudioNivel.modalidadNivel.nivel,
                      ];
                    }
                    return acum;
                  }, [])
                  .map((nivel) => (
                    <Grid
                      container
                      alignItems="center"
                      style={{ marginTop: 10 }}
                    >
                      <Grid item>
                        <FormRadio
                          form={form}
                          name="idNivel"
                          value={nivel.idNivel.toString()}
                        />
                      </Grid>
                      <Grid item style={{ maxWidth: '200px' }}>
                        <Typography>{nivel.descripcionNivel}</Typography>
                      </Grid>
                    </Grid>
                  ))}
              </Grid>
            )
          }
        </LocalizacionPlanEstudioNivelCall>
      </Grid>
    </Grid>
  );
};
