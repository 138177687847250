import React, { useMemo } from 'react';
import { Content, TabBar } from 'src/commons';
import { Acciones } from './acciones/Acciones';
import { useAcapEstablecimientoContext } from './context/AcapEstablecimientoContext';
import { AcapEstablecimientoContextProvider } from './context/AcapEstablecimientoContextProvider';
import { ACAP_SCREEN } from './context/type';
import { Organizacion } from './organizaciones';
import { Oferta } from './tabs/oferta';

const AcapEstablecimiento = () => {
  const { screen } = useAcapEstablecimientoContext();

  const tabs = useMemo<Content[]>(
    () => [
      {
        title: 'Organizaciones',
        component: <Organizacion />,
        hidden: screen === ACAP_SCREEN.ACCIONES_ASOCIADAS,
      },
      {
        title: 'Oferta',
        component: <Oferta />,
        hidden: screen === ACAP_SCREEN.ACCIONES_ASOCIADAS,
      },
    ],
    [screen],
  );

  return screen === ACAP_SCREEN.ORGANIZACIONES ? (
    <TabBar content={tabs} />
  ) : (
    <Acciones />
  );
};

export const AcapEstablecimientoScreen = () => {
  return (
    <AcapEstablecimientoContextProvider>
      <AcapEstablecimiento />
    </AcapEstablecimientoContextProvider>
  );
};
