export const getXPosition = (
  dayIndex: number,
  isJornadaCompleta = true,
  turno = 1,
) => {
  if (turno > 2 || turno < 1) throw new Error('Turno tiene que ser 1 o 2');
  if (dayIndex > 5 || dayIndex < 0)
    throw new Error('El dia tiene que ser entre lunes y sabado');

  return isJornadaCompleta ? dayIndex * 2 + (turno - 1) : dayIndex;
};

export class Grid {
  width: number;
  height: number;

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }
}

export class Point {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  getDayIndex(tipoJornada = 'Completa') {
    if (tipoJornada === 'Completa') {
      const turno = this.x % 2 ? 2 : 1;
      return (this.x - turno + 1) / 2;
    } else {
      return this.x;
    }
  }

  isEqual(point: Point) {
    return point.x === this.x && point.y === this.y;
  }

  clone() {
    return new Point(this.x, this.y);
  }

  ArrowDown(gridWidth: number, gridHeight: number) {
    if (this.y === gridHeight) {
      this.y = 0;
    } else {
      this.translateY(1);
    }
  }

  ArrowUp(gridWidth: number, gridHeight: number) {
    if (this.y === 0) {
      this.y = gridHeight;
    } else {
      this.translateY(-1);
    }
  }

  ArrowRight(gridWidth: number, gridHeight: number) {
    if (this.x === gridWidth && this.y < gridHeight) {
      this.translateY(1);
      this.x = 0;
    } else if (this.x === gridWidth && this.y === gridHeight) {
      this.x = 0;
    } else {
      this.translateX(1);
    }
  }

  ArrowLeft(gridWidth: number, gridHeight: number) {
    if (this.x === 0) {
      this.translateY(-1);
      this.x = gridWidth;
    } else {
      this.translateX(-1);
    }
  }

  translateToPosition(point: Point) {
    this.x = point.x;
    this.y = point.y;
  }

  translateX(amount: number) {
    if (!(this.x === 0 && amount < 0)) this.x += amount;
  }

  translateY(amount: number) {
    if (!(this.y === 0 && amount < 0)) this.y += amount;
  }
}
