import { useIndicadoresDeEvaluacionLibbyCall } from 'src/app/business';
import { IndicadoresDeEvaluacion } from 'src/app/models';
import { makeEntityByEspacio } from '../../../commons/hooks';
import { useArealInterarealContext } from '../../context';

export const useIndicadoresFetch = () => {
  const { espacioCurricular } = useArealInterarealContext();

  const filterVoid = () => ({
    filter: {},
    limit: 20000,
  });

  const useIndicadoresDAO = makeEntityByEspacio<IndicadoresDeEvaluacion[]>(
    useIndicadoresDeEvaluacionLibbyCall,
    filterVoid,
    true,
  );

  const { data: indicadores = [], working } =
    useIndicadoresDAO(espacioCurricular);

  return { indicadores, working };
};
