import { Matriculation } from '../../../screens';
import { MenuItem } from '../../../commons/const/ui/menu';
import { espaciosCurricularesMenu } from './espaciosCurriculares';
import { gestionRolesMenu } from './gestionRoles';
import { legajoAlumnoMenu } from './legajoAlumno';
import { repositorioMenu } from './repositorio';
import { repositorioABMMenu } from './repositorioABM';
import { configurationMenu } from './configuration';
import { comunicacionesMenu } from './comunicaciones';
import { signUpMenu } from './signUp';
import { aulasVirtualesMenu } from './aulasVirvuales/aulasVirtuales';
import { configurationAdminMenu } from './configurationAdmin';
import { boletinesMenu } from './boletines';
import { PresentismoMenu } from './presentismo';
import { informesc } from './informes';
import { calificacionesMenu } from './calificaciones';
import { previewMenu } from './preview';
import { aprendizajeBasadoEnProyectos } from './aprendizajeBasadoEnProyectos';
import { createc } from './createc';
import { plataformaEvaluaciones } from './evaluaciones';
import { ciudadaniaGlobal } from './ciudadaniaGlobal';
import { leerParaCrecer } from './leerParaCrecer';
import { Reportes } from 'src/screens/Private';
import { PpsMenu } from './pps/ppsMenu';
import { AcapMenu } from './acap/acapMenu';
import Dashboard from './dashboard/Dashboard';

export const routes: MenuItem[] = [
  {
    key: 'matriculation',
    basePath: '/matriculation',
    target: {
      component: Matriculation,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Matriculation',
    children: [],
    default: true,
  },
  espaciosCurricularesMenu,
  calificacionesMenu,
  PresentismoMenu,
  AcapMenu,
  legajoAlumnoMenu,
  aulasVirtualesMenu,
  repositorioABMMenu,
  repositorioMenu,
  signUpMenu,
  // configurationMenu,
  configurationAdminMenu,
  gestionRolesMenu,
  comunicacionesMenu,
  boletinesMenu,
  informesc,
  previewMenu,
  aprendizajeBasadoEnProyectos,
  createc,
  leerParaCrecer,
  ciudadaniaGlobal,
  plataformaEvaluaciones,
  {
    key: 'reportes',
    basePath: '/reportes',
    target: {
      component: Reportes,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Reportes',
    children: [],
  },
  {
    key: 'tablero',
    basePath: '/tablero',
    target: {
      component: Dashboard,
      path: '/',
    },
    enabled: true,
    icon: 'Tablero',
    text: 'Tablero',
    children: [],
  },
  PpsMenu,
];
