import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { typeOfPass, typeEstablishment, level } from 'src/commons/const';
import moment from 'moment';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import {
  AnyObject,
  LibbyObject,
  statusPassesRequest,
  useDebounce,
} from 'src/commons';
import { primary } from 'src/theme/colors';
import { useRolesContext } from 'src/context/RolesContext';
import { HeaderTable, InfoTable } from 'src/commons/components';
import { MainButton } from 'src/commons/components/MainButton';
import { OptionsModal } from 'src/commons/components/OptionsModal';
import { useLibbyFetch } from 'src/commons/hooks/useLibbyFetch';
import { useLibbyFetch as useLibbyFetchWithAspect } from 'src/lib/libby/hooks/useLibbyFetch';
import customFormDialog from 'src/commons/services/customFormDialog';
import confirmDialog from 'src/commons/services/confirmDialog';
import { orderPending } from 'src/utils';
import { PermissionBlocker } from 'src/lib/permission/components/PermissionBlocker';
import { BLOCKER_ID } from 'src/platform/permission/const/BlockerId';
import { useLegajoLogic } from 'src/screens/Private/LegajoAlumno';
import { SolicitudPases } from 'src/app/models';
import { FilterTables } from './components/PassRequestModal/FilterTables';
import { PassRequestModal } from './components/PassRequestModal';
import { IconStatus } from '../components/IconStatus';
import { GestionModalInicial } from '../components/GestionModalInicial';
import { useDateValidator } from 'src/commons/hooks/useDateValidator';
import { useSnackbar } from 'notistack';
import { setSearchParamsMultipleWords } from 'src/utils/setSearchParamsMultipleWords';
import { useMatriculationContext } from '../../context/Context';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';

const { SCHOOLD_TECHNIQUE } = typeEstablishment;

const { PENDING, CANCELLED } = statusPassesRequest;

const InitialPassManagementRaw = ({ libby }: LibbyObject) => {
  const [direction, setDirection] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState('fechaPedida');
  const [formValues, setFormValues] = useState({
    anio: '',
    estadoSolicitudPases: '',
    localizacionDestino: '',
  });
  const [search, setSearch] = useState('');
  const searchDebounced = useDebounce(search, 1000);

  const searchFilterParams = useMemo(
    () => ({
      ...setSearchParamsMultipleWords(
        [
          'alumno.persona.nombre',
          'alumno.persona.apellido',
          'alumno.persona.documento',
          'anio.descripcionAnio',
          'alumno.condicion.descripcionCondicion',
          'localizacionOrigen.descripcion',
          'localizacionDestino.descripcion',
          'estadoSolicitudPases.descripcionSolicitudPase',
        ],
        searchDebounced,
      ),
    }),
    [searchDebounced],
  );

  const { enqueueSnackbar } = useSnackbar();
  const roleInfo = useRolesContext();
  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();
  const isEfectivizado = establecimientoEfectivizado as boolean;
  const idLocation = roleInfo?.selectedRole?.localizacionId;
  const { nivel, tipoEstablecimiento } = roleInfo?.selectedRole;

  const filterDefaultLevel = [{ path: 'nivel', value: nivel }];

  const filterDefault = [
    { path: 'localizacionOrigen', value: idLocation },
    { path: 'localizacionDestino', value: idLocation },
  ];
  const [filterLibby, setFilterLibby] = useState<AnyObject>({
    0: filterDefault,
    1: filterDefaultLevel,
  });

  const paramsFetchManagement = {
    orderBy,
    direction,
    daoName: 'solicitud_pases',
    filter: filterLibby,
    aspect: 'matriculacion_gestionDePases_solicitudDePasesRequest',
  };

  const { badgeGestionDePaseReFetch } = useMatriculationContext();

  const {
    data: passRequest = [],
    working,
    reFetch,
    fetchMore,
  } = useLibbyFetchWithAspect(paramsFetchManagement);

  const { cicloLectivo } = useColegioEfectivizadoContext();

  const paramsFetchAnio = useMemo(
    () => ({
      daoName: 'anio',
      orderBy: 'numeroAnio',
      direction: 'asc',
      filter: {
        0: [{ path: 'nivel', value: nivel }],
        1:
          tipoEstablecimiento === SCHOOLD_TECHNIQUE ||
          Number(nivel) === level.PRIMARIO ||
          Number(nivel) === level.INICIAL
            ? {}
            : [{ path: 'numeroAnio', value: 6, method: 'notEquals' }],
      },
    }),
    [nivel, tipoEstablecimiento],
  );

  const { data: anio } = useLibbyFetch(libby, paramsFetchAnio);
  const { data: statusRequestPass } = useLibbyFetch(libby, {
    daoName: 'estado_solicitud_pases',
  });

  const handleOnClick = () => {
    customFormDialog.show({
      title: 'Solicitar Pase',
      renderComponent: (
        <PassRequestModal
          idLocation={idLocation}
          reFetch={reFetch}
          nivel={nivel}
          badgeGestionDePaseReFetch={badgeGestionDePaseReFetch}
          cicloLectivo={cicloLectivo}
        />
      ),
      sizeWidth: 'md',
    });
  };

  const handleGestionPassOnClick = (student: AnyObject) => {
    customFormDialog.show({
      title: 'Gestionar pase',
      renderComponent: (
        <GestionModalInicial
          selectAlumn={student}
          reFetch={reFetch}
          badgeGestionDePaseReFetch={badgeGestionDePaseReFetch}
          working={working}
          nivel={nivel}
          cicloLectivo={cicloLectivo}
          isEfectivizado={isEfectivizado}
        />
      ), // <ManagementModal selectAlumn={student} reFetch={reFetch} />,
      sizeWidth: 'md',
    });
  };

  const handleChange = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const handleChangeSearch = (e: React.FormEvent<EventTarget>) => {
    const target = e.target as HTMLInputElement;
    setSearch(target.value);
  };

  const handleCancelRequest = useCallback(
    async (idSolicitud: number) => {
      try {
        const confirm = await confirmDialog.show({
          title: '¿Desea cancelar la solicitud?',
          content: `Una vez cancelada la solicitud no podrá cambiar su estado.`,
          confirmText: 'Confirmar',
          cancelText: 'Cancelar',
        });
        if (confirm) {
          const passCanceled = {
            idSolicitudPases: idSolicitud,
            estadoSolicitudPases: CANCELLED,
            fechaPase: moment().toISOString(),
          };
          await libby.solicitud_pases.save(passCanceled);
          confirmDialog.handleCancel();
          reFetch();
          badgeGestionDePaseReFetch();
        }
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
        confirmDialog.handleCancel();
      }
    },
    [badgeGestionDePaseReFetch, libby.solicitud_pases, reFetch],
  );

  const passManagementLogicTable = (data: SolicitudPases[]) => {
    const rows = data.map(
      ({
        idSolicitudPases,
        alumno: {
          idAlumno,
          persona: { nombre, apellido, documento },
          estadoMatricula: { idEstadoMatricula },
        },
        anio,
        localizacionOrigen: { descripcion: establecimientoOrigen },
        localizacionDestino: {
          idLocalizacion: idLocalizacionDestino,
          descripcion: establecimientoDestino,
        },
        estadoSolicitudPases: {
          idEstadoSolicitudPases,
          descripcionSolicitudPase,
        },
        fechaPedida,
        fechaPase,
        observacion,
      }: AnyObject) => {
        const date =
          idEstadoSolicitudPases === PENDING ? fechaPedida : fechaPase;
        return {
          id: idSolicitudPases,
          icon:
            idLocalizacionDestino !== idLocation ? (
              <ArrowUpwardIcon style={{ color: primary.warning }} />
            ) : (
              <ArrowDownwardIcon />
            ),
          alumno: (
            <button
              style={{
                color: '#00A0D6',
                margin: '0',
                textAlign: 'left',
                background: 'none',
                border: 'none',
              }}
            >
              {`${nombre} ${apellido}`}
            </button>
          ),
          document: documento,
          anio: anio?.descripcionAnio ?? '-',
          Establacemiento_origen: establecimientoOrigen,
          establecimiento_destino: establecimientoDestino,
          estado:
            idLocalizacionDestino !== idLocation &&
            idEstadoSolicitudPases === PENDING ? (
              <div>
                <MainButton
                  title="Gestionar"
                  type="secondary"
                  handleOnClick={() =>
                    handleGestionPassOnClick({
                      idSolicitudPases,
                      observacion,
                      apellido,
                      nombre,
                      documento,
                      establecimientoDestino,
                      idAlumno,
                      idLocalizacionDestino,
                      idEstadoMatricula,
                      fechaPase,
                      anio,
                    })
                  }
                />
              </div>
            ) : (
              <IconStatus description={descripcionSolicitudPase} />
            ),
          fecha: moment(date, 'YYYY-MM-DD').format('DD/MM/YYYY'),
          option:
            idLocalizacionDestino === idLocation &&
            idEstadoSolicitudPases === PENDING ? (
              <PermissionBlocker id={BLOCKER_ID.ESTABLISHMENT_PASS_MANAGEMENT}>
                <OptionsModal
                  options={[
                    {
                      label: 'Cancelar',
                      onClick: () => handleCancelRequest(idSolicitudPases),
                    },
                  ]}
                />
              </PermissionBlocker>
            ) : (
              ''
            ),
        };
      },
    );

    return {
      rows,
    };
  };

  const columns = [
    {
      id: 'icon',
      label: '',
      width: '2%',
      hideSortIcon: true,
      orderById: '',
    },
    {
      id: 'alumno',
      label: 'Alumno',
      width: '10%',
      orderById: 'alumno.persona.nombre',
    },
    {
      id: 'document',
      label: 'Documento',
      width: '2%',
      orderById: 'alumno.persona.documento',
    },
    {
      id: 'anio',
      label: 'Año',
      width: '3%',
      orderById: 'anio.descripcionAnio',
    },
    {
      id: 'Establacemiento_origen',
      label: 'Establecimiento de origen',
      width: '15%',
      orderById: 'localizacionOrigen.descripcion',
    },
    {
      id: 'establecimiento_destino',
      label: 'Establecimiento de destino',
      width: '15%',
      orderById: 'localizacionDestino.descripcion',
    },
    {
      id: 'estado',
      label: 'Estado',
      width: '8%',
      orderById: 'estadoSolicitudPases.descripcionSolicitudPase',
    },
    {
      id: 'fecha',
      label: 'Fecha',
      width: '2%',
      orderById: 'fechaPedida',
    },
    {
      id: 'option',
      label: '',
      width: '2%',
      hideSortIcon: true,
      noSort: true,
      orderById: '',
    },
  ];

  const filterTables = useCallback(
    (filterSearch: any) => {
      const {
        anio: id_anio,
        estadoSolicitudPases,
        localizacionDestino,
      } = formValues;
      if (id_anio || estadoSolicitudPases || localizacionDestino) {
        const variant =
          localizacionDestino !== 'entrada' ? 'notEquals' : 'equals';

        let toObjectLibby = {
          default: filterDefault,
          defaultNivel: filterDefaultLevel,
          ...filterSearch,
        };

        Object.entries(formValues)
          .filter((filter) => !!filter[1])
          // eslint-disable-next-line
          .map((filter, i) => {
            const filterRaw = [
              { path: filter[0], value: filter[1], method: 'equals' },
            ];
            if (filter[0] === 'localizacionDestino') {
              filterRaw[0].value = idLocation;
              filterRaw[0].method = variant;
            }
            toObjectLibby = {
              ...toObjectLibby,
              [i + 1]: filterRaw,
            };
          });

        setFilterLibby(toObjectLibby);
      } else {
        setFilterLibby({
          0: filterDefault,
          1: filterDefaultLevel,
          ...filterSearch,
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [formValues],
  );

  useEffect(() => {
    filterTables(searchFilterParams);
  }, [filterTables, searchFilterParams]);

  const orderRow: SolicitudPases[] = useMemo(
    () => orderPending(passRequest),
    [passRequest],
  );
  const passManagementRows = passManagementLogicTable(orderRow);
  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setOrderBy(newOrderBy);
    setDirection(newDirection);
  };

  return (
    <>
      <HeaderTable
        title="Gestión de pases"
        titleMainButton="Solicitar Pase"
        handleOnClick={handleOnClick}
      >
        <FilterTables
          handleChangeSearch={handleChangeSearch}
          search={search}
          year={anio}
          passStatus={statusRequestPass}
          typeOfPass={typeOfPass}
          handleChange={handleChange}
          formValues={formValues}
        />
      </HeaderTable>
      <InfoTable
        working={working}
        rows={passManagementRows.rows || []}
        columns={columns}
        rowIdKey="id"
        orderBy={orderBy}
        direction={direction}
        onSortChange={handleRequestSort}
        onBottomScroll={fetchMore}
      />
    </>
  );
};

export const InitialPassManagement = DatabaseConnector(
  InitialPassManagementRaw,
)('solicitud_pases', 'anio', 'estado_solicitud_pases');
