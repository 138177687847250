import React, { useCallback } from 'react';
import { DatabaseConnector } from '@phinxlab/libby-rest-web';
import { IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';
import PersonIcon from '@material-ui/icons/Person';
import { useProfileContext } from 'src/lib/profiles/ProfileContext';
import { UserCard } from 'src/commons/components';
import { grey } from '../../../theme/colors';
import { LibbyObject } from '../../types';
import { useRolesContext } from '../../../context/RolesContext';
import { Link } from 'react-router-dom';
import { useRouterContext } from 'src/lib/router';

const headerStyles = makeStyles(() => ({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: 25,
  },
  userContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingRight: 20,
    '@media print': {
      display: 'none',
    },
  },
  userText: {
    fontFamily: 'Open Sans',
    color: grey.heading,
    fontSize: 13,
    fontWeight: 'bold',
    paddingRight: 10,
  },
  titleStyle: {
    fontFamily: 'Open Sans',
    color: grey.medium,
    fontSize: 30,
  },
  iconStyle: {
    color: grey.heading,
    fontSize: 24,
    paddingRight: 5,
  },
  arrowBackIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media print': {
      display: 'none',
    },
  },
}));

interface Title {
  title?: string;
  user?: string;
  school?: string;
  libby: LibbyObject;
  backButton?: boolean;
  backToProfile?: string;
}

const HeaderRaw = ({
  title,
  user,
  school,
  libby,
  backButton = false,
  backToProfile,
}: Title) => {
  const classes = headerStyles();
  const { setProfile } = useProfileContext();
  const goBack = useCallback(
    () => setProfile(backToProfile),
    [backToProfile, setProfile],
  );
  const { deselectRole } = useRolesContext();
  const onLogout = useCallback(async () => {
    libby.session.logout();
    deselectRole();
  }, [deselectRole, libby.session]);
  const { metadata } = useRouterContext();

  return (
    <div className={classes.container}>
      <div className={classes.arrowBackIcon}>
        {!!backButton &&
          (!backToProfile && !metadata?.callback ? (
            <Link
              to={{
                pathname: metadata.toPath ?? '/',
              }}
            >
              <IconButton>
                <ArrowBackIcon />
              </IconButton>
            </Link>
          ) : metadata?.callback ? (
            <IconButton onClick={metadata?.callback}>
              <ArrowBackIcon />
            </IconButton>
          ) : (
            <IconButton onClick={goBack}>
              <ArrowBackIcon />
            </IconButton>
          ))}
        <span className={classes.titleStyle}>{title}</span>
      </div>
      <div className={classes.userContainer}>
        {user && (
          <>
            <PersonIcon className={classes.iconStyle} />
            <span className={classes.userText}>{user}</span>
          </>
        )}
        <>
          {title === 'Solicitar Usuario' ? (
            <IconButton>
              <HomeIcon className={classes.iconStyle} onClick={goBack} />
            </IconButton>
          ) : title !== 'Roles' ? (
            <>
              <HomeIcon className={classes.iconStyle} />
              <span className={classes.userText}>{school}</span>
            </>
          ) : (
            ''
          )}
          <UserCard logout={onLogout} />
        </>
      </div>
    </div>
  );
};

export const Header = DatabaseConnector(HeaderRaw)();
