import React, { useCallback } from 'react';
import { ObservacionesModal, SelectWithField, SimpleSelect } from 'src/commons';
import { useMemo } from 'react';
import { Grid, IconButton, makeStyles } from '@material-ui/core';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import EditIcon from '@material-ui/icons/Edit';
import customFormDialog from 'src/commons/services/customFormDialog';
import { primary } from 'src/theme/colors';
import {
  Alumno,
  CicloLectivo,
  EstadoAlumno,
  Seccion,
  Pps,
} from 'src/app/models';
import { usePPSFormContext } from '../PPSFormProvider';
import { FormApi } from 'final-form';

const useStyles = makeStyles(() => ({
  spanError: {
    color: 'red',
    fontWeight: 'bold',
  },
  iconObservacion: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'end',
    color: primary.lightBlue,
    marginLeft: '20px',
  },
  observaciones: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '200px',
    textAlign: 'right',
    color: 'black',
    paddingBottom: 10,
  },
}));

interface formValuesType {
  puedeOrganizarse: string;
  cumple: string;
  consulta: string;
  demuestra: string;
  logra: string;
  manifiesta: string;
  observaciones: string;
}

export const useActitudFrenteAlAprendizajeColumns = (informePps: {
  row: {
    idAlumnoMovimiento: string;
    updateAt: string;
    updatedBy: string;
    deletedAt: Date | null;
    alumno: Alumno;
    cicloLectivo: CicloLectivo;
    estadoAlumno: EstadoAlumno;
    seccion: Seccion;
    deletedBy: string | number | null;
  };
  button: string;
}) => {
  const { form, pps } = usePPSFormContext();
  const values = (form as FormApi).getState().values;

  const classes = useStyles();

  const handleObservaciones = useCallback(() => {
    customFormDialog.show({
      title: 'Observaciones',
      renderComponent: (
        <ObservacionesModal
          content={values?.actitud?.observaciones}
          onAddContent={(content: string) =>
            (form as FormApi).change('actitud.observaciones', content)
          }
          value={values?.actitud?.observaciones}
        />
      ),
      sizeWidth: 'sm',
    });
  }, [values, form]);

  const columns = useMemo(() => {
    const observaciones = values?.actitud?.observaciones;
    const siNoOptions = [{ label: 'Sí' }, { label: 'No' }];

    const genericOptions = [
      { label: 'Siempre' },
      { label: 'Frecuentemente' },
      { label: 'Con poca frecuencia' },
    ];

    const isOpen = pps[0] !== undefined ? (pps[0] as Pps)?.abierto : true;
    const isDisabled = !isOpen;

    return [
      {
        id: 'puedeOrganizarse',
        label:
          'Puede organizarse y participar en las actividades propuestas a través de los diversos formatos.',
        width: '10%',
        hideSortIcon: true,
        render: () => {
          return (
            <SelectWithField
              form={form}
              name="actitud.puedeOrganizarse"
              placeholder="Seleccioná una opción"
              labelKey="label"
              valueKey="label"
              content={genericOptions}
              disabled={isDisabled}
            />
          );
        },
      },
      {
        id: 'cumple',
        label:
          'Cumple con las tareas/actividades/producciones en los tiempos establecidos',
        width: '10%',
        hideSortIcon: true,
        render: () => (
          <SelectWithField
            form={form}
            name="actitud.cumple"
            placeholder="Seleccioná una opción"
            labelKey="label"
            valueKey="label"
            content={genericOptions}
            disabled={isDisabled}
          />
        ),
      },
      {
        id: 'consulta',
        label:
          'Consulta, revisa y corrige su trabajo teniendo en cuenta las intervenciones y/o retroalimentación de sus docentes',
        width: '10%',
        hideSortIcon: true,
        render: () => (
          <SelectWithField
            form={form}
            name="actitud.consulta"
            placeholder="Seleccioná una opción"
            labelKey="label"
            valueKey="label"
            content={genericOptions}
            disabled={isDisabled}
          />
        ),
      },
      {
        id: 'demuestra',
        label:
          'Demuestra motivación e Interés sobre las actividades propuestas y sus aportes evidencian su compromiso frente a la tarea',
        width: '10%',
        hideSortIcon: true,
        render: () => (
          <SelectWithField
            form={form}
            name="actitud.demuestra"
            placeholder="Seleccioná una opción"
            labelKey="label"
            valueKey="label"
            content={genericOptions}
            disabled={isDisabled}
          />
        ),
      },
      {
        id: 'logra',
        label:
          'Logra comprometerse con su aprendizaje reconociendo logros y dificultades',
        width: '10%',
        hideSortIcon: true,
        render: () => (
          <SelectWithField
            form={form}
            name="actitud.logra"
            placeholder="Seleccioná una opción"
            labelKey="label"
            valueKey="label"
            content={genericOptions}
            disabled={isDisabled}
          />
        ),
      },
      {
        id: 'manifiesta',
        label: 'Manifiesta predisposición ante las actividades planteadas',
        width: '10%',
        hideSortIcon: true,
        render: () => (
          <SelectWithField
            form={form}
            name="actitud.manifiesta"
            placeholder="Seleccioná una opción"
            labelKey="label"
            valueKey="label"
            content={genericOptions}
            disabled={isDisabled}
          />
        ),
      },
      {
        id: 'observaciones',
        label: 'Observaciones',
        width: '5%',
        hideSortIcon: true,
        render: () => (
          <Grid>
            {values?.actitud?.observaciones ? (
              <>
                <Grid className={classes.iconObservacion}>
                  <div className={classes.observaciones}>{observaciones}</div>
                  <IconButton
                    className={classes.iconObservacion}
                    disabled={isDisabled}
                    onClick={() => handleObservaciones()}
                  >
                    {<LibraryBooksIcon />}
                  </IconButton>
                </Grid>
              </>
            ) : (
              <Grid className={classes.iconObservacion}>
                <IconButton
                  disabled={isDisabled}
                  onClick={() => handleObservaciones()}
                >
                  {<EditIcon style={{ color: primary.lightBlue }} />}
                </IconButton>
              </Grid>
            )}
          </Grid>
        ),
      },
    ];
  }, [values?.actitud?.observaciones, pps, form, classes, handleObservaciones]);

  return columns;
};
