import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { EstadoDisciplina } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'estado_disciplina';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEstadoDisciplina',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEstadoDisciplina',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EstadoDisciplina
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EstadoDisciplina,
      hoc: { ...defaultOptions.hoc, ...businessOptions.EstadoDisciplina.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EstadoDisciplina>(DAO_NAME, optionsToUse);

// hooks
export const useEstadoDisciplinaFetchById = fetchById;
export const useEstadoDisciplinaLibbyCall = libbyCall;
export const useEstadoDisciplinaDAO = libbyDAO;
export const useEstadoDisciplinaLibbyFetch = libbyFetch;

// components/hoc
export const EstadoDisciplinaAutocomplete = entityAutocomplete;
export const EstadoDisciplinaCall = renderPropCall;
export const EstadoDisciplinaById = renderPropFetchById;
export const EstadoDisciplinaSelect = simpleSelect;
export const EstadoDisciplinaAccordionSelector = accordionSelector;
export const EstadoDisciplinaSelectedChips = selectedChips;

// context
// ByIdContext
export const useEstadoDisciplinaByIdContext = useByIdContext;
export const EstadoDisciplinaByIdProvider = ByIdProvider;
export const EstadoDisciplinaByIdContext = ByIdContext;
// GeneralContext
export const useEstadoDisciplinaContext = useGeneralContext;
export const EstadoDisciplinaProvider = GeneralProvider;
export const EstadoDisciplinaContext = GeneralContext;
