import { useCallback } from 'react';

export default (
  setFormValues: (arg0: (prev: any) => any[]) => void,
  idCurrentSection: String,
  setStudentsData: (arg0: (prev: any) => any) => void,
) => {
  const handleChangePassSection = useCallback(
    (e: React.FormEvent<EventTarget>, restStudentData) => {
      const target = e.target as HTMLInputElement;
      const idSeccion = target.value;

      const newStudentData = {
        ...restStudentData,
        sectionPass: idSeccion,
        estadoProyeccionMotivo:
          idSeccion !== 'No Concurrirá'
            ? null
            : restStudentData.estadoProyeccionMotivo,
      };

      setStudentsData((prev: any) =>
        prev.map((student: any) => {
          if (student.idStudent === restStudentData.idStudent) {
            return newStudentData;
          }
          return student;
        }),
      );

      setFormValues((prev: any) =>
        prev.map((student: any) => {
          if (student.alumno === restStudentData.idStudent) {
            student = {
              ...student,
              idSeccion: newStudentData.sectionPass,
              estadoProyeccionMotivo: newStudentData.estadoProyeccionMotivo,
            };
          }
          return student;
        }),
      );
    },
    [setFormValues, setStudentsData],
  );

  return handleChangePassSection;
};
