import { EstadoPresente } from 'src/app/models/presentismo_v2';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'estado_presente';
// NO USAR DEPRECADO
const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idEstadoPresente',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idEstadoPresente',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.EstadoPresente
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.EstadoPresente,
      hoc: { ...defaultOptions.hoc, ...businessOptions.EstadoPresente.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<EstadoPresente>(DAO_NAME, optionsToUse);
// NO USAR DEPRECADO
// hooks
export const useEstadoPresenteFetchById = fetchById;
export const useEstadoPresenteLibbyCall = libbyCall;
export const useEstadoPresenteDAO = libbyDAO;
export const useEstadoPresenteLibbyFetch = libbyFetch;

// components/hoc
export const EstadoPresenteAutocomplete = entityAutocomplete;
export const EstadoPresenteCall = renderPropCall;
export const EstadoPresenteById = renderPropFetchById;
export const EstadoPresenteSelect = simpleSelect;
export const EstadoPresenteAccordionSelector = accordionSelector;
export const EstadoPresenteSelectedChips = selectedChips;

// context
// ByIdContext
export const useEstadoPresenteByIdContext = useByIdContext;
export const EstadoPresenteByIdProvider = ByIdProvider;
export const EstadoPresenteByIdContext = ByIdContext;
// GeneralContext
export const useEstadoPresenteContext = useGeneralContext;
export const EstadoPresenteProvider = GeneralProvider;
export const EstadoPresenteContext = GeneralContext;
