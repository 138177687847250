import { AccionesSoloOrientacionesDAO } from 'src/platform/libby/dao';
import { makeBusinessEntity } from '../../../lib/libby/generator';

const DAO_NAME = 'acciones_solo_orientaciones';

const {
  hook: { libbyDAO, libbyFetch, libbyCall },
} = makeBusinessEntity<AccionesSoloOrientacionesDAO>(DAO_NAME, {});

// hooks
export const useAccionesSoloOrientacionesDAO = libbyDAO;
export const useAccionesSoloOrientacionesLibbyFetch = libbyFetch;
export const useAccionesSoloOrientacionesLibbyCall = libbyCall;
