import { useMemo } from 'react';

export const useCicloLectivoAnioFilter = (from = 2021, to = 2025) => {
  const filter = useMemo(() => {
    let filter = [];
    for (let anio = from; anio < to; anio++) {
      filter.push({
        path: 'anio',
        value: anio,
      });
    }
    return { ciclo: filter };
  }, [from, to]);
  return filter;
};
