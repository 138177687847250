import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import LibraryBooksOutlinedIcon from '@material-ui/icons/LibraryBooksOutlined';
import { Grid, Typography, Button, Tooltip } from '@material-ui/core';
import { ModalBase } from 'src/commons/const/ui';
import { Loading, NIVEL } from 'src/commons';
import { grey } from 'src/theme/colors';
import { CertificadoSeptimo, CicloLectivo, TipoInformes } from 'src/app/models';
import { useInformesHistoryDAO } from 'src/app/business';
import { OptionsModal } from './OptionsModal';
import { useRolesContext } from 'src/context/RolesContext';

export type HideOptionDocumentsFunction = (data: any) => boolean;

export type OptionsReportsModalProps<T = any> = {
  color?: 'inherit' | 'primary' | 'secondary' | 'default';
  classIcon?: any;
  reportTypes: TipoInformes[] | undefined;
  reports: any[];
  data?: any;
  cicloLectivo?: CicloLectivo | null;
  customStyleIcon?: any;
  userInfo: any;
  alumno: any;
  anio: any;
  seccion: any;
  certificadoSeptimo?: CertificadoSeptimo;
};

export const OptionsReportsModal: FunctionComponent<
  OptionsReportsModalProps
> = ({
  cicloLectivo,
  reportTypes,
  // reports: studentReports,
  reports: _reports,
  userInfo,
  alumno,
  anio,
  seccion,
  certificadoSeptimo,
  ...props
}) => {
  const InformesDAO = useInformesHistoryDAO();
  const [reports, setReports] = useState<any>(_reports);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const [modalOpen, setModalOpen] = useState<boolean>(false);

  let idCicloLectivoActual = cicloLectivo?.idCicloLectivo;

  const reportById = useCallback(
    (reportType: TipoInformes) => {
      return reports?.find(
        (report: any) =>
          report.tipoInforme.idTipoInforme === reportType.idTipoInforme,
      );
    },
    [reports],
  );

  // TODO
  // IMPROVE THIS
  const setSeenBy = useCallback(
    (report: any, userInfoId: string) => {
      const newReports = reports.map((_report: any) =>
        report.idTipoInforme === _report.tipoInforme.idTipoInforme
          ? { ..._report, seenBy: userInfoId }
          : { ..._report },
      );
      setReports(newReports);
    },
    [reports],
  );

  const onClick = useCallback(
    async (item: any) => {
      const report = reportById(item);

      if (report) {
        if (!report.seenBy) {
          try {
            await InformesDAO.save({ ...report, seenBy: userInfo.id });
            setSeenBy(item, userInfo.id);
          } catch (error) {
            console.log(error);
          }
        }
        window.open(
          report.preview.urlDrive && report.preview.template === null
            ? report.preview.urlDrive
            : `/private/preview/${report.preview.previewId}?ciclolectivo=${idCicloLectivoActual}`,
        );
      }
    },
    [InformesDAO, reportById, setSeenBy, userInfo.id, idCicloLectivoActual],
  );

  const isDisabled = useCallback(
    (report: TipoInformes) => {
      const reportFind = reportById(report);
      return !reportFind;
    },
    [reportById],
  );

  const isVisited = useCallback(
    (report: TipoInformes) => {
      const reportFind = reportById(report);
      return reportFind && Boolean(reportFind.seenBy);
    },
    [reportById],
  );

  const closeConfirmModal = () => {
    setModalOpen(false);
  };

  const renderCertificados = (
    <Grid container direction="column">
      <Typography style={{ margin: '10px 0' }}>
        {certificadoSeptimo ? (
          <a
            target="_blank"
            rel="noreferrer"
            style={{ textDecoration: 'none' }}
            href={certificadoSeptimo?.enlace}
          >
            Descargar
          </a>
        ) : (
          'No hay certificados disponibles para este alumno'
        )}
      </Typography>
    </Grid>
  );

  const searchForCertificate = useCallback(async () => {
    setModalOpen(true);
  }, []);

  const addModalActions = (
    <>
      <Button onClick={closeConfirmModal} color="primary" variant="outlined">
        Cerrar
      </Button>
    </>
  );

  const modal = (
    <ModalBase
      open={modalOpen}
      onClose={closeConfirmModal}
      title="Certificado 7°"
      content={renderCertificados}
      actions={addModalActions}
      customTheme={null}
    />
  );

  const { selectedRole } = useRolesContext();

  const reportsToModalOptions = useMemo(
    () =>
      reportTypes?.map((report: TipoInformes): any => {
        const esSeptimo =
          (seccion?.idAnio === 7 || seccion?.idAnio === 9) &&
          Number(report?.idTipoInforme) === 6 &&
          anio === cicloLectivo?.anio;
        const updatedReport =
          Number(report.idTipoInforme) === 1
            ? { ...report, descripcion: 'Informe' }
            : report;
        return {
          ...updatedReport,
          label:
            Number(selectedRole.nivel) === NIVEL.INICIAL
              ? updatedReport.descripcion
              : report?.descripcion,
          onClick: esSeptimo ? searchForCertificate : onClick,
          isVisited: isVisited(report),
          isDisabled: esSeptimo ? !certificadoSeptimo : isDisabled(report),
        };
      }),
    [
      reportTypes,
      seccion?.idAnio,
      anio,
      cicloLectivo?.anio,
      selectedRole.nivel,
      searchForCertificate,
      onClick,
      isVisited,
      certificadoSeptimo,
      isDisabled,
    ],
  );

  const esSeptimo = seccion?.idAnio === 7 || seccion?.idAnio === 9;
  const canDisable = !esSeptimo
    ? !reports?.length
    : !reports?.length && !certificadoSeptimo;

  const iconStyles = canDisable ? { color: grey.disabled } : {};

  const toolTipTitle = isOpen
    ? ''
    : !reports?.length
    ? 'No hay informes cargados para este estudiante'
    : 'Ver informes';

  return !reportsToModalOptions ? (
    <Loading />
  ) : (
    // using span to handle tooltip
    <Tooltip title={toolTipTitle} open={showTooltip}>
      <span
        onMouseEnter={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onClick={() => setShowTooltip(false)}
      >
        {modal}
        <OptionsModal
          options={reportsToModalOptions}
          customStyleIcon={iconStyles}
          icon={LibraryBooksOutlinedIcon}
          isDisabledIcon={canDisable}
          setIsOpen={setIsOpen}
        />
      </span>
    </Tooltip>
  );
};
