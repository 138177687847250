import React, { useCallback, useEffect, useState } from 'react';
import { Card, CardContent, CardHeader, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { AppTheme } from '../../../../../theme';
import { MainInput } from '../../../../../commons/components/MainInput';
import {
  RepositorioCicloAccordionSelector,
  RepositorioCicloSelectedChips,
  AnioAccordionSelector,
  AnioSelectedChips,
  RepositorioAreasSelectedChips,
  RepositorioTransversalesSelectedChips,
  RepositorioEspaciosCurricularesAccordionSelector,
  RepositorioAudienciaItemAccordionSelector,
  RepositorioTipoItemAccordionSelector,
  RepositorioTipoItemSelectedChips,
  RepositorioEspaciosCurricularesSelectedChips,
  RepositorioAreasAccordionSelector,
  RepositorioAudienciaItemSelectedChips,
  RepositorioTransversalesAccordionSelector,
} from '../../../../../app/business';
import {
  RepositorioAudienciaItem,
  RepositorioTipoItem,
  RepositorioAreas,
  RepositorioCiclo,
  RepositorioTransversales,
  RepositorioEspaciosCurriculares,
  Anio,
} from '../../../../../app/models';

const useStyle = makeStyles<AppTheme>((theme) => ({
  container: {
    backgroundColor: theme.palette.common.neutral,
  },
  filterItem: {
    width: '100%',
  },
}));

export interface JoinedFilters {
  tipos: RepositorioTipoItem[];
  ciclos: RepositorioCiclo[];
  anios: Anio[];
  areas: RepositorioAreas[];
  espacioCurricular: RepositorioEspaciosCurriculares[];
  audiencia: RepositorioAudienciaItem[];
  transversales: RepositorioTransversales[];
  search: string;
}
export interface FilterProps {
  value: JoinedFilters;
  onChange: (newValue: JoinedFilters) => void;
  idNivel: string | number | undefined;
}

export const Filter = ({ value, onChange, idNivel }: FilterProps) => {
  const classes = useStyle();
  const [search, setSearch] = useState(value.search);
  const [tipos, setTipos] = useState<RepositorioTipoItem[]>(value.tipos);
  const [ciclos, setCiclos] = useState<RepositorioCiclo[]>(value.ciclos);
  const [anios, setAnios] = useState<Anio[]>(value.anios);
  const [areas, setAreas] = useState<RepositorioAreas[]>(value.areas);
  const [espacioCurricular, setEspacioCurricular] = useState<
    RepositorioEspaciosCurriculares[]
  >(value.espacioCurricular);
  const [transversales, setTransversales] = useState<
    RepositorioTransversales[]
  >(value.transversales);
  const [audiencia, setAudiencia] = useState<RepositorioAudienciaItem[]>(
    value.audiencia,
  );

  const onSearchChange = useCallback(({ target: { value: _value } }) => {
    setSearch(_value);
  }, []);

  useEffect(() => {
    const debounce = setTimeout(() => {
      onChange({
        tipos,
        ciclos,
        audiencia,
        search,
        anios,
        areas,
        espacioCurricular,
        transversales,
      });
    }, 600);
    return () => {
      clearTimeout(debounce);
    };
  }, [
    audiencia,
    ciclos,
    onChange,
    search,
    tipos,
    anios,
    areas,
    espacioCurricular,
    transversales,
  ]);
  return (
    <Card className={classes.container}>
      <CardHeader
        title="Filtrar recursos"
        subheader={
          <>
            <RepositorioAudienciaItemSelectedChips
              items={audiencia}
              onChange={setAudiencia}
            />
            <AnioSelectedChips items={anios} onChange={setAnios} />
            <RepositorioEspaciosCurricularesSelectedChips
              items={espacioCurricular}
              onChange={setEspacioCurricular}
            />
            <RepositorioTransversalesSelectedChips
              items={transversales}
              onChange={setTransversales}
            />
            <RepositorioAreasSelectedChips items={areas} onChange={setAreas} />
            <RepositorioTipoItemSelectedChips
              items={tipos}
              onChange={setTipos}
            />
            <RepositorioCicloSelectedChips
              items={ciclos}
              onChange={setCiclos}
            />
          </>
        }
      />
      <CardContent>
        <MainInput
          type="search"
          handleChange={onSearchChange}
          placeholder="Buscar"
          name="search"
          value={search}
          fullWidth
        />
        <Grid container direction="row" spacing={2} alignItems="stretch">
          <Grid item className={classes.filterItem}>
            <RepositorioCicloAccordionSelector
              value={ciclos}
              onChange={setCiclos}
              title="Ciclo"
            />
          </Grid>
          <Grid item className={classes.filterItem}>
            <RepositorioTipoItemAccordionSelector
              value={tipos}
              onChange={setTipos}
              title="Tipo de recurso"
            />
          </Grid>
          <Grid item className={classes.filterItem}>
            <AnioAccordionSelector
              value={anios}
              onChange={setAnios}
              title="Sala/Grado/Año"
              filter={{ 0: [{ path: 'id_nivel', value: idNivel }] }}
            />
          </Grid>
          <Grid item className={classes.filterItem}>
            <RepositorioAudienciaItemAccordionSelector
              value={audiencia}
              onChange={setAudiencia}
              title="Audiencia"
            />
          </Grid>
          {idNivel === 2 || idNivel === 3 ? (
            <Grid item className={classes.filterItem}>
              <RepositorioAreasAccordionSelector
                value={areas}
                onChange={setAreas}
                title="Área"
              />
            </Grid>
          ) : null}
          {idNivel === 2 && (
            <Grid item className={classes.filterItem}>
              <RepositorioTransversalesAccordionSelector
                value={transversales}
                onChange={setTransversales}
                title="Transversales"
              />
            </Grid>
          )}
          {idNivel === 3 && (
            <Grid item className={classes.filterItem}>
              <RepositorioEspaciosCurricularesAccordionSelector
                value={espacioCurricular}
                onChange={setEspacioCurricular}
                title="Espacio Curricular"
              />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
