import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { PeriodoInicio } from '../../models';
import { businessOptions } from '../businessOptions';
import { PeriodoInicioDAO } from 'src/platform/libby/dao/Presentismov3';

const DAO_NAME = 'periodoInicio';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idPeriodoInicio',
      labelKey: 'description',
    },
    withSelectedChips: {
      idKey: 'idPeriodoInicio',
      labelKey: 'description',
    },
    withSimpleSelect: {
      labelKey: 'description',
      valueKey: 'idPeriodoInicio',
      title: '',
      placeholder: 'Seleccioná PeriodoInicio',
      name: 'id',
    },
  },
};

const optionsToUse = !businessOptions.periodoInicio
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.periodoInicio,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.periodoInicio.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
  },
} = makeBusinessEntity<PeriodoInicio, PeriodoInicioDAO>(DAO_NAME, optionsToUse);

// hooks
export const usePeriodoInicioFetchById = fetchById;
export const usePeriodoInicioLibbyCall = libbyCall;
export const usePeriodoInicioDAO = libbyDAO;
export const usePeriodoInicioLibbyFetch = libbyFetch;

// components/hoc
export const PeriodoInicioAutocomplete = entityAutocomplete;
export const PeriodoInicioCall = renderPropCall;
export const PeriodoInicioById = renderPropFetchById;
export const PeriodoInicioSelect = simpleSelect;
