import React from 'react';
import { AnyObject } from 'src/commons';

export const useTableOperations = (
  order: 'asc' | 'desc',
  onSortChange?: (prop: any, order: 'asc' | 'desc') => void,
  onBottomScroll?: () => void,
) => {
  const handleScroll = (e: any) => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop <= e.target.clientHeight + 100;
    if (bottom && onBottomScroll) {
      onBottomScroll();
    }
  };

  const handleRequestSort = (event: AnyObject, property: any) => {
    if (onSortChange) {
      onSortChange(property, order === 'asc' ? 'desc' : 'asc');
    }
  };

  return {
    handleScroll,
    handleRequestSort,
  };
};
