import { Box, Checkbox, FormControlLabel, Grid } from '@material-ui/core';

import React from 'react';
import { AccionesFormValues, ArbolContent } from '../types';

interface FormCheckboxProps {
  label: string;
  name?: string;
  checked?: boolean;
  indeterminate?: boolean;
  handleChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
}

const FormCheckbox = ({
  label,
  name,
  checked,
  indeterminate,
  handleChange,
}: FormCheckboxProps) => {
  return (
    <FormControlLabel
      label={label}
      name={name}
      labelPlacement="end"
      control={
        <Checkbox
          name={name}
          checked={checked}
          indeterminate={indeterminate}
          onChange={handleChange}
        />
      }
    />
  );
};

const makeCheckboxField = (contenidos: ArbolContent[]) => {
  return contenidos.map((contenido) => {
    const secondLevel = contenido.contenidos ? contenido.contenidos : [];

    return (
      <Grid item container direction="column">
        <FormCheckbox
          label={contenido.label}
          checked={contenido.checked}
          name={contenido.name}
          indeterminate={contenido.indeterminate}
          handleChange={contenido.handleChange}
        />
        <Grid component={Box} display="flex" flexDirection="column" ml={3}>
          {makeCheckboxField(secondLevel)}
        </Grid>
      </Grid>
    );
  });
};

export const ArbolCheckbox = ({ contenido }: { contenido: ArbolContent[] }) => {
  return <Grid container>{makeCheckboxField(contenido)}</Grid>;
};
