import React, { ReactEventHandler, useEffect, useMemo, useState } from 'react';
import {
  Card,
  CardActionArea,
  Divider,
  Grid,
  makeStyles,
  Paper,
  Box,
  ListItem,
  IconButton,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Loading, Tooltips } from 'src/commons';
import { grey, primary } from '../../../../theme/colors';
import { EspacioCurricular, Seccion } from '../../../../app/models';
import { imageCard } from '../const/imageCard';
import { useEspacioCurricularSeccionLibbyCall } from 'src/app/business';

const useStyles = makeStyles((theme) => ({
  rootClose: {
    maxHeight: 200,
    marginBottom: theme.spacing(1),
  },
  rootOpen: {
    maxHeight: 500,
    transition: '0.8s',
    marginBottom: theme.spacing(1),
  },
  CardActionArea: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    height: 140,
  },
  CardActionArea2: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
    height: 120,
  },
  headerContent: {
    backgroundColor: primary.headerGreenCard,
    height: 33,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    paddingLeft: 12,
  },
  divisionContent: {
    paddingLeft: 12,
  },
  headerText: {
    color: primary.white,
    fontSize: 13,
    fontFamily: 'Open Sans',
  },
  textAssignatureContainer: {
    paddingTop: 10,
    height: 60,
  },
  assignatureTitle: {
    fontSize: 18,
    fontWeight: 600,
    color: grey.heading,
    fontFamily: 'Open Sans',
    paddingLeft: 10,
  },
  sectionText: {
    marginTop: 8,
  },
  divisionText: {
    justifyContent: 'flex-start',
    fontSize: '13px',
    color: primary.black,
    fontFamily: 'Open Sans',
    paddingRight: 15,

    textTransform: 'uppercase',
  },
  anioText: {
    fontSize: 12,
    color: grey.heading,
    fontFamily: 'Open Sans',
    fontWeight: 600,
    textAlign: 'center',
  },
  anioContainer: {
    marginTop: theme.spacing(0),
    marginBottom: theme.spacing(-1),
    paddingLeft: 10,
  },
  imagenCard: {
    height: '100%',
    width: '100%',
    marginBottom: theme.spacing(-1.5),
    borderTopLeftRadius: 10,
    borderTopRightRadius: 10,
  },
  drawerOpenContainer: {
    transition: '0.8s',
    width: '100%',
    marginBottom: 0,
  },
  drawerCloseContainer: {
    transition: '0.8s',
    width: '100%',
    marginBottom: 0,
    marginTop: 0,
  },
  seccionesText: {
    alignItems: 'flex-start',
    fontSize: '14px',
    color: primary.black,
    fontFamily: 'Open Sans',
    paddingRight: 15,
    textTransform: 'uppercase',
    paddingLeft: 10,
    paddingTop: 10,
    justifyContent: 'center',
  },
  iconButtonComponent: {
    transition: '0.8s',
    backgroundColor: primary.white,
    '&:hover': {
      backgroundColor: primary.white,
    },
    justifyContent: 'flex-end',
    alignItems: 'flex-end',
  },
}));

export interface EspacioCurricularCardProps {
  espacioCurricular: EspacioCurricular;
  secciones: Seccion[];
  onClick: ReactEventHandler;
}

const NUMERO_ANIO = 3;

export const EspacioCurricularCard = ({
  espacioCurricular,
  secciones,
  onClick,
}: EspacioCurricularCardProps) => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);

  const details = useMemo(() => {
    secciones.sort((a, b) => (a.nombreSeccion > b.nombreSeccion ? 1 : -1));
    let result = secciones.length ? (
      <Box component="div" textOverflow="ellipsis" overflow="hidden" my={1}>
        {secciones.map((seccion: any, index: number) => {
          return (
            <>
              <div className={classes.sectionText}>
                <span key={index} className={classes.divisionText}>
                  {seccion.nombreSeccion}
                  <br />
                </span>
              </div>
            </>
          );
        })}
      </Box>
    ) : null;

    return result;
  }, [classes.divisionText, classes.sectionText, secciones]);

  return (
    <Paper>
      <Grid onClick={onClick}>
        <CardActionArea>
          <img
            src={
              espacioCurricular?.materia?.imagenes?.imagenUrl ||
              imageCard.fallback
            }
            alt="materia"
            className={classes.imagenCard}
          />
        </CardActionArea>
      </Grid>
      <Card className={!isOpen ? classes.rootClose : classes.rootOpen}>
        <CardActionArea
          className={
            espacioCurricular.planEstudio.descripcionPlanEstudio.length <= 40
              ? classes.CardActionArea2
              : classes.CardActionArea
          }
          onClick={onClick}
        >
          <div className={classes.headerContent}>
            {espacioCurricular.materia.anio.numeroAnio < NUMERO_ANIO ? (
              <span className={classes.headerText}>
                {espacioCurricular.materia.area.descripcion}
              </span>
            ) : (
              <span className={classes.headerText}>
                {espacioCurricular.materia.orientacion.descripcionOrientacion}
              </span>
            )}
          </div>
          <Grid
            container
            className={classes.textAssignatureContainer}
            direction="column"
          >
            <Grid item className={classes.assignatureTitle}>
              <span>{espacioCurricular.descripcion}</span>
            </Grid>
          </Grid>
          <Grid item className={classes.anioContainer}>
            <Tooltips
              title={espacioCurricular.planEstudio.descripcionPlanEstudio}
            >
              <Box
                component="span"
                className={classes.anioText}
                textOverflow="ellipsis"
                overflow="hidden"
                my={1}
              >
                {espacioCurricular.planEstudio.descripcionPlanEstudio}
              </Box>
            </Tooltips>
          </Grid>
        </CardActionArea>
        <nav
          className={
            isOpen ? classes.drawerOpenContainer : classes.drawerCloseContainer
          }
        >
          <Divider />
          <Grid>
            <Grid container justify="space-between">
              {!isOpen ? (
                <Typography className={classes.seccionesText}>
                  Ver secciones
                </Typography>
              ) : (
                <Typography className={classes.seccionesText}>
                  Secciones
                </Typography>
              )}
              <Divider />
              <IconButton
                onClick={() => setIsOpen(!isOpen)}
                className={classes.iconButtonComponent}
              >
                {isOpen ? (
                  <ExpandMoreIcon
                    style={{
                      transform: 'rotate(180deg)',
                      transition: '0.8s',
                      color: primary.lightBlue,
                    }}
                  />
                ) : (
                  <ExpandMoreIcon
                    style={{ transition: '0.8s', color: primary.lightBlue }}
                  />
                )}
              </IconButton>
            </Grid>
          </Grid>
          {isOpen && (
            <ListItem style={{ overflowY: 'auto', maxHeight: '300px' }}>
              <Grid alignItems="center" className={classes.divisionContent}>
                {details}
              </Grid>
            </ListItem>
          )}
        </nav>
      </Card>
    </Paper>
  );
};
