import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { CalificacionesPrimarioDAO } from '../../platform/libby/dao';
import { CalificacionesPrimario } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'calificaciones_primario';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idCalificacionesPrimario',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idCalificacionesPrimario',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.CalificacionesPrimario
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.CalificacionesPrimario,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.CalificacionesPrimario.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<CalificacionesPrimario, CalificacionesPrimarioDAO>(
  DAO_NAME,
  optionsToUse,
);

// hooks
export const useCalificacionesPrimarioFetchById = fetchById;
export const useCalificacionesPrimarioLibbyCall = libbyCall;
export const useCalificacionesPrimarioDAO = libbyDAO;
export const useCalificacionesPrimarioLibbyFetch = libbyFetch;

// components/hoc
export const CalificacionesPrimarioAutocomplete = entityAutocomplete;
export const CalificacionesPrimarioCall = renderPropCall;
export const CalificacionesPrimarioById = renderPropFetchById;
export const CalificacionesPrimarioSelect = simpleSelect;
export const CalificacionesPrimarioAccordionSelector = accordionSelector;
export const CalificacionesPrimarioSelectedChips = selectedChips;

// context
// ByIdContext
export const useCalificacionesPrimarioByIdContext = useByIdContext;
export const CalificacionesPrimarioByIdProvider = ByIdProvider;
export const CalificacionesPrimarioByIdContext = ByIdContext;
// GeneralContext
export const useCalificacionesPrimarioContext = useGeneralContext;
export const CalificacionesPrimarioProvider = GeneralProvider;
export const CalificacionesPrimarioContext = GeneralContext;
