import { Param } from './../../../../utils/setSearchParamsMultipleWords';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import { Seccion, SolicitudPases } from 'src/app/models';
import { PassRequestRow } from '../../Matriculation/passManagement/utils/const';

export const useLegajoLogic = (seccion: Seccion[] = []) => {
  const history = useHistory();

  const findCorrectEstablecimiento = (pase: SolicitudPases) => {
    const establecimiento =
      pase.estadoSolicitudPases.descripcionSolicitudPase === 'Aprobado'
        ? { ...pase.localizacionDestino.establecimiento }
        : { ...pase.localizacionOrigen.establecimiento };
    return establecimiento;
  };
  let schollLastYear = seccion[seccion.length - 1]?.anio.idAnio;
  const toLegajoAlumnoFromSolicitudPase = (
    alumnPass: PassRequestRow,
    passRequests: SolicitudPases[],
  ) => {
    const findAlumnoByPassId = (pase: SolicitudPases) =>
      pase.alumno.persona.documento === alumnPass.document;
    // @ts-ignore
    const paseAlumno: SolicitudPases = passRequests.find(findAlumnoByPassId);
    const { alumno } = paseAlumno;
    history.push(
      `/private/legajo-alumno/${alumno.idAlumno}?year=${schollLastYear}`,
    );
  };

  const toLegajoAlumno = useCallback(
    (id: string | number) => {
      if (!id) return;
      history.push(`/private/legajo-alumno/${id}?year=${schollLastYear}`);
    },
    [history, schollLastYear],
  );
  return {
    findCorrectEstablecimiento,
    toLegajoAlumnoFromSolicitudPase,
    toLegajoAlumno,
  };
};
