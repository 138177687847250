//Aunque aparecen en el archivo, del ticket MIESC-3084.
//20171700: Entra en escuelas normales,
//20107900: Entra en escuelas técnicas.
// Según ticket jira 3161 mantis 35366 este establecimiento debe comportarse como un establecimiento normal
//20038300: Entra en escuelas normales,

type EstablecimientoReingresoNocturosYComerciales = {
  [key: string]: {
    descripcion: string;
    cue: number;
    tipo: string;
  };
};

export const establecimientoReingresoNocturosYComerciales: EstablecimientoReingresoNocturosYComerciales =
  {
    // 20038300: {
    //   descripcion: 'Colegio N.º 16 D.E. 15 «Guillermo Rawson»',
    //   cue: 200383,
    //   tipo: 'nocturno',
    // },
    20048700: {
      descripcion:
        'Escuela de Comercio N.º 21 D.E. 11 «Cap. de Navío Hipólito Bouchard»',
      cue: 200487,
      tipo: 'nocturno',
    },
    20056300: {
      descripcion: 'Escuela de Comercio N.º 2 D.E. 1 «Dr. Antonio Bermejo»',
      cue: 200563,
      tipo: 'nocturno',
    },
    20062300: {
      descripcion: 'Escuela de Comercio N.º 1 D.E. 4 «Joaquín V. González»',
      cue: 200623,
      tipo: 'nocturno',
    },
    //    20171700:{descripcion: 'Liceo N.º 7 DE 1 «Domingo Faustino Sarmiento»', cue:201717 tipo: 'nocturno'},
    20063700: {
      descripcion: 'Escuela de Comercio N.º 12 D.E. 21 «Juan XXIII»',
      cue: 200637,
      tipo: 'nocturno',
    },
    20085900: {
      descripcion: 'Escuela de Comercio N.º 11 D.E. 17 «Dr. José Peralta»',
      cue: 200859,
      tipo: 'nocturno',
    },
    20094400: {
      descripcion: 'Liceo N.º 8 DE 13 «Esteban Echeverria»',
      cue: 200944,
      tipo: 'nocturno',
    },
    20111700: {
      descripcion:
        'Escuela de Comercio N.º 29 D.E. 7 «Marina Mercante Argentina»',
      cue: 201117,
      tipo: 'nocturno',
    },
    20113700: {
      descripcion: 'Escuela de Comercio N.º 35 D.E. 21 «Leopoldo Marechal»',
      cue: 201137,
      tipo: 'nocturno',
    },
    20125000: {
      descripcion: 'Escuela de Comercio N.º 9 D.E. 11 «José Ingenieros»',
      cue: 201250,
      tipo: 'nocturno',
    },
    20126700: {
      descripcion:
        'Escuela. Superior de Comercio N..º 3 D.E. 7 «Hipólito Vieytes»',
      cue: 201267,
      tipo: 'nocturno',
    },
    20142300: {
      descripcion: 'Escuela de Comercio N.º 10 D.E. 1 «Islas Malvinas»',
      cue: 201423,
      tipo: 'nocturno',
    },
    20160600: {
      descripcion: 'Escuela de Comercio N.º 36 D.E. 3 «Isaac Halperin»',
      cue: 201606,
      tipo: 'nocturno',
    },
    20111300: {
      descripcion:
        'Bachillerato con Orientación Artística para Adultos Nº 01 "Antonio Berni" DE 08',
      cue: 201113,
      tipo: 'nocturno',
    },
    20141200: {
      descripcion:
        'Bachillerato con Orientación Artística para Adultos Nº 04 "Xul Solar" DE 06',
      cue: 201412,
      tipo: 'nocturno',
    },
    20066500: {
      descripcion:
        'Esc. Sup. De Educación Artística en Artes Visuales «Lola Mora»',
      cue: 200665,
      tipo: 'nocturno',
    },
    //    20107900:{descripcion: 'Escuela Técnica N.º 18 D.E. 11 «Jose Antonio Alvarez Condarco»', cue:201079 tipo: 'nocturno'},
    20039000: {
      descripcion: 'Escuela de Comercio N.º 26 D.E. 1 «Enrique de Vedia»',
      cue: 200390,
      tipo: 'nocturno',
    },
    20235100: {
      descripcion: 'Escuela de Educación Media N.º 6 D.E. 21',
      cue: 202351,
      tipo: 'reingreso',
    },
    20234700: {
      descripcion: 'Esc. de Educación Media Nº 01 DE 05 Norberto Julio Morresi',
      cue: 20234700,
      tipo: 'reingreso',
    },
    20234800: {
      descripcion: 'Esc. de Educación Media Nº 02 DE 10',
      cue: 20234800,
      tipo: 'reingreso',
    },
    20234900: {
      descripcion: 'Esc. de Educación Media Nº 01 DE 18',
      cue: 20234900,
      tipo: 'reingreso',
    },
    20235000: {
      descripcion: 'Esc. de Educación Media Nº 05 DE 19',
      cue: 20235000,
      tipo: 'reingreso',
    },
    20235200: {
      descripcion: 'Esc. de Educación Media Nº 02 DE 04',
      cue: 20235200,
      tipo: 'reingreso',
    },
    20240000: {
      descripcion: 'Esc. de Educación Media Nº 01 DE 03',
      cue: 20240000,
      tipo: 'reingreso',
    },
    20240700: {
      descripcion: 'Esc. de Educación Media Nº 01 DE 02',
      cue: 20240700,
      tipo: 'reingreso',
    },
  };
