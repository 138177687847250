import { Grid } from '@material-ui/core';
import React from 'react';

export const InputComponent = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid
      item
      xs={6}
      container
      justifyContent="center"
      alignItems="center"
      style={{
        display: 'inline-grid',
      }}
    >
      {children}
    </Grid>
  );
};
