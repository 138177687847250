import { LibbyFetchDAO } from '../LibbyFetchDAO';

export class PresentismoEspacioCurricularDAO extends LibbyFetchDAO {
  constructor() {
    super('presente_espacio_curricular', 'idPresenteEspacioCurricular');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }
}
