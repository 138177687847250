import React, { useCallback } from 'react';
import { FormControlLabel, List } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { useField } from 'react-final-form-hooks';

export interface FormCheckboxTypesProps {
  name: string;
  label: string;
  form: any;
  disabled?: boolean;
}

// TODO: hay que abstraer el uso de material como checkbox a la carpeta ui
export const FormCheckbox = ({
  name,
  form,
  label,
  disabled,
}: FormCheckboxTypesProps): JSX.Element => {
  const field = useField(name, form);

  const onChange = useCallback(
    (value) => {
      field.input.onChange(value.target.checked);
    },
    [field.input],
  );

  return (
    <>
      <List
        key="list-item-one-checkbox"
        dense
        component="div"
        role="list"
        disablePadding
      >
        <FormControlLabel
          control={
            <Checkbox
              disabled={disabled}
              checked={field.input.value}
              onChange={(value) => onChange(value)}
              color="primary"
            />
          }
          label={label}
        />
      </List>
    </>
  );
};
