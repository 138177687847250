import { makeStyles } from '@material-ui/core';
import { primary } from 'src/theme/colors';

export const useInformesStyles = () => {
  const useStyles = makeStyles(() => ({
    header: {
      color: primary.lightBlue,
      textAlign: 'center',
    },
    adjunto: {
      border: 'none',
      borderRadius: 10,
      padding: 10,
      backgroundColor: 'rgb(240,240,240)',
      display: 'flex',
      justifyContent: 'space-between',
      cursor: 'pointer',
      width: '100px',
      whiteSpace: 'nowrap',
    },
    adjuntoClose: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '0 7px',
      fontWeight: 'bold',
    },
    disabledInput: {
      backgroundColor: '#DADADA',
    },
    adjuntoNombre: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    iconObservacion: {
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'end',
      color: primary.lightBlue,
    },
    footer: {
      display: 'flex',
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
    },
    observacionesWrapper: {
      width: '100px',
      color: 'black',
    },
    observaciones: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100px',
      textAlign: 'center',
      color: 'black',
    },
    observacionesText: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '70px',
      textAlign: 'center',
      color: 'black',
    },
    iconNotes: {
      justifyContent: 'flex-end',
      display: 'flex',
      alignItems: 'end',
      color: primary.lightBlue,
    },
    notesWrapper: {
      width: '100px',
      color: 'black',
    },
    notes: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      width: '100px',
      textAlign: 'center',
      color: 'black',
    },
    notesText: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      width: '70px',
      textAlign: 'center',
      color: 'black',
    },
    rowSelect: {
      minWidth: 50,
    },
    required: {
      position: 'relative',
      borderRadius: '5px',
      boxShadow: '0 0 5px 2px red',
      '&::after': {
        width: '100%',
        content: '"Campo requerido"',
        color: 'red',
        position: 'absolute',
        textAlign: 'center',
        whiteSpace: 'nowrap',
      },
    },
  }));

  const classes = useStyles();
  return classes;
};
