import React, { useEffect, useMemo, useState } from 'react';
import { useLocation, useHistory } from 'react-router';
import { useRolesContext } from 'src/context/RolesContext';
import { useRouterContext } from 'src/lib/router';
import { Footer, InfoTable, useRouteScreenTitle } from 'src/commons';
import { FiltersHeaderReferente } from './components/FiltersHeaderReferente';
import { useReferenteViewColumns } from './hooks/useReferenteViewColumns';
import { useOfertasGruposLibbyFetch } from 'src/app/business/acap/OfertasGrupos';
import { PresentismoState, ReferenteViewType, filters } from './types';
import { Presentismo } from '../AcapPresentismo/Presentismo';
import { TablePagination } from '@material-ui/core';
import _ from 'lodash';

export const ReferenteView = ({
  isReferente,
  handleBack,
}: ReferenteViewType) => {
  const { setMetadata } = useRouterContext();
  const location = useLocation();
  const history = useHistory();
  const referenteOrganizacion = location.pathname.includes(
    'referente-organizacion',
  );

  const {
    userInfo: { email },
  } = useRolesContext();

  const [filters, setFilters] = useState<filters>({
    accion: '',
    turno: '',
  });

  const [tomandoPresentismo, setTomandoPresentismo] =
    useState<PresentismoState>({
      enabled: false,
      row: null,
    });

  const [limit, setLimit] = useState(10);
  const [offset, setOffset] = useState(0);
  const [page, setPage] = useState(0);
  const [direction, setDirection] = useState<'asc' | 'desc'>('asc');
  const [orderBy, setOrderBy] = useState('ofertaTurno.oferta.accion.idAccion');

  const columns = useReferenteViewColumns(setTomandoPresentismo);

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setDirection(newDirection);
    setOrderBy(newOrderBy);
  };

  const ofertasGruposFilter = useMemo(
    () => ({
      ...(isReferente?.enabled
        ? {
            organizacion: [
              {
                path: 'ofertaTurno.oferta.accion.organizacion',
                value: isReferente?.row?.idOrganizacion,
              },
            ],
          }
        : {
            referenteActividad:
              Boolean(email) && !referenteOrganizacion
                ? [{ path: 'referente.email', value: email }]
                : [],
            referenteOrganizacion:
              Boolean(email) && referenteOrganizacion
                ? [
                    {
                      path: 'ofertaTurno.oferta.accion.organizacion.referente.email',
                      value: email,
                    },
                  ]
                : [],
          }),
      ...Boolean(filters.accion) && {
        accion: [
          {
            path: 'ofertaTurno.oferta.accion',
            value: filters.accion,
          },
        ]
      },
      ...Boolean(filters.turno) && {
        turno: [{ path: 'ofertaTurno.turno', value: filters.turno }]
      },
    }),
    [filters.turno, filters.accion, email, referenteOrganizacion, isReferente],
  );

  const buscarOferta = (filters: filters) => {
    setFilters(filters);
  };

  const { data: OfertasGrupos = [], working: workingOfertasGrupos } =
    useOfertasGruposLibbyFetch({
      filter: ofertasGruposFilter,
      limit: 1000,
      aspect: 'default',
    });

  const NombreOrganizaciones = useMemo(() => {
    let nombreOrganizaciones: string[] = [];
    OfertasGrupos.forEach((ofertaGrupo) => {
      if (
        !nombreOrganizaciones.some(
          (nombreOrganizacion) =>
            nombreOrganizacion ===
            ofertaGrupo?.ofertaTurno?.oferta?.accion?.organizacion?.nombre,
        )
      )
        nombreOrganizaciones = [
          ...nombreOrganizaciones,
          ofertaGrupo?.ofertaTurno?.oferta?.accion?.organizacion?.nombre,
        ];
    });

    return nombreOrganizaciones;
  }, [OfertasGrupos]);

  const ofertasGruposPaginadas = OfertasGrupos.slice(offset, limit + offset);

  const ofertasGruposSorted = useMemo(() => {
    const organizacionesCloned = _.cloneDeep(ofertasGruposPaginadas);

    organizacionesCloned.sort(
      (a: any | { [k: string]: string }, b: any | { [k: string]: string }) => {
        return direction === 'desc'
          ? _.get(a, orderBy)?.localeCompare(_.get(b, orderBy))
          : _.get(b, orderBy)?.localeCompare(_.get(a, orderBy));
      },
    );

    return organizacionesCloned;
  }, [orderBy, direction, ofertasGruposPaginadas]);

  useRouteScreenTitle(
    referenteOrganizacion === true
      ? 'Devoluciones'
      : `Actividades ${
          NombreOrganizaciones.length > 0 ? 'en ' + NombreOrganizaciones : ''
        }`,
  );

  const buttonConfig = [
    {
      title: 'Salir',
      handleOnClick: () => {
        if (handleBack) {
          handleBack();
          setMetadata((prev) => ({
            ...prev,
            title: 'Actividades de aproximación',
          }));
        } else history.push('/establecimientos/home');
      },
      size: 'medium',
      disabled: false,
    },
  ];

  useEffect(() => {
    if (location && location.state) {
      const { state } = location.state as any;
      if (state?.row) {
        setTomandoPresentismo({ enabled: true, row: state.row });
      }
    }
  }, [location]);

  return (
    <>
      {tomandoPresentismo?.enabled ? (
        <Presentismo
          isInsideSchool={false}
          row={tomandoPresentismo?.row}
          setTomandoPresentismo={setTomandoPresentismo}
          ofertaReferenteAccion={OfertasGrupos}
        />
      ) : (
        <>
          <FiltersHeaderReferente
            workingOferta={workingOfertasGrupos}
            isReferente={isReferente}
            buscarOferta={buscarOferta}
          />
          <InfoTable
            rows={ofertasGruposSorted}
            columns={columns}
            working={workingOfertasGrupos}
            direction={direction}
            onSortChange={handleRequestSort}
          />
          {OfertasGrupos.length && !workingOfertasGrupos && (
            <TablePagination
              rowsPerPageOptions={[10, 25, 50, 100]}
              component="div"
              count={OfertasGrupos.length}
              rowsPerPage={limit}
              page={offset / limit}
              onPageChange={(e, page) => {
                let offset = 0;
                if (page < 0) offset = 0 * limit;
                else offset = page * limit;
                setOffset(offset);
                setPage(page);
              }}
              onRowsPerPageChange={(e) => {
                setLimit(parseInt(e.target.value));
              }}
              labelRowsPerPage="Filas por página"
              labelDisplayedRows={({ from, to, count }) => {
                return from + '-' + to + ' de ' + count;
              }}
            />
          )}
          <Footer buttonConfig={buttonConfig} />
        </>
      )}
    </>
  );
};
