import React from 'react';
import { MenuItem } from '../../../../commons/const/ui/menu';
import {
  Calificaciones,
  CalificacionesPeriodos,
} from 'src/screens/Private/Calificaciones';
import { SecundarioReducerProvider } from 'src/screens/Private/Calificaciones/hooks/useSecundarioState';

const CalificacionesPeriodosEfectivizacion = () => (
  <SecundarioReducerProvider>
    <CalificacionesPeriodos />
  </SecundarioReducerProvider>
);

export const routes: MenuItem[] = [
  {
    key: 'calificacionesBimestres',
    basePath: '/:periodo',
    target: {
      component: CalificacionesPeriodosEfectivizacion,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Calificaciones',
    children: [],
  },
  {
    key: 'calificacionesSwitch',
    basePath: '/',
    target: {
      component: Calificaciones,
      path: '/',
    },
    enabled: true,
    icon: 'List',
    text: 'Calificaciones',
    children: [],
    default: true,
  },
];
