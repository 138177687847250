import { CONTINUA_CON_APOYO, SeccionWithProyeccion } from 'src/app/models';
import Efectivizador, { SingleProyeccion } from './Efectivizador';

export default class EfectivizadorSecundaria extends Efectivizador {
  private secciones: SeccionWithProyeccion[];

  constructor(
    proyecciones: SingleProyeccion[],
    secciones: SeccionWithProyeccion[],
    estado?: number,
  ) {
    super(proyecciones, estado);
    this.proyecciones = proyecciones;
    this.estado = estado;
    this.secciones = secciones;
  }

  hasSomePending() {
    return this.proyecciones.some(
      (proyeccion) => proyeccion.articula && proyeccion.seccionDestino === null,
    );
  }

  hasSomeInProgress() {
    return this.proyecciones.some((proyeccion) => proyeccion.status === 4);
  }

  hasSameAnoLectivo() {
    return this.proyecciones.some(
      (proyeccion) =>
        proyeccion.seccionOrigen.cicloLectivo.anio ===
        proyeccion.seccionDestino?.cicloLectivo.anio,
    );
  }

  hasSomeIntermediateStatus() {
    return this.proyecciones.some(
      (proyeccion) => proyeccion.status === CONTINUA_CON_APOYO,
    );
  }

  canEfectivizar(): boolean {
    const can = super.canEfectivizar();
    return (
      can &&
      !this.hasSomeInProgress() &&
      !this.hasSomePending() &&
      !this.hasSomeIntermediateStatus() &&
      !this.hasSameAnoLectivo()
    );
  }
}
