import { MotivoTipoSituacionV3DAO } from 'src/platform/libby/dao/Presentismov3';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { MotivoTipoSituacionV3 } from '../../models/presentismo_v3';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'motivo_tipo_situacion';

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: { renderPropCall, renderPropFetchById, simpleSelect, selectInput },
} = makeBusinessEntity<MotivoTipoSituacionV3, MotivoTipoSituacionV3DAO>(
  DAO_NAME,
  {},
);

// hooks
export const useMotivoTipoSituacionV3FetchById = fetchById;
export const useMotivoTipoSituacionV3LibbyCall = libbyCall;
export const useMotivoTipoSituacionV3DAO = libbyDAO;
export const useMotivoTipoSituacionV3LibbyFetch = libbyFetch;

// components/hoc
export const MotivoTipoSituacionV3Call = renderPropCall;
export const MotivoTipoSituacionV3ById = renderPropFetchById;
export const MotivoTipoSituacionV3Select = simpleSelect;
export const MotivoTipoSituacionV3SelectInput = selectInput;
