import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Grid, makeStyles, TextField, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useHistory, useParams } from 'react-router';
import { FilterTableCalificaciones } from '../components';
import {
  useAlumnoMovimientoLibbyFetch,
  useCalificacionesCustomDAO,
  useEspacioCurricularSeccionLibbyFetch,
  usePeriodoLibbyFetch,
} from 'src/app/business';
import { useCalificacionTableColumns } from '../hooks';
import { useRolesContext } from 'src/context/RolesContext';
import { Seccion, Periodo } from 'src/app/models';
import { Footer, InfoTable, SimpleSelect, useDebounce } from 'src/commons';
import confirmDialog from 'src/commons/services/confirmDialog';
import { NIVEL } from 'src/commons/const';
import { Loading } from 'src/commons/components';
import { isEqual } from 'lodash';
import useGenerarExcel from '../../../../../../../src/commons/hooks/useGenerarExcel';
import { PermissionBlocker } from 'src/lib/permission/components/PermissionBlocker';
import { BLOCKER_ID } from 'src/platform/permission/const/BlockerId';
import { useLibbyFetch as useLibbyFetchWithAspect } from 'src/lib/libby/hooks/useLibbyFetch';
import { useGeneralContext } from 'src/context/GeneralContext';
import { setSearchParamsMultipleWords } from 'src/utils/setSearchParamsMultipleWords';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';
import { AlertMessage } from 'src/screens/Private/Calificaciones';

export type TipoPlanificacion = {
  id: string;
  descripcion: string;
};

export type objTable = {
  id: string;
  nombre: string;
  tipo: string;
  seccion: string;
  bimestre: string;
};

const useStyles = makeStyles({
  table: {
    maxHeight: '600px',
    marginBottom: '80px',
  },
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

const selects = ['participacion', 'calificacion'];

const calificacionMock = [
  { descripcion: 'En Proceso', id: 0 },
  { descripcion: 'Suficiente', id: 1 },
  { descripcion: 'Avanzado', id: 2 },
];

const calificacionNumericaMock = [
  { descripcion: '1', id: 0 },
  { descripcion: '2', id: 1 },
  { descripcion: '3', id: 2 },
  { descripcion: '4', id: 3 },
  { descripcion: '5', id: 4 },
  { descripcion: '6', id: 5 },
  { descripcion: '7', id: 6 },
  { descripcion: '8', id: 7 },
  { descripcion: '9', id: 8 },
  { descripcion: '10', id: 9 },
];

const participacionMock = [
  { descripcion: 'No', id: 0 },
  { descripcion: 'Sí', id: 1 },
];

export const CalificacionesSecundarioTable = ({
  working: validYearsWorking,
  validYears,
}: {
  working: boolean;
  validYears: {
    anio: {
      path: string;
      value: unknown;
    }[];
  };
}) => {
  const { establecimientoEfectivizado } = useColegioEfectivizadoContext();
  const {
    generalState: { cicloLectivo },
  } = useGeneralContext();

  const anio = validYears?.anio;
  const history = useHistory();
  const classes = useStyles();
  const {
    userInfo: { id: userId },
    selectedRole: { localizacionId },
  } = useRolesContext();
  const { enqueueSnackbar } = useSnackbar();
  const [search, setSearch] = useState('');
  const [secciones, setSecciones] = useState<any>();
  const [anioSelect, setAnioSelect] = useState('');
  const [espacioCurricularSeccion, setEspacioCurricularSeccion] = useState<any>(
    [],
  );
  const [enabledLibbyFetch, setEnabledLibbyFetch] = useState(false);
  const [calificacionFilter, setCalificacionFilter] = useState<any>({});
  const [calificationRows, setCalificationRows] = useState<any>([]);
  const [filterPeriodo, setFilterPeriodo] = useState({ id_periodo: 1 });
  const [periodoSelect, setPeriodoSelect] = useState<any>('');
  const [filterSeccion, setFilterSeccion] = useState<any>('');
  const [filterEspCurrSeccion, setFilterEspCurrSeccion] = useState<any>();
  const [incompletedRows, setIncompletedRows] = useState<any[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [fetchDisabled, setFetchDisabled] = useState<boolean>(false);
  const [filterAnio, setFilterAnio] = useState<any>('');
  const [filterEspacioCurricular, setFilterEspacioCurricular] =
    useState<any>('');
  const [hasEspacioCurricular, setHasEspacioCurricular] = useState<any>();
  const { idEspacioCurricular } = useParams<any>();

  const searchDebounce = useDebounce(search, 500);
  const generateExcel = useGenerarExcel();

  const { current: fieldsToValidate } = useRef([...selects, 'valoracion']);

  const orderByLastName = 'alumno.persona.apellido';

  const searchFilterParams = useMemo(
    () => ({
      ...setSearchParamsMultipleWords(
        [
          'alumno.persona.nombre',
          'alumno.persona.apellido',
          'alumno.persona.documento',
        ],
        searchDebounce,
      ),
    }),
    [searchDebounce],
  );

  const filterPeriodoNivel = useMemo(
    () => ({
      0: [{ path: 'nivel', value: NIVEL.SECUNDARIO }],
      1: [{ path: 'tipoPeriodo.idTipoPeriodo', value: 1 }],
    }),
    [],
  );

  const { data: periodos = [] } = usePeriodoLibbyFetch({
    filter: filterPeriodoNivel,
    orderBy: 'tipoPeriodo.idTipoPeriodo',
  });

  const filterParams = useMemo(() => {
    return {
      0: [{ path: 'seccion.idSeccion', value: filterSeccion.seccion }],
      1: [
        {
          path: 'espacioCurricular.idEspacioCurricular',
          value: idEspacioCurricular,
        },
      ],
    };
  }, [filterSeccion.seccion, idEspacioCurricular]);

  const { data: seccionEspacioCurricular = [], reFetch: reFetchEcs } =
    useEspacioCurricularSeccionLibbyFetch({
      aspect: 'short',
      filter: filterParams,
      enabled: Boolean(idEspacioCurricular && filterSeccion.seccion),
    });

  const filterAlumnoMovimiento = useMemo(
    () => ({
      0: [{ path: 'seccion', value: filterSeccion.seccion }],
      ...searchFilterParams,
    }),
    [filterSeccion, searchFilterParams],
  );

  const {
    data: alumnoMovimiento,
    working: alumnoMovimientoWorking,
    reFetch: alumnoReFetch,
  } = useAlumnoMovimientoLibbyFetch({
    filter: filterAlumnoMovimiento,
    orderBy: orderByLastName,
    aspect: 'shorted_relation',
    limit: 100,
    enabled: fetchDisabled,
  });

  const onFetch = useCallback(() => {
    setFetchDisabled(true);
    alumnoReFetch();
  }, [alumnoReFetch]);

  useEffect(() => {
    if (espacioCurricularSeccion?.length) {
      const idEcs = espacioCurricularSeccion.find(
        (ecs: any) =>
          ecs.idEspacioCurricularSeccion ===
          filterEspacioCurricular.espacioCurricularSeccion,
      );
      setFilterEspCurrSeccion(idEcs?.idEspacioCurricularSeccion);
    }
  }, [
    espacioCurricularSeccion,
    filterEspacioCurricular,
    filterSeccion,
    secciones,
  ]);

  useEffect(() => {
    if (alumnoMovimiento?.length !== 0) {
      let idEspacioCurricularValue = !!filterEspCurrSeccion
        ? filterEspCurrSeccion
        : seccionEspacioCurricular[0]?.idEspacioCurricularSeccion;
      const calificacionFilterParams = alumnoMovimiento?.map((item: any) => ({
        path: 'id_alumno',
        value: item.alumno.idAlumno,
      }));

      if (filterPeriodo.id_periodo && idEspacioCurricularValue)
        setEnabledLibbyFetch(true);

      setCalificacionFilter({
        0: calificacionFilterParams,
        1: [{ path: 'id_periodo', value: filterPeriodo.id_periodo }],
        2: [
          {
            path: 'id_espacio_curricular_seccion',
            value: idEspacioCurricularValue,
          },
        ],
        ...searchFilterParams,
      });
    }
  }, [
    alumnoMovimiento,
    filterPeriodo,
    searchFilterParams,
    filterEspCurrSeccion,
    seccionEspacioCurricular,
  ]);

  const paramsFetchManagement = {
    filter: calificacionFilter,
    limit: 100,
    daoName: 'calificaciones_secundario',
    aspect: 'calificacionesSecundario_v1',
    enabled: enabledLibbyFetch,
  };

  const {
    data: calificaciones = [],
    working: calificacionesWorking,
    reFetch,
  } = useLibbyFetchWithAspect(paramsFetchManagement);

  useEffect(() => {
    const rows = alumnoMovimiento?.map((alumno) => {
      let idEspacioCurricularValue = !!filterEspCurrSeccion
        ? filterEspCurrSeccion
        : seccionEspacioCurricular[0]?.idEspacioCurricularSeccion;
      const match = calificaciones.find((calificacion: any) => {
        return (
          alumno.alumno.idAlumno === calificacion.alumno.idAlumno &&
          calificacion.espacioCurricularSeccion.idEspacioCurricularSeccion ===
            idEspacioCurricularValue
        );
      });
      if (match) {
        return match;
      } else {
        return {
          alumno: alumno.alumno,
          calificacion: undefined,
          participacion: undefined,
          valoracion: undefined,
        };
      }
    });
    setCalificationRows(rows);
  }, [
    alumnoMovimiento,
    calificaciones,
    filterEspCurrSeccion,
    seccionEspacioCurricular,
  ]);

  const calificacionesCustomDAO = useCalificacionesCustomDAO();

  const working = useMemo(
    () => calificacionesWorking && alumnoMovimientoWorking,
    [calificacionesWorking, alumnoMovimientoWorking],
  );

  const seccionSelectedColumn = useMemo(
    () =>
      secciones?.filter(
        (seccion: Seccion) => seccion.idSeccion === filterSeccion.seccion,
      ),
    [secciones, filterSeccion],
  );

  const periodosSelectOptions = periodos.map(
    ({ idPeriodo, tipoPeriodo }: Periodo) => ({
      id: idPeriodo,
      title: tipoPeriodo.descripcionTipoPeriodo,
    }),
  );

  const handleSelectChange = useCallback(
    (target) => {
      if (target.name === 'anio') {
        setFilterAnio({ [target.name]: target.value });
      } else if (target.name === 'seccion') {
        setFilterSeccion({ [target.name]: target.value });
        onFetch();
      } else if (target.name === 'espacioCurricularSeccion') {
        setFilterEspacioCurricular({ [target.name]: target.value });
        onFetch();
        reFetchEcs();
      } else if (target.name === 'idEspacioCurricularSeccion') {
        setFilterSeccion({ seccion: target.value });
        onFetch();
      }
    },
    [onFetch, reFetchEcs],
  );

  const handleSave = useCallback(async () => {
    let idEspacioCurricularValue = !!filterEspCurrSeccion
      ? filterEspCurrSeccion
      : seccionEspacioCurricular[0]?.idEspacioCurricularSeccion;
    const rows = calificationRows
      .filter((c: any) => {
        let isUpdated = false;
        if (c.idCalificacionSecundario) {
          const cCopy = { ...c };
          const currentCalification = calificaciones.find(
            (calif: any) =>
              calif.idCalificacionSecundario === cCopy.idCalificacionSecundario,
          );
          isUpdated = !isEqual(currentCalification, cCopy);
          return isUpdated;
        }
        return (
          c.calificacion !== undefined &&
          c.participacion !== undefined &&
          c.valoracion !== undefined
        );
      })
      .map((calificacion: any) => {
        return {
          idAlumno: calificacion.alumno.idAlumno,
          calificacion: calificacion.calificacion,
          valoracion: calificacion.valoracion,
          participacion: calificacion.participacion,
          espacioCurricularSeccion: idEspacioCurricularValue,
          periodo: filterPeriodo.id_periodo,
          tipoCalificacion: 1,
          idCalificacionSecundario: calificacion.idCalificacionSecundario,
        };
      });
    if (rows.length) {
      const confirm = await confirmDialog.show({
        title: 'Calificaciones',
        content: '¿Está seguro que desea guardar estas calificaciones?',
        confirmText: 'Confirmar',
        cancelText: 'Cancelar',
      });

      if (confirm) {
        try {
          await calificacionesCustomDAO.add({
            rows,
            nivel: NIVEL.SECUNDARIO,
            user: userId,
          });
          reFetch();
          enqueueSnackbar('Se han guardado las calificaciones con exito', {
            variant: 'success',
          });
        } catch (e) {
          enqueueSnackbar('Ha ocurrido un error', {
            variant: 'error',
          });
        }
      }
    } else {
      await confirmDialog.show({
        title: 'Calificaciones',
        content: 'No hay cambios realizados para guardar',
        confirmText: 'Entendido',
      });
    }
  }, [
    calificationRows,
    enqueueSnackbar,
    calificacionesCustomDAO,
    filterEspCurrSeccion,
    filterPeriodo,
    reFetch,
    userId,
    calificaciones,
    seccionEspacioCurricular,
  ]);

  const handleLeave = useCallback(async () => {
    if (!establecimientoEfectivizado) {
      history.push('/');
      return;
    }
    const confirm = await confirmDialog.show({
      title: '¿Desea continuar?',
      content:
        'La información precargada se perderá en caso que no guarde, por favor seleccione una opción.',
      confirmText: 'Guardar',
      cancelText: 'Salir',
    });
    if (confirm) {
      await handleSave();
    }
    history.push('/');
  }, [history, handleSave, establecimientoEfectivizado]);

  const validator = useCallback(
    (rows: any) => {
      let disabled = false;
      rows.forEach((r: any) => {
        const name = `${r?.alumno.persona.apellido}, ${r?.alumno.persona.nombre}`;
        const result = fieldsToValidate.reduce(
          (acc: number, val: string | number) => {
            if (r[val] === undefined || r[val] === null) ++acc;
            return acc;
          },
          0,
        );
        if (result !== fieldsToValidate.length && result !== 0) {
          disabled = true;
          if (incompletedRows.indexOf(name) === -1) {
            setIncompletedRows([...incompletedRows, name]);
          }
        } else {
          if (incompletedRows.indexOf(name) !== -1) {
            const incompletedRowsCopy = [...incompletedRows];
            incompletedRowsCopy.splice(incompletedRowsCopy.indexOf(name), 1);
            setIncompletedRows(incompletedRowsCopy);
          }
        }
      });
      setIsDisabled(disabled);
    },
    [fieldsToValidate, incompletedRows],
  );

  const handleChangeTable: any = useCallback(
    (event: any, index: number) => {
      const target = event.target as HTMLInputElement;
      const changeRow = (row: any) => {
        if (
          selects.includes(target.name) &&
          typeof target.value !== 'number' &&
          typeof target.value !== 'boolean'
        ) {
          return {
            ...row,
            [target.name]: undefined,
          };
        }
        if (target.name === 'valoracion' && !target.value) {
          return {
            ...row,
            [target.name]: undefined,
          };
        }
        return {
          ...row,
          [target.name]: target.value,
        };
      };
      const rows = [...calificationRows];
      rows[index] = changeRow(rows[index]);
      setCalificationRows(rows);
      validator(rows);
    },
    [calificationRows, validator],
  );

  const handleChangeSearch = useCallback((event) => {
    setIncompletedRows([]);
    const target = event.target as HTMLInputElement;
    setSearch(target.value);
  }, []);

  useEffect(() => {
    setHasEspacioCurricular(!!idEspacioCurricular);
  }, [idEspacioCurricular]);

  const handleDescargar = useCallback(async () => {
    let espacioCurricularSeleccionado;
    let currentSeccion: string;
    if (
      hasEspacioCurricular &&
      calificationRows[0]?.espacioCurricularSeccion?.seccion
    ) {
      espacioCurricularSeleccionado = calificationRows.find(
        (alumno: any) =>
          alumno?.espacioCurricularSeccion?.espacioCurricular
            ?.idEspacioCurricular === idEspacioCurricular,
      )?.espacioCurricularSeccion;
      currentSeccion =
        calificationRows[0]?.espacioCurricularSeccion?.seccion?.nombreSeccion;
    } else {
      if (!hasEspacioCurricular) {
        espacioCurricularSeleccionado = espacioCurricularSeccion.find(
          (espacioCurricular: any) =>
            espacioCurricular.idEspacioCurricularSeccion ===
            filterEspacioCurricular.espacioCurricularSeccion,
        );
      } else {
        espacioCurricularSeleccionado = espacioCurricularSeccion.find(
          (espacioCurricular: any) =>
            espacioCurricular?.espacioCurricular?.idEspacioCurricular ===
            idEspacioCurricular,
        );
      }
      currentSeccion = espacioCurricularSeccion[0]?.seccion.nombreSeccion;
    }
    if (espacioCurricularSeleccionado) {
      const metadata: Array<string> | [] = [
        periodoSelect,
        `Ciclo ${String(cicloLectivo)}`,
        currentSeccion,
        espacioCurricularSeleccionado.espacioCurricular?.descripcion,
      ];

      if (anioSelect) {
        // Se evita repetir el año para las secciones que ya incluyen el año en su descripción
        const seccionConcatAnio = currentSeccion.includes(String(anioSelect))
          ? currentSeccion
          : `${String(anioSelect)} ${currentSeccion}`;

        metadata[2] = seccionConcatAnio;
      }
      const reqBody: any = {
        metadata,
        columns: [
          'Apellido y Nombre',
          // '¿Sostuvo la participación y la continuidad pedagógica?',
          //  'Valoración',
          'Calificación',
        ],
        data: [],
      };
      calificationRows.forEach((c: any) => {
        if (currentSeccion) {
          const participacion = participacionMock.find(
            (vp) => vp.id === c.participacion,
          )?.descripcion;
          const calificacion = calificacionMock.find(
            (vp) => vp.id === c.calificacion,
          )?.descripcion;
          reqBody.data.push({
            [currentSeccion]: `${c.alumno.persona.nombre} ${c.alumno.persona.apellido}`,
            '¿Valoracion?': c.valoracion || '',
            Calificacion: calificacion === undefined ? '' : calificacion,
          });
        }
      });
      await generateExcel(
        reqBody,
        `Calificaciones - ${espacioCurricularSeleccionado.espacioCurricular.descripcion} - ${periodosSelectOptions[0].title} - ${currentSeccion} - ${localizacionId}`,
      );
    }
  }, [
    hasEspacioCurricular,
    calificationRows,
    idEspacioCurricular,
    espacioCurricularSeccion,
    filterEspacioCurricular.espacioCurricularSeccion,
    anioSelect,
    generateExcel,
    periodosSelectOptions,
    localizacionId,
    periodoSelect,
    cicloLectivo,
  ]);

  const calificacionesContent = useMemo(
    () =>
      filterPeriodo.id_periodo === 3 || filterPeriodo.id_periodo === 8
        ? calificacionNumericaMock
        : calificacionMock,
    [filterPeriodo],
  );
  const rows = useMemo(
    () =>
      calificationRows.map((calificacion: any, index: any) => {
        // fetch from db
        return {
          fullName: `${calificacion?.alumno?.persona?.apellido}, ${calificacion?.alumno?.persona?.nombre}`,
          participacion: (
            <Grid style={{ width: 200 }}>
              <SimpleSelect
                placeholder="Seleccione una opción"
                labelKey="descripcion"
                valueKey="id"
                content={participacionMock}
                value={calificacion.participacion}
                name="participacion"
                key="idParticipacion"
                handleChange={(event) => handleChangeTable(event, index)}
                // disabled={!establecimientoEfectivizado}
              />
            </Grid>
          ),
          valoracion: (
            <TextField
              value={calificacion.valoracion}
              fullWidth
              name="valoracion"
              onChange={(event) => handleChangeTable(event, index)}
              // disabled={!establecimientoEfectivizado}
              inputProps={{
                maxLength: 100,
              }}
            />
          ),
          calificacion: (
            <Grid style={{ width: 200 }}>
              <SimpleSelect
                placeholder="Seleccione una opción"
                labelKey="descripcion"
                valueKey="id"
                content={calificacionesContent} // map with calificacion type in db
                value={calificacion.calificacion}
                name="calificacion"
                key="idCalificacion"
                handleChange={(event) => handleChangeTable(event, index)}
                // disabled={!establecimientoEfectivizado}
              />
            </Grid>
          ),
        };
      }),
    [
      // establecimientoEfectivizado,
      calificationRows,
      handleChangeTable,
      calificacionesContent,
    ],
  );

  const buttonConfig: any = useMemo(() => {
    const defaultButtons: any[] = [
      {
        title: 'Salir',
        size: 'medium',
        type: 'secondary',
        handleOnClick: () => handleLeave(),
      },
      {
        title: 'Guardar',
        size: 'medium',
        handleOnClick: () => handleSave(),
        disabled: isDisabled || !establecimientoEfectivizado,
      },
    ];

    return defaultButtons;
  }, [handleLeave, handleSave, isDisabled, establecimientoEfectivizado]);

  const columns = useCalificacionTableColumns(
    seccionSelectedColumn && seccionSelectedColumn[0],
  );

  const enabledDescargar = useMemo(() => {
    const alumnosConCalificaciones = [];
    calificationRows.forEach((element: any) => {
      if (
        !!element?.calificacion ||
        !!element?.valoracion ||
        !!element?.participacion
      ) {
        alumnosConCalificaciones.push(element);
      }
    });
    return alumnosConCalificaciones.length > 0 ? true : false;
  }, [calificationRows]);

  const handleAnioChange = (options: any) => {
    const anio = options?.find((s: any) => s.idAnio === filterAnio.anio);
    setAnioSelect(anio?.descripcionAnio);
  };

  const handlePeriodosChange = (options: any) => {
    const periodo = options?.find(
      (p: any) => p.idPeriodo === filterPeriodo.id_periodo,
    );
    setPeriodoSelect(periodo?.tipoPeriodo.descripcionTipoPeriodo);
  };

  const espacioCurricularValidator =
    !!idEspacioCurricular && !!filterSeccion
      ? true
      : !!filterEspacioCurricular.espacioCurricularSeccion
      ? true
      : false;
  return (
    <>
      <AlertMessage
        establecimientoEfectivizado={establecimientoEfectivizado}
        incompletedRows={incompletedRows}
        isDisabled={isDisabled}
      />
      {validYearsWorking ? (
        <Loading />
      ) : (
        <>
          <FilterTableCalificaciones
            handleSelectChange={handleSelectChange}
            filterAnio={filterAnio}
            filterEspacioCurricular={filterEspacioCurricular}
            filterPeriodo={filterPeriodo}
            filterSeccion={filterSeccion}
            handleChangeSearch={handleChangeSearch}
            search={search}
            handleDescargar={handleDescargar}
            enableDescargar={enabledDescargar}
            setEspacioCurricularSeccion={setEspacioCurricularSeccion}
            setFilterEspacioCurricular={setFilterEspacioCurricular}
            anioCustomFilter={{ anio }}
            handleAnioChange={handleAnioChange}
            handlePeriodosChange={handlePeriodosChange}
          />
          {working ? (
            <Loading />
          ) : !espacioCurricularValidator ? (
            <Grid
              container
              justify="center"
              alignItems="center"
              style={{ height: '400px' }}
            >
              <Typography
                style={{
                  fontSize: '40px',
                  marginTop: '50px',
                  color: 'gray',
                  fontWeight: 'bold',
                }}
              >
                Por favor, seleccione una sección.
              </Typography>
            </Grid>
          ) : (
            <>
              <InfoTable
                rows={rows}
                columns={columns}
                working={working}
                orderBy={orderByLastName}
                customStyle={classes.table}
              />
              <PermissionBlocker id={BLOCKER_ID.QUALIFY_ESTUDENS}>
                <Grid className={classes.footer}>
                  <Footer buttonConfig={buttonConfig} />
                </Grid>
              </PermissionBlocker>
            </>
          )}
        </>
      )}
    </>
  );
};
