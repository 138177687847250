import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { businessOptions } from '../businessOptions';
import { ObservacionesBoletin } from '../../models/presentismo_v3/ObservacionesBoletin';
import { ObservacionesBoletinDAO } from '../../../platform/libby/dao/Presentismov3';

const DAO_NAME = 'observacionesboletin';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idObservacionBoletin',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idObservacionBoletin',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.ObservacionnesBoletin
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.ObservacionnesBoletin,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.ObservacionnesBoletin.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<ObservacionesBoletin, ObservacionesBoletinDAO>(
  DAO_NAME,
  optionsToUse,
);

// hooks
export const useObservacionnesBoletinFetchById = fetchById;
export const useObservacionnesBoletinLibbyCall = libbyCall;
export const useObservacionnesBoletinDAO = libbyDAO;
export const useObservacionnesBoletinLibbyFetch = libbyFetch;

// components/hoc
export const ObservacionnesBoletinAutocomplete = entityAutocomplete;
export const ObservacionnesBoletinCall = renderPropCall;
export const ObservacionnesBoletinById = renderPropFetchById;
export const ObservacionnesBoletinSelect = simpleSelect;
export const ObservacionnesBoletinAccordionSelector = accordionSelector;
export const ObservacionnesBoletinSelectedChips = selectedChips;

// context
// ByIdContext
export const useObservacionnesBoletinByIdContext = useByIdContext;
export const ObservacionnesBoletinByIdProvider = ByIdProvider;
export const ObservacionnesBoletinByIdContext = ByIdContext;
// GeneralContext
export const useObservacionnesBoletinContext = useGeneralContext;
export const ObservacionnesBoletinProvider = GeneralProvider;
export const ObservacionnesBoletinContext = GeneralContext;
