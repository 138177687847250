import { OrganizacionFormInitialValue, SedeFormInitialValue } from '../types';

export const sedeInitialValue: SedeFormInitialValue = {
  nombre: '',
  direccion: '',
  idSede: null,
  turnos: [],
  isMain: false,
};

export const referenteInitialValue: OrganizacionFormInitialValue['referente'] =
  {
    idReferente: null,
    nombre: '',
    apellido: '',
    email: '',
    telefono: '',
    codigo: '',
    isResetPassword: false,
  };

export const initialValue: OrganizacionFormInitialValue = {
  idOrganizacion: null,
  idSede: null,
  nombre: '',
  sector: 0,
  fechaInicio: null,
  fechaFin: null,
  domicilio: '',
  cuit: '',
  email: '',
  telefono: '',
  referente: { ...referenteInitialValue },
  sedes: [{ ...sedeInitialValue }],
  turnos: [],
};
