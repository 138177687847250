import { LibbyFetchDAO } from './LibbyFetchDAO';

export class ResponsableDAO extends LibbyFetchDAO {
  constructor() {
    super('responsable', 'idResponsable');
  }

  getAll() {
    return this.query().limit(0, 10000).run();
  }

  getByApellido(nombre: string) {
    return this.query().includes('persona.apellido', nombre).run();
  }

  getByDocumento(apellido: string) {
    return this.query().includes('persona.documento', apellido).run();
  }

  getById(id: string) {
    return this.query().equals('idResponsable', id).run();
  }
}
