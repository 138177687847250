import React, { useCallback, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Box } from '@material-ui/core';
import { AnyObject } from 'src/commons';
import { Footer, InfoTable, Loading } from 'src/commons/components';
import customFormDialog from 'src/commons/services/customFormDialog';
import { level, statusPassesRequest } from 'src/commons/const/';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useSolicitudPasesDAO } from 'src/app/business';
import { useSnackbar } from 'notistack';
import moment from 'moment';
import { DatePicker } from 'src/app/components/inputs/Input/DatePicker';
import { MaterialUiPickersDate } from '@material-ui/pickers/typings/date';
import { setAlumnoPassValidationMessages } from '../../../utils/alumnoPassValidation';
import { useAlumnoPassValidationV2 } from '../../../customHooks';
import { CicloLectivo } from 'src/app/models';

const { APPROVED, REJECTED } = statusPassesRequest;

const headerStyles = makeStyles(() => ({
  titleText: {
    color: '#737373',
    fontWeight: 'normal',
    fontSize: '16px',
    fontFamily: '"Open Sans", sans-serif',
    marginBottom: '8px',
    paddingTop: '14px',
  },
  observationText: {
    color: '#737373',
    fontWeight: 'initial',
    fontSize: '12px',
    fontFamily: '"Open Sans", sans-serif',
  },
  root: {
    color: '#737373',
    fontSize: 13,
    fontFamily: '"Open Sans", sans-serif',
  },
}));
// TODO: tipar
interface GestionModalInicialProps {
  selectAlumn: AnyObject;
  reFetch: () => void;
  badgeGestionDePaseReFetch: () => void;
  working: boolean;
  nivel: string;
  cicloLectivo: CicloLectivo | null;
  isEfectivizado?: boolean;
}

export const GestionModalInicial = ({
  selectAlumn,
  reFetch,
  badgeGestionDePaseReFetch,
  working,
  nivel,
  cicloLectivo,
  isEfectivizado,
}: GestionModalInicialProps) => {
  const [submitting, setSubmitting] = useState(false);
  const [passDate, setPassDate] = useState<MaterialUiPickersDate | Date>(
    moment(selectAlumn.fechaPase).toDate(),
  );
  const { observacion } = selectAlumn;
  const solicitudPaseDAO = useSolicitudPasesDAO();
  const { enqueueSnackbar } = useSnackbar();
  const classes = headerStyles();

  const handleToRefuse = useCallback(async () => {
    setSubmitting(true);
    try {
      const confirm = await confirmDialog.show({
        title: '¿Desea rechazar la solicitud?',
        content: `Una vez rechazada la solicitud no podrá cambiar su estado.`,
        confirmText: 'Confirmar',
        cancelText: 'Cancelar',
      });
      if (confirm) {
        const { idSolicitudPases } = selectAlumn;
        const passRejected = {
          idSolicitudPases,
          estadoSolicitudPases: REJECTED,
          fechaPase: passDate ? passDate.toISOString() : moment().toISOString(),
        };
        await solicitudPaseDAO.save(passRejected);
        setSubmitting(false);
        customFormDialog.handleCancel();
        confirmDialog.handleCancel();
        reFetch();
        badgeGestionDePaseReFetch();
      }
      customFormDialog.handleCancel();
      confirmDialog.handleCancel();
    } catch (e) {
      // eslint-disable-next-line
      console.error(e);
      enqueueSnackbar('Se produjo un error al rechazar la solicitud', {
        variant: 'error',
      });
      customFormDialog.handleCancel();
      confirmDialog.handleCancel();
    }
  }, [
    selectAlumn,
    solicitudPaseDAO,
    reFetch,
    badgeGestionDePaseReFetch,
    enqueueSnackbar,
    passDate,
  ]);

  const handleSubmit = useCallback(async () => {
    setSubmitting(true);
    try {
      const passApproved = {
        ...selectAlumn,
        estadoSolicitudPases: APPROVED,
        fechaPase: passDate ? passDate.toISOString() : moment().toISOString(),
      };
      await solicitudPaseDAO.save(passApproved);
      reFetch();
      badgeGestionDePaseReFetch();
      setSubmitting(false);
      customFormDialog.handleCancel();
    } catch (e) {
      // eslint-disable-next-line
      enqueueSnackbar('Se produjo un error al aprobar la solicitud', {
        variant: 'error',
      });
      console.error(e);
      customFormDialog.handleCancel();
    }
  }, [
    selectAlumn,
    solicitudPaseDAO,
    reFetch,
    badgeGestionDePaseReFetch,
    enqueueSnackbar,
    passDate,
  ]);

  const { alumnos, working: passValidationWorking } = useAlumnoPassValidationV2(
    {
      alumnosIds: [selectAlumn.idAlumno],
      fechaFin: moment(passDate).format('YYYY-MM-DD'),
    },
  );

  const alumnoValidationData = alumnos.find(
    (alumno) => alumno.idAlumno === selectAlumn.idAlumno,
  );

  const alumnoPassValidationMessages = setAlumnoPassValidationMessages({
    hasPresentismo: alumnoValidationData?.hasCompletePresentismo,
    hasBoletinGenerado: alumnoValidationData?.hasGeneratedBoletin,
    isNivelInicial: Number(nivel) === level.INICIAL,
    anio: selectAlumn.anio,
  });

  const formattedValidationMessages =
    alumnoPassValidationMessages.join('<br />');

  const buttonConfig: any = [
    {
      title: 'Cancelar',
      handleOnClick: () => customFormDialog.handleCancel(),
      type: 'secondary',
      size: 'small',
    },
    {
      title: 'Rechazar',
      handleOnClick: handleToRefuse,
      size: 'small',
      type: 'cancel',
      disabled: submitting,
    },
    {
      title: 'Aprobar',
      handleOnClick: handleSubmit,
      size: 'small',
      tooltip:
        alumnoPassValidationMessages?.length > 0 ? (
          <div
            dangerouslySetInnerHTML={{ __html: formattedValidationMessages }}
          />
        ) : undefined,
      disabled:
        alumnoPassValidationMessages?.length > 0 ||
        submitting ||
        passValidationWorking,
    },
  ];

  const usePassStudentTableLogic = (_student: AnyObject) => {
    const rows = _student.map(
      ({ documento, nombre, apellido, establecimientoDestino }: AnyObject) => ({
        name: (
          <Box alignContent="center" justifyContent="center">
            {`${nombre} ${apellido}`}
          </Box>
        ),
        document: documento,
        establishment: establecimientoDestino,
      }),
    );
    return {
      rows,
    };
  };

  const column = [
    {
      id: 'name',
      label: 'Nombre y Apellido',
      width: '25%',
    },
    {
      id: 'document',
      label: 'Documento',
      width: '3%',
    },
    {
      id: 'establishment',
      label: 'Establecimiento de destino',
      width: '20%',
    },
  ];

  const updatePassDate = (date: MaterialUiPickersDate | Date) => {
    if (date) {
      setPassDate(moment(date).toDate());
    } else {
      setPassDate(null);
    }
  };

  const usePassStudent = usePassStudentTableLogic([selectAlumn]);
  let passDatePlaceHolder = moment(selectAlumn.fechaPase)
    .format('DD-MM-YYYY')
    .toString();

  const fechaInicioCiclo = moment(
    cicloLectivo?.fechaInicio,
    'YYYY-MM-DD',
  ).format('YYYY-MM-DD');
  const minDate = fechaInicioCiclo || moment(new Date()).startOf('year');
  const today = moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD');
  const fechaFinCiclo = moment(cicloLectivo?.fechaFin, 'YYYY-MM-DD').format(
    'YYYY-MM-DD',
  );
  const isBeforeInit = moment(today, 'YYYY-MM-DD').isBefore(fechaInicioCiclo);
  let maxDate = moment(today, 'YYYY-MM-DD').isBefore(fechaFinCiclo)
    ? today
    : fechaFinCiclo;
  if (isBeforeInit) {
    maxDate = fechaInicioCiclo;
  }

  let isCicloLectivoStarted = fechaInicioCiclo > today && isEfectivizado;

  return submitting ? (
    <Loading />
  ) : (
    <Grid>
      <div style={{ width: '100%', paddingBottom: '15px' }}>
        <div style={{ paddingRight: '24px', paddingLeft: '24px' }}>
          <InfoTable rows={usePassStudent.rows} columns={column} />
        </div>
        <Grid container style={{ padding: '24px' }}>
          <Grid item xs={12}>
            <Typography className={classes.titleText}>Observaciones</Typography>
            <Typography className={classes.observationText}>
              {observacion}
            </Typography>
          </Grid>
        </Grid>
        <Grid container style={{ padding: '24px' }}>
          <Grid item xs={12}>
            <Typography className={classes.titleText}>
              Fecha efectiva de pase
            </Typography>
            <DatePicker
              shouldDisableDate={(day: MaterialUiPickersDate) =>
                [0, 6].includes(Number(day?.format('d')))
              }
              value={isCicloLectivoStarted ? fechaInicioCiclo : passDate}
              disabled={false}
              placeholder={passDatePlaceHolder}
              onChange={updatePassDate}
              maxDate={maxDate}
              minDate={minDate}
              focused={isBeforeInit}
            />
          </Grid>
        </Grid>
      </div>
      <Footer buttonConfig={buttonConfig} spacing={1} />
    </Grid>
  );
};
