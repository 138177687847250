import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../lib/libby/generator';
import { Nivel } from '../models';
import { businessOptions } from './businessOptions';

const DAO_NAME = 'nivel';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idNivel',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idNivel',
      labelKey: 'descripcion',
    },
    withSimpleSelect: {
      name: 'nivel',
      title: 'Nivel',
      placeholder: 'Seleccionar Nivel',
      labelKey: 'descripcionNivel',
      valueKey: 'idNivel',
    },
  },
};

const optionsToUse = !businessOptions.Nivel
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Nivel,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Nivel.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Nivel>(DAO_NAME, optionsToUse);

// hooks
export const useNivelFetchById = fetchById;
export const useNivelLibbyCall = libbyCall;
export const useNivelDAO = libbyDAO;
export const useNivelLibbyFetch = libbyFetch;

// components/hoc
export const NivelAutocomplete = entityAutocomplete;
export const NivelCall = renderPropCall;
export const NivelById = renderPropFetchById;
export const NivelSelect = simpleSelect;
export const NivelAccordionSelector = accordionSelector;
export const NivelSelectedChips = selectedChips;

// context
// ByIdContext
export const useNivelByIdContext = useByIdContext;
export const NivelByIdProvider = ByIdProvider;
export const NivelByIdContext = ByIdContext;
// GeneralContext
export const useNivelContext = useGeneralContext;
export const NivelProvider = GeneralProvider;
export const NivelContext = GeneralContext;
