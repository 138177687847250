import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useCallback } from 'react';
import {
  ButtonTypesProps,
  Footer,
  Loading,
  MainButton,
  MainInput,
  SimpleSelect,
} from 'src/commons';
//@ts-ignore
import MUIEditor from 'react-mui-draft-wysiwyg';
import { convertToRaw } from 'draft-js';
import { DetalleAccionProps, HandleChangeOrientacion } from '../types';
import { onlyNumbers } from '../../organizaciones/utils/regex';
import { cloneDeep } from 'lodash';

export const DetalleAccionForm = ({
  formValues,
  ciclosLectivos,
  orientaciones,
  tipoAcciones,
  accionesNombre,
  editorConfig,
  onlyView = false,
  isDublicating = false,
  loading = false,
  disabled = false,
  setFormValues,
  handleCancel,
  handleSubmit,
}: DetalleAccionProps) => {
  const buttonConfig: ButtonTypesProps[] = [
    {
      title: 'Salir',
      handleOnClick: handleCancel,
      size: 'medium',
    },
    {
      title: isDublicating ? 'Duplicar' : 'Guardar',
      handleOnClick: handleSubmit,
      size: 'medium',
      disabled: disabled,
      hidden: onlyView,
    },
  ];

  const handleChangeOrientaciones = useCallback<HandleChangeOrientacion>(
    (item) => (_, checked) => {
      if (checked) {
        // ADD
        setFormValues((prev) => ({
          ...prev,
          accionOrientaciones: [
            ...prev.accionOrientaciones,
            {
              idOrientacion: item.idOrientacion,
              nombreOrientacion: item.nombre,
              status: true,
              nodos: [],
              habilidades: [],
              perfiles: [],
            },
          ],
        }));
      } else {
        // REMOVE
        setFormValues((prev) => {
          const filtered = cloneDeep(prev.accionOrientaciones).filter(
            (ao) => ao.idOrientacion !== item.idOrientacion,
          );
          return {
            ...prev,
            accionOrientaciones: [...filtered],
          };
        });
      }
    },
    [setFormValues],
  );

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Grid container direction="column">
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={3}
            style={{ padding: '0 15px 15px', paddingRight: 15, marginTop: 23 }}
          >
            <Grid
              container
              xs={10}
              justifyContent="flex-start"
              spacing={3}
              alignItems="flex-end"
            >
              <Grid item xs={1}>
                <p style={{ fontFamily: 'Open Sans' }}>Sector:</p>
              </Grid>
              <Grid item xs={3}>
                <MainInput
                  label=""
                  type="search"
                  customStyleLabel={{ color: '#737373', paddingBottom: 15 }}
                  handleChange={(e: any) => console.log('')}
                  placeholder="Buscar"
                  name="search"
                  value={formValues.sectorName}
                  fullWidth
                  disabled
                />
              </Grid>

              <Grid item xs={2}>
                <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                  Organización:
                </p>
              </Grid>

              <Grid item xs={3}>
                <MainInput
                  label=""
                  type="search"
                  customStyleLabel={{ color: '#737373', paddingBottom: 15 }}
                  handleChange={(e: any) => console.log('')}
                  placeholder="Buscar"
                  name="search"
                  value={formValues.organizacionName}
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={3}
            style={{ padding: '0 15px 15px', paddingRight: 15, marginTop: 23 }}
          >
            <Grid
              container
              xs={8}
              justifyContent="flex-start"
              spacing={3}
              alignItems="flex-end"
            >
              <Grid item xs={2}>
                <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                  Ciclo Lectivo:
                </p>
              </Grid>
              <Grid item xs={3}>
                <SimpleSelect
                  name="cicloLectivo"
                  value={formValues.cicloLectivo}
                  handleChange={(e) => {
                    const value = e.target?.value ? Number(e.target.value) : 0;
                    setFormValues((prev) => ({
                      ...prev,
                      cicloLectivo: value,
                    }));
                  }}
                  placeholder="Seleccioná ciclo"
                  content={ciclosLectivos}
                  labelKey="anio"
                  valueKey="idCicloLectivo"
                  requiredField
                  disabled={onlyView}
                />
              </Grid>
              <Grid item xs={2}>
                <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                  Tipo de Acción:
                  {!Boolean(formValues?.tipoAccion) && (
                    <span style={{ color: '#ff0000' }}> *</span>
                  )}
                </p>
              </Grid>
              <Grid item xs={3}>
                <SimpleSelect
                  title=""
                  name="tipoAccion"
                  value={formValues.tipoAccion}
                  handleChange={(e) => {
                    const value = e.target?.value ? Number(e.target.value) : 0;
                    setFormValues((prev) => ({
                      ...prev,
                      tipoAccion: value,
                    }));
                  }}
                  placeholder="Seleccioná el tipo de Acción"
                  content={tipoAcciones}
                  labelKey="nombre"
                  valueKey="idTipoAccion"
                  disabled={isDublicating || onlyView}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={3}
            style={{ padding: '0 15px 15px', paddingRight: 15, marginTop: 23 }}
          >
            <Grid
              container
              xs={8}
              justifyContent="flex-start"
              spacing={3}
              alignItems="flex-end"
            >
              <Grid item xs={2}>
                <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                  Nombre de la Acción:
                  {!Boolean(formValues.autoCompleteInputValue) && (
                    <span style={{ color: '#ff0000' }}> *</span>
                  )}
                </p>
              </Grid>
              <Grid item xs={10}>
                <Autocomplete
                  value={formValues.autoCompleteValue}
                  freeSolo
                  fullWidth
                  onChange={(_, newValue: any) => {
                    if (typeof newValue === 'string') {
                      /*  setValue({
                         entity_nombre: newValue,
                       }); */
                      setFormValues((prev) => ({
                        ...prev,
                        nombre: newValue,
                        autoCompleteValue: { entity_nombre: newValue },
                      }));
                    } else if (newValue && newValue.inputValue) {
                      // Create a new value from the user input
                      setFormValues((prev) => ({
                        ...prev,
                        autoCompleteValue: {
                          entity_nombre: newValue.inputValue,
                        },
                      }));
                      /*   setValue({
                          entity_nombre: newValue.inputValue,
                        }); */
                    } else {
                      setFormValues((prev) => ({
                        ...prev,
                        autoCompleteValue: newValue,
                      }));
                      /*  setValue(newValue); */
                    }
                  }}
                  inputValue={formValues.autoCompleteInputValue}
                  getOptionLabel={(option) => option.entity_nombre}
                  onInputChange={(event: any, newInputValue) => {
                    if (event !== null && event?.key === undefined) {
                      setFormValues((prev) => ({
                        ...prev,
                        nombre: newInputValue,
                        autoCompleteInputValue: newInputValue,
                      }));
                    }
                  }}
                  id="controllable-states-demo"
                  options={accionesNombre}
                  renderInput={(params: any) => (
                    <TextField multiline={true} {...params} />
                  )}
                  style={{ marginBottom: '10px' }}
                  disabled={onlyView}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={3}
            style={{ padding: '0 15px 15px', paddingRight: 15, marginTop: 40 }}
          >
            <Grid
              container
              xs={8}
              justifyContent="flex-start"
              spacing={3}
              alignItems="flex-end"
            >
              <Grid item xs={2}>
                <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                  Orientación:
                  {formValues?.accionOrientaciones?.length === 0 && (
                    <span style={{ color: '#ff0000' }}> *</span>
                  )}
                </p>
              </Grid>

              <Grid
                container
                xs={6}
                justifyContent="flex-start"
                spacing={3}
                alignItems="flex-end"
              >
                <Grid item xs={6}>
                  <FormGroup
                    style={{
                      width: '20em',
                      display: 'flex',
                      flexWrap: 'wrap',
                      height: '8em',
                    }}
                  >
                    {orientaciones
                      ?.sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                      ?.map((orientacion, index: number) => {
                        const isChecked = formValues?.accionOrientaciones?.find(
                          (check) => {
                            return (
                              check?.idOrientacion ===
                              orientacion?.idOrientacion
                            );
                          },
                        );
                        return (
                          <FormControlLabel
                            key={index + '-' + orientacion.idOrientacion}
                            control={
                              <Checkbox
                                checked={isChecked?.status}
                                onChange={handleChangeOrientaciones(
                                  orientacion,
                                )}
                                disabled={onlyView}
                              />
                            }
                            disabled={onlyView}
                            label={orientacion?.nombre}
                          />
                        );
                      })}
                  </FormGroup>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={3}
            style={{ padding: '0 15px 15px', paddingRight: 15, marginTop: 23 }}
          >
            <Grid
              container
              xs={8}
              justifyContent="flex-start"
              spacing={3}
              alignItems="flex-end"
            >
              <Grid item xs={2}>
                <p style={{ fontFamily: 'Open Sans', marginRight: '8px' }}>
                  Cantidad de Horas ACAP:
                  {!Boolean(formValues?.horas) && (
                    <span style={{ color: '#ff0000' }}> *</span>
                  )}
                </p>
              </Grid>
              <Grid item xs={3}>
                <MainInput
                  label=""
                  customStyleLabel={{ color: '#737373', paddingBottom: 15 }}
                  handleChange={(e) => {
                    if (!e.target?.value || !onlyNumbers.test(e.target.value))
                      return;
                    const value = Number(e.target.value);
                    setFormValues((prev) => ({
                      ...prev,
                      horas: value,
                    }));
                  }}
                  placeholder="Cantidad de horas ACAP"
                  name="horas"
                  value={formValues?.horas}
                  fullWidth
                  disabled={onlyView}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-end"
            spacing={3}
            style={{
              padding: '0 15px 15px',
              paddingRight: 15,
              marginTop: 23,
              marginBottom: 23,
            }}
          >
            <Grid
              container
              xs={8}
              justifyContent="flex-start"
              spacing={3}
              alignItems="flex-end"
              direction="row"
            >
              <Grid item xs={2} justifyContent="flex-start">
                <p style={{ fontFamily: 'Open Sans' }}>
                  Descripción:
                  {!Boolean(formValues?.descripcion) && (
                    <span style={{ color: '#ff0000' }}> *</span>
                  )}
                </p>
              </Grid>
              <Grid item xs={10}>
                <MUIEditor
                  editorState={formValues.editorTextValue}
                  onChange={(value: any) => {
                    setFormValues((prev) => ({
                      ...prev,
                      editorTextValue: value,
                    }));
                  }}
                  config={editorConfig}
                />
                <MainButton
                  title="Guardar Descripción"
                  handleOnClick={() => {
                    setFormValues((prev) => ({
                      ...prev,
                      // Esto no devuelve un string pero asi se esta manejando
                      // la entidad devuelve un string, en que momento lo convierte ?
                      descripcion: convertToRaw(
                        prev.editorTextValue.getCurrentContent(),
                      ) as unknown as string,
                    }));
                  }}
                  disabled={onlyView}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <Footer buttonConfig={buttonConfig} />
    </>
  );
};
