import React from 'react';
import { useRouteMatch } from 'react-router';
import { useRouteScreenTitle } from '../../../commons/hooks';
import { BLOCKER_ID } from '../../../platform/permission/const';
import { CONFIGURATION_IMAGE_CARD } from '../../../commons/const/configurationImageCard';
import {
  MenuCardList,
  MenuCardItem,
} from '../../../commons/components/MenuCardList';

export const configurationItems: MenuCardItem[] = [
  {
    id: BLOCKER_ID.CONFIGURATION_ADMIN_USUARIOS,
    title: 'Gestión de Usuarios',
    img: CONFIGURATION_IMAGE_CARD.USUARIOS,
    path: '/usuarios',
  },
  {
    id: BLOCKER_ID.CONFIGURATION_ADMIN_APROBACION_ROLES,
    title: 'Aprobación de Roles',
    img: CONFIGURATION_IMAGE_CARD.APROBACION_ROLES,
    path: '/aprobacionroles',
  },
  {
    id: BLOCKER_ID.CONFIGURATION_ADMIN_MATRICULA,
    title: ' Confirmar matrícula',
    img: CONFIGURATION_IMAGE_CARD.CONFIGURACION_MATRICULA,
    path: '/configuracion_matricula',
  },
];

export const ConfigurationAdmin = () => {
  useRouteScreenTitle('Configuración Administración');
  const match = useRouteMatch();
  return <MenuCardList items={configurationItems} url={match.url} />;
};
