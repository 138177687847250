import React, { useMemo } from 'react';
import { Grid } from '@material-ui/core';
// import { SituacionesHeader } from './SituacionesHeader';
// import { SituacionesTable } from './SituacionesTable';
import { PropuestaHeader } from './PropuestaHeader';
import { PropuestaTable } from './PropuestaTable';
import { useStyles } from '../../styles/configuration';
// import { useSeccionLibbyFetch } from 'src/app/business';
// import { useSituacionLibbyFetch } from 'src/app/business/presentismo_v2/Situaciones';

export const Configuration = () => {
  const classes = useStyles();

  /* const filterSecciones = useMemo(
    () => ({
      0: [
        { path: 'jornada', value: 'Extendida' },
        { path: 'jornada', value: 'Simple' },
        { path: 'jornada', value: 'Completa' },
      ],
    }),
    [],
  ); */

  // const { data: secciones, working: seccionesWorking } = useSeccionLibbyFetch({
  //   filter: filterSecciones,
  // });
  // const situacionesFetch = useSituacionLibbyFetch();
  return (
    <Grid
      container
      classes={{
        root: classes.rootContainer,
      }}
    >
      <Grid
        item
        xs={12}
        classes={{
          root: classes.propuestaContainer,
        }}
      >
        <PropuestaHeader />
        <PropuestaTable />
      </Grid>
      {/* <Grid item xs={12}>
        <SituacionesHeader
          secciones={secciones}
          situacionesFetch={situacionesFetch}
        />
        <SituacionesTable
          secciones={secciones}
          situacionesFetch={situacionesFetch}
        />
      </Grid> */}
    </Grid>
  );
};
