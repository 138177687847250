import React, { ChangeEvent, useCallback, useMemo } from 'react';
import { useHistory, useRouteMatch } from 'react-router';
import {
  FormControl,
  FormControlLabel,
  Grid,
  makeStyles,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import { ButtonTypesProps, Footer, Loading } from 'src/commons';
import { useColegioEfectivizadoContext } from '../../context/EfectivizacionProvider';
import { usePeriodosJe } from 'src/screens/Private/EspaciosCurriculares/EspacioCurricular/Calificaciones/hooks/usePeriodosRoles';

const useStyles = makeStyles({
  footer: {
    display: 'flex',
    position: 'fixed',
    bottom: 0,
    left: 0,
    right: 0,
  },
});

export const CalificacionesPrimarioSwitch = () => {
  const classes = useStyles();
  const history = useHistory();
  const match = useRouteMatch();

  const { establecimientoEfectivizado, cicloLectivo } =
    useColegioEfectivizadoContext();

  const isEfectivizado = establecimientoEfectivizado as boolean;

  const { periodo, setPeriodo, isJe } = usePeriodosJe();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setPeriodo(String(event.target.value));
  };

  const handleSubmit = useCallback(() => {
    history.push(`${match.url}/${periodo}`);
  }, [history, match, periodo]);

  const handleLeave = useCallback(() => {
    history.push('/');
  }, [history]);

  const buttonConfig = useMemo<ButtonTypesProps[]>(() => {
    return [
      {
        title: 'Salir',
        size: 'medium',
        type: 'secondary',
        handleOnClick: handleLeave,
      },
      {
        title: 'Entrar',
        size: 'medium',
        handleOnClick: handleSubmit,
      },
    ];
  }, [handleLeave, handleSubmit]);

  return (
    <Grid container style={{ marginTop: '50px', marginLeft: '30px' }}>
      <Grid item xs>
        <Typography style={{ fontSize: '20px', marginBottom: '20px' }}>
          Seleccione Período
        </Typography>
      </Grid>
      {establecimientoEfectivizado === null ? (
        <Loading />
      ) : (
        <Grid item container>
          <FormControl component="fieldset">
            <RadioGroup
              aria-label="periodos"
              name="periodos"
              value={periodo}
              onChange={handleChange}
            >
              <FormControlLabel
                value={'1'}
                control={<Radio />}
                label="Primer Bimestre"
                disabled={isJe}
              />
              <FormControlLabel
                value={'2'}
                control={<Radio />}
                label="Segundo Bimestre"
              />
              <FormControlLabel
                value={'3'}
                control={<Radio />}
                label="Tercer Bimestre"
                disabled={isJe || isEfectivizado}
                // disabled={cicloLectivo?.idCicloLectivo === 24 || isJe}
              />
              <FormControlLabel
                value={'4'}
                control={<Radio />}
                label="Cuarto Bimestre"
                disabled={isEfectivizado}
                //disabled={cicloLectivo?.idCicloLectivo === 24}
              />
            </RadioGroup>
          </FormControl>
        </Grid>
      )}
      <Grid className={classes.footer}>
        <Footer buttonConfig={buttonConfig} />
      </Grid>
    </Grid>
  );
};
