import moment from 'moment';
import { SituacionV3 } from '../../../../../../../app/models';

export const getSituacionByDate = (
  situaciones: SituacionV3[],
  date: string,
  seccion: string,
  isJE: boolean,
) => {
  const _situacion = situaciones.find(
    (situacion) =>
      moment(date, 'YYYY-MM-DD').isBetween(
        moment(situacion.fechaDesde, 'YYYY-MM-DD'),
        moment(situacion.fechaHasta, 'YYYY-MM-DD'),
        undefined,
        '[]',
      ) &&
      situacion.secciones.find(
        (sa) =>
          sa.idSeccion === seccion &&
          (sa.afectaJE === isJE ||
            (sa.afectaJE === false && sa.afectaJS === false && isJE === false)),
      ),
  );
  return _situacion;
};
