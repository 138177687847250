import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { HeaderTable, InfoTable } from 'src/commons/components';
import { useRolesContext } from 'src/context/RolesContext';
import { level, useRouteScreenTitle } from 'src/commons';
import { FilterTablesBoletines } from './components/FilterBoletines';
import { Grid, Typography } from '@material-ui/core';
import {
  useGenerarinformeGetRequest,
  useGenerarinformePostRequest,
} from 'src/app/business/businessCustomEndpoints/generarinforme';
import { useBoletinesColumns } from './hooks';
import { useSnackbar } from 'notistack';
import { DEFAULT_HIDE_TIME } from 'src/commons/const/ui/snackbar';
import { ModalEspaciosCurricularesPendientes } from './components/ModalEspaciosCurriculares';
import customFormDialog from 'src/commons/services/customFormDialog';
import { useCalificacionesstateGetRequest } from 'src/app/business/businessCustomEndpoints/calificacionesstate';
import {
  useAlumnoMovimientoDAO,
  useAlumnoMovimientoLibbyFetch,
  usePipLibbyFetch,
  usePpsLibbyFetch,
} from 'src/app/business';
import { PermissionBlocker } from 'src/lib/permission/components/PermissionBlocker';
import { BLOCKER_ID } from 'src/platform/permission/const/BlockerId';
import { NIVEL } from 'src/commons/const/nivel';
import { ModalInformesPipPendientes } from './components/ModalInformesPipPendientes';
import {
  Seccion,
  EspacioCurricularState,
  AlumnoMovimiento,
} from 'src/app/models';
import { ResponseGenerarInforme } from './types';

const PAGE_NAME_BY_NIVEL: { [key: number]: string } = {
  1: 'Generación de Informes',
  2: 'Boletines e Informes',
  3: 'Boletines',
};

const NOMBRE_TIPO_INFORME: { [key: number]: string } = {
  1: 'Boletines',
  2: 'Informes de valoración',
  3: 'Informes de Escuela de verano',
  4: 'Informes PIP',
  5: 'Informes PPS',
  6: 'Certificados 7°',
};

export const Boletines = () => {
  const {
    selectedRole: { nivel: levelId, rol, localizacionId, localizacion },
    userInfo: { id: usuarioId },
  } = useRolesContext();
  const [filter, setFilter] = useState({
    anio: 0,
    periodo: 0,
  });

  const pageName = PAGE_NAME_BY_NIVEL[Number(levelId)];

  useRouteScreenTitle(pageName);
  const [firstStateFetch, setFirstStateFetch] = useState(true);

  const [loading, setLoading] = useState({
    idSeccion: '',
    loading: false,
    idTipoInforme: '',
    periodo: '',
  });
  const [direction, setDirection] = useState<'asc' | 'desc'>('desc');
  const [orderBy, setOrderBy] = useState('seccion');
  const [totalAlumnoMovimiento, setTotalAlumnoMovimiento] = useState([]);
  const alumnoMovimientoDAO = useAlumnoMovimientoDAO();

  const [idSecciones, setIdSecciones] = useState('');
  const { enqueueSnackbar } = useSnackbar();

  const {
    data: informesGeneradosRequest = [],
    working: wInformes,
    request: reFetch,
  } = useGenerarinformeGetRequest<ResponseGenerarInforme[]>({
    url: `/api/public/generarinforme?anio=${
      filter.anio === 0 ? '' : filter.anio
    }&periodo=${filter.periodo}&direction=${direction}&orderby=${orderBy}`,
    autoCall: Boolean(filter.periodo),
  });

  const aggregateFilter = useMemo(
    () => ({
      0: [
        {
          path: 'seccion.localizacion',
          value: localizacionId,
        },
      ],
    }),
    [localizacionId],
  );
  useEffect(() => {
    if (totalAlumnoMovimiento.length === 0 && localizacionId !== undefined) {
      alumnoMovimientoDAO
        .getCountBySeccionFilter(
          aggregateFilter,
          'matriculacion_secciones_alumnoCount',
        )
        .then((response) => {
          setTotalAlumnoMovimiento(response);
        });
    }
  }, [
    aggregateFilter,
    alumnoMovimientoDAO,
    totalAlumnoMovimiento.length,
    localizacionId,
  ]);

  const informesGenerados = useMemo(() => {
    const informesFiltered: ResponseGenerarInforme[] = [];

    if (Array.isArray(informesGeneradosRequest)) {
      informesGeneradosRequest.forEach((element) => {
        const findElement = totalAlumnoMovimiento.find(
          (alumnosInSeccion: {
            countofidalumnomovimiento: string;
            entity_id_seccion: string;
          }) =>
            (element?.id_seccion || element?.seccion?.idSeccion) ===
            alumnosInSeccion?.entity_id_seccion,
        );
        if (!!findElement) {
          informesFiltered.push(element);
        }
      });

      const informesPorAnio: ResponseGenerarInforme[] = [];

      if (filter.anio) {
        const filteredInformesPorAnio = informesFiltered.filter(
          (informe) => informe.seccion.anio.idAnio === filter.anio,
        );
        informesPorAnio.push(...filteredInformesPorAnio);
      } else {
        informesPorAnio.push(...informesFiltered);
      }

      return informesPorAnio?.sort((informeA, informeB) => {
        const nombreSeccionA = informeA?.seccion?.nombreSeccion
          ?.toLowerCase()
          .replace(/°+/g, '° ')
          .replace(/\s+/g, ' ');
        const nombreSeccionB = informeB?.seccion?.nombreSeccion
          ?.toLowerCase()
          .replace(/°+/g, '° ')
          .replace(/\s+/g, ' ');
        if (nombreSeccionA < nombreSeccionB) {
          return direction === 'desc' && orderBy === 'seccion' ? -1 : 1;
        }
        if (nombreSeccionA > nombreSeccionB) {
          return direction === 'desc' && orderBy === 'seccion' ? 1 : -1;
        }
        return 0;
      });
    } else if (
      'status' in informesGeneradosRequest &&
      'error' === informesGeneradosRequest.status
    ) {
      enqueueSnackbar(informesGeneradosRequest.message ?? 'Error', {
        variant: 'error',
      });

      return [];
    } else {
      return [];
    }
  }, [
    enqueueSnackbar,
    totalAlumnoMovimiento,
    direction,
    filter.anio,
    informesGeneradosRequest,
    orderBy,
  ]);

  const {
    data: espaciosCurriculares = [],
    working: wEspacios,
    request,
  } = useCalificacionesstateGetRequest<EspacioCurricularState[]>({
    url: `/api/calificaciones/calificacionesstate${idSecciones}`,
    autoCall: false,
  });

  useEffect(() => {
    if (idSecciones && firstStateFetch && !wInformes) {
      request();
      setFirstStateFetch(false);
    }
  }, [idSecciones, firstStateFetch, request, wInformes]);

  useEffect(() => {
    if (filter.periodo) setFirstStateFetch(true);
  }, [filter.periodo, setFirstStateFetch]);

  const informesFiltered = useMemo(() => {
    const isInicial = Number(levelId) === NIVEL.INICIAL;
    const isPrimaria = Number(levelId) === NIVEL.PRIMARIO;
    return informesGenerados.filter((informe) => {
      if (isInicial) {
        return (
          informe?.seccion?.anio?.idAnio === 15 ||
          informe?.seccion?.anio?.idAnio === 19
        );
      } else if (isPrimaria) {
        return (
          informe.seccion?.anio?.idAnio === 7 ||
          informe.seccion?.anio?.idAnio === 9
        );
      } else {
        return true;
      }
    });
  }, [informesGenerados, levelId]);
  const alumnoMovimientoFilter = useMemo(
    () => ({
      sec: [
        {
          path: 'seccion.idSeccion',
          value: informesFiltered.map((informe) => informe.seccion.idSeccion),
          method: 'in',
        },
      ],
    }),
    [informesFiltered],
  );

  const { data: alumnosMovimiento = [] } = useAlumnoMovimientoLibbyFetch({
    limit: 1000,
    aspect: 'shorted_relation',
    filter: alumnoMovimientoFilter,
    enabled:
      // (filter.anio === 7 || filter.anio === 15 || filter.anio === 19) &&
      (filter.periodo === 11 ||
        filter.periodo === 13 ||
        filter.periodo === 14 ||
        filter.periodo === 15) &&
      informesGenerados?.length > 0 &&
      informesFiltered.length > 0,
  });

  const alumnosFilter = useMemo(
    () => ({
      alumnos: [
        {
          path: 'alumno.idAlumno',
          value: alumnosMovimiento.map((alumno) => alumno.alumno.idAlumno),
          method: 'in',
        },
      ],
    }),
    [alumnosMovimiento],
  );

  const { data: pps = [], working: wPps } = usePpsLibbyFetch({
    limit: 1000,
    filter: alumnosFilter,
    enabled: alumnosMovimiento.length > 0 && Number(levelId) === NIVEL.PRIMARIO,
    checkDuplication: false,
    aspect: 'basic',
  });

  const { data: pipData = [], working: workingPip } = usePipLibbyFetch({
    limit: 200,
    filter: alumnosFilter,
    enabled: alumnosMovimiento.length > 0 && Number(levelId) === NIVEL.INICIAL,
    checkDuplicationDeep: true,
    aspect: 'basic',
  });

  const { request: generate } = useGenerarinformePostRequest();

  const handleGenerate = useCallback(
    async (seccion: Seccion, idTipoInforme: number, periodo?: number) => {
      setLoading({
        idSeccion: seccion.idSeccion,
        loading: true,
        idTipoInforme,
        periodo,
      });
      try {
        const { error, previewIds, previews } = await generate({
          idSeccion: seccion.idSeccion,
          idTipoInforme: idTipoInforme,
          idNivel: Number(levelId),
          idPeriodo: periodo === undefined ? filter.periodo : periodo,
          idUsuario: usuarioId,
        });

        const previewValidation =
          previewIds !== undefined ? !previewIds.length : !previews?.length;

        if (error) {
          enqueueSnackbar(
            error,
            // `¡Ha ocurrido un error al intentar generar los ${NOMBRE_TIPO_INFORME[idTipoInforme]} para la sección ${seccion?.nombreSeccion}!`,
            { variant: 'error', autoHideDuration: DEFAULT_HIDE_TIME },
          );
          setLoading({
            idSeccion: seccion.idSeccion,
            loading: false,
            idTipoInforme,
            periodo,
          });
        } else if (previewValidation) {
          setLoading({
            idSeccion: seccion.idSeccion,
            loading: false,
            idTipoInforme,
            periodo,
          });
          enqueueSnackbar(
            `¡No hay ${NOMBRE_TIPO_INFORME[idTipoInforme]} para la sección ${seccion?.nombreSeccion}!`,
            {
              variant: 'warning',
              autoHideDuration: DEFAULT_HIDE_TIME,
            },
          );
        } else {
          setLoading({
            idSeccion: seccion.idSeccion,
            loading: false,
            idTipoInforme,
            periodo,
          });
          reFetch();
          enqueueSnackbar(
            `¡Los ${NOMBRE_TIPO_INFORME[idTipoInforme]} para la sección ${seccion?.nombreSeccion} fueron generados con éxito!`,
            {
              variant: 'success',
              autoHideDuration: DEFAULT_HIDE_TIME,
            },
          );
        }
      } catch {
        enqueueSnackbar(
          `¡Ha ocurrido un error al intentar generar los ${NOMBRE_TIPO_INFORME[idTipoInforme]} para la sección ${seccion?.nombreSeccion}!`,
          { variant: 'error', autoHideDuration: DEFAULT_HIDE_TIME },
        );
        setLoading({
          idSeccion: seccion.idSeccion,
          loading: false,
          idTipoInforme,
          periodo,
        });
      }
    },
    [enqueueSnackbar, filter.periodo, generate, levelId, reFetch, usuarioId],
  );

  const openModal = useCallback(
    async (
      row: ResponseGenerarInforme & {
        idPeriodo: number;
        alumnos: AlumnoMovimiento[];
        idNivel: number;
        isPip: boolean;
        isPps: boolean;
      },
    ) => {
      await customFormDialog.show({
        title: `${row.seccion.nombreSeccion}`,
        renderComponent: row?.isPip ? (
          <ModalInformesPipPendientes
            rol={rol}
            idSeccion={row?.seccion?.idSeccion}
            pipData={pipData}
            alumnos={alumnosMovimiento}
            working={workingPip}
            reFetch={reFetch}
          />
        ) : (
          <ModalEspaciosCurricularesPendientes
            item={row}
            request={request}
            reFetchBoletin={reFetch}
          />
        ),
        sizeWidth: 'md',
      });
    },
    [alumnosMovimiento, pipData, request, rol, workingPip, reFetch],
  );

  const columns = useBoletinesColumns({
    reFetch,
    handleGenerate,
    loading,
    espaciosCurriculares,
    levelId,
    filter,
    openModal,
    alumnosMovimiento,
    pps,
    pipData,
  });

  useEffect(() => {
    if (informesGenerados.length > 0) {
      const seccionesId: any = informesGenerados.map((item: any) => {
        return item.seccion.idSeccion;
      });

      let sectionIds = `?&nivel=${levelId}&periodo=${
        filter.periodo
      }&secciones=${seccionesId.join(',')}`;

      setIdSecciones(sectionIds);
    }
  }, [levelId, filter.periodo, informesGenerados]);

  const handleRequestSort = (
    newOrderBy: string,
    newDirection: 'asc' | 'desc',
  ) => {
    setDirection(newDirection);
    setOrderBy(newOrderBy);
  };

  const working = useMemo(
    () => wInformes || wEspacios || wPps,
    [wInformes, wEspacios, wPps],
  );

  return (
    <PermissionBlocker id={BLOCKER_ID.BOLETINES}>
      <>
        <HeaderTable title={localizacion}>
          <FilterTablesBoletines
            filter={filter}
            setFilter={setFilter}
            levelId={levelId}
          />
        </HeaderTable>
        {filter.periodo ? (
          <InfoTable
            working={working}
            rows={informesGenerados}
            columns={columns || []}
            onSortChange={handleRequestSort}
            direction={direction}
            orderBy={orderBy}
          />
        ) : (
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            style={{ height: '400px' }}
          >
            <Typography
              style={{
                fontSize: '40px',
                marginTop: '50px',
                color: 'gray',
                fontWeight: 'bold',
              }}
            >
              Seleccione período para generar.
            </Typography>
          </Grid>
        )}
      </>
    </PermissionBlocker>
  );
};
