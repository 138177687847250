import { useCallback, useMemo, useState } from 'react';

interface PaginateProps<T> {
  items: T[];
  acumulate?: boolean;
  pageSize?: number;
  filter?: (item: T) => boolean;
}

const defaultFilter = () => true;

export const usePaginate = <T>({
  pageSize = 40,
  acumulate = false,
  items,
  filter = defaultFilter,
}: PaginateProps<T>) => {
  const [currentPage, setCurrentPage] = useState(1);

  const paginate = useMemo(() => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    const newResults = items
      .filter(filter)
      .slice(acumulate ? 0 : startIndex, endIndex);
    const totalPages = Math.ceil(items.length / pageSize);
    return { results: newResults, totalPages };
  }, [acumulate, currentPage, filter, items, pageSize]);

  const nexPage = useCallback(() => {
    setCurrentPage((prevPage) => prevPage + 1);
  }, []);

  const prevPage = useCallback(() => {
    setCurrentPage((prevPage) => {
      if (prevPage === 1) return prevPage;
      return prevPage - 1;
    });
  }, []);

  return { ...paginate, currentPage, nexPage, prevPage };
};
