import { ROL, TRAVERSE_ACTIONS } from 'src/commons';
import { LibbyFetchDAO } from './LibbyFetchDAO';

export class UsuarioRolTraverseActionDAO extends LibbyFetchDAO {
  constructor() {
    super('usuario_rol_traverse_action', 'usuarioRolTraverseAction');
  }

  getByUsuarioRolEstablecimiento(filter: any) {
    return this.fetch({ filter });
  }

  getByUsuarioRol(ids: string[]) {
    let query = this.query().groupStart();
    ids.forEach((id, index) => {
      if (index === 0) {
        query = query
          .equals('usuarioRolEstablecimiento', id)
          .equals('traverseAction.traverseActionId', 8);
      } else {
        query = query
          .or()
          .equals('usuarioRolEstablecimiento', id)
          .equals('traverseAction.traverseActionId', 8);
      }
    });
    return query.groupEnd().run();
  }

  getEjes(id: number, rol: number, localizacion: number) {
    return this.query()
      .equals('traverseAction', TRAVERSE_ACTIONS.ORIENTACION_EJE)
      .equals('usuarioRolEstablecimiento.usuario.idUsuario', id)
      .equals('usuarioRolEstablecimiento.rolUsuario.idRolUsuario', rol)
      .equals(
        'usuarioRolEstablecimiento.localizacion.idLocalizacion',
        localizacion,
      )
      .limit(0, 1)
      .run();
  }
}
