import { useRouteMatch } from 'react-router';
import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import moment from 'moment';
import { makeActionButtons } from './makeActionButtons';
import { EspaciosArealPlan } from '../../../../../../../app/models';
import { OptionsModal } from '../../../../../../../commons/components/OptionsModal';
import confirmDialog from 'src/commons/services/confirmDialog';
import { useSnackbar } from 'notistack';
import {
  useArealPlanDAO,
  useEspaciosArealPlanDAO,
  useArealPlanAreaDAO,
} from 'src/app/business';

const useActionButtons = makeActionButtons(
  'idEspaciosArealPlan',
  (row: EspaciosArealPlan) => row.arealPlan.planificacionEstados,
  'arealPlan',
);

export const useArealTableColumns = (reFetch: () => void) => {
  const { url } = useRouteMatch();
  const { enqueueSnackbar } = useSnackbar();

  const espaciosArealPlan = useEspaciosArealPlanDAO();
  const arealPlanDAO = useArealPlanDAO();
  const arealPlanArea = useArealPlanAreaDAO();

  const handleDeletePlanificacionAreal = useCallback(
    async (planificacion) => {
      const espaciosArealPlans = await espaciosArealPlan
        .query()
        .equals('id_areal_plan', planificacion.arealPlan.idArealPlan)
        .run();

      try {
        const confirm = await confirmDialog.show({
          title: 'Confirmación',
          content: `¿Esta seguro que desea eliminar la planificacion?`,
          confirmText: 'Eliminar',
          cancelText: 'No',
        });
        if (confirm) {
          for (let element of espaciosArealPlans) {
            await espaciosArealPlan.remove({
              idEspaciosArealPlan: element.idEspaciosArealPlan,
            });
          }
          for (let element of planificacion.arealPlan.arealPlanAreas) {
            await arealPlanArea.remove({
              idArealPlanArea: element.idArealPlanArea,
            });
          }
          await arealPlanDAO.remove({
            idArealPlan: planificacion.arealPlan.idArealPlan,
          });
          enqueueSnackbar('Se ha eliminado con éxito', { variant: 'success' });
          reFetch();
        }
      } catch (e) {
        enqueueSnackbar('Ha ocurrido un error al intentar eliminar', {
          variant: 'error',
        });
        reFetch();
      }
    },
    [espaciosArealPlan, arealPlanDAO, enqueueSnackbar, reFetch, arealPlanArea],
  );

  const actionButtons = useActionButtons({
    urls: {
      edit: `${url}/planificacion/interArealInterAreal`,
      feedback: `${url}/planificacionfeedback/interArealInterAreal`,
      preview: `${url}/planificacionpreview/interArealInterAreal`,
    },
    helper: {
      delete: handleDeletePlanificacionAreal,
    },
  });

  return useMemo(
    () => [
      {
        id: 'espacioCurricular.Area',
        label: 'Áreas',
        width: '15%',
        orderById: 'arealPlan.arealPlanAreas.area.descripcion',
        render: (value: any, row: EspaciosArealPlan) =>
          row.arealPlan.arealPlanAreas
            .map((item) => item.area.descripcion)
            .join(', '),
      },
      {
        id: 'espacioCurricular.PlanEstudio',
        label: 'Proyecto',
        width: '15%',
        orderById: 'espacioCurricular.planEstudio.descripcionPlanEstudio',
        render: (value: any, row: EspaciosArealPlan) => (
          <Box whiteSpace="nowrap">{row.arealPlan.proyecto.nombre}</Box>
        ),
      },
      {
        id: 'tipo',
        label: 'Tipo',
        width: '10%',
        style: {
          whiteSpace: 'nowrap',
        },
        hideSortIcon: true,
        render: () => 'Areal/InterAreal',
      },
      {
        id: 'arealPlan.ultimaActualizacion',
        label: 'Ultima modificación',
        width: '20%',
        render: (value: any, row: EspaciosArealPlan) => (
          <Box whiteSpace="nowrap">
            {row.arealPlan.ultimaActualizacion
              ? moment(row.arealPlan.ultimaActualizacion).format(
                  'DD/MM/YYYY - HH:mm',
                )
              : '-'}
          </Box>
        ),
      },
      {
        id: 'planificacionEstado',
        label: 'Estado',
        width: '10%',
        orderById: 'arealPlan.planificacionEstados.nombre',
        render: (value: any, row: EspaciosArealPlan) => (
          <Box whiteSpace="nowrap">
            {row.arealPlan.planificacionEstados.nombre ?? '-'}
          </Box>
        ),
      },
      {
        id: 'nombre',
        label: 'Creado Por',
        width: '10%',
        style: { textAlign: 'center' },
        orderById: 'arealPlan.cuentas.nombreUsuario',
        render: (value: any, row: EspaciosArealPlan) => {
          return (
            <Box whiteSpace="nowrap" textAlign="center">
              {`${row.arealPlan.cuentas.nombreUsuario} ${row.arealPlan.cuentas.apellidoUsuario}` ??
                '-'}
            </Box>
          );
        },
      },
      {
        id: 'icono',
        label: '',
        width: '5%',
        hideSortIcon: true,
        style: { textAlign: 'right' },
        render: (value: any, row: any) => (
          <Box whiteSpace="nowrap" textAlign="right">
            <OptionsModal options={actionButtons} data={row} />
          </Box>
        ),
      },
    ],
    [actionButtons],
  );
};
