import { makeEntityLibbyCall } from '../../lib/libby/hooks/makeEntityLibbyCall';
import { makeEntityLibbyDAO } from '../../lib/libby/hooks/makeEntityLibbyDAO';
import { GenerarExcelDAO } from '../../platform/libby/dao';

const DAO_NAME = 'generarexcel';

// hooks
export const useGenerarExcelLibbyCall =
  makeEntityLibbyCall<GenerarExcelDAO>(DAO_NAME);
export const useGenerarExcelDAO = makeEntityLibbyDAO<GenerarExcelDAO>(DAO_NAME);
