export enum TURNOS {
  MANIANA = 1,
  TARDE = 2,
  NOCHE = 3,
  VESPERTINO = 4,
  DOBLE = 5,
}

export const TURNOS_MAP: { [k: number]: string } = {
  [TURNOS.MANIANA]: 'Mañana',
  [TURNOS.TARDE]: 'Tarde',
  [TURNOS.NOCHE]: 'Noche',
  [TURNOS.VESPERTINO]: 'Vespertino',
};
