import { TableCell } from '@material-ui/core';
import { ReportProblemRounded } from '@material-ui/icons';
import React, { useMemo } from 'react';
import cx from 'classnames';
import { COLORS } from '../../Resume';
import { Tooltip } from '@material-ui/core';
import { TooltipAlertMessage } from '../../../../components/ModalAlertMgs';
import { AlertasType, AvisosCellProps } from '../../../types';
import { useAvisosCellStyles } from '../css';
import { Disabled } from '../../../../../../../stories/components/MainButton.stories';

export const getIconColor = (avisosCount: AlertasType) => {
  const [alertaUno, alertaDos, alertaTres, alertaCuatro, alertaCinco] =
    avisosCount;
  if (alertaCinco.active) return COLORS.RED;
  if (alertaCuatro.active) return COLORS.RED;
  if (alertaTres.active) return COLORS.RED;
  if (alertaDos.active) return COLORS.ORANGE;
  if (alertaUno.active) return COLORS.YELLOW;
  return null;
};

export const AvisosCell = ({
  avisosCount,
  defaultIcon = '-',
}: AvisosCellProps) => {
  const classes = useAvisosCellStyles();
  const color = useMemo(() => {
    if (!avisosCount) return;
    return getIconColor(avisosCount);
  }, [avisosCount]);

  return (
    <TableCell align="center" className={cx(classes.cell, classes.noBorder)}>
      {color ? (
        <Tooltip
          placement="right"
          classes={{ tooltip: classes.tooltip }}
          title={<TooltipAlertMessage alerts={avisosCount} />}
        >
          <ReportProblemRounded
            className={classes.icon}
            htmlColor={color.typography}
          />
        </Tooltip>
      ) : (
        defaultIcon
      )}
    </TableCell>
  );
};
