import React, { useCallback } from 'react';
import { FormControlLabel, List, Grid } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { makeStyles } from '@material-ui/styles';
import typography from 'src/theme/typography';
import { grey } from 'src/theme/colors';
import { ModalFormComponentProps } from '../../../../../../commons/const/ui/dialogs';

type SubLevel2 = {
  id: number | string;
  descripcion: JSX.Element[];
  checked: boolean;
};
type SubLevel = {
  id: number | string;
  descripcion: JSX.Element[];
  checked: boolean;
  subLevel2?: SubLevel2[];
};

export type ContenidosEspecificosFormValue = {
  id: number | string;
  descripcion: string;
  checked: boolean;
  subLevel: SubLevel[];
};

export type subLevel = {
  id: number | string;
  descripcion: string;
  checked: boolean;
};

const labelCheckboxStyles = makeStyles(() => ({
  label: {
    color: grey.textPrimary,
    fontSize: 14,
    fontFamily: typography.fontFamily,
  },
  labelPrimary: {
    background: `${grey.background}`,
    borderTop: `1px solid ${grey.normal}`,
    borderBottom: `1px solid ${grey.normal}`,
    padding: '5px 10px',
    marginBottom: 10,
  },
  listItem: {
    paddingLeft: 20,
    paddingTop: 0,
    paddingBottom: 0,
  },
  listItemSub: {
    paddingLeft: 20,
  },
}));

// TODO: move all this logic to commnmulticheckbox
export const ContenidosEspecificoForm = ({
  value = [],
  onChange,
}: ModalFormComponentProps<ContenidosEspecificosFormValue[]>) => {
  const onChangeValues = useCallback(
    (checked, index) => {
      const newValues = [...value];
      newValues[index] = { ...newValues[index], checked };
      onChange(newValues);
    },
    [onChange, value],
  );
  const onChangeSubLevelValues = useCallback(
    (checked, index, indexSubLevel) => {
      const newValues = [...value];
      const subLevel = [...newValues[index].subLevel];

      const subLevel2 = subLevel[indexSubLevel].subLevel2.map(
        (sub: subLevel) => ({ ...sub, checked }),
      );

      subLevel[indexSubLevel] = {
        ...subLevel[indexSubLevel],
        checked,
        subLevel2,
      };

      newValues[index] = { ...newValues[index], subLevel };
      onChange(newValues);
    },
    [onChange, value],
  );
  const onChangeSubLevel2Values = useCallback(
    (checked, index, indexSubLevel, indexSubLevel2) => {
      const newValues = [...value];
      const subLevel = [...newValues[index].subLevel];
      if (!subLevel[indexSubLevel].checked) {
        subLevel[indexSubLevel] = { ...subLevel[indexSubLevel], checked };
      }

      const findLevel = subLevel[indexSubLevel].subLevel2.filter(
        (sub2: subLevel) => sub2.checked,
      );

      if (findLevel.length === 1 && !checked) {
        subLevel[indexSubLevel] = { ...subLevel[indexSubLevel], checked };
      }

      const subLevel2 = [...subLevel[indexSubLevel].subLevel2];
      subLevel2[indexSubLevel2] = { ...subLevel2[indexSubLevel2], checked };
      subLevel[indexSubLevel] = { ...subLevel[indexSubLevel], subLevel2 };
      newValues[index] = { ...newValues[index], subLevel };
      onChange(newValues);
    },
    [onChange, value],
  );

  const classes = labelCheckboxStyles();

  return value.map((item: ContenidosEspecificosFormValue, index: number) => (
    <List key={item.id} dense component="div" role="list" disablePadding>
      <Grid container className={classes.labelPrimary}>
        <FormControlLabel
          control={
            <Checkbox
              checked={item.checked}
              onChange={({ target: { checked } }) =>
                onChangeValues(checked, index)
              }
              color="primary"
            />
          }
          label={item.descripcion}
          className={classes.label}
        />
      </Grid>
      {item.checked &&
        item.subLevel.map((sub, indexSubLevel) => (
          <List
            key={sub.id}
            dense
            component="div"
            role="list"
            className={classes.listItem}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={sub.checked}
                  onChange={({ target: { checked } }) =>
                    onChangeSubLevelValues(checked, index, indexSubLevel)
                  }
                  color="primary"
                />
              }
              label={sub.descripcion}
              className={classes.label}
            />
            {sub?.subLevel2?.map((sub2, indexSubLevel2) => (
              <List
                key={sub2.id}
                dense
                component="div"
                role="list"
                className={classes.listItemSub}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={sub2.checked}
                      onChange={({ target: { checked } }) =>
                        onChangeSubLevel2Values(
                          checked,
                          index,
                          indexSubLevel,
                          indexSubLevel2,
                        )
                      }
                      color="primary"
                    />
                  }
                  label={sub2.descripcion}
                  className={classes.label}
                />
              </List>
            ))}
          </List>
        ))}
    </List>
  ));
};
