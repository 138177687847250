import React, { useMemo, useState, useCallback } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Typography, Input } from '@material-ui/core';
import { level } from 'src/commons/const';
import { MainInput, MainButton } from 'src/commons/components';
import {
  PaisSelect,
  GeneroSelect,
  TurnoSelect,
  CicloLectivoSelect,
  ActividadExtracurricularSelect,
  NivelSelect,
  TipoDocumentoSelect,
  AnioSelect,
  useLocalizacionLibbyFetch,
} from 'src/app/business';
import { AnyObject } from 'src/commons';
import { primary } from 'src/theme/colors';
import { DatePicker } from 'src/app/components/inputs';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { ResultAlumnosSanatized } from 'src/platform/libby/dao/AlumnoExisteDAO';
import { CicloLectivo } from 'src/app/models';
import { useColegioEfectivizadoContext } from 'src/screens/Private/Calificaciones/context/EfectivizacionProvider';

const addStudentFormStyles = makeStyles(() => ({
  mainContainer: {
    flexDirection: 'column',
    display: 'flex',
    width: '100%',
    alignItems: 'center',
    alignContent: 'center',
    justifyItems: 'center',
    justifyContent: 'center',
  },
  inputContainer: {
    flexDirection: 'row',
    marginTop: 10,
    justifyContent: 'space-between',
  },
  selectContainer: {
    justifyContent: 'space-between',
    '& .MuiOutlinedInput-root': {
      width: '95%',
    },
  },
  customInput: {
    width: '20%',
    backgroundColor: 'red',
  },
  customStyleTitleSelect: {
    fontSize: 12,
    marginBottom: 2,
  },
  buttonContainer: {
    marginTop: 30,
  },
  datePicker: {
    paddingLeft: 0,
    '& .MuiOutlinedInput-root': {
      border: `1px solid ${primary.white}`,
    },
  },
  labelAsterisk: {
    color: 'red',
  },
  paddingTypography: {
    paddingBottom: 5,
  },
  apellidoSelect: {
    paddingLeft: 13,
    paddingBottom: 5,
  },
  disabledDate: {
    width: '100%',
    padding: 7,
  },
}));

export interface AlumnAdd {
  name: string;
  lastName: string;
  country: string | number;
  genre: string | number;
  cicloLectivo: string | number;
  selectLevel: string;
  turn: string;
  activity: string;
  typeDocument: string | number;
  numDocument: string | number;
  idAnio: string;
  date: ParsableDate;
}

export interface RecuperaAlumno extends AlumnAdd {
  idAlumno: string | number;
  localizacionPrevia: string | number;
}
interface AddStudentFormProps {
  external?: boolean;
  commonSchool?: boolean;
  handleAddExternalAlumn: (
    alumn: AlumnAdd | RecuperaAlumno,
    isDeleted?: boolean,
  ) => Promise<void>;
  deletedStudent?: ResultAlumnosSanatized;
  cicloLectivoActual?: CicloLectivo;
  localizacionId?: string | number;
}

export const AddStudentForm = ({
  handleAddExternalAlumn,
  external,
  commonSchool,
  deletedStudent,
  cicloLectivoActual,
  localizacionId,
}: AddStudentFormProps) => {
  const classes = addStudentFormStyles();
  const { cicloLectivo: cicloLectivoContext } = useColegioEfectivizadoContext();
  const [alumn, setAlumn] = useState<AlumnAdd>({
    name: deletedStudent?.name || '',
    lastName: deletedStudent?.lastName || '',
    country: deletedStudent?.country || '',
    genre: deletedStudent?.genre || '',
    cicloLectivo: cicloLectivoActual?.idCicloLectivo || '',
    selectLevel: '',
    turn: '',
    activity: '',
    typeDocument: deletedStudent?.typeDocument || '',
    numDocument: deletedStudent?.numDocument || '',
    idAnio: '',
    date: deletedStudent?.date || null,
  });

  const filterLocalizacion = useMemo(
    () => ({
      localizacion: [
        {
          path: 'idLocalizacion',
          value: localizacionId,
        },
      ],
    }),
    [localizacionId],
  );

  const { data: localizacion }: any = useLocalizacionLibbyFetch({
    limit: 1,
    filter: filterLocalizacion,
    aspect: 'dependencia_funcional',
  });

  const DEPENDENCIA_FUNCIONAL =
    localizacion[0]?.establecimiento?.dependenciaFuncional
      ?.idDependenciaFuncional;

  const filterLevel = useMemo(
    () => ({
      id: [
        {
          path: 'idNivel',
          value: level.INICIAL,
        },
        {
          path: 'idNivel',
          value: level.PRIMARIO,
        },
        {
          path: 'idNivel',
          value: level.SECUNDARIO,
        },
      ],
    }),
    [],
  );

  const filterGenero = useMemo(
    () => ({
      0: [
        {
          path: 'idGenero',
          value: 3,
          method: 'notEquals',
        },
      ],
      1: [
        {
          path: 'idGenero',
          value: -1,
          method: 'notEquals',
        },
      ],
    }),
    [],
  );

  const handleSubmit = useCallback(() => {
    if (deletedStudent) {
      handleAddExternalAlumn(
        {
          ...alumn,
          idAlumno: deletedStudent.idAlumno,
          localizacionPrevia: deletedStudent.localizacionId,
        },
        deletedStudent.isDeleted,
      );
    } else {
      handleAddExternalAlumn({
        ...alumn,
        name: alumn.name.toUpperCase(),
        lastName: alumn.lastName.toUpperCase(),
      });
    }
  }, [handleAddExternalAlumn, alumn, deletedStudent]);

  const validateFormSave = () => {
    const inputValidetor =
      !alumn.name ||
      !alumn.lastName ||
      !alumn.country ||
      !alumn.genre ||
      !alumn.idAnio ||
      !alumn.cicloLectivo ||
      !alumn.turn ||
      !alumn.typeDocument ||
      !alumn.numDocument ||
      !alumn.date;

    if (commonSchool) return inputValidetor || !alumn.selectLevel;
    if (external) return inputValidetor || !alumn.activity;
    return inputValidetor;
  };

  return (
    <Grid container className={classes.mainContainer}>
      <Grid container className={classes.inputContainer}>
        <Grid item xs={6}>
          <Typography className={classes.paddingTypography}>
            Nombre
            <span className={classes.labelAsterisk}>
              {alumn.name ? '' : ' *'}
            </span>
          </Typography>
          <MainInput
            value={alumn.name}
            handleChange={({ target }) => {
              setAlumn({ ...alumn, name: target.value });
            }}
            placeholder="Nombre"
            name="name"
            fullWidth
            customStyleLabel={{
              fontSize: 12,
              paddingBottom: 10,
              backgroundColor: 'blue',
            }}
            disabled={!!deletedStudent?.name}
            customStyle={{ width: '95%' }}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography className={classes.apellidoSelect}>
            Apellido
            <span className={classes.labelAsterisk}>
              {alumn.lastName ? '' : ' *'}
            </span>
          </Typography>
          <MainInput
            name="lastName"
            value={alumn.lastName}
            handleChange={({ target }) => {
              setAlumn({ ...alumn, lastName: target.value });
            }}
            placeholder="Apellido"
            fullWidth
            customStyleLabel={{
              fontSize: 12,
              paddingBottom: 10,
              marginLeft: 0,
            }}
            customStyle={{ width: '95%' }}
            disabled={!!deletedStudent?.lastName}
          />
        </Grid>
      </Grid>
      <Grid container className={classes.inputContainer}>
        <Grid className={classes.selectContainer} item xs={4}>
          <Typography className={classes.paddingTypography}>
            País
            <span className={classes.labelAsterisk}>
              {alumn.country ? '' : ' *'}
            </span>
          </Typography>
          <PaisSelect
            orderBy="descripcionPais"
            handleChange={({ target }) => {
              setAlumn({ ...alumn, country: target.value });
            }}
            title=""
            value={alumn.country}
            name="country"
            customStyleTitle={{ color: primary.black, fontSize: 12 }}
            disabled={!!deletedStudent?.country}
          />
        </Grid>
        <Grid className={classes.selectContainer} item xs={4}>
          <Typography className={classes.paddingTypography}>
            Genero
            <span className={classes.labelAsterisk}>
              {alumn.genre ? '' : ' *'}
            </span>
          </Typography>
          <GeneroSelect
            handleChange={({ target }) => {
              setAlumn({ ...alumn, genre: target.value });
            }}
            title=""
            value={alumn.genre}
            name="genre"
            customStyleTitle={{ color: primary.black, fontSize: 12 }}
            disabled={!!deletedStudent?.genre}
            filter={filterGenero}
          />
        </Grid>
        <Grid className={classes.selectContainer} item xs={4}>
          <Typography className={classes.paddingTypography}>
            Fecha de nacimiento
            <span className={classes.labelAsterisk}>
              {alumn.date ? '' : ' *'}
            </span>
          </Typography>
          {deletedStudent?.date ? (
            <Input
              value={deletedStudent?.date}
              disabled={!!deletedStudent?.date}
              disableUnderline
              className={classes.disabledDate}
            />
          ) : (
            <DatePicker
              value={alumn.date}
              onChange={(_date) =>
                setAlumn({ ...alumn, date: _date?.toDate() })
              }
              className={classes.datePicker}
              placeholder="Ingrese la fecha"
              fullWidth
              disableFuture
              clearable
            />
          )}
        </Grid>
      </Grid>
      <Grid container className={classes.inputContainer}>
        <Grid className={classes.selectContainer} item xs={6}>
          <Typography className={classes.paddingTypography}>
            Tipo de documento
            <span className={classes.labelAsterisk}>
              {alumn.typeDocument ? '' : ' *'}
            </span>
          </Typography>
          <TipoDocumentoSelect
            handleChange={({ target }) => {
              setAlumn({ ...alumn, typeDocument: target.value });
            }}
            orderBy="descripcionTipoDocumento"
            title=""
            value={alumn.typeDocument}
            customStyleTitle={{ color: primary.black, fontSize: 12 }}
            disabled={!!deletedStudent?.typeDocument}
          />
        </Grid>
        <Grid className={classes.selectContainer} item xs={6}>
          <Typography className={classes.paddingTypography}>
            Número de documento
            <span className={classes.labelAsterisk}>
              {alumn.numDocument ? '' : ' *'}
            </span>
          </Typography>
          <MainInput
            placeholder="Escribí el documento"
            fullWidth
            name="numDocument"
            handleChange={({ target }) => {
              setAlumn({ ...alumn, numDocument: target.value });
            }}
            value={alumn.numDocument}
            customStyleLabel={{ fontSize: 12, paddingBottom: 15 }}
            disabled={!!deletedStudent?.numDocument}
            customStyle={{ width: '95%' }}
          />
        </Grid>
        <Grid container className={classes.inputContainer}>
          <Grid className={classes.selectContainer} item xs={6}>
            <Typography className={classes.paddingTypography}>
              Ciclo Lectivo
              <span className={classes.labelAsterisk}>
                {alumn.cicloLectivo ? '' : ' *'}
              </span>
            </Typography>
            <CicloLectivoSelect
              title=""
              placeholder="Seleccioná Ciclo"
              value={
                alumn.cicloLectivo !== ''
                  ? alumn.cicloLectivo
                  : Number(cicloLectivoActual?.idCicloLectivo)
              }
              handleChange={({ target }) => {
                setAlumn({
                  ...alumn,
                  cicloLectivo:
                    target.value !== ''
                      ? target.value
                      : cicloLectivoActual?.idCicloLectivo,
                });
              }}
              transformOptions={(params: AnyObject[]): AnyObject[] => {
                if (!params.length) return params;
                const isDFInicial = DEPENDENCIA_FUNCIONAL === 10;
                const editedParams = params.filter((param) => {
                  const value = !!cicloLectivoActual
                    ? cicloLectivoActual?.anio
                    : cicloLectivoContext?.anio;
                  return isDFInicial
                    ? param.anio >= value
                    : param.anio === value;
                });
                return editedParams;
              }}
            />
          </Grid>
          <Grid className={classes.selectContainer} item xs={6}>
            <Typography className={classes.paddingTypography}>
              Turno
              <span className={classes.labelAsterisk}>
                {alumn.turn ? '' : ' *'}
              </span>
            </Typography>
            <TurnoSelect
              handleChange={({ target }) => {
                setAlumn({ ...alumn, turn: target.value });
              }}
              title=""
              value={alumn.turn}
              name="turn"
              customStyleTitle={{ color: primary.black, fontSize: 12 }}
            />
          </Grid>
        </Grid>
        <Grid container className={classes.inputContainer}>
          {external && (
            <Grid className={classes.selectContainer} item xs={6}>
              <Typography className={classes.paddingTypography}>
                Actividad Extracurricular
                <span className={classes.labelAsterisk}>
                  {alumn.activity ? '' : ' *'}
                </span>
              </Typography>
              <ActividadExtracurricularSelect
                handleChange={({ target }) => {
                  setAlumn({ ...alumn, activity: target.value });
                }}
                title=""
                value={alumn.activity}
                name="activity"
                customStyleTitle={{ color: primary.black, fontSize: 12 }}
              />
            </Grid>
          )}
          {commonSchool && (
            <Grid className={classes.selectContainer} item xs={6}>
              <Typography className={classes.paddingTypography}>
                Nivel
                <span className={classes.labelAsterisk}>
                  {alumn.selectLevel ? '' : ' *'}
                </span>
              </Typography>
              <NivelSelect
                handleChange={({ target }) => {
                  setAlumn({ ...alumn, selectLevel: target.value });
                }}
                title=""
                value={alumn.selectLevel}
                name="selectLevel"
                filter={filterLevel}
                customStyleTitle={{ color: primary.black, fontSize: 12 }}
              />
            </Grid>
          )}
          <Grid className={classes.selectContainer} item xs={6}>
            <Typography className={classes.paddingTypography}>
              Año
              <span className={classes.labelAsterisk}>
                {alumn.idAnio ? '' : ' *'}
              </span>
            </Typography>
            <AnioSelect
              orderBy="numeroAnio,descripcionAnio"
              handleChange={({ target }) => {
                setAlumn({ ...alumn, idAnio: target.value });
              }}
              title=""
              value={alumn.idAnio}
              name="idAnio"
              customStyleTitle={{ color: primary.black, fontSize: 12 }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid className={classes.buttonContainer}>
        <MainButton
          title="Guardar Estudiante"
          size="medium"
          disabled={validateFormSave()}
          handleOnClick={handleSubmit}
        />
      </Grid>
    </Grid>
  );
};
