import { Metadata } from '../../models/presentismo_v2';
import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'metadata';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idMetadata',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idMetadata',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.Metadata
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.Metadata,
      hoc: { ...defaultOptions.hoc, ...businessOptions.Metadata.hoc },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    entityAutocomplete,
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<Metadata>(DAO_NAME, optionsToUse);

// hooks
export const useMetadataFetchById = fetchById;
export const useMetadataLibbyCall = libbyCall;
export const useMetadataDAO = libbyDAO;
export const useMetadataLibbyFetch = libbyFetch;

// components/hoc
export const MetadataAutocomplete = entityAutocomplete;
export const MetadataCall = renderPropCall;
export const MetadataById = renderPropFetchById;
export const MetadataSelect = simpleSelect;
export const MetadataAccordionSelector = accordionSelector;
export const MetadataSelectedChips = selectedChips;

// context
// ByIdContext
export const useMetadataByIdContext = useByIdContext;
export const MetadataByIdProvider = ByIdProvider;
export const MetadataByIdContext = ByIdContext;
// GeneralContext
export const useMetadataContext = useGeneralContext;
export const MetadataProvider = GeneralProvider;
export const MetadataContext = GeneralContext;
