import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { ISwitchItem } from '../interface/SwitchItem';
import { AntSwitch } from './AntSwicth';

export const SwitchItem = (item: ISwitchItem) => {
  const labelledby = `SwitchItem-${item.descripcionNivel.toLowerCase()}`;
  return (
    <Grid container alignItems="center">
      <Grid item xs={8}>
        <Typography
          style={{ fontWeight: 'bold' }}
          variant="body1"
          color="textPrimary"
        >
          {item.descripcionNivel}
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <AntSwitch
          edge="end"
          name={item.descripcionNivel}
          inputProps={{ 'aria-labelledby': labelledby }}
          color={'primary'}
          checked={item.checked}
          onChange={item.onSwicth && item.onSwicth(item)}
        />
      </Grid>
    </Grid>
  );
};

/* 
    <ListItem key={labelledby} style={{ paddingLeft: item.idNivel ? 32 : undefined }}>
      <ListItemText primary={item.idNivel && item.name} secondary={!item.idNivel && item.name} />
      <ListItemSecondaryAction>
        <Switch
          edge="end"
          name={item.name}
          inputProps={{ 'aria-labelledby': labelledby }}
          checked={item.checked}
          onChange={item.onSwicth && item.onSwicth(item)}
        />
      </ListItemSecondaryAction>
    </ListItem>

*/
