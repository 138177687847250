import React, { useEffect } from 'react';
import { ROL } from 'src/commons/const';
import { Content } from '../TabBar';
import { useRolesContext } from 'src/context/RolesContext';

interface CustomRedirectsArguments {
  filteredContent: Array<Content>;
  setTabBarValue: (tabIndex: number) => void;
}

export const useCustomRedirects = ({
  filteredContent,
  setTabBarValue,
}: CustomRedirectsArguments) => {
  const {
    selectedRole: { rol },
  } = useRolesContext();

  // 1. ACAP Docente - redirect específico
  useEffect(() => {
    if (
      ROL.DOCENTE === Number(rol) &&
      filteredContent[filteredContent?.length - 1]?.title === 'Devoluciones'
    ) {
      setTabBarValue(filteredContent?.length - 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
