import { useEffect } from 'react';
import {
  EspacioCurricular,
  Orientacion,
  Area,
  Bloques,
  Nivel,
  PlanEstudioNivel,
  LocalizacionPlanEstudioNivel,
  Seccion,
} from 'src/app/models';
import { EntityLibbyCallOptions, LibbyCallReturn } from 'src/lib/libby';

export type MakeEntityLibbyCallReturn = <T>(
  options: EntityLibbyCallOptions,
) => LibbyCallReturn<T>;

type FilterPath = { path: string; value: number | string; method?: string };
export type MakeGetFilterAndLimit<T = any> = (entity?: T) => {
  filter: { [k: string]: FilterPath[] };
  limit: number;
};
// TODO: organize this in subfiles
export const makeEntityBy =
  <S>() =>
  <T = any>(
    useCallDAO: MakeEntityLibbyCallReturn,
    getFilterAndLimit: MakeGetFilterAndLimit<S>,
    ignoreBy?: boolean,
    aspect?: string,
  ) =>
  (by?: S) => {
    const { working, data, recall } = useCallDAO<T>({
      methodName: 'fetch',
      params: [],
      noAutoCall: true,
      aspect,
    });

    useEffect(() => {
      if (recall && (ignoreBy || by)) {
        const filterAndLimit = getFilterAndLimit(by);
        recall(filterAndLimit);
      }
    }, [by, recall]);
    return { working, data, recall };
  };

export const makeEntityByEspacio = makeEntityBy<EspacioCurricular>();
export const makeEntityByOrientaciones = makeEntityBy<Orientacion[]>();
export const makeEntityByAreas = makeEntityBy<Area[]>();
export const makeEntityByEspacios = makeEntityBy<EspacioCurricular[]>();
export const makeEntityByBloques = makeEntityBy<Bloques[]>();
export const makeEntityBySecciones = makeEntityBy<Seccion[]>();
export const makeEntityByLocalizacionPlanEstudios =
  makeEntityBy<LocalizacionPlanEstudioNivel[]>();
