import { Grid } from '@material-ui/core';
import React, { useMemo } from 'react';

import { TabBar } from 'src/commons';
import { TABBAR_BLOCKER_ID } from 'src/platform/permission/const';
import PermissionsAssign from './PermissionsAssign';

const RolesPermisos = () => {
  const tabbarItems = useMemo(
    () => [
      {
        id: TABBAR_BLOCKER_ID.PERMISSION_MODULE_PEM_FORM,
        title: 'Asignación de Permisos',
        component: <PermissionsAssign />,
      },
    ],
    [],
  );

  return (
    <Grid container>
      <Grid item xs={12}>
        <TabBar content={tabbarItems} />
      </Grid>
    </Grid>
  );
};

export default RolesPermisos;
