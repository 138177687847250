import { Tooltip } from '@material-ui/core';
import React from 'react';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

export const colorSectionInscription = (item: string) => {
  if (item === 'Políticas públicas') {
    return '#db6e03';
  }
  if (item === 'Académico') {
    return '#b3188b';
  }
  if (item === 'Sociocomunitario') {
    return '#f9d92d';
  }
  if (item === 'Productivo') {
    return '#0053a3';
  }
};

export const colorSectionDiary = (item: string) => {
  if (item === 'Políticas públicas') {
    return (
      <Tooltip title="Políticas públicas">
        <FiberManualRecordIcon style={{ color: '#db6e03' }} />
      </Tooltip>
    );
  }

  if (item === 'Académico') {
    return (
      <Tooltip title="Académico">
        <FiberManualRecordIcon style={{ color: '#b3188b' }} />
      </Tooltip>
    );
  }

  if (item === 'Sociocomunitario') {
    return (
      <Tooltip title="Sociocomunitario">
        <FiberManualRecordIcon style={{ color: '#f9d92d' }} />
      </Tooltip>
    );
  }

  if (item === 'Productivo') {
    return (
      <Tooltip title="Productivo">
        <FiberManualRecordIcon style={{ color: '#0053a3' }} />
      </Tooltip>
    );
  }
};
