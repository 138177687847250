import { useMemo, useRef } from 'react';
import {
  Template,
  TemplateProperty,
} from '../../../../../../../../lib/templates';
import { Templates } from '../../../../../../../../app/models';

export type TemplateJoinerConfig = {
  [key: string]: string;
};

export type TemplateJoinerTemplates = {
  [key: string]: Templates | any;
};

export const useTemplateJoiner = (
  templates: TemplateJoinerTemplates,
  config: TemplateJoinerConfig,
  joinedName = 'JoinedTemplates',
): Template | null => {
  const configRef = useRef<TemplateJoinerConfig>(config);
  return useMemo<Template | null>(() => {
    if (!templates) {
      return null;
    }
    const join: TemplateProperty[] = Object.entries(configRef.current).map(
      ([name, templateKey]) => ({
        name,
        type: {
          name: 'object',
          kind: templates[templateKey].template,
        },
      }),
    );
    return { name: joinedName, template: join };
  }, [joinedName, templates]);
};
