import React, { FC, useEffect, useMemo } from 'react';
import { Grid, createStyles } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  FormMultiCheckboxInput,
  FormMultiCheckbox,
} from 'src/lib/templates/components';
import { usePlanificationFormDisciplinarComun } from '../../hooks';
import { CheckboxListItem } from '../../../commons/components/CheckboxListItem';
import { useDisciplinarComunContext } from '../../components/context';
import { SimpleSelect } from 'src/commons';
import {
  INITIAL_YEAR,
  useCicloLectivoLibbyFetch,
  useProyeccionEstadoLibbyFetch,
} from 'src/app/business';
import { useField } from 'react-final-form-hooks';
import { grey } from '@material-ui/core/colors';
import { useRolesContext } from 'src/context/RolesContext';

const customStyles = {
  container: {
    padding: '32px 10px',
    marginBottom: 60,
  },
  inputStyle: { padding: 10, marginTop: 23 },
};

const useStyles = makeStyles((theme) => createStyles(customStyles));

export const PropositosEnsenanza: FC = () => {
  const classes = useStyles();
  const { form } = usePlanificationFormDisciplinarComun();
  const { data } = useDisciplinarComunContext();
  const buttonCustomStyle = { margin: '10px 0px 25px 0' };

  const { selectedRole } = useRolesContext();

  const filterProyeccionEstado = useMemo(
    () => ({
      0: [{ path: 'localizacion', value: selectedRole.localizacionId }],
    }),
    [selectedRole.localizacionId],
  );

  const { data: proyeccionEstado = [] } = useProyeccionEstadoLibbyFetch({
    limit: 1,
    filter: filterProyeccionEstado,
    orderBy: 'cicloLectivo',
    direction: 'desc',
  });

  useEffect(() => {
    form.change(
      'ensenanza.cicloLectivo',
      proyeccionEstado[0]?.cicloLectivo?.idCicloLectivo,
    );
  }, [proyeccionEstado, form]);

  return (
    <Grid container direction="column" className={classes.container}>
      <FormMultiCheckbox
        name="ensenanza.propositosDeEnsenanzas"
        // @ts-ignore
        options={data?.propositos}
        form={form}
        idKey="idProposito"
      />
      <FormMultiCheckboxInput
        label=""
        name="ensenanza.adicionales"
        form={form}
        addButtonText="Agregar Propósito"
        buttonCustomStyle={buttonCustomStyle}
        getNewElement={(value) => ({
          checked: false,
          descripcion: '',
          id: -1 - 1 * value.filter((item: any) => item.id < 0).length, // use negative id to validate new items
        })}
      >
        {(value, input, meta, index) => (
          <CheckboxListItem
            inputPlaceholder="Nuevo propósito"
            value={value}
            input={input}
            meta={meta}
            index={index}
          />
        )}
      </FormMultiCheckboxInput>
    </Grid>
  );
};
