import moment from 'moment';
import { Presente_v3 } from 'src/app/models';
import { ESTADO_PRESENTE } from 'src/commons';
import { PresenteGeneral } from '../../TomaPresentismo/types';
import { capitalizarPalabras } from './functions';

export const getDataJE = (periodoFiltrado: PresenteGeneral[]) => {
  const cantidadPresetes = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.PRESENTE,
    )
    .length.toString();
  const cantidadAusentes = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE,
    )
    .length.toString();
  const cantidadAustenesJuistificados = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE &&
        item.justificacion !== null,
    )
    .length.toString();

  const sinDatos = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.NO_CORRESPONDE,
    )
    .length.toString();

  const SinJustificacion = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE ||
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.INGRESO_TARDIO ||
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.RETIRO_ANTICIPADO ||
        item.justificacion !== null,
    )
    .length.toString();

  const SinJustificacionJE = periodoFiltrado
    .filter(
      (item) =>
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE ||
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.INGRESO_TARDIO ||
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.RETIRO_ANTICIPADO ||
        (item.justificacion !== null && item.isJornadaExtendida === true),
    )
    .length.toString();

  const JornadaExtendidaPresentes = periodoFiltrado
    .filter(
      (item) =>
        item.isJornadaExtendida === true &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.PRESENTE,
    )
    .length.toString();

  const JornadaExtendidaAusente = periodoFiltrado
    .filter(
      (item) =>
        item.isJornadaExtendida === true &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE,
    )
    .length.toString();

  const JornadaExtendidaAusentesJustificados = periodoFiltrado
    .filter(
      (item) =>
        item.isJornadaExtendida === true &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.AUSENTE &&
        item.justificacion !== null,
    )
    .length.toString();

  const jornadaExtendidaIngresosTardios = periodoFiltrado
    .filter(
      (item) =>
        item.isJornadaExtendida === true &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.INGRESO_TARDIO,
    )
    .length.toString();

  const jornadaExtendidaingnresoTardiosJustificados = periodoFiltrado
    .filter(
      (item) =>
        item.isJornadaExtendida === true &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.INGRESO_TARDIO &&
        item.justificacion !== null,
    )
    .length.toString();

  const JornadaExtendidaRetirosAntisipados = periodoFiltrado
    .filter(
      (item) =>
        item.isJornadaExtendida === true &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.RETIRO_ANTICIPADO,
    )
    .length.toString();

  const JornadaExtendidaAnticipadosJustificados = periodoFiltrado
    .filter(
      (item) =>
        item.isJornadaExtendida === true &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.RETIRO_ANTICIPADO &&
        item.justificacion !== null,
    )
    .length.toString();

  const JornadaExtendidaAuusentesConPresenciaEnClases = periodoFiltrado
    .filter(
      (item) =>
        item.isJornadaExtendida === true &&
        item.estadoPresente.idEstadoPresente ===
          ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE,
    )
    .length.toString();

  const JornadaExtendidaAusentesConPresenciaEnClasesJustificado =
    periodoFiltrado
      .filter(
        (item) =>
          item.isJornadaExtendida === true &&
          item.estadoPresente.idEstadoPresente ===
            ESTADO_PRESENTE.AUSENTE_PRESENCIA_EN_CLASE &&
          item.justificacion !== null,
      )
      .length.toString();

  const JornadaExtenidadSinDatos = periodoFiltrado
    .filter(
      (item) =>
        item.isJornadaExtendida === true &&
        item.estadoPresente.idEstadoPresente === ESTADO_PRESENTE.NO_CORRESPONDE,
    )
    .length.toString();

  return [
    {
      Nombre: 'Presente',
      CantidadPresente: cantidadPresetes,

      ResumenJustificacionGuion: '-',
      JornadaExtendidaGuion: '-',
    },
    {
      Nombre: 'Ausente',
      CantidadAsuntes: cantidadAusentes,

      NombreAusentesJustificados: 'Ausentes Justificados',

      JornadaExtendidaAusentesJustificados:
        JornadaExtendidaAusentesJustificados,
    },
    {
      Nombre: 'No Corresponde',
      CantidadSinDatos: sinDatos,
    },
  ];
};

export const getTableSecDataJE = (periodoFiltrado: PresenteGeneral[]) => {
  const uniqueDates = [...new Set(periodoFiltrado.map((item) => item.fecha))];

  const columns = [
    'Día',
    'Fecha',
    'Presentismo',
    'Presentismo JE',
    'Justificación Ausencia',
    'Justificación Ausencia JE',
  ];

  const data = uniqueDates.map((date) => {
    const itemsForDate = periodoFiltrado.filter((item) => item.fecha === date);

    const diaCapitalizado = capitalizarPalabras(
      moment(itemsForDate[0].fecha).format('dddd'),
    );

    return {
      dia: diaCapitalizado,
      fecha: moment(itemsForDate[0].fecha).format('DD-MM-YYYY'),
      presentismo: itemsForDate.find(
        (item) => item.isJornadaExtendida === false,
      )?.estadoPresente.descripcion,
      presentismoJE:
        itemsForDate.find((item) => item.isJornadaExtendida === true)
          ?.estadoPresente?.descripcion ?? '-', //? si no hay presentismoJE, se pone un guion
      justificacionAusencia: itemsForDate.some(
        (item) =>
          item.justificacion === null && item.isJornadaExtendida === false,
      )
        ? 'No'
        : 'Si',
      justificacionAusenciaJE:
        itemsForDate.filter(
          (item) =>
            item.justificacion !== null && item.isJornadaExtendida === true,
        ).length > 0
          ? 'Si'
          : 'No',
    };
  });

  return { columns, data };
};
