import {
  makeBusinessEntity,
  PartialBusinessEntityOptions,
} from '../../../lib/libby/generator';
import { ConfirmacionMatriculaLocalizacion } from '../../models';
import { businessOptions } from '../businessOptions';

const DAO_NAME = 'confirmacion_matricula_localizacion';

const defaultOptions: PartialBusinessEntityOptions<any> = {
  hoc: {
    withAccordionSelector: {
      idKey: 'idConfirmacionMatriculaLocalizacion',
      labelKey: 'descripcion',
    },
    withSelectedChips: {
      idKey: 'idConfirmacionMatriculaLocalizacion',
      labelKey: 'descripcion',
    },
  },
};

const optionsToUse = !businessOptions.ConfirmacionMatriculaLocalizacion
  ? defaultOptions
  : {
      ...defaultOptions,
      ...businessOptions.ConfirmacionMatriculaLocalizacion,
      hoc: {
        ...defaultOptions.hoc,
        ...businessOptions.ConfirmacionMatriculaLocalizacion.hoc,
      },
    };

const {
  hook: { fetchById, libbyCall, libbyDAO, libbyFetch },
  hoc: {
    renderPropCall,
    renderPropFetchById,
    simpleSelect,
    accordionSelector,
    selectedChips,
  },
  context: {
    byIdContext: { useByIdContext, ByIdProvider, ByIdContext },
    generalContext: { useGeneralContext, GeneralProvider, GeneralContext },
  },
} = makeBusinessEntity<ConfirmacionMatriculaLocalizacion>(
  DAO_NAME,
  optionsToUse,
);

// hooks
export const useConfirmacionMatriculaLocalizacionFetchById = fetchById;
export const useConfirmacionMatriculaLocalizacionLibbyCall = libbyCall;
export const useConfirmacionMatriculaLocalizacionDAO = libbyDAO;
export const useConfirmacionMatriculaLocalizacionLibbyFetch = libbyFetch;

// components/hoc
export const ConfirmacionMatriculaLocalizacionCall = renderPropCall;
export const ConfirmacionMatriculaLocalizacionById = renderPropFetchById;
export const ConfirmacionMatriculaLocalizacionSelect = simpleSelect;
export const ConfirmacionMatriculaLocalizacionAccordionSelector =
  accordionSelector;
export const ConfirmacionMatriculaLocalizacionSelectedChips = selectedChips;

// context
// ByIdContext
export const useConfirmacionMatriculaLocalizacionByIdContext = useByIdContext;
export const ConfirmacionMatriculaLocalizacionByIdProvider = ByIdProvider;
export const ConfirmacionMatriculaLocalizacionByIdContext = ByIdContext;
// GeneralContext
export const useConfirmacionMatriculaLocalizacionContext = useGeneralContext;
export const ConfirmacionMatriculaLocalizacionProvider = GeneralProvider;
export const ConfirmacionMatriculaLocalizacionContext = GeneralContext;
