import { NIVEL } from 'src/commons';

/**
 * El modulo EPA debe mostrarse para
 * secundario y solo en el mes de Marzo
 * @param currentLevel Nivel actual
 * @returns boolean
 */
export const showEpa = (currentLevel: string, anio: number) =>
  Number(currentLevel) === NIVEL.SECUNDARIO && anio === 21;
